import React, { useEffect, useState } from 'react';
import Banner from 'common/Banner';
import * as Routes from 'routes';
import googleClassroomIcon from 'images/icons/google-classroom-icon.png';
import styles from './ConnectGoogleClassroomBanner.module.scss';

const ConnectGoogleClassroomBanner = () => {
  const [connected, setConnected] = useState(false);

  const openAuthPopup = () => {
    window.open(Routes.authorize_google_classroom_index_path(), '', 'width=450,height=600');
  };

  const receiveMessage = ({ data }) => {
    if (data && data.email) {
      setConnected(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage);

    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  // Hide banner after successfully connected:
  if (connected) return null;

  return (
    <div className={styles.banner}>
      <Banner iconType="fa-info" color="blue">
        <span>
          Your teacher has connected Google Classroom and TCI.
          {' '}
          <a onClick={openAuthPopup} href="#">Click here</a>
          {' '}
          to connect your account.
        </span>
        <img alt="" src={googleClassroomIcon} className={styles.googleIcon} />
      </Banner>
    </div>
  );
};

export default ConnectGoogleClassroomBanner;
