/**
 * General layout of these components:
 * <Table>
 *   <NewModal addObject={this.addObject.bind(this)}>
 *     <Form newMaterial={true} />
 *   </NewModal>
 *   <Row>
 *     <EditModal>
 *       <Form newMaterial={false}>
 *         <ImageUploader />
 *         <SafetyNotes />
 *       </Form>
 *     </EditModal>
 *   </Row>
 *   …
 * </Materials>
 */

import React, { Component } from 'react';
import Axios from 'axios';
import SysadminTable from 'common/SysadminTable';
import Row from './Row';
import Form from './Form';
import Actions from './Actions';
import UnitForm from '../Units/Form';


const columns = [
  { text: 'Image', className: 'image' },
  { text: 'Name', className: 'name' },
  { text: 'Unit', className: 'unit' },
  { text: 'Safety Notes', className: 'safety-notes' },
  { text: 'Actions', className: 'actions' }
];

const actions = [
  {
    buttonText: 'Add Materials',
    buttonClasses: 'btn btn--sm btn--green',
    modalHeader: 'New Materials',
    modalClass: 'add-materials',
    formComponent: Form
  },
  {
    buttonText: 'Edit Units',
    buttonClasses: 'ml5 btn btn--sm btn--outline-purple',
    modalHeader: 'Edit Units of Measurement',
    modalClass: 'edit-units',
    formComponent: UnitForm
  }
];

export default class Table extends Component {
  static defaultProps = {
    error: null,
    materials: [],
    safetyNotes: [],
    isLoading: false,
    modalIsOpen: false
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  renderButtonAbove() {
    return actions.map(action => (
      <Actions
        key={action.modalClass}
        buttonClasses={action.buttonClasses}
        safetyNotes={this.state.safetyNotes}
        units={this.state.units}
        buttonAttributes={action}
        FormComponent={action.formComponent}
        onAdd={this.addObject.bind(this)}
        onUpdate={this.updateUnit.bind(this)}
        removeObject={this.removeObject.bind(this)}
      />
    ));
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getMaterials();
  }

  addObject(object, stateName, attr) {
    this.setState(prevState => ({
      [stateName || 'materials']: this.sortByAttribute([...prevState[stateName || 'materials'], object], attr || 'name')
    }));
  }

  sortByAttribute(array, attr) {
    return array.sort((a, b) => a[attr || 'materials'].localeCompare(b[attr || 'materials']));
  }

  updateUnit(object) {
    this.removeObject(object.value, 'units', 'value');
    this.addObject(object, 'units', 'label');
  }

  getMaterials() {
    Axios.get('/admin/materials/get_materials').then((response) => {
      const { materials, safetyNotes, units } = response.data;
      this.setState({
        materials,
        safetyNotes,
        units,
        isLoading: false
      });
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  renderMaterials() {
    return this.state.materials && this.state.materials.map((material) => {
      return (
        <Row key={material.id}
             newRecord={false}
             removeObject={this.removeObject.bind(this)}
             safetyNotes={this.state.safetyNotes}
             updateRow={this.updateRow.bind(this)}
             units={this.state.units}
             {...material} />
      );
    });
  }

  updateRow(updatedMaterial) {
    this.setState({
      materials: this.sortByAttribute(this.state.materials.map(material =>
        updatedMaterial.id === material.id ? updatedMaterial : material
      ), 'name')
    });
  }

  removeObject(id, stateName, attr) {
    this.setState({
      [stateName || 'materials']: this.state[stateName || 'materials'].filter(object => object[attr || 'id'] !== id)
    });
  }

  render() {
    return (
      <SysadminTable
        containerClass="materials-container"
        emptyMessage="No materials found."
        tableId="materials"
        isLoading={this.state.isLoading}
        columns={columns}
        buttonAbove={this.renderButtonAbove()}
      >
        {this.renderMaterials()}
      </SysadminTable>
    );
  }
}
