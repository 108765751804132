import React, { useState } from 'react';
import { CheckboxField } from '@/components/common/Forms';

interface NeedsAudioDescriptionsFieldProps {
  disabled?: boolean;
  needsAudioDescriptions: boolean;
}

const NeedsAudioDescriptionsField = ({ needsAudioDescriptions, disabled = false }: NeedsAudioDescriptionsFieldProps) => {
  const [isChecked, setIsChecked] = useState(needsAudioDescriptions);

  return (
    <CheckboxField
      rowClass="tw-flex tw-items-center"
      disabled={disabled}
      labelClass="tw-font-bold tw-mr-1 tw-flex tw-gap-0.5"
      checked={isChecked}
      onChange={() => setIsChecked(prevState => !prevState)}
      name="needs_audio_descriptions"
      label="Request Audio Descriptions"
      tooltipIcon="info"
      tooltipText={`
        Videos marked as Needs Audio Descriptions will automatically
        be sent to a service for audio description generation.
      `}
    />
  );
};

export default NeedsAudioDescriptionsField;
