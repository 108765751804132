import React from 'react';
import { ErrorMessage as RhfErrorMessage, Props } from '@hookform/error-message';

interface ErrorMessageProps extends Props<any, any> {}

const ErrorMessage = (props: ErrorMessageProps) => (
  <RhfErrorMessage
    render={({ message }) => <p className="tw-mt-2 tw-text-[red]">{message}</p>}
    {...props}
  />
);

export default ErrorMessage;
