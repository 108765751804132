import { useLayoutEffect } from 'react';

interface UseMutationObserverHookOptions {
  shouldObserve?: boolean;
}

const DEFAULT_HOOK_OPTIONS: UseMutationObserverHookOptions = {
  shouldObserve: true,
};

const DEFAULT_OBSERVER_OPTIONS: MutationObserverInit = {};

interface UseMutationObserverProps {
  callback: MutationCallback;
  node: HTMLElement;
  opts?: UseMutationObserverHookOptions;
  observerOpts?: MutationObserverInit;
}

const useMutationObserver = ({
  callback,
  node,
  opts = DEFAULT_HOOK_OPTIONS,
  observerOpts = DEFAULT_OBSERVER_OPTIONS,
}: UseMutationObserverProps) => {
  const { shouldObserve } = { ...DEFAULT_HOOK_OPTIONS, ...opts };

  useLayoutEffect(() => {
    if (!shouldObserve) return undefined;
    if (!node) return undefined;

    const observer = new MutationObserver(callback);

    observer.observe(node, observerOpts);

    return () => { observer.disconnect(); };
  }, [shouldObserve]);
};

export default useMutationObserver;
