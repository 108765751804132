export type DropZoneType = {
  id: string;
  top: number;
  left: number;
  text: string;
  type: string;
  width?: number;
  height?: number;
  shape?: 'rectangle' | 'circle';
  title: string;
};

export type VocabDropZoneType = DropZoneType & {
  vocabId: number;
};

export type DraggableChoiceType = {
  top: number;
  left: number;
  type: string;
  title: string;
  position: number
  vocabId?: number;
};

export type TextAreaType = {
  top: number;
  left: number;
  text: string;
  type: string;
  width?: number;
  height?: number;
};

export type DraggableChoicesType = {
  [key: string]: DraggableChoiceType;
};

export type DropZonesType = {
  [key: string]: DraggableChoiceType;
};

export type TextAreasType = {
  [key: string]: TextAreaType;
};

export type CorrectAnswersType = {
  [key: string]: string[];
};

export enum DraggableContainerPosition {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top',
};

export type DragAndDropDataType = {
  draggable_choices: DraggableChoicesType;
  drop_zones: DropZonesType;
  correct_answers: CorrectAnswersType;
  has_transparent_answer_choices: boolean;
  has_transparent_dropzones: boolean;
  drop_zone_shape: 'rectangle' | 'circle',
  draggable_container_position:
    DraggableContainerPosition.Bottom |
    DraggableContainerPosition.Left |
    DraggableContainerPosition.Right |
    DraggableContainerPosition.Top,
  text_areas: TextAreasType;
};

export type BackgroundImageType = {
  id: number;
  position?: string;
  url: string;
  size?: string;
};

export type DragAndDropQuestionType = {
  id: number;
  body: string;
  data: DragAndDropDataType;
  background_image: BackgroundImageType;
  points: number;
};

export type DraggableItemType = {
  id: string;
  title: string;
};

export type DragAndDropSettings = {
  hasTransparentAnswerChoices?: boolean;
  hasTransparentDropzones?: boolean;
};
