import * as Routes from '@/modules/routes';

const ONLY = [
  'id', 'image_en_file_name', 'image_en_updated_at', 'image_file_size', 'thumb_url', 'title_en', 'title_es', 'url',
  'description_en', 'description_es', 'description_confidence', 'credits', 'es_thumb_url', 'image_es_file_name',
  'has_text', 'english_image_only',
];

const EXTRA_ATTRIBUTES = ['num_of_uses'];

export const PAGE_SIZE = 500;

export const IMAGES_PATH = Routes.plato_api_images_path({
  page: 'pageNumber',
  per_page: PAGE_SIZE,
  column: 'columnName',
  order: 'sortDirection',
  only: ONLY,
  extra_attributes: EXTRA_ATTRIBUTES,
  includes: ['image_models'],
});

export const SEARCH_IMAGES_PATH = Routes.plato_api_search_images_path({
  only: ONLY,
  extra_attributes: EXTRA_ATTRIBUTES,
});

export const LABEL_CLASSES = 'tw-w-[130px] tw-font-bold';
export const FIELD_CONTAINER_CLASSES = 'tw-flex tw-items-center';

export const IMAGE_FILTERS = [
  {
    label: 'File Name (English)',
    value: 'image_en_file_name',
    operators: ['eq', 'not_eq', 'cont', 'not_cont'],
  },
  {
    label: 'File Name (Spanish)',
    value: 'image_es_file_name',
    operators: ['eq', 'not_eq', 'cont', 'not_cont', 'blank'],
  },
  {
    label: 'Title (English)',
    operators: ['eq', 'not_eq', 'cont', 'not_cont', 'blank'],
    value: 'title_en',
  },
  {
    label: 'Title (Spanish)',
    operators: ['eq', 'not_eq', 'cont', 'not_cont', 'blank'],
    value: 'title_es',
  },
  {
    label: 'Has Text',
    value: 'has_text',
    operators: ['eq'],
    isBoolean: true,
  },
];

export const INITIAL_IMAGE_FILTERS = [
  {
    operatorValue: 'contains',
    propertyValue: 'image_en_file_name',
    value: null,
  },
];
