import React, { useEffect, useState } from 'react';
import styles from '../ContentActions.module.scss';
import { convertToDecimal } from '../../Utils';
import { PopoverData } from '../types';

const REMOVE = 'REMOVE';
const HEX_REGEX = /^#([0-9a-f]{3}){1,2}$/i;
const NUMBERS_REGEX = /[0-9]/;

const COLOR_OPTIONS = [
  '#000000',
  '#555555',
  '#777770',
  '#CCCCCC',
  '#EEEEEE',
  '#FFFFFF',
  '#EA363B',
  '#F88222',
  '#FCC521',
  '#269A40',
  '#26B9CA',
  '#0072BC',
  '#8445D3',
  '#F37AB1',
  '#D2232A',
  '#F69C05',
  '#2A8806',
  '#247F99',
  '#522E91',
  REMOVE,
];

type RemoveIconProps = {
  onChange: (data: PopoverData) => void;
};

const RemoveIcon = ({ onChange }: RemoveIconProps) => (
  <span
    className={`${styles.colorSquare} ${styles.remove}`}
    onClick={() => onChange({ color: '', opacity: '' })}
    role="button"
  >
    <svg
      className={styles.removeIcon}
      focusable="false"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15,10v8H9v-8H15 M14,4H9.9l-1,1H6v2h12V5h-3L14,4z M17,8H7v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V8z"
      />
    </svg>
  </span>
);

type ColorPickerProps = {
  activeColor?: string;
  activeOpacity?: string;
  colorOptions?: string[];
  onChange: (data: PopoverData) => void;
  onSubmit?: (data: PopoverData) => Promise<any>;
  showHexInput?: boolean;
  showOpacityInput?: boolean;
};

const ColorPicker = ({
  activeColor, activeOpacity, colorOptions, onChange, onSubmit, showHexInput = true, showOpacityInput = true
}: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState<string>(activeColor || '');
  const [currentOpacity, setCurrentOpacity] = useState<string>(activeOpacity || '100');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayOpacity, setDisplayOpacity] = useState(activeOpacity || '100');

  // Handle click on color picker to show changes live.
  const handleChange = (data: PopoverData) => {
    onChange(data);
    setCurrentColor(data.color);
    setCurrentOpacity(data.opacity);
  };

  const handleColorInputChange = (nextColor) => {
    if (HEX_REGEX.test(nextColor)) {
      onChange({ color: nextColor, opacity: currentOpacity });
    }

    setCurrentColor(nextColor);
  };

  const handleOpacityInputChange = (inputOpacity) => {
    // If opacity input is empty, return 100 opacity.
    const opacity = inputOpacity.trim() === '' ? '100' : inputOpacity;

    // Limit max input to 100.
    const max = Math.min(100, Number(opacity));

    // Limit minimum value to 0.
    const value = Math.max(0, max).toString();

    onChange({ color: currentColor, opacity: value });

    setCurrentOpacity(value);
    setDisplayOpacity(inputOpacity.trim() === '' ? '' : value);
  };

  useEffect(() => {
    let opacity;

    // If slideObject has already has an opacity and isn't NaN
    if (activeOpacity && !Number.isNaN(parseInt(activeOpacity, 16))) {
      opacity = convertToDecimal(activeOpacity).toString();
    } else {
      opacity = '100';
    }

    setCurrentOpacity(opacity);
    setDisplayOpacity(opacity);
  }, []);

  const colors = colorOptions || COLOR_OPTIONS;

  return (
    <div className={styles.container}>
      <div className={styles.colors}>
        {colors.map((color) => {
          if (color === REMOVE) return <RemoveIcon key={color} onChange={handleChange} />;

          return (
            <span
              key={color}
              className={styles.colorSquare}
              onClick={() => handleChange({ color, opacity: currentOpacity })}
              role="button"
              style={{ background: color }}
            />
          );
        })}
      </div>
      {(showOpacityInput || showHexInput) && (
        <div className={styles.inputs}>
          {showOpacityInput && (
            <div className={`${styles.inputContainer} ${styles.opacityContainer}`} data-value={displayOpacity}>
              <input
                max="100"
                min="0"
                name="opacity"
                placeholder=" "
                onChange={(e) => {
                  handleOpacityInputChange(e.target.value);
                }}
                type="number"
                value={displayOpacity}
                onKeyPress={(event) => {
                  if (!NUMBERS_REGEX.test(event.key)) {
                    // Prevents typing of non-numerical text.
                    event.preventDefault();
                  }
                }}
              />
              <label htmlFor="opacity">Opacity</label>
            </div>
          )}
          {showHexInput && (
            <div className={styles.inputContainer}>
              <input
                maxLength={7}
                name="hexColor"
                onChange={(e) => {
                  handleColorInputChange(e.target.value);
                }}
                placeholder=" "
                value={currentColor}
              />
              <label htmlFor="hexColor">HEX Color</label>
            </div>
          )}
          {onSubmit && (
            <button
              className={styles.submitBtn}
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setIsSubmitting(true);
                onSubmit({ color: currentColor, opacity: currentOpacity })
                  .then(() => setIsSubmitting(false));
              }}
            >
              OK
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
