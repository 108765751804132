import React from 'react';
import I18n from 'modules/i18n';
import { IApiUnit } from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';
import ProfessionalDevelopmentCard from '@/components/common/ProfessionalDevelopment/ProfessionalDevelopmentCard';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';

interface UnitSectionProps {
  data: IApiUnit[];
  pdProgramId: number;
  programId: number;
  userId: number;
}

const NoResultsMessage = () => (
  <div>
    <h3 className="tw-text-center tw-font-bold tw-text-[18px] tw-mb-[2rem] tw-mt-[10rem]">{I18n.t('no_courses_found')}</h3>
    <p className="tw-text-[16px] tw-text-center">{I18n.t('please_modify_filters')}</p>
  </div>
);

const UnitSection = ({ data, pdProgramId, programId, userId }: UnitSectionProps) => {
  if (data.map(unit => unit.slide_shows).flat().length === 0) return <NoResultsMessage />;

  return (
    <ApolloProvider client={apolloClient}>
      {data.map(unit => (
        <div className="tw-ml-12" key={unit.id}>
          <h3 className="tw-text-lg tw-mt-0">{unit.title}</h3>
          <h4 className="tw-font-normal tw-text-gray-500 tw-mt-2 tw-mb-8">{unit.subtitle}</h4>
          <div className="tw-grid tw-gap-12 tw-grid-cols-3">
            {unit.slide_shows.map(slideShow => (
              <ProfessionalDevelopmentCard
                key={slideShow.id}
                slideShow={slideShow}
                pdProgramId={pdProgramId}
                programId={programId}
                userId={userId}
              />
            ))}
          </div>
        </div>
      ))}
    </ApolloProvider>
  );
};

export default UnitSection;
