import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Banner.module.scss';

const BannerContent = ({
  iconType, children, color, title, singleColorCard, borderColor, iconColor
}) => (
  <div className={clsx(`${styles.banner} card`, {
    [`tw-border-2 tw-border-${borderColor}`]: !!borderColor
  })}
  >
    <div className={`${styles.lockIcon} ${styles[color]}`}>
      <i className={`fa ${iconType} tw-text-${iconColor} mr5`} aria-hidden="true" />
    </div>
    <div
      className={clsx(`${styles.content}`, {
        [styles[color]]: singleColorCard,
      })}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className='tw-leading-tight'>
        {children}
      </div>
    </div>
  </div>
);

BannerContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  color: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  title: PropTypes.string,
  singleColorCard: PropTypes.bool,
  borderColor: PropTypes.string,
  iconColor: PropTypes.string
};

BannerContent.defaultProps = {
  title: '',
  singleColorCard: false,
  borderColor: null,
  iconColor: 'white'
};

export default BannerContent;
