import React from 'react';

const Thumbnail = ({ thumbnailUrl, url }: { thumbnailUrl: string, url: string }) => (
  <div className="tw-flex tw-w-[100px] tw-h-auto">
    {url && (
      <a
        className="tw-cursor-zoom-in"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="tw-max-w-[100px] tw-max-h-[100px] tw-object-contain"
          src={thumbnailUrl}
          alt="..."
        />
      </a>
    )}
  </div>
);

export default Thumbnail;
