import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const TextArea = React.forwardRef(({
  className, id, input, meta, placeholder, disabled // `input` and `meta` are FieldRenderProps
}, ref) => {
  useEffect(() => {
    $('.resizable').autoResize({ minHeight: 1 });
  });

  return (
    <textarea
      ref={ref}
      className={className}
      data-dirty={meta.dirty}
      placeholder={placeholder}
      id={id}
      disabled={disabled}
      {...input}
    />
  );
});

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired
  }).isRequired,
  placeholder: PropTypes.string
};

TextArea.defaultProps = {
  className: '',
  disabled: false,
  placeholder: null
};

export default TextArea;
