import React, { useMemo, useState } from 'react';
import { GroupedPrograms } from './useGroupedPrograms';

interface UseExpandTableProps {
  groupedPrograms: GroupedPrograms[];
}

export const useExpandTable = ({ groupedPrograms }: UseExpandTableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isRowShownByDefault = (row: GroupedPrograms) => row.seatPools.length || row.programs.recommended.length;

  const hideButton = useMemo(() => groupedPrograms.every(isRowShownByDefault), [groupedPrograms]);

  const expandTableButtonProps = useMemo(() => ({
    hideButton,
    isExpanded,
    setIsExpanded,
  }), [hideButton, isExpanded, setIsExpanded]);

  const isRowHidden = (row: GroupedPrograms) => {
    if (isRowShownByDefault(row)) return false;

    return !isExpanded;
  };

  return {
    expandTableButtonProps,
    isRowHidden,
  };
};

interface ExpandTableButtonProps {
  hideButton: boolean;
  isExpanded: boolean;
  setIsExpanded: (boolean) => void;
}

const ExpandTableButton = ({ hideButton, isExpanded, setIsExpanded }: ExpandTableButtonProps) => {
  if (hideButton) return null;

  return (
    <button
      type="button"
      className="btn btn--link-purple tw-px-0"
      onClick={() => setIsExpanded(prev => !prev)}
    >
      {isExpanded ? 'See Fewer Programs' : 'See More Programs'}
    </button>
  );
};

export default ExpandTableButton;
