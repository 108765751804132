import React from 'react';
import * as Routes from '@/modules/routes';
import { Games as GameIcon } from '@/../images/react/Games';
import { Biographies as BiographiesIcon } from '@/../images/react/Biographies';
import { Assessments as AssessmentsIcon } from '@/../images/react/Assessments';
import { VideoActivity as VideoActivityIcon } from '@/../images/react/VideoActivity';

type LibrariesObjProps = {
  programId: number;
  lessonId: number;
  unitId: number;
  civicsLibrary: boolean;
  primarySource: boolean;
};

const librariesObj = ({ programId, lessonId, unitId, civicsLibrary, primarySource } : LibrariesObjProps) => {
  const civicsLibraryObj = {
    title_tag: 'civics_library',
    icon: <BiographiesIcon fill="#555555" />,
    path: Routes.shared_program_reference_materials_path(programId, { tag: 'Civics Library' }),
  };
  const primarySourceObj = {
    title_tag: 'primary_sources',
    icon: <BiographiesIcon fill="#555555" />,
    path: Routes.shared_program_reference_materials_path(
      programId, { tag: 'Civics Library' },
    ),
  };
  const libraryCards = [
    {
      title_tag: 'videos',
      icon: <VideoActivityIcon fill="#555555" />,
      path: Routes.shared_program_videos_path({ id: programId }),
    },
    {
      title_tag: 'games',
      icon: <GameIcon fill="#555555" />,
      path: Routes.library_shared_program_games_path({ id: programId }),
    },
    {
      title_tag: 'biographies',
      icon: (<BiographiesIcon fill="#555555" />),
      path: Routes.shared_program_reference_materials_path(
        programId, { selected_unit_id: unitId, tag: 'Biographies' },
      ),
    },
    {
      title_tag: 'assessments',
      icon: <AssessmentsIcon fill="#555555" />,
      path: Routes.library_shared_program_assessments_path(
        programId, { lesson_id: lessonId, unit_id: unitId },
      ),
    },

  ];

  if (civicsLibrary) libraryCards.push(civicsLibraryObj);
  if (primarySource) libraryCards.push(primarySourceObj);
  return libraryCards;
};

export default librariesObj;
