import React from 'react';
import PropTypes from 'prop-types';
import canvasIcon from 'images/icons/canvas-red-box.png';
import UnconnectedCard from 'common/Settings/IntegrationsCards/CardTypes/UnconnectedCard';
import ConnectedLtiCard from 'components/common/Settings/IntegrationsCards/CardTypes/ConnectedLtiCard';

const CanvasLti13Settings = ({ connectedToCanvas1_3, programId }) => {
  const Link = () => (
    <a
      href="https://www.teachtci.com/canvas-integration-setup"
      className="btn btn--purple"
      rel="noopener noreferrer"
      target="_blank"
    >
      <i className="fa fa-external-link mr10" aria-hidden="true" />
      Set up Integration
    </a>
  );

  // eslint-disable-next-line camelcase
  if (connectedToCanvas1_3) {
    return (
      <ConnectedLtiCard
        headerText="Canvas LTI 1.3"
        icon={canvasIcon}
        programId={programId}
      />
    )
  }

  return (
    <UnconnectedCard
      bodyText="Sync Assignments and Grades"
      Button={<Link />}
      headerText="Canvas LTI 1.3"
      icon={canvasIcon}
    />
  );
};

CanvasLti13Settings.propTypes = {
  connectedToCanvas1_3: PropTypes.bool.isRequired,
  programId: PropTypes.number.isRequired,
};

export default CanvasLti13Settings;
