import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import Dropdown from 'common/Dropdown';
import BackgroundPositionManager from 'common/BackgroundPositionManager';
import { slidePropTypes } from '../../Utils';
import styles from './BackgroundDropdownActions.module.scss';
import useSlideShowContext from '../../stores/useSlideShowContext';
import { UPDATE_SLIDE } from '../../Utils/slideShowGraphQL';

const ChangeBackgroundPosition = ({ slide }) => {
  const [updateSlideMutation] = useMutation(UPDATE_SLIDE);
  const slideRef = useSlideShowContext(state => state.slideRef);
  const updateSlide = useSlideShowContext(state => state.updateSlide);

  const submitHandler = ({ backgroundPosition: bgPosition, customPosition }) => {
    const backgroundPosition = bgPosition === 'custom' ? `center ${customPosition}` : bgPosition;

    const mutationParams = {
      variables: {
        backgroundPosition,
        id: slide.id
      }
    };

    updateSlideMutation(mutationParams)
      .then((res) => {
        updateSlide(slide.id, res.data.updateSlide.slide);
      })
      .catch((error) => { console.log(error); });
  };

  if (!slideRef) return null;

  return (
    <Dropdown
      buttonClass={`${styles.dropdownItem} ${styles.submenu}`}
      dropdownPosition="right"
      toggleText={(
        <Fragment>
          Change Position
          <i className={`fa fa-angle-right ${styles.angleRight}`} />
        </Fragment>
      )}
    >
      <BackgroundPositionManager
        object={slide}
        objectRef={slideRef.slideRef}
        submitHandler={submitHandler}
      />
    </Dropdown>
  );
};

ChangeBackgroundPosition.propTypes = {
  slide: slidePropTypes.isRequired,
};

export default ChangeBackgroundPosition;
