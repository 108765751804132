import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SubmitModal, useModalManager } from 'common/Modal';
import * as Routes from 'routes';
import DropdownItemButton from 'common/ActionsDropdown/DropdownItemButton';

const DeleteVideo = ({ videoId, referenceCount, afterSubmit }) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <DropdownItemButton
        text="Delete"
        icon="trash"
        onClick={modalManager.open}
        disabled={referenceCount > 0}
        disabledTooltipText="Videos in use cannot be deleted."
      />
      <SubmitModal
        headerText="Delete Video"
        submitPath={Routes.plato_api_video_path(videoId)}
        method="delete"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
      >
        Are you sure you want to delete this video?
      </SubmitModal>
    </Fragment>
  );
};

DeleteVideo.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  referenceCount: PropTypes.number.isRequired,
  videoId: PropTypes.number.isRequired
};

export default DeleteVideo;
