import React, { Component } from 'react';
import Axios from 'axios/index';
import SortableImageList from './SortableImageList';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';

const SortableList = SortableContainer(SortableImageList);

export default class ImagesList extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.props.onReorderItems(arrayMove(this.props.items, oldIndex, newIndex));
  };

  render() {
    return <SortableList items={this.props.items}
                         onSortEnd={this.onSortEnd}
                         onRemoveItem={this.props.onRemoveItem}
                         useDragHandle={true}
                         onUpdateItem={this.props.onUpdateItem}
                         toggleEditingMode={this.props.toggleEditingMode}
    />
  }
}

