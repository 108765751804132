import { gql } from '@apollo/client';
import { MODEL_VIDEO_COLS } from '../../VideoManager/Utils';

const IMAGE_MODEL_COLS = `
  id,
  alignment,
  captionEn,
  captionEs,
  decorative,
  displaySize,
  displayStyle,
  snippet,
  zoomType,
  image {
    id,
    credits,
    descriptionEn,
    descriptionEs,
    descriptionConfidence,
    imageUrl,
    imageEsUrl,
    fileName,
    fileNameEs,
    resolution,
    thumbnail,
    titleEn,
    titleEs,
    hasText,
    englishImageOnly,
  }
`;

const QUESTION_GROUP_COLS = `
  id,
  exerciseType
  notebook {
    modelType
    modelId
    id
  }
`;

const SLIDE_COLS = `
  id,
  backgroundPosition,
  notes,
  notesEn,
  notesEs
  position,
  title,
  slideTemplateId,
  slideGroupId,
  slideShowId,
  updatedAt,
  modelTags {
    tagId
  },
  buildingTowardsModelTags {
    tagId
  }
  imageModel {
    ${IMAGE_MODEL_COLS}
  },
  condition {
    id,
    slideId,
    condition,
    action,
    slideIds,
  }
  backgroundAudioUrl,
  backgroundAudioFileName,
  backgroundAudioEsUrl,
  backgroundAudioEsFileName,
  displaySlideHeader,
  startPage,
  endPage,
  slideGroupTitleEn,
  slideGroupTitleEs,
  slideGroupIcon,
`;

const SLIDE_TEMPLATE_COLS = `id,
                             title,
                             slideTemplateObjects {
                               id,
                               index,
                               position,
                               width,
                               height,
                               defaultData,
                               styles
                           }`;
const SLIDE_OBJECT_COLS = `id,
                           imageModel {
                             ${IMAGE_MODEL_COLS}
                           },
                           imageModels {
                             ${IMAGE_MODEL_COLS}
                           },
                           questionGroups {
                             ${QUESTION_GROUP_COLS},
                           }
                           modelVideo {
                             ${MODEL_VIDEO_COLS}
                           },
                           isDragAndDrop,
                           dragAndDropQuestion {
                             id,
                             body,
                             data,
                             backgroundImage {
                               id,
                               position,
                               url,
                               size,
                             },
                             points,
                           },
                           answerableQuestionIds,
                           backgroundColor,
                           index,
                           html,
                           htmlEn,
                           htmlEs,
                           htmlElements {
                             id,
                             type,
                             modelType,
                             modelId,
                             htmlEn,
                             htmlEs,
                             data,
                             snippet,
                             modelVideo {
                               ${MODEL_VIDEO_COLS}
                             },
                             questionGroups {
                               ${QUESTION_GROUP_COLS},
                             },
                           },
                           modelType,
                           modelId,
                           opacity,
                           padding,
                           borderColor,
                           borderRadius,
                           borderWidth,
                           borderOpacity,
                           slideId,
                           panoramicImages {
                            id,
                            image,
                            hotspots,
                            defaultView
                           }
                           threeDModels {
                            cameraPos,
                            cameraRot,
                            cameraTarget,
                            cameraZoom,
                            id,
                            fileName,
                            modelUrl,
                            textures {
                              id,
                              imageUrl,
                              imageModels {
                                id,
                                textureType
                              }
                            }
                           },
                           modelInteractiveMaps {
                             id,
                             instructionsEn,
                             instructionsEs,
                             interactiveMap {
                               id,
                               appId,
                               titleEn,
                               titleEs,
                             },
                           },
`;

const SLIDE_GROUP_COLS = `
  displaySlideHeader,
  extension,
  icon,
  id,
  pacing,
  position,
  titleEn,
  titleEs,
  slides {
    id,
    displaySlideHeader,
  }
`;

/** Queries * */

export const GET_SLIDES = gql`
  query SlidesInSlideShow($slideShowId: Int!) {
    slides(slideShowId: $slideShowId, order: { col: "position", dir: "asc" }) {
      ${SLIDE_COLS}
    }
  }
`;

export const GET_COMPLETE_SLIDES = gql`
  query CompleteSlideData($slideShowId: Int!, $ids: [ID!]) {
    slides(slideShowId: $slideShowId, order: { col: "position", dir: "asc" }, ids: $ids) {
      ${SLIDE_COLS},
      slideTemplate {${SLIDE_TEMPLATE_COLS}},
      slideObjects {${SLIDE_OBJECT_COLS}}
    },
    slideGroups(slideShowId: $slideShowId, order: { col: "position", dir: "asc" }) {${SLIDE_GROUP_COLS}}
  }
`;

export const GET_VIDEO_ACTIVITY_RESULTS = gql`
  query VideoActivityResults($programId: ID!, $slideShowId: ID!, $parentId: ID, $parentType: String, $studentId: ID) {
    slideShow(id: $slideShowId) {
      lesson(programId: $programId, parentId: $parentId, parentType: $parentType) {
        videoActivity {
          exerciseResult(studentId: $studentId) {
            completedAt
            answerUpdatedAt
          }
        }
      },
    }
  }
`;

export const GET_PD_FINAL_SLIDE_DATA = gql`
  query GetProfessionalDevelopmentFinalSlideData($programId: ID!, $slideShowId: ID!, $userId: ID) {
    slideShow(id: $slideShowId) {
      courseBadgeUrl,
      pdStatus(userId: $userId),
      lesson(programId: $programId) {
        id,
        titleWithPosition(programId: $programId)
        professionalDevelopment {
          id,
          completedForStaffer(userId: $userId),
          stafferExerciseResult(userId: $userId) {
            id
            completedAt
            answerUpdatedAt
          }
        }
      },
      unansweredQuestions {
        id,
        slideId,
      }
    }
  }
`;

export const GET_SLIDE_SHOW_ASSIGNMENT_RESULTS = gql`
  query GetSlideShowAssignmentResults($slideShowId: ID!, $assignmentId: ID) {
    assignment(id: $assignmentId) {
      exerciseResults {
        id,
        completedAt,
        answerUpdatedAt,
      }
    }
    slideShow(id: $slideShowId) {
      unansweredQuestions {
        id,
        slideId,
      }
    }
  }
`;

export const GET_PRESENT_MODE_SLIDE_SHOW = gql`
  query PresentModeSlideShow($programId: ID!, $slideShowId: ID!, $parentId: ID, $parentType: String, $studentId: ID) {
    slideShow(id: $slideShowId) {
      descriptionEn,
      descriptionEs,
      themeId,
      hasSlideConditions,
      id,
      imageUrl(programId: $programId, parentId: $parentId, parentType: $parentType),
      lesson(programId: $programId, parentId: $parentId, parentType: $parentType) {
        id,
        lessonGameAssignable { label },
        position(programId: $programId),
        sections {
          id,
          investigation,
          notebooks(displayOnAssessWithSection: true) {
            id,
            titleEn,
            titleEs
          }
        }
        titleEn,
        titleEs,
        videoActivity {
          id,
          resettable(programId: $programId, studentId: $studentId),
          exerciseResult(studentId: $studentId) {
            completedAt
          }
        },
        professionalDevelopment {
          id
        },
      },
      titleEn,
      titleEs,
      slides {
        ${SLIDE_COLS},
        slideTemplate {${SLIDE_TEMPLATE_COLS}},
        slideObjects {${SLIDE_OBJECT_COLS}}
      },
      slideGroups {
        ${SLIDE_GROUP_COLS}
      },
      unit(programId: $programId, parentId: $parentId, parentType: $parentType) {
        id,
        position,
        titleEn,
        titleEs
      },
      activityType,
      slideGroupsAssignments {
        id,
        assignmentLabel,
        assignmentsModels {
          id,
          modelId,
          modelType,
        },
      },
      unansweredQuestions {
        id,
        slideId,
      },
    }
  }
`;

export const GET_SLIDE_TEMPLATES = gql`
  query GetSlideTemplates {
    slideTemplates(fabricData: null) {
      id,
      title,
      slideTemplateObjects {
        id,
        index,
        position,
        width,
        height,
        defaultData
      }
    }
  }
`;

export const GET_ALL_SECTIONS = gql`
  query getAllSections($lessonId: ID, $isLesson: Boolean!, $programId: ID!, $unitId: ID) {
    lesson (id: $lessonId) @include(if: $isLesson) {
      sections {
        id,
        title(programId: $programId)
      },
      unit (programId: $programId) {
        unitSections {
          id,
          title(programId: $programId)
         }
      }
    },
    unit(id: $unitId) @skip(if: $isLesson) {
      sections {
        id,
        title(programId: $programId)
      },
      unitSections {
        id,
        title(programId: $programId)
      },
    }
  }
`;

export const GET_ALL_SLIDE_SHOW_IMAGES = gql`
  query GetAllSlideShowImages($slideShowId: ID) {
    slideShow(id: $slideShowId) {
      slides {
        imageModel {
          id,
          image {
            id,
            fileName,
            smallUrl,
          }
        },
        htmlElements {
          imageModels {
            id,
            image {
              id,
              fileName,
              smallUrl,
            }
          }
        },
        slideObjects {
          imageModels {
            id,
            image {
              id,
              credits,
              descriptionEn,
              descriptionEs,
              descriptionConfidence,
              imageUrl,
              imageEsUrl,
              fileName,
              fileNameEs,
              resolution,
              thumbnail,
              titleEn,
              titleEs,
              hasText,
              englishImageOnly,
              smallUrl,
            }
          }
        }
      }
    }
  }
`;

export const GET_SLIDE_OBJECT = gql`
  query GetSlideObject($id: ID) {
    slideObject(id: $id) {
      ${SLIDE_OBJECT_COLS}
    }
  }
`;

/** Mutations * */

export const CREATE_SLIDE = gql`
  mutation CreateSlide($slideShowId: ID!, $position: Int, $slideGroupId: Int) {
    createSlide(slideShowId: $slideShowId, position: $position, slideGroupId: $slideGroupId) {
      result {
        ${SLIDE_COLS},
        slideTemplate {${SLIDE_TEMPLATE_COLS}},
        slideObjects {${SLIDE_OBJECT_COLS}}
      }
    }
  }
`;

export const COPY_SLIDE = gql`
  mutation CopySlide($id: ID!, $position: Int, $slideShowId: Int) {
    copySlide(id: $id, position: $position, slideShowId: $slideShowId) {
      success,
      result {
        ${SLIDE_COLS},
        slideTemplate {${SLIDE_TEMPLATE_COLS}},
        slideObjects {${SLIDE_OBJECT_COLS}}
      }
    }
  }
`;

export const REMOVE_SLIDE = gql`
  mutation RemoveSlide($id: ID!) {
    destroySlide(id: $id) {
      success
    }
  }
`;

export const REMOVE_SLIDE_GROUP = gql`
  mutation RemoveSlideGroup($id: ID!) {
    destroySlideGroupAndSlides(id: $id) {
      success
    }
  }
`;

export const UPDATE_SLIDE_SHOW = gql`
  mutation UpdateSlideShow(
    $id: ID!,
    $title: String!,
    $description: String,
    $activityType: String,
    $themeId: ID,
  ) {
    updateSlideShow(
      id: $id,
      title: $title,
      description: $description,
      activityType: $activityType,
      themeId: $themeId,
    ) {
      id
      title
      titleEn
      titleEs
      description
      activityType
      themeId
    }
  }
`;

export const UPDATE_SLIDE_OBJECT = gql`
  mutation UpdateSlideObject(
    $slideObjectId: ID!,
    $html: String,
    $locale: String,
    $borderColor: String,
    $borderRadius: String,
    $borderWidth: String,
    $borderOpacity: String,
    $backgroundColor: String,
    $opacity: String,
    $padding: Float,
  ) {
    updateSlideObject(
      slideObjectId: $slideObjectId,
      html: $html,
      locale: $locale,
      borderColor: $borderColor,
      borderRadius: $borderRadius,
      borderWidth: $borderWidth,
      borderOpacity: $borderOpacity,
      backgroundColor: $backgroundColor,
      opacity: $opacity,
      padding: $padding,
    ) {
      success
      slideObject {
        ${SLIDE_OBJECT_COLS}
      }
    }
  }
`;

export const CREATE_SLIDE_OBJECT = gql`
  mutation createSlideObject($slideId: ID!, $modelType: String!, $index: Int!) {
    createSlideObject(slideId: $slideId, modelType: $modelType, index: $index) {
      result {
        ${SLIDE_OBJECT_COLS}
      }
    }
  }
`;

export const APPLY_TEMPLATE = gql`
  mutation ApplyTemplate($slideId: ID!, $slideTemplateId: ID!, $modelId: Int!, $modelType: String!, $programId: Int!) {
    applyTemplate(
      slideId: $slideId,
      slideTemplateId: $slideTemplateId,
      modelId: $modelId,
      modelType: $modelType,
      programId: $programId
    ) {
      success,
      result {
        ${SLIDE_COLS},
        slideTemplate {${SLIDE_TEMPLATE_COLS}},
        slideObjects {${SLIDE_OBJECT_COLS}}
      }
    }
  }
`;

export const UPDATE_SLIDE = gql`
  mutation UpdateSlide(
    $id: ID!,
    $title: String,
    $tagIds: [ID!],
    $buildingTowardsIds: [ID!],
    $position: Int,
    $slideGroupId: Int,
    $backgroundPosition: String,
    $notes: String,
  ) {
    updateSlide(
      id: $id,
      title: $title,
      tagIds: $tagIds,
      buildingTowardsIds: $buildingTowardsIds,
      position: $position,
      slideGroupId: $slideGroupId,
      backgroundPosition: $backgroundPosition,
      notes: $notes,
    ) {
      success,
      slide {
        ${SLIDE_COLS},
        slideTemplate {${SLIDE_TEMPLATE_COLS}},
        slideObjects {${SLIDE_OBJECT_COLS}}
      }
    }
  }
`;

export const REORDER_SLIDE = gql`
  mutation UpdateSlide(
    $id: ID!,
    $position: Int,
    $slideGroupId: Int,
  ) {
    updateSlide(
      id: $id,
      position: $position,
      slideGroupId: $slideGroupId,
    ) {
      success,
      slideShow {
        slides {
          ${SLIDE_COLS},
          slideTemplate {${SLIDE_TEMPLATE_COLS}},
          slideObjects {${SLIDE_OBJECT_COLS}}
        },
        slideGroups {
          ${SLIDE_GROUP_COLS}
        },
      },
    }
  }
`;


export const DESTROY_SLIDE_GROUP_MUTATION = gql`
  mutation DestroySlideGroup($slideGroupId: ID!) {
    destroySlideGroup(slideGroupId: $slideGroupId) {
      success,
      slideShow {
        slides {
          ${SLIDE_COLS},
          slideTemplate {${SLIDE_TEMPLATE_COLS}},
          slideObjects {${SLIDE_OBJECT_COLS}}
        },
        slideGroups {
          ${SLIDE_GROUP_COLS}
        },
      }
    }
  }
`;

export const CREATE_SLIDE_GROUP = gql`
  mutation CreateSlideGroup($slideShowId: ID!, $titleEn: String!) {
    createSlideGroup(slideShowId: $slideShowId, titleEn: $titleEn) {
      success,
      slideShow {
        slides {
          ${SLIDE_COLS},
          slideTemplate {${SLIDE_TEMPLATE_COLS}},
          slideObjects {${SLIDE_OBJECT_COLS}}
        },
        slideGroups {
          ${SLIDE_GROUP_COLS}
        },
      },
    }
  }
`;

export const UPDATE_SLIDE_GROUP = gql`
  mutation UpdateSlideGroup(
    $slideGroupId: ID!,
    $programId: ID!,
    $titleEn: String,
    $titleEs: String,
    $slideIds: String,
    $pacing: Int,
    $extension: Boolean,
    $displaySlideHeader: Boolean,
    $icon: String,
  ) {
    updateSlideGroup(
      slideGroupId: $slideGroupId,
      programId: $programId,
      titleEn: $titleEn,
      titleEs: $titleEs,
      slideIds: $slideIds,
      pacing: $pacing,
      extension: $extension,
      displaySlideHeader: $displaySlideHeader,
      icon: $icon,
    ) {
      success,
      slideShow {
        slides {
          ${SLIDE_COLS},
          slideTemplate {${SLIDE_TEMPLATE_COLS}},
          slideObjects {${SLIDE_OBJECT_COLS}}
        },
        slideGroups {
          ${SLIDE_GROUP_COLS}
        },
      }
    }
  }
`;
