import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Tooltip from '@/components/common/Tooltip';
import NoteForm from './NoteForm';

interface StudentNotesProps {
  imgUrl: string;
  imgWhiteUrl: string;
  isStudent: boolean;
  note?: string;
  submitUrl: string;
}

const StudentNotes = ({ imgUrl, imgWhiteUrl, isStudent, note, submitUrl }: StudentNotesProps) => {
  const [isOpen, setIsOpen] = useState(note?.length > 0);
  const tagType = isStudent ? 'button' : 'link';
  const handleClick = () => {
    if (isStudent) return setIsOpen(true);

    return alert('Teachers cannot add notes. This feature only works for students.');
  };

  return (
    <>
      <Tooltip content="Add Note">
        {tagType === 'button' ? (
          <button
            aria-label="Add Note"
            className={`text-action ${isOpen ? 'purple' : 'white'}`}
            onClick={handleClick}
            type="button"
          >
            <img
              alt="Add note"
              src={isOpen ? imgWhiteUrl : imgUrl}
            />
          </button>
        ) : (
          <a
            onClick={handleClick}
            data-testid="add-note-button"
            aria-label="Add Note"
            className={`text-action ${isOpen ? 'purple' : 'white'} tw-block`}
          >
            <img
              alt="Add note"
              src={isOpen ? imgWhiteUrl : imgUrl}
            />
          </a>
        )}
      </Tooltip>
      {isStudent && isOpen && createPortal(
        <NoteForm studentNote={note} submitUrl={submitUrl} />,
        document.getElementById('student-notes-container'),
      )}
    </>
  );
};

export default StudentNotes;
