import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

export default function SubmitButton({
  submitDisabled, submitting, submittingText,
  submitButtonText, disableWithTooltipText, onClick, buttonClassName
}) {
  function renderSubmitButton() {
    if (submitting) {
      return (
        <button
          className={buttonClassName}
          disabled
          type="submit"
        >
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
          {' '}
          {submittingText}
        </button>
      );
    }

    if (submitDisabled && disableWithTooltipText) {
      return renderSubmitWithTooltip();
    }

    return renderSubmit();
  }

  function renderSubmit() {
    return (
      <input
        className={buttonClassName}
        disabled={submitDisabled}
        type="submit"
        value={submitButtonText}
        data-disable-with="Submitting…"
        onClick={onClick}
      />
    );
  }

  function renderSubmitWithTooltip() {
    return (
      <Tooltip
        content={disableWithTooltipText}
      >
        <div>
          {renderSubmit()}
        </div>
      </Tooltip>
    );
  }

  return (
    renderSubmitButton()
  );
}

SubmitButton.propTypes = {
  buttonClassName: PropTypes.string,
  disableWithTooltipText: PropTypes.string,
  onClick: PropTypes.func,
  submitButtonText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  submittingText: PropTypes.string
};

SubmitButton.defaultProps = {
  buttonClassName: 'btn btn--purple',
  disableWithTooltipText: null,
  onClick: () => {},
  submitButtonText: 'Submit',
  submitDisabled: false,
  submitting: false,
  submittingText: 'Submitting...'
};
