import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

interface GoogleTagManagerProps {
  daysActive?: string;
  gtmContainerId: string;
  lesson?: { id: string; }
  locale: Locale;
  program?: {
    id: string;
    title: string;
    subtitle: string;
    remove_copyright_symbol: string;
    subject: string;
    grade_level: string;
  },
  section?: { id: string; }
  subscriber?: { name: string; state: string; };
  trialVersion: boolean;
  user?: {
    canvas_setup_status?: string;
    email?: string;
    first_name: string;
    google_classroom_setup_status?: string;
    last_name: string;
    last_sign_in_unix_timestamp?: number;
    has_demo_classes: boolean;
    id: string;
    launchdarkly_flags: { [key: string]: boolean; };
    school_year_sign_in_count: number;
    sign_in_count: number;
    subscriber_id: string;
    type: UserType;
  },
}

const GoogleTagManager = ({
  daysActive, gtmContainerId, lesson, locale, program, section, subscriber, trialVersion, user,
}: GoogleTagManagerProps) => {
  useEffect(() => {
    const dataLayer = {
      canvasSetupStatus: user.canvas_setup_status,
      daysActive,
      email: user.email,
      firstName: user.first_name,
      googleClassroomSetupStatus: user.google_classroom_setup_status,
      gradeLevel: program.grade_level,
      has_demo_classes: user.has_demo_classes,
      launchdarklyFlags: JSON.stringify(user.launchdarkly_flags),
      lastName: user.last_name,
      lastSignInUnixTimestamp: user.last_sign_in_unix_timestamp,
      lessonId: lesson.id,
      locale,
      userType: user.type,
      userId: user.id,
      subscriberId: user.subscriber_id,
      subscriberName: subscriber.name,
      subscriberState: subscriber.state,
      programId: program.id,
      programTitle: program.title,
      programSubtitle: program.subtitle,
      programCopyright: program.remove_copyright_symbol,
      sectionId: section.id,
      signInCount: user.sign_in_count,
      schoolYearSignInCount: user.school_year_sign_in_count,
      subject: program.subject,
      trialVersion,
    };

    TagManager.initialize({
      gtmId: gtmContainerId,
      dataLayer,
    });
  }, []);

  return null;
};

export default GoogleTagManager;
