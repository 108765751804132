import React from 'react';
import Anchor from '@/components/ui/Anchor/Anchor';

interface MainIdeasDeletePopoverProps {
  deleteIcon: string;
  onDelete: () => void;
  targetElement: HTMLElement;
  editorTop: number;
  editorInstance: any;
}

function MainIdeasDeletePopover({
  deleteIcon,
  onDelete,
  targetElement,
  editorTop,
  editorInstance,
}: MainIdeasDeletePopoverProps) {
  // Check if popup would be obstructed by toolbar
  const wouldBeObstructed = targetElement.getBoundingClientRect().top - editorTop < 50;

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!editorInstance) { return; }

    editorInstance.undo.saveStep();
    onDelete();

    editorInstance.undo.saveStep();
    editorInstance.events.trigger('contentChanged');
  };

  return (
    <Anchor
      to={targetElement}
      className={`tw-w-20 tw-h-10 tw-bg-white tw-rounded ${wouldBeObstructed
        ? 'tw-shadow-[0_-2px_7px_rgba(0,0,0,0.1),0_2px_10px_rgba(0,0,0,0.15)]'
        : 'tw-shadow-lg'
      }`}
      offset={{ top: 50 }}
      attachListenersTo={[document.querySelector('.fr-wrapper')]}
    >
      <button
        className="tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-border-0 tw-bg-transparent tw-cursor-pointer"
        onMouseDown={handleDelete}
        dangerouslySetInnerHTML={{ __html: deleteIcon }}
        type="button"
      />
      <div
        className={`tw-absolute tw-w-0 tw-h-0 tw-left-1/2 tw--ml-1.5 tw-border-l-[6px] tw-border-r-[6px] 
          tw-border-l-transparent tw-border-r-transparent tw-border-solid ${wouldBeObstructed
          ? 'tw-top-[-6px] tw-border-b-[6px] tw-border-b-white'
          : 'tw-bottom-[-6px] tw-border-t-[6px] tw-border-t-white'
            }`}
      />
    </Anchor>
  );
}

export default MainIdeasDeletePopover;
