import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './GooglePicker.module.scss';

export default class GooglePicker extends Component {
  static propTypes = {
    directory: PropTypes.string.isRequired,
    selectCallback: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.getFiles = this.getFiles.bind(this);
    this.onPickerApiLoad = this.onPickerApiLoad.bind(this);
    this.createPicker = this.createPicker.bind(this);
  }

  onPickerApiLoad() {
    const oauthToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    this.createPicker(oauthToken);
  }

  getFiles() {
    gapi.load('picker', { callback: this.onPickerApiLoad });
  }

  // Create and render a Picker object for searching folders.
  createPicker(token) {
    if (token) {
      const view = new google.picker.DocsView();
      view.setIncludeFolders(true);
      view.setSelectFolderEnabled(true);
      view.setMimeTypes('application/vnd.google-apps.folder');
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setOrigin(window.location.protocol + '//' + window.location.host)
        .setAppId('1058868928485')
        .setOAuthToken(token)
        .addView(view)
        .setTitle('Select Destination:')
        .setCallback(this.props.selectCallback)
        .build();
      picker.setVisible(true);
    }
  }

  render() {
    return (
      <div>
        <i className={`fa fa-folder ${styles.folder}`} aria-hidden="true" />
        {this.props.directory} (
        <button
          type="button"
          className={`btn btn--link-purple ${styles.changeLink}`}
          onClick={this.getFiles}
        >
          change
        </button>
        )
      </div>
    );
  }
}
