import { convertCopyright } from '../Forms/Utils';

/**
 * Formats the student object for use as an option in the select.
 * @param student - the student object that should be formatted
 * @returns {{ label:, value: }}
 */
export function formatStudentForSelect(student) {
  return ({
    label: `${student.first_name} ${student.last_name} (${student.username})`,
    value: student.id
  });
}

/**
 * Formats the teacher object for use as an option in the select
 * @param teacher - the teacher object that should be formatted
 * @returns {{ label:, value: }}
 */
export function formatTeacherForSelect(teacher) {
  return ({
    label: `${teacher.first_name} ${teacher.last_name} (${teacher.email})`,
    value: teacher.id
  });
}

/**
 * Formats a program object for use as an option in the select
 * @param program - the program object that should be formatted
 * @returns {{ label:, value: }}
 */
export function formatProgramForSelect(program) {
  return ({ label: program.full_title_with_edition, value: program.id });
}

/**
 * Formats a programs for use as an option in the select
 * @param programs - the programs that should be formatted
 * @returns formatted array option for select [{ label:, value:}, { label:, value:}]
 */
export function formatProgramsForSelect(programs) {
  return programs.map(program => formatProgramForSelect(program));
}

/**
 * Sorts and object based on the value given
 * @param a - first element
 * @param b - second element
 * @returns {number} sorting value
 */
export function sortingCallback(a, b) {
  return (a.label > b.label) ? 1 : -1;
}
