import Axios from 'axios';
import { plato_api_style_sheet_path } from '@/modules/routes';
import { useMutation } from '@tanstack/react-query';

export default function useEditStyleSheetMutation() {
  return useMutation({
    mutationFn: async ({ id, rulesData }: { id: number | string, rulesData: string }) => {
      const request = await Axios.patch(
        plato_api_style_sheet_path(id),
        { rules_data: rulesData }
      );

      return request.data;
    },
    mutationKey: ['useEditStyleSheetMutation'],
  });
};
