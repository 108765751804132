import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import ModelSelect from './index';

const UnitLessonSelect = ({ ...props }) => (
  <ModelSelect
    {...props}
    selectType="unit_lesson"
    ariaLabel="Select unit or lesson"
    title={`${props.disableUnitOptions ? I18n.t('lesson') : `${I18n.t('unit')}/${I18n.t('lesson')}`}:`}
    optionsCacheKey={props.cacheKey}
  />
);

UnitLessonSelect.propTypes = {
  cacheKey: PropTypes.string,
  disableUnitOptions: PropTypes.bool,
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  parentResourceUrl: PropTypes.string,
  programId: PropTypes.number.isRequired,
  resourceUrl: PropTypes.string,
};

UnitLessonSelect.defaultProps = {
  cacheKey: null,
  disableUnitOptions: false,
  parentResourceUrl: null,
  resourceUrl: null,
};

export default UnitLessonSelect;
