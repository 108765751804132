import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faInfo,
  faExclamationTriangle,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';

import styles from './Toast.module.scss';
import { ToastTypes, ToastProps } from './types';

const Toast = ({ destroy, content, title, duration = 0, type, showIcon }: ToastProps) => {
  const toastIcon = useMemo(() => {
    let icon = faCheck;
    switch (type) {
      case ToastTypes.Informative: {
        icon = faInfo;
        break;
      }
      case ToastTypes.WARNING: {
        icon = faExclamationTriangle;
        break;
      }
      case ToastTypes.ERROR: {
        icon = faExclamation;
        break;
      }
      default:
        break;
    }
    return <FontAwesomeIcon fontWeight={900} icon={icon} />;
  }, [type]);

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <div>
      <div className={`${styles[type]} ${styles.iconBox}`}>{showIcon && toastIcon}</div>

      <button className={styles.closeButton} onClick={destroy} type="button">
        X
      </button>

      <div className={styles.toastBody}>
        <div className={styles.toastTitle}>{title}</div>
        <div className={styles.toastContent}>{content}</div>
      </div>
    </div>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => prevProps.id === nextProps.id;

export default React.memo(Toast, shouldRerender);
