import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager, modalManagerPropTypes } from 'common/Modal';
import { ApolloProvider, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import FormModal from './FormModal';
import { initialValueShapeProps, modelVideoShapeProps, renderVideoSourceOptions } from './Utils';
import YoutubeEmbed from './YoutubeEmbed';
import useFlags from '../../../hooks/useFlags';

const MutationModal = ({ ...props }) => {
  const modalManager = props.modalManager || useModalManager();
  const [modelVideoMutation, { loading }] = useMutation(props.mutation);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [videoSource, setVideoSource] = useState('tci');

  const mutateModelVideo = (values) => {
    const variables = {
      variables: {
        autoplay: values.autoplay,
        id: props.modelVideo ? props.modelVideo.id : undefined,
        modelId: props.modelId,
        modelType: props.modelType,
        readOnly: props.fromButton,
        videoId: values.video_id
      }
    };

    modelVideoMutation(variables)
      .then((result) => {
        const resultKey = Object.keys(result.data)[0];
        props.afterSubmit(result.data[resultKey].result);
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setHasSubmitError(true);
      });
  };

  const canAddTCIVideos = ['Sysadmin', 'ContentManager'].includes(props.userType);

  useEffect(() => {
    if (props.initialValues.youtube_video_url || !canAddTCIVideos) {
      setVideoSource('youtube');
    }
    else if ((props.modelVideo && canAddTCIVideos) || canAddTCIVideos) {
      setVideoSource('tci');
    }
  }, []);

  const addVideoButton = () => (
    <button type="button" className="btn btn--green btn--sm mb5" onClick={modalManager.open}>
      {props.addButtonText}
    </button>
  );

  const { autoplayAudio } = useFlags();

  const renderVideoSource = () => {
    if (videoSource === 'tci') {
      return (
        <FormModal
          initialValues={props.initialValues}
          hasSubmitError={hasSubmitError}
          loading={loading}
          onSubmit={mutateModelVideo}
          modalBodyProps={{ showAutoplayOption: autoplayAudio }}
        />
      );
    }

    return (
      <YoutubeEmbed
        initialValues={props.initialValues}
        loading={!!props.youtubeSubmitLoading}
        onSubmit={props.onYoutubeSubmit}
      />
    );
  };

  const modalContent = () => {
    return (
      <>
        {(canAddTCIVideos && props.showYoutubeOption) && renderVideoSourceOptions(videoSource, setVideoSource)}
        {renderVideoSource()}
      </>
    );
  };

  return (
    <ApolloProvider client={apolloClient}>
      {props.standaloneButton && addVideoButton()}
      <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText={props.headerText}>
        {modalContent()}
      </Modal>
    </ApolloProvider>
  );
};

MutationModal.propTypes = {
  addButtonText: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
  fromButton: PropTypes.bool,
  headerText: PropTypes.string,
  initialValues: initialValueShapeProps,
  modalManager: modalManagerPropTypes,
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modelType: PropTypes.string.isRequired,
  modelVideo: modelVideoShapeProps,
  // eslint-disable-next-line react/forbid-prop-types
  mutation: PropTypes.object.isRequired,
  showYoutubeOption: PropTypes.bool,
  standaloneButton: PropTypes.bool,
  userType: PropTypes.string,
};

MutationModal.defaultProps = {
  addButtonText: 'Add Video',
  fromButton: false,
  headerText: 'Add Video',
  initialValues: {},
  modalManager: null,
  modelId: undefined,
  modelVideo: undefined,
  showYoutubeOption: true,
  standaloneButton: true,
  userType: '',
};

export default MutationModal;
