import React from 'react';
import Modal, { Footer } from 'components/common/Modal';
import I18n from 'i18n-js';
import { UnitType } from '@/components/shared/NewDashboard/types';
import TableOfContents from '@/components/shared/TableOfContents';

interface TableOfContentsModalProps {
  units: UnitType[];
  isOpen: boolean;
  closeModal: () => void;
}

const TableOfContentsModal = ({ units, isOpen, closeModal }: TableOfContentsModalProps) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    headerText={I18n.t('table_of_contents')}
    preventScroll
  >
    <TableOfContents units={units} />
    <Footer
      primaryButtonCallback={() => {
        closeModal();
      }}
      primaryButtonClassName="btn btn--purple"
      primaryButtonSubmit={false}
      primaryButtonText={I18n.t('close')}
    />
  </Modal>
);

export default TableOfContentsModal;
