import React from 'react';
import Modal, { useModalManager } from '../../../common/Modal';
import VocabularyGameForm from './VocabularyGameForm';
import DropdownLink from './DropdownLink';

interface AddVocabularyGameButtonProps {
  hasVocabGame: boolean;
}

const AddVocabularyGameButton = ({
  hasVocabGame,
}: AddVocabularyGameButtonProps) => {
  const modalManager = useModalManager();

  return (
    <>
      <DropdownLink
        disabledText="Only one vocabulary game is allowed per lesson."
        href="#"
        icon="#"
        isDisabled={hasVocabGame}
        text="Vocabulary Game"
        onClick={() => modalManager.open()}
      />
      <Modal
        closeModal={modalManager.close}
        headerText="Add Vocabulary Game"
        nearlyFullScreen
        primaryButtonText="Save"
        {...modalManager}
      >
        <VocabularyGameForm modalManager={modalManager} />
      </Modal>
    </>
  );
};

export default AddVocabularyGameButton;
