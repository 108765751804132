import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { required } from 'common/Forms/Validators';
import Footer from 'common/Wizard/Footer';

import styles from '../../../Form.module.scss';
import { CONFIGURATION_STEP } from '../../../../steps';

const FormContents = ({ handleSubmit, previous }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="public_key"
      validate={required}
    >
      {({ input, meta }) => (
        <div className={styles.row}>
          <div className={styles.fieldContainer}>
            <label htmlFor="public-key" className={styles.label}>
              Public SSH Key
            </label>

            <textarea
              id="public-key"
              className={`${styles.textInput} ${styles.textarea}`}
              {...input}
            />
          </div>

          {
            meta.error && meta.touched &&
            <div className={styles.fieldError}>{meta.error}</div>
          }
        </div>
      )}
    </Field>

    <Footer page={CONFIGURATION_STEP} previous={previous} />
  </form>
);

FormContents.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired
};

export default FormContents;
