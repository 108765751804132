import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';
import DropdownMenu from './DropdownMenu';

export const useDropdownEvents = (ref, isOpen, initialElement) => {
  const [open, setOpen] = useState(isOpen || false);
  const [currentElement, setCurrentElement] = useState(initialElement);

  const handleOutsideClick = (e) => {
    if (!(ref || ref.current)) return;
    if (ref.current.contains(e.target)) return;

    setOpen(false);
  };

  return { currentElement, handleOutsideClick, open, setCurrentElement, setOpen };
};

export const useDropdownEventsPropTypes = PropTypes.shape({
  handleOutsideClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
});

export const useClickOutside = (handler) => {
  useEffect(() => {
    document.addEventListener('click', handler, false);

    return () => document.removeEventListener('click', handler, false);
  }, []);
};

const Dropdown = ({
  ariaLabel, buttonClass, children, containerClass, dropdownClass,
  dropdownPosition, render, toggleText, isOpen, hasButton,
}) => {
  const ref = useRef();
  const { handleOutsideClick, open, setOpen } = useDropdownEvents(ref, isOpen);
  useClickOutside(handleOutsideClick);

  const renderChildren = () => {
    if (render) return render({ closeModal: () => setOpen(false), isOpen: open });

    return (
      React.Children.map(children, (child) => {
        if (!child) return child;
        if (child && !child.props['data-closeonclick']) return child;

        return React.cloneElement(child, {
          onClick: (e) => {
            if (child.props.onClick) child.props.onClick(e);

            setOpen(false);
          },
        });
      })
    );
  };

  return (
    <div ref={ref} className={`${styles.container} ${containerClass}`}>
      {hasButton && (
        <button
          type="button"
          className={`${buttonClass} ${styles.button}`}
          aria-label={ariaLabel}
          aria-haspopup="true"
          aria-expanded={open}
          onClick={() => setOpen(prev => !prev)}
        >
          {typeof toggleText === 'function' ? toggleText({ setOpen }) : toggleText}
        </button>
      )}
      <DropdownMenu open={open} position={dropdownPosition} className={dropdownClass}>
        {renderChildren()}
      </DropdownMenu>
    </div>
  );
};

Dropdown.defaultProps = {
  ariaLabel: '',
  buttonClass: 'btn btn--outline-purple dropdown-toggle',
  children: null,
  containerClass: '',
  dropdownClass: '',
  dropdownPosition: 'bottom',
  hasButton: true,
  isOpen: false,
  render: null,
  toggleText: 'Toggle',
};

Dropdown.propTypes = {
  ariaLabel: PropTypes.string,
  buttonClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  containerClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  dropdownPosition: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top-end',
    'bottom-start',
  ]),
  hasButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  render: PropTypes.func,
  toggleText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
};

export default Dropdown;
