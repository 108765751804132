import PropTypes from 'prop-types';
import React from 'react';

import styles from '../IntegrationsCards.module.scss';
import ConnectButton from '../ConnectButton';

const tooltipContent = `
This integration requires admin setup first.<br><a href="https://teachtci.com/canvas-integration">Learn More.</a>
`;

const UnconnectedCard = ({
  bodyText, Button, connectLMS, connectionText, enabled, headerText, icon, isBeta
}) => (
  <div className={`${styles.cardWrapper}`}>
    <div className={styles.cardHeader}>
      {headerText} {isBeta && <span className="beta-label">BETA</span>}
    </div>
    <div className={styles.cardBody}>
      <img alt="" className={styles.icon} src={icon} />
      <div className={styles.cardContent}>
        <div>{bodyText}</div>
        {Button ||
          (
            <ConnectButton
              enabled={enabled}
              onClick={connectLMS}
              styles="btn btn--purple"
              tooltipContent={tooltipContent}
              withIcon={false}
            >
              {connectionText}
            </ConnectButton>
          )
        }
      </div>
    </div>
  </div>
);

UnconnectedCard.propTypes = {
  Button: PropTypes.node,
  bodyText: PropTypes.string.isRequired,
  connectLMS: PropTypes.func,
  connectionText: PropTypes.string,
  enabled: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isBeta: PropTypes.bool,
};

UnconnectedCard.defaultProps = {
  Button: null,
  connectionText: '',
  connectLMS: () => {},
  enabled: true,
  isBeta: false,
};

export default UnconnectedCard;
