import React from 'react';
import { useModalManager } from 'components/common/Modal';
import EditContactAdminModal from './EditContactAdminModal';

interface EditContactAdminLinkProps {
  getContactAdminsPath: string;
  subscriberPath: string;
}

const EditContactAdminLink = ({ getContactAdminsPath, subscriberPath }: EditContactAdminLinkProps) => {
  const modalManager = useModalManager();

  return (
    <>
      <a href="#" onClick={modalManager.open}>
        <i className="fa fa-pencil-square-o fa-fw mr5" />
        Edit Contact Admin Popup
      </a>
      <i
        aria-hidden="true"
        data-tippy="When teacher program access is controlled at the admin level, teachers are prompted to contact an admin for access. You can add a custom message in this popup."
        className="fa fa-question-circle ml5"
        onClick={e => e.preventDefault()}
      />

      {modalManager.isOpen &&
        <EditContactAdminModal
          getContactAdminsPath={getContactAdminsPath}
          modalManager={modalManager}
          subscriberPath={subscriberPath}
        />
      }
    </>
  );
}

export default EditContactAdminLink;
