import React from 'react';
import PropTypes from 'prop-types';

import AddImage from '../../../../../../ImageManager/Add';
import { slideObjectPropTypes } from '../../../../../Utils';
import { insertIntoEditor } from '../FroalaListeners';
import useSlideShowImageFetcher from '../../../../../Utils/useSlideShowImageFetcher.ts';
import useSlideShowContext from '../../../../../stores/useSlideShowContext';

const CustomImageModal = ({
  clearDefaultImage, editorInstance, fromButton, modalManager, pastedImage, slideObject,
}) => {
  const { fetchImages } = useSlideShowImageFetcher();
  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const locale = useSlideShowContext(state => state.locale);

  const imageTag = (imageModel) => {
    const { image: { imageUrl, imageEsUrl, descriptionEn, descriptionEs } } = imageModel;
    const src = locale === 'es' && imageEsUrl && !imageEsUrl.includes('missing.png') ? imageEsUrl : imageUrl;
    const description = locale === 'es' ? descriptionEs || descriptionEn : descriptionEn;

    return (
      <img
        alt={description || ''}
        className={fromButton ? '' : 'imageInTextObject'}
        data-snippet={imageModel.snippet}
        data-zoomurl={src}
        src={src}
      />
    );
  };

  const afterSave = (res) => {
    const imageModel = res.length ? res[0] : res;
    insertIntoEditor(editorInstance, imageTag(imageModel), true, false, fromButton);
  };

  return (
    <AddImage
      afterMergeDuplicates={afterSave}
      afterSubmit={afterSave}
      dontMergeCallback={afterSave}
      clearDefaultImage={clearDefaultImage}
      defaultImage={pastedImage}
      forFroala
      modalManager={modalManager}
      fromButton={fromButton}
      modelId={parseInt(slideObject.id, 10)}
      modelType="SlideObject"
      standaloneButton={false}
      getExistingImages={fetchImages}
      isAdmin={isAdmin}
      fromSlideShow
      excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
    />
  );
};

CustomImageModal.propTypes = {
  clearDefaultImage: PropTypes.func,
  editorInstance: PropTypes.object,
  fromButton: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  pastedImage: PropTypes.object,
  slideObject: slideObjectPropTypes.isRequired,
};

CustomImageModal.defaultProps = {
  clearDefaultImage: () => {},
  editorInstance: {},
  fromButton: false,
  pastedImage: null,
};

export default CustomImageModal;
