import React from 'react';
import { Field } from 'react-final-form';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';
import styles from './FormFields.module.scss';

const FormField = ({ htmlFor, label, required, ...fieldProps }) => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor={htmlFor} className={styles.label}>
        {label} {required && <RequiredAsterisk />}
      </label>
      <Field
        id={htmlFor}
        name={htmlFor}
        component="input"
        className={styles.textInput}
        required={required}
        {...fieldProps}
      />
    </div>
  </div>
);

export default FormField;
