import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import { Field } from 'react-final-form';
import styles from '@/components/admin/subscribers/StaffTable/StaffForm.module.scss';

const MultipleSessionsTooltipText = () => (
  <span>
    This should only be used for shared reviewer accounts.
    The user will be able to sign in on multiple browsers and devices at the same time.
  </span>
);

const AllowMultipleSessionsField = () => (
  <div className="mb15">
    <label htmlFor="allow-multiple-sessions" className={styles.label}>
      Allow multiple sessions
      <Tooltip content={<MultipleSessionsTooltipText />} theme="white">
        <div className="inline_block tw-ml-0.5">
          <i className="fa fa-question-circle" aria-hidden="true" />
          <span className="sr-only">More info</span>
        </div>
      </Tooltip>
      <i className="fa fa-lock tw-ml-1" aria-hidden="true" />
    </label>

    <Field
      id="allow-multiple-sessions"
      name="allow_multiple_sessions"
      component="input"
      type="checkbox"
    />
  </div>
);

export default AllowMultipleSessionsField;
