import React, { useRef } from 'react';
import Anchor from '@/components/ui/Anchor/Anchor';

interface GlossaryTermsPopoverProps {
  onDelete: () => void;
  targetElement: HTMLElement;
  editorInstance: any;
  glossaryTermsPopoverRef: any;
}

function GlossaryTermsPopover(
  { onDelete, targetElement, editorInstance, glossaryTermsPopoverRef }: GlossaryTermsPopoverProps,
) {
  const popoverRef = useRef(null);

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!editorInstance) {
      return;
    }

    editorInstance.undo.saveStep();
    onDelete();

    editorInstance.undo.saveStep();
    editorInstance.events.trigger('contentChanged');
  };

  const buttonClasses = 'tw-w-full tw-h-full tw-flex tw-items-center '
    + 'tw-justify-center tw-border-0 tw-bg-transparent tw-cursor-pointer';

  return (
    <Anchor
      to={targetElement}
      className="tw-flex tw-w-20 tw-h-10 tw-bg-white tw-rounded tw-shadow-lg"
      offset={{ top: 50 }}
      attachListenersTo={[document.querySelector('.fr-wrapper')]}
      ref={popoverRef}
    >
      <button className={buttonClasses} onMouseDown={handleDelete} type="button">
        <i className="fa fa-chain-broken" />
      </button>
      <button
        className={`tw-hidden ${buttonClasses}`}
        onMouseDown={() => {
          editorInstance.selection.save();
          glossaryTermsPopoverRef.current.open();
          popoverRef.current.close();
        }}
        type="button"
      >
        <i className="fa fa-edit" />
      </button>
      <div
        className="tw-absolute tw-w-0 tw-h-0 tw-left-1/2 tw--ml-1.5 tw-border-l-[6px] tw-border-r-[6px]
        tw-border-l-transparent tw-border-r-transparent tw-border-solid tw-bottom-[-6px] tw-border-t-[6px]
        tw-border-t-white"
      />
    </Anchor>
  );
}

export default GlossaryTermsPopover;
