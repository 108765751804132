import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { matchAttributesForMultipleRows } from 'common/DataTable/Utils';
import styles from './styles.module.scss';
import Tooltip from '../../common/Tooltip';
import DataTable from '../../common/DataTable';
import { renderProgramsCount, renderTeachersCount } from './utils';

const SyncResults = ({
  allClassrooms, syncResults
}) => {
  const [successClassrooms, setSuccessClassrooms] = useState([]);
  const [errorClassrooms, setErrorClassrooms] = useState([]);

  const columns = [
    'status',
    'name',
    'period',
    'term_name',
    'subjects',
    'grades',
    'teachers',
    'student_seats_count',
    'same_uid_classrooms'
  ];

  const errorIcon = (rowData) => {
    const errors = (
      <div>
        <p>{syncResults.errors[rowData.uid]}</p>
        <p className={styles.contactAdmin}>Contact your <b>admin</b> for assistance</p>
      </div>
    );

    return (
      <Tooltip
        content={errors}
      >
        <i className={`${styles.statusIcon} ${styles.error} fa fa-exclamation-circle`} />
      </Tooltip>
    );
  };

  const columnDefs = (kind) => {
    const defs = [{
      targets: 3,
      title: 'Term'
    },
    {
      createdCell: (td, cellData, rowData) => renderTeachersCount(td, cellData, rowData),
      targets: 6,
      title: 'Teachers'
    },
    {
      targets: 7,
      title: 'Students'
    },
    {
      createdCell: (td, cellData) => renderProgramsCount(td, cellData),
      targets: 8,
      title: 'Programs'
    }];

    const iconColDef = {
      createdCell: (td, cellData, rowData) => {
        let icon;

        if (kind === 'success') {
          icon = <i className={`${styles.statusIcon} ${styles.success} fa fa-check-circle`} />;
        }
        else {
          icon = errorIcon(rowData);
        }

        ReactDOM.render(icon, td);
      },
      data: null,
      searchable: false,
      sortable: false,
      targets: 0,
      title: ''
    };

    defs.unshift(iconColDef);

    return defs;
  };

  const getSuccessClassrooms = () => {
    const successIds = syncResults.successful_classroom_ids;
    setSuccessClassrooms(allClassrooms.filter(classroom => successIds.includes(classroom.id)));
  };

  const getErrorClassrooms = () => {
    const errorUids = Object.keys(syncResults.errors);
    setErrorClassrooms(allClassrooms.filter(classroom => errorUids.includes(classroom.uid)));
  };

  useEffect(() => {
    matchAttributesForMultipleRows(allClassrooms, columns);

    getSuccessClassrooms();
    getErrorClassrooms();
  }, []);

  const successTable = () => {
    if (!successClassrooms || successClassrooms.length === 0) return;

    return (
      <span>
        <span>Classes that were linked successfully</span>
        <DataTable
          columns={columns}
          columnDefs={columnDefs('success')}
          defaultOrder={[1, 'asc']}
          handleResourcesManually
          resources={successClassrooms}
        />
      </span>
    );
  };

  const errorTable = () => {
    if (!errorClassrooms || errorClassrooms.length === 0) return;

    return (
      <span>
        <span>
          Classes that could not be linked
          (Hover over <i className={`${styles.error} fa fa-exclamation-circle`} /> for details)
        </span>
        <DataTable
          columns={columns}
          columnDefs={columnDefs('error')}
          defaultOrder={[1, 'asc']}
          handleResourcesManually
          resources={errorClassrooms}
        />
      </span>
    );
  };

  return (
    <span>
      {successTable()}
      {errorTable()}
    </span>
  );
};

SyncResults.propTypes = {
  allClassrooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      uid: PropTypes.string
    }).isRequired
  ).isRequired,
  syncResults: PropTypes.shape({
    errors: PropTypes.shape({}),
    successful_classroom_ids: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
};

export default SyncResults;
