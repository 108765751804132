import React, { useState, useEffect } from 'react';
import { YOUTUBE_LINK_TEST_REGEX } from '../../../../../../../../../../components/admin/VideoManager/Utils';
import styles from '../../ElementInsert.module.scss';

interface YoutubeURLInputProps {
  enteredURL: string,
  handleURLChange: (param) => void,
}

const YoutubeURLInput = ({ enteredURL, handleURLChange } : YoutubeURLInputProps) => {
  const [error, setError] = useState('')
  const [initialValue, setInitialValue] = useState(enteredURL)

  useEffect(() => {
    if (enteredURL && enteredURL.includes('<iframe')) {
      const $iframeHTML = $(enteredURL)
      const videoURL = $iframeHTML.attr('src');
      setInitialValue(videoURL)
    }
  }, [])


  useEffect(() => {
    if (enteredURL && !YOUTUBE_LINK_TEST_REGEX.test(enteredURL)) {
      setError('Invalid URL');
      return;
    }

    setError('');
  }, [enteredURL]);

  const validateURL = event => handleURLChange(event.target.value.trim());

  return (
    <span>
      <label className={`${styles.required} ${styles.labelText} hint-text`} htmlFor="youtubeURL">
        Youtube Video URL
      </label>
      <input
        className={`${styles.modalField} ${styles.videoSelect}`}
        defaultValue={initialValue}
        id="youtubeURL"
        onChange={validateURL}
      />
      <span>{error}</span>
    </span>
  )
}

export default YoutubeURLInput;
