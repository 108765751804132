import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../Navigator.module.scss';
import Tooltip from '../../../../../../common/Tooltip';

const FullScreen = ({ fullScreenRef }) => {
  const [inFullScreen, setIsFullScreen] = useState(false);

  const checkFullScreen = () => {
    if (document.fullscreenElement === null) {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    $('body').on('fullscreenchange', checkFullScreen);
    return () => $('body').off('fullscreenchange', checkFullScreen);
  }, [inFullScreen]);

  const requestFullScreen = (node) => {
    if (node.requestFullscreen) node.requestFullscreen();
    else if (node.webkitRequestFullscreen) node.webkitRequestFullscreen();

    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();

    setIsFullScreen(false);
  };

  const onClick = () => {
    if (!(fullScreenRef && fullScreenRef.current)) return;

    const { current } = fullScreenRef;

    if (inFullScreen) exitFullScreen();
    else requestFullScreen(current);
  };

  if (!(fullScreenRef && fullScreenRef.current)) return null;

  const fullScreenText = () => (inFullScreen ? 'Exit Full Screen' : 'Enter Full Screen');

  return (
    <div className={`${styles.dropdownItem} ${styles.flexSpaceBetween}`}>
      <div>{fullScreenText()}</div>
      <Tooltip
        appendTo={fullScreenRef.current}
        content={fullScreenText()}
        interactive={false}
      >
        <button className={styles.fullScreenButton} onClick={onClick} type="button">
          <i className={inFullScreen ? 'fa fa-compress' : 'fa fa-expand'} />
        </button>
      </Tooltip>
    </div>
  );
};

FullScreen.defaultProps = {
  fullScreenRef: null,
};

export const FullScreenPropTypes = PropTypes.shape({
  fullScreenRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ])
});

FullScreen.propTypes = FullScreenPropTypes.isRequired;

export default FullScreen;
