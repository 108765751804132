import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { useForm, FormProvider, useFormContext, useFieldArray } from 'react-hook-form';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import EditImageModal, { EditImageModalProps } from './EditImageModal';
import useEditImageModal from './useEditImageModal';
import {ImageFormData} from '@/components/admin/Images/interfaces';

export { useEditImageModal };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  },
});

const EditImageModalWrapper = (props: EditImageModalProps) => {
  const { image } = props;

  const methods = useForm<ImageFormData>({
    defaultValues: {
      credits: image.credits,
      description_en: image.description_en,
      description_es: image.description_es,
      description_confidence: image.description_confidence,
      english_image_only: image.english_image_only,
      has_text: image.has_text,
      title_en: image.title_en,
      title_es: image.title_es,
    },
  });

  return (
    <FormProvider {...methods}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <EditImageModal {...props} />
      </QueryClientProvider>
    </FormProvider>
  );
};

export default EditImageModalWrapper;
