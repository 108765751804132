import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import styles from '../CustomFroalaCommands/ElementInsert/ElementInsert.module.scss';
import adStyles from './AnchoredDropdown.module.scss';

const elementStyle = (element: HTMLDivElement) => {
  const { style: { top, right, bottom, left, width } } = element;
  if (!(top || right || bottom || left)) return { top: '', left: '', bottom: '', right: '', width };

  return { top, right, bottom, left, width };
};

export const getDefaultValue = (element) => {
  const { style: { top, right, bottom, left } } = element;

  if (top === '0%' && left === '0%') return '0 0';
  else if (top === '0%' && right === '0%') return '100 0';
  else if (bottom === '0%' && left === '0%') return '0 100';
  else if (bottom === '0%' && right === '0%') return '100 100';
  else if (left.includes('px')) return 'custom';

  return '100 100';
};

export const getInitialSliderPosition = (element, containerRef, attr) => {
  if (element.style[attr].includes('px')) {
    if (attr === 'left') {
      const { offsetWidth } = containerRef.current;
      const leftPixels = parseInt(element.style.left.replace('px', ''), 10);

      return (Math.round((leftPixels / (offsetWidth - 290)) * 100)).toString();
    }

    const { offsetHeight } = containerRef.current;
    const topPixels = parseInt(element.style[attr].replace('px', ''), 10);
    return (Math.round((topPixels / (offsetHeight - element.offsetHeight - 50)) * 100)).toString();
  }

  return '100';
};

export const resetElementStyle = (element) => {
  element.style.left = '';
  element.style.top = '';
  element.style.bottom = '';
  element.style.right = '';
};

export function DisplayTooltip({ element, containerRef, isHtmlElement }) {
  const defaultValue = getDefaultValue(element);
  const useInlineStyle = defaultValue !== '100 100';
  const style: CSSProperties = {
    display: 'inline',
    position: 'absolute',
    opacity: '.5',
    ...elementStyle(element),
  };

  return ReactDOM.createPortal(
    <div id="display-tooltip" className={adStyles.myClass}>
      <div className="fr-view">
        <span
          className={`slide-popup hint-text ${isHtmlElement ? styles.htmlElement : styles.slidePopup} ${(isHtmlElement || useInlineStyle) && styles.inlineStyles}`}
          style={style}
        >
          <div className={styles.slideTooltipWrapper}>
            <span dangerouslySetInnerHTML={{ __html: element.innerHTML }} />
          </div>
          <i
            className={`fa fa-times-circle popup-close-btn ${styles.slidePopupCloseBtn}`}
            role="button"
            tabIndex={0}
          />
        </span>
      </div>
    </div>,
    containerRef.current,
  );
}
