import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

type SlideShow = {
  slides: {
    id: number;
    position: number;
    slideObjects: { index: number }[]
  }[];
};

type Data = { slideShow: SlideShow } | undefined;

const ALLOWED_USER_TYPES: UserType[] = ['Sysadmin'];

const useDuplicateIndicesReporter = (data: Data, previousData: Data, userType: UserType) => {
  useEffect(() => {
    if (!data) return;
    if (!previousData) return;
    if (!ALLOWED_USER_TYPES.includes(userType)) return;

    data.slideShow.slides.forEach((slide) => {
      const positions = slide.slideObjects.map(slideObject => slideObject.index);
      const previousSlideData = previousData.slideShow.slides.find(prevSlide => prevSlide.id === slide.id);

      if (!previousSlideData) return;

      const previousPositions = previousSlideData.slideObjects.map(so => so.index);

      // skip logging if the previous version of the data already had duplicate indices
      if (previousPositions.length !== [...new Set(previousPositions)].length) return;

      if (positions.length === [...new Set(positions)].length) return;

      const error = new Error();

      error.name = 'Duplicate slide object indices';
      error.message = `for ${slide.id}`;

      Sentry.captureException(error, { tags: { component: 'SlideBuilder' } });
    });
  }, [data, previousData, userType]);
};

export default useDuplicateIndicesReporter;
