import React from 'react';
import Axios from 'axios';
import { ConfirmModal, useModalManager } from '@/components/common/Modal';
import * as Routes from '@/modules/routes';
import showToast from '@/components/common/Toast';
import styles from './SysadminDropdown.module.scss';

interface ArchiveSysadminProps {
  stafferEmail: string;
  stafferId: number;
  updateTable?: ({ updatedStaff: any }) => void;
}

const ArchiveSysadmin = ({ stafferEmail, stafferId, updateTable }: ArchiveSysadminProps) => {
  const modalManager = useModalManager();
  const archivePath = Routes.plato_api_archive_sysadmin_staffer_path(stafferId);

  const archiveSysadmin = () => {
    Axios.post(archivePath)
      .then((res) => {
        if (updateTable) updateTable({ updatedStaff: res.data.staffer });
        showToast(`Sysadmin ${stafferEmail} archived successfully`, { autoClose: 10000 });
      })
      .catch((error) => {
        showToast(`Failed to archive sysadmin ${stafferEmail}`, { autoClose: 10000, msgType: 'error' });
        console.log(error);
      })
      .finally(() => {
        modalManager.close();
      });
  };

  return (
    <>
      <button
        id="archive-button"
        className={`dropdown-item ${styles.dropdownButton}`}
        type="button"
        onClick={modalManager.open}
      >
        <i aria-hidden="true" className="fa fa-archive" />
        {' '}
        Archive
      </button>
      <ConfirmModal
        isLoading={false}
        isOpen={modalManager.isOpen}
        onCancel={modalManager.close}
        onConfirm={archiveSysadmin}
        headerText="Archive Sysadmin"
        confirmationText={`Are you sure you want to archive ${stafferEmail}?`}
        confirmButtonText="Archive"
      />
    </>
  );
};

export default ArchiveSysadmin;
