import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { closeOnEscapeKey } from 'common/Utils';
import useKeydownAndClick from '../../../hooks/useKeydownAndClick';
import styles from './Drawer.module.scss';

const Drawer = ({
  buttonClass,
  buttonText,
  children,
  contentClass,
  count,
  drawerClass,
  headerClass,
  hidden,
  title,
  position
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const renderCounter = () => {
    if (typeof count === 'number') return `(${count})`;
  };

  closeOnEscapeKey(close);

  return (
    <div className={styles.container} {...{ ...(hidden && { style: { display: 'none' } }) }}>
      {['right'].includes(position) && <div className={styles.shroud} />}
      <button
        aria-label={title ?? buttonText}
        className={buttonClass}
        type="button"
        {...useKeydownAndClick(open)}
      >
        {buttonText} {renderCounter()}
      </button>
      <div
        aria-hidden={!isOpen}
        className={`${styles.drawer} ${styles[position]} ${drawerClass} ${isOpen ? styles.open : ''}`}
      >
        <div className={`${styles.header} ${headerClass}`}>
          <h2 className={styles.title}>{title} {renderCounter()}</h2>
          <button
            className={styles.closeBtn}
            type="button"
            {...useKeydownAndClick(close)}
          >
            ✕
          </button>
        </div>
        <div className={`${styles.content} ${contentClass}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

Drawer.defaultProps = {
  buttonClass: '',
  buttonText: 'Click Me',
  contentClass: '',
  drawerClass: '',
  headerClass: '',
  hidden: false,
  position: 'bottom',
  title: '',
};

Drawer.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  contentClass: PropTypes.string,
  count: PropTypes.number,
  drawerClass: PropTypes.string,
  headerClass: PropTypes.string,
  hidden: PropTypes.bool,
  position: PropTypes.oneOf([
    'bottom',
    'right',
  ]),
  title: PropTypes.string,
};

export default Drawer;
