import React from 'react';
import I18n from 'modules/i18n';

interface HardHatIconProps {
  ariaLabel?: string;
  color?: string;
  size?: number
}

const HardHatIcon = ({
  ariaLabel = I18n.t('library_career_profiles'),
  color = '#247F99',
  size = 60
 }: HardHatIconProps) => (
  <svg
    aria-label={ariaLabel}
    width={size}
    height={size}
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M38.1053 19.8947C38.1053 35.0432 15.3684 35.0432 15.3684 19.8947H21.0526C21.0526 27.4832 32.4211 27.4832 32.4211 19.8947M49.4737 45.4737V54H4V45.4737C4 37.8853 19.1484 34.1053 26.7368 34.1053C34.3253 34.1053 49.4737 37.8853 49.4737 45.4737ZM44.0737 45.4737C44.0737 43.6547 35.1779 39.5053 26.7368 39.5053C18.2958 39.5053 9.4 43.6547 9.4 45.4737V48.6H44.0737M28.1579 0C28.9537 0 29.5789 0.625263 29.5789 1.42105V9.94737H32.4211V2.84211C34.4324 3.77296 36.1176 5.28686 37.2579 7.18736C38.3982 9.08785 38.941 11.2872 38.8158 13.5C38.8158 13.5 40.8053 13.8979 40.9474 17.0526H12.5263C12.5263 13.8979 14.6579 13.5 14.6579 13.5C14.5327 11.2872 15.0755 9.08785 16.2158 7.18736C17.3561 5.28686 19.0412 3.77296 21.0526 2.84211V9.94737H23.8947V1.42105C23.8947 0.625263 24.52 0 25.3158 0" fill={color} />
  </svg>
);

export default HardHatIcon;
