/* eslint-disable no-param-reassign */

import * as fabric from 'fabric';

const ARROW_KEYS = {
  ArrowDown: ['top', 5],
  ArrowRight: ['left', 5],
  ArrowUp: ['top', -5],
  ArrowLeft: ['left', -5],
};

let timer: NodeJS.Timeout;

const metaOrCtrlKey = (e: KeyboardEvent) => e.metaKey || e.ctrlKey;

export const handleDuplicateKeys = (e: KeyboardEvent, duplicateObject: () => void) => {
  if (metaOrCtrlKey(e) && e.key === 'd') {
    duplicateObject();
  }
};

export const handleCopyPasteKeys = (
  e: KeyboardEvent,
  copy: () => void,
  paste: () => void,
) => {
  if (metaOrCtrlKey(e) && e.key === 'c') {
    copy();
  }

  if (metaOrCtrlKey(e) && e.key === 'v') {
    paste();
  }
};

const handleCutKeys = (
  e: KeyboardEvent,
  cut: () => void,
) => {
  if (metaOrCtrlKey(e) && e.key === 'x') {
    cut();
  }
};

const handleHelpKeys = (
  e: KeyboardEvent,
  toggleHelpMenuOpen: () => void,
) => {
  if (metaOrCtrlKey(e) && e.key === '/') {
    e.preventDefault();
    toggleHelpMenuOpen();
  }
};

// Fire off object modified event to save history.
const debounceMove = (canvas, timeout = 800) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    canvas.fire('object:modified');
  }, timeout);
};

const moveObject = (canvas: fabric.Canvas, object: fabric.Object, key: string) => {
  if (ARROW_KEYS[key]) {
    const property = ARROW_KEYS[key][0];
    const offset = ARROW_KEYS[key][1];

    object[property] += offset;
    object.setCoords();

    debounceMove(canvas);
    canvas.requestRenderAll();
  }
};

export const handleArrowKeys = (
  canvas: fabric.Canvas,
  e: KeyboardEvent,
  setCoordinates: (coordinates: { object: fabric.Object, canvas: fabric.Canvas }) => void
) => {
  const activeObject = canvas.getActiveObject();

  if (activeObject) {
    e.preventDefault();
    moveObject(canvas, activeObject, e.key);
    setCoordinates({ object: activeObject, canvas });
  }
};

export const handleUndoRedo = (
  e: KeyboardEvent, undo: () => void, redo: () => void,
) => {
  if (metaOrCtrlKey(e) && e.key.toLowerCase() === 'z') {
    e.shiftKey ? redo() : undo();
  }
};

export const handleDeleteKeys = (e: KeyboardEvent, deleteObject: () => void) => {
  if ((e.key === 'Backspace' || e.key === 'Delete') && (e.target as HTMLElement).tagName !== 'INPUT') {
    deleteObject();
  }
};

interface UseKeyboardControlsProps {
  canvas: fabric.Canvas,
  copy: (offset?: boolean) => void,
  cut: () => void,
  deleteObject: () => void,
  duplicateObject: () => void,
  e: KeyboardEvent,
  paste: () => void,
  redo: () => void,
  setCoordinates: (coordinates: { object: fabric.Object, canvas: fabric.Canvas }) => void,
  toggleHelpMenuOpen: () => void,
  undo: () => void;
}

const useKeyboardControls = ({
  canvas,
  e,
  copy,
  cut,
  paste,
  undo,
  redo,
  deleteObject,
  duplicateObject,
  setCoordinates,
  toggleHelpMenuOpen,
}: UseKeyboardControlsProps) => {
  const activeObject = canvas?.getActiveObject();

  if (activeObject?.isType(fabric.IText.type) && (activeObject as fabric.IText).isEditing) {
    return;
  }

  handleDuplicateKeys(e, duplicateObject);
  handleArrowKeys(canvas, e, setCoordinates);
  handleUndoRedo(e, undo, redo);
  handleDeleteKeys(e, deleteObject);
  handleCopyPasteKeys(e, copy, paste);
  handleCutKeys(e, cut);
  handleHelpKeys(e, toggleHelpMenuOpen);
};

export default useKeyboardControls;
