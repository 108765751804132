import React, { useState } from 'react';
import styles from '@/components/admin/SlideShow/Slide/ContentActions/ContentActions.module.scss';

export type Data = {
  width: number;
  height: number;
};

type ImageSizeEditorProps = {
  initialHeight?: number;
  initialWidth?: number;
  onChange: (data: Data) => void;
  onSubmit: (data: Data) => Promise<any>;
};

const ImageSizeEditor = ({ initialHeight, initialWidth, onChange, onSubmit }: ImageSizeEditorProps) => {
  const [height, setHeight] = useState<number>(initialHeight);
  const [width, setWidth] = useState<number>(initialWidth);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const float = (value) => {
    return typeof value === 'string' ? parseFloat(value) : value;
  };

  const valueFromInput = (value) => {
    return Math.max(0, parseFloat(value || '0'));
  };

  return (
    <div className={`tw-w-[250px] ${styles.container} ${styles.paddingContainer}`}>
      <div className={styles.contentWrapper}>
        <div className={styles.paddingWrapper}>
          <div className={styles.paddingInner}>
            <label htmlFor="width">Width</label>
            <input
              className={styles.paddingInput}
              name="width"
              onChange={(e) => {
                const newValue = valueFromInput(e.target.value);
                setWidth(newValue);
                onChange({ width: newValue, height });
              }}
              placeholder="auto"
              value={width || ''}
            />
          </div>
          <span>{width != null ? 'px' : ''}</span>
        </div>
        <div className={styles.paddingWrapper}>
          <div className={styles.paddingInner}>
            <label htmlFor="height">Height</label>
            <input
              className={styles.paddingInput}
              name="height"
              onChange={(e) => {
                const newValue = valueFromInput(e.target.value);
                setHeight(newValue);
                onChange({ width, height: newValue });
              }}
              placeholder="auto"
              value={height || ''}
            />
          </div>
          <span>{height != null ? 'px' : ''}</span>
        </div>
      </div>
      <div className={`tw-float-right ${styles.btnWrapper}`}>
        <button
          className={`tw-text-base !tw-p-4 !tw-m-0 ${styles.submitBtn}`}
          disabled={isSubmitting}
          type="button"
          onClick={() => {
            setIsSubmitting(true);
            onSubmit({
              width: float(width),
              height: float(height)
            }).then(() => setIsSubmitting(false));
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default ImageSizeEditor;
