import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NotebookGrid from 'modules/grid/NotebookGrid';
import styles from './TablePromptAnswerKeyContent.module.scss';

const GridAnswerTable = ({ gridData, tableId, notebookGrid, setNotebookGrid, visible }) => {
  const tableRef = useRef();
  const hasGridData = Object.keys(gridData).length > 0;

  useEffect(() => {
    if (!tableRef.current || !hasGridData || (notebookGrid && notebookGrid.hasInvokedInit)) return;

    const grid = new NotebookGrid({
      height: gridData.height,
      id: tableId,
      read_only: false,
      show_update: false,
      teacher: true,
      gridData: { ...gridData },
      editingStyles: { color: '#247f99' }
    });
    grid.init();
    setNotebookGrid(grid);
  }, [tableRef.current, gridData, notebookGrid, setNotebookGrid]);

  if (!hasGridData) return null;

  return (
    <div
      ref={tableRef}
      id={tableId}
      className={`notebook-grid-wrapper question-interactable ${styles.gridAnswerTableNotebookGridWrapper} ${visible ? '' : styles.hidden}`}
      data-height={gridData.height}
      data-ignore="1"
    />
  );
};

GridAnswerTable.propTypes = {
  tableId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  notebookGrid: PropTypes.object,
  setNotebookGrid: PropTypes.func.isRequired,
  gridData: PropTypes.shape({
    chunk: PropTypes.string,
    rows: PropTypes.number,
    columns: PropTypes.number,
    col_ratios: PropTypes.string,
    height: PropTypes.number,
  }),
};

export default GridAnswerTable;
