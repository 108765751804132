import React from 'react';
import styles from './BetaFeatures.module.scss';

const ComingSoon = ({ displayNewsletter = true }: { displayNewsletter?: boolean }) => (
  <div>
    <p>
      Welcome to TCI&apos;s opt-in features! As we continually enhance our product,
      this page allows you to turn on
      or turn off features early in development and share your feedback along the way.
    </p>

    <hr />
    {displayNewsletter && (
      <div className={styles.featuresContainer}>
        <p>
          Check back soon! Our team is hard at work.
          Sign up for our newsletter to be the first to hear about new releases!
        </p>
        <a
          type="button"
          className="btn btn--purple"
          href="https://go.teachtci.com/TCI-newsletter-sign-up"
        >
          Sign Up For Our Newsletter
        </a>
      </div>
    )}
  </div>
);

export default ComingSoon;
