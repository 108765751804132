import React, { useContext } from 'react';
import DndAnswerContext from 'components/common/Context';
import TTSButton from 'components/shared/TextToSpeechButton';
import styles from './DraggableChoicesBank.module.scss';

interface QuestionBodyProps {
  ttsEnabled: boolean;
}

const QuestionBody = ({ ttsEnabled }: QuestionBodyProps) => {
  const {
    locale,
    questionId,
    questionBody,
  } = useContext(DndAnswerContext);

  return (
    <div className={styles.questionBody}>
      <label htmlFor={`question_${questionId}`}>
        <span
          className="respect_breaks"
          dangerouslySetInnerHTML={{ __html: questionBody}}
          id={`question_${questionId}_body`}
        />
      </label>
      {ttsEnabled && (
        <div className="inline">
          <TTSButton
            locale={locale}
            idToRead={`question_${questionId}_body`}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionBody;
