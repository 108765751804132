import { Canvas, FabricObject } from 'fabric';
import create from 'zustand';

const ACTION_BAR_WIDTH = 125;
const ACTION_BAR_HEIGHT = 40;
const OFFSET = 10;
const BOTTOM_OFFSET = OFFSET * 4;

const getOutOfBounds = (coordinates: { left: number, top: number }, canvas: Canvas) => {
  const elementCoordinates = canvas.getElement().getBoundingClientRect();

  return ({
    left: coordinates.left < elementCoordinates.left,
    right: coordinates.left + ACTION_BAR_WIDTH > elementCoordinates.right,
    top: coordinates.top < elementCoordinates.top,
  });
};

const getCoordinates = (selected: FabricObject, canvas: Canvas | undefined) => {
  if (!canvas) return undefined;

  const elementCoordinates = canvas.getElement().getBoundingClientRect();
  const rect = selected.getBoundingRect();

  let top = elementCoordinates.y + (rect.top * canvas.getZoom()) - ACTION_BAR_HEIGHT - OFFSET;
  let left = elementCoordinates.x
    + (rect.left * canvas.getZoom())
    + (rect.width / 2 * canvas.getZoom())
    - (ACTION_BAR_WIDTH / 2);

  const outOfBounds = getOutOfBounds({ left, top }, canvas);

  if (outOfBounds.top) top = elementCoordinates.y + ((rect.top + rect.height) * canvas.getZoom()) + BOTTOM_OFFSET;
  if (outOfBounds.left) left = elementCoordinates.left + OFFSET;
  if (outOfBounds.right) left = elementCoordinates.right - ACTION_BAR_WIDTH - OFFSET;

  return { top, left };
};

interface UseActionBarStore {
  clearCoordinates: () => void;
  coordinates?: { left: number, top: number };
  setCoordinates: ({ object, canvas }: { object: FabricObject, canvas: Canvas }) => void;
  actionBarElement: HTMLDivElement;
  setActionBarElement: (element: HTMLDivElement) => void;
}

const useActionBarStore = create<UseActionBarStore>(set => ({
  actionBarElement: null,
  clearCoordinates: () => set(state => ({ ...state, coordinates: undefined })),
  coordinates: undefined,
  setCoordinates: ({ object, canvas }) => set(state => ({ ...state, coordinates: getCoordinates(object, canvas) })),
  setActionBarElement: actionBarElement => set(state => ({ ...state, actionBarElement })),
}));

export default useActionBarStore;
