import React from 'react';
import I18n from 'i18n-js';
import axios, { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useForm, useFormContext } from 'react-hook-form';
import Modal from '@/components/ui/Modal';
import { SchemaType, useVocabDecksStore } from '@/components/common/VocabularyCards/variables';
import IGlossaryTerm from '@/components/interfaces/GlossaryTerm';
import { plato_api_glossary_term_path } from '@/modules/routes';
import { useModalManager } from '@/components/common/Modal';
import ImageDisplay from '@/components/common/VocabularyCards/Forms/ImageDisplay';
import useDeck from '@/components/common/VocabularyCards/hooks/useDeck';

interface IImageFileSchema {
  glossary_term: { glossary_term_image: FileList };
}

const useAddImageMutation = (id: number, onSuccess: (res: AxiosResponse<{ data: IGlossaryTerm }>) => void) => (
  useMutation({
    mutationFn: (formData: FormData) => axios
      .put<{ data: IGlossaryTerm }>(plato_api_glossary_term_path(id),
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }),
    onSuccess,
  })
);

interface TermImageInputProps {
  index: number;
}

const TermImageInput = ({ index }: TermImageInputProps) => {
  const { setValue, watch } = useFormContext<SchemaType>();
  const { formState, handleSubmit, register } = useForm<IImageFileSchema>();
  const { isOpen, open, close } = useModalManager();
  const deckId = useVocabDecksStore(state => state.deckId);
  const { data: deck } = useDeck(deckId);

  // watch values to re-render
  const s3Url = watch(`deck.glossary_term_models.${index}.glossary_term.s3_url`);
  const id = deck.glossary_term_models[index]?.glossary_term?.id;
  const definition = deck.glossary_term_models[index]?.glossary_term?.definition;

  const { isLoading: isAddImageLoading, mutate: addImageMutate } = useAddImageMutation(id, (res) => {
    setValue(`deck.glossary_term_models.${index}.glossary_term.s3_url`, res.data.data.s3_url);
    close();
  });

  const addImage = (data: IImageFileSchema) => {
    const formData = new FormData();
    formData.append('glossary_term[glossary_image]', data.glossary_term.glossary_term_image[0]);
    addImageMutate(formData);
  };

  if (s3Url && !s3Url.includes('missing')) {
    return <ImageDisplay id={id} index={index} s3Url={s3Url} definition={definition} />;
  }

  return (
    <div className="tw-flex tw-items-baseline tw-gap-6">
      <button
        className="btn btn--outline-purple"
        disabled={!id}
        onClick={open}
        type="button"
      >
        {I18n.t('add_image')}
      </button>
      <Modal className="tw-w-site" close={close} headerText={I18n.t('add_image')} isOpen={isOpen}>
        <figure className="tw-min-h-[275px] tw-max-h-[510px] tw-flex tw-flex-col tw-justify-center tw-items-center">
          <input
            accept="image/jpeg,image/png,"
            type="file"
            {...register('glossary_term.glossary_term_image', { required: true })}
          />
          <span className="tw-text-[11px] tw-pt-1.5">Image Requirement : .JPEG / .JPG / .PNG</span>
        </figure>
        <Modal.SubmitFooter
          close={close}
          isLoading={isAddImageLoading}
          isValid={formState.isValid}
          onSubmit={handleSubmit(addImage)}
          submittingText={I18n.t('saving')}
          submitText={I18n.t('save')}
        />
      </Modal>
    </div>
  );
};

export default TermImageInput;
