import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import styles from './NavigationCard.module.scss';
import LibraryLinks from './LibraryLinks';
import { NotebookLinks } from './NotebookLinks';
import { ApolloProvider } from '../ApolloClient';

const NavigationCard = ({ cardTitle, programId, lessonId, unitId, civicsLibraryEnabled, primarySourceLibraryEnabled }) => {
  const renderLinks = () => {
    if (cardTitle === 'libraries') {
      return (
        <LibraryLinks
          programId={programId}
          lessonId={lessonId}
          unitId={unitId}
          civicsLibraryEnabled={civicsLibraryEnabled}
          primarySourceLibraryEnabled={primarySourceLibraryEnabled}
        />
      );
    }

    if (cardTitle === 'unit_projects') {
      return (
        <ApolloProvider>
          <NotebookLinks
            programId={programId}
            unitId={unitId}
          />
        </ApolloProvider>
      );
    }

    return '';
  };

  return (
    <div className={`card disabled ${styles.navigationCard}`} role="group">
      <div className={styles.cardHeader}>
        <h2 className={styles.cardTitle}>{I18n.t(cardTitle)}</h2>
        <hr />
        {renderLinks()}
      </div>
    </div>
  );
};

NavigationCard.defaultProps = {
  civicsLibraryEnabled: false,
  primarySourceLibraryEnabled: false,
  unitId: 0
};

NavigationCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  civicsLibraryEnabled: PropTypes.bool,
  lessonId: PropTypes.number,
  primarySourceLibraryEnabled: PropTypes.bool,
  programId: PropTypes.number.isRequired,
  unitId: PropTypes.number,
};

export default NavigationCard;
