// put this in a reducer because the background image is an object,
// and we will likely be adding positioning to this in the future
import update from 'immutability-helper';

const setBackgroundPosition = (backgroundImage, { backgroundPosition: bgPosition }) => (
  update(backgroundImage, {
    $merge: { position: bgPosition }
  })
);

const setBackgroundSize = (backgroundImage, { backgroundSize: bgSize }) => (
  update(backgroundImage, {
    $merge: { size: bgSize }
  })
);

const backgroundReducer = (backgroundImage, { payload, type }) => {
  switch (type) {
    case 'SET_POSITION':
      return setBackgroundPosition(backgroundImage, payload);
    case 'SET':
      return payload.backgroundImage;
    case 'SET_SIZE':
      return setBackgroundSize(backgroundImage, payload);
    default:
      return backgroundImage;
  }
};

export default backgroundReducer;
