import React from 'react';
import styles from '../IntegrationsCards.module.scss';
import IntegrationStatus from './IntegrationStatus';

interface ConnectedLtiCardProps {
  headerText: 'Canvas LTI 1.3' | 'Schoology LTI 1.3',
  icon: string,
  programId: number;
}

const ConnectedLtiCard = ({
  headerText, icon, programId,
}: ConnectedLtiCardProps) => (
  <div className={`${styles.cardWrapper}`}>
    <div className={styles.cardHeader}>
      {headerText}
    </div>
    <div className={styles.cardBody}>
      <img alt="" className={styles.icon} src={icon} />
      <IntegrationStatus lmsType={headerText} programId={programId} />
    </div>
  </div>
);

export default ConnectedLtiCard;
