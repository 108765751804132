import React, { useMemo } from 'react';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import { HeaderCell } from '../Cells';
import useSlideShows from '../../hooks/queries/useSlideShows';
import SlideShowGradeCell from '../Cells/GradeCells/SlideShowGradeCell';

const useSlideShowColumns = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const { data: slideShows } = useSlideShows(classroom.id, lesson?.id);

  return useMemo(() => {
    if (!slideShows || slideShows.length === 0) return [];

    return (
      slideShows.filter(s => s.max_score > 0).map((slideShow) => {
        const accessor = (row: IStudentRow) => row.student;

        return columnHelper.accessor(accessor, {
          id: `slideShow-${slideShow.id}`,
          header: () => (
            <HeaderCell title={`Slideshow: ${slideShow.title}`} maxScore={slideShow.max_score}> </HeaderCell>
          ),
          cell: info => <SlideShowGradeCell slideshow={slideShow} student={info.getValue()} />,
        });
      })
    );
  }, []);
};

export default useSlideShowColumns;
