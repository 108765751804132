import { GradeByType } from '@/components/staffer/NotebookGradeByMenu/types';

export const gradingByAssignment = (classroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Assignment;
};

export const gradingByLesson = (classroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Lesson;
};

export const gradingBySection = (classroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Section;
};
