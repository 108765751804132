import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import FinalFormSelect from '../../../common/Forms/FinalFormSelect';
import { Footer } from '../../../common/Modal';
import { RequiredAsterisk } from '../../../common/Forms/Utils';
import StaticRow from '../StaticRow';
import styles from './ExchangeForm.module.scss';
import formStyles from '../../../common/Classes/Form.module.scss';
import SeatCountInput from '../SeatCountInput';
import {
  required, integer, nonNegative, composeValidators
} from '../../../common/Forms/Validators';
import { maxSeatsValidation } from '../Utils';
import TypeToConfirm from '../../../common/Forms/TypeToConfirm';
import showToast from 'common/Toast';

export default class ExchangeForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    exchangePath: PropTypes.string.isRequired,
    individualSubscriptionPath: PropTypes.string.isRequired,
    isSysadmin: PropTypes.bool.isRequired,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
      })
    ).isRequired,
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
      })
    ),
    programsPath: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      expires_on: PropTypes.string,
      id: PropTypes.number,
      license_name: PropTypes.string,
      program_full_title_with_edition: PropTypes.string,
      program_id: PropTypes.number,
      purchase_order_id: PropTypes.number,
      purchase_order_number: PropTypes.string,
      transfer_notes: PropTypes.string
    }).isRequired,
    staffersTablePath: PropTypes.string.isRequired,
    studentsTablePath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired
  };

  static defaultProps = {
    programs: [{ label: 'None', value: null }]
  };

  noSelectionPlaceholder = 'No License Selected';

  static renderProceedInput() {
    return (
      <div className={formStyles.row}>
        <div className={formStyles.fieldContainer}>
          <span className={formStyles.label}>
            Proceed? <RequiredAsterisk />
          </span>
          <TypeToConfirm
            className={`${styles.field} ${styles.proceedInput}`}
            confirmationString="Proceed"
            name="delete_confirmation"
          />
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      exchangeProgramTitle: '',
      programOptions: [],
      selectedProgram: null,
      showConfirmationPrompt: false,
      student_seats_available: 0,
      student_seats_in_use: 0,
      submitting: false,
      teacher_seats_available: 0,
      teacher_seats_in_use: 0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.formChangeValue = this.formChangeValue.bind(this);
    this.toggleConfirmationPrompt = this.toggleConfirmationPrompt.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.getSeatCounts();
    this.setState({
      exchangeProgramTitle: this.noSelectionPlaceholder,
      programOptions: this.props.licenses
    });
  }

  getSeatCounts() {
    return axios
      .get(this.props.individualSubscriptionPath.replace(':id', this.props.rowData.id))
      .then((response) => {
        const subscription = response.data.subscription;
        this.setState({
          student_seats_available: subscription.student_seats_available,
          student_seats_in_use: subscription.student_seats_in_use,
          teacher_seats_available: subscription.teacher_seats_available,
          teacher_seats_in_use: subscription.teacher_seats_in_use
        });
      })
      .catch(error => console.log(error));
  }

  handleSubmit(data) {
    this.setState({ submitting: true });

    return axios
      .post(this.props.exchangePath.replace(':id', this.props.rowData.id), data)
      .then((response) => {
        this.props.updateTable(response.data.data[0], 'add');
        this.props.updateTable(response.data.data[1], 'add');
        this.props.closeModal();
        showToast('Exchange Successful.');
      })
      .catch((error) => {
        this.setState({ submitting: false });
        showToast('Exchange Error.', { msgType: 'error' });
        console.log(error);
      });
  }

  formChangeValue(args, state, utils) {
    const value = args[0] ? args[0].value : null;
    const title = args[0] ? args[0].label : this.noSelectionPlaceholder;
    this.setState({ exchangeProgramTitle: title, selectedProgram: args[0] });
    const fieldName = 'exchange_license_id';
    utils.changeValue(state, fieldName, () => value);
  }

  toggleConfirmationPrompt() {
    this.setState({ showConfirmationPrompt: !this.state.showConfirmationPrompt });
  }

  validateForm(values) {
    const studentMaxSeatsValidation = maxSeatsValidation(this.state.student_seats_available, 'exchange');
    const teacherMaxSeatsValidation = maxSeatsValidation(this.state.teacher_seats_available, 'exchange');

    const studentSeatFieldValidator = composeValidators(required, integer, nonNegative, studentMaxSeatsValidation);
    const teacherSeatFieldValidator = composeValidators(required, integer, nonNegative, teacherMaxSeatsValidation);

    const errors = {};

    if (this.state.student_seats_available !== 0) {
      errors.student_seats_count = studentSeatFieldValidator(values.student_seats_count);
    }

    if (this.state.teacher_seats_available !== 0) {
      errors.teacher_seats_count = teacherSeatFieldValidator(values.teacher_seats_count);
    }

    if (values.student_seats_count === '0' && values.teacher_seats_count === '0') {
      errors.student_seats_count = 'Please enter at least 1 teacher or student license.';
      errors.teacher_seats_count = 'Please enter at least 1 teacher or student license.';
    }

    return errors;
  }

  notesPlaceholder() {
    if (this.props.isSysadmin) {
      return (
        'Please enter contact info for the customer who requested the exchange and provide any other additional info.'
      );
    }

    return null;
  }

  notesInitialValues() {
    if (this.props.isSysadmin) {
      return null;
    }

    return (
      `Original License: ${this.subscriptionTitle()}`
    );
  }

  subscriptionTitle() {
    return this.props.rowData.license_name;
  }

  renderSummaryBody(formProps) {
    return (
      <div className="mt10">
        Exchange
        {' '}
        <span className="bold">
          {formProps.values.teacher_seats_count || 'N/A'} teacher subs
        </span>
        {' '}
        and
        {' '}
        <span className="bold">
          {formProps.values.student_seats_count || 'N/A'} student subs
        </span>
        {' '}
        from
        {' '}
        <span className="bold">
          {this.subscriptionTitle()}
        </span>
        {' '}
        to
        {' '}
        <span className="bold">
          {this.state.exchangeProgramTitle}.
        </span>
        {' '}
        These will continue to expire
        {' '}
        {moment(this.props.rowData.expires_on, 'YYYY-MM-DD').format('MMM DD, YYYY')}.
      </div>
    );
  }

  renderSummary(formProps) {
    return (
      <div>
        <div className={formStyles.label}>Summary:</div>
        <div>{this.renderSummaryBody(formProps)}</div>
      </div>
    );
  }

  renderConfirmationPrompt(formProps) {
    const hasErrors = !Object.values(formProps.errors).every(elem => elem === undefined);

    return (
      <div className={styles.warnings}>
        <p className="bold mt10 mb20">
          To proceed, please type &quot;Proceed&quot; below.
        </p>
        {ExchangeForm.renderProceedInput()}
        <Footer
          primaryButtonDisabled={hasErrors}
          primaryButtonSubmit
          primaryButtonText="Submit"
          secondaryButtonCallback={this.toggleConfirmationPrompt}
          secondaryButtonText="Back"
          submitting={this.state.submitting}
          submittingText="Submitting..."
        />
      </div>
    );
  }

  renderStaticInfo() {
    return (
      <div>
        <StaticRow label="PO Number" data={this.props.rowData.purchase_order_number} />
        <StaticRow label="License" data={this.subscriptionTitle()} />
        <StaticRow
          label="Expires On"
          data={moment(this.props.rowData.expires_on, 'YYYY-MM-DD').format('MMM DD, YYYY')}
        />
      </div>
    );
  }

  programsPlaceholder() {
    if (this.state.programOptions.length === 0) {
      return 'None available';
    }

    return 'Search by Title or License Code';
  }

  renderExchangeProgramSelector(mutators) {
    return (
      <div className={formStyles.row}>
        <div className={formStyles.fieldContainer}>
          <div>
            <label htmlFor="exchangeProgramPicker" className={formStyles.label}>
              New License <RequiredAsterisk />
            </label>
          </div>
          <Field
            className={styles.exchangeProgramDropdown}
            component={FinalFormSelect}
            disabled={this.state.programOptions.length === 0}
            id="exchangeProgramPicker"
            name="exchange_license_id"
            noResultsText="None available"
            onChange={mutators.changeExchangeProgram}
            options={this.state.programOptions}
            placeholder={this.programsPlaceholder()}
            searchable
            selectValue={this.state.selectedProgram}
            validate={required}
          />
        </div>
      </div>
    );
  }

  renderSubsFields() {
    return (
      <div>
        <SeatCountInput
          actionName="exchange"
          id="teacher-seats"
          label="Teacher Subs"
          name="teacher_seats_count"
          programName={this.subscriptionTitle()}
          seatsAvailable={this.state.teacher_seats_available}
          seatsInUse={this.state.teacher_seats_in_use}
          tablePath={this.props.staffersTablePath}
          licenseName={this.props.rowData.license_name}
        />

        <SeatCountInput
          actionName="exchange"
          id="student-seats"
          label="Student Subs"
          name="student_seats_count"
          programName={this.subscriptionTitle()}
          seatsAvailable={this.state.student_seats_available}
          seatsInUse={this.state.student_seats_in_use}
          tablePath={this.props.studentsTablePath}
          licenseName={this.props.rowData.license_name}
        />
      </div>
    );
  }

  renderNotesField() {
    return (
      <div className={`${formStyles.row} ${styles.notesField}`}>
        <div className={`${formStyles.fieldContainer} ${styles.notesRowAlignment}`}>
          <div>
            <label className={`${formStyles.label} mt10`} htmlFor="notesField">
              Notes <RequiredAsterisk />
            </label>
          </div>
          <Field
            className={`${styles.notesField} ${styles.field}`}
            component="textarea"
            id="notesField"
            name="exchange_notes"
            placeholder={this.notesPlaceholder()}
            validate={required}
          />
        </div>
      </div>
    );
  }

  renderExchangeForm(formProps) {
    const hasErrors = !Object.values(formProps.errors).every(elem => elem === undefined);

    return (
      <span>
        {this.renderStaticInfo()}
        {this.renderExchangeProgramSelector(formProps.form.mutators)}
        {this.renderSubsFields()}
        {this.renderNotesField()}
        <br />
        {this.renderSummary(formProps)}
        <Footer
          primaryButtonCallback={this.toggleConfirmationPrompt}
          primaryButtonDisabled={hasErrors}
          primaryButtonSubmit={false}
          primaryButtonText="Next"
          secondaryButtonCallback={this.props.closeModal}
          secondaryButtonText="Cancel"
        />
      </span>
    );
  }

  renderFormContent(formProps) {
    if (this.state.showConfirmationPrompt) {
      return (this.renderConfirmationPrompt(formProps));
    }

    return (this.renderExchangeForm(formProps));
  }

  renderForm(formProps) {
    return (
      <form onSubmit={formProps.handleSubmit}>
        {this.renderFormContent(formProps)}
      </form>
    );
  }

  render() {
    return (
      <Form
        initialValues={{
          exchange_notes: this.notesInitialValues()
        }}
        mutators={{
          changeExchangeProgram: this.formChangeValue
        }}
        onSubmit={this.handleSubmit}
        validate={this.validateForm}
        render={this.renderForm}
      />
    );
  }
}
