import update from 'immutability-helper';
import getNextLetter from './getNextLetter';
import { getTextAreaNode, UNITS } from '../../utils';

const addTextArea = (textAreas, textArea = undefined) => {
  const nextLetter = getNextLetter(textAreas);

  const newTextArea = textArea || {
    [nextLetter]: {
      height: 5,
      left: 20,
      text: `Text area ${nextLetter}`,
      top: 12,
      type: 'textArea',
      width: 20
    },
  };

  return update(textAreas, {
    $merge: newTextArea
  });
};

const deleteTextArea = (textAreas, { id }) => update(textAreas, { $unset: [id] });

const updateTextArea = (textAreas, { height, id, left, text, top, width }) => (
  update(textAreas, {
    [id]: {
      $merge: {
        height,
        left,
        text,
        top,
        width
      }
    }
  })
);

const moveWithKey = (textAreas, { id, key, left, text, top, height, width }) => {
  switch (key) {
    case 'ArrowUp':
      return updateTextArea(textAreas, { height, id, left, text, top: top - 1, width });
    case 'ArrowDown':
      return updateTextArea(textAreas, { height, id, left, text, top: top + 1, width });
    case 'ArrowLeft':
      return updateTextArea(textAreas, { height, id, left: left - 1, text, top, width });
    case 'ArrowRight':
      return updateTextArea(textAreas, { height, id, left: left + 1, text, top, width });
    default:
      return textAreas;
  }
};

const addVocabTextArea = (textAreas, payload) => {
  const key = Object.keys(payload)[0];
  const newTextArea = Object.values(payload)[0];
  const currentTextArea = textAreas[key] || {};
  // When creating a new vocab text area AND the shape is already a circle, move the initial position down.
  const circleOffset = payload[key].shape !== 'circle' ? 0 : 10;

  if (newTextArea.vocabId === null) return deleteTextArea(textAreas, { id: key });

  // we don't want the new text area to overwrite existing coordinates or dimensions
  const persistentVals = {
    height: currentTextArea.height || newTextArea.height,
    left: currentTextArea.left || newTextArea.left,
    top: currentTextArea.top || (newTextArea.top + circleOffset),
    width: currentTextArea.width || newTextArea.width,
  };

  // so we store those and merge those in last
  const newPayload = {
    [key]: {
      ...currentTextArea,
      ...newTextArea,
      ...persistentVals,
    },
  };

  return addTextArea(textAreas, newPayload);
};

const spaceTextAreas = (textAreas, { shape }) => {
  const container = document.querySelector('section[aria-label="Drop Zones"]');
  const referenceRect = container.getBoundingClientRect();
  const { bottom: containerBottom, top: containerTop } = referenceRect;
  const ids = Object.keys(textAreas);

  const textAreasWithBottoms = ids.map((id) => {
    const dropzone = textAreas[id];
    const textarea = getTextAreaNode(id);
    const rect = textarea.getBoundingClientRect();

    const bottom = (rect.bottom - containerTop) / container.clientHeight * 100;

    return { ...dropzone, bottom };
  });

  const nextTextAreas = ids.reduce((acc, id, i) => {
    const nextId = ids[i + 1];

    const textarea1 = textAreasWithBottoms[i];

    const offset = shape === 'rectangle' ? UNITS.Y.THREE : UNITS.Y.CIRCLE_OFFSET;
    if (nextId === undefined) {
      if (textarea1.bottom < 100) return acc;

      const textarea = getTextAreaNode(id);
      const rect = textarea.getBoundingClientRect();
      const top = Math.floor((containerBottom - rect.height) / containerBottom * 100);

      acc[id] = { ...textAreasWithBottoms[i], top };

      return acc;
    }

    const textarea2 = textAreasWithBottoms[i + 1];

    const top = Math.ceil(textarea1.bottom + offset);

    const percentTopDiff = top - textarea2.top;

    textAreasWithBottoms[i + 1] = { ...textAreasWithBottoms[i + 1], bottom: textarea2.bottom + percentTopDiff };

    acc[nextId] = { ...textAreas[nextId], top };

    return acc;
  }, {});

  return update(textAreas, { $merge: nextTextAreas });
};

const textAreaReducer = (textAreas, { payload, type }) => {
  switch (type) {
    case 'ADD':
      return addTextArea(textAreas, payload);
    case 'ADD_VOCAB_TEXT_AREA':
      return addVocabTextArea(textAreas, payload);
    case 'DELETE':
      return deleteTextArea(textAreas, { id: payload.id });
    case 'UPDATE':
      return updateTextArea(textAreas, payload);
    case 'MOVE_WITH_ARROW_KEY':
      return moveWithKey(textAreas, payload);
    case 'SPACE_TEXT_AREAS':
      return spaceTextAreas(textAreas, { shape: payload });
    default:
      return textAreas;
  }
};

export default textAreaReducer;
