import React, { useLayoutEffect, useState } from 'react';
import { Form } from 'react-final-form';
import Modal, { Footer } from '@/components/common/Modal';
import { FileField } from '@/components/common/Forms';
import { required } from '@/components/common/Forms/Validators';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import { useMutation } from '@tanstack/react-query';
import { Slide } from '@/components/admin/SlideShow/Builder/CreateTransistionGroup/utils';
import { playableBackgroundAudioUrl } from '@/components/admin/SlideShow/PresentMode/Utils';
import { addFileToInput } from '@/components/admin/SlideShow/Utils';
import AudioPlayerWrapper from '@/components/common/AudioPlayer';

interface BackgroundAudioModalProps {
  actionText: string;
  afterSubmit: (arg) => void;
  modalManager: ModalManager;
  slide: Slide;
}

const INPUT_ELEMENT_ID_EN = 'backgroundAudioInputField';
const INPUT_ELEMENT_ID_ES = 'backgroundAudioEsInputField';

const BackgroundAudioModal = ({
  actionText,
  afterSubmit,
  modalManager,
  slide,
}: BackgroundAudioModalProps) => {
  const [backgroundAudio, setBackgroundAudio] = useState(null);
  const [backgroundAudioEs, setBackgroundAudioEs] = useState(null);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (formValues : { id: string, backgroundAudio: File, backgroundAudioEs: File }) => {
      const route = Routes.plato_api_slide_path(slide.id);

      const formData = new FormData();

      formData.append('background_audio', formValues.backgroundAudio);
      if (formValues.backgroundAudioEs) formData.append('background_audio_es', formValues.backgroundAudioEs);
      formData.append('id', formValues.id);
      return Axios.patch(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
  });

  useLayoutEffect(() => {
    // Prepopulate the inputs with the audio files
    if (playableBackgroundAudioUrl(slide?.backgroundAudioUrl)) {
      addFileToInput({
        callback: setBackgroundAudio,
        filename: slide.backgroundAudioFileName,
        inputElementId: INPUT_ELEMENT_ID_EN,
        url: slide.backgroundAudioUrl
      });
    }

    if (playableBackgroundAudioUrl(slide?.backgroundAudioEsUrl)) {
      addFileToInput({
        callback: setBackgroundAudioEs,
        filename: slide.backgroundAudioEsFileName,
        inputElementId: INPUT_ELEMENT_ID_ES,
        url: slide.backgroundAudioEsUrl
      });
    }
  }, [slide]);

  const existingAudioPlayer = (src, es = false) => {
    if (!src || src === '/missing.png') return null;

    const label = es ? 'Current Audio File (Spanish)' : 'Current Audio File'

    return (
      <>
        <span className="tw-font-bold tw-mr-5">{label}</span>
        <AudioPlayerWrapper src={src} />
      </>
    );
  };

  const submitForm = async (formValues) => {
    const res = await mutateAsync(formValues);
    afterSubmit(res.data);

    modalManager.close();
  };

  return (
    <Modal
      closeModal={modalManager.close}
      headerText={actionText}
      isOpen={modalManager.isOpen}
    >
      <Form
        initialValues={{ backgroundAudio, backgroundAudioEs }}
        onSubmit={submitForm}
        render={({
          handleSubmit,
          invalid,
          pristine,
        }) => (
          <form onSubmit={handleSubmit}>
            {existingAudioPlayer(slide.backgroundAudioUrl)}
            <FileField
              id={INPUT_ELEMENT_ID_EN}
              name="backgroundAudio"
              label="Background Audio File"
              accept="audio/mp3, audio/mp4, audio/mpeg"
              required
              validate={required}
            />
            {existingAudioPlayer(slide.backgroundAudioEsUrl, true)}
            <FileField
              id={INPUT_ELEMENT_ID_ES}
              name="backgroundAudioEs"
              label="Background Audio File (Spanish)"
              accept="audio/mp3, audio/mp4, audio/mpeg"
            />
            <Footer
              submitting={isLoading}
              secondaryButtonCallback={modalManager.close}
              primaryButtonText="Submit"
              primaryButtonDisabled={invalid || pristine}
            />
          </form>
        )}
      />
    </Modal>
  );
};

export default BackgroundAudioModal;
