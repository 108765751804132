import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { pollEndpoint } from '../../../modules/TCIUtils';
import I18n from 'i18n-js';

export default class Randomize extends Component {
  static propTypes = {
    copyPath: PropTypes.string.isRequired,
    copyStatusPath: PropTypes.string.isRequired,
    editPath: PropTypes.string.isRequired,
    randomizePath: PropTypes.string.isRequired
  };

  static getPath(path, assessmentId) {
    return path.replace(':assessment_id', assessmentId);
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    return axios
      .post(this.props.copyPath)
      .then((response) => {
        if (response.data.errors) console.log(response.data.errors);
        else if (response.data.redirect_to) return this.pollForCompletion(response.data);
        return null;
      })
      .then(assessmentId => this.randomize(assessmentId))
      .catch(error => console.log(error));
  }

  pollForCompletion(data) {
    const { redirect_to: redirectPath, new_assessment_id: assessmentId } = data;
    const path = Randomize.getPath(this.props.copyStatusPath, assessmentId);

    return pollEndpoint({
      endpointUrl: path,
      interval: 1000,
      until: response => !response.data[Object.keys(response.data)[0]].copying
    })
      .then(() => assessmentId)
      .catch(() => window.location.replace(redirectPath));
  }

  randomize(assessmentId) {
    return axios
      .post(Randomize.getPath(this.props.randomizePath, assessmentId))
      .then(() => window.location.replace(Randomize.getPath(this.props.editPath, assessmentId)))
      .then(error => console.log(error));
  }

  render() {
    return (
      <li>
        <a onClick={this.handleClick} role="button" href="#" className="js-copy-spinner">
          <i className="fa fa-random fa-fw" aria-hidden="true" />
          {I18n.t('randomize')}
        </a>
      </li>
    );
  }
}
