import React from 'react';
import { useForm, useController } from 'react-hook-form';
import CreatableSelect from 'react-select-5/creatable';
import { useParams, useSearchParams } from 'react-router-dom';
import useSearchStore from '@/components/shared/Search/useSearchStore';
import { Input } from '@/components/ui/Form';
import { LinkButton, SubmitButton } from '@/components/ui/Buttons';
import { SearchFilterType } from '@/components/shared/Search/types';

type SearchLocation = 'current' | 'standard' | 'all';

type FormData = {
  query: string;
  exclude?: string;
  location: SearchLocation;
};

const createOption = (label: string) => ({ label, value: label });

const SearchBar = () => {
  const { userType } = useParams();
  const isLoading = useSearchStore(state => state.isLoading);
  const exclusions = useSearchStore(state => state.exclusions);
  const setExclusions = useSearchStore(state => state.setExclusions);
  const error = useSearchStore(state => state.error);
  const suggestions = useSearchStore(state => state.suggestions);
  const showAdvanced = useSearchStore(state => state.showAdvanced);
  const setShowAdvanced = useSearchStore(state => state.setShowAdvanced);
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useSearchStore(state => state.filters);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    register,
  } = useForm<FormData>({
    defaultValues: {
      exclude: undefined,
      query: searchParams.get('term') || '',
      location: searchParams.get('location') as SearchLocation,
    },
  });

  const {
    field: { value: queryValue, onChange: onQueryChange, ...restQueryField },
  } = useController({ name: 'query', control });

  const {
    field: { value: excludeValue, onChange: onExcludeChange, ...restExcludeField },
  } = useController({ name: 'exclude', control });

  const handleKeyDown = (event) => {
    if (['Backspace'].includes(event.key) && !excludeValue) {
      const previousElement = event.target.parentElement.previousElementSibling;
      if (previousElement.className.includes('multiValue')) {
        const valueToRemove = previousElement.querySelector('[class$="MultiValueGeneric"]')?.textContent;
        setExclusions(exclusions.filter(term => term.value !== valueToRemove));
      }
    }

    if (!excludeValue) return;

    if (['Enter', 'Tab'].includes(event.key)) {
      setExclusions([...exclusions, createOption(excludeValue)]);
      setValue('exclude', '');
      event.preventDefault();
    }
  };

  const handleOnChange = (_, { action, removedValue }) => {
    if (action === 'remove-value') {
      setExclusions(exclusions.filter(term => term.value !== removedValue.value));
    } else if (action === 'clear') {
      setExclusions([]);
    }
  };

  const buildParams = (filterType: SearchFilterType) => (
    filters?.[filterType]?.filter(filter => filter.checked)?.map(filter => filter.value)?.join(',')
  );

  const updateSearchParams = ({ query }: { query: string; }) => {
    if (!query) return;

    const searchExclusions = exclusions.map(term => term.value).join(',');
    const newSearchParams: {
      language?: string;
      term: string;
      exclude?: string;
      reading?: string;
      reference_material?: string;
      image?: string;
      location?: SearchLocation;
      slide_show?: string;
    } = { term: query };

    if (searchExclusions) newSearchParams.exclude = searchExclusions;

    const readingFilters = buildParams('reading');
    const referenceMaterialFilters = buildParams('reference_material');
    const imageFilters = buildParams('image');
    const languageFilters = buildParams('language');
    const slideShowFilters = buildParams('slide_show');
    const location = getValues('location');

    if (readingFilters) newSearchParams.reading = readingFilters;
    if (referenceMaterialFilters) newSearchParams.reference_material = referenceMaterialFilters;
    if (imageFilters) newSearchParams.image = imageFilters;
    if (languageFilters) newSearchParams.language = languageFilters;
    if (location) newSearchParams.location = location;
    if (slideShowFilters) newSearchParams.slide_show = slideShowFilters;

    if (searchParams.toString() !== new URLSearchParams(newSearchParams).toString()) {
      setSearchParams(newSearchParams);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(updateSearchParams)}>
      <div className="tw-text-center">
        <Input
          autoFocus
          aria-label="Enter a search term"
          disabled={isLoading}
          name="query"
          placeholder="Enter a search term"
          onChange={onQueryChange}
          variant="fullwidth"
          value={queryValue}
          {...restQueryField}
        />
        <SubmitButton
          disabled={queryValue.length === 0}
          className="tw-p-2.5"
          label="Search"
          submitting={isLoading}
          submittingText=""
          variant="rounded-right"
        >
          <i className="fa fa-search" />
        </SubmitButton>
      </div>

      {(userType === 'admin' || userType === 'ContentManager') && (
        <div className="tw-flex tw-items-center tw-justify-end tw-w-[98.5%]">
          <LinkButton
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="tw-text-xs tw-pt-0"
          >
            <i className="fa fa-cog" />&nbsp;
            Advanced Search
          </LinkButton>
        </div>
      )}

      {showAdvanced && (
        <div>
          <div className="tw-flex tw-items-center tw-justify-center tw-py-2.5">
            <CreatableSelect
              backspaceRemovesValue
              className="tw-w-[93.5%]"
              components={{ DropdownIndicator: null }}
              inputValue={excludeValue}
              isMulti
              menuIsOpen={false}
              onChange={handleOnChange}
              onInputChange={newValue => setValue('exclude', newValue)}
              onKeyDown={e => handleKeyDown(e)}
              placeholder="Enter an excluded search term and press tab or enter"
              value={exclusions}
              {...restExcludeField}
            />
          </div>
          <div className="tw-flex tw-items-center  tw-py-2.5">
            <h3 className="tw-mt-0">Location</h3>
            <label className="tw-flex tw-items-center tw-ml-4">
              <input
                type="radio"
                value={undefined}
                defaultChecked={!searchParams.get('location')}
                {...register('location')}
              />

              <span className="tw-pl-1">
                Current Program Only
              </span>
            </label>

            <label className="tw-flex tw-items-center tw-ml-4">
              <input
                type="radio"
                value="standard"
                defaultChecked={searchParams.get('location') === 'standard'}
                {...register('location')}
              />

              <span className="tw-pl-1">
                Standard Programs
              </span>
            </label>

            <label className="tw-flex tw-items-center tw-ml-4">
              <input
                type="radio"
                value="all"
                defaultChecked={searchParams.get('location') === 'all'}
                {...register('location')}
              />

              <span className="tw-pl-1">
                All Programs
              </span>
            </label>
          </div>
        </div>
      )}

      {suggestions?.length > 0 && !isLoading && (
        <div>
          <h3>Did you mean?</h3>
          {suggestions.map(suggestion => (
            <LinkButton
              onClick={() => {
                setValue('query', suggestion, { shouldValidate: true });
                updateSearchParams({ query: suggestion });
              }}
              key={suggestion}
            >
              {suggestion}
            </LinkButton>
          ))}
        </div>
      )}

      {error && <p className="tw-text-red">Something went wrong. Please try again.</p>}
    </form>
  );
};

export default SearchBar;
