import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { GroupedPrograms } from './useGroupedPrograms';
import ProgramCheckbox from './ProgramCheckbox';

const columnHelper = createColumnHelper<GroupedPrograms>();

const useColumns = (groupedPrograms: GroupedPrograms[], licenseId: number) => useMemo(() => {
  if (!groupedPrograms.length) return [];

  const programNameColumn = columnHelper.accessor((row: GroupedPrograms) => row.id, {
    id: 'program',
    cell: info => info.getValue(),
    header: 'Program',
  });

  const maxRecommended = Math.max(0, ...groupedPrograms.map(group => group.programs.recommended.length));

  const recommendedProgramsColumns = Array.from(Array(maxRecommended).keys()).map(index => (
    columnHelper.accessor((row: GroupedPrograms) => row.programs.recommended[index] || null, {
      id: `recommended-${index}`,
      cell: info => info.getValue() && (
        <ProgramCheckbox recommended program={info.getValue()} licenseId={licenseId} />
      ),
      header: index === 0 ? 'Recommended' : '',
    })
  ));

  const maxOther = Math.max(0, ...groupedPrograms.map(group => group.programs.other.length));

  const otherProgramsColumns = Array.from(Array(maxOther).keys()).map(index => (
    columnHelper.accessor((row: GroupedPrograms) => row.programs.other[index] || null, {
      id: `other-${index}`,
      cell: info => info.getValue() && (
        <ProgramCheckbox program={info.getValue()} licenseId={licenseId} />
      ),
      header: index === 0 ? 'Other' : '',
    })
  ));

  return [programNameColumn, ...recommendedProgramsColumns, ...otherProgramsColumns];
}, [groupedPrograms]);

export default useColumns;
