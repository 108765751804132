import React from 'react';
import { DESTROY_SLIDE_GROUP_MUTATION } from 'components/admin/SlideShow/Utils/slideShowGraphQL';
import { useMutation } from '@apollo/client';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './CreateTransitionGroup.module.scss';

type DeleteSlideGroupButtonProps = {
  id: number;
};

const DestroySlideGroupButton = ({ id }: DeleteSlideGroupButtonProps) => {
  const [destroySlideGroupMutation] = useMutation(DESTROY_SLIDE_GROUP_MUTATION);
  const updateSlideShow = useSlideShowContext(state => state.updateSlideShow);

  const handleClick = () => {
    const mutationParams = {
      variables: {
        slideGroupId: id,
      },
    };

    destroySlideGroupMutation(mutationParams)
      .then((res) => {
        updateSlideShow(res.data.destroySlideGroup.slideShow);
      });
  };

  return (
    <button className={`${styles.dropdownItem} dropdown-item`} onClick={handleClick} type="button">
      <i className="fa fa-trash" />
      Delete
    </button>
  );
};

export default DestroySlideGroupButton;
