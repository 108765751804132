import React from 'react';
import cn from '@/utils/cn';
import Instructions from './Instructions';

type MapViewerHeaderProps = {
  title: string;
  instructions: string;
};

const MapHeader = ({ title, instructions }: MapViewerHeaderProps) => {
  const hasInstructions = instructions && instructions.length > 0;

  return (
    <div>
      {title && (
        <div
          className={cn('tw-text-xl tw-font-bold tw-ml-4 tw-mt-2', {
            'tw-mb-1': hasInstructions,
            'tw-mb-2': !hasInstructions,
          })}
        >
          {title}
        </div>
      )}
      {hasInstructions && <Instructions instructions={instructions} />}
    </div>
  );
};

export default MapHeader;
