import React, { useState, useLayoutEffect } from 'react';
import Modal, { Footer } from 'components/common/Modal';
import Axios from 'axios';
import { Form } from 'react-final-form';
import { TextAreaField } from 'components/common/Forms';
import { SubmitError } from '../../../common/Forms/Utils';
import Spinner from 'components/common/Spinner';
import ModalContent from 'components/common/AdministratorsModal/ModalContent';
import styles from './EditContactAdminModal.module.scss';

interface FormValues {
  message?: string;
}

interface EditContactAdminModalProps {
  getContactAdminsPath: string;
  modalManager: ModalManager;
  subscriberPath: string;
}

const EditContactAdminModal = ({ getContactAdminsPath, modalManager, subscriberPath }: EditContactAdminModalProps) => {
  const { close, isOpen } = modalManager;
  const [initialMessage, setInitialMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useLayoutEffect(() => {
    if (initialMessage) return;

    Axios.get(subscriberPath)
      .then(response => {
        setInitialMessage(response.data.subscriber.contact_admin_message);
        setIsLoading(false);
      })
      .catch(error => {
        console.log('error fetching subscriber: ', error)
        setIsLoading(false);
      })
  }, [subscriberPath, setIsLoading]);

  const handleSubmitAction = (values: FormValues) => {
    if (isPreviewMode) {
      setIsSubmitting(true);
      return Axios.put(subscriberPath, { subscriber: { contact_admin_message: values.message || '' } })
        .then((_response) => {
          setIsSubmitting(false);
          setIsPreviewMode(false);
          modalManager.close();
        })
        .catch((error) => {
          console.log('Api error: ', error)
          setIsSubmitting(false);
          setErrorMessage(`Error saving: ${error.response.data.errors}`)
        });
    } else {
      setIsPreviewMode(true);
    }
  };

  const renderPreview = (values) => {
    return (
      <ModalContent
        ajaxPath={getContactAdminsPath}
        classNames={styles.modalContent}
        customMessage={values.message}
      />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={close}
      headerText="Edit Contact Admin Popup"
    >
      <div className={styles.modalContent}>
        <Form
          initialValues={{ message: initialMessage }}
          onSubmit={handleSubmitAction}
          render={({ form, pristine, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {isPreviewMode && renderPreview(form.getState().values)}

              {/* Don't remove this element but instead hide it, to preserve the form data */}
              <div className={`${isPreviewMode ? 'hidden' : ''}`}>
                <div>
                  You can add a custom message that appears above the list of TCI administrators teachers in your district can contact.
                </div>

                <label className="mt30 bold">
                  Message:
                </label>

                {isLoading ?
                  <Spinner /> :
                  <TextAreaField
                    className={styles.textArea}
                    name="message"
                    onChange={() => setErrorMessage(undefined)}
                    placeholder="Start typing here..."
                    hideLabel
                  />
                }
              </div>
              {errorMessage && <SubmitError error={errorMessage} />}

              <Footer
                submitting={isSubmitting}
                primaryButtonText={isPreviewMode ? 'Submit' : 'Preview'}
                primaryButtonDisabled={(isPreviewMode && pristine) || isSubmitting}
                secondaryButtonCallback={() => isPreviewMode ? setIsPreviewMode(false) : close()}
                secondaryButtonText={isPreviewMode ? 'Back' : 'Cancel'}
              />
            </form>
          )}
        />
      </div>
    </Modal>
  );
}

export default EditContactAdminModal;
