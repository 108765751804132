import React, { useState } from 'react';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/ui/Tooltip';
import Popover from '@/components/ui/Popover';
import cn from '@/utils/cn';
import SnapToGridSwitch from './SnapToGridSwitch';
import { buttonStyle, faStyle, scaleValue } from '../styles';
import useDrawingContext from '../../store/useDrawingContext';
import ShortcutsTooltip from './ShortcutsTooltip';

const SettingsButton = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const disabled = useDrawingContext(state => state.disabled);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Popover
          open={popoverIsOpen}
          onOpenChange={(isOpen) => {
            setPopoverIsOpen(isOpen);
          }}
        >
          <Popover.Anchor />
          <Tooltip.Trigger asChild>
            <Popover.Trigger
              className={cn(
                'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
                'hover:tw-bg-tci-purple',
                'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
              )}
              type="button"
              aria-label="Settings"
              disabled={disabled}
              style={buttonStyle(toolbarEl)}
            >
              <i
                style={faStyle({ toolbarEl })}
                className="fa fa-icon fa-ellipsis-h tw-text-3xl"
              />
            </Popover.Trigger>
          </Tooltip.Trigger>
          <Tooltip.Content
            className={cn(
              'tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2',
              'tw-outline tw-outline-1 tw-outline-gray-300',
            )}
            side="right"
            sideOffset={10}
          >
            {I18n.t('settings')}
          </Tooltip.Content>
          <Popover.Content
            className="tw-absolute tw-left-6 tw-top-1 tw-overflow-visible tw-w-[250px] tw-flex-col tw-h-18 tw-text-base"
            aria-label="Settings"
          >
            <h2
              className="tw-pt-1.5 tw-pb-1 tw-px-5"
              style={{ fontSize: `${scaleValue(toolbarEl, 110, 120)}%` }}
            >
              {I18n.t('settings')}
            </h2>
            <ShortcutsTooltip />
            <SnapToGridSwitch />
          </Popover.Content>
        </Popover>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default SettingsButton;
