import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import I18n from 'modules/i18n';
import 'video.js/dist/video-js.css';
import QualitySelector from '@silvermine/videojs-quality-selector';
import PropTypes from 'prop-types';
import videoPropTypes from './Proptypes';
import styles from './VideoPlayer.module.scss';
import './video-js-styles.scss';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';
import AudioDescriptionsPlayer from '@/components/common/AudioDescriptionsPlayer/index';
import AudioDescriptionsPlugin from '@/components/common/AudioDescriptionsPlayer/AudioDescriptionsPlugin';
import {
  createCheckmarkWrappers,
  setInitialOffSelectionText,
  setMenuItemText,
} from './Utils';

const DEFAULT_VIDEO_OPTIONS = {
  controls: true,
  controlsList: 'nodownload',
  crossOrigin: 'anonymous',
  disablePictureInPicture: true,
  fluid: true,
  preload: 'metadata',
};

const VideoResolutions = (video, isUsingEnglish) => {
  const persistedResolution = localStorage.getItem('selectedVideoResolution');

  const getKey = () => {
    if (isUsingEnglish) return 'url';
    if (video.es_url && !isUsingEnglish) return 'es_url';

    return 'url';
  };

  return [
    {
      label: '480P',
      selected: persistedResolution === '480P',
      src: video[`sd_${getKey()}`],
      type: 'video/mp4',
    },
    {
      label: '720P',
      selected: persistedResolution ? persistedResolution === '720P' : true,
      src: video[`hd_${getKey()}`],
      type: 'video/mp4',
    },
    {
      label: '1080P',
      selected: persistedResolution === '1080P',
      src: video[`fhd_${getKey()}`],
      type: 'video/mp4',
    },
  ].filter(resolution => resolution.src);
};

const QualitySelectorPersistence = (qualitySelector) => {
  // Initialize onClick listeners for toggling resolutions
  qualitySelector.on('mouseover', (e) => {
    $(e.currentTarget).find($('.vjs-menu-item-text')).each((i, resolutionOption) => {
      $(resolutionOption).on('click', (option) => {
        localStorage.setItem('selectedVideoResolution', option.target.innerHTML);
      });
    });
  });
};

const VideoPlayer = ({
  locale,
  video,
  width,
  height,
  maxWidth,
  minWidth,
  setVideoWidth,
  setVideoHeight,
  setLoading,
  sharePlayer,
  options = DEFAULT_VIDEO_OPTIONS,
  onPlay,
  shouldAutoPlay,
}) => {
  window.videojs = videojs;
  QualitySelector(videojs);

  if (!video || !video.url) {
    setLoading(false);
    return null;
  }

  const [videoPlayer, setVideoPlayer] = useState(null);
  const vidRef = useRef();
  const captionEnUrl = video.caption_url;
  const captionEsUrl = video.caption_es_url;
  const audioDescriptionsUrl = video.audio_descriptions_url;
  const audioDescriptionsCues = video.audio_descriptions_cues;
  const isUsingEnglish = () => {
    if (typeof locale === 'string') return locale === 'en';

    return I18n.locale === 'en';
  };
  const resolutions = VideoResolutions(video, isUsingEnglish());

  useEffect(() => {
    $(vidRef.current).on('loadedmetadata', (e) => {
      // @ts-ignore
      setVideoHeight(e.target.videoHeight);
      // @ts-ignore
      setVideoWidth(e.target.videoWidth);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // Set current player to null for changing videos
    const hasVideoPlayer = !!videoPlayer;
    if (hasVideoPlayer) setVideoPlayer(null);

    if (!hasVideoPlayer) {
      const videoElement = vidRef.current;

      if (!videoElement) return;

      if (video.audio_descriptions_url) {
        window.videojs.registerPlugin('audioDescriptionsPlugin', AudioDescriptionsPlugin);
      }
      const player = window.videojs(videoElement, options);
      // Add custom player events
      player.on('play', onPlay);
      setVideoPlayer(player);
      sharePlayer(player);
      const qualitySelector = player.controlBar.addChild('QualitySelector');

      if (resolutions.length) player.src(resolutions);

      QualitySelectorPersistence(qualitySelector);

      player.on('loadedmetadata', (e) => {
        const { controlBar = {} } = player;
        const { progressControl, pictureInPictureToggle, subsCapsButton } = controlBar;
        const playerWidth = player.currentWidth();
        const nonZeroWidth = playerWidth && playerWidth !== 0;

        if (nonZeroWidth && playerWidth <= 200 && progressControl) {
          // Checks width on play to account for videos in buttons.
          player.on('play', () => {
            if (player.currentWidth() <= 200) progressControl.dispose();
          });
        }
        if (((nonZeroWidth && playerWidth <= 300) || options.disablePictureInPicture) && pictureInPictureToggle) {
          pictureInPictureToggle.dispose();
        }

        // Update styling of Closed Captions menu (with accommodation for Safari closed captions handling)
        if (subsCapsButton.items.length < 3) {
          // Safari: ["OffTextTrackMenuItem", "SubsCapsMenuItem"]
          setInitialOffSelectionText(subsCapsButton.items[0]);
        } else {
          // Chrome: ['CaptionSettingsMenuItem', 'OffTextTrackMenuItem', 'SubsCapsMenuItem']
          setInitialOffSelectionText(subsCapsButton.items[1]);
          setMenuItemText(subsCapsButton.items[0], 'Closed Captions');
        }
        createCheckmarkWrappers(subsCapsButton.items);

      });
    }
  }, [options, setVideoPlayer, vidRef, video.url, video.audio_descriptions_url]);

  useEffect(() => {
    if (shouldAutoPlay && videoPlayer) videoPlayer.play();

    return () => {
      if (videoPlayer) {
        videoPlayer.dispose();
        setVideoPlayer(null);
      }
    };
  }, [setVideoPlayer, shouldAutoPlay, videoPlayer]);

  const getMinWidth = () => {
    if (!minWidth && !width) return undefined;

    const minWidthOrWidth = minWidth || width;
    const isPercentage = minWidthOrWidth.toString().includes('%');
    const isUnset = minWidthOrWidth.toString() === 'unset';
    if (isPercentage || isUnset) return minWidthOrWidth;

    return `${minWidthOrWidth}px`;
  };

  const getHeight = () => {
    if (!height) return undefined;

    if (height.toString().includes('%')) return height;

    return `${height}px`;
  };

  const getMaxWidth = () => {
    if (!maxWidth) return undefined;

    if (maxWidth.toString().includes('%')) return maxWidth;

    return `${maxWidth}px`;
  };

  const getMarginStyles = () => {
    const maxWidthRes = getMaxWidth();

    if (!maxWidthRes) return undefined;
    if (maxWidthRes.includes('%')) return '0 auto';

    return undefined;
  };

  const getUrl = () => {
    if (isUsingEnglish()) return video.url;
    if (video.es_url) return video.es_url;

    return video.url;
  };

  const getPosterUrl = () => {
    if (isUsingEnglish()) return video.poster_url;
    if (video.poster_es_url) return video.poster_es_url;

    return video.poster_url;
  };

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: getHeight(),
        margin: getMarginStyles(),
        maxWidth: getMaxWidth(),
        minWidth: getMinWidth(),
      }}
    >
      <div data-vjs-player>
        <video
          aria-label="video"
          ref={vidRef}
          key={getUrl()}
          poster={getPosterUrl()}
          muted={video.is_silent}
          width={width}
          height={height}
          className={`react-video-player video-js vjs-big-play-centered ${styles.player}`}
          crossOrigin="anonymous"
          autoPlay={shouldAutoPlay}
        >
          {captionEnUrl && (isUsingEnglish() || !(video.es_url || video.caption_es_url)) && (
            <track label="English" kind="captions" srcLang="en" src={captionEnUrl} />
          )}
          {captionEsUrl && !isUsingEnglish() && <track label="Español" kind="captions" srcLang="es" src={captionEsUrl} />}
          {audioDescriptionsUrl &&
            <AudioDescriptionsPlayer
              audioDescriptionsCues={audioDescriptionsCues}
              audioDescriptionsUrl={audioDescriptionsUrl}
              videoPlayer={videoPlayer}
            />
          }
          {resolutions.length === 0 && <source src={getUrl()} />}
        </video>
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locale: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPlay: PropTypes.func,
  setLoading: PropTypes.func,
  setVideoHeight: PropTypes.func,
  setVideoWidth: PropTypes.func,
  sharePlayer: PropTypes.func,
  shouldAutoPlay: PropTypes.bool,
  video: videoPropTypes,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VideoPlayer.defaultProps = {
  height: null,
  locale: null,
  onPlay: () => {},
  setLoading: () => {},
  setVideoHeight: () => {},
  setVideoWidth: () => {},
  sharePlayer: () => {},
  shouldAutoPlay: false,
  video: null,
  width: null,
};

export {
  DEFAULT_VIDEO_OPTIONS,
  QualitySelectorPersistence,
  videojs,
  VideoResolutions,
};

export default VideoPlayer;
