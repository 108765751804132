import { useEffect } from 'react';
import { Canvas, filters } from 'fabric';
import * as fabric from 'fabric';

interface UseColorsProps {
  canvas: Canvas;
  color: string;
}

const getColorValues = (color) => {
  let pars;
  let values = { red: null, green: null, blue: null, alpha: null };
  if (typeof color === 'string') {
    if (color.indexOf('rgb(') === 0) {
      pars = color.indexOf(',');
      values = {
        red: parseInt(color.substr(4, pars), 10),
        green: parseInt(color.substr(pars + 1, color.indexOf(',', pars)), 10),
        blue: parseInt(color.substr(color.indexOf(',', pars + 1) + 1, color.indexOf(')')), 10),
        alpha: 1,
      };
      /* rgba */
    } else if (color.indexOf('rgba(') === 0) {
      pars = color.indexOf(',');
      const repars = color.indexOf(',', pars + 1);
      values = {
        red: parseInt(color.substr(5, pars), 10),
        green: parseInt(color.substr(pars + 1, repars), 10),
        blue: parseInt(color.substr(color.indexOf(',', pars + 1) + 1, color.indexOf(',', repars)), 10),
        alpha: parseFloat(color.substr(color.indexOf(',', repars + 1) + 1, color.indexOf(')'))),
      };
    }
  }
  return values;
};

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

export const setColor = (groupObj, color) => {
  const whiteListColors = ['#FFFFFF', 'none', '#E6E6E6', ''];
  if (!(groupObj.fill instanceof fabric.Gradient)) {
    if (groupObj.stroke) groupObj.set('stroke', color);
    if (!whiteListColors.includes(groupObj.fill)) groupObj.set('fill', color);
  }
};

const useColors = ({ canvas, color }: UseColorsProps) => {
  useEffect(() => {
    if (!canvas) return;

    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length === 0) return;

    activeObjects.forEach((obj) => {
      if (obj.isType(fabric.Path.type)) obj.set('stroke', color);
      if (obj.isType(fabric.IText.type)) obj.set('fill', color);
      if (obj.isType(fabric.FabricImage.type)) {
        (obj as fabric.FabricImage).filters?.pop();

        const colorValues = getColorValues(color);
        const colorHEXConverted = rgbToHex(colorValues.red, colorValues.green, colorValues.blue);

        const filter = new filters.BlendColor({ color: colorHEXConverted, mode: 'tint' });

        (obj as fabric.FabricImage).filters?.push(filter);

        (obj as fabric.FabricImage).applyFilters();
      }
      if (obj.isType(fabric.Group.type)) {
        // eslint-disable-next-line no-underscore-dangle
        (obj as fabric.Group)._objects.forEach(groupObj => setColor(groupObj, color));
      }
    });

    canvas.renderAll();
  }, [color]);
};

export default useColors;
