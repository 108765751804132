import React, { useState, useEffect } from 'react';
import Modal, { Footer } from '@/components/common/Modal';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_subscriber_terms_path, plato_api_bulk_update_subscriber_terms_path } from '@/modules/routes';
import moment from 'moment-timezone';

interface AcademicTermsModalProps {
  modalManager: ModalManager;
  subscriberId: string;
}

interface TermType {
  id: string,
  name: string,
  start_date: string,
  end_date: string,
  start_in_days?: string,
  ended_in_days?: string,
}

const AcademicTermsModal = ({ modalManager, subscriberId }: AcademicTermsModalProps) => {
  const [startGracePeriod, setStartGradePeriod] = useState('0');
  const [endGracePeriod, setEndGradePeriod] = useState('0');
  const [termsWithRange, setTermsWithRange] = useState({});

  const inputClasses = 'tw-w-5 tw-pl-[4px] tw-pr-[15px] tw-py-3 tw-m-2 tw-rounded-md tw-border '
    + 'tw-border-gray-400 tw-border-solid tw-text-right';

  const { data: terms, isLoading } = useQuery({
    queryFn: () => (
      axios
        .get(plato_api_subscriber_terms_path(subscriberId))
        .then(res => res.data.data)
        .catch(e => console.log(e))
    ),
    queryKey: [subscriberId],
  });

  const checkTerms = () => {
    const calculatedTermsHash = {};

    // check if the current date is between the term's start and end dates -/+ the grace period
    terms.forEach((term: TermType) => {
      const isWithinRange = (t: TermType) => {
        // we subtract from start date and add to end date to extend the ranges that they include
        // even if it seems like we should be doing the opposite
        const startDateWithGrace = moment(t.start_date).subtract(startGracePeriod, 'days');
        const endDateWithGrace = moment(t.end_date).add(endGracePeriod, 'days');

        return moment().isBetween(startDateWithGrace, endDateWithGrace, 'days', '[]');
      };

      // store whether or not the term is within range in a hash
      calculatedTermsHash[term.id] = isWithinRange(term);
    });

    setTermsWithRange(calculatedTermsHash);
  };

  // Initial load to set the start and end grade input values.
  useEffect(() => {
    if (!terms || isLoading) return;

    setStartGradePeriod(terms[0].start_in_days);
    setEndGradePeriod(terms[0].ended_in_days);
    checkTerms();
  }, [terms]);

  useEffect(() => {
    if (!terms || isLoading) return;

    checkTerms();
  }, [startGracePeriod, endGracePeriod]);

  const termsAndRanges = () => {
    const formattedTerm = (term: TermType) => {
      const inRangeIcon = termsWithRange[term.id] ? 'fa fa-check text--green' : 'fa fa-times tw-text-red';

      const formattedDate = date => moment(date).format('MM/DD/YYYY');
      return (
        <div>
          <i aria-hidden className={`${inRangeIcon} tw-mr-2 tw-w-5`} />
          <span>{term.name} ({formattedDate(term.start_date)} - {formattedDate(term.end_date)})</span>
        </div>
      );
    };

    return terms.map(term => formattedTerm(term));
  };

  const updateTerms = () => {
    const termIds = Object.keys(termsWithRange);
    axios.post(plato_api_bulk_update_subscriber_terms_path(subscriberId), {
      term_ids: termIds,
      start_in_days: startGracePeriod,
      ended_in_days: endGracePeriod,
    })
      .then(() => { modalManager.close(); })
      .catch(e => console.log(e));
  };

  return (
    <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText="Select Academic Terms">
      <div className="tw-text-lg">
        <div>
          <p>Include <strong>all active academic terms</strong> and any that:</p>
          <div>Start in the next
            <input
              className={inputClasses}
              placeholder="0"
              onChange={e => setStartGradePeriod(e.target.value)}
              type="number"
              value={startGracePeriod}
              aria-label="startGracePeriod"
            />
            days
          </div>
          <div>Ended in the last
            <input
              className={inputClasses}
              placeholder="0"
              onChange={e => setEndGradePeriod(e.target.value)}
              type="number"
              value={endGracePeriod}
              aria-label="endGracePeriod"
            />
            days
          </div>
        </div>
        <hr />
        <div>
          <strong>Included Academic Terms</strong>
          {!isLoading && termsAndRanges()}
        </div>
      </div>
      <Footer
        primaryButtonText="Save"
        primaryButtonSubmit={false}
        primaryButtonCallback={updateTerms}
        secondaryButtonCallback={() => {
          modalManager.close();
        }}
      />
    </Modal>
  );
};

const AcademicTermsModalWrapper = ({ modalManager, subscriberId }: AcademicTermsModalProps) => (
  <QueryClientProvider client={new QueryClient()}>
    <AcademicTermsModal modalManager={modalManager} subscriberId={subscriberId} />
  </QueryClientProvider>
);

export default AcademicTermsModalWrapper;
