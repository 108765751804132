import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/common/Modal';
import StaffForm from '../StaffForm';

interface EditProps {
  autoRosterDistrict: boolean;
  closeModal: () => void;
  contactTypeOptions: [{ label: string, value: string }],
  modalOpen: boolean;
  staff: {
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    uid: string;
    username?: string;
  };
  updateTable: () => void;
  userIsSysadmin: boolean;
  flags:{
    label: string;
    name: string;
  }[];
}

const Edit = ({
  autoRosterDistrict, closeModal, contactTypeOptions, modalOpen, staff, updateTable, userIsSysadmin, flags,
}: EditProps) => (
  <Modal
    closeModal={closeModal}
    isOpen={modalOpen}
    headerText="Edit Staff"
  >
    <StaffForm
      autoRosterDistrict={autoRosterDistrict}
      closeModal={closeModal}
      contactTypeOptions={contactTypeOptions}
      newRecord={false}
      staff={staff}
      updateTable={updateTable}
      userIsSysadmin={userIsSysadmin}
      flags={flags}
    />
  </Modal>
);

Edit.propTypes = {
  autoRosterDistrict: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contactTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  staff: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    username: PropTypes.string,
  }).isRequired,
  updateTable: PropTypes.func.isRequired,
  userIsSysadmin: PropTypes.bool.isRequired,
};

export default Edit;
