export const isEmpty = (note: string | undefined) => {
  if (typeof note !== 'string') return true;

  return (
    note
      .replace(/<br>/g, '')
      .replace(/&nbsp;/g, '')
      .trim()
      .length === 0
  );
};
