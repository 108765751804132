import React, { useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import ColorPicker from '../../../ContentActions/EditBackgroundPopover/ColorPicker';

interface Props {
  editorInstance: { undo: { saveStep: () => void; } };
  htmlElement: JQuery<HTMLElement>;
  htmlElementId?: number;
  setEditMode: (nextState: boolean) => void;
}

const COLOR_MAP = {
  '#522E91': 'purple',
  '#F69C05': 'orange',
  '#D2232A': 'red',
  '#2B9AB8': 'blue',
  '#2A8806': 'green',
  '#CCCCCC': 'med-grey',
};

export const updateHtmlElement = async (id: string, buttonColor: string) => {
  const res = await Axios.patch(Routes.plato_api_html_element_path(id, {
    background_color: buttonColor,
  }));

  const { data: { data: { background_color: backgroundColor } } } = res.data;

  return backgroundColor;
};

const ColorMenu = ({ editorInstance, htmlElement, setEditMode }: Props) => {
  const htmlElementId = htmlElement.attr('data-html-element-id');

  useEffect(() => {
    return () => { setEditMode(false); };
  });

  const onSubmit = async ({ color }) => {
    const backgroundColor = await updateHtmlElement(htmlElementId, COLOR_MAP[color]);

    if (htmlElement[0]) {
      htmlElement.attr('data-background-color', backgroundColor);
      htmlElement.attr('data-updated-at', Date.now().toString());
    }

    editorInstance.undo.saveStep();
    setEditMode(false);
  };

  return (
    <div>
      <ColorPicker
        colorOptions={Object.keys(COLOR_MAP)}
        onChange={onSubmit}
        showHexInput={false}
        showOpacityInput={false}
      />
    </div>
  );
};

export default ColorMenu;
