import React from 'react';
import PaddingPopover from './PaddingPopover';
import { EditPopoverProps } from '../types';

const EditPaddingPopover = ({ anchorId, slideObject, styleDivRef, dropdownEvents }: EditPopoverProps) => {
  const openerClass = `padding-popover-opener-${slideObject.id}`;
  return (
    <>
      <a
        className={`dropdown-item ${openerClass}`}
        href="#"
        role="button"
        onClick={() => dropdownEvents.setOpen(false)}
      >
        Change Padding
      </a>
      <PaddingPopover
        anchorId={anchorId}
        openerClass={openerClass}
        slideObject={slideObject}
        styleDivRef={styleDivRef}
      />
    </>
  );
};

export default EditPaddingPopover;
