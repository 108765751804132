import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import { SubmitError } from 'common/Forms/Utils';
import DownloadResultCsvButton from './DownloadResultCsvButton';
import DeleteBtn from '../IndexCsvTable/DeleteBtn';

const GET_PROGRAM_INDEX_PDF_UPLOAD = gql`
    query ProgramIndexPdfPoll($id: ID!) {
      programIndexPdfUpload(id: $id) {
        id
        uploadStatus,
        resultCsvUrl
      }
    }
  `;

const POLL_WAIT_TIME = 5000; // 5 seconds.

const PdfPollerWrapper = ({ programIndexPdfUploadId }) => (
  <ApolloProvider client={apolloClient}>
    <PdfPoller
      programIndexPdfUploadId={programIndexPdfUploadId}
    />
  </ApolloProvider>
);

PdfPollerWrapper.propTypes = {
  programIndexPdfUploadId: PropTypes.number.isRequired
};

const PdfPoller = ({ programIndexPdfUploadId }) => {
  const queryData = {
    fetchPolicy: 'network-only',
    pollInterval: POLL_WAIT_TIME,
    variables: { id: programIndexPdfUploadId }
  };
  const {
    loading, data, error, stopPolling
  } = useQuery(GET_PROGRAM_INDEX_PDF_UPLOAD, queryData);
  const [pdf, setPdf] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error) {
      console.log(error);
      setHasError(true);
      return;
    }

    if (loading) {
      return;
    }

    if (!data || !data.programIndexPdfUpload) {
      setHasError(true);
      return;
    }

    setPdf(data.programIndexPdfUpload);

    if (data.programIndexPdfUpload.uploadStatus !== 'In Progress') {
      stopPolling();
    }
  }, [data]);

  if (hasError) {
    return <SubmitError error="Something went wrong. Please refresh the page and try again." />;
  }

  if (!pdf?.id) {
    return <></>;
  }

  if (pdf) {
    if (pdf.uploadStatus === 'Finished') {
      return (
        <DownloadResultCsvButton resultCsvUrl={pdf.resultCsvUrl} />
      );
    }

    if (pdf.uploadStatus === 'Failed') {
      return <SubmitError error="Failed to generate CSV." />;
    }
  }

  return (
    <button
      className="btn btn--sm btn--outline-purple"
      type="button"
      disabled
    >
      <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      {' '}
      Generating CSV...
    </button>
  );
};

PdfPoller.propTypes = {
  programIndexPdfUploadId: PropTypes.number.isRequired
};

export default PdfPollerWrapper;
