import React from 'react';
import I18n from 'i18n';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import Popover from '@/components/ui/Popover';
import styles from './SlideShowTitle.module.scss';
import SlideShowTitleForm from './SlideShowTitleForm';

interface SlideShowTitleProps {
  children?: React.ReactNode;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
}

const SlideShowTitle = ({ children, isSaving, setIsSaving }: SlideShowTitleProps) => {
  const slideShow = useSlideShowContext(state => state.slideShow);

  const title = () => {
    if (!slideShow) return null;

    return I18n.locale === 'en' ? slideShow.titleEn : slideShow.titleEs;
  };

  return (
    <>
      <Popover>
        <span className={styles.titleWrapper}>
          <div className="tw-flex">
            <h2>Title: {title()}</h2>
            {slideShow && (
              <Popover.Trigger
                className={`btn btn--outline-purple tw-w-7 tw-h-6 tw-flex tw-items-center tw-justify-center tw-ml-1.5
                          tw-bg-transparent`}
              >
                <i className="fa fa-gear" aria-hidden="true" />
              </Popover.Trigger>
            )}
          </div>
        </span>
        <Popover.Content className="tw-p-2 tw-overflow-visible tw-w-96">
          <SlideShowTitleForm
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            slideShow={slideShow}
          />
        </Popover.Content>
      </Popover>
      {children}
    </>
  );
};

export default SlideShowTitle;
