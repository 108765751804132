import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import apolloClient from 'common/ApolloClient';
import { gql } from '@apollo/client';
import Section from '../Section';
import { RequiredAsterisk } from '../../../common/Forms/Utils';
import { sortByLastNameFirstName } from '../Utils';
import CheckboxGroupDropdown from '../../../common/Forms/CheckboxGroupDropdown';
import sectionStyles from '../Section.module.scss';
import {
  assignmentInitialValuesPropType, classroomsPropType
} from '../PropTypes';

export default class WhoSection extends Component {
  static propTypes = {
    classrooms: classroomsPropType,
    formChangeValue: PropTypes.func.isRequired,
    initialValues: assignmentInitialValuesPropType,
    setShouldShowLmsInfo: PropTypes.func
  };

  static defaultProps = {
    classrooms: [],
    initialValues: {},
    setShouldShowLmsInfo: () => {}
  };

  static processStudentOptions(students) {
    return students
      .sort(sortByLastNameFirstName)
      .map(student => ({
        label: `${student.firstName} ${student.lastName}`,
        value: student.id.toString()
      }));
  }

  constructor(props) {
    super(props);

    this.state = {
      classroomToggleText: 'All classes',
      error: undefined,
      selectedClassroomIds: this.props.initialValues.classroom_ids || [],
      studentOptions: [],
      studentToggleText: 'All students'
    };

    this.classroomRequired = this.classroomRequired.bind(this);
    this.handleClassroomChange = this.handleClassroomChange.bind(this);
    this.handleStudentChange = this.handleStudentChange.bind(this);
    this.studentRequired = this.studentRequired.bind(this);
  }

  componentDidMount() {
    this.setInitialValues();
    this.displayLmsInfo(this.state.selectedClassroomIds);
  }

  componentDidUpdate(prevProps) {
    const loadedClassroomAssignees = !prevProps.initialValues.classroom_ids && this.props.initialValues.classroom_ids;
    const loadedStudentAssignees = !prevProps.initialValues.student_ids && this.props.initialValues.student_ids;

    // if initialValues for classroom_ids/student_ids don't exist in the prevProps
    // but the current props have values for them
    // that means the initial values were loaded from a parent component
    // so we set the initial values on the form and in state
    if (loadedClassroomAssignees || loadedStudentAssignees) {
      this.setInitialValues();
    }
  }

  setInitialValues() {
    const {
      classroom_ids: classroomIds,
      student_ids: studentIds
    } = this.props.initialValues;

    if (classroomIds === undefined) this.setState({ classroomToggleText: 'Classes (0)' });

    if (classroomIds && classroomIds.length > 0) {
      this.setState({ selectedClassroomIds: classroomIds });

      this.updateClassroomToggleText(classroomIds);

      if (classroomIds.length === 1) {
        // all students in classroom are selected
        if (studentIds.length === 0) return this.handleClassroomChange(classroomIds);

        // individual students selected
        return this.getStudentOptions(classroomIds[0])
          .then(() => {
            this.props.formChangeValue(studentIds, 'student_ids');
            this.updateStudentToggleText(studentIds);
          });
      }
    }

    return null;
  }

  getClassroomOptions() {
    return this.props.classrooms
      .map(classroom => ({
        label: `Period ${classroom.period}-${classroom.name}`,
        value: classroom.id.toString()
      }))
      .sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  getStudentOptions(classroomId) {
    if (!classroomId) throw new Error('classroomId must be passed in');

    const GET_CLASSROOM_STUDENTS = gql`
      query GetClassroomStudents($classroomId: ID) {
        classroom(id: $classroomId) {
          students {
            id,
            firstName,
            lastName
          }
        }
      }
    `;

    return apolloClient
      .query({ query: GET_CLASSROOM_STUDENTS, variables: { classroomId: classroomId } })
      .then((result) => {
        const students = [...result.data.classroom.students];
        const studentOptions = WhoSection.processStudentOptions(students);
        return this.setState({ studentOptions });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: "Students couldn't be loaded. Please refresh the page and try again." });
      });
  }

  displayLmsInfo(selectedClassroomIds) {
    if (selectedClassroomIds === undefined) return;

    const selectedClassrooms = this.props.classrooms.filter(classroom => (
      selectedClassroomIds.includes(classroom.id.toString())
    ));
    const connectedClassrooms = selectedClassrooms.filter(classroom => (
      (classroom.lms_course_id && classroom.lms_type) || (classroom.lmsCourseId && classroom.lmsType)
    ));
    this.props.setShouldShowLmsInfo(connectedClassrooms.length > 0);
  }

  handleClassroomChange(value) {
    this.setState({ selectedClassroomIds: value });
    this.updateClassroomToggleText(value);

    if (value.length === 1) { // one classroom selected, select all students by default
      return this
        .getStudentOptions(value[0])
        .then(() => {
          const allStudentIds = this.state.studentOptions.map(option => option.value);
          this.props.formChangeValue(allStudentIds, 'student_ids');
          this.updateStudentToggleText(allStudentIds);
          this.displayLmsInfo(value);
        });
    }

    return this.setState(
      { studentOptions: [] },
      () => {
        this.props.formChangeValue([], 'student_ids');
        this.updateStudentToggleText([]);
        this.displayLmsInfo(value);
      }
    );
  }

  handleStudentChange(studentIds) {
    this.updateStudentToggleText(studentIds);
  }

  updateClassroomToggleText(classroomIds) {
    let text = `Classes (${classroomIds.length})`;

    if (classroomIds.length === this.props.classrooms.length) text = 'All classes';
    else if (classroomIds.length === 1) {
      const classroomId = classroomIds[0];
      text = this.props.classrooms.find(c => c.id.toString() === classroomId).name;
    }
    this.setState({ classroomToggleText: text });
  }

  updateStudentToggleText(studentIds) {
    let text = `Students (${studentIds.length})`;

    // 'None' if no classrooms selected OR no students exist for the classroom selected
    if (this.state.selectedClassroomIds.length === 0 ||
      (this.state.selectedClassroomIds.length === 1 &&
        this.state.studentOptions.length === 0)) text = 'None';
    // 'All students' if more than one classroom selected
    else if (this.state.selectedClassroomIds.length > 1 ||
      studentIds.length === this.state.studentOptions.length) {
      text = 'All students';
    }
    // student name if one student selected
    else if (studentIds.length === 1) {
      text = this.state.studentOptions.find(s => s.value === studentIds[0]).label;
    }

    this.setState({ studentToggleText: text });
  }

  classroomRequired(value) {
    if (this.props.loadingInitialValues) return undefined;

    const error = 'Choose at least one classroom.';
    if (Array.isArray(value) && value.length) {
      if (this.state.error === error) this.setState({ error: undefined });
      return undefined;
    }

    this.setState({ error });
    return error;
  }

  studentRequired(value) {
    const error = 'Choose at least one student.';

    if (!this.state.studentOptions.length ||
      (Array.isArray(value) && value.length)) {
      if (this.state.error === error) this.setState({ error: undefined });
      return undefined;
    }

    this.setState({ error });
    return error;
  }

  renderError() {
    if (this.state.error) {
      return <div className={sectionStyles.fieldError}>{this.state.error}</div>;
    }

    return null;
  }

  render() {
    return (
      <Section title="Who">
        <div className={sectionStyles.row}>
          <div className={sectionStyles.fieldContainer}>
            <div className={sectionStyles.label}>
              Students
              <RequiredAsterisk />
            </div>

            <div className={sectionStyles.selectColumns}>
              <Field
                bulkSelectText="All classes"
                component={CheckboxGroupDropdown}
                disabled={this.props.loadingInitialValues}
                formChangeValue={this.props.formChangeValue}
                handleChange={this.handleClassroomChange}
                hasBulkSelect
                legendText="Select classroom(s) to assign to."
                legendSrOnly
                name="classroom_ids"
                options={this.getClassroomOptions()}
                showErrorMessage={false}
                toggleText={this.state.classroomToggleText}
                validate={this.classroomRequired}
              />

              <Field
                bulkSelectText="All students"
                component={CheckboxGroupDropdown}
                disabled={
                  this.props.loadingInitialValues ||
                  this.state.selectedClassroomIds.length !== 1 ||
                  this.state.studentOptions.length === 0
                }
                formChangeValue={this.props.formChangeValue}
                handleChange={this.handleStudentChange}
                hasBulkSelect
                legendText="Select student(s) to assign to."
                legendSrOnly
                name="student_ids"
                options={this.state.studentOptions}
                showErrorMessage={false}
                toggleText={this.state.studentToggleText}
                validate={this.studentRequired}
              />
            </div>
          </div>

          <div className={sectionStyles.fieldContainer}>
            {this.renderError()}
          </div>
        </div>
      </Section>
    );
  }
}
