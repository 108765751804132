import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import AddForm from './AddForm';

import styles from '../Modal.module.scss';

export default function AddModal({
  allDistrictCourseCodes, closeModal, getDistrictCourseCodesForProgram, isOpen,
  programs, subscriberId, updateTable, updatePath
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      headerText="Add Course Mapping"
      isOpen={isOpen}
    >
      <AddForm
        allDistrictCourseCodes={allDistrictCourseCodes}
        closeModal={closeModal}
        getDistrictCourseCodesForProgram={getDistrictCourseCodesForProgram}
        programs={programs}
        subscriberId={subscriberId}
        updateTable={updateTable}
        updatePath={updatePath}
      />
    </Modal>
  );
}

AddModal.propTypes = {
  allDistrictCourseCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func.isRequired,
  getDistrictCourseCodesForProgram: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  programs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    full_title_with_edition: PropTypes.string
  })),
  subscriberId: PropTypes.number,
  updateTable: PropTypes.func.isRequired,
  updatePath: PropTypes.string.isRequired
};

AddModal.defaultProps = {
  programs: [],
  subscriberId: null
};
