import React, { useEffect } from 'react';
import { Position } from '@photo-sphere-viewer/core';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import PanoramicImageUploadModal from './PanoramicImageUploadModal';
import usePanoramicImage from './usePanoramicImage';
import HiddenHotspots from './PanoramicImage/HiddenHotspots';
import PanoramicImageViewer from './PanoramicImage/PanoramicImageViewer';

interface SlideObjectPanoramicImageProps {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  interactable: boolean;
  modalManager: ModalManager;
  slideObject: {
    id: string;
  };
  thumbnail: boolean;
  setPanoramicCamera: (pos: Position) => void;
}

const SlideObjectPanoramicImage = ({
  containerRef,
  interactable,
  modalManager,
  slideObject,
  thumbnail,
  setPanoramicCamera,
}: SlideObjectPanoramicImageProps) => {
  const { data: panoramicImage } = usePanoramicImage(slideObject.id);
  const locale = useSlideShowContext(state => state.locale);

  useEffect(() => {
    if (containerRef?.current?.style) {
      // eslint-disable-next-line no-param-reassign
      containerRef.current.style.height = '100%';
    }
  }, [containerRef]);

  if (thumbnail) return <img alt="thumbnail" className="tw-w-full tw-h-full" src={panoramicImage?.image?.url} />;

  if (modalManager.isOpen && interactable) {
    return (
      <PanoramicImageUploadModal
        currentValues={panoramicImage}
        modalManager={modalManager}
        slideObjectId={slideObject.id}
      />
    );
  }

  if (!panoramicImage) return null;

  return (
    <>
      <HiddenHotspots panoramicImage={panoramicImage} locale={locale} />
      <PanoramicImageViewer setPanoramicCamera={setPanoramicCamera} panoramicImage={panoramicImage} locale={locale} />
    </>
  );
};

export default SlideObjectPanoramicImage;
