import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';

import Footer from 'common/Wizard/Footer';
import { renderErrors, SubmitError } from 'common/Forms/Utils';

import Options from './Options';

export default class MethodStep extends Component {
  static propTypes = {
    existingModels: PropTypes.arrayOf(PropTypes.string).isRequired,
    next: PropTypes.func,
    ssoModelCreatePath: PropTypes.string.isRequired,
    subscriber_id: PropTypes.number.isRequired,
    updateSSOModel: PropTypes.func.isRequired
  };

  static defaultProps = {
    next: () => {}
  };

  static isFormEmpty(values) {
    return values.method === null;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    switch (values.method) {
      case 'saml':
        this._createSSOModel('SamlSetting');
        break;
      case 'lti':
        this._createSSOModel('LtiKey');
        break;
      case 'clever':
        this._createSSOModel('Clever');
        break;
      default:
        console.log('Method not supported');
    }
  }

  _createSSOModel(methodType) {
    return (
      Axios
        .post(this.props.ssoModelCreatePath, this._getParams(methodType))
        .then((response) => {
          this.props.updateSSOModel(response.data.data);
          this.props.next();
          return null;
        }).catch(error => (
          { [FORM_ERROR]: renderErrors(error.response.data.errors) }
        ))
    );
  }

  _getParams(methodType) {
    let modelAttributes;

    if (methodType === 'Clever') {
      modelAttributes = null;
    }
    else {
      modelAttributes = { subscriber_id: this.props.subscriber_id };
    }

    return {
      sso_model:
        {
          model_type: methodType,
          subscriber_id: this.props.subscriber_id,
          model_attributes: modelAttributes
        }
    };
  }

  render() {
    return (
      <div>
        <p className="mb50">To begin your Single Sign On set up, please select one of the methods below.</p>
        <Form
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={{ method: null }}
        >
          {({
              handleSubmit, submitError, values
            }) => (
              <form onSubmit={handleSubmit}>
                <Options
                  existingModels={this.props.existingModels}
                  hasCompletedCleverRostering={this.props.hasCompletedClever}
                />

                <SubmitError error={submitError} />

                <Footer disabled={MethodStep.isFormEmpty(values)} />
              </form>
          )}
        </Form>
      </div>
    );
  }
}
