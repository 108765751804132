import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { CancelButton, SubmitButton } from 'common/Modal';
import Axios from 'axios';
import { pollEndpoint } from '../../../modules/TCIUtils';
import showToast from '../../common/Toast';

export default class RemoveProgramButton extends Component {
  static propTypes = {
    confirmPath: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    programId: PropTypes.number.isRequired,
    removeProgramsPath: PropTypes.string.isRequired,
    stafferId: PropTypes.number.isRequired,
    stafferPath: PropTypes.string.isRequired
  };

  static removeFinished(res) {
    return res.data.staffer.classroom_destroy_finished;
  }

  constructor(props) {
    super(props);

    this.state = {
      deletingSeat: false,
      modalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeProgram = this.removeProgram.bind(this);
    this.onRemoveFinished = this.onRemoveFinished.bind(this);
    this.onError = this.onError.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  removeProgram() {
    this.setState({ deletingSeat: true });

    const postData = { program_ids: [this.props.programId], staffer_ids: [this.props.stafferId] };
    return Axios.post(this.props.removeProgramsPath, postData)
      .then(() => {
        const pollingData = {
          endpointUrl: this.props.stafferPath,
          timeout: 500000,
          until: RemoveProgramButton.removeFinished
        };

        return pollEndpoint(pollingData)
          .then(this.onRemoveFinished)
          .catch(this.onError);
      })
      .catch(this.onError);
  }

  onRemoveFinished() {
    window.location.replace(this.props.confirmPath);
  }

  onError() {
    this.setState({ deletingSeat: false, modalOpen: false });
    showToast('Program removal failed', { autoClose: 4000, msgType: 'error' });
  }

  confirmationModal() {
    return (
      <Modal
        closeModal={this.closeModal}
        isOpen={this.state.modalOpen}
        headerText="Remove Program"
        closeable={!this.state.deletingSeat}
      >
        Removing this program will remove all classroom, student, and program data for this teacher. Continue?

        <hr />

        <footer>
          {!this.state.deletingSeat && <CancelButton onCancel={this.closeModal} />}
          <SubmitButton
            onSubmit={this.removeProgram}
            isSubmitting={this.state.deletingSeat}
            text="Continue"
            submittingText="Removing program..."
          />
        </footer>
      </Modal>
    );
  }

  removalButton() {
    return (
      <button
        type="button"
        className="action pos_fr"
        onClick={this.openModal}
        disabled={this.props.disabled}
      >
        Remove
      </button>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.removalButton()}
        {this.confirmationModal()}
      </React.Fragment>
    );
  }
}
