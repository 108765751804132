import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styles from 'admin/SlideShow/Builder/BackgroundDropdownActions/BackgroundDropdownActions.module.scss';
import BackgroundPositionManager from 'common/BackgroundPositionManager';
import BuilderContext from 'common/Context';
import Dropdown from 'common/Dropdown';
import * as Routes from 'routes';
import AddBackgroundImageModal from './AddBackgroundImageModal';
import RemoveBackgroundImageModal from './RemoveBackgroundImageModal';
import EditBackgroundImageButton from './EditBackgroundImageButton';
import ImageFitButton from './ImageFitButton';

const BackgroundImageButton = ({ background, questionId, spanishImagesInMicAndDd, userType }) => {
  const { backgroundDispatch, backgroundRef, modalManager } = useContext(BuilderContext);
  const hasBackgroundImage = background && background.id && background.url;
  const handleClick = () => { modalManager.open(); };
  const headerText = `${hasBackgroundImage ? 'Remove' : 'Add'} Background Image`;
  const Modal = hasBackgroundImage ? RemoveBackgroundImageModal : AddBackgroundImageModal;
  const questionRoute = Routes.plato_api_question_path(questionId);

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    modalManager.open();
  };

  const afterSubmit = () => {
    Axios.get(questionRoute).then((response) => {
      const backgroundImage = response.data.question.background_image;
      backgroundDispatch({ payload: { backgroundImage }, type: 'SET' });
      modalManager.close();
    }).catch((response) => {
      console.log(response);
      modalManager.close();
    });
  };

  const submitHandler = ({ backgroundPosition: bgPosition, customPosition }) => {
    const position = bgPosition === 'custom' ? `center ${customPosition}` : bgPosition;
    Axios.patch(questionRoute, {
      background_position: position,
    }).then(() => {
      backgroundDispatch({ payload: { backgroundPosition: position }, type: 'SET_POSITION' });
    });
  };

  const backgroundSizeSubmitHandler = (bgSize) => {
    Axios.patch(questionRoute, {
      background_size: bgSize,
    }).then(() => {
      backgroundDispatch({ payload: { backgroundSize: bgSize }, type: 'SET_SIZE' });
    });
  };

  return (
    <>
      <div className="inline_block">
        <Dropdown
          buttonClass="btn btn--sm btn--green dropdown-toggle inline_block"
          toggleText="Background"
        >
          {hasBackgroundImage && (
            <EditBackgroundImageButton imageModelId={background.id} userType={userType} />
          )}
          <button
            className="dropdown-item !tw-cursor-pointer"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            type="button"
            tabIndex="0"
          >
            {headerText}
          </button>
          {hasBackgroundImage && (
            <Dropdown
              buttonClass={`${styles.submenu} dropdown-item !tw-cursor-pointer`}
              dropdownPosition="right"
              toggleText={(
                <>
                  Change Position
                  <i className={`fa fa-angle-right ${styles.angleRight}`} />
                </>
              )}
            >
              <BackgroundPositionManager
                object={{ backgroundPosition: background.position }}
                submitHandler={submitHandler}
                objectRef={backgroundRef}
              />
            </Dropdown>
          )}
          {hasBackgroundImage && (
            <Dropdown
              buttonClass={`${styles.submenu} dropdown-item !tw-cursor-pointer`}
              dropdownPosition="right"
              toggleText={(
                <>
                  Image Fit
                  <i className={`fa fa-angle-right ${styles.angleRight}`} />
                </>
              )}
            >
              <ImageFitButton submitHandler={backgroundSizeSubmitHandler} size={background.size} />
            </Dropdown>
          )}
          <Modal
            afterSubmit={afterSubmit}
            questionId={questionId}
            backgroundId={background.id}
            spanishImagesInMicAndDd={spanishImagesInMicAndDd}
          />
        </Dropdown>
      </div>
    </>
  );
};

BackgroundImageButton.propTypes = {
  background: PropTypes.shape({
    id: PropTypes.number,
    position: PropTypes.string,
    size: PropTypes.string,
    url: PropTypes.string,
  }),
  questionId: PropTypes.number.isRequired,
  spanishImagesInMicAndDd: PropTypes.bool,
};

BackgroundImageButton.defaultProps = {
  background: { id: null, url: null },
  spanishImagesInMicAndDd: false,
};

export default BackgroundImageButton;
