import React from 'react';
import PropTypes from 'prop-types';
import { AsyncSelectField, NumberField, CheckboxField } from 'common/Forms';
import { required } from 'common/Forms/Validators';
import * as Routes from 'routes';

const ModalBody = ({ initialVideoFileName, showWidth, showAutoplayOption, showVideoLessonOption }) => (
  <div style={{ marginBottom: '150px' }}>
    <AsyncSelectField
      name="video_id"
      label="Video"
      placeholder="Search/Select a video..."
      searchPath={value => Routes.plato_api_search_videos_path({ search: { video_file_name_cont: value || initialVideoFileName } })}
      formatOptions={videos => videos.map(video => ({ label: video.video_file_name, value: video.id }))}
      required
      validate={required}
    />
    {showWidth && <NumberField name="width" required validate={required} />}
    {showVideoLessonOption && <CheckboxField name="video_lesson" label="Video Lesson?" />}
    {showAutoplayOption && <CheckboxField name="autoplay" label="Enable Auto-Play" />}
  </div>
);

ModalBody.propTypes = {
  initialVideoFileName: PropTypes.string,
  showAutoplayOption: PropTypes.bool.isRequired,
  showVideoLessonOption: PropTypes.bool.isRequired,
  showWidth: PropTypes.bool.isRequired
};


ModalBody.defaultProps = {
  initialVideoFileName: '',
  showAutoplayOption: false,
  showVideoLessonOption: false,
  showWidth: false
};

export default ModalBody;
