import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import VideoPlayer from 'common/VideoPlayer';
import I18n from 'i18n';
import Callout from './Callout';
import CompellingQuestion from './CompellingQuestion';
import styles from './Unit.module.scss';
import { NotebookLinks } from '../../../common/NavigationCard/NotebookLinks';

const Unit = ({
  id, programId, studentTextImage, title, position, storyline, compellingQuestion, video,
  callOutText, callOutColor, unitId
}) => {
  const unitPath = Routes.shared_program_unit_path(programId, id);

  const simpleNavUnit = () => (
    <div className={`card ${styles.unit}`}>
      <Callout color={callOutColor} text={callOutText} />
      <div className="dsp-flex">
        <figure>
          <img src={studentTextImage} alt="" />
        </figure>
        <div className={styles.centerColumn}>
          <a href={unitPath} className={`bold ${styles.unitTitle}`}>{title}</a>
          <div className={styles.sectionHeader}>
            {I18n.t('unit')} {position}
          </div>
          <div className={styles.centerBtn}>
            <a href={unitPath} className="btn btn--green mt10 mb10">{I18n.t('explore_unit')}</a>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <CompellingQuestion content={compellingQuestion} />
        <div className={styles.sectionHeader} />
        <p>{storyline}</p>
        <div className="center">
          <VideoPlayer video={video} width="90%" />
        </div>
      </div>
      <div className={styles.unitProjectLinks}>
        <NotebookLinks
          programId={programId}
          unitId={unitId}
        />
      </div>
    </div>
  );

  const renderChosenNav = () => (
    <Fragment>
      {simpleNavUnit()}
    </Fragment>
  );

  return (
    <Fragment>
      {renderChosenNav()}
    </Fragment>
  );
};

Unit.propTypes = {
  callOutColor: PropTypes.string,
  callOutText: PropTypes.string,
  compellingQuestion: PropTypes.string,
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  storyline: PropTypes.string.isRequired,
  studentTextImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  unitId: PropTypes.string,
  video: PropTypes.shape({
    caption_url: PropTypes.string,
    is_silent: PropTypes.bool,
    poster_url: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

Unit.defaultProps = {
  callOutColor: null,
  callOutText: null,
  compellingQuestion: null,
  unitId: '',
};

export default Unit;
