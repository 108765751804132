import React from 'react';
import StandardButton from './StandardButton';

export interface ISubmitButtonProps {
  color?: ButtonColors;
  children?: ReactChildren;
  className?: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  submitText?: string;
  submitting?: boolean;
  submittingText?: string;
  variant?: 'rounded-right';
}

const SubmitButton = ({
  color = 'purple',
  children,
  className = '',
  disabled = false,
  label = '',
  onClick = () => {},
  submitting = false,
  submittingText = 'Submitting…',
  variant,
}: ISubmitButtonProps) => {
  if (submitting) {
    return (
      <StandardButton
        label={label}
        className={className}
        color={color}
        variant={variant}
        disabled
      >
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        {' '}
        {submittingText}
      </StandardButton>
    );
  }

  return (
    <StandardButton
      className={className}
      color={color}
      disabled={disabled}
      label={label}
      onClick={onClick}
      variant={variant}
      type="submit"
    >
      {children}
    </StandardButton>
  );
};

export default SubmitButton;
