import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import formStyles from '../../common/Classes/Form.module.scss';
import { RequiredAsterisk } from '../../common/Forms/Utils';
import styles from './Form.module.scss';

export default function SeatCountInput({
  id, name, label, licenseName, seatsAvailable, seatsInUse, tablePath, actionName
}) {
  function renderInput(meta, input) {
    const inputClasses = `${formStyles.textInput} ${styles.seatCountInput} ${!meta.pristine && meta.error ? styles.hasError : ''}`;

    return (
      <input
        id={id}
        className={inputClasses}
        disabled={seatsAvailable <= 0}
        {...input}
      />
    );
  }

  function renderErrors(meta) {
    if (meta.error && !meta.pristine) {
      return (
        <div className={formStyles.fieldError}>{meta.error}</div>
      );
    }
    return null;
  }

  function seatInput(params) {
    const tablePathWithFilter = tablePath.replace('license_name', licenseName);

    return (
      <div className={formStyles.row}>
        <div className={formStyles.fieldContainer}>
          <label htmlFor={id} className={formStyles.label}>
            {label} <RequiredAsterisk />
          </label>
          {renderInput(params.meta, params.input)}
          <span className="ml10 gray">
            {seatsAvailable || 'No seats'} available for {actionName}
            <br />
            {seatsAvailable ? seatsInUse : 'All'} in use
            (<a href={tablePathWithFilter} target="_blank">manage</a>)
          </span>
        </div>
        {renderErrors(params.meta)}
      </div>
    );
  }

  return (
    <Field
      className={`${formStyles.input} ${formStyles.textInput}`}
      name={name}
      render={seatInput}
    />
  );
}

SeatCountInput.propTypes = {
  actionName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  licenseName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  seatsAvailable: PropTypes.number.isRequired,
  seatsInUse: PropTypes.number.isRequired,
  tablePath: PropTypes.string.isRequired,
};

SeatCountInput.defaultProps = {
  actionName: 'change'
};
