import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import DataTable from 'components/common/DataTable';
import ActionsDropdown from 'components/common/ActionsDropdown';
import axios from 'axios';
import * as Routes from 'modules/routes';
import TableActions from './TableActions';
import GameTemplate from '../../interfaces/GameTemplate';
import { ILessonGamesGlossaryTerm } from './interfaces';
import useLessonGamesStore from '../../../stores/useLessonGamesStore';

type TableTypes = {
  gameTemplates: GameTemplate[],
  glossaryTerms: ILessonGamesGlossaryTerm[],
  lessonId: number;
  programId: number;
  hasChallenge?: boolean;
  hasVocabGame: boolean;
};

const LessonGamesTable = ({
  gameTemplates,
  glossaryTerms,
  lessonId,
  programId,
  hasChallenge,
  hasVocabGame: initialHasVocabGame,
}: TableTypes) => {
  const [gamesTableData, setGamesTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [hasVocabGame, setHasVocabGame] = useState(initialHasVocabGame);
  const { setStore } = useLessonGamesStore();

  useEffect(() => {
    setStore({
      gameTemplates,
      glossaryTerms,
      lessonId,
      programId,
      refetch: () => {
        setShouldFetch(true);
        setHasVocabGame(true);
      },
    });
  }, []);

  useEffect(() => {
    if (!shouldFetch) return;

    setIsLoading(true);

    axios
      .get(Routes.plato_api_lesson_games_games_path({ lesson_id: lessonId, program_id: programId }))
      .then((res) => {
        setGamesTableData(res.data.data);
        setIsLoading(false);
        setShouldFetch(false);
      });
  }, [shouldFetch]);

  const editPath = (rowData) => {
    if (rowData.game_type === 'Lesson Review Game') {
      return (
        Routes.edit_admin_lesson_challenge_path(lessonId, { program_id: programId })
      );
    }

    return (
      Routes.edit_admin_game_path(rowData.id, { program_id: programId, lesson_id: lessonId })
    );
  };

  const actionsDropdown = rowData => (
    <ActionsDropdown id={`slide-group-${rowData.id}-actions`} toggleText="Actions">
      <a
        className="dropdown-item"
        href={editPath(rowData)}
      >
        <i aria-hidden="true" className="fa fa-edit" /> Edit
      </a>
    </ActionsDropdown>
  );

  return (
    <div className="pb20">
      <TableActions hasChallenge={hasChallenge} hasVocabGame={hasVocabGame} />
      <DataTable
        columns={['name', 'game_type', 'published', 'question_count', 'actions']}
        columnDefs={[
          {
            sortable: false,
            targets: 0,
            createdCell: (td, _cellData, rowData) => {
              ReactDOM.render(
                <a href={editPath(rowData)}>
                  {rowData.name}
                </a>,
                td,
              );
            },
          },
          { sortable: false, targets: 1 },
          { sortable: false, targets: 2 },
          { sortable: false, targets: 3 },
          {
            sortable: false,
            targets: 4,
            createdCell: (td, _cellData, rowData) => {
              ReactDOM.render(
                actionsDropdown(rowData),
                td,
              );
            },
          },
        ]}
        columnMapping={['name', 'type', 'status', 'questions']}
        defaultOrder={[0, 'asc']}
        handleResourcesManually
        info={false}
        paging={false}
        resources={gamesTableData}
        sortable={false}
        isLoading={isLoading}
      />
    </div>
  );
};

export default LessonGamesTable;
