import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import ExchangeForm from './ExchangeForm';

export default function Exchange(props) {
  return (
    <Modal
      closeModal={props.closeModal}
      isOpen={props.modalIsOpen}
      headerText="Exchange"
    >
      <ExchangeForm
        closeModal={props.closeModal}
        programsPath={props.programsPath}
        exchangePath={props.exchangePath}
        individualSubscriptionPath={props.individualSubscriptionPath}
        isSysadmin={props.isSysadmin}
        programs={props.programs}
        rowData={props.rowData}
        staffersTablePath={props.staffersTablePath}
        studentsTablePath={props.studentsTablePath}
        updateTable={props.updateTable}
        licenses={props.licenses}
      />
    </Modal>
  );
}

Exchange.propTypes = {
  closeModal: PropTypes.func.isRequired,
  exchangePath: PropTypes.string.isRequired,
  individualSubscriptionPath: PropTypes.string.isRequired,
  isSysadmin: PropTypes.bool.isRequired,
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  modalIsOpen: PropTypes.bool,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  programsPath: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    expires_on: PropTypes.string,
    program_full_title_with_edition: PropTypes.string,
    program_id: PropTypes.number,
    purchase_order_id: PropTypes.number,
    purchase_order_number: PropTypes.string,
    student_seats_count: PropTypes.number,
    teacher_seats_count: PropTypes.number,
    transfer_notes: PropTypes.string
  }).isRequired,
  staffersTablePath: PropTypes.string.isRequired,
  studentsTablePath: PropTypes.string.isRequired,
  updateTable: PropTypes.func.isRequired
};

Exchange.defaultProps = {
  modalIsOpen: false,
  programs: []
};
