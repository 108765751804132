import React, { useEffect } from 'react';
import axios from 'axios';
import * as Routes from 'modules/routes';
import { toCamelCase } from 'modules/TCIUtils';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';
import BackgroundPositionManager from '../../../../../../common/BackgroundPositionManager';
import styles from '../CustomFroalaCommands/ElementInsert/ElementInsert.module.scss';
import { getInitialSliderPosition, resetElementStyle, getDefaultValue, DisplayTooltip } from './utils';
import WidthManager from './WidthManager';

type PositionMenuProps = {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  positionMenuRef: React.MutableRefObject<HTMLDivElement>;
  relocate: Function;
  editorInstance: { undo: { saveStep: () => void; } };
  elementToUpdate: HTMLDivElement;
  htmlElementId?: number;
  setElementToUpdate: (newElement: HTMLDivElement) => void;
  setEditMode: (nextState: boolean) => void;
};

async function updateHtmlElement(id: number, tooltipStyles: { right?: string, top?: string, bottom?: string, left?: string, width: string }) {
  const res = await axios.patch(Routes.plato_api_html_element_path(id, {
    tooltip_styles: tooltipStyles,
  }));

  const tooltipButtonElement: ITooltipButton = toCamelCase(res.data.data);

  return tooltipButtonElement;
}

async function updateWidth(id: number, width: string) {
  const res = await axios.patch(Routes.plato_api_html_element_path(id, {
    width,
  }));

  const tooltipButtonElement: ITooltipButton = toCamelCase(res.data.data);

  return tooltipButtonElement;
}

const PositionMenu = ({ containerRef, positionMenuRef, relocate, editorInstance, elementToUpdate, htmlElementId, setElementToUpdate, setEditMode }: PositionMenuProps) => {
  useEffect(() => {
    relocate();
    return () => { setEditMode(false); };
  }, []);

  const onWidthSubmit = async ({ width: newWidth }) => {
    const newElement = document.createElement('div');
    const newWidthInPx = `${newWidth}px`;
    newElement.innerHTML = elementToUpdate.innerHTML;
    newElement.style.width = newWidthInPx;

    ['top', 'right', 'bottom', 'left'].forEach((position) => {
      newElement.style[position] = elementToUpdate.style[position];
    });

    setElementToUpdate(newElement);

    if (!htmlElementId) {
      elementToUpdate.style.width = newWidthInPx;
      editorInstance.undo.saveStep();
      return;
    }

    await updateWidth(htmlElementId, newWidthInPx);

    const query = `.current-slide [data-html-element-id="${htmlElementId}"]`;
    const el: HTMLElement = document.querySelector(query);

    if (el) el.dataset.updatedAt = Date.now().toString();
    editorInstance.undo.saveStep();
  };

  const onSubmit = async ({ backgroundPosition, customPosition, customPositionY }) => {
    const isCustom = backgroundPosition === 'custom';
    let horizontalAttr = 'left';
    let verticalAttr = 'top';
    let horizontalVal: string | number = '0';
    let verticalVal: string | number = '0';

    if (htmlElementId) {
      if (isCustom) {
        const { offsetHeight, offsetWidth } = containerRef.current;
        horizontalVal = `${(customPosition / 100) * (offsetWidth - 290)}px`;
        verticalVal = `${(customPositionY / 100) * (offsetHeight - elementToUpdate.offsetHeight - 50)}px`;
      } else {
        const [left, top] = backgroundPosition.split(' ');

        horizontalAttr = left === '100' ? 'right' : 'left';
        verticalAttr = top === '100' ? 'bottom' : 'top';
        horizontalVal = horizontalAttr === 'right' ? '0%' : `${left}%`;
        verticalVal = verticalAttr === 'bottom' ? '0%' : `${top}%`;
      }

      const newElement = document.createElement('div');
      newElement.innerHTML = elementToUpdate.innerHTML;
      newElement.style[horizontalAttr] = horizontalVal;
      newElement.style[verticalAttr] = verticalVal;
      newElement.style.width = elementToUpdate.style.width;

      setElementToUpdate(newElement);

      const newStyles = { [horizontalAttr]: horizontalVal, [verticalAttr]: verticalVal, width: elementToUpdate.offsetWidth.toString() };

      await updateHtmlElement(htmlElementId, newStyles);

      const query = `.current-slide [data-html-element-id="${htmlElementId}"]`;
      const el: HTMLElement = document.querySelector(query);

      if (el) el.dataset.updatedAt = Date.now().toString();
      editorInstance.undo.saveStep();
      return;
    }

    if (backgroundPosition === 'custom') {
      resetElementStyle(elementToUpdate);

      const { offsetHeight, offsetWidth } = containerRef.current;

      elementToUpdate.style.left = `${(customPosition / 100) * (offsetWidth - 290)}px`;
      elementToUpdate.style.top = `${(customPositionY / 100) * (offsetHeight - elementToUpdate.offsetHeight - 50)}px`;
      editorInstance.undo.saveStep();
      return;
    }

    const [left, top] = backgroundPosition.split(' ');

    resetElementStyle(elementToUpdate);
    elementToUpdate.classList.add(styles.inlineStyles);

    if (left === '100') {
      elementToUpdate.style.left = '';
      elementToUpdate.style.right = '0%';
    }
    else {
      elementToUpdate.style.left = `${left}%`;
    }

    if (top === '100') {
      elementToUpdate.style.top = '';
      elementToUpdate.style.bottom = '0%';
    }
    else {
      elementToUpdate.style.top = `${top}%`;
    }

    editorInstance.undo.saveStep();
  };

  return (
    <div ref={positionMenuRef}>
      <WidthManager
        initialValue={elementToUpdate.style.width.replace('px', '') || '250'}
        submitHandler={onWidthSubmit}
      />
      <BackgroundPositionManager
        defaultValue={getDefaultValue(elementToUpdate)}
        includeVertical
        initialSliderPosition={getInitialSliderPosition(elementToUpdate, containerRef, 'left')}
        initialSliderPositionY={getInitialSliderPosition(elementToUpdate, containerRef, 'top')}
        minWidth={false}
        object={{}}
        options={[
          { label: 'Top-Left', value: '0 0' },
          { label: 'Top-Right', value: '100 0' },
          { label: 'Bottom-Left', value: '0 100' },
          { label: 'Bottom-Right', value: '100 100' },
          { label: 'Custom', value: 'custom' },
        ]}
        relocate={relocate}
        submitHandler={onSubmit}
      />
      <DisplayTooltip isHtmlElement={!!htmlElementId} element={elementToUpdate} containerRef={containerRef} />
    </div>
  );
};

export default PositionMenu;
