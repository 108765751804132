import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../Forms/SubmitButton';

export default function PrimaryFooterButton(props) {
  if (props.isSubmit) {
    return (
      <SubmitButton
        disableWithTooltipText={props.disableWithTooltipText}
        submitButtonText={props.buttonText}
        submitDisabled={props.disabled}
        submitting={props.submitting}
        submittingText={props.submittingText}
        wrapperClassName={props.wrapperClassName}
        buttonClassName={props.buttonClassName}
      />
    );
  }

  return (
    <button
      className={props.buttonClassName}
      disabled={props.submitting || props.disabled}
      onClick={props.onClick}
      type="button"
    >
      {props.submitting && <i className="fa fa-spinner fa-spin mr5" aria-hidden="true" />}
      {props.buttonText}
    </button>
  );
}

PrimaryFooterButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  disableWithTooltipText: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submittingText: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string
};

PrimaryFooterButton.defaultProps = {
  buttonClassName: 'btn btn--purple',
  disableWithTooltipText: '',
  wrapperClassName: ''
};
