import update from 'immutability-helper';
import { DropZoneType, VocabDropZoneType } from '@/components/common/Forms/Questions/DragAndDrop/types';
import { UNITS, getDimensions } from '@/components/common/Forms/Questions/DragAndDrop/utils';
import getNextLetter from './getNextLetter';

export type DropZoneActions = 'ADD' | 'UPDATE' | 'DELETE' | 'MOVE_WITH_ARROW_KEY' | 'COPY' | 'UPDATE_VOCAB' | 'MATCH_DROPZONES_TO_TEXT_AREAS';
export type UpdateDropZonePayload = {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
  title: string;
};

const addDropZone = (dropZones, dropzone = undefined, shape = 'rectangle') => {
  const nextLetter = getNextLetter(dropZones);

  const newDropZone = dropzone || {
    [nextLetter]: {
      left: 20,
      shape,
      title: nextLetter.toUpperCase(),
      top: 12,
      type: 'dropZone',
      ...getDimensions(shape, 5, 20),
    },
  };

  return update(dropZones, {
    $merge: newDropZone,
  });
};

const updateDropZone = (dropZones, { payload: { id, left, shape, top, width, height, title } }) => {
  const updatedDropZone = {
    left,
    shape,
    title,
    top,
    ...getDimensions(shape, height || dropZones[id].height, width || dropZones[id].width),
  };

  return update(dropZones, {
    [id]: {
      $merge: updatedDropZone,
    },
  });
};


const deleteDropZone = (dropZones, { payload: { id } }) => update(dropZones, { $unset: [id] });

const moveWithKey = (dropZones, { payload: { id, key, left, shape, title, top, width, height } }) => {
  switch (key) {
    case 'ArrowUp':
      return updateDropZone(dropZones, { payload: { height, id, left, shape, title, top: top - 1, width } });
    case 'ArrowDown':
      return updateDropZone(dropZones, { payload: { height, id, left, shape, title, top: top + 1, width } });
    case 'ArrowLeft':
      return updateDropZone(dropZones, { payload: { height, id, left: left - 1, shape, title, top, width } });
    case 'ArrowRight':
      return updateDropZone(dropZones, { payload: { height, id, left: left + 1, shape, title, top, width } });
    default:
      return dropZones;
  }
};

const copyDropzone = (dropZones, { payload: { id } }) => {
  const nextLetter = getNextLetter(dropZones);

  const nextDropZone = {
    [nextLetter]: {
      ...dropZones[id],
      left: 20,
      top: 12,
    },
  };

  return addDropZone(dropZones, nextDropZone);
};

const updateVocabDropzones = (dropZones: { [key: string]: DropZoneType }, { payload, textAreaDispatch, shape = 'rectangle' }) => {
  const payloadIds = payload.map(item => item.id);
  const dropzoneValues = Object.values(dropZones);

  let nextDropzones = { ...dropZones };
  let startingY = 0;

  // Get the top positioning of the lowest dropzone to use as a starting position
  Object.entries(dropZones).forEach(([key, dropzone]: [string, DropZoneType]) => {
    const offset = payloadIds.includes(key) ? 0 : UNITS.Y.THREE;

    if (dropzone.top > startingY) startingY = dropzone.top + offset;
  });

  const inGridDropzones = payload
    .filter(dropzone => dropzoneValues.some(dz => dz.id === dropzone.id))
    .map(dropzone => ({
      [dropzone.id]: {
        ...dropZones[dropzone.id] || {},
        ...dropzone,
        coordinates: {},
        dimensions: {},
        title: dropzone.id.toUpperCase(),
        ...getDimensions(shape, dropZones[dropzone.id].height, dropZones[dropzone.id].width),
        shape,
      },
    }));

  // Since we rely on the index for offsetting the top coordinate,
  // handle new dropzones separately from dropzones currently in the grid
  const newDropzones = payload
    .filter(dropzone => !dropzoneValues.some(dz => dz.id === dropzone.id))
    .map((dropzone, i) => {
      const indexForOffset = inGridDropzones.length > 0 ? i + 1 : i;

      return ({
        [dropzone.id]: {
          ...dropZones[dropzone.id] || {},
          ...dropzone,
          ...{ left: 3, top: startingY + (indexForOffset * UNITS.X.TWO) },
          ...getDimensions(shape, UNITS.Y.THREE, UNITS.X.THIRTEEN),
          title: dropzone.id.toUpperCase(),
          shape,
        },
      });
    });

  const mappedDropzones = [...inGridDropzones, ...newDropzones];

  mappedDropzones.forEach((dz: { [key: string]: VocabDropZoneType }, i) => {
    const key = Object.keys(dz)[0];

    if (i === 0) dz[key].top = UNITS.X.VOCAB_TOP;
  });

  mappedDropzones
    .forEach((dropzone: { [key: string]: VocabDropZoneType }) => {
      const values = Object.values(dropzone)[0];

      if (values.vocabId === null && values.id !== 'a') {
        const id = Object.keys(dropzone)[0];
        nextDropzones = deleteDropZone(nextDropzones, { payload: { id } });
      } else {
        nextDropzones = addDropZone(nextDropzones, dropzone, shape);
      }

      const p = {
        [values.id]: {
          top: values.top,
          left: values.left + values.width + 3,
          height: 'auto',
          width: UNITS.X.FORTY_FIVE,
          text: '',
          type: 'textArea',
          vocabId: values.vocabId,
          shape: values.shape,
        },
      };

      textAreaDispatch({ type: 'ADD_VOCAB_TEXT_AREA', payload: p });
    });

  return nextDropzones;
};

const matchDropzonesToTextareas = (dropZones: { [key: string]: UpdateDropZonePayload }) => {
  const nextDropzones = Object.values(dropZones).reduce((acc, cur) => {
    const textarea = document.querySelector(`[aria-label="Text area ${cur.id.toUpperCase()}"]`) as HTMLDivElement;

    if (!textarea) return acc;

    const top = parseFloat(textarea.style.top.replace('%', '')) - (cur.height / 2);

    const newDropzone = { ...cur, top };

    return { ...acc, [newDropzone.id]: newDropzone };
  }, {});

  return update(dropZones, { $merge: nextDropzones });
};

const updateAllDropZones = (dropZones, updateData) => {
  let updatedDropZones = { ...dropZones };

  const ids = Object.keys(updatedDropZones);
  const values = Object.values(updatedDropZones);

  values.forEach((value: DropZoneType, index) => {
    updatedDropZones = updateDropZone(updatedDropZones, {
      payload: {
        id: ids[index],
        left: value.left,
        top: value.top,
        title: value.title,
        ...getDimensions(value.shape, value.height, value.width),
        ...updateData,
      },
    });
  });

  return updatedDropZones;
};

const updateDropZoneShapes = (dropZones, { payload: { dropZoneShape } }: any) => updateAllDropZones(dropZones, { shape: dropZoneShape });

const dropZoneReducer = (dropZones, action) => {
  switch (action.type) {
    case 'ADD':
      return addDropZone(dropZones, undefined, action.shape);
    case 'UPDATE_VOCAB':
      return updateVocabDropzones(dropZones, action);
    case 'UPDATE':
      return updateDropZone(dropZones, action);
    case 'DELETE':
      return deleteDropZone(dropZones, action);
    case 'MOVE_WITH_ARROW_KEY':
      return moveWithKey(dropZones, action);
    case 'COPY':
      return copyDropzone(dropZones, action);
    case 'MATCH_DROPZONES_TO_TEXT_AREAS':
      return matchDropzonesToTextareas(dropZones);
    case 'UPDATE_DROP_ZONE_SHAPE':
      return updateDropZoneShapes(dropZones, action);
    default:
      return dropZones;
  }
};

export default dropZoneReducer;
