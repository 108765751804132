import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from 'common/ActionsDropdown';

export default function ClassesAdminDropdown({ rowData, openModal }) {
  return (
    <ActionsDropdown>
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'edit')}
      >
        <i aria-hidden="true" className="fa fa-edit" /> Edit Class Details
      </a>

      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'students')}
      >
        <i aria-hidden="true" className="fa fa-user" /> Manage Students
      </a>

      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'teachers')}
      >
        <i aria-hidden="true" className="fa fa-user" /> Manage Teachers
      </a>

      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'delete')}
      >
        <i aria-hidden="true" className="fa fa-trash" /> Delete
      </a>
    </ActionsDropdown>
  );
}

ClassesAdminDropdown.propTypes = {
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
};
