import React, { useState } from 'react';
import GradingSelect from '@/components/shared/Grading/Navigation/GradingSelect';
import styles from './Navigation.module.scss';
import { ToggleMenu } from './ToggleMenu';

interface GradingDropdownGroupsProps {
  activityType: string;
  classroomOptionsData: { label: string; value: number }[];
  currentClassroomID: number;
  displayAutoGradedToggle: boolean;
  exerciseType: string;
  modelType: string;
  questionViewPath?: string;
  studentViewPath?: string;
}

const GradingDropdownGroups = ({
  activityType,
  classroomOptionsData,
  currentClassroomID,
  displayAutoGradedToggle,
  modelType,
  questionViewPath,
  studentViewPath,
  exerciseType,
}: GradingDropdownGroupsProps) => {
  const isStudent = () => modelType === 'student';
  const isQuestion = () => modelType === 'question';

  const [ans, setAns] = useState('all-ans');
  const [grd, setGrd] = useState('all-grd');
  const viewByToggleGroup = () => {
    if (activityType) return null;

    const defaultValue = studentViewPath ? ({
      label: 'Question', value: 'question',
    }) : ({
      label: 'Student', value: 'student',
    });

    return (
      <GradingSelect
        defaultValue={defaultValue}
        id="view-by-toggle"
        label="View by"
        onChange={({ value }) => {
          if (value === 'student') window.location.assign(studentViewPath);
          else window.location.assign(questionViewPath);
        }}
        options={[{ label: 'Student', value: 'student' }, { label: 'Question', value: 'question' }]}
        selectClass="tw-min-w-[108px] tw-ml-1"
      />
    );
  };

  const toggleQuestionCardClasses = (type: 'all-ans' | 'all-grd' | 'answered' | 'unanswered' | 'graded' | 'ungraded') => {
    const allSections = $('.question-card-numberings');
    const allQuestions = $('.new-question-card').not('.sample-question');

    // already that type. no need to mess with the filters.
    if (type === ans || type === grd) return;

    if ((type === 'all-ans' && grd === 'all-grd') || (type === 'all-grd' && ans === 'all-ans')) {
      allQuestions.removeClass('hidden');
      allSections.removeClass('hidden');
    }

    let questionsToHide = $<HTMLElement>();
    if (type === 'answered') questionsToHide = $('.unanswered-question').not('.sample-question');
    if (type === 'unanswered') questionsToHide = $('.answered-question').not('.sample-question');
    if (type === 'graded') questionsToHide = $('.ungraded-question').not('.sample-question');
    if (type === 'ungraded') questionsToHide = $('.graded-question').not('.sample-question');

    // ensures overlap
    let additionalToHide = $<HTMLElement>();
    if ((type === 'graded' || type === 'ungraded') && ans !== 'all-ans') {
      additionalToHide = ans === 'answered' ? $('.unanswered-question').not('.sample-question')
        : $('.answered-question').not('.sample-question');
      questionsToHide.add(additionalToHide);
    } else if ((type === 'answered' || type === 'unanswered') && grd !== 'all-grd') {
      additionalToHide = grd === 'graded' ? $('.ungraded-question').not('.sample-question')
        : $('.graded-question').not('.sample-question');
    }

    allQuestions.removeClass('hidden');
    questionsToHide.addClass('hidden');
    additionalToHide.addClass('hidden');

    allSections.each((_, section) => {
      $(section).removeClass('hidden');
      if ($(section).find(allQuestions).not(questionsToHide).not(additionalToHide).length === 0) {
        $(section).addClass('hidden');
      }
    });
  };
  const toggleQuestionStatusClasses = (type: 'all-grd' | 'graded' | 'ungraded') => {
    const allSections = isStudent() ? (
      $('.section-questions')
    ) : (
      $('.answerable-question-card').not('.sample-question')
    );

    // For notebooks, the grades are placed on exercise_results, so we don't need to look at individual questions
    if (exerciseType === 'Notebook' && !activityType) {
      if (type === 'all-grd') {
        allSections.removeClass('hidden');
      } else {
        allSections.removeClass('hidden');

        if (type === 'ungraded') {
          allSections.each((_, section) => {
            $(section).removeClass('hidden');
            if ($(section).hasClass('graded-section')) {
              $(section).addClass('hidden');
            }
          });
        } else {
          allSections.each((_, section) => {
            $(section).removeClass('hidden');
            if ($(section).hasClass('ungraded-section')) {
              $(section).addClass('hidden');
            }
          });
        }
      }
    } else {
      toggleQuestionCardClasses(type);
    }
  };

  const questionsToggleGroup = () => (
    <GradingSelect
      id={`${isStudent() ? 'questions' : 'student-responses'}-toggle`}
      label={`${isStudent() ? 'Questions' : 'Student Responses'}`}
      onChange={({ value }) => {
        setAns(value);
        toggleQuestionCardClasses(value as 'all-ans' | 'answered' | 'unanswered');
      }}
      options={[
        { label: 'All', value: 'all-ans' },
        { label: 'Answered', value: 'answered' },
        { label: 'Unanswered', value: 'unanswered' },
      ]}
      selectClass="tw-min-w-[130px] tw-ml-1"
      useCookie
    />
  );

  const toggleAnswerKeyClasses = (type: 'show' | 'hide') => {
    const answerKeyIcons = $('.answer-key-toggle i');
    const answerKeyContainers = $('.question-answer-key');

    if (type === 'show') answerKeyContainers.show();
    if (type === 'hide') answerKeyContainers.hide();
    answerKeyIcons.removeClass(type === 'show' ? 'fa-caret-right' : 'fa-caret-down');
    answerKeyIcons.addClass(type === 'show' ? 'fa-caret-down' : 'fa-caret-right');
  };

  const answerKeysToggleGroup = () => (
    <GradingSelect
      id="answer-key-toggle"
      label="Answer Keys"
      aria-label="dropdown-toggle"
      onChange={({ value }) => toggleAnswerKeyClasses(value as 'show' | 'hide')}
      options={[{ label: 'Show', value: 'show' }, { label: 'Hide', value: 'hide' }]}
      selectClass="tw-ml-1"
      useCookie
    />
  );

  const gradeStatusToggleGroup = () => (
    <GradingSelect
      id="grade-status-toggle"
      label="Status"
      aria-label="dropdown-toggle"
      onChange={({ value }) => {
        setGrd(value);
        toggleQuestionStatusClasses(value as 'all-grd' | 'graded' | 'ungraded');
      }}
      options={[
        { label: 'All', value: 'all-grd' },
        { label: 'Graded', value: 'graded' },
        { label: 'Ungraded', value: 'ungraded' },
      ]}
      selectClass="tw-min-w-[100px] tw-ml-1"
      useCookie
    />
  );

  const toggleAutoGradedClasses = (type: 'show' | 'hide') => {
    const autoGradedQuestions = $('.autograded-question');

    if (type === 'show') autoGradedQuestions.show();
    if (type === 'hide') autoGradedQuestions.hide();
  };

  const autoGradedToggleGroup = () => (
    <GradingSelect
      id="auto-graded-toggle"
      label="Auto-graded"
      onChange={({ value }) => toggleAutoGradedClasses(value as 'show' | 'hide')}
      options={[{ label: 'Show', value: 'show' }, { label: 'Hide', value: 'hide' }]}
      selectClass="tw-ml-1"
      useCookie
    />
  );

  const classroomToggle = () => (
    <ToggleMenu
      id="gradebook-classroom-toggle"
      label="Classroom"
      value={currentClassroomID}
      width="200px"
      optionsData={classroomOptionsData}
    />
  );

  return (
    <div className="tw-flex tw-gap-4 tw-flex-col">
      <div className={styles.gradingDropdowns}>
        {classroomToggle()}
        {viewByToggleGroup()}
      </div>
      <div className={styles.gradingDropdowns}>
        {questionsToggleGroup()}
        {answerKeysToggleGroup()}
        {gradeStatusToggleGroup()}
        {isStudent() && displayAutoGradedToggle && autoGradedToggleGroup()}
      </div>
    </div>
  );
};

export default GradingDropdownGroups;
