import React from 'react';
import * as Routes from '@/modules/routes';
import I18n from 'i18n-js';
import ILesson from '@/types/api/Lessons';
import ITag from '@/types/api/Tags';
import clsx from 'clsx';
import EngineeringBanner from './EngineeringBanner';

type LessonCardProps = {
  programId: number,
  lesson: ILesson,
  unitColor: string,
};

const LessonCard = ({ programId, lesson, unitColor }: LessonCardProps) => {
  const lessonPath = Routes.shared_program_lesson_slide_shows_path(programId, lesson.id);
  const lessonNumber = lesson?.title_number ? I18n.t('lesson').concat(' ', lesson.title_number.toString()) : '';

  const performanceExpectations = lesson.performance_expectations?.filter((pe: ITag) => !pe.value_en.includes('ETS'));

  return (
    <div className="tw-w-full tw-h-[20rem] tw-border-solid tw-border-[1px]
        tw-border-med-grey tw-rounded-lg tw-shadow-md tw-relative"
    >
      {lesson.engineering_icon && (
        <EngineeringBanner
          className="tw-absolute tw-right-4 tw--top-0.5 tw-z-[1000]"
          unitColor={unitColor}
        />
      )}
      <a href={lessonPath} className="hover:tw-no-underline">
        <div className="tw-relative tw-h-[180px] tw-p-1 tw-mb-2">
          <div className="tw-absolute tw-top-4 tw-left-4 tw-flex">
            {performanceExpectations?.slice(0, 2)?.map((pe: ITag) => {
              return (
                <div
                  key={pe.id}
                  className="tw-flex tw-justify-center tw-items-center tw-rounded-sm tw-h-6 tw-bg-green tw-text-white
                  tw-z-10 tw-px-4 tw-mr-2 tw-shadow-md tw-relative"
                  style={{ backgroundColor: `#${unitColor}` }}
                >
                  <span className="tw-z-10 tw-font-bold">{pe.value_en}</span>
                  <div className="tw-absolute tw-w-full tw-h-full tw-bg-black tw-opacity-25 tw-rounded-sm" />
                </div>
              );
            })}
          </div>
          <img
            src={lesson.image_url}
            alt={lesson.title}
            className="tw-w-full tw-h-[180px] tw-rounded-lg tw-object-cover tw-bg-center tw-aspect-video"
          />
        </div>

        <div className="tw-flex tw-flex-col tw-px-[1.5rem]">
          <span
            className={clsx('tw-mb-[0.5rem]', {
              'tw-text-[16px] tw-text-dark-grey': lesson?.title_number,
              'tw-h-[16px]': !lesson?.title_number,
            })}
          >
            {lessonNumber}
          </span>
          <p className="tw-max-w-[85%] tw-text-[1.25rem] tw-font-bold tw-text-tci-purple tw-mr-auto tw-h-full
          hover:tw-underline hover:tw-decoration-tci-purple"
          >
            {lesson.title}
          </p>
        </div>
        <span
          className="btn tw-absolute tw-bottom-4 tw-right-4 tw-h-[2.5rem] tw-bg-tci-purple tw-rounded-full tw-flex tw-items-center tw-justify-center hover:tw-bg-[#311C57]"
          role="button"
          tabIndex={0}
          aria-label={I18n.t('go_to', { name: `${lessonNumber} - ${lesson.title}` })}
        >
          <i className="tw-text-white fa fa-arrow-right fa-lg" />
        </span>
      </a>
    </div>
  );
};

export default LessonCard;
