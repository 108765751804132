/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const LifeSciencesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3258 4.95529C11.0741 5.45433 9.39713 5.62729 9.8469 7.81881C9.8469 7.81881 10.9786 7.66297 11.6127 7.07353C11.6317 7.05577 11.6608 7.05481 11.6811 7.07081C11.7013 7.08697 11.7069 7.11545 11.6939 7.13801C10.8899 8.54009 8.56786 9.28057 7.96993 9.84905C7.96993 9.84905 8.14354 8.92841 7.85793 7.89433C7.85793 7.89433 9.00289 6.54265 8.63825 5.11097C8.2829 3.71657 7.15154 2.98057 5.34546 2.63977C3.91362 2.36969 2.8061 1.38377 2.50898 0.722168C2.50898 0.722168 1.53634 3.20745 1.92818 5.13897C2.32002 7.07049 3.3133 8.76345 6.54146 8.41209C6.54146 8.41209 6.4885 6.85561 5.86594 5.59209C5.85714 5.57433 5.86354 5.55305 5.88034 5.54281C5.89714 5.53273 5.91922 5.53705 5.93058 5.55305C6.39377 6.19337 7.18273 7.36665 7.27138 8.04457C7.65601 10.9844 6.06225 11.4105 6.00738 13.43C6.00738 13.43 3.5629 13.7343 1.86898 14.7666C1.80626 14.8047 1.77682 14.8798 1.79666 14.9503C1.8165 15.0207 1.88082 15.0695 1.9541 15.0695H12.0258C12.1058 15.0695 12.1741 15.0116 12.1871 14.9327C12.2002 14.8538 12.1541 14.7769 12.0783 14.7513C10.6096 14.255 8.00321 13.4974 7.57362 13.4986C7.57362 13.4986 7.22337 12.7777 7.44513 12.0198C7.98753 10.1658 9.84818 9.45609 10.2032 9.16553C10.2032 9.16553 11.7927 9.52473 12.8999 8.63897C14.1007 7.67865 14.5626 5.87129 13.6418 3.80377C13.6418 3.80377 13.3287 4.55545 12.3258 4.95529Z"
      fill="currentColor"
    />
  </svg>
);

export default LifeSciencesIcon;
