import React, { useMemo, useState } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import Select from '@/components/common/ReactSelect5';
import usePrograms from '../usePrograms';

interface AddProgramModalProps {
  modalManager: ModalManager
}

type INewProgramType = 'create' | 'reuse';

type ISelectOption = {
  value: number;
  label: string;
};

type IProgram = {
  id: number;
  code: string;
  full_title_with_edition: string;
};

const formatOptions = (programs: IProgram[]) => programs.map(program => ({
  value: program.id,
  label: `[${program.code}] ${program.full_title_with_edition}`,
}));

const AddProgramModal = ({ modalManager }: AddProgramModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newProgramType, setNewProgramType] = useState<INewProgramType>('reuse');
  const [selected, setSelected] = useState<ISelectOption | null>(null);
  const { isOpen, close } = modalManager;
  const createProgramMode = newProgramType === 'create';
  const reuseProgramMode = newProgramType === 'reuse';

  const { programs, isLoading: isLoadingPrograms } = usePrograms({ skip: createProgramMode });
  const options = useMemo(() => formatOptions(programs), [programs]);

  const isValid = () => {
    if (createProgramMode) return true;

    return !!selected;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    Axios.post(
      Routes.plato_api_programs_path({ base_program_id: selected?.value }),
    ).then((res) => {
      window.location.replace(Routes.edit_admin_program_path(res.data.data.id));
    }).catch((err) => {
      console.error('error', err);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <SubmitModal
      bodyClassName="tw-min-h-[500px]"
      close={close}
      isOpen={isOpen}
      headerText="Add Program"
      className="tw-text-base"
      formClassName="tw-min-h-[500px]"
      onSubmit={handleSubmit}
      submitText="Add Program"
      isLoading={isLoading}
    >
      <p>
        Create a program from scratch or reuse an existing one.
      </p>
      <div className="tw-text-sm tw-ml-2 tw-mb-2">
        <label htmlFor="create-new-program">
          <input
            className="tw-mb-[5px]"
            type="radio"
            name="program"
            id="create-new-program"
            onChange={() => {
              setNewProgramType('create');
            }}
            checked={createProgramMode}
          />
          Create new program
        </label>

        <br />

        <label htmlFor="reuse-program">
          <input
            className="tw-mb-[5px]"
            type="radio"
            name="program"
            id="reuse-program"
            onChange={() => {
              setNewProgramType('reuse');
            }}
            checked={reuseProgramMode}
          />
          Reuse program
        </label>
      </div>

      {reuseProgramMode && (
        <label
          className="tw-ml-8"
          htmlFor="base_program_id"
        >
          <span className="tw-text-sm tw-font-bold">Base Program</span>
          <Select
            name="base_program_id"
            options={options}
            onChange={setSelected}
            inputId="base_program_id"
            isClearable
            isLoading={isLoadingPrograms}
            showCaret
            value={selected}
          />
        </label>
      )}

      {reuseProgramMode && (
        <SubmitModal.SubmitButton
          submitting={isLoading}
          submittingText="Adding Program…"
          disabled={!isValid()}
        >
          Add Program
        </SubmitModal.SubmitButton>
      )}

      {createProgramMode && (
        <SubmitModal.SubmitButton asChild>
          <a href={Routes.new_admin_program_path()} className="btn btn--purple">
            Add Program
          </a>
        </SubmitModal.SubmitButton>
      )}
    </SubmitModal>
  );
};

export default AddProgramModal;
