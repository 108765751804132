import React, { useEffect, useState } from 'react';
import { ImpersonateStudent } from '@/components/staffer/ImpersonateStudent';
import useGradesTableStore from '@/components/staffer/Scores/GradesTable/store/useGradesTableStore';
import clsx from 'clsx';
import useFlags from '@/hooks/useFlags';
import { IStudent } from '../../types';

export const StudentCell = ({ student, hoveredRow, rowId }: { student: IStudent, hoveredRow: number, rowId: number }) => {
  const programId = useGradesTableStore(state => state.programId);
  const stafferId = useGradesTableStore(state => state.stafferId);
  const isImpersonating = useGradesTableStore(state => state.isImpersonating);

  const { updatedGradesTable } = useFlags();

  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!updatedGradesTable) return;
    setHover(hoveredRow === rowId);
  }, [hoveredRow]);

  return (
    <div
      className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-between"
    >
      {student.first_name} {student.last_name}

      <span className={clsx('tw-float-right tw-pr-1 -tw-mt-1', { 'tw-hidden': !hover && updatedGradesTable })}>
        <ImpersonateStudent
          student={{ ...student, name: `${student.first_name} ${student.last_name}` }}
          programId={programId}
          enabled={!isImpersonating}
          stafferId={stafferId}
        />
      </span>
    </div>
  );
};

export default StudentCell;
