import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import Modal, { Footer } from '../../common/Modal';

import { renderErrors, SubmitError } from '../../common/Forms/Utils';
import { truncate } from '../../../modules/TCIUtils';
import styles from './Form.module.scss';

export default class ProgramLessonPdfModal extends Component {
  static propTypes = {
    initialFileUrl: PropTypes.string,
    initialFileName: PropTypes.string,
    updatePath: PropTypes.string.isRequired
  };

  static defaultProps = {
    initialFileUrl: null,
    initialFileName: null
  };

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileUrl: this.props.initialFileUrl,
      fileName: this.props.initialFileName,
      submitting: false,
      removeUploadedFile: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addFile = this.addFile.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  clearFile(e) {
    e.target.previousSibling.value = null;
    this.setState({
      file: null,
      fileUrl: null,
      fileName: null,
      removeUploadedFile: this.state.removeUploadedFile || this.state.fileName
    });
  }

  addFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleSubmit = async () => {
    this.setState({ submitting: true });

    const formData = new FormData();
    if (this.state.file) {
      formData.append('program_lesson[pdf]', this.state.file);
    }
    else if (this.state.removeUploadedFile) {
      formData.append('program_lesson[remove_file]', '1');
    }

    return Axios
      .patch(this.props.updatePath, formData)
      .then((response) => {
        this.setState({
          fileUrl: response.data.data.pdf_url,
          fileName: response.data.data.pdf_file_name,
          submitting: false,
          removeUploadedFile: false
        });
        this.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  _renderFileUpload() {
    return (
      <div className={styles.formRow}>
        <label htmlFor="pdf-upload" className={styles.label}>
          PDF
        </label>
        {this.state.fileName ?
          <a className="ml15" href={this.state.fileUrl} target="_blank">
            {truncate(this.state.fileName, 50)} <i aria-hidden="true" className="fa fa-external-link" />
          </a> :
          <input
            type="file"
            id="pdf-upload"
            name="pdf-upload"
            accept=".pdf"
            onChange={this.addFile}
          />
        }
        <button
          className={`btn btn--purple ${styles.clearButton}`}
          onClick={this.clearFile}
          type="button"
        >
          Clear File
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <a href="#" role="button" onClick={this.openModal}>
          {this.state.fileName ? 'Edit PDF' : 'Add PDF'}
        </a>

        <Modal
          className="w850"
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          headerText={this.props.initialFileName ? 'Edit PDF' : 'Add PDF'}
        >
          <Form
            onSubmit={this.handleSubmit}
            render={({
              form, handleSubmit, submitError
            }) => (
              <form className={styles.form} onSubmit={handleSubmit}>

                {this._renderFileUpload()}

                <SubmitError error={submitError} />

                <Footer
                  secondaryButtonCallback={this.state.closeModal}
                  primaryButtonDisabled={!this.state.file && !this.state.removeUploadedFile}
                  submitting={this.state.submitting}
                />
              </form>
            )}
          />
        </Modal>
      </div>
    );
  }
}
