import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { IClassroom, IExerciseResult, ILesson } from '../../../types';

const useVideoActivityExerciseResult = (classroom: IClassroom, lesson: ILesson) => {
  const isEnabled = !!(classroom?.id && lesson?.id);

  const query = useQuery([QueryKeys.VideoActivityExerciseResults, classroom.id, lesson?.id], () => {
    const path = Routes.plato_api_video_activity_result_exercise_results_path({
      only: [
        'id', 'student_id', 'exercise_type', 'exercise_id', 'score', 'completed_at',
        'requires_clever_passback', 'lms_settings', 'updated_since_graded',
      ],
      classroom_id: classroom.id,
      lesson_id: lesson.id,
    });

    return axios
      .get<{ data: IExerciseResult[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err));
  }, { staleTime: Infinity, enabled: isEnabled });

  return { ...query, isEnabled, data: query.data || [] };
};

export default useVideoActivityExerciseResult;
