import React from 'react';
import ActivityCard from '@/components/shared/LessonOverview/Cards/ActivityCard';
import TeacherCard from '@/components/shared/LessonOverview/Student/TeacherCard';
import { Lesson, Program, StudentSlideShows, StudentSlideShowAssignments } from '../types';
import { tciCreatedSlideShows } from './TCICreatedSlideShowCards';

interface AssignedSlideShowCardsProps {
  lesson: Lesson;
  program: Program;
  slideShows: StudentSlideShows;
  tci?: boolean;
}

const getSlideShow = (data: StudentSlideShowAssignments) => (
  data.assignment.assignment_models_hash.SlideShow?.[0]
  ?? data.assignment.assignment_models_hash.SlideGroup?.[0]
);

const assignmentNotSlideShow = (data: StudentSlideShowAssignments) => (
  data.assignment.assignment_models_hash.SlideShow === null
  || data.assignment.assignment_models_hash.SlideGroup === null
);

const AssignedSlideShowCards = ({ lesson, program, slideShows, tci = false }: AssignedSlideShowCardsProps) => {
  const slideShowIds = tciCreatedSlideShows(slideShows).map(slideShow => slideShow.id);

  const filteredAssignments = slideShows.assignments.filter((data) => {
    if (data.assignment.within_assignment_date_availability === false) return false;
    if (assignmentNotSlideShow(data)) return false;

    const slideShow = getSlideShow(data);

    // If a slideshow Id has been seen, skip it
    // TODO: prob a better way to do this but idk
    if (slideShowIds.includes(slideShow.slide_show_id)) return false;
    slideShowIds.push(slideShow.slide_show_id);

    // If tci is true, will only return TCI created slideshows
    // If tci is false, will only return custom slideshows. AKA teacher created.
    return slideShow.custom_slide_show !== tci;
  });

  return (
    <>
      {filteredAssignments.map((assignment) => {
        const slideShow = getSlideShow(assignment);

        const CardComponent = slideShow.custom_slide_show ? TeacherCard : ActivityCard;

        const slideShowDetails = {
          activityType: slideShow.activity_type,
          description: slideShow.description,
          links: {
            unfilteredPresentPath: assignment.assignment.direct_link,
            presentPath: assignment.assignment.direct_link,
          },
          staffer_initials: assignment.assignment.teacher_initials,
          truncatedDescription: slideShow.description,
          truncatedTitle: slideShow.label,
        };

        return (
          <CardComponent
            key={assignment.assignment.id}
            slideShow={slideShowDetails}
            programId={program.id}
            lessonId={lesson.id}
          />
        );
      })}
    </>
  );
};

export default AssignedSlideShowCards;
