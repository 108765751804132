import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextGroup from './TextGroup';
import Modal from '../../common/Modal';
import styles from './LessonTextButton.module.scss';

export default class LessonTextButton extends Component {
  static propTypes = {
    textGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    updatePdfPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.state = {
      isModalOpen: false
    };
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  renderModalContent() {
    return this.props.textGroupNames.map((name, index) => (
      <div key={name} >
        <TextGroup
          name={name}
          updatePdfPath={this.props.updatePdfPaths[index]}
        />
        {index < this.props.textGroupNames.length - 1 && <hr />}
      </div>
    ));
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="action"
          onClick={this.openModal}
        >
          Update Lesson Text PDF
        </button>
        <Modal
          headerText="Lesson Text PDF"
          className={styles.modal}
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
        >
          {this.renderModalContent()}
        </Modal>
      </div>
    );
  }
}
