import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';

import Modal, { useModalManager } from 'common/Modal';

const ResetConfirmationModal = ({ confirmationLink, confirmationMessage, headerText }) => {
  const modalManager = useModalManager();

  return (
    <React.Fragment>
      <button className="btn btn--md btn--outline-purple ml5" type="button" onClick={modalManager.open}>
        {I18n.t('restart')}
      </button>
      <Modal headerText={headerText} isOpen={modalManager.isOpen} closeModal={modalManager.close}>
        <p className="mb10">
          {confirmationMessage}
        </p>

        <hr />

        <footer>
          <button
            className="btn btn--link-purple"
            onClick={modalManager.close}
            type="button"
          >
            {I18n.t('cancel')}
          </button>
          <a
            href={confirmationLink}
            className="btn btn--purple"
            data-method="delete"
          >
            {I18n.t('restart')}
          </a>
        </footer>
      </Modal>
    </React.Fragment>
  );
};

ResetConfirmationModal.propTypes = {
  confirmationLink: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default ResetConfirmationModal;
