/* eslint-disable @typescript-eslint/naming-convention */
import { SearchFilterType } from '@/components/shared/Search/types';

const reference_material = [
  { value: 'biography', label: 'Biographies', checked: false },
  { value: 'primary_source', label: 'Primary Source', checked: false },
  { value: 'civics_library', label: 'Civics Library', checked: false },
];

const reading = [
  { value: 'text', label: 'Text', checked: false },
];

const image = [
  { value: 'text', label: 'Text', checked: false },
];

const language = [
  { value: 'en', label: 'English', checked: false },
  { value: 'es', label: 'Spanish', checked: false },
];

const slide_show = [
  { value: 'classroom_activity', label: 'Classroom Activity', checked: false },
  { value: 'video_activity', label: 'Video Activity', checked: false },
  { value: 'lesson_outline', label: 'Lesson Outline', checked: false },
  { value: 'investigation_activity', label: 'Investigation Activity', checked: false },
  { value: 'slide_show', label: 'Uncategorized', checked: false },
];

export const DEFAULT_FILTERS = {
  image,
  language,
  reading,
  reference_material,
  slide_show,
};

export const FILTER_CATEGORIES = [
  ...reading,
  ...reference_material,
  ...image,
  ...language,
  ...slide_show,
];

export const filters: SearchFilterType[] = [
  'reading', 'reference_material', 'image', 'slide_show', 'language',
];

export const allFilters = (userType: string): SearchFilterType[] => {
  if (userType === 'admin') return filters;

  return filters.filter(f => f !== 'slide_show');
};
