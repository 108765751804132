import colorIcon from './color.svg';
import eraserIcon from './eraser.svg';
import penIcon from './pen.svg';
import popupIcon from './popup.svg';
import redoIcon from './redo.svg';
import selectIcon from './select.svg';
import weightIcon from './weight.svg';
import shapesIcon from './shapes.svg';
import textIcon from './text.svg';
import undoIcon from './undo.svg';
import opacityIcon from './opacity.svg';
import I18n from '@/modules/i18n';

const icons = {
  color: {
    alt: I18n.t('color'),
    icon: colorIcon,
  },
  eraser: {
    alt: 'Eraser',
    icon: eraserIcon,
  },
  opacity: {
    alt: I18n.t('opacity'),
    icon: opacityIcon,
  },
  pen: {
    alt: I18n.t('pen'),
    icon: penIcon,
  },
  popup: {
    alt: 'Popup',
    icon: popupIcon,
  },
  redo: {
    alt: 'Redo',
    icon: redoIcon,
  },
  select: {
    alt: I18n.t('select'),
    icon: selectIcon,
  },
  shapes: {
    alt: I18n.t('shapes'),
    icon: shapesIcon,
  },
  text: {
    alt: I18n.t('text'),
    icon: textIcon,
  },
  undo: {
    alt: 'Undo',
    icon: undoIcon,
  },
  weight: {
    alt: I18n.t('pen_weight'),
    icon: weightIcon,
  },
};

export default icons;
