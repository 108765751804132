/* eslint-disable react/button-has-type */
import React from 'react';
import { twMerge } from '@/utils';

interface IStandardButtonProps {
  /** Ref for the button */
  buttonRef?: React.RefObject<HTMLButtonElement>;
  /** Button contents */
  children?: ReactChildren;
  /** Additional classes to apply to the button */
  className?: string;
  /** Button color */
  color?: ButtonColors;
  /** Is the button disabled? */
  disabled?: boolean;
  /** Aria label for the button */
  label?: string;
  /** Optional click handler */
  onClick?: (formData) => void;
  /** Should the button have an outline style? */
  outlineStyle?: boolean;
  /** Should the button be small? */
  small?: boolean;
  /** Button type */
  type?: 'button' | 'submit';
  /** Button variant */
  variant?: 'rounded-right';
}

/** Standard button component */
const StandardButton = ({
  buttonRef = null,
  children,
  className = '',
  color = 'purple',
  disabled = false,
  label = '',
  onClick = () => {},
  outlineStyle = false,
  small = false,
  type = 'button',
  variant,
}: IStandardButtonProps) => {
  const buttonClass = () => {
    const roundedRight = variant === 'rounded-right' ? 'rounded-right' : '';

    if (outlineStyle) return `btn--outline-${color} ${roundedRight}`;

    return `btn--${color} ${roundedRight}`;
  };

  return (
    <button
      aria-label={label}
      className={
        twMerge('btn', buttonClass(), className, small ? 'btn--sm' : '')
      }
      disabled={disabled}
      onClick={onClick}
      ref={buttonRef}
      type={type}
    >
      {children}
    </button>
  );
};

export default StandardButton;
