import { createColumnHelper } from '@tanstack/react-table';
import { IStudentRow } from './types';

export const columnHelper = createColumnHelper<IStudentRow>();

export const SECTION_COLLAPSE_ID = 'section-collapse-id';

export const COLOR_LEGEND = {
  in_progress: 'tw-bg-[#c3dee1]',
  completed: 'tw-bg-[#cbd595]',
  low_score: 'tw-bg-[#e06051] tw-text-white hover:tw-text-white',
};

export const SCORE_STYLES = 'tw-leading-7 tw-font-normal tw-mt-6';

export const TRUNCATION_LENGTH = 20;
