import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import DeleteButton from 'common/Forms/Questions/DragAndDrop/Builder/DeleteButton';
import ReactDOMServer from 'react-dom/server';
import BuilderContext from 'common/Context';
import CorrectAnswers from './CorrectAnswers';
import styles from './DraggableChoiceEditor.module.scss';
import TextEditor from './FroalaComponents/TextEditor';
import LinkVocabularyWrapper from '../LinkVocabularyButton';
import useVocabularyStore from '../../useVocabularyStore';

const DraggableChoice = ({
  id, left, title, top, position, hideDelete, vocabId, draggableChoices
}) => {
  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const [vocabTerm, setVocabTerm] = useState('');
  const { loading } = useContext(BuilderContext);

  const deleteLabel = `Delete draggable choice: ${title}`;

  const renderDeleteButton = () => {
    if (hideDelete) return undefined;

    return (
      <DeleteButton id={id} type="draggableChoice" label={deleteLabel} />
    );
  };

  useEffect(() => {
    if (vocabId && !loading) {
      setVocabTerm(vocabularyTerms.find(vocab => vocab.value === vocabId));
    }
  }, [vocabId, vocabularyTerms, loading]);

  return (
    <div className={styles.draggableChoiceCard}>
      {vocabId && !loading && (
        <div className={styles.vocabOverlay}>
          <div className={styles.vocabMessageContainer}>
            <div className={styles.vocabMessage}>
              This answer choice is linked to vocabulary. A corresponding dropzone and a
              text box have been added to the canvas.
            </div>
            <div
              className={styles.vocabDefinition}
              dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToStaticMarkup(vocabTerm.label) }}
            />
            <div className="tw-w-3/12">
              <LinkVocabularyWrapper
                text="Edit"
                icon="fa-edit"
              />
            </div>
          </div>
        </div>
      )}
      {!vocabId && <TextEditor id={id} left={left} title={title} top={top} position={position} />}
      <CorrectAnswers />
      {renderDeleteButton()}
    </div>
  );
};

DraggableChoice.propTypes = {
  draggableChoices: PropTypes.objectOf(PropTypes.object).isRequired,
  hideDelete: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  vocabId: PropTypes.number,
};

DraggableChoice.defaultProps = {
  vocabId: null,
};

export default DraggableChoice;
