import React, { Component } from 'react';
import Axios from 'axios';
import Form from './Form';
import { ModalButton, DeleteButton } from '../Utils';

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const { image, name, pkgSize,
            kitUnit, pkgQty, boxNum,
            locDetail, venDesc,
            venPartNum, unitPrice, inRefillKit } = this.state;
    return (
      <tr>
        <td className="image"><img src={image} /></td>
        <td>{name}</td>
        <td className="pkg-size">{parseFloat(pkgSize)}</td>
        <td className="unit">{kitUnit}</td>
        <td className="pkg-qty">{parseFloat(pkgQty)}</td>
        <td className="box-num">{boxNum}</td>
        <td>{locDetail}</td>
        <td>{venDesc}</td>
        <td>{venPartNum}</td>
        <td>{unitPrice ? parseFloat(unitPrice).toFixed(2) : ''}</td>
        <td>{inRefillKit ? 'Yes' : 'No'}</td>
        <td className="actions">
          <ModalButton buttonText="Edit"
                       modalHeader="Edit Kit Material"
                       buttonClass="btn btn--outline-purple btn--sm"
                       modalClass="edit-kit-materials"
                       openModal={this.openModal.bind(this)}
                       closeModal={this.closeModal.bind(this)}
                       onUpdate={this.updateMaterial.bind(this)}
                       FormComponent={Form}
                       unitPrice={parseFloat(unitPrice).toFixed(2)}
                       {...this.state} />
          <DeleteButton confirmationModalIsOpen={this.state.confirmationModalIsOpen}
                        openConfirmationModal={this.openConfirmationModal.bind(this)}
                        closeConfirmationModal={this.closeConfirmationModal.bind(this)}
                        handleDelete={this.handleDelete.bind(this)}
                        isDeleting={this.state.isDeleting} />
        </td>
      </tr>
    )
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  openConfirmationModal() {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/program_kit_materials/${this.props.id}`).then(response => {
      response.data ? this.handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    });
  }

  updateMaterial(material) {
    this.setState(material);
    this.props.updateRow(material);
  }
}

Row.defaultProps = {
  modalIsOpen: false,
  confirmationModalIsOpen: false,
  isDeleting: false,
  boxNum: '',
  forSelect: [],
  id: null,
  locDetail: '',
  materialId: null,
  name: '',
  pkgQty: '',
  pkgSize: '',
  inRefillKit: false,
  programId: null,
  removeRow: null,
  unit: '',
  unitPrice: '',
  venDesc: '',
  venPartNum: ''
};
