import React, { useState } from 'react';
import Tooltip from '@/components/ui/Tooltip';

const ProgramCode = ({ rowData }) => {
  const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');

  const handleClick = () => {
    navigator.clipboard.writeText(`${rowData.license?.code}:${rowData.program?.code}`);
    setTooltipContent('Copied!');
    setTimeout(() => {
      setTooltipContent('Copy to clipboard');
    }, 2000);
  };

  return (
    <div className="tw-flex tw-items-center">
      {rowData.license?.code}:{rowData.program?.code}
      <Tooltip.Provider>
        <Tooltip delayDuration={0}>
          <Tooltip.Trigger
            asChild
            onClick={(event) => {
              // Prevent tooltip from closing on click.
              event.preventDefault();
            }}
          >
            <button
              type="button"
              className="tw-ml-2 tw-text-[#522e91] tw-border-none tw-bg-transparent tw-cursor-pointer"
              aria-label="Copy code"
              onClick={(event) => {
                // Prevent tooltip from closing on click.
                const target = event.currentTarget;
                target.blur();
                target.focus();
                handleClick();
              }}
            >
              <i className="fa fa-clone" />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Content className="tw-text-sm tw-shadow tw-p-2 tw-bg-white tw-rounded tw-text-dark-grey">
            {tooltipContent}
            <Tooltip.Arrow className="tw-fill-white" />
          </Tooltip.Content>
        </Tooltip>
      </Tooltip.Provider>
    </div>
  );
};

export default ProgramCode;
