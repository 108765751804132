import React from 'react';
import I18n from 'i18n-js';
import Popover from '@/components/ui/Popover';
import SlideTagGroup from './SlideTagGroup';
import useSlideTags from './useSlideTags';

const SlideStandards = () => {
  const { tagGroups } = useSlideTags();

  if (!Object.keys(tagGroups).length) return null;

  return (
    <Popover>
      <Popover.Trigger
        className="tw-bg-transparent tw-border-none tw-self-center tw-mr-8 tw-text-white tw-text-base hover:tw-underline"
      >
        {I18n.t('slide_standards')}
      </Popover.Trigger>
      <Popover.Content
        className="tw-relative tw-mr-4 tw-p-2 tw-border-solid tw-border-orange tw-w-[unset] tw-min-w-72 tw-max-w-[33vw]
        tw-border-4 tw-rounded-none tw-overflow-visible tw-max-h-[50vh] tw-outline-transparent"
      >
        <Popover.Close
          asChild
          className="tw-flex tw-items-center tw-justify-center tw-w-7 tw-h-7
          tw-rounded-full tw-bg-white tw-absolute tw--right-4 tw--top-4"
        >
          <div>
            <i
              className="fa fa-times-circle fa-2x"
              role="button"
              tabIndex={0}
            />
          </div>
        </Popover.Close>
        <div className="tw-max-h-[inherit] tw-overflow-y-auto tw-overflow-x-hidden">
          <p className="tw-text-lg">
            {I18n.t('slide_standards')}
          </p>

          {Object.keys(tagGroups).map(category => (
            <SlideTagGroup
              category={category}
              tags={tagGroups[category]}
            />
          ))}
        </div>
      </Popover.Content>
    </Popover>
  );
};

export default SlideStandards;
