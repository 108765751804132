import React from 'react';
import I18n from '@/modules/i18n';
import { GradingBy } from './types';

const SwitchGradeModeConfirmationText = (
  { gradingBy, lessonTitle }: { gradingBy: GradingBy; lessonTitle: string; }) => (
  <>
    <span dangerouslySetInnerHTML={{ __html: I18n.t(`grade_by_confirmation_${gradingBy}`)}} />
    <div
      className="tw-pt-5"
      dangerouslySetInnerHTML={{
        __html: I18n.t(
          'grade_by_confirmation',
          { type: I18n.t(gradingBy).toLowerCase(), title: lessonTitle }
        )
      }}
    />
  </>
);

export default SwitchGradeModeConfirmationText;
