import React from 'react';
import { WrapperFunction } from '../SectionEditorUtils';
import DropdownMenu from './DropdownMenu';
import DropdownOption from './DropdownOption';
import AlignLeft from './icons/AlignLeft';
import AlignMiddle from './icons/AlignMiddle';
import AlignRight from './icons/AlignRight';

interface AlignmentDropdownProps {
  element: HTMLElement;
  wrapper: WrapperFunction;
}

const AlignmentDropdown = ({ element, wrapper }: AlignmentDropdownProps) => {
  const setAlignment = wrapper((nextAlignment: 'left' | 'none' | 'right') => {
    element.classList.remove('align-left');
    element.classList.remove('align-right');

    if (nextAlignment === 'none') return;

    element.classList.add(`align-${nextAlignment}`);
  });

  return (
    <DropdownMenu
      content={<AlignMiddle />}
      height={106}
      id="align"
      title="Align"
    >
      <DropdownOption onClick={() => setAlignment('left')} title="Left">
        <AlignLeft />
      </DropdownOption>
      <DropdownOption onClick={() => setAlignment('none')} title="None">
        <AlignMiddle />
      </DropdownOption>
      <DropdownOption onClick={() => setAlignment('right')} title="Right">
        <AlignRight />
      </DropdownOption>
    </DropdownMenu>
  );
};

export default AlignmentDropdown;
