import React from 'react';
import { useModalManager } from 'components/common/Modal';
import EditImageModel from '@/components/admin/ImageManager/Edit';
import { ImageModelResponseType, useExcludedFields } from '@/components/admin/QuestionImage/utils';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import { toCamelCase } from '@/modules/TCIUtils';

interface EditProps {
  afterSubmit: (imageModel: object) => any;
  imageModel: ImageModelResponseType;
  mediaType: string;
  modelId: number;
  modelType: 'Question' | 'QuestionGroup';
  userType: UserType;
}

const graphQlValidModel = (imageModel) => {
  const { image } = imageModel;

  const imagePrefixedObject = Object.keys(image).reduce((acc, cur) => {
    const camelizedKey = Object.keys(toCamelCase({ [cur]: null }))[0];

    return { ...acc, [`image_${camelizedKey}`]: image[cur] };
  }, {});

  let newItem = {
    ...imageModel,
    image: {
      ...image,
      image_url: image.url,
      image_es_url: image.es_url,
      file_name: image.image_en_file_name,
    },
  };

  newItem = toCamelCase(newItem);


  // @ts-ignore
  return ({
    ...newItem,
    ...imagePrefixedObject,
    image_fileNameEs: image.image_es_file_name,
    image_fileName: image.image_en_file_name,
    image_descriptionConfidence: image.description_confidence ? parseFloat(image.description_confidence) : undefined,
  });
};

const Edit = ({ afterSubmit, imageModel, mediaType, modelId, modelType, userType }: EditProps) => {
  const modalManager = useModalManager();
  const excludeFields = useExcludedFields(userType);

  return (
    <EditImageModel
      afterMergeDuplicates={afterSubmit}
      afterSubmit={afterSubmit}
      buttonClass="!tw-mb-0"
      dontMergeCallback={afterSubmit}
      excludeFields={excludeFields}
      fromSlideShow
      imageModel={graphQlValidModel(imageModel)}
      isAdmin={userType === 'Admin' || userType === 'Sysadmin' || userType === 'ContentManager'}
      modelType={modelType}
      modelId={modelId}
      modalManager={modalManager}
      mediaType={mediaType}
    />
  );
};

const QuestionImageEditWrapper = (props: EditProps) => (
  <ApolloProvider client={apolloClient}>
    <Edit {...props} />
  </ApolloProvider>
);

export default QuestionImageEditWrapper;
