import { plato_api_slide_show_questions_slide_shows_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface UseSlideShowQuestionGroupsArgs {
  params?: object;
  onSuccess?: (data: any[]) => any;
}

interface IQuestion {
  question_body: string;
  question_display_position: string;
  question_position: number;
  question_type: string;
}

interface IQuestionGroup {
  is_selected: boolean;
  position: number;
  questions: IQuestion[];
  question_group_body?: string;
  question_group_id: number;
}

interface ApiResponse {
  question_groups: IQuestionGroup[];
}

export default function useSlideShowQuestionGroups({ params = {}, onSuccess }: UseSlideShowQuestionGroupsArgs) {
  return useQuery({
    onSuccess,
    queryKey: [QueryKeys.SlideShowQuestionGroups, params],
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_slide_show_questions_slide_shows_path(), { params })
        .then(res => res.data.question_groups)
        .catch(() => ([]))
    ),
    placeholderData: [],
  });
}
