import React, { useState } from 'react';
import Popover from '@/components/ui/Popover';
import Tooltip from 'components/common/Tooltip';
import I18n from '@/modules/i18n';
import headerIcons from '../../Utils/icons';

const IconSelect = ({ currentIcon, setIcon }) => {
  const [selectedIcon, setSelectedIcon] = useState(currentIcon);

  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    setIcon('icon', icon);
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-3 tw-ml-4">
      <div className="tw-border-[1px] tw-border-solid tw-rounded tw-border-[#ccc] tw-shadow-sm tw-flex tw-items-center
      tw-justify-center"
      >
        <Popover>
          <Popover.Trigger
            className="tw-cursor-pointer tw-bg-none tw-border-none tw-bg-inherit tw-text-lg tw-flex tw-items-center"
          >
            {selectedIcon ? <img src={headerIcons[selectedIcon]} alt={selectedIcon} />
              : (
                <div className="tw-px-2 tw-w-[135px]">
                  <span className="tw-text-[#757575] tw-text-[14px]">Select an icon</span>
                  <i className="fa fa-caret-down tw-ml-2" />
                </div>
              )
            }
          </Popover.Trigger>
          <Popover.Content side="bottom" className="tw-w-96 tw-flex" align="start">
            <div className="tw-px-2">
              <button
                type="button"
                className="tw-text-3xl tw-border-none tw-bg-inherit tw-cursor-pointer"
                onClick={() => handleIconSelect(null)}
              >
                <i className="fa fa-times-circle-o tw-text-[#ccc]" />
              </button>
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-1">
              {Object.entries(headerIcons).map(([key, icon]) => (
                <button
                  onClick={() => {
                    handleIconSelect(key);
                  }}
                  key={key}
                  className="tw-border-none tw-bg-inherit tw-cursor-pointer tw-text-lg"
                  type="button"
                >
                  <img src={icon} alt={key} />
                </button>
              ))}
            </div>
          </Popover.Content>
        </Popover>
      </div>
      <Tooltip content={I18n.t('icon_select_tooltip')}>
        <i className="fa fa-question-circle" />
      </Tooltip>
    </div>
  );
};

export default IconSelect;
