import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from '@/components/staffer/AssignmentModal/WhatSection/WhatSection.module.scss';
import sectionStyles from '@/components/staffer/AssignmentModal/Section.module.scss';
import { SelectField } from '@/components/common/Forms';
import useAssignablesStore from '../store/useAssignablesStore';

const AssessmentSelector = () => {
  const [assessments, setAssessments] = useState([]);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const programId = useAssignablesStore(state => state.programId);
  const openAssessmentInNewTab = useAssignablesStore(state => state.openAssessmentInNewTab);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const initialModels = useAssignablesStore(state => state.initialValues.modelsToAssign);
  const initialValues = useAssignablesStore(state => state.initialValues);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);

  const getAssessments = () => {
    const params : { lesson_id: string | number, extra_assessment_id?: string } = { lesson_id: selectedUnitLesson.value };

    // id of any assessment passed in as an initialModel, is used to allow assigning of assessments a staffer does not own
    if (initialModels.length && !!initialModels[0].value) params.extra_assessment_id = initialModels[0].value.split('-')[1];
    const assessmentPath = Routes.plato_api_get_assessment_assignables_assignables_path({ params });

    Axios
      .get(assessmentPath)
      .then((res) => {
        setAssessments(res.data.assignables);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getAssessments();
  }, []);

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!initialModels.length || !initialModels[0].value) return;

    formChangeValue(initialModels[0].value, 'modelsToAssign');
    formChangeValue(initialValues.randomizeAnswers, 'randomize_answers');

    const score = initialModels[0]['score'];
    setStore({ score });
  }, [initialModels, loadingLessons, loadingTypes, initialValues]);

  const handleAssessmentChange = (assessmentId: string) => {
    const score = assessments.find(assessment => assessmentId === assessment.value)?.score;
    setStore({ score });
    formChangeValue(assessmentId, 'modelsToAssign');
  };

  const renderNoAssessmentsMessage = () => (
    <div className={styles.errorMessage}>
      You have not created an assessment for this lesson. Go to {' '}
      <a
        href={Routes.staffer_lesson_assessments_path(selectedUnitLesson.value, { program_id: programId })}
        target={openAssessmentInNewTab ? '_blank' : '_self'}
      >
        Assessments
      </a>
      {' '} to create one.
    </div>
  );

  return (
    <>
      <SelectField
        className={styles.assignableSelector}
        disabled={assessments.length === 0}
        fieldClass={styles.largeField}
        label="Details"
        labelClass={sectionStyles.label}
        name="modelsToAssign"
        searchable={false}
        placeholder="Select one"
        onChange={handleAssessmentChange}
        options={assessments}
        required
        rowClass={sectionStyles.fieldContainer}
      />
      {assessments.length <= 0 && renderNoAssessmentsMessage()}
    </>
  );
};

export default AssessmentSelector;
