import * as fabric from 'fabric';
import { LegacyCanvasDimensions } from './types';
import { ORIGINAL_DIMENSIONS } from './hooks/useCanvasResizer';
import rotateJpg from './rotate.png';

const snapToGrid = (axis, activeObject, position) => {
  activeObject.set(axis, position);
  activeObject.setCoords();
};

const showGuideLines = (showVGuide, showHGuide, verticalGuide, horizontalGuide) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  showVGuide ? verticalGuide.show() : verticalGuide.hide();
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  showHGuide ? horizontalGuide.show() : horizontalGuide.hide();
};

export const isObjectRotating = (activeObject: fabric.FabricObject) => {
  const currentAngle = activeObject.getTotalAngle();

  return new Promise((resolve) => {
    setTimeout(() => resolve(!(activeObject.getTotalAngle() === currentAngle)), 100);
  });
};

export const hideGuideLines = () => {
  $('#guide-v').hide();
  $('#guide-h').hide();
};

export const drawGuideLines = (
  activeObject: fabric.FabricObject | fabric.ActiveSelection,
  canvas: fabric.Canvas,
) => {
  let showHGuide: boolean;
  let showVGuide: boolean;

  const verticalGuide = $('#guide-v');
  const horizontalGuide = $('#guide-h');

  const pointer = canvas.getViewportPoint(null);

  const TOLERANCE = 10 * canvas.getZoom();

  canvas.forEachObject((targ: fabric.FabricObject) => {
    const activeObjectWidth = activeObject.getScaledWidth() * canvas.getZoom();
    const activeObjectHeight = activeObject.getScaledHeight() * canvas.getZoom();

    if (
      activeObject.isType(fabric.ActiveSelection.type)
      && (activeObject as fabric.ActiveSelection).getObjects().some(obj => obj === targ)
    ) {
      return;
    }
    if (targ === activeObject) return;

    // Allow unsnapping when moving away from the tolerance area
    if (activeObject.getScaledWidth() < targ.getScaledWidth()) {
      if (pointer.x < targ.oCoords.tl.x + (1.5 * TOLERANCE) || pointer.x > targ.oCoords.tl.x + (6 * TOLERANCE)) {
        snapToGrid('left', activeObject, (pointer.x - (activeObjectWidth / 2)) / canvas.getZoom());
      }

      // Use separate logic when active object is larger than target.
    } else if (pointer.x < targ.oCoords.tl.x + targ.getScaledWidth() - TOLERANCE || pointer.x > targ.oCoords.tl.x + targ.getScaledWidth() + TOLERANCE) {
      snapToGrid('left', activeObject, (pointer.x - (activeObjectWidth / 2)) / canvas.getZoom());
    } else if (pointer.y < targ.oCoords.tl.y + targ.getScaledHeight() - TOLERANCE || pointer.y > targ.oCoords.tl.y + targ.getScaledHeight() + TOLERANCE) {
      snapToGrid('top', activeObject, (pointer.y - (activeObjectHeight / 2)) / canvas.getZoom());
    }

    const targWidth = targ.oCoords.tr.x - targ.oCoords.tl.x;
    const targetMiddle = targ.oCoords.tl.x + (targWidth / 2);

    const activeObjectTop = activeObject.top * canvas.getZoom();
    const activeObjectRight = (activeObject.left + activeObject.getScaledWidth()) * canvas.getZoom();
    const activeObjectLeft = activeObject.left * canvas.getZoom();
    const activeObjectMiddle = activeObjectLeft + (activeObjectWidth / 2);
    const activeObjectBottom = (activeObject.top + activeObject.getScaledHeight()) * canvas.getZoom();

    const targetObjectTop = targ.top * canvas.getZoom();
    const targetObjectRight = (targ.left + targ.getScaledWidth()) * canvas.getZoom();
    const targetObjectLeft = targ.left * canvas.getZoom();
    const targetObjectBottom = (targ.top + targ.getScaledHeight()) * canvas.getZoom();
    const targetObjectWidth = targetObjectRight - targetObjectLeft;
    const targetObjectMiddle = targetObjectLeft + (targetObjectWidth / 2);
    const targetObjectHeight = targ.getScaledHeight() * canvas.getZoom();

    // right edge of active object meets left edge of target
    if (Math.abs(activeObjectRight - targetObjectLeft) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left - activeObject.getScaledWidth());
      showVGuide = true;
      verticalGuide.css('left', targ.left * canvas.getZoom());
    }
    // right edge of the active object meets the vertical middle of the target
    if (Math.abs(activeObjectRight - targetMiddle) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + (targ.getScaledWidth() / 2) - activeObject.getScaledWidth());
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth() / 2) * canvas.getZoom());
    }
    // right edge of active object meets right edge of target
    if (Math.abs(activeObjectRight - targetObjectRight) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + targ.getScaledWidth() - activeObject.getScaledWidth());
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth()) * canvas.getZoom());
    }

    // left edge of active object meets left edge of target
    if (Math.abs(activeObjectLeft - targetObjectLeft) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left);
      showVGuide = true;
      verticalGuide.css('left', targ.left * canvas.getZoom());
    }
    // left edge of the active object meets the vertical middle of the target
    if (Math.abs(activeObjectLeft - (targetObjectLeft + targetObjectWidth / 2)) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + (targ.getScaledWidth() / 2));
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth() / 2) * canvas.getZoom());
    }
    // left edge of active object meets right edge of target
    if (Math.abs(activeObjectLeft - targetObjectRight) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + targ.getScaledWidth());
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth()) * canvas.getZoom());
    }

    // vertical middle of the active object meets the left edge of the target
    if (Math.abs((activeObjectLeft + activeObjectWidth / 2) - targetObjectLeft) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left - (activeObject.getScaledWidth() / 2));
      showVGuide = true;
      verticalGuide.css('left', targ.left * canvas.getZoom());
    }
    // vertical middle of the active object meets the vertical middle of the target
    if (Math.abs(targetObjectMiddle - activeObjectMiddle) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + (targ.getScaledWidth() / 2) - (activeObject.getScaledWidth() / 2));
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth() / 2) * canvas.getZoom());
    }
    // vertical middle of the active object meets the right edge of the target
    if (Math.abs(activeObjectRight - (activeObjectWidth / 2) - targetObjectRight) < TOLERANCE) {
      snapToGrid('left', activeObject, targ.left + targ.getScaledWidth() - (activeObject.getScaledWidth() / 2));
      showVGuide = true;
      verticalGuide.css('left', (targ.left + targ.getScaledWidth()) * canvas.getZoom());
    }

    // top edge of active object meets top edge of target
    if (Math.abs(targetObjectTop - activeObjectTop) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top);
      showHGuide = true;
      horizontalGuide.css('top', targ.top * canvas.getZoom());
    }
    // top edge of the active object meets the horizontal middle of the target
    if (Math.abs(activeObjectTop - (targetObjectTop + (targetObjectHeight / 2))) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + (targ.getScaledHeight() / 2));
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight() / 2) * canvas.getZoom());
    }
    // top edge of active object meets bottom edge of target
    if (Math.abs(targetObjectBottom - activeObjectTop) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + targ.getScaledHeight());
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight()) * canvas.getZoom());
    }

    // bottom edge of active object meets top edge of target
    if (Math.abs(activeObjectBottom - targetObjectTop) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top - activeObject.getScaledHeight());
      showHGuide = true;
      horizontalGuide.css('top', targ.top * canvas.getZoom());
    }
    // bottom edge of the active object meets the horizontal middle of the target
    if (Math.abs(activeObjectBottom - (targetObjectTop + (targetObjectHeight / 2))) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + (targ.getScaledHeight() / 2) - activeObject.getScaledHeight());
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight() / 2) * canvas.getZoom());
    }
    // bottom edge of active object meets bottom edge of target
    if (Math.abs(activeObjectBottom - targetObjectBottom) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + targ.getScaledHeight() - activeObject.getScaledHeight());
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight()) * canvas.getZoom());
    }

    // horizontal middle of the active object meets the top edge of the target
    if (Math.abs((activeObjectTop + activeObjectHeight / 2) - targetObjectTop) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top - (activeObject.getScaledHeight() / 2));
      showHGuide = true;
      horizontalGuide.css('top', targ.top * canvas.getZoom());
    }
    // horizontal middle of the active object meets the horizontal middle of the target
    if (Math.abs((activeObjectTop + activeObjectHeight / 2) - (targetObjectTop + (targetObjectHeight / 2))) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + (targ.getScaledHeight() / 2) - (activeObject.getScaledHeight() / 2));
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight() / 2) * canvas.getZoom());
    }
    // horizontal middle of the active object meets the bottom edge of the target
    if (Math.abs((activeObjectTop + activeObjectHeight / 2) - targetObjectBottom) < TOLERANCE) {
      snapToGrid('top', activeObject, targ.top + targ.getScaledHeight() - activeObject.getScaledHeight() / 2);
      showHGuide = true;
      horizontalGuide.css('top', (targ.top + targ.getScaledHeight()) * canvas.getZoom());
    }
  });

  showGuideLines(showVGuide, showHGuide, verticalGuide, horizontalGuide);
};

export function setupCustomSelectTools() {
  const img = new Image();
  img.src = rotateJpg;
  const { controls } = fabric.InteractiveFabricObject.createControls();

  function renderIcon(ctx, left, top, _styleOverride, fabricObject) {
    const size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    if (img.complete) ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  const mtr = new fabric.Control({
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    actionName: 'rotate',
    cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    offsetY: 20,
    render: renderIcon,
    withConnection: false,
    y: 0.5,
  });

  controls.mtr = mtr;

  fabric.InteractiveFabricObject.ownDefaults = {
    ...fabric.InteractiveFabricObject.ownDefaults,
    centeredRotation: true,
    cornerColor: '#fff',
    transparentCorners: false,
    cornerStrokeColor: '#3498db',
    borderColor: '#3498db',
    cornerSize: 11,
    borderScaleFactor: 2,
    controls: {
      ...controls,
    },
  };
}

export function centerImage(fabricImage: fabric.FabricObject) {
  const scaleWidth = ORIGINAL_DIMENSIONS.width / fabricImage.width;
  const scaleHeight = ORIGINAL_DIMENSIONS.height / fabricImage.height;

  const scaleFactor = Math.min(scaleWidth, scaleHeight);
  fabricImage.scale(scaleFactor);
  // eslint-disable-next-line no-param-reassign
  fabricImage.erasable = false;
  fabricImage.setX((ORIGINAL_DIMENSIONS.width - (fabricImage.width * scaleFactor)) / 2);
}

interface TranslateLegacyObjectsProps {
  backgroundImageDimensions?: LegacyCanvasDimensions;
  canvas: fabric.Canvas;
}

// this is used in the new drawing tool to translate v3 objects to v6 objects
export function translateLegacyObjects({
  backgroundImageDimensions,
  canvas,
}: TranslateLegacyObjectsProps) {
  // Retrieve the old canvas dimensions
  const oldCanvasWidth = Math.max(850, backgroundImageDimensions?.width ?? 0);
  const oldCanvasHeight = backgroundImageDimensions?.height || 600;

  // Retrieve the new canvas dimensions
  const newCanvasWidth = canvas.width;
  const newCanvasHeight = canvas.height;

  // Calculate the scaling factors for width and height based on the background image
  const widthScale = newCanvasWidth / oldCanvasWidth;
  const heightScale = newCanvasHeight / oldCanvasHeight;

  // Use the smaller scale factor to maintain the aspect ratio of the background image
  const scaleFactor = Math.min(widthScale, heightScale);

  // Calculate the zoom factor of the canvas
  const zoom = canvas.getZoom();

  // Centering offset for the new canvas
  const offsetX = (newCanvasWidth - oldCanvasWidth * scaleFactor) / 2;
  const offsetY = (newCanvasHeight - oldCanvasHeight * scaleFactor) / 2;

  canvas.getObjects().forEach((object) => {
    // @ts-ignore-next-line
    if (object.version !== '3.6.6') return;

    object.set({
      left: (object.left * scaleFactor + offsetX) / zoom,
      scaleX: (object.scaleX * scaleFactor) / zoom,
      scaleY: (object.scaleY * scaleFactor) / zoom,
      top: (object.top * scaleFactor + offsetY) / zoom,
    });

    object.setCoords();
  });
}

interface TranslateNewObjectsProps {
  canvas: fabric.Canvas;
  canvasDimensions: LegacyCanvasDimensions;
}

// this is used in the old drawing tool to translate v6 objects to v3 objects
export function translateNewObjects({
  canvas,
  canvasDimensions,
}: TranslateNewObjectsProps) {
  // Retrieve the old canvas dimensions
  const oldCanvasWidth = canvasDimensions.width;
  const oldCanvasHeight = canvasDimensions.height;

  // Assume the new canvas dimensions are 1366x768 (16:9 aspect ratio)
  const newCanvasWidth = 1366;
  const newCanvasHeight = 768;

  // Calculate the scaling factors for width and height based on the background image
  const widthScale = newCanvasWidth / oldCanvasWidth;
  const heightScale = newCanvasHeight / oldCanvasHeight;

  // Use the smaller scale factor to maintain the aspect ratio of the background image
  const scaleFactor = Math.min(widthScale, heightScale);

  // Calculate the zoom factor of the canvas (assuming it's 1 if not zoomed)
  const zoom = 1;

  const offsetX = (newCanvasWidth - oldCanvasWidth * scaleFactor) / 2;
  const offsetY = (newCanvasHeight - oldCanvasHeight * scaleFactor) / 2;

  canvas.getObjects().forEach((object) => {
    // @ts-ignore-next-line
    if (object.version !== '6.1.0') return;

    object.set({
      left: (object.left * zoom - offsetX) / scaleFactor,
      scaleX: object.scaleX / scaleFactor / zoom,
      scaleY: object.scaleY / scaleFactor / zoom,
      top: (object.top * zoom - offsetY) / scaleFactor,
    });

    object.setCoords();
  });
}
