import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'components/common/Modal';
import Form from './Form';

interface CopyLinkProps {
  ajaxPath: string;
  dropdownItemText?: string;
  statusPath: string;
  testName: string;
}

interface CopyModalProps {
  ajaxPath: string;
  closeModal: () => void;
  modalIsOpen: boolean;
  statusPath: string;
  testName: string;
}

export const CopyModal = ({ closeModal, modalIsOpen, ajaxPath, testName, statusPath }: CopyModalProps) => (
  <Modal
    closeModal={closeModal}
    isOpen={modalIsOpen}
    headerText="Copy Test"
    id="copyButton"
    role="dialog"
    aria-modal="true"
    aria-labelledby="Copy Test"
  >
    <Form
      ajaxPath={ajaxPath}
      initialName={`Copy of ${testName}`}
      statusPath={statusPath}
    />
  </Modal>
);

const CopyLink = ({ dropdownItemText = 'Copy', ajaxPath, statusPath, testName }: CopyLinkProps) => {
  const modalManager = useModalManager();

  return (
    <div>
      <a
        href="#"
        className="dropdown-item js-prevent-default"
        onClick={modalManager.open}
        role="button"
      >
        <i className="fa fa-clone" aria-hidden="true" /> {dropdownItemText}
      </a>
      <CopyModal
        ajaxPath={ajaxPath}
        closeModal={modalManager.close}
        modalIsOpen={modalManager.isOpen}
        statusPath={statusPath}
        testName={testName}
      />
    </div>
  );
};

export default CopyLink;
