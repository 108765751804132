import React, { Component } from 'react';
import Select from 'common/ReactSelect5';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Axios from 'axios';
import I18n from 'i18n';
import { flattenDeep, flattenDeepArray } from '../../../modules/TCIUtils';
import { convertCopyright, formatSelectOption } from '../Forms/Utils';
import DatePicker from './DatePicker';
import ReactAsyncSelectAdapter from './ReactAsyncSelectAdapter';
import ReactSelectAdapter from './ReactSelectAdapter';
import styles from './AdvancedFilters.module.scss';
import AutoSave from '../Forms/AutoSave';
import { propertyPropTypes } from './Utils';

const BOOLEAN_OPTIONS = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' }
];

const NO_FILTER_CHOSEN = 'no_filter_chosen';

export const OPERATORS = {
  cont: { label: 'Contains', value: 'cont' },
  not_cont: { label: 'Not contains', value: 'not_cont' },
  eq: { label: 'Equals', value: 'eq' },
  not_eq: { label: 'Not equals', value: 'not_eq' },
  gteq: { label: 'Greater than or equal to', value: 'gteq' },
  lteq: { label: 'Less than or equal to', value: 'lteq' },
  blank: { label: 'Is Blank', value: 'blank' }
};

export default class FilterRow extends Component {
  static propTypes = {
    activeProperties: PropTypes.arrayOf(PropTypes.string),
    asyncSearchPath: PropTypes.string,
    clearable: PropTypes.bool,
    disableSelectedProperties: PropTypes.bool,
    index: PropTypes.number.isRequired,
    initialOperatorValue: PropTypes.string,
    initialProperty: propertyPropTypes,
    initialValue: PropTypes.string,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    resources: PropTypes.arrayOf(PropTypes.object).isRequired,
    addToFilters: PropTypes.func.isRequired,
    removeFilterRow: PropTypes.func.isRequired,
    removeFromFilters: PropTypes.func.isRequired
  };

  static defaultProps = {
    activeProperties: [],
    asyncSearchPath: null,
    clearable: true,
    disableSelectedProperties: false,
    initialOperatorValue: null,
    initialProperty: null,
    initialValue: null
  };

  static isCopyrightFilter(filterName) {
    return filterName.includes('full_title_with_copyright') || filterName.includes('copyrighted_title_with_code');
  }

  constructor(props) {
    super(props);

    const value = this.props.initialValue ?
      formatSelectOption(this.props.initialValue) : null;

    this.state = {
      autofillOptions: [],
      currentFilter: NO_FILTER_CHOSEN,
      operators: [],
      selectedProperty: this.props.initialProperty,
      selectedOperator: this.props.initialOperatorValue,
      value
    };

    this.formRef = React.createRef();
    this.selectRef = React.createRef();
    this.bindSelectRef = this.bindSelectRef.bind(this);
  }

  componentDidMount() {
    this._setDefaultFilter();
  }

  isBooleanOperator() {
    if (!this.state.selectedOperator) return this.state.selectedProperty.isBoolean;

    return this.state.selectedProperty.isBoolean || this.state.selectedOperator.value === OPERATORS.blank.value;
  }

  // looks through custom config for specified key, otherwise return default
  getAsyncConfig(key, defaultVal) {
    return (this.state.selectedProperty.asyncConfig && this.state.selectedProperty.asyncConfig[key]) || defaultVal;
  }

  getOptions = async (input) => {
    if (!this.state.selectedProperty) return null;

    if (this.isBooleanOperator()) return { options: BOOLEAN_OPTIONS };

    if (this.state.selectedProperty.autofillOptions) {
      return { options: this.useInitialAutofillOptions() };
    }

    return Axios
      .post(this.getAsyncConfig('path', this.props.asyncSearchPath), this.getAutofillParams(input))
      .then(response => ({ options: this.formatOptions(response.data.data) }))
      .catch(error => console.log(error));
  };

  getAutofillParams(input) {
    const property = this.getAsyncConfig('value', this.state.selectedProperty.value);
    const autofillParams = { search: { [`${property}_not_null`]: true } };
    if (!this.state.selectedProperty.isNumerical) {
      autofillParams.search[`${property}_cont`] = input || '';
    }

    return autofillParams;
  }

  setAutofillOptions() {
    const property = this.state.selectedProperty;
    let autofillOptions = [];

    if (property) {
      if (property.autofillOptions) {
        autofillOptions = this.useInitialAutofillOptions();
      }
      else { // pull options from table resources
        let subscribers = this.props.resources.map(subscriber => subscriber[property.value]);
        subscribers = [...new Set(subscribers)].sort();
        autofillOptions = subscribers.map(option => formatSelectOption(option));
      }
    }

    this.setState({ autofillOptions });
  }

  getOperatorSelectOptions() {
    return Object.keys(OPERATORS)
      .filter(key => this.state.selectedProperty.operators.includes(key))
      .map(key => OPERATORS[key]);
  }

  setOperators(selected = null, persistValue = false) {
    const operators = this.getOperatorSelectOptions();
    const selectedOperator = selected || operators[0];

    this.setState(
      { operators, selectedOperator },
      () => this.updatePropertyAndOperator(persistValue)
    );
  }

  // Use auto-fill options passed in with the given property
  useInitialAutofillOptions() {
    const options = this.state.selectedProperty.autofillOptions;

    return options.map((option) => {
      const convertedOption = Object.assign({}, option);
      convertedOption.label = convertCopyright(option.label);
      return convertedOption;
    });
  }

  _setDefaultFilter() {
    if (this.props.initialProperty && this.props.initialOperatorValue) {
      const selectedOperator = OPERATORS[this.props.initialOperatorValue];
      this.setOperators(selectedOperator, true);
      if (!this.props.asyncSearchPath) this.setAutofillOptions();
    }
  }

  formatOptions(data) {
    const property = this.getAsyncConfig('value', this.state.selectedProperty.value);
    const flattenedData = data.map(unflattenedData => flattenDeep(unflattenedData, '', false));

    let options = FilterRow.isCopyrightFilter(property) ?
      flattenedData.map(option => convertCopyright(option[property])) :
      flattenedData.map(option => option[property]);

    // this seems overly verbose, can we just check if the property is an array instead?
    if (property.includes('subjects') || property.includes('grades') || property.includes('mapping_rule')) {
      options = flattenDeepArray(options);
    }

    options = [...new Set(options)].filter(el => el).sort();

    return options.map(option => formatSelectOption(option));
  }

  search = async (values) => {
    const filterName = Object.keys(values)[0];
    const filterValue = Object.values(values)[0];

    if (filterName === NO_FILTER_CHOSEN || !filterValue) return;
    const value = FilterRow.isCopyrightFilter(filterName) ?
      convertCopyright(filterValue.value, false) : filterValue.value;

    this.props.addToFilters(filterName, value);
  };

  bindSelectRef(ref) {
    this.selectRef = ref;
  }

  clearFiltersAndInputs() {
    this.clearInputs(this.state.currentFilter);
    this.setState({
      autofillOptions: [],
      currentFilter: NO_FILTER_CHOSEN,
      operators: [],
      selectedOperator: null,
      selectedProperty: null,
      value: null
    });
  }

  clearInputs(filter) {
    this.clearInputValues();
    this.formRef.form.mutators.clearField(filter);
  }

  clearInputValues() {
    if (!this.isSelectedPropDate()) {
      this.selectRef.setState({ inputValue: '' });
      if (this.props.asyncSearchPath) {
        this.selectRef.setState({ options: [] });
        this.selectRef.select.setState({ inputValue: '' });
      }
    }
    else {
      this.selectRef.clear();
    }
  }

  _generateCurrentFilter() {
    const { selectedProperty: property, selectedOperator: operator } = this.state;
    let propertyValue = property.value;

    // negate "or" chaining for negative operators
    if (operator.value.startsWith('not')) propertyValue = propertyValue.replace(/_or_/g, '_and_');

    return `${propertyValue}_${operator.value}`;
  }

  updatePropertyAndOperator(persistValue = false) {
    const prevFilter = this.state.currentFilter;
    const currentFilter = this._generateCurrentFilter();

    this.setState({ currentFilter });
    this.props.removeFromFilters(prevFilter);
    this.formRef.form.mutators.clearField(prevFilter);

    if (persistValue) this.formRef.form.mutators.setFieldValue(currentFilter, this.state.value);
    else {
      this.clearInputs(prevFilter);
      this.setState({ value: null });
    }
  }

  handlePropertyChange(property) {
    this.setState(
      { selectedProperty: property },
      () => {
        this.setOperators();
        if (!this.props.asyncSearchPath) this.setAutofillOptions();
      }
    );
  }

  handleOperatorChange(operator) {
    this.setState(
      { selectedOperator: operator },
      () => this.updatePropertyAndOperator(true)
    );
  }

  isSelectedPropDate() {
    if (this.state.selectedProperty) return this.state.selectedProperty.isDate;
    return false;
  }

  renderSearchField(form, values) {
    if (this.isSelectedPropDate()) {
      return (
        <Field
          name={this.state.currentFilter}
          filterName={this.state.currentFilter}
          component={DatePicker}
          className={styles.datePicker}
          changeField={form.mutators.setFieldValue}
          selected={Object.values(values).length ? Object.values(values)[0] && Object.values(values)[0].value : null}
          bindSelectRef={this.bindSelectRef}
          placeholderText={I18n.t('select_date')}
        />
      );
    }

    if (this.props.asyncSearchPath) {
      return (
        <Field
          className={`${styles.operatorSelect} mr20`}
          clearable={this.props.clearable}
          component={ReactAsyncSelectAdapter}
          name={this.state.currentFilter}
          placeholder={I18n.t('enter_value')}
          bindSelectRef={this.bindSelectRef}
          onInputChange={value => form.mutators.setFieldValue(this.state.currentFilter, formatSelectOption(value))}
          onChange={value => form.mutators.setFieldValue(this.state.currentFilter, value)}
          getOptions={this.getOptions}
          key={JSON.stringify(this.state.currentFilter)}
        />
      );
    }

    const searchable = !(this.state.selectedProperty && this.state.selectedProperty.disableSearch);

    return (
      <Field
        clearable={this.props.clearable}
        searchable={searchable}
        className={`${styles.operatorSelect} mr20`}
        component={ReactSelectAdapter}
        options={this.state.autofillOptions}
        name={this.state.currentFilter}
        placeholder={I18n.t('enter_value')}
        bindSelectRef={this.bindSelectRef}
        onInputChange={value => form.mutators.setFieldValue(this.state.currentFilter, formatSelectOption(value))}
        onChange={value => form.mutators.setFieldValue(this.state.currentFilter, value)}
      />
    );
  }

  renderRemoveFilterButton() {
    return (
      <button
        className={`fa fa-trash-o fa-fw ${styles.deleteFilterRowButton}`}
        aria-label="Remove Filter"
        onClick={() => this.props.removeFilterRow(this.state.currentFilter, this.props.index)}
      />
    );
  }

  disabledProperties() {
    if (!this.props.disableSelectedProperties) return this.props.properties;

    return this.props.properties
      .map((element) => {
        const shouldBeDisabled = this.props.activeProperties.some(prop => prop.includes(element.value));

        return Object.assign(
          {},
          element,
          { disabled: shouldBeDisabled }
        );
      });
  }

  render() {
    return (
      <Form
        ref={(ref) => { this.formRef = ref; }}
        onSubmit={this.search}
        mutators={{
          clearField: (args, state, utils) => {
            utils.changeValue(state, args[0], () => undefined);
          },
          setFieldValue: (args, state, utils) => {
            this.setState({ value: args[1] });
            utils.changeValue(state, args[0], () => args[1]);
          }
        }}
        render={({ form, values }) => (
          <div className={styles.formRow}>
            <AutoSave forFilters save={this.search} debounce={400} />
            <Select
              aria-label="Filter Row Property Select"
              clearable={false}
              searchable={false}
              value={this.state.selectedProperty}
              options={this.disabledProperties()}
              className={`${styles.propertySelect} mr20`}
              onChange={property => this.handlePropertyChange(property, form)}
              placeholder={I18n.t('property')}
            />
            <Select
              aria-label="Filter Row Operator Select"
              clearable={false}
              searchable={false}
              value={this.state.selectedOperator}
              options={this.state.operators}
              className={`${styles.operatorSelect} mr20`}
              onChange={operator => this.handleOperatorChange(operator, form)}
              disabled={this.state.selectedProperty && this.state.selectedProperty.isBoolean}
              placeholder={I18n.t('operator')}
            />
            {this.renderSearchField(form, values)}
            {this.renderRemoveFilterButton()}
          </div>
        )}
      />
    );
  }
}
