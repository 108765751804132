import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from './ConnectStudentsButton.module.scss';
import googleClassroomLogo from './connection-confirmation.png';
import canvasLogo from '../ConnectClassroom/canvas_logo.svg';

const MatchRow = ({
  lmsStudent, matchData, tciStudents, createMatch, lmsType
}) => {
  const currentMatch = matchData[lmsStudent.uid];

  return (
    <Fragment>
      <span>{lmsStudent.firstName} {lmsStudent.lastName}</span>
      <div className={`${styles.middle} center`}>
        {currentMatch && <i className="fa fa-arrow-right" aria-hidden="true" />}
      </div>
      <StudentSelector
        tciStudents={tciStudents}
        matchData={matchData}
        currentMatch={currentMatch}
        createMatch={createMatch}
        lmsStudent={lmsStudent}
        lmsType={lmsType}
      />
    </Fragment>
  );
};

MatchRow.propTypes = {
  createMatch: PropTypes.func.isRequired,
  lmsStudent: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }).isRequired,
  lmsType: PropTypes.string.isRequired,
  matchData: PropTypes.shape().isRequired,
  tciStudents: PropTypes.arrayOf(PropTypes.shape({
    fullNameWithUsername: PropTypes.string
  })).isRequired
};

const StudentSelector = ({
  tciStudents, currentMatch, createMatch, lmsStudent, matchData, lmsType
}) => {
  const removeMatch = () => createMatch(lmsStudent.uid, null);

  if (currentMatch && currentMatch.previouslyExisted) {
    return (
      <div className={styles.existingMatch}>
        <span className={styles.name}>{currentMatch.firstName} {currentMatch.lastName} ({currentMatch.username})</span>
        <button type="button" className="btn btn--link-purple" onClick={removeMatch}>Remove match</button>
        <img src={lmsType === 'google' ? googleClassroomLogo : canvasLogo} height="30px" alt="" />
      </div>
    );
  }

  const currentMatchId = currentMatch && currentMatch.tciId;

  // Once a TCI student has been matched to a Google Classroom student, we want to hide them from all of the other
  // dropdowns
  const studentsToShow = tciStudents.filter((tciStudent) => {
    const matchedToCurrentLmsStudent = tciStudent.id === currentMatchId;
    if (matchedToCurrentLmsStudent) return true;

    const matchedToOtherLmsStudent = Object.values(matchData).find(data => data && (data.tciId === tciStudent.id));
    return !matchedToOtherLmsStudent;
  });

  return (
    <Select
      options={studentsToShow.map(tciStudent => ({
        label: `${tciStudent.firstName} ${tciStudent.lastName} (${tciStudent.username})`,
        value: tciStudent.id
      }))}
      value={currentMatchId}
      onChange={e => createMatch(lmsStudent.uid, e && e.value)}
    />
  );
};

StudentSelector.propTypes = {
  createMatch: PropTypes.func.isRequired,
  currentMatch: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    previouslyExisted: PropTypes.bool,
    tciId: PropTypes.string,
    username: PropTypes.string
  }),
  lmsStudent: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }).isRequired,
  lmsType: PropTypes.string.isRequired,
  matchData: PropTypes.shape().isRequired,
  tciStudents: PropTypes.arrayOf(PropTypes.shape({
    fullNameWithUsername: PropTypes.string
  })).isRequired
};

StudentSelector.defaultProps = {
  currentMatch: null
};

export default MatchRow;
