import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CsvSteps from './Steps/Configuration/CsvSteps';
import NonCsvSteps from './Steps/Configuration/NonCsvSteps';

import { ROSTER_METHOD_STEP } from '../steps';

export default class ConfigurationSteps extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      contact_email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      sync_type: PropTypes.string,
    }),
    autoRosterDistrictPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    completedSteps: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ])).isRequired,
    currentStep: PropTypes.number,
    env: PropTypes.string,
    firstPageBackCallback: PropTypes.func.isRequired,
    mapCoursesPath: PropTypes.string.isRequired,
    mappedCoursesSize: PropTypes.number.isRequired,
    nextSyncTime: PropTypes.string,
    subscriber: PropTypes.shape({
      id: PropTypes.number,
      uid: PropTypes.string
    }),
    subscriberUpdatePath: PropTypes.string.isRequired,
    teacherSeatsLocked: PropTypes.bool,
    updateRosterDistrict: PropTypes.func.isRequired,
    updateSubscriber: PropTypes.func.isRequired,
    updateTeacherSeatsLocked: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    autoRosterDistrict: null,
    currentStep: null,
    env: '',
    nextSyncTime: null,
    subscriber: null,
    teacherSeatsLocked: false
  };

  _renderSteps(Element) {
    return (
      <Element
        subscriber={this.props.subscriber}
        autoRosterDistrict={this.props.autoRosterDistrict}
        autoRosterDistrictPath={this.props.autoRosterDistrictPath}
        subscriberUpdatePath={this.props.subscriberUpdatePath}
        autoRosterUpdatePath={this.props.autoRosterDistrictPath}
        updateSubscriber={this.props.updateSubscriber}
        updateRosterDistrict={this.props.updateRosterDistrict}
        completedSteps={this.props.completedSteps}
        initialStep={this.props.currentStep > 0 ? this.props.currentStep - 1 : 0}
        firstPageBackCallback={this.props.firstPageBackCallback}
        closeModal={this.props.closeModal}
        mapCoursesPath={this.props.mapCoursesPath}
        env={this.props.env}
        mappedCoursesSize={this.props.mappedCoursesSize}
        nextSyncTime={this.props.nextSyncTime}
        teacherSeatsLocked={this.props.teacherSeatsLocked}
        updateTeacherSeatsLocked={this.props.updateTeacherSeatsLocked}
        userType={this.props.userType}
      />
    );
  }

  render() {
    return this._renderSteps(this.props.completedSteps[ROSTER_METHOD_STEP] === 'tci_csv' ? CsvSteps : NonCsvSteps);
  }
}
