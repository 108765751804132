import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import styles from './LanguageToggle.module.scss';
import clsx from 'clsx';

const getLanguage = (locale, collapsed, isAdminEnvironment) => {
  if (collapsed || isAdminEnvironment) return locale === 'en' ? 'EN' : 'ES';

  return locale === 'en' ? 'English' : 'Español';
};

const LanguageToggleWrapper = ({ locale, copyright, colorMode, collapsed, isAdminEnvironment }) => (
  <ApolloProvider client={apolloClient}>
    <LanguageToggle
      collapsed={collapsed}
      colorMode={colorMode}
      copyright={copyright}
      isAdminEnvironment={isAdminEnvironment}
      locale={locale}
    />
  </ApolloProvider>
);

LanguageToggleWrapper.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  colorMode: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  isAdminEnvironment: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

LanguageToggleWrapper.defaultProps = {
  isAdminEnvironment: false,
};

const SAVE_LOCALE = gql`
mutation SaveLocale($locale: String!) {
  updateLocale(locale: $locale) {
    success
  }
}
`;

const reload = () => window.location.reload();

const Spinner = () => <i aria-hidden className="fa fa-spinner fa-spin mr5" />;

const LanguageToggle = ({ locale: initialLocale, copyright, colorMode, collapsed, isAdminEnvironment }) => {
  const [locale, setLocale] = useState(initialLocale);
  const [saveLocale] = useMutation(SAVE_LOCALE);
  const [loading, setLoading] = useState(false);

  const updateLocale = (newLocale) => {
    if (newLocale === locale) return;

    setLocale(newLocale);
    setLoading(true);
    saveLocale({ variables: { locale: newLocale } }).then(reload);
  };

  return (
    <div className={clsx('dsp-flex-center', {
      [`${styles.bottomContainer} tw-fixed tw-bottom-0`]: !isAdminEnvironment,
      'tw-bg-inherit tw-border-t-0 tw-pt-2': isAdminEnvironment,
    })}>
      <div className={clsx('dropdown', {
        'tw-left-3': !isAdminEnvironment,
        'tw-left-2.5': isAdminEnvironment,
      })}>
        <button
          type="button"
          className={clsx([`${styles.button} dropdown-toggle`], {
            'tw-p-0': isAdminEnvironment,
          })}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={loading}
          onClick={e => e.stopPropagation()}
        >
          {loading && <Spinner />}
          {getLanguage(locale, collapsed, isAdminEnvironment)}
        </button>
        <div className={clsx('dropdown-menu', {
          [styles.dropdownMenu]: !isAdminEnvironment,
        })}>
          <LanguageOption locale={locale} setLocale={updateLocale} value="en" collapsed={collapsed} />
          <LanguageOption locale={locale} setLocale={updateLocale} value="es" collapsed={collapsed} />
        </div>
      </div>
      {!isAdminEnvironment && (
        <div
          className={clsx('tw-left-12 tw-relative', {
            hidden: collapsed,
            [styles.copyrightLight]: colorMode === 'light',
            [styles.copyrightDark]: colorMode !== 'light',
          })}
        >
          &copy;
          &nbsp;
          {copyright}
        </div>
      )}
    </div>
  );
};

LanguageToggle.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  colorMode: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  isAdminEnvironment: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

LanguageToggle.defaultProps = {
  isAdminEnvironment: false,
};

const LanguageOption = ({ locale, setLocale, value, collapsed }) => {
  const selected = value === locale;

  return (
    <button
      className={`${styles.option} ${selected ? '' : styles.notSelected} dropdown-item full-size`}
      role="button"
      onClick={(e) => {
        setLocale(value);
        e.stopPropagation();
      }}
    >
      {selected && <i className={`${styles.check} fa fa-check`} />}
      {getLanguage(value, collapsed)}
    </button>
  );
};

LanguageOption.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default LanguageToggleWrapper;
