import React, { useState } from 'react';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import cn from '@/utils/cn';
import CheckboxGroupDropdown from '@/components/common/Forms/CheckboxGroupDropdown';
import Tooltip from '@/components/ui/Tooltip';
import useConditionCopyMutation, { ConditionResponse } from '@/hooks/api/useConditionCopyMutation';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { ISlide } from '../../SlideShow/stores/useSlideShowStore';
import { canSlideApplySelfRule, CONDITIONS, isConditionSelfRule, isSlideSelectable } from './utils';

interface SlideConditionCopyModalProps {
  slide: ISlide;
}

const SlideConditionCopyModal = ({ slide }: SlideConditionCopyModalProps) => {
  const [selectedSlideIds, setSelectedSlideIds] = useState(new Set<string>());

  const { close, isOpen, open } = useModalManager();
  const { isLoading, mutateAsync } = useConditionCopyMutation({
    id: slide.condition.id,
    slideIds: [...selectedSlideIds],
  });
  const slides = useSlideShowContext(state => state.slides);
  const updateSlide = useSlideShowContext(state => state.updateSlide);

  const hasValidCondition = (
    slide.condition.action
    && slide.condition.condition
    && (slide.condition.slideIds.length > 0 || slide.condition.condition === CONDITIONS.QuestionsCompletedFromThisSlide)
  );

  const slidesInCondition = slides.filter(s => slide.condition.slideIds.includes(parseInt(s.id as string, 10)));

  const slidesCopyableTo = (slides as ISlide[])
    /* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
    .filter(s => s.__typename === 'Slide' && !(s.id === slide.id))
    .filter(s => !slide.condition.slideIds.includes(parseInt((s.id as unknown as string), 10)))
    .filter(s => !s.condition)
    .filter((s) => {
      if (isConditionSelfRule(slide.condition)) return canSlideApplySelfRule(s);

      return slidesInCondition.every(other => isSlideSelectable(s, other as ISlide, slide.condition));
    });

  const slideOptions = slidesCopyableTo.map(s => ({
    label: `Slide ${s.position}`,
    value: s.id,
  }));

  const toggleText = slidesCopyableTo.length === 0 ? 'No valid slides found' : (
    slides
      .filter(s => selectedSlideIds.has(s.id as unknown as string))
      .map(s => s.position)
      .join(', ') || <span className="tw-text-[#aaa]">Select slides...</span>
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const res = await mutateAsync() as { data: ConditionResponse[] };

    res.data.forEach((condition) => {
      updateSlide(condition.slide_id, {
        condition: {
          __typename: 'Condition',
          action: condition.action,
          condition: condition.condition,
          id: condition.id.toString(),
          slideId: condition.slide_id,
          slideIds: condition.slide_ids,
        },
      });
    });

    close();
  };

  return (
    <>
      <Tooltip.Provider>
        <Tooltip disableHoverableContent>
          <Tooltip.Trigger asChild>
            <button
              aria-label="Copy slide condition"
              className={cn(
                'tw-border-none tw-bg-transparent tw-text-tci-purple tw-cursor-pointer',
                'focus:tw-outline-tci-purple focus:tw-outline focus:tw-outline-1',
              )}
              disabled={!hasValidCondition}
              onClick={open}
              type="button"
            >
              <i className="fa fa-copy" />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Content className="tw-text-sm tw-shadow tw-p-2 tw-bg-white tw-rounded tw-text-dark-grey">
            {hasValidCondition ? 'Copy' : 'Slide must be saved and have a complete condition to copy'}
            <Tooltip.Arrow className="tw-fill-white" />
          </Tooltip.Content>
        </Tooltip>
      </Tooltip.Provider>
      {open && (
        <SubmitModal
          close={close}
          headerText="Copy Slide Condition"
          isLoading={isLoading}
          isOpen={isOpen}
          isValid={selectedSlideIds.size > 0}
          onSubmit={handleSubmit}
          submitText="Copy"
        >
          <div className="tw-min-h-[225px] tw-pt-6 tw-box-border">
            <p className="tw-text-base">Select the slide(s) you want to copy the slide condition to:</p>
            <label className="tw-mb-2" htmlFor="slide-condition-dropdown">Slide(s)</label>
            <div className="tw-w-2/3 tw-min-w-min">
              <CheckboxGroupDropdown
                buttonClassName="tw-w-full"
                disabled={slidesCopyableTo.length === 0}
                dropdownClassName="tw-w-full"
                id="slide-condition-dropdown"
                hasBulkSelect
                input={{ name: 'Test', value: [...selectedSlideIds] }}
                options={slideOptions}
                meta={{ error: '', pristine: true }}
                toggleText={toggleText}
                formChangeValue={(values: string[]) => setSelectedSlideIds(new Set(values))}
              />
            </div>
          </div>
        </SubmitModal>
      )}
    </>
  );
};

export default SlideConditionCopyModal;
