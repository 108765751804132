/**
 * DataTable has two main ways of managing data (`this.state.resources`).
 *
 * 1. Simple / Default
 *   Pass in an ajaxUrl with data serialized accordingly for the given columns.
 *
 * 2. Manual
 *   Manage resources directly by passing in resources as a prop.
 */
import React, { Component, useState, useEffect } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import Table from './Table';
import { matchAttributesForMultipleRows } from './Utils';
import { Spinner } from '../../common/Modal';
import { flattenDeep } from '../../../modules/TCIUtils';

export default class DataTable extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string,
    autoWidth: PropTypes.bool,
    buttons: PropTypes.instanceOf(Array),
    columnDefs: PropTypes.instanceOf(Array),
    columns: PropTypes.instanceOf(Array).isRequired,
    createdRow: PropTypes.func,
    defaultExpandRows: PropTypes.bool,
    defaultOrder: PropTypes.instanceOf(Array),
    deferRender: PropTypes.bool,
    expandCallback: PropTypes.func,
    expandCallbackAfterInit: PropTypes.func,
    handleResourcesManually: PropTypes.bool,
    info: PropTypes.bool,
    initComplete: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingBackground: PropTypes.bool,
    model: PropTypes.string,
    noRecordsMessage: PropTypes.string,
    pageLength: PropTypes.number,
    paginatedLoading: PropTypes.bool,
    paging: PropTypes.bool,
    resources: PropTypes.instanceOf(Object),
    searching: PropTypes.bool,
    showPagingOptions: PropTypes.bool,
    showSpinner: PropTypes.bool,
    sortCallback: PropTypes.func,
    totalCount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object
    ])
  };

  static defaultProps = {
    ajaxUrl: null,
    autoWidth: true,
    buttons: [],
    columnDefs: [],
    createdRow: null,
    defaultExpandRows: false,
    defaultOrder: [0, 'asc'],
    deferRender: true,
    expandCallback: null,
    expandCallbackAfterInit: null,
    handleResourcesManually: false,
    info: true,
    initComplete: null,
    isLoading: false,
    isLoadingBackground: false,
    model: undefined,
    noRecordsMessage: 'No data available in table',
    pageLength: 20,
    paginatedLoading: false,
    paging: true,
    resources: [],
    searching: true,
    showPagingOptions: true,
    showSpinner: false,
    sortCallback: null,
    totalCount: null
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoading,
      resources: props.resources
    };
  }

  componentDidMount() {
    this._getResources();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        isLoading: nextProps.isLoading
      }, () => this._getResources());
    }
  }

  getRows(config) {
    if (this.tableRef) return this.tableRef.getRows(config);

    return null;
  }

  _getResources() {
    if (this.props.handleResourcesManually) { // resources manually handled
      const resources = this.props.resources;
      matchAttributesForMultipleRows(resources, this.props.columns);
      this.setState({ resources });
    }
    else { // resources simply handled through ajax
      this.setState({ isLoading: true });
      Axios
        .get(this.props.ajaxUrl)
        .then((response) => {
          const resources = this._processResources(response.data);
          this.setState({ isLoading: false, resources: resources });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    }
  }

  _processResources(data) {
    if (Object.keys(data).length) {
      const resources = this._flattenResponse(data);
      matchAttributesForMultipleRows(resources, this.props.columns);
      return resources;
    }
    return [];
  }

  _flattenResponse(data) {
    // if a nested resource is passed from the response, e.g.
    // { subscriber: { id: 4, auto_roster_syncs: [ ...collection ] } }
    // flatten the parent object and apply the props to each row of the collection so the parent props are accessible
    // by DataTables. If not, return the values.

    const resources = Object.values(data)[0];
    const parent = Object.keys(data)[0];
    if (!this.props.model) return resources;

    const outsideProps = Object.keys(resources).map((key) => {
      if (key !== this.props.model) return key;
      return null;
    });

    // apply outside props to nested collection
    resources[this.props.model].forEach((resource) => {
      outsideProps.forEach((prop) => {
        resource[`${parent}_${prop}`] = resources[prop];
      });
    });

    return resources[this.props.model];
  }

  render() {
    return (
      <div>
        <Spinner isLoading={this.props.showSpinner || this.state.isLoading} />
        <Table
          autoWidth={this.props.autoWidth}
          buttons={this.props.buttons}
          columns={this.props.columns}
          columnDefs={this.props.columnDefs}
          createdRow={this.props.createdRow}
          defaultOrder={this.props.defaultOrder}
          defaultExpandRows={this.props.defaultExpandRows}
          expandCallback={this.props.expandCallback}
          expandCallbackAfterInit={this.props.expandCallbackAfterInit}
          searching={this.props.searching}
          pageLength={this.props.pageLength}
          paging={this.props.paging}
          info={this.props.info}
          showPagingOptions={this.props.showPagingOptions}
          initComplete={this.props.initComplete}
          deferRender={this.props.deferRender}
          paginatedLoading={this.props.paginatedLoading}
          noRecordsMessage={this.props.noRecordsMessage}
          sortCallback={this.props.sortCallback}
          isLoadingBackground={this.props.isLoadingBackground}
          totalCount={this.props.totalCount}
          ref={(ref) => { this.tableRef = ref; }}
          {...this.state}
        />
      </div>
    );
  }
}

export function useDataTable(resourcesPath, additionalData) {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (resourcesPath) {
      Axios
        .get(resourcesPath, additionalData)
        .then((response) => {
          setResources(response.data.data);
          setIsLoading(false);
        })
        .catch(() => {
          setError(true);
        });
    }
    else {
      setIsLoading(false);
    }
  }, []);

  const addResource = (resource) => {
    setResources(resources.concat([resource]));
  };

  const updateResource = (resource) => {
    setResources(resources.map(r => (r.id === resource.id ? resource : r)));
  };

  const deleteResource = resource => setResources(resources.filter(r => r.id !== resource.id));

  const renderDataTable = ({ columns, resources: customResources, ...props }) => {
    if (error) return 'Something went wrong. Please refresh the page and try again.';

    const formattedResources = (customResources || resources).map(resource => Object.assign({ actions: '' }, resource));

    return (
      <React.Fragment>
        <DataTable
          handleResourcesManually
          isLoading={isLoading}
          resources={formattedResources}
          columns={columns}
          {...props}
        />
      </React.Fragment>
    );
  };

  return {
    DataTable: renderDataTable,
    addResource,
    deleteResource,
    error,
    resources,
    updateResource
  };
}

export function useDataTableFromQuery(query, queryVariables, queryResultName) {
  const { loading, data } = useQuery(query, { variables: queryVariables });

  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const formattedQueryResource = queryDataResource => Object.assign({ actions: '' }, flattenDeep(queryDataResource));

  const formattedQueryResources = (queryDataResources) => {
    return queryDataResources.map(queryDataResource => formattedQueryResource(queryDataResource));
  };

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }

    if (!data || !data[queryResultName]) {
      setError(true);
      return;
    }

    setResources(formattedQueryResources(data[queryResultName]));
    setIsLoading(false);
  }, [data]);

  const addResource = (resource) => {
    setResources(resources.concat([formattedQueryResource(resource)]));
  };

  const updateResource = (resource) => {
    setResources(resources.map(r => (r.id === resource.id ? formattedQueryResource(resource) : r)));
  };

  const updateResources = (updatedResources) => {
    setResources(resources.map((originalResource) => {
      const updatedResource = updatedResources.find(resource => resource.id === originalResource.id);

      return (updatedResource ? formattedQueryResource(updatedResource) : originalResource);
    }));
  };

  const deleteResource = (resource) => {
    setResources(resources.filter(r => r.id !== resource.id));
  };

  const renderDataTable = ({ columns, resources: customResources, ...props }) => {
    if (error) return 'Something went wrong. Please refresh the page and try again.';

    if (loading) return <Spinner />;

    const formattedResources = (customResources || resources).map(resource => Object.assign({ actions: '' }, resource));

    return (
      <React.Fragment>
        <DataTable
          handleResourcesManually
          isLoading={isLoading}
          resources={formattedResources}
          columns={columns}
          {...props}
        />
      </React.Fragment>
    );
  };

  return {
    DataTable: renderDataTable,
    addResource,
    deleteResource,
    error,
    resources,
    updateResource,
    updateResources
  };
}
