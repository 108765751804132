import ReactDOMServer from 'react-dom/server';
import FE from 'froala-editor';
import equation from 'images/icons/equation.svg';

import {
  ELEMENT_INSERT_COMMAND_NAME,
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  CUSTOM_AUDIO_COMMAND_NAME,
  EQUATION_COMMAND_NAME,
  IMAGE_EDIT_COMMAND_NAME,
} from '../../../../../../shared/Froala/froalaConstants';

const iconTemplate = '<i class="fa fa-[NAME]" style="color:#000000;font-size:17px" aria-hidden="true"></i>';
const svgTemplate = '<img src="[SRC]" style="width:20px;height:20px" aria-hidden="true" />';

const enterOrClick = e => e.key === undefined || e.key === 'Enter';

const imageEditCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('editImageIcon', { NAME: 'pencil-square-o', template: 'black-icon' });

  FE.RegisterCommand(IMAGE_EDIT_COMMAND_NAME, {
    focus: false,
    icon: 'editImageIcon',
    refreshAfterCallback: true,
    title: 'Edit',
    undo: false,
  });
};

const equationCommand = () => {
  FE.DefineIconTemplate('equation-icon', svgTemplate);
  FE.DefineIcon('equationIcon', { SRC: equation, template: 'equation-icon' });

  FE.RegisterCommand(EQUATION_COMMAND_NAME, {
    focus: false,
    icon: 'equationIcon',
    refreshAfterCallback: true,
    title: 'Insert Math Equation',
    undo: true,
  });
};

// Creates the element insert button
const elementInsertCommand = () => {
  FE.DefineIconTemplate('tci-purple-icon', iconTemplate);
  FE.DefineIcon('hintIcon', { NAME: 'plus-circle', template: 'tci-purple-icon' });

  FE.RegisterCommand(ELEMENT_INSERT_COMMAND_NAME, {
    focus: false,
    icon: 'hintIcon',
    refreshAfterCallback: true,
    title: 'Insert Button',
    undo: true,
  });
};

// creates the custom image button
const customImageCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addImageIcon', { NAME: 'image', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_IMAGE_COMMAND_NAME, {
    focus: false,
    icon: 'addImageIcon',
    refreshAfterCallback: true,
    title: 'Add Image',
    undo: true,
  });
};

// creates the custom video button
const customVideoCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addVideoIcon', { NAME: 'video-camera', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_VIDEO_COMMAND_NAME, {
    focus: false,
    icon: 'addVideoIcon',
    refreshAfterCallback: true,
    title: 'Add Video',
    undo: true,
  });
};

// creates the custom audio button
const customAudioCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addAudioIcon', { NAME: 'volume-down', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_AUDIO_COMMAND_NAME, {
    focus: false,
    icon: 'addAudioIcon',
    refreshAfterCallback: true,
    title: 'Add Audio',
    undo: true,
  });
};

// initializes the custom Froala toolbar commands
export const registerFroalaCommands = () => {
  elementInsertCommand();
  customImageCommand();
  customVideoCommand();
  customAudioCommand();
  equationCommand();
  imageEditCommand();
};

export const addElementInsertListeners = ({
  froalaRef,
  elementInsertModalManager,
  setEditMode,
  setElementParent,
  setHtmlElementId,
  setImageModel,
  editor
}) => {
  froalaRef.on('click', '.imageInTextObject', (e) => {
    setImageModel($(e.target).data('snippet'));
  });

  froalaRef.on('click', '[data-html-element-id]', (e) => {
    // Hide any Froala popups (eg. table styles popup) when editing a button
    editor.popups.hideAll();

    const { htmlElementId } = e.target.dataset;
    setHtmlElementId(htmlElementId);
    setElementParent($(e.target));

    setEditMode(true);
  });

  froalaRef.on('click', '.hint-icon, .reading-btn, .jump-btn, .video-btn, .question-btn', (e) => {
    // Get the element clicked and store it for editing.
    setHtmlElementId(undefined);
    setElementParent($(e.target).parent());
    setEditMode(true);
    elementInsertModalManager.open();
  });

  froalaRef.on('click', '.tooltip-btn', (e) => {
    const { htmlElementType } = e.target.dataset;

    if (htmlElementType === 'TooltipButton') return;

    setHtmlElementId(null);
    setElementParent($(e.target).parent());
    setEditMode(true);
  });

  // Focus on froala editor when clicking on an image.
  froalaRef.on('click', '.imageInTextObject', () => {
    editor.events.trigger('focus', [], true);
  });
};

// get the slide_sound_clip_id from the clicked audio button
export const addEditAudioListeners = (froalaRef, setSlideSoundClipId, setAudioButtonToEdit, modalManager) => {
  const openModal = (target) => {
    setSlideSoundClipId(target.dataset.soundClipId);
    setAudioButtonToEdit($(target));
    modalManager.open();
  };

  froalaRef.on('click', '.full-player-span', (e) => {
    openModal(e.currentTarget.children[0]);
  });

  froalaRef.on('click', '.simple-audio-player', (e) => {
    openModal(e.currentTarget);
  });
};

// If contains a tooltip, wrap the inner span if it hasn't already been wrapped
// This allows us to have the tooltip content scroll if it is longer than 400px
const wrapTooltip = (textWrapper) => {
  if (textWrapper.data('button-type') !== 'tooltip') return;

  const tooltipWrapper = textWrapper.find('.slide-popup.hint-text');
  if (tooltipWrapper.find('.tooltipWrapper').length) return;

  const tooltipContent = tooltipWrapper.find('> span');
  tooltipContent.wrap('<div class="tooltipWrapper" style="max-height: 400px; overflow: auto;"></div>');
};

const relocateTooltip = (textWrapper) => {
  if (textWrapper.data('button-type') !== 'tooltip') return;

  const tooltipWrapper = textWrapper.find('.slide-popup.hint-text');
  const navigator = $('#present-navigator');
  tooltipWrapper.css('transform', '');
  const distBetweenElements = tooltipWrapper[0].getBoundingClientRect().bottom - navigator[0].getBoundingClientRect().top;

  if (distBetweenElements > 0) {
    const slideScale = $('.slide')[0].style.transform.match(/scale\(([0-9.]*)\)/)[1];
    tooltipWrapper.css('transform', `translateY(-${50 / slideScale}px)`);
  }
};

// Drill into parent/wrapper to toggle display for text.
const toggleText = (textWrapper) => {
  wrapTooltip(textWrapper);
  textWrapper.find('.hint-icon:first').toggleClass('fa-minus-circle fa-plus-circle');
  textWrapper.find('.hint-text:first').toggle();
  relocateTooltip(textWrapper);
};

const getClipboardImage = files => files.find(file => file.type.includes('image'));

// Chrome stores multiple html tags when an image is copied
// we need to loop through them and find the img tag in the clipboard to get the src
const getImgSrc = ($html) => {
  const img = $html.find('img').first();

  return img?.attr('src');
};

export const handleImagePaste = (pasteEvent, setPastedImage, addImageModalManager) => {
  if (!pasteEvent.clipboardData) return;

  // if something is pasted
  const pasteEventFiles = [...pasteEvent.clipboardData.files];
  let imageFile;

  // and a file is on the clipboard
  if (pasteEventFiles.length) {
    imageFile = getClipboardImage(pasteEventFiles);

    // and it's an image
    if (imageFile) {
      setPastedImage(imageFile);
      addImageModalManager.open();
    }

    return;
  }

  // but a file is not on the clipboard
  // we need to check if we're pasting an image or text
  if (pasteEvent.clipboardData.getData('text/html').includes('<img')) {
    const imgSrc = getImgSrc($(pasteEvent.clipboardData.getData('text/html')));

    if (!imgSrc) return;

    // fetch the image from the src then store it in a file object
    fetch(imgSrc)
      .then(res => res.blob())
      .then((blob) => {
        imageFile = new File([blob],
          `image.${blob.type.split('/').slice(-1)[0]}`,
          {
            lastModified: new Date().getTime(),
            type: `${blob.type}`
          });
        setPastedImage(imageFile);
        addImageModalManager.open();
      });
  }
};

const brInsert = (editorInstance, elemToInsert) => {
  const height = editorInstance.$el.closest('.slideObjectAnchor').height();
  const width = editorInstance.$el.closest('.slideObjectAnchor').width();

  // If container is taller than it is wide, add br below image. Else add it above.
  if (height >= width) {
    editorInstance.html.insert(ReactDOMServer.renderToStaticMarkup(elemToInsert), true);
    editorInstance.html.insert('<br />');
  }
  else {
    editorInstance.html.insert('<br />');
    editorInstance.html.insert(ReactDOMServer.renderToStaticMarkup(elemToInsert), true);
  }
};

export const insertIntoEditor = (
  editorInstance, elemToInsert, imageInsert = false, insertedViaJquery = false, fromButton = false,
) => {
  editorInstance.selection.restore();
  // Image resizer popup will only render if there's extra space on container.
  // Add br to container if it's empty
  if (imageInsert && editorInstance.$el.get(0).children.length === 1 && !fromButton) {
    brInsert(editorInstance, elemToInsert);
  }
  else if (!insertedViaJquery) {
    editorInstance.html.insert(ReactDOMServer.renderToStaticMarkup(elemToInsert), true);
  }

  // Bug fix to trigger contentChanged event in froala editor to save slide.
  editorInstance.undo.saveStep();

  // Reset caret position to end of line
  editorInstance.selection.setAtEnd(editorInstance.$el.get(0));
  editorInstance.selection.restore();
};

const simplifiedAudioButtonListener = (e, setAudioSrc, setAudioCommand, locale) => {
  // we need a data migration for these players, as they're hardcoded into the html
  if (!enterOrClick(e)) return;

  const $currentTarget = $(e.currentTarget);

  // remove the current-target class on all audio buttons
  $('.simple-audio-player').removeClass('current-target');

  // add it to the button clicked
  $currentTarget.addClass('current-target');

  // find all other audio buttons that are not the current target
  // swap the icons of any that are playing (aka have the "stop" icon) to the "play" icon
  const $audioButtons = $('.simple-audio-player:not(.current-target)');
  $audioButtons.data('command', 'play');
  $audioButtons.find('i.fa-stop').toggleClass('fa-play fa-stop');
  $audioButtons.removeClass('btn--purple').addClass('btn--outline-purple');

  // get the src of the current target to play
  const audioSrcAttr = locale === 'en' ? 'src' : 'src-es';
  const audioSrc = $currentTarget.data(audioSrcAttr) ?? $currentTarget.data('src');
  setAudioSrc(audioSrc);

  // get the command to execute for the current target button (aka play or stop depending on the data attr)
  setAudioCommand($currentTarget.data('command'));

  // swap the command to the opposite after it's clicked
  if ($currentTarget.data('command') === 'play') {
    $currentTarget.data('command', 'stop');
  }
  else {
    $currentTarget.data('command', 'play');
  }

  // toggle the style and icon of the current target to match the appropriate action
  $currentTarget.toggleClass('btn--purple btn--outline-purple');
  $currentTarget.find('i.fa').toggleClass('fa-play fa-stop');
};

// Present Mode listeners
export const addPresentListeners = (
  froalaRef, jumpToSlide, modalManager, setSectionIds, setAudioSrc, setAudioCommand, slides, locale
) => {
  const showHints = (e) => {
    if (!enterOrClick(e)) return;

    // Find icon/button parent.
    const par = $(e.target).parents('span:first');
    const sectionIds = par.children().data('section-ids');

    if (sectionIds) {
      setSectionIds([sectionIds.toString().split(',')].flat());
      modalManager.open();
    }
    else {
      toggleText(par);
    }
  };

  const closePopupButton = (e) => {
    if (!enterOrClick(e)) return;

    $(e.target).parents('span:first').toggle();
  };

  const jump = (e) => {
    if (!enterOrClick(e)) return;

    const jumpToId = e.target.dataset.jumpTo || e.target.getAttribute('data-jump-to');
    jumpToSlide(slides.find(slide => slide.id === jumpToId.toString()).position - 1);
  };

  // Show hints on click/enter.
  froalaRef.on('click', '.hint-icon, .tooltip-btn, .reading-btn, .video-btn, .question-btn', showHints);
  froalaRef.on('keydown', '.hint-icon, .tooltip-btn, .reading-btn, .video-btn, .question-btn', showHints);

  froalaRef.on('click', '.jump-btn, .jump-link', jump);
  froalaRef.on('keydown', '.jump-btn, .jump-link', jump);

  // Close popup button.
  froalaRef.on('click', '.popup-close-btn', closePopupButton);
  froalaRef.on('keydown', '.popup-close-btn', closePopupButton);

  froalaRef.on('click', '.simple-audio-player', e => simplifiedAudioButtonListener(e, setAudioSrc, setAudioCommand, locale));
  froalaRef.on('keydown', '.simple-audio-player', e => simplifiedAudioButtonListener(e, setAudioSrc, setAudioCommand, locale));
};

export const setSpanishAudioSrc = ({ froalaRef, locale }) => {
  if (locale === 'es') {
    const fullAudioPlayers = froalaRef.find('span.full-player-span audio');
    fullAudioPlayers.each((_, player) => {
      const spanishAudioSrc = player.getAttribute('data-src-es');
      if (spanishAudioSrc) {
        player.src = spanishAudioSrc;
      }
    });
  }
};
