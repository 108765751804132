import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from 'common/ActionsDropdown';
import ToggleColumnButton from './ToggleColumnButton';
import ToggleExclusionsButton from './ToggleExclusionsButton';
import ManageProgramsButton from '../ManageProgramsWizard/ManageProgramsButton';

const ProgramActionsDropdown = ({
  exclusionSetting, initiallyUnderReview, isSysadmin, seatPoolId, showReviewButton, licenseId, subscriberStateId
}) => (
  <ActionsDropdown>
    {isSysadmin && showReviewButton && (
      <ToggleColumnButton
        name="Under Review"
        valueName="under_review"
        seatPoolId={seatPoolId}
        initialValue={initiallyUnderReview}
      />
    )}

    <ToggleExclusionsButton
      seatPoolId={seatPoolId}
      exclusionSettingInitialValue={exclusionSetting}
    />

    <ManageProgramsButton licenseId={licenseId} subscriberStateId={subscriberStateId} inDropdown />
  </ActionsDropdown>
);

ProgramActionsDropdown.propTypes = {
  exclusionSetting: PropTypes.string.isRequired,
  initiallyUnderReview: PropTypes.bool,
  isSysadmin: PropTypes.bool.isRequired,
  licenseId: PropTypes.number,
  seatPoolId: PropTypes.number.isRequired,
  showReviewButton: PropTypes.bool,
  subscriberStateId: PropTypes.number.isRequired,
};

ProgramActionsDropdown.defaultProps = {
  initiallyUnderReview: false,
  licenseId: null,
  showReviewButton: true,
};

export default ProgramActionsDropdown;
