import { Canvas, Path } from 'fabric';
import { DEFAULT_BRUSH_WIDTH } from '../../constants';

export function renderWeightSetting(canvas: Canvas | undefined) {
  if (!canvas) return false;

  const activeObjects = canvas.getActiveObjects();

  if (activeObjects.length === 0) return true;

  return activeObjects.every(obj => obj.isType(Path.type));
}

export function getPenWidth(canvas: Canvas | undefined, globalPenWidth: number) {
  if (!canvas) return DEFAULT_BRUSH_WIDTH;

  const activeObjects = canvas.getActiveObjects();

  if (activeObjects.length === 0) return globalPenWidth;

  const strokeWidths = [...new Set(activeObjects.filter(obj => obj.isType(Path.type)).map(path => path.strokeWidth))];

  if (strokeWidths.length === 1) return strokeWidths[0];

  return DEFAULT_BRUSH_WIDTH;
}

export function getPenOpacity(canvas: Canvas | undefined, globalTransparency: number) {
  if (!canvas) return DEFAULT_BRUSH_WIDTH;

  const activeObjects = canvas.getActiveObjects();

  if (activeObjects.length === 0) return globalTransparency;

  const opacities = activeObjects.map((obj) => {
    if (obj.isType(Path.type)) {
      const last = obj.stroke.toString().split(',').reverse()[0];

      return parseFloat(last.replace(')', '').trim());
    }

    return obj.opacity;
  })
    .map(opacityPercent => opacityPercent * 100)

  const uniqueOpacities = [...new Set(opacities)];

  if (uniqueOpacities.length === 1) return uniqueOpacities[0];

  return DEFAULT_BRUSH_WIDTH;
}
