import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'common/Wizard/Footer';

export default function CleverStep({
  closeModal
}) {
  return (
    <div>
      You&#8217;re all set up!
      Thanks for letting us know your district uses Clever as a sign-in method.

      <Footer
        onClick={closeModal}
        isFinalButton
      />
    </div>
  );
}

CleverStep.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
