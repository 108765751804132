import React from 'react';
import PropTypes from 'prop-types';

import Wizard from 'common/Wizard';
import DistrictConfigStep from '../DistrictConfigStep';
import ConfigurationStep from '../ConfigurationStep';
import ContactStep from '../ContactStep';
import ProgressHeader from '../ProgressHeader';
import CompletionStep from '../CompletionStep';

export default function CsvSteps({
  autoRosterDistrict, subscriber, autoRosterDistrictPath, completedSteps, initialStep, env,
  subscriberUpdatePath, updateRosterDistrict, updateSubscriber, closeModal, firstPageBackCallback, nextSyncTime,
}) {
  return (
    <Wizard
      progressHeader={<ProgressHeader completedSteps={completedSteps} isCsv />}
      initialStep={initialStep}
      firstPageBackCallback={firstPageBackCallback}
      lastPageNextCallback={closeModal}
    >
      <ConfigurationStep
        subscriber={subscriber}
        autoRosterDistrict={autoRosterDistrict}
        autoRosterDistrictPath={autoRosterDistrictPath}
        subscriberUpdatePath={subscriberUpdatePath}
        autoRosterUpdatePath={autoRosterDistrictPath}
        updateSubscriber={updateSubscriber}
        updateRosterDistrict={updateRosterDistrict}
        completedSteps={completedSteps}
      />

      <DistrictConfigStep
        subscriberId={subscriber.id}
        autoRosterDistrictPath={autoRosterDistrictPath}
        env={env}
        updateSubscriber={updateSubscriber}
        updateRosterDistrict={updateRosterDistrict}
      />

      <ContactStep
        autoRosterDistrict={autoRosterDistrict}
        autoRosterDistrictPath={autoRosterDistrictPath}
        updateAutoRosterDistrict={updateRosterDistrict}
        completedSteps={completedSteps}
      />

      <CompletionStep
        autoRosterDistrict={autoRosterDistrict}
        closeModal={closeModal}
        nextSyncTime={nextSyncTime}
        completedSteps={completedSteps}
      />
    </Wizard>
  );
}

CsvSteps.propTypes = {
  autoRosterDistrict: PropTypes.shape({
    contact_email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    sync_type: PropTypes.string,
  }),
  autoRosterDistrictPath: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  completedSteps: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])).isRequired,
  env: PropTypes.string,
  firstPageBackCallback: PropTypes.func.isRequired,
  initialStep: PropTypes.number,
  nextSyncTime: PropTypes.string,
  subscriber: PropTypes.shape({
    id: PropTypes.number
  }),
  subscriberUpdatePath: PropTypes.string.isRequired,
  updateRosterDistrict: PropTypes.func.isRequired,
  updateSubscriber: PropTypes.func.isRequired,
};

CsvSteps.defaultProps = {
  autoRosterDistrict: null,
  env: '',
  initialStep: null,
  nextSyncTime: null,
  subscriber: null
};
