import React, { Fragment } from 'react';
import ModalBody from 'staffer/ImpersonateStudent/ModalBody';
import Modal, { useModalManager } from 'common/Modal';
import keyIcon from './key_icon.png';

const ImpersonateButton = ({ student }) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <button className="dropdown-item" onClick={modalManager.open} aria-haspopup="dialog" aria-controls="impersonateStudentModal" aria-label="Sign in as student">
        <img alt="" src={keyIcon} width="13px" className="mr5" /> Sign in as Student
      </button>
      <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText="Sign in as a Student">
        <ModalBody student={student} close={modalManager.close} />
      </Modal>
    </Fragment>
  );
};

export default ImpersonateButton;
