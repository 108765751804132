import React, { Component } from 'react';
import { capitalizeWords, makeHtmlSafe } from 'TCIUtils';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import moment from 'moment';
import 'datatables.net-plugins/dataRender/datetime';
import { fileSizeFromString } from 'common/Utils';
import { expandCollapseAllRows, expandAllRows } from './Utils';

window.moment = moment;

export default class Table extends Component {
  static propTypes = {
    autoWidth: PropTypes.bool,
    buttons: PropTypes.instanceOf(Array),
    columns: PropTypes.instanceOf(Array).isRequired,
    createdRow: PropTypes.func,
    defaultExpandRows: PropTypes.bool,
    defaultOrder: PropTypes.instanceOf(Array),
    expandCallback: PropTypes.func,
    expandCallbackAfterInit: PropTypes.func,
    noRecordsMessage: PropTypes.string,
    columnDefs: PropTypes.instanceOf(Array),
    resources: PropTypes.instanceOf(Array),
    searching: PropTypes.bool,
    pageLength: PropTypes.number.isRequired,
    paging: PropTypes.bool,
    info: PropTypes.bool,
    initComplete: PropTypes.func,
    deferRender: PropTypes.bool,
    paginatedLoading: PropTypes.bool,
    showPagingOptions: PropTypes.bool,
    sortCallback: PropTypes.func,
    isLoadingBackground: PropTypes.bool,
    totalCount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object
    ])
  };

  static defaultProps = {
    autoWidth: true,
    buttons: [],
    createdRow: null,
    defaultExpandRows: false,
    expandCallback: null,
    expandCallbackAfterInit: null,
    noRecordsMessage: 'No data available in table',
    resources: [],
    columnDefs: [],
    paging: true,
    info: true,
    initComplete: null,
    defaultOrder: [],
    showPagingOptions: true,
    searching: true,
    deferRender: true,
    paginatedLoading: false,
    sortCallback: null,
    isLoadingBackground: false,
    totalCount: null
  };

  // check that all old resources are contained in new resources
  static resourcesMatch(oldResources, newResources) {
    if (newResources.length <= oldResources.length) return false;
    if (!oldResources.length) return true;
    return oldResources.some((resource, index) => resource.id === newResources[index].id);
  }

  constructor(props) {
    super(props);
    this.state = {
      resources: props.resources
    };

    this.onTableDraw = this.onTableDraw.bind(this);
  }

  componentDidMount() {
    jQuery.fn.dataTable.ext.type.order['file-size-pre'] = fileSizeFromString;
    jQuery.fn.dataTable.ext.buttons.toggleExpandAll = {
      action: (e, dt) => expandCollapseAllRows(dt),
      name: 'expandCollapse',
      text: `${I18n.t('expand_all')}`
    };

    let rowId = 0;
    this.$table = $(this.el);
    this.dataTable = this.$table.DataTable({
      autoWidth: this.props.autoWidth,
      buttons: this.props.buttons,
      dom: this._domOptions(),
      drawCallback: this.onTableDraw,
      data: this.state.resources,
      columns: this._setColumns(),
      createdRow: this.props.createdRow,
      order: this.props.defaultOrder,
      columnDefs: this._setColumnDefs(),
      searching: this.props.searching,
      pageLength: this.props.pageLength,
      showPagingOptions: this.props.showPagingOptions,
      paging: this.props.paging,
      info: this.props.info,
      initComplete: this.props.initComplete,
      deferRender: this.props.deferRender,
      destroy: true,
      rowId: this.props.expandCallback ? () => `row-${++rowId}` : undefined,
      oLanguage: {
        sEmptyTable: this.props.noRecordsMessage,
        sLengthMenu: (
          '<select aria-label="Dropdown for number of results per page">' +
          '<option value="10">10 per page</option>' +
          '<option value="20">20 per page</option>' +
          '<option value="30">30 per page</option>' +
          '<option value="40">40 per page</option>' +
          '<option value="50">50 per page</option>' +
          '<option value="-1">All</option>' +
          '</select>'
        )
      },
      infoCallback: (settings, start, end, max) => {
        if (max < 1) return '0 results';

        if (this.props.paginatedLoading && this.props.isLoadingBackground) {
          if (typeof this.props.totalCount === 'object') {
            if (!this.props.totalCount) {
              return '';
            }

            return `Loaded ${max} of ${Object.keys(this.props.totalCount).length} results`;
          }

          return `Loaded ${max} of ${this.props.totalCount} results`;
        }

        return `${start} - ${end} of ${max} results`;
      }
    });

    if (this.props.expandCallback) {
      // Add event listener for opening and closing details
      $(`#${this.$table.attr('id')} tbody`).on('click', 'td', (e) => {
        if (e.currentTarget.className.includes('actions-column')) {
          return null;
        }

        const tr = $(e.target).closest('tr');
        const row = this.dataTable.row(tr);
        const icon = tr.find('.toggle-icon');
        const button = icon.closest('button.no_button_actions');
        const expandedIcon = 'fa-caret-right';
        const collapsedIcon = 'fa-caret-down';

        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
          icon.removeClass(collapsedIcon);
          icon.addClass(expandedIcon);
          button.attr('aria-expanded', false);
          button.attr('aria-label', 'Button: expand the table row');
        }
        else {
          // Open this row
          const returnedHtml = this.props.expandCallback(row.data());
          if (!returnedHtml) return;

          row.child(returnedHtml).show();
          if (this.props.expandCallbackAfterInit) this.props.expandCallbackAfterInit(row);
          tr.addClass('shown');
          icon.removeClass(expandedIcon);
          icon.addClass(collapsedIcon);
          button.attr('aria-expanded', true);
          button.attr('aria-label', 'Button: collapse the table row');
        }

        return null;
      });

      if (this.props.paginatedLoading) {
        this.$table.on('order.dt', () => {
          if (this.dataTable.order().length) {
            this.props.sortCallback(this.dataTable.order()[0][0], this.dataTable.order()[0][1]);
          }
          this.props.sortCallback();
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.paginatedLoading && Table.resourcesMatch(this.props.resources, nextProps.resources)) {
      const prevResourcesLength = this.props.resources.length;
      this.setState({
        resources: nextProps.resources
      }, () => this.appendResourcesAndDraw(false, prevResourcesLength, nextProps.resources));
    }
    else if (this.props.resources !== nextProps.resources) {
      this.setState({
        resources: nextProps.resources
      }, () => this.drawTable(false));
    }
    else if (this.props.pageLength !== nextProps.pageLength) {
      this.dataTable.page.len(nextProps.pageLength).draw();
    }

    if (!this.props.isLoadingBackground) {
      this.updateButtonsAfterLoad();
    }
  }

  onTableDraw() {
    if (this.props.defaultExpandRows) expandAllRows(this.dataTable);

    $(`#${this.$table.attr('id')} tbody .no_button_actions`).each((_, button) => {
      const row = $(button).closest('tr');
      $(button).attr('id', `${row.attr('id')}-expand-btn`);
      row.attr('aria-labelledby', button.id);
      $(button).attr('aria-controls', row.attr('id'));
      $(button).find('i').attr('aria-labelledby', button.id);
    });
  }

  getRows(config) {
    return this.dataTable.rows(config).data();
  }

  // update buttons once resources are finished loading
  updateButtonsAfterLoad() {
    // remove spinner
    this.dataTable.buttons('.fa-spinner').remove();
    // enable export to csv button
    this.dataTable.buttons('.buttons-csv').enable();
  }

  // only add rows that are not already added to the table
  appendResourcesAndDraw(paging, oldResourcesCount, newResources) {
    this.dataTable.rows.add(newResources.slice(oldResourcesCount));
    this.dataTable.draw(paging);
  }

  // See https://datatables.net/reference/api/draw() for draw options
  drawTable(paging) {
    const pageInfo = this.dataTable.page.info();
    const currentPage = pageInfo.page;
    const newNumPages = Math.ceil(this.state.resources.length / pageInfo.length);

    this.dataTable.clear();
    this.dataTable.rows.add(this.state.resources);

    // If current page index does not exist for the new results, draw the last
    // page of the new results
    if (currentPage < newNumPages) {
      this.dataTable.draw(paging);
    }
    else {
      this.dataTable.page(newNumPages).draw(paging);
    }

    if (!this.state.resources.length) {
      $('.dataTables_empty').text(this.props.noRecordsMessage);
    }
  }

  _setColumns() {
    return this.props.columns.map(column => ({ data: column }));
  }

  // This was added to prevent xss vulnerabilities with jquery datatables. It is sometimes desired to pass
  // script tags through the render function, but just make sure the data you are rendering is clean. If you are
  // rendering something potentially harmful, pass makeHtmlSafe: true as an option to the column definition.
  _setColumnDefs() {
    return this.props.columnDefs.map((def) => {
      let _render;
      if (def.makeHtmlSafe) {
        if (typeof def.render === 'function') {
          _render = rowData => makeHtmlSafe(def.render(rowData));
        }
        else {
          _render = rowData => makeHtmlSafe(rowData);
        }
        return { ...def, render: _render };
      }
      return def;
    });
  }

  _setColumnHeaders() {
    return this.props.columns.map((column) => {
      const titleizedColumn =
        column
          .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
          .toLowerCase()
          .split('_')
          .map(s => capitalizeWords(s))
          .join(' ');
      return <th key={column}>{titleizedColumn}</th>;
    });
  }

  _domOptions() {
    if (this.props.showPagingOptions) {
      return '<"dataTables_results"Bil>rtp';
    }

    return '<"dataTables_results"Bi>rt';
  }

  render() {
    return (
      <div>
        <table
          className="table"
          ref={(el) => { this.el = el; }}
        >
          <thead>
            <tr>
              {this._setColumnHeaders()}
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}
