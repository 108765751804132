import React, { Component } from 'react';
import Axios from 'axios';
import Row from './Row';
import SysadminTable from 'common/SysadminTable';
import Form from './Form';
import { ModalButton } from "../Utils";

const columns = [
  { text: 'Image', className: 'image' },
  { text: 'Name', className: 'name' },
  { text: 'Description', className: 'description' },
  { text: 'Actions', className: 'actions' }
];

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      safetyNotes: [],
      isLoading: false
    }
  }

  static defaultProps = {
    isLoading: false,
    safetyNotes: []
  };

  render() {
    return (
      <SysadminTable isLoading={this.state.isLoading}
                     containerClass="safety-notes-container"
                     tableId="safety-notes"
                     columns={columns}
                     buttonAbove={this.renderButtonAbove()}>
        {this.renderSafetyNotes()}
      </SysadminTable>
    );
  }

  renderButtonAbove() {
    return (
      <ModalButton buttonText="New Safety Note"
                   buttonClass={'action'}
                   modalHeader="New Safety Note"
                   modalClass="new-safety-notes"
                   newRecord={true}
                   openModal={this.openModal.bind(this)}
                   closeModal={this.closeModal.bind(this)}
                   onAdd={this.addSafetyNote.bind(this)}
                   FormComponent={Form}
                   {...this.state} />
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    this.getSafetyNotes();
  }

  addSafetyNote(safetyNote) {
    this.setState(prevState => ({
      safetyNotes: [...prevState.safetyNotes, safetyNote].sort(this.sortByTitle)
    }));
  }

  getSafetyNotes() {
    this.setState({ isLoading: true });
    Axios.get('/admin/safety_notes/get_safety_notes').then(response => {
      this.setState({ safetyNotes: response.data, isLoading: false });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  renderSafetyNotes() {
    if (this.state.safetyNotes) {
      return this.state.safetyNotes.map((safetyNote) => (
        <Row key={safetyNote.id}
             newRecord={false}
             removeRow={this.removeRow.bind(this)}
             updateRow={this.updateRow.bind(this)}
             {...this.props}
             {...safetyNote} />
      ));
    }
  }

  removeRow(id) {
    this.setState({
      safetyNotes: this.state.safetyNotes.filter(safetyNote => safetyNote.id !== id)
    });
  }

  updateRow(updatedSafetyNote) {
    this.setState({
      safetyNotes: this.state.safetyNotes.map(safetyNote =>
        updatedSafetyNote.id === safetyNote.id ? updatedSafetyNote : safetyNote
      ).sort(this.sortByTitle)
    });
  }

  sortByTitle(a, b) {
    return a.title.localeCompare(b.title);
  }
}
