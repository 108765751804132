import { IStyleSheet } from '@/components/interfaces/Theme';
import { plato_api_style_sheets_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';
import { useQuery, QueryOptions } from '@tanstack/react-query';
import axios from 'axios';

type UseStyleSheetQueryOptions = Omit<QueryOptions<IStyleSheet[]>, 'queryKey' | 'queryFn'>;

interface ApiResponse {
  data: IStyleSheet[];
}

export default function useStyleSheets(args: UseStyleSheetQueryOptions = {}) {
  return useQuery({
    ...args,
    placeholderData: ([] as IStyleSheet[]),
    queryKey: [QueryKeys.UseStyleSheets],
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_style_sheets_path())
        .then(res => res.data.data)
    ),
  });
}
