import React, { useCallback, useState } from 'react';
import I18n from 'i18n-js';
import useNodeSearcher from '@/hooks/useNodeSearcher';
import useMutationObserver from '@/hooks/useMutationObserver';

interface ScoreListenerProps {
  initialScore?: number;
  numAnswers: number;
  scoreOutOf?: number;
}

const ScoreListener = ({ initialScore = 0, numAnswers, scoreOutOf }: ScoreListenerProps) => {
  const [score, setScore] = useState(initialScore);
  const scoreNode = useNodeSearcher<HTMLSpanElement>('#submission-alert');
  const [hasAnswer, setHasAnswer] = useState(numAnswers > 0);

  const mutationCallback = useCallback<MutationCallback>((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type !== 'characterData') return;
      if (mutation.target.nodeType !== Node.TEXT_NODE) return;
      setHasAnswer(true);

      const { data } = mutation.target as Text;

      if (!data) return;

      const regexMatch = data.match(/\d\.?\d?/g);
      if (regexMatch?.length === 0) return;

      setScore(parseFloat(regexMatch[0]));
    });
  }, [scoreNode]);

  useMutationObserver({
    node: scoreNode,
    callback: mutationCallback,
    opts: { shouldObserve: !!scoreNode },
    observerOpts: { subtree: true, childList: true, characterData: true },
  });

  const formatScore = (num: number) => num.toString().replace('.0', '');
  const scoreLabel = I18n.t('score');
  const scoreValue = !hasAnswer
    ? '-'
    : `${formatScore(score)}${scoreOutOf ? `/${scoreOutOf}` : ''}`;
  return <span className="tw-text-base">{`${scoreLabel}: ${scoreValue}`}</span>;
};

export default ScoreListener;
