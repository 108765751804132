import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FileField } from 'common/Forms';
import { SubmitError } from 'common/Forms/Utils';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import styles from '../Button.module.scss';

const UploadPdfButtonWrapper = ({ programId, programIndexPdfUpload, resources, setResources }) => (
  <ApolloProvider client={apolloClient}>
    <UploadPdfButton
      programId={programId}
      programIndexPdfUpload={programIndexPdfUpload}
      resources={resources}
      setResources={setResources}
    />
  </ApolloProvider>
);

UploadPdfButtonWrapper.propTypes = {
  programId: PropTypes.number.isRequired,
  programIndexPdfUpload: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    pdfUrl: PropTypes.string.isRequired,
    resultCsvUrl: PropTypes.string,
    uploadStatus: PropTypes.string.isRequired
  }),
  resources: PropTypes.arrayOf(
    {
      fileName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      pdfUrl: PropTypes.string.isRequired,
      resultCsvUrl: PropTypes.string,
      uploadStatus: PropTypes.string.isRequired
    }
  ),
  setResources: PropTypes.func.isRequired,
};

UploadPdfButtonWrapper.defaultProps = {
  programIndexPdfUpload: null
};

const UPLOAD_PDF = gql`
  mutation UploadProgramIndexPdf($programId: ID!, $pdfFile: File!, $locale: String!) {
    uploadProgramIndexPdf(programId: $programId, pdfFile: $pdfFile, locale: $locale) {
      result {
        id,
        locale,
        pdfUrl,
        fileName,
        uploadStatus,
        resultCsvUrl
      }
    }
  }
`;

const UploadPdfButton = ({ programId, programIndexPdfUpload, resources, setResources }) => {
  const [uploadProgramIndexPdfMutation] = useMutation(UPLOAD_PDF, { context: { hasUpload: true } });
  const [pdf, setPdf] = useState(programIndexPdfUpload);
  const [errorMessage, setErrorMessage] = useState(null);
  const pdfInputRef = useRef();

  const onSubmit = (values) => {
    if (!values.pdf) {
      setErrorMessage('Select a PDF file to upload');

      return;
    }

    setErrorMessage(null);

    uploadProgramIndexPdfMutation({ variables: {
      locale: programIndexPdfUpload.locale, pdfFile: values.pdf, programId: parseInt(programId, 10)
    } })
      .then((result) => {
        const data = result.data.uploadProgramIndexPdf.result;

        if (data) {
          setPdf(data);
        }

        const updatedResources = resources.map((resource) => {
          if (resource.locale === data.locale) {
            return data;
          }

          return resource;
        });

        setResources(updatedResources);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Something went wrong. Please refresh the page and try again.');
      });
  };

  const currentPdfLabel = () => (
    <div className="mt10">
      <a
        href={pdf.pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {pdf.fileName}
      </a>
    </div>
  );

  const clearInput = (form) => {
    pdfInputRef.current.value = '';
    form.change('pdf', '');
  };

  const uploadPdfButton = () => (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.inputContainer}>
              <FileField
                name="pdf"
                accept="pdf/*"
                setRef={pdfInputRef}
                onChange={handleSubmit}
                hideLabel
              />
              {values.pdf && (
                <button
                  type="button"
                  aria-label="Clear PDF"
                  onClick={() => { clearInput(form); }}
                  className={styles.clearButton}
                >
                  x
                </button>
              )}
            </div>

            {errorMessage && <div className="mb10"><SubmitError error={errorMessage} /></div>}
          </form>
        )}
      />
    </Fragment>
  );

  if (pdf && pdf.fileName && pdf.uploadStatus) {
    if (pdf.uploadStatus === 'Failed') {
      return (
        <Fragment>
          {currentPdfLabel()}
          {' '}
          {'Failed to generate CSV.'}
          {uploadPdfButton()}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {currentPdfLabel()}
      </Fragment>
    );
  }

  return uploadPdfButton();
};

UploadPdfButton.propTypes = {
  programId: PropTypes.number.isRequired,
  programIndexPdfUpload: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    pdfUrl: PropTypes.string.isRequired,
    resultCsvUrl: PropTypes.string,
    uploadStatus: PropTypes.string.isRequired
  }),
  resources: PropTypes.arrayOf(
    {
      fileName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      pdfUrl: PropTypes.string.isRequired,
      resultCsvUrl: PropTypes.string,
      uploadStatus: PropTypes.string.isRequired
    }
  ),
  setResources: PropTypes.func.isRequired,
};

UploadPdfButton.defaultProps = {
  programIndexPdfUpload: null
};

export default UploadPdfButtonWrapper;
