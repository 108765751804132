import React, { useEffect, useMemo } from 'react';
import Cookie from 'react-cookies';
import ReactSelect5 from '@/components/common/ReactSelect5';

interface Option {
  label: string;
  value: string;
}

interface GradingSelectProps {
  defaultValue?: Option;
  id: string;
  label: string;
  onChange: (opt: Option) => any;
  options: Option[];
  useCookie?: boolean;
  selectClass?: string;
}

const GradingSelect = ({
  defaultValue: defaultValueProp,
  id,
  label,
  onChange,
  options,
  useCookie = false,
  selectClass = '',
}: GradingSelectProps) => {
  const defaultValue = useMemo(() => {
    if (defaultValueProp) return defaultValueProp;

    if (useCookie) {
      const index = parseInt(Cookie.load(id) || -1, 10);

      if ([0, -1].includes(index)) {
        return options[0];
      }

      onChange(options[index]);
      return options[index];
    }

    return options[0];
  }, []);

  return (
    <label aria-label={label} htmlFor={id}>
      <span className="tw-block tw-mb-1 tw-ml-1">{label}</span>
      <ReactSelect5
        className={selectClass}
        id={id}
        defaultValue={defaultValue}
        options={options}
        onChange={(opt: Option) => {
          if (useCookie) Cookie.save(id, options.findIndex(o => o.value === opt.value));
          onChange(opt);
        }}
      />
    </label>
  );
}

export default GradingSelect;
