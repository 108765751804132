import React from 'react';
import I18n from 'i18n-js';
import backIcon from 'images/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import * as Routes from 'modules/routes';
import Cookie from 'react-cookies';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from '../Navigator.module.scss';
import Tooltip from '../../../../../common/Tooltip';

const BackToButton = ({ parentId, parentType, programId, slideShowId, position, isTemporary }) => {
  const slideRef = useSlideShowContext(state => state.slideRef);
  const userType = useSlideShowContext(state => state.userType);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const referrerProgramId = useSlideShowContext(state => state.referrerProgramId);
  const urlParams = new URLSearchParams(window.location.search);

  const backToText = () => {
    const previousLocation = urlParams.get('from')?.toLowerCase();

    if (isTemporary) return I18n.t('no_access_to_lesson');
    if (slideShow.activityType === 'professional_development') return I18n.t('back_to_professional_development');
    if (previousLocation === 'builder') return I18n.t('back_to_editor');
    if (previousLocation === 'unit') return I18n.t('back_to_unit');
    if (previousLocation === 'lesson') return I18n.t('back_to_lesson');
    if (urlParams.has('assignment_id') || userType === 'Student') return I18n.t('back_to_assignments');

    return I18n.t('back_to_lesson');
  };

  const getBackToPath = () => {
    const previousLocation = urlParams.get('from')?.toLowerCase();

    if (isTemporary) return '';

    if (slideShow.activityType === 'professional_development') {
      return Routes.teacher_program_professional_developments_path(referrerProgramId);
    }
    if (previousLocation === 'builder') {
      console.log('userType', userType);
      const path = userType === 'Sysadmin' ? Routes.builder_admin_slide_show_path : Routes.builder_shared_slide_show_path;

      return path(slideShowId, {
        parent_id: parentId, parent_type: parentType, program_id: programId, position,
      });
    }
    if (previousLocation === 'unit') {
      return Routes.stream_shared_program_investigations_path(programId, {
        unit_id: parentId, unit_projects: true,
      });
    }
    if (previousLocation === 'lesson') {
      return Routes.shared_program_lesson_slide_shows_path(programId, parentId);
    }
    if (urlParams.has('assignment_id') || userType === 'Student') {
      return Routes.shared_program_assignments_path(programId, { classroom_id: Cookie.load('classroomId') });
    }

    return '';
  };

  return (
    <Tooltip
      content={backToText()}
      interactive={false}
    >
      <a
        aria-label="Back To Button"
        className={`${styles.backButton} ${isTemporary && styles.disabled} tw-flex`}
        href={getBackToPath()}
        onClick={isTemporary && (e => e.preventDefault())}
      >
        <img src={backIcon} alt="Back" />
        <span className="tw-ml-2 tw-text-white tw-text-lg">{backToText()}</span>
      </a>
    </Tooltip>
  );
};

export const BackToButtonPropsTypes = PropTypes.shape({
  parentId: PropTypes.string.isRequired,
  parentType: PropTypes.string.isRequired,
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  slideShowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isTemporary: PropTypes.bool,
});

BackToButton.propTypes = BackToButtonPropsTypes.isRequired;

export default BackToButton;
