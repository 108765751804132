/**
 * ConfirmDeleteModal: renders a modal to prompt the user for confirmation to delete.
 * Similar to <ConfirmModal>, but adheres to delete modal styling.
 * props:
 *            isOpen (bool): whether the modal is open
 *         headerText (str): text to be rendered in the header of the modal
 *   confirmationText (str): text to be rendered inside the modal
 *      onCancel (function): callback function when the modal is canceled/closed
 *     onConfirm (function): callback function when the modal is confirmed
 */

import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Modal from './Modal';
import styles from './Modal.module.scss';
import Spinner from './Spinner';
import { Footer } from './index';

interface ConfirmDeleteModalProps {
  confirmButtonText?: string;
  confirmationText: string;
  headerText: string;
  isLoading: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDeleteModal = ({
  confirmationText, headerText, isLoading, isOpen, onCancel, onConfirm, confirmButtonText,
}: ConfirmDeleteModalProps) => {
  const footer = () => (
    <Footer
      primaryButtonCallback={onConfirm}
      primaryButtonClassName="btn btn--red"
      primaryButtonSubmit={false}
      primaryButtonText={confirmButtonText}
      primaryButtonDisabled={isLoading}
      secondaryButtonCallback={onCancel}
      wrapperClassName="tw-pr-4 tw-pb-4"
    />
  );

  return (
    <Modal
      className={styles.confirm}
      isOpen={isOpen}
      closeModal={onCancel}
      headerText={headerText}
      headerClass={styles.deleteHeader}
      footer={footer()}
    >
      {isLoading ? <Spinner isLoading={isLoading} /> : confirmationText}
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  confirmationText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmDeleteModal.defaultProps = {
  confirmButtonText: `${I18n.t('yes')}, ${I18n.t('delete')}`,
};

export default ConfirmDeleteModal;
