import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';

import styles from './Link.module.scss';
import Tooltip from '../../../common/Tooltip';
import I18n from 'i18n-js';

export default class Link extends Component {
  static propTypes = {
    assessmentId: PropTypes.number.isRequired,
    assignable: PropTypes.bool,
    lessonId: PropTypes.number,
    openModal: PropTypes.func.isRequired,
    withIcon: PropTypes.bool
  };

  static defaultProps = {
    assignable: true,
    lessonId: null,
    withIcon: false
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  getLinkClassName() {
    let linkClassName = 'js-prevent-default';
    if (!this.props.assignable) linkClassName += ` ${styles.disabledLink} disabled`;

    return linkClassName;
  }

  handleClick() {
    this.props.openModal(this.props.assessmentId, this.props.lessonId);
  }

  renderDisabledTooltipWithLink() {
    const content = (
      <div className="p5">
        Tests without questions can’t be assigned.
      </div>
    );

    return (
      <Tooltip
        interactive={false}
        content={renderToString(content)}
      >
        {this.renderLink()}
      </Tooltip>
    );
  }

  renderIcon() {
    return this.props.withIcon ?
      <i className="fa fa-calendar" aria-hidden="true" /> : '';
  }

  renderLink() {
    return (
      <a
        href="#"
        className={this.getLinkClassName()}
        onClick={this.props.assignable ? this.handleClick : null}
        role="button"
      >
        {this.renderIcon()}
        {I18n.t('assign')}
      </a>
    );
  }

  render() {
    if (this.props.assignable) return this.renderLink();
    return this.renderDisabledTooltipWithLink();
  }
}
