import React, { useEffect, useState } from 'react';
import { SpinnerIcon } from 'components/common/Utils';
import useSpeechstream from './useSpeechStream';

interface TTSButtonProps {
  page?: number;
  locale: Locale;
  idToRead: string;
  getUserTTSSpeed: () => Promise<string>;
  voiceForSpeed: (speed: string) => string;
}

const SpeakElementButton = ({ locale, idToRead, getUserTTSSpeed, voiceForSpeed }: TTSButtonProps) => {
  const [selectedSpeed, setSelectedSpeed] = useState(null);
  const { playing, toggleSpeech, loaded, setVoice } = useSpeechstream({ locale, idToRead });
  const label = playing ? 'Pause' : 'Play';
  const iconClass = playing ? 'fa-pause' : 'fa-volume-up';

  const updateUserPreferredSpeed = (newSpeed: string) => {
    setSelectedSpeed(newSpeed);
    setVoice(voiceForSpeed(newSpeed));
  };

  useEffect(() => {
    setVoice(voiceForSpeed(selectedSpeed));
  }, [loaded, selectedSpeed]);


  useEffect(() => {
    getUserTTSSpeed().then(speed => updateUserPreferredSpeed(speed));
  }, []);

  return (
    <button
      type="button"
      className="btn btn--outline-purple btn--sm speak-question-button"
      onClick={toggleSpeech}
      aria-label={label}
    >
      {loaded ? <i className={`fa ${iconClass}`} aria-hidden="true" /> : <SpinnerIcon />}
    </button>
  );
};

export default SpeakElementButton;
