/* istanbul ignore file */

import React, { useRef, useEffect, useState } from 'react';
import Modal, { useModalManager } from '@/components/common/Modal';
import SubmitFooter from '@/components/ui/Modal/SubmitFooter';
import Input from '@/components/ui/Form/Input';
import { useForm } from 'react-hook-form';

export type IStaffer = {
  email: string;
  firstName: string;
  lastName: string;
};

interface FeedbackFormProps {
  staffer: IStaffer;
  className?: string;
}

const ACTION_URL = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00Di0000000J5oJ';
const HIDDEN_IFRAME_NAME = 'JLA_API';

const FeedbackForm = ({
  staffer: { email, firstName, lastName },
  className,
}: FeedbackFormProps) => {
  const { register, watch, reset } = useForm();
  const { isOpen, open, close } = useModalManager();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [submitted, setSubmitted] = useState(false);
  const subjectValue = watch('subject');
  const descriptionValue = watch('description');

  useEffect(() => {
    setSubmitted(false);
  }, [isOpen]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const handleLoad = () => {
        if (isOpen) {
          setSubmitted(true);
          reset({ subject: '', description: '' });
        }
      };
      iframe.addEventListener('load', handleLoad);

      return () => {
        iframe.removeEventListener('load', handleLoad);
      };
    }
  }, [close]);

  return (
    <>
      <button
        type="button"
        className={`dropdown-item purple full-size tw-cursor-pointer ${className || ''}`}
        onClick={open}
      >
        Feedback
      </button>
      <Modal
        isOpen={isOpen}
        closeModal={close}
        headerText="Share Feedback"
      >
        {submitted && (
          <span className="tw-text-base">Thank you for sharing your feedback with us.</span>
        )}
        {!submitted && (
          <form
            className="tw-text-base"
            autoComplete="off"
            action={ACTION_URL}
            method="POST"
            target={HIDDEN_IFRAME_NAME}
          >
            <input type="hidden" name="orgid" value="00Di0000000J5oJ" />
            <input type="hidden" name="retURL" value="http://" />
            <input type="hidden" id="name" maxLength={80} name="name" value={`${firstName} ${lastName}`} />
            <input type="hidden" id="00N6S00000OOjxL" maxLength={20} name="00N6S00000OOjxL" value={firstName} />
            <input type="hidden" id="00N6S00000OOjxN" maxLength={20} name="00N6S00000OOjxN" value={lastName} />
            <input type="hidden" id="email" maxLength={80} name="email" value={email} />
            <input type="hidden" id="00N0H00000It7ue" name="00N0H00000It7ue" value="User Experience" />
            <input type="hidden" id="00Ni000000Hal9w" name="00Ni000000Hal9w" value="Tier 2" />
            <input type="hidden" id="origin" name="origin" value="In-App Feedback" />
            <Input
              id="subject"
              maxLength={80}
              label="Subject"
              name="subject"
              type="text"
              className="!tw-max-w-full"
              {...register('subject')}
            />
            <div className="tw-flex tw-gap-2 tw-flex-col">
              <label
                htmlFor="description"
                className="tw-pt-2 tw-mt-2"
              >
                What would you like to tell us?
              </label>
              <textarea
                className="tw-w-full tw-border-gray-300 tw-border tw-border-solid tw-rounded tw-p-2.5 tw-box-border tw-font-tci tw-min-h-[100px]"
                name="description"
                {...register('description')}
              />
              <SubmitFooter
                close={close}
                submitText="Submit"
                isValid={subjectValue && descriptionValue}
              />
            </div>
          </form>
        )}
      </Modal>
      <iframe
        ref={iframeRef}
        id={HIDDEN_IFRAME_NAME}
        name={HIDDEN_IFRAME_NAME}
        src="about:blank"
        className="tw-hidden"
        title={HIDDEN_IFRAME_NAME}
      />
    </>
  );
};

export default FeedbackForm;
