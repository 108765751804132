import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { renderToString } from 'react-dom/server';
import { Footer } from 'common/Modal';
import Tooltip from 'common/Tooltip';
import * as Routes from 'routes';
import { truncate } from 'TCIUtils';
import styles from './SelectClassroom.module.scss';
import canvasLogo from '../canvas_logo.svg';
import DataTable from '../../../../../common/DataTable';

const SelectClassroom = ({
  lmsClassrooms, closeModal, saveClassroom, classroomId, classroomName, classrooms
}) => {
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedSectionId, setSelectedSectionId] = useState();
  const [lmsSections, setLmsSections] = useState([]);
  const [error, setError] = useState(null);

  const renderInput = (section, checked) => {
    const alreadyConnected = classrooms.map(classroom => classroom.lms_section_id).includes(section.id.toString());

    return (
      <label key={section.id} className={styles.label}>
        {selectedCourse.workflow_state === 'available' && (
          <input
            type="radio"
            value={section.id}
            checked={checked}
            onChange={selectSection}
          />
        )}
        {section.name}
        {alreadyConnected && (
          <Tooltip
            content="A TCI class is already connected to this Canvas Course."
            shouldDisplay={alreadyConnected}
            size="medium"
            theme="white"
          >
            <i
              className="fa fa-info-circle tw-ml-2"
              aria-hidden="true"
            />
          </Tooltip>
        )}
      </label>
    );
  };

  const selectSection = (e) => {
    ReactDOM.render(
      <div id={selectedCourse.id}>
        {lmsSections.map(section => (
          <div className={styles.formInput}>
            {renderInput(section, e.target.value.toString() === section.id.toString())}
          </div>
        ))}
      </div>,
      document.getElementById(selectedCourse.id)
    );

    setSelectedSectionId(e.target.value);
  };

  const columns = [
    'expand',
    'name',
    'workflow_state',
    'start_at',
    'end_at',
  ];

  const columnDefs = () => {
    const toggleIcon = '<button class="no_button_actions" type="button"><i class="fa toggle-icon fa-caret-right" /></button>';

    return (
      [{
        ariaTitle: 'Expand/Collapse Canvas Course',
        render: () => toggleIcon,
        sortable: false,
        targets: 0,
        title: '',
        width: '2%'
      },
      {
        createdCell: (td, cellData) => { ReactDOM.render(<>{cellData === 'available' ? 'Published' : 'Unpublished'}</>, td); },
        targets: 2,
        title: 'Status',
      },
      {
        class: 'tw-align-middle',
        createdCell: (td, cellData) => { ReactDOM.render(<>{cellData ? moment(cellData).format('MMM D, YYYY') : null}</>, td); },
        targets: 3,
      },
      {
        class: 'tw-align-middle',
        createdCell: (td, cellData) => { ReactDOM.render(<>{cellData ? moment(cellData).format('MMM D, YYYY') : null}</>, td); },
        targets: 4,
      }]
    );
  };

  useEffect(() => {
    if (selectedCourse) {
      Axios
        .get(Routes.client_sections_lms_course_index_path({ classroom_id: classroomId, course_id: selectedCourse.id }))
        .then((response) => {
          setLmsSections(response.data);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.data && e.response.data) {
            setError(e.response.data.error);
          }
          else {
            setError(e);
          }
        });
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;

    ReactDOM.render(
      <div id={selectedCourse.id}>
        {lmsSections.map(section => (
          <div className={styles.formInput}>
            {renderInput(section, false)}
          </div>
        ))}
      </div>,
      document.getElementById(selectedCourse.id)
    );
  }, [lmsSections]);

  const sectionInfo = (rowData) => {
    if (!rowData) return;

    // Clear to re-trigger useEffect
    setSelectedCourse(null);
    setSelectedCourse(rowData);

    return (
      renderToString(<div id={rowData.id} className="actions-column" />)
    );
  };

  const rowAfterInit = (row) => {
    if (row) {
      const td = row.child().find('td');
      td?.addClass('no-top-border');
    }
  };

  const submit = (e) => {
    e.preventDefault();

    const classroom = {
      id: classroomId,
      lms_course_id: selectedCourse.id,
      lms_course_name: selectedCourse.name,
      lms_section_id: selectedSectionId,
      lms_section_name: lmsSections.find(section => section.id.toString() === selectedSectionId).name,
      lms_type: 'canvas'
    };

    saveClassroom(classroom);
  };

  const renderSectionTableSelect = () => (
    <form onSubmit={submit}>
      <p className="mb20">
        Select the Canvas Course to link with <b>{classroomName}</b>:
      </p>
      <DataTable
        pageLength={-1}
        columns={columns}
        columnDefs={columnDefs()}
        defaultOrder={[]}
        handleResourcesManually
        resources={lmsClassrooms}
        expandCallback={sectionInfo}
        expandCallbackAfterInit={rowAfterInit}
        showPagingOptions={false}
        info={false}
      />

      <Footer
        primaryButtonText="Connect"
        secondaryButtonCallback={closeModal}
        primaryButtonDisabled={!selectedSectionId}
        wrapperClassName={styles.modalFooter}
      />
    </form>
  );

  if (error) {
    return 'Something went wrong. Please refresh the page and try again.';
  }

  return renderSectionTableSelect();
};

SelectClassroom.propTypes = {
  classroomId: PropTypes.number.isRequired,
  classroomName: PropTypes.string.isRequired,
  classrooms: PropTypes.arrayOf(PropTypes.shape({
    lms_section_id: PropTypes.number,
  })).isRequired,
  closeModal: PropTypes.func.isRequired,
  lmsClassrooms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  saveClassroom: PropTypes.func.isRequired
};

export default SelectClassroom;
