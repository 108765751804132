import React, { useState, useEffect, useContext } from 'react';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import FinalFormSelect from '@/components/common/Forms/FinalFormSelect';
import BuilderContext from '@/components/common/Context';
import useVocabularyStore from '../../useVocabularyStore';

interface VocabDropdownProps {
  id: string;
  displayPosition: number;
  position: number;
  setUpdatedChoices: (choices: any) => void;
  selectedChoices?: any[];
  updatedChoices?: any[];
  vocabId?: number;
}

const VocabDropdown = ({
  id, displayPosition, position, setUpdatedChoices, selectedChoices, vocabId
}: VocabDropdownProps) => {
  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const setSelectedTerm = useVocabularyStore(state => state.setSelectedTerm);
  const selectedValues = useVocabularyStore(state => state.selectedValues);

  const {
    dropZones,
    draggableChoices,
    textAreas,
    dropZoneDispatch,
    draggableChoiceDispatch,
    textAreaDispatch,
  } = useContext(BuilderContext);

  const getInitialVocab = () => {
    const vocabTerm = vocabularyTerms.find(vocab => vocab.value === vocabId);
    if (vocabTerm) {
      return {
        label: vocabTerm.label,
        value: vocabTerm.value,
        vocabTerm: vocabTerm.vocabTerm,
      };
    }
    return { label: '', value: '', vocabTerm: '' };
  };

  const [selectedVocab, setSelectedVocab] = useState(getInitialVocab());

  const updatedChoices = (prevState, removing = false) => {
    if (removing) return prevState.filter(choice => choice.id !== id);

    const noVocabSelected = selectedVocab.value === '';

    // If no vocab is selected and the current dropdown doesn't have an associated vocabId, skip it in the update.
    if (noVocabSelected && !vocabId) return prevState;

    const filteredChoices = prevState.filter(choice => choice.id !== id);

    return [
      ...filteredChoices,
      {
        id,
        title: noVocabSelected ? `Draggable Choice ${id}` : selectedVocab.vocabTerm,
        type: noVocabSelected ? 'draggableChoice' : 'vocab',
        vocabId: noVocabSelected ? null : selectedVocab.value,
      },
    ];
  };

  const deleteVocabTerm = () => {
    const dropZonesToDelete = Object.keys(dropZones).filter(key => dropZones[key].vocabId === vocabId);
    const draggableChoicesToDelete = Object.keys(draggableChoices).filter(key => {
      if (vocabId) return draggableChoices[key].vocabId === vocabId;

      return draggableChoices[key].position === position;
    });
    const textAreasToDelete = Object.keys(textAreas).filter(key => textAreas[key].vocabId === vocabId);

    textAreasToDelete.forEach(key => textAreaDispatch({ payload: { id: key }, type: 'DELETE' }));
    draggableChoicesToDelete.forEach(key => draggableChoiceDispatch({ payload: { id: key }, type: 'DELETE' }));
    dropZonesToDelete.forEach(key => dropZoneDispatch({ payload: { id: key }, type: 'DELETE' }));

    setUpdatedChoices(updatedChoices(selectedChoices, true));
  };

  useEffect(() => {
    setUpdatedChoices(updatedChoices);
    setSelectedTerm(id, selectedVocab.value);
  }, [selectedVocab]);

  const dropdownOptions = () => vocabularyTerms.map(term => ({
    ...term,
    disabled: selectedValues().includes(term.value),
  }));

  const handleChange = (e) => {
    if (e && e.value) {
      setSelectedVocab(e);
    } else {
      setSelectedVocab({ label: '', value: '', vocabTerm: '' });
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-mt-3">
      <div>Answer Choice {displayPosition}</div>
      &nbsp;
      <button
        type="button"
        className={clsx(
          'fa fa-trash-o tw-text-red tw-border-0 tw-bg-transparent tw-w-[24px] tw-h-[24px] tw-cursor-pointer',
          { 'tw-invisible': id === 'a' },
        )}
        onClick={deleteVocabTerm}
      />
      <Field
        name="vocabulary_dropdown"
        component={FinalFormSelect}
        options={dropdownOptions()}
        className="tw-ml-3 tw-w-3/4"
        selectValue={selectedVocab}
        onChange={handleChange}
        filterOption={(vocabOptions, searchInput) => {
          const inputValue = searchInput.toLowerCase();
          return vocabOptions.term.toLowerCase().includes(inputValue);
        }}
      />
    </div>
  );
};

export default VocabDropdown;
