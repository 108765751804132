import React, { useEffect } from 'react';
import { TextAreaField, TextField, CheckboxField } from 'common/Forms';
import I18n from 'i18n-js';
import Section from './Section';
import sectionStyles from './Section.module.scss';
import useAssignablesStore from './WhatSection/store/useAssignablesStore';
import useFlags from '../../../hooks/useFlags';

const NotesSection = () => {
  const initialValues = useAssignablesStore(state => state.initialValues);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const selectedType = useAssignablesStore(state => state.selectedType);

  const { showRandomizeAnswerChoices } = useFlags();

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!initialValues || !selectedType) return;

    formChangeValue(initialValues.randomizeAnswers, 'randomize_answers');
  }, [loadingTypes, loadingLessons, initialValues, selectedType]);

  return (
    <Section title="Optional">
      {selectedType === 'assessment' && showRandomizeAnswerChoices && (
        <div className={sectionStyles.row}>
          <label className="bold" htmlFor="randomize_answers_field">Assignment Settings:</label>
          <CheckboxField
            name="randomize_answers"
            label="Randomize answer choices"
            labelPos="right"
            tooltipText={I18n.t('randomize_answer_choices_message')}
            rowClass={sectionStyles.checkboxField}
          />
        </div>
      )}
      <div className={sectionStyles.row}>
        <label className="bold" htmlFor="name_field">Assignment Name:</label>
        <TextField
          name="name"
          placeholder="Start typing here..."
          hideLabel
        />
      </div>
      <div className={sectionStyles.row}>
        <label className="bold" htmlFor="notes_field">Assignment Directions:</label>
        <TextAreaField
          className={sectionStyles.textArea}
          name="notes"
          placeholder="Start typing here..."
          hideLabel
        />
      </div>
    </Section>
  );
};

export default NotesSection;
