import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsDropdown from 'common/ActionsDropdown';
import UploadModal from 'common/UploadCsvModal';
import AddModal from './AddModal';

export default class Add extends Component {
  static propTypes = {
    batchUpdateCsvPath: PropTypes.string.isRequired,
    canCsvUpdate: PropTypes.bool,
    classroomsPath: PropTypes.string.isRequired,
    createPath: PropTypes.string.isRequired,
    csvTemplateUrl: PropTypes.string.isRequired,
    importCsvPath: PropTypes.string.isRequired,
    isCoordinator: PropTypes.bool.isRequired,
    processCsvNowPath: PropTypes.string,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    programCodesUrl: PropTypes.string.isRequired,
    stafferId: PropTypes.number.isRequired,
    studentCsvUploadsPath: PropTypes.string,
    updateTable: PropTypes.func.isRequired
  };

  static defaultProps = {
    canCsvUpdate: true,
    processCsvNowPath: '',
    studentCsvUploadsPath: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      addModalOpen: false,
      uploadModalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(type) {
    this.setState({ [`${type}ModalOpen`]: true });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false });
  }

  _renderUploadModal() {
    if (this.state.uploadModalOpen) {
      return (
        <UploadModal
          batchUpdateCsvPath={this.props.batchUpdateCsvPath}
          canCsvUpdate={this.props.canCsvUpdate}
          closeModal={() => this.closeModal('upload')}
          csvTemplateUrl={this.props.csvTemplateUrl}
          importCsvPath={this.props.importCsvPath}
          importType="shared_classes_student"
          modalOpen={this.state.uploadModalOpen}
          processCsvNowPath={this.props.processCsvNowPath}
          programCodes={this.props.programCodes}
          programsPath={this.props.programCodesUrl}
          stafferId={this.props.stafferId}
          studentCsvUploadsPath={this.props.studentCsvUploadsPath}
        />
      );
    }

    return null;
  }

  _renderAddButton() {
    if (this.props.isCoordinator) {
      return this._renderAddStudentButton();
    }

    return this._renderAddDropdown();
  }

  _renderAddStudentButton() {
    return (
      <button className="btn btn--green" onClick={() => this.openModal('add')}>
        Add Student
      </button>
    );
  }

  _renderAddDropdown() {
    return (
      <ActionsDropdown toggleText="Add Student" toggleClassName="btn--green">
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={() => this.openModal('add')}
        >
          <i aria-hidden="true" className="fa fa-plus" /> Add Student
        </a>

        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={() => this.openModal('upload')}
        >
          <i aria-hidden="true" className="fa fa-upload" /> Upload CSV
        </a>
      </ActionsDropdown>
    );
  }

  render() {
    return (
      <div>
        {this._renderAddButton()}

        <AddModal
          classroomsPath={this.props.classroomsPath}
          closeModal={() => this.closeModal('add')}
          createPath={this.props.createPath}
          modalIsOpen={this.state.addModalOpen}
          updateTable={this.props.updateTable}
        />

        {this._renderUploadModal()}
      </div>
    );
  }
}
