import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ handleClick }) {
  return (
    <button className="btn btn--green" onClick={handleClick}>
      Add Course Mapping
    </button>
  );
}

Button.propTypes = {
  handleClick: PropTypes.func.isRequired
};
