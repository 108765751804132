import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import styles from './SimpleFormDatePicker.module.scss';

const SimpleFormDatePicker = ({
  id, initialDate, label, name, canUsePastDates, required
}) => {
  const [date, setDate] = useState(initialDate ? new Date(initialDate) : undefined);
  const className = `string${required ? ' required' : ''}`;

  return (
    <>
      <label htmlFor={id} className={className}>
        {label}
        {required && <span className="asterisk"> *</span>}
      </label>
      <div className={styles.datePickerWrapper}>
        <ReactDatePicker
          className={className}
          dateFormat="MM/dd/yyyy"
          id={id}
          minDate={canUsePastDates ? undefined : moment().toDate()}
          name={name}
          onChange={newDate => setDate(newDate)}
          placeholderText="MM / DD /YYYY"
          selected={date}
        />
      </div>
    </>
  );
};

SimpleFormDatePicker.propTypes = {
  canUsePastDates: PropTypes.bool,
  id: PropTypes.string,
  initialDate: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

SimpleFormDatePicker.defaultProps = {
  canUsePastDates: true,
  id: 'date-picker',
  initialDate: undefined,
  required: false,
};

export default SimpleFormDatePicker;
