import React from 'react';
import apolloClient from '@/components/common/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import AddImage from '@/components/admin/ImageManager/Add';
import { useModalManager } from '@/components/common/Modal';
import { ImageModelResponseType, useExcludedFields } from '@/components/admin/QuestionImage/utils';
import { UserType } from '@/components/admin/QuestionImage/variables';
import I18n from '@/modules/i18n';

interface IGridImageModalProps {
  form: HTMLFormElement;
  questionId: string;
  root: HTMLDivElement;
  userType: UserType;
}

const GridImageModal = ({ form, questionId, root, userType }: IGridImageModalProps) => {
  const modalManager = useModalManager();
  const $targetDiv = $(root).parents('.grid_td_wrapper_div').children('.grid_td_content_div');
  const excludeFields = useExcludedFields(userType);

  const afterSubmit = (imageModel: ImageModelResponseType[] | any) => {
    const imageModelToSave = imageModel.length ? imageModel[0] : imageModel;

    const url = imageModelToSave.image.imageUrl.replace('/original/', `/${imageModelToSave.displaySize}/`);
    const originalUrl = imageModelToSave.image.imageUrl;

    $targetDiv.append(`<img src="${url}" data-zoomurl="${originalUrl}" data-snippet="[IMAGE_MODEL_${imageModelToSave.id}]" />`);
    $targetDiv.removeClass('empty_cell');
    $targetDiv.addClass('react-has-text');
    $targetDiv.find('.placeholder_text').remove();
    $targetDiv[0].dispatchEvent(new CustomEvent(`${form.id}-SYNC`));
  };

  return (
    <ApolloProvider client={apolloClient}>
      <AddImage
        afterMergeDuplicates={afterSubmit}
        afterSubmit={afterSubmit}
        buttonClass={`!tw-mb-0 propagate grid-image-button tw-border-none tw-shadow-none tw-bg-transparent tw-min-h-full 
                      hover:tw-shadow-[inset_0_0_0_1px] hover:tw-shadow-tci-purple hover:tw-bg-transparent`}
        buttonText=""
        headerText={I18n.t('add_stimulus')}
        dontMergeCallback={afterSubmit}
        excludeFields={excludeFields}
        isAdmin={userType === 'Admin' || userType === 'Sysadmin' || userType === 'ContentManager'}
        modelType="Question"
        modelId={questionId}
        modalManager={modalManager}
        mediaType="in_grid"
      />
    </ApolloProvider>
  );
};

export default GridImageModal;
