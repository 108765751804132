import PropTypes from 'prop-types';

type ModelVideo = {
  id: string;
  video: {
    id: string;
  };
};

export type Notebook = {
  id: number;
  title: string;
  sectionDescription: string;
  fastTrack: boolean;
  showWhatYouKnowSlideShow?: SlideShow;
  showWhatYouKnowSlideIndex?: number;
};

export type Assignable = {
  label: string,
  value: string,
};

export type Assessment = {
  id: string,
  isAssigned: boolean,
  name: string,
};

export type ReferenceMaterialAssignment = {
  id: string,
  href: string,
  label: string,
};

export type Challenge = {
  challengeTemplateId: number,
  id: string,
  isAssigned: boolean,
};

export type SlideShow = {
  id: string;
  use_new_builder?: boolean;
  useNewBuilder?: boolean;
};

export type Lesson = {
  assessments: Assessment[],
  referenceMaterialAssignments: ReferenceMaterialAssignment[],
  lessonGameAssignable?: Assignable,
  lessonGameAssignables?: Assignable[],
  challenge: Challenge,
  showWhatYouKnowSlideShow: SlideShow,
  showWhatYouKnowSlideIndex?: number,
  descriptionEn: string,
  descriptionEs: string,
  id: number,
};

export type SectionType = {
  assignable: Assignable,
  id: number,
  investigation: boolean,
  isAssigned: boolean,
  kind: string,
  modelVideos: ModelVideo[];
  notebooks: Notebook[];
  subtitle: string;
  title: string;
  titleWithoutPageNumbers: string;
};

export type Program = {
  id: number,
  displayInvestigations: boolean,
  displayUnitContent: boolean,
  hasVideoActivity: boolean,
  middleSchool: boolean,
  science: boolean,
  referenceTextLabel: string,
};

export type CardType = '' |
'reading' |
'more_reading' |
'lesson_game' |
'assessments' |
'biographies' |
'classroom_activity' |
'video_activity' |
'investigation' |
'notebook' |
'games' |
'marketing';

export type BorderColors = 'blue' | 'green' | 'orange' | 'very-dark-grey';

export type StafferSlideShow = {
  activityType: CardType;
  description: string;
  id: number;
  hasSlideConditions: boolean;
  is_module: boolean;
  links: {
    presentPath: string;
  }
  showOnLessonOverview?: boolean;
  stafferInitials: string;
  title: string;
  truncatedDescription: string;
  truncatedTitle: string;
  useForInvestigations: boolean;
  useForUnitProjects: boolean;
  useNewBuilder: boolean;
};

export type StafferSlideShows = {
  tci: {
    first: StafferSlideShow;
    oldSlideShows?: StafferSlideShow[];
    newSlideShows?: StafferSlideShow[];
  };
  modules: StafferSlideShow[];
  staffer: StafferSlideShow[];
  hasStreamAssignment?: boolean;
};

type AssignmentModelHash = {
  activity_type: string;
  custom_slide_show: boolean;
  description: string;
  label: string;
  slide_show_id: number;
};

export type StudentSlideShowAssignments = {
  assignment: {
    assignment_models_hash: {
      SlideShow?: AssignmentModelHash[];
      SlideGroup?: AssignmentModelHash[];
    };
    direct_link: string;
    id: number;
    teacher_initials: string;
    within_assignment_date_availability: boolean;
  }
};

export type StudentSlideShows = {
  assignments: StudentSlideShowAssignments[];
  hasStreamAssignment: boolean;
  modules: any[];
  staffer: any[];
  tci: any[];
};

export const propTypes = {
  hasAssessments: PropTypes.bool.isRequired,
  program: PropTypes.shape({
    displayInvestigations: PropTypes.bool,
    displayUnitContent: PropTypes.bool,
    id: PropTypes.number.isRequired,
    middleSchool: PropTypes.bool.isRequired,
    referenceTextLabel: PropTypes.string.isRequired,
    science: PropTypes.bool.isRequired,
  }).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    assignable: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    investigation: PropTypes.bool.isRequired,
    isAssigned: PropTypes.bool.isRequired,
    kind: PropTypes.string.isRequired,
    lesson: PropTypes.shape({
      assessments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        isAssigned: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
      })),
      challenge: PropTypes.shape({
        id: PropTypes.number.isRequired,
        challengeTemplateId: PropTypes.number.isRequired,
        isAssigned: PropTypes.bool.isRequired,
      }),
      id: PropTypes.number.isRequired,
      lessonGameAssignables: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      descriptionEn: PropTypes.string.isRequired,
      descriptionEs: PropTypes.string.isRequired,
    }),
    modelVideos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      video: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    })),
    notebooks: PropTypes.arrayOf(PropTypes.shape({
      fastTrack: PropTypes.bool,
      id: PropTypes.number.isRequired,
      sectionDescription: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })),
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleWithoutPageNumbers: PropTypes.string.isRequired,
  })).isRequired,
  slideShows: PropTypes.shape({
    assignments: PropTypes.array,
    hasStreamAssignment: PropTypes.bool,
    modules: PropTypes.array,
    staffer: PropTypes.array,
    tci: PropTypes.array,
  }).isRequired,
};
