import React from 'react';
import { twMerge } from '@/utils';

interface BaseProps {
  alt: string;
  className?: string;
  src: string;
}

type LinkProps =
  | { to?: undefined, toText?: never }
  | { to: string, toText: string };

type CardImageProps = BaseProps & LinkProps;

const CardImage = ({ alt, className = '', src, to, toText }: CardImageProps) => {
  const base = <img className={twMerge('tw-min-w-full tw-h-[150px] tw-object-cover', className)} src={src} alt={alt} />;

  if (to) return <a href={to}>{base}<span className="sr-only">{toText}</span></a>;

  return base;
};

export default CardImage;
