import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Footer from 'common/Wizard/Footer';
import { Form } from 'react-final-form';
import { samlSettingPropTypes } from '../../../../Proptypes';
import { copyTextButton } from '../../../../Utils';
import styles from '../ConfigurationStep.module.scss';

export default function ConfigurationStepB({
  rootUrl, samlSetting, page, previous, next, ssoModelPath,
  ssoModel, updateSSOModel
}) {
  function submitRead() {
    const path = ssoModelPath.replace(':id', ssoModel.id);
    Axios.put(path, { sso_model: { instructions_seen: true } })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          updateSSOModel(response.data.data);
          next();
        }
      })
      .catch(error => console.log(error));
  }

  function samlURL(method) {
    const url = `${rootUrl}saml/${method}/${samlSetting.domain}`;
    const styleClass = method === 'sso' ? styles.ssoLabel : styles.acsLabel;
    return (
      <p className="mb15">
        <span className={`bold ${styleClass}`}>{method.toUpperCase()} URL: </span>
        <input
          className={`${styles.textInput}`}
          type="text"
          value={url}
          readOnly
        />
        {copyTextButton(url)}
      </p>
    );
  }

  return (
    <div>
      <p className="mb20">
        Use the following information to set up TCI as a SAML app in your IDP platform.
        See
        {' '}
        <a
          href="https://www.teachtci.com/set-up-saml"
          target="_blank"
          rel="noreferrer noopener"
        >
          SAML Instructions
        </a>
        {' '}
        for more information.
      </p>

      <p className="mb15">
        <span className={`bold ${styles.entityLabel}`}>Entity URL: </span>
        <input
          className={`${styles.textInput}`}
          type="text"
          value={rootUrl}
          readOnly
        />
        {copyTextButton(rootUrl)}
      </p>

      {samlURL('acs')}
      {samlURL('sso')}

      <Form
        onSubmit={submitRead}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Footer
              page={page}
              previous={previous}
              isFinalButton
            />
          </form>
        )}
      </Form>
    </div>
  );
}

ConfigurationStepB.propTypes = {
  next: PropTypes.func,
  samlSetting: PropTypes.shape({
    id: PropTypes.number,
    domain: PropTypes.string
  }),
  page: PropTypes.number,
  previous: PropTypes.func,
  rootUrl: PropTypes.string.isRequired,
  ssoModelPath: PropTypes.string.isRequired,
  ssoModel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    model: samlSettingPropTypes
  }).isRequired,
  updateSSOModel: PropTypes.func.isRequired
};

ConfigurationStepB.defaultProps = {
  next: () => {},
  samlSetting: {
    id: null,
    domain: ''
  },
  page: 1,
  previous: () => {}
};
