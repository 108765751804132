import axios from 'axios';
import { plato_api_deck_path } from '@/modules/routes';
import { QueryKeys } from '@/components/common/VocabularyCards/variables';
import { useQuery } from '@tanstack/react-query';
import { IDeck } from '@/components/common/VocabularyCards/interfaces';

interface IDeckApiResponse {
  deck: IDeck;
}

const useDeck = (deckId: number) => (
  useQuery([QueryKeys.DECK, deckId], () => (
    axios
      .get<IDeckApiResponse>(plato_api_deck_path(deckId, { extra_attributes: ['s3_url'] }))
      .then(res => res.data.deck)
  ), { staleTime: Infinity, placeholderData: ({} as IDeck) })
);

export default useDeck;
