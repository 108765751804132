import React from 'react';
import { useModalManager } from '@/components/common/Modal';
import { Button } from '@/components/ui/Buttons';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import { AddImageModal } from './PanoramicImage/AddImage';
import usePanoramicImage from './usePanoramicImage';
import usePanoramicImageStore from './PanoramicImage/usePanoramicImageStore';

const EditImageButton = ({ slideObjectId }) => {
  const { data, refetch } = usePanoramicImage(slideObjectId);
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const setPanoramicImage = usePanoramicImageStore(state => state.setPanoramicImage);

  const modalManager: ModalManager = useModalManager();

  const handleSubmit = async () => {
    const { data } = await refetch();

    setPanoramicImage({ ...data, hotspots: panoramicImage.hotspots });
    modalManager.close();
  };

  return (
    <>
      <AddImageModal
        currentValues={data || ({} as IPanoramicImage)}
        modalManager={modalManager}
        onSubmit={handleSubmit}
        slideObjectId={slideObjectId}
      />
      <Button onClick={modalManager.open}>Edit Background Image</Button>
    </>
  );
};

export default EditImageButton;
