import React from 'react';
import ButtonSnippet from '@/components/common/HtmlElements/ButtonSnippet';
import IReadingButton from '@/components/interfaces/graphql/HtmlElement/ReadingButton';

interface ReadingButtonSnippetProps {
  readingButton: IReadingButton;
}

const Snippet = ({
  readingButton: { data: { backgroundColor = 'green', buttonTextEn, buttonTextEs }, id, type },
}: ReadingButtonSnippetProps) => (
  <ButtonSnippet
    backgroundColor={backgroundColor}
    buttonTextEn={buttonTextEn}
    buttonTextEs={buttonTextEs}
    id={id}
    type={type}
  />
);


export default Snippet;
