import React from 'react';
import Axios from 'axios';
import { renderToString } from 'react-dom/server';
import { SubmitHandler, useController, useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';
import { buildMultiPartFormData } from '@/modules/Utils';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import { ImagePreview } from '@/components/common/Forms';
import { EditableDiv, Input } from '@/components/ui/Form';
import DescriptionConfidenceField from '@/components/admin/ImageManager/DescriptionConfidenceField';
import useDescribeImage from '@/components/admin/ImageManager/hooks/useDescribeImage';
import { IImage, ImageFormData } from '../interfaces';
import { FIELD_CONTAINER_CLASSES, LABEL_CLASSES } from '../constants';
import AltTextFieldTooltip from './AltTextFieldTooltip';
import ImageFileField from './ImageFileField';

export interface EditImageModalProps {
  close: () => void;
  image: IImage;
  isOpen: boolean;
  updateRow: (IImage) => void;
}

const EditImageModal = ({ close, image, isOpen, updateRow }: EditImageModalProps) => {
  const {
    formState: {
      isValid,
      isDirty,
    },
    getValues,
    handleSubmit,
    register,
    control,
    setValue,
  } = useFormContext<ImageFormData>();

  const controller = useController({ control, name: 'description_confidence' });

  const { isLoading, mutate } = useMutation({
    mutationFn: (formData: ImageFormData) => (
      Axios.put(Routes.plato_api_image_path(image.id), buildMultiPartFormData(formData, ['description_confidence']), {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ),
    onSuccess: (res) => {
      updateRow(res.data.data);
      close();
    },
  });

  const onSubmit: SubmitHandler<ImageFormData> = async (formData: ImageFormData) => mutate(formData);

  const { describeImage, loading } = useDescribeImage();

  const formatDescriptionConfidence = () => {
    const value = getValues('description_confidence');

    if (!value) return 'N/A';

    return `${(value * 100).toFixed(1)}%`;
  };

  const fetchDescription = async (params) => {
    const response = await describeImage(params);
    // @ts-ignore
    const { description, confidence } = response.data;

    setValue('description_en', description, { shouldDirty: true });
    setValue('description_confidence', confidence, { shouldDirty: true });
  };

  return (
    <SubmitModal
      isOpen={isOpen}
      close={close}
      headerText="Edit Image"
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      submittingText="Saving..."
      isValid={isValid && isDirty}
      nearlyFullScreen
    >
      <div className="tw-grid tw-grid-cols-2 tw-gap-5">
        <div className={FIELD_CONTAINER_CLASSES}>
          <label htmlFor="current_image" className={LABEL_CLASSES}>
            Current Image (English)
          </label>

          <div id="current_image" className="tw-grow-1">
            <div className="tw-flex tw-gap-2 tw-items-center">
              <ImagePreview src={image.thumb_url} />
              {image.image_en_file_name || 'None'}
            </div>
          </div>
        </div>

        <div className={FIELD_CONTAINER_CLASSES}>
          <label htmlFor="current_image" className={LABEL_CLASSES}>
            Current Image (Spanish)
          </label>

          <div id="current_image" className="tw-grow-1">
            <div className="tw-flex tw-gap-2 tw-items-center">
              <ImagePreview src={image.es_thumb_url} />
              {image.image_es_file_name || 'None'}
            </div>
          </div>
        </div>

        <ImageFileField
          name="image_en"
          label="Replace Image (English)"
          onChange={async (file) => {
            const params = new FormData();
            params.append('image', file);

            await fetchDescription(params);
          }}
          loading={loading}
        />

        <ImageFileField
          name="image_es"
          label="Replace Image (Spanish)"
        />

        <Input
          label="English Image Only"
          name="english_image_only"
          type="checkbox"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: 'tw-font-bold' }}
          {...register('english_image_only')}
        />

        <div />

        <Input
          name="title_en"
          label="Title (English)"
          placeholder="Enter a title of the image in English..."
          className="tw-min-w-[315px] tw-rounded-md"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: LABEL_CLASSES }}
          variant="inline"
          {...register('title_en')}
        />

        <Input
          name="title_es"
          label="Title (Spanish)"
          placeholder="Enter a title of the image in Spanish..."
          className="tw-min-w-[315px] tw-rounded-md"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: LABEL_CLASSES }}
          variant="inline"
          {...register('title_es')}
        />

        <Input
          name="credits"
          label="Credits"
          placeholder="Enter image credits..."
          className="tw-min-w-[315px] tw-rounded-md"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: LABEL_CLASSES }}
          variant="inline"
          {...register('credits')}
        />

        <div />

        <Input
          label="Has Text"
          name="has_text"
          type="checkbox"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: 'tw-font-bold' }}
          {...register('has_text')}
        />

        <div />

        <div className="tw-flex tw-flex-col tw-gap-1">
          <EditableDiv
            name="description_en"
            label="Alt Text (English)"
            placeholder="Enter a description of the image in English..."
            tooltipText={renderToString(<AltTextFieldTooltip />)}
            className="tw-rounded-md !tw-max-w-none !tw-w-5/6"
            containerClasses={FIELD_CONTAINER_CLASSES}
            LabelProps={{ className: LABEL_CLASSES }}
            control={control}
            onChange={() => setValue('description_confidence', null, { shouldDirty: true })}
          />

          <DescriptionConfidenceField
            disabled={!!getValues('description_confidence') || loading}
            handleClick={async () => {
              let params;
              const imageFile = (getValues('image_en') || [])[0];
              if (imageFile) {
                params = new FormData();
                params.append('image', imageFile);
              } else {
                params = { image_id: image.id };
              }

              await fetchDescription(params);
            }}
            field={(
              <div ref={controller.field.ref}>
                <span className="tw-mx-2">
                  <i>Confidence: {formatDescriptionConfidence()}</i>
                </span>
              </div>
            )}
          />
        </div>

        <EditableDiv
          name="description_es"
          label="Alt Text (Spanish)"
          placeholder="Enter a description of the image in Spanish..."
          className="tw-rounded-md !tw-max-w-none !tw-w-5/6"
          containerClasses={FIELD_CONTAINER_CLASSES}
          LabelProps={{ className: LABEL_CLASSES }}
          control={control}
        />
      </div>
    </SubmitModal>
  );
};

export default EditImageModal;
