import FE from 'froala-editor';
import FroalaConfig from 'froala/FroalaConfig';

const iconTemplate = '<i class="fa fa-[NAME]" style="color:#000000;font-size:17px" aria-hidden="true"></i>';

export const CUSTOM_VIDEO_COMMAND_NAME = 'customAddVideo';
export const CUSTOM_IMAGE_COMMAND_NAME = 'customAddImage';

const customVideoCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addVideoIcon', { NAME: 'video-camera', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_VIDEO_COMMAND_NAME, {
    focus: false,
    icon: 'addVideoIcon',
    refreshAfterCallback: true,
    title: 'Add Video',
    undo: true,
  });
};

// creates the custom image button
const customImageCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addImageIcon', { NAME: 'image', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_IMAGE_COMMAND_NAME, {
    focus: false,
    icon: 'addImageIcon',
    refreshAfterCallback: true,
    title: 'Add Image',
    undo: true,
  });
};

// initializes the custom Froala toolbar commands
export const registerFroalaCommands = () => {
  customImageCommand();
  customVideoCommand();
};

const TextEditorEvents = ({ addVideoModalManager, addImageModalManager, setEditorInstance }) => ({
  // eslint-disable-next-line func-names
  'commands.after': function(commandName) {
    switch (commandName) {
      case CUSTOM_VIDEO_COMMAND_NAME:
        this.selection.save();
        addVideoModalManager.open();
        break;
      case CUSTOM_IMAGE_COMMAND_NAME:
        this.selection.save();
        addImageModalManager.open();
        break;
      default:
        break;
    }
  },
  initialized: function() {
    setEditorInstance(this);
  },
});

const TextEditorConfig = ({ addVideoModalManager, addImageModalManager, setEditorInstance, baseUrl }) => FroalaConfig({
  allowFileUploads: true,
  allowHtml: true,
  baseUrl,
  customConfig: {
    toolbarButtons: [
      // eslint-disable-next-line max-len
      ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize'],
      ['textColor', 'backgroundColor', 'emoticons', 'inlineStyle'],
      ['paragraphStyle', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'],
      ['quote', 'insertHR', 'insertLink', CUSTOM_IMAGE_COMMAND_NAME, CUSTOM_VIDEO_COMMAND_NAME, 'insertFile', 'insertTable',
        'undo', 'redo', 'clearFormatting', 'selectAll', 'html'],
    ],
  },
  events: TextEditorEvents({ addVideoModalManager, setEditorInstance, addImageModalManager }),
  livePreview: true,
});

export default TextEditorConfig;
