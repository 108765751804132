import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { LabelField } from 'components/common/Forms';
import TagSelect from 'components/admin/TagsForm/TagSelect';
import styles from './index.module.scss';

const queryClient = new QueryClient();

const VideoTags = () => (
  <QueryClientProvider client={queryClient}>
    <LabelField
      id="tag_ids"
      label={(
        <>
          Category<span className="red">*</span>
        </>
      )}
      content={(
        <div className={styles.input}>
          <TagSelect
            scope="video_category"
            name="tag_ids"
            placeholder="Select…"
          />
        </div>
      )}
    />
  </QueryClientProvider>
);

export default VideoTags;
