import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Axios from 'axios';
import { renderErrors, SubmitError } from 'common/Forms/Utils';
import { validEmail } from 'common/Forms/Validators';
import Footer from 'common/Wizard/Footer';

import styles from '../Form.module.scss';

import { CONTACT_STEP_NON_CSV } from '../../steps';

export default class ContactStep extends Component {
  static propTypes = {
    autoRosterDistrictPath: PropTypes.string.isRequired,
    autoRosterDistrict: PropTypes.shape({
      contact_email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    updateAutoRosterDistrict: PropTypes.func.isRequired,
    previous: PropTypes.func,
    next: PropTypes.func
  };

  static defaultProps = {
    autoRosterDistrict: null,
    previous: () => {},
    next: () => {}
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    return (
      Axios
        .put(this.props.autoRosterDistrictPath, {
          auto_roster_district: {
            ...values,
            enabled: true,
            setup_complete: true
          }
        })
        .then((response) => {
          if (response.data.errors) {
            return { [FORM_ERROR]: renderErrors(response.data.errors) };
          }

          this.props.updateAutoRosterDistrict(response.data.data);
          this.props.next();
          return null;
        })
        .catch(error => (
          { [FORM_ERROR]: renderErrors(error.response.data.errors) }
        ))
    );
  }

  _getContactInitialValues() {
    const { contact_email, first_name, last_name } = this.props.autoRosterDistrict;
    return { contact_email, first_name, last_name };
  }

  render() {
    return (
      <div>
        <p>
          Provide a contact for rostering-related communication. This person will be the main point of contact for all rostering events.
        </p>

        <Form
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={this._getContactInitialValues()}
        >
          {({ handleSubmit, submitError }) => (
            <form onSubmit={handleSubmit} className="mt25">
              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="first_name" className={styles.label}>
                    First Name
                  </label>
                  <Field
                    id="first_name"
                    name="first_name"
                    component="input"
                    placeholder="Enter First Name"
                    className={styles.textInput}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="last_name" className={styles.label}>
                    Last Name
                  </label>
                  <Field
                    id="last_name"
                    name="last_name"
                    component="input"
                    placeholder="Enter Last Name"
                    className={styles.textInput}
                  />
                </div>
              </div>

              <Field
                name="contact_email"
                validate={validEmail}
              >
                {({ input, meta }) => (
                  <div className={styles.row}>
                    <div className={styles.fieldContainer}>
                      <label htmlFor="contact_email" className={styles.label}>
                        Email
                      </label>

                      <input
                        id="contact_email"
                        className={styles.textInput}
                        type="email"
                        placeholder="Enter Email"
                        {...input}
                      />
                    </div>

                    {
                      meta.error && meta.touched &&
                      <div className={styles.fieldError}>{meta.error}</div>
                    }
                  </div>
                )}
              </Field>

              <SubmitError error={submitError} />

              <Footer page={CONTACT_STEP_NON_CSV} previous={this.props.previous} />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
