import React from 'react';
import PropTypes from 'prop-types';
import { decode } from 'html-entities';
import styles from './Utils.module.scss';

/**
 * Converts the HTML entity name for the copyright symbol to the symbol itself
 * and vice versa.
 * @param str
 * @param htmlToChar boolean If true, converts copyright symbol's HTML entity to
 *                  the character. If false, converts the character to the HTML
 *                  entity.
 * @returns {string}
 */
export function convertCopyright(str, htmlToChar = true) {
  if (typeof str !== 'string') throw new Error('str must be of type string');

  if (htmlToChar) return str.replace('&copy;', '©');
  return str.replace('©', '&copy;');
}

/**
 * Formats the value as an object for react-select option.
 * @param option
 * @returns {{label: *, value: *}}
 */
export function formatSelectOption(option) {
  if (option.label && option.value) {
    return ({
      label: decode(option.label),
      value: option.value
    });
  }

  return ({
    label: decode(option.toString()),
    value: option
  });
}

export function formatPrograms(programs) {
  return programs.map(program => ({
    label: program.full_title_with_edition,
    value: program.id
  }));
}

/**
 * Convert question index into question letter (e.g. 0 to 'a', 1 to 'b').
 * @param index
 * @returns {string}
 */
export const getQuestionLetter = index => String.fromCharCode(97 + index);

/**
 * Converts array of errors to a string, with the errors numbered in a list.
 * @param errors
 * @returns {string}
 */
export const renderErrors = (errors) => {
  let num = 1;
  const list = errors.map(error => `${num++}) ${error}`);
  return `Errors: ${list.join(' ')}`;
};

/**
 * Returns red asterisk to denote required fields.
 * @returns {*}
 */
export const RequiredAsterisk = () => (
  <span className={styles.red}>*</span>
);

/**
 * Display FORM_ERROR in red from React Final Form.
 * @param error
 * @returns {*}
 */
export const SubmitError = ({ error }) => {
  if (error) {
    return <div className={styles.red}>{error}</div>;
  }
  return null;
};

SubmitError.propTypes = {
  error: PropTypes.string
};

SubmitError.defaultProps = {
  error: null
};
