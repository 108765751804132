import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import PrintButton from '../PrintButton';
import printIcon from '../PrintButton/print.png';

const PrintPdf = ({ ...props }) => (
  <span className={styles.icon}>
    <PrintButton
      pdfPath={props.pdfPath}
      icon={printIcon}
      wrapperClass="shortcutPrintButton"
      tooltipText={props.tooltipText}
      placement="bottom"
    />
  </span>
);

PrintPdf.propTypes = {
  pdfPath: PropTypes.string,
  tooltipText: PropTypes.string
};

PrintPdf.defaultProps = {
  pdfPath: undefined,
  tooltipText: undefined
};

export default PrintPdf;
