import React, { Component } from 'react';

export default class FormCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <div className="form-row">
        <label>
          <strong className="w20">{this.props.checkLabel}</strong>
        </label>
        <input type="checkbox"
               defaultChecked={this.props.checkboxValue}
               onChange={this.toggleCheckboxValue.bind(this)}
               ref="selected" />
      </div>
    );
  }

  toggleCheckboxValue() {
    this.setState({
      checkboxValue: this.refs.selected.checked
    });
    this.props.onChange(!this.state.checkboxValue, this.props.stateName);
  }
}
