import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useModalManager, SubmitModal } from 'common/Modal';
import { FileField, TextField } from 'common/Forms';
import * as Routes from 'routes';
import { required } from 'common/Forms/Validators';
import NeedsAudioDescriptionsField from './FormCheckboxFields/NeedsAudioDescriptionsField';
import VideoTags from './VideoTags.tsx';
import styles from './index.module.scss';
import StartDurationInputs from './StartDurationInputs';
import { getStartDurationPairsFromFile } from './audioAnalyze';
import NeedsCaptionField from './FormCheckboxFields/NeedsCaptionField';
import EnglishVideoOnly from './FormCheckboxFields/EnglishVideoOnly';
import BulkCaptionsRequestModal from './BulkCaptionsRequestModal.tsx';
import ActionsDropdown from '../../common/ActionsDropdown';

const AddVideo = ({ afterSubmit }) => {
  const modalManager = useModalManager();
  const bulkCaptionsModalManager = useModalManager();

  const handleBulkRequestCaptions = () => {
    bulkCaptionsModalManager.open();
  };

  return (
    <Fragment>
      <div className="dsp-flex">
        <button
          className="btn btn--green tw-rounded-tr-none tw-rounded-br-none"
          type="button"
          onClick={modalManager.open}
          aria-label="Add Video"
        >
          Add Video
        </button>
        <ActionsDropdown
          toggleClassName="btn--green tw-rounded-tl-none tw-rounded-bl-none tw-border-l-0 tw-px-2"
          toggleText=""
        >
          <button
            type="button"
            className="dropdown-item"
            onClick={handleBulkRequestCaptions}
          >
            Bulk Request Captions
          </button>
        </ActionsDropdown>
      </div>

      <SubmitModal
        headerText="Add Video"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
        submitPath={Routes.plato_api_videos_path()}
        method="post"
        initialValues={{
          needs_captions: false,
          tag_ids: [],
        }}
        useRecursiveAppend
        className={styles.modalSize}
        bodyClassName={styles.bodyModalSize}
      >
        {({ form }) => (
          <>
            <VideoTags />
            <hr />
            <h2 className="tw-text-lg"><strong>English</strong></h2>
            <TextField
              className={styles.input}
              name="title_en"
              placeholder="Enter a video title…"
              label="Title"
              validate={required}
              required
            />
            <FileField name="video" label="Upload Video File" accept=".mp4" required validate={required} />
            <NeedsCaptionField
              needsCaptions
              type="add"
              hasVideoAttachment={form.getState().values.video}
              hasCaptionAttachment={form.getState().values.caption_en}
              form={form}
              locale="en"
            />
            <EnglishVideoOnly />
            <FileField name="caption_en" label="Upload Caption File" accept=".vtt" />
            <NeedsAudioDescriptionsField needsAudioDescriptions />
            {form.getState().values.video && (
              <div>
                <FileField
                  accept=".mp3"
                  name="audios_attributes[0][track_en]"
                  onChange={async (file) => {
                    const videoUrl = URL.createObjectURL(form.getState().values.video);

                    const startDurationPairs = await getStartDurationPairsFromFile(file, videoUrl);

                    form.change('audios_attributes[0][ad_en_data]', startDurationPairs);
                  }}
                  label="Upload Audio Description File"
                />
                <StartDurationInputs key={form.getState().values} form={form} name="ad_en_data" />
              </div>
            )}
            <hr className={styles.spanishDivingLine} />
            <h2 className={styles.spanishSectionHeader}><strong>Spanish</strong></h2>
            <TextField
              className={styles.input}
              name="title_es"
              placeholder="Enter a video title…"
              label="Title"
              tooltipIcon="info"
              tooltipText="A Spanish translation will be automatically entered if there is none provided."
            />
            <FileField name="video_es" label="Upload Video File" accept=".mp4" />
            <NeedsCaptionField
              type="add"
              hasVideoAttachment={form.getState().values.video_es}
              hasCaptionAttachment={form.getState().values.caption_es}
              form={form}
              locale="es"
            />
            <FileField name="caption_es" label="Upload Caption File" accept=".vtt" />
            {form.getState().values.video_es && (
              <div className="tw-hidden">
                <FileField
                  accept=".mp3"
                  name="audios_attributes[0][track_es]"
                  onChange={async (file) => {
                    const videoUrl = URL.createObjectURL(form.getState().values.video_es);

                    const startDurationPairs = await getStartDurationPairsFromFile(file, videoUrl);

                    form.change('audios_attributes[0][ad_es_data]', startDurationPairs);
                  }}
                  label="Upload Audio Description File"
                />
                <StartDurationInputs key={form.getState().values} form={form} name="ad_es_data" />
              </div>
            )}
          </>
        )}
      </SubmitModal>
      <BulkCaptionsRequestModal
        isOpen={bulkCaptionsModalManager.isOpen}
        close={bulkCaptionsModalManager.close}
      />
    </Fragment>
  );
};

AddVideo.propTypes = {
  afterSubmit: PropTypes.func.isRequired
};

export default AddVideo;
