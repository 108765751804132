import React from 'react';
import { IIconProps } from './utils';

export const VideoActivity = ({ fill = 'currentColor' }: IIconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 12C0.75 8.86925 3.2057 6.25 6.33333 6.25H12.1111C12.8015 6.25 13.3611 6.80964 13.3611 7.5C13.3611 8.19036 12.8015 8.75 12.1111 8.75H6.33333C4.6745 8.75 3.25 10.1602 3.25 12V18C3.25 19.8398 4.6745 21.25 6.33333 21.25H15C16.6588 21.25 18.0833 19.8398 18.0833 18V12C18.0833 11.0209 17.6746 10.1532 17.0379 9.56143C16.5322 9.09144 16.5033 8.30051 16.9733 7.79485C17.4433 7.28918 18.2342 7.26026 18.7399 7.73025C19.8762 8.78641 20.5833 10.3136 20.5833 12V12.2652L24.8129 8.60502C26.6356 7.02763 29.25 8.45675 29.25 10.7026V19.2974C29.25 21.5433 26.6356 22.9724 24.8129 21.395L20.5833 17.7348V18C20.5833 21.1308 18.1276 23.75 15 23.75H6.33333C3.2057 23.75 0.75 21.1308 0.75 18V12ZM21.2435 15L26.4488 19.5046C26.493 19.5428 26.5215 19.5481 26.5356 19.5496C26.5556 19.5519 26.5859 19.549 26.6215 19.5317C26.6571 19.5144 26.6886 19.4869 26.7105 19.4527C26.7299 19.4226 26.75 19.3752 26.75 19.2974V10.7026C26.75 10.6248 26.7299 10.5774 26.7105 10.5473C26.6886 10.5131 26.6571 10.4856 26.6215 10.4683C26.5859 10.451 26.5556 10.4481 26.5356 10.4504C26.5215 10.4519 26.493 10.4572 26.4488 10.4954L21.2435 15Z"
      fill={fill}
    />
    <path
      d="M13.9366 14.3247C14.4314 14.6389 14.4314 15.3611 13.9366 15.6753L8.72887 18.9827C8.19625 19.3209 7.49998 18.9383 7.49998 18.3074L7.49998 11.6926C7.49998 11.0617 8.19625 10.679 8.72886 11.0173L13.9366 14.3247Z"
      fill={fill}
    />
  </svg>
);
