import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Modal, { Footer, useModalManager, modalManagerPropTypes } from 'common/Modal';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import { SubmitError } from 'common/Forms/Utils';
import styles from './RemoveImage.module.scss';
import I18n from '../../../../modules/i18n';

const RemoveImagePropTypes = {
  afterSubmit: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  fileName: PropTypes.string,
  headerClass: PropTypes.string,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imageModelId: PropTypes.string.isRequired,
  modalManager: modalManagerPropTypes,
  modalText: PropTypes.string,
  removeButtonText: PropTypes.string,
  standaloneButton: PropTypes.bool,
  useIcon: PropTypes.bool,
};

const RemoveImageDefaultProps = {
  buttonClass: 'btn btn--sm btn--outline-red',
  buttonText: I18n.t('remove'),
  closeButtonClassName: '',
  fileName: '',
  headerClass: '',
  headerText: 'Remove Image',
  modalManager: null,
  modalText: null,
  removeButtonText: 'Remove',
  standaloneButton: false,
  useIcon: true,
};

const RemoveImageWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <RemoveImage
      {...props}
    />
  </ApolloProvider>
);

RemoveImageWrapper.propTypes = RemoveImagePropTypes;
RemoveImageWrapper.defaultProps = RemoveImageDefaultProps;

const REMOVE_IMAGE = gql`
  mutation RemoveImageModel($imageModelId: ID!) {
    destroyImageModel(id: $imageModelId) {
      success
    }
  }
`;

const textLocations = [
  {
    name: 'English Level A HTML',
    selector: 'textarea[name="section[section_reading_levels_attributes][0][translations_attributes][0][body]"]'
  },
  {
    name: 'English Level B HTML',
    selector: 'textarea[name="section[section_reading_levels_attributes][1][translations_attributes][0][body]"]'
  },
  {
    name: 'Spanish HTML',
    selector: 'textarea[name="section[section_reading_levels_attributes][2][translations_attributes][0][body]"]'
  }
];

const RemoveImage = ({
  buttonClass,
  buttonText,
  closeButtonClassName,
  imageModelId,
  fileName,
  afterSubmit,
  standaloneButton,
  modalManager,
  modalText,
  headerClass,
  headerText,
  removeButtonText,
  useIcon,
}) => {
  const _modalManager = modalManager || useModalManager();
  const [removeImageMutation] = useMutation(REMOVE_IMAGE);
  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const isImageReferencedInText = (textSelector) => {
    const snippetRegex = new RegExp(`\\[IMAGE_MODEL_${imageModelId}(?:_CAPTION)?\\]`);
    const textField = document.querySelector(textSelector);

    if (!textField) return false;

    return (snippetRegex.test(textField.textContent));
  };

  const findSnippetReferenceLocations = () => {
    return textLocations.map((textSelector) => {
      return isImageReferencedInText(textSelector.selector) ? textSelector.name : null;
    }).filter(reference => !!reference);
  };

  const _modalText = (snippetReferenceLocations) => {
    if (modalText) return modalText;

    if (snippetReferenceLocations.length > 0) {
      return (
        <div className={styles.warningContainer}>
          <p>The image you are attempting to remove is being used in the:</p>
          <ul>
            {snippetReferenceLocations.map(location => (<li key={location}>{location}</li>))}
          </ul>
          <p>Please remove the snippet from the location(s) above, save your changes, and try again.</p>
        </div>
      );
    }

    return (
      <p>
        Are you sure you want to remove <strong>{fileName}</strong>?
      </p>
    );
  };

  const removeImage = () => {
    setSubmitting(true);
    removeImageMutation({ variables: { imageModelId: imageModelId } })
      .then((success) => {
        if (!success) {
          console.log(success);
          return;
        }

        afterSubmit({ id: imageModelId.toString() });
        setSubmitting(false);
        _modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setSubmitting(false);
      });
  };

  const renderModal = () => {
    const snippetReferenceLocations = findSnippetReferenceLocations();

    return (
      <Modal
        isOpen={_modalManager.isOpen}
        closeModal={_modalManager.close}
        headerText={headerText}
        headerClass={headerClass}
        closeButtonClassName={closeButtonClassName}
      >
        {_modalText(snippetReferenceLocations)}
        {hasError && <SubmitError error="Error removing image. Please reload the page and try again." />}
        <Footer
          primaryButtonText={removeButtonText}
          primaryButtonCallback={removeImage}
          primaryButtonSubmit={false}
          primaryButtonDisabled={snippetReferenceLocations.length > 0}
          secondaryButtonCallback={_modalManager.close}
          primaryButtonClassName="btn btn--red"
          submitting={submitting}
        />
      </Modal>
    );
  };

  return (
    <Fragment>
      {standaloneButton && (
        <button
          type="button"
          className={buttonClass}
          onClick={_modalManager.open}
        >
          {useIcon && <i className="fa fa-trash" aria-hidden="true" />} {buttonText}
        </button>
      )}
      {renderModal()}
    </Fragment>
  );
};

RemoveImage.propTypes = RemoveImagePropTypes;
RemoveImage.defaultProps = RemoveImageDefaultProps;

export default RemoveImageWrapper;
