export enum Color {
  RED = 'red',
  ORANGE = 'orange',
  GREEN = 'green',
  BLUE = 'blue',
  PURPLE = 'purple',
  WHITE = 'white',
  LIGHT_GREY = 'light-grey',
  MED_GREY = 'med-grey',
  DARK_GREY = 'dark-grey',
  VERY_DARK_GREY = 'very-dark-grey',
  DARK = 'dark',
  BLACK = 'black',
}

export interface UserInfo {
  staffer?: {
    first_name: string;
    last_name: string;
    email: string;
    id: string | number;
    role: string;
    username?: string;
    num_classes: number;
    num_programs: number;
    exclude_from_rostering?: boolean;
    subscriber_name: string;
    uid?: string;
    settings: object;
    from_auto_roster_district: boolean;
    full_name_with_email: string;
    classroom_destroy_finished?: boolean;
    require_password_reset?: string;
    school_year_sign_in_count: number;
    sysadmin: boolean;
    current_sign_in_at: string;
  };

  student?: {
    first_name: string;
    last_name: string;
    username: string;
    id: number | string;
    last_sign_in?: string;
    num_classes: number;
    full_name_with_username: string;
    uid?: string;
    school_year_sign_in_count: number;
  };
}

export type Program = {
  id: string,
  display_investigations: boolean,
  display_unit_content: boolean,
  science: boolean,
  referenceTextLabel: string,
};

export type Lesson = {
  id: string;
};
