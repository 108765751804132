import React from 'react';
import PropTypes from 'prop-types';

// this component just opens the modal rendered in:
// SlideObjectHTMLEditor/SlideObjectImage/SlideObjectVideo
// being rendered in SlideTemplateObject
const EditSlideObject = props => (
  <a
    className="dropdown-item"
    href="#"
    onClick={() => {
      props.modalManager.open();
      props.dropdownEvents.setOpen(false);
    }}
    role="button"
  >
    Edit
  </a>
);

EditSlideObject.propTypes = {
  dropdownEvents: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
  }).isRequired,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired
};

export default EditSlideObject;
