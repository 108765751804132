import React from 'react';
import Tooltip from 'common/Tooltip';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

const LTIGradebook = ({ text }) => {

  return (
    <Tooltip
      content="Copied to clipboard!"
      trigger="click"
    >
      <a role="button" href="#" className="js-clipboard-trigger js-tippy-trigger-with-clipboard js-prevent-default" data-clipboard-text={text}>
        <i className="fa fa-link fa-fw" aria-hidden="true" />
        {I18n.t('lti_gradebook_link')}
      </a>
    </Tooltip>
  );
};

LTIGradebook.propTypes = {
  text: PropTypes.string.isRequired
};

export default LTIGradebook;
