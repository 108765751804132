import React, { useEffect, useRef } from 'react';
import I18n from '@/modules/i18n';
import PropTypes from 'prop-types';
import { useModalManager } from 'common/Modal';
import SlideConditionsIcon from '@/components/common/SlideConditionsIcon';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import styles from './Thumbnail.module.scss';
import SlideActionsDropdown from './SlideActionsDropdown';
import Slide from '../../../Slide';
import { slideObjectPropTypes } from '../../../Utils';
import useSlideShowContext from "../../../stores/useSlideShowContext";

const Thumbnail = ({
  isSelected,
  setSelectedSlide,
  slide,
}) => {
  const locale = useSlideShowContext(state => state.locale);
  const removeModalManager = useModalManager();
  const setIsSlideConditionsDrawerOpen = useSlideShowStore(state => state.setIsSlideConditionsDrawerOpen);
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') { removeModalManager.open(); }
  };

  const onMouseDown = () => {
    setSelectedSlide(slide.id);
  };

  const slideRef = useRef(null);
  useEffect(() => {
    if (isSelected) slideRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [isSelected]);

  return (
    <div
      className={`${styles.thumbnailWrapper} ${isSelected && styles.selected} locale-${locale}`}
      id={`slide_reel-${slide.id}`}
      ref={slideRef}
      onMouseDown={onMouseDown}
      onFocus={() => setSelectedSlide(slide.position)}
      onKeyDown={handleKeyDown}
      role="button"
      aria-label={`Slide Thumbnail ${slide.position}`}
      tabIndex="0"
    >
      <SlideActionsDropdown
        slide={slide}
        removeModalManager={removeModalManager}
      />

      <span className={styles.positionNumber}>
        {slide.position}
        {slide.condition && (
        <SlideConditionsIcon
          onClick={() => setIsSlideConditionsDrawerOpen(true)}
          tooltipContent={I18n.t('slide_conditions_for_slide')}
          tooltipPlacement="bottom-end"
        />
        )}
      </span>

      <Slide
        className={`${styles.overflowHidden} ${styles.noPointerEvents}`}
        forDisplay="thumbnail"
        imageModel={slide.imageModel}
        isSelected={isSelected}
        slide={slide}
        slideId={slide.id}
        slideObjects={slide.slideObjects}
        templateObjects={slide.slideTemplate && slide.slideTemplate.slideTemplateObjects}
      />
    </div>
  );
};

Thumbnail.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  setSelectedSlide: PropTypes.func.isRequired,
  slide: slideObjectPropTypes.isRequired,
};

export default Thumbnail;
