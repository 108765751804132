import React from 'react';
import * as Routes from '@/modules/routes';
import { COLOR_LEGEND } from '../../../variables';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useGames from '../../../hooks/queries/exerciseResults/useGames';
import { IChallenge, IExerciseResult, IGame, IStatus } from '../../../types';
import GradeCell from './index';
import { roundScore } from './utils';

interface GamesGradeCellProps {
  studentId: number,
  game: IGame | IChallenge,
}

export const compareFn = (exerciseResult: IExerciseResult, game: IGame | IChallenge, studentId: number) => (
  exerciseResult.exercise_id === game.id
  && exerciseResult.student_id === studentId
  && (
    exerciseResult.exercise_type === 'Game'
    || exerciseResult.exercise_type === 'Challenge'
  )
);

const GamesGradeCell = ({ studentId, game }: GamesGradeCellProps) => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);
  const { data: exerciseResults } = useGames();

  const getURL = (activityType) => {
    let url;
    switch (activityType) {
      case 'vocabulary_game':
      case 'state_capitals':
        url = Routes.games_shared_question_answers_path(studentId, {
          activity_type: activityType,
          classroom_id: classroom.id,
          lesson_id: lesson.id,
          program_id: programId,
          game_id: game.id,
        });
        break;
      case 'challenge':
        url = Routes.teacher_challenge_score_path(studentId, {
          challenge_id: game.id,
          classroom_id: classroom.id,
          program_id: programId,
        });
        break;
      default:
        url = '#';
        break;
    }
    return url;
  };

  const gamesStatus = (exerciseResult) => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    if (!exerciseResult) return status;

    if (exerciseResult.score) {
      const score = roundScore(exerciseResult.score);
      const lowScoreThreshold = game.max_score * lowScoresSettings.threshold / 100;
      const shouldHighlight = lowScoresSettings.enabled && score <= lowScoreThreshold;

      status.text = score;
      status.color = shouldHighlight ? COLOR_LEGEND.low_score : '';
    }

    const hasAnswers = exerciseResults.some(er => (er.student_id === studentId));

    // If it has answers and isn't complete, its in progress.
    if (hasAnswers && !exerciseResult.completed) {
      status.color = COLOR_LEGEND.in_progress;
    }

    return status;
  };

  const exerciseResult = exerciseResults.find(er => compareFn(er, game, studentId));

  let activityType = null;

  if (game.game_type === 'Vocabulary Game') {
    activityType = game.game_type.split(' ').join('_').toLowerCase();
  } else if (game.game_type === 'Geography Game') {
    activityType = 'state_capitals';
  } else if (game.game_type === 'Lesson Review Game') {
    activityType = 'challenge';
  }

  const url = getURL(activityType);
  const gameStatus: IStatus = gamesStatus(exerciseResult);

  return (
    <GradeCell
      id={`${activityType}-${game.id}-student-${studentId}-score`}
      status={gameStatus}
      url={url}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default GamesGradeCell;
