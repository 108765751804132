import React from 'react';
import { useForm } from 'react-hook-form';
import cn from '@/utils/cn';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import useCreatePanoramicImageMutation from '../../useCreatePanoramicImageMutation';
import useEditPanoramicImageMutation from '../../useEditPanoramicImageMutation';

const validFileExtensions = ['.png', '.jpg'] as const;
const fileRequirementText = `File Requirement: ${validFileExtensions.join(', ')}`;

interface AddImageModalProps {
  currentValues: IPanoramicImage;
  modalManager: ModalManager;
  slideObjectId: string;
  onSubmit?: (data: any) => any;
}

const AddImageModal = ({
  currentValues,
  modalManager,
  onSubmit: onSubmitCallback,
  slideObjectId,
}: AddImageModalProps) => {
  const {
    isLoading: isLoadingCreate, mutateAsync,
  } = useCreatePanoramicImageMutation(slideObjectId);
  const {
    isLoading: isLoadingUpdate, mutateAsync: mutateAsyncUpdate,
  } = useEditPanoramicImageMutation(currentValues.id);
  const isLoading = isLoadingCreate || isLoadingUpdate;

  const hasImage = !!currentValues.id;

  const form = useForm({
    defaultValues: {
      image: currentValues.image,
    },
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async (data) => {
    if (currentValues.id) {
      await mutateAsyncUpdate(data);
    } else {
      await mutateAsync(data);
    }

    if (onSubmitCallback) onSubmitCallback(data);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await form.handleSubmit(onSubmit)();
  };

  return (
    <SubmitModal
      isLoading={isLoading}
      className="tw-max-w-7xl tw-min-w-max"
      headerText={hasImage ? 'Edit Image' : 'Add Image'}
      submitText="Upload"
      submittingText="Uploading…"
      isOpen={modalManager.isOpen}
      close={modalManager.close}
      onSubmit={handleSubmit}
    >
      <form
        onSubmit={onSubmit}
      >
        <div className="tw-flex tw-items-center tw-gap-12 tw-mb-10">
          <label className="after:tw-text-red after:tw-content-['*']" htmlFor="model">
            <span className="tw-font-bold">360 Image</span>
          </label>
          <div>
            <div
              data-requirement-text={fileRequirementText}
              className={cn(
                'tw-relative after:tw-content-[attr(data-requirement-text)]',
                'after:tw-absolute after:tw-left-0 after:tw-top-full after:tw-mt-1',
              )}
            >
              <input accept={validFileExtensions.join(',')} type="file" {...form.register('image')} />
            </div>
          </div>
        </div>
      </form>
    </SubmitModal>
  );
};

export default AddImageModal;
