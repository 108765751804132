import React from 'react';
import Axios from 'axios';
import Modal, { Footer } from '@/components/common/Modal';
import * as Routes from '@/modules/routes';

interface DisableMFAProps {
  afterSubmit: (valid) => void;
  modalManager: ModalManager;
  userId: string;
}

const DisableMFAModal = ({ afterSubmit, modalManager, userId }: DisableMFAProps) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post(Routes.plato_api_disable_mfa_staffer_path({ id: userId }))
      .then(({ data: { enabled } }) => {
        afterSubmit(enabled);
        modalManager.close();
      });
  };

  return (
    <Modal
      bodyClassName="tw-text-base"
      headerText="Are you sure you want to disable multi-factor authentication?"
      isOpen={modalManager.isOpen}
      closeModal={modalManager.close}
    >
      <form onSubmit={handleSubmit}>
        <span className="tw-text-base">
          TCI requires all sysadmins to have multi-factor authentication enabled. Only disable this if you need to
          reconfigure your setup.
        </span>
        <Footer
          primaryButtonText="Yes, disable"
          primaryButtonClassName="btn btn--red"
          secondaryButtonText="Cancel"
          secondaryButtonCallback={modalManager.close}
        />
      </form>
    </Modal>
  );
};

export default DisableMFAModal;
