import { CSSProperties } from 'react';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';

/**
 * Used to set values for tooltip positioning data
 * If it is a datafied element, will use those values
 * If it is a non-datafied element, will get the values from the tooltip data from the markup
 * @param spanElement
 * @param tooltipButton
 */
export function getPositionStyles(
  spanElement: { 0: HTMLSpanElement } | undefined,
  tooltipButton: ITooltipButton,
): CSSProperties {
  if (tooltipButton.data?.tooltipStyles) return tooltipButton.data.tooltipStyles;
  if (!spanElement) return { bottom: 0, right: 0 };

  const span = spanElement[0];

  // Is a legacy element if it has children
  if (span.children.length > 0) {
    const { top, right, bottom, left } = (span.lastChild as HTMLSpanElement).style;
    const values = { top, right, bottom, left };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.fromEntries(Object.entries(values).filter(([_k, v]) => v !== ''));
  }

  return { bottom: 0, right: 0 };
}
