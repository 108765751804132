/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import { compareObjects } from '@/components/common/libraries/common/utils';
import { userInfo } from '@/components/common/libraries/actions';
import ToastManager, { ToastTypes } from '@/components/common/libraries/common/Toast/ToastManager';
import Status from '@/components/common/libraries/common/Status';
import { Color } from '@/components/common/libraries/types';
import Pagination from '@/components/common/libraries/common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { getFilteredVideos, getFilters, initialFilters, publishVideo, unpublishVideo } from './actions';
import { VideoFilter, VideoInfo } from './types';
import styles from './VideoLibrary.module.scss';
import VideoCard from './VideoCard';
import VideoPlayerModal from './VideoPlayerModal';

import FilterBar from './FilterBar';

interface PlayerStateType {
  visibility: boolean;
  videoInfo?: VideoInfo;
}

interface Props {
  programId: string | number;
}

const VideoLibrary = ({ programId }: Props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [videos, setVideos] = useState<Array<VideoInfo>>([]);
  const [filters, setFilters] = useState<VideoFilter>(initialFilters);
  const [playerState, setPlayerState] = useState<PlayerStateType>({
    visibility: false,
    videoInfo: undefined,
  });

  const isStudent = !userInfo.staffer;

  useEffect(() => {
    getFilters(programId).then((res) => {
      setFilters({ ...res });
    });
  }, [programId]);

  useEffect(() => {
    if (!compareObjects(filters, initialFilters)) {
      localStorage.setItem('video-filters', JSON.stringify(filters));
    }

    setIsLoading(true);

    getFilteredVideos(filters, programId).then(({ data, count }) => {
      setVideos(data);
      setTotalCount(count);
      setIsLoading(false);
    });
  }, [filters, userInfo, programId]);

  const handleFilterChange = (newFilters: VideoFilter, keepTotal?: boolean) => {
    if (!keepTotal) {
      setTotalCount(0);
    }
    setFilters(newFilters);
  };

  const handlePlayVideo = (videoInfo: VideoInfo) => {
    setPlayerState({ visibility: true, videoInfo });
  };

  const handleClosePlayer = () => {
    setPlayerState({ visibility: false, videoInfo: undefined });
  };

  const setNewVideos = (id: string | number, videos: VideoInfo[], title: string | undefined, publish: boolean) => {
    const newVideos = videos.map((videoInfo) => {
      if (videoInfo.id === id) {
        videoInfo.published = publish;
      }

      return videoInfo;
    });

    setVideos(newVideos);

    if (playerState.videoInfo?.id === id) {
      setPlayerState({
        ...playerState,
        videoInfo: { ...playerState.videoInfo, published: publish },
      });
    }

    const content = publish
      ? `Your students have access to ${title} on their Videos page.`
      : `Your students no longer have access to ${title} on their Videos page.`;

    ToastManager.show({
      type: ToastTypes.SUCCESS,
      title: (
        <>
          <Status borderColor={Color.GREEN} checked={publish} />
          Video {publish ? 'Published!' : 'Unpublished!'}
        </>
      ),
      content,
      duration: 5000,
      showIcon: true,
    });
  };

  const handlePublishVideo = (id: string | number | undefined, title: string | undefined, publish: boolean) => {
    if (!id) return;

    const publishMethod = publish ? publishVideo : unpublishVideo;

    publishMethod(id).then(() => {
      setNewVideos(id, videos, title, publish);
    });
  };

  const handlePageChange = (curPage: number) => {
    handleFilterChange({ ...filters, page: curPage }, true);
  };

  return (
    <div className={styles.page}>
      <div className={styles.description}>
        {!isStudent && 'See all the videos in your program and choose which ones to publish to your students.'}
      </div>

      <div className={styles.topPagination}>
        <Pagination
          currentPage={filters.page}
          totalCount={totalCount}
          pageSize={filters.per_page}
          onPageChange={handlePageChange}
        />
      </div>

      <div className={styles.container}>
        <FilterBar filters={filters} onFilterChange={handleFilterChange} />

        {!isLoading && videos.length > 0 ? (
          <div className={styles.paginationWrapper}>
            <div className={styles.cardContainer}>
              {videos.map(videoData => (
                <VideoCard
                  data={videoData}
                  key={`${videoData.id}-${btoa(videoData.lesson_title)}`}
                  onPlayVideo={handlePlayVideo}
                  onPublish={(id, title) => handlePublishVideo(id, title, true)}
                  onUnpublish={(id, title) => handlePublishVideo(id, title, false)}
                  showPublish={!isStudent}
                />
              ))}
            </div>
            <div className={styles.bottomPagination}>
              <Pagination
                currentPage={filters.page}
                totalCount={totalCount}
                pageSize={filters.per_page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        ) : isLoading ? (
          <div className={styles.loading}>
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className={styles.noResult}>
            <div className={styles.noResultTitle}>
              {isStudent ? 'No Videos Published' : 'No Videos Found'}
            </div>
            <div className={styles.noResultDescription}>
              {isStudent
                ? 'Your teacher has not published any videos. Please check back again later.'
                : 'Please modify your filters to explore different results.'}
            </div>
          </div>
        )}
      </div>

      <VideoPlayerModal
        visibility={playerState.visibility}
        onClose={handleClosePlayer}
        videoInfo={playerState.videoInfo}
        onPublish={(id, title) => handlePublishVideo(id, title, true)}
        onUnpublish={(id, title) => handlePublishVideo(id, title, false)}
        showPublish={!isStudent}
      />
    </div>
  );
};

export default VideoLibrary;
