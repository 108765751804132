import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import * as Routes from 'routes';
import styles from './Table.module.scss';
import AdvancedFilters from '../../../common/AdvancedFilters';
import { buildFilterPath } from '../../../common/AdvancedFilters/Utils';
import PaginatedTable from '../../../common/DataTable/PaginatedTable';
import ManualMerge from '../ManualMerge';

const INITIAL_HAMMING_DISTANCE = '0';

const Table = () => {
  const pageSize = 20;

  const imagesPath = (searchParams = {}) => (
    Routes.plato_api_images_path({
      column: 'columnName',
      page: 'pageNumber',
      per_page: 'pageSize',
      search: searchParams
    }).replace('pageSize', pageSize.toString())
  );

  const initialFilters = [{ operatorValue: 'lteq',
                            propertyValue: 'hamming_distance',
                            value: INITIAL_HAMMING_DISTANCE }];

  const initialFiltersParam = initialFilters.reduce((acc, filter) => {
    const { propertyValue, operatorValue, value } = filter;
    acc[`${propertyValue}_${operatorValue}`] = value;

    return acc;
  }, {});

  const [getUrl, setGetUrl] = useState(imagesPath(initialFiltersParam));
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [resources, setResources] = useState([]);
  const [doneLoading, setDoneLoading] = useState(false);
  const [filterSent, setFilterSent] = useState(true);

  const getAllImageIds = () => resources.map(resource => [resource.id, resource.dup_id]).flat();

  const filterResources = newResources => (
    newResources.filter(resource => !getAllImageIds().includes(resource.id, resource.dup_id) && resource.id !== resource.dup_id)
  );

  const removeResource = (resource, bestImage) => {
    setResources((prevResources) => {
      const updatedResources = prevResources.filter(r => r.id !== resource.id).map((prevResource) => {
        if (prevResource.id === resource.dupId) {
          return {
            ...prevResource,
            id: parseInt(bestImage.id, 10),
            image_en_file_name: bestImage.fileName,
            thumb_url: bestImage.smallUrl,
            url: bestImage.imageUrl
          };
        }

        if (prevResource.dup_id === resource.dupId) {
          return {
            ...prevResource,
            dup_id: parseInt(bestImage.id, 10),
            dup_image_en_file_name: bestImage.fileName,
            dup_thumb_url: bestImage.smallUrl,
            dup_url: bestImage.imageUrl
          };
        }

        return Object.assign({}, prevResource);
      });

      return updatedResources;
    });
  };

  const resetResources = () => {
    setDoneLoading(false);
    setPagesLoaded(0);
    setResources([]);
  };

  const addResources = (newResources, meta) => {
    setDoneLoading(meta.empty);
    setResources([...resources].concat(filterResources(newResources)));
    setPagesLoaded(pagesLoaded + 1);

    if (filterSent) {
      resetResources();
      setFilterSent(false);
    }
  };

  const updateActiveFilters = (updatedActiveFilters) => {
    setGetUrl(buildFilterPath(imagesPath(), updatedActiveFilters));
    resetResources();
    setFilterSent(true);
  };

  const asyncSearchPath = Routes.plato_api_search_images_path();
  const properties = [
    {
      label: 'Hamming Distance',
      operators: ['lteq'],
      value: 'hamming_distance'
    },
    {
      isBoolean: true,
      label: 'Has Text',
      operators: ['eq'],
      value: 'has_text'
    },
    {
      label: 'File Name (English)',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'image_en_file_name'
    }
  ];

  const renderActiveFilters = () => (
    <div>
      <AdvancedFilters
        initialFilters={initialFilters}
        properties={properties}
        searchPath={getUrl}
        resources={resources}
        updateActiveFilters={updateActiveFilters}
        asyncSearchPath={asyncSearchPath}
      />
      <hr />
    </div>
  );

  const columns = [
    {
      name: 'dup_id',
      targets: 0,
      title: 'ID'
    },
    {
      name: 'id',
      targets: 1,
      title: 'Dup ID'
    },
    {
      name: 'dup_count',
      targets: 2,
      title: 'Dup Count'
    },
    {
      name: 'hamming_distance',
      targets: 3,
      title: 'Hamming Distance'
    },
    {
      name: 'dup_image_en_file_name',
      targets: 4,
      title: 'File Name'
    },
    {
      name: 'image_en_file_name',
      targets: 5,
      title: 'Detected Dup File Name'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <div className={`${styles.thumbnailContainer}`}>
            {rowData.dup_url && (
              <a href={rowData.dup_url} target="_blank" rel="noopener noreferrer">
                <img src={rowData.dup_thumb_url} alt="..." />
              </a>
            )}
          </div>,
          td
        );
      },
      name: 'dup_url',
      targets: 6,
      title: 'Image'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <div className={`${styles.thumbnailContainer}`}>
            {rowData.url && (
              <a href={rowData.url} target="_blank" rel="noopener noreferrer">
                <img src={rowData.thumb_url} alt="..." />
              </a>
            )}
          </div>,
          td
        );
      },
      name: 'url',
      targets: 7,
      title: ' Dup Image'
    },
    {
      name: 'dup_has_text',
      targets: 8,
      title: 'Has Text'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <ManualMerge
            detectedDuplicateImage={{
              dupId: rowData.dup_id,
              fileName: rowData.image_en_file_name,
              id: rowData.id,
              imageUrl: rowData.url,
              perceptualHash: rowData.perceptual_hash
            }}
            selectedImage={{
              dupId: rowData.dup_id,
              fileName: rowData.dup_image_en_file_name,
              id: rowData.dup_id,
              imageUrl: rowData.dup_url,
              perceptualHash: rowData.perceptual_hash
            }}
            onRemove={removeResource}
          />,
          td
        );
      },
      name: 'actions',
      targets: 9,
      title: 'Actions'
    }
  ];

  const columnMapping = columns.map(c => (c.name === 'actions' ? null : c.name));

  const isLoading = () => !doneLoading && !resources.length;

  return (
    <React.Fragment>
      {renderActiveFilters()}
      <PaginatedTable
        addResources={addResources}
        columns={columns.map(c => c.name)}
        columnDefs={columns}
        columnMapping={columnMapping}
        defaultOrder={[0, 'asc']}
        doneLoading={doneLoading}
        exportedColumns={[0, 1, 2, 3, 4, 5, 6, 7]}
        getUrl={getUrl}
        isLoading={isLoading}
        pagesLoaded={pagesLoaded}
        resetResources={resetResources}
        resources={resources}
      />
    </React.Fragment>
  );
};

export default Table;
