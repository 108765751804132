import React from 'react';
import PropTypes from 'prop-types';

export default function LoadingButton(props) {
  function renderButton() {
    if (props.isLoading) {
      return renderLoadingButton();
    }

    return renderDefaultButton();
  }

  function renderDefaultButton() {
    return (
      <button
        className={props.buttonClass}
        onClick={props.handleClick}
      >
        {props.buttonTextDefault}
      </button>
    );
  }

  function renderLoadingButton() {
    return (
      <button
        className={`disabled ${props.buttonClass}`}
        disabled
      >
        <i
          className="fa fa-spinner fa-spin fa-1x fa-fw"
          aria-hidden="true"
        />
        {props.buttonTextLoading}
      </button>
    );
  }

  return (
    renderButton()
  );
}

LoadingButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  buttonTextDefault: PropTypes.string.isRequired,
  buttonTextLoading: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string
};

LoadingButton.defaultProps = {
  buttonClass: 'btn btn--outline-purple btn--sm',
};
