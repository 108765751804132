import React from 'react';
import Tooltip from 'components/common/Tooltip';

interface DropdownLinkProps {
  disabledText: string;
  href: string;
  icon: string;
  isDisabled: boolean;
  text: string;
  onClick?: () => void;
}

const DropdownLink = ({ disabledText, text, href, isDisabled, onClick = () => {} }: DropdownLinkProps) => {
  if (isDisabled) {
    return (
      <Tooltip
        allowHTML
        content={disabledText}
        interactive={false}
        size="medium"
        theme="white"
      >
        <span>
          <a
            className="dropdown-item disabled"
            href="#"
            role="button"
          >
            {text}
          </a>
        </span>
      </Tooltip>
    );
  }

  return (
    <a
      className="dropdown-item"
      href={href}
      role="button"
      onClick={onClick}
    >
      {text}
    </a>
  );
};

export default DropdownLink;
