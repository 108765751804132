import { useEffect, useMemo, useState } from 'react';

interface Filter<T> {
  getValue: (arg: T) => boolean;
  setInactiveIfActive?: string[];
  setActiveIf?: boolean;
}

export default function useFilters<T>(initialData: T[], filters: { [key: string]: Filter<T> }) {
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: boolean }>({});

  const data = useMemo(() => {
    if (!Object.values(activeFilters).some(activeFilter => activeFilter)) {
      return initialData;
    }

    return (Object.keys(activeFilters)).reduce((acc, cur) => {
      if (!activeFilters[cur]) return acc;

      return acc.filter(datum => filters[cur].getValue(datum));
    }, initialData);
  }, [Object.values(activeFilters), initialData]);

  useEffect(() => {
    const nextState = Object.keys(filters).reduce((acc, cur) => {
      if (filters[cur].setActiveIf === undefined) return acc;

      return { ...acc, [cur]: filters[cur].setActiveIf };
    }, {});

    setActiveFilters(prev => ({ ...prev, ...nextState }));
  }, Object.values(filters).map(filter => filter.setActiveIf));

  const toggle = (newFilter: string) => {
    const filter = filters[newFilter];
    let additionalSettings = {};

    if (!activeFilters[newFilter] && filter.setInactiveIfActive) {
      additionalSettings = filter.setInactiveIfActive.reduce((acc, cur) => ({ ...acc, [cur]: false }), {});
    }

    setActiveFilters(prev => ({ ...prev, ...additionalSettings, [newFilter]: !activeFilters[newFilter] }));
  };

  const clear = () => setActiveFilters({});

  return { activeFilters, clear, data, toggle };
}
