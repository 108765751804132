import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from './MyAccount.module.scss';

const DefaultHomeViewField = ({ value, onChange, isTeacher }) => {
  if (isTeacher) return null;

  return (
    <div className={styles.row}>
      <label id="default_home_id" className={styles.label} htmlFor="default_home">Default Landing Page</label>
      <Select
        className={styles.defaultHomeSelect}
        aria-labelledby="default_home_id"
        id="default_home"
        name="default_home"
        value={value}
        onChange={onChange}
        options={[
          { label: 'Admin Dashboard', value: '0' },
          { label: 'My Programs', value: '1' }
        ]}
        searchable={false}
        clearable={false}
      />
    </div>
  );
};

DefaultHomeViewField.propTypes = {
  isTeacher: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default DefaultHomeViewField;
