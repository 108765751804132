import React, { useState } from 'react';
import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Modal from '@/components/common/Modal';
import * as Routes from '@/modules/routes';
import Instructions from './Instructions';
import CodeForm from './CodeForm';

interface EnableMFAFormProps {
  afterSubmit?: (valid) => void;
  mfaEnabled?: boolean;
  inline?: boolean;
  invalidCode?: string;
  modalManager?: ModalManager;
  QRCodeURI?: string;
  userId: string;
}

interface ApiData {
  uri: string;
}

const EnableMFAForm = ({
  afterSubmit, mfaEnabled, inline, invalidCode, modalManager, QRCodeURI, userId,
}: EnableMFAFormProps) => {
  const [QRCode, setQRCode] = useState<string>(QRCodeURI);

  const { data: uriData, isFetching } = useQuery(['GenerateOTP'], () => (
    Axios.get<ApiData>(Routes.plato_api_generate_otp_staffer_path(userId, { format: 'json' }))
      .then(({ data }) => {
        setQRCode(data.uri);
        return data.uri;
      })
  ), {
    enabled: !!(modalManager?.isOpen && !QRCode),
    staleTime: Infinity,
  });

  if (isFetching) return Modal.spinner();

  return (
    <>
      <Instructions
        inline={inline}
        mfaEnabled={mfaEnabled}
        QRCode={uriData || QRCodeURI}
      />
      <CodeForm
        inline={inline}
        afterSubmit={afterSubmit}
        modalManager={modalManager}
        userId={userId}
        invalidCode={invalidCode}
      />
    </>
  );
};

export default EnableMFAForm;
