import React, { useState, useEffect } from 'react';
import * as Routes from '@/modules/routes';
import Axios from 'axios';
import successIcon from 'images/icons/success.png';
import warningIcon from 'images/icons/warning.png';

type IntegrationStatusTypes = {
  lmsType: 'Canvas' | 'Google Classroom' | 'Canvas LTI 1.3' | 'Schoology LTI 1.3';
  programId: number;
};

const IntegrationStatus = ({
  lmsType, programId,
}: IntegrationStatusTypes) => {
  const [classrooms, setClassrooms] = useState([]);
  const [studentSeats, setStudentSeats] = useState([]);
  const [studentLmsIds, setStudentLmsIds] = useState([]);

  const LMS_MAP = {
    Canvas: 'canvas',
    'Canvas LTI 1.3': 'canvas_lti',
    'Google Classroom': 'google',
    'Schoology LTI 1.3': 'schoology',
  };

  const filterLmsIds = (lmsIds, seats) => (
    lmsIds.filter(id => id.user_type === 'Student' && seats.map(seat => seat.student_id).includes(id.user_id))
  );

  const isLti = lmsType.includes('LTI');

  useEffect(() => {
    const route = Routes.plato_api_me_path({ extra_attributes: [
      'classrooms', 'shared_student_seats', 'student_user_google_ids', 'student_user_canvas_ids',
      'student_canvas_lti_ids', 'student_schoology_lti_ids',
    ] });

    Axios.get(route)
      .then((res) => {
        const filteredClassrooms = res.data.staffer.classrooms.filter(classroom => classroom.program_id === programId);
        setClassrooms(filteredClassrooms);

        const filteredStudentSeats = res.data.staffer.shared_student_seats
          .filter(seat => filteredClassrooms.map(classroom => classroom.id).includes(seat.classroom_id));
        setStudentSeats(filteredStudentSeats);

        const lmsIdMapping = {
          Canvas: res.data.staffer.student_user_canvas_ids,
          'Google Classroom': res.data.staffer.student_user_google_ids,
          'Canvas LTI 1.3': res.data.staffer.student_canvas_lti_ids,
          'Schoology LTI 1.3': res.data.staffer.student_schoology_lti_ids,
        };

        const filteredLmsIds = filterLmsIds(lmsIdMapping[lmsType], filteredStudentSeats);
        setStudentLmsIds(filteredLmsIds);
      });
  }, [lmsType]);

  const classroomsForLms = () => classrooms.filter(classroom => (
    classroom.lms_type === LMS_MAP[lmsType] && !!classroom.lms_course_id
  ));

  const renderIcon = (syncedCount, totalCount) => {
    const url = syncedCount === totalCount ? successIcon : warningIcon;

    return (
      <img src={url} alt="" className="tw-w-[1rem]" />
    );
  };

  const renderCard = (title, connectedLength, totalLength) => (
    <div className="tw-flex">
      <div className="tw-w-[1.5rem]">
        {renderIcon(connectedLength, totalLength)}
      </div>

      <p className="tw-w-[5rem]">
        {title}
      </p>

      <p>
        {connectedLength}/{totalLength} Connected
      </p>

    </div>
  );

  return (
    <div className={isLti ? 'tw-mt-1' : 'tw-mt-5 tw-ml-[10rem]'}>
      <p className="tw-font-bold tw-pb-1">
        Integration Status
      </p>
      {!isLti && renderCard('Classrooms', classroomsForLms().length, classrooms.length)}
      {renderCard('Students', studentLmsIds.length, studentSeats.length)}
    </div>
  );
};

export default IntegrationStatus;
