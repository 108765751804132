import React from 'react';
import PropTypes from 'prop-types';

import CleverForm from './CleverForm';
import OnerosterForm from './OnerosterForm';
import CsvForm from './CsvForm';

import { ROSTER_METHOD_STEP, completedStepsPropTypes } from '../../../steps';

export default function ConfigurationStep({
  autoRosterDistrict, autoRosterUpdatePath, subscriberUpdatePath, previous,
  subscriber, updateRosterDistrict, updateSubscriber, next, completedSteps
}) {
  if (completedSteps[ROSTER_METHOD_STEP] === 'clever_api') {
    return (
      <CleverForm
        autoRosterDistrict={autoRosterDistrict}
        autoRosterUpdatePath={autoRosterUpdatePath}
        subscriberUpdatePath={subscriberUpdatePath}
        previous={previous}
        subscriber={subscriber}
        updateRosterDistrict={updateRosterDistrict}
        updateSubscriber={updateSubscriber}
        next={next}
      />
    );
  }
  else if (completedSteps[ROSTER_METHOD_STEP] === 'oneroster_api') {
    return (
      <OnerosterForm
        autoRosterDistrict={autoRosterDistrict}
        autoRosterDistrictPath={autoRosterUpdatePath}
        updateRosterDistrict={updateRosterDistrict}
        updateSubscriber={updateSubscriber}
        subscriber={subscriber}
        next={next}
        previous={previous}
      />
    );
  }
  else if (completedSteps[ROSTER_METHOD_STEP] === 'tci_csv') {
    return (
      <CsvForm
        autoRosterDistrict={autoRosterDistrict}
        autoRosterUpdatePath={autoRosterUpdatePath}
        next={next}
        updateRosterDistrict={updateRosterDistrict}
        previous={previous}
      />
    );
  }

  return null;
}

ConfigurationStep.propTypes = {
  autoRosterDistrict: PropTypes.shape({
    client_id: PropTypes.string,
    client_secret: PropTypes.string,
    endpoint_url: PropTypes.string,
    username_source: PropTypes.string
  }).isRequired,
  subscriber: PropTypes.shape({
    uid: PropTypes.string
  }).isRequired,
  subscriberUpdatePath: PropTypes.string.isRequired,
  autoRosterUpdatePath: PropTypes.string.isRequired,
  completedSteps: completedStepsPropTypes.isRequired,
  updateSubscriber: PropTypes.func.isRequired,
  updateRosterDistrict: PropTypes.func.isRequired,
  previous: PropTypes.func,
  next: PropTypes.func
};

ConfigurationStep.defaultProps = {
  previous: () => {},
  next: () => {}
};
