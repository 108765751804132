import React from 'react';

interface SidebarHeadProps {
  children: React.ReactNode;
  className?: string;
}

const SidebarHead = ({ children, className }: SidebarHeadProps) => (
  <div className={className}>
    {children}
    <div className="tw-border tw-border-gray-200 tw-min-w-full tw-border-solid -tw-mx-4 tw-my-2" />
  </div>
);

export default SidebarHead;
