import { Form, Field } from 'react-final-form';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { formatLocalTimestamp } from 'common/Utils';
import AutoSave from '../../../common/Forms/AutoSave';
import styles from './QuestionAnswerComment.module.scss';
import SaveStatus from '../SaveStatus';

export default class QuestionAnswerComment extends Component {
  static propTypes = {
    lastUpdated: PropTypes.string,
    teacherComment: PropTypes.string,
    updateURL: PropTypes.string.isRequired,
    userIsTeacher: PropTypes.bool.isRequired
  };

  static defaultProps = {
    lastUpdated: null,
    teacherComment: null
  };

  constructor(props) {
    super(props);

    this.state = {
      saveError: false,
      savedTime: null,
      saving: false
    };
  }

  save = async (values) => {
    this.setState({ saving: true, saveError: false });
    await Axios
      .put(this.props.updateURL, { question_answer: { teacher_comment: values.teacher_comment } })
      .then(() => {
        const savedTime = formatLocalTimestamp(new Date(), true);
        this.setState({ savedTime, saving: false });
      })
      .catch(() => {
        this.setState({ saving: false, saveError: true });
      });
  };

  // Renders editable comment form if the user is a teacher
  _renderCommentForm() {
    return (
      <Form
        keepDirtyOnReinitialize
        initialValues={{ teacher_comment: this.props.teacherComment }}
        onSubmit={this.save}
        render={() => (
          <div>
            <AutoSave save={this.save} />
            <Field
              name="teacher_comment"
              component="textarea"
              type="text"
              placeholder="Type here to comment..."
              className={styles.textarea}
            />
          </div>
        )}
      />
    );
  }

  // Renders plain text comment if the user is a student
  _renderPlainComment() {
    return <p className="mt15">{this.props.teacherComment}</p>;
  }

  render() {
    return (
      <div>
        <span>
          <b className="mr10">Comments</b>
          <SaveStatus
            savedTime={this.state.savedTime}
            saving={this.state.saving}
            saveErrorStatus={this.state.saveErrorStatus}
            networkError={this.state.networkError}
            inputError={false}
            lastUpdated={formatLocalTimestamp(this.props.lastUpdated, false)}
            saveError={this.state.saveError}
          />
        </span>

        {this.props.userIsTeacher ? this._renderCommentForm() : this._renderPlainComment()}
      </div>
    );
  }
}
