import React from 'react';
import { ISearchResult } from '../types';

const ImageModel = ({ body, image_url }: ISearchResult) => (
  <div className="tw-flex tw-items-start tw-mt-2">
    <img className="tw-max-w-[100px] tw-max-h-[100px]" src={image_url} />
    <div className="tw-pl-2" dangerouslySetInnerHTML={{ __html: `<strong>Caption:</strong> ${body}` }} />
  </div>
);

export default ImageModel;
