import { useEffect, useState } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';

const useDefaultProgramLicensesData = (programParams: { programParams: { id: number } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultProgramLicensesData, setDefaultProgramLicensesData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(Routes.plato_api_program_path(programParams.programParams.id),
      { params: {
        extra_attributes: ['program_licenses', 'licenses'],
        only: ['program_licenses', 'licenses'],
      } })
      .then((data) => {
        const licenses = data.data.program.licenses;

        if (licenses?.length) {
          const licensesData = data.data.program.program_licenses.map((program_license) => {
            const associatedLicense = licenses.find(l => (l.id === program_license.license_id));

            return { ...associatedLicense, ...program_license };
          });

          setDefaultProgramLicensesData(licensesData);
        }
        setIsLoading(false);
      });
  }, []);

  return { defaultProgramLicensesData, isLoading };
};

export default useDefaultProgramLicensesData;
