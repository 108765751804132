import create from 'zustand';
import { FilterType, TagType } from '@/components/shared/Correlations/types';

interface ICorrelationsStoreValues {
  filteredTags: TagType[];
  filters: FilterType;
  isLoading: boolean;
  tags: TagType[];
  unitLessonMap: { [key: number]: number };
  setFilteredTags: (filteredTags: TagType[]) => void;
  setFilters: (filters: FilterType) => void;
  setIsLoading: (isLoading: boolean) => void;
  setTags: (tags: TagType[]) => void;
  setUnitLessonMap: (unitLessonMap: { [key: number]: number }) => void;
}

const useCorrelationsStore = create<ICorrelationsStoreValues>((set, get) => ({
  filteredTags: [],
  filters: {},
  isLoading: false,
  tags: [],
  unitLessonMap: {},
  setFilteredTags: filteredTags => set({ filteredTags }),
  setFilters: (filters) => {
    const { tags, setFilteredTags, unitLessonMap } = get();

    const lessonIds = Object.keys(filters)
      .map(key => filters[key])
      .flatMap(filter => (filter.filter(f => f.checked).map(f => f.value)));

    set({ filters });

    if (lessonIds.length === 0) {
      get().setFilteredTags([]);
    } else {
      const filteredTags = tags
        .filter(tag => tag.lessons.some(lesson => lessonIds.includes(lesson.id)))
        .map((tag) => {
          const unitIds = lessonIds.map(lessonId => unitLessonMap[lessonId]);

          return ({
            ...tag,
            units: tag.units?.filter(unit => unitIds.includes(unit.id)),
            lessons: tag.lessons.filter(lesson => lessonIds.includes(lesson.id)),
          });
        });

      setFilteredTags(filteredTags);
    }
  },
  setIsLoading: isLoading => set({ isLoading }),
  setTags: tags => set({ tags }),
  setUnitLessonMap: unitLessonMap => set({ unitLessonMap }),
}));

export default useCorrelationsStore;
