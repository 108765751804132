import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { useDataTable } from '../../../common/DataTable';

interface GameGradesTableProps {
  activityType: String;
  columns: [String];
  studentAnswers: [{
    definition: String,
    state: String,
    region: String,
    attempts: [String],
    answer_choices: [String],
    correct_answer_index: string,
    score: Number,
    max_points: Number
  }];
}

const GameGradesTable = ({ activityType, columns, studentAnswers }: GameGradesTableProps) => {
  const { DataTable } = useDataTable();

  const getIconClasses = (attemptIndex, correctAnswerIndex) => {
    if (attemptIndex === correctAnswerIndex) {
      return `fa fa-check-circle ${styles.green}`;
    }

    return `fa fa-times-circle ${styles.red}`;
  };

  const renderAttemptCell = (attemptIndex, rowData) => {
    if (attemptIndex === '') return <span>-</span>;

    return (
      <span>
        {<i className={getIconClasses(attemptIndex, rowData.correct_answer)} />}
        {' '}
        {rowData.answer_choices.at(attemptIndex)}
      </span>
    );
  };

  const renderCorrectAnswerCell = (correctAnswerIndex, rowData) => (
    <span>
      {rowData.answer_choices.at(correctAnswerIndex)}
    </span>
  );

  const columnDefs = () => (
    [
      {
        targets: 1,
        width: activityType === 'vocabulary_game' ? '35%' : '20%',
      },
      {
        targets: -4,
        title: 'Selected Answer (1st Attempt)',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            renderAttemptCell(cellData, rowData),
            td,
          );
        },
      },
      {
        targets: -3,
        title: 'Selected Answer (2nd Attempt)',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            renderAttemptCell(cellData, rowData),
            td,
          );
        },
      },
      {
        targets: -2,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            renderCorrectAnswerCell(cellData, rowData),
            td,
          );
        },
      },
    ]
  );

  const formatTableContent = () => {
    if (activityType === 'vocabulary_game') {
      return studentAnswers.map(sa => ({
        definition: sa.definition,
        first_attempt: sa.attempts.at(0) || '',
        second_attempt: sa.attempts.at(1) || '',
        correct_answer: sa.correct_answer_index,
        answer_choices: sa.answer_choices,
        points: `${sa.score} / ${sa.max_points}`,
      }));
    }

    return studentAnswers.map(sa => ({
      state: sa.state,
      region: sa.region,
      first_attempt: sa.attempts.at(0) || '',
      second_attempt: sa.attempts.at(1) || '',
      correct_answer: sa.correct_answer_index,
      answer_choices: sa.answer_choices,
      points: `${sa.score} / ${sa.max_points}`,
    }));
  };

  return (
    <DataTable
      columnDefs={columnDefs()}
      columns={columns}
      resources={formatTableContent()}
      defaultOrder={[0, 'asc']}
    />
  );
};

export default GameGradesTable;
