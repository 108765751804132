import React, { useState } from 'react';
import StandardButton from '@/components/ui/Buttons/StandardButton';
import { ISlide } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { slidePropTypes } from '@/components/admin/SlideShow/Utils';
import EditSlideCondition from '@/components/admin/LessonGuides/SlideConditions/EditSlideCondition';
import DeleteModalButton from '@/components/common/DeleteModalButton';
import Tooltip from '@/components/common/Tooltip';
import Axios from 'axios';
import I18n from '@/modules/i18n';
import * as Routes from 'modules/routes';
import { toCamelCase } from '@/modules/TCIUtils';
import SlideConditionCopyModal from './SlideConditionCopyModal';

interface SlideConditionProps {
  slide: ISlide;
}

const SlideCondition = ({ slide }: SlideConditionProps) => {
  const { condition } = slide;
  const isEditing = !!condition;
  const updateSlide = useSlideShowContext(state => state.updateSlide);
  const [isLoading, setIsLoading] = useState(false);

  const deleteIcon = () => (
    <Tooltip content={I18n.t('delete')}>
      <i aria-hidden="true" className="fa tw-text-red fa-trash" />
    </Tooltip>
  );

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-border tw-border-solid tw-border-med-grey tw-rounded tw-box-border">
        <div className="tw-mx-4 tw-my-3">
          <strong className="tw-absolute">
            {I18n.t('slide')} {slide.position}
          </strong>

          {!isEditing && (
            <div className="tw-text-center">
              <StandardButton
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  Axios
                    .post(Routes.plato_api_conditions_path(), { slide_id: slide.id })
                    .then((res) => {
                      updateSlide(slide.id, { condition: toCamelCase(res.data.data) });
                    })
                    .finally(() => setIsLoading(false));
                }}
                outlineStyle
              >
                <i
                  className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa-plus-circle'} tw-mr-2`}
                  aria-hidden="true"
                />
                {I18n.t('add_slide_condition')}
              </StandardButton>
            </div>
          )}

          {isEditing && condition?.id && (
            <div className="tw-float-right">
              <SlideConditionCopyModal slide={slide} />
              <DeleteModalButton
                ariaLabel="delete-button"
                buttonClasses="tw-cursor-pointer tw-bg-transparent tw-border-none tw-text-lg tw-mt-[-5px] tw-ml-2 tw-px-0"
                buttonLabel={deleteIcon()}
                destroyPath={Routes.plato_api_condition_path(condition.id)}
                id={Number(condition.id)}
                modalHeaderText={I18n.t('delete_slide_condition')}
                confirmationMessage={I18n.t('delete_slide_condition_confirmation', { position: slide.position })}
                removeResource={() => updateSlide(slide.id, { condition: null })}
              />
            </div>
          )}

          {isEditing && <EditSlideCondition slide={slide} />}
        </div>
      </div>
    </div>
  );
};

SlideCondition.propTypes = {
  slide: slidePropTypes.isRequired,
};

export default SlideCondition;
