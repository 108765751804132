import * as Routes from 'modules/routes';
import I18n from 'i18n-js';
import civicsIcon from 'images/icons/library-civics.png';
import videoIcon from 'images/icons/library-video.png';
import gamesIcon from 'images/icons/library-games.png';
import biographyIcon from 'images/icons/library-biography.png';
import primarySourceIcon from 'images/icons/library-primary-source.png';
import assessmentsIcon from 'images/icons/library-assessments-source.png';
import styles from './NavigationCard.module.scss';

const videoLibrary = () => ({
  className: styles.purple,
  icon: videoIcon,
  text: 'videos',
  url: programId => Routes.shared_program_videos_path({ id: programId }),
});

const gamesLibrary = () => ({
  className: styles.orange,
  icon: gamesIcon,
  text: 'games',
  url: programId => Routes.library_shared_program_games_path({ id: programId }),
});

const biographiesLibrary = unitId => ({
  className: styles.green,
  icon: biographyIcon,
  text: 'biographies',
  url: programId => Routes.shared_program_reference_materials_path(
    programId, { selected_unit_id: unitId, tag: 'Biographies' }
  ),
});

const civicsLibrary = (enabled) => {
  if (!enabled) return null;

  return ({
    className: styles.red,
    icon: civicsIcon,
    text: 'civics',
    url: programId => Routes.shared_program_reference_materials_path(
      programId, { tag: 'Civics Library' }
    )
  });
};

const assessmentsLibrary = unitId => ({
  className: styles.blueGreen,
  icon: assessmentsIcon,
  text: 'assessments',
  url: (programId, lessonId) => Routes.library_shared_program_assessments_path(
    programId, { lesson_id: lessonId, unit_id: unitId }
  )
});

const primarySourceLibrary = (enabled, unitId) => {
  if (!enabled) return null;

  return ({
    className: styles.blue,
    icon: primarySourceIcon,
    text: 'primary_sources',
    url: programId => Routes.shared_program_reference_materials_path(
      programId, { selected_unit_id: unitId, tag: 'Primary Source Library' }
    ),
  });
};

const libraries = (unitId, civicsLibraryEnabled, primarySourceLibraryEnabled) => [
  videoLibrary(),
  gamesLibrary(),
  biographiesLibrary(unitId),
  civicsLibrary(civicsLibraryEnabled),
  primarySourceLibrary(primarySourceLibraryEnabled, unitId),
  assessmentsLibrary(unitId)
].filter(Boolean);

export default libraries;
