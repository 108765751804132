import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { PerspectiveCamera } from 'three';
import { Position } from '@photo-sphere-viewer/core';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SlideObject } from '@/components/admin/SlideShow/types';
import DropdownMenu from '@/components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from '@/components/common/Dropdown';
import { slideObjectPropTypes } from '@/components/admin/SlideShow/Utils/index';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import RemoveSlideObject from './RemoveSlideObject';
import EditSlideObject from './EditSlideObject';
import EditBackgroundPopover from './EditBackgroundPopover';
import styles from './ContentActions.module.scss';
import EditPaddingPopover from './EditPaddingPopover';
import usePopover from '../hooks/usePopover';
import EditBorderPopover from './EditBorderPopover';
import CameraPositionButton from './CameraPositionButton';
import PanoramicDefaultViewButton from './PanoramicDefaultViewButton';

interface ContentActionsProps {
  isEditable?: boolean;
  modalManager: ModalManager,
  slideObject: slideObjectPropTypes,
  styleDivRef: React.MutableRefObject<HTMLDivElement>,
  threeJsCamera?: PerspectiveCamera;
  threeJsControls?: OrbitControls;
  panoramicCamera: Position;
  thumbnail: boolean;
}

const EDITABLE_TYPES = ['HTML', 'Image', 'Video', 'Question', '3DModel', 'PanoramicImage', 'InteractiveMap'];

const ContentActions = ({
  modalManager, slideObject, styleDivRef, threeJsCamera, thumbnail, threeJsControls, panoramicCamera,
}: ContentActionsProps) => {
  const ref = useRef();
  const dropdownEvents = useDropdownEvents(ref);
  const anchorId = `color-popover-anchor-${slideObject.id}`;
  const openerClass = `actions-${slideObject.id}`;
  const { coordinates } = usePopover({ anchorId, dropdownEvents, openerClass, ref, position: 'bottom left' });
  const isSysAdmin = useSlideShowContext(state => state.isSysAdmin);

  // don't show the edit button for editable types
  // don't show the edit button to teachers for HTML or question objects
  const showEditButton = () => {
    if (!EDITABLE_TYPES.includes(slideObject.modelType)) return false;
    if (isSysAdmin) return true;
    if (slideObject.modelType === 'HTML') return false;
    if (slideObject.modelType === '3DModel') return false;
    if (slideObject.modelType === 'Video' && slideObject.modelVideo) return false;
    if (slideObject.modelType === 'InteractiveMap') return false;
    if (slideObject.modelType === 'PanoramicImage') return false;

    return true;
  };

  return (
    <>
      <div id={anchorId} className={styles.actionsButtonWrapper}>
        <button
          type="button"
          className={`btn btn--outline-purple ${styles.actionsButton} ${openerClass}`}
        >
          <i className={`fa fa-edit ${styles.buttonIcon}`} />
        </button>
      </div>

      {ReactDOM.createPortal(
        <DropdownMenu
          open={dropdownEvents.open}
          className={`${styles.actionsButton}`}
          coordinates={coordinates}
          ref={ref}
        >
          {showEditButton() && (
            <EditSlideObject modalManager={modalManager} dropdownEvents={dropdownEvents} />
          )}
          {showEditButton() && threeJsCamera && slideObject.threeDModels.length > 0 && (
            <CameraPositionButton
              camera={threeJsCamera}
              controls={threeJsControls}
              modalManager={modalManager}
              slideObject={slideObject}
            />
          )}
          {showEditButton() && slideObject.modelType === 'PanoramicImage' && (
            <PanoramicDefaultViewButton
              slideObjectId={slideObject.id}
              panoramicCamera={panoramicCamera}
              onSuccess={() => dropdownEvents.setOpen(false)}
            />
          )}
          {slideObject.modelType !== 'Question' && slideObject.modelType !== '3DModel' && (
            <>
              <EditBackgroundPopover
                anchorId={`color-popover-anchor-${slideObject.id}`}
                slideObject={slideObject}
                styleDivRef={styleDivRef}
                dropdownEvents={dropdownEvents}
              />
              <EditPaddingPopover
                anchorId={`color-popover-anchor-${slideObject.id}`}
                slideObject={slideObject}
                styleDivRef={styleDivRef}
                dropdownEvents={dropdownEvents}
              />
            </>
          )}
          {!slideObject.isDragAndDrop && slideObject.modelType !== '3DModel' && (
            <EditBorderPopover
              anchorId={`color-popover-anchor-${slideObject.id}${thumbnail ? '-thumbnail' : ''}`}
              slideObject={slideObject}
              styleDivRef={styleDivRef}
              dropdownEvents={dropdownEvents}
            />
          )}
          <RemoveSlideObject
            slideObject={slideObject}
            dropdownEvents={dropdownEvents}
          />
        </DropdownMenu>,
        document.body,
      )}
    </>
  );
};

export default ContentActions;
