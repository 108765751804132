import React from 'react';
import SidebarHead from '@/components/ui/Sidebar/SidebarHead';

interface SidebarFilterHeadProps {
  buttonText: React.ReactNode;
  className?: string;
  headerText: React.ReactNode;
  onClick: () => any;
}

const SidebarHeadWithButton = ({ buttonText, className, headerText, onClick }: SidebarFilterHeadProps) => (
  <SidebarHead className={className}>
    <div className="tw-flex tw-justify-between tw-items-baseline tw-min-w-full">
      <p className="tw-font-bold tw-text-lg tw-mb-0">{headerText}</p>
      <button
        className="tw-bg-transparent tw-border-none tw-text-tci-purple tw-cursor-pointer hover:tw-underline"
        onClick={onClick}
        type="button"
      >
        {buttonText}
      </button>
    </div>
  </SidebarHead>
);

export default SidebarHeadWithButton;
