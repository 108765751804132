import React from 'react';

interface GlossaryTermProps {
  originalText: string;
  selectedTerm: {
    id: number;
    term: string;
    term_es: string;
    definition: string;
    definition_es: string;
  }
  modelId: number;
  locale: Locale
}

const GlossaryTerm = ({ originalText, modelId, selectedTerm, locale }: GlossaryTermProps) => (
  <span
    className="glossary-term tw-text-[#247f99]"
    data-term-id={selectedTerm.id}
    data-term-model-id = {modelId} 
    data-term={locale === 'en' ? selectedTerm.term : selectedTerm.term_es || ''}
  >
    <strong className="glossary-text tw-underline tw-cursor-pointer">
      {originalText}
    </strong>
    <span className="tw-hidden glossary-definition">
      &nbsp;[{locale === 'en' ? selectedTerm.term : selectedTerm.term_es || selectedTerm.term} : {locale === 'en' ? selectedTerm.definition : selectedTerm.definition_es || ''}]
    </span>
  </span>
);

export default GlossaryTerm;
