/**
 * Wrapper component for assessment question
 * Includes functionality for rendering media (images, GIFs, videos) and
 * auto-saving
 */

import React, { Component } from 'react';
import clsx from 'clsx';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import Axios from 'axios/index';
import { Form, Field } from 'react-final-form';
import videoPropTypes from 'common/VideoPlayer/Proptypes';
import AutoSave from './AutoSave';
import {
  formatTimestamp, renderImage, renderStatusMessage, renderVideo
} from './QuestionUtils';
import submit, { getPreviousAnswers } from './Questions/submit';

import styles from './MultipleChoiceQuestion.module.scss';
import SubmissionTextHelper from './SubmissionTextHelper';
import ResetReadingGameBtn from '../ResetReadingGameBtn';

export default class AssessmentQuestionForm extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    correctChoices: PropTypes.arrayOf(PropTypes.string),
    correctChoicesIndexes: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    gif: PropTypes.shape({
      imageUrl: PropTypes.string,
      width: PropTypes.number
    }),
    hasMultipartAnswer: PropTypes.bool,
    image: PropTypes.shape({
      imageUrl: PropTypes.string,
      width: PropTypes.number,
      zoomUrl: PropTypes.string,
    }),
    isInReadingGame: PropTypes.bool,
    isInSlide: PropTypes.bool,
    maxAttempts: PropTypes.number.isRequired,
    parseAnswer: PropTypes.func,
    parseValues: PropTypes.func,
    points: PropTypes.number,
    preview: PropTypes.bool,
    questionContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    questionId: PropTypes.number.isRequired,
    questionType: PropTypes.string,
    readingGameId: PropTypes.number,
    saveDebounce: PropTypes.number,
    savedResult: PropTypes.shape({
      answer: PropTypes.string,
      previousAnswers: PropTypes.arrayOf(PropTypes.string),
      updated_at: PropTypes.string,
    }),
    submitForm: PropTypes.bool,
    submitStatusDebounce: PropTypes.number,
    useDeferredSubmitStatus: PropTypes.bool,
    useReadingGameResetBtn: PropTypes.bool,
    useSubmitButton: PropTypes.bool,
    video: videoPropTypes,
  };

  static defaultProps = {
    correctChoices: [],
    correctChoicesIndexes: [],
    disabled: false,
    gif: null,
    hasMultipartAnswer: false,
    image: null,
    isInReadingGame: false,
    isInSlide: false,
    parseAnswer: null,
    parseValues: null,
    points: 0,
    preview: true,
    questionContent: null,
    questionType: '',
    saveDebounce: 800,
    savedResult: null,
    submitForm: false,
    submitStatusDebounce: null,
    useSubmitButton: false,
    video: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      correctChoices: this.props.correctChoices,
      correctChoicesIndexes: this.props.correctChoicesIndexes,
      pointsEarned: (this.props.savedResult && this.props.savedResult.question_answer && parseFloat(this.props.savedResult.question_answer.score, 10)) || 0,
      previousAnswers: getPreviousAnswers(this.props.savedResult),
      status: this.props.savedResult && this.props.savedResult.updated_at ? '200' : null,
      submitting: false,
      unsavedChanges: false,
      updatedAt: this.props.savedResult && formatTimestamp(this.props.savedResult.updated_at, false),
      value: this.parseAnswer(this.props.savedResult),
    };

    this.save = this.save.bind(this);
    this.setNewState = this.setNewState.bind(this);
    this.parseAnswer = this.parseAnswer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getChildren(form) {
    if (this.props.hasMultipartAnswer) {
      return React.cloneElement(this.props.children, {
        disabled: this.submissionTextHelper().isDisabled(),
        handleAnswerSelect: form.mutators.handleAnswerChange,
        isDropdownDisabledValidator: dropdownIndex => (
          (answerString, answerIndex) => {
            if (!answerString) return false;

            const { previousAnswers } = this.state;

            // if there are no previous answers, do not disable
            if (previousAnswers.length === 0) return false;
            const mostRecentAnswer = previousAnswers[previousAnswers.length - 1];

            // if there is no most recent answer (in assessments, user can select answer and refresh page
            // which causes an error), do not disable
            if (!mostRecentAnswer) return false;

            const hasCorrectAnswer = this.state.correctChoices[dropdownIndex] === answerString.trim();
            const isMostRecentAnswer = mostRecentAnswer[dropdownIndex] === answerIndex;

            // since we allow 2 attempts, we can check if the most recent answer is correct
            // and disable based on that
            if (hasCorrectAnswer && isMostRecentAnswer) return true;

            return false;
          }
        ),
        isPartiallyCorrect: (dropdownIndex) => {
          const { previousAnswers, correctChoicesIndexes } = this.state;
          if (previousAnswers.length === 0 || correctChoicesIndexes.length === 0) return false;

          let indexAtFirstCorrect = -1;
          previousAnswers.find((answer, index) => {
            const isCorrect = answer[dropdownIndex] === correctChoicesIndexes[dropdownIndex];
            if (isCorrect) indexAtFirstCorrect = index;
            return isCorrect;
          });

          return ![0, -1].includes(indexAtFirstCorrect);
        },
        isComplete: () => {
          return this.submissionTextHelper().hasCorrectAnswer() || this.state.previousAnswers.length >= this.props.maxAttempts;
        },
      });
    }

    return this.props.children;
  }

  save = async (values) => {
    if (!this.props.submitForm) return;
    this.setState({ status: null, submitting: true });

    await Axios
      .post(this.props.ajaxUrl, { question_answer: { answer: this.state.value, ...this.parseValues(values) } })
      .then((response) => {
        const data = response.data;
        if (data.errors) {
          this.setState({ status: '500' });
          console.log(data.errors);
        }
        else {
          const formattedDate = formatTimestamp(data.question_answer.updated_at, true);
          const formattedAnswer = this.parseAnswer(data.question_answer);

          this.setState({
            status: '200',
            updatedAt: formattedDate,
            value: formattedAnswer
          });
        }
      })
      .catch((error) => {
        this.setState({ status: '400' });
        console.log(error);
      })
      .then(() => {
        if (this.props.submitStatusDebounce) {
          setTimeout(() => {
            this.setState({ submitting: false, unsavedChanges: false });
          }, this.props.submitStatusDebounce);
        }
        else {
          this.setState({ submitting: false, unsavedChanges: false });
        }
      });
  };

  setNewState(data) {
    this.setState(data);
  }

  parseAnswer(questionAnswer) {
    if (!questionAnswer) return;

    if (this.props.parseAnswer) {
      return this.props.parseAnswer(questionAnswer.answer);
    }

    if (this.props.questionType === 'FillInTheBlank') {
      return questionAnswer.answer;
    }

    return questionAnswer.answer;
  }

  parseValues(values) {
    if (this.props.parseValues) {
      return this.props.parseValues(values);
    }

    return values;
  }

  isAnswered() {
    if (this.props.hasMultipartAnswer) {
      return this.state.answered;
    }

    return !!this.state.value;
  }

  submissionTextHelper() {
    return (
      new SubmissionTextHelper({
        correctChoices: this.state.correctChoices,
        correctChoicesIndexes: this.state.correctChoicesIndexes,
        disabled: this.props.disabled,
        hasSubmitted: this.state.previousAnswers.length > 0,
        maxAttempts: this.props.maxAttempts,
        maxPoints: this.props.points,
        pointsEarned: this.state.pointsEarned,
        previousAnswers: this.state.previousAnswers,
        questionType: this.props.questionType,
        savedResult: this.props.savedResult,
        submitting: this.state.submitting,
        value: this.state.value,
      })
    );
  }

  getQuestionStyles() {
    const classes = 'expandable_question question-toggle allow-list-style';

    if (this.props.questionType === 'FillInTheBlank') {
      return `${classes} ${styles.respectWhiteSpace}`;
    }
    return classes;
  }

  submitButtonDisabled() {
    return this.submissionTextHelper().cannotSubmit();
  }

  renderVideo = () => {
    if (this.props.isInReadingGame) {
      return renderVideo({ video: this.props.video, width: null, minWidth: null, maxWidth: '500' });
    }

    return renderVideo({ video: this.props.video });
  }

  handleSubmit = () => (
    submit({
      ajaxUrl: this.props.ajaxUrl,
      parseAnswer: this.parseAnswer,
      questionId: this.props.questionId,
      questionType: this.props.questionType,
      setNewState: this.setNewState,
      value: this.state.value,
    })
  )

  render() {
    return (
      <Form
        initialValues={{
          answer: this.state.value,
          question_id: this.props.questionId
        }}
        mutators={{
          handleAnswerChange: (args, state, utils) => {
            this.setState({
              answered: args[0].fullyAnswered,
              unsavedChanges: args[0].fullyAnswered && !args[0].initial,
            });

            utils.changeValue(state, 'answer', () => JSON.stringify(args[0].data));
          }
        }}
        keepDirtyOnReinitialize
        onSubmit={this.save}
        subscription={{}}
        render={({ form }) => (
          <>
            <form
              className={clsx({
                'has-image': !!this.props.image,
                'reading-game-question': this.props.isInReadingGame,
                'slide-question': this.props.isInSlide,
              })}
              data-answered={this.isAnswered() && !this.state.unsavedChanges}
              data-question-type={this.props.questionType}
            >
              {this.props.submitForm && <AutoSave debounce={this.props.saveDebounce} save={this.save} />}

              <fieldset id={`question_${this.props.questionId}`}>
                <div>
                  <legend
                    className={this.getQuestionStyles()}
                    data-accordian-pair-id={this.props.questionId}
                  >
                    <Field
                      name="answer_data"
                      // Updates the question child so that on change it calls
                      // the form mutator
                      render={() => this.getChildren(form)}
                    />
                    {!(this.props.isInSlide || this.props.isInReadingGame) && renderStatusMessage(this.state.status, this.state.submitting, this.state.updatedAt, 'Assessment')}
                  </legend>
                </div>

                <div
                  className="js-question-details mb20"
                  data-accordian-pair-id={this.props.questionId}
                >
                  <div className={styles.stimuliWrapper}>
                    {renderImage(this.props.image)}
                    {renderImage(this.props.gif)}
                    {this.renderVideo()}
                  </div>
                  <div className={`${this.submissionTextHelper().isDisabled() ? styles.disabled : ''}`}>
                    {typeof this.props.questionContent === 'function' ? (
                      this.props.questionContent({
                        isDisabled: this.state.previousAnswers.length === this.props.maxAttempts || this.submissionTextHelper().hasCorrectAnswer(),
                        onAnswerChange: (_, data) => this.setState({ value: data.value })
                      })
                    ) : (
                      this.props.questionContent
                    )}
                  </div>
                </div>
              </fieldset>
            </form>
            {this.props.useSubmitButton && !this.props.preview && (
              <div className={clsx(styles.submitBtnContainer, 'submit-btn-container')}>
                {this.submissionTextHelper().submissionAlert()}
                <div className="tw-flex tw-gap-4">
                  <button
                    className={`btn btn--purple ${this.submitButtonDisabled() && 'link-disabled disabled'}`}
                    disabled={this.submitButtonDisabled()}
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    {I18n.t('submit')}
                  </button>
                  {this.props.useReadingGameResetBtn && this.props.readingGameId && (
                    <ResetReadingGameBtn
                      disabled={!this.state.unsavedChanges && this.state.previousAnswers.length === 0 && !this.state.value}
                      readingGameId={this.props.readingGameId}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      />
    );
  }
}
