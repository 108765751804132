import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import SysadminTable from '../../common/SysadminTable';
import Form from './Form';
import Row from './Row';
import { ModalButton } from '../materials/Utils';

const columns = [
  { text: 'Section', className: 'section' },
  { text: 'Page Number(s)', className: 'page-numbers' },
  { text: 'Timing', className: 'timing' },
  { text: 'Actions', className: 'actions' }
];

export default class Table extends Component {
  static sortByPosition(a, b) {
    return (a.position > b.position);
  }

  constructor(props) {
    super(props);
    this.state = props;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addRows = this.addRows.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
  }

  componentDidMount() {
    this.getSections();
  }


  getSections() {
    this.setState({ isLoading: true });
    Axios.get('get_sections').then((response) => {
      const sections = response.data;
      this.setState({
        sections,
        isLoading: false
      });
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  addRows(sections) {
    this.setState(prevState => ({
      sections: [...prevState.sections, ...sections].sort(Table.sortByPosition)
    }));
  }

  updateRow(updatedSection) {
    this.setState({
      sections: this.state.sections.map(section => (updatedSection.id === section.id ? updatedSection : section))
        .sort(this.sortByName)
    });
  }

  removeRow(id) {
    this.setState({
      sections: this.state.sections.filter(section => section.id !== id)
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  renderButtonAbove() {
    return (
      <div>
        <ModalButton
          buttonText="Add Section"
          buttonClass="action"
          modalHeader="Add Section"
          modalClass="add-sections"
          newRecord
          openModal={this.openModal}
          closeModal={this.closeModal}
          onAdd={this.addRows}
          FormComponent={Form}
          {...this.state}
        />
      </div>
    );
  }

  renderSections() {
    return this.state.sections && this.state.sections.map(section =>
      (
        <Row
          key={section.id}
          newRecord={false}
          removeRow={this.removeRow}
          updateRow={this.updateRow}
          {...this.props}
          {...section}
        />
      ));
  }

  render() {
    return (
      <SysadminTable
        containerClass="lesson-modules-sections"
        tableId="module-sections"
        columns={columns}
        isLoading={this.state.isLoading}
        buttonAbove={this.renderButtonAbove()}
        emptyMessage={this.props.emptyMessage}
      >
        {this.renderSections()}
      </SysadminTable>
    );
  }
}

Table.defaultProps = {
  allSections: [],
  emptyMessage: null,
  modalIsOpen: false,
  isLoading: false,
  sections: [],
  timings: []
};

Table.propTypes = {
  allSections: PropTypes.arrayOf(PropTypes.object),
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  lessonModuleId: PropTypes.number.isRequired,
  modalIsOpen: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
  timings: PropTypes.arrayOf(PropTypes.object)
};
