import React, { useState, KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select-5/creatable';

interface IOption {
  label: string;
  value: string;
}

const createOption = (label: string) => ({ label, value: label });

interface Props {
  additionalCopyrights?: string[];
}

const ProgramCopyrightSelector = ({ additionalCopyrights = [] }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<IOption[]>(additionalCopyrights.map(createOption));

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    if (['Enter', 'Tab'].includes(event.key)) {
      setValue(prev => [...prev, createOption(inputValue)]);
      setInputValue('');
      event.preventDefault();
    }
  };

  return (
    <>
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isMulti
        menuIsOpen={false}
        // @ts-ignore
        onChange={newValue => setValue(newValue)}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Enter a copyright year and press enter"
        value={value}
        styles={{ control: baseStyles => ({ ...baseStyles, width: '230px' }),
        }}
      />
      <input
        className="string optional"
        id="additional_copyrights"
        name="program[additional_copyrights]"
        type="hidden"
        value={JSON.stringify(value.map(option => option.value))}
      />
    </>
  );
};

export default ProgramCopyrightSelector;
