import React from 'react';
import I18n from '@/modules/i18n';
import { BringToFront, SendToBack } from '@carbon/react/icons';
import ColorPicker from '../../Toolbar/ColorPicker';
import Action from '../Action';
import useDrawingContext from '../../store/useDrawingContext';
import icons from './../../Toolbar/icons';

const SharedActions = () => {
  const duplicateObject = useDrawingContext(state => state.duplicateObject);
  const deleteObject = useDrawingContext(state => state.deleteObject);
  const moveObjects = useDrawingContext(state => state.moveObjects);
  const color = useDrawingContext(state => state.color);
  const { alt, icon } = icons.color;

  return (
    <>
      <Action
        className="tw-w-[35px]"
        tooltipContent={I18n.t('change_color')}
        PopoverContent={<ColorPicker />}
      >
        <div
          aria-label={alt}
          role="button"
          style={{
            backgroundColor: (color as string),
            backgroundImage: `url(${icon})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
          className="tw-flex tw-items-center tw-justify-center tw-w-[17px] tw-h-[17px] tw-rounded tw-border-[1px] tw-border-solid tw-border-gray-300"
        />
      </Action>
      <Action
        className="fa fa-clone tw-w-[35px]"
        onClick={() => duplicateObject()}
        tooltipContent={I18n.t('copy')}
      />
      <Action
        className="tw-w-[35px]"
        onClick={() => moveObjects('back')}
        tooltipContent={I18n.t('send_to_back')}
      >
        <SendToBack height={18} width={18} />
      </Action>
      <Action
        className="tw-w-[35px]"
        onClick={() => moveObjects('front')}
        tooltipContent={I18n.t('bring_to_front')}
      >
        <BringToFront height={18} width={18} />
      </Action>
      <Action
        className="fa fa-trash tw-text-red tw-w-[35px] tw-ml-2"
        onClick={() => deleteObject()}
        tooltipContent={I18n.t('delete')}
      />
    </>
  );
};

export default SharedActions;
