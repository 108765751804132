import React from 'react';
import styles from './Cards/index.module.scss';

type LessonSubheaderProps = {
  children: ReactChildren,
  description: string,
  isStaffer: boolean,
  title: string,
};

const LessonSubheader = ({
  children, description, isStaffer, title,
}: LessonSubheaderProps) => {
  return !!React.Children.count(children) && (
    <div className={styles.lessonSubheader}>
      <div className={styles.subheaderNumber}></div>
      <h2 className={styles.subheaderHeader}>
        <div className={styles.subheaderTitle}>{title}</div>
        {isStaffer && <div className={styles.subheaderDescription}>{description}</div>}
      </h2>
      <div className={styles.subheaderCards}>
        {children}
      </div>
    </div>
  );
}

export default LessonSubheader;
