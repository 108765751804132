import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/index';
import AssignmentForm from './AssignmentForm';
import {
  assessmentsPropType,
  classroomsPropType,
  assignmentInitialValuesPropType
} from './PropTypes';
import styles from './AssignmentModal.module.scss';
import useAssignablesStore from "./WhatSection/store/useAssignablesStore";

const AssignmentModal = ({
  assessments, assessmentsPagePath, assignmentsPath, classrooms,
  closeModal, connectedToCanvas, connectedToCanvas13, connectedToGoogleClassroom, loadingInitialValues, handlePostSave,
  initialLessonId, initialValues, modalIsOpen, newRecord, program, stafferId, subscriberConnectedToCanvas,
  updatePath
}) => {
  const score = useAssignablesStore(state => state.score);
  return (
    <Modal
      bodyClassName={styles.modalPopup}
      className={styles.modalPopup}
      closeModal={closeModal}
      headerText={newRecord ? 'Create Assignment' : 'Edit Assignment'}
      isOpen={modalIsOpen}
    >
      <AssignmentForm
        assessments={assessments}
        assessmentsPagePath={assessmentsPagePath}
        assignmentsPath={assignmentsPath}
        classrooms={classrooms}
        closeModal={closeModal}
        connectedToCanvas={connectedToCanvas}
        connectedToCanvas13={connectedToCanvas13}
        connectedToGoogleClassroom={connectedToGoogleClassroom}
        loadingInitialValues={loadingInitialValues}
        handlePostSave={handlePostSave}
        initialLessonId={initialLessonId}
        initialValues={initialValues}
        newRecord={newRecord}
        program={program}
        score={score}
        stafferId={stafferId}
        subscriberConnectedToCanvas={subscriberConnectedToCanvas}
        updatePath={updatePath}
      />
    </Modal>
  );
};

AssignmentModal.propTypes = {
  assessments: assessmentsPropType,
  assessmentsPagePath: PropTypes.string.isRequired,
  assignmentsPath: PropTypes.string.isRequired,
  classrooms: classroomsPropType,
  closeModal: PropTypes.func.isRequired,
  connectedToCanvas: PropTypes.bool,
  connectedToCanvas13: PropTypes.bool,
  connectedToGoogleClassroom: PropTypes.bool,
  handlePostSave: PropTypes.func.isRequired,
  initialLessonId: PropTypes.number,
  initialValues: assignmentInitialValuesPropType,
  loadingInitialValues: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  newRecord: PropTypes.bool,
  program: PropTypes.shape({
    display_investigations_text_section: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    science: PropTypes.bool.isRequired,
  }).isRequired,
  stafferId: PropTypes.number.isRequired,
  subscriberConnectedToCanvas: PropTypes.bool,
  updatePath: PropTypes.string
};

AssignmentModal.defaultProps = {
  assessments: [],
  classrooms: [],
  connectedToCanvas: false,
  connectedToCanvas13: false,
  connectedToGoogleClassroom: false,
  initialLessonId: null,
  initialValues: {},
  loadingInitialValues: false,
  newRecord: true,
  subscriberConnectedToCanvas: false,
  updatePath: ''
};

export default AssignmentModal;
