import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SubmitModal, useModalManager } from 'common/Modal';
import * as Routes from 'routes';
import ModalBody from './ModalBody';

const EditModal = ({ afterSubmit, modelVideo, showVideoLessonOption }) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <button type="button" className="dropdown-item full-size" onClick={modalManager.open}>
        <i className="fa fa-edit" aria-hidden />Edit
      </button>
      <SubmitModal
        headerText="Edit Video"
        submitPath={Routes.plato_api_model_video_path(modelVideo.id)}
        method="patch"
        closeModal={modalManager.close}
        isOpen={modalManager.isOpen}
        afterSubmit={afterSubmit}
        initialValues={modelVideo}
        disableUntilChange
      >
        <ModalBody
          initialVideoFileName={modelVideo.video_video_file_name}
          showWidth
          showVideoLessonOption={showVideoLessonOption}
        />
      </SubmitModal>
    </Fragment>
  );
};

EditModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  modelVideo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video_video_file_name: PropTypes.string.isRequired
  }).isRequired,
  showVideoLessonOption: PropTypes.bool
};

EditModal.defaultProps = {
  showVideoLessonOption: false
};

export default EditModal;
