import React from 'react';
import PropTypes from 'prop-types';

import styles from './MyAccount.module.scss';

export default function StudentInfo({
  student
}) {
  return (
    <div>
      <div>
        <div className={`inline_block ${styles.infoGroup}`}>
          <p className="bold">First Name</p>
          <p>{student.first_name}</p>
        </div>

        <div className={`inline_block ${styles.infoGroup}`}>
          <p className="bold">Last Name</p>
          <p>{student.last_name}</p>
        </div>
      </div>

      <div className={styles.infoGroup}>
        <p className="bold">Username</p>
        <p>{student.username}</p>
      </div>
    </div>
  );
}

StudentInfo.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string
  }).isRequired
};
