import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'common/Forms/Switch';
import * as Routes from 'routes';
import styles from './RosteringStep.module.scss';

export default function ClassesStep({
  closeModal, mapCoursesPath, mappedCoursesSize, previous, next, teacherSeatsLocked, subscriber, userType,
  updateTeacherSeatsLocked,
}) {
  const [adminLocked, setAdminLocked] = useState(teacherSeatsLocked);
  const hasPARS = mappedCoursesSize > 0;

  const programAssignmentCopy = () => {
    let iconClass;
    let rulesText;

    if (adminLocked) {
      if (hasPARS) {
        iconClass = 'fa fa-check-circle';
        rulesText = (
          <p>
            Congratulations! You&apos;re already set up.
            Go to <b>Program Assignments</b> to review settings and set up assignment rules.
          </p>
        );
      }
      else {
        iconClass = 'fa fa-exclamation-circle';
        rulesText = (
          <>
            <p>Admin lock is enabled and no program assignment rules have been created.</p>
            <p>
              Please create a rule to complete the auto-rostering setup.
              If you want teachers to manage their own program assignments,
              disable the setting below to finish the setup.
            </p>
          </>
        );
      }
    }
    else {
      iconClass = 'fa fa-check-circle';
      rulesText = (
        <>
          <p>Admin lock is disabled. Program assignment rules are not necessary.</p>
          <p>
            If you do not want teachers to manage their own program assignments,
            enable the setting below to finish the setup.
          </p>
        </>
      );
    }

    return (
      <>
        <p className={styles.stepSubSection}><i className={iconClass} /> Program Assignment</p>
        {rulesText}
        <Switch
          label="Manage teacher program access at the admin level"
          labelTooltip={'Enabling this setting will remove the ability for teachers to self-assign programs if ' +
            'the roster integration is on. They will be prompted to contact an admin in your account for assistance.'}
          ajaxPath={Routes.plato_api_subscriber_path(subscriber.id)}
          model="subscriber"
          name="teacher_seats_locked"
          disabled={userType !== 'Sysadmin'}
          disabledTooltip="Only district admins are allowed to modify this setting."
          checked={teacherSeatsLocked}
          displayLabelFirst={false}
          onClick={() => {
            setAdminLocked(!adminLocked);
            updateTeacherSeatsLocked(!adminLocked);
          }}
        />
      </>
    );
  };

  const modalCloseCTA = () => (
    <button
      className="btn btn--outline-purple"
      onClick={closeModal}
    >
      Close
    </button>
  );

  return (
    <div>
      <p>
        {programAssignmentCopy()}
      </p>
      <div>
        <hr />
        <footer className="!tw-justify-between">
          <button type="button" onClick={previous} className="btn btn--link-purple">
            Back
          </button>
          <div className="tw-flex tw-gap-2">
            {modalCloseCTA()}
            {(adminLocked && !hasPARS) && (
              <a
                href={mapCoursesPath}
                className="btn btn--purple"
              >
                Go to Program Assignments
              </a>
            )}
            {((adminLocked && hasPARS) || (!adminLocked)) && (
              <button type="button" onClick={next} className="btn btn--purple">
                Next
              </button>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
}

ClassesStep.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mapCoursesPath: PropTypes.string.isRequired,
  mappedCoursesSize: PropTypes.number.isRequired,
  next: PropTypes.func,
  previous: PropTypes.func,
  subscriber: PropTypes.shape({
    id: PropTypes.number
  }),
  teacherSeatsLocked: PropTypes.bool,
  updateTeacherSeatsLocked: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

ClassesStep.defaultProps = {
  next: () => {},
  previous: () => {},
  subscriber: null,
  teacherSeatsLocked: false,
};
