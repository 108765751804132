import React, { useContext } from 'react';
import BuilderContext from 'components/common/Context';
import styles from './DropZone.module.scss';
import { UpdateDropZonePayload, DropZoneActions } from '../reducers/dropZoneReducer';

interface LabelFieldProps {
  id: string;
  title: string;
  item: {
    height: number;
    id: string;
    left: number;
    title: string;
    top: number;
    width: number;
  }
}

type DropZoneDispatchType = ({ payload, type }: { payload: UpdateDropZonePayload, type: DropZoneActions }) => void;

const LabelField = ({ id, title, item }: LabelFieldProps) => {
  const { dropZoneDispatch } = useContext<{ dropZoneDispatch: DropZoneDispatchType }>(BuilderContext);
  const updateTitle = (value: string) => dropZoneDispatch({ payload: { ...item, title: value }, type: 'UPDATE' });
  const ensureValue = (e: React.FocusEvent<HTMLTextAreaElement>) => !e.target.value && updateTitle(id.toUpperCase());

  return (
    <textarea
      className={styles.label}
      id={id}
      value={title}
      onChange={e => updateTitle(e.target.value)}
      onKeyDown={e => e.stopPropagation()}
      onBlur={ensureValue}
    />
  );
};

export default LabelField;
