import React from 'react';
import styles from './Spinner.module.scss';

export default function Spinner() {
  return (
    <div>
      <div className={styles.container}>
        <i className={`fa fa-spinner fa-spin ${styles.spinner}`} aria-hidden="true" />
        <p className="mt15 mb0">Checking file...</p>
      </div>

      <hr />
      <footer>
        <button className="btn btn--purple" disabled>
          Upload
        </button>
      </footer>
    </div>
  );
}
