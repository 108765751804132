import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios/index';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import Modal, { Footer } from '../../../common/Modal';
import StaticRow from '../StaticRow';
import { required, integer, nonNegative, composeValidators } from '../../../common/Forms/Validators';
import SeatCountInput from '../SeatCountInput';
import { RequiredAsterisk } from '../../../common/Forms/Utils';
import { maxSeatsValidation } from '../Utils';
import showToast from '../../../common/Toast';
import TCIOnlyLock from '../../../common/TCIOnlyLock';
import formStyles from '../../../common/Classes/Form.module.scss';
import styles from './Return.module.scss';

export default class Return extends Component {
  static propTypes = {
    createReturnPath: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
    licenseName: PropTypes.string.isRequired,
    poNumber: PropTypes.string,
    staffersTablePath: PropTypes.string.isRequired,
    studentSeatsAvailable: PropTypes.number.isRequired,
    studentSeatsInUse: PropTypes.number.isRequired,
    studentsTablePath: PropTypes.string.isRequired,
    subscriptionID: PropTypes.number.isRequired,
    teacherSeatsAvailable: PropTypes.number.isRequired,
    teacherSeatsInUse: PropTypes.number.isRequired,
    updateTable: PropTypes.func.isRequired,
  };

  static defaultProps = {
    poNumber: '',
  };

  constructor(props) {
    super(props);
    this.state = { modalOpen: false, submitting: false };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._validateForm = this._validateForm.bind(this);
  }

  _openModal(e) {
    e.preventDefault();
    this.setState({ modalOpen: true });
  }

  _closeModal() {
    this.setState({ modalOpen: false });
  }

  _handleSubmit(formData) {
    this.setState({ submitting: true }, () => { this._submitReturn(formData); });
  }

  _submitReturn(formData) {
    const submitData = Object.assign({ original_subscription_id: this.props.subscriptionID }, formData);

    Axios
      .post(this.props.createReturnPath, submitData)
      .then((response) => {
        this.props.updateTable(response.data.data, 'add');
        this.props.updateTable(response.data.data, 'update');
        showToast('Return successful.');
        this.setState({ modalOpen: false, submitting: false });
      })
      .catch(error => console.log(error));
  }

  _validateForm(values) {
    const studentMaxSeatsValidation = maxSeatsValidation(this.props.studentSeatsAvailable, 'return');
    const teacherMaxSeatsValidation = maxSeatsValidation(this.props.teacherSeatsAvailable, 'return');

    const studentSeatFieldValidator = composeValidators(required, integer, nonNegative, studentMaxSeatsValidation);
    const teacherSeatFieldValidator = composeValidators(required, integer, nonNegative, teacherMaxSeatsValidation);

    const errors = {};

    if (this.props.studentSeatsAvailable !== 0) {
      errors.student_seats_count = studentSeatFieldValidator(values.student_seats_count);
    }

    if (this.props.teacherSeatsAvailable !== 0) {
      errors.teacher_seats_count = teacherSeatFieldValidator(values.teacher_seats_count);
    }

    return errors;
  }

  _expirationDate() {
    return moment(this.props.expirationDate, 'YYYY-MM-DD').format('MMMM D, YYYY');
  }

  _renderNotesField() {
    return (
      <div className={`${formStyles.row}`}>
        <div className={`${formStyles.fieldContainer} ${styles.notesRowAlignment}`}>
          <div>
            <label className={`${formStyles.label} mt10`} htmlFor="notesField">
              Notes <RequiredAsterisk />
            </label>
          </div>
          <Field
            className={`${styles.field} ${styles.notesField}`}
            component="textarea"
            id="notesField"
            name="note"
            placeholder="Please enter reason and/or requestor info."
            validate={required}
          />
        </div>
      </div>
    );
  }

  _renderForm() {
    return (
      <Form
        onSubmit={this._handleSubmit}
        validate={this._validateForm}
        render={({
          handleSubmit, hasValidationErrors
        }) => (
          <form onSubmit={handleSubmit}>
            {this._renderStaticInfo()}

            <SeatCountInput
              id="teacher-seats"
              name="teacher_seats_count"
              label="Teacher Subs"
              seatsAvailable={this.props.teacherSeatsAvailable}
              seatsInUse={this.props.teacherSeatsInUse}
              tablePath={this.props.staffersTablePath}
              licenseName={this.props.licenseName}
            />

            <SeatCountInput
              id="student-seats"
              name="student_seats_count"
              label="Student Subs"
              seatsAvailable={this.props.studentSeatsAvailable}
              seatsInUse={this.props.studentSeatsInUse}
              tablePath={this.props.studentsTablePath}
              licenseName={this.props.licenseName}
            />

            {this._renderNotesField()}

            <Footer
              secondaryButtonCallback={this._closeModal}
              primaryButtonDisabled={hasValidationErrors}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }

  _renderModal() {
    return (
      <Modal
        closeModal={this._closeModal}
        isOpen={this.state.modalOpen}
        headerText="Return"
      >
        {this._renderForm()}
      </Modal>
    );
  }

  _renderStaticInfo() {
    return (
      <div>
        <StaticRow
          label="PO Number"
          data={this.props.poNumber}
        />
        <StaticRow
          label="License"
          data={this.props.licenseName}
        />
        <StaticRow
          label="Expires On"
          data={this._expirationDate()}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this._renderModal()}
        <a
          onClick={this._openModal}
          className="dropdown-item"
          href="#"
          role="button"
        >
          <i aria-hidden="true" className="fa fa-undo" />
          Return
          {' '}
          <TCIOnlyLock />
        </a>
      </div>
    );
  }
}
