import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ActionBar from 'shared/LessonOverview/Cards/ActionBar';
import styles from 'shared/LessonOverview/Staffer/TeacherCard.module.scss';

function TeacherCard({ slideShow, programId, lessonId }) {
  return (
    <div className={`card ${styles.card}`}>
      <div className={styles.banner}>
        {I18n.t(slideShow.activityType).toUpperCase()}
      </div>
      <div className={styles.header}>
        {renderThumbnail(slideShow)}
        <div className={styles.title}>
          <a href={slideShow.links.presentPath} target="_blank" rel="noopener noreferrer">{slideShow.truncatedTitle}</a>
        </div>
      </div>

      <ActionBar
        slideShow={slideShow}
        isStaffer={false}
        programId={programId}
        lessonId={lessonId}
      />
    </div>
  );
}

function renderThumbnail(slideShow) {
  if (slideShow.staffer_initials) {
    return (
      <div className={styles.thumbnail}>
        {slideShow.staffer_initials}
      </div>
    );
  }

  return <div className={`${styles.thumbnail} ${styles.tciAvatar} tci-logo-white`} />;
}

TeacherCard.propTypes = {
  lessonId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  slideShow: PropTypes.shape({
    activityType: PropTypes.string,
    links: PropTypes.shape({
      presentPath: PropTypes.string.isRequired
    }),
    staffer_initials: PropTypes.string,
    truncatedTitle: PropTypes.string.isRequired,
  })
};

TeacherCard.defaultProps = {
  slideShow: {
    staffer_initials: null,
  }
};

export default TeacherCard;
