import create from 'zustand';

export type Video = {
  es_url?: string;
  id: number;
  poster_es_url?: string;
  poster_url: string;
  url: string;
  use_en_poster: boolean;
  video_es_file_name?: string;
  video_file_name: string;
};

interface IVideoPosterStore {
  enPoster?: HTMLCanvasElement;
  esPoster?: HTMLCanvasElement;
  setEnPoster: (nextState: HTMLCanvasElement) => void;
  setEsPoster: (nextState: HTMLCanvasElement) => void;
  setVideo: (nextState: Video) => void;
  setUseEnPoster: (useEnPoster: boolean) => void;
  useEnPoster: boolean;
  video?: Video;
}

const useVideoPosterStore = create<IVideoPosterStore>(set => ({
  enPoster: null,
  esPoster: null,
  setEnPoster: enPoster => set({ enPoster }),
  setEsPoster: esPoster => set({ esPoster }),
  setUseEnPoster: useEnPoster => set({ useEnPoster }),
  setVideo: video => set({
    enPoster: null,
    esPoster: null,
    useEnPoster: video.use_en_poster,
    video,
  }),
  useEnPoster: false,
  video: null,
}));

export default useVideoPosterStore;
