import { flattenDeepArray } from '../../../modules/TCIUtils';
import { gql } from '@apollo/client';

/**
 * Given an array of checkbox values (strings) for assignables, return an
 * array of attributes for assignments_models to be created.
 * @param modelsToAssign Array of strings, where strings are in the format of
 *   `${model_type}-${model_id}[,${model_type}-${model_id}]*`.
 * @returns [] Array of { model_type:, model_id } objects.
 */
export const buildAssignmentsModels = (modelsToAssign, lessonId) => {
  if (modelsToAssign && modelsToAssign.length) {
    return flattenDeepArray(
      [modelsToAssign].flat().map(str => (
        str.split(',').map((modelStr) => {
          const model = modelStr.split('-');

          if (model[2] && model[2].includes('Unit')) { // Assigning unit projects
            return {
              model_id: model[1],
              model_type: model[0],
              unit_id: model[2].replace('Unit', '')
            };
          }

          if (model[2]) { // assigning investigation fast track or master
            return {
              data: {
                fast_track: model[2] === 'fast'
              },
              lesson_id: lessonId,
              model_id: model[1],
              model_type: model[0]
            };
          }

          return {
            data: {},
            lesson_id: lessonId,
            model_id: model[1],
            model_type: model[0],
          };
        })
      ))
    );
  }

  return [];
};

export const nextJune30 = () => {
  const currentYear = new Date().getFullYear();
  const june30 = year => new Date(year, 5, 30);

  return june30(currentYear) > new Date() ? june30(currentYear) : june30(currentYear + 1);
};

export const sortByLastNameFirstName = (a, b) => {
  const collator = new Intl.Collator('en', { sensitivity: 'base' });

  const lastNameCompare = collator.compare(a.lastName, b.lastName);
  if (!lastNameCompare) return collator.compare(a.firstName, b.firstName);
  return lastNameCompare;
};

export const GET_ASSIGNMENT_MODAL_DATA = gql`
  query GetAssignmentModalData($programId: ID!, $stafferId: ID) {
    program(id: $programId) {
      displayInvestigations,
      displayInvestigationsTextSection,
      displayUnitContent,
      hasVideoActivity,
      id,
      middleSchool,
      science,
      lessons {
        id,
        titleEn,
        position(programId: $programId)
      }
    },
    staffer(id: $stafferId) {
      assessments(forProgram: $programId, nonEmpty: true) {
        id, name, lessonId
      },
      classrooms(withProgram: $programId) {
        id,
        period,
        name
      }
      connectedToCanvas
      connectedToGoogleClassroom
      connectedToCanvas13
      subscriberConnectedToCanvas
    }
  }
`;

export const GET_CLASSROOM_DATA = gql`
  query GetClassroomData($programId: ID!, $stafferId: ID) {
    staffer(id: $stafferId) {
      classrooms(withProgram: $programId) {
        id,
        period,
        name
      }
      connectedToCanvas
      connectedToGoogleClassroom
      connectedToCanvas13
      subscriberConnectedToCanvas
    }
  }
`;
