import create from 'zustand';
import { ILessonGamesGlossaryTerm } from '../components/admin/Games/interfaces';
import GameTemplate from '../components/interfaces/GameTemplate';

interface ILessonGamesStoreVariables {
  gameTemplates: GameTemplate[];
  glossaryTerms: ILessonGamesGlossaryTerm[];
  lessonId?: number;
  programId?: number;
  refetch: () => void;
}

interface ILessonGamesStoreState extends ILessonGamesStoreVariables {
  setStore: (newState: ILessonGamesStoreVariables) => void;
}

const useLessonGamesStore = create<ILessonGamesStoreState>(set => ({
  gameTemplates: [],
  glossaryTerms: [],
  lessonId: undefined,
  programId: undefined,
  refetch: () => { },
  setStore: newState => set(state => ({ ...state, ...newState })),
}));

export default useLessonGamesStore;
