import React from 'react';
import ButtonSnippet from '@/components/common/HtmlElements/ButtonSnippet';
import IVideoButton from '@/components/interfaces/graphql/HtmlElement/VideoButton';

interface SnippetProps {
  videoButton: IVideoButton;
}

const Snippet = ({
  videoButton: { id, type, data: { backgroundColor = 'purple', buttonTextEn, buttonTextEs } },
}: SnippetProps) => (
  <ButtonSnippet
    backgroundColor={backgroundColor}
    buttonTextEn={buttonTextEn}
    buttonTextEs={buttonTextEs}
    id={id}
    type={type}
  />
);

export default Snippet;
