import React, { useState } from 'react';
import I18n from '@/modules/i18n';
import { SubmitModal, useModalManager } from '@/components/common/Modal';
import { capitalize } from '@/components/common/Utils';
import { GradingBy } from './types';
import { GradeByMenu } from '../GradeByMenu';
import SwitchGradeModeConfirmationText from './SwitchGradeModeConfirmationText';

interface NotebookGradeByMenuProps {
  initialGradingBy: GradingBy;
  lessonTitle: string;
}

const NotebookGradeByMenu = ({ initialGradingBy, lessonTitle }: NotebookGradeByMenuProps) => {
  const [gradingBy, setGradingBy] = useState(initialGradingBy);
  const modalManager = useModalManager();

  const handleSelect = (value: GradingBy) => {
    setGradingBy(value);
    modalManager.open();
  };

  return (
    <>
      <GradeByMenu handleSelect={handleSelect} />

      <SubmitModal
        closeAfterSubmit={false}
        closeModal={modalManager.close}
        headerText={`${I18n.t('grade_by')} ${capitalize(I18n.t(gradingBy))}`}
        isOpen={modalManager.isOpen}
        method="post"
        submitPath={''}
      >
        <SwitchGradeModeConfirmationText
          gradingBy={gradingBy}
          lessonTitle={lessonTitle}
        />
      </SubmitModal>
    </>
  );
};

export default NotebookGradeByMenu;
