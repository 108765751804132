import React from 'react';
import { WrapperFunction } from '../SectionEditorUtils';
import DropdownMenu from './DropdownMenu';
import DropdownOption from './DropdownOption';
import Display from './icons/Display';

const BREAK_TEXT_CLASS = 'break-text';

interface DisplayDropdownProps {
  element: HTMLElement;
  wrapper: WrapperFunction;
}

const DisplayDropdown = ({ element, wrapper }: DisplayDropdownProps) => {
  const setDisplay = wrapper((nextDisplay: 'inline' | 'break-text') => {
    element.classList.remove(BREAK_TEXT_CLASS);

    if (nextDisplay === 'inline') return;

    element.classList.add(BREAK_TEXT_CLASS);
  });

  return (
    <DropdownMenu
      content={<Display />}
      height={76}
      left={100}
      id="display"
      title="Display"
    >
      <DropdownOption onClick={() => setDisplay('inline')} title="Left">
        Inline
      </DropdownOption>
      <DropdownOption onClick={() => setDisplay('break-text')} title="None">
        Break Text
      </DropdownOption>
    </DropdownMenu>
  );
};

export default DisplayDropdown;
