import PropTypes from 'prop-types';
import React from 'react';
import styles from './DraggableChoiceEditor.module.scss';

const AddImageButton = ({ title }) => {
  const label = `Add image to draggable choice: ${title}`;

  return (
    <div className={styles.addImageButtonContainer}>
      <button
        type="button"
        className="btn btn--green"
        onClick={() => {}}
        aria-label={label}
      >
        Add Image
      </button>
    </div>
  );
};

AddImageButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AddImageButton;
