import React, { useEffect, useState } from 'react';
import Popover from '@/components/ui/Popover';
import cn from '@/utils/cn';
import IHotspot from '@/components/interfaces/Hotspot';
import usePanoramicImageStore
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectPanoramicImage/PanoramicImage/usePanoramicImageStore';
import locationIcon from './icons/Location_Normal.svg';

interface HotspotProps {
  hotspot: IHotspot;
  hotspotClassName?: string;
  locale?: 'en' | 'es';
}

const Hotspot = ({ hotspot, hotspotClassName, locale }: HotspotProps) => {
  const panoramicContainer = usePanoramicImageStore(state => state.container);

  return (
    <Popover>
      <Popover.Trigger asChild>
        <button
          type="button"
          key={hotspot.id}
          className={cn(
            'tw-cursor-pointer tw-bg-transparent tw-border-0',
            `hotspot-${hotspot.id}`,
            hotspotClassName,
          )}
          tabIndex={0}
        >
          <img src={locationIcon} alt="pin" style={{ width: '32px', height: '32px' }} />
        </button>
      </Popover.Trigger>
      <Popover.Content
        className={`tw-bg-white !tw-rounded-sm tw-text-base fr-box fr-basic tw-w-[330px]
                    tw-box-border tw-max-h-[300px] tw-overflow-auto hotspot-content-container`}
        container={document.fullscreenElement ? panoramicContainer : document.body}
      >
        <div className="hotspot-content fr-element fr-view">
          <div
            dangerouslySetInnerHTML={{ __html: locale === 'es' ? hotspot.hotspotContent_es : hotspot.hotspotContent }}
          />
        </div>
      </Popover.Content>
    </Popover>
  );
};
export default Hotspot;
