/* eslint-disable no-param-reassign */

const Select = (canvas) => {
  canvas.freeDrawingBrush = null;
  canvas.isDrawingMode = false;
  canvas.defaultCursor = 'default';
  canvas.hoverCursor = 'move';
  canvas.moveCursor = 'move';
};

export default Select;
