import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import CreatableSelect from 'react-select/lib/Creatable';
import Axios from 'axios';
import PropTypes from 'prop-types';

import { Footer } from '../../../common/Modal';
import { renderErrors, SubmitError } from '../../../common/Forms/Utils';
import { checkArrayEquality } from '../../../../modules/TCIUtils';
import getCodeSelectOptions from '../FormUtils';
import styles from '../Form.module.scss';

export default class EditForm extends Component {
  static propTypes = {
    allDistrictCourseCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    closeModal: PropTypes.func.isRequired,
    getDistrictCourseCodesForProgram: PropTypes.func.isRequired,
    programCode: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    subscriberId: PropTypes.number.isRequired,
    updateTable: PropTypes.func.isRequired,
    updatePath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      savedCodes: [],
      selectedCodes: [],
      submitting: false
    };
  }

  componentDidMount() {
    this._setDefaultValues();
  }

  handleSubmit = async (values) => {
    const requestData = this._processDataForSubmission(values);

    this.setState({ submitting: true });

    return Axios
      .post(this.props.updatePath, requestData)
      .then((response) => {
        this.props.updateTable(response.data);
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  // Determine which courses to add and remove
  _processDataForSubmission(values) {
    const requestData = values;
    const selectedCourses = values.course_numbers.map(c => c.value);
    const savedCourses = this.state.savedCodes.map(c => c.value);

    requestData.courses_to_add = selectedCourses.filter(c => !savedCourses.includes(c));
    requestData.courses_to_remove = savedCourses.filter(c => !selectedCourses.includes(c));

    return requestData;
  }

  _setDefaultValues() {
    const mappedCodes = this.props.getDistrictCourseCodesForProgram(this.props.programCode);
    const savedCodes =
      getCodeSelectOptions(this.props.allDistrictCourseCodes).filter(option => (mappedCodes.includes(option.value)));
    this.setState({ savedCodes, selectedCodes: savedCodes });
    return savedCodes;
  }

  render() {
    return (
      <Form
        initialValues={{
          course_numbers: this.state.savedCodes,
          program_code: this.props.programCode,
          subscriber_id: this.props.subscriberId
        }}
        mutators={{
          handleSelect: (args, state, utils) => {
            this.setState({ selectedCodes: args[0] });
            utils.changeValue(state, 'course_numbers', () => args[0]);
          }
        }}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, pristine, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="program"
              render={() => (
                <div className={styles.formRow}>
                  <label className={styles.label} htmlFor="edit-program">
                    Program
                  </label>

                  <input
                    className={`${styles.input} ${styles.textInput}`}
                    id="edit-program"
                    type="text"
                    value={this.props.programName}
                    readOnly
                  />
                </div>
              )}
            />

            <Field
              isEqual={(a, b) => checkArrayEquality(a, b)}
              name="course_numbers"
              render={() => (
                <div className={styles.formRow}>
                  <label
                    className={styles.label}
                    htmlFor="edit-district-course-codes"
                  >
                    District Course Code
                  </label>

                  <CreatableSelect
                    className={styles.input}
                    id="edit-district-course-codes"
                    value={this.state.selectedCodes}
                    onChange={form.mutators.handleSelect}
                    multi
                    options={getCodeSelectOptions(this.props.allDistrictCourseCodes)}
                  />
                </div>
              )}
            />

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={pristine}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }
}
