import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalButton } from '../Utils';

export default class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <ModalButton
        newRecord
        buttonClass={this.props.buttonClasses}
        openModal={this.openModal}
        closeModal={this.closeModal}
        removeObject={this.props.removeObject}
        onAdd={this.props.onAdd}
        onUpdate={this.props.onUpdate}
        units={this.props.units}
        FormComponent={this.props.FormComponent}
        modalIsOpen={this.state.modalIsOpen}
        safetyNotes={this.props.safetyNotes}
        {...this.props.buttonAttributes}
      />
    );
  }
}

Actions.defaultProps = {
  buttonClasses: 'btn btn--purple',
  units: [],
  safetyNotes: [],
  buttonAttributes: []
};

Actions.propTypes = {
  buttonClasses: PropTypes.string,
  removeObject: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.object),
  FormComponent: PropTypes.element.isRequired,
  safetyNotes: PropTypes.arrayOf(PropTypes.object),
  buttonAttributes: PropTypes.arrayOf(PropTypes.object)
};
