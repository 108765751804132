import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import styles from "./Unit.module.scss";

const PerformanceExpectations = ({ content }) => {
  if (!content) return null;

  return (
    <Fragment>
      <div className={styles.sectionHeader}>
        {I18n.t('performance_expectations')}:
      </div>
      <p>{content}</p>
    </Fragment>
  );
};

PerformanceExpectations.propTypes = {
  content: PropTypes.string
};

PerformanceExpectations.defaultProps = {
  content: null
};

export default PerformanceExpectations;
