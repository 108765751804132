import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { INotebookData } from '../../../types';

const useSectionNotebooksQuestionAnswers = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const isEnabled = !!(classroom && lesson);

  const query = useQuery([QueryKeys.SectionNotebooksQuestionAnswers, classroom.id, lesson?.id], () => {
    const path = Routes.plato_api_all_section_notebook_progress_question_answers_path({
      classroom_id: classroom.id,
      lesson_id: lesson?.id,
    });

    return axios
      .get<{ data: INotebookData }>(path)
      .then(res => res.data)
      .catch(err => console.error(err));
  }, { enabled: isEnabled });

  return { ...query, data: query.data || [] };
};

export default useSectionNotebooksQuestionAnswers;
