import React, { CSSProperties } from 'react';
import ReactDOMServer from 'react-dom/server';

export const UNITS = {
  X: {
    FORTY_FIVE: 71,
    THIRTEEN: 20.5,
    TWO: 10,
    VOCAB_TOP: 3.69,
  },
  Y: {
    THREE: 5,
    CIRCLE_OFFSET: 20,
  },
};

const getBackgroundSize = (fit) => {
  switch (fit) {
    case 'stretch':
      return '100% 100%';
    default:
      return fit;
  }
};

export const backgroundStyle = (background) => {
  const backgroundImage = background && background.url
    ? `url(${background.url.replaceAll("'", "\\'")})`
    : '';

  const backgroundPosition = background && background.position;
  const setBackgroundSize = background && background.size;

  if (!backgroundPosition && !setBackgroundSize) {
    return { backgroundImage };
  }

  const [backgroundPositionX, positionY] = backgroundPosition.split(' ');
  const isCustom = positionY.match(/\d+/g);
  const backgroundPositionY = `${positionY}${isCustom ? '%' : ''}`;

  const backgroundSize = setBackgroundSize ? `${getBackgroundSize(background.size)}` : '';

  return ({
    backgroundImage,
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
  });
};

export const positionedContainerStyle = (left: number, top: number, width: number, height: number) => ({
  opacity: 1,
  position: 'absolute',
  left: `${left}%`,
  top: `${top}%`,
  width: `${width}%`,
  height: `${height}%`,
} as CSSProperties);

// TODO: this will be updated later when styling reqs are updated
export const sizedContainerStyle = (hasChildren: boolean = false) => ({
  position: 'relative',
  userSelect: 'auto',
  width: '100%',
  height: hasChildren ? 'auto' : '100%',
  boxSizing: 'border-box',
} as CSSProperties);

export const resizeWithinContainer = (elementRef, containerWidth, containerHeight) => {
  if (!elementRef || !elementRef.current || !containerHeight || !containerWidth) return;

  const height = elementRef.current.clientHeight;

  if (height > containerHeight) {
    $(elementRef.current).css({ transform: `scale(${containerHeight / height})` });
  }
};

type Term = { definition: string, id: number, term: string };

export const formatVocab = (vocabTerms: Term[]) => vocabTerms.map(term => ({
  definition: term.definition,
  label: <div><strong>{term.term}:</strong> <span dangerouslySetInnerHTML={{ __html: term.definition }} /></div>,
  term: term.term,
  value: term.id,
  vocabDefinition: <div>{term.definition}</div>,
  vocabTerm: <div>{term.term}</div>,
}));

export const getTextContent = (vocabId, vocabularyTerms, vocabLoading, text, valueToReturn) => {
  if (vocabId && !vocabLoading) {
    const vocabTerm = vocabularyTerms.find(vocab => vocab.value === vocabId);
    if (!vocabTerm) return 'Undefined Vocabulary Term';
    switch (valueToReturn) {
      case 'term':
        return vocabTerm.term;
      case 'definition':
        return vocabTerm.definition;
      default:
        return ReactDOMServer.renderToStaticMarkup(vocabTerm.label);
    }
  }

  return text;
};

export const getTextAreaNode = (id: string) => {
  const label = `Text area ${id.toUpperCase()}`;
  return document.querySelector(`[aria-label="${label}"]`);
};

export const getDimensions = (shape: string, height: number, width: number) => {
  // Will return the same height and width for when the dropzone shape is circular to create circles instead of ovals
  if (shape === 'circle') {
    return {
      // To get a perfect circle, we have to use the ratio of our builder's width to height, 640/540 = 1.185.
      height: width * 1.185,
      width,
    };
  }

  // Regular dimensions for rectangles since they aren't limited to a specific shape.
  return {
    height,
    width,
  };
};
