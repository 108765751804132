import Axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { plato_api_panoramic_image_path } from '@/modules/routes';
import IHotspot from '@/components/interfaces/Hotspot';

const useUpdateHotspotsMutation = (id: number | string) => (
  useMutation({
    mutationFn: async (values: IHotspot[]) => {
      const formData = new FormData();

      if (values) {
        formData.append('panoramic_image[hotspots]', JSON.stringify(values));
      }

      const hotspotsRequest = await Axios.patch(
        plato_api_panoramic_image_path(id),
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      return hotspotsRequest.data;
    },
    mutationKey: ['useUpdateHotspotsMutation', id],
  })
);

export default useUpdateHotspotsMutation;
