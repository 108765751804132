import React from 'react';
import ILesson from '@/types/api/Lessons';
import useProgramContentStore from './useProgramContentStore';
import ContentInputs from './ContentInputs';

interface LessonInputsProps {
  lesson: ILesson;
}

const LessonInputs = ({ lesson }: LessonInputsProps) => {
  const { id, title_with_position: title } = lesson;
  const reuseIds = useProgramContentStore(state => state.reuseLessonIds);
  const copyIds = useProgramContentStore(state => state.copyLessonIds);
  const addId = useProgramContentStore(state => state.addLessonId);
  const removeId = useProgramContentStore(state => state.removeLessonId);
  const isChecked = reuseIds.includes(id) || copyIds.includes(id);

  return (
    <ContentInputs
      id={id}
      title={title}
      reuseIds={reuseIds}
      copyIds={copyIds}
      addId={addId}
      removeId={removeId}
      isChecked={isChecked}
      onCheck={() => {
        if (isChecked) {
          removeId('copy', id);
          removeId('reuse', id);
        } else {
          addId('copy', id);
        }
      }}
    />
  );
};

export default LessonInputs;
