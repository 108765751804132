/**
 * `Slot` Component
 *
 * A utility component that allows you to wrap and enhance its child component with additional
 * props and styles. The `Slot` component is designed to merge its own props with those of its
 * child component, providing a flexible way to extend or override the properties of the child.
 *
 * @component
 *
 * Props:
 * - `children` (React.ReactNode): The child component to be wrapped and enhanced by the `Slot` component.
 * - Any other valid HTML attributes for an HTMLElement.
 *
 * Example usage:
 *
 * ```jsx
 * <Slot className="custom-class" style={{ color: 'blue' }}>
 *   <button>Click Me</button>
 * </Slot>
 * ```
 *
 * In this example, the `Slot` component will merge the `className` and `style` props with those
 * of the child `button` element. The resulting `button` will have both the `custom-class` class
 * and the blue text color applied.
 *
 * Implementation details:
 * - If the `children` prop contains a valid React element, the `Slot` component will clone the
 *   element and merge its own props with those of the child element. The styles and class names
 *   are combined using the `twMerge` utility function.
 * - If the `children` prop contains more than one child, the `Slot` component will throw an
 *   error by calling `React.Children.only(null)`.
 * - If the `children` prop is not a valid React element or contains more than one child, the
 *   `Slot` component will return `null`.
 *
 * Note:
 * - The `twMerge` utility function is used to merge the class names, ensuring that there are no
 *   duplicate class names and that the final class name string is optimized.
 */

import React from 'react';
import { twMerge } from '@/utils';

type SlotProps = React.HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode
};

const Slot = ({ children, ...props }: SlotProps) => {
  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...props,
      ...children.props,
      style: {
        ...props.style,
        ...children.props.style,
      },
      className: twMerge(
        props.className,
        children.props.className,
      ),
    });
  }

  if (React.Children.count(children) > 1) {
    React.Children.only(null);
  }

  return null;
};

export default Slot;
