import React, { MutableRefObject, useRef } from 'react';
import Toolbar from './Toolbar';
import useListeners from './hooks/useListeners';
import {
  useCanvas, useModes, useColors, useCanvasResizer, useQuestionAnswer,
} from './hooks';
import ActionBar from './ActionBar';
import Spinner from './Spinner';
import QuestionText from './QuestionText';
import useDrawingContext from './store/useDrawingContext';
import ShortcutsModal from './ShortcutsModal/ShortcutsModal';

interface DrawingProps {
  resizeContainer?: MutableRefObject<HTMLDivElement>;
}

const Drawing = ({
  resizeContainer,
}: DrawingProps) => {
  const disabled = useDrawingContext(state => state.disabled);
  const questionId = useDrawingContext(state => state.questionId);
  const mode = useDrawingContext(state => state.mode);
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const containerEl = useRef<HTMLDivElement>(null);
  const questionTextEl = useRef<HTMLDivElement>(null);
  const color = useDrawingContext(state => state.color);
  const penWidth = useDrawingContext(state => state.penWidth);
  const penOpacity = useDrawingContext(state => state.penOpacity);
  const eraserWidth = useDrawingContext(state => state.eraserWidth);

  const canvas = useCanvas({ canvasEl });
  const { containerWidth, containerHeight } = useCanvasResizer({ canvas, questionTextEl, toolbarEl, resizeContainer });
  const { isLoading } = useQuestionAnswer({ canvas });
  useListeners({ canvas });
  useModes({ canvas, eraserWidth, mode, color: (color as string), penWidth, penOpacity });
  useColors({ canvas, color: (color as string) });

  const guideStyles = { marginTop: questionTextEl.current?.offsetHeight };

  return (
    <div
      className="tw-h-full tw-flex tw-flex-row tw-relative"
      ref={containerEl}
    >
      <Toolbar />
      <ShortcutsModal parentContainer={containerEl} />
      <div className="tw-shadow-md tw-bg-white tw-relative tw-w-full">
        {(isLoading) && (
          <div className="tw-absolute tw-w-full tw-h-full tw-bg-white tw-z-10">
            <Spinner />
          </div>
        )}
        {(questionId !== 0) && !disabled && (
          <QuestionText
            questionTextEl={questionTextEl}
          />
        )}
        {!disabled && (
          <>
            <div
              className="guide"
              id="guide-v"
              style={guideStyles}
            />
            <div
              className="guide"
              id="guide-h"
              style={guideStyles}
            />
          </>
        )}
        <canvas
          width={containerWidth}
          height={containerHeight}
          ref={canvasEl}
        />
      </div>
      {!disabled && (
        <ActionBar />
      )}
    </div>
  );
};

export default Drawing;
