import React from 'react';
import PropTypes from 'prop-types';

export default function MultipleChoiceOption({
  id, input, label, meta, type, disabled, ...props // `input` and `meta` are FieldRenderProps
}) {
  const _input = {
    ...input,
    onChange: (e) => {
      if (props.onChange) props.onChange(e, input);
      input.onChange(e);
    }
  };

  return (
    <div className={`mc-flex-container ${props.optionContainerStyle || ''}`}>
      <div className="mc-question-button-input">
        <input disabled={disabled} data-dirty={meta.dirty} id={id} type={type} {..._input} />
      </div>
      <label className={props.optionLabelStyle || ''} htmlFor={id}>
        <li dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </div>
  );
}

MultipleChoiceOption.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired
  }).isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired
};

MultipleChoiceOption.defaultProps = { disabled: false, onChange: null };
