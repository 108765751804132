import React from 'react';
import cn from '@/utils/cn';
import useGradeByMethods from '@/components/staffer/NotebookGradeBySwitch/useGradeByMethods';

interface NotebookGradeByButtonProps {
  afterSubmitCallback?: (res: any) => any;
  className?: string;
  classroomId: number;
  classroomLessonId?: number;
  gradingByLesson?: boolean;
  lessonId: number;
  lessonTitle: string;
}

const NotebookGradeByButton = ({
  afterSubmitCallback = () => {},
  className = '',
  classroomId,
  classroomLessonId = null,
  gradingByLesson = true,
  lessonId,
  lessonTitle,
}: NotebookGradeByButtonProps) => {
  const {
    initialGradingByLesson,
    openGradeByLessonModal,
    openGradeBySectionModal,
    renderModal,
  } = useGradeByMethods({
    classroomLessonId,
    gradingByLesson,
    classroomId,
    afterSubmitCallback,
    lessonId,
    lessonTitle,
  });

  const handleClick = () => (initialGradingByLesson ? openGradeBySectionModal() : openGradeByLessonModal());

  return (
    <>
      <button
        className={cn('tw-cursor-pointer', className)}
        onClick={handleClick}
        type="button"
      >
        <i className="fa fa-edit" />
        {initialGradingByLesson ? 'Grade By Section' : 'Grade By Lesson'}
      </button>
      {renderModal()}
    </>
  );
};

export default NotebookGradeByButton;
