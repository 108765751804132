import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'react-cookies';

export function ToggleButtonGroup({ ...props }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    if (props.useCookie) {
      const index = parseInt(Cookie.load(props.id) || -1, 10);
      setActiveIndex(index);
      if (index > -1) props.children[index].props.onToggle();
    }
  }, []);

  const renderButton = (child, index) => {
    const buttonProps = {
      active: activeIndex === -1 ? child.props.active : index === activeIndex,
      key: `toggle-option-${index}`,
      onToggle: () => {
        setActiveIndex(index);
        if (props.useCookie) Cookie.save(props.id, index);
        child.props.onToggle();
      }
    };

    return React.cloneElement(child, buttonProps);
  };

  return (
    <div className={props.className}>
      <label htmlFor={props.id} className="mb5">{props.labelText}</label>
      <div id={props.id} className="btn-group" role="group" aria-label={props.labelText}>
        {props.children.map(renderButton)}
      </div>
    </div>
  );
}

ToggleButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  useCookie: PropTypes.bool
};

ToggleButtonGroup.defaultProps = {
  useCookie: false
};

export function ToggleOption({ ...props }) {
  return (
    <button
      type="button"
      className={`btn btn--sm ${props.active ? 'btn--purple' : 'btn--outline-purple'}`}
      onClick={() => { if (!props.active) props.onToggle(); }}
    >
      {props.buttonText}
    </button>
  );
}

ToggleOption.propTypes = {
  active: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired
};

ToggleOption.defaultProps = {
  active: false
};
