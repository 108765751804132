import React from 'react';
import Key from './Key';
import useDrawingContext from '../store/useDrawingContext';
import { scaleValue } from '../Toolbar/styles';

export const detectMac = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const ShortcutList = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  type Shortcut = [string, string[]];
  const winShortcuts: Shortcut[] = [
    ['Move:', ['←', '↑', '↓', '→']],
    ['Delete:', ['Backspace']],
    ['Copy:', ['Ctrl', 'C']],
    ['Paste:', ['Ctrl', 'V']],
    ['Cut:', ['Ctrl', 'X']],
    ['Duplicate:', ['Ctrl', 'D']],
    ['Undo:', ['Ctrl', 'Z']],
    ['Redo:', ['Ctrl', 'Shift', 'Z']],
  ];
  const macShortcuts: Shortcut[] = [
    ['Move:', ['←', '↑', '↓', '→']],
    ['Delete:', ['Backspace']],
    ['Copy:', ['Cmd', 'C']],
    ['Paste:', ['Cmd', 'V']],
    ['Cut:', ['Cmd', 'X']],
    ['Duplicate:', ['Cmd', 'D']],
    ['Undo:', ['Cmd', 'Z']],
    ['Redo:', ['Cmd', 'Shift', 'Z']],
  ];

  return (
    <div
      className="tw-pt-2 tw-pb-6 tw-px-10 "
      style={{
        paddingLeft: `${scaleValue(toolbarEl, 1.5, 2.5)}rem`,
        paddingRight: `${scaleValue(toolbarEl, 1.5, 2.5)}rem`,
      }}
    >
      {detectMac() && macShortcuts && macShortcuts.map(shortcut => (
        <div
          key={`${shortcut[0]}_div`}
          className="tw-flex tw-items-center tw-gap-x-2 tw-mb-3"
          style={{
            marginBottom: `${scaleValue(toolbarEl, 0.5, 0.75)}rem`,
          }}
        >
          <p className="tw-m-0">{shortcut[0]}</p>
          {shortcut[1] && shortcut[1].map(keyName => <Key key={`${keyName}_key`} keyName={keyName} />)}
        </div>
      ))}
      {!detectMac() && winShortcuts && winShortcuts.map(shortcut => (
        <div
          key={`${shortcut[0]}_div`}
          className="tw-flex tw-items-center tw-gap-x-2 tw-mb-3"
        >
          <p className="tw-m-0">{shortcut[0]}</p>
          {shortcut[1] && shortcut[1].map(keyName => <Key key={`${keyName}_key`} keyName={keyName} />)}
        </div>
      ))}
    </div>
  );
};

export default ShortcutList;
