import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as Routes from 'routes';
import Modal, { useModalManager } from 'common/Modal';

const DisconnectButton = ({ lmsType, updateState }) => {
  const modalManager = useModalManager();

  const disconnect = () => {
    if (lmsType === 'Google Classroom') {
      Axios.post(Routes.plato_api_disconnect_account_user_google_ids_path())
        .then(() => {
          updateState({
            googleConnectionData: {
              displayName: null,
              email: null,
              profilePictureUrl: null,
              user_google_id: null
            }
          });
        });
    }
    else {
      Axios.post(Routes.plato_api_disconnect_account_user_canvas_ids_path())
        .then(() => {
          updateState({
            canvasConnectionData: {
              displayName: null,
              email: null,
              profilePictureUrl: null,
              user_canvas_id: null
            }
          });
        });
    }
  };

  const accountDomain = lmsType === 'Google Classroom' ? 'Google' : 'Canvas';

  return (
    <Fragment>
      <button type="button" className="btn btn--outline-red mt20" onClick={modalManager.open}>
        <i className="fa fa-unlink" aria-hidden="true" /> Disconnect Account
      </button>

      <Modal
        headerText={`Disconnect ${accountDomain} Account`}
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
      >
        <div className="mb50">
          <p className="mb10">By disconnecting your {accountDomain} account from TCI, you will be <b>unable</b> to:</p>

          <ol className="mb10">
            {lmsType === 'Google Classroom' && <li className="mb5">Sign in to TCI with this Google account</li>}
            <li>Sync TCI assignments and grades to {lmsType}</li>
          </ol>
          <p>Are you sure you want to continue?</p>
        </div>
        <hr />
        <footer>
          <button className="btn btn--link-purple" onClick={modalManager.close} type="button">
            Cancel
          </button>
          <button className="btn btn--red" onClick={disconnect} type="button">
            Disconnect Account
          </button>
        </footer>
      </Modal>
    </Fragment>
  );
};

DisconnectButton.propTypes = {
  lmsType: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired
};

export default DisconnectButton;
