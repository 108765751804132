import React from 'react';
import I18n from 'modules/i18n';

interface BiographiesIconProps {
  ariaLabel?: string;
  color?: string;
  size?: number;
}

const BiographiesIcon = ({
  ariaLabel = I18n.t('library_biographies'),
  color = '#247F99',
  size = 125
 }: BiographiesIconProps) => (
  <svg
    aria-label={ariaLabel}
    width={size}
    height={size * 3 / 10}
    viewBox="0 0 50 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.86111 7.27273C8.82553 7.27273 9.75045 6.88961 10.4324 6.20766C11.1144 5.52571 11.4975 4.60079 11.4975 3.63636C11.4975 2.67194 11.1144 1.74702 10.4324 1.06507C9.75045 0.383116 8.82553 0 7.86111 0C6.89668 0 5.97176 0.383116 5.28981 1.06507C4.60786 1.74702 4.22474 2.67194 4.22474 3.63636C4.22474 4.60079 4.60786 5.52571 5.28981 6.20766C5.97176 6.88961 6.89668 7.27273 7.86111 7.27273ZM10.2853 3.63636C10.2853 4.27931 10.0299 4.89593 9.5753 5.35056C9.12067 5.8052 8.50406 6.06061 7.86111 6.06061C7.21816 6.06061 6.60154 5.8052 6.14691 5.35056C5.69227 4.89593 5.43686 4.27931 5.43686 3.63636C5.43686 2.99341 5.69227 2.3768 6.14691 1.92217C6.60154 1.46753 7.21816 1.21212 7.86111 1.21212C8.50406 1.21212 9.12067 1.46753 9.5753 1.92217C10.0299 2.3768 10.2853 2.99341 10.2853 3.63636ZM15.1338 13.3333C15.1338 14.5455 13.9217 14.5455 13.9217 14.5455H1.8005C1.8005 14.5455 0.588379 14.5455 0.588379 13.3333C0.588379 12.1212 1.8005 8.48485 7.86111 8.48485C13.9217 8.48485 15.1338 12.1212 15.1338 13.3333ZM13.9217 13.3285C13.9205 13.0303 13.735 12.1333 12.9132 11.3115C12.1229 10.5212 10.6357 9.69697 7.86111 9.69697C5.08535 9.69697 3.59929 10.5212 2.80898 11.3115C1.98717 12.1333 1.80292 13.0303 1.8005 13.3285H13.9217Z" fill={color} />
    <path d="M25.1341 7.27273C26.0985 7.27273 27.0234 6.88961 27.7054 6.20766C28.3873 5.52571 28.7704 4.60079 28.7704 3.63636C28.7704 2.67194 28.3873 1.74702 27.7054 1.06507C27.0234 0.383116 26.0985 0 25.1341 0C24.1696 0 23.2447 0.383116 22.5628 1.06507C21.8808 1.74702 21.4977 2.67194 21.4977 3.63636C21.4977 4.60079 21.8808 5.52571 22.5628 6.20766C23.2447 6.88961 24.1696 7.27273 25.1341 7.27273ZM27.5583 3.63636C27.5583 4.27931 27.3029 4.89593 26.8483 5.35056C26.3936 5.8052 25.777 6.06061 25.1341 6.06061C24.4911 6.06061 23.8745 5.8052 23.4199 5.35056C22.9652 4.89593 22.7098 4.27931 22.7098 3.63636C22.7098 2.99341 22.9652 2.3768 23.4199 1.92217C23.8745 1.46753 24.4911 1.21212 25.1341 1.21212C25.777 1.21212 26.3936 1.46753 26.8483 1.92217C27.3029 2.3768 27.5583 2.99341 27.5583 3.63636ZM32.4068 13.3333C32.4068 14.5455 31.1947 14.5455 31.1947 14.5455H19.0734C19.0734 14.5455 17.8613 14.5455 17.8613 13.3333C17.8613 12.1212 19.0734 8.48485 25.1341 8.48485C31.1947 8.48485 32.4068 12.1212 32.4068 13.3333ZM31.1947 13.3285C31.1934 13.0303 31.008 12.1333 30.1862 11.3115C29.3959 10.5212 27.9086 9.69697 25.1341 9.69697C22.3583 9.69697 20.8722 10.5212 20.0819 11.3115C19.2601 12.1333 19.0759 13.0303 19.0734 13.3285H31.1947Z" fill={color} />
    <path d="M42.4065 7.27273C43.3709 7.27273 44.2959 6.88961 44.9778 6.20766C45.6598 5.52571 46.0429 4.60079 46.0429 3.63636C46.0429 2.67194 45.6598 1.74702 44.9778 1.06507C44.2959 0.383116 43.3709 0 42.4065 0C41.4421 0 40.5172 0.383116 39.8352 1.06507C39.1533 1.74702 38.7702 2.67194 38.7702 3.63636C38.7702 4.60079 39.1533 5.52571 39.8352 6.20766C40.5172 6.88961 41.4421 7.27273 42.4065 7.27273ZM44.8308 3.63636C44.8308 4.27931 44.5753 4.89593 44.1207 5.35056C43.6661 5.8052 43.0495 6.06061 42.4065 6.06061C41.7636 6.06061 41.147 5.8052 40.6923 5.35056C40.2377 4.89593 39.9823 4.27931 39.9823 3.63636C39.9823 2.99341 40.2377 2.3768 40.6923 1.92217C41.147 1.46753 41.7636 1.21212 42.4065 1.21212C43.0495 1.21212 43.6661 1.46753 44.1207 1.92217C44.5753 2.3768 44.8308 2.99341 44.8308 3.63636ZM49.6792 13.3333C49.6792 14.5455 48.4671 14.5455 48.4671 14.5455H36.3459C36.3459 14.5455 35.1338 14.5455 35.1338 13.3333C35.1338 12.1212 36.3459 8.48485 42.4065 8.48485C48.4671 8.48485 49.6792 12.1212 49.6792 13.3333ZM48.4671 13.3285C48.4659 13.0303 48.2805 12.1333 47.4586 11.3115C46.6683 10.5212 45.1811 9.69697 42.4065 9.69697C39.6308 9.69697 38.1447 10.5212 37.3544 11.3115C36.5326 12.1333 36.3483 13.0303 36.3459 13.3285H48.4671Z" fill={color} />
  </svg>
);

export default BiographiesIcon;
