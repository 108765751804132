import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import Modal, { Footer } from 'components/common/Modal';

interface BulkDeleteModalProps {
  modalManager: { isOpen: boolean, open: () => void, close: () => void };
  studentIds: Set<number>;
  subscriberId: number;
  updateTable: (data: any, action: string) => void;
}

const BulkDeleteModal = ({ modalManager, studentIds, subscriberId, updateTable }: BulkDeleteModalProps) => {
  const [hasDeleteError, setHasDeleteError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const bulkDeleteText = (
    "Deleting these users will remove all access to your account from these users. " +
    `Are you sure you want to complete this action for ${studentIds.size} users?`
  )

  const renderHasDeleteError = () => (
    <div>
      <br />
      <span className="red">Error deleting students. Please reload your page and try again.</span>
    </div>
  )

  const submitDelete = () => {
    setSubmitting(true);
    Axios.post(Routes.plato_api_bulk_delete_subscriber_students_path(subscriberId), {
      student_ids: Array.from(studentIds)
    })
      .then((response) => {
        updateTable(response.data.deleted_student_ids, 'delete')
        setSubmitting(false);
        modalManager.close();
      })
      .catch((e) => {
        console.log(e);
        setHasDeleteError(true)
      });
  }

  return (
    <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText="Delete Students">
      {bulkDeleteText}
      {hasDeleteError && renderHasDeleteError()}
      <Footer
        primaryButtonCallback={submitDelete}
        primaryButtonClassName="btn btn--purple"
        primaryButtonDisabled={submitting}
        primaryButtonSubmit={false}
        primaryButtonText="Yes, delete"
        secondaryButtonCallback={modalManager.close}
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
}

export default BulkDeleteModal;
