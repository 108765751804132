/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';

import styles from './Pagination.module.scss';
import { usePagination } from './usePagination';

interface Props {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  onPageChange: (curPage: number) => void;
}

const Pagination = ({ currentPage, pageSize, totalCount, siblingCount = 1, onPageChange }: Props) => {
  if (totalCount < 1 || pageSize < 1 || totalCount <= pageSize) {
    return <></>;
  }

  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

  const lastPage = Math.ceil(totalCount / pageSize);

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <ul className={styles.container}>
      <li
        className={
          currentPage === 1 ? `${styles.nextPrev} ${styles.disabled}` : styles.nextPrev
        }
        onClick={onPrevious}
      >
        &lt;
      </li>

      {paginationRange.map((pageNumber, index) => {
        if (typeof pageNumber === 'string') {
          // eslint-disable-next-line react/no-array-index-key
          return <li key={pageNumber + index}>&#8230;</li>;
        }

        return (
          <li
            key={pageNumber}
            className={pageNumber === currentPage ? styles.active : ''}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        className={
          currentPage === lastPage ? `${styles.nextPrev} ${styles.disabled}` : styles.nextPrev
        }
        onClick={onNext}
      >
        &gt;
      </li>
    </ul>
  );
};

export default Pagination;
