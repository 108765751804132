import update from 'immutability-helper';

const addCorrectAnswer = (correctAnswers, action) => {
  const { draggableChoiceId, dropZoneId } = action.payload;

  const answersForDropZone = new Set(correctAnswers[dropZoneId]).add(draggableChoiceId);

  const newAnswers = {
    [dropZoneId]: Array.from(answersForDropZone),
  };

  return update(correctAnswers, {
    $merge: newAnswers,
  });
};

// Removes all correct answers from a given drop zone
const removeDropZoneAnswers = (correctAnswers, dropZoneId) => update(correctAnswers, { $unset: [dropZoneId] });

// Removes a correct answer from all drop zones
const removeDraggableChoiceAnswers = (correctAnswers, draggableChoiceId) => {
  const answers = { ...correctAnswers };

  Object.keys(answers).forEach((key) => {
    const index = answers[key].indexOf(draggableChoiceId);
    if (index !== -1) answers[key].splice(index, 1);
  });

  return answers;
};

// Removes a correct answer from a given drop zone
const removeDraggableChoiceAnswer = (correctAnswers, dropZoneId, draggableChoiceId) => {
  const answers = { ...correctAnswers };

  const index = answers[dropZoneId].indexOf(draggableChoiceId);
  if (index !== -1) answers[dropZoneId].splice(index, 1);

  return answers;
};

const removeCorrectAnswer = (correctAnswers, { payload: { dropZoneId, id, type } }) => {
  switch (type) {
    case 'dropZone':
      return removeDropZoneAnswers(correctAnswers, id);
    case 'draggableChoice':
      if (dropZoneId) return removeDraggableChoiceAnswer(correctAnswers, dropZoneId, id);
      return removeDraggableChoiceAnswers(correctAnswers, id);
    default:
      return correctAnswers;
  }
};

const addVocabCorrectAnswers = (correctAnswers, { payload }) => (
  payload.reduce((acc, cur) => (
    update(acc, {
      $merge: {
        [cur.id]: cur.type === 'draggableChoice' ? [] : [cur.id],
      },
    })
  ), correctAnswers)
);

const correctAnswerReducer = (correctAnswers, action) => {
  switch (action.type) {
    case 'ADD':
      return addCorrectAnswer(correctAnswers, action);
    case 'REMOVE':
      return removeCorrectAnswer(correctAnswers, action);
    case 'UPDATE_VOCAB':
      return addVocabCorrectAnswers(correctAnswers, action);
    default:
      return correctAnswers;
  }
};

export default correctAnswerReducer;
