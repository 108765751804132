import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import Wizard from 'common/Wizard';
import Info from './Steps/Info';
import LmsSelection from './Steps/LmsSelection';
import SchoologyConfiguration from './Steps/SchoologyConfiguration';
import Configuration from './Steps/Configuration';
import ProgressHeader from './Steps/ProgressHeader';
import * as constants from '../steps';
import { ltiHeaderImages } from '../Utils';
import useFlags from '../../../../../hooks/useFlags';
import CanvasConfiguration from './Steps/CanvasConfiguration';
import useCredentialStore from './useCredentialStore';

const CanvasIntegrationModal = ({
  modalManager,
  subscriberId,
  hasCompletedLTI,
  initialLmsType,
  isEditing,
}) => {
  const canvasCredentials = useCredentialStore(state => state.canvasCredentials);
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);
  const step = useCredentialStore(state => state.step);
  const setStep = useCredentialStore(state => state.setStep);

  const [seenInfo, setSeenInfo] = useState(!!(canvasCredentials?.id || ltiCredentials?.id));
  const [lmsType, setLmsType] = useState(initialLmsType);
  const { canvasLti13 } = useFlags();

  useEffect(() => {
    setStep(1);
    setSeenInfo(false);
  }, [modalManager.isOpen]);

  const progressHeader = () => {
    if (!lmsType) return <></>;

    return (
      <ProgressHeader
        completedSteps={{ 1: lmsType, 2: (step >= 2), 3: seenInfo }}
        steps={[constants.METHOD, constants.CONFIG, constants.TEACHER_INFO]}
        headerImages={ltiHeaderImages}
        labels={['Method', 'Configuration', 'Teacher Instructions']}
      />
    );
  };

  const renderConfiguration = () => {
    if (lmsType === 'schoology') {
      return (
        <SchoologyConfiguration
          initialLmsType={initialLmsType}
          subscriberId={subscriberId}
          setLmsType={setLmsType}
        />
      );
    }

    if (lmsType === 'canvas_1_3' && canvasLti13) {
      return (
        <CanvasConfiguration
          initialLmsType={initialLmsType}
          setLmsType={setLmsType}
          subscriberId={subscriberId}
        />
      );
    }

    return (
      <Configuration
        initialLmsType={initialLmsType}
        modalManager={modalManager}
        subscriberId={subscriberId}
      />
    );
  };

  return (
    <Modal
      isOpen={modalManager.isOpen}
      closeModal={modalManager.close}
      headerText="Assignment and Grade Sync Setup"
    >
      <Wizard progressHeader={progressHeader()}>
        <LmsSelection
          setLmsType={setLmsType}
          modalManager={modalManager}
          lmsType={lmsType}
          hasCompletedLTI={hasCompletedLTI}
          initialLmsType={initialLmsType}
          isEditing={isEditing}
        />

        {renderConfiguration()}

        <Info
          modalManager={modalManager}
          setSeenInfo={setSeenInfo}
          lmsType={lmsType}
        />
      </Wizard>
    </Modal>
  );
};

CanvasIntegrationModal.propTypes = {
  hasCompletedLTI: PropTypes.bool,
  initialLmsType: PropTypes.string,
  isEditing: PropTypes.bool,
  isInitialSetup: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
  }).isRequired,
  setCanvasCredentials: PropTypes.func,
  setLtiCredentials: PropTypes.func,
  subscriberId: PropTypes.number.isRequired,
};

CanvasIntegrationModal.defaultProps = {
  hasCompletedLTI: false,
  initialLmsType: null,
  isEditing: false,
  isInitialSetup: true,
  setCanvasCredentials: () => {},
  setLtiCredentials: () => {},
};

export default CanvasIntegrationModal;
