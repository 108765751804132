import React, { useState } from 'react';
import { QueryClientProvider, useIsFetching, useQueries } from '@tanstack/react-query';
import Modal from '@/components/common/Modal';
import { correlationQuery, queryClient, queryKey } from './queries';
import AssessmentCorrelations from './AssessmentCorrelations';
import PresentationCorrelations from './PresentationCorrelations';
import SectionCorrelations from './SectionCorrelations';
import { AssessmentCorrelationsType, PresentationCorrelationsType, SectionCorrelationsType } from './types';

interface EdgateCorrelationsProps {
  assessmentId?: number;
  grade: string;
  lessonId: number;
  trial: boolean;
}

const EdgateCorrelations = ({ assessmentId, grade, lessonId, trial }: EdgateCorrelationsProps) => {
  const [assessmentCorrelations, setAssessmentCorrelations] = useState<AssessmentCorrelationsType>({});
  const [presentationCorrelations, setPresentationCorrelations] = useState<PresentationCorrelationsType>({});
  const [sectionCorrelations, setSectionCorrelations] = useState<SectionCorrelationsType>({});
  const [allExpanded, setAllExpanded] = useState(false);
  const isFetching = useIsFetching();

  useQueries({
    queries: [
      {
        queryKey: queryKey('assessment'),
        queryFn: () => (
          correlationQuery({ lessonId, grade, type: 'assessment' })
            .then(({ data: correlationData }) => {
              setAssessmentCorrelations(correlationData);
              return correlationData;
            })
        ),
      },
      {
        queryKey: queryKey('presentation'),
        queryFn: () => (
          correlationQuery({ lessonId, grade, type: 'presentation' })
            .then(({ data: correlationData }) => {
              setPresentationCorrelations(correlationData);
              return correlationData;
            })
        ),
      },
      {
        queryKey: queryKey('section'),
        queryFn: () => (
          correlationQuery({ lessonId, grade, type: 'section' })
            .then(({ data: correlationData }) => {
              setSectionCorrelations(correlationData);
              return correlationData;
            })
        ),
      },
    ],
  });

  if (isFetching > 0) return Modal.spinner();

  const showAssessments = () => {
    if (!assessmentId) return false;

    const { assessment_questions, ...correlations } = assessmentCorrelations;

    return Object.values(correlations).some(correlation => correlation.length > 0);
  };

  const showPresentations = () => Object.values(presentationCorrelations).some(correlation => correlation.length > 0);

  const showSections = () => {
    const { sections, ...correlations } = sectionCorrelations;

    return Object.values(correlations).some(correlation => correlation.length > 0);
  };

  const hasCorrelations = () => (assessmentId && showAssessments()) || showPresentations() || showSections();

  const expandAll = () => {
    $(`.expandable.small.${allExpanded ? 'open' : 'closed'} .expandable-toggle-btn`).click();
    setAllExpanded(prev => !prev);
  };

  return (
    <>
      {trial && (
        <>
          <br />
          <p>
            Trials see California standards by default. To see your state&apos;s standards, visit our&nbsp;
            <a
              href="http://www.teachtci.com/standards-correlations.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              correlations database
            </a>
          </p>
        </>
      )}
      {hasCorrelations() && (
        <div className="correlations pt20">
          <button
            type="button"
            onClick={expandAll}
            className="btn btn--sm btn--outline-purple pos_fr"
          >
            {allExpanded ? 'Collapse All' : 'Expand All'}
          </button>
          {showSections() && <SectionCorrelations correlations={sectionCorrelations} />}
          {showPresentations() && <PresentationCorrelations correlations={presentationCorrelations} />}
          {showAssessments() && <AssessmentCorrelations correlations={assessmentCorrelations} />}
        </div>
      )}
      {!hasCorrelations() && (
        <>
          <p id="no_correlations">There are no correlations for this lesson.</p>
        </>
      )}
    </>
  );
};

const EdgateCorrelationsWrapper = (props: EdgateCorrelationsProps) => (
  <QueryClientProvider client={queryClient}>
    <EdgateCorrelations {...props} />
  </QueryClientProvider>
);

export default EdgateCorrelationsWrapper;
