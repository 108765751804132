/* eslint-disable no-underscore-dangle */

import create, { createStore } from 'zustand';
import I18n from 'i18n-js';
import update from 'immutability-helper';
import { formatPresentSlides, SlideShow } from '@/components/admin/SlideShow/PresentMode/Utils';
import { SlideGroup } from '@/components/admin/SlideShow/Builder/CreateTransistionGroup/utils';
import { IAssignment, Slide, SlideObject, UnansweredQuestion } from '@/components/admin/SlideShow/types';
import { updatedFromPayload } from './utils';
import ITheme from '@/components/interfaces/Theme';

const ADMIN_TYPES = ['Sysadmin', 'ContentManager', 'Admin'];
const SYS_ADMIN_TYPES = ['Sysadmin', 'ContentManager'];

export interface ISlideShow extends SlideShow {
  descriptionEn?: string;
  hasSlideConditions?: boolean;
  id: number;
  imageUrl: string;
  lesson: any;
  unit: any;
  slideGroupsAssignments: IAssignment[];
  slides: ISlide[];
  themeId?: number;
}

export interface IImageModel {
  alignment: string;
  captionEn: string;
  captionEs: string;
  decorative: boolean;
  displaySize: string;
  displayStyle: string;
  id: number;
  image: {
    id: number;
    credits: string;
    descriptionEn: string;
    descriptionEs: string;
    descriptionConfidence: string;
    imageUrl: string;
    fileName: string;
    resolution: string;
    thumbnail: string;
    titleEn: string;
    titleEs: string;
  };
  snippet: string;
  zoomType: string;
}

export type Conditions = 'questions_answered' | 'questions_answered_current_slide' | null;
export type Actions = 'unlock_navigation' | 'unlock_next_slide' | null;

export interface ICondition {
  __typename: 'Condition';
  id: string;
  slideId: number;
  condition: Conditions;
  action: Actions;
  slideIds: number[] | null;
}

export interface ISlideTemplateObject {
  id: string;
  index: number;
  position: { x: number, y: number };
  width: number;
  height: number;
}

export interface ISlideTemplate {
  id: string;
  title: string;
  slideTemplateObjects: ISlideTemplateObject[];
}

export interface ISlide {
  __typename: 'Slide';
  buildingTowardsModelTags: { tagId: number }[];
  backgroundAudioEsFileName?: string;
  backgroundAudioEsUrl?: string;
  backgroundAudioFileName?: string;
  backgroundAudioUrl?: string;
  backgroundPosition: string;
  endPage?: number;
  id: number;
  imageModel?: IImageModel;
  modelTags: { tagId: number }[];
  notes: string;
  notesEn: string;
  notesEs: string;
  position: number;
  slideTemplateId: number;
  slideGroupId: number;
  slideObjects?: SlideObject[];
  slideShowId: number;
  startPage?: number;
  title: string;
  type: string;
  updatedAt: string;
  condition?: ICondition;
  slideTemplate?: ISlideTemplate;
}

export type ISlideOrSlideGroup = ISlide | SlideGroup;

export interface SlideShowStoreVariables {
  activityType: string;
  allowImpersonate: boolean;
  allowViewSlideshows: boolean;
  canEditQuestions: boolean;
  currentSlide: ISlide;
  formattedSlides: ISlideOrSlideGroup[];
  hasNotebookQuestions: boolean;
  hasUnsavedChanges: boolean;
  currentOpenHintId?: string | number;
  currentOpenTooltipId?: string | number;
  isAdmin: boolean;
  isPresentMode: boolean;
  isResettable: boolean;
  isSlideConditionsDrawerOpen: boolean;
  isSysAdmin: boolean;
  jumpToSlide: (nextPosition: number) => void;
  lessonId: number;
  locale: 'en' | 'es';
  parentId: number;
  parentType: string;
  programId: number;
  recentlyAnsweredQuestionId: number;
  referrerProgramId?: number;
  slideRef: null | { current: HTMLDivElement };
  slideShow: ISlideShow;
  slideShowId: number;
  slides: ISlideOrSlideGroup[];
  slideIds: number[];
  stafferId: number;
  themeId?: number;
  themes: ITheme[];
  getSetSlideAudioVolume: (volumeLevel?: number) => number;
  unansweredQuestions: UnansweredQuestion[];
  unitId: number;
  userId: number;
  userType: UserType;
  storeRef: ReturnType<typeof createSlideShowStore>;
}

type ISlideShowStore = Partial<SlideShowStoreVariables> & {
  setActivityType: (nextState: string) => void;
  setAllowImpersonate: (nextState: boolean) => void;
  setAllowViewSlideshows: (nextState: boolean) => void;
  setCanEditQuestions: (nextState: boolean) => void;
  setCurrentSlide: (nextState: ISlide) => void;
  setFormattedSlides: (nextState: ISlide[]) => void;
  setHasUnsavedChanges: (nextState: boolean) => void;
  setCurrentOpenHintId: (nextState: string | number) => void;
  setCurrentOpenTooltipId: (nextState: string | number) => void;
  setJumpToSlide: (nextState: (nextPosition: number) => void) => void;
  setIsPresentMode: (nextState: boolean) => void;
  setIsResettable: (nextState: boolean) => void;
  setHasNotebookQuestions: (nextState: boolean) => void;
  setIsSlideConditionsDrawerOpen: (nextState: boolean) => void;
  setLessonId: (nextState: number) => void;
  setParentId: (nextState: number) => void;
  setParentType: (nextState: string) => void;
  setProgramId: (nextState: number) => void;
  setLocale: (nextState: 'en' | 'es') => void;
  setReferrerProgramId: (nextState: number) => void;
  setSlideShow: (nextState: ISlideShow) => void;
  setSlideShowId: (nextState: number) => void;
  setSlides: (nextState: ISlide[]) => void;
  setSlideIds: (nextState: number[]) => void;
  setSlideRef: (nextState: { current: HTMLDivElement }) => void;
  setStafferId: (nextState: number) => void;
  setGetSetSlideAudioVolume: (nextState: (volumeLevel?: number) => number) => void;
  setThemeId: (nextState: number) => void;
  setThemes: (nextState: ITheme[]) => void;
  setUnitId: (nextState: number) => void;
  setUserId: (nextState: number) => void;
  setUserType: (nextState: UserType) => void;
};

export interface CreateSlideShowStoreVariables extends SlideShowStoreVariables {
  updateSlide: (id: number, payload: Partial<ISlide>) => Partial<CreateSlideShowStoreVariables>;
  updateSlideObject: (slideObject: SlideObject) => void;
  updateSlideShow: (payload: Partial<ISlideShow>) => void;
  addSlide: (slide: ISlide) => void;
  removeSlide: (slideToRemove: ISlide | Slide) => void;
  removeSlideGroup: (slideToRemove: SlideGroup) => void;
  removeSlideObject: (slideObjectToRemove: SlideObject) => void;
  removeUnansweredQuestion: (id: number) => void;
  setCurrentSlide: (currentSlide: ISlide) => void;
  setCurrentOpenHintId: (nextState: string | number) => void;
  setCurrentOpenTooltipId: (nextState: string | number) => void;
  setSlides: (slides: ISlideOrSlideGroup[]) => void;
  setStore: (nextState: Partial<SlideShowStoreVariables>) => void;
  setSelectedSlide: (selectedSlide: ISlide) => void;
  setSlideShow: (nextState: ISlideShow) => void;
  setSlideShowId: (slideShowId: number) => void;
  setRecentlyAnsweredQuestionId: (nextState: number) => void;
  setThemeId: (themeId: number) => void;
  setThemes: (themes: ITheme[]) => void;
}

interface CreateSlideShowStoreProps {
  isResettable?: boolean;
  activityType?: string;
  allowImpersonate?: boolean;
  allowViewSlideshows?: boolean;
  canEditQuestions?: boolean;
  classroomIds?: number[];
  hasNotebookQuestions?: boolean;
  isPresentMode?: boolean;
  lessonId?: number;
  locale?: string;
  parentId?: number;
  parentType?: string;
  position?: number;
  programId: number;
  referrerProgramId?: number;
  returnPath: string;
  slideId?: string;
  slideIds?: number[];
  slideRef?: null | { current: HTMLDivElement };
  slideShowId: number;
  stafferId: number;
  themeId?: number;
  themes?: ITheme[];
  unitId?: number;
  userType: UserType;
  userId?: string;
}

export const createSlideShowStore = (
  props: CreateSlideShowStoreProps,
  storeRef: ReturnType<typeof createSlideShowStore>,
) => createStore()((set, get) => ({
  ...props,
  storeRef,
  // this actually inits from props.slideShow.activity_type, can we fix that? prob need to change the props type
  activityType: false,
  addSlide: (newSlide: ISlide) => set((state) => {
    const { slideShow } = state;

    const insertIndex = newSlide.position - 1;
    const newSlides = [...slideShow.slides];
    newSlides.splice(insertIndex, 0, newSlide);
    const updatedSlides = newSlides.map((s, index) => ({
      ...s,
      position: index + 1,
    }));

    const newSlideShow = { ...slideShow, slides: updatedSlides };

    return {
      slideShow: newSlideShow,
      slides: formatPresentSlides(newSlideShow, newSlides.map(slide => slide.id)),
      currentSlide: newSlide,
    };
  }),
  removeSlide: (slide: ISlide) => set((state) => {
    const {
      slideShow,
    } = state;

    const slideToRemove = slideShow.slides.find(s => slide.id === s.id);
    const slideToRemoveIndex = slideShow.slides.indexOf(slideToRemove);

    const newSlides = slideShow.slides.filter(s => slide.id !== s.id);
    const newSlideShow = { ...slideShow, slides: newSlides };
    const formattedSlides = formatPresentSlides(newSlideShow, newSlides.map(s => s.id));
    const currentSlide = newSlideShow.slides[slideToRemoveIndex === 0 ? 0 : slideToRemoveIndex - 1];

    return {
      slideShow: newSlideShow,
      slides: formattedSlides,
      currentSlide,
    };
  }),
  removeSlideObject: (slideObject: SlideObject) => set((state: CreateSlideShowStoreVariables) => {
    const {
      currentSlide,
      slideShow,
    } = state;

    const slideIndex = slideShow.slides.findIndex(slide => slide.id.toString() === slideObject.slideId.toString());
    const objectIndex = slideShow.slides[slideIndex].slideObjects.findIndex(obj => obj.id === slideObject.id);

    const updatedSlideShow = update(slideShow, {
      slides: {
        [slideIndex]: {
          slideObjects: {
            $splice: [[objectIndex, 1]],
          },
        },
      },
    });

    const formattedSlides = formatPresentSlides(updatedSlideShow, updatedSlideShow.slides.map(s => s.id));

    return {
      slideShow: updatedSlideShow,
      slides: formattedSlides,
      currentSlide: formattedSlides.find(s => s.id.toString() === currentSlide.id.toString()),
    };
  }),
  removeSlideGroup: (slideGroup: SlideGroup) => set((state) => {
    const {
      slides,
      slideShow,
    } = state;

    const slideGroupToRemove = slideShow.slideGroups.find(s => slideGroup.id === s.id);
    const newSlides = slideShow.slides.filter(s => slideGroupToRemove.id !== s.slideGroupId?.toString());
    const newSlideGroups = slideShow.slideGroups.filter(group => group.id !== slideGroupToRemove.id);
    const newSlideShow = { ...slideShow, slides: newSlides, slideGroups: newSlideGroups };
    const formattedSlides = formatPresentSlides(newSlideShow, newSlides.map(s => s.id));
    const slideToRemoveIndex = slides.findIndex(s => s.id === slideGroupToRemove.id && s.__typename === 'SlideGroup');
    const currentSlide = formattedSlides[slideToRemoveIndex === 0 ? 0 : slideToRemoveIndex - 1];

    return {
      slideShow: newSlideShow,
      slides: formattedSlides,
      currentSlide,
    };
  }),
  removeUnansweredQuestion: (id: number) => set(state => ({
    unansweredQuestions: state.unansweredQuestions.filter(q => Number(q.id) !== Number(id)),
  })),
  updateSlide: (id: number, payload: Partial<ISlide>) => {
    const {
      slides,
      slideShow,
      currentSlide,
    } = get() as CreateSlideShowStoreVariables;

    const slide = slides.find((s) => {
      if (s.__typename !== 'Slide') return false;

      return s.id.toString() === id.toString();
    }) as ISlide;

    if (!slide) {
      // eslint-disable-next-line no-console
      console.warn(`Couldn't update slide ${id}`);
      return {};
    }

    const updatedSlide = updatedFromPayload(slide, payload);
    const slideIndex = slideShow.slides.findIndex(s => slide.id.toString() === s.id.toString());

    const updatedSlideShow = update(slideShow, {
      slides: {
        [slideIndex]: {
          $merge: updatedSlide,
        },
      },
    });

    const formattedSlides = formatPresentSlides(updatedSlideShow, updatedSlideShow.slides.map(s => s.id));

    const updatedState = {
      currentSlide: formattedSlides.find(s => s.id.toString() === currentSlide.id.toString()),
      slides: formattedSlides,
      slideShow: updatedSlideShow,
    };

    set(updatedState);

    return updatedState;
  },
  updateSlideObject: (slideObject: SlideObject) => set((state: CreateSlideShowStoreVariables) => {
    const { slideShow, updateSlide } = state;

    const slide = slideShow.slides.find(s => s.id.toString() === slideObject.slideId.toString());
    const objectIndex = slide.slideObjects.findIndex(obj => obj.id.toString() === slideObject.id.toString());
    const updatedSlideObjects = update(slide.slideObjects, {
      [objectIndex]: {
        $set: slideObject,
      },
    });

    return updateSlide(slideObject.slideId, { slideObjects: [...updatedSlideObjects] });
  }),
  updateSlideShow: (payload: Partial<ISlideShow>) => set((state: CreateSlideShowStoreVariables) => {
    const updatedSlideShow = updatedFromPayload(state.slideShow, payload) as SlideShow;
    const formattedSlides = formatPresentSlides(updatedSlideShow, updatedSlideShow.slides.map(s => s.id));

    return {
      currentSlide: formattedSlides.find(s => s.id.toString() === state.currentSlide.id.toString()),
      slides: formattedSlides,
      slideShow: updatedSlideShow,
    };
  }),
  allowImpersonate: props.allowImpersonate,
  allowViewSlideshows: props.allowViewSlideshows,
  currentSlide: null,
  hasNotebookQuestions: props.hasNotebookQuestions,
  isAdmin: ADMIN_TYPES.includes(props.userType),
  isPresentMode: props.isPresentMode,
  isResettable: props.isResettable,
  isSysAdmin: SYS_ADMIN_TYPES.includes(props.userType),
  jumpToSlide: () => {},
  lessonId: props.lessonId,
  locale: props.locale ?? I18n.locale,
  parentId: props.parentId,
  parentType: props.parentType,
  programId: props.programId,
  referrerProgramId: props.referrerProgramId,
  setActivityType: activityType => set({ activityType }),
  setAllowImpersonate: allowImpersonate => set({ allowImpersonate }),
  setAllowViewSlideshows: allowViewSlideshows => set({ allowViewSlideshows }),
  setCurrentSlide: currentSlide => set({ currentSlide }),
  setCurrentOpenHintId: currentOpenHintId => set({ currentOpenHintId }),
  setCurrentOpenTooltipId: currentOpenTooltipId => set({ currentOpenTooltipId }),
  setHasNotebookQuestions: hasNotebookQuestions => set({ hasNotebookQuestions }),
  setIsPresentMode: isPresentMode => set({ isPresentMode }),
  setIsResettable: isResettable => set({ isResettable }),
  setJumpToSlide: jumpToSlide => set({ jumpToSlide }),
  setLocale: locale => set({ locale }),
  setLessonId: lessonId => set({ lessonId }),
  setParentId: parentId => set({ parentId }),
  setProgramId: programId => set({ programId }),
  setRecentlyAnsweredQuestionId: recentlyAnsweredQuestionId => set(state => ({
    recentlyAnsweredQuestionId,
    unansweredQuestions: state.unansweredQuestions.filter(q => Number(q.id) !== Number(recentlyAnsweredQuestionId)),
  })),
  setReferrerProgramId: referrerProgramId => set({ referrerProgramId }),
  setSlideIds: slideIds => set({ slideIds }),
  setSlideShow: slideShow => set({ slideShow }),
  setSlideShowId: slideShowId => set({ slideShowId }),
  setSlideRef: slideRef => set({ slideRef }),
  setSlides: slides => set({ slides }),
  setStafferId: stafferId => set({ stafferId }),
  setStore: nextState => set(prevState => ({ ...prevState, ...nextState })),
  setThemeId: themeId => set({ themeId }),
  setThemes: themes => set({ themes }),
  setUnansweredQuestions: unansweredQuestions => set({ unansweredQuestions }),
  setUnitId: unitId => set({ unitId }),
  setUserId: userId => set({ userId }),
  setUserType: userType => set({ userType }),
  slideIds: props.slideIds,
  slideShowId: props.slideShowId,
  slideRef: { current: null },
  slides: [],
  stafferId: props.stafferId,
  themeId: props.themeId,
  themes: props.themes,
  userId: props.userId,
  unansweredQuestions: [],
  unitId: props.unitId,
  userType: props.userType,
}));

const useSlideShowStore = create<ISlideShowStore>(set => ({
  setActivityType: activityType => set({ activityType }),
  setAllowImpersonate: allowImpersonate => set({ allowImpersonate }),
  setAllowViewSlideshows: allowViewSlideshows => set({ allowViewSlideshows }),
  isPresentMode: false,
  formattedSlides: [],
  currentOpenHintId: undefined,
  currentOpenTooltipId: undefined,
  isSlideConditionsDrawerOpen: false,
  locale: 'en',
  jumpToSlide: () => {},
  setHasUnsavedChanges: hasUnsavedChanges => set({ hasUnsavedChanges }),
  setCurrentOpenHintId: currentOpenHintId => set({ currentOpenHintId }),
  setCurrentOpenTooltipId: currentOpenTooltipId => set({ currentOpenTooltipId }),
  setCanEditQuestions: canEditQuestions => set({ canEditQuestions }),
  setCurrentSlide: currentSlide => set({ currentSlide }),
  setFormattedSlides: formattedSlides => set({ formattedSlides }),
  setJumpToSlide: jumpToSlide => set({ jumpToSlide }),
  setIsPresentMode: isPresentMode => set({ isPresentMode }),
  setIsResettable: isResettable => set({ isResettable }),
  setHasNotebookQuestions: hasNotebookQuestions => set({ hasNotebookQuestions }),
  setIsSlideConditionsDrawerOpen: isSlideConditionsDrawerOpen => set({ isSlideConditionsDrawerOpen }),
  setLessonId: lessonId => set({ lessonId }),
  setLocale: locale => set({ locale }),
  setParentId: parentId => set({ parentId }),
  setParentType: parentType => set({ parentType }),
  setProgramId: programId => set({ programId }),
  setReferrerProgramId: referrerProgramId => set({ referrerProgramId }),
  setSlideShow: slideShow => set({ slideShow }),
  setSlideShowId: slideShowId => set({ slideShowId }),
  setSlides: slides => set((state) => {
    if (state.slideShow) {
      return ({
        formattedSlides: formatPresentSlides(state.slideShow, slides.map(slide => slide.id)),
        slides,
      });
    }

    return { slides };
  }),
  setSlideIds: slideIds => set({ slideIds }),
  setSlideRef: slideRef => set({ slideRef }),
  setStafferId: stafferId => set({ stafferId }),
  setThemeId: themeId => set({ themeId }),
  setThemes: themes => set({ themes }),
  setGetSetSlideAudioVolume: getSetSlideAudioVolume => set({ getSetSlideAudioVolume }),
  setUnitId: unitId => set({ unitId }),
  setUserId: userId => set({ userId }),
  setUserType: userType => set({
    isAdmin: ADMIN_TYPES.includes(userType),
    isSysAdmin: SYS_ADMIN_TYPES.includes(userType),
    userType,
  }),
  slides: [],
  themeId: undefined,
  themes: [],
}));

export default useSlideShowStore;
