import React, {useRef} from 'react';
import { SlideObject } from 'components/admin/SlideShow/types';
import styles from 'components/admin/SlideShow/Builder/SlideShowBuilder.module.scss';
import RemoveSlide from '@/components/admin/SlideShow/Utils/RemoveSlide';
import AddSlide from '@/components/admin/SlideShow/Utils/AddSlide';
import CopySlide from 'components/admin/SlideShow/Utils/CopySlide';
import { getNewSlidePosition } from '@/components/admin/SlideShow/Utils';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import CopySlideIdButton from './CopySlideIdButton';
import Anchor from '@/components/ui/Anchor';

interface SlideActionsDropdownProps {
  removeModalManager: {
    close: () => void;
    isOpen: boolean;
    open: () => void;
  };
  slide: {
    id: string;
    position: number;
    slideGroupId: number;
    slideObjects: SlideObject[];
  };
}

const SlideActionsDropdown = ({ removeModalManager, slide }: SlideActionsDropdownProps) => {
  const slides = useSlideShowContext(state => state.slides);
  const userType = useSlideShowContext(state => state.userType);
  const isContentManager = ['ContentManager', 'Sysadmin'].includes(userType);
  const ref = useRef()

  return (
    <div className={styles.actionsButtonWrapper}>
      <button
        className={`${styles.actionsButton} dropdown-toggle btn btn--outline-purple`}
        id="dropdownMenuButton"
        ref={ref}
        type="button"
      />
      <Anchor
        attachListenersTo={[document.querySelector('#slide-panel')]}
        className="dropdown-menu"
        defaultOpen={false}
        position={'bottom-left'}
        to={ref.current}
        within={document.getElementById("slideShowBuilderDiv")}
      >
        <AddSlide
          position={getNewSlidePosition(slide, slides)}
          slideGroupId={slide.slideGroupId}
          styling="dropdown"
        />
        <CopySlide slide={slide} />
        {isContentManager && <CopySlideIdButton slideId={slide.id} />}
        <RemoveSlide slide={slide} modalManager={removeModalManager} />
      </Anchor>
    </div>
  );
};

export default SlideActionsDropdown;
