import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import styles from './Delete.module.scss';

export default class Delete extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    destroyPath: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      district_course_code: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      program_name: PropTypes.string.isRequired
    }).isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    Axios
      .delete(this.props.destroyPath.replace(':id', this.props.rowData.id))
      .then((response) => {
        if (response.status === 200) {
          this.props.updateTable({
            newMappedPrograms: [],
            deletedIds: [response.data.data.id]
          });
        }

        this.props.closeModal();
      })
      .catch(error => console.log(error));
  }

  _renderDeleteButton() {
    let text = 'Delete';
    if (this.state.submitting) {
      text = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--purple"
        disabled={this.state.submitting}
        onClick={this.handleDelete}
      >
        {text}
      </button>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        headerText="Delete Course Mapping"
        isOpen={this.props.modalIsOpen}
      >
        <h1 className="mb10">
          Are you sure you want to delete this course mapping?
        </h1>

        <p className={styles.message}>
          Roster syncs going forward will no longer provision
          {` ${this.props.rowData.program_name}`} for classes with district
          course code {` ${this.props.rowData.district_course_code}`}.
        </p>

        <hr />

        <footer>
          <button
            className="btn btn--link-purple"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>

          {this._renderDeleteButton()}
        </footer>
      </Modal>
    );
  }
}
