import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Footer from 'common/Wizard/Footer';
import { Form } from 'react-final-form';
import { ssoModelPropType } from '../../../Proptypes';
import { copyTextButton } from '../../../Utils';

export default function LtiConfiguration({
  rootUrl, ssoModelPath, updateSSOModel, ssoModel, next, page
}) {
  function submitRead() {
    const path = ssoModelPath.replace(':id', ssoModel.id);
    Axios.put(path, { sso_model: { instructions_seen: true } })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          updateSSOModel(response.data.data);
          next();
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div>
      <p className="mb20">
        Next, use the following information to set up TCI as an LTI app in your Learning Management System.
        See
        {' '}
        <a
          href="https://www.teachtci.com/set-up-lti-sso"
          target="_blank"
          rel="noreferrer noopener"
        >
          LTI Instructions
        </a>
      </p>

      <p className="mb20">
        <span className="bold mr20">URL </span>
        {rootUrl}
        lti_launch
        {copyTextButton(`${rootUrl}lti_launch`)}
      </p>

      <p className="mb20">
        <span className="bold mr20">Key </span>
        {ssoModel.model.consumer_key}
        {copyTextButton(ssoModel.model.consumer_key)}
      </p>

      <p className="mb20">
        <span className="bold mr20">Secret </span>
        {ssoModel.model.secret}
        {copyTextButton(ssoModel.model.secret)}
      </p>

      <Form
        onSubmit={submitRead}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Footer page={page} />
          </form>
        )}
      </Form>
    </div>
  );
}

LtiConfiguration.propTypes = {
  rootUrl: PropTypes.string.isRequired,
  next: PropTypes.func,
  ssoModel: ssoModelPropType.isRequired,
  ssoModelPath: PropTypes.string.isRequired,
  updateSSOModel: PropTypes.func.isRequired,
  page: PropTypes.number
};

LtiConfiguration.defaultProps = {
  page: 0,
  next: () => {}
};
