import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { truncate } from '../../../../modules/TCIUtils';
import SourceTooltip from '../../../staffer/materials/SourceTooltip';
import MaterialsActionsDropdown from './MaterialsActionsDropdown';
import { TeacherIcon, KitIcon, PrintIcon } from '../../../common/Icons';
import { ModalButton, DeleteButton, SafetyNoteThumbnail } from '../Utils';
import Form from './Form';
import styles from './LessonMaterialsTable.module.scss';

const showMaterialName = (link, name, optional, sds, notes) => {
  const sds_link = (sds ? <a href={sds} target="_blank">(SDS)</a> : '');
  const notesHtml = notes ? <p className={styles.notes}>{notes}</p> : '';
  return (
    <td className="name">
      {link || name}{optional === true ? '*' : ''} {sds_link}
      {notesHtml}
    </td>
  );
};

const showQuantity = (amount, unit, distribution) => (
  `${amount} ${(unit !== null && unit !== undefined) ? unit : ''} ${distribution}`
);

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateMaterial = this.updateMaterial.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handoutLink() {
    const { name, handoutUrl, handoutTitle } = this.state;

    if (name === 'Handout') {
      return (
        <a href={handoutUrl} target="_blank" >
          {truncate(handoutTitle, 50)} <i className="fa fa-external-link" aria-hidden="true" />
        </a>
      );
    }
    return false;
  }

  static renderTooltipIcon(source) {
    switch (source) {
      case 'Teacher Supplied':
        return (
          <TeacherIcon />
        );
      case 'Kit':
        return (
          <KitIcon />
        );
      default:
        return (
          <PrintIcon />
        );
    }
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/model_materials/${this.props.id}`)
      .then(response => (response.data ? this.handleConfirm() : this.setState({ error: true })))
      .catch((error) => {
        console.log(error);
        this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
      });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  updateMaterial(materials) {
    let location = 0;
    const id = this.props.id;
    // Updating model materials can include adding new rows,
    // so we first find the original model material and
    // its location in the incoming array
    const updated_material = materials.find((el, i) => {
      if (el.id === id) {
        location = i;
      }
      return el.id === id;
    });

    materials.splice(location, location + 1);

    this.setState(updated_material);
    this.props.updateRow(updated_material);

    // we don't need to call add row if we aren't adding rows
    if (materials.length) this.props.addRows(materials);
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  openConfirmationModal() {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  stafferActionsCell() {
    if (!this.props.handoutUrl) return null;

    return (
      <MaterialsActionsDropdown
        downloadURL={this.props.handoutUrl}
        downloadTitle={this.props.handoutTitle}
        id={`actions-dropdown-${this.props.id}`}
      />
    );
  }

  renderActionsCell() {
    if (!this.state.admin) {
      return (
        <td>
          {this.stafferActionsCell()}
        </td>
      );
    }
    return (
      <td className="actions">
        <ModalButton
          buttonHtml={<span><i className="fa fa-edit" aria-hidden="true" />Edit</span>}
          buttonClass="btn btn--outline-purple btn--sm"
          modalHeader={this.state.modalHeader}
          modalClass="edit-materials"
          openModal={this.openModal}
          closeModal={this.closeModal}
          onUpdate={this.updateMaterial}
          FormComponent={Form}
          {...this.state}
        />
        <br />
        <DeleteButton
          confirmationModalIsOpen={this.state.confirmationModalIsOpen}
          openConfirmationModal={this.openConfirmationModal}
          closeConfirmationModal={this.closeConfirmationModal}
          handleDelete={this.handleDelete}
          isDeleting={this.state.isDeleting}
          withIcon
        />
      </td>
    );
  }

  render() {
    const {
      image, quantity, distribution, name, optional, modelUnit, sds,
      used_in, tooltipText, programScopedSource, handoutUrl
    } = this.state;

    return (
      <tr>
        <td className="image"><img src={image} alt="" /></td>
        {showMaterialName(this.handoutLink(), name, optional, sds, this.state['notes'])}
        <td className="quantity">{showQuantity(parseFloat(quantity), modelUnit, distribution)}</td>
        <td className="used-in">{used_in ? used_in.title : ''}</td>
        <td className="src">
          <SourceTooltip
            kind={programScopedSource.title.toLowerCase()}
            icon={Row.renderTooltipIcon(programScopedSource.title)}
            tooltipText={tooltipText}
            handoutUrl={handoutUrl}
            kitMaterialsUrl={programScopedSource.title === 'Kit' ? this.props.kitMaterialsUrl : null}
            materialName={`${name} ${this.state.handoutTitle || ''}`}
          />
        </td>
        <td className="safety-notes">
          {this.state.safetyNotes.map(safetyNote => (
            <SafetyNoteThumbnail key={safetyNote.id} {...safetyNote} />
          ))}
        </td>
        {this.renderActionsCell()}
      </tr>
    );
  }
}

Row.defaultProps = {
  handoutTitle: '',
  image: null,
  name: '',
  quantity: null,
  usedIn: null,
  safetyNotes: [],
  materialId: null,
  modalHeader: 'Edit Module Materials',
  modelId: null,
  lessonId: null,
  id: null,
  updateRow: null,
  isDeleting: false,
  isLoading: false,
  removeRow: null,
  material_modules: {},
  handoutUrl: '',
  unitNotebooks: [],
};

Row.propTypes = {
  kitMaterialsUrl: PropTypes.string.isRequired,
  usedIn: PropTypes.objectOf(PropTypes.string),
  handoutTitle: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.string,
  safetyNotes: PropTypes.arrayOf(PropTypes.object),
  materialId: PropTypes.number,
  modalHeader: PropTypes.string,
  modelId: PropTypes.number,
  lessonId: PropTypes.number,
  id: PropTypes.number,
  updateRow: PropTypes.func,
  isDeleting: PropTypes.bool,
  isLoading: PropTypes.bool,
  removeRow: PropTypes.func,
  material_modules: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
  handoutUrl: PropTypes.string,
  unitNotebooks: PropTypes.arrayOf(PropTypes.object),
};
