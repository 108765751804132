import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../common/Tooltip';

function Button({ openModal, disabled }) {
  return (
    <button
      className="btn btn--sm btn--outline-purple"
      onClick={openModal}
      disabled={disabled}
    >
      <i aria-hidden="true" className="fa fa-play" />
      {' '}
      Run Sync
    </button>
  );
}

Button.propTypes = {
  openModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  disabled: false
};

function ButtonWithTooltip({ openModal, tooltipText }) {
  return (
    <Tooltip
      content={tooltipText}
    >
      <div className="inline_block">
        <Button openModal={openModal} disabled />
      </div>
    </Tooltip>
  );
}

ButtonWithTooltip.propTypes = {
  openModal: PropTypes.func.isRequired,
  tooltipText: PropTypes.string.isRequired
};

export default function RunSyncButton({ hasRunningSync, districtDisabled, openRunModal }) {
  if (districtDisabled || hasRunningSync) {
    const tooltipText = districtDisabled ? 'Sync must be enabled.' : 'Only one roster sync can run at a time.';
    return (
      <ButtonWithTooltip openModal={openRunModal} tooltipText={tooltipText} />
    );
  }

  return (
    <Button openModal={openRunModal} />
  );
}

RunSyncButton.propTypes = {
  hasRunningSync: PropTypes.bool,
  districtDisabled: PropTypes.bool,
  openRunModal: PropTypes.func.isRequired
};

RunSyncButton.defaultProps = {
  hasRunningSync: false,
  districtDisabled: false
};
