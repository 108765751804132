import { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';

export type Tag = {
  category: string;
  subcategory: string;
  value_en: string;
  value_es: string;
  description_en: string;
  description_es: string;
};

const useSlideTags = () => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  const [tags, setTags] = useState<{ [key: number]: Tag[] }>({});
  const [tagGroups, setTagGroups] = useState<{ [key: string]: Tag[] }>({});

  useEffect(() => {
    if (!slideShowId) return;

    Axios.get<{ data: Tag[] }>(Routes.plato_api_tags_slide_show_path(slideShowId))
      .then(res => setTags(res.data));
  }, [slideShowId]);

  useEffect(() => {
    if (!currentSlide || !tags[currentSlide.id]?.length) {
      setTagGroups({});
      return;
    }

    const tagsByCategory = tags[currentSlide.id].reduce((acc, tag) => {
      if (tag.category === 'NGSS') {
        acc[tag.category] = [...(acc[tag.category] || []), tag];
      } else {
        acc[tag.subcategory] = [...(acc[tag.subcategory] || []), tag];
      }

      return acc;
    }, {});

    setTagGroups(tagsByCategory);
  }, [currentSlide?.id, tags]);

  return { tagGroups };
};

export default useSlideTags;
