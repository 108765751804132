import { useReducer, useEffect, useState, useMemo } from 'react';
import { useScript } from 'components/common/Utils';
import useFlags from '@/hooks/useFlags';
import SpeechStream from './SpeechStream';
import playbackStateReducer from './playbackStateReducer';

export const scriptSrc = (canUseUpdatedSpeechstream?: boolean) => {
  const version = canUseUpdatedSpeechstream ? '3.9.7' : '3.7.0';

  return (
    `https://toolbar.speechstream.net/SpeechStream/${version}/speechstream.js`
  );
};

const speechstreamConfig = (canUseUpdatedSpeechstream?: boolean, showSpeechstreamToolbar?: boolean) => {
  if (canUseUpdatedSpeechstream) {
    return ({
      speechstreamConfig: showSpeechstreamToolbar ? 'TCIadminv395R1' : 'TCIv395R1',
    });
  }

  return ({
    speechstreamConfig: 'TCIv351R1',
  });
};

interface SpeechStreamHook {
  canUseUpdatedSpeechstream: boolean;
  playing: boolean;
  toggleSpeech: () => void;
  stop: () => void;
  loaded: boolean;
  setVoice: (voice: string) => void;
}

interface Params {
  page?: number;
  locale: Locale;
  idToRead: string;
}

const useSpeechStream = ({ page, locale, idToRead }: Params): SpeechStreamHook => {
  const { canUseUpdatedSpeechstream, showSpeechstreamToolbar } = useFlags();
  const config = speechstreamConfig(canUseUpdatedSpeechstream, showSpeechstreamToolbar);
  const src = scriptSrc(canUseUpdatedSpeechstream);
  const [loaded, setIsLoaded] = useState(!!window.speechstreamEventListenerAdded);
  const initialState = { playing: false, paused: false };
  const [{ playing, paused }, playbackStateDispatch] = useReducer(playbackStateReducer, initialState);
  const speechstreamParams = { idToRead, playbackStateDispatch };
  const shouldPause = useMemo(() => playing || paused, [playing, paused]);

  const playOrPause = () => {
    if (shouldPause) {
      SpeechStream.pause(playbackStateDispatch);
    } else {
      SpeechStream.play(speechstreamParams);
    }
  };

  const playElement = () => {
    if (!window.lastIdSpoken) return SpeechStream.play(speechstreamParams);
    if (idToRead === window.lastIdSpoken) return playOrPause();

    SpeechStream.stop();
    SpeechStream.play(speechstreamParams);
  };

  const toggleSpeech = () => {
    if (!loaded) return null;

    return idToRead ? playElement() : playOrPause();
  };

  const setVoice = (voice: string) => { if (loaded) SpeechStream.setVoice(voice); };

  const stop = () => {
    if (!loaded) return null;

    return SpeechStream.stop(playbackStateDispatch);
  };

  const ttsVoice = () => {
    if (canUseUpdatedSpeechstream) return 'Matthew';

    return null;
  };

  useScript(src, config);

  useEffect(() => {
    SpeechStream.addEventListener({
      setIsLoaded,
      locale,
      page,
      voice: ttsVoice(),
      showSpeechstreamToolbar,
    });
  }, []);

  return { canUseUpdatedSpeechstream, playing, toggleSpeech, stop, loaded, setVoice };
};

export default useSpeechStream;
