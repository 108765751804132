import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as Routes from 'routes';
import { Spinner } from 'common/Modal';
import styles from '../index.module.scss';
import QuestionCheckboxField from './QuestionCheckboxField';

const QuestionPicker = (props) => {
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [questionGroups, setQuestionGroups] = useState([]);

  // When the notebook id changes, fetch the questions for it.
  useEffect(() => {
    let path;
    let params;
    if (props.typeOption !== 'Slide Show') {
      if (!props.notebookId) return;
      path = Routes.plato_api_nb_questions_notebook_path(props.notebookId, {
        model_id: props.modelId,
        model_type: props.modelType
      });
      params = new URLSearchParams([]);
    }
    else {
      path = Routes.plato_api_slide_show_questions_slide_shows_path();
      params = new URLSearchParams([['id', props.slideShowId], ['model_id', props.modelId], ['model_type', props.modelType]]);
    }

    setIsLoadingQuestions(true);

    Axios.get(path, { params })
      .then((response) => {
        // If no initial selectedQuestionGroups are passed in, look into the response and get the selected questions.
        if (props.selectedQuestionGroupIds.size === 0) {
          const selectedIds = response.data.question_groups
            .filter(qg => qg.is_selected)
            .map(qg => qg.question_group_id);
          props.setSelectedQuestionGroupIds(new Set(selectedIds));
        }
        setQuestionGroups([...response.data.question_groups]);
        setIsLoadingQuestions(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        props.setHasError(true);
        setIsLoadingQuestions(false);
      });
  }, [props.typeOption, props.notebookId]);

  const allChecked = () => questionGroups.every(qg => props.selectedQuestionGroupIds.has(qg.question_group_id));

  const handleToggle = (questionGroupId) => {
    if (props.selectedQuestionGroupIds.has(questionGroupId)) {
      props.setSelectedQuestionGroupIds(prev => new Set([...prev].filter(id => id !== questionGroupId)));
    }
    else {
      props.setSelectedQuestionGroupIds(prev => new Set(prev.add(questionGroupId)));
    }
  };

  const handleBulkToggle = () => {
    if (allChecked()) {
      props.setSelectedQuestionGroupIds(new Set());
    }
    else {
      props.setSelectedQuestionGroupIds(new Set(questionGroups.map(qg => qg.question_group_id)));
    }
  };

  const questionCheckbox = (questionGroup) => {
    if (questionGroup.questions.length > 1) {
      // eslint-disable-next-line no-console
      console.error('Multi-question question groups not yet supported');
      return null;
    }

    const checkboxId = `question-checkbox-${questionGroup.question_group_id}`;

    const hasQuestion = questionGroup.questions.length > 0;

    if (!hasQuestion) {
      // eslint-disable-next-line no-console
      console.error('Empty-question question groups not yet supported');
      return null;
    }

    return (
      <QuestionCheckboxField
        id={checkboxId}
        handleClick={() => handleToggle(questionGroup.question_group_id)}
        key={checkboxId}
        checked={props.selectedQuestionGroupIds.has(questionGroup.question_group_id)}
        question={questionGroup.questions[0]}
      />
    );
  };

  const questionPickerContent = () => {
    if (isLoadingQuestions) return <Spinner isLoading />;
    if (!props.notebookId && props.typeOption !== 'Slide Show') return <p>No notebooks for this section.</p>;
    if (questionGroups.length === 0) return <p>No questions for this notebook.</p>;

    return (
      <React.Fragment>
        <label htmlFor="bulk-question-checkbox">
          <input
            type="checkbox"
            id="bulk-question-checkbox"
            onClick={handleBulkToggle}
            onChange={handleBulkToggle}
            checked={allChecked()}
          />
          Select All
        </label>

        {questionGroups.map(questionCheckbox)}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.questionPickerContainer}>
      {questionPickerContent()}
    </div>
  );
};

QuestionPicker.propTypes = {
  modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  modelType: PropTypes.string.isRequired,
  notebookId: PropTypes.number.isRequired,
  selectedQuestionGroupIds: PropTypes.instanceOf(Set).isRequired,
  setHasError: PropTypes.func.isRequired,
  setSelectedQuestionGroupIds: PropTypes.func.isRequired,
  slideShowId: PropTypes.number.isRequired,
  typeOption: PropTypes.string.isRequired,
};

export default QuestionPicker;
