import React from 'react';
import ThreeJsContainer from '@/components/ui/ThreeJsContainer';
import { Texture } from '@/hooks/useThreeJs/threeJsUtils';
import { UseThreeJsOnSuccess } from '@/hooks/useThreeJs/useThreeJs';
import { Vec3 } from '@/hooks/useThreeJs/threeJsHelpers';

interface ThreeDModelViewerProps {
  onSuccess: UseThreeJsOnSuccess;
  threeDModel: {
    cameraPos?: Vec3;
    cameraRot?: Vec3;
    cameraTarget?: Vec3;
    cameraZoom?: number;
    id: string;
    modelUrl: string;
    textures: {
      id: string;
      imageUrl: string;
      imageModels: {
        textureType: string;
      }[];
    }[];
  }
}

const ThreeDModelViewer = ({ onSuccess, threeDModel }: ThreeDModelViewerProps) => {
  const textures = threeDModel
    .textures
    .map(t => ({ url: t.imageUrl, type: t.imageModels[0].textureType })) as Texture[];

  return (
    <ThreeJsContainer
      modelUrl={threeDModel.modelUrl}
      onSuccess={onSuccess}
      startingPosition={threeDModel.cameraPos}
      startingRotation={threeDModel.cameraRot}
      startingTarget={threeDModel.cameraTarget}
      startingZoom={threeDModel.cameraZoom}
      textures={textures}
    />
  );
};

export default ThreeDModelViewer;
