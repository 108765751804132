import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { Footer } from 'common/Modal';
import { Field, Form } from 'react-final-form';
import DataTable from 'common/DataTable';
import { matchAttributesForMultipleRows } from 'common/DataTable/Utils';
import ReactDOM from 'react-dom';
import Axios from 'axios';

export default class QuestionSlides extends Component {
  static propTypes = {
    slides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      position: PropTypes.number,
      title: PropTypes.string
    })).isRequired,
    slidesAdded: PropTypes.arrayOf(PropTypes.number),
    slideShowTitle: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    ajaxUrl: PropTypes.string.isRequired,
    previewSlideUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    columns: ['add', 'position', 'title'],
    slidesAdded: []
  };

  static _renderEmptyRow() {
    return (
      <tr id="empty">
        <td className="center" colSpan="5">No slides.</td>
      </tr>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      slides: this.props.slides,
      slidesAdded: this.props.slidesAdded,
      slidesToAdd: this.props.slidesAdded,
      submitting: false
    };

    matchAttributesForMultipleRows(this.state.slides, this.props.columns);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  handleSubmit(values) {
    this.setState({ submitting: true });
    return Axios
      .patch(this.props.ajaxUrl, { question: values })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          this.setState({ slidesAdded: response.data.slide_ids, slidesToAdd: response.data.slide_ids });
          this.closeModal();
        }
      })
      .catch(error => console.log(error))
      .then(() => this.setState({ submitting: false }));
  }

  handleCheckboxChange(args, state, utils) {
    const updatedSlidesToAdd = new Set(this.state.slidesToAdd);
    if (args[0].target.checked) {
      updatedSlidesToAdd.add(parseInt(args[0].target.value, 10));
    }
    else {
      updatedSlidesToAdd.delete(parseInt(args[0].target.value, 10));
    }

    this.setState({ slidesToAdd: Array.from(updatedSlidesToAdd) });
    utils.changeValue(state, 'slide_ids', () => Array.from(updatedSlidesToAdd));
  }

  _renderCheckbox(slide, form) {
    return (
      <div>
        <label
          htmlFor={`add-slide-${slide.id}`}
        >
          <span className="sr-only">
            remove {slide.title} from
          </span>
        </label>

        <Field
          component="input"
          type="checkbox"
          value={slide.id}
          checked={form.values}
          name="slide_ids[]"
          defaultChecked={this.state.slidesToAdd.includes(slide.id)}
          id={`add-slide-${slide.id}`}
          onClick={form.mutators.handleChange}
        />
      </div>
    );
  }

  _renderTableForm(form) {
    return (
      <DataTable
        handleResourcesManually
        columns={this.props.columns}
        searching={false}
        resources={this.state.slides}
        isLoading={false}
        pageLength={-1}
        columnDefs={[{
          title: 'Add',
          targets: 0,
          sortable: false,
          searchable: false,
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              rowData.id && this._renderCheckbox(rowData, form),
              td
            );
          }
        },
        {
          targets: 2,
          width: '50%',
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              rowData.id && this._renderSlidePreviewLink(rowData.id, '', rowData.title),
              td
            );
          }
        }]}
      />
    );
  }

  _renderForm() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={{
          handleChange: this.handleCheckboxChange
        }}
        initialValues={{ slide_ids: this.state.slidesAdded }}
        render={({ form, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
          >
            <p>Lesson Guide Title: {this.props.slideShowTitle}</p>
            {this._renderTableForm(form)}

            <Footer wrapperClassName="pt30" secondaryButtonCallback={this.closeModal} submitting={this.state.submitting} />
          </form>
        )}
      />);
  }

  _deleteQuestionSlide(slideIdToDelete) {
    if (confirm('Are you sure you want to delete this slide?')) {
      this.handleSubmit({ slide_ids: this.state.slidesAdded.filter(slideId => slideId !== slideIdToDelete) });
    }
  }

  _renderSlidePreviewLink(slideId, className, linkText) {
    return (
      <a
        target="_blank"
        className={className}
        rel="noopener noreferrer"
        href={`${this.props.previewSlideUrl}?slide_id=${slideId}`}
      >
        {linkText}
      </a>
    );
  }

  _deleteButton(slideId) {
    return (
      <a className="action" onClick={() => this._deleteQuestionSlide(slideId)}>Delete</a>
    );
  }

  _editAction() {
    return (
      <a className="action" onClick={this.openModal}>Edit</a>
    );
  }

  _renderTableBody() {
    if (this.state.slidesAdded.length) {
      return this.state.slides.filter(slide => this.state.slidesAdded.includes(slide.id)).map(slide => (
        <tr key={slide.id}>
          <td>{slide.position}</td>
          <td>{slide.title}</td>
          <td>
            {this._renderSlidePreviewLink(slide.id, 'action', 'Preview')}
            {' '}
            {this._editAction()}
            {' '}
            {this._deleteButton(slide.id)}
          </td>
        </tr>
      ));
    }

    return QuestionSlides._renderEmptyRow();
  }

  _renderTable() {
    return (
      <div className="tablebox">
        <table className="tabular_data striped">
          <thead>
            <tr>
              <td>#</td>
              <td>Title</td>
              <td>Actions</td>
            </tr>
          </thead>

          <tbody>
            {this._renderTableBody()}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="btn btn--green btn--sm mb5 ml10"
          onClick={this.openModal}
        >
          Add Slide
        </button>
        <Modal
          headerText="Add Slides"
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
        >
          {this._renderForm()}
        </Modal>

        {this._renderTable()}
      </div>
    );
  }
}
