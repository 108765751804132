import React from 'react';
import Tooltip from 'components/ui/Tooltip';
import I18n from 'i18n-js';
import cn from '@/utils/cn';
import useDrawingContext from '../../store/useDrawingContext';
import { listItemStyle } from '../styles';
import icons from './../icons';

const ShortcutsTooltip = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const toggleHelpMenuOpen = useDrawingContext(state => state.toggleHelpMenuOpen);

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Tooltip.Trigger asChild onClick={toggleHelpMenuOpen}>
          <button
            type="button"
            className={
              cn(
                'tw-flex tw-justify-start tw-items-center tw-px-5 tw-py-2 !tw-cursor-pointer tw-border-0',
                'tw-bg-transparent tw-text-base tw-font-tci tw-text-[#3c3c3c] tw-w-full hover:tw-bg-light-grey',
              )
            }
            style={listItemStyle(toolbarEl)}
          >
            <span>{I18n.t('view_keyboard_shortcuts')}</span>
            <i className="custom-icon" >
              <img
                src={icons.popup.icon}
                alt={icons.popup.alt}
                className="tw-flex tw-items-center tw-w-4 tw-h-4 tw-border-0 tw-pl-2"
                style={listItemStyle(toolbarEl)}
              />
            </i>
          </button>
        </Tooltip.Trigger>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default ShortcutsTooltip;
