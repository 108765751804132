import React from 'react';
import Froala from 'froala-editor';
import EditImage from '@/components/admin/ImageManager/Edit';
import useImageModel from '@/hooks/api/useImageModel';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';
import { imageModelForEditForm } from './froalaUtils';

export interface EditImageModalProps {
  afterSubmit?: (imageModel: GraphQlImageModel, editorInstance: any) => any;
  currentImageModelId: number;
  editorInstance: typeof Froala;
  editImageModalManager: ModalManager;
  modelId: number | string;
  modelType: string;
}

const EditImageModal = ({
  afterSubmit,
  currentImageModelId,
  editorInstance,
  editImageModalManager,
  modelId,
  modelType,
}: EditImageModalProps) => {
  const { data, refetch } = useImageModel({ id: currentImageModelId });

  const replaceImage = (imageModel: GraphQlImageModel) => {
    const img = document.querySelector(`img[data-snippet="[IMAGE_MODEL_${currentImageModelId}]"]`) as HTMLImageElement;

    if (!img) return;

    const { image: { imageUrl, descriptionEn }, hideCaptions } = imageModel;

    img.src = imageUrl;
    img.alt = descriptionEn;
    img.dataset.zoomurl = imageUrl;
    img.dataset.hideCaptions = hideCaptions?.toString();
    img.dataset.captionId = `caption-${currentImageModelId}`;

    const captionElement = document.querySelector(
      `[data-caption-for="${currentImageModelId}"]`,
    ) as HTMLElement;

    if (captionElement) {
      if (hideCaptions) {
        captionElement.style.cssText = 'position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0;';
      } else {
        captionElement.style.cssText = '';
      }
    }

    editorInstance.html.set(editorInstance.html.get());
  };

  const handleSubmit = (imageModel: GraphQlImageModel) => {
    if (afterSubmit) afterSubmit(imageModel, editorInstance);
    else replaceImage(imageModel);

    refetch();
  };

  if (!data) return null;

  return (
    <EditImage
      excludeFields={['display_size', 'zoom_type', 'alignment']}
      modelType={modelType}
      forFroala
      modalManager={editImageModalManager}
      modelId={modelId}
      imageModel={imageModelForEditForm(data)}
      updateResources={() => { }}
      standaloneButton={false}
      isAdmin
      afterSubmit={handleSubmit}
    />
  );
};

export default EditImageModal;
