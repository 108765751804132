import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'react-cookies';
import Axios from 'axios';
import I18n from 'i18n';
import TCIOnlyLock from '../TCIOnlyLock';
import Tab from './Tab';
import styles from './LeftNavBarTabs.module.scss';
import LanguageToggle from './LanguageToggle';
import CollapsedNav from './Collapsed/Nav';
import ExpandCollapseBtn from './ExpandCollapseBtn';

const subTabsShape = {
  active: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  showNewLabel: PropTypes.bool,
  sublabel: PropTypes.string,
  target: PropTypes.string,
  tciOnly: PropTypes.bool,
  url: PropTypes.string
};

const tabsShape = {
  active: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  lineAbove: PropTypes.bool,
  showNewLabel: PropTypes.bool,
  sublabel: PropTypes.string,
  subtabs: PropTypes.arrayOf(PropTypes.shape(subTabsShape)),
  target: PropTypes.string,
  tciOnly: PropTypes.bool,
  url: PropTypes.string
};

export default class LeftNavBarTabs extends Component {
  static propTypes = {
    collapsedTabs: PropTypes.arrayOf(PropTypes.shape({
      active: PropTypes.bool.isRequired,
      icon: PropTypes.string.isRequired,
      linksTo: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
    })).isRequired,
    colorMode: PropTypes.string.isRequired,
    coordinator: PropTypes.bool,
    copyright: PropTypes.string.isRequired,
    currentProgramId: PropTypes.number,
    initiallyCollapsed: PropTypes.bool.isRequired,
    newCollapsedSidebar: PropTypes.bool,
    settingsPath: PropTypes.string,
    showSpanishToggle: PropTypes.bool.isRequired,
    student: PropTypes.bool.isRequired,
    studentBorder: PropTypes.bool.isRequired,
    subscriberInfo: PropTypes.shape({
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string
      }),
      name: PropTypes.string,
      subId: PropTypes.string
    }),
    tabs: PropTypes.arrayOf(PropTypes.shape(tabsShape)).isRequired,
    updateUserPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    coordinator: false,
    currentProgramId: null,
    newCollapsedSidebar: false,
    settingsPath: null,
    subscriberInfo: null
  };

  constructor(props) {
    super(props);

    this.state = { collapsed: this.props.initiallyCollapsed };

    this._toggleExpandCollapse = this._toggleExpandCollapse.bind(this);
    this._expandLeftNav = this._expandLeftNav.bind(this);
    this._saveCollapsedSetting = this._saveCollapsedSetting.bind(this);
  }

  componentDidUpdate() {
    document.body.classList.toggle('left-nav-collapsed', this.state.collapsed);
  }

  _renderTab(tabData, index, tabExpanded) {
    return (
      <Tab
        colorMode={this.props.colorMode}
        className={tabData.class_name}
        key={tabData.label}
        label={tabData.label}
        active={tabData.active}
        currentProgramId={this.props.currentProgramId}
        initiallyExpanded={tabData.active || tabExpanded}
        url={tabData.url}
        subtabs={tabData.subtabs ? tabData.subtabs : null}
        target={tabData.target}
        index={index}
        sublabel={tabData.sublabel}
        lineAbove={tabData.lineAbove}
        id={tabData.id}
      />
    );
  }

  _getExpandedTabState(tabData, expandedTabsData) {
    const expandedFromCookie = expandedTabsData && expandedTabsData[tabData.label];

    if (tabData.subtabs) {
      // For tabs with subtabs, default to expanded if the cookie is unset
      return (expandedFromCookie === undefined) || expandedFromCookie;
    }

    return tabData.active || expandedFromCookie;
  }

  // Sets each tab to initially expanded if either of the following are true:
  // 1) The tab is the currently active tab
  // 2) The tab should be expanded according to the cookie data
  // If any tabs' expansion status has changed, update the cookie to reflect the change
  _renderTabs() {
    let expandedTabsData = Cookie.load('expandedTabs') || {};
    const previousProgramId = parseInt(Cookie.load('currentProgramId'), 10);

    // Reset the expanded tabs data if the user switches programs
    if (previousProgramId !== this.props.currentProgramId) expandedTabsData = {};

    const tabs = this.props.tabs.map((tabData, index) => {
      const tabShouldBeExpanded = this._getExpandedTabState(tabData, expandedTabsData);
      expandedTabsData[tabData.label] = tabShouldBeExpanded;

      return this._renderTab(tabData, index, tabShouldBeExpanded);
    });

    if (this.props.currentProgramId !== null) {
      Cookie.save('currentProgramId', this.props.currentProgramId, { path: '/' });
    }
    else {
      Cookie.remove('currentProgramId', { path: '/' });
    }

    Cookie.save('expandedTabs', expandedTabsData, { path: '/' });

    return tabs;
  }

  _renderSubscriberInfo() {
    if (!this.props.subscriberInfo) return null;
    const subscriber = this.props.subscriberInfo;

    return (
      <div className={styles.subscriberName}>
        <span className="mr5">{subscriber.name}</span>
        <TCIOnlyLock />
        <div>Sub ID: {subscriber.subId}</div>
        <div>Location: {subscriber.location.city}, {subscriber.location.state}</div>
      </div>
    );
  }

  _toggleExpandCollapse() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }), this._saveCollapsedSetting);
  }

  _renderSidebarContent() {
    return (
      <div className={`${styles.contentFlex} ${this.state.collapsed ? styles.collapsed : ''}`}>
        <div className={`${styles.tabsContainer} ${this.state.collapsed ? styles.hidden : ''} ${this.props.coordinator ? 'tw-pt-[50px]' : ''}`}>
          <ul>
            {this._renderTabs().map((tab, i) => {
              return <li key={`render-tab-wrapper-${i}`}>{tab}</li>
            })}
          </ul>
        </div>
        <div className={`${styles.info} ${this.state.collapsed ? styles.hidden : ''}`}>
          {this._renderSubscriberInfo()}
        </div>
        <div className={styles.languageDropdown}>
          {this.props.showSpanishToggle && (
            <LanguageToggle
              locale={I18n.locale}
              copyright={this.props.copyright}
              colorMode={this.props.colorMode}
              collapsed={this.state.collapsed}
            />
          )}
        </div>
      </div>
    );
  }

  _expandLeftNav() {
    if (this.state.collapsed) this.setState({ collapsed: false }, this._saveCollapsedSetting);
  }

  _saveCollapsedSetting() {
    let data = { left_nav_collapsed: this.state.collapsed };
    if (this.props.student) data = { student: data };
    Axios.put(this.props.updateUserPath, data);
  }

  render() {
    let wrapperClass = styles.contentContainer;
    if (this.state.collapsed) wrapperClass += ` ${styles.clickable}`;
    if (this.props.studentBorder) wrapperClass += ` ${styles.studentBorder}`;

    return (
      <div
        className={wrapperClass}
        onClick={this._expandLeftNav}
      >
        {!this.props.newCollapsedSidebar && (
          <button
            aria-label={this.state.collapsed ? 'Expand sidebar' : 'Collapse sidebar'}
            type="button"
            className={`${styles.expandCollapseButton} ${this.state.collapsed ? styles.expand : styles.collapse}`}
            onClick={this._toggleExpandCollapse}
          />
        )}
        {this.props.newCollapsedSidebar && (
          <>
            <ExpandCollapseBtn isCollapsed={this.state.collapsed} onClick={this._toggleExpandCollapse} />
            {this.state.collapsed && (
              <CollapsedNav tabs={this.props.collapsedTabs} />
            )}
          </>
        )}
        {this._renderSidebarContent()}
      </div>
    );
  }
}
