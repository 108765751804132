import React from 'react';
import { useModalManager } from 'components/common/Modal';
import * as Routes from 'modules/routes';
import ProgramAccessModal from '../../ProgramAccessModal';

const ProgramAccessButton = ({ slideShowId }) => {
  const modalManager = useModalManager();

  const handleOpen = (e) => {
    e.preventDefault();

    modalManager.open();
  };

  return (
    <>
      <button type="button" onClick={handleOpen} className="btn btn--outline-purple">
        Edit Access
      </button>

      {modalManager.isOpen && (
        <ProgramAccessModal
          isOpen={modalManager.isOpen}
          closeModal={modalManager.close}
          getPath={Routes.plato_api_program_access_slide_show_path({ id: slideShowId })}
          submitPath={Routes.plato_api_slide_show_path({ id: slideShowId })}
          model="slide_show"
          modalHeaderText="Edit Access"
          paramName="associated_program_ids"
        />
      )}
    </>
  );
};

export default ProgramAccessButton;
