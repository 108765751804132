import React, { useEffect, useState } from 'react';
import axios from 'axios';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import Spinner from '@/components/common/Spinner';
import TableOfContentsButton from '@/components/shared/TableOfContents/TableOfContentsButton';
import UnitCard from '../Cards/UnitCard';
import styles from './index.module.scss';

interface UnitSectionProps {
  programId: number;
}

const UnitSection = ({ programId }: UnitSectionProps) => {
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const route = Routes.plato_api_program_units_path(programId);

    axios.get(route)
      .then((response) => {
        setLoading(false);
        setUnits(response.data.data);
      });
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className={styles.unitCardsContainer}>
      <div className="tw-pb-1 tw-py-4 tw-flex tw-items-center tw-justify-between">
        <div className="tw-font-bold tw-text-2xl">{I18n.t('units')}</div>
        <TableOfContentsButton
          programId={programId}
          renderInModal
          units={units}
        />
      </div>
      <div className={styles.unitCards}>
        {units.map(unit => (
          <UnitCard key={unit.id} unit={unit} />
        ))}
      </div>
    </div>
  );
};

export default UnitSection;
