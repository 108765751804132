import PropTypes from 'prop-types';
import Axios from 'axios';
import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Modal from 'common/Modal';

import styles from '../RosteringSetupModal/Form.module.scss';
import { Footer } from '../../../../common/Modal';

class DeleteForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    deleteMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    deletePath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit() {
    if (this.props.handleSubmit) {
      return this.props.handleSubmit()
        .then(response => (this.props.onDelete(response.data.data.id)));
    }

    return Axios.delete(this.props.deletePath)
      .then(response => (this.props.onDelete(response.data.data.id)));
  }

  render() {
    return (
      <div>
        {this.props.deleteMessage}

        <Form
          onSubmit={this._handleSubmit}
          render={({ values, handleSubmit }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Field
                autoFocus
                className={styles.textInput}
                component="input"
                name="delete"
                type="text"
              />

              <Footer
                secondaryButtonCallback={this.props.closeModal}
                primaryButtonDisabled={values.delete !== 'delete me'}
              />
            </form>
          )}
        />
      </div>
    );
  }
}

const DeleteModal = ({
  closeModal, deletePath, onDelete, deleteMessage, handleSubmit
}) => (
  <Modal
    isOpen
    closeModal={closeModal}
    headerText="Delete Integration"
  >
    <DeleteForm
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      deletePath={deletePath}
      onDelete={onDelete}
      deleteMessage={deleteMessage}
    />
  </Modal>
);

DeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  deletePath: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  onDelete: PropTypes.func.isRequired
};

export default DeleteModal;
