import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RosterCard from './RosterCard';
import ExistingCards from './SSOCards/ExistingCards';
import rosteringCardStyles from './RosteringCard.module.scss';
import { ssoModelPropType } from './Proptypes';
import AutoRosterDistrict from './AutoRosterDistrict';

export default class RosteringOverview extends Component {
  static propTypes = {
    allow_self_serve_autoroster: PropTypes.bool,
    autoRosterDistrictCreatePath: PropTypes.string.isRequired,
    autoRosterDistrictUpdatePath: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired,
    getRosterEventsPath: PropTypes.string.isRequired,
    initialAutoRosterDistrict: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    initialSsoModels: PropTypes.arrayOf(ssoModelPropType),
    initialSubscriber: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    mapCoursesPath: PropTypes.string.isRequired,
    mappedCoursesSize: PropTypes.number.isRequired,
    nextSyncTime: PropTypes.string,
    rootUrl: PropTypes.string.isRequired,
    runSyncPath: PropTypes.string.isRequired,
    ssoModelCreatePath: PropTypes.string.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    subscriberId: PropTypes.number.isRequired,
    subscriberUpdatePath: PropTypes.string.isRequired,
    teacherSeatsLocked: PropTypes.bool,
    uploadXMLUrl: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    allow_self_serve_autoroster: false,
    initialAutoRosterDistrict: null,
    initialSsoModels: null,
    nextSyncTime: null,
    teacherSeatsLocked: false
  };

  constructor(props) {
    super(props);

    this.state = {
      autoRosterDistrict: this.props.initialAutoRosterDistrict,
      subscriber: this.props.initialSubscriber,
      teacherSeatsLocked: this.props.teacherSeatsLocked
    };

    this.getRosterDistrictPath = this.getRosterDistrictPath.bind(this);
    this.updateRosterDistrict = this.updateRosterDistrict.bind(this);
    this.updateSubscriber = this.updateSubscriber.bind(this);
    this.updateTeacherSeatsLocked = this.updateTeacherSeatsLocked.bind(this);
  }

  getRosterDistrictPath() {
    if (this.state.autoRosterDistrict) {
      return this.props.autoRosterDistrictUpdatePath.replace(':id', this.state.autoRosterDistrict.id);
    }
    return this.props.autoRosterDistrictCreatePath;
  }

  updateRosterDistrict(updatedDistrict) {
    let updatedRosterDistrict = updatedDistrict;

    if (this.state.autoRosterDistrict && updatedDistrict) {
      updatedRosterDistrict = Object.assign({}, this.state.autoRosterDistrict, updatedDistrict);
    }

    this._autoRosterDistrict().updateDistrict(updatedRosterDistrict);
    this.setState({ autoRosterDistrict: updatedRosterDistrict });
  }

  updateTeacherSeatsLocked(teacherSeatsLocked) {
    const updatedTeacherSeatsLocked = teacherSeatsLocked;
    this._autoRosterDistrict().updateTeacherSeatsLocked(updatedTeacherSeatsLocked);
    this.setState({ teacherSeatsLocked: updatedTeacherSeatsLocked });
  }

  updateSubscriber(updatedSub) {
    const updatedSubscriber = Object.assign({}, this.state.subscriber, updatedSub);
    this._autoRosterDistrict().updateSubscriber(updatedSubscriber);
    this.setState({ subscriber: updatedSubscriber });
  }

  _autoRosterDistrict() {
    if (!this.autoRosterDistrict) {
      this.autoRosterDistrict = new AutoRosterDistrict(
        this.state.autoRosterDistrict,
        this.props.mappedCoursesSize,
        this.state.subscriber,
        this.props.teacherSeatsLocked,
      );
    }

    return this.autoRosterDistrict;
  }

  renderRosterCard() {
    if (!this.props.allow_self_serve_autoroster) return null;

    return (
      <RosterCard
        autoRosterDistrictPath={this.getRosterDistrictPath()}
        autoRosterDistrict={this.state.autoRosterDistrict}
        completedSteps={this._autoRosterDistrict().getCompletedSteps()}
        env={this.props.env}
        getRosterEventsPath={this.props.getRosterEventsPath}
        isSetupComplete={this._autoRosterDistrict().isSetupComplete()}
        mapCoursesPath={this.props.mapCoursesPath}
        mappedCoursesSize={this.props.mappedCoursesSize}
        nextSyncTime={this.props.nextSyncTime}
        runSyncPath={this.props.runSyncPath}
        subscriber={this.state.subscriber}
        subscriberId={this.props.subscriberId}
        subscriberUpdatePath={this.props.subscriberUpdatePath}
        updateRosterDistrict={this.updateRosterDistrict}
        updateSubscriber={this.updateSubscriber}
        userType={this.props.userType}
        teacherSeatsLocked={this.state.teacherSeatsLocked}
        updateTeacherSeatsLocked={this.updateTeacherSeatsLocked}
      />
    );
  }

  render() {
    return (
      <div className={`${rosteringCardStyles.displayFlex} ${rosteringCardStyles.cardsContainer}`}>
        {this.renderRosterCard()}

        <ExistingCards
          subscriber_id={this.props.subscriberId}
          ssoModels={this.props.initialSsoModels}
          ssoModelPath={this.props.ssoModelPath}
          ssoModelCreatePath={this.props.ssoModelCreatePath}
          uploadXMLUrl={this.props.uploadXMLUrl}
          userType={this.props.userType}
          rootUrl={this.props.rootUrl}
          hasCompletedClever={this._autoRosterDistrict().isCleverComplete()}
        />
      </div>
    );
  }
}
