import React, { useContext } from 'react';
import cn from '@/utils/cn';
import DraggableChoiceContent from './DraggableChoiceContent';
import styles from './Builder/DraggableChoice/DraggableChoice.module.scss';

const DraggableChoicePreview = ({ content, context }) => {
  const { hasTransparentAnswerChoices: transparent, dropZoneShape, question } = useContext(context);

  return (
    <div className={cn(
      styles.draggableChoice, 'tw-inline-block tw-max-w-[280px]', {
        [styles.transparentBackground]: transparent,
        [styles.circle]: dropZoneShape === 'circle' || question?.data?.drop_zone_shape === 'circle',
        [styles.rectangle]: dropZoneShape !== 'circle' || question?.data?.drop_zone_shape === 'circle',
      }
    )}
    >
      <DraggableChoiceContent className={styles.content} content={content} />
    </div>
  );
};

export default DraggableChoicePreview;
