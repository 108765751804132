import React from 'react';
import PropTypes from 'prop-types';

const BackToButton = ({ btnClass, btnText, destinationPath }) => (
  <a className={btnClass} href={destinationPath}>
    {btnText}
  </a>
);

BackToButton.propTypes = {
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
  destinationPath: PropTypes.string.isRequired,
};

BackToButton.defaultProps = {
  btnClass: 'btn btn--outline-purple',
  btnText: '',
};

export default BackToButton;
