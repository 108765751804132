import React from 'react';
import PropTypes from 'prop-types';
import { snakeToTitle } from 'common/Utils';
import { ssoModelPropType } from '../Proptypes';
import { CompletedCardTable } from '../Utils';
import DeleteButton from '../Delete';
import EditButton from './EditButton';

const DISPLAY_NAME = {
  Clever: 'Clever',
  LtiKey: 'LTI',
  SamlSetting: 'SAML'
};

export default function CompletedCard({
  ssoModels,
  ssoModelPath,
  removeSSOModel,
  subscriberId,
  uploadXMLUrl,
  updateSSOModel,
  rootUrl
}) {
  function getSignInPreferences(ssoModel) {
    if (ssoModel.sign_in_preferences === null) {
      return 'N/A';
    }

    return Object.keys(ssoModel.sign_in_preferences)
      .map(preference => snakeToTitle(preference))
      .join(', ');
  }

  function buildTableRows(completedSSOs) {
    return completedSSOs.map((ssoModel) => {
      const preferences = getSignInPreferences(ssoModel);
      return [
        DISPLAY_NAME[ssoModel.model_type],
        'Completed',
        preferences,
        [
          <EditButton
            key="edit-button"
            ssoModel={ssoModel}
            ssoModelPath={ssoModelPath}
            subscriber_id={subscriberId}
            uploadXMLUrl={uploadXMLUrl}
            updateSSOModel={updateSSOModel}
            rootUrl={rootUrl}
          />,
          <DeleteButton
            key="delete-button"
            deletePath={ssoModelPath.replace(':id', ssoModel.id)}
            onDelete={removeSSOModel}
            buttonClass="btn--sm"
            wrapperClass="ml5"
            buttonLabel={(
              <span>
                <i className="fa fa-trash-o" aria-hidden />
                {' '}
                Delete
              </span>
            )}
          />
        ]
      ];
    });
  }

  return (
    <CompletedCardTable
      headers={[['Method', 'Status', 'Sign In Preference', 'Actions']]}
      contents={[buildTableRows(ssoModels)]}
      cardHeader="Single Sign On"
    />
  );
}

CompletedCard.propTypes = {
  removeSSOModel: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  ssoModelPath: PropTypes.string.isRequired,
  ssoModels: PropTypes.arrayOf(ssoModelPropType).isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateSSOModel: PropTypes.func.isRequired,
  uploadXMLUrl: PropTypes.string.isRequired
};
