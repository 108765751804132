import React from 'react';
import { ISlide } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import { slidePropTypes } from '@/components/admin/SlideShow/Utils';
import SlideCondition from '@/components/admin/LessonGuides/SlideConditions/SlideCondition';

interface SlideConditionsProps {
  slide: ISlide;
}

const SlideConditions = ({ slide }: SlideConditionsProps) => {
  // Designs have many slides' slide conditions in the drawer.
  // Once we get the backend and also data fetching done, this can be adjusted.
  const slides = [slide];

  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-h-full tw-mr-4">
      <div>
        {slides.map(s => (
          <SlideCondition
            key={`slide-condition-${s.id}`}
            slide={s}
          />
        ))}
      </div>
    </div>
  );
};

SlideConditions.propTypes = {
  slide: slidePropTypes.isRequired,
};

export default SlideConditions;
