import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'modules/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

const AutoplayPopup = ({ setAutoplayMedia }) => {
  return (
    <div className={`tw-max-w-[400px] tw-absolute tw-z-[10]
                     tw-bg-white tw-bottom-[4rem] tw-right-4
                     tw-flex tw-flex-row tw-rounded-l-md tw-rounded-r`}
    >
      <div className="tw-bg-tci-purple tw-p-5 tw-flex tw-items-center tw-rounded-l">
        <FontAwesomeIcon color="white" size="lg" icon={faBullhorn} />
      </div>
      <div className="tw-p-3 tw-rounded-r">
        <div className="tw-flex tw-flex-row">
          <div className="tw-leading-5">
            {I18n.t('autoplay_enable_message')}
          </div>
          <button
            type="button"
            className={`tw-flex tw-justify-start tw-mt-[-10px]
                        tw-bg-transparent tw-border-none
                        tw-text-xl tw-text-[#999] tw-cursor-pointer`}
            onClick={() => setAutoplayMedia(false)}
            aria-label="Close popup"
          >
            ✕
          </button>
        </div>
        <div className="tw-flex tw-justify-end tw-pt-3">
          <button
            className="btn btn--link-purple"
            onClick={() => setAutoplayMedia(false)}
          >
            {I18n.t('autoplay_disable_button')}
          </button>
          <button
            className="btn btn--purple"
            onClick={() => setAutoplayMedia(true)}
          >
            {I18n.t('autoplay_enable_button')}
          </button>
        </div>
      </div>
    </div>
  );
};

AutoplayPopup.propTypes = {
  setAutoplayMedia: PropTypes.func.isRequired,
};

export default AutoplayPopup;
