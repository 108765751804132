import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { Footer } from 'common/Modal';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import { SubmitError } from 'common/Forms/Utils';
import styles from './CompareDuplicates.module.scss';

const CompareDuplicatesPropTypes = {
  afterMergeDuplicates: PropTypes.func,
  close: PropTypes.func.isRequired,
  dontMergeCallback: PropTypes.func.isRequired,
  duplicateImages: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  })).isRequired,
  imageModel: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    displaySize: PropTypes.string.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelType: PropTypes.string.isRequired,
  newImage: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired,
  updateResources: PropTypes.func.isRequired
};

const CompareDuplicatesDefaultProps = {
  afterMergeDuplicates: null
};

const CompareDuplicatesWrapper = ({ ...props }) => (
  <ApolloProvider client={apolloClient}>
    <CompareDuplicates {...props} />
  </ApolloProvider>
);

CompareDuplicatesWrapper.propTypes = CompareDuplicatesPropTypes;
CompareDuplicatesWrapper.defaultProps = CompareDuplicatesDefaultProps;

const MERGE_DUPLICATE_IMAGES = gql`
  mutation MergeDuplicateImages($imageIds: [ID!]!, $modelId: ID!, $modelType: String!) {
    mergeDuplicateImages(imageIds: $imageIds, modelId: $modelId, modelType: $modelType) {
      result {
        updatedImageModels {
          id,
          alignment,
          captionEn,
          captionEs,
          decorative,
          displaySize,
          displayStyle,
          mediaMeta,
          modelId,
          modelType,
          snippet,
          zoomType,
          image {
            id,
            credits,
            descriptionEn,
            descriptionEs,
            imageUrl,
            imageEsUrl,
            fileName,
            resolution,
            thumbnail,
            titleEn,
            titleEs
          }
        }
      }
    }
  }
`;

const CompareDuplicates = ({ ...props }) => {
  const [mergeDuplicateImagesMutation, { loading }] = useMutation(MERGE_DUPLICATE_IMAGES);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  const mergeImages = () => {
    const imageIds = [
      parseInt(props.newImage.id, 10),
      ...props.duplicateImages.map(image => parseInt(image.id, 10))
    ];
    const variables = { imageIds: imageIds, modelId: props.modelId, modelType: props.modelType };

    mergeDuplicateImagesMutation({ variables })
      .then((result) => {
        props.updateResources(result.data.mergeDuplicateImages.result.updatedImageModels);
        if (props.afterMergeDuplicates) props.afterMergeDuplicates(result.data.mergeDuplicateImages.result.updatedImageModels);
        props.close();
      })
      .catch((error) => {
        console.log(error);
        setHasSubmitError(true);
      });
  };

  const warningIcon = () => (
    <p className={styles.warning}>
      <i className="fa fa-exclamation-triangle" />
      <strong>WARNING</strong>
    </p>
  );

  const warningInfo = () => (
    <div className="mb30">
      {warningIcon()}
      <p>The image you attempting to upload may be a duplicate image.</p>
      <p>
        Please verify the images below. If the uploaded image is a duplicate, click `Merge Images`.
        {' '}
        If the uploaded image is not a duplicate, click `Don’t Merge`.
      </p>
    </div>
  );

  const imageInfo = image => (
    <div key={image.id} className="mb20">
      <img width="200px" src={image.imageUrl} alt="..." />
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={image.imageUrl}
        >
          {image.fileName}
        </a>
      </div>
    </div>
  );

  const modalText = () => (
    <div>
      {warningInfo()}

      <div className="mb20">
        <h2>Uploaded Image:</h2>
        {imageInfo(props.newImage)}
      </div>

      <div>
        <h2>Potential Duplicate(s):</h2>
        {props.duplicateImages.map(duplicateImage => imageInfo(duplicateImage))}
      </div>
    </div>
  );

  const dontMerge = () => {
    props.dontMergeCallback(props.imageModel);
    props.close();
  };

  return (
    <Modal
      nearlyFullScreen
      closeable={false}
      isOpen={props.isOpen}
      closeModal={props.close}
      headerText="Compare Duplicate Images"
    >
      {modalText()}
      {hasSubmitError && <SubmitError error="Error merging images. Please reload the page and try again." />}
      <Footer
        primaryButtonText="Merge Images"
        secondaryButtonText="Don't Merge"
        primaryButtonCallback={mergeImages}
        primaryButtonSubmit={false}
        secondaryButtonCallback={dontMerge}
        submitting={loading}
      />
    </Modal>
  );
};

CompareDuplicates.propTypes = CompareDuplicatesPropTypes;
CompareDuplicates.defaultProps = CompareDuplicatesDefaultProps;

export default CompareDuplicatesWrapper;
