import React, { useState } from 'react';
import PaginatedTable, { usePaginatedTable } from '@/components/common/DataTable/PaginatedTable';
import { matchAttributesForSingleRow } from '@/components/common/DataTable/Utils';
import AdvancedFilters from '@/components/common/AdvancedFilters';
import { buildFilterPath } from '@/components/common/AdvancedFilters/Utils';
import { IMAGES_PATH, SEARCH_IMAGES_PATH, PAGE_SIZE, IMAGE_FILTERS, INITIAL_IMAGE_FILTERS } from './constants';
import EditImageModal, { useEditImageModal } from './EditImageModal';
import useImageTableColumns from './useImageTableColumns';

export default function ImagesTable() {
  const [filterSent, setFilterSent] = useState(false);
  const [getUrl, setGetUrl] = useState(IMAGES_PATH);

  const { openEditImageModal, ...EditImageModalProps } = useEditImageModal();

  const { columns, columnMapping, columnDefs } = useImageTableColumns({ onEditAction: openEditImageModal });

  const {
    resources,
    pagesLoaded,
    addResources,
    doneLoading,
    setDoneLoading,
    resetResources,
    updateResource,
    setPagesLoaded,
  } = usePaginatedTable(PAGE_SIZE);

  const _addResources = (_resources) => {
    addResources(_resources);

    if (filterSent) {
      resetResources();
      setFilterSent(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <AdvancedFilters
          initialFilters={INITIAL_IMAGE_FILTERS}
          properties={IMAGE_FILTERS}
          searchPath={getUrl}
          resources={resources}
          updateActiveFilters={(newFilters) => {
            setGetUrl(buildFilterPath(IMAGES_PATH, newFilters));
            setDoneLoading(false);
            resetResources();
            setPagesLoaded(0);
            setFilterSent(true);
          }}
          asyncSearchPath={SEARCH_IMAGES_PATH}
        />
        <hr />
      </div>

      <PaginatedTable
        autoWidth={false}
        columns={columns}
        columnDefs={columnDefs}
        columnMapping={columnMapping}
        exportedColumns={[1, 2, 3, 4, 5]}
        defaultOrder={[[1, 'asc'], [3, 'asc']]}
        getUrl={getUrl}
        pageSize={PAGE_SIZE}
        pagesLoaded={pagesLoaded}
        doneLoading={doneLoading}
        resources={resources}
        addResources={_addResources}
        resetResources={resetResources}
      />

      {EditImageModalProps.image && (
        <EditImageModal
          {...EditImageModalProps}
          updateRow={(rowData) => {
            matchAttributesForSingleRow(rowData, columns);
            updateResource(rowData);
          }}
        />
      )}
    </React.Fragment>
  );
}
