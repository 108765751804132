import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import I18n from 'modules/i18n';
import ButtonWithTooltip from './ButtonWithTooltip';
import Form from './Form';

const CloneButton = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <span>
      <ButtonWithTooltip
        ariaLabel={`${I18n.t('copy')} ${props.title}`}
        iconClassName="fa fa-clone"
        onClick={openModal}
        tooltipContent={I18n.t('make_a_copy')}
      />
      <Modal
        closeModal={closeModal}
        isOpen={modalIsOpen}
        headerText="Save New Presentation"
      >
        <Form
          button="clone"
          {...props}
        />
      </Modal>
    </span>
  );
};

export default CloneButton;

CloneButton.propTypes = {
  hideTabs: PropTypes.bool,
  id: PropTypes.number.isRequired,
  modalIsOpen: PropTypes.bool,
  slideShowId: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  useNewBuilder: PropTypes.bool.isRequired,
};

CloneButton.defaultProps = {
  hideTabs: false,
  modalIsOpen: false,
  slideShowId: null
};
