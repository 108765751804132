import React, { useState } from 'react';
import styles from './InlineEditWithLanguage.module.scss';
import Axios from 'axios';
import PropTypes from 'prop-types';

const Form = ({ formData, onSuccess, onCancel, updatePath, formValue, setFormValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    Axios.put(updatePath, formData).then((response) => {
      setIsLoading(false);

      if (response.data.errors) {
        console.log(response.data.errors);
        setErrorMessage(response.data.errors);
      } else {
        setErrorMessage(null);
        onSuccess();
      }
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setErrorMessage('Something went wrong. If the problem persists, please contact support.');
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          disabled={isLoading}
          className={styles.editModeInput}
          value={formValue}
          onChange={setFormValue} />

        <button type="submit" className={styles.saveIcon}>
          <i className="fa fa-check" aria-hidden></i>
        </button>
        <span onClick={onCancel} className={styles.cancelIcon}>
          <i className="fa fa-times" aria-hidden></i>
        </span>
      </form>

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </>
  );
};

Form.propTypes = {
  formData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  updatePath: PropTypes.string.isRequired,
  setFormValue: PropTypes.func.isRequired,
  formValue: PropTypes.string,
};

export default Form;
