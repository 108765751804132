import React from 'react';
import beautify from 'js-beautify';
import { Button } from '@/components/ui/Buttons';

export const BEAUTIFY_SETTINGS = {
  brace_style: 'collapse',
  break_chained_methods: false,
  comma_first: false,
  e4x: false,
  end_with_newline: false,
  indent_char: ' ',
  indent_empty_lines: false,
  indent_inner_html: false,
  indent_scripts: 'normal',
  indent_size: 2,
  jslint_happy: false,
  keep_array_indentation: false,
  max_preserve_newlines: 5,
  preserve_newlines: true,
  space_before_conditional: true,
  unescape_strings: false,
  wrap_line_length: 0,
};

type Level = 'a' | 'b' | 'spanish';

interface AutoFormatButtonProps {
  level: Level
}

const getEditor = (level: string) => {
  if (level === 'a') return window.editor;
  if (level === 'b') return window.editor2;
  if (level === 'spanish') return window.editor3;
};

const AutoFormatButton = ({ level }:AutoFormatButtonProps) => {
  const autoFormatText = () => {
    const editor = getEditor(level.toLowerCase());

    editor.setValue(
      beautify.html(editor.getValue(), BEAUTIFY_SETTINGS),
    );
  };


  return (
    <Button onClick={autoFormatText} outlineStyle small>
      <i className="fa fa-magic" />
      Auto-Format
    </Button>
  );
};

export default AutoFormatButton;
