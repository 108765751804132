import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from '@/components/staffer/AssignmentModal/WhatSection/WhatSection.module.scss';
import sectionStyles from '@/components/staffer/AssignmentModal/Section.module.scss';
import { SelectField } from '@/components/common/Forms';
import useAssignablesStore from '../store/useAssignablesStore';

const ReferenceMaterialSelector = ({ selectedType }) => {
  const [referenceMaterials, setReferenceMaterials] = useState([]);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const programId = useAssignablesStore(state => state.programId);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const initialModels = useAssignablesStore(state => state.initialValues.modelsToAssign);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);

  const getReferenceMaterials = () => {
    const path = Routes.plato_api_get_reference_material_assignables_assignables_path({
      lesson_id: selectedUnitLesson.value,
      program_id: programId,
      reference_material_type: selectedType
    });

    Axios
      .get(path)
      .then((res) => {
        setReferenceMaterials(res.data.assignables);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getReferenceMaterials();
  }, [selectedType]);

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!initialModels.length) return;

    setStore({ score: initialModels[0]['score'] });
    formChangeValue(initialModels[0].value, 'modelsToAssign');
  }, [initialModels, loadingLessons, loadingTypes]);

  const handleReferenceMaterialChange = (referenceMaterialId: string) => {
    const score = referenceMaterials.find(rm => rm.value === referenceMaterialId)?.score;
    setStore({ score });
    formChangeValue(referenceMaterialId, 'modelsToAssign');
  };

  return (
    <SelectField
      className={styles.assignableSelector}
      disabled={referenceMaterials.length === 0}
      fieldClass={styles.largeField}
      label="Details"
      labelClass={sectionStyles.label}
      name="modelsToAssign"
      searchable={false}
      placeholder="Select one"
      onChange={handleReferenceMaterialChange}
      options={referenceMaterials}
      required
      rowClass={sectionStyles.fieldContainer}
    />
  );
};

export default ReferenceMaterialSelector;
