/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const ShapesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_672_2288)">
      <path d="M15.1972 0.720215H0.799805V15.1177H15.1972V0.720215Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_672_2288">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default ShapesIcon;
