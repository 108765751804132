import React, { useEffect, useState } from 'react';
import Select from '@/components/common/ReactSelect5';
import I18n from 'modules/i18n';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';

export const ASSIGNMENT_PARAM_NAME = 'assignment_id';
export const ASSIGNMENTS_MODEL_PARAM_NAME = 'assignments_model_id';
const SLIDE_IDS_PARAM_NAME = 'slide_ids[]';
const NONE_OPTION = { label: I18n.t('none'), value: null };

const AssignmentSelect = ({ assignmentId }: { assignmentId: string }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const userType = useSlideShowContext(state => state.userType);
  const allowViewSlideshows = useSlideShowContext(state => state.allowViewSlideshows);
  const isNoneAvailable = userType === 'Student' && !allowViewSlideshows;

  useEffect(() => {
    const assignmentOptions = slideShow.slideGroupsAssignments
      .map(assignment => ({ label: assignment.assignmentLabel, value: assignment.id }))
      .sort((o1, o2) => o1.label.localeCompare(o2.label));
    const option = assignmentOptions.find(o => o.value === assignmentId);

    if (isNoneAvailable && assignmentOptions.length > 0) {
      setOptions(assignmentOptions);
      if (option) setSelectedOption(option);
      else if (assignmentOptions.length === 1) setSelectedOption(assignmentOptions[0]);
    } else {
      setOptions([NONE_OPTION, ...assignmentOptions]);
      if (assignmentId === null) {
        setSelectedOption(NONE_OPTION);
      } else {
        setSelectedOption(option || assignmentOptions[0] || NONE_OPTION);
      }
    }
  }, []);

  const onChange = (option) => {
    const url = new URL(window.location.href);

    if (option.value === NONE_OPTION.value) {
      url.searchParams.delete(SLIDE_IDS_PARAM_NAME);
      url.searchParams.delete(ASSIGNMENT_PARAM_NAME);
      url.searchParams.delete(ASSIGNMENTS_MODEL_PARAM_NAME);
      setSelectedOption(option);
      window.location.replace(url.toString());
      return;
    }

    const selectedAssignment = slideShow.slideGroupsAssignments.find(assignment => assignment.id === option.value);
    const slideGroupIds = selectedAssignment.assignmentsModels.map(a => a.modelId.toString());
    const slideIds = slideShow.slideGroups
      .filter(g => slideGroupIds.includes(`${g.id}`))
      .flatMap(group => group.slides.map(s => s.id));

    url.searchParams.delete(SLIDE_IDS_PARAM_NAME);
    [...new Set(slideIds)].forEach(id => url.searchParams.append(SLIDE_IDS_PARAM_NAME, id.toString()));
    url.searchParams.set(ASSIGNMENT_PARAM_NAME, option.value);

    setSelectedOption(option);
    window.location.replace(url.toString());
  };

  return (
    <label className="tw-flex tw-items-center tw-w-full tw-mr-8" htmlFor="slideshow-assignment-select">
      <span className="tw-align-middle tw-mr-2 tw-mb-[2px] tw-text-[11px]">{I18n.t('assignment')}:</span>
      <Select
        id="slideshow-assignment-select"
        value={selectedOption}
        className="tw-w-full"
        options={options}
        onChange={onChange}
        menuHeightOverride="32px"
        fontSizeOverride="11px"
      />
    </label>
  );
};

export default AssignmentSelect;
