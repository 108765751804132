import { useEffect } from 'react';
import { PRESENT_NAVIGATOR_ID } from '@/components/admin/SlideShow/PresentMode/Constants';

const VJS_CLASS = '.video-js';
const VJS_CONTROL_BAR_CLASS = '.vjs-control-bar';

/**
 * This hook adds an event listener on window resize to reposition the video's
 * control bar to prevent it from being covered up by the slide show navigator.
 */

const navigator = () => $(`#${PRESENT_NAVIGATOR_ID}`)[0];

export const repositionControlBar = () => {
  document.querySelectorAll(VJS_CLASS).forEach((video: HTMLVideoElement) => {
    const controlBar = video.querySelector(VJS_CONTROL_BAR_CLASS) as HTMLDivElement;

    if (window.getComputedStyle(controlBar, null).display === 'none') return;

    for (let i = 0, offset = 0; i < 100 && (offset < -1 || offset >= 0); i += 1) {
      const controlBarDimensions = controlBar.getBoundingClientRect();
      const navigatorDimensions = navigator().getBoundingClientRect();
      const currentBottom = parseFloat(controlBar.style.bottom) || 0;

      offset = controlBarDimensions.bottom - navigatorDimensions.top;

      controlBar.style.bottom = `${Math.max(currentBottom + (offset > 0 ? 1 : -1), 0)}px`;
    }
  });
};

const useVideoControlBarRepositioner = (slideId: number | string) => {
  useEffect(() => {
    const onResize = () => repositionControlBar();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [slideId]);
};

export default useVideoControlBarRepositioner;
