import React, { useState } from 'react';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import pluralize from 'pluralize';

interface LockTeacherModalProps {
  action: 'lock' | 'unlock';
  closeModal: () => void;
  modalIsOpen: boolean;
  stafferIds: Set<number>;
  subscriberId: number;
  updateTable: (any) => void;
}

const LockTeacherModal = ({
  action, closeModal, modalIsOpen, stafferIds, subscriberId, updateTable,
} : LockTeacherModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const locking = action === 'lock';

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    Axios.post(
      Routes.plato_api_bulk_lock_subscriber_staffers_path(
        subscriberId,
        { lock: action === 'lock', staffer_ids: [...stafferIds] },
      ),
    ).then((res) => {
      updateTable({ updatedStaff: res.data.staffers });
    }).catch((err: Error) => {
      console.error(err);
    }).finally(() => {
      setSubmitting(false);
      closeModal();
    });
  };

  return (
    <SubmitModal
      headerText={locking ? 'Lock Access' : 'Unlock Access'}
      close={closeModal}
      isOpen={modalIsOpen}
      onSubmit={handleSubmit}
      submitText={locking ? 'Yes, lock access' : 'Yes, unlock access'}
      submittingText={locking ? 'Locking…' : 'Unlocking…'}
    >
      <span className="tw-text-base">
        {locking ? 'Locking' : 'Unlocking'} a user&apos;s access will {!locking && 'no longer'} redirect them to the
        Locked Access page after signing in. Are you sure you want to {locking ? 'lock' : 'unlock'}&nbsp;
        {pluralize('user', stafferIds.size, true)}?
      </span>
    </SubmitModal>
  );
};

export default LockTeacherModal;
