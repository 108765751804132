import React, { useState } from 'react';
import I18n from 'i18n-js';
import Pluralize from 'pluralize';
import axios from 'axios';
import {
  clone_shared_program_lesson_deck_path,
  clone_tci_deck_shared_program_lesson_decks_path,
  edit_shared_program_lesson_deck_path,
  shared_program_lesson_glossary_cards_path,
  plato_api_deck_path, pdf_shared_program_lesson_decks_path,
} from '@/modules/routes';
import Card from '@/components/ui/Card';
import { useModalManager, ConfirmModal } from '@/components/common/Modal';
import DeckUserIcon from '@/components/common/VocabularyCards/DeckUserIcon';
import { IVocabDecksPageDeck } from '@/components/common/VocabularyCards/interfaces';
import { useVocabDecksStore } from '@/components/common/VocabularyCards/variables';
import tciVocabDeckIcon from '../../../../images/icons/tci-vocab-deck-icon.svg';
import PrintModal from '@/components/shared/PrintModal';

interface VocabDeckCardProps {
  deck: IVocabDecksPageDeck;
  refetch: Function;
}

const useActionUrls = (deck: IVocabDecksPageDeck, isTciDeck: boolean) => {
  const lessonId = useVocabDecksStore(state => state.lessonId);
  const programId = useVocabDecksStore(state => state.programId);

  if (isTciDeck) {
    return ({
      copyUrl: clone_tci_deck_shared_program_lesson_decks_path(programId, lessonId),
      editUrl: clone_tci_deck_shared_program_lesson_decks_path(programId, lessonId),
      viewUrl: shared_program_lesson_glossary_cards_path(programId, lessonId),
    });
  }

  return ({
    copyUrl: clone_shared_program_lesson_deck_path(programId, lessonId, deck.id),
    editUrl: edit_shared_program_lesson_deck_path(programId, lessonId, deck.id),
    viewUrl: shared_program_lesson_glossary_cards_path(programId, lessonId, { deck_id: deck.id }),
  });
};

const VocabDeckCard = ({ deck, refetch }: VocabDeckCardProps) => {
  const isTciDeck = deck.staffer_id === 0;
  const { copyUrl, editUrl, viewUrl } = useActionUrls(deck, isTciDeck);
  const lessonId = useVocabDecksStore(state => state.lessonId);
  const programId = useVocabDecksStore(state => state.programId);
  const currentUser = useVocabDecksStore(state => state.currentUser);
  const deleteModalManager = useModalManager();
  const printModalManager = useModalManager();
  const [isDeleting, setIsDeleting] = useState(false);

  const actions = () => {
    const studentActions = [
      { view: viewUrl },
      { print: '' },
    ];

    if (currentUser.type === 'Student') return studentActions;

    const defaultActions = [
      { view: viewUrl },
      { edit: editUrl },
      { copy: copyUrl },
      { print: '' },
    ];

    if (isTciDeck) return defaultActions;

    return [...defaultActions, { delete: '' }];
  };

  const actionOptions = () => ({
    delete: { onClick: deleteModalManager.open, shouldNavigate: false },
    print: { onClick: printModalManager.open, shouldNavigate: false, tooltipContent: I18n.t('print_text') },
  });

  const deleteVocabularyCards = () => {
    setIsDeleting(true);

    return axios.delete(plato_api_deck_path(deck.id)).then(() => {
      setIsDeleting(false);
      refetch();
      deleteModalManager.close();
    });
  };

  const renderDeleteModal = () => (
    <ConfirmModal
      confirmButtonText={isDeleting ? `${I18n.t('deleting')}...` : I18n.t('yes_delete')}
      confirmationText={I18n.t('delete_vocab_cards_message')}
      isLoading={isDeleting}
      isOpen={deleteModalManager.isOpen}
      headerText={I18n.t('delete_vocab_cards')}
      onCancel={deleteModalManager.close}
      onConfirm={deleteVocabularyCards}
    />
  );

  return (
    <Card className="tw-max-w-[245px] tw-min-w-[245px] tw-box-border">
      <a href={viewUrl}>
        <div className="tw-min-h-[110px] tw-bg-[#eee] tw-flex tw-items-center tw-justify-center tw-relative">
          <img alt={deck.title_en} src={tciVocabDeckIcon} />
          <DeckUserIcon deck={deck} />
        </div>
      </a>
      <Card.Body className="tw-p-2 tw-pt-5">
        <a href={viewUrl} className="tw-font-bold tw-text-base">
          {I18n.currentLocale() === 'en' ? deck.title_en : deck.title_es}
        </a>
        <p className="tw-italic tw-text-dark-grey tw-text-sm tw-pt-2">
          {`${deck.total_terms} ${I18n.t(Pluralize('term', deck.total_terms))}`}
        </p>
        <Card.Actions
          className="tw-pt-5"
          actions={actions()}
          actionOptions={actionOptions()}
        />
        {!isTciDeck && deleteModalManager.isOpen && renderDeleteModal()}
        <PrintModal
          closeModal={printModalManager.close}
          headerText={`${I18n.t('print')} ${I18n.t('vocabulary_cards')}`}
          modalIsOpen={printModalManager.isOpen}
          url={pdf_shared_program_lesson_decks_path(programId, lessonId, { deck_id: deck?.id })}
        />
      </Card.Body>
    </Card>
  );
};

export default VocabDeckCard;
