import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import Cookie from 'react-cookies';

import { RequiredAsterisk } from '@/components/common/Forms/Utils';
import CheckboxGroup from '@/components/common/Forms/CheckboxGroup';
import NotebooksAndReadingCheckboxes from '../Details/NotebooksAndReadingCheckboxes';
import IndeterminateCheckboxChecker from '../IndeterminateCheckboxChecker';
import sectionStyles from '../../Section.module.scss';
import styles from '../WhatSection.module.scss';
import useAssignablesStore from '../store/useAssignablesStore';

const ReadingAssignablesSelector = ({ form }) => {
  const [detailedAssignables, setDetailedAssignables] = useState([]);
  const [simpleAssignables, setSimpleAssignables] = useState([]);
  const [simpleAssignablesLoaded, setSimpleAssignablesLoaded] = useState(false);
  const [detailedAssignablesLoaded, setDetailedAssignablesLoaded] = useState(false);
  const initialCookieValue = !(Cookie.load('viewAllDetails') === 'false');
  const [viewAllDetails, setViewAllDetails] = useState(initialCookieValue);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const selectedType = useAssignablesStore(state => state.selectedType);
  const programId = useAssignablesStore(state => state.programId);
  const initialModels = useAssignablesStore(state => state.initialValues.modelsToAssign);
  const readingAssignableSelections = useAssignablesStore(state => state.initialValues.readingAssignableSelections);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);

  const getDetailedAssignables = () => {
    const detailedAssignablesPath: string = Routes.plato_api_get_reading_assignables_assignables_path(
      { lesson_id: selectedUnitLesson.value, program_id: programId },
    );

    Axios
      .get(detailedAssignablesPath)
      .then((res) => {
        setDetailedAssignables(res.data.assignables);
        setDetailedAssignablesLoaded(true);
      })
      .catch(err => console.error(err));
  };

  const getSimpleAssignables = () => {
    let simpleAssignablesPath: string;

    if (selectedType === 'sectionNotebook') {
      simpleAssignablesPath = Routes.plato_api_get_simple_reading_assignables_assignables_path(
        { lesson_id: selectedUnitLesson.value, program_id: programId },
      );
    }
    else {
      simpleAssignablesPath = Routes.plato_api_get_unit_project_assignables_assignables_path(
        { unit_id: selectedUnitLesson.value },
      );
    }

    Axios
      .get(simpleAssignablesPath)
      .then((res) => {
        setSimpleAssignables(res.data.assignables);
        setSimpleAssignablesLoaded(true);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (!selectedUnitLesson?.value) return;

    if (selectedType === 'sectionNotebook') getDetailedAssignables();
    getSimpleAssignables();
  }, [selectedUnitLesson]);

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!initialModels.length) return;

    formChangeValue(initialModels, 'modelsToAssign');
  }, [initialModels, loadingLessons, loadingTypes]);

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;

    if (!readingAssignableSelections?.length) return;
    if (!detailedAssignables.length) return;

    setViewAllDetails(true);
    const data = {};
    detailedAssignables.forEach((readingAssignable) => {
      const assignablesValues = (readingAssignable.assignables || []).filter(a => a != null).map(a => a.value);

      const selected = readingAssignableSelections.filter(f => assignablesValues.includes(f));

      if (selected.length > 0) {
        const key = `notebook-reading-${readingAssignable.id}`;
        data[key] ||= [];
        selected.forEach(s => data[key].push(s));
      }
    });

    Object.keys(data).forEach(key => formChangeValue(data[key], key));
  }, [readingAssignableSelections, detailedAssignables, loadingLessons, loadingTypes]);

  useEffect(() => {
    if (!(selectedType === 'unitProjects')) return;
    if (!(simpleAssignables[0])) return;

    const toAssign = form.getState().values.modelsToAssign;
    let score = 0;

    toAssign.forEach((value) => {
      score += simpleAssignables.find(s => s.value === value)['score'];
    })
    setStore({ score });
  }, [form.getState().values.modelsToAssign, simpleAssignables]);

  useEffect(() => {
    if (!(selectedType === 'sectionNotebook' && !viewAllDetails)) return;

    let selectedAssignments = form.getState().values.modelsToAssign.map(item => item.split(',')).flat();
    const values = form.getState().values;
    let score = 0;

    Object.entries(values).forEach(([key, vlist]: [string, string[]]) => {
      if (key && vlist && key.startsWith('notebook-reading-')) {
        vlist.forEach((v) => {
          if (!(selectedAssignments.includes(v))) selectedAssignments.push(v);
        })
      }
    });

    selectedAssignments.forEach((assignment) => {
      const section = simpleAssignables.find(s => s.value.includes(assignment));
      if (section) score += section.score[assignment];
    })

    setStore({ score });
  }, [form.getState().values]);

  const toggleReadingAssignables = () => {
    const readingSorter = (a, b) => {
      const value = (item) => {
        if (item.includes('Section')) return 1;
        if (item.includes('Notebook')) return 2;
        if (item.includes('Reading')) return 3;
      };

      return value(a) - value(b);
    };

    const assignablesBySectionId = form.getState().values.modelsToAssign.reduce((acc, cur) => {
      const sectionId = cur.match(/Section-\d+/g)[0]?.replace('Section-', '');

      if (!sectionId) return acc;

      return { ...acc, [sectionId]: true };
    }, {});

    detailedAssignables.forEach((readingAssignable) => {
      const assignables = readingAssignable.assignables.filter(a => !!a);

      const key = `notebook-reading-${readingAssignable.id}`;
      const inputValue = assignables.map(assignable => assignable.value).sort(readingSorter).join(',');

      if (viewAllDetails && selectedType === 'sectionNotebook') {
        const { values } = form.getState();
        let nextState = form.getState().values.modelsToAssign;
        const newValue = assignables.map(a => a.value).sort(readingSorter).join(',');

        if (values[key]?.length > 0) {
          if (!nextState.includes(newValue)) nextState = [...nextState, newValue];

          // If not all values have been assigned, set the simple input value to indeterminate
          if (values[key].length !== assignables.length) {
            setTimeout(() => {
              const input = document.querySelector(`input[name="modelsToAssign"][value="${inputValue}"]`);

              if (input) input['indeterminate'] = true;
            }, 25);
          }
          else {
            formChangeValue(nextState, 'modelsToAssign');
          }
        }
        else {
          nextState = nextState.filter(value => value !== newValue);
          formChangeValue(nextState, 'modelsToAssign');
        }
      }
      else {
        if (!assignablesBySectionId[readingAssignable.id]) {
          return;
        }

        const assignablesArr = assignables.map(assignable => assignable.value);

        // if indeterminate, do not set values to default
        const input = document.querySelector(`input[name="modelsToAssign"][value="${inputValue}"]`);
        if (input['indeterminate']) return;

        formChangeValue(assignablesArr, key);
      }
    });
  };

  const renderReadingDetailsToggle = () => {
    if (detailedAssignables?.length) {
      const toggleViewAllDetails = () => {
        toggleReadingAssignables();

        setViewAllDetails(prevState => {
          Cookie.save('viewAllDetails', !prevState, { path: '/' });
          return !prevState;
        });
      };
      const action = viewAllDetails ? 'Hide' : 'View';

      return (
        <button
          type="button"
          className="btn btn--sm btn--link-purple tw-float-right"
          onClick={toggleViewAllDetails}
        >
          <i
            className="fa fa-pencil-square-o"
            aria-label={`${action} all details`}
          />
          &nbsp;
          {action} All Details
        </button>
      );
    }

    return null;
  };

  if (viewAllDetails && selectedType === 'sectionNotebook') {
    return (
      <>
        <span className={`${sectionStyles.detailList}`}>
          <span className={sectionStyles.label}>Details<RequiredAsterisk /></span>
          <div className={styles.largeField}>
            <span>Select one to assign</span>
            {renderReadingDetailsToggle()}
          </div>
        </span>
        {detailedAssignablesLoaded && simpleAssignablesLoaded && (
          <NotebooksAndReadingCheckboxes
            assignables={detailedAssignables}
            values={form.getState().values}
            formChangeValue={formChangeValue}
          />
        )}
      </>
    );
  }

  const renderSectionNotebookAssignables = () => (
    <Field
      checkboxRowStyle={sectionStyles.detailOption}
      bulkSelectText="All"
      component={CheckboxGroup}
      formChangeValue={formChangeValue}
      hasBulkSelect
      key="section-notebook-assignables"
      legendText="Assign All."
      legendSrOnly
      name="modelsToAssign"
      options={simpleAssignables}
    />
  );

  return (
    <>
      <span className={sectionStyles.detailList}>
        <span className={sectionStyles.label}>Details<RequiredAsterisk /></span>
        <div className={styles.largeField}>
          <span>Select one to assign</span>
          <IndeterminateCheckboxChecker
            form={form}
            formChangeValue={formChangeValue}
            readingAssignables={detailedAssignables}
            viewAllDetails={viewAllDetails}
          />
          {renderReadingDetailsToggle()}
          <div className="clear" />
          {renderSectionNotebookAssignables()}
        </div>
      </span>
    </>
  );
};

export default ReadingAssignablesSelector;
