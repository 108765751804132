import React from 'react';
import { Field } from 'react-final-form';
import CheckboxGroup from 'components/common/Forms/CheckboxGroup';
import BulkSelectCheckbox from './BulkSelectCheckbox';
import useAssignablesStore from "@/components/staffer/AssignmentModal/WhatSection/store/useAssignablesStore";

export interface NotebooksAndReadingCheckboxesProps {
  assignables: { id: number, fullTitle: string, assignables: { label: string, value: string }[] }[];
  formChangeValue: ((value: any, key: string) => {}) | (() => void);
  values: { [key: string]: string[] }
}

const NotebooksAndReadingCheckboxes = ({ assignables, formChangeValue, values }: NotebooksAndReadingCheckboxesProps) => {
  const setStore = useAssignablesStore(state => state.setStore);
  let score = 0;

  Object.entries(values).forEach(([key, vlist]) => {
    if (key && vlist && key.startsWith('notebook-reading-')) {
      const id = parseInt(key.split('-')[2]);
      const assignable = assignables.find(assignable => assignable.id === id);

      if (assignable) {
        vlist.forEach((v) => {
          score += assignable.assignables.filter(a => a !== null)
            .find(a => a.value === v)['score'];
        })
      }
    }
  });

  setStore({ score });

  const sectionCheckboxes = section => (
    <Field
      bulkSelectText={section.fullTitle}
      checkboxLabelSrOnly
      component={CheckboxGroup}
      formChangeValue={formChangeValue}
      hasBulkSelect
      inTable
      alignLeft
      key={`${section.fullTitle}`}
      legendText={`Select what to assign for ${section.fullTitle}`}
      legendSrOnly
      name={`notebook-reading-${section.id}`}
      options={section.assignables}
    />
  );

  const tableHeader = () => (
    <thead>
      <tr className="tw-h-5 tw-align-middle">
        <th className="tw-w-[20rem]" />
        <th>
          <BulkSelectCheckbox
            assignables={assignables}
            formChangeValue={formChangeValue}
            label="Text"
            values={values}
            valueKey="Section"
          />
        </th>
        <th>
          <BulkSelectCheckbox
            assignables={assignables}
            formChangeValue={formChangeValue}
            label="Reading Check"
            values={values}
            valueKey="ReadingGame"
          />
        </th>
        <th>
          <BulkSelectCheckbox
            assignables={assignables}
            formChangeValue={formChangeValue}
            label="Reading Notes"
            values={values}
            valueKey="Notebook"
          />
        </th>
      </tr>
    </thead>
  );

  return (
    <table className="tw-mt-[10px]">
      {tableHeader()}
      <tbody>
        {assignables.map(section => sectionCheckboxes(section))}
      </tbody>
    </table>
  );
};

export default NotebooksAndReadingCheckboxes;
