define([], function() {
  function init() {
    setup_popups();
  }

  function setup_popups() {
    $('.js_popup_trigger').on('click', function clickPopupTrigger(e) {
      e.preventDefault();
      open_popup(this);
    });

    $('.js_popup_close').on('click', () => close_popups());

    $('.js_popup_trigger_hover').hover(() => open_popup(this));
  }

  function open_popup(target) {
    const $wrapper = $(target).closest('.js_popup_wrapper');
    const $popup = $wrapper.find('.js_popup');

    close_popups($popup);

    if (!$popup.is(':visible')) {
      $popup.stop(true, true).fadeIn(100);
    }
  }

  function close_popups($exclude) {
    $('.js_popup').not($exclude).stop(true, true).fadeOut(100);
  }

  return {
    init: init
  };
});
