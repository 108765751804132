import React from 'react';
import I18n from 'i18n-js';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Menu } from '@/components/common/LessonPlanning';
import menuStyles from '@/components/common/LessonPlanning/LessonPlanning.module.scss';

interface SlideTypeToggleProps {
  hasArchivedContent?: boolean;
  hideTabs: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string | number>>
  toggleShowArchived?: () => void;
}

const SlideTypeToggle = ({
  hasArchivedContent = false,
  hideTabs,
  setSelected,
  toggleShowArchived = () => {
  },
}: SlideTypeToggleProps) => (
  <div>
    {!hideTabs && (
      <div className="tw-relative">
        <ScrollMenu
          data={Menu(
            [
              { id: 0, title: I18n.t('tci_content'), ariaControlId: 'tciContentContainer' },
              { id: 1, title: I18n.t('my_slideshows'), ariaControlId: 'mySlideshowsContainer' },
            ],
            'resource-0',
          )}
          itemClass={menuStyles.itemWrapper}
          menuClass={menuStyles.horizontalMenu}
          selected="resource-0"
          onSelect={setSelected}
          alignCenter={false}
          hideSingleArrow
          hideArrows
          wheel={false}
          dragging={false}
        />
        {hasArchivedContent && (
          <span className="tw-absolute tw-top-0 tw-right-0">
            <label htmlFor="show_archived_content" className="tw-mt-3">
              <input
                id="show_archived_content"
                className="tw-mr-2"
                name="show_archived_content"
                type="checkbox"
                onChange={toggleShowArchived}
              />

              {I18n.t('show_archived_content')}
            </label>
          </span>
        )}
      </div>
    )}
  </div>
);

export default SlideTypeToggle;
