import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';

const DropdownItemButton = ({
  onClick, icon, text, disabled, disabledTooltipText
}) => {
  const buttonContent = () => (
    <Fragment>
      <i className={`fa fa-${icon}`} aria-hidden />
      {` ${text}`}
    </Fragment>
  );

  function renderDisabledItem() {
    const disabledItem = (
      <span
        className="dropdown-item disabled"
      >
        {buttonContent()}
      </span>
    );

    if (disabledTooltipText) {
      return (
        <Tooltip
          interactive={false}
          content={disabledTooltipText}
        >
          {disabledItem}
        </Tooltip>
      );
    }

    return (disabledItem);
  }

  const renderItem = () => (
    <button type="button" className="dropdown-item full-size" onClick={onClick}>
      {buttonContent()}
    </button>
  );

  return (disabled ? renderDisabledItem() : renderItem());
};

DropdownItemButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

DropdownItemButton.defaultProps = {
  disabled: false,
  disabledTooltipText: null,
};

export default DropdownItemButton;
