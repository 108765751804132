import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import ReactSelectAdapter from '../../../common/AdvancedFilters/ReactSelectAdapter';
import styles from './MyAccount.module.scss';
import Tooltip from '../../../common/Tooltip';

const MyAccountDropdown = ({
  className, label, name, options, tooltip
}) => (
  <div className={`${styles.row} ${className}`}>
    <label id={`${name}_id`} className={styles.label} htmlFor={name}>
      {label}
      {tooltip && (
        <Tooltip content={tooltip}>
          <i className={`${styles.tooltipIcon} fa fa-info-circle`} />
        </Tooltip>
      )}
    </label>
    <Field
      className={styles.myAccountDropdown}
      name={name}
      component={ReactSelectAdapter}
      clearable={false}
      searchable={false}
      bindSelectRef={() => {}}
      options={options}
    />
  </div>
);

MyAccountDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  })).isRequired,
  tooltip: PropTypes.string
};

MyAccountDropdown.defaultProps = {
  className: '',
  tooltip: undefined
};

export default MyAccountDropdown;
