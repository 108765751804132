import React from 'react';
import BorderPopover from './BorderPopover';
import { EditPopoverProps } from '../types';

const EditBorderPopover = ({ anchorId, slideObject, styleDivRef, dropdownEvents }: EditPopoverProps) => {
  const openerClass = `border-popover-opener-${slideObject.id}`;

  return (
    <>
      <a
        className={`dropdown-item ${openerClass}`}
        href="#"
        role="button"
        onClick={() => dropdownEvents.setOpen(false)}
      >
        <BorderPopover
          anchorId={anchorId}
          openerClass={openerClass}
          slideObject={slideObject}
          styleDivRef={styleDivRef}
        />
        Change Border
      </a>

    </>
  );
};

export default EditBorderPopover;
