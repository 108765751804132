import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { samlSettingPropTypes, ltiKeyPropTypes } from '../Proptypes';
import wizards from './constants';
import { completedStepsPropTypes } from '../steps';

export default class ConfigurationSteps extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    ssoModel: PropTypes.shape({
      id: PropTypes.number,
      model_type: PropTypes.string,
      model: PropTypes.oneOfType([samlSettingPropTypes, ltiKeyPropTypes]),
    }),
    ssoModelPath: PropTypes.string.isRequired,
    rootUrl: PropTypes.string.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    initialStep: PropTypes.number,
    completedSteps: completedStepsPropTypes.isRequired,
  };

  static defaultProps = {
    ssoModel: null,
    initialStep: 0
  };

  _renderSteps(Element) {
    return (
      <Element
        closeModal={this.props.closeModal}
        ssoModel={this.props.ssoModel}
        ssoModelPath={this.props.ssoModelPath}
        rootUrl={this.props.rootUrl}
        uploadXMLUrl={this.props.uploadXMLUrl}
        updateSSOModel={this.props.updateSSOModel}
        completedSteps={this.props.completedSteps}
        initialStep={this.props.initialStep > 1 ? this.props.initialStep - 1 : 0}
      />
    );
  }

  render() {
    return this._renderSteps(wizards[this.props.ssoModel.model_type]);
  }
}
