import React, { Component } from 'react';
import Axios from 'axios/index';
import styles from './ImageSelector.module.scss';

export default class ImageSelector extends Component {
  static defaultProps = {
    images: [],
    selectedImage: null
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <div className={styles['selector-wrapper']}>
        <div className={styles['images-container']}>
          {this.renderThumbs()}
        </div>
        <div>
          {this.renderAddImageButton()}
        </div>
      </div>
    )
  }

  componentWillMount() {
    this.getImages();
  }

  getImages() {
    let url = this.props.getImagesUrl;

    Axios.get(url).then(response => {
      let images = response.data;
      console.log('get', response);
      this.setState({
        images: images,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  selectImage(id) {
    this.setState({ selectedImage: id });
    console.log('select', id, this.state.selectedImage);
  }

  renderAddImageButton() {
    return (
      <div className="block_link" onClickCapture={() => { this.props.onAddImage(this.state.selectedImage) }}>
        Use Image
      </div>
    )
  }

  renderThumbs() {
    return this.state.images && this.state.images.map((image) => {
      return (
        <figure key={image.id} onClickCapture={() => this.selectImage(image.id)} >
          <div>
            <img src={image.urls.thumb}
                 className={this.state.selectedImage == image.id ? styles['selected-image'] : ''} />
          </div>
          <figcaption>{image.filename}</figcaption>
        </figure>
      )
    })
  }
}