import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import Modal, { Footer } from '../../../common/Modal';
import { renderErrors, SubmitError } from '../../../common/Forms/Utils';

import styles from './Form.module.scss';

export default class TransferModal extends Component {
  static propTypes = {
    subscriberName: PropTypes.string.isRequired,
    transferPath: PropTypes.string.isRequired,
    signUpCodesPath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      submitting: false,
      subscriberName: props.subscriberName
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .post(this.props.transferPath, values)
      .then((response) => {
        this.setState({ subscriberName: response.data.staffer.subscriber_name, submitting: false });
        this.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  render() {
    return (
      <div id="transfer-modal-container">
        {`${this.state.subscriberName} `}
        <a href="#" className="p10" role="button" onClick={this.openModal} id="join_another_district">
            Change District
            <i aria-label="Join Another District" className={`fa fa-edit ${styles.p2}`} />
        </a>
        <Modal
          className="w850"
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          headerText="Join Another District"
        >
          <Form
            onSubmit={this.handleSubmit}
            render={({
                       form, handleSubmit, pristine, submitError
                     }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formRow}>
                  <label htmlFor="signup_code" className={styles.label}>
                    Account Code
                  </label>
                  <Field
                    id="signup_code"
                    name="signup_code"
                    component="input"
                    placeholder="Enter account code"
                    className={styles.textInput}
                    parse={val => (val === null ? '' : val)}
                  />
                </div>
                <p className={styles.infoText}>Not sure what your code is? {' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.signUpCodesPath}
                  >
                    Request an account code
                  </a>
                </p>

                <SubmitError error={submitError} />

                <Footer
                  secondaryButtonCallback={this.state.closeModal}
                  primaryButtonDisabled={pristine}
                  submitting={this.state.submitting}
                />
              </form>)}
          />
        </Modal>
      </div>
    );
  }
}
