import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import moment from 'moment-timezone';
import { RequiredAsterisk } from '../../../common/Forms/Utils';
import DatePicker from './DatePicker';
import { assignmentInitialValuesPropType } from '../PropTypes';
import styles from './WhenSection.module.scss';
import sectionStyles from '../Section.module.scss';

export default class DateTimePicker extends Component {
  static propTypes = {
    callback: PropTypes.func,
    currentDate: PropTypes.objectOf(moment),
    fieldName: PropTypes.string.isRequired,
    formHandleSelect: PropTypes.func.isRequired,
    initialValues: assignmentInitialValuesPropType,
    label: PropTypes.string,
    required: PropTypes.bool,
    timeBuffer: PropTypes.number,
    validator: PropTypes.func.isRequired,
    wrapperClass: PropTypes.string
  };

  static defaultProps = {
    callback: () => {},
    currentDate: moment(),
    initialValues: {},
    label: '',
    required: true,
    timeBuffer: 1,
    wrapperClass: ''
  };

  static renderErrors(params) {
    if (!params.meta.pristine && params.meta.error) {
      return (
        <div className={sectionStyles.fieldError}>{params.meta.error}</div>
      );
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      date: null,
      time: null,
      dateTime: null,
      minDate: null,
      minTime: this.props.currentDate.clone().startOf('day')
    };

    this.renderDateTimeField = this.renderDateTimeField.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleManualTimeChange = this.handleManualTimeChange.bind(this);
    this.handleManualDateChange = this.handleManualDateChange.bind(this);
  }

  componentDidMount() {
    const state = { minDate: this.calculateMinDate() };
    if (this.props.initialValues[this.props.fieldName]) this.setInitialDates();
    this.setState(state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setInitialDates();
    }
  }

  setInitialDates() {
    if (this.props.initialValues[this.props.fieldName] === undefined) {
      return;
    }

    const initialDateTime = moment.utc(this.props.initialValues[this.props.fieldName], 'YYYY-MM-DD H:mm:ss').local();
    const state = {
      dateTime: initialDateTime,
      date: initialDateTime,
      time: initialDateTime,
      minTime: this.calculateMinTime(initialDateTime)
    };

    this.setState(state);
  }

  getMinMinutes() {
    const currentMinutes = this.props.currentDate.get('minutes');
    return currentMinutes < 30 ? 0 : 30;
  }

  calculateMinDate() {
    const isBefore11PM = this.props.currentDate.get('hour') < 23;

    if (isBefore11PM) {
      return this.props.currentDate;
    }

    return this.props.currentDate.clone().add({ date: 1 });
  }

  calculateMinTime(dateSelected) {
    const isToday = dateSelected.isSame(this.props.currentDate, 'date');

    if (isToday) {
      // Set minimum time based on the time buffer given
      return this.props.currentDate.clone()
        .add({ hour: this.props.timeBuffer })
        .set({ minutes: this.getMinMinutes() });
    }

    return this.props.currentDate.clone().startOf('day'); // set minimum time to 12:00 am
  }

  handleDateChange(date) {
    if (date.isValid()) {
      const dateTime = this.props.currentDate.clone().set({
        month: date.get('month'),
        date: date.get('date'),
        year: date.get('year'),
        hour: 23,
        minutes: 59,
        seconds: 0,
        milliseconds: 0
      });

      this.setState({
        date, time: dateTime, dateTime, minTime: this.calculateMinTime(date)
      }, () => {
        this.props.formHandleSelect(this.formattedDateTime(), this.props.fieldName);
        this.props.callback(this.formattedDateTime());
      });
    }
    else {
      this.setState({
        date: null, time: null, dateTime: null, minDate: this.calculateMinDate()
      }, () => this.props.formHandleSelect(null, this.props.fieldName));
    }
  }

  handleManualDateChange(manuallyEnteredDate) {
    const date = moment(
      manuallyEnteredDate.target.value,
      ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY']
    );

    this.handleDateChange(date);
  }

  handleTimeChange(time) {
    if (time.isValid() && this.state.date) {
      const dateTime = this.state.dateTime.clone().set({
        hour: time.get('hour'),
        minutes: time.get('minutes'),
        seconds: 0,
        milliseconds: 0
      });

      this.setState(
        { time, dateTime },
        () => {
          this.props.formHandleSelect(this.formattedDateTime(), this.props.fieldName);
          this.props.callback(this.formattedDateTime());
        }
      );
    }
    else {
      this.setState(
        { time: null },
        () => this.props.formHandleSelect(null, this.props.fieldName)
      );
    }
  }

  handleManualTimeChange(manuallyEnteredTime) {
    const time = moment(manuallyEnteredTime.target.value, 'h:mm A');
    this.handleTimeChange(time);
  }

  formattedDateTime() {
    return ({
      // format the value saved to the form as UTC
      value: moment.utc(this.state.dateTime).format('YYYY-MM-DD H:mm:ss UTC')
    });
  }

  renderDatePicker(meta) {
    return (
      <DatePicker
        className={`${styles.picker} ${!meta.pristine && meta.error ? styles.hasError : ''}`}
        selected={this.state.date ? moment(this.state.date).toDate() : null}
        onChange={date => this.handleDateChange(moment(date))}
        onChangeRaw={this.handleManualDateChange}
        dateFormat="MM / dd / yyyy"
        placeholderText="MM / DD / YYYY"
        popperPlacement="top"
        minDate={this.state.minDate}
        disabledKeyboardNavigation
      />
    );
  }

  renderTimePicker(meta) {
    return (
      <DatePicker
        className={`${styles.picker} ${!meta.pristine && meta.error ? styles.hasError : ''}`}
        selected={this.state.time ? moment(this.state.time).toDate() : null}
        onChange={date => this.handleTimeChange(moment(date))}
        onChangeRaw={this.handleManualTimeChange}
        timeIntervals={30}
        dateFormat="h:mm a"
        timeCaption="Time"
        showTimeSelect
        showTimeSelectOnly
        placeholderText="Select Time"
        popperPlacement="top"
        minTime={this.state.minTime}
        maxTime={this.props.currentDate.clone().set({ hour: 23, minutes: 30 })}
        disabled={!this.state.date}
        disabledKeyboardNavigation
      />
    );
  }

  renderDateTimeField(params) {
    return (
      <div className={`${sectionStyles.row} mb10`}>
        <div className={sectionStyles.fieldContainer}>
          <div className={sectionStyles.label}>
            {this.props.label}
            {this.props.required && <RequiredAsterisk />}
          </div>

          <div className={`${styles.pickerContainer} ${sectionStyles.selectColumns}`}>
            {this.renderDatePicker(params.meta)}
            {this.renderTimePicker(params.meta)}
          </div>
        </div>

        {DateTimePicker.renderErrors(params)}
      </div>
    );
  }

  render() {
    return (
      <span
        className={this.props.wrapperClass}
      >
        <Field
          name={this.props.fieldName}
          render={this.renderDateTimeField}
          validate={this.props.validator}
        />
      </span>
    );
  }
}
