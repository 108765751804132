import { Field } from 'react-final-form';
import React from 'react';
import I18n from 'i18n';
import { getDate, getDateTime } from '../../../modules/TCIUtils';

/**
 * Match attributes for a single row/object.
 * @param resource Object
 * @param columns Array of column headers
 */
export function matchAttributesForSingleRow(resource, columns) {
  // find any attributes that are listed in the column attributes that weren't passed in from the api response
  // and appends them to the response object so DataTables can correctly consume the object (e.g. action columns)
  const resourceKeys = Object.keys(resource);
  const difference = columns.filter(x => !resourceKeys.includes(x));

  if (difference) {
    difference.forEach((key) => {
      const value = resource[key];
      if (!value) resource[key] = '';
    });
  }
}

/**
 * Match attributes for an array of objects/multiple rows.
 * @param resource Array of objects
 * @param columns Array of column headers
 */
export function matchAttributesForMultipleRows(resources, columns) {
  if (!resources.length) return [];

  // find any attributes that are listed in the column attributes that weren't passed in from the api response
  // and appends them to the response object so DataTables can correctly consume the object (e.g. action columns)
  const resourceKeys = Object.keys(resources[0]);
  const difference = columns.filter(x => !resourceKeys.includes(x));

  if (difference) {
    resources.forEach((resource) => {
      difference.forEach((key) => {
        const value = resource[key];
        if (!value) resource[key] = '';
      });
    });
  }
}

/**
 * Loops through all the flags and returns a checkbox + label per flag if userIsSysadmin is true
 * @param userIsSysadmin boolean
 * @param flags Hash array of flags ({name: 'FlagName', label: 'FlagLabel'})
 * @param rowStyle CSS class string
 * @param checkboxLabelStyle CSS class string
 */

export function renderFlagFields(userIsSysadmin, flags, rowStyle, checkboxLabelStyle) {
  if (!userIsSysadmin) return null;

  return flags.map(flag => (
    <div className={rowStyle} key={flag.name}>
      <Field
        id={flag.name}
        name={flag.name}
        component="input"
        type="checkbox"
      />
      <label htmlFor={flag.name} className={checkboxLabelStyle}>
        {flag.label}
      </label>
    </div>
  ));
}

/**
 * To be used in the render property for DataTable columnDefs.
 * Returns a function that applies a formatter function to the cell data when displaying the table.
 * This allows the cell to be sorted using the original data format while displaying in another format.
 * This also allows the cell data to be filtered by the displayed format.
 * @param formatter The function to be applied on the data
 */
export function formatDataForDisplay(formatter) {
  return (data, type) => {
    if (type === 'display' || type === 'filter') {
      return formatter(data);
    }

    return data;
  };
}

export function formatCellAsDate() {
  return formatDataForDisplay(getDate);
}

export function formatCellAsDateWithTime() {
  return formatDataForDisplay(getDateTime);
}

export function expandCollapseAllRows(dt) {
  if (!dt) return;

  const button = dt.button('expandCollapse:name');
  const expanded = button.text() === `${I18n.t('collapse_all')}`;
  // Find indexes of rows with 'shown' class when collapsing, and rows without 'shown' when expanding
  const indexes = dt.rows().eq(0).filter((rowIdx) => {
    const row = dt.row(rowIdx).node();
    if (!row) return false;
    return expanded === (row.classList.contains('shown'));
  });

  dt.rows(indexes).nodes().to$().find('td:first-child')
    .trigger('click');

  if (button.text() === `${I18n.t('collapse_all')}`) {
    button.text(`${I18n.t('expand_all')}`);
  }
  else if (button.text() === `${I18n.t('expand_all')}`) {
    button.text(`${I18n.t('collapse_all')}`);
  }
}

export function expandAllRows(dt) {
  if (!dt) return;

  dt.rows(':not(.parent):not(.shown)').nodes().to$().find('td:first-child')
    .trigger('click');

  dt.button('expandCollapse:name').text(I18n.t('collapse_all'));
}

const specialChars = ['-', '=', '+', '@', '%', '|'];
export const sanitizeCell = cell => (specialChars.includes(cell[0]) ? `'${cell}` : cell);
