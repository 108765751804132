import React, { useEffect } from 'react';
import { Input } from '@/components/ui/Form';
import useCredentialStore from '@/components/admin/integrations/RosteringOverview/AssignmentAndGrades/useCredentialStore';
import { useFormContext } from 'react-hook-form';
import LtiConfiguration, { formWrapper, LtiConfigurationProps } from './LtiConfiguration';

const CanvasConfiguration = ({
  subscriberId, next, previous, setLmsType, initialLmsType,
}: LtiConfigurationProps) => {
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);

  const {
    formState: {
      errors,
    },
    register,
    watch,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (initialLmsType === 'canvas_1_3') {
      setValue('client_id', ltiCredentials.client_id);
      setValue('deployment_id', ltiCredentials.deployment_id);
    }
  }, []);

  const deploymentId = watch('deployment_id');
  const clientId = watch('client_id');

  return (
    <LtiConfiguration
      initialLmsType={initialLmsType}
      setLmsType={setLmsType}
      subscriberId={subscriberId}
      next={next}
      previous={previous}
      disabled={!deploymentId || !clientId}
      lmsType="canvas_1_3"
    >
      <div className="tw-mb-3">
        Provide the credentials unique to your LTI 1.3 app installation. For instructions, see
        {' '}
        <a
          href="https://teachtci.com/how-to-set-up-canvas-lti-1-3-admin"
          target="_blank"
          rel="noreferrer noopener"
        >
          How to set up LTI 1.3
        </a>
      </div>

      <Input
        variant="inline"
        errors={errors}
        placeholder="Enter Client ID"
        label="Client ID"
        required
        {...register('client_id', {
          required: 'Required',
          setValueAs: (value: string) => value.replace(/\s/g, ''),
        })}
      />

      <Input
        variant="inline"
        errors={errors}
        placeholder="Enter Deployment ID"
        label="Deployment ID"
        required
        {...register('deployment_id', {
          required: 'Required',
          setValueAs: (value: string) => value.replace(/\s/g, ''),
        })}
      />
    </LtiConfiguration>
  );
};

export default formWrapper(CanvasConfiguration);
