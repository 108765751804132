import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { EditableDiv, Input } from '@/components/ui/Form';
import clsx from 'clsx';
import I18n from 'i18n-js';
import {
  MIN_COL_WIDTH_CLASS,
  SchemaType,
} from '@/components/common/VocabularyCards/variables';
import TermImageInput from '@/components/common/VocabularyCards/Forms/TermImageInput';
import TermDeleteButton from '@/components/common/VocabularyCards/Forms/TermDeleteButton';

interface VocabTermRowProps {
  field: FieldArrayWithId<SchemaType, 'deck.glossary_term_models'>;
  index: number;
  locale: 'es' | 'en';
  remove: UseFieldArrayRemove;
}

const VocabTermRow = ({ field, index, remove, locale }: VocabTermRowProps) => {
  const { register } = useFormContext<SchemaType>();
  const [shouldHighlight, setShouldHighlight] = useState(false);

  return (
    <Draggable draggableId={`TERM-${field.id}`} index={index} disableInteractiveElementBlocking>
      {provided => (
        <div
          className={clsx('tw-flex tw-gap-6 tw-items-start tw-transition-colors tw-py-2 tw-px-1 tw-rounded tw-max-w-site', {
            'tw-bg-light-grey': shouldHighlight,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <button
            className={`tw-border-none tw-bg-transparent tw-flex tw-justify-center tw-items-center tw-cursor-pointer 
                        tw-text-xl tw-cursor-move tw-mt-2`}
            type="button"
            {...provided.dragHandleProps}
          >
            <i className="fa fa-arrows" />
          </button>
          <Input
            className={clsx(MIN_COL_WIDTH_CLASS, 'tw-rounded-md')}
            label={I18n.t('term')}
            LabelProps={{ className: 'sr-only' }}
            {...register(`deck.glossary_term_models.${index}.glossary_term.term_${locale}`)}
          />
          <EditableDiv
            className={clsx(MIN_COL_WIDTH_CLASS, 'tw-rounded-md')}
            label={I18n.t('definition')}
            LabelProps={{ className: 'sr-only' }}
            name={`deck.glossary_term_models.${index}.glossary_term.definition_${locale}`}
          />
          <div className="tw-flex tw-justify-between tw-flex-1">
            <TermImageInput index={index} />
            <TermDeleteButton
              index={index}
              onMouseEnter={() => setShouldHighlight(true)}
              onMouseLeave={() => setShouldHighlight(false)}
              remove={remove}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default VocabTermRow;
