import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import AddForm from './AddForm';

export default function AddModal({
  closeModal, modalIsOpen, createPath, classroomsPath, updateTable
}) {
  return (
    <Modal
      closeModal={closeModal}
      headerText="Add Student"
      isOpen={modalIsOpen}
    >
      <AddForm
        closeModal={closeModal}
        createPath={createPath}
        updateTable={updateTable}
        classroomsPath={classroomsPath}
      />
    </Modal>
  );
}

AddModal.propTypes = {
  classroomsPath: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  createPath: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  updateTable: PropTypes.func.isRequired
};
