import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import DeleteModal from './DeleteModal';

class Delete extends Component {
  static propTypes = {
    buttonClass: PropTypes.string,
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    deleteMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    deletePath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    wrapperClass: PropTypes.string
  };

  static defaultProps = {
    buttonClass: '',
    buttonLabel: <span aria-label="delete"><i className="fa fa-trash-o" aria-hidden="true" /></span>,
    deleteMessage: <p>To confirm deletion, type <em>delete me</em> into the field.</p>,
    onDelete: () => {},
    wrapperClass: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  _openModal() {
    this.setState({ isModalOpen: true });
  }

  _closeModal() {
    this.setState({ isModalOpen: false });
  }

  _renderModal() {
    if (this.state.isModalOpen) {
      return (
        <DeleteModal
          closeModal={this._closeModal}
          deletePath={this.props.deletePath}
          onDelete={this.props.onDelete}
          deleteMessage={this.props.deleteMessage}
          handleSubmit={this.props.handleSubmit}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div className={`inline_block ${this.props.wrapperClass}`}>
        <Button
          onClick={this._openModal}
          label={this.props.buttonLabel}
          buttonClass={this.props.buttonClass}
        />

        {this._renderModal()}
      </div>
    );
  }
}

export default Delete;
