import React, { useState, useEffect } from 'react';
import AddImage from 'components/admin/ImageManager/Add';
import EditImage from 'components/admin/ImageManager/Edit';
import apolloClient from 'components/common/ApolloClient';
import { ApolloProvider, useQuery } from '@apollo/client';
import { useModalManager } from 'components/common/Modal';
import { FETCH_LESSON_IMAGE, ApiResponse, FlattenedImageModel, ImageModel } from './fetchImageGql';

interface LessonImageProps {
  modelId: number;
}

export const LessonImage = ({ modelId }: LessonImageProps) => {
  const queryData = { variables: { id: modelId } };
  const { loading, data, refetch } = useQuery(FETCH_LESSON_IMAGE, queryData);
  const afterSubmit = () => refetch(queryData.variables);
  const modalManager = useModalManager();
  const hasImage = () => data && data.lesson && data.lesson.imageModel;

  const parseImageModel = (imageData: ApiResponse): FlattenedImageModel | {} => {
    if (loading || !hasImage()) return {};
    // spread so we get a copy of the object that is mutable
    const imageModel: ImageModel = { ...imageData.lesson.imageModel };
    // prefix all `imageModel.image` keys with `_` and add them to `imageModel`
    // to match the expected data structure
    Object.keys(imageModel.image).forEach((key) => { imageModel[`image_${key}`] = imageModel.image[key]; });
    return imageModel;
  };

  const [imageModel, setImageModel] = useState(parseImageModel(data));

  useEffect(() => {
    setImageModel(parseImageModel(data));
  }, [hasImage()]);

  const renderButton = () => {
    if (loading) return null;

    if (hasImage()) {
      return (
        <EditImage
          afterMergeDuplicates={afterSubmit}
          afterSubmit={afterSubmit}
          dontMergeCallback={afterSubmit}
          excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
          imageModel={imageModel}
          modelId={modelId}
          modelType="Lesson"
          modalManager={modalManager}
        />
      );
    }

    return (
      <AddImage
        afterMergeDuplicates={afterSubmit}
        afterSubmit={afterSubmit}
        dontMergeCallback={afterSubmit}
        excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
        modelType="Lesson"
        modelId={modelId}
        modalManager={modalManager}
      />
    );
  };

  return (
    <div className="input file optional lesson_image">
      <label className="file optional" htmlFor="lesson_image">Lesson Image</label>
      {renderButton()}
    </div>
  );
};

/* istanbul ignore next */
const LessonImageWrapper = ({ modelId }: LessonImageProps) => (
  <ApolloProvider client={apolloClient}>
    <LessonImage modelId={modelId} />
  </ApolloProvider>
);

export default LessonImageWrapper;
