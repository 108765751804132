import React, { useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import DropdownMenu from '@/components/common/Dropdown/DropdownMenu';
import { closeOnEscapeKey } from '@/components/common/Utils';
import { popupCoordinates } from './Utils';
import ImageSizeEditor, { Data } from './ImageSizeEditor';

const EditImageWithCaptionsSizePopover = ({ handleClose, menuRef, refreshEditor, wrapperEl }) => {
  closeOnEscapeKey(handleClose);

  const findSubElements = () => {
    let imageElement: HTMLElement, captionElement: HTMLElement;
    wrapperEl.children.forEach((el: HTMLElement) => {
      if (el.nodeName === 'IMG') imageElement = el;
      if (el.nodeName === 'P') captionElement = el;
    });

    return { imageElement, captionElement };
  };

  const getInitialDimensions = () => {
    const { imageElement } = findSubElements();
    const initialHeight = $(imageElement).css('height');
    const initialWidth = $(imageElement).css('width');

    return {
      initialHeight: parseInt(initialHeight, 10) || 0,
      initialWidth: parseInt(initialWidth, 10) || 0
    };
  };

  const coordinates = useMemo(() => {
    return popupCoordinates(menuRef);
  }, [menuRef?.current]);

  const handleChange = (data: Data) => {
    const { width, height } = data;
    const { imageElement, captionElement } = findSubElements();

    if (width) {
      imageElement.style.width = `${data.width}px`;
      captionElement.style.width = `${data.width}px`;
      if (!height) {
        imageElement.style.height = '';
        captionElement.style.height = '';
      }
    }
    if (height) {
      imageElement.style.height = `${data.height}px`;
      if (!width) {
        imageElement.style.width = '100%';
        captionElement.style.width = 'auto';
      }
    }
  };

  const handleSubmit = (data: Data): Promise<any> => {
    handleChange(data);
    refreshEditor();
    handleClose();

    return new Promise(() => {});
  };

  const { initialHeight, initialWidth } = getInitialDimensions();

  return ReactDOM.createPortal(
    <DropdownMenu open={true} coordinates={coordinates}>
      {initialHeight && initialWidth && (
        <ImageSizeEditor
          initialHeight={initialHeight}
          initialWidth={initialWidth}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
    </DropdownMenu>,
    document.body,
  );
};

export default EditImageWithCaptionsSizePopover;
