import PropTypes from 'prop-types';
import React from 'react';

export default function Checkbox({
  checked, className, handleChange, id, indeterminate, input, label, onClick, srOnly, ...props
}) {
  const setCheckboxRef = (checkbox) => {
    if (checkbox) {
      checkbox.indeterminate = indeterminate;
    }
  };

  function renderLabelText() {
    if (srOnly) {
      return <span className="sr-only">{label}</span>;
    }

    return label;
  }

  return (
    <label className={className}>
      <input
        type="checkbox"
        id={id}
        {...input}
        onClick={onClick}
        onChange={handleChange}
        checked={checked}
        ref={setCheckboxRef}
        {...props}
      />
      {renderLabelText()}
    </label>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  checked: PropTypes.bool
};

Checkbox.defaultProps = {
  className: '',
  indeterminate: false,
  onClick: () => {},
  handleChange: () => {},
  checked: false
};
