import React, { FormEvent, useEffect, useState } from 'react';
import i18n from '@/modules/i18n';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import ReactSelect5 from '@/components/common/ReactSelect5';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_staffer_path } from '@/modules/routes';

interface CorrelationsSettingsModalProps {
  grade: number | null;
  programId: number;
  refetch: () => any;
  stafferId: number;
  state: string;
}

const LABEL_CLASSES = 'tw-w-[65px] tw-font-bold';
const GRADES = ['K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
const GRADE_OPTIONS = GRADES.map((label, value) => ({ label, value }));

const CorrelationsSettingsModal = ({
  grade: initialGrade,
  programId,
  refetch,
  stafferId,
  state,
}: CorrelationsSettingsModalProps) => {
  const { isOpen, close, open } = useModalManager();
  const [grade, setGrade] = useState(initialGrade);

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ grade: newGrade }: { grade: number }) => (
      axios
        .patch(plato_api_staffer_path(stafferId), {
          correlations: { [programId]: newGrade },
        })
        .then(res => res.data)
    ),
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await mutateAsync({ grade });

    refetch();
    close();
  };

  useEffect(() => {
    if (initialGrade === null) open();
  }, [initialGrade]);

  return (
    <>
      <p className="tw-flex tw-items-center tw-justify-end tw-gap-1.5 tw-m-2.5">
        <span>{i18n.t('state')}: {state}</span>
        {' | '}
        <span>{i18n.t('grade')}: {grade === null ? 'N/A' : GRADES[grade]}</span>
        <button
          className="tw-outline-none tw-bg-inherit tw-border-none tw-text-xl tw-p-0 tw-cursor-pointer"
          onClick={open}
          type="button"
        >
          <i className="fa fa-edit tw-text-tci-purple" />
        </button>
        <SubmitModal
          close={close}
          headerText={`${i18n.t('correlation')} ${i18n.t('settings')}`}
          isOpen={isOpen}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          submitText={i18n.t('save')}
          submittingText={`${i18n.t('saving')}...`}
        >
          <div className="tw-flex tw-flex-col tw-gap-4 tw-min-h-[225px]">
            <div>
              <label className={LABEL_CLASSES} htmlFor="state">
                {i18n.t('state')}:
              </label>
              <span>{state}</span>
            </div>
            <div className="tw-flex tw-items-baseline">
              <label className={LABEL_CLASSES} htmlFor="grade">
                {i18n.t('grade')}:
              </label>
              <ReactSelect5
                className="tw-min-w-[100px]"
                defaultValue={GRADE_OPTIONS.find(opt => opt.value === grade)}
                dropdownHeightOverride={170}
                name="grade"
                onChange={({ value }) => setGrade(value)}
                options={GRADE_OPTIONS}
              />
            </div>
            <p className="tw-text-red tw-italic tw-text-xs">
              These settings apply to everywhere correlations can be seen throughout the website.
            </p>
          </div>
        </SubmitModal>
      </p>
    </>
  );
};

export default CorrelationsSettingsModal;
