import React from 'react';
import PropTypes from 'prop-types';
import { inIframe } from 'modules/TCIUtils';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { PRESENT_NAVIGATOR_ID, SLIDE_SETTINGS_STORAGE_KEY } from '../Constants';
import styles from './Navigator.module.scss';
import { FullScreenPropTypes } from './Controls/Settings/FullScreen';
import AutoplayPopup from './AutoplayPopup';
import Navigation, { NavigationPropTypes } from './Controls/Navigation';
import Settings, { useSettings } from './Controls/Settings';
import BackToButton, { BackToButtonPropsTypes } from './Controls/BackToButton';
import { SlideShowEditButtonPropTypes } from './Controls/Settings/SlideShowEditButton';
import SlideStandards from './Controls/SlideStandards';
import SlideAudioControl, { SlideAudioControlMutedPropTypes } from './Controls/SlideAudioControl';
import useFlags from '../../../../../hooks/useFlags';
import { autoplayMediaSetting, slidesHaveAutoplayAudioOrVideo } from '../Utils';

const Navigator = ({
  BackToButtonProps, FullScreenProps, NavigationProps, SlideAudioControlProps, SlideShowEditButtonProps, slideShow
}) => {
  const { settings, update, toggleAutoplayMedia } = useSettings(SLIDE_SETTINGS_STORAGE_KEY, { autoHide: false });
  const { autoplayAudio } = useFlags();
  const slides = useSlideShowContext(state => state.slides);
  const hasUnsetAutoplaySetting = autoplayMediaSetting(slideShow.id) == null;
  const needsAutoplaySetting = autoplayAudio &&
                                 slidesHaveAutoplayAudioOrVideo(slides) &&
                                 hasUnsetAutoplaySetting;

  const setAutoplayMedia = (value) => {
    toggleAutoplayMedia(value, slideShow.id);
  };

  return (
    <div className={`${styles.navigator} notranslate`}>
      <div id={PRESENT_NAVIGATOR_ID} className={`${styles.content} ${settings.autoHide ? styles.autoHide : ''}`}>
        {!inIframe() ? (
          <BackToButton {...BackToButtonProps} position={NavigationProps.position} />
        ) : <div />}
        <Navigation {...NavigationProps} />
        <div className="tw-flex tw-justify-self-end">
          <SlideStandards />
          {autoplayAudio &&
            <SlideAudioControl
              {...SlideAudioControlProps}
              setVolumeInSettings={(value) => update('backgroundAudioVolume', value)}
              volumeFromSettings={settings.backgroundAudioVolume}
            />
          }
          {needsAutoplaySetting && <AutoplayPopup setAutoplayMedia={setAutoplayMedia} />}
          <Settings
            fullScreenProps={FullScreenProps}
            needsExplanationTooltip={needsAutoplaySetting}
            slideShowEditButtonProps={SlideShowEditButtonProps}
            settings={settings}
            update={update}
            slideShow={slideShow}
            toggleAutoplayMediaEnabled={setAutoplayMedia}
          />
        </div>
      </div>
    </div>
  );
};

Navigator.propTypes = {
  BackToButtonProps: BackToButtonPropsTypes.isRequired,
  FullScreenProps: FullScreenPropTypes.isRequired,
  NavigationProps: NavigationPropTypes.isRequired,
  SlideAudioControlProps: SlideAudioControlMutedPropTypes.isRequired,
  SlideShowEditButtonProps: SlideShowEditButtonPropTypes.isRequired,
  slideShow: PropTypes.object.isRequired,
};

export default Navigator;
