import React, { useState } from 'react';
import Tooltip from '@/components/ui/Tooltip';
import Popover from '@/components/ui/Popover';
import { twMerge } from '@/utils';
import cn from '@/utils/cn';
import { capitalizeWords } from '@/modules/TCIUtils';
import I18n from '@/modules/i18n';
import useDrawingContext from '../../store/useDrawingContext';
import { buttonStyle, imgStyle, popoverStyle } from '../styles';

interface ButtonProps {
  alt: string;
  children: ReactChildren;
  disabled?: boolean;
  src: string;
  type: 'opacity' | 'weight';
}

const Button = ({ alt, children, disabled = false, src, type }: ButtonProps) => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const isDisabled = useDrawingContext(state => state.disabled) || disabled;
  const [isOpen, setIsOpen] = useState(false);

  const buttonClassName = twMerge(
    'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
    'hover:tw-bg-tci-purple ',
    'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
  );

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Popover onOpenChange={setIsOpen}>
          <Tooltip.Trigger asChild>
            <Popover.Trigger
              style={buttonStyle(toolbarEl)}
              className={cn(
                buttonClassName, { 'tw-bg-tci-purple': isOpen },
              )}
              disabled={isDisabled}
            >
              <div
                aria-label={capitalizeWords(type)}
                className="tw-flex tw-items-center tw-justify-center tw-rounded"
              >
                <img
                  style={imgStyle({ toolbarEl })}
                  alt={alt}
                  src={src}
                />
              </div>
            </Popover.Trigger>
          </Tooltip.Trigger>
          <Popover.Content
            align="start"
            aria-label={I18n.t(type)}
            className={cn(
              'tw-absolute tw-left-2 tw-transition-all tw-py-8 tw-px-4 tw-flex tw-flex-col tw-gap-10',
            )}
            side="right"
            sideOffset={4}
            style={popoverStyle(toolbarEl)}
          >
            {children}
          </Popover.Content>
          <Tooltip.Content
            className={cn(
              'tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2',
              'tw-outline tw-outline-1 tw-outline-gray-300',
            )}
            side="right"
            sideOffset={10}
          >
            {alt}
          </Tooltip.Content>
        </Popover>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default Button;
