import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { IApiSlideShow, IApiUnit } from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';

const indeterminateCheckboxClasses = `
  indeterminate:after:tw-content-['-'] indeterminate:after:tw-absolute indeterminate:after:tw-inset-0 
  indeterminate:after:tw-z-10 indeterminate:after:tw-flex indeterminate:after:tw-items-center 
  indeterminate:after:tw-justify-center indeterminate:after:tw-rounded-md
`;

const getUnitCheckboxState = (unit: IApiUnit, slideShowIds: number[]) => {
  const slideShowStatuses = unit.slide_shows.map(ss => slideShowIds.includes(ss.id));

  if (slideShowStatuses.reduce((acc, cur) => acc && cur)) return 'all';

  if (slideShowStatuses.some(status => status)) return 'indeterminate';

  return 'none';
};

interface UnitCheckboxesProps {
  setSelectedSlideShows: React.Dispatch<React.SetStateAction<IApiSlideShow[]>>
  slideShowIds: number[];
  unit: IApiUnit;
}

const UnitCheckboxes = ({ setSelectedSlideShows, slideShowIds, unit }: UnitCheckboxesProps) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();
  const unitCheckboxRef = useRef<HTMLInputElement>();

  const toggleSlideShow = (slideShow: IApiSlideShow) => {
    if (slideShowIds.includes(slideShow.id)) setSelectedSlideShows(prev => prev.filter(ss => ss.id !== slideShow.id));
    else setSelectedSlideShows(prev => ([...prev, slideShow]));
  };

  const toggleUnit = (toggledUnit: IApiUnit) => {
    const isEachSlideShowActive = toggledUnit
      .slide_shows
      .reduce((acc, cur) => slideShowIds.includes(cur.id) && acc, true);

    const unitSlideShowIds = toggledUnit.slide_shows.map(ss => ss.id);

    if (isEachSlideShowActive) setSelectedSlideShows(prev => prev.filter(ss => !unitSlideShowIds.includes(ss.id)));
    else setSelectedSlideShows(prev => ([...prev, ...toggledUnit.slide_shows]));
  };

  const changeHeight = () => {
    if (height !== 0) {
      setHeight(0);
      return;
    }

    setHeight(ref?.current.clientHeight);
  };

  const checkboxState = getUnitCheckboxState(unit, slideShowIds);

  useEffect(() => {
    if (!unitCheckboxRef) return;
    if (!unitCheckboxRef.current) return;
    if (checkboxState !== 'indeterminate') {
      unitCheckboxRef.current.indeterminate = false;
      return;
    }

    unitCheckboxRef.current.indeterminate = true;
  }, [unitCheckboxRef?.current, checkboxState]);

  return (
    <div className="tw-pb-4">
      <div className="tw-relative tw-flex tw-items-center">
        <input
          checked={checkboxState === 'all'}
          className={indeterminateCheckboxClasses}
          onChange={() => toggleUnit(unit)}
          ref={unitCheckboxRef}
          type="checkbox"
        />
        <span className="tw-truncate tw-max-w-[75%]">{unit.title}</span>
        <button
          className="tw-absolute tw-top-0 tw-right-0 tw-bg-transparent tw-border-none tw-cursor-pointer tw-z-10"
          onClick={changeHeight}
          type="button"
        >
          <span
            className={clsx({
              'fa fa-chevron-down': true,
              'tw-transition-all': true,
              'tw-rotate-180': height !== 0,
            })}
          />
        </button>
      </div>
      <div style={{ height }} className="tw-overflow-hidden tw-transition-all">
        <div ref={ref} className="tw-pt-1">
          {unit.slide_shows.map(slideShow => (
            <div className="tw-ml-4 tw-text-xs tw-flex tw-items-center" key={slideShow.id}>
              <input
                type="checkbox"
                checked={slideShowIds.includes(slideShow.id)}
                onChange={() => toggleSlideShow(slideShow)}
              />
              <span className="tw-truncate">{slideShow.title_en}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnitCheckboxes;
