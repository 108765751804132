import React, { useState, useEffect } from 'react';

import ActionsDropdown from 'components/common/ActionsDropdown';
import { useModalManager } from 'components/common/Modal';
import BulkDeleteModal from './BulkDeleteModal';

interface BulkActionProps {
  studentIds: Set<number>;
  subscriberId: number;
  updateTable: (data: any, action: string) => void;
}

const BulkAction = ({ studentIds, subscriberId, updateTable }: BulkActionProps) => {
  const bulkDeleteModalManager = useModalManager();

  return (
    <>
      <ActionsDropdown toggleText="Bulk Actions">
        <a
          className="dropdown-item"
          href="#"
          onClick={bulkDeleteModalManager.open}
          role="button"
        >
          <i aria-hidden="true" className="fa fa-trash-o" /> Delete
        </a>

      <BulkDeleteModal
        modalManager={bulkDeleteModalManager}
        studentIds={studentIds}
        subscriberId={subscriberId}
        updateTable={updateTable}
      />
      </ActionsDropdown>
    </>
  );
}

export default BulkAction;
