import React from 'react';

const AltTextFieldTooltip = () => (
  <span>
    See
    {' '}
    <a
      href="http://teachtci.com/tci-alt-text-best-practice"
      rel="noopener noreferrer"
      target="_blank"
    >
      this document
    </a>
    {' '}
    for best practices.
  </span>
);

export default AltTextFieldTooltip;
