import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_slide_object_panoramic_images_path } from '@/modules/routes';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';

const usePanoramicImage = (slideObjectId: string | number) => (
  useQuery({
    enabled: (!!slideObjectId),
    queryFn: () => (
      axios
        .get<{ data: IPanoramicImage[] }>(plato_api_slide_object_panoramic_images_path(slideObjectId))
        .then((res) => {
          if (!res.data.data) return null;

          return res.data.data[0];
        })
    ),
    queryKey: ['usePanoramicImages', slideObjectId],
  })
);

export default usePanoramicImage;
