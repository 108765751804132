import React from 'react';
import cn from '@/utils/cn';
import MapHeader from './MapHeader';
import styles from './styles.module.scss';

interface InteractiveMapProps {
  appId: string;
  showHeader?: boolean;
  title: string;
  instructions: string;
}

const InteractiveMap = ({ appId, showHeader, title, instructions } : InteractiveMapProps) => {
  if (!appId || appId.length === 0) return <p> No Map here </p>;

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col">
      {showHeader && (
        <MapHeader title={title} instructions={instructions} />
      )}
      <iframe
        className={cn('tw-w-full tw-h-full', {
          [styles.slideObjectInteractiveMapFrame]: !showHeader,
        })}
        src={`https://teacherscurricul.maps.arcgis.com/apps/instant/media/index.html?appid=${appId}`}
        title={title}
        loading="lazy"
        allowFullScreen
      >iFrames are not supported on this page.
      </iframe>
    </div>
  );
};

InteractiveMap.defaultProps = {
  showHeader: false,
};

export default InteractiveMap;
