import React from 'react';
import I18n from 'i18n-js';
import { LinkButton, SubmitButton } from '@/components/ui/Buttons';

interface SubmitFooterProps {
  buttonColor?: ButtonColors;
  close: () => void;
  isLoading?: boolean;
  isValid?: boolean;
  onSubmit?: () => void;
  submitText?: string;
  submittingText?: string;
}

const SubmitFooter = ({
  buttonColor = 'purple',
  close,
  isLoading,
  isValid,
  onSubmit,
  submitText = 'Submit',
  submittingText = 'Submitting...',
}: SubmitFooterProps) => (
  <div>
    <hr />
    <footer className="tw-flex tw-justify-end tw-items-baseline">
      <LinkButton onClick={close}>
        {I18n.t('cancel')}
      </LinkButton>
      <SubmitButton
        color={buttonColor}
        disabled={!isValid}
        onClick={onSubmit}
        submitting={isLoading}
        submittingText={submittingText}
      >
        {submitText}
      </SubmitButton>
    </footer>
  </div>
);

export default SubmitFooter;
