import React, { useState } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import Modal, { useModalManager, Footer } from 'common/Modal';
import * as Routes from 'routes';

const ProgramSyncModal = ({ subscriberId, syncInProgress }) => {
  const [isRunning, setIsRunning] = useState(syncInProgress);
  const modalManager = useModalManager();

  const runSync = () => {
    const path = Routes.plato_api_run_program_assignment_sync_subscriber_auto_roster_syncs_path({ id: subscriberId });
    Axios.get(path)
      .then(() => {
        setIsRunning(true);
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setIsRunning(false);
      });
  };

  return (
    <React.Fragment>
      <button className="btn btn--outline-purple ml10" type="button" onClick={modalManager.open} disabled={isRunning}>
        <i className="fa fa-refresh mr5" aria-hidden="true" />
        {isRunning ? 'Sync in progress' : 'Update Program Assignments'}
      </button>

      <Modal headerText="Update Program Assignments" isOpen={modalManager.isOpen} closeModal={modalManager.close}>
        <h1>Are you sure you want to run a program assignment sync for this district?</h1>
        <ul className="bullet_list pt20">
          <li>This sync can take up to a few hours</li>
          <li>This sync may remove access to programs from teachers and students</li>
          <li>Remember to check if there is already a sync running from this district</li>
        </ul>

        <Footer
          primaryButtonText="Run Sync"
          primaryButtonCallback={runSync}
          primaryButtonSubmit={false}
          secondaryButtonCallback={modalManager.close}
          secondaryButtonText="Cancel"
        />
      </Modal>
    </React.Fragment>
  );
};

ProgramSyncModal.propTypes = {
  subscriberId: PropTypes.number.isRequired,
  syncInProgress: PropTypes.bool.isRequired
};

export default ProgramSyncModal;
