/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

interface UseDropdownMenuArgs {
  anchorId: string;
  afterToggle?: () => void;
  dropdownEvents: {
    handleOutsideClick: () => {};
    open: boolean;
    setOpen: (prev) => {};
  }
  openerClass: string;
  position?: string;
  ref: React.MutableRefObject<HTMLDivElement>;
}

type Coordinates = {
  left: number;
  top: number;
};

const HORIZONTAL_PADDING = 22;
const VERTICAL_PADDING = -8;

const usePopoverRelocator = (anchorId: string, popoverRef: React.MutableRefObject<HTMLDivElement>, position?: string): Coordinates => {
  const [coordinates, setCoordinates] = useState<Coordinates>({ left: 0, top: 0 });

  useEffect(() => {
    if (!popoverRef.current) return null;

    const relocate = () => {
      const anchor = document.getElementById(anchorId);

      if (!anchor) return;

      const rect = anchor.getBoundingClientRect();

      if (position === 'bottom left') {
        if (!popoverRef.current) return;
        // Unhide the popover in order to get its dimensions
        const currentDisplay = popoverRef.current.style.display;
        popoverRef.current.style.display = 'block';
        const popoverRect = popoverRef.current.getBoundingClientRect();
        popoverRef.current.style.display = currentDisplay;

        setCoordinates({
          left: rect.x + rect.width - popoverRect.width,
          top: rect.y + rect.height + window.scrollY,
        });

        return;
      }

      setCoordinates({
        left: rect.x + HORIZONTAL_PADDING,
        top: rect.y + VERTICAL_PADDING + window.scrollY,
      });
    };

    relocate();
    document.addEventListener('scroll', relocate, true);
    window.addEventListener('resize', relocate);

    return () => {
      document.removeEventListener('scroll', relocate);
      window.removeEventListener('resize', relocate);
    };
  }, [anchorId, popoverRef.current]);

  return coordinates;
};

interface UsePopover {
  coordinates: Coordinates;
  isUpdated: boolean;
  setIsUpdated: (isUpdated) => void;
}

const usePopover = ({ afterToggle, anchorId, dropdownEvents, openerClass, ref, position }: UseDropdownMenuArgs): UsePopover => {
  const coordinates = usePopoverRelocator(anchorId, ref, position);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    const toggle = (e) => {
      if (ref.current && ref.current.contains(e.target)) return;
      if (!e.target.classList.contains(openerClass)) {
        if (isUpdated) setIsUpdated(false);
        dropdownEvents.setOpen(false);
        return;
      }
      dropdownEvents.setOpen(prev => !prev);
    };

    document.addEventListener('click', toggle);

    return () => document.removeEventListener('click', toggle);
  }, [openerClass, isUpdated]);

  useEffect(() => {
    if (afterToggle) afterToggle();
  }, [dropdownEvents.open]);

  return { coordinates, isUpdated, setIsUpdated };
};

export default usePopover;
