import React from 'react';
import I18n from 'i18n-js';
import ButtonWithTooltip from '@/components/shared/LessonOverview/Cards/ActionBar/ButtonWithTooltip';
import { twMerge } from '@/utils';

type AvailableActions = 'view' | 'edit' | 'copy' | 'print' | 'delete';

const actionProps = {
  view: {
    ariaLabel: I18n.t('view'),
    iconClassName: 'fa fa-play tw-text-center',
    tooltipContent: I18n.t('view'),
  },
  edit: {
    ariaLabel: I18n.t('edit'),
    iconClassName: 'fa fa-edit tw-text-center',
    tooltipContent: I18n.t('edit'),
  },
  copy: {
    ariaLabel: I18n.t('make_a_copy'),
    iconClassName: 'fa fa-clone tw-text-center',
    tooltipContent: I18n.t('make_a_copy'),
  },
  print: {
    ariaLabel: I18n.t('print'),
    iconClassName: 'fa fa-print tw-text-center',
    tooltipContent: I18n.t('print'),
  },
  delete: {
    ariaLabel: I18n.t('delete'),
    iconClassName: 'fa fa-trash-o tw-text-center',
    tooltipContent: I18n.t('delete'),
  },
};

type Action = {
  [key in AvailableActions]?: string;
};

type ActionOption = {
  [key in AvailableActions]?: {
    ariaLabel?: string;
    dataMethod?: string;
    iconClassName?: string;
    openInNewWindow?: boolean;
    onClick?: Function;
    shouldNavigate?: boolean;
    tooltipContent?: string;
  }
};

export interface CardActionsProps {
  actionOptions?: ActionOption;
  actions: Action[];
  className?: string;
}

const CardActions = ({ actionOptions = {}, actions, className }: CardActionsProps) => (
  <div className={twMerge('tw-flex', className)}>
    {actions.map((actionObj) => {
      const action = Object.keys(actionObj)[0];
      const url = actionObj[action];

      return (
        <ButtonWithTooltip
          className="tw-text-dark-grey"
          key={action}
          link={url}
          {...actionProps[action]}
          {...actionOptions[action]}
        />
      );
    })}
  </div>
);

export default CardActions;
