import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Thumbnail from './Thumbnail';
import styles from '../SlideShowBuilder.module.scss';
import { Slide } from '../CreateTransistionGroup/utils';

type DraggableThumbnailProps = {
  index: number;
  selectedSlide: Slide;
  setSelectedSlide: () => void;
  slide: Slide;
};

const DraggableThumbnail = ({
  index,
  setSelectedSlide,
  selectedSlide,
  slide,
}: DraggableThumbnailProps) => (
  <Draggable
    draggableId={slide.id.toString()}
    index={index}
  >
    {draggable => (
      <div
        className={styles.dragContainer}
        ref={draggable.innerRef}
        tabIndex="-1"
        {...draggable.dragHandleProps}
        {...draggable.draggableProps}
      >
        <Thumbnail
          isSelected={!!selectedSlide && selectedSlide.id === slide.id}
          setSelectedSlide={setSelectedSlide}
          slide={slide}
        />
      </div>
    )}
  </Draggable>
);

export default DraggableThumbnail;
