import React from 'react';
import IReadingButton from '@/components/interfaces/graphql/HtmlElement/ReadingButton';

interface ReadingButtonProps {
  readingButton: IReadingButton;
}

const ReadingButton = ({
  readingButton: { data: { backgroundColor = 'green', buttonMaxWidth, buttonTextEn, buttonTextEs, sectionIds, sectionType } }
}: ReadingButtonProps) => (
  <span>
    <span contentEditable={false} data-button-type="reading" data-section-type={sectionType}>
      <span
        className="btn reading-btn"
        data-background-color={backgroundColor}
        data-section-ids={sectionIds.join(',')}
        data-html-element-type="ReadingButton"
        data-button-text-en={buttonTextEn}
        data-button-text-es={buttonTextEs || buttonTextEn}
        style={{ maxWidth: buttonMaxWidth }}
        role="button"
        tabIndex={0}
      />
    </span>
  </span>
);

export default ReadingButton;
