import React, { useRef, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import { TextAreaField } from '@/components/common/Forms';
import { Form } from 'react-final-form';
import AutoSave from '@/components/common/Forms/AutoSave';
import clsx from 'clsx';

interface NoteFormProps {
  studentNote?: string;
  submitUrl: string;
}

const SAVE_MESSAGES = {
  error: 'Save failed. Please try again.',
  saved: 'Saved!',
  saving: 'Saving Note…',
};

interface SaveMessageProps {
  isSaving: boolean;
  message: string;
}

const SaveMessage = ({ isSaving, message }: SaveMessageProps) => {
  const [shouldShow, setShouldShow] = useState(false);
  const timeoutRef = useRef(null);

  const spanClasses = useMemo(() => (
    clsx('save_message', 'note', '!tw-visible', 'tw-transition-all', {
      '!tw-opacity-1': shouldShow,
      '!tw-opacity-0': !shouldShow,
    })
  ), [shouldShow]);

  useEffect(() => {
    if (!isSaving) return;

    setShouldShow(true);

    if (message !== SAVE_MESSAGES.saved) return;

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setShouldShow(false);
    }, 4000);
  }, [isSaving, message]);

  return (
    <span className={spanClasses}>{message}</span>
  );
};

const NoteForm = ({ studentNote, submitUrl }: NoteFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState(SAVE_MESSAGES.saved);
  const inputRef = useRef();

  // @ts-ignore
  const autoResize = () => $(inputRef.current).autoResize({ minHeight: 85 });

  const handleSubmit = ({ studentNote: notes }) => {
    setIsSaving(true);
    setSaveMessage(SAVE_MESSAGES.saving);

    Axios.post(submitUrl, { notes }).then((data) => {
      setSaveMessage(data.status === 200 ? SAVE_MESSAGES.saved : SAVE_MESSAGES.error);
      setIsSaving(false);
    });
  };

  useEffect(() => {
    autoResize();
  }, []);

  return (
    <Form
      initialValues={{ studentNote }}
      onSubmit={handleSubmit}
      render={() => (
        <form style={{ width: '730px' }}>
          <AutoSave debounce={800} save={handleSubmit} />
          <SaveMessage
            isSaving={isSaving}
            message={saveMessage}
          />
          <TextAreaField
            hideLabel
            id="studentNote"
            name="studentNote"
            onChange={autoResize}
            setRef={inputRef}
            className="tw-transition-all"
          />
        </form>
      )}
    />
  );
};

export default NoteForm;
