import React, { Component } from 'react';
import styles from './Forms.module.scss';

export default class FormCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <label className={styles['checkbox-label']}>
        <input type="checkbox" value={this.props.value}
               defaultChecked={this.props.checked}
               onChange={this.props.onChange.bind(this)} />
        {this.props.checkLabel}
      </label>
    )
  }
}