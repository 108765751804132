import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OpenMenuButton from './OpenMenuButton';
import SectionMenu from './SectionMenu';

const JumpToSectionButton = ({ sectionGroups }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!sectionGroups) {
    return null;
  }

  const openMenu = () => { setIsExpanded(true); };
  const closeMenu = () => { setIsExpanded(false); };

  if (isExpanded) {
    return (<SectionMenu sectionGroups={sectionGroups} closeMenu={closeMenu} />);
  }

  return (<OpenMenuButton openMenu={openMenu} />);
};

JumpToSectionButton.propTypes = {
  sectionGroups: PropTypes.arrayOf(PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      section: PropTypes.string
    })).isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
};

export default JumpToSectionButton;
