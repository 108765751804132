import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDOM from 'react-dom';

import DataTable from 'common/DataTable';
import Edit from 'common/Classes/Edit';
import Delete from 'common/Classes/Delete';
import TeacherModal from 'common/Classes/TeacherModal';
import styles from './index.module.scss';

import ClassesDropdown from './ClassesDropdown';
import StudentLmsInfo from './StudentLmsInfo';

export default class Table extends Component {
  static propTypes = {
    assignPath: PropTypes.string.isRequired,
    classroomStaffersPath: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    connectedToCanvas: PropTypes.bool.isRequired,
    connectedToCanvasLti13: PropTypes.bool.isRequired,
    connectedToGoogleClassroom: PropTypes.bool.isRequired,
    connectedToSchoology: PropTypes.bool.isRequired,
    destroyPath: PropTypes.string.isRequired,
    lessonId: PropTypes.number.isRequired,
    manageTeachersPath: PropTypes.string.isRequired,
    programId: PropTypes.number.isRequired,
    resourcesPath: PropTypes.string.isRequired,
    scoresPath: PropTypes.string.isRequired,
    signInInstructionsPath: PropTypes.string.isRequired,
    subscriberConnectedToCanvas: PropTypes.string.isRequired,
    subscriberId: PropTypes.number.isRequired,
    subscriberStaffersPath: PropTypes.string.isRequired,
    subscriptionsLocked: PropTypes.bool.isRequired,
    updatePath: PropTypes.string.isRequired
  };

  static _processResources(resources) {
    return resources.data.map(resource => (
      Table._processResource(resource)
    ));
  }

  static _processResource(resource) {
    return {
      ...resource,
      actions: '',
      programId: resource.program.id,
      students: resource.student_seats_count
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteModalIsOpen: false,
      isLoading: false,
      resources: [],
      teachersModalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateClassroom = this.updateClassroom.bind(this);
    this.updateTeachers = this.updateTeachers.bind(this);
    this.removeClassroom = this.removeClassroom.bind(this);
  }

  componentDidMount() {
    this._getResources();
    this._getSubscriberStaffers();
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({
      [`${type}ModalIsOpen`]: true,
      rowData
    });
  }

  closeModal(type) {
    this.setState({
      [`${type}ModalIsOpen`]: false,
      rowData: null
    });
  }

  updateClassroom(data) {
    const updatedResources = this.state.resources.map((classroom) => {
      if (classroom.id !== data.id) {
        return classroom;
      }
      return Table._processResource({ ...classroom, ...data });
    });

    this.setState({ resources: updatedResources });
  }

  updateTeachers(data) {
    const updatedResources = this.state.resources.map((classroom) => {
      if (classroom.id !== data.id) {
        return Object.assign({}, classroom);
      }

      return {
        ...classroom,
        teachers: data.teachers,
      };
    });

    this.setState({ resources: updatedResources });
  }

  removeClassroom(data) {
    const updatedResources = this.state.resources.filter(classroom => classroom.id !== data.id);

    this.setState({ resources: updatedResources });
  }

  _getResources() {
    this.setState({ isLoading: true });

    Axios
      .get(this.props.resourcesPath)
      .then((response) => {
        const resources = Table._processResources(response.data);
        this.setState({
          isLoading: false,
          resources: resources
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  _getSubscriberStaffers() {
    this.setState({ isLoading: true });
    Axios
      .get(this.props.subscriberStaffersPath)
      .then((response) => {
        this.setState({ teachers: response.data.data.sort() });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  _renderDelete() {
    if (this.state.deleteModalIsOpen) {
      return (
        <Delete
          closeModal={() => this.closeModal('delete')}
          destroyPath={this.props.destroyPath}
          modalIsOpen={this.state.deleteModalIsOpen}
          rowData={this.state.rowData}
          updateTable={this.removeClassroom}
        />
      );
    }

    return null;
  }

  _renderDropdown(rowData, classrooms) {
    return (
      <ClassesDropdown
        signInInstructionsPath={this.props.signInInstructionsPath}
        scoresPath={this.props.scoresPath}
        openModal={this.openModal}
        assignPath={this.props.assignPath}
        rowData={rowData}
        classrooms={classrooms}
        showGoogleClassroomButton={this.props.connectedToGoogleClassroom}
        showCanvasButton={this.props.connectedToCanvas && this.props.subscriberConnectedToCanvas}
        subscriberId={this.props.subscriberId}
        programId={this.props.programId}
        lessonId={this.props.lessonId}
        updateClassroom={this.updateClassroom}
      />
    );
  }

  _renderEditModal() {
    if (this.state.editModalIsOpen) {
      return (
        <Edit
          closeModal={() => this.closeModal('edit')}
          updatePath={`${this.props.updatePath}/${this.state.rowData.id}`}
          modalIsOpen={this.state.editModalIsOpen}
          rowData={this.state.rowData}
          updateClassroom={this.updateClassroom}
        />
      );
    }

    return null;
  }

  _renderTeachersModal() {
    if (this.state.teachersModalIsOpen) {
      return (
        <TeacherModal
          classroomStaffersPath={this.props.classroomStaffersPath}
          closeModal={() => this.closeModal('teachers')}
          modalIsOpen={this.state.teachersModalIsOpen}
          rowData={this.state.rowData}
          subscriberTeachers={this.state.teachers}
          updateTeachers={this.updateTeachers}
          subscriberStaffersPath={this.props.subscriberStaffersPath}
          subscriptionsLocked={this.props.subscriptionsLocked}
          sharePath={this.props.manageTeachersPath}
        />
      );
    }

    return null;
  }

  _renderStudentCell(rowData) {
    const classroomIsConnected = rowData.connected_lms_type != null;
    const {
      connectedToCanvas, connectedToCanvasLti13, connectedToGoogleClassroom, connectedToSchoology,
      subscriberConnectedToCanvas
    } = this.props;
    const userIsConnected = (connectedToCanvas && subscriberConnectedToCanvas) ||
                            connectedToCanvasLti13 ||
                            connectedToGoogleClassroom ||
                            connectedToSchoology;
    const showLmsInfo = userIsConnected && classroomIsConnected;

    const studentLmsInfo = (
      <StudentLmsInfo
        classroomId={rowData.id}
        lmsType={rowData.connected_lms_type}
        isConnectedToLti13={rowData.is_connected_to_canvas_lti_1_3 || rowData.connected_lms_type === 'schoology'}
        programId={this.props.programId}
        studentLmsAccountCount={rowData.student_lms_account_count}
        studentSeatsCount={rowData.student_seats_count}
      />
    );

    return (
      <span className={styles.studentsContainer}>
        {rowData.student_seats_count}
        {showLmsInfo && studentLmsInfo}
      </span>
    );
  }

  _columnDefs() {
    return (
      [
        { targets: [0, 3], width: '20%' },
        { targets: [1, 2], width: '10%' },
        {
          render: $.fn.dataTable.render.moment('MMM DD, YYYY'),
          targets: 4,
          title: 'Last Updated',
          width: '12%'
        },
        {
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              this._renderDropdown(rowData, this.state.resources),
              td
            );
          },
          searchable: false,
          sortable: false,
          targets: -1,
          width: '12%'
        },
        {
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(this._renderStudentCell(rowData), td);
          },
          targets: 2,
          title: 'Students',
          width: '10%'
        }
      ]
    )
  }

  render() {
    return (
      <div>
        <DataTable
          buttons={[{
            extend: 'csv',
            className: 'btn btn--sm btn--outline-purple mr30',
            text: 'Export to CSV',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }]}
          columns={this.props.columns}
          handleResourcesManually
          searching={false}
          defaultOrder={[[1, 'asc'], [0, 'asc']]}
          resources={this.state.resources}
          isLoading={this.state.isLoading}
          columnDefs={this._columnDefs()}
        />

        {this._renderTeachersModal()}
        {this._renderEditModal()}
        {this._renderDelete()}
      </div>
    );
  }
}
