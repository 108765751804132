import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import { truncate } from '@/modules/TCIUtils';
import { ImageModelType } from './types';
import styles from './ExistingImagePicker.module.scss';

interface ImageModelCardPropTypes {
  imageModel: ImageModelType;
  onSelect: () => void;
  selected: boolean;
}

const MAX_IMAGE_FILE_NAME_LENGTH = 24;

const ImageModelCard = ({ imageModel, onSelect, selected }: ImageModelCardPropTypes) => (
  <div
    role="button"
    className={`${styles.imageModelCard} ${selected ? styles.selected : ''}`}
    aria-label={`image model ${imageModel.image.id}`}
    tabIndex={0}
    onClick={onSelect}
    onKeyDown={onSelect}
    onFocus={onSelect}
  >
    <img
      src={imageModel.image.smallUrl}
      alt=""
      className={styles.thumbnail}
    />

    {imageModel.image.fileName.length > MAX_IMAGE_FILE_NAME_LENGTH ? (
      <Tooltip
        content={<span className="tw-break-words">{imageModel.image.fileName}</span>}
        theme="white"
        size="medium"
      >
        <span>{truncate(imageModel.image.fileName, MAX_IMAGE_FILE_NAME_LENGTH)}</span>
      </Tooltip>
    ) : (
      <span>{imageModel.image.fileName}</span>
    )}
  </div>
);

export default ImageModelCard;
