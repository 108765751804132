import React from 'react';
import UnconnectedCard from 'components/common/Settings/IntegrationsCards/CardTypes/UnconnectedCard';
import ConnectedLtiCard from 'components/common/Settings/IntegrationsCards/CardTypes/ConnectedLtiCard';

import schoologyIcon from 'images/icons/schoology-icon.svg';

const SchoologySettings = ({ connectedToSchoology1_3, programId }) => {
  const HELP_CENTER_LINK = 'https://help.teachtci.com/s/article/Set-up-TCI-s-Schoology-Integration';

  const SchoologyLink = () => (
    <a href={HELP_CENTER_LINK} className="btn btn--purple" rel="noopener noreferrer" target="_blank">
      <i className="fa fa-external-link mr10" aria-hidden="true" />
      Set up Integration
    </a>
  );

  if (connectedToSchoology1_3) {
    return (
      <ConnectedLtiCard
        headerText="Schoology LTI 1.3"
        icon={schoologyIcon}
        programId={programId}
      />
    );
  }

  return (
    <UnconnectedCard
      Button={<SchoologyLink />}
      bodyText="Sync Assignments and Grades"
      headerText="Schoology LTI 1.3"
      icon={schoologyIcon}
    />
  );
};

export default SchoologySettings;
