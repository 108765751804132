import React, { useRef, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import { admin_themes_path } from 'modules/routes';
import FroalaEditor from 'froala-editor';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import Froala from '@/components/shared/Froala';
import { insertIntoEditor }
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/FroalaListeners';
import { FroalaProps } from '@/components/shared/Froala/Froala';
import useThemes from '@/hooks/api/useThemes';
import cn from '@/utils/cn';
import ITheme from '@/components/interfaces/Theme';
import {
  getCurrentImageSnippet,
  getImageModelIdFromElement,
  graphQlImageModelToTag,
} from '@/components/shared/Froala/froalaUtils';
import { MainIdeasStore } from '@/components/shared/Froala/plugins/MainIdeas/MainIdeasStore';
import { MAIN_IDEAS } from '@/components/shared/Froala/plugins/MainIdeas';
import { GLOSSARY_TERMS } from '@/components/shared/Froala/plugins/GlossaryTerms';
import { VOCAB_BOX } from '@/components/shared/Froala/plugins/VocabBox';
import './froala-styles.scss';
import LayoutDropdown from './LayoutDropdown';
import useLayout from './useLayout';
import ThemeDropdown from './ThemeDropdown';
import ImageCaptionPopover from './ImageCaptionPopover';
import { editImageAfterSubmit, sortLessonImagesFromObject } from './SectionEditorUtils';
import ImageFetcher from './ImageFetcher';
import { IImageModel } from '../SlideShow/stores/useSlideShowStore';
import Popover from '@/components/shared/Froala/plugins/GlossaryTerms/Popover';
import { AnchorRef } from '@/components/ui/Anchor';

type SectionEditorProps = Omit<FroalaProps, 'modelType'> & {
  lessonId: number | string;
  level: 'a' | 'b' | 'es';
  programId: number | string;
  themeId: number;
  userType: UserType;
};

const classNames = (modelId: Pick<SectionEditorProps, 'modelId'>['modelId']) => {
  if (modelId === undefined) {
    return '!tw-w-full tw-mt-4 tw-border-none section-editor';
  }

  return '!tw-w-full tw-mt-4';
};

const SectionEditor = ({
  lessonId,
  level,
  modelId,
  name,
  programId,
  text: initialText,
  themeId,
  userType,
}: SectionEditorProps) => {
  const [text, setText] = useState(initialText);
  const [editor, setEditor] = useState<typeof FroalaEditor>();
  const [currentThemeId, setCurrentThemeId] = useState<ITheme['id']>(themeId);
  const [element, setElement] = useState<HTMLElement>(null);
  const [lessonImages, setLessonImages] = useState<{ [key: number]: IImageModel[] }>({});

  const glossaryTermsPopoverRef = useRef<AnchorRef>();

  const store = MainIdeasStore.get(`.level-${level}`);
  const isActive = store(state => state.isActive);

  const { layout, onChange: changeLayout } = useLayout({ editor, onChange: setText });
  const { data: themes } = useThemes();

  const marginClass = cn({ 'tw-ml-[15px]': !modelId });
  const themeClass = themes.find(theme => theme.id === currentThemeId)?.css_name;
  const locale = level === 'es' ? 'es' : 'en';

  return (
    <>
      <ImageFetcher lessonId={lessonId} setLessonImages={setLessonImages} />
      <ImageCaptionPopover editorInstance={editor} element={element} />
      {editor && layout && (
        <LayoutDropdown
          className={marginClass}
          initialValue={layout}
          onChange={changeLayout}
        />
      )}
      <div className="tw-flex tw-column tw-justify-between">
        <ThemeDropdown
          className={marginClass}
          currentThemeId={currentThemeId}
          onChange={nextTheme => setCurrentThemeId(nextTheme)}
        />
        <div className="tw-flex tw-items-end">
          <a
            href={admin_themes_path({ theme_id: currentThemeId })}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--sm btn--outline-purple"
          >
            <i className="fa fa-pencil-square-o tw-pr-[2px]" />
            Edit CSS
          </a>
        </div>
      </div>

      <div
        className={cn(
          { 'two-column': layout === 'two_column' },
          { 'main-ideas-editing-mode': isActive },
          'new-section-text',
        )}
      >
        <div
          className={cn({
            [themeClass]: !!currentThemeId,
            'display-main-ideas': isActive,
          })}
        >
          <Froala
            activePlugins={[MAIN_IDEAS.commandName, VOCAB_BOX.commandName, GLOSSARY_TERMS.commandName]}
            className={cn(classNames(modelId), `level-${level}`)}
            customConfig={{
              enter: FroalaEditor.ENTER_P,
              height: 725,
              tabSpaces: 6,
              lineBreakerTags: [...FroalaEditor.DEFAULTS.lineBreakerTags, '.captions'],
            }}
            eventHandlers={[
              {
                click: (e) => {
                  const target = e.target as HTMLElement;
                  const id = getImageModelIdFromElement(target);
                  const currentSnippet = getCurrentImageSnippet(id);

                  if (!id) return;
                  if (!currentSnippet.getAttribute('data-snippet').includes('CAPTION')) return;

                  setElement(getCurrentImageSnippet(id) as HTMLElement);
                },
              },
            ]}
            modelId={modelId}
            modelType="Section"
            name={name}
            pluginProps={{
              [MAIN_IDEAS.commandName]: {
                selector: `.level-${level}`,
              },
              [GLOSSARY_TERMS.commandName]: {
                glossaryTermsPopoverRef,
                selector: `.level-${level}`,
              },
              [VOCAB_BOX.commandName]: {
                lessonId,
                locale,
                programId,
                selector: `.level-${level}`,
              },
            }}
            setEditor={setEditor}
            text={text}
            userType={userType}
            AddImageProps={{
              afterSubmit: (imageModel, editorInstance) => {
                insertIntoEditor(editorInstance, graphQlImageModelToTag(imageModel));
              },
              getExistingImages: () => Promise.resolve(sortLessonImagesFromObject(lessonImages)),
            }}
            EditImageProps={{
              afterSubmit: editImageAfterSubmit(),
            }}
          />
        </div>
      </div>
      <Popover editorInstance={editor} setAnchorRef={glossaryTermsPopoverRef} locale={locale} />
    </>
  );
};

const SectionEditorWrapper = (props: SectionEditorProps) => (
  <ApolloProvider client={apolloClient}>
    <QueryClientProvider client={queryClient}>
      <SectionEditor {...props} />
    </QueryClientProvider>
  </ApolloProvider>
);

export default SectionEditorWrapper;
