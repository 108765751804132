import React from 'react';
import Modal from 'common/Modal';

export default function ModalButton(props) {
  return (
    <div className="inline_block">
      <a href="#" className="action" onClick={props.openModal}>{props.buttonText}</a><br />
      <Modal className={props.modalClass}
             closeModal={props.closeModal}
             isOpen={props.modalIsOpen}
             headerText={props.modalHeader || "Edit Toggle Image"}>
        {props.formComponent}
      </Modal>
    </div>
  )
}