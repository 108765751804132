import React from 'react';
import { Lesson, Program, StafferSlideShow } from '../types';
import { ActivityCard } from '../Cards';

interface TCISlideShowsProps {
  hideTabs: boolean;
  lesson: Lesson;
  program: Program;
  showArchived: boolean;
  slideShows: StafferSlideShow[];
  stafferId: number;
}

const TCISlideShows = ({
  hideTabs, lesson, program, showArchived, slideShows, stafferId,
}: TCISlideShowsProps) => {
  const shouldRenderSlideShow = slideShow => showArchived || !slideShow.archived;

  return (
    <>
      {slideShows.map((slideShow) => {
        if (slideShow.useForInvestigations) return (<></>);
        if (slideShow.useForUnitProjects) return (<></>);
        if (!slideShow.activityType) return (<></>);
        if (!shouldRenderSlideShow(slideShow)) return (<></>);
        if (slideShow.activityType === 'marketing') return (<></>);

        return (
          <ActivityCard
            key={slideShow.id}
            lessonId={lesson.id}
            programId={program.id}
            slideShow={slideShow}
            stafferId={stafferId}
            hideTabs={hideTabs}
          />
        );
      })}
    </>
  );
};

export default TCISlideShows;
