import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export interface IApiSlideShow {
  class_time?: string;
  completed_at: string;
  course_duration: string;
  description_en: string;
  id: number;
  lesson_id: number;
  title_en: string;
  status: 'not_started' | 'in_progress' | 'completed';
  thumbnail_url: string;
  unit_id: number;
}

export interface IApiUnit {
  id: number;
  title: string;
  subtitle: string;
  slide_shows: IApiSlideShow[];
}

export interface ApiData {
  collection: IApiUnit[];
}

const BASE_ENDPOINT = '/api/v2/slide_shows/professional_development';

export default function useProfessionalDevelopmentData(programId: number) {
  return useQuery(['ProfessionalDevelopment', programId], () => (
    axios
      .get<ApiData>(`${BASE_ENDPOINT}.json?program_id=${programId}`)
      .then(res => res.data.collection)
  ), { placeholderData: [], staleTime: 60000 });
}
