import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { plato_api_slide_shows_path } from '@/modules/routes';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';
import {
  getCurrentImageSnippet,
  graphQlImageModelToTag,
  jsxToNode,
  replaceInEditor,
} from '@/components/shared/Froala/froalaUtils';
import QueryKeys from '@/utils/QueryKeys';
import { LayoutValue } from './LayoutDropdown';
import { IImageModel } from '../SlideShow/stores/useSlideShowStore';

export const wrapHtml = (html: HTMLBodyElement) => {
  const outerWrapper = document.createElement('div');
  outerWrapper.classList.add('two-column');

  const innerWrapper = document.createElement('div');
  innerWrapper.classList.add('text-column');
  innerWrapper.classList.add('fr-inner');

  const innerWrapper2 = document.createElement('div');
  innerWrapper2.classList.add('text-column');
  innerWrapper2.classList.add('fr-inner');

  if (html) {
    while (html.firstChild) {
      innerWrapper.appendChild(html.firstChild);
    }
  }

  outerWrapper.appendChild(innerWrapper);
  outerWrapper.appendChild(innerWrapper2);

  return outerWrapper;
};

export const unwrapHtml = (html: HTMLBodyElement) => {
  const placeholderWrapper = document.createElement('div');
  const textColumns = html.querySelectorAll('.text-column');

  textColumns.forEach((col) => {
    while (col.firstChild) {
      placeholderWrapper.appendChild(col.firstChild);
    }
  });

  return placeholderWrapper;
};

export const getLayout = (html: string): LayoutValue => {
  if (!html) return 'full_width';

  const parser = new DOMParser();

  const parsed = parser.parseFromString(html, 'text/html').body.firstChild as HTMLElement | string;

  if (typeof parsed === 'string') return 'full_width';

  if ('querySelectorAll' in parsed && parsed.querySelectorAll('.text-column').length > 0) return 'two_column';

  return 'full_width';
};

export const editImageAfterSubmit = () => (
  (imageModel: GraphQlImageModel, editorInstance: any) => {
    const oldElement = getCurrentImageSnippet(imageModel.id);
    const newElement = jsxToNode(graphQlImageModelToTag(imageModel));

    replaceInEditor({
      editorInstance,
      includeNewLine: true,
      newElement,
      oldElement,
    });
  }
);

type UseSlideShowIdsResponse = { data: { id: number }[] };

export const useSlideShowIds = (lessonId: number | string) => {
  const params = { for_lesson: lessonId, use_new_builder: true, only: ['id'] };

  return (
    useQuery({
      queryFn: () => (
        axios
          .get<UseSlideShowIdsResponse>(plato_api_slide_shows_path(params))
          .then(res => res.data.data)
      ),
      queryKey: [QueryKeys.SlideShows, lessonId, params],
      placeholderData: [],
    })
  );
};

type LessonImagesObject = { [key: number]: IImageModel[] };

const filterLessonImagesFromObject = (lessonImagesObject: LessonImagesObject) => (
  Object.values(lessonImagesObject)
    .reduce((acc, cur) => {
      cur.forEach((imageModel) => {
        acc[imageModel.image.fileName] = imageModel;
      });

      return acc;
    }, {} as { [key: string]: IImageModel })
);

export const sortLessonImagesFromObject = (lessonImagesObject: { [key: number]: IImageModel[] }) => (
  Object.values(filterLessonImagesFromObject(lessonImagesObject))
    .flat()
    .sort((a, b) => a.image.fileName.localeCompare(b.image.fileName))
);

export type CallbackFunction<T extends any[] = any[]> = (...args: T) => void;
export type WrapperFunction = <T extends any[]>(callback: CallbackFunction<T>) => CallbackFunction<T>;
