type PlaybackState = {
  playing: boolean,
  paused: boolean,
};

const play = (): PlaybackState => ({ playing: true, paused: false });
const stop = (): PlaybackState => ({ playing: false, paused: false });
const toggle = ({ playing, paused }): PlaybackState => ({ playing: !playing, paused: !paused });

const playbackStateReducer = (playbackState, { type }): PlaybackState => {
  switch (type) {
    case 'PLAY':
      return play();
    case 'TOGGLE':
      return toggle(playbackState);
    case 'STOP':
      return stop();
    /* istanbul ignore next */
    default:
      return playbackState;
  }
};

export default playbackStateReducer;
