import cn from '@/utils/cn';
import React from 'react';

interface DropdownOptionProps extends React.HTMLProps<HTMLAnchorElement> {
  isActive?: boolean;
  title: string;
}

const DropdownOption = ({
  children,
  isActive = false,
  title,
  ...rest
}: DropdownOptionProps) => (
  <a
    aria-selected={false}
    className={cn('fr-command fr-title', { 'fr-active': isActive })}
    data-title={title}
    role="option"
    tabIndex={-1}
    {...rest}
  >
    {children}
    <span className="fr-sr-only">{title}</span>
  </a>
);

export default DropdownOption;
