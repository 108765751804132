import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'common/Wizard/Footer';
import { Form } from 'react-final-form';
import Axios from 'axios';

import styles from '../Form.module.scss';

import { DISTRICT_CONFIG_STEP } from '../../steps';

export default function DistrictConfigStep({
  previous, autoRosterDistrictPath, subscriberId, updateRosterDistrict, next, env
}) {
  function submitRead() {
    Axios.put(autoRosterDistrictPath, { auto_roster_district: { instructions_seen: true } })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          updateRosterDistrict(response.data.data);
          next();
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div>
      <p>
        Rostering is completed via two CSV files. See below for CSV instructions and SFTP info where you&apos;ll place files daily.
        The sync runs at approximately 8:30AM UTC, so please place updated files into the directory before that time.
      </p>
      <ol>
        <li>
          Download the CSV templates (
          <a
            href="/csv_templates/teachers_YYYYMMDD.csv"
            target="_blank"
            rel="noreferrer noopener"
          >
            Teacher CSV
          </a>
          {' '}
          and
          {' '}
          <a
            href="/csv_templates/students_YYYYMMDD.csv"
            target="_blank"
            rel="noreferrer noopener"
          >
            Student CSV
          </a>
          )
        </li>
        <li>
          Configure your script to generate CSVs from your SIS following
          {' '}
          <a
            href="https://www.teachtci.com/create-roster-csv-file"
            target="_blank"
            rel="noreferrer noopener"
          >
            TCI CSV specifications
          </a>
        </li>
        <li>
          Use this filename convention: teachers_YYYYMMDD.csv (ex: teachers_20190630.csv) and students_YYYYMMDD.csv (ex: students_20190630.csv) where the datestamp is the day the files are generated
        </li>
        <li>
          Configure your script to log in using the following information:
          <ul className={styles.indentedList}>
            <li>Hostname: sftp.teachtci.com</li>
            <li>Port: 22</li>
            <li>
              {`Username: ${env}_`}
              {subscriberId}
            </li>
            <li>Authentication: Your SSH Private Key</li>
            <li>
              {`Path: _${subscriberId}/writable`}
            </li>
          </ul>
        </li>
      </ol>

      <Form
        onSubmit={submitRead}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Footer page={DISTRICT_CONFIG_STEP} previous={previous} />
          </form>
        )}
      </Form>
    </div>
  );
}

DistrictConfigStep.propTypes = {
  autoRosterDistrictPath: PropTypes.string.isRequired,
  env: PropTypes.string,
  next: PropTypes.func,
  previous: PropTypes.func,
  subscriberId: PropTypes.number.isRequired,
  updateRosterDistrict: PropTypes.func.isRequired
};

DistrictConfigStep.defaultProps = {
  env: '',
  next: () => {},
  previous: () => {},
};
