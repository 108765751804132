import React, { Component } from 'react';
import Axios from 'axios';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { convertCopyright, renderErrors, RequiredAsterisk, SubmitError } from '../../../common/Forms/Utils';
import { Footer } from '../../../common/Modal';
import Tooltip from '../../../common/Tooltip';

import styles from '../Form.module.scss';

export default class AddForm extends Component {
  static propTypes = {
    classroomsPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    createPath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      classroomId: null,
      classes: [],
      errorMessage: 'Please enter the email of a teacher with classes',
      loadingClasses: false
    };
  }

  getClasses(e) {
    this.setState({ classes: [], loadingClasses: true });
    Axios
      .get(this.props.classroomsPath.concat(e.target.value))
      .then((response) => {
        if (!response.data.length) {
          this.setState({ errorMessage: 'Could not find classes for this teacher. Please enter another email.' });
        }
        else {
          this.setState({ classes: response.data });
        }
      })
      .catch(() => {
        this.setState({ errorMessage: 'Could not find classes for this teacher. Please enter another email.' });
      })
      .then(() => this.setState({ loadingClasses: false }));
  }

  getClassSelectOptions() {
    return this.state.classes.map(classroom => ({ value: classroom.id, label: convertCopyright(classroom.name) }));
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .post(this.props.createPath, { student: values })
      .then((response) => {
        this.props.updateTable(response.data.data, 'add');
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  validation(values) {
    const errors = {};
    if (!values.teacher_email) {
      errors.teacher_email = 'Please complete all required fields.';
    }
    if (!this.state.classroomId) {
      errors.classRoom = 'Please complete all required fields.';
    }
    if (!values.first_name) {
      errors.first_name = 'Please complete all required fields.';
    }
    if (!values.last_name) {
      errors.last_name = 'Please complete all required fields.';
    }
    if (!values.username) {
      errors.first_name = 'Please complete all required fields.';
    }
    if (!values.password) {
      errors.password = 'Please complete all required fields.';
    }
    else if (values.password !== values.password_confirmation) {
      errors.password_confirmation = 'Password must match';
    }
    return errors;
  }

  renderSelectClasses(form) {
    return (
      <Field
        name="initial_classroom_id"
        render={() => (
          <div className={styles.formRow}>
            <label htmlFor="initial_classroom_id" className={styles.label}>
              Class<RequiredAsterisk />
            </label>
            <Select
              searchable
              id="initial_classroom_id"
              value={this.state.classroomId}
              options={this.getClassSelectOptions()}
              className={styles.input}
              onChange={form.mutators.handleSelect}
              clearable={false}
              required
            />
          </div>
        )}
      />
    );
  }

  renderSelectClassesDisabled() {
    return (
      <Tooltip content={this.state.errorMessage}>
        <Field
          name="initial_classroom_id"
          render={() => (
            <div className={styles.formRow}>
              <label htmlFor="initial_classroom_id" className={styles.label}>
                Class<RequiredAsterisk />
              </label>
              <Select
                id="initial_classroom_id"
                className={styles.input}
                clearable={false}
                disabled
                required
              />
            </div>
          )}
        />
      </Tooltip>
    );
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={{
          handleSelect: (args, state, utils) => {
            this.setState({ classroomId: args[0].value });
            utils.changeValue(state, 'initial_classroom_id', () => args[0].value);
          }
        }}
        validate={values => this.validation(values)}
        render={({
          form, handleSubmit, hasValidationErrors, hasSubmitErrors, dirtySinceLastSubmit, submitError, errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="sr-only">Fields marked with an asterisk (*) are required.</span>

            <div className={styles.formRow}>
              <label htmlFor="teacher_email" className={styles.label}>
                Teacher Email<RequiredAsterisk />
              </label>
              <Field
                id="teacher_email"
                name="teacher_email"
                component="input"
                className={styles.textInput}
                onBlur={e => this.getClasses(e)}
              />
            </div>

            {(this.state.classes.length || this.state.loadingClasses) ?
              this.renderSelectClasses(form) : this.renderSelectClassesDisabled() }

            <div className={styles.formRow}>
              <label htmlFor="first_name" className={styles.label}>
                First Name <RequiredAsterisk />
              </label>
              <Field
                id="first_name"
                name="first_name"
                component="input"
                className={styles.textInput}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="last_name" className={styles.label}>
                Last Name<RequiredAsterisk />
              </label>
              <Field
                id="last_name"
                name="last_name"
                component="input"
                className={styles.textInput}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="username" className={styles.label}>
                Username<RequiredAsterisk />
              </label>
              <Field
                id="username"
                name="username"
                component="input"
                className={styles.textInput}
                parse={val => (val === null ? '' : val)}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="password" className={styles.label}>
                New Password<RequiredAsterisk />
              </label>
              <Field
                autoComplete="new-password"
                id="password"
                name="password"
                type="password"
                component="input"
                className={styles.textInput}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="password_confirmation" className={styles.label}>
                Confirm Password<RequiredAsterisk />
              </label>
              <Field
                autoComplete="new-password-confirmation"
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                component="input"
                className={styles.textInput}
              />
            </div>

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit)}
              submitting={this.state.submitting}
              disableWithTooltipText={errors[Object.keys(errors)[0]]}
            />
          </form>
        )}
      />
    );
  }
}
