import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { IClassroom, IExerciseResult, ISlideShow } from '../../../types';

const useSlideShowExerciseResults = (classroom: IClassroom, slideshow: ISlideShow) => {
  const isEnabled = !!(classroom?.id && slideshow?.id);

  const query = useQuery([QueryKeys.SlideShowExerciseResults, classroom?.id, slideshow?.id], () => {
    if (!classroom.id) return [];

    const path = Routes.plato_api_slideshow_results_exercise_results_path({
      classroom_id: classroom.id,
      slideshow_id: slideshow?.id,
    });

    return axios
      .get<{ data: IExerciseResult[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err));
  }, { enabled: isEnabled, staleTime: Infinity });

  return { ...query, isEnabled, data: query.data || [] };
};

export default useSlideShowExerciseResults;
