import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import SearchResult from './SearchResult';
import noResultsImage from './no-results.png';
import useSearchStore from './useSearchStore';

export const ITEMS_PER_PAGE = 25;

const SearchResults = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const isLoading = useSearchStore(state => state.isLoading);
  const results = useSearchStore(state => state.results);
  const numResults = useSearchStore(state => state.numResults);

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentPageData = results.slice(offset, offset + ITEMS_PER_PAGE);
  const pageCount = Math.ceil(results.length / ITEMS_PER_PAGE);

  useEffect(() => {
    setCurrentPage(0);
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-64 tw-w-full">
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      </div>
    );
  }

  if (numResults === undefined || numResults === 0) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-pt-16 tw-w-full">
        {numResults === undefined && (
          'Enter a search term and click the magnifying glass or press enter to begin searching.'
        )}
        {numResults === 0 && (
          <div className="tw-text-center">
            <img src={noResultsImage} alt="No matching results" className="tw-w-1/3" />
            <h1 className="tw-mt-4">No matching results</h1>
            <span className="tw-text-sm">Try using a different search term</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="tw-w-full" role="list">
      {currentPageData.map((result, index) => (
        <SearchResult position={index + 1} key={`${result.klass}-${result.id}-${currentPage}`} {...result} />
      ))}
      {pageCount > 1 && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={e => setCurrentPage(e.selected)}
          containerClassName="tw-flex tw-float-right"
          pageClassName="tw-flex tw-justify-center tw-items-center tw-px-2 tw-h-6"
          breakClassName="tw-flex tw-items-center"
          activeClassName="tw-bg-tci-purple tw-rounded-sm"
          activeLinkClassName="tw-text-white hover:tw-text-white"
          previousLabel={<i className="fa fa-chevron-left" />}
          previousClassName={`tw-flex tw-items-center ${currentPage <= 0 && 'tw-invisible'}`}
          nextLabel={<i className="fa fa-chevron-right" />}
          nextClassName={`tw-flex tw-items-center ${currentPage >= pageCount - 1 && 'tw-invisible'}`}
        />
      )}
    </div>
  );
};

export default SearchResults;
