import React from 'react';
import I18n from 'i18n-js';
import { capitalizeWords } from '@/modules/TCIUtils';
import ReadingCard from '@/components/shared/LessonOverview/Cards/ReadingCard';
import { readingPath } from '../utils';
import { Lesson, Program, SectionType } from '../types';

interface MoreReadingCardsProps {
  lesson: Lesson;
  program: Program;
  sectionsByKind: {
    default?: SectionType[];
    explore?: SectionType[];
    'key science concept'?: SectionType[];
    'reading further'?: SectionType[];
  };
  stafferId?: number;
  types: string[];
}

const MoreReadingCards = ({ lesson, program, sectionsByKind, stafferId, types }: MoreReadingCardsProps) => (
  <>
    {types.map((type) => {
      const moreReadingSections = sectionsByKind[type];

      const linkLabel = () => {
        const label = type === 'biography' ? 'profiles_in_history' : type
        const allCaps = capitalizeWords(I18n.t(label.replace(/ /g, '_')));

        return allCaps.replace(' The ', ' the ').replace(' To ', ' to ').replace(' In ', ' in ');
      };

      return (
        <ReadingCard
          bannerBorderColor="blue"
          bannerLabel="more_reading"
          key={type}
          lessonId={lesson.id}
          linkLabel={linkLabel()}
          readingPath={readingPath(moreReadingSections[0], program, moreReadingSections)}
          sections={moreReadingSections}
          stafferId={stafferId}
          program={program}
          type={type.replace(/ /g, '-')}
        >
          {moreReadingSections.map(section => (
            <a key={section.id} href={readingPath(section, program, moreReadingSections)}>
              {section.subtitle}
            </a>
          ))}
        </ReadingCard>
      );
    })}
  </>
);

export default MoreReadingCards;
