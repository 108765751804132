import React from 'react';
import { SketchPicker } from 'react-color';
import useDrawingContext from '../store/useDrawingContext';

const ColorPicker = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const setColor = useDrawingContext(state => state.setColor);
  const color = useDrawingContext(state => state.color);

  return (
    <SketchPicker
      color={color}
      onChange={({ rgb: { r, g, b, a } }) => {
        setColor(`rgba(${r}, ${g}, ${b}, ${a})`);
      }}
      onChangeComplete={() => {
        if (canvas.getActiveObjects().length === 0) return;

        canvas.fire('object:modified');
      }}
    />
  );
};

export default ColorPicker;
