import { gql } from '@apollo/client';

const GET_STUDENTS_FOR_PROGRAM = gql`
  query GetStudentsForProgram($programId: ID, $stafferId: ID) {
    staffer(id: $stafferId) {
      classrooms(withProgram: $programId) {
        students {
          value: id
          label: fullNameWithUsername
        }
      }
    }
  }
`;

export default GET_STUDENTS_FOR_PROGRAM;
