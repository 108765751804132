import PropTypes from 'prop-types';
import React from 'react';

const DownloadResultCsvButton = ({ resultCsvUrl }) => (
  <a
    href={resultCsvUrl}
    target="_blank"
    rel="noopener noreferrer"
    download
    className="btn btn--sm btn--outline-purple"
  >
    Download Result CSV
  </a>
);

DownloadResultCsvButton.propTypes = {
  resultCsvUrl: PropTypes.string.isRequired
};

export default DownloadResultCsvButton;
