import React from 'react';
import I18n from 'i18n-js';
import { SlideObject } from '@/components/admin/SlideShow/types';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { useModalManager } from '@/components/common/Modal';
import InteractiveModal from '@/components/common/InteractiveModal';
import InteractiveMap from '@/components/admin/SlideShow/Slide/Content/SlideObjectInteractiveMap/InteractiveMap';
import styles from './styles.module.scss';

type MapViewerProps = {
  slideObject: SlideObject
  showOverlay?: boolean
  showHeader?: boolean
};

const MapViewer = ({ slideObject, showOverlay, showHeader }: MapViewerProps) => {
  const locale = useSlideShowContext(state => state.locale);
  const modalManager = useModalManager();
  const modelInteractiveMap = slideObject.modelInteractiveMaps && slideObject.modelInteractiveMaps[0];
  const { interactiveMap, instructionsEn, instructionsEs } = modelInteractiveMap || {};
  const { appId = '' } = interactiveMap || {};

  const getMapTitle = () => {
    if (interactiveMap && locale === 'es') {
      return interactiveMap.titleEs;
    } if (interactiveMap) {
      return interactiveMap.titleEn;
    } return '';
  };

  const title = getMapTitle();
  const instructions = locale === 'es' ? instructionsEs : instructionsEn;

  const RequireOpenOverlay = () => (
    <div
      className={`${styles.slideObjectMapOverlay} tw-cursor-pointer`}
      role="button"
      tabIndex={0}
      onClick={modalManager.open}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          modalManager.open();
        }
      }}
    >
      <button
        className={`${styles.interactiveMapOpenMapButton} btn btn--purple`}
        type="button"
      >
        <img alt="Map icon" src="/images/map_solid.svg" />
        <p>{I18n.t('open_map_button_text')}</p>
      </button>
    </div>
  );

  if (!showOverlay) {
    return (
      <InteractiveMap appId={appId} title={title} instructions={instructions} />
    );
  }

  return (
    <>
      <RequireOpenOverlay />
      <InteractiveMap appId={appId} showHeader={false} title={title} instructions={instructions} />
      {modalManager.isOpen && (
        <InteractiveModal
          isOpen={modalManager.isOpen}
          closeFunction={modalManager.close}
          nearlyFullScreen
        >
          <InteractiveMap appId={appId} showHeader={showHeader} title={title} instructions={instructions} />
        </InteractiveModal>
      )}
    </>
  );
};

MapViewer.defaultProps = {
  showOverlay: false,
  showHeader: false,
};

export default MapViewer;
