import React, { useState } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { useModalManager } from 'common/Modal';
import * as Routes from 'routes';
import AsyncAssignmentModal from 'staffer/AssignmentModal/AsyncAssignmentModal';
import Tooltip from '../Tooltip';
import styles from './index.module.scss';

const comingSoonText = 'Assigning coming soon';

const ComingSoonButton = () => (
  <span>
    <button type="button" className={styles.icon} disabled>
      <i className={`fa fa-calendar ${styles.disabled}`} aria-hidden="true" />
      <span className="sr-only">{comingSoonText}</span>
    </button>
  </span>
);

const AssignmentShortcut = ({ isTemporary, ...props }) => {
  const [isAssigned, setIsAssigned] = useState(props.isAssigned);

  if (props.comingSoon) {
    return (
      <Tooltip
        content={comingSoonText}
        size="medium"
        distance="15"
        placement="bottom"
      >
        <ComingSoonButton />
      </Tooltip>
    );
  }

  const modalManager = useModalManager();
  const iconClasses = isAssigned ? `fa fa-calendar-check-o ${styles.assigned}` : `fa fa-calendar ${isTemporary ? styles.disabled : styles.share}`;

  const tooltipContent = () => {
    if (isTemporary) return I18n.t('no_access_to_lesson');
    if (!isAssigned) return 'Create Assignment';

    const assignmentsLink = Routes.teacher_program_assignments_path(props.program.id, { lesson_id: props.lessonId });

    return (
      <div className={styles.assignmentTooltip}>
        <div className={styles.header}>Create Assignment</div>
        <hr />
        <div>
          {I18n.t('assignment_already_created')}
          {' '}
          <a href={assignmentsLink}>View Details</a>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Tooltip
        content={tooltipContent()}
        size="medium"
        distance="15"
        interactive={isAssigned && !isTemporary}
        placement="bottom"
      >
        <button
          type="button"
          aria-haspopup="dialog"
          aria-controls="CreateAssignmentModal"
          aria-label="Create Assignment"
          className={`${styles.icon} ${isAssigned ? styles.assigned : ''} ${isTemporary && styles.disabled}`}
          onClick={(e) => {
            if (!isTemporary) {
              e.currentTarget.blur();
              modalManager.open();
            }
          }}
          disabled={isTemporary}
        >
          <i
            className={iconClasses}
            aria-hidden="true"
          />
          <span className="sr-only">Create Assignment</span>
        </button>
      </Tooltip>

      <AsyncAssignmentModal
        closeModal={modalManager.close}
        handlePostSave={() => setIsAssigned(true)}
        initialValues={props.initialValues}
        initialLessonId={props.unitId || props.lessonId}
        modalIsOpen={modalManager.isOpen}
        program={props.program}
        stafferId={props.stafferId}
      />
    </React.Fragment>
  );
};

AssignmentShortcut.propTypes = {
  comingSoon: PropTypes.bool,
  initialValues: PropTypes.shape({
    modelType: PropTypes.string.isRequired,
    modelsToAssign: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }))
    ]),
  }).isRequired,
  isAssigned: PropTypes.bool.isRequired,
  lessonId: PropTypes.number,
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    science: PropTypes.bool.isRequired
  }).isRequired,
  stafferId: PropTypes.number.isRequired,
  unitId: PropTypes.number,
  isTemporary: PropTypes.bool,
};

AssignmentShortcut.defaultProps = {
  comingSoon: false,
  isTemporary: false,
};

export default AssignmentShortcut;
