import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Routes from 'modules/routes';
import I18n from 'modules/i18n';
import Axios from 'axios';
import CorrelationsContext from 'components/common/Context';
import styles from '../Correlations.module.scss';
import { LessonType, TagType, UnitType } from '../types';

interface TagCardPropType {
  tag: TagType;
  anchored: boolean;
  isSysadmin: boolean;
}

type ClearCacheResponse = {
  data: any
};

const TagCard = ({ tag, anchored, isSysadmin }: TagCardPropType) => {
  const [isClearingCache, setIsClearingCache] = useState(false);
  const { programId } = useContext(CorrelationsContext);
  const ref = useRef(null);

  const headerLabel = (model: LessonType | UnitType) => {
    const isComingSoon = 'comingSoon' in model && model.comingSoon;
    const title = isComingSoon ? `${model.title} (${I18n.t('coming_soon')})` : model.title;

    if ('titleNumber' in model && model.titleNumber) {
      return `${I18n.t('unit')} ${model.unitPosition} - ${I18n.t('lesson')} ${model.titleNumber} - ${title}`;
    }

    return `${I18n.t('unit')} ${model.unitPosition} - ${title}`;
  };

  const handleClearingCache = (e, lessonId) => {
    e.preventDefault();
    setIsClearingCache(true);

    Axios.post<ClearCacheResponse, any>(Routes.plato_api_clear_lesson_cache_tags_path(),
      { tag_id: tag.id, program_id: programId, lesson_id: lessonId })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setIsClearingCache(false);
      }).then(() => {
        window.location.reload();
      });
  };

  useEffect(() => {
    if (!anchored || !ref.current) return;

    ref.current.scrollIntoView();
  }, [ref.current]);

  return (
    <section
      id={tag.alias}
      aria-label={`Benchmarks for ${tag.alias}`}
      key={tag.id}
      className={`card ${styles.card}`}
      ref={ref}
    >
      <div className={styles.header}>
        {I18n.locale === 'es' ? tag.description_es : tag.description_en}
      </div>
      <div>
        {tag.units.map(unit => (
          <div key={headerLabel(unit)} className={styles.lesson}>
            <span>{headerLabel(unit)}</span>
            {unit.notebooks.map(notebook => (
              <li key={notebook.id}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={Routes.stream_shared_program_investigations_path(
                    programId,
                    { unit_id: unit.id, unit_projects: true, expanded: notebook.id, anchor: `section-${notebook.id}` },
                  )}
                >
                  {`${I18n.t('unit_projects')} / ${notebook.label}`}
                </a>
              </li>
            ))}
          </div>
        ))}
      </div>
      <div>
        {tag.lessons.map(lesson => (
          <div key={headerLabel(lesson)} className={styles.lesson}>
            <span>{headerLabel(lesson)}</span>
            {lesson.classroomActivities.map(classroomActivity => (
              <li key={`${lesson.title} ${classroomActivity.id}`}>
                <a
                  className={lesson.comingSoon ? styles.disabledLink : ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={classroomActivity.href}
                >
                  {classroomActivity.label}
                </a>
              </li>
            ))}
            {lesson.videoActivities.map(videoActivity => (
              <li key={`${lesson.title} ${videoActivity.id}`}>
                <a
                  className={lesson.comingSoon ? styles.disabledLink : ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={videoActivity.href}
                >
                  {videoActivity.label}
                </a>
              </li>
            ))}
            {lesson.sections.map(section => (
              <li key={section.id}>
                <a
                  className={lesson.comingSoon ? styles.disabledLink : ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={Routes.shared_section_path(section.id, { program_id: programId })}
                >
                  {`${I18n.t('reading')} / ${section.label}`}
                </a>
              </li>
            ))}
            {lesson.questions.map(question => (
              <li key={question.id}>
                <a
                  className={lesson.comingSoon ? styles.disabledLink : ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={question.href}
                >
                  {question.label}
                </a>
              </li>
            ))}

            {isSysadmin && (
              <button
                onClick={e => handleClearingCache(e, lesson.id)}
                type="button"
                className="btn btn--red btn--sm tw-mt-1"
                disabled={isClearingCache}
              >
                <i className="fa fa-lock" /> Clear Cache
              </button>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default TagCard;
