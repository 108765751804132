import React, { useEffect, useState } from 'react';
import ReactRailsUJS from '@/modules/ReactRailsUJS';
import HorizontalScrollMenu, { MenuItem } from '@/components/common/HorizontalScrollMenu';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';

interface SectionTabsProps {
  items: { title: string; html: string; }[];
  selected?: string;
}

const SectionTabs = ({ items, selected: initialSelected }: SectionTabsProps) => {
  const [selected, setSelected] = useState<string>(initialSelected || items[0].title);
  const isItemSelected = (title: string): boolean => title === selected;

  useEffect(() => {
    window.editor?.refresh();
    window.editor2?.refresh();
    window.editor3?.refresh();
    ReactRailsUJS.mountComponents();
  }, [selected]);

  return (
    <>
      <HorizontalScrollMenu hideArrows>
        {items.map(({ title }) => (
          <MenuItem
            key={title}
            selected={isItemSelected(title)}
            text={title}
            {...useKeydownAndClick(() => {
              setSelected(title);
            })}
          />
        ))}
      </HorizontalScrollMenu>
      <div className="tw-border-solid tw-border-t-[1px] tw-border-[#ddd] tw-pt-4">
        {items.map(({ title, html }) => (
          <div
            key={title}
            className={isItemSelected(title) ? '' : 'tw-hidden'}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ))}
      </div>
    </>
  );
};

export default SectionTabs;
