/**
 * Used to render a full sentence with embedded dropdown menus for
 * fill in the blank questions
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TTSButton from 'shared/TextToSpeechButton';
import Dropdown from './Dropdown';
import styles from './Prompt.module.scss';

export default class Prompt extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    handleAnswerSelect: PropTypes.func,
    locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
    questionBody: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTTSEnabled: PropTypes.bool,
    randomizeChoices: PropTypes.bool,
    randomizeSeed: PropTypes.number,
    savedResult: PropTypes.object,
    usePromptStyle: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    handleAnswerSelect: null,
    questionTTSEnabled: true,
    randomizeChoices: false,
    randomizeSeed: null,
    savedResult: null,
    usePromptStyle: true,
  };

  static renderSROnlyText(segment) {
    const answer_choices = segment.split(',');
    return $(answer_choices).map((i, answer_choice) => {
      const option_number = `Option ${i + 1}:`;
      return (
        <div key={option_number} className="sr-only" aria-hidden>
          {option_number + answer_choice.replace(/[\W_]+/g, ' ')}
        </div>
      );
    });
  }

  constructor(props) {
    super(props);

    let answerData = {};

    if (this.props.savedResult) {
      answerData = JSON.parse(this.props.savedResult.question_answer.answer);
    }

    this.state = {
      answerData,
      numberOfBlanks: this.props.questionBody.match(/\[[\s\S]*?\]/g)?.length ?? 0,
    };

    this.props.handleAnswerSelect({
      data: this.state.answerData,
      fullyAnswered: this.fullyAnswered(),
      initial: true
    });
  }

  fullyAnswered() {
    const numberOfBlanksAnswered = Object.keys(this.state.answerData).length;
    return this.state.numberOfBlanks === numberOfBlanksAnswered;
  }

  updateAnswerData(dropdownIndex, answerIndex) {
    const answerData = Object.assign({}, this.state.answerData);
    if (answerData[dropdownIndex] === answerIndex) return;
    answerData[dropdownIndex] = answerIndex;
    this.setState({ answerData }, () => {
      if (this.props.handleAnswerSelect) {
        this.props.handleAnswerSelect({
          data: answerData,
          fullyAnswered: this.fullyAnswered(),
          initial: false
        });
      }
    });
  }

  // Wrap all normal text with <span> tags and replace bracketed text with drop downs
  renderPromptWithDropDowns() {
    // Split bracketed text from non-bracketed text
    // Ex: 'The sky is [blue*,red] and large' => ['The sky is ', '[blue*,red]', 'and large']
    const bracketsTextSplit = this.props.questionBody.split(/(\[[\s\S]*?\])/g);

    let dropdownIndex = 0;
    return bracketsTextSplit.map((segment, index) => {
      if (segment.includes('[')) {
        const currentDropdownIndex = dropdownIndex++;
        return this.renderDropDownSegment(segment, currentDropdownIndex, index);
      }

      return Prompt.renderTextSegment(segment, index);
    });
  }

  renderDropDownSegment(segment, currentDropdownIndex, index) {
    return (
      <span key={currentDropdownIndex}>
        {Prompt.renderSROnlyText(segment)}
        <span className={styles.dropdownSegment} data-ignore="1">
          <Dropdown
            disabled={this.props.disabled}
            isComplete={this.props.isComplete()}
            isPartiallyCorrect={this.props.isPartiallyCorrect(currentDropdownIndex)}
            validateDropdownDisabled={this.props.isDropdownDisabledValidator(currentDropdownIndex)}
            key={`${segment}-${index}`}
            bracketedText={segment}
            randomizeChoices={this.props.randomizeChoices}
            randomizeSeed={this.props.randomizeSeed}
            selectedAnswerIndex={this.state.answerData[currentDropdownIndex]}
            handleAnswerSelect={answerIndex => {
              this.updateAnswerData(currentDropdownIndex, answerIndex)
            }}
          />
        </span>
      </span>
    );
  }

  static renderTextSegment(segment, index) {
    return (
      <span
        className={styles.textSegment}
        key={`${segment}-${index}`}
        dangerouslySetInnerHTML={{ __html: segment }}
      />
    );
  }

  renderTTSButton() {
    if (!this.props.questionTTSEnabled) return null;

    return (
      <TTSButton
        locale={this.props.locale}
        idToRead={`question_${this.props.questionId}`}
      />
    );
  }

  render() {
    return (
      <span id={`question_${this.props.questionId}`} className={this.props.usePromptStyle ? styles.prompt : ''}>
        <span>
          {this.renderPromptWithDropDowns()}
        </span>
        {this.renderTTSButton()}
      </span>
    );
  }
}
