import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { IClassroom } from '../../types';
import useGradesTableStore from '../../store/useGradesTableStore';

interface IApiResponse {
  classroom: IClassroom;
}

const useClassroom = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const setStore = useGradesTableStore(state => state.setStore);

  const query = useQuery(['Classroom', classroom?.id], () => (
    axios.get<IApiResponse>(Routes.plato_api_classroom_path(classroom.id, {
      only: ['id', 'name', 'lms_type', 'lms_course_id', 'lms_sync_time', 'lms_sync_date',
             'connected_lms_type', 'is_connected_to_canvas_lti_1_3'],
    })).then(res => res.data.classroom)
  ), { enabled: !!classroom?.id });

  useEffect(() => {
    if (query.isFetching || !query.data) return;

    setStore({ classroom: query.data });
  }, [query.data]);

  return query;
};

export default useClassroom;
