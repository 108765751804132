import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ ...props }) => (
  <div className={props.className}>
    <i aria-hidden className="fa fa-spinner fa-spin fa-2x" />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string
};

Spinner.defaultProps = {
  className: 'center'
};

export default Spinner;
