import { useCallback, useState } from 'react';
import useInterval from '@/hooks/useInterval';

const useNodeSearcher = <T extends HTMLElement | HTMLElement[]>(selector: string) => {
  const [node, setNode] = useState<T>();

  const intervalHandler = useCallback(() => {
    const dNode = document.querySelector(selector) as T;

    if (!dNode) return;

    setNode(dNode);
  }, [selector]);

  useInterval(intervalHandler, { shouldClearEarly: !!node });

  return node;
};

export default useNodeSearcher;
