import React from 'react';

enum ToastTypes {
  SUCCESS = 'success',
  Informative = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

interface ToastProps {
  id: string;
  destroy: () => void;
  title?: React.ReactNode;
  content: React.ReactNode;
  duration?: number;
  type: ToastTypes;
  showIcon?: boolean;
}


export { ToastProps, ToastTypes };
