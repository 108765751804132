import React from 'react';
import PropTypes from 'prop-types';

import BtnLinkWithTooltip, { TooltipMessage } from './BtnLinkWithTooltip';
import I18n from 'modules/i18n';

const RemoveProgramsButton = ({
  disabled, removeProgramsPath, getAdminInfoPath, userType, adminDashPath
}) => (
  <div>
    <BtnLinkWithTooltip
      disabled={disabled}
      disabledMessage={(
        <TooltipMessage
          getAdminInfoPath={getAdminInfoPath}
          userType={userType}
          adminDashPath={adminDashPath}
        />
      )}
      path={removeProgramsPath}
      buttonClasses="dropdown-item"
      faIcon="fa fa-trash"
      buttonText={I18n.t('remove_programs')}
    />
  </div>
);

RemoveProgramsButton.propTypes = {
  adminDashPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getAdminInfoPath: PropTypes.string.isRequired,
  removeProgramsPath: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired
};

RemoveProgramsButton.defaultProps = {
  disabled: false
};

export default RemoveProgramsButton;
