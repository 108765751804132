import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import useProgramAccessStore from '@/components/admin/ProgramAccess/ProgramAccessTable/useProgramAccessStore';
import { IProgram } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import useGroupedPrograms from './useGroupedPrograms';
import useColumns from './useColumns';
import ExpandTableButton, { useExpandTable } from './ExpandTableButton';

const TABLE_BORDER_CLASSES = 'tw-border tw-border-collapse tw-border-solid';
const DEFAULT_BORDER = 'tw-border-med-grey';
const ERROR_BORDER = 'tw-border-red tw-border-2';

interface ManageProgramsTableProps {
  licenseId: number;
  subscriberStateId: number;
}

const ManageProgramsTable = ({ licenseId, subscriberStateId }: ManageProgramsTableProps) => {
  const groupedPrograms = useGroupedPrograms(licenseId, subscriberStateId);
  const columns = useColumns(groupedPrograms, licenseId);
  const { isRowHidden, expandTableButtonProps } = useExpandTable({ groupedPrograms });
  const failedSeatPools = useProgramAccessStore(state => state.failedSeatPools);
  const failedSeatPoolProgramIds = failedSeatPools.map(sp => sp.program_id);

  const table = useReactTable({
    data: groupedPrograms,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="tw-p-3 tw-text-base tw-text-sm tw-w-fit">
      <table className={clsx(TABLE_BORDER_CLASSES, DEFAULT_BORDER)}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="tw-bg-light-grey">
              {headerGroup.headers.map(header => (
                <th key={header.id} className="tw-px-3 tw-py-1 tw-text-left">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              className={clsx({
                'tw-hidden': isRowHidden(row.original),
              })}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={clsx({
                    'tw-min-w-[250px] tw-max-w-[350px]': true,
                    'tw-px-3 tw-py-1 tw-align-middle': true,
                    [TABLE_BORDER_CLASSES]: true,
                    'tw-bg-[#e6e6e6]': !cell.getValue(),
                    [ERROR_BORDER]: failedSeatPoolProgramIds.includes((cell.getContext().getValue() as IProgram)?.id),
                    [DEFAULT_BORDER]: !failedSeatPoolProgramIds.includes((cell.getContext().getValue() as IProgram)?.id),
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <ExpandTableButton {...expandTableButtonProps} />
    </div>
  );
};

export default ManageProgramsTable;
