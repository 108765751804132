import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './InlineEditWithLanguage.module.scss';
import I18n from 'i18n';
import Form from './Form';

const ENGLISH = 'en';
const SPANISH = 'es';

export const LanguageTabs = ({ activeTab, setActiveTab }) => {
  const isEnglish = activeTab === ENGLISH;
  const isSpanish = activeTab === SPANISH;

  return (
    <div className={styles.tabsContainer}>
      <span className={`${styles.tab} ${isEnglish ? styles.active : ''}`} onClick={() => setActiveTab(ENGLISH)}>
        English
      </span>
      <span className={`${styles.tab} ${isSpanish ? styles.active : ''}`} onClick={() => setActiveTab(SPANISH)}>
        Spanish
      </span>
    </div>
  )
};

LanguageTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

const InlineEditWithLanguage = ({ englishVal, spanishVal, paramData, updatePath }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(I18n.locale);
  const [currentEnglishValue, setCurrentEnglishValue] = useState(englishVal);
  const [currentSpanishValue, setCurrentSpanishValue] = useState(spanishVal);
  const [savedEnglishValue, setSavedEnglishValue] = useState(englishVal);
  const [savedSpanishValue, setSavedSpanishValue] = useState(spanishVal);

  const onSuccess = () => {
    const val = formValue();
    isEnglish() ? setSavedEnglishValue(val) : setSavedSpanishValue(val);
    onClose();
  };

  const onCancel = () => {
    setCurrentEnglishValue(savedEnglishValue);
    setCurrentSpanishValue(savedSpanishValue);
    onClose()
  };

  const onClose = () => {
    setActiveTab(I18n.locale);
    setIsEditing(false);
  };

  const isEnglish = () => {
    return activeTab === ENGLISH;
  }

  const formValue = () => {
    return isEnglish() ? currentEnglishValue : currentSpanishValue;
  };

  const setFormValue = (e) => {
    const val = e.target.value;
    isEnglish() ? setCurrentEnglishValue(val) : setCurrentSpanishValue(val);
  };

  const formDataForSubmit = () => {
    const { model, englishParamName, spanishParamName } = paramData;
    return { [model]: { [isEnglish() ? englishParamName : spanishParamName] : formValue() } };
  };

  const renderEdit = () => (
    <div className={styles.editModeContainer}>
      <LanguageTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab} />
      <Form
        formData={formDataForSubmit()}
        onSuccess={onSuccess}
        onCancel={onCancel}
        updatePath={updatePath}
        formValue={formValue()}
        setFormValue={setFormValue} />
    </div>
  );

  const renderDisplayText = () => (
    <>
      {I18n.locale === ENGLISH ? currentEnglishValue : currentSpanishValue}
      <span className={styles.editButton} onClick={() => setIsEditing(true)}>
        <i className="fa fa-pencil-square-o" aria-hidden></i>
      </span>
    </>
  );

  return (
    <div className={styles.inlineEditWithLanguage}>
      {isEditing ?
        renderEdit() :
        renderDisplayText()}
    </div>
  );
};

InlineEditWithLanguage.propTypes = {
  englishVal: PropTypes.string,
  spanishVal: PropTypes.string,
  paramData: PropTypes.shape({
    model: PropTypes.string.isRequired,
    englishParamName: PropTypes.string.isRequired,
    spanishParamName: PropTypes.string.isRequired,
  }),
  updatePath: PropTypes.string.isRequired,
};

export default InlineEditWithLanguage;
