import React, { useMemo, useRef } from 'react';
import Tooltip from '@/components/common/Tooltip';
import { Thumbnail } from '@/components/admin/ProgramAccess/ProgramAccessTable/ProgramCell';
import { IProgram, expiringYear } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import { programIsExpired } from '@/components/common/Utils';
import useProgramAccessStore from '@/components/admin/ProgramAccess/ProgramAccessTable/useProgramAccessStore';

interface ProgramCheckboxProps {
  licenseId: number;
  program: IProgram;
  recommended?: boolean;
}

const useIsDisabled = (program: IProgram, licenseId: number) => {
  const seatPools = useProgramAccessStore(state => state.seatPools);

  const seatPool = useMemo(() => (
    seatPools.find(sp => (
      sp.program.id.toString() === program.id.toString() && sp.license_id.toString() === licenseId.toString()
    ))
  ), [seatPools.length, program.id]);

  return useMemo(() => (
    (!!seatPool) && (seatPool.number_of_students > 0 || seatPool.number_of_teachers > 0)
  ), [seatPool?.id, program.id]);
};

const ProgramCheckbox = ({ licenseId, program, recommended }: ProgramCheckboxProps) => {
  const selectedPrograms = useProgramAccessStore(state => state.selectedPrograms);
  const toggleProgram = useProgramAccessStore(state => state.toggleProgram);
  const id = `program-${program.id}`;
  const isDisabled = useIsDisabled(program, licenseId);
  const ref = useRef<HTMLDivElement>();

  const renderProgramMessage = () => {
    if (programIsExpired(program.retirement_year)) {
      return (
        <span className="tw-text-xs tw-leading-5 tw-text-red">
          Retired edition.&nbsp;
          <a
            href="https://help.teachtci.com/s/article/Edition-Retirement"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn More
          </a>
        </span>
      );
    }

    if (recommended) {
      return (
        <span className="tw-text-xs tw-leading-5 tw-text-green">
          <i>Recommended</i>
        </span>
      );
    }

    if (program.retirement_year === expiringYear) {
      return (
        <span className="tw-text-xs tw-leading-5 tw-text-red">
          Unavailable after June 30, {expiringYear}
        </span>
      );
    }

    return null;
  };

  return (
    <label
      htmlFor={id}
      className="tw-flex tw-items-center tw-justify-between tw-min-w-[250px]"
    >
      <Thumbnail url={!program.small_url.includes('missing.png') && program.small_url} />

      <div className="tw-flex tw-mx-3 tw-flex-col tw-items-center">
        <span>{program.edition} Edition</span>
        {renderProgramMessage()}
      </div>
      <div ref={ref}>
        <Tooltip
          appendTo={ref && ref.current}
          interactive={false}
          content={<span>You cannot remove programs that are still in use.<br /> Tip: Bulk Remove program from all Staff.</span>}
          shouldDisplay={isDisabled}
        >
          <span>
            <input
              id={id}
              type="checkbox"
              disabled={isDisabled}
              checked={selectedPrograms.has(program.id)}
              onChange={() => toggleProgram(program.id)}
            />
          </span>
        </Tooltip>
      </div>
    </label>
  );
};

export default ProgramCheckbox;
