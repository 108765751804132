import React, { useEffect, useRef } from 'react';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './TransitionSlide.module.scss';
import { useResizer } from '../../Slide/Utils';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { SlideGroup } from '../../Builder/CreateTransistionGroup/utils';
import { ForDisplay, IAssignmentShortcutProps, IGradeShortcutProps, ILmsShareProps } from './types';
import VideoActivityFinalSlide from './VideoActivityFinalSlide';
import ProfessionalDevelopmentFinalSlide from './ProfessionalDevelopmentFinalSlide';
import FinalSlide from './FinalSlide';
import { ASSIGNMENT_PARAM_NAME } from './AssignmentSelect';

interface TransitionSlideProps {
  AssignmentShortcutProps?: IAssignmentShortcutProps;
  className?: string;
  forDisplay?: ForDisplay;
  GradeShortcutProps?: IGradeShortcutProps;
  imageUrl: string;
  isSelected?: boolean;
  isTemporary?: boolean;
  jumpToSlide: (number) => void;
  LmsShareProps?: ILmsShareProps;
  slideGroupIndex: number;
  slideGroups: SlideGroup[];
}

const TransitionSlide = ({
  className = '', forDisplay = 'present', AssignmentShortcutProps, GradeShortcutProps, LmsShareProps, imageUrl,
  jumpToSlide, slideGroupIndex, slideGroups, isSelected, isTemporary = false,
}: TransitionSlideProps) => {
  const slideRef = useRef(null);
  const isIntroSlide = slideGroupIndex === 0;
  const isFinalSlide = slideGroupIndex === slideGroups.length - 1;
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const { activityType } = slideShow;
  const url = new URL(window.location.href);
  const assignmentId = url.searchParams.get(ASSIGNMENT_PARAM_NAME);

  useResizer(forDisplay, slideRef, false);

  useEffect(() => {
    if (isSelected) slideRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [isSelected]);

  if (!slideGroups[slideGroupIndex]) return null;

  const getFinalSlide = () => {
    if (isFinalSlide && activityType === 'professional_development') {
      return <ProfessionalDevelopmentFinalSlide />;
    }

    if (isFinalSlide && activityType === 'video_activity') {
      return (
        <VideoActivityFinalSlide
          assignmentId={assignmentId}
          AssignmentShortcutProps={AssignmentShortcutProps}
          LmsShareProps={LmsShareProps}
          GradeShortcutProps={GradeShortcutProps}
          forDisplay={forDisplay}
        />
      );
    }

    if (isFinalSlide) {
      return (
        <FinalSlide
          assignmentId={assignmentId}
          imageUrl={imageUrl}
          slideGroups={slideGroups}
          slideGroupIndex={slideGroupIndex}
          jumpToSlide={jumpToSlide}
          AssignmentShortcutProps={AssignmentShortcutProps}
          LmsShareProps={LmsShareProps}
          GradeShortcutProps={GradeShortcutProps}
          forDisplay={forDisplay}
          isTemporary={isTemporary}
        />
      );
    }

    return (
      <>
        <LeftPanel
          imageUrl={imageUrl}
          isIntroSlide={isIntroSlide}
          slideGroup={isIntroSlide || isFinalSlide ? slideGroups[slideGroupIndex] : slideGroups[slideGroupIndex - 1]}
        />
        <RightPanel
          assignmentId={assignmentId}
          isIntroSlide={isIntroSlide}
          isFinalSlide={isFinalSlide}
          slideGroups={slideGroups}
          slideGroupIndex={slideGroupIndex}
          jumpToSlide={jumpToSlide}
          AssignmentShortcutProps={AssignmentShortcutProps}
          LmsShareProps={LmsShareProps}
          GradeShortcutProps={GradeShortcutProps}
          forDisplay={forDisplay}
          loading={false}
          isTemporary={isTemporary}
        />
      </>
    );
  };

  const classes = `slide ${styles.transitionSlide} ${styles.slide} ${className}
    ${forDisplay !== 'builder' && styles.present}
    ${isSelected && styles.selected}`;

  return (
    <section
      aria-label="Transition Slide"
      className={classes}
      ref={slideRef}
    >
      {getFinalSlide()}
    </section>
  );
};

export default TransitionSlide;
