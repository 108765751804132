import React from 'react';
import I18n from 'modules/i18n';

interface PrimarySourceIconProps {
  ariaLabel?: string;
  color?: string;
  size?: number
}

const PrimarySourceIcon = ({
  ariaLabel = I18n.t('library_primary_sources'),
  color = '#247F99',
  size = 60
 }: PrimarySourceIconProps) => (
  <svg
    aria-label={ariaLabel}
    width={size}
    height={size}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M46.5513 8.78573C46.3159 8.6758 46.0574 8.62437 45.7978 8.63581L45.6642 5.60548C46.4119 5.57252 47.1565 5.72067 47.8347 6.03733C48.5128 6.35399 49.1045 6.82978 49.5592 7.42422C50.0139 8.01865 50.3184 8.71414 50.4465 9.45152C50.571 10.1675 50.5257 10.9025 50.3148 11.5973L49.5729 14.754H39.7876V11.7207H47.1699L47.3877 10.7939L47.4056 10.7375C47.4846 10.49 47.5026 10.227 47.4581 9.97104C47.4136 9.71505 47.3079 9.4736 47.15 9.26722C46.9922 9.06084 46.7868 8.89566 46.5513 8.78573Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.20708 38.2264H27.7669L27.1054 41.1024L25.8619 40.8164V40.8373L27.0722 41.2236C26.9931 41.4716 26.9751 41.735 27.0199 41.9914C27.0647 42.2479 27.1708 42.4897 27.3293 42.6962C27.4878 42.9027 27.6939 43.0677 27.93 43.1773C28.1107 43.2611 28.3049 43.3105 28.5026 43.3236H28.7571L28.8289 46.3552C28.6952 46.3615 28.5615 46.3621 28.4283 46.3569H5.34212C4.60641 46.3855 3.87423 46.2389 3.20601 45.9288C2.52586 45.6133 1.93218 45.1378 1.47567 44.543C1.01915 43.9481 0.713379 43.2517 0.584436 42.5131C0.459454 41.7971 0.504207 41.0622 0.714683 40.3673L1.20708 38.2264ZM24.2503 43.3236C24.1535 43.0615 24.0802 42.7902 24.0318 42.5131C23.9593 42.0978 23.9439 41.6762 23.9849 41.2596H3.61381C3.54412 41.497 3.52991 41.7474 3.57251 41.9914C3.61728 42.2479 3.72344 42.4897 3.88192 42.6962C4.04041 42.9027 4.24652 43.0677 4.48265 43.1773C4.71878 43.2869 4.97791 43.3376 5.23792 43.3253L5.27381 43.3236H24.2503Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.23553 3.63949L5.1793 6.24413C6.95701 8.62626 10.4348 10.6391 14.2476 12.2126C17.6819 13.6341 20.588 15.1951 22.656 16.9554C24.4959 18.5215 25.8122 20.3709 26.0758 22.5266C27.2965 23.4684 28.0783 24.5152 28.4784 25.6149C28.944 26.8949 28.8398 28.1036 28.5069 29.0588C28.2954 29.6659 27.7368 30.0531 27.1309 30.0757C28.5534 31.9697 29.5712 33.7274 30.0249 35.2257L30.8744 38.0309L21.3599 34.8594L21.0836 34.2704C20.8514 33.7756 20.596 33.2921 20.3182 32.8217C18.3643 32.8585 16.8885 32.4438 15.9131 31.497C15.2011 30.8058 14.9153 29.9877 14.8182 29.2616C12.1378 28.1628 9.75184 26.4407 7.86175 24.2324C3.84959 19.9885 0.922053 13.426 2.48786 6.8023L3.23553 3.63949ZM25.6078 28.1743C25.6179 28.1363 25.6294 28.0983 25.6426 28.0606C25.7787 27.67 25.8223 27.1865 25.6278 26.6518C25.4327 26.1154 24.9453 25.3851 23.7686 24.6034L23.0889 24.152L23.0911 23.336C23.0945 22.0734 22.4061 20.726 20.6899 19.2652C18.9733 17.8041 16.4038 16.3879 13.0896 15.0161C10.2964 13.8633 7.37291 12.3701 5.09437 10.4805C5.08853 14.8507 7.19009 19.1181 10.0898 22.1738L10.1178 22.2032L10.1441 22.2341C11.917 24.3148 14.2164 25.8808 16.8018 26.7684L17.9216 27.1528L17.8205 28.3324C17.7715 28.904 17.8725 29.1717 18.0258 29.3205C18.2048 29.4942 18.8843 29.9258 21.006 29.7498L21.9024 29.6754L22.3976 30.4264C22.8145 31.0588 23.1957 31.7139 23.5394 32.3886L25.5295 33.052C24.5558 31.6074 23.1854 29.92 21.4406 28.0679C18.3022 24.7363 14.1144 21.0394 9.3248 17.5176C8.64998 17.0214 8.50517 16.0721 9.00137 15.3972C9.49756 14.7224 10.4469 14.5776 11.1217 15.0738C16.0372 18.6882 20.367 22.5046 23.6485 25.988C24.3433 26.7255 24.999 27.4563 25.6078 28.1743Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4178 8.63604C19.2057 8.63604 17.6082 9.5876 16.3111 11.9315L13.6571 10.4629C15.2145 7.64839 17.6336 5.60277 20.4178 5.60277H45.7206V8.63604C44.359 8.63604 42.4968 9.86418 41.1726 12.8343L41.1726 12.8343C40.7471 13.7884 40.3694 15.6071 40.0182 18.0958C39.7401 20.0659 39.4948 22.3187 39.2389 24.669C39.1787 25.222 39.1178 25.7804 39.0559 26.3419C38.7329 29.2682 38.3796 32.2651 37.9194 34.8862C37.467 37.4623 36.8837 39.8537 36.0315 41.469L36.0304 41.4711C35.0798 43.266 33.9937 44.5245 32.7108 45.3175C31.4153 46.1181 30.0621 46.3557 28.7467 46.3557V43.3225C29.6843 43.3225 30.4363 43.1574 31.116 42.7372C31.8081 42.3095 32.5671 41.5292 33.3495 40.0522C33.9599 38.8944 34.4824 36.9205 34.9318 34.3616C35.3734 31.8466 35.7178 28.937 36.0409 26.0091C36.1015 25.4604 36.1613 24.9105 36.221 24.3628C36.4772 22.0092 36.7292 19.6946 37.0147 17.6719C37.3588 15.2335 37.7776 12.9999 38.4022 11.5991C38.8901 10.5048 39.4932 9.49885 40.1897 8.63604H20.4178Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3853 29.6859C13.0587 33.2537 12.3541 36.7766 11.2834 40.1955L8.38875 39.2889C9.39532 36.075 10.0577 32.7633 10.3646 29.4095L13.3853 29.6859Z" fill={color} />
  </svg>
);

export default PrimarySourceIcon;
