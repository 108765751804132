import React from 'react';
import PropTypes from 'prop-types';
import styles from './ManagePrograms.module.scss';

export const handleKeyPress = (e, callback) => {
  const key = e.which;
  if (key === 13 || key === 32) callback();
};

export const Button = ({
  classes, disabled, children, path
}) => (
  <div className={`inline_block ${styles.button}`}>
    <a
      href={disabled ? null : path}
      className={`${disabled ? 'disabled' : ''} ${classes}`}
    >
      {children}
    </a>
  </div>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  path: PropTypes.string.isRequired
};

Button.defaultProps = {
  classes: 'btn btn--purple',
  disabled: false
};
