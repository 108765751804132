import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ onClick, label, buttonClass }) => (
  <button
    className={`btn btn--outline-red ${buttonClass}`}
    onClick={onClick}
  >
    {label}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonClass: PropTypes.string
};

Button.defaultProps = {
  buttonClass: ''
};

export default Button;
