import React from 'react';
import { LessonType, UnitType } from '@/components/shared/NewDashboard/types';
import * as Routes from '@/modules/routes';

interface TableOfContentsProps {
  units: UnitType[];
}

const TableOfContents = ({ units }: TableOfContentsProps) => {
  const getUnitPath = (unit: UnitType) => Routes.shared_program_unit_path(unit.program_id, unit.id);
  const getLessonPath = (unit: UnitType, lesson: LessonType) => (
    Routes.shared_program_lesson_slide_shows_path(unit.program_id, lesson.id)
  );

  return (
    <section className="tw-py-2 tw-px-4 tw-max-h-[30rem] tw-overflow-y-auto">
      {units.map(unit => (
        <ul key={unit.id} className="tw-flex tw-mb-5 tw-leading-loose">
          <li className="tw-w-full">
            <div className="tw-flex tw-items-flex-start">
              <img
                className="tw-w-20 tw-h-20 tw-object-cover tw-rounded-full"
                src={unit.poster_url}
                alt={unit.title}
              />
              <div className="tw-flex-grow">
                <div className="tw-h-10 tw-border-b tw-border-b-1 tw-border-grey tw-border-solid">
                  <a
                    className="tw-text-tci-purple tw-font-bold tw-text-l tw-ml-4"
                    href={getUnitPath(unit)}
                  >
                    {unit.title_with_position}
                  </a>
                </div>
                <ul className="tw-pl-8">
                  {unit.lessons.map(lesson => (
                    <li key={lesson.id}>
                      <a href={getLessonPath(unit, lesson)}>{lesson.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      ))}
    </section>
  );
};

export default TableOfContents;
