import React, { useState } from 'react';
import I18n from 'modules/i18n';
import * as Routes from 'modules/routes';
import styles from './CorrelationsTags.module.scss';
import CorrelationTag, { Tag } from './CorrelationTag';

type Program = {
  id: number;
};

type CorrelationsTagsProps = {
  label: string;
  linkHref?: string;
  locale?: 'en' | 'es';
  program: Program;
  tags: Tag[];
};

const CorrelationsTags = ({ label, linkHref = '#', locale = 'en', program, tags }: CorrelationsTagsProps) => {
  const [expanded, setExpanded] = useState(false);

  if (tags.length === 0) return null;

  const end = expanded ? tags.length : Math.min(4, tags.length);

  return (
    <div>
      {label && (
        <a className={styles.benchmarksLink} href={Routes.state_shared_program_correlations_path(program.id)}>
          {label}
          :&nbsp;
        </a>
      )}
      {tags.slice(0, end).map((tag, i) => (
        <span key={tag.id}>
          <CorrelationTag appendText={i !== end - 1} linkHref={linkHref} locale={locale} tag={tag} />
        </span>
      ))}
      {(end < tags.length || expanded) && (
        <button className={`${styles.seeMore} no-toggle`} type="button" onClick={() => setExpanded(!expanded)}>
          {I18n.t(expanded ? 'see_less' : 'see_more')}
        </button>
      )}
    </div>
  );
};

export default CorrelationsTags;
