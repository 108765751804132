import { useLayoutEffect } from 'react';
import { IHtmlElement } from '@/components/interfaces/graphql/HtmlElement';
import {
  replaceModelPhenomenon,
  replaceModelStoryline,
  replaceShallowVariable,
} from './utils';

export const useVariableReplacer = (htmlElements: IHtmlElement[]) => {
  useLayoutEffect(() => {
    htmlElements.forEach((htmlElement) => {
      const node = document.querySelector(`[data-html-element-id="${htmlElement.id}"]`) as HTMLElement;

      if (!node) return;

      if (htmlElement.type === 'ModelNumber') {
        replaceShallowVariable(htmlElement, node);
      } else if (htmlElement.type === 'ModelTitle') {
        replaceShallowVariable(htmlElement, node);
      } else if (htmlElement.type === 'ModelPhenomenon') {
        replaceModelPhenomenon(htmlElement, node);
      } else if (htmlElement.type === 'ModelEssentialQuestion') {
        replaceShallowVariable(htmlElement, node);
      } else if (htmlElement.type === 'ModelStoryline') {
        replaceModelStoryline(htmlElement, node);
      }
    });
  }, []);
};
