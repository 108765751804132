import React from 'react';
import { useDragLayer } from 'react-dnd';
import BuilderContext from '@/components/common/Context';
import BoxDragPreview from './BoxDragPreview';
import DraggableChoicePreview from '../DraggableChoicePreview';
import doSnapToGrid from './snapToGrid';
import ItemTypes from '../ItemTypes';
import styles from './DragAndDrop.module.scss';

function getItemStyles({ initialOffset, currentOffset, isSnapToGrid }) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;
  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = doSnapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

interface CustomDragLayerProps {
  snapToGrid: boolean;
}

const CustomDragLayer = ({ snapToGrid }: CustomDragLayerProps) => {
  const {
    isDragging,
    item,
    itemType,
    initialOffset,
    currentOffset,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case ItemTypes.BOX:
        return <BoxDragPreview height={item.height as number} width={item.width as number} shape={item.shape as string} />;
      case ItemTypes.textArea:
        return <BoxDragPreview height={item.height as number} width={item.width as number} />;
      case ItemTypes.draggableChoice:
        return <DraggableChoicePreview content={item.textContent} context={BuilderContext} />;
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div className={styles.customDragLayer}>
      <div style={getItemStyles({
                    initialOffset,
                    currentOffset,
                    isSnapToGrid: snapToGrid && itemType !== ItemTypes.draggableChoice
                  })}
      >
        {renderItem()}
      </div>
    </div>
  );
};

export default CustomDragLayer;
