import React, { useContext } from 'react';
import BuilderContext from '../../../../../Context';
import styles from './CopyingText.module.scss';

const CopyingText = () => {
  const { isCopying } = useContext(BuilderContext);

  if (!isCopying) return null;

  return (
    <div>
      <div className={styles.iconWrapper}>
        <i className="fa fa-info" />
      </div>
      Please select which drop zone to make a copy of below.
    </div>
  );
};

export default CopyingText;
