import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import apolloClient, { ApolloProvider } from 'common/ApolloClient';
import BuilderContext from 'common/Context';
import AddImage from 'admin/ImageManager/Add';
import { FileField } from 'common/Forms';
import { SubmitModal } from 'common/Modal';
import * as Routes from 'routes';
import styles from './BackgroundImageButton.module.scss';

const AddBackgroundImageModal = ({ afterSubmit, questionId, spanishImagesInMicAndDd }) => {
  const { modalManager } = useContext(BuilderContext);

  if (spanishImagesInMicAndDd) {
    return (
      <ApolloProvider client={apolloClient}>
        <AddImage
          mediaMeta={{ position: 'center center', size: 'contain', type: 'background' }}
          afterMergeDuplicates={afterSubmit}
          afterSubmit={afterSubmit}
          modelId={questionId}
          modelType="Question"
          standaloneButton={false}
          isAdmin
          modalManager={modalManager}
        />
      </ApolloProvider>
    );
  }

  return (
    <SubmitModal
      headerText="Add Background Image"
      isOpen={modalManager.isOpen}
      closeModal={modalManager.close}
      submitPath={Routes.plato_api_question_path(questionId)}
      afterSubmit={afterSubmit}
      method="patch"
      primaryButtonText="Submit"
      primaryButtonClassName="btn btn--purple"
    >
      <figure className={styles.inputContainer}>
        <FileField
          name="background_image"
          accept="image/png, image/jpeg, image/jpg"
        />
        <span className={styles.hint}>
          Image Requirement: .JPEG / .JPG / .PNG
        </span>
      </figure>
    </SubmitModal>
  );
};

AddBackgroundImageModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
  spanishImagesInMicAndDd: PropTypes.bool.isRequired,
};

export default AddBackgroundImageModal;
