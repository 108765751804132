/**
 * Uses jQuery to parse HTML in the `content` prop
 *  Get all elements in the HTML that have the `data-button-type="reading"` attribute
 *  Iterates through them, creates an object that maps `data-section-ids` to `data-section-type`
 *  Memoizes the map based on the content passed in
 */

import { useMemo } from 'react';

type SectionTypeMap = { [key: string]: string };

const buildSectionTypeMap = (htmlContent): SectionTypeMap => {
  const $readingButtons = $(`<div>${htmlContent}</div>`).find('[data-button-type="reading"]').get();

  return $readingButtons.reduce((buttons, currentButton) => {
    $(currentButton)
      .find('[data-section-ids]')
      .attr('data-section-ids').split(',')
      .forEach((sectionId) => {
        /* eslint-disable-next-line no-param-reassign */
        buttons[sectionId] = $(currentButton).attr('data-section-type');
      });
    return buttons;
  }, {});
};

const useSectionTypeMap = content => useMemo(() => buildSectionTypeMap(content), [content]);

export default useSectionTypeMap;
