const useBrowserCache = () => {
  const keyBase = key => key.split(':')[0];

  const flushStaleKeys = (cacheKey) => {
    Object.keys(localStorage)
      .filter(key => key !== cacheKey && keyBase(key) === keyBase(cacheKey))
      .forEach(key => localStorage.removeItem(key));
  };

  /**
   * Uses localStorage to store the result of the request at the cacheKey
   * @param {String} cacheKey - The localStorage key to cache the response from request at
   * @param {Function} request - A callback that returns data that can be Stringified
   * @returns {Promise} - A promise to the data that's either returned from localStorage or the request
   */
  const fetchCachedResponse = (cacheKey, request) => {
    try {
      if (cacheKey && typeof Storage !== 'undefined') {
        const cachedResponse = localStorage.getItem(cacheKey);

        flushStaleKeys(cacheKey);

        if (cachedResponse) return new Promise(resolve => resolve(JSON.parse(cachedResponse)));

        return request()
          .then((response) => {
            const data = response.data.data;

            localStorage.setItem(cacheKey, JSON.stringify(data));

            return data;
          });
      }

      return request().then(response => response.data.data);
    }
    catch {
      return request().then(response => response.data.data);
    }
  };

  return { fetchCachedResponse };
};

export default useBrowserCache;
