import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import Select from 'react-select';
import SelectAsync from 'react-select/lib/Async';
import Tooltip from 'common/Tooltip';
import { snakeToTitle } from 'common/Utils';
import { RequiredAsterisk } from './Utils';
import styles from './index.module.scss';
import clsx from 'clsx';

const appendFormData = (name, value, formData) => {
  if (value === undefined || value === null) return;

  if (Array.isArray(value)) {
    value.forEach(val => appendFormData(`${name}[]`, val, formData));
  }
  else if (value instanceof File) {
    formData.append(name, value);
  }
  else if (typeof value === 'object') {
    Object.keys(value).forEach((k) => {
      appendFormData(`${name}[${k}]`, value[k], formData);
    });
  }
  else {
    formData.append(name, value);
  }
};

export const useSubmit = () => {
  const [submitting, setSubmitting] = useState(false);

  const submit = (method, path, data, callback, errorCallback, useRecursiveAppend = false) => {
    setSubmitting(true);

    let modifiedData = null;

    if (data) {
      modifiedData = Object.keys(data).reduce((formData, name) => {
        if (useRecursiveAppend) {
          appendFormData(name, data[name], formData);
        }
        else {
          if (data[name] === undefined || data[name] === null) return formData;

          formData.append(name, data[name]);
        }

        return formData;
      }, new FormData());
    }

    Axios[method](path, modifiedData)
      .then((response) => {
        setSubmitting(false);
        if (callback) callback(response.data.data);
      }).catch((error) => {
        setSubmitting(false);
        if (errorCallback) errorCallback(error);
      });
  };

  return { submit, submitting };
};

/**
 * Renders our basic form field layout, including a required asterisk if necessary, as well as validation errors
 */
export const StandardField = ({
  required, element, validate, tooltipText, warning, hideLabel,
  labelClass, fieldClass, rowClass, parse, format, onChangeCallback,
  labelPos, setRef, tooltipIcon, ...props
}) => {
  const id = props.id || `${props.name}_field`;
  const label = props.label || snakeToTitle(props.name);

  const tooltip = () => {
    if (tooltipText) {
      return (
        <Tooltip content={tooltipText} theme="white" size="medium">
          <span>
            <i className={`fa fa-${tooltipIcon}-circle`} aria-hidden />
            <span className="sr-only">{tooltipText}</span>
          </span>
        </Tooltip>
      );
    }
    return null;
  };

  const createInputElement = (input, onChange) => (
    React.createElement(element, {
      ...input,
      ...props,
      id,
      ref: setRef,
      onChange: (e) => {
        input.onChange(e);
        if (onChange) onChange(e);
        if (onChangeCallback) onChangeCallback(e);
      },
    })
  );

  const labelElem = () => {
    if (hideLabel) return null;

    return (
      <label htmlFor={id} className={labelClass || styles.label}>
        {label}
        {required && <RequiredAsterisk />}
        {tooltip()}
      </label>
    );
  };

  return (
    <Field
      {...props}
      validate={validate}
      format={format}
      parse={parse}
      render={({
        meta,
        input,
        onChange
      }) => {
        const error = meta.error || warning;

        return (
          <div className={rowClass || styles.fieldRow}>
            {labelPos === 'left' && labelElem()}
            <div className={fieldClass || styles.fieldColumn}>
              {createInputElement(input, onChange)}
              {(meta.touched || warning) && error && <div className={styles.error}>{error}</div>}
            </div>
            {labelPos === 'right' && labelElem()}
          </div>
        );
      }}
    />
  );
};

StandardField.propTypes = {
  element: PropTypes.elementType.isRequired,
  fieldClass: PropTypes.string,
  format: PropTypes.func,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClass: PropTypes.string,
  labelPos: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  parse: PropTypes.func,
  required: PropTypes.bool,
  rowClass: PropTypes.string,
  setRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  tooltipIcon: PropTypes.oneOf(['question', 'info']),
  tooltipText: PropTypes.string,
  validate: PropTypes.func,
  warning: PropTypes.node
};

StandardField.defaultProps = {
  fieldClass: null,
  format: undefined,
  hideLabel: false,
  id: null,
  label: null,
  labelClass: null,
  labelPos: 'left',
  onChangeCallback: undefined,
  parse: undefined,
  required: false,
  rowClass: null,
  tooltipIcon: 'question',
  tooltipText: '',
  validate: null,
  warning: undefined
};

/**
 * Renders a text area element within our basic field layout
 */
export const TextAreaField = ({ ...props }) => <StandardField {...props} element="textarea" />;

/**
 * Renders an input text element within our basic field layout
 */
export const TextField = ({ ...props }) => <StandardField {...props} element="input" type="text" />;

const ReactSelectAdapter = ({ onChange, ...props }) => (
  <Select {...props} searchable onChange={args => onChange(args && args.value)} />
);

ReactSelectAdapter.propTypes = {
  onChange: PropTypes.func.isRequired
};

/**
 * Renders a react select object within our basic field layout
 */
export const SelectField = ({ ...props }) => <StandardField {...props} element={ReactSelectAdapter} />;

const ReactSelectAsyncAdapter = ({
  onChange, formatOptions, searchPath, value: initialValue, ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const loadOptions = input => (
    Axios
      .post(searchPath(input))
      .then(response => ({ options: formatOptions(response.data.data) }))
      .catch(error => console.log(error))
  );

  return (
    <SelectAsync
      onChange={(args) => {
        setValue(args);
        onChange(args && args.value);
      }}
      searchable
      loadOptions={loadOptions}
      {...props}
      value={value}
    />
  );
};

ReactSelectAsyncAdapter.propTypes = {
  formatOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchPath: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ReactSelectAsyncAdapter.defaultProps = {
  value: null
};

export const AsyncSelectField = ({ ...props }) => <StandardField {...props} element={ReactSelectAsyncAdapter} />;

export const ImagePreview = ({ src }) => {
  if (!src) return null;

  return (
    <a className="tw-cursor-zoom-in" href={src} target="_blank" rel="noopener noreferrer">
      <img alt="Preview" className="tw-w-[50px]" src={src} />
    </a>
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string,
};

const FileFieldAdapter = ({ fileTypeText, onChange, onChangeCallback, value, setRef, loading, useImagePreview, initialPreview, ...props }) => {
  const [imagePreview, setImagePreview] = useState(initialPreview);

  useEffect(() => {
    if (!initialPreview) return;

    setImagePreview(initialPreview);
  }, [initialPreview]);

  const Component = useImagePreview ? 'div' : React.Fragment;
  const componentProps = useImagePreview ? { className: 'tw-flex tw-gap-2 tw-relative' } : {};

  return (
    <Component {...componentProps}>
      {imagePreview && <ImagePreview src={imagePreview} />}
      <input
        {...props}
        type="file"
        onChange={({ target }) => {
          onChange(target.files[0]);
          if (onChangeCallback) onChangeCallback(target.files[0]);
          if (useImagePreview) setImagePreview(URL.createObjectURL(target.files[0]));
        }}
        ref={setRef}
      />
      {fileTypeText && <span className={styles.fileTypeText}>{fileTypeText}</span>}
      {loading && (
        <span className={clsx({ 'tw-absolute tw-right-0': useImagePreview })}>
          <i aria-hidden className="fa fa-spinner fa-spin" />
        </span>
      )}
    </Component>
  );
};

FileFieldAdapter.propTypes = {
  fileTypeText: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func,
  setRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  useImagePreview: PropTypes.bool,
  initialPreview: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

FileFieldAdapter.defaultProps = {
  fileTypeText: '',
  initialPreview: undefined,
  loading: false,
  onChangeCallback: undefined,
  setRef: React.createRef(),
  useImagePreview: false,
  value: {}
};

export const FileField = ({ ...props }) => <StandardField {...props} element={FileFieldAdapter} />;

export const CheckboxField = ({ ...props }) => <StandardField {...props} element="input" type="checkbox" />;

export const LabelField = ({ content, id, label }) => (
  <div className={styles.fieldRow}>
    <label htmlFor={id} className={styles.label}>{label}</label>
    <div id={id} className={styles.fieldColumn}>{content}</div>
  </div>
);

LabelField.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

LabelField.defaultProps = {
  content: null
};

export const NumberField = ({ className, ...props }) => (
  <StandardField
    element="input"
    type="number"
    className={`${styles.numberField} ${className}`}
    {...props}
  />
);

NumberField.propTypes = {
  className: PropTypes.string
};

NumberField.defaultProps = {
  className: null
};

const DatePickerAdapter = ({ value, ...rest }) => (
  <ReactDatePicker
    selected={value || null}
    {...rest}
  />
);

DatePickerAdapter.propTypes = {
  value: PropTypes.shape().isRequired
};

export const DateField = ({ ...props }) => (
  <StandardField element={DatePickerAdapter} placeholderText="No date selected." {...props} />
);
