import React from 'react';
import Async from 'react-select/lib/Async';
import PropTypes from 'prop-types';

export default function ReactAsyncSelectAdapter({ input, ...rest }) {
  return (
    <Async
      {...input}
      {...rest}
      key={rest.key}
      loadOptions={rest.getOptions}
      ref={(ref) => { rest.bindSelectRef(ref); }}
      searchable
      cache={false}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
    />
  );
}

ReactAsyncSelectAdapter.propTypes = {
  clearable: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
};

ReactAsyncSelectAdapter.defaultProps = {
  clearable: true
};
