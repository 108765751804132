import React, { Fragment } from 'react';
import showToast from '../../common/Toast';

const scienceMessage = 'Only one Investigation type can be visible to students at a time. ';

export default function NotVisibleToStudents({ settingsPath, scienceProgram }) {
  showToast(
    <Fragment>
      {scienceProgram && scienceMessage}
      <a href={settingsPath} target="_blank" rel="noopener noreferrer">Change your visibility settings</a>.
    </Fragment>,
    {
      autoClose: false,
      closeOnClick: false,
      msgHeading: 'Not visible to students!',
      msgType: 'warning'
    }
  );

  return null;
}
