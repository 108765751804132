import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDOM from 'react-dom';

import Edit from '../../common/Classes/Edit';
import Delete from '../../common/Classes/Delete';
import { formatProgramsForSelect } from '../../common/Classes/FormUtils';
import TeacherModal from '../../common/Classes/TeacherModal';
import { matchAttributesForSingleRow } from '../../common/DataTable/Utils';
import PaginatedTable from '../../common/DataTable/PaginatedTable';
import AdvancedFilters from '../../common/AdvancedFilters';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from '../../common/AdvancedFilters/Utils';

import StudentModal from './StudentModal';
import ClassesAdminDropdown from './ClassesAdminDropdown';
import Add from './Add';
import { isAdmin } from '../../common/Utils';

export default class ClassesTable extends Component {
  static propTypes = {
    ajaxPath: PropTypes.string.isRequired,
    asyncSearchPath: PropTypes.string.isRequired,
    classroomStaffersPath: PropTypes.string.isRequired,
    classroomStudentsPath: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    createPath: PropTypes.string.isRequired,
    destroyPath: PropTypes.string.isRequired,
    initialFilters: filtersPropTypes,
    manageStudentsPath: PropTypes.string.isRequired,
    manageTeachersPath: PropTypes.string.isRequired,
    pageSize: PropTypes.number,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    searchStudentsPath: PropTypes.string.isRequired,
    subscriberId: PropTypes.string.isRequired,
    subscriberProgramsPath: PropTypes.string.isRequired,
    subscriberStaffersPath: PropTypes.string.isRequired,
    subscriberStudentsPath: PropTypes.string.isRequired,
    updatePath: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    initialFilters: null,
    pageSize: 250
  };

  static _processResources(resources) {
    return resources.map(resource => ClassesTable._processResource(resource));
  }

  static _processResource(resource) {
    return {
      ...resource,
      program: resource.program.full_title_with_edition,
      programId: resource.program.id,
      students: resource.student_seats_count,
      actions: ''
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      filterSent: false,
      getPath: this.props.ajaxPath.replace('pageSize', this.props.pageSize.toString()),
      pagesLoaded: 0,
      programsLoaded: false,
      resources: [],
      doneLoading: false,
      teachers: [],
      teachersLoaded: false,
      addModalIsOpen: false,
      studentsModalIsOpen: false,
      teachersModalIsOpen: false,
      editModalIsOpen: false,
      deleteModalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateTeachers = this.updateTeachers.bind(this);
    this.updateClassroom = this.updateClassroom.bind(this);
    this.updateStudentCount = this.updateStudentCount.bind(this);
    this.removeClassroom = this.removeClassroom.bind(this);
    this.addRow = this.addRow.bind(this);
    this.addResources = this.addResources.bind(this);
    this.resetResources = this.resetResources.bind(this);
    this.updateActiveFilters = this.updateActiveFilters.bind(this);
    this.hyperlinkStudents = this.hyperlinkStudents.bind(this);
  }

  componentDidMount() {
    this._getSubscriberStaffers();
    this._getSubscriberPrograms();
  }

  addResources(data) {
    const newResources = ClassesTable._processResources(data);

    const state = {
      resources: [...this.state.resources].concat(newResources),
      doneLoading: newResources.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1
    };

    if (this.state.filterSent) {
      state.doneLoading = false;
      state.filterSent = false;
    }

    this.setState(state);
  }

  resetResources() {
    this.setState({ resources: [], doneLoading: false, pagesLoaded: 0 });
  }

  addRow(updatedObject) {
    matchAttributesForSingleRow(updatedObject, this.props.columns);
    const updatedResources = [...this.state.resources].concat(updatedObject);

    this.setState({ resources: updatedResources });
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({
      [`${type}ModalIsOpen`]: true,
      rowData
    });
  }

  closeModal(type) {
    this.setState({
      [`${type}ModalIsOpen`]: false,
      rowData: null
    });
  }

  updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this._showFilteredResources()
    );
  }

  _showFilteredResources() {
    let getPath = this.props.ajaxPath.replace('pageSize', this.props.pageSize.toString());
    getPath = buildFilterPath(getPath, this.state.activeFilters);

    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getPath,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  updateClassroom(data) {
    const updatedResources = this.state.resources.map((classroom) => {
      if (classroom.id !== data.id) {
        return classroom;
      }
      return ClassesTable._processResource(data);
    });

    this.setState({ resources: updatedResources });
  }

  updateStudentCount(data) {
    const updatedResources = this.state.resources.map((classroom) => {
      if (classroom.id !== data.id) {
        return Object.assign({}, classroom);
      }

      const numAddedStudents = data.addedStudents.length;
      const numRemovedStudents = data.removedStudents.length;

      return {
        ...classroom,
        students: classroom.students + numAddedStudents - numRemovedStudents
      };
    });

    this.setState({ resources: updatedResources });
  }

  updateTeachers(data) {
    const updatedResources = this.state.resources.map((classroom) => {
      if (classroom.id !== data.id) {
        return Object.assign({}, classroom);
      }

      return {
        ...classroom,
        teachers: data.teachers,
      };
    });

    this.setState({ resources: updatedResources });
  }

  removeClassroom(data) {
    const updatedResources = this.state.resources.filter(classroom => classroom.id !== data.id);

    this.setState({ resources: updatedResources });
  }

  _getSubscriberStaffers() {
    if (this.state.teachers.length) return;

    Axios
      .get(this.props.subscriberStaffersPath)
      .then(response => this.setState({ teachers: response.data.data, teachersLoaded: true }))
      .catch((error) => {
        console.log(error);
        this.setState({ teachersLoaded: true });
      });
  }

  _getSubscriberPrograms() {
    Axios
      .get(this.props.subscriberProgramsPath)
      .then((response) => {
        const programs = formatProgramsForSelect(response.data.data);
        this.setState({ programs, programsLoaded: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ programsLoaded: true });
      });
  }

  _renderDelete() {
    if (this.state.deleteModalIsOpen) {
      return (
        <Delete
          closeModal={() => this.closeModal('delete')}
          destroyPath={this.props.destroyPath}
          modalIsOpen={this.state.deleteModalIsOpen}
          rowData={this.state.rowData}
          updateTable={this.removeClassroom}
        />
      );
    }

    return null;
  }

  _renderStudentModal() {
    if (this.state.studentsModalIsOpen) {
      return (
        <StudentModal
          classroomStudentsPath={this.props.classroomStudentsPath}
          closeModal={() => this.closeModal('students')}
          modalIsOpen={this.state.studentsModalIsOpen}
          searchStudentsPath={this.props.searchStudentsPath}
          rowData={this.state.rowData}
          manageStudentsPath={this.props.manageStudentsPath}
          updateStudentCount={this.updateStudentCount}
        />
      );
    }

    return null;
  }

  _renderTeachersModal() {
    if (this.state.teachersModalIsOpen) {
      return (
        <TeacherModal
          classroomStaffersPath={this.props.classroomStaffersPath}
          closeModal={() => this.closeModal('teachers')}
          modalIsOpen={this.state.teachersModalIsOpen}
          rowData={this.state.rowData}
          subscriberTeachers={this.state.teachers}
          updateTeachers={this.updateTeachers}
          subscriberStaffersPath={this.props.subscriberStaffersPath}
          sharePath={this.props.manageTeachersPath}
        />
      );
    }

    return null;
  }

  _renderEditModal() {
    if (this.state.editModalIsOpen) {
      return (
        <Edit
          closeModal={() => this.closeModal('edit')}
          updatePath={`${this.props.updatePath}/${this.state.rowData.id}`}
          modalIsOpen={this.state.editModalIsOpen}
          rowData={this.state.rowData}
          updateClassroom={this.updateClassroom}
        />
      );
    }

    return null;
  }

  _renderAdvancedFilters() {
    return (
      <div>
        <AdvancedFilters
          asyncSearchPath={this.props.asyncSearchPath}
          initialFilters={this.props.initialFilters}
          properties={this.props.properties}
          resources={this.state.resources}
          updateActiveFilters={this.updateActiveFilters}
        />
        <hr />
      </div>
    );
  }

  hyperlinkStudents(td, cellData, rowData) {
    if (cellData > 0) {
      const data = {
        initialFilters: [
          {
            propertyValue: 'complete_classrooms_full_name',
            operatorValue: 'eq',
            value: `${rowData.name}, Period ${rowData.period}`
          },
          {
            propertyValue: 'staffers_full_name_with_email',
            operatorValue: 'cont',
            value: `${rowData.teachers.split(',')[0]}`
          }
        ]
      };
      ReactDOM.render(
        <a href={`${this.props.subscriberStudentsPath}?${$.param(data)}`}>
          {cellData}
        </a>,
        td
      );
    }

    return cellData;
  }

  _renderAdd() {
    if (isAdmin(this.props.userType)) {
      return (
        <Add
          closeModal={() => this.closeModal('add')}
          createPath={this.props.createPath}
          isLoading={!(this.state.programsLoaded && this.state.teachersLoaded)}
          modalIsOpen={this.state.addModalIsOpen}
          openModal={this.openModal}
          programs={this.state.programs}
          subscriberId={this.props.subscriberId}
          teachers={this.state.teachers}
          updateTable={this.addRow}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        {this._renderAdvancedFilters()}
        {this._renderAdd()}

        <PaginatedTable
          addResources={this.addResources}
          columns={this.props.columns}
          columnDefs={[
            { targets: [0, 3], width: '20%' },
            { targets: [1, 2], width: '10%' },
            {
              targets: 2,
              title: 'Num of Students',
              createdCell: this.hyperlinkStudents,
              sortable: false
            },
            {
              targets: 5,
              width: '12%',
              sortable: false,
              searchable: false,
              visible: isAdmin(this.props.userType),
              createdCell: (td, cellData, rowData) => {
                ReactDOM.render(
                  <ClassesAdminDropdown openModal={this.openModal} rowData={rowData} />,
                  td
                );
              }
            },
            {
              createdCell: (td, cellData, rowData) => {
                ReactDOM.render(
                  <p>{rowData.uid}</p>,
                  td
                );
              },
              targets: 6,
              title: 'UID',
              visible: false
            }
          ]}
          columnMapping={['name', 'period', null, null, 'program', null, 'uid']}
          doneLoading={this.state.doneLoading}
          exportedColumns={[0, 1, 2, 3, 4, 6]}
          getUrl={this.state.getPath}
          pagesLoaded={this.state.pagesLoaded}
          resetResources={this.resetResources}
          resources={this.state.resources}
        />

        {this._renderStudentModal()}
        {this._renderTeachersModal()}
        {this._renderEditModal()}
        {this._renderDelete()}
      </div>
    );
  }
}
