import moment from 'moment';

/**
 * Validators for use in React Final Form. Returns `undefined` if valid and the
 * error message if not.
 */

export const minLength = min => (value) => {
  if (value && value.length < min) {
    return `Must be at least ${min} characters long`;
  }

  return undefined;
};

export const required = value => (value ? undefined : 'Required');

export const validEmail = (value) => {
  if (value && !value.match(/[A-Za-z0-9._'%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+/)) {
    return 'Invalid email provided';
  }

  return undefined;
};

export const validUrl = (value) => {
  if (value && !value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/)) {
    return 'Invalid URL provided';
  }

  return undefined;
};

export const validDate = (date) => {
  if (date?.value) date = date.value;
  const selectedDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss');

  if (!selectedDate.isValid()) {
    return 'Enter a valid date and time.';
  }

  return undefined;
};

export const integer = (value) => {
  const numeric = Number(value);
  if (isNaN(numeric) || parseInt(numeric, 10) !== numeric) return 'Value must be an integer';

  return undefined;
};

export const nonNegative = (value) => {
  const numeric = Number(value);
  if (numeric < 0) return 'Value cannot be negative';

  return undefined;
};

export const validConfirmation = confirmationString => (value) => {
  if (value === confirmationString) return undefined;

  return `Must enter ${confirmationString} to continue`;
};

export const fileSizeLimiter = (size, fileType) => {
  const fileSize = size * 1024 * 1024;

  return (file) => {
    if (!file || file.size < fileSize) return undefined;

    return `${fileType} must be less than ${size}MB. ${fileType} cannot be saved.`;
  };
};

export const composeValidators = (...validators) => (value, formValues) => {
  return validators.reduce((error, validator) => error || validator(value, formValues), undefined);
};
