import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import Form from './Form';

export default class LeftPaneAction extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool
  };

  static defaultProps = {
    modalIsOpen: false
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <div>
        <a onClick={this.openModal.bind(this)} href='#'>
          <i className="fa fa-pencil-square-o fa-fw" /> Edit {this.props.name}
        </a>
        <Modal
          closeModal={this.closeModal.bind(this)}
          isOpen={this.state.modalIsOpen}
          headerText={`Edit ${this.state.name}`}>
          <Form closeModal={this.closeModal.bind(this)}
                handleUpdate={this.handleUpdate.bind(this)}
                {...this.state} />
        </Modal>
      </div>
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleUpdate(challenge) {
    this.setState(challenge);
  }
}
