import I18n from 'modules/i18n';
import { useLayoutEffect } from 'react';
import { SlideGroup } from '../Builder/CreateTransistionGroup/utils';
import { ISlide, ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import { SLIDE_SETTINGS_STORAGE_KEY } from './Constants';
import { ITciVideoButton } from '@/components/interfaces/graphql/HtmlElement/VideoButton';

type Slide = {
  id: number;
  position: number;
  slideGroupId: number;
};

export type SlideShow = {
  activityType: 'video_activity' | 'classroom_activity' | 'investigation' | 'professional_development' | 'lesson_outline';
  slides: Slide[];
  slideGroups: SlideGroup[];
  titleEn: string;
  titleEs?: string;
};

/**
 * Iterates through each slide and injects a transition slide before the first slide with a corresponding slideGroupId
 * Re-indexes the slide positions to ensure each slide and slide group is sequential
 * Filters the set of slides that are shown if a slideIds array is passed.
 * @param {SlideShow} slideShow - The slide_show with its slides, slide_groups, and title
 * @param {number[]} slideIds - An array of slide_ids to filter
 * @param {boolean} addTransitionSlides - Allows showing/hiding the transition slides
 * @returns {Slide[]} - All slides including transition slides
 */
export const formatPresentSlides = (slideShow: SlideShow, slideIds: number[], addTransitionSlides = true) => {
  const { activityType, slides, slideGroups, titleEn, titleEs } = slideShow;
  const filteredSlides = slideIds.length > 0 ? slides.filter(slide => slideIds.includes(slide.id)) : slides;
  const slideGroupsSet = slideGroups.reduce((groups: {}, sg: SlideGroup) => ({ ...groups, [sg.id]: sg }), {});
  const allSlides = [];

  filteredSlides.forEach((slide: Slide) => {
    const addTransitionSlide = slide.slideGroupId && Object.keys(slideGroupsSet).includes(slide.slideGroupId.toString());

    if (addTransitionSlides && addTransitionSlide) {
      const transitionSlide = {
        ...slideGroupsSet[slide.slideGroupId],
        position: allSlides.length + 1,
        type: 'Transition',
      };

      allSlides.push(transitionSlide);

      delete slideGroupsSet[slide.slideGroupId];
    }

    allSlides.push({ ...slide, position: allSlides.length + 1 });
  });

  if (allSlides.some(slide => slide.type === 'Transition')
    || activityType === 'video_activity'
    || activityType === 'professional_development'
    || activityType === 'lesson_outline') {
    const finalSlide = {
      id: '0',
      position: allSlides.length + 1,
      titleEn,
      titleEs,
      type: 'Transition',
    };

    return [...allSlides, finalSlide];
  }

  return allSlides;
};

export const useSlugParser = (currentSlide) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      const srcNodes = document.querySelectorAll('[src]');
      const hrefNodes = document.querySelectorAll('[href]');
      const nodeLists = [{ attr: 'src', list: srcNodes }, { attr: 'href', list: hrefNodes }];
      const slugsDiv = document.querySelector('#slug-attributes');
      const slugs = {
        lesson_id: slugsDiv.getAttribute('data-lesson-id'),
        program_id: slugsDiv.getAttribute('data-program-id'),
        unit_id: slugsDiv.getAttribute('data-unit-id'),
        classroom_id: slugsDiv.getAttribute('data-classroom-id'),
      };

      nodeLists.forEach((nodeList) => {
        nodeList.list.forEach((node) => {
          for (const key in slugs) {
            const nodeAttr = node.getAttribute(nodeList.attr);
            const val = slugs[key];
            const slugKeys = [`%3A${key}`, `:${key}`];

            slugKeys.forEach((slugKey) => {
              if (nodeAttr.includes(slugKey)) {
                const newAttr = nodeAttr.replaceAll(slugKey, val);
                node.setAttribute(nodeList.attr, newAttr);
              }

              if (node.textContent && node.textContent.includes(slugKey)) {
                node.textContent = node.textContent.replaceAll(slugKey, val);
              }
            });
          }
        });
      });
    }, 50);
  }, [currentSlide && currentSlide.id]);
};

export const playableBackgroundAudioUrl = (url) => {
  if (!url || url === '/missing.png') return;

  return url;
};

export const slideBackgroundAudioUrl = (slide) => {
  if (!slide) return;

  const enUrl = playableBackgroundAudioUrl(slide.backgroundAudioUrl);
  const esUrl = playableBackgroundAudioUrl(slide.backgroundAudioEsUrl);

  return I18n.locale === 'en' ? enUrl : esUrl || enUrl;
};

export const autoplayMediaSetting = (slideShowId, autoplayMediaSettings = null) => {
  if (!slideShowId) return false;

  const storageSettings = JSON.parse(localStorage.getItem(SLIDE_SETTINGS_STORAGE_KEY)) || {};
  const settings = autoplayMediaSettings || storageSettings.autoplayMedia || {};

  return settings[slideShowId];
}

const slidesHaveAutoplayVideos = (slides: ISlide[]) => {
  const modelVideos = slides.flatMap((slide: ISlide) => (slide
    .slideObjects
    .flatMap((slideObject) => {
      const { modelVideo, htmlElements } = slideObject;
      const tciVideoButtons = htmlElements
        .filter(el => el.type === 'VideoButton' && el.modelVideo) as ITciVideoButton[];

      const htmlModelVideos = tciVideoButtons.map(el => el.modelVideo);

      return [modelVideo].concat(htmlModelVideos);
    })));

  return !!modelVideos.find(modelVideo => modelVideo?.autoplay);
};

const slidesHaveAutoplayAudio = (slides: ISlide[]) => {
  return !!slides.find((slide: ISlide) => slideBackgroundAudioUrl(slide));
};

export const slidesHaveAutoplayAudioOrVideo = (slides: ISlideOrSlideGroup[]) => {
  const iSlides = slides.filter((slide: ISlideOrSlideGroup) => slide.__typename === 'Slide');

  return slidesHaveAutoplayVideos(iSlides as ISlide[]) ||
    slidesHaveAutoplayAudio(iSlides as ISlide[]);
};

export const uniqueUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    url.searchParams.append('timeNow', new Date().getTime().toString());

    return url.toString();
  } catch {
    return urlString;
  }
}
