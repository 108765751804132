import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import { Footer } from '../../../../common/Modal';
import styles from './Form.module.scss';
import SaveLocation from './SaveLocation';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isLoading: true });

    const urlId = this.props.type === 'slide_show' ? this.props.id : this.props.slideShowId;
    let url = `slide_shows/${urlId}/clone${this.props.useNewBuilder ? '_new_slide_show' : ''}.json`;

    if (this.props.fromSlideShow) {
      url = Routes.clone_new_slide_show_shared_program_lesson_slide_show_path(urlId, { lesson_id: this.props.lessonId, program_id: this.props.programId });
    }

    const data = {
      button: this.props.button,
      from: 'index',
      module_id: this.props.type === 'lesson_module' ? this.props.id : null,
      slide: { title: this.state.title }
    };

    Axios.put(url, data).then((response) => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        if (response.data.redirect_to) {
          window.location.replace(response.data.redirect_to);
        }
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <div className={styles.row}>
          <label className={styles.label}>
            <span className={styles.labelText}>Title </span>
            <input
              type="text"
              value={this.state.title}
              onChange={this.handleChange}
              className={styles.textInput}
            />
          </label>
        </div>

        {!this.props.hideTabs && <SaveLocation />}
        <Footer />
      </form>
    );
  }
}

Form.propTypes = {
  button: PropTypes.string,
  error: PropTypes.bool,
  fromSlideShow: PropTypes.bool,
  hideTabs: PropTypes.bool,
  id: PropTypes.number.isRequired,
  lessonId: PropTypes.string,
  programId: PropTypes.string,
  slideShowId: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  useNewBuilder: PropTypes.bool,
};

Form.defaultProps = {
  button: '',
  error: false,
  fromSlideShow: false,
  hideTabs: false,
  isLoading: false,
  lessonId: '',
  programId: '',
  slideShowId: null,
  useNewBuilder: false,
};
