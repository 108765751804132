import React, { Component } from 'react';
import PropTypes from "prop-types";
import Modal from 'common/Modal';
import Form from './Form';
import styles from './LessonNumber.module.scss'

export default class LessonNumber extends Component {
  static propTypes = {
    modalIsOpen: PropTypes.bool,
    ajaxPath: PropTypes.string.isRequired,
    display_type: PropTypes.string,
    icons: PropTypes.array
  };

  static defaultProps = {
    modalIsOpen: false,
    display_type: 'number'
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  _openModal() {
    this.setState({modalIsOpen: true});
  }

  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  _editButton() {
    return(
      <button className={`${styles['edit-lesson-number']} btn btn--link-purple`} onClick={this._openModal.bind(this)}>
        <i className='fa fa-edit'/>
      </button>
    );
  }

  render() {
    return(
      <div>
        { this._editButton() }
        <Modal className={styles.modal}
               closeModal={this._closeModal.bind(this)}
               isOpen={this.state.modalIsOpen}
               headerText='Lesson Number Display'>
          <Form ajaxPath={this.state.ajaxPath}
                display_type={this.state.display_type}
                icons={this.state.icons}
                params={this.state.params} />
        </Modal>
      </div>
    );
  }
}
