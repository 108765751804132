import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import Select from 'react-select';
import styles from '../index.module.scss';

const LessonNotebookSelectsWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <LessonNotebookSelects {...props} />
  </ApolloProvider>
);

const GET_INITIAL_DROPDOWN_OPTIONS = gql`
  query getInitialDropdownOptions($lessonId: ID, $unitId: ID, $isLesson: Boolean!) {
    lesson(id: $lessonId) @include(if: $isLesson) {
      id,
      lessonNotebooks {
        id,
        title,
      }
    }
    unit(id: $unitId) @skip(if: $isLesson) {
      lessons {
        lessonNotebooks {
          id,
          title
        }
      }
    }
  }
`;

// pass that into notebook selects
const LessonNotebookSelects = (props) => {
  // Initialize initial notebook
  const [notebookOption, setNotebookOption] = useState({ id: 0, title: '' });

  const queryData = {
    fetchPolicy: 'network-only',
    variables: { isLesson: !!props.lessonId, lessonId: props.lessonId, unitId: props.unitId }
  };
  const { loading, data, error } = useQuery(GET_INITIAL_DROPDOWN_OPTIONS, queryData);

  useEffect(() => {
    if (error) {
      props.setHasError(true);
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }

    if (loading) return;

    // When data is loaded, use first notebook label/value for dropdown option
    const dataPrefix = props.lessonId ? data.lesson : data.unit.lessons[0];

    // If notebookId is defined, filter through data for matching notebook.
    let initialNotebook;
    if (props.notebookId) {
      initialNotebook = dataPrefix.lessonNotebooks.filter(notebook => notebook.id === props.notebookId.toString());
    }

    // If matching notebook is found, use it as initial dropdown option.
    if (Array.isArray(initialNotebook) && initialNotebook.length) {
      setNotebookOption({
        label: initialNotebook[0].title,
        value: props.notebookId,
      });
    }
    else if (dataPrefix && dataPrefix.lessonNotebooks[0]) {
      setNotebookOption({
        label: dataPrefix.lessonNotebooks[0].title,
        value: dataPrefix.lessonNotebooks[0].id,
      });

      // Pass notebookId back to QuestionPicker to render questions.
      props.setNotebookId(parseInt(dataPrefix.lessonNotebooks[0].id, 10));
    }
  }, [data]);

  const handleNotebookChange = (e) => {
    // Set component state to chosen dropdown option
    setNotebookOption(e.value);

    // Pass notebookId back to QuestionPicker to render questions.
    props.setNotebookId(parseInt(e.value, 10));
  };

  const getOptions = () => {
    if (props.lessonId) {
      return (
        data.lesson.lessonNotebooks.map(notebook => ({
          label: notebook.title,
          value: notebook.id,
        }))
      );
    }

    return (
      data.unit.lessons.flatMap(
        lesson => lesson.lessonNotebooks.map(
          notebook => ({ label: notebook.title, value: notebook.id })
        )
      )
    );
  };

  return (
    <>
      {!loading && (
        <div className={`${styles.modelSelect}`}>
          <label htmlFor="notebook-select">
            <p>Notebooks:</p>
          </label>
          <Select
            className={`${styles.select} model-select`}
            id="notebook-select"
            label="notebook-select"
            onChange={handleNotebookChange}
            options={getOptions()}
            value={notebookOption}
          />
        </div>
      )}
    </>
  );
};

LessonNotebookSelects.propTypes = {
  lessonId: PropTypes.number.isRequired,
  notebookId: PropTypes.number,
  setHasError: PropTypes.func.isRequired,
  setNotebookId: PropTypes.func.isRequired,
  unitId: PropTypes.number,
};

export default LessonNotebookSelectsWrapper;
