define([], function() {
  function InteractiveHotSpot(parent_type, obj) {
    if (!$(obj).attr('id')) return;

    var parent = parent_type,
      id = '#' + $(obj).attr('id');

    $('.hs_info').hide();

    $(id + ' .hs_trigger').off('click');
    $(id + ' .hs_trigger').on('click', function () {
      var class_arr = $(this).attr('class').split(' '),
        slide_id = '#' + $(this).closest('.' + parent).attr('id') + ' ',
        hs_regex = new RegExp('hs_id_', 'g'),
        hs_id;

      if (slide_id == '#undefined ') {
        slide_id = '#' + $(this).closest('#section_text_outer_container').attr('id') + ' ';
      }

      if (slide_id == '#undefined ') {
        slide_id = '#' + $(this).closest('.hs_ref').attr('id') + ' ';
      }

      for (i = 0; i < class_arr.length; i++) {
        if (class_arr[i].match(hs_regex)) {
          hs_id = "." + class_arr[i];
          break;
        }
      }

      if ($(slide_id + hs_id + ".hs_info").is(':visible')) {
        $(slide_id + '.hs_info:not(.hs_stay_open)').stop(true, true).fadeOut(100);
        $(slide_id + hs_id + ".hs_info").stop(true, true).fadeOut(100);
      }
      else {
        $(slide_id + '.hs_info:not(.hs_stay_open)').stop(true, true).fadeOut(100);
        $(slide_id + '.hs_info' + hs_id).stop(true, true).fadeIn(100);

        // resize iframe to show scroll on chrome after changes in visibility
        let $iframes = $('iframe');
        if ($iframes.length) {
          $iframes.each((i, obj) => {
            let iFrameBody = $(obj).contents().find("body");
            let originalOverflow = $(iFrameBody).css("overflow");
            if (originalOverflow === "visible" || originalOverflow === "auto") {
              $(iFrameBody).css("overflow", "scroll");
            } else {
              $(iFrameBody).css("overflow", "auto");
            }
          });
        }
      }

      return false;
    });
  }

  return {
    init: function(kind) {
      kind = kind || 'slide';
      $('.' + kind).each(function() {
        InteractiveHotSpot(kind, this);
      });
    }
  }
});
