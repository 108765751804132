import React from 'react';

interface DottedLineProps {
  className?: string;
  color: string;
}

const DottedLine = ({ className, color }: DottedLineProps) => (
  <div
    className={`${className} tw-h-1 tw-bg-repeat-x`}
    style={{
      background: `radial-gradient(circle, #${color} 2px, transparent 2px)`,
      backgroundSize: '10px 4px',
    }}
  />
);

export default DottedLine;
