import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../common/Tooltip';
import styles from './TableNotification.module.scss';

const TableNotification = props => (
  <Tooltip content={props.tooltip}>
    <div className={`${props.iconOnly ? '' : styles.tableTooltip} fa fa-info-circle`}>
      <span className="text">
        {props.text}
      </span>
    </div>
  </Tooltip>
);

TableNotification.propTypes = {
  iconOnly: PropTypes.bool,
  text: PropTypes.string,
  tooltip: PropTypes.string.isRequired
};

TableNotification.defaultProps = {
  iconOnly: false,
  text: ''
};

export default TableNotification;
