import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IQuestionAnswer, IReferenceMaterial, IClassroom } from '../../../types';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { plato_api_reference_material_progress_question_answers_path } from '@/modules/routes';

const useReferenceMaterialQuestionAnswers = (referenceMaterial: IReferenceMaterial) => {
  const classroom = useGradesTableStore(state => state.classroom);
  const isEnabled = !!(classroom?.id && referenceMaterial?.id);

  const query = useQuery([QueryKeys.ReferenceMaterialQuestionAnswers, classroom.id, referenceMaterial.id], () => {
    return axios
      .get<{ data: IQuestionAnswer[] }>(plato_api_reference_material_progress_question_answers_path({
        classroom_id: classroom.id,
        reference_material_id: referenceMaterial.id,
      }))
      .then(res => res.data?.data || [])
      .catch(err => console.error(err));
  }, { enabled: isEnabled, placeholderData: [] });

  return { ...query, data: query.data || [] };
};

export default useReferenceMaterialQuestionAnswers;
