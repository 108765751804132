import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../Navigator.module.scss';
import PositionInput from './PositionInput';
import useKeyboardNavigation from '../../../../Builder/useKeyboardNavigation.ts';
import useSlideShowContext from '../../../../stores/useSlideShowContext';
import NavigationButton from './NavigationButton';

const Navigation = ({
  dropdownItemText,
  onBack,
  onDirectNav,
  onForward,
  numElements,
  position,
}) => {
  const formattedSlides = useSlideShowContext(state => state.slides);
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slideObjects = (currentSlide || {}).slideObjects || [];
  const has360Images = slideObjects.some(slideObject => {
    const { modelType, panoramicImages } = slideObject;

    return modelType === 'PanoramicImage' || (panoramicImages || []).length > 0
  });

  useKeyboardNavigation({
    backKeys: (has360Images ? [] : ['ArrowLeft']).concat(['Backspace', 'PageUp']),
    forwardKeys: (has360Images ? [] : ['ArrowRight']).concat(['Enter', ' ', 'PageDown']),
    numElements,
    onBack,
    onForward,
    position,
  });

  return (
    <div className={styles.navigation}>
      <NavigationButton
        disabled={position === 1 ? true : null}
        onClick={onBack}
        label="Previous Slide"
        faIcon="fa-chevron-left"
        slide={formattedSlides[position - 2]}
      />

      <PositionInput
        dropdownItemText={dropdownItemText}
        numElements={numElements}
        onDirectNav={onDirectNav}
        position={position}
      />

      <NavigationButton
        disabled={position === numElements ? true : null}
        onClick={onForward}
        label="Next Slide"
        faIcon="fa-chevron-right"
        slide={formattedSlides[position]}
      />
    </div>
  );
};

Navigation.defaultProps = {
  dropdownItemText: 'Item',
};

export const NavigationPropTypes = PropTypes.shape({
  dropdownItemText: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onDirectNav: PropTypes.func.isRequired,
  onForward: PropTypes.func.isRequired,
  numElements: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
});

Navigation.propTypes = NavigationPropTypes.isRequired;

export default Navigation;
