import React from 'react';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import useSlideConditions from '@/components/admin/SlideShow/PresentMode/useSlideConditions';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import IJumpToButton from '@/components/interfaces/graphql/HtmlElement/JumpToButton';

interface JumpToButtonProps {
  jumpToButton: IJumpToButton;
}

const JumpToButton = ({
  jumpToButton: {
    data: {
      backgroundColor = 'purple',
      buttonTextEs,
      buttonTextEn,
      buttonMaxWidth,
      destinationSlideId,
      displayAsLink = false,
    },
  },
}: JumpToButtonProps) => {
  const { isSlideLocked } = useSlideConditions();
  const formattedSlides = useSlideShowContext(state => state.slides);
  const jumpToSlide = useSlideShowContext(state => state.jumpToSlide);

  const slide = formattedSlides.find(s => s.id === destinationSlideId.toString());

  const onClick = () => {
    if (!slide) {
      // eslint-disable-next-line no-console
      console.error(`Could not find slide ${destinationSlideId}`);
      return;
    }

    jumpToSlide(slide.position - 1);
  };

  return (
    <button
      className={displayAsLink ? '' : 'btn'}
      data-background-color={backgroundColor}
      data-html-element-type="JumpToButton"
      data-button-text-en={buttonTextEn}
      data-button-text-es={buttonTextEs || buttonTextEn}
      data-display-as-link={displayAsLink}
      style={{ maxWidth: buttonMaxWidth }}
      type="button"
      disabled={isSlideLocked(slide)}
      {...useKeydownAndClick(onClick)}
    >
      {isSlideLocked(slide) && <span aria-hidden="true" className="fa fa-lock tw-mr-2" />}
    </button>
  );
};

export default JumpToButton;
