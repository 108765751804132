import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { pollEndpoint } from '../../../../modules/TCIUtils';
import styles from './Form.module.scss';
import { Footer } from '../../../common/Modal';

export default class Form extends Component {
  static propTypes = {
    ajaxPath: PropTypes.string.isRequired,
    initialName: PropTypes.string.isRequired,
    statusPath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { name: this.props.initialName };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ name: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      name: this.state.name
    };

    return axios
      .post(this.props.ajaxPath, data)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else if (response.data.redirect_to) {
          return this.pollForCompletion(response.data);
        }

        return null;
      })
      .catch(error => console.log(error));
  }

  pollForCompletion(data) {
    const { redirect_to: redirectPath, new_assessment_id: assessmentId } = data;
    const path = this.props.statusPath.replace(':assessment_id', assessmentId);

    return pollEndpoint({
      endpointUrl: path,
      interval: 1000,
      until: response => !response.data[Object.keys(response.data)[0]].copying
    })
      .then(() => window.location.replace(redirectPath))
      .catch(() => window.location.replace(redirectPath));
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <div className={styles.row}>
          <label className={styles.label}>
            <span className={styles.labelText}>Name </span>
            <input
              type="text"
              required
              value={this.state.name}
              onChange={this.handleChange}
              className={styles.textInput}
            />
          </label>
        </div>
        <Footer primaryButtonClassName="btn btn--purple js-copy-spinner" />
      </form>
    );
  }
}
