import React, { useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from 'usehooks-ts';
import cn from '@/utils/cn';
import useMutationObserver from '@/hooks/useMutationObserver';

type Position = 'bottom-left' | 'top-right';
type Offset = Partial<{
  top: number;
  right: number;
  bottom: number;
  left: number;
}>;

const DEFAULT_OFFSET: Offset = { top: 0, right: 0, bottom: 0, left: 0 };

const getCoordinates = (
  anchor: HTMLElement,
  parent: HTMLElement,
  popover: HTMLElement,
  position: Position,
  offset: Offset,
) => {
  const coordinates = anchor.getBoundingClientRect();
  const parentCoordinates = parent.getBoundingClientRect();
  const popoverCoordinates = popover.getBoundingClientRect();
  let left: number;
  let top: number;

  if (position === 'bottom-left') {
    /* eslint-disable-next-line prefer-destructuring */
    left = coordinates.left;
    top = coordinates.bottom - parentCoordinates.top;
  } else if (position === 'top-right') {
    left = coordinates.right + offset.left;
    top = coordinates.top - parentCoordinates.top - offset.top;
  } else {
    // top down, centered to width of anchor
    left = coordinates.left - parentCoordinates.left + (coordinates.width / 2) - (popoverCoordinates.width / 2);
    top = coordinates.top - parentCoordinates.top - offset.top;
  }

  return { left, top };
};

interface AnchorProps {
  attachListenersTo?: HTMLElement[] | Element[];
  attachTogglesTo?: HTMLElement[] | Element[];
  children: HTMLCollection | React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  offset?: Offset;
  onClose?: () => void;
  position?: Position;
  ref?: any;
  to?: HTMLElement;
  toggleOnTargetClick?: boolean;
  within?: HTMLElement;
}

export type AnchorRef = {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
};

const Anchor = React.forwardRef<AnchorRef, AnchorProps>(({
  attachListenersTo = [],
  attachTogglesTo = [],
  children,
  className = '',
  defaultOpen = true,
  position,
  offset = {},
  onClose,
  to,
  toggleOnTargetClick = true,
  within = document.body,
}, refProp) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const ref = useRef<HTMLDivElement>();

  const setCoordinates = () => {
    if (!ref.current) return;

    setTimeout(() => {
      const { left, top } = getCoordinates(
        to,
        within,
        ref.current,
        position,
        { ...DEFAULT_OFFSET, ...offset },
      );

      ref.current.style.top = `${top.toString()}px`;
      ref.current.style.left = `${left.toString()}px`;
    }, 0);
  };

  const toggle = () => {
    setCoordinates();
    setIsOpen((prev) => {
      const next = !prev;

      if (next === false && onClose) onClose();

      return next;
    });
  };

  const open = () => {
    if (isOpen) return;

    setCoordinates();
    setIsOpen(true);
  };

  const close = () => {
    if (!isOpen) return;

    setIsOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (!isOpen) return;

    setCoordinates();
  }, [isOpen]);

  useImperativeHandle(refProp, () => ({
    isOpen,
    close,
    open,
    toggle,
  }), []);

  useOnClickOutside(ref, (e) => {
    if (to?.contains(e.target as HTMLElement)) return;

    close();
  }, 'mousedown', { capture: true });

  useMutationObserver({
    callback: (mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type !== 'attributes') return;
        if (!['class', 'style'].includes(mutation.attributeName)) return;

        setCoordinates();
      });
    },
    node: to,
    observerOpts: { attributes: true, attributeFilter: ['class', 'style'] },
  });

  useMutationObserver({
    callback: (mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type !== 'childList') return;

        mutation.removedNodes.forEach((node) => {
          if (node === to) close();
        });
      });
    },
    node: to?.parentElement,
    observerOpts: { childList: true },
  });

  useLayoutEffect(() => {
    if (!to) return () => { };

    setCoordinates();

    const listenerElements = attachListenersTo.filter(el => !!el);

    [...listenerElements, document.body, window].forEach((el) => {
      el.addEventListener('scroll', setCoordinates);
      el.addEventListener('resize', setCoordinates);
    });

    if (toggleOnTargetClick) to.addEventListener('click', toggle);
    attachTogglesTo.forEach(el => el.addEventListener('click', toggle));

    return () => {
      to.removeEventListener('click', toggle);
      listenerElements.forEach(el => el.removeEventListener('scroll', setCoordinates));
      attachTogglesTo.forEach(el => el.removeEventListener('click', toggle));
    };
  }, [to]);

  if (!to) return null;

  return (
    ReactDOM.createPortal(
      <div
        className={cn(
          'tw-absolute tw-z-10 tw-block anchor',
          { 'tw-block': isOpen, '!tw-hidden tw-pointer-events-none': !isOpen },
          className,
        )
        }
        ref={ref}
      >
        {children}
      </div>,
      within,
    )
  );
});

Anchor.displayName = 'Anchor';

export default Anchor;
