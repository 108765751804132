import React, { useEffect, useState } from 'react';
import axios from 'axios';
import I18n from 'i18n-js';
import { QueryClientProvider, useMutation } from '@tanstack/react-query';
import { useForm, FormProvider, useFormContext, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  dbSchema,
  schema,
  queryClient,
  QueryKeys,
  SchemaType,
  useVocabDecksStore,
} from '@/components/common/VocabularyCards/variables';
import useDeck from '@/components/common/VocabularyCards/hooks/useDeck';
import { Input, SaveStatus } from '@/components/ui/Form';
import VocabTerms from '@/components/common/VocabularyCards/Forms/VocabTerms';
import * as Routes from '@/modules/routes';
import useAutoSave from '@/components/common/VocabularyCards/hooks/useAutoSave';
import { IDeck } from '@/components/common/VocabularyCards/interfaces';
import LocaleToggle from '@/components/common/LocaleToggle';
import useDragAndDrop from '@/components/common/VocabularyCards/hooks/useDragAndDrop';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

interface VocabDeckFormProps {
  deckId: number;
  lessonId: number;
  programId: number;
}

type Locale = 'en' | 'es';

const VocabDeckForm = ({ deckId, programId, lessonId }: VocabDeckFormProps) => {
  const { data: deck, isFetched } = useDeck(deckId);
  const methods = useFormContext<SchemaType>();
  const [locale, setLocale] = useState<Locale>(I18n.currentLocale() as Locale);

  const fieldArray = useFieldArray<SchemaType>({
    control: methods.control,
    name: 'deck.glossary_term_models',
  });

  const handleDragEnd = (result: DropResult) => {
    // move the item to its new position
    const item = methods.getValues(`deck.glossary_term_models.${result.source.index}`);
    fieldArray.remove(result.source.index);
    fieldArray.insert(result.destination.index, item);

    // update the positions of all elements
    const glossaryTermModels = methods.getValues('deck.glossary_term_models');

    glossaryTermModels.forEach((glossaryTermModel, index) => {
      methods.setValue(`deck.glossary_term_models.${index}.position`, index + 1);
    });
  };

  const { placeholderProps, onDragUpdate, onDragEnd } = useDragAndDrop({ onDragEnd: handleDragEnd });

  const setStore = useVocabDecksStore(state => state.setStore);

  const { isLoading: isSaving, mutate } = useMutation({
    mutationFn: (formData: SchemaType) => axios.put<{ data: IDeck }>(Routes.plato_api_deck_path(deckId), formData),
    onSuccess: res => queryClient.setQueryData([QueryKeys.DECK, deckId], res.data.data),
  });

  useAutoSave({
    saveFn: () => {
      const formData = methods.getValues();

      mutate({ deck: dbSchema.parse(formData) });
    },
  });

  useEffect(() => setStore({ deckId, lessonId, programId }), [deckId, lessonId, programId]);

  useEffect(() => {
    if (!isFetched) return;

    methods.reset(schema.parse({ deck }));
  }, [isFetched, locale]);

  const updatedAt = () => {
    if (!isFetched) return '';

    const dates = [
      new Date(deck.updated_at).getTime(),
      ...deck.glossary_term_models.map(gtm => new Date(gtm.glossary_term.updated_at).getTime()),
    ];

    return new Date(Math.max(...dates)).toISOString();
  };

  return (
    <form className="tw-mt-10">
      <Input
        label={I18n.t('title')}
        {...methods.register(`deck.title_${locale}`)}
      />
      <div className="tw-flex tw-flex-col tw-items-end tw-gap-2">
        <div className="tw-flex tw-gap-2">
          <LocaleToggle
            btnClasses="tw-p-0"
            isSaving={isSaving}
            locale={locale}
            setLocale={setLocale}
          />
          <a
            href={Routes.shared_program_lesson_glossary_cards_path(programId, lessonId, { deck_id: deck.id })}
            className="btn btn--outline-purple"
          >
            {I18n.t('view')} {I18n.t('cards')}
          </a>
          <a className="btn btn--purple" href={Routes.shared_program_lesson_decks_path(programId, lessonId)}>
            {I18n.t('done')}
          </a>
        </div>
        <SaveStatus dbTimestamp={updatedAt()} isSaving={isSaving} />
      </div>
      <hr className="tw-mt-1" />
      <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <VocabTerms fieldArray={fieldArray} placeholderProps={placeholderProps} locale={locale} />
      </DragDropContext>
    </form>
  );
};
const VocabDeckFormWrapper = (props: VocabDeckFormProps) => {
  const methods = useForm<SchemaType>({ resolver: zodResolver(schema) });

  return (
    <FormProvider {...methods}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <VocabDeckForm {...props} />
      </QueryClientProvider>
    </FormProvider>
  );
};

export default VocabDeckFormWrapper;
