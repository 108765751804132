import FroalaConfig from 'modules/froala/FroalaConfig';
import FE from 'froala-editor';
import { formatToolbarButtons, TextEditorToolbarId } from './Toolbar';

const config = questionId => FroalaConfig({
  allowHTML: true,
  customConfig: {
    charCounterCount: false,
    enter: FE.ENTER_BR,
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
    height: '100%',
    initOnClick: false,
    quickInsertTags: [],
    toolbarButtons: formatToolbarButtons(),
    toolbarContainer: `#${TextEditorToolbarId}-${questionId}`,
    width: '100%',
  },
  events: {},
});

export default config;
