import React from 'react';
import ReactDOM from 'react-dom';
import Checkbox from 'common/Checkbox';

export function getCurrentPageIds(tableRef) {
  if (tableRef) {
    const rows = tableRef.getRows({ page: 'current' });
    if (rows) return Array.from(rows).map(row => row.id);
  }

  return [];
}

export function isCurrentPageAllChecked(set, tableRef) {
  if (set.size === 0) return 'none';

  const currentPageIds = getCurrentPageIds(tableRef);
  return currentPageIds.every(id => set.has(id)) ? 'all' : 'some';
}

export function renderBulkCheckbox(resources, bulkChecked, handleBulkCheckboxChange, labelText) {
  if (resources.length && document.getElementById('select-all')) {
    return ReactDOM.createPortal(
      <Checkbox
        checked={bulkChecked === 'all'}
        indeterminate={bulkChecked === 'some'}
        handleChange={handleBulkCheckboxChange}
        labelText={labelText}
        name="select-all"
        srOnly
      />,
      document.getElementById('select-all')
    );
  }

  return null;
}
