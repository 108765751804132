import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

// This is a plugin to be able to set data on the video player,
// for use in managing play/pause functionality with audio descriptions.
class AudioDescriptionsPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.shouldBypassAudioPause = false;
    this.isGloballyPaused = false;
  }

  getShouldBypassAudioPause() {
    return this.shouldBypassAudioPause;
  }

  setShouldBypassAudioPause(val) {
    this.shouldBypassAudioPause = val;
  }

  getIsGloballyPaused() {
    return this.isGloballyPaused;
  }

  setIsGloballyPaused(val) {
    this.isGloballyPaused = val;
  }
}

export default AudioDescriptionsPlugin;
