import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ElementInsert.module.scss';

// Content editable span wrapped in another span to allow deleting button and tooltip in one action.
const TooltipButton = ({ hintText, button, buttonText }) => {
  let inlineStyles = {};
  let className = `slide-popup hint-text ${styles.slidePopup}`;

  if (button && button.dataset.buttonType === 'tooltip') {
    const { lastChild } = button;
    className = lastChild.className;

    if (lastChild.style.left ||
      lastChild.style.top ||
      lastChild.style.right ||
      lastChild.style.bottom
    ) {
      className = `${lastChild.className} ${styles.inlineStyles}`;
    }

    inlineStyles = {
      left: lastChild.style.left,
      top: lastChild.style.top,
      right: lastChild.style.right,
      bottom: lastChild.style.bottom,
    };
  }

  return (
    <span>
      <span contentEditable={false} data-button-type="tooltip">
        <span
          className="btn btn--purple tooltip-btn"
          data-button-type="tooltip"
          role="button"
        >
          {buttonText}
        </span>
        <span className={className} style={inlineStyles}>
          <span dangerouslySetInnerHTML={{ __html: hintText }} />
          <i
            className={`fa fa-times-circle popup-close-btn ${styles.slidePopupCloseBtn}`}
            role="button"
            tabIndex={0}
          />
        </span>
      </span>
    </span>
  );
};

TooltipButton.propTypes = {
  button: PropTypes.object,
  buttonText: PropTypes.string,
  hintText: PropTypes.string,
};

TooltipButton.defaultProps = {
  button: undefined,
  buttonText: '',
  hintText: '',
};

export default TooltipButton;
