import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import ModelSelect from './index';

const SectionSelect = ({ ...props }) => (
  <ModelSelect {...props} selectType="section" title={`${I18n.t('section')}:`} />
);

SectionSelect.propTypes = {
  handleSelect: PropTypes.func,
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }),
  lessonId: PropTypes.number.isRequired,
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  programId: PropTypes.number.isRequired,
  resourceUrl: PropTypes.string
};

SectionSelect.defaultProps = {
  handleSelect: null,
  initialOption: null,
  resourceUrl: ''
};

export default SectionSelect;
