import React from 'react';
import i18n from '@/modules/i18n';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import axios from 'axios';
import { post_scores_shared_program_lesson_assessment_scores_path } from '@/modules/routes';
import { useMutation } from '@tanstack/react-query';
import QueryKeys from '@/utils/QueryKeys';
import MutationKeys from '@/utils/MutationKeys';
import GradesTableQueryClientProvider from '@/components/staffer/Scores/GradesTable/GradesTableQueryClientProvider';
import cn from '@/utils/cn';

interface PostScoresButtonProps {
  assessmentId: number;
  classroomId: number;
  displayIcon?: boolean;
  lessonId: number;
  programId: number;
  text?: string;
  variant?: 'dropdown' | 'button';
}

export const PostScoresButton = ({
  assessmentId,
  classroomId,
  displayIcon = false,
  lessonId,
  programId,
  text = 'Post Scores',
  variant = 'dropdown',
}: PostScoresButtonProps) => {
  const { close, isOpen, open } = useModalManager();

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: () => {
      const route = post_scores_shared_program_lesson_assessment_scores_path(
        programId,
        lessonId, {
          classroom_id: classroomId,
          assessment_id: assessmentId,
          format: 'json',
        },
      );

      return axios.post(route);
    },
    mutationKey: [QueryKeys.Assessments, MutationKeys.Update, assessmentId],
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await mutateAsync();
    window.location.reload();
  };

  return (
    <>
      <button
        className={cn({
          'dropdown-item tw-min-w-full tw-px-6 tw-cursor-pointer': variant === 'dropdown',
          'btn btn--purple': variant === 'button',
        })}
        onClick={open}
        type="button"
      >
        {displayIcon && <i className="fa fa-thumb-tack" />}
        {text}
      </button>
      <SubmitModal
        close={close}
        headerText={i18n.t('post_assessment_scores')}
        isOpen={isOpen}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        submittingText="Posting Scores..."
        submitText="Post Scores"
      >
        <p className="tw-mb-3.5 tw-text-base">
          You are about to post scores for all students who turned in work for this assessment.
        </p>
        <p className="tw-mb-3.5 tw-text-base">
          The assessment scores will be <strong>published</strong> and <strong>available</strong> for students to view.
        </p>
        <p className="tw-text-base">Are you sure you want to continue?</p>
      </SubmitModal>
    </>
  );
};

const PostScoresButtonWrapper = (props: PostScoresButtonProps) => (
  <GradesTableQueryClientProvider>
    <PostScoresButton {...props} />
  </GradesTableQueryClientProvider>
);

export default PostScoresButtonWrapper;
