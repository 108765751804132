import React, { useState } from 'react';
import Cookie from 'react-cookies';
import ReactSwitch from 'react-switch';
import I18n from 'i18n-js';
import Tooltip from '@/components/ui/Tooltip';
import useDrawingContext from '../../store/useDrawingContext';
import { listItemStyle } from '../styles';

const SnapToGridSwitch = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const [snapToGrid, setSnapToGrid] = useState(Cookie.load('snapToGrid') === 'true');

  const setSnapToGridSetting = () => {
    if (snapToGrid === true) {
      Cookie.save('snapToGrid', false);
      setSnapToGrid(false);
    } else {
      Cookie.save('snapToGrid', true);
      setSnapToGrid(true);
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <div
            className="tw-flex tw-items-center tw-justify-start tw-px-5 tw-py-2 !tw-cursor-pointer hover:tw-bg-light-grey"
            style={listItemStyle(toolbarEl)}
          >
            <label
              className="tw-items-center"
              htmlFor="snap-to-grid-switch"
            >
              <ReactSwitch
                id="snap-to-grid-switch"
                onChange={() => setSnapToGridSetting()}
                checked={snapToGrid}
                onColor="#522e91"
                offColor="#cccccc"
                checkedIcon={false}
                uncheckedIcon={false}
                height={12}
                width={24}
                handleDiameter={10}
              />
              <span className="tw-pl-2 !tw-cursor-pointer">
                {I18n.t('snap_to_grid')}
              </span>
            </label>
            <i className="fa fa-info-circle tw-pl-2"/>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content
          className="tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-p-2
            tw-outline tw-outline-1 tw-outline-gray-300 tw-flex tw-flex-wrap tw-w-70
            !tw-left-10"
          side="right"
        >
          <div className="tw-w-[250px]">
            {I18n.t('snap_to_grid_tooltip')}
          </div>
          <div>
            <Tooltip.Arrow className="tw-absolute tw-text-sm tw-fill-gray-300 tw--translate-x-1/2 tw-top-[1px]" />
            <Tooltip.Arrow className="tw-absolute tw-fill-white tw--translate-x-1/2 " />
          </div>
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default SnapToGridSwitch;
