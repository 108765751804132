import I18n from '@/modules/i18n';
import FE from 'froala-editor';
import {
  shared_program_glossaries_path,
  shared_program_lesson_glossary_cards_path,
} from 'modules/routes';
import IGlossaryTerm from '@/components/interfaces/GlossaryTerm';

const definedTerm = (term: IGlossaryTerm, locale: Locale = 'en') => {
  const termKey = locale === 'en' ? 'term_en' : 'term_es';
  let vocabTerm = term[termKey];

  if (locale === 'es' && vocabTerm.toLowerCase().includes('spanish placeholder')) {
    vocabTerm = term.term_en;
  }

  return vocabTerm;
};

const termsHtml = (terms: IGlossaryTerm[], locale: Locale = 'en') => (
  terms.map(term => `<p class="vocab-term">${definedTerm(term, locale)}</p>`).join('')
);

export const sortedTerms = (terms: IGlossaryTerm[], locale: Locale = 'en') => (
  terms
    .sort((a, b) => {
      const termA = definedTerm(a, locale);
      const termB = definedTerm(b, locale);

      return termA.toLowerCase().localeCompare(termB.toLowerCase());
    })
);

export const vocabBoxHtml = (
  terms: IGlossaryTerm[],
  locale: Locale,
  lessonId: number | string,
  programId: number | string,
) => {
  const vocabLink = shared_program_lesson_glossary_cards_path(programId, lessonId);
  const glossaryLink = shared_program_glossaries_path(programId, { lesson_id: lessonId });

  return (`
    <div class="vocab-box">
      <div class="vocab-box-header">
        <p class="vocab-title">Vocabulary</p>
        <div>
          <a id="dynamic-vocab-cards-link" class="vocab-btn" href="${vocabLink}" target="_blank">
            ${I18n.t('vocabulary_cards', { locale })}
          </a>
          <a id="dynamic-glossary" class="vocab-btn" href="${glossaryLink}" target="_blank">
            ${I18n.t('glossary', { locale })}
          </a>
        </div>
      </div>
      <div class="vocab-terms">
        ${termsHtml(sortedTerms(terms, locale), locale)}
      </div>
    </div>
  `);
};

export const refreshTermsInEditor = (terms: IGlossaryTerm[], {
  selector = 'body',
  locale = 'en',
}: { selector: string, locale: Locale }) => {
  const termsContainer = document.querySelector(`${selector} .vocab-terms`);

  if (!termsContainer) return;

  termsContainer.innerHTML = termsHtml(terms, locale);
};

export const cleanUpDeleteButton = () => {
  const vocabBoxDeleteContainer = document.getElementById('vocabBoxDeleteContainer');
  if (vocabBoxDeleteContainer) vocabBoxDeleteContainer.remove();

  const vocabBoxDeleteButton = document.getElementById('vocabBoxDeleteButton');
  if (vocabBoxDeleteButton) vocabBoxDeleteButton.remove();
};
