import React from 'react';
import Spinner from '@/components/common/Spinner';
import useProgramContent from './useProgramContent';
import UnitInputs from './UnitInputs';
import LessonInputs from './LessonInputs';

interface ReuseProgramContentProps {
  programId: number;
}

const ReuseProgramContent = ({ programId }: ReuseProgramContentProps) => {
  const { isLoading, units, lessons } = useProgramContent({ programId });

  const renderProgramContent = () => {
    if (isLoading) return <Spinner />;

    if (units.length > 0) {
      return units.map((unit, i) => (
        <div key={`unit-${i}`}>
          <UnitInputs unit={unit} />
          <div className="tw-ml-5">
            {unit.lessons.map((lesson, i) => (
              <LessonInputs
                key={`lesson-inputs-${i}`}
                lesson={lesson}
              />
            ))}
          </div>
        </div>
      ));
    }

    return lessons.map((lesson, i) => (
      <LessonInputs
        key={`lesson-inputs-${i}`}
        lesson={lesson}
      />
    ));
  };

  return (
    <div className="tw-mt-2 tw-max-w-[800px]">
      <span className="tw-text-lg tw-font-bold tw-mb-1">
        {(isLoading || units.length) ? 'Units and Lessons' : 'Lessons'}
      </span>
      {renderProgramContent()}
    </div>
  );
};

export default ReuseProgramContent;
