import React from 'react';
import { IExerciseResult } from '@/components/staffer/Scores/GradesTable/types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import axios from 'axios';

export interface SyncGradesButtonProps {
  exerciseResults: IExerciseResult[];
  exerciseType: string;
  link: string;
}

const SyncGradesButton = ({ exerciseResults, exerciseType, link }: SyncGradesButtonProps) => {
  const isConnectedToCanvas = useGradesTableStore(state => state.isConnectedToCanvas);
  if (isConnectedToCanvas) return <></>;

  if (!exerciseResults.some(result => Object.keys(result.lms_settings || {}).length || result.requires_clever_passback)) {
    return <></>;
  }

  const syncGrades = () => {
    axios.post(link);
  };

  return (
    <span>
      <button
        aria-label="Sync scores with LMS"
        data-confirm={`This action will synchronize this classroom's ${exerciseType} results with LMS. Are you sure?`}
        className="btn btn--sm btn--outline-purple"
        id={`sync_class_${exerciseType.replace(/ /g, '_')}_score`}
        type="button"
        onClick={syncGrades}
      >
        Sync Grades
      </button>
    </span>
  );
};

export default SyncGradesButton;
