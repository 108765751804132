import React, { useContext } from 'react';
import BuilderContext from 'common/Context';

const AddTextAreaButton = () => {
  const { textAreaDispatch } = useContext(BuilderContext);
  const handleClick = () => textAreaDispatch({ type: 'ADD' });
  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    textAreaDispatch({ type: 'ADD' });
  };

  return (
    <button
      type="button"
      className="btn btn--sm btn--green"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      Add Text Area
    </button>
  );
};

export default AddTextAreaButton;
