import React, { useState } from 'react';
import Select from '@/components/common/ReactSelect5';
import { IProgram } from '../types';
import usePrograms from '../usePrograms';

interface ProgramSelectProps {
  onSelect: (programId: number) => void;
}

interface IOption {
  label: string;
  value: number;
}

const createOption = (program: IProgram) => ({
  label: `[${program.code}] ${program.full_title_with_edition}`,
  value: program.id,
});

const ProgramSelect = ({ onSelect }: ProgramSelectProps) => {
  const [option, setOption] = useState<IOption>();
  const { isLoading, programs } = usePrograms();

  const setProgram = (newValue: IOption) => {
    onSelect(newValue.value);
    setOption(newValue);
  };

  return (
    <div className="tw-max-w-[800px] tw-ml-5">
      <label htmlFor="programSelect" className="tw-font-bold tw-mb-1">
        Program
      </label>
      <Select
        id="programSelect"
        onChange={(newOption: IOption) => setProgram(newOption)}
        placeholder="Select a Program"
        value={option}
        options={programs.map(createOption)}
        borderColorOverride="#aaa"
        isLoading={isLoading}
      />
    </div>
  );
};

export default ProgramSelect;
