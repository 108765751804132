import React, { useState, useEffect, useCallback, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { capitalizeWords } from 'TCIUtils';
import I18n from 'i18n';
import moment from 'moment-timezone';
import { toCamelCase } from '../../modules/TCIUtils';

// Utils for determining type of User
export const isSysadmin = userType => (
  userType === 'Sysadmin'
);

export const isSupportManager = userType => (
  userType === 'SupportManager'
);

export const isAdmin = userType => (
  isSysadmin(userType) || userType === 'Admin'
);

export const isCoordinator = userType => (
  userType === 'Coordinator'
);

const lowerCaseWords = ['and'];

/**
 * Turns snake case to title case
 * @param {string} snakeString - string in snake case ex: 'snake_case'
 * @return {string} title case string ex: 'Title Case'
 */
export const snakeToTitle = snakeString => (
  snakeString && snakeString.toLowerCase()
    .split('_')
    .map(s => (lowerCaseWords.includes(s) ? s : capitalizeWords(s)))
    .join(' ')
);

/**
 * Capitalizes the string passed in
 * @param string - ex: "hello, I'm a string"
 * @returns {string} param capitalized - ex: "Hello, I'm a string"
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function alphabetizePrograms(program1, program2) {
  return program1.full_title_with_edition.localeCompare(program2.full_title_with_edition);
}

/**
 * Adds a parameter to a url
 * @param url - ex: "http://localhost.com, http://localhost.com?key=value"
 * @param parameterString - ex: "key=value"
 */
export function addParameterToUrl(url, parameterString) {
  const delimiter = url && url.includes('?') ? '&' : '?';

  return `${url}${delimiter}${parameterString}`;
}

/**
 * Returns an object of all the current url parameters in key-value pairs
 */
export function getUrlParams() {
  if (window.location.href.indexOf('?') === -1) return {};

  const paramPairs = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  const params = {};

  paramPairs.forEach((paramPair) => {
    const [param, value] = paramPair.split('=');

    if (param && value) {
      params[param] = value;
    }
  });

  return params;
}

/**
 * Returns the value of the given url parameter
 * @param {string} name - name of the url parameter
 */
export function getUrlParam(name) {
  return getUrlParams()[name];
}

/**
 * Returns the current locale (lowercase string) of the page from the url parameters.
 * Returns 'en' if the locale is not explicitly set as a url parameter.
 */
export function getLocale() {
  const locale = getUrlParam('locale');

  if (!locale) { return 'en'; }

  return locale.toLowerCase();
}

/**
 * Returns with the current height and width of the window
 * @returns {{ width: number, height: number }}
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  const updateDimensions = () => {
    setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return windowDimensions;
}

/**
 * Returns the current attribute value of a DOM element
 * @param {string} selector - CSS selector of the element to observe
 * @param {string} attribute - attribute to observe (ex. 'data-slide-id')
 * @returns {string} the current attribute value
 */
export function useMutationObserver(selector, attribute) {
  const [attributeValue, setAttributeValue] = useState(null);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      setAttributeValue(mutationsList[0].target.getAttribute(attribute));
    });

    const observerConfig = { attributeFilter: [attribute], attributes: true };
    observer.observe(document.querySelector(selector), observerConfig);

    return () => observer.disconnect();
  }, []);

  return attributeValue;
}

export const fileSizeFromString = (data) => {
  if (!data) return -1;

  const matches = data.match(/^(\d+(?:\.\d+)?)\s*([a-z]+)/i);
  const multipliers = {
    b: 1,
    bytes: 1,
    kb: 1000,
    kib: 1024,
    mb: 1000000,
    mib: 1048576,
    gb: 1000000000,
    gib: 1073741824,
    tb: 1000000000000,
    tib: 1099511627776,
    pb: 1000000000000000,
    pib: 1125899906842624
  };

  if (matches) {
    const multiplier = multipliers[matches[2].toLowerCase()];
    return parseFloat(matches[1]) * multiplier;
  }

  return -1;
};

export const investigationLabel = isScience => (
  isScience ? 'Investigation' : 'Activity'
);

/**
 * Returns a formatted string of a given date to a date with the given format in the user's local timezone:
 * Show the time e.g: 09:45 AM PST (if timeFormat of 'hh:mm A' is given)
 * Show the date and time e.g: Jan 01, 09:45 AM PST (if timeFormat of 'MMM DD, hh:mm A' is given)
 * @param time (Date or Time String)
 * @param timeFormat (String of timestamp format to use)
 * @returns {String}
 */
export const formatLocalTimestampWithFormat = (time, timeFormat) => {
  if (!time) return null;
  moment.locale(I18n.locale);

  const timezone = moment.tz(moment.tz.guess()).zoneAbbr();

  return `${moment(time).format(timeFormat)} ${timezone}`;
};

/**
 * Returns a formatted string of a given date to either:
 * Show the time e.g: 09:45 AM PST (if newlyUpdated is TRUE)
 * Show the date and time e.g: Jan 01, 09:45 AM PST (if newlyUpdated is FALSE)
 * @param time (Date or Time String)
 * @param newlyUpdated (Boolean indicating if the timestamp was updated)
 * @returns {String}
 */
export const formatLocalTimestamp = (time, newlyUpdated) => {
  const timeFormat = newlyUpdated ? 'hh:mm A' : 'MMM DD, hh:mm A';

  return formatLocalTimestampWithFormat(time, timeFormat);
};

/**
 * Returns the previous prop/state with the given name (value) for functional components using Hook API
 * @param value (Name of prop/state)
 * @returns {value}
 */
export const getPrev = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Appends a script tag to the dom referencing the passed url.
 * @param url (the src for the script tag)
 * @param dataset (dataset attributes for the script)
 * @param beforeLoad (function to run before loading the script)
 */
export const useScript = (url, dataset, beforeLoad = () => {}) => {
  useEffect(() => {
    if (beforeLoad) beforeLoad();

    const existingScript = document.querySelector(`script[src="${url}"]`);

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    if (dataset) {
      Object.keys(dataset).forEach((key) => { script.dataset[key] = dataset[key]; });
    }

    if (!existingScript) {
      document.querySelector(`script[src="${url}"]`);
      document.body.appendChild(script);
    }

    return () => {
      // don't remove if we have multiple components using the same script
      if (!existingScript) document.body.removeChild(script);
    };
  }, [url]);
};

/**
 * Allows you to create a debounced version of a `useEffect` in a component
 * @param effect (the effect to debounce)
 * @param deps (dependencies of the effect)
 * @param delay (how long to wait before calling effect)
 */
export const useDebouncedEffect = (effect, deps, delay) => {
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return () => {};
    }

    const handler = setTimeout(effect, delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};

/**
 * Font-awesome icon for loading spinner
 */
export const SpinnerIcon = () => <i className="fa fa-spinner fa-spin" aria-hidden="true" />;

export const ConditionalWrap = ({ condition, wrap, children }) => (
  condition ? wrap(children) : children
);

/**
 * For elements with open/close state, invoke the close function with the escape key
 */
export const closeOnEscapeKey = (handleClose) => {
  const handleEscKey = useCallback((event) => {
    if (event.keyCode === 27) handleClose();
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('keyup', handleEscKey, false);

    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
    };
  }, [handleEscKey]);
};

export const programIsExpired = (retirementYear) => {
  if (retirementYear == null) return false;

  return new Date(retirementYear, 5, 30) < new Date(Date.now());
};

export const maxDateTime = (dates) => {
  const dateTimeNumbers = dates.map(date => date.getTime());

  return new Date(Math.max(...dateTimeNumbers));
};

export const graphQlValidModel = (imageModel) => {
  const { image } = imageModel;

  const imagePrefixedObject = Object.keys(image).reduce((acc, cur) => {
    const camelizedKey = Object.keys(toCamelCase({ [cur]: null }))[0];

    return { ...acc, [`image_${camelizedKey}`]: image[cur] };
  }, {});

  let newItem = {
    ...imageModel,
    image: {
      ...image,
      image_url: image.url,
      image_es_url: image.es_url,
      file_name: image.image_en_file_name,
    },
  };

  newItem = toCamelCase(newItem);


  // @ts-ignore
  return ({
    ...newItem,
    ...imagePrefixedObject,
    image_fileNameEs: image.image_es_file_name,
    image_fileName: image.image_en_file_name,
    image_descriptionConfidence: image.description_confidence ? parseFloat(image.description_confidence) : undefined,
  });
};

export const arraysEqual = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;

  return a.length === b.length && a.every(val => b.includes(val));
};

export const presenceOr = (val, backup) => {
  return val != null ? val : backup
};

/**
 * Transforms a React component that returns an <svg> into a string usable as an img tag's `src` attribute.
 * @param component (the React svg Component to transform)
 */
export const svgAsImgSrc = (component) => {
  // See https://stackoverflow.com/questions/11753485/set-img-src-to-dynamic-svg-element
  return `data:image/svg+xml;base64,${btoa(renderToString(component))}`;
};
