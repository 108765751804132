import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import tciLogo from 'images/tci-logo.svg';
import { Footer } from 'common/Modal';
import Spinner from 'common/Spinner';
import { reconnectAccountMessage } from 'common/GoogleSettings/Utils';
import styles from './ConnectStudentsButton.module.scss';
import googleClassroomLogo from '../ConnectClassroom/google_logo.svg';
import canvasLogo from '../ConnectClassroom/canvas_logo.svg';
import MatchRow from './MatchRow';
import Confirmation from './Confirmation';
import { autoMatch } from './Utils';

const LMS_COURSE_DATA = gql`
query LmsCourseData($classroomId: ID!) {
  classroom(id: $classroomId) {
    name
    lmsCourseName
    tciStudents: students {
      id
      firstName
      lastName
      username
    }
    lmsStudents {
      firstName
      lastName
      uid
      existingMatch {
        id
        firstName
        lastName
        username
      }
    }
  }
}
`;

const ModalContent = ({
  classroomId, closeModal, lmsType, programId, subscriberId, updateClassroom
}) => {
  const queryData = { fetchPolicy: 'network-only', variables: { classroomId } };
  const { loading, data, error } = useQuery(LMS_COURSE_DATA, queryData);

  const [matchData, setMatchData] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const isGoogleIntegration = lmsType === 'google';

  // Once the GraphQL data loads, we must build out the "matchData" state variable with the matches that already existed
  // The "previouslyExisted" key in the data flags matches as having existed before the modal was opened
  useEffect(() => {
    if (!data) return;

    const newMatchData = data.classroom.lmsStudents.reduce((builtData, student) => {
      if (!student.existingMatch) return builtData;

      const matchDataForStudent = {
        firstName: student.existingMatch.firstName,
        lastName: student.existingMatch.lastName,
        previouslyExisted: true,
        tciId: student.existingMatch.id,
        username: student.existingMatch.username
      };

      return { ...builtData, [student.uid]: matchDataForStudent };
    }, {});

    setMatchData(newMatchData);
  }, [data]);

  const createMatch = (googleId, tciId) => {
    setMatchData(prevData => ({ ...prevData, [googleId]: tciId && { previouslyExisted: false, tciId } }));
    setAllowSubmit(true);
  };

  if (error) {
    if (error.toString().match(/insufficientPermissions/)) return reconnectAccountMessage(programId);

    return 'Something went wrong. Please refresh the page and try again.';
  }

  if (loading) return <Spinner />;

  const tciStudents = data.classroom.tciStudents;
  const lmsStudents = data.classroom.lmsStudents;

  const updateWithAutoMatch = () => {
    setMatchData(prev => autoMatch(prev, tciStudents, lmsStudents));
    setAllowSubmit(true);
  };

  const lmsIntegrationName = () => {
    if (isGoogleIntegration) return 'Google Classroom';

    return 'Canvas';
  };

  const modalInstructions = () => {
    if (isGoogleIntegration) {
      return (
        <Fragment>
          <p>
            To sync grades, each Google student account must be connected
            to a TCI account. This can be done in two ways:
          </p>
          <ol>
            <li>
              For each Google student below, select a matching TCI account.
              Click &quot;Auto Match Students&quot; to suggest matches based on first and last name.
            </li>
            <li>
              Ask students to sign into their TCI account and connect to their Google Classroom account.&nbsp;
              <a href="https://www.teachtci.com/tci-google-classroom-connect" target="_blank" rel="noopener noreferrer">
                See instructions here.
              </a>
            </li>
          </ol>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <p>
          To sync grades, each Canvas student account must be connected to a TCI account.
        </p>
        <p>
          For each Canvas student below, select a matching TCI account.
          Click &quot;Auto Match Students&quot; to suggest matches based on first and last name.
        </p>
      </Fragment>
    );
  };

  if (showConfirmation) {
    return (
      <Confirmation
        isGoogleIntegration={isGoogleIntegration}
        matchData={matchData}
        tciStudents={data.classroom.tciStudents}
        goBack={() => setShowConfirmation(false)}
        closeModal={closeModal}
        updateClassroom={updateClassroom}
        classroomId={classroomId}
        subscriberId={subscriberId}
      />
    );
  }

  return (
    <Fragment>
      {modalInstructions()}
      <button type="button" className="btn btn--outline-purple btn--md mt10" onClick={updateWithAutoMatch}>
        Auto Match Students
      </button>
      <div className={styles.selectionColumns}>
        <div className={styles.header}>
          <img src={isGoogleIntegration ? googleClassroomLogo : canvasLogo} height="25px" alt="" />
          {lmsIntegrationName()} - {data.classroom.lmsCourseName}
        </div>
        <div className={`${styles.header} ${styles.middle}`}>matched with</div>
        <div className={styles.header}>
          <img src={tciLogo} alt="" height="15px" />
          TCI Classroom - {data.classroom.name}
        </div>
        {lmsStudents.map(lmsStudent => (
          <MatchRow
            lmsType={lmsType}
            lmsStudent={lmsStudent}
            matchData={matchData}
            tciStudents={tciStudents}
            createMatch={createMatch}
          />
        ))}
      </div>
      <Footer
        primaryButtonText="Next"
        primaryButtonDisabled={!allowSubmit}
        secondaryButtonCallback={closeModal}
        primaryButtonSubmit={false}
        primaryButtonCallback={() => setShowConfirmation(true)}
      />
    </Fragment>
  );
};

ModalContent.propTypes = {
  classroomId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  lmsType: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};

export default ModalContent;
