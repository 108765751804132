import React, { useRef, useState } from 'react';
import EditImageModal from '@/components/shared/Froala/EditImageModal';
import { getImageModelIdFromElement } from '@/components/shared/Froala/froalaUtils';
import Anchor from '@/components/ui/Anchor';
import useImageModel from '@/hooks/api/useImageModel';
import { useModalManager } from '@/components/common/Modal';
import EditImageMarginsPopover from '@/components/shared/Froala/ImageMarginEditor';
import { EditImageMarginsStore } from '@/components/shared/Froala/ImageMarginEditor/useEditImageMarginsStore';
import marginIcon from 'images/icons/img-margin.svg';
import sizeIcon from 'images/icons/img-size.svg';
import Trash from './icons/Trash';
import AlignmentDropdown from './AlignmentDropdownMenu';
import { editImageAfterSubmit, WrapperFunction } from '../SectionEditorUtils';
import DisplayDropdown from './DisplayDropdownMenu';
import BorderDropdown from './BorderDropdownMenu';
import EditImageWithCaptionsSizePopover from './EditImageWithCaptionsSizePopover';

interface ImageCaptionPopoverProps {
  element?: HTMLElement;
  editorInstance: any;
}

const ImageCaptionPopover = ({ editorInstance, element }: ImageCaptionPopoverProps) => {
  const modalManager = useModalManager();
  const menuRef = useRef(null);
  const [isSizeEditorOpen, setIsSizeEditorOpen] = useState(false);
  const { data: imageModel } = useImageModel({ id: getImageModelIdFromElement(element) });

  const store = EditImageMarginsStore.get(`${editorInstance?.id?.toString()}-captions`);
  const setElement = store(state => state.setElement);

  const updateEditor: WrapperFunction = callback => (
    (...args) => {
      callback(...args);
      editorInstance.events.trigger('contentChanged');
    }
  );

  const refreshEditor = () => {
    editorInstance.html.set(editorInstance.html.get());
    editorInstance.selection.restore();
    editorInstance.events.trigger('contentChanged');
  };

  const remove = updateEditor(() => {
    element.parentNode.removeChild(element);
  });

  return (
    <>
      {imageModel && (
        <EditImageModal
          afterSubmit={editImageAfterSubmit()}
          currentImageModelId={imageModel.id}
          editorInstance={editorInstance}
          editImageModalManager={modalManager}
          modelType={imageModel.model_type}
          modelId={imageModel.model_id}
        />
      )}
      {/* {isMarginEditorOpen && ( */}
      <EditImageMarginsPopover
        editorInstance={editorInstance}
        id="captions"
      />
      {/* )} */}
      {isSizeEditorOpen && (
        <EditImageWithCaptionsSizePopover
          handleClose={() => setIsSizeEditorOpen(false)}
          menuRef={menuRef}
          refreshEditor={refreshEditor}
          wrapperEl={element}
        />
      )}
      <Anchor
        attachListenersTo={[document.querySelector('.fr-wrapper')]}
        className="fr-popup fr-desktop"
        key={element?.getAttribute('data-snippet')}
        to={element}
      >
        <div ref={menuRef} className="fr-buttons">
          <button className="fr-command fr-btn" onClick={modalManager.open} type="button">
            <i className="fa fa-pencil-square-o tw-text-[17px]" />
          </button>
          <AlignmentDropdown
            element={element}
            wrapper={updateEditor}
          />
          <DisplayDropdown
            element={element}
            wrapper={updateEditor}
          />
          <BorderDropdown
            element={element}
            wrapper={updateEditor}
          />
          <button className="fr-command fr-btn" onClick={remove} type="button">
            <Trash />
            <span className="fr-sr-only">Trash</span>
          </button>
          <button
            className="fr-command fr-btn"
            onClick={() => setIsSizeEditorOpen(true)}
            type="button"
          >
            <img alt="Size" src={sizeIcon} />
            <span className="fr-sr-only">Change Size</span>
          </button>
          <button
            className="fr-command fr-btn"
            onClick={() => setElement(element)}
            type="button"
          >
            <img alt="Margin" src={marginIcon} />
            <span className="fr-sr-only">Change Margin</span>
          </button>
        </div>
      </Anchor>
    </>
  );
};

export default ImageCaptionPopover;
