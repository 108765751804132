import React from 'react';
import Correlations from './Correlations';
import { AssessmentCorrelationsType } from './types';

interface AssessmentCorrelationsProps {
  correlations: AssessmentCorrelationsType
}

const AssessmentCorrelations = ({ correlations }: AssessmentCorrelationsProps) => (
  <div>
    <h2 className="mt30">Assessments</h2>
    {Object.keys(correlations.assessment_questions).sort((a, b) => (
      correlations.assessment_questions[a].position - correlations.assessment_questions[b].position
    )).map((questionId: string) => {
      const questionBody = correlations.assessment_questions[questionId].body;
      const correlation = correlations[questionId];
      if (correlation.length === 0) return null;

      return (
        <Correlations
          key={questionId}
          toggleText={questionBody}
          correlations={correlation}
        />
      );
    })}
  </div>
);

export default AssessmentCorrelations;
