import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

const TagTooltip = ({ buttonClasses, buttonText, tooltipHTML }) => {
  const tooltipContent = (
    <div className="tooltip_popup collapsed">
      <div className="tooltip_text" dangerouslySetInnerHTML={{ __html: tooltipHTML }} />
    </div>
  );

  return (
    <div className="tag-tooltip-trigger">
      <Tooltip
        hideOnClick
        appendTo={ref => ref.parentNode}
        placement="bottom"
        theme="white tag"
        trigger="mouseenter focus click"
        content={tooltipContent}
      >
        <button type="button" aria-haspopup="dialog" className={buttonClasses}>{buttonText}</button>
      </Tooltip>
    </div>
  );
};

TagTooltip.propTypes = {
  buttonClasses: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  tooltipHTML: PropTypes.string.isRequired
};

export default TagTooltip;
