import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { Texture } from './threeJsUtils';

type SupportedExtension = 'glb' | 'gltf' | 'obj' | 'fbx';

export const getExtension = (url: string): SupportedExtension => {
  const parts = url.split('.');

  const end = parts[parts.length - 1];

  if (end.toLowerCase().includes('glb') || end.toLowerCase().includes('gltf')) {
    return 'glb';
  }

  return 'obj';
};

export function setupScene() {
  const scene = new THREE.Scene();

  return scene;
}

interface SetupCamera {
  fov?: number;
  aspectRatio?: number;
  min?: number;
  max?: number;
}

export function setupCamera({
  fov = 75,
  max = 10_000,
  min = 0.01,
  aspectRatio = 16 / 9,
}: SetupCamera) {
  const camera = new THREE.PerspectiveCamera(
    fov,
    aspectRatio,
    min,
    max,
  );

  return camera;
}

export type Vec3 = { x: number, y: number, z: number };
export type CameraStartingArgs = { position: Vec3, rotation: Vec3, target: Vec3, zoom: number };

export function adjustCamera(
  camera: THREE.PerspectiveCamera,
  controls: OrbitControls,
  model: THREE.Group,
  { position, rotation, target, zoom }: CameraStartingArgs,
) {
  if (Object.keys(position).length > 0) {
    camera.position.set(
      position.x,
      position.y,
      position.z,
    );
  } else {
    const box = new THREE.Box3().setFromObject(model);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());

    if (!(Number.isNaN(center.x) || Number.isNaN(size.x))) {
      camera.position.set(
        center.x - size.x,
        center.y - size.y,
        center.z - size.z,
      );
    }
  }

  if (Object.keys(rotation).length > 0) {
    camera.rotation.set(
      rotation.x,
      rotation.y,
      rotation.z,
    );
  }

  if (Object.keys(target).length > 0) {
    controls.target.set(
      target.x,
      target.y,
      target.z,
    );
  }

  /* eslint-disable-next-line no-param-reassign */
  if (zoom) camera.zoom = zoom;
}

export function setupRenderer(element: HTMLElement) {
  const renderer = new THREE.WebGLRenderer({ logarithmicDepthBuffer: true });

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(element.clientWidth, element.clientHeight);

  return renderer;
}

export function setupControls(camera: THREE.Camera, element: HTMLElement): OrbitControls {
  const controls = new OrbitControls(camera, element);
  controls.enableDamping = true;

  return controls;
}

export function setupEnvironment(scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
  /* eslint-disable-next-line no-param-reassign */
  scene.background = new THREE.Color(0xbfe3dd);

  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  /* eslint-disable-next-line no-param-reassign */
  scene.environment = pmremGenerator.fromScene(new RoomEnvironment(renderer), 0.04).texture;
}

interface LoadModel {
  modelUrl: string;
  onError?: (error: ErrorEvent) => any;
  onLoading?: (progress: ProgressEvent) => any;
  onSuccess?: (model: THREE.Group) => any;
}

export function renderGlbGltf({
  modelUrl,
  onError = () => { },
  onLoading = () => { },
  onSuccess = () => { },
}: LoadModel) {
  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    'https://www.gstatic.com/draco/versioned/decoders/1.5.7/',
  );

  dracoLoader.setCrossOrigin('use-credentials');

  loader.setDRACOLoader(dracoLoader);
  loader.load(
    modelUrl,
    (gltf: { scene: THREE.Group }) => onSuccess(gltf.scene),
    onLoading,
    onError,
  );
}

function getNameFromUrl(url: string) {
  const fileName = url.split('/').pop();

  const match = fileName.match(/^(.*)_.*$/);

  if (match) return match[1].toLowerCase();

  return 'base';
}

function setupTextures(textures: Texture[]) {
  return textures.map((t) => {
    const texture = new THREE.TextureLoader().load(t.url);
    texture.colorSpace = THREE.SRGBColorSpace;

    return { texture, type: t.type, url: t.url };
  });
}

type SetupTexturesResult = ReturnType<typeof setupTextures>;

interface SetupObjFbx extends LoadModel {
  textures: Texture[];
}

export function setupObjFbx({
  modelUrl,
  onError = () => { },
  onLoading = () => { },
  onSuccess = () => { },
  textures: textureObjects,
}: SetupObjFbx) {
  const textures = setupTextures(textureObjects);
  const loader = new OBJLoader();

  loader.load(
    modelUrl,
    (obj: THREE.Group) => {
      let materialNames = [];

      obj.traverse((child: THREE.Mesh) => {
        if (!child.isMesh) return;

        if (Array.isArray(child.material)) return;

        if (child.material?.name) materialNames.push(child.material.name);
      });

      materialNames = [...new Set(materialNames)];

      obj.traverse((child: THREE.Mesh) => {
        if (!child.isMesh) return;

        if (Array.isArray(child.material)) return;

        if (Array.isArray(child.material)) {
          child.material.forEach((material: THREE.MeshStandardMaterial) => {
            if (!material) return;

            const materialTextures = textures.filter((t) => {
              const mat = child.material as THREE.MeshStandardMaterial;

              return t.url.includes(mat.name);
            });

            const opacityTexture = materialTextures.find(t => t.type === 'opacity');
            const colorTexture = materialTextures.find(t => t.type === 'color');
            const normalTexture = materialTextures.find(t => t.type === 'normal');
            const roughnessTexture = materialTextures.find(t => t.type === 'roughness');
            const metallicTexture = materialTextures.find(t => t.type === 'metallic');
            const emissiveTexture = materialTextures.find(t => t.type === 'emissive');
            const displacementTexture = materialTextures.find(t => t.type === 'displacement');
            const bumpTexture = materialTextures.find(t => t.type === 'bump');

            // eslint-disable-next-line no-param-reassign
            if (colorTexture) material.map = colorTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (opacityTexture) material.alphaMap = opacityTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (normalTexture) material.normalMap = normalTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (roughnessTexture) material.roughnessMap = roughnessTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (metallicTexture) material.metalnessMap = metallicTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (emissiveTexture) material.emissiveMap = emissiveTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (displacementTexture) material.displacementMap = displacementTexture.texture;
            // eslint-disable-next-line no-param-reassign
            if (bumpTexture) material.bumpMap = bumpTexture.texture;
          });

          return;
        }

        const materialTextures = textures.filter((t) => {
          const material = child.material as THREE.MeshStandardMaterial;

          return t.url.includes(material.name);
        });

        const material = child.material as THREE.MeshStandardMaterial;

        const opacityTexture = materialTextures.find(t => t.type === 'opacity');
        const colorTexture = materialTextures.find(t => t.type === 'color');
        const normalTexture = materialTextures.find(t => t.type === 'normal');
        const roughnessTexture = materialTextures.find(t => t.type === 'roughness');
        const metallicTexture = materialTextures.find(t => t.type === 'metallic');
        const emissiveTexture = materialTextures.find(t => t.type === 'emissive');
        const displacementTexture = materialTextures.find(t => t.type === 'displacement');

        if (colorTexture) material.map = colorTexture.texture;
        if (opacityTexture) material.alphaMap = opacityTexture.texture;
        if (normalTexture) material.normalMap = normalTexture.texture;
        if (roughnessTexture) material.roughnessMap = roughnessTexture.texture;
        if (metallicTexture) material.metalnessMap = metallicTexture.texture;
        if (emissiveTexture) material.emissiveMap = emissiveTexture.texture;
        if (displacementTexture) material.displacementMap = displacementTexture.texture;

        // eslint-disable-next-line no-param-reassign
        child.material = material;
      });

      onSuccess(obj);
    },
    onLoading,
    onError,
  );
}

interface Animate {
  camera: THREE.Camera;
  controls: OrbitControls;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
}

export function animate({
  camera,
  controls,
  renderer,
  scene,
}: Animate) {
  controls.update();
  renderer.render(scene, camera);
}
