import React from 'react';
import clsx from 'clsx';
import Pluralize from 'pluralize';
import { Droppable } from 'react-beautiful-dnd';
import { UseFieldArrayReturn } from 'react-hook-form';
import I18n from 'i18n-js';
import {
  MIN_COL_WIDTH_CLASS,
  newGlossaryTerm,
  SchemaType,
  useVocabDecksStore,
} from '@/components/common/VocabularyCards/variables';
import VocabTermRow from '@/components/common/VocabularyCards/Forms/VocabTermRow';
import { IPlaceholderProps } from '@/components/common/VocabularyCards/interfaces';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_glossary_term_models_path } from '@/modules/routes';

interface VocabTermProps {
  fieldArray: UseFieldArrayReturn<SchemaType, 'deck.glossary_term_models'>;
  locale: 'en' | 'es';
  placeholder?: React.ReactNode;
  placeholderProps: IPlaceholderProps;
}

const VocabTerms = ({ fieldArray, locale, placeholderProps }: VocabTermProps) => {
  const deckId = useVocabDecksStore(state => state.deckId);
  const { fields, append, remove } = fieldArray;

  const { isLoading, mutate } = useMutation({
    mutationFn: () => axios.post(plato_api_glossary_term_models_path(), { glossary_term_model: newGlossaryTerm(deckId) }),
    onSuccess: res => append(res.data.data),
  });

  const addVocabTerm = () => mutate();

  return (
    <>
      <div className="tw-flex tw-gap-20 tw-mb-4">
        <h4 className={clsx(MIN_COL_WIDTH_CLASS, 'tw-text-[11px]')}>
          {fields.length} {Pluralize(I18n.t('terms'), fields.length)}
        </h4>
        <h4 className={clsx(MIN_COL_WIDTH_CLASS, 'tw-text-[11px]')}>{I18n.t('enter_def_image_or_both')}</h4>
      </div>
      <Droppable droppableId="vocab-terms" type="TERM">
        {(provided, snapshot) => (
          <div className="tw-relative" ref={provided.innerRef} {...provided.droppableProps}>
            <div className="tw-flex tw-flex-col tw-gap-6">
              {fields.map((field, index) => (
                <VocabTermRow key={field.id} field={field} index={index} remove={remove} locale={locale} />
              ))}
            </div>
            {provided.placeholder}
            {!(Object.keys(placeholderProps).length === 0) && snapshot.isDraggingOver && (
              <div
                className="tw-absolute"
                style={{
                  top: placeholderProps.clientY,
                  left: placeholderProps.clientX,
                  height: placeholderProps.clientHeight,
                  width: placeholderProps.clientWidth,
                }}
              />
            )}
          </div>
        )}
      </Droppable>
      <button
        className="btn btn--green tw-mt-8"
        disabled={isLoading}
        onClick={addVocabTerm}
        type="button"
      >
        + {I18n.t('add_term')}
      </button>
    </>
  );
};

export default VocabTerms;
