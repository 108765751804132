import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../common/Classes/Form.module.scss';

export default function StaticRow({ label, data }) {
  return (
    <div className={styles.row}>
      <div className={styles.fieldContainer}>
        <span className={styles.label}>
          {label}
        </span>
        <span>
          {data}
        </span>
      </div>
    </div>
  );
}

StaticRow.propTypes = {
  data: PropTypes.string,
  label: PropTypes.string
};

StaticRow.defaultProps = {
  data: '',
  label: ''
};
