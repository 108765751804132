import React, { Component } from 'react';
import Form from './Form';
import Axios from 'axios';
import { truncate } from 'TCIUtils';
import { ModalButton, DeleteButton, SafetyNoteThumbnail } from '../Utils';

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    modalIsOpen: false,
    confirmationModalIsOpen: false,
    handoutTitle: '',
    image: null,
    name: '',
    quantity: null,
    unit: null,
    distribution: null,
    reusable: false,
    recyclable: false,
    safetyNotes: [],
    isDeleting: false,
    programKitMaterial: null
  };

  render() {
    const { image, quantity, modelUnit, name,
            distribution, reusable, recyclable, programKitMaterial } = this.state;
    return (
      <tr>
        <td className="image"><img src={image} /></td>
        <td>{this.handoutLink() || name}</td>
        <td className="quantity">{parseFloat(quantity)}</td>
        <td className="unit">{modelUnit}</td>
        <td className="distribution">{distribution}</td>
        <td className="program-kit-material">{programKitMaterial === null ? 'N/A' : programKitMaterial.label}</td>
        <td className="reusable">{reusable && 'Y'}</td>
        <td className="recyclable">{recyclable && 'Y'}</td>
        <td className="safety-notes">
          {this.state.safetyNotes.map(safetyNote => (
            <SafetyNoteThumbnail key={safetyNote.id} {...safetyNote} />
          ))}
        </td>
        <td className="actions">
          <ModalButton buttonText="Edit"
                       buttonClass={'action'}
                       modalHeader="Edit Module Materials"
                       modalClass="edit-materials"
                       openModal={this.openModal.bind(this)}
                       closeModal={this.closeModal.bind(this)}
                       onUpdate={this.updateMaterial.bind(this)}
                       FormComponent={Form}
                       {...this.state} />
          <DeleteButton confirmationModalIsOpen={this.state.confirmationModalIsOpen}
                        openConfirmationModal={this.openConfirmationModal.bind(this)}
                        closeConfirmationModal={this.closeConfirmationModal.bind(this)}
                        handleDelete={this.handleDelete.bind(this)} isDeleting={this.state.isDeleting} />
        </td>
      </tr>
    );
  }

  handoutLink() {
    const { name, handoutUrl, handoutTitle } = this.state;
    return (name === 'Handout' &&
      <a href={handoutUrl} target="_blank">
        {truncate(handoutTitle, 50)} <i className="fa fa-external-link" />
      </a>
    )
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  updateMaterial(material) {
    this.setState(material);
    this.props.updateRow(material);
  }

  openConfirmationModal() {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false })
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/model_materials/${this.props.id}`).then(response => {
      response.data ? this.handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    });
  }
}
