import React from 'react';
import I18n from 'i18n-js';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import {
  plato_api_deck_path,
  shared_program_lesson_decks_path,
  plato_api_glossary_term_path,
} from '@/modules/routes';
import {
  QueryKeys,
  SchemaType,
  useVocabDecksStore,
} from '@/components/common/VocabularyCards/variables';
import useDeck from '@/components/common/VocabularyCards/hooks/useDeck';
import Modal from '@/components/ui/Modal';
import { useModalManager } from '@/components/common/Modal';
import removeIcon from '../../../../../images/icons/remove-gray.svg';

interface TermDeleteButtonProps {
  index: number;
  onMouseEnter: () => any;
  onMouseLeave: () => any;
  remove: UseFieldArrayRemove;
}

const TermDeleteButton = ({ index, onMouseEnter, onMouseLeave, remove }: TermDeleteButtonProps) => {
  const deckId = useVocabDecksStore(store => store.deckId);
  const lessonId = useVocabDecksStore(store => store.lessonId);
  const programId = useVocabDecksStore(store => store.programId);

  const { data: deck } = useDeck(deckId);
  const queryClient = useQueryClient();
  const { getValues, setValue } = useFormContext<SchemaType>();
  const { isOpen, open, close } = useModalManager();

  const id = deck.glossary_term_models[index]?.id;
  const glossaryTermId = deck.glossary_term_models[index]?.glossary_term?.id;
  const isLastField = getValues('deck.glossary_term_models').length === 1;

  const { isLoading, mutate } = useMutation({
    mutationFn: () => {
      if (isLastField) {
        return axios.delete(plato_api_deck_path(deckId));
      }

      return axios.delete(plato_api_glossary_term_path(glossaryTermId));
    },
    onSuccess: () => {
      if (isLastField) {
        window.location.replace(shared_program_lesson_decks_path(programId, lessonId));
        return;
      }

      remove(index);

      const newGlossaryTermModels = deck.glossary_term_models.filter((_gtm, i) => i !== index);
      const updatedData = {
        ...deck,
        glossary_term_models: deck.glossary_term_models.map((gtm, i) => ({ ...gtm, position: i + 1 })),
        updated_at: new Date().toISOString(),
      };

      newGlossaryTermModels.forEach((term, i) => setValue(`deck.glossary_term_models.${i}.position`, i + 1));
      queryClient.setQueryData([QueryKeys.DECK, deckId], updatedData);
    },
  });

  const baseMsg = I18n.t('delete_confirmation', { item: I18n.t('vocabulary_term') });
  const msg = `${baseMsg} ${isLastField ? I18n.t('delete_deck') : ''}`;

  return (
    <>
      <button
        className={`tw-border-none tw-bg-transparent tw-flex tw-justify-center tw-items-center tw-cursor-pointer 
                    tw-rounded-full tw-bg-white tw-max-w-[25px] tw-max-h-[25px]`}
        onClick={open}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type="button"
        disabled={!id}
      >
        <img alt="Delete" src={removeIcon} />
      </button>
      <Modal
        close={close}
        headerText={`${I18n.t('delete')} ${I18n.t('term')}`}
        isOpen={isOpen}
      >
        <div>
          <p>{msg}</p>
        </div>
        <Modal.SubmitFooter
          buttonColor="red"
          close={close}
          isLoading={isLoading}
          isValid
          onSubmit={mutate}
          submittingText={I18n.t('deleting')}
          submitText={`${I18n.t('yes')}, ${I18n.t('delete').toLowerCase()}`}
        />
      </Modal>
    </>
  );
};

export default TermDeleteButton;
