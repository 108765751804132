import React from 'react';
import ColorPickerPopover from './ColorPickerPopover';
import { EditPopoverProps } from '../types';

const EditBackgroundPopover = ({ anchorId, slideObject, styleDivRef, dropdownEvents }: EditPopoverProps) => {
  const openerClass = `color-popover-opener-${slideObject.id}`;
  return (
    <>
      <a
        className={`dropdown-item ${openerClass}`}
        href="#"
        role="button"
        onClick={() => dropdownEvents.setOpen(false)}
      >
        Change Background
      </a>
      <ColorPickerPopover
        anchorId={anchorId}
        openerClass={openerClass}
        slideObject={slideObject}
        styleDivRef={styleDivRef}
      />
    </>
  );
};

export default EditBackgroundPopover;
