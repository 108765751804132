import React from 'react';
import { Benchmark } from '@tci/media-libraries';
import '@tci/media-libraries/dist/style.css';

type BenchmarksProps = {
  addStudentsPath: string;
  classroomId: number;
  locale: Locale,
  programId: number;
};

const Benchmarks = ({ addStudentsPath, classroomId, locale, programId }: BenchmarksProps) => (
  <Benchmark addStudentsPath={addStudentsPath} classId={classroomId} locale={locale}  programId={programId} />
);

export default Benchmarks;
