import React from 'react';
import I18n from 'i18n-js';
import { Lesson, Program, SectionType } from '@/components/shared/LessonOverview/types';
import LessonSubheader from './LessonSubheader';
import AssessmentsCard from './Cards/AssessmentsCard';
import LessonGameCard from './Cards/LessonGameCard';
import NotebookCards from './Cards/NotebookCards';

interface AssessWithProps {
  hasAssessments?: boolean;
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  stafferId?: number;
  unitColor: string;
}

const AssessWith = ({ hasAssessments, lesson, program, sections, stafferId, unitColor }: AssessWithProps) => (
  <LessonSubheader
    title={I18n.t('assess_with')}
  >
    <NotebookCards
      lesson={lesson}
      program={program}
      sections={sections}
      stafferId={stafferId}
      unitColor={unitColor}
    />

    {lesson.lessonGameAssignables.length > 0 && (
      <LessonGameCard
        lesson={lesson}
        lessonId={lesson.id}
        program={program}
        stafferId={stafferId}
        type="lesson-game"
        unitColor={unitColor}
      />
    )}

    {(hasAssessments || stafferId) && (
      <AssessmentsCard
        hasAssessments={hasAssessments}
        lesson={lesson}
        lessonId={lesson.id}
        program={program}
        stafferId={stafferId}
        type="assessments"
        unitColor={unitColor}
      />
    )}
  </LessonSubheader>
);

export default AssessWith;
