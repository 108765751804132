/**
 * Renders a button that opens a modal.
 * Children will be rendered as the modal content.
 * When rendering the children, a `closeModal` prop will be passed that will close the parent modal when called.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

export default class ModalButton extends Component {
  static propTypes = {
    allowOverflow: PropTypes.bool,
    ariaLabel: PropTypes.string,
    buttonClasses: PropTypes.string.isRequired,
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    children: PropTypes.element.isRequired,
    closeButtonClassName: PropTypes.string,
    modalHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    modalHeaderClass: PropTypes.string
  };

  static defaultProps = {
    allowOverflow: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  renderButton() {
    return (
      <button
        aria-label={this.props.ariaLabel}
        type="button"
        className={this.props.buttonClasses}
        onClick={this.openModal}
      >
        {this.props.buttonLabel}
      </button>
    );
  }

  renderModal() {
    return (
      <Modal
        allowOverflow={this.props.allowOverflow}
        closeButtonClassName={this.props.closeButtonClassName}
        headerClass={this.props.modalHeaderClass}
        headerText={this.props.modalHeaderText}
        isOpen={this.state.isModalOpen}
        closeModal={this.closeModal}
      >
        {this.renderChildren()}
      </Modal>
    );
  }

  renderChildren() {
    // Render the child component and pass the `closeModal` function as a prop:
    return React.cloneElement(this.props.children, { closeModal: this.closeModal });
  }

  render() {
    return (
      <Fragment>
        {this.renderButton()}
        {this.renderModal()}
      </Fragment>
    );
  }
}
