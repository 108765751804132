/* eslint-disable react/prop-types */

import React from 'react';
import I18n from 'modules/i18n';
import ActionsDropdown from 'components/common/ActionsDropdown';
import * as Routes from 'modules/routes';
import Tooltip from '../../../common/Tooltip';

type AddClassButtonProps = { allowManualAdd: boolean, programId: string, programTitle: string; licenseId: number; }

const AddClassButton = ({ allowManualAdd, programId, programTitle, licenseId } : AddClassButtonProps) => {
  const addClassOption = () => {
    const dropdownOption = (
      <a
        className={`dropdown-item ${allowManualAdd ? '' : 'disabled'}`}
        href={allowManualAdd ? Routes.new_teacher_program_classroom_path(programId) : '#'}
      >
        Add New Class
      </a>
    );

    if (allowManualAdd) return dropdownOption;

    return (
      <Tooltip content={I18n.t('exclude_teacher_or_program_notice_short')}>
        {dropdownOption}
      </Tooltip>
    );
  };

  return (
    <ActionsDropdown
      toggleClassName="btn--green"
      toggleText="Add Class"
    >
      {addClassOption()}
      <div className="dropdown-divider" />
      <div
        data-program-title={programTitle}
        data-license-id={licenseId}
        className={`program-${programId}`}
      />
    </ActionsDropdown>
  );
};

export default AddClassButton;
