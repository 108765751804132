import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Axios from 'axios/index';
import * as Sentry from '@sentry/react';
import I18n from 'i18n';
import ErrorBoundary from 'shared/Error/ErrorBoundary';
import videoPropTypes from 'common/VideoPlayer/Proptypes';
import Tooltip from 'common/Tooltip';
import TTSButton from 'shared/TextToSpeechButton';
import AutoSave from './AutoSave';
import TextArea from './TextArea';
import assessmentStyles from './TextAreaFormAssessment.module.scss';
import notebookStyles from './TextAreaFormNotebook.module.scss';
import {
  formatTimestamp, renderStatusMessage, renderVideo, validateAnswerLength, DEFAULT_NOTEBOOK_GREEN
} from './QuestionUtils';
import clsx from 'clsx';

export default class TextAreaForm extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    exerciseType: PropTypes.string.isRequired,
    gifs: PropTypes.arrayOf(PropTypes.string),
    hasSaveButton: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.string),
    inputId: PropTypes.string.isRequired,
    locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
    notebookBorderColor: PropTypes.string,
    preview: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTTSEnabled: PropTypes.bool,
    savedResult: PropTypes.shape({
      answer: PropTypes.string,
      updated_at: PropTypes.string
    }),
    showNotebookBorder: PropTypes.bool,
    url: PropTypes.string.isRequired,
    video: videoPropTypes,
  };

  static defaultProps = {
    disabled: false,
    gifs: null,
    hasSaveButton: false,
    images: null,
    notebookBorderColor: DEFAULT_NOTEBOOK_GREEN,
    questionTTSEnabled: true,
    savedResult: null,
    showNotebookBorder: false,
    video: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      status: this.props.savedResult && this.props.savedResult.updated_at ? '200' : '',
      submitting: false,
      updatedAt: this.props.savedResult && formatTimestamp(this.props.savedResult.updated_at, false),
      value: this.props.savedResult && this.props.savedResult.answer
    };

    this.save = this.save.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  // Return stylesheet based on exercise type
  getStyles() {
    if (this.props.exerciseType === 'Assessment') return assessmentStyles;
    if (this.props.exerciseType === 'Notebook' || this.props.exerciseType === 'SlideShow') return notebookStyles;
    return {};
  }

  save = async (values) => {
    // don't save answer if disabled or answer is empty
    if (this.props.disabled) return;

    this.setState({ status: '', submitting: true });

    await Axios
      .post(this.props.url, { question_answer: values })
      .then((response) => {
        const data = response.data;

        if (data.errors) {
          this.setState({ deleted: false, status: '500' });
          console.log(response.data.errors);
        }
        else if (data.question_answer) {
          this.setState({
            deleted: false,
            status: '200',
            updatedAt: formatTimestamp(data.question_answer.updated_at, true),
            value: data.question_answer.answer
          });
        }
        else {
          this.setState({
            deleted: true,
            status: '200',
            updatedAt: formatTimestamp(new Date(), true),
            value: undefined
          });
        }
      })
      .catch((error) => {
        this.setState({ status: '400' });
        Sentry.captureException(error, { tags: { component: 'TextAreaForm' } });
        console.log(error);
      })
      .then(() => this.setState({ submitting: false }));
  };

  _renderTTSButton() {
    return (
      <div className="inline">
        <TTSButton
          locale={this.props.locale}
          idToRead={`question_${this.props.questionId}`}
        />
      </div>
    );
  }

  renderAttachments() {
    return (
      <div>
        {this.renderImageAttachments(this.props.images)}
        {this.renderImageAttachments(this.props.gifs)}
        {
          <div className={this.props.video ? this.getStyles().imagesContainer : ''}>
            {renderVideo({ video: this.props.video })}
          </div>
        }
      </div>
    );
  }

  // Render attached images/gifs, if any
  renderImageAttachments(attachments) {
    if (!attachments || attachments.length === 0) return null;

    return (
      <div className={this.getStyles().imagesContainer}>
        {attachments.map(imageUrl => (
          <img
            key={imageUrl}
            src={imageUrl}
            alt=""
            className={this.getStyles().questionImage}
          />
        ))}
      </div>
    );
  }

  renderAutoSave(answerIsValid) {
    if (this.props.preview) return null;

    return <AutoSave allowBlanks save={values => answerIsValid && this.save(values)} />;
  }

  renderInvalidMessage(invalidMessage) {
    return (
      <div className={this.getStyles().invalidStatusMessage}>
        <span>
          <i className="fa fa-exclamation-triangle" />
          <strong>Not Saved</strong>
        </span>
        <span>{invalidMessage}</span>
      </div>
    );
  }

  renderStatusMessage(errors, model) {
    if (errors.answer) return this.renderInvalidMessage(errors.answer);

    return renderStatusMessage(this.state.status, this.state.submitting, this.state.updatedAt, model);
  }

  renderAssessmentQuestion({ errors }) {
    return (
      <div data-answered={!!this.state.value}>
        {this.renderAutoSave(!errors.answer)}

        <li
          className="expandable_question question-toggle allow-list-style"
          data-accordian-pair-id={this.props.questionId}
        >
          <label
            className={assessmentStyles.label}
            htmlFor={this.props.inputId}
          >
            <span
              className="respect_breaks"
              dangerouslySetInnerHTML={{ __html: this.props.question }}
              id={`question_${this.props.questionId}`}
            />
          </label>
          {this.props.questionTTSEnabled && this._renderTTSButton()}
          {this.renderStatusMessage(errors, 'Assessment')}
        </li>

        <div
          className={`js-question-details mb20 ${assessmentStyles.questionAnswer}`}
          data-accordian-pair-id={this.props.questionId}
        >
          {this.renderAttachments()}
          <Field
            component={TextArea}
            name="answer"
            id={this.props.inputId}
            className={assessmentStyles.textarea}
          />
        </div>
      </div>
    );
  }

  renderNotebookQuestion({ errors }) {
    return (
      <div data-answered={!!this.state.value}>
        {this.renderAutoSave(!errors.answer)}

        <div>
          <div className="notebook-question-text">
            <label
              htmlFor={this.props.inputId}
              className="respect_breaks"
              dangerouslySetInnerHTML={{ __html: this.props.question }}
            />
            {this.props.questionTTSEnabled && this._renderTTSButton()}
          </div>
          {this.renderAttachments()}
          <div
            className="question-interactable"
            style={{ backgroundColor: this.props.showNotebookBorder && `#${this.props.notebookBorderColor}` }}
          >
            <div className={errors.answer && this.getStyles().invalid}>
              {this.props.disabled && <div className="div-overlay" />}
              <Field
                component={TextArea}
                placeholder={I18n.t('start_typing_here')}
                name="answer"
                id={this.props.inputId}
                className={notebookStyles.textarea}
                disabled={this.props.disabled}
              />
              <Tooltip content={I18n.t('click_inside_the_box')} size="regular" theme="white">
                <i className="fa fa-pencil pencil-icon" />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="save">
          {this.renderStatusMessage(errors, 'Notebook')}
        </div>
      </div>
    );
  };

  renderExerciseQuestion = ({ errors }) => (
    <div data-answered={!!this.state.value}>
      {this.renderAutoSave(!errors.answer)}

      <div>
        <div className="exercise-question-text">
          <label
            htmlFor={this.props.inputId}
            className={clsx('respect_breaks', { 'tw-text-[19px]': this.props.exerciseType === 'ReferenceMaterial' })}
            dangerouslySetInnerHTML={{ __html: this.props.question }}
          />
          {this.props.questionTTSEnabled && this._renderTTSButton()}
        </div>
        {this.renderAttachments()}
        <div
          className="question-interactable"
        >
          <div className={errors.answer && this.getStyles().invalid}>
            {this.props.disabled && <div className="div-overlay" />}
            <Field
              component={TextArea}
              placeholder={I18n.t('start_typing_here')}
              name="answer"
              id={this.props.inputId}
              className={notebookStyles.textarea}
              disabled={this.props.disabled}
            />
            <Tooltip content={I18n.t('click_inside_the_box')} size="regular" theme="white">
              <i className="fa fa-pencil pencil-icon" />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="save">
        {this.renderStatusMessage(errors, this.props.exerciseType)}
      </div>
    </div>
  );

  // Render HTML of the textarea form based on the exercise type
  renderQuestion(formProps) {
    if (this.props.exerciseType === 'Assessment') {
      return this.renderAssessmentQuestion(formProps);
    }
    if (this.props.exerciseType === 'Notebook' || this.props.exerciseType === 'SlideShow') {
      return this.renderNotebookQuestion(formProps);
    }

    if (this.props.exerciseType === 'ReferenceMaterial') {
      return this.renderExerciseQuestion(formProps);
    }

    return null;
  }

  render() {
    return (
      <ErrorBoundary sentryTags={{ component: 'TextAreaForm' }}>
        <Form
          keepDirtyOnReinitialize
          initialValues={{ answer: this.state.value, question_id: this.props.questionId }}
          onSubmit={this.save}
          render={this.renderQuestion}
          validate={validateAnswerLength}
        />
      </ErrorBoundary>
    );
  }
}
