import React from 'react';

import { GameLibrary } from '@tci/media-libraries';
import '@tci/media-libraries/dist/style.css';
import '@tci/media-libraries/dist/assets/images/close-icon.svg';
import '@tci/media-libraries/dist/assets/images/collapse-icon.svg';

import AsyncAssignmentModal from '../../staffer/AssignmentModal/AsyncAssignmentModal';

type GameProps = {
  program: {
    id: string,
    display_investigations: boolean,
    display_unit_content: boolean,
    science: boolean,
    referenceTextLabel: string,
  };
  lesson?: {
    id: string;
  };
  unit?: {
    id: string;
  };
  gradeUri: string;
};

const Games = ({ program, lesson, unit, gradeUri }: GameProps) => (
  <GameLibrary
    program={program}
    lesson={lesson}
    unit={unit}
    AsyncAssignmentModal={AsyncAssignmentModal}
    gradeUri={gradeUri}
  />
);

export default Games;
