import React, { Component } from 'react';
import Axios from 'axios';
import SysadminTable from 'common/SysadminTable';
import Row from './Row';
import Form from './Form';
import { ModalButton } from '../Utils';

const columns = [
  { text: 'Image', className: 'image' },
  { text: 'Name', className: 'name' },
  { text: 'Pkg Size', className: 'pkg-size' },
  { text: 'Unit', className: 'unit' },
  { text: 'Pkg Qty', className: 'pkg-qty' },
  { text: 'Box #', className: 'box-num' },
  { text: 'Location Detail', className: 'location-detail' },
  { text: 'Vendor Description', className: 'vendor-description' },
  { text: 'Vendor Part #', className: 'vendor-part' },
  { text: 'Unit Price', className: 'unit-price' },
  { text: 'In Refill Kit', className: 'in-refill-kit'},
  { text: 'Actions', className: 'actions' }
];

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <SysadminTable isLoading={this.state.isLoading}
                     containerClass="program-kit-materials"
                     tableId="kit-materials"
                     columns={columns}
                     buttonAbove={this.renderButtonAbove()}
                     emptyMessage="No kit materials found.">
        {this.renderMaterials()}
      </SysadminTable>
    );
  }

  renderButtonAbove() {
    return (
      <div>
        <ModalButton buttonText="Add Kit Material"
                     modalHeader="Add Kit Material"
                     buttonClass="action"
                     modalClass="add-kit-materials"
                     newRecord={true}
                     openModal={this.openModal.bind(this)}
                     closeModal={this.closeModal.bind(this)}
                     onAdd={this.addRow.bind(this)}
                     FormComponent={Form}
                     {...this.state} />
        <a href={this.props.previewUrl} target="_blank" className="action ml5">Preview</a>
      </div>
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getMaterials();
  }

  addRow(material) {
    this.setState(prevState => ({
      materials: [...prevState.materials, material].sort(this.sortByName)
    }));
  }

  updateRow(updatedMaterial) {
    this.setState({
      materials: this.state.materials.map(material =>
        updatedMaterial.id === material.id ? updatedMaterial : material
      ).sort(this.sortByName)
    });
  }

  getMaterials() {
    Axios.get('get_materials').then(response => {
      let { materials, forSelect, units } = response.data;
      this.setState({
        materials,
        forSelect,
        units,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  removeRow(id) {
    this.setState({
      materials: this.state.materials.filter(material => material.id !== id)
    });
  }

  sortByName(a, b) {
    return a.name.localeCompare(b.name);
  }

  renderMaterials() {
    return this.state.materials && this.state.materials.map((material) => {
      return (
        <Row key={material.id}
             programId={this.props.programId}
             forSelect={this.state.forSelect}
             units={this.state.units}
             removeRow={this.removeRow.bind(this)}
             updateRow={this.updateRow.bind(this)}
             {...material} />
      );
    });
  }
}

Table.defaultProps = {
  error: null,
  materials: [],
  safetyNotes: [],
  isLoading: false,
  modalIsOpen: false,
  previewUrl: '#'
};
