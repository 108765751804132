import React from 'react';

type LessonSubheaderProps = {
  children: ReactChildren,
  description?: string,
  title: string,
};

const LessonSubheader = ({
  children, title,
}: LessonSubheaderProps) => !!React.Children.count(children) && (
<div className="tw-ml-8 tw-mb-8">
  <h2>
    <div>{title}</div>
  </h2>
  <div className="tw-inline-flex">
    {children}
  </div>
</div>
);

export default LessonSubheader;
