import React from 'react';
import { components as Components, MenuProps } from 'react-select-5';

// react-select-5 custom component
interface ExtendMenuProps extends MenuProps {
  handleOpen: () => void;
}
const Menu = (
  { handleOpen, children, ...props } : ExtendMenuProps,
) => (
  <Components.Menu {...props}>
    <button
      type="button"
      className="tw-flex tw-justify-start tw-items-end tw-w-full tw-h-[1.75rem] tw-text-tci-purple tw-cursor-pointer
      tw-bg-white tw-border-none tw-text-[14px] tw-px-[10px]"
      onClick={handleOpen}
    >
      <i className="fa fa-plus tw-mr-[0.5rem]" />
      Add New Term
    </button>
    {children}
  </Components.Menu>
);

export default Menu;
