import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { INotebook } from '../../types';
import useGradesTableStore from '../../store/useGradesTableStore';

const useUnitProjects = () => {
  const unit = useGradesTableStore(state => state.unit);
  const isEnabled = !!unit?.id;

  const query = useQuery(['unitProjects', unit?.id], () => (
    axios.get<{ data: INotebook[] }>(Routes.plato_api_unit_projects_notebooks_path({
      unit_id: unit.id,
    })).then(res => res.data.data || [])
  ), { enabled: isEnabled, placeholderData: [] });

  return { ...query, isEnabled };
};

export default useUnitProjects;
