import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import styles from './Checkbox.module.scss';

export default function Checkbox({
  checked, handleChange, indeterminate, inTable, labelText, srOnly, name, value
}) {
  const setCheckboxRef = (checkbox) => {
    if (checkbox) checkbox.indeterminate = indeterminate;
  };

  function _renderLabelText() {
    let className = '';
    const sanitized = sanitizeHtml(labelText, {
      allowedTags: ['i', 'em']
    });

    if (srOnly) className = 'sr-only';

    return <span className={className} dangerouslySetInnerHTML={{ __html: sanitized }} />;
  }

  function handleInputChange() {
    handleChange(value);
  }

  return (
    <label className={`${styles.label} ${inTable ? 'tw-flex tw-items-start tw-gap-2' : ''}`}>
      <input
        className={inTable ? 'tw-mb-0 tw-mt-[2px]' : ''}
        name={name}
        value={value}
        onChange={handleInputChange}
        type="checkbox"
        checked={checked}
        ref={setCheckboxRef}
      />
      {_renderLabelText()}
    </label>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  inTable: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  srOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Checkbox.defaultProps = {
  checked: false,
  indeterminate: false,
  inTable: false,
  srOnly: false,
  value: ''
};
