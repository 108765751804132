import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AddImage from '../../../../../../../../../admin/ImageManager/Add';
import { insertIntoEditor } from
  '../../../../../../../../../admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/FroalaListeners';

const CustomImageModal = ({ editorInstance, questionId, modalManager }) => {
  useEffect(() => {
  });

  const imageTag = imageModel => (
    <img
      alt={imageModel.image.descriptionEn ? imageModel.image.descriptionEn : ''}
      className="imageInQuestion"
      data-snippet={imageModel.snippet}
      src={imageModel.image.imageUrl}
    />
  );

  return (
    <AddImage
      afterSubmit={imageModel => insertIntoEditor(editorInstance, imageTag(imageModel))}
      forFroala
      modalManager={modalManager}
      modelId={questionId}
      modelType="Question"
      mediaMeta={{ type: 'answer' }}
      standaloneButton={false}
    />
  );
};

CustomImageModal.propTypes = {
  editorInstance: PropTypes.object,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  questionId: PropTypes.number.isRequired,
};

CustomImageModal.defaultProps = {
  editorInstance: {},
};

export default CustomImageModal;
