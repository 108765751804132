import React from 'react';

export const GenericError = () => (
  <p>Something went wrong. Please refresh the page and try again.</p>
);

export const AuthorizationError = () => (
  <p>
    Cannot match students because one or more students are already connected to a different account.
    Contact <a href="mailto:info@teachtci.com">info@teachtci.com</a> for assistance.
  </p>
);
