import React from 'react';
import styles from './Cards.module.scss';

export default function Card(props) {
  return (
    <div className={props.wrapperClass}>
      <div className={styles.header}>
        {props.cardBody}
        </div>
      <div className={styles.bar}>
        {props.bar}
      </div>
      {props.children}
    </div>
  )
}
