import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '../RadioGroup';
import { CheckboxField } from 'common/Forms';

const InvestigationSettings = ({ checkboxProps, form, values, scienceProgram }) => {
  if (!scienceProgram) return null;

  return (
    checkboxProps ?
      <CheckboxField
        {...checkboxProps}
        checked={!values.investigation_notebook_off}
        label="Investigation Notebook"
        name="investigation_notebook_off"
      /> :
      <RadioGroup
        form={form}
        values={values}
        label="Investigation Notebook"
        name="investigation_notebook_off"
        negativeSetting
        onLabel="On"
        offLabel="Off"
        showDots={false}
        sectionHeaderClass="bold"
      />
  );
};

InvestigationSettings.propTypes = {
  checkboxProps: PropTypes.object,
  form: PropTypes.object.isRequired,
  scienceProgram: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired
};

export default InvestigationSettings;
