import React, { useState } from 'react';
import I18n from 'i18n';
import Axios from 'axios';
import { plato_api_settings_data_path, teacher_program_settings_path } from 'modules/routes';
import Tooltip from '../Tooltip';
import Modal, { useModalManager } from '../Modal';
import styles from './index.module.scss';
import { TeacherSettings, StudentFeaturesDataProps, GradesDataProps, StudentAccessDataProps } from './types';
import Spinner from '@/components/common/Spinner';
import StudentFeatures from '@/components/staffer/Settings/Forms/StudentFeatures';
import StudentAccess from '@/components/staffer/Settings/Forms/StudentAccess';
import GradesSettingsForm from '@/components/staffer/Settings/Forms/GradesSettingsForm';
import useProgramContext from '@/hooks/useProgramContext';

interface SettingsShortcutProps {
  settingsType: 'reading' | 'grades' | 'assignments';
};

type ComponentProps = StudentFeaturesDataProps | GradesDataProps | StudentAccessDataProps;

const SettingsShortcut = ({ settingsType }: SettingsShortcutProps) => {
  const modalManager = useModalManager();
  const [settings, setSettings] = useState<TeacherSettings | null>(null);
  const [componentProps, setComponentProps] = useState<ComponentProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { programId } = useProgramContext();

  const settingsIcon = () => (
    <button
      type="button"
      aria-haspopup="dialog"
      aria-controls="settingsShortcutModal"
      aria-label="Open Settings"
      className={`tw-text-tci-purple ${styles.icon}`}
      onClick={async (e) => {
        e.currentTarget.blur();
        await fetchData();
        modalManager.open();
      }}
    >
      <i
        className="fa fa-cog non-dropdown tw-text-tci-purple tw-text-[24px]"
        aria-hidden="true"
      />
      <span className="sr-only">{I18n.t('settings')}</span>
    </button>
  );

  const settingsLink = () => (
    <div className="tw-flex tw-items-center tw-justify-end">
      <a href={teacher_program_settings_path(programId)} target="_blank">
        See All Settings <i className="fa fa-external-link" aria-hidden="true" />
      </a>
    </div>
  );

  const fetchData = async () => {
    if (settings || componentProps) return;

    setIsLoading(true);
    Axios
      .get(plato_api_settings_data_path({ settings_type: settingsType, program_id: programId }))
      .then(res => {
        const { initialSettings, settingsProps } = res.data.data;
        setSettings(initialSettings);
        setComponentProps(settingsProps);
      })
      .catch(() => ({}))
      .finally(() => setIsLoading(false))
  };

  const updateSettings = ({ settings }) => {
    modalManager.close();
    setSettings(settings);
  };

  const onGradesSubmitSuccess = ({ fieldsChanged, settings }) => {
    if (['notebook_possible_points', 'section_possible_points'].find(attr => fieldsChanged.includes(attr))) {
      window.location.reload();
    } else updateSettings({ settings })
  }

  const headerText = () => {
    switch (settingsType) {
      case 'assignments': return 'Settings (Assignments)'
      case 'reading': return 'Settings (Reading)'
      case 'grades': return 'Settings (Grades)'
      default: return 'Settings'
    }
  };

  const renderModalBody = () => {
    if (isLoading) return <div className="tw-py-[50px]"><Spinner /></div>;
    if (!settings || !componentProps) return null;

    switch (settingsType) {
      case 'assignments':
        return (
          <StudentAccess
            {...componentProps}
            active
            handleCancel={modalManager.close}
            settings={settings}
            showLimitedSettings
            updateState={updateSettings}
          />
        );
      case 'reading':
        return (
          <StudentFeatures
            {...componentProps}
            active
            handleCancel={modalManager.close}
            settings={settings}
            showLimitedSettings
            updateState={() => window.location.reload()}
          />
        );
      case 'grades':
        return (
          <GradesSettingsForm
            {...componentProps as GradesDataProps}
            handleCancel={modalManager.close}
            onSubmitSuccess={onGradesSubmitSuccess}
            settings={settings}
          />
        );
    }
  }

  return (
    <Tooltip
      content={I18n.t('settings')}
      size="medium"
      distance="15"
      placement="bottom"
      interactive={false}
    >
      {settingsIcon()}

      <Modal
        id="settingsShortcutModal"
        role="dialog"
        aria-modal="true"
        aria-labelledby={I18n.t('settings')}
        bodyClassName="tw-pb-0"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        headerText={headerText()}
      >
        <div className={`tw-ml-4 ${styles.settingsShortcutModal}`}>
          {settingsLink()}

          {renderModalBody()}
        </div>
      </Modal>
    </Tooltip>
  );
};

export default SettingsShortcut;
