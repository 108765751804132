import React from 'react';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import SlideConditions from './SlideConditions';

const Wrapper = () => {
  const currentSlide = useSlideShowContext(slide => slide.currentSlide);

  if (!currentSlide) return null;

  return <SlideConditions slide={currentSlide} />;
};

export default Wrapper;
