import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdministratorsModal from 'common/AdministratorsModal';

import BtnLinkWithTooltip, { TooltipMessage } from './BtnLinkWithTooltip';
import { handleKeyPress } from './Utils';
import I18n from 'modules/i18n';

export function AllSeatsInUseMessageTeacher({
  getAdminInfoPath
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <p>
        All teacher licenses for your district’s programs are currently in use.
        {' '}
        Contact your
        {' '}
        <a
          className="admin-modal-button"
          role="button"
          tabIndex={0}
          onClick={() => setIsModalOpen(true)}
          onKeyPress={e => handleKeyPress(e, () => setIsModalOpen(true))}
        >
          admins
        </a>
        {' '}
        to get access to a program.
      </p>

      {isModalOpen && (
        <AdministratorsModal
          closeModal={() => setIsModalOpen(false)}
          getAdminInfoPath={getAdminInfoPath}
          isOpen={isModalOpen}
        />
      )}
    </div>
  );
}

AllSeatsInUseMessageTeacher.propTypes = {
  getAdminInfoPath: PropTypes.string.isRequired
};

export const AllSeatsInUseMessageAdmin = ({
  adminProgramsPath
}) => (
  <p>
    All teacher licenses for the programs are currently in use.
    Go to
    {' '}
    <a href={adminProgramsPath}>
      Programs
    </a>
    {' '}
    to view your district&apos;s current program usage and
    {' '}
    <a
      href="https://www.teachtci.com/add-remove-program-from-teacher"
      target="_blank"
      rel="noopener noreferrer"
    >
      manage
    </a>
    {' '}
    your staff&apos;s program assignments.

  </p>
);

AllSeatsInUseMessageAdmin.propTypes = {
  adminProgramsPath: PropTypes.string.isRequired
};

export const AllSeatsInUseMessage = ({
  userType, adminProgramsPath, getAdminInfoPath
}) => {
  if (userType === 'Admin') {
    return (
      <AllSeatsInUseMessageAdmin
        adminProgramsPath={adminProgramsPath}
      />
    );
  }

  return (
    <AllSeatsInUseMessageTeacher
      getAdminInfoPath={getAdminInfoPath}
    />
  );
};

AllSeatsInUseMessage.propTypes = {
  adminProgramsPath: PropTypes.string.isRequired,
  getAdminInfoPath: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired
};

export const DisabledMessage = ({
  manuallyManaged, userType, adminProgramsPath, getAdminInfoPath, adminDashPath
}) => {
  if (manuallyManaged) {
    return (
      <TooltipMessage
        getAdminInfoPath={getAdminInfoPath}
        userType={userType}
        adminDashPath={adminDashPath}
      />
    );
  }

  return (
    <AllSeatsInUseMessage
      userType={userType}
      adminProgramsPath={adminProgramsPath}
      getAdminInfoPath={getAdminInfoPath}
    />
  );
};

DisabledMessage.propTypes = {
  adminDashPath: PropTypes.string.isRequired,
  adminProgramsPath: PropTypes.string.isRequired,
  getAdminInfoPath: PropTypes.string.isRequired,
  manuallyManaged: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

const AddProgramsButton = ({
  adminDashPath, userType, disabled, getAdminInfoPath, addProgramsPath, manuallyManaged, adminProgramsPath
}) => (
  <div>
    <BtnLinkWithTooltip
      disabled={disabled}
      disabledMessage={(
        <DisabledMessage
          manuallyManaged={manuallyManaged}
          userType={userType}
          adminProgramsPath={adminProgramsPath}
          getAdminInfoPath={getAdminInfoPath}
          adminDashPath={adminDashPath}
        />
      )}
      getAdminInfoPath={getAdminInfoPath}
      path={addProgramsPath}
      buttonClasses="dropdown-item"
      faIcon="fa fa-plus"
      buttonText={I18n.t('add_programs')}
    />
  </div>
);

AddProgramsButton.propTypes = {
  addProgramsPath: PropTypes.string.isRequired,
  adminDashPath: PropTypes.string.isRequired,
  adminProgramsPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getAdminInfoPath: PropTypes.string.isRequired,
  manuallyManaged: PropTypes.bool,
  userType: PropTypes.string.isRequired
};

AddProgramsButton.defaultProps = {
  disabled: false,
  manuallyManaged: false
};

export default AddProgramsButton;
