import React, { Component } from 'react';
import Axios from 'axios';
import Select from 'react-select';
import PropTypes from 'prop-types';
import MaterialSelect from '../MaterialSelect';
import {
  FormRow, inputId, FormInput, FormCheckbox, FileInput
} from '../Utils';
import { Footer, Spinner } from '../../../common/Modal';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoading: false,
      selectedNotebook: this.props.newRecord ? this.props.unitNotebooks[0] : this.props.unitNotebooks.find(n => n.value === this.props.modelId)
    };
    this.changeHandout = this.changeHandout.bind(this);
    this.setDistribution = this.setDistribution.bind(this);
    this.setModule = this.setModule.bind(this);
    this.setMaterial = this.setMaterial.bind(this);
    this.setProgramKitMaterial = this.setProgramKitMaterial.bind(this);
    this.clearHandout = this.clearHandout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setUnit = this.setUnit.bind(this);
    this.setDisabledModules = this.setDisabledModules.bind(this);
    this.isSelectAllDisabled = this.isSelectAllDisabled.bind(this);
    this.enableAllModules = this.enableAllModules.bind(this);
  }

  componentDidMount() {
    this.initialState = this.state;
    if (this.props.modelType === 'LessonModule') {
      this.setState({ selectedModules: [this.props.modelId] });
    }

    if (!this.props.modelId && this.props.modelType === 'Notebook' && this.props.unitNotebooks.length) {
      this.setState({ modelId: this.props.unitNotebooks[0].value });
    }
  }

  // add disabled=true property to any module that is already associated with the selected material
  setDisabledModules() {
    const self = this;

    // if material is handout we will allow duplicates so dont disable
    if (this.materialIsHandout()) {
      this.enableAllModules();
      return;
    }

    // we dont want to disable the existing module-material association that we are editing
    const initialModuleId = this.initialState ? this.initialState.modelId : this.state.modelId;
    this.props.allModules.forEach((module) => {
      const disabledModules = self.state.material_modules[self.state.materialId];
      if (disabledModules && disabledModules.includes(module.value) && module.value !== initialModuleId) {
        module.disabled = true;
      }
      else {
        module.disabled = false;
      }
    });
  }

  setMaterial(e) {
    this.setState({
      materialId: e ? e.value : null,
      name: e ? e.label : null,
      modelUnitId: e && e.materialUnitId ? e.materialUnitId : null
    });
  }

  setDistribution(e) {
    this.setState({ distribution: e ? e.value : null });
  }

  setModule(e) {
    if (e && e.length > 0) {
      let modelId = [];
      let selectedModules = [];

      if (e.find(el => el.value === 'all')) {
        modelId = this.props.allModules.map(module => module.value);
        selectedModules = this.props.allModules.filter(module => !module.disabled);
      }
      else {
        modelId = e.map(module => module.value);
        selectedModules = e;
      }
      this.setState({
        modelType: 'LessonModule',
        modelId: modelId,
        selectedModules: selectedModules
      });
    }
    else {
      this.setState({
        modelType: 'Lesson',
        modelId: this.state.lessonId,
        selectedModules: []
      });
    }
  }

  setUnit(e) {
    this.setState({ modelUnitId: e ? e.value : null });
  }

  setProgramKitMaterial(e) {
    this.setState({ programKitMaterial: e });
  }

  enableAllModules() {
    this.props.allModules.forEach((module) => {
      module.disabled = false;
    });
  }

  isSelectAllDisabled() {
    return this.props.allModules.every(module => module.disabled);
  }

  clearForm() {
    this.setState(this.initialState);
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleCheck(e, stateName) {
    this.setState({
      [stateName]: e.target.checked
    });
  }

  handleQuantityChange(e, stateName) {
    const value_string = e.target.value.toString();

    if (value_string.includes('.')) {
      const separated_value = value_string.split('.');
      if (separated_value[1].length > 2) return;
    }

    if (e.target.value === '' || parseInt(e.target.value, 10) > 0) this.handleChange(e, stateName);
  }

  changeHandout(e) {
    this.setState({
      handout: e.target.files[0],
      removeHandout: false
    });
  }

  clearHandout(e) {
    e.target.previousSibling.value = null;
    this.setState({
      handout: null,
      removeHandout: true
    });
  }

  materialIsHandout() {
    return this.state.name === 'Handout';
  }

  _buildFormData() {
    const {
      modelType, modelId, materialId, quantity,
      distribution, additionalInfo, notes, optional, recyclable, programKitMaterial,
      reusable, handoutTitle, handout, removeHandout, modelUnitId, programId, lessonId
    } = this.state;

    const formData = new FormData();

    if (removeHandout) formData.append('model_material[delete_handout]', '1');
    if (handout) formData.append('model_material[handout]', handout);
    formData.append('model_material[model_type]', modelType);
    formData.append('model_material[model_id]', modelId || lessonId);
    formData.append('model_material[material_id]', materialId);
    formData.append('model_material[measurement_unit_id]', modelUnitId || null);
    formData.append('model_material[program_kit_material_id]', ((programKitMaterial && Object.keys(programKitMaterial).length > 0) ? programKitMaterial.value : ''));
    formData.append('model_material[quantity]', quantity);
    formData.append('model_material[distribution]', distribution);
    formData.append('model_material[additional_information]', additionalInfo);
    formData.append('model_material[notes]', notes || '');
    formData.append('model_material[optional]', optional);
    formData.append('model_material[recyclable]', recyclable);
    formData.append('model_material[reusable]', reusable);
    formData.append('model_material[handout_title]', handoutTitle);
    formData.append('model_material[program_id]', programId);

    return formData;
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const method = this.props.newRecord ? 'post' : 'put';
    const url = `/admin/model_materials/${this.props.newRecord ? '' : this.props.id}`;
    const callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate;
    const data = this._buildFormData();

    Axios({ method, url, data }).then((response) => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }

  renderUnitProjectsInput() {
    if (this.props.modelType !== 'Notebook') return;

    return (
      <FormRow>
        <label
          className="mt5"
          htmlFor="unit-projects-select"
        >
          Projects
        </label>
        <Select
          id="unit-projects-select"
          searchable
          required
          onChange={(notebook) => {
            this.setState({
              modelId: notebook.value,
              selectedNotebook: this.props.unitNotebooks.find(n => n.value === notebook.value)
            })
          }}
          value={this.state.selectedNotebook.value}
          options={this.props.unitNotebooks}
        />
      </FormRow>
    );
  }

  renderMaterialsInput() {
    return (
      <FormRow>
        <label htmlFor="material-select" className="mt5">Material</label>
        <MaterialSelect
          id="material-select"
          name="lesson_module_material[material_id]"
          value={this.state.materialId}
          onChange={this.setMaterial}
          options={this.props.forSelect}
        />
      </FormRow>
    );
  }

  renderUnitsInput() {
    return (
      <FormRow>
        <label
          className="mt5"
          htmlFor={inputId({
            label: 'units',
            newRecord: this.props.newRecord,
            id: this.props.id
          })}
        >
          Unit of Measurement
        </label>
        <Select
          searchable
          value={this.state.modelUnitId || ''}
          onChange={this.setUnit}
          options={[{ value: '', label: 'None' }].concat(this.props.units)}
        />
      </FormRow>
    );
  }

  renderProgramKitInput() {
    const pkm_options = this.props.programKitMaterials
      .filter(pkm => pkm.material_id === this.state.materialId)
      .map(pkm => ({ value: pkm.value, label: pkm.label }));

    return (
      <FormRow>
        <label
          className="mt5"
          htmlFor={inputId({
            label: 'program-kit-materials',
            newRecord: this.props.newRecord,
            id: this.props.id
          })}
        >
          Kit Location
        </label>
        <Select
          searchable
          value={(this.state.programKitMaterial && Object.keys(this.state.programKitMaterial).length > 0) ? this.state.programKitMaterial : ''}
          onChange={this.setProgramKitMaterial}
          options={[{ value: '', label: 'None' }].concat(pkm_options)}
        />
      </FormRow>
    );
  }

  renderModuleInput() {
    if (this.props.modelType === 'Notebook') return;

    this.setDisabledModules();
    return (
      <FormRow>
        <label
          className="mt5"
          htmlFor={inputId({
            label: 'module',
            newRecord: this.props.newRecord,
            id: this.props.id
          })}
        >
          Module
        </label>
        <Select
          searchable
          multi
          onChange={this.setModule}
          value={this.state.selectedModules || []}
          options={[{ label: 'All', value: 'all', disabled: this.isSelectAllDisabled() }].concat(...this.props.allModules)}
        />
      </FormRow>
    );
  }

  renderDistributionInput() {
    return (
      <FormRow>
        <label
          className="mt5"
          htmlFor={inputId({
            label: 'distribution',
            newRecord: this.props.newRecord,
            id: this.props.id
          })}
        >
          Distribution
        </label>
        <Select
          searchable
          required
          onChange={this.setDistribution}
          value={this.state.distribution}
          options={this.props.distributions}
        />
      </FormRow>
    );
  }

  renderHandoutFileInput() {
    return (
      <FileInput
        label="Handout PDF"
        required
        className="handout-pdf"
        id={this.props.id}
        removeFile={this.state.removeHandout}
        file={this.state.handout}
        fileName={this.state.handoutFileName}
        fileUrl={this.state.handoutUrl}
        handleChange={this.changeHandout}
        handleClear={this.clearHandout}
      />
    );
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">There was an error. Please reload the page and try again.</div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`materials-form ${this.state.isLoading ? 'loading' : ''}`}
      >
        {this.renderUnitProjectsInput()}
        {this.renderMaterialsInput()}
        {this.materialIsHandout() && (
        <FormInput
          label="handout title"
          stateName="handoutTitle"
          value={this.state.handoutTitle}
          id={this.props.id}
          required
          onChange={this.handleChange}
        />
        )}
        {this.materialIsHandout() && this.renderHandoutFileInput()}
        <FormInput
          label="quantity"
          inputType="number"
          value={this.state.quantity}
          required={!this.state.optional}
          id={this.props.id}
          step={0.01}
          onChange={this.handleQuantityChange}
        />
        {this.renderUnitsInput()}
        {this.renderDistributionInput()}
        {this.renderModuleInput()}
        {this.renderProgramKitInput()}
        <FormInput
          label="additional info"
          stateName="additionalInfo"
          value={this.state.additionalInfo}
          id={this.props.id}
          InputType="textarea"
          onChange={this.handleChange}
        />
        <FormInput
          label="notes"
          stateName="notes"
          value={this.state.notes}
          id={this.props.id}
          InputType="textarea"
          onChange={this.handleChange}
        />
        <FormCheckbox
          checked={this.state.optional}
          onChange={e => this.handleCheck(e, 'optional')}
          label="Optional"
        />
        <FormCheckbox
          checked={this.state.recyclable}
          onChange={e => this.handleCheck(e, 'recyclable')}
          label="Recyclable"
        />
        <FormCheckbox
          checked={this.state.reusable}
          onChange={e => this.handleCheck(e, 'reusable')}
          label="Reusable"
        />
        <Footer secondaryButtonCallback={this.closeModal} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }
}

Form.defaultProps = {
  additionalInfo: '',
  notes: '',
  allModules: [],
  closeModal: null,
  distribution: '',
  distributions: [],
  forSelect: [],
  handout: null,
  handoutFileName: '',
  handoutTitle: '',
  handoutUrl: '',
  id: null,
  lessonId: null,
  materialId: null,
  materials: null,
  modelId: null,
  modelType: null,
  modelUnitId: null,
  modules: [],
  newRecord: false,
  onAdd: null,
  onUpdate: null,
  optional: false,
  programKitMaterial: {},
  programKitMaterials: [],
  quantity: null,
  recyclable: false,
  removeHandout: false,
  reusable: false,
  selectedModules: [],
  unitNotebooks: [],
  units: [],
  material_modules: {}
};

Form.propTypes = {
  additionalInfo: PropTypes.string,
  notes: PropTypes.string,
  allModules: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  distribution: PropTypes.string,
  distributions: PropTypes.arrayOf(PropTypes.object),
  forSelect: PropTypes.arrayOf(PropTypes.object),
  // handout: PropTypes.,
  handoutFileName: PropTypes.string,
  handoutTitle: PropTypes.string,
  handoutUrl: PropTypes.string,
  id: PropTypes.number,
  lessonId: PropTypes.number,
  materialId: PropTypes.number,
  materials: PropTypes.arrayOf(PropTypes.object),
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  modelUnitId: PropTypes.number,
  modules: PropTypes.arrayOf(PropTypes.object),
  newRecord: PropTypes.bool,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  optional: PropTypes.bool,
  programKitMaterial: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  programKitMaterials: PropTypes.arrayOf(PropTypes.object),
  quantity: PropTypes.string,
  recyclable: PropTypes.bool,
  removeHandout: PropTypes.bool,
  reusable: PropTypes.bool,
  selectedModules: PropTypes.arrayOf(PropTypes.object),
  unitNotebooks: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.arrayOf(PropTypes.object),
  material_modules: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number))
};
