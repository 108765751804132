import FroalaConfig from 'froala/FroalaConfig';
import FroalaEditor from 'froala-editor';
import { convert } from 'html-to-text';
import {
  addElementInsertListeners,
  addEditAudioListeners,
  handleImagePaste,
} from './CustomFroalaCommands/FroalaListeners';
import {
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  ELEMENT_INSERT_COMMAND_NAME,
  CUSTOM_AUDIO_COMMAND_NAME,
  EQUATION_COMMAND_NAME,
  IMAGE_EDIT_COMMAND_NAME
} from '../../../../../shared/Froala/froalaConstants';
import { formatToolbarButtons, getImageEditButtons, TextEditorToolbarId } from './Toolbar';
import { updateHtmlElement } from './AnchoredDropdown/ColorMenu';

const applyColorToLiElements = (slideObject) => {
  const froalaEditorContainer = document.getElementById(slideObject.id);
  const liElementsArray = froalaEditorContainer.getElementsByTagName('li');

  for (let i = 0; i < liElementsArray.length; i++) {
    if (liElementsArray[i].childNodes[0].tagName) {
      if (liElementsArray[i].childNodes[0].tagName.toLowerCase() === 'span') {
        liElementsArray[i].style.color = getComputedStyle(liElementsArray[i].childNodes[0]).color;
      }
    }
  }
};

const setupButtonMaps = (map, button) => {
  map.set(button.dataset.htmlElementId, button.dataset.backgroundColor);
};

const compareButtons = (previousButtons, currentButtons) => {
  let currentBackgroundColor;

  // return if the maps are different size.
  if (previousButtons.size !== currentButtons.size) {
    return;
  }
  previousButtons.forEach((backgroundColor, htmlElementId) => {
    currentBackgroundColor = currentButtons.get(htmlElementId);
    // If no matching color is found for current given htmlElementId or if HtmlElementId is not found in current buttons
    if (currentBackgroundColor === undefined || !currentButtons.has(htmlElementId)) return;

    // If background colors exist and do not match, update button with the previous background color.
    if (currentBackgroundColor !== backgroundColor) {
      updateHtmlElement(htmlElementId, backgroundColor);
    }
  });
};

const handleColorChange = (editor, commandName) => {
  const indexOffset = commandName === 'redo' ? 2 : 0;
  const parser = new DOMParser();

  // HTML from undo_stack that contains our desired colors.
  const targetState = parser.parseFromString(editor.undo_stack[editor.undo_index - 1].html, 'text/html');

  // Our current button colors. Position in stack depends on undo_index and whether or we're redoing or undoing changes.
  const currentState = parser.parseFromString(editor.undo_stack[editor.undo_index - indexOffset].html, 'text/html');

  const prevButtons = targetState.querySelectorAll('[data-html-element-id]');
  const currentButtons = currentState.querySelectorAll('[data-html-element-id]');

  // Skip color update if:
  // - No buttons on slide.
  if (!currentButtons.length || !prevButtons.length) return;

  const prevButtonsMap = new Map();
  const currentButtonsMap = new Map();

  prevButtons.forEach((button) => {
    setupButtonMaps(prevButtonsMap, button);
  });

  currentButtons.forEach((button) => {
    setupButtonMaps(currentButtonsMap, button);
  });

  compareButtons(prevButtonsMap, currentButtonsMap);
};

const triggerKeys = ['Backspace', 'Delete', 'Enter'];

const TextEditorEvents = ({
  elementInsertModalManager, addImageModalManager, addVideoModalManager,
  setEditorInstance, setEditMode, setElementParent, setPastedImage, slideObject,
  audioModalManager, setSlideSoundClipId, setAudioButtonToEdit, setHtmlElementId,
  setHasUnsavedChanges, equationModalManager, editImageModalManager, setImageModel,
}) => ({
  // eslint-disable-next-line func-names
  blur: function() {
    const editor = this;
    if (editor.codeView.isActive()) return;

    editor.$el.css('background', 'transparent');
    editor.toolbar.hide();
  },
  // eslint-disable-next-line func-names
  'commands.after': function(commandName) {
    switch (commandName) {
      case ELEMENT_INSERT_COMMAND_NAME:
        this.selection.save();
        elementInsertModalManager.open();
        break;
      case CUSTOM_IMAGE_COMMAND_NAME:
        this.selection.save();
        addImageModalManager.open();
        break;
      case CUSTOM_VIDEO_COMMAND_NAME:
        this.selection.save();
        addVideoModalManager.open();
        break;
      case CUSTOM_AUDIO_COMMAND_NAME:
        this.selection.save();
        audioModalManager.open();
        break;
      case EQUATION_COMMAND_NAME:
        this.selection.save();
        equationModalManager.open();
        break;
      case IMAGE_EDIT_COMMAND_NAME:
        this.selection.save();
        editImageModalManager.open();
        this.popups.hide('image.edit');
        break;
      case 'textColor': case 'formatUL': case 'formatOL':
        applyColorToLiElements(slideObject);
        break;
      case 'redo': case 'undo': {
        handleColorChange(this, commandName);
        break;
      }
      default:
        break;
    }
  },
  // eslint-disable-next-line func-names
  focus: function() {
    const editor = this;
    editor.$el.css('background', 'rgba(255, 255, 255, 0.6)');
    if (editor.toolbar) editor.toolbar.show();
  },
  // eslint-disable-next-line func-names
  initialized: function() {
    const editor = this;
    editor.el.blur();
    editor.toolbar.hide();
    setEditorInstance(this);
    addElementInsertListeners({
      editor,
      elementInsertModalManager,
      froalaRef: editor.$el,
      setEditMode,
      setElementParent,
      setHtmlElementId,
      setImageModel,
    });
    addEditAudioListeners(editor.$el, setSlideSoundClipId, setAudioButtonToEdit, audioModalManager);
  },
  // Catch specific modifier/non-printing keys for modal.
  keydown: (e) => {
    if (triggerKeys.includes(e.key)) {
      setHasUnsavedChanges(true);
    }
  },
  // Only catches "printing keys".
  keypress: () => {
    setHasUnsavedChanges(true);
  },
  'paste.before': (pasteEvent) => {
    handleImagePaste(pasteEvent, setPastedImage, addImageModalManager);
  },
  'paste.beforeCleanup': (clipboardHTML) => {
    const convertedHTML = convert(clipboardHTML, {
      selectors: [
        { selector: 'a', options: { ignoreHref: true } },
        { selector: 'img', format: 'skip' },
      ],
    });

    return convertedHTML.replace(/\n/g, '<br id="isPasted">');
  },
});

const TextEditorConfig = ({
  elementInsertModalManager, addImageModalManager, addVideoModalManager,
  setEditorInstance, setEditMode, setElementParent, setPastedImage, slideObject,
  audioModalManager, setSlideSoundClipId, setAudioButtonToEdit, userType, setHtmlElementId,
  setHasUnsavedChanges, equationModalManager, editImageModalManager, setImageModel,
}) => FroalaConfig({
  allowHTML: true,
  customConfig: {
    charCounterCount: false,
    enter: FroalaEditor.ENTER_BR,
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
    height: '100%',
    htmlAllowedEmptyTags: ['span', 'i', 'textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr'],
    imageEditButtons: getImageEditButtons(userType),
    imagePaste: false, // disable pasting of images for now while froala bug isn't fixed
    initOnClick: false,
    paragraphStyles: {
      builder_lesson_eq: 'Lesson Essential Question',
      builder_lesson_title: 'Lesson Title',
      builder_trans_black_bkg: 'Transparent Black Background',
      builder_trans_white_bkg: 'Transparent White Background',
      builder_unit_title: 'Unit Title',
    },
    quickInsertTags: [],
    toolbarButtons: formatToolbarButtons(userType),
    toolbarContainer: `#${TextEditorToolbarId}`,
    videoEditButtons: ['videoRemove', '|', 'videoDisplay', 'videoAlign', 'videoSize'],
    width: '100%',
  },
  events: TextEditorEvents({
    addImageModalManager,
    addVideoModalManager,
    audioModalManager,
    elementInsertModalManager,
    equationModalManager,
    setAudioButtonToEdit,
    setEditMode,
    setEditorInstance,
    setElementParent,
    setHasUnsavedChanges,
    setHtmlElementId,
    setPastedImage,
    setSlideSoundClipId,
    slideObject,
    editImageModalManager,
    setImageModel,
  }),
});

export default TextEditorConfig;
