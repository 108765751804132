import React from 'react';
import useAssignablesStore from './store/useAssignablesStore';
import AssessmentSelector from './AssignableModels/AssessmentSelector';
import GameSelector from './AssignableModels/GameSelector';
import ReadingAssignablesSelector from './AssignableModels/ReadingAssignablesSelector';
import SlideShowSelector from './AssignableModels/SlideShowSelector';
import InvestigationSelector from './AssignableModels/InvestigationSelector';
import ReferenceMaterialSelector from './AssignableModels/ReferenceMaterialSelector';
import { ReferenceMaterialTypes } from '@/components/interfaces/ReferenceMaterial';

const AssignableModelsSelector = ({ form }) => {
  const selectedType = useAssignablesStore(state => state.selectedType);
  switch (selectedType) {
    case 'assessment':
      return <AssessmentSelector />;
    case 'games':
      return <GameSelector />;
    case 'unitProjects':
    case 'sectionNotebook':
      return <ReadingAssignablesSelector form={form} />;
    case 'videoActivity':
    case 'lessonOutline':
    case 'investigationActivity':
    case 'classroomActivity':
      return <SlideShowSelector form={form} />;
    case 'investigation':
      return <InvestigationSelector form={form} />;
    case ReferenceMaterialTypes.BIOGRAPHIES_TYPE:
    case ReferenceMaterialTypes.CAREER_PROFILES_TYPE:
    case ReferenceMaterialTypes.CIVICS_TYPE:
    case ReferenceMaterialTypes.MAPS_TYPE:
    case ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE:
    case ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE:
    case ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE:
      return <ReferenceMaterialSelector selectedType={selectedType} />;
    default:
      return null;
  }
};

export default AssignableModelsSelector;
