import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from '@/components/common/ActionsDropdown';
import EditVideo from './EditVideo';
import DeleteVideo from './DeleteVideo';
import SelectPoster from './SelectPoster';

export default function VideosDropdown({ afterEdit, afterDelete, rowData }) {
  const dropdownButtonClass = 'dropdown-item full-size';

  return (
    <ActionsDropdown>
      <button
        type="button"
        className={`${dropdownButtonClass} js-clipboard-trigger`}
        data-clipboard-text={rowData.url}
      >
        <i className="fa fa-link" aria-hidden="true" /> Copy Video URL
      </button>
      <a
        className="dropdown-item"
        href={rowData.url}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        <i className="fa fa-download" aria-hidden="true" /> Download Video
      </a>
      <EditVideo
        video={rowData}
        afterSubmit={afterEdit}
      />
      <DeleteVideo
        videoId={rowData.id}
        afterSubmit={afterDelete}
        referenceCount={rowData.reference_count}
      />
      <SelectPoster
        afterSubmit={afterEdit}
        videoId={rowData.id}
      />
    </ActionsDropdown>
  );
}

VideosDropdown.propTypes = {
  afterDelete: PropTypes.func.isRequired,
  afterEdit: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number,
    needs_captions: PropTypes.bool,
    reference_count: PropTypes.number,
    url: PropTypes.string,
    video_file_name: PropTypes.string,
  }),
};

VideosDropdown.defaultProps = {
  rowData: { needs_captions: false },
};
