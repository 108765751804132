import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import moment from 'moment-timezone';
import * as Routes from 'routes';
import showToast from 'common/Toast';
import { Footer } from '../../common/Modal/index';
import { renderErrors, SubmitError } from '../../common/Forms/Utils';
import WhatSection from './WhatSection';
import WhenSection from './WhenSection';
import WhoSection from './WhoSection';
import NotesSection from './NotesSections';
import googleClassroomLogo from '../classrooms/Table/ConnectClassroom/google_logo.svg';
import canvasLogo from '../classrooms/Table/ConnectClassroom/canvas_logo.svg';
import {
  classroomsPropType, assessmentsPropType,
  assignmentInitialValuesPropType
} from './PropTypes';
import styles from './AssignmentForm.module.scss';
import { buildAssignmentsModels } from './Utils';
import { pollEndpoint } from '@/modules/TCIUtils';

export default class AssignmentForm extends Component {
  static propTypes = {
    assessments: assessmentsPropType,
    assessmentsPagePath: PropTypes.string.isRequired,
    assignmentsPath: PropTypes.string.isRequired,
    classrooms: classroomsPropType,
    closeModal: PropTypes.func.isRequired,
    connectedToCanvas: PropTypes.bool,
    connectedToCanvas13: PropTypes.bool,
    connectedToGoogleClassroom: PropTypes.bool,
    handlePostSave: PropTypes.func.isRequired,
    initialLessonId: PropTypes.number,
    initialValues: assignmentInitialValuesPropType,
    loadingInitialValues: PropTypes.bool,
    newRecord: PropTypes.bool,
    program: PropTypes.shape({
      display_investigations: PropTypes.bool.isRequired,
      display_investigations_text_section: PropTypes.bool.isRequired,
      display_unit_content: PropTypes.bool.isRequired,
      hasVideoActivity: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      middle_school: PropTypes.bool.isRequired,
      science: PropTypes.bool.isRequired
    }).isRequired,
    programId: PropTypes.number,
    score: PropTypes.number,
    stafferId: PropTypes.number.isRequired,
    subscriberConnectedToCanvas: PropTypes.bool,
    updatePath: PropTypes.string
  };

  static defaultProps = {
    assessments: [],
    classrooms: [],
    connectedToCanvas: false,
    connectedToCanvas13: false,
    connectedToGoogleClassroom: false,
    initialLessonId: null,
    initialValues: {},
    loadingInitialValues: false,
    newRecord: true,
    score: 0,
    subscriberConnectedToCanvas: false,
    updatePath: ''
  };

  static formHandleSelect(args, state, utils) {
    const value = args[0] === null ? null : args[0].value;
    AssignmentForm.formChangeValue(args, state, utils, value);
  }

  static formChangeValue(args, state, utils, newValue) {
    const value = newValue || args[0];
    utils.changeValue(state, args[1], () => value);
  }

  static validate(values) {
    const errors = {};
    const noModelsToAssign = !(values.modelsToAssign && values.modelsToAssign.length);
    const noNotebookAndReadingToAssign = Object.keys(values)?.filter(key => key.includes('notebook-reading'))?.length === 0;

    if (noModelsToAssign && noNotebookAndReadingToAssign) {
      errors.modelsToAssign = 'Choose at least one item to assign.';
    }

    return errors;
  }

  constructor(props) {
    super(props);

    this.state = {};

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setShouldShowLmsInfo = this.setShouldShowLmsInfo.bind(this);
  }

  getInitialValues() {
    const initialValues = Object.assign(
      { modelsToAssign: [], lesson_id: this.props.initialLessonId },
      this.props.initialValues
    );

    if (this.props.newRecord) {
      initialValues.classroom_ids = this.props.classrooms.map(classroom => classroom.id.toString());
      initialValues.student_ids = [];
      initialValues.start_datetime = moment().utc().format('YYYY-MM-DD H:mm:ss UTC');
      initialValues.lesson_id = this.props.initialLessonId;
    }
    else if (Object.keys(this.props.initialValues).length) {
      if (this.props.initialValues.classroomAssignment) {
        initialValues.classroom_ids = this.props.initialValues.assignee_model_ids.map(id => id.toString());

        // if a classroom assignment has only been assigned to one classroom, select all students in the student selector
        if (this.props.initialValues.assignee_model_ids.length === 1) {
          initialValues.student_ids = this.props.initialValues.classroom_student_ids.map(id => id.toString());
        }
        else {
          initialValues.student_ids = [];
        }
      }
      else {
        initialValues.classroom_ids = this.props.initialValues.student_classroom_ids.map(id => id.toString());
        // If the assignment has only been assigned to one classroom, populate the student selector with the individual assignees
        if (this.props.initialValues.student_classroom_ids.length === 1) {
          initialValues.student_ids = this.props.initialValues.assignee_model_ids.map(id => id.toString());
        }
        else {
          initialValues.student_ids = [];
        }
      }

      const dueDateTime = moment(this.props.initialValues.due_datetime, 'MM/DD/YYYY, H:mm A');
      initialValues.due_datetime = dueDateTime.utc().format('YYYY-MM-DD H:mm:ss UTC');

      const startDateTime = moment(this.props.initialValues.start_datetime, 'MM/DD/YYYY, H:mm A');
      initialValues.start_datetime = startDateTime.utc().format('YYYY-MM-DD H:mm:ss UTC');
      initialValues.randomize_answers = this.props.initialValues.randomize_answers;
    }

    return initialValues;
  }

  setShouldShowLmsInfo(value) {
    this.setState({ showLmsInfo: value });
  }

  handleSubmit(values) {
    const requestData = this.buildRequestData(values);

    if (this.props.newRecord) {
      return axios
        .post(this.props.assignmentsPath, requestData)
        .then((response) => {
          const { data } = response;
          if (data.assessment_copy_in_progress) {
            return this.pollForCompletion(data);
          }
          else {
            showToast('Assignment created!');
            this.props.handlePostSave(data.data, 'create');
            this.props.closeModal();
          }
        })
        .catch(error => ({
          [FORM_ERROR]: renderErrors(error.response.data.errors)
        }));
    }

    return axios
      .patch(this.props.updatePath.replace(':id', this.props.initialValues.assignment_id), requestData)
      .then((response) => {
        showToast('Assignment edited!');
        this.props.handlePostSave(response.data.data, 'update');
        this.props.closeModal();
      })
      .catch(error => ({
        [FORM_ERROR]: renderErrors(error.response.data.errors)
      }));
  }

  pollForCompletion(data) {
    return pollEndpoint({
      endpointUrl: Routes.plato_api_assessment_path(data.new_assessment_id),
      interval: 1000,
      until: response => !response.data[Object.keys(response.data)[0]].copying
    })
      .then(() => {
        showToast('Assignment created!');
        this.props.closeModal();
        window.location.reload();
      })
      .catch(response => {
        if (response.toString().includes('timed out')) {
          showToast('Assignment creation will finish shortly.', { autoClose: 3000 });
        }
        this.props.closeModal();
      });
  }

  formatNotebookAndReading(values) {
    return Object.keys(values)
      .filter((key) => key.includes('notebook-reading'))
      .map((key) => values[key])
      .filter(val => val && val.length > 0);
  }

  buildRequestData(values) {
    const assignment = {
      dates_enforced: values.dates_enforced,
      due_datetime: values.due_datetime,
      name: values.name,
      notes: values.notes,
      program_id: this.props.programId || this.props.program.id,
      randomize_answers: values.randomize_answers || false,
      start_datetime: values.start_datetime,
    };

    const notebookAndReadingToAssign = this.formatNotebookAndReading(values);

    let formattedModelsToAssign;

    // if assigning multiple things
    if (values.type === 'sectionNotebook' && notebookAndReadingToAssign.length > 0) {
      formattedModelsToAssign = notebookAndReadingToAssign;
    } else if (Array.isArray(values.modelsToAssign)) {
      formattedModelsToAssign = [...values.modelsToAssign]
    }
    else {
      formattedModelsToAssign = [values.modelsToAssign];
    }

    return {
      assignment,
      assignments_models: buildAssignmentsModels(formattedModelsToAssign.filter(toAssign => toAssign !== undefined).flat(), values.lesson_id),
      classroom_ids: values.classroom_ids,
      student_ids: values.student_ids
    };
  }

  shouldRenderDraftMessage() {
    if (!this.props.newRecord) return false;
    if (this.props.connectedToGoogleClassroom || this.props.connectedToCanvas13) return true;

    return this.props.subscriberConnectedToCanvas && this.props.connectedToCanvas;
  }

  renderDraftMessage() {
    if (!this.shouldRenderDraftMessage()) return '';

    const articleLink = this.props.connectedToCanvas ?
      'https://www.teachtci.com/canvas-draft-assignments' : 'https://www.teachtci.com/google-draft-assignments';

    return (
      <span className={styles.draftMessage}>
        <img className={styles.lmsLogo} alt="" src={this.props.connectedToCanvas ? canvasLogo : googleClassroomLogo} />

        A draft assignment will be created in your connected {' '}
        {this.props.connectedToCanvas ? 'Canvas Course(s)' : 'Google Classroom(s)'}. {' '}
        <a
          href={articleLink}
          className={styles.lmsLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn More
        </a>
      </span>
    );
  }

  renderForm({
    dirtySinceLastSubmit, errors, form, handleSubmit, hasSubmitErrors,
    hasValidationErrors, initialValues, submitError, submitting
  }) {
    return (
      <form onSubmit={handleSubmit} className={styles.form} role="dialog" id="CreateAssignmentModal" aria-modal="true" aria-labelledby="Create Assignment">
        <div className={styles.sectionsContainer}>
          <div className={styles.columns}>
            <WhatSection
              assessments={this.props.assessments}
              assessmentsPagePath={this.props.assessmentsPagePath}
              displayInvestigations={this.props.program.display_investigations}
              displayInvestigationsAsText={this.props.program.display_investigations_text_section}
              form={form}
              formChangeValue={form.mutators.changeValue}
              formHandleSelect={form.mutators.handleSelect}
              hasVideoActivity={this.props.program.hasVideoActivity}
              initialLessonId={this.props.initialLessonId}
              initialValues={initialValues}
              isMiddleSchoolProgram={this.props.program.middle_school}
              isScienceProgram={this.props.program.science}
              programID={this.props.programId || this.props.program.id}
              score={this.props.score}
              showUnitContent={this.props.program.display_unit_content}
              stafferId={this.props.stafferId}
            />
            <div>
              <WhoSection
                classrooms={this.props.classrooms}
                loadingInitialValues={this.props.loadingInitialValues}
                formChangeValue={form.mutators.changeValue}
                initialValues={initialValues}
                setShouldShowLmsInfo={this.setShouldShowLmsInfo}
              />

              <WhenSection
                errors={errors}
                initialValues={initialValues}
                formHandleSelect={form.mutators.handleSelect}
                newRecord={this.props.newRecord}
                modelType={form.getState().values.type}
              />

              <NotesSection
                initialValues={initialValues}
              />
            </div>
          </div>
          <SubmitError error={submitError} />
        </div>

        <Footer
          secondaryButtonCallback={this.props.closeModal}
          primaryButtonText="Assign"
          primaryButtonDisabled={
            hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit)
          }
          disableWithTooltipText="Please complete all required fields marked with *."
          submitting={submitting}
          wrapperClassName={styles.footer}
        >
          {this.state.showLmsInfo && this.renderDraftMessage()}
        </Footer>
      </form>
    );
  }

  render() {
    return (
      <Form
        initialValues={this.getInitialValues()}
        keepDirtyOnReinitialize
        mutators={{
          changeValue: AssignmentForm.formChangeValue,
          handleSelect: AssignmentForm.formHandleSelect
        }}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
        validate={AssignmentForm.validate}
      />
    );
  }
}
