import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { SubmitError } from './Utils';

export default class CheckboxGroup extends Component {
  static propTypes = {
    alignLeft: PropTypes.bool,
    bulkSelectText: PropTypes.string,
    checkboxLabelSrOnly: PropTypes.bool,
    checkboxRowStyle: PropTypes.string,
    className: PropTypes.string,
    formChangeValue: PropTypes.func.isRequired, // React Final Form mutator
    handleChange: PropTypes.func,
    hasBulkSelect: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]).isRequired
    }).isRequired,
    inTable: PropTypes.bool,
    legendSrOnly: PropTypes.bool,
    legendText: PropTypes.string,
    meta: PropTypes.shape({
      error: PropTypes.string,
      pristine: PropTypes.bool,
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired,
    showErrorMessage: PropTypes.bool
  };

  static defaultProps = {
    alignLeft: false,
    bulkSelectText: 'Select all',
    checkboxLabelSrOnly: false,
    checkboxRowStyle: '',
    className: '',
    handleChange: () => {},
    hasBulkSelect: false,
    inTable: false,
    legendSrOnly: false,
    legendText: '',
    showErrorMessage: true
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.input.value
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) this.setValue();
  }

  setValue() {
    this.setState({ value: this.props.input.value });
  }

  allSelected() {
    return this.state.value.length === this.props.options.filter(opt => opt !== null).length;
  }

  handleChange(value) {
    const updatedSet = new Set(this.state.value);

    if (value === 'bulk-select') {
      if (this.allSelected()) {
        updatedSet.clear();
      }
      else {
        this.props.options.map((option) => {
          if (option) return updatedSet.add(option.value);

          return;
        });
      }
    }
    // handle individual checkbox
    else if (updatedSet.has(value)) updatedSet.delete(value);
    else updatedSet.add(value);

    const newValue = Array.from(updatedSet);
    this.setState({ value: newValue });
    this.props.handleChange(newValue);
    this.props.formChangeValue(newValue, this.props.input.name);
  }

  renderLegend() {
    const text = this.props.legendSrOnly ?
      <span className="sr-only">{this.props.legendText}</span> : this.props.legendText;

    return <legend>{text}</legend>;
  }

  renderBulkSelectCheckbox() {
    if (!this.props.hasBulkSelect) return null;

    const bulkCheckbox = (
      <Checkbox
        checked={this.allSelected()}
        handleChange={this.handleChange}
        indeterminate={
          Array.isArray(this.props.input.value) &&
          this.props.input.value.length > 0 &&
          this.props.input.value.length < this.props.options.filter(opt => !!opt).length
        }
        key={`${this.props.input.name}-bulk-select`}
        labelText={this.props.bulkSelectText}
        inTable={this.props.inTable}
        name={this.props.input.name}
        value="bulk-select"
      />
    )

    if (this.props.inTable) {
      const classes = this.props.alignLeft ? 'tw-pr-4' : '';

      return <td className={`tw-align-top tw-py-2 ${classes}`}>{bulkCheckbox}</td>;
    }

    return (
      <div className={this.props.checkboxRowStyle}>
        {bulkCheckbox}
      </div>
    );
  }

  renderCheckboxes() {
    const checkboxes = this.props.options.map(option => {
      let checkbox;

      if (option) {
        checkbox = (
          <Checkbox
            checked={this.state.value.includes(option.value)}
            handleChange={this.handleChange}
            labelText={option.label}
            name={this.props.input.name}
            srOnly={this.props.checkboxLabelSrOnly}
            value={option.value}
          />
        );
      }

      if (this.props.inTable) {
        const classes = this.props.alignLeft ? '' : 'tw-text-center tw-px-5';

        return <td className={`tw-align-top ${classes}`}><span className={this.props.alignLeft ? 'tw-p-2' : ''}>{checkbox}</span></td>;
      }

      return (
        <div className={this.props.checkboxRowStyle} key={`${this.props.input.name}-${option.value}`}>
          {checkbox}
        </div>
      );
    });

    return (this.props.hasBulkSelect && !this.props.inTable) ?
      <div className="ml15">{checkboxes}</div> : checkboxes;
  }

  renderError() {
    if (this.props.showErrorMessage) {
      const { error, pristine } = this.props.meta;
      if (!pristine && error) return <SubmitError error={error} />;
    }

    return null;
  }

  render() {
    if (this.props.inTable) {
      return (
        <tr className={this.props.className}>
          {this.renderBulkSelectCheckbox()}
          {this.renderCheckboxes()}
          {this.renderError()}
        </tr>
      )
    }

    return (
      <fieldset className={this.props.className}>
        {this.renderLegend()}
        {this.renderBulkSelectCheckbox()}
        {this.renderCheckboxes()}
        {this.renderError()}
      </fieldset>
    );
  }
}
