import * as Routes from 'routes';
import React from 'react';

export const openAuthPopup = () => (
  new Promise((resolve, reject) => {
    window.onmessage = ({ data }) => {
      if (data.authorizationSucceeded) {
        resolve(data);
      }
      else {
        const error = new Error(data.reason);
        reject(error);
      }
    };

    window.open(Routes.authorize_google_classroom_index_path(), '', 'width=450,height=600');
  })
);

export const missingScopesMessage = (
  <div>
    <p className="mt5">
      Please allow TCI to access all Google permissions in the pop-up window.<br />
      <a
        href="https://www.teachtci.com/google-classroom/account-connection"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more.
      </a>
    </p>
  </div>
);

export const duplicateKeyMessage = (
  <div>
    <p className="mt5">
      The Google account you are attempting to connect is already connected to a different TCI account.
      Please disconnect the Google account or use a different Google account.
    </p>
    <a href="https://www.teachtci.com/google-connected-account" target="_blank" rel="noopener noreferrer">Learn More</a>
  </div>
);

export const reconnectAccountMessage = programId => (
  <p>
    Oops! Something went wrong. Please reconnect your Google account in&nbsp;
    <a href={Routes.teacher_program_settings_path(programId, { initial_active_tab: 'integrations' })}>
      Settings
    </a>
    &nbsp;and try again.
  </p>
);
