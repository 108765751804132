import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'modules/i18n';
import cn from '@/utils/cn';

interface ActionsDropdownProps {
  children: ReactChildren;
  dropdownClassName?: string;
  id?: string;
  position?: number;
  toggleClassName?: string;
  toggleText?: string;
}

const ActionsDropdown = ({
  children,
  dropdownClassName = '',
  id,
  position,
  toggleClassName,
  toggleText,
}: ActionsDropdownProps) => (
  <div className="dropdown">
    <button
      id={id}
      type="button"
      className={`dropdown-toggle btn ${toggleClassName} ${position}`}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {toggleText}
    </button>

    <div className={cn('dropdown-menu', dropdownClassName)} aria-labelledby={id}>
      {children}
    </div>
  </div>
);

ActionsDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  position: PropTypes.string,
  toggleClassName: PropTypes.string,
  toggleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ActionsDropdown.defaultProps = {
  id: 'dropdownMenuButton',
  position: '',
  toggleClassName: 'btn--outline-purple',
  toggleText: `${I18n.t('actions')}`,
};

export default ActionsDropdown;
