const tippy = require('tippy.js');

tippy.default.setDefaults({
  animateFill: false,
  arrow: true,
  hideOnClick: 'toggle',
  interactive: true,
  theme: 'white'
});

export default tippy;
