import React, { useContext, useLayoutEffect, useState } from 'react';
import DndAnswerContext from 'components/common/Context';
import I18n from 'i18n-js';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import Answer from '../index';
import styles from './DraggableChoicesBank.module.scss';
import Modal from '../../../../../Modal';

const AnswerKeyButton = () => {
  const { locale, parentContainerId, question } = useContext(DndAnswerContext);
  const isPresentMode = useSlideShowStore(state => state.isPresentMode);
  const [isOpen, setIsOpen] = useState(false);
  const [parentNode, setParentNode] = useState(null);

  const parent = isPresentMode ? '.slide' : '.current-slide';

  useLayoutEffect(() => {
    setParentNode(document.querySelector(parent));

    return () => setParentNode(null);
  }, []);

  if (!parentNode) return null;

  return (
    <>
      <button
        className={`${styles.answerKeyButton} btn btn--sm btn--outline-orange mt5`}
        onClick={() => setIsOpen(prev => !prev)}
        type="button"
      >
        {I18n.t('show_answer_key')}
      </button>
      <Modal
        overlayClassName={styles.answerKeyModal}
        closeModal={() => setIsOpen(false)}
        headerClass={styles.answerKeyModalHeader}
        headerText=""
        isOpen={isOpen}
        nearlyFullScreen
        parentSelector={() => parentNode}
      >
        <Answer locale={locale} parentContainerId={parentContainerId} question={question} showAnswerKey />
      </Modal>
    </>
  );
};

export default AnswerKeyButton;
