import React from 'react';
import PropTypes from 'prop-types';
import { modalManagerPropTypes } from 'common/Modal';
import MutationModal from '../MutationModal';
import { CREATE_MODEL_VIDEO } from '../Utils';

const AddVideo = ({ ...props }) => {
  return (
    <MutationModal
      afterSubmit={props.afterSubmit}
      fromButton={props.fromButton}
      modalManager={props.modalManager}
      modelId={props.modelId}
      modelType={props.modelType}
      mutation={CREATE_MODEL_VIDEO}
      onYoutubeSubmit={props.onYoutubeSubmit}
      showYoutubeOption={props.showYoutubeOption}
      standaloneButton={props.standaloneButton}
      userType={props.userType}
      youtubeSubmitLoading={props.youtubeSubmitLoading}
    />
  );
};

AddVideo.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  fromButton: PropTypes.bool,
  modalManager: modalManagerPropTypes,
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelType: PropTypes.string.isRequired,
  showYoutubeOption: PropTypes.bool,
  standaloneButton: PropTypes.bool,
  userType: PropTypes.string
};

AddVideo.defaultProps = {
  fromButton: false,
  modalManager: null,
  showYoutubeOption: false,
  standaloneButton: true,
  userType: 'Sysadmin'
};

export default AddVideo;
