import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';

import { Footer } from 'common/Modal';
import Tooltip from 'common/Tooltip';

const FormModalFooter = ({
  allowManualAdd, closeModal, hideSkipLinkingButton, linkClasses, manualAddDisabledNotice,
  selectedClassroomIds, skipLinkingClasses, submitting
}) => {
  const skipLinkingButton = () => {
    const button = (<button
      className="btn btn--outline-purple mr15"
      disabled={!allowManualAdd}
      onClick={skipLinkingClasses}
      type="button"
    >
      Skip Linking Classes
    </button>);

    if (allowManualAdd) return button;

    return (
      <Tooltip
        content={manualAddDisabledNotice}
      >
        <div>
          {button}
        </div>
      </Tooltip>
    );
  }


  return (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={linkClasses}
      primaryButtonDisabled={selectedClassroomIds.size === 0}
      primaryButtonSubmit={false}
      primaryButtonText={submitting ? 'Linking Classes' : 'Link Classes'}
      submitting={submitting}
      wrapperClassName="pr20 pl20 pb20"
    >
      <button
        className="btn btn--link-purple"
        onClick={closeModal}
        type="button"
      >
        Cancel
      </button>
      {!hideSkipLinkingButton && skipLinkingButton()}
    </Footer>
  );
}

FormModalFooter.propTypes = {
  allowManualAdd: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  linkClasses: PropTypes.func.isRequired,
  selectedClassroomIds: PropTypes.shape({ size: PropTypes.number }).isRequired,
  skipLinkingClasses: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default FormModalFooter;
