import React from 'react';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/ui/Tooltip';
import cn from '@/utils/cn';
import redoIcon from './icons/redo.svg';
import { buttonStyle, imgStyle } from './styles';
import useDrawingContext from '../store/useDrawingContext';

const RedoButton = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const canRedo = useDrawingContext(state => state.canRedo);
  const redo = useDrawingContext(state => state.redo);

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Tooltip.Trigger
          style={buttonStyle(toolbarEl)}
          className={cn(
            'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
            'hover:tw-bg-tci-purple tw-flex tw-items-center tw-justify-center',
            'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
          )}
          disabled={!canRedo()}
          onClick={() => redo()}
        >
          <img
            style={imgStyle({
              toolbarEl,
              referenceWidth: 16.5,
              referenceHeight: 14.5,
              maxWidth: 25,
              maxHeight: 16,
            })}
            src={redoIcon}
            alt={I18n.t('redo')}
          />
        </Tooltip.Trigger>
        <Tooltip.Content
          className={cn(
            'tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2',
            'tw-outline tw-outline-1 tw-outline-gray-300',
          )}
          side="right"
          sideOffset={10}
        >
          {I18n.t('redo')}
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default RedoButton;
