import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import TCIOnlyLock from 'common/TCIOnlyLock';
import Form from './Form';
import { isSysadmin } from '../../../common/Utils';

export default class ManageRostering extends Component {
  static propTypes = {
    manageModalIsOpen: PropTypes.bool,
    name: PropTypes.string.isRequired,
    priorityOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired,
    runModalIsOpen: PropTypes.bool,
    subscriber_id: PropTypes.number,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    manageModalIsOpen: false,
    runModalIsOpen: false,
    subscriber_id: null
  };

  constructor(props) {
    super(props);
    this.state = props;

    // bind functions
    this.openManageModal = this.openManageModal.bind(this);
    this.closeManageModal = this.closeManageModal.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  openManageModal() {
    this.setState({ manageModalIsOpen: true });
  }

  closeManageModal() {
    this.setState({ manageModalIsOpen: false });
  }

  handleUpdate(response) {
    const object = response.auto_roster_district;
    for (const key in object) {
      // check if the property/key is defined in the object itself, not in parent
      if (object.hasOwnProperty(key)) {
        this.setState({
          [key]: object[key]
        });
      }
    }

    this.setState({
      syncDisabled: response.syncDisabled,
      newRecord: response.newRecord,
      uid: response.uid
    });
  }

  render() {
    if (isSysadmin(this.props.userType)) {
      return (
        <div className="inline_block">
          <button
            onClick={this.openManageModal}
            className="btn btn--outline-purple"
          >
            Manage Rostering <TCIOnlyLock />
          </button>
          <div className="inline_block">
            <Modal
              closeModal={this.closeManageModal}
              isOpen={this.state.manageModalIsOpen}
              headerText="Auto Roster Setup"
            >
              <Form
                closeModal={this.closeManageModal}
                handleUpdate={this.handleUpdate}
                priorityOptions={this.props.priorityOptions}
                {...this.state}
              />
            </Modal>
          </div>
        </div>
      );
    }
    return null;
  }
}
