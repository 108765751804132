import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'common/Modal';
import ConnectClassroomForm from './ConnectClassroomForm';
import DisconnectClassroomForm from './DisconnectClassroomForm';
import canvasLogo from './canvas_logo.svg';
import googleLogo from './google_logo.svg';
import styles from './ConnectClassroomButton.module.scss';

const GoogleClassroomButton = ({
  subscriberId, programId, lessonId, classrooms, updateClassroom, rowData, lmsProvider
}) => {
  const { isOpen, open, close } = useModalManager();
  const isConnected = rowData.lms_course_id !== null;
  const connectMessage = lmsProvider === 'google' ? 'Connect to Google Classroom' : 'Connect to Canvas Course';
  const disconnectMessage = lmsProvider === 'google' ? 'Disconnect from Google Classroom' : 'Disconnect from Canvas';
  const headerText = isConnected ? disconnectMessage : connectMessage;

  const connectClassroomForm = (
    <ConnectClassroomForm
      closeModal={close}
      subscriberId={subscriberId}
      classroomId={rowData.id}
      classroomName={rowData.name}
      programId={programId}
      lessonId={lessonId}
      classrooms={classrooms}
      updateClassroom={updateClassroom}
      lmsProvider={lmsProvider}
    />
  );

  const disconnectClassroomForm = (
    <DisconnectClassroomForm
      closeModal={close}
      subscriberId={subscriberId}
      rowData={rowData}
      updateClassroom={updateClassroom}
      lmsProvider={lmsProvider}
    />
  );

  const header = (
    <div className={styles.googleClassroomButton}>
      <img alt="" src={lmsProvider === 'google' ? googleLogo : canvasLogo} />
      {headerText}
    </div>
  );

  return (
    <Fragment>
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={open}
      >
        {header}
      </a>
      <Modal
        isOpen={isOpen}
        headerText={header}
        closeModal={close}
        className={styles.modal}
        bodyClassName={styles.modalBody}
      >
        {isConnected ? disconnectClassroomForm : connectClassroomForm }
      </Modal>
    </Fragment>
  );
};

GoogleClassroomButton.propTypes = {
  classrooms: PropTypes.arrayOf(PropTypes.shape({
    lms_course_id: PropTypes.string
  })).isRequired,
  lessonId: PropTypes.number.isRequired,
  lmsProvider: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lms_course_id: PropTypes.string,
    lms_course_name: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};

export default GoogleClassroomButton;
