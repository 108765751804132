import React, { MouseEvent, useEffect, useRef } from 'react';

import styles from './Checkbox.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  extraClassName?: string;
  extraInputClassName?: string;
  extraLabelClassName?: string;
  indeterminate?: boolean;
  shouldCover?: boolean;
}

const Checkbox = ({
  label,
  extraClassName,
  extraInputClassName,
  extraLabelClassName,
  indeterminate = false,
  shouldCover,
  ...otherProps
}: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const TagName = shouldCover ? 'label' : 'div';

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <TagName className={`${styles.container} ${extraClassName}`}>
      <input
        ref={checkboxRef}
        type="checkbox"
        className={`${styles.checkbox} ${extraInputClassName}`}
        onClick={handleClick}
        {...otherProps}
      />
      <span className={extraLabelClassName}>{label}</span>
    </TagName>
  );
};

export default Checkbox;
