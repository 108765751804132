import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import styles from '../SlideShowBuilder.module.scss';
import AddSlide from '../../Utils/AddSlide';
import DraggableThumbnail from './DraggableThumbnail';
import { formatPresentSlides } from '../../PresentMode/Utils';
import useSlideShowContext from '../../stores/useSlideShowContext';
import thumbnailStyles from './Thumbnail/Thumbnail.module.scss';
import TransitionSlide from '../../PresentMode/TransitionSlide';
import ActionsDropdown from '../../../../common/ActionsDropdown';
import RemoveSlideGroup from '../../Utils/RemoveSlideGroup';
import { REORDER_SLIDE } from '../../Utils/slideShowGraphQL';

const SlidePanel = ({ setSelectedSlide, setIsSaving }) => {
  const [updateSlideMutation] = useMutation(REORDER_SLIDE);
  const formattedSlides = useSlideShowContext(state => state.slides);
  const setFormattedSlides = useSlideShowContext(state => state.setSlides);
  const slideShow = useSlideShowContext(state => state.slideShow);
  const selectedSlide = useSlideShowContext(state => state.currentSlide);
  const updateSlideShow = useSlideShowContext(state => state.updateSlideShow);

  const updateSlide = (position) => {
    // If the user picked up a draggable and didn't change position, do nothing
    if (position === selectedSlide.position) return;
    const slideGroups = formattedSlides.filter(slide => slide.__typename === 'SlideGroup');

    // Find the number of slide group slides that are positioned before our new position
    let offset = slideGroups.reduce((acc, cur) => {
      if (cur.__typename === 'SlideGroup' && cur.position < position) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const dir = position < selectedSlide.position ? 'UP' : 'DOWN';

    let slideBeforeNewPosition = {};

    // If we are moving down and we move directly behind a Slide Group slide,
    // then we are actually not changing our position and are actually moving between groups
    // so we need to account for that in the offset
    if (dir === 'DOWN') {
      slideBeforeNewPosition = formattedSlides.find(slide => slide.position === position);
      if (slideBeforeNewPosition.__typename === 'SlideGroup') offset += 1;
    }

    // If the slide before our new position is a Slide Group, we use that as our slide group
    // otherwise, we find the last slide group before our new position
    let slideGroupBeforePosition;
    if (slideBeforeNewPosition.__typename === 'SlideGroup') {
      slideGroupBeforePosition = slideBeforeNewPosition;
    }
    else {
      slideGroupBeforePosition = [...slideGroups].reverse()
        .find(slide => slide.__typename === 'SlideGroup' && slide.position < position);
    }

    const slideGroupId = slideGroupBeforePosition ? parseInt((slideGroupBeforePosition.id.toString()), 10) : null;

    const dbPosition = position - offset;

    const mutationParams = {
      variables: {
        id: selectedSlide.id,
        // Slide position index is 1-based while position index is zero-based.
        position: dbPosition,
        slideGroupId,
      }
    };

    // Create a new array of slides with updated positions for re-rendering
    const newSlides = slideShow.slides.map((s) => {
      if (s.id !== selectedSlide.id) return s;

      const amtToAdjust = dbPosition > s.position ? -1 : 1;
      return { ...s, position: dbPosition - amtToAdjust, slideGroupId };
    }).sort((a, b) => a.position - b.position);

    const newFormattedSlides = formatPresentSlides({ ...slideShow, slides: newSlides }, newSlides.map(({ id }) => id));

    setFormattedSlides(newFormattedSlides);

    setIsSaving(true);

    updateSlideMutation(mutationParams)
      .then((res) => {
        updateSlideShow(res.data.updateSlide.slideShow);
        setIsSaving(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSaving(false);
      });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    // Update slides state.
    const items = Array.from(formattedSlides);
    const [reorderedItem] = items.splice(result.source.index, 1);
    if (reorderedItem) items.splice(result.destination.index, 0, reorderedItem);

    // Send in client-side position from react-beautiful-dnd
    updateSlide(result.destination.index);
  };

  const reformattedSlides = () => {
    const slideGroups = formattedSlides.filter(s => s.type === 'Transition');

    let groupIndex = 0;

    return formattedSlides.map((slide, index) => {
      if (slide.type === 'Transition') {
        const myIndex = groupIndex++;
        const isSelected = !!selectedSlide && selectedSlide.id === slide.id;

        return (
          <Draggable key={slide.id} draggableId={slide.id.toString()} index={index + 1} isDragDisabled>
            {draggable => (
              <div ref={draggable.innerRef} {...draggable.dragHandleProps} {...draggable.draggableProps}>
                <div
                  role="button"
                  tabIndex={0}
                  className={`${thumbnailStyles.thumbnailWrapper} ${thumbnailStyles.pointer}`}
                  onClick={() => setSelectedSlide(slide)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setSelectedSlide(slide);
                  }}
                >
                  <div className={`${styles.fullWidthMax} ${isSelected && styles.selected}`}>
                    <div className={styles.actionsButtonWrapper}>
                      {(myIndex < slideGroups.length - 1) && (
                        <ActionsDropdown toggleText="" toggleClassName={`${styles.actionsButton} btn--outline-purple`}>
                          <RemoveSlideGroup slideGroup={slide} />
                        </ActionsDropdown>
                      )}
                    </div>
                    <span className={thumbnailStyles.positionNumber}>{index + 1}</span>
                    <TransitionSlide
                      className={`${thumbnailStyles.overflowHidden} ${thumbnailStyles.noPointerEvents} ${thumbnailStyles.adjusted}`}
                      forDisplay="thumbnail"
                      imageUrl={slideShow.imageUrl}
                      slideGroupIndex={myIndex}
                      slideGroups={slideGroups}
                      jumpToSlide={() => {}}
                      isSelected={isSelected}
                    />
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        );
      }

      return (
        <DraggableThumbnail
          key={slide.id}
          index={index + 1}
          selectedSlide={selectedSlide}
          setSelectedSlide={() => setSelectedSlide(slide)}
          slide={slide}
        />
      );
    });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="slides">
        {provided => (
          <section
            id="slide-panel"
            aria-label="Slide Panel"
            className={styles.slidePanel}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {slideShow && reformattedSlides()}
            {provided.placeholder}
            <AddSlide styling="slide" />
          </section>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SlidePanel.propTypes = {
  setIsSaving: PropTypes.func.isRequired,
  setSelectedSlide: PropTypes.func.isRequired,
};

export default SlidePanel;
