import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';

import DataTable from 'common/DataTable';
import Tooltip from 'common/Tooltip';

import { Footer } from 'common/Modal';

export default class ManageTeachers extends Component {
  static propTypes = {
    classroomName: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    noAvailableTeacherSeats: PropTypes.bool.isRequired,
    sharePath: PropTypes.string.isRequired,
    teachers: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      string: PropTypes.string
    })).isRequired,
    updateComponent: PropTypes.func.isRequired,
    resourcesAreLoading: PropTypes.bool,
    resources: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    })).isRequired
  };

  static defaultProps = {
    resourcesAreLoading: false
  };

  static _noAvailableSeatsWarning() {
    return (
      <div className="no_subscriptions red">
        <p>
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
          />
          {' '}
          All teacher licenses are in use. You will only be able to add
          teachers that already have this license.
        </p>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedTeacher: null,
      staffers_to_remove: new Set(),
      submitting: false
    };

    this._handleSubmit = this._handleSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  disableSubmitButton() {
    return this.state.staffers_to_remove.size === this.props.resources.length;
  }

  handleCheckboxChange(args, state, utils) {
    const updatedStaffersToRemove = new Set(this.state.staffers_to_remove);
    if (args[0].target.checked) {
      updatedStaffersToRemove.add(args[0].target.value);
    }
    else {
      updatedStaffersToRemove.delete(args[0].target.value);
    }

    this.setState({ staffers_to_remove: updatedStaffersToRemove });
    utils.changeValue(state, 'staffers_to_remove', () => Array.from(updatedStaffersToRemove));
  }

  handleSelect(args, state, utils) {
    this.setState({ selectedTeacher: args[0] });
    utils.changeValue(state, 'teacherId', () => args[0].value);
  }

  _handleSubmit(values) {
    this.setState({ submitting: true });
    Axios.post(this.props.sharePath, values)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          this.setState({ submitting: false });
          this.props.updateComponent(response);
          this.props.closeModal();
        }
      })
      .catch(error => console.log(error));
  }

  _renderCheckbox(staffer, form) {
    return (
      <div>
        <label
          htmlFor={`remove-staffer-${staffer.id}`}
        >
          <span className="sr-only">
            remove {staffer.first_name} {staffer.last_name} from {this.props.classroomName}
          </span>
        </label>

        <Field
          component="input"
          type="checkbox"
          value={staffer.id}
          checked={form.values}
          id={`remove-staffer-${staffer.id}`}
          onClick={form.mutators.handleChange}
        />{' '}
      </div>
    );
  }

  _showWarning() {
    if (this.props.noAvailableTeacherSeats) {
      return ManageTeachers._noAvailableSeatsWarning();
    }

    return null;
  }

  _userSelect(form) {
    return (
      <Select
        className="user-search"
        joinValues
        disabled={this.props.resources.length >= 10}
        id={`select-teachers-${this.props.id}`}
        value={this.state.selectedTeacher}
        onChange={form.mutators.handleSelect}
        options={this.props.teachers}
      />
    );
  }

  _renderSelect(form) {
    if (this.props.resources.length < 10) {
      return this._userSelect(form);
    }
    return (
      <Tooltip
        content="Please remove a teacher from the class to add another. Max 10 teachers per class."
      >
        {this._userSelect(form)}
      </Tooltip>
    );
  }

  render() {
    return (
      <Form
        mutators={{
          handleChange: this.handleCheckboxChange,
          handleSelect: this.handleSelect
        }}
        onSubmit={this._handleSubmit}
        render={({ form, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className={`safety-notes-form ${this.state.isLoading && 'loading'}`}
          >
            <div className="form-row">
              <label
                className="mt5"
                htmlFor={`select-teachers-${this.props.id}`}
              >
                Add Teachers
              </label>
              {this._renderSelect(form)}
            </div>

            {this._showWarning()}

            <DataTable
              searching={false}
              paging={false}
              columns={this.props.columns}
              info={false}
              handleResourcesManually
              resources={this.props.resources}
              isLoading={this.props.resourcesAreLoading}
              columnDefs={[{
                title: 'Remove',
                targets: -1,
                sortable: false,
                searchable: false,
                createdCell: (td, cellData, rowData) =>
                  ReactDOM.render(
                    rowData.id && this._renderCheckbox(rowData, form),
                    td
                  )
              }]}
            />
            <Footer
              disableWithTooltipText={this.state.staffers_to_remove.size > 0 ? 'Classes require at least one teacher.' : ''}
              primaryButtonClassName="btn btn--purple"
              primaryButtonDisabled={this.disableSubmitButton()}
              primaryButtonText="Submit"
              secondaryButtonCallback={this.props.closeModal}
              submitting={this.state.submitting}
              submittingText="Submitting..."
            />
          </form>
        )}
      />
    );
  }
}
