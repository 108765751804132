import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import DataTable from 'components/common/DataTable';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { useModalManager } from 'components/common/Modal';
import axios from 'axios';
import * as Routes from 'modules/routes';
import ProgramAccessModal from '../ProgramAccessModal';

const StateCapitalsTable = () => {
  const [stateCapitalsTableData, setStateCapitalsTableData] = useState([]);
  const [gameId, setGameId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const modalManager = useModalManager();

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(Routes.plato_api_state_capitals_games_path())
      .then((res) => {
        if (res.data.game) {
          const tableData = Object.assign(res.data.game, { actions: '' });
          setStateCapitalsTableData([tableData]);
          setGameId(res.data.game.id);
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <DataTable
        columns={['name', 'game_type', 'published', 'question_count', 'actions']}
        columnDefs={[
          { sortable: false, targets: 0 },
          { sortable: false, targets: 1 },
          { sortable: false, targets: 2 },
          { sortable: false, targets: 3 },
          {
            sortable: false,
            targets: 4,
            createdCell: (td, _cellData, rowData) => {
              ReactDOM.render(
                <ActionsDropdown id={`slide-group-${rowData.id}-actions`} toggleText="Actions">
                  <button
                    className="dropdown-item full-size"
                    onClick={() => undefined}
                    type="button"
                  >
                    <i className="fa fa-eye" />
                    Preview
                  </button>
                  <button
                    className="dropdown-item full-size"
                    onClick={modalManager.open}
                    type="button"
                  >
                    <i className="fa fa-pencil-square-o" />
                    Edit Access
                  </button>
                </ActionsDropdown>,
                td,
              );
            },
          },
        ]}
        columnMapping={['name', 'type', 'status', 'questions']}
        defaultOrder={[0, 'asc']}
        handleResourcesManually
        info={false}
        paging={false}
        resources={stateCapitalsTableData}
        sortable={false}
        isLoading={isLoading}
      />

      {modalManager.isOpen && (
        <ProgramAccessModal
          isOpen={modalManager.isOpen}
          closeModal={modalManager.close}
          getPath={Routes.plato_api_program_access_games_path()}
          submitPath={Routes.plato_api_game_path({ id: gameId })}
          model="game"
          modalHeaderText="Edit State Capitals Access"
        />
      )}
    </>
  );
};

export default StateCapitalsTable;
