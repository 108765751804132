import React from 'react';
import I18n from 'i18n-js';
import Checkbox from '@/components/common/libraries/common/Checkbox';
import { twMerge } from '@/utils';
import styles from './index.module.scss';

const ShowSlideshowCheckbox = () => {
  const extraClassName = twMerge(
    'tw-inline-flex tw-items-center tw-bg-[#247f99] tw-bg-opacity-5',
    'tw-border-[1px] tw-border-solid tw-border-[#247f99] tw-rounded-full tw-px-4 tw-py-2 w-fit',
  );

  return (
    <Checkbox
      label={I18n.t('show_my_slideshows')}
      extraClassName={extraClassName}
      extraInputClassName={styles.checkbox}
      extraLabelClassName="tw-text-[#3c3c3c] tw-tracking-[.03em] tw-leading-relaxed tw-pl-2"
    />
  );
};

export default ShowSlideshowCheckbox;
