import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';

export default function Add({ openAssignmentModal }) {
  function openModal() {
    openAssignmentModal(true);
  }

  return (
    <div className="mt10 tw-bg-white">
      <button
        onClick={openModal}
        className="btn btn--green"
        aria-haspopup="dialog"
        aria-controls="CreateAssignmentModal"
        aria-label="Create New"
      >
        {I18n.t('create_new')}
      </button>
    </div>
  );
}

Add.propTypes = {
  openAssignmentModal: PropTypes.func.isRequired
};
