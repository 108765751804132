import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Axios from 'axios/index';
import PropTypes from 'prop-types';

import { renderErrors, RequiredAsterisk, SubmitError } from '../../Forms/Utils';
import { Footer } from '../../../common/Modal/index';
import styles from '../Form.module.scss';

export default class EditForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    updateClassroom: PropTypes.func.isRequired,
    updatePath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  handleSubmit = async (values) => {
    const request_data = { classroom: values };

    this.setState({ submitting: true });

    return Axios
      .put(this.props.updatePath, request_data)
      .then((response) => {
        this.props.updateClassroom(response.data.data);
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{ name: this.props.name, period: this.props.period, uid: this.props.uid }}
        render={({
          handleSubmit, form, pristine, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="sr-only">Fields marked with an asterisk (*) are required.</span>

            <div className={styles.row}>
              <div className={styles.fieldContainer}>
                <label htmlFor="edit-name" className={styles.label}>
                  Class Name<RequiredAsterisk />
                </label>
                <Field
                  id="edit-name"
                  className={`${styles.input} ${styles.textInput}`}
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Class Name"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.fieldContainer}>
                <label htmlFor="edit-period" className={styles.label}>
                  Period<RequiredAsterisk />
                </label>
                <Field
                  id="edit-period"
                  className={`${styles.input} ${styles.textInput}`}
                  name="period"
                  component="input"
                  type="text"
                  placeholder="Period"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.fieldContainer}>
                <label htmlFor="edit-uid" className={styles.label}>
                  UID
                </label>
                <Field
                  id="edit-uid"
                  className={`${styles.input} ${styles.textInput}`}
                  name="uid"
                  component="input"
                  type="text"
                  parse={val => (val === undefined ? '' : val)}
                />
              </div>
            </div>

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={pristine}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }
}
