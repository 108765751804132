/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import * as fabric from 'fabric';
import useDrawingContext from '../store/useDrawingContext';
import { setupCustomSelectTools } from '../functions';

setupCustomSelectTools();

const useCanvas = ({ canvasEl }: { canvasEl: React.MutableRefObject<HTMLCanvasElement> | null; }) => {
  const setCanvas = useDrawingContext(state => state.setCanvas);
  const canvas = useDrawingContext(state => state.canvas);

  useEffect(() => {
    setCanvas(
      new fabric.Canvas(canvasEl.current, {
        containerClass: 'tw-transition-all',
        preserveObjectStacking: true,
      }),
    );

    return () => {
      canvas?.dispose();
      canvasEl.current = null;
      setCanvas(null);
    };
  }, []);

  return canvas;
};

export default useCanvas;
