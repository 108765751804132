import React from 'react';
import I18n from 'modules/i18n';
import { useQuery } from '@apollo/client';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './TransitionSlide.module.scss';
import partyIcon from '../../../../../../../assets/images/party-icon.svg';
import * as Routes from '../../../../../modules/routes';
import AssessWithLinks from './AssessWithLinks';
import TurnInButton from './TurnInButton';
import ImpersonateShortcut from '../../../../common/ShortcutButtons/ImpersonateShortcut';
import LmsShare from '../../../../common/ShortcutButtons/LmsShare';
import AssignmentShortcut from '../../../../common/ShortcutButtons/AssignmentShortcut';
import GradeShortcut from '../../../../common/ShortcutButtons/GradeShortcut';
import { GET_VIDEO_ACTIVITY_RESULTS, GET_SLIDE_SHOW_ASSIGNMENT_RESULTS } from '../../Utils/slideShowGraphQL';
import { IAssignmentShortcutProps, ILmsShareProps, IGradeShortcutProps, ForDisplay } from './types';
import UnansweredQuestions from './UnansweredQuestions';

export interface VideoActivityFinalSlideProps {
  assignmentId: string;
  AssignmentShortcutProps: IAssignmentShortcutProps;
  forDisplay: ForDisplay
  GradeShortcutProps: IGradeShortcutProps;
  LmsShareProps: ILmsShareProps;
}

const VideoActivityFinalSlide = ({ assignmentId, AssignmentShortcutProps, forDisplay, GradeShortcutProps, LmsShareProps }: VideoActivityFinalSlideProps) => {
  const isPresentMode = useSlideShowContext(state => state.isPresentMode);
  const lessonId = useSlideShowContext(state => state.lessonId);
  const parentId = useSlideShowContext(state => state.parentId);
  const parentType = useSlideShowContext(state => state.parentType);
  const programId = useSlideShowContext(state => state.programId);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const slideIds = useSlideShowContext(state => state.slideIds);
  const userId = useSlideShowContext(state => state.userId);
  const userType = useSlideShowContext(state => state.userType);

  const { lesson } = slideShow;
  const isStudent = userType === 'Student';
  const assignVideoActivitySlideshow = $('#slug-attributes').data('flags')?.assign_slideshow_video_activity;

  const getQuery = () => (assignVideoActivitySlideshow ? GET_SLIDE_SHOW_ASSIGNMENT_RESULTS : GET_VIDEO_ACTIVITY_RESULTS);

  const getQueryOptions = () => (
    assignVideoActivitySlideshow ? {
      fetchPolicy: 'network-only',
      variables: {
        assignmentId,
        slideShowId,
      },
    } : {
      fetchPolicy: 'no-cache',
      variables: {
        parentId,
        parentType,
        programId,
        slideShowId,
        studentId: isStudent ? userId : null,
      },
    }
  );

  // @ts-ignore
  const { loading, data } = useQuery(getQuery(), getQueryOptions());

  const backToButton = () => {
    if (isStudent) {
      return (
        <a
          className={`btn btn--outline-purple btn--sm ${styles.backToButton}`}
          href={Routes.shared_program_assignments_path(programId, { lesson_id: lesson.id })}
        >
          {I18n.t('back_to_assignments')}
        </a>
      );
    }

    return (
      <a
        className={`btn btn--outline-purple btn--sm ${styles.backToButton}`}
        href={Routes.shared_program_lesson_slide_shows_path(programId, lesson.id)}
      >
        {I18n.t('back_to_lesson_overview')}
      </a>
    );
  };

  const turnInPath = () => {
    if (assignVideoActivitySlideshow) {
      return Routes.plato_api_turn_in_slide_show_path(slideShow.id, {
        program_id: programId,
        lesson_id: lesson.id,
        assignment_id: assignmentId,
      });
    }
    return Routes.plato_api_turn_in_unit_notebook_notebook_path(lesson.videoActivity.id, {
      program_id: programId,
      lesson_id: lessonId,
    });
  };

  // Disable turn in button if the slide show has slide groups but is not assigned.
  const disableButton = () => {
    if (assignVideoActivitySlideshow && slideShow.slideGroupsAssignments?.length > 0) {
      return !assignmentId;
    }
    return false;
  };

  const filteredUnansweredQuestions = () => {
    if (slideIds?.length === 0) {
      return data?.slideShow?.unansweredQuestions;
    }

    return data?.slideShow?.unansweredQuestions?.filter(question => slideIds?.includes(question.slideId));
  };

  const turnInButton = () => {
    const exerciseResult = data?.slideShow?.lesson?.videoActivity?.exerciseResult;

    return (
      <TurnInButton
        turnInPath={turnInPath()}
        exerciseResults={exerciseResult ? [exerciseResult] : []}
        isStudent={userType === 'Student'}
        isPresentMode={isPresentMode}
        loading={loading}
        unansweredQuestions={loading || !assignVideoActivitySlideshow ? [] : filteredUnansweredQuestions()}
        disabled={disableButton()}
      />
    );
  };

  const showUnansweredQuestions = () => (
    userType === 'Student' && filteredUnansweredQuestions()?.length > 0 && assignVideoActivitySlideshow
  );

  return (
    <>
      <section id="left-panel" className={styles.leftPanel}>
        <div className={styles.titleContainer}>
          <img className={styles.partyIcon} src={partyIcon} alt="" />
          <span>
            {I18n.t('you_just_finished')}
            :
          </span>
          <span className={styles.title}>{I18n.t('video_activity')}</span>
        </div>
      </section>
      <section id="right-panel" className={styles.rightPanel}>
        {userType === 'Teacher' && forDisplay === 'present' && (
          <div className={styles.actionsContainer}>
            <ImpersonateShortcut
              enabled
              programId={programId}
              stafferId={userId}
            />
            {LmsShareProps && <LmsShare {...LmsShareProps} />}
            {AssignmentShortcutProps && <AssignmentShortcut {...AssignmentShortcutProps} />}
            {GradeShortcutProps && <GradeShortcut {...GradeShortcutProps} />}
          </div>
        )}
        <div className={styles.finalSlideItem}>
          <p>{`${I18n.t('you_reached_the_end', { title: 'Video Activity' })}!`}</p>
        </div>
        <div className={styles.unansweredQuestionBox}>
          {showUnansweredQuestions() && <UnansweredQuestions unansweredQuestions={filteredUnansweredQuestions()} />}
        </div>
        {turnInButton()}
        {userType !== 'Student' && <AssessWithLinks />}
        {backToButton()}
      </section>
    </>
  );
};

export default VideoActivityFinalSlide;
