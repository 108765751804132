import React from 'react';

const AlignRight = () => (
  <svg
    className="fr-svg"
    focusable="false"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15,18h6v-2h-6V18z M9,11v2h12v-2H9z M3,6v2h18V6H3z" />
  </svg>
);

export default AlignRight;
