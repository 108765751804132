import React from 'react';
import PropTypes from 'prop-types';
import styles from './Callout.module.scss';

const Callout = ({ text, color }) => {
  if (!text) return null;

  return (
    <div className={styles.callOut} style={{ borderColor: color }}>
      {text}
    </div>
  );
};

Callout.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string
};

Callout.defaultProps = {
  color: null,
  text: null
};

export default Callout;
