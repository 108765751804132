import React, { useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import useFlags from '@/hooks/useFlags';
import { SECTION_COLLAPSE_ID } from '../variables';
import useStudentRows from './useStudentRows';
import useColumns from './columns/useColumns';
import Legend from './Legend';
import SectionCollapseHeader from './Cells/SectionCollapseHeader';
import cn from '@/utils/cn';

const { updatedGradesTable } = useFlags();
const TABLE_BORDER_CLASSES = 'tw-border-[0.5px] tw-border-separate tw-border-solid';
const DEFAULT_BORDER = 'tw-border-med-grey';
const CELL_DIMS = updatedGradesTable
  ? 'tw-w-[6rem] tw-min-w-[6rem] tw-min-h-[46px] tw-max-h-[120px]' : 'tw-min-w-[185px] tw-min-h-[46px]';
const TH_CLASSES = 'tw-text-center tw-text-[13px] tw-relative tw-leading-4 tw-px-5 tw-py-3 tw-text-[#333] tw-bg-white tw-z-[1032]';
const STICKYHEADER = 'tw-sticky tw-left-0 tw-right-0 tw-z-[1032] tw-bg-clip-padding';
const STICKY = 'tw-sticky tw-left-0 tw-z-[1030] tw-bg-clip-padding tw-text-left tw-pl-3';

const Table = () => {
  const studentRows = useStudentRows();
  const columns = useColumns();
  const tableRef = useRef();
  const [hoveredRow, setHoveredRow] = useState(null);

  const table = useReactTable({
    data: studentRows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="tw-flex tw-flex-col tw-pr-10">
      <Legend />
      <div
        className={clsx(
          'tw-mt-2 tw-overflow-scroll tw-h-full', 'tw-max-h-[90vh]', {
            TABLE_BORDER_CLASSES: !updatedGradesTable,
            DEFAULT_BORDER: !updatedGradesTable,
          },
        )}
        ref={tableRef}
      >
        <table className={clsx({ 'tw-w-full': !updatedGradesTable }, TABLE_BORDER_CLASSES, DEFAULT_BORDER)}>
          <tbody>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="tw-bg-white">
                {headerGroup.headers.map(header => (
                  header.id === SECTION_COLLAPSE_ID ? (
                    <SectionCollapseHeader key={header.id} rowSpan={studentRows.length + 1} tableRef={tableRef} />
                  ) : (
                    <th
                      key={header.id}
                      className={clsx({
                        [TH_CLASSES]: true,
                        [CELL_DIMS]: true,
                        [TABLE_BORDER_CLASSES]: true,
                        [DEFAULT_BORDER]: true,
                        [STICKYHEADER]: header.id.match('student'),
                        'tw-bg-light-grey': header.id.match('notebook-'),
                        'tw-sticky': true,
                        'tw-top-0': true,
                      })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  )
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                className={cn({
                  'tw-relative hover:tw-bg-opacity-10 hover:tw-bg-tci-purple tw-group': updatedGradesTable,
                })}
                onMouseEnter={() => { setHoveredRow(row.id); }}
                onMouseLeave={() => { setHoveredRow(null); }}
              >
                {row.getVisibleCells().map(cell => (
                  cell.column.columnDef.id === SECTION_COLLAPSE_ID ? null : (
                    <td
                      key={cell.id}
                      className={clsx('tw-h-[46px] tw-align-middle', {
                        [CELL_DIMS]: true,
                        [TABLE_BORDER_CLASSES]: true,
                        [DEFAULT_BORDER]: true,
                        [STICKY]: cell.id.match('student'),
                        'tw-text-center': !cell.id.match('student'),
                        'tw-text-[#3C3C3C]': cell.id.match('student') && updatedGradesTable,
                        'tw-font-bold': cell.id.match('student') && !updatedGradesTable,
                        'group-hover:tw-bg-opacity-75': !cell.id.match('student') && updatedGradesTable,
                        'tw-bg-white': cell.id.match('student') || !updatedGradesTable,
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), hoveredRow })}
                    </td>
                  )
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
