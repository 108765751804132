import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PathContext from 'common/Context';
import ActionsDropdown from 'common/ActionsDropdown';
import UploadModal from 'common/UploadCsvModal';

export default class ImportCsvButton extends Component {
  static contextType = PathContext;

  static propTypes = {
    canCsvUpdate: PropTypes.bool,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    stafferId: PropTypes.number.isRequired
  };

  static defaultProps = {
    canCsvUpdate: true
  };

  constructor(props) {
    super(props);

    this.state = {
      staffModalOpen: false,
      studentModalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e, type) {
    e.preventDefault();
    this.setState({ [`${type}ModalOpen`]: true });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false });
  }

  renderImportButton() {
    return (
      <ActionsDropdown
        toggleClassName="btn--green mr5"
        toggleText="Add Users"
      >
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, 'staff')}
        >
          <i aria-hidden="true" className="fa fa-upload" />
          Upload Staff CSV
        </a>
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, 'student')}
        >
          <i aria-hidden="true" className="fa fa-upload" />
          Upload Student CSV
        </a>
      </ActionsDropdown>
    );
  }

  renderStaffModal() {
    if (this.state.staffModalOpen) {
      return (
        <UploadModal
          closeModal={() => this.closeModal('staff')}
          canCsvUpdate={this.props.canCsvUpdate}
          csvTemplateUrl={this.context.staffCsvTemplateUrl}
          importType="staff"
          manualCsvUploadPath={this.context.staffCsvUploadPath}
          modalOpen={this.state.staffModalOpen}
          programCodes={this.props.programCodes}
          programsPath={this.context.programsPath}
          staffCsvUploadsPath={this.context.redirectPath}
          stafferId={this.props.stafferId}
        />
      );
    }

    return null;
  }

  renderStudentModal() {
    if (this.state.studentModalOpen) {
      return (
        <UploadModal
          batchUpdateCsvPath={this.context.studentBatchUpdateCsvPath}
          canCsvUpdate={this.props.canCsvUpdate}
          closeModal={() => this.closeModal('student')}
          csvTemplateUrl={this.context.studentCsvTemplateUrl}
          importCsvPath={this.context.studentImportCsvPath}
          importType="shared_classes_student"
          modalOpen={this.state.studentModalOpen}
          processCsvNowPath={this.context.studentProcessCsvNowPath}
          programCodes={this.props.programCodes}
          programsPath={this.context.programsPath}
          stafferId={this.props.stafferId}
          studentCsvUploadsPath={this.context.redirectPath}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        {this.renderImportButton()}

        {this.renderStaffModal()}
        {this.renderStudentModal()}
      </div>
    );
  }
}
