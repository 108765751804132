import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import styles from './Pager.module.scss';
import Tooltip from '../Tooltip';
import { capitalize } from '../Utils';

const buttonShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

export const pagerDataShape = PropTypes.shape({
  next: buttonShape,
  previous: buttonShape,
  position: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
});

export default class Pager extends Component {
  static propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    modelName: PropTypes.string.isRequired,
    pagingData: pagerDataShape.isRequired,
    showPosition: PropTypes.bool,
    tippyDistance: PropTypes.number,
    tippyPlacement: PropTypes.string,
    updatedGradesTable: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    fullWidth: false,
    showPosition: true,
    tippyDistance: null,
    tippyPlacement: 'bottom',
    updatedGradesTable: false,
  };

  static _buttonTooltip(tooltipLabel) {
    const maxCharacters = 38;
    const truncatedLabel = `${tooltipLabel.substring(0, maxCharacters)}...`;

    // .replace(/<[^>]*>?/gm, '') to strip html tags when calculating length
    return tooltipLabel.replace(/<[^>]*>?/gm, '').length < maxCharacters ? tooltipLabel : truncatedLabel;
  }

  _renderButtonContent(type, label) {
    if (type === 'previous') {
      return this.props.updatedGradesTable ? (
        <i className="fa fa-arrow-left" />
      ) : (
        <span className={this.props.children && styles.pagingButtonContent}>
          <i aria-hidden="true" className="fa fa-angle-left mr10" />
          {I18n.t('previous_model', { model: capitalize(I18n.t(this.props.modelName)) })}
          <span className="sr-only">{label}</span>
        </span>
      );
    }

    return this.props.updatedGradesTable ? (
      <i className="fa fa-arrow-right" />
    ) : (
      <span className={this.props.children && styles.pagingButtonContent}>
        {I18n.t('next_model', { model: capitalize(I18n.t(this.props.modelName)) })}
        <span className="sr-only">{label}</span>
        <i aria-hidden="true" className="fa fa-angle-right ml10" />
      </span>
    );
  }

  _renderButton(buttonData, type) {
    const label = buttonData ? buttonData.label : '';
    const buttonContent = this._renderButtonContent(type, label);
    const pagingButtonClass = this.props.children ? `${styles.smallPagingButton}` : `${styles.pagingButton}`;
    if (!buttonData) return <span className={pagingButtonClass}>{buttonContent}</span>;

    return (
      <Tooltip
        content={Pager._buttonTooltip(buttonData.label)}
        distance={this.props.tippyDistance}
        placement={this.props.tippyPlacement}
        size="regular"
        theme="white"
      >
        <a
          className={pagingButtonClass}
          href={buttonData.url}
        >
          {buttonContent}
        </a>
      </Tooltip>
    );
  }

  _renderPosition() {
    const label = this.props.showPosition ? `${this.props.pagingData.position} of ${this.props.pagingData.total}` : '';

    return (
      <span className={styles.positionContainer}>
        {label}
      </span>
    );
  }

  render() {
    return (
      <div className={styles.pagingContainer}>
        {this._renderButton(this.props.pagingData.previous, 'previous')}
        {this.props.children || this._renderPosition()}
        {this._renderButton(this.props.pagingData.next, 'next')}
      </div>
    );
  }
}
