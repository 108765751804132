import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToastBody.module.scss';

const faIcon = {
  error: 'fa-times',
  info: 'fa-info',
  success: 'fa-check',
  warning: 'fa-exclamation-triangle'
};

export default function ToastBody({ body, heading, msgType }) {
  function renderMessage() {
    return (
      <div className={styles.messageContainer}>
        <div>
          {
            heading.length > 0 &&
            <div className={styles.heading}>{heading}</div>
          }
          <div>{body}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.iconContainer} ${styles[msgType]}`}>
        <i className={`fa ${faIcon[msgType]}`} aria-label={`${msgType} message`} />
      </div>

      {renderMessage()}
    </div>
  );
}

ToastBody.propTypes = {
  body: PropTypes.node.isRequired,
  heading: PropTypes.string,
  msgType: PropTypes.string
};

ToastBody.defaultProps = {
  heading: '',
  msgType: 'success'
};
