import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SubmitModal, useModalManager } from 'common/Modal';
import VideoPlayer from 'common/VideoPlayer';
import * as Routes from 'routes';
import videoPropTypes from 'common/VideoPlayer/Proptypes';
import styles from './VideoCard.module.scss';

const VideoCard = ({ cardLabel, currentModelVideo, setCurrentModelVideo }) => {
  if (!currentModelVideo) return null;

  const modalManager = useModalManager();

  return (
    <Fragment>
      {cardLabel && <div className={cardLabel.className}>{cardLabel.text}</div>}
      <div className={`${styles.videoCard} card`}>
        <VideoPlayer
          width={350}
          video={currentModelVideo.video}
        />
        <div className={styles.videoInfo}>
          <i className={`fa fa-file-video-o ${styles.icon}`} aria-hidden />
          <button type="button" className="btn btn--red btn--sm" onClick={modalManager.open}>Remove</button>
          <p>
            <i className="fa fa-exclamation-circle mr5" aria-hidden />
            Videos will not show up in print and can only be accessed online
          </p>
        </div>
      </div>
      <SubmitModal
        headerText="Remove Video"
        submitPath={Routes.plato_api_model_video_path(currentModelVideo.id)}
        method="delete"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={() => setCurrentModelVideo(null)}
      >
        Are you sure you want to remove this video?
      </SubmitModal>
    </Fragment>
  );
};

VideoCard.propTypes = {
  cardLabel: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string
  }),
  currentModelVideo: PropTypes.shape({
    id: PropTypes.number,
    video: videoPropTypes
  }),
  setCurrentModelVideo: PropTypes.func.isRequired
};

VideoCard.defaultProps = {
  cardLabel: null,
  currentModelVideo: null
};

export default VideoCard;
