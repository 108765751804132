import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface SectionProps {
  children: React.ReactNode;
  headerColorClass: string;
  headerIcon: React.ReactNode;
  headerLink?: React.ReactNode;
  headerTitle: string;
}

const Section = ({ children, headerColorClass, headerIcon, headerLink, headerTitle }: SectionProps) => {
  return (
    <div className={styles.container}>
      <div className={`tw-flex tw-justify-between tw-text-white tw-text-lg tw-font-bold
                       ${headerColorClass} ${styles.cardTitle}`}>
        <div className="tw-flex flex-row tw-items-center tw-pl-5">
          {headerIcon}
          <div className="tw-text-2xl tw-font-bold">{headerTitle}</div>
        </div>
        <div className="tw-pr-3">{headerLink}</div>
      </div>
      <div className={styles.cardBody}>
        {children}
      </div>
    </div>
  );
};

export default Section;
