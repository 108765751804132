import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';
import DataTable from '@/components/common/DataTable';
import { matchAttributesForSingleRow } from '@/components/common/DataTable/Utils';
import Tooltip from '@/components/common/Tooltip';
import SysadminDropdown from './SysadminDropdown';
import Edit from './Edit';

const accessDisplayName = {
  ContentManager: 'Content Only',
  SupportManager: 'Support Only',
  Sysadmin: 'Full Access',
};

interface SysadminTableProps {
  archivePath: string;
  columns: string[];
  flags: { name: string; label: string }[];
  getPath: string;
  updateStafferPath: string;
}

const SysadminTable = ({
  archivePath, columns, flags, getPath, updateStafferPath,
}: SysadminTableProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [rowData, setRowData] = useState();

  const getResources = (path) => {
    setIsLoading(true);

    return Axios
      .get(path)
      .then(response => setResources(response.data.data))
      .catch(error => console.log(error))
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    getResources(getPath);
  }, []);

  const openModal = (e, currentRowData) => {
    e.preventDefault();
    setRowData(currentRowData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const updatedResources = (existingResources, updatedStaff) => (
    existingResources.map((staff) => {
      if (updatedStaff && staff.id === updatedStaff.id) {
        matchAttributesForSingleRow(updatedStaff, columns);
        return updatedStaff;
      }
      return Object.assign({}, staff);
    })
  );

  const updateTable = ({ updatedStaff }) => {
    setResources(prev => updatedResources(prev, updatedStaff));
  };

  const columnDefs = [
    {
      createdCell: (td, _, currentRowData) => {
        ReactDOM.render(
          <SysadminDropdown
            openModal={openModal}
            rowData={currentRowData}
            updateTable={updateTable}
          />,
          td,
        );
      },
      data: null,
      searchable: false,
      sortable: false,
      targets: 5,
      width: '12%',
    },
    {
      createdCell: (td, cellData) => {
        const tooltipContent = cellData ? 'MFA configured' : 'MFA unconfigured. User must set up MFA after logging in.';

        ReactDOM.render(
          <Tooltip
            content={tooltipContent}
            theme="white"
            size="medium"
          >
            <i className={`fa ${cellData ? 'fa-lock' : 'fa-unlock'}`} />
          </Tooltip>,
          td,
        );
      },
      targets: 4,
      title: 'MFA',
    },
    {
      targets: 3,
      title: 'Access Type',
      render: data => (accessDisplayName[data]),
    },
  ];

  return (
    <div>
      <DataTable
        columnDefs={columnDefs}
        columns={columns}
        defaultOrder={[[1, 'asc'], [2, 'asc']]}
        handleResourcesManually
        isLoading={isLoading}
        resources={resources}
        searching={false}
      />

      {modalOpen && (
        <Edit
          closeModal={closeModal}
          rowData={rowData}
          updateTable={updateTable}
          updateStafferPath={updateStafferPath}
          flags={flags}
        />
      )}
    </div>
  );
};

SysadminTable.propTypes = {
  archivePath: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
  flags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  getPath: PropTypes.string.isRequired,
  updateStafferPath: PropTypes.string.isRequired,
};

SysadminTable.defaultProps = {
  columns: [],
};

export default SysadminTable;
