/**
 * Renders an image uploader component with a preview
 * uses the FileReader API (https://caniuse.com/#feat=filereader)
 * props:
 *    onFileAdded (function): callback method for file addition
 *  onFileremoved (function): callback method for file removal
 *         smallUrl (string): URL of existing image, rendered in the preview
 *          inputId (string): what we want the ID of the input to be
 *        allowRemove (bool): whether we render a remove button/have the removeImage state
 *        removeImage (bool): whether we are removing an image
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './ImageUploader.module.scss';

export default class ImageUploader extends Component {
  static propTypes = {
    accept: PropTypes.string,
    smallUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    inputId: PropTypes.string.isRequired,
    labelClasses: PropTypes.string,
    containerClasses: PropTypes.string,
    controlsClasses: PropTypes.string,
    fileLabelClasses: PropTypes.string,

  };

  static defaultProps = {
    accept: null,
    smallUrl: null,
    imageFile: null,
    imageUrl: null,
    removeImage: false,
    newRecord: false,
    allowRemove: true,
    required: false,
    labelClasses: '',
    containerClasses: '',
    controlsClasses: '',
    fileLabelClasses: ''
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {/* Here we add two labels and hide the file input -
          * much easier to style a label than an input element */}
        <label className={`${this.props.labelClasses} ${styles.mainLabel}`} htmlFor={this.props.inputId}>Image</label>
        <div className={`${styles.imageInputContainer} ${this.props.containerClasses} ${this.hasImage() ? '' : styles.noImage}`}>
          <img
            className={this.hasImage() ? '' : styles.noImage}
            src={this.hasImage() ? this.props.imageUrl || this.props.smallUrl : '/missing.png'}
          />
          <div className={`${styles.imageControls} ${this.props.controlsClasses}`}>
            <label className={this.props.fileLabelClasses} htmlFor={this.props.inputId}>
              <span className="block_link mr5">Choose File</span>
            </label>
            {this.props.allowRemove && <span className="block_link" onClick={this.removeFile.bind(this)}>Remove</span>}
          </div>
          <input
            required={this.props.required && !this.hasImage()}
            type="file"
            accept={this.props.accept}
            id={this.props.inputId}
            onChange={this.changeFile.bind(this)}
            ref={element => this.input = element}
          />
        </div>
      </Fragment>
    );
  }

  hasImage() {
    if (this.props.removeImage) return false;
    else return this.props.imageUrl !== null && this.props.imageUrl !== '/missing.png';
  }

  setFile(dataUrl, file) {
    this.props.onFileAdded(dataUrl, file);
  }

  removeFile() {
    this.input.value = null;
    this.props.onFileRemoved();
  }

  changeFile(e) {
    let reader = new FileReader(),
        file = e.currentTarget.files[0];

    reader.onloadend = () => this.setFile(reader.result, file);

    // readAsDataUrl read the contents of the file and triggers onloadend
    file ? reader.readAsDataURL(file) : this.removeFile();
  }
}
