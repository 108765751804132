import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from '@/components/common/ActionsDropdown';
import ResetMfa from './ResetMfa';
import ArchiveSysadmin from './ArchiveSysadmin';
import styles from './SysadminDropdown.module.scss';

type IRowData = {
  id: number;
  email: string;
  otp_required_for_login: boolean;
};

interface SysadminDropdownProps {
  openModal: (e: React.MouseEvent, rowData: IRowData, action: string) => void;
  rowData: IRowData;
  updateTable: ({ updatedStaff: any }) => void;
}

const SysadminDropdown = ({ openModal, rowData, updateTable }: SysadminDropdownProps) => (
  <ActionsDropdown>
    <ArchiveSysadmin
      stafferId={rowData.id}
      updateTable={updateTable}
      stafferEmail={rowData.email}
    />
    {rowData.otp_required_for_login && (
      <ResetMfa
        stafferId={rowData.id}
        updateTable={updateTable}
        stafferEmail={rowData.email}
      />
    )}
    <button
      id="edit-button"
      className={`dropdown-item ${styles.dropdownButton}`}
      type="button"
      onClick={e => openModal(e, rowData, 'edit')}
    >
      <i aria-hidden="true" className="fa fa-edit" />
      {' '}
      Edit
    </button>
  </ActionsDropdown>
);

SysadminDropdown.propTypes = {
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    otp_required_for_login: PropTypes.bool,
  }).isRequired,
  updateTable: PropTypes.func.isRequired,
};

export default SysadminDropdown;
