import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import TransferForm from './TransferForm';
import { getTransferContent } from './Utils';

export default class BulkTransferModal extends Component {
  static propTypes = {
    searchSubscribersPath: PropTypes.string.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      customerNumber: PropTypes.string.isRequired
    }).isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      transferNotes: PropTypes.string,
      adminEmail: PropTypes.string
    })).isRequired,
    transferPath: PropTypes.string.isRequired,
    transferSubscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    updateTable: PropTypes.func.isRequired,
    viewSubscriberPath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { modalOpen: false };
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  _openModal() {
    this.setState({ modalOpen: true });
  }

  _closeModal() {
    this.setState({ modalOpen: false });
  }

  _renderTransferButton() {
    return (
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={this._openModal}
      >
        {getTransferContent()}
      </a>
    );
  }

  _renderModal() {
    return (
      <Modal
        headerText="Submit Bulk Transfer"
        isOpen={this.state.modalOpen}
        closeModal={this._closeModal}
      >
        <TransferForm
          closeModal={this._closeModal}
          subscriber={this.props.subscriber}
          subscriptions={this.props.subscriptions}
          transferPath={this.props.transferPath}
          updateTable={this.props.updateTable}
          searchSubscribersPath={this.props.searchSubscribersPath}
          viewSubscriberPath={this.props.viewSubscriberPath}
          transferSubscriber={this.props.transferSubscriber}
        />
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this._renderModal()}
        {this._renderTransferButton()}
      </div>
    );
  }
}
