import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { IUnit } from '../types';

interface UseUnitsProps {
  programId: number;
  skip?: boolean;
  attributes?: string[];
}

const DEFAULT_ATTRIBUTES = ['id', 'title_with_position'];

const useUnits = ({ programId, skip, attributes = DEFAULT_ATTRIBUTES }: UseUnitsProps) => {
  const [units, setUnits] = useState<IUnit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const path = Routes.plato_api_program_units_path(programId, { only: attributes });

  useEffect(() => {
    if (skip) return;

    setIsLoading(true);
    axios
      .get<{ data: IUnit[] }>(path)
      .then(res => setUnits(res.data.data))
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }, [programId]);

  return { isLoading, units };
};

export default useUnits;
