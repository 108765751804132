type StartEndTimePair = {
  start: number;
  end: number;
};

export type StartDurationPair = {
  start: number;
  duration: number;
};

type GetStartEndTimesFromUrlRes = Promise<StartEndTimePair[]>;

export const getStartEndTimesFromUrl = (mp3Url: string): GetStartEndTimesFromUrlRes => (
  fetch(mp3Url)
    .then(res => res.arrayBuffer())
    .then(buffer => (
      new Promise((resolve, reject) => {
        const context = new AudioContext();
        context.decodeAudioData(buffer, resolve, reject);
      })
    ))
    .then((buffer) => {
      const audioBuffer = buffer as AudioBuffer;
      const audioData = audioBuffer.getChannelData(0);

      const timestampsWithSound = [];
      const threshold = 0.1;

      for (let i = 0; i < audioData.length; i += 1) {
        if (Math.abs(audioData[i]) > threshold) {
          const timestamp = i / audioBuffer.sampleRate;

          timestampsWithSound.push(parseFloat(timestamp.toFixed(2)));
        }
      }

      const uniqueTimestamps = [...new Set(timestampsWithSound)];
      const startEndTimePairs = [];
      let startTime = uniqueTimestamps[0];

      for (let i = 1; i < uniqueTimestamps.length - 2; i += 1) {
        const prevTime = uniqueTimestamps[i - 1];
        const currentTime = uniqueTimestamps[i];

        if (currentTime - prevTime > 0.5) {
          startEndTimePairs.push({ start: startTime, end: prevTime });
          startTime = currentTime;
        }
      }

      return startEndTimePairs;
    })
);

export const startEndTimePairsToStartDurationPairs = (startEndTimePairs: StartEndTimePair[]): StartDurationPair[] => {
  const startAndDurationPairs = [] as StartDurationPair[];

  startEndTimePairs.reduce((acc, cur) => {
    const pauseDuration = cur.end - cur.start as number;

    const start = parseFloat((cur.start - acc).toFixed(2));
    const duration = parseFloat(pauseDuration.toFixed(2));

    startAndDurationPairs.push({ start, duration });

    return pauseDuration + acc;
  }, 0);

  return startAndDurationPairs;
};

const getDurationFromUrl = (elementType: 'audio' | 'video', url: string): Promise<number> => {
  const videoElement = document.createElement(elementType);
  videoElement.preload = 'metadata';

  return new Promise((resolve, reject) => {
    videoElement.addEventListener('loadedmetadata', () => {
      const duration = parseFloat(videoElement.duration.toFixed(2));

      resolve(duration);
    });

    videoElement.addEventListener('error', () => {
      reject(new Error('Error loading video'));
    });

    videoElement.src = url;
  });
};

export const shouldAnalyzeAudio = async (videoUrl: string, audioUrl: string) => {
  const videoDuration = await getDurationFromUrl('video', videoUrl);
  const audioDuration = await getDurationFromUrl('audio', audioUrl);

  return videoDuration !== audioDuration;
};

export const getStartDurationPairsFromFile = async (file: File, videoUrl: string) => {
  const audioUrl = URL.createObjectURL(file);
  const startEndTimePairs = await getStartEndTimesFromUrl(audioUrl);

  const shouldAnalyze = await shouldAnalyzeAudio(videoUrl, audioUrl);

  if (!shouldAnalyze) return [];

  return startEndTimePairsToStartDurationPairs(startEndTimePairs);
};
