import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Axios from 'axios/index';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Tooltip from 'common/Tooltip';
import { formatSelectOption, renderErrors, SubmitError } from '../../../common/Forms/Utils';
import { Footer } from '../../../common/Modal';
import styles from '../Form.module.scss';

const june30ResetTooltip = 'Class and student data will not be reset on June 30.' +
  ' Instead it will be reset on July 29. This field cannot be modified between June 30 and July 31.';

export default class EditForm extends Component {
  static propTypes = {
    allowSelfServeAutoroster: PropTypes.bool,
    city: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    customerNumber: PropTypes.string.isRequired,
    editResetDateDisabled: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    mdr_id: PropTypes.number,
    name: PropTypes.string,
    overshareEnabled: PropTypes.bool,
    resetOnJuly29: PropTypes.bool,
    state: PropTypes.string.isRequired,
    stateList: PropTypes.instanceOf(Array).isRequired,
    updatePath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowSelfServeAutoroster: false,
    city: '',
    mdr_id: null,
    name: '',
    overshareEnabled: false,
    resetOnJuly29: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      submitting: false
    };
  }

  getStateSelectOptions() {
    return this.props.stateList.map(state => formatSelectOption(state));
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .patch(this.props.updatePath.replace(':id', this.props.id), { subscriber: values })
      .then((response) => {
        this.props.updateTable(response.data.data, 'update');
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  render() {
    return (
      <Form
        initialValues={{
          allow_self_serve_autoroster: this.props.allowSelfServeAutoroster,
          city: this.props.city,
          customer_number: this.props.customerNumber,
          mdr_id: this.props.mdr_id,
          name: this.props.name,
          overshare_enabled: this.props.overshareEnabled,
          reset_on_july_29: this.props.resetOnJuly29,
          state: this.props.state,
        }}
        mutators={{
          handleSelect: (args, state, utils) => {
            this.setState({ state: args[0].value });
            utils.changeValue(state, 'state', () => args[0].value);
          }
        }}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, pristine, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.formRow}>
              <label htmlFor="name" className={styles.label}>Name</label>
              <Field
                id="name"
                name="name"
                component="input"
                className={styles.textInput}
                required
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="city" className={styles.label}>City</label>
              <Field
                id="city"
                name="city"
                component="input"
                className={styles.textInput}
                required
              />
            </div>

            <Field
              name="state"
              render={() => (
                <div className={styles.formRow}>
                  <label htmlFor="state" className={styles.label}>State</label>
                  <Select
                    searchable
                    id="state"
                    value={this.state.state}
                    options={this.getStateSelectOptions()}
                    className={styles.input}
                    onChange={form.mutators.handleSelect}
                    required
                    clearable={false}
                  />
                </div>
              )}
            />

            <div className={styles.formRow}>
              <label htmlFor="customer_number" className={styles.label}>Sub ID</label>
              <Field
                id="customer_number"
                name="customer_number"
                component="input"
                className={styles.textInput}
                required
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="mdr_id" className={styles.label}>MDR ID</label>
              <Field
                id="mdr_id"
                name="mdr_id"
                component="input"
                className={styles.textInput}
                parse={val => (val === null ? '' : val)}
              />
            </div>

            <div className={styles.formRow}>
              <Field
                id="allow_self_serve_autoroster"
                name="allow_self_serve_autoroster"
                component="input"
                type="checkbox"
              />
              <label htmlFor="allow_self_serve_autoroster" className={styles.checkboxLabel}>
                Enable self-serve autoroster setup
              </label>
            </div>

            <div className={styles.formRow}>
              <Field
                id="overshare_enabled"
                name="overshare_enabled"
                component="input"
                type="checkbox"
              />
              <label htmlFor="overshare_enabled" className={styles.checkboxLabel}>
                Enable overshare licenses
              </label>
            </div>

            <div className={styles.formRow}>
              <Field
                id="reset_on_july_29"
                name="reset_on_july_29"
                component="input"
                type="checkbox"
                disabled={this.props.editResetDateDisabled}
              />
              <label htmlFor="reset_on_july_29" className={styles.checkboxLabel}>
                Reset data on July 29 instead of June 30
              </label>
              <Tooltip
                content={june30ResetTooltip}
                theme="white"
                size="medium"
              >
                <span className={`${styles.checkboxLabel} ml3`}>
                  <i className="fa fa-question-circle" aria-hidden />
                  <span className="sr-only">{june30ResetTooltip}</span>
                </span>
              </Tooltip>
            </div>

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={pristine}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }
}
