import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import Tooltip from '../../../common/Tooltip';

const linkHref = (props) => {
  if (props.kind === 'kit') {
    return props.kitMaterialsUrl || '#';
  }
  else if (props.lessonGuideUrl) {
    return props.lessonGuideUrl;
  }
  return props.handoutUrl || '#';
};

const spanComponent = (kind, icon) => (
  <span className={`source-tooltip ${kind}`}>
    {icon}
  </span>
);

const tooltipLink = (props) => {
  if (props.kind === 'teacher supplied') {
    return (
      spanComponent(props.kind, props.icon)
    );
  }
  return (
    <a
      href={linkHref(props)}
      className={(props.handoutUrl || props.kitMaterialsUrl) ? '' : 'js-prevent-default'}
      target={props.kind === 'print' ? '_blank' : ''}
    >
      {spanComponent(props.kind, props.icon)}
      <span className="sr-only">View source for {props.materialName}</span>
    </a>
  );
};

export default class SourceTooltip extends Component {
  kitTooltip() {
    return <div className="p5">{this.props.tooltipText}</div>;
  }

  render() {
    return (
      <Tooltip distance="20" content={renderToString(this.kitTooltip())}>
        {tooltipLink(this.props)}
      </Tooltip>
    );
  }
}

SourceTooltip.defaultProps = {
  handoutUrl: null,
  icon: '',
  kind: '',
  kitMaterialsUrl: null,
  lessonGuideUrl: null,
  materialName: null,
  tooltipText: ''
};

SourceTooltip.propTypes = {
  handoutUrl: PropTypes.string,
  icon: PropTypes.node,
  kind: PropTypes.string,
  kitMaterialsUrl: PropTypes.string,
  lessonGuideUrl: PropTypes.string,
  materialName: PropTypes.string,
  tooltipText: PropTypes.string
};
