/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import * as fabric from 'fabric';
import { translateLegacyObjects } from '../functions';
import useDrawingContext from '../store/useDrawingContext';
import loadData from './loadData';

interface UseQuestionAnswerProps {
  canvas: fabric.Canvas;
  preview?: boolean;
}

const useQuestionAnswer = ({
  canvas, preview = false,
}: UseQuestionAnswerProps) => {
  const [dataUrl, setDataUrl] = useState('');
  const isLoading = useDrawingContext(state => state.isLoading);
  const setIsLoading = useDrawingContext(state => state.setIsLoading);
  const answerId = useDrawingContext(state => state.answerId);
  const builder = useDrawingContext(state => state.builder);
  const questionId = useDrawingContext(state => state.questionId);
  const setBody = useDrawingContext(state => state.setBody);
  const userType = useDrawingContext(state => state.userType);
  const setQuestionAnswer = useDrawingContext(state => state.setQuestionAnswer);
  const beginReloading = useDrawingContext(state => state.beginReloading);
  const clearReloading = useDrawingContext(state => state.clearReloading);
  const pushUndoHistory = useDrawingContext(state => state.pushUndoHistory);
  const initialData = useDrawingContext(state => state.initialData);
  const setInitialData = useDrawingContext(state => state.setInitialData);
  const setIsDirty = useDrawingContext(state => state.setIsDirty);

  useEffect(() => {
    if (!canvas) return;

    setIsLoading(true);

    loadData({ answerId, builder, questionId, userType })
      .then(({ answerData, backgroundImageData, backgroundImageDimensions, questionData }) => {
        setBody(questionData.data.question.body);
        const questionFabricData = JSON.parse(questionData.data.question.data.fabric_data || '{}');
        const dataToLoad = answerData || questionFabricData;

        if (!preview) beginReloading();

        const canvasData = JSON.stringify({ ...dataToLoad, backgroundImage: backgroundImageData });

        canvas.loadFromJSON(
          canvasData,
        ).then(() => {
          if (!preview) {
            pushUndoHistory({ entry: JSON.stringify(canvas.toJSON()), isMutable: false });
            clearReloading();
            setQuestionAnswer(dataToLoad);
          }
          translateLegacyObjects({ backgroundImageDimensions, canvas });
          canvas.renderAll();
          setDataUrl(canvas.toDataURL({ multiplier: 4 }));
          if (!initialData) {
            setInitialData(JSON.stringify(canvas.toJSON()));
          } else {
            setIsDirty(initialData !== JSON.stringify(canvas.toJSON()));
          }
          setIsLoading(false);
        });
      });
  }, [canvas]);

  return { isLoading, dataUrl };
};

export default useQuestionAnswer;
