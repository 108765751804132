import React from 'react';
import I18n from 'i18n-js';
import LibraryCard from '@/components/shared/NewLessonOverview/Libraries/LibraryCard/LibraryCard';
import librariesUtils from './Libraries.utils';

type LibrariesProps = {
  programId: number,
  unitId: number,
  lessonId: number,
  civicsLibrary: boolean,
  primarySource: boolean,
};

const Libraries = ({ programId, unitId, lessonId, civicsLibrary = false, primarySource = false } : LibrariesProps) => {
  const libraries = librariesUtils({ programId, lessonId, unitId, civicsLibrary, primarySource });

  return (
    <div className=" tw-h-full tw-w-full tw-bg-light-grey tw-px-[2rem]">
      <h2 className="tw-text-[1.5rem] tw-mb-[2rem] tw-mt-[1.5rem]"> {I18n.t('looking_for_more')} </h2>
      <div className="tw-flex tw-flex-row tw-gap-x-[1rem]">
        {libraries.map(libraryCard => (
          <LibraryCard
            key={libraryCard.title_tag}
            titleTag={libraryCard.title_tag}
            icon={libraryCard.icon}
            path={libraryCard.path}
          />
        ))}
      </div>
    </div>
  );
};

export default Libraries;
