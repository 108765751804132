import PropTypes from 'prop-types';
import React from 'react';
import { Footer } from 'common/Modal';
import { SelectField, TextAreaField } from 'common/Forms';
import { required } from 'common/Forms/Validators';

const NotesForm = ({
  onSubmit, closeModal, submitting, hasValidationErrors
}) => (
  <form onSubmit={onSubmit}>
    <SelectField
      name="status"
      required
      options={[
        { label: 'Prod', value: 'Prod' },
        { label: 'Content', value: 'Content' },
        { label: 'Admin', value: 'Admin' },
        { label: 'Project', value: 'Project' },
        { label: 'Final', value: 'Final' }
      ]}
      validate={required}
    />
    <TextAreaField
      name="note"
      label="Notes"
      placeholder="Enter a note (tip: include who it is directed to, if applicable)"
    />
    <Footer
      secondaryButtonCallback={closeModal}
      submitting={submitting}
      primaryButtonDisabled={hasValidationErrors}
    />
  </form>
);

NotesForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default NotesForm;
