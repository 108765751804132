import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import styles from './SliderQuestionBuilder.module.scss';

export default function SliderPointInput(props) {
  function deletePoint() {
    props.onDelete(props.id);
  }

  function renderDot() {
    return (
      <span className={styles.dot} />
    );
  }

  function renderDeletePointButton() {
    return (
      <i
        className={`fa fa-trash-o fa-fw ${styles.deletePointButton}`}
        aria-hidden="true"
        onClick={deletePoint}
      />
    );
  }

  return (
    <div>
      <div>
        {renderDot()}
      </div>
      <Field
        id={props.id}
        name={props.id}
        component="input"
        type="text"
        aria-label={`Answer choice ${props.index}`}
      />
      <div>
        {props.showDelete && renderDeletePointButton()}
      </div>
    </div>
  );
}

SliderPointInput.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  showDelete: PropTypes.bool
};

SliderPointInput.defaultProps = {
  showDelete: true
};
