import React from 'react';
import * as Routes from 'modules/routes';
import useGradesTableStore from '@/components/staffer/Scores/GradesTable/store/useGradesTableStore';

const StudentlessClassroomMessage = () => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);

  return (
    <div>
      <p className="mt50">
        You have a studentless classroom! Click Add Students to finish creating your class.
      </p>

      <a
        className="btn btn--green add_student_button mt10"
        href={Routes.new_teacher_classroom_student_path(classroom.id, { program_id: programId })}
      >
        Add Students
      </a>
    </div>
  );
};

export default StudentlessClassroomMessage;
