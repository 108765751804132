export default class PhotonAbsorptionSimulation {
  constructor(config) {
    this.container = config.container;
    this.fps = config.fps || 30;
    this.width = 660;
    this.height = 482;
    this.frame_count = 0;
    this.play_speed = 4;
    this.visible_photons = new Map();
    this.infrared_photons = new Map();
    this.paused = false;
    this.visible = true;
    this.BASE_URL = config.BASE_URL;
    this.GAS_PRESETS = {
      'ch4': {
        size: 20,
        color: '#999',
        absorption_threshold: .61,
        className: 'ch4'
      },
      'co2': {
        size: 30,
        color: '#99dddd',
        absorption_threshold: .61,
        className: 'co2'
      },
      'h2o': {
        size: 10,
        color: '#9999ee',
        absorption_threshold: .61,
        className: 'h2o'
      },
      'n2': {
        size: 15,
        color: '#990099',
        absorption_threshold: 1,
        className: 'n2'
      },
      'o2': {
        size: 15,
        color: '#99ff99',
        absorption_threshold: 1,
        className: 'o2'
      }
    };
    this.photon_urls = {
      infrared: this.BASE_URL + 'Photon_Infrared.png',
      sunlight: this.BASE_URL + 'Photon_Visible.png'
    }
  }

  init() {
    this._build();

    // Hack to preload background
    $(this.photon_gun).addClass('infrared');
    $(this.photon_gun).removeClass('infrared');

    this._setupListeners();

    this.time_marker_prev = Date.now();
    this.interval = 1000/this.fps;

    this.current_molecule = new Molecule({
      x: this.width * .7,
      y: this.height / 2,
      parent: this
    });

    window.requestAnimationFrame(this.loop.bind(this));
  }

  _build() {
    let br = document.createElement('br');

    this.backdrop = document.createElement('div');
    this.backdrop.className = 'simulation-backdrop photon-absorption';

    this.canvas = document.createElement('canvas');
    this.canvas.className = 'simulation-canvas';

    this.canvas.height = this.height;
    this.canvas.width = this.width;

    this.menu = document.createElement('div');
    this.menu.className = 'simulation-menu';

    this.legend_header = document.createElement('div');
    this.legend_header.className = 'simulation-submenu-header';
    this.legend_header.innerHTML = 'Legend';
    this.menu.appendChild(this.legend_header);

    this.legend = document.createElement('div');
    this.legend.className = 'simulation-submenu';
    this.menu.appendChild(this.legend_header);

    this.visible_photon_legend = document.createElement('div');
    this.visible_photon_legend.className = 'simulation-photon-legend visible';
    this.visible_photon_legend.innerHTML = 'Visible Light';
    this.legend.appendChild(this.visible_photon_legend);

    this.infrared_photon_legend = document.createElement('div');
    this.infrared_photon_legend.className = 'simulation-photon-legend infrared';
    this.infrared_photon_legend.innerHTML = 'Infrared Light';
    this.legend.appendChild(this.infrared_photon_legend);

    this.menu.appendChild(this.legend);

    this.gas_select_menu_header = document.createElement('div');
    this.gas_select_menu_header.className = 'simulation-submenu-header';
    this.gas_select_menu_header.innerHTML = 'Gas Type';
    this.menu.appendChild(this.gas_select_menu_header);

    this.gas_select_menu = document.createElement('div');
    this.gas_select_menu.className = 'simulation-submenu gas-type';

    this.menu.appendChild(this.gas_select_menu);

    this.ch4_radio_label = document.createElement('label');
    this.ch4_radio_label.innerHTML = 'Methane';
    this.ch4_radio = document.createElement('input');
    this.ch4_radio.id = Date.now() + 'ch4-radio';
    this.ch4_radio.type = 'radio';
    this.ch4_radio.value = 'ch4';
    this.ch4_radio.name = 'gas_type';
    this.ch4_radio.checked = true;
    this.ch4_radio_label.htmlFor = this.ch4_radio.id;
    this.gas_select_menu.appendChild(this.ch4_radio);
    this.gas_select_menu.appendChild(this.ch4_radio_label);

    this.co2_radio_label = document.createElement('label');
    this.co2_radio_label.innerHTML = 'Carbon Dioxide';
    this.co2_radio = document.createElement('input');
    this.co2_radio.id = Date.now() + 'co2-radio';
    this.co2_radio.type = 'radio';
    this.co2_radio.value = 'co2';
    this.co2_radio.name = 'gas_type';
    this.co2_radio_label.htmlFor = this.co2_radio.id;
    this.gas_select_menu.appendChild(this.co2_radio);
    this.gas_select_menu.appendChild(this.co2_radio_label);

    this.h2o_radio_label = document.createElement('label');
    this.h2o_radio_label.innerHTML = 'Water';
    this.h2o_radio = document.createElement('input');
    this.h2o_radio.id = Date.now() + 'h2o-radio';
    this.h2o_radio.type = 'radio';
    this.h2o_radio.value = 'h2o';
    this.h2o_radio.name = 'gas_type';
    this.h2o_radio_label.htmlFor = this.h2o_radio.id;
    this.gas_select_menu.appendChild(this.h2o_radio);
    this.gas_select_menu.appendChild(this.h2o_radio_label);

    this.n2_radio_label = document.createElement('label');
    this.n2_radio_label.innerHTML = 'Nitrogen';
    this.n2_radio = document.createElement('input');
    this.n2_radio.id = Date.now() + 'n2-radio';
    this.n2_radio.type = 'radio';
    this.n2_radio.value = 'n2';
    this.n2_radio.name = 'gas_type';
    this.n2_radio_label.htmlFor = this.n2_radio.id;
    this.gas_select_menu.appendChild(this.n2_radio);
    this.gas_select_menu.appendChild(this.n2_radio_label);

    this.o2_radio_label = document.createElement('label');
    this.o2_radio_label.innerHTML = 'Oxygen';
    this.o2_radio = document.createElement('input');
    this.o2_radio.id = Date.now() + 'o2-radio';
    this.o2_radio.type = 'radio';
    this.o2_radio.value = 'o2';
    this.o2_radio.name = 'gas_type';
    this.o2_radio_label.htmlFor = this.o2_radio.id;
    this.gas_select_menu.appendChild(this.o2_radio);
    this.gas_select_menu.appendChild(this.o2_radio_label);


    this.controls_header = document.createElement('div');
    this.controls_header.className = 'simulation-submenu-header';
    this.controls_header.innerHTML = 'Controls';
    this.menu.appendChild(this.controls_header);

    this.controls = document.createElement('div');
    this.controls.className = 'simulation-submenu';

    this.play_button = document.createElement('div');
    this.play_button.className = 'simulation-play-button pause';

    this.controls.appendChild(this.play_button);

    this.menu.appendChild(this.controls);

    this.photon_gun = document.createElement('div');
    this.photon_gun.className = 'simulation-photon-gun visible';

    this.photon_gun_controls = document.createElement('div');
    this.photon_gun_controls.className = 'simulation-photon-gun-controls';
    this.photon_gun.appendChild(this.photon_gun_controls);

    this.photon_slider = document.createElement('input');
    this.photon_slider.type = 'range';
    this.photon_slider.min = '0';
    this.photon_slider.max = '4';
    this.photon_slider.step = '1';
    this.photon_slider.value = '4';

    this.photon_gun_controls.appendChild(this.photon_slider);

    this.visible_photon_radio = document.createElement('div');
    this.visible_photon_radio.className = 'simulation-photon-gun-select visible';
    this.visible_photon_radio.innerHTML = 'Visible light';

    this.infrared_photon_radio = document.createElement('div');
    this.infrared_photon_radio.className = 'simulation-photon-gun-select infrared';
    this.infrared_photon_radio.innerHTML = 'Infrared light';

    this.photon_gun_controls.appendChild(this.visible_photon_radio);
    this.photon_gun_controls.appendChild(this.infrared_photon_radio);

    this.molecule = document.createElement('div');
    this.molecule.className = 'simulation-selected-molecule';

    this.backdrop.appendChild(this.canvas);
    this.container.appendChild(this.backdrop);
    this.container.appendChild(this.menu);
    this.backdrop.appendChild(this.photon_gun);
    this.backdrop.appendChild(this.molecule);

    this.ctx = this.canvas.getContext('2d');
  }

  _setupListeners() {
    $(this.play_button).on('click', () => {
      if (this.paused) {
        this.play();
      }
      else {
        this.pause();
      }
    });

    $(this.gas_select_menu).on('click', (e) => {
      if (e.target.nodeName == 'INPUT') {
        this.current_molecule.changeType(this.GAS_PRESETS[e.target.value]);
      }
    });

    $(this.photon_slider).on('change', (e) => {
      this.play_speed = e.target.value;
    });

    $(this.visible_photon_radio).on('click', () => {
      this.visible = true;
      $(this.photon_gun).removeClass('infrared');
    });

    $(this.infrared_photon_radio).on('click', () => {
      this.visible = false;
      $(this.photon_gun).addClass('infrared');
    });
  }
  pause() {
    this.play_button.className = 'simulation-play-button play';
    this.paused = true;
  }

  play() {
    this.play_button.className = 'simulation-play-button pause';
    this.paused = false;
    this.loop();
  }

  loop() {
    this.time_marker_now = Date.now();
    this.delta = this.time_marker_now - this.time_marker_prev;

    if (this.delta > this.interval) {
      this.frame_count++;

      this._clearCanvas();

      if (this.frame_count % (20 * (5 - this.play_speed)) === 0) {
        let id = this.frame_count;
        let config = {
          parent: this,
          pos_x: this.width * .2,
          pos_y: this.height * .5,
          velocity: {x: 2, y: 0},
          active: true,
          deflected: false
        };

        if (this.visible) {
          config.active = true;
          this.visible_photons.set(id, new VisiblePhoton(config));
        }
        else {
          this.infrared_photons.set(id, new InfraredPhoton(config));
        }

      }

      this.time_marker_prev = this.time_marker_now - (this.delta % this.interval);
      this._updateObjects();
      this._drawObjects();
    }

    if (!this.paused) {
      window.requestAnimationFrame(this.loop.bind(this));
    }
  }

  resize() {
    this.width = $(this.container).innerWidth();
    this.height = $(this.container).innerHeight();
  }

  _updateObjects() {
    this.visible_photons.forEach((v, k, m) => {
      if (v.pos_x > this.width || v.pos_y > this.height || v.pos_y < 0) {
        this.visible_photons.delete(k);
      }
      else {
        v.move();
      }
    });

    this.infrared_photons.forEach((v, k, m) => {
      if (v.pos_x > this.width || v.pos_y > this.height || v.pos_y < 0) {
        this.infrared_photons.delete(k);
      }
      else if (!v.deflected && v.pos_x <= (this.width / 1.24 + 10) && v.pos_x >= (this.width / 1.24 - 10)) {
        if (!this.current_molecule.collide(v)) {
          v.move();
        }
      }
      else {
        v.move();
      }
    });

    if (this.current_molecule.absorbed_photon && this.current_molecule.animate_count == 0) {
      this.current_molecule.emitPhoton();
    }
  }

  _drawObjects() {
    this.current_molecule.draw();

    this.infrared_photons.forEach((v, k, m) => {
      v.draw();
    });

    this.visible_photons.forEach((v, k, m) => {
      v.draw();
    });
  }

  _clearCanvas() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }
}

class Molecule {
  constructor(config) {
    this.parent = config.parent;
    this.pos_x = $(this.parent.container).innerWidth() / 2;
    this.pos_y = $(this.parent.container).innerHeight() / 2;
    this.size = 20;
    this.color = '#999';
    this.absorbed_photon = null;
    this.absorption_threshold = .61;
    this.animate_count = 0;
    this.image = new Image();
  }

  collide(photon) {
    if (this.absorbed_photon) {
      return false;
    }

    if (Math.random() > this.absorption_threshold) {
      this.absorbed_photon = photon;
      photon.active = false;
      photon.deflected = true;
      this.animate_count = 50;
      return true;
    }
  }

  draw() {
    if (this.animate_count > 0) {
      if (this.animate_count % 5 === 0) {
        $(this.parent.molecule).removeClass('up');
        $(this.parent.molecule).addClass('down');
      }
      else {
        $(this.parent.molecule).removeClass('down');
        $(this.parent.molecule).addClass('up');
      }
      this.animate_count--;
    }
    else {
      $(this.parent.molecule).removeClass('down');
      $(this.parent.molecule).removeClass('up');
    }
  }

  emitPhoton() {
    this.absorbed_photon.velocity = this.getRandomVelocity(this.absorbed_photon.velocity);
    this.absorbed_photon.active = true;
    this.absorbed_photon = null;
  }

  getRandomVelocity(velocity) {
    let magnitude = Math.sqrt(velocity.x * velocity.x + velocity.y + velocity.y);

    let new_x = Math.floor(Math.random() * magnitude);
    // Make sure we allow for negatives
    new_x *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;

    let new_y = Math.sqrt(magnitude * magnitude - new_x * new_x);
    new_y *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;

    return {
      x: new_x,
      y: new_y
    }
  }

  changeType(config) {
    this.size = config.size;
    this.color = config.color;
    this.image.src = config.url;
    this.absorption_threshold = config.absorption_threshold;
    this.animate_count = 0;
    if (this.absorbed_photon) {
      this.absorbed_photon.active = true;
      this.absorbed_photon = null;
    }
    this.parent.molecule.className = 'simulation-selected-molecule ' + config.className;
  }
}

class Photon {
  constructor(config) {
    this.pos_x = config.pos_x;
    this.pos_y = config.pos_y;
    this.parent = config.parent;
    this.active = config.active;
    this.velocity = config.velocity || {
      x: 8,
      y: 0
    };
    this.deflected = config.deflected;
    this.image = new Image();
    this.image.src = this.parent.photon_urls.sunlight;
  }

  move() {
    if (!this.active) return;
    this.pos_x += (this.velocity.x * .004 * this.parent.width);
    this.pos_y += (this.velocity.y * .004 * this.parent.width);
  }

  draw() {
    if (this.active) {
      this.parent.ctx.drawImage(this.image, this.pos_x, this.pos_y, this.parent.width * .02, this.parent.width * .02);
    }
  }
}

class VisiblePhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.sunlight;
  }
}

class InfraredPhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.infrared;
  }
}

function _log(fc, msg, val) {
  if (fc % 100 === 0) {
    console.log(msg, val);
  }
}
