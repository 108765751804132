import React from 'react';
import PropTypes from 'prop-types';

export default function Footer({
  page, previous, disabled, cancel, onClick, isFinalButton, primaryButtonText
}) {
  function renderBackButton() {
    if (page > 0) {
      return (
        <button type="button" onClick={previous} className="btn btn--link-purple">
          Back
        </button>
      );
    }

    return null;
  }

  function renderCancelButton() {
    if (cancel) {
      return (
        <button type="button" onClick={cancel} className="btn btn--link-purple">
          Cancel
        </button>
      );
    }
    return null;
  }

  function renderPrimaryButton() {
    return (
      <button
        type="submit"
        className="btn btn--purple"
        disabled={disabled}
        onClick={onClick}
      >
        {primaryButtonText || (isFinalButton ? 'Done' : 'Next')}
      </button>
    );
  }

  return (
    <div>
      <hr />
      <footer>
        {renderCancelButton()}
        {renderBackButton()}
        {renderPrimaryButton()}
      </footer>
    </div>
  );
}

Footer.propTypes = {
  isFinalButton: PropTypes.bool,
  onClick: PropTypes.func,
  page: PropTypes.number,
  previous: PropTypes.func,
  primaryButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  cancel: PropTypes.func
};

Footer.defaultProps = {
  isFinalButton: false,
  onClick: () => {},
  page: 0,
  previous: null,
  primaryButtonText: '',
  disabled: false,
  cancel: null
};
