import React, { Component } from 'react';
import Axios from 'axios';
import Footer from 'common/Wizard/Footer';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { renderErrors, SubmitError } from 'common/Forms/Utils';
import { ssoModelPropType } from '../../../Proptypes';
import options from './Options';
import UrlField from './UrlField';
import inputName from './utils';

export const Checkbox = ({
  className, id, input, label, onClick
}) => (
  <div className={className}>
    <input type="checkbox" id={id} {...input} onClick={onClick} />
    <label htmlFor={id}>{label}</label>
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Checkbox.defaultProps = {
  className: '',
  onClick: () => {},
};

export default class SignInPreferenceLocation extends Component {
  static propTypes = {
    next: PropTypes.func,
    ssoModel: ssoModelPropType.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    page: PropTypes.number,
    previous: PropTypes.func
  };

  static defaultProps = {
    next: () => {},
    page: 2,
    previous: () => {}
  };

  static _createParams(preferences, urls) {
    const signInPreferences = {};

    preferences.forEach((preference) => {
      signInPreferences[preference] = urls && urls[preference] || '';
    });

    return signInPreferences;
  }

  static _getParams(values) {
    const preferences = SignInPreferenceLocation
      ._createParams(values.preferences, values.sign_in_preferences);

    return {
      sso_model: {
        sign_in_preferences: preferences
      }
    };
  }

  static _handleCheckbox(args, state, utils) {
    const checkbox = args[0].target;
    if (!checkbox.checked) {
      utils.changeValue(state, `sign_in_preferences[${checkbox.value}]`, () => { ''; });
    }
  }

  static _renderInput(values, currentValue) {
    if (values.preferences && values.preferences.includes(inputName(currentValue))) {
      return (
        <UrlField currentValue={currentValue} />
      );
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const path = this.props.ssoModelPath.replace(':id', this.props.ssoModel.id);
    return (
      Axios
        .put(path, SignInPreferenceLocation._getParams(values))
        .then((response) => {
          if (response.data.errors) {
            return {
              [FORM_ERROR]: renderErrors(response.data.errors)
            };
          }
          this.props.updateSSOModel(response.data.data);
          this.props.next();
          return null;
        }).catch(error => (
          { [FORM_ERROR]: renderErrors(error.response.data.errors) }
        ))
    );
  }

  _initialFormValues() {
    return {
      preferences: (this.props.ssoModel.sign_in_preferences && Object.keys(this.props.ssoModel.sign_in_preferences)),
      sign_in_preferences: this.props.ssoModel.sign_in_preferences
    };
  }

  _renderCheckboxes(form, values) {
    return options[this.props.ssoModel.model_type].map(value => (
      <div key={value}>
        <Field
          className="ml10 inline_block w120"
          component={Checkbox}
          id={inputName(value)}
          label={value}
          name="preferences[]"
          type="checkbox"
          value={inputName(value)}
          checked={form.values}
          onClick={form.mutators.handleCheckbox}
        />

        {SignInPreferenceLocation._renderInput(values, value)}
      </div>
    ));
  }

  render() {
    return (
      <div>
        <p className="mb20">
          Next, provide the application that will be used to sign in to TCI.
        </p>
        <Form
          onSubmit={this.handleSubmit}
          mutators={{
            handleCheckbox: SignInPreferenceLocation._handleCheckbox
          }}
          initialValues={this._initialFormValues()}
        >
          {({
            handleSubmit, form, values, submitError
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                {this._renderCheckboxes(form, values)}
              </div>

              <SubmitError error={submitError} />

              <Footer
                page={this.props.page}
                previous={this.props.previous}
                isFinalButton
              />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
