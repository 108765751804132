import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { toCamelCase } from '@/modules/TCIUtils';
import QueryKeys from '@/utils/QueryKeys';

interface UsePdSlideShowProps {
  programId: number;
  slideShowId: number;
  isEnabled?: boolean;
}

export interface PdSlideShowResults {
  slideShow: {
    pdStatus: 'not_started' | 'in_progress' | 'completed';
    lesson: {
      id: number;
      titleWithPosition: string;
      professionalDevelopment: {
        stafferExerciseResult: {
          id: number;
        };
      };
    };
  };
}

const useProfessionalDevelopmentResults = ({ programId, slideShowId, isEnabled = true }: UsePdSlideShowProps) => {
  const path = Routes.plato_api_professional_development_results_slide_show_path(slideShowId, {
    program_id: programId,
  });

  const query = useQuery([QueryKeys.UseProfessionalDevelopmentResults, programId, slideShowId], async () => axios
    .get<{ data: PdSlideShowResults }>(path)
    .then(res => toCamelCase(res.data.data))
    .catch(err => console.error(err)), { enabled: isEnabled });

  return { ...query, data: query.data || {} as PdSlideShowResults };
};

export default useProfessionalDevelopmentResults;
