import * as Routes from '@/modules/routes';
import React, { useMemo } from 'react';
import { IGame, IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import useProgramAccess from '../../hooks/queries/useProgramAccess';
import useGames from '../../hooks/queries/exerciseResults/useGames';
import { compareFn } from '../Cells/GradeCells/GamesGradeCell';
import { HeaderCell } from '../Cells';

const STATE_CAPITALS_GAME = {
  game_type: 'Geography Game',
  id: 1,
  max_score: 50,
} as IGame;

const useStateCapitalsColumn = () => {
  const { data } = useProgramAccess();
  const { data: exerciseResults } = useGames();
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  return useMemo(() => {
    if (!data.includes(programId)) return [];

    const accessor = (row: IStudentRow) => row.student.id;

    return ([
      columnHelper.accessor(accessor, {
        header: () => (
          <HeaderCell title="State Capitals Game" maxScore={STATE_CAPITALS_GAME.max_score}>
            <HeaderCell.SyncGradesButton
              exerciseType="game"
              exerciseResults={exerciseResults}
              link={Routes.send_game_scores_shared_program_game_path(
                STATE_CAPITALS_GAME.id, classroom.id,
                { classroom_id: classroom.id,
                  program_id: programId },
              )}
            />
          </HeaderCell>
        ),
        id: 'state-capitals-game',
        cell: (info) => {
          const studentId = info.getValue();

          const exerciseResult = exerciseResults.find(er => compareFn(er, STATE_CAPITALS_GAME, studentId));

          if (!exerciseResult) return '-';

          const url = Routes.games_shared_question_answers_path(studentId, {
            activity_type: 'state_capitals',
            classroom_id: classroom.id,
            lesson_id: lesson.id,
            program_id: programId,
            game_id: STATE_CAPITALS_GAME.id,
          });

          return (
            <a
              id={`state-capitals-game-${STATE_CAPITALS_GAME.id}-student-${studentId}-score`}
              href={url}
              className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full"
            >
              {exerciseResult?.score ? exerciseResult.score.replace('.0', '') : '-'}
            </a>
          );
        },
      }),
    ]);
  }, [data.length]);
};

export default useStateCapitalsColumn;
