import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import Tippy from 'common/Tooltip';
import showToast from 'common/Toast';
import styles from './TextHighlighter.module.scss';
import Tooltip from './Tooltip';
import { flattenDeepArray } from '../../../modules/TCIUtils';

import {
  allNodes,
  countOccurrences,
  detectMobileDevice,
  filterTextNodes,
  loadHighlight,
  nodesBetween,
  selectorClicked,
  splitNode
} from './Util';

import marker from './marker-green.svg';
import markerWhite from './marker-white.svg';

export default class TextHighlighter extends Component {
  static propTypes = {
    containerSelector: PropTypes.string.isRequired,
    editPath: PropTypes.string.isRequired,
    modelId: PropTypes.number.isRequired,
    modelType: PropTypes.string.isRequired,
    postPath: PropTypes.string.isRequired,
    previousHighlights: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired
    })).isRequired,
    userId: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      currentColor: 'green',
      showHighlights: false,
      showTooltip: false,
      mobileDevice: detectMobileDevice(),
      range: null,
    };

    this._onTextSelect = this._onTextSelect.bind(this);
    this._createHighlight = this._createHighlight.bind(this);
    this._deleteHighlights = this._deleteHighlights.bind(this);
    this._toggleHighlights = this._toggleHighlights.bind(this);
    this._updateHighlight = this._updateHighlight.bind(this);

    document.addEventListener('selectionchange', this._onTextSelect);
  }

  componentDidMount() {
    this._addBufferElement();
    this._loadHighlights();
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this._onTextSelect);
    document.removeEventListener('mousedown', this._onTextSelect);
  }

  _loadHighlights() {
    this.props.previousHighlights.forEach((highlightData) => {
      const highlightNodes = loadHighlight(highlightData, this.props.containerSelector);
      highlightNodes.forEach(node => this._attachId(node, highlightData.id));
    });

    const anyHighlightsLoaded = document.querySelector(`.${styles.highlight}`);
    this.setState({ showHighlights: anyHighlightsLoaded });
  }

  _getHighlightableArea() {
    return document.querySelector(this.props.containerSelector);
  }

  // If a user double clicks a text element that is the last child of the highlightable area, the selection will bleed
  // over outside the container. This function appends an invisible buffer to the end of the highlightable area so that
  // the last visible element is never the last element in the container's DOM. This will ensure that if a user selects
  // the last visible element within the highlightable area, the selection will actually stay contained within the area
  _addBufferElement() {
    const highlightableArea = this._getHighlightableArea();

    if (!highlightableArea) return;

    const bufferElement = document.createElement('div');
    bufferElement.className = styles.buffer;
    bufferElement.innerHTML = '-';
    bufferElement.setAttribute('ignore', true);
    bufferElement.setAttribute('aria-hidden', 'true');

    highlightableArea.appendChild(bufferElement);
  }

  _nodeInHighlightableArea(node) {
    const highlightableArea = this._getHighlightableArea();
    if (highlightableArea === node) return true;
    return highlightableArea.contains(node);
  }

  // Returns true if both of the following are true:
  // 1) The user is currently selecting text in the document
  // 2) Both the start node and end node of the selection are contained within the highlightable area
  _textIsSelected() {
    const selection = window.getSelection();

    const selectionContainsText = selection.toString().replace(/\s+/, '').length > 0;
    if (!selectionContainsText) {
      this.setState({ ...this.state, range: null });
      return false;
    }

    this.setState({ ...this.state, range: selection.getRangeAt(0) });

    const anchorNodeInParent = this._nodeInHighlightableArea(selection.anchorNode);
    const focusNodeInParent = this._nodeInHighlightableArea(selection.focusNode);
    return anchorNodeInParent && focusNodeInParent;
  }

  // If the user has any text selected, returns any highlights that the selection touches
  _selectedHighlightIds() {
    const selection = window.getSelection();
    if (selection.isCollapsed) return [];

    const range = selection.getRangeAt(0);
    const nodesInRange = nodesBetween(range.startContainer, range.endContainer, this.props.containerSelector, true);
    const textNodesInRange = filterTextNodes(nodesInRange);

    const highlightNodesInRange = textNodesInRange
      .map(node => $(node.parentNode).closest(`.${styles.highlight}:not(.${styles.invisible})`)[0])
      .filter(node => node);

    const classes = flattenDeepArray(highlightNodesInRange.map(node => Array.from(node.classList)));

    // Highlight id classes have the format highlight-1, highlight-2, etc.
    // Extracts the ids from these classes
    const highlightIds = classes
      .filter(className => className.match(/highlight-(\d+)/))
      .map(className => className.match(/highlight-(\d+)/)[1]);

    // Return unique values
    return Array.from(new Set(highlightIds));
  }

  _showTooltip(e, tooltipMode, highlightIds = []) {
    const containerDimensions = this.tooltipContainer.getBoundingClientRect();
    highlightIds = highlightIds.concat(this._selectedHighlightIds());

    const selectionBoundingBox = window.getSelection().getRangeAt(0).getBoundingClientRect();

    const tooltipX = selectionBoundingBox.x + (selectionBoundingBox.width / 2) - containerDimensions.left;
    let tooltipY = selectionBoundingBox.y - containerDimensions.top;
    if (this.state.mobileDevice) tooltipY += selectionBoundingBox.height;

    this.setState({
      highlightIds,
      showTooltip: true,
      tooltipMode,
      tooltipX,
      tooltipY
    });
  }

  _onTextSelect(e) {
    if (this._textIsSelected()) {
      this._showTooltip(e, 'create');
    }
    else {
      this.setState({ showTooltip: false });
    }
  }

  // Finds the index of the highlighted text by searching within all text up to and including the highlight
  // Then wraps the highlight and saves the data to the database
  _createHighlight({ color = this.state.currentColor }) {
    const selection = window.getSelection();

    let range;
    try {
      range = selection.getRangeAt(0);
    } catch {
      range = this.state.range;
    }

    const textNodesInSelection = filterTextNodes(allNodes(range.cloneContents()));
    let selectionText = textNodesInSelection.reduce((text, node) => text + node.textContent, '').replace(/\s+/g, ' ');
    const nextChar = selection.focusNode.textContent.charAt(selection.focusOffset);
    const includeNextChar = nextChar === '.';
    if (includeNextChar) selectionText += '.';

    const endOffset = includeNextChar ? range.endOffset + 1 : range.endOffset;
    const endNode = splitNode(range.endContainer, endOffset, true);

    const areaNodes = allNodes(this._getHighlightableArea());
    let endNodeIndex = areaNodes.indexOf(endNode);
    if (includeNextChar) endNodeIndex += 1;

    const textNodesUpToRange = areaNodes.filter((node, index) => index < endNodeIndex && node.nodeType === Node.TEXT_NODE);
    const textUpToRange = textNodesUpToRange.reduce((total, node) => total + node.textContent, '');

    const index = countOccurrences(selectionText, textUpToRange) - 1;

    const highlightNodes = loadHighlight({ color, index, text: selectionText }, this.props.containerSelector);

    window.getSelection().removeAllRanges();
    this.setState({ currentColor: color, showTooltip: false, range: null });

    this._toggleHighlights({ showHighlights: true });
    return this._saveHighlight(selectionText, index, highlightNodes, color);
  }

  _saveHighlight(selectionText, index, highlights, color) {
    const data = {
      highlight: {
        color,
        index,
        model_id: this.props.modelId,
        model_type: this.props.modelType,
        text: selectionText,
        user_id: this.props.userId,
        user_type: this.props.userType
      }
    };

    return Axios.post(this.props.postPath, data)
      .then((response) => {
        const id = response.data.data.id;
        highlights.forEach(node => this._attachId(node, id));
      });
  }

  _deleteHighlights() {
    this.state.highlightIds.forEach((id) => {
      const path = this.props.editPath.replace('highlight_id', id);
      Axios.delete(path);
      this.setState({ showTooltip: false });

      document.querySelectorAll(`.highlight-${id}`).forEach((highlight) => {
        highlight.className = '';
        $(highlight).off();
      });
    });

    if (document.querySelectorAll(`.${styles.highlight}`).length === 0) {
      this.setState({ showHighlights: false });
    }

    window.getSelection().removeAllRanges();
  }

  _attachId(node, id) {
    node.classList.add(`highlight-${id}`);
    node.classList.add(styles.saved);

    $(node).on('click touchstart', (e) => {
      if (selectorClicked(e, `.${styles.invisible}`) || !selectorClicked(e, `.${styles.highlight}`)) return;
      if (this._textIsSelected()) return;

      e.stopPropagation();
      this._showTooltip(e, 'edit', [id]);
    });
  }


  _toggleHighlights({ showHighlights = !this.state.showHighlights }) {
    const allHighlightNodes = document.querySelectorAll(`.${styles.highlight}`);

    if (allHighlightNodes.length === 0) {
      showToast('To add a highlight, select text and choose your color.', { autoClose: 5000, msgType: 'info' });
      return;
    }

    allHighlightNodes.forEach((currentHighlightNode) => {
      currentHighlightNode.classList.toggle(styles.invisible, !showHighlights);
    });

    this.setState({ showHighlights });
  }

  _renderHighlightsToggle() {
    const label = this.state.showHighlights ? 'Hide highlights' : 'Show highlights';
    const toggleContainer = document.querySelector('#highlights-toggle');
    if (!toggleContainer) return null;

    return ReactDOM.createPortal((
      <Tippy content={label} theme="white" size="medium">
        <button
          type="button"
          className={`text-action ${this.state.showHighlights && 'purple'} ${styles.button}`}
          aria-label={label}
          data-tippy={label}
          onClick={this._toggleHighlights}
        >
          <img alt="highlighter image" src={this.state.showHighlights ? markerWhite : marker} />
        </button>
      </Tippy>
    ), toggleContainer);
  }

  _updateHighlight({ color }) {
    const path = this.props.editPath.replace('highlight_id', this.state.highlightIds[0]);
    Axios.patch(path, { highlight: { color: color } }).catch(error => console.log(error));

    document.querySelectorAll(`.highlight-${this.state.highlightIds[0]}`).forEach((highlight) => {
      highlight.classList.remove(styles.green, styles.blue, styles.orange, styles.rose, styles.yellow, styles.purple);
      highlight.classList.add(styles[color]);
    });

    this.setState({ currentColor: color, showTooltip: false });
  }

  render() {
    return (
      <div className={styles.tooltipContainer} ref={(container) => { this.tooltipContainer = container; }}>
        {this.state.showTooltip && (
          <Tooltip
            currentColor={this.state.currentColor}
            showDelete={this.state.highlightIds.length > 0}
            mode={this.state.tooltipMode}
            xPosition={this.state.tooltipX}
            yPosition={this.state.tooltipY}
            createHighlight={this._createHighlight}
            deleteHighlights={this._deleteHighlights}
            updateHighlight={this._updateHighlight}
            mobileDevice={this.state.mobileDevice}
          />
        )}
        {this._renderHighlightsToggle()}
      </div>
    );
  }
}
