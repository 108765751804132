import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import * as Routes from '@/modules/routes';
import { INPUT_VARIANTS } from '@/components/ui/Form/variants';
import Modal, { Footer } from '@/components/common/Modal';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';
import { SlideObject } from '@/components/admin/SlideShow/types';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { toCamelCase } from '@/modules/TCIUtils';
import { InteractiveMapFormData } from './types';
import MapViewer from './MapViewer';
import InstructionsEditor from './InstructionsEditor';

interface ModalManager {
  close: () => void;
  isOpen: boolean;
  open: () => void;
}

interface SlideObjectInteractiveMapProps {
  interactable?: boolean;
  modalManager: ModalManager;
  slideObject: SlideObject;
  updateSlideHtml: (arcgisMapHtml: { html: string }) => {};
}

const INVALID_APP_ID_MESSAGE = 'Invalid app ID. Please ensure the app ID is correct and the '
  + 'instant app is shared with everyone (public).';
const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please refresh and try again.';

const SlideObjectInteractiveMap = ({
  interactable = true,
  modalManager,
  slideObject,
}: SlideObjectInteractiveMapProps) => {
  const modelInteractiveMap = slideObject.modelInteractiveMaps && slideObject.modelInteractiveMaps[0];
  const {
    interactiveMap = { appId: '' },
    instructionsEn = '',
  } = modelInteractiveMap || {};

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<InteractiveMapFormData>({ defaultValues: {
    app_id: interactiveMap?.appId,
    instructions_en: instructionsEn,
  } });

  useEffect(() => {
    reset({
      app_id: interactiveMap?.appId,
      instructions_en: instructionsEn,
    });
  }, [slideObject.id]);

  const onSubmit = async (vals: InteractiveMapFormData) => {
    const path = Routes.plato_api_slide_object_model_interactive_maps_path(slideObject.id, vals);

    setIsSaving(true);
    Axios.post<{ data: InteractiveMapFormData }>(path)
      .then((res) => {
        updateSlideObject({
          ...slideObject,
          modelInteractiveMaps: [toCamelCase(res.data.data)],
        });

        reset(vals);
        modalManager.close();
      })
      .catch((e) => {
        if (e.response?.data?.errors === 'InvalidAppId') {
          setError(INVALID_APP_ID_MESSAGE);
        } else {
          setError(GENERIC_ERROR_MESSAGE);
        }
      })
      .finally(() => setIsSaving(false));
  };

  const modalFooter = () => (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={handleSubmit(onSubmit)}
      primaryButtonClassName="btn btn--purple"
      primaryButtonText="Save"
      primaryButtonSubmit={false}
      primaryButtonDisabled={!isDirty}
      secondaryButtonCallback={() => modalManager.close()}
      submitting={isSaving}
      submittingText="Saving..."
      wrapperClassName="pr20 pl20 pb20"
    />
  );

  if (modalManager.isOpen && interactable) {
    return (
      <Modal
        closeModal={() => modalManager.close()}
        footer={modalFooter()}
        headerText="Add Interactive Map"
        isOpen={modalManager.isOpen}
        nearlyFullScreen
      >
        <div className="tw-mb-2">
            For a list of instant apps managed by TCI, go to&nbsp;
          <a href="https://www.teachtci.com/tci-arcgis-apps">
              TCI&apos;s ArcGIS Apps.
          </a>
        </div>
        <div className="mb-4">
          <label htmlFor="appId" className="tw-block tw-text-sm tw-font-bold tw-mb-1">
              ArcGIS App ID
            <RequiredAsterisk />
          </label>
          <div className="tw-flex">
            <input
              id="appId"
              type="text"
              className={INPUT_VARIANTS.default}
              {...register('app_id')}
            />
            {error && (
              <div className="tw-flex tw-items-center tw-text-red tw-ml-2">
                {error}
              </div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="instructions" className="tw-block tw-text-sm tw-font-bold tw-mb-1">
            Instructions
          </label>
          <InstructionsEditor initialInstructions={instructionsEn} setValue={setValue} />
        </div>
      </Modal>
    );
  }

  return <MapViewer slideObject={slideObject} showOverlay showHeader />;
};

export default SlideObjectInteractiveMap;
