import React, { useState } from 'react';
import Table from '@/components/admin/ProgramsTable/Table';
import useProgramsTableData from '@/components/admin/ProgramsTable/useProgramsTableData';
import AddProgram from 'components/admin/ProgramManagement/AddProgram';

interface AdminProgramsTableProps {
  custom?: boolean;
}

const AdminProgramsTable = ({ custom }: AdminProgramsTableProps) => {
  const [isArchived, setIsArchived] = useState(false);
  const { isLoading, tableData, archivedTableData } = useProgramsTableData({ custom });

  return (
    <div className="tabs_container">
      <div className="tabs_container_wrapper tw-relative np">
        <span className="tw-float-right">
          <label>
            <input
              disabled={isLoading}
              type="checkbox"
              checked={isArchived}
              onChange={(e) => {
                setIsArchived(e.target.checked);
              }}
            />
            Show Archived Programs
          </label>&nbsp;
          <AddProgram disabled={isLoading} />
        </span>

        <div className="clear" />

        <hr />

        <Table
          isLoading={isLoading}
          archived={isArchived}
          custom={custom}
          tableData={tableData}
          archivedTableData={archivedTableData}
        />
      </div>
    </div>
  );
};

export default AdminProgramsTable;
