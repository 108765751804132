/* eslint-disable no-param-reassign */

import * as fabric from 'fabric';
import textToolCursor from '../cursors/text_tool_cursor.svg';
import I18n from 'i18n'

const Text = (canvas) => {
  canvas.freeDrawingBrush = null;
  canvas.isDrawingMode = false;

  canvas.defaultCursor = `url(${textToolCursor}), auto`;
  canvas.hoverCursor = `url(${textToolCursor}), auto`;
  canvas.moveCursor = `url(${textToolCursor}), auto`;
};

export const placeText = (canvas, color) => {
  const pointerPosition = canvas.getViewportPoint();

  const text = new fabric.IText(I18n.t('start_typing_here'), {
    angle: 0,
    centerTransform: true,
    editingBorderColor: '#000000',
    fill: color,
    fontFamily: 'Lato, Helvetica, Arial, sans-serif',
    fontWeight: '',
    hasRotatingPoint: true,
    left: pointerPosition.x / canvas.getZoom(),
    originX: 'left',
    padding: 5,
    scaleX: 0.5,
    scaleY: 0.5,
    top: pointerPosition.y / canvas.getZoom(),
  });

  text.enterEditing();
  text.selectAll();
  canvas.add(text);
  canvas.setActiveObject(text);
  text.initHiddenTextarea();
  text.hiddenTextarea.focus();
  canvas.calcOffset();
};

export default Text;
