import React from 'react';
import axios from 'axios';
import Tooltip from '@/components/common/Tooltip';
import useFlags from '@/hooks/useFlags';
import cn from '@/utils/cn';
import { queryClient } from '../../../GradesTableQueryClientProvider';

export interface ClearAllButtonsProps {
  assignmentType?: 'lesson game' | 'assessment';
  isDisabled?: boolean;
  link: string;
  queryKey: any[];
}

const ClearAllButton = ({
  assignmentType = 'assessment',
  isDisabled = true,
  link,
  queryKey,
}: ClearAllButtonsProps) => {
  const { updatedGradesTable } = useFlags();

  const additionalMarkup = () => {
    if (!updatedGradesTable) return null;

    return <i className={cn('fa fa-trash-o tw-text-red', { 'tw-text-inherit': isDisabled })} />;
  };

  const reset = () => {
    axios
      .post(link)
      .then(() => queryClient.invalidateQueries({
        queryKey,
        exact: true,
        refetchType: 'active',
      }));
  };

  return (
    <Tooltip
      content="There is no student work to clear."
      interactive={false}
      theme="white"
      shouldDisplay={isDisabled}
      size="medium"
      delay={750}
    >
      <span>
        <button
          aria-label={`Clear all ${assignmentType} scores`}
          data-confirm={`This action will delete this classroom's ${assignmentType} results. Are you sure?`}
          className={cn('tw-w-full', {
            'dropdown-item tw-px-6': updatedGradesTable,
            'btn btn--sm btn--outline-red': !updatedGradesTable,
          })}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          id={`reset_class_${assignmentType}_scores`}
          type="button"
          onClick={reset}
        >
          {additionalMarkup()}
          Clear All
        </button>
      </span>
    </Tooltip>
  );
};

export default ClearAllButton;
