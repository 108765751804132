import { IStatus, ILowScoresSettings } from '@/components/staffer/Scores/GradesTable/types';
import { COLOR_LEGEND } from '@/components/staffer/Scores/GradesTable/variables';

/**
 * Rounds the given score to the nearest 10th and removes the `.0` if it's a whole number.
 * @param {string} score
 * @returns {number} - The score rounded to the nearest 10th
 */
export const roundScore = (score: string | number) => Math.round(parseFloat(score.toString()) * 10) / 10;

export type CellStatusExerciseResult = {
  score?: string;
  graded_at?: string;
  completed: boolean;
};
export type CellStatusQuestionAnswer = {
  score?: string;
};
interface GradeCellStatusProps {
  exerciseResultForStudent?: CellStatusExerciseResult;
  lowScoresSettings: ILowScoresSettings;
  maxScore?: number;
  questionAnswersForStudent: CellStatusQuestionAnswer[];
};
export const gradeCellStatus = (
  { exerciseResultForStudent, questionAnswersForStudent, maxScore, lowScoresSettings }: GradeCellStatusProps
) => {
  const status: IStatus = { color: '', text: '-' };

  if (exerciseResultForStudent?.completed) {
    const { score } = exerciseResultForStudent;
    const graded: boolean = !!exerciseResultForStudent.graded_at;

    if (!graded || score == null) {
      status.text = 'Turned In';
      status.color = COLOR_LEGEND.completed;
      return status;
    }

    status.text = roundScore(score);
    status.color = lowScoreHighlightColor({ score, maxScore, lowScoresSettings });

    return status;
  }

  if (questionAnswersForStudent.length > 0) {
    status.color = COLOR_LEGEND.in_progress;

    if (questionAnswersForStudent.some(qa => !!qa.score)) {
      status.text = questionAnswersForStudent.reduce((acc, cur) => acc + (parseFloat(cur.score) || 0), 0);
    } else {
      status.text = 'In Progress';
    }
  }

  return status;
};

export const lowScoreHighlightColor = ({ score, maxScore, lowScoresSettings }) => {
  if (score == null || maxScore == null) return '';

  const lowScoreThreshold = lowScoresSettings.enabled ? maxScore * lowScoresSettings.threshold / 100 : null;
  const shouldHighlight = lowScoreThreshold != null && parseInt(score, 10) <= lowScoreThreshold;

  return shouldHighlight ? COLOR_LEGEND.low_score : '';
};
