import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { renderToString } from 'react-dom/server';
import * as Routes from '@/modules/routes';
import ProgramCell from '@/components/admin/ProgramAccess/ProgramAccessTable/ProgramCell';
import { ISeatPool, ISubscriber, IUserType } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import ProgramActionsDropdown from '../ProgramActionsDropdown';
import ProgramCode from './ProgramCode';

const getColumnDefs = (subscriber: ISubscriber, userType: IUserType) => {
  const baseColumnDefs = [
    {
      targets: 0,
      sortable: false,
      searchable: false,
      width: '16%',
      className: 'tw-align-middle',
      createdCell: (td, _cellData, rowData) => {
        const exclusionSetting = rowData.exclusion_setting.replaceAll('_', '-');
        if (exclusionSetting === 'no-exclusions') return;
        // .exclude-from-rostering-row, .remove-all-students-row classes from stylesheets/_program_home.scss
        td.parentElement.classList.add(`${exclusionSetting}-row`);
      },
      render: (_data: undefined, _type, row: ISeatPool) => (
        row.program.licenses.find(license => license.id === row.license_id).name
      ),
    },
    {
      targets: 1,
      sortable: false,
      searchable: false,
      className: 'tw-align-middle',
      render: (data, type, row: ISeatPool) => (
        renderToString(
          <div className="tw-flex tw-items-center">
            {row.grade || 'None'}
            {userType !== 'Coordinator' && (
              <a
                aria-label="Edit grade"
                className="tw-ml-2 tw-text-xl"
                href={Routes.edit_admin_subscriber_seat_pool_path(subscriber.id, row.id, { grade: 1 })}
              >
                <span className="fa fa-edit" />
              </a>
            )}
          </div>,
        )
      ),
    },
    {
      targets: 2,
      sortable: false,
      searchable: false,
      width: '42%',
      className: 'tw-align-middle',
      render: (data, type, row: ISeatPool) => (
        row.program && renderToString(<ProgramCell program={row.program} />)
      ),
    },
    {
      targets: 3,
      sortable: false,
      searchable: false,
      className: 'tw-align-middle tw-break-all',
      width: '20%',
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(<ProgramCode rowData={rowData} />, td);
      },
    },
    {
      title: 'Teachers',
      targets: 4,
      sortable: false,
      searchable: false,
      className: 'tw-align-middle tw-text-center',
    },
    {
      title: 'Students',
      targets: 5,
      sortable: false,
      searchable: false,
      className: 'tw-align-middle tw-text-center',
    },
  ];

  if (userType === 'Coordinator') return baseColumnDefs;
  return ([
    ...baseColumnDefs,
    {
      targets: -1,
      sortable: false,
      searchable: false,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <ProgramActionsDropdown
            exclusionSetting={rowData.exclusion_setting}
            initiallyUnderReview={rowData.under_review}
            isSysadmin
            seatPoolId={rowData.id}
            showReviewButton={false}
            licenseId={rowData.program.license_id}
            subscriberStateId={subscriber.state_id}
          />,
          td,
        );
      },
    },
  ]);
};

const useProgramAccessTableColumnDefs = (subscriber: ISubscriber, userType: IUserType) => (
  useMemo(() => getColumnDefs(subscriber, userType), [subscriber.id, userType])
);

export default useProgramAccessTableColumnDefs;
