import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'common/Modal';
import { SubmitError } from 'common/Forms/Utils';
import { renderToString } from 'react-dom/server';
import { CheckboxField, FileField, LabelField, SelectField, TextAreaField, TextField } from 'common/Forms';
import { composeValidators, fileSizeLimiter, required } from 'common/Forms/Validators';
import { Field, Form } from 'react-final-form';
import styles from './Form.module.scss';
import useDescribeImage from './hooks/useDescribeImage.ts';
import DescriptionConfidenceField from './DescriptionConfidenceField';
import { ImagePreview } from '../../common/Forms';

export const FILENAME_LENGTH_SERVER_ERROR = 'file name is greater than 200 characters';
export const FILENAME_LENGTH_ERROR_MESSAGE = 'Filename must be fewer than 200 characters.';
export const DEFAULT_ERROR_MESSAGE = 'Error adding image.';

const ImageModelForm = ({
  imageModel,
  onSubmit,
  submitting,
  closeModal,
  submitError,
  defaultImage,
  isAdmin,
  fromSlideShow,
  excludeFields,
  action,
}) => {
  const [validationError, setValidationError] = useState();
  const [removeSpanishImage, setRemoveSpanishImage] = useState(false);
  const { describeImage, loading } = useDescribeImage();
  const inTeacherSlideshow = fromSlideShow && !isAdmin;

  useLayoutEffect(() => {
    // pre-populate the input with a default image file if given
    if (defaultImage) {
      const fileInputElement = document.getElementById('image_field');
      const container = new DataTransfer();
      container.items.add(defaultImage);
      fileInputElement.files = container.files;
    }
  }, []);

  const altTextFieldTooltip = () => (
    <span>
      See
      {' '}
      <a
        href="http://teachtci.com/tci-alt-text-best-practice"
        rel="noopener noreferrer"
        target="_blank"
      >
        this document
      </a>
      {' '}
      for best practices.
    </span>
  );

  const getInitialValues = () => {
    if (imageModel) {
      return (
        {
          alignment: imageModel.alignment,
          caption_en: imageModel.captionEn,
          caption_es: imageModel.captionEs,
          credits: imageModel.image_credits,
          decorative: imageModel.decorative,
          description_confidence: imageModel.image_descriptionConfidence,
          description_en: imageModel.image_descriptionEn || imageModel.image.descriptionEn,
          description_es: imageModel.image_descriptionEs || imageModel.image.descriptionEs,
          display_size: imageModel.displaySize,
          english_image_only: imageModel.image.englishImageOnly,
          has_text: imageModel.image.hasText,
          hide_captions: imageModel.hide_captions,
          title_en: imageModel.image_titleEn,
          title_es: imageModel.image_titleEs,
          zoom_type: imageModel.zoomType
        }
      );
    }

    const defaults = {
      display_size: 'large',
      zoom_type: 'magnifier'
    };

    // pre-populate the form data with an image file if given
    if (defaultImage) defaults.image = defaultImage;

    return defaults;
  };

  const decorativeCheckbox = form => (
    <div className={styles.decorative}>
      <CheckboxField
        name="decorative"
        label="Decorative?"
        onClick={(e) => {
          form.mutators.handleDecorativeChange(e.target.value === 'true');
        }}
      />
      <span className="ml5">No Alt Text</span>
    </div>
  );

  const hideCaptionsCheckbox = form => (
    <div className={styles.decorative}>
      <CheckboxField
        name="hide_captions"
        label="Hide Captions"
        onClick={(e) => {
          form.mutators.handleHideCaptionsChange(e.target.checked);
        }}
      />
    </div>
  );

  const isDecorative = form => form.getState().values.decorative;

  const showMetaData = isAdmin || !fromSlideShow;

  const hasTextCheckbox = form => (
    <div className={styles.decorative}>
      <CheckboxField
        name="has_text"
        label="Has Text?"
        onClick={(e) => {
          form.mutators.handleHasTextChange(e.target.value === 'true');
        }}
      />
    </div>
  );

  const englishImageOnlyCheckbox = form => (
    <div className={styles.decorative}>
      <CheckboxField
        name="english_image_only"
        label="English Image Only"
        onClick={(e) => {
          form.mutators.handleEnglishImageOnlyChange(e.target.value === 'true');
        }}
      />
    </div>
  );

  const fileTypeText = () => {
    if (inTeacherSlideshow) return 'Image Requirement: .JPEG / .JPG / .PNG and less than 2MB';

    return null;
  };

  const validate = (optional = false) => {
    const under2MB = fileSizeLimiter(2, 'Image');

    if (inTeacherSlideshow) {
      return (optional ? under2MB : composeValidators(required, under2MB));
    }

    if (imageModel || defaultImage || optional) return null;

    return required;
  };

  const errorMessage = submitError || validationError || DEFAULT_ERROR_MESSAGE;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues()}
      mutators={{
        handleDecorativeChange: (args, state, utils) => {
          utils.changeValue(state, 'decorative', () => args[0]);
        },
        handleDescriptionChange: (args, state, utils) => {
          utils.changeValue(state, 'description_en', () => args[0]);
        },
        handleDescriptionConfidenceChange: (args, state, utils) => {
          utils.changeValue(state, 'description_confidence', () => args[0]);
        },
        handleHasTextChange: (args, state, utils) => {
          utils.changeValue(state, 'has_text', () => args[0]);
        },
        handleEnglishImageOnlyChange: (args, state, utils) => {
          utils.changeValue(state, 'english_image_only', () => args[0]);
        },
        handleSpanishImageChange: (args, state, utils) => {
          utils.changeValue(state, 'remove_spanish_image', () => args[0]);
        },
        handleHideCaptionsChange: (args, state, utils) => {
          utils.changeValue(state, 'hide_captions', () => args[0]);
        },
      }}
      render={({
        form, handleSubmit, invalid, pristine, values
      }) => (
        <form className="tw-flex tw-flex-col tw-justify-between tw-h-full" onSubmit={handleSubmit}>
          <div className="tw-flex tw-justify-around">
            <div className="tw-flex-auto tw-w-0 tw-pr-3">
              {imageModel && (
                <LabelField
                  id="current_image"
                  label="Current Image (English)"
                  content={(
                    <div className="tw-flex tw-gap-2">
                      <ImagePreview src={imageModel.image.imageUrl} />
                      {imageModel.image_fileName}
                    </div>
                  )}
                />
              )}

              <FileField
                name="image"
                label={`${!imageModel ? 'Image' : 'Replace Image'} (English)`}
                accept="image/png, image/jpg, image/jpeg, image/gif"
                required={!imageModel}
                validate={validate(true)}
                loading={loading}
                useImagePreview
                onChangeCallback={async (newFile) => {
                  const params = new FormData();
                  params.append('image', newFile);

                  const response = await describeImage(params);
                  const { description, confidence, errors } = response.data;
                  if ((errors || []).length > 0) {
                    setValidationError(errors.find(e => e.includes(FILENAME_LENGTH_SERVER_ERROR)) ?
                      FILENAME_LENGTH_ERROR_MESSAGE :
                      DEFAULT_ERROR_MESSAGE);
                  }

                  form.mutators.handleDescriptionChange(description);
                  form.mutators.handleDescriptionConfidenceChange(confidence);
                }}
                fileTypeText={fileTypeText()}
              />

              {englishImageOnlyCheckbox(form)}

              <div>
                <div style={{ display: `${showMetaData ? '' : 'none'}` }}>
                  <TextField
                    name="title_en"
                    placeholder="Enter a title of the image in English..."
                    label="Title (English)"
                  />
                  {!excludeFields.includes('caption_en') && (
                    <TextAreaField
                      name="caption_en"
                      placeholder="Enter a caption of the image in English..."
                      label="Caption (English)"
                    />
                  )}

                  {hideCaptionsCheckbox(form)}

                  <TextField
                    name="credits"
                    placeholder="Enter image credits..."
                    label="Credits"
                  />

                  {decorativeCheckbox(form)}

                  {['Edit Image', 'Edit Background Image'].includes(action) && hasTextCheckbox(form)}

                  <TextAreaField
                    name="description_en"
                    placeholder="Enter a description of the image in English..."
                    label="Alt Text (English)"
                    tooltipText={renderToString(altTextFieldTooltip())}
                    disabled={isDecorative(form)}
                    onChangeCallback={() => {
                      // Clear description confidence if description_en is changed manually
                      form.mutators.handleDescriptionConfidenceChange(null);
                    }}
                  />

                  <DescriptionConfidenceField
                    disabled={!!values.description_confidence || !(values.image || imageModel) || loading}
                    handleClick={async () => {
                      let params;
                      if (values.image) {
                        params = new FormData();
                        params.append('image', values.image);
                      }
                      else {
                        params = { image_model_id: imageModel.id };
                      }

                      const response = await describeImage(params);
                      const { description, confidence } = response.data;

                      form.mutators.handleDescriptionChange(description);
                      form.mutators.handleDescriptionConfidenceChange(confidence);
                    }}
                    field={(
                      <Field
                        name="description_confidence"
                        format={value => (value ? `${(value * 100).toFixed(1)}%` : value)}
                        parse={value => (value ? parseFloat(value) : value)}
                        render={({ input }) => (
                          <div>
                            <span className="tw-mx-2">
                              <i>Confidence: {input.value || 'N/A'}</i>
                            </span>
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
                {!excludeFields.includes('display_size') && (
                  <SelectField
                    name="display_size"
                    options={[
                      { label: 'small', value: 'small' },
                      { label: 'medium', value: 'medium' },
                      { label: 'large', value: 'large' },
                      { label: 'xlarge', value: 'xlarge' },
                    ]}
                    placeholder="Select display size..."
                  />
                )}
                <div style={{ display: `${showMetaData ? '' : 'none'}` }}>
                  {!excludeFields.includes('zoom_type') && (
                    <SelectField
                      name="zoom_type"
                      options={[
                        { label: 'magnifier', value: 'magnifier' },
                        { label: 'sliders', value: 'sliders' },
                        { label: 'in/out buttons', value: 'in/out buttons' }
                      ]}
                      placeholder="Select zoom type..."
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="tw-flex-auto tw-w-0 tw-pl-3">
              {imageModel?.image_fileNameEs && !removeSpanishImage && (
                <div className="tw-flex tw-items-center">
                  <LabelField
                    id="current_image_es"
                    label="Current Image (Spanish)"
                    content={(
                      <div className="tw-flex tw-gap-2">
                        <ImagePreview src={imageModel.image.imageEsUrl} />
                        {imageModel.image_fileNameEs}
                      </div>
                    )}
                  />
                  <button
                    className="btn btn--sm btn--red tw-ml-6"
                    type="button"
                    onClick={() => {
                      setRemoveSpanishImage(true);
                      form.mutators.handleSpanishImageChange(true);
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}

              <div style={{ display: 'none' }}>
                <CheckboxField
                  label="Remove Image (Spanish)"
                  name="remove_spanish_image"
                />
              </div>

              <FileField
                label={`${!imageModel?.image_fileNameEs ? 'Image' : 'Replace Image'} (Spanish)`}
                name="image_es"
                accept="image/png, image/jpg, image/jpeg, image/gif"
                validate={validate(true)}
                fileTypeText={fileTypeText()}
                useImagePreview
              />

              <div style={{ display: `${showMetaData ? '' : 'none'}` }}>
                <TextField
                  name="title_es"
                  placeholder="Enter a title of the image in Spanish..."
                  label="Title (Spanish)"
                />

                {!excludeFields.includes('caption_es') && (
                  <TextAreaField
                    name="caption_es"
                    placeholder="Enter a caption of the image in Spanish..."
                    label="Caption (Spanish)"
                  />
                )}
                <TextAreaField
                  name="description_es"
                  placeholder="Enter a description of the image in Spanish..."
                  label="Alt Text (Spanish)"
                  disabled={isDecorative(form)}
                />
              </div>
            </div>
          </div>

          {(submitError || validationError) &&
            <SubmitError error={`${errorMessage} Please reload the page and try again.`} />
          }

          <Footer
            submitting={submitting}
            secondaryButtonCallback={closeModal}
            primaryButtonText="Submit"
            primaryButtonDisabled={submitError || validationError || invalid || (pristine && !defaultImage)}
          />
        </form>
      )}
    />
  );
};

ImageModelForm.propTypes = {
  action: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultImage: PropTypes.object,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es'])),
  fromSlideShow: PropTypes.bool,
  submitError: PropTypes.string,
  imageModel: PropTypes.shape({
    alignment: PropTypes.string,
    captionEn: PropTypes.string,
    captionEs: PropTypes.string,
    decorative: PropTypes.bool,
    displaySize: PropTypes.string,
    hideCaptions: PropTypes.bool,
    id: PropTypes.number.isRequired,
    image_credits: PropTypes.string,
    image_descriptionConfidence: PropTypes.number,
    image_descriptionEn: PropTypes.string,
    image_descriptionEs: PropTypes.string,
    image_fileName: PropTypes.string.isRequired,
    image_fileNameEs: PropTypes.string,
    image_titleEn: PropTypes.string,
    image_titleEs: PropTypes.string,
    zoomType: PropTypes.string,
    image: PropTypes.shape({
      englishImageOnly: PropTypes.bool,
      hasText: PropTypes.bool,
      imageUrl: PropTypes.string,
      imageEsUrl: PropTypes.string,
    }),
  }),
  isAdmin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ImageModelForm.defaultProps = {
  excludeFields: [],
  fromSlideShow: false,
  imageModel: null,
  isAdmin: false,
};

export default ImageModelForm;
