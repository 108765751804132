import React from 'react';
import styles from './DraggableChoiceEditor.module.scss';

const CorrectAnswers = () => {
  return (
    <div className={styles.inputContainer}>
    </div>
  );
};

export default CorrectAnswers;
