import PropTypes from 'prop-types';

export const propertyPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  operators: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDate: PropTypes.bool,
  isBoolean: PropTypes.bool,
  isNumerical: PropTypes.bool,
  asyncConfig: PropTypes.shape({
    path: PropTypes.string,
    value: PropTypes.string
  }),
});

export const filtersPropTypes = PropTypes.arrayOf(PropTypes.shape({
  operatorValue: PropTypes.string,
  propertyValue: PropTypes.string,
  value: PropTypes.string
}));

/**
 * Build url with query parameters for filtering.
 * @param basePath String holding filter endpoint
 * @param activeFilters Object of filters
 */
export function buildFilterPath(basePath, activeFilters) {
  let filterPath = basePath;
  if (activeFilters) {
    Object.entries(activeFilters).forEach((filter) => {
      const filterVal = filter[1];
      const filterName = filter[0];
      filterPath += `&search%5B${filterName}%5D=${encodeURIComponent(filterVal)}`;
    });
  }

  filterPath += ('&endParam'); // need to append an arbitrary param to preserve trailing spaces
  return filterPath;
}

export function buildQueryPath(basePath, queries) {
  let newPath = basePath;

  if (queries) {
    Object.entries(queries).forEach((query) => {
      const queryName = query[0];
      const queryValue = query[1];
      newPath += `&${queryName}=${queryValue}`;
    });
  }

  return newPath;
}
