import { useEffect, useState } from 'react';
import FroalaJsEditor from 'froala-editor';
import { LayoutValue } from './LayoutDropdown';
import { getLayout, unwrapHtml, wrapHtml } from './SectionEditorUtils';

interface UseLayoutArgs {
  editor: FroalaJsEditor;
  onChange: (htmlString: string) => any;
}

const useLayout = ({ editor, onChange: onChangeCallback }: UseLayoutArgs) => {
  const [layout, setlayout] = useState<LayoutValue>();

  useEffect(() => {
    if (!editor) return;

    setlayout(getLayout(editor.html.get()));
  }, [editor]);

  const onChange = (nextLayout: LayoutValue) => {
    if (!editor) return;

    setlayout(nextLayout);

    const html = editor.html.get();

    const parser = new DOMParser();
    const parsed = parser.parseFromString(html, 'text/html').body as HTMLBodyElement;

    if (nextLayout === 'two_column') {
      onChangeCallback(wrapHtml(parsed).outerHTML);
    } else {
      onChangeCallback(unwrapHtml(parsed).innerHTML);
    }
  };

  return { layout, onChange };
};

export default useLayout;
