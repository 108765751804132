import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { toSnakeCase } from '@/modules/TCIUtils';
import { useModalManager } from '@/components/common/Modal';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import IVideoButton from '@/components/interfaces/graphql/HtmlElement/VideoButton';
import Popup from './Popup';

interface VideoButtonProps {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  videoButton: IVideoButton;
}

const VideoButton = ({
  containerRef,
  videoButton: { modelVideo, data: { backgroundColor = 'purple', buttonMaxWidth, buttonTextEn, buttonTextEs, youtubeVideoUrl } },
}: VideoButtonProps) => {
  const modalManager = useModalManager<ModalManager>();
  const buttonRef = useRef();

  const onClickOrKeyDown = () => {
    modalManager.open();
  };

  return (
    <>
      <button
        ref={buttonRef}
        className="btn"
        data-background-color={backgroundColor}
        data-button-text-en={buttonTextEn}
        data-button-text-es={buttonTextEs || buttonTextEn}
        data-html-element-type="VideoButton"
        style={{ maxWidth: buttonMaxWidth }}
        type="button"
        {...useKeydownAndClick(onClickOrKeyDown)}
      />

      {ReactDOM.createPortal(
        <Popup
          autoplay={modelVideo?.autoplay}
          modalManager={modalManager}
          video={toSnakeCase(modelVideo?.video || {})}
          youtubeVideoUrl={youtubeVideoUrl}
        />,
        containerRef.current,
      )}
    </>
  );
};

export default VideoButton;
