import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import Axios from 'axios/index';
import { renderErrors } from '../../Forms/Utils';
import Confirmation from './Confirmation';
import UploadForm from './UploadForm';

export default class StudentMethods extends Component {
  static propTypes = {
    batchUpdateCsvPath: PropTypes.string.isRequired,
    canCsvUpdate: PropTypes.bool,
    csvFile: PropTypes.instanceOf(Object).isRequired,
    importCsvPath: PropTypes.string.isRequired,
    numRows: PropTypes.number.isRequired,
    processCsvNowPath: PropTypes.string.isRequired,
    redirectPath: PropTypes.string,
    showConfirmation: PropTypes.func.isRequired,
    stafferId: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
  };

  static defaultProps = {
    canCsvUpdate: true,
    redirectPath: ''
  };

  static isBetween3pmAnd4amPst() {
    const currentTimePst = new Date(Date.now())
      .toLocaleTimeString('en-US', { hour12: false, timeZone: 'America/Los_Angeles' });
    const hour = currentTimePst.split(':')[0];

    return hour < 4 || hour >= 15;
  }

  constructor(props) {
    super(props);

    this.state = {
      csvUploadId: null,
      submitting: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.scheduleCsvNow = this.scheduleCsvNow.bind(this);
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    const batchUpdate = values.method === 'destructive'; // false = import
    const path = (batchUpdate ? this.props.batchUpdateCsvPath : this.props.importCsvPath);

    const formData = new FormData();
    formData.append('csv', this.props.csvFile);
    formData.append('destructive', batchUpdate.toString());
    formData.append('rows_count', this.props.numRows.toString());
    formData.append('staffer_id', this.props.stafferId.toString());
    formData.append('sync_type', values.method);
    formData.append('user_type', 'student');

    return Axios
      .post(path, formData)
      .then((response) => {
        if (StudentMethods.isBetween3pmAnd4amPst() || !batchUpdate) {
          window.location.assign(this.props.redirectPath);
          return;
        }

        this.setState({ csvUploadId: response.data.data.id });
        this.props.showConfirmation();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  scheduleCsvNow() {
    if (!this.state.csvUploadId) return null;

    const path = this.processCsvNowPath();

    return Axios
      .post(path)
      .then(() => {
        window.location.assign(this.props.redirectPath);
      })
      .catch(error => console.log(error));
  }

  processCsvNowPath() {
    return this.props.processCsvNowPath.replace(':id', this.state.csvUploadId);
  }

  renderUploadForm() {
    return (
      <UploadForm
        canCsvUpdate={this.props.canCsvUpdate}
        handleSubmit={this.handleSubmit}
        numRows={this.props.numRows}
        submitting={this.state.submitting}
      />
    );
  }

  renderConfirmation() {
    return (
      <Confirmation
        numRows={this.props.numRows}
        redirectPath={this.props.redirectPath}
        scheduleCsvNow={this.scheduleCsvNow}
      />
    );
  }

  renderModalContent() {
    if (this.props.step === 2) return this.renderUploadForm();
    if (this.props.step === 3) return this.renderConfirmation();
    return null;
  }

  render() {
    return this.renderModalContent();
  }
}
