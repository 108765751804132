import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SubmitModal, useModalManager } from 'common/Modal';
import * as Routes from 'routes';

const DeleteModal = ({ modelVideoId, afterSubmit }) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <button type="button" className="dropdown-item full-size" onClick={modalManager.open}>
        <i className="fa fa-trash" aria-hidden />Remove
      </button>
      <SubmitModal
        headerText="Remove Video"
        submitPath={Routes.plato_api_model_video_path(modelVideoId)}
        method="delete"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
      >
        Are you sure you want to remove this video?
      </SubmitModal>
    </Fragment>
  );
};

DeleteModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  modelVideoId: PropTypes.number.isRequired
};

export default DeleteModal;
