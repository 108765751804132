import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as Routes from '@/modules/routes';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ui/Form';
import { twMerge } from '@/utils';

interface TopNavSearchBarProps {
  programId: number;
  userType?: UserType;
}
const TopNavSearchBar = ({ userType = 'Teacher', programId }: TopNavSearchBarProps) => {
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const { register, handleSubmit } = useForm();

  const searchPath = (userType === 'Sysadmin' || userType === 'ContentManager') ? Routes.search_admin_program_path : Routes.search_shared_program_path;

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
      e.preventDefault();
      setOpenSearchModal(prev => !prev);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const onSubmit = ({ query }) => {
    window.open(searchPath(programId, { term: query }), '_blank');

    setOpenSearchModal(false);
  };

  return (
    <>
      <button
        aria-label="Search this program"
        type="button"
        className={twMerge(
          'tw-bg-transparent tw-border-0 tw-cursor-pointer tw-outline-offset-0 tw-outline-1 tw-p-2 tw-text-white ',
          'hover:tw-text-tci-purple tw-text-xl tw-outline-transparent',
        )}
        onClick={() => setOpenSearchModal(true)}
      >
        <i className="fa fa-search" aria-hidden="true" />
      </button>
      <Modal
        role="dialog"
        aria-hidden={!openSearchModal}
        overlayClassName={twMerge(
          'tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-75 tw-z-[999999] tw-flex ',
          'tw-items-center tw-justify-center tw-transition-all tw-duration-300 tw-ease-in-out',
        )}
        className={twMerge(
          'tw-flex tw-items-center tw-justify-start tw-fixed tw-z-50 tw-overflow-y-auto tw-overflow-x-hidden',
          'tw-outline-offset-0 tw-outline-1 tw-shadow-lg tw-max-h-[calc(100%-2rem)] tw-max-w-[calc(100%-2rem)] ',
          'tw-rounded-md tw-transition-all tw-duration-300 tw-ease-in-out tw-transform tw-translate-y-0 tw-opacity-100',
          'tw-scale-100 tw-origin-center tw-overflow-hidden tw-w-[50rem] tw-bg-white tw-px-2.5 tw-text-base',
        )}
        isOpen={openSearchModal}
        onRequestClose={() => setOpenSearchModal(false)}
        contentLabel="Search"
      >
        <form autoComplete="off" className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            variant="inline"
            className="tw-w-full !tw-border-0 tw-outline-transparent tw-text-base"
            LabelProps={{ className: '!tw-w-5 tw-mx-1' }}
            // @ts-ignore-next-line
            label={<i className="fa fa-search tw-text-tci-purple tw-text-lg" />}
            autoFocus
            name="query"
            placeholder="Enter a search term"
            {...register('query')}
          />
        </form>
      </Modal>
    </>
  );
};

export default TopNavSearchBar;
