import React from 'react';
import axios from 'axios';
import { plato_api_program_index_pdf_upload_path } from 'modules/routes';

const DeleteBtn = ({ id, onClick }: { id: string, onClick: () => void }) => {
  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    const shouldDelete = confirm('Are you sure you want to remove this pdf?');

    if (!shouldDelete) return;

    axios
      .delete(plato_api_program_index_pdf_upload_path(id))
      .then(() => onClick());
  };

  return (
    <button
      className="btn btn--purple btn--sm btn--outline-purple"
      onClick={handleClick}
      type="button"
    >
      Delete
    </button>
  );
};

export default DeleteBtn;
