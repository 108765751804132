import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { IChallenge, IGame } from '../../types';
import useGradesTableStore from '../../store/useGradesTableStore';

interface IApiResponse {
  data: (IChallenge | IGame)[];
}

const useLessonGames = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);

  return useQuery(['Games', lesson?.id, programId], () => (
    axios
      .get<IApiResponse>(Routes.plato_api_lesson_games_games_path({ program_id: programId, lesson_id: lesson.id }))
      .then(res => res.data.data)
  ), { enabled: !!(lesson?.id && programId), placeholderData: [] });
};

export default useLessonGames;
