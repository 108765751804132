import { IExerciseResult } from '@/components/staffer/Scores/GradesTable/types';
import useGradesTableStore from '@/components/staffer/Scores/GradesTable/store/useGradesTableStore';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/components/staffer/Scores/GradesTable/GradesTableQueryClientProvider';
import axios from 'axios';
import { plato_api_reading_game_results_exercise_results_path } from '@/modules/routes';

interface IApiResponse {
  data: IExerciseResult[];
}

const useReadingGameExerciseResults = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const isEnabled = !!(classroom?.id && lesson?.id);

  const query = useQuery([QueryKeys.ReadingGameExerciseResults, classroom?.id, lesson?.id], () => (
    axios
      .get<IApiResponse>(plato_api_reading_game_results_exercise_results_path({
        classroom_id: classroom.id,
        lesson_id: lesson.id,
      }))
      .then(res => res.data?.data || [])
  ), { enabled: isEnabled, placeholderData: [] });

  return { isEnabled, ...query };
};

export default useReadingGameExerciseResults;
