import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { plato_api_slide_object_three_d_models_path, plato_api_three_d_model_textures_path } from '@/modules/routes';
import { SchemaType } from './utils';

const useCreateThreeDModelMutation = (slideObjectId: string) => (
  useMutation({
    mutationFn: async (values: SchemaType) => {
      const formData = new FormData();

      formData.append('three_d_model[model]', values.model[0]);

      const modelRequest = await axios
        .post(
          plato_api_slide_object_three_d_models_path(slideObjectId),
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

      const model = modelRequest.data.data;

      const textureRequests = values
        .textures
        .filter(texture => !!texture.file[0])
        .map((texture) => {
          const textureFormData = new FormData();

          textureFormData.append('texture[image_en]', texture.file[0]);
          textureFormData.append('texture[image_models_attributes][0][model_id]', model.id);
          textureFormData.append('texture[image_models_attributes][0][model_type]', 'ThreeDModel');
          textureFormData.append('texture[image_models_attributes][0][texture_type]', texture.texture_type);

          return axios
            .post(
              plato_api_three_d_model_textures_path(model.id),
              textureFormData,
              { headers: { 'Content-Type': 'multipart/form-data' } },
            );
        });

      return Promise.all(textureRequests).then((textureResponses) => {
        const textures = textureResponses.map(data => data.data.data);

        return { ...model, textures };
      });
    },
    mutationKey: ['useCreateThreeDModelMutation', slideObjectId],
  })
);

export default useCreateThreeDModelMutation;
