import React from 'react';
import Tippy from '@tippy.js/react';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import { redirectLinks } from '../../../modules/ExternalRedirect';

export default function Tooltip({ children, shouldDisplay, ...props }) {
  // Runs after tooltip has been rendered/displayed.
  const onShown = () => {
    redirectLinks(document.body);
  };

  if (shouldDisplay) {
    return (
      <Tippy {...props} onShown={onShown}>
        {children}
      </Tippy>
    );
  }

  return children;
}

Tooltip.propTypes = {
  animateFill: PropTypes.bool,
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideOnClick: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  interactive: PropTypes.bool,
  shouldDisplay: PropTypes.bool,
  size: PropTypes.string,
  theme: PropTypes.string
};

Tooltip.defaultProps = {
  animateFill: false,
  arrow: true,
  hideOnClick: 'toggle',
  interactive: true,
  shouldDisplay: true,
  size: 'medium',
  theme: 'white'
};
