import React from 'react';
import { createStore } from 'zustand';
import * as fabric from 'fabric';
import { ActiveSelection, Canvas, FabricObject, IText, TFiller } from 'fabric';
import autoSaveHook from '../hooks/autoSave';
import { DEFAULT_BRUSH_WIDTH, Mode } from '../constants';
import { DrawingProps, LegacyCanvasDimensions, QuestionAnswer, CanvasHistory, Entry, ExerciseType } from '../types';

const CLONE_OFFSET = 50;

export interface CreateDrawingStoreVariables {
  addHistory: (isMutable?: boolean) => void;
  answerId?: number;
  autoSave: () => void;
  autoSaveQueued: boolean;
  body?: string;
  builder: boolean;
  canvas: Canvas;
  clipboardValue: FabricObject | null;
  color: string | TFiller;
  disabled: boolean;
  editable?: boolean;
  exerciseType?: ExerciseType;
  eraserWidth?: number;
  helpMenuOpen: boolean;
  initialData?: string;
  isDirty: boolean;
  isExpanded: boolean;
  isLoading: boolean;
  legacyCanvasDimensions?: LegacyCanvasDimensions;
  mode: Mode;
  penOpacity: number;
  penWidth: number;
  questionAnswer: QuestionAnswer;
  questionId: number;
  isReloading: boolean;
  redoHistory: CanvasHistory;
  saving: boolean;
  shapeUrl: string;
  sectionId?: number;
  slideShowId?: number;
  toolbarEl?: React.MutableRefObject<HTMLDivElement>;
  undoHistory: CanvasHistory;
  userType: UserType;
  useDirtyTracking: boolean;

  beginReloading: () => void;
  canRedo: () => boolean;
  canUndo: () => boolean;
  clearReloading: () => void;
  copy: (offset?: boolean) => void;
  cut: () => void;
  deleteObject: () => void;
  duplicateObject: () => void;
  moveObjects: (direction: 'front' | 'back') => void;
  paste: () => void;
  popRedoHistory: () => Entry | undefined;
  popUndoHistory: () => Entry | undefined;
  pushUndoHistory: ({ entry, isMutable }: {
    entry: string,
    isMutable: boolean,
  }) => void;
  redo: () => void;
  setAnswerId: (answerId: number) => void;
  setAutoSave: (autoSave: () => void) => void;
  setAutoSaveQueued: (autoSaveQueued: boolean) => void;
  setLegacyCanvasDimensions: (dimensions: LegacyCanvasDimensions) => void;
  setBody: (body: string) => void;
  setCanvas: (canvas: Canvas) => void;
  setClipboardValue: (clipboardValue: FabricObject | null) => void;
  setColor: (color: string | TFiller) => void;
  setEraserWidth: (eraserWidth: number) => void;
  setInitialData: (initialData: string) => void;
  setIsDirty: (isDirty: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setMode: (mode: Mode) => void;
  setPenOpacity: (penOpacity: number) => void;
  setPenWidth: (penWidth: number) => void;
  setQuestionAnswer: (questionAnswer: QuestionAnswer) => void;
  setSaving: (saving: boolean) => void;
  setSectionId: (sectionId: number) => void;
  setShapeUrl: (shapeUrl: string) => void;
  setToolbarEl: (toolbarEl: React.MutableRefObject<HTMLDivElement>) => void;
  toggleHelpMenuOpen: () => void;
  undo: () => void;
}

const createDrawingStore = (
  props: DrawingProps,
  storeRef: ReturnType<typeof createDrawingStore>,
) => createStore<CreateDrawingStoreVariables>()((set, get) => ({
  answerId: props.answerId,
  autoSave: () => {
    set({ autoSaveQueued: true });

    return autoSaveHook({ ...get() });
  },
  autoSaveQueued: false,
  builder: props.builder,
  body: null,
  canvas: null,
  clipboardValue: null,
  color: 'rgba(0, 0, 0, 1)',
  disabled: props.disabled,
  editable: props.editable ?? true,
  eraserWidth: DEFAULT_BRUSH_WIDTH,
  exerciseType: props.exerciseType,
  helpMenuOpen: false,
  initialData: null,
  isDirty: false,
  isLoading: true,
  legacyCanvasDimensions: { height: 600, width: 850 },
  mode: 'select',
  penOpacity: 100,
  penWidth: 10,
  questionAnswer: null,
  questionId: props.questionId ?? 0,
  redoHistory: [],
  isExpanded: false,
  isReloading: false,
  saving: false,
  shapeUrl: null,
  sectionId: props.sectionId,
  slideShowId: props.slideShowId,
  toolbarEl: null,
  undoHistory: [],
  useDirtyTracking: props.useDirtyTracking !== undefined ? props.useDirtyTracking : true,

  addHistory: (isMutable = true) => {
    const { canvas, isReloading, pushUndoHistory } = get();

    if (isReloading || !canvas) return;

    setTimeout(() => {
      pushUndoHistory({ entry: JSON.stringify(canvas.toJSON()), isMutable });
    }, 0);
  },
  beginReloading: () => set({ isReloading: true }),
  canRedo: () => get().redoHistory.length > 0,
  canUndo: () => {
    const { undoHistory } = get();

    return undoHistory.length > 0 && undoHistory[undoHistory.length - 1].isMutable;
  },
  clearReloading: () => {
    setTimeout(() => set((state) => {
      if (!get().canvas) return state;

      return { ...state, isReloading: false };
    }));
  },
  copy: async (offset = true) => {
    const selection = get().canvas.getActiveObject();

    if (!selection) return;

    const clone = await selection.clone();

    if (offset) {
      clone.left += CLONE_OFFSET;
      clone.top += CLONE_OFFSET;
    }

    set({ clipboardValue: clone });
  },
  cut: async () => {
    const { copy, deleteObject } = get();

    copy(false);
    deleteObject();
  },
  deleteObject: () => {
    const { canvas } = get();
    const selection = canvas.getActiveObject();

    if (!selection) return;

    if (selection.isType(ActiveSelection.type)) {
      (selection as ActiveSelection).forEachObject(obj => canvas.remove(obj));
    } else {
      canvas.remove(selection);
    }

    canvas.discardActiveObject();
    canvas.requestRenderAll();
  },
  duplicateObject: async () => {
    const { canvas } = get();
    const selection = canvas.getActiveObject();

    if (!selection) return;

    const clone = await selection.clone();
    clone.left += CLONE_OFFSET;
    clone.top += CLONE_OFFSET;

    if (clone.isType(ActiveSelection.type)) {
      clone.canvas = canvas;
      (clone as ActiveSelection).forEachObject(obj => canvas.add(obj));
      clone.setCoords();
    } else {
      canvas.add(clone);
    }

    canvas.setActiveObject(clone);
    canvas.requestRenderAll();
  },
  moveObjects: (direction) => {
    const { canvas } = get();
    const activeObject = canvas.getActiveObject();

    const moveObject = (c: fabric.Canvas, object: fabric.Object, dir: 'front' | 'back') => {
      if (dir === 'front') return c.bringObjectToFront(object);

      return c.sendObjectToBack(object);
    };

    if (activeObject.isType(fabric.ActiveSelection.type)) {
      (activeObject as fabric.ActiveSelection).getObjects().forEach((object) => {
        moveObject(canvas, object, direction);
      });
    } else {
      moveObject(canvas, activeObject, direction);
    }

    canvas.fire('object:modified');
    canvas.renderAll();
  },
  paste: async () => {
    const { canvas, clipboardValue } = get();
    const selection = canvas.getActiveObject();

    if (selection?.isType(IText.type) && (selection as IText)?.isEditing) return;

    if (!clipboardValue) return;

    const clone = await clipboardValue.clone();

    if (clone.isType(ActiveSelection.type)) {
      (clone as ActiveSelection).forEachObject(obj => canvas.add(obj));
      clone.setCoords();
    } else {
      canvas.add(clone);
    }

    canvas.setActiveObject(clone);
    canvas.requestRenderAll();
  },
  popRedoHistory: () => {
    let last: Entry;

    set((state) => {
      const { redoHistory, undoHistory } = get();
      last = redoHistory.pop();

      return {
        ...state,
        undoHistory: [...undoHistory, last],
        redoHistory,
        isReloading: true,
      };
    });

    return last;
  },
  popUndoHistory: () => {
    set((state) => {
      const { canvas } = get();
      if (!canvas) return state;

      const { undoHistory, redoHistory } = get();
      const last = undoHistory.pop();

      return {
        ...state,
        undoHistory,
        redoHistory: [...redoHistory, last],
        isReloading: true,
      };
    });

    const { undoHistory } = get();

    return undoHistory[undoHistory.length - 1];
  },
  pushUndoHistory: ({ entry, isMutable }) => set((state) => {
    const { canvas } = get();

    if (!canvas) return state;

    const undoHistory = get().undoHistory as CanvasHistory;
    const redoHistory = get().redoHistory as CanvasHistory;
    const nextHistory = [...undoHistory, { entry, isMutable }];

    const nextState = { ...state, undoHistory: nextHistory };

    if (redoHistory.length > 0) return { ...nextState, redoHistory: [] };

    return nextState;
  }),
  redo: () => {
    const { canRedo, canvas, popRedoHistory, clearReloading } = get();

    if (!canRedo()) return;

    const prev = popRedoHistory();

    if (!prev) return;

    canvas.forEachObject(obj => canvas.remove(obj));
    const entry = JSON.parse(prev.entry);

    fabric.util.enlivenObjects(entry.objects)
      .then(res => res.forEach(obj => canvas.add(obj as fabric.FabricObject)));

    clearReloading();
    canvas.discardActiveObject();
  },
  setAnswerId: answerId => set({ answerId }),
  setAutoSave: autoSave => set({ autoSave }),
  setAutoSaveQueued: autoSaveQueued => set({ autoSaveQueued }),
  setLegacyCanvasDimensions: dimensions => set({ legacyCanvasDimensions: dimensions }),
  setBody: body => set({ body }),
  setColor: color => set({ color }),
  setCanvas: canvas => set({ canvas }),
  setClipboardValue: clipboardValue => set({ clipboardValue }),
  setEraserWidth: eraserWidth => set({ eraserWidth }),
  setInitialData: initialData => set({ initialData }),
  setIsDirty: isDirty => set({ isDirty }),
  setIsExpanded: isExpanded => set({ isExpanded }),
  setIsLoading: isLoading => set({ isLoading }),
  setMode: mode => set({ mode }),
  setPenOpacity: penOpacity => set({ penOpacity }),
  setPenWidth: penWidth => set({ penWidth }),
  setQuestionAnswer: questionAnswer => set({ questionAnswer }),
  setSaving: saving => set({ saving }),
  setSectionId: sectionId => set({ sectionId }),
  setShapeUrl: shapeUrl => set({ shapeUrl }),
  setToolbarEl: toolbarEl => set({ toolbarEl }),
  storeRef,
  toggleHelpMenuOpen: () => set(state => ({ helpMenuOpen: !state.helpMenuOpen })),
  undo: () => {
    const { canUndo, canvas, popUndoHistory, clearReloading } = get();

    if (!canUndo()) return;

    const prev = popUndoHistory();

    if (!prev) {
      canvas.forEachObject(obj => canvas.remove(obj));
    } else {
      const entry = JSON.parse(prev.entry);

      canvas.forEachObject(obj => canvas.remove(obj));
      fabric.util.enlivenObjects(entry.objects)
        .then(res => res.forEach(obj => canvas.add(obj as fabric.FabricObject)));
    }

    clearReloading();
    canvas.fire('object:modified');
    canvas.discardActiveObject();
  },
  userType: props.userType,
}));

export default createDrawingStore;
