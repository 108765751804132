import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import AddImage from '@/components/admin/ImageManager/Add';
import { insertIntoEditor } from '@/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/FroalaListeners';
import { useModalManager } from '../../common/Modal';
import FroalaEditor from '../../common/FroalaEditor';
import apolloClient from '../../common/ApolloClient';
import CustomVideoModal
  from '../SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/CustomVideo/CustomVideoModal';

import TextEditorConfig, { registerFroalaCommands } from './TextEditorConfig';

registerFroalaCommands();

type TextEditorTypes = {
  baseUrl: string;
  referenceMaterial: { id: string };
  text: string;
};

const TextEditor = ({ text, baseUrl, referenceMaterial }: TextEditorTypes) => {
  const addVideoModalManager = useModalManager();
  const addImageModalManager = useModalManager();
  const [editorInstance, setEditorInstance] = useState(null);
  const froalaConfig = TextEditorConfig({ addVideoModalManager, addImageModalManager, setEditorInstance, baseUrl });

  const customVideoModal = () => (
    <CustomVideoModal
      editorInstance={editorInstance}
      model={referenceMaterial}
      modalManager={addVideoModalManager}
      modelType="ReferenceMaterial"
    />
  );

  const imageTag = imageModel => (
    <img
      alt={imageModel.image.descriptionEn ? imageModel.image.descriptionEn : ''}
      data-snippet={imageModel.snippet}
      data-zoomurl={imageModel.image.imageUrl}
      src={imageModel.image.imageUrl}
    />
  );

  return (
    <ApolloProvider client={apolloClient}>
      <FroalaEditor
        customConfig={froalaConfig}
        model={text}
        htmlName="reference_material[translations_attributes][0][body]"
        text={text}
      />
      {addImageModalManager.isOpen && (
        <AddImage
          excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
          modelType="ReferenceMaterial"
          forFroala
          modalManager={addImageModalManager}
          modelId={referenceMaterial.id}
          updateResources={() => {}}
          standaloneButton={false}
          isAdmin
          afterSubmit={imageModel => insertIntoEditor(editorInstance, imageTag(imageModel), true, false, true)}
        />
      )}
      {addVideoModalManager.isOpen && customVideoModal()}
    </ApolloProvider>
  );
};

export default TextEditor;
