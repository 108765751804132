import React from 'react';
import * as Routes from '@/modules/routes';
import I18n from 'i18n';
import Axios from 'axios';
import showToast from 'components/common/Toast';
import LmsButton from './LmsButton';

interface SyncGradesProps {
  assignmentId: number;
  showGoogleClassroomLogo: boolean;
  showCanvasLogo: boolean;
  showSchoologyLogo: boolean;
}

const SyncGrades = ({ assignmentId, showGoogleClassroomLogo, showCanvasLogo, showSchoologyLogo }: SyncGradesProps) => {
  const handleClick = () => {
    const url = Routes.plato_api_sync_grades_assignments_path();

    Axios.post(url, { ids: assignmentId.toString() })
      .then(() => {
        showToast('Grades are now syncing for this assignment');
      })
      .catch(() => {
      });
  };

  return (
    <LmsButton
      handleClick={handleClick}
      showGoogleClassroomLogo={showGoogleClassroomLogo}
      showCanvasLogo={showCanvasLogo}
      showSchoologyLogo={showSchoologyLogo}
    >
      {I18n.t('sync_all_grades')}
    </LmsButton>
  );
};

export default SyncGrades;
