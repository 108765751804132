import FE from 'froala-editor';
import FroalaConfig from '@/modules/froala/FroalaConfig';
import { CUSTOM_IMAGE_COMMAND_NAME, CUSTOM_VIDEO_COMMAND_NAME } from './froalaConstants';
import { getImageEditButtons } from './innerConfig';
import { TextEditorEvents } from './TextEditorEvents';
import { MAIN_IDEAS } from './plugins/MainIdeas';
import { GLOSSARY_TERMS } from './plugins/GlossaryTerms';
import { VOCAB_BOX } from './plugins/VocabBox';

const TextEditorConfig = ({
  activePlugins = [],
  addVideoModalManager,
  addImageModalManager,
  eventHandlers = [],
  customEvents = {},
  editorClass,
  editImageModalManager,
  pluginProps = {},
  setCurrentImageModelId,
  setEditorInstance,
  userType,
  useImageCommand,
  useVideoCommand,
  customConfig = {},
}) => (
  FroalaConfig({
    allowFileUploads: true,
    allowHtml: true,
    customConfig: {
      editorClass,
      imageEditButtons: getImageEditButtons(userType),
      imagePaste: false, // disable pasting of images for now while froala bug isn't fixed
      toolbarButtons: [
        // eslint-disable-next-line max-len
        ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize'],
        ['textColor', 'backgroundColor', 'emoticons', 'inlineStyle'],
        ['paragraphStyle', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'],
        // eslint-disable-next-line max-len
        ['quote', 'insertHR', 'insertLink', useImageCommand && CUSTOM_IMAGE_COMMAND_NAME, useVideoCommand && CUSTOM_VIDEO_COMMAND_NAME, 'insertFile', 'insertTable',
          'undo', 'redo', 'clearFormatting', 'selectAll', 'html'],
        [
          activePlugins.includes(MAIN_IDEAS.commandName) && MAIN_IDEAS.commandName,
          activePlugins.includes(GLOSSARY_TERMS.commandName) && GLOSSARY_TERMS.commandName,
          activePlugins.includes(VOCAB_BOX.commandName) && VOCAB_BOX.commandName,
        ],
      ],
      ...customConfig,
    },
    enter: FE.ENTER_BR,
    events: TextEditorEvents({
      activePlugins,
      addImageModalManager,
      addVideoModalManager,
      editorClass,
      eventHandlers,
      customEvents,
      editImageModalManager,
      pluginProps,
      setCurrentImageModelId,
      setEditorInstance,
    }),
    livePreview: true,
    ...customConfig,
  })
);

export default TextEditorConfig;
