import React, { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import FE from 'froala-editor';
import FroalaEditor from '@/components/common/FroalaEditor';
import FroalaConfig from '@/modules/froala/FroalaConfig';
import { InteractiveMapFormData } from './types';

interface InstructionsEditorProps {
  initialInstructions: string;
  setValue: UseFormSetValue<InteractiveMapFormData>;
}

const froalaConfig = FroalaConfig({
  customConfig: {
    enter: FE.ENTER_BR,
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
    height: '100%',
    heightMax: 200,
    toolbarButtons: [
      'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
      'formatOL', 'formatUL', 'indent', 'outdent', 'insertLink', 'undo', 'redo', 'clearFormatting', 'selectAll',
    ],
    width: '100%',
  },
});

const InstructionsEditor = ({ initialInstructions, setValue }: InstructionsEditorProps) => {
  const [instructionsValue, setInstructionsValue] = useState(initialInstructions);

  const setInstructions = (instructions: string) => {
    if (instructions === instructionsValue) return;

    setValue('instructions_en', instructions, { shouldDirty: true });

    setInstructionsValue(instructions);
  };

  return (
    <FroalaEditor
      customConfig={froalaConfig}
      model={instructionsValue}
      onModelChange={setInstructions}
      text={instructionsValue}
    />
  );
};

export default InstructionsEditor;
