import React, { useState } from 'react';

interface LocaleToggleProps {
  btnClasses?: string;
  isSaving: boolean;
  locale?: 'en' | 'es';
  setLocale: (prevState: 'en' | 'es') => void;
}

// eslint-disable-next-line max-len
const BASE_BUTTON_CLASS = 'tw-border tw-border-solid tw-border-grey tw-bg-white tw-p-1.5 enabled:tw-cursor-pointer enabled:hover:tw-bg-tci-purple enabled:hover:tw-text-white';
// eslint-disable-next-line max-len
const SELECTED_BUTTON_CLASS = 'tw-border tw-border-solid tw-border-tci-purple tw-bg-tci-purple tw-p-1.5 enabled:tw-cursor-pointer tw-text-white enabled:hover:tw-bg-tci-purple enabled:hover:tw-text-white';

const LocaleToggle = ({ isSaving, locale, setLocale, btnClasses }: LocaleToggleProps) => {
  const [toggled, setToggled] = useState(false);
  const disabled = toggled || isSaving;

  const toggleLocale = (newLocale) => {
    if (disabled) return;
    setToggled(true);
    // disable button for 900ms because we debounce saves for 800ms after content change
    // don't want users to be able to switch toggles before/during saves so we don't dispatch another improper save
    setTimeout(() => setToggled(false), 900);

    setLocale(newLocale);
  };

  return (
    <div className={btnClasses || 'tw-absolute -tw-top-2.5 tw-right-0'}>
      <button
        disabled={disabled}
        type="button"
        className={!locale || locale === 'en' ? SELECTED_BUTTON_CLASS : BASE_BUTTON_CLASS}
        onClick={() => toggleLocale('en')}
      >
        English
      </button>
      <button
        disabled={disabled}
        type="button"
        className={locale === 'es' ? SELECTED_BUTTON_CLASS : BASE_BUTTON_CLASS}
        onClick={() => toggleLocale('es')}
      >
        Spanish
      </button>
    </div>
  );
};

export default LocaleToggle;
