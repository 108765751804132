import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { SubmitModal, useModalManager } from 'common/Modal';
import * as Routes from 'routes';
import PropTypes from 'prop-types';
import ModalBody from './ModalBody';

const AddButton = ({
  buttonTarget, onClick, ctaText, className
}) => {
  if (buttonTarget) {
    return ReactDOM.createPortal(
      <button type="button" className={className} onClick={onClick}>{ctaText}</button>,
      document.querySelector(buttonTarget)
    );
  }

  return <button type="button" className={className} onClick={onClick}>{ctaText}</button>;
};

AddButton.propTypes = {
  buttonTarget: PropTypes.string,
  className: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

AddButton.defaultProps = {
  buttonTarget: null
};

const AddModal = ({
  afterSubmit, initialValues, buttonTarget, ctaText, buttonClassName, modelId, modelType, unique, showWidth,
  showVideoLessonOption
}) => {
  const modalManager = useModalManager();

  const createPath = unique ? Routes.plato_api_create_unique_model_videos_path() : Routes.plato_api_model_videos_path();

  return (
    <Fragment>
      <AddButton
        buttonTarget={buttonTarget}
        onClick={modalManager.open}
        ctaText={ctaText}
        className={buttonClassName}
      />
      <SubmitModal
        headerText={ctaText}
        submitPath={createPath}
        method="post"
        closeModal={modalManager.close}
        isOpen={modalManager.isOpen}
        additionalData={{ model_id: modelId, model_type: modelType }}
        afterSubmit={afterSubmit}
        initialValues={initialValues}
        disableUntilChange
        showVideoLessonOption={showVideoLessonOption}
      >
        <ModalBody showWidth={showWidth} showVideoLessonOption={showVideoLessonOption} />
      </SubmitModal>
    </Fragment>
  );
};

AddModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  buttonTarget: PropTypes.string,
  ctaText: PropTypes.string,
  initialValues: PropTypes.shape(),
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired,
  showVideoLessonOption: PropTypes.bool,
  showWidth: PropTypes.bool,
  unique: PropTypes.bool.isRequired
};

AddModal.defaultProps = {
  buttonTarget: null,
  ctaText: 'Add Video',
  initialValues: {},
  showVideoLessonOption: false,
  showWidth: false
};

export default AddModal;
