import React, { Component } from 'react';
import { Form } from 'react-final-form';
import Select from 'react-select';
import Axios from 'axios';
import PropTypes from 'prop-types';

import AutoSave from '../../../common/Forms/AutoSave';
import { convertCopyright, formatSelectOption } from '../../../common/Forms/Utils';
import InputField from './InputField';
import formStyles from '../Form.module.scss';
import styles from './Search.module.scss';

export default class Search extends Component {
  static propTypes = {
    grades: PropTypes.instanceOf(Array).isRequired,
    searchPath: PropTypes.string.isRequired,
    subscriberId: PropTypes.number,
    updateSearchResults: PropTypes.func.isRequired
  };

  static defaultProps = {
    subscriberId: null
  };

  constructor(props) {
    super(props);
    this.state = { gradeOptions: [], selectedGrade: null };
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this._setGradeOptions();
  }

  search = async (values) => {
    const data = this._buildSearchData(values);

    await Axios
      .post(this.props.searchPath, data)
      .then(response => this.props.updateSearchResults(response.data.data))
      .catch(error => console.log(error));
  };

  _buildSearchData(values) {
    const formValues = values;
    if (formValues.program_full_title_with_edition_cont) {
      formValues.program_full_title_with_edition_cont =
        convertCopyright(formValues.program_full_title_with_edition_cont, false);
    }

    return {
      search: formValues,
      subscriber_id: this.props.subscriberId
    };
  }

  async triggerSearch() {
    await this.search(this.formRef.state.state.values);
  }

  _setGradeOptions() {
    const allOption = { label: 'All', value: '' };
    const grades = this.props.grades.map(grade => formatSelectOption(grade));

    const options = [allOption].concat(grades);

    this.setState({
      gradeOptions: options,
      selectedGrade: { label: 'All', value: '' }
    });
  }

  _renderSelectField(onChange) {
    return (
      <div>
        <label htmlFor="seat_pools_grade_eq" className={styles.gradeLabel}>
          <span className="sr-only">District grade is</span>
        </label>

        <Select
          className={`${styles.field} ${styles.gradeField}`}
          id="seat_pools_grade_eq"
          clearable={false}
          searchable={false}
          name="seat_pools_grade_eq"
          onChange={onChange}
          options={this.state.gradeOptions}
          value={this.state.selectedGrade}
        />
      </div>
    );
  }

  render() {
    return (
      <Form
        ref={(ref) => { this.formRef = ref; }}
        initialValues={{
          seat_pools_subscriber_id_eq: this.props.subscriberId
        }}
        mutators={{
          clear: (args, state, utils) => {
            this.setState({ selectedGrade: { label: 'All', value: '' } });
            utils.changeValue(state, 'course_number_cont', () => '');
            utils.changeValue(state, 'seat_pools_grade_eq', () => '');
            utils.changeValue(state, 'program_full_title_with_edition_cont', () => '');
            utils.changeValue(state, 'program_code_cont', () => '');
          },
          handleGradeSelect: (args, state, utils) => {
            this.setState({ selectedGrade: args[0] });
            utils.changeValue(state, 'seat_pools_grade_eq', () => args[0].value);
          }
        }}
        onSubmit={this.search}
        render={({ form }) => (
          <div>
            <div className={styles.header}>
              <h2 className={styles.title}>Filters</h2>
              <button
                className="btn btn--sm btn--link-purple"
                onClick={form.mutators.clear}
              >
                Clear
              </button>
            </div>

            <div className={formStyles.formRow}>
              <AutoSave debounce={300} save={this.search} />

              <InputField
                className={`${styles.districtCourseCodeField} ${styles.field}`}
                id="course_number_cont"
                fieldName="district course code"
              />

              {this._renderSelectField(form.mutators.handleGradeSelect)}

              <InputField
                className={`${styles.programNameField} ${styles.field}`}
                id="program_full_title_with_edition_cont"
                fieldName="program name"
              />

              <InputField
                className={`${styles.programCodeField} ${styles.field}`}
                id="program_code_cont"
                fieldName="program code"
              />
            </div>
          </div>
        )}
      />
    );
  }
}
