import { useEffect, useMemo, useState } from 'react';

export default function useAlphabetFilter<T>(initialData: T[], valueToCompare: (datum: T) => string) {
  const [activeLetter, setActiveLetter] = useState('');
  const [data, setData] = useState<T[]>(initialData);

  const availableLetters = useMemo(() => {
    const lettersFromData = initialData.map(datum => valueToCompare(datum));

    return [...new Set(lettersFromData)];
  }, [initialData]);

  useEffect(() => {
    if (activeLetter === '') {
      setData(initialData);
    } else {
      const filteredData = initialData
        .filter(datum => valueToCompare(datum).toUpperCase() === activeLetter);

      setData(filteredData);
    }
  }, [activeLetter]);

  return { activeLetter, availableLetters, data, setActiveLetter };
}
