import React from 'react';
import LessonCard from '@/components/shared/NewDashboard/Cards/LessonCard';
import useLessons from '@/hooks/api/useLessons';
import Spinner from '@/components/common/Spinner';

type LessonSectionProps = {
  programId: number,
  unitId: number,
  unitColor: string,
};

const LessonSection = ({ programId, unitId, unitColor }: LessonSectionProps) => {
  const { lessons, isLoading } = useLessons({
    programId,
    unitId,
    attributes: [
      'id', 'title', 'image_url', 'engineering_icon', 'position', 'performance_expectations', 'title_number',
    ],
  });

  if (isLoading) return <Spinner />;

  return (
    <div className="tw-flex tw-justify-center tw-px-[2rem] tw-pt-[1rem]">
      <div className="tw-w-full tw-grid tw-grid-cols-3 tw-gap-3">
        {lessons && lessons.map(lesson => (
          <LessonCard key={lesson.id} programId={programId} lesson={lesson} unitColor={unitColor} />
        ))}
      </div>
    </div>
  );
};

export default LessonSection;
