import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import I18n from '@/modules/i18n';
import useFlags from '@/hooks/useFlags';
import { useMutation } from '@apollo/client';
import { UPDATE_SLIDE } from 'components/admin/SlideShow/Utils/slideShowGraphQL';
import NotesTable from '../../../../LessonGuides/NotesTable';
import SlideBuilderDrawer from '../SlideBuilderDrawer';
import { slidePropTypes } from '../../../Utils';
import styles from './EditorTabs.module.scss';
import TagsForm from '../../../../TagsForm';
import SlidePageNumbers from '@/components/admin/SlideShow/Builder/SlideEditor/SlidePageNumbers/SlidePageNumbers';
import showToast from '../../../../../common/Toast';
import TeacherNotes from '../../../../LessonGuides/TeacherNotes/TeacherNotes';
import useSlideShowStore, { ISlide } from '../../../stores/useSlideShowStore';
import useSlideShowContext from '../../../stores/useSlideShowContext';
import SlideConditions from '../../../../LessonGuides/SlideConditions';
import { checkArrayEquality } from '../../../../../../modules/TCIUtils';
import useTeacherNotes from '../../../../LessonGuides/TeacherNotes/useTeacherNotes';
import { isEmpty } from './editorTabUtils';
import useDeepCompareEffect from 'use-deep-compare-effect';
const { displaySlideGroupHeaders } = useFlags();

const useTagIds = (setTagsCount: (next: number) => void, slide: ISlide) => {
  const [buildingTowardsIds, setBuildingTowardsIds] = useState(
    slide.buildingTowardsModelTags.map(({ tagId }) => tagId),
  );
  const [tagIds, setTagIds] = useState(slide.modelTags.map(({ tagId }) => tagId));

  useDeepCompareEffect(() => {
    const nextBuildingTowardsIds = slide.buildingTowardsModelTags.map(({ tagId }) => tagId);
    const nextTagIds = slide.modelTags.map(({ tagId }) => tagId).filter(id => !nextBuildingTowardsIds.includes(id));

    setTagsCount(nextBuildingTowardsIds.length + nextTagIds.length);
    setBuildingTowardsIds(nextBuildingTowardsIds);
    setTagIds(nextTagIds);
  }, [slide]);

  return { buildingTowardsIds, tagIds };
};

const useInitialCount = (path: string, setState: (next: number) => void, slideId: string, isSysAdmin: boolean) => {
  useEffect(() => {
    if (!isSysAdmin) return;

    axios
      .get(path)
      .then((res) => {
        const notesForCurrentSlide = res
          .data
          .data
          .filter((adminNote: { slide_id: number }) => adminNote.slide_id === parseInt(slideId, 10));
        setState(notesForCurrentSlide.length);
      });
  }, [path, setState, slideId]);
};

const EditorTabs = ({ slide }) => {
  // Editor tab counts
  const [adminNotesCount, setAdminNotesCount] = useState(0);
  const [tagsCount, setTagsCount] = useState(0);

  const stafferId = useSlideShowContext(state => state.stafferId);
  const isSysAdmin = useSlideShowContext(state => state.isSysAdmin);
  const isSlideConditionsDrawerOpen = useSlideShowStore(state => state.isSlideConditionsDrawerOpen);
  const setIsSlideConditionsDrawerOpen = useSlideShowStore(state => state.setIsSlideConditionsDrawerOpen);
  const [updateSlideMutation] = useMutation(UPDATE_SLIDE);
  const { buildingTowardsIds, tagIds } = useTagIds(setTagsCount, slide);
  const [prevSlideId, setPrevSlideId] = useState(slide.id);
  const updateSlide= useSlideShowContext(state => state.updateSlide);

  const { data: teacherNotes } = useTeacherNotes(slide);

  useInitialCount(
    Routes.plato_api_slide_statuses_path({ for_slide_show: slide.slideShowId, includes: ['staffer'] }),
    setAdminNotesCount,
    slide.id,
    isSysAdmin
  );

  const onSubmit = ({ building_towards_ids, tag_ids }) => {
    if (prevSlideId !== slide.id) {
      setPrevSlideId(slide.id);
      return;
    }

    const isSameBuildingTags = checkArrayEquality(buildingTowardsIds.sort(), building_towards_ids.sort());
    const isSameTags = checkArrayEquality(tagIds.sort(), tag_ids.sort());

    if (isSameBuildingTags && isSameTags) return;

    const mutationParams = {
      variables: {
        buildingTowardsIds: building_towards_ids,
        id: slide.id,
        tagIds: tag_ids,
      },
    };

    updateSlideMutation(mutationParams)
      .then((res) => {
        updateSlide(slide.id, res.data.updateSlide.slide);

        showToast('Successfully saved tags', { position: 'top-right' });

        // Update tag count after each submit.
        setTagsCount(building_towards_ids.length + tag_ids.length);
      })
      .catch(() => {
        showToast('Error saving tags', { msgType: 'error', position: 'top-right' });
      });
  };

  const lockIcon = <i className="tw-pl-1 fa fa-lock" />;

  return (
    <div className={styles.container}>
      {isSysAdmin && (
        <>
          <SlideBuilderDrawer
            buttonText={<>{`Admin Notes (${adminNotesCount})`} {lockIcon}</>}
            title={`Admin Notes (${adminNotesCount})`}
          >
            <NotesTable
              hasTitle={false}
              setAdminNotesCount={setAdminNotesCount}
              slideId={slide.id}
              slideShowId={slide.slideShowId}
              stafferId={stafferId}
            />
          </SlideBuilderDrawer>
          <SlideBuilderDrawer
            buttonText={<>{`Tags (${tagsCount})`} {lockIcon}</>}
            title={`Tags (${tagsCount})`}
          >
            <TagsForm
              key={slide.id}
              onSubmit={onSubmit}
              buildingTowardsIds={buildingTowardsIds}
              tagIds={tagIds}
            />
          </SlideBuilderDrawer>
          {displaySlideGroupHeaders &&
            <SlideBuilderDrawer
              buttonText={<>Page Numbers {lockIcon}</>}
              title="Page Numbers"
            >
              <SlidePageNumbers
                key={slide.id}
              />
            </SlideBuilderDrawer>
          }
        </>
      )}
      <SlideBuilderDrawer
        buttonText={`Teacher Notes (${isEmpty(teacherNotes) ? 0 : 1})`}
        title={`Teacher Notes (${isEmpty(teacherNotes) ? 0 : 1})`}
      >
        <TeacherNotes slide={slide} />
      </SlideBuilderDrawer>
      <SlideBuilderDrawer
        buttonText={`${I18n.t('slide_conditions')} (${slide.condition ? 1 : 0})`}
        isOpen={isSlideConditionsDrawerOpen}
        setIsOpen={setIsSlideConditionsDrawerOpen}
        title={`${I18n.t('slide_conditions')} (${slide.condition ? 1 : 0})`}
      >
        <SlideConditions />
      </SlideBuilderDrawer>
    </div>
  );
};

EditorTabs.propTypes = {
  slide: slidePropTypes.isRequired,
};

export default EditorTabs;
