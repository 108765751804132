import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import TCIOnlyLock from 'common/TCIOnlyLock';
import * as Routes from 'routes';
import axios from 'axios';
import useSlideShowContext from '../../stores/useSlideShowContext';

const addSlideObjectPropTypes = {
  className: PropTypes.string,
  innerText: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  sysadminOnly: PropTypes.bool,
  variables: PropTypes.shape({
    index: PropTypes.number.isRequired,
    modelType: PropTypes.string.isRequired,
    slideId: PropTypes.string.isRequired
  }).isRequired
};

const AddSlideObject = ({ className, innerText, openModal, sysadminOnly, variables, }) => {
  const updateSlide = useSlideShowContext(state => state.updateSlide);
  const slideShow = useSlideShowContext(state => state.slideShow);

  const createSlideObject = () => {
    axios.post(Routes.plato_api_slide_objects_path(),
      { index: variables.index, model_type: variables.modelType, slide_id: variables.slideId })
      .then((response) => {
        const slideObject = response.data.slideObject;
        const slide = slideShow.slides.find(s => s.id.toString() === slideObject.slideId.toString());

        const updatedSlideObjects = update(slide.slideObjects, {
          $push: [slideObject],
        });
        updateSlide(slideObject.slideId, { slideObjects: updatedSlideObjects });

        if (openModal) openModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <button
      className={`dropdown-item tw-min-w-full tw-cursor-pointer ${className}`}
      onClick={createSlideObject}
      type="button"
    >
      {innerText} {sysadminOnly && <TCIOnlyLock classes="ml5" />}
    </button>
  );
};

AddSlideObject.propTypes = addSlideObjectPropTypes;

AddSlideObject.defaultProps = {
  className: '',
  openModal: () => {},
  sysadminOnly: false,
};

export default AddSlideObject;
