import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from '../Utils';
import * as constants from '../steps';

export default function SSOSteps({
  isSaml, wrapperClass, divider, renderStep
}) {
  function renderSamlSteps() {
    return (
      <Steps
        wrapperClass={wrapperClass}
        renderStep={renderStep}
        divider={divider}
        steps={[constants.METHOD_STEP, constants.SAML_CONFIGURATION_STEP_1, constants.SAML_CONFIGURATION_STEP_2]}
        labels={['SSO Method', 'Configuration', 'District Configuration']}
      />
    );
  }

  function renderLtiSteps() {
    return (
      <Steps
        wrapperClass={wrapperClass}
        renderStep={renderStep}
        divider={divider}
        steps={[constants.ROSTER_METHOD_STEP, constants.LTI_CONFIGURATION_STEP, constants.LTI_PREFERENCES_STEP]}
        labels={['SSO Method', 'Configuration', 'Sign In Preference']}
      />
    );
  }

  return isSaml ? renderSamlSteps() : renderLtiSteps();
}

SSOSteps.propTypes = {
  wrapperClass: PropTypes.string.isRequired,
  divider: PropTypes.element.isRequired,
  renderStep: PropTypes.func.isRequired,
  isSaml: PropTypes.bool.isRequired,
};
