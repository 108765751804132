import ActionButton from './ActionButton';
import LinkButton from './LinkButton';
import SubmitButton from './SubmitButton';
import StandardButton from './StandardButton';

const Button = StandardButton;

export {
  ActionButton,
  Button,
  LinkButton,
  SubmitButton,
};
