import React from 'react';
import * as Routes from '@/modules/routes';
import I18n from 'i18n-js';
import { Lesson, Program } from '@/components/shared/LessonOverview/types';
import gameIcon from 'images/icons/game-icon.svg';
import Card, { CardBody } from './Card';
import ActionBar from './ActionBar';

type LessonGameCardProps = {
  lesson: Lesson,
  lessonId: number,
  program: Program,
  stafferId?: number,
  type: string,
  unitColor: string,
};

const LessonGameCard = ({
  lesson, lessonId, program, type, stafferId, unitColor,
}: LessonGameCardProps) => {
  const { isAssigned } = lesson.challenge || {};

  return (
    <Card label="games" type={type} unitColor={unitColor}>
      <CardBody
        ariaLabel={I18n.t('lesson_games')}
        iconSrc={gameIcon}
        linkHref={Routes.library_shared_program_games_path(program.id, { lesson_id: lesson.id, lesson_games: true })}
        linkLabel={I18n.t('lesson_games')}
        type={I18n.t('games')}
        unitColor={unitColor}
      />
      {stafferId && (
        <ActionBar
          href={Routes.library_shared_program_games_path(program.id, { lesson_id: lesson.id, lesson_games: true })}
          initialValues={{
            modelType: 'games',
            modelsToAssign: [lesson.lessonGameAssignables[0].value],
          }}
          isAssigned={isAssigned}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
        />
      )}
    </Card>
  );
};

export default LessonGameCard;
