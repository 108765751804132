import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'common/Modal';
import styles from './RuleBreakdown.module.scss';
import RuleList from './RuleList';

const RuleBreakdown = ({ versionData, programData }) => {
  const completeRule = versionData.current_rules.join(' AND ');

  if (versionData.change_type !== 'Edit') return completeRule;

  const { isOpen, open, close } = useModalManager();

  return (
    <Fragment>
      <a type="button" onClick={open}>{completeRule}</a>
      <Modal isOpen={isOpen} headerText="Rule Change" closeModal={close}>
        <h2>Before</h2>
        <RuleList
          rules={versionData.previous_rules}
          comparisonRules={versionData.current_rules}
          programId={versionData.previous_program_id}
          comparisonProgramId={versionData.current_program_id}
          diffColor={styles.red}
          programData={programData}
        />

        <hr />

        <h2>After</h2>
        <RuleList
          rules={versionData.current_rules}
          comparisonRules={versionData.previous_rules}
          programId={versionData.current_program_id}
          comparisonProgramId={versionData.previous_program_id}
          diffColor={styles.green}
          programData={programData}
        />
      </Modal>
    </Fragment>
  );
};

RuleBreakdown.propTypes = {
  programData: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string
  })).isRequired,
  versionData: PropTypes.shape({
    change_type: PropTypes.string,
    current_program_id: PropTypes.number,
    current_rules: PropTypes.array,
    previous_program_id: PropTypes.number,
    previous_rules: PropTypes.array
  }).isRequired
};

export default RuleBreakdown;
