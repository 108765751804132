import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Axios from 'axios';

import cleverLogo from 'images/rosteringSetup/cleverLogo.png';
import onerosterLogo from 'images/rosteringSetup/onerosterLogo.png';
import sftpIcon from 'images/rosteringSetup/sftpIcon.svg';

import Footer from 'common/Wizard/Footer';
import { renderErrors, SubmitError } from 'common/Forms/Utils';
import styles from './RosteringStep.module.scss';

import { ROSTER_METHOD_STEP, completedStepsPropTypes } from '../../steps';

export default class RosteringStep extends Component {
  static isFormEmpty(values) {
    return values.sync_type === null;
  }

  static propTypes = {
    autoRosterDistrictPath: PropTypes.string.isRequired,
    completedSteps: completedStepsPropTypes.isRequired,
    subscriberUpdatePath: PropTypes.string.isRequired,
    updateRosterDistrict: PropTypes.func.isRequired,
    subscriberId: PropTypes.number.isRequired,
    next: PropTypes.func,
  };

  static defaultProps = {
    next: () => {},
  };

  constructor(props) {
    super(props);

    this.providerHash = {
      clever_api: 'clever',
      oneroster_api: 'oneroster',
      tci_csv: 'tci_csv'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getAutoRosterDistrictParams(values) {
    return { auto_roster_district: { ...values, subscriber_id: this.props.subscriberId } };
  }

  getSubscriberParams(values) {
    return { subscriber: { provider: this.providerHash[values.sync_type] } };
  }

  handleSubmit(values) {
    const method = this.props.completedSteps[ROSTER_METHOD_STEP] ? 'put' : 'post';
    return Promise.all([
      Axios({
        method,
        url: this.props.autoRosterDistrictPath,
        data: this.getAutoRosterDistrictParams(values)
      }),
      Axios.put(this.props.subscriberUpdatePath, this.getSubscriberParams(values))
    ])
      .then((responses) => {
        const rosteringResponse = responses[0];

        if (rosteringResponse.data.errors) {
          return { [FORM_ERROR]: renderErrors(rosteringResponse.data.errors) };
        }

        this.props.updateRosterDistrict(rosteringResponse.data.data);
        return this.props.next();
      })
      .catch(error => ({ [FORM_ERROR]: renderErrors(error.response.data.errors) }));
  }

  render() {
    return (
      <div>
        <p>To begin rostering integration, please select one of the methods below.</p>
        <Form
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={{ sync_type: this.props.completedSteps[ROSTER_METHOD_STEP] }}
        >
          {({
              handleSubmit, submitError, values
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.choices}>
                <Field name="sync_type" type="radio" value="clever_api" required>
                  {({ input }) => (
                    <div className={styles.radioContainer}>
                      <div className={styles.content}>
                        <label className={styles.radioLabel} htmlFor="clever-radio">
                          <span className="sr-only">clever</span>
                          <img className={styles.image} alt="" src={cleverLogo} />
                        </label>
                        <input className={styles.radio} id="clever-radio" type="radio" {...input} required />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="sync_type" type="radio" value="oneroster_api">
                  {({ input }) => (
                    <div className={styles.radioContainer}>
                      <div className={styles.content}>
                        <label className={styles.radioLabel} htmlFor="oneroster-radio">
                          <span className="sr-only">one roster</span>
                          <img className={styles.image} alt="" src={onerosterLogo} />
                        </label>
                        <input className={styles.radio} id="oneroster-radio" type="radio" {...input} />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="sync_type" type="radio" value="tci_csv">
                  {({ input }) => (
                    <div className={styles.radioContainer}>
                      <div className={styles.content}>
                        <label className={styles.radioLabel} htmlFor="csv-radio">
                          <span className="sr-only">TCI CSV upload</span>
                          <img className={`${styles.image} ${styles.sftpImage}`} alt="" src={sftpIcon} />
                        </label>
                        <input className={styles.radio} id="csv-radio" type="radio" {...input} />
                      </div>
                    </div>
                  )}
                </Field>
              </div>


              <SubmitError error={submitError} />

              <Footer page={ROSTER_METHOD_STEP} disabled={RosteringStep.isFormEmpty(values)} />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
