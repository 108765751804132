import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider, QueryObserverOptions } from '@tanstack/react-query';

const BASE_OPTIONS: QueryObserverOptions = {
  networkMode: 'always',
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: 0,
};

export enum QueryKeys {
  AssessmentExerciseResults = 'ASSESSMENT_EXERCISE_RESULTS',
  ReferenceMaterialExerciseResults = 'REFERENCE_MATERIAL_EXERCISE_RESULTS',
  GameExerciseResults = 'GAME_EXERCISE_RESULTS',
  VideoActivityExerciseResults = 'VIDEO_ACTIVITY_EXERCISE_RESULTS',
  ClassroomLesson = 'CLASSROOM_LESSON',
  Students = 'STUDENTS',
  AssessmentQuestionAnswers = 'ASSESSMENT_QUESTION_ANSWERS',
  ReferenceMaterialQuestionAnswers = 'REFERENCE_MATERIAL_QUESTION_ANSWERS',
  VideoActivityQuestionAnswers = 'VIDEO_ACTIVITY_QUESTION_ANSWERS',
  ReadingGameExerciseResults = 'READING_GAME_EXERCISE_RESULTS',
  ReadingGames = 'READING_GAMES',
  SlideShowExerciseResults = 'SLIDESHOW_EXERCISE_RESULTS',
  SlideShowQuestionAnswers = 'SLIDESHOW_QUESTION_ANSWERS',
  SectionNotebooksQuestionAnswers = 'SECTION_NOTEBOOKS_QUESTION_ANSWERS',
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { ...BASE_OPTIONS },
  },
});

interface GradesTableQueryClientProviderProps {
  children?: React.ReactNode;
}

const GradesTableQueryClientProvider = ({ children = null }: GradesTableQueryClientProviderProps) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    {children}
  </QueryClientProvider>
);

export default GradesTableQueryClientProvider;
