import React, { useEffect } from 'react';
import Axios from 'axios';
import I18n from 'modules/i18n';
import { plato_api_program_tags_tags_path } from '@/modules/routes';
import CorrelationsSettingsModal from '@/components/shared/Correlations/CorrelationsSettingsModal';
import Spinner from '@/components/common/Spinner';
import CorrelationsContext from '@/components/common/Context';
import useFlags from '@/hooks/useFlags';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import TagCard from './TagCard/TagCard';
import Filters from './Filters';
import useCorrelationsStore from './useCorrelationsStore';

interface CorrelationProps {
  grade: number | null;
  isSysadmin?: boolean;
  programId: number;
  stafferId: number | null;
  state: string;
}

const Correlations = ({ grade, isSysadmin = false, programId, stafferId, state }: CorrelationProps) => {
  const { showAdvancedStandards } = useFlags();
  const isLoading = useCorrelationsStore(s => s.isLoading);
  const setIsLoading = useCorrelationsStore(s => s.setIsLoading);
  const tags = useCorrelationsStore(s => s.tags);
  const setTags = useCorrelationsStore(s => s.setTags);
  const filteredTags = useCorrelationsStore(s => s.filteredTags);

  useEffect(() => {
    if (tags.length > 0) return;

    setIsLoading(true);
    Axios
      .get(plato_api_program_tags_tags_path({ program_id: programId }))
      .then(res => setTags(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  const renderTags = () => {
    if (tags.length === 0) return I18n.t('no_correlations_message');

    const tagsToRender = filteredTags.length > 0 ? filteredTags : tags;

    return (
      tagsToRender.map(tag => (
        <TagCard
          key={tag.id}
          tag={tag}
          anchored={window.location.hash.substr(1) === tag.alias}
          isSysadmin={isSysadmin}
        />
      ))
    );
  };

  return (
    <CorrelationsContext.Provider value={{ programId }}>
      <div className="tw-flex">
        {tags.length > 0 && (
          <Filters filterData={tags} />
        )}
        <section aria-label="Benchmarks container">
          {stafferId && showAdvancedStandards && (
            <CorrelationsSettingsModal
              grade={grade}
              programId={programId}
              refetch={() => setIsLoading(true)}
              stafferId={stafferId}
              state={state}
            />
          )}
          {isLoading ? <Spinner /> : renderTags()}
        </section>
      </div>
    </CorrelationsContext.Provider>
  );
};

const CorrelationsWrapper = (props: CorrelationProps) => (
  <QueryClientProvider client={queryClient}>
    <Correlations {...props} />
  </QueryClientProvider>
);

export default CorrelationsWrapper;
