import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from 'common/Tooltip';

const tooltipMessage = 'This change will be applied in the next sync.';

const ChangeDate = ({ changeDate, lastSuccessfulSyncTime }) => {
  const changeApplied = moment(lastSuccessfulSyncTime).isAfter(changeDate);

  return (
    <div className="dsp-flex">
      <div>
        {moment(changeDate).format('MMM D, YYYY')}
      </div>
      {!changeApplied && (
        <div className="ml5">
          <Tooltip content={tooltipMessage} size="medium" theme="white">
            <i className="fa fa-clock-o" aria-label={tooltipMessage} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

ChangeDate.propTypes = {
  changeDate: PropTypes.string.isRequired,
  lastSuccessfulSyncTime: PropTypes.string
};

ChangeDate.defaultProps = {
  lastSuccessfulSyncTime: null
};

export default ChangeDate;
