import React from 'react';
import { Path } from 'fabric';
import useDrawingContext from '../../store/useDrawingContext';
import useActionBarStore from '../../ActionBar/useActionBarStore';
import icons from '../icons';
import PenSettingsInput from './PenSettingsInput';
import { getPenWidth, renderWeightSetting } from './penSettingUtils';
import Button from './Button';

const WeightButton = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const globalPenWidth = useDrawingContext(state => state.penWidth);
  const setPenWidth = useDrawingContext(state => state.setPenWidth);
  const { alt, icon } = icons.weight;

  const width = getPenWidth(canvas, globalPenWidth);
  const activeObjects = canvas?.getActiveObjects() || [];

  // force the component to re-render when the coordinates change
  // this happens when the selected object changes
  useActionBarStore(state => state.coordinates);

  const handleWeightChange = (newWidth: number) => {
    if (activeObjects.length > 0) {
      activeObjects.forEach((obj) => {
        if (!obj.isType(Path.type)) return;

        /* eslint-disable-next-line no-param-reassign */
        obj.strokeWidth = newWidth;
      });

      canvas.requestRenderAll();

      return;
    }

    setPenWidth(newWidth);
  };

  return (
    <Button
      alt={alt}
      disabled={!renderWeightSetting(canvas)}
      src={icon}
      type="weight"
    >
      <PenSettingsInput
        defaultValue={width}
        label={alt}
        min={2}
        onChange={handleWeightChange}
      />
    </Button>
  );
};

export default WeightButton;
