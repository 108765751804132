import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import update from 'immutability-helper';
import { slideObjectPropTypes } from '../../Utils';
import AddVideo from '../../../VideoManager/Add';
import VideoPlayer from '../../../../common/VideoPlayer';
import styles from '../Slide.module.scss';
import EditVideo from '../../../VideoManager/Edit';
import { renderYoutubeIframe, UPDATE_SLIDE_OBJECT } from '../../../VideoManager/Utils';
import { repositionControlBar } from '../hooks/useVideoControlBarRepositioner';
import useSlideShowContext from '../../stores/useSlideShowContext';
import { autoplayMediaSetting } from '../../PresentMode/Utils';

const SlideObjectVideo = ({ interactable, modalManager, slideObject, thumbnail, userType }) => {
  const videoContainerRef = useRef();

  const [videoHeight, setVideoHeight] = useState('');
  const [videoWidth, setVideoWidth] = useState('');
  const [loading, setLoading] = useState(true);
  const [slideObjectMutation, { loading: youtubeSubmitLoading }] = useMutation(UPDATE_SLIDE_OBJECT);
  const locale = useSlideShowContext(state => state.locale);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  useLayoutEffect(() => {
    if (loading) return;

    const $el = $(videoContainerRef.current);

    // Scale video container width to match aspect ratio.
    if (videoWidth > videoHeight) {
      const x = videoWidth * $el.height() / videoHeight;

      $el.css('width', `${x}px`);
    }
  }, [loading]);

  const onYoutubeSubmit = (formValues) => {
    const variables = { variables: { html: formValues.youtube_video_url, slideObjectId: slideObject.id } };

    slideObjectMutation(variables)
      .then(() => {
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const afterSubmit = (modelVideo) => {
    const newSlideObject = update(slideObject, {
      modelVideo: {
        $set: modelVideo,
      },
    });

    updateSlideObject(newSlideObject);
  }

  const renderModal = () => {
    if (slideObject.modelVideo || slideObject.html) {
      return (
        <EditVideo
          afterSubmit={afterSubmit}
          headerText="Edit Video"
          modelId={slideObject.id}
          modalManager={modalManager}
          modelType="SlideObject"
          modelVideo={slideObject.modelVideo}
          onYoutubeSubmit={onYoutubeSubmit}
          showYoutubeOption
          standaloneButton={false}
          userType={userType}
          youtubeSubmitLoading={youtubeSubmitLoading}
          youtubeVideoURL={slideObject.html}
        />
      );
    }

    return (
      <AddVideo
        afterSubmit={afterSubmit}
        modelId={slideObject.id}
        modalManager={modalManager}
        modelType="SlideObject"
        onYoutubeSubmit={onYoutubeSubmit}
        showYoutubeOption
        standaloneButton={false}
        userType={userType}
        youtubeSubmitLoading={youtubeSubmitLoading}
      />
    );
  };

  const renderTCIVideo = () => {
    const {
      // eslint-disable-next-line camelcase
      audioDescriptionsCues: audio_descriptions_cues,
      // eslint-disable-next-line camelcase
      audioDescriptionsUrl: audio_descriptions_url,
      // eslint-disable-next-line camelcase
      captionUrl: caption_url,
      // eslint-disable-next-line camelcase
      captionEsUrl: caption_es_url,
      // eslint-disable-next-line camelcase
      isSilent: is_silent,
      // eslint-disable-next-line camelcase
      posterUrl: poster_url,
      // eslint-disable-next-line camelcase
      sdUrl: sd_url,
      // eslint-disable-next-line camelcase
      hdUrl: hd_url,
      // eslint-disable-next-line camelcase
      fhdUrl: fhd_url,
      // eslint-disable-next-line camelcase
      esUrl: es_url,
      // eslint-disable-next-line camelcase
      sdEsUrl: sd_es_url,
      // eslint-disable-next-line camelcase
      hdEsUrl: hd_es_url,
      // eslint-disable-next-line camelcase
      fhdEsUrl: fhd_es_url,
      // eslint-disable-next-line camelcase
      posterEsUrl: poster_es_url,
      url,
    } = slideObject.modelVideo.video;

    const video = {
      audio_descriptions_cues, audio_descriptions_url, caption_es_url, caption_url, es_url, fhd_es_url,
      fhd_url, hd_es_url, hd_url, is_silent, poster_es_url, poster_url, sd_es_url, sd_url, url,
    };

    return (
      <VideoPlayer
        key={`${slideObject.id}-${locale}`}
        locale={locale}
        loading={loading}
        video={video}
        width="100%"
        setLoading={setLoading}
        setVideoHeight={setVideoHeight}
        setVideoWidth={setVideoWidth}
        onPlay={repositionControlBar}
        shouldAutoPlay={
          !(interactable || thumbnail) &&
            slideObject.modelVideo.autoplay &&
            autoplayMediaSetting(slideShowId)
        }
      />
    );
  };

  const renderVideo = () => {
    if (slideObject.modelVideo) return renderTCIVideo();

    if (slideObject.html) return renderYoutubeIframe(slideObject.html);

    return null;
  };

  return (
    <div
      className={`${styles.slideObjectVideoContainer} ${loading && slideObject.modelVideo ? 'hide' : ''}`}
      ref={videoContainerRef}
      style={{
        height: videoHeight,
        width: videoWidth,
      }}
    >
      {renderVideo()}
      {interactable && renderModal()}
    </div>
  );
};

SlideObjectVideo.propTypes = {
  interactable: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  thumbnail: PropTypes.bool,
  userType: PropTypes.string.isRequired,
};

SlideObjectVideo.defaultProps = {
  interactable: true
};

export default SlideObjectVideo;
