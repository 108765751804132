import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeWords } from 'TCIUtils';
import styles from './AssignmentsTable.module.scss';

export const ASSIGNMENT_DETAILS_TOOLTIP_CLASS = 'assignment-details-tooltip-icon';

const divideSections = (sections) => {
  if (!sections) {
    return [null, []];
  }

  const streams = sections.filter(section => section.stream);
  const text = sections.filter(section => !section.stream);

  return [streams, text];
};

const filterNotebooks = (notebooks) => {
  if (!notebooks) {
    return [];
  }

  return notebooks.filter(notebook => notebook.unit_project);
};

const getReadingNotebooks = (notebooks, assignedReadingSections) => {
  if (!notebooks) return [];

  // only show reading notebooks link if the reading text has not been assigned
  return notebooks.filter(notebook => (!notebook.unit_project && !notebook.video_activity &&
    !assignedReadingSections?.find((assignedReading) => assignedReading.id === notebook.parent_section)))
}

const getReadingGames = (readingGames, assignedReadingSections) => {
  if (!readingGames) return [];

  // only show reading games link if the reading text has not been assigned
  return readingGames.filter(readingGame => !assignedReadingSections?.find((assignedReading) => assignedReading.id === readingGame.parent_section))
}

const filterVideoActivities = (notebooks, slideShows) => {
  if (!notebooks && !slideShows) return [];

  const assignables = (notebooks || []).concat(slideShows || []);

  return assignables.filter(assignable => (assignable && assignable.video_activity));
};

const filterClassroomActivities = (slideShows) => {
  if (!slideShows) return [];

  return slideShows.filter(ss => ss.classroom_activity);
};

export const AssignmentModels = ({ models, className, isStudent }) => {
  if (!models) return null;

  return models.map((model) => {
    const status = model.status ? capitalizeWords(model.status.replace('_', ' ')) : null;

    return (
      <li key={`${model.label} ${model.href}`} className={`${styles['dropdown-item']} ${className} ${styles.listItem}`}>
        {model.before_assignment_start_date && isStudent ? (
          <div>
            {model.label}
            {' '}
            <i className={`fa fa-info-circle ${ASSIGNMENT_DETAILS_TOOLTIP_CLASS}`} />
          </div>
        ) : (
          <a
            href={model.href}
            rel="noopener noreferrer"
            target="_blank"
            className="tw-flex"
          >
            <div dangerouslySetInnerHTML={{ __html: model.label }} />
            <i className="fa fa-external-link purple ml5" />
          </a>
        )}
        {' '}
        <span className={`${styles[model.status]}`}>{status}</span>
      </li>
    );
  });
};

const ModelDetails = ({ models,
  isStudent,
  science = false,
  displayUnitContent = false,
  unitProjects = false
}) => {
  if (!models.length) {
    return null;
  }

  return (
    <li className={`${styles.listItem} ${styles['dropdown-item']}`}>
      <ul>
        <AssignmentModels
          models={models}
          className={styles.sectionItem}
          isStudent={isStudent}
        />
      </ul>
    </li>
  );
};

ModelDetails.propTypes = {
  displayUnitContent: PropTypes.bool,
  models: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    before_assignment_start_date: PropTypes.bool
  })).isRequired,
  isStudent: PropTypes.number,
  science: PropTypes.bool,
  unitProjects: PropTypes.bool
};

ModelDetails.defaultProps = {
  displayUnitContent: false,
  science: true,
  unitProjects: false
};

const TeacherNote = ({ rowData, isStudent }) => {
  if (!rowData.notes) return null;

  return (
    <div className={styles.noteContainer}>
      {!!isStudent && <span>{rowData.teacher_name}:</span>}

      <div className={styles.notes}>
        {rowData.notes}
      </div>

      <div className={styles.noteBubbleTail}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <line x1="0" y1="0" x2="0" y2="15" style={{ stroke: "#ccc", strokeWidth: 2 }} />
          <line x1="0" y1="0" x2="24" y2="15" style={{ stroke: "#ccc", strokeWidth: 1 }} />
        </svg>
      </div>
    </div>
  );
};

TeacherNote.propTypes = {
  isStudent: PropTypes.number,
  rowData: PropTypes.shape({
    notes: PropTypes.string,
    teacher_name: PropTypes.string
  }).isRequired
};

TeacherNote.defaultProps = {
  isStudent: true
};

const AssignmentDetails = ({ rowData, isStudent, science, displayUnitContent }) => {
  if (!rowData) return null;

  const { Notebook, Section, SlideShow, Challenge, Assessment, Game, SlideGroup, ReadingGame, ReferenceMaterial } = rowData.assignment_models_hash;
  const unitProjects = filterNotebooks(Notebook);
  const [streams, text] = divideSections(Section);
  const readingNotebooksToShow = getReadingNotebooks(Notebook, text);
  const readingGamesToShow = getReadingGames(ReadingGame, text)
  const videoActivity = filterVideoActivities(Notebook, SlideShow);
  const classroomActivity = filterClassroomActivities(SlideShow);

  return (
    <div>
      <AssignmentModels models={streams} isStudent={isStudent} />
      <AssignmentModels models={SlideGroup} isStudent={isStudent} />
      <ModelDetails models={text} science={science} displayUnitContent={displayUnitContent} isStudent={isStudent} />
      <AssignmentModels models={readingNotebooksToShow} isStudent={isStudent} />
      <AssignmentModels models={readingGamesToShow} isStudent={isStudent} />
      <AssignmentModels models={Challenge} isStudent={isStudent} />
      <AssignmentModels models={Assessment} isStudent={isStudent} />
      <AssignmentModels models={Game} isStudent={isStudent} />
      <ModelDetails models={unitProjects} isStudent={isStudent} unitProjects />
      <AssignmentModels models={videoActivity} isStudent={isStudent} />
      <AssignmentModels models={classroomActivity} isStudent={isStudent} />
      <AssignmentModels models={ReferenceMaterial} isStudent={isStudent} />
      <TeacherNote rowData={rowData} isStudent={isStudent} />
    </div>
  );
};

export default AssignmentDetails;
