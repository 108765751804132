import React from 'react';
import Anchor from '@/components/ui/Anchor';
import { cleanUpDeleteButton } from '@/components/shared/Froala/plugins/VocabBox/vocabBoxUtils';

interface DeleteVocabBoxProps {
  vocabBox: HTMLElement ;
  editor: any;
}

const DeleteVocabBox = ({ vocabBox, editor } : DeleteVocabBoxProps) => {
  if (!vocabBox) return (<div> </div>);

  const deleteNode = () => {
    vocabBox.remove();

    editor.html.set(editor.html.get());
    editor.undo.saveStep();
    editor.events.trigger('contentChanged');

    setTimeout(cleanUpDeleteButton, 100);
  };

  return (
    <Anchor
      to={vocabBox}
      offset={{ top: 55 }}
    >
      <button
        onClick={deleteNode}
        id="vocabBoxDeleteButton"
        type="button"
        className="tw-h-12 tw-w-24 tw-bg-white tw-outline-none tw-rounded-lg tw-shadow-xl tw-border-none"
      >
        <span className="tw-text-red">
          <i className="fa fa-trash-o fa-2x" />
        </span>
      </button>
    </Anchor>
  );
};

export default DeleteVocabBox;
