import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager, Footer } from 'common/Modal';
import Axios from 'axios';
import * as Routes from 'routes';
import Tooltip from 'common/Tooltip';

const DisableSeatPoolButton = ({
  hasUsers, programTitle, seatPoolId, subscriber
}) => {
  const { isOpen, open, close } = useModalManager();

  const disableSeatPool = (e) => {
    e.preventDefault();

    Axios
      .patch(Routes.disable_admin_subscriber_seat_pool_path(subscriber.id, seatPoolId))
      .then(() => window.location.reload());
  };

  const removeModal = () => (
    <Modal headerText="Remove Program" closeModal={close} isOpen={isOpen}>
      <form onSubmit={disableSeatPool}>
        <p className="lh1_3">
          Teachers and students in this district will no longer be able to use this program.
          Are you sure you want to remove <span dangerouslySetInnerHTML={{ __html: programTitle }} />&nbsp;
          from {subscriber.name}?
        </p>
        <Footer
          primaryButtonText="Remove Program"
          primaryButtonCallback={disableSeatPool}
          secondaryButtonCallback={close}
        />
      </form>
    </Modal>
  );

  const enabledOption = () => {
    return (
      <div>
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={open}
        >
          Remove Program
        </a>
        {removeModal()}
      </div>
    );
  };

  const tooltipContent = () => (
    <div>
      <div>You cannot remove programs that are still in use.</div>
      <div>Tip: Bulk Remove program from all Staff.</div>
    </div>
  );

  const disabledOption = () => (
    <Tooltip
      allowHTML
      content={tooltipContent()}
      interactive={false}
      size="medium"
      theme="white"
    >
      <div>
        <a
          className="dropdown-item disabled"
          href="#"
          role="button"
        >
          Remove Program
        </a>
      </div>
    </Tooltip>
  );

  return (
    <Fragment>
      {hasUsers ? disabledOption() : enabledOption()}
    </Fragment>
  );
};

DisableSeatPoolButton.propTypes = {
  hasUsers: PropTypes.bool.isRequired,
  programTitle: PropTypes.string.isRequired,
  seatPoolId: PropTypes.number.isRequired,
  subscriber: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default DisableSeatPoolButton;
