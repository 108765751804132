import { useQuery } from '@apollo/client';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { GET_ALL_SLIDE_SHOW_IMAGES } from './slideShowGraphQL';

export const getSlideShowImageQuery = (slideShowId: number | string) => {
  const { refetch } = useQuery(GET_ALL_SLIDE_SHOW_IMAGES, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const fetchImages = async () => {
    const { data } = await refetch({ slideShowId });

    return data.slideShow.slides.reduce((slideImageModels, slide) => {
      // add slide background image
      if (slide.imageModel) slideImageModels.push(slide.imageModel);

      // add slide object images
      if (slide.slideObjects) {
        const imageModels = slide.slideObjects.map(slideObject => slideObject.imageModels).flat();
        slideImageModels.push(...imageModels);
      }

      // add html element images
      if (slide.htmlElements) {
        const imageModels = slide.htmlElements.map(htmlElement => htmlElement.imageModels).flat();
        slideImageModels.push(...imageModels);
      }

      return slideImageModels;
    }, []);
  };

  return fetchImages;
};

const useSlideShowImageFetcher = () => {
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  return { fetchImages: getSlideShowImageQuery(slideShowId) };
};

export default useSlideShowImageFetcher;
