/* eslint-disable no-param-reassign */

import React from 'react';

import Checkbox from '@/components/common/libraries/common/Checkbox';
import { Divider, FilterCategory } from '@/components/common/libraries/common/Filter';
import { FilterField, UnitFilter, VideoFilter } from '@/components/common/libraries/VideoLibrary/types';
import { userInfo } from '@/components/common/libraries/actions';

import styles from './FilterBar.module.scss';

interface Props {
  filters: VideoFilter;
  onFilterChange: (newFilters: VideoFilter) => void;
}

const FilterBar = ({ filters, onFilterChange }: Props) => {
  const isStudent = !userInfo.staffer;

  const handleLessonChange = (value: string | number, checked: boolean) => {
    const newFilters = { ...filters };
    newFilters.unit?.forEach((unitFilter) => {
      const lesson = unitFilter.lessons.find(l => l.value === value);

      if (lesson) {
        lesson.checked = checked;
      }
    });

    newFilters.lesson?.forEach((lessonFilter) => {
      if (lessonFilter.value === value) {
        lessonFilter.checked = checked;
      }
    });

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  const handleUnitChange = (oldUnit: UnitFilter, checked: boolean) => {
    const newFilters = { ...filters };
    const newLessons = oldUnit.lessons.map(lesson => ({ ...lesson, checked }));

    newFilters.unit?.forEach((unitFilter) => {
      if (unitFilter.label === oldUnit.label) {
        unitFilter.lessons = newLessons;
      }
    });

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  const handleIncludedChange = (value: string | number, checked: boolean) => {
    const newFilters = { ...filters };

    const newIncluded = newFilters.included_in.find(
      includedFilter => includedFilter.value === value,
    );

    if (newIncluded) {
      newIncluded.checked = checked;
    }

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  const handleCategoryChange = (value: string | number, checked: boolean) => {
    const newFilters = { ...filters };

    const newCategory = newFilters.category.find(categoryFilter => categoryFilter.value === value);

    if (newCategory) {
      newCategory.checked = checked;
    }

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  const handleStatusChange = (value: string | number, checked: boolean) => {
    const newFilters = { ...filters };

    const newStatus = newFilters.status.find(statusFilter => statusFilter.value === value);

    if (newStatus) {
      newStatus.checked = checked;
    }

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  const handleFilterClear = () => {
    const newFilters = { ...filters };
    const setUnchecked = (filter: FilterField) => {
      filter.checked = false;
    };

    newFilters.unit?.forEach((unitFilter) => {
      unitFilter.lessons.forEach(setUnchecked);
    });
    newFilters.lesson?.forEach(setUnchecked);
    newFilters.category.forEach(setUnchecked);
    newFilters.included_in.forEach(setUnchecked);
    newFilters.status.forEach(setUnchecked);
    newFilters.keyword = '';

    newFilters.page = 1;

    onFilterChange(newFilters);
  };

  return (
    <div className={`${styles.bar} card`}>
      <div className={styles.barTitle}>Filters</div>
      <button
        className={`btn btn--link-purple ${styles.clearButton}`}
        type="button"
        onClick={handleFilterClear}
      >
        Clear
      </button>

      <Divider />

      {!!filters.unit?.length && (
        <FilterCategory
          className={styles.category}
          shouldCollapse={false}
          triggerElement={<div className={styles.categoryTitle}>Unit</div>}
        >
          {filters.unit.map((unitFilter) => {
            const allLessonsChecked = unitFilter.lessons.every(lesson => lesson.checked);
            const someLessonsChecked = unitFilter.lessons.some(lesson => lesson.checked);

            return (
              <FilterCategory
                key={unitFilter.label}
                filterClassName={styles.innerFilter}
                filters={unitFilter.lessons}
                onChange={handleLessonChange}
                triggerElement={(
                  <Checkbox
                    extraInputClassName="hover:tw-outline hover:tw-border-black focus:tw-outline focus:tw-outline-1 focus:tw-outline-black"
                    extraClassName="mb10"
                    indeterminate={someLessonsChecked && !allLessonsChecked}
                    label={unitFilter.label}
                    checked={unitFilter.lessons.every(lesson => lesson.checked)}
                    onChange={(event) => {
                      handleUnitChange(unitFilter, event.target.checked);
                    }}
                  />
                )}
              />
            );
          })}
        </FilterCategory>
      )}

      {!!filters.lesson?.length && (
        <FilterCategory
          filters={filters.lesson}
          onChange={handleLessonChange}
          triggerElement={<div className={styles.categoryTitle}>Lesson</div>}
        />
      )}

      {!isStudent && filters.included_in && (
        <FilterCategory
          filters={filters.included_in.sort((a, b) => a.label.localeCompare(b.label))}
          onChange={handleIncludedChange}
          shouldCollapse={false}
          triggerElement={<div className={styles.categoryTitle}>Included In</div>}
        />
      )}

      {!!filters.category.length && (
        <FilterCategory
          filters={filters.category}
          onChange={handleCategoryChange}
          triggerElement={<div className={styles.categoryTitle}>Category</div>}
        />
      )}

      {!isStudent && (
        <FilterCategory
          filters={filters.status}
          onChange={handleStatusChange}
          triggerElement={<div className={styles.categoryTitle}>Status</div>}
        />
      )}
    </div>
  );
};

export default FilterBar;
