import React from 'react';
import clsx from 'clsx';
import shapesIcons from './icons';
import useDrawingContext from '../../store/useDrawingContext';
import { scaleValue, listItemStyle } from '../styles';

interface ShapeCategoryProps {
  category: string;
  categoryId: string;
  setCategory: (category: string) => void;
  title: string;
}

export const className = (selected = false) => clsx(
  'tw-w-full tw-cursor-pointer tw-block tw-py-1 tw-px-4 tw-border-0 tw-text-left tw-text-[#555] tw-bg-white',
  '!tw-outline-1',
  'tw-px-6 tw-py-2 tw-text-base tw-font-tci',
  'tw-flex tw-items-center tw-justify-start',
  'hover:tw-bg-light-grey hover:tw-outline-1 hover:tw-text-tci-purple',
  '!focus:tw-outline-1',
  { '!tw-text-tci-purple !tw-bg-light-grey !tw-outline-1 tw-outline-grey': selected },
);

const ShapeCategory = ({ category, categoryId, setCategory, title }: ShapeCategoryProps) => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const renderIcon = () => shapesIcons[categoryId]();

  return (
    <button
      aria-haspopup="menu"
      type="button"
      onClick={() => {
        setCategory(title);
      }}
      className={className(category === title)}
      style={listItemStyle(toolbarEl)}
    >
      {renderIcon()}
      <span className="tw-px-4">{title}</span>
    </button>
  );
};

export default ShapeCategory;
