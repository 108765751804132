export const isEllipsisActive = (e: HTMLElement) => {
  return e.offsetWidth < e.scrollWidth;
};

export const compareObjects = (obj1: object, obj2: object) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  const isDifferent = Object.keys(obj1).some((key) => {
    const objKey = key as keyof typeof obj1;
    if (typeof obj1[objKey] !== typeof obj2[objKey]) {
      return true;
    }

    if (typeof obj1[objKey] === 'object') {
      if (!compareObjects(obj1[objKey], obj2[objKey])) {
        return true;
      }
    } else if (obj1[objKey] !== obj2[objKey]) {
      return true;
    }

    return false;
  });

  return !isDifferent;
};

/**
 * Returns the string truncated at the designated length
 * @param string
 */
export function truncate(string: string, length: number) {
  return string.length > length ? `${string.substring(0, length)}…` : string;
}

/**
 * Returns a key without the specified prefix
 * @param obj object
 * @param prefix string
 */
function removePrefix(key, prefix) {
  return key.startsWith(prefix) ? key.substring(prefix.length) : key;
}

/**
 * Filters object keys and renames them without the prefix
 * @param obj object
 * @param prefix string
 */
export function filterAndRemovePrefix(obj, prefix) {
  return Object.keys(obj).reduce((filteredObj, key) => {
    const newKey = removePrefix(key, prefix);
    filteredObj[newKey] = obj[key];
    return filteredObj;
  }, {});
}
