import React, { Component } from 'react';
import Modal from '../Modal';
import ImageUploader from '../ImageUploader';
import ImageSelector from '../ImageSelector';
import styles from './ImageManager.module.scss';

export default class ImageManager extends Component {
  static defaultProps = {
    isOpen: false,
    imageUrl: null,
    uploading: false,
    addNew: true
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}
             headerText="Add image"
             closeModal={this.props.onClose}
             className="image-manager">
        <div className={styles['image-type-selectors-wrapper']} >
          <label onClick={this.setToAddNew.bind(this)}>
            <input type="radio" name="type-of-add" defaultChecked={this.state.addNew} />
            Add New
          </label>
          <label onClick={this.setToAddExisting.bind(this)}>
            <input type="radio" name="type-of-add" defaultChecked={!this.state.addNew} />
            Add Existing
          </label>
        </div>
        <div style={{ display: this.state.addNew === true ? 'block' : 'none' }} >
          <ImageUploader
            allowRemove={true}
            inputId="file-new"
            required={true}
            onFileAdded={this.onFileAdded.bind(this)}
            onFileRemoved={this.onFileRemoved.bind(this)}
            imageUrl={this.state.imageUrl}/>

          {this.showSubmitButton()}
        </div>
        <div style={{ display: this.state.addNew === true ? 'none' : 'flex' }} >
          <ImageSelector getImagesUrl={this.props.getExistingImagesUrl} onAddImage={this.props.onAddImage} />
        </div>
      </Modal>
    )
  }

  showSubmitButton() {
    if (this.state.imageFile) {
      return (
        <div className="block_link" onClick={this.handleFileSubmit.bind(this)}>
          {this.state.uploading ? 'Adding Image...' : 'Add Image'}
        </div>
      )
    }
  }

  setToAddNew() {
    this.setState({ addNew: true });
  }

  setToAddExisting() {
    this.setState({ addNew: false });
  }

  handleFileSubmit() {
    this.setState({
      uploading: true
    });
    this.props.onAddImage(this.state.imageFile)
  }

  onFileAdded(imageUrl, imageFile) {
    this.setState({
      imageFile,
      imageUrl,
      removeImage: false
    });
  }

  onFileRemoved() {
    this.setState({
      imageFile: null,
      imageUrl: null,
      removeImage: true
    });
  }
}