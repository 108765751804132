import React, { useState } from 'react';
import I18n from 'i18n-js';
import { ApolloProvider, useMutation } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import Modal, { Footer, useModalManager } from '@/components/common/Modal';
import { SubmitError } from '@/components/common/Forms/Utils';
import { SlideGroup } from '@/components/admin/SlideShow/Builder/CreateTransistionGroup/utils';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { REMOVE_SLIDE_GROUP } from './slideShowGraphQL';

interface RemoveSlideGroupProps {
  slideGroup: SlideGroup;
}

const RemoveSlideGroup = ({ slideGroup }: RemoveSlideGroupProps) => {
  const modalManager = useModalManager();
  const [removeSlideGroupMutation] = useMutation(REMOVE_SLIDE_GROUP);
  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { id, titleEn, titleEs } = slideGroup;
  const removeSlideGroup = useSlideShowContext(state => state.removeSlideGroup);

  const destroySlideGroup = () => {
    setSubmitting(true);
    removeSlideGroupMutation({ variables: { id } })
      .then((result) => {
        if (!result.data.destroySlideGroupAndSlides.success) {
          console.log(result);
          setHasError(true);
          setSubmitting(false);
          return;
        }

        removeSlideGroup(slideGroup);
        setSubmitting(false);
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setSubmitting(false);
      });
  };

  return (
    <>
      <div>
        <button
          aria-label={I18n.t('delete_slide_group')}
          className="dropdown-item full-size"
          onClick={modalManager.open}
          type="button"
        >
          {I18n.t('delete_slide_group')}
        </button>
      </div>

      <Modal
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        headerText={`${I18n.t('delete_slide_group')} - ${I18n.locale === 'es' ? titleEs : titleEn}`}
      >
        <p>{I18n.t('delete_slide_group_confirm')}</p>
        {hasError && <SubmitError error="Error deleting slide group. Please reload the page and try again." />}
        <Footer
          primaryButtonText={I18n.t('yes_delete')}
          primaryButtonCallback={destroySlideGroup}
          primaryButtonSubmit={false}
          secondaryButtonCallback={modalManager.close}
          primaryButtonClassName="btn btn--red"
          submitting={submitting}
        />
      </Modal>
    </>
  );
};

const RemoveSlideGroupWrapper = (props: RemoveSlideGroupProps) => (
  <ApolloProvider client={apolloClient}>
    <RemoveSlideGroup {...props} />
  </ApolloProvider>
);

export default RemoveSlideGroupWrapper;
