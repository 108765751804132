import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import Axios from 'axios';

import Modal from 'common/Modal';
import {
  filterDuplicateRosters,
  renderCheckboxForRow,
  renderProgramsCount,
  renderTeachersCount
} from './utils';

import { usePaginatedTable } from '../../common/DataTable/PaginatedTable';
import { pollEndpoint } from '../../../modules/TCIUtils';
import SyncResults from './SyncResults';
import FormModalFooter from './ModalFooters/FormModalFooter';
import ResultsModalFooter from './ModalFooters/ResultsModalFooter';

const ClassesModal = ({
  allowManualAdd, closeModal, currentStafferId, hideSkipLinkingButton, isOpen, linkedClassroomMap,
  manualAddDisabledNotice, program, programTitle, provider, redirectOnLinkSkipPath, redirectOnLinkSuccess,
  stafferIdToAssignProgramsTo, subscriberId, licenseId,
}) => {
  const linkedClassrooms = linkedClassroomMap[program.id]?.map(classroom => classroom.id);
  const [selectedClassroomIds, setSelectedClassroomIds] = useState(new Set(linkedClassrooms));
  const [submitting, setSubmitting] = useState(false);
  const [syncResults, setSyncResults] = useState(null);

  const providerName = {
    clever: 'Clever',
    oneroster: 'One Roster'
  };

  const {
    pagesLoaded,
    PaginatedTable,
    resources,
    setResources
  } = usePaginatedTable(10);

  useEffect(() => {
    setResources(filterDuplicateRosters(resources));
  }, [pagesLoaded]);

  const headerText = `Link ${providerName[provider]} Classes for ${programTitle}`;

  const columnMapping = [
    null,
    'name',
    'period',
    'terms',
    'subjects',
    'grade',
    'teachers_count',
    'student_seats_count',
    'same_uid_classrooms'
  ];

  const columns = [
    'select',
    'name',
    'period',
    'term_name',
    'subjects',
    'grades',
    'teachers',
    'student_seats_count',
    'same_uid_classrooms'
  ];

  const checked = classroomId => selectedClassroomIds.has(classroomId);

  const disabled = classroomId => linkedClassrooms?.includes(classroomId);

  const handleCheckboxChange = (e) => {
    const id = parseInt(e.target.value, 10);
    const updatedSet = new Set(selectedClassroomIds);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    }
    else {
      updatedSet.add(id);
    }

    setSelectedClassroomIds(updatedSet);

    renderCheckboxForRow({
      checked: checked(id),
      disabled: disabled(id),
      handleCheckboxChange,
      rowData: { id, name: e.target.dataset.classroomName },
      target: document.getElementById(`bulk-classroom-${id}`),
    });
  };

  const columnDefs = [
    {
      createdCell: (td, cellData, rowData) => {
        $(td).attr({
          'data-classroom-name': rowData.name,
          id: `bulk-classroom-${rowData.id}`
        });

        renderCheckboxForRow({
          checked: checked(rowData.id),
          disabled: disabled(rowData.id),
          handleCheckboxChange,
          rowData,
          target: td
        });
      },
      data: null,
      searchable: false,
      sortable: false,
      targets: 0,
      title: ''
    },
    {
      targets: 3,
      title: 'Term'
    },
    {
      createdCell: (td, cellData, rowData) => renderTeachersCount(td, cellData, rowData),
      targets: 6,
      title: 'Teachers'
    },
    {
      targets: 7,
      title: 'Students'
    },
    {
      createdCell: (td, cellData) => renderProgramsCount(td, cellData),
      targets: 8,
      title: 'Programs'
    },
  ];

  const classroomsIndexPath = Routes.plato_api_subscriber_classrooms_path(
    subscriberId,
    { active: true,
      column: 'columnName',
      for_staffer: stafferIdToAssignProgramsTo,
      order: 'sortDirection',
      page: 'pageNumber',
      per_page: '10',
      with_uid: true
    }
  );

  const syncFinished = response => response.data.auto_roster_sync.sync_status.includes('Finished');

  const linkClasses = () => {
    setSubmitting(true);
    Axios.post(Routes.plato_api_link_program_classrooms_path({
      classroom_ids: [...selectedClassroomIds],
      license_id: licenseId,
      program_id: program.id,
      staffer_id: currentStafferId,
      subscriber_id: subscriberId,
    }))
      .then(res => (
        pollEndpoint({
          endpointUrl: Routes.plato_api_auto_roster_sync_path(res.data.sync_id),
          timeout: 500000,
          until: syncFinished
        })
      ))
      .then(res => Axios.get(Routes.plato_api_get_sync_results_auto_roster_sync_path(
        res.data.auto_roster_sync.id,
        { classroom_ids: [...selectedClassroomIds] }
      )))
      .then((res) => {
        setSubmitting(false);
        setSyncResults(res.data.sync_results);
      })
      .catch(error => console.log(error));
  };

  const skipLinkCallback = () => {
    if (redirectOnLinkSkipPath) {
      return window.location.replace(redirectOnLinkSkipPath.replace('program_id_placeholder', program.id));
    }

    return window.location.reload();
  };

  const skipLinkingClasses = () => {
    const classroomData = {
      classroom: {
        license_id: licenseId,
        name: program.subtitle_en,
        period: 1,
        program_id: program.id,
        staffer_id: stafferIdToAssignProgramsTo,
      }
    };

    Axios.post(Routes.plato_api_classrooms_path(classroomData))
      .then(() => {
        skipLinkCallback();
      })
      .catch(e => console.log(e));
  };

  const footer = () => {
    if (syncResults) {
      return (
        <ResultsModalFooter
          programId={program.id}
          redirectOnLinkSuccess={redirectOnLinkSuccess}
          syncResults={syncResults}
        />
      );
    }

    return (
      <FormModalFooter
        allowManualAdd={allowManualAdd}
        closeModal={closeModal}
        hideSkipLinkingButton={hideSkipLinkingButton}
        linkClasses={linkClasses}
        manualAddDisabledNotice={manualAddDisabledNotice}
        selectedClassroomIds={selectedClassroomIds}
        skipLinkingClasses={skipLinkingClasses}
        submitting={submitting}
      />
    );
  };

  const syncResultsScreen = () => (
    <SyncResults
      allClassrooms={resources}
      syncResults={syncResults}
    />
  );

  const modalContent = () => {
    if (syncResults) return syncResultsScreen();

    return (
      <PaginatedTable
        columns={columns}
        columnDefs={columnDefs}
        columnMapping={columnMapping}
        defaultOrder={[1, 'asc']}
        getUrl={classroomsIndexPath}
        handleResourcesManually
        resources={resources}
      />
    );
  };

  return (
    <Modal
      closeModal={closeModal}
      headerText={headerText}
      isOpen={isOpen}
      nearlyFullScreen
      footer={footer()}
    >
      {modalContent()}
    </Modal>
  );
};

ClassesModal.propTypes = {
  allowManualAdd: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentStafferId: PropTypes.number.isRequired,
  hideSkipLinkingButton: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  licenseId: PropTypes.number.isRequired,
  linkedClassroomMap: PropTypes.shape({}),
  manualAddDisabledNotice: PropTypes.string.isRequired,
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    subtitle_en: PropTypes.string.isRequired,
    title_en: PropTypes.string.isRequired,
  }).isRequired,
  programTitle: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  redirectOnLinkSkipPath: PropTypes.bool.isRequired,
  redirectOnLinkSuccess: PropTypes.bool.isRequired,
  stafferIdToAssignProgramsTo: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
};

ClassesModal.defaultProps = {
  isOpen: false,
  linkedClassroomMap: {},
};

export default ClassesModal;
