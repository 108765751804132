import { useLayoutEffect } from 'react';
import { isVariableElement } from './slideObjectTextUtils';

export const useHtmlVariableListener = (editorInstance: any) => {
  const keysEntered = new Set();

  useLayoutEffect(() => {
    if (!editorInstance) return;

    const keydownHandler = (e: KeyboardEvent) => {
      keysEntered.add(e.key);

      const { anchorNode } = window.getSelection();

      if (!anchorNode) return;

      const element = anchorNode as HTMLElement;

      if (!element) return;
      if (!isVariableElement(element)) return;
      if (keysEntered.has('Meta')) return;

      if (e.key === 'Backspace') {
        element.parentElement.removeChild(element);
        editorInstance.undo.saveStep();
      }

      if (e.key === 'Enter') {
        const br = document.createElement('br');
        element.insertAdjacentElement('afterend', br);

        setTimeout(() => {
          editorInstance.selection.setAfter(element);
          editorInstance.selection.restore();
        }, 0);
      }

      e.preventDefault();
      e.stopPropagation();
    };

    const keyupHandler = (e: KeyboardEvent) => keysEntered.delete(e.key);

    setTimeout(() => {
      const editorElements = [...document.querySelectorAll('.fr-view')];

      editorElements.forEach((node) => {
        node.addEventListener('keydown', keydownHandler, { capture: true });
      });

      editorElements.forEach((node) => {
        node.addEventListener('keyup', keyupHandler, { capture: true });
      });

      return () => {
        [...document.querySelectorAll('.fr-view')].forEach((node) => {
          node.removeEventListener('keydown', keydownHandler);
          node.removeEventListener('keyup', keyupHandler);
        });
      };
    }, 10);
  }, [editorInstance]);
};
