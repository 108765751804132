import React from 'react';
import PropTypes from 'prop-types';
import CanvasIntegrationModal from './CanvasIntegrationModal';
import useCredentialStore from './useCredentialStore';

const EditButton = ({
  modalManager,
  subscriberId,
  hasCompletedLTI,
  initialLmsType,
}) => {
  const setCanvasCredentials = useCredentialStore(state => state.setCanvasCredentials);
  const setLtiCredentials = useCredentialStore(state => state.setLtiCredentials);

  return (
    <span>
      <button
        type="button"
        className="btn btn--outline-purple btn--sm"
        onClick={modalManager.open}
        aria-label="Edit SSO Setup"
      >
        <i className="fa fa-edit" aria-hidden />
        {' '}
        Edit
      </button>

      <CanvasIntegrationModal
        isInitialSetup={false}
        subscriberId={subscriberId}
        modalManager={modalManager}
        hasCompletedLTI={hasCompletedLTI}
        initialLmsType={initialLmsType}
        setCanvasCredentials={setCanvasCredentials}
        setLtiCredentials={setLtiCredentials}
        isEditing
      />
    </span>
  );
};

EditButton.propTypes = {
  hasCompletedLTI: PropTypes.bool.isRequired,
  initialLmsType: PropTypes.string,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  subscriberId: PropTypes.number.isRequired,
};

EditButton.defaultProps = {
  initialLmsType: null,
};

export default EditButton;
