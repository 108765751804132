import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Select from 'react-select';
import { Form } from 'react-final-form';
import { Footer } from '../../../common/Modal';
import showToast from '../../../common/Toast';
import styles from './BulkAddProgram.module.scss';
import {
  RequiredAsterisk,
  SubmitError
} from '../../../common/Forms/Utils';

export default class BulkAddProgram extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    referenceMaterials: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      program_ids: PropTypes.arrayOf(PropTypes.number).isRequired
    })).isRequired,
    updatePath: PropTypes.string.isRequired,
    updateResources: PropTypes.func.isRequired
  };

  static defaultProps = {
    closeModal: () => {}
  };

  static buildFormData(referenceMaterial, values) {
    const formData = new FormData();

    const idsToSend = referenceMaterial.program_ids.concat(values.program_id);

    idsToSend.forEach(id => formData.append('reference_material[program_ids][]', id));

    return formData;
  }

  static showSuccessToast() {
    showToast('Successfully added reference materials to a program.');
  }

  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSubmit(values) {
    this.setState({ submitting: true, updatedResources: [] });

    const requests = this.getReferenceMaterialsToUpdate(values.program_id)
      .map(referenceMaterial => this.sendRequest(values, referenceMaterial));

    return Promise
      .all([...requests])
      .then(() => {
        this.props.updateResources(this.state.updatedResources);
        this.setState({ submitting: false });
        BulkAddProgram.showSuccessToast();
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  }

  getReferenceMaterialsToUpdate(programId) {
    // Only update reference materials that don't already have the program:
    return this.props.referenceMaterials.filter(referenceMaterial => !referenceMaterial.program_ids.includes(programId));
  }

  sendRequest(values, referenceMaterial) {
    const path = this.props.updatePath.replace(':id', referenceMaterial.id);

    return (
      Axios
        .patch(path, BulkAddProgram.buildFormData(referenceMaterial, values))
        .then((response) => {
          if (response.data.errors) {
            console.log(response.data.errors);
          }
          else {
            this.setState(prevState => ({
              updatedResources: [...prevState.updatedResources].concat(response.data.data)
            }));
          }
        })
        .catch((error) => {
          this.setState({ submitting: false });
          console.log(error);
        })
    );
  }

  handleSelect(args, state, utils) {
    this.setState({ program: args[0] });

    const updatedValue = args[0] ? args[0].value : null;
    utils.changeValue(state, 'program_id', () => updatedValue);
  }

  programsForSelect() {
    return this.props.programs.map(program => ({ label: program.title, value: program.id }));
  }

  renderProgramSelect(handleSelect) {
    return (
      <div className={styles.formRow}>
        <label htmlFor="program_id" className={styles.label}>
          Program
          <RequiredAsterisk />
        </label>
        <Select
          id="program_id"
          className={styles.select}
          searchable
          required
          onChange={handleSelect}
          value={this.state.program}
          options={this.programsForSelect()}
        />
      </div>
    );
  }

  renderForm(form, handleSubmit, submitError) {
    return (
      <form onSubmit={handleSubmit}>
        {this.renderProgramSelect((form.mutators.handleSelect))}

        {this.state.error && <SubmitError error={this.state.error} />}

        <SubmitError error={submitError} />

        <Footer
          primaryButtonDisabled={!this.state.program}
          secondaryButtonCallback={this.props.closeModal}
          submitting={this.state.submitting}
        />
      </form>
    );
  }

  render() {
    return (
      <Form
        mutators={{
          handleSelect: this.handleSelect
        }}
        onSubmit={this.handleSubmit}
        validate={this.validateForm}
        render={(
          {
            form, handleSubmit, submitError
          }
        ) => (this.renderForm(form, handleSubmit, submitError))}
      />
    );
  }
}
