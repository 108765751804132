import React from 'react';
import PropTypes from 'prop-types';

import ModalButton from '../ModalButton';
import DeleteForm from './DeleteForm';

export default function DeleteModalButton(props) {
  return (
    <ModalButton
      ariaLabel={props.ariaLabel}
      buttonClasses={props.buttonClasses}
      buttonLabel={props.buttonLabel}
      closeButtonClassName={props.closeButtonClassName}
      modalHeaderText={props.modalHeaderText}
      modalHeaderClass={props.modalHeaderClass}
    >
      <DeleteForm
        deleteButtonClass={props.deleteButtonClass}
        deleteButtonLabel={props.deleteButtonLabel}
        confirmationMessage={props.confirmationMessage}
        destroyPath={props.destroyPath}
        id={props.id}
        removeResource={props.removeResource}
      />
    </ModalButton>
  );
}

DeleteModalButton.propTypes = {
  ariaLabel: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  closeButtonClassName: PropTypes.string,
  confirmationMessage: PropTypes.string,
  deleteButtonClass: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  destroyPath: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modalHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  modalHeaderClass: PropTypes.string,
  removeResource: PropTypes.func.isRequired
};

DeleteModalButton.defaultProps = {
  buttonClasses: 'btn btn--sm btn--outline-red',
  buttonLabel: 'Delete',
  closeButtonClassName: '',
  modalHeaderClass: ''
};
