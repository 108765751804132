import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Axios from 'axios';

import { Footer } from 'common/Modal';
import PathContext from 'common/Context';

import styles from './BulkRemoveProgramsForm.module.scss';

export default class BulkRemoveProgramsForm extends Component {
  static contextType = PathContext;

  static propTypes = {
    bulkProgramRemove: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    stafferIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    subscriberId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { selectedPrograms: new Set(), programsForStaffers: [] };

    this.onSubmit = this.onSubmit.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentDidMount() {
    this.getProgramsForStaffers();
  }

  onSubmit() {
    const msg = 'Removing programs will remove all classroom, student, and program' +
      ' data for all selected teachers. Continue?';
    if (window.confirm(msg)) {
      this.props.bulkProgramRemove(this.state.selectedPrograms);
      this.props.closeModal();
    }
  }

  getProgramsForStaffers() {
    const scope = 'programs_for_staffers';
    let params = `active_for_sub=${this.props.subscriberId}&`
    params += this.props.stafferIds.map(id => `${scope}[]=${id}`).join('&');

    return Axios.get(`${this.context.programsForStaffersPath}?${params}`)
      .then(response => this.setState({ programsForStaffers: response.data.data }))
      .catch(error => console.log(error));
  }

  isChecked(id) {
    return this.state.selectedPrograms.has(id);
  }

  handleCheck(id) {
    this.setState(prevState => ({ selectedPrograms: this._toggleCheck(prevState, id) }));
  }

  _toggleCheck(prevState, id) {
    const updatedSet = new Set(prevState.selectedPrograms);
    this.isChecked(id) ? updatedSet.delete(id) : updatedSet.add(id);

    return updatedSet;
  }

  renderProgram(program) {
    return (
      <div className={styles.programRow} key={`program-${program.id}`} id={`program-${program.id}`}>
        <Field
          id={program.id}
          name={`field-${program.id}`}
          component="input"
          type="checkbox"
          checked={this.isChecked(program.id)}
          onChange={() => this.handleCheck(program.id)}
        />
        <label htmlFor={program.id}>
          <span className={styles.programCode}>
            {program.code}
          </span>
          {`- ${program.full_title_with_edition}`}
        </label>
      </div>
    );
  }

  renderProgramCheckboxes() {
    return this.state.programsForStaffers.map(program => this.renderProgram(program));
  }

  renderForm({ handleSubmit, submitting }) {
    return (
      <form className={styles.programsForm} onSubmit={handleSubmit}>
        {this.renderProgramCheckboxes()}

        <Footer
          secondaryButtonCallback={this.props.closeModal}
          primaryButtonText="Remove"
          submitting={submitting}
          wrapperClassName={styles.footer}
          primaryButtonClassName="btn btn--red"
          primaryButtonDisabled={this.state.selectedPrograms.size === 0}
        />
      </form>
    );
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}
