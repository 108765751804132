import React from 'react';
import useFlags from '@/hooks/useFlags';
import cn from '@/utils/cn';

export interface TrendsButtonsProps {
  assignmentType?: 'lesson game' | 'assessment';
  link: string;
}

const TrendsButton = ({ assignmentType = 'assessment', link }: TrendsButtonsProps) => {
  const { updatedGradesTable } = useFlags();

  const additionalMarkup = () => {
    if (!updatedGradesTable) return null;

    return (
      <>
        <i className="fa fa-bar-chart tw-text-inherit" />
        View
        {' '}
      </>
    );
  };

  return (
    <a
      aria-label={`View ${assignmentType} score trends`}
      className={cn({
        'dropdown-item tw-px-6 !tw-text-neutral-600': updatedGradesTable,
        'btn btn--sm btn--outline-purple': !updatedGradesTable,
        'focus:tw-bg-inherit': updatedGradesTable,
      })}
      href={link}
    >
      {additionalMarkup()}
      Trends
    </a>
  );
};

export default TrendsButton;
