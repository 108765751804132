import React, { useEffect, Component } from 'react';
import Axios from 'axios';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import { Footer } from '../../common/Modal';
import { buildFilterPath } from '../../common/AdvancedFilters/Utils';
import PaginatedTable, { usePaginatedTable } from '../../common/DataTable/PaginatedTable';
import { SelectField } from '../../common/Forms';
import { required } from '../../common/Forms/Validators';
import AdvancedFilters from '../../common/AdvancedFilters';
import { OPERATORS } from '../../common/AdvancedFilters/FilterRow';
import { formatProgramsForSelect, formatLicensesForSelect } from '../../../modules/Utils';

import styles from './ProgramMappingsTable.module.scss';

const filterProperties = (subscriberId) => {
  const staffersSearchPath = Routes.plato_api_search_subscriber_staffers_path(
    subscriberId,
    { includes: ['shared_classrooms', 'classrooms', 'teacher_seats'] }
  );

  const classroomSearchPath = Routes.plato_api_search_subscriber_classrooms_path(
    subscriberId,
    { active: true }
  );

  return [
    {
      label: 'Course Number',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'course_number'
    },
    {
      label: 'Class Name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'name'
    },
    {
      label: 'Class Subject',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'subjects_arr'
    },
    {
      label: 'Class Grade',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'grades_arr'
    },
    {
      asyncConfig: { path: classroomSearchPath, value: 'school_name' },
      label: 'School Name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'school_name'
    },
    {
      asyncConfig: { path: staffersSearchPath, value: 'email' },
      label: 'Teacher Email',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'staffers_email'
    },
    {
      asyncConfig: { path: staffersSearchPath, value: 'first_name' },
      label: 'Teacher First Name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'staffers_first_name'
    },
    {
      asyncConfig: { path: staffersSearchPath, value: 'last_name' },
      label: 'Teacher Last Name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'staffers_last_name'
    },
    {
      asyncConfig: { path: staffersSearchPath, value: 'uid' },
      label: 'Teacher UID',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      value: 'staffers_uid'
    }
  ];
};

const formatMappingRules = (mappingRules) => {
  const keys = Object.keys(mappingRules);

  return keys.map((key) => {
    // Sort by length descending so that we check not_cont before cont
    const operators = Object.keys(OPERATORS).sort((a, b) => b.length - a.length);

    const operator = operators.find(element => (key.endsWith(element)));

    const kind = key.split(`_${operator}`)[0];
    const value = mappingRules[key];

    return { kind: kind, operator: operator, rule: value };
  });
};

class ProgramMappingForm extends Component {
  constructor(props) {
    super(props);

    this.filtersRef = React.createRef();

    this.state = {
      hasFilters: !!this.props.rowData,
      isSubmitting: false,
      licenses: [],
      programOptions: {},
    };

    this.setHasFilters = this.setHasFilters.bind(this);
  }

  componentDidMount() {
    const licensePath = Routes.plato_api_subscriber_licenses_path(this.props.subscriberId);
    Axios
      .get(licensePath)
      .then((response) => {
        const filteredLicenses = response.data.data
          .filter(license => license.code !== 'NO_PROGRAM')
          .sort((a, b) => a.position - b.position);
        this.setState({ licenses: formatLicensesForSelect(filteredLicenses) });
      });

    const path = Routes.plato_api_programs_by_license_subscriber_path(this.props.subscriberId);
    Axios
      .get(path)
      .then((response) => {
        const licenseIds = Object.keys(response.data);
        const parsedResponse = {};

        licenseIds.forEach((id) => {
          parsedResponse[id] = formatProgramsForSelect(response.data[id]);
        });

        this.setState({ programOptions: parsedResponse });
      });
  }

  updateFilter = (values) => {
    this.setState({ isSubmitting: true });

    let path;
    let method;
    if (this.props.rowData) {
      path = Routes.plato_api_subscriber_program_mapping_path(this.props.rowData.id,
        { subscriber_id: this.props.subscriberId });
      method = 'put';
    }
    else {
      path = Routes.plato_api_subscriber_program_mappings_path({ subscriber_id: this.props.subscriberId });
      method = 'post';
    }

    const data = values;

    data.program_mapping.mapping_rules_attributes = formatMappingRules(this.filtersRef.current.state.filters);

    Axios({
      data,
      method: method,
      url: path
    }).then((response) => {
      this.props.callback(response.data.data);
      this.setState({ isSubmitting: false });
      this.props.closeModal();
    });
  };

  setHasFilters(hasFilters) {
    this.setState({ hasFilters });
  }

  filteredLicenses = () => (
    this.state.licenses.filter(license => Object.keys(this.state.programOptions).includes(license.value.toString()))
  )

  initialValues = () => {
    const initialAttributes = { program_mapping: { subscriber_id: this.props.subscriberId } };

    if (this.props.rowData) {
      initialAttributes.program_mapping = {
        ...initialAttributes.program_mapping,
        id: this.props.rowData.id,
        license_id: this.props.rowData.license_id,
        program_id: this.props.rowData.program_id,
      };
    }

    return initialAttributes;
  };

  render = () => (
    <div>
      <Form
        onSubmit={this.updateFilter}
        initialValues={this.initialValues()}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => {
          const programId = values.program_mapping.program_id;
          const licenseId = values.program_mapping.license_id;
          const noStudentSubs = programId &&
            !this.props.programsWithStudentSeats.includes(values.program_mapping.program_id);
          const programAccessType = programId && this.props.programAccessTypeHash[programId];
          const warning = noStudentSubs && (
            <React.Fragment>
              <i className="fa fa-info-circle mr5" aria-hidden="true" />
              No student licenses have been purchased for this program.
            </React.Fragment>
          );

          return (
            <form onSubmit={handleSubmit} id={`mapping-form-${this.props.rowData ? this.props.rowData.id : 'new'}`}>
              <div className="tw-flex tw-items-baseline tw-gap-2">
                <label className="tw-whitespace-nowrap" htmlFor="program_mapping[program_id]">
                  These classes should be assigned:<span className="tw-text-[#d2232a]">*</span>
                </label>
                <div className="tw-flex tw-flex-row tw-w-full tw-gap-5">
                  <SelectField
                    name="program_mapping[license_id]"
                    hideLabel
                    required
                    validate={required}
                    options={this.filteredLicenses()}
                    warning={warning}
                    rowClass={styles.row}
                  />
                  <SelectField
                    name="program_mapping[program_id]"
                    hideLabel
                    required
                    validate={required}
                    options={licenseId && this.state.programOptions[licenseId]}
                    warning={warning}
                    rowClass={styles.row}
                  />
                </div>
              </div>

              <ClassroomTable
                hasRosteredClassrooms={this.props.hasRosteredClassrooms}
                subscriberId={this.props.subscriberId}
                initialFilters={this.props.rowData && this.props.rowData.rules}
                filtersRef={this.filtersRef}
                setHasFilters={this.setHasFilters}
              />

              <Footer
                wrapperClassName={styles.footer}
                primaryButtonText="Save Rule"
                submitting={this.state.isSubmitting}
                primaryButtonDisabled={!values.program_mapping.license_id ||
                  !values.program_mapping.program_id ||
                  !this.state.hasFilters
                }
              />
            </form>
          );
        }}
      />
    </div>
  )
}

ProgramMappingForm.propTypes = {
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  hasRosteredClassrooms: PropTypes.bool.isRequired,
  programAccessTypeHash: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  programsWithStudentSeats: PropTypes.arrayOf(PropTypes.number).isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number,
    license_id: PropTypes.number,
    program_id: PropTypes.number,
    rules: PropTypes.arrayOf(PropTypes.shape({
      kind: PropTypes.string,
      operator: PropTypes.string,
      rule: PropTypes.string
    }))
  }),
  subscriberId: PropTypes.number.isRequired
};

ProgramMappingForm.defaultProps = {
  rowData: null
};

const ClassroomTable = ({
  hasRosteredClassrooms, subscriberId, initialFilters, filtersRef, setHasFilters
}) => {
  const pageSize = 500; // loading less takes more time

  let classroomsPath = Routes.plato_api_subscriber_classrooms_path(
    subscriberId,
    {
      active: true,
      column: 'columnName',
      includes: 'student_seats',
      order: 'sortDirection',
      page: 'pageNumber',
      per_page: 'pageSize',
      with_uid: true,
    }
  ).replace('pageSize', pageSize.toString());

  const {
    resources,
    resetResources,
    addResources,
    pagesLoaded,
    doneLoading,
    updateActiveFilters,
    activeFilters
  } = usePaginatedTable(10);

  classroomsPath = buildFilterPath(classroomsPath, activeFilters);
  const filterLength = Object.keys(activeFilters).length;
  const showCTAMessage = !filterLength;

  useEffect(() => { setHasFilters(!!filterLength); }, [activeFilters]);

  const renderPreview = () => {
    if (!hasRosteredClassrooms) {
      return (
        <p className={styles.message}>The program assignment preview is not available until the first full roster sync has completed. You can still set up program assignment rules to use in the next sync.</p>
      );
    }

    if (showCTAMessage) {
      return <p className={styles.message}>Please add a filter to preview which classes it will apply to.</p>;
    }

    const columns = [
      { name: 'name' },
      { name: 'period' },
      {
        name: 'student_seats_count',
        title: 'Students'
      },
      { name: 'teachers' },
      { name: 'course_number' }
    ];

    return (
      <PaginatedTable
        columnMapping={columns.map(c => (c.name))}
        resources={resources}
        pageLength={10}
        columns={columns.map(c => c.name)}
        getUrl={classroomsPath}
        noRecordsMessage="0 classes match your filter criteria"
        defaultOrder={[[0, 'asc']]}
        columnDefs={columns}
        handleResourcesManually
        addResources={addResources}
        pagesLoaded={pagesLoaded}
        doneLoading={doneLoading}
        resetResources={resetResources}
      />
    );
  };

  const initialRules = (rules) => {
    if (rules === null) {
      return [{ operatorValue: 'cont', propertyValue: 'course_number' }];
    }

    return rules.map(rule => ({
      operatorValue: rule.operator,
      propertyValue: rule.kind,
      value: rule.rule
    }));
  };

  return (
    <React.Fragment>
      <div className="mt10">
        <AdvancedFilters
          properties={filterProperties(subscriberId)}
          clearable={false}
          ref={filtersRef}
          asyncSearchPath={Routes.plato_api_search_subscriber_classrooms_path(subscriberId, { active: true })}
          initialFilters={initialRules(initialFilters)}
          updateActiveFilters={updateActiveFilters}
          buttonClass="btn--sm"
          resources={resources}
          filterLabel="Criteria"
        />

      </div>

      <hr />

      {renderPreview()}
    </React.Fragment>
  );
};

ClassroomTable.propTypes = {
  filtersRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  hasRosteredClassrooms: PropTypes.bool.isRequired,
  initialFilters: PropTypes.arrayOf(PropTypes.shape({
    kind: PropTypes.string,
    operator: PropTypes.string,
    rule: PropTypes.string
  })),
  setHasFilters: PropTypes.func,
  subscriberId: PropTypes.number.isRequired
};

ClassroomTable.defaultProps = {
  initialFilters: null,
  setHasFilters: () => {}
};

export default ProgramMappingForm;
