import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FroalaEditor from 'common/FroalaEditor';
import { useModalManager } from 'common/Modal';
import { toCamelCase } from 'modules/TCIUtils';
import { useScript } from 'common/Utils';
import styles from '../../Slide.module.scss';
import './froala.scss';
import { slideObjectPropTypes } from '../../../Utils';
import ElementInsertModal from './CustomFroalaCommands/ElementInsert/ElementInsertModal';
import { registerFroalaCommands } from './CustomFroalaCommands/FroalaListeners';
import { useDebouncedEffect } from '../../../../../common/Utils';
import CustomImageModal from './CustomFroalaCommands/CustomImage/CustomImageModal';
import CustomVideoModal from './CustomFroalaCommands/CustomVideo/CustomVideoModal';
import CustomAudioModal from './CustomFroalaCommands/CustomAudio/CustomAudioModal';
import TextEditorConfig from './EditorConfig';
import AnchoredDropdown from './AnchoredDropdown';
import useRetroactiveAdder from './useRetroactiveAdder';
import useSlideShowStore from '../../../stores/useSlideShowStore';
import EquationInsert from './CustomFroalaCommands/EquationInsert';
import CustomEditImageModal from './CustomFroalaCommands/CustomImage/CustomEditImageModal';
import useSlideShowContext from '../../../stores/useSlideShowContext';
import { useHtmlVariableListener } from './useHtmlVariableListener';

registerFroalaCommands();

const SlideObjectTextEditor = ({
  slideObject, updateSlideObject, hasError, setHasError, setIsSaving, isSaving,
}) => {
  const elementInsertModalManager = useModalManager();
  const addImageModalManager = useModalManager();
  const editImageModalManager = useModalManager();
  const addVideoModalManager = useModalManager();
  const audioModalManager = useModalManager();
  const equationModalManager = useModalManager();
  const [editorInstance, setEditorInstance] = useState(null);
  const [elementParent, setElementParent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [slideSoundClipId, setSlideSoundClipId] = useState('');
  const [$audioButtonToEdit, setAudioButtonToEdit] = useState(null);
  const [content, setContent] = useState(slideObject.html || '');
  const [pastedImage, setPastedImage] = useState(null);
  const [htmlElementId, setHtmlElementId] = useState();
  const [imageModel, setImageModel] = useState(null);

  const setHasUnsavedChanges = useSlideShowStore(state => state.setHasUnsavedChanges);
  const locale = useSlideShowContext(state => state.locale);
  const userType = useSlideShowContext(state => state.userType);

  useScript('https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js', {}, () => {
    window.MathJax = {
      loader: {
        load: ['input/asciimath', 'output/svg'],
      },
    };
  });

  useRetroactiveAdder(editorInstance);

  const froalaConfig = TextEditorConfig({
    addImageModalManager,
    addVideoModalManager,
    audioModalManager,
    elementInsertModalManager,
    equationModalManager,
    setAudioButtonToEdit,
    setEditMode,
    setEditorInstance,
    setElementParent,
    setHasUnsavedChanges,
    setHtmlElementId,
    setPastedImage,
    setSlideSoundClipId,
    slideObject,
    userType,
    editImageModalManager,
    setImageModel,
  });

  useDebouncedEffect(() => {
    updateSlideObject({ html: content, locale });
  }, [content], 800);

  // replace editor content with locale specific content when changing locales
  useEffect(() => {
    if (!editorInstance || !slideObject || !locale) return;

    const slideObjectHtml = locale === 'en' ? slideObject.htmlEn : slideObject.htmlEs;
    editorInstance.html.set(slideObjectHtml);
    editorInstance.undo.saveStep();
  }, [editorInstance, locale]);

  useHtmlVariableListener(editorInstance);

  const anchoredDropdown = () => {
    if (!elementParent) return null;

    if (elementInsertModalManager.isOpen) return null;

    let button = slideObject.htmlElements.find(htmlElement => parseInt(htmlElement.id, 10) === parseInt(htmlElementId, 10));
    if (!button) return null;

    button = toCamelCase(button);

    if ([
      'ModelNumber',
      'ModelTitle',
      'ModelPhenomenon',
      'ModelStoryline',
      'ModelEssentialQuestion',
    ].includes(button.type)) return null;

    return (
      <AnchoredDropdown
        setEditMode={setEditMode}
        editorInstance={editorInstance}
        element={elementParent}
        isSaving={isSaving}
        openerClass="btn"
        openModal={elementInsertModalManager.open}
        button={button}
      />
    );
  };

  const customVideoModal = (localEditorInstance, modalManager, fromButton) => (
    <CustomVideoModal
      model={slideObject}
      editorInstance={localEditorInstance}
      modelType="SlideObject"
      userType={userType}
      fromButton={fromButton}
      modalManager={modalManager}
    />
  );

  const customImageModal = (localEditorInstance, modalManager, fromButton) => (
    <CustomImageModal
      clearDefaultImage={() => setPastedImage(null)}
      editorInstance={localEditorInstance}
      fromButton={fromButton}
      modalManager={modalManager}
      pastedImage={pastedImage}
      slideObject={slideObject}
    />
  );

  const editImageModal = (localEditorInstance, modalManager) => (
    <CustomEditImageModal
      modalManager={modalManager}
      imageModelToEdit={imageModel}
      slideObject={slideObject}
      editorInstance={localEditorInstance}
    />
  );

  const customAudioModal = (localEditorInstance, modalManager, localAudioButtonToEdit, setLocalAudioButtonToEdit, setLocalSlideSoundClipId, slideLocalSoundClipId) => (
    <CustomAudioModal
      $audioButtonToEdit={localAudioButtonToEdit}
      editorInstance={localEditorInstance}
      modalManager={modalManager}
      setAudioButtonToEdit={setLocalAudioButtonToEdit}
      setSlideSoundClipId={setLocalSlideSoundClipId}
      slideObject={slideObject}
      slideSoundClipId={slideLocalSoundClipId}
      userType={userType}
    />
  );

  const equationModal = (localEditorInstance, modalManager, fromButton) => {
    return (
      <EquationInsert
        editorInstance={localEditorInstance}
        modalManager={modalManager}
        fromButton={fromButton}
      />
    );
  };

  const elementInsertModal = () => (
    <ElementInsertModal
      editMode={editMode}
      editorInstance={editorInstance}
      elementParent={elementParent}
      htmlElementId={htmlElementId}
      modalManager={elementInsertModalManager}
      setEditMode={setEditMode}
      slideObject={slideObject}
      hasError={hasError}
      setHasError={setHasError}
      setHtmlElementId={setHtmlElementId}
      setIsSaving={setIsSaving}
      customImageModal={customImageModal}
      customAudioModal={customAudioModal}
      customVideoModal={customVideoModal}
      equationModal={equationModal}
    />
  );

  return (
    <Fragment>
      <section
        aria-label={`Text Object ${slideObject.id}`}
        className={styles.textEditContainer}
        id={slideObject.id}
      >
        <FroalaEditor
          customConfig={froalaConfig}
          text={content}
          onModelChange={newContent => setContent(newContent)}
          model={content}
          tag="div"
        />
      </section>

      {anchoredDropdown()}
      {elementInsertModalManager.isOpen && elementInsertModal()}
      {customVideoModal(editorInstance, addVideoModalManager, false)}
      {equationModal(editorInstance, equationModalManager, false)}
      {customImageModal(editorInstance, addImageModalManager, false)}
      {editImageModal(editorInstance, editImageModalManager, false)}
      {audioModalManager.isOpen && (
        customAudioModal(
          editorInstance,
          audioModalManager,
          $audioButtonToEdit,
          setAudioButtonToEdit,
          setSlideSoundClipId,
          slideSoundClipId
        )
      )}

    </Fragment>
  );
};

SlideObjectTextEditor.propTypes = {
  hasError: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  setHasError: PropTypes.func.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  updateSlideObject: PropTypes.func.isRequired,
};

export default SlideObjectTextEditor;
