import { useEffect, useLayoutEffect } from 'react';
import { Slide, SlideObject } from 'components/admin/SlideShow/types';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import useDragAndDropStore from '@/components/common/Forms/Questions/DragAndDrop/useDragAndDropStore';
import { capitalize } from '@/components/common/Utils';

export const useBackgroundImage = (slideRef, slide, imageModel) => {
  const locale = useSlideShowContext(state => state.locale);

  useEffect(() => {
    if (!slide) return;
    if (!slideRef.current) return;

    const isCustom = slide.backgroundPosition && slide.backgroundPosition.match(/center \d+/g);

    let backgroundPosition;
    if (isCustom) {
      backgroundPosition = `${slide.backgroundPosition}%`;
    }
    else if (slide.backgroundPosition) {
      backgroundPosition = slide.backgroundPosition;
    }
    else {
      backgroundPosition = 'center';
    }

    if (!imageModel) {
      $(slideRef.current).css({ backgroundImage: 'none' });
    }
    else {
      const { image: { imageUrl, imageEsUrl } } = imageModel;

      const src = locale === 'es' && imageEsUrl && !imageEsUrl.includes('missing.png') ? imageEsUrl : imageUrl;
      const backgroundImage = `url(${src})`;
      $(slideRef.current).css({ backgroundImage, backgroundPosition });
    }
  }, [slideRef.current, imageModel, slide && slide.backgroundPosition, locale]);
};

export const useResizer = (forDisplay, slideRef, isTemplate) => {
  const SCALE = {
    presentMode: 1.0,
    thumbnail: 0.75,
  };

  const setResizerScaleValue = useDragAndDropStore(state => state.setResizerScaleValue);

  const doResize = (scaler, translate) => {
    if (forDisplay === 'builder') return;
    if (!slideRef) return;
    if (!slideRef.current) return;

    const el = $(slideRef.current);
    const elHeight = el.outerHeight();
    const elWidth = el.outerWidth();

    if (document.fullscreenElement?.id?.includes('pano')) {
      return;
    }

    // Container to scale the element relative to.
    const baseContainer = forDisplay === 'thumbnail' ? '#slide-panel' : window;

    const scale = Math.min(
      // @ts-ignore
      ($(baseContainer).width() * scaler) / elWidth,
      // @ts-ignore
      ($(baseContainer).height() * scaler) / elHeight,
    );

    setResizerScaleValue(scale);
    const transformVals = translate ? `${'translate(-50%, -50%) scale('}${scale})` : `scale(${scale})`;

    el.css({ transform: transformVals });

    el[0].dispatchEvent(new Event('slide-resize'));
  };

  const resizeListener = () => {
    if (forDisplay === 'present') doResize(SCALE.presentMode, true);
  };

  useLayoutEffect(() => {
    if (isTemplate || forDisplay === 'builder') return;

    const scaler = forDisplay === 'present' ? SCALE.presentMode : SCALE.thumbnail;

    doResize(scaler, true);
    window.addEventListener('resize', resizeListener);
    window.addEventListener('pano-fullscreen', resizeListener);

    // eslint-disable-next-line consistent-return
    return () => { window.removeEventListener('resize', resizeListener); };
  }, [slideRef, slideRef?.current]);
};

export const getHTML = (locale, slideObject) => {
  if (!slideObject.html) return '';

  return slideObject[`html${capitalize(locale)}`];
};

export const getNotes = (locale, slide) => {
  if (!slide.notesEn) return '';

  return (locale === 'en' ? slide.notesEn : slide.notesEs);
};

export const convertToDecimal = (hexToConvert) => {
  // Hexadecimal for opacity is base 16, e.g. 255 = 100%.
  // We divide by this constant to get the correct decimal later.
  const hexColorConstant = 255;

  // Convert hex to decimal.
  const initialOpacity = parseInt(hexToConvert, 16);

  // Divide by the constant and multiply by 100 to get percent.
  return Math.round((initialOpacity / hexColorConstant) * 100);
};

export const convertToHex = (stringToConvert) => {
  if (stringToConvert === '') return stringToConvert;

  // Hexadecimal for opacity is base 16, e.g. 255 = 100%.
  // We multiply by this constant to get the correct hexcode later.
  const hexColorConstant = 255;

  // Convert string to decimal percentage.
  const opacityDecimal = parseInt(stringToConvert, 10) / 100;

  // Convert decimal to hexcode for opacity.
  // Round to whole number for proper hexcode conversion.
  const hexCode = Math.round(hexColorConstant * opacityDecimal).toString(16);

  // Hexcode for opacity MUST be 2 digits. Pad hexcode with 0 at the start if it's less than 2 digits.
  return hexCode.padStart(2, '0');
};

export const getSlideLabel = (slide, currentSlide) => (
  `Slide ${slide.position} ${slide.id === currentSlide.id ? '(current)' : ''}`
);

export const slideObjectHasQuestions = (slideObject: SlideObject): boolean => {
  if (slideObject.modelType === 'Question') return true;
  if (!slideObject.html) return false;
  if (slideObject.htmlElements.some(element => element.type === 'QuestionButton')) {
    return true;
  }

  return slideObject.html.includes('question-btn');
};

export const slideHasQuestions = (slide: Slide): boolean => slide.slideObjects.some(slideObjectHasQuestions);

// Template objects that should be full size.
export const fullSizeSlideTemplateObjectIds = [13];
