const MutationKeys = {
  CreateAll: 'CreateAll',
  Create: 'Create',
  Delete: 'Delete',
  DeleteAll: 'DeleteAll',
  Update: 'Update',
  UpdateAll: 'UpdateAll',
} as const;

export default MutationKeys;
