import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintModal from '../../../shared/PrintModal';

export default class PrintOption extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  renderLink() {
    const iconClass = this.props.name === 'Print Assessment' ? 'fa fa-print' : 'fa fa-check-square-o';

    return (
      <a
        href="#"
        className="dropdown-item js-prevent-default"
        onClick={this.openModal}
        role="button"
      >
        <i className={iconClass} aria-hidden="true" />
        {this.props.name}
      </a>
    );
  }

  render() {
    return (
      <div>
        {this.renderLink()}
        <PrintModal
          closeModal={this.closeModal}
          headerText={this.props.name}
          modalIsOpen={this.state.modalIsOpen}
          url={this.props.url}
        />
      </div>
    );
  }
}
