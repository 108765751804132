import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import Modal from '../../../common/Modal';
import NoteForm from './NoteForm';

export default class AddNote extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      note: PropTypes.string
    }).isRequired,
    updatePath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.handleAddNote = this.handleAddNote.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
  }

  updateNote(values) {
    return Axios
      .put(this.props.updatePath.replace(':id', this.props.rowData.id), values)
      .then((response) => {
        if (response.status === 200) {
          this.props.updateTable(response.data.data, 'update');
        }
        this.props.closeModal();
      })
      .catch((error) => {
        this.props.updateTable(error.toString(), 'error');
        this.props.closeModal();
      });
  }

  handleAddNote(values) {
    this.updateNote(values);
  }

  handleDeleteNote() {
    const msg = 'Are you sure you want to delete the subscriber note?';

    if (window.confirm(msg)) {
      this.updateNote({ subscriber: { note: '' } });
    }
  }

  _headerText() {
    if (this.props.rowData.note) return 'Edit Note';

    return 'Add Note';
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        headerText={this._headerText()}
        isOpen={this.props.modalIsOpen}
      >
        <Form
          onSubmit={this.handleAddNote}
          initialValues={{
            subscriber: {
              note: this.props.rowData.note
            }
          }}
        >
          {({ handleSubmit, pristine, values }) => (
            <NoteForm
              handleSubmit={handleSubmit}
              closeModal={this.props.closeModal}
              pristine={pristine}
              deleteNote={this.handleDeleteNote}
              values={values}
              deletable={this.props.rowData.note !== ''}
            />
          )}
        </Form>
      </Modal>
    );
  }
}
