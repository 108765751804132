import moment from 'moment';

export function formatProgramsWithCode(programs, filterYear) {
  const formattedPrograms = programs.map(program => ({
    label: `[${program.code}] ${program.full_title_with_edition}`,
    value: program.id
  }));

  if (filterYear) return formattedPrograms.filter(program => !program.label.match(`© ${filterYear}`));

  return formattedPrograms;
}

export const maxSeatsValidation = (maximum, action) => (value) => {
  if (value > maximum) return `Not enough seats to ${action}`;

  return undefined;
};

export function mapSubscription(resource) {
  return (
    {
      adminEmail: resource.purchase_order_admin_email,
      id: resource.id,
      transactionType: resource.transaction_type,
      transferNotes: resource.transfer_notes
    }
  );
}

export function isExpired(expirationDate) {
  const currentDate = moment().format('YYYY-MM-DD');

  return currentDate > expirationDate;
}

export function formatDate(date) {
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
}

export function formatYear(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY');
}
