import React from 'react';
import TrendsButton, { TrendsButtonsProps }
  from '@/components/staffer/Scores/GradesTable/Table/Cells/HeaderCell/TrendsButton';
import ClearAllButton, { ClearAllButtonsProps }
  from '@/components/staffer/Scores/GradesTable/Table/Cells/HeaderCell/ClearAllButton';
import useFlags from '@/hooks/useFlags';

interface TrendsAndClearButtonsProps {
  ClearAllButtonProps: ClearAllButtonsProps;
  TrendsButtonProps: TrendsButtonsProps;
}

const TrendsAndClearButtons = ({
  ClearAllButtonProps, TrendsButtonProps,
}: TrendsAndClearButtonsProps) => {
  const { updatedGradesTable } = useFlags();

  if (updatedGradesTable) {
    return (
      <>
        <TrendsButton {...TrendsButtonProps} />
        <ClearAllButton {...ClearAllButtonProps} />
      </>
    );
  }

  return (
    <div className="tw-flex tw-font-light tw-gap-0.5 tw-justify-center tw-min-w-full">
      <TrendsButton {...TrendsButtonProps} />
      <ClearAllButton {...ClearAllButtonProps} />
    </div>
  );
};

export default TrendsAndClearButtons;
