import { z } from 'zod';

export const shouldUseTextures = (ext?: string) => (
  ['obj', 'fbx'].includes((ext || '').toLowerCase())
);

export const getExtension = (fileName?: string) => (
  (fileName || '').split('.').pop().toLowerCase()
);

export const fileExtension = (fileList: FileList) => {
  if (!fileList) return '';
  if (!fileList[0]) return '';

  return getExtension(fileList[0].name);
};

export const schema = z.object({
  model: z.instanceof(FileList),
  modelFileName: z.string().optional(),
  textures: z.array(z.object({
    id: z.string().optional(),
    file: z.instanceof(FileList),
    imageModelId: z.string().optional(),
    texture_type: z.string().optional(),
    destroy: z.boolean().optional(),
  })).optional(),
})
  .refine((values) => {
    if (!values.modelFileName && !values.model[0]) return false;

    return true;
  })
  .superRefine((values, ctx) => {
    const modelExt = fileExtension(values.model) || getExtension(values.modelFileName);

    const hasValidTextures = values
      .textures
      .every((t) => {
        if (t.destroy) return true;

        return t.texture_type && ((t.file && t.file[0]) || t.id);
      });

    const numUsedTextures = values.textures.filter(t => !t.destroy).length;

    const isObj = ['obj', 'fbx'].includes(modelExt);

    if (isObj && numUsedTextures === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        inclusive: true,
        message: 'You need textures for .obj and .fbx files',
        minimum: 1,
        path: ['textures'],
        type: 'array',
      });

      return false;
    }

    if (['obj', 'fbx'].includes(modelExt) && !hasValidTextures) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Textures should have a file and texture type present',
        path: ['textures'],
      });

      return false;
    }

    return true;
  });

export type SchemaType = z.infer<typeof schema>;

export type TextureCurrentValue = {
  id: string;
  imageModelId: string;
  imageUrl: string;
  textureType: string;
};

export type CurrentValues = {
  id: string;
  fileName?: string;
  textures: TextureCurrentValue[];
};
