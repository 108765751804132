import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from './ToggleMenu.module.scss';

const toggleOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string,
  className: PropTypes.string
});

export { toggleOptionShape };

export class ToggleMenu extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    width: PropTypes.string.isRequired,
    optionsData: PropTypes.arrayOf(toggleOptionShape).isRequired,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool
  };

  static defaultProps = {
    searchable: true,
    clearable: true
  };

  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
    this._handleValueChange = this._handleValueChange.bind(this);

    this.optionsData = this.props.optionsData.map((data) => {
      if (data.disabled) data.className = styles.header;
      return data;
    });
  }

  _handleValueChange(selectedOption) {
    const newValue = selectedOption && selectedOption.value;
    this.setState({ value: newValue });
    if (newValue === null || newValue === this.state.value) return;

    const optionData = this.props.optionsData.find(d => d.value === selectedOption.value);
    window.location.href = optionData.url;
  }

  render() {
    return (
      <div className={styles.option} style={{ width: this.props.width }}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <Select
          id={this.props.id}
          value={this.state.value}
          onChange={this._handleValueChange}
          options={this.optionsData}
          searchable={this.props.searchable}
          clearable={this.props.clearable}
        />
      </div>
    );
  }
}
