import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import Popover from '@/components/ui/Popover';
import useFlags from '@/hooks/useFlags';
import { PostScoresButton } from '@/components/staffer/Scores/GradesTable/PostScoresButton';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import { QueryKeys } from '../../GradesTableQueryClientProvider';
import useGradesTableStore from '../../store/useGradesTableStore';
import useAssessments from '../../hooks/queries/useAssessments';
import useAssessmentExerciseResults from '../../hooks/queries/exerciseResults/useAssessmentExerciseResults';
import { HeaderCell, AssessmentGradeCell } from '../Cells';

const useAssessmentColumns = () => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const { updatedGradesTable } = useFlags();

  const { data: assessments } = useAssessments(classroom.id, lesson?.id);
  const { data: exerciseResults } = useAssessmentExerciseResults(classroom, lesson);

  return useMemo(() => (
    assessments.filter(a => a.total_points_possible > 0).map((assessment) => {
      const accessor = (row: IStudentRow) => row.student;

      const clearLink = Routes.reset_classroom_scores_shared_program_lesson_assessment_scores_path(
        programId,
        lesson?.id,
        { assessment_id: assessment.id, classroom_id: classroom?.id },
      );

      const trendsLink = Routes.teacher_assessment_trend_path(assessment.id, {
        classroom_id: classroom?.id,
        lesson_id: lesson?.id,
        program_id: programId,
      });

      const hasResultsForAssessment = exerciseResults.some(er => er.exercise_id === assessment.id);

      return columnHelper.accessor(accessor, {
        id: `assessment-${assessment.id}`,
        header: () => (
          <HeaderCell title={`Assessment: ${assessment.name}`} maxScore={assessment.total_points_possible}>
            {updatedGradesTable ? (
              <Popover>
                <Popover.Trigger
                  className={`tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-absolute 
                            tw-right-1 tw-top-1 tw-text-lg hover:tw-bg-opacity-10 hover:tw-bg-tci-purple`}
                >
                  <i className="fa fa-ellipsis-v" />
                </Popover.Trigger>
                <Popover.Content
                  className="tw-w-auto tw-z-[1040]"
                  onOpenAutoFocus={(e) => {
                    e.preventDefault();
                    // The default focusable element is the bottom element which can be disabled
                    // and opens its tooltip which is kind of annoying behavior. Focus the always enabled el instead
                    const div = e.target as HTMLDivElement;
                    (div.children[0] as HTMLAnchorElement).focus();
                  }}
                  side="top"
                >
                  <HeaderCell.TrendsAndClearButtons
                    ClearAllButtonProps={{
                      assignmentType: 'assessment',
                      isDisabled: !hasResultsForAssessment,
                      link: clearLink,
                      queryKey: [QueryKeys.AssessmentExerciseResults, classroom.id, lesson.id],
                    }}
                    TrendsButtonProps={{
                      assignmentType: 'assessment',
                      link: trendsLink,
                    }}
                  />
                  <PostScoresButton
                    assessmentId={assessment.id}
                    classroomId={classroom.id}
                    displayIcon
                    lessonId={lesson.id}
                    programId={programId}
                  />
                </Popover.Content>
              </Popover>
            ) : (
              <HeaderCell.TrendsAndClearButtons
                ClearAllButtonProps={{
                  assignmentType: 'assessment',
                  isDisabled: !hasResultsForAssessment,
                  link: clearLink,
                  queryKey: [QueryKeys.AssessmentExerciseResults, classroom.id, lesson.id],
                }}
                TrendsButtonProps={{
                  assignmentType: 'assessment',
                  link: trendsLink,
                }}
              />
            )}

            <HeaderCell.SyncGradesButton
              exerciseType="assessment"
              exerciseResults={exerciseResults}
              link={Routes.sync_classroom_scores_shared_program_lesson_assessment_scores_path(
                programId, lesson.id,
                { classroom_id: classroom.id,
                  lesson_id: lesson.id,
                  program_id: programId },
              )}
            />
          </HeaderCell>
        ),
        cell: info => <AssessmentGradeCell student={info.getValue()} assessment={assessment} />,
      });
    })
  ), [assessments, classroom?.id, programId, lesson?.id, exerciseResults]);
};

export default useAssessmentColumns;
