import React, { useEffect, useState } from 'react';
import Tooltip from '@/components/ui/Tooltip';
import I18n from 'i18n-js';
import cn from '@/utils/cn';
import AsyncAssignmentModal from 'components/staffer/AssignmentModal/AsyncAssignmentModal';
import { useModalManager } from 'components/common/Modal';
import { diveDeeperFormat } from '@/components/shared/NewLessonOverview/DiveDeeper/DiveDeeper.utils';
import ReadingCard from './ReadingCard';
import ReferenceMaterialCard from './ReferenceMaterialCard';

type DiveDeeperProps = {
  sections?: any;
  lesson?: any;
  programId?: number;
  stafferId: number;
};

const DiveDeeper = ({ sections, lesson, programId, stafferId } : DiveDeeperProps) => {
  if (!lesson || !sections) return null;

  const modalManager = useModalManager();
  const { readings = [], referenceMaterials = [] } = diveDeeperFormat(lesson, sections);

  if (!readings && !referenceMaterials) return null;
  return (
    <div className={cn('tw-h-full tw-min-w-[30%] tw-w-[45%] tw-bg-[#F6F5FA] tw-px-[2rem] tw-overflow-x-hidden tw-overflow-y-auto')}>
      <div className="tw-flex tw-items-center tw-mt-[1.5rem] tw-mb-[2rem]">
        <h2 className="tw-text-[1.5rem] tw-mr-[0.75rem] tw-pb-0"> {I18n.t('dive_deeper_with')} </h2>
        <Tooltip.Provider>
          <Tooltip>
            <Tooltip.Trigger className="tw-bg-transparent tw-border-0">
              <i className="tw-text-[1.5rem] fa fa-info-circle fa-lg" aria-hidden="true" />
            </Tooltip.Trigger>
            {stafferId && (
              <Tooltip.Content className="tw-z-[1345] tw-text-sm tw-max-w-[26rem] tw-shadow tw-p-2 tw-bg-white
              tw-rounded tw-text-dark-grey"
              >
                {I18n.t('lesson_subheader_dive_deeper')}
                <Tooltip.Arrow className="tw-fill-white" />
              </Tooltip.Content>
            )}
          </Tooltip>
        </Tooltip.Provider>
      </div>
      {
        readings && readings
          .map(reading => (
            <ReadingCard
              key={`reading-${reading.id}`}
              openAssign={modalManager.open}
              programId={programId}
              reading={reading}
            />
          ))
      }
      {
        referenceMaterials && referenceMaterials
          .map(referenceMaterial => (
            <ReferenceMaterialCard
              key={`referenceMaterial-${referenceMaterial.id}`}
              referenceMaterial={referenceMaterial}
            />
          ))
      }
      {sections && (
      <AsyncAssignmentModal
        closeModal={modalManager.close}
        initialValues={
                {
                  modelType: 'sectionNotebook',
                  modelsToAssign: sections.map(section => section.assignable.value),
                }}
        handlePostSave={modalManager.close}
        modalIsOpen={modalManager.isOpen}
        initialLessonId={lesson?.id}
        programId={programId}
        stafferId={stafferId}
      />
      )}
    </div>
  );
};

export default DiveDeeper;
