import { MutableRefObject, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export const ORIGINAL_DIMENSIONS = {
  width: 1366,
  height: 768,
};

interface UseModalResizerProps {
  resizeContainer: MutableRefObject<HTMLElement>;
}

const newDimensions = (availableWidth, availableHeight, aspectRatio) => {
  if (availableWidth / availableHeight < aspectRatio) {
    return {
      width: availableWidth,
      height: availableWidth / aspectRatio,
    };
  }

  return {
    height: availableHeight,
    width: availableHeight * aspectRatio,
  };
};

const useModalResizer = ({ resizeContainer }: UseModalResizerProps) => {
  const [containerWidth, setContainerWidth] = useState(ORIGINAL_DIMENSIONS.width);
  const [containerHeight, setContainerHeight] = useState(ORIGINAL_DIMENSIONS.height);

  useResizeObserver({
    ref: resizeContainer,
    box: 'content-box',
    onResize: () => {
      const fullWidth = window.innerWidth - 100;
      const fullHeight = window.innerHeight - 100;

      const availableWidth = fullWidth;
      const availableHeight = fullHeight;

      const aspectRatio = 16 / 9;

      const { height, width } = newDimensions(availableWidth, availableHeight, aspectRatio);

      setContainerWidth(width);
      setContainerHeight(height);
    },
  });

  return { containerWidth, containerHeight };
};

export default useModalResizer;
