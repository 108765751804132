import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import showToast from 'common/Toast';
import FinalFormSelect from 'common/Forms/FinalFormSelect';
import SubmitButton from 'common/Forms/SubmitButton';
import { SubmitError } from 'common/Forms/Utils';
import styles from './ImportYourData.module.scss';

export default class ImportForm extends Component {
  static propTypes = {
    importDataPath: PropTypes.string.isRequired,
    programId: PropTypes.number.isRequired,
    programOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    }))
  };

  static defaultProps = {
    programOptions: []
  };

  static validate(values) {
    const errors = {};

    if (values.import_lesson_guides === 'none' && values.import_assessments === 'none') {
      errors.data_validations = 'Choose what to import';
    }

    if (!values.old_program_id) {
      errors.program_validation = 'Select a program';
    }
    return errors;
  }

  static renderRadioButtons(type) {
    const dataType = type.split(' ').join('_').toLowerCase();
    return (
      <div className={styles.sectionContainer}>
        <p className="bold">
          Import
          {' '}
          {type}
        </p>
        <div className={styles.radioButtonsContainer}>
          <label htmlFor={`${dataType}_all`}>
            <Field
              id={`${dataType}_all`}
              name={`import_${dataType}`}
              component="input"
              type="radio"
              value="all"
            />
            <span>All</span>
          </label>
          <label
            className="ml100"
            htmlFor={`${dataType}_none`}
          >
            <Field
              id={`${dataType}_none`}
              name={`import_${dataType}`}
              component="input"
              type="radio"
              value="none"
            />
            <span>None</span>
          </label>
        </div>
      </div>
    );
  }

  static getImportSelection(value) {
    return value === 'all' ? 'all' : 'no';
  }

  constructor(props) {
    super(props);

    this.state = {
      programTitle: 'No Program Selected',
      selectedProgram: null,
      submitting: false
    };

    this.submit = this.submit.bind(this);
    this.handleProgramSelect = this.handleProgramSelect.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  handleProgramSelect(args, state, utils) {
    const value = args[0] ? args[0].value : null;
    const title = args[0] ? args[0].label : 'No Program Selected';
    this.setState({ programTitle: title, selectedProgram: args[0] });
    utils.changeValue(state, 'old_program_id', () => value);
  }

  submit(submitData) {
    this.setState({ submitting: true });
    axios
      .post(this.props.importDataPath, submitData)
      .then(() => {
        showToast('Import Successful.');
        this.setState({ submitting: false });
      })
      .catch((error) => {
        showToast('Import Error.', { msgType: 'error' });
        this.setState({ submitting: false });
        console.log(error);
      });
  }

  renderProgramSelector(mutators) {
    return (
      <div className={styles.sectionContainer}>
        <p className="bold">Program</p>
        <Field
          className={styles.programSelector}
          component={FinalFormSelect}
          name="old_program_id"
          onChange={mutators.handleProgramSelect}
          options={this.props.programOptions}
          placeholder="Select Program"
          selectValue={this.state.selectedProgram}
        />
      </div>
    );
  }

  renderSummary(formProps) {
    return (
      <div>
        You are importing
        {' '}
        <span className="bold">
          {ImportForm.getImportSelection(formProps.values.import_lesson_guides)}
          {' '}
          Lesson Guides
        </span>
        {' '}
        and
        {' '}
        <span className="bold">
          {ImportForm.getImportSelection(formProps.values.import_assessments)}
          {' '}
          Assessments
        </span>
        {' '}
        from
        {' '}
        <span className="bold">
          {this.state.programTitle}
        </span>
        .
      </div>
    );
  }

  renderForm(formProps) {
    return (
      <form onSubmit={formProps.handleSubmit}>
        <div className="mb20">
          {this.renderProgramSelector(formProps.form.mutators)}
          {ImportForm.renderRadioButtons('Lesson Guides')}
          {ImportForm.renderRadioButtons('Assessments')}
          <SubmitError error={formProps.submitError} />
        </div>
        <hr />
        {this.renderSummary(formProps)}
        <SubmitButton
          buttonClassName="pos_fr mt15 btn btn--purple"
          submitButtonText="Import"
          submittingText="Importing"
          submitDisabled={formProps.hasValidationErrors}
          submitting={this.state.submitting}
        />
      </form>
    );
  }

  render() {
    return (
      <Form
        initialValues={{
          import_assessments: 'none',
          import_lesson_guides: 'none',
          new_program_id: this.props.programId
        }}
        mutators={{
          handleProgramSelect: this.handleProgramSelect
        }}
        onSubmit={this.submit}
        render={this.renderForm}
        validate={ImportForm.validate}
      />
    );
  }
}
