import React from 'react';
import * as Routes from 'modules/routes';
import { SelectOption } from './types';

interface SignInButtonProps {
  student?: SelectOption;
}

const SignInButton = ({ student }: SignInButtonProps) => {
  const linkHref = student ? Routes.student_masquerade_path(student.value, { return_path: window.location.href }) : null;
  const linkClass = student ? 'btn btn--purple' : 'btn btn--purple disabled';

  return (
    <a
      href={linkHref}
      className={linkClass}
    >
      Sign in
    </a>
  );
};

export default SignInButton;
