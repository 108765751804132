import React from 'react';
import PropTypes from 'prop-types';

import Wizard from 'common/Wizard';
import ConfigurationStepA from '../ConfigurationStep/Saml/ConfigurationStepA';
import ConfigurationStepB from '../ConfigurationStep/Saml/ConfigurationStepB';
import ProgressHeader from '../ProgressHeader';

import { completedStepsPropTypes } from '../../../steps';
import { samlSettingPropTypes } from '../../../Proptypes';

export default function SamlSteps({
  closeModal, uploadXMLUrl, ssoModel, ssoModelPath, updateSSOModel, rootUrl, completedSteps, initialStep
}) {
  return (
    <Wizard
      progressHeader={<ProgressHeader completedSteps={completedSteps} isSaml />}
      lastPageNextCallback={closeModal}
      initialStep={initialStep}
    >
      <ConfigurationStepA
        closeModal={closeModal}
        uploadXMLUrl={uploadXMLUrl}
        ssoModel={ssoModel}
        ssoModelPath={ssoModelPath}
        updateSSOModel={updateSSOModel}
      />

      <ConfigurationStepB
        closeModal={closeModal}
        samlSetting={ssoModel.model}
        rootUrl={rootUrl}
        ssoModel={ssoModel}
        ssoModelPath={ssoModelPath}
        updateSSOModel={updateSSOModel}
      />
    </Wizard>
  );
}

SamlSteps.propTypes = {
  closeModal: PropTypes.func.isRequired,
  ssoModel: PropTypes.shape({
    id: PropTypes.number,
    model_type: PropTypes.string,
    model: samlSettingPropTypes
  }).isRequired,
  ssoModelPath: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  uploadXMLUrl: PropTypes.string.isRequired,
  updateSSOModel: PropTypes.func.isRequired,
  completedSteps: completedStepsPropTypes.isRequired,
  initialStep: PropTypes.number
};

SamlSteps.defaultProps = {
  initialStep: 0
};
