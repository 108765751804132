import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';

import Wizard from 'common/Wizard';
import RosteringStep from './Steps/RosteringStep';

import { ROSTER_METHOD_STEP } from '../steps';
import ConfigurationSteps from './ConfigurationSteps';
import styles from './Form.module.scss';

export default class RosteringSetupModal extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      contact_email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    autoRosterDistrictPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    completedSteps: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ])).isRequired,
    currentStep: PropTypes.number,
    env: PropTypes.string,
    mapCoursesPath: PropTypes.string.isRequired,
    mappedCoursesSize: PropTypes.number.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    nextSyncTime: PropTypes.string,
    subscriber: PropTypes.shape({
      uid: PropTypes.string
    }),
    subscriberId: PropTypes.number.isRequired,
    subscriberUpdatePath: PropTypes.string.isRequired,
    teacherSeatsLocked: PropTypes.bool,
    updateRosterDistrict: PropTypes.func.isRequired,
    updateSubscriber: PropTypes.func.isRequired,
    updateTeacherSeatsLocked: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    autoRosterDistrict: null,
    currentStep: ROSTER_METHOD_STEP,
    env: '',
    nextSyncTime: null,
    subscriber: null,
    teacherSeatsLocked: false
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: this.props.currentStep > 0 ? 1 : 0
    };

    this.firstPageBackCallback = this.firstPageBackCallback.bind(this);
    this.WizardRef = React.createRef();
  }

  firstPageBackCallback() {
    this.WizardRef.previous();
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        isOpen={this.props.modalIsOpen}
        headerText="Rostering Integration Setup"
        bodyClassName={styles.setUpModal}
      >
        <Wizard
          completedSteps={this.props.completedSteps}
          initialStep={this.state.currentStep}
          ref={(ref) => { this.WizardRef = ref; }}
        >
          <RosteringStep
            autoRosterDistrictPath={this.props.autoRosterDistrictPath}
            subscriberId={this.props.subscriberId}
            completedSteps={this.props.completedSteps}
            updateRosterDistrict={this.props.updateRosterDistrict}
            subscriberUpdatePath={this.props.subscriberUpdatePath}
          />

          <ConfigurationSteps
            subscriber={this.props.subscriber}
            autoRosterDistrict={this.props.autoRosterDistrict}
            autoRosterDistrictPath={this.props.autoRosterDistrictPath}
            subscriberUpdatePath={this.props.subscriberUpdatePath}
            autoRosterUpdatePath={this.props.autoRosterDistrictPath}
            updateSubscriber={this.props.updateSubscriber}
            updateRosterDistrict={this.props.updateRosterDistrict}
            completedSteps={this.props.completedSteps}
            currentStep={this.props.currentStep}
            closeModal={this.props.closeModal}
            mapCoursesPath={this.props.mapCoursesPath}
            firstPageBackCallback={this.firstPageBackCallback}
            env={this.props.env}
            mappedCoursesSize={this.props.mappedCoursesSize}
            nextSyncTime={this.props.nextSyncTime}
            teacherSeatsLocked={this.props.teacherSeatsLocked}
            updateTeacherSeatsLocked={this.props.updateTeacherSeatsLocked}
            userType={this.props.userType}
          />
        </Wizard>
      </Modal>
    );
  }
}
