import React, { useMemo } from 'react';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import { HeaderCell } from '../Cells';
import useReferenceMaterials from '../../hooks/queries/useReferenceMaterials';
import ReferenceMaterialGradeCell from '../Cells/GradeCells/ReferenceMaterialGradeCell';

const useReferenceMaterialColumns = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const { data: referenceMaterials } = useReferenceMaterials(classroom.id, lesson?.id);

  return useMemo(() => {
    if (!referenceMaterials || referenceMaterials.length === 0) return [];
    const sortedReferenceMaterials = referenceMaterials.sort((a, b) => {
      return a.assignable_label.localeCompare(b.assignable_label);
    });

    return (
      sortedReferenceMaterials.filter(r => r.max_score > 0).map((referenceMaterial) => {
        const accessor = (row: IStudentRow) => row.student;

        return columnHelper.accessor(accessor, {
          id: `referenceMaterial-${referenceMaterial.id}`,
          header: () => (
            <HeaderCell
              title={`${referenceMaterial.assignable_label}: ${referenceMaterial.title}`}
              maxScore={referenceMaterial.max_score}
            > </HeaderCell>
          ),
          cell: info => <ReferenceMaterialGradeCell
                          referenceMaterial={referenceMaterial}
                          student={info.getValue()}
                        />,
        });
      })
    );
  }, []);
};

export default useReferenceMaterialColumns;
