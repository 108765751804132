import React from 'react';
import styles from './AnchoredDropdown.module.scss';

type InitialMenuProps = {
  canEditPosition: boolean;
  handleClose: () => void;
  isSaving: boolean;
  setIsEditingPosition: (nextState: boolean) => void;
  setIsEditingColor: (nextState: boolean) => void;
  setIsEditingSize: (nextState: boolean) => void;
};

const InitialMenu = ({
  canEditPosition, handleClose, isSaving, setIsEditingColor, setIsEditingPosition, setIsEditingSize
}: InitialMenuProps) => (
  <React.Fragment>
    <button
      className={`dropdown-item btn ${styles.dropdownItem}`}
      disabled={isSaving}
      onClick={handleClose}
      type="button"
    >
      Edit
    </button>
    {canEditPosition && (
      <button
        data-closeonclick={false}
        disabled={isSaving}
        className={`dropdown-item btn ${styles.dropdownItem}`}
        onClick={() => setIsEditingPosition(true)}
        type="button"
      >
        Change Tooltip Size and Position
      </button>
    )}
    <button
      data-closeonclick={false}
      disabled={isSaving}
      className={`dropdown-item btn ${styles.dropdownItem}`}
      onClick={() => setIsEditingColor(true)}
      type="button"
    >
      Change Color
    </button>
    <button
      data-closeonclick={false}
      disabled={isSaving}
      className={`dropdown-item btn ${styles.dropdownItem}`}
      onClick={() => setIsEditingSize(true)}
      type="button"
    >
      Change Size
    </button>
  </React.Fragment>
);

export default InitialMenu;
