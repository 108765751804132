import React from 'react';
import { StafferSlideShow } from '../types';
import { ActivityCard } from '../Cards';

interface FirstTCICardProps {
  hideTabs: boolean;
  lessonId: number;
  programId: number;
  shouldRenderSlideShow: boolean;
  slideShow: StafferSlideShow;
  stafferId: number;
}

const FirstTCICard = ({
  hideTabs, lessonId, programId, shouldRenderSlideShow, slideShow, stafferId,
}: FirstTCICardProps) => {
  if (!slideShow) return (<></>);

  if (slideShow.useForInvestigations || slideShow.useForUnitProjects || !shouldRenderSlideShow) return (<></>);

  if (slideShow?.activityType) {
    return (
      <ActivityCard
        key={slideShow?.id}
        slideShow={slideShow}
        programId={programId}
        lessonId={lessonId}
        stafferId={stafferId}
        hideTabs={hideTabs}
      />
    );
  }

  return (<></>);
};

export default FirstTCICard;
