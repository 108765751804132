type Program = {
  code: string;
  full_title_with_edition: string;
  id: string;
};

export const formatProgramsForSelect = (programs: Program[]) => programs.map(program => ({
  label: `[${program.code}] ${program.full_title_with_edition}`,
  value: program.id,
}));

type License = {
  code: string;
  name: string;
  id: string;
};

export const formatLicensesForSelect = (licenses: License[]) => licenses.map(license => ({
  label: `[${license.code}] ${license.name}`,
  value: license.id,
}));

export const areSetsEqual = (a: Set<any>, b: Set<any>) => a.size === b.size && [...a].every(value => b.has(value));

export const buildMultiPartFormData = (data, nullableFields = []) => (
  Object.keys(data).reduce((acc: FormData, fieldName: string) => {
    if (data[fieldName] === null) {
      // Note: FormData null values become the literal string 'null,' so
      // only set for non-string fields, allowing us to clear them.
      if (nullableFields.includes(fieldName)) {
        acc.append(fieldName, '');
      }
    } else if (data[fieldName].constructor.name === 'FileList') {
      if (data[fieldName][0]) {
        acc.append(fieldName, data[fieldName][0]);
      }
    } else {
      acc.append(fieldName, data[fieldName].toString());
    }

    return acc;
  }, new FormData())
);
