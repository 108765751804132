import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';
import { Tabs, Pane } from 'common/Tabs';
import { capitalizeWords } from 'TCIUtils';
import { formatLocalTimestampWithFormat } from 'common/Utils';

import styles from './RosterEventModal.module.scss';

export default class RosterEventModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    getRosterEventPath: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    resource: PropTypes.shape({
      id: PropTypes.number.isRequired,
      method: PropTypes.string.isRequired,
      date: PropTypes.number.isRequired,
      begin_time: PropTypes.string.isRequired,
      end_time: PropTypes.string.isRequired,
      sync_status: PropTypes.string.isRequired,
      actions: PropTypes.string,
      roster_event_id: PropTypes.number
    }).isRequired,
    subscriberName: PropTypes.string.isRequired
  };

  static _renderResults(results) {
    const output = Object.keys(results).map((resultType) => {
      const errors = RosterEventModal._renderErrorContainer(results[resultType]);
      return (
        <div key={resultType}>
          <p className={styles.errorTitle}>
            {capitalizeWords(resultType)}: ({results[resultType].length})
          </p>
          <textarea
            readOnly
            className={styles.errorContainer}
            value={errors}
            rows={errors === 'None' ? '1' : '10'}
          />
        </div>
      );
    });

    return (
      <div>
        {output}
      </div>
    );
  }

  static _renderErrorContainer(results) {
    if (results.length) {
      return results.map(result => `${result.uid ? `UID: ${result.uid} ` : ''} Errors: ${result.errors}`).join('\r\n');
    }

    return 'None';
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      rosterEvent: null
    };
  }

  componentDidMount() {
    this._getRosterEvent();
  }

  _getRosterEvent() {
    this.setState({ isLoading: true });

    Axios
      .get(this.props.getRosterEventPath.replace(':id', this.props.resource.roster_event_id))
      .then((response) => {
        this.setState({ rosterEvent: response.data.roster_event });
      })
      .catch(error => console.log(error))
      .then(() => this.setState({ isLoading: false }));
  }

  _renderSyncOverview() {
    return (
      <div className={styles.syncOverview}>
        <span>
          <b>Date:&nbsp;</b>
          {moment.unix(this.props.resource.date).utc().format('MMM D, YYYY')}
        </span>

        <span>
          <b>Begin Time:&nbsp;</b>
          {formatLocalTimestampWithFormat(this.props.resource.begin_time, 'hh:mm A')}
        </span>

        <span>
          <b>End Time:&nbsp;</b>
          {this.props.resource.end_time && formatLocalTimestampWithFormat(this.props.resource.end_time, 'hh:mm A')}
        </span>
      </div>
    );
  }

  _renderErrors() {
    if (this.state.isLoading) {
      return (
        <div>
          <i className="fa fa-spinner fa-spin mr5" aria-hidden="true" />
          Errors are loading. Please wait...
        </div>
      );
    }

    if (this.state.rosterEvent) {
      if (this.props.resource.sync_status === 'Failed') {
        return this._renderError();
      }

      return this._renderErrorTable();
    }

    return null;
  }

  _renderError() {
    return (
      <div>
        <em>Sync failed to run due to the following error:</em>
        <div className={styles.errorText}>
          {this.state.rosterEvent.errors}
        </div>
      </div>
    );
  }

  _renderErrorTable() {
    const { errors, archived, warnings } = this.state.rosterEvent;

    return (
      <Tabs>
        <Pane label={`Errors (${errors.count})`}>
          {RosterEventModal._renderResults(errors.results)}
        </Pane>

        <Pane label={`Archives (${archived.count})`}>
          {RosterEventModal._renderResults(archived.results)}
        </Pane>

        <Pane label={`Warnings (${warnings.count})`}>
          {RosterEventModal._renderResults(warnings.results)}
        </Pane>
      </Tabs>
    );
  }

  _renderFooter() {
    return (
      <div>
        <hr />
        <footer>
          <button
            className="btn btn--purple"
            type="button"
            onClick={this.props.closeModal}
          >
            Close
          </button>
        </footer>
      </div>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        isOpen={this.props.modalIsOpen}
        headerText={`Sync errors for ${this.props.subscriberName}`}
      >
        {this._renderSyncOverview()}
        {this._renderErrors()}
        {this._renderFooter()}
      </Modal>
    );
  }
}
