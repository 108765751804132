import { Form, Field } from 'react-final-form';
import Autosize from 'react-input-autosize';
import clsx from 'clsx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { formatLocalTimestamp } from 'common/Utils';
import SaveStatus from '../SaveStatus';
import AutoSave from '../../../common/Forms/AutoSave';
import styles from './ScoreEdit.module.scss';
import Tooltip from '../../../common/Tooltip';

export default class ScoreEdit extends Component {
  static propTypes = {
    activityType: PropTypes.string,
    answerID: PropTypes.number,
    canEdit: PropTypes.bool,
    exerciseType: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string,
    maxScore: PropTypes.number,
    notebookId: PropTypes.number,
    questionId: PropTypes.number,
    sectionId: PropTypes.number,
    score: PropTypes.number,
    slideGroupId: PropTypes.number,
    tooltip: PropTypes.string,
    unitId: PropTypes.number,
    updateScoresMap: PropTypes.func,
    updateURL: PropTypes.string
  };

  static defaultProps = {
    activityType: '',
    answerID: null,
    canEdit: false,
    lastUpdated: null,
    maxScore: null,
    notebookId: null,
    questionId: null,
    score: null,
    slideGroupId: null,
    tooltip: null,
    unitId: null,
    updateScoresMap: () => {},
    updateURL: null
  };

  constructor(props) {
    super(props);

    this.state = {
      inputError: false,
      saveError: false,
      savedTime: null,
      saving: false,
      score: this.props.score
    };

    this._handleScoreChange = this._handleScoreChange.bind(this);
  }

  componentDidMount() {
    if (this.props.exerciseType !== 'SlideGroup') {
      this.props.updateScoresMap(this.props.answerID || this.props.notebookId, this.props.slideGroupId || null, this.state.score);
    }
  }

  save = async (values) => {
    if (this.state.inputError) return;

    this.setState({ saving: true, saveError: false });
    await Axios
      .put(this.props.updateURL, { notebook_result: { grade: values.score }, score: values.score })
      .then(() => {
        const savedTime = formatLocalTimestamp(new Date(), true);
        this.setState({ savedTime, saving: false });
        this.props.updateScoresMap(this.props.answerID || this.props.notebookId || this.props.questionId, this.props.slideGroupId || null, this.state.score);
        this._toggleGradedOrUngraded(values.score);
      })
      .catch(() => {
        this.setState({ saving: false, saveError: true });
      });
  };

  _toggleGradedOrUngraded = (score) => {
    const questionEl = $(`#question-${this.props.questionId}`).parent();
    const sectionEl = $(`#section-${this.props.sectionId}-header`).parent();

    if (['', undefined, null].includes(score)) {
      questionEl.removeClass('graded-question').addClass('ungraded-question');
      sectionEl.removeClass('graded-section').addClass('ungraded-section');
    } else {
      questionEl.removeClass('ungraded-question').addClass('graded-question');
      sectionEl.removeClass('ungraded-section').addClass('graded-section');
    }
  };

  _handleScoreChange(e) {
    const value = e.target.value;
    const newScore = parseFloat(value);

    let scoringError;

    if (value !== '') {
      if (isNaN(newScore) || newScore > this.props.maxScore || newScore < 0) {
        scoringError = `Score must be a numerical value between ${0} and ${this.props.maxScore}.`;
      }
      else if ((newScore * 10) % 1 !== 0) {
        scoringError = 'Score can only have 1 digit after the decimal point.';
      }
    }

    this.setState({
      inputError: scoringError,
      score: value
    });
  }

  _renderError() {
    if (this.state.inputError) {
      return <div className={styles.error}>{this.state.inputError}</div>;
    }

    return null;
  }

  _renderSaveStatus() {
    return (
      <span className={styles.saveStatus}>
        <SaveStatus
          lastUpdated={formatLocalTimestamp(this.props.lastUpdated, false)}
          inputError={this.state.inputError}
          savedTime={this.state.savedTime}
          saving={this.state.saving}
          saveError={this.state.saveError}
        />
      </span>
    );
  }

  _renderLabel() {
    let label;
    if (this.props.unitId) {
      label = 'Project Score';
    }
    else if (this.props.activityType === 'video_activity') {
      label = 'Score';
    }
    else if (this.props.exerciseType === 'Notebook') {
      label = 'Lesson Notebook Score';
    }
    else if (this.props.exerciseType === 'Section' || this.props.exerciseType === 'ReadingGame') {
      label = 'Section Score';
    }
    else {
      label = 'Score';
    }

    return (
      <span>
        <b>{label}</b>:
      </span>
    );
  }

  _renderMaxScore() {
    return (
      <span>
        <span className="ml5 mr5">/</span>
        {this.props.maxScore}
      </span>
    );
  }

  _renderScore() {
    if (!this.props.score || isNaN(this.props.score)) return this.props.score;

    // Ensure that score only shows one decimal place at most:
    return Number(this.props.score).toFixed(1).replace(/\.0$/, '');
  }

  _giveCreditButton(fullCredit) {
    const message = fullCredit ? 'Give Full Credit' : 'Give No Credit';
    return (
      <Tooltip content={message} size="medium" theme="white">
        <button
          type="button"
          className={styles.creditButton}
          onClick={() => this.setState({ score: fullCredit ? this.props.maxScore : 0 })}
        >
          <i className={clsx('fa', {
            'text--green fa-check': fullCredit,
            'text--red fa-times': !fullCredit,
          })
          }
          />
        </button>
      </Tooltip>
    );
  }

  render() {
    return (
      <div className={styles.scoreContainer}>
        <Form
          keepDirtyOnReinitialize
          initialValues={{ score: this.state.score }}
          onSubmit={this.save}
          render={() => (
            <span>
              <AutoSave save={this.save} />
              <Field
                name="score"
                render={({ input }) => (
                  <div>
                    <span className={styles.scoreFieldsContainer}>
                      {this._renderLabel()}
                      {this.props.canEdit && (
                        <>
                          {this._giveCreditButton(true)}
                          {this._giveCreditButton(false)}
                        </>
                      )}
                      <span className="inline_block" data-tippy={this.props.tooltip}>
                        <Autosize
                          {...input}
                          className={styles.scoreInput}
                          onChange={this._handleScoreChange}
                          maxLength="6"
                          disabled={!this.props.canEdit}
                          value={this.props.canEdit ? input.value : this._renderScore()}
                        />
                      </span>
                      {this._renderMaxScore()}
                    </span>
                    {this.props.canEdit ? this._renderSaveStatus() : null}
                    {this._renderError()}
                  </div>
                )}
              />
            </span>
          )}
        />
      </div>
    );
  }
}
