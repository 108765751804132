export default interface IReferenceMaterial {
  id: number;
  title: string;
  content_type: string;
  program_summary: string;
  primary_image: string;
  tag_values: number[];
  published: boolean;
  program_ids: number[];
  created_at: string;
  updated_at: string;
  has_video: boolean;
  date_range: string;
  link: string;
  medium_image: string;
  is_assigned: boolean;
  model_to_assign: { label: string, value: string }[];
  assignable_type?: string | null;
}

export enum ReferenceMaterialTypes {
  BIOGRAPHIES_TYPE = 'biographies',
  CAREER_PROFILES_TYPE = 'careerProfiles',
  CIVICS_TYPE = 'civics',
  MAPS_TYPE = 'maps',
  PRIMARY_SOURCES_TYPE = 'primarySources',
  SCIENCE_AND_ENGINEERING_TYPE = 'scienceAndEngineering',
  SUPREME_COURT_CASES_TYPE = 'supremeCourtCases',
}
