/* eslint-disable no-param-reassign */

import React, { useCallback, useRef } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import ReactDOM from 'react-dom';
import { useDebounceCallback } from 'usehooks-ts';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import DropdownMenu from '@/components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from '@/components/common/Dropdown';
import styles from '../../Content/SlideObjectText/AnchoredDropdown/AnchoredDropdown.module.scss';
import PaddingEditor from './PaddingEditor';
import { PopoverProps } from '../types';
import usePopover from '../../hooks/usePopover';

type Data = {
  padding: number;
};

const PaddingPopover = ({ anchorId, openerClass, slideObject, styleDivRef }: PopoverProps) => {
  const ref = useRef<HTMLDivElement>();
  const dropdownEvents = useDropdownEvents(ref);
  const updateSlideObjectInContext = useSlideShowContext(state => state.updateSlideObject);


  const afterToggle = () => {
    if (slideObject.padding) {
      styleDivRef.current.style.padding = `${slideObject.padding}px`;
    } else {
      styleDivRef.current.style.padding = '';
    }
  };

  const { coordinates, setIsUpdated } = usePopover({ afterToggle, anchorId, dropdownEvents, openerClass, ref });

  const updateSlideObject = (data: Data) => (
    Axios.patch(Routes.plato_api_slide_object_path(slideObject.id), {
      padding: data.padding,
    }).then((res) => {
      updateSlideObjectInContext(res.data.slideObject);
    })
  );

  const handleSubmit = (data: Data, closePopover = true): Promise<any> => {
    setIsUpdated(true);
    if (closePopover) dropdownEvents.setOpen(false);

    return updateSlideObject(data);
  };

  const debouncedSubmit = useCallback(useDebounceCallback(handleSubmit, 300), []);

  const handleChange = async (data: Data) => {
    if (data.padding) {
      styleDivRef.current.style.padding = `${data.padding}px`;
    } else {
      styleDivRef.current.style.padding = '';
    }

    await debouncedSubmit(data, false);
  };

  return ReactDOM.createPortal(
    <DropdownMenu open={dropdownEvents.open} className={styles.margin} coordinates={coordinates} ref={ref}>
      {dropdownEvents.open && (
        <PaddingEditor
          initialValue={slideObject.padding}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
    </DropdownMenu>,
    document.body,
  );
};

export default PaddingPopover;
