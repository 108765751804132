import React, { RefObject } from 'react';
import ReactDOM from 'react-dom';
import FE from 'froala-editor';
// eslint-disable-next-line import/no-cycle
import { AnchorRef } from '@/components/ui/Anchor';
import Axios from 'axios';
import { plato_api_glossary_term_model_path, plato_api_glossary_term_models_path } from '@/modules/routes';
import icon from './icon.svg';
import GlossaryTermsPopover from './GlossaryTermsPopover';

export const GLOSSARY_TERMS = {
  commandName: 'GlossaryTerms',
  name: 'Glossary Terms',
  icon: 'glossaryTermsIcon',
  events: {
    mouseup: (selector: string) => {
    },
    mousedown: (e: MouseEvent, selector: string) => {
    },
    click: (e: MouseEvent, glossaryTermsPopoverRef: RefObject<AnchorRef>, selector: string, editorInstance: any) => {
      const editor = document.querySelector(`${selector} .fr-view`) as HTMLElement;
      if (!editor) return;

      let target = e.target as HTMLElement;

      // Check if the target or the target parent has the 'glossary-term' class
      if (!target.classList.contains('glossary-term') && !target.parentElement.classList.contains('glossary-term')) {
        return;
      }

      // If the current target doesn't have the class, i.e. isn't the wrapping element, the parent does. Set the target correctly.
      if (!target.classList.contains('glossary-term')) {
        target = target.parentElement as HTMLElement;
      }

      const popoverRoot = document.querySelector('#glossary-terms-root') || document.createElement('div');
      popoverRoot.id = 'glossary-terms-root';

      e.stopPropagation();
      e.preventDefault();

      const handleDelete = async () => {
        const parent = target.parentNode;
        if (!parent) return;

        const modelId = target.dataset.termModelId;
        await Axios.delete(plato_api_glossary_term_model_path(modelId)).catch(err => console.log(err));

        const content = target.querySelector('.glossary-text').textContent;
        const textNode = document.createTextNode(content);
        target.parentNode.replaceChild(textNode, target);
        ReactDOM.unmountComponentAtNode(popoverRoot);
        popoverRoot.remove();
      };

      ReactDOM.render(
        <GlossaryTermsPopover
          onDelete={handleDelete}
          targetElement={target}
          editorInstance={editorInstance}
          glossaryTermsPopoverRef={glossaryTermsPopoverRef}
        />,
        popoverRoot,
      );
    },
  },
} as const;

export const glossaryTermsPluginCommand = () => {
  const glossaryTermsIcon = '<img alt="Edit margins" src="[PATH]" />';
  FE.DefineIconTemplate(GLOSSARY_TERMS.icon, glossaryTermsIcon);
  FE.DefineIcon(GLOSSARY_TERMS.commandName, { PATH: icon, template: GLOSSARY_TERMS.icon });

  FE.RegisterCommand(GLOSSARY_TERMS.commandName, {
    icon: GLOSSARY_TERMS.commandName,
    title: GLOSSARY_TERMS.name,
    undo: true,
  });
};
