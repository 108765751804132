import React from 'react';
import usePanoramicImageStore from '../usePanoramicImageStore';
import AddHotspotButton from './AddHotspotButton';
import HotspotEditor from './HotspotEditor';

const HotspotBank = () => {
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);

  return (
    <section className="tw-flex tw-flex-col tw-gap-4">
      {panoramicImage.hotspots.map((hotspot, i) => (
        <HotspotEditor index={i} key={hotspot.id} hotspot={hotspot} />
      ))}
      <AddHotspotButton />
    </section>
  );
};

export default HotspotBank;
