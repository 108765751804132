import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'modules/routes';
import Axios from 'axios';
import Modal, { useModalManager } from '@/components/common/Modal';
import styles from './index.module.scss';

const RetiredProgramModal = ({ autoRostered, latestProgramEditionId, stafferPath, stafferUpdatePayload }) => {
  const { isOpen, close } = useModalManager(true);

  useEffect(() => {
    Axios.put(stafferPath, stafferUpdatePayload)
  }, []);

  const header = () => {
    return (
      <div className={styles.retiredProgramModalHeader}>
        <i className={`fa fa-exclamation-circle fa-lg`} />
        Retired Edition
      </div>
    )
  }

  let latestEditionRoute = Routes.teacher_programs_path();
  if (!!latestProgramEditionId) latestEditionRoute += `#${latestProgramEditionId}`

  return (
    <Modal
      isOpen={isOpen}
      closeModal={close}
      headerText={header()}
      className={styles.retiredProgramModal}
      bodyClassName={styles.retiredProgramModalBodyOverride}
      headerClass={styles.retiredProgramModalHeaderOverride}
    >
      <div className={styles.retiredProgramBody}>
        <div>
          This edition of the program has been retired. The content and software features are no longer being actively maintained.
        </div>
        <br />
        <div className={styles.retiredProgramInlineText}>
          To access the latest content and software features, update to the latest edition.&nbsp;
          <a href="https://help.teachtci.com/s/article/Edition-Retirement" target="_blank" rel="noopener noreferrer">
            Learn more
          </a>&nbsp;
          about updating editions.
        </div>
      </div>

      <footer>
        {!autoRostered &&
          <a href={latestEditionRoute} className="btn btn--link-purple">
            Use a different edition
          </a>
        }
        <button
          className="btn btn--red"
          onClick={close}
          type="button"
        >
          Continue
        </button>
      </footer>
    </Modal>
  );
};

RetiredProgramModal.propTypes = {
  autoRostered: PropTypes.bool.isRequired,
  latestProgramEditionId: PropTypes.number,
  stafferPath: PropTypes.string.isRequired,
  stafferUpdatePayload: PropTypes.object.isRequired,
};

export default RetiredProgramModal;
