import React from 'react';
import Tooltip from '@/components/ui/Tooltip';
import cn from '@/utils/cn';
import homeIcon from '@images/icons/home-white.svg';
import gradesIcon from '@images/icons/bar-outline.svg';
import lessonOverviewIcon from '@images/icons/hand.svg';
import readingIcon from '@images/icons/open-books-white.svg';
import gamesLibraryIcon from '@images/icons/controller-white.svg';
import assignmentIcon from '@images/icons/fa-calendar.svg';
import styles from '../LeftNavBarTabs.module.scss';

const ICONS = {
  assignments: assignmentIcon,
  games: gamesLibraryIcon,
  grades: gradesIcon,
  home: homeIcon,
  overview: lessonOverviewIcon,
  reading: readingIcon,
} as const;

interface Tab {
  active: boolean;
  icon: 'home' | 'assignments' | 'grades' | 'overview' | 'reading' | 'games';
  linksTo: string;
  tooltip: string;
}

interface NavProps {
  tabs: Tab[];
}

const Nav = ({ tabs }: NavProps) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-mt-6 tw-gap-6">
    {tabs.map((tab) => {
      const isActive = `${window.location.pathname}${window.location.search}` === tab.linksTo || tab.active;

      return (
        <Tooltip.Provider key={tab.icon} delayDuration={0}>
          <Tooltip>
            <Tooltip.Trigger
              role="button"
              onClick={(e) => {
                const target = e.target as HTMLElement;

                // necessary for tab navigation
                if (target.tagName === 'BUTTON') {
                  (target.children[0] as HTMLAnchorElement).click();
                }

                e.stopPropagation();
              }}
              className={cn(
                `tw-bg-transparent tw-border-none tw-rounded tw-w-4/5 tw-aspect-square tw-cursor-pointer 
                 tw-outline-none tw-flex tw-items-stretch tw-justify-stretch hover:tw-bg-white
                 hover:tw-bg-opacity-25 focus:tw-bg-white focus:tw-bg-opacity-25 focus:tw-outline-none`,
                { 'tw-bg-white tw-bg-opacity-25': isActive },
              )}
            >
              <a
                className={`tw-flex tw-items-center tw-justify-center tw-bg-transparent tw-border-none
                            tw-outline-none tw-w-full`}
                href={tab.linksTo}
                tabIndex={-1}
              >
                <img className="tw-fill-amber-500" alt={tab.tooltip} src={ICONS[tab.icon]} tabIndex={-1} />
              </a>
            </Tooltip.Trigger>
            <Tooltip.Content
              className={cn('tw-z-9999 tw-bg-white tw-py-2 tw-px-6 tw-rounded', styles.tooltipShadow)}
              side="right"
              sideOffset={14}
            >
              {tab.tooltip}
              <Tooltip.Arrow className="tw-fill-white tw-bg-transparent" />
            </Tooltip.Content>
          </Tooltip>
        </Tooltip.Provider>
      );
    })}
  </div>
);

export default Nav;
