import { useMemo } from 'react';
import useGradesTableStore from '../store/useGradesTableStore';
import { IStudent, IStudentRow } from '../types';

const useStudentRows = () => {
  const students = useGradesTableStore(state => state.students);

  return useMemo<IStudentRow[]>(() => (
    students.map<IStudentRow>((student: IStudent) => ({
      student,
    }))
  ), [students]);
};

export default useStudentRows;
