import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_SLIDE_GROUP } from '@/components/admin/SlideShow/Utils/slideShowGraphQL';
import Modal, { useModalManager, Footer } from 'components/common/Modal';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import CreateSlideGroupsContent from './CreateSlideGroupsContent';
import EditSlideGroupForm from './EditSlideGroupForm';
import { SlideGroup, CREATING_SLIDE_GROUPS, EDITING_SLIDE_GROUP, useFooterProps, Slide } from './utils';
import styles from '../SlideShowBuilder.module.scss';

type CreateTransitionGroupProps = {
  slideShowId: number;
  slides: Slide[];
};

const CreateTransitionGroup = ({ slideShowId, slides }: CreateTransitionGroupProps) => {
  const [currentAction, setCurrentAction] = useState<typeof CREATING_SLIDE_GROUPS | typeof EDITING_SLIDE_GROUP>(
    CREATING_SLIDE_GROUPS,
  );
  const [currentSlideGroupId, setCurrentSlideGroupId] = useState(-1);
  const [updateSlideGroupMutation] = useMutation(UPDATE_SLIDE_GROUP, { ignoreResults: true });
  const slideGroups: SlideGroup[] = useSlideShowContext(state => state.slideShow.slideGroups);
  const updateSlideShow = useSlideShowContext(state => state.updateSlideShow);
  const programId = useSlideShowContext(state => state.programId);

  const onSubmit = (data) => {
    const mutationParams = {
      variables: {
        slideGroupId: currentSlideGroupId,
        programId: programId,
        titleEn: data.title_en,
        titleEs: data.title_es,
        slideIds: data.slideIds,
        extension: data.extension,
        pacing: parseInt(data.pacing, 10),
        displaySlideHeader: data.display_slide_header,
        icon: data.icon,
      },
    };

    updateSlideGroupMutation(mutationParams)
      .then((res) => {
        updateSlideShow(res.data.updateSlideGroup.slideShow);
        setCurrentAction(CREATING_SLIDE_GROUPS);
      });
  };

  const modalManager = useModalManager();
  const footerProps = useFooterProps(currentAction, setCurrentAction, onSubmit, modalManager);

  const closeModal = () => {
    modalManager.close();
    setCurrentAction(CREATING_SLIDE_GROUPS);
  };

  const modalContent = () => {
    switch (currentAction) {
      case CREATING_SLIDE_GROUPS:
        return (
          <CreateSlideGroupsContent
            setCurrentAction={setCurrentAction}
            setCurrentSlideGroupId={setCurrentSlideGroupId}
            slideShowId={slideShowId}
          />
        );
      case EDITING_SLIDE_GROUP:
        const slideGroup = slideGroups.find(sg => sg.id === currentSlideGroupId);
        const slideGroupIds = slideGroups.map(sg => sg.id);

        return (
          <EditSlideGroupForm
            slideGroup={slideGroup}
            onSubmit={onSubmit}
            setCurrentAction={setCurrentAction}
            slides={slides
              .filter(slide => slide.slideGroupId === parseInt(slideGroup.id as string, 10) || !slide.slideGroupId || !slideGroupIds.includes(slide.slideGroupId.toString()))}
          />
        );
      default:
        return null;
    }
  };

  const createTransitionGroupModal = () => (
    <Modal
      nearlyFullScreen
      headerText={currentAction === CREATING_SLIDE_GROUPS ? 'Create Slide Group' : 'Add Slide Group'}
      isOpen={modalManager.isOpen}
      closeModal={closeModal}
      footer={currentAction !== EDITING_SLIDE_GROUP && <Footer {...footerProps} />}
    >
      {modalContent()}
    </Modal>
  );

  return (
    <div className="dsp-flex">
      <button type="button" className="btn btn--outline-purple" onClick={modalManager.open}>
        <div className={styles.textWithIcon}>
          <i className="fa fa-object-group"></i>
          Group Slides
        </div>
      </button>
      {modalManager.isOpen && createTransitionGroupModal()}
    </div>
  );
};

export default CreateTransitionGroup;
