import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Banner from 'common/Banner';
import DisconnectButton from './DisconnectButton';

import styles from './ConnectionData.module.scss';

const UserProfile = ({ email, displayName, profilePictureUrl }) => (
  <div className={styles.connectionData}>
    <img alt="" src={profilePictureUrl} />
    <div>
      <div className={styles.displayName}>{displayName}</div>
      <div>{email}</div>
    </div>
  </div>
);

UserProfile.propTypes = {
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profilePictureUrl: PropTypes.string.isRequired
};

const ConnectionData = ({
  email, displayName, lmsType, profilePictureUrl, updateState, userType, iconPath
}) => (
  <Fragment>
    {email && (
      <UserProfile
        displayName={displayName}
        email={email}
        profilePictureUrl={profilePictureUrl || iconPath}
      />
    )}

    {lmsType === 'Google Classroom' && !email && userType !== 'Student' && (
      <Banner iconType="fa-check" color="green">
        Your Google account is connected via Google Sign-In.
        You can sign in to TCI with your Google account.
      </Banner>
    )}

    <DisconnectButton
      lmsType={lmsType}
      updateState={updateState}
    />
  </Fragment>
);

ConnectionData.propTypes = {
  displayName: PropTypes.string,
  email: PropTypes.string,
  iconPath: PropTypes.string,
  lmsType: PropTypes.string.isRequired,
  profilePictureUrl: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  userType: PropTypes.string
};

ConnectionData.defaultProps = {
  displayName: null,
  email: null,
  iconPath: null,
  profilePictureUrl: null,
  userType: 'Student'
};

export default ConnectionData;
