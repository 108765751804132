import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import Spinner from 'common/Spinner';
import styles from './index.module.scss';

const DashboardCard = ({ ...props }) => {
  const [expanded, setExpanded] = useState(props.cardExpanded);

  const cardBody = (
    <div className={`${props.bodyClassName} ${!expanded && styles.collapsed}`}>
      {props.children}
    </div>
  );

  const renderCardFooter = () => {
    if (props.isLoading) return null;

    return (
      <div className={styles.cardFooter}>
        <button
          id="seeMoreButton"
          type="button"
          className="btn btn--link-purple"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? I18n.t('see_less') : I18n.t('see_more')}
          <i className={`fa ${expanded ? 'fa-caret-up' : 'fa-caret-down'} ml5`} aria-hidden />
        </button>
      </div>
    );
  };

  const spinner = <Spinner className="center mt10 mb10" isLoading={props.isLoading} />;

  return (
    <div
      className={`card mb10  mt10 ${styles.dashboardCard} ${styles[props.borderColor]}`}
    >
      <div className={styles.cardHeader}>
        <div className="title ml20 mb10">
          <h1 className="inline_block">{props.label}</h1>
          <a
            className="pos_fr dsp-flex-center mr20"
            href={props.link.url}
          >
            {props.link.label}&nbsp;
            <i className={`fa fa-arrow-circle-right ml10 ${styles.arrow}`} aria-hidden />
          </a>
        </div>
      </div>

      <hr />

      {props.isLoading ? spinner : cardBody}
      {props.showFooter && renderCardFooter()}
    </div>
  );
};


DashboardCard.propTypes = {
  bodyClassName: PropTypes.string,
  borderColor: PropTypes.string.isRequired,
  cardExpanded: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  showFooter: PropTypes.bool
};

DashboardCard.defaultProps = {
  bodyClassName: '',
  cardExpanded: true,
  isLoading: false,
  link: '#',
  showFooter: false
};

export default DashboardCard;
