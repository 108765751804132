import { DraggableChoicesType } from "../../types";

export const sortedDraggableChoicesByPosition = (draggableChoices: DraggableChoicesType) => {
  return Object.entries(draggableChoices).sort(([_key1, choice1], [_key2, choice2]) => {
    return choice1.position > choice2.position ? 1 : -1;
  });
};

export const REORDER_LEFT = 'REORDER_LEFT';
export const REORDER_RIGHT = 'REORDER_RIGHT';

export const reorderedDraggableChoiceParams = (key, sortedDraggableChoices, direction) => {
  const [selectedSortedChoice, index] = findDraggableChoice(key, sortedDraggableChoices, direction);
  if (!selectedSortedChoice || index === -1) return [];

  const indexToSwap = direction === REORDER_LEFT ? index - 1 : index + 1;
  const sortedChoiceToSwap = sortedDraggableChoices[indexToSwap];

  const [selectedChoiceKey, selectedChoiceData] = selectedSortedChoice;
  const [sortedChoiceToSwapKey, sortedChoiceToSwapData] = sortedChoiceToSwap;

  return [
    { id: selectedChoiceKey, ...selectedChoiceData, position: sortedChoiceToSwapData.position },
    { id: sortedChoiceToSwapKey, ...sortedChoiceToSwapData, position: selectedChoiceData.position }
  ];
};

const findDraggableChoice = (key, choices, direction) => {
  const choice = choices.find(([choiceKey, _]) => choiceKey === key);
  if (!choice) return [];

  const index = choices.indexOf(choice);
  if (
    (index === 0 && direction === REORDER_LEFT) ||
    (index === choices.length - 1 && direction === REORDER_RIGHT)
  ) return [];

  return [choice, index];
};
