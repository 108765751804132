import React from 'react';
import PropTypes from 'prop-types';
import AssignButton from 'shared/LessonOverview/Cards/AssignButton';
import CloneButton from './CloneButton';
import styles from './ActionBar.module.scss';
import {
  DeleteButton,
  EditNewSlideShowButton,
  EditExistingSlideShowButton,
  PresentButton,
  PrintButton,
} from './Buttons';
import { SLIDE_SHOW_MODEL_TYPES } from 'shared/slide_shows/utils';
import { toCamelCase } from 'modules/TCIUtils';

// pacing info for only modules
const Pacing = ({ slideShow }) => {
  if (!slideShow.pacing) return null;

  return (
    <div className={styles.pacing}>
      <i className="fa fa-clock-o" /> {slideShow.pacing} mins
    </div>
  );
};

const ActionBar = ({
  hideTabs, slideShow, isStaffer, stafferId, teacherCard,
}) => {
  const showStafferActions = () => {
    if (!isStaffer) return null;

    let initialValues;

    if (slideShow.assignable[0]?.slideGroups?.length) {
      initialValues = {
        modelType: SLIDE_SHOW_MODEL_TYPES[slideShow.activityType],
        modelsToAssign: slideShow.assignable[0].slideGroups,
        slideShowSelect: slideShow.assignable[0].value,
      };
    }
    else {
      initialValues = {
        modelType: SLIDE_SHOW_MODEL_TYPES[slideShow.activityType],
        modelsToAssign: slideShow.assignable[0].value,
      };
    }

    return (
      <React.Fragment>
        {Object.keys(SLIDE_SHOW_MODEL_TYPES).includes(slideShow.activityType) && slideShow.assignable && (
          <AssignButton
            initialValues={initialValues}
            isAssigned={slideShow.isAssigned}
            lessonId={parseInt(slideShow.lesson.id, 10)}
            program={toCamelCase(slideShow.program)}
            stafferId={stafferId}
          />
        )}

        {teacherCard && (
          <EditExistingSlideShowButton slideShow={slideShow} />
        )}
        {!teacherCard && (
          <EditNewSlideShowButton
            slideShow={slideShow}
            useNewBuilder={slideShow.useNewBuilder}
            hideTabs={hideTabs}
          />
        )}
        <CloneButton
          type={slideShow.className}
          id={slideShow.id}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          useNewBuilder={slideShow.useNewBuilder}
          hideTabs={hideTabs}
        />
        {slideShow.activityType !== 'video_activity' && <PrintButton slideShow={slideShow} />}
        <DeleteButton slideShow={slideShow} />
      </React.Fragment>
    );
  };

  return (
    <div className={styles.bar}>
      <div className={styles.actions}>
        <PresentButton slideShow={slideShow} />
        {showStafferActions()}
      </div>
      <Pacing slideShow={slideShow} />
    </div>
  );
};

const slideShowPropTypes = PropTypes.shape({
  activityType: PropTypes.string,
  assignable: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  links: PropTypes.shape({
    deletePath: PropTypes.string,
    editPath: PropTypes.string,
    presentPath: PropTypes.string,
    printUrl: PropTypes.string,
    sharePath: PropTypes.string,
    unsharePath: PropTypes.string
  }).isRequired,
  pacing: PropTypes.number,
  program: PropTypes.object,
  shareable: PropTypes.bool,
  shared: PropTypes.bool,
  slideShowId: PropTypes.number,
  stafferId: PropTypes.number,
  title: PropTypes.string,
  useNewBuilder: PropTypes.bool,
}).isRequired;

ActionBar.propTypes = {
  hideTabs: PropTypes.bool,
  isStaffer: PropTypes.bool,
  slideShow: slideShowPropTypes,
  stafferId: PropTypes.number.isRequired,
  teacherCard: PropTypes.bool,
};

ActionBar.defaultProps = {
  completeLessonGuideId: null,
  hideTabs: false,
  isStaffer: true,
  teacherCard: true,
};

Pacing.propTypes = {
  slideShow: slideShowPropTypes
};

export default ActionBar;
