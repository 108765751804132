define(['TCIUtils', 'Modal'], function(TCIUtils, Modal) {
  window.statesModal = [];

  function picker(config) {
    this.id = config.id;
    this.type = config.type;
    this.submit = config.submit;
    this.submitUrl = config.submitUrl;
    this.statesUrl = config.statesUrl;
    this.trigger_element = config.trigger_element;
    this.modal = statesModal[this.id];
    this.preload = config.preload;
    this.default_closed = config.default_closed;
  }

  picker.prototype = {
    init: function() {
      if (this.modal === undefined) this._buildModal();
      this._initModal();
    },

    _buildModal: function() {
      this.statesContainer = document.createElement('div');
      this.statesContainer.classList.add('states_container');
      this.statesContainer.id = `states_${this.id}`;

      const spinner = document.createElement('i');
      spinner.className = 'fa fa-spinner fa-spin fa-lg';
      this.statesContainer.appendChild(spinner);

      this._populateStates(this.statesContainer, this.statesUrl);

      // need the container in the DOM so the modal works :(
      // also need the container to be inside of the form
      this.trigger_element.parentElement.appendChild(this.statesContainer);
      if (this.preload === 'true') $(this.statesContainer).hide();
    },

    _populateStates: function(container, url) {
      $.get(url)
        .done(function(data) {
          let stateData;
          if (Array.isArray(data)) {
            stateData = data;
          }
          else {
            stateData = data.data;
          }

          this._buildCheckboxes(container, stateData);
        }.bind(this))
        .fail(function() {
          container.textContent = '';
          const errors = document.createElement('div');
          errors.className = 'error';
          errors.textContent = 'Failed to get states. Try refreshing the page.';
          container.appendChild(errors);
        });
    },

    _buildCheckboxes: function(container, states) {
      container.textContent = '';

      var topUl = document.createElement('ul');
      topUl.className = 'states_list top';
      this._buildSelectAllCheckbox(container);
      container.appendChild(topUl);

      var childUl = document.createElement('ul');
      childUl.className = 'programs child';
      this._buildStates(childUl, states);
      topUl.appendChild(childUl);

      this._buildFooter(container);
      this._setSelectAllState();
    },

    _buildSelectAllCheckbox: function(container) {
      var selectAll = document.createElement('li');
      selectAll.className = 'subcategory parent';

      var selectAllLabel = document.createElement('label');

      var selectAllInput = document.createElement('input');
      selectAllInput.type = 'checkbox';
      selectAllInput.className = 'select_all';
      TCIUtils.setupSelectAllCheckboxes($(selectAllInput));

      selectAllLabel.appendChild(selectAllInput);
      selectAllLabel.appendChild(document.createTextNode('Select All'));
      selectAll.appendChild(selectAllLabel);

      container.appendChild(selectAll);
    },

    _buildStates: function(container, states) {
      let statesCheckboxes = document.createElement('ul');
      statesCheckboxes.className = 'states_checkboxes';

      for (let i = 0; i < states.length; i++) {
        const stateLi = document.createElement('li');
        const stateLabel = document.createElement('label');
        const input = document.createElement('input');
        input.name = `[${this.type}][states][]`;
        input.type = 'checkbox';
        input.value = states[i].id;
        input.dataset.saved = input.checked = states[i].checked;
        input.dataset.abbrev = states[i].abbreviation;
        $(input).on('change', this._makeDirty.bind(this));

        stateLabel.appendChild(input);
        stateLabel.appendChild(document.createTextNode(states[i].name));

        stateLi.appendChild(stateLabel);
        statesCheckboxes.appendChild(stateLi);

        if ((i + 1) % 11 === 0 || i === states.length - 1) {
          container.appendChild(statesCheckboxes);
          statesCheckboxes = document.createElement('ul');
          statesCheckboxes.className = 'states_checkboxes';
        }
      }
    },

    _buildFooter: function(container) {
      const footer = document.createElement('footer');
      this.submitButton = document.createElement('button');
      this.submitButton.className = 'btn btn--purple';
      this.submitButton.type = 'button';
      this.submitButton.textContent = this.submit;
      $(this.submitButton).on('click', this._submit.bind(this));

      footer.appendChild(this.submitButton);

      container.appendChild(footer);
    },

    _initModal: function() {
      this.modal = window.statesModal[this.id] || new Modal({
        trigger_element: this.trigger_element,
        popup_element: this.statesContainer,
        popup_class: 'modal_popup_wrapper grey-modal',
        close_button_class: 'modal_close_button',
        overlay: true,
        center: true,
        width: '817px',
        title: 'Edit State-Specific Access',
        close: this._resetCheckboxes.bind(this),
        default_closed: this.default_closed,
        no_observer: true,
      });

      if (this.preload !== 'true') this.modal.initialized ? this.modal.open() : this.modal.init();
    },

    _resetCheckboxes: function() {
      const dirtyInputs = this.modal.popup_element.querySelectorAll('input[data-dirty=true]');
      dirtyInputs.forEach(function(input) {
        delete input.dataset.dirty;
        input.checked = input.dataset.saved === 'true';
      });
      this._setSelectAllState();
      this.modal.hide();
    },

    _setSelectAllState: function() {
      const $selectAll = $(this.modal.popup_element).find('.select_all');
      const $checkedInputs = $(this.modal.popup_element).find('input:not(.select_all):checked');
      const $inputs = $(this.modal.popup_element).find('input:not(.select_all)');
      if ($inputs.length > 0 && $selectAll.length > 0) {
        TCIUtils.setSelectAllStates($selectAll, $checkedInputs, $inputs, []);
      }
    },

    /** listener functions **/
    _makeDirty: function(e) {
      e.target.dataset.dirty = true;
      this._setSelectAllState();
    },

    _submit: function() {
      var checkedInputs = this.modal.popup_element.querySelectorAll('input:not(.select_all):checked');

      if (this.submit === 'Submit') {
        const states = [];
        const data = {};
        checkedInputs.forEach(function(input) {
          states.push(input.value);
        });
        // if we have no states, pass an empty string so
        // rails recognizes the param
        data[this.type] = { states: states.length === 0 ? '' : states };
        $.ajax({
          type: 'POST',
          dataType: 'json',
          url: this.submitUrl,
          data: data,
          error: function(response) {
            console.log('error:', response);
          },
          success: function(data) {
            var dirtyInputs = this.modal.popup_element.querySelectorAll('input[data-dirty=true]');
            dirtyInputs.forEach(function(input) {
              delete input.dataset.dirty;
              input.dataset.saved = input.checked;
            });
            this.modal.trigger_element.querySelector('span.selection').textContent = data.selected;
            this.modal.close();
          }.bind(this)
        });
      }
      else {
        var inputs = this.modal.popup_element.querySelectorAll('input:not(.select_all)');
        var dirtyInputs = this.modal.popup_element.querySelectorAll('input[data-dirty=true]');
        var checkedInputs = this.modal.popup_element.querySelectorAll('input:not(.select_all):checked');

        dirtyInputs.forEach(function(input) {
          delete input.dataset.dirty;
          input.dataset.saved = input.checked;
        });

        this.modal.trigger_element.querySelector('span.selection').textContent = this._updateTrigger(inputs, checkedInputs);
        this.modal.close();
      }
    },

    _updateTrigger: function(inputs, checkedInputs) {
      if (checkedInputs.length === 0) return 'TCI only';
      else if (checkedInputs.length === 1) return `${checkedInputs[0].dataset.abbrev} only`;
      else if (checkedInputs.length === inputs.length) return 'National';
      return 'Many';
    }
  };

  return {
    picker: picker
  };
});
