import React from 'react';
import PropTypes from 'prop-types';
import styles from './HorizontalScrollMenu.module.scss';

interface MenuItemProps {
  ariaControlId?: string;
  onClick: (text: string) => void;
  selected: boolean;
  text: string;
}

const MenuItem = ({ ariaControlId, onClick, selected, text }: MenuItemProps) => (
  <button
    onClick={() => onClick(text)}
    type="button"
    role="tab"
    aria-selected={selected}
    aria-controls={selected ? ariaControlId : null}
    className={`${styles.menuItem} ${selected ? styles.active : ''}`}
    tabIndex={selected ? -1 : null}
  >
    {text}
  </button>
);

MenuItem.propTypes = {
  ariaControlId: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
  ariaControlId: null,
};

export default MenuItem;
