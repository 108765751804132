import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export default function RadioButton({
  buttonPosition, defaultChecked, groupName, label, value
}) {
  return (
    <label className={styles.label}>
      {buttonPosition.toLowerCase() !== 'before' && label}
      <input type="radio" value={value} name={groupName} defaultChecked={defaultChecked} />
      {buttonPosition.toLowerCase() === 'before' && label}
    </label>
  );
}

RadioButton.propTypes = {
  buttonPosition: PropTypes.string,
  defaultChecked: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  buttonPosition: 'before',
  defaultChecked: false
};
