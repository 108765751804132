import React, { useEffect, useRef, useState } from 'react';

import Tooltip from '@/components/common/Tooltip';
import { Color } from '@/components/common/libraries/types';
import { userInfo } from '@/components/common/libraries/actions';
import Status from '@/components/common/libraries/common/Status';
import { isEllipsisActive } from '@/components/common/libraries/common/utils';
import { VideoInfo } from '@/components/common/libraries/VideoLibrary/types';
import { hideIncludedIns } from '@/components/common/libraries/VideoLibrary/actions';
import PlayIcon from 'images/icons/play-icon.png';
import FullscreenIcon from 'images/icons/fullscreen-icon.svg';
import style from './VideoCard.module.scss';

interface Props {
  data: VideoInfo;
  onPlayVideo: (videoInfo: VideoInfo) => void;
  onPublish: (id: string | number | undefined, title: string) => void;
  onUnpublish: (id: string | number | undefined, title: string) => void;
  showPublish: boolean;
}

const generateKey = (id: string | number, string: string) => {
  const binary = new TextEncoder().encode(`${id}-${string}`);

  return btoa(String.fromCharCode(...binary));
};

const VideoCard = ({
  data,
  onPlayVideo,
  showPublish,
  onPublish,
  onUnpublish,
}: Props) => {
  const unitRef = useRef(null);
  const lessonRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState({
    unit: false,
    lesson: false,
  });

  const isStudent = !userInfo.staffer;

  useEffect(() => {
    setShowTooltip({
      unit: unitRef.current ? isEllipsisActive(unitRef.current) : false,
      lesson: lessonRef.current ? isEllipsisActive(lessonRef.current) : false,
    });
  }, [unitRef, lessonRef]);

  const handlePlayVideo = () => {
    onPlayVideo(data);
  };

  const handlePublishClick = () => {
    if (data.published) {
      onUnpublish(data.id, data.title);
    } else {
      onPublish(data.id, data.title);
    }
  };

  return (
    <div className={`card ${style.videoCard}`}>
      <div
        className={style.cardImg + (!data.poster_url ? ` ${style.blackImg}` : '')}
        onClick={handlePlayVideo}
      >
        <img src={data.poster_url} />
        <div className={style.playButton}>
          <img src={PlayIcon} />
        </div>

        {data.video_duration && (
          <div className={`${style.videoLength} pos_bottom_right`}>
            {data.video_duration}
          </div>
        )}
      </div>

      <div className={style.cardContent}>
        <p className={style.cardTitle} onClick={handlePlayVideo}>
          {data.title}
        </p>

        <div className="mb10">
          {data.unit_title && (
            <Tooltip
              content={data.unit_title}
              key={generateKey(data.id, data.unit_title)}
              shouldDisplay={showTooltip.unit}
            >
              <div ref={unitRef} className={style.cardSubtitle}>
                {data.unit_title}
              </div>
            </Tooltip>
          )}
          {data.lesson_title && (
            <Tooltip
              content={data.lesson_title}
              key={generateKey(data.id, data.lesson_title)}
              shouldDisplay={showTooltip.lesson}
            >
              <div ref={lessonRef} className={style.cardSubtitle}>
                {data.lesson_title}
              </div>
            </Tooltip>
          )}
        </div>

        {!isStudent && data.included_in && (
          !hideIncludedIns.includes(data.included_in) && (
            <div className={style.included_in}>
              Go to&nbsp;
              {data.included_in_link ? (
                <a
                  href={data.included_in_link}
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.included_in}
                  &nbsp;&nbsp;
                  <img src={FullscreenIcon} alt="Link" />
                </a>
              ) : (
                data.included_in
              )}
            </div>
          )
        )}

        {showPublish && (
          <button
            type="button"
            className={`btn btn--outline-purple ${style.publishButton}`}
            onClick={handlePublishClick}
          >
            {data.published ? (
              <>
                <Status borderColor={Color.GREEN} checked /> Published
              </>
            ) : (
              <>
                <Status /> Publish
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
