import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';
import Tooltip from 'common/Tooltip';
import { required } from 'common/Forms/Validators';
import PathContext from 'common/Context';

import styles from './InviteModal.module.scss';

export default class InviteModal extends Component {
  static contextType = PathContext;

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { postSubmitMessage: null };
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const data = Object.assign({}, values);
    data.emails = data.emails.split(',').map(email => email.trim());

    Axios
      .post(this.context.teacherInvitationsPath, data)
      .then((response) => {
        this.setState({ postSubmitMessage: "Invitations sent to emails that don't already have an account" });
      })
      .catch(error => console.log(error));
  }

  _renderForm() {
    return (
      <Form
        onSubmit={this.submit}
        render={({
          dirtySinceLastSubmit, handleSubmit, pristine, submitSucceeded
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="emails"
              validate={required}
            >
              {({ input }) => (
                <div>
                  <label htmlFor="staff-emails">
                    <span className="sr-only">
                      Comma-separated list of staff emails
                    </span>
                  </label>

                  <textarea
                    className={styles.emailTextarea}
                    id="staff-emails"
                    placeholder="Enter email addresses separated by commas
                      (e.g. john.doe@example.com, jenny.smith@example.com)"
                    {...input}
                  />
                </div>
              )}
            </Field>

            <div className={styles.flexCenter}>
              <button
                className="btn btn--purple"
                onClick={handleSubmit}
                disabled={pristine || (submitSucceeded && !dirtySinceLastSubmit)}
              >
                Send invitations
              </button>

              {
                submitSucceeded && !dirtySinceLastSubmit &&
                <span className="ml15">{this.state.postSubmitMessage}</span>
              }
            </div>
          </form>
        )}
      />
    );
  }

  _renderLinkInformation() {
    return (
      <div className={styles.flexCenter}>
        <a href={this.context.signUpUrl}>{this.context.signUpUrl}</a>
        <Tooltip
          content="Copied to clipboard!"
          trigger="click"
        >
          <button
            className="ml30 btn btn--sm btn--outline-purple js-clipboard-trigger"
            data-clipboard-text={this.context.signUpUrl}
          >
            Copy link
          </button>
        </Tooltip>
      </div>
    );
  }

  renderEmailForm() {
    if (this.props.userType === 'SupportManager') return null;

    return (
      <>
        <p className="mb15">
          Send staff an email from TCI with sign-up instructions.
        </p>

        {this._renderForm()}

        <p className="mb20 mt20">OR</p>
      </>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        isOpen={this.props.modalOpen}
        headerText="Invite Staff"
      >
        <div>
          {this.renderEmailForm()}

          <p className="mb10">Share this link with your staff:</p>

          {this._renderLinkInformation()}
        </div>
      </Modal>
    );
  }
}
