import React from 'react';
import plural from 'pluralize';
import i18n from '@/modules/i18n';
import styles from '../index.module.scss';

interface QuestionCheckboxFieldProps {
  checked: boolean;
  handleClick: () => void;
  id: string;
  question: {
    question_body: string;
    question_display_position: string;
    question_position: number;
    question_type: string;
    section_count: number;
    slide_count: number;
  }
}

const QuestionCheckboxField = ({
  checked,
  handleClick,
  id,
  question,
}: QuestionCheckboxFieldProps) => {
  const iconClass = () => {
    switch (question.question_type) {
      case 'PromptOnly': return 'fa fa-info';
      case 'ImageMultipleChoiceQuestion': return 'fa fa-th-large fa-fw';
      case 'MultipleChoiceQuestion': return 'fa fa-list-ul';
      case 'OpenEndedQuestion': return 'fa fa-pencil';
      case 'GridQuestion': return 'fa fa-table';
      case 'DrawingQuestion': return 'fa fa-photo';
      case 'SliderQuestion': return `question-custom-icon icon-slider-question ${styles.sliderQuestionIcon}`;
      case 'FillInTheBlankQuestion': return 'fa fa-question';
      case 'DragAndDropQuestion': return 'fa fa-arrows';
      case 'section_intro': return 'fa fa-file';
      default: return null;
    }
  };

  const numberedQuestion = `${question.question_display_position || ''}${question.question_body}`;

  return (
    <div className={styles.questionGroupCheckboxContainer}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={handleClick}
        />
        <div className={styles.iconContainer}>
          <i className={`${iconClass()}`} />
        </div>
        <div className="tw-flex tw-items-baseline tw-gap-1">
          <p dangerouslySetInnerHTML={{ __html: numberedQuestion }} />
          {question.slide_count > 0 && (
            <p className="tw-italic">
              {`${question.slide_count} ${plural(i18n.t('slide'), question.slide_count)}`.toLowerCase()}
              {question.section_count > 0 && ','}
            </p>
          )}
          {question.section_count > 0 && (
            <p className="tw-italic">
              {`${question.section_count} ${plural(i18n.t('reading_section'), question.section_count)}`.toLowerCase()}
            </p>
          )}
        </div>
      </label>
    </div>
  );
};

export default QuestionCheckboxField;
