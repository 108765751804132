import React, { Fragment } from 'react';
import I18n from 'i18n-js';
import PropTypes, { oneOfType } from 'prop-types';
import * as Routes from 'modules/routes';
import { gql, useQuery } from '@apollo/client';
import styles from './NavigationCard.module.scss';
import Spinner from '../Spinner';

export const GET_UNITS = gql`
  query UnitOverviewPage($programId: ID, $unitId: ID) {
    program(id: $programId) {
      units(id: $unitId) {
        unitNotebooks {
          id,
          unitOpenerCloser,
          title,
          sectionId,
        }
      }
    }
  }
`;

const isSocialStudiesStories = (notebook) => notebook.unitOpenerCloser === 'social_studies_stories';

export const notebookDisplayTitle = (notebook) => {
  const { title } = notebook;
  if (!isSocialStudiesStories(notebook)) return title;

  const regexp = new RegExp(`^(${I18n.t('unit_social_studies_stories')}) ?[-:] `, 'i');
  return title.replace(regexp, '');
};

export const NotebookLinks = ({ programId, unitId }) => {
  const { loading, data } = useQuery(GET_UNITS, {
    fetchPolicy: 'network-only',
    variables: { programId, unitId }
  });

  if (loading) return <Spinner />;

  const unit = data.program.units[0];
  const notebooks = unit.unitNotebooks;

  const openers = notebooks.filter(notebook => notebook.unitOpenerCloser === 'opener');
  const closers = notebooks.filter(notebook => notebook.unitOpenerCloser === 'closer');
  const socialStudiesStories = notebooks.filter(isSocialStudiesStories);

  // Returns either openers or closers for unit based on given array.
  const unitNotebooks = notebooksArr => (
    notebooksArr.map((notebook) => {
      const href = Routes.stream_shared_program_investigations_path(programId, {
        expand: notebook.id,
        unit_id: unitId,
        unit_projects: true
      });

      return (
        <a
          key={notebook.id}
          className={styles.link}
          href={href}
          dangerouslySetInnerHTML={{ __html: notebookDisplayTitle(notebook) }}
        />
      );
    })
  );

  const renderLinks = () => {
    // If all arrays are empty, return path to Unit Projects page
    if ([openers, closers, socialStudiesStories].every(group => group.length === 0)) {
      return (
        <a
          className={styles.defaultLink}
          href={Routes.stream_shared_program_investigations_path(programId, { unit_id: unitId, unit_projects: true })}
        >
          Go To Unit Projects
        </a>
      );
    }

    return (
      <div className={styles.unitProjectLinksWrapper}>
        {[[openers, 'unit_openers'],
          [closers, 'unit_closers'],
          [socialStudiesStories, 'unit_social_studies_stories']].map(([notebookGroup, translation]) => {

          return (
            <div key={translation} className={styles.notebookSection}>
              {notebookGroup.length > 0 ? <b>{I18n.t(translation)}</b> : ''}
              {unitNotebooks(notebookGroup)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Fragment>
      {renderLinks()}
    </Fragment>
  );
};

NotebookLinks.defaultProps = {
  unitId: ''
};

NotebookLinks.propTypes = {
  programId: PropTypes.number.isRequired,
  unitId: oneOfType([PropTypes.string, PropTypes.number])
};

export default NotebookLinks;
