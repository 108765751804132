import React from 'react';
import PropTypes from 'prop-types';
import { useScript } from '../Utils';
import Tooltip from '../Tooltip';

const ShareToGoogleClassroom = ({ ...props }) => {
  useScript('https://apis.google.com/js/platform.js');

  return (
    <Tooltip
      content="Share to Google Classroom"
      size="medium"
      flip={false}
      distance={25}
      interactive
    >
      <span className={`share-to-google-classroom ${props.className}`}>
        <div className="g-sharetoclassroom" data-size="32" data-url={props.url} />
      </span>
    </Tooltip>
  );
};

ShareToGoogleClassroom.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired
};

ShareToGoogleClassroom.defaultProps = {
  className: ''
};

export default ShareToGoogleClassroom;
