import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import { AudioPlayerProvider } from 'react-use-audio-player';
import './froala.scss';
import useHtmlElementReplacer from 'common/HtmlElements/useHtmlElementReplacer';
import { autoplayMediaSetting } from '@/components/admin/SlideShow/PresentMode/Utils';
import { addPresentListeners, setSpanishAudioSrc } from './CustomFroalaCommands/FroalaListeners';
import styles from '../../Slide.module.scss';
import { useModalManager } from '../../../../../common/Modal';
import SectionModal from './SectionModal';
import { getUrlParams } from '../../../../../common/Utils';
import audioPlayerEffects from './CustomFroalaCommands/CustomAudio/audioEffects';
import { slideObjectPropTypes } from '../../../Utils';
import ParsedFroalaHtml from '../../../../../shared/ParsedFroalaHtml';
import ErrorBoundary from '../../../../../shared/Error/ErrorBoundary';
import useSlideShowContext from '../../../stores/useSlideShowContext';

const SlideObjectTextPresentWrapper = ({ content, jumpToSlide, slideObject, thumbnail }) => (
  <AudioPlayerProvider>
    <SlideObjectTextPresent
      content={content}
      jumpToSlide={jumpToSlide}
      slideObject={slideObject}
      thumbnail={thumbnail}
    />
  </AudioPlayerProvider>
);

SlideObjectTextPresentWrapper.propTypes = {
  content: PropTypes.string.isRequired,
  jumpToSlide: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes,
  thumbnail: PropTypes.bool.isRequired,
};

const SlideObjectTextPresent = ({ content, jumpToSlide, slideObject, thumbnail }) => {
  const presentRef = useRef(null);
  const modalManager = useModalManager();
  const [sectionIds, setSectionIds] = useState([]);
  const [audioSrc, setAudioSrc] = useState('');
  const [audioCommand, setAudioCommand] = useState('play');
  const [hasErrorParsing, setHasErrorParsing] = useState(false);
  let programId = getUrlParams('program_id')['program_id'];
  programId = programId && programId.replace('#', '');
  const isPresentMode = useSlideShowContext(state => state.isPresentMode);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slides = useSlideShowContext(state => state.slides);

  // create the audio player object and attach the useEffects
  audioPlayerEffects({ audioCommand, audioSrc });

  // Close modals when switching slides
  useEffect(() => {
    modalManager.close();
  }, [currentSlide]);

  useEffect(() => {
    if (thumbnail || !presentRef.current) return;

    // we render the present mode on the builder for text areas that are not editable
    // we don't want any button listeners when a user is unable to edit, and we don't want `cursor: pointer`
    if (isPresentMode) {
      addPresentListeners(
        $(presentRef.current), jumpToSlide, modalManager, setSectionIds, setAudioSrc, setAudioCommand, slides, I18n.locale
      );
    } else {
      $(presentRef.current)
        .find('.hint-icon, .tooltip-btn, .reading-btn, .video-btn, .question-btn, .jump-btn')
        .addClass(styles.defaultCursor);
    }
  }, []);

  useEffect(() => {
    setSpanishAudioSrc({ froalaRef: $(presentRef.current), locale: I18n.locale });
  }, [currentSlide, I18n.locale, presentRef, presentRef?.current]);

  useHtmlElementReplacer({
    containerRef: presentRef,
    htmlElements: slideObject.htmlElements,
    skip: !isPresentMode,
  });

  const { modelVideo } = slideObject;
  const { autoplay } = (modelVideo || {});

  return (
    <Fragment>
      {!hasErrorParsing ? (
        <ErrorBoundary
          fallback={<span>Something went wrong.</span>}
          onErrorCallback={() => setHasErrorParsing(true)}
        >
          <div className={`${styles.textEditContainer} fr-view`} ref={presentRef}>
            <ParsedFroalaHtml
              html={content}
              shouldAutoPlayVideo={autoplay && autoplayMediaSetting(slideShowId)}
              shouldParse={isPresentMode}
            />
          </div>
        </ErrorBoundary>
      ) : (
        <div
          className={`${styles.textEditContainer} fr-view`}
          dangerouslySetInnerHTML={{ __html: content }}
          ref={presentRef}
        />
      )}
      {sectionIds.length > 0 && (
        <SectionModal
          content={content}
          programId={programId}
          sectionIds={sectionIds}
          modalManager={modalManager}
        />
      )}
    </Fragment>
  );
};

SlideObjectTextPresent.propTypes = {
  content: PropTypes.string.isRequired,
  jumpToSlide: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes,
  thumbnail: PropTypes.bool.isRequired,
};

export default SlideObjectTextPresentWrapper;
