import React from 'react';
import PropTypes from 'prop-types';
import check from 'images/rosteringSetup/check.svg';
import exclamationMarkCircle from 'images/rosteringSetup/exclamationMarkCircle.svg';
import puzzlePiece from 'images/rosteringSetup/puzzlePieceExclamation.svg';
import configurationImg from 'images/rosteringSetup/configurationImgExclamation.svg';
import contactIcon from 'images/rosteringSetup/contactIconExclamation.svg';
import classesIcon from 'images/rosteringSetup/classesIconExclamation.svg';
import puzzlePieceCompleted from 'images/rosteringSetup/puzzlePieceCompleted.svg';
import configurationImgCompleted from 'images/rosteringSetup/configurationImgCompleted.svg';
import contactIconCompleted from 'images/rosteringSetup/contactIconCompleted.svg';
import classesIconCompleted from 'images/rosteringSetup/classesIconCompleted.svg';
import keyLogo from 'images/ssoSetup/keyLogo.svg';
import keyLogoCheck from 'images/ssoSetup/keyLogoCheck.svg';
import methodCheck from 'images/ssoSetup/methodCheck.svg';

import { formatSelectOption } from '../../../common/Forms/Utils';
import * as constants from './steps';
import Tooltip from '../../../common/Tooltip';

import rosteringCardStyles from './RosteringCard.module.scss';
import completedCardStyles from './RosterCard/CompletedCard.module.scss';
import styles from './RosterCard/PartialCard.module.scss';
import SchedulePause from './RosterCard/SchedulePause';

/**
 * Return dropdown objects for username source based on rostering method.
 * @param method String
 */
export function usernameSources(method) {
  if (method === 'clever') {
    return [
      formatSelectOption('district_username'),
      formatSelectOption('email'),
      formatSelectOption('sis_id')
    ];
  }

  if (method === 'oneroster') {
    return [
      formatSelectOption('username'),
      formatSelectOption('email'),
      formatSelectOption('sourcedId')
    ];
  }

  return null;
}

export const rosterHeaderImages = {
  [constants.ROSTER_METHOD_STEP]: {
    completed: puzzlePieceCompleted,
    pending: puzzlePiece
  },
  [constants.CONFIGURATION_STEP]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.CONTACT_STEP_NON_CSV]: {
    completed: contactIconCompleted,
    pending: contactIcon
  },
  [constants.MAP_COURSES_STEP]: {
    completed: classesIconCompleted,
    pending: classesIcon
  },
  [constants.FINAL_STEP]: {
    completed: check,
    pending: exclamationMarkCircle
  },
};

export const rosterHeaderImagesCsv = {
  [constants.ROSTER_METHOD_STEP]: {
    completed: puzzlePieceCompleted,
    pending: puzzlePiece
  },
  [constants.CONFIGURATION_STEP]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.DISTRICT_CONFIG_STEP]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.CONTACT_STEP_CSV]: {
    completed: contactIconCompleted,
    pending: contactIcon
  },
  [constants.FINAL_STEP]: {
    completed: check,
    pending: exclamationMarkCircle
  },
};

export const ssoHeaderImagesLti = {
  [constants.METHOD_STEP]: {
    completed: keyLogoCheck,
    pending: keyLogo
  },
  [constants.LTI_CONFIGURATION_STEP]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.LTI_PREFERENCES_STEP]: {
    completed: contactIconCompleted,
    pending: contactIcon
  }
};

export const ssoHeaderImagesSaml = {
  [constants.METHOD_STEP]: {
    completed: keyLogoCheck,
    pending: keyLogo
  },
  [constants.SAML_CONFIGURATION_STEP_1]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.SAML_CONFIGURATION_STEP_2]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.SAML_PREFERENCES_STEP]: {
    completed: contactIconCompleted,
    pending: contactIcon
  }
};

export const ltiHeaderImages = {
  [constants.METHOD]: {
    completed: methodCheck,
    pending: methodCheck
  },
  [constants.CONFIG]: {
    completed: configurationImgCompleted,
    pending: configurationImg
  },
  [constants.TEACHER_INFO]: {
    completed: contactIconCompleted,
    pending: contactIcon
  }
};

export function getStepImage(images, isStepCompleted, page) {
  if (isStepCompleted) {
    return images[page].completed;
  }

  return images[page].pending;
}

export const SrOnlyStepStatus = ({ isStepCompleted }) => {
  if (isStepCompleted) {
    return (<span className="sr-only">step completed</span>);
  }

  return (<span className="sr-only">step incomplete</span>);
};

SrOnlyStepStatus.propTypes = {
  isStepCompleted: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])
};

SrOnlyStepStatus.defaultProps = {
  isStepCompleted: false
};

export const VerticalDottedLine = () => (
  <div className={styles.step}>
    <div className={`${styles.dottedLine} ml20`} />
  </div>
);

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function renderStartButton(disabled, onClick, label) {
  return (
    <button
      type="button"
      className="btn btn--block btn--purple"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export function renderDisabledStartButton(label) {
  return (
    <Tooltip
      content="Only administrators can modify integrations."
    >
      <div>
        {renderStartButton(true, null, label)}
      </div>
    </Tooltip>
  );
}

export const CardCTA = ({ enabled, onClick, started }) => {
  const label = started ? 'Continue Rostering Setup' : 'Start Rostering Setup';

  if (enabled) {
    return renderStartButton(false, onClick, label);
  }

  return renderDisabledStartButton(label);
};

export function isSamlConfigComplete(ssoModel) {
  return isSamlConfigStepOneComplete(ssoModel) && isSamlConfigStepTwoComplete(ssoModel);
}

export function isSamlConfigStepOneComplete(ssoModel) {
  return (
    ssoModel &&
    ssoModel.model_type === 'SamlSetting' &&
    ssoModel.model.idp_entity_id &&
    ssoModel.model.idp_sso_target_url &&
    ssoModel.model.idp_cert &&
    ssoModel.model.name_identifier_format
  );
}

export function isSamlConfigStepTwoComplete(ssoModel) {
  return (
    ssoModel &&
    ssoModel.model_type === 'SamlSetting' &&
    ssoModel.instructions_seen
  );
}

export function isLtiConfigComplete(ssoModel) {
  return (
    ssoModel &&
    ssoModel.model_type === 'LtiKey' &&
    ssoModel.instructions_seen &&
    ssoModel.model.consumer_key &&
    ssoModel.model.secret
  );
}

export function isConfigComplete(ssoModel) {
  return isSamlConfigComplete(ssoModel) || isLtiConfigComplete(ssoModel);
}

export function percentComplete(steps) {
  if (!steps || !Object.keys(steps).length) return 0;
  const stepsComplete = Object.values(steps).reduce((acc, current) => acc + !!current, 0);
  return parseInt(100 * stepsComplete / Object.values(steps).length, 10);
}

export function isPreferencesStepComplete(ssoModel) {
  return (
    ssoModel &&
    ssoModel.sign_in_preferences &&
    Object.keys(ssoModel.sign_in_preferences).length > 0
  );
}

export function getCardClass(isComplete) {
  const classes = `card ${rosteringCardStyles.card}`;

  return isComplete ? `${classes} ${rosteringCardStyles.completedCard}` : classes;
}

// dimensions of inputs:
// headers: N x M elements where N = num tables, N = num columns per table
// content: N cols of [P x M elements] where  N = num tables, P = number rows per table, M = num columns per table
//  Ex: header = [["Table 1 Head 1", "Table 1 Head 2""],["Table 2 Head 1", "Table 2 Head 2"]
//      contents = [[["Table 1 Row 1 Col 1", "Table 1 Row 1 Col 2"], ["Table 1 Row 2 Col 1", "Table 1 Row 2 Col 2"]],
//                  [[["Table 2 Row 1 Col 1", "Table 2 Row 1 Col 2"]]]
export const CompletedCardTable = ({
  cardHeader, headers, contents, actionButtons, closePauseModal, openPauseModal, handlePauseSubmit, pauseModalIsOpen,
  pauseStartDate, pauseStopDate, editing
}) => {
  const renderTable = (headerRow, contentsRows, index) => (
    <table key={index} className={completedCardStyles.table}>
      <thead>
        <tr align="left">
          {headerRow.map(text => (
            <th key={text}>
              {text}
              {text && ':'}

              {text === 'Scheduled Pause' && (
                <SchedulePause
                  close={closePauseModal}
                  handlePauseSubmit={handlePauseSubmit}
                  pauseStartDate={pauseStartDate}
                  pauseStopDate={pauseStopDate}
                  isOpen={pauseModalIsOpen}
                  open={openPauseModal}
                  editing={editing}
                />
              )}
            </th>
          ))
          }
        </tr>
      </thead>
      <tbody>
        {contentsRows.map(contentRow => (
          <tr key={contentRow}>
            {contentRow.map(text => (<td className="pt10" key={text}>{text}</td>))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderTables = () => (
    headers.map((headerRow, index) => renderTable(headerRow, contents[index], index))
  );

  return (
    <div>
      <h2
        className={`${rosteringCardStyles.displayFlex} ${completedCardStyles.header}`}
      >
        <img alt="" className={styles.headerImage} src={check} />
        <span className="ml10">{cardHeader}</span>
      </h2>

      <hr className={rosteringCardStyles.hr} />
      {actionButtons}
      {renderTables()}
    </div>
  );
};

CompletedCardTable.propTypes = {
  actionButtons: PropTypes.element,
  cardHeader: PropTypes.string.isRequired,
  closePauseModal: PropTypes.func,
  contents: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])))
  ).isRequired,
  editing: PropTypes.bool,
  handlePauseSubmit: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  openPauseModal: PropTypes.func,
  pauseModalIsOpen: PropTypes.bool,
  pauseStartDate: PropTypes.string,
  pauseStopDate: PropTypes.string
};

CompletedCardTable.defaultProps = {
  actionButtons: null,
  closePauseModal: () => {},
  editing: false,
  handlePauseSubmit: () => {},
  openPauseModal: () => {},
  pauseModalIsOpen: false,
  pauseStartDate: undefined,
  pauseStopDate: undefined
};

export function Steps({
  wrapperClass, divider, renderStep, steps, labels
}) {
  function renderStepAndDivider(step, label, lastStep) {
    if (lastStep) {
      return renderStep(step, label);
    }
    return [renderStep(step, label), divider];
  }

  function renderSteps() {
    return steps.map((step, index) => renderStepAndDivider(step, labels[index], index === steps.length - 1));
  }

  return (
    <div className={wrapperClass}>
      {renderSteps()}
    </div>
  );
}

Steps.propTypes = {
  divider: PropTypes.element.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  wrapperClass: PropTypes.string.isRequired
};

export function copyTextButton(text) {
  return (
    <Tooltip
      content="Copied to clipboard!"
      trigger="click"
    >
      <button
        type="button"
        className="ml20 btn btn--sm btn--outline-purple js-clipboard-trigger"
        data-clipboard-text={text}
      >
        Copy
      </button>
    </Tooltip>
  );
}

export function isSaml(ssoModel) {
  return !!ssoModel && ssoModel.model_type === 'SamlSetting';
}

export function completedSSOSteps(ssoModel) {
  if (isSaml(ssoModel)) {
    return {
      [constants.METHOD_STEP]: (ssoModel && ssoModel.model_type),
      [constants.SAML_CONFIGURATION_STEP_1]: isSamlConfigStepOneComplete(ssoModel),
      [constants.SAML_CONFIGURATION_STEP_2]: isSamlConfigStepTwoComplete(ssoModel),
    };
  }

  return {
    [constants.METHOD_STEP]: (ssoModel && ssoModel.model_type),
    [constants.LTI_CONFIGURATION_STEP]: isLtiConfigComplete(ssoModel),
    [constants.LTI_PREFERENCES_STEP]: isPreferencesStepComplete(ssoModel)
  };
}
