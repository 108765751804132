import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { plato_api_slide_object_three_d_models_path } from '@/modules/routes';
import IThreeDModel from '@/components/interfaces/ThreeDModel';
import QueryKeys from '@/utils/QueryKeys';

interface UseThreeDModelQuery extends Omit<UseQueryOptions<IThreeDModel | null>, 'enabled' | 'queryFn' | 'queryKey'> {
  slideObjectId: number | string;
}

interface ApiResponse {
  data: IThreeDModel[];
}

const useThreeDModel = ({ slideObjectId, ...rest }: UseThreeDModelQuery) => (
  useQuery({
    ...rest,
    enabled: !!slideObjectId,
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_slide_object_three_d_models_path(slideObjectId))
        .then((res) => {
          if (res.data.data.length === 0) return null;

          return res.data.data[0];
        })
    ),
    queryKey: [QueryKeys.UseThreeDModel, slideObjectId],
  })
);

export default useThreeDModel;
