import React from 'react';
import Tooltip from 'components/common/Tooltip';
import styles from './CorrelationsTags.module.scss';

export type Tag = {
  alias: string;
  category: string;
  created_at: string;
  description_en: string;
  description_es: string;
  id: number;
  name_en: string;
  name_es: string | null;
  subcategory: string;
  updated_at: string;
  value_en: string;
  value_es: string | null;
};

type Props = {
  linkHref?: string;
  locale?: 'en' | 'es';
  tag: Tag;
  appendText?: boolean;
};

type TooltipContentProps = Omit<Props, 'appendText'>;

const TooltipContent = ({ locale = 'en', tag }: TooltipContentProps) => {
  let description = tag.description_en;
  if (locale === 'es' && tag.description_es) {
    description = tag.description_es;
  }

  return <div>{description}</div>;
};

const CorrelationTag = ({ appendText = false, linkHref = '#', locale = 'en', tag }: Props) => (
  <Tooltip content={<TooltipContent locale={locale} tag={tag} />} key={tag.id}>
    <>
      <a className={styles.tag} href={linkHref}>{`${tag.alias}${appendText ? ', ' : ''}`}</a>
      {' '}
    </>
  </Tooltip>
);

export default CorrelationTag;
