import React from 'react';
import { inputId } from "../../admin/materials/Utils";
import Row from './Row';
import { capitalizeWords } from "TCIUtils";

export function FormInput(props) {
  const { id, newRecord, label,
      stateName, inputType, required,
      onChange, accept, step, afterLabel } = props,
    InputType = props.InputType || 'input';

  let value = props.value;
  if (typeof props.value === 'number') value = props.value;
  else if (inputType === 'file') value = undefined;
  else value = props.value || '';

  return (
    <Row>
      <label className="mt5" htmlFor={inputId({ label, newRecord, id })}>
        {capitalizeWords(label)}
        {afterLabel && ` (${afterLabel})`}
      </label>
      <InputType required={required} type={inputType}
                 id={inputId({ label, newRecord, id })}
                 value={value} step={step} accept={accept}
                 onChange={(e) => onChange(e, (stateName || label))}>
      </InputType>
    </Row>

  );
}

FormInput.defaultProps = {
  newRecord: false,
  required: false,
  accept: null,
  onChange: null,
  inputType: 'text',
  afterLabel: null
};
