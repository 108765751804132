import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager, Footer } from 'common/Modal';
import { initClipboard } from 'ClipboardButton';
import shareIcon from 'images/icons/share.png';
import ShareToGoogleClassroom from '../ShareToGoogleClassroom';
import Tooltip from '../Tooltip';
import CopyToClipboardField from './CopyToClipboardField';
import styles from './index.module.scss';

const LmsShare = ({ isTemporary, ...props }) => {
  const modalManager = useModalManager();

  useEffect(initClipboard, []);

  const googleClassroomField = (
    <div className={styles.row}>
      <p className={styles.fieldContainer}>1. Share to Google Classroom</p>
      <ShareToGoogleClassroom className={styles.shareToGoogleButton} url={props.googleClassroomUrl} />
    </div>
  );

  const shareableLinkField = (
    <CopyToClipboardField
      id="share-link"
      label="2. Shareable Link"
      copyText={props.shareableResourceUrl}
    />
  );

  const ltiLinkField = (
    <CopyToClipboardField
      id="lti-link"
      label="3. LTI Link"
      copyText={props.ltiResourceUrl}
    />
  );

  return (
    <React.Fragment>
      <Tooltip
        content={isTemporary ? I18n.t('no_access_to_lesson') : "Share"}
        size="medium"
        distance="15"
        interactive={false}
        placement="bottom"
      >
        <button
          type="button"
          className={`${styles.icon} ${isTemporary && styles.disabled}`}
          disabled={isTemporary}
          onClick={isTemporary ? undefined : modalManager.open}
          aria-haspopup="dialog"
          aria-controls="shareModal"
          aria-label="Share"
        >
          <img className={styles.shareIcon} src={shareIcon} alt="" />
          <span className="sr-only">Sharing Options</span>
        </button>
      </Tooltip>

      <Modal
        id="shareModal"
        role="dialog"
        aria-modal="true"
        aria-labelledby="Sharing Options"
        headerText="Sharing Options"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        className={styles.lmsShareModal}
      >
        <div className={styles.lmsShareModalBody}>
          {googleClassroomField}
          {shareableLinkField}
          {props.ltiResourceUrl && ltiLinkField}
        </div>

        <Footer
          primaryButtonText="Done"
          primaryButtonCallback={modalManager.close}
          primaryButtonSubmit={false}
          secondaryButtonCallback={modalManager.close}
          secondaryButtonText="Cancel"
        />
      </Modal>
    </React.Fragment>
  );
};

LmsShare.propTypes = {
  googleClassroomUrl: PropTypes.string,
  ltiResourceUrl: PropTypes.string,
  shareableResourceUrl: PropTypes.string,
  isTemporary: PropTypes.bool
};

LmsShare.defaultProps = {
  googleClassroomUrl: null,
  ltiResourceUrl: null,
  shareableResourceUrl: null,
  isTemporary: false
};

export default LmsShare;
