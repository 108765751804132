import React, { Component } from 'react';
import Form from './Form';
import Axios from 'axios';
import { DeleteButton, ModalButton } from "../Utils";

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    note: '',
    imageUrl: '',
    image: null,
    canDelete: true,
    modalIsOpen: false,
    confirmationModalIsOpen: false,
    isDeleting: false
  };

  render() {
    const { title, note, imageUrl, canDelete, confirmationModalIsOpen } = this.state;
    return (
      <tr>
        <td className="image"><img src={imageUrl} /></td>
        <td>{title}</td>
        <td>{note}</td>
        <td className="actions">
          <ModalButton buttonText="Edit"
                       buttonClass={'action'}
                       modalHeader="Edit Safety Note"
                       modalClass="edit-safety-note"
                       openModal={this.openModal.bind(this)}
                       closeModal={this.closeModal.bind(this)}
                       onUpdate={this.updateSafetyNote.bind(this)}
                       FormComponent={Form}
                       {...this.state} />
          <DeleteButton confirmationModalIsOpen={confirmationModalIsOpen}
                        canDelete={canDelete}
                        openConfirmationModal={this.openConfirmationModal.bind(this)}
                        closeConfirmationModal={this.closeConfirmationModal.bind(this)}
                        handleDelete={this.handleDelete.bind(this)}
                        isDeleting={this.state.isDeleting} />
        </td>
      </tr>
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openConfirmationModal(e) {
    e.preventDefault();
    if (this.state.canDelete) this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/safety_notes/${this.props.id}`).then(response => {
      response.data ? this.handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    });
  }

  updateSafetyNote(safetyNote) {
    this.setState(safetyNote);
    this.props.updateRow(safetyNote);
  }
}
