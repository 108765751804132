import React, { useState } from 'react';
import { IText } from 'fabric';
import useDrawingContext from '../../store/useDrawingContext';
import { handleTextSizeChange } from '../actions';
import I18n from 'i18n-js'

const TextSizeChange = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const [fontSize, setFontSize] = useState((canvas?.getActiveObject() as IText)?.fontSize);

  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-ml-1.5">
      <label className="tw-text-base tw-font-tci tw-py-1">{I18n.t('text_size')}
        <input
          className="tw-text-base tw-font-tci tw-bg-transparent tw-mx-2 hover:tw-bg-gray-100 tw-cursor-pointer tw-rounded-sm
                tw-transition-colors tw-w-5 tw-text-center tw-border-2 tw-border-solid tw-border-slate-300"
          type="number"
          defaultValue={parseFloat(fontSize.toString())}
          onChange={(e) => {
            const next = parseFloat(e.target.value);

            setFontSize(next);
            handleTextSizeChange(canvas, next);
            canvas.fire('object:modified');
          }}
          name="textSize"
        />
      </label>
    </div>
  );
};

export default TextSizeChange;
