import React from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from 'common/DataTable/PaginatedTable';
import * as Routes from 'routes';
import ReactDOM from 'react-dom';
import RuleBreakdown from './RuleBreakdown';
import ChangeDate from './ChangeDate';
import moment from 'moment';

const ProgramMappingVersionsTable = ({
  isSysadmin, subscriberId, tableHooks, programData, lastSuccessfulSyncTime
}) => {
  const pageSize = 10;

  const {
    resources,
    resetResources,
    addResources,
    doneLoading,
    pagesLoaded,
  } = tableHooks;

  const getUrl = Routes.plato_api_subscriber_program_mapping_versions_path(subscriberId, {
    column: 'columnName',
    includes: [{ subscriber: 'seat_pools' }, 'author'],
    order: 'sortDirection',
    page: 'pageNumber',
    per_page: pageSize
  });

  const formattedResources = resources.map((resource) => {
    const hideEmail = !isSysadmin && resource.author.is_sysadmin;
    const changedBy = hideEmail ? 'TCI' : resource.author.email;

    const program = programData.find(currentProgram => currentProgram.id === resource.current_program_id);
    const programTitle = program && program.title;
    const programCode = program && program.code;

    return {
      ...resource,
      changed_by: changedBy,
      program_code: programCode || '',
      program_name: programTitle || '',
    };
  });

  return (
    <div className="mt80">
      <h2>Recent Changes</h2>
      <PaginatedTable
        getUrl={getUrl}
        columns={['current_rules', 'program_name', 'program_code', 'grade', 'change_type', 'changed_by', 'created_at']}
        resources={formattedResources}
        pageLength={pageSize}
        handleResourcesManually
        addResources={addResources}
        pagesLoaded={pagesLoaded}
        doneLoading={doneLoading}
        resetResources={resetResources}
        columnMapping={[]}
        defaultOrder={[[6, 'desc'], [4, 'asc'], [1, 'asc']]}
        columnDefs={[
          {
            createdCell: (td, cellData, rowData) => {
              ReactDOM.render(
                <RuleBreakdown versionData={rowData} programData={programData} />,
                td
              );
            },
            render: currentRules => currentRules && currentRules.join(' AND '),
            targets: 0,
            title: 'Rule'
          },
          {
            targets: 1,
            width: '200px'
          },
          {
            targets: 2,
            width: '75px'
          },
          {
            targets: 3,
            width: '55px'
          },
          {
            targets: 4,
            width: '60px'
          },
          {
            targets: 5,
            width: '120px'
          },
          {
            createdCell: (td, cellData) => {
              ReactDOM.render(
                <ChangeDate
                  changeDate={cellData}
                  lastSuccessfulSyncTime={lastSuccessfulSyncTime}
                />,
                td
              );
            },
            isDate: true,
            targets: 6,
            title: 'Change Date',
            width: '65px'
          }
        ]}
      />
    </div>
  );
};

ProgramMappingVersionsTable.propTypes = {
  isSysadmin: PropTypes.bool.isRequired,
  lastSuccessfulSyncTime: PropTypes.string,
  programData: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string
  })).isRequired,
  subscriberId: PropTypes.number.isRequired,
  tableHooks: PropTypes.shape({
    addResources: PropTypes.func.isRequired,
    doneLoading: PropTypes.bool.isRequired,
    pagesLoaded: PropTypes.number.isRequired,
    resetResources: PropTypes.func.isRequired,
    resources: PropTypes.array.isRequired
  }).isRequired
};

ProgramMappingVersionsTable.defaultProps = {
  lastSuccessfulSyncTime: null
};

export default ProgramMappingVersionsTable;
