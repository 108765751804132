import React, { Fragment } from 'react';
import useGradeByMethods from '@/components/staffer/NotebookGradeBySwitch/useGradeByMethods';

interface NotebookGradeBySwitchProps {
  afterSubmitCallback?: (res: any) => any;
  className?: string;
  classroomId: number;
  classroomLessonId?: number;
  gradingByLesson?: boolean;
  lessonId: number;
  lessonTitle: string;
}

const NotebookGradeBySwitch = ({
  afterSubmitCallback = () => {},
  classroomId,
  classroomLessonId = null,
  gradingByLesson = true,
  lessonId,
  lessonTitle,
}: NotebookGradeBySwitchProps) => {
  const {
    initialGradingByLesson,
    openGradeByLessonModal,
    openGradeBySectionModal,
    renderModal,
  } = useGradeByMethods({
    classroomLessonId,
    gradingByLesson,
    classroomId,
    afterSubmitCallback,
    lessonId,
    lessonTitle,
  });

  return (
    <Fragment>
      <span>Grade by:</span>

      <label>
        <input
          type="radio"
          id="grade_notebook_by_lesson"
          name="grade_notebook_by"
          checked={initialGradingByLesson}
          onChange={openGradeByLessonModal}
        />
        Lesson
      </label>

      <label>
        <input
          type="radio"
          id="grade_notebook_by_section"
          name="grade_notebook_by"
          checked={!initialGradingByLesson}
          onChange={openGradeBySectionModal}
        />
        Section
      </label>

      {renderModal()}
    </Fragment>
  );
};

export default NotebookGradeBySwitch;
