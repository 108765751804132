import { plato_api_copy_condition_path } from '@/modules/routes';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';
import { Actions, Conditions } from '@/components/admin/SlideShow/stores/useSlideShowStore';

interface UseConditionCopyMutationArgs extends UseMutationOptions {
  id: number | string;
  slideIds: (number | string)[];
}

export type ConditionResponse = {
  action: Actions;
  condition: Conditions;
  id: number;
  slide_id: number;
  slide_ids: number[];
};

const useConditionCopyMutation = ({ id, slideIds, ...rest }: UseConditionCopyMutationArgs) => (
  useMutation({
    mutationFn: () => (
      axios
        .post<{ data: ConditionResponse[] }>(plato_api_copy_condition_path(id), { slide_ids: slideIds })
        .then(res => res.data)
    ),
    mutationKey: ['useConditionCopyMutation', slideIds],
    ...rest,
  })
);

export default useConditionCopyMutation;
