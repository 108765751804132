import React, { useEffect, useRef } from 'react';

interface OuterHtmlProps {
  html: string;
}

/**
 * Sometimes we have HTML and we want to place it in the DOM without having a wrapper div around it.
 * This component takes in an html string and uses a temporary div to place it into the DOM.
 */
const OuterHtml = ({ html }: OuterHtmlProps) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.outerHTML = html;
    }
  }, [html]);

  return <div ref={ref} />;
};

export default OuterHtml;
