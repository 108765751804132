/* eslint-disable react/prop-types */

import React from 'react';
import Dropdown from '@/components/common/Dropdown';
import Tooltip from '@/components/common/Tooltip';
import * as Routes from '@/modules/routes';
import styles from './HelpButton.module.scss';
import FeedbackForm, { IStaffer } from './FeedbackForm';

type HelpButtonProps = {
  helpIconUrl: string;
  programId: string,
  hasPdCourse: boolean;
  staffer: IStaffer;
};

const HelpButton = ({
  helpIconUrl,
  hasPdCourse,
  programId,
  staffer,
}: HelpButtonProps) => {
  const toggleText = () => (
    <Tooltip
      content="Support"
      hideOnClick
      interactive={false}
      placement="bottom"
    >
      <img
        alt="Help icon"
        src={helpIconUrl}
        tabIndex={-1}
      />
    </Tooltip>
  );
  return (
    <Dropdown
      ariaLabel="Help"
      buttonClass={`${styles.helpButton} btn btn--link-white`}
      dropdownPosition="bottom"
      toggleText={toggleText()}
      dropdownClass={styles.dropdownHelpButton}
    >
      <a
        className="dropdown-item purple !tw-text-base"
        href="https://help.teachtci.com/s/"
        target="_blank"
        rel="noopener noreferrer"
      >
          Help
      </a>
      <FeedbackForm
        staffer={staffer}
        className="!tw-text-base !tw-text-tci-purple"
      />
      {hasPdCourse && !!programId && (
      <a
        className="dropdown-item purple !tw-text-base"
        href={Routes.teacher_program_professional_developments_path(programId)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Professional Development
      </a>
      )}
    </Dropdown>
  );
};

export default HelpButton;
