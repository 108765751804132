import React, { Component } from 'react';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import Footer from 'common/Wizard/Footer';
import FileInput from 'common/Forms/FileInput';
import { required } from 'common/Forms/Validators';
import { SubmitError } from 'common/Forms/Utils';

import formStyles from '../../../../RosteringSetupModal/Form.module.scss';
import styles from '../ConfigurationStep.module.scss';
import { samlSettingPropTypes } from '../../../../Proptypes';

export default class ConfigurationStepA extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    next: PropTypes.func,
    ssoModelPath: PropTypes.string.isRequired,
    ssoModel: PropTypes.shape({
      id: PropTypes.number.isRequired,
      model: samlSettingPropTypes
    }).isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired
  };

  static defaultProps = {
    next: () => {}
  };

  static isFormIncomplete(values) {
    return Object.values(values).some(formValue => formValue == null || !formValue.trim());
  }

  static handleResponse(response) {
    const idpCert = response.data.idp_cert.replace(/\r?\n|\r/g, '');
    return Object.assign(response.data, { idp_cert: idpCert });
  }

  static renderInputField(label, id) {
    return (
      <Field
        name={id}
        validate={required}
      >
        {({ input, meta }) => (
          <div className={formStyles.row}>
            <div className={formStyles.fieldContainer}>
              <label
                htmlFor={id}
                className={`${formStyles.label} ${styles.samlLabels}`}
              >
                {label}
              </label>

              <input
                id={id}
                className={formStyles.textInput}
                {...input}
              />
            </div>
            {
              meta.error && meta.touched &&
              <SubmitError error={meta.error} />
            }
          </div>
        )}
      </Field>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      showManualEntry: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.populateFormValues = this.populateFormValues.bind(this);
  }

  handleSubmit(values) {
    const path = this.props.ssoModelPath.replace(':id', this.props.ssoModel.id);
    return (
      Axios
        .put(path, this._getSamlParams(values))
        .then((response) => {
          if (response.data.errors) {
            return {
              [FORM_ERROR]: `There was an error while saving. ${response.data.errors}`
            };
          }
          this.props.next();
          this.props.updateSSOModel(response.data.data);
          return null;
        }).catch(error => (
          { [FORM_ERROR]: `There was a ${error.response.status} error while saving. ${error.response.data.errors}` }
        ))
    );
  }

  handleUpload(e) {
    const file = e[0].target.files[0];
    const formData = new FormData();
    formData.append('0', file);

    return Axios
      .post(this.props.uploadXMLUrl, formData)
      .then(response => response)
      .catch(error => console.log(error));
  }

  populateFormValues(e, state, { changeValue }) {
    this.handleUpload(e)
      .then((response) => {
        const formattedResponse = ConfigurationStepA.handleResponse(response);

        changeValue(state, 'idp_entity_id', () => formattedResponse.idp_entity_id);
        changeValue(state, 'idp_sso_target_url', () => formattedResponse.idp_sso_target_url);
        changeValue(state, 'idp_cert', () => formattedResponse.idp_cert);
        changeValue(state, 'name_identifier_format', () => formattedResponse.name_identifier_format);

        this.handleSubmit(state.formState.values);
      });
  }

  handleRadioChange(showManualEntry) {
    this.setState({ showManualEntry });
  }

  _getSamlParams(values) {
    return {
      sso_model: {
        id: this.props.ssoModel.id,
        model_attributes: {
          id: this.props.ssoModel.model.id,
          ...values
        }
      }
    };
  }

  _initialFormValues() {
    const {
      idp_entity_id, idp_sso_target_url, idp_cert, name_identifier_format
    } = this.props.ssoModel.model;
    return {
      idp_entity_id, idp_sso_target_url, idp_cert, name_identifier_format
    };
  }

  renderRadioButtons() {
    return (
      <div className="mb15">
        <label>
          <input
            type="radio"
            name="showManualEntry"
            onChange={() => this.handleRadioChange(false)}
            defaultChecked
          />
          Upload Metadata XML
        </label>
        <label className="pl55">
          <input
            type="radio"
            name="showManualEntry"
            onChange={() => this.handleRadioChange(true)}
          />
          Input manually
        </label>
      </div>
    );
  }

  render() {
    return (
      <div>
        <p className="mb20">
          Next, provide us your IDP information either by uploading an XML file or inputting the information manually.
          See
          {' '}
          <a
            href="https://www.teachtci.com/set-up-saml"
            target="_blank"
            rel="noreferrer noopener"
          >
            SAML Instructions
          </a>
          {' '}
          for more information.
        </p>
        <Form
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={this._initialFormValues()}
          mutators={{ populateFormValues: this.populateFormValues }}
        >
          {({
            handleSubmit, form, values
          }) => (
            <form onSubmit={handleSubmit}>
              {this.renderRadioButtons()}
              <div className={this.state.showManualEntry ? 'hide' : ''}>
                <FileInput
                  buttonText="Upload XML"
                  id="xml-upload"
                  name="0"
                  accept=".xml"
                  onChange={form.mutators.populateFormValues}
                  buttonType="button"
                />
              </div>
              <div className={this.state.showManualEntry ? '' : 'hide'}>
                {ConfigurationStepA.renderInputField('IdP Entity', 'idp_entity_id', 'input')}
                {ConfigurationStepA.renderInputField('IdP SSO Target URL', 'idp_sso_target_url', 'input')}
                <Field
                  name="idp_cert"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <div className={formStyles.row}>
                      <div className={formStyles.fieldContainer}>
                        <label
                          htmlFor="idp_cert"
                          className={`${formStyles.label} ${styles.samlLabels}`}
                        >
                          IDP Cert
                        </label>
                        <textarea
                          id="idp_cert"
                          className={`${formStyles.textInput} ${styles.textarea} mb20`}
                          {...input}
                        />
                      </div>
                      {
                        meta.error && meta.touched &&
                        <SubmitError error={meta.error} />
                      }
                    </div>
                  )}
                </Field>
                {ConfigurationStepA.renderInputField('Name Identifier Format', 'name_identifier_format', 'input')}
              </div>
              <Footer disabled={ConfigurationStepA.isFormIncomplete(values)} />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
