import React from 'react';
import ActionsDropdown from 'components/common/ActionsDropdown';
import DeleteRule from './DeleteRule';
import { ProgramMappingEditModal } from './ProgramMappingModal';

interface ProgramMappingActionsProps {
  deleteResource: () => void;
  hasRosteredClassrooms: boolean;
  programsWithStudentSeats: number[];
  rowData: {
    id: number;
    subscriber_id: number;
  };
  updateResource: () => void;
  programAccessTypeHash: { [key: string] : string[] }
}

const ProgramMappingActions = ({
  deleteResource, rowData, updateResource, hasRosteredClassrooms, programsWithStudentSeats, programAccessTypeHash,
}: ProgramMappingActionsProps) => (
  <ActionsDropdown>
    <ProgramMappingEditModal
      hasRosteredClassrooms={hasRosteredClassrooms}
      subscriberId={rowData.subscriber_id}
      updateResource={updateResource}
      rowData={rowData}
      programsWithStudentSeats={programsWithStudentSeats}
      programAccessTypeHash={programAccessTypeHash}
    />
    <DeleteRule programMapping={rowData} afterSubmit={deleteResource} />
  </ActionsDropdown>
);

export default ProgramMappingActions;
