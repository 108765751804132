import * as Routes from '@/modules/routes';
import Axios from 'axios';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export const queryKey = (type: string) => ['EdgateCorrelations', type];

export const correlationQuery = ({ lessonId, grade, type }) => (
  Axios.get(Routes.plato_api_correlations_path({
    correlation_type: type,
    format: 'json',
    grade,
    lesson_id: lessonId,
  }))
);
