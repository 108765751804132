import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager, Footer } from 'common/Modal';
import { SubmitError } from 'common/Forms/Utils';
import * as Routes from 'routes';
import axios from 'axios';
import { slideObjectPropTypes } from '../../Utils';
import useSlideShowContext from '../../stores/useSlideShowContext';

const RemoveSlideObject = ({ dropdownEvents, slideObject }) => {
  const modalManager = useModalManager();
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const removeSlideObject = useSlideShowContext(state => state.removeSlideObject);

  const destroySlideObject = () => {
    setSubmitting(true);
    axios.delete(`${Routes.plato_api_slide_objects_path()}/${slideObject.id}`)
      .then((res) => {
        if (!res.data.data.id) {
          setHasError(true);
          return;
        }
        modalManager.close();
        removeSlideObject(slideObject);
      })
      .catch((error) => {
        setHasError(true);
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => setSubmitting(false));
  };

  const confirmationModal = () => (
    <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText="Clear content">
      {hasError && <SubmitError error="Error clearing content. Please reload the page and try again." />}
      Are you sure you want to clear the content for this area?
      <Footer
        primaryButtonCallback={destroySlideObject}
        primaryButtonClassName="btn btn--red"
        primaryButtonText="Yes, Clear"
        primaryButtonSubmit={false}
        secondaryButtonCallback={modalManager.close}
        submitting={submitting}
        submittingText="Clearing..."
      />
    </Modal>
  );

  return (
    <>
      <a
        className="dropdown-item"
        href="#"
        onClick={() => {
          modalManager.open();
          dropdownEvents.setOpen(false);
        }}
        role="button"
      >
        Clear
      </a>
      {confirmationModal()}
    </>
  );
};

RemoveSlideObject.propTypes = {
  dropdownEvents: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
  }).isRequired,
  slideObject: slideObjectPropTypes.isRequired
};

export default RemoveSlideObject;
