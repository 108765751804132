import React from 'react';
import pencilIcon from 'images/icons/pencil-orange-outline.png';
import gameIcon from 'images/icons/lesson-game-yellow-outline.png';
import assessmentsIcon from 'images/icons/assessments-yellow-outline.png';
import styles from './TransitionSlide.module.scss';

interface AssessLinkProps {
  href: string;
  icon: 'game' | 'assess' | 'notebook';
  title: string;
}

const iconMap = {
  game: gameIcon,
  assess: assessmentsIcon,
  notebook: pencilIcon,
};

const AssessLink = ({ href, icon, title }: AssessLinkProps) => (
  <div className={styles.titleLink}>
    <div className={styles.linkBorder}>
      <a
        className="btn btn--link-purple no_pad"
        href={href}
      >
        <img src={iconMap[icon]} alt="" />
        {title}
      </a>
    </div>
  </div>
);

export default AssessLink;
