export const popupCoordinates = (menuRef) => {
  if (!menuRef?.current) return {};

  const rect = menuRef.current.getBoundingClientRect();

  return {
    left: rect.x + rect.width + 10,
    top: rect.y + rect.height + window.scrollY + 10
  };
};
