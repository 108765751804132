import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ handleClick, buttonClass }) {
  return (
    <button className={buttonClass} onClick={handleClick}>
      Add Subscriber
    </button>
  );
}

Button.propTypes = {
  handleClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string
};

Button.defaultProps = {
  buttonClass: ''
};
