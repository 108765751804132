import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from '../../../common/ActionsDropdown';
import SaveToGoogleDrive from '../../../shared/SaveToGoogleDrive';

export default function MaterialsActionsDropdown({
  downloadURL, downloadTitle, id
}) {
  const linkText = '<div class="google-drive action-dropdown"></div>Save to Google Drive';

  return (
    <ActionsDropdown id={id}>
      <a
        href={downloadURL}
        target="_blank"
        role="button"
        className="dropdown-item"
      >
        <i className="fa fa-download" aria-hidden="true" /> Download PDF
      </a>
      <SaveToGoogleDrive
        button={false}
        linkClasses="dropdown-item"
        linkText={linkText}
        fileUrl={downloadURL}
        fileName={downloadTitle}
      />
    </ActionsDropdown>
  );
}

MaterialsActionsDropdown.propTypes = {
  downloadURL: PropTypes.string.isRequired,
  downloadTitle: PropTypes.string.isRequired,
  id: PropTypes.string
};

MaterialsActionsDropdown.defaultProps = {
  id: 'dropdownMenuButton'
};
