import React, { useEffect, useRef, useState } from 'react';
import Anchor, { AnchorRef } from '@/components/ui/Anchor';
import { capitalize } from '@/components/common/Utils';
import cn from '@/utils/cn';
import { EditImageMarginsStore } from './useEditImageMarginsStore';

type Margin = { top: string; right: string; bottom: string; left: string };

const DIRS = ['top', 'bottom', 'left', 'right'] as const;

const EditImageMarginsPopover = ({ editorInstance, id = 'image' }) => {
  const ref = useRef<AnchorRef>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [margins, setMargins] = useState<Margin>({ top: '0', right: '0', bottom: '0', left: '0' });

  const store = EditImageMarginsStore.get(`${editorInstance?.id?.toString()}-${id}`);
  const element = store(state => state.element);
  const setElement = store(state => state.setElement);

  const node = editorInstance?.$box[0];

  useEffect(() => {
    if (!ref.current) return;
    if (!element) return;
    if (!editorInstance.el.contains(element)) return;

    ref.current.open();
  }, [element, ref]);

  useEffect(() => {
    if (!element) return;

    const nextMargin = DIRS.reduce((acc, dir) => {
      const key = `margin-${dir}`;

      const currentValue = element.style[key].replace('px', '');

      return { ...acc, [dir]: currentValue || '0' };
    }, {} as Margin);

    setMargins(nextMargin);
  }, [element]);

  const isValid = (values: Margin) => (
    Object
      .keys(values)
      .every(key => !Number.isNaN(parseFloat(values[key]) || values[key] === ''))
  );

  const updateElement = (values: Margin) => {
    if (!isValid(values)) return;
    if (!element) return;

    Object.keys(values).forEach((key) => {
      const marginKey = `margin-${key}`;

      if (values[key] === '0') element.style[marginKey] = null;
      else element.style[marginKey] = `${values[key] || 0}px`;
    });

    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => editorInstance.undo.saveStep(), 500);
  };

  const setMargin = (dir: keyof Margin, value: string) => {
    setMargins((prev) => {
      const next = { ...prev, [dir]: value };

      updateElement(next);
      return next;
    });
  };

  if (!node) return null;

  return (
    <Anchor
      className="tw-border tw-border-[#eee] tw-rounded tw-shadow-lg tw-border-solid tw-py-2 tw-px-4"
      defaultOpen={false}
      offset={{ left: 8 }}
      onClose={() => setElement(null)}
      position="top-right"
      ref={ref}
      to={node as HTMLElement}
      toggleOnTargetClick={false}
    >
      <h2 className="tw-text-base tw-font-bold tw-p-0 tw-mb-4">Margins</h2>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {(DIRS.map(dir => (
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-6">
            <label className="tw-text-black" htmlFor={`margin-${dir}`}>{capitalize(dir)}</label>
            <div>
              <input
                className={cn(
                  'tw-box-border tw-w-[75px] tw-h-[45px] tw-rounded-md tw-border-gray-400',
                  'tw-border-solid tw-outline-none tw-border tw-px-2 focus:tw-border-info',
                )}
                name={`margin-${dir}`}
                onChange={e => setMargin(dir, e.target.value)}
                type="number"
                value={margins[dir]}
              />
              <span className="tw-ml-2">px</span>
            </div>
          </div>
        )))}
      </div>
    </Anchor>
  );
};

export default EditImageMarginsPopover;
