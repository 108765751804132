import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxOrRadioGroup } from '../Util';

export const textLabel = (scienceProgram) => {
  if (scienceProgram) {
    return 'Text with Notes';
  }

  return 'Reading';
};

export const isMiddleSchool = (schoolLevel) => {
  return schoolLevel === 'Middle School';
};

export const ScienceWithNarration = () => (
  <React.Fragment>
    Set to&nbsp;
    <em>Off</em>
    &nbsp;to hide the text audio feature in Investigation, Text with Notes, Lesson Game, and Assessment. Set to&nbsp;
    <em>Narration</em>
    &nbsp;to play real-voice audio of Text with Notes (subject to availability).
  </React.Fragment>
);

export const SocialStudiesWithNarration = () => (
  <React.Fragment>
    Set to&nbsp;
    <em>Off</em>
    &nbsp;to hide the text audio feature in Notebook, Text, Lesson Game, and Assessment. Set to&nbsp;
    <em>Narration</em>
    &nbsp;to play real-voice audio of Text (subject to availability).
  </React.Fragment>
);

export const ScienceWithoutNarration = () => (
  <React.Fragment>
    Set to&nbsp;
    <em>Off</em>
    &nbsp;to hide the text audio feature in Investigation, Text with Notes, Lesson Game, and Assessment.
  </React.Fragment>
);

export const SocialStudiesWithoutNarration = () => (
  <React.Fragment>
    Set to&nbsp;
    <em>Off</em>
    &nbsp;to hide the text audio feature in Notebook, Text, Lesson Game, and Assessment.
  </React.Fragment>
);

export default function TextReadAloudTooltip({ scienceProgram, narrationEnabled }) {
  if (scienceProgram) {
    return narrationEnabled ? <ScienceWithNarration /> : <ScienceWithoutNarration />;
  }

  return narrationEnabled ? <SocialStudiesWithNarration /> : <SocialStudiesWithoutNarration />;
}

TextReadAloudTooltip.propTypes = {
  narrationEnabled: PropTypes.bool.isRequired,
  scienceProgram: PropTypes.bool.isRequired
};

export const AssessmentResults = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Assessment Results"
    name="student_view_assessment_results_off"
    text="Allow students to see their score and correct answers immediately after submitting an assessment."
    negativeSetting
  />
);
