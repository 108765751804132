import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NotebookResourceButton extends Component {
  static propTypes = {
    audience: PropTypes.string,
    body: PropTypes.string,
    title: PropTypes.string.isRequired
  };

  static defaultProps ={
    audience: 'teacher',
    body: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false
    };

    this.togglePopup = this.togglePopup.bind(this);
  }

  getButtonClassName = () => (
    `model-resource-button btn ${this.props.audience === 'student' ? 'btn--green' : 'btn--orange'}`
  );

  togglePopup() {
    this.setState(prevState => ({ popupOpen: !prevState.popupOpen }));
  }

  renderPopupCloseButton() {
    return (
      <button
        onClick={this.togglePopup}
        className="fa-stack notebook-popup-close"
        type="button"
      >
        <i className="fa fa-circle fa-stack-2x" aria-hidden="true" />
        <i className="fa fa-times-circle fa-stack-1x" aria-hidden="true" />
        <span className="sr-only">Close pop-up</span>
      </button>
    );
  }

  renderPopup() {
    if (this.state.popupOpen) {
      return (
        <div className="model-resource-popup-container notebook">
          <div
            className={`resource-popup notebook-resource ${this.props.audience}`}
            dangerouslySetInnerHTML={{ __html: this.props.body }}
          />
          {this.renderPopupCloseButton()}
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="model-resource-container">
        <button
          className={this.getButtonClassName()}
          onClick={() => this.togglePopup()}
          type="button"
        >
          {this.props.title}
        </button>
        {this.renderPopup()}
      </div>
    );
  }
}
