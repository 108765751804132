import PropTypes from 'prop-types';
import Select from 'react-select';
import React, { Component } from 'react';
import Axios from 'axios';
import { Footer, Spinner } from 'common/Modal';
import { inputId, FormInput } from '../../materials/Utils';
import { usernameSources } from '../../integrations/RosteringOverview/Utils';

export default class Form extends Component {
  static propTypes = {
    client_id: PropTypes.string,
    client_secret: PropTypes.string,
    contact_email: PropTypes.string,
    endpoint_url: PropTypes.string,
    id: PropTypes.number,
    isLoading: PropTypes.bool,
    partial_teacher_update: PropTypes.bool,
    priority: PropTypes.string,
    priorityOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired,
    sftp_export_url: PropTypes.string,
    sftp_import_url: PropTypes.string,
    subscriber_id: PropTypes.number.required,
    subscriber_name: PropTypes.string,
    sync_type: PropTypes.string,
    sync_types: PropTypes.array.required,
    uid: PropTypes.string,
    username_source: PropTypes.string
  };

  static defaultProps = {
    client_id: '',
    client_secret: '',
    contact_email: '',
    enabled: true,
    endpoint_url: '',
    handleUpdate: null,
    id: null,
    isLoading: false,
    partial_teacher_update: false,
    priority: '',
    sync_type: 'tci_csv',
    sftp_export_url: '',
    sftp_import_url: '',
    subscriber_id: null,
    subscriber_name: '',
    sync_types: [],
    uid: '',
    username_source: null
  };

  constructor(props) {
    super(props);
    this.state = props;

    this.provider_hash = {
      clever_api: 'clever',
      oneroster_api: 'oneroster',
      tci_csv: ''
    };

    this.formInputs = {
      clever_api: {
        inputs: [{
          table: 'subscriber',
          stateName: 'uid',
          label: 'App UID'
        },
        {
          table: 'auto_roster_district',
          stateName: 'staff_username_source',
          label: 'Staff Username Source',
          inputType: 'select',
          InputType: Select,
          options: usernameSources('clever')
        },
        {
          table: 'auto_roster_district',
          stateName: 'username_source',
          label: 'Student Username Source',
          InputType: Select,
          inputType: 'select',
          options: usernameSources('clever')
        },
        {
          table: 'auto_roster_district',
          stateName: 'priority',
          label: 'Priority',
          InputType: Select,
          inputType: 'select',
          options: this.props.priorityOptions
        }]
      },
      tci_csv: {
        inputs: [
          {
            table: 'auto_roster_district',
            stateName: 'sftp_import_url',
            label: 'Import From'
          },
          {
            table: 'auto_roster_district',
            stateName: 'sftp_export_url',
            label: 'Export To'
          },
          {
            table: 'auto_roster_district',
            stateName: 'partial_teacher_update',
            label: 'Partial Teacher Update',
            inputType: 'checkbox'
          },

        ]
      },
      oneroster_api: {
        inputs: [
          {
            table: 'subscriber',
            stateName: 'uid',
            label: 'App UID'
          },
          {
            table: 'auto_roster_district',
            stateName: 'client_id',
            label: 'Client ID'
          },
          {
            table: 'auto_roster_district',
            stateName: 'client_secret',
            label: 'Client Secret'
          },
          {
            table: 'auto_roster_district',
            stateName: 'endpoint_url',
            label: 'Endpoint URL'
          },
          {
            table: 'auto_roster_district',
            stateName: 'staff_username_source',
            label: 'Staff Username Source',
            inputType: 'select',
            InputType: Select,
            options: usernameSources('oneroster')
          },
          {
            table: 'auto_roster_district',
            stateName: 'username_source',
            label: 'Student Username Source',
            inputType: 'select',
            InputType: Select,
            options: usernameSources('oneroster')
          },
          {
            table: 'auto_roster_district',
            stateName: 'priority',
            label: 'Priority',
            InputType: Select,
            inputType: 'select',
            options: this.props.priorityOptions
          }
        ]
      }
    };

    // bind functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeForm = this.changeForm.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  _buildFormData() {
    const { contact_email, sync_type, provider, enabled } = this.state;
    const formData = new FormData();
    formData.append('subscriber_id', this.props.subscriber_id);
    formData.append('auto_roster_district[subscriber_id]', this.props.subscriber_id);
    formData.append('auto_roster_district[contact_email]', contact_email == null ? '' : contact_email);
    formData.append('auto_roster_district[sync_type]', sync_type == null ? '' : sync_type);
    formData.append('subscriber[provider]', provider == null ? '' : provider);
    formData.append('auto_roster_district[enabled]', enabled == null ? '' : enabled);

    this.formInputs[this.state.sync_type].inputs.forEach((el) => {
      formData.append(`${el.table}[${el.stateName}]`, this.state[el.stateName] == null ? '' : this.state[el.stateName]);
    });

    return formData;
  }

  changeForm(select) {
    this.setState({ sync_type: select.value });
    this.setState({ provider: this.provider_hash[select.value], username_source: null });
  }

  handleSelect(select, stateName) {
    this.setState({ [stateName]: select.value });
  }

  createForm() {
    return this.formInputs[this.state.sync_type].inputs.map(el =>
      (<FormInput
          key={el.stateName}
          label={el.label}
          stateName={el.stateName}
          inputType={el.inputType}
          InputType={el.InputType}
          id={this.props.id}
          value={this.state[el.stateName]}
          options={el.options}
          onChange={el.inputType !== 'select' ? this.handleChange : this.handleSelect }
          checked={this.state[el.stateName]}
        />
      ));
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });

    // Checkboxes don't change their value when checked/unchecked
    if (e.target.type === 'checkbox') {
      this.setState({
        [stateName]: e.target.checked
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const method = this.props.newRecord ? 'post' : 'put';
    const update_url = `/admin/subscribers/${this.props.subscriber_id}/integrations/${this.props.id}`;
    const new_url = `/admin/subscribers/${this.props.subscriber_id}/integrations`;
    const url = this.props.newRecord ? new_url : update_url;
    const callback = this.props.handleUpdate;
    const data = this._buildFormData();

    Axios({
      method,
      url,
      headers: { 'content-type': 'application/json' },
      data
    })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
          this.setState({
            isLoading: false,
            error: true
          });
        }
        else {
          this.setState({
            isLoading: false,
            newRecord: false
          });
          this.props.closeModal();
          callback(response.data);

          if (this.props.newRecord) window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          error: true
        });
      });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">There was an error. Please reload the page and try again.</div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`safety-notes-form ${this.state.isLoading && 'loading'}`}
      >
        <FormInput
          label="Subscriber"
          stateName="subscriber"
          id={this.props.id}
          value={this.props.subscriber_name}
          readOnly
        />

        <FormInput
          label="Contact Email"
          stateName="contact_email"
          id={this.props.id}
          value={this.state.contact_email}
          onChange={this.handleChange}
        />

        <div className="form-row">
          <label
            className="mt5"
            htmlFor={
              inputId({
                newRecord: this.props.newRecord,
                id: this.props.id
              })}
          >
            Method
          </label>
          <Select
            required
            id={
              inputId({
                newRecord: this.props.newRecord,
                id: this.props.id
              })
            }
            value={this.state.sync_type}
            onChange={this.changeForm}
            options={this.props.sync_types}
          />

        </div>
        {this.createForm()}
        <FormInput
          label="Enabled"
          stateName="enabled"
          inputType="checkbox"
          id={this.props.id}
          value={this.state.enabled}
          onChange={this.handleChange}
          checked={this.state.enabled}
        />

        <Footer secondaryButtonCallback={this.closeModal} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }
}
