export default class GlassPaneSimulation {
  constructor(config) {
    this.container = config.container;
    this.fps = config.fps || 30;
    this.width = 660;
    this.height = 482;
    this.visible_photons = new Map();
    this.infrared_photons = new Map();
    this.frame_count = 0;
    this.number_of_panes = 0;
    this.show_all = false;
    this.BASE_TEMP = -15;
    this.paused = false;
    this.play_speed = 10;
    this.produce_heat = false;
    this.REFLECTION_THRESHOLD = .45;
    this.BASE_URL = config.BASE_URL;
    this.photon_urls = {
      infrared: this.BASE_URL + 'Photon_Infrared.png',
      sunlight: this.BASE_URL + 'Photon_Visible.png'
    };
    this.pane_images = [];
  }

  init() {
    this._build();

    this._setupListeners();

    this.time_marker_prev = Date.now();
    this.interval = 1000/this.fps;

    this.pane_images[0] = new Image();
    this.pane_images[0].src = this.BASE_URL + 'Simulation_GlassLayers_Glass_01.png';

    this.pane_images[1] = new Image();
    this.pane_images[1].src = this.BASE_URL + 'Simulation_GlassLayers_Glass_02.png';

    this.pane_images[2] = new Image();
    this.pane_images[2].src = this.BASE_URL + 'Simulation_GlassLayers_Glass_01.png';

    window.requestAnimationFrame(this.loop.bind(this));
  }

  _build() {
    this.backdrop = document.createElement('div');
    this.backdrop.className = 'simulation-backdrop glass-panes';

    this.canvas = document.createElement('canvas');
    this.canvas.className = 'simulation-canvas';

    this.canvas.height = this.height;
    this.canvas.width = this.width;

    this.menu = document.createElement('div');
    this.menu.className = 'simulation-menu';

    this.legend_header = document.createElement('div');
    this.legend_header.className = 'simulation-submenu-header';
    this.legend_header.innerHTML = 'Legend';
    this.menu.appendChild(this.legend_header);

    this.legend = document.createElement('div');
    this.legend.className = 'simulation-submenu';

    this.visible_photon_legend = document.createElement('div');
    this.visible_photon_legend.className = 'simulation-photon-legend visible';
    this.visible_photon_legend.innerHTML = 'Visible Sunlight';
    this.legend.appendChild(this.visible_photon_legend);

    this.infrared_photon_legend = document.createElement('div');
    this.infrared_photon_legend.className = 'simulation-photon-legend infrared';
    this.infrared_photon_legend.innerHTML = 'Infrared Sunlight';
    this.legend.appendChild(this.infrared_photon_legend);

    this.menu.appendChild(this.legend);

    this.options_header = document.createElement('div');
    this.options_header.className = 'simulation-submenu-header';
    this.options_header.innerHTML = 'Options';
    this.menu.appendChild(this.options_header);

    this.options = document.createElement('div');
    this.options.className = 'simulation-submenu';

    this.panes_label = document.createElement('div');
    this.panes_label.innerHTML = 'Glass panes';
    this.panes_label.className = 'simulation-submenu-panes-label';

    this.panes_selector_wrapper = document.createElement('div');
    this.panes_selector_wrapper.className = 'simulation-panes-select-wrapper';

    this.panes_select = document.createElement('div');
    this.panes_select.className = 'panes-select';
    this.panes_selector_wrapper.appendChild(this.panes_select);

    this.panes_selected_option = document.createElement('span');
    this.panes_selected_option.className = 'panes-selected-option';
    this.panes_selected_option.textContent = '0';
    this.panes_select.appendChild(this.panes_selected_option);

    this.panes_selector = document.createElement('ul');
    this.panes_selector.className = 'select-options';
    this.panes_selector.innerHTML = '<li class="selected" value=0>0</li><li value=1>1</li><li value=2>2</li><li value=3>3</li>';
    this.panes_select.appendChild(this.panes_selector);

    this.options.appendChild(this.panes_label);
    this.options.appendChild(this.panes_selector_wrapper);
    this.menu.appendChild(this.options);

    this.show_all_photons_checkbox = document.createElement('input');
    this.show_all_photons_checkbox.type = 'checkbox';
    this.show_all_photons_checkbox.className = 'simulation-glass-panes-checkbox';
    this.show_all_photons_checkbox.id = Date.now() + 'photons-check';
    this.show_all_photons_checkbox_label = document.createElement('label');
    this.show_all_photons_checkbox_label.innerHTML = 'Show all photons';
    this.show_all_photons_checkbox_label.className = 'simulation-glass-panes-checkbox-label';
    this.show_all_photons_checkbox_label.htmlFor = this.show_all_photons_checkbox.id;

    this.options.appendChild(this.show_all_photons_checkbox);
    this.options.appendChild(this.show_all_photons_checkbox_label);

    this.controls_header = document.createElement('div');
    this.controls_header.className = 'simulation-submenu-header';
    this.controls_header.innerHTML = 'Controls';
    this.menu.appendChild(this.controls_header);

    this.controls = document.createElement('div');
    this.controls.className = 'simulation-submenu';

    this.play_button = document.createElement('div');
    this.play_button.className = 'simulation-play-button pause';

    this.controls.appendChild(this.play_button);

    this.speed_slider_wrapper = document.createElement('div');
    this.speed_slider_wrapper.className = 'simulation-speed-control-wrapper';

    this.speed_slider = document.createElement('input');
    this.speed_slider.type = 'range';
    this.speed_slider.className = 'simulation-speed-control';
    this.speed_slider.min = '1';
    this.speed_slider.max = '10';
    this.speed_slider.value = '10';
    this.speed_slider.step = '1';

    this.controls.appendChild(this.speed_slider_wrapper);
    this.speed_slider_wrapper.appendChild(this.speed_slider);

    this.menu.appendChild(this.controls);
    this.backdrop.appendChild(this.canvas);
    this.container.appendChild(this.backdrop);
    this.container.appendChild(this.menu);

    this.ctx = this.canvas.getContext('2d');

    this.thermometer = new Thermometer({
      container: this.container,
      parent: this
    });

    this.thermometer.build();

  }

  _setupListeners() {
    this._setupPaneSelectListeners();

    $(this.show_all_photons_checkbox).on('change', (e) => {
      this.show_all = e.target.checked;
    });

    $(this.play_button).on('click', () => {
      if (this.paused) {
        this.play();
      }
      else {
        this.pause();
      }
    });

    $(this.speed_slider).on('change', (e) => {
      this.play_speed = parseInt(e.target.value, 10);
    });
  }

  _setupPaneSelectListeners() {
    $(this.panes_select).on('click', (e) => {
      e.stopPropagation();
      $(this.panes_selector).toggle();
    });

    // Handle option selection
    $('.select-options li').on('click', (e) => {
      e.stopPropagation();
      const $target = $(e.currentTarget);
      const value = $target.attr('value');
      const text = $target.text();

      this.number_of_panes = value;
      $(this.panes_selected_option).text(text);
      $target.siblings().removeClass('selected');
      $target.addClass('selected');
      $(this.panes_selector).hide();
    });

    // Close the options when clicking anywhere on the document
    $(document).on('click', (e) => {
      if (this.panes_selector_wrapper.contains(e.target)) return;
      $('.select-options').hide();
    });
  }

  pause() {
    this.play_button.className = 'simulation-play-button play';
    this.paused = true;
  }

  play() {
    this.play_button.className = 'simulation-play-button pause';
    this.paused = false;
    this.loop();
  }

  loop() {
    this.time_marker_now = Date.now();
    this.delta = this.time_marker_now - this.time_marker_prev;

    if (this.delta > this.interval) {
      this.frame_count++;

      this._clearCanvas();

      if (this.frame_count % (5 + 10 - this.play_speed) === 0) {
        let always_visible = (this.frame_count % (15 + 10 - this.play_speed) === 0);
        let id = this.frame_count;

        this.visible_photons.set(id, new VisiblePhoton({
          parent: this,
          pos_x: getRandomInt(this.width),
          pos_y: 0,
          velocity: {x: 0, y: 1},
          always_visible: always_visible
        }));
      }

      if (this.produce_heat && this.frame_count % (2 + 10 - this.play_speed) === 0) {
        let always_visible = (this.frame_count % (6 + 10 - this.play_speed) === 0);
        let id = this.frame_count;

        this.infrared_photons.set(id, new InfraredPhoton({
          parent: this,
          pos_x: getRandomInt(this.width),
          pos_y: this.height * .9,
          velocity: {x: 0, y: -1},
          always_visible: always_visible
        }));
      }

      this._updateObjects();
      this._drawObjects();
      this.time_marker_prev = this.time_marker_now - (this.delta % this.interval);
    }

    if (!this.paused) {
      window.requestAnimationFrame(this.loop.bind(this));
    }
  }

  _clearCanvas() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  _updateObjects() {
    let reflection_points = this.getPaneLocations(this.number_of_panes);

    this.visible_photons.forEach((v, k, m) => {
      if (v.pos_y > this.height) {
        this.produce_heat = true;
        this.visible_photons.delete(k);
      }
      else {
        v.move();
      }
    });

    this.infrared_photons.forEach((v, k, m) => {
      if (v.pos_y < 0) {
        this.infrared_photons.delete(k);
      }
      else {
        v.reflect(reflection_points);
        v.move();
      }
    });

    this.thermometer.setReading(this.calculateTemperature());

    if (this.frame_count % 100 === 0) {
      this.thermometer.updateCylinder();
    }
  }

  _drawObjects() {
    let panes = this.getPaneLocations(3);

    this.infrared_photons.forEach((v, k, m) => {
      if (v.always_visible || this.show_all) {
        v.draw();
      }
    });

    if (this.number_of_panes > 0) {
      this.ctx.drawImage(this.pane_images[0], 0, panes[0], this.width, this.height * .03);
    }

    if (this.number_of_panes > 1) {
      this.ctx.drawImage(this.pane_images[1], 0, panes[1], this.width, this.height * .03);
    }

    if (this.number_of_panes > 2) {
      this.ctx.drawImage(this.pane_images[2], 0, panes[2], this.width, this.height * .03);
    }

    this.visible_photons.forEach((v, k, m) => {
      if (v.always_visible || this.show_all) {
        v.draw();
      }
    });
  }

  resize() {
    this.width = $(this.container).innerWidth();
    this.height = $(this.container).innerHeight();
  }

  calculateTemperature() {
    return this.BASE_TEMP + parseInt((this.infrared_photons.size * .45), 10);
  }

  getPaneLocations(num) {
    let locations;
    switch(parseInt(num, 10)) {
      case 1:
        locations = [this.height - (.3 * this.height)];
        break;
      case 2:
        locations = [this.height - (.3 * this.height), this.height - (.4 * this.height)];
        break;
      case 3:
        locations = [this.height - (.3 * this.height), this.height - (.4 * this.height), this.height - (.5 * this.height)];
        break;
      default:
        locations = [];
        break;
    }

    return locations;
  }
}

class Photon {
  constructor(config) {
    this.parent = config.parent;
    this.pos_x = config.pos_x || this.parent.width / 4;
    this.pos_y = config.pos_y || 0;
    this.velocity = config.velocity || {x: 0, y: 1};
    this.always_visible = config.always_visible || false;
    this.image = new Image();
    this.image.src = this.parent.photon_urls.sunlight;
  }

  move() {
    this.pos_x += (this.velocity.x * .004 * this.parent.width) * (this.parent.play_speed / 10);
    this.pos_y += (this.velocity.y * .004 * this.parent.width) * (this.parent.play_speed / 10);
  }

  draw() {
    this.parent.ctx.drawImage(this.image, this.pos_x, this.pos_y, this.parent.width * .02, this.parent.width * .02);
  }
}

class InfraredPhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.infrared;
  }

  reflect(reflection_points) {
    for (let i = 0; i < reflection_points.length; i++) {
      if ((this.pos_y >= reflection_points[i] - (.002 * this.parent.width) && this.pos_y <= reflection_points[i] + (.002 * this.parent.width)) && Math.random() > this.parent.REFLECTION_THRESHOLD) {
        this.velocity.y = 0 - this.velocity.y;
        break;
      }
      if (this.pos_y > this.parent.height && this.velocity.y > 0) {
        this.velocity.y = 0 - this.velocity.y;
      }
    }
  }


}

class VisiblePhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.sunlight;
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function _log(fc, msg, val) {
  if (fc % 100 == 0) {
    console.log(msg, val);
  }
}

class Thermometer {
  constructor(config) {
    this.container = config.container;
    this.parent = config.parent;
  }

  build() {
    this.wrapper = document.createElement('div');
    this.wrapper.className = 'simulation-thermometer';


    this.therm_fill = document.createElement('div');
    this.therm_fill.className = 'simulation-thermometer-fill';

    this.therm_outline = document.createElement('div');
    this.therm_outline.className = 'simulation-thermometer-outline';

    this.reading = document.createElement('div');
    this.reading.className = 'simulation-thermometer-text';
    this.reading.innerHTML = '50';

    this.therm_outline.appendChild(this.reading);

    this.wrapper.appendChild(this.therm_fill);
    this.wrapper.appendChild(this.therm_outline);

    this.container.appendChild(this.wrapper);
  }

  setReading(val) {
    this.reading.innerHTML = this.val = val;
  }

  updateCylinder() {
    let percent = (this.val / 200) * 100;

    if (percent >= 40) {
      this.therm_fill.className = 'simulation-thermometer-fill per_40';
    }
    if (percent >= 45) {
      this.therm_fill.className = 'simulation-thermometer-fill per_45';
    }
    if (percent >= 50) {
      this.therm_fill.className = 'simulation-thermometer-fill per_50';
    }
    if (percent >= 55) {
      this.therm_fill.className = 'simulation-thermometer-fill per_55';
    }
    if (percent >= 60) {
      this.therm_fill.className = 'simulation-thermometer-fill per_60';
    }
    if (percent >= 65) {
      this.therm_fill.className = 'simulation-thermometer-fill per_65';
    }
    if (percent >= 70) {
      this.therm_fill.className = 'simulation-thermometer-fill per_70';
    }
    if (percent >= 75) {
      this.therm_fill.className = 'simulation-thermometer-fill per_75';
    }
    if (percent >= 80) {
      this.therm_fill.className = 'simulation-thermometer-fill per_80';
    }
    if (percent >= 85) {
      this.therm_fill.className = 'simulation-thermometer-fill per_85';
    }
    if (percent >= 90) {
      this.therm_fill.className = 'simulation-thermometer-fill per_90';
    }
    if (percent >= 95) {
      this.therm_fill.className = 'simulation-thermometer-fill per_95';
    }
  }
}
