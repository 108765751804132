import React, { useState } from 'react';
import { CheckboxField } from '@/components/common/Forms';

interface EnglishVideoOnly {
  englishOnlySelected: boolean;
}

export const EnglishVideoOnly = ({ englishOnlySelected }: EnglishVideoOnly) => {
  const [isChecked, setIsChecked] = useState(englishOnlySelected);

  return (
    <CheckboxField
      checked={isChecked}
      onChange={() => setIsChecked(prevState => !prevState)}
      name="english_video_only"
      label="English Video Only"
    />
  );
};

export default EnglishVideoOnly;
