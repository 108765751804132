import React, { Component } from 'react';
import Axios from 'axios';
import MaterialSelect from '../MaterialSelect';
import Select from 'react-select';
import { FormRow, inputId, FormInput, FormCheckbox, FileInput } from '../Utils';
import { Footer, Spinner } from 'common/Modal';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    materialId: null,
    quantity: null,
    modelType: '',
    distribution: '',
    additionalInfo: '',
    optional: false,
    recyclable: false,
    reusable: false,
    handoutTitle: '',
    handout: null,
    removeHandout: false,
    handoutFileName: '',
    handoutUrl: '',
    modelUnitId: '',
    programKitMaterial: ''
  };

  render() {
    if (this.state.error) {
      return (
        <div className="error">There was an error. Please reload the page and try again.</div>
      );
    }
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`materials-form ${this.state.isLoading ? 'loading' : ''}`}>
        {this.renderMaterialsInput()}
        {this.materialIsHandout() &&
          <FormInput label="handout title" stateName="handoutTitle"
                     value={this.state.handoutTitle}
                     id={this.props.id} required={true}
                     onChange={this.handleChange.bind(this)} />
         }
        {this.materialIsHandout() && this.renderHandoutFileInput()}
        <FormInput label="quantity" inputType="number"
                   value={this.state.quantity}
                   required={!this.state.optional}
                   id={this.props.id}
                   step={.01}
                   onChange={this.handleQuantityChange.bind(this)}/>
        {this.renderUnitsInput()}
        {this.renderDistributionInput()}
        {this.renderProgramKitInput()}
        <FormInput label="additional info" stateName="additionalInfo"
                   value={this.state.additionalInfo}
                   id={this.props.id} InputType={'textarea'}
                   onChange={this.handleChange.bind(this)} />
        <FormCheckbox checked={this.state.optional}
                      onChange={e => this.handleCheck(e, 'optional')}
                      label="Optional" />
        <FormCheckbox checked={this.state.recyclable}
                      onChange={e => this.handleCheck(e, 'recyclable')}
                      label="Recyclable" />
        <FormCheckbox checked={this.state.reusable}
                      onChange={e => this.handleCheck(e, 'reusable')}
                      label="Reusable" />
        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  materialIsHandout() {
    return this.state.name === 'Handout';
  }

  renderHandoutFileInput() {
    return (
      <FileInput label="Handout PDF" required={true}
                 className="handout-pdf" id={this.props.id}
                 removeFile={this.state.removeHandout}
                 file={this.state.handout}
                 fileName={this.state.handoutFileName}
                 fileUrl={this.state.handoutUrl}
                 handleChange={this.changeHandout.bind(this)}
                 handleClear={this.clearHandout.bind(this)} />
    );
  }

  changeHandout(e) {
    this.setState({
      handout: e.target.files[0],
      removeHandout: false
    });
  }

  clearHandout(e) {
    e.target.previousSibling.value = null;
    this.setState({
      handout: null,
      removeHandout: true
    });
  }

  handleQuantityChange(e, stateName) {
    const value_string = e.target.value.toString();

    if (value_string.includes('.')) {
      const separated_value = value_string.split('.');
      if (separated_value[1].length > 2) return;
    }

    if (e.target.value === '' || parseInt(e.target.value) > 0) this.handleChange(e, stateName);
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleCheck(e, stateName) {
    this.setState({
      [stateName]: e.target.checked
    });
  }

  renderDistributionInput() {
    return (
      <FormRow>
        <label className="mt5" htmlFor={inputId({ label: 'distribution',
                                                  newRecord: this.props.newRecord,
                                                  id: this.props.id })}>
          Distribution
        </label>
        <Select searchable={true}
                required={true}
                onChange={this.setDistribution.bind(this)}
                value={this.state.distribution}
                options={this.props.distributions} />
      </FormRow>
    );
  }

  renderMaterialsInput() {
    return(
      <FormRow>
        <label className="mt5">Material</label>
        <MaterialSelect name="lesson_module_material[material_id]"
                        value={this.state.materialId}
                        onChange={this.setMaterial.bind(this)}
                        options={this.props.forSelect} />
      </FormRow>
    );
  }

  renderUnitsInput() {
    return(
      <FormRow>
        <label className="mt5"
               htmlFor={inputId({ label: 'units',
                                  newRecord: this.props.newRecord,
                                  id: this.props.id })}>
          Unit of Measurement
        </label>
        <Select searchable={true}
                required={true}
                value={this.state.modelUnitId || ''}
                onChange={this.setUnit.bind(this)}
                options={[{ value: '', label: 'None' }].concat(this.props.units)} />
      </FormRow>
    );
  }

  renderProgramKitInput() {
    let pkm_options = this.props.programKitMaterials.filter((pkm) => pkm.material_id === this.state.materialId)
      .map((pkm) => {
      return { value: pkm.value, label: pkm.label }
    });

    return(
      <FormRow>
        <label className="mt5"
               htmlFor={inputId({ label: 'program-kit-materials',
                 newRecord: this.props.newRecord,
                 id: this.props.id })}>
          Source
        </label>
        <Select searchable={true}
                required={true}
                value={ (this.state.programKitMaterial !== null) ? this.state.programKitMaterial.value : ''}
                onChange={this.setProgramKitMaterial.bind(this)}
                options={[{ value: '', label: 'None' }].concat(pkm_options)} />
      </FormRow>
    );
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  setMaterial(e) {
    this.setState({
      materialId: e ? e.value : null,
      name: e ? e.label : null,
      modelUnitId: e && e.materialUnitId ? e.materialUnitId : ''
    })
  }

  setDistribution(e) {
    this.setState({ distribution: e ? e.value : null });
  }

  setUnit(e) {
    this.setState({ modelUnitId: e ? e.value : '' });
  }

  setProgramKitMaterial(e) {
    const value = e ? e.value : '';
    this.setState({ programKitMaterial: { value: value } })
  }

  _buildFormData() {
    const { modelType, modelId, materialId, quantity,
            distribution, additionalInfo, optional, recyclable, programKitMaterial,
            reusable, handoutTitle, handout, removeHandout, modelUnitId } = this.state;

    let formData = new FormData();

    const programKitMaterialId = programKitMaterial ? programKitMaterial.value : '';

    if (removeHandout) formData.append('model_material[delete_handout]', '1');
    if (handout) formData.append('model_material[handout]', handout);
    formData.append('model_material[model_type]', modelType);
    formData.append('model_material[model_id]', modelId);
    formData.append('model_material[material_id]', materialId);
    formData.append('model_material[measurement_unit_id]', modelUnitId || '');
    formData.append('model_material[program_kit_material_id]', programKitMaterialId);
    formData.append('model_material[quantity]', quantity);
    formData.append('model_material[distribution]', distribution);
    formData.append('model_material[additional_information]', additionalInfo);
    formData.append('model_material[optional]', optional);
    formData.append('model_material[recyclable]', recyclable);
    formData.append('model_material[reusable]', reusable);
    formData.append('model_material[handout_title]', handoutTitle);

    return formData;
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    let method = this.props.newRecord ? 'post' : 'put',
        url = `/admin/model_materials/${this.props.newRecord ? '' : this.props.id}`,
        callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
        data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }
}
