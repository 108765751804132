import React from 'react';
import I18n from 'i18n';
import cn from '@/utils/cn';
import { LinkButton } from '@/components/ui/Buttons';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import LockedSlideIcon from '../Navigator/Controls/Navigation/LockedSlideIcon';
import useSlideConditions from '../useSlideConditions';

interface UnansweredQuestionsLinkProps {
  slide: ISlideOrSlideGroup;
  unansweredQuestions: any[];
}

const UnansweredQuestionsLink = ({ slide, unansweredQuestions }: UnansweredQuestionsLinkProps) => {
  const jumpToSlide = useSlideShowContext(state => state.jumpToSlide);
  const { isSlideLocked } = useSlideConditions();
  const slideIsLocked = isSlideLocked(slide);

  const questionStringTranslation = unansweredQuestions.length > 1 ? 'questions' : 'question';
  const slidePosition = slide.position;

  const renderSlideLabelOrLink = () => {
    const slideLabel = `${I18n.t('slide')} ${slidePosition}`;

    return (
      slideIsLocked ?
        // Give matching border of LinkButton for alignment
        <span className="tw-p-1 tw-border tw-border-transparent tw-border-solid">
          {slideLabel}
        </span>
        :
        <LinkButton className="tw-p-1" onClick={() => jumpToSlide(slidePosition - 1)}>
          {slideLabel}
        </LinkButton>
    );
  };

  return (
    <div
      key={`unanswered-questions-link-${slidePosition}`}
      className="tw-flex tw-items-center tw-justify-start"
    >
      {/* Conditionally make the icon invisible to preserve alignment */}
      <span className={cn({ 'tw-invisible': !slideIsLocked })}>
        <LockedSlideIcon slide={slide} />
      </span>
      {renderSlideLabelOrLink()}
      <span>-</span>
      <span className="tw-p-1">
        {unansweredQuestions.length} {I18n.t(questionStringTranslation)}
      </span>
    </div>
  );
};

export default UnansweredQuestionsLink;
