import React, { useRef } from 'react';
import { thumbnailFromCanvas } from '@/modules/TCIUtils';
import useVideoPosterStore from './useVideoPosterStore';

interface PosterSelectorProps {
  locale: 'en' | 'es';
}

const PosterSelector = ({ locale }: PosterSelectorProps) => {
  const {
    enPoster,
    esPoster,
    setEnPoster,
    setEsPoster,
    setUseEnPoster,
    useEnPoster,
    video,
  } = useVideoPosterStore();
  const poster = locale === 'es' ? esPoster : enPoster;
  const posterSrc = locale === 'es' ? video.poster_es_url : video.poster_url;
  const videoRef = useRef();
  const setPoster = locale === 'es' ? setEsPoster : setEnPoster;

  const videoSrc = locale === 'es' ? video.es_url : video.url;

  const handleClick = () => {
    const newPoster = poster ? null : thumbnailFromCanvas(videoRef.current, '500px');

    setPoster(newPoster);
  };

  const shouldHideVideo = () => {
    if (locale === 'en') return false;

    return useEnPoster;
  };

  return (
    <>
      <h1>{locale === 'es' ? 'Spanish' : 'English'}</h1>
      <div className="tw-flex">
        <div className="tw-flex tw-flex-1 tw-justify-start tw-items-center tw-relative">
          <span className="tw-mb-0 tw-mr-4">Current Poster</span>
          <img
            data-testid={`current-poster-${locale}`}
            src={posterSrc}
            width="180px"
            alt="Current poster"
          />
          {locale === 'es' && (
            <label
              htmlFor={`use_es_poster-${locale}`}
              className="tw-absolute tw-bottom-1 tw-left-1"
            >
              Use English Poster
              <input
                id={`use_es_poster-${locale}`}
                type="checkbox"
                checked={useEnPoster}
                onChange={() => {
                  setUseEnPoster(!useEnPoster);
                }}
              />
            </label>
          )}
        </div>
        <div className="tw-flex tw-flex-1">
          {poster && (
            <img
              data-testid={`poster-${locale}`}
              className={`tw-w-[325px] ${shouldHideVideo() && 'tw-invisible'}`}
              src={poster.toDataURL()}
              alt="Video poster"
            />
          )}
          {!poster && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              data-testid={`video-${locale}`}
              ref={videoRef}
              className={`tw-w-[325px] ${shouldHideVideo() && 'tw-invisible'}`}
              controls
              crossOrigin="anonymous"
            >
              <source src={videoSrc} />
            </video>
          )}
        </div>
      </div>

      <div className="tw-flex">
        <div className="tw-flex tw-flex-1" />
        {!shouldHideVideo() && (
          <div className="tw-flex tw-flex-1 tw-items-center tw-justify-center tw-p-4">
            <button
              data-testid={`capture-button-${locale}`}
              id="capture_button"
              type="button"
              className="btn btn--outline-purple btn--md"
              onClick={handleClick}
            >
              {poster ? 'Pick New Frame' : 'Capture Frame'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PosterSelector;
