import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { INotebook } from '../../types';

const useVideoActivity = (classroomId: number, lessonId: number) => {
  const assignVideoActivitySlideshow = $('#slug-attributes').data('flags')?.assign_slideshow_video_activity;
  const isEnabled = !!lessonId && !assignVideoActivitySlideshow;

  const query = useQuery(['videoActivity', lessonId], () => {
    const path = Routes.plato_api_get_video_activity_notebooks_path({
      lesson_id: lessonId,
    });
    return axios
      .get<{ data: INotebook }>(path)
      .then(res => res.data?.data || null)
      .catch(err => console.error(err));
  }, { staleTime: Infinity, enabled: isEnabled });
  return { ...query, isEnabled, data: query.data || null };
};

export default useVideoActivity;
