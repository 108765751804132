import React from 'react';
import * as Routes from 'modules/routes';
import I18n from 'modules/i18n';
import greenBookIcon from 'images/icons/book-green-outline.png';
import blueBookIcon from 'images/icons/book-blue-outline.png';
import ButtonWithTooltip from '@/components/shared/LessonOverview/Cards/ActionBar/ButtonWithTooltip';
import Card, { ActionBar, CardBody } from './Card';
import AssignButton from './AssignButton';
import {
  BorderColors, CardType, Program, SectionType,
} from '../types';

type ReadingCardProps = {
  bannerBorderColor: BorderColors,
  bannerLabel: CardType,
  children: ReactChildren,
  lessonId: number,
  linkLabel: string,
  readingPath: string,
  sections: SectionType[],
  stafferId?: number,
  program: Program,
  type: string,
};

const ReadingCard = ({
  bannerBorderColor, bannerLabel, children, lessonId,
  linkLabel, readingPath, sections, stafferId, program, type,
}: ReadingCardProps) => {
  const renderActionBar = () => (
    <ActionBar>
      <AssignButton
        initialValues={{
          modelType: 'sectionNotebook',
          modelsToAssign: sections.map(section => section.assignable.value),
        }}
        isAssigned={sections.some(section => section.isAssigned)}
        lessonId={lessonId}
        program={program}
        stafferId={stafferId}
      />

      <ButtonWithTooltip
        ariaLabel="Print reading"
        link={Routes.pdf_shared_program_lesson_path(program.id, lessonId, {
          locale: I18n.locale,
        })}
        openInNewWindow
        tooltipContent={I18n.t('print_text')}
        iconClassName="fa fa-print"
      />
    </ActionBar>
  );

  return (
    <Card
      bannerBorderColor={bannerBorderColor}
      label={bannerLabel}
      type={type}
    >
      <CardBody
        ariaLabel={linkLabel}
        iconSrc={bannerBorderColor === 'green' ? greenBookIcon : blueBookIcon}
        linkLabel={linkLabel}
        linkHref={readingPath}
      >
        {children}
      </CardBody>

      {stafferId && renderActionBar()}
    </Card>
  );
};

export default ReadingCard;
