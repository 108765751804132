import { RefObject, useEffect } from 'react';

const useOnClickOnKeys = (ref: RefObject<any>, keys: string[]) => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!ref.current) return;

      if (keys.includes(e.key)) ref.current.click();
    };

    document.addEventListener('keyup', handleKeyPress, false);

    return () => document.removeEventListener('keyup', handleKeyPress, false);
  }, [ref.current]);
};

export default useOnClickOnKeys;
