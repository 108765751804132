import React from 'react';
import { twMerge } from '@/utils';
import CardImage from './CardImage';
import CardBody from './CardBody';
import CardActions from './CardActions';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card = ({ children, className = '' }: CardProps) => (
  <div
    className={twMerge(
      `tw-rounded tw-overflow-hidden tw-min-w-[250px] tw-min-h-[220px] 
      tw-shadow-md hover:tw-shadow-lg tw-transition-shadow tw-box-border`,
      className,
    )}
  >
    {children}
  </div>
);

Card.Actions = CardActions;
Card.Body = CardBody;
Card.Image = CardImage;

export default Card;
