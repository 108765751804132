import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import editIcon from 'images/icons/editIcon.svg';
import { isSupportManager } from 'common/Utils';
import ActionsDropdown from 'common/ActionsDropdown';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from 'common/AdvancedFilters/Utils';
import { matchAttributesForSingleRow } from 'common/DataTable/Utils';
import PaginatedTable from 'common/DataTable/PaginatedTable';
import AdvancedFilters from 'common/AdvancedFilters';
import Edit from './Edit';
import Delete from './Delete';
import Add from './Add';
import AddNote from './AddNote';
import styles from './SubscriberTable.module.scss';

export const SubscriberNameCell = ({
  rowData
}) => (
  <span>
    <a href={`/admin/subscribers/${rowData.id}/home`}>{rowData.name}</a>
    {' '}
    { rowData.note && (
      <Tooltip
        content={<div className={styles.note}>{rowData.note}</div>}
      >
        <span>
          <i className="fa fa-info-circle" aria-hidden />
          <span className="sr-only">subscriber note</span>
        </span>
      </Tooltip>
    )}
  </span>
);

SubscriberNameCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    note: PropTypes.string
  }).isRequired
};

export const SubscriberDropdown = ({
  rowData, openModal
}) => (
  <ActionsDropdown>
    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={e => openModal(e, rowData, 'edit')}
    >
      <i aria-hidden="true" className="fa fa-edit" />
      {' Edit'}
    </a>

    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={e => openModal(e, rowData, 'delete')}
    >
      <i aria-hidden="true" className="fa fa-trash" />
      {' Delete'}
    </a>

    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={e => openModal(e, rowData, 'addNote')}
    >
      <span className={styles.iconContainer}>
        <img className={styles.icon} src={editIcon} alt="" />
      </span>
      {rowData.note ? ' Edit Note' : ' Add Note'}
    </a>
  </ActionsDropdown>
);

SubscriberDropdown.propTypes = {
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    note: PropTypes.string
  }).isRequired
};

export default class SubscribersTable extends Component {
  static propTypes = {
    adminType: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    ajaxUrl: PropTypes.string.isRequired,
    editResetDateDisabled: PropTypes.bool.isRequired,
    pageSize: PropTypes.number,
    states: PropTypes.instanceOf(Array).isRequired,
    createPath: PropTypes.string.isRequired,
    updatePath: PropTypes.string.isRequired,
    destroyPath: PropTypes.string.isRequired,
    initialFilters: filtersPropTypes,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    asyncSearchPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    initialFilters: [{ propertyValue: 'name', operatorValue: 'cont' }],
    pageSize: 50
  };

  static _renderProgramsHyperLink(rowData) {
    return (<a href={`/admin/subscribers/${rowData.id}/programs`}>{rowData.num_programs}</a>);
  }

  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      deleteModalOpen: false,
      errorMessage: null,
      resources: [],
      pagesLoaded: 0,
      activeFilters: {},
      doneLoading: false,
      getUrl: this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString()),
      filterSent: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateTable = this.updateTable.bind(this);

    // used to map table column to column in our database
    this.columnMapping = ['name', 'state', 'customer_number', 'mdr_id', null, null, null, null, null];
    this.addResources = this.addResources.bind(this);
    this.resetResources = this.resetResources.bind(this);

    // used for the advanced filters
    this.updateActiveFilters = this.updateActiveFilters.bind(this);
  }

  getActiveFilters() {
    const activeFilters = this.state.activeFilters;

    let getUrl = this.props.ajaxUrl
      .replace('pageSize', this.props.pageSize.toString());

    getUrl = buildFilterPath(getUrl, activeFilters);

    // if we're done loading, set doneLoading to false to begin loading the filter request
    // if we're currently loading something, set doneLoading to true to stop loading
    // and allow the filter request through
    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getUrl,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  updateRow(updatedObject) {
    return this.state.resources.map((sub) => {
      if (sub.id !== updatedObject.id) {
        return Object.assign({}, sub);
      }
      return Object.assign({}, sub, updatedObject);
    });
  }

  deleteRow(updatedObject) {
    return this.state.resources.filter(sub => sub.id !== updatedObject.id);
  }

  addRow(updatedObject) {
    matchAttributesForSingleRow(updatedObject, this.columnHeaders());
    return [...this.state.resources].concat(updatedObject);
  }

  updateTable(data, action) {
    let updatedResources;

    // reload all resources on an add/delete to ensure our table is clean
    switch (action) {
      case 'update':
        updatedResources = this.updateRow(data);
        break;
      case 'delete':
        if (!this.state.doneLoading) {
          this.resetResources();
          return;
        }
        updatedResources = this.deleteRow(data);
        break;
      case 'add':
        if (!this.state.doneLoading) {
          this.resetResources();
          return;
        }
        updatedResources = this.addRow(data);
        break;
      case 'showFilteredResources':
        this.getActiveFilters();
        updatedResources = data;
        break;
      case 'error':
        this.setState({ errorMessage: data });
        return;
      default:
        console.log('Action not yet implemented');
    }

    this.setState({ resources: updatedResources, errorMessage: null });
  }

  _renderDelete() {
    if (this.state.deleteModalOpen) {
      return (
        <Delete
          closeModal={() => this.closeModal('delete')}
          destroyPath={this.props.destroyPath}
          modalIsOpen={this.state.deleteModalOpen}
          rowData={this.state.rowData}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({ [`${type}ModalOpen`]: true, rowData });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false, rowData: null });
  }

  _renderError() {
    if (this.state.errorMessage) {
      return (
        <div id="flash" className="alert">
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
    return null;
  }

  _renderEdit() {
    if (this.state.editModalOpen) {
      return (
        <Edit
          closeModal={() => this.closeModal('edit')}
          modalIsOpen={this.state.editModalOpen}
          rowData={this.state.rowData}
          states={this.props.states}
          updatePath={this.props.updatePath}
          updateTable={this.updateTable}
          editResetDateDisabled={this.props.editResetDateDisabled}
        />
      );
    }

    return null;
  }

  resetResources() {
    this.setState({ resources: [], doneLoading: false, pagesLoaded: 0 });
  }

  addResources(newResources) {
    const state = {
      resources: [...this.state.resources].concat(newResources),
      doneLoading: newResources.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1
    };

    if (this.state.filterSent) {
      state.doneLoading = false;
      state.filterSent = false;
    }

    this.setState(state);
  }

  columnHeaders() {
    if (!isSupportManager(this.props.adminType)) return this.props.columns;

    return this.props.columns.filter(columnHeader => columnHeader !== 'actions');
  }

  columnDefs() {
    const alwaysDisplayedColumns = [
      {
        targets: 0,
        title: 'Subscriber Name',
        width: '30%',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            (<SubscriberNameCell rowData={rowData} />),
            td
          );
        }
      },
      {
        targets: 1,
        title: 'Location',
        width: '20%'
      },
      {
        targets: 2,
        title: 'Sub ID'
      },
      {
        targets: 3,
        title: 'MDR ID'
      },
      {
        // rostering
        targets: 4,
        sortable: false
      },
      {
        // sign on
        targets: 5,
        sortable: false
      },
      {
        targets: 6,
        title: 'Num of Programs',
        sortable: false,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            SubscribersTable._renderProgramsHyperLink(rowData),
            td
          );
        }
      },
      {
        title: 'Num of Licenses',
        targets: 7,
        sortable: false,
        render: function formatNumber(data) {
          return data.toLocaleString();
        }
      }
    ];

    const actionsColumn = {
      targets: 8,
      sortable: false,
      searchable: false,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <SubscriberDropdown rowData={rowData} openModal={this.openModal} />,
          td
        );
      }
    };

    if (isSupportManager(this.props.adminType)) {
      return alwaysDisplayedColumns;
    }

    return alwaysDisplayedColumns.concat(actionsColumn);
  }

  _renderPaginatedTable() {
    return (
      <PaginatedTable
        columns={this.columnHeaders()}
        columnDefs={this.columnDefs()}
        exportedColumns={[0, 1, 2, 3, 4, 5, 6, 7]}
        pagesLoaded={this.state.pagesLoaded}
        doneLoading={this.state.doneLoading}
        columnMapping={this.columnMapping}
        resources={this.state.resources}
        addResources={this.addResources}
        resetResources={this.resetResources}
        getUrl={this.state.getUrl}
      />
    );
  }

  updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this.updateTable([], 'showFilteredResources')
    );
  }

  _renderAdd() {
    if (isSupportManager(this.props.adminType)) return null;

    return (
      <Add
        updateTable={this.updateTable}
        createPath={this.props.createPath}
        states={this.props.states}
        buttonClass="btn btn--green"
      />
    );
  }

  _renderAddNote() {
    if (this.state.addNoteModalOpen) {
      return (
        <AddNote
          closeModal={() => this.closeModal('addNote')}
          updatePath={this.props.updatePath}
          modalIsOpen={this.state.addNoteModalOpen}
          rowData={this.state.rowData}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  _renderFilter() {
    return (
      <div>
        <AdvancedFilters
          initialFilters={this.props.initialFilters}
          properties={this.props.properties}
          searchPath={this.props.ajaxUrl}
          resources={this.state.resources}
          updateActiveFilters={this.updateActiveFilters}
          asyncSearchPath={this.props.asyncSearchPath}
        />
        <hr />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this._renderError()}
        {this._renderFilter()}
        {this._renderAdd()}
        {this._renderPaginatedTable()}
        {this._renderEdit()}
        {this._renderDelete()}
        {this._renderAddNote()}
      </div>
    );
  }
}
