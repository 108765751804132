import React from 'react';
import I18n from 'i18n-js';
import * as Routes from 'modules/routes';

import Tooltip from 'components/common/TCIReactTooltip';

interface ViewCoursesButtonProps {
  currentStafferId: number;
  programWithPD: {
    id: number;
  };
  subscriberId: number;
}

const ViewCoursesButton = ({ currentStafferId, programWithPD, subscriberId }: ViewCoursesButtonProps) => {
  if (programWithPD) {
    return (
      <a className="btn btn--outline-purple"
         href={Routes.teacher_program_professional_developments_path(programWithPD.id)}
      >
        {I18n.t('view_courses')}
      </a>
    )
  }

  const tooltipContent = (
    <span>
      Professional Development courses are delivered for specific programs.
      {' '}
      <a
        href={Routes.admin_subscriber_teacher_path(subscriberId, currentStafferId)}
      >
        Add a program
      </a> to view the courses for that program.
    </span>
  );

  return (
    <Tooltip
      html={tooltipContent}
      theme="white"
      size="medium"
    >
      <button className="btn btn--outline-purple disabled" >{I18n.t('view_courses')}</button>
    </Tooltip>
  );
}

export default ViewCoursesButton;
