import React from 'react';

const AlignMiddle = () => (
  <svg
    className="fr-svg"
    focusable="false"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9,18h6v-2H9V18z M6,11v2h12v-2H6z M3,6v2h18V6H3z" />
  </svg>
);

export default AlignMiddle;
