import update from 'immutability-helper';
import getNextLetter from './getNextLetter';

const addDraggableChoice = (draggableChoices) => {
  const nextLetter = getNextLetter(draggableChoices);
  const positions = Object.values(draggableChoices).map(c => c.position);

  const newDraggableChoice = {
    [nextLetter]: {
      left: 150,
      position: Math.max(...positions) + 1,
      title: `Draggable Choice ${nextLetter}`,
      top: 180,
      type: 'draggableChoice',
    },
  };

  return update(draggableChoices, {
    $merge: newDraggableChoice,
  });
};
const deleteDraggableChoice = (draggableChoices, { payload: { id } }) => update(draggableChoices, { $unset: [id] });

const updateDraggableChoice = (draggableChoices, { payload: { id, left, top, title, position } }) => (
  update(draggableChoices, {
    [id]: {
      $merge: { left, position, title, top },
    },
  })
);

const updateVocabulary = (draggableChoices, updates) => (
  updates.payload.reduce((updatedVocabulary, updatedChoice) => (
    update(updatedVocabulary, {
      [updatedChoice.id]: {
        $merge: {
          title: updatedChoice.id.toUpperCase(),
          type: updatedChoice.type,
          vocabId: updatedChoice.vocabId
        },
      },
    })), draggableChoices)
);

const draggableChoiceReducer = (draggableChoices, action) => {
  switch (action.type) {
    case 'ADD':
      return addDraggableChoice(draggableChoices);
    case 'DELETE':
      return deleteDraggableChoice(draggableChoices, action);
    case 'UPDATE':
      return updateDraggableChoice(draggableChoices, action);
    case 'UPDATE_VOCAB':
      return updateVocabulary(draggableChoices, action);
    default:
      return draggableChoices;
  }
};

export default draggableChoiceReducer;
