import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import cn from '@/utils/cn';
import { useDrop } from 'react-dnd';
import BuilderContext from 'common/Context';
import DeleteButton from '../DeleteButton';
import styles from './DropZone.module.scss';
import ItemTypes from '../../ItemTypes';
import ResizableContainer from '../ResizableContainer';
import DraggableContainer from '../DraggableContainer';
import LabelField from './LabelField';
import CopyButton from '../CopyButton';
import useDragAndDropStore from '../../useDragAndDropStore';

const DropZone = ({
  boundsRef,
  dropZoneContainerRef,
  children,
  correctAnswers,
  height,
  id,
  left,
  minDimensions,
  shape,
  title,
  top,
  type,
  vocabId,
  width,
}) => {
  const MOVEMENT_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  const { dropZoneDispatch, correctAnswerDispatch, hasTransparentDropzones, isEditingLabels } = useContext(BuilderContext);
  const isGloballyDragging = useDragAndDropStore(state => state.isGloballyDragging);
  const [isResizing, setIsResizing] = useState(false);
  const answers = useRef();

  const [{ highlighted }, drop] = useDrop(() => ({
    accept: ItemTypes.draggableChoice,
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      highlighted: monitor.isOver(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      correctAnswerDispatch({ payload: { draggableChoiceId: item.id, dropZoneId: id }, type: 'ADD' });
    },
  }), [children, correctAnswers]);

  const handleKeyDown = (e) => {
    if (MOVEMENT_KEYS.includes(e.key)) e.preventDefault();
    dropZoneDispatch({ payload: { height, id, key: e.key, left, shape, title, top, width, }, type: 'MOVE_WITH_ARROW_KEY' });
  };

  const item = { height, id, label: `Drop zone ${id.toUpperCase()}`, left, shape, title, top, type: ItemTypes.BOX, vocabId, width };

  const renderChildren = () => {
    const isVocab = type === 'vocab';
    const useLabelField = !isVocab && (isEditingLabels || children.length === 0);

    if (useLabelField) return <LabelField id={id} title={title} item={item} />;

    return (
      <div id={`test-${id}`} ref={answers} aria-label={`Correct answers for Drop zone ${id}`} role="list">
        {children}
      </div>
    );
  };

  return (
    <DraggableContainer
      draggable={!isResizing}
      handleKeyDown={handleKeyDown}
      item={item}
      minDimensions={minDimensions}
    >
      <div className={styles.dropZoneWrapper} ref={dropZoneContainerRef}>
        <ResizableContainer
          className={cn(
            styles.dropZone, {
              [styles.highlighted]: highlighted,
              [styles.transparentBackgroundDragging]: isGloballyDragging && hasTransparentDropzones,
              [styles.transparentBackgroundNotDragging]: !isGloballyDragging && hasTransparentDropzones,
              [styles.circle]: shape === 'circle',
              [styles.rectangle]: shape !== 'circle',
            },
            shape === 'circle' ? styles.circle : styles.rectangle,
          )}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={(e, newDimensions) => {
            setIsResizing(false);
            dropZoneDispatch({ payload: { e, id, key: e.key, left, shape, title, top, ...newDimensions }, type: 'UPDATE' });
          }}
          parentEl={boundsRef && boundsRef.current && boundsRef.current.firstChild}
        >
          <div
            ref={drop}
            className={styles.resizableChild}
          >
            {renderChildren()}
          </div>
        </ResizableContainer>
      </div>
      <CopyButton id={id} />
      {id !== 'a' && <DeleteButton id={id} type={type} label={`Delete drop zone: ${title}`} />}
    </DraggableContainer>
  );
};

DropZone.defaultProps = {
  boundsRef: null,
  height: null,
  vocabId: null,
  width: null,
};

DropZone.propTypes = {
  boundsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  dropZoneContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.node,
  correctAnswers: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  minDimensions: PropTypes.shape({ height: PropTypes.number, width: PropTypes.number }).isRequired,
  shape: PropTypes.string,
  title: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  vocabId: PropTypes.number,
  width: PropTypes.number,
};

DropZone.defaultProps = {
  children: React.createElement('div'),
  correctAnswers: [],
};

export default DropZone;
