import React from 'react';
import { useModalManager } from '@/components/common/Modal';
import Dropdown from '@/components/common/Dropdown';
import styles from '@/components/admin/SlideShow/Builder/SlideShowBuilder.module.scss';
import dropdownStyles
  from '@/components/admin/SlideShow/Builder/BackgroundDropdownActions/BackgroundDropdownActions.module.scss';
import {
  ManageAudioOption,
} from '@/components/admin/SlideShow/Builder/BackgroundAudioButton/AudioOptions';
import DeleteModalButton from '@/components/common/DeleteModalButton';
import { plato_api_delete_background_audio_slide_path } from 'modules/routes';
import useSlideShowContext from '../../stores/useSlideShowContext';
import { Slide } from '../CreateTransistionGroup/utils';

interface BackgroundAudioButtonProps {
  slide: Slide;
}

const BackgroundAudioButton = ({ slide }: BackgroundAudioButtonProps) => {
  const audioModalManager = useModalManager();
  const hasAudio = slide.backgroundAudioUrl !== '/missing.png';
  const updateSlide = useSlideShowContext(state => state.updateSlide);

  const text = () => (
    <div className={`mr10 ${styles.textWithIcon}`}>
      <i className="fa fa-volume-down" />
      {hasAudio ? 'Manage Background Audio' : 'Add Background Audio'}
    </div>
  );

  const afterSubmit = (data) => {
    const {
      backgroundAudioEsFileName,
      backgroundAudioEsUrl,
      backgroundAudioFileName,
      backgroundAudioUrl
    } = data;

    updateSlide(parseInt(slide.id.toString()), {
      backgroundAudioEsFileName,
      backgroundAudioEsUrl,
      backgroundAudioFileName,
      backgroundAudioUrl
    });
  };

  const deleteAudioButton = () => (
    <DeleteModalButton
      buttonClasses={`${dropdownStyles.dropdownItem} dropdown-item`}
      buttonLabel="Remove Background Audio"
      closeButtonClassName="tw-text-white"
      confirmationMessage="Are you sure you want to remove background audio for this slide?"
      deleteButtonClass="btn btn--red"
      deleteButtonLabel="Yes, Remove"
      destroyPath={plato_api_delete_background_audio_slide_path(slide.id)}
      id={slide.id}
      modalHeaderClass="tw-bg-red"
      modalHeaderText={<span className="tw-text-white">Remove Background Audio</span>}
      removeResource={afterSubmit}
    />
  );

  return (
    <Dropdown toggleText={text} buttonClass="btn btn--outline-purple dropdown-toggle end" dropdownPosition="top">
      <ManageAudioOption modalManager={audioModalManager} slide={slide} afterSubmit={afterSubmit} />
      {hasAudio && deleteAudioButton()}
    </Dropdown>
  );
};

export default BackgroundAudioButton;
