import React from 'react';
import showToast from 'common/Toast';
import PropTypes from 'prop-types';

const LTIGradebookLink = ({ text }) => {
  const handleToast = () => {
    showToast('Link copied to clipboard', { autoClose: 3000 });
  };

  return (
    <a
      href="#"
      role="button"
      className="js-clipboard-trigger js-prevent-default dropdown-item"
      data-clipboard-text={text}
      onClick={handleToast}
    >
      <i className="fa fa-link fa-fw" aria-hidden="true" />
      LTI Gradebook Link
    </a>
  );
};

LTIGradebookLink.propTypes = {
  text: PropTypes.string.isRequired
};

export default LTIGradebookLink;
