import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

export const initialValueShapeProps = PropTypes.shape({
  id: PropTypes.string,
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  videoId: PropTypes.number,
  youtube_video_url: PropTypes.string
});

export const modelVideoShapeProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired,
  video: PropTypes.shape({
    captionUrl: PropTypes.string,
    id: PropTypes.string.isRequired,
    isSilent: PropTypes.bool.isRequired,
    posterUrl: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    videoFileName: PropTypes.string.isRequired
  }),
  videoId: PropTypes.number.isRequired,
  width: PropTypes.number
});

const VIDEO_COLS = `
  id,
  audioDescriptionsCues,
  audioDescriptionsUrl,
  captionEsUrl,
  captionUrl,
  url,
  esUrl,
  sdEsUrl,
  hdEsUrl,
  fhdEsUrl,
  sdUrl,
  hdUrl,
  fhdUrl,
  isSilent,
  posterUrl,
  posterEsUrl,
  videoFileName
`;

export const MODEL_VIDEO_COLS = `
  id,
  modelId,
  modelType,
  videoId,
  width,
  autoplay,
  video {
    ${VIDEO_COLS}
  }
`;

export const CREATE_MODEL_VIDEO = gql`
  mutation createModelVideo(
  $autoplay: Boolean,
  $modelId: ID!,
  $modelType: String!,
  $videoId: ID!
  $width: Int,
  $videoLesson: Boolean,
  $readOnly: Boolean
) {
  createModelVideo(
    autoplay: $autoplay,
    modelId: $modelId,
    modelType: $modelType,
    videoId: $videoId,
    width: $width,
    videoLesson: $videoLesson,
    readOnly: $readOnly
  ) {
    result {
      ${MODEL_VIDEO_COLS}
    }
  }
}
`;

export const UPDATE_MODEL_VIDEO = gql`
  mutation updateModelVideo(
    $autoplay: Boolean,
    $id: ID!,
    $modelId: ID!,
    $modelType: String!,
    $videoId: ID!
    $width: Int,
    $videoLesson: Boolean
  ) {
    updateModelVideo(
      autoplay: $autoplay,
      id: $id,
      modelId: $modelId,
      modelType: $modelType,
      videoId: $videoId,
      width: $width,
      videoLesson: $videoLesson
    ) {
      result {
        ${MODEL_VIDEO_COLS}
      }
    }
  }
`;

export const UPDATE_SLIDE_OBJECT = gql`
  mutation UpdateSlideObject($slideObjectId: ID!, $html: String) {
    updateSlideObject(slideObjectId: $slideObjectId, html: $html) {
      success
      slideObject {
        id,
        html,
        htmlEn,
        htmlEs
      }
    }
  }
`;

// regex used to validate that a given string is formatted like a valid youtube URL
export const YOUTUBE_LINK_TEST_REGEX = /^.*((youtu.be)|(youtube.com))\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?\??v?=?([^#&?]*).*/;

export const renderYoutubeIframe = (link, classes = '', width = '100%', height = '100%') => {
  if (YOUTUBE_LINK_TEST_REGEX.test(link)) {
    const youtubeLinkVideoRegex = /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?([0-9a-zA-Z_-]+)(.+)?/g;
    const videoURL = link.replace(youtubeLinkVideoRegex, 'https://www.youtube.com/embed/$1?$2');
    const iframeBaseHTML = '<iframe class="{classes}" width="{width}" height="{height}" src="{url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    const videoIframe = iframeBaseHTML.replace(/\{url\}/, videoURL)
      .replace(/\{classes\}/, classes)
      .replace(/\{width\}/, width)
      .replace(/\{height\}/, height);

    return (
      <div dangerouslySetInnerHTML={{ __html: videoIframe }} />
    );
  }

  return <div>Invalid URL given</div>;
};

export const renderVideoSourceOptions = (videoSource, setVideoSource) => (
  <>
    <label>
      <input
        defaultChecked={videoSource === 'tci'}
        name="video-source"
        onChange={e => setVideoSource(e.currentTarget.value)}
        type="radio"
        value="tci"
      /> TCI Video
    </label>
    <label>
      <input
        defaultChecked={videoSource === 'youtube'}
        name="video-source"
        onChange={e => setVideoSource(e.currentTarget.value)}
        type="radio"
        value="youtube"
      /> Youtube Video
    </label>
  </>
);
