import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdministratorsModal from 'common/AdministratorsModal';

export default class ContactAdminButton extends Component {
  static propTypes = {
    buttonClass: PropTypes.string,
    getAdminInfoPath: PropTypes.string.isRequired,
    label: PropTypes.string,
    modalIsOpen: PropTypes.bool,
    subscriberContactAdminMessage: PropTypes.string,
  };

  static defaultProps = {
    buttonClass: 'btn--light-grey',
    label: 'Contact Admin',
    modalIsOpen: false
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.modalIsOpen
    };

    this._closeModal = this._closeModal.bind(this);
    this._openModal = this._openModal.bind(this);
  }

  _openModal(event) {
    event.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className={`btn ${this.props.buttonClass}`}
          onClick={this._openModal}
        >
          {this.props.label}
        </button>

        <AdministratorsModal
          closeModal={this._closeModal}
          isOpen={this.state.modalIsOpen}
          getAdminInfoPath={this.props.getAdminInfoPath}
          subscriberContactAdminMessage={this.props.subscriberContactAdminMessage}
        />
      </div>
    );
  }
}
