import React from 'react';
import PropTypes from 'prop-types';
import { getQuestionLetter } from './Utils';

export default function QuestionLetter(props) {
  return (
    <span className="sr-only" aria-hidden>{getQuestionLetter(props.index)}</span>
  );
}

QuestionLetter.propTypes = {
  index: PropTypes.number.isRequired
};
