import React from 'react';
import clsx from 'clsx';
import { twMerge } from '@/utils';
import Tooltip from '@/components/ui/Tooltip';
import useDrawingContext from '../store/useDrawingContext';
import { imgStyle, isSelected, buttonStyle } from './styles';
import icons from './icons';

interface ToolbarButtonProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  text?: string;
}

const ToolbarButton = ({
  children, className = null, onClick, text,
}: ToolbarButtonProps) => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const disabled = useDrawingContext(state => state.disabled);
  const mode = useDrawingContext(state => state.mode);
  const setMode = useDrawingContext(state => state.setMode);
  const icon = icons[text];

  const buttonClassName = twMerge(
    'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
    'hover:tw-bg-tci-purple tw-flex tw-items-center tw-justify-center',
    'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
    icon?.customClassName,
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      // @ts-ignore-next-line
      setMode(text);
    }
    const event = new Event('toolSelected');
    document.dispatchEvent(event);
  };

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Tooltip.Trigger
          type="button"
          className={clsx(
            (className ?? buttonClassName), {
              'tw-bg-tci-purple': isSelected(mode, text, disabled),
            },
          )}
          onClick={handleClick}
          disabled={disabled}
          style={buttonStyle(toolbarEl)}
        >
          {children}
          {!children && (
            <img
              style={imgStyle({ toolbarEl })}
              src={icon.icon}
              alt={icon.alt}
            />
          )}
        </Tooltip.Trigger>
        <Tooltip.Content
          className="tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2 tw-outline tw-outline-1 tw-outline-gray-300"
          side="right"
          sideOffset={10}
        >
          {icon.alt}
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default ToolbarButton;
