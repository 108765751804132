import React from 'react';
import I18n from '@/modules/i18n';
import cn from '@/utils/cn';
import { IText } from 'fabric';
import Action from '../Action';
import { handleBoldText, handleItalicText } from '../actions';
import MoreMenu from '../MoreMenu';
import useDrawingContext from '../../store/useDrawingContext';

const TextActions = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const activeObject = (canvas.getActiveObject() as IText);
  const isBold = activeObject?.fontWeight === 'bold';

  return (
    <>
      <Action
        className={cn(
          'fa fa-bold tw-w-[35px]',
          { 'tw-bg-gray-100': isBold },
        )}
        onClick={() => handleBoldText(canvas)}
        tooltipContent={I18n.t('bold')}
      />
      <Action
        className={cn(
          'fa fa-italic tw-w-[35px]',
          { 'tw-bg-gray-100': activeObject?.fontStyle === 'italic' },
        )}
        onClick={() => handleItalicText(canvas)}
        tooltipContent={I18n.t('italic')}
      />
      <Action
        className="fa fa-text-height tw-mr-2"
        tooltipContent={I18n.t('text_size')}
        PopoverContent={(
          <MoreMenu
            type="text"
          />
        )}
      />
    </>
  );
};

export default TextActions;
