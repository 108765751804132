import React, { useEffect, useLayoutEffect } from 'react';
import { Viewer } from '@photo-sphere-viewer/core';
import { MarkerConfig, MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { useModalManager } from '@/components/common/Modal';
import IHotspot from '@/components/interfaces/Hotspot';
import { AddImageModal }
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectPanoramicImage/PanoramicImage/AddImage';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import ReactDOM from 'react-dom';
import usePanoramicImageStore from './usePanoramicImageStore';
import './photo-sphere-viewer.scss';
import './markers-plugin.scss';
import { useUpdateHotspot } from '../panoramicImageUtils';
import HiddenHotspots from './HiddenHotspots';

interface PanoramicImageProps {
  panoramicImage: IPanoramicImage;
  slideObjectId: string;
}


export const hotspotsToMarkers = (hotspots: IHotspot[]): MarkerConfig[] => (
  hotspots.map((hotspot) => {
    const element = document.querySelector(`.hotspot-${hotspot.id}`);

    if (element && hotspot.placed) return { ...hotspot, id: hotspot.id.toString(), element } as any as MarkerConfig;

    return null;
  }).filter(Boolean)
);

const PanoramicImage = ({ slideObjectId }: PanoramicImageProps) => {
  const viewer = usePanoramicImageStore(state => state.viewer);
  const setViewer = usePanoramicImageStore(state => state.setViewer);
  const setCurrentlyPlacing = usePanoramicImageStore(state => state.setCurrentlyPlacing);
  const currentlyPlacing = usePanoramicImageStore(state => state.currentlyPlacing);
  const addImageModalManager: ModalManager = useModalManager();
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const updateHotspot = useUpdateHotspot();
  const locale = usePanoramicImageStore(state => state.locale);
  const setContainer = usePanoramicImageStore(state => state.setContainer);

  useEffect(() => {
    if (viewer) {
      viewer.navbar.getButton('add-image')?.show();
      viewer.navbar.getButton('add-hotspot')?.show();
    }
  }, [viewer, panoramicImage.image]);

  useLayoutEffect(() => {
    const panoViewer = new Viewer({
      container: `panoViewer-${panoramicImage.id}`,
      panorama: panoramicImage.image.url ?? '/missing.png',
      keyboard: 'always',
      navbar: ['zoom', 'fullscreen'],
      plugins: [
        [MarkersPlugin, { markers: hotspotsToMarkers(panoramicImage.hotspots) }],
      ],
    });
    const markersPlugin = panoViewer.getPlugin(MarkersPlugin) as MarkersPlugin;

    panoViewer.addEventListener('fullscreen', () => {
      markersPlugin.clearMarkers();

      const container = document.getElementById('hiddenHotspotEditor');
      ReactDOM.unmountComponentAtNode(container);

      ReactDOM.render(
        (<HiddenHotspots panoramicImage={panoramicImage} locale={locale} />),
        container,
      );

      markersPlugin.setMarkers(hotspotsToMarkers(panoramicImage.hotspots));
    });

    setViewer(panoViewer);
    setContainer(panoViewer.container);

    return () => panoViewer.destroy();
  }, [panoramicImage.hotspots, addImageModalManager.isOpen]);

  const addMarkerToViewer = async (e: { data: { yaw: number, pitch: number } }) => {
    if (!currentlyPlacing) return;

    const updatedHotspot = {
      ...currentlyPlacing,
      id: currentlyPlacing.id,
      placed: true,
      position: { pitch: e.data.pitch, yaw: e.data.yaw },
    };

    updateHotspot(updatedHotspot);
    setCurrentlyPlacing(null);
  };

  useEffect(() => {
    if (!viewer) return;

    viewer.addEventListener('click', addMarkerToViewer);

    // eslint-disable-next-line consistent-return
    return () => viewer.removeEventListener('click', addMarkerToViewer as any);
  }, [panoramicImage.hotspots, viewer, currentlyPlacing]);

  useEffect(() => {
    viewer?.setCursor(currentlyPlacing ? 'crosshair' : null);
  }, [currentlyPlacing]);

  useLayoutEffect(() => {
    if (panoramicImage.hotspots.length === 0) return;
    if (!viewer) return;

    (viewer.getPlugin(MarkersPlugin) as MarkersPlugin)?.setMarkers(hotspotsToMarkers(panoramicImage.hotspots));
  }, [viewer, panoramicImage.hotspots]);

  return (
    <>
      <div id="hiddenHotspotEditor" />
      <HiddenHotspots panoramicImage={panoramicImage} locale={locale} />
      <AddImageModal
        currentValues={panoramicImage || ({} as IPanoramicImage)}
        modalManager={addImageModalManager}
        slideObjectId={slideObjectId}
      />
      <div
        id={`panoViewer-${panoramicImage.id}`}
        className="tw-w-full tw-h-full tw-relative"
        key={`editor-${panoramicImage.id}`}
      />
    </>
  );
};

export default PanoramicImage;
