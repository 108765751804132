import React from 'react';
import I18n from 'modules/i18n';
import clsx from 'clsx';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import UnitType from 'types/graphql/Unit';
import LessonType from 'types/graphql/Lesson';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './TransitionSlide.module.scss';
import partyIcon from '../../../../../../../assets/images/party-icon.svg';
import { SlideGroup } from '../../Builder/CreateTransistionGroup/utils';

interface LeftPanelProps {
  imageUrl: string;
  isIntroSlide: boolean;
  slideGroup: SlideGroup;
}

const LeftPanel = ({ imageUrl, isIntroSlide, slideGroup }: LeftPanelProps) => {
  const { titleEn, titleEs } = slideGroup;
  const title = I18n.locale === 'es' ? titleEs : titleEn;
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const { lesson, unit } = slideShow;

  const modelTitle = (modelName: string, model: UnitType | LessonType) => {
    if (!model) return null;

    const stringParts = [I18n.t(modelName), model.position, '-', I18n.locale === 'es' ? model.titleEs : model.titleEn];

    return (
      <span>
        {stringParts.filter(e => e != null).join(' ')}
      </span>
    )
  };

  return (
    <section id="left-panel" className={styles.leftPanel}>
      {slideShow.activityType !== 'professional_development' && (
        <div className={styles.lessonImageTitle}>
          {imageUrl && <img className={styles.lessonImage} src={imageUrl} alt="lesson image" />}
          <div className={styles.lessonTitle}>
            {modelTitle('unit', unit)}
            {modelTitle('lesson', lesson)}
          </div>
        </div>
      )}
      <div
        className={clsx(styles.titleContainer, {
          '!tw-mt-[65%]': slideShow.activityType === 'professional_development',
        })}
      >
        {isIntroSlide ? (
          <>
            <span className={styles.letsStart}>{I18n.t('lets_start')}</span>
            <span className={styles.slideNavInstructions}>{I18n.t('slide_nav_instructions')}</span>
          </>
        ) : (
          <>
            <img className={styles.partyIcon} src={partyIcon} alt="" />
            <span>
              {I18n.t('you_just_finished')}
              :
            </span>
            <span className={styles.title}>{title}</span>
          </>
        )}
      </div>
    </section>
  );
};

export default LeftPanel;
