import React, { useEffect } from 'react';
import VocabDeckCard from '@/components/common/VocabularyCards/VocabDeckCard';
import { IUser } from '@/components/common/VocabularyCards/interfaces';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient, useVocabDecksStore } from '@/components/common/VocabularyCards/variables';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import IGlossaryTerm from '@/components/interfaces/GlossaryTerm';
import { useVocabStoreDecks } from '@/components/common/VocabularyCards/hooks/useDecks';

interface SharedProps {
  tciGlossaryTerms: IGlossaryTerm[];
}

const VocabularyCards = ({ tciGlossaryTerms }: SharedProps) => {
  const { data: decks, refetch } = useVocabStoreDecks();

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-16 tw-mt-10">
      <VocabDeckCard
        deck={{
          glossary_term_models: [],
          id: -1,
          staffer_id: 0,
          staffer: { first_name: 'TCI', last_name: 'TCI' },
          title_en: 'TCI Vocabulary Deck',
          title_es: 'Tarjetas de Vocabulario TCI',
          total_terms: tciGlossaryTerms.length,
          updated_at: '',
        }}
        refetch={refetch}
      />
      {decks.map(deck => (
        <VocabDeckCard key={deck.id} deck={deck} refetch={refetch} />
      ))}
    </div>
  );
};

interface Props extends SharedProps {
  currentUser: IUser;
  lessonId: number;
  programId: number;
}

const VocabularyCardsWrapper = ({ currentUser, lessonId, programId, tciGlossaryTerms }: Props) => {
  const store = useVocabDecksStore();

  useEffect(() => store.setStore({ currentUser, lessonId, programId }), [currentUser, lessonId, programId]);

  if (!(store.lessonId && store.programId && store.currentUser)) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <VocabularyCards tciGlossaryTerms={tciGlossaryTerms} />
    </QueryClientProvider>
  );
};

export default VocabularyCardsWrapper;
