import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import SysadminTable from '../../../common/SysadminTable';
import Form from './Form';
import Row from './Row';
import SourceTooltip from '../../../staffer/materials/SourceTooltip';
import { PrintIcon, KitIcon, TeacherIcon } from '../../../common/Icons';
import { ModalButton } from '../Utils';
import MaterialsActionsDropdown from './MaterialsActionsDropdown';

export default class Table extends Component {
  static propTypes = {
    admin: PropTypes.bool,
    lessonName: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    emptyMessage: PropTypes.string,
    filters: PropTypes.objectOf(PropTypes.object),
    forSelect: PropTypes.objectOf(PropTypes.object),
    handoutThumb: PropTypes.string,
    headerLink: PropTypes.objectOf(PropTypes.string),
    isLoading: PropTypes.bool,
    isScience: PropTypes.bool,
    kit: PropTypes.arrayOf(PropTypes.object),
    kitMaterialsUrl: PropTypes.string,
    lessonId: PropTypes.number,
    materials: PropTypes.arrayOf(PropTypes.object),
    modalIsOpen: PropTypes.bool,
    modelType: PropTypes.string,
    presentationGuide: PropTypes.string,
    previewUrl: PropTypes.string,
    print: PropTypes.arrayOf(PropTypes.object),
    safetyNotes: PropTypes.arrayOf(PropTypes.object),
    teacher: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    admin: false,
    lessonName: '',
    columns: [],
    emptyMessage: null,
    filters: {},
    forSelect: null,
    handoutThumb: '',
    headerLink: null,
    isLoading: false,
    isScience: false,
    kit: [],
    kitMaterialsUrl: '',
    lessonId: null,
    materials: [],
    modalIsOpen: false,
    modelType: 'LessonModule',
    presentationGuide: null,
    previewUrl: '#',
    print: [],
    safetyNotes: [],
    teacher: []
  };

  static sortByName(a, b) {
    return (a.handoutTitle || a.name).localeCompare(b.handoutTitle || b.name);
  }

  static addMaterialFilter(material_array, used_in_options) {
    material_array.forEach((material) => {
      if (material.moduleType === 'Lesson') {
        if (!used_in_options.find(arr_el => arr_el.label === 'Lesson')) {
          used_in_options.push({ label: 'Lesson', value: 'lesson' });
        }
      }
      else if (material.used_in &&
        material.used_in.title &&
        !used_in_options.find(arr_el => arr_el.label === material.used_in.title)) {
        used_in_options.push({ label: material.used_in.title, value: material.used_in.title.replace(/[\W_]+/g, '').toLowerCase() });
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = props;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addRows = this.addRows.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.filterChange = this.filterChange.bind(this);
  }

  componentDidMount() {
    this.getLessonMaterials();
  }

  getLessonMaterials() {
    this.setState({ isLoading: true });
    Axios.get(`/staffer/lessons/${this.state.modelId}/materials/get_lesson_materials?program_id=${this.state.programId}&source_program_id=${this.state.sourceProgramId}`).then((response) => {
      const {
        lesson_materials, forSelect, units, modules
      } = response.data;
      this.setState({
        teacher: lesson_materials.teacher || [],
        kit: lesson_materials.kit || [],
        print: lesson_materials.print || [],
        forSelect,
        modules: modules.lesson_modules,
        units,
        isLoading: false,
        material_modules: this.getMaterialModuleMapping(lesson_materials)
      }, this.setColumns);
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  // returns a map of all modules that each material is already associated with (used to prevent duplicates)
  getMaterialModuleMapping(materials) {
    const mapping = {};
    const materials_merged = (materials.kit || []).concat(materials.print || []).concat(materials.teacher || []);
    materials_merged.forEach(function(row) {
      if (mapping[row.materialId]) {
        mapping[row.materialId].push(row.modelId);
      }
      else {
        mapping[row.materialId] = [row.modelId];
      }
    });
    return mapping;
  }

  setSourceFilter() {
    const source_options = [];
    let source_filter = {};

    if (this.state.teacher && this.state.teacher.length) {
      source_options.push({ label: 'Teacher Supplied', value: 'teachersupplied' });
    }

    if (this.state.kit && this.state.kit.length) {
      source_options.push({ label: 'Kit', value: 'kit' });
    }

    if (this.state.presentationGuide || (this.state.print && this.state.print.length)) {
      source_options.push({ label: 'Print', value: 'print' });
    }

    if (source_options.length > 1) {
      source_filter = {
        name: 'source',
        options: source_options,
        selectAll: true
      };
    }

    return source_filter;
  }

  setUsedInFilter() {
    const used_in_options = [];
    let used_in_filter = {};

    if (this.state.presentationGuide) {
      used_in_options.push({ label: 'Lesson', value: 'lesson' });
    }

    if (this.state.teacher) {
      Table.addMaterialFilter(this.state.teacher, used_in_options);
    }

    if (this.state.kit) {
      Table.addMaterialFilter(this.state.kit, used_in_options);
    }

    if (this.state.print) {
      Table.addMaterialFilter(this.state.print, used_in_options);
    }

    if (used_in_options.length > 1) {
      used_in_filter = {
        name: 'used_in',
        options: used_in_options,
        selectAll: true
      };
    }

    return used_in_filter;
  }

  setColumns() {
    const source_filter = this.setSourceFilter();
    const used_in_filter = this.setUsedInFilter();

    const new_columns = this.state.columns || this.props.columns;

    new_columns[3].filter = used_in_filter;
    new_columns[4].filter = source_filter;

    this.setState({ columns: new_columns });
  }

  addRows(materials) {
    const normalized_materials = Array.isArray(materials) ? materials : [materials];

    if (normalized_materials[0].errors) { return; }

    switch (normalized_materials[0].programScopedSource.title) {
      case 'Kit':
        this.setState(prevState => ({
          kit: [...prevState.kit, ...normalized_materials].sort(Table.sortByName)
        }), this.setColumns);
        break;
      case 'Print':
        this.setState(prevState => ({
          print: [...prevState.print, ...normalized_materials].sort(Table.sortByName)
        }), this.setColumns);
        break;
      default:
        this.setState(prevState => ({
          teacher: [...prevState.teacher, ...normalized_materials].sort(Table.sortByName)
        }), this.setColumns);
        break;
    }
  }

  updateRow(updatedMaterial) {
    this.setState({
      materials: this.state.materials.map(material => (updatedMaterial.id === material.id ? updatedMaterial : material))
        .sort(this.sortByName)
    }, this.setColumns);
  }

  removeRow(id) {
    this.setState({
      teacher: (this.state.teacher && this.state.teacher.filter(material => material.id !== id)),
      print: (this.state.print && this.state.print.filter(material => material.id !== id)),
      kit: (this.state.kit && this.state.kit.filter(material => material.id !== id))
    }, this.setColumns);
  }

  filterChange(opts) {
    const filter_obj = this.state.filters;

    Object.keys(opts.active).forEach((key) => {
      filter_obj[opts.name] = filter_obj[opts.name] || {};
      filter_obj[opts.name][key] = opts.active[key];
    });

    this.setState(filter_obj);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  filteredOut(material) {
    // If no filters were adjusted, the default is to show everything
    if (Object.keys(this.state.filters).length === 0) return false;
    let filtered = false;

    if (this.state.filters.source && this.state.filters.source[material.programScopedSource.title.split(' ').join('').toLowerCase()] === false) {
      filtered = true;
    }

    if (this.state.filters.usedIn && this.state.filters.usedIn[material.used_in.title.replace(/[\W_]+/g, '').toLowerCase()] === false) {
      filtered = true;
    }

    return filtered;
  }

  renderPresentationGuide(pg) {
    if (!pg) return null;

    // Since the PresentationGuide is directly added, it isn't filtered automatically.
    // So we do a filter check
    const hide_pg = this.state.filters && (this.state.filters.source && this.state.filters.source.print === false) ||
      (this.state.filters.usedIn && this.state.filters.usedIn.lesson === false);

    if (pg === undefined || hide_pg) { return false; }

    return (
      <tr className="print all">
        <td className="image">
          <img src={this.props.handoutThumb} alt="PDF Icon" />
        </td>
        <td className="name">
          <a href={pg} target="_blank">Lesson Guide</a>
        </td>
        <td className="quantity">
          1 per teacher
        </td>
        <td className="used-in">Lesson</td>
        <td className="src">
          <SourceTooltip
            kind="print"
            icon={<PrintIcon />}
            tooltipText="Print"
            lessonGuideUrl={pg}
          />
        </td>
        <td className="safety-notes" />
        {this.renderGuideActionCell(pg)}
      </tr>
    );
  }

  renderGuideActionCell(presentation_guide_url) {
    const dropdown = (
      <MaterialsActionsDropdown
        downloadURL={presentation_guide_url}
        downloadTitle={`${this.props.lessonName} Lesson Guide`.trim()}
        id="presentation-guide-actions"
      />
    );

    return (
      <td>
        {!this.state.admin && dropdown}
      </td>
    );
  }

  renderMaterials(materials) {
    return materials && materials.map((material) => {
      if (this.filteredOut(material)) {
        return false;
      }
      return (
        <Row
          key={material.id}
          newRecord={false}
          units={this.state.units}
          removeRow={this.removeRow}
          updateRow={this.updateRow}
          addRows={this.addRows}
          kitMaterialsUrl={this.props.kitMaterialsUrl}
          usedIn={material.usedIn}
          admin={this.state.admin}
          allModules={this.state.modules}
          forSelect={this.state.forSelect}
          programKitMaterials={this.state.programKitMaterials}
          distributions={this.state.distributions}
          material_modules={this.state.material_modules}
          lessonId={this.state.lessonId}
          {...material}
        />
      );
    });
  }

  renderButtonAndLink() {
    return (
      <div className="h35 inline_block pos_fr" >
        {this.renderAddMaterialButton()}
        {this.renderHeaderLink()}
      </div>
    );
  }

  renderHeaderLink() {
    if (this.props.headerLink) {
      return (
        <a href={this.props.headerLink.url} className="btn btn--outline-purple">{this.props.headerLink.text}</a>
      );
    }
    return null;
  }

  renderAddMaterialButton() {
    if (!this.state.admin) return false;

    return (
      <ModalButton
        buttonText="Add Material"
        buttonClass="btn btn--green mr10"
        modalHeader="Add Material"
        modalClass="add-materials"
        newRecord
        openModal={this.openModal}
        closeModal={this.closeModal}
        onAdd={this.addRows}
        FormComponent={Form}
        allModules={this.state.modules}
        {...this.state}
      />
    );
  }

  render() {
    return (
      <SysadminTable
        containerClass="lesson-materials"
        tableId="module-materials"
        columns={this.state.columns}
        isLoading={this.state.isLoading}
        buttonAbove={this.renderButtonAndLink()}
        emptyMessage={this.props.emptyMessage}
        filterChange={this.filterChange}
      >
        {this.renderMaterials(this.state.kit)}
        {this.renderMaterials(this.state.teacher)}
        {this.renderPresentationGuide(this.state.presentationGuide)}
        {this.renderMaterials(this.state.print)}
      </SysadminTable>
    );
  }
}
