import React, { useState } from 'react';
import { ImageModelResponseType } from '@/components/admin/QuestionImage/utils';
import Select from 'react-select-5';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_image_model_path } from '@/modules/routes';

interface DisplayLocationDropdownProps {
  afterSubmit: () => any;
  imageModel: ImageModelResponseType;
  label?: string;
}

const OPTIONS = [
  { label: 'Background', value: 'background' },
  { label: 'Below prompt', value: 'stimulus' },
] as const;

const useDisplayLocationMutation = (afterSubmit: () => any, imageModel: ImageModelResponseType) => useMutation({
  mutationFn: async ({ type }: { type: 'background' | 'stimulus' }) => {
    const data = { image_model: { id: imageModel.id, media_meta: { ...imageModel.media_meta, type } } };

    return axios.put(plato_api_image_model_path(imageModel.id), data).then(res => res.data);
  },
  onSuccess: () => afterSubmit(),
});

export default function DisplayLocationDropdown({
  afterSubmit,
  imageModel,
  label = 'Display Location:',
}: DisplayLocationDropdownProps) {
  const [dropdownValue, setDropdownValue] = useState(OPTIONS.find(opt => opt.value === imageModel.media_meta.type));
  const { mutate } = useDisplayLocationMutation(afterSubmit, imageModel);

  const handleChange = (opt) => {
    mutate({ type: opt.value });
    setDropdownValue(opt);
  };

  return (
    <>
      <span className="tw-inline-block tw-font-bold tw-pb-1">{label}</span>
      <Select
        options={OPTIONS}
        onChange={handleChange}
        value={dropdownValue}
      />
    </>
  );
}
