/**
 * Component for creating a Slider Question.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Axios from 'axios';
import SliderPointInput from './SliderPointInput';
import styles from './SliderQuestionBuilder.module.scss';
import AutoSave from '../AutoSave';

const MINIMUM_POINTS = 2;
const MAXIMUM_POINTS = 6;

export default class SliderQuestionBuilder extends Component {
  static propTypes = {
    currentLocaleId: PropTypes.number,
    initialValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    questionId: PropTypes.number.isRequired,
    questionTranslationId: PropTypes.number.isRequired,
    updateAnswerChoicesPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    currentLocaleId: 0
  };

  static processNewValues(values) {
    return Object.values(values).map(value => value || '');
  }

  static showDeleteButtons(pointsCount) {
    return pointsCount > MINIMUM_POINTS;
  }

  static renderSliderPoints(form, sliderPointKeys) {
    return sliderPointKeys.map((key, index) => (
      <SliderPointInput
        key={key}
        id={key}
        index={index}
        onDelete={form.mutators.removeField}
        showDelete={SliderQuestionBuilder.showDeleteButtons(sliderPointKeys.length)}
      />
    ));
  }

  constructor(props) {
    super(props);

    const pointsCount = this.props.initialValues.length;

    this.state = {
      nextPointIndex: pointsCount,
      pointsCount: pointsCount,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeField = this.removeField.bind(this);
    this.addField = this.addField.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.pointId = this.pointId.bind(this);
  }

  getSaveSuccessfulId() {
    return `question-${this.props.questionId}-save-successful`;
  }

  getInitialValues() {
    const initialValues = {};

    this.props.initialValues.forEach((value, index) => {
      initialValues[this.pointId(index)] = value;
    });

    return initialValues;
  }

  autoSave = async (values) => {
    await this.handleSubmit(values);
  };

  handleSubmit(values) {
    const newValues = SliderQuestionBuilder.processNewValues(values);

    const requestData = this.buildRequestData(newValues);

    return Axios
      .put(this.props.updateAnswerChoicesPath, requestData)
      .then((response) => {
        const data = response.data;
        if (data.errors) {
          console.log(data.errors);
        }
        else {
          this.flashSaveSuccessfulNotice();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  buildRequestData(valuesToSend) {
    return ({
      question: {
        translations_attributes: {
          [this.props.currentLocaleId]: {
            additional_data: {
              answer_choices: valuesToSend
            },
            id: this.props.questionTranslationId
          }
        }
      }
    });
  }

  flashSaveSuccessfulNotice() {
    $(`#${this.getSaveSuccessfulId()}`)
      .fadeTo(1500, 1)
      .fadeTo(1500, 0);
  }

  pointId(index) {
    return `question-${this.props.questionId}-answer-choice-${index}-text-en`;
  }

  updatePointsCount(pointsCount) {
    this.setState({ pointsCount: pointsCount });
  }

  showingMaximumPoints() {
    return this.state.pointsCount >= MAXIMUM_POINTS;
  }

  removeField(args, state) {
    const fieldId = args[0];

    delete state.formState.values[fieldId];

    const newPointsCount = Object.keys(state.formState.values).length;
    this.updatePointsCount(newPointsCount);

    this.forceUpdate();

    this.handleSubmit(state.formState.values);
  }

  addField(args, state) {
    const newPointId = this.pointId(this.state.nextPointIndex);

    this.setState(prevState => ({ nextPointIndex: prevState.nextPointIndex + 1 }), () => {
      state.formState.values[this.pointId(newPointId)] = '';

      const newPointsCount = Object.keys(state.formState.values).length;
      this.updatePointsCount(newPointsCount);

      this.forceUpdate();

      this.handleSubmit(state.formState.values);
    });
  }

  renderSliderLine() {
    return (
      <div
        className={`${styles.line} ${this.showingMaximumPoints() ? styles.lineWithoutAddButton : styles.lineWithAddButton}`}
      />
    );
  }

  renderAddPointButton(form) {
    if (this.showingMaximumPoints()) return null;

    return (
      <i
        key="add"
        className={`fa fa-plus ${styles.addPointButton}`}
        aria-hidden="true"
        onClick={() => form.mutators.addField()}
      />
    );
  }

  renderSuccessfulSaveNotice() {
    return (
      <div
        id={this.getSaveSuccessfulId()}
        className={styles.saveSuccessful}
      >
        <i
          className="fa fa-check fa-fw"
          aria-hidden="true"
        />
        Slider Labels Saved
      </div>
    );
  }

  renderForm({ form, handleSubmit, values }) {
    const keys = Object.keys(values);

    return (
      <form
        className={styles.nodes}
        onSubmit={handleSubmit}
      >
        <AutoSave save={this.autoSave} />
        {SliderQuestionBuilder.renderSliderPoints(form, keys)}
        {this.renderAddPointButton(form)}
      </form>
    );
  }

  render() {
    return (
      <div>
        {this.renderSuccessfulSaveNotice()}
        <div className={styles.container}>
          {this.renderSliderLine()}
          <Form
            initialValues={this.getInitialValues()}
            onSubmit={this.handleSubmit}
            mutators={{
              addField: this.addField,
              removeField: this.removeField
            }}
            render={this.renderForm}
          />
        </div>
      </div>
    );
  }
}
