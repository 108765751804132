import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import Spinner from '../../Spinner';
import ShapeCategory from './ShapeCategory';
import useShapesStore, { IShape } from './useShapesStore';

interface ShapeCategoriesProps {
  category: string;
  setCategory: (category: string) => void;
}

const formatShapes = (shapes) => {
  const formattedShapes = shapes.reduce((acc, shape) => {
    if (acc[shape.category_id]) {
      acc[shape.category_id].push(shape);
    } else {
      acc[shape.category_id] = [shape];
    }
    return acc;
  }, {});

  Object.keys(formattedShapes).forEach((key) => {
    formattedShapes[key].sort((a, b) => a.position - b.position);
  });

  return formattedShapes;
};

const formatCategoryMap = categories => (
  categories.reduce((acc, shape) => {
    acc[shape.category] = shape.category_id;
    return acc;
  }, {})
);

const ShapeCategories = ({ category, setCategory }: ShapeCategoriesProps) => {
  const setShapes = useShapesStore(state => state.setShapes);
  const setCategoryMap = useShapesStore(state => state.setCategoryMap);
  const shapes = useShapesStore(state => state.shapes);
  const shapesLoaded = Object.keys(shapes).length > 0;
  const [isLoading, setIsLoading] = useState(!shapesLoaded);

  useEffect(() => {
    if (shapesLoaded) return;

    Axios.get<{ data: IShape[] }>(Routes.plato_api_cliparts_path()).then((data) => {
      setShapes(formatShapes(data.data.data));
      setCategoryMap(formatCategoryMap(data.data.data));
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading && (<Spinner />)}
      {Object.keys(shapes).map(categoryId => (
        <ShapeCategory
          categoryId={categoryId}
          title={shapes[categoryId][0].category}
          key={categoryId}
          category={category}
          setCategory={setCategory}
        />
      ))}
    </div>
  );
};

export default ShapeCategories;
