import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { renderErrors, RequiredAsterisk } from '../../../common/Forms/Utils';
import { Footer } from '../../../common/Modal';
import styles from '../Form.module.scss';
import NumberInput from './NumberInput';
import DateField from './DateField';
import showToast from '../../../common/Toast';

export default class CompForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    compPath: PropTypes.string.isRequired,
    licenses: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })),
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    updateTable: PropTypes.func.isRequired
  };

  static defaultProps = { licenses: null };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  handleSubmit(values) {
    this.state = { submitting: true };

    return (
      Axios
        .post(this.props.compPath, values)
        .then((response) => {
          if (response.data.errors) {
            this.setState({ submitting: false });
            console.log(response.data.errors);
          }
          else {
            this.props.updateTable(response.data.subscription, 'add');
            showToast('Temp Access successful.');
            this.props.closeModal();
          }
        })
        .catch((error) => {
          this.setState({ submitting: false });
          console.log(error.response.data.errors);
          return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
        })
    );
  }

  handleSelect(args, state, utils) {
    if (this.props.licenses == null) {
      this.setState({ program: args[0] });

      const updatedValue = args[0] ? args[0].value : null;
      utils.changeValue(state, 'program_id', () => updatedValue);
    }
    else {
      this.setState({ license: args[0] });

      const updatedValue = args[0] ? args[0].value : null;
      utils.changeValue(state, 'license_id', () => updatedValue);
    }
  }

  validateForm(values) {
    const errors = {};

    if (parseInt(values.teacher_seats_count, 10) === 0 && parseInt(values.student_seats_count, 10) === 0) {
      errors.seats_count = 'You must enter at least 1 Teacher Sub or 1 Student Sub.';
    }

    if (values.comp_notes === undefined) {
      errors.comp_notes = 'You must enter a note.';
    }

    return errors;
  }

  renderLicenseSelect(handleSelect) {
    return (
      <div className={styles.formRow}>
        <label htmlFor="license_id" className={styles.label}>
          License
          <RequiredAsterisk />
        </label>
        <Select
          id="license_id"
          className={`${styles.select}`}
          searchable
          required
          onChange={handleSelect}
          value={this.state.license}
          options={this.props.licenses}
        />
      </div>
    );
  }

  renderSelector(handleSelect) {
    return this.renderLicenseSelect(handleSelect);
  }

  renderForm(form, handleSubmit, submitError, hasValidationErrors) {
    return (
      <form onSubmit={handleSubmit}>
        {this.renderSelector(form.mutators.handleSelect)}

        <NumberInput
          id="teacher_seats_count"
          label="Teacher License Count"
          placeholderText="Enter quantity"
          formRowClass={styles.formRow}
          labelClass={styles.label}
          inputClass={`${styles.textInput} ${styles.comp}`}
          required
        />

        <NumberInput
          id="student_seats_count"
          label="Student License Count"
          placeholderText="Enter quantity"
          formRowClass={styles.formRow}
          labelClass={styles.label}
          inputClass={`${styles.textInput} ${styles.comp}`}
          required
        />

        <DateField
          id="access_end_date"
          label="Access End Date"
          placeholderText="MM / DD / YYYY"
          formRowClass={styles.formRow}
          labelClass={styles.label}
          inputClass={`${styles.textInput} ${styles.datepicker}`}
          handleFormChange={form.mutators.handleDateSelect}
          required
        />

        <div className={styles.formRow}>
          <label htmlFor="comp_notes" className={styles.label}>
            Note
            <RequiredAsterisk />
          </label>
          <Field
            id="comp_notes"
            name="comp_notes"
            component="textarea"
            className={styles.textArea}
            required
            placeholder="Please enter reason and/or requestor info."
          />
        </div>

        <Footer
          primaryButtonDisabled={hasValidationErrors}
          secondaryButtonCallback={this.props.closeModal}
          submitting={this.state.submitting}
        />
      </form>
    );
  }

  render() {
    return (
      <Form
        initialValues={{
          student_seats_count: 0,
          teacher_seats_count: 0
        }}
        mutators={{
          handleDateSelect: DateField.handleFormChange,
          handleSelect: this.handleSelect
        }}
        onSubmit={this.handleSubmit}
        validate={this.validateForm}
        render={(
          {
            form, handleSubmit, submitError, hasValidationErrors
          }
        ) => (this.renderForm(form, handleSubmit, submitError, hasValidationErrors))}
      />
    );
  }
}
