import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'react-cookies';
import HorizontalScrollMenu, { MenuItem as HorizontalScrollMenuItem } from '@/components/common/HorizontalScrollMenu';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import I18n from 'i18n';
import { initVideoPlayers } from '../../../modules/TCIUtils';
import styles from './LessonPlanning.module.scss';
import { redirectLinks } from '../../../modules/ExternalRedirect';
import Tooltip from '../Tooltip';

const MenuItem = ({ text, selected }) => (
  <button
    type="button"
    role="tab"
    aria-selected={selected}
    aria-controls={selected ? 'resourcesContainer' : null}
    className={`${styles.menuItem} ${selected ? styles.active : ''}`}
    tabIndex={selected ? -1 : null}
  >
    {text}
  </button>
);

MenuItem.propTypes = {
  ariaControlId: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export const Menu = (resources, selected) => (
  resources.map((resource) => {
    const resourceId = `resource-${resource.id}`;

    return (
      <MenuItem
        text={resource.title}
        key={resourceId}
        selected={!!selected}
      />
    );
  })
);

const LessonPlanning = ({ allowPrint = false, pdfPath = '', resources, displayStudentWarning = true }) => {
  const [selected, setSelected] = useState(`resource-${resources[0].id}`);
  const [hidden, setHidden] = useState(true);
  const [containerHeight, setContainerHeight] = useState(0);
  const resourcesContainer = useRef(<div />);
  const [planningCollapsed, setPlanningCollapsed] = useState(false);

  const togglePlanningCollapsed = () => setPlanningCollapsed((prevState) => {
    Cookie.save('planningCollapsed', !prevState, { path: '/' });
    if (!prevState && !hidden) setHidden(true);
    return !prevState;
  });

  const html = resources.find(resource => `resource-${resource.id}` === selected).html;
  const menu = Menu(resources, selected);

  const recalculateHeight = () => {
    const _hidden = hidden; // Save the current hidden state
    setHidden(false); // un-hide the container to get its full height
    if (resourcesContainer.current) {
      setContainerHeight(resourcesContainer.current.offsetHeight);
    }
    setHidden(_hidden); // restore the original hidden state
    return () => {};
  };

  const onSelect = (key) => {
    setSelected(key);
    if (planningCollapsed) togglePlanningCollapsed();
  };

  const seeMoreButton = () => {
    if (containerHeight > 159) {
      return (
        <button
          id="seeMoreButton"
          type="button"
          className="btn btn--link-purple"
          onClick={() => {
            if (planningCollapsed && hidden) togglePlanningCollapsed();
            setHidden(!hidden);
          }}
        >
          {hidden ? I18n.t('see_more') : I18n.t('see_less')}
          <i className={`fa ${hidden ? 'fa-caret-down' : 'fa-caret-up'} ml5`} aria-hidden />
        </button>
      );
    }

    return null;
  };

  const printButton = () => {
    if (pdfPath) {
      return (
        <Tooltip
          interactive={false}
          content="Print Planning"
        >
          <a
            aria-label="Print Planning"
            className={`btn btn--link-purple ${styles.planningActions}`}
            href={pdfPath}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i aria-hidden className={`fa fa-print`}/>
          </a>
        </Tooltip>
      );
    }

    return null;
  }

  const expandCollapseButton = () => {
    const buttonText = `${planningCollapsed ? 'Expand' : 'Collapse'} Planning`;

    return (
      <Tooltip
        interactive={false}
        content={buttonText}
      >
        <button
          aria-label={buttonText}
          className={`btn btn--link-purple ${styles.planningActions}`}
          id="expand-btn"
          onClick={togglePlanningCollapsed}
          type="button"
        >
          <i aria-hidden className={`fa ${planningCollapsed ? 'fa-expand' : 'fa-compress'}`}/>
        </button>
      </Tooltip>
    )
  }

  useEffect(() => {
    resourcesContainer.current = document.getElementById('resourcesContainer');
    recalculateHeight();
    if (containerHeight > 159) setHidden(true);
    setPlanningCollapsed(Cookie.load('planningCollapsed') === 'true');
  }, []);

  useEffect(() => {
    recalculateHeight();
    initVideoPlayers();
    redirectLinks(resourcesContainer.current);
  }, [selected]);

  return (
    <Fragment>
      <div className="text--right">
        {allowPrint && printButton()}
        {expandCollapseButton()}
      </div>
      <div className={`${styles.border} ${planningCollapsed ? styles.planningCollapsed : ''}`}>
        <div className={styles.label}>
          <span className={styles.primary}>
            {I18n.t('planning')}
            <div className={styles.secondary}>
              {displayStudentWarning && I18n.t('not_visible_to_students')}
            </div>
          </span>
        </div>
        <div className="tw-pl-[5px]">
          <HorizontalScrollMenu hideArrows>
            {resources.map((resource) => {
              const resourceId = `resource-${resource.id}`;

              return (
                <HorizontalScrollMenuItem
                  key={resourceId}
                  className={styles.menuItem}
                  selected={resourceId === selected}
                  text={resource.title}
                  {...useKeydownAndClick(() => setSelected(resourceId))}
                />
              );
            })}
          </HorizontalScrollMenu>
        </div>
        <div
          id="resourcesContainerWrapper"
          className={styles.resourcesContainerWrapper}
          aria-expanded={!planningCollapsed}
          aria-controls="expand-btn"
        >
          <div
            id="resourcesContainer"
            className={`${styles.resourcesContainer} ${hidden ? styles.hidden : ''} fr-view`}
          >
            <div
              className={`${styles.html} allow-list-style model-resource-container`}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          {seeMoreButton()}
        </div>
      </div>
    </Fragment>
  );
};

LessonPlanning.propTypes = {
  allowPrint: PropTypes.bool,
  displayStudentWarning: PropTypes.bool,
  pdfPath: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.shape({
    html: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  })).isRequired
};

export default LessonPlanning;
