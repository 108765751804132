import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'components/common/ApolloClient';
import DataTable from 'components/common/DataTable';
import { SlideShowContext } from '@/components/admin/SlideShow/stores/SlideShowStoreProvider';
import ActionsDropdown from 'components/common/ActionsDropdown';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './CreateTransitionGroup.module.scss';
import DestroySlideGroupButton from './DestroySlideGroupButton';
import { EDITING_SLIDE_GROUP, SetCurrentAction, SlideGroup } from './utils';
import AddGroupButton from './AddGroupButton';

type CreateSlideGroupsContentProps = {
  slideShowId: number;
  setCurrentAction: SetCurrentAction;
  setCurrentSlideGroupId: (nextSlideGroupId: number) => void;
};

const CreateSlideGroupsContent = ({
  setCurrentAction,
  setCurrentSlideGroupId,
  slideShowId,
}: CreateSlideGroupsContentProps) => {
  const slideGroups: SlideGroup[] = useSlideShowContext(state => state.slideShow.slideGroups);
  const storeRef = useSlideShowContext(state => state.storeRef);

  const tableData = slideGroups
    .map(slideGroup => ({
      id: slideGroup.id,
      position: slideGroup.position,
      title: slideGroup.titleEn,
      slidesLength: slideGroup.slides.length,
      actions: '',
    }));

  return (
    <>
      <DataTable
        columns={['position', 'title', 'slidesLength', 'actions']}
        columnDefs={[
          {
            targets: 0,
            width: '10%',
          },
          {
            targets: 2,
            title: '# of Slides',
          },
          {
            sortable: false,
            targets: 3,
            createdCell: (td, _cellData, rowData) => {
              ReactDOM.render(
                <ApolloProvider client={apolloClient}>
                  <ActionsDropdown id={`slide-group-${rowData.id}-actions`} toggleText="Actions">
                    <button
                      className={`${styles.dropdownItem} dropdown-item`}
                      onClick={() => {
                        setCurrentSlideGroupId(rowData.id);
                        setCurrentAction(EDITING_SLIDE_GROUP);
                      }}
                      type="button"
                    >
                      <i className="fa fa-pencil-square-o" />
                      Edit
                    </button>
                    <SlideShowContext.Provider value={storeRef.current}>
                      <DestroySlideGroupButton id={rowData.id} />
                    </SlideShowContext.Provider>
                  </ActionsDropdown>
                </ApolloProvider>,
                td,
              );
            },
          },
        ]}
        defaultOrder={[0, 'asc']}
        handleResourcesManually
        info={false}
        noRecordsMessage={`
          No slide groups have been added for this slideshow.
          To create a group, click the Add Group button below.
        `}
        paging={false}
        resources={tableData}
      />
      <AddGroupButton slideShowId={slideShowId} />
    </>
  );
};

export default CreateSlideGroupsContent;
