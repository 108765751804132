import React, { useEffect } from 'react';
import { getSlideShowImageQuery } from '../SlideShow/Utils/useSlideShowImageFetcher';
import { useSlideShowIds } from './SectionEditorUtils';
import { IImageModel } from '../SlideShow/stores/useSlideShowStore';

interface ImageQueryFetcherProps {
  ids: number[];
  setLessonImages: React.Dispatch<React.SetStateAction<{ [key: number]: IImageModel[] }>>;
}
const ImageQueryFetcher = ({ ids, setLessonImages }: ImageQueryFetcherProps) => {
  const queries = ids.map(id => getSlideShowImageQuery(id));

  useEffect(() => {
    queries.forEach(async (query, i) => {
      const queryData = await query();

      setLessonImages(prev => ({ ...prev, [i]: queryData }));
    });
  }, []);

  return null;
};

type ImageFetcherProps = { lessonId: number | string } & Omit<ImageQueryFetcherProps, 'ids'>;

const ImageFetcher = ({ lessonId, setLessonImages }: ImageFetcherProps) => {
  const { data } = useSlideShowIds(lessonId);

  if (data.length === 0) return null;

  return <ImageQueryFetcher ids={data.map(({ id }) => id)} setLessonImages={setLessonImages} />;
};

export default ImageFetcher;
