import React, { Component } from 'react';
import PropTypes from 'prop-types';

const SafetyNotes = ({ safetyNotes, checked, setSafetyNotes, id }) => {
  const renderSafetyNotes = () => {
    if (safetyNotes.length === 0)
      return (<div className="error-message">There are no safety notes.</div>);

    return safetyNotes.map((note =>
      <SafetyNote {...note}
                  key={note.title}
                  checked={checked}
                  setSafetyNotes={setSafetyNotes} />
    ));
  };

  return (
    <div className="form-row">
      <div className="safety-notes-container">
        <label htmlFor={id}>Safety Notes</label>
        <div className="safety-notes">
          <div>
            {renderSafetyNotes()}
          </div>
        </div>
      </div>
    </div>
  );
};

SafetyNotes.defaultProps = {
  safetyNotes: [],
  checked: [],
  setSafetyNotes: () => {},
  id: 'safety-note-0'
};

SafetyNotes.propTypes = {
  safetyNotes: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  setSafetyNotes: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};


export const SafetyNote = ({ id, imageUrl, title, checked, setSafetyNotes }) => {
  const isChecked = id => checked.includes(id);

  const handleChange = (id, e) => {
    let isChecked = e.target.checked ?
      [...checked, id] :
      checked.filter(item => item !== id);
    setSafetyNotes(isChecked);
  };

  return (
    <label key={id} className={`safety-note ${isChecked(id) ? 'selected' : '' }`}>
        <span>
          <input type="checkbox"
                 checked={isChecked(id)}
                 onChange={(e) => handleChange(id, e)}/>
          <div className="img-container">
            <img src={imageUrl}/>
          </div>
          <div className="text-container">
            {title}
          </div>
        </span>
    </label>
  );
};

SafetyNote.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.array.isRequired,
  setSafetyNotes: PropTypes.func.isRequired
};

SafetyNote.defaultProps = {
  id: 0,
  imageUrl: '/missing.png',
  title: '',
  checked: [],
  setSafetyNotes: () => {}
};

export default SafetyNotes;

