import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BuilderContext from 'common/Context';
import styles from './DraggableChoiceEditor.module.scss';
import DraggableChoice from './DraggableChoice';
import { sortedDraggableChoicesByPosition, reorderedDraggableChoiceParams, REORDER_LEFT, REORDER_RIGHT } from './Utils';

const OrderingControls = ({ choiceNumber, draggableChoiceKey, sortedDraggableChoices }) => {
  const { draggableChoiceDispatch } = useContext(BuilderContext);

  const handleReorder = (direction) => {
    reorderedDraggableChoiceParams(draggableChoiceKey, sortedDraggableChoices, direction).map(payload => {
      draggableChoiceDispatch({ payload, type: 'UPDATE' })
    });
  };

  const reorderButton = (direction) => {
    const [iconDirection, iconStyle] = direction === REORDER_LEFT ?
      ['left', styles.chevronLeftIcon] :
      ['right', styles.chevronRightIcon];

    return (
      <div className={`fa-stack ${styles.iconWrapper}`} onClick={() => handleReorder(direction)}>
        <i className={`fa fa-circle fa-stack-2x ${styles.circleIcon}`} />
        <i className={`fa fa-chevron-${iconDirection} fa-stack-1x fa-inverse ${iconStyle}`} />
      </div>
    );
  };

  return (
    <div className={styles.choiceNumberWithControls}>
      <div className="mb5">{`${choiceNumber}.`}</div>
      {choiceNumber !== 1 && <div className="mb5">{reorderButton(REORDER_LEFT)}</div>}
      {choiceNumber !== sortedDraggableChoices.length && reorderButton(REORDER_RIGHT)}
    </div>
  );
};

const DraggableChoiceEditor = ({ draggableChoices }) => {
  const { draggableChoiceDispatch } = useContext(BuilderContext);
  const handleClick = () => draggableChoiceDispatch({ type: 'ADD' });
  const sortedDraggableChoices = sortedDraggableChoicesByPosition(draggableChoices);

  const renderChoices = () => (
    sortedDraggableChoices.map(([key, draggableChoice], i) => {
      return (
        <div key={`draggableChoice-editor-${key}`} className={styles.numberedChoiceContainer}>
          <OrderingControls
            key={`draggableChoice-orderingControls-${key}`}
            draggableChoiceKey={key}
            choiceNumber={i + 1}
            sortedDraggableChoices={sortedDraggableChoices} />

          <DraggableChoice
            key={`draggableChoice-${key}`}
            id={key}
            hideDelete={sortedDraggableChoices.length === 1}
            draggableChoices={draggableChoices}
            {...draggableChoice}
          />
        </div>
      );
    })
  );

  return (
    <div className={styles.draggableChoiceContainer}>
      {renderChoices()}
      <div
        className={`card ${styles.draggableChoiceCard} ${styles.addDraggableChoice}`}
        onClick={handleClick}
        aria-label="Add draggable choice"
        role="button"
        tabIndex={0}
      >
        +
      </div>
    </div>
  );
};

DraggableChoiceEditor.propTypes = {
  draggableChoices: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default DraggableChoiceEditor;
