import create from 'zustand';
import ILicense from '@/components/interfaces/License';
import { IProgram, ISeatPool, ISubscriber } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';

interface UseProgramAccessTableVariables {
  failedSeatPools: ISeatPool[];
  isLoading: boolean;
  licenses: ILicense[];
  programs: IProgram[];
  seatPools: ISeatPool[];
  selectedPrograms: Set<number>;
  subscriber: ISubscriber;
}

interface UseProgramAccessTableState extends UseProgramAccessTableVariables {
  setStore: (nextState: Partial<UseProgramAccessTableVariables>) => void;
  toggleProgram: (id: number) => void;
}

const useProgramAccessStore = create<UseProgramAccessTableState>(set => ({
  failedSeatPools: [],
  isLoading: false,
  licenses: [],
  programs: [],
  seatPools: [],
  selectedPrograms: new Set(),
  subscriber: null,
  setStore: nextState => set(prevState => ({ ...prevState, ...nextState })),
  toggleProgram: id => set((prevState) => {
    const newSet = new Set([...prevState.selectedPrograms]);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);

    return { selectedPrograms: newSet };
  }),
}));

export default useProgramAccessStore;
