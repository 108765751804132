/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';
import I18n from 'modules/i18n';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import NotebookPreview from 'components/common/NotebookPreview';
import DragAndDrop from 'components/common/Forms/Questions/DragAndDrop/Answer';
import useDragAndDropStore from '@/components/common/Forms/Questions/DragAndDrop/useDragAndDropStore';
import { slideObjectPropTypes } from '../../../Utils';
import QuestionPickerModal from './QuestionPickerModal';
import styles from './index.module.scss';

interface SlideObjectQuestionsProps {
  hasError?: boolean;
  interactable?: boolean;
  isSaving?: boolean;
  isVideoActivity?: boolean;
  lessonId?: number;
  modalManager: ModalManager;
  progressIcon?: React.ReactNode;
  setHasError: (error: boolean) => void;
  setIsSaving?: (boolean) => void;
  slideObject: slideObjectPropTypes;
  thumbnail: boolean;
}

const SlideObjectQuestions = ({
  hasError,
  interactable,
  isSaving,
  isVideoActivity,
  modalManager,
  progressIcon,
  setHasError,
  setIsSaving,
  slideObject,
  thumbnail,
  lessonId,
}: SlideObjectQuestionsProps) => {
  const [question, setQuestion] = useState(null);
  const programId = useSlideShowContext(state => state.programId);
  const userType = useSlideShowContext(state => state.userType);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const presentMode = !!useSlideShowContext(state => state.isPresentMode);
  const resizerScaleValue = useDragAndDropStore(state => state.resizerScaleValue);

  useEffect(() => {
    if (!slideObject.isDragAndDrop) return;

    setQuestion({
      ...slideObject.dragAndDropQuestion,
      background_image: slideObject.dragAndDropQuestion.backgroundImage,
    });
  }, [slideObject.dragAndDropQuestion]);

  const renderModal = () => {
    if (thumbnail) return null;

    return modalManager.isOpen && interactable && (
      <QuestionPickerModal
        hasError={hasError}
        isSaving={isSaving}
        isVideoActivity={isVideoActivity}
        modalManager={modalManager}
        setHasError={setHasError}
        setIsSaving={setIsSaving}
        slideObject={slideObject}
      />
    );
  };

  if (slideObject.isDragAndDrop) {
    const parentContainerId = thumbnail ? `${slideObject.id}-dnd-id-thumbnail` : `${slideObject.id}-dnd-id`;

    const isSubmittable = ['Student', 'Teacher', 'Sysadmin', 'Admin', 'Coordinator'].includes(userType);

    return (
      <>
        <div id={parentContainerId} className={styles.dragAndDropContainer}>
          {question && (
            <DragAndDrop
              key={question.id}
              inheritedScaleValue={interactable ? 1 : (resizerScaleValue || 1)}
              isSubmittable={isSubmittable}
              locale={I18n.locale}
              parentContainerId={parentContainerId}
              progressIcon={progressIcon}
              question={question}
              slideShowId={slideShowId}
              lessonId={lessonId}
            />
          )}
        </div>
        {renderModal()}
      </>
    );
  }

  return (
    <>
      <NotebookPreview
        previewParams={{
          slideId: slideObject.slideId,
          programId,
          presentMode,
          questionGroupIds: slideObject.questionGroups?.map(({ id }) => id) || [],
        }}
        thumbnail={thumbnail}
        slideObjectId={slideObject.id}
      />
      {renderModal()}
    </>
  );
};

export default SlideObjectQuestions;
