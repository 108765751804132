import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDOM from 'react-dom';
import Modal, { Footer } from 'common/Modal';
import * as Routes from 'routes';
import showToast from 'common/Toast';
import { capitalize } from 'common/Utils';
import Tooltip from 'common/Tooltip';
import { reconnectAccountMessage } from 'common/GoogleSettings/Utils';
import styles from './ConnectClassroomForm.module.scss';
import SelectClassroom from './Canvas/SelectClassroom';
import DataTable from '../../../../common/DataTable';

const byCourseState = (c1, c2) => {
  if (c1.course_state === 'ACTIVE' && c2.course_state !== 'ACTIVE') return -1;
  if (c1.course_state !== 'ACTIVE' && c2.course_state === 'ACTIVE') return 1;

  return 0;
};

const byWorkflowState = (c1, c2) => {
  if (c1.workflow_state === 'available' && c2.workflow_state !== 'available') return -1;
  if (c1.workflow_state !== 'available' && c2.workflow_state === 'available') return 1;

  return 0;
};

const ConnectClassroomForm = ({
  closeModal, subscriberId, classroomId, classroomName, programId, lessonId, classrooms, updateClassroom, lmsProvider
}) => {
  const [lmsClassrooms, setlmsClassrooms] = useState(null);
  const [selectedClassroomId, setSelectedClassroomId] = useState();
  const [error, setError] = useState(null);

  const selectClassroom = (e) => {
    lmsClassrooms.forEach((classroom) => {
      renderFormInput(false,
        classroom,
        document.getElementById(`radio-${classroom.id}`));
    });

    renderFormInput(true,
      lmsClassrooms.find(classroom => classroom.id.toString() === e.target.value),
      document.getElementById(`radio-${e.target.value}`));


    setSelectedClassroomId(e.target.value);
  };

  const renderFormInput = (checked, rowData, target) => {
    const alreadyConnected = classrooms.map(classroom => classroom.lms_course_id).includes(rowData.id.toString());

    ReactDOM.render(
      <div>
        <label key={rowData.id} className="tw-flex tw-items-center">
          {rowData.course_state === 'ACTIVE' && (
            <input
              type="radio"
              value={rowData.id}
              checked={checked}
              onChange={selectClassroom}
              data-classroom-name={rowData.name}
            />
          )}
          {rowData.name}
          {alreadyConnected && (
            <Tooltip
              content="A TCI class is already connected to this Google Classroom."
              shouldDisplay={alreadyConnected}
              size="medium"
              theme="white"
            >
              <i
                className="fa fa-info-circle tw-ml-2"
                aria-hidden="true"
              />
            </Tooltip>
          )}
        </label>
      </div>,
      target
    );
  };

  const columns = [
    'name',
    'course_state',
  ];

  const columnDefs = () => (
    [{
      createdCell: (td, cellData, rowData) => {
        $(td).attr({
          'data-classroom-name': rowData.name,
          id: `radio-${rowData.id}`
        });
        const checked = selectedClassroomId?.toString() === rowData.id.toString();
        renderFormInput(checked, rowData, td);
      },
      targets: 0,
      title: 'Name',
      width: '75%'
    },
    {
      render: courseState => capitalize(courseState.toLowerCase()),
      targets: 1,
      title: 'Status',
    }]
  );

  useEffect(() => {
    Axios
      .get(Routes.client_classrooms_lms_course_index_path({ lms_provider: lmsProvider, classroom_id: classroomId }))
      .then((response) => {
        const sortedClassrooms = response.data.sort(lmsProvider === 'google' ? byCourseState : byWorkflowState);
        setlmsClassrooms(sortedClassrooms);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.data && e.response.data) {
          setError(e.response.data.error);
        }
        else {
          setError(e);
        }
      });
  }, []);

  const saveClassroom = (classroom) => {
    const updateClassroomPath = Routes.plato_api_subscriber_classroom_path(subscriberId, classroomId);

    Axios
      .patch(updateClassroomPath, { classroom })
      .then((res) => {
        closeModal();
        updateClassroom(res.data.data);

        const assignmentsLink = Routes.teacher_program_assignments_path(programId, { lesson_id: lessonId });
        showToast((
          <Fragment>
            Class successfully connected. Start creating <a href={assignmentsLink}>Assignments</a>.
          </Fragment>
        ), { autoClose: 35000 });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    const lmsClassroom = lmsClassrooms.find(gc => gc.id.toString() === selectedClassroomId);
    const classroom = {
      id: classroomId,
      lms_course_id: selectedClassroomId,
      lms_course_name: lmsClassroom.name,
      lms_type: lmsProvider
    };

    saveClassroom(classroom);
  };

  const renderGoogleConnectClassroomTable = () => (
    <form onSubmit={submit}>
      <p className="mb20">
        Select the Google Classroom to link with <b>{classroomName}</b>:
      </p>
      <DataTable
        pageLength={-1}
        columns={columns}
        columnDefs={columnDefs()}
        handleResourcesManually
        resources={lmsClassrooms}
        showPagingOptions={false}
        info={false}
        defaultOrder={[]}
      />
      <Footer
        primaryButtonText="Connect"
        secondaryButtonCallback={closeModal}
        primaryButtonDisabled={!selectedClassroomId}
        wrapperClassName={styles.modalFooter}
      />
    </form>
  );

  if (error) {
    if (error.toString().match(/insufficientPermissions/)) return reconnectAccountMessage(programId);

    return 'Something went wrong. Please refresh the page and try again.';
  }

  if (!lmsClassrooms) return Modal.spinner();

  let noClassroomMessage;

  if (lmsProvider === 'google') {
    noClassroomMessage = 'You have no Google classrooms available.';
  }
  else {
    noClassroomMessage = 'You do not have any Canvas courses to connect. Please create or join a course in Canvas and try again later.';
  }

  if (lmsClassrooms.length === 0) return noClassroomMessage;

  if (lmsProvider === 'google') {
    return renderGoogleConnectClassroomTable();
  }

  return (
    <SelectClassroom
      lmsClassrooms={lmsClassrooms}
      closeModal={closeModal}
      saveClassroom={saveClassroom}
      classroomId={classroomId}
      classroomName={classroomName}
      classrooms={classrooms}
    />
  );
};

ConnectClassroomForm.propTypes = {
  classroomId: PropTypes.number.isRequired,
  classroomName: PropTypes.string.isRequired,
  classrooms: PropTypes.arrayOf(PropTypes.shape({
    google_course_id: PropTypes.string
  })).isRequired,
  closeModal: PropTypes.func.isRequired,
  lessonId: PropTypes.number.isRequired,
  lmsProvider: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};

export default ConnectClassroomForm;
