import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

import * as Routes from 'routes';
import RadioButton from 'common/Forms/RadioButton';
import Modal, { useModalManager, Footer } from 'common/Modal';

const ToggleExclusionsButton = ({ exclusionSettingInitialValue, seatPoolId }) => {
  const { isOpen, open, close } = useModalManager();
  const [exclusionValue, setExclusionValue] = useState(exclusionSettingInitialValue);

  const updateSeatPool = (e) => {
    e.preventDefault();

    Axios
      .patch(Routes.plato_api_seat_pool_path(seatPoolId), { seat_pool: { [exclusionValue]: true } })
      .then(() => window.location.reload());
  };

  const updateSelection = (e) => {
    setExclusionValue(e.target.value);
  };

  const radioOption = (groupName, label, value) => {
    const defaultChecked = exclusionValue === value;

    return (
      <div>
        <RadioButton
          groupName={groupName}
          label={label}
          value={value}
          defaultChecked={defaultChecked}
        />
      </div>
    );
  };

  const syncSettingsModal = () => {
    return (
      <Modal headerText="Program Sync Settings" closeModal={close} isOpen={isOpen}>
        <div>For this program:</div>
        <form onSubmit={updateSeatPool} onChange={updateSelection}>
          {radioOption('exclusionSetting', 'Do not exclude', 'no_exclusions')}
          {radioOption('exclusionSetting', 'Only auto-roster teachers', 'remove_all_students')}
          {radioOption('exclusionSetting', 'Exclude from auto-rostering', 'exclude_from_rostering')}
          <Footer
            primaryButtonText="Save"
            secondaryButtonCallback={close}
          />
        </form>
      </Modal>
    );
  };

  return (
    <Fragment>
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={open}
      >
        Change program sync settings
      </a>
      {syncSettingsModal()}
    </Fragment>
  );
};

ToggleExclusionsButton.propTypes = {
  exclusionSettingInitialValue: PropTypes.string.isRequired,
  seatPoolId: PropTypes.number.isRequired
};

export default ToggleExclusionsButton;
