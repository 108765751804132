import React from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import styles from '../ElementInsert.module.scss';

const QuestionButton = ({
  buttonText, programId, slideId, slideObjectId, selectedQuestionGroupIds, typeOption,
  notebookId, sectionId,
}) => (
  <span>
    <span
      contentEditable={false}
      data-button-type="question"
      data-question-group-ids={JSON.stringify([...selectedQuestionGroupIds])}
      data-notebook-type={typeOption}
      data-section-id={sectionId}
      data-notebook-id={notebookId}
      tabIndex={0}
    >
      <span
        className="btn btn--blue question-btn"
        role="button"
      >
        {buttonText}
      </span>
      <span className={`slide-popup hint-text ${styles.slidePopup} ${styles.large}`}>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          className={`isn-iframe-${slideObjectId}`}
          width="100%"
          height="100%"
          src={Routes.preview_shared_sections_path({
            program_id: programId,
            question_group_ids: [...selectedQuestionGroupIds],
            slide_id: slideId
          })}
        />
        <i
          className={`fa fa-times-circle popup-close-btn ${styles.slidePopupCloseBtn}`}
          role="button"
          tabIndex={0}
        />
      </span>
    </span>
  </span>
);

QuestionButton.propTypes = {
  buttonText: PropTypes.string,
  notebookId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  sectionId: PropTypes.number,
  selectedQuestionGroupIds: PropTypes.instanceOf(Set).isRequired,
  slideId: PropTypes.string.isRequired,
  slideObjectId: PropTypes.string.isRequired,
  typeOption: PropTypes.string.isRequired,
};

QuestionButton.defaultProps = {
  buttonText: '',
};

export default QuestionButton;
