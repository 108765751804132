import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import * as Routes from 'modules/routes';
import GameTemplate from '../../../interfaces/GameTemplate';
import styles from './TemplateSelect.module.scss';

interface VocabularyGameFormProps {
  gameId: number;
  gameTemplates: GameTemplate[];
  currentTemplate: GameTemplate;
}

const TemplateSelect = ({ gameId, gameTemplates, currentTemplate }: VocabularyGameFormProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (!currentTemplate) return;

    const formattedTemplate = { value: currentTemplate.id, label: currentTemplate.name };
    setSelectedTemplate(formattedTemplate);
  }, [currentTemplate]);

  const handleChange = (option) => {
    setSelectedTemplate(option);
    setSaved(false);

    axios.put(Routes.plato_api_game_path({ id: gameId }), { game: { game_template_id: option.value } })
      .then(() => {
        setSaved(true);
      });
  };

  return (
    <div className="tw-mt-3">
      <label htmlFor="game_template_id" className={styles.label}>Type:</label>
      <div className={styles.selectContainer}>
        <Select
          value={selectedTemplate}
          onChange={handleChange}
          options={gameTemplates.map(gt => ({ value: gt.id, label: gt.name }))}
          menuContainerStyle={{ zIndex: 1050 }}
          name="game_template_id"
          className={styles.select}
        />
        {saved && <i className={`fa fa-check text--green ${styles.check}`} aria-hidden="true" />}
      </div>
    </div>
  );
};

export default TemplateSelect;
