import React, { useMemo } from 'react';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import EditImage from '../../../../../../ImageManager/Edit';
import useSlideShowImageFetcher from '../../../../../Utils/useSlideShowImageFetcher';

const CustomEditImageModal = ({ editorInstance, modalManager, slideObject, imageModelToEdit }) => {
  const { fetchImages } = useSlideShowImageFetcher();
  const locale = useSlideShowContext(state => state.locale);
  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const imgModel = useMemo(() => {
    if (!imageModelToEdit) return null;

    return slideObject.imageModels.find(im => im.id.toString() === imageModelToEdit.match(/\d+/)[0].toString());
  }, [slideObject, imageModelToEdit]);

  const replaceImage = (imageData) => {
    const image = $(`img[data-snippet="${imageModelToEdit}"]`);

    if (image.length === 0) {
      return;
    }
    const { image: { imageUrl, imageEsUrl, descriptionEn, descriptionEs } } = imageData;

    const src = locale === 'es' && imageEsUrl && !imageEsUrl.includes('missing.png') ? imageEsUrl : imageUrl;
    const description = locale === 'es' ? descriptionEs || descriptionEn : descriptionEn;

    const imageToUpdate = image[1] || image[0];
    // @ts-ignore
    imageToUpdate.src = src;
    // @ts-ignore
    imageToUpdate.alt = description || '';
    imageToUpdate.dataset.zoomurl = src;
  };

  const afterSave = (res) => {
    const imageModel = res.length ? res[0] : res;

    replaceImage(imageModel);

    editorInstance.undo.saveStep();
  };

  const formatImageModel = (imageData) => {
    if (!imageData) return null;
    return (
      {
        id: parseInt(imageData.id, 10),
        image_fileName: imageData.image.fileName,
        image_fileNameEs: imageData.image.fileNameEs,
        image: imageData.image,
      }
    );
  };

  return (
    <EditImage
      afterMergeDuplicates={afterSave}
      afterSubmit={afterSave}
      dontMergeCallback={afterSave}
      excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
      imageModel={formatImageModel(imgModel)}
      modelType="SlideObject"
      modelId={slideObject.id}
      modalManager={modalManager}
      standaloneButton={false}
      isAdmin={isAdmin}
      fromSlideObject
      getExistingImages={fetchImages}
    />
  );
};

export default CustomEditImageModal;
