import FE from 'froala-editor';
import marginIcon from 'images/icons/img-margin.svg';
import {
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  IMAGE_EDIT_COMMAND_NAME,
  IMAGE_EDIT_MARGINS_COMMAND_NAME,
} from './froalaConstants';
import { mainIdeasPluginCommand } from './plugins/MainIdeas';
import { glossaryTermsPluginCommand } from './plugins/GlossaryTerms';
import { vocabBoxPluginCommand } from './plugins/VocabBox';

const iconTemplate = '<i class="fa fa-[NAME]" style="color:#000000;font-size:17px" aria-hidden="true"></i>';

export const customVideoCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addVideoIcon', { NAME: 'video-camera', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_VIDEO_COMMAND_NAME, {
    focus: false,
    icon: 'addVideoIcon',
    refreshAfterCallback: true,
    title: 'Add Video',
    undo: true,
  });
};

// creates the custom image button
export const customImageCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addImageIcon', { NAME: 'image', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_IMAGE_COMMAND_NAME, {
    focus: false,
    icon: 'addImageIcon',
    refreshAfterCallback: true,
    title: 'Add Image',
    undo: true,
  });
};

export const imageEditCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('editImageIcon', { NAME: 'pencil-square-o', template: 'black-icon' });

  FE.RegisterCommand(IMAGE_EDIT_COMMAND_NAME, {
    focus: false,
    icon: 'editImageIcon',
    refreshAfterCallback: true,
    title: 'Edit',
    undo: false,
  });
};


export const imageEditMarginsCommand = () => {
  const marginIconTemplate = '<img alt="Edit margins" src="[PATH]" />';
  FE.DefineIconTemplate('margins-icon', marginIconTemplate);
  FE.DefineIcon('editImageMarginsIcon', { PATH: marginIcon, template: 'margins-icon' });

  FE.RegisterCommand(IMAGE_EDIT_MARGINS_COMMAND_NAME, {
    focus: false,
    icon: 'editImageMarginsIcon',
    refreshAfterCallback: true,
    title: 'Margin',
    undo: true,
  });
};

export const registerFroalaCommands = () => {
  customImageCommand();
  customVideoCommand();
  imageEditCommand();
  imageEditMarginsCommand();
  mainIdeasPluginCommand();
  glossaryTermsPluginCommand();
  vocabBoxPluginCommand();
};
