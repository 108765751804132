import React from 'react';
import ReactModal from 'react-modal';
import { useInView } from 'react-intersection-observer';
import { useModalManager } from '@/components/common/Modal';
import cn from '@/utils/cn';
import i18n from '@/modules/i18n';
import useTurnInListener from './hooks/useTurnInListener';
import DrawingCanvas from './Drawing';
import Preview from './Preview';
import DrawingStoreProvider from './store/DrawingStoreProvider';
import useDrawingContext from './store/useDrawingContext';
import { DrawingProps } from './types';

export const Drawing = () => {
  const answerId = useDrawingContext(state => state.answerId);
  const questionId = useDrawingContext(state => state.questionId);
  const disabled = useDrawingContext(state => state.disabled);
  const saving = useDrawingContext(state => state.saving);
  const autoSaveQueued = useDrawingContext(state => state.autoSaveQueued);
  const isDirty = useDrawingContext(state => state.isDirty);
  const setIsDirty = useDrawingContext(state => state.setIsDirty);
  const useDirtyTracking = useDrawingContext(state => state.useDirtyTracking);
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
  const isHelpMenuOpen = useDrawingContext(state => state.helpMenuOpen);

  const modalManager = useModalManager();

  const hasUnsavedChanges = saving || autoSaveQueued;

  const closeModal = () => {
    if (hasUnsavedChanges || isHelpMenuOpen) return;

    modalManager.close();
  };

  useTurnInListener({ questionId, setIsDirty });

  const dirtyProps = useDirtyTracking ? { 'data-dirty': isDirty } : {};

  return (
    <div
      ref={ref}
      data-answered={!!answerId}
      data-type="drawing"
      {...dirtyProps}
    >
      {(!modalManager.isOpen && inView) && (
        <Preview modalManager={modalManager} />
      )}
      {!disabled && (
        <ReactModal
          role="dialog"
          aria={{ hidden: !modalManager.isOpen }}
          overlayClassName="tw-fixed tw-z-[1100] tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-flex tw-items-center"
          className={cn(
            'tw-p-0 tw-relative tw-mx-auto tw-bg-white tw-rounded-xl',
            'tw-border-2 tw-border-white tw-border-solid tw-overflow-hidden',
          )}
          isOpen={modalManager.isOpen}
          onRequestClose={closeModal}
          parentSelector={() => document.body}
          preventScroll
          shouldCloseOnOverlayClick={false}
        >
          <DrawingCanvas />
          <button
            disabled={saving}
            aria-label={i18n.t('close_drawing_modal')}
            className={cn(
              'tw-fixed tw-top-2 tw-right-2 tw-z-99 tw-bg-transparent tw-border-none',
              'tw-flex tw-items-center tw-justify-center tw-cursor-pointer',
            )}
            onClick={closeModal}
            type="button"
          >
            <span
              className={cn('fa fa-times tw-text-white tw-text-4xl', { 'tw-opacity-50': saving })}
            />
          </button>
        </ReactModal>
      )}
    </div>
  );
};

const DrawingWrapper = ({ editable = true, ...rest }: DrawingProps) => (
  <DrawingStoreProvider props={{ ...rest, editable }}>
    <Drawing />
  </DrawingStoreProvider>
);

export default DrawingWrapper;
