import React, { useCallback, useEffect, useState } from 'react';
import cn from '@/utils/cn';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import { plato_api_slide_program_setting_path, plato_api_slide_program_settings_path } from '@/modules/routes';
import { useDebounceCallback } from 'usehooks-ts';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ui/Form';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { SlideProgramSettingType } from './types';

const PageNumberInput = ({ attrName, hasErrors, formRegister }) => {
  return (
    <Input
      className={cn('tw-text-right tw-p-0.5', {
        'tw-border-red tw-border-[1px] tw-border-solid tw-rounded focus:tw-outline-none': hasErrors
      })}
      containerClasses={cn('tw-mx-1 tw-w-[50px] tw-p-0', {
        'tw-border-red tw-border-[1px] tw-border-solid tw-rounded': hasErrors
      })}
      name={attrName}
      type="number"
      {...formRegister}
    />
  );
};

const SlidePageNumbers = ({ slideProgramSetting }: { slideProgramSetting: SlideProgramSettingType }) => {
  const { startPage, endPage, slideId, programId, programTitle } = slideProgramSetting;
  const [id, setId] = useState(slideProgramSetting.id);
  const updateSlide = useSlideShowContext(state => state.updateSlide);
  const currentProgramId = useSlideShowContext(state => state.programId);
  const getDefaultValues = () => ({ startPage, endPage });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm<{ startPage?: number, endPage?: number }>({
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [slideId, reset]);

  const createOrUpdate = (data) => {
    const pageNums = {
      start_page: data.startPage != null ? parseInt(data.startPage) : null,
      end_page: data.endPage != null ? parseInt(data.endPage) : null,
    };

    return (id ?
      Axios.patch(plato_api_slide_program_setting_path(id), pageNums) :
      Axios.post(plato_api_slide_program_settings_path({
        ...pageNums, slide_id: slideId, program_id: programId
      }))
    );
  };

  const handleSave = (data) => {
    createOrUpdate(data)
      .then((res) => {
        const { id, program_id, start_page, end_page } = res.data.data;
        setId(id);

        if (program_id === currentProgramId) {
          updateSlide(slideId, { startPage: start_page, endPage: end_page });
        }
      })
      .catch((error) => { console.log(error); });
  };
  const debouncedSave = useCallback(useDebounceCallback(handleSave, 500), [slideId, id]);

  const handleInputChange = async (attrName) => {
    const isValid = await trigger(attrName);
    if (isValid) handleSubmit(debouncedSave)();
  };

  // Each input has the same error message, so if either has an error, display the message.
  const errorMessage = Object.values(errors || {})[0]?.message;

  const formRegister = (attrName) => (
    register(attrName, {
      required: false,
      min: { value: 0, message: 'Value needs to be between 0 and 999' },
      max: { value: 999, message: 'Value needs to be between 0 and 999' },
      onChange: () => handleInputChange(attrName)
    })
  );

  return (
    <>
      <div className="tw-text-dark-grey tw-flex tw-items-center tw-flex-row">
        <form
          className="tw-flex tw-items-center tw-flex-row tw-w-full"
          onSubmit={handleSubmit(debouncedSave)}
        >
          <div className="tw-text-lg tw-w-1/3 tw-pr-[50px]">
            {programTitle}
          </div>
          <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-items-center tw-flex-row">
              <div className="tw-text-base">{`Start page `}</div>
              <PageNumberInput
                attrName="startPage"
                hasErrors={Object.keys(errors).includes('startPage')}
                formRegister={formRegister('startPage')}
              />

              <div className="tw-text-base tw-pl-2">{` End page `}</div>

              <PageNumberInput
                attrName="endPage"
                hasErrors={Object.keys(errors).includes('endPage')}
                formRegister={formRegister('endPage')}
              />
            </div>
            {errorMessage &&
              <div className="tw-text-red tw-pt-2">{errorMessage}</div>
            }
          </div>
        </form>
      </div>
    </>
  );
};

export default SlidePageNumbers;
