import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import showToast from '@/components/common/Toast';
import useSlideShowContext from '../stores/useSlideShowContext';
import { COPY_SLIDE } from './slideShowGraphQL';

const ImportSlideButton = () => {
  const [slideId, setSlideId] = useState<number>();

  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const modalManager: ModalManager = useModalManager();

  const [copySlideMutation, { loading: isLoading }] = useMutation(COPY_SLIDE);
  const afterCopy = useSlideShowContext(state => state.addSlide);

  const copySlide = () => {
    copySlideMutation({ variables: { id: slideId, position: (currentSlide?.position || 0) + 1, slideShowId } })
      .then((result) => {
        if (!result.data.copySlide.success) {
          showToast('Slide ID not found.', { position: 'bottom-right', msgType: 'error' });
          return;
        }

        showToast('Slide successfully imported.', { position: 'bottom-right' });
        setSlideId(undefined);
        modalManager.close();

        afterCopy(result.data.copySlide.result);
      })
      .catch(() => {
        showToast('Unable to copy slide.', { position: 'bottom-right', msgType: 'error' });
      });
  };

  const handleSubmit = async (e: SubmitEvent) => {
    e.preventDefault();

    copySlide();
  };

  return (
    <>
      <button
        className="dropdown-item tw-w-full tw-py-2 tw-cursor-pointer"
        type="button"
        onClick={modalManager.open}
      >
        <span className="fa fa-lock" />
        Import Slide
      </button>
      <SubmitModal
        close={modalManager.close}
        headerText="Import Slide"
        isLoading={isLoading}
        isOpen={modalManager.isOpen}
        isValid={!!slideId}
        onSubmit={handleSubmit}
      >
        <div className="tw-px-4 tw-text-base">
          <p className="tw-mb-4">To import a slide:</p>
          <ol>
            <li className="tw-mb-1">Open the slide builder and find the slide you want to import.</li>
            <li className="tw-mb-1">Find the slide’s thumbnail and click the caret.</li>
            <li className="tw-mb-1">Copy the slide ID and paste the ID below.</li>
          </ol>
          <label className="tw-inline-flex tw-items-center tw-gap-2 tw-mt-6 tw-mb-4">
            <strong>Slide ID</strong>
            <input
              className="tw-py-2.5 tw-px-3 tw-rounded-lg tw-border-solid tw-border"
              onChange={e => setSlideId(parseInt(e.target.value, 10))}
              pattern="d+"
              size={8}
              type="number"
              value={slideId}
            />
          </label>
        </div>
      </SubmitModal>
    </>
  );
};

export default ImportSlideButton;
