import React from 'react';
import { Tag } from './useSlideTags';
import I18n from 'i18n-js';

interface SlideTagProps {
  category: string;
  tags: Tag[];
}

const SlideTagGroup = ({ category, tags }: SlideTagProps) => {
  return (
    <div className="tw-text-sm">
      <p>
        <strong>{category}</strong>
      </p>

      {tags.map((tag) => {
        const {
          description_en: descriptionEn,
          description_es: descriptionEs,
          value_en: valueEn,
          value_es: valueEs,
        } = tag;

        const value = I18n.locale === 'es' && !!valueEs ? valueEs : valueEn;
        const description = I18n.locale === 'es' && !!descriptionEs ? descriptionEs : descriptionEn;

        return (
          <div className="tw-mb-4">
            <strong>{value}: </strong>
            {description}
          </div>
        );
      })}
    </div>
  );
};

export default SlideTagGroup;
