import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { Footer } from 'common/Modal';

import styles from './index.module.scss';

const NoteField = ({ input }) => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label
        htmlFor="notes"
        className={`${styles.label}`}
      >
        Notes
      </label>
      <textarea
        id="notes"
        rows={10}
        className={`${styles.textInput} ${styles.textarea} mb20`}
        placeholder="Please enter information about the subscriber (e.g., roster integration set up)"
        {...input}
      />
    </div>
  </div>
);

NoteField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
};

const NoteForm = ({
  handleSubmit, deleteNote, pristine, values, deletable, closeModal
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="subscriber[note]"
      component={NoteField}
    />

    <Footer
      secondaryButtonCallback={deletable ? deleteNote : closeModal}
      secondaryButtonText={deletable ? 'Delete Note' : 'Cancel'}
      primaryButtonDisabled={pristine || values.subscriber === undefined}
    />

  </form>
);

NoteForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deletable: PropTypes.bool.isRequired,
  deleteNote: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    subscriber: PropTypes.shape({
      note: PropTypes.string
    })
  }).isRequired
};

export default NoteForm;
