/**
 * Wraps a fill in the blank prompt in a full question form component
 * Used for a question's assessment view / preview
 */

import React from 'react';
import PropTypes from 'prop-types';
import Prompt from './Prompt';
import AssessmentQuestionForm from '../AssessmentQuestionForm';

export default function FillInTheBlankQuestion(props) {
  return (
    <AssessmentQuestionForm
      {...props}
      questionType="FillInTheBlank"
      hasMultipartAnswer
    >
      <Prompt
        locale={props.locale}
        questionBody={props.questionBody}
        randomizeChoices={props.randomizeChoices}
        savedResult={props.savedResult}
        randomizeSeed={props.randomizeSeed}
        questionId={props.questionId}
        questionTTSEnabled={props.questionTTSEnabled}
        usePromptStyle={!props.useSubmitButton}
      />
    </AssessmentQuestionForm>
  );
}

FillInTheBlankQuestion.propTypes = {
  disabled: PropTypes.bool,
  isInSlide: PropTypes.bool,
  locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
  questionBody: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTTSEnabled: PropTypes.bool,
  randomizeChoices: PropTypes.bool,
  saveDebounce: PropTypes.number,
  savedResult: PropTypes.object,
  useSubmitButton: PropTypes.bool,
};

FillInTheBlankQuestion.defaultProps = {
  disabled: false,
  isInSlide: false,
  questionTTSEnabled: true,
  randomizeChoices: false,
  saveDebounce: 300,
  savedResult: {},
  useSubmitButton: false,
};
