/**
 * Wraps a multi-correct multiple choice question form component
 * Used for a question's assessment view / preview
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TTSButton from 'shared/TextToSpeechButton';
import AssessmentQuestionForm from './AssessmentQuestionForm';
import MultipleChoiceOption from './MultipleChoiceOption';
import { checkArrayEquality } from '../../../modules/TCIUtils';
import QuestionLetter from './QuestionLetter';

export default class CheckboxGroupWithAutoSave extends Component {
  static propTypes = {
    checkboxId: PropTypes.string.isRequired,
    checkboxName: PropTypes.string.isRequired,
    correctChoices: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    isInSlide: PropTypes.bool,
    isInReadingGame: PropTypes.bool,
    locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
    maxAttempts: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    points: PropTypes.number,
    pointsEarned: PropTypes.number,
    questionBody: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTTSEnabled: PropTypes.bool,
    saveDebounce: PropTypes.number,
    savedResult: PropTypes.shape({
      answer: PropTypes.string,
      updated_at: PropTypes.string
    }),
  };

  static defaultProps = {
    correctChoices: [],
    disabled: false,
    isInSlide: false,
    isInReadingGame: false,
    points: 0,
    questionTTSEnabled: true,
    saveDebounce: 800,
    savedResult: {},
  };

  static parseValues(values) {
    return {
      answer: values.answer.toString(),
      question_id: values.question_id
    };
  }

  static parseAnswer(answer) {
    // Convert from a string to an array:
    if (answer && answer.split) {
      return answer.split(',');
    }

    return answer;
  }

  // Append index of checkbox to the given id
  getCheckboxId(index) {
    return `${this.props.checkboxId}_${index}`;
  }

  renderCheckbox(key, index, isDisabled, value) {
    return (
      <span key={`${this.props.questionId}-${index}`}>
        <QuestionLetter index={index} />
        <Field
          component={MultipleChoiceOption}
          disabled={isDisabled}
          id={this.getCheckboxId(index)}
          key={`${this.props.checkboxId}_${key}`}
          label={key}
          name={this.props.checkboxName}
          type="checkbox"
          value={value.toString()}
          isEqual={(a, b) => checkArrayEquality(a, b)}
        />
      </span>
    );
  }

  renderCheckboxes(isDisabled) {
    return this.props.options.map((option, index) => {
      const key = option.key;
      if (key === '') return null;

      return this.renderCheckbox(key, index, isDisabled, option.answer_value.toString());
    });
  }

  renderCheckboxGroup(isDisabled = false) {
    return (
      <ol className="mc-question-group-list upper-alpha">
        {this.renderCheckboxes(this.props.disabled || isDisabled)}
      </ol>
    );
  }

  renderTTSButton() {
    if (!this.props.questionTTSEnabled) return null;

    return (
      <TTSButton
        locale={this.props.locale}
        idToRead={`question_${this.props.questionId}`}
      />
    );
  }

  render() {
    return (
      <div>
        <AssessmentQuestionForm
          {...this.props}
          correctChoices={(this.props.correctChoices || [])}
          questionContent={({ isDisabled }) => this.renderCheckboxGroup(isDisabled)}
          questionType="MultipleChoiceQuestion"
          parseValues={CheckboxGroupWithAutoSave.parseValues}
          parseAnswer={CheckboxGroupWithAutoSave.parseAnswer}
        >
          <span
            className="respect_breaks"
            dangerouslySetInnerHTML={{ __html: this.props.questionBody }}
          />
          {this.renderTTSButton()}
        </AssessmentQuestionForm>
      </div>
    );
  }
}
