import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import { SpinnerIcon } from '@/components/common/Utils';
import { GradesTableProps } from './types';
import GradesTableQueryClientProvider from './GradesTableQueryClientProvider';
import useGradesTableStore from './store/useGradesTableStore';
import useGradesTableData from './hooks/useGradesTableData';
import Navigation from './Navigation';
import Table from './Table';
import StudentlessClassroomMessage from './StudentlessClassroomMessage';
import GradesTableStoreProvider from './store/GradesTableStoreProvider';

export const GradesTable = () => {
  const students = useGradesTableStore(state => state.students);
  const isFetching = useIsFetching();

  useGradesTableData();

  const render = () => {
    if (isFetching > 0) {
      return (
        <div className="tw-flex tw-justify-center tw-align-middle tw-text-5xl tw-pt-12">
          <SpinnerIcon />
        </div>
      );
    };
    if (!students.length) return <StudentlessClassroomMessage />;

    return <Table />;
  };

  return (
    <div>
      <Navigation />
      {render()}
    </div>
  );
};

const GradesTableWrapper = (props: GradesTableProps) => (
  <BrowserRouter basename="/teacher/scores">
    <GradesTableStoreProvider props={props}>
      <GradesTableQueryClientProvider>
        <Routes>
          <Route path=":classroomId" element={<GradesTable />} />
        </Routes>
      </GradesTableQueryClientProvider>
    </GradesTableStoreProvider>
  </BrowserRouter>
);

export default GradesTableWrapper;
