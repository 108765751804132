import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import EditScore from './EditScore';
import { SCORE_STYLES } from './GradesTable/variables';

export default class ShowLessonScore extends Component {
  static propTypes = {
    classroomId: PropTypes.number.isRequired,
    initialMaxScore: PropTypes.number.isRequired,
    initialSectionScores: PropTypes.objectOf(PropTypes.number).isRequired,
    programId: PropTypes.number.isRequired,
    unitProjects: PropTypes.bool,
    updatedGradesTable: PropTypes.bool
  };

  static defaultProps = {
    unitProjects: false,
    updatedGradesTable: false
  };

  constructor(props) {
    super(props);

    this.state = {
      maxScore: this.props.initialMaxScore,
      sectionScores: this.props.initialSectionScores
    };
  }

  handleScoreChange(sectionId, score) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const values = { ...this.state.sectionScores, [sectionId]: score };
    const maxScore = Object.values(values).reduce(reducer);

    this.setState({ maxScore });
  }

  _renderScoreModal(score, sectionId) {
    return (
      <EditScore
        initialMaxScore={score}
        classroomId={this.props.classroomId}
        sectionId={sectionId}
        programId={this.props.programId}
        callback={value => this.handleScoreChange(sectionId, value)}
      />
    );
  }

  renderSectionMaxScores() {
    return Object.entries(this.props.initialSectionScores).map((data) => {
      const [sectionId, score] = data;

      const container = document.querySelector(`.section-${sectionId}`);
      if (!container) return null;

      const modal = this._renderScoreModal(score, parseInt(sectionId, 10));
      return ReactDOM.createPortal(modal, container);
    });
  }

  renderMaxScore() {
    return this.props.updatedGradesTable ? (
      <div className="tw-text-[12px] tw-font-light">
        <span>Max Score:&nbsp;</span>
        <span className={SCORE_STYLES}>{Pluralize('pt', this.state.maxScore, true)}</span>
      </div>
    ) : (
      <div className="tw-text-[14px] tw-font-light">
        <span>Max Score:&nbsp;</span>
        <span className={`${SCORE_STYLES} tw-text-[18px]`}>{this.state.maxScore}</span>
      </div>
    );
  }

  render() {
    return (
      <span id="max-lesson-score">
        {!this.props.unitProjects && this.renderMaxScore()}
        {this.renderSectionMaxScores()}
      </span>
    );
  }
}
