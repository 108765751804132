/* eslint-disable no-console */

import React, { useRef } from 'react';
import Clipboard from 'clipboard';
import { Button } from '@/components/ui/Buttons';
import showToast from '@/components/common/Toast';

interface CopyToClipboardButtonProps {
  errorMessage: string;
  successMessage: string;
  text: string;
  useIcon: boolean;
}

const CopyToClipboardButton = ({ errorMessage, successMessage, text, useIcon = false }: CopyToClipboardButtonProps) => {
  const buttonRef = useRef(null);

  const copyToClipboard = () => {
    const clipboard = new Clipboard(buttonRef.current, {
      text: () => text,
    });

    clipboard.on('success', () => {
      showToast(successMessage, { autoClose: 4000 });
      clipboard.destroy();
    });

    clipboard.on('error', () => {
      showToast(errorMessage, { autoClose: 4000, msgType: 'error' });
      clipboard.destroy();
    });

    clipboard.onClick({ currentTarget: buttonRef.current });
  };

  return (
    <Button
      onClick={copyToClipboard}
      buttonRef={buttonRef}
      outlineStyle
      small
    >
      {useIcon ? (
        <i className="fa fa-copy" />
      ) : 'Copy'}
    </Button>
  );
};

export default CopyToClipboardButton;
