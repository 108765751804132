import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import EditForm from './EditForm';

import styles from '../Modal.module.scss';

export default function Edit({
  closeModal, modalIsOpen, rowData, states, updatePath, updateTable, editResetDateDisabled
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText="Edit Subscriber"
    >
      <EditForm
        city={rowData.city}
        closeModal={closeModal}
        customerNumber={rowData.customer_number}
        id={rowData.id}
        mdr_id={rowData.mdr_id}
        name={rowData.name}
        state={rowData.state}
        stateList={states}
        updatePath={updatePath}
        allowSelfServeAutoroster={rowData.allow_self_serve_autoroster}
        overshareEnabled={rowData.overshare_enabled}
        resetOnJuly29={rowData.reset_on_july_29}
        updateTable={updateTable}
        editResetDateDisabled={editResetDateDisabled}
      />
    </Modal>
  );
}

Edit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  editResetDateDisabled: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    allow_self_serve_autoroster: PropTypes.bool,
    city: PropTypes.string.isRequired,
    customer_number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    mdr_id: PropTypes.number,
    name: PropTypes.string.isRequired,
    overshare_enabled: PropTypes.bool,
    reset_on_july_29: PropTypes.bool,
    state: PropTypes.string.isRequired,
  }).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  updatePath: PropTypes.string.isRequired,
  updateTable: PropTypes.func.isRequired
};
