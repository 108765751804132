import React, { useMemo } from 'react';
import { ISlideShow, IStatus, IStudent } from '@/components/staffer/Scores/GradesTable/types';
import * as Routes from '@/modules/routes';
import { COLOR_LEGEND } from '@/components/staffer/Scores/GradesTable/variables';
import useSlideShowExerciseResults
  from '../../../hooks/queries/exerciseResults/useSlideShowExerciseResults';
import GradeCell from './index';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useSlideShowQuestionAnswers
  from '../../../hooks/queries/questionAnswers/useSlideShowQuestionAnswers';
import { roundScore, lowScoreHighlightColor } from './utils';

interface SlideShowGradeCellProps {
  slideshow: ISlideShow;
  student: IStudent;
}

const SlideShowGradeCell = ({ slideshow, student }: SlideShowGradeCellProps) => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lessonId = useGradesTableStore(state => state.lesson)?.id;
  const programId = useGradesTableStore(state => state.programId);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);

  const { data: exerciseResults } = useSlideShowExerciseResults(classroom, slideshow);
  const { data: slideshowQuestionAnswers } = useSlideShowQuestionAnswers(slideshow);

  const path = useMemo(() => (
    Routes.grade_by_student_shared_question_answers_path(student, {
      program_id: programId,
      lesson_id: lessonId,
      classroom_id: classroom.id,
      slideshow_id: slideshow.id,
    })
  ), [programId, lessonId, classroom]);

  const { slide_group_count: slideGroupCount } = slideshow;
  const hasSlideGroups = slideGroupCount > 0;

  const exerciseResultsForStudent = exerciseResults.filter(exerciseResult => {
    return exerciseResult.student_id === student.id;
  });
  const questionAnswersForStudent = slideshowQuestionAnswers.filter(qa => (qa.student_id === student.id));

  const inProgress = exerciseResultsForStudent.length > 0 || questionAnswersForStudent.length > 0;
  const allTurnedIn = exerciseResultsForStudent.some(er => !!er.completed_at);
  const allGroupsAnswered = hasSlideGroups ?
                              exerciseResultsForStudent.length === slideGroupCount :
                              exerciseResultsForStudent.length === 1;
  const allScored = exerciseResultsForStudent.every(er => er.score != null);

  const slideShowStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    if (exerciseResultsForStudent.length === 0 && !inProgress) return status;
    const totalScore = exerciseResultsForStudent.reduce((acc, cur) => acc + parseFloat(cur.score), 0);
    const questionAnswersScore = roundScore(questionAnswersForStudent.reduce((acc, cur) => acc + (parseFloat(cur.score) || 0), 0));

    const getStatusText = statusText => (
      questionAnswersForStudent.some(qa => !!qa.score) ? questionAnswersScore : statusText
    );

    if (totalScore != null && allGroupsAnswered && allScored) {
      status.text = roundScore(totalScore);
      status.color = lowScoreHighlightColor({ score: totalScore, maxScore: slideshow.max_score, lowScoresSettings })
    } else if (allTurnedIn && allGroupsAnswered && !allScored) {
      status.color = COLOR_LEGEND.completed;
      status.text = getStatusText('Turned In');
    } else if (inProgress) {
      status.color = COLOR_LEGEND.in_progress;
      status.text = getStatusText('In Progress');
    }

    return status;
  };

  const updatedSinceGraded = exerciseResultsForStudent.filter(er => er.updated_since_graded).length > 0;

  return (
    <GradeCell
      id={`slideshow-${slideshow.id}-student-${student.id}-score`}
      status={slideShowStatus()}
      url={path}
      updatedSinceGraded={updatedSinceGraded}
    />
  );
};

export default SlideShowGradeCell;
