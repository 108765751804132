import React from 'react';
import ButtonSnippet from '@/components/common/HtmlElements/ButtonSnippet';
import IJumpToButton from '@/components/interfaces/graphql/HtmlElement/JumpToButton';

interface JumpToButtonSnippetProps {
  jumpToButton: IJumpToButton;
}

const Snippet = ({
  jumpToButton: {
    data: {
      buttonTextEn,
      buttonTextEs,
      displayAsLink,
      backgroundColor = displayAsLink ? 'blue' : 'purple',
      destinationSlideId,
    },
    id,
    type,
  },
}: JumpToButtonSnippetProps) => (
  <ButtonSnippet
    backgroundColor={backgroundColor}
    buttonTextEn={buttonTextEn}
    buttonTextEs={buttonTextEs}
    displayAsLink={displayAsLink}
    destinationSlideId={destinationSlideId}
    id={id}
    type={type}
  />
);

export default Snippet;
