import { useEffect, useState } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';

interface UseProgramsTableDataParams {
  custom: boolean;
}

type Program = {
  active_subscriber_names: string[];
  archived: boolean;
  code: string;
  full_title_with_edition: string;
  id: number;
  num_lessons: number;
  publish_status: string;
  subscribers: string[],
  title: string;
};

type TableData = {
  archived: Program[];
  nonArchived: Program[];
};

const useProgramsTableData = ({ custom }: UseProgramsTableDataParams) => {
  const defaultTableData = { archived: [], nonArchived: [] };
  const [programsTableData, setProgramsTableData] = useState<TableData>(defaultTableData);
  const [isLoading, setIsLoading] = useState(false);
  const includes = ['units', { program_lessons: 'lesson' }];
  const only = [
    'id', 'code', 'all_copyright_years', 'full_title_with_edition', 'num_licenses', 'publish_status', 'archived',
  ];

  const programsPathParams = () => {
    const customParam = custom ? { custom: true } : { standard: true };

    if (custom) includes.push('active_subscribers');
    if (custom) only.push('active_subscriber_names');

    return { ...customParam, includes, only };
  };


  useEffect(() => {
    setProgramsTableData(defaultTableData);
    setIsLoading(true);
    Axios
      .get(Routes.plato_api_programs_path(programsPathParams()))
      .then((res) => {
        const tableData = res.data.data.reduce((programs, program) => {
          const formattedProgram = {
            action: null,
            archived: program.archived,
            code: program.code,
            copyrights: program.all_copyright_years.join(', '),
            id: program.id,
            title: program.full_title_with_edition,
            subscriber: program.active_subscriber_names,
            num_licenses: program.num_licenses,
            published: program.publish_status,
          };

          formattedProgram.archived ? programs.archived.push(formattedProgram) : programs.nonArchived.push(formattedProgram);

          return programs;
        }, { archived: [], nonArchived: [] });

        setProgramsTableData(tableData);
        setIsLoading(false);
      });
  }, [custom]);

  return {
    isLoading,
    tableData: programsTableData.nonArchived,
    archivedTableData: programsTableData.archived,
  };
};

export default useProgramsTableData;
