import React, { Component } from 'react';
import * as Routes from 'routes';
import AsyncSelect from 'react-select/lib/Async';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactDOM from 'react-dom';

import Modal, { Footer } from 'common/Modal';
import Spinner from 'common/Spinner';
import DataTable from 'common/DataTable';
import { matchAttributesForMultipleRows } from 'common/DataTable/Utils';

import { formatStudentForSelect } from 'common/Classes/FormUtils';
import { FORM_ERROR } from 'final-form';
import { renderErrors, SubmitError } from '../../common/Forms/Utils';

export default class StudentModal extends Component {
  static propTypes = {
    classroomStudentsPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    columns: PropTypes.instanceOf(Array),
    manageStudentsPath: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      seat_pool_id: PropTypes.number.isRequired,
    }).isRequired,
    searchStudentsPath: PropTypes.string.isRequired,
    updateStudentCount: PropTypes.func.isRequired
  };

  static defaultProps = {
    columns: ['last_name', 'first_name', 'username', 'remove']
  };

  static _noAvailableSeatsWarning() {
    return (
      <div className="red">
        <p>
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
          />
          {' '}
          All student licenses are in use. You will only be able to add
          students that already have this license.
        </p>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      resources: [],
      studentsToAdd: null,
      studentsToRemove: new Set(),
      submitting: false
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  componentDidMount() {
    if (this.props.modalIsOpen) {
      Promise
        .all([this._getResources(), this._getClassroomSeatPool()])
        .then(() => this.setState({ isLoading: false }))
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  }

  getOptions(input) {
    return Axios
      .post(this.props.searchStudentsPath, { search: { full_name_with_username_cont: input } })
      .then((response) => {
        const options = this._removeCurrentStudentsFromSelectOptions(response.data.data);
        return { options };
      });
  }

  handleCheckboxChange(args, state, utils) {
    const updatedStudentsToRemove = new Set(this.state.studentsToRemove);
    if (args[0].target.checked) {
      updatedStudentsToRemove.add(args[0].target.value);
    }
    else {
      updatedStudentsToRemove.delete(args[0].target.value);
    }

    this.setState({ studentsToRemove: updatedStudentsToRemove });
    utils.changeValue(state, 'students_to_remove', () => Array.from(updatedStudentsToRemove));
  }

  handleSelect(args, state, utils) {
    this.setState({ studentsToAdd: args[0] });
    utils.changeValue(state, 'students_to_add', () => [args[0].value]);
  }

  handleSubmit(values) {
    this.setState({ submitting: true });

    return Axios
      .post(this.props.manageStudentsPath.replace(':id', this.props.rowData.id), values)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
          this.setState({ submitting: false });
          return { [FORM_ERROR]: renderErrors(response.data.errors) };
        }

        this.props.updateStudentCount(response.data);
        this.setState({ submitting: false });
        this.props.closeModal();
        return null;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  }

  _getResources() {
    if (this.state.resources.length) return null;

    return Axios
      .get(this.props.classroomStudentsPath.replace(':classroom_id', this.props.rowData.id))
      .then((response) => {
        const resources = [...response.data.data];
        matchAttributesForMultipleRows(resources, this.props.columns);
        this.setState({ resources });
      });
  }

  _getClassroomSeatPool() {
    const path = Routes.plato_api_seat_pool_path(this.props.rowData.seat_pool_id, {
      extra_attributes: 'has_available_student_seats',
      only: ['id'],
    });

    return Axios
      .get(path)
      .then(response => this.setState({ classroomSeatPool: response.data.seat_pool }));
  }

  _removeCurrentStudentsFromSelectOptions(studentsForSelect) {
    const currentStudentsIds = this.state.resources.map(student => student.id);

    const filteredStudents = studentsForSelect.filter(student => !currentStudentsIds.includes(student.id));

    return filteredStudents.map(student => formatStudentForSelect(student));
  }

  _renderCheckbox(student, form) {
    return (
      <div>
        <label
          htmlFor={`remove-student-${student.id}`}
        >
          <span className="sr-only">
            remove {student.first_name} {student.last_name} from {this.props.rowData.name}
          </span>
        </label>

        <Field
          component="input"
          type="checkbox"
          value={student.id}
          checked={form.values}
          id={`remove-student-${student.id}`}
          onClick={form.mutators.handleChange}
        />
      </div>
    );
  }

  _noAvailableStudentSeats() {
    return !this.state.classroomSeatPool.has_available_student_seats;
  }

  _showWarning() {
    if (this._noAvailableStudentSeats()) {
      return StudentModal._noAvailableSeatsWarning();
    }

    return null;
  }

  _renderSelect(handleSelect) {
    return (
      <div className="form-row">
        <label
          className="mt5"
          htmlFor="select-students"
        >
          Students
        </label>

        <AsyncSelect
          className="user-search"
          joinValues
          id="select-students"
          cacheOptions
          loadOptions={this.getOptions}
          defaultOptions
          onChange={handleSelect}
          value={this.state.studentsToAdd}
        />
      </div>
    );
  }

  _renderTable(form) {
    return (
      <DataTable
        columns={this.props.columns}
        handleResourcesManually
        searching={false}
        resources={this.state.resources}
        isLoading={this.state.isLoading}
        defaultOrder={[[0, 'asc'], [1, 'asc']]}
        columnDefs={[{
          title: 'Remove',
          targets: -1,
          sortable: false,
          searchable: false,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              rowData.id && this._renderCheckbox(rowData, form),
              td
            )
        }]}
      />
    );
  }

  _renderForm() {
    if (this.state.isLoading) return <Spinner />;

    return (
      <Form
        mutators={{
          handleChange: this.handleCheckboxChange,
          handleSelect: this.handleSelect
        }}
        onSubmit={this.handleSubmit}
        render={({ form, handleSubmit, submitError }) => (
          <form
            onSubmit={handleSubmit}
            className={`safety-notes-form ${this.state.isLoading && 'loading'}`}
          >

            {this._renderSelect(form.mutators.handleSelect)}

            {this._showWarning()}

            {this._renderTable(form)}

            <SubmitError error={submitError} />

            <Footer
              wrapperClassName="pt30"
              secondaryButtonCallback={this.props.closeModal}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }

  render() {
    return (
      <div>
        <Modal
          closeModal={this.props.closeModal}
          isOpen={this.props.modalIsOpen}
          headerText="Manage Students"
        >
          {this._renderForm()}
        </Modal>
      </div>
    );
  }
}
