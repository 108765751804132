import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsDropdown from 'common/ActionsDropdown';
import PathContext from 'common/Context';
import UploadModal from 'common/UploadCsvModal';

import AddModal from './AddModal';
import InviteModal from './InviteModal';

export default class AddStaff extends Component {
  static contextType = PathContext;

  static propTypes = {
    autoRosterDistrict: PropTypes.bool.isRequired,
    canCsvUpdate: PropTypes.bool,
    contactTypeOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    flags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    stafferId: PropTypes.number.isRequired,
    updateTable: PropTypes.func.isRequired,
    userIsSysadmin: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    canCsvUpdate: true
  };

  constructor(props) {
    super(props);

    this.state = {
      addModalOpen: false,
      inviteModalOpen: false,
      uploadModalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e, type) {
    e.preventDefault();
    this.setState({ [`${type}ModalOpen`]: true });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false });
  }

  _renderAddModal() {
    if (this.state.addModalOpen) {
      return (
        <AddModal
          autoRosterDistrict={this.props.autoRosterDistrict}
          closeModal={() => this.closeModal('add')}
          contactTypeOptions={this.props.contactTypeOptions}
          modalOpen={this.state.addModalOpen}
          updateTable={this.props.updateTable}
          userIsSysadmin={this.props.userIsSysadmin}
          flags={this.props.flags}
        />
      );
    }

    return null;
  }

  _renderInviteModal() {
    if (this.state.inviteModalOpen) {
      return (
        <InviteModal
          closeModal={() => this.closeModal('invite')}
          modalOpen={this.state.inviteModalOpen}
          userType={this.props.userType}
        />
      );
    }

    return null;
  }

  _renderUploadModal() {
    if (this.state.uploadModalOpen) {
      return (
        <UploadModal
          batchUpdateCsvPath={this.context.teacherBatchUpdateCsvsPath}
          canCsvUpdate={this.props.canCsvUpdate}
          closeModal={() => this.closeModal('upload')}
          csvTemplateUrl={this.context.csvTemplateUrl}
          importCsvPath={this.context.teacherImportCsvsPath}
          importType="staff"
          manualCsvUploadPath={this.context.manualCsvUploadPath}
          modalOpen={this.state.uploadModalOpen}
          programCodes={this.props.programCodes}
          programsPath={this.context.programsPath}
          staffCsvUploadsPath={this.context.staffCsvUploadsPath}
          stafferId={this.props.stafferId}
        />
      );
    }

    return null;
  }

  _renderButton() {
    if (this.props.isAdmin) return this._renderActionsDropdown();

    // for coordinators
    return this._renderInviteButton();
  }

  _renderActionsDropdown() {
    return (
      <ActionsDropdown
        toggleClassName="btn--green mr5"
        toggleText="Add Staff"
      >
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, 'add')}
        >
          <i aria-hidden="true" className="fa fa-plus" /> Add Staff
        </a>

        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, 'invite')}
        >
          <i aria-hidden="true" className="fa fa-envelope-o" /> Invite Staff
        </a>

        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, 'upload')}
        >
          <i aria-hidden="true" className="fa fa-upload" /> Upload CSV
        </a>
      </ActionsDropdown>
    );
  }

  _renderInviteButton() {
    return (
      <button className="btn btn--green" onClick={e => this.openModal(e, 'invite')}>
        Invite Staff
      </button>
    );
  }

  render() {
    return (
      <div>
        {this._renderButton()}

        {this._renderAddModal()}
        {this._renderInviteModal()}
        {this._renderUploadModal()}
      </div>
    );
  }
}
