import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import * as Routes from 'modules/routes';
import axios from 'axios';
import { CheckboxField } from '@/components/common/Forms';
import showToast from '@/components/common/Toast';
import styles from './index.module.scss';

interface FormValues {
  en: boolean;
  es: boolean;
  videoIds: string;
}

const initialValues: FormValues = {
  en: false,
  es: false,
  videoIds: '',
};

const BulkCaptionsRequestModal = ({ isOpen, close }) => {
  const [loading, setLoading] = useState(false);
  const [invalidIdError, setInvalidIdError] = useState(false);

  const showOrderSuccessToast = (captionsOrdered: number) => {
    const videosLabel = captionsOrdered === 1 ? 'video' : 'videos';
    showToast(`Captions successfully ordered for ${captionsOrdered} ${videosLabel}.`, {
      autoClose: 3000,
    });
  };

  const validateVideoIds = (value: string): boolean => {
    const ids = value.split(',');

    return ids.every((id) => {
      const trimmedId = id.trim();
      if (!trimmedId) return true;

      const num = Number(trimmedId);

      return Number.isInteger(num) && num.toString() === trimmedId;
    });
  };

  const onSubmit = (values: FormValues) => {
    if (!validateVideoIds(values.videoIds)) {
      setInvalidIdError(true);
      return;
    }

    setInvalidIdError(false);

    const processedIds = values.videoIds.split(',')
      .map(id => id.trim())
      .filter(Boolean)
      .map(id => parseInt(id, 10))
      .filter(id => !Number.isNaN(id));

    setLoading(true);

    axios.post(
      Routes.plato_api_place_bulk_order_captions_path({
        en_video_ids: values.en ? processedIds : [],
        es_video_ids: values.es ? processedIds : [],
      }),
    )
      .then((response) => {
        console.log('Captions requested:', response.data);
        showOrderSuccessToast(response.data.ordered);
        setLoading(false);
        close();
      })
      .catch((err) => {
        console.error('Error requesting captions:', err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setInvalidIdError(false);
    close();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, values }) => (
        <SubmitModal
          headerText="Bulk Request Captions"
          isOpen={isOpen}
          close={handleClose}
          onSubmit={handleSubmit}
          isValid={(values.en || values.es) && values.videoIds.trim() !== ''}
          isLoading={loading}
          submitText="Submit"
        >
          <>
            <div className="tw-mb-6">
              <p className="tw-mb-4">Please select which captions you want to request:</p>

              <CheckboxField
                rowClass="tw-flex tw-items-center tw-mr-0 tw-mb-2"
                labelClass="!tw-mx-2 tw-flex tw-gap-0.5"
                checked={values.en}
                onChange={() => form.change('en', !values.en)}
                name="en"
                label="English"
                labelPos="right"
                fieldClass="tw-mr-0"
              />

              <CheckboxField
                rowClass="tw-flex tw-items-center tw-mr-0 tw-mb-2"
                labelClass="!tw-mx-2 tw-flex tw-gap-1.5"
                checked={values.es}
                onChange={() => form.change('es', !values.es)}
                name="es"
                label="Spanish"
                tooltipIcon="info"
                tooltipText="Spanish captions will be generated from the English captions"
                labelPos="right"
                fieldClass="tw-mr-0"
              />
            </div>

            <div className="tw-mb-6">
              <div className="tw-block tw-mb-2">IDs to order:</div>
              <input
                type="text"
                name="videoIds"
                value={values.videoIds}
                onChange={e => form.change('videoIds', e.target.value)}
                placeholder="Enter a comma separated list of video IDs to order captions for."
                className="tw-w-11/12 tw-py-6 tw-pl-4 tw-border tw-border-grey tw-border-solid tw-placeholder-med-grey tw-rounded"
              />
              {invalidIdError && (
                <div className="tw-mt-2 tw-text-red">
                  Invalid ID. Please try again.
                </div>
              )}
            </div>

            <div className={styles.warningIcon}>
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              <span className={styles.warningText}>
                If a caption already exists for the video, it will be ordered again and replaced.
              </span>
            </div>
          </>
        </SubmitModal>
      )}
    />
  );
};

BulkCaptionsRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default BulkCaptionsRequestModal;
