import React from 'react';
import Pluralize from 'pluralize';
import * as Routes from 'modules/routes';
import styles from './index.module.scss';
import { ClassroomType, NotificationType, NotificationTypes, NotificationByDateType } from './types';
import I18n from 'i18n';

interface NotificationLinksProps {
  classroom: ClassroomType,
  notificationId: number;
  notificationType: NotificationTypes;
  programId: number;
}

const NotificationLinks = ({
  classroom, notificationId, notificationType, programId,
}: NotificationLinksProps) => {
  const linkHref = () => {
    const linkAssociation = `${notificationType.toLowerCase()}_id`;

    return Routes.teacher_score_path(classroom.id, { [linkAssociation]: notificationId, program_id: programId });
  };

  const turnedInText = () => {
    const itemOrItems = Pluralize('item', classroom.turned_in_count, false);
    const turnedInString = I18n.t(`${itemOrItems}_turned_in`);

    return `${classroom.turned_in_count} ${turnedInString}`;
  };

  const needsReviewText = () => {
    const needOrNeeds = classroom.turned_in_count === 1 ? 'needs' : 'need';
    const needsReviewString = I18n.t(`${needOrNeeds}_review`);

    return `${classroom.turned_in_count} ${needsReviewString}`;
  };

  return (
    <div>
      <div className="mb5">
        <a href={linkHref()}>{classroom.name}</a>
      </div>
      <ul className={`mb5 ${styles.list}`}>
        <li>
          <a className="no-link-style" href={linkHref()}>
            {turnedInText()}&nbsp;

            {!!classroom.needs_grading_count && (
              <span className={styles.needsGrading}>
                ({needsReviewText()})
              </span>
            )}
          </a>
        </li>
      </ul>
    </div>
  );
};

interface NotificationProps {
  programId: number;
  notification: NotificationType;
}

const Notification = ({ notification, programId }: NotificationProps) => {
  return (
    <div className={styles.message}>
      <div className="mb5">{notification.title}</div>
      {notification.classrooms.map(classroom => (
        <NotificationLinks
          key={notification.id}
          classroom={classroom}
          notificationId={notification.id}
          notificationType={notification.type}
          programId={programId}
        />
      ))}
    </div>
  );
};

interface NotificationsByDateProps {
  programId: number;
  date: NotificationByDateType;
}

const NotificationsByDate = ({ date, programId }: NotificationsByDateProps) => (
  <>
    <div className={styles.date}>{date.date_formatted}</div>
    {date.notifications.map(notification => (
      <Notification
        key={`${notification.id}-${date.date_formatted}`}
        notification={notification}
        programId={programId}
      />
    ))}
  </>
);

export default NotificationsByDate;
