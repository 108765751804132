import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import ReactSwitch from 'react-switch';
import Tooltip from 'common/Tooltip';
import styles from './Switch.module.scss';

export default class Switch extends Component {
  static propTypes = {
    actions: PropTypes.func,
    ajaxPath: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
    displayLabelFirst: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelTooltip: PropTypes.string,
    model: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    actions: () => {},
    disabled: false,
    disabledTooltip: null,
    displayLabelFirst: true,
    labelTooltip: null,
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
    const data = {};
    data[this.props.model] = {};
    data[this.props.model][this.props.name] = checked;
    Axios.patch(this.props.ajaxPath, data);
    this.props.onClick();
  }

  renderSwitch() {
    const switchTooltip = this.props.disabled ? this.props.disabledTooltip : null;
    return (
      <div
        className="inline"
        data-tippy={switchTooltip}
      >
        <ReactSwitch
          onChange={this.handleChange}
          checked={this.state.checked}
          onColor="#522e91"
          offColor="#cccccc"
          checkedIcon={false}
          uncheckedIcon={false}
          height={24}
          width={48}
          handleDiameter={20}
          disabled={this.props.disabled}
        />
      </div>
    );
  }

  renderLabelTooltip() {
    if (!this.props.labelTooltip) return null;

    return (
      <Tooltip content={this.props.labelTooltip}>
        <i
          aria-hidden="true"
          className="fa fa-question-circle slide-digital-only-tooltip"
          onClick={e => e.preventDefault()}
        />
      </Tooltip>
    );
  }

  renderActions() {
    if (!this.props.actions) return null;

    return (this.props.actions);
  }

  render() {
    return (
      <label className={styles.container}>
        {!this.props.displayLabelFirst && this.renderSwitch()}
        <span>
          {`${this.props.label} `}
          {this.renderLabelTooltip()}
          {this.renderActions()}
        </span>
        {this.props.displayLabelFirst && this.renderSwitch()}
      </label>
    );
  }
}
