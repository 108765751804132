import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { renderErrors } from 'common/Forms/Utils';
import showToast from 'common/Toast';
import { CheckboxField } from 'common/Forms';
import styles from './StudentFeatures.module.scss';
import { handleRadio } from '../RadioGroup';
import {
  ActionButtonsWithError,
  handleSelect,
  handleCheckbox,
  Header,
  SelectField,
} from '../Util';
import ConfirmationModal from './ConfirmationModal';
import {
  RestartClassroomActivities,
  RestartLessonOutlines,
  RestartLessonReviewGames,
  RestartReadingChecks,
  RestartVideoActivities,
  RestartVocabularyGames,
} from './Util';

const defaultSettings = (scienceProgram, narrationEnabled) => (
  {
    lesson_game_retry: false,
    reading_game_retry: true,
    show_page_numbers: true,
    student_default_investigation: scienceProgram ? 'fast_track' : 'master',
    student_view_lesson_game_off: false,
    student_view_lesson_off: false,
    text_audio_enabled: true,
    text_audio_mode: narrationEnabled ? 'narration' : 'text_to_speech',
    video_activity_retry: false,
    vocab_game_retry: true,
  }
);

export default class StudentFeatures extends Component {
  static propTypes = {
    active: PropTypes.bool,
    handleCancel: PropTypes.func,
    manageClassesPath: PropTypes.string,
    narrationEnabled: PropTypes.bool.isRequired,
    programHasClassroomActivity: PropTypes.bool.isRequired,
    programHasLessonOutline: PropTypes.bool.isRequired,
    programHasVideoActivity: PropTypes.bool.isRequired,
    programHasVocabGames: PropTypes.bool.isRequired,
    reviewGameSetting: PropTypes.bool.isRequired,
    scienceProgram: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
      show_page_numbers: PropTypes.bool,
      student_view_lesson_off: PropTypes.bool
    }),
    showLimitedSettings: PropTypes.bool,
    stafferPath: PropTypes.string.isRequired,
    streamEnabled: PropTypes.bool.isRequired,
    updateState: PropTypes.func
  };

  static defaultProps = {
    active: false,
    settings: {
      student_view_lesson_off: false
    },
    showLimitedSettings: false,
    updateState: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      valuesForSubmit: null,
      confirmationModalIsOpen: false,
    };
  }

  handleSubmit = (values) => {
    this.setState({ confirmationModalIsOpen: true, valuesForSubmit: values });
  };

  handleSave = async () => {
    this.setState({ submitting: true });

    return Axios
      .put(this.props.stafferPath, this.state.valuesForSubmit)
      .then((response) => {
        const settings = response.data.data.settings;
        showToast('Save successful');
        this.setState({ submitting: false, confirmationModalIsOpen: false });
        this.props.updateState({ settings });
      })
      .catch(error => this._handleSubmitError(error));
  };

  _handleSubmitError(error) {
    this.setState({ submitting: false, confirmationModalIsOpen: false });
    showToast('Save failed', { msgType: 'error' });
    return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
  }

  _getInitialValues() {
    return Object.assign(
      {},
      defaultSettings(this.props.scienceProgram, this.props.narrationEnabled),
      this.props.settings,
    );
  }

  render() {
    if (!this.props.active) return null;

    return (
      <div className={this.props.streamEnabled ? undefined : styles.withInvestigationNotebook}>
        <Form
          initialValues={this._getInitialValues()}
          onSubmit={this.handleSubmit}
          mutators={{
            handleCheckbox: handleCheckbox,
            handleRadio: handleRadio,
            handleSelect: handleSelect
          }}
          render={({
            handleSubmit, submitError, values, form
          }) => {
            const checkboxProps = {
              onChange: form.mutators.handleCheckbox,
              fieldClass: 'tw-flex tw-justify-end',
              labelClass: 'tw-w-full tw-font-bold',
              rowClass: 'tw-flex tw-justify-between tw-w-[95%] tw-mb-3'
            };

            return (
              <form onSubmit={handleSubmit}>
                <Header
                  text="Reading Levels and Languages"
                  showEditPerStudentLink
                />
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.student_reading_level_a_enabled}
                    label="Level A"
                    name="student_reading_level_a_enabled"
                  />
                  The Lexile level of Level A text is at grade level.
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.student_reading_level_b_enabled}
                    label="Level B"
                    name="student_reading_level_b_enabled"
                  />
                  The Lexile level of Level B text has been reduced by approximately <br /> 25 percent.
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.student_spanish_enabled}
                    label="Spanish"
                    name="student_spanish_enabled"
                  />
                  The Spanish translation of content is based on Level A text.
                </div>
                <i className={styles.font14}>
                  In the event that Level B or Spanish text are not available, Level A text
                  will appear, even if Level A is turned off. Students will automatically return to the preferred
                  reading option available.
                </i>
                <hr />
                <Header
                  text="Student Support"
                  showEditPerStudentLink
                />
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.text_audio_enabled}
                    label="Text Read-Aloud"
                    name="text_audio_enabled"
                  />
                  Select this to allow the text audio feature in Investigation, Text with <br />
                  Notes, Lesson Games, and Assessments.
                </div>
                <div className="mb20">
                  {this.props.narrationEnabled && values.text_audio_enabled && (
                    <>
                      <SelectField
                        label="Preference for Student Text"
                        name="text_audio_mode"
                        onChange={form.mutators.handleSelect}
                        options={[
                          { label: 'Narration', value: 'narration' },
                          { label: 'Text to Speech', value: 'text_to_speech' }
                        ]}
                        sectionHeaderClass="bold"
                        indented
                      />
                    </>
                  )}
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.main_ideas_enabled}
                    label="Main Ideas"
                    name="main_ideas_enabled"
                  />
                  Allow students to click a button to reveal main ideas in the student text.
                </div>
                {!this.props.showLimitedSettings &&
                  <>
                    <hr />
                    <Header text="Allow Multiple Attempts" />

                    <RestartLessonReviewGames form={form} values={values} />

                    {this.props.programHasVocabGames &&
                      <RestartVocabularyGames form={form} values={values} />
                    }

                    {this.props.reviewGameSetting &&
                      <RestartReadingChecks form={form} values={values} />
                    }

                    {this.props.programHasVideoActivity &&
                      <RestartVideoActivities form={form} values={values} />
                    }

                    {this.props.programHasClassroomActivity &&
                      <RestartClassroomActivities form={form} values={values} />
                    }

                    {this.props.programHasLessonOutline &&
                      <RestartLessonOutlines form={form} values={values} />
                    }

                    <div className="mb20">
                      <CheckboxField
                        {...checkboxProps}
                        checked={values.lock_after_turn_in}
                        label="Lock after Turn In"
                        name="lock_after_turn_in"
                      />
                      Select this to allow students to turn in each notebook section only once.
                      Once turned <br /> in, the notebook section will be in view only mode.
                      <p className={styles.turnInNote}>This setting does not apply to unit projects and slideshows.</p>
                    </div>
                    <hr />
                    <Header text="Print Book Alignment" />
                    <div className="mb20">
                      <CheckboxField
                        {...checkboxProps}
                        checked={values.show_page_numbers}
                        label="See Print Book Page Numbers"
                        name="show_page_numbers"
                      />
                      Display page numbers in the section drop down for student text.
                    </div>
                  </>
                }

                <ActionButtonsWithError
                  handleCancel={this.props.handleCancel}
                  submitError={submitError}
                  submitting={this.state.submitting}
                />
              </form>
            );
          }}
        />

        {this.state.confirmationModalIsOpen && (
          <ConfirmationModal
            submitting={this.state.submitting}
            handleSave={this.handleSave}
            isOpen={this.state.confirmationModalIsOpen}
            closeModal={() => this.setState({ confirmationModalIsOpen: false })} />
        )}
      </div>
    );
  }
}
