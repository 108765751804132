import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import { formatLocalTimestamp } from 'common/Utils';
import Tooltip from 'common/Tooltip';
import styles from './AssignmentStatus.module.scss';

const AssignmentStatus = ({
  initialStatus, turnedInTime, dueDateTime, dueDateEnforced, studentScore, maxScore, displayBorder
}) => {
  const status = initialStatus;

  const renderStatus = () => {
    if (status === 'turned_in') {
      if (!turnedInTime) return I18n.t('turned_in');

      return I18n.t('turned_in_on', { timestamp: formatLocalTimestamp(turnedInTime, false) });
    }

    if (status === 'in_progress') {
      return I18n.t('in_progress');
    }

    return I18n.t('not_started');
  };

  const renderScore = () => {
    if (status !== 'turned_in' || !maxScore) return null;

    const score = (studentScore || studentScore === 0) ? studentScore : I18n.t('grading_in_progress');

    return (
      <p>
        <span className={styles.sectionTitle}>
          {I18n.t('total_score')}:
        </span>
        {' '}
        {`${score} / ${maxScore}`}
      </p>
    );
  };

  const renderDueDate = () => {
    if (!dueDateTime) return null;

    const lockTooltip = (
      <Tooltip
        content={`${I18n.t('assignment_lock_warning')}.`}
        theme="white"
      >
        <i className="fa fa-lock ml5" />
      </Tooltip>
    );

    return (
      <p>
        <span className={styles.sectionTitle}>
          {I18n.t('due_date')}:
        </span>
        {' '}
        {formatLocalTimestamp(dueDateTime, false)}
        {dueDateEnforced && lockTooltip}
      </p>
    );
  };

  return (
    <div className={`${styles.status} ${displayBorder && styles.border} ${!displayBorder && styles.inline}`}>
      <p>
        <span className={styles.sectionTitle}>
          {I18n.t('status')}:
        </span>
        {' '}
        {renderStatus()}
      </p>
      {renderDueDate()}
      {renderScore()}
    </div>
  );
};

AssignmentStatus.propTypes = {
  displayBorder: PropTypes.bool,
  dueDateEnforced: PropTypes.bool,
  dueDateTime: PropTypes.string,
  initialStatus: PropTypes.string,
  maxScore: PropTypes.number,
  studentScore: PropTypes.number,
  turnedInTime: PropTypes.string
};

AssignmentStatus.defaultProps = {
  displayBorder: true,
  dueDateEnforced: false,
  dueDateTime: null,
  initialStatus: null,
  maxScore: null,
  studentScore: null,
  turnedInTime: null
};

export default AssignmentStatus;
