import React from 'react';

export type SlideGroup = {
  __typename: 'SlideGroup';
  displaySlideHeader: boolean;
  extension: boolean;
  icon?: string;
  id: number | string;
  pacing?: number;
  position: number;
  slides: { id: number }[];
  titleEn: string;
  titleEs?: string;
  type: string;
};

export type Slide = {
  slideGroupId: number;
  id: number | string;
  position: number;
  title: string;
  type?: string;
  backgroundAudioUrl?: string;
  backgroundAudioFileName?: string;
  backgroundAudioEsUrl?: string;
  backgroundAudioEsFileName?: string;
};

export type SetCurrentAction = (nextAction: typeof CREATING_SLIDE_GROUPS | typeof EDITING_SLIDE_GROUP) => void;

export const CREATING_SLIDE_GROUPS = 'CREATING_SLIDE_GROUPS';
export const EDITING_SLIDE_GROUP = 'EDITING_SLIDE_GROUP';

type FooterProps = {
  nearlyFullScreen: boolean;
  primaryButtonCallback: () => void;
  primaryButtonSubmit: boolean;
  primaryButtonText: string;
  secondaryButtonCallback: () => void;
  secondaryButtonText: string;
  wrapperClassName: string;
};

export const useFooterProps = (
  currentAction: typeof CREATING_SLIDE_GROUPS | typeof EDITING_SLIDE_GROUP,
  setCurrentAction: SetCurrentAction,
  onSubmit: (any) => void,
  modalManager: ModalManager,
): FooterProps => {
  const closeModal = () => {
    modalManager.close();
    setCurrentAction(CREATING_SLIDE_GROUPS);
  };

  const baseProps = {
    nearlyFullScreen: true,
    wrapperClassName: 'pr20 pl20 pb20',
    primaryButtonSubmit: false,
  };

  if (currentAction === CREATING_SLIDE_GROUPS) {
    return ({
      ...baseProps,
      primaryButtonText: 'Close',
      primaryButtonCallback: closeModal,
      secondaryButtonCallback: closeModal,
      secondaryButtonText: 'Cancel',
    });
  }

  return <FooterProps>({
    ...baseProps,
    primaryButtonText: 'Submit',
    primaryButtonCallback: onSubmit,
    secondaryButtonCallback: () => setCurrentAction(CREATING_SLIDE_GROUPS),
    secondaryButtonText: 'Back',
  });
};

type AllCheckedProps = {
  selectedSlideIds: any[];
  slides: any[];
};

export const allChecked = ({ selectedSlideIds, slides }: AllCheckedProps) => {
  if (selectedSlideIds.length === 0) return 'none';

  const all = slides.every(slide => selectedSlideIds.includes(parseInt(slide.id, 10)));
  return all ? 'all' : 'some';
};

export const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>,
  selectedSlideIds: any[],
  setSelectedSlideIds: (prevArray) => void): void => {
  const id = parseInt(e.target.value, 10);

  if (selectedSlideIds.includes(id)) {
    setSelectedSlideIds(prevArray => prevArray.filter(slideId => slideId !== id));
  } else {
    setSelectedSlideIds(prevArray => [...prevArray, id]);
  }
};

export const uncheckAllCheckboxes = (
  { setSelectedSlideIds, setBulkChecked }: { setSelectedSlideIds: (any: []) => void,
    setBulkChecked: (string) => void },
) => {
  setSelectedSlideIds([]);
  setBulkChecked(('none'));
};

export const checkAllCheckboxes = (
  { slides, setSelectedSlideIds,
    setBulkChecked }: { slides: any[], setSelectedSlideIds: (any: number[]) => void,
    setBulkChecked: (string) => void },
) => {
  const allSlideIds = slides.map(slide => parseInt(slide.id, 10));

  setSelectedSlideIds(allSlideIds);
  setBulkChecked(('all'));
};

export const handleBulkCheckboxChange = (
  { bulkChecked, setSelectedSlideIds, setBulkChecked, slides }: { bulkChecked: string,
    setSelectedSlideIds: (any: number[]) => void,
    setBulkChecked: (string) => void, slides: any[] },
) => {
  if (bulkChecked === 'all') {
    uncheckAllCheckboxes({ setSelectedSlideIds, setBulkChecked });
  } else {
    checkAllCheckboxes({ slides, setSelectedSlideIds, setBulkChecked });
  }
};
