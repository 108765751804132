import update from 'immutability-helper';
import { ISlideShow, ISlide } from './useSlideShowStore';

type ISlideOrSlideShow = ISlide | ISlideShow;
export const updatedFromPayload = (
  object: ISlideOrSlideShow, payload: Partial<ISlideShow> | Partial<ISlide>
): ISlideOrSlideShow => {
  if (!payload) return object;

  const mergeCommand = Object.keys(payload).reduce((command, key) => (
    { ...command, [key]: { $set: payload[key] } }
  ), {});

  return update(object, mergeCommand);
};
