import React from 'react';
import PropTypes from 'prop-types';

import { decode } from 'html-entities';

import Modal from '../../../common/Modal';
import EditForm from './EditForm';

import styles from '../Modal.module.scss';

export default function Edit({
  closeModal, modalIsOpen, updateClassroom, updatePath, rowData
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText="Edit Class Details"
    >
      <EditForm
        closeModal={closeModal}
        updateClassroom={updateClassroom}
        updatePath={updatePath}
        name={decode(rowData.name)}
        period={rowData.period}
        uid={rowData.uid}
      />
    </Modal>
  );
}

Edit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }).isRequired,
  updatePath: PropTypes.string.isRequired,
  updateClassroom: PropTypes.func.isRequired
};
