import { useMemo } from 'react';
import { IProgram, ISeatPool } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import useProgramAccessStore from '@/components/admin/ProgramAccess/ProgramAccessTable/useProgramAccessStore';

export interface GroupedPrograms {
  id: string;
  seatPools: ISeatPool[];
  programs: {
    recommended: IProgram[];
    other: IProgram[];
  };
}

const EDITION_ORDER = ['fourth', 'third', 'second', 'first'];

const byEdition = (p1: IProgram, p2: IProgram) => {
  const i1 = EDITION_ORDER.indexOf(p1.edition.toLowerCase());
  const i2 = EDITION_ORDER.indexOf(p2.edition.toLowerCase());

  if (i1 < i2) return -1;
  if (i1 > i2) return 1;
  return 0;
};

const rowOrder = (g1: GroupedPrograms, g2: GroupedPrograms) => {
  if (g1.programs.recommended.length && !g2.programs.recommended.length) return -1;
  if (!g1.programs.recommended.length && g2.programs.recommended.length) return 1;

  if (g1.seatPools.length && !g2.seatPools.length) return -1;
  if (!g1.seatPools.length && g2.seatPools.length) return 1;

  return g1.id.localeCompare(g2.id);
};

// Group programs by title/subtitle
// Sort programs by descending edition
// Partition programs by recommended and other
const groupPrograms = (programs: IProgram[], seatPools: ISeatPool[], licenseId: number, subscriberStateId: number) => {
  if (!programs.length) return [];

  const groupedPrograms = {};

  programs.sort(byEdition).forEach((p: IProgram) => {
    const key = `${p.title} ${p.subtitle}`;
    groupedPrograms[key] = [...(groupedPrograms[key] || []), p];
  });

  return Object.keys(groupedPrograms).map<GroupedPrograms>(key => ({
    id: key,
    seatPools: seatPools.filter(pool => groupedPrograms[key].map((p: IProgram) => p.id).includes(pool.program_id)),
    programs: {
      recommended: groupedPrograms[key].filter((p: IProgram) => (p.recommended_for_states[licenseId].includes(subscriberStateId))),
      other: groupedPrograms[key].filter((p: IProgram) => !p.recommended_for_states[licenseId].includes(subscriberStateId)),
    },
  })).sort(rowOrder);
};

const useGroupedPrograms = (licenseId: number, subscriberStateId: number) => {
  const programs = useProgramAccessStore(state => state.programs);
  const seatPools = useProgramAccessStore(state => state.seatPools);

  return useMemo<GroupedPrograms[]>(() => {
    const programsForSelectedLicense = programs.filter(p => p.licenses.map(l => l.id).includes(licenseId));

    return groupPrograms(programsForSelectedLicense, seatPools, licenseId, subscriberStateId);
  }, [licenseId, programs.length]);
};

export default useGroupedPrograms;
