import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApolloProvider, useQuery } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import apolloClient from 'common/ApolloClient';
import Spinner from 'common/Spinner';
import videojs from 'video.js';
import QualitySelector from '@silvermine/videojs-quality-selector';
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player';
import { GET_PRESENT_MODE_SLIDE_SHOW } from '../Utils/slideShowGraphQL';
import Slide from '../Slide';
import styles from './PresentMode.module.scss';
import './PresentMode.scss';
import Navigator from './Navigator';
import TransitionSlide from './TransitionSlide/index.tsx';
import {
  autoplayMediaSetting,
  formatPresentSlides,
  slideBackgroundAudioUrl,
  uniqueUrl,
  useSlugParser
} from './Utils.ts';
import useSlideConditions from './useSlideConditions';
import 'video.js/dist/video-js.css';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';
import useSlideShowStore from '../stores/useSlideShowStore';
import useSlideShowContext from '../stores/useSlideShowContext';
import SlideShowStoreProvider from '../stores/SlideShowStoreProvider';
import { queryClient } from '../../../../utils/ReactQuery';
import useFlags from '../../../../hooks/useFlags';
import { SLIDE_SETTINGS_STORAGE_KEY } from './Constants';
import useThemes from '../../../../hooks/api/useThemes';
import cn from '../../../../utils/cn';
import EnvironmentLabel from '../Builder/EnvironmentLabel';

export const DEFAULT_BACKGROUND_AUDIO_VOLUME = 0.7;

const PresentModeWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <SlideShowStoreProvider props={props}>
      <QueryClientProvider client={queryClient}>
        <AudioPlayerProvider>
          <PresentMode {...props} />
        </AudioPlayerProvider>
      </QueryClientProvider>
    </SlideShowStoreProvider>
  </ApolloProvider>
);

const PresentMode = (props) => {
  const [slides, setSlides] = useState([]);
  const slideRef = useRef();
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [position, setPosition] = useState(0);
  const [isBackgroundAudioMuted, setIsBackgroundAudioMuted] = useState(false);
  const [themeClass, setThemeClass] = useState('');

  const setActivityType = useSlideShowContext(state => state.setActivityType);
  const setIsPresentMode = useSlideShowContext(state => state.setIsPresentMode);
  const setIsPresentModeStore = useSlideShowStore(state => state.setIsPresentMode);
  const setSlideRef = useSlideShowContext(state => state.setSlideRef);
  const setLocale = useSlideShowContext(state => state.setLocale);
  const setJumpToSlide = useSlideShowContext(state => state.setJumpToSlide);
  const setCurrentSlide = useSlideShowContext(state => state.setCurrentSlide);
  const unansweredQuestions = useSlideShowContext(state => state.unansweredQuestions);
  const setUnansweredQuestions = useSlideShowContext(state => state.setUnansweredQuestions);
  const setLessonId = useSlideShowContext(state => state.setLessonId);
  const setSlideShowId = useSlideShowContext(state => state.setSlideShowId);
  const setUnitId = useSlideShowContext(state => state.setUnitId);
  const themeId = useSlideShowContext(state => state.themeId);
  const setThemes = useSlideShowContext(state => state.setThemes);

  const { data: themes, isSuccess, isLoading } = useThemes();

  useEffect(() => {
    if (isLoading || !isSuccess) return;
    setThemes(themes);
  }, [isLoading, isSuccess, themes]);

  useEffect(() => {
    if (!themes) return;
    setThemeClass(themes.find(theme => theme.id === themeId)?.css_name);
  }, [themes, themeId]);

  useEffect(() => setActivityType(props.slideShow.activity_type), [props.slideShow.activity_type]);
  useEffect(() => {
    setIsPresentMode(true);
    // this is here because we apparently look at the store in `AnswerKeyButton.tsx` from drag and drop questions
    setIsPresentModeStore(true);
  }, []);
  useEffect(() => setSlideRef(slideRef), [slideRef.current]);
  useEffect(() => setLocale(I18n.locale), [I18n.locale]);
  useDeepCompareEffect(() => setCurrentSlide(selectedSlide), [selectedSlide || {}]);

  // need to move this one still
  const setGetSetSlideAudioVolume = useSlideShowStore(state => state.setGetSetSlideAudioVolume);
  // moving this one breaks a bunch of stuff
  const setSlideShow = useSlideShowStore(state => state.setSlideShow);
  const setStoreSlides = useSlideShowStore(state => state.setSlides);
  const setContextSlides = useSlideShowContext(state => state.setSlides);

  const queryData = {
    variables: {
      parentId: props.parentId,
      parentType: props.parentType,
      programId: props.programId,
      slideShowId: props.slideShowId,
      studentId: props.userType === 'Student' ? props.userId : null,
    },
  };

  const {
    loading: loadingSlideShow,
    data: slideShowData,
    error
  } = useQuery(GET_PRESENT_MODE_SLIDE_SHOW, queryData);
  window.videojs = videojs;
  QualitySelector(videojs);

  useSlugParser(selectedSlide);

  const { isSlideLocked } = useSlideConditions();

  useDeepCompareEffect(() => {
    if (!slideShowData) return;
    if (!slideShowData.slideShow) return;
    if (!slideShowData.slideShow.lesson) return;

    setLessonId(slideShowData.slideShow.lesson.id);
  }, [slideShowData || {}]);

  useDeepCompareEffect(() => {
    if (!slideShowData) return;

    setSlideShow(slideShowData.slideShow);
    setSlideShowId(slideShowData.slideShow.id);
  }, [slideShowData || {}]);

  useDeepCompareEffect(() => {
    if (!slideShowData) return;
    if (!slideShowData.slideShow.slides) return;

    setStoreSlides(formatPresentSlides(slideShowData.slideShow, props.slideIds, props.showTransitionSlides));
    setContextSlides(formatPresentSlides(slideShowData.slideShow, props.slideIds, props.showTransitionSlides));
  }, [slideShowData || {}]);

  useDeepCompareEffect(() => {
    if (!slideShowData) return;
    if (!slideShowData.slideShow) return;
    if (!slideShowData.slideShow.unit) return;

    setUnitId(slideShowData.slideShow.unit.id);
  }, [slideShowData || {}]);

  useDeepCompareEffect(() => {
    if (!slideShowData) return;
    if (!slideShowData.slideShow) return;
    if (!slideShowData.slideShow.unansweredQuestions) return;

    setUnansweredQuestions(slideShowData.slideShow.unansweredQuestions);
  }, [slideShowData || {}]);

  useLayoutEffect(() => {
    // Remove width and height styling from video to allow auto resizing to fit container for fullscreen.
    document.querySelectorAll('.vjs-tech').forEach((video) => {
      video.style.removeProperty('width');
      video.style.removeProperty('height');
    });
  }, [selectedSlide]);

  const audioPlayer = useAudioPlayer();

  const { autoplayAudio } = useFlags();
  const {
    load: loadAudio,
    stop: stopAudio,
    playing: audioPlaying,
    volume: audioVolume,
  } = audioPlayer;

  const volumeForAudioPlayer = (isMuted) => {
    const slideSettings = JSON.parse(localStorage.getItem(SLIDE_SETTINGS_STORAGE_KEY)) || {};
    const { backgroundAudioVolume } = slideSettings;

    if (isMuted) return 0;

    return backgroundAudioVolume != null ?
             backgroundAudioVolume : audioVolume() !== 0 ?
             audioVolume() : DEFAULT_BACKGROUND_AUDIO_VOLUME;
  };

  useEffect(() => {
    if (audioPlaying) stopAudio();
    if (!autoplayAudio) return;

    const backgroundAudioUrl = slideBackgroundAudioUrl(selectedSlide);
    if (backgroundAudioUrl) {
      const isMuted = !autoplayMediaSetting(props.slideShowId);
      setIsBackgroundAudioMuted(isMuted);

      // Use a unique version of the URL, to fix the case where
      // leaving a slide and coming back doesn't replay audio since it's the same `src` URL.
      const audioUrl = uniqueUrl(backgroundAudioUrl);

      loadAudio({
        autoplay: true,
        src: audioUrl,
        volume: volumeForAudioPlayer(isMuted)
      });
    }
  }, [selectedSlide, props.slideShowId]);

  // store the method that sets and gets the audio volume
  useEffect(() => {
    setGetSetSlideAudioVolume(audioVolume);
  }, [audioPlaying, selectedSlide]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }

    if (loadingSlideShow) return;
    if (!slideShowData || !slideShowData.slideShow.slides) return;

    setSlides(formatPresentSlides(slideShowData.slideShow, props.slideIds, props.showTransitionSlides));
  }, [slideShowData]);

  // set the starting slide after slides have been loaded from GraphQL
  useEffect(() => {
    if (slides.length === 0) return;

    const startingSlideId = slides.find(slide => (
      slide.position === props.startingSlide || parseInt(slide.id, 10) === props.startingSlide
    ))?.id;
    const startingSlide = slides.find(slide => slide.id === startingSlideId);

    if (props.startingSlide !== 0 && startingSlide) {
      const availableStartingSlide = [...slides].reverse()
        .slice(slides.length - startingSlide.position, slides.length)
        .find(s => !isSlideLocked(s)); // Find first slide before starting slide that isn't locked

      setSelectedSlide(availableStartingSlide);
    }
    else {
      // Default to first slide if we can't find the slide with the given starting position
      setSelectedSlide(slides[0]);
    }
  }, [slides]);

  useEffect(() => {
    const applyCORSTimeout = setTimeout(() => {
      document.querySelectorAll('.vjs-poster').forEach((poster) => {
        // eslint-disable-next-line no-param-reassign
        poster.style.backgroundColor = 'transparent';
        poster.setAttribute('crossorigin', 'anonymous');
      });
    }, 1000);

    if (selectedSlide) setPosition(selectedSlide.position);

    return () => clearTimeout(applyCORSTimeout);
  }, [selectedSlide]);

  const jumpToSlide = (newPosition) => {
    const slide = slides.at(newPosition);

    if (isSlideLocked(slide)) return;

    setSelectedSlide(slide);
  };

  useDeepCompareEffect(() => setJumpToSlide(jumpToSlide), [slides, unansweredQuestions]);

  const sharedProps = {
    isTemporary: props.isTemporary,
    parentId: props.parentId,
    parentType: props.parentType,
    programId: props.programId,
    slideShowId: props.slideShowId,
  };

  const displaySelectedSlide = (slide) => {
    const templateObjects = slide.slideTemplate && slide.slideTemplate.slideTemplateObjects;
    const slideGroups = slides.filter(s => s.type === 'Transition');

    return (
      <span className={`current-slide locale-${props.locale || 'en'}`} ref={slideRef}>
        {slide.type === 'Transition' ? (
          <TransitionSlide
            imageUrl={slideShowData.slideShow.imageUrl}
            slideGroupIndex={slideGroups.findIndex(group => group.id === slide.id)}
            slideGroups={slideGroups}
            jumpToSlide={jumpToSlide}
            AssignmentShortcutProps={props.AssignmentShortcutProps}
            LmsShareProps={props.LmsShareProps}
            GradeShortcutProps={props.GradeShortcutProps}
            isTemporary={props.isTemporary}
          />
        ) : (
          <Slide
            forDisplay="present"
            imageModel={slide.imageModel}
            slide={slide}
            slideId={slide.id}
            slideObjects={slide.slideObjects}
            templateObjects={templateObjects}
            userType={props.userType}
            jumpToSlide={jumpToSlide}
            slides={slides}
            slideGroups={slideGroups}
          />
        )}
        <Navigator
          FullScreenProps={{ fullScreenRef: { current: document.body } }}
          NavigationProps={{
            dropdownItemText: 'Slide',
            numElements: slides.length,
            onBack: () => jumpToSlide(position - 2),
            onDirectNav: jumpToSlide,
            onForward: () => jumpToSlide(position),
            position,
          }}
          BackToButtonProps={{ ...sharedProps, isTemporary: props.isTemporary }}
          SlideAudioControlProps={{
            isMuted: isBackgroundAudioMuted,
            setIsMuted: setIsBackgroundAudioMuted
          }}
          SlideShowEditButtonProps={{ ...sharedProps, position }}
          slideShow={props.slideShow}
        />
        <EnvironmentLabel
          environment={props.environment}
          locale={props.locale}
          programId={props.programId}
          userType={props.userType}
        />
      </span>
    );
  };

  return (
    <div id="slide_reel" className={cn(styles.presentModeContainer, {
      [themeClass]: !!themeId
    })}>
      {loadingSlideShow ? <Spinner className="tw-flex tw-justify-center tw-items-center tw-text-white" /> : (
        slides && selectedSlide && displaySelectedSlide(selectedSlide)
      )}
    </div>
  );
};

PresentMode.propTypes = {
  allowImpersonate: PropTypes.bool.isRequired,
  allowViewSlideshows: PropTypes.bool,
  environment: PropTypes.string.isRequired,
  isResettable: PropTypes.bool,
  hasNotebookQuestions: PropTypes.bool,
  locale: PropTypes.string,
  AssignmentShortcutProps: PropTypes.object.isRequired,
  GradeShortcutProps: PropTypes.object.isRequired,
  LmsShareProps: PropTypes.object.isRequired,
  isTemporary: PropTypes.bool,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  programId: PropTypes.number.isRequired,
  referrerProgramId: PropTypes.number,
  showTransitionSlides: PropTypes.bool,
  slideIds: PropTypes.arrayOf(PropTypes.number),
  slideShow: PropTypes.object.isRequired,
  slideShowId: PropTypes.number.isRequired,
  startingSlide: PropTypes.number,
  themeId: PropTypes.number,
  userId: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
};

PresentMode.defaultProps = {
  allowViewSlideshows: false,
  isResettable: false,
  hasNotebookQuestions: false,
  locale: 'en',
  parentId: null,
  parentType: null,
  showTransitionSlides: true,
  slideIds: [],
  startingSlide: 1,
  themeId: null,
};

export default PresentModeWrapper;
