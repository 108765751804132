import React, { createContext, useRef } from 'react';
import { createSlideShowStore } from '@/components/admin/SlideShow/stores/useSlideShowStore';

type SlideShowBuilderProps = {
  canEditQuestions?: boolean;
  classroomIds?: number[]
  lessonId?: number;
  locale?: string;
  position?: number;
  programId: number;
  returnPath: string;
  slideId?: string;
  slideShowId: number;
  stafferId: number;
  unitId?: number;
  userType: UserType;
};

interface SlideShowStoreProviderProps {
  children?: React.ReactNode;
  props: SlideShowBuilderProps;
}

type SlideShowStore = ReturnType<typeof createSlideShowStore>;

export const SlideShowContext = createContext<SlideShowStore | null>(null);

const SlideShowStoreProvider = ({ children, props }: SlideShowStoreProviderProps) => {
  const storeRef = useRef<SlideShowStore>();

  if (!storeRef.current) {
    storeRef.current = createSlideShowStore(props, storeRef);
  }

  return (
    <SlideShowContext.Provider value={storeRef.current}>
      {children}
    </SlideShowContext.Provider>
  );
};

export default SlideShowStoreProvider;
