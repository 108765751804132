import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import BuilderContext from 'common/Context';
import ReactDOMServer from 'react-dom/server';
import FroalaEditor from '../../../../../FroalaEditor';
import DraggableContainer from '../DraggableContainer';
import DeleteButton from '../DeleteButton';
import ResizableContainer from '../ResizableContainer';
import styles from './TextArea.module.scss';
import froalaConfig from './FroalaConfig';
import useVocabularyStore from '../../useVocabularyStore';

const TextArea = ({ boundsRef, height, id, text, left, minDimensions, top, type, width, vocabId }) => {
  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const [vocabTerm, setVocabTerm] = useState({ label: '' });
  const [editMode, setEditMode] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const { textAreaDispatch, questionId, loading } = useContext(BuilderContext);
  const label = `Text area ${id.toUpperCase()}`;
  const MOVEMENT_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

  useEffect(() => {
    if (vocabId && !loading) {
      setVocabTerm(vocabularyTerms.find(vocab => vocab.value === vocabId));
    }
    else {
      setVocabTerm({ label: '' });
    }
  }, [vocabId, vocabularyTerms, loading]);

  const handleKeyDown = (e) => {
    if (MOVEMENT_KEYS.includes(e.key)) {
      e.preventDefault();
      textAreaDispatch({ payload: { height, id, key: e.key, left, text, top, width }, type: 'MOVE_WITH_ARROW_KEY' });
    }
  };

  const updateText = (value) => {
    textAreaDispatch({ payload: { height, id, left, text: value, top, width }, type: 'UPDATE' });
  };

  return (
    <DraggableContainer
      item={{ height, id, label, left, text, title: label, top, type, width, vocabId }}
      handleKeyDown={handleKeyDown}
      draggable={!editMode && !isResizing}
      minDimensions={minDimensions}
      type="TextArea"
    >
      <ResizableContainer
        highlighted={false}
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={(e, newDimensions) => {
          setIsResizing(false);
          textAreaDispatch({ payload: { id, left, text, top, ...newDimensions }, type: 'UPDATE' });
        }}
        parentEl={boundsRef && boundsRef.current && boundsRef.current.firstChild}
      >
        <div className={styles.textareaContainer}>
          {editMode ? (
            <FroalaEditor
              customConfig={froalaConfig(questionId)}
              text={text}
              onModelChange={newContent => updateText(newContent)}
              model={text}
              tag="div"
            />
          ) : (
            <div
              className={styles.textarea}
              dangerouslySetInnerHTML={{ __html: vocabTerm.definition || text }}
            />
          )}
          <div
            role="button"
            className={`fa ${editMode ? 'fa-edit' : 'fa-arrows'} ${styles.editIcon}`}
            onClick={() => setEditMode(!editMode)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setEditMode(!editMode);
            }}
            aria-label="edit toggle"
          />
          <DeleteButton
            id={id}
            type={type}
            label={`Delete ${label}`}
          />
        </div>
      </ResizableContainer>
    </DraggableContainer>
  );
};

TextArea.propTypes = {
  boundsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  minDimensions: PropTypes.shape({ height: PropTypes.number, width: PropTypes.number }).isRequired,
  text: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  vocabId: PropTypes.number,
  width: PropTypes.number,
};

TextArea.defaultProps = {
  height: 80,
  vocabId: null,
  width: 224,
};

export default TextArea;
