import React from 'react';
import ActionsDropdown from 'components/common/ActionsDropdown';
import * as Routes from 'modules/routes';

interface DropdownLinkProps {
  href: string;
  icon: string;
  text: string;
}

const DropdownLink = ({ text, icon, href }: DropdownLinkProps) => (
  <a
    className="dropdown-item"
    href={href}
    role="button"
  >
    <i aria-hidden="true" className={`fa ${icon}`}/>
    {text}
  </a>
);

interface UnitActionsProps {
  programId: number
  unitId: number
}

const UnitActions = ({ programId, unitId }: UnitActionsProps) => {
  return (
    <ActionsDropdown id={`unit_${unitId}`}>
      <DropdownLink
        href={Routes.edit_admin_unit_path(unitId)}
        icon="fa-edit"
        text="Edit Unit Lessons"
      />
      <div className="dropdown-divider" />
      <DropdownLink
        href={Routes.unit_index_admin_notebooks_path({ program_id: programId, unit_id: unitId })}
        icon="fa-eye"
        text="See Unit Projects"
      />
      <DropdownLink
        href={Routes.admin_unit_unit_project_materials_path(unitId)}
        icon="fa-eye"
        text="See Unit Materials"
      />
      <DropdownLink
        href={Routes.admin_unit_sections_path(unitId, { program_id: programId })}
        icon="fa-eye"
        text="See Unit Sections"
      />
      <DropdownLink
        href={Routes.unit_index_admin_unit_slide_shows_path(unitId)}
        icon="fa-eye"
        text="See Unit Slideshows"
      />

    </ActionsDropdown>
  );
};

export default UnitActions;
