import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import I18n from '@/modules/i18n';

import { Footer } from '../Modal';
import { SubmitError } from '../Forms/Utils';

export default class DeleteForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    confirmationMessage: PropTypes.string,
    deleteButtonClass: PropTypes.string,
    deleteButtonLabel: PropTypes.string,
    destroyPath: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    removeResource: PropTypes.func.isRequired
  };

  static defaultProps = {
    closeModal: () => {}
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleDelete = this.handleDelete.bind(this);
  }

  setError(errorMessage) {
    console.log(errorMessage);
    this.setState({ error: errorMessage, submitting: false });
  }

  handleDelete(e) {
    e.preventDefault();

    this.setState({ submitting: true });

    return Axios
      .delete(this.props.destroyPath.replace(':id', this.props.id))
      .then((response) => {
        if (response.data.errors) {
          this.setError(response.data.errors);
        }
        else {
          this.setState(
            { error: false, submitting: false }, () => {
              this.props.removeResource(response.data.data);
              this.props.closeModal();
            }
          );
        }
      })
      .catch((error) => {
        this.setError(error.message);
      });
  }

  render() {
    return (
      <form onSubmit={this.handleDelete}>
        <p className="mb10">
          {this.props.confirmationMessage || I18n.t('delete_confirmation', { item: 'item' })}
        </p>

        {this.state.error && <SubmitError error={this.state.error} />}

        <Footer
          secondaryButtonCallback={this.props.closeModal}
          primaryButtonClassName={this.props.deleteButtonClass}
          primaryButtonText={this.props.deleteButtonLabel || I18n.t('delete')}
          submittingText={`${I18n.t('deleting')}...`}
          submitting={this.state.submitting}
        />
      </form>
    );
  }
}
