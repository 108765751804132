import React, { useState, useEffect } from 'react';
import I18n from 'i18n';
import * as Routes from 'modules/routes';
import moment from 'moment-timezone';
import Axios from 'axios';
import DashboardCard from '../../DashboardCard';
import styles from '../../index.module.scss';
import NotificationsByDate from './NotificationsByDate';
import { NotificationByDateType } from './types';

interface StudentWorkCardProps {
  lessonId: number;
  program: { id: number; }
}

const StudentWorkCard = ({ lessonId, program }: StudentWorkCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationsByDate, setNotificationsByDate] = useState<NotificationByDateType[]>([]);
  const [error, setError] = useState();

  useEffect(() => {
    const getPath = Routes.plato_api_staffer_notifications_path({
      program_id: program.id,
      time_zone: moment.tz.guess(),
    });

    setIsLoading(true);
    Axios
      .get<{ dates: NotificationByDateType[] }>(getPath)
      .then((response) => {
        setNotificationsByDate(response.data.dates);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }, []);

  const renderError = () => (
    <div className={styles.message}>
      <p>Refresh the page to load notifications.</p>
    </div>
  );

  const renderStudentNotifications = () => {
    if (isLoading) return null;
    if (error) return renderError();
    if (!notificationsByDate.length) return <p className={styles.cardBodyContent}>{I18n.t('no_student_work_text')}</p>;

    return notificationsByDate.map((date) => {
      const notificationIds = date.notifications.map(notification => `${notification.type}${notification.id}`).join(',');

      return (
        <NotificationsByDate
          key={`${date.date_formatted}-${notificationIds}`}
          date={date}
          programId={program.id}
        />
      );
    });
  };

  return (
    <DashboardCard
      borderColor="blue"
      label={<div className="tw-bg-white">{I18n.t('student_work')}</div>}
      link={{
        label: <div className="tw-bg-white">{I18n.t('view_grades')}</div>,
        url: Routes.teacher_program_scores_path(program.id, { lesson_id: lessonId })
      }}
      showFooter={notificationsByDate.length > 1}
      cardExpanded={false}
      isLoading={isLoading}
      bodyClassName={!notificationsByDate.length && styles.cardBody}
    >
      {renderStudentNotifications()}
    </DashboardCard>
  );
};

export default StudentWorkCard;
