import React from 'react';
import ImpersonateStudent from 'components/staffer/ImpersonateStudent';
import { ImpersonateStudentProps } from 'components/staffer/ImpersonateStudent/types';
import styles from './index.module.scss';

interface ExtendedImpersonateStudentProps extends ImpersonateStudentProps {
  isTemporary?: boolean;
}

const ImpersonateShortcut = ({
  student,
  enabled,
  programId,
  stafferId,
  isTemporary,
}: ExtendedImpersonateStudentProps) => {

  return (
    <ImpersonateStudent
      student={student}
      enabled={enabled && !isTemporary}
      programId={programId}
      stafferId={stafferId}
      buttonClass={styles.icon}
      iconClass={styles.shareIcon}
      tooltipPosition="bottom"
      wrapperClass=""
    />
  );
};

export default ImpersonateShortcut;
