import React, { useState } from 'react';
import I18n from 'modules/i18n';
import styles from './ImpersonateStudent.module.scss';
import SignInAsStudent from './SignInAsStudent';
import SignInButton from './SignInButton';
import { StudentType } from './types';

interface ModalBodyProps {
  student?: StudentType
  close: () => void;
  programId?: number;
  stafferId?: number;
}

const ModalBody = ({ student, close, programId, stafferId }: ModalBodyProps) => {
  const initialStudent = student ? { label: student.name, value: student.id } : null;
  const [selectedStudent, setSelectedStudent] = useState(initialStudent);
  const signInText = student ? I18n.t('impersonate_text', { name: student.name }) : I18n.t('impersonate_text_no_student');
  const signInWarning = student ? I18n.t('impersonate_warning', { name: student.name }) : I18n.t('impersonate_warning_no_student');

  const handleSelect = (_selectedStudent) => {
    setSelectedStudent(_selectedStudent);
  };

  return (
    <div role="dialog" id="impersonateStudentModal" aria-modal="true" aria-labelledby="Sign in as a Student">
      <p>{signInText}</p>
      <br />
      <p><i className={`fa fa-exclamation-triangle ${styles.warningIcon}`} /><strong>WARNING:</strong></p>
      <br />
      <p dangerouslySetInnerHTML={{ __html: signInWarning }} />

      {!student
        && (
          <SignInAsStudent
            handleSelect={handleSelect}
            selectedStudent={selectedStudent}
            programId={programId}
            stafferId={stafferId}
          />
        )}

      <hr />
      <footer>
        <button
          className="btn btn--link-purple"
          onClick={close}
          type="button"
        >
          Cancel
        </button>

        <SignInButton student={selectedStudent} />
      </footer>
    </div>
  );
};

export default ModalBody;
