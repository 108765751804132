import { useCallback, useState } from 'react';
import axios from 'axios';

const useAsyncFileDownload = (url: string, fileNameWithExtension: string = 'file.pdf') => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(() => {
    setIsLoading(true);

    return axios.get(url, { responseType: 'blob' })
      .then((res) => {
        const href = URL.createObjectURL(res.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileNameWithExtension);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => setIsLoading(false));
  }, [url, fileNameWithExtension]);

  return { isLoading, onClick };
};

export default useAsyncFileDownload;
