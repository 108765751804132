import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';

export default class Edit extends Component {
  static propTypes = {
    openAssignmentModal: PropTypes.func.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  };

  static defaultProps = {
    rowData: null
  };

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.props.openAssignmentModal(false, this.props.rowData);
  }

  render() {
    return (
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={this.openModal}
      >
        <i className="fa fa-edit" aria-hidden="true" /> {I18n.t('edit')}
      </a>
    );
  }
}
