/* eslint-disable no-param-reassign */

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import ReactDOM from 'react-dom';
import { useDebounceCallback } from 'usehooks-ts';
import DropdownMenu from 'components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from 'components/common/Dropdown';
import { UPDATE_SLIDE_OBJECT } from '@/components/admin/SlideShow/Utils/slideShowGraphQL';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from '../../Content/SlideObjectText/AnchoredDropdown/AnchoredDropdown.module.scss';
import contentActionStyles from '../ContentActions.module.scss';
import { convertToHex } from '../../Utils';
import usePopover from '../../hooks/usePopover';
import { PopoverProps, PopoverData } from '../types';
import ColorPicker from '../EditBackgroundPopover/ColorPicker';
import BorderEditor from './BorderEditor';
import { initialWidth, initialColor, initialBorderRadius, initialOpacity } from './initialBorderValues';

const BorderPopover = ({ anchorId, openerClass, slideObject, styleDivRef }: PopoverProps) => {
  const [updateSlideObjectMutation] = useMutation(UPDATE_SLIDE_OBJECT);

  const divToStyle = () => {
    if (!styleDivRef) return null;

    return styleDivRef && styleDivRef.current;
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [width, setWidth] = useState<string>(initialWidth(slideObject));
  const [color, setColor] = useState<string>(initialColor(slideObject));
  const [borderRadius, setBorderRadius] = useState<string>(initialBorderRadius(slideObject));
  const [opacity, setOpacity] = useState<string>(initialOpacity(slideObject));
  const updateSlideObjectInContext = useSlideShowContext(state => state.updateSlideObject);

  const ref = useRef<HTMLDivElement>();
  const dropdownEvents = useDropdownEvents(ref);

  const resetSlideBorder = () => {
    setWidth(initialWidth(slideObject));
    setColor(initialColor(slideObject));
    setBorderRadius(initialBorderRadius(slideObject));
    setOpacity(initialOpacity(slideObject));
  };

  // on slideObject change, sync border
  useEffect(() => {
    if (!divToStyle()) return;

    resetSlideBorder();
  }, [slideObject.id, slideObject.backgroundColor]);

  const afterToggle = () => {
    if (!divToStyle()) return;

    resetSlideBorder();

    if (slideObject.borderWidth) {
      divToStyle().style.borderWidth = `${slideObject.borderWidth}px`;
    } else {
      divToStyle().style.borderWidth = '';
    }

    if (slideObject.borderRadius) {
      divToStyle().style.borderRadius = `${slideObject.borderRadius}px`;
    } else {
      divToStyle().style.borderRadius = '';
    }

    if (slideObject.borderColor || slideObject.borderOpacity) {
      divToStyle().style.borderColor = `${slideObject.borderColor}${slideObject.borderOpacity}`;
    } else {
      divToStyle().style.borderColor = '';
    }
  };

  const { coordinates, setIsUpdated } = usePopover({ afterToggle, anchorId, dropdownEvents, openerClass, ref });

  const updateSlideObject = (data: PopoverData) => {
    const mutationParams = {
      variables: {
        borderColor: data.color || color,
        borderRadius: data.borderRadius || borderRadius,
        borderWidth: data.width || width,
        borderOpacity: convertToHex(data.opacity || opacity),
        slideObjectId: slideObject.id,
      },
    };

    return updateSlideObjectMutation(mutationParams)
      .then((res) => {
        updateSlideObjectInContext(res.data.updateSlideObject.slideObject);
      });
  };

  const handleSubmit = (data: PopoverData, closePopover = true): Promise<any> => {
    setIsUpdated(true);

    if (closePopover) dropdownEvents.setOpen(false);

    return updateSlideObject(data);
  };

  const debouncedSubmit = useCallback(useDebounceCallback(handleSubmit, 300), []);

  const handleChange = async (data: PopoverData) => {
    if (data.width) {
      setWidth(data.width);
      divToStyle().style.borderWidth = `${data.width}px`;
    }

    if (data.borderRadius) {
      setBorderRadius(data.borderRadius);
      divToStyle().style.borderRadius = `${data.borderRadius}px`;
    }

    if (data.color || data.opacity) {
      setColor(data.color);
      setOpacity(data.opacity);
      divToStyle().style.borderColor = `${data.color}${convertToHex(data.opacity)}`;
    }

    await debouncedSubmit(data, false);
  };

  return ReactDOM.createPortal(
    <DropdownMenu open={dropdownEvents.open} className={styles.margin} coordinates={coordinates} ref={ref}>
      {dropdownEvents.open && (
        <>
          <ColorPicker
            activeColor={color}
            activeOpacity={opacity}
            onChange={handleChange}
          />
          <BorderEditor
            activeBorderRadius={borderRadius}
            activeWidth={width}
            onChange={handleChange}
          />
          <div className={contentActionStyles.borderSubmitWrapper}>
            <button
              className={contentActionStyles.submitBtn}
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setIsSubmitting(true);
                handleSubmit({ color, opacity })
                  .then(() => {
                    setIsSubmitting(false);
                  });
              }}
            >
              OK
            </button>
          </div>
        </>
      )}
    </DropdownMenu>,
    document.body,
  );
};

export default BorderPopover;
