import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/common/Tooltip';
import slideConditionIcon from 'images/icons/conditional-slide-rules.svg';

interface SlideConditionsIconProps {
  iconClassName?: string;
  onClick?: () => void;
  tooltipPlacement?: string;
  tooltipContent: string;
};

const SlideConditionsIcon = ({ iconClassName, onClick, tooltipContent, tooltipPlacement }: SlideConditionsIconProps) => (
  <Tooltip
    content={tooltipContent}
    placement={tooltipPlacement}
  >
    <img
      width="20px"
      height="20px"
      alt=""
      className={`tw-mt-2 ${iconClassName}`}
      onClick={onClick}
      src={slideConditionIcon}
    />
  </Tooltip>
);

SlideConditionsIcon.propTypes = {
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
};

SlideConditionsIcon.defaultProps = {
  tooltipPlacement: 'bottom',
};

export default SlideConditionsIcon;
