import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { QueryClientProvider, useMutation } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import { useModalManager } from 'components/common/Modal';
import AddImage from '@/components/admin/ImageManager/Add';
import EditImage from '@/components/admin/ImageManager/Edit';
import RemoveImage from '@/components/admin/ImageManager/Remove';
import useImageModel from '@/hooks/api/useImageModel';
import { useExcludedFields } from '@/components/admin/QuestionImage/utils';
import apolloClient, { ApolloProvider } from '@/components/common/ApolloClient';
import { plato_api_question_path } from '@/modules/routes';
import useQuestionLocaleToggleListener from '@/hooks/useQuestionLocaleToggleListener';
import { graphQlValidModel } from '@/components/common/Utils';
import QueryKeys from '@/utils/QueryKeys';
import styles from './index.module.scss';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';

type ImageModel = {
  id: string;
  captionEn?: string;
  captionEs?: string;
  decorative: boolean;
  displaySize?: string;
  mediaMeta: {
    type: string;
  }
  modelId: number;
  modelType: string;
  zoomType?: string;
  image: {
    credits?: string;
    descriptionConfidence?: string;
    descriptionEn?: string;
    descriptionEs?: string;
    englishImageOnly?: string;
    fileName: string;
    fileNameEs?: string;
    hasText?: string;
    imageUrl: string;
    imageEsUrl: string;
    titleEn?: string;
    titleEs?: string;
  };
};

interface Props {
  checkboxContainerId: string;
  checkboxId: string;
  choiceId: string;
  imageChoiceContainerId: string;
  imageModel: ImageModel | null;
  questionId: number;
  userType: UserType;
}

const MEDIA_META = { alignment: 'below', type: 'choice' };

const ImageRender = ({ imageUrl }: { imageUrl?: string }) => {
  if (!imageUrl) {
    return <i aria-hidden="true" className="fa fa-file-image-o tw-mt-[20px] tw-text-[170px] tw-text-[#e4e4e4]" />;
  }

  return (
    <div className="tw-relative tw-w-[300px] tw-h-[300px] tw-overflow-hidden tw-flex tw-items-center tw-justify-center">
      <img src={imageUrl} alt="" className="tw-object-contain tw-max-w-full tw-max-h-full tw-w-auto tw-h-auto" />
    </div>
  );
};

const ImageChoiceImageModelCardBody = ({
  checkboxContainerId,
  checkboxId,
  choiceId,
  imageChoiceContainerId,
  imageModel: initialImageModel,
  questionId,
  userType,
}: Props) => {
  const [id, setId] = useState<null | string>(initialImageModel?.id);
  const modalManager = useModalManager();
  const excludeFields = useExcludedFields(userType);

  const { isInSpanish } = useQuestionLocaleToggleListener({ modelId: questionId });

  const [imageUrl, setImageUrl] = useState(initialImageModel?.image?.imageUrl);
  const [imageEsUrl, setImageEsUrl] = useState(initialImageModel?.image?.imageEsUrl);

  const { data, refetch } = useImageModel({ id });

  const imageModel = data ? graphQlValidModel(data) : null;

  const url = useMemo(() => {
    if (isInSpanish && imageEsUrl) return imageEsUrl;

    return imageUrl;
  }, [imageUrl, imageEsUrl, isInSpanish]);

  const { mutate: imageModelMutation } = useMutation({
    mutationFn: ({ imageModelId }: { imageModelId?: string }) => (
      axios
        .patch(plato_api_question_path(questionId, {
          image_model_choice_id: choiceId,
          image_model_id: imageModelId,
        }))
        .then(() => $(imageChoiceContainerId)[0].dispatchEvent(new CustomEvent('validate_image_choice')))
    ),
  });

  const afterSubmit = (res: GraphQlImageModel | undefined) => {
    if (!res) {
      refetch();
      return;
    }

    const esUrl = res.image.imageEsUrl.includes('missing') ? null : res.image.imageEsUrl;

    setImageEsUrl(esUrl);
    setImageUrl(res.image.imageUrl);
    imageModelMutation({ imageModelId: res.id });
    if (!id) setId(res.id);
    refetch();
  };

  const afterMergeDuplicates = (res: GraphQlImageModel[]) => {
    const imageModelsForQuestion = res
      .filter(im => im.modelId === questionId && im.modelType === 'Question' && im.mediaMeta.type === 'choice')
      .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));

    afterSubmit(imageModelsForQuestion[0]);
  };

  const afterDelete = () => {
    setImageEsUrl(null);
    setImageUrl(null);
    imageModelMutation({});
    setId(null);
    queryClient.removeQueries({ queryKey: [QueryKeys.ImageModels, id] });
  };

  const hasImage = () => (imageUrl !== null && imageUrl !== undefined);

  const toggleCheckboxContainer = () => {
    if (hasImage()) $(checkboxContainerId).show();
    else {
      $(checkboxId).prop('checked', false);
      $(checkboxContainerId).hide();
    }
  };

  const imageModelForEditForm = () => {
    if (!imageModel) return null;

    const { image } = imageModel;

    return {
      id: imageModel.id,
      captionEn: imageModel.captionEn,
      captionEs: imageModel.captionEs,
      decorative: imageModel.decorative,
      displaySize: imageModel.displaySize,
      image_credits: image.credits,
      image_descriptionConfidence: parseFloat(image.descriptionConfidence),
      image_descriptionEn: image.descriptionEn,
      image_descriptionEs: image.descriptionEs,
      image_fileName: image.fileName,
      image_fileNameEs: image.imageEsFileName,
      image_titleEn: image.titleEn,
      image_titleEs: image.titleEs,
      zoomType: imageModel.zoomType,
      image: {
        englishImageOnly: image.englishImageOnly,
        hasText: image.hasText,
        imageUrl: image.imageUrl,
        imageEsUrl: image.imageEsUrl,
      },
    };
  };

  useEffect(toggleCheckboxContainer, [imageUrl]);

  return (
    // @ts-ignore
    <ApolloProvider client={apolloClient}>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-h-full">
        <span className="tw-mb-[5px] tw-font-[500] tw-text-[18px] tw-self-start">{`${choiceId}.`}</span>
        <ImageRender imageUrl={url} />
        {!imageModel && (
          <AddImage
            afterMergeDuplicates={afterMergeDuplicates}
            afterSubmit={afterSubmit}
            buttonClass="!tw-mb-[5px] tw-mt-auto"
            buttonText="Add Image"
            headerText="Add Image"
            isSmallBtn={false}
            dontMergeCallback={afterSubmit}
            excludeFields={excludeFields}
            mediaMeta={MEDIA_META}
            modelType="Question"
            modelId={questionId}
            modalManager={modalManager}
          />
        )}

        {imageModel && (
          <div className={styles.modalsWrapper}>
            <EditImage
              afterMergeDuplicates={afterMergeDuplicates}
              afterSubmit={afterSubmit}
              btnClasses="btn--med btn--green mr10"
              useButtonIcon={false}
              dontMergeCallback={afterSubmit}
              excludeFields={excludeFields}
              imageModel={imageModelForEditForm()}
              modelType="Question"
              fromSlideShow
              isAdmin={userType === 'Admin' || userType === 'Sysadmin' || userType === 'ContentManager'}
              modelId={questionId}
            />
            <RemoveImage
              buttonClass="btn btn--red tw-mt-auto tw-mb-[5px]"
              buttonText="Delete Image"
              useIcon={false}
              imageModelId={imageModel.id}
              fileName={imageModel.image.fileName}
              afterSubmit={afterDelete}
              modalManager={modalManager}
              headerText={`Delete Image Choice - ${choiceId}`}
              standaloneButton
            />
          </div>
        )}
      </div>
    </ApolloProvider>
  );
};

const Wrapper = (props: Props) => (
  <QueryClientProvider client={queryClient}>
    <ImageChoiceImageModelCardBody {...props} />
  </QueryClientProvider>
);

export default Wrapper;
