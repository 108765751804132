import { UseThreeJsOnSuccess } from '@/hooks/useThreeJs/useThreeJs';
import React, { memo } from 'react';
import ThreeDModelUploadModal from './ThreeDModelUploadModal';
import ThreeDModelViewer from './ThreeDModelViewer';
import useThreeDModel from './useThreeDModel';
import { CurrentValues } from './utils';

const MemoizedThreeDModelViewer = memo(ThreeDModelViewer);

interface SlideObject3DModelProps {
  isSaving: boolean;
  interactable: boolean;
  modalManager: ModalManager;
  onSuccess: UseThreeJsOnSuccess;
  slideObject: {
    id: string;
    threeDModels: {
      id: string;
      fileName: string;
      modelUrl: string;
      textures: {
        id: string;
        imageUrl: string;
        imageModels: {
          id: string;
          textureType: string;
        }[];
      }[];
    }[];
  };
}

const SlideObject3DModel = ({
  interactable,
  modalManager,
  onSuccess,
  slideObject,
}: SlideObject3DModelProps) => {
  const { data: threeDModel } = useThreeDModel({ slideObjectId: slideObject.id });

  if (modalManager.isOpen && interactable) {
    const currentValues = {} as CurrentValues;

    if (threeDModel) {
      currentValues.id = threeDModel.id.toString();
      currentValues.fileName = threeDModel.file_name;
      currentValues.textures = threeDModel.image_models.map((im) => {
        const texture = threeDModel.textures.find(t => t.id === im.image_id);

        return {
          id: texture.id.toString(),
          imageUrl: texture.url,
          textureType: im.media_meta.texture_type,
          imageModelId: im.id.toString(),
        };
      });
    }

    return (
      <ThreeDModelUploadModal
        currentValues={currentValues}
        modalManager={modalManager}
        slideObjectId={slideObject.id}
      />
    );
  }

  if (!threeDModel) return null;

  return <MemoizedThreeDModelViewer onSuccess={onSuccess} threeDModel={slideObject.threeDModels[0]} />;
};

export default SlideObject3DModel;
