import { useQuery } from '@tanstack/react-query';
import * as Routes from 'modules/routes';
import axios from 'axios';
import { IProgram, ISubscriber } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';

const usePrograms = (subscriber: ISubscriber) => {
  const path = Routes.plato_api_programs_path({
    available_to_subscriber: subscriber.id,
    extra_attributes: ['license', 'small_url', 'licenses', 'recommended_for_states'],
    includes: ['states', 'license'],
    only: ['edition', 'full_title_with_edition', 'id', 'license_id', 'retirement_year', 'subtitle', 'title', 'code'],
  });

  const query = useQuery(['programs', subscriber.id], () => (
    axios
      .get<{ data: IProgram[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err))
  ), { staleTime: Infinity });

  return { ...query, data: query.data || [] };
};

export default usePrograms;
