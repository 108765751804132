import React from 'react';
import AddImage from '@/components/admin/ImageManager/Add';
import { useModalManager } from 'components/common/Modal';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import { useExcludedFields } from '@/components/admin/QuestionImage/utils';

interface AddProps {
  afterSubmit: (imageModel: object) => any;
  buttonText?: 'Add Image' | 'Add GIF' | 'Add Answer Image',
  forAnswerKey?: boolean;
  mediaType: string;
  modelId: number;
  modelType: 'Question' | 'QuestionGroup';
  userType: UserType;
}

const Add = ({
  afterSubmit,
  buttonText = 'Add Image',
  forAnswerKey = false,
  mediaType,
  modelId,
  modelType,
  userType,
}: AddProps) => {
  const modalManager = useModalManager();
  const excludeFields = useExcludedFields(userType);
  const mediaMeta = forAnswerKey ? { type: 'answer' } : {};

  return (
    <AddImage
      afterMergeDuplicates={afterSubmit}
      afterSubmit={afterSubmit}
      buttonClass="!tw-mb-0"
      buttonText={buttonText}
      headerText={buttonText}
      dontMergeCallback={afterSubmit}
      excludeFields={excludeFields}
      isAdmin={userType === 'Admin' || userType === 'Sysadmin' || userType === 'ContentManager'}
      fromSlideShow
      mediaMeta={mediaMeta}
      modelType={modelType}
      modelId={modelId}
      modalManager={modalManager}
      mediaType={mediaType}
    />
  );
};

const QuestionImageAddWrapper = (props: AddProps) => (
  <ApolloProvider client={apolloClient}>
    <Add {...props} />
  </ApolloProvider>
);

export default QuestionImageAddWrapper;
