import { QueryClient } from '@tanstack/react-query';
import ILicense from '@/components/interfaces/License';

export interface IProgram {
  full_title_with_edition: string;
  id: number;
  retirement_year: number;
  license_id: number;
  code: string;
  title: string;
  subtitle: string;
  edition: string;
  small_url: string;
  recommended_for_states: { [key : number] : Array<number> };
  license: ILicense;
  licenses: ILicense[];
}

export interface ISeatPool {
  active: boolean;
  exclude_from_rostering: boolean;
  exclusion_setting: string;
  grade: string;
  id: number;
  license_id: number;
  number_of_teachers: number;
  number_of_students: number;
  program: IProgram;
  license?: ILicense;
  program_id: number;
  subscriber_id: number;
  under_review: boolean;
}

export interface ISubscriber {
  id: number;
  name: string;
  state_id?: number;
}

export type IUserType = 'Coordinator' | 'Admin' | 'Sysadmin';

export const COLUMNS = ['License', 'Grade', 'Program', 'Code', 'number_of_teachers', 'number_of_students', 'Actions'];

export const getColumns = (userType: IUserType) => {
  const baseColumns = ['License', 'Grade', 'Program', 'Code', 'number_of_teachers', 'number_of_students'];

  if (userType === 'Coordinator') return baseColumns;

  return [...baseColumns, 'Actions'];
};

export const programAccessQueryClient = new QueryClient();

const currentYear = new Date().getFullYear();

export const expiringYear = new Date(currentYear, 5, 30) > new Date() ? currentYear : currentYear + 1;

const GRADE_ORDER = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', null, undefined];

export const seatPoolOrder = (s1: ISeatPool, s2: ISeatPool) => {
  const l1Position = s1.program.licenses.find(license => license.id === s1.license_id).position;
  const l2Position = s2.program.licenses.find(license => license.id === s2.license_id).position;

  const diff = l1Position - l2Position;
  if (diff) return diff;

  const i1 = GRADE_ORDER.indexOf(s1.grade);
  const i2 = GRADE_ORDER.indexOf(s2.grade);

  if (i1 < i2) return -1;
  if (i1 > i2) return 1;

  const p1Title = `${s1.program.title} ${s1.program.subtitle}`;
  const p2Title = `${s2.program.title} ${s2.program.subtitle}`;

  return p1Title.localeCompare(p2Title);
};
