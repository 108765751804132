import React from 'react';
import { Field } from 'react-final-form';
import { required, minLength, composeValidators, validEmail } from '@/components/common/Forms/Validators';
import Tooltip from '@/components/common/Tooltip';
import AllowMultipleSessionsField from '@/components/admin/FormFields/AllowMultipleSessionsField';
import Select from '@/components/common/ReactSelect5';
import styles from '../StaffForm.module.scss';

const FirstNameField = () => (
  <Field
    name="first_name"
    validate={required}
  >
    {({ input, meta }) => (
      <div className={styles.row}>
        <div className={styles.fieldContainer}>
          <label htmlFor="staff-first-name" className={styles.label}>
            First Name
            <span className={styles.red}>*</span>
          </label>

          <input
            id="staff-first-name"
            className={styles.textInput}
            {...input}
          />
        </div>

        {meta.error && meta.touched && <div className={styles.fieldError}>{meta.error}</div>}
      </div>
    )}
  </Field>
);

const LastNameField = () => (
  <Field
    name="last_name"
    validate={required}
  >
    {({ input, meta }) => (
      <div className={styles.row}>
        <div className={styles.fieldContainer}>
          <label htmlFor="staff-last-name" className={styles.label}>
            Last Name
            <span className={styles.red}>*</span>
          </label>

          <input
            id="staff-last-name"
            className={styles.textInput}
            {...input}
          />
        </div>

        {meta.error && meta.touched && <div className={styles.fieldError}>{meta.error}</div>}
      </div>
    )}
  </Field>
);

const EmailField = () => (
  <Field
    name="email"
    validate={composeValidators(required, validEmail)}
  >
    {({ input, meta }) => (
      <div className={styles.row}>
        <div className={styles.fieldContainer}>
          <label htmlFor="staff-email" className={styles.label}>
            Email
            <span className={styles.red}>*</span>
          </label>

          <input
            id="staff-email"
            className={styles.textInput}
            type="email"
            {...input}
          />
        </div>

        {meta.error && meta.touched && <div className={styles.fieldError}>{meta.error}</div>}
      </div>
    )}
  </Field>
);

const UsernameField = () => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor="staff-username" className={styles.label}>
        Username
      </label>
      <Field
        className={styles.textInput}
        component="input"
        id="staff-username"
        name="username"
        parse={val => (val === undefined ? '' : val)}
      />
    </div>
  </div>
);

interface PasswordFieldProps {
  newRecord?: boolean;
  userIsSysadmin: boolean;
}

type PasswordLabel = string | JSX.Element;

const PasswordField = ({ newRecord, userIsSysadmin }: PasswordFieldProps) => {
  let labelText: PasswordLabel = 'New Password';
  if (newRecord) {
    labelText = (
      <span>
        Password
        <span className={styles.red}>*</span>
      </span>
    );
  }

  const requirePasswordReset = (
    <div className={`${styles.fieldContainer} tw-mt-2`}>
      <div className={styles.label} />
      <Field
        id="require-password-reset"
        name="require_password_reset"
        component="input"
        type="checkbox"
      />
      <label htmlFor="require-password-reset" className={styles.checkboxLabel}>
        Require a password change at the next sign-in
      </label>
    </div>
  );

  return (
    <Field
      name="password"
      validate={
        newRecord ? composeValidators(minLength(6), required) : minLength(6)
      }
    >
      {({ input, meta }) => (
        <div className={styles.row}>
          <div className={styles.fieldContainer}>
            <label htmlFor="staff-password" className={styles.label}>
              {labelText}
            </label>
            <input
              autoComplete="new-password"
              id="staff-password"
              className={styles.textInput}
              type="password"
              {...input}
            />
          </div>

          {userIsSysadmin && requirePasswordReset}

          {
            meta.error && meta.touched
            && <div className={styles.fieldError}>{meta.error}</div>
          }
        </div>
      )}
    </Field>
  );
};

const ExcludeFromRosteringTooltipText = () => {
  const url = 'https://www.teachtci.com/exclude-teacher';

  return (
    <span>
      Excluding a teacher will leave the teacher&apos;s classes
      (including student rosters) unmodified during a roster sync. See&nbsp;
      <a href={url} target="_blank" rel="noreferrer noopener">
        How to exclude a teacher from auto-rostering
      </a>
      &nbsp;for more details.
    </span>
  );
};

const ExcludeFromRosteringField = () => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor="staff-exclude-from-rostering" className={styles.label}>
        Exclude from auto-rostering&nbsp;
        <Tooltip content={<ExcludeFromRosteringTooltipText />} theme="white">
          <div className="inline_block">
            <i className="fa fa-question-circle" aria-hidden="true" />
            <span className="sr-only">More info</span>
          </div>
        </Tooltip>
      </label>

      <Field
        component="input"
        id="staff-exclude-from-rostering"
        name="exclude_from_rostering"
        type="checkbox"
        parse={val => (val === true ? 'true' : false)}
      />
    </div>
  </div>
);

const AccessTypeField = () => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <span className={styles.label}>Access Type</span>
      <label className={styles.radioLabel}>
        <Field
          className={styles.radioInput}
          name="type"
          component="input"
          type="radio"
          value="Sysadmin"
        />
        {' '}
        Full Access
      </label>

      <label className={styles.radioLabel}>
        <Field
          className={styles.radioInput}
          name="type"
          component="input"
          type="radio"
          value="SupportManager"
        />
        {' '}
        Support Only
      </label>

      <label className={styles.radioLabel}>
        <Field
          className={styles.radioInput}
          name="type"
          component="input"
          type="radio"
          value="ContentManager"
        />
        {' '}
        Content Only
      </label>
    </div>
  </div>
);

const RoleField = ({ form, isTeacher }) => {
  const includeInContactPopup = (
    <div className={`${styles.fieldContainer} tw-mt-2`}>
      <div className={styles.label} />
      <Field
        component="input"
        id="staff-include-as-contact-admin"
        name="include_as_contact_admin"
        type="checkbox"
      />
      <label htmlFor="staff-include-as-contact-admin" className="tw-ml-1">
        Include in Contact Admin popup&nbsp;
        <Tooltip
          content="Unchecking this field will hide this admin as a contact option for teachers."
          interactive={false}
          theme="white"
        >
          <div className="inline_block">
            <i className="fa fa-question-circle" aria-hidden="true" />
            <span className="sr-only">More info</span>
          </div>
        </Tooltip>
      </label>
    </div>
  );

  return (
    <div className={styles.row}>
      <div className={styles.fieldContainer}>
        <span className={styles.label}>Role</span>

        <label className={styles.radioLabel}>
          <Field
            className={styles.radioInput}
            name="type"
            component="input"
            type="radio"
            value="Teacher"
            onClick={form.mutators.handleSelectRoleField}
          />{' '}
          Teacher
        </label>

        <label className={styles.radioLabel}>
          <Field
            className={styles.radioInput}
            name="type"
            component="input"
            type="radio"
            value="Coordinator"
            onClick={form.mutators.handleSelectRoleField}
          />{' '}
          Coordinator
        </label>

        <label className={styles.radioLabel}>
          <Field
            className={styles.radioInput}
            name="type"
            component="input"
            type="radio"
            value="Admin"
            onClick={form.mutators.handleSelectRoleField}
          />{' '}
          Admin
        </label>
      </div>
      { !isTeacher && includeInContactPopup }
    </div>
  );
};

const UIDField = () => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor="staff-uid" className={styles.label}>
        UID
      </label>
      <Field
        className={styles.textInput}
        component="input"
        id="staff-uid"
        name="uid"
      />
    </div>
  </div>
);

const ReactSelectAdapter = ({ input, ...rest }) => (
  <Select
    {...input}
    {...rest}
  />
);

const ContactType = ({ contactTypeOptions }) => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor="contact_types" className={styles.label}>
        Contact Type
        {' '}
        <Tooltip
          content="Designate this staffer as a point of contact for tech integration or curriculum-related queries."
          interactive={false}
          theme="white"
        >
          <div className="inline_block">
            <i className="fa fa-question-circle" aria-hidden="true" />
            <span className="sr-only">More info</span>
          </div>
        </Tooltip>
      </label>
      <Field
        className="tw-w-[330px]"
        component={ReactSelectAdapter}
        id="contact_types"
        isMulti
        name="contact_types"
        options={contactTypeOptions}
        placeholder="Select Contact Type"
      />
    </div>
  </div>
);


const Fields = {
  AccessTypeField,
  AllowMultipleSessionsField,
  ContactType,
  EmailField,
  ExcludeFromRosteringField,
  FirstNameField,
  LastNameField,
  PasswordField,
  RoleField,
  UIDField,
  UsernameField,
};

export default Fields;
