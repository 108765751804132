import React, { useMemo } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import I18n from 'i18n-js';
import TextToSpeechButton from '@/components/shared/TextToSpeechButton';
import cn from '@/utils/cn';

interface InstructionsProps {
  instructions: string;
}

const ttsId = 'instructions-text-to-speech';
const collapsedHeight = '24px';

const Instructions = ({ instructions }: InstructionsProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Collapsible.Root
        className="tw-w-full tw-bg-white tw-absolute tw-flex tw-items-center tw-justify-between"
        open={open}
        onOpenChange={setOpen}
      >
        <div
          id={ttsId}
          className={cn('tw-text-base tw-leading-5 tw-mx-4 tw-break-words', {
            'tw-overflow-hidden': !open,
            [`tw-h-[${collapsedHeight}]`]: !open,
            'tw-overflow-y-auto': open,
            'tw-mb-2': open,
          })}
          dangerouslySetInnerHTML={{ __html: instructions }}
        />

        <div className="tw-flex tw-flex-col tw-self-start">
          <Collapsible.Trigger asChild>
            <button
              type="button"
              className="btn btn--link-purple tw-p-0 tw-mr-4"
            >
              {open ? I18n.t('see_less') : I18n.t('see_more')}
              <i className={`fa ${open ? 'fa-caret-up' : 'fa-caret-down'} ml5`} aria-hidden/>
            </button>
          </Collapsible.Trigger>

          {open && (
            <div className="tw-self-end tw-pr-4 tw-pt-2">
              <TextToSpeechButton
                locale={I18n.locale === 'es' ? 'es' : 'en'}
                idToRead={ttsId}
              />
            </div>
          )}
        </div>
      </Collapsible.Root>
      <div className={`tw-h-[${collapsedHeight}]`} />
    </>
  );
};

export default Instructions;
