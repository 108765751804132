import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_slide_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';
import { ISlide } from '../../SlideShow/stores/useSlideShowStore';

const useTeacherNotes = (slide: ISlide) => (
  useQuery({
    placeholderData: (slide.notes || ''),
    queryFn: () => (
      axios
        .get(plato_api_slide_path(slide.id))
        .then(res => res.data.notes || '')
    ),
    queryKey: [QueryKeys.UseTeacherNotes, slide.id],
  })
);

export default useTeacherNotes;
