import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as Routes from 'routes';

const ToggleColumnButton = ({
  seatPoolId, initialValue, name, toggleClass, valueName
}) => {
  const [isValueOn, setValue] = useState(initialValue);

  const toggleValue = (e) => {
    e.preventDefault();

    Axios
      .patch(Routes.plato_api_seat_pool_path(seatPoolId), { seat_pool: { [valueName]: !isValueOn } })
      .then((response) => {
        if (!response.data) return;

        // Skip updating if value wasn't toggled:
        if (response.data.data[valueName] === isValueOn) {
          return;
        }

        setValue(!isValueOn);

        if (toggleClass) {
          const row = document.getElementById(`seat_pool_${seatPoolId}`);
          if (row) row.classList.toggle(toggleClass);
        }
      });
  };

  return (
    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={toggleValue}
    >
      Turn {isValueOn ? 'off' : 'on'} {name}
    </a>
  );
};

ToggleColumnButton.propTypes = {
  initialValue: PropTypes.bool,
  name: PropTypes.string.isRequired,
  seatPoolId: PropTypes.number.isRequired,
  toggleClass: PropTypes.string,
  valueName: PropTypes.string.isRequired
};

ToggleColumnButton.defaultProps = {
  initialValue: false,
  toggleClass: null
};

export default ToggleColumnButton;
