import React from 'react';
import Row from './Row';
import FormCheckbox from './FormCheckboxGroupBox';

export default class FormCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <Row>
        <label>
          {this.props.labelText}
        </label>
        <div>
          {this.renderCheckboxes()}
        </div>
      </Row>
    )
  }

  renderCheckboxes() {
    return this.props.checkboxes.map((checkbox, i) => {
      return <FormCheckbox key={i}
                           checked={checkbox.checked}
                           value={checkbox.checkValue}
                           onChange={this.props.onChange.bind(this)}
                           checkLabel={checkbox.checkText} />
    })
  }
}