import React from 'react';

import { VideoLibrary } from '@/components/common/libraries';

type VideoProps = {
  programId: number;
};

const Videos = ({ programId }: VideoProps) => (
  <VideoLibrary programId={programId} />
);

export default Videos;
