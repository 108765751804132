import React, { useEffect, useRef } from 'react';
import Tooltip from '@/components/common/Tooltip';
import { useModalManager } from '@/components/common/Modal';
import useProfessionalDevelopmentResults
  from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentResults';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import restartIcon from '../../../../../../../../../images/icons/restart-icon-purple.svg';
import styles from '../../../Navigator.module.scss';
import ConfirmResetModal from './ConfirmResetModal';

const ResetSlideShowButton = () => {
  const appendEl = useRef();
  const slideRef = useSlideShowContext(state => state.slideRef);
  const activityType = useSlideShowContext(state => state.activityType);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const programId = useSlideShowContext(state => state.programId);
  const modalManager = useModalManager();

  const { data, refetch, isFetching, isRefetching } = useProfessionalDevelopmentResults({
    slideShowId,
    programId,
    isEnabled: activityType === 'professional_development',
  });

  useEffect(() => {
    if (activityType !== 'professional_development') return undefined;

    const interval = setInterval(() => refetch(), 5000);

    return () => clearInterval(interval);
  }, []);

  const isHidden = activityType === 'professional_development' && data?.slideShow?.pdStatus === 'not_started';

  if (isFetching && !isRefetching) return null;
  if (isHidden) return null;

  return (
    <>
      <div
        className={`${styles.dropdownItem} ${styles.flexSpaceBetween}`}
        ref={appendEl}
      >
        <div className="tw-flex">
          <div className="tw-mr-1">
            Restart Slideshow
          </div>
        </div>
        <div
          role="button"
          onClick={() => modalManager.open()}
          onKeyDown={() => modalManager.open()}
          className={styles.editButton}
          tabIndex={0}
        >
          <Tooltip
            appendTo={slideRef.current}
            content="Restart"
            interactive={false}
          >
            <img
              alt="restart icon"
              src={restartIcon}
              className="tw-p-2 tw-w-6"
            />
          </Tooltip>
        </div>
      </div>

      {modalManager.isOpen && (
        <ConfirmResetModal
          data={data}
          loading={isFetching}
          modalManager={modalManager}
          slideShowId={slideShowId}
          activityType={activityType}
        />
      )}
    </>
  );
};

export default ResetSlideShowButton;
