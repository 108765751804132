import React, { useState } from 'react';
import I18n from 'i18n';
import * as Routes from 'modules/routes';

import AssignmentsTable from 'components/shared/AssignmentsTable';
import DashboardCard from '../DashboardCard';
import styles from '../index.module.scss';

interface AssignmentCardProps {
  lastViewed: string;
  lessonId: number;
  program: {
    display_unit_content: boolean;
    id: number;
    science: boolean;
  };
  userId: number;
}

const AssignmentCard = ({ userId, lastViewed, program, lessonId }: AssignmentCardProps) => {
  const [hidden, setHidden] = useState(true);
  const [assignments, setAssignments] = useState([]);
  const maxAssignments = 100;
  const minAssignments = 3;

  const columns = () => {
    let cols = ['expand', 'assignment_label'];
    if (!userId) cols.push('classes');
    return cols.concat(['start_datetime', 'due_datetime']);
  }

  return (
    <DashboardCard
      bodyClassName={styles.assignmentsCard}
      borderColor="blue"
      label={I18n.t('assignments_to_do')}
      link={{
        label: I18n.t('view_all_assignments'),
        url: Routes.shared_program_assignments_path(program.id, { lesson_id: lessonId })
      }}
    >
      <AssignmentsTable
        columns={columns()}
        info={false}
        lastViewed={lastViewed}
        pageLength={hidden ? minAssignments : maxAssignments}
        program={program}
        setAssignments={setAssignments}
        showPaging={false}
        showPagingOptions={false}
        studentId={userId}
        userId={userId}
        hideButtons
        inDashboard
        defaultExpandRows
      />

      {assignments.length > 3 && (
        <div className="dsp-flex-horizontal-center">
          <button id="seeMoreButton" type="button" className="btn btn--link-purple" onClick={() => setHidden(!hidden)}>
            {hidden ? I18n.t('see_more') : I18n.t('see_less')}
            <i className={`fa ${hidden ? 'fa-caret-down' : 'fa-caret-up'} ml5`} aria-hidden />
          </button>
        </div>
      )}
    </DashboardCard>
  );
};

export default AssignmentCard;
