import React from 'react';
import PropTypes from 'prop-types';

import Wizard from 'common/Wizard';
import ClassesStep from '../ClassesStep';
import ConfigurationStep from '../ConfigurationStep';
import ContactStep from '../ContactStep';
import ProgressHeader from '../ProgressHeader';
import CompletionStep from '../CompletionStep';

export default function NonCsvSteps({
  autoRosterDistrict, subscriber, autoRosterDistrictPath, completedSteps, initialStep,
  subscriberUpdatePath, updateRosterDistrict, updateSubscriber, closeModal, firstPageBackCallback, mapCoursesPath,
  mappedCoursesSize, nextSyncTime, teacherSeatsLocked, userType, updateTeacherSeatsLocked,
}) {
  return (
    <Wizard
      progressHeader={progressHeader()}
      initialStep={initialStep}
      firstPageBackCallback={firstPageBackCallback}
      lastPageNextCallback={closeModal}
    >
      <ConfigurationStep
        subscriber={subscriber}
        autoRosterDistrict={autoRosterDistrict}
        autoRosterDistrictPath={autoRosterDistrictPath}
        subscriberUpdatePath={subscriberUpdatePath}
        autoRosterUpdatePath={autoRosterDistrictPath}
        updateSubscriber={updateSubscriber}
        updateRosterDistrict={updateRosterDistrict}
        completedSteps={completedSteps}
      />

      <ContactStep
        autoRosterDistrict={autoRosterDistrict}
        autoRosterDistrictPath={autoRosterDistrictPath}
        updateAutoRosterDistrict={updateRosterDistrict}
        completedSteps={completedSteps}
      />

      <ClassesStep
        autoRosterDistrict={autoRosterDistrict}
        closeModal={closeModal}
        mappedCoursesSize={mappedCoursesSize}
        mapCoursesPath={mapCoursesPath}
        nextSyncTime={nextSyncTime}
        teacherSeatsLocked={teacherSeatsLocked}
        updateTeacherSeatsLocked={updateTeacherSeatsLocked}
        subscriber={subscriber}
        userType={userType}
      />

      <CompletionStep
        autoRosterDistrict={autoRosterDistrict}
        closeModal={closeModal}
        nextSyncTime={nextSyncTime}
        completedSteps={completedSteps}
      />
    </Wizard>
  );

  function progressHeader() {
    return (
      <ProgressHeader
        completedSteps={completedSteps}
        isCsv={false}
      />
    );
  }
}

NonCsvSteps.propTypes = {
  autoRosterDistrict: PropTypes.shape({
    contact_email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    sync_type: PropTypes.string,
  }),
  autoRosterDistrictPath: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  completedSteps: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])).isRequired,
  firstPageBackCallback: PropTypes.func.isRequired,
  initialStep: PropTypes.number,
  mapCoursesPath: PropTypes.string.isRequired,
  mappedCoursesSize: PropTypes.number.isRequired,
  nextSyncTime: PropTypes.string,
  subscriber: PropTypes.shape({
    id: PropTypes.number
  }),
  subscriberUpdatePath: PropTypes.string.isRequired,
  teacherSeatsLocked: PropTypes.bool,
  updateRosterDistrict: PropTypes.func.isRequired,
  updateSubscriber: PropTypes.func.isRequired,
  updateTeacherSeatsLocked: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

NonCsvSteps.defaultProps = {
  autoRosterDistrict: null,
  initialStep: null,
  nextSyncTime: null,
  subscriber: null,
  teacherSeatsLocked: false
};
