import React, { Component } from 'react';
import Axios from "axios/index";
import { DeleteButton } from '../materials/Utils';
import Modal from 'common/Modal';
import Form from './Form';

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const { title, selectionType, zoomable } = this.state;

    return (
      <tr>
        <td>{title}</td>
        <td>{selectionType}</td>
        <td>{zoomable === 't' ? 'Yes' : 'No'}</td>
        <td>{'<div data-toggle-image="' + this.props.id + '"></div>'}</td>
        <td className="actions">
          <a href="#" className="action" onClick={this.openModal.bind(this)}> Edit</a><br />
          <Modal className="edit-toggle-image"
                 closeModal={this.closeModal.bind(this)}
                 isOpen={this.state.modalIsOpen}
                 headerText="Add Toggle Image"
                 newRecord={false} >
            <Form closeModal={this.closeModal.bind(this)}
                  id={this.props.id}
                  locationType="Slide"
                  locationId={this.props.locationId}
                  selectionType={this.props.selectionType}
                  selectionTypes={this.props.selectionTypes}
                  title={this.props.title}
                  zoomable={zoomable === 't'}
                  newRecord={this.props.newRecord}
                  onUpdate={this.updateToggleImage.bind(this)}
                  getExistingImagesUrl={this.props.getExistingImagesUrl} />
          </Modal>
          <DeleteButton confirmationModalIsOpen={this.state.confirmationModalIsOpen}
                        openConfirmationModal={this.openConfirmationModal.bind(this)}
                        closeConfirmationModal={this.closeConfirmationModal.bind(this)}
                        handleDelete={this.handleDelete.bind(this)} isDeleting={this.state.isDeleting} />
        </td>
      </tr>
    )
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openConfirmationModal() {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  updateToggleImage(toggle_image) {
    this.setState(toggle_image);
    this.props.updateRow(toggle_image);
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/toggle_images/${this.props.id}`).then(response => {
      response.data ? this.handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    });
  }
}

Row.defaultProps = {
  confirmationModalIsOpen: false,
  modalIsOpen: false,
  isDeleting: false,
  id: null,
  title: '',
  selectionType: '',
  zoomable: '',
  removeRow: null
};
