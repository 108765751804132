import React, { Component } from 'react';
import Axios from 'axios';
import Row from './Row';
import SysadminTable from '../../common/SysadminTable';
import { ModalButton } from '../materials/Utils';
import Form from './Form';

const columns = [
  { text: 'Title', className: 'toggle-image-title' },
  { text: 'Selection Type', className: 'selection-type' },
  { text: 'Zoom Enabled', className: 'zoom-enabled' },
  { text: 'Snippet', className: 'snippet' },
  { text: 'Actions', className: 'actions' }
];

export default class ToggleImageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  addRow(toggle_image) {
    let new_image_list = this.state.toggle_images.concat(toggle_image);

    this.setState({ toggle_images: new_image_list });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getToggleImages();
  }

  getToggleImages() {
    Axios.get(this.props.getUrl).then(response => {
      if (response.data[0]['error'] === undefined) {
        let toggle_images = response.data.length > 0 ? response.data.sort((a, b) => { a.position > b.position }) : response.data;

        this.setState({
          toggle_images: toggle_images,
          isLoading: false
        });
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  removeRow(id) {
    let filtered_images = this.state.toggle_images.filter(toggle_image => toggle_image.id !== id);

    this.setState({
      toggle_images: filtered_images
    });
  }

  updateRow(updatedToggleImage) {
    let updated_images = this.state.toggle_images.map((toggle_image) => {
      return (updatedToggleImage.id === toggle_image.id ? updatedToggleImage : toggle_image);
    });

    this.setState({
      toggle_images: updated_images
    });
  }

  renderToggleImages() {
    return this.state.toggle_images && this.state.toggle_images.map((toggle_image) => {
      return (
        <Row key={toggle_image.id}
             title={toggle_image.title}
             removeRow={this.removeRow.bind(this)}
             updateRow={this.updateRow.bind(this)}
             selectionType={toggle_image.selection_type}
             zoomable={toggle_image.zoomable == 't' ? true : false}
             locationId={this.state.locationId}
             selectionTypes={this.props.selectionTypes}
             newRecord={false}
             getExistingImagesUrl={this.props.getExistingImagesUrl}
             {...toggle_image} />
      );
    });
  }

  render() {
    const new_form = <Form closeModal={this.closeModal.bind(this)}
                           locationType="Slide"
                           locationId={this.props.locationId}
                           selectionType={this.props.selectionTypes[0]['value']}
                           selectionTypes={this.props.selectionTypes}
                           title={''}
                           zoomable={true}
                           newRecord={true}
                           onAdd={this.addRow.bind(this)}
                           getExistingImagesUrl={this.props.getExistingImagesUrl} />

    return (
      <div>
        <ModalButton buttonText="Add Toggle Image"
                     buttonClass="action"
                     modalHeader="Add Toggle Image"
                     modalClass="add-toggle-images"
                     newRecord={true}
                     openModal={this.openModal.bind(this)}
                     closeModal={this.closeModal.bind(this)}
                     formComponent={new_form}
                     {...this.state} />
        <SysadminTable isLoading={this.state.isLoading}
                       locationId={this.props.locationId}
                       locationType={this.props.locationType}
                       containerClass="toggle-image-container"
                       tableId="toggle-image"
                       columns={columns}
                       selectionTypes={this.props.selectionTypes}
                       emptyMessage="No toggle images."
        >
          {this.renderToggleImages()}
        </SysadminTable>
      </div>
    )
  }
}

ToggleImageEditor.defaultProps = {
  isLoading: false,
  toggle_images: []
};