import React from 'react';
import { t } from 'i18n-js';
import useFilters from '@/hooks/useFilters';
import AlphabetFilter from '@/components/ui/AlphabetFilter';
import IReferenceMaterial from '@/components/interfaces/ReferenceMaterial';
import { useAlphabetFilter } from '@/components/ui/AlphabetFilter/AlphabetFilter';
import Sidebar, { SidebarHeadWithButton } from '@/components/ui/Sidebar';
import ReferenceMaterialCard from './ReferenceMaterialCard';

interface Props {
  currentStafferId?: number;
  defaultLessonId: number;
  programId: number;
  referenceMaterials: IReferenceMaterial[];
}

const Biographies = ({
  currentStafferId, programId, referenceMaterials: initialReferenceMaterials, defaultLessonId
}: Props) => {
  const { activeLetter, availableLetters, data: referenceMaterials, setActiveLetter } = useAlphabetFilter(
    initialReferenceMaterials,
    referenceMaterial => referenceMaterial.title.at(0),
  );

  const { activeFilters, clear, data: filteredData, toggle } = useFilters(referenceMaterials, {
    has_video: {
      getValue: referenceMaterial => referenceMaterial.has_video,
    },
  });

  return (
    <div>
      <div className="tw-mt-8 tw-mb-4">
        <div className="tw-border-b tw-border-solid tw-border-gray-300" />
        <AlphabetFilter activeLetters={availableLetters} initialLetter={activeLetter} onChange={setActiveLetter} />
        <div className="tw-border-t tw-border-dotted tw-border-gray-300" />
      </div>
      <div className="tw-flex tw-gap-8">
        <Sidebar isFloating>
          <SidebarHeadWithButton buttonText={t('clear')} headerText={t('filters')} onClick={clear} />
          <label className="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer">
            <input
              className="tw-cursor-pointer"
              type="checkbox"
              checked={activeFilters.has_video}
              onChange={() => toggle('has_video')}
            />
            {t('contains_video')}
          </label>
        </Sidebar>
        <div className="tw-flex tw-flex-wrap tw-gap-4">
          {filteredData.map(referenceMaterial => (
            <ReferenceMaterialCard
              key={referenceMaterial.id}
              currentStafferId={currentStafferId}
              programId={programId}
              referenceMaterial={referenceMaterial}
              lessonId={defaultLessonId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Biographies;
