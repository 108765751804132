import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';

interface IProgramAccessApiResponse {
  program_ids: number[];
}

const useProgramAccess = () => (
  useQuery(['ProgramAccess'], () => (
    axios
      .get<IProgramAccessApiResponse>(Routes.plato_api_program_access_games_path())
      .then(res => res.data.program_ids)
      .catch(() => [])
  ), { placeholderData: [] })
);

export default useProgramAccess;
