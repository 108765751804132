import React from 'react';
import I18n from 'modules/i18n';
import { ApolloProvider } from '@apollo/client';
import Modal, { useModalManager } from 'components/common/Modal';
import apolloClient from 'components/common/ApolloClient';
import styles from './ImpersonateStudent.module.scss';
import { ImpersonateStudentProps } from './types';
import ImpersonateStudentButton from './ImpersonateStudentButton';
import ModalBody from './ModalBody';

export const ImpersonateStudent = ({
  student, enabled, buttonClass, iconClass, wrapperClass, programId, stafferId,
}: ImpersonateStudentProps) => {
  const spanClass = typeof wrapperClass === 'string' ? wrapperClass : styles.buttonWrapper;
  const modalHeader = student ? I18n.t('sign_in_as_this_student') : I18n.t('sign_in_as_student');
  const modal = useModalManager();

  return (
    <span className={spanClass}>
      <ImpersonateStudentButton
        modal={modal}
        enabled={enabled}
        student={student}
        buttonClass={buttonClass}
        iconClass={iconClass}
        tooltipPlacement="bottom"
      />
      {enabled
        && (
          <Modal isOpen={modal.isOpen} closeModal={modal.close} headerText={modalHeader}>
            <ModalBody
              student={student}
              close={modal.close}
              programId={programId}
              stafferId={stafferId}
            />
          </Modal>
        )}
    </span>
  );
};

/* istanbul ignore next */
const ImpersonateStudentWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <ImpersonateStudent {...props} />
  </ApolloProvider>
);

export default ImpersonateStudentWrapper;
