import GlassPaneSimulation from './GlassPaneSimulation';
import GreenhouseSimulation from './GreenhouseSimulation';
import PhotonAbsorptionSimulation from './PhotonAbsorptionSimulation';

const STATIC_ASSET_BASE_URL = 'https://s3.amazonaws.com/platoproduction20160712/system/static_assets/simulations/';
const W_H_RATIO = 1140 / 730;
const WIDTH_FONT_RATIO = 1835 / 33;

export default class ClimateSimulation {
  constructor(config) {
    this.parent_el = config.parent_el;
  }

  init() {
    this._build();
    $(this.glass_panes_content)[0].style.display = 'flex';

    // Set initial sizing
    let width = $(this.container).innerWidth();
    let new_height = width / W_H_RATIO;
    this.container.style.height = new_height + 'px';
    this.setFontSize();

    this._setupListeners();
    this._createGames();
    this.glass_panes_sim.pause();
  }

  _build() {
    let font_link = document.createElement('link');
    font_link.type = 'text/css';
    font_link.rel = 'stylesheet';

    $('head').append(font_link);

    font_link.href = 'https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet">';

    this.container = document.createElement('div');
    this.container.className = 'tci-simulation-wrapper';
    this.parent_el.appendChild(this.container);

    this.tab_bar = document.createElement('div');
    this.tab_bar.className = 'simulation-tabs-container';
    this.container.appendChild(this.tab_bar);

    this.glass_panes_tab = document.createElement('div');
    this.glass_panes_tab.className = 'simulation-tab selected';
    this.glass_panes_tab.innerHTML = 'Glass Layers';
    this.tab_bar.appendChild(this.glass_panes_tab);

    this.photon_absorption_tab = document.createElement('div');
    this.photon_absorption_tab.className = 'simulation-tab';
    this.photon_absorption_tab.innerHTML = 'Photon Absorption';
    this.tab_bar.appendChild(this.photon_absorption_tab);

    this.greenhouse_tab = document.createElement('div');
    this.greenhouse_tab.className = 'simulation-tab';
    this.greenhouse_tab.innerHTML = 'Greenhouse Effect';
    this.tab_bar.appendChild(this.greenhouse_tab);

    this.content_container = document.createElement('div');
    this.content_container.className = 'simulation-tabbed-content-container';
    this.container.appendChild(this.content_container);

    this.glass_panes_content = document.createElement('div');
    this.glass_panes_content.className = 'simulation-tabbed-content glass-panes';
    this.content_container.appendChild(this.glass_panes_content);

    this.photon_absorption_content = document.createElement('div');
    this.photon_absorption_content.className = 'simulation-tabbed-content photon-absorption';
    this.content_container.appendChild(this.photon_absorption_content);

    this.greenhouse_content = document.createElement('div');
    this.greenhouse_content.className = 'simulation-tabbed-content greenhouse';
    this.content_container.appendChild(this.greenhouse_content);
  }

  setFontSize() {
    let width = $(this.container).innerWidth();

    let font_size = width / WIDTH_FONT_RATIO;

    this.container.style.fontSize = Math.round(font_size) + 'px';
  }

  _setupListeners() {
    $(this.glass_panes_tab).on('click', () => {
      $(this.greenhouse_content).hide();
      $(this.greenhouse_tab).removeClass('selected');
      $(this.photon_absorption_tab).removeClass('selected');
      $(this.glass_panes_tab).addClass('selected');
      $(this.photon_absorption_content).hide();
      $(this.glass_panes_content)[0].style.display = 'flex';
      this.greenhouse_sim.pause();
      this.photon_absorption_sim.pause();
    });

    $(this.photon_absorption_tab).on('click', () => {
      $(this.glass_panes_content).hide();
      $(this.glass_panes_tab).removeClass('selected');
      $(this.greenhouse_tab).removeClass('selected');
      $(this.photon_absorption_tab).addClass('selected');
      $(this.greenhouse_content).hide();
      $(this.photon_absorption_content)[0].style.display = 'flex';
      this.glass_panes_sim.pause();
      this.greenhouse_sim.pause();
    });

    $(this.greenhouse_tab).on('click', () => {
      $(this.glass_panes_content).hide();
      $(this.glass_panes_tab).removeClass('selected');
      $(this.photon_absorption_tab).removeClass('selected');
      $(this.greenhouse_tab).addClass('selected');
      $(this.photon_absorption_content).hide();
      $(this.greenhouse_content)[0].style.display = 'flex';
      this.glass_panes_sim.pause();
      this.photon_absorption_sim.pause();
    });

    $(window).on('resize', () => {
      let active_sim;

      if (!this.glass_panes_sim.paused) {
        active_sim = this.glass_panes_sim;
      }

      if (!this.greenhouse_sim.paused) {
        active_sim = this.greenhouse_sim;
      }

      if (!this.photon_absorption_sim.paused) {
        active_sim = this.photon_absorption_sim;
      }

      this.glass_panes_sim.pause();

      this.greenhouse_sim.pause();

      this.photon_absorption_sim.pause();

      let width = $(this.container).innerWidth();

      let new_height = width / W_H_RATIO;

      this.container.style.height = new_height + 'px';

      this.setFontSize();

      if (active_sim) {
        active_sim.play();
      }
    });
  }

  _createGames() {
    this.glass_panes_sim = new GlassPaneSimulation({
      container: this.glass_panes_content,
      BASE_URL: STATIC_ASSET_BASE_URL
    });

    this.glass_panes_sim.init();

    this.greenhouse_sim = new GreenhouseSimulation({
      container: this.greenhouse_content,
      BASE_URL: STATIC_ASSET_BASE_URL
    });

    this.greenhouse_sim.init();
    this.greenhouse_sim.pause();

    this.photon_absorption_sim = new PhotonAbsorptionSimulation({
      container: this.photon_absorption_content,
      BASE_URL: STATIC_ASSET_BASE_URL
    });

    this.photon_absorption_sim.init();
    this.photon_absorption_sim.pause();
  }
}