import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterInput from './FilterInput';
import FilterPopup from './FilterPopup';
import styles from './Filters.module.scss';

const ARIA_LABELS = {
  used_in: 'Filter materials by where they are used',
  source: 'Filter materials by where they are supplied'
};

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onSelectAllChange = this.onSelectAllChange.bind(this);
    this.toggleFilterPopup = this.toggleFilterPopup.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    for (let i = 0; i < this.state.options.length; i++) {
      this[this.state.options[i].value] = React.createRef();
    }
  }

  onOptionChange(val, checked) {
    const opt_obj = {};
    opt_obj.active = opt_obj.active || {};
    opt_obj.active[val] = checked;

    this.setState(opt_obj, () => {
      this.props.handleOptionChange(this.state);
    });
  }

  onSelectAllChange(val, checked) {
    const all_filters = {};
    for (let i = 0; i < this.props.options.length; i++) {
      this[this.props.options[i].value].select(checked);
      all_filters.active = all_filters.active || {};
      all_filters.active[this.props.options[i].value] = checked;
    }
    this.selectAll.select(checked);

    this.setState(all_filters, () => {
      this.props.handleOptionChange(this.state);
    });
  }

  getAriaDescribedbyForToggle(show) {
    if (show) return `filter-popup-${this.props.name}`;

    return null;
  }

  toggleFilterPopup() {
    this.setState({ show: !this.state.show });
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.which === 32) {
      e.preventDefault();
      this.toggleFilterPopup();
    }
  }

  renderInputHtml() {
    return (
      <div id={`filter-popup-${this.props.name}`} className="filter-tooltip">
        {this.renderSelectAll()}
        {this.props.options.map(option => (
          <FilterInput
            name={this.props.name}
            key={option.label}
            ref={(filter_input) => { this[option.value] = filter_input; }}
            option={option}
            onOptionChange={this.onOptionChange}
          />
        ))}
      </div>
    );
  }

  renderSelectAll() {
    if (this.props.selectAllOption) {
      return (
        <FilterInput
          name={this.props.name}
          key={`select-all-${this.props.name}`}
          ref={(select_all) => { this.selectAll = select_all; }}
          option={{ label: '(Select All)', value: 'selectAll' }}
          onOptionChange={this.onSelectAllChange}
        />
      );
    }
    return false;
  }

  render() {
    return (
      <FilterPopup html={this.renderInputHtml()} show={this.state.show} filterName={this.props.name}>
        <span
          className={styles['dropdown-icon']}
          role="button"
          tabIndex="0"
          onClick={this.toggleFilterPopup}
          onKeyDown={this.handleKeyDown}
          aria-label={ARIA_LABELS[this.props.name]}
          aria-describedby={this.getAriaDescribedbyForToggle(this.state.show)}
        >
          <i className="fa fa-chevron-down" aria-hidden="true" />
        </span>
      </FilterPopup>
    );
  }
}

Filter.defaultProps = {
  options: [],
  name: null,
  selectAllOption: false,
  show: false
};

Filter.propTypes = {
  name: PropTypes.string,
  show: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  handleOptionChange: PropTypes.func.isRequired,
  selectAllOption: PropTypes.bool
};
