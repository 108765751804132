import React from 'react';
import { BackgroundColor } from '@/components/interfaces/graphql/HtmlElement/HtmlElementUtils';

interface ButtonSnippetProps {
  autoHide?: boolean;
  backgroundColor?: BackgroundColor;
  buttonMaxWidth?: string;
  buttonTextEn?: string;
  buttonTextEs?: string;
  className?: string;
  displayAsLink?: boolean;
  destinationSlideId?: number;
  id: number | string;
  type: string;
}

const ButtonSnippet = ({
  autoHide,
  backgroundColor,
  buttonMaxWidth,
  buttonTextEn,
  buttonTextEs,
  className = 'fr-deletable',
  displayAsLink,
  destinationSlideId,
  id,
  type,
  ...props
}: ButtonSnippetProps) => (
  <span
    className={className}
    contentEditable={false}
    data-background-color={backgroundColor}
    data-max-width={buttonMaxWidth}
    data-button-text-en={buttonTextEn}
    data-button-text-es={buttonTextEs}
    data-html-element-id={id}
    data-html-element-type={type}
    data-updated-at={Date.now()}
    data-auto-hide={autoHide}
    data-display-as-link={displayAsLink}
    {...(destinationSlideId && { 'data-destination-slide-id': destinationSlideId })}
    {...props}
  />
);

export default ButtonSnippet;
