import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { plato_api_slide_program_settings_path } from '@/modules/routes';
import { toCamelCase } from '@/modules/TCIUtils';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import EditSlidePageNumbers from './EditSlidePageNumbers';
import { SlideProgramSettingType } from './types';

const SlidePageNumbers = () => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const programId = useSlideShowContext(state => state.programId);
  const [slideProgramSettings, setSlideProgramSettings] = useState<SlideProgramSettingType[]>([]);

  const sortedByProgram = (data) => {
    const forCurrentProgram = data.find(s => s.programId === programId);
    const sortedByTitle = data
      .filter(s => s.programId !== programId)
      .sort((a, b) => a.programTitle.localeCompare(b.programTitle))

    return [forCurrentProgram, ...sortedByTitle].filter(e => e!= null);
  }

  useEffect(() => {
    Axios.get(plato_api_slide_program_settings_path({ slide_id: currentSlide.id }))
      .then((res) => {
        const camelizedData = res.data.slide_program_settings.map(s => toCamelCase(s));
        setSlideProgramSettings(sortedByProgram(camelizedData));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="tw-space-y-[20px] tw-overflow-y-auto tw-pb-[40px]">
      {slideProgramSettings.map((setting, i) => (
        <EditSlidePageNumbers
          key={`edit-slide-page-numbers-${i}`}
          slideProgramSetting={setting}
        />
      ))}
    </div>
  );
};

export default SlidePageNumbers;
