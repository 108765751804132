import React from 'react';
import { Button } from 'react-aria-components';
import Tooltip from '@/components/common/Tooltip';
import { Collapse } from '../../../../images/react/Collapse';
import { Expand } from '../../../../images/react/Expand';
import { HEADER_BUTTON_CLASSES, TRANSLATIONS, usePlanningDrawer } from '../PlanningDrawer/utils';

export const ExpandCollapseButton = () => {
  const { isExpanded, toggleExpanded } = usePlanningDrawer();
  const message = isExpanded ? TRANSLATIONS.dialog.collapse : TRANSLATIONS.dialog.expand;

  return (
    <Tooltip content={message} interactive={false}>
      <Button
        aria-label={message}
        className={HEADER_BUTTON_CLASSES}
        onPress={toggleExpanded}
        type="button"
      >
        {isExpanded ? <Collapse fill={null} /> : <Expand fill={null} />}
      </Button>
    </Tooltip>
  );
};
