import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import bookIcon from 'images/icons/book.png';
import calendarIcon from 'images/icons/calendar.png';
import clipboardIcon from 'images/icons/clipboard.png';
import lessonGameIcon from 'images/icons/lesson_game_icon.svg';
import pencilIcon from 'images/icons/pencil.png';
import styles from './index.module.scss';

const QuickLinkCard = ({ borderColor, icon, iconSize, label, labelClass, url }) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = url;
  };

  return (
    <a
      href={url}
      className={`card ${styles.quickLink} ${styles[borderColor]}`}
      onClick={handleClick}
    >
      <img className={`${styles.icon} ${styles[iconSize]}`} src={icon} alt="" />
      <p className={labelClass}>{label}</p>
    </a>
  );
};

QuickLinkCard.propTypes = {
  borderColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  url: PropTypes.string.isRequired
};

QuickLinkCard.defaultProps = {
  iconSize: 'small',
  labelClass: ''
};

export default QuickLinkCard;

const QuickLinkCardPropType = { url: PropTypes.string.isRequired };

const ReadTextQuickLink = ({ url }) => (
  <QuickLinkCard
    label={I18n.t('read_text')}
    borderColor="purple"
    icon={bookIcon}
    iconSize="large"
    url={url}
  />
);

ReadTextQuickLink.propTypes = QuickLinkCardPropType;

const AssignmentsQuickLink = ({ url }) => (
  <QuickLinkCard
    label={I18n.t('view_assignments')}
    borderColor="green"
    icon={calendarIcon}
    url={url}
  />
);

AssignmentsQuickLink.propTypes = QuickLinkCardPropType;

const LessonGameQuickLink = ({ url }) => (
  <QuickLinkCard
    label={I18n.t('lesson_game')}
    borderColor="green"
    icon={lessonGameIcon}
    url={url}
  />
);

LessonGameQuickLink.propTypes = QuickLinkCardPropType;

export const QuickLinks = ({ isStudent, quickLinks }) => {
  const studentQuickLinks = () => (
    <React.Fragment>
      <ReadTextQuickLink url={quickLinks.readText.links_to} />
      <QuickLinkCard
        label={`${I18n.t('view')} ${quickLinks.notebook.label}`}
        borderColor="orange"
        icon={pencilIcon}
        url={quickLinks.notebook.links_to}
      />
      <LessonGameQuickLink url={quickLinks.lessonGame.links_to} />
    </React.Fragment>
  );

  const teacherQuickLinks = () => (
    <React.Fragment>
      <QuickLinkCard
        label={I18n.t('plan_lesson')}
        borderColor="orange"
        icon={clipboardIcon}
        url={quickLinks.planLesson.links_to}
      />
      <ReadTextQuickLink url={quickLinks.readText.links_to} />
      <AssignmentsQuickLink url={quickLinks.assignments.links_to} />
    </React.Fragment>
  );

  return (
    <div className={styles.quickLinksContainer}>
      {isStudent ? studentQuickLinks() : teacherQuickLinks()}
    </div>
  );
};

export const QuickLinkPropType = { links_to: PropTypes.string.isRequired };

QuickLinks.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  quickLinks: PropTypes.shape({
    assignments: QuickLinkPropType,
    culturalResponse: QuickLinkPropType,
    notebook: QuickLinkPropType,
    planLesson: QuickLinkPropType,
    readText: QuickLinkPropType,
    lessonGame: QuickLinkPropType,
  }).isRequired
};
