import React from 'react';
import useSearchStore from './useSearchStore';

const TotalResults = () => {
  const isLoading = useSearchStore(state => state.isLoading);
  const numResults = useSearchStore(state => state.numResults);

  const text = () => {
    if (numResults === undefined || isLoading) return <>&nbsp;</>;
    if (numResults === 0) return 'RESULTS (0)';

    return `RESULTS (1 - ${numResults})`;
  };

  return (
    <p className="tw-font-bold tw-text-xs tw-mb-2">
      {text()}
    </p>
  );
};

export default TotalResults;
