/* eslint-disable no-underscore-dangle */

import { ISlide, ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import { SLIDE_SETTINGS_STORAGE_KEY } from '@/components/admin/SlideShow/PresentMode/Constants';
import I18n from '@/modules/i18n';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';

export const findLockedSlides = ({ condition, slides, unansweredQuestionIds, position }) => {
  return slides.filter((slide: ISlide) => {
    const questionIds = slide.slideObjects.flatMap(so => so.answerableQuestionIds);

    if (slide.condition?.action === 'unlock_next_slide' && position > slide.position) {
      return unansweredQuestionIds.some(id => questionIds.includes(id));
    }
    if (condition?.action !== 'unlock_navigation') return false;
    if (!condition.slideIds.includes(Number(slide.id))) return false;

    return unansweredQuestionIds.some(id => questionIds.includes(id));
  });
};

const useSlideConditions = () => {
  const formattedSlides = useSlideShowContext(state => state.slides);
  const unansweredQuestions = useSlideShowContext(state => state.unansweredQuestions);

  const lockedSlides = (slide: ISlideOrSlideGroup) => {
    if (!slide) return [];
    if (slide.__typename !== 'Slide') return [];

    const { condition } = slide;
    const slideSettings = JSON.parse(localStorage.getItem(SLIDE_SETTINGS_STORAGE_KEY)) || {};

    if (slideSettings.overrideSlideConditions) return [];

    return findLockedSlides({
      position: slide.position,
      condition,
      slides: formattedSlides.filter(s => s.__typename === 'Slide') as ISlide[],
      unansweredQuestionIds: unansweredQuestions.map(q => q.id),
    });
  };

  const lockedSlideMessage = (slide: ISlideOrSlideGroup) => {
    const lockedSlidePositions = lockedSlides(slide).map(s => s.position);
    if (lockedSlidePositions.length === 0) return '';

    const slideLabel = I18n.t(
      'slide_with_article',
      { count: lockedSlidePositions.length },
    ).toLowerCase();
    const slideLabelWithNumbers = `${slideLabel} ${lockedSlidePositions.join(', ')}`;

    return I18n.t('slide_conditions_locked_slide_message', { slides: slideLabelWithNumbers });
  };

  return {
    isSlideLocked: slide => lockedSlides(slide).length !== 0,
    lockedSlideMessage,
    lockedSlides,
  };
};

export default useSlideConditions;
