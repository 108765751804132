import React from 'react';

const AlignLeft = () => (
  <svg
    className="fr-svg"
    focusable="false"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3,18h6v-2H3V18z M3,11v2h12v-2H3z M3,6v2h18V6H3z" />
  </svg>
);

export default AlignLeft;
