import React from 'react';
import PropTypes from 'prop-types';

import styles from './MyAccount.module.scss';
import StudentInfo from './StudentInfo';
import ChangePasswordForm from './ChangePasswordForm';
import AutorosterNotice from './AutorosterNotice';

export default function MyAccount({
  active, student, studentPath, isAutoRostered
}) {
  if (!active) return null;

  return (
    <div className={styles.formContainer}>
      {isAutoRostered && <AutorosterNotice />}

      <StudentInfo student={student} />

      {!isAutoRostered && <ChangePasswordForm studentPath={studentPath} />}
    </div>
  );
}

MyAccount.propTypes = {
  active: PropTypes.bool.isRequired,
  isAutoRostered: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    username: PropTypes.string
  }).isRequired,
  studentPath: PropTypes.string.isRequired
};
