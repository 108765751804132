/**
 * Checks whether the row given matches the headers for the given import type.
 * @param cells Array of strings from a CSV
 * @param importType Can be 'staff', 'student'
 * @returns {object}
 */
const STAFF_HEADERS = ['email', 'first_name', 'last_name', 'password', 'password_confirmation', 'program_code'];
const STUDENT_HEADERS = ['first_name', 'last_name', 'username', 'password',
  'password_confirmation', 'teacher_email', 'program_code', 'class_period'];
const SHARED_CSV_STUDENT_HEADERS = STUDENT_HEADERS.concat('class_uid');

const expectedCells = {
  shared_classes_student: SHARED_CSV_STUDENT_HEADERS,
  staff: STAFF_HEADERS,
  student: STUDENT_HEADERS,
};

export function correctHeaders(cells, importType) {
  const headerRow = cells.map(cell => cell.replace(/\s+/g, '')).join(',').toLowerCase()
    .replace('first_initial', 'first_name'); // first_initial is also a valid header.

  if (headerRow === expectedCells[importType].join(',')) {
    return [];
  }

  // studentCells is a valid header row for shared_classes_student (no class_uid)
  if (importType === 'shared_classes_student' && headerRow === expectedCells.student.join(',')) {
    return [];
  }

  return [{
    details: 'First row must contain the correct header information',
    error: 'Incorrect header row',
    row: 1,
  }];
}

/**
 * Checks if row has correct number of cells, based on the header row given.
 * @param cells Array of strings from parsing row
 * @param importType 'staff' or 'student'
 * @param row Row number
 * @returns {Array} Contains the error if any.
 */
export function hasIncorrectNumOfCells(cells, importType, row) {
  const cellCount = cells.length;

  if (cellCount === expectedCells[importType].length) {
    return [];
  }

  if (importType === 'shared_classes_student' && cellCount === expectedCells.student.length) {
    return [];
  }

  return [{
    details: 'Row contains incorrect number of cells',
    error: 'Invalid row',
    row
  }];
}

const hasSpaces = value => /\s/g.test(value);

const isEmpty = value => value === '';

const minLength = min => value => value && value.length >= min;

const matchingPasswords = (password, confirmation) => password === confirmation;

/**
 * Checks that the password is of min length and does not have any invalid
 * characters, including spaces.
 * @param password String to check
 * @param confirmation Password confirmation
 * @param row Row number
 * @returns {Array} Contains error objects with error and row number
 */
export function checkPassword(password, confirmation, row) {
  const errors = [];
  const error = 'Invalid password';

  if (!minLength(6)(password)) {
    errors.push({ details: 'Password must be at least 6 characters long', error, row });
  }

  if (hasSpaces(password)) {
    errors.push({ details: 'Password cannot have spaces', error, row });
  }

  if (!matchingPasswords(password, confirmation)) {
    errors.push({ details: 'Password confirmation does not match password', error, row });
  }

  return errors;
}

/**
 * Checks if the program code matches any of the codes provided.
 * @param value Program code
 * @param codes Array of valid program codes
 * @param row Row number
 * @returns {Array} Contains the error, if any
 */
export function checkProgramCode(value, codes, row) {
  if (codes.includes(value)) return [];
  return [{
    details: 'Program code does not match any programs',
    error: 'Invalid program code',
    row
  }];
}

/**
 * Checks if a row contains any empty cells.
 * @param cells Array of strings making up the row.
 * @param row_num
 * @returns {Array} Contains the error, if any.
 */
export function hasBlankCells(cells, row_num) {
  if (cells.some(isEmpty)) {
    return [{
      details: 'All cells must have a value',
      error: 'Missing information',
      row: row_num
    }];
  }

  return [];
}

/**
 * Checks for duplicate staff (unique email and program code). If the row is
 * unique, it's added to the rows read.
 * @param cells Array of strings making up the row
 * @param row_num
 * @param rowsRead Array of objects (email, program code, row number) of rows already read.
 * @returns {Array} Contains the error, if any.
 */
export function checkDuplicateStaff(cells, row_num, rowsRead) {
  const email = cells[0];
  const programCode = cells[5];

  const duplicate = rowsRead.find(row => row.email === email && row.programCode === programCode);
  if (duplicate) {
    return [{
      details: `Duplicate of row ${duplicate.row}: email and program code must be unique per row`,
      error: 'Duplicate rows',
      row: row_num
    }];
  }

  rowsRead.push({ email, programCode, row: row_num });
  return [];
}

/**
 * Checks for duplicate students (unique username and program code). If the row is
 * unique, it's added to the rows read.
 * @param cells Array of strings making up the row
 * @param importType string declaring the type of student import
 * @param row_num
 * @param rowsRead Array of objects (email, program code, row number) of rows already read.
 * @returns {Array} Contains the error, if any.
 */
export function checkDuplicateStudents(cells, importType, row_num, rowsRead) {
  const username = cells[2];
  const teacherEmail = cells[5];
  const programCode = cells[6];
  const classUid = cells[8];

  const duplicate = rowsRead.find(row => duplicateStudentConditions(row, importType, username, teacherEmail, programCode, classUid));

  let errorMsg;
  if (importType === 'student' || classUid === undefined) {
    errorMsg = 'username, teacher email, and program code';
  }
  else {
    errorMsg = 'username, teacher email, program code, and class uid';
  }

  if (duplicate) {
    return [{
      details: `Duplicate of row ${duplicate.row}: ${errorMsg} must be unique per row`,
      error: 'Duplicate rows',
      row: row_num
    }];
  }

  rowsRead.push({
    classUid,
    programCode,
    row: row_num,
    teacherEmail,
    username
  });

  return [];
}

function duplicateStudentConditions(row, importType, username, teacherEmail, programCode, classUid) {
  const studentUniqRow = (row.username === username && row.teacherEmail === teacherEmail && row.programCode === programCode);

  // if the subscriber does not have the shared classes csv flag or if the csv does not have the classUid column
  // use username, teacherEmail, and programCode to validate uniqueness
  if (importType === 'student' || classUid === undefined) {
    return studentUniqRow;
  }

  // add classUid to validation if it exists and the subscriber has the shared classes csv flag
  return studentUniqRow && row.classUid === classUid;
}
