import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';

import playIcon from '../../../../../assets/images/student_text_toolbar/play.svg';
import pauseIcon from '../../../../../assets/images/student_text_toolbar/pause.svg';

export default class NarrationButton extends Component {
  static propTypes = {
    narrationClip: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      playing: false
    };

    this.audio = new Audio(props.narrationClip);
    this.audio.onended = () => this.toggleAudio();

    this.toggleAudio = this.toggleAudio.bind(this);
  }

  toggleAudio() {
    if (this.state.playing) {
      this.audio.pause();
    }
    else {
      this.audio.play();
    }

    this.setState(prevState => ({ playing: !prevState.playing }));
  }

  render() {
    return (
      <Tooltip
        content={this.state.playing ? 'Pause' : 'Play'}
        theme="white"
        size="medium"
      >
        <button
          type="button"
          onClick={this.toggleAudio}
          className={`text-action ${this.state.playing ? 'purple' : null}`}
        >
          <img
            alt={this.state.playing ? 'pause icon' : 'play icon'}
            src={`${this.state.playing ? pauseIcon : playIcon}`}
          />
        </button>
      </Tooltip>
    );
  }
}
