import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import Tooltip from '../../../common/Tooltip';

export default function UsedIn(props) {
  const html = props.lessons.map(lesson =>
    <div className="materials-filters" key={lesson}>{lesson}</div>);

  return (
    <Tooltip
      content={renderToString(html)}
      placement="bottom"
      theme="white"
    >
      <a>
        ({props.lessons.length} {props.lessons.length > 1 ? 'Lessons' : 'Lesson'})
      </a>
    </Tooltip>
  );
}

UsedIn.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.string)
};

UsedIn.defaultProps = {
  lessons: []
};
