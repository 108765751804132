import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';

const SetPublishWrapper = ({ assessmentId, initialPublished }) => (
  <ApolloProvider client={apolloClient}>
    <SetPublishButton initialPublished={initialPublished} assessmentId={assessmentId} />
  </ApolloProvider>
);

SetPublishWrapper.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  initialPublished: PropTypes.bool.isRequired
};

const SET_PUBLISHED = gql`
  mutation SetPublished($assessmentId: ID!, $publish: Boolean!) {
    setPublished(assessmentId: $assessmentId, publish: $publish) {
      success,
      published
    }
  }
`;

const SetPublishButton = ({ assessmentId, initialPublished }) => {
  const [setPublishedMutation] = useMutation(SET_PUBLISHED);
  const [published, setPublished] = useState(initialPublished);

  const togglePublish = () => {
    setPublishedMutation({ variables: { assessmentId, publish: !published } })
      .then((res) => {
        setPublished(res.data.setPublished.published);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const buttonText = published ? 'Unpublish' : 'Publish';

  return <button className="action purple" onClick={togglePublish} type="button">{buttonText}</button>;
};

SetPublishButton.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  initialPublished: PropTypes.bool.isRequired
};

export default SetPublishWrapper;
