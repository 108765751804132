import React from 'react';
import I18n from '@/modules/i18n';
import Popover from '@/components/ui/Popover';
import Tooltip from '@/components/ui/Tooltip';
import { twMerge } from '@/utils';
import clsx from 'clsx';
import ColorPicker from './ColorPicker';
import useDrawingContext from '../store/useDrawingContext';
import { imgStyle, isSelected, buttonStyle } from './styles';
import icons from './icons';

const ColorButton = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const disabled = useDrawingContext(state => state.disabled);
  const mode = useDrawingContext(state => state.mode);
  const color = useDrawingContext(state => state.color);
  const { alt, icon } = icons.color;

  const buttonClassName = twMerge(
    'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
    'hover:tw-bg-tci-purple',
    'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
    'tw-flex tw-items-center tw-justify-center',
  );

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Popover>
          <Tooltip.Trigger asChild>
            <Popover.Trigger
              disabled={disabled}
              className={clsx(
                buttonClassName, {
                  'tw-bg-tci-purple': isSelected(mode, 'color', disabled),
                },
              )}
              style={buttonStyle(toolbarEl)}
            >
              <div
                aria-label={alt}
                role="button"
                style={{
                  ...imgStyle({ toolbarEl }),
                  backgroundColor: (color as string),
                  backgroundImage: `url(${icon})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded tw-border-[1px] tw-border-solid tw-border-white"
              />
            </Popover.Trigger>
          </Tooltip.Trigger>
          <Popover.Content
            className="tw-absolute tw-left-8 tw-w-[unset] tw-z-9999 tw-font-tci"
          >
            <ColorPicker />
          </Popover.Content>
          <Tooltip.Content
            className="tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2 tw-outline tw-outline-1 tw-outline-gray-300"
            side="right"
            sideOffset={10}
          >
            {I18n.t('change_color')}
          </Tooltip.Content>
        </Popover>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default ColorButton;
