import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'components/common/Spinner';
import DropdownMenu from 'components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from 'components/common/Dropdown';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';
import styles from './AnchoredDropdown.module.scss';
import InitialMenu from './InitialMenu';
import PositionMenu from './PositionMenu';
import ColorMenu from './ColorMenu';
import MaxWidth from './MaxWidth';

type AnchoredDropdownProps = {
  editorInstance: { undo: { saveStep: () => void; } };
  element: React.ElementType;
  isSaving: boolean;
  openerClass: string;
  openModal: () => void;
  button?: ITooltipButton;
  setEditMode: (nextState: boolean) => void;
};

const AnchoredDropdown = ({
  editorInstance, element, isSaving, openerClass, openModal, button, setEditMode,
}: AnchoredDropdownProps) => {
  const [isEditingPosition, setIsEditingPosition] = useState(false);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [isEditingSize, setIsEditingSize] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const positionMenuRef = useRef<HTMLDivElement>();
  const dropdownEvents = useDropdownEvents(ref);
  const slideRef = useSlideShowContext(state => state.slideRef);
  const [coordinates, setCoordinates] = useState<{ left: number, top: number }>();
  const [dummyElement, setDummyElement] = useState(document.createElement('div'));
  const isTooltipButton = button?.type === 'TooltipButton';

  useEffect(() => {
    if (!button) return;

    const newElement = document.createElement('div');
    newElement.innerHTML = button.htmlEn;

    if (isTooltipButton) {
      const positionStyles = button.data.tooltipStyles || { bottom: 0, right: 0 };

      Object.keys(positionStyles).forEach((style) => {
        newElement.style[style] = positionStyles[style];
      });

      newElement.style.width = button?.data.width ? button.data.width : '';
    }

    setDummyElement(newElement);
  },
    [
      element,
      button?.data?.tooltipStyles?.top,
      button?.data?.tooltipStyles?.left,
      button?.data?.tooltipStyles?.right,
      button?.data?.tooltipStyles?.bottom,
    ]);

  useEffect(() => {
    if (dropdownEvents.open || ref.current == null) return;

    setIsEditingPosition(false);
    setIsEditingColor(false);
    setIsEditingSize(false);
    setEditMode(false);
  }, [dropdownEvents.open]);

  useEffect(() => {
    const toggle = (e) => {
      // TODO: For some reason, the button in the first menu does not show up as a
      // child of the Dropdown. So this will be here for now as a initial solution but
      // should be looked at later for refactoring
      if (['Change Tooltip Size and Position', 'Change Color', 'Change Size'].includes(e.target.innerText)) return;
      if (ref.current.contains(e.target)) return;
      if (!e.target.classList.contains(openerClass) && !e.target.dataset.htmlElementId) {
        dropdownEvents.setOpen(false);
        return;
      }

      // for legacy buttons, the button itself is nested within a contenteditable span, so we need to look at
      // the first child element
      if (e.target === element[0] || e.target === element[0].firstChild) {
        dropdownEvents.setOpen(prev => !prev);
      }
    };

    document.addEventListener('click', toggle);

    return () => document.removeEventListener('click', toggle);
  }, [openerClass, element[0], ref && ref.current]);

  const relocate = () => {
    if (!element[0]) return;

    const rect = element[0].getBoundingClientRect();
    let left = rect.x;
    let top = rect.bottom + window.scrollY;

    const positionMenuHeight = positionMenuRef?.current?.clientHeight;
    if (positionMenuHeight) {
      const distancePositionMenuIsOffScreen = top + positionMenuHeight - window.visualViewport.height;
      if (distancePositionMenuIsOffScreen > 0) {
        top -= (distancePositionMenuIsOffScreen + 30);
        left += (rect.width + 10)
      }
    }

    setCoordinates({ left, top });
  };

  useEffect(() => {
    if (!element[0]) return;

    relocate();
    document.addEventListener('scroll', relocate, true);
    window.addEventListener('resize', relocate);

    return () => {
      document.removeEventListener('scroll', relocate);
      window.removeEventListener('resize', relocate);
    };
  }, [element, positionMenuRef?.current]);

  const handleClose = () => {
    openModal();
    setIsEditingPosition(false);
    setIsEditingColor(false);
    setIsEditingSize(false);
  };

  if (!coordinates) return null;

  return ReactDOM.createPortal(
    <DropdownMenu open={dropdownEvents.open} className={styles.dropdown} coordinates={coordinates} ref={ref}>
      {isSaving && (
        <div
          className="
            tw-absolute tw-left-0 tw-top-0 tw-flex tw-items-center
            tw-justify-center tw-z-10 tw-min-w-full tw-min-h-full"
        >
          <div className="tw-absolute tw-inset-0 tw-min-w-full tw-min-h-full tw-bg-white tw-opacity-50" />
          <Spinner />
        </div>
      )}
      {!isEditingPosition && !isEditingColor && !isEditingSize && (
        <InitialMenu
          canEditPosition={isTooltipButton}
          handleClose={handleClose}
          isSaving={isSaving}
          setIsEditingPosition={setIsEditingPosition}
          setIsEditingColor={setIsEditingColor}
          setIsEditingSize={setIsEditingSize}
        />
      )}
      {isEditingPosition && (
        <PositionMenu
          editorInstance={editorInstance}
          htmlElementId={element[0].dataset.htmlElementId}
          containerRef={slideRef}
          relocate={relocate}
          positionMenuRef={positionMenuRef}
          elementToUpdate={dummyElement}
          setElementToUpdate={setDummyElement}
          setEditMode={setEditMode}
        />
      )}
      {isEditingColor && (
        <ColorMenu
          editorInstance={editorInstance}
          // this is actually already sending a jquery element, but this fixes type errors
          htmlElement={$(element[0])}
          setEditMode={setEditMode}
        />
      )}
      {isEditingSize && (
        <MaxWidth
          editorInstance={editorInstance}
          htmlElement={$(element[0])}
          setEditMode={setEditMode}
          setIsEditingSize={() => {dropdownEvents.setOpen(false)}}
        />
      )}
    </DropdownMenu>,
    document.body,
  );
};

export default AnchoredDropdown;
