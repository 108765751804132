import React from 'react';
import Select from 'react-select-5';
import styles from './TwelveDaysOfCleanup.module.scss';
import { MessageType, SelectType } from './types';

interface SlackMessageProps {
  message: MessageType;
  remainingDays: SelectType[];
  setSelectedDay: (option, id) => void;
  selectedDays: {
    [key: string]: SelectType;
  };
}

const SlackMessage = ({ message, remainingDays, setSelectedDay, selectedDays }: SlackMessageProps) => {
  if (!message.client_msg_id) return null;

  const isSelected = () => selectedDays[message.client_msg_id];
  const handleSelect = option => setSelectedDay(option, message.client_msg_id);

  return (
    <div
      className={`card ${styles.message} ${isSelected() && styles.selected} ${isSelected() && 'focus'}`}
    >
      {isSelected() && (
        <span className={`small_text ${styles.selectedText} purple`}>
          Included in CSV
        </span>
      )}
      <h2>Message text:</h2>
      <span className="respect_breaks">
        {message.text}
      </span>
      <hr />
      <h2>CSV header:</h2>
      <Select
        isClearable
        isSearchable
        options={remainingDays}
        onChange={handleSelect}
        value={selectedDays[message.client_msg_id]}
      />
    </div>
  );
};

export default SlackMessage;
