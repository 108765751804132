import React from 'react';
import PropTypes from 'prop-types';
import styles from './Errors.module.scss';

export default function Errors({ errors, handleReUpload, numRows }) {
  const errorTypes = ['Incorrect header row', 'Invalid row', 'Duplicate rows',
    'Invalid username', 'Invalid password', 'Invalid program code', 'Missing information'];

  function _renderErrorsCount() {
    const numErrors = new Set(errors.map(error => error.row)).size;
    return (
      <div className={styles.errorsCountContainer}>
        <div>
          <i
            className={`fa fa-exclamation-circle ${styles.exclamation}`}
            aria-hidden="true"
          />
        </div>

        <div>
          <b>Errors:</b>
          <br />
          {numErrors.toLocaleString()} of {numRows.toLocaleString()} rows
        </div>
      </div>
    );
  }

  function _renderErrorsTableBody() {
    return errorTypes.map((type) => {
      const errorsOfSameType = errors.filter(e => e.error === type);
      if (errorsOfSameType.length === 0) return null;

      // show the first three row numbers, with an ellipsis if more exist
      const rowNumbers = Array.from(new Set(errorsOfSameType.map(e => e.row)));
      let rowNumbersText = rowNumbers.slice(0, 3).join(', ');
      if (rowNumbers.length > 3) {
        rowNumbersText = rowNumbersText.concat(', ...');
      }

      return (
        <tr key={type}>
          <td>{type}</td>
          <td>{errorsOfSameType.length}</td>
          <td>{rowNumbersText}</td>
        </tr>
      );
    });
  }

  function _renderErrorsTable() {
    return (
      <table className="table table--sm">
        <thead>
          <tr>
            <th>Error</th>
            <th>Count</th>
            <th>Row #</th>
          </tr>
        </thead>

        <tbody>
          {_renderErrorsTableBody()}
        </tbody>
      </table>
    );
  }

  function _getErrorsCsv() {
    const csvRows = [Object.keys(errors[0]).join(',')]; // header row
    errors.forEach(error => csvRows.push(Object.values(error).join(',')));
    const csv = encodeURI(csvRows.join('\n'));

    return `data:text/csv;charset=utf-8,${csv}`;
  }

  function _downloadErrorsCsv() {
    document.getElementById('upload-errors-csv').click();
  }

  function _renderErrorsDownload() {
    return (
      <div>
        <button className="mt15 btn btn--link-purple" onClick={_downloadErrorsCsv}>
          Export errors
        </button>

        <a
          download="tci_upload_errors.csv"
          href={_getErrorsCsv()}
          id="upload-errors-csv"
          style={{ display: 'none' }}
        >
          Export errors
        </a>
      </div>
    );
  }

  return (
    <div>
      {_renderErrorsCount()}

      <div className="mt20 mb20">Resolve all data errors and re-upload your CSV.</div>

      <div className={styles.errorsTableContainer}>
        {_renderErrorsTable()}
        {_renderErrorsDownload()}
      </div>

      <hr />

      <footer>
        <button className="btn btn--purple" onClick={handleReUpload}>
          Re-upload CSV
        </button>
      </footer>
    </div>
  );
}

Errors.propTypes = {
  errors: PropTypes.instanceOf(Array).isRequired,
  handleReUpload: PropTypes.func.isRequired,
  numRows: PropTypes.number.isRequired
};
