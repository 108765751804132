/* eslint-disable no-param-reassign */
import * as fabric from 'fabric';
import { Canvas } from 'fabric';
import penToolCursor from '../cursors/pen_tool_cursor.svg';

const Pen = (canvas: Canvas, color: string, penWidth: number, penOpacity: number) => {
  const brush = new fabric.PencilBrush(canvas);
  canvas.defaultCursor = 'default';
  canvas.hoverCursor = 'default';

  const opacity = penOpacity / 100;
  let rgb = color;

  const parts = rgb.split(',');
  parts.splice(parts.length - 1, 1);
  parts.push(` ${opacity})`);
  rgb = parts.join(',');

  brush.color = rgb;
  brush.width = penWidth;

  canvas.freeDrawingBrush = brush;
  // url to svg, x offset, y offset
  canvas.freeDrawingCursor = `url(${penToolCursor}) 0 20, auto`;

  return brush;
};

export default Pen;
