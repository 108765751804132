import React from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';

import { Footer } from 'common/Modal';

const ResultsModalFooter = ({ programId, redirectOnLinkSuccess, syncResults }) => {
  const successCallback = () => {
    if (redirectOnLinkSuccess) {
      const successfullyRosteredClasses = syncResults.successful_classroom_ids.length > 0

      // if at least one classroom is successfully rostered for the program
      // redirect the teacher to their classes index for the program
      if (successfullyRosteredClasses) {
        const teacherClassesPage = Routes.teacher_program_classrooms_path(programId);
        return window.location.replace(teacherClassesPage);
      }
    }

    return window.location.reload();
  }

  return (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={successCallback}
      primaryButtonSubmit={false}
      primaryButtonText="Close"
      wrapperClassName="pr20 pl20 pb20"
    />
  );
}

ResultsModalFooter.propTypes = {
  programId: PropTypes.number.isRequired,
  redirectOnLinkSuccess: PropTypes.bool,
  syncResults: PropTypes.shape({
    errors: PropTypes.shape({}),
    successful_classroom_ids: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
}

ResultsModalFooter.defaultProps = {
  redirectOnLinkSuccess: false
}

export default ResultsModalFooter;
