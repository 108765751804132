import React from 'react';
import Correlations from './Correlations';
import { PresentationCorrelationsType, PRESENTATION_TYPES } from './types';

type PresentationCorrelationsProps = {
  correlations: PresentationCorrelationsType;
};

const PresentationCorrelations = ({ correlations }: PresentationCorrelationsProps) => (
  <div>
    <h2 className="mt30">Presentations</h2>
    {PRESENTATION_TYPES.map((type: string) => {
      const presentationCorrelations = correlations[type];
      if (!presentationCorrelations || presentationCorrelations.length === 0) return null;

      return (
        <Correlations
          key={type}
          correlations={presentationCorrelations}
          toggleText={type}
        />
      );
    })}
  </div>
);

export default PresentationCorrelations;
