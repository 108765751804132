import React, { Component } from 'react';
import Axios from 'axios';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import { Footer } from '../Modal';
import { renderErrors, SubmitError } from '../Forms/Utils';
import {
  Legend, RadioButton, UploadInfo, Warning
} from './Methods';
import styles from './StaffMethods.module.scss';

const PARTIAL_UPDATE = '2';
const DESTRUCTIVE_UPDATE = '3';

export default class StaffMethods extends Component {
  static propTypes = {
    batchUpdateCsvPath: PropTypes.string.isRequired,
    canCsvUpdate: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    csvFile: PropTypes.instanceOf(Object).isRequired,
    importCsvPath: PropTypes.string.isRequired,
    manualCsvUploadPath: PropTypes.string,
    numRows: PropTypes.number.isRequired,
    redirectPath: PropTypes.string,
    stafferId: PropTypes.number.isRequired
  };

  static defaultProps = {
    canCsvUpdate: true,
    manualCsvUploadPath: '',
    redirectPath: ''
  };

  static _disableButton(values) {
    return !(values.method === 'import' || values.type_of_upload);
  }

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleUpload = this.handleUpload.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  handleUpload = async (values) => {
    this.setState({ submitting: true });

    const destructive = values.type_of_upload === DESTRUCTIVE_UPDATE;
    const formData = this._buildFormData(values, destructive);
    const ajaxPath = this.props.manualCsvUploadPath;

    return Axios
      .post(ajaxPath, formData)
      .then(() => {
        this.props.closeModal();
        if (this.props.redirectPath) window.location.href = this.props.redirectPath;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  _renderEditWarning = (type) => {
    if (!type) return null;

    const destructiveWarning = type === DESTRUCTIVE_UPDATE ?
      <li>Programs will be removed from ALL STAFF not included in your CSV</li> :
      null;

    return (
      <Warning>
        {destructiveWarning}
        <li>Programs will be replaced for staff included in your CSV</li>
        <li>New staff will be added</li>
      </Warning>
    );
  };

  _buildFormData(values, destructive = false) {
    const formData = new FormData();
    formData.append('csv', this.props.csvFile);
    formData.append('destructive', destructive.toString());
    formData.append('sync_type', values.method);
    formData.append('user_type', 'teacher');
    formData.append('staffer_id', this.props.stafferId);

    if (values.method === 'update') {
      formData.append('type_of_upload', values.type_of_upload);
    }

    return formData;
  }

  _renderUpdateRadioButton() {
    if (this.props.canCsvUpdate) {
      return (
        <Field
          component={RadioButton}
          id="edit"
          label="Edit staff"
          name="method"
          type="radio"
          value="update"
        />
      );
    }

    return (
      <Tooltip
        content="To update your rosters for all classes, Run Sync in Integrations."
        interactive={false}
        theme="white"
      >
        <Field
          id="edit"
          name="method"
          value="update"
          type="radio"
        >
          {({ input }) => (
            <label htmlFor="editOptions" className={styles.disabled}>
              <input
                className={styles.radioButton}
                disabled
                type="radio"
                id="editOptions"
                {...input}
              />
              Edit Staff
            </label>
          )}
        </Field>
      </Tooltip>
    );
  }

  _renderUpdateOptions(form) {
    return (
      <div>
        {this._renderUpdateRadioButton()}

        {this._showEditStaffOptions(form.values)}
      </div>
    );
  }

  _showEditStaffOptions(values) {
    if (values.method === 'update') {
      return (
        <div className={styles.editMethodsContainer}>
          {this._renderFullUpdateOption(values)}
          {this._renderPartialUpdateOption(values)}
        </div>
      );
    }

    return null;
  }

  _renderFullUpdateOption(values) {
    return (
      <span>
        <Field
          component={RadioButton}
          id="edit-destructive"
          label="Overwrite all staff"
          name="type_of_upload"
          type="radio"
          value={DESTRUCTIVE_UPDATE}
        />

        {values.type_of_upload === DESTRUCTIVE_UPDATE && this._renderEditWarning(values.type_of_upload)}
      </span>
    );
  }

  _renderPartialUpdateOption(values) {
    return (
      <span>
        <Field
          component={RadioButton}
          id="edit-partial"
          label="Make partial changes to some staff"
          name="type_of_upload"
          type="radio"
          value={PARTIAL_UPDATE}
        />

        {values.type_of_upload === PARTIAL_UPDATE && this._renderEditWarning(values.type_of_upload)}
      </span>
    );
  }

  renderForm(form) {
    return (
      <form onSubmit={form.handleSubmit}>

        {this.renderFormContent(form)}

        <SubmitError error={form.submitError} />

        <Footer
          primaryButtonDisabled={StaffMethods._disableButton(form.values)}
          primaryButtonText="Upload"
          submitting={this.state.submitting}
          submittingText="Uploading..."
        />
      </form>
    );
  }

  renderFormContent(form) {
    return (
      <fieldset>
        <Legend />

        <Field
          component={RadioButton}
          id="add"
          label="Add staff and assign programs"
          name="method"
          type="radio"
          value="import"
        />

        {this._renderUpdateOptions(form)}
      </fieldset>
    );
  }

  initialValues() {
    if (!this.props.canCsvUpdate) return { method: 'import' };

    return null;
  }

  render() {
    return (
      <div>
        <UploadInfo numRows={this.props.numRows} />
        <Form
          initialValues={this.initialValues()}
          onSubmit={this.handleUpload}
          render={this.renderForm}
        />
      </div>
    );
  }
}
