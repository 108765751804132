import React, { Fragment } from 'react';
import Axios from 'axios';
import { plato_api_copy_slide_path } from '@/modules/routes';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { Slide } from '../types';

interface CopySlideProps {
  slide: Slide;
}

const CopySlide = ({ slide }: CopySlideProps) => {
  const afterCopy = useSlideShowContext(state => state.addSlide);

  const copySlide = () => {
    Axios.post(plato_api_copy_slide_path(slide.id))
      .then((result) => {
        if (result.data.success === 'false') {
          // eslint-disable-next-line no-console
          console.log(result);
          return;
        }

        afterCopy(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  return (
    <Fragment>
      <div>
        <button
          aria-label="Copy slide"
          className="dropdown-item full-size"
          onClick={copySlide}
          type="button"
        >
          Make a Copy
        </button>
      </div>
    </Fragment>
  );
};

export default CopySlide;
