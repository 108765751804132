import IModelEssentialQuestion from '@/components/interfaces/graphql/HtmlElement/ModelEssentialQuestion';
import IModelNumber from '@/components/interfaces/graphql/HtmlElement/ModelNumber';
import IModelPhenomenon from '@/components/interfaces/graphql/HtmlElement/ModelPhenomenon';
import IModelStoryline from '@/components/interfaces/graphql/HtmlElement/ModelStoryline';
import IModelTitle from '@/components/interfaces/graphql/HtmlElement/ModelTitle';

const stringToHtml = (str: string): HTMLElement => {
  const div = document.createElement('div');
  div.innerHTML = str;

  return div.firstElementChild as HTMLElement;
};

const findTextNodeContainer = (node: HTMLElement) => {
  let container = node;

  while (container.children.length > 0) {
    container = container.firstElementChild as HTMLElement;
  }

  return container;
};

type ISimpleVariable = IModelNumber | IModelTitle | IModelEssentialQuestion;

export const replaceShallowVariable = (htmlElement: ISimpleVariable, domElement: HTMLElement) => {
  const snippet = stringToHtml(htmlElement.snippet);
  const container = findTextNodeContainer(domElement);

  container.innerText = snippet.innerText;
};

const PHENOMENON_TITLE_CLASS = '.phenomenon-title';
const PHENOMENON_CLASS = '.phenomenon';

export const replaceModelPhenomenon = (htmlElement: IModelPhenomenon, domElement: HTMLElement) => {
  const snippet = stringToHtml(htmlElement.snippet);
  const snippetTitle = snippet.querySelector(PHENOMENON_TITLE_CLASS) as HTMLElement;
  const containerTitle = findTextNodeContainer(domElement.querySelector(PHENOMENON_TITLE_CLASS));

  containerTitle.innerText = snippetTitle.innerText;

  const snippetPhenomenon = snippet.querySelector(PHENOMENON_CLASS) as HTMLElement;
  const containerPhenomenon = findTextNodeContainer(domElement.querySelector(PHENOMENON_CLASS));

  containerPhenomenon.innerText = snippetPhenomenon.innerText;
};

const STORYLINE_TITLE_CLASS = '.storyline-title';
const STORYLINE_CLASS = '.storyline';

export const replaceModelStoryline = (htmlElement: IModelStoryline, domElement: HTMLElement) => {
  const snippet = stringToHtml(htmlElement.snippet);
  const snippetTitle = snippet.querySelector(STORYLINE_TITLE_CLASS) as HTMLElement;
  const containerTitle = findTextNodeContainer(domElement.querySelector(STORYLINE_TITLE_CLASS));

  containerTitle.innerText = snippetTitle.innerText;

  const snippetPhenomenon = snippet.querySelector(STORYLINE_CLASS) as HTMLElement;
  const containerPhenomenon = findTextNodeContainer(domElement.querySelector(STORYLINE_CLASS));

  containerPhenomenon.innerText = snippetPhenomenon.innerText;
};
