import React, { useState } from 'react';
import Modal, { Footer, useModalManager } from '@/components/common/Modal';
import clsx from 'clsx';
import I18n from 'i18n-js';
import Axios from 'axios';
import { SpinnerIcon } from '@/components/common/Utils';
import unshareIcon from '../../../../../../assets/images/unshare.svg';

interface UnShareButtonProps {
  assessment: any;
  ajaxPath: string;
  fallbackPath: string;
  shared: boolean;
  grayscale?: boolean;
}

const UnShareButton = ({ assessment, ajaxPath, fallbackPath, shared, grayscale }: UnShareButtonProps) => {
  const modalManager = useModalManager();
  const [unsharing, setUnsharing] = useState(false);

  const unshareAssessment = () => {
    Axios.delete(ajaxPath)
      .then((response) => {
        modalManager.close;
        window.location.replace(fallbackPath);
      });
  };

  return (
    <>
      <button className="tw-border-none tw-bg-transparent" type="button" onClick={modalManager.open} disabled={!shared}>
        <div className={clsx('tw-flex tw-flex-row tw-items-center', {
          'tw-opacity-50': !shared,
        })}
        >
          <img
            className={clsx('tw-w-[18px] tw-mr-2', {
              'tw-grayscale': grayscale,
              'tw-ml-2': grayscale,
            })}
            src={unshareIcon}
            alt="Unshare"
          />
          {I18n.t('unshare')}
        </div>
      </button>
      <Modal
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        headerText="Unshare Assessment"
      >
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-base">
          <span>{I18n.t('unshare_assessment_message')}</span>
          <span>{I18n.t('unshare_assessment_title', { title: assessment.name })}</span>
        </div>
        <Footer
          primaryButtonText={unsharing ? <SpinnerIcon /> : 'Unshare'}
          primaryButtonDisabled={unsharing}
          primaryButtonSubmit={false}
          primaryButtonCallback={() => {
            setUnsharing(true);
            unshareAssessment();
          }}
          secondaryButtonCallback={modalManager.close}
        />
      </Modal>
    </>
  );
};

export default UnShareButton;
