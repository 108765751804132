import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useModalManager } from '@/components/common/Modal';
import ManageProgramsWizard from './ManageProgramsWizard';
import { programAccessQueryClient } from '../ProgramAccessTable/utils';

interface ManageProgramsButtonProps {
  inDropdown: boolean;
  licenseId: number;
  subscriberStateId: number;
}

const ManageProgramsButton = ({ inDropdown, licenseId, subscriberStateId } : ManageProgramsButtonProps) => {
  const modalManager = useModalManager();

  const handleOpen = (e) => {
    e.preventDefault();
    e.currentTarget.setAttribute('aria-expanded', 'true');
    modalManager.open();
  };

  const dropdownOption = () => (
    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={handleOpen}
      aria-haspopup="menu"
      aria-expanded="false"
    >
      Manage Programs
    </a>
  );

  const buttonOption = () => (
    <button type="button" onClick={handleOpen} className="btn btn--purple" aria-haspopup="menu" aria-expanded="false">
      Manage Programs
    </button>
  );

  return (
    <QueryClientProvider client={programAccessQueryClient}>
      {inDropdown ? dropdownOption() : buttonOption()}
      {modalManager.isOpen && (
        <ManageProgramsWizard modalManager={modalManager} initialLicenseId={licenseId} subscriberStateId={subscriberStateId} />
      )}
    </QueryClientProvider>
  );
};

export default ManageProgramsButton;
