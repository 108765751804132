import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Axios from 'axios';
import { plato_api_model_select_index_path } from '../../../../../../../../../../modules/routes';
import styles from '../../ElementInsert.module.scss';
import useSlideShowContext from '../../../../../../../stores/useSlideShowContext';

const SectionsSelect = ({ sectionIds, sectionType, setSectionIds }) => {
  const [rawSectionData, setRawSectionData] = useState([])
  const [sectionOptions, setSectionOptions] = useState([]);

  const lessonId = useSlideShowContext(state => state.lessonId);
  const programId = useSlideShowContext(state => state.programId);
  const unitId = useSlideShowContext(state => state.unitId);

  const handleSelectedSectionsChange = sections => setSectionIds(sections.map((section => section.value)));
  const formatSectionsForSelect = sections => (sections.map(section => ({ label: section.title, value: section.id })));

  useEffect(() => {
    Axios.get(plato_api_model_select_index_path({
      lesson_id: lessonId,
      program_id: programId,
      select_type: 'slide_builder',
      unit_id: unitId,
    })).then((response) => {
      const data = response.data.data;
      // If we're in a lesson slideshow.
      if (lessonId) {
        // Fix unitSections for lessons
        setRawSectionData(sectionType === 'Unit' ? data.lesson.unit.unitSections : data.lesson.sections);
      }
      else {
        // We're in a unit slideshow.
        setRawSectionData(sectionType === 'Unit' ? data.unit.unitSections : data.unit.sections);
      }
    });
  }, [sectionType]);

  useEffect(() => {
    if (rawSectionData === []) return;
    const sections = formatSectionsForSelect(rawSectionData);

    setSectionOptions(sections);
  }, [rawSectionData, sectionType]);

  return (
    <Fragment>
      <label htmlFor="sectionsSelect" className={`${styles.required} ${styles.labelText} mt15`}>
        Sections
      </label>
      <Select
        className={styles.sectionsSelect}
        clearable={false}
        inputProps={{ id: 'sectionsSelect' }}
        multi
        onChange={handleSelectedSectionsChange}
        openOnFocus
        options={sectionOptions}
        value={sectionIds}
        isOptionDisabled={option => option.disabled}
      />
    </Fragment>
  );
};

SectionsSelect.propTypes = {
  sectionIds: PropTypes.arrayOf(PropTypes.string),
  sectionType: PropTypes.string.isRequired,
  setSectionIds: PropTypes.func.isRequired,
};

SectionsSelect.defaultProps = {
  sectionIds: [],
};

export default SectionsSelect;
