import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';
import PathContext from 'common/Context';

import StaffForm from '../../subscribers/StaffTable/StaffForm';

export default function Edit({
  closeModal, rowData, updateStafferPath, updateTable, flags
}) {
  return (
    <Modal
      closeModal={closeModal}
      headerText="Edit Sysadmin"
      isOpen
    >
      <PathContext.Provider value={{ updatePath: updateStafferPath }}>
        <StaffForm
          closeModal={closeModal}
          newRecord={false}
          staff={rowData}
          updateTable={updateTable}
          userIsSysadmin
          autoRosterDistrict={false}
          flags={flags}
        />
      </PathContext.Provider>
    </Modal>
  );
}

Edit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  rowData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired,
    require_password_reset: PropTypes.bool,
    role: PropTypes.string.isRequired,
    sysadmin: PropTypes.bool.isRequired
  }).isRequired,
  updateStafferPath: PropTypes.string.isRequired,
  updateTable: PropTypes.func.isRequired
};
