import React from 'react';
import I18n from 'modules/i18n';

interface CivicsLibraryIconProps {
  ariaLabel?: string;
  color?: string;
  size?: number
}

const CivicsLibraryIcon = ({
  ariaLabel = I18n.t('library_civics'),
  color = '#247F99',
  size = 60
 }: CivicsLibraryIconProps) => (
  <svg
    aria-label={ariaLabel}
    width={size}
    height={size}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M50.5152 17.5926V16.0926V12.037L24.5893 0L0.515228 12.037V16.0926V17.5926H2.01523H49.0152H50.5152ZM2.01523 12.9641L24.6134 1.66499L49.0152 12.9944V16.0926H2.01523V12.9641ZM2.01523 40.3889H49.0152V48.5H2.01523V40.3889ZM0.515228 38.8889H2.01523H49.0152H50.5152V40.3889V48.5V50H49.0152H2.01523H0.515228V48.5V40.3889V38.8889ZM18.1078 20.3704H21.8115V37.037H18.1078V20.3704ZM32.9226 20.3704H29.2189V37.037H32.9226V20.3704ZM40.33 20.3704H44.0337V37.037H40.33V20.3704ZM10.7004 20.3704H6.99671V37.037H10.7004V20.3704Z" fill={color} />
  </svg>
);

export default CivicsLibraryIcon;
