import create from 'zustand';

export type IShape = {
  category: string;
  category_id: number;
  id: number;
  position: number;
  url: string;
};

interface UseShapesStoreVariables {
  categoryMap: { [key: string]: number };
  setCategoryMap: (categoryMap: { [key: string]: number }) => void;
  setShapes: (shapes: { [key: string]: IShape[] }) => void;
  shapes: { [key: number]: IShape[]; };
}

const useShapesStore = create<UseShapesStoreVariables>((set) => ({
  categoryMap: {},
  setCategoryMap: categoryMap => set({ categoryMap }),
  setShapes: shapes => set({ shapes }),
  shapeCategories: [],
  shapes: {},
}));

export default useShapesStore;
