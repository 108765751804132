import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useModalManager, SubmitModal } from 'common/Modal';
import * as Routes from 'routes';

const DeleteRule = ({ afterSubmit, programMapping }) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <button type="button" onClick={modalManager.open} className="dropdown-item full-size">
        <i className="fa fa-trash-o" aria-hidden /> Delete
      </button>
      <SubmitModal
        headerText="Delete Rule"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        method="delete"
        submitPath={Routes.plato_api_subscriber_program_mapping_path(programMapping.subscriber_id, programMapping.id)}
        afterSubmit={afterSubmit}
      >
        In the next sync, you will be removing program access that is only given through this rule.
        Are you sure you want to delete this rule?
      </SubmitModal>
    </Fragment>
  );
};

DeleteRule.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  programMapping: PropTypes.shape({
    id: PropTypes.number.isRequired,
    subscriber_id: PropTypes.number.isRequired
  }).isRequired
};

export default DeleteRule;
