import React from 'react';
import PropTypes from 'prop-types';
import LanguageToggle from '../../../common/LeftNavBarTabs/LanguageToggle';

const EnvironmentLabel = ({ environment, locale, programId, userType }) => {
  if (!['ContentManager', 'SupportManager', 'Sysadmin'].includes(userType)) return <></>;

  return (
    <div className={`environment-label ${environment}`}>
      {environment?.toUpperCase()}
      {programId &&
        (
          <LanguageToggle
            collapsed
            colorMode=""
            copyright=""
            isAdminEnvironment
            locale={locale}
          />
        )
      }
    </div>
  );
};

EnvironmentLabel.propTypes = {
  environment: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
};

export default EnvironmentLabel;
