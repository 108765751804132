import React, { LegacyRef } from 'react';
import styles from './Dropdown.module.scss';

type Positions = 'top' | 'right' | 'bottom' | 'left' | 'top-end' | 'bottom-start';
type Coordinates = { left?: number, top?: number };

type PositionProps =
  | { position: Positions, coordinates?: never }
  | { position?: never, coordinates: Coordinates };

type CommonProps = {
  children: ReactChildren;
  className?: string;
  open: boolean;
};

type DropdownMenuProps = CommonProps & PositionProps;

const DropdownMenu = React.forwardRef(({ children, className, coordinates, open, position }: DropdownMenuProps, ref: LegacyRef<HTMLDivElement>) => (
  <div
    ref={ref}
    role="menu"
    className={`${styles.dropdown} ${open ? '' : styles.closed} ${styles[position]} ${className}`}
    aria-hidden={!open}
    style={coordinates}
  >
    {children}
  </div>
));

export default DropdownMenu;
