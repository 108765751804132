import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { TextAreaField, TextField, SelectField } from 'components/common/Forms';
import { required } from 'components/common/Forms/Validators';
import { Footer } from 'components/common/Modal';
import { UPDATE_SLIDE_SHOW } from 'components/admin/SlideShow/Utils/slideShowGraphQL';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import Popover from '@/components/ui/Popover';

interface SlideShowTitleFormProps {
  isSaving: boolean;
  setIsSaving: (saving) => void;
  slideShow: {
    id: number;
    titleEn?: string;
    descriptionEn?: string;
    activityType?: string;
    themeId?: number;
  }
}

const SlideShowTitleForm = ({ isSaving, setIsSaving, slideShow }: SlideShowTitleFormProps) => {
  const updateSlideShowInStore = useSlideShowContext(state => state.updateSlideShow);
  const userType = useSlideShowContext(state => state.userType);
  const setThemeIdStore = useSlideShowContext(state => state.setThemeId);
  const themes = useSlideShowContext(state => state.themes);

  const [saved, setSaved] = useState(false);
  // Using a ref here to accurately track the saved state in the useEffect cleanup. Otherwise the state is always false.
  const savedRef = useRef(null);
  savedRef.current = saved;

  const [updateSlideShowMutation] = useMutation(UPDATE_SLIDE_SHOW);

  useEffect(() => () => {
    if (!savedRef.current) {
      setThemeIdStore(slideShow.themeId);
      return;
    }
    setSaved(false);
  }, []);

  const formattedThemes = themes.map(theme => ({
    label: theme.name,
    value: theme.id,
  }));

  const updateSlideShow = ({ title, desc, actType, theme }) => {
    const mutationParams = {
      variables: {
        id: slideShow.id,
        title,
        description: desc,
        activityType: actType,
        themeId: theme,
      },
    };

    setIsSaving(true);

    updateSlideShowMutation(mutationParams)
      .then((res) => {
        const { titleEn, titleEs, themeId } = res.data?.updateSlideShow;
        const updatedThemeId = parseInt(themeId, 10);
        updateSlideShowInStore({ titleEn, titleEs, themeId: updatedThemeId });
        setIsSaving(false);
        setSaved(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSaving(false);
      });
  };

  return (
    <>
      <div className="tw-pt-1 tw-flex tw-items-center tw-justify-between">
        <div className="tw-font-bold">Edit Slide Show Settings</div>
        <Popover.Close className="tw-outline-none tw-bg-transparent tw-border-none tw-cursor-pointer">
          <i className="fa fa-times" aria-hidden="true" />
        </Popover.Close>
      </div>
      <hr />
      <Form
        initialValues={{
          title: slideShow.titleEn,
          desc: slideShow.descriptionEn,
          actType: slideShow.activityType,
          theme: slideShow.themeId,
        }}
        onSubmit={updateSlideShow}
        render={({ handleSubmit, hasValidationErrors, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-px-4">
              <TextField
                name="title"
                placeholder="Enter title"
                label="Title"
                validate={required}
                required
              />
              <TextAreaField
                name="desc"
                placeholder="Enter description..."
                label="Description"
              />

              {userType === 'Sysadmin' && (
                <>
                  {/* Saving the options as hard coded for now while the gql epic is on going.
                  Should work on cleaning up to pull from the backend after to keep the options consistent */}
                  <SelectField
                    name="actType"
                    label="Activity Type"
                    placeholder="Select a type"
                    options={[
                      { label: 'Classroom Activity', value: 'classroom_activity' },
                      { label: 'Video Activity', value: 'video_activity' },
                      { label: 'Investigation Activity', value: 'investigation_activity' },
                      { label: 'Lesson Outline', value: 'lesson_outline' },
                      { label: 'Professional Development', value: 'professional_development' },
                      { label: 'Marketing Slideshow', value: 'marketing' },
                    ]}
                  />

                  <SelectField
                    name="theme"
                    label="Theme Id"
                    placeholder="Select a theme"
                    options={formattedThemes}
                    onChange={(e) => {
                      setThemeIdStore(e);
                    }}
                  />
                </>
              )}
            </div>

            <hr />
            <Footer
              wrapperClassName="tw-flex tw-justify-end tw-pb-2 tw-pr-2 tw-pt-0"
              primaryButtonText="Save"
              submittingText="Saving…"
              primaryButtonDisabled={hasValidationErrors || pristine}
              submitting={isSaving}
            />
          </form>
        )}
      />
    </>
  );
};

export default SlideShowTitleForm;
