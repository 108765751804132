import React, { Component } from 'react';
import Axios from 'axios';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { formatSelectOption, renderErrors, RequiredAsterisk, SubmitError } from '../../../common/Forms/Utils';
import { Footer } from '../../../common/Modal';

import styles from '../Form.module.scss';

export default class AddForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    stateList: PropTypes.instanceOf(Array).isRequired,
    createPath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      state: null,
      submitting: false
    };
  }

  getStateSelectOptions() {
    return this.props.stateList.map(state => formatSelectOption(state));
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .post(this.props.createPath, { subscriber: values })
      .then((response) => {
        this.props.updateTable(response.data.data, 'add');
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  render() {
    return (
      <Form
        mutators={{
          handleSelect: (args, state, utils) => {
            this.setState({ state: args[0].value });
            utils.changeValue(state, 'state', () => args[0].value);
          }
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          if (!values.city) {
            errors.city = 'Required';
          }
          if (!values.customer_number) {
            errors.customer_number = 'Required';
          }
          if (!this.state.state) {
            errors.state = 'Required';
          }
          return errors;
        }}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, invalid, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="sr-only">Fields marked with an asterisk (*) are required.</span>

            <div className={styles.formRow}>
              <label htmlFor="name" className={styles.label}>
               Name<RequiredAsterisk />
              </label>
              <Field
                id="name"
                name="name"
                component="input"
                placeholder="Subscriber Name"
                className={styles.textInput}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="city" className={styles.label}>
               City<RequiredAsterisk />
              </label>
              <Field
                id="city"
                name="city"
                component="input"
                className={styles.textInput}
              />
            </div>

            <Field
              name="state"
              render={() => (
                <div className={styles.formRow}>
                  <label htmlFor="state" className={styles.label}>
                    State<RequiredAsterisk />
                  </label>
                  <Select
                    searchable
                    id="state"
                    value={this.state.state}
                    options={this.getStateSelectOptions()}
                    className={styles.input}
                    onChange={form.mutators.handleSelect}
                    clearable={false}
                  />
                </div>
              )}
            />

            <div className={styles.formRow}>
              <label htmlFor="customer_number" className={styles.label}>
               Sub ID<RequiredAsterisk />
              </label>
              <Field
                id="customer_number"
                name="customer_number"
                component="input"
                className={styles.textInput}
              />
            </div>

            <div className={styles.formRow}>
              <label htmlFor="mdr_id" className={styles.label}>MDR ID</label>
              <Field
                id="mdr_id"
                name="mdr_id"
                component="input"
                className={styles.textInput}
                parse={val => (val === null ? '' : val)}
              />
            </div>

            <div className={styles.formRow}>
              <Field
                id="allow_self_serve_autoroster"
                name="allow_self_serve_autoroster"
                component="input"
                type="checkbox"
              />
              <label htmlFor="allow_self_serve_autoroster" className={styles.checkboxLabel}>
                Enable self-serve autoroster setup
              </label>
            </div>

            <div className={styles.formRow}>
              <Field
                id="overshare_enabled"
                name="overshare_enabled"
                component="input"
                type="checkbox"
              />
              <label htmlFor="overshare_enabled" className={styles.checkboxLabel}>
                Enable overshare licenses
              </label>
            </div>

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={invalid}
              submitting={this.state.submitting}
              disableWithTooltipText="Please complete all required fields."
            />
          </form>
        )}
      />
    );
  }
}
