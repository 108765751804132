import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import styles from './Switch.module.scss';
import Tooltip from '../../../../../../common/Tooltip';

const Switch = ({ checked, id, label, onChange, tooltip }) => {
  const appendEl = useRef();

  return (
    <div className={styles.label} ref={appendEl}>
      <label htmlFor={id}>
        {label}
        {tooltip && (
          <Tooltip appendTo={appendEl && appendEl.current} content={tooltip} interactive={false}>
            <i className={`${styles.tooltipIcon} fa fa-info-circle`} />
          </Tooltip>
        )}
      </label>
      <ReactSwitch
        id={id}
        onChange={onChange}
        checked={checked}
        onColor="#522e91"
        offColor="#cccccc"
        checkedIcon={false}
        uncheckedIcon={false}
        height={24}
        width={48}
        handleDiameter={20}
      />
    </div>
  );
};

Switch.defaultProps = {
  label: '',
  tooltip: null,
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default Switch;
