import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SetupMFA, { IMFASetup } from './SetupMFA';

const queryClient = new QueryClient();

const MFASetupWrapper = ({ userId, mfaEnabled }: IMFASetup) => (
  <QueryClientProvider client={queryClient}>
    <SetupMFA userId={userId} mfaEnabled={mfaEnabled} />
  </QueryClientProvider>
);

export default MFASetupWrapper;
