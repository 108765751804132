import { createStore } from 'zustand';
import { GradesTableProps } from '../types';
import * as Interfaces from '../types';

interface UseGradesTableVariables {
  classroom: Interfaces.IClassroom;
  isImpersonating: boolean;
  isSectionNotebooksExpanded: boolean;
  isConnectedToCanvas: boolean;
  lesson: Interfaces.ILesson;
  programId: number;
  stafferId: number;
  unit: Interfaces.IUnit;
  students: Interfaces.IStudent[];
  videoActivity: Interfaces.INotebook;
  gameExerciseResults: Interfaces.IExerciseResult[];
  lowScoresSettings: Interfaces.ILowScoresSettings;
}

export interface UseGradesTableState extends UseGradesTableVariables {
  setStore: (nextState: Partial<UseGradesTableVariables>) => void;
}

const createGradesTableStore = (props: GradesTableProps) => createStore<UseGradesTableState>()(set => ({
  ...props,
  isSectionNotebooksExpanded: false,
  gameExerciseResults: [],
  videoActivity: {} as Interfaces.INotebook,
  students: [],
  setStore: nextState => set(prevState => ({ ...prevState, ...nextState })),
}));

export default createGradesTableStore;
