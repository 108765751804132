import create from 'zustand';

interface IDragAndDropStoreVariables {
  isGloballyDragging: boolean;
  resizerScaleValue: number;
  setIsGloballyDragging: (isGloballyDragging: boolean) => void;
  setResizerScaleValue: (resizerScaleValue: number) => void;
}

const useDragAndDropStore = create<IDragAndDropStoreVariables>((set) => ({
  isGloballyDragging: false,
  resizerScaleValue: 1,
  setIsGloballyDragging: (isGloballyDragging: boolean) => set({ isGloballyDragging }),
  setResizerScaleValue: (resizerScaleValue: number) => set({ resizerScaleValue }),
}));

export default useDragAndDropStore;
