const variableClassNames = [
  'model-essential-question',
  'model-number',
  'model-title',
  'phenomenon-title',
  'phenomenon',
  'storyline',
];

export const isVariableElement = (element: HTMLElement | Text) => {
  if (element.nodeType === Node.TEXT_NODE) return false;

  return variableClassNames.some(cn => (element as HTMLElement).classList.contains(cn));
};
