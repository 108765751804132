import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Axios from 'axios/index';
import videoPropTypes from 'common/VideoPlayer/Proptypes';
import TTSButton from 'shared/TextToSpeechButton';
import AutoSave from './AutoSave';
import MultipleChoiceOption from './MultipleChoiceOption';
import QuestionLetter from './QuestionLetter';
import {
  formatTimestamp, renderImage, renderStatusMessage, renderVideo
} from './QuestionUtils';
import styles from './MultipleChoiceQuestion.module.scss';
import submit, { getPreviousAnswers } from './Questions/submit';
import SubmissionTextHelper from './SubmissionTextHelper';
import clsx from 'clsx';
import ResetReadingGameBtn from '../ResetReadingGameBtn';

export default class RadioGroupWithAutoSave extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    correctChoices: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    gif: PropTypes.shape({
      imageUrl: PropTypes.string,
      width: PropTypes.number
    }),
    image: PropTypes.shape({
      imageUrl: PropTypes.string,
      width: PropTypes.number
    }),
    isInReadingGame: PropTypes.bool,
    isInSlide: PropTypes.bool,
    legendText: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
    maxAttempts: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    points: PropTypes.number,
    preview: PropTypes.bool.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTTSEnabled: PropTypes.bool,
    radioButtonId: PropTypes.string.isRequired,
    radioButtonName: PropTypes.string.isRequired,
    randomized: PropTypes.bool,
    readingGameId: PropTypes.number,
    saveDebounce: PropTypes.number,
    savedResult: PropTypes.shape({
      answer: PropTypes.string,
      correctChoices: PropTypes.arrayOf(PropTypes.string),
      previousAnswers: PropTypes.arrayOf(PropTypes.string),
      updated_at: PropTypes.string
    }),
    submitForm: PropTypes.bool,
    useReadingGameResetBtn: PropTypes.bool,
    useSubmitButton: PropTypes.bool,
    video: videoPropTypes,
  };

  static defaultProps = {
    correctChoices: [],
    disabled: false,
    gif: null,
    image: null,
    isInReadingGame: false,
    isInSlide: false,
    legendText: '',
    points: 0,
    questionTTSEnabled: true,
    randomized: false,
    readingGameId: undefined,
    saveDebounce: 800,
    savedResult: null,
    submitForm: false,
    useReadingGameResetBtn: false,
    useSubmitButton: false,
    video: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      correctChoices: props.correctChoices,
      pointsEarned: (props.savedResult && parseFloat(props.savedResult.question_answer.score)) || 0,
      previousAnswers: getPreviousAnswers(this.props.savedResult),
      status: this.props.savedResult && this.props.savedResult.updated_at ? '200' : null,
      submitting: false,
      updatedAt: this.props.savedResult && formatTimestamp(this.props.savedResult.updated_at, false),
      value: this.props.savedResult && this.props.savedResult.answer
    };

    this.save = this.save.bind(this);
    this.setNewState = this.setNewState.bind(this);
  }

  setNewState(data) {
    this.setState(data);
  }

  // Append index of radio button to the given id
  getRadioButtonId(index) {
    return `${this.props.radioButtonId}_${index}`;
  }

  save = async (values) => {
    if (!this.props.submitForm) return;

    this.setState({ status: null, submitting: true });

    await Axios
      .post(this.props.ajaxUrl, { question_answer: values })
      .then((response) => {
        const data = response.data;
        if (data.errors) {
          this.setState({ status: '500' });
          console.log(data.errors);
        }
        else {
          const formattedDate = formatTimestamp(data.question_answer.updated_at, true);

          this.setState({
            status: '200',
            updatedAt: formattedDate,
            value: data.question_answer.answer
          });
        }
      })
      .catch((error) => {
        this.setState({ status: '400' });
        console.log(error);
      })
      .then(() => this.setState({ submitting: false }));
  };

  renderRadioButtons() {
    return this.props.options.map((option, index) => {
      const key = option.key;
      if (key === '') return null;

      const isAtMaxAttempts = this.state.previousAnswers.length >= this.props.maxAttempts;
      const hasCorrectAnswer = this.state.previousAnswers.length > 0 && this.state.pointsEarned === this.props.points;
      const isInPreviousAnswers = this.state.previousAnswers.includes(index.toString());

      const isDisabled = this.props.disabled || isAtMaxAttempts || hasCorrectAnswer || isInPreviousAnswers;

      return (
        <span key={`${this.props.questionId}-${index}`}>
          <QuestionLetter index={index} />
          <Field
            disabled={isDisabled}
            component={MultipleChoiceOption}
            id={this.getRadioButtonId(index)}
            key={`${this.props.radioButtonId}_${key}`}
            label={key}
            name={this.props.radioButtonName}
            type="radio"
            value={option.answer_value.toString()}
          />
        </span>
      );
    });
  }

  renderTTSButton = () => {
    if (!this.props.questionTTSEnabled) return null;

    return (
      <TTSButton
        locale={this.props.locale}
        idToRead={`question_${this.props.questionId}`}
      />
    );
  };

  renderVideo = () => {
    if (this.props.isInReadingGame) return renderVideo({ video: this.props.video, width: '550', minWidth: '550' })

    return renderVideo({ video: this.props.video, width: '100%', minWidth: 'unset', maxWidth: '400' });
  }

  render() {
    const submissionTextHelper = new SubmissionTextHelper({
      correctChoices: this.state.correctChoices,
      disabled: this.props.disabled,
      hasSubmitted: this.state.previousAnswers.length > 0,
      maxAttempts: this.props.maxAttempts,
      pointsEarned: this.state.pointsEarned,
      previousAnswers: this.state.previousAnswers,
      submitting: this.state.submitting,
      value: this.state.value,
    });

    return (
      <Form
        initialValues={{
          answer: this.state.value,
          question_id: this.props.questionId
        }}
        keepDirtyOnReinitialize
        onSubmit={this.save}
        subscription={{}}
        render={({ handleSubmit }) => (
          <>
            <form
              className={clsx({
                'has-image': !!this.props.image || !!this.props.gif || !!this.props.video,
                'reading-game-question': this.props.isInReadingGame,
                'slide-question': this.props.isInSlide,
              })}
              data-answered={!!this.state.value}
              data-question-type="MultipleChoiceQuestion"
              onSubmit={handleSubmit}
            >
              {!this.props.preview && <AutoSave debounce={this.props.saveDebounce} save={this.save} />}

              <fieldset id={`question_${this.props.questionId}`}>
                <legend
                  className="expandable_question question-toggle allow-list-style"
                  data-accordian-pair-id={this.props.questionId}
                >
                  <span
                    className="respect_breaks"
                    dangerouslySetInnerHTML={{ __html: this.props.legendText }}
                  />
                  {this.renderTTSButton()}
                  {!this.props.isInSlide && renderStatusMessage(this.state.status, this.state.submitting, this.state.updatedAt, 'Assessment')}
                </legend>

                <div
                  className="js-question-details mb20"
                  data-accordian-pair-id={this.props.questionId}
                >
                  {renderImage(this.props.image)}
                  {renderImage(this.props.gif)}
                  {this.renderVideo()}
                  <ol className="mc-question-group-list upper-alpha">
                    {this.renderRadioButtons()}
                  </ol>
                </div>
              </fieldset>
            </form>
            {this.props.useSubmitButton && !this.props.preview && (
              <div className={clsx(styles.submitBtnContainer, 'submit-btn-container')}>
                {submissionTextHelper.submissionAlert()}
                <div className="tw-flex tw-gap-4">
                  <button
                    className="btn btn--purple"
                    disabled={submissionTextHelper.cannotSubmit()}
                    type="button"
                    onClick={() => (
                      submit({
                        ajaxUrl: this.props.ajaxUrl,
                        questionId: this.props.questionId,
                        setNewState: this.setNewState,
                        value: this.state.value,
                      })
                    )}
                  >
                    {I18n.t('submit')}
                  </button>
                  {this.props.useReadingGameResetBtn && this.props.readingGameId && (
                    <ResetReadingGameBtn
                      disabled={!this.state.value && this.state.previousAnswers.length === 0}
                      readingGameId={this.props.readingGameId}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      />
    );
  }
}
