import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import { formatLocalTimestampWithFormat } from 'common/Utils';
import styles from './SaveStatus.module.scss';

function getClass(props) {
  if (props.saving) return 'gray';
  if (props.saveError) return 'red';
  if (props.savedTime) return styles.savedTime;

  return 'gray';
}

function getTimestamp(props) {
  if (props.saving) return `${I18n.t('saving')}...`;
  if (props.saveError) return I18n.t('save_error');
  if (props.savedTime) {
    return I18n.t('turned_in_at', { time: formatLocalTimestampWithFormat(props.savedTime, 'hh:mm A') });
  }

  if (props.lastUpdated) {
    return I18n.t('turned_in_on', { timestamp: formatLocalTimestampWithFormat(props.lastUpdated, 'MMMM DD, hh:mm A') });
  }

  return '';
}

export default function SaveStatus(props) {
  return (
    <span className={`${styles.text} ${getClass(props)}`}>
      {getTimestamp(props)}
    </span>
  );
}

SaveStatus.propTypes = {
  lastUpdated: PropTypes.string,
  saveError: PropTypes.bool,
  savedTime: PropTypes.instanceOf(Date),
  saving: PropTypes.bool.isRequired
};

SaveStatus.defaultProps = {
  lastUpdated: null,
  saveError: null,
  savedTime: null
};
