import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import printIcon from './print-icon.svg';
import printIconImage from './print.png';
import styles from './PrintButton.module.scss';

export default function PrintButton(props) {
  return (
    <Tooltip
      content={props.tooltipText}
      theme={props.tippyTheme}
      size="medium"
      distance="15"
      placement={props.placement}
      interactive={false}
    >
      <a
        className={styles[props.wrapperClass]}
        href={props.pdfPath}
        target="_blank"
        aria-label={props.tooltipText}
        rel="noopener noreferrer"
      >
        <span className={styles.print}>
          <img
            className={styles.printIcon}
            src={(props.icon === 'print.png' ?
                  printIconImage : props.icon) || printIcon}
            alt="Printer icon - a simple representation of a printer with paper output tray, and paper sheets."
          />
        </span>
        <span className="sr-only">{props.tooltipText}</span>
      </a>
    </Tooltip>
  );
}

PrintButton.propTypes = {
  icon: PropTypes.string,
  pdfPath: PropTypes.string.isRequired,
  placement: PropTypes.string,
  tippyTheme: PropTypes.string,
  tooltipText: PropTypes.string,
  wrapperClass: PropTypes.string
};

PrintButton.defaultProps = {
  icon: null,
  placement: 'top',
  tippyTheme: 'white',
  tooltipText: 'Print Text',
  wrapperClass: ''
};
