import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useModalManager } from 'common/Modal';
import useCompletedStatus from '@/hooks/useCompletedStatus';
import { plato_api_question_answers_path, plato_api_slide_object_path } from '@/modules/routes';
import CompletedStatusIcon from '@/components/common/HtmlElements/QuestionButton/CompletedStatusIcon';
import styles from './Slide.module.scss';
import { slideObjectPropTypes } from '../Utils';
import TypeSelector from './TypeSelector';
import ContentActions from './ContentActions';
import SlideObjectText from './Content/SlideObjectText';
import SlideObjectImage from './Content/SlideObjectImage';
import SlideObjectHTMLEditor from './Content/SlideObjectHTMLEditor';
import SlideObjectVideo from './Content/SlideObjectVideo';
import SlideObjectQuestions from './Content/SlideObjectQuestions';
import SlideObject3DModel from './Content/SlideObject3DModel';
import SlideObjectPanoramicImage from './Content/SlideObjectPanoramicImage';
import SlideObjectInteractiveMap from './Content/SlideObjectInteractiveMap';
import './froalaStyles.css';
import useSlideShowStore from '../stores/useSlideShowStore';
import useSlideShowContext from '../stores/useSlideShowContext';
import useSlideObjectRemover from './useSlideObjectRemover';

const ContentContainer = ({
  index, interactable, isSaving, isTemplate, isVideoActivity, jumpToSlide,
  setIsSaving, slideId, slideObject, thumbnail, slideObjects, slideTemplateStyles
}) => {
  // create a modal manager to handle opening modals right when a type is selected
  const modalManager = useModalManager();
  const [hasError, setHasError] = useState(false);
  const styleDivRef = useRef();
  const setHasUnsavedChanges = useSlideShowStore(state => state.setHasUnsavedChanges);
  const locale = useSlideShowContext(state => state.locale);
  const recentlyAnsweredQuestionId = useSlideShowContext(state => state.recentlyAnsweredQuestionId);
  const removeUnansweredQuestions = useSlideShowContext(state => state.removeUnansweredQuestion);
  const questionAnswersPath = plato_api_question_answers_path();
  const lessonId = useSlideShowContext(state => state.lessonId);
  const [threeJsCamera, setThreeJsCamera] = useState();
  const [threeJsControls, setThreeJsControls] = useState();
  const updateSlideObjectInContext = useSlideShowContext(state => state.updateSlideObject);
  const userType = useSlideShowContext(state => state.userType);

  const threeJsOnSuccess = useCallback(({ camera, controls }) => {
    setThreeJsCamera(camera);
    setThreeJsControls(controls);
  }, []);

  const [panoramicCamera, setPanoramicCamera] = useState({ yaw: 0, pitch: 0 });

  useSlideObjectRemover(slideObject, slideObjects, interactable, isTemplate);

  const { refetch } = useCompletedStatus({ id: (slideObject || {}).id, modelType: 'SlideObject' });
  const answerableQuestionIds = (slideObject || {}).answerableQuestionIds || [];

  useEffect(() => {
    if (interactable || thumbnail) return; // return if in builder

    axios.interceptors.response.use((response) => {
      const { config: { method, url } } = response;
      if (method === 'post' && url.includes(questionAnswersPath)) {
        const { data: { question_answer: questionAnswer } } = response;
        const { question_id: questionId, submitted } = (questionAnswer || {});

        if (submitted) removeUnansweredQuestions(questionId);
        if (answerableQuestionIds.includes(questionId?.toString())) refetch();
      }

      return response;
    }, error => Promise.reject(error));

    if (recentlyAnsweredQuestionId && answerableQuestionIds.includes(recentlyAnsweredQuestionId.toString())) refetch();
  }, [axios, questionAnswersPath, refetch, answerableQuestionIds, recentlyAnsweredQuestionId, slideObject]);

  const updateSlideObject = ({ html }) => {
    setIsSaving(true);
    axios.patch(plato_api_slide_object_path(slideObject.id), { html, locale })
      .then((res) => {
        updateSlideObjectInContext(res.data.slideObject);
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
      })
      .finally(() => {
        setIsSaving(false);
        setHasUnsavedChanges(false);
      });
  };

  const renderObjectContent = () => {
    if (!slideObject) return null;

    if (slideObject.modelType === 'Text') {
      return (
        <SlideObjectText
          isEditable
          interactable={interactable}
          isSaving={isSaving}
          slideObject={slideObject}
          thumbnail={thumbnail}
          updateSlideObject={updateSlideObject}
          jumpToSlide={jumpToSlide}
          hasError={hasError}
          setHasError={setHasError}
          setIsSaving={setIsSaving}
          userType={userType}
        />
      );
    }

    if (slideObject.modelType === 'HTML') {
      return (
        <SlideObjectHTMLEditor
          hasError={hasError}
          interactable={interactable}
          isSaving={isSaving}
          modalManager={modalManager}
          setHasError={setHasError}
          slideObject={slideObject}
          updateSlideObject={updateSlideObject}
        />
      );
    }

    if (slideObject.modelType === 'Image') {
      return (
        <SlideObjectImage
          interactable={interactable}
          modalManager={modalManager}
          slideObject={slideObject}
        />
      );
    }

    if (slideObject.modelType === 'Video') {
      return (
        <SlideObjectVideo
          key={slideObject.id}
          interactable={interactable}
          modalManager={modalManager}
          slideObject={slideObject}
          thumbnail={thumbnail}
          userType={userType}
        />
      );
    }

    if (slideObject.modelType === 'Question') {
      return (
        <SlideObjectQuestions
          interactable={interactable}
          isSaving={isSaving}
          isVideoActivity={isVideoActivity}
          hasError={hasError}
          setHasError={setHasError}
          slideObject={slideObject}
          modalManager={modalManager}
          setIsSaving={setIsSaving}
          thumbnail={thumbnail}
          progressIcon={renderProgressIcon()}
          lessonId={lessonId}
        />
      );
    }

    if (slideObject.modelType === '3DModel') {
      return (
        <SlideObject3DModel
          interactable={interactable}
          modalManager={modalManager}
          slideObject={slideObject}
          onSuccess={threeJsOnSuccess}
          thumbnail={thumbnail}
        />
      );
    }

    if (slideObject.modelType === 'PanoramicImage') {
      return (
        <SlideObjectPanoramicImage
          containerRef={styleDivRef}
          interactable={interactable}
          modalManager={modalManager}
          slideObject={slideObject}
          setPanoramicCamera={setPanoramicCamera}
          thumbnail={thumbnail}
        />
      );
    }

    if (slideObject.modelType === 'InteractiveMap') {
      return (
        <SlideObjectInteractiveMap
          interactable={interactable}
          modalManager={modalManager}
          slideObject={slideObject}
        />
      );
    }

    return <div>Not Implemented: {slideObject.modelType}</div>;
  };

  const renderActionDropdown = () => {
    // if the SlideTemplateObject has a type, render the purple caret dropdown
    if (slideObject) {
      return (
        <ContentActions
          isEditable
          threeJsCamera={threeJsCamera}
          threeJsControls={threeJsControls}
          panoramicCamera={panoramicCamera}
          modalManager={modalManager}
          slideObject={slideObject}
          styleDivRef={styleDivRef}
          thumbnail={thumbnail}
        />
      );
    }

    // If the SlideTemplateObject does not have a type, render the green "Select Type" dropdown
    return (
      <TypeSelector
        index={index}
        modalManager={modalManager}
        slideId={slideId}
      />
    );
  };

  const renderObjectContainer = () => {
    if (isTemplate) return null;

    return (
      <Fragment>
        {interactable && renderActionDropdown()}
        {renderObjectContent()}
      </Fragment>
    );
  };

  const renderProgressIcon = () => {
    if (!slideObject || slideObject.modelType !== 'Question') return;

    return (
      <div className={styles.slideObjectStatusIconWrapper}>
        <CompletedStatusIcon id={slideObject.id} modelType="SlideObject" />
      </div>
    );
  };

  // return `.slideObject` if slideTemplateObject is not for a template
  // return `.inBuilder` if slideTemplateObject is being viewed for the builder
  const slideObjectClasses = (`${!isTemplate && styles.slideObject} ${interactable && styles.inBuilder}`);

  const getSlideObjectHeight = () => {
    if (['Image', 'HTML', 'Video', 'Question'].includes(slideObject.modelType)) {
      return '100%';
    }

    return '';
  };

  const getSlideObjectBgContainerStyles = () => {
    if (!slideObject) return { padding: 0 };

    return {
      backgroundColor: slideObject.backgroundColor ?
        `${slideObject.backgroundColor}${slideObject.opacity}` : '',
      boxSizing: 'border-box',
      display: slideObject.modelType === 'Video' ? 'flex' : '',
      height: getSlideObjectHeight(),
      justifyContent: slideObject.modelType === 'Video' ? 'center' : '',
      overflow: 'auto',
      padding: slideObject.padding,
    };
  };

  const divToStyle = () => {
    if (!styleDivRef) return null;
    if (!styleDivRef.current) return null;
    if (!slideObject) return null;

    return styleDivRef.current;
  };

  const setBorderStyles = () => {
    divToStyle().style.borderStyle = 'solid';

    if (slideObject && slideObject.borderWidth) {
      divToStyle().style.borderWidth = `${slideObject.borderWidth}px`;
    } else {
      divToStyle().style.borderWidth = '';
    }

    if (slideObject && slideObject.borderRadius) {
      divToStyle().style.borderRadius = `${slideObject.borderRadius}px`;
    } else {
      divToStyle().style.borderRadius = '';
    }

    if (slideObject && slideObject.borderColor) {
      const borderColor = `${slideObject.borderColor}${slideObject.borderOpacity}`;
      divToStyle().style.borderColor = borderColor;
    } else {
      divToStyle().style.borderColor = '';
    }
  };

  useEffect(() => {
    if (!slideObject || !divToStyle()) return;

    setBorderStyles();

    return () => {}
  }, [slideObject, divToStyle()]);

  return (
    <div style={slideTemplateStyles} className="tw-absolute">
      {slideObject && !slideObject.isDragAndDrop && renderProgressIcon()}
      <div
        className={
          `slideObjectAnchor ${styles.slideTemplateObject} ${slideObjectClasses}
          ${!slideObject ? styles.empty : ''}`
        }
      >
        <div
          className={styles.slideObjectBackgroundContainer}
          ref={styleDivRef}
          style={getSlideObjectBgContainerStyles()}
        >
          {renderObjectContainer()}
        </div>
      </div>
    </div>
  );
};

ContentContainer.propTypes = {
  index: PropTypes.number.isRequired,
  interactable: PropTypes.bool,
  isSaving: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isVideoActivity: PropTypes.bool,
  jumpToSlide: PropTypes.func,
  slideTemplateStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    height: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }).isRequired,
  setIsSaving: PropTypes.func,
  slideId: PropTypes.string.isRequired,
  slideObject: slideObjectPropTypes,
  slideObjects: PropTypes.arrayOf(slideObjectPropTypes),
  thumbnail: PropTypes.bool,
};

ContentContainer.defaultProps = {
  interactable: false,
  isSaving: true,
  isTemplate: true,
  isVideoActivity: false,
  jumpToSlide: () => { },
  setIsSaving: () => { },
  slideObject: null,
  thumbnail: false,
};

export default ContentContainer;
