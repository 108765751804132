import React from 'react';
import * as Routes from '@/modules/routes';
import GradeCell from './index';
import useSectionNotebookExerciseResults
  from '../../../hooks/queries/exerciseResults/useSectionNotebookExerciseResults';
import useLessonNotebooks
  from '../../../hooks/queries/exerciseResults/useLessonNotebooks';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { COLOR_LEGEND } from '../../../variables';
import { INotebook, IStatus } from '../../../types';
import useClassroomLesson from '../../../hooks/queries/useClassroomLesson';
import { roundScore } from './utils';
import useSectionNotebooksQuestionAnswers
  from '../../../hooks/queries/questionAnswers/useSectionNotebooksQuestionAnswers';
import { gradingBySection } from '@/components/staffer/Scores/GradesTable/Utils';

interface SectionNotebookGradeCellProps {
  studentId: number,
  notebook: INotebook,
  sectionNotebookMaxScore: number;
}

const SectionNotebookGradeCell = ({ studentId, notebook, sectionNotebookMaxScore }: SectionNotebookGradeCellProps) => {
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);

  const { data: exerciseResults } = useSectionNotebookExerciseResults();
  const { data: lessonNotebookExerciseResults } = useLessonNotebooks();
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const { data: allSectionNotebooksProgress } = useSectionNotebooksQuestionAnswers();

  const exerciseResult = exerciseResults.find(er => (
    er.student_id === studentId
    && er.exercise_id === notebook.id
  ));

  const lessonExerciseResult = lessonNotebookExerciseResults.find(er => (er.student_id === studentId));
  const notebookQuestionDataForStudent = studentId in allSectionNotebooksProgress && allSectionNotebooksProgress[studentId].length > 0;
  const sectionNotebookProgressForStudent = notebookQuestionDataForStudent && allSectionNotebooksProgress[studentId]?.includes(notebook.id);

  const getNotebookStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    if (gradingBySection(classroomLesson) && exerciseResult) {
      if (exerciseResult.score) {
        const score = roundScore(exerciseResult.score);
        const lowScoreThreshold = sectionNotebookMaxScore * lowScoresSettings.threshold / 100;
        const shouldHighlight = lowScoresSettings.enabled && score <= lowScoreThreshold;

        status.color = shouldHighlight ? COLOR_LEGEND.low_score : '';
        status.text = score;
      } else if (exerciseResult.completed_at) {
        // If exercise has been submitted/completed:
        status.color = COLOR_LEGEND.completed;
        status.text = 'Turned In';
      } else {
        // There is an exercise result but no score and has not been submitted:
        status.color = COLOR_LEGEND.in_progress;
        status.text = 'In Progress';
      }
      return status;
    }

    // If the lesson notebook has been graded, don't return a color
    if ((lessonExerciseResult?.score) || (!exerciseResult && !sectionNotebookProgressForStudent)) {
      return status;
    }

    // If an exercise result exists, it's either turned in or in progress.
    status.text = exerciseResult?.completed_at ? 'Turned In' : 'In Progress';
    status.color = exerciseResult?.completed_at ? COLOR_LEGEND.completed : COLOR_LEGEND.in_progress;

    return status;
  };

  const url = Routes.grade_by_student_shared_question_answers_path(studentId, {
    program_id: programId,
    classroom_id: classroom.id,
    lesson_id: lesson.id,
    anchor: `section-${notebook.section_id}-header`,
  });

  const notebookStatus = getNotebookStatus();

  return (
    <GradeCell
      id={`section-notebook-${notebook.id}-student-${studentId}-score`}
      status={notebookStatus}
      url={url}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default SectionNotebookGradeCell;
