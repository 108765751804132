import React from 'react';
import PropTypes from 'prop-types';

import AddVideo from '../../../../../../VideoManager/Add';
import { renderYoutubeIframe } from '../../../../../../VideoManager/Utils';
import { slideObjectPropTypes } from '../../../../../Utils';
import { insertIntoEditor } from '../FroalaListeners';

const CustomVideoModal = ({ editorInstance, fromButton, modalManager, model, modelType, userType }) => {
  const videoTag = video => (
    <span
      className="fr-video fr-dvi fr-draggable fr-deletable"
      contentEditable="false"
    >
      <video
        key={video.url}
        controls="pause"
        controlsList="nodownload"
        poster={video.posterUrl}
        preload="metadata"
        disablePictureInPicture
        muted={video.is_silent}
        width="240px"
        style={{ background: 'transparent' }}
      >
        {video.captionUrl && <track label="English" kind="captions" srcLang="en" src={video.captionUrl} />}
        {video.captionEsUrl && <track label="Español" kind="captions" srcLang="es" src={video.captionEsUrl} />}
        <source src={video.url} />
      </video>
    </span>
  );

  const onYoutubeSubmit = (formValues) => {
    const resizableVideo = (
      <span
        className="fr-video fr-deletable fr-rv fr-fvc fr-dvb fr-draggable fr-active"
        contentEditable="false"
        draggable="true"
      >
        {renderYoutubeIframe(formValues.youtube_video_url, 'textObjectYoutubeVideo', '240px')}
      </span>
    );
    insertIntoEditor(editorInstance, resizableVideo, false, false, fromButton);
    modalManager.close();
  };

  return (
    <AddVideo
      afterSubmit={modelVideo => insertIntoEditor(editorInstance, videoTag(modelVideo.video), false, false, fromButton)}
      showYoutubeOption
      modelId={model.id}
      modalManager={modalManager}
      modelType={modelType}
      onYoutubeSubmit={onYoutubeSubmit}
      standaloneButton={false}
      userType={userType}
      fromButton={fromButton}
    />
  );
};

CustomVideoModal.propTypes = {
  editorInstance: PropTypes.shape({
    $el: PropTypes.shape({ get: PropTypes.func.isRequired }),
    html: PropTypes.shape({ insert: PropTypes.func.isRequired }),
    selection: PropTypes.shape({
      restore: PropTypes.func.isRequired,
      setAtEnd: PropTypes.func.isRequired,
    }),
    undo: PropTypes.shape({ saveStep: PropTypes.func.isRequired }),
  }),
  fromButton: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  model: slideObjectPropTypes.isRequired,
  modelType: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired
};

CustomVideoModal.defaultProps = {
  editorInstance: {
    $el: { get: () => {} },
    html: { insert: () => {} },
    selection: { restore: () => {}, setAtEnd: () => {} },
    undo: { saveStep: () => {} },
  },
  fromButton: false,
};

export default CustomVideoModal;
