import React from 'react';
import PropTypes from 'prop-types';
import BulkRemoveProgramsForm from '../BulkRemoveProgramsForm';
import Modal from '../../../../common/Modal';
import styles from './RemovePrograms.module.scss';

export default function RemovePrograms({
  bulkProgramRemove, closeModal, modalOpen, stafferIds, subscriberId
}) {
  const headerMessage = 'Please select the current programs you would like to' +
    ' remove from your selected teachers.';

  return (
    <Modal
      closeModal={closeModal}
      isOpen={modalOpen}
      headerText="Remove Programs"
    >
      <div className={`${styles.modalSubheading}`}>
        {headerMessage}
      </div>
      <BulkRemoveProgramsForm
        bulkProgramRemove={bulkProgramRemove}
        closeModal={closeModal}
        stafferIds={stafferIds}
        subscriberId={subscriberId}
      />
    </Modal>
  );
}

RemovePrograms.propTypes = {
  bulkProgramRemove: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  stafferIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  subscriberId: PropTypes.number.isRequired,
};
