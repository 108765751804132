import React, { useEffect, useState } from 'react';
import { Position, Viewer } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import { useDebounceCallback } from 'usehooks-ts';
import usePanoramicImageStore
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectPanoramicImage/PanoramicImage/usePanoramicImageStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import {
  hotspotsToMarkers,
} from '@/components/admin/SlideShow/Slide/Content/SlideObjectPanoramicImage/PanoramicImage/PanoramicImage';
import IHotspot from '@/components/interfaces/Hotspot';
import ReactDOM from 'react-dom';
import HiddenHotspots from './HiddenHotspots';

interface PanoramicImageViewerProps {
  panoramicImage: IPanoramicImage;
  setPanoramicCamera: (pos: Position) => void;
  locale?: 'en' | 'es';
}

const PanoramicImageViewer = ({
  panoramicImage, locale, setPanoramicCamera,
}: PanoramicImageViewerProps) => {
  const markers = panoramicImage.hotspots;
  const viewerId = `pano-${panoramicImage.id}-viewer`;
  const updateDefaultView = useDebounceCallback(setPanoramicCamera, 50);
  const setContainer = usePanoramicImageStore(state => state.setContainer);

  const slideId = useSlideShowContext(state => state.currentSlide.id);

  useEffect(() => {
    const hotspots = markers.map((marker) => {
      const domElement = document.querySelector(`.hotspot-${marker.id}`);

      if (marker.placed && domElement) {
        return ({
          ...marker,
          element: domElement as HTMLElement,
        });
      }

      return null;
    }).filter(Boolean);

    // eslint-disable-next-line no-new
    const panoViewer = new Viewer({
      container: viewerId,
      panorama: panoramicImage.image.url ?? '/missing.png',
      keyboard: 'always',
      navbar: ['zoom', 'fullscreen'],
      plugins: [
        [MarkersPlugin, { markers: hotspots }],
      ],
    });
    const markersPlugin = panoViewer.getPlugin(MarkersPlugin) as MarkersPlugin;

    const defaultView = panoramicImage.default_view;
    if (defaultView.yaw && defaultView.pitch) panoViewer.rotate(panoramicImage.default_view);

    // Add content to the <canvas> element for accessibility:
    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_usage#accessible_content
    panoViewer.addEventListener('ready', () => {
      const { image: { description_en, description_es } } = panoramicImage;
      const description = locale === 'es' ? description_es || description_en : description_en;
      $(`#${viewerId} canvas`).text(description || 'Panoramic image');
    });
    panoViewer.addEventListener('position-updated', ({ position }) => {
      updateDefaultView(position);
    });

    panoViewer.addEventListener('fullscreen', () => {
      const currentSlide = document.getElementById(`${slideId}`);
      markersPlugin.clearMarkers();

      const container = document.getElementById(`hiddenHotspots${panoramicImage.id}`);
      ReactDOM.unmountComponentAtNode(container);

      ReactDOM.render(
        (<HiddenHotspots panoramicImage={panoramicImage} locale={locale} />),
        container,
      );

      if (document.fullscreenElement && document.fullscreenElement.id.includes('pano')) {
        currentSlide.style.transform = 'none';
      } else {
        window.dispatchEvent(new Event('pano-fullscreen'));
      }
      markersPlugin.setMarkers(hotspotsToMarkers(hotspots));
    });

    setContainer(panoViewer.container);
    return () => panoViewer.destroy();
  }, [markers]);

  return (
    <>
      <div id={`hiddenHotspots${panoramicImage.id}`} />
      <HiddenHotspots panoramicImage={panoramicImage} locale={locale} />
      <div
        id={viewerId}
        style={{ width: '100%', height: '100%', position: 'relative' }}
        key={viewerId}
      />
    </>
  );
};

export default PanoramicImageViewer;
