import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';

export default class RunSync extends Component {
  static propTypes = {
    subscriber_id: PropTypes.number,
    run_sync_url: PropTypes.string
  };
  static defaultProps = {
    subscriber_id: null,
    run_sync_url: ''
  };
  constructor(props) {
    super(props);
    this.state = {};
    // bind functions
    this.runSync = this.runSync.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.props.closeModal();
  }
  runSync() {
    Axios.get(this.props.run_sync_url)
      .then(() => {
        this.setState({
          is_running: true
        });
        this.closeModal();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_running: false });
      });
  }
  render() {
    if (this.state.error) {
      return (
        <div className="error">There was an error. Please reload the page and try again.</div>
      );
    }
    return (
      <div>
        <h1>Are you sure you want to run a roster sync for this district?</h1>
        <ul className="bullet_list pt20">
          <li>This sync can take up to a few hours</li>
          <li>This sync may remove access to programs from teachers and students</li>
          <li>Remember to check if there is already a sync running from this district</li>
        </ul>
        <footer>
          <button
            className="btn btn--link-purple"
            type="button"
            value="Cancel"
            onClick={this.closeModal}
          >Cancel
          </button>
          <button
            className={`btn btn--purple ${this.state.is_running ? 'disabled' : ''}`}
            onClick={this.runSync}
          >Run Sync
          </button>
        </footer>
      </div>
    );
  }
}
