import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ElementInsert.module.scss';

// Content editable span wrapped in another span to allow deleting button and hint in one action.
const PlusButton = ({ hintText }) => (
  <span>
    <span className="hint" role="button" contentEditable={false} data-button-type="hint">
      <i className={`fa fa-plus-circle hint-icon ${styles.hintIcon}`} role="button" contentEditable={false} />
      <br />
      <span className={`hint-text ${styles.hintText}`} contentEditable={false}>
        <span dangerouslySetInnerHTML={{ __html: hintText }} />
      </span>
    </span>
  </span>
);

PlusButton.propTypes = {
  hintText: PropTypes.string,
};

PlusButton.defaultProps = {
  hintText: '',
};

export default PlusButton;
