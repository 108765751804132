import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';
import Wizard from 'common/Wizard';

import ProgressHeader from './Steps/ProgressHeader';
import MethodStep from './Steps/MethodStep';
import ConfigurationSteps from './ConfigurationSteps';
import * as constants from '../steps';
import { ssoModelPropType } from '../Proptypes';
import styles from '../RosteringSetupModal/Form.module.scss';

export default class SSOSetupModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    existingModels: PropTypes.arrayOf(PropTypes.string),
    modalIsOpen: PropTypes.bool.isRequired,
    ssoModel: ssoModelPropType,
    ssoModelCreatePath: PropTypes.string,
    ssoModelPath: PropTypes.string.isRequired,
    subscriber_id: PropTypes.number.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    rootUrl: PropTypes.string.isRequired,
    currentStep: PropTypes.number,
    completedSteps: constants.completedStepsPropTypes.isRequired,
    isSaml: PropTypes.bool,
    hasCompletedClever: PropTypes.bool
  };

  static defaultProps = {
    existingModels: [],
    ssoModel: null,
    currentStep: 0,
    isSaml: false,
    ssoModelCreatePath: '',
    hasCompletedClever: false
  };

  // get currentStep prop or first step that is not completed
  _getInitialStep() {
    return this.props.currentStep ||
      parseInt(Object.keys(this.props.completedSteps).find(key => !this.props.completedSteps[key]), 10);
  }

  render() {
    return (
      <Modal
        progressHeader={<ProgressHeader completedSteps={this.props.completedSteps} isSaml={this.props.isSaml} />}
        closeModal={this.props.closeModal}
        isOpen={this.props.modalIsOpen}
        headerText="Single Sign On Setup"
        bodyClassName={styles.setUpModal}
      >
        <Wizard
          initialStep={this._getInitialStep() > 0 ? 1 : 0}
        >
          <MethodStep
            setMethod={this.setMethod}
            ssoModelCreatePath={this.props.ssoModelCreatePath}
            subscriber_id={this.props.subscriber_id}
            updateSSOModel={this.props.updateSSOModel}
            existingModels={this.props.existingModels}
            hasCompletedClever={this.props.hasCompletedClever}
          />

          <ConfigurationSteps
            uploadXMLUrl={this.props.uploadXMLUrl}
            closeModal={this.props.closeModal}
            rootUrl={this.props.rootUrl}
            ssoModel={this.props.ssoModel}
            ssoModelPath={this.props.ssoModelPath}
            updateSSOModel={this.props.updateSSOModel}
            isSaml={this.props.isSaml}
            completedSteps={this.props.completedSteps}
            initialStep={this._getInitialStep()}
          />
        </Wizard>
      </Modal>
    );
  }
}
