import React, { useEffect } from 'react';
import ReactRailsUJS from 'modules/ReactRailsUJS';
import NotebookGrid from 'modules/grid/NotebookGrid';
import I18n from 'i18n-js';
import ClimateSimulation from 'modules/simulations/ClimateSimulation/ClimateSimulation';

const initializeAnswerKeyJS = (ref) => {
  $(ref).find('.answer-key-toggle').click((e) => {
    const $toggleButton = $(e.currentTarget);
    $toggleButton.toggleClass('btn--outline-orange btn--orange');

    const newLabel = $toggleButton.hasClass('btn--orange') ? I18n.t('hide_answer_key') : I18n.t('show_answer_key');
    $toggleButton.html(newLabel);

    const $container = $toggleButton.closest('.investigation-question, .notebook-question, .exercise-question');
    $container.find('.answer-field, .answer-key').toggleClass('active');
  });
};

const initializeNotebookGrids = (ref, setRecentlyAnsweredQuestionId, thumbnail, slideObjectId) => {
  ref.querySelectorAll('.notebook-grid-wrapper').forEach((grid) => {
    const $grid = $(grid);
    const originalId = $grid.attr('id');
    const gridId = thumbnail ? `${originalId}_${slideObjectId}_thumbnail` : originalId;

    if (thumbnail) {
      $grid.attr('id', gridId);
    }

    const teacher = $grid.hasClass('teacher');
    const readOnly = teacher ? $grid.hasClass('gradebook') : $grid.parents().hasClass('questions-disabled');
    const showUpdate = teacher ? false : $grid.attr('data-show-update');

    const notebookGrid = new NotebookGrid({
      height: $grid.attr('data-height'),
      id: gridId,
      read_only: readOnly,
      set_recently_answered_question_id: setRecentlyAnsweredQuestionId,
      show_update: showUpdate,
      teacher,
      url: $grid.attr('data-url'),
      preview: true,
    });

    notebookGrid.init();
  });
};

const initializeSimulations = (ref) => {
  ref.querySelectorAll('.tci-simulation').forEach((simulation) => {
    const sim = new ClimateSimulation({ parent_el: simulation });

    sim.init();
  });
};

interface NotebookPreviewJSProps {
  isLoading: boolean;
  previewContent?: string;
  ref: React.MutableRefObject<HTMLDivElement>;
  setRecentlyAnsweredQuestionId: Function;
  thumbnail: boolean;
  slideObjectId?: string;
}

export const useNotebookPreviewJS = ({ isLoading, previewContent, ref, thumbnail, setRecentlyAnsweredQuestionId, slideObjectId }: NotebookPreviewJSProps) => {
  useEffect(() => {
    if (isLoading || !previewContent || !ref.current) return;

    ReactRailsUJS.mountComponents('[data-react-class]');

    initializeAnswerKeyJS(ref.current);
    initializeNotebookGrids(ref.current, setRecentlyAnsweredQuestionId, thumbnail, slideObjectId);
    if (!thumbnail) initializeSimulations(ref.current);
  }, [isLoading, previewContent, ref, thumbnail]);
};
