import { KeyboardEvent } from 'react';

type UseKeydownAndClickResult = {
  onClick: () => void,
  onKeyDown: (e: KeyboardEvent) => void,
  tabIndex: number
};

const useKeydownAndClick = (onClick: () => void): UseKeydownAndClickResult => ({
  onClick,
  onKeyDown: (e) => {
    if (e.key === 'Enter') onClick();
  },
  tabIndex: 0,
});

export default useKeydownAndClick;
