import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import cn from '@/utils/cn';

const PopoverContent = React.forwardRef<
React.ElementRef<typeof PopoverPrimitive.Content>,
React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & { container? : HTMLElement | null }
>(({ className, children, align = 'center', side = 'top', container, sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal container={container}>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        `tw-z-9999 tw-w-72 tw-rounded tw-border tw-bg-white tw-text-popover-foreground tw-shadow-md
         tw-outline tw-outline-1 tw-outline-gray-300 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out
         data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
         data-[state=open]:zoom-in-95 data-[side=top] data-[side=bottom]:tw-slide-in-from-top-2
         data-[side=left]:tw-slide-in-from-right-2 tw-overflow-hidden data-[side=right]:tw-slide-in-from-left-2
         data-[side=top]:tw-slide-in-from-bottom-2`,
        className,
      )}
      side={side}
      onOpenAutoFocus={() => {
        /**
         * Old comment: "The default focusable element is the bottom element which can be disabled"
         *   `e.preventDefault()` used to be here to accomplish this, but this breaks accessibility-
         *     the ability of screen readers to focus on the dialog.
         *   See https://github.com/radix-ui/primitives/discussions/935
         *
         *   For now can't find behavior in the app this was trying to fix- may need follow up work.
         */
      }}
      {...props}
    >
      {children}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const Popover = Object.assign(PopoverPrimitive.Root, {
  Anchor: PopoverPrimitive.Anchor,
  Content: PopoverContent,
  Trigger: PopoverPrimitive.Trigger,
  Close: PopoverPrimitive.Close,
});

export default Popover;
