import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';

import ActionsDropdown from '@/components/common/ActionsDropdown';
import PathContext from '@/components/common/Context';
import BulkActionOption from './BulkActionOption';

interface BulkActionProps {
  isSysadmin: boolean;
  numLocked: number;
  numSelectedPrograms: number;
  openDeleteStafferModal: MouseEventHandler<HTMLAnchorElement>;
  openRemoveProgramsModal: MouseEventHandler<HTMLAnchorElement>;
  openRosterExclusionModal: (e: React.MouseEvent, rowData: any, type: string, options?: any) => void;
  openLockTeacherModal: (e: React.MouseEvent, lockAction: 'lock' | 'unlock') => void;
  rosterExclusionNums: {
    excluded: number;
    unexcluded: number;
  }
  selectedStaffIds: number[];
}

const BulkAction = ({
  isSysadmin, numLocked, numSelectedPrograms, openDeleteStafferModal, openRemoveProgramsModal, openRosterExclusionModal,
  openLockTeacherModal, rosterExclusionNums, selectedStaffIds,
}: BulkActionProps) => {
  const pathContext = React.useContext<{ transferPath: string }>(PathContext);

  const getTransferPath = () => {
    const ids = selectedStaffIds.map(id => `&teacher[]=${id}`).join();
    return pathContext.transferPath.concat(ids);
  };

  return (
    <ActionsDropdown toggleText="Bulk Actions">
      {isSysadmin && (
        <>
          <BulkActionOption
            icon="exchange"
            href={getTransferPath()}
            adminOnly
          >
            Transfer
          </BulkActionOption>
          {numLocked !== selectedStaffIds.length && (
            <BulkActionOption
              icon="lock"
              onClick={e => openLockTeacherModal(e, 'lock')}
              adminOnly
            >
              Lock Access
            </BulkActionOption>
          )}
          {numLocked > 0 && (
            <BulkActionOption
              icon="unlock"
              onClick={e => openLockTeacherModal(e, 'unlock')}
              adminOnly
            >
              Unlock Access
            </BulkActionOption>
          )}
        </>
      )}
      {numSelectedPrograms > 0 && (
        <BulkActionOption icon="trash" onClick={openRemoveProgramsModal}>Remove Programs</BulkActionOption>
      )}
      {rosterExclusionNums.unexcluded > 0 && (
        <BulkActionOption
          icon="ban"
          onClick={e => (
            openRosterExclusionModal(e, null, 'rosterExclusion', { rosterAction: 'exclude' })
          )}
        >
          Exclude from Rostering
        </BulkActionOption>
      )}
      {rosterExclusionNums.excluded > 0 && (
        <BulkActionOption
          icon="ban"
          onClick={e => (
            openRosterExclusionModal(e, null, 'rosterExclusion', { rosterAction: 'unexclude' })
          )}
        >
          Unexclude from Rostering
        </BulkActionOption>
      )}
      <BulkActionOption icon="trash" onClick={openDeleteStafferModal}>Delete Staff</BulkActionOption>
    </ActionsDropdown>
  );
};

BulkAction.propTypes = {
  isSysadmin: PropTypes.bool.isRequired,
  numSelectedPrograms: PropTypes.number.isRequired,
  openDeleteStafferModal: PropTypes.func.isRequired,
  openRemoveProgramsModal: PropTypes.func.isRequired,
  openRosterExclusionModal: PropTypes.func.isRequired,
  openLockTeacherModal: PropTypes.func.isRequired,
  rosterExclusionNums: PropTypes.shape({
    excluded: PropTypes.number.isRequired,
    unexcluded: PropTypes.number.isRequired,
  }).isRequired,
  selectedStaffIds: PropTypes.instanceOf(Array).isRequired,
};

export default BulkAction;
