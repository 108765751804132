import * as Routes from '@/modules/routes';
import React from 'react';
import Pluralize from 'pluralize';
import useClassroomLesson from '@/components/staffer/Scores/GradesTable/hooks/queries/useClassroomLesson';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useLessonNotebooks
  from '../../../hooks/queries/exerciseResults/useLessonNotebooks';
import useSectionNotebookExerciseResults
  from '../../../hooks/queries/exerciseResults/useSectionNotebookExerciseResults';
import { COLOR_LEGEND } from '../../../variables';
import GradeCell from './index';
import { IStatus } from '../../../types';
import { roundScore } from './utils';
import useSectionNotebooksQuestionAnswers
  from '../../../hooks/queries/questionAnswers/useSectionNotebooksQuestionAnswers';
import { gradingBySection } from '@/components/staffer/Scores/GradesTable/Utils';

const LessonNotebookGradeCell = ({ studentId }) => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);

  const { data: exerciseResults } = useLessonNotebooks();
  const { data: sectionNotebookExerciseResults } = useSectionNotebookExerciseResults();
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const { data: allSectionNotebooksProgress } = useSectionNotebooksQuestionAnswers();

  const exerciseResult = exerciseResults.find(er => er.student_id === studentId);
  const sectionExerciseResults = sectionNotebookExerciseResults.filter(er => (er.student_id === studentId));

  const notebooksInProgress = studentId in allSectionNotebooksProgress && allSectionNotebooksProgress[studentId].length > 0;

  const url = Routes.grade_by_student_shared_question_answers_path(studentId, {
    program_id: programId, classroom_id: classroom.id, lesson_id: lesson.id,
  });

  const getNumOfNotebooksInProgress = () => (notebooksInProgress ? allSectionNotebooksProgress[studentId].length : 0);

  const getSectionCounts = (status) => {
    const cellStatus = status;

    // Check each section notebook for a completed_at date.
    // This indicates that it has been turned in and needs grading.
    const completed: boolean = sectionExerciseResults.some(er => !!er.completed_at);

    // If any notebook has been turned in, focus on counting the completed notebooks.
    const count = completed ? sectionExerciseResults.filter(er => (!!er.completed_at === true)).length
      : getNumOfNotebooksInProgress();

    const text = completed ? 'Turned In' : 'In Progress';
    cellStatus.color = completed ? COLOR_LEGEND.completed : COLOR_LEGEND.in_progress;
    cellStatus.text = `${Pluralize('section', count, true)} ${text}`;
  };

  const getNotebookStatus = () => {
    // Default, no grade or exercise results for any section notebook in the lesson.
    const status: IStatus = {
      color: '',
      text: '-',
    };

    // Grading by section
    if (gradingBySection(classroomLesson)) {
      // Check if any section notebooks in the lesson have exercise results.
      // This means that they've been turned in or are in progress.
      if (sectionExerciseResults.length > 0 || notebooksInProgress) {
        const scores = sectionExerciseResults.map(er => er.score).filter(score => (score != null));

        // If scores are present, add up the scores.
        if (scores.length > 0) {
          status.text = roundScore(scores.reduce((acc, score) => acc + parseFloat(score), 0));
        } else {
          getSectionCounts(status);
        }
      }
      return status;
    }

    // Grading by lesson
    // Display notebook exercise result score if it exists.
    if (exerciseResult?.score) {
      const score = roundScore(exerciseResult.score);
      const lowScoreThreshold = classroomLesson.max_score * lowScoresSettings.threshold / 100;
      const shouldHighlight = lowScoresSettings.enabled && score <= lowScoreThreshold;

      status.text = score;
      status.color = shouldHighlight ? COLOR_LEGEND.low_score : '';

      return status;
    }

    // Check if any section notebooks in the lesson have exercise results.
    // This means that they've been turned in or are in progress.
    if (sectionExerciseResults.length > 0 || notebooksInProgress) {
      getSectionCounts(status);
    }

    return status;
  };

  const notebookStatus = getNotebookStatus();

  return (
    <GradeCell
      id={`lesson-notebook-student-${studentId}-score`}
      status={notebookStatus}
      url={url}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default LessonNotebookGradeCell;
