import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ISlideShow } from '../../types';

const useSlideShows = (classroomId: number, lessonId?: number) => {
  const isEnabled = !!lessonId;

  const query = useQuery(['slideShows', classroomId, lessonId], () => {
    const path = Routes.plato_api_staffer_slideshows_slide_shows_path({
      classroom_id: classroomId,
      lesson_id: lessonId,
      only: ['id', 'title', 'max_score', 'custom', 'slide_group_count'],
    });

    return axios
      .get<{ data: ISlideShow[] }>(path)
      .then(res => res.data?.data || null)
      .catch(err => console.error(err));
  }, { staleTime: Infinity, enabled: isEnabled });

  return { ...query, isEnabled, data: query.data || null };
};

export default useSlideShows;
