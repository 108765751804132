import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { renderErrors } from 'common/Forms/Utils';
import { CheckboxField } from 'common/Forms';
import showToast from 'common/Toast';
import styles from './StudentAccess.module.scss';
import InvestigationSettings from './InvestigationSettings';
import { handleRadio } from '../RadioGroup';
import { handleSelect, handleCheckbox, Header, ActionButtonsWithError } from '../Util';
import { isMiddleSchool, AssessmentResults } from './Util';

const defaultSettings = scienceProgram => (
  {
    allow_view_slideshows: false,
    allow_view_unit_projects: true,
    lesson_game_retry: false,
    reading_game_retry: false,
    show_page_numbers: true,
    student_default_investigation: scienceProgram ? 'fast_track' : 'master',
    student_view_lesson_game_off: false,
    student_view_lesson_notes_off: false,
    student_view_reading_checks_off: false,
    student_view_lesson_text_off: false,
    text_audio_enabled: true,
    text_audio_mode: 'text_to_speech'
  }
);

export default class StudentAccess extends Component {
  static propTypes = {
    active: PropTypes.bool,
    assignmentsPath: PropTypes.string.isRequired,
    displayUnitContent: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func,
    schoolLevel: PropTypes.string.isRequired,
    scienceProgram: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
      allow_view_slideshows: PropTypes.bool,
      allow_view_unit_projects: PropTypes.bool,
      show_page_numbers: PropTypes.bool,
      student_view_lesson_notes_off: PropTypes.bool,
      student_view_reading_checks_off: PropTypes.bool,
      student_view_lesson_text_off: PropTypes.bool,
    }),
    showLimitedSettings: PropTypes.bool,
    stafferPath: PropTypes.string.isRequired,
    streamEnabled: PropTypes.bool.isRequired,
    updateState: PropTypes.func,
    programHasReadingChecks: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
    settings: {
      student_view_lesson_text_off: false,
      student_view_lesson_notes_off: false,
      student_view_reading_checks_off: false,
    },
    showLimitedSettings: false,
    programHasReadingChecks: true,
    updateState: () => {}
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .put(this.props.stafferPath, values)
      .then((response) => {
        const settings = response.data.data.settings;
        showToast('Save successful');
        this.props.updateState({ settings });
        this.setState({ submitting: false });
      })
      .catch(error => this._handleSubmitError(error));
  };

  _handleSubmitError(error) {
    this.setState({ submitting: false });
    showToast('Save failed', 'error');
    return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
  }

  _getInitialValues() {
    const {
      allow_view_unit_projects: allowViewUnitProjects,
    } = this.props.settings;

    const unitProjectsValue = allowViewUnitProjects === null ? true : allowViewUnitProjects;

    return Object.assign(
      {},
      defaultSettings(this.props.scienceProgram),
      {
        ...this.props.settings,
        allow_view_unit_projects: unitProjectsValue
      }
    );
  }

  render() {
    if (!this.props.active) return null;

    return (
      <div className={this.props.streamEnabled ? undefined : styles.withInvestigationNotebook}>
        <Form
          initialValues={this._getInitialValues()}
          onSubmit={this.handleSubmit}
          mutators={{
            handleCheckbox: handleCheckbox,
            handleRadio: handleRadio,
            handleSelect: handleSelect
          }}
          render={({
            handleSubmit, submitError, values, form
          }) => {
            const checkboxProps = {
              onChange: form.mutators.handleCheckbox,
              fieldClass: 'tw-flex tw-justify-end',
              labelClass: 'tw-w-full tw-font-bold',
              rowClass: 'tw-flex tw-justify-between tw-w-[95%] tw-mb-3'
            };

            return (
              <form onSubmit={handleSubmit}>
                {!this.props.showLimitedSettings && (
                  <>
                    <Header text="Class Management" />

                    <div className="mb20">
                      <CheckboxField
                        {...checkboxProps}
                        checked={!values.student_add_lock}
                        label="Students Can Change Classes"
                        name="student_add_lock"
                      />
                      Allow student to add/remove themselves from my classes
                    </div>
                    <hr />
                  </>
                )}

                <Header text="Content Access" />
                <div className="mb20">
                  Prior to <a target="_blank" rel="noopener noreferrer" href={this.props.assignmentsPath}>assignment</a>
                  , students can view:
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.allow_view_unit_projects}
                    label="Unit Projects"
                    name="allow_view_unit_projects"
                  />
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={values.allow_view_slideshows}
                    label="Slideshows"
                    name="allow_view_slideshows"
                  />
                </div>
                <div className="mb20">
                  {this.props.displayUnitContent && isMiddleSchool(this.props.schoolLevel) && (
                    <InvestigationSettings
                      form={form}
                      values={values}
                      checkboxProps={checkboxProps}
                      scienceProgram={this.props.scienceProgram}
                    />
                  )}
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={!values.student_view_lesson_text_off}
                    label="Text"
                    name="student_view_lesson_text_off"
                  />
                </div>
                { this.props.programHasReadingChecks && (
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={!values.student_view_reading_checks_off}
                    label="Reading Check"
                    name="student_view_reading_checks_off"
                  />
                </div>
                )}
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={!values.student_view_lesson_notes_off}
                    label="Reading Notes"
                    name="student_view_lesson_notes_off"
                  />
                </div>
                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={!values.student_view_lesson_game_off}
                    label="Lesson Game"
                    name="student_view_lesson_game_off"
                  />
                </div>
                <i className={styles.font14}>
                  Reference materials are always available for students to view.<br /><br />
                  Assessments can not be viewed by students until assigned.<br />
                </i>
                <hr />

                <Header text="Assessment Access" />

                <div className="mb20">
                  <CheckboxField
                    {...checkboxProps}
                    checked={!values.student_closed_book_assessments}
                    label="Open Book Test"
                    name="student_closed_book_assessments"
                  />
                  Allow students to access reading and other lesson content while taking an assessment.
                </div>

                <AssessmentResults form={form} values={values} />

                <ActionButtonsWithError
                  handleCancel={this.props.handleCancel}
                  submitError={submitError}
                  submitting={this.state.submitting}
                />
              </form>
            );
          }}
        />
      </div>
    );
  }
}
