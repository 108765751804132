import React, { useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import Select from 'react-select';
import AutoSave from '@/components/common/Forms/AutoSave';
import { NumberField } from '@/components/common/Forms';

const OPTIONS = [
  { label: 'Default (250px)', value: '250' },
  { label: 'Full (800px)', value: '800' },
  { label: 'Custom', value: '' },
] as const;

interface WidthDropdownProps {
  customWidth: boolean;
  handleChange: (option, input) => void;
  input: FieldRenderProps['input'];
}

const WidthDropdown = ({ customWidth, handleChange, input }: WidthDropdownProps) => {
  return (
    <div className="tw-grow">
      <label className="tw-mb-1 tw-ml-0.5" htmlFor="width">Width</label>
      <Select
        {...input}
        clearable={false}
        inputId="width"
        onChange={opt => handleChange(opt, input)}
        options={OPTIONS}
        value={customWidth ? OPTIONS[2] : OPTIONS.find(o => (o.value === input.value))}
        searchable={false}
      />
    </div>
  );
};

interface WidthManagerProps {
  initialValue: string;
  submitHandler: ({ width: string }) => any;
}

interface Values {
  width: string | number;
  customWidth: string | number;
}

const WidthManager = ({ initialValue, submitHandler }: WidthManagerProps) => {
  const standardOptions = OPTIONS.map(o => o.value).slice(0, -1);
  const [customWidth, setCustomWidth] = useState(!standardOptions.some(option => option === initialValue));

  const handleSubmit = ({ customWidth: newCustomWidth, width: newWidth }: Values | any, change) => {
    if (customWidth && newCustomWidth) {
      const currentWidth = parseInt(newCustomWidth, 10);

      if (currentWidth > 800) {
        change('customWidth', '800');
        return submitHandler({ width: '800' });
      }

      if (currentWidth < 200) {
        change('customWidth', '200');
        return submitHandler({ width: '200' });
      }
    }

    return submitHandler({ width: customWidth ? newCustomWidth : newWidth });
  };

  return (
    <Form
      initialValues={{ width: initialValue, customWidth: initialValue.split('px')[0] }}
      onSubmit={handleSubmit}
      render={({ values, form: { change } }) => (
        <form className="tw-p-2">
          <AutoSave debounce={400} save={() => handleSubmit(values, change)} />
          <div className="tw-flex tw-flex-row tw-items-start tw-justify-start">
            <Field
              name="width"
              value={OPTIONS[0].value}
              render={({ input }) => {
                const handleChange = (option, el) => {
                  setCustomWidth(option.label === 'Custom');

                  el.onChange(option.value);
                };

                return (
                  <WidthDropdown
                    customWidth={customWidth}
                    handleChange={handleChange}
                    input={input}
                  />
                );
              }}
            />
            {customWidth && (
              <>
                <NumberField
                  className="tw-grow-0 tw-ml-2.5 tw-mt-[8px] tw-h-[14px] tw-w-[30px]"
                  id="customWidth"
                  name="customWidth"
                  hideLabel
                />
                <span className="tw-relative tw-top-7 tw-ml-2.5">px</span>
              </>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default WidthManager;
