import PropTypes from 'prop-types';

export default PropTypes.shape({
  caption_es_url: PropTypes.string,
  caption_url: PropTypes.string,
  es_url: PropTypes.string,
  is_silent: PropTypes.bool,
  poster_es_url: PropTypes.string,
  poster_url: PropTypes.string,
  url: PropTypes.string,
});
