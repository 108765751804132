import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as Routes from 'routes';

import Modal from 'common/Modal';
import ManageTeachers from 'common/ManageTeachers';
import { addParameterToUrl } from 'common/Utils';
import Spinner from 'common/Spinner';

import { formatTeacherForSelect, sortingCallback } from './FormUtils';

export default class TeacherModal extends Component {
  static propTypes = {
    classroomStaffersPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    columns: PropTypes.instanceOf(Array),
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      programId: PropTypes.number,
      seat_pool_id: PropTypes.number.isRequired,
      staffers: PropTypes.instanceOf(Array),
    }).isRequired,
    sharePath: PropTypes.string.isRequired,
    subscriberStaffersPath: PropTypes.string.isRequired,
    subscriberTeachers: PropTypes.instanceOf(Array),
    updateTeachers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    columns: ['first_name', 'last_name', 'email', 'remove'],
    subscriberTeachers: [],
  };

  static _processResources(resources) {
    return resources.map(resource => (
      {
        ...resource,
        remove: ''
      }
    ));
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      resources: [],
      teachers: [],
    };

    this.updateTeachers = this.updateTeachers.bind(this);
  }

  componentDidMount() {
    Promise
      .all([this._getClassroomSeatPool(), this._getClassroomStaffers()])
      .then(() => this.setState({ isLoading: false }, this._getTeachersForSelect))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateTeachers(response) {
    const staffers = this.state.resources.filter(teacher => !response.data.removedStaffers.includes(teacher.id));

    if (response.data.sharedStaffer) {
      staffers.push(response.data.sharedStaffer);
    }

    let teachers = staffers.map(teacher => `${teacher.first_name} ${teacher.last_name}`).sort();
    teachers = teachers.join(', ');

    this.props.updateTeachers({ ...response.data, teachers });
  }

  _getClassroomStaffers() {
    const url = this.props.classroomStaffersPath.replace(':classroom_id', this.props.rowData.id);

    return Axios
      .get(url)
      .then((response) => {
        this.setState({ resources: TeacherModal._processResources(response.data.data) });
      });
  }

  _getClassroomSeatPool() {
    const path = Routes.plato_api_seat_pool_path(this.props.rowData.seat_pool_id, {
      extra_attributes: 'has_available_seats',
      only: ['id', 'license_id'],
    });

    return Axios
      .get(path)
      .then(response => this.setState({ classroomSeatPool: response.data.seat_pool }));
  }

  _getShareableTeachers(teachers) {
    const filteredTeachers = teachers.filter(teacher => !this._isTeacherInClassroom(teacher.id));

    const formattedTeachers = filteredTeachers.map(teacher => formatTeacherForSelect(teacher)).sort(sortingCallback);

    this.setState({ teachers: formattedTeachers });
  }

  _getStaffersInProgram() {
    const url = addParameterToUrl(this.props.subscriberStaffersPath,
      `for_license=${this.state.classroomSeatPool.license_id}`);

    return Axios
      .get(url)
      .then((response) => {
        this.setState({ teachers: response.data.data }, () => this._getShareableTeachers(this.state.teachers));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  _getTeachersForSelect() {
    if (!this.state.classroomSeatPool.has_available_seats) {
      return this._getStaffersInProgram();
    }

    return this._getShareableTeachers(this.props.subscriberTeachers);
  }

  _isTeacherInClassroom(teacherId) {
    return this.state.resources.findIndex(currentTeacher => currentTeacher.id === teacherId) >= 0;
  }

  _renderForm() {
    if (this.state.isLoading) return <Spinner />;

    return (
      <ManageTeachers
        classroomName={this.props.rowData.name}
        closeModal={this.props.closeModal}
        columns={this.props.columns}
        id={this.props.rowData.id}
        noAvailableTeacherSeats={!this.state.classroomSeatPool.has_available_seats}
        sharePath={this.props.sharePath.replace(':id', this.props.rowData.id)}
        teachers={this.state.teachers}
        updateComponent={this.updateTeachers}
        resources={this.state.resources}
        resourcesAreLoading={this.state.isLoading}
      />
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        isOpen={this.props.modalIsOpen}
        headerText="Manage Teachers"
      >
        {this._renderForm()}
      </Modal>
    );
  }
}
