import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { useModalManager } from 'common/Modal';
import ClassesModal from './ClassesModal';

const RosterClassLinking = ({
  currentStafferId, hideSkipLinkingButton, modalTriggerClass, modalTriggerText, programs, provider,
  redirectOnLinkSkipPath, redirectOnLinkSuccess, stafferIdToAssignProgramsTo, subscriberId, linkedClassroomMap,
}) => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [allowManualAdd, setAllowManualAdd] = useState(true);
  const [programTitle, setProgramTitle] = useState(null);
  const [licenseId, setLicenseId] = useState(null);
  const [manualAddDisabledNotice, setManualAddDisabledNotice] = useState('Please exclude teacher or program from autorostering');
  const classModalManager = useModalManager();

  const renderModal = () => {
    if (!classModalManager.isOpen) return;

    return (
      <ClassesModal
        allowManualAdd={allowManualAdd}
        closeModal={classModalManager.close}
        currentStafferId={currentStafferId}
        hideSkipLinkingButton={hideSkipLinkingButton}
        isOpen={classModalManager.isOpen}
        linkedClassroomMap={linkedClassroomMap}
        manualAddDisabledNotice={manualAddDisabledNotice}
        program={selectedProgram}
        programTitle={programTitle}
        licenseId={licenseId}
        provider={provider}
        redirectOnLinkSkipPath={redirectOnLinkSkipPath}
        redirectOnLinkSuccess={redirectOnLinkSuccess}
        stafferIdToAssignProgramsTo={stafferIdToAssignProgramsTo}
        subscriberId={subscriberId}
      />
    );
  };

  const openModal = (clickedProgram, clickedProgramDataset) => {
    setSelectedProgram(clickedProgram);
    setProgramTitle(clickedProgramDataset.programTitle);
    setAllowManualAdd(clickedProgramDataset.allowManualAdd === 'true');
    setManualAddDisabledNotice(clickedProgramDataset.manualAddDisabledNotice);
    setLicenseId(clickedProgramDataset.licenseId);
    classModalManager.open();
  };

  const renderModalTriggers = () => (
    programs.map((program) => {
      const id = `program-${program.id}`;
      const elements = document.getElementsByClassName(id);

      if (!elements) return;

      return Array.from(elements).map((el) => {
        const button = (
          <button
            type="button"
            className={modalTriggerClass}
            onClick={() => openModal(program, el.dataset)}
            dangerouslySetInnerHTML={{ __html: modalTriggerText }}
          />
        );

        return ReactDOM.createPortal(button, el);
      });
    }).flat()
  );

  return (
    <Fragment>
      {renderModalTriggers()}
      {renderModal()}
    </Fragment>
  );
};

RosterClassLinking.propTypes = {
  currentStafferId: PropTypes.number.isRequired,
  hideSkipLinkingButton: PropTypes.bool,
  linkedClassroomMap: PropTypes.shape({}),
  modalTriggerClass: PropTypes.string,
  modalTriggerText: PropTypes.string,
  programs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  provider: PropTypes.string.isRequired,
  redirectOnLinkSkipPath: PropTypes.bool,
  redirectOnLinkSuccess: PropTypes.bool,
  stafferIdToAssignProgramsTo: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
};

RosterClassLinking.defaultProps = {
  hideSkipLinkingButton: false,
  linkedClassroomMap: {},
  modalTriggerClass: '',
  modalTriggerText: 'Add Program',
  programs: [],
  redirectOnLinkSkipPath: false,
  redirectOnLinkSuccess: false,
};

export default RosterClassLinking;
