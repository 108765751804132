import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import DropdownMenu from '@/components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from '@/components/common/Dropdown';
import AddSlideObject from './ContentActions/AddSlideObject';
import usePopover from './hooks/usePopover';
import useSlideShowContext from '../stores/useSlideShowContext';

interface TypeSelectorProps {
  index: number;
  modalManager: ModalManager;
  slideId: number;
}

const TypeSelector = ({ index, modalManager, slideId }: TypeSelectorProps) => {
  const ref = useRef();
  const dropdownEvents = useDropdownEvents(ref);
  const anchorId = `type-selector-anchor-${index}`;
  const openerClass = `type-selector-${index}`;
  const { coordinates } = usePopover({ anchorId, dropdownEvents, openerClass, position: 'bottom left', ref });
  const isAdmin = useSlideShowContext(state => state.isAdmin);

  return (
    <>
      <button
        id={anchorId}
        type="button"
        className={`dropdown-toggle btn btn--green ${openerClass}`}
      >
        Select Type
      </button>

      {ReactDOM.createPortal(
        <DropdownMenu
          open={dropdownEvents.open}
          coordinates={coordinates}
          ref={ref}
        >
          <AddSlideObject
            innerText="Text"
            variables={{ index, modelType: 'Text', slideId }}
          />
          {isAdmin && (
            <AddSlideObject
              innerText="HTML"
              openModal={modalManager.open}
              sysadminOnly
              variables={{ index, modelType: 'HTML', slideId }}
            />
          )}
          <AddSlideObject
            openModal={modalManager.open}
            innerText="Image"
            variables={{ index, modelType: 'Image', slideId }}
          />
          <AddSlideObject
            openModal={modalManager.open}
            innerText="Video"
            variables={{ index, modelType: 'Video', slideId }}
          />
          <AddSlideObject
            openModal={modalManager.open}
            innerText="Questions"
            variables={{ index, modelType: 'Question', slideId }}
          />
          {isAdmin && (
            <AddSlideObject
              openModal={modalManager.open}
              innerText="3D Model"
              variables={{ index, modelType: '3DModel', slideId }}
            />
          )}
          {isAdmin && (
            <AddSlideObject
              openModal={modalManager.open}
              innerText="360 Image"
              variables={{ index, modelType: 'PanoramicImage', slideId }}
            />
          )}
          {isAdmin && (
            <AddSlideObject
              innerText="Interactive Map"
              openModal={modalManager.open}
              sysadminOnly
              variables={{ index, modelType: 'InteractiveMap', slideId }}
            />
          )}
        </DropdownMenu>,
        document.body,
      )}
    </>
  );
};

export default TypeSelector;
