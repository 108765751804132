import React from 'react';
import cn from '@/utils/cn';
import styles from './ScoreIcon.module.scss';

interface IconProps {
  isCorrect?: boolean;
  isPartiallyCorrect?: boolean
}

const ScoreIcon = ({ isCorrect = false, isPartiallyCorrect = false }: IconProps) => (
  <div className={styles.icon}>
    <div
      className={cn({
        [styles.iconChild]: true,
        [styles.correct]: isCorrect,
        [styles.partiallyCorrect]: isPartiallyCorrect,
        [styles.incorrect]: !isCorrect && !isPartiallyCorrect,
      })}
    >
      <i className={`fa fa-${(isCorrect || isPartiallyCorrect) ? 'check' : 'times'}`} />
    </div>
  </div>
);

export default ScoreIcon;
