import React from 'react';
import I18n from 'i18n-js';
import Modal from '@/components/ui/Modal';
import { useModalManager } from '@/components/common/Modal';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_reset_reading_game_path } from '@/modules/routes';

const queryClient = new QueryClient();

type Props = {
  disabled: boolean;
  readingGameId: number;
};

const ResetReadingGameBtn = ({ disabled = false, readingGameId }: Props) => {
  const { isOpen, open, close } = useModalManager();

  const { isLoading, mutate } = useMutation({
    mutationFn: () => axios.post(plato_api_reset_reading_game_path(readingGameId)),
    onSuccess: () => window.location.reload(),
  });

  return (
    <>
      <button
        className="btn btn--outline-purple"
        disabled={disabled}
        type="button"
        onClick={open}
      >
        {I18n.t('restart')}
      </button>
      <Modal
        close={close}
        headerText={`${I18n.t('restart')} ${I18n.t('reading_check')}`}
        isOpen={isOpen}
      >
        <div>
          <p className="tw-leading-6">{I18n.t('reading_check_restart_message')}</p>
        </div>
        <Modal.SubmitFooter
          buttonColor="purple"
          close={close}
          isLoading={isLoading}
          isValid
          onSubmit={mutate}
          submittingText={I18n.t('deleting')}
          submitText={`${I18n.t('restart')}`}
        />
      </Modal>
    </>
  );
};

const ResetReadingGameBtnWrapper = (props: Props) => (
  <QueryClientProvider client={queryClient}>
    <ResetReadingGameBtn {...props} />
  </QueryClientProvider>
)

export default ResetReadingGameBtnWrapper;
