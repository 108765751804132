import React from 'react';
import { NumberField } from '@/components/common/Forms';

interface StartDurationInputsProps {
  form: any;
  name: 'ad_en_data' | 'ad_es_data';
}

const StartDurationInputs = ({ form, name }: StartDurationInputsProps) => {
  const audiosAttributes = form.getState().values.audios_attributes;

  if (!audiosAttributes) return null;
  if (audiosAttributes.length === 0) return null;

  const values = audiosAttributes[0][name];

  if (!values) return null;

  return (
    <>
      {(values as any[]).map((_value, i) => (
        <div className="tw-flex tw-gap-4 tw-mb-2">
          <NumberField
            label="Pause at this timestamp"
            labelClass="tw-font-bold"
            name={`audios_attributes[0][${name}][${i}][start]`}
            rowClass="tw-flex tw-items-center tw-gap-2"
          />
          <NumberField
            label="For this many seconds"
            labelClass="tw-font-bold"
            name={`audios_attributes[0][${name}][${i}][duration]`}
            rowClass="tw-flex tw-items-center tw-gap-2"
          />
        </div>
      ))}
    </>
  );
};

export default StartDurationInputs;
