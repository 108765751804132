import React, { Component } from 'react';
import Axios from 'axios';
import { Footer, Spinner } from 'common/Modal';
import { FormInput, FormImageUploader } from '../../materials/Utils';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    factoid: '',
    caption: '',
    imageUrl: null,
    removeImage: false,
    imageFile: null,
    isLoading: false,
    error: false,
    newRecord: false
  };

  render() {
    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    );
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`safety-notes-form ${this.state.isLoading && 'loading'}`}>
        {this.props.name === "Background Image" &&
          <div>
            <FormImageUploader
              onFileAdded={this.onFileAdded.bind(this)}
              imageUrl={this.state.backgroundImageUrl}
              allowRemove={false}
              newRecord={this.props.newRecord}
              id={this.props.id}
            />

            <FormInput label="Caption" stateName="caption"
                       required={true} id={this.props.id}
                       value={this.state.caption} InputType={'input'}
                       onChange={this.handleChange.bind(this)} />
          </div>}
        {this.props.name === "Factoid" &&
          <FormInput label="Factoid" stateName="factoid"
                     required={true} id={this.props.id}
                     value={this.state.factoid} InputType={'textarea'}
                     onChange={this.handleChange.bind(this)} />}
        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  onFileAdded(backgroundImageUrl, imageFile) {
    this.setState({ imageFile, backgroundImageUrl });
  }

  _buildFormData() {
    const { imageFile, caption, factoid } = this.state;
    let formData = new FormData();

    if (imageFile) formData.append('challenge[background_image]', imageFile);
    if (caption) formData.append('challenge[background_image_caption]', caption);
    if (factoid) formData.append('challenge[factoid]', factoid);
    formData.append('program_id', this.props.programId);

    return formData;
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.imageFile && !this.state.caption && this.props.name === 'Background Image') {
      this.closeModal();
      return
    }

    this.setState({ isLoading: true });

    let method = 'put',
      url = `/admin/lessons/${this.props.lessonId}/challenge`,
      callback = this.props.handleUpdate,
      data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }
}
