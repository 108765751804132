import React, { useEffect, useMemo } from 'react';
import useThemes from '@/hooks/api/useThemes';
import ITheme from '@/components/interfaces/Theme';
import SectionEditorDropdown from './SectionEditorDropdown';

interface SectionEditorDropdownProps {
  className?: string;
  currentThemeId: number;
  labelClassName?: string;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  withNoneOption?: boolean;
}

const THEME_CHANGE_EVENT = 'custom-theme-change';

type ThemeOption = { label: ITheme['name'] | 'None', value: ITheme['id'] | null };

const ThemeDropdown = ({
  className,
  currentThemeId,
  labelClassName,
  onChange,
  withNoneOption = true
}: SectionEditorDropdownProps) => {
  const { data: themes, isLoading } = useThemes();

  const handleChange = (value: number) => {
    document.dispatchEvent(new CustomEvent(THEME_CHANGE_EVENT, { detail: value }));
    onChange(value);
  };

  useEffect(() => {
    const handleEvent = (e: CustomEvent) => onChange(e.detail);
    document.addEventListener(THEME_CHANGE_EVENT, handleEvent);

    return () => document.removeEventListener(THEME_CHANGE_EVENT, handleEvent);
  }, []);

  const themeOptions = useMemo(() => {
    if (isLoading) return [];

    const results: ThemeOption[] = themes
      .map(theme => ({
        label: theme.name, value: theme.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (withNoneOption) results.unshift({ label: 'None', value: null });

    return results;
  }, [themes, isLoading, withNoneOption]);

  const dropdownValue = themeOptions.find(option => option.value === currentThemeId);

  if (isLoading || themes.length === 0) return null;

  return (
    <SectionEditorDropdown
      className={className}
      initialValue={currentThemeId}
      label="Theme"
      labelClassName={labelClassName}
      name="section[theme_id]"
      onChange={handleChange}
      options={themeOptions}
      SelectProps={{
        value: dropdownValue,
      }}
    />
  );
};

export default ThemeDropdown;
