import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import Modal, { Footer } from 'common/Modal';
import showToast from 'common/Toast';

const DisconnectModal = ({ closeModal, setCurrentPane, integrationType }) => {
  const isConnectedToGoogle = () => integrationType === 'Google';

  const disconnectQuery = () => {
    if (isConnectedToGoogle()) {
      return (
        gql`
          mutation DisconnectFromGoogleClassroom {
            disconnectFromGoogleClassroom {
              success
            }
          }
        `
      );
    }

    return (
      gql`
        mutation DisconnectFromCanvas {
          disconnectFromCanvas {
            success
          }
        }
      `
    );
  }

  const [disconnect, { loading }] = useMutation(disconnectQuery());

  const disconnectAndClose = () => {
    disconnect().then(() => {
      closeModal();
      showToast(`${integrationType} account disconnected`);
    });
  };

  return (
    <Modal headerText={`Disconnect ${integrationType} Account`} isOpen closeable={false}>
      <p>
        By disconnecting your {integrationType} account from TCI,
        TCI assignments and grades will no longer sync to {isConnectedToGoogle() ? 'Google Classroom' : 'Canvas'}.
      </p>

      <p>
        Are you sure you want to continue?
      </p>

      <Footer
        submitting={loading}
        secondaryButtonCallback={() => setCurrentPane('info')}
        primaryButtonText="Disconnect Account"
        primaryButtonClassName="btn btn--red"
        primaryButtonCallback={disconnectAndClose}
        primaryButtonSubmit={false}
      />
    </Modal>
  );
};

DisconnectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  integrationType: PropTypes.string.isRequired,
  setCurrentPane: PropTypes.func.isRequired
};

export default DisconnectModal;
