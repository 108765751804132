import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import sectionStyles from '@/components/staffer/AssignmentModal/Section.module.scss';
import { Field } from 'react-final-form';
import { SelectField } from '@/components/common/Forms';
import ArchivedMessage from '@/components/staffer/AssignmentModal/WhatSection/ArchivedMessage';
import styles from '@/components/staffer/AssignmentModal/WhatSection/WhatSection.module.scss';
import CheckboxGroup from '@/components/common/Forms/CheckboxGroup';
import useAssignablesStore from '@/components/staffer/AssignmentModal/WhatSection/store/useAssignablesStore';
import { investigationLabel } from '@/components/common/Utils';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';

const InvestigationSelector = ({ form }) => {
  const [stream, setStream] = useState([]);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const isScience = useAssignablesStore(state => state.isScience);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const programId = useAssignablesStore(state => state.programId);
  const isMiddleSchoolProgram = useAssignablesStore(state => state.isMiddleSchoolProgram);
  const initialModels = useAssignablesStore(state => state.initialValues.modelsToAssign);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);

  useEffect(() => {
    const investigationPath = Routes.plato_api_get_investigation_assignables_assignables_path({
      lesson_id: selectedUnitLesson.value, program_id: programId,
    });

    Axios
      .get(investigationPath)
      .then((res) => {
        setStream(res.data.assignables);
      });
  }, []);

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!initialModels.length) return;

    formChangeValue(initialModels[0], 'modelsToAssign');
  }, [initialModels, loadingLessons, loadingTypes]);

  const handleInvestigationChange = (investigationId: string) => {
    const score = stream.find(investigation => investigation.value.includes(investigationId))?.score;

    setStore({ score });
    formChangeValue(investigationId, 'modelsToAssign');
  };

  return (
    <>
      {form.getState().values.type === 'investigation' && !isMiddleSchoolProgram && (
        <ArchivedMessage />
      )}
      <SelectField
        className={styles.assignableSelector}
        disabled={stream.length === 0}
        fieldClass={styles.largeField}
        label="Details"
        labelClass={sectionStyles.label}
        name="modelsToAssign"
        searchable={false}
        placeholder="Select one"
        onChange={handleInvestigationChange}
        options={stream}
        required
        rowClass={sectionStyles.fieldContainer}
      />
    </>
  );
};

export default InvestigationSelector;
