import React from 'react';
import PropTypes from 'prop-types';

import FileInput from '../Forms/FileInput';
import styles from './Instructions.module.scss';

export default function Instructions({
  addFile, csvTemplateUrl, file, importType, programsPath, triggerCsvCheck
}) {
  function checkCsv() {
    triggerCsvCheck();
  }

  function getPluralizedModel() {
    if (importType === 'student' || importType === 'shared_classes_student') return 'students';
    return importType; // 'staff' is the only other option
  }

  return (
    <div>
      <p className="mb15">
        Add or edit
        {' '}
        {getPluralizedModel()}
        {' '}
        by uploading a CSV file with their
        info such as email, password, and program code.
        <br />
        <a
          href="https://www.teachtci.com/helpcenter"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn more
        </a>
      </p>

      <p className="mb10">
        <b>Not sure how to get started?</b>
      </p>

      <ul className={styles.ul}>
        <li>
          Download sample
          {' '}
          <a href={csvTemplateUrl} target="_blank" rel="noopener noreferrer">
            CSV template
          </a>
        </li>
        <li>
          Download all existing
          {' '}
          {getPluralizedModel()}
          {' '}
          in a CSV file
        </li>
        <li>
          Find your
          {' '}
          <a href={programsPath} target="_blank" rel="noopener noreferrer">
            program codes
          </a>
        </li>
      </ul>

      <p className="mb10">Note: All fields in the CSV are required</p>

      <FileInput
        buttonText="Upload CSV"
        id="csv-upload"
        name="csv-upload"
        accept=".csv"
        onChange={addFile}
      />

      <hr />

      <footer>
        <button
          type="button"
          className="btn btn--purple"
          disabled={!file}
          onClick={checkCsv}
        >
          Next
        </button>
      </footer>
    </div>
  );
}

Instructions.propTypes = {
  addFile: PropTypes.func.isRequired,
  csvTemplateUrl: PropTypes.string.isRequired,
  file: PropTypes.instanceOf(Object),
  importType: PropTypes.string.isRequired,
  programsPath: PropTypes.string.isRequired,
  triggerCsvCheck: PropTypes.func.isRequired,
};

Instructions.defaultProps = {
  file: null
};
