import PropTypes from 'prop-types';

const assignmentsModelPropType = PropTypes.shape({
  model_id: PropTypes.number.isRequired,
  model_type: PropTypes.string.isRequired
});

export const assignablePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});

export const assessmentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export const assessmentsPropType = PropTypes.arrayOf(assessmentPropType);

export const classroomsPropType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired
}));

export const assignmentInitialValuesPropType = PropTypes.shape({
  assessment_ids: PropTypes.arrayOf(assignmentsModelPropType),
  classroom_ids: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  dates_enforced: PropTypes.bool,
  due_datetime: PropTypes.string,
  lesson_game: PropTypes.arrayOf(PropTypes.string),
  section_notebook: PropTypes.arrayOf(PropTypes.string),
  start_datetime: PropTypes.string,
  student_seat_ids: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  unit_projects: PropTypes.arrayOf(PropTypes.string)
});
