import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { classroomsPropType } from '../../AssignmentModal/PropTypes';
import AssignmentModal from '../../AssignmentModal';
import Link from './Link';

export default class AssignmentModalTrigger extends Component {
  static propTypes = {
    assessmentsPagePath: PropTypes.string.isRequired,
    assignmentsPath: PropTypes.string.isRequired,
    classrooms: classroomsPropType.isRequired,
    containerSelector: PropTypes.string.isRequired,
    program: PropTypes.shape({
      id: PropTypes.number.isRequired,
      science: PropTypes.bool.isRequired
    }).isRequired,
    stafferAssessmentsPath: PropTypes.string.isRequired,
    stafferId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      currentAssessmentId: null,
      assessments: [],
      initialLessonId: null,
      initialValues: {},
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateAssignedColumn = this.updateAssignedColumn.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    this.getAssessments();
  }

  getAssessments() {
    return axios
      .get(
        this.props.stafferAssessmentsPath,
        { params: { for_program: this.props.program.id, non_empty: true } }
      )
      .then((response) => {
        const assessments = response.data.data;
        return this.setState({ assessments });
      });
  }

  handleLinkClick(assessment) {
    this.setState({ currentAssessmentId: assessment.id });
    this.openModal(assessment.id, assessment.lesson_id);
  }

  openModal(assessmentId, lessonId) {
    const initialValues = { modelType: 'assessment', modelsToAssign: [{ value: `Assessment-${assessmentId}` }] };
    const initialLessonId = lessonId;

    this.setState({ initialLessonId, initialValues, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ initialValues: {}, modalIsOpen: false });
  }

  updateAssignedColumn() {
    if (!this.state.currentAssessmentId) return;

    const id = `${this.props.containerSelector}${this.state.currentAssessmentId}`;
    const container = document.getElementById(id);

    if (!container) return;

    container.innerHTML = '<i class="fa fa-check text--green" aria-hidden="true" />';
  }

  renderLink(assessment) {
    return (
      <Link
        assessmentId={assessment.id}
        assignable={assessment.assignable}
        lessonId={assessment.lesson_id}
        openModal={() => this.handleLinkClick((assessment))}
      />
    );
  }

  renderAssignmentLinks() {
    return this.state.assessments.map((assessment) => {
      const id = `${this.props.containerSelector}${assessment.id}`;
      const container = document.getElementById(id);
      if (!container) return null;

      const link = this.renderLink(assessment);
      return ReactDOM.createPortal(link, container);
    });
  }

  render() {
    return (
      <div>
        {this.renderAssignmentLinks()}

        <AssignmentModal
          assessments={this.state.assessments}
          assessmentsPagePath={this.props.assessmentsPagePath}
          assignmentsPath={this.props.assignmentsPath}
          classrooms={this.props.classrooms}
          closeModal={this.closeModal}
          handlePostSave={this.updateAssignedColumn}
          initialValues={this.state.initialValues}
          initialLessonId={this.state.initialLessonId}
          modalIsOpen={this.state.modalIsOpen}
          program={this.props.program}
          stafferId={this.props.stafferId}
        />
      </div>
    );
  }
}
