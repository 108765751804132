import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import PaginatedTable from 'common/DataTable/PaginatedTable';
import PathContext from 'common/Context';
import { formatLocalTimestampWithFormat } from 'common/Utils';
import RosterEventModal from './RosterEventModal';
import styles from '../../subscribers/StaffTable/StaffTable.module.scss';
import ImportCsvButton from '../ImportCsvButton';

export default class Table extends Component {
  static propTypes = {
    canCsvUpdate: PropTypes.bool,
    columns: PropTypes.instanceOf(Array).isRequired,
    csvOnly: PropTypes.bool,
    getAutoRosterSyncsPath: PropTypes.string.isRequired,
    getRosterEventPath: PropTypes.string.isRequired,
    isSysadmin: PropTypes.bool,
    pageSize: PropTypes.number,
    paths: PropTypes.objectOf(PropTypes.string).isRequired,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    showImportCsvButton: PropTypes.bool,
    stafferId: PropTypes.number.isRequired,
    subscriberName: PropTypes.string.isRequired
  };

  static defaultProps = {
    canCsvUpdate: true,
    csvOnly: false,
    isSysadmin: false,
    pageSize: 20,
    showImportCsvButton: false
  };

  static _renderDownloadLink(url) {
    return (
      <span>
        {' - '}
        <a href={url}>
          Download File
        </a>
      </span>
    );
  }

  static renderDate(td, cellData) {
    ReactDOM.render(Table.formatDate(cellData), td);
  }

  static formatDate(date) {
    return moment.unix(date).format('MMM D, YYYY');
  }

  static renderTime(td, cellData) {
    ReactDOM.render(Table.formatTime(cellData), td);
  }

  static formatTime(time) {
    if (time) return formatLocalTimestampWithFormat(time, 'hh:mm A');

    return '';
  }

  static renderMethod(td, cellData, rowData) {
    ReactDOM.render(
      Table.getMethod(cellData, rowData),
      td
    );
  }

  static getMethod(cellData, rowData) {
    // if the sync has a manual csv, render a download link to the csv
    if (rowData.manual_csv) {
      return (
        <div>
          <p>{cellData}</p>
          <a
            href={rowData.manual_csv.url}
          >
            ({rowData.manual_csv.file_name})
          </a>
        </div>
      );
    }

    return <span>{cellData}</span>;
  }

  constructor(props) {
    super(props);

    this.state = {
      doneLoading: false,
      getPath: this.props.getAutoRosterSyncsPath.replace('pageSize', this.props.pageSize.toString()),
      modalOpen: false,
      pagesLoaded: 0,
      resources: [],
      rowData: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderCompletedBy = this.renderCompletedBy.bind(this);
    this.addResources = this.addResources.bind(this);
  }

  openModal(e, rowData) {
    e.preventDefault();
    this.setState({ modalOpen: true, rowData });
  }

  closeModal() {
    this.setState({ modalOpen: false, rowData: null });
  }

  _renderSyncTypeWithAction(rowData) {
    if (rowData.actions) {
      return (
        <a
          onClick={e => this.openModal(e, rowData)}
          href="#"
          role="button"
        >
          {rowData.sync_status}
        </a>
      );
    }

    return rowData.sync_status;
  }

  _renderSyncStatus(rowData) {
    return (
      <span>
        {this._renderSyncTypeWithAction(rowData)}
        {rowData.results_csv_url && Table._renderDownloadLink(rowData.results_csv_url)}
      </span>
    );
  }

  _renderRosterEventModal() {
    if (this.state.modalOpen) {
      return (
        <RosterEventModal
          closeModal={this.closeModal}
          getRosterEventPath={this.props.getRosterEventPath}
          modalIsOpen={this.state.modalOpen}
          resource={this.state.rowData}
          subscriberName={this.props.subscriberName}
        />
      );
    }

    return null;
  }

  _renderImportCsvButton() {
    return (
      <ImportCsvButton
        canCsvUpdate={this.props.canCsvUpdate}
        programCodes={this.props.programCodes}
        stafferId={this.props.stafferId}
      />
    );
  }

  userEmail(rowData) {
    if (rowData.staffer_email) {
      if (!this.props.isSysadmin && rowData.completed_by_sysadmin) {
        return 'TCI';
      }

      return rowData.staffer_email;
    }

    return 'Automated';
  }

  renderCompletedBy(td, cellData, rowData) {
    ReactDOM.render(
      <span>{this.userEmail(rowData)}</span>,
      td
    );
  }

  getColumnPositions() {
    const columnPos = {};

    if (this.props.csvOnly) {
      columnPos.date = 2;
      columnPos.time = [3, 4];
      columnPos.completedBy = 5;
      columnPos.syncStatus = 6;
    }
    else {
      columnPos.date = 1;
      columnPos.time = [2, 3];
      columnPos.completedBy = 4;
      columnPos.syncStatus = 5;
    }

    return columnPos;
  }

  columnDefs() {
    const columnPos = this.getColumnPositions();

    const columnDefs = [{
      createdCell: Table.renderMethod,
      targets: 0,
      width: '16%'
    },
    {
      targets: columnPos.date,
      createdCell: Table.renderDate,
      width: '16%'
    },
    {
      createdCell: Table.renderTime,
      targets: columnPos.time,
      width: '17%'
    },
    {
      targets: columnPos.completedBy,
      createdCell: this.renderCompletedBy
    },
    {
      targets: columnPos.syncStatus,
      sortable: false,
      searchable: false,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          this._renderSyncStatus(rowData),
          td
        );
      }
    }];

    if (this.props.csvOnly) {
      columnDefs.push({
        targets: 1,
        title: 'Upload ID',
        width: '12%'
      });
    }

    return columnDefs;
  }

  defaultOrder() {
    const datePos = this.props.csvOnly ? 2 : 1;

    return ([[datePos, 'desc']]);
  }

  addResources(data) {
    const state = {
      doneLoading: data.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1,
      resources: [...this.state.resources].concat(data)
    };

    if (this.state.filterSent) {
      state.doneLoading = false;
    }

    this.setState(state);
  }

  render() {
    return (
      <PathContext.Provider value={this.props.paths}>
        <div>
          <div className={styles.flexCenter}>
            <div className={styles.flexCenter}>
              {this.props.showImportCsvButton && this._renderImportCsvButton()}
            </div>
          </div>
          <PaginatedTable
            addResources={this.addResources}
            searching={false}
            columns={this.props.columns}
            columnDefs={this.columnDefs()}
            defaultOrder={this.defaultOrder()}
            doneLoading={this.state.doneLoading}
            resources={this.state.resources}
            getUrl={this.state.getPath}
            pagesLoaded={this.state.pagesLoaded}
            columnMapping={['method', 'date', 'begin_time', 'end_time', 'completed_by', 'status']}
          />

          {this._renderRosterEventModal()}
        </div>
      </PathContext.Provider>
    );
  }
}
