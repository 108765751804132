import { plato_api_remove_duplicates_slide_object_path } from '@/modules/routes';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

export interface ISlideObject {
  __typename: 'SlideObject';
  id: string;
  index: number;
  slideId: number;
}

const useSlideObjectRemover = (slideObject: ISlideObject, slideObjects: ISlideObject[], interactable: boolean, isTemplate: boolean) => {
  const { mutate, isSuccess } = useMutation({
    onMutate: () => (
      axios
        .post(plato_api_remove_duplicates_slide_object_path(slideObject.id))
        .then(res => res.data)
    ),
  });

  useEffect(() => {
    if (!interactable || isTemplate) return;
    if (!slideObject) return;
    if (!slideObjects.some(so => so.index === slideObject.index && so.id !== slideObject.id)) return;
    if (isSuccess) return;

    mutate();
  }, [slideObject, interactable, isTemplate, isSuccess]);
};

export default useSlideObjectRemover;
