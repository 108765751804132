import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import Units from './Units';

const ProgramContents = ({ programId }) => (
  <ApolloProvider client={apolloClient}>
    <Units
      programId={programId}
    />
  </ApolloProvider>
);

ProgramContents.propTypes = {
  programId: PropTypes.number.isRequired
};

export default ProgramContents;
