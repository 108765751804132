import React from 'react';
import styles from './AutorosterNotice.module.scss';

export default function AutorosterNotice() {
  return (
    <div className={`mb20 ${styles.info}`}>
      <span className={styles.content}>
        <i className={`fa fa-exclamation-circle ${styles.icon}`} aria-hidden />
        <p className={styles.text}>
          Your district manages all account information.
          To request a change, please contact your teacher.
        </p>
      </span>
    </div>
  );
}
