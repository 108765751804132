import React from 'react';
import PropTypes from 'prop-types';
import { useDataTable } from 'common/DataTable';
import * as Routes from 'routes';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import ActionsDropdown from 'common/ActionsDropdown';
import { isSupportManager } from 'common/Utils';
import styles from './LicenseTable.module.scss';
import Tooltip from '../../common/Tooltip';
import RemoveDemoAccessModal, { useRemoveDemoAccess } from './RemoveDemoAccessModal';

const tippy = require('TciTippy').default;

export default function LicenseTable({
  expiringYear, subscriber, isApiRostering, providerName, userType, canSeeRetiringMessages,
}) {
  const { selectedRowData, isRemoveDemoAccessModalOpen, toggleRemoveDemoAccessModal } = useRemoveDemoAccess();

  const seatPoolsPath = Routes.plato_api_subscriber_licenses_path({
    non_welcome: true,
    subscriber_id: subscriber.id,
    with_current_subscriptions: ''
  });

  const licensesPath = Routes.admin_subscriber_subscriptions_path({
    subscriber_id: subscriber.id
  });

  const programsPagePath = Routes.admin_subscriber_programs_path({
    subscriber_id: subscriber.id
  });

  const {
    DataTable,
    resources
  } = useDataTable(seatPoolsPath);

  const resourcesByUserType = resources.map((resource) => {
    const resourceWithTeacher = {
      ...resource,
      assigned_next_sync: resource.teachers_assigned_next_sync,
      assigned_users: resource.assigned_teacher_seats_count,
      expiring: resource.expiring_teacher_subscriptions,
      temp_access: resource.temp_teacher_subscriptions,
      total: resource.total_allowed_teacher_seats,
      user_type: 'Teachers'
    };

    const resourceWithStudent = {
      ...resource,
      assigned_next_sync: resource.students_assigned_next_sync,
      assigned_users: resource.assigned_student_seats_count,
      expiring: resource.expiring_student_subscriptions,
      temp_access: resource.temp_student_subscriptions,
      total: resource.total_allowed_student_seats,
      user_type: 'Students'
    };

    return [resourceWithTeacher, resourceWithStudent];
  }, []).flat();

  const data = rowData => ({
    initialFilters: [
      {
        operatorValue: 'eq',
        propertyValue: 'license_name',
        value: rowData.name
      }
    ]
  });

  const actionsDropdown = rowData => (
    <ActionsDropdown>
      <a
        className="dropdown-item"
        href={`${licensesPath}?${$.param(data(rowData))}`}
        role="button"
      >
        <i aria-hidden="true" className="fa fa-history" /> View order history
      </a>

      <a
        className="dropdown-item"
        href="https://www.teachtci.com/get-quote"
        role="button"
        target="_blank"
      >
        <i aria-hidden="true" className="fa fa-shopping-cart" /> Get quote
      </a>

      {(rowData.demo && userType === 'Sysadmin') && (
        <button
          type="button"
          className="dropdown-item full-size"
          onClick={() => toggleRemoveDemoAccessModal(rowData)}
        >
          <i className="fa fa-trash" />
          Remove Demo Access
          {' '}
          <i className="fa fa-lock" />
        </button>
      )}
    </ActionsDropdown>
  );

  const learnMoreLink = (
    <>
      At least one of your program editions will be unavailable after June 30, {expiringYear}.
      <a href="https://teachtci.com/retiring-program" target="_blank" rel="noreferrer noopener"> Learn more </a>
      about available upgrades.
    </>
  );

  const renderProgramsHyperlink = rowData => (
    <div className="dsp-flex-center">
      <a href={programsPagePath}>
        {rowData.programs_count}
      </a>
      { rowData.programs_retirement_years.includes(expiringYear) && canSeeRetiringMessages ? (
        <Tooltip
          content={learnMoreLink}
        >
          <i className="fa fa-warning ml5 red" />
        </Tooltip>
      ) :
        ''
      }
    </div>
  );

  const assignedNextSyncTooltip = {
    content: 'The next sync will attempt to assign licenses to this number of users' +
      ' based on the current settings in Program Assignment Rules.',
    id: 'assigned-next-sync-tooltip'
  };

  const tempAccessTooltip = {
    content: 'Temporary access provided by TCI until access issues are resolved.',
    id: 'temp-access-tooltip'
  };

  const initTooltips = () => {
    tippy.default(`#${assignedNextSyncTooltip.id}`, { content: assignedNextSyncTooltip.content });
    tippy.default(`#${tempAccessTooltip.id}`, { content: tempAccessTooltip.content });
  };

  const renderAssignedNextSyncTitle = () => ReactDOMServer.renderToStaticMarkup(
    <React.Fragment>
      Users From {providerName}
      <span id={assignedNextSyncTooltip.id}>
        <i className="fa fa-info-circle" aria-hidden />
        <span className="sr-only">{assignedNextSyncTooltip.content}</span>
      </span>
    </React.Fragment>
  );

  const renderTempAccessTitle = () => ReactDOMServer.renderToStaticMarkup(
    <React.Fragment>
      Temp&nbsp;&nbsp;
      <span id={tempAccessTooltip.id}>
        <i className="fa fa-info-circle" aria-hidden />
        <span className="sr-only">{tempAccessTooltip.content}</span>
      </span>
      Access
    </React.Fragment>
  );

  const userFilter = rowData => ({
    0: { operatorValue: 'eq', propertyValue: 'licenses_name_with_code', value: `[${rowData.code}] ${rowData.name}` }
  });

  const columnDefs = () => {
    let defs = [
      {
        targets: 0,
        title: 'License Type',
      },
      {
        targets: 1,
        title: 'User Type'
      },
      {
        render: $.fn.dataTable.render.number(','),
        targets: 2,
      },
      {
        createdCell: (td, cellData, rowData) => {
          const path = rowData.user_type === 'Students' ? Routes.admin_subscriber_students_path : Routes.admin_subscriber_teachers_path;

          // don't link to students table if user is a support manager
          if (rowData.user_type === 'Students' && isSupportManager(userType)) {
            ReactDOM.render(
              <span>{cellData.toLocaleString()}</span>,
              td
            );
          }
          else {
            ReactDOM.render(
              <a
                href={path(subscriber.id, { initialFilters: userFilter(rowData) })}
              >
                {cellData.toLocaleString()}
              </a>,
              td
            );
          }
        },
        targets: 3
      },
      {
        render: $.fn.dataTable.render.number(','),
        targets: 4,
        title: `Expiring 6/30/${expiringYear.toString().slice(-2)}`,
        width: '10%'
      },
      {
        render: $.fn.dataTable.render.number(','),
        targets: 5,
        title: renderTempAccessTitle(),
        width: '8%'
      },
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            renderProgramsHyperlink(rowData),
            td
          );
        },
        targets: 6,
        title: 'Programs'
      },
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            actionsDropdown(rowData),
            td
          );
        },
        sortable: false,
        targets: 7
      },
      {
        targets: 8,
        visible: false
      }
    ];

    if (isApiRostering) {
      // update the column defs targets

      defs = defs.map((def, idx) => {
        if (idx > 3) return { ...def, targets: def.targets + 1 };
        return def;
      });
      // insert the def at position 4
      defs.splice(4, 0, {
        render: $.fn.dataTable.render.number(','),
        targets: 4,
        title: renderAssignedNextSyncTitle(),
        width: '12%',
        createdCell: (td, cellData, rowData) => {
          if (cellData > rowData.total) {
            ReactDOM.render(
              <span className={styles.red}>
                <b>{cellData}</b>
              </span>,
              td
            );
          }
        },
      });
    }

    return defs;
  };

  const columns = () => {
    const cols = ['name', 'user_type', 'total', 'assigned_users', 'expiring',
      'temp_access', 'programs_count', 'actions', 'position'];

    if (isApiRostering) cols.splice(4, 0, 'assigned_next_sync');

    return cols;
  };

  return (
    <div className={styles.table}>
      <DataTable
        buttons={[{
          className: 'btn btn--sm btn--outline-purple mr30',
          exportOptions: {
            columns: isApiRostering ? [0, 1, 2, 3, 4, 5, 6, 7] : [0, 1, 2, 3, 4, 5, 6]
          },
          extend: 'csv',
          text: 'Export to CSV',
          customize: (csv) => {
            const csvRows = csv.split('\n');
            const splitRows = csvRows[0].split(',');
            csvRows[0] = csvRows[0].replace(splitRows[isApiRostering ? 6 : 5], '"Temp Access"');
            if (isApiRostering) {
              csvRows[0] = csvRows[0].replace(splitRows[4], `"Users From ${providerName}"`);
            }
            return csvRows.join('\n');
          }
        }]}
        columnDefs={columnDefs()}
        columns={columns()}
        defaultOrder={[[isApiRostering ? 9 : 8, 'asc'], [1, 'desc']]}
        initComplete={initTooltips}
        paging={false}
        resources={resourcesByUserType}
      />

      {isRemoveDemoAccessModalOpen && (
        <RemoveDemoAccessModal
          closeModal={() => toggleRemoveDemoAccessModal(null)}
          rowData={selectedRowData}
          isOpen={isRemoveDemoAccessModalOpen}
          subscriber={subscriber}
        />
      )}
    </div>
  );
}

LicenseTable.propTypes = {
  canSeeRetiringMessages: PropTypes.bool,
  expiringYear: PropTypes.number.isRequired,
  isApiRostering: PropTypes.bool.isRequired,
  providerName: PropTypes.string,
  subscriber: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  userType: PropTypes.string.isRequired,
};

LicenseTable.defaultProps = {
  canSeeRetiringMessages: false,
  providerName: ''
};
