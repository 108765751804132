import Axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { plato_api_panoramic_image_path } from '@/modules/routes';

type Values = {
  image: any[];
};

const useEditPanoramicImageMutation = (id: number) => (
  useMutation({
    mutationFn: async (values: Values) => {
      const formData = new FormData();

      if (values.image) {
        formData.append('panoramic_image[image]', values.image[0]);
      }

      const imageRequest = await Axios.patch(
        plato_api_panoramic_image_path(id),
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      return imageRequest.data;
    },
    mutationKey: ['useEditPanoramicImageMutation', id],
  })
);

export default useEditPanoramicImageMutation;
