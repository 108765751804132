import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import { IExerciseResult, IReferenceMaterial, IStudent, IQuestionAnswer } from '@/components/staffer/Scores/GradesTable/types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useReferenceMaterialExerciseResults from '../../../hooks/queries/exerciseResults/useReferenceMaterialExerciseResults';
import useReferenceMaterialQuestionAnswers from '../../../hooks/queries/questionAnswers/useReferenceMaterialQuestionAnswers';
import GradeCell from './index';
import { gradeCellStatus } from './utils';
interface ReferenceMaterialGradeCellProps {
  referenceMaterial: IReferenceMaterial;
  student: IStudent;
}

const ReferenceMaterialGradeCell = ({ referenceMaterial, student }: ReferenceMaterialGradeCellProps) => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const programId = useGradesTableStore(state => state.programId);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);
  const { data: exerciseResults } = useReferenceMaterialExerciseResults(classroom, lesson);
  const { data: questionAnswers } = useReferenceMaterialQuestionAnswers(referenceMaterial);

  const exerciseResultForStudent: IExerciseResult = useMemo(() => (
    exerciseResults.find(er => (
      er.student_id === student.id && er.exercise_type === 'ReferenceMaterial' && er.exercise_id === referenceMaterial.id
    ))
  ), [exerciseResults, student.id, referenceMaterial.id]);

  const questionAnswersForStudent: IQuestionAnswer[] = useMemo(() => (
    (questionAnswers || []).filter(qa => qa.student_id === student.id)
  ), [questionAnswers, student.id]);

  const gradePath = useMemo(() => (
    Routes.grade_by_student_shared_question_answers_path(student, {
      program_id: programId,
      lesson_id: lesson.id,
      classroom_id: classroom.id,
      reference_material_id: referenceMaterial.id,
      activity_type: referenceMaterial.assignable_type,
    })
  ), [programId, lesson, classroom, referenceMaterial]);

  const referenceMaterialStatus = gradeCellStatus({
    exerciseResultForStudent,
    lowScoresSettings,
    maxScore: referenceMaterial.max_score,
    questionAnswersForStudent,
  });

  return (
    <GradeCell
      id={`referenceMaterial-${referenceMaterial.id}-student-${student.id}-score`}
      status={referenceMaterialStatus}
      url={gradePath}
      updatedSinceGraded={false}
    />
  );
};

export default ReferenceMaterialGradeCell;
