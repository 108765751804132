import React, { useEffect, useState } from 'react';
import Select from 'react-select-5';
import I18n from 'modules/i18n';
import { useQuery } from '@apollo/client';
import { GetStudentsResponseType, SelectOption } from './types';
import getStudentsForProgram from './getStudentsForProgram';

interface SignInAsStudentProps {
  handleSelect: (selectedStudent: SelectOption) => void;
  selectedStudent: SelectOption;
  programId: number;
  stafferId: number;
}

interface GetStudentsVars {
  programId: number;
  stafferId: number;
}

export const SignInAsStudent = ({ handleSelect, selectedStudent, programId, stafferId }: SignInAsStudentProps) => {
  const [students, setStudents] = useState([]);
  const { loading, data } = useQuery<GetStudentsResponseType, GetStudentsVars>(
    getStudentsForProgram,
    { variables: { programId, stafferId } },
  );

  const parseStudents = (responseData) => {
    // pull all students from the API response
    const studentsFromClassrooms: SelectOption[] = responseData.staffer.classrooms.reduce((acc, classroom) => (
      [...acc, ...classroom.students]
    ), []);
    // filter out duplicate students
    return studentsFromClassrooms.filter((elem, index) => (
      studentsFromClassrooms.findIndex(obj => obj.value === elem.value) === index
    ));
  };

  useEffect(() => {
    if (loading) return;

    if (data) setStudents(parseStudents(data));
  }, [data]);

  return (
    <>
      <label htmlFor="student_select">
        <p className="mt15"><strong>{I18n.t('select_a_student')}</strong></p>
      </label>
      <Select
        classNamePrefix="Select"
        inputId="student_select"
        options={students}
        placeholder={I18n.t('select_student_or_search')}
        onChange={handleSelect}
        value={selectedStudent}
      />
    </>
  );
};

export default SignInAsStudent;
