import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import I18n from 'i18n-js';
import useDrawingContext from '../store/useDrawingContext';
import Key from './Key';
import ShortcutList, { detectMac } from './ShortcutList';
import { scaleValue } from '../Toolbar/styles';

interface ShortcutsModalProps {
  parentContainer: React.MutableRefObject<HTMLDivElement>;
}

const containerStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  bottom: `${scaleValue(toolbarEl, 0.25, 0.5)}rem`,
  right: `${scaleValue(toolbarEl, 0.25, 0.5)}rem`,
  fontSize: `${scaleValue(toolbarEl, 14, 16)}px`,
});

const headerStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  paddingTop: `${scaleValue(toolbarEl, 1.1, 1.25)}rem`,
  paddingBottom: '0.25rem',
  paddingLeft: `${scaleValue(toolbarEl, 1.5, 2.5)}rem`,
  paddingRight: `${scaleValue(toolbarEl, 1.5, 2.5)}rem`,
});

const ShortcutsModal = ({ parentContainer } : ShortcutsModalProps) => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const helpMenuOpen = useDrawingContext(state => state.helpMenuOpen);
  const toggleHelpMenuOpen = useDrawingContext(state => state.toggleHelpMenuOpen);

  return (
    <>
      <Dialog.Root open={helpMenuOpen}>
        <Dialog.Portal container={parentContainer.current}>
          <Dialog.Overlay data-testid="overlay" className="tw-bg-black tw-opacity-70 tw-z-[2000] tw-fixed tw-inset-0" />
          <Dialog.Content
            aria-label="Keyboard shortcuts"
            className="tw-absolute tw-bg-black tw-opacity-95 tw-text-white tw-w-fit tw-border-8 tw-z-[2001]
            tw-rounded-xl focus:tw-outline-none tw-max-h-full"
            onInteractOutside={toggleHelpMenuOpen}
            onEscapeKeyDown={toggleHelpMenuOpen}
            style={containerStyle(toolbarEl)}
          >
            <div
              style={headerStyle(toolbarEl)}
            >
              <h2
                className="tw-text-white tw-mb-2"
              >
                {I18n.t('keyboard_shortcuts')}
              </h2>
              <div className="tw-flex tw-items-center tw-gap-x-2">
                <p> {I18n.t('press')}</p>
                <Key keyName={`${detectMac() ? 'Cmd' : 'Ctrl'}`} />
                <p> + </p>
                <Key keyName="?" />
                <p> {I18n.t('show_or_hide_window')} </p>
              </div>
            </div>
            <hr
              className="tw-border-[1px] tw-border-dark-grey"
              style={{
                margin: `${scaleValue(toolbarEl, 0.5, 1)}rem 0`,
              }}
            />
            <ShortcutList />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default ShortcutsModal;
