import React from 'react';
import ActionsDropdown from 'components/common/ActionsDropdown';
import * as Routes from 'modules/routes';
import AddVocabularyGameButton from './AddVocabularyGameButton';
import DropdownLink from './DropdownLink';
import useLessonGamesStore from '../../../../stores/useLessonGamesStore';

interface TableActionsProps {
  hasChallenge?: boolean;
  hasVocabGame: boolean;
}

const TableActions = ({ hasChallenge, hasVocabGame }: TableActionsProps) => {
  const lessonId = useLessonGamesStore(state => state.lessonId);
  const programId = useLessonGamesStore(state => state.programId);

  if (!(lessonId || programId)) return null;

  return (
    <ActionsDropdown id={`lesson_id_${lessonId}`} toggleClassName="btn--green" toggleText="Add Game">
      <DropdownLink
        disabledText="Only one lesson game is allowed per lesson."
        href={Routes.edit_admin_lesson_challenge_path(lessonId, { program_id: programId })}
        isDisabled={hasChallenge}
        icon="fa-edit"
        text="Lesson Game"
      />
      <AddVocabularyGameButton hasVocabGame={hasVocabGame} />
    </ActionsDropdown>
  );
};

export default TableActions;
