import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '@/modules/i18n';
import Dropdown from '@/components/common/Dropdown';
import useSlideShowStore, { ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { inIframe } from '@/modules/TCIUtils';
import useFlags from '@/hooks/useFlags';
import styles from '../../Navigator.module.scss';
import Switch from './Switch';
import FullScreen, { FullScreenPropTypes } from './FullScreen';
import { autoplayMediaSetting, slidesHaveAutoplayAudioOrVideo } from '../../../Utils';
import SlideShowEditButton, { SlideShowEditButtonPropTypes } from './SlideShowEditButton';
import ResetSlideShowButton from './ResetSlideShowButton/ResetSlideShowButton';
import SettingsTooltip from './SettingsTooltip';

export const useSettings = (storageKey, initialState = {}) => {
  const [settings, setSettings] = useState(initialState);

  const currentSettings = () => JSON.parse(localStorage.getItem(storageKey)) || {};

  const update = (key, value) => {
    setSettings({ ...settings, [key]: value });
    localStorage.setItem(storageKey, JSON.stringify({ ...currentSettings(), [key]: value }));
  };

  const toggleAutoplayMedia = (newValue, slideShowId) => {
    const autoplayMediaSettings = currentSettings()['autoplayMedia'] || {};
    autoplayMediaSettings[slideShowId] = newValue;
    update('autoplayMedia', autoplayMediaSettings);
  };

  useEffect(() => {
    setSettings(currentSettings() || initialState);
  }, [storageKey]);

  return { settings, update, toggleAutoplayMedia };
};

const Settings = ({
  fullScreenProps,
  needsExplanationTooltip,
  slideShowEditButtonProps,
  settings,
  slideShow,
  toggleAutoplayMediaEnabled,
  update,
}) => {
  const activityType = useSlideShowContext(state => state.activityType);
  const userType = useSlideShowContext(state => state.userType);
  const slideShowFromStore = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const slides: ISlideOrSlideGroup[] = useSlideShowContext(state => state.slides);
  const isProfessionalDevelopment = useSlideShowStore(state => state.slideShow.activityType === 'professional_development');
  const canViewSlideConditionsToggle = ['Teacher', 'Admin', 'Coordinator', 'Sysadmin'].includes(userType);
  const isResettable = useSlideShowContext(state => state.isResettable);
  const { autoplayAudio } = useFlags();
  let { autoplayMedia: autoplayMediaSettings = {} } = settings;

  const resettableSlideShowTypes = [
    'video_activity', 'professional_development', 'classroom_activity', 'lesson_outline',
  ];

  const showResetButton = () => {
    if (userType === 'Student') return isResettable;

    return resettableSlideShowTypes.includes(activityType);
  };

  return (
    <Dropdown
      buttonClass={styles.settingsButton}
      dropdownClass={styles.settingsDropdown}
      dropdownPosition="top-end"
      render={({ isOpen }) => (
        <React.Fragment>
          <p className={styles.dropdownHeader}>Settings</p>
          {slideShowFromStore && slideShowFromStore.hasSlideConditions && canViewSlideConditionsToggle &&
            <div className={styles.dropdownItem}>
              <Switch
                checked={settings.overrideSlideConditions}
                label={I18n.t('slide_conditions_override')}
                id="overrideSlideConditions"
                onChange={newValue => update('overrideSlideConditions', newValue)}
                tooltip={I18n.t('slide_conditions_override_tooltip')}
              />
            </div>
          }
          <div className={styles.dropdownItem}>
            <Switch
              checked={settings.autoHide}
              label={I18n.t('auto_hide_slide_show_toolbar_label')}
              id="autoHide"
              onChange={(newValue) => {
                update('autoHide', newValue);
              }}
              tooltip={I18n.t('auto_hide_slide_show_toolbar_tooltip')}
            />
          </div>
          {autoplayAudio && slidesHaveAutoplayAudioOrVideo(slides) &&
            <div className={styles.dropdownItem}>
              <Switch
                checked={autoplayMediaSetting(slideShow.id, autoplayMediaSettings)}
                label={I18n.t('autoplay_toggle_label')}
                id="autoplayMedia"
                onChange={toggleAutoplayMediaEnabled}
                tooltip={I18n.t('autoplay_toggle_tooltip')}
              />
            </div>
          }
          {!inIframe() && userType !== 'Student' && activityType !== 'marketing' && !isProfessionalDevelopment && (
            <SlideShowEditButton {...slideShowEditButtonProps} slideShow={slideShow} />
          )}
          {isOpen && showResetButton() && <ResetSlideShowButton />}
          <FullScreen {...fullScreenProps} />
        </React.Fragment>
      )}
      toggleText={<SettingsTooltip needsExplanationTooltip={needsExplanationTooltip} />}
    />
  );
};

Settings.defaultProps = {
  settings: {},
};

Settings.propTypes = {
  fullScreenProps: FullScreenPropTypes.isRequired,
  needsExplanationTooltip: PropTypes.bool.isRequired,
  settings: PropTypes.object,
  slideShowEditButtonProps: SlideShowEditButtonPropTypes.isRequired,
  slideShow: PropTypes.object.isRequired,
  toggleAutoplayMediaEnabled: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default Settings;
