import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { modalManagerPropTypes } from 'common/Modal';
import AddImage from 'admin/ImageManager/Add';
import EditImage from 'admin/ImageManager/Edit';
import { slidePropTypes } from '../../Utils';
import useSlideShowImageFetcher from '../../Utils/useSlideShowImageFetcher.ts';
import styles from './BackgroundDropdownActions.module.scss';
import useSlideShowContext from '../../stores/useSlideShowContext';

const AddBackgroundImage = ({ modalManager, slide }) => {
  const { fetchImages } = useSlideShowImageFetcher();
  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const updateSlide = useSlideShowContext(state => state.updateSlide);

  const hasImage = !!slide.imageModel;
  const text = hasImage ? 'Edit Background Image' : 'Add Background Image';

  const updateContext = (res) => {
    const imageModel = res.length ? res[0] : res;
    updateSlide(slide.id, { imageModel });
  };

  const sharedFormProps = {
    afterMergeDuplicates: updateContext,
    afterSubmit: updateContext,
    dontMergeCallback: updateContext,
    getExistingImages: fetchImages,
    modalManager,
    modelId: parseInt(slide.id, 10),
    modelType: 'Slide',
    standaloneButton: false
  };

  const imageModel = hasImage ? {
    ...slide.imageModel,
    id: parseInt(slide.imageModel.id, 10),
    image_credits: slide.imageModel.image.credits,
    image_descriptionConfidence: slide.imageModel.image.descriptionConfidence,
    image_descriptionEn: slide.imageModel.image.descriptionEn,
    image_descriptionEs: slide.imageModel.image.descriptionEs,
    image_fileName: slide.imageModel.image.fileName,
    image_fileNameEs: slide.imageModel.image.fileNameEs,
    image_titleEn: slide.imageModel.image.titleEn,
    image_titleEs: slide.imageModel.image.titleEs
  } : null;

  return (
    <Fragment>
      <button type="button" className={`${styles.dropdownItem} dropdown-item`} onClick={modalManager.open}>{text}</button>
      {hasImage ? (
        <EditImage
          isAdmin={isAdmin}
          headerText={text}
          imageModel={imageModel}
          {...sharedFormProps}
          fromSlideShow
          excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
        />
      ) : (
        <AddImage
          isAdmin={isAdmin}
          headerText={text}
          {...sharedFormProps}
          fromSlideShow
          excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
        />
      )}
    </Fragment>
  );
};

AddBackgroundImage.propTypes = {
  modalManager: modalManagerPropTypes.isRequired,
  slide: slidePropTypes.isRequired
};

export default AddBackgroundImage;
