import React, { useReducer, useRef, useLayoutEffect, useEffect, useState } from 'react';
import axios from 'axios';
import I18n from 'i18n-js';
import cn from '@/utils/cn';
import * as Routes from 'modules/routes';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import DndAnswerContext from 'components/common/Context';
import clsx from 'clsx';
import ResetReadingGameBtn from '@/components/common/ResetReadingGameBtn';
import useProgramContext from '@/hooks/useProgramContext';
import styles from './Answer.module.scss';
import bankStyles from './DraggableChoicesBank/DraggableChoicesBank.module.scss';
import { DragAndDropQuestionType, DraggableContainerPosition } from '../types';
import answerChoiceReducer from './reducers/answerChoiceReducer';
import DraggableChoicesBank from './DraggableChoicesBank';
import DropZoneArea from './DropZoneArea';
import useQuestionAnswerSave from './DraggableChoicesBank/useQuestionAnswerSave';
import QuestionAnswer from '../../../../../interfaces/QuestionAnswer';
import { usePreviousAnswers, useSubmitDisabled } from './hooks/helpers';
import SubmissionTextHelper from '../../../SubmissionTextHelper';
import { formatVocab } from '../utils';
import useVocabularyStore from '../useVocabularyStore';
import CustomDragLayer from './CustomDragLayer';

interface DragAndDropProps {
  gradingView?: boolean;
  inheritedScaleValue?: number;
  isSubmittable?: boolean;
  lessonId?: number;
  locale: Locale;
  parentContainerId: string;
  progressIcon?: React.ReactNode;
  question: DragAndDropQuestionType;
  readingGameId?: number,
  shouldDisplayPrompt?: boolean,
  showAnswerKey?: boolean;
  slideShowId?: number,
  studentId?: string;
  useFloatingSubmitBtn?: boolean;
  useReadingGameResetBtn?: boolean;
}

export const DIMENSIONS = {
  width: 960,
  height: 540,
};

const DragAndDrop = ({
  gradingView = false,
  inheritedScaleValue = 1,
  isSubmittable = false,
  locale,
  question,
  parentContainerId,
  progressIcon,
  readingGameId,
  useReadingGameResetBtn,
  showAnswerKey = false,
  shouldDisplayPrompt = true,
  studentId = null,
  useFloatingSubmitBtn = false,
  slideShowId = null,
}: DragAndDropProps) => {
  const { lessonId } = useProgramContext();
  const dragAndDropContainerRef = useRef();
  const [questionAnswer, setQuestionAnswer] = useState<QuestionAnswer>();
  const [loading, setLoading] = useState(true);
  const setVocabularyTerms = useVocabularyStore(state => state.setVocabularyTerms);
  const scalar = useRef(0);
  const setVocabLoading = useVocabularyStore(state => state.setLoading);

  useEffect(() => {
    if (!lessonId) return;

    const route = Routes.plato_api_get_glossary_terms_glossary_terms_path({ lesson_id: lessonId });

    axios.get(route)
      .then((response) => {
        if (response.data.glossary_terms) setVocabularyTerms(formatVocab(response.data.glossary_terms));
        setVocabLoading(false);
      });
  }, []);

  const doResize = () => {
    const el = $(dragAndDropContainerRef.current);
    const parentContainer = $(`#${parentContainerId}`);
    const elHeight = el.outerHeight();
    const elWidth = el.outerWidth();
    const parentHeight = parentContainer.outerHeight();
    const parentWidth = parentContainer.outerWidth();
    const scale = Math.min(
      parentWidth / elWidth,
      parentHeight / elHeight,
    );
    const marginLeft = (parentWidth - (elWidth * scale)) / 2;
    scalar.current = scale;

    // the height and width values below are the base size values
    // that we then scale up or down based on the slide template object size
    // the width x height values here determine the aspect ratio for the whole question
    el.css({
      width: `${DIMENSIONS.width}px`,
      height: `${DIMENSIONS.height}px`,
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
      marginLeft: `${marginLeft}px`,
      transformOrigin: 'top left',
    });
  };

  useLayoutEffect(() => {
    // Transform styles won't be applied when D&D is displayed in the modal when showing the answer key
    if (showAnswerKey && !gradingView) return;

    // resize twice to get the most accurate sizing after react refreshes the component
    doResize();
    doResize();

    // Set the parent container height after resizing to trim the overflow created by scaling
    const $parentContainer = $(`#${parentContainerId}`);
    if ($parentContainer.outerHeight() !== 0 || $parentContainer.outerWidth() !== 0) {
      $parentContainer.css({ height: `${DIMENSIONS.height * scalar.current}px` });
    }
  }, [parentContainerId, showAnswerKey]);

  const {
    background_image: background,
    body: questionBody,
    id: questionId,
    data: questionData,
  } = question;

  const {
    correct_answers: correctAnswers,
    draggable_choices: draggableChoices,
    draggable_container_position: draggableContainerPosition,
    drop_zones: dropZones,
    text_areas: textAreas,
    has_transparent_answer_choices: hasTransparentAnswerChoices,
    has_transparent_dropzones: hasTransparentDropzones,
  } = questionData;

  const [answerChoices, answerChoiceDispatch] = useReducer(answerChoiceReducer, {});

  useEffect(() => {
    Object.keys(answerChoices).forEach((key) => {
      answerChoiceDispatch({ payload: { draggableChoiceId: key }, type: 'REMOVE' });
    });
  }, [question.id]);

  useEffect(() => {
    setQuestionAnswer(null);
  }, [question.id]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(Routes.plato_api_question_answers_path({
        question_id: question.id,
        student_id: studentId,
        slideshow_id: slideShowId,
      }))
      .then((res) => {
        if (res.data.length === 0) return;
        if (res.data.question_answers.length === 0) return;

        setQuestionAnswer(res.data.question_answers[0]);
        const storedAnswer = JSON.parse(res.data.question_answers[0].answer);

        answerChoiceDispatch({
          payload: storedAnswer,
          type: 'INITIALIZE_FROM_DB',
        });
      }).catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [question.id]);

  useLayoutEffect(() => {
    window.addEventListener('toggled', doResize);
  }, []);

  const questionAnswerSave = useQuestionAnswerSave({
    correctChoices: correctAnswers,
    isSubmittable,
    loading,
    slideShowId,
    parseAnswer: (qa: QuestionAnswer) => JSON.parse(qa.answer),
    pointsEarned: questionAnswer?.earned_points || 0,
    previousAnswers: questionAnswer?.answer_data?.attempts_made,
    pointsPerDraggable: questionAnswer?.answer_data?.points_per_draggable || {},
    values: answerChoices,
    questionId,
  });

  const submissionTextHelper = new SubmissionTextHelper({
    correctChoices: questionAnswerSave.correctChoices,
    hasSubmitted: questionAnswerSave.previousAnswers.length > 0,
    maxAttempts: 2,
    maxPoints: question.points,
    pointsEarned: questionAnswerSave.pointsEarned,
    previousAnswers: questionAnswerSave.previousAnswers,
    submitting: questionAnswerSave.submitting,
    value: questionAnswerSave.value,
    questionType: 'DragAndDrop',
  });

  const isSubmitDisabled = useSubmitDisabled({
    answerChoices,
    isComplete: submissionTextHelper.hasCorrectAnswer(),
    isSubmitting: questionAnswerSave.submitting,
    previousAnswers: questionAnswerSave.previousAnswers,
    value: questionAnswerSave.value,
  });

  const previousAnswers = usePreviousAnswers({ previousAnswers: questionAnswerSave.previousAnswers });
  const isComplete = submissionTextHelper.hasCorrectAnswer() || questionAnswerSave.previousAnswers.length === 2 || gradingView;
  const cumulativeDndScalar = inheritedScaleValue * (scalar ? scalar.current : 1);
  const isHorizontallyPositioned = [
    DraggableContainerPosition.Top, DraggableContainerPosition.Bottom,
  ].includes(draggableContainerPosition);

  return (
    <DndAnswerContext.Provider
      value={{
        answerChoiceDispatch,
        answerChoices,
        correctAnswers,
        background,
        locale,
        draggableChoices,
        dropZones,
        hasTransparentAnswerChoices,
        hasTransparentDropzones,
        parentContainerId,
        pointsPerDraggable: questionAnswerSave.pointsPerDraggable,
        question,
        questionBody,
        questionId,
        showAnswerKey,
        textAreas,
        studentId,
        useFloatingSubmitBtn,
      }}
    >
      <section
        className={cn(
          styles.dragAndDropQuestionContainer, {
            [styles.answerKeyStyles]: showAnswerKey && !gradingView,
            'tw-flex-row-reverse': draggableContainerPosition === DraggableContainerPosition.Right,
            'tw-flex-col-reverse': draggableContainerPosition === DraggableContainerPosition.Bottom,
            'tw-flex-col': draggableContainerPosition === DraggableContainerPosition.Top,
          },
        )}
        ref={dragAndDropContainerRef}
      >
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          {!loading && (
            <>
              {progressIcon}
              <DraggableChoicesBank
                isSubmitDisabled={isSubmitDisabled}
                onSubmit={questionAnswerSave.submit}
                submissionTextHelper={submissionTextHelper}
                isSubmittable={isSubmittable}
                isComplete={isComplete}
                shouldDisplayPrompt={shouldDisplayPrompt}
                isHorizontallyPositioned={isHorizontallyPositioned}
              />
              <DropZoneArea
                correctChoices={questionAnswerSave.correctChoices}
                cumulativeDndScalar={cumulativeDndScalar}
                isComplete={isComplete}
                isDragDisabled={questionAnswerSave.submitting || isComplete}
                draggableContainerPosition={draggableContainerPosition}
                previousAnswers={previousAnswers}
              />
              <CustomDragLayer />
            </>
          )}
        </DndProvider>
      </section>
      {useFloatingSubmitBtn && isSubmittable && (
        <div className={clsx(bankStyles.submitBtnContainer, { [bankStyles.floating]: useFloatingSubmitBtn })}>
          <>
            {submissionTextHelper.submissionAlert()}
            <div className="tw-flex tw-gap-4">
              <button
                className="btn btn--purple"
                disabled={isSubmitDisabled}
                onClick={() => questionAnswerSave.submit(true)}
                type="button"
              >
                {I18n.t('submit')}
              </button>
              {useReadingGameResetBtn && readingGameId && (
              <ResetReadingGameBtn
                disabled={Object.keys(answerChoices).length === 0 && questionAnswerSave.previousAnswers.length === 0}
                readingGameId={readingGameId}
              />
              )}
            </div>

          </>
        </div>
      )}
    </DndAnswerContext.Provider>
  );
};

export default DragAndDrop;
