import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Spinner = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading) {
    return (
      <div className={styles.loading}>
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        <span className="sr-only">Loading. Please wait...</span>
      </div>
    );
  }

  return null;
};

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Spinner;
