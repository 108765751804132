/* eslint-disable react/no-danger */

import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from '@/utils/cn';
import styles from './TextArea.module.scss';
import { getTextContent, positionedContainerStyle, sizedContainerStyle } from '../../utils';
import useVocabularyStore from '../../useVocabularyStore';

interface TextAreaType {
  id: string;
  text: string;
  left: number;
  top: number;
  height?: number;
  vocabId?: string;
  width?: number;
}

const TextArea = ({ height, id, text, left, top, width, vocabId }: TextAreaType) => {
  const textAreaContainerRef = useRef();
  const label = `Text area ${id.toUpperCase()}`;

  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const vocabLoading = useVocabularyStore(state => state.loading);
  const [textContent, setTextContent] = useState('');

  useLayoutEffect(() => {
    setTextContent(getTextContent(vocabId, vocabularyTerms, vocabLoading, text, 'definition'));
  }, [vocabId, vocabularyTerms, vocabLoading, text]);

  return (
    <div
      className={cn({ 'tw-text-base tw-leading-4': !!vocabId })}
      style={positionedContainerStyle(left, top, width, height)}
      role="button"
      aria-label={label}
      tabIndex={0}
    >
      <div style={sizedContainerStyle()}>
        <div className={styles.textareaContainer} ref={textAreaContainerRef}>
          <div
            className={styles.textarea}
            dangerouslySetInnerHTML={{ __html: textContent }}
          />
        </div>
      </div>
    </div>
  );
};

export default TextArea;
