import cn from '@/utils/cn';
import React, { useState } from 'react';
import { Resource } from './utils';

interface TabbedContentProps {
  resources: Resource[];
}

export const TabbedContent = ({ resources }: TabbedContentProps) => {
  const [id, setId] = useState(resources[0].id);

  return (
    <div className="tw-flex tw-py-8 tw-px-4 tw-overflow-hidden">
      <ul className="tw-flex tw-flex-col tw-border-r tw-border-solid tw-border-med-grey tw-flex-1">
        {resources.map(resource => (
          <li key={resource.id} className="tw-w-full tw-pr-4 tw-box-border">
            <button
              className={cn(
                'tw-w-full tw-border-none tw-py-4 tw-bg-white tw-pl-2 tw-text-dark-grey tw-outline-none',
                'tw-border-0 tw-border-l-4 tw-border-transparent tw-border-solid tw-box-border',
                'hover:tw-text-tci-purple hover:tw-border-l-tci-purple',
                'focus:tw-text-tci-purple focus:tw-border-l-tci-purple focus:tw-outline-none',
                'tw-text-left tw-transition-all tw-cursor-pointer tw-text-base',
                {
                  'tw-text-tci-purple tw-border-l-tci-purple': id === resource.id,
                },
              )}
              type="button"
              onClick={() => setId(resource.id)}
            >
              {resource.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tw-overflow-y-auto tw-w-4/5">
        {resources.map(resource => (
          <div
            className={cn({
              'tw-invisible !tw-h-0 tw-overflow-hidden': resource.id !== id,
              'tw-pb-2': resource.id === id,
            },
              'tw-pl-4')}
            dangerouslySetInnerHTML={{ __html: resource.html }}
            key={resource.id}
          />
        ))}
      </div>
    </div>
  );
};
