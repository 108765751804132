import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import { graphQlValidModel } from '@/components/common/Utils';
import useImageModel from '@/hooks/api/useImageModel';
import { useModalManager } from 'components/common/Modal';
import EditImageModel from '@/components/admin/ImageManager/Edit';
import { useExcludedFields } from '@/components/admin/QuestionImage/utils';
import BuilderContext from '@/components/common/Context';

interface EditBackgroundImageButtonProps {
  imageModelId: number;
  userType: UserType;
}

type EditResponse = { id: string, mediaMeta: { position: string }, image: { imageUrl: string, imageEsUrl: string } };

const EditBackgroundImageButton = ({ imageModelId, userType }: EditBackgroundImageButtonProps) => {
  const { backgroundDispatch } = useContext(BuilderContext);
  const { data, isSuccess, refetch } = useImageModel({ id: imageModelId });

  const afterSubmit = async (imageModel?: EditResponse) => {
    if (imageModel) {
      const backgroundImage = {
        id: imageModel.id,
        position: imageModel.mediaMeta.position,
        url: imageModel.image.imageUrl,
      };

      backgroundDispatch({ payload: { backgroundImage }, type: 'SET' });
    }

    await refetch();
  };

  const modalManager = useModalManager();
  const excludeFields = useExcludedFields(userType);

  if (!isSuccess) return null;

  return (
    <EditImageModel
      afterMergeDuplicates={afterSubmit}
      afterSubmit={afterSubmit}
      btnClasses="!tw-mb-0 dropdown-item !tw-cursor-pointer tw-min-w-full tw-shadow-none"
      buttonText="Edit Background Image"
      dontMergeCallback={afterSubmit}
      excludeFields={excludeFields}
      fromSlideShow
      imageModel={graphQlValidModel(data)}
      isAdmin={userType === 'Admin' || userType === 'Sysadmin' || userType === 'ContentManager'}
      modelType="Question"
      modelId={data.id}
      modalManager={modalManager}
      useButtonIcon={false}
    />
  );
};

const EditBackgroundImageButtonWrapper = (props: EditBackgroundImageButtonProps) => (
  <ApolloProvider client={apolloClient}>
    <QueryClientProvider client={queryClient}>
      <EditBackgroundImageButton {...props} />
    </QueryClientProvider>
  </ApolloProvider>
);

export default EditBackgroundImageButtonWrapper;
