import React, { Component } from 'react';
import Axios from 'axios';
import Form from './Form';
import { ModalButton, DeleteButton, SafetyNoteThumbnail } from '../Utils';

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const {
      imageUrl, name, unit, canDelete, confirmationModalIsOpen
    } = this.state;

    return (
      <tr>
        <td className="image"><img src={imageUrl} alt={name} /></td>
        <td>{name}</td>
        <td className="unit">{unit}</td>
        <td className="safety-notes">{this.renderSafetyNotes()}</td>
        <td className="actions">
          <ModalButton
            buttonText="Edit"
            buttonClass="btn btn--sm btn--outline-purple unstyled"
            modalHeader="Edit Material"
            modalClass="edit-materials"
            openModal={this.openModal.bind(this)}
            closeModal={this.closeModal.bind(this)}
            onUpdate={this.updateMaterial.bind(this)}
            FormComponent={Form}
            units={this.props.units}
            {...this.state}
          />
          <br />
          <DeleteButton
            confirmationModalIsOpen={confirmationModalIsOpen}
            canDelete={canDelete}
            openConfirmationModal={this.openConfirmationModal.bind(this)}
            closeConfirmationModal={this.closeConfirmationModal.bind(this)}
            handleDelete={this.handleDelete.bind(this)}
            isDeleting={this.state.isDeleting}
          />
        </td>
      </tr>
    )
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openConfirmationModal(e) {
    e.preventDefault();
    if (this.props.canDelete) this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeObject(this.props.id);
  }

  renderSafetyNotes() {
    let safetyNotes = this.state.safetyNotes,
        safetyNoteIds = this.state.safetyNoteIds;

    return (
      safetyNotes.filter(safetyNote => safetyNoteIds.includes(safetyNote.id))
        .map(safetyNote => <SafetyNoteThumbnail key={safetyNote.id} {...safetyNote} />)
    );
  }

  updateMaterial(material) {
    this.setState(material);
    this.props.updateRow(material);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/materials/${this.props.id}`).then(response => {
      response.data ? this.handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    });
  }
}

Row.defaultProps = {
  name: '',
  unit: '',
  canDelete: true,
  safetyNoteIds: [],
  safetyNotes: [],
  modalIsOpen: false,
  confirmationModalIsOpen: false,
  isDeleting: false
};

