import React from 'react';
import { useModalManager, SubmitModal } from 'components/common/Modal';
import { TextField } from 'components/common/Forms';
import { required } from 'components/common/Forms/Validators';
import * as Routes from 'modules/routes';
import showToast from 'components/common/Toast';

const GradeSync = () => {
  const modalManager = useModalManager();

  const afterSubmit = () => {
    showToast(
      'Successfully scheduled background jobs to sync assignment grades',
      { autoClose: 6000 },
    );
  };

  return (
    <>
      <button
        className="btn btn--link-purple tw-p-0 tw-text-[0.95rem] !tw-no-underline tw-hover:no-underline"
        type="button"
        onClick={modalManager.open}
      >
        Rerun Grade Syncs
      </button>
      <SubmitModal
        headerText="Rerun Grade Sync"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
        submitPath={Routes.plato_api_sync_grades_assignments_path()}
        method="post"
      >
        <TextField
          name="ids"
          placeholder="Enter a comma separated list of Assignment IDs to sync"
          label="Assignment IDs to sync"
          validate={required}
          required
        />
      </SubmitModal>
    </>
  );
};

export default GradeSync;
