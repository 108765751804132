import React from 'react';
import clsx from 'clsx';
import Pluralize from 'pluralize';
import useFlags from '@/hooks/useFlags';
import TrendsAndClearButtons from './TrendsAndClearButtons';
import TrendsButton from './TrendsButton';
import ClearAllButton from './ClearAllButton';
import SyncGradesButton from './SyncGradesButton';
import { SCORE_STYLES } from '../../../variables';
import { TruncateTitle } from '../../../utils/TruncateTitle';

interface HeaderCellProps {
  children?: React.ReactNode;
  title: string;
  maxScore?: number;
}

const HEADER_CELL_CLASSES = 'tw-flex tw-flex-col tw-gap-2.5 tw-h-full';
const { updatedGradesTable } = useFlags();

const HeaderCellInfo = (maxScore) => {
  return updatedGradesTable ? (
    <div className="tw-text-[12px]">
      <span className={SCORE_STYLES}>{Pluralize('PT', maxScore, true)}</span>
    </div>
  ) : (
    <div>
      <span className="tw-text-[14px] tw-font-light">Max Score: </span>
      <span className={`${SCORE_STYLES} tw-text-[18px]`}>{maxScore}</span>
    </div>
  );
};

export const HeaderCell = ({ children = null, title, maxScore }: HeaderCellProps) => (
  <div className={clsx(HEADER_CELL_CLASSES)}>
    {TruncateTitle(title, updatedGradesTable)}
    {maxScore != null && HeaderCellInfo(maxScore)}
    {children}
  </div>
);

HeaderCell.ClearAllButton = ClearAllButton;
HeaderCell.TrendsButton = TrendsButton;
HeaderCell.TrendsAndClearButtons = TrendsAndClearButtons;
HeaderCell.SyncGradesButton = SyncGradesButton;

export default HeaderCell;
