import { useQuery } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';
import axios from 'axios';
import { INotebook } from '../../types';
import useGradesTableStore from '../../store/useGradesTableStore';

interface IApiResponse {
  data: INotebook[];
}

const useScorableNotebooks = () => {
  const lesson = useGradesTableStore(state => state.lesson);

  const isEnabled = !!lesson?.id;

  return useQuery(['ScorableNotebooks', lesson?.id], () => (
    axios.get<IApiResponse>(Routes.plato_api_scorable_notebooks_notebooks_path({
      lesson_id: lesson.id,
      includes: ['section'],
      extraAttributes: ['section_header', 'section_id'],
    })).then(res => res.data.data)
  ), { enabled: isEnabled, placeholderData: [] });
};

export default useScorableNotebooks;
