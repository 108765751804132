/**
 * InteractiveModal: renders a modal to contain interactive activities such as maps or drawing.
 *
 * props:
 *      isOpen (bool): whether the modal is open
 *      closeOnOverlayClick (bool): enables clicking outside the content area to close modal
 *      closeFunction (function): callback function that closes the modal
 *      children (ReactNode): text to be rendered in the header of the modal
 */

import * as Dialog from '@radix-ui/react-dialog';
import React, { useRef } from 'react';
import clsx from 'clsx';
import cn from '@/utils/cn';
import i18n from '@/modules/i18n';
import useModalResizer from './useModalResizer';

interface InteractiveModalProps {
  children?: React.ReactNode,
  closeOnOverlayClick?: boolean,
  isOpen: boolean,
  closeFunction: () => void,
  nearlyFullScreen?: boolean,
}

const InteractiveModal = ({
  children, isOpen, closeFunction, closeOnOverlayClick = false, nearlyFullScreen = false,
}: InteractiveModalProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (e: Event) => {
    e.preventDefault();
    if (closeOnOverlayClick) closeFunction();
  };

  const contentStyle = () => {
    if (!nearlyFullScreen) return {};

    const { containerWidth, containerHeight } = useModalResizer({ resizeContainer: overlayRef });

    return { width: containerWidth, height: containerHeight };
  };

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          ref={overlayRef}
          className="tw-z-[1100] tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-75"
          data-testid="overlay"
        >
          <button
            aria-label={i18n.t('close_interactive_modal')}
            className={cn(
              'tw-fixed tw-top-3 tw-right-4 tw-z-99 tw-bg-transparent tw-border-none',
              'tw-flex tw-items-center tw-justify-center tw-cursor-pointer',
            )}
            onClick={closeFunction}
            type="button"
          >
            <span
              className={cn('fa fa-times fa-2x tw-text-white tw-w-5 tw-h-5')}
            />
          </button>
        </Dialog.Overlay>
        <Dialog.Content
          style={contentStyle()}
          onInteractOutside={handleOverlayClick}
          onEscapeKeyDown={closeFunction}
          className={clsx({
            'tw-left-1/2 tw-top-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-z-9999 tw-min-w-[330px]': true,
            'tw-min-h-[186px] tw-fixed tw-bg-white tw-rounded-xl tw-border-2': true,
            'tw-border-white tw-border-solid tw-overflow-hidden': true,
            'tw-h-[85vh] tw-w-[90vw]': !nearlyFullScreen,
          })}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default InteractiveModal;
