import React from 'react';
import PropTypes from 'prop-types';
import styles from './Filters.module.scss';

export default function FilterPopup(props) {
  const renderPopup = () => {
    if (props.show === false) {
      return null;
    }
    return (
      <div className={styles['popup-div']}>
        {props.html}
      </div>
    );
  };

  return (
    <div className={styles['filter-container']}>
      {props.children}
      {renderPopup()}
    </div>
  );
}

FilterPopup.propTypes = {
  children: PropTypes.node,
  html: PropTypes.node,
  show: PropTypes.bool
};

FilterPopup.defaultProps = {
  children: null,
  html: null,
  show: false
};
