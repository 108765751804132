import React, { useState } from 'react';
import { capitalize } from '@/components/common/Utils';
import styles from '../ContentActions.module.scss';
import { PopoverData } from '../types';

const MAX = 237;
const MIN = 0;

type PaddingEditorProps = {
  initialValue?: string | number;
  onChange: (data: PopoverData) => void;
  onSubmit: (data: PopoverData) => Promise<any>;
  type?: string;
};

const PaddingEditor = ({ initialValue, onChange, onSubmit, type = 'padding' }: PaddingEditorProps) => {
  const [value, setValue] = useState(initialValue || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className={`${styles.container} ${styles.paddingContainer}`}>
      <div className={styles.contentWrapper}>
        <div className={styles.paddingWrapper}>
          <div className={styles.paddingInner}>
            <label htmlFor={type}>{capitalize(type)}</label>
            <input
              className={styles.paddingInput}
              min="0"
              max="237"
              type="number"
              name={type}
              onChange={(e) => {
                const newValue = Math.max(MIN, Math.min(MAX, parseFloat(e.target.value || '0')));
                setValue(newValue);
                onChange({ [type]: newValue });
              }}
              placeholder=" "
              value={value || ''}
            />
          </div>
          <span>px</span>
        </div>
        <div className={styles.btnWrapper}>
          <button
            className={`${styles.submitBtn}`}
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              setIsSubmitting(true);
              onSubmit({ [type]: typeof value === 'string' ? parseFloat(value) : value })
                .then(() => setIsSubmitting(false));
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaddingEditor;
