import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import CompForm from './CompForm';

export default class AddComp extends Component {
  static propTypes = {
    compPath: PropTypes.string.isRequired,
    licenses: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })),
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    updateTable: PropTypes.func.isRequired
  };

  static defaultProps = { licenses: null };

  constructor(props) {
    super(props);

    this.state = { modalOpen: false };
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  _openModal() {
    this.setState({ modalOpen: true });
  }

  _closeModal() {
    this.setState({ modalOpen: false });
  }

  _renderModal() {
    return (
      <Modal
        headerText="Add Temp Access"
        isOpen={this.state.modalOpen}
        closeModal={this._closeModal}
      >
        <CompForm
          closeModal={this._closeModal}
          compPath={this.props.compPath}
          licenses={this.props.licenses}
          programs={this.props.programs}
          updateTable={this.props.updateTable}
        />
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this._renderModal()}
        <button type="button" className="btn btn--green mr10" onClick={this._openModal}>
          Add Temp Access
        </button>
      </div>
    );
  }
}
