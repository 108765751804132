/**
 * `SubmitModal` Component
 *
 * A modal component that includes a form with a customizable submit button. The `SubmitModal`
 * component is designed to handle form submissions within a modal dialog. It provides a
 * flexible way to customize the appearance and behavior of the submit button using the
 * `SubmitModal.SubmitButton` component.
 *
 * Example usage:
 *
 * ```jsx
 * <SubmitModal
 *   isOpen={isOpen}
 *   close={handleClose}
 *   onSubmit={handleSubmit}
 *   headerText="Submit Form"
 * >
 *   <input type="text" name="example" />
 *   <SubmitModal.SubmitButton>Custom Submit</SubmitModal.SubmitButton>
 * </SubmitModal>
 * ```
 *
 * `SubmitModal.SubmitButton`:
 *
 * A special button component that can be used to override the default submit button in the
 * `SubmitModal`. This component accepts all the props of `SubmitButton`, with an optional
 * `asChild` prop.
 *
 * - `asChild` (boolean): If true, the `SubmitModal.SubmitButton` will render the child component
 *   passed to it, while merging the props. If false or not provided, it will render the
 *   default `SubmitButton` component using the props passed to the SubmitModal.SubmitButton.
 *
 * Example usage with `asChild` prop:
 *
 * ```jsx
 * <SubmitModal.SubmitButton asChild>
 *   <button className="custom-button">Custom Submit</button>
 * </SubmitModal.SubmitButton>
 * ```
 *
 * Example usage without `asChild` prop:
 *
 * ```jsx
 * <SubmitModal.SubmitButton>
 *   Custom Submit
 * </SubmitModal.SubmitButton>
 * ```
 *
 * When `asChild` is true, the `SubmitModal.SubmitButton` will render the child component
 * provided, merging its own props with the child's props and styles. When `asChild` is false
 * or not provided, it will render the default `SubmitButton` component with the provided
 * props.
 */

import React from 'react';
import Modal from '@/components/common/Modal';
import { LinkButton, SubmitButton } from '@/components/ui/Buttons';
import { ISubmitButtonProps } from '@/components/ui/Buttons/SubmitButton';
import Slot from '@/components/ui/Slot';

type AsChildProps<DefaultElementProps> =
  | ({ asChild?: false } & DefaultElementProps)
  | { asChild: true; children: React.ReactNode };

type ButtonProps = AsChildProps<ISubmitButtonProps> & {
  style?: React.CSSProperties;
  className?: string;
};

const ButtonOverride = ({ asChild, ...props }: ButtonProps) => {
  const Component = asChild ? Slot : SubmitButton;

  return (
    <Component {...props} />
  );
};

export interface SubmitModalProps {
  /** Additional classes to apply to the modal body */
  bodyClassName?: string;
  /** Modal contents */
  children: ReactChildren;
  /** Additional classes to apply to the modal */
  className?: string;
  /** Close the modal */
  close: () => void;
  /** Additional classes to apply to the form */
  formClassName?: string;
  /** Modal header text */
  headerText: string;
  /** Is the modal open? */
  isOpen: boolean;
  /** Is the form loading? */
  isLoading?: boolean;
  /** Is the form valid? */
  isValid?: boolean;
  /** Is the modal nearly full screen? */
  nearlyFullScreen?: boolean;
  /** Form submission handler */
  onSubmit: (any) => void;
  /** Submit button text */
  submitText?: string;
  /** Submit button text when submitting */
  submittingText?: string;
}

const SubmitModal = ({
  bodyClassName = '',
  children,
  className = '',
  close,
  formClassName = '',
  headerText,
  isOpen,
  isLoading = false,
  isValid = true,
  nearlyFullScreen = false,
  onSubmit,
  submitText = 'Submit',
  submittingText = 'Submitting…',
}: SubmitModalProps) => {
  const childrenArray = React.Children.toArray(children);

  const submitButtonOverride = childrenArray.find(
    (child: React.ReactElement) => child.type === SubmitModal.SubmitButton,
  ) as React.ReactElement;

  const filteredChildren = childrenArray.filter(
    (child: React.ReactElement) => !(
      React.isValidElement(child) && child.type === SubmitModal.SubmitButton
    ),
  );

  return (
    <Modal
      bodyClassName={bodyClassName}
      className={className}
      closeModal={close}
      headerText={headerText}
      isOpen={isOpen}
      nearlyFullScreen={nearlyFullScreen}
    >
      <form
        autoComplete="off"
        className={`tw-flex tw-flex-col tw-h-full ${formClassName}`}
        onSubmit={onSubmit}
      >
        {filteredChildren}
        <div className="tw-mt-auto">
          <hr />
          <footer className="tw-flex tw-justify-end tw-items-baseline">
            <LinkButton onClick={close}>
              Cancel
            </LinkButton>
            {submitButtonOverride && (
              <ButtonOverride {...submitButtonOverride.props} />
            )}
            {!submitButtonOverride && (
              <SubmitButton
                disabled={!isValid}
                submitting={isLoading}
                submittingText={submittingText}
              >
                {submitText ?? 'Submit'}
              </SubmitButton>
            )}
          </footer>
        </div>
      </form>
    </Modal>
  );
};

SubmitModal.SubmitButton = ButtonOverride;

export default SubmitModal;
