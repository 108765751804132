import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal';
import styles from './PrintModal.module.scss';
import I18n from 'i18n';
import printSvg from '../../../../images/icons/print.svg';

PrintModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired
};

export default function PrintModal({
  closeModal, headerText, modalIsOpen, url
}) {
  const handleClick = (language) => {
    const locale = language === 'Spanish' ? '&locale=es' : '';
    window.open(url + locale, '_blank');
    closeModal();
  };

  const renderLanguageOption = language => (
    <button
      className={styles.languageButton}
      onClick={() => handleClick(language)}
      type="button"
    >
      <img src={printSvg} alt="" className={styles.printIcon} />
      {language}
    </button>
  );

  const renderFooter = () => (
    <div className={styles.footer}>
      <button
        className="btn btn--link-purple"
        onClick={closeModal}
        type="button"
      >
        Cancel
      </button>
    </div>
  );


  return (
    <Modal
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText={headerText}
      className={styles.modal}
    >
      <div className={styles.content}>
        <div className={styles.warningBox}>
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <span className={styles.warningText}>
            {I18n.t('warning_message')}
          </span>
        </div>
        <div className={styles.languageOptionsContainer}>
          {renderLanguageOption('English')}
          {renderLanguageOption('Spanish')}
        </div>
      </div>
      <hr />
      {renderFooter()}
    </Modal>
  );
}
