import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './DragAndDrop.module.scss';
import cn from '../../../../../../utils/cn';

const getHeight = (height, isDragging) => {
  if (isDragging) return 0;
  if (typeof height === 'string') return height;

  return `${height}%`;
};

const getStyles = (left, top, isDragging, height, width, minDimensions, shape) => ({
  borderRadius: shape === 'circle' ? '50%' : '',
  height: getHeight(height, isDragging),
  left: `${left}%`,
  minHeight: `${minDimensions.height}%`,
  minWidth: `${minDimensions.width}%`,
  opacity: isDragging ? 0 : 1,
  position: 'absolute',
  top: `${top}%`,
  width: `${width}%`,
});

const DraggableContainer = ({
  children,
  draggable,
  handleKeyDown,
  item: {
    height,
    id,
    left,
    label,
    shape,
    title,
    text,
    top,
    type,
    width,
    vocabId,
  },
  minDimensions,
  type: draggableType,
}) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    item: { children, height, id, left, shape, text, title, top, type, width },
    type
  }), [height, id, left, shape, top, text, title, type, width]);

  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  const isVocabDraggable = !!vocabId;
  const isVocabTextArea = isVocabDraggable && draggableType === 'TextArea';

  if (isVocabDraggable) minDimensions.height = 8;

  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging, height, width, minDimensions, shape)}
      className={cn(styles.draggableContainer, {
        'tw-text-base tw-leading-4': isVocabTextArea,
      })}
      role="button"
      aria-label={label}
      onKeyDown={draggable ? handleKeyDown : () => { }}
      tabIndex={0}
      draggable={draggable}
    >
      {children}
    </div>
  );
};

DraggableContainer.propTypes = {
  children: PropTypes.node,
  draggable: PropTypes.bool,
  handleKeyDown: PropTypes.func.isRequired,
  item: PropTypes.shape({
    height: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    left: PropTypes.number.isRequired,
    shape: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    top: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    vocabId: PropTypes.number,
    width: PropTypes.number.isRequired,
  }).isRequired,
  minDimensions: PropTypes.shape({ height: PropTypes.number, width: PropTypes.number }).isRequired,
  type: PropTypes.oneOf(['Draggable', 'TextArea']),
};

DraggableContainer.defaultProps = {
  children: React.createElement('div'),
  draggable: true,
  type: 'Draggable',
};

export default DraggableContainer;
