import { useQuery } from '@tanstack/react-query';
import QueryKeys from '@/utils/QueryKeys';
import axios from 'axios';
import { plato_api_classrooms_path } from '@/modules/routes';

export interface IClassroom {
  connected_lms_type: string | null;
  course_number: string;
  full_name: string;
  grades: string[];
  grades_arr: string[];
  id: number;
  is_connected_to_canvas_lti_1_3: boolean | null;
  lms_course_id: number | null;
  lms_course_name: string | null;
  lms_sync_date: string | null;
  lms_sync_time: string | null;
  lms_type: string | null;
  name: string;
  period: string;
  program: {
    full_title_with_edition: string;
    id: number;
  };
  same_uid_classrooms: IClassroom[];
  seat_pool_id: 81569;
  student_lms_account_count: 0;
  student_seats_count: 0;
  subjects: string[];
  subjects_arr: string[];
  teachers: string;
  teachers_count: number;
  term_end_date: string;
  term_name: string;
  term_start_date: string;
  uid: string;
  updated_at: string;
}

interface ApiResponse {
  data: IClassroom[];
}

export default function useClassrooms({ params = {}, ...rest }) {
  return useQuery({
    placeholderData: [],
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_classrooms_path(params))
        .then(res => res.data.data)
        .catch(() => ([]))
    ),
    queryKey: [QueryKeys.UseClassrooms, params],
    ...rest,
  });
}

export function useStafferClassrooms(stafferId, params = {}) {
  return useClassrooms({ params: { for_staffer: stafferId, ...params }, enabled: !!stafferId });
}
