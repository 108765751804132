import React from 'react';
import I18n from 'modules/i18n';
import { useQuery } from '@apollo/client';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import StafferTurnInButton from '@/components/admin/SlideShow/PresentMode/TransitionSlide/StafferTurnInButton';
import styles from './TransitionSlide.module.scss';
import partyIcon from '../../../../../../../assets/images/party-icon.svg';
import { GET_PD_FINAL_SLIDE_DATA } from '../../Utils/slideShowGraphQL';

const ProfessionalDevelopmentFinalSlide = () => {
  const isPresentMode = useSlideShowContext(state => state.isPresentMode);
  const lessonId = useSlideShowContext(state => state.lessonId);
  const programId = useSlideShowContext(state => state.programId);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const userId = useSlideShowContext(state => state.userId);
  const stafferId = useSlideShowContext(state => state.stafferId);

  const { lesson } = slideShow;

  const queryData = {
    fetchPolicy: 'no-cache',
    variables: {
      programId,
      slideShowId,
      userId: userId || stafferId,
    },
  };
  // @ts-ignore
  const { loading, data } = useQuery(GET_PD_FINAL_SLIDE_DATA, queryData);

  return (
    <>
      <section id="left-panel" className={styles.leftPanel}>
        <div className={styles.titleContainer}>
          <img className={styles.partyIcon} src={partyIcon} alt="" />
          <span>
            {I18n.t('you_just_finished')}
            :
          </span>
          {!loading && <span className={styles.title}>{data.slideShow.lesson.titleWithPosition}</span>}
        </div>
      </section>
      <section id="right-panel" className={styles.rightPanel}>
        <div>
          {!loading && data.slideShow.lesson.professionalDevelopment && (
            <StafferTurnInButton
              badgeUrl={data.slideShow.courseBadgeUrl}
              completed={data.slideShow.lesson.professionalDevelopment.completedForStaffer}
              exerciseResult={data.slideShow.lesson.professionalDevelopment.stafferExerciseResult}
              isPresentMode={isPresentMode}
              lessonData={data.slideShow.lesson}
              lessonId={lessonId}
              programId={programId.toString()}
              notebook={lesson.professionalDevelopment}
              unansweredQuestions={data.slideShow.unansweredQuestions}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ProfessionalDevelopmentFinalSlide;
