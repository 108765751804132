import { QueryClient } from '@tanstack/react-query';
import create from 'zustand';
import { z } from 'zod';

export const queryClient = new QueryClient();

export const MIN_COL_WIDTH_CLASS = 'tw-min-w-[315px]';

export const newGlossaryTerm = (deckId: number, locale: 'en' | 'es' = 'en') => {
  if (locale === 'en') {
    return ({
      model_id: deckId,
      model_type: 'Deck',
      glossary_term_attributes: {
        term_en: 'Enter term',
        term_es: 'Ingrese el término',
        definition_en: 'Enter definition',
        definition_es: 'Ingrese definición',
      },
    });
  }

  return {};
};

export enum QueryKeys {
  DECK = 'DECK',
  DECKS = 'DECKS',
}

/**
 * STORE VARIABLES
 */

interface IVocabDecksStoreVariables {
  currentUser?: { first_name: string, id: number, last_name: string, type: string };
  deckId?: number;
  lessonId?: number;
  programId?: number;
}

interface IVocabDecksStoreState extends IVocabDecksStoreVariables {
  setStore: (newState: IVocabDecksStoreVariables) => void;
}

export const useVocabDecksStore = create<IVocabDecksStoreState>(set => ({
  currentUser: undefined,
  deckId: undefined,
  lessonId: undefined,
  programId: undefined,
  setStore: newState => set(state => ({ ...state, ...newState })),
}));

/**
 * SCHEMA VARIABLES
 */

export const glossaryTermModelSchema = z.object({
  id: z.number(),
  position: z.number(),
  glossary_term: z.object({
    id: z.number(),
    term_en: z.string()
      .max(100, { message: '100 character limit exceeded' })
      .min(1, { message: 'Term required' }),
    term_es: z.string()
      .max(100, { message: '100 character limit exceeded' })
      .min(1, { message: 'Term required' }),
    s3_url: z.string().optional(),
    definition_en: z.string().max(600, { message: '600 character limit exceeded' }).optional(),
    definition_es: z.string().max(600, { message: '600 character limit exceeded' }).optional(),
    glossary_image: z.instanceof(FileList).optional(),
  })
    .refine(
      data => data.definition_en || !data.s3_url.includes('missing'),
      { message: 'Definition must be present or image must be provided', path: ['definition_en'] },
    ),
  updated_at: z.string().optional(),
});

export const schema = z.object({
  deck: z.object({
    title_en: z.string(),
    title_es: z.string(),
    glossary_term_models: z.array(glossaryTermModelSchema),
  }),
});

export const dbSchema = schema.transform(input => ({
  ...input.deck,
  glossary_term_models: undefined,
  glossary_term_models_attributes: input.deck.glossary_term_models.map(glossary_term_model => ({
    ...glossary_term_model,
    glossary_term: undefined,
    glossary_term_attributes: { ...glossary_term_model.glossary_term, s3_url: undefined },
  })),
}));

export type SchemaType = z.infer<typeof schema>;
