import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import cn from '@/utils/cn';

interface TooltipContentProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  disabled?: boolean;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, disabled, ...props }, ref) => {
  if (disabled) return null;

  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        `z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0
      zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95
      data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2
      data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`,
        className,
      )}
      {...props}
    />
  );
});

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip = Object.assign(TooltipPrimitive.Root, {
  Arrow: TooltipPrimitive.Arrow,
  Content: TooltipContent,
  Portal: TooltipPrimitive.Portal,
  Provider: TooltipPrimitive.Provider,
  Trigger: TooltipPrimitive.Trigger,
});

export default Tooltip;
