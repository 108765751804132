import { gql } from '@apollo/client';

export const FETCH_LESSON_IMAGE = gql`
  query GetLessonImage($id: ID!) {
    lesson(id: $id) {
      imageModel {
        alignment
        captionEn
        captionEs
        decorative
        displaySize
        id
        zoomType
        image {
          credits
          descriptionConfidence
          descriptionEn
          descriptionEs
          fileName
          fileNameEs
          titleEn
          titleEs
        }
      }
    }
  }
`;

export interface ImageModel {
  alignment?: string;
  captionEn?: string;
  captionEs?: string;
  decorative: boolean;
  displaySize: string;
  hideCaptions: boolean;
  id: number;
  image: Image;
  zoomType?: string;
}

export interface FlattenedImageModel extends ImageModel {
  image_credits?: string;
  image_descriptionConfidence?: number;
  image_descriptionEn?: string;
  image_descriptionEs?: string;
  image_fileName: string;
  image_titleEn?: string;
  image_titleEs?: string;
}

interface Image {
  credits?: string;
  descriptionConfidence?: number;
  descriptionEn?: string;
  descriptionEs?: string;
  fileName: string;
  titleEn?: string;
  titleEs?: string;
}

export interface ApiResponse {
  lesson: {
    imageModel?: ImageModel
  }
}
