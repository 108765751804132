import Axios from 'axios';
import { formatTimestamp } from '../QuestionUtils';

export const formatAnswerData = (questionAnswer) => {
  if (!questionAnswer) return null;

  return questionAnswer.answer_data || questionAnswer.answerData;
};

export const getPreviousAnswers = (questionAnswer) => {
  const answerData = formatAnswerData(questionAnswer);

  if (!answerData) return [];

  if (!answerData.attempts_made) return [];

  return answerData.attempts_made;
};

type SubmitTypes = {
  ajaxUrl: string,
  parseAnswer?: Function,
  questionId: number,
  questionType?: string,
  setNewState: Function,
  isSubmitting?: boolean,
  value: string | number,
};

export const submit = async ({
  ajaxUrl,
  questionId,
  setNewState,
  value,
  isSubmitting = true,
  parseAnswer = undefined,
}: SubmitTypes) => {
  setNewState({ status: null, submitting: true });

  await Axios
    .post(ajaxUrl, { question_answer: { answer: value, question_id: questionId, submit: isSubmitting } })
    .then((response) => {
      const { data } = response;

      if (data.errors) {
        setNewState({ status: '500' });
        console.log(data.errors);
      } else {
        const formattedDate = formatTimestamp(data.question_answer.updated_at, true);

        if (isSubmitting) {
          const { correct_choices: correctChoices } = data;

          setNewState({
            ...(correctChoices ? { correctChoices } : {}),
            previousAnswers: getPreviousAnswers(data.question_answer),
            status: '200',
            updatedAt: formattedDate,
            value: parseAnswer ? parseAnswer(data.question_answer) : data.question_answer.answer,
            pointsEarned: data.question_answer.score && parseFloat(data.question_answer.score.toString()),
            pointsPerDraggable: data.question_answer.answer_data.points_per_draggable || {},
          });
        } else {
          setNewState({ status: '200', updatedAt: formattedDate });
        }
      }
    })
    .catch((error) => {
      setNewState({ status: '400' });
      console.log(error);
    })
    .then(() => setNewState({ submitting: false }));
};

export default submit;
