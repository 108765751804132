import React from 'react';
import googleClassroomLogo from 'images/icons/google-classroom-icon.png';
import schoologyLogo from 'images/icons/schoology-icon.svg';
import canvasLogo from 'images/icons/canvas_logo.svg';

interface LmsButtonProps {
  children: ReactChildren;
  handleClick: () => void;
  showGoogleClassroomLogo: boolean;
  showCanvasLogo: boolean;
  showSchoologyLogo: boolean;
}

const LmsButton = ({
  children, handleClick, showGoogleClassroomLogo, showCanvasLogo, showSchoologyLogo
}: LmsButtonProps) => (
  <button
    type="button"
    className="dropdown-item tw-cursor-pointer tw-w-full"
    onClick={handleClick}
  >
    <div className="tw-flex tw-items-center">
      {showGoogleClassroomLogo && (
        <img width="20" alt="Connected to Google" src={googleClassroomLogo} />
      )}
      {showCanvasLogo && <img width="20" alt="Connected to Canvas" src={canvasLogo} />}
      {showSchoologyLogo && <img width="20" alt="Connected to Schoology" src={schoologyLogo} />}

      <span className="ml5">
        {children}
      </span>
    </div>
  </button>
);

export default LmsButton;
