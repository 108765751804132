import React, { useEffect } from 'react';
import Tooltip from 'components/common/Tooltip';
import { Footer } from 'components/common/Modal';
import { ConditionalWrap } from 'components/common/Utils';
import { useForm, SubmitHandler } from 'react-hook-form';
import useFlags from '@/hooks/useFlags';
import styles from './LmsSelection.module.scss';
import useCredentialStore from '../useCredentialStore';

interface IFormData {
  lmsType: string;
}

type ModalManager = {
  close: () => void,
  open: () => void,
  isOpen: boolean
};

type LmsSelectionType = {
  initialLmsType?: string;
  setLmsType: Function,
  next?: Function;
  modalManager: ModalManager;
  hasCompletedLTI: boolean;
  isEditing: boolean;
};

const LmsSelection = ({
  setLmsType, next, modalManager, hasCompletedLTI, initialLmsType, isEditing = false,
}: LmsSelectionType) => {
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);
  const canvasCredentials = useCredentialStore(state => state.canvasCredentials);
  const { canvasLti13 } = useFlags();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('lmsType', initialLmsType);
  }, []);

  const lmsType = watch('lmsType');

  const onSubmit: SubmitHandler<IFormData> = (formData: IFormData) => {
    setLmsType(formData.lmsType);
    next();
  };

  const hasLti13 = !!ltiCredentials?.deployment_id && !!ltiCredentials?.client_id;

  const disableCanvasApi = () => {
    if (!hasCompletedLTI) return true;
    if (isEditing && initialLmsType === 'canvas') return false;

    return !!canvasCredentials?.id;
  };

  const disableLti13 = () => {
    if (isEditing && ['canvas_1_3', 'schoology'].includes(initialLmsType)) return false;

    return hasLti13;
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <p>
        To begin your assignment and grade sync setup, please select one of the methods below.
      </p>
      <div className={styles.inputGroup}>
        <ConditionalWrap
          condition={!hasCompletedLTI}
          wrap={children => (
            <Tooltip
              content="Please set up the LTI Single Sign On integration before adding the Canvas integration."
              theme="white"
              size="medium"
            >
              <span>{children}</span>
            </Tooltip>
          )}
        >
          <label>
            <input
              disabled={disableCanvasApi()}
              value="canvas"
              type="radio"
              {...register('lmsType')}
            />
            Canvas API
          </label>
        </ConditionalWrap>
        {canvasLti13 && (
          <label className="tw-flex tw-items-center">
            <input
              disabled={disableLti13()}
              value="canvas_1_3"
              type="radio"
              {...register('lmsType')}
            />
            Canvas LTI 1.3
          </label>
        )}
        <label className="tw-flex tw-items-center">
          <input
            disabled={disableLti13()}
            value="schoology"
            type="radio"
            {...register('lmsType')}
          />
          Schoology LTI 1.3
        </label>
      </div>
      <Footer
        secondaryButtonCallback={modalManager.close}
        primaryButtonText="Next"
        primaryButtonDisabled={!lmsType}
        onClick={onSubmit}
      />
    </form>
  );
};

export default LmsSelection;
