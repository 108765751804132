import React, { Component } from 'react';
import Select from 'react-select';

export default class MaterialSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <Select name={this.props.name}
              required={true}
              arrowRenderer={null}
              value={this.props.value}
              onChange={this.props.onChange}
              options={this.props.options}
              optionComponent={OptionComponent}
              valueComponent={ValueComponent}
      />
    );
  }
}

// renders for options in select
class OptionComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelect(this.props.option, e);
  }

  handleMouseEnter(e) {
    this.props.onFocus(this.props.option, e)
  }

  render() {
    let { label, image } = this.props.option;
    return (
      <div onMouseEnter={this.handleMouseEnter.bind(this)}
           onMouseDown={this.handleMouseDown.bind(this)}
           className={`material-select-option ${this.props.className} list`}>
        <div className="select-image-container">
          <img src={image} />
        </div>
        {label}
      </div>
    );
  }
}

// renders for selected values in select
class ValueComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { label, image } = this.props.value;
    let hasImage = image !== '/missing.png';
    return (
      <span className={`material-select-option ${!hasImage && 'pl10'}`}>
        {hasImage && <div className="select-image-container">
          <img src={image} />
        </div>}
        {label}
      </span>
    )
  }
}
