import React, { useState } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import ProgramSelect from './ProgramSelect';
import ReuseProgramContent from './ReuseProgramContent';
import useProgramContentStore from './useProgramContentStore';

interface AddLessonProps {
  parentProgramId: number;
  modalManager: ModalManager
}

const AddLessonsModal = ({ parentProgramId, modalManager }: AddLessonProps) => {
  const { isOpen, close } = modalManager;
  const [selectedOption, setSelectedOption] = useState('reuse');
  const [programId, setProgramId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const copyLessonIds = useProgramContentStore(state => state.copyLessonIds);
  const copyUnitIds = useProgramContentStore(state => state.copyUnitIds);
  const reuseLessonIds = useProgramContentStore(state => state.reuseLessonIds);
  const reuseUnitIds = useProgramContentStore(state => state.reuseUnitIds);
  const isAnyChecked = useProgramContentStore(state => state.isAnyChecked);
  const reset = useProgramContentStore(state => state.reset);

  const onSubmit = () => {
    setIsLoading(true);
    if (selectedOption === 'create') {
      window.location.assign(Routes.new_admin_program_lesson_path(parentProgramId));
      return;
    }
    Axios
      .post(Routes.plato_api_copy_resources_program_path(parentProgramId), {
        copy_lesson_ids: copyLessonIds,
        copy_unit_ids: copyUnitIds,
        reuse_lesson_ids: reuseLessonIds,
        reuse_unit_ids: reuseUnitIds,
      })
      .then(() => window.location.reload())
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setProgramId(null);
  };

  return (
    <SubmitModal
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      close={close}
      headerText="Add Lesson"
      isOpen={isOpen}
      nearlyFullScreen
      bodyClassName="tw-flex tw-flex-col tw-justify-between"
      isLoading={isLoading}
      submitText="Add Content"
      isValid={selectedOption === 'create' || isAnyChecked()}
    >
      <div className="tw-h-full tw-overflow-scroll">
        <p>Create a lesson from scratch or reuse an existing one from another program.</p>

        <div className="tw-flex tw-flex-col">
          <label className="tw-flex tw-items-center tw-mb-2">
            <input
              type="radio"
              value="create"
              checked={selectedOption === 'create'}
              onChange={handleOptionChange}
            />
            <span>Create new lesson</span>
          </label>
          <label className="tw-flex tw-items-center tw-mb-2">
            <input
              type="radio"
              value="reuse"
              checked={selectedOption === 'reuse'}
              onChange={handleOptionChange}
            />
            <span>Reuse a lesson from an existing program</span>
          </label>
          {selectedOption === 'reuse' && (
            <>
              <ProgramSelect
                onSelect={(value) => {
                  setProgramId(value);
                  reset();
                }}
              />
              {programId && (
                <ReuseProgramContent programId={programId} />
              )}
            </>
          )}
        </div>
      </div>
    </SubmitModal>
  );
};

export default AddLessonsModal;
