import React from 'react';
import { useModalManager } from 'common/Modal';
import Dropdown from 'common/Dropdown';
import AddBackgroundImage from './AddBackgroundImage';
import { slidePropTypes } from '../../Utils';
import ChangeBackgroundPosition from './ChangeBackgroundPosition';
import RemoveBackgroundImage from './RemoveBackgroundImage.tsx';
import styles from '../SlideShowBuilder.module.scss';

const BackgroundDropdownActions = ({ slide }) => {
  const addImageModalManager = useModalManager();
  const removeImageModalManager = useModalManager();
  const hasImage = !!slide.imageModel;
  const text = () => (
    <div className={`mr10 ${styles.textWithIcon}`}>
      <i className="fa fa-picture-o" />
      { hasImage ? 'Edit Background Image' : 'Add Background Image' }
    </div>
  );

  return (
    <Dropdown toggleText={text} buttonClass="btn btn--outline-purple dropdown-toggle end" dropdownPosition="top">
      <AddBackgroundImage modalManager={addImageModalManager} slide={slide} />
      {!!slide.imageModel && <ChangeBackgroundPosition slide={slide} />}
      {!!slide.imageModel && (
        <RemoveBackgroundImage
          modalManager={removeImageModalManager}
          slide={slide}
        />
      )}
    </Dropdown>
  );
};

BackgroundDropdownActions.propTypes = {
  slide: slidePropTypes.isRequired
};

export default BackgroundDropdownActions;
