import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import { ApolloProvider, useMutation } from '@apollo/client';
import apolloClient from '../../../common/ApolloClient';
import { LanguageTabs } from '../../../common/InlineEditWithLanguage/index';
import Modal, { Footer, useModalManager } from '../../../common/Modal';
import GridAnswerTable from './GridAnswerTable';
import styles from './TablePromptAnswerKeyContent.module.scss';
import html2canvas from 'html2canvas';
import {
  mergeValuesIntoGridData,
  editGridDataForQuestionAndLocale,
  registerCurrentQuestionDataState
} from './utils';
import { CREATE_IMAGE_MODEL } from '../../ImageManager/Add/index';
import { UPDATE_IMAGE_MODEL } from '../../ImageManager/Edit/index';
import NotebookGrid from 'modules/grid/NotebookGrid';

export const TABLE_PROMPT_ANSWER_DESCRIPTION = 'table prompt answer image';

export type GridData = {
  chunk: string;
  rows: number;
  columns: number;
  col_ratios: string;
  height: number;
};

export type Locale = 'en' | 'es';

const TablePromptAnswerKeyContent = ({ questionId, automaticTranslation, imageModel, close, afterSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeLanguageTab, setActiveLanguageTab] = useState(I18n.locale);
  const [gridDataEn, setGridDataEn] = useState<GridData | {}>({});
  const [gridDataEs, setGridDataEs] = useState<GridData | {}>({});
  const [notebookGridEn, setNotebookGridEn] = useState<NotebookGrid>();
  const [notebookGridEs, setNotebookGridEs] = useState<NotebookGrid>();
  const [imageModelIdForSpanishImageSave, setImageModelIdForSpanishImageSave] = useState(undefined);
  const [createImageModelMutation, createMutationData] = useMutation(CREATE_IMAGE_MODEL, { context: { hasUpload: true } });
  const [updateImageModelMutation, updateMutationData] = useMutation(UPDATE_IMAGE_MODEL, { context: { hasUpload: true } });
  const tableIdEn = `answer-key-table-grid_${questionId}-en`;
  const tableIdEs = `answer-key-table-grid_${questionId}-es`;
  const isEnglish = activeLanguageTab === 'en';
  const loading = isLoading || createMutationData.loading || updateMutationData.loading;
  const confirmationModal = useModalManager();

  useEffect(() => {
    registerCurrentQuestionDataState(questionId);
  }, [registerCurrentQuestionDataState, questionId]);

  useEffect(() => {
    const answerData = imageModel?.answer_data || {};
    setGridDataEn(answerData['object_data'] || editGridData('en'));
    setGridDataEs(answerData['object_data_es'] || editGridData('es'));
  }, [imageModel, setGridDataEn, setGridDataEs]);

  useEffect(() => {
    if (imageModelIdForSpanishImageSave == undefined) return;

    saveImage('es', { id: imageModelIdForSpanishImageSave });
    setImageModelIdForSpanishImageSave(undefined);
  }, [notebookGridEs, imageModelIdForSpanishImageSave, setImageModelIdForSpanishImageSave]);

  const handleSaveClick = () => {
    const image = imageModel?.image;
    const hasExistingImage = isEnglish ? !!image?.image_en_file_name : !!image?.image_es_file_name;

    hasExistingImage ? confirmationModal.open() : saveImage();
  };

  // Manipulate the DOM to hide the grammarly extension for the screenshot
  const toggleGrammarly = ({ hidden }: { hidden: boolean }) => {
    const grammarlyElements = $('grammarly-extension');
    if (!grammarlyElements[0]) return;

    grammarlyElements.css('display', hidden ? 'none' : 'block');
  };

  const saveImage = (locale: Locale = activeLanguageTab, imageModelToSave = imageModel) => {
    const table = document.getElementById(locale === 'en' ? tableIdEn : tableIdEs);

    setIsLoading(true);
    toggleGrammarly({ hidden: true });

    html2canvas(table, { useCORS: true })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          createOrUpdateImageModel(blob, locale, imageModelToSave?.id)
            .then((result) => {
              updateForAutoTranslation(result, locale);
              afterSubmit();
            })
            .catch(e => console.error(e));
        });
      })
      .catch(e => console.error('Error creating table image!', e))
      .finally(() => {
        toggleGrammarly({ hidden: false });
        setIsLoading(false);
      });
  };

  const createOrUpdateImageModel = (blob: Blob, locale: Locale, imageModelId?: number) => {
    const localeEn = locale === 'en';
    const filename = `table-prompt-answer-${locale}-${questionId}-${Date.now()}.png`;
    const data = localeEn ? { ...gridDataEn } : { ...gridDataEs };
    const grid = localeEn ? notebookGridEn : notebookGridEs;
    data.chunk = grid.answerData().chunk;

    const basePayload = {
      [localeEn ? 'imageFile' : 'imageEsFile']: new File([blob], filename, { type: 'image/png' }),
      answerData: { [localeEn ? 'object_data' : 'object_data_es']: data },
      questionId
    };

    if (imageModelId) {
      return updateImageModelMutation({ variables: { ...basePayload, id: imageModelId } });
    }

    return createImageModelMutation({
      variables: {
        ...basePayload,
        descriptionConfidence: 0.9999,
        descriptionEn: TABLE_PROMPT_ANSWER_DESCRIPTION,
        displaySize: 'large',
        mediaMeta: { type: 'answer' },
        modelId: questionId,
        modelType: 'Question',
        readOnly: false,
        zoomType: 'magnifier',
        mediaType: 'answer'
      }
    });
  };

  const updateForAutoTranslation = (result, locale: Locale) => {
    if (locale !== 'en' || !automaticTranslation) return;

    const mutationResult = (result.data.createImageModel || result.data.updateImageModel).result
    const spanishValues = mutationResult?.imageModel?.tableCellsAnswerDataEs;
    const spanishData = mergeValuesIntoGridData(editGridData('es') as GridData, spanishValues)
    if (!spanishData) return;

    setEmptyDataForLocale('es');
    setGridDataEs({ ...spanishData });
    updateLanguageTab('es');
    setImageModelIdForSpanishImageSave(mutationResult?.imageModel?.id);
  };

  const editGridData = (locale: Locale): GridData | {} => {
    return editGridDataForQuestionAndLocale(locale, questionId);
  };

  const updateLanguageTab = (val: Locale) => {
    if (val === activeLanguageTab) return;

    setActiveLanguageTab(val);
  };

  const resetData = async () => {
    await setEmptyData();

    setGridDataEn({ ...editGridData('en') });
    setGridDataEs({ ...editGridData('es') });
  };

  const setEmptyDataForLocale = (locale: Locale) => {
    if (locale === 'en') {
      setGridDataEn({});
      setNotebookGridEn(undefined);
    }
    if (locale === 'es') {
      setGridDataEs({});
      setNotebookGridEs(undefined);
    }
  };

  const setEmptyData = async () => {
    setEmptyDataForLocale('en');
    setEmptyDataForLocale('es');
  };

  return (
    <div className={styles.modalBodyWrapper}>
      <div>
        Edit the table prompt below to create an answer image for this question. You can use the reset link to get the most recent changes to the table prompt.

        <div className={styles.gridAnswerTableWrapper}>
          <div className={styles.languageToggleWithTable}>
            <div className={styles.tableLinks}>
              <div className={styles.languageTabs}>
                <LanguageTabs
                  activeTab={activeLanguageTab}
                  setActiveTab={updateLanguageTab} />
              </div>
              <button onClick={resetData} className="btn btn--link-purple">
                Reset to current table prompt
              </button>
            </div>
            <GridAnswerTable
              visible={isEnglish}
              tableId={tableIdEn}
              notebookGrid={notebookGridEn}
              setNotebookGrid={setNotebookGridEn}
              gridData={gridDataEn}
            />
            <GridAnswerTable
              visible={!isEnglish}
              tableId={tableIdEs}
              notebookGrid={notebookGridEs}
              setNotebookGrid={setNotebookGridEs}
              gridData={gridDataEs}
            />
            <div className={styles.saveButton}>
              <button
                onClick={handleSaveClick}
                disabled={loading}
                className="btn btn--purple"
              >
                {loading && <i className="mr5 fa fa-spinner fa-spin" />}
                {loading ? 'Saving...' : 'Save Answer Image'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer
        primaryButtonText="Close"
        primaryButtonCallback={close}
        primaryButtonSubmit={false}
        secondaryButtonCallback={close}
        secondaryButtonText="Cancel"
      />

      <Modal
        isOpen={confirmationModal.isOpen}
        closeModal={confirmationModal.close}
        headerText="Replace Answer Image"
      >
        Are you sure you want to replace the existing answer image?

        <Footer
          primaryButtonText="Yes, replace"
          primaryButtonCallback={() => {
            confirmationModal.close();
            saveImage();
          }}
          primaryButtonSubmit={false}
          secondaryButtonCallback={confirmationModal.close}
          secondaryButtonText="Cancel"
        />
      </Modal>
    </div>
  );
};

const tablePromptAnswerKeyContentPropTypes = {
  questionId: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  imageModel: PropTypes.object,
};

TablePromptAnswerKeyContent.propTypes = tablePromptAnswerKeyContentPropTypes;

const TablePromptAnswerKeyContentWrapper = ({ questionId, automaticTranslation, imageModel, close, afterSubmit }) => (
  <ApolloProvider client={apolloClient}>
    <TablePromptAnswerKeyContent
      questionId={questionId}
      automaticTranslation={automaticTranslation}
      imageModel={imageModel}
      close={close}
      afterSubmit={afterSubmit} />
  </ApolloProvider>
);
TablePromptAnswerKeyContentWrapper.propTypes = tablePromptAnswerKeyContentPropTypes;

export default TablePromptAnswerKeyContentWrapper;
