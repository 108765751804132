import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { composeValidators, validUrl } from 'common/Forms/Validators';

import styles from './Location.module.scss';
import inputName from './utils';

const invalidClass = meta => (
  meta.error && meta.touched ? styles.invalid : ''
);

const UrlField = ({ currentValue }) => (
  <div className="inline_block">
    <Field
      name={`sign_in_preferences[${inputName(currentValue)}]`}
      validate={composeValidators(validUrl)}
    >
      {({ input, meta }) => (
        <div>
          <div>
            <label
              htmlFor={`${inputName(currentValue)}-input`}
            >
              URL:
            </label>

            <input
              id={`${inputName(currentValue)}-input`}
              className={`inline_block ${styles.input} ${invalidClass(meta)}`}
              {...input}
              required={currentValue === 'Other'}
            />

            {meta.error && meta.touched &&
            <span className={`${styles.fieldError} ml5`}>{meta.error}</span>}
          </div>
        </div>
      )}
    </Field>
  </div>
);

UrlField.propTypes = {
  currentValue: PropTypes.string.isRequired
};

export default UrlField;

