const firstNamesMatch = (tciStudent, lmsStudent) => (
  tciStudent.firstName.toLowerCase() === lmsStudent.firstName.toLowerCase()
);

const lastNamesMatch = (tciStudent, lmsStudent) => (
  tciStudent.lastName.toLowerCase() === lmsStudent.lastName.toLowerCase()
);

const firstInitialsMatch = (tciStudent, lmsStudent) => (
  tciStudent.firstName.toLowerCase() === lmsStudent.firstName.toLowerCase()[0]
);

const lastInitialsMatch = (tciStudent, lmsStudent) => (
  tciStudent.lastName.toLowerCase() === lmsStudent.lastName.toLowerCase()[0]
);

const isExactMatch = (tciStudent, lmsStudent) => (
  firstNamesMatch(tciStudent, lmsStudent) && lastNamesMatch(tciStudent, lmsStudent)
);

const isFirstInitialMatch = (tciStudent, lmsStudent) => (
  firstInitialsMatch(tciStudent, lmsStudent) && lastNamesMatch(tciStudent, lmsStudent)
);

const isLastInitialMatch = (tciStudent, lmsStudent) => (
  firstNamesMatch(tciStudent, lmsStudent) && lastInitialsMatch(tciStudent, lmsStudent)
);

const matchOnCondition = (matchData, tciStudents, lmsStudents, condition) => (
  lmsStudents.reduce((currentMatchData, lmsStudent) => {
    const editedMatchData = { ...currentMatchData };

    if (editedMatchData[lmsStudent.uid]) return editedMatchData;

    const matchedTciIds = Object.values(currentMatchData).filter(match => !!match).map(match => match.tciId);

    const unmatchedTciStudents = tciStudents.filter(tciStudent => !matchedTciIds.includes(tciStudent.id));

    const potentialMatches = unmatchedTciStudents.filter(tciStudent => condition(tciStudent, lmsStudent));

    if (potentialMatches.length === 1) {
      const potentialMatch = potentialMatches[0];

      editedMatchData[lmsStudent.uid] = {
        firstName: potentialMatch.firstName,
        lastName: potentialMatch.lastName,
        previouslyExisted: false,
        tciId: potentialMatch.id,
        userName: potentialMatch.userName
      };
    }

    return editedMatchData;
  }, matchData)
);

export const autoMatch = (matchData, tciStudents, lmsStudents) => {
  let newMatchData = matchOnCondition(matchData, tciStudents, lmsStudents, isExactMatch);
  newMatchData = matchOnCondition(newMatchData, tciStudents, lmsStudents, isFirstInitialMatch);
  newMatchData = matchOnCondition(newMatchData, tciStudents, lmsStudents, isLastInitialMatch);

  return newMatchData;
};
