import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button } from '@/components/ui/Buttons';

interface TextFieldWithDatePickerProps {
  label: string;
  initialDate?: Date;
  name: string;
  minDate?: Date;
}

const TextFieldWithDatePicker = ({
  label,
  initialDate,
  name,
  minDate,
}: TextFieldWithDatePickerProps) => {
  const startDate = initialDate ? new Date(initialDate) : undefined;
  const [date, setDate] = useState(startDate);
  const pickerRef = useRef<ReactDatePicker>();

  return (
    <div className="textfield-icon-button-container input date optional slide_show_access_start_date">
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label>{label}</label>
      <div className="textfield-icon-button">
        <ReactDatePicker
          ref={pickerRef}
          dateFormat="MM/dd/yyyy"
          minDate={new Date(minDate) || false}
          onChange={(newDate: React.SetStateAction<Date>) => setDate(newDate)}
          placeholderText="MM/DD/YYYY"
          selected={date}
          value={date}
          className="tw-p-4 tw-box-border tw-w-full"
        />
        {/* Hidden input that formats the date value to match the YYYY-MM-DD format used by Postgres */}
        <input
          hidden
          readOnly
          name={name}
          value={date ? date.toISOString().split('T')[0] : ''}
        />
        <Button
          small
          outlineStyle
          className="textfield-calendar-icon-button"
          onClick={() => {
            pickerRef.current.setOpen(true);
          }}
        >
          <i className="fa fa-regular fa-calendar" />
        </Button>
      </div>
    </div>
  );
};

export default TextFieldWithDatePicker;
