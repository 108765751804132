import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Tooltip from 'common/Tooltip';
import {
  Legend, RadioButton, UploadInfo, Warning
} from '../Methods';
import { SubmitError } from '../../Forms/Utils';
import { Footer } from '../../Modal';
import styles from '../StaffMethods.module.scss';

export default function UploadForm({
  canCsvUpdate, handleSubmit, numRows, submitting
}) {
  function disableButton(values) {
    return !(values.method);
  }

  function renderWarning() {
    return (
      <Warning>
        <li>Classes will be removed from all students not included in your CSV</li>
        <li>Classes will be replaced for students included in your CSV</li>
        <li>New students will be added</li>
      </Warning>
    );
  }

  function renderUpdateOption(form) {
    if (canCsvUpdate) {
      return (
        <div>
          <Field
            component={RadioButton}
            id="destructive-update"
            label="Update ALL students and classes in account"
            name="method"
            type="radio"
            value="destructive"
          />

          {form.values.method === 'destructive' && renderWarning()}
        </div>
      );
    }

    return (
      <Tooltip
        content="To update your rosters for all classes, Run Sync in Integrations."
        interactive={false}
        theme="white"
      >
        <Field
          id="destructive-update"
          name="method"
          type="radio"
          value="destructive"
        >
          {({ input }) => (
            <label htmlFor="masterUpdate" className={styles.disabled}>
              <input
                className={styles.radioButton}
                disabled
                type="radio"
                id="masterUpdate"
                {...input}
              />
              Update ALL students and classes in account
            </label>
          )}
        </Field>
      </Tooltip>
    );
  }

  function initialValues() {
    if (!canCsvUpdate) return { method: 'import' };

    return null;
  }

  function renderFormContent(form) {
    return (
      <fieldset>
        <Legend />

        <Field
          component={RadioButton}
          id="import"
          label="Add students and classes"
          name="method"
          type="radio"
          value="import"
        />

        {renderUpdateOption(form)}
      </fieldset>
    );
  }

  function renderForm(form) {
    return (
      <form onSubmit={form.handleSubmit}>
        {renderFormContent(form)}

        <SubmitError error={form.submitError} />

        <Footer
          primaryButtonText="Upload"
          primaryButtonDisabled={disableButton(form.values)}
          submitting={submitting}
          submittingText="Uploading..."
        />
      </form>
    );
  }

  return (
    <div>
      <UploadInfo numRows={numRows} />
      <Form
        initialValues={initialValues()}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </div>
  );
}

UploadForm.propTypes = {
  canCsvUpdate: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  numRows: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
};

UploadForm.defaultProps = {
  canCsvUpdate: true
};
