import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import * as Routes from 'modules/routes';
import { useQuery } from '@tanstack/react-query';

export const useTags = scope => (
  useQuery({
    placeholderData: [],
    queryFn: () => (
      axios.get(Routes.plato_api_tags_path({ [scope]: true }))
        .then(res => res.data.data)
    ),
    queryKey: ['useTags', scope],
  })
);

export const SelectAdapter = ({ input, tagIds, ...rest }) => {
  const value = input.value.filter(id => tagIds[id]).map(id => ({ label: tagIds[id], value: id }));

  const onChange = (opts) => {
    const currentValues = input.value.reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
    const newValues = opts.map(opt => opt.value).filter(id => !currentValues[id]);

    if (newValues.length === 0) {
      const otherSelectValues = input.value.filter(val => !tagIds[val]);
      const valuesToKeep = opts.map(opt => opt.value);
      input.onChange([...otherSelectValues, ...valuesToKeep]);
    }
    else {
      input.onChange([...input.value, ...newValues]);
    }
  };

  return <Select clearable multi {...input} {...rest} value={value} onChange={onChange} />;
};

SelectAdapter.defaultProps = {
  children: undefined,
  render: undefined,
};

SelectAdapter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  render: PropTypes.func,
  tagIds: PropTypes.object.isRequired,
};

export const CATEGORIES = [
  {
    label: 'Performance Expectation (Fulfilling)',
    scope: 'performance_expectation',
    name: 'tag_ids',
  },
  {
    label: 'Performance Expectation (Building Towards)',
    scope: 'performance_expectation',
    name: 'building_towards_ids',
  },
  {
    label: 'Science and Engineering Practices',
    scope: 'science_and_engineering_practices',
    name: 'tag_ids',
  },
  {
    label: 'Crosscutting Concepts',
    scope: 'crosscutting_concepts',
    name: 'tag_ids',
  },
  {
    label: 'Disciplinary Core Ideas',
    scope: 'disciplinary_core_ideas',
    name: 'tag_ids',
  },
  {
    label: 'Connections to Nature of Science',
    scope: 'connections_to_nature_of_science',
    name: 'tag_ids',
  },
  {
    label: 'Connections to Engineering, Technology, and Applications of Science',
    scope: 'connections_to_eng_tech_and_apps_of_science',
    name: 'tag_ids',
  },
  {
    label: 'Reading',
    scope: 'reading',
    name: 'tag_ids',
  },
  {
    label: 'Writing',
    scope: 'writing',
    name: 'tag_ids',
  },
  {
    label: 'Speaking and Listening',
    scope: 'speaking_and_listening',
    name: 'tag_ids',
  },
  {
    label: 'Language',
    scope: 'language',
    name: 'tag_ids',
  },
  {
    label: 'Math',
    scope: 'math',
    name: 'tag_ids',
  },
  {
    label: 'CPALMS',
    scope: 'cpalms',
    name: 'tag_ids',
  },
];
