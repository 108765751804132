import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core';
import { debounce } from '@/modules/TCIUtils';
import useDrawingContext from '../../store/useDrawingContext';
import { scaleValue } from '../styles';

const useStyles = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => {
  const width = scaleValue(toolbarEl, 100, 160);

  return makeStyles({
    root: {
      display: 'block',
      width,
    },
    rail: {
      height: 8,
      borderRadius: 8,
      backgroundColor: '#3C3C3C',
    },
    thumb: {
      height: 24,
      width: 24,
      marginTop: -9,
      backgroundColor: '#522E91',
    },
    track: {
      borderRadius: 8,
      height: 8,
      backgroundColor: '#522E91',
    },
  })();
};

interface PenSettingsInputProps {
  defaultValue?: number;
  label: string;
  max?: number;
  min?: number;
  onChange?: (newValue: number) => any;
  step?: number;
}

const PenSettingsInput = ({
  defaultValue = 100,
  label,
  max = 100,
  min = 1,
  onChange = () => { },
  step = 2,
}: PenSettingsInputProps) => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const canvas = useDrawingContext(state => state.canvas);
  const questionId = useDrawingContext(state => state.questionId);
  const [value, setValue] = useState(defaultValue);
  const classes = useStyles(toolbarEl);

  const handleChange = (_e: React.ChangeEvent | React.MouseEvent, newValue: number) => {
    setValue(newValue);
    onChange(newValue);
  };

  const saveHistory = debounce(() => {
    if (canvas.getActiveObjects().length === 0) return;

    canvas.fire('object:modified');
  }, 100);

  const ariaLabel = `${label.toLowerCase().replace(' ', '-')}-${questionId}`;

  return (
    <div>
      <label htmlFor={ariaLabel}>
        {label}
      </label>
      <div className="tw-flex tw-justify-between tw-items-center">
        <Slider
          classes={classes}
          step={step}
          value={value}
          onChange={handleChange}
          onChangeCommitted={saveHistory}
          max={max}
          min={min}
          aria-labelledby={ariaLabel}
        />
        <div className="tw-relative tw-max-w-[75px]">
          <input
            className="tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-p-2.5 tw-pr-7"
            max={max}
            min={min}
            type="number"
            value={value}
            onChange={e => handleChange(e, parseInt(e.target.value, 10))}
          />
          <div className="tw-absolute tw-py-2 tw-right-2 -tw-top-2 tw-flex tw-flex-col tw-justify-center tw-h-full">
            <button
              className="tw-border-none tw-bg-transparent tw-text-lg tw-h-1/2 tw-flex tw-cursor-pointer"
              onClick={e => handleChange(e, Math.min(100, value + 2))}
              type="button"
            >
              <span className="fa fa-angle-up" />
            </button>
            <button
              className="tw-border-none tw-bg-transparent tw-text-lg tw-h-1/2 tw-flex tw-cursor-pointer"
              onClick={e => handleChange(e, Math.max(0, value - 2))}
              type="button"
            >
              <span className="fa fa-angle-down" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PenSettingsInput;
