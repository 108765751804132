import React from 'react';
import * as Routes from 'modules/routes';
import I18n from 'modules/i18n';
import assessmentsIcon from 'images/icons/assessments-yellow-outline.png';
import ButtonWithTooltip from '@/components/shared/LessonOverview/Cards/ActionBar/ButtonWithTooltip';
import Card, { ActionBar, CardBody } from './Card';
import { BorderColors, Lesson, Program } from '../types';
import AssignButton from './AssignButton';

type AssessmentsCardProps = {
  bannerBorderColor: BorderColors,
  hasAssessments: boolean,
  lesson: Lesson,
  lessonId: number,
  program: Program,
  type: string,
  stafferId?: number,
};

const AssessmentsCard = ({
  bannerBorderColor, hasAssessments, lesson, lessonId, program, stafferId, type,
}: AssessmentsCardProps) => {
  const renderPrintButton = () => (
    <ButtonWithTooltip
      ariaLabel="Print assessment"
      link={Routes.pdf_staffer_assessment_print_path(lesson.assessments[0].id, {
        program_id: program.id,
        locale: I18n.locale,
      })}
      openInNewWindow
      tooltipContent={I18n.t('print_text')}
      iconClassName="fa fa-print"
    />
  );

  const renderActionBar = () => {
    // If there is at least one assessment that can be assigned, return the id of the first one.
    const assessmentToAssign = hasAssessments ? {
      label: lesson.assessments[0].name,
      value: `Assessment-${lesson.assessments[0].id}`,
    } : {
      label: '',
      value: '',
    };

    return (
      <ActionBar>
        <AssignButton
          initialValues={{
            modelType: 'assessment',
            modelsToAssign: [assessmentToAssign],
          }}
          isAssigned={lesson.assessments.some(assessment => assessment.isAssigned)}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
        />

        {hasAssessments && renderPrintButton()}

      </ActionBar>
    );
  };

  return (
    <Card label="assessments" type={type} bannerBorderColor={bannerBorderColor}>
      <CardBody
        ariaLabel="TCI Assessments"
        iconSrc={assessmentsIcon}
        linkHref={Routes.shared_program_lesson_assessments_path(program.id, lessonId)}
        linkLabel={`TCI ${I18n.t('assessments')}`}
      >
        {I18n.t('lesson_overview_assessments_description')}
      </CardBody>
      {stafferId && renderActionBar()}
    </Card>
  );
};

export default AssessmentsCard;
