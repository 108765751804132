import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import styles from './index.module.scss';

const CopyToClipboardField = ({ id, copyText, label }) => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      <label htmlFor={id}>
        <p>{label}</p>
      </label>
      <input
        disabled
        value={copyText}
        id={id}
        className={styles.textCopyField}
        type="text"
      />
    </div>

    <Tooltip
      content="Copied to clipboard!"
      trigger="click"
      interactive
      placement="bottom"
    >
      <button
        type="button"
        className={`btn btn--outline-purple js-clipboard-trigger ${styles.copyButton}`}
        data-clipboard-text={copyText}
      >
        Copy
      </button>
    </Tooltip>
  </div>
);

CopyToClipboardField.propTypes = {
  copyText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default CopyToClipboardField;
