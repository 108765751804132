import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import { INotebook, IStatus, IStudent } from '../../../types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useVideoActivityExerciseResult from '../../../hooks/queries/exerciseResults/useVideoActivityExerciseResult';
import useVideoActivityQuestionAnswers
  from '../../../hooks/queries/questionAnswers/useVideoActivityQuestionAnswers';
import { COLOR_LEGEND } from '../../../variables';
import GradeCell from './index';
import { roundScore } from './utils';

interface VideoActivityGradeCellProps {
  student: IStudent;
  videoActivity: INotebook;
}

export const VideoActivityGradeCell = ({ videoActivity, student }: VideoActivityGradeCellProps) => {
  const programId = useGradesTableStore(state => state.programId);
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);

  const gradePath = useMemo(() => (
    Routes.grade_by_student_shared_question_answers_path(student, {
      program_id: programId,
      lesson_id: lesson.id,
      classroom_id: classroom.id,
      activity_type: 'video_activity',
    })
  ), [programId, lesson, classroom]);

  const { data: exerciseResults } = useVideoActivityExerciseResult(classroom, lesson);
  const { data: videoActivityQuestionAnswers } = useVideoActivityQuestionAnswers();

  const exerciseResult = useMemo(() => (
    exerciseResults.find(er => (er.student_id === student.id && er.exercise_type === 'Notebook' && er.exercise_id === videoActivity.id))
  ), [exerciseResults]);

  const hasQuestionAnswers = useMemo(() => (
    videoActivityQuestionAnswers.find(qa => (qa.student_id === student.id))
  ), [videoActivityQuestionAnswers]);

  const getVideoActivityStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    // If the exercise result exists, it's either been turned in or graded.
    if (exerciseResult) {
      if (exerciseResult.score) {
        const score = roundScore(exerciseResult.score);
        const lowScoreThreshold = videoActivity.max_score * lowScoresSettings.threshold / 100;
        const shouldHighlight = lowScoresSettings.enabled && score <= lowScoreThreshold;

        status.text = score;
        if (shouldHighlight) {
          status.color = COLOR_LEGEND.low_score;
        } else if (!exerciseResult.completed_at) {
          status.color = COLOR_LEGEND.in_progress;
        }
      } else if (exerciseResult.completed_at) {
        // Has exercise result but no score.
        status.text = 'Turned In';
        status.color = COLOR_LEGEND.completed;
      }
      return status;
    }

    // Does not have an exercise result AND has Question answers for the notebook.
    if (hasQuestionAnswers) {
      status.color = COLOR_LEGEND.in_progress;
      status.text = 'In Progress';
    }

    return status;
  };

  return (
    <GradeCell
      id={`videoActivity-${videoActivity.id}-student-${student.id}-score`}
      aria-label={`View/Grade video activity score for ${student.first_name} ${student.last_name}`}
      status={getVideoActivityStatus()}
      url={gradePath}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default VideoActivityGradeCell;
