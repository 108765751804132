import { useMemo } from 'react';
import useUnits from './useUnits';
import useLessons from "@/hooks/api/useLessons";

interface UseProgramContentProps {
  programId: number;
}

const useProgramContent = ({ programId }: UseProgramContentProps) => {
  const { lessons, isLoading: isLoadingLessons } = useLessons({ programId });
  const { units, isLoading: isLoadingUnits } = useUnits({ programId });

  const isLoading = useMemo(() => isLoadingUnits || isLoadingLessons, [isLoadingLessons, isLoadingUnits]);

  const unitsWithLessons = useMemo(() => {
    if (isLoading) return [];

    return units.map(unit => ({
      ...unit,
      lessons: lessons.filter(l => l.unit_id === unit.id),
    }));
  }, [lessons.length, units.length, isLoading]);

  return { isLoading, units: unitsWithLessons, lessons };
};

export default useProgramContent;
