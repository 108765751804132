import { IClassroom, ILesson, IExerciseResult } from '@/components/staffer/Scores/GradesTable/types';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/components/staffer/Scores/GradesTable/GradesTableQueryClientProvider';
import axios from 'axios';
import { plato_api_reference_material_results_exercise_results_path } from '@/modules/routes';

interface IApiResponse {
  data: IExerciseResult[];
}

const useReferenceMaterialExerciseResults = (classroom: IClassroom, lesson: ILesson) => {
  const isEnabled = !!(classroom?.id && lesson?.id);

  const query = useQuery([QueryKeys.ReferenceMaterialExerciseResults, classroom?.id, lesson?.id], () => (
    axios
      .get<IApiResponse>(plato_api_reference_material_results_exercise_results_path({
        classroom_id: classroom.id,
        lesson_id: lesson.id,
      }))
      .then(res => res.data?.data || [])
  ), { enabled: isEnabled, placeholderData: [] });

  return { isEnabled, ...query };
};

export default useReferenceMaterialExerciseResults;
