import React from 'react';
import CommonModal from 'components/common/Modal';
import SubmitFooter from '@/components/ui/Modal/SubmitFooter';

interface SubmitModalProps {
  children: React.ReactNode;
  className?: string;
  close: () => void;
  headerText: string;
  isOpen: boolean;
  nearlyFullScreen?: boolean;
  bodyClassName?: string;
}

const Modal = ({
  children,
  close,
  ...rest
}: SubmitModalProps) => (
  <CommonModal closeModal={close} {...rest}>
    {children}
  </CommonModal>
);

Modal.SubmitFooter = SubmitFooter;

export default Modal;
