import { IMAGE_EDIT_COMMAND_NAME, IMAGE_EDIT_MARGINS_COMMAND_NAME } from './froalaConstants';

export const getImageEditButtons = (userType: UserType) => {
  const imageEditButtons = ['imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit',
    'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageSize', IMAGE_EDIT_MARGINS_COMMAND_NAME];

  // Only show image edit command for Sysadmins and content managers.
  if (['Sysadmin', 'ContentManager'].includes(userType)) {
    imageEditButtons.unshift(IMAGE_EDIT_COMMAND_NAME);
  }

  return imageEditButtons;
};
