import { plato_api_image_model_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import IImageModel from '@/components/interfaces/ImageModel';
import IImage from '@/components/interfaces/Image';

interface UseSlideShowQuestionGroupsArgs {
  id: number | string;
}

interface ImageModelResponseType extends IImageModel {
  image: IImage;
}

interface ApiResponse {
  image_model: ImageModelResponseType;
}

export default function useImageModel({ id }: UseSlideShowQuestionGroupsArgs) {
  return useQuery({
    enabled: !!id,
    queryKey: [QueryKeys.ImageModels, id],
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_image_model_path(id))
        .then(res => res.data.image_model)
    ),
  });
}
