/* eslint-disable no-param-reassign */
import * as fabric from 'fabric';
import shapeToolCursor from '../cursors/shape_tool_cursor.svg';
import { setColor } from '../hooks/useColors';

const Shape = (canvas) => {
  canvas.freeDrawingBrush = null;
  canvas.isDrawingMode = false;

  canvas.defaultCursor = `url(${shapeToolCursor}), auto`;
  canvas.hoverCursor = `url(${shapeToolCursor}), auto`;
  canvas.moveCursor = `url(${shapeToolCursor}), auto`;
};

export const placeShape = async (canvas, shapeUrl, color) => {
  const pointerPosition = canvas.getViewportPoint();

  return fabric.loadSVGFromURL(shapeUrl).then((svg) => {
    const group = new fabric.Group(svg.objects, {
      height: 100,
      left: pointerPosition.x / canvas.getZoom(),
      top: pointerPosition.y / canvas.getZoom(),
      width: 100,
    });

    group.getObjects().forEach((item) => { setColor(item, color); });

    canvas.add(group);
    canvas.setActiveObject(group);
    canvas.calcOffset();
  });
};

export default Shape;
