import React from 'react';
import { CREATE_SLIDE_GROUP } from '@/components/admin/SlideShow/Utils/slideShowGraphQL';
import { useMutation } from '@apollo/client';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';

type AddGroupButtonProps = {
  slideShowId: number;
};

const AddGroupButton = ({ slideShowId }: AddGroupButtonProps) => {
  const [createSlideGroupMutation] = useMutation(CREATE_SLIDE_GROUP);
  const updateSlideShow = useSlideShowContext(state => state.updateSlideShow);

  const handleAddGroup = () => {
    const mutationParams = {
      variables: {
        slideShowId,
        titleEn: 'Slide Group Title',
      },
    };

    createSlideGroupMutation(mutationParams)
      .then((res) => {
        updateSlideShow(res.data.createSlideGroup.slideShow);
      });
  };

  return (
    <button type="button" className="btn btn--purple" onClick={handleAddGroup}>
      <i className="fa fa-solid fa-plus mr5" />
    &nbsp;Add Group
    </button>
  );
};

export default AddGroupButton;
