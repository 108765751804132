import React, { HTMLAttributes } from 'react';
import FE from 'froala-editor';
import ReactDOMServer from 'react-dom/server';
import { ImageModelResponseType } from '@/components/admin/QuestionImage/utils';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';
import { ExclusiveRequiredFields } from '@/utils/TypeUtils';

export const getCurrentImageSnippet = (imageModelId: string | number): HTMLElement => {
  const captionQuery = `.fr-wrapper [data-snippet="[IMAGE_MODEL_${imageModelId}_CAPTIONS]"]`;
  const imgQuery = `.fr-wrapper [data-snippet="[IMAGE_MODEL_${imageModelId}]"]`;

  return document.querySelector(captionQuery) || document.querySelector(imgQuery);
};

export const transferrableStyles = (node: HTMLElement) => {
  const transferrable = [
    'width',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left',
  ];

  return transferrable.reduce((acc, cur) => {
    const key = cur.split('-').map((part, i) => {
      if (i === 0) return part;

      return part.charAt(0).toUpperCase() + part.slice(1);
    }).join('');

    if (node?.style[cur]) acc[key] = node.style[cur];

    return acc;
  }, {});
};

export const graphQlImageModelToImgTag = (
  imageModel: GraphQlImageModel,
  html: HTMLAttributes<HTMLImageElement> = {},
  styles = {},
) => (
  <img
    alt={imageModel.image.descriptionEn ? imageModel.image.descriptionEn : ''}
    data-snippet={imageModel.snippet}
    data-zoomurl={imageModel.image.imageUrl}
    data-caption-id={`caption-${imageModel.id}`}
    src={imageModel.image.imageUrl}
    style={styles}
    {...html}
  />
);

export const graphQlImageModelToCaptionsTag = (
  imageModel: GraphQlImageModel,
  html: HTMLAttributes<HTMLDivElement> = {},
  styles = {},
) => (
  <div
    className="captions"
    data-snippet={`[IMAGE_MODEL_${imageModel.id}_CAPTIONS]`}
    data-hide-captions={imageModel.hideCaptions}
    {...html}
    style={styles}
  >
    {graphQlImageModelToImgTag(imageModel, { contentEditable: false })}
    <p
      className="caption"
      data-snippet={`[IMAGE_MODEL_${imageModel.id}_CAPTION]`}
      data-caption-for={imageModel.id}
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: imageModel.captionEn }}
      style={{ visibility: imageModel.hideCaptions ? 'hidden' : 'visible' }}
    />
  </div>
);

export const graphQlImageModelToTag = (imageModel: GraphQlImageModel) => {
  const currentSnippet = getCurrentImageSnippet(imageModel.id);

  if (imageModel.captionEn && !imageModel.hideCaptions) {
    return graphQlImageModelToCaptionsTag(imageModel, { contentEditable: false }, transferrableStyles(currentSnippet));
  }

  const divStyles = transferrableStyles(currentSnippet);

  const styles = { ...divStyles };

  return graphQlImageModelToImgTag(imageModel, {}, styles);
};

export const imageModelForEditForm = (imageModel: ImageModelResponseType) => {
  if (!imageModel) return null;

  const { image } = imageModel;

  return {
    id: imageModel.id,
    captionEn: imageModel.caption_en,
    captionEs: imageModel.caption_es,
    decorative: imageModel.decorative,
    displaySize: imageModel.displaySize,
    hide_captions: imageModel.hide_captions,
    image_credits: image.credits,
    image_descriptionConfidence: image.description_confidence ? (
      parseFloat(image.description_confidence.toString())
    ) : image.description_confidence,
    image_descriptionEn: image.description_en,
    image_descriptionEs: image.description_es,
    image_fileName: image.image_en_file_name,
    image_fileNameEs: image.image_es_file_name,
    image_titleEn: image.title_en,
    image_titleEs: image.title_es,
    zoomType: imageModel.zoom_type,
    image: {
      englishImageOnly: image.english_image_only,
      hasText: image.has_text,
      imageUrl: image.url,
      imageEsUrl: image.es_url,
    },
  };
};

export const isImgSnippet = (element: HTMLElement) => {
  const snippet = element.getAttribute('data-snippet');

  return snippet?.includes('[IMAGE_MODEL_');
};

export const getImageModelIdFromElement = (element?: HTMLElement) => {
  if (!element) return null;
  if (!isImgSnippet(element)) return null;

  const match = element.getAttribute('data-snippet').match(/\d+/);

  if (match) return match[0];

  return null;
};

export const jsxToNode = (jsxElement: JSX.Element) => {
  const newElementString = ReactDOMServer.renderToStaticMarkup(jsxElement);

  const temp = document.createElement('div');
  temp.innerHTML = newElementString;

  return temp.firstChild as HTMLElement;
};

interface ReplaceInEditorArgsBase {
  cursorLocationQuery?: string;
  editorInstance: any;
  includeNewLine?: boolean;
  newElement: Element | JSX.Element | HTMLElement;
  oldElement?: HTMLElement | Element;
  replaceQuery?: string;
}

type ReplaceInEditorArgs = ExclusiveRequiredFields<ReplaceInEditorArgsBase, 'oldElement', 'replaceQuery'>;

export const replaceInEditor = ({
  cursorLocationQuery,
  editorInstance,
  oldElement,
  newElement,
  replaceQuery,
}: ReplaceInEditorArgs) => {
  const replaceableElement = oldElement || document.querySelector(replaceQuery);

  replaceableElement.parentNode.replaceChild(newElement as Node, oldElement);

  if (cursorLocationQuery) editorInstance.selection.setAfter(document.querySelector(cursorLocationQuery));
  else editorInstance.selection.setAfter(newElement);

  editorInstance.selection.restore();
};

export const extendFroalaTemplates = () => {
  FE.ICON_TEMPLATES = {
    ...FE.ICON_TEMPLATES,
    empty: '[ICON]',
  };
};

export const FROALA_TEMPLATE_NAMES = {
  'black-icon': 'black-icon',
  empty: 'empty',
  font_awesome: 'font_awesome',
  font_awesome_5: 'font_awesome_5',
  font_awesome_5b: 'font_awesome_5b',
  font_awesome_5l: 'font_awesome_5l',
  font_awesome_5r: 'font_awesome_5r',
  image: 'image',
  'margins-icon': 'margins-icon',
  svg: 'svg',
  text: 'text',
} as const;
