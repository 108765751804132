import React, { createContext, useRef } from 'react';
import { GradesTableProps } from '../types';
import createGradesTableStore from './createGradesTableStore';

interface GradesTableStoreProviderProps {
  children?: React.ReactNode;
  props: GradesTableProps;
}

type GradesTableStore = ReturnType<typeof createGradesTableStore>;

export const GradesTableContext = createContext<GradesTableStore | null>(null);

const GradesTableStoreProvider = ({ children, props }: GradesTableStoreProviderProps) => {
  const storeRef = useRef<GradesTableStore>();

  if (!storeRef.current) {
    storeRef.current = createGradesTableStore(props);
  }

  return (
    <GradesTableContext.Provider value={storeRef.current}>
      {children}
    </GradesTableContext.Provider>
  );
};

export default GradesTableStoreProvider;
