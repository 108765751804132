import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DropZone from './DropZone';
import DraggableChoice from './DraggableChoice';

const DropZoneWithDraggableChoices = ({
  id, boundsRef, minDimensions, correctAnswers, draggableChoices, dropZoneProps,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const current = ref && ref.current;
    if (!current) return;

    setWidth(current.clientWidth);
    setHeight(current.clientHeight);
  }, [ref?.current?.clientWidth, ref?.current?.clientHeight, setWidth, setHeight]);

  return (
    <DropZone
      key={id}
      id={id}
      boundsRef={boundsRef}
      dropZoneContainerRef={ref}
      correctAnswers={correctAnswers}
      minDimensions={minDimensions}
      {...dropZoneProps}
    >
      {correctAnswers.map(key => (
        <DraggableChoice
          id={key}
          key={key}
          title={draggableChoices[key].title}
          dropZoneId={id}
          dragZoneWidth={width}
          dragZoneHeight={height}
          vocabId={draggableChoices[key].vocabId}
        />
      ))}
    </DropZone>
  );
};

DropZoneWithDraggableChoices.propTypes = {
  boundsRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  correctAnswers: PropTypes.objectOf(PropTypes.array).isRequired,
  draggableChoices: PropTypes.objectOf(PropTypes.object).isRequired,
  dropZoneProps: PropTypes.shape({
    height: PropTypes.number,
    left: PropTypes.number,
    title: PropTypes.string,
    top: PropTypes.number,
    type: PropTypes.string,
    width: PropTypes.number,
  }).isRequired,
  id: PropTypes.string.isRequired,
  minDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }).isRequired,
};

export default DropZoneWithDraggableChoices;
