import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  onErrorCallback?: (error) => void;
  sentryTags?: { component: string; };
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ hasError: true });
    const { sentryTags, onErrorCallback } = this.props;

    if (sentryTags?.component) { Sentry.captureException(error, { tags: sentryTags }); }
    if (onErrorCallback) onErrorCallback(error);
  }

  render() {
    const { props: { children, fallback }, state: { hasError } } = this;

    if (hasError) return fallback || <></>;

    return children;
  }
}
