import React from 'react';
import warningIcon from 'images/icons/warning.svg';

interface CaptionOverwriteWarningProps {
  className?: string;
}

const CaptionOverwriteWarning = ({ className }: CaptionOverwriteWarningProps) => (
  <div className={className}>
    <img alt="Warning" src={warningIcon} width={16} height={16} />
    <p className="tw-mt-auto tw-mb-auto tw-p-0 tw-leading-8">
            The current caption will be replaced.
    </p>
  </div>
);

export default CaptionOverwriteWarning;
