import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Wizard extends Component {
  static propTypes = {
    progressHeader: PropTypes.node,
    children: PropTypes.node,
    initialStep: PropTypes.number,
    firstPageBackCallback: PropTypes.func,
    lastPageNextCallback: PropTypes.func
  };

  static defaultProps = {
    children: null,
    initialStep: 0,
    progressHeader: '',
    firstPageBackCallback: () => {},
    lastPageNextCallback: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      page: this.props.initialStep,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  next = () =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1)
    }));

  previous = () => {
    if (this.state.page === 0) this.props.firstPageBackCallback();

    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));
  };

  handleSubmit = () => {
    const { children } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;

    if (!isLastPage) {
      return this.next();
    }

    return this.props.lastPageNextCallback();
  };

  _getActivePage = () =>
    React.Children.toArray(this.props.children)[this.state.page];

  _getActivePageWithProps = () =>
    React.cloneElement(this._getActivePage(), { next: this.handleSubmit, previous: this.previous });

  render() {
    return (
      <div>
        {this.props.progressHeader && React.cloneElement(this.props.progressHeader, { page: this.state.page })}
        {this._getActivePageWithProps()}
      </div>
    );
  }
}
