type Correlation = { key: string; value: string; };

export const PRESENTATION_TYPES = [
  'Activity',
  'Investigation',
  'Preview',
  'Processing',
  'Reading_Further',
];

export type SectionCorrelationsType = {
  sections?: { id: number; title: string; }[];
  [key: number]: Correlation[];
};

export type PresentationCorrelationsType = {
  Activity?: Correlation[];
  Investigation?: Correlation[];
  Preview?: Correlation[];
  Processing?: Correlation[];
  Reading_Further?: Correlation[];
};

export type AssessmentCorrelationsType = {
  assessment_questions?: { [key: number]: { body?: string; position: number; }; }
  [key: number]: Correlation[];
};
