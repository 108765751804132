import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';

const ConnectButton = ({ children, enabled, onClick, styles, tooltipContent, withIcon }) => (
  <Tooltip
    content={tooltipContent}
    distance="15"
    size="medium"
    shouldDisplay={!enabled}
    placement="top"
  >
    <span>
      <button type="button" className={styles} onClick={onClick} disabled={!enabled}>
        {withIcon && <i className="fa fa-link" aria-hidden="true" />}
        {' '}
        {children}
      </button>
    </span>
  </Tooltip>
);

ConnectButton.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.string,
  tooltipContent: PropTypes.string,
  withIcon: PropTypes.bool,
};

ConnectButton.defaultProps = {
  children: 'Connect to Google',
  enabled: true,
  styles: 'btn btn--outline-purple',
  tooltipContent: '',
  withIcon: true,
};

export default ConnectButton;
