import FE from 'froala-editor';

export const CUSTOM_VIDEO_COMMAND_NAME = 'customAddVideo';

const iconTemplate = '<i class="fa fa-[NAME]" style="color:#000000;font-size:17px" aria-hidden="true"></i>';

// creates the custom video button
const customVideoCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addVideoIcon', { NAME: 'video-camera', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_VIDEO_COMMAND_NAME, {
    focus: false,
    icon: 'addVideoIcon',
    refreshAfterCallback: true,
    title: 'Add Video',
    undo: true,
  });
};

export const registerFroalaCommands = () => {
  customVideoCommand();
};
