import React from 'react';
import clsx from 'clsx';
import I18n from 'i18n-js';
import icons from '../../Utils/icons';
import styles from './SlideHeader.module.scss';

interface SlideHeaderProps {
  locale: string;
  slide?: any;
}

const SlideHeader = ({ locale, slide }: SlideHeaderProps) => {
  const SLIDE_HEADER_STYLES = 'tw-flex tw-items-center tw-w-max tw-pl-4 tw-rounded-r-2xl tw-relative '
    + 'tw-top-[10px] tw-gap-2 tw-font-black tw-border-solid tw-border-2 tw-border-l-0 tw-border-[#FFC50C] tw-h-8 '
    + 'tw-bg-white';

  const hasPageNumbers = slide?.startPage || slide?.endPage;

  const getPageNumbers = () => {
    // If one page number is blank it is set to be equal to the other, so just one should show (eg. 42 to 42 => 42)
    if (slide?.startPage && slide?.endPage && !(slide?.startPage === slide?.endPage)) {
      return `${I18n.t('pages')} ${slide?.startPage} - ${slide?.endPage}`;
    }
    return `${I18n.t('page')} ${slide?.startPage || slide?.endPage}`;
  };

  return (
    <div
      className={clsx(SLIDE_HEADER_STYLES, {
        'tw-pr-0': hasPageNumbers,
        'tw-pr-6': !hasPageNumbers,
      })}
    >
      {slide?.slideGroupIcon && <img className="tw-grayscale tw-h-7" src={icons[slide?.slideGroupIcon]} alt="icon" />}
      <div>{locale === 'en' ? slide?.slideGroupTitleEn : slide?.slideGroupTitleEs}</div>

      {hasPageNumbers && (
        <div className="tw-flex tw-items-center">
          <div className={clsx(styles.ribbon, 'tw-h-8 tw-w-10 tw-bg-[#ffeeb6]')} />
          <div className="tw-bg-[#ffeeb6] tw-flex tw-items-center tw-rounded-r-2xl tw-pr-6 tw-pl-3 tw-h-8">
            {getPageNumbers()}
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideHeader;
