import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.module.scss';

export default function Section({ className, title, children }) {
  function renderTitle() {
    return (
      <div className={styles.title}>
        <i className={`${styles.circleIcon} fa fa-circle`} aria-hidden="true" />
        <h2>{title}</h2>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${className}`}>
      {renderTitle()}
      <div className={styles.body}>{children}</div>
    </div>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

Section.defaultProps = {
  children: null,
  className: '',
  title: '',
};
