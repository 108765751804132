import React from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'common/Modal';
import key from 'images/icons/key.png';
import styles from './ImpersonateModal.module.scss';
import ModalBody from './ModalBody';

const ImpersonateModal = ({ staffer, userType }) => {
  const modal = useModalManager();

  if (userType === 'Admin' && ['Admin', 'Sysadmin'].includes(staffer.role)) {
    return null;
  }

  if (userType === 'Sysadmin' && staffer.role === 'Sysadmin') {
    return null;
  }

  return (
    <React.Fragment>
      <button
        className="dropdown-item full-size"
        type="button"
        onClick={modal.open}
      >
        <img src={key} alt="" className={styles.keyIcon} />&nbsp;
        Sign in as Staff User
      </button>
      <Modal isOpen={modal.isOpen} closeModal={modal.close} headerText="Sign in as Staff User">
        <ModalBody staffer={staffer} close={modal.close} />
      </Modal>
    </React.Fragment>
  );
};

ImpersonateModal.propTypes = {
  staffer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired,
  userType: PropTypes.string.isRequired
};

export default ImpersonateModal;
