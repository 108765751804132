import React, { useState } from 'react';
import PropTypes from 'prop-types';
import warningIcon from 'images/icons/warning.svg';
import preview from 'images/betaFeatures/lesson-overview-preview.png';
import styles from './BetaFeatures.module.scss';

const Welcome = () => (
  <p className={styles.welcome}>
    Welcome to TCI&apos;s opt-in features!
    As we continually enhance our product, this page allows you to turn on or turn off features early in development
    and share your feedback along the way.
  </p>
);

const Warning = ({ text }) => (
  <p>
    <img alt="Warning" className={styles.warningIcon} src={warningIcon} />
    {text}
  </p>
);

Warning.propTypes = {
  text: PropTypes.string.isRequired,
};

const FeatureToggle = ({ ...props }) => {
  const [submitting, setSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(props.initialEnabled);

  const handleToggle = () => {
    setSubmitting(true);
    props.handleToggle(!enabled)
      .then(() => {
        setEnabled(!enabled);
        const url = new URL(window.location.href);
        url.searchParams.set('initial_active_tab', 'beta_features');
        window.location.assign(url.href);
      });
  };

  return (
    <React.Fragment>
      <Welcome />
      <div className={styles.betaFeature}>
        <p className={styles.header}>
          {props.title}
        </p>
        {props.warning && <Warning text={props.warning} />}
        <div className={styles.descriptionWrapper}>
          <div className={styles.description}>
            {props.description}
          </div>
          <button
            type="button"
            className="btn btn--purple"
            onClick={handleToggle}
            disabled={submitting}
          >
            {enabled ? 'Turn Off' : 'Turn On'}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

FeatureToggle.defaultProps = {
  warning: '',
};

FeatureToggle.propTypes = {
  description: PropTypes.node.isRequired,
  handleToggle: PropTypes.func.isRequired,
  initialEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  warning: PropTypes.string,
};

export default FeatureToggle;
