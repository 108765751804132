import React, { useLayoutEffect, useRef, useContext, useState, useEffect } from 'react';
import DndAnswerContext from 'components/common/Context';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import cn from '@/utils/cn';
import ScoreIcon from '@/components/common/Forms/ScoreIcon';
import ItemTypes from '../../ItemTypes';
import styles from './DraggableChoice.module.scss';
import DraggableChoiceContent from '../../DraggableChoiceContent';
import { getTextContent, resizeWithinContainer } from '../../utils';
import useDragAndDropStore from '../../useDragAndDropStore';
import useVocabularyStore from '../../useVocabularyStore';

interface DraggableChoiceType {
  isCorrect?: boolean;
  isDragged?: boolean;
  isDragDisabled?: boolean;
  isHorizontallyPositioned?: boolean;
  id: string;
  title: string;
  dragZoneWidth?: number;
  dragZoneHeight?: number;
  useAnswerStyles?: boolean;
  vocabId?: string;
}

const DraggableChoice = ({
  isCorrect: isCorrectProp = false,
  isDragDisabled = false,
  isDragged = false,
  isHorizontallyPositioned = false,
  id,
  title,
  dragZoneWidth,
  dragZoneHeight,
  useAnswerStyles: useAnswerStylesProp = false,
  vocabId = null,
}: DraggableChoiceType) => {
  const type = ItemTypes.draggableChoice;
  const label = `Draggable choice: ${title}`;
  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const vocabLoading = useVocabularyStore(state => state.loading);
  const setIsGloballyDragging = useDragAndDropStore(state => state.setIsGloballyDragging);
  const { pointsPerDraggable, hasTransparentAnswerChoices, question } = useContext(DndAnswerContext);
  const [textContent, setTextContent] = useState('');

  const points = pointsPerDraggable[id] || [];
  const latestPoints = points[points.length - 1];
  const isCorrect = latestPoints === 1 || isCorrectProp;
  const isPartiallyCorrect = latestPoints === 0.5;
  const useAnswerStyles = useAnswerStylesProp || isCorrect || isPartiallyCorrect;

  useEffect(() => {
    setTextContent(getTextContent(vocabId, vocabularyTerms, vocabLoading, title, 'term'));
  }, [vocabId, vocabularyTerms, vocabLoading, title]);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    canDrag: () => !isDragDisabled,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    item: { id, title, textContent },
    type,
  }), [isDragDisabled, textContent]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    setIsGloballyDragging(isDragging);
  }, [isDragging, setIsGloballyDragging]);

  const contentRef = useRef(null);
  const resizeListener = () => {
    resizeWithinContainer(contentRef, dragZoneWidth, dragZoneHeight)
  };

  useLayoutEffect(() => {
    resizeListener();
    setTimeout(() => resizeListener(), 300);

    window.addEventListener('resize', resizeListener);

    // eslint-disable-next-line consistent-return
    return () => { window.removeEventListener('resize', resizeListener); };
  }, [contentRef, contentRef?.current, contentRef?.current?.clientWidth, contentRef?.current?.clientHeight, dragZoneHeight, dragZoneWidth]);

  return (
    <div
      ref={contentRef}
      className={cn({
        [styles.draggedChoiceContainer]: isDragged,
        [styles.choicesBankItem]: isHorizontallyPositioned && !isDragged,
        [styles.textChoicesBankItem]: isHorizontallyPositioned && !textContent.includes('<img'),
      })}
    >
      {useAnswerStyles && (
        <ScoreIcon
          isCorrect={isCorrect}
          isPartiallyCorrect={isPartiallyCorrect}
        />
      )}
      <div
        ref={drag}
        key={id}
        className={cn(
          styles.draggableChoice, {
            [styles.transparentBackground]: hasTransparentAnswerChoices,
            [styles.updatedQuestionScoring]: true,
            [styles.answered]: useAnswerStyles,
            [styles.correct]: isCorrect || isPartiallyCorrect,
            [styles.incorrect]: !(isCorrect || isPartiallyCorrect) && useAnswerStylesProp,
            [styles.circle]: question.data.drop_zone_shape === 'circle',
            [styles.rectangle]: question.data.drop_zone_shape !== 'circle',
          },
        )}
        role="button"
        aria-label={label}
        tabIndex={0}
      >
        <DraggableChoiceContent className={styles.content} content={textContent} />
      </div>
    </div>
  );
};

export default DraggableChoice;
