/* eslint-disable react/no-danger */

import React, { useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider, QueryClientConfig } from '@tanstack/react-query';
import Spinner from '@/components/common/Spinner';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { useNotebookPreviewJS } from './hooks/useNotebookPreviewJS';
import usePreviewContent from './hooks/usePreviewContent';
import { PreviewParams } from './types';

interface NotebookPreviewProps {
  previewParams: PreviewParams;
  thumbnail?: boolean;
  slideObjectId?: string;
}

const DEV_QUERY_OPTS: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  },
};

const CONFIG = process.env.NODE_ENV === 'development' ? DEV_QUERY_OPTS : {};

const queryClient = new QueryClient(CONFIG);

const NotebookPreview = ({ previewParams, thumbnail, slideObjectId }: NotebookPreviewProps) => {
  const [previewContent, setPreviewContent] = useState('');
  const ref = useRef();
  const { data, isLoading, isError } = usePreviewContent(previewParams);

  useEffect(() => {
    if (!data) return;
    setPreviewContent(data);
  }, [data]);

  const setRecentlyAnsweredQuestionId = useSlideShowContext(state => state.setRecentlyAnsweredQuestionId);

  useNotebookPreviewJS({
    isLoading,
    previewContent,
    ref,
    thumbnail,
    setRecentlyAnsweredQuestionId,
    slideObjectId,
  });

  if (isError) return <div>Something went wrong...</div>;

  if (isLoading) return <Spinner className="center tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-bg-opacity-50" />;

  return (
    <div
      ref={ref}
      className="notebook-preview"
      dangerouslySetInnerHTML={{ __html: previewContent }}
    />
  );
};

export const NotebookPreviewWrapper = ({ previewParams, thumbnail, slideObjectId }: NotebookPreviewProps) => (
  <QueryClientProvider client={queryClient}>
    <NotebookPreview
      previewParams={previewParams}
      thumbnail={thumbnail}
      slideObjectId={slideObjectId}
    />
  </QueryClientProvider>
);
