import React from 'react';
import { useAddNewHotspot } from '../../panoramicImageUtils';

const AddHotspotButton = () => {
  const addNewHotspot = useAddNewHotspot();

  return (
    <div className="tw-bg-light-grey tw-p-6 tw-rounded-lg">
      <button
        className={`
          tw-w-full tw-aspect-square tw-bg-white tw-shadow-none tw-border-none 
          tw-text-9xl tw-text-tci-purple tw-cursor-pointer tw-rounded-lg
        `}
        onClick={addNewHotspot}
        type="button"
      >
        <span className="fa fa-plus" />
      </button>
    </div>
  );
};

export default AddHotspotButton;
