import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import PaginatedTable from '../../common/DataTable/PaginatedTable/index';
import ActionsDropdown from '../../common/ActionsDropdown';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from '../../common/AdvancedFilters/Utils';
import { matchAttributesForSingleRow } from '../../common/DataTable/Utils';
import { isCoordinator, isAdmin, isSupportManager, isSysadmin } from '../../common/Utils';
import Edit from './Edit';
import Add from './Add';
import Delete from './Delete';
import DeleteAllStudents from './DeleteAllStudents';
import AdvancedFilters from '../../common/AdvancedFilters';
import styles from './StudentsTable.module.scss';
import ImpersonateButton from './ImpersonateButton';
import Checkbox from 'common/Checkbox';
import BulkActions from './BulkAction/BulkAction';
import { isCurrentPageAllChecked, renderBulkCheckbox, getCurrentPageIds } from 'common/BulkAction/utils';

export default class StudentsTable extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    userIsSysadmin: PropTypes.bool.isRequired,
    asyncSearchPath: PropTypes.string,
    batchUpdateCsvPath: PropTypes.string.isRequired,
    canCsvUpdate: PropTypes.bool,
    classroomsPath: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    createPath: PropTypes.string.isRequired,
    csvTemplateUrl: PropTypes.string.isRequired,
    deleteAllStudentsPath: PropTypes.string.isRequired,
    destroyPath: PropTypes.string.isRequired,
    flags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })),
    importCsvPath: PropTypes.string.isRequired,
    initialFilters: filtersPropTypes,
    manageClassesPath: PropTypes.string.isRequired,
    pageSize: PropTypes.number,
    processCsvNowPath: PropTypes.string,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    programCodesUrl: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    stafferId: PropTypes.number.isRequired,
    studentCsvUploadsPath: PropTypes.string,
    subscriberClassesPath: PropTypes.string.isRequired, // classes table
    subscriberId: PropTypes.number.isRequired,
    updatePath: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    asyncSearchPath: '',
    canCsvUpdate: true,
    flags: [],
    initialFilters: [
      { operatorValue: 'cont', propertyValue: 'username' },
      { operatorValue: 'cont', propertyValue: 'last_name' }
    ],
    pageSize: 1000,
    processCsvNowPath: '',
    studentCsvUploadsPath: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      pagesLoaded: 0,
      editModalOpen: false,
      deleteModalOpen: false,
      columnMapping: ['last_name', 'first_name', 'username', 'current_sign_in_at', null, null, 'uid'],
      doneLoading: false,
      getUrl: this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString()),
      selectedStudentIds: new Set(),
      bulkChecked: 'none'
    };

    this.addResources = this.addResources.bind(this);
    this.resetResources = this.resetResources.bind(this);
    this.hyperlinkClasses = this.hyperlinkClasses.bind(this);
    this.openModal = this.openModal.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.updateActiveFilters = this.updateActiveFilters.bind(this);
    this.handleBulkCheckboxChange = this.handleBulkCheckboxChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.setBulkState = this.setBulkState.bind(this);
    this.onTableDraw = this.onTableDraw.bind(this);
  }

  getActiveFilters() {
    const activeFilters = this.state.activeFilters;

    let getUrl = this.props.ajaxUrl
      .replace('pageSize', this.props.pageSize.toString());

    getUrl = buildFilterPath(getUrl, activeFilters);

    // if we're done loading, set doneLoading to false to begin loading the filter request
    // if we're currently loading something, set doneLoading to true to stop loading
    // and allow the filter request through
    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getUrl,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  resetResources() {
    this.setState({ resources: [], doneLoading: false, pagesLoaded: 0 });
  }

  addResources(newResources) {
    const state = {
      resources: [...this.state.resources].concat(newResources),
      doneLoading: newResources.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1
    };

    if (this.state.filterSent) {
      state.doneLoading = false;
      state.filterSent = false;
    }

    this.setState(state);
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({ [`${type}ModalOpen`]: true, rowData });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false, rowData: null });
  }

  updateRow(updatedObject) {
    return this.state.resources.map((sub) => {
      if (sub.id !== updatedObject.id) {
        return Object.assign({}, sub);
      }
      return Object.assign({}, sub, updatedObject);
    });
  }

  addRow(updatedObject) {
    matchAttributesForSingleRow(updatedObject, this.props.columns);
    return [...this.state.resources].concat(updatedObject);
  }

  deleteRow(updatedObject) {
    return this.state.resources.filter(sub => sub.id !== updatedObject.id);
  }

  updateTable(data, action) {
    let updatedResources;
    let bulkChecked = this.state.bulkChecked;
    let selectedStudentIds = this.state.selectedStudentIds;

    // reload all resources on an add/delete to ensure our table is clean
    switch (action) {
      case 'update':
        updatedResources = this.updateRow(data);
        break;
      case 'add':
        updatedResources = this.addRow(data);
        break;
      case 'delete':
        if (!this.state.doneLoading) {
          this.resetResources();
          return;
        }
        if (Array.isArray(data) && data.length) {
          updatedResources = this.state.resources.filter(student => !data.includes(student.id));
          bulkChecked = 'none';
          selectedStudentIds = new Set();
        }
        else {
          updatedResources = this.deleteRow(data);
        }

        break;
      case 'showFilteredResources':
        this.getActiveFilters();
        updatedResources = data;
        break;
      case 'error':
        this.setState({ errorMessage: data });
        return;
      default:
        console.log('Action not yet implemented');
    }

    this.setState({ resources: updatedResources, bulkChecked, selectedStudentIds });
  }

  updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this.updateTable([], 'showFilteredResources')
    );
  }

  _renderError() {
    if (this.state.errorMessage) {
      return (
        <div id="flash" className="alert">
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
    return null;
  }

  _renderEdit() {
    if (this.state.editModalOpen) {
      return (
        <Edit
          closeModal={() => this.closeModal('edit')}
          modalIsOpen={this.state.editModalOpen}
          rowData={this.state.rowData}
          updatePath={this.props.updatePath}
          updateTable={this.updateTable}
          userIsSysadmin={this.props.userIsSysadmin}
          flags={this.props.flags}
        />
      );
    }

    return null;
  }

  _renderAdd() {
    return (
      <Add
        userIsSysadmin={this.props.userIsSysadmin}
        batchUpdateCsvPath={this.props.batchUpdateCsvPath}
        canCsvUpdate={this.props.canCsvUpdate}
        classroomsPath={this.props.classroomsPath}
        createPath={this.props.createPath}
        csvTemplateUrl={this.props.csvTemplateUrl}
        flags={this.props.flags}
        importCsvPath={this.props.importCsvPath}
        isCoordinator={isCoordinator(this.props.userType)}
        processCsvNowPath={this.props.processCsvNowPath}
        programCodes={this.props.programCodes}
        programCodesUrl={this.props.programCodesUrl}
        stafferId={this.props.stafferId}
        studentCsvUploadsPath={this.props.studentCsvUploadsPath}
        updateTable={this.updateTable}
      />
    );
  }

  _renderDelete() {
    if (this.state.deleteModalOpen) {
      return (
        <Delete
          closeModal={() => this.closeModal('delete')}
          destroyPath={this.props.destroyPath}
          modalIsOpen={this.state.deleteModalOpen}
          rowData={this.state.rowData}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  _renderDropdown(rowData) {
    return (
      <ActionsDropdown>
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, rowData, 'edit')}
        >
          <i aria-hidden="true" className="fa fa-edit" /> Edit
        </a>

        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, rowData, 'delete')}
        >
          <i aria-hidden="true" className="fa fa-trash" /> Delete
        </a>

        <a
          className="dropdown-item"
          href={this.props.manageClassesPath.replace(':id', rowData.id)}
        >
          <i aria-hidden="true" className="fa fa-users" /> Manage Classes
        </a>
        <ImpersonateButton
          student={{
            id: rowData.id,
            name: `${rowData.first_name} ${rowData.last_name}`
          }}
        />
      </ActionsDropdown>
    );
  }

  hyperlinkClasses(td, cellData, rowData) {
    if (cellData > 0) {
      const data = {
        initialFilters: [
          {
            propertyValue: 'students_full_name_with_username',
            operatorValue: 'eq',
            value: `${rowData.first_name} ${rowData.last_name} (${rowData.username})`
          }
        ]
      };
      ReactDOM.render(
        <a href={`${this.props.subscriberClassesPath}?${$.param(data)}`}>
          {cellData}
        </a>,
        td
      );
    }

    return cellData;
  }

  handleCheckboxChange(e) {
    const id = parseInt(e.target.value, 10);
    const updatedSet = new Set(this.state.selectedStudentIds);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    }
    else {
      updatedSet.add(id);
    }
    const bulkChecked = isCurrentPageAllChecked(updatedSet, this.tableRef);

    this.setState(
      { selectedStudentIds: updatedSet, bulkChecked },
      () =>
        this._renderCheckboxForRow({
          id,
          checked: this.state.selectedStudentIds.has(id),
          parentContainer: document.getElementById(`bulk-student-${id}`)
        })
    );
  }

  _renderCheckboxForRow({ id, checked, parentContainer }) {
    const fullName = parentContainer.dataset.fullName;

    ReactDOM.render(
      <Checkbox
        checked={checked}
        handleChange={this.handleCheckboxChange}
        labelText={checked ? `Unselect ${fullName}` : `Select ${fullName}`}
        name="bulk-student"
        srOnly
        value={id.toString()}
      />,
      parentContainer
    );
  }

  setBulkState() {
    this.setState(prevState => ({
      bulkChecked: isCurrentPageAllChecked(prevState.selectedStudentIds, this.tableRef)
    }));
  }

  onTableDraw() {
    // updates bulk checkbox upon table re-draw
    const currentSelectedIds = this.state.selectedStudentIds;
    this.setState({
      bulkChecked: isCurrentPageAllChecked(currentSelectedIds, this.tableRef)
    });
  }

  handleBulkCheckboxChange() {
    const currentPageStudentIds = getCurrentPageIds(this.tableRef);
    const updatedSet = new Set(this.state.selectedStudentIds);
    let bulkChecked = 'none';

    if (this.state.bulkChecked === 'all') {
      currentPageStudentIds.forEach((id) => {
        updatedSet.delete(id);
        this._renderCheckboxForRow({
          id, checked: false, parentContainer: document.getElementById(`bulk-student-${id}`)
        });
      });
    }
    else {
      currentPageStudentIds.forEach((id) => {
        updatedSet.add(id);
        this._renderCheckboxForRow({
          id, checked: true, parentContainer: document.getElementById(`bulk-student-${id}`)
        });
      });
      bulkChecked = 'all';
    }

    this.setState({ selectedStudentIds: updatedSet, bulkChecked });
  }

  getColumnDefs() {
    return [
      {
        targets: 0,
        title: '<div id="select-all" />',
        sortable: false,
        searchable: false,
        visible: isAdmin(this.props.userType),
        data: null,
        createdCell: (td, cellData, rowData) => {
          $(td).attr({
            id: `bulk-student-${rowData.id}`,
            'data-full-name': `${rowData.first_name} ${rowData.last_name}`
          });

          this._renderCheckboxForRow({
            id: rowData.id,
            checked: this.state.selectedStudentIds.has(rowData.id),
            parentContainer: td
          });
        }
      },
      {
        makeHtmlSafe: true,
        targets: 1,
      },
      {
        targets: 2,
        title: 'First Name'
      },
      {
        targets: 4,
        type: 'date'
      },
      {
        targets: 5,
        title: 'Num of Sign-ins',
        width: '12%'
      },
      {
        createdCell: this.hyperlinkClasses,
        sortable: false,
        targets: 6,
        title: 'Num of Classes',
        width: '12%'
      },
      {
        targets: 7,
        title: 'Actions',
        sortable: false,
        visible: isAdmin(this.props.userType),
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            this._renderDropdown(rowData),
            td
          );
        }
      },
      {
        targets: 8,
        title: 'UID',
        visible: false,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <p>{rowData.uid}</p>,
            td
          );
        }
      }
    ]
  }

  _renderPaginatedTable() {
    return (
      <PaginatedTable
        addResources={this.addResources}
        columns={this.props.columns}
        columnDefs={this.getColumnDefs()}
        columnMapping={this.state.columnMapping}
        defaultOrder={[[1, 'asc'], [2, 'asc'], [3, 'asc']]}
        doneLoading={this.state.doneLoading}
        doneLoadingCallback={this.setBulkState}
        exportedColumns={[0, 1, 2, 3, 4, 5, 7]}
        getUrl={this.state.getUrl}
        onDraw={this.onTableDraw}
        pageSize={this.props.pageSize}
        pagesLoaded={this.state.pagesLoaded}
        ref={(ref) => { this.tableRef = ref; }}
        resources={this.state.resources}
        resetResources={this.resetResources}
      />
    );
  }

  _renderFilter() {
    return (
      <div>
        <AdvancedFilters
          initialFilters={this.props.initialFilters}
          properties={this.props.properties}
          searchPath={this.props.ajaxUrl}
          resources={this.state.resources}
          updateActiveFilters={this.updateActiveFilters}
          asyncSearchPath={this.props.asyncSearchPath}
        />
        <hr />
      </div>
    );
  }

  renderBulkActions() {
    if (this.state.selectedStudentIds.size && isAdmin(this.props.userType)) {
      return (
        <BulkActions
          studentIds={this.state.selectedStudentIds}
          subscriberId={this.props.subscriberId}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  _renderButtons() {
    return (
      <div className={styles.buttons}>
        {this._renderAdd()}
        {this.renderBulkActions()}
        {
          isSysadmin(this.props.userType) &&
          <DeleteAllStudents path={this.props.deleteAllStudentsPath} />
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        {this._renderError()}
        {this._renderFilter()}
        {this._renderButtons()}
        {this._renderPaginatedTable()}
        {this._renderEdit()}
        {this._renderDelete()}
        {renderBulkCheckbox(this.state.resources, this.state.bulkChecked, this.handleBulkCheckboxChange,
          (this.state.bulkChecked ? 'Unselect' : 'Select').concat(' all students on this page'))}
      </div>
    );
  }
}
