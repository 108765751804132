import { Canvas, IText } from 'fabric';

export const handleBoldText = (canvas: Canvas) => {
  const selection = canvas.getActiveObject();

  if (!selection) return;

  selection.set('fontWeight', (selection as IText).fontWeight === 'bold' ? '' : 'bold');
  canvas.fire('object:modified');
  canvas.requestRenderAll();
};

export const handleItalicText = (canvas: Canvas) => {
  const selection = canvas.getActiveObject();

  if (!selection) return;

  selection.set('fontStyle', ((selection as IText).fontStyle) === 'italic' ? '' : 'italic');
  canvas.fire('object:modified');
  canvas.requestRenderAll();
};

export const handleTextSizeChange = (canvas: Canvas, fontSize: number) => {
  const selection = canvas.getActiveObject();

  if (!selection) return;

  selection.set('fontSize', fontSize);
  canvas.fire('object:modified');
  canvas.requestRenderAll();
};
