import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import * as Routes from 'modules/routes';
import { matchAttributesForMultipleRows } from '@/components/common/DataTable/Utils';
import { COLUMNS, ISeatPool, seatPoolOrder } from '../ProgramAccessTable/utils';
import useProgramAccessStore from '../ProgramAccessTable/useProgramAccessStore';

const useUpdateActiveProgramsMutation = () => {
  const programs = useProgramAccessStore(state => state.programs);
  const licenses = useProgramAccessStore(store => store.licenses);
  const seatPools = useProgramAccessStore(state => state.seatPools);
  const selectedPrograms = useProgramAccessStore(state => state.selectedPrograms);
  const setStore = useProgramAccessStore(state => state.setStore);
  const subscriber = useProgramAccessStore(state => state.subscriber);

  const updateSeatPoolsResources = (updatedSeatPools) => {
    matchAttributesForMultipleRows(updatedSeatPools, COLUMNS);

    const newSeatPools = [...seatPools];

    updatedSeatPools.forEach((updatedSeatPool) => {
      const index = seatPools.findIndex(pool => pool.id === updatedSeatPool.id);

      if (index > -1) {
        newSeatPools[index] = updatedSeatPool;
      } else {
        newSeatPools.push(updatedSeatPool);
      }
    });

    setStore({ seatPools: newSeatPools.filter(pool => pool.active).sort((a, b) => seatPoolOrder(a, b)) });
  };

  const updateActivePrograms = (licenseId: number) => {
    const path = Routes.plato_api_manage_programs_seat_pools_path({
      subscriber_id: subscriber.id,
      license_id: licenseId,
      program_ids: [...selectedPrograms],
    });

    return axios.post<{ data: ISeatPool[] }>(path)
      .then((res) => {
        const updatedSeatPools = res.data.data.map(pool => ({
          ...pool,
          program: programs.find(p => p.id === pool.program_id),
          license: licenses.find(l => l.id === pool.license_id),
        }));

        updateSeatPoolsResources(updatedSeatPools);
      });
  };

  return useMutation({ mutationFn: updateActivePrograms });
};

export default useUpdateActiveProgramsMutation;
