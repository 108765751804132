import React from 'react';
import PropTypes from 'prop-types';

export default function Confirmation({
  numRows, redirectPath, scheduleCsvNow
}) {
  const SMALL_CSV_LIMIT = 10000;

  function isLargeCsv() {
    return numRows > SMALL_CSV_LIMIT;
  }

  function renderSmallUpdateCsvConfirmation() {
    return (
      <div>
        <p>
          Your CSV file has been successfully scheduled to process
          at <b>3:00 PM Pacific Time</b>.
        </p>

        <p>
          Please keep in mind that batch updates may overwrite existing data.
        </p>

        <div>
          <hr />
          <footer>
            <button
              type="button"
              className="btn btn--outline-purple mr10"
              onClick={scheduleCsvNow}
            >
              Process CSV Now
            </button>

            <a href={redirectPath} className="btn btn--purple">
              View Upload Status
            </a>
          </footer>
        </div>
      </div>
    );
  }

  function renderLargeCsvConfirmation() {
    return (
      <div>
        <p>
          This CSV includes more than {SMALL_CSV_LIMIT.toLocaleString()} rows
          and will overwrite existing data. It has been successfully scheduled
          to process at <b>3:00 PM Pacific Time</b>.
        </p>

        <div>
          <hr />
          <footer>
            <a href={redirectPath} className="btn btn--purple">
              View Upload Status
            </a>
          </footer>
        </div>
      </div>
    );
  }

  return isLargeCsv() ?
    renderLargeCsvConfirmation() : renderSmallUpdateCsvConfirmation();
}

Confirmation.propTypes = {
  numRows: PropTypes.number.isRequired,
  redirectPath: PropTypes.string.isRequired,
  scheduleCsvNow: PropTypes.func.isRequired
};
