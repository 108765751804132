import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import AdvancedFilters from '../../common/AdvancedFilters';
import { buildFilterPath } from '../../common/AdvancedFilters/Utils';
import PaginatedTable from '../../common/DataTable/PaginatedTable';
import ActionsDropdown from '../../common/ActionsDropdown';
import Return from './Return';
import AddComp from './Comp/AddComp';
import Transfer from './Transfer';
import { matchAttributesForSingleRow } from '../../common/DataTable/Utils';
import Exchange from './Exchange';
import Tooltip from '../../common/Tooltip';
import {
  formatProgramsWithCode,
  mapSubscription,
  isExpired,
  formatDate,
  formatYear
} from './Utils';
import { alphabetizePrograms } from '../../common/Utils';
import styles from './Table.module.scss';
import BulkTransferModal from './Transfer/BulkTransferModal';
import Checkbox from '../../common/Checkbox';
import { isRowTransferrable, getTransferContent } from './Transfer/Utils';
import TCIOnlyLock from '../../common/TCIOnlyLock';

export default class TransactionsTable extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    asyncSearchPath: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    compPath: PropTypes.string.isRequired,
    createReturnPath: PropTypes.string.isRequired,
    exchangePath: PropTypes.string.isRequired,
    exchangeProgramsPath: PropTypes.string.isRequired,
    individualSubscriptionPath: PropTypes.string.isRequired,
    initialFilters: PropTypes.arrayOf(PropTypes.shape({
      operatorValue: PropTypes.string.isRequired,
      propertyValue: PropTypes.string.isRequired
    })),
    isSupportManager: PropTypes.bool,
    isSysadmin: PropTypes.bool,
    licenses: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })),
    pageSize: PropTypes.number,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    sameSeriesProgramsPath: PropTypes.string,
    searchSubscribersPath: PropTypes.string.isRequired,
    staffersTablePath: PropTypes.string.isRequired,
    studentsTablePath: PropTypes.string.isRequired,
    subscriber: PropTypes.shape({
      customerNumber: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    transferPath: PropTypes.string.isRequired,
    transferSubscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    viewSubscriberPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    initialFilters: null,
    isSupportManager: false,
    isSysadmin: false,
    licenses: null,
    pageSize: 40,
    sameSeriesProgramsPath: '',
  };

  static renderDate(td, cellData, rowData) {
    ReactDOM.render(formatDate(rowData.created_at), td);
  }

  static filterResources(resources) {
    return resources.filter(resource => !resource.overshared);
  }

  static renderExpirationYear(td, cellData, rowData) {
    ReactDOM.render(formatYear(rowData.expires_on), td);
  }

  static _renderType(rowData) {
    return (
      <span>
        {rowData.transaction_type}
        {' '}
        {rowData.transfer_notes && TransactionsTable.renderNotesTooltip(rowData.transfer_notes)}
      </span>
    );
  }

  static renderNotesTooltip(text) {
    const icon = (
      <span>
        <i className="fa fa-info-circle" aria-hidden="true" />
        <span className="sr-only">
          {text}
        </span>
      </span>
    );

    return (
      <Tooltip
        interactive={false}
        content={text}
      >
        {icon}
      </Tooltip>
    );
  }

  static _renderTypeLabel(labelText, colorStyle) {
    return (
      <span className={`${styles.labelContainer} mb5`}>
        <span className={`${styles.colorBox} ${colorStyle}`} />
        {labelText}
      </span>
    );
  }

  static _renderDisabledItem(content, tooltipText) {
    return (
      <Tooltip
        interactive={false}
        content={tooltipText}
      >
        <span
          className="dropdown-item disabled"
        >
          {content}
        </span>
      </Tooltip>
    );
  }

  static _renderDisabledReturnButton(disabledText) {
    const content = (
      <span>
        <i aria-hidden="true" className="fa fa-undo" />
        {' '}
        Return
      </span>
    );

    return TransactionsTable._renderDisabledItem(content, disabledText);
  }

  static hideDropdown(rowData) {
    return (
      rowData.transaction_type === 'Return' ||
      TransactionsTable.zeroSeats(rowData) ||
      TransactionsTable.noSeatsAvailable(rowData) ||
      isExpired(rowData.expires_on)
    );
  }

  static noSeatsAvailable(rowData) {
    return rowData.teacher_seats_available <= 0 && rowData.student_seats_available <= 0;
  }

  static zeroSeats(rowData) {
    return rowData.teacher_seats_count <= 0 && rowData.student_seats_count <= 0;
  }

  static filterProperties = [
    {
      label: 'License',
      value: 'license_name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont']
    },
    {
      label: 'Type',
      value: 'transaction_type',
      operators: ['eq', 'not_eq', 'cont', 'not_cont'],
      autofillOptions: [
        { label: 'Temp Access', value: 'Temp Access' },
        { label: 'Exchange', value: 'Exchange' },
        { label: 'Order', value: 'Order' },
        { label: 'Return', value: 'Return' },
        { label: 'Transfer', value: 'Transfer' }
      ]
    },
    {
      label: 'PO Number',
      value: 'purchase_order_number',
      operators: ['eq', 'not_eq', 'cont', 'not_cont']
    },
    {
      label: 'Transaction ID',
      value: 'purchase_order_order_number',
      operators: ['eq', 'not_eq', 'cont', 'not_cont']
    },
    {
      label: 'Date',
      value: 'created_at',
      operators: ['eq', 'not_eq', 'gteq', 'lteq'],
      isDate: true
    },
    {
      label: 'Contact',
      value: 'purchase_order_admin_email',
      operators: ['eq', 'not_eq', 'cont', 'not_cont']
    },
    {
      label: 'Expires',
      value: 'expires_on',
      operators: ['eq', 'not_eq', 'gteq', 'lteq']
    }
  ];

  static _addCellId(td, id) {
    $(td).attr({
      id: `bulk-subscription-${id}`
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      doneLoading: false,
      exchangeModalIsOpen: false,
      exchangePrograms: [],
      filterSent: false,
      getPath: this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString()),
      pagesLoaded: 0,
      resources: [],
      transferModalIsOpen: false,
      selectedSubscriptionIds: new Set()
    };

    this._updateActiveFilters = this._updateActiveFilters.bind(this);
    this._addResources = this._addResources.bind(this);
    this._resetResources = this._resetResources.bind(this);
    this.processResources = this.processResources.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.updateRowsForPO = this.updateRowsForPO.bind(this);
    this.styleRow = this.styleRow.bind(this);
    this.handleBulkCheckboxChange = this.handleBulkCheckboxChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onTableDraw = this.onTableDraw.bind(this);
    this.setBulkState = this.setBulkState.bind(this);
  }

  componentDidMount() {
    if (this.props.isSysadmin) { this.getExchangePrograms(); }
  }

  getExchangePrograms() {
    axios.get(this.props.exchangeProgramsPath).then((response) => {
      const alphabetizedPrograms = response.data.data.sort(alphabetizePrograms);
      this.setState({ exchangePrograms: formatProgramsWithCode(alphabetizedPrograms, 2022) });
    });
  }

  getSelectedSubscriptions() {
    const selected = this.state.resources.filter(resource => this.state.selectedSubscriptionIds.has(resource.id));

    return selected.map(resource => mapSubscription(resource));
  }

  _renderLicenseOrProgram(rowData) {
    return (
      <span>
        {rowData.license_name}
        {' '}
        {rowData.program_full_title_with_edition && TransactionsTable.renderNotesTooltip(rowData.program_full_title_with_edition)}
      </span>
    );
  }

  _getColumnMapping() {
    return [
      null,
      'license_name',
      'transaction_type',
      'purchase_order_number',
      'purchase_order_order_number',
      'created_at',
      'purchase_order_admin_email',
      'expires_on',
      'teacher_seats_count',
      'student_seats_count',
      null,
      'ship_to',
    ];
  }

  _getCheckboxColumn(columnIndex) {
    if (this.props.isSysadmin && !this.props.isSupportManager) {
      return (
        {
          targets: columnIndex,
          title: '<div id="select-all" />',
          sortable: false,
          searchable: false,
          data: null,
          createdCell: (td, cellData, rowData) => {
            TransactionsTable._addCellId(td, rowData.id);

            if (isRowTransferrable(rowData)) {
              this._renderCheckboxForRow({
                id: rowData.id,
                checked: this.state.selectedSubscriptionIds.has(rowData.id),
                target: td
              });
            }
          }
        }
      );
    }

    return (
      {
        targets: columnIndex,
        visible: false
      }
    );
  }

  _getActionsColumn(columnIndex) {
    if (this.props.isSysadmin) {
      return (
        {
          targets: columnIndex,
          sortable: false,
          searchable: false,
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              this._renderDropdown(rowData),
              td
            );
          }
        }
      );
    }

    return ({
      targets: columnIndex,
      visible: false
    });
  }

  _getColumnDefs() {
    return [
      this._getCheckboxColumn(0),
      {
        targets: 1,
        title: 'License',
        width: '20%',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            this._renderLicenseOrProgram(rowData),
            td
          );
        }
      },
      {
        targets: 2,
        title: 'Type',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            TransactionsTable._renderType(rowData),
            td
          );
        },
        width: '8%'
      },
      {
        targets: 3,
        title: 'PO Number',
        width: '10%',
        createdCell: (td) => {
          td.classList.add(styles.wrapContent);
        }
      },
      {
        targets: 4,
        title: 'Transaction ID',
        visible: false
      },
      {
        targets: 5,
        title: 'Date',
        createdCell: TransactionsTable.renderDate,
        width: '10%'
      },
      {
        targets: 6,
        title: 'Contact',
        width: '15%',
        createdCell: (td) => {
          td.classList.add(styles.wrapContent);
        }
      },
      {
        targets: 7,
        title: 'Expires',
        createdCell: TransactionsTable.renderExpirationYear,
        width: '9%'
      },
      {
        targets: 8,
        title: 'Teacher Licenses',
        width: '8%'
      },
      {
        targets: 9,
        title: 'Student Licenses',
        width: '8%'
      },
      this._getActionsColumn(10),
      {
        targets: 11,
        visible: false
      }
    ];
  }

  _renderReturnButton(rowData) {
    if (rowData.transaction_type === 'Return') return null;

    if (TransactionsTable.noSeatsAvailable(rowData)) {
      return TransactionsTable._renderDisabledReturnButton('Release enough seats to return.');
    }

    return (
      <Return
        createReturnPath={this.props.createReturnPath}
        expirationDate={rowData.expires_on}
        licenseName={rowData.license_name}
        poNumber={rowData.purchase_order_number}
        program={rowData.program_full_title_with_edition}
        staffersTablePath={this.props.staffersTablePath}
        studentsTablePath={this.props.studentsTablePath}
        studentSeatsAvailable={rowData.student_seats_available}
        studentSeatsInUse={rowData.student_seats_in_use}
        subscriptionID={rowData.id}
        teacherSeatsAvailable={rowData.teacher_seats_available}
        teacherSeatsInUse={rowData.teacher_seats_in_use}
        updateTable={this.updateTable}
      />
    );
  }

  _updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this._showFilteredResources()
    );
  }

  _showFilteredResources() {
    let getPath = this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString());
    getPath = buildFilterPath(getPath, this.state.activeFilters);

    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getPath,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  _renderAdvancedFilters() {
    return (
      <div>
        <AdvancedFilters
          asyncSearchPath={this.props.asyncSearchPath}
          properties={TransactionsTable.filterProperties}
          resources={this.state.resources}
          updateActiveFilters={this._updateActiveFilters}
          initialFilters={this.props.initialFilters}
        />
        <hr />
      </div>
    );
  }

  _addResources(newResources) {
    this.setState(prevState => ({
      doneLoading: !prevState.filterSent && newResources.length < this.props.pageSize,
      filterSent: false,
      pagesLoaded: prevState.pagesLoaded + 1,
      resources: [...prevState.resources].concat(this.processResources(newResources))
    }));
  }

  _resetResources() {
    this.setState({
      doneLoading: false,
      pagesLoaded: 0,
      resources: []
    });
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({
      [`${type}ModalIsOpen`]: true,
      rowData
    });
  }

  closeModal(type) {
    this.setState({
      [`${type}ModalIsOpen`]: false,
      rowData: null
    });
  }

  _renderDropdown(rowData) {
    const { isSupportManager } = this.props;
    if (TransactionsTable.hideDropdown(rowData) || !(this.props.isSysadmin || isSupportManager)) return null;

    return (
      <ActionsDropdown>
        {this._renderTransferButton(rowData)}
        {!isSupportManager && this._renderReturnButton(rowData)}
        {!isSupportManager && this._renderExchangeButton(rowData)}
      </ActionsDropdown>
    );
  }

  updateTable(data, action) {
    let updatedResources;

    // reload all resources on an add/delete to ensure our table is clean
    switch (action) {
      case 'delete':
        this.removeFromSelected(data.id);
        updatedResources = this.deleteRow(data);
        break;
      case 'add':
        updatedResources = this.addRow(data);
        break;
      case 'update':
        updatedResources = this.updateRowsForPO(data);
        break;
      default:
        console.log('Action not yet implemented');
        return;
    }

    this.setState({ resources: updatedResources });
  }

  updateRowsForPO(updatedResources) {
    const updatedRows = [];
    this.state.resources.forEach((row) => {
      const updatedRow = Object.assign({}, row);

      if (row.purchase_order_number === updatedResources.purchase_order_number) {
        const updatedStudentCount = row.student_seats_available + updatedResources.student_seats_count;
        const updatedTeacherCount = row.teacher_seats_available + updatedResources.teacher_seats_count;

        if (updatedStudentCount >= 0 && updatedTeacherCount >= 0) {
          updatedRow.student_seats_available = updatedStudentCount;
          updatedRow.teacher_seats_available = updatedTeacherCount;
        }
      }
      updatedRows.push(updatedRow);
    });

    return updatedRows;
  }

  addRow(updatedResource) {
    matchAttributesForSingleRow(updatedResource, this.props.columns);
    return [...this.state.resources].concat(updatedResource);
  }

  deleteRow(updatedResource) {
    return this.state.resources.filter(resource => resource.id !== updatedResource.id);
  }

  removeFromSelected(id) {
    const updatedSelectedSet = new Set(this.state.selectedSubscriptionIds);

    updatedSelectedSet.delete(id);

    this.updateCheckboxState(id, updatedSelectedSet);
  }

  _renderTransferButton(rowData) {
    const transferContent = getTransferContent();

    if (rowData.transaction_type === 'Return') {
      return (TransactionsTable._renderDisabledItem(transferContent, 'Returns cannot be transferred'));
    }

    if (rowData.is_transferrable === false) {
      return (TransactionsTable._renderDisabledItem(transferContent, 'Release enough seats to transfer.'));
    }

    return (
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => this.openModal(e, rowData, 'transfer')}
      >
        {transferContent}
      </a>
    );
  }

  _renderTransferModal() {
    if (this.props.isSysadmin && this.state.transferModalIsOpen) {
      return (
        <Transfer
          subscriber={this.props.subscriber}
          subscription={mapSubscription(this.state.rowData)}
          closeModal={() => this.closeModal('transfer')}
          modalIsOpen={this.state.transferModalIsOpen}
          transferPath={this.props.transferPath}
          updateTable={this.updateTable}
          searchSubscribersPath={this.props.searchSubscribersPath}
          viewSubscriberPath={this.props.viewSubscriberPath}
          transferSubscriber={this.props.transferSubscriber}
        />
      );
    }

    return null;
  }

  _renderExchangeButton(rowData) {
    const actionContent = (
      <span>
        <i className="fa fa-exchange" />
        {' '}
        Exchange
        {' '}
        <TCIOnlyLock />
      </span>
    );

    if (TransactionsTable.noSeatsAvailable(rowData)) {
      return TransactionsTable._renderDisabledItem(actionContent, 'Release enough seats to exchange.');
    }

    if (['Temp Access', 'Return'].includes(rowData.transaction_type)) {
      const disabledMessage = `${rowData.transaction_type} transactions cannot be exchanged`;
      return TransactionsTable._renderDisabledItem(actionContent, disabledMessage);
    }

    return (
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => this.openModal(e, rowData, 'exchange')}
      >
        {actionContent}
      </a>
    );
  }

  _renderExchangeModal() {
    if (this.state.exchangeModalIsOpen) {
      return (
        <Exchange
          closeModal={() => this.closeModal('exchange')}
          exchangePath={this.props.exchangePath}
          individualSubscriptionPath={this.props.individualSubscriptionPath}
          isSysadmin={this.props.isSysadmin}
          modalIsOpen={this.state.exchangeModalIsOpen}
          programs={this.state.exchangePrograms}
          programsPath={this.props.sameSeriesProgramsPath}
          rowData={this.state.rowData}
          staffersTablePath={this.props.staffersTablePath}
          studentsTablePath={this.props.studentsTablePath}
          updateTable={this.updateTable}
          licenses={this.props.licenses}
        />
      );
    }

    return null;
  }

  _renderAddCompButton() {
    if (!this.props.isSysadmin || this.props.isSupportManager) return null;

    return (
      <AddComp
        compPath={this.props.compPath}
        licenses={this.props.licenses}
        programs={this.props.programs}
        updateTable={this.updateTable}
      />
    );
  }

  processResources(resources) {
    const processedResources = resources.map(resource => this.processResource(resource));
    return processedResources.filter(resource => !resource.overshared);
  }

  processResource(resource) {
    if (!this.props.isSysadmin && resource.completed_by_sysadmin) {
      resource.purchase_order_admin_email = 'TCI';
    }

    return resource;
  }

  _renderRowLabels() {
    return (
      <div>
        {this.state.showExchangeRowLabel && TransactionsTable._renderTypeLabel('Exchanged license', styles.exchange)}
        {this.state.showReturnRowLabel && TransactionsTable._renderTypeLabel('Returned license', styles.return)}
        {this.state.showTransferRowLabel && TransactionsTable._renderTypeLabel('Transferred license', styles.transfer)}
      </div>
    );
  }

  // adds styles.exchangeRow, styles.returnRow, styles.transferRow to the row classList
  // and sets showExchangeRowLabel, showReturnRowLabel, showTransferRowLabel to true
  // for a given data.transaction_type
  styleRow(row, data) {
    const types = ['Exchange', 'Return', 'Transfer'];
    const type = data.transaction_type;
    if (types.includes(type)) {
      row.classList.add(styles[`${type.toLowerCase()}Row`]);
      this.setState({ [`show${type}RowLabel`]: true });
      return true;
    }
    return false;
  }

  _renderBulkAction() {
    if (this.state.selectedSubscriptionIds.size && this.props.isSysadmin && !this.props.isSupportManager) {
      return (
        <ActionsDropdown toggleText="Bulk Actions">
          <BulkTransferModal
            subscriber={this.props.subscriber}
            subscriptions={this.getSelectedSubscriptions()}
            closeModal={() => this.closeModal('transfer')}
            modalIsOpen={this.state.transferModalIsOpen}
            transferPath={this.props.transferPath}
            updateTable={this.updateTable}
            searchSubscribersPath={this.props.searchSubscribersPath}
            viewSubscriberPath={this.props.viewSubscriberPath}
            transferSubscriber={this.props.transferSubscriber}
          />
        </ActionsDropdown>
      );
    }

    return null;
  }

  uncheckAllCheckboxes() {
    const updatedSelectedSet = new Set(this.state.selectedSubscriptionIds);

    // Remove all ids that are from the current page:
    this._getCurrentPageCheckboxIds().forEach((id) => {
      if (updatedSelectedSet.has(id)) {
        updatedSelectedSet.delete(id);

        this._updateCheckbox({ id, checked: false });
      }
    });

    this.setState({ selectedSubscriptionIds: updatedSelectedSet, bulkChecked: 'none' });
  }

  checkAllCheckboxes() {
    const updatedSelectedSet = new Set(this.state.selectedSubscriptionIds);

    this._getCurrentPageCheckboxIds().forEach((id) => {
      updatedSelectedSet.add(id);

      this._updateCheckbox({ id, checked: true });
    });

    this.setState({ selectedSubscriptionIds: updatedSelectedSet, bulkChecked: 'all' });
  }

  _getCurrentPageCheckboxIds() {
    if (this.tableRef) {
      const rows = this.tableRef.getRows({ page: 'current' });

      if (rows) return Array.from(rows).filter(row => isRowTransferrable(row)).map(row => row.id);
    }

    return [];
  }

  _getExportedColumns() {
    const exportedColumns = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    if (this.props.isSysadmin) {
      exportedColumns.push(11);
    }

    return exportedColumns;
  }

  handleBulkCheckboxChange() {
    if (this._getBulkCheckedState(this.state.selectedSubscriptionIds) === 'all') {
      this.uncheckAllCheckboxes();
    }
    else {
      this.checkAllCheckboxes();
    }
  }

  _getBulkCheckedState(set) {
    if (set.size === 0) return 'none';

    return this._getCurrentPageCheckboxIds().every(id => set.has(id)) ? 'all' : 'some';
  }

  handleCheckboxChange(e) {
    const id = parseInt(e.target.value, 10);
    const updatedSelectedSet = new Set(this.state.selectedSubscriptionIds);

    if (updatedSelectedSet.has(id)) {
      updatedSelectedSet.delete(id);
    }
    else {
      updatedSelectedSet.add(id);
    }

    this.updateCheckboxState(id, updatedSelectedSet);
  }

  updateCheckboxState(id, updatedSelectedSet) {
    const bulkChecked = this._getBulkCheckedState(updatedSelectedSet);

    this.setState(
      {
        selectedSubscriptionIds: updatedSelectedSet,
        bulkChecked
      },
      () => this._updateCheckbox({ id, checked: this.state.selectedSubscriptionIds.has(id) })
    );
  }

  _renderBulkCheckbox() {
    const label = (
      <span className="sr-only">
        {(this.state.bulkChecked ? 'Unselect' : 'Select').concat(' all subscriptions on this page')}
      </span>
    );

    if (this.state.resources.length && document.getElementById('select-all')) {
      return ReactDOM.createPortal(
        <Checkbox
          id="select-all"
          checked={this.state.bulkChecked === 'all'}
          input={{ name: 'select-all', value: 'select-all' }}
          indeterminate={this.state.bulkChecked === 'some'}
          handleChange={this.handleBulkCheckboxChange}
          label={label}
        />,
        document.getElementById('select-all')
      );
    }

    return null;
  }

  onTableDraw() {
    // updates bulk checkbox upon table re-draw
    const currentSelectedIds = this.state.selectedSubscriptionIds;
    this.setState({
      bulkChecked: this._getBulkCheckedState(currentSelectedIds)
    });
  }

  setBulkState() {
    this.setState(prevState => ({
      bulkChecked: this._getBulkCheckedState(prevState.selectedSubscriptionIds)
    }));
  }

  _updateCheckbox({ id, checked }) {
    const target = document.getElementById(`bulk-subscription-${id}`);

    if (!target) return;

    this._renderCheckboxForRow({ id, checked, target });
  }

  _renderCheckboxForRow({ id, checked, target }) {
    const label = (
      <span className="sr-only">
        {checked ? 'Unselect row' : 'Select row'}
      </span>
    );

    ReactDOM.render(
      <Checkbox
        id={`bulk-subscription-${id}`}
        input={{ name: 'bulk-subscription', value: id.toString() }}
        checked={checked}
        handleChange={this.handleCheckboxChange}
        label={label}
      />,
      target
    );
  }

  _renderPaginatedTable() {
    return (
      <div>
        <div className={styles.flexCenter}>
          <div className={styles.flexCenter}>
            {this._renderAddCompButton()}
            {this._renderBulkAction()}
          </div>

          {this._renderRowLabels()}
        </div>

        <PaginatedTable
          doneLoadingCallback={this.setBulkState}
          addResources={this._addResources}
          columns={this.props.columns}
          resources={this.state.resources}
          ref={(ref) => { this.tableRef = ref; }}
          columnMapping={this._getColumnMapping()}
          createdRow={this.styleRow}
          exportedColumns={this._getExportedColumns()}
          defaultOrder={[[7, 'desc'], [5, 'desc'], [1, 'asc'], [3, 'asc']]}
          columnDefs={this._getColumnDefs()}
          getUrl={this.state.getPath}
          pageSize={this.props.pageSize}
          pagesLoaded={this.state.pagesLoaded}
          doneLoading={this.state.doneLoading}
          resetResources={this._resetResources}
          processResources={this.processResources}
          filterResources={TransactionsTable.filterResources}
          onDraw={this.onTableDraw}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this._renderAdvancedFilters()}
        {this._renderPaginatedTable()}
        {this._renderTransferModal()}
        {this._renderExchangeModal()}
        {this._renderBulkCheckbox()}
      </div>
    );
  }
}
