import React, { Component } from 'react';
import Axios from 'axios';
import I18n from 'i18n';
import PropTypes from 'prop-types';

import Popup from '../../../modules/popup';
import Modal, { Spinner } from '../../common/Modal';

export default class CorrelationsModal extends Component {
  static propTypes = {
    modelScope: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { ...props };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getCorrelations = this.getCorrelations.bind(this);
  }

  componentDidMount() {
    this.setState({
      modalIsOpen: false,
      data: null,
      error: false
    });
  }

  componentDidUpdate() {
    Popup.init();
  }

  getCorrelations() {
    if (this.state.data === null && !this.state.error) {
      Axios.get(this.props.correlationsUrl).then((response) => {
        this.setState({
          data: response.data
        });
      }).catch(() => {
        this.setState({ error: true });
      });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div id="correlations-modal-container">
        <button
          className="lesson-correlations btn btn--link-purple"
          onClick={this.openModal}
        >
          <img src={this.props.iconUrl} className="mr5" alt="" />{this.props.modelScope} {I18n.t('correlations')}
        </button>

        <Modal
          className="w850"
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          headerText={`Standards Correlations for this ${this.props.modelScope}`}
          error={this.state.error}
        >
          <Correlations
            getCorrelations={this.getCorrelations}
            data={this.state.data}
          />
        </Modal>
      </div>
    );
  }
}

CorrelationsModal.propTypes = {
  correlationsUrl: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired
};


export function Correlations(props) {
  props.getCorrelations();

  return (
    <div className="mh500">
      <div dangerouslySetInnerHTML={{ __html: props.data }} />
      <Spinner isLoading={props.data === null} />
    </div>
  );
}

Correlations.propTypes = {
  data: PropTypes.string,
  getCorrelations: PropTypes.func.isRequired
};

Correlations.defaultProps = {
  data: null
};
