/**
 * Used to render one dropdown element for fill in the blank questions
 * The prompt 'The sky is [blue*, red, green] and is filled with [water, air*, sand].'
 * would render TWO of these components, one for each blank

 * Example of fully rendered element (without all the HTML attributes):
 * <div className="dropdown inline">
 *   <button>Choose an answer</button>
 *   <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
 *     <a className='dropdown-item'>blue</a>
 *     <a className='dropdown-item'>red</a>
 *     <a className='dropdown-item'>green</a>
 *   </div>
 * </div>
 */

import React, { useMemo, useState } from 'react';
import I18n from 'modules/i18n';
import cn from '@/utils/cn';
import ScoreIcon from '@/components/common/Forms/ScoreIcon';
import styles from './Dropdown.module.scss';
import { fisherYatesShuffle } from '../../../../modules/TCIUtils';

interface DropdownProps {
  bracketedText: string;
  disabled?: boolean;
  handleAnswerSelect?: (index: number) => any;
  isComplete?: boolean;
  isPartiallyCorrect?: boolean;
  randomizeChoices?: boolean;
  randomizeSeed?: number;
  selectedAnswerIndex?: number;
  validateDropdownDisabled?: (choices: string[], index: number | undefined) => any;
}

const Dropdown = ({
  bracketedText,
  disabled = false,
  handleAnswerSelect = () => { },
  isComplete = false,
  isPartiallyCorrect = false,
  randomizeChoices = false,
  randomizeSeed,
  selectedAnswerIndex: initialSelectedAnswerIndex,
  validateDropdownDisabled = () => { },
}: DropdownProps) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(initialSelectedAnswerIndex);

  const parsedChoices = useMemo(() => (
    bracketedText
      .replace('[', '')
      .replace(']', '')
      .replace(/\*/g, '')
      .split(',')
      .map(choice => choice.trim())
  ), [bracketedText]);

  const answerChoices = useMemo(() => {
    if (randomizeChoices) return fisherYatesShuffle([...parsedChoices], randomizeSeed);

    return parsedChoices;
  }, [randomizeChoices]);

  const selectAnswerHandler = (index: number) => {
    const realIndex = randomizeChoices ? parsedChoices.findIndex(opt => opt === answerChoices[index]) : index;

    handleAnswerSelect(realIndex);
    setSelectedAnswerIndex(realIndex);
  };

  const answer = parsedChoices[selectedAnswerIndex];
  const displayLabel = answer || I18n.t('choose_an_answer');

  const isCorrectAnswer = validateDropdownDisabled(answerChoices[selectedAnswerIndex], selectedAnswerIndex);

  return (
    <div className="dropdown inline">
      {(isComplete || isCorrectAnswer) && (
        <ScoreIcon
          isCorrect={isCorrectAnswer && !isPartiallyCorrect}
          isPartiallyCorrect={isPartiallyCorrect}
        />
      )}
      <button
        id="dropdownMenuButton"
        type="button"
        className={cn(
          `dropdown-toggle btn btn--outline-purple ${styles.button}`, {
            [styles.correct]: isComplete && isCorrectAnswer,
            [styles.incorrect]: isComplete && !isCorrectAnswer,
          },
        )}
        data-toggle="dropdown"
        aria-haspopup="listbox"
        aria-expanded="false"
        disabled={disabled || isCorrectAnswer}
        dangerouslySetInnerHTML={{ __html: displayLabel }}
      />

      <div className={`dropdown-menu ${styles.dropdownLink}`} aria-labelledby="dropdownMenuButton">
        {answerChoices.map((choiceText: string, index: number) => (
          <a
            key={`${choiceText}-${index}`}
            className={`dropdown-item ${styles.dropdownLink}`}
            href="#"
            role="option"
            aria-selected={selectedAnswerIndex === index}
            onClick={(e) => {
              e.preventDefault();

              selectAnswerHandler(index);
            }}
            dangerouslySetInnerHTML={{ __html: choiceText }}
          />
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
