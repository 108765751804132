import React from 'react';

const Key = ({ keyName }) => (
  <div className="tw-rounded tw-h-6 tw-px-2 tw-py-0.5 tw-bg-white tw-shadow-md
    tw-shadow-very-dark-grey tw-flex tw-items-center tw-cursor-default"
  >
    <p className="tw-text-black tw-m-0">{keyName}</p>
  </div>
);

export default Key;
