import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatLocalTimestampWithFormat } from 'common/Utils';
import styles from '../../SlideShowBuilder.module.scss';
import { slideObjectPropTypes } from '../../../Utils';

const SaveStatus = ({ isSaving, slide }) => {
  const momentTime = moment(slide.updatedAt).local();

  const updatedMessage = `Saved on ${formatLocalTimestampWithFormat(momentTime, 'MMMM DD, YYYY hh:mm A')}`;

  const content = isSaving ? 'Saving...' : updatedMessage;

  return <p className={styles.saveStatus}>{content}</p>;
};

SaveStatus.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  slide: slideObjectPropTypes.isRequired
};

export default SaveStatus;
