import create from 'zustand';

type NewContentAction = 'copy' | 'reuse';

interface IProgramContentVariables {
  reuseLessonIds: number[];
  copyLessonIds: number[];
  reuseUnitIds: number[];
  copyUnitIds: number[];
  addLessonId: (type: NewContentAction, id: number) => void;
  addUnitId: (type: NewContentAction, id: number) => void;
  removeLessonId: (type: NewContentAction, id: number) => void;
  removeUnitId: (type: NewContentAction, id: number) => void;
  anyLessonsChecked: (lessonIds: number[]) => boolean;
  allLessonsChecked: (lessonIds: number[]) => boolean;
  isAnyChecked: () => boolean;
  reset: () => void;
}

const useProgramContentStore = create<IProgramContentVariables>((set, get) => ({
  reuseLessonIds: [],
  copyLessonIds: [],
  copyUnitIds: [],
  reuseUnitIds: [],
  addLessonId: (type, id) => {
    const lessonIds = new Set(get()[`${type}LessonIds`]);
    lessonIds.add(id);
    set({ [`${type}LessonIds`]: [...lessonIds] });
  },
  addUnitId: (type, id) => {
    const unitIds = new Set(get()[`${type}UnitIds`]);
    unitIds.add(id);
    set({ [`${type}UnitIds`]: [...unitIds] });
  },
  removeLessonId: (type, id) => {
    const lessonIds = new Set(get()[`${type}LessonIds`]);
    lessonIds.delete(id);
    set({ [`${type}LessonIds`]: [...lessonIds] });
  },
  removeUnitId: (type, id) => {
    const unitIds = new Set(get()[`${type}UnitIds`]);
    unitIds.delete(id);
    set({ [`${type}UnitIds`]: [...unitIds] });
  },
  isAnyChecked: () => {
    const { reuseUnitIds, reuseLessonIds, copyLessonIds, copyUnitIds } = get();
    if (reuseUnitIds.length > 0) return true;
    if (reuseLessonIds.length > 0) return true;
    if (copyLessonIds.length > 0) return true;
    return copyUnitIds.length > 0;
  },
  anyLessonsChecked: (lessonIds: number[]) => {
    const { reuseLessonIds, copyLessonIds } = get();

    if (reuseLessonIds.some(lessonId => lessonIds.includes(lessonId))) return true;

    return copyLessonIds.some(lessonId => lessonIds.includes(lessonId));
  },
  allLessonsChecked: (lessonIds: number[]) => {
    const { reuseLessonIds, copyLessonIds } = get();

    return lessonIds.every(id => [...reuseLessonIds, ...copyLessonIds].includes(id));
  },
  reset: () => set({
    reuseLessonIds: [],
    reuseUnitIds: [],
    copyLessonIds: [],
    copyUnitIds: [],
  }),
}));

export default useProgramContentStore;
