import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RosteringSetupModal from '../RosteringSetupModal';
import CompletedCard from './CompletedCard';
import UnstartedCard from './UnstartedCard';
import PartialCard from './PartialCard';

import * as constants from '../steps';
import { getCardClass } from '../Utils';

export default class RosterCard extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      contact_email: PropTypes.string,
      enabled: PropTypes.bool.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    autoRosterDistrictPath: PropTypes.string.isRequired,
    completedSteps: constants.completedStepsPropTypes.isRequired,
    env: PropTypes.bool.isRequired,
    getRosterEventsPath: PropTypes.string.isRequired,
    isSetupComplete: PropTypes.bool,
    mapCoursesPath: PropTypes.string.isRequired,
    mappedCoursesSize: PropTypes.number.isRequired,
    nextSyncTime: PropTypes.string,
    runSyncPath: PropTypes.string.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    subscriberId: PropTypes.number.isRequired,
    subscriberUpdatePath: PropTypes.string.isRequired,
    teacherSeatsLocked: PropTypes.bool,
    updateRosterDistrict: PropTypes.func.isRequired,
    updateSubscriber: PropTypes.func.isRequired,
    updateTeacherSeatsLocked: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    autoRosterDistrict: null,
    isSetupComplete: false,
    nextSyncTime: null,
    teacherSeatsLocked: false
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: constants.ROSTER_METHOD_STEP,
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteDistrictCallBack = this.deleteDistrictCallBack.bind(this);
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  deleteDistrictCallBack() {
    this.props.updateRosterDistrict(null);
    this.props.updateSubscriber({ uid: null });
  }

  openModal(e, page) {
    e.preventDefault();
    this.setState({
      currentStep: page,
      modalIsOpen: true,
    });
  }

  _renderRosteringSetupModal() {
    if (this.state.modalIsOpen) {
      return (
        <RosteringSetupModal
          autoRosterDistrict={this.props.autoRosterDistrict}
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          autoRosterDistrictPath={this.props.autoRosterDistrictPath}
          completedSteps={this.props.completedSteps}
          subscriberId={this.props.subscriberId}
          subscriber={this.props.subscriber}
          updateRosterDistrict={this.props.updateRosterDistrict}
          subscriberUpdatePath={this.props.subscriberUpdatePath}
          updateSubscriber={this.props.updateSubscriber}
          mapCoursesPath={this.props.mapCoursesPath}
          env={this.props.env}
          currentStep={this.state.currentStep}
          mappedCoursesSize={this.props.mappedCoursesSize}
          nextSyncTime={this.props.nextSyncTime}
          teacherSeatsLocked={this.props.teacherSeatsLocked}
          updateTeacherSeatsLocked={this.props.updateTeacherSeatsLocked}
          userType={this.props.userType}
        />
      );
    }

    return null;
  }

  _isSetupUnstarted() {
    return this.props.autoRosterDistrict === null;
  }

  _renderCorrectCard() {
    if (this._isSetupUnstarted()) {
      return (<UnstartedCard openModal={this.openModal} userType={this.props.userType} />);
    }

    if (this.props.isSetupComplete) {
      return (
        <CompletedCard
          deleteDistrictCallBack={this.deleteDistrictCallBack}
          nextSyncTime={this.props.nextSyncTime}
          openModal={this.openModal}
          subscriber={this.props.subscriber}
          autoRosterDistrict={this.props.autoRosterDistrict}
          autoRosterDistrictPath={this.props.autoRosterDistrictPath}
          mappedCoursesSize={this.props.mappedCoursesSize}
          mapCoursesPath={this.props.mapCoursesPath}
          getRosterEventsPath={this.props.getRosterEventsPath}
          runSyncPath={this.props.runSyncPath}
          updateRosterDistrict={this.props.updateRosterDistrict}
          userType={this.props.userType}
        />
      );
    }

    return (
      <PartialCard
        autoRosterDistrict={this.props.autoRosterDistrict}
        autoRosterDistrictPath={this.props.autoRosterDistrictPath}
        completedSteps={this.props.completedSteps}
        openModal={this.openModal}
        updateRosterDistrict={this.deleteDistrictCallBack}
        userType={this.props.userType}
      />
    );
  }

  render() {
    return (
      <div className={getCardClass(this.props.isSetupComplete)}>
        {this._renderCorrectCard()}
        {this._renderRosteringSetupModal()}
      </div>
    );
  }
}
