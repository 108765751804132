/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const PhysicalSciencesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6562 5.85009V10.4C10.6562 11.8519 9.46802 13.04 8.01618 13.04C6.56418 13.04 5.37618 11.8519 5.37618 10.4V5.85009H10.6562ZM12.6683 2.31425V10.4063V10.4066H12.6655C12.6655 11.688 12.1432 12.8507 11.3021 13.6923C10.4605 14.5335 9.29778 15.0559 8.01618 15.0559V15.0584H8.01586V15.0559C6.73442 15.0559 5.57138 14.5333 4.72994 13.6923C3.88866 12.8507 3.36642 11.688 3.36642 10.4066H3.36402V10.4063V2.31425H2.60338C2.51682 2.31425 2.44434 2.24273 2.44434 2.15521V1.07777C2.44434 0.915851 2.57666 0.783691 2.73842 0.783691H13.2936C13.4555 0.783691 13.588 0.916971 13.588 1.07777V2.15521C13.588 2.24177 13.5157 2.31425 13.429 2.31425H12.6683ZM11.4315 2.31425H4.60066V10.4063V10.4066H4.59826C4.59826 11.3432 4.9837 12.1971 5.60434 12.8179C6.22498 13.4386 7.0789 13.8242 8.01586 13.8242V13.8216H8.01602H8.01634V13.8242C8.9533 13.8242 9.80705 13.4386 10.4275 12.8179C11.0485 12.1971 11.4338 11.3432 11.4338 10.4066H11.4315V10.4063V2.31425Z"
      fill="currentColor"
    />
  </svg>
);

export default PhysicalSciencesIcon;
