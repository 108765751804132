import React from 'react';
import { CardType } from '@/components/shared/LessonOverview/types';
import clsx from 'clsx';

type CardBodyProps = {
  ariaLabel: string,
  iconSrc: string,
  linkLabel: string,
  linkHref: string,
  openInNewWindow?: boolean,
  type: string,
  unitColor?: string,
};

export const CardBody = ({
  ariaLabel,
  iconSrc,
  linkHref,
  linkLabel,
  openInNewWindow,
  type,
  unitColor,
}: CardBodyProps) => {
  const target = openInNewWindow ? '_blank' : '';

  return (
    <>
      <div
        className="tw-h-fit tw-overflow-hidden tw-relative tw-py-5 tw-px-4 tw-flex tw-items-center tw-justify-start"
      >
        <a
          href={linkHref}
          tabIndex={-1}
          aria-label={ariaLabel}
          target={target}
          aria-hidden
          className="tw-w-20 tw-h-20 tw-border-none tw-rounded-xl tw-text-white tw-flex tw-items-center
          tw-justify-center tw-cursor-pointer"
          style={{ backgroundColor: `#${unitColor}` }}
        >
          <img
            alt={ariaLabel}
            src={iconSrc}
            className="tw-h-12 tw-w-12"
          />
        </a>
        <div className="tw-h-20 tw-pl-5 tw-overflow-y-auto">
          <span className="tw-text-very-dark-grey tw-text-lg tw-leading-6">
            {type}
          </span>
          <div className="tw-text-tci-purple tw-text-xl tw-font-bold tw-leading-6 tw-mb-4 tw-tracking-wide">
            <a
              href={linkHref}
              className="hover:tw-text-very-dark-grey"
              aria-label={linkLabel}
              target={target}
            >
              {linkLabel}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

CardBody.defaultProps = {
  openInNewWindow: false,
};

type CardProps = {
  archived?: boolean,
  label: CardType,
  paddedLabel?: boolean,
  type: string,
  children: ReactChildren,
  unitColor: string,
};

const Card = (
  { archived = false, children, type }: CardProps,
) => (
  <section
    className={clsx(
      'card tw-relative tw-shrink-0 tw-w-96 tw-h-48 tw-m-2',
      { archived },
    )}
    aria-label={`${type}-card`}
    style={{ borderRadius: '5%' }}
  >
    {children}
  </section>
);

export default Card;
