import React from 'react';
import PropTypes from 'prop-types';
import styles from './Methods.module.scss';

export const UploadInfo = ({ numRows }) => (
  <div className="mb15">
    Total rows:
    {' '}
    {numRows.toLocaleString()}
    {' '}
    (no errors)
  </div>
);

UploadInfo.propTypes = {
  numRows: PropTypes.number.isRequired
};


export const Legend = () => (
  <legend className="mb5">
    <b>Select an upload method</b>
    {' '}
    <a
      href="https://www.teachtci.com/helpcenter"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more
    </a>
  </legend>
);


export const RadioButton = ({
  id, input, label
}) => (
  <div className={styles.radioButtonWrapper}>
    <input className={styles.radioButton} type="radio" id={id} {...input} />
    <label htmlFor={id}>{label}</label>
  </div>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
};


export const Warning = ({ children }) => (
  <div className={styles.warningWrapper}>
    <i
      className={`fa fa-exclamation-triangle ${styles.exclamation}`}
      aria-hidden="true"
    />

    <div>
      <p><b>WARNING:</b></p>
      <ul className={styles.warningList}>
        {children}
      </ul>
    </div>
  </div>
);

Warning.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};
