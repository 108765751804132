import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { useDataTable } from 'common/DataTable';
import * as Routes from 'routes';
import { useWindowDimensions, useMutationObserver } from 'common/Utils';
import styles from './index.module.scss';
import NotesModal from './NotesModal';
import DeleteNoteModal from './DeleteNoteModal';

export default function NotesTable({ setAdminNotesCount, slideId, slideShowId, initialSlideId, stafferId, hasTitle }) {
  const notesPath = Routes.plato_api_slide_statuses_path({ for_slide_show: slideShowId, includes: ['staffer'] });

  const {
    DataTable,
    resources,
    updateResource,
    addResource,
    deleteResource
  } = useDataTable(notesPath);

  const windowDimensions = useWindowDimensions();
  const currentSlideId = parseInt(slideId, 10) || parseInt(useMutationObserver('#slide_reel', 'data-current-slide-id'), 10) || initialSlideId;

  const notesForCurrentSlide = resources.filter(resource => resource.slide_id === currentSlideId);

  useEffect(() => {
    setAdminNotesCount(notesForCurrentSlide.length);
  }, [notesForCurrentSlide]);

  return (
    <React.Fragment>
      <div className={styles.headerContainer}>
        {hasTitle && <h2 className="inline mr10">Notes</h2>}
        <NotesModal
          type="add"
          currentSlideId={currentSlideId}
          stafferId={stafferId}
          buttonContent="Add Note"
          buttonClass="btn btn--sm btn--outline-purple"
          afterSubmit={addResource}
        />
      </div>
      <DataTable
        key={windowDimensions.width}
        columns={['created_at', 'status', 'note', 'staffer_name', 'actions']}
        info={false}
        paging={false}
        defaultOrder={[[0, 'desc'], [1, 'asc'], [2, 'asc']]}
        resources={notesForCurrentSlide}
        columnDefs={[
          {
            targets: 0,
            title: 'Date',
            createdCell: (td, cellData) => {
              ReactDOM.render(moment(cellData).format('MM/DD/YYYY @ h:mm A'), td);
            }
          },
          {
            targets: 3,
            title: 'Name'
          },
          {
            targets: 4,
            title: '',
            sortable: false,
            createdCell: (td, cellData, rowData) => {
              ReactDOM.render(
                <div className={styles.actions}>
                  <NotesModal
                    type="edit"
                    currentSlideId={currentSlideId}
                    resource={rowData}
                    stafferId={stafferId}
                    buttonContent={<i className="fa fa-edit" />}
                    buttonClass="btn btn--link-purple"
                    afterSubmit={updateResource}
                  />
                  <DeleteNoteModal resourceId={rowData.id} afterSubmit={deleteResource} />
                </div>,
                td
              );
            }
          }
        ]}
      />
    </React.Fragment>
  );
}

NotesTable.propTypes = {
  hasTitle: PropTypes.bool,
  initialSlideId: PropTypes.number,
  setAdminNotesCount: PropTypes.func,
  slideId: PropTypes.string,
  slideShowId: PropTypes.number.isRequired,
  stafferId: PropTypes.number.isRequired,
};

NotesTable.defaultProps = {
  hasTitle: true,
  initialSlideId: null,
  setAdminNotesCount: () => {},
  slideId: null,
};
