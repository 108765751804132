import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import Axios from 'axios';
import { Form } from 'react-final-form';
import Pluralize from 'pluralize';
import ReactDOM from 'react-dom';
import { usePaginatedTable } from 'common/DataTable/PaginatedTable';
import { matchAttributesForSingleRow } from 'common/DataTable/Utils';
import Tooltip from 'common/Tooltip';
import Switch from 'common/Forms/Switch';
import AdvancedFilters from 'common/AdvancedFilters';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from 'common/AdvancedFilters/Utils';
import Modal, { Footer, useModalManager } from 'common/Modal';
import ActionsDropdown from 'common/ActionsDropdown';
import Checkbox from 'common/Checkbox';
import { SelectField } from 'common/Forms';
import { required } from 'common/Forms/Validators';
import { isCurrentPageAllChecked } from 'common/BulkAction/utils';

import RulePreviewLink from './RulePreviewLink';
import ProgramMappingModal from './ProgramMappingModal';
import ProgramSyncModal from './ProgramSyncModal';
import ProgramMappingVersionsTable from '../ProgramMappingVersionsTable';
import styles from './ProgramMappingsTable.module.scss';
import ProgramMappingActions from './ProgramMappingActions';
import { formatProgramsForSelect, formatLicensesForSelect } from '../../../modules/Utils';
import AcademicTermsModal from './AcademicTermsModal';

const renderRule = (cellData, inHTML = true) => {
  const rules = cellData.map(rule => (rule.complete_rule)).join(' AND ');

  if (inHTML) return `<span>${rules}</span>`;

  return rules;
};

const ProgramMappingsTable = (props) => {
  const academicTermsModalManager = useModalManager();
  const pageSize = 10;

  const programMappingsPath = Routes.plato_api_subscriber_program_mappings_path(
    props.subscriberId,
    {
      column: 'columnName',
      includes: 'mapping_rules',
      order: 'sortDirection',
      page: 'pageNumber',
      per_page: 'pageSize',
    }
  ).replace('pageSize', pageSize.toString());

  const [getUrl, setGetUrl] = useState(programMappingsPath);
  const [programs, setPrograms] = useState([]);
  const [licenseId, setLicenseId] = useState();
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    if (!licenseId) return;

    const path = Routes.plato_api_programs_path({
      active_for_sub: props.subscriberId,
      except: ['num_lessons', 'active_subscriber_names'],
      for_license: licenseId,
    });
    Axios
      .get(path)
      .then((response) => {
        setPrograms(formatProgramsForSelect(response.data.data));
      });
  }, [licenseId]);

  useEffect(() => {
    const licensesPath = Routes.plato_api_subscriber_licenses_path({
      non_welcome: true,
      subscriber_id: props.subscriberId,
      with_current_subscriptions: ''
    });

    Axios
      .get(licensesPath)
      .then((response) => {
        setLicenses(formatLicensesForSelect(response.data.data));
      });
  }, []);

  const versionsTableHooks = usePaginatedTable(pageSize);

  const {
    PaginatedTable,
    resources,
    resetResources,
    setResources,
    updateResource: updateResourceInTable,
    deleteResource,
    addResources,
    setPagesLoaded,
    setDoneLoading,
    tableRef
  } = usePaginatedTable(pageSize);

  const updateActiveFilters = (updatedActiveFilters) => {
    setGetUrl(buildFilterPath(programMappingsPath, updatedActiveFilters));
    setDoneLoading(false);
    setResources([]);
    setPagesLoaded(0);
  };

  const addResource = (resource) => {
    matchAttributesForSingleRow(resource, columns.map(c => c.name));
    addResources([resource]);
  };

  const updateResource = (resource) => {
    matchAttributesForSingleRow(resource, columns.map(c => c.name));
    updateResourceInTable(resource);
  };

  const showActions = () => (props.userType !== 'Coordinator');

  const bulkActionsVisibilityRef = useRef();

  // use a ref to store the set instead of state to prevent re-render
  // (which causes the table to return to the first page)
  const selectedPARIds = useRef(new Set())
  const setSelectedPARIds = (newIds) => {
    selectedPARIds.current = newIds;

    // we need to manually manage the button visibility since setting the ref value doesn't trigger a re-render
    if (selectedPARIds.current.size > 0) {
      bulkActionsVisibilityRef.current.style.display = 'inline';
    }
    else {
      bulkActionsVisibilityRef.current.style.display = 'none';
    }
  };

  const removeResourcesFromTable = (parIds) => {
    parIds.map((id) => {
      const deletedPar = resources.find(par => par.id === id);
      deleteResource(deletedPar);
      setSelectedPARIds(new Set());
    });
  };

  const renderBulkAction = () => {
    const modalManager = useModalManager();
    const changeProgramModalManager = useModalManager();

    const submitDelete = () => {
      Axios.post(Routes.plato_api_bulk_action_subscriber_program_mappings_path(props.subscriberId), {
        par_ids: Array.from(selectedPARIds.current),
        bulk_action: 'delete'
      })
        .then((response) => {
          removeResourcesFromTable(response.data.program_mappings);
          modalManager.close();
        })
        .catch((e) => console.log(e));
    };

    const handleFormSubmit = (values) => {
      Axios.post(Routes.plato_api_bulk_action_subscriber_program_mappings_path(props.subscriberId), {
        bulk_action: 'change_program',
        license_id: values.license_id,
        par_ids: Array.from(selectedPARIds.current),
        program_id: values.program_id,
      })
        .then((response) => {
          response.data.data.forEach((resource) => {
            updateResource(resource);
          });

          setSelectedPARIds(new Set());
          changeProgramModalManager.close();
        })
        .catch((e) => console.log(e));
    };

    const renderDeleteModal = (
      <Modal
        closeModal={modalManager.close}
        headerText="Delete Rules"
        isOpen={modalManager.isOpen}
      >
        In the next sync, you will remove program access that is only given through these rules.
        {` Are you sure you want to delete ${Pluralize('rule', selectedPARIds.current.size, true)}?`}
        <Footer
          primaryButtonCallback={submitDelete}
          primaryButtonSubmit={false}
          primaryButtonText="Yes, delete"
          secondaryButtonCallback={modalManager.close}
        />
      </Modal>
    );

    const renderChangeProgramModel = (
      <Modal
        closeModal={changeProgramModalManager.close}
        headerText="Change Program"
        isOpen={changeProgramModalManager.isOpen}
      >
        <Form
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, values, form: { change } }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-h-[250px]">
                <SelectField
                  fieldClass={styles.fieldColumn}
                  rowClass={styles.fieldRow}
                  name="license_id"
                  labelClass={styles.addProgramLabel}
                  label={`Change this program for ${Pluralize('rule', selectedPARIds.current.size, true)}:`}
                  validate={required}
                  options={licenses}
                  onChange={(e) => {
                    setLicenseId(e);
                    change('program_id', null);
                  }}
                  placeholder="Select license..."
                />
                <SelectField
                  fieldClass={styles.fieldColumn}
                  rowClass={styles.fieldRow}
                  name="program_id"
                  labelClass={styles.addProgramLabel}
                  validate={required}
                  options={programs}
                  placeholder="Select program..."
                  hideLabel
                />
              </div>

              <Footer
                primaryButtonText="Change Program"
                secondaryButtonCallback={changeProgramModalManager.close}
                primaryButtonDisabled={!(values.program_id && values.license_id)}
              />
            </form>
          )}
        />
      </Modal>
    );

    if (showActions()) {
      return (
        <span ref={bulkActionsVisibilityRef} style={{ display: 'none' }}>
          <span className="inline_block ml10">
            <ActionsDropdown toggleText="Bulk Actions">
              <a
                className="dropdown-item"
                href="#"
                role="button"
                onClick={changeProgramModalManager.open}
              >
                <i aria-hidden="true" className="fa fa-exchange" /> Change Program
              </a>

              <a
                className="dropdown-item"
                href="#"
                role="button"
                onClick={modalManager.open}
              >
                <i aria-hidden="true" className="fa fa-trash-o" /> Delete
              </a>
            </ActionsDropdown>
            {modalManager.isOpen && renderDeleteModal}
            {changeProgramModalManager.isOpen && renderChangeProgramModel}
          </span>
        </span>
      );
    }

    return null;
  };

  const handleCheckboxChange = (e) => {
    const id = parseInt(e.target.value, 10);
    const updatedSet = new Set(selectedPARIds.current);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    }
    else {
      updatedSet.add(id);
    }

    setSelectedPARIds(updatedSet);

    if (tableRef?.current) {
      setBulkChecked(isCurrentPageAllChecked(selectedPARIds.current, tableRef.current));
    }

    renderCheckboxForRow(
      { id: id, rules: e.target.rules },
      selectedPARIds.current.has(id),
      document.getElementById(`bulk-par-${id}`),
      handleCheckboxChange
    );
  };

  const renderCheckboxForRow = (rowData, checked, parentContainer, handleCheckboxChange) => {
    return ReactDOM.render(
      <Checkbox
        checked={checked}
        handleChange={handleCheckboxChange}
        label={checked ? `Unselect ${rowData.rules}` : `Select ${rowData.rules}`}
        srOnly
        value={rowData.id.toString()}
        data-rules={rowData.rules}
      />,
      parentContainer
    );
  };

  const getCurrentPageRows = () => {
    if (tableRef?.current) {
      return Object.entries(tableRef.current.getRows());
    }
  }

  const bulkChecked = useRef('none');
  const setBulkChecked = (value) => {
    bulkChecked.current = value;

    const bulkActionCheckbox = document.getElementById('select-all');

    if (bulkActionCheckbox) {
      switch (bulkChecked.current) {
        case 'all':
          bulkActionCheckbox.checked = true;
          bulkActionCheckbox.indeterminate = false;
          return;
        case 'some':
          bulkActionCheckbox.checked = true;
          bulkActionCheckbox.indeterminate = true;
          return;
        case 'none': default:
          bulkActionCheckbox.indeterminate = false;
          bulkActionCheckbox.checked = false;
          return;
      }
    }
  }

  const handleBulkCheckboxChange = () => {
    const currentPageRows = getCurrentPageRows();

    const updatedSet = selectedPARIds.current

    if (bulkChecked.current === 'all') {
      currentPageRows.forEach((rowData) => {
        if (!document.getElementById(`bulk-par-${rowData[1].id}`)) return;

        updatedSet.delete(rowData[1].id);

        renderCheckboxForRow({ id: rowData[1].id, rules: renderRule(rowData[1].rules, false) },
          selectedPARIds.current.has(rowData[1].id), document.getElementById(`bulk-par-${rowData[1].id}`), handleCheckboxChange);
      });

      setBulkChecked('none');
    }
    else {
      currentPageRows.forEach((rowData) => {
        if (!document.getElementById(`bulk-par-${rowData[1].id}`)) return;

        updatedSet.add(rowData[1].id);
        renderCheckboxForRow({ id: rowData[1].id, rules: renderRule(rowData[1].rules, false) },
          selectedPARIds.current.has(rowData[1].id), document.getElementById(`bulk-par-${rowData[1].id}`), handleCheckboxChange);
      });

      setBulkChecked('all');
    }

    setSelectedPARIds(updatedSet);
  };

  useEffect(() => {
    $('#select-all').on('change', (e) => {
      handleBulkCheckboxChange();
    });
  }, [document.querySelectorAll('#select-all')]);

  const columns = [
    {
      name: 'select',
      title: `<input type="checkbox" id="select-all" ${bulkChecked.current === 'all' && "checked"} />`,
      orderable: false,
      sortable: false,
      searchable: false,
      data: null,
      visible: showActions(),
      createdCell: (td, cellData, rowData) => {
        $(td).attr({
          id: `bulk-par-${rowData.id}`,
        });

        renderCheckboxForRow({ id: rowData.id, rules: renderRule(rowData.rules, false) },
          selectedPARIds.current.has(rowData.id), td, handleCheckboxChange);
      }
    },
    {
      name: 'rules',
      render: renderRule,
      title: 'Rule'
    },
    {
      name: 'program_title',
      title: 'Program Name',
      width: '12%'
    },
    {
      name: 'license_code',
    },
    {
      name: 'program_code'
    },

    {
      name: 'grade'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <RulePreviewLink
            path={Routes.admin_subscriber_classes_path}
            subscriberId={rowData.subscriber_id}
            initialFilters={rowData.classes.initialFilters}
            count={rowData.classes.count}
          />,
          td
        );
      },
      name: 'classes',
      render: data => (data.count),
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <RulePreviewLink
            path={Routes.admin_subscriber_teachers_path}
            subscriberId={rowData.subscriber_id}
            initialFilters={rowData.teachers.initialFilters}
            count={rowData.teachers.count}
          />,
          td
        );
      },
      name: 'teachers',
      render: data => (data.count),
    },
    {
      createdCell: (td, cellData, rowData) => {
        if (rowData.students.hasCurrentSubscriptions) {
          ReactDOM.render(
            <RulePreviewLink
              path={Routes.admin_subscriber_students_path}
              subscriberId={rowData.subscriber_id}
              initialFilters={rowData.students.initialFilters}
              count={rowData.students.count}
            />,
            td
          );
        }
        else {
          ReactDOM.render(
            <Tooltip
              content="No student licenses have been purchased for this program."
              size="medium"
              theme="white"
            >
              <div>N/A</div>
            </Tooltip>,
            td
          );
        }
      },
      name: 'students',
      render: data => (data.count),
    },
    {
      name: 'actions',
      orderable: false,
      visible: showActions(),
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <ProgramMappingActions
            rowData={rowData}
            deleteResource={(resource) => { deleteResource(resource); resetResources(); }}
            updateResource={(resource) => { updateResource(resource); resetResources(); }}
            hasRosteredClassrooms={props.hasRosteredClassrooms}
            programsWithStudentSeats={props.programsWithStudentSeats}
            programAccessTypeHash={props.programAccessTypeHash}
          />,
          td
        );
      }
    }
  ].filter(c => c)
    .map((c, i) => ({ ...c, targets: c.name === 'actions' ? -1 : i }));

  const columnMapping = columns.map(c => ((c.name === 'actions' || c.name === 'select') ? null : c.name));

  const renderCTAButtons = () => {
    return (
      <div className="mt15">
        <ProgramMappingModal
          subscriberId={props.subscriberId}
          addResource={addResource}
          hasRosteredClassrooms={props.hasRosteredClassrooms}
          programsWithStudentSeats={props.programsWithStudentSeats}
          programAccessTypeHash={props.programAccessTypeHash}
        />

        {resources.length > 0 && props.rosterIntegrationEnabled &&
          <ProgramSyncModal subscriberId={props.subscriberId} syncInProgress={props.syncInProgress} />}

        {renderBulkAction()}
      </div>
    );
  };

  const onDraw = () => {
    // update the bulkCheckbox state when the page is changed
    if (tableRef?.current) {
      setBulkChecked(isCurrentPageAllChecked(selectedPARIds.current, tableRef.current));
    }
  };

  const renderAcademicTermsModalButton = () => (
    <button
      className="tw-border-none tw-bg-inherit tw-cursor-pointer tw-text-[#522e91] tw-text-base tw-ml-1 tw-p-0"
      type="button"
      onClick={() => { academicTermsModalManager.open(); }}
    >
      <i className="fa fa-edit" />
    </button>
  );

  return (
    <React.Fragment>
      <AdvancedFilters
        initialFilters={props.initialFilters}
        properties={props.filterProperties}
        searchPath={getUrl}
        resources={resources}
        updateActiveFilters={updateActiveFilters}
        asyncSearchPath={props.asyncSearchPath}
      />

      <hr />

      <div className={styles.heading}>Settings</div>

      <div className="mt10">
        <Switch
          label="Manage teacher program access at the admin level"
          labelTooltip="Enabling this setting will remove the ability for teachers to self-assign programs if the roster integration is on. They will be prompted to contact an admin in your account for assistance."
          ajaxPath={Routes.plato_api_subscriber_path(props.subscriberId)}
          model="subscriber"
          name="teacher_seats_locked"
          disabled={!props.isAdmin}
          disabledTooltip="Only district admins are allowed to modify this setting."
          checked={props.teacherSeatsLocked}
          displayLabelFirst={false}
        />
      </div>

      <div className="mt10">
        {!props.rosterIntegrationEnabled && (
          <Switch
            label="Manage student program access at the admin level"
            labelTooltip="Enabling this setting will remove the ability for staff to add students to a class and for students to add or remove themselves from a class."
            ajaxPath={Routes.plato_api_subscriber_path(props.subscriberId)}
            model="subscriber"
            name="student_seats_locked"
            disabled={!props.isAdmin}
            disabledTooltip="Only district admins are allowed to modify this setting."
            checked={props.studentSeatLocked}
            displayLabelFirst={false}
          />
        )}
      </div>

      <div className="mt10">
        <Switch
          label="Only include active academic terms and enrollment dates in program assignment sync"
          labelTooltip="Syncs will not assign programs to classes that have an academic term period outside of the current date. If no term information is given for a class, programs will still be assigned based on the rules. Additionally, users will be enrolled based on enrollment dates, if applicable."
          ajaxPath={Routes.plato_api_subscriber_path(props.subscriberId)}
          model="subscriber"
          name="only_provision_current_terms"
          disabled={!props.isAdmin}
          disabledTooltip="Only district admins are allowed to modify this setting."
          checked={props.onlyProvisionCurrentTerms}
          displayLabelFirst={false}
          actions={renderAcademicTermsModalButton()}
        />
      </div>

      <AcademicTermsModal
        modalManager={academicTermsModalManager}
        subscriberId={props.subscriberId}
      />

      {showActions() && renderCTAButtons()}

      <PaginatedTable
        autoWidth={false}
        columns={columns.map(c => c.name)}
        columnDefs={columns}
        columnMapping={columnMapping}
        defaultOrder={[[2, 'asc'], [1, 'asc']]}
        exportedColumns={[0, 1, 2, 3, 4, 5, 6]}
        getUrl={getUrl}
        onDraw={onDraw}
        pageLength={10}
        resources={resources}
      />

      <ProgramMappingVersionsTable
        subscriberId={props.subscriberId}
        tableHooks={versionsTableHooks}
        isSysadmin={props.isSysadmin}
        programData={props.programData}
        lastSuccessfulSyncTime={props.lastSuccessfulSyncTime}
      />
    </React.Fragment>
  );
};

ProgramMappingsTable.propTypes = {
  asyncSearchPath: PropTypes.string.isRequired,
  filterProperties: PropTypes.arrayOf(propertyPropTypes).isRequired,
  hasRosteredClassrooms: PropTypes.bool.isRequired,
  initialFilters: filtersPropTypes,
  isAdmin: PropTypes.bool.isRequired,
  isSysadmin: PropTypes.bool.isRequired,
  lastSuccessfulSyncTime: PropTypes.string,
  onlyProvisionCurrentTerms: PropTypes.bool,
  programAccessTypeHash: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  programData: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string
  })).isRequired,
  programsWithStudentSeats: PropTypes.arrayOf(PropTypes.number).isRequired,
  rosterIntegrationEnabled: PropTypes.bool.isRequired,
  studentSeatLocked: PropTypes.bool.isRequired,
  subscriberId: PropTypes.number.isRequired,
  syncInProgress: PropTypes.bool.isRequired,
  teacherSeatsLocked: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired
};

ProgramMappingsTable.defaultProps = {
  lastSuccessfulSyncTime: null,
  onlyProvisionCurrentTerms: false
};

export default ProgramMappingsTable;
