import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import ILesson from '../../types/api/Lessons';

interface UseLessonsProps {
  programId: number;
  skip?: boolean;
  attributes?: string[];
  unitId?: number
}

const DEFAULT_ATTRIBUTES = ['id', 'unit_id', 'title_with_position', 'image_url', 'title_number', 'title'];

const useLessons = ({ programId, skip, attributes = DEFAULT_ATTRIBUTES, unitId }: UseLessonsProps) => {
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const path = Routes.plato_api_program_lessons_path(programId, { only: attributes, unit_id: unitId });

  useEffect(() => {
    if (skip) return;

    setIsLoading(true);
    axios
      .get<{ data: ILesson[] }>(path)
      .then(res => setLessons(res.data.data))
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }, [programId]);

  return { isLoading, lessons };
};

export default useLessons;
