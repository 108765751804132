import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import ActionsDropdown from './ActionsDropdown';
import Thumbnail from './Thumbnail';
import { IImage } from './interfaces';

interface UseImageTableColumnsProps {
  onEditAction: (image: IImage) => void;
}

const useImageTableColumns = ({ onEditAction }: UseImageTableColumnsProps) => {
  const columnDefs = useMemo(() => (
    [
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(<Thumbnail url={rowData.url} thumbnailUrl={rowData.thumb_url} />, td);
        },
        name: 'thumb_url',
        targets: 0,
        title: 'Image',
      },
      {
        name: 'image_en_file_name',
        targets: 1,
        title: 'File Name',
        width: '30%',
        class: 'tw-align-middle',
      },
      {
        name: 'image_file_size',
        targets: 2,
        title: 'File Size',
        type: 'file-size',
        class: 'tw-align-middle',
      },
      {
        createdCell: (td, cellData) => { ReactDOM.render(<>{moment(cellData).format('MMM D, YYYY')}</>, td); },
        name: 'image_en_updated_at',
        targets: 3,
        title: 'Date Uploaded',
        class: 'tw-align-middle',
      },
      {
        name: 'num_of_uses',
        targets: 4,
        title: 'Num of Uses',
        width: '7%',
        class: 'tw-align-middle',
      },
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <ActionsDropdown
              rowData={rowData}
              openEditImageModal={onEditAction}
            />,
            td,
          );
        },
        class: 'tw-align-middle',
        name: 'actions',
        searchable: false,
        sortable: false,
        targets: 5,
      },
    ]
  ), []);

  const columns = useMemo(() => columnDefs.map(c => c.name), [columnDefs]);

  const columnMapping = useMemo(() => columnDefs.map(c => (c.name === 'actions' ? null : c.name)), [columnDefs]);

  return { columns, columnDefs, columnMapping };
};

export default useImageTableColumns;
