import React from 'react';
import Axios from 'axios';
import ReactSwitch from 'react-switch';
import { FORM_ERROR } from 'final-form';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import { CheckboxField } from 'common/Forms';
import { renderErrors, SubmitError } from 'common/Forms/Utils';
import SubmitButton from 'common/Forms/SubmitButton';
import showToast from 'common/Toast';
import { teacher_program_classrooms_path } from 'modules/routes';
import useProgramContext from '@/hooks/useProgramContext';
import styles from './Grades/GradesForm.module.scss';

export const SwitchAdapter = ({ ...props }) => (
  <ReactSwitch
    onColor="#2A8806"
    offColor="#cccccc"
    checkedIcon={false}
    uncheckedIcon={false}
    height={24}
    width={48}
    handleDiameter={20}
    {...props}
  />
);

export const InfoTooltip = ({ content }) => (
  <Tooltip size="medium" theme="white" content={content}>
    <span>
      <span className="sr-only">More info</span>
      <i className="fa fa-info-circle ml5" />
    </span>
  </Tooltip>
);

InfoTooltip.propTypes = {
  content: PropTypes.string.isRequired
};

export const SelectField = ({
  indented, label, name, onChange, options, sectionHeader, tooltip
}) => (
  <div className={styles.selectContainer}>
    <label htmlFor={name} className={indented ? styles.indented : ''}>
      <div className={sectionHeader}>{label}</div>
      {tooltip && <InfoTooltip content={tooltip} />}
    </label>

    <Field
      id={name}
      className={`ml100 ${styles.select}`}
      component="select"
      name={name}
      placeholder={null}
      onChange={onChange}
    >
      {options.map(optionData => (
        <option key={optionData.value} value={optionData.value}>{optionData.label}</option>
      ))}
    </Field>
  </div>
);

SelectField.propTypes = {
  indented: PropTypes.number,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
      })
    ])
  ).isRequired,
  sectionHeader: PropTypes.string,
  tooltip: PropTypes.string
};

export function handleSelect(args, state, utils) {
  let value = args[0].target.value;
  if (value === "true" || value == "false") value = JSON.parse(value);

  utils.changeValue(state, state.formState.active, () => value);
}

export const handleCheckbox = (args, state, utils) => {
  const isOn = args[0].target.value === 'true';
  utils.changeValue(state, args[0].target.name, () => !isOn);
};

SelectField.defaultProps = {
  indented: 0,
  sectionHeader: '',
  tooltip: null
};

export const optionList = (start, end, step, suffix = '') => {
  const optionsArray = [];

  for (let value = start; value <= end; value += step) {
    optionsArray.push({ label: `${value}${suffix}`, value: value });
  }

  return optionsArray;
};

export function formHandleSelect(args, state, utils) {
  const value = args[0] === null ? null : args[0].value;
  utils.changeValue(state, state.formState.active, () => value);
};

export const Header = ({ text, showEditPerStudentLink = false }) => {
  const { programId } = useProgramContext();

  return (
    <div className="tw-mb-5 tw-flex tw-items-center">
      <h1 className="tw-mr-5 tw-text-base">{text}</h1>
      {showEditPerStudentLink &&
        <a href={teacher_program_classrooms_path(programId)} target="_blank" rel="noopener noreferrer">
          Edit per individual student
        </a>
      }
    </div>
  );
};

export const ActionButtonsWithError = ({ handleCancel = null, submitError, submitting }) => {
  return (
    <>
      <SubmitError error={submitError} />
      <hr />
      <div className="tw-flex tw-justify-end tw-sticky tw-bottom-0 tw-bg-white tw-pb-3 tw-px-0">
        {handleCancel &&
          <button
            className="btn btn--link-purple"
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
        }

        <SubmitButton
          submitButtonText="Update"
          submitting={submitting}
        />
      </div>
    </>
  );
};

export const handleSubmit = async ({ values = {}, setSubmitting, stafferPath, onSubmitSuccess }) => {
  setSubmitting(true);

  return Axios
    .put(stafferPath, values)
    .then((response) => {
      showToast('Save successful');
      setSubmitting(false);
      onSubmitSuccess({ settings: response.data.data.settings });
    })
    .catch(error => {
      setSubmitting(false);
      showToast('Save failed', { msgType: 'error' });
      return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
    });
};

export const CheckboxOrRadioGroup = ({ form, values, label, name, text = null, negativeSetting = false }) => {

  return (
    <div className="tw-mb-5">
      <CheckboxField
        onChange={form.mutators.handleCheckbox}
        fieldClass="tw-flex tw-justify-end"
        labelClass="tw-w-full tw-font-bold"
        rowClass="tw-flex tw-justify-between tw-w-[95%] tw-mb-3"
        checked={negativeSetting ? !values[name] : values[name]}
        label={label}
        name={name}
      />
      {text}
    </div>
  );
};
