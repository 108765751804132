import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as Routes from 'routes';
import ActionsDropdown from 'common/ActionsDropdown';
import Tooltip from 'common/Tooltip';
import DeleteButton from './DeleteButton';
import ConnectClassroomButton from './ConnectClassroom';
import ConnectStudentsButton from './ConnectStudentsButton';

function InstructionsDownload({ signInInstructionsPath, rowData }) {
  return (
    <Tooltip
      content="Print this PDF with instructions and distribute each section to the respective student."
      placement="right"
      theme="white"
    >
      <a
        className="dropdown-item"
        href={signInInstructionsPath.replace(':id', rowData.id)}
        role="button"
      >
        <i aria-hidden="true" className="fa fa-file-pdf-o" /> Download Sign In Instructions
      </a>
    </Tooltip>
  );
}

InstructionsDownload.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  signInInstructionsPath: PropTypes.string.isRequired
};

export default function ClassesDropdown({
  rowData, openModal, signInInstructionsPath, assignPath, scoresPath,
  classrooms, showGoogleClassroomButton, subscriberId,
  programId, lessonId, updateClassroom, showCanvasButton
}) {
  const isGoogleIntegration = rowData.lms_type === 'google';
  const isCanvasIntegration = rowData.lms_type === 'canvas';

  return (
    <ActionsDropdown>
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'edit')}
      >
        <i aria-hidden="true" className="fa fa-edit" /> Edit Class Details
      </a>

      <a
        className="dropdown-item"
        href={Routes.teacher_classroom_students_path(rowData.id, { programId: programId })}
        role="button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i aria-hidden="true" className="fa fa-user" /> Manage Students
      </a>

      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'teachers')}
      >
        <i aria-hidden="true" className="fa fa-user" /> Manage Teachers
      </a>

      {rowData.student_seats_count > 0 && (
        <InstructionsDownload
          signInInstructionsPath={signInInstructionsPath}
          rowData={rowData}
        />
      )}

      <Tooltip
        content="To delete your last class in a program, remove the program from My Programs."
        placement="left"
        theme="white"
      >
        <DeleteButton
          openModal={openModal}
          rowData={rowData}
          numOfClassrooms={classrooms.length}
        />
      </Tooltip>

      {(showCanvasButton || showGoogleClassroomButton) && <div className="dropdown-divider" />}
      {((showGoogleClassroomButton && !isCanvasIntegration) || isGoogleIntegration) && (
        <Fragment>
          <ConnectClassroomButton
            subscriberId={subscriberId}
            programId={programId}
            lessonId={lessonId}
            rowData={rowData}
            classrooms={classrooms}
            updateClassroom={updateClassroom}
            lmsProvider="google"
          />
        </Fragment>
      )}

      {((showCanvasButton && !isGoogleIntegration) || isCanvasIntegration) && (
        <Fragment>
          <ConnectClassroomButton
            subscriberId={subscriberId}
            programId={programId}
            lessonId={lessonId}
            rowData={rowData}
            classrooms={classrooms}
            updateClassroom={updateClassroom}
            lmsProvider="canvas"
          />
        </Fragment>
      )}

      {((showGoogleClassroomButton && isGoogleIntegration) || (showCanvasButton && isCanvasIntegration)) && (
        <ConnectStudentsButton
          classroomId={rowData.id}
          lmsType={rowData.lms_type}
          programId={programId}
          subscriberId={subscriberId}
          updateClassroom={updateClassroom}
        />
      )}

      <div className="dropdown-divider" />

      <a
        className="dropdown-item"
        href={assignPath.replace('_classroom_id_', rowData.id)}
        role="button"
      >
        <i aria-hidden="true" className="fa fa-calendar" /> Assign
      </a>

      <a
        className="dropdown-item"
        href={scoresPath.replace(':id', rowData.id)}
        role="button"
      >
        <i aria-hidden="true" className="fa fa-bar-chart" /> Grades
      </a>
    </ActionsDropdown>
  );
}

ClassesDropdown.propTypes = {
  assignPath: PropTypes.string.isRequired,
  classrooms: PropTypes.arrayOf(PropTypes.shape({
    google_course_id: PropTypes.string
  })).isRequired,
  lessonId: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lms_course_id: PropTypes.string,
    lms_course_name: PropTypes.string,
    lms_type: PropTypes.string,
    name: PropTypes.string.isRequired,
    student_seats_count: PropTypes.number.isRequired
  }).isRequired,
  scoresPath: PropTypes.string.isRequired,
  showCanvasButton: PropTypes.bool.isRequired,
  showGoogleClassroomButton: PropTypes.bool.isRequired,
  signInInstructionsPath: PropTypes.string.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};
