import { SlideObject } from 'components/admin/SlideShow/types';
import { convertToDecimal } from '../../Utils';

export const initialWidth = (slideObject: SlideObject): string => {
  if (slideObject.borderWidth) return slideObject.borderWidth;

  if (slideObject.modelType === 'Question') return '10';

  return '0';
};

export const initialColor = (slideObject: SlideObject): string => {
  if (slideObject.borderColor) return slideObject.borderColor;

  if (slideObject.modelType === 'Question') return '#247F99';

  return '';
};

export const initialBorderRadius = (slideObject: SlideObject): string => {
  if (slideObject.borderRadius) return slideObject.borderRadius;

  if (slideObject.modelType === 'Question') return '4';

  return '0';
};

export const initialOpacity = (slideObject: SlideObject): string => {
  if (slideObject.borderOpacity) return convertToDecimal(slideObject.borderOpacity).toString();

  return '100';
};
