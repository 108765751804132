import React, { useState } from 'react';
import { useModalManager } from '@/components/common/Modal';
import EnableMFAModal from '../Enable/EnableMFAModal';
import DisableMFAModal from '../Disable/DisableMFAModal';

export interface IMFASetup {
  userId: string;
  mfaEnabled: boolean;
}

const SetupMFA = ({ userId, mfaEnabled }: IMFASetup) => {
  const [enabled, setEnabled] = useState(mfaEnabled);
  const MFAModal = enabled ? DisableMFAModal : EnableMFAModal;
  const modalManager = useModalManager();

  return (
    <div className="tw-w-[500px] tw-h-[500px] tw-border-[#aaa] tw-border tw-border-solid tw-rounded tw-p-[15px]">
      <button
        type="button"
        className={`pos_fr btn ${enabled ? 'btn--red' : 'btn--purple'}`}
        onClick={modalManager.open}
      >
        {enabled ? 'Disable' : 'Enable'}
      </button>
      <h2 className="tw-leading-[30px]">MFA Setup</h2>
      <hr />
      <span>Status: {enabled ? 'Enabled' : 'Disabled'}</span>
      <MFAModal
        afterSubmit={valid => setEnabled(valid)}
        userId={userId}
        modalManager={modalManager}
      />
    </div>
  );
};

export default SetupMFA;
