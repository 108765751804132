/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import apolloClient from 'components/common/ApolloClient';

import styles from './styles.module.scss';

const NotebookCheckboxesWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <NotebookCheckboxes {...props} />
  </ApolloProvider>
);

export const GET_UNIT_NOTEBOOKS_OPTIONS = gql`
  query getUnitNotebooks($unitId: ID!) {
    unit(id: $unitId) {
      id,
      titleEn
      unitNotebooks {
        id,
        titleEn
      }
    }
  }
`;

type NotebookCheckboxesProps = {
  selectedNotebooks: Set<number>,
  setSelectedNotebooks:  Function,
  unitId: string,
}

const NotebookCheckboxes = ({ selectedNotebooks, setSelectedNotebooks, unitId } : NotebookCheckboxesProps) => {
  const [notebookOptions, setNotebookOptions] = useState([])

  const queryData = { unitId: unitId.toString(), fetchPolicy: 'network-only' };
  const { loading, data } = useQuery(GET_UNIT_NOTEBOOKS_OPTIONS, { variables: queryData });

  useEffect(() => {
    if (loading) return;

    setNotebookOptions(data.unit.unitNotebooks.map(notebook => ({ label: notebook.titleEn, value: notebook.id })));
  }, [data])

  useEffect(() => {
    setSelectedNotebooks(new Set(notebookOptions.map(nb => nb.value)));
  }, [notebookOptions])

  const handleToggle = (notebookId) => {
    if (selectedNotebooks.has(notebookId)) {

      setSelectedNotebooks(prev => {
        selectedNotebooks.delete(notebookId);
        return new Set(selectedNotebooks);
      });
    }
    else {
      setSelectedNotebooks(prev => new Set(prev.add(notebookId)));
    }
  };

  const allChecked = () => notebookOptions.every(nb => selectedNotebooks.has(nb.value));

  const handleBulkToggle = () => {
    if (allChecked()) {
      setSelectedNotebooks(new Set());
    }
    else {
      setSelectedNotebooks(new Set(notebookOptions.map(nb => nb.value)));
    }
  };

  const NotebookCheckbox = (notebook) => {
    return (
      <div className={styles.individualCheckboxes} key={notebook.value}>
        <label htmlFor={notebook.value}>
          <input
            checked={selectedNotebooks.has(notebook.value)}
            id={notebook.value}
            onChange={() => handleToggle(notebook.value)}
            type="checkbox"
          />
          {notebook.label}
        </label>
      </div>
    )
  };

  const renderOptions = () => {
    if (notebookOptions.length === 0) {
      return (
        <p>No Notebooks for selected Unit</p>
      )
    }

    return (
      <span className={styles.checkboxGroup}>
        <label htmlFor="bulk-notebook-checkbox">
          <input
            type="checkbox"
            id="bulk-notebook-checkbox"
            onClick={handleBulkToggle}
            onChange={handleBulkToggle}
            checked={allChecked()}
          />
          Select All
        </label>

        {notebookOptions.map(NotebookCheckbox)}
      </span>
    )
  }

  return renderOptions();
}

export default NotebookCheckboxesWrapper;
