const ClipboardJS = require('clipboard');
const tippy = require('tippy.js');

export class ClipboardButton {
  /*
   * `tooltip_origin_class` - the selector to which the tooltip will appear in reference
   * `clipboard_trigger` - the selector that will trigger the tippy event
   */
  constructor(config) {
    this.tooltip_origin_class = config.tooltip_origin_class;
    this.clipboard_trigger = config.clipboard_trigger;
    this.tippys = document.querySelectorAll(this.tooltip_origin_class);
  }

  init() {
    tippy.default(this.tippys, {
      trigger: 'manual'
    });

    this.clipboard = new ClipboardJS(this.clipboard_trigger);

    this._setup_listeners();
  }

  _setup_listeners() {
    this.clipboard.on('success', e => this.show_tooltip(e, 'Copied to clipboard!'));

    this.clipboard.on('error', e => this.show_tooltip(e, 'Not copied. Please try again.'));
  }

  show_tooltip(e, tippy_text) {
    const tippy_el = $(e.trigger).closest(this.tooltip_origin_class).get()[0];
    if (!tippy_el) { return; }
    tippy_el._tippy.popper.querySelector('.tippy-content').textContent = tippy_text;

    tippy_el._tippy.show();
    setTimeout(() => {
      tippy_el._tippy.hide();
    }, 1000);

    e.clearSelection();
  }
}

export const initClipboard = () => {
  const clipboardButtonConfig = {
    clipboard_trigger: '.js-clipboard-trigger',
    tooltip_origin_class: '.js-tippy-trigger-with-clipboard'
  };

  const clipboardButton = new ClipboardButton(clipboardButtonConfig);
  clipboardButton.init();
};
