import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { SpinnerIcon } from '@/components/common/Utils';
import Modal, { useModalManager, Footer } from 'common/Modal';
import * as Routes from 'routes';
import { formatProgramsForSelect } from '../../../modules/Utils';

const AddProgramButton = ({ subscriberId }) => {
  const modalManager = useModalManager();

  return (
    <React.Fragment>
      <button type="button" className="btn btn--green" onClick={modalManager.open}>
        Add Program
        {' '}
        <span className="fa fa-lock" />
      </button>

      <Modal headerText="Add Program" isOpen={modalManager.isOpen} closeModal={modalManager.close} allowOverflow>
        <ModalBody close={modalManager.close} subscriberId={subscriberId} />
      </Modal>
    </React.Fragment>
  );
};

AddProgramButton.propTypes = {
  subscriberId: PropTypes.number.isRequired
};

export default AddProgramButton;

const ModalBody = ({ close, subscriberId }) => {
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const only = ['id', 'code', 'full_title_with_edition'];
    const scope = { inactive_for_sub: subscriberId, only };

    const path = Routes.plato_api_programs_path(scope);

    setIsLoading(true);
    Axios
      .get(path)
      .then(response => setPrograms(response.data.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleSelect = (program) => {
    setSelectedProgram(program.value);
  };

  const addProgram = () => {
    const url = Routes.plato_api_add_program_subscriber_programs_path({ subscriber_id: subscriberId });
    Axios.post(url, { program_id: selectedProgram }).then(() => {
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      <div className="tw-flex tw-items-center">
        <Select
          id="program_id"
          className="w90 tw-mr-1.5"
          searchable
          required
          onChange={handleSelect}
          value={selectedProgram}
          options={formatProgramsForSelect(programs)}
        />
        {isLoading && <span><SpinnerIcon /></span>}
      </div>

      <Footer
        primaryButtonText="Add Program"
        primaryButtonCallback={addProgram}
        primaryButtonSubmit={false}
        secondaryButtonCallback={close}
        secondaryButtonText="Cancel"
      />
    </React.Fragment>
  );
};

ModalBody.propTypes = {
  close: PropTypes.func.isRequired,
  subscriberId: PropTypes.number.isRequired
};
