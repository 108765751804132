import React from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { FilterCategory } from '@/components/common/libraries/common/Filter';
import Checkbox from '@/components/common/libraries/common/Checkbox';
import { LinkButton } from '@/components/ui/Buttons';
import useSearchStore from '../useSearchStore';
import { SearchFilterType, SearchFilter } from '../types';
import { allFilters } from './filterCategories';

const removeNullValues = <T extends Record<string, any>>(obj: T): T => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach(key => newObj[key] === null && delete newObj[key]);

  return newObj;
};

const Filters = () => {
  const filters = useSearchStore(state => state.filters);
  const setFilters = useSearchStore(state => state.setFilters);
  const isLoading = useSearchStore(state => state.isLoading);
  const setCancelTokenSource = useSearchStore(state => state.setCancelTokenSource);
  const cancelTokenSource = useSearchStore(state => state.cancelTokenSource);
  const someChecked = (type: SearchFilterType) => filters[type].some(filter => filter.checked);
  const allChecked = (type: SearchFilterType) => filters[type].every(filter => filter.checked);
  const clearFilters = useSearchStore(state => state.clearFilters);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userType } = useParams();

  const updateSearchParams = (type: SearchFilterType, newFilters: SearchFilter[]) => {
    const allNewParams = {
      term: searchParams.get('term'),
      exclude: searchParams.get('exclude'),
      location: searchParams.get('location'),
      language: searchParams.get('language'),
      reading: searchParams.get('reading'),
      reference_material: searchParams.get('reference_material'),
      image: searchParams.get('image'),
      slide_show: searchParams.get('slide_show'),
      [type]: newFilters.filter(f => f.checked).map(f => f.value).join(',') || null,
    };

    const newSearchParams = removeNullValues(allNewParams);

    if (isLoading) {
      cancelTokenSource.cancel('Canceled');
      setCancelTokenSource(axios.CancelToken.source());
    }

    setSearchParams(newSearchParams);
  };

  const handleFilterChange = (type: SearchFilterType, value: string | number, checked: boolean) => {
    const oldFilters = filters[type];

    const newFilters = oldFilters.map(filter => (
      filter.value === value ? { ...filter, checked } : filter
    ));

    setFilters({ ...filters, [type]: newFilters });

    updateSearchParams(type, newFilters);
  };

  const setAllFilters = (type: SearchFilterType, checked: boolean) => {
    const newFilters = filters[type].map(filter => ({ ...filter, checked }));

    setFilters({ ...filters, [type]: newFilters });

    updateSearchParams(type, newFilters);
  };

  const clearAllFilters = () => {
    clearFilters();

    const newSearchParams = removeNullValues({
      term: searchParams.get('term'),
      exclude: searchParams.get('exclude'),
      location: searchParams.get('location'),
    });

    setSearchParams(newSearchParams);
  };

  return (
    <div className="tw-sticky tw-top-1 tw-h-0 tw-flex-grow-0 tw-flex-shrink-0 tw-basis-60">
      <div className="card tw-mr-3 tw-mt-3">
        <h1 className="tw-px-4 tw-mt-3">Filters</h1>
        <LinkButton
          className="tw-absolute tw-right-1 tw-top-5 tw-text-sm tw-cursor-pointer"
          onClick={clearAllFilters}
        >
          Clear
        </LinkButton>
        <hr className="tw-mx-2 tw-mt-1 tw-mb-2" />
        <FilterCategory
          filterClassName="tw-pl-4"
          className="tw-pr-4 tw-mb-1"
          shouldCollapse={false}
          triggerElement={<h1 className="tw-px-4 tw-mb-3 hover:tw-text-tci-purple">Category</h1>}
        >
          {allFilters(userType).map(filter => (
            <FilterCategory
              key={filter}
              filterClassName="tw-pl-8"
              filters={filters[filter]}
              onChange={(value, checked) => {
                handleFilterChange(filter, value, checked);
              }}
              shouldCollapse={false}
              triggerElement={(
                <Checkbox
                  extraInputClassName="hover:tw-outline hover:tw-border-black focus:tw-outline focus:tw-outline-1 focus:tw-outline-black"
                  extraClassName="tw-pb-2 tw-pl-4 tw-pr-4"
                  indeterminate={someChecked(filter) && !allChecked(filter)}
                  label={filter.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  checked={allChecked(filter)}
                  onChange={(event) => {
                    setAllFilters(filter, event.target.checked);
                  }}
                />
              )}
            />
          ))}
        </FilterCategory>
      </div>
    </div>
  );
};

export default Filters;
