/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const HistoryIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.42703 6.45264H2.58447V10.9938H4.42703V6.45264Z" fill="currentColor" />
    <path d="M7.36877 6.45264H5.52637V10.9938H7.36877V6.45264Z" fill="currentColor" />
    <path d="M10.3105 6.45264H8.46777V10.9938H10.3105V6.45264Z" fill="currentColor" />
    <path d="M13.2522 6.45264H11.4097V10.9938H13.2522V6.45264Z" fill="currentColor" />
    <path
      d="M1.84716 5.88569C1.71676 5.88569 1.61084 5.77977 1.61084 5.64921V4.42265C1.61084 4.31849 1.67916 4.22649 1.77884 4.19641L7.84988 2.36457C7.89436 2.35113 7.94172 2.35113 7.98636 2.36457L8.60764 2.55209C8.6766 2.57273 8.7318 2.62361 8.75868 2.69001L8.96892 3.21305L9.6406 3.68025L10.4544 3.51321C10.5128 3.50121 10.5732 3.51177 10.6243 3.54249L11.5161 4.08217L12.7195 3.82361C12.7585 3.81513 12.799 3.81673 12.8372 3.82841L14.0566 4.19641C14.1566 4.22649 14.2248 4.31849 14.2248 4.42265V5.64921C14.2248 5.77977 14.1188 5.88569 13.9884 5.88569H1.84716Z"
      fill="currentColor"
    />
    <path
      d="M14.2013 11.6703C14.158 11.6021 14.0826 11.5605 14.002 11.5605H1.8346C1.7538 11.5605 1.67844 11.6021 1.63508 11.6703L0.834115 12.9341C0.787875 13.0068 0.785155 13.0989 0.826595 13.1746C0.868195 13.2503 0.947395 13.2972 1.03364 13.2972H14.8026C14.8029 13.2972 14.8029 13.2972 14.8032 13.2972C14.9338 13.2972 15.0399 13.1914 15.0399 13.0605C15.0399 13.0084 15.0231 12.9601 14.9941 12.9209L14.2013 11.6703Z"
      fill="currentColor"
    />
    <path
      d="M14.2251 5.64921V4.42265C14.2251 4.31849 14.1569 4.22649 14.0569 4.19641L12.8376 3.82841C12.7993 3.81673 12.7588 3.81513 12.7198 3.82361L11.5164 4.08217L10.6246 3.54249C10.5737 3.51177 10.5132 3.50121 10.4547 3.51321L9.64092 3.68025L8.96924 3.21305L8.759 2.69001C8.73212 2.62345 8.67676 2.57273 8.60796 2.55209L7.98668 2.36457C7.9422 2.35113 7.89468 2.35113 7.8502 2.36457L1.77884 4.19641C1.679 4.22649 1.61084 4.31849 1.61084 4.42265V5.64921C1.61084 5.77977 1.7166 5.88569 1.84716 5.88569H13.9889C14.1192 5.88569 14.2251 5.77977 14.2251 5.64921Z"
      fill="currentColor"
    />
  </svg>
);

export default HistoryIcon;
