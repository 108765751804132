import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import AddForm from './AddForm';

import styles from '../Modal.module.scss';

export default function AddModal({
  closeModal, modalIsOpen, states, createPath, updateTable
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      headerText="Add Subscriber"
      isOpen={modalIsOpen}
    >
      <AddForm
        closeModal={closeModal}
        stateList={states}
        createPath={createPath}
        updateTable={updateTable}
      />
    </Modal>
  );
}

AddModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  createPath: PropTypes.string.isRequired,
  updateTable: PropTypes.func.isRequired
};
