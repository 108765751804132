import React from 'react';
import {
  ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, HttpLink, NormalizedCacheObject,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import PropTypes from 'prop-types';
import * as Routes from '@/modules/routes';

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink({ uri: Routes.graphql_path() }),
  new HttpLink({
    headers: { 'Accept-Language': document.querySelector('html').lang },
    uri: Routes.graphql_path(),
  }),
);

class ApolloClientClass {
  client: ApolloClient<NormalizedCacheObject>;

  constructor() {
    this.client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
    });
  }
}

const ApolloClientProvider = ({ children }: { children: ReactChildren }) => (
  <ApolloProvider client={new ApolloClientClass().client}>
    {children}
  </ApolloProvider>
);

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const apolloClient = new ApolloClientClass().client;

export { ApolloClientProvider as ApolloProvider };

export default apolloClient;
