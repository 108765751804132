import React from 'react';
import ReactSelect5, { ReactSelect5Props } from '@/components/common/ReactSelect5';
import cn from '@/utils/cn';

export type Option = { label: string | React.ReactNode, value: any };

interface SectionEditorDropdownProps<T extends Option> {
  className?: string;
  initialValue: T['value'];
  label: string;
  labelClassName?: string;
  name: string;
  options: T[];
  onChange: (value: T['value']) => any;
  SelectProps?: ReactSelect5Props;
}

const SectionEditorDropdown = <T extends Option>({
  className,
  initialValue,
  label,
  labelClassName = '',
  name,
  onChange,
  options,
  SelectProps = {},
}: SectionEditorDropdownProps<T>) => {
  const handleChange = ({ value }: T) => {
    onChange(value);
  };

  const defaultValue = options.find(opt => opt.value === initialValue);

  return (
    <div className={cn('tw-flex tw-items-center input !tw-overflow-visible', className)}>
      <label className={cn('!tw-p-0', labelClassName)} htmlFor={name}>
        {label}
      </label>
      <div className="tw-w-[230px] tw-inline-block tw-overflow-visible">
        <ReactSelect5
          defaultValue={defaultValue}
          name={name}
          options={options}
          onChange={handleChange}
          {...SelectProps}
        />
      </div>
    </div>
  );
};

export default SectionEditorDropdown;
