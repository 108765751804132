import React from 'react';
import Tooltip from 'common/Tooltip';

const DisableButton = ({ }) => {

    return (
      <Tooltip
        interactive={false}
        content={'There is no student work to clear.'}
        theme="white"
        size="medium"
      >
        <span>
          <button disabled={true} aria-disabled={true} aria-selected={false} className={'btn btn--sm btn--outline-red'}>
            Clear All
          </button>
        </span>
      </Tooltip>
    );
};

export default DisableButton;
