import React from 'react';
import { className } from '../Toolbar/ShapesButton/ShapeCategory';
import TextSizeChange from './MoreMenuActions/TextSizeChange';
import useDrawingContext from '../store/useDrawingContext';

interface MoreMenuProps {
  type?: 'text';
}

const MoreMenu = ({ type }: MoreMenuProps) => {
  const moveObjects = useDrawingContext(state => state.moveObjects);

  return (
    <div className="tw-flex tw-flex-col">
      {type === 'text' ? (
        <TextSizeChange />
      ) : (
        <>
          <button
            className={className()}
            type="button"
            onClick={() => moveObjects('front')}
          >
            Bring to Front
          </button>
          <button
            className={className()}
            type="button"
            onClick={() => moveObjects('back')}
          >
            Send to Back
          </button>
        </>
      )}
    </div>
  );
};

export default MoreMenu;
