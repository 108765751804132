import React, { useState } from 'react';
import Select from '@/components/common/ReactSelect5';
import ILicense from '@/components/interfaces/License';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';

interface IOption {
  label: string;
  value: number;
}

const createOption = (license: ILicense) => ({ label: license.name, value: license.id });

interface Props {
  initialLicenses: ILicense[];
  licenses: ILicense[];
  errors?: string;
}

const ProgramLicensesSelector = ({ initialLicenses, licenses, errors }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<IOption[]>(initialLicenses.map(createOption));

  return (
    <>
      <div className="tw-flex tw-items-center">
        <label
          className="tw-min-w-[140px] tw-font-bold tw-text-very-dark-grey"
          htmlFor="license_ids"
        >
          Licenses
          <RequiredAsterisk />
        </label>
        <div className="tw-min-w-[230px] tw-max-w-[520px]">
          <Select
            inputValue={inputValue}
            isMulti
            onChange={newValue => setValue(newValue as IOption[])}
            onInputChange={newValue => setInputValue(newValue)}
            placeholder="Select licenses"
            value={value}
            options={licenses.map(createOption)}
            borderColorOverride="#aaa"
          />
        </div>
        <input
          className="string optional"
          id="license_ids"
          name="program[license_ids]"
          type="hidden"
          value={JSON.stringify(value.map(option => option.value))}
        />
      </div>
      {errors && <span className="error">{errors}</span>}
    </>
  );
};

export default ProgramLicensesSelector;
