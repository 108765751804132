import Axios from 'axios';
import * as Routes from 'modules/routes';
import React, { useState, useReducer, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import I18n from 'modules/i18n';
import BuilderContext from 'components/common/Context';
import { useModalManager } from 'components/common/Modal';
import { useDebouncedEffect } from 'components/common/Utils';
import LinkVocabularyWrapper from './LinkVocabularyButton/LinkVocabularyWrapper';
import Container from './Container';
import DraggableChoiceEditor from './DraggableChoiceEditor';
import DropZoneButton from './DropZone/DropZoneButton';
import BackgroundImageButton from './BackgroundImageButton';
import SettingsButton from './SettingsButton';
import SectionHeader from './SectionHeader';
import backgroundReducer from './reducers/backgroundReducer';
import dropZoneReducer from './reducers/dropZoneReducer';
import draggableChoiceReducer from './reducers/draggableChoiceReducer';
import correctAnswerReducer from './reducers/correctAnswerReducer';
import dragAndDropSettingsReducer from './reducers/dragAndDropSettingsReducer';
import textAreaReducer from './reducers/textAreaReducer';
import AddTextAreaButton from './TextArea/AddTextAreaButton';
import useVocabularyStore from '../useVocabularyStore';
import CustomDragLayer from './CustomDragLayer';
import styles from './DragAndDrop.module.scss';
import CopyingText from './CopyingText';
import { formatVocab } from '../utils';
import { DragAndDropSettings } from '../types';

const SNAP_TO_GRID = true;

interface BuilderProps {
  backgroundImage?: {
    id: number;
    url: string;
    position?: string;
    size?: string;
  };
  data: {
    correct_answers: object;
    draggable_choices: object;
    drop_zones: object;
    text_areas: object;
    has_transparent_answer_choices: boolean;
    has_transparent_dropzones: boolean;
    drop_zone_shape: 'rectangle' | 'circle';
    draggable_container_position: 'top' | 'bottom' | 'left' | 'right';
  };
  lessonId?: number;
  questionId: number;
  questionTranslationId: number;
  spanishImagesInMicAndDd?: boolean;
  userType: UserType;
}

const Builder = ({
  backgroundImage = { id: null, url: null },
  lessonId,
  questionId,
  questionTranslationId,
  data,
  spanishImagesInMicAndDd = false,
  userType,
}: BuilderProps) => {
  const modalManager = useModalManager();
  const [dropZones, dropZoneDispatch] = useReducer(dropZoneReducer, data.drop_zones);
  const [draggableChoices, draggableChoiceDispatch] = useReducer(draggableChoiceReducer, data.draggable_choices);
  const [correctAnswers, correctAnswerDispatch] = useReducer(correctAnswerReducer, data.correct_answers);
  const [dragAndDropSettings, dragAndDropSettingsDispatch] = useReducer(
    dragAndDropSettingsReducer,
    {
      hasTransparentAnswerChoices: data.has_transparent_answer_choices,
      hasTransparentDropzones: data.has_transparent_dropzones,
      dropZoneShape: data.drop_zone_shape,
      draggableContainerPosition: data.draggable_container_position,
    },
  );
  const { hasTransparentAnswerChoices, hasTransparentDropzones } = dragAndDropSettings as DragAndDropSettings;
  const [background, backgroundDispatch] = useReducer(backgroundReducer, backgroundImage);
  const [textAreas, textAreaDispatch] = useReducer(textAreaReducer, data.text_areas);
  const [isSaving, setIsSaving] = useState(false);
  const [backgroundRef, setBackgroundRef] = useState();
  const [isEditingLabels, setIsEditingLabels] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [loading, setLoading] = useState(true);
  const setVocabularyTerms = useVocabularyStore(state => state.setVocabularyTerms);
  const [dropZoneShape, setDropZoneShape] = useState(data.drop_zone_shape || 'rectangle');
  const [draggableContainerPosition, setDraggableContainerPosition] = useState(data.draggable_container_position || 'left');

  const saveObjects = () => {
    setIsSaving(true);
    const route = Routes.plato_api_question_path(questionId);
    Axios.put(route, {
      data: {
        correct_answers: correctAnswers,
        draggable_choices: draggableChoices,
        drop_zones: dropZones,
        text_areas: textAreas,
        has_transparent_answer_choices: hasTransparentAnswerChoices,
        has_transparent_dropzones: hasTransparentDropzones,
        drop_zone_shape: dropZoneShape,
        draggable_container_position: draggableContainerPosition,
      },
    }).then(() => setIsSaving(false));
  };

  useDebouncedEffect(() => {
    saveObjects();
  }, [
    correctAnswers,
    draggableChoices,
    dropZones,
    textAreas,
    hasTransparentAnswerChoices,
    hasTransparentDropzones,
    dropZoneShape,
    draggableContainerPosition,
  ], 500);

  useEffect(() => {
    if (!lessonId) return;
    const route = Routes.plato_api_get_glossary_terms_glossary_terms_path({ lesson_id: lessonId });

    Axios.get(route)
      .then((response) => {
        if (response.data.glossary_terms) setVocabularyTerms(formatVocab(response.data.glossary_terms));
        setLoading(false);
      }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <BuilderContext.Provider
      value={{
        backgroundDispatch,
        backgroundRef,
        correctAnswerDispatch,
        draggableChoiceDispatch,
        draggableContainerPosition,
        draggableChoices,
        dropZoneDispatch,
        dropZones,
        dropZoneShape,
        hasTransparentAnswerChoices,
        hasTransparentDropzones,
        dragAndDropSettingsDispatch,
        isCopying,
        isEditingLabels,
        modalManager,
        questionId,
        setBackgroundRef,
        setDropZoneShape,
        setDraggableContainerPosition,
        setIsCopying,
        setIsEditingLabels,
        textAreaDispatch,
        textAreas,
        loading,
      }}
    >
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <SectionHeader
          header={I18n.t('drag_drop_dropzones_header')}
          subHeader={I18n.t('drag_drop_dropzones_subheader')}
        />
        <div className={styles.controlsHeader}>
          <DropZoneButton />
          <BackgroundImageButton
            background={background}
            questionId={questionId}
            spanishImagesInMicAndDd={spanishImagesInMicAndDd}
            userType={userType}
          />
          <AddTextAreaButton />
          <div>
            <SettingsButton
              hasTransparentAnswerChoices={hasTransparentAnswerChoices}
              hasTransparentDropzones={hasTransparentDropzones}
            />
          </div>
          <CopyingText />
        </div>
        <Container
          {...{ background, correctAnswers, draggableChoices, dropZones, isSaving, questionTranslationId, textAreas }}
          snapToGrid={SNAP_TO_GRID}
        />
        <CustomDragLayer snapToGrid={SNAP_TO_GRID} />
        <SectionHeader
          header={I18n.t('drag_drop_draggable_header')}
          subHeader={I18n.t('drag_drop_draggable_subheader')}
        />
        {lessonId && (
          <LinkVocabularyWrapper
            text="Link Vocabulary"
            icon="fa-link"
          />
        )}
        <DraggableChoiceEditor draggableChoices={draggableChoices} />
      </DndProvider>
    </BuilderContext.Provider>
  );
};

export default Builder;
