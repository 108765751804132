import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IExerciseResult } from '../../../types';
import useUnitProjects from '../useUnitProjects';
import useGradesTableStore from '../../../store/useGradesTableStore';

interface IApiResponse {
  data: IExerciseResult[];
}

const useUnitProjectExerciseResults = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const unit = useGradesTableStore(state => state.unit);
  const isEnabled = !!(classroom?.id && unit?.id);

  const query = useQuery(['ExerciseResults', 'UnitProjects', classroom?.id, unit?.id], () => (
    axios.get<IApiResponse>(Routes.plato_api_unit_project_results_exercise_results_path({
      only: [
        'id', 'student_id', 'exercise_type', 'exercise_id', 'score',
        'requires_clever_passback', 'lms_settings', 'completed_at', 'updated_since_graded',
      ],
      classroom_id: classroom.id,
      unit_id: unit.id,
    })).then(res => res.data.data)
  ), { enabled: isEnabled, placeholderData: [] });

  return { ...query, isEnabled };
};

export default useUnitProjectExerciseResults;
