import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleOption } from 'common/ToggleButtonGroup';
import styles from './Navigation.module.scss';

export default function GradingToggleGroups({ ...props }) {
  const isStudent = () => props.modelType === 'student';
  const isQuestion = () => props.modelType === 'question';

  const viewByToggleGroup = () => {
    if (props.activityType) return;

    return (
      <ToggleButtonGroup id="view-by-toggle" className={styles.groupContainer} labelText="View by">
        <ToggleOption
          buttonText="Student"
          onToggle={() => {
            window.location.assign(props.studentViewPath);
          }}
          active={isStudent()}
        />
        <ToggleOption
          buttonText="Question"
          onToggle={() => {
            window.location.assign(props.questionViewPath);
          }}
          active={isQuestion()}
        />
      </ToggleButtonGroup>
    )
  };

  const toggleQuestionCardClasses = (type) => {
    const allSections = $('.question-card-numberings');
    const allQuestions = $('.new-question-card').not('.sample-question');

    if (type === 'all') {
      allQuestions.removeClass('hidden');
      allSections.removeClass('hidden');
    }
    else {
      const unansweredQuestions = $('.unanswered-question').not('.sample-question');
      const questionsToHide = type === 'answered' ? unansweredQuestions : allQuestions.not(unansweredQuestions);

      allQuestions.removeClass('hidden');
      questionsToHide.addClass('hidden');
      allSections.each((_, section) => {
        $(section).removeClass('hidden');
        if ($(section).find(allQuestions).not(questionsToHide).length === 0) {
          $(section).addClass('hidden');
        }
      });
    }
  };

  const questionsToggleGroup = () => (
    <ToggleButtonGroup
      id={`${isStudent() ? 'questions' : 'student-responses'}-toggle`}
      labelText={`${isStudent() ? 'Questions' : 'Student Responses'}`}
      className={styles.groupContainer}
      useCookie
    >
      <ToggleOption
        buttonText="All"
        onToggle={() => toggleQuestionCardClasses('all')}
        active
      />
      <ToggleOption
        buttonText="Answered"
        onToggle={() => toggleQuestionCardClasses('answered')}
      />
      <ToggleOption
        buttonText="Unanswered"
        onToggle={() => toggleQuestionCardClasses('unanswered')}
      />
    </ToggleButtonGroup>
  );

  const toggleAnswerKeyClasses = (type) => {
    const answerKeyIcons = $('.answer-key-toggle i');
    const answerKeyContainers = $('.question-answer-key');

    if (type === 'show') answerKeyContainers.show();
    if (type === 'hide') answerKeyContainers.hide();
    answerKeyIcons.removeClass(type === 'show' ? 'fa-caret-right' : 'fa-caret-down');
    answerKeyIcons.addClass(type === 'show' ? 'fa-caret-down' : 'fa-caret-right');
  };

  const answerKeysToggleGroup = () => (
    <ToggleButtonGroup
      id="answer-key-toggle"
      labelText="Answer Keys"
      className={styles.groupContainer}
      useCookie
    >
      <ToggleOption
        buttonText="Show"
        onToggle={() => toggleAnswerKeyClasses('show')}
        active
      />
      <ToggleOption
        buttonText="Hide"
        onToggle={() => toggleAnswerKeyClasses('hide')}
      />
    </ToggleButtonGroup>
  );

  const toggleAutoGradedClasses = (type) => {
    const autoGradedQuestions = $('.autograded-question');

    if (type === 'show') autoGradedQuestions.show();
    if (type === 'hide') autoGradedQuestions.hide();
  };

  const autoGradedToggleGroup = () => (
    <ToggleButtonGroup
      id="auto-graded-toggle"
      labelText="Auto-graded"
      className={styles.groupContainer}
      useCookie
    >
      <ToggleOption
        buttonText="Show"
        onToggle={() => toggleAutoGradedClasses('show')}
        active
      />
      <ToggleOption
        buttonText="Hide"
        onToggle={() => toggleAutoGradedClasses('hide')}
      />
    </ToggleButtonGroup>
  );

  return (
    <div className={styles.toggleGroupsContainer}>
      {viewByToggleGroup()}
      {questionsToggleGroup()}
      {answerKeysToggleGroup()}
      {isStudent() && props.displayAutoGradedToggle && autoGradedToggleGroup()}
    </div>
  );
}

GradingToggleGroups.propTypes = {
  activityType: PropTypes.string.isRequired,
  displayAutoGradedToggle: PropTypes.bool.isRequired,
  modelType: PropTypes.string.isRequired,
  questionViewPath: PropTypes.string.isRequired,
  studentViewPath: PropTypes.string.isRequired
};
