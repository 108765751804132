import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import { Input } from '@/components/ui/Form';
import { insertIntoEditor } from '../FroalaListeners';

interface EquationInsertProps {
  editorInstance: any;
  modalManager: ModalManager;
}

interface FormData {
  equation: string;
}

const EquationInsert = ({ editorInstance, modalManager }: EquationInsertProps) => {
  const { close, isOpen } = modalManager;
  const [svg, setSvg] = useState(null);
  const imgSrc = `data:image/svg+xml;base64,${btoa($(svg).find('svg')[0]?.outerHTML)}`;

  const {
    formState: {
      errors,
      isValid,
    },
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { equation: '' },
  });

  const generateSvg = equation => window.MathJax.asciimath2svg(equation, { display: true });

  const onSubmitAsSvg: SubmitHandler<FormData> = (formData) => {
    const src = `data:image/svg+xml;base64,${btoa($(generateSvg(formData.equation)).find('svg')[0]?.outerHTML)}`;

    insertIntoEditor(editorInstance, (<img alt={formData.equation} src={src} />), false, false);

    close();

    setSvg(null);
    reset();
  };

  return (
    <SubmitModal
      close={close}
      headerText="Insert Math Equation"
      isOpen={isOpen}
      isValid={isValid}
      onSubmit={handleSubmit(onSubmitAsSvg)}
    >
      <p className="tw-py-2">
        For instructions on syntax, see <a href="https://teachtci.com/external-ascii-math" target="_blank">this link</a>.
      </p>
      <Input
        variant="inline"
        errors={errors}
        label="Math Equation"
        name="equation"
        placeholder="Insert equation"
        required
        {...register('equation', {
          onChange: (e) => {
            setSvg(generateSvg(e.target.value));
          },
          required: 'Required',
        })}
      />
      {svg && (
        <span className="tw-flex tw-items-center tw-justify-center">
          <img alt="equation" className="tw-py-2" src={imgSrc} />
        </span>
      )}
    </SubmitModal>
  );
};

export default EquationInsert;
