import { useState } from 'react';
import { useModalManager } from '@/components/common/Modal';
import { IImage } from '../interfaces';

const useEditImageModal = () => {
  const { isOpen, open, close } = useModalManager();
  const [image, setImage] = useState<IImage>(null);

  const openEditImageModal = (selectedImage) => {
    setImage(selectedImage);
    open();
  };

  const closeModal = () => {
    setImage(null);
    close();
  };

  return {
    openEditImageModal,
    isOpen,
    image,
    close: closeModal,
  };
};

export default useEditImageModal;
