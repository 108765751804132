import React, { useEffect } from 'react';
import Modal from '@/components/common/Modal';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import { Button } from '@/components/ui/Buttons';
import LocaleToggle from '@/components/common/LocaleToggle';
import SubmitFooter from '@/components/ui/Modal/SubmitFooter';
import PanoramicImage from './PanoramicImage';
import { AddImageModal } from './PanoramicImage/AddImage';
import usePanoramicImage from './usePanoramicImage';
import EditImageButton from './EditImageButton';
import HotspotBank from './PanoramicImage/HotspotBank';
import { useAddNewHotspot } from './panoramicImageUtils';
import usePanoramicImageStore from './PanoramicImage/usePanoramicImageStore';
import useUpdateHotspotsMutation from './useUpdateHotspotsMutation';

interface PanoramicImageUploadModalProps {
  currentValues: IPanoramicImage;
  modalManager: ModalManager;
  slideObjectId: string;
}

const PanoramicImageUploadModal = ({ modalManager, slideObjectId }: PanoramicImageUploadModalProps) => {
  const { data, isLoading, isInitialLoading, refetch } = usePanoramicImage(slideObjectId);
  const { isLoading: isMutating, mutateAsync } = useUpdateHotspotsMutation(data?.id);
  const addNewHotspot = useAddNewHotspot();

  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const setPanoramicImage = usePanoramicImageStore(state => state.setPanoramicImage);
  const isDirty = usePanoramicImageStore(state => state.isDirty);
  const setIsDirty = usePanoramicImageStore(state => state.setIsDirty);
  const locale = usePanoramicImageStore(state => state.locale)
  const setLocale = usePanoramicImageStore(state => state.setLocale)

  const handleSubmit = async () => {
    if (!isDirty) return;

    await mutateAsync(panoramicImage.hotspots);
    setIsDirty(false);
    refetch();
    modalManager.close();
  };

  useEffect(() => {
    if (isInitialLoading) return;

    setPanoramicImage(data);
  }, [isInitialLoading]);

  if (isLoading || isInitialLoading) return null;

  if (!panoramicImage) {
    return (
      <AddImageModal
        currentValues={panoramicImage || ({} as IPanoramicImage)}
        modalManager={modalManager}
        onSubmit={refetch}
        slideObjectId={slideObjectId}
      />
    );
  }

  return (
    <Modal
      closeModal={modalManager.close}
      headerText="Edit 360 Image"
      isLoading={false}
      isOpen={modalManager.isOpen}
      nearlyFullScreen
      shouldCloseOnOverlayClick={false}
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-pb-5">
          <div className="tw-flex tw-gap-4">
            <EditImageButton slideObjectId={slideObjectId} />
            <Button onClick={addNewHotspot}>Add Hotspot</Button>
          </div>
          <LocaleToggle btnClasses="tw-block" locale={locale} setLocale={setLocale} isSaving={false} />
        </div>
        <div className="tw-flex tw-gap-3 tw-grid-cols-4 tw-grow tw-overflow-y-auto">
          <div className="tw-self-center tw-h-full tw-grow">
            <PanoramicImage
              key={`PanoramicImage-${panoramicImage.updated_at}`}
              panoramicImage={panoramicImage}
              slideObjectId={slideObjectId}
            />
          </div>
          <div className="tw-pr-1 tw-overflow-y-auto tw-box-border tw-w-96 tw-h-full">
            <HotspotBank />
          </div>
        </div>
        <SubmitFooter
          close={modalManager.close}
          isLoading={isMutating}
          isValid={isDirty}
          onSubmit={handleSubmit}
          submitText="Save"
        />
      </div>
    </Modal>
  );
};

export default PanoramicImageUploadModal;
