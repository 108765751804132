import React, { useRef } from 'react';
import Drawer from '@/components/ui/Drawer';
import cn from '@/utils/cn';
import useOnClickOnKeys from '@/hooks/useOnClickOnKeys';

interface Props {
  buttonText: string | React.ReactNode;
  buttonProps?: React.ComponentPropsWithoutRef<'button'>;
  isOpen?: boolean;
  setIsOpen?: (nextState: boolean) => void;
  title: string;
  titleProps?: React.ComponentPropsWithoutRef<'h2'>;
}

type SlideBuilderDrawerProps = React.PropsWithChildren<Props>;

const SlideBuilderDrawer = ({
  buttonText,
  buttonProps = {},
  children,
  isOpen,
  setIsOpen,
  title,
  titleProps = {},
}: SlideBuilderDrawerProps) => {
  const ref = useRef<HTMLButtonElement>();

  useOnClickOnKeys(ref, ['Escape']);

  return (
    <Drawer
      modal={false}
      noBodyStyles
      onOpenChange={setIsOpen}
      open={isOpen}
      shouldScaleBackground={false}
    >
      <Drawer.Trigger
        {...buttonProps}
        className={cn(
          'tw-box-border tw-bg-white tw-border tw-border-solid tw-border-[#eaeaea] tw-rounded tw-font-tci',
          'tw-text-[#777770] tw-min-w-[120px] tw-h-[40px] tw-cursor-pointer tw-whitespace-nowrap',
          buttonProps.className,
        )}
      >
        {buttonText}
      </Drawer.Trigger>
      <Drawer.Content
        className={cn(
          'tw-inset-x-auto tw-w-[calc(100vw-220px)] tw-left-[220px]',
          'tw-box-border tw-px-2 tw-rounded-none tw-bg-white tw-h-[360px]',
        )}
      >
        <Drawer.Header className="tw-flex tw-justify-between tw-items-center tw-px-0 tw-pt-2 tw-pb-1">
          <Drawer.Title {...titleProps} className={cn('tw-text-xl tw-font-normal', titleProps.className)}>
            {title}
          </Drawer.Title>
          <Drawer.Close
            aria-label="Close dialog"
            className="tw-border-none tw-bg-inherit tw-text-2xl tw-cursor-pointer"
            ref={ref}
          >
            ✕
          </Drawer.Close>
        </Drawer.Header>
        <div className="tw-h-[90%] tw-overflow-auto">
          {children}
        </div>
      </Drawer.Content>
    </Drawer>
  );
};

export default SlideBuilderDrawer;
