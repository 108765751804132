import React from 'react';
import TCIReactTooltip from '../../../common/TCIReactTooltip';

export default function Filters(props) {
  window.filters = window.filters || { show: [], hide: [] };
  props.options.forEach(option => window.filters.show.push(option.value));

  const handleChange = (e) => {
    let $tbody = $('table.teacher-lesson-materials tbody'),
        $emptyRow = $tbody.find('tr.empty');

    if (e.target.checked) {
      window.filters.hide = window.filters.hide.filter(filter => filter !== e.target.value);
      window.filters.show.push(e.target.value);
    }
    else {
      window.filters.show = window.filters.show.filter(filter => filter !== e.target.value);
      window.filters.hide.push(e.target.value);
    }

    window.filters.show.forEach(filter => $tbody.find(`tr.${filter}`).removeClass('hide'));
    window.filters.hide.forEach(filter => $tbody.find(`tr.${filter}`).addClass('hide'));
    $tbody.find('tr:not(.hide)').length === 0 ? $emptyRow.removeClass('hide') : $emptyRow.addClass('hide');
  };

  const html = (
    <div className="filter-tooltip">
      {props.options.map(option =>
        <div className="materials-filters" key={option.value}>
          <input id={`filter-${option.label}`} className="mr5" type="checkbox" value={option.value} defaultChecked onChange={handleChange} />
          <label htmlFor={`filter-${option.label}`}>{option.label}</label>
        </div>
      )}
    </div>
  );

  return (
    <TCIReactTooltip
      arrow={false}
      distance="2"
      theme="white"
      html={html}
      trigger="click"
      placement="bottom-end"
    >
      <span className="dropdown-icon">
        <i className="fa fa-chevron-down" />
      </span>
    </TCIReactTooltip>
  );
}
