import React, { createContext, useRef } from 'react';
import createDrawingStore from './createDrawingStore';
import { DrawingProps } from '../types';

interface DrawingStoreProviderProps {
  children?: React.ReactNode;
  props: DrawingProps;
}

type DrawingStore = ReturnType<typeof createDrawingStore>;

export const DrawingContext = createContext<DrawingStore | null>(null);

const DrawingStoreProvider = ({ children, props }: DrawingStoreProviderProps) => {
  const storeRef = useRef<DrawingStore>();


  if (!storeRef.current) {
    storeRef.current = createDrawingStore(props, storeRef);
  }

  return (
    <DrawingContext.Provider value={storeRef.current}>
      {children}
    </DrawingContext.Provider>
  );
};

export default DrawingStoreProvider;
