import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Axios from 'axios';
import Footer from 'common/Wizard/Footer';

import styles from '../../Form.module.scss';
import ReactSelectAdapter from './ReactSelectAdapter';
import { usernameSources } from '../../../Utils';
import { CONFIGURATION_STEP } from '../../../steps';
import { checkConnection, footerCTA, CheckConnectionBanner } from './CheckConnection';
import { RequiredAsterisk } from 'common/Forms/Utils';
import UsernameSource from './UsernameSource';

export default class CleverForm extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      staff_username_source: PropTypes.string,
      username_source: PropTypes.string
    }).isRequired,
    autoRosterUpdatePath: PropTypes.string.isRequired,
    next: PropTypes.func,
    previous: PropTypes.func,
    subscriber: PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string
    }).isRequired,
    subscriberUpdatePath: PropTypes.string.isRequired,
    updateRosterDistrict: PropTypes.func.isRequired,
    updateSubscriber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    previous: () => {},
    next: () => {}
  };

  static getSubscriberParams(values) {
    return { subscriber: { uid: values.uid } };
  }

  static getAutoRosterDistrictParams(values) {
    return {
      auto_roster_district: {
        staff_username_source: values.staff_username_source,
        username_source: values.username_source
      }
    };
  }

  constructor(props) {
    super(props);

    this.state = { cleverConnection: '' };

    this._submitClever = this._submitClever.bind(this);
  }

  detectDistrictId(value) {
    const districtId = value.split('district_id=')[1];

    if (districtId) return districtId;

    return value;
  }

  _submitClever(values) {
    if (this.state.cleverConnection !== 'connected') {
      this.setState({ cleverConnection: 'checking' }, async () => {
        const connection = await checkConnection(this.props.subscriber.id, `uid=${values.uid}`);

        this.setState({ cleverConnection: connection });
      });

      return false;
    }

    return Promise.all([
      Axios.put(this.props.autoRosterUpdatePath, CleverForm.getAutoRosterDistrictParams(values)),
      Axios.put(this.props.subscriberUpdatePath, CleverForm.getSubscriberParams(values))
    ])
      .then((responses) => {
        if (responses[0].data.errors) {
          console.log(responses[0].data.errors);
        }
        else if (responses[1].data.errors) {
          console.log(responses[1].data.errors);
        }
        else {
          this.props.updateRosterDistrict(responses[0].data.data);
          this.props.updateSubscriber(responses[1].data.data);
          this.props.next();
        }
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <div>
        <ol>
          <li>
            Send TCI an invitation to
            {' '}
            <a
              href="https://www.teachtci.com/connect-on-clever"
              target="_blank"
              rel="noreferrer noopener"
            >
              connect in the Clever marketplace
            </a>
            .
          </li>
          <li>
            <a
              href="https://www.teachtci.com/find-clever-district-id"
              target="_blank"
              rel="noreferrer noopener"
            >
              Provide your district Clever ID
            </a>
            {' '}
            below and indicate the Clever source field to use to populate the TCI student username field.
            If you plan on using SAML or LTI for single sign on, student identities matched from TCI will be based on the student username.
          </li>
        </ol>
        <br />

        <Form
          onSubmit={this._submitClever}
          initialValues={{
            uid: this.props.subscriber.uid,
            username_source: this.props.autoRosterDistrict.username_source,
            staff_username_source: this.props.autoRosterDistrict.staff_username_source
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <div className={`${styles.fieldContainer} required`}>
                  <label htmlFor="uid" className={styles.label}>
                    Clever ID <RequiredAsterisk />
                  </label>
                  <Field
                    id="uid"
                    name="uid"
                    component="input"
                    placeholder="Enter Clever ID"
                    className={styles.textInput}
                    parse={value => this.detectDistrictId(value)}
                  />
                </div>
              </div>

              <UsernameSource
                rosteringType="clever"
                usernameSource={this.props.autoRosterDistrict.username_source}
                staffUsernameSource={this.props.autoRosterDistrict.staff_username_source}
              />

              <CheckConnectionBanner connectionState={this.state.cleverConnection} />
              <Footer
                page={CONFIGURATION_STEP}
                previous={this.props.previous}
                primaryButtonText={footerCTA(this.state.cleverConnection)}
                disabled={!values.uid || this.state.cleverConnection === 'checking'}
              />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
