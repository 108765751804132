import { useContext } from 'react';
import { useStore } from 'zustand';
import { DrawingContext } from './DrawingStoreProvider';
import { CreateDrawingStoreVariables } from './createDrawingStore';

const useDrawingContext = <T>(
  selector: (state: CreateDrawingStoreVariables) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(DrawingContext);

  return useStore(store, selector, equalityFn);
};

export default useDrawingContext;
