import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import moment from 'moment-timezone';
import { ApolloProvider, useQuery } from '@apollo/client';
import * as Routes from 'modules/routes';
import apolloClient from 'components/common/ApolloClient';
import { RequiredAsterisk } from 'components/common/Forms/Utils';
import SubmitButton from 'components/common/Forms/SubmitButton';
import WhatSection from '../WhatSection';
import WhenSection from '../WhenSection';
import styles from './LtiAssign.module.scss';
import { buildAssignmentsModels, nextJune30, GET_ASSIGNMENT_MODAL_DATA } from '../Utils';
import useAssignablesStore from '../WhatSection/store/useAssignablesStore';

type ProgramType = {
  display_investigations: boolean,
  display_investigations_text_section: boolean,
  display_unit_content: boolean,
  label: string,
  value: number,
  science: boolean
};

type LtiAssignmentsModalProps = {
  lmsProviderId?: number,
  ltiAuthenticationId?: number,
  lineItemsUrl?: string,
  programs: ProgramType[],
  stafferId: number,
};

const formChangeValue = (args, state, utils) => {
  utils.changeValue(state, args[1], () => args[0]);
};

const formHandleSelect = (args, state, utils) => {
  formChangeValue(args, state, utils);
};

const LtiAssignForm = ({ programs, stafferId,
  ltiAuthenticationId, lmsProviderId, lineItemsUrl,
}: LtiAssignmentsModalProps) => {
  const [programId, setProgramId] = useState(programs[0].value);
  const [submitting, setSubmitting] = useState(false);
  const score = useAssignablesStore(state => state.score);

  const { loading, data, error } = useQuery(
    GET_ASSIGNMENT_MODAL_DATA,
    { variables: { programId, stafferId } },
  );

  useEffect(() => {
    if (!programId) return;

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [data]);

  const onSubmit = (values) => {
    const notebookAndReadingToAssign = Object.keys(values)
      .filter(key => key.includes('notebook-reading'))
      .map(key => values[key]);

    let formattedModelsToAssign;

    if (values.type === 'sectionNotebook' && notebookAndReadingToAssign.length > 0) {
      formattedModelsToAssign = notebookAndReadingToAssign;
    } else if (Array.isArray(values.modelsToAssign)) {
      formattedModelsToAssign = [...values.modelsToAssign];
    } else {
      formattedModelsToAssign = [values.modelsToAssign];
    }

    const requestData = {
      assignment: {
        dates_enforced: false,
        due_datetime: values?.due_datetime?.value ? new Date(values?.due_datetime?.value) : nextJune30(),
        lms_type: lmsProviderId ? 'LTI' : 'schoology',
        line_items_url: lineItemsUrl,
        name: '',
        lms_provider_id: lmsProviderId,
        notes: '',
        program_id: programId,
        start_datetime: new Date(),
      },
      assignments_models: buildAssignmentsModels(formattedModelsToAssign.filter(toAssign => toAssign !== undefined).flat(), values.lesson_id),
      classroom_ids: data.staffer.classrooms.map(classroom => classroom.id),
      student_ids: [],
    };

    setSubmitting(true);

    const assignmentSelectionForm = document.getElementById('assignment_selection') as HTMLFormElement;

    return axios
      .post(Routes.plato_api_assignments_path({
        lms_provider_id: lmsProviderId,
        lti_authentication_id: ltiAuthenticationId,
        lti_return_url: assignmentSelectionForm.action,
      }), requestData)
      .then((response) => {
        const contentItemsInput = document.getElementById('content_items') as HTMLInputElement;
        const jwtInput = document.getElementById('jwt') as HTMLInputElement;

        if (contentItemsInput) {
          const assessmentName = document.getElementById('assessment_name') as HTMLInputElement;
          const contentFormValue = contentItemsInput.value;

          contentItemsInput.value = contentFormValue.replace(/:lti_link/g, response.data.data.lti_link);
          assessmentName.value = response.data.data.lti_title || 'TCI Assignment';
        } else if (jwtInput) {
          const contentFormValue = jwtInput.value;

          jwtInput.value = contentFormValue.replace(/:jwt_token/g, response.data.data.jwt_token);
        }

        assignmentSelectionForm.submit();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setSubmitting(false);
      });
  };

  const findProgram = () => {
    if (!programId) return undefined;

    return programs.find(program => program.value === programId);
  };

  const formatAssessments = () => {
    if (!data) return [];

    const { assessments } = data.staffer;
    return assessments.map(assessment => ({ ...assessment, lesson_id: assessment.lessonId }));
  };

  const assessmentsPagePath = () => {
    const lessonId = data.program.lessons[0].id;
    return Routes.staffer_lesson_assessments_path(lessonId);
  };

  const isCanvas = lmsProviderId === 1;

  const getInitialValues = () => (
    Object.assign(
      {
        assessment_ids: null,
        investigation: [],
        lesson_game: [],
        modelsToAssign: [],
        section_notebook: [],
        classroom_ids: [],
        student_ids: [],
        start_datetime: moment().utc().format('YYYY-MM-DD H:mm:ss UTC'),
        unit_projects: [],
      },
    )
  );

  const getInitialLessonId = () => {
    const lessons = data.program.lessons.slice().filter(lesson => !!lesson.position);

    if (lessons && lessons.length > 0) {
      return lessons.sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10))[0].id;
    }
    return data.program.lessons[0].id;
  };

  const hasErrors = (values) => {
    const noModelsToAssign = !(values.modelsToAssign && values.modelsToAssign.length);
    const noNotebookAndReadingToAssign = Object.keys(values)?.filter(key => key.includes('notebook-reading'))?.length === 0;
    const noDueDate : boolean = !values?.due_datetime;

    if (isCanvas) return noModelsToAssign && noNotebookAndReadingToAssign;
    return (noModelsToAssign && noNotebookAndReadingToAssign) || noDueDate;
  };

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <p className={styles.content}>
            Don't know what you're looking for?
          </p>
          <a href={Routes.home_teacher_programs_path()} target="_blank">Browse content <i className="fa fa-external-link" /></a>
        </div>
      </div>
      <Form
        onSubmit={onSubmit}
        mutators={{
          changeValue: formChangeValue,
          handleSelect: formHandleSelect,
        }}
        render={({ form, handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            aria-label="lti-assign-form"
            className={styles.form}
          >
            <h1>Select Content to Assign</h1>
            <div className={styles.row}>
              <Field
                name="program"
                render={() => (
                  <div className={`mb10 ${styles.field}`}>
                    <label htmlFor="program-id">
                      <p>Program</p>
                      <RequiredAsterisk />
                    </label>
                    <Select
                      id="program-id"
                      label="program-id"
                      aria-label="program-select"
                      isLoading={loading}
                      className={styles.select}
                      searchable
                      required
                      onChange={program => setProgramId(program.value)}
                      value={findProgram()}
                      options={programs.map(p => ({ ...p, label: p.label }))}
                    />
                  </div>
                )}
              />
            </div>

            {data && (
              <WhatSection
                assessments={formatAssessments()}
                assessmentsPagePath={assessmentsPagePath()}
                displayInvestigations={findProgram().display_investigations}
                displayInvestigationsAsText={findProgram().display_investigations_text_section}
                form={form}
                formChangeValue={form.mutators.changeValue}
                formHandleSelect={form.mutators.handleSelect}
                initialLessonId={parseInt(getInitialLessonId(), 10)}
                initialValues={getInitialValues()}
                isScienceProgram={findProgram().science}
                openAssessmentInNewTab
                programID={programId}
                score={score}
                showUnitContent={findProgram().display_unit_content}
                showWhatTitle={false}
                hasVideoActivity={data.program.hasVideoActivity}
                stafferId={stafferId}
              />
            )}

            { !isCanvas && (
              <WhenSection
                formHandleSelect={form.mutators.handleSelect}
                modelType={form.getState().values.type}
                showWhenTitle={false}
              />
            )}


            <SubmitButton
              submitDisabled={hasErrors(values)}
              submitting={submitting}
              submittingText="Submitting..."
              submitButtonText="Assign"
              buttonClassName={`btn btn--purple ${styles.submitButton}`}
            />
          </form>
        )}
      />
    </>
  );
};

const LtiAssign = ({ programs, stafferId, ltiAuthenticationId, lmsProviderId,
  lineItemsUrl,
}: LtiAssignmentsModalProps) => (
  <ApolloProvider client={apolloClient}>
    <LtiAssignForm {... { programs, stafferId, ltiAuthenticationId, lmsProviderId, lineItemsUrl }} />
  </ApolloProvider>
);

export default LtiAssign;
