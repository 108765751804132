import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './FileInput.module.scss';

export default class FileInput extends Component {
  static propTypes = {
    buttonText: PropTypes.string,
    accept: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    buttonType: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Choose File',
    buttonType: ''
  };

  constructor(props) {
    super(props);

    this.state = { file: null };

    this.clickFileInput = this.clickFileInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  clickFileInput() {
    $(`#${this.props.id}`).click();
  }

  handleChange(e) {
    this.setState({ file: e.target.files[0] });
    this.props.onChange(e);
  }

  _getFileName() {
    const text = this.state.file ? this.state.file.name : 'No file chosen';

    return (
      <div className="ml15">
        {text}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <button className="btn btn--purple" onClick={this.clickFileInput} type={this.props.buttonType}>
          {this.props.buttonText}
        </button>

        {this._getFileName()}

        <input
          type="file"
          id={this.props.id}
          name={this.props.name}
          accept={this.props.accept}
          onChange={this.handleChange}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}
