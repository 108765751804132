import React, { useState } from 'react';
import Modal, { Footer } from 'components/common/Modal';
import showToast from 'components/common/Toast';
import { Form } from 'react-final-form';
import { NumberField, CheckboxField } from 'components/common/Forms';
import Axios from 'axios';
import Pluralize from 'pluralize';
import { ScoreData } from './types';
import styles from './BulkGradingModal.module.scss';

interface BulkGradingModalProps {
  isOpen: boolean;
  closeModal: () => void;
  scoresData: ScoreData[];
}

const BulkGradingModal = ({ isOpen, closeModal, scoresData }: BulkGradingModalProps) => {
  const [saving, setSaving] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [error, setError] = useState('');
  const [hasError, setHasError] = useState(false);

  const unansweredQuestions = scoresData.filter(sd => (!sd.questionAnswered && sd.updateURL));
  const answeredQuestions = scoresData.filter(sd => (sd.questionAnswered && sd.updateURL));

  const errorStatus = (status, text) => {
    setHasError(status);
    setError(text);
  };

  const validations = async (values) => {
    if (Object.keys(values).length === 0 || (!values.answeredQuestions && !values.unansweredQuestions)) {
      errorStatus(true, 'Choose at least one option in Step 1');
      return true;
    }
    if (!values.score) {
      errorStatus(true, 'A score is required');
      return true;
    }
    if ((values.score * 10) % 1 !== 0) {
      errorStatus(true, 'Score can only have 1 digit after the decimal point');
      return true;
    }
    if (values.score > scoresData[0].maxScore || values.score < 0) {
      errorStatus(true, `Score must be a numerical value between 0 and ${scoresData[0].maxScore}`);
      return true;
    }
    setHasError(false);
    return false;
  };

  const onSubmit = async (data) => {
    const inputError = await validations(data);
    if (inputError) return;

    const questionsToGrade = [];
    if (data.answeredQuestions) {
      questionsToGrade.push(...answeredQuestions);
    }
    if (data.unansweredQuestions) {
      questionsToGrade.push(...unansweredQuestions);
    }

    let numQuestionsGraded = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, question] of questionsToGrade.entries()) {
      setSaving(true);
      const url = question.updateURL;
      const body = {
        notebook_result: { grade: data.score },
        score: data.score,
      };

      try {
        // eslint-disable-next-line no-await-in-loop
        await Axios.put(url, body).then(() => {
          if (numQuestionsGraded != null) numQuestionsGraded += 1;
          if (index === questionsToGrade.length - 1) {
            setSaving(false);
            closeModal();

            // Can be removed later if we decide to put more time into updating the page instead of reloading.
            window.location.reload();
          }
        }).catch(_e => {
          numQuestionsGraded = null;
          setErrors();
        })
      } catch (e) {
        numQuestionsGraded = null;
        setErrors();
      }
    }

    if (numQuestionsGraded) showToast(`${numQuestionsGraded} student answers have been bulk graded.`, { autoClose: 5000 });
  };

  const setErrors = () => {
    setSaving(false);
    setError('An error occurred while grading student work. Please try again.');
    setHasError(true);
  }

  const getNumQuestionsToGrade = (checked, questionsLength) => {
    setNumberOfQuestions(prevState => (checked ? prevState + questionsLength : prevState - questionsLength));
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText="Bulk Grade Student Work"
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div><strong>Step 1:</strong> Select student work to grade for this question.</div>
            <div className={styles.formGroup}>
              <div className={styles.formField}>
                <CheckboxField
                  name="answeredQuestions"
                  label="Answered"
                  labelPos="right"
                  disabled={answeredQuestions.length === 0}
                  onClick={(e) => {
                    getNumQuestionsToGrade(e.target.checked, answeredQuestions.length);
                  }}
                />
              </div>
              <div className={styles.formField}>
                <CheckboxField
                  name="unansweredQuestions"
                  label="Unanswered"
                  labelPos="right"
                  disabled={unansweredQuestions.length === 0}
                  onClick={(e) => {
                    getNumQuestionsToGrade(e.target.checked, unansweredQuestions.length);
                  }}
                />
              </div>
            </div>

            <div><strong>Step 2:</strong> Set the Score.</div>
            <div className={styles.formGroup}>
              <div className={styles.formField}>
                <NumberField
                  name="score"
                  label={`/ ${scoresData[0].maxScore}`}
                  className={styles.scoreInput}
                  labelPos="right"
                />
              </div>
            </div>

            {hasError && <div className={styles.error}>{error}</div>}

            {numberOfQuestions > 0 && (
              <span className="tw-flex tw-justify-end tw-pr-4">
                <i className="fa fa-exclamation-triangle tw-text-orange" aria-hidden="true" />
                &nbsp;{numberOfQuestions} student {Pluralize('answer', numberOfQuestions, false)} will be graded
              </span>
            )}

            <Footer
              primaryButtonText="Bulk Grade"
              primiaryButtonDisabled={hasError === true || saving === true}
              secondaryButtonCallback={closeModal}
            />
          </form>
        )}
      />
    </Modal>
  );
};

export default BulkGradingModal;
