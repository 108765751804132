import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BuilderContext from 'common/Context';
import { SubmitModal } from 'common/Modal';
import * as Routes from 'routes';

const RemoveBackgroundImageModal = ({ afterSubmit, backgroundId, questionId, spanishImagesInMicAndDd }) => {
  const { modalManager } = useContext(BuilderContext);
  const path = spanishImagesInMicAndDd ? (
    Routes.plato_api_image_model_path(backgroundId)
  ) : (
    Routes.plato_api_question_question_attachment_path(questionId, backgroundId)
  );

  return (
    <SubmitModal
      headerText="Remove Background Image"
      submitPath={path}
      method="delete"
      isOpen={modalManager.isOpen}
      closeModal={modalManager.close}
      afterSubmit={afterSubmit}
      submittingText="Removing..."
      primaryButtonText="Remove"
      primaryButtonClassName="btn btn--red"
    >
      Are you sure you want to remove the background image?
    </SubmitModal>
  );
};

RemoveBackgroundImageModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  backgroundId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  spanishImagesInMicAndDd: PropTypes.bool.isRequired,
};

export default RemoveBackgroundImageModal;
