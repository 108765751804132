import React, { useEffect, Fragment } from 'react';
import { useModalManager } from 'common/Modal';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import { capitalize } from 'common/Utils';
import { CompletedCardTable, getCardClass } from '../Utils';
import EditButton from './EditButton';
import DeleteButton from '../Delete';
import OAuthCard from './OAuthCard';
import useCredentialStore from './useCredentialStore';
import { fetchCredentials, deleteLtiKey } from './queries';

const CompletedCard = ({ subscriberId, hasCompletedLTI, userType }) => {
  const canvasCredentials = useCredentialStore(state => state.canvasCredentials);
  const setCanvasCredentials = useCredentialStore(state => state.setCanvasCredentials);
  const setLtiCredentials = useCredentialStore(state => state.setLtiCredentials);
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);
  const submitting = useCredentialStore(state => state.submitting);
  const setSubmitting = useCredentialStore(state => state.setSubmitting);
  const canvasCredentialsId = canvasCredentials?.id;
  const apiModalManager = useModalManager();
  const modalManager = useModalManager();

  useEffect(() => {
    if (modalManager.isOpen || apiModalManager.isOpen || submitting) return;

    setSubmitting(true);
    fetchCredentials(subscriberId)
      .then((response) => {
        const [canvasCreds, ltiCreds] = response;
        setCanvasCredentials(canvasCreds.data?.data);
        setLtiCredentials(ltiCreds.data?.data[0]);
        setSubmitting(false);
      });
  }, [modalManager.isOpen, apiModalManager.isOpen]);

  const anyLmsSetup = () => canvasCredentialsId || ltiCredentials?.deployment_id;

  const renderCanvasRow = () => {
    if (!canvasCredentialsId) return;

    const canvasDeletePath = Routes
      .plato_api_subscriber_subscriber_oauth_credential_path(subscriberId, canvasCredentialsId);

    return (
      [
        'Canvas API',
        'Completed',
        [<EditButton
          modalManager={apiModalManager}
          subscriberId={subscriberId}
          hasCompletedLTI={hasCompletedLTI}
          initialLmsType="canvas"
        />,
          <DeleteButton
            deletePath={canvasDeletePath}
            onDelete={() => {
              setCanvasCredentials(null);
            }}
            buttonClass="btn--sm"
            wrapperClass="ml5"
            buttonLabel={(
              <span>
                <i className="fa fa-trash-o" aria-hidden />
                {' '}
                Delete
              </span>
            )}
          />]
      ]
    );
  };

  const renderLtiRow = () => {
    if (!ltiCredentials?.deployment_id) return;

    const ltiType = ltiCredentials.lti_type.replace(/[^a-zA-Z]/g, '');

    return (
      [
        `${capitalize(ltiType)} LTI 1.3`,
        'Completed',
        [<EditButton
          modalManager={modalManager}
          subscriberId={subscriberId}
          hasCompletedLTI={hasCompletedLTI}
          initialLmsType={ltiCredentials.lti_type}
        />,
          <DeleteButton
            deletePath={Routes.plato_api_subscriber_lti_key_path(subscriberId, ltiCredentials.id)}
            handleSubmit={() => deleteLtiKey(subscriberId, ltiCredentials)}
            onDelete={() => {
              setLtiCredentials(null);
            }}
            buttonClass="btn--sm"
            wrapperClass="ml5"
            buttonLabel={(
              <span>
                <i className="fa fa-trash-o" aria-hidden />
                {' '}
                Delete
              </span>
            )}
          />]
      ]
    );
  };

  const hasCanvasApi = !!canvasCredentials?.id;
  const hasLti13 = !!ltiCredentials?.deployment_id && !!ltiCredentials?.client_id;

  const renderAuthCard = () => {
    const element = document.getElementById('newCredentials');

    if (!element) return;
    if (hasCanvasApi && hasLti13) return;

    return (
      ReactDOM
        .createPortal(
          <OAuthCard
            subscriberId={subscriberId}
            hasCompletedLTI={hasCompletedLTI}
            setCanvasCredentials={setCanvasCredentials}
            setLtiCredentials={setLtiCredentials}
            userType={userType}
          />,
          element
        )
    );
  };

  const renderCompleteTable = () => (
    <div key="completed_card" className={getCardClass(true)}>
      <CompletedCardTable
        headers={[['Method', 'Status', 'Actions']]}
        contents={[[renderCanvasRow(), renderLtiRow()].filter(row => !!row)]}
        cardHeader="Assignment and Grade Sync"
      />
    </div>
  );

  return (
    <Fragment>
      {anyLmsSetup() && renderCompleteTable()}
      {renderAuthCard()}
    </Fragment>
  );
};

CompletedCard.propTypes = {
  hasCompletedLTI: PropTypes.bool,
  subscriberId: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired
};

export default CompletedCard;
