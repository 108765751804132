import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import AddModal from './AddModal';

export default class Add extends Component {
  static propTypes = {
    allDistrictCourseCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    getDistrictCourseCodesForProgram: PropTypes.func.isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      full_title_with_edition: PropTypes.string
    })),
    subscriberId: PropTypes.number,
    updateTable: PropTypes.func.isRequired,
    updatePath: PropTypes.string.isRequired
  };

  static defaultProps = {
    programs: [],
    subscriberId: null
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <div>
        <Button handleClick={this.openModal} />

        <AddModal
          allDistrictCourseCodes={this.props.allDistrictCourseCodes}
          closeModal={this.closeModal}
          getDistrictCourseCodesForProgram={this.props.getDistrictCourseCodesForProgram}
          isOpen={this.state.modalOpen}
          programs={this.props.programs}
          subscriberId={this.props.subscriberId}
          updateTable={this.props.updateTable}
          updatePath={this.props.updatePath}
        />
      </div>
    );
  }
}
