import React from 'react';
import Select, { components, Props, LoadingIndicatorProps, DropdownIndicatorProps } from 'react-select-5';
import { SpinnerIcon } from '@/components/common/Utils';

const { DropdownIndicator } = components || {};
const CustomDropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps: { menuIsOpen } } = props;
  const iconDirection = menuIsOpen ? 'up' : 'down';

  return (
    <DropdownIndicator {...props}>
      {<i className={`fa fa-caret-${iconDirection}`} />}
    </DropdownIndicator>
  );
};

interface CustomLoadingIndicatorProps extends LoadingIndicatorProps {
  isLoading: boolean;
}

const CustomLoadingIndicator = ({ innerProps, isLoading }: CustomLoadingIndicatorProps) => {
  if (isLoading) return null;

  return <div {...innerProps}><SpinnerIcon /></div>;
};

const backgroundColor = ({ isSelected, isFocused }) => {
  if (isSelected) return '#f0f6ff';
  if (isFocused) return '#f8faff';

  return '#fff';
};

const styleOverrides = ({
  borderColorOverride = null,
  dropdownHeightOverride = null,
  menuHeightOverride = null,
  fontSizeOverride = null,
  showCaret = false,
}) => ({
  control: (styles, { menuIsOpen }) => {
    const borderColorStyle = {
      borderColor: borderColorOverride ?? 'hsl(0, 0%, 85%)',
      ...(menuIsOpen && { borderBottomColor: 'transparent' }),
    };

    return {
      ...styles,
      ...borderColorStyle,
      ...(!showCaret && { caretColor: 'transparent' }),
      boxShadow: 0,
      borderBottomRightRadius: menuIsOpen ? 0 : '4px',
      borderBottomLeftRadius: menuIsOpen ? 0 : '4px',
      ...(menuHeightOverride && { height: menuHeightOverride }),
      ...(menuHeightOverride && { minHeight: menuHeightOverride }),
      ...(fontSizeOverride && { fontSize: fontSizeOverride }),
      '&:hover': {
        ...borderColorStyle,
      },
    };
  },
  menuPortal: styles => ({
    ...styles,
    fontSize: '8px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '8px 10px',
    color: 'hsl(0, 0%, 40%)',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  menu: styles => ({
    ...styles,
    zIndex: 1500,
    marginTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  }),
  menuList: styles => ({
    ...styles,
    padding: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    ...(dropdownHeightOverride && { height: dropdownHeightOverride }),
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: backgroundColor({ isSelected, isFocused }),
      color: isDisabled ? '#ccc' : '#333',
      cursor: isDisabled ? 'default' : 'pointer',
      ...(fontSizeOverride && { fontSize: fontSizeOverride }),
    };
  },
  singleValue: styles => ({
    ...styles,
    padding: '4px 0',
  }),
});

export interface ReactSelect5Props extends Props {
  borderColorOverride?: string;
  showCaret?: boolean;
  dropdownHeightOverride?: number | string;
  fontSizeOverride?: number | string;
  menuHeightOverride?: number | string;
}

const ReactSelect5 = React.forwardRef((props: ReactSelect5Props, ref) => (
  <Select
    // @ts-ignore
    ref={ref}
    {...props}
    classNamePrefix="react-select"
    components={{
      DropdownIndicator: CustomDropdownIndicator,
      LoadingIndicator: CustomLoadingIndicator,
    }}
    styles={styleOverrides({ ...props })}
  />
));

export default ReactSelect5;
