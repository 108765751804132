import React from 'react';
import ActionsDropdownBase from '@/components/common/ActionsDropdown';
import { IImage } from './interfaces';

interface ActionsDropdownProps {
  openEditImageModal: (image: IImage) => void;
  rowData: any;
}

const dropdownButtonClass = 'dropdown-item full-size';

export const ActionsDropdown = ({ openEditImageModal, rowData }: ActionsDropdownProps) => {
  return (
    <ActionsDropdownBase>
      <button
        type="button"
        className={dropdownButtonClass}
        onClick={() => openEditImageModal(rowData)}
      >
        <i className="fa fa-edit" aria-hidden="true" /> Edit Image
      </button>
      <a
        className="dropdown-item"
        href={rowData.url}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        <i className="fa fa-download" aria-hidden="true" /> Download Image
      </a>
      <button
        type="button"
        className={`${dropdownButtonClass} js-clipboard-trigger`}
        data-clipboard-text={rowData.url}
      >
        <i className="fa fa-link" aria-hidden="true" /> Copy Image URL
      </button>
    </ActionsDropdownBase>
  );
};

export default ActionsDropdown;
