import update from 'immutability-helper';
import React from 'react';
import toolbarButtons from 'froala/ToolbarButtons';
import {
  ELEMENT_INSERT_COMMAND_NAME,
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  CUSTOM_AUDIO_COMMAND_NAME,
  EQUATION_COMMAND_NAME,
  IMAGE_EDIT_COMMAND_NAME,
} from '../../../../../shared/Froala/froalaConstants';

export const TextEditorToolbarId = 'froala-editor-toolbar';

const Toolbar = () => (
  <div
    aria-label="Text Editor Toolbar"
    role="toolbar"
    id={TextEditorToolbarId}
  />
);

const defaultExcludedToolbarButtons = [
  'inlineStyle', 'insertImage', 'insertVideo', 'insertFile', 'paragraphStyle',
  'emoticons', 'fullscreen', 'fontFamily'
];

const addButtonToToolbar = (buttons, buttonToAdd) => {
  const lastGroup = [...buttons.pop(), buttonToAdd];

  return [...buttons, lastGroup];
};

const insertButtonsBefore = (buttons, buttonsToInsert, buttonToInsertAfter) => {
  return buttons.reduce((buttonGroups, buttonGroup) => {
    const undoCommandIndex = buttonGroup.findIndex(command => command === buttonToInsertAfter);

    if (undoCommandIndex !== -1) {
      buttonGroup.splice(undoCommandIndex, 0, ...buttonsToInsert);
    }

    return update(buttonGroups, { $push: [buttonGroup] });
  }, []);
};

export const formatToolbarButtons = (userType, customExcludedButtons = []) => {
  let excludedToolbarButtons = defaultExcludedToolbarButtons;

  // remove code view button from any user that is not a Sysadmin or a ContentManager
  if (!['Sysadmin', 'ContentManager'].includes(userType)) {
    excludedToolbarButtons = [...defaultExcludedToolbarButtons, 'html'];
  }

  const buttons = toolbarButtons({ allowHTML: true, exclude: excludedToolbarButtons });
  const _toolbarButtons = addButtonToToolbar(buttons);

  const customCommands = [
    ELEMENT_INSERT_COMMAND_NAME,
    EQUATION_COMMAND_NAME,
    CUSTOM_IMAGE_COMMAND_NAME,
    CUSTOM_VIDEO_COMMAND_NAME,
    CUSTOM_AUDIO_COMMAND_NAME,
  ];

  // Don't insert custom commands that are explicitly excluded on froala initialization.
  const customCommandsToInsert = customCommands.filter(command => !customExcludedButtons.includes(command));

  return insertButtonsBefore(_toolbarButtons, customCommandsToInsert, 'undo');
};

export const getImageEditButtons = (userType) => {
  const imageEditButtons = ['imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit',
    'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageSize'];

  // Only show image edit command for Sysadmins and content managers.
  if (['Sysadmin', 'ContentManager'].includes(userType)) {
    imageEditButtons.unshift(IMAGE_EDIT_COMMAND_NAME);
  }

  return imageEditButtons;
};

export default Toolbar;
