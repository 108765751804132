import React, { useContext, useState } from 'react';
import Modal, { Footer } from 'components/common/Modal';
import BuilderContext from '@/components/common/Context';
import { Form } from 'react-final-form';
import VocabDropdown from './VocabDropdown';
import { sortedDraggableChoicesByPosition } from '../DraggableChoiceEditor/Utils';

interface LinkVocabularyModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const LinkVocabularyModal = ({ isOpen, closeModal }: LinkVocabularyModalProps) => {
  const [updatedChoices, setUpdatedChoices] = useState([]);

  const {
    correctAnswerDispatch,
    draggableChoiceDispatch,
    draggableChoices,
    dropZoneDispatch,
    textAreaDispatch,
    dropZoneShape,
  } = useContext(BuilderContext);

  const sortedDraggableChoices = sortedDraggableChoicesByPosition(draggableChoices);

  const onSubmit = async () => {
    await draggableChoiceDispatch({ payload: updatedChoices, type: 'UPDATE_VOCAB' });
    await dropZoneDispatch({ payload: updatedChoices, type: 'UPDATE_VOCAB', textAreaDispatch, shape: dropZoneShape });
    await correctAnswerDispatch({ payload: updatedChoices, type: 'UPDATE_VOCAB' });
    await textAreaDispatch({ payload: dropZoneShape, type: 'SPACE_TEXT_AREAS' });
    await dropZoneDispatch({ type: 'MATCH_DROPZONES_TO_TEXT_AREAS' });
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText="Link Vocabulary"
      nearlyFullScreen
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="tw-text-base">
            Select the terms you want to link to answer choices.
            <div className="tw-ml-2 tw-h-[87.5%]">
              {sortedDraggableChoices.map(([key, draggableChoice], index) => (
                <VocabDropdown
                  {...draggableChoice}
                  key={key}
                  id={key}
                  displayPosition={index + 1}
                  setUpdatedChoices={setUpdatedChoices}
                  selectedChoices={updatedChoices}
                />
              ))}
              <button
                type="button"
                className="btn btn--sm btn--outline-purple tw-mt-4 tw-cursor-pointer"
                onClick={() => {
                  draggableChoiceDispatch({ type: 'ADD' });
                }}
              >
                Add Choice
              </button>
            </div>
            <Footer
              secondaryButtonCallback={closeModal}
              primaryButtonText="Save"
              primaryButtonDisabled={updatedChoices.length === 0}
            />
          </form>
        )}
      />
    </Modal>
  );
};

export default LinkVocabularyModal;
