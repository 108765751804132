import React, { useState, useEffect } from 'react';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';

import CollapseIcon from 'images/icons/collapse-icon.svg';
import ExpandIcon from 'images/icons/expand-icon.svg';

import styles from './Collapse.module.scss';

interface CollapseProps {
  triggerElement?: JSX.Element;
  children?: React.ReactNode;
  shouldCollapse?: boolean;
}

const Collapse = ({
  triggerElement,
  children,
  shouldCollapse,
}: CollapseProps) => {
  const [expanded, setExpanded] = useState(!shouldCollapse);

  useEffect(() => {
    setExpanded(!shouldCollapse);
  }, [shouldCollapse]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={styles.collapseContainer}
        {...useKeydownAndClick(() => setExpanded(!expanded))}
      >
        {triggerElement}

        <div className={styles.collapseIcon}>
          <img src={expanded ? CollapseIcon : ExpandIcon} alt={expanded ? 'Collapse' : 'Expand'} />
        </div>
      </div>

      <div
        className={`${styles.collapsible} ${expanded ? '' : styles.collapsed}`}
      >
        {children}
      </div>
    </>
  );
};

export default Collapse;
