import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Footer } from 'common/Modal';
import showToast from 'common/Toast';
import styles from './ConnectStudentsButton.module.scss';
import { AuthorizationError, GenericError } from './Errors';

const connectStudentsToLmsMutation = (isGoogleIntegration) => {
  if (isGoogleIntegration) {
    return (
      gql`
        mutation ConnectStudentsToGoogle($connectionData: JSON!) {
          connectStudentsToGoogle(connectionData: $connectionData) {
            success
            message
            status
          }
        }
      `
    );
  }

  return (
    gql`
      mutation ConnectStudentsToCanvas($connectionData: JSON!, $subscriberId: ID!) {
        connectStudentsToCanvas(connectionData: $connectionData, subscriberId: $subscriberId) {
          success
        }
      }
      `
  );
};

const Confirmation = ({
  isGoogleIntegration, goBack, matchData, tciStudents, closeModal, updateClassroom, classroomId, subscriberId
}) => {
  const matchesCount = Object.values(matchData).filter(data => !!data).length;
  const unmatchedStudentsCount = tciStudents.length - matchesCount;

  const [connectStudentsToLms, { loading: submitting }] = useMutation(connectStudentsToLmsMutation(isGoogleIntegration));
  const [mutationError, setMutationError] = useState(200);
  const integrationName = () => (
    isGoogleIntegration ? 'Google' : 'Canvas'
  );

  const submitThenClose = (e) => {
    e.preventDefault();
    connectStudentsToLms({ variables: { connectionData: matchData, subscriberId: subscriberId } })
      .then((res) => {
        if (isGoogleIntegration) {
          const { success, status } = res.data.connectStudentsToGoogle;

          if (!success && status === 404) {
            setMutationError(404);
            return;
          }
        }

        closeModal();
        updateClassroom({ id: classroomId, student_lms_account_count: matchesCount });
        showToast('Student connections updated');
      })
      .catch(() => {
        setMutationError(500);
      });
  };

  if (mutationError === 500) return <GenericError />;
  if (mutationError === 404) return <AuthorizationError />;

  return (
    <form onSubmit={submitThenClose}>
      <p>
        <strong>{matchesCount} student(s)</strong> in your classroom will be matched to a {integrationName()} account.
        Please click “Match Student(s)” to complete the match or “Back” to make changes.
      </p>
      {unmatchedStudentsCount > 0 && <Warning count={unmatchedStudentsCount} integrationName={integrationName()} />}
      <Footer
        secondaryButtonCallback={goBack}
        secondaryButtonText="Back"
        primaryButtonText="Match Student(s)"
        submitting={submitting}
      />
    </form>
  );
};

Confirmation.propTypes = {
  classroomId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  isGoogleIntegration: PropTypes.bool.isRequired,
  matchData: PropTypes.shape().isRequired,
  subscriberId: PropTypes.number.isRequired,
  tciStudents: PropTypes.arrayOf(PropTypes.shape({
    fullNameWithUsername: PropTypes.string
  })).isRequired,
  updateClassroom: PropTypes.func.isRequired
};

const Warning = ({ count, integrationName }) => (
  <Fragment>
    <p className="mt20">
      <i className={`fa fa-exclamation-triangle ${styles.warning}`} />
      <strong>WARNING:</strong>
    </p>
    <p>
      <strong>{count} student(s)</strong> in your classroom have not been matched to a {integrationName} account yet.
      Are you sure you want to continue?
    </p>
  </Fragment>
);

Warning.propTypes = {
  count: PropTypes.number.isRequired,
  integrationName: PropTypes.string.isRequired
};

export default Confirmation;
