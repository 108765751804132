import create from 'zustand';
import { Viewer } from '@photo-sphere-viewer/core';
import IHotspot from '@/components/interfaces/Hotspot';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';

export type Hotspot = IHotspot & {
  element: HTMLElement | null
};

interface UsePanoramicImageStoreVariables {
  currentlyPlacing: IHotspot | null;
  isDirty: boolean;
  setCurrentlyPlacing: (marker: IHotspot) => void;
  setViewer: (viewer: Viewer) => void;
  panoramicImage: IPanoramicImage;
  setIsDirty: (isDirty: boolean) => void;
  setPanoramicImage: (panoramicImage: IPanoramicImage) => void;
  viewer: Viewer | null;
  locale: 'en' | 'es';
  setLocale: (locale: 'en' | 'es') => void;
  container: HTMLElement | null,
  setContainer: (container : HTMLElement | null) => void;
}

const usePanoramicImageStore = create<UsePanoramicImageStoreVariables>(set => ({
  currentlyPlacing: null,
  isDirty: false,
  panoramicImage: null,
  setCurrentlyPlacing: marker => set(() => ({ currentlyPlacing: marker })),
  setIsDirty: isDirty => set(() => ({ isDirty })),
  setPanoramicImage: panoramicImage => set(() => ({ panoramicImage })),
  viewer: null,
  setViewer: viewer => set(() => ({ viewer })),
  locale: 'en',
  setLocale: locale => set(() => ({ locale })),
  container: null,
  setContainer: container => set(() => ({ container })),
}));

export default usePanoramicImageStore;
