import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import Spinner from 'common/Spinner';
import styles from './Units.module.scss';
import Unit from './Unit/index';

const GET_UNITS = gql`
  query ProgramContentsPage($programId: ID) {
    program(id: $programId) {
      units {
        id
        title
        position
        storyline
        compellingQuestion
        studentTextImage
        video
        callOutText
        callOutColor
        notebooks {
          id,
          unitOpenerCloser,
          titleEn,
          sectionId,
        }
        sections {
          id,
          kind,
          lessonId,
        }
      }
    }
  }
`;

const Units = ({ programId }) => {
  const { loading, data } = useQuery(GET_UNITS, { variables: { programId } });

  if (loading) return <Spinner />;

  return (
    <div className={styles.units}>
      {
        data.program.units.map(
          unit => (
            <Unit
              key={unit.id}
              programId={programId}
              unitId={unit.id}
              notebooks={unit.notebooks}
              sections={unit.sections}
              {...unit}
            />
          )
        )
      }
    </div>
  );
};

Units.propTypes = {
  programId: PropTypes.number.isRequired
};

export default Units;
