import { useQuery } from '@tanstack/react-query';
import Axios, { AxiosResponse } from 'axios';
import * as Routes from '@/modules/routes';
import { toSnakeCase } from 'modules/TCIUtils';
import { PreviewParams } from '../types';

type Response = {
  html: string;
};

const usePreviewContent = (previewParams: PreviewParams) => (
  useQuery(['NotebookPreview', previewParams], () => (
    Axios.get(Routes.preview_shared_sections_path({ ...toSnakeCase(previewParams), format: 'json' }))
      .then((res: AxiosResponse<Response>) => res.data.html)
  ))
);

export default usePreviewContent;
