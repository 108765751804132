import React, { useContext, useEffect, useState } from 'react';
import { VisibilityContext, type publicApiType } from 'react-horizontal-scrolling-menu-7';
import { OnScreenContext } from './OnScreenContext';
import styles from './HorizontalScrollMenu.module.scss';

interface ArrowProps {
  direction: 'left' | 'right'
}

const Arrow = ({ direction }: ArrowProps) => {
  const visibility = useContext<publicApiType>(VisibilityContext);
  const adjacentItem = direction === 'left' ? 'first' : 'last';
  const defaultValue = direction === 'left';
  const isAdjacentItemVisible = visibility.useIsVisible(adjacentItem, defaultValue);

  const isOnScreen = useContext(OnScreenContext);
  const [disabled, setDisabled] = useState(isAdjacentItemVisible);
  const arrowClass = direction === 'left' ? styles.prevArrow : styles.nextArrow;

  useEffect(() => {
    if (isOnScreen) {
      setDisabled(isAdjacentItemVisible);
    }
  }, [isOnScreen, isAdjacentItemVisible]);

  return (
    <button
      type="button"
      className={`${arrowClass} tw-bg-transparent tw-border-0 tw-cursor-pointer tw-flex tw-flex-col tw-justify-center tw-mb-2 tw-opacity-1 tw-select-none`}
      disabled={disabled}
      onClick={() => (direction === 'left' ? visibility.scrollPrev() : visibility.scrollNext())}
    />
  );
};

export default Arrow;
