define([], function() {
  function slideShowGame(wl) {
    let whitelist = typeof wl !== 'undefined' ? wl : false;

    // ensures jQuery UI is ready before initializing
    fireWhenDraggableReady(initDraggable);
    fireWhenDroppableReady(initDroppable);

    let scale;
    const parentContainer = $('.slide');

    if (parentContainer && parentContainer.css('transform')) {
      scale = parentContainer.css('transform').match(/-?\d+\.?\d*/g);

      if (scale) {
        prepareOffsets(parseFloat(scale));
      }
    }

    function fireWhenDraggableReady(callback) {
      if (typeof $('.dd_answer_js').draggable === 'function') {
        callback();
      } else {
        setTimeout(() => { fireWhenDraggableReady(callback); }, 100);
      }
    }

    function initDraggable() {
      $('.dd_answer_js').draggable({
        drag: function(event, ui) {
          if (!scale) return;

          let constants = {
            height: parentContainer.innerHeight(),
            scale: parseFloat(scale),
            width: parentContainer.innerWidth(),
          };

          constants = Object.assign(constants, {
            scaledHeight: constants.height * constants.scale,
            scaledWidth: constants.width * constants.scale,
          });

          constants = Object.assign(constants, {
            dHeight: constants.height - constants.scaledHeight,
            dWidth: constants.width - constants.scaledWidth,
          });

          ui.position.top += ui.position.top * constants.dHeight / constants.scaledHeight;
          ui.position.left += ui.position.left * constants.dWidth / constants.scaledWidth;
        },
        revert: 'invalid',
        start: function () {
          if ($(this).hasClass('dd_answer_no_shadow')) {
            box_shadow = 'none';
          }
          else if ($(this).hasClass('dd_answer_img')) {
            box_shadow = 'inset 0 .05em 0 #333, 0 0.3em 0 0 #444, .8em .8em .7em #777';
          }
          else {
            box_shadow = 'inset 0 .05em 0 #008C94, 0 .25em 0 0 #014E56, .8em .8em .7em #666066';
          }
          $(this).css({
            'box-shadow': box_shadow,
            'z-index': 1000
          });
        },
        stop: function () {
          $(this).css({
            'box-shadow': '',
            'z-index': 1
          })
        }
      });
    }

    function fireWhenDroppableReady(callback) {
      if (typeof $('.dd_answer_js').draggable === 'function') {
        callback();
      } else {
        setTimeout(() => { fireWhenDroppableReady(callback); }, 100);
      }
    }

    function initDroppable() {
      if (whitelist) {
        $('.destination.dd_answer_img').droppable({
          accept: function (el) {
            if ($(this).find('.dd_answer_js.dd_answer_img').length == 0) {
              return el.hasClass('dd_answer_img');
            }
            return false;
          },
          drop: function (event, ui) {
            moveImage(ui.draggable, $(this), event, ui);
          }
        });

        $('.destination.dd_answer_text').droppable({
          accept: function (el) {
            if ($(this).find('.dd_answer_js.dd_answer_text').length == 0) {
              return el.hasClass('dd_answer_text');
            }
            return false;
          },
          drop: function (event, ui) {
            moveImage(ui.draggable, $(this), event, ui);
          }
        });

        $('.dd_origin.img_origin').droppable({
          accept: '.dd_answer_img',
          drop: function (event, ui) {
            moveImage(ui.draggable, $(this), event, ui);
          }
        });

        $('.dd_origin.text_origin').droppable({
          accept: '.dd_answer_text',
          drop: function (event, ui) {
            moveImage(ui.draggable, $(this), event, ui);
          }
        });
      } else {
        $('.destination').droppable({
          accept: function (el) {
            if ($(this).find('.dd_answer_js').length == 0 || $(this).hasClass('accept_multiple_js')) {
              return el.hasClass('dd_answer_js');
            }
            return false;
          },
          drop: function (event, ui) {
            if ($(this).parents('.current-slide').length || $(this).parents('.active_interaction').length) {
              moveImage(ui.draggable, $(this), event, ui);
            }
          },
          tolerance: 'pointer'
        });

        $('.dd_origin').droppable({
          accept: '.dd_answer_js',
          drop: function (event, ui) {
            moveImage(ui.draggable, $(this), event, ui);
          }
        });
      }
    }

    function moveImage($image, dropzone, event, ui) {
      let position = ui.position,
        css_font_size = document.getElementById('slide_window') ? document.getElementById('slide_window').style.fontSize : '14px',
        size = parseInt(css_font_size.slice(0, -2), 10);

      if ($(dropzone).hasClass('free_positioning_js')) {
        $image.css({
          'top': position.top / size + 'em',
          'left': position.left / size + 'em'
        });
      }
      else {
        $image.appendTo($(dropzone));
        $image.css({
          'top': 0,
          'left': 0
        });
      }
    }
  }

  function slideExpandables() {
    $(".expandable_answer").each(function() {
      $(this).hide();
    });

    $(".expandable_js i").click(function() {
      closest_slide = $(this).closest('.slide');

      question = $(this).parent()[0];
      question_classes = question.classList;

      for (let i = 0; i < question_classes.length; i++) {
        if (question_classes[i].indexOf("expandable_question_") > -1) {
          id = question_classes[i].split("expandable_question_")[1];
          answer_class = ".expandable_answer_" + id;

          if ($(this).hasClass('fa-plus-circle')) {
            $(this).removeClass('fa-plus-circle');
            $(this).addClass('fa-minus-circle');

            $(closest_slide).find(answer_class).show();
          } else {
            $(this).removeClass('fa-minus-circle');
            $(this).addClass('fa-plus-circle');

            $(closest_slide).find(answer_class).hide();
          }
        }
      }
    });
  }

  // Override jquery-ui prepareOffsets method to account for scaling
  function prepareOffsets(scale) {
    $.ui.ddmanager.prepareOffsets = function(t, event) {
      let i;
      let j;
      const m = $.ui.ddmanager.droppables[t.options.scope] || [];
      const type = event ? event.type : null; // workaround for #2317
      const list = (t.currentItem || t.element).find(':data(ui-droppable)').addBack();

      droppablesLoop: for (i = 0; i < m.length; i++) {
        // No disabled and non-accepted
        if (m[i].options.disabled || (t && !m[i].accept.call(m[i].element[0],
          (t.currentItem || t.element)))) {
          continue;
        }

        // Filter out elements in the current dragged item
        for (j = 0; j < list.length; j++) {
          if (list[j] === m[i].element[0]) {
            m[i].proportions().height = 0;
            continue droppablesLoop;
          }
        }

        m[i].visible = m[i].element.css('display') !== 'none';
        if (!m[i].visible) {
          continue;
        }

        // Activate the droppable if used directly from draggables
        if (type === 'mousedown') {
          m[i]._activate.call(m[i], event);
        }

        m[i].offset = m[i].element.offset();
        m[i].proportions({
          height: m[i].element[0].offsetHeight * scale,
          width: m[i].element[0].offsetWidth * scale,
        });
      }
    };
  }

  return {
    slideShowGame: slideShowGame,
    slideExpandables: slideExpandables
  };
});
