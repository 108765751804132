import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { IClassroom, IExerciseResult, ILesson } from '../../../types';

const useAssessmentExerciseResults = (classroom: IClassroom, lesson: ILesson) => {
  const isEnabled = !!(classroom?.id && lesson?.id);

  const query = useQuery([QueryKeys.AssessmentExerciseResults, classroom.id, lesson?.id], () => {
    if (!classroom.id) return [];

    const path = Routes.plato_api_assessment_results_exercise_results_path({
      classroom_id: classroom.id,
      lesson_id: lesson?.id,
      only: [
        'id', 'student_id', 'exercise_type', 'exercise_id', 'score', 'requires_clever_passback', 'lms_settings',
        'completed', 'graded_at', 'updated_since_graded',
      ],
    });

    return axios
      .get<{ data: IExerciseResult[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err));
  }, { enabled: isEnabled, staleTime: Infinity });

  return { ...query, isEnabled, data: query.data || [] };
};

export default useAssessmentExerciseResults;
