import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDragLayer } from 'react-dnd';
import DraggableChoicePreview from '../DraggableChoicePreview';
import DndAnswerContext from '@/components/common/Context';
import ItemTypes from '../ItemTypes';
import styles from './DraggableChoice/DraggableChoice.module.scss';

function getItemStyles({ mouseCoordinates, clientWidth = 0, clientHeight = 0 }) {
  const x = mouseCoordinates.x - (clientWidth / 2)
  const y = mouseCoordinates.y - (clientHeight / 2)
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = () => {
  const previewRef = useRef(null);
  const [mouseCoordinates, setMouseCoordinates] = useState({});
  const {
    isDragging,
    item,
    itemType,
  } = useDragLayer(monitor => {
    return ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
    });
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setMouseCoordinates(() => ({ x: e.clientX, y: e.clientY }))
    };
    document.addEventListener('dragover', mouseMove);
    document.addEventListener('mousedown', mouseMove);
  }, [setMouseCoordinates]);

  if (!isDragging) return null;

  const wrapperStyles = getItemStyles({
    mouseCoordinates,
    clientWidth: previewRef?.current?.clientWidth,
    clientHeight: previewRef?.current?.clientHeight,
  });

  return ReactDOM.createPortal(
    <div className={styles.customDragLayer}>
      <div
        className="tw-fixed"
        ref={previewRef}
        style={wrapperStyles}
      >
        {itemType === ItemTypes.draggableChoice &&
          <DraggableChoicePreview
            content={item.textContent}
            context={DndAnswerContext}
          />
        }
      </div>
    </div>, document.body
  );
};

export default CustomDragLayer;
