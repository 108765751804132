import React from 'react';
import PropTypes from 'prop-types';
import styles from './VirtualStamps.module.scss';
import VirtualStamp from './VirtualStamp';

const StampPicker = ({ selectStamp }) => {
  const stampSections = [
    {
      icons: ['stamp-correct-pigeon-trophy',
              'stamp-correct-alpaca-party-hat',
              'stamp-correct-checkmark',
              'stamp-correct-thumbs-up',
              'stamp-correct-excellent-work'],
      label: 'Correct'
    },
    {
      icons: ['stamp-incorrect-pigeon-x',
              'stamp-incorrect-robot-question',
              'stamp-incorrect-x',
              'stamp-incorrect-confused',
              'stamp-incorrect-incomplete'],
      label: 'Incorrect'
    },
    {
      icons: [
        'stamp-general-star',
        'stamp-general-way-to-go',
        'stamp-general-creative',
        'stamp-general-showing-growth',
        'stamp-general-lookin-sharp',
        'stamp-general-good-effort'
      ],
      label: 'General'
    }
  ];

  const renderStamps = stampIcons => (
    stampIcons.map(icon => (
      <button
        type="button"
        className={styles.stamp}
        onClick={() => selectStamp(icon)}
        key={icon}
      >
        <VirtualStamp stampName={icon} />
      </button>
    ))
  );

  const renderStampSections = () => (
    stampSections.map(stampSection => (
      <div key={stampSection.label}>
        <div className={styles.header}>{stampSection.label}</div>
        <div className={styles.stampList}>
          {renderStamps(stampSection.icons)}
        </div>
      </div>
    ))
  );

  return (
    <div className={styles.stampPicker}>
      {renderStampSections()}
    </div>
  );
};

StampPicker.propTypes = {
  selectStamp: PropTypes.func.isRequired
};

export default StampPicker;
