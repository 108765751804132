import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import FroalaJsEditor from 'froala-editor';
import { queryClient } from '@/utils/ReactQuery';
import AddImage, { IAddImageProps } from '@/components/admin/ImageManager/Add';
import { insertIntoEditor }
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/FroalaListeners';
import CustomVideoModal
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/CustomVideo/CustomVideoModal';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';
import { useModalManager } from '../../common/Modal';
import FroalaEditor from '../../common/FroalaEditor';
import apolloClient from '../../common/ApolloClient';
import TextEditorConfig from './FroalaConfig';
import { registerFroalaCommands } from './customCommands';
import EditImageModal, { EditImageModalProps } from './EditImageModal';
import ImageMarginEditor from './ImageMarginEditor';
import { extendFroalaTemplates, graphQlImageModelToTag } from './froalaUtils';
import { AvailablePlugin, EventHandlerEntry, PluginProps } from './froalaTypes';

extendFroalaTemplates();
registerFroalaCommands();

interface IAddImagePropsDerived extends Omit<IAddImageProps, 'afterSubmit'> {
  afterSubmit: (imageModel: GraphQlImageModel, editorInstance: typeof FroalaJsEditor) => any;
}

export interface FroalaProps {
  activePlugins?: AvailablePlugin[];
  className?: string;
  eventHandlers?: EventHandlerEntry[];
  customConfig?: object;
  customEvents?: object;
  modelId?: string | number;
  modelType: string;
  name: string;
  onChange?: (text: string) => any;
  pluginProps?: PluginProps;
  setEditor?: (editor: typeof FroalaJsEditor) => void;
  text: string;
  userType: UserType;
  AddImageProps?: Partial<IAddImagePropsDerived>;
  EditImageProps?: Partial<EditImageModalProps>;
}

const Froala = ({
  activePlugins = [],
  className,
  eventHandlers = [],
  customConfig = {},
  customEvents = {},
  modelId,
  modelType,
  name,
  pluginProps = {},
  setEditor: setEditorCallback,
  text,
  userType,
  onChange = () => { },
  AddImageProps = {},
  EditImageProps = {},
}: FroalaProps) => {
  const model = { id: modelId };

  const addVideoModalManager = useModalManager();
  const addImageModalManager = useModalManager();
  const editImageModalManager = useModalManager();

  const [editorInstance, setEditorInstance] = useState<typeof FroalaJsEditor>(null);
  const [currentImageModelId, setCurrentImageModelId] = useState<number>();

  const setEditor = (newEditor: typeof FroalaJsEditor) => {
    setEditorInstance(newEditor);
    if (setEditorCallback) setEditorCallback(newEditor);
  };

  const froalaConfig = TextEditorConfig({
    activePlugins,
    addVideoModalManager,
    addImageModalManager,
    editorClass: className,
    editImageModalManager,
    setCurrentImageModelId,
    setEditorInstance: setEditor,
    userType,
    useVideoCommand: !!modelId,
    useImageCommand: !!modelId,
    eventHandlers,
    customConfig,
    customEvents,
    pluginProps,
  });

  const addImageSubmitHandler = (imageModel: GraphQlImageModel) => {
    if (AddImageProps.afterSubmit) {
      AddImageProps.afterSubmit(imageModel, editorInstance);
    } else {
      insertIntoEditor(editorInstance, graphQlImageModelToTag(imageModel), true, false, true);
    }
  };

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <FroalaEditor
          className={className}
          customConfig={froalaConfig}
          model={text}
          htmlName={name}
          text={text}
          onModelChange={onChange}
        />
        <AddImage
          excludeFields={['display_size', 'zoom_type', 'alignment']}
          modelType={modelType}
          forFroala
          modalManager={addImageModalManager}
          modelId={model.id}
          updateResources={() => { }}
          standaloneButton={false}
          isAdmin
          {...AddImageProps}
          afterSubmit={addImageSubmitHandler}
        />
        {currentImageModelId && (
          <EditImageModal
            currentImageModelId={currentImageModelId}
            editorInstance={editorInstance}
            modelId={model.id}
            modelType={modelType}
            editImageModalManager={editImageModalManager}
            {...EditImageProps}
          />
        )}
        <ImageMarginEditor editorInstance={editorInstance} />
        {addVideoModalManager.isOpen && (
          <CustomVideoModal
            editorInstance={editorInstance}
            model={model}
            modalManager={addVideoModalManager}
            modelType={modelType}
          />
        )}
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default Froala;
