import React, { Component } from 'react';
import Axios from 'axios';
import SafetyNotes from '../SafetyNotes';
import { Footer, Spinner } from 'common/Modal';
import Select from 'react-select';
import { inputId, FormInput, FileInput, FormImageUploader } from '../Utils';
import FormCheckbox from 'common/Forms/FormCheckbox';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    );
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`materials-form ${this.state.isLoading ? 'loading' : ''}`}>
        <div className="row-container">
          <FormImageUploader
            onFileAdded={this.onFileAdded.bind(this)}
            onFileRemoved={this.onFileRemoved.bind(this)}
            imageUrl={this.state.imageUrl}
            newRecord={this.props.newRecord}
            id={this.props.id}
          />

          <FormInput label="name"
                     required={true}
                     id={this.props.id}
                     value={this.state.name}
                     onChange={this.handleChange.bind(this)} />
          {this.renderUnitsInput()}
          <FormCheckbox checkboxValue={this.state.coupon}
                        stateName="coupon"
                        id={this.props.id}
                        onChange={this.handleCheckboxChange.bind(this)}
                        checkLabel='Coupon' />
          <FileInput label="Safety Document"
                     className="sds"
                     id={this.props.id}
                     removeFile={this.state.removeSds}
                     file={this.state.sds}
                     fileName={this.state.sdsFileName}
                     fileUrl={this.state.sdsUrl}
                     handleChange={this.changeSds.bind(this)}
                     handleClear={this.clearSds.bind(this)} />
          <SafetyNotes safetyNotes={this.props.safetyNotes}
                       setSafetyNotes={this.setSafetyNotes.bind(this)}
                       id={inputId({ label: 'safety-notes',
                                     newRecord: this.props.newRecord,
                                     id: this.props.id })}
                       checked={this.state.safetyNoteIds} />
        </div>
        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  onFileAdded(imageUrl, imageFile) {
    this.setState({
      imageFile,
      imageUrl,
      removeImage: false
    });
  }

  onFileRemoved() {
    this.setState({
      imageFile: null,
      imageUrl: null,
      removeImage: true
    });
  }

  _buildFormData() {
    const { name, unitId, imageFile, removeImage, coupon,
            safetyNoteIds, sds, removeSds } = this.state;
    let formData = new FormData();

    if (imageFile) formData.append('material[material_image]', imageFile);
    if (removeImage) formData.append('material[delete_image]', '1');
    if (sds) formData.append('material[sds]', sds);
    if (removeSds) formData.append('material[delete_sds]', '1');

    formData.append('material[name]', name);
    formData.append('material[measurement_unit_id]', unitId || '');
    formData.append('material[coupon]', coupon);

    // send empty array to rails controller if no notes
    if (safetyNoteIds.length === 0) formData.append('material[safety_note_ids][]', '');
    else safetyNoteIds.forEach(id => formData.append('material[safety_note_ids][]', id));

    return formData;
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleCheckboxChange(val, stateName) {
    this.setState({
      [stateName]: val
    });
  }

  changeSds(e) {
    this.setState({
      sds: e.target.files[0],
      removeSds: false
    });
  }

  clearSds(e) {
    e.target.previousSibling.value = null;
    this.setState({
      sds: null,
      removeSds: true
    });
  }

  setSafetyNotes(ids) {
    this.setState({ safetyNoteIds: ids });
  }

  setUnit(e) {
    this.setState({ unitId: e ? e.value : '' });
  }

  renderUnitsInput() {
    return (
      <div className="form-row">
        <label className="mt5"
               htmlFor={inputId({ label: 'units',
                                  newRecord: this.props.newRecord,
                                  id: this.props.id })}>
          Default Unit
        </label>
        <Select searchable={true}
                required={true}
                value={this.state.unitId || ''}
                onChange={this.setUnit.bind(this)}
                options={[{ value: '', label: 'None' }].concat(this.props.units)} />
      </div>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    let method = this.props.newRecord ? 'post' : 'put',
        url = this.props.newRecord ? 'materials' : `materials/${this.props.id}`,
        callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
        data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }
};

Form.defaultProps = {
  name: '',
  unitId: '',
  sdsFileName: '',
  sdsUrl: '',
  safetyNoteIds: [],
  imageFile: null,
  safetyNotes: [],
  removeImage: false,
  isLoading: false,
  error: false,
  onUpdate: null,
  onAdd: null,
  coupon: false
};

