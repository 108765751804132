export const WRAPPER_VARIANTS = {
  default: 'tw-flex tw-flex-col tw-gap-2',
  fullwidth: 'tw-inline-block tw-items-center tw-py-2.5 tw-px-0 tw-w-[90%]',
  inline: 'tw-flex tw-items-center tw-py-2.5 tw-px-0',
};

export const LABEL_VARIANTS = {
  default: '',
  fullwidth: '',
  inline: 'tw-font-bold',
};

export const INPUT_WRAPPER_VARIANTS = {
  default: '',
  fullwidth: 'tw-flex-1',
  inline: 'tw-flex-1',
};

export const INPUT_VARIANTS = {
  default: 'tw-w-full tw-border-gray-300 tw-border tw-border-solid tw-rounded tw-p-2.5 tw-max-w-[280px] tw-box-border',
  fullwidth: 'tw-border tw-border-solid tw-border-grey tw-rounded-tl tw-rounded-bl tw-p-2.5 tw-w-full tw-text-sm tw-box-border',
  inline: 'tw-border tw-border-solid tw-border-grey tw-rounded tw-p-2.5 tw-w-full tw-text-sm tw-box-border',
  file: 'tw-rounded tw-p-2.5 tw-w-full tw-text-sm',
};
