import React, { useState } from 'react';
import { Field } from 'react-final-form';
import DPkr from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { RequiredAsterisk } from '../../../common/Forms/Utils';
import styles from '../Form.module.scss';
import { validDate } from '../../../common/Forms/Validators';

class DatePicker extends DPkr {
  deferFocusInput = () => this.cancelFocusInput()
}

const DateField = ({ ...props }) => {
  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = (date) => {
    const formattedDate = { value: moment.utc(date).format('YYYY-MM-DD H:mm:ss UTC') };

    props.handleFormChange(formattedDate, props.id);
    setSelectedDate(date);
  };

  const handleManualDateChange = (manuallyEnteredDate) => {
    const date = moment(
      manuallyEnteredDate.target.value,
      ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY']
    );

    handleDateChange(date);
  };

  const renderDatePicker = (params) => {
    const meta = params.meta;
    const hasError = !meta.pristine && meta.error;

    return (
      <div className={styles.dateInput}>
        <DatePicker
          className={`${props.inputClass} ${hasError ? styles.hasError : ''}`}
          selected={selectedDate ? moment(selectedDate).toDate() : null}
          onChange={handleDateChange}
          onChangeRaw={handleManualDateChange}
          dateFormat="MM / dd / yyyy"
          placeholderText="MM / DD / YYYY"
          popperPlacement="top"
          minDate={moment()}
          disabledKeyboardNavigation
        />
      </div>
    );
  };

  return (
    <div className={props.formRowClass}>
      <label htmlFor={props.id} className={props.labelClass}>
        {props.label}
        {props.required && <RequiredAsterisk />}
      </label>
      <Field
        id={props.id}
        name={props.id}
        render={renderDatePicker}
        validate={validDate}
        required={props.required}
      />
    </div>
  );
};

// Form mutator for handling date select
// args[0] = { value: newDate }; args[1] = field name
DateField.handleFormChange = (args, state, utils) => {
  utils.changeValue(state, args[1], () => args[0].value);
};

DateField.propTypes = {
  formRowClass: PropTypes.string,
  handleFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
  required: PropTypes.bool
};

DateField.defaultProps = {
  formRowClass: '',
  inputClass: '',
  labelClass: '',
  required: false
};

export default DateField;
