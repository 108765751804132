import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Footer from 'common/Wizard/Footer';
import { RequiredAsterisk } from 'common/Forms/Utils';
import styles from '../../Form.module.scss';
import { CONFIGURATION_STEP } from '../../../steps';
import ReactSelectAdapter from './ReactSelectAdapter';
import UsernameSource from './UsernameSource';
import { checkConnection, footerCTA, CheckConnectionBanner } from './CheckConnection';

const APP_OPTIONS = [
  {
    label: 'Classlink',
    value: 'classlink'
  },
  {
    label: 'Infinite Campus',
    value: 'infinite_campus'
  },
  {
    label: 'Aeries',
    value: 'aeries'
  },
  {
    label: 'Synergy',
    value: 'synergy'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const CONTENT_TYPE_OPTIONS = [
  {
    label: 'application/json',
    value: 'application/json'
  },
  {
    label: 'application/x-www-form-urlencoded',
    value: 'application/x-www-form-urlencoded'
  }
];

export default class OnerosterForm extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      client_id: PropTypes.string,
      client_secret: PropTypes.string,
      endpoint_url: PropTypes.string,
      roster_app: PropTypes.string,
      staff_username_source: PropTypes.string,
      token_content_type: PropTypes.string,
      token_url: PropTypes.string,
      use_oauth2: PropTypes.bool,
      username_source: PropTypes.string,
    }).isRequired,
    autoRosterDistrictPath: PropTypes.string.isRequired,
    next: PropTypes.func,
    previous: PropTypes.func,
    subscriber: PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string
    }).isRequired,
    updateRosterDistrict: PropTypes.func.isRequired,
  };

  static defaultProps = {
    next: () => {},
    previous: () => {}
  };

  constructor(props) {
    super(props);

    this.state = { oneRosterConnection: '' };

    this._submitOneRoster = this._submitOneRoster.bind(this);
  }

  _submitOneRoster(values) {
    if (this.state.oneRosterConnection !== 'connected') {
      this.setState({ oneRosterConnection: 'checking' }, async () => {
        const authStrategy = values.use_oauth2 ? 'oauth2' : 'oauth';
        let params = `client_id=${values.client_id}&client_secret=${values.client_secret}&` +
          `api_url=${values.endpoint_url}&oauth_strategy=${authStrategy}&roster_app=${values.roster_app}`;

        if (values.token_url) params += `&token_url=${values.token_url}`;
        if (values.token_content_type) params += `&token_content_type=${values.token_content_type}`;

        const connection = await checkConnection(this.props.subscriber.id, params);

        this.setState({ oneRosterConnection: connection });
      });

      return false;
    }

    return Axios
      .put(this.props.autoRosterDistrictPath, { auto_roster_district: values })
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          this.props.updateRosterDistrict(response.data.data);
          this.props.next();
        }
      })
      .catch(error => console.log(error));
  }

  _getInitialValues() {
    return {
      client_id: this.props.autoRosterDistrict.client_id,
      client_secret: this.props.autoRosterDistrict.client_secret,
      endpoint_url: this.props.autoRosterDistrict.endpoint_url,
      roster_app: this.props.autoRosterDistrict.roster_app,
      staff_username_source: this.props.autoRosterDistrict.staff_username_source,
      token_content_type: this.props.autoRosterDistrict.token_content_type,
      token_url: this.props.autoRosterDistrict.token_url,
      use_oauth2: this.props.autoRosterDistrict.use_oauth2,
      username_source: this.props.autoRosterDistrict.username_source
    };
  }

  render() {
    return (
      <div>
        <ol>
          <li>Provide the API credentials you&apos;ve created for TCI</li>
          <li>
            Indicate the OneRoster source field to use to populate the TCI student username field. If you plan on using
            SAML or LTI for single sign on, student identities matched from TCI will be based on on student username
          </li>
        </ol>
        <br />
        <p>If you are using ClassLink, please connect with TeachTCI on ClassLink to generate API credentials.</p>

        <Form
          onSubmit={this._submitOneRoster}
          initialValues={this._getInitialValues()}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="api-url" className={styles.label}>
                    URL
                    <RequiredAsterisk />
                  </label>
                  <Field
                    id="api-url"
                    name="endpoint_url"
                    component="input"
                    placeholder="Enter URL (ex: https://example.oneroster.com)"
                    className={styles.textInput}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="api-key" className={styles.label}>
                    Key
                    <RequiredAsterisk />
                  </label>
                  <Field
                    id="api-key"
                    name="client_id"
                    component="input"
                    placeholder="Enter Key"
                    className={styles.textInput}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="api-secret" className={styles.label}>
                    Secret
                    <RequiredAsterisk />
                  </label>
                  <Field
                    id="api-secret"
                    name="client_secret"
                    component="input"
                    placeholder="Enter Secret"
                    className={styles.textInput}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label htmlFor="roster_app" className={styles.label}>
                    Roster App
                    <RequiredAsterisk />
                  </label>
                  <Field
                    name="roster_app"
                    id="roster_app"
                    component={ReactSelectAdapter}
                    options={APP_OPTIONS}
                    className={styles.input}
                    placeholder="Select Roster App"
                    parse={val => (val ? val.value : val)}
                    required
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldContainer}>
                  <label>
                    <Field
                      component="input"
                      id="use-oauth2"
                      name="use_oauth2"
                      type="checkbox"
                    />
                    Use OAuth2 Authentication
                  </label>
                </div>
              </div>

              {values.use_oauth2 && (
                <>
                  <div className={styles.row}>
                    <div className={styles.fieldContainer}>
                      <label htmlFor="token-url" className={styles.label}>
                        OAuth 2 Token URL (optional)
                      </label>
                      <Field
                        id="token-url"
                        name="token_url"
                        component="input"
                        placeholder="Enter URL (ex: https://example.oneroster.com/token)"
                        className={styles.textInput}
                      />
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.fieldContainer}>
                      <label htmlFor="token_content_type" className={styles.label}>
                        Token Content Type
                        <RequiredAsterisk />
                      </label>
                      <Field
                        name="token_content_type"
                        id="token_content_type"
                        component={ReactSelectAdapter}
                        options={CONTENT_TYPE_OPTIONS}
                        className={styles.input}
                        placeholder="Select Token Content Type"
                        parse={val => (val ? val.value : val)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <UsernameSource
                rosteringType="oneroster"
                staffUsernameSource={this.props.autoRosterDistrict.staff_username_source}
                usernameSource={this.props.autoRosterDistrict.username_source}
              />


              <CheckConnectionBanner connectionState={this.state.oneRosterConnection} />
              <Footer
                page={CONFIGURATION_STEP}
                previous={this.props.previous}
                primaryButtonText={footerCTA(this.state.oneRosterConnection)}
                disabled={this.state.oneRosterConnection === 'checking'}
              />
            </form>
          )}
        </Form>
      </div>
    );
  }
}
