import React, { Component } from 'react';
import Row from './Row';
import PropTypes from 'prop-types';

export default class Table extends Component {
  static propTypes = {
    units: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    removeObject: PropTypes.func.isRequired
  };

  static defaultProps = {
    error: null,
    units: []
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <div>
        <div className="tablebox mt1em">
          <table className="tabular_data striped measurement-unit-table">
            <thead>
              <tr>
                <td>Name (click to edit)</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {this.props.units.map((unit) =>
                <Row key={unit.value}
                     id={unit.value}
                     unit={unit}
                     onUpdate={this.props.onUpdate}
                     removeObject={this.props.removeObject}
                     canDelete={unit.canDelete} />                
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};
