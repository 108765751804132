import React from 'react';
import { Tooltip } from 'react-tippy';

import '!style-loader!css-loader!react-tippy/dist/tippy.css';

export default function TCITooltip(props) {
  return (
    <Tooltip {...props}>
      {props.children}
    </Tooltip>
  );
}

TCITooltip.defaultProps = {
  position: 'top',
  multiple: false,
  distance: 10,
  interactive: true,
  hideOnClick: false,
  size: 'small',
  arrow: true,
  animateFill: false,
  theme: 'purple'
};
