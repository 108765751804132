import React, { MouseEventHandler } from 'react';

interface BulkActionOptionProps {
  adminOnly?: boolean;
  children: React.ReactNode;
  href?: string;
  icon: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const BulkActionOption = ({ adminOnly, children, href, icon, onClick }: BulkActionOptionProps) => (
  <a
    href={href}
    className="dropdown-item"
    onClick={onClick}
    role="button"
  >
    <i
      aria-hidden="true"
      className={`fa fa-${icon}`}
    />
    {children}
    {adminOnly && <i aria-hidden="true" className="fa fa-lock ml5" />}
  </a>
);

export default BulkActionOption;
