import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import * as Routes from 'routes';
import googleLogo from './ConnectClassroom/google_logo.svg';
import canvasLogo from './ConnectClassroom/canvas_logo.svg';
import schoologyLogo from 'images/icons/schoology-icon.svg';
import styles from './index.module.scss';

const LMS_CONFIG = {
  google: {
    url: 'https://www.teachtci.com/tci-google-classroom-connect',
    title: 'Google Classroom',
    shortTitle: 'Google',
    logo: googleLogo
  },
  canvas: {
    url: 'https://teachtci.com/canvas-integration',
    title: 'Canvas',
    shortTitle: 'Canvas',
    logo: canvasLogo
  },
  schoology: {
    url: 'https://help.teachtci.com/s/article/Set-up-TCI-s-Schoology-Integration',
    title: 'Schoology',
    shortTitle: 'Schoology',
    logo: schoologyLogo
  }
};

const StudentLmsInfo = ({
  classroomId, isConnectedToLti13, lmsType, programId, studentLmsAccountCount, studentSeatsCount
}) => {
  const config = LMS_CONFIG[lmsType.toLowerCase()] || {};

  const tooltipBody = () => {
    if (isConnectedToLti13) {
      return `Students get connected when they click on a TCI assignment in ${config.shortTitle}.`;
    }

    return (
      <a href={config.url} target="_blank" rel="noreferrer noopener">
        See how students can connect to {config.shortTitle}
      </a>
    );
  }

  const tooltipContent = (
    <div className={styles.connectedStudentsTooltip}>
      <p>
        <strong>{studentLmsAccountCount}/{studentSeatsCount} students</strong> connected to {' '}
        {config.title}.
      </p>
      {tooltipBody()}
    </div>
  );

  return (
    <Tooltip content={tooltipContent} theme="white">
      <a
        href={Routes.teacher_classroom_students_path(classroomId, { programId: programId })}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.studentGoogleInfoContainer}
      >
        {studentLmsAccountCount}
        <img alt="" src={config.logo} />
      </a>
    </Tooltip>
  );
};

StudentLmsInfo.propTypes = {
  classroomId: PropTypes.number.isRequired,
  isConnectedToLti13: PropTypes.bool.isRequired,
  lmsType: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  studentLmsAccountCount: PropTypes.number.isRequired,
  studentSeatsCount: PropTypes.number.isRequired
};

export default StudentLmsInfo;
