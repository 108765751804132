import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { RequiredAsterisk } from '../../../common/Forms/Utils';

export default function NumberInput(props) {
  return (
    <div className={props.formRowClass}>
      <label htmlFor={props.id} className={props.labelClass}>
        {props.label}
        {props.required && <RequiredAsterisk />}
      </label>
      <Field
        id={props.id}
        name={props.id}
        component="input"
        type="number"
        min={props.min}
        className={props.inputClass}
        required
        placeholder={props.placeholderText}
      />
    </div>
  );
}

NumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  min: PropTypes.number,
  required: PropTypes.bool,
  formRowClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string
};

NumberInput.defaultProps = {
  min: 0,
  required: false,
  formRowClass: '',
  inputClass: '',
  labelClass: '',
};
