import React from 'react';
import { FieldErrors } from 'react-hook-form';
import Label, { ILabelProps } from '@/components/ui/Form/Label';
import { twMerge } from '@/utils';
import ErrorMessage from '@/components/ui/Form/ErrorMessage';
import {
  WRAPPER_VARIANTS, LABEL_VARIANTS, INPUT_WRAPPER_VARIANTS, INPUT_VARIANTS,
} from '@/components/ui/Form/variants';

interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  containerClasses?: string;
  errors?: FieldErrors<any>;
  label?: string;
  LabelProps?: Partial<ILabelProps>;
  name: string;
  placeholder?: string;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  variant?: 'default' | 'inline' | 'file' | 'fullwidth';
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(({
  className = '',
  containerClasses,
  errors = {},
  label,
  LabelProps = {},
  name,
  placeholder = '',
  required = false,
  variant = 'default',
  ...rest
}, ref) => (
  <div className={containerClasses ?? WRAPPER_VARIANTS[variant]}>
    {label && (
      <Label className={LABEL_VARIANTS[variant]} {...{ htmlFor: name, required, ...LabelProps }}>
        {label}
      </Label>
    )}
    <div className={INPUT_WRAPPER_VARIANTS[variant]}>
      <input
        className={twMerge(INPUT_VARIANTS[variant], className)}
        id={name}
        name={name}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
      <ErrorMessage errors={errors} name={name} />
    </div>
  </div>
));

export default Input;
