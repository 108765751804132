/* istanbul ignore file */

import { isTransparent } from '@erase2d/fabric';
import { type FabricObject } from 'fabric';
import { useCallback, useEffect, useRef } from 'react';

export function useIsTransparentWorker() {
  const workerRef = useRef<Worker>();

  useEffect(() => {
    // need to get the URL working. look at worker-loader
    // const worker = new Worker(new URL('./worker.ts', import.meta.url));
    const worker = new Worker(new URL('./worker.ts'));
    workerRef.current = worker;
    return () => worker.terminate();
  }, []);

  return useCallback(
    (target: FabricObject) => isTransparent(target, workerRef.current),
    [workerRef],
  );
}
