import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_completed_status_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';

interface UseCompletedStatusArgs {
  id: number | string;
  modelType: 'HtmlElements::QuestionButton' | 'SlideObject';
}

interface ICompletedStatus {
  id: number;
  status: 'not_started' | 'in_progress' | 'completed';
  type: 'HtmlElements::QuestionButton' | 'SlideObject';
}

interface ApiResponse {
  data: ICompletedStatus;
}

const useCompletedStatus = ({ id, modelType }: UseCompletedStatusArgs) => (
  useQuery({
    enabled: !!id,
    placeholderData: ({} as ICompletedStatus),
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_completed_status_path(id, { model_type: modelType }))
        .then(res => res.data.data)
        .catch(() => ({} as ICompletedStatus))
    ),
    queryKey: [QueryKeys.CompletedStatus, id, modelType],
  })
);

export default useCompletedStatus;
