import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './Unit.module.scss';

const CompellingQuestion = ({ content }) => {
  if (!content) return null;

  return (
    <Fragment>
      <div className={styles.sectionHeader} />

      <p>{content}</p>
    </Fragment>
  );
};

CompellingQuestion.propTypes = {
  content: PropTypes.string,
};

CompellingQuestion.defaultProps = {
  content: null,
};

export default CompellingQuestion;
