import React from 'react';
import I18n from 'i18n-js';
import axios, { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import Modal from '@/components/ui/Modal';
import { useModalManager } from '@/components/common/Modal';
import IGlossaryTerm from '@/components/interfaces/GlossaryTerm';
import { plato_api_glossary_term_path } from '@/modules/routes';
import { SchemaType } from '@/components/common/VocabularyCards/variables';
import Tooltip from 'components/common/Tooltip';
import removeIcon from '../../../../../images/icons/remove.svg';

const useRemoveImageMutation = (id: number, onSuccess: (res: AxiosResponse<{ data: IGlossaryTerm }>) => void) => (
  useMutation({
    mutationFn: () => axios.put<{ data: IGlossaryTerm }>(plato_api_glossary_term_path(id),
      { glossary_term: { glossary_image: null } }),
    onSuccess,
  })
);

interface ImageDisplayProps {
  definition: string;
  id: number;
  index: number;
  s3Url: string;
}

const ImageDisplay = ({ definition, id, index, s3Url }: ImageDisplayProps) => {
  const { setValue } = useFormContext<SchemaType>();
  const { isOpen, open, close } = useModalManager();

  const { isLoading: isRemoveImageLoading, mutate: removeImageMutate } = useRemoveImageMutation(id, (res) => {
    setValue(`deck.glossary_term_models.${index}.glossary_term.s3_url`, res.data.data.s3_url);
    close();
  });

  return (
    <div
      className="tw-min-w-[80px] tw-max-w-[80px] tw-min-h-[75px] tw-border tw-relative tw-flex tw-items-center tw-justify-center tw-border-dark-grey tw-border-solid tw-bg-white">
      <img alt="Glossary Term Definition" className="tw-max-w-full" src={s3Url} />

      <button
        className={`tw-absolute -tw-top-[12.5px] -tw-right-[12.5px] tw-border-none tw-bg-transparent
                    tw-flex tw-justify-center tw-items-center tw-cursor-pointer`}
        onClick={open}
        type="button"
        disabled={!definition}
      >
        <Tooltip
          content={I18n.t('vocabulary_card_delete_tooltip')}
          shouldDisplay={!definition}
        >
          <img alt="Remove" src={removeIcon} />
        </Tooltip>
      </button>
      <Modal
        close={close}
        headerText={I18n.t('remove_item', { item: I18n.t('image') })}
        isOpen={isOpen}
      >
        <div>
          <p>{I18n.t('delete_image_message')}</p>
        </div>
        <Modal.SubmitFooter
          buttonColor="red"
          close={close}
          isLoading={isRemoveImageLoading}
          isValid
          onSubmit={removeImageMutate}
          submittingText={I18n.t('deleting')}
          submitText={`${I18n.t('yes')}, ${I18n.t('delete').toLowerCase()}`}
        />
      </Modal>
    </div>
  );
};

export default ImageDisplay;
