import React, { useState } from 'react';
import clsx from 'clsx';

interface FilterSectionProps {
  children: React.ReactNode;
  title: string;
}

const FilterSection = ({ children, title }: FilterSectionProps) => {
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(false);

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-pb-4">
        <h4 className="tw-font-bold tw-text-base">{title}</h4>
        <button
          className="tw-bg-transparent tw-border-none tw-cursor-pointer"
          onClick={() => setIsSectionCollapsed(prev => !prev)}
          type="button"
        >
          <span
            className={clsx({
              'fa fa-chevron-down': true,
              'tw-transition-all': true,
              'tw-rotate-180': !isSectionCollapsed,
            })}
          />
        </button>
      </div>
      <div
        className={clsx({
          'tw-overflow-hidden tw-transition-all': true,
          'tw-max-h-0': isSectionCollapsed,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default FilterSection;
