import React, { useState } from 'react';
import * as Routes from '@/modules/routes';
import AdminProgramsTable from './AdminProgramsTable';

interface ProgramsTableProps {
  custom?: boolean
}

const ProgramsTable = ({ custom = false }: ProgramsTableProps) => {
  const [isCustom, setIsCustom] = useState(custom);

  return (
    <>
      <ul className="tabs !-tw-mb-px">
        <li className={!isCustom ? 'selected' : ''}>
          <button type="button" onClick={() => { setIsCustom(false); }}>
            Standard Programs
          </button>
        </li>&nbsp;
        <li className={isCustom ? 'selected' : ''}>
          <button type="button" onClick={() => { setIsCustom(true); }}>
            Custom Programs
          </button>
        </li>&nbsp;
        <li>
          <a href={Routes.admin_alert_messages_path()}>Alert Messages</a>
        </li>&nbsp;
        <li>
          <a href={Routes.admin_reference_materials_path()}>Reference Materials</a>
        </li>&nbsp;
      </ul>
      <AdminProgramsTable
        custom={isCustom}
        // need to key this table to force a rerender because datatables sucks
        key={`table-${isCustom}`}
      />
    </>
  );
};

export default ProgramsTable;
