import React from 'react';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import { StudentCell } from '../Cells';
import useAssessmentColumns from './useAssessmentColumns';
import useVideoActivityColumn from './useVideoActivityColumn';
import useGamesColumns from './useGamesColumns';
import useStateCapitalsColumn from './useStateCapitalsColumn';
import useUnitProjectsColumns from './useUnitProjectsColumns';
import useLessonNotebooksColumns from './useLessonNotebooksColumns';
import useSectionNotebooksColumns from './useSectionNotebooksColumns';
import useReadingChecksColumn from './useReadingChecksColumn';
import useSlideShowColumns from './useSlideShowColumns';
import useReferenceMaterialColumns from './useReferenceMaterialColumns';

const useColumns = () => {
  const unit = useGradesTableStore(state => state.unit);

  const studentColumn = columnHelper.accessor((row: IStudentRow) => row.student, {
    id: 'student',
    // @ts-ignore
    cell: info => <StudentCell student={info.getValue()} hoveredRow={info.hoveredRow} rowId={info.row.id} />,
    header: '',
  });

  const unitColumns = [
    studentColumn,
    ...useUnitProjectsColumns(),
  ];

  const lessonColumns = [
    studentColumn,
    ...useAssessmentColumns(),
    ...useSlideShowColumns(),
    ...useLessonNotebooksColumns(),
    ...useSectionNotebooksColumns(),
    ...useVideoActivityColumn(),
    ...useReadingChecksColumn(),
    ...useGamesColumns(),
    ...useStateCapitalsColumn(),
    ...useReferenceMaterialColumns(),
  ];

  if (unit?.id) return unitColumns;

  return lessonColumns;
};

export default useColumns;
