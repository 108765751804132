import React from 'react';
import DataTable from '@/components/common/DataTable';
import Modal, { Footer } from '@/components/common/Modal';
import useDefaultProgramLicensesData from '@/components/admin/ProgramsTable/DefaultProgramLicenses/useDefaultProgramLicensesData';
import columnDefs from '@/components/admin/ProgramsTable/DefaultProgramLicenses/columnDefs';

const LicenseAvailabilityTable = (programParams: any) => {
  const { isLoading, defaultProgramLicensesData } = useDefaultProgramLicensesData(programParams);

  return (
    <DataTable
      columns={['position', 'name', 'States']}
      columnDefs={columnDefs()}
      defaultOrder={[0, 'asc']}
      handleResourcesManually
      info={false}
      paging={false}
      resources={defaultProgramLicensesData}
      sortable={false}
      isLoading={isLoading}
    />
  );
};

interface LicenseAvailabilityModalProps {
  modalManager: ModalManager;
  programParams: any;
}

const LicenseAvailabilityModal = ({ modalManager, programParams }: LicenseAvailabilityModalProps) => {
  return (
    <Modal
      closeModal={modalManager.close}
      headerText="Availability By Default Per License"
      isOpen={modalManager.isOpen}
    >
      To modify which licenses are associated with the program, edit the program.
      <LicenseAvailabilityTable programParams={programParams} />
      <Footer primaryButtonText="Save" secondaryButtonCallback={modalManager.close} />
    </Modal>
  );
};

export default LicenseAvailabilityModal;
