import React, { useState } from 'react';
import bookIcon from 'images/icons/book-open-solid.svg';
import PropTypes from 'prop-types';
import styles from './JumpToSectionButton.module.scss';

const OpenMenuButton = ({ openMenu }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleButtonClick = () => {
    setMenuOpen(!isMenuOpen);
    openMenu();
  };

  return (
    <div className={styles.openMenuButton}>
      <button
        type="button"
        className="btn btn--green"
        aria-label="Open Jump to Section menu"
        aria-expanded={isMenuOpen.toString()}
        aria-controls="jumpToSectionMenu"
        onClick={handleButtonClick}
      >
        <img className={styles.icon} src={bookIcon} alt="" /> Jump to Section
      </button>
    </div>
  );
};

OpenMenuButton.propTypes = {
  openMenu: PropTypes.func.isRequired
};

export default OpenMenuButton;
