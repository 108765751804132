import React, { useRef } from 'react';
import I18n from '@/modules/i18n';
import useThreeJs from '@/hooks/useThreeJs';
import { Texture } from '@/hooks/useThreeJs/threeJsUtils';
import { UseThreeJsOnSuccess } from '@/hooks/useThreeJs/useThreeJs';
import { Vec3 } from '@/hooks/useThreeJs/threeJsHelpers';
import Tooltip from '../Tooltip';
import HelpIcon from './HelpIcon';

interface ThreeJsProps {
  modelUrl: string;
  onSuccess: UseThreeJsOnSuccess;
  startingPosition?: Vec3;
  startingRotation?: Vec3;
  startingTarget?: Vec3;
  startingZoom?: number;
  textures: Texture[];
}

const ThreeJsContainer = ({
  modelUrl,
  onSuccess,
  startingPosition,
  startingRotation,
  startingTarget,
  startingZoom,
  textures,
}: ThreeJsProps) => {
  const ref = useRef<HTMLDivElement>();

  useThreeJs({
    modelUrl,
    onSuccess,
    ref,
    startingPosition,
    startingRotation,
    startingTarget,
    startingZoom,
    textures,
  });

  return (
    <div className="tw-absolute tw-inset-0">
      <div className="tw-relative tw-h-full tw-w-full" ref={ref} />
      <div className="tw-absolute tw-left-2 tw-bottom-2">
        <Tooltip.Provider>
          <Tooltip delayDuration={250}>
            <Tooltip.Trigger asChild className="tw-cursor-pointer">
              <div>
                <HelpIcon />
              </div>
            </Tooltip.Trigger>

            <Tooltip.Content className="tw-bg-white tw-rounded tw-shadow-xl tw-p-4 tw-text-center" side="right">
              {I18n.t('three_d_help_message')}
              <Tooltip.Arrow className="tw-fill-white" />
            </Tooltip.Content>
          </Tooltip>
        </Tooltip.Provider>
      </div>
    </div>
  );
};


export default ThreeJsContainer;
