import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_SLIDE } from 'components/admin/SlideShow/Utils/slideShowGraphQL';
import FroalaEditor from '../../../common/FroalaEditor';
import TextEditorConfig from './EditorConfig';
import showToast from '../../../common/Toast';
import useTeacherNotes from './useTeacherNotes';
import { ISlide } from '../../SlideShow/stores/useSlideShowStore';

interface TeacherNotesProps {
  slide: ISlide;
}

const TeacherNotes = ({ slide }: TeacherNotesProps) => {
  const [notes, setNotes] = useState(slide.notes || '');
  const [editorInstance, setEditorInstance] = useState(null);
  const [updateSlideNotesMutation] = useMutation(UPDATE_SLIDE);
  const froalaConfig = TextEditorConfig({ setEditorInstance });
  const typingTimeout = 800;

  const timeoutRef = useRef<NodeJS.Timeout>();

  const { data, isLoading, refetch } = useTeacherNotes(slide);

  const updateSlide = (text: string) => {
    if (data === text) return;

    const mutationParams = {
      variables: {
        id: slide.id,
        notes: text,
      },
    };

    updateSlideNotesMutation(mutationParams)
      .then(() => {
        showToast('Successfully saved notes', { position: 'top-right' });
        refetch();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast('Error saving notes', { msgType: 'error', position: 'top-right' });
      });
  };

  // Set editor content to slide notes each time we switch slides.
  useEffect(() => {
    if (!editorInstance) return;
    editorInstance.html.set(slide.notes);
    setNotes(slide.notes);
  }, [slide.id]);

  const handleModelChange = (text: string) => {
    // Skip save if editor content has not changed.
    if (text === notes) return;

    // Timeout to limit update calls until user is finished typing.
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      updateSlide(text);
      setNotes(text);
    }, typingTimeout);
  };

  if (isLoading) return null;

  return (
    <FroalaEditor
      customConfig={froalaConfig}
      model={data}
      onModelChange={handleModelChange}
      text={data}
    />
  );
};

export default TeacherNotes;
