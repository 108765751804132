import create from 'zustand';

export type CanvasCredentials = {
  created_at: string;
  id: number;
  key: string;
  secret: string;
  subscriber_id: number;
  updated_at: string;
  url: string;
};

export type LtiCredentials = {
  client_id: string;
  consumer_key: string;
  deployment_id: number | string | null;
  lms_provider_id: 1 | 3;
  id: number;
  lti_type: string;
  secret: string;
};

interface ISyncStoreVariables {
  canvasCredentials: CanvasCredentials | null;
  ltiCredentials: LtiCredentials | null;
  setCanvasCredentials: (canvasCredentials: CanvasCredentials) => void;
  setLtiCredentials: (ltiCredentials: LtiCredentials) => void;
  setStep: (step: number) => void;
  step: number | null;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
}

const useCredentialStore = create<ISyncStoreVariables>(set => ({
  canvasCredentials: null,
  ltiCredentials: null,
  setCanvasCredentials: canvasCredentials => set({ canvasCredentials }),
  setLtiCredentials: ltiCredentials => set({ ltiCredentials }),
  setStep: step => set({ step }),
  step: 1,
  setSubmitting: submitting => set({ submitting }),
  submitting: false,
}));

export default useCredentialStore;
