import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Instructions from './Instructions';
import CsvChecker from './CsvChecker';
import Errors from './Errors';
import StaffMethods from './StaffMethods';
import StudentMethods from './Student/StudentMethods';

import styles from './UploadCsvModal.module.scss';

export default class UploadCsvModal extends Component {
  static propTypes = {
    batchUpdateCsvPath: PropTypes.string,
    canCsvUpdate: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    csvTemplateUrl: PropTypes.string.isRequired,
    importCsvPath: PropTypes.string,
    importType: PropTypes.string.isRequired,
    manualCsvUploadPath: PropTypes.string,
    modalOpen: PropTypes.bool.isRequired,
    processCsvNowPath: PropTypes.string,
    programCodes: PropTypes.instanceOf(Array).isRequired,
    programsPath: PropTypes.string.isRequired,
    staffCsvUploadsPath: PropTypes.string,
    stafferId: PropTypes.number,
    studentCsvUploadsPath: PropTypes.string,
  };

  static defaultProps = {
    batchUpdateCsvPath: '',
    canCsvUpdate: true,
    importCsvPath: '',
    manualCsvUploadPath: '',
    processCsvNowPath: '',
    staffCsvUploadsPath: '',
    stafferId: null,
    studentCsvUploadsPath: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      file: null,
      isChecking: false,
      numRows: 0,
      step: 1
    };

    this.addFile = this.addFile.bind(this);
    this.triggerCsvCheck = this.triggerCsvCheck.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.returnToStep1 = this.returnToStep1.bind(this);
  }

  triggerCsvCheck() {
    this.setState({ errors: null, isChecking: true, numRows: 0 });
  }

  addFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleCheck(errors, numRows) {
    if (errors.length) this.setState({ errors });
    this.setState({ isChecking: false, numRows, step: 2 });
  }

  returnToStep1() {
    this.setState({ errors: null, file: null, step: 1 });
  }

  showConfirmation() {
    this.setState({ step: 3 });
  }

  _renderCsvChecker() {
    return (
      <CsvChecker
        file={this.state.file}
        handleCheck={this.handleCheck}
        importType={this.props.importType}
        isChecking={this.state.isChecking}
        programCodes={this.props.programCodes}
      />
    );
  }

  _renderErrors() {
    return (
      <Errors
        errors={this.state.errors}
        handleReUpload={this.returnToStep1}
        numRows={this.state.numRows}
      />
    );
  }

  isStudentImport() {
    return (this.props.importType === 'student' || this.props.importType === 'shared_classes_student');
  }

  // Total number of rows here does not include header row
  _renderMethods() {
    if (this.isStudentImport()) {
      return (
        <StudentMethods
          batchUpdateCsvPath={this.props.batchUpdateCsvPath}
          canCsvUpdate={this.props.canCsvUpdate}
          csvFile={this.state.file}
          importCsvPath={this.props.importCsvPath}
          numRows={this.state.numRows - 1}
          processCsvNowPath={this.props.processCsvNowPath}
          redirectPath={this.props.studentCsvUploadsPath}
          showConfirmation={this.showConfirmation}
          stafferId={this.props.stafferId}
          step={this.state.step}
        />
      );
    }

    if (this.props.importType === 'staff') {
      return (
        <StaffMethods
          batchUpdateCsvPath={this.props.batchUpdateCsvPath}
          canCsvUpdate={this.props.canCsvUpdate}
          closeModal={this.props.closeModal}
          csvFile={this.state.file}
          importCsvPath={this.props.importCsvPath}
          manualCsvUploadPath={this.props.manualCsvUploadPath}
          numRows={this.state.numRows - 1}
          redirectPath={this.props.staffCsvUploadsPath}
          stafferId={this.props.stafferId}
        />
      );
    }

    return null;
  }

  _renderModalContent() {
    // step 1
    if (this.state.step === 1) {
      if (this.state.isChecking) return this._renderCsvChecker();

      return (
        <Instructions
          addFile={this.addFile}
          csvTemplateUrl={this.props.csvTemplateUrl}
          file={this.state.file}
          importType={this.props.importType}
          programsPath={this.props.programsPath}
          triggerCsvCheck={this.triggerCsvCheck}
        />
      );
    }

    // step 2
    if (this.state.errors) return this._renderErrors();

    return this._renderMethods();
  }

  _renderModalHeaderText() {
    const step = this.state.step !== 3 ? ` (${this.state.step} of 2)` : '';
    return `Upload CSV${step}`;
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        bodyClassName={styles.modalBody}
        isOpen={this.props.modalOpen}
        headerText={this._renderModalHeaderText()}
      >
        {this._renderModalContent()}
      </Modal>
    );
  }
}
