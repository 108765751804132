import React from 'react';
import * as Routes from '@/modules/routes';
import useGradesTableStore from '../../store/useGradesTableStore';
import LmsSyncTime from './LmsSyncTime';
import ColorLegend from './ColorLegend';

const Legend = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const unit = useGradesTableStore(state => state.unit);
  const programId = useGradesTableStore(state => state.programId);

  return (
    <div className="tw-self-end tw-flex tw-flex-col tw-items-end tw-space-y-2">
      <LmsSyncTime classroom={classroom} />

      {!unit && (
        <a
          href={Routes.export_csv_teacher_score_path(classroom.id,
            { program_id: programId, format: 'csv' })
          }
          className="btn btn--sm btn--outline-purple tw-w-20"
        >
          Export to CSV
        </a>
      )}
      <ColorLegend />
    </div>
  );
};

export default Legend;
