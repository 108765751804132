import React from 'react';
import cn from '@/utils/cn';
import styles from './DropZone/DropZone.module.scss';

const minDimensions = { height: 5, width: 5 };

const BOUNDS_REF_HEIGHT_PERCENTAGE = 5.4;
const BOUNDS_REF_WIDTH_PERCENTAGE = 6.42;

const getStyles = (height, width) => ({
  height: `${height * BOUNDS_REF_HEIGHT_PERCENTAGE}px`,
  minHeight: `${minDimensions.height * BOUNDS_REF_HEIGHT_PERCENTAGE}px`,
  minWidth: `${minDimensions.width * BOUNDS_REF_WIDTH_PERCENTAGE}px`,
  width: `${width * BOUNDS_REF_WIDTH_PERCENTAGE}px`,
});

interface BoxDragPreviewProps {
  height: number;
  width: number;
  shape?: string;
}

const BoxDragPreview = ({ height, width, shape }: BoxDragPreviewProps) => (
  <div style={getStyles(height, width)}>
    <div className={cn(styles.highlighted, styles.previewBox, {
      [styles.circle]: shape === 'circle',
      [styles.rectangle]: shape !== 'circle',
    })}
    >
      Preview
    </div>
  </div>
);

export default BoxDragPreview;
