import create from 'zustand';

interface AssignablesStoreState {
  form: {
    getState: any,
    getValues: any
  };
  formChangeValue: ((value: any, key: string) => {}) | (() => void);
  hasUnits: boolean;
  initialValues: {
    modelType: string | null,
    modelsToAssign: [{ label: string, value: string }] | [],
    randomizeAnswers: boolean | null,
    readingAssignableSelections: [string] | [],
    slideShowSelect: string | null,
  };
  isFetching: boolean;
  isMiddleSchoolProgram: boolean;
  isScience: boolean;
  loadingLessons: boolean;
  loadingTypes: boolean;
  programId: string;
  openAssessmentInNewTab: boolean;
  refetch: () => void;
  resetState: () => void;
  score: number;
  selectedType: string;
  selectedUnitLesson: { [key: string]: string | number };
  setSelectedType: (newState: string) => void;
  setStore: (newState: object) => void;
  setUnitLesson: (selectedUnitLesson: { [key: string]: string | number }) => void;
  stafferId: number;
}

const useAssignablesStore = create<AssignablesStoreState>()(set => ({
  form: {
    getState: () => {},
    getValues: () => {},
  },
  formChangeValue: null,
  hasUnits: false,
  initialValues: { modelType: null, modelsToAssign: [], randomizeAnswers: false, readingAssignableSelections: [], slideShowSelect: null },
  isFetching: true,
  isMiddleSchoolProgram: false,
  isScience: false,
  loadingLessons: true,
  loadingTypes: true,
  openAssessmentInNewTab: false,
  programId: '',
  score: 0,
  refetch: () => {},
  resetState: () => {
    set(state => ({ ...state,
      initialValues: { modelType: null, modelsToAssign: [], randomizeAnswers: false, readingAssignableSelections: [], slideShowSelect: null },
      loadingLessons: true,
      loadingTypes: true,
      selectedType: '',
      selectedUnitLesson: { label: '', value: '' },
    }));
  },
  selectedType: '',
  selectedUnitLesson: { label: '', value: '' },
  setSelectedType: newType => set(state => ({ ...state, selectedType: newType })),
  setUnitLesson: selectedUnitLesson => set(state => ({ ...state, selectedUnitLesson })),
  setStore: newState => set(state => ({ ...state, ...newState })),
  stafferId: 0,
}));

export default useAssignablesStore;
