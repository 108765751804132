import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import useKeyboardNavigation from './useKeyboardNavigation';

const Navigation = ({ selectedSlide, setSelectedSlide }) => {
  const formattedSlides = useSlideShowContext(state => state.slides);

  const slideIds = formattedSlides.map(slide => slide.id);
  const indexOfSelectedSlide = slideIds.indexOf(selectedSlide && selectedSlide.id);

  useKeyboardNavigation({
    numElements: formattedSlides.length,
    onBack: () => setSelectedSlide(formattedSlides[indexOfSelectedSlide - 1]),
    onForward: () => setSelectedSlide(formattedSlides[indexOfSelectedSlide + 1]),
    position: selectedSlide ? selectedSlide.position : 1,
  });

  return null;
};

export default Navigation;
