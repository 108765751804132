/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const LinesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2285 7.96141C15.2285 7.96141 11.6211 11.6021 10.529 12.6837C9.44193 13.76 8.02641 12.22 9.07937 11.2341C9.61137 10.7361 11.3218 9.04877 11.3218 9.04877C11.3218 9.04877 4.37585 9.07149 1.82785 9.05021C0.525294 9.03981 0.594894 6.85277 1.81649 6.86477C4.34449 6.88989 11.2309 6.85165 11.2309 6.85165C11.2309 6.85165 9.62321 5.30845 9.09057 4.79085C8.06577 3.79469 9.65393 2.44429 10.4947 3.28461C11.6715 4.46045 15.2285 7.96141 15.2285 7.96141Z"
      fill="currentColor"
    />
  </svg>
);

export default LinesIcon;
