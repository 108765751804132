import React, { useEffect, useState } from 'react';
import Select from 'common/ReactSelect5';
import Axios from 'axios';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import * as Routes from 'routes';
import styles from './index.module.scss';
import useBrowserCache from '../BrowserCache';

const ModelSelect = ({ ...props }) => {
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { fetchCachedResponse } = useBrowserCache();

  const scrollToSelectedOption = () => {
    setTimeout(() => {
      const selectedEl = $('.react-select__option--is-selected')[0];

      if (selectedEl) {
        const scrollSpeed = 500 * (selectedEl.offsetTop / selectedEl.parentElement.scrollHeight);
        $(selectedEl.parentElement).animate({ scrollTop: selectedEl.offsetTop - 205 }, scrollSpeed);
      }
    }, 15);
  };

  const formatOptions = (optionsData, selectedOption = props.initialOption) => {
    const formattedOptions = optionsData.map((_option, idx) => {
      const option = { ..._option };

      if (selectedOption?.value === option?.value) {
        option.isDisabled = true;
        setSelectedIdx(idx);
      }
      else {
        option.isDisabled = false;
      }

      return option;
    });

    setOptions(formattedOptions);
    setLoading(false);
    scrollToSelectedOption();
  };

  const handleRedirect = (option) => {
    let newUrl;

    if (option.parentResource && props.parentResourceUrl) {
      newUrl = props.parentResourceUrl.replace('select_id', option.value);
    }
    else {
      newUrl = props.resourceUrl.replace('select_id', option.value);
    }

    return window.location.assign(newUrl);
  };

  const handleSelect = (option) => {
    if (props.handleSelect) {
      props.handleSelect(option);
      formatOptions([...options], option);
      setSelectedIdx(options.findIndex(o => o.value === option.value));
      return null;
    }

    return handleRedirect(option);
  };

  const fetchOptions = () => {
    const optionsPath = Routes.plato_api_model_select_index_path({
      disable_unit_options: props.disableUnitOptions,
      lesson_id: props.lessonId,
      locale: I18n.locale,
      model_id: props.modelId,
      model_type: props.modelType,
      notebook_id: props.notebookId,
      notebook_type: props.notebookType,
      program_id: props.programId,
      section_id: props.sectionId,
      select_type: props.selectType,
      unit_id: props.unitId,
    });

    setLoading(true);

    // Get the options out of local storage if they exist,
    // otherwise use the callback to get them from plato api
    fetchCachedResponse(props.optionsCacheKey, () => Axios.get(optionsPath))
      .then((_options) => {
        if (props.doneLoadingCallback) {
          props.doneLoadingCallback(_options, formatOptions);
        }
        else {
          formatOptions(_options);
        }
      });
  };

  const placeholder = () => {
    if (loading) return props.initialOption?.label || props.placeholder;
    if (options.length === 0) return props.noResultsText;
    if (options[selectedIdx] && options[selectedIdx].label) return options[selectedIdx].label;

    return props.initialOption?.label || props.placeholder;
  };

  useEffect(() => {
    // Prevent refetching if dependencies are uninitialized (falsey)
    if (!props.refetchDependencies.reduce((acc, dependency) => acc && dependency, true)) return;

    fetchOptions();
  }, [...props.refetchDependencies]);

  const ariaLabelId = `model-select-${Math.floor(Math.random() * 1000000000)}`;

  const label = (
    <label id={ariaLabelId} htmlFor="model-select">
      <p className="mt15">{props.title}</p>
    </label>
  )

  return (
    <div className={`${props.className} ${styles.modelSelect}`}>
      {props.showLabel && label}
      <Select
        id="model-select"
        aria-labelledby={ariaLabelId}
        aria-label={props.ariaLabel}
        className={`model-select ${styles.select}`}
        options={options}
        value={options[selectedIdx]}
        clearable={false}
        isLoading={loading}
        noResultsText={props.noResultsText}
        onChange={handleSelect}
        onOpen={scrollToSelectedOption}
        placeholder={placeholder()}
        searchable={false}
        tabSelectsValue={false}
        classNames={{
          option: (state) => {
            const className = (state.data || {}).class;
            return className ? styles[className] : '';
          }
        }}
      />
    </div>
  );
};

ModelSelect.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disableUnitOptions: PropTypes.bool,
  doneLoadingCallback: PropTypes.func,
  handleSelect: PropTypes.func,
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }),
  lessonId: PropTypes.number,
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  noResultsText: PropTypes.string,
  notebookId: PropTypes.number,
  notebookType: PropTypes.string,
  optionsCacheKey: PropTypes.string,
  parentResourceUrl: PropTypes.string,
  placeholder: PropTypes.string,
  programId: PropTypes.number.isRequired,
  refetchDependencies: PropTypes.arrayOf(PropTypes.number),
  resourceUrl: PropTypes.string,
  sectionId: PropTypes.number,
  selectType: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  unitId: PropTypes.number,
};

ModelSelect.defaultProps = {
  ariaLabel: '',
  className: '',
  disableUnitOptions: false,
  doneLoadingCallback: null,
  handleSelect: null,
  initialOption: null,
  lessonId: null,
  noResultsText: 'Loading...',
  notebookId: null,
  notebookType: null,
  optionsCacheKey: null,
  parentResourceUrl: null,
  placeholder: '',
  refetchDependencies: [],
  resourceUrl: null,
  sectionId: null,
  showLabel: true,
  unitId: null,
};

export default ModelSelect;
