import React from 'react';
import Modal from '@/components/common/Modal';
import EnableMFAForm from './EnableMFAForm';

interface EnableMFAModalProps {
  afterSubmit: (valid) => void;
  modalManager: ModalManager;
  userId: string;
}

const EnableMFAModal = ({ afterSubmit, modalManager, userId }: EnableMFAModalProps) => (
  <Modal
    bodyClassName="tw-text-base"
    headerText="Enable multi-factor authentication"
    isOpen={modalManager.isOpen}
    closeModal={modalManager.close}
  >
    <EnableMFAForm
      afterSubmit={afterSubmit}
      modalManager={modalManager}
      userId={userId}
    />
  </Modal>
);

export default EnableMFAModal;
