import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import * as Routes from 'modules/routes';
import { SelectField } from '../../../common/Forms';
import styles from '../Games.module.scss';
import useLessonGamesStore from '../../../../stores/useLessonGamesStore';
import showToast from '../../../common/Toast';

interface VocabularyGameFormProps {
  modalManager: ModalManager;
}

interface FormValues {
  gameTemplateId: number;
  glossaryTermIds: number[];
}

const VocabularyGameForm = ({ modalManager }: VocabularyGameFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { gameTemplates, glossaryTerms, lessonId, programId } = useLessonGamesStore();

  return (
    <Form
      initialValues={{
        glossaryTermIds: [],
      }}
      onSubmit={({ gameTemplateId, glossaryTermIds }: FormValues) => {
        setIsSubmitting(true);

        axios
          .post(Routes.plato_api_create_vocab_game_games_path(), {
            program_id: programId,
            game: {
              game_template_id: gameTemplateId, glossary_term_ids: glossaryTermIds, lesson_id: lessonId,
            },
          })
          .then((res) => {
            window.location.assign(Routes.edit_admin_game_path(res.data.game.id, { lesson_id: lessonId, program_id: programId }));
          })
          .catch(() => {
            showToast('Something went wrong. Please try again later.', { msgType: 'error', position: 'top-right' });
            setIsSubmitting(false);
          });
      }}
      render={({ handleSubmit, values, form: { change } }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div>
            <div className={styles.gameTemplateSelectWrapper}>
              <SelectField
                label="Type"
                name="gameTemplateId"
                options={gameTemplates.map(({ id, name }) => ({
                  label: name, value: id,
                }))}
              />
            </div>
            <p className={styles.vocabLabel}>Vocabulary Terms</p>
            <label className={styles.checkboxWrapper}>
              <input
                checked={values.glossaryTermIds.length === glossaryTerms.length}
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    change('glossaryTermIds', glossaryTerms.map(({id}) => id));
                  } else {
                    change('glossaryTermIds', []);
                  }
                }}
              />
              Select All
            </label>
            <div className={styles.checkboxesWrapper}>
              {glossaryTerms.map(({ id, sectionTitle, term }) => (
                <div key={id} className={styles.checkboxWrapper}>
                  <label className="custom-control-label">
                    <Field
                      component="input"
                      name="glossaryTermIds"
                      type="checkbox"
                      value={id}
                    />
                    {term}
                    {' '}
                    <span className={styles.sectionTitle}>({sectionTitle})</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.footer}>
            <button className="btn btn--link-purple" onClick={modalManager.close} type="button">Cancel</button>
            <button
              className="btn btn--purple"
              disabled={!values.gameTemplateId || values.glossaryTermIds.length === 0 || isSubmitting}
              type="submit"
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default VocabularyGameForm;
