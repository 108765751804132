import React, { useContext, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import BuilderContext from 'common/Context';
import checkSymbol from 'images/icons/check.svg';
import ItemTypes from '../ItemTypes';
import DraggableChoiceContainer from './DraggableChoiceEditor/Container';
import doSnapToGrid from './snapToGrid';
import TextArea from './TextArea';
import styles from './DragAndDrop.module.scss';
import './froala.scss';
import { backgroundStyle } from '../utils';
import TextAreaToolbar from './TextArea/Toolbar';
import DropZoneWithDraggableChoices from './DropZoneWithDraggableChoices';

const Container = ({ background, correctAnswers, draggableChoices, dropZones, isSaving, snapToGrid, textAreas }) => {
  const boundsRef = useRef();
  const backgroundRef = useRef();
  const { dropZoneDispatch, setBackgroundRef, questionId, textAreaDispatch } = useContext(BuilderContext);
  const minDimensions = { height: 5, width: 5 };
  const [, drop] = useDrop(() => ({
    accept: [ItemTypes.BOX, ItemTypes.textArea],
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();

      // percents of the parent params
      // Using the parent for height, slowly desyncs the item off of the grid, but
      // using the child's height value keeps it aligned
      const parentHeight = boundsRef.current.firstChild.clientHeight / 100;
      const parentWidth = boundsRef.current.clientWidth / 100;

      let left;
      let top;

      if (snapToGrid) {
        left = Math.round((item.left * parentWidth) + delta.x);
        top = Math.round((item.top * parentHeight) + delta.y);
      }
      else {
        // this is the x coordinate of the left side of the draggable
        // need to convert this to a percent by dividing it by the width of the parent element
        left = ((item.left * parentWidth) + delta.x) / parentWidth;

        // this is the y coordinate of the top side of the draggable
        // need to convert this to a percent by dividing it by the height of the parent element
        top = ((item.top * parentHeight) + delta.y) / parentHeight;
      }

      if (snapToGrid) {
        [left, top] = doSnapToGrid(left, top);
        // convert values to percentages after the snapping happens
        left /= parentWidth;
        top /= parentHeight;
      }
      if (item.type === ItemTypes.BOX) {
        dropZoneDispatch({ payload: { ...item, left, top }, type: 'UPDATE' });
      } else {
        textAreaDispatch({ payload: { ...item, left, top }, type: 'UPDATE' });
      }
      return undefined;
    },
  }), [dropZoneDispatch]);

  useEffect(() => {
    setBackgroundRef(backgroundRef);
  }, [backgroundRef]);

  const renderDropZones = () => (
    Object.keys(dropZones).sort().map(key => (
      <DropZoneWithDraggableChoices
        id={key}
        key={`DropZoneWithDraggableChoices-${key}`}
        boundsRef={boundsRef}
        correctAnswers={correctAnswers[key] || []}
        draggableChoices={draggableChoices}
        minDimensions={minDimensions}
        dropZoneProps={dropZones[key]}
      />
    ))
  );

  const renderTextAreas = () => (
    Object.keys(textAreas).sort().map(key => (
      <TextArea
        key={key}
        id={key}
        boundsRef={boundsRef}
        minDimensions={minDimensions}
        {...textAreas[key]}
      />
    ))
  );

  return (
    <div
      className={styles.dropZonesWrapper}
      ref={boundsRef}
    >
      <div className={styles.containerWrapper}>
        <section
          aria-label="Drop Zones"
          ref={drop}
          className={styles.dropZones}
        >
          <div
            className={styles.bgContainer}
            ref={backgroundRef}
            style={backgroundStyle(background)}
          >
            <div className={styles.gridDisplay} />
            <div className={styles.saveStatus}>
              {!isSaving && <img className={styles.saveSymbol} src={checkSymbol} alt="Saving" />}
              <div className={styles.saveText}>
                {isSaving ? 'Saving…' : 'Saved'}
              </div>
            </div>
            {renderDropZones()}
            {renderTextAreas()}
          </div>
        </section>
        <TextAreaToolbar questionId={questionId} />
      </div>
      <DraggableChoiceContainer draggableChoices={draggableChoices} />
    </div>
  );
};

Container.propTypes = {
  background: PropTypes.shape({
    id: PropTypes.number,
    position: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  correctAnswers: PropTypes.objectOf(PropTypes.array).isRequired,
  draggableChoices: PropTypes.objectOf(PropTypes.object).isRequired,
  dropZones: PropTypes.objectOf(PropTypes.object).isRequired,
  isSaving: PropTypes.bool.isRequired,
  snapToGrid: PropTypes.bool.isRequired,
  textAreas: PropTypes.objectOf(PropTypes.object)
};

Container.defaultProps = {
  textAreas: {}
};

export default Container;
