import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IAssessment } from '../../types';

const useAssessments = (classroomId: number, lessonId: number | undefined) => {
  const query = useQuery(['classroomAssessments', classroomId, lessonId], () => {
    const path = Routes.plato_api_gradable_by_staffer_assessments_path({
      classroom_id: classroomId,
      lesson_id: lessonId,
      extra_attributes: ['total_points_possible', 'assigned_by_staffer'],
      only: ['id', 'name', 'created_at'],
    });

    return axios
      .get<{ data: IAssessment[] }>(path)
      .then(res => res.data.data.sort((a1, a2) => a2.created_at - a1.created_at))
      .catch(err => console.error(err));
  }, { enabled: !!(classroomId && lessonId), placeholderData: [] });

  return { ...query, data: query.data || [] };
};

export default useAssessments;
