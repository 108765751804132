import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import { SubmitError } from 'common/Forms/Utils';

const ProgramIndexRowPollerWrapper = ({ programIndexId, uploadStatus, updateResource }) => (
  <ApolloProvider client={apolloClient}>
    <ProgramIndexRowPoller
      programIndexId={programIndexId}
      uploadStatus={uploadStatus}
      updateResource={updateResource}
    />
  </ApolloProvider>
);

ProgramIndexRowPollerWrapper.propTypes = {
  programIndexId: PropTypes.number.isRequired,
  updateResource: PropTypes.func.isRequired,
  uploadStatus: PropTypes.string.isRequired
};

const GET_PROGRAM_INDEX = gql`
    query ProgramIndexRowPoll($id: ID!) {
      programIndex(id: $id) {
        id,
        uploadStatus,
        locale,
        createdAt,
        processingEndTime,
        programIndexCsv {
          id,
          fileName,
          url
        }
      }
    }
  `;

const POLL_WAIT_TIME = 10000; // 10 seconds.

const ProgramIndexRowPoller = ({ programIndexId, uploadStatus, updateResource }) => {
  if (uploadStatus !== 'In Progress') return uploadStatus;

  const queryData = {
    fetchPolicy: 'network-only',
    pollInterval: POLL_WAIT_TIME,
    variables: { id: programIndexId }
  };
  const {
    loading, data, error, stopPolling
  } = useQuery(GET_PROGRAM_INDEX, queryData);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error) {
      console.log(error);
      setHasError(true);
      return;
    }

    if (loading) {
      return;
    }

    if (!data || !data.programIndex) {
      setHasError(true);
      return;
    }

    if (data.programIndex.uploadStatus !== 'In Progress') {
      stopPolling();

      updateResource(data.programIndex);
    }
  }, [data]);

  if (hasError) {
    return <SubmitError error="Something went wrong. Please refresh the page and try again." />;
  }

  return (
    <Fragment>
      <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      {' '}
      {uploadStatus}
    </Fragment>
  );
};

ProgramIndexRowPoller.propTypes = {
  programIndexId: PropTypes.number.isRequired,
  updateResource: PropTypes.func.isRequired,
  uploadStatus: PropTypes.string.isRequired
};

export default ProgramIndexRowPollerWrapper;
