import * as Routes from 'routes';
import React from 'react';

export const openAuthPopup = () => (
  new Promise((resolve, reject) => {
    window.onmessage = ({ data }) => {
      if (data.authorizationSucceeded) {
        resolve(data);
      }
      else {
        const error = new Error(data.reason);
        reject(error);
      }
    };

    window.open(Routes.authorize_canvas_path(), '', 'width=800,height=600');
  })
);

export const duplicateKeyMessage = (
  <div>
    <p className="mt5">
      The Canvas account you are attempting to connect is already connected to a different TCI account.
      Please disconnect the Canvas account or use a different Canvas account.
    </p>
    <a href="https://www.teachtci.com/canvas-connected-account" target="_blank" rel="noopener noreferrer">Learn More</a>
  </div>
);

export const incompleteSetup = (
  <div>
    <p className="mt5">
      The Canvas setup for your district is incomplete. Please try again after your district has completed setting up.
    </p>
  </div>
);
