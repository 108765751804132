interface UseSubmitDisabledProps {
  answerChoices: any[];
  isComplete?: boolean;
  isSubmitting?: boolean;
  previousAnswers?: any[];
  value: object;
}

export const useSubmitDisabled = ({ answerChoices, isComplete = false, isSubmitting = false, previousAnswers = [], value }: UseSubmitDisabledProps) => {
  if (previousAnswers.length === 2) return true;
  if (isComplete) return true;
  if (isSubmitting) return true;

  return Object.keys(value).length > 0 && JSON.stringify(answerChoices) === JSON.stringify(value);
};

interface UsePreviousAnswersProps {
  previousAnswers: any[];
}

export const usePreviousAnswers = ({ previousAnswers }: UsePreviousAnswersProps) => {
  if (previousAnswers.length === 0) return {};

  return JSON.parse(previousAnswers[previousAnswers.length - 1]);
};
