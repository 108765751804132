import React from 'react';
import i18n from '@/modules/i18n';
import styles from '@/components/common/LeftNavBarTabs/LeftNavBarTabs.module.scss';
import cn from '@/utils/cn';
import Tooltip from '@/components/ui/Tooltip';
import expandIcon from './sidebar-expand-white.svg';
import collapseIcon from './sidebar-collapse-white.svg';

const ExpandCollapseBtn = ({
  isCollapsed,
  onClick,
}) => (
  <Tooltip.Provider>
    <Tooltip>
      <Tooltip.Trigger asChild>
        <button
          aria-label={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
          tabIndex={0}
          type="button"
          className={cn(
            `tw-bg-transparent tw-border-none tw-rounded tw-w-[52px] tw-aspect-square tw-cursor-pointer tw-outline-none 
             hover:tw-bg-white hover:tw-bg-opacity-25 focus:tw-bg-white focus:tw-bg-opacity-25 focus:tw-outline-none 
             tw-text-2xl tw-h-[52px]`, {
              'tw-l-[17px] tw-mt-[13px] tw-mx-auto': isCollapsed,
              'tw-top-1 tw-right-1 tw-absolute': !isCollapsed,
            },
          )}
          onClick={onClick}
        >
          <img
            alt={isCollapsed ? 'Expand' : 'Collapse'}
            className={cn('tw-w-5', { 'tw-ml-1': isCollapsed })}
            src={isCollapsed ? expandIcon : collapseIcon}
          />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content
        className={cn('tw-z-9999 tw-bg-white tw-py-2 tw-px-6 tw-rounded', styles.tooltipShadow)}
        side="right"
        sideOffset={14}
      >
        {isCollapsed ? i18n.t('expand') : i18n.t('collapse')}
        <Tooltip.Arrow className="tw-fill-white tw-bg-transparent" />
      </Tooltip.Content>
    </Tooltip>
  </Tooltip.Provider>

);

export default ExpandCollapseBtn;
