import React from 'react';
import lightBulbIcon from 'images/icons/light-bulb.svg';
import { getModelToAssign } from '@/components/shared/LessonOverview/Cards/NotebookCard';
import { Program, SectionType } from '@/components/shared/LessonOverview/types';
import Card, { CardBody } from './Card';
import ActionBar from './ActionBar';

type NotebookCardProps = {
  fastTrack: boolean,
  lessonId: number,
  notebookName: string,
  notebookPath: string,
  program: Program,
  section: SectionType,
  stafferId?: number,
  type: string,
  unitColor: string,
};

const NotebookCard = ({
  fastTrack, lessonId, unitColor, notebookName, notebookPath, program, section, stafferId, type,
}: NotebookCardProps) => {
  const investigationSection = section.investigation;
  // Investigation Notebook tab of MS Sci classes don't have the print pdf option.
  // If notebook is from stream, if the notebook isn't fast track, and if the program is science.
  const hidePrintButton = (investigationSection && !fastTrack && program.science);

  const initialValues = {
    modelType: investigationSection ? 'investigation' : 'sectionNotebook',
    modelsToAssign: getModelToAssign(section, investigationSection, fastTrack, program),
  };

  return (
    <Card label="notebook" type={type} unitColor={unitColor}>
      <CardBody
        ariaLabel={notebookName}
        iconSrc={lightBulbIcon}
        linkHref={notebookPath}
        linkLabel={notebookName}
        type={type}
        unitColor={unitColor}
      />
      {stafferId && (
        <ActionBar
          href={notebookPath}
          initialValues={initialValues}
          isAssigned={section.isAssigned}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
          showPrintButton={!hidePrintButton}
        />
      )}
    </Card>
  );
};

export default NotebookCard;
