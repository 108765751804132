import React from 'react';
import ILesson from '@/types/api/Lessons';
import { IUnit } from '../types';
import useProgramContentStore from './useProgramContentStore';
import ContentInputs from './ContentInputs';

interface UnitInputsProps {
  unit: IUnit & {
    lessons: ILesson[];
  };
}

const UnitInputs = ({ unit }: UnitInputsProps) => {
  const { id, title_with_position: title } = unit;
  const lessonIds = unit.lessons.map(l => l.id);
  const reuseIds = useProgramContentStore(state => state.reuseUnitIds);
  const copyIds = useProgramContentStore(state => state.copyUnitIds);
  const addId = useProgramContentStore(state => state.addUnitId);
  const removeId = useProgramContentStore(state => state.removeUnitId);
  const anyLessonsChecked = useProgramContentStore(state => state.anyLessonsChecked);
  const allLessonsChecked = useProgramContentStore(state => state.allLessonsChecked);
  const addLessonId = useProgramContentStore(state => state.addLessonId);
  const removeLessonId = useProgramContentStore(state => state.removeLessonId);
  const isChecked = reuseIds.includes(id) || copyIds.includes(id);

  return (
    <ContentInputs
      id={id}
      title={title}
      reuseIds={reuseIds}
      copyIds={copyIds}
      addId={addId}
      removeId={removeId}
      showDoNotUseRadio
      isIndeterminate={isChecked || anyLessonsChecked(lessonIds)}
      isChecked={allLessonsChecked(lessonIds)}
      onCheck={() => {
        if (allLessonsChecked(lessonIds)) {
          lessonIds.forEach((lessonId) => {
            removeLessonId('copy', lessonId);
            removeLessonId('reuse', lessonId);
          });
        } else {
          lessonIds.forEach((lessonId) => {
            addLessonId('copy', lessonId);
            addLessonId('reuse', lessonId);
          });
        }
      }}
    />
  );
};

export default UnitInputs;
