import PropTypes from 'prop-types';

// Steps for Rostering
export const ROSTER_METHOD_STEP = 0;
export const CONFIGURATION_STEP = 1;
export const FINAL_STEP = 4;

// Steps for non-csv rostering
export const CONTACT_STEP_NON_CSV = 2;
export const MAP_COURSES_STEP = 3;

// Steps for csv-rostering
export const DISTRICT_CONFIG_STEP = 2;
export const CONTACT_STEP_CSV = 3;

// Steps for SSO
export const METHOD_STEP = 0;

// Steps for SSO Saml
export const SAML_CONFIGURATION_STEP_1 = 1;
export const SAML_CONFIGURATION_STEP_2 = 2;
export const SAML_PREFERENCES_STEP = 3;

// Steps for SSO LTI
export const LTI_CONFIGURATION_STEP = 1;
export const LTI_PREFERENCES_STEP = 2;

// Steps for Canvas
export const CONFIGURATION = 1;
export const INFO = 2;


// Steps for Canvas/Schoology
export const METHOD = 1;
export const CONFIG = 2;
export const TEACHER_INFO = 3;

export const completedStepsPropTypes = PropTypes.objectOf(PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
]));
