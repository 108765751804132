import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FileField } from 'common/Forms';
import { SubmitError } from 'common/Forms/Utils';
import SubmitButton from 'common/Forms/SubmitButton';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import styles from '../Button.module.scss';

const UploadCsvButtonWrapper = ({ programId, afterSubmit }) => (
  <ApolloProvider client={apolloClient}>
    <UploadCsvButton
      programId={programId}
      afterSubmit={afterSubmit}
    />
  </ApolloProvider>
);

UploadCsvButtonWrapper.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired
};

const UPLOAD_CSV = gql`
  mutation UploadProgramIndexCsv($programId: ID!, $locale: String!, $csvFile: File!) {
    uploadProgramIndexCsv(programId: $programId, locale: $locale, csvFile: $csvFile) {
      result {
        id,
        createdAt,
        locale,
        processingEndTime,
        uploadStatus,
        programIndexCsv {
          id,
          fileName,
          url
        }
      }
    }
  }
`;

const UploadCsvButton = ({ programId, afterSubmit }) => {
  const [uploadProgramIndexCsvMutation, { loading }] = useMutation(UPLOAD_CSV, { context: { hasUpload: true } });
  const [errorMessage, setErrorMessage] = useState(null);
  const csvInputRef = useRef();

  const onSubmit = (values) => {
    if (!values.csv) {
      setErrorMessage('Select a CSV file to upload');

      return;
    }

    if (!values.lang) {
      setErrorMessage('Select a language to upload');

      return;
    }

    setErrorMessage(null);

    uploadProgramIndexCsvMutation({ variables: { csvFile: values.csv, locale: values.lang, programId: parseInt(programId, 10) } })
      .then((result) => {
        if (result.data.uploadProgramIndexCsv.result) {
          afterSubmit(result.data.uploadProgramIndexCsv.result);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Something went wrong. Please refresh the page and try again.');
      });
  };

  const clearInput = (form) => {
    csvInputRef.current.value = '';
    form.change('csv', '');
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.inputContainer}>
              <FileField
                name="csv"
                label="Index CSV"
                accept="csv/*"
                setRef={csvInputRef}
              />
              {values.csv && (
                <button
                  type="button"
                  aria-label="Clear CSV"
                  onClick={() => { clearInput(form); }}
                  className={styles.clearButton}
                >
                  x
                </button>
              )}
            </div>
            <div className="tw-flex tw-mb-6 tw-mt-3 tw-items-center">
              <div className="tw-font-bold tw-mr-4">Index Language</div>
              <div className="tw-flex tw-gap-2">
                <Field name="lang" type="radio" value="en">
                  {({ input }) => (
                    <div>
                      <label className="tw-flex tw-items-center tw-cursor-pointer">
                        <input
                          name={input.name}
                          type="radio"
                          value="en"
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                        English
                      </label>
                    </div>
                  )}
                </Field>
                <Field name="lang" type="radio" value="es">
                  {({ input }) => (
                    <div>
                      <label className="tw-flex tw-items-center tw-cursor-pointer">
                        <input
                          name={input.name}
                          type="radio"
                          value="es"
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                        Spanish
                      </label>
                    </div>
                  )}
                </Field>
              </div>
            </div>

            {errorMessage && <div className="mb10"><SubmitError error={errorMessage} /></div>}

            <SubmitButton
              submitButtonText="Upload CSV"
              submitting={loading}
              submittingText="Uploading CSV..."
            />
          </form>
        )}
      />
    </Fragment>
  );
};

UploadCsvButton.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired
};

export default UploadCsvButtonWrapper;
