import React from 'react';
import Spinner from 'components/common/Spinner';

interface StatusIconProps {
  loading: boolean | null;
  error: Error | null;
}

const StatusIcon = ({ loading, error }: StatusIconProps) => {
  if (loading === null) return null;
  if (loading) return <Spinner className="tw-ml-3" />;
  if (error) return <i className="fa fa-times tw-ml-3 tw-text-red" aria-hidden />;

  return <i className="fa fa-check tw-ml-3 tw-text-green" aria-hidden />;
};

export default StatusIcon;
