import React from 'react';
import PropTypes from 'prop-types';
import { ContentTextArea } from './Components/SharedInputs';

const PlusButtonInputs = ({ customImageModal, customAudioModal, customVideoModal, equationModal, handleContentTextChange, hintText }) => (
  <ContentTextArea
    customImageModal={customImageModal}
    customAudioModal={customAudioModal}
    customVideoModal={customVideoModal}
    equationModal={equationModal}
    handleContentTextChange={handleContentTextChange}
    hintText={hintText}
  />
);

PlusButtonInputs.propTypes = {
  customImageModal: PropTypes.func,
  customAudioModal: PropTypes.func,
  customVideoModal: PropTypes.func,
  equationModal: PropTypes.func,
  handleContentTextChange: PropTypes.func.isRequired,
  hintText: PropTypes.string,
};

PlusButtonInputs.defaultProps = {
  customImageModal: () => {},
  customAudioModal: () => {},
  customVideoModal: () => {},
  equationModal: () => {},
  hintText: '',
};

export default PlusButtonInputs;
