import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/common/Tooltip';
import styles from '../../Navigator.module.scss';

/*
  This component renders one of two tooltips, depending on various states.

  If `needsExplanationTooltip` was initiated to true, but then gets changed to false,
  we automatically render an explanation tooltip (showOnInit: true), then after 5 seconds,
  hide that and go back to the normal 'More' tooltip which only shows on hover.
*/
const SettingsTooltip = ({ needsExplanationTooltip }) => {
  const appendEl = useRef();
  const [initiatedWithNeedsExplanationTooltip] = useState(needsExplanationTooltip);
  const [showExplanationTooltip, setShowExplanationTooltip] = useState(false);

  useEffect(() => {
    if (needsExplanationTooltip || !initiatedWithNeedsExplanationTooltip) return;

    setShowExplanationTooltip(true);
  }, [needsExplanationTooltip]);

  const tooltipProps = () => {
    if (initiatedWithNeedsExplanationTooltip && showExplanationTooltip) {
      return {
        key: 'modify-slideshow-settings-tooltip',
        content: I18n.t('modify_slideshow_settings_tooltip'),
        hideOnClick: true,
        onHidden: () => setShowExplanationTooltip(false),
        onShow: (instance) => setTimeout(() => instance.hide(), 5000),
        showOnInit: true,
        theme: 'wide white'
      };
    }

    return {
      key: 'settings-more-tooltip',
      content: I18n.t('more')
    };
  };

  return (
    <div ref={appendEl}>
      <Tooltip
        {...tooltipProps()}
        appendTo={appendEl && appendEl.current}
        interactive={false}
      >
        <i className={`fa fa-ellipsis-v ${styles.settingsIcon}`} />
      </Tooltip>
    </div>
  );
};

SettingsTooltip.propTypes = {
  needsExplanationTooltip: PropTypes.bool.isRequired,
};

export default SettingsTooltip;
