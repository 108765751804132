import React from 'react';
import toolbarButtons from 'modules/froala/ToolbarButtons';
import styles from './TextArea.module.scss';

export const TextEditorToolbarId = 'froala-editor-toolbar';

interface ToolbarProps {
  questionId: number;
}

const Toolbar = ({ questionId }: ToolbarProps) => (
  <div
    aria-label="Text Editor Toolbar"
    role="toolbar"
    id={`${TextEditorToolbarId}-${questionId}`}
    className={styles.textAreaToolbar}
  />
);

const formatToolbarButtons = () => (
  toolbarButtons({
    allowHTML: true,
    exclude: [
      'insertImage',
      'insertVideo',
      'insertFile',
      'paragraphStyle',
      'emoticons',
      'fullscreen',
      'fontFamily',
    ],
  })
);

export { formatToolbarButtons };
export default Toolbar;
