import React, { Component } from 'react';
import styles from './ToggleImage.module.scss';
import Axios from "axios/index";
import { SortableHandle } from 'react-sortable-hoc';
import EditableLabel from 'react-inline-editing';

const DragHandle = SortableHandle(() => <span>::</span>);

export default class ImageItem extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <li className={styles['image-list-item']}>
        <div className={styles['drag-handle']}>
          <DragHandle />
        </div>
        <div className={styles['position']}>{this.props.position}</div>
        <div className={styles['title']}>
          {this.renderTitle()}
        </div>
        <div className={styles['thumb-container']} style={{ backgroundImage: 'url(' + this.props.urls.thumb + ')' }}></div>
        <div className={styles['zoomable'] }>{this.props.zoomable ? 'Zoomable' : 'Not Zoomable'}</div>
        <div className={styles['image-list-item-actions']} onClick={this.handleDelete.bind(this)}><i className='fa fa-times fa-2x'></i></div>
      </li>
    )
  }

  renderTitle() {
    return (
      <EditableLabel text={this.props.title || 'Click to add title'}
                     inputWidth='200px'
                     inputHeight='24px'
                     inputMaxLength={50}
                     onFocus={this.enterEditing.bind(this)}
                     onFocusOut={this.handleTitleEdit.bind(this)}
                     onKeyDown={e => { if (e.which === 13) e.preventDefault() }}
      />
    )
  }

  handleTitleEdit(text) {
    let method = 'put',
        url = `/admin/toggle_image_layers/${ this.props.id }`,
        data = { toggle_image_layer: { title: text }};
    this.setState({ title: text });
    Axios({ method, url, data }).then(response => {
      console.log('response', response);
      this.props.onUpdate(this.state);
      this.props.toggleEditingMode(false);
    }).catch(error => {
      console.log(error);
    });
  }

  enterEditing() {
    this.props.toggleEditingMode(true);
  }

  handleDelete() {
    Axios.delete(`/admin/toggle_image_layers/${this.props.id}`).then( () => {
      this.props.onDelete(this.state);
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }
}