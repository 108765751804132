import React from 'react';
import { IApiSlideShow } from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';

interface StatusCheckboxesProps {
  statuses: (IApiSlideShow['status'])[];
  setStatuses: React.Dispatch<React.SetStateAction<(IApiSlideShow['status'])[]>>
}

const StatusCheckboxes = ({ statuses, setStatuses }: StatusCheckboxesProps) => {
  const slideShowStates = ['Not Started', 'In Progress', 'Completed'] as const;

  return (
    <>
      {slideShowStates.map((state) => {
        const key = state.toLowerCase().split(' ').join('_');
        const isChecked = statuses.includes(key as IApiSlideShow['status']);

        return (
          <div className="tw-pb-2" key={key}>
            <div className="tw-relative tw-flex tw-items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  if (isChecked) setStatuses(statuses.filter(status => status !== key));
                  else setStatuses([...statuses, (e.target.name as IApiSlideShow['status'])]);
                }}
                name={key}
              />
              <span className="tw-max-w-[75%]">{state}</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StatusCheckboxes;
