import ITheme from '@/components/interfaces/Theme';
import { plato_api_themes_path } from '@/modules/routes';
import QueryKeys from '@/utils/QueryKeys';
import { useQuery, QueryOptions } from '@tanstack/react-query';
import axios from 'axios';

type UseThemeQueryOptions = Omit<QueryOptions<ITheme[]>, 'queryKey' | 'queryFn'>;

interface ApiResponse {
  data: ITheme[];
}

export default function useThemes(args: UseThemeQueryOptions = {}) {
  return useQuery({
    ...args,
    placeholderData: ([] as ITheme[]),
    queryKey: [QueryKeys.UseThemes],
    queryFn: () => (
      axios
        .get<ApiResponse>(plato_api_themes_path())
        .then(res => res.data.data)
    ),
  });
}
