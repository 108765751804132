import React, { useState, useEffect } from 'react';
import I18n from '@/modules/i18n';
import * as Routes from '@/modules/routes';
import Axios from 'axios';
import DashboardCard from '../../DashboardCard';
import styles from '../../index.module.scss';

interface StudentWorkCardProps {
  program: { id: number; }
}

const BadgesCard = ({ program }: StudentWorkCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [badges, setBadges] = useState<string[]>([]);

  useEffect(() => {
    const getPath = Routes.plato_api_course_badges_slide_shows_path({ program_id: program.id });

    setIsLoading(true);

    Axios
      .get<{ course_badges: string[] }>(getPath)
      .then((response) => {
        setBadges(response.data.course_badges);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const renderBadges = () => {
    if (badges.length === 0) {
      return (
        <p className={styles.cardBodyContent}>
          {I18n.t('no_badges')}
        </p>
      );
    }

    return (
      <>
        {badges.map(badge => (
          <div className={styles.badge}>
            <a href={badge} download>
              <img src={badge} alt="badge_icon" />
            </a>
          </div>
        ))}
      </>
    );
  };

  return (
    <DashboardCard
      borderColor="red"
      label={<div className="tw-bg-white">{I18n.t('my_badges')}</div>}
      link={{
        label: <div className="tw-bg-white">{I18n.t('view_professional_development')}</div>,
        url: Routes.teacher_program_professional_developments_path(program.id),
      }}
      showFooter={badges.length > 1}
      cardExpanded={false}
      isLoading={isLoading}
      bodyClassName={!badges.length && styles.cardBody}
    >
      <div className={styles.badgesContainer}>
        {renderBadges()}
      </div>
    </DashboardCard>
  );
};

export default BadgesCard;
