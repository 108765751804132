import React, { useState, useEffect } from 'react';
import { Footer, Spinner } from 'components/common/Modal';
import { gql, useMutation } from '@apollo/client';
import { ImageModelType } from './types';
import ImageModelCard from './ImageModelCard';
import styles from './ExistingImagePicker.module.scss';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';

export const COPY_IMAGE_MODEL = gql`
  mutation CopyExistingImageModel(
    $modelId: ID!,
    $modelType: String!,
    $existingImageModelId: ID!,
    $imageModelId: ID,
    $readOnly: Boolean
  ) {
    copyExistingImageModel(
      modelId: $modelId,
      modelType: $modelType,
      existingImageModelId: $existingImageModelId,
      imageModelId: $imageModelId,
      readOnly: $readOnly
    ) {
      imageModel {
        id,
        alignment,
        captionEn,
        captionEs,
        decorative,
        displaySize,
        displayStyle,
        hideCaptions,
        snippet,
        zoomType,
        image {
          id,
          credits,
          descriptionConfidence,
          descriptionEn,
          descriptionEs,
          imageUrl,
          fileName,
          resolution,
          thumbnail,
          titleEn,
          titleEs
        }
      }
    }
  }
`;

interface ExistingImagePickerPropTypes {
  afterSubmit: (result: GraphQlImageModel) => void;
  closeModal: () => void;
  fromButton?: boolean;
  getExistingImages: () => Promise<ImageModelType[]>;
  imageModelId?: number;
  modelId: string | number;
  modelType: string;
}

const ExistingImagePicker = ({
  afterSubmit,
  closeModal,
  fromButton,
  getExistingImages,
  imageModelId,
  modelId,
  modelType,
}: ExistingImagePickerPropTypes) => {
  const [copyExistingImageModelMutation, { loading: submitting }] = useMutation(COPY_IMAGE_MODEL);
  const [imageModels, setImageModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [selectedImageModelId, setSelectedImageModelId] = useState(0);

  useEffect(() => {
    getExistingImages()
      .then((data: ImageModelType[]) => setImageModels(data))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  const submit = () => {
    const variables = {
      modelId,
      modelType,
      imageModelId,
      existingImageModelId: selectedImageModelId,
      readOnly: fromButton,
    };

    copyExistingImageModelMutation({ variables })
      .then((result) => {
        const { imageModel } = result.data.copyExistingImageModel;

        afterSubmit(imageModel);
        closeModal();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(e);
      });
  };

  if (loading) return <Spinner isLoading />;
  if (error) return <p>Something went wrong. Please refresh and try again.</p>;

  return (
    <section aria-label="image-picker-container">
      <div className={styles.existingImagesContainer}>
        {imageModels.map(imageModel => (
          <ImageModelCard
            key={imageModel.id}
            onSelect={() => setSelectedImageModelId(imageModel.id)}
            imageModel={imageModel}
            selected={imageModel.id.toString() === selectedImageModelId.toString()}
          />
        ))}
      </div>

      <Footer
        submitting={submitting}
        secondaryButtonCallback={closeModal}
        primaryButtonSubmit={false}
        primaryButtonText="Submit"
        primaryButtonDisabled={!selectedImageModelId}
        primaryButtonCallback={submit}
      />
    </section>
  );
};

export default ExistingImagePicker;
