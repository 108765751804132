import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import I18n from 'i18n-js';
import { useVideoHtmlParser } from '@/components/shared/ParsedFroalaHtml/ParsedFroalaHtml';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { closeOnEscapeKey } from '@/components/common/Utils';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import IHintButton from '@/components/interfaces/graphql/HtmlElement/HintButton';
import styles from './HintButton.module.scss';
import useMediaManager from '../hooks/useMediaManager';
import useBrHider from './useBrHider';

interface HintButtonProps {
  hintButton: IHintButton;
}

const HintButton = ({
  hintButton: {
    data: {
      autoHide = true,
      backgroundColor,
      buttonMaxWidth,
    },
    htmlEn,
    htmlEs,
    id,
  },
}: HintButtonProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const currentOpenHintId = useSlideShowContext(state => state.currentOpenHintId);
  const setCurrentOpenHintId = useSlideShowContext(state => state.setCurrentOpenHintId);

  const showHintText = id === currentOpenHintId;
  // Set this state instead if we don't want to auto hide the hint when other hints are opened.
  const [displayHintText, setDisplayHintText] = useState(false);

  const html = (I18n.locale === 'es' && !!htmlEs) ? htmlEs : htmlEn;

  const hintTextStyle = showHintText || displayHintText ? { display: 'block' } : {};
  const { parse, ref } = useVideoHtmlParser('.fr-video');
  const { stopMedias } = useMediaManager({ ref });
  const hideBr = useBrHider(spanRef, showHintText);

  closeOnEscapeKey(() => {
    setCurrentOpenHintId(undefined);
    setDisplayHintText(false);
  });

  useLayoutEffect(() => {
    const $video = $(`<div>${html}></div>`).find('video');

    if ($video.length === 0) return;

    parse();
  }, [html]);

  useEffect(() => {
    if ((autoHide && !showHintText) || (!autoHide && !displayHintText)) stopMedias();
  }, [currentOpenHintId, displayHintText]);

  const toggleHint = () => {
    if (autoHide) {
      setCurrentOpenHintId(showHintText ? undefined : id);
    } else {
      // Set this state instead if we don't want to auto hide the hint when other hints are opened.
      setDisplayHintText(!displayHintText);
    }
  };

  return (
    <span className={clsx({ hintButton: true, hideBr })} ref={spanRef}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <i
        data-background-color={backgroundColor}
        className={`fa ${styles.hintIcon} ${showHintText || displayHintText ? 'fa-minus-circle' : 'fa-plus-circle'}`}
        style={{ maxWidth: buttonMaxWidth }}
        role="button"
        {...useKeydownAndClick(toggleHint)}
      />
      <span
        ref={ref}
        className={styles.hintText}
        style={hintTextStyle}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </span>
  );
};

export default HintButton;
