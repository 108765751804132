export const setImageModelIdFromSnippet = (e: MouseEvent, setCurrentImageModelId: (id: number) => void) => {
  const target = e.target as HTMLElement;

  if (!target.hasAttribute('data-snippet')) return;
  if (!target.getAttribute('data-snippet').includes('IMAGE_MODEL')) return;

  const snippet = target.getAttribute('data-snippet');
  const idMatch = snippet.match(/\d+/);

  if (!idMatch[0]) return;

  setCurrentImageModelId(parseInt(idMatch[0], 10));
};
