import React from 'react';
import PropTypes from 'prop-types';

import Modal, { useModalManager } from 'common/Modal';
import ProgramMappingForm from './ProgramMappingForm';

export const ProgramMappingEditModal = ({
  rowData, subscriberId, updateResource, hasRosteredClassrooms, programsWithStudentSeats, programAccessTypeHash,
}) => {
  const modalManager = useModalManager();

  return (
    <React.Fragment>
      <button className="dropdown-item full-size js-clipboard-trigger" type="button" onClick={modalManager.open}>
        <i className="fa fa-edit" aria-hidden /> Edit
      </button>
      <Modal
        headerText="Edit Rule"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        nearlyFullScreen
      >
        <ProgramMappingForm
          subscriberId={subscriberId}
          closeModal={modalManager.close}
          callback={updateResource}
          rowData={rowData}
          hasRosteredClassrooms={hasRosteredClassrooms}
          programsWithStudentSeats={programsWithStudentSeats}
          programAccessTypeHash={programAccessTypeHash}
        />
      </Modal>
    </React.Fragment>
  );
};

ProgramMappingEditModal.propTypes = {
  hasRosteredClassrooms: PropTypes.bool,
  programAccessTypeHash: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  programsWithStudentSeats: PropTypes.arrayOf(PropTypes.number).isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number,
    program_id: PropTypes.number,
    rules: PropTypes.arrayOf(PropTypes.shape({
      kind: PropTypes.string,
      operator: PropTypes.string,
      rule: PropTypes.string
    }))
  }),
  subscriberId: PropTypes.number.isRequired,
  updateResource: PropTypes.func.isRequired
};

ProgramMappingEditModal.defaultProps = {
  hasRosteredClassrooms: false,
  rowData: null
};

const ProgramMappingModal = ({
  subscriberId, addResource, hasRosteredClassrooms, programsWithStudentSeats, programAccessTypeHash
}) => {
  const modalManager = useModalManager();

  return (
    <React.Fragment>
      <button className="btn btn--green" type="button" onClick={modalManager.open}>
        Add Rule
      </button>
      <Modal
        headerText="Add Rule"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        nearlyFullScreen
      >
        <ProgramMappingForm
          subscriberId={subscriberId}
          closeModal={modalManager.close}
          callback={addResource}
          hasRosteredClassrooms={hasRosteredClassrooms}
          programsWithStudentSeats={programsWithStudentSeats}
          programAccessTypeHash={programAccessTypeHash}
        />
      </Modal>
    </React.Fragment>
  );
};

ProgramMappingModal.propTypes = {
  addResource: PropTypes.func.isRequired,
  hasRosteredClassrooms: PropTypes.bool,
  programAccessTypeHash: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  programsWithStudentSeats: PropTypes.arrayOf(PropTypes.number).isRequired,
  subscriberId: PropTypes.number.isRequired
};

ProgramMappingModal.defaultProps = {
  hasRosteredClassrooms: false
};

export default ProgramMappingModal;
