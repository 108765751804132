import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../PaginatedTable';

export default class BasicPaginatedTable extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnMapping: PropTypes.arrayOf(PropTypes.string).isRequired,
    exportedColumns: PropTypes.arrayOf(PropTypes.number).isRequired,
    defaultOrder: PropTypes.instanceOf(Array),
    columnDefs: PropTypes.instanceOf(Array).isRequired,
    ajaxUrl: PropTypes.string.isRequired,
    pageSize: PropTypes.number,
    processResources: PropTypes.func,
    filterResources: PropTypes.func,
  };

  static defaultProps = {
    pageSize: 500,
    defaultOrder: [[0, 'asc']],
    processResources: resources => (resources),
    filterResources: resources => (resources)
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      resources: [],
      pagesLoaded: 0,
      doneLoading: false,
      getUrl: this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString()),
    };

    this.addResources = this.addResources.bind(this);
    this.resetResources = this.resetResources.bind(this);
  }

  addResources(newResources) {
    const state = {
      resources: [...this.state.resources].concat(this.props.processResources(this.props.filterResources(newResources))),
      doneLoading: newResources.length < this.props.pageSize,
      pagesLoaded: this.state.pagesLoaded + 1
    };

    this.setState(state);
  }

  _renderError() {
    if (this.state.errorMessage) {
      return (
        <div id="flash" className="alert">
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
    return null;
  }

  resetResources() {
    this.setState({ resources: [], doneLoading: false, pagesLoaded: 0 });
  }

  _renderPaginatedTable() {
    return (
      <PaginatedTable
        columns={this.props.columns}
        columnDefs={this.props.columnDefs}
        columnMapping={this.props.columnMapping}
        exportedColumns={this.props.exportedColumns}
        defaultOrder={this.props.defaultOrder}
        resources={this.state.resources}
        addResources={this.addResources}
        resetResources={this.resetResources}
        doneLoading={this.state.doneLoading}
        pagesLoaded={this.state.pagesLoaded}
        getUrl={this.state.getUrl}
      />
    );
  }

  render() {
    return (
      <div>
        {this._renderError()}
        {this._renderPaginatedTable()}
      </div>
    );
  }
}
