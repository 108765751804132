import IHotspot from '@/components/interfaces/Hotspot';
import usePanoramicImageStore from './PanoramicImage/usePanoramicImageStore';

const NEW_HOTSPOT_DEFAULTS = {
  anchor: 'center center',
  createdAt: null,
  hotspotContent: 'Popup content',
  id: null,
  placed: false,
  position: { pitch: 0, yaw: 0 },
  size: { height: 32, width: 32 },
};

const getNewHotspot = (position: number) => {
  const timestamp = Date.now();

  return {
    ...NEW_HOTSPOT_DEFAULTS,
    id: timestamp,
    createdAt: timestamp,
    hotspotContent: `Hotspot ${position}`,
  };
};

export const useAddNewHotspot = () => {
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const setPanoramicImage = usePanoramicImageStore(state => state.setPanoramicImage);
  const setIsDirty = usePanoramicImageStore(state => state.setIsDirty);

  return () => {
    setPanoramicImage({
      ...panoramicImage,
      updated_at: Date.now().toString(),
      hotspots: [...panoramicImage.hotspots, getNewHotspot(panoramicImage.hotspots.length + 1)],
    });

    setIsDirty(true);
  };
};

type HotSpotData = Pick<IHotspot, 'id'> & Partial<IHotspot>;

export const useUpdateHotspot = () => {
  const setPanoramicImage = usePanoramicImageStore(state => state.setPanoramicImage);
  const setIsDirty = usePanoramicImageStore(state => state.setIsDirty);

  return (data: HotSpotData) => {
    const { panoramicImage } = usePanoramicImageStore.getState();

    const hotspots = panoramicImage.hotspots.map((h) => {
      if (h.id !== data.id) return h;

      return { ...h, ...data };
    });

    setPanoramicImage({ ...panoramicImage, updated_at: Date.now().toString(), hotspots });
    setIsDirty(true);
  };
};
export const useDeleteHotspot = (id: number) => {
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const setPanoramicImage = usePanoramicImageStore(state => state.setPanoramicImage);
  const setIsDirty = usePanoramicImageStore(state => state.setIsDirty);

  return () => {
    setPanoramicImage({
      ...panoramicImage,
      updated_at: Date.now().toString(),
      hotspots: [...panoramicImage.hotspots.filter(h => id !== h.id)],
    });

    setIsDirty(true);
  };
};
