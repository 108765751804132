import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import DataTable from '@/components/common/DataTable';
import {
  getColumns, ISubscriber, IUserType, programAccessQueryClient,
} from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import useProgramAccessStore from '@/components/admin/ProgramAccess/ProgramAccessTable/useProgramAccessStore';
import useProgramAccessTableColumnDefs from './useProgramAccessTableColumnDefs';
import useProgramAccessTableData from './useProgramAccessTableData';

interface ProgramAccessTableProps {
  hasSeatPools: boolean;
  subscriber: ISubscriber;
  userType: IUserType;
}

export const ProgramAccessTable = ({ hasSeatPools, subscriber, userType }: ProgramAccessTableProps) => {
  if (!hasSeatPools) return <></>;

  const seatPools = useProgramAccessStore(store => store.seatPools);
  const isLoading = useProgramAccessStore(store => store.isLoading);
  const columnDefs = useProgramAccessTableColumnDefs(subscriber, userType);
  const columns = getColumns(userType);

  useProgramAccessTableData(subscriber);

  return (
    <div>
      <DataTable
        handleResourcesManually
        columns={columns}
        columnDefs={columnDefs}
        loading={isLoading}
        paging={false}
        info={false}
        resources={seatPools}
        defaultOrder={[]}
      />
    </div>
  );
};

const ProgramAccessTableWrapper = (props: ProgramAccessTableProps) => (
  <QueryClientProvider client={programAccessQueryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ProgramAccessTable {...props} />
  </QueryClientProvider>
);

export default ProgramAccessTableWrapper;
