import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keyLogo from 'images/rosteringSetup/keyLogo.svg';

import rosteringCardStyles from '../RosteringCard.module.scss';
import SSOSetupModal from '../SSOSetupModal/index';
import { isAdmin } from '../../../../common/Utils';
import Tooltip from '../../../../common/Tooltip/index';
import * as constants from '../steps';
import { ssoModelPropType } from '../Proptypes';

export default class SSONewCard extends Component {
  static propTypes = {
    completedSteps: constants.completedStepsPropTypes.isRequired,
    existingModels: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasCompletedClever: PropTypes.bool,
    isSaml: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    rootUrl: PropTypes.string.isRequired,
    ssoModel: ssoModelPropType,
    ssoModelCreatePath: PropTypes.string.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    subscriber_id: PropTypes.number.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    hasCompletedClever: false,
    isSaml: false,
    ssoModel: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
    this.props.onClose();
  }

  openModal(e) {
    e.preventDefault();
    this.setState({
      modalIsOpen: true
    });
  }

  _hideCard() {
    return this.props.existingModels.includes('SamlSetting') &&
      this.props.existingModels.includes('LtiKey') &&
      this.props.existingModels.includes('Clever');
  }

  _hasPartialSetup() {
    return this.props.existingModels.includes('SamlSetting') ||
      this.props.existingModels.includes('LtiKey') ||
      this.props.existingModels.includes('Clever');
  }

  _renderSSOSetupModal() {
    if (this.state.modalIsOpen) {
      return (
        <SSOSetupModal
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          ssoModel={this.props.ssoModel}
          ssoModelPath={this.props.ssoModelPath}
          ssoModelCreatePath={this.props.ssoModelCreatePath}
          subscriber_id={this.props.subscriber_id}
          updateSSOModel={this.props.updateSSOModel}
          uploadXMLUrl={this.props.uploadXMLUrl}
          rootUrl={this.props.rootUrl}
          completedSteps={this.props.completedSteps}
          isSaml={this.props.isSaml}
          existingModels={this.props.existingModels}
          hasCompletedClever={this.props.hasCompletedClever}
        />
      );
    }

    return null;
  }

  _renderStartButton(disabled) {
    return (
      <button
        className="btn btn--block btn--purple"
        onClick={this.openModal}
        disabled={disabled}
        type="button"
      >
        {this._hasPartialSetup() ? 'Add Another SSO' : 'Add SSO'}
      </button>
    );
  }

  _renderDisabledStartButton() {
    return (
      <Tooltip
        content="Only administrators can set up single sign on integrations`"
      >
        <div>
          {this._renderStartButton(true)}
        </div>
      </Tooltip>
    );
  }

  render() {
    if (this._hideCard()) return null;

    return (
      <div className={`card ${rosteringCardStyles.card} ${rosteringCardStyles.generalCard}`}>
        <h2>Single Sign On</h2>

        <hr className={rosteringCardStyles.hr} />

        <div className={rosteringCardStyles.displayFlex}>
          <img alt="" src={keyLogo} />
          <div className="ml30">
            <p className="mb20">
              Supported methods: Clever, SAML, LTI, Authentication Tokens, Google Sign In*
              <br />
              <br />
              *Administrator setup not required
            </p>
            {isAdmin(this.props.userType) ? this._renderStartButton(false) : this._renderDisabledStartButton()}
          </div>
        </div>

        {this._renderSSOSetupModal()}
      </div>
    );
  }
}
