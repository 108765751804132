import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default function ReactSelectAdapter({ input, ...rest }) {
  return (
    <Select
      {...input}
      {...rest}
      ref={(ref) => { rest.bindSelectRef(ref); }}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
    />
  );
}

ReactSelectAdapter.propTypes = {
  clearable: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  searchable: PropTypes.bool
};

ReactSelectAdapter.defaultProps = {
  clearable: true,
  searchable: true
};
