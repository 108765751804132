import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Modal, { Footer } from 'common/Modal';
import HtmlElementFactory from 'common/HtmlElements/SnippetFactory';

import PlusButtonInputs from './ModalInputs/PlusButtonInputs';
import TextButtonInputs from './ModalInputs/TextButtonInputs';

import styles from './ElementInsert.module.scss';
import { insertIntoEditor } from '../FroalaListeners';

import { getSlideLabel } from '../../../../Utils';
import { slideObjectPropTypes } from '../../../../../Utils';
import { toCamelCaseDeep } from '../../../../../../../../modules/TCIUtils';
import { getPositionStyles } from './utils';
import useSlideShowContext from '../../../../../stores/useSlideShowContext';
import { capitalize } from '../../../../../../../common/Utils';

const ElementInsertModal = ({
  editMode,
  editorInstance,
  elementParent,
  htmlElementId,
  modalManager,
  setEditMode,
  slideObject,
  hasError,
  setHasError,
  setHtmlElementId,
  customImageModal,
  customAudioModal,
  customVideoModal,
  equationModal,
}) => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const lessonId = useSlideShowContext(state => state.lessonId);
  const slides = useSlideShowContext(state => state.slides);
  const unitId = useSlideShowContext(state => state.unitId);
  const programId = useSlideShowContext(state => state.programId);
  const userType = useSlideShowContext(state => state.userType);
  const locale = useSlideShowContext(state => state.locale);

  const slideshowType = lessonId ? 'Lesson' : 'Unit';
  const canAddTCIVideos = ['Sysadmin', 'ContentManager'].includes(userType);

  const [buttonType, setButtonType] = useState('+/-');
  const [buttonText, setButtonText] = useState('');
  const [contentType, setContentType] = useState('Tooltip');
  const [disableSave, setDisableSave] = useState(true);
  const [sectionIds, setSectionIds] = useState([]);
  const [sectionType, setSectionType] = useState(slideshowType);
  const [contentText, setContentText] = useState('');
  const [destinationSlide, setDestinationSlide] = useState({ label: '', value: '' });
  const [chosenVideo, setChosenVideo] = useState(null);
  const [sectionId, setSectionId] = useState(0);
  const [notebookId, setNotebookId] = useState(0);
  const [selectedQuestionGroupIds, setSelectedQuestionGroupIds] = useState(new Set());
  const [typeOption, setTypeOption] = useState('Section');
  const [videoSource, setVideoSource] = useState(canAddTCIVideos ? 'tci' : 'youtube');
  const [htmlElement, setHtmlElement] = useState({});
  const [autoHide, setAutoHide] = useState(true);
  const [displayAsLink, setDisplayAsLink] = useState(false);

  useEffect(() => {
    if (slides.length === 0) return;

    // If our current slide is the last in the slideshow, use the current slide as the default jump to target.
    const lastSlide = slides.length === currentSlide.position;
    const nextSlide = lastSlide ? currentSlide : slides[currentSlide.position];

    setDestinationSlide({
      label: `Slide ${nextSlide.position} ${lastSlide ? '(current)' : ''}`,
      value: nextSlide.id,
    });
  }, [slides.length]);

  // Defines the two different types of buttons we currently have.
  const labelOptions = [
    { label: '+/-', value: '+/-' },
    { label: 'Text', value: 'Text' },
  ];

  // Section type options
  const sectionTypeOptions = [
    { label: 'Lesson', value: 'Lesson' },
    { label: 'Unit', value: 'Unit' },
  ];

  useEffect(() => {
    if (!editMode) return;
    if (!(elementParent || htmlElementId)) return;

    if (htmlElementId) {
      const _htmlElement = toCamelCaseDeep(slideObject.htmlElements.find(e => parseInt(e.id, 10) === parseInt(htmlElementId, 10)));

      setHtmlElement(_htmlElement);

      if (_htmlElement.type === 'HintButton') {
        setContentText(_htmlElement[`html${capitalize(locale)}`] || '');
        setAutoHide(typeof _htmlElement.data.autoHide === 'undefined' ? true : _htmlElement.data.autoHide);
      }
      else if (_htmlElement.type === 'JumpToButton') {
        setButtonType('Text');
        setContentType('Jump To...');
        setButtonText(_htmlElement.data[`buttonText${locale === 'en' ? 'En' : 'Es'}`]);
        setDisplayAsLink(_htmlElement.data.displayAsLink || false);

        const jumpToSlide = slides.find(slide => slide.id === _htmlElement.data.destinationSlideId?.toString());

        if (jumpToSlide) {
          setDestinationSlide({
            label: getSlideLabel(jumpToSlide, currentSlide),
            value: jumpToSlide.id
          });
        }
      }
      else if (_htmlElement.type === 'QuestionButton') {
        setButtonType('Text');
        setContentType('Question');
        setButtonText(_htmlElement.data[`buttonText${locale === 'en' ? 'En' : 'Es'}`]);

        const exerciseType = _htmlElement.questionGroups[0]?.exerciseType;
        if (exerciseType === 'Notebook') {
          const notebook = _htmlElement.questionGroups[0]?.notebook;

          const notebookModelTypes = {
            Lesson: 'Activity Notebook',
            Section: 'Section',
            Unit: 'Unit Project',
          };

          setTypeOption(notebookModelTypes[notebook.modelType]);
          setNotebookId(parseInt(notebook.id, 10));
          setSectionId(notebook.modelType === 'Section' ? notebook.modelId : 0);
          setSelectedQuestionGroupIds(new Set(_htmlElement.questionGroups.map(({ id }) => parseInt(id, 10))));
        }
        if (exerciseType === 'SlideShow') {
          setTypeOption('Slide Show');
        }
      }
      else if (_htmlElement.type === 'ReadingButton') {
        setButtonType('Text');
        setContentType('Reading');
        setButtonText(_htmlElement.data[`buttonText${locale === 'en' ? 'En' : 'Es'}`]);
        setSectionType(_htmlElement.data.sectionType);
        setSectionIds(_htmlElement.data.sectionIds);
      }
      else if (_htmlElement.type === 'VideoButton') {
        setButtonType('Text');
        setContentType('Video');
        setButtonText(_htmlElement.data[`buttonText${locale === 'en' ? 'En' : 'Es'}`]);

        if (_htmlElement.data.youtubeVideoUrl) {
          setVideoSource('youtube');
          setContentText(_htmlElement.data.youtubeVideoUrl);
        }
        else {
          setChosenVideo({
            id: _htmlElement.modelVideo.video.id,
            label: _htmlElement.modelVideo.video.videoFileName,
            modelVideoId: _htmlElement.modelVideo.id,
            value: parseInt(_htmlElement.modelVideo.video.id, 10),
          });
        }
      }
      else if (_htmlElement.type === 'TooltipButton') {
        setButtonType('Text');
        setContentType('Tooltip');
        setButtonText(_htmlElement.data[`buttonText${locale === 'en' ? 'En' : 'Es'}`] || '');
        setContentText(_htmlElement[`html${capitalize(locale)}`]);
        setAutoHide(typeof _htmlElement.data.autoHide === 'undefined' ? true : _htmlElement.data.autoHide);
      }

      return;
    }

    const type = elementParent.data('button-type');

    setContentText(elementParent.find('.hint-text').children().first().html() || '');

    if (type === 'hint') {
      setButtonType('+/-');
    }
    else if (type === 'tooltip') {
      setButtonType('Text');
      setContentType('Tooltip');
      setButtonText(elementParent.children().first().text());
    }
    else if (type === 'jump') {
      setButtonType('Text');
      setContentType('Jump To...');
      setButtonText(elementParent.children().first().text());
      const jumpToSlide = slides.find(slide => slide.id === elementParent.data('jumpTo').toString());
      setDestinationSlide({ label: getSlideLabel(jumpToSlide, currentSlide), value: jumpToSlide.id });
    }
    else if (type === 'video') {
      setButtonType('Text');
      setContentType('Video');
      setButtonText(elementParent.children().first().text());
      setChosenVideo(elementParent.data('video'));
    }
    else if (type === 'question') {
      setButtonType('Text');
      setContentType('Question');
      setButtonText(elementParent.children().first().text());
      setTypeOption(elementParent.data('notebookType'));
      setNotebookId(elementParent.data('notebookId'));
      setSectionId(elementParent.data('sectionId'));
      setSelectedQuestionGroupIds(new Set(elementParent.data('questionGroupIds')));
    }
    else {
      // Section type of button
      const secType = elementParent.data('section-type');
      const ids = elementParent.children().first().data('section-ids');

      setButtonType('Text');
      setContentType('Reading');

      // If 'data-section-type' is defined on the "Reading" btn. AKA buttons created after Unit and Lesson Sections.
      // Get section-type of button on click to pre-populate edit modal.
      if (typeof secType !== 'undefined') {
        setSectionType(secType);
      }
      setButtonText(elementParent.text());
      setSectionIds(ids ? ids.toString().split(',') : []);
    }
  }, [elementParent]);

  const resetValues = () => {
    modalManager.close();
    setContentType('Tooltip');
    setDisableSave(true);
    setDestinationSlide({ label: '', value: '' });
    setEditMode(false);
    setContentText('');
    setButtonText('');
    setButtonType('+/-');
    setSectionIds([]);
    setSectionType(slideshowType);
    setChosenVideo(null);
    setSectionId(0);
    setNotebookId(0);
    setSelectedQuestionGroupIds(new Set());
    setTypeOption('Section');
    setHtmlElementId(null);
    setHtmlElement(null);
    setAutoHide(true);
    setDisplayAsLink(false);
  };

  const getObjectToInsert = async () => {
    const factory = HtmlElementFactory();

    if (buttonType === '+/-') {
      const hintButtonParams = {
        autoHide: autoHide,
        htmlEn: contentText,
        id: htmlElement.id,
        locale,
        modelId: slideObject.id,
        modelType: 'SlideObject',
        type: 'HintButton',
      };
      return factory.createOrUpdateHtmlElement(hintButtonParams);
    }

    switch (contentType) {
      case 'Reading':
        return factory.createOrUpdateHtmlElement({
          [`buttonText${capitalize(locale)}`]: buttonText,
          id: htmlElement.id,
          modelId: slideObject.id,
          modelType: 'SlideObject',
          sectionIds,
          sectionType,
          type: 'ReadingButton',
        });
      case 'Jump To...':
        return factory.createOrUpdateHtmlElement({
          [`buttonText${capitalize(locale)}`]: buttonText,
          destinationSlideId: parseInt(destinationSlide.value, 10),
          displayAsLink,
          id: htmlElement.id,
          modelId: slideObject.id,
          modelType: 'SlideObject',
          type: 'JumpToButton',
        });
      case 'Video':
        const videoButtonParams = {
          [`buttonText${capitalize(locale)}`]: buttonText,
          id: htmlElement.id,
          modelId: slideObject.id,
          modelType: 'SlideObject',
          type: 'VideoButton',
        };

        if (videoSource === 'tci' && chosenVideo) {
          videoButtonParams.modelVideoAttributes = {
            id: chosenVideo.modelVideoId ? chosenVideo.modelVideoId : undefined,
            videoId: chosenVideo.id
          };

          // clear youtube url if a TCI video is chosen
          videoButtonParams.youtubeVideoUrl = undefined;
        }

        if (videoSource === 'youtube') {
          videoButtonParams.youtubeVideoUrl = contentText;

          // delete existing model videos if a youtube URL is given
          if (chosenVideo) {
            videoButtonParams.modelVideo = null;

            setChosenVideo(undefined);
          }
        }

        return factory.createOrUpdateHtmlElement(videoButtonParams);
      case 'Question':
        return factory.createOrUpdateHtmlElement({
          [`buttonText${capitalize(locale)}`]: buttonText,
          id: htmlElement.id,
          modelId: slideObject.id,
          modelType: 'SlideObject',
          questionGroupIds: [...selectedQuestionGroupIds],
          type: 'QuestionButton',
        });
      case 'Tooltip':
      default:
        return factory.createOrUpdateHtmlElement({
          autoHide: autoHide,
          [`buttonText${capitalize(locale)}`]: buttonText,
          htmlEn: contentText,
          id: htmlElement.id,
          locale,
          modelId: slideObject.id,
          modelType: 'SlideObject',
          tooltipStyles: getPositionStyles(elementParent, htmlElement),
          type: 'TooltipButton',
        });
    }
  };

  // Inserts element into froala editor here.
  const handleSubmit = async () => {
    let insertedViaJquery = false;
    if (editMode) {
      /**
       * Remove via jquery due to froala removing text alignment from inserted elements
       * if trying to insert via editorInstance.html.insert
       */
      if (htmlElementId) {
        elementParent.after(ReactDOMServer.renderToStaticMarkup(await getObjectToInsert(false)));
        elementParent.first().remove();
      }
      else {
        elementParent.parent().after(ReactDOMServer.renderToStaticMarkup(await getObjectToInsert(false)));
        elementParent.parent().first().remove();
      }
      insertedViaJquery = true;
      editorInstance.selection.setBefore(elementParent[0]);
    }

    insertIntoEditor(editorInstance, await getObjectToInsert(true), false, insertedViaJquery);

    // Reset state values to default to prepare for next element to insert.
    resetValues();
  };

  const validateTextButtonContent = () => {
    // Disable 'Save' button if buttonText is empty for new buttons
    if (buttonText.trim() === '') {
      setDisableSave(true);
      return;
    }

    // Disable 'Save' button if contentText is empty for Tooltip buttons
    if (contentType === 'Tooltip') {
      setDisableSave(contentText.trim() === '');
      return;
    }

    if (contentType === 'Jump To...') {
      setDisableSave(destinationSlide.value.trim() === '');
      return;
    }

    if (contentType === 'Video') {
      if (videoSource === 'tci') {
        setDisableSave(!chosenVideo);
        return;
      }

      setDisableSave(!contentText);
      return;
    }

    if (contentType === 'Question') {
      setDisableSave(hasError || selectedQuestionGroupIds.size === 0);
      return;
    }
    // Disable 'Save' button if no sections are associated for Reading Buttons
    setDisableSave(sectionIds.length === 0);
  };

  // Disable save button based on state.
  const validateSubmit = () => {
    if (buttonType === '+/-') {
      setDisableSave(contentText.trim() === '');
    }
    else if (buttonType === 'Text') {
      validateTextButtonContent();
    }
  };

  useEffect(() => {
    setDisableSave(true);
    validateSubmit();
  }, [
    contentText, buttonType, buttonText, contentType, destinationSlide, sectionIds, chosenVideo,
    selectedQuestionGroupIds
  ]);

  const handleContentTextChange = value => setContentText(value);
  const handleContentTypeChange = e => setContentType(e.value);
  const handleButtonTextChange = e => setButtonText(e.target.value);
  const handleLabelChange = e => setButtonType(e.value);
  const handleSectionTypeChange = e => setSectionType(e.value);
  const handleDestinationSlideChange = e => setDestinationSlide(e);
  const handleVideoChange = e => setChosenVideo(e);

  const buttonTypeSelect = () => (
    <div className="tw-flex">
      <div className="tw-flex-col">
        <label htmlFor="buttonType" className={`${styles.required} ${styles.labelText}`}>
          Button Type
        </label>
        <Select
          className={styles.labelSelect}
          clearable={false}
          inputProps={{ id: 'buttonType' }}
          onChange={handleLabelChange}
          openOnFocus
          options={labelOptions}
          value={buttonType}
        />
      </div>
      {(buttonType === '+/-' || contentType === 'Tooltip') && (
        <label className="tw-flex tw-items-end tw-ml-10">
          <div className="tw-flex tw-items-center">
            <input type="checkbox" checked={!autoHide} onChange={() => setAutoHide(!autoHide)} />
            <b>Do not close automatically</b>
          </div>
        </label>
      )}
    </div>
  );

  const renderElementInputs = () => {
    if (buttonType === 'Text') {
      return (
        <TextButtonInputs
          editMode={editMode}
          canAddTCIVideos={canAddTCIVideos}
          contentType={contentType}
          destinationSlide={destinationSlide}
          displayAsLink={displayAsLink}
          buttonText={buttonText}
          chosenVideo={chosenVideo}
          handleContentTextChange={handleContentTextChange}
          handleContentTypeChange={handleContentTypeChange}
          handleButtonTextChange={handleButtonTextChange}
          handleDestinationSlideChange={handleDestinationSlideChange}
          handleSectionTypeChange={handleSectionTypeChange}
          handleVideoChange={handleVideoChange}
          hintText={contentText}
          lessonId={lessonId}
          notebookId={notebookId}
          programId={programId}
          sectionIds={sectionIds}
          sectionTypeOptions={sectionTypeOptions}
          sectionType={sectionType}
          setDisplayAsLink={setDisplayAsLink}
          setSectionIds={setSectionIds}
          setSectionType={setSectionType}
          setHasError={setHasError}
          selectedQuestionGroupIds={selectedQuestionGroupIds}
          setTypeOption={setTypeOption}
          setNotebookId={setNotebookId}
          sectionId={sectionId}
          setSectionId={setSectionId}
          setSelectedQuestionGroupIds={setSelectedQuestionGroupIds}
          setVideoSource={setVideoSource}
          slideObject={slideObject}
          typeOption={typeOption}
          unitId={unitId}
          videoSource={videoSource}
          customImageModal={customImageModal}
          customAudioModal={customAudioModal}
          customVideoModal={customVideoModal}
          htmlElementId={htmlElementId}
          equationModal={equationModal}
        />
      );
    }

    return (
      <PlusButtonInputs
        handleContentTextChange={handleContentTextChange}
        hintText={contentText}
        customImageModal={customImageModal}
        customAudioModal={customAudioModal}
        customVideoModal={customVideoModal}
        equationModal={equationModal}
      />
    );
  };

  // Submit is called in the footer here.
  const modalFooter = () => (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={handleSubmit}
      primaryButtonDisabled={disableSave}
      primaryButtonSubmit={false}
      primaryButtonText="Save"
      wrapperClassName="pr20 pl20 pb20"
    />
  );

  return (
    <Modal
      nearlyFullScreen
      headerText={`${editMode ? 'Edit' : 'Add'} Button`}
      isOpen={modalManager.isOpen}
      closeModal={resetValues}
      footer={modalFooter()}
    >
      <div className={styles.modalFieldsWrapper}>
        {buttonTypeSelect()}

        {renderElementInputs()}
      </div>
    </Modal>
  );
};

ElementInsertModal.propTypes = {
  customAudioModal: PropTypes.func,
  customImageModal: PropTypes.func,
  customVideoModal: PropTypes.func,
  editMode: PropTypes.bool,
  editorInstance: PropTypes.shape({
    $el: PropTypes.shape({ get: PropTypes.func.isRequired }),
    html: PropTypes.shape({ insert: PropTypes.func.isRequired }),
    selection: PropTypes.shape({
      restore: PropTypes.func.isRequired,
      setAtEnd: PropTypes.func.isRequired,
    }),
    undo: PropTypes.shape({ saveStep: PropTypes.func.isRequired }),
  }),
  elementParent: PropTypes.object,
  equationModal: PropTypes.func,
  hasError: PropTypes.bool.isRequired,
  htmlElementId: PropTypes.string,
  locale: PropTypes.string,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  setEditMode: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHtmlElementId: PropTypes.func,
  slideObject: slideObjectPropTypes.isRequired,
};

ElementInsertModal.defaultProps = {
  customAudioModal: () => {
  },
  customImageModal: () => {
  },
  customVideoModal: () => {
  },
  editMode: false,
  editorInstance: null,
  elementParent: null,
  equationModal: () => {},
  locale: 'en',
};

export default ElementInsertModal;
