import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import styles from './Delete.module.scss';

export default class Delete extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    destroyPath: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    }).isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.setState({ submitting: true });

    Axios
      .delete(this.props.destroyPath.replace(':id', this.props.rowData.id))
      .then((response) => {
        if (response.status === 200) {
          this.props.updateTable(response.data.data, 'delete');
        }
        this.props.closeModal();
      })
      .catch((error) => {
        this.props.updateTable(error.toString(), 'error');
        this.props.closeModal();
      });
  }

  _renderDeleteButton() {
    let text = 'Yes, delete';
    if (this.state.submitting) {
      text = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--purple"
        disabled={this.state.submitting}
        onClick={this.handleDelete}
      >
        {text}
      </button>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        headerText="Delete Student"
        isOpen={this.props.modalIsOpen}
      >
        <h1 className={styles.message}>
          Deleting this student will permanently remove all data associated with this student from TCI.
          Are you sure you want to complete this action?
        </h1>

        <hr />

        <footer>
          <button
            className="btn btn--link-purple"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>

          {this._renderDeleteButton()}
        </footer>
      </Modal>
    );
  }
}
