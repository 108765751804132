import React, { useState } from 'react';
import Axios, { AxiosError } from 'axios';
import { ActionButton } from '@/components/ui/Buttons';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import { QueryClientProvider, QueryClient, useMutation } from '@tanstack/react-query';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Input } from '@/components/ui/Form';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

interface Response {
  data: {
    data: {
      redirect_to?: string;
    }
  }
}

interface CopyButtonProps {
  buttonText?: string;
  parentId: number;
  parentType: 'Unit' | 'Lesson' | 'Section';
  modelId: number;
  modelType: 'Slideshow' | 'Notebook' | 'Section';
  submitPath: string;
  title?: string;
}

interface FormData {
  id: number;
  parent_type: string;
  title_en?: string;
  parent_id: string | number;
}

const CopyButton = ({
  buttonText = 'Make a Copy', modelId, modelType, parentId, parentType, submitPath, title,
}: CopyButtonProps) => {
  const { isOpen, open, close } = useModalManager();
  const [invalidId, setInvalidId] = useState(null);
  const [apiError, setApiError] = useState(null);
  const usesTitle = modelType !== 'Section';

  const defaultValues = {
    parent_id: parentId,
    parent_type: parentType,
    id: modelId,
    title_en: `Copy of ${title}`,
  };

  const {
    formState: {
      errors,
      isValid,
    },
    getValues,
    handleSubmit,
    register,
    trigger,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });

  const onSuccess = ({ data }: Response) => {
    if (data.data && data.data.redirect_to) {
      window.location.replace(data.data.redirect_to);
    } else {
      window.location.reload();
    }
  };

  interface ResponseData { message?: string; code?: string, resource_id?: string };
  const onError = async (error: AxiosError) => {
    const { status } = error.response || {};
    const data: ResponseData = error.response.data || {};
    const parentId = getValues('parent_id');

    if (
      status === 404 ||
      (status === 422 && data.code === 'RESOURCE_NOT_FOUND' && data.resource_id === parentId)
    ) {
      setInvalidId(parentId);
      await trigger();
    } else {
      setApiError(data.message || 'There was a problem copying')
      await trigger();
    }
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: (formData: FormData) => Axios.post(submitPath, formData),
    onSuccess,
    onError,
  });

  const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
    setApiError(null);
    mutate(formData);
  };

  return (
    <>
      <ActionButton onClick={open}>
        {buttonText}
      </ActionButton>
      <SubmitModal
        headerText={`Make a ${modelType} Copy`}
        close={() => {
          setApiError(null);
          close();
        }}
        isLoading={isLoading}
        isOpen={isOpen}
        isValid={isValid}
        onSubmit={handleSubmit(onSubmit)}
        submitText="Make a Copy"
        submittingText="Copying…"
      >
        {usesTitle && <Input
          variant="inline"
          errors={errors}
          label="Title"
          placeholder={`${modelType} title`}
          required={usesTitle}
          {...register('title_en', { required: 'Required' })}
        />}
        <p className="tw-mt-2 tw-mb-0.5">Enter the ID for the location you&apos;d like to copy to.</p>
        <Input
          variant="inline"
          errors={errors}
          label={`${parentType} ID`}
          placeholder={`${parentType} ID`}
          required
          {...register('parent_id', {
            required: 'Required',
            validate: value => value !== invalidId || `${parentType} ID does not exist`,
          })}
        />
        {apiError && <div className="tw-text-red tw-pt-2">{apiError}</div>}
      </SubmitModal>
    </>
  );
};

const queryClient = new QueryClient();

const CopyButtonWrapper = (props: CopyButtonProps) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <CopyButton {...props} />
  </QueryClientProvider>
);

export default CopyButtonWrapper;
