import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import globalContext from 'common/Context';

import Modal from 'common/Modal';

export default class Delete extends Component {
  static contextType = globalContext;

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.setState({ submitting: true });

    Axios
      .delete(this.context.destroyPath.replace(':id', this.props.id))
      .then((response) => {
        if (response.status === 200) {
          this.props.updateTable({ deletedIds: [this.props.id] });
        }

        this.props.closeModal();
      })
      .catch(error => console.log(error));
  }

  _renderDeleteButton() {
    let text = 'Yes, delete';
    if (this.state.submitting) {
      text = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--purple"
        disabled={this.state.submitting}
        onClick={this.handleDelete}
      >
        {text}
      </button>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        headerText="Delete Staff"
        isOpen={this.props.isOpen}
      >
        Deleting this user will remove all access to your account from this
        user. Are you sure you want to complete this action?

        <hr />

        <footer>
          <button
            className="btn btn--link-purple"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>

          {this._renderDeleteButton()}
        </footer>
      </Modal>
    );
  }
}
