import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { slideObjectPropTypes } from '../../../Utils';
import styles from './SlideTitle.module.scss';
import { UPDATE_SLIDE } from '../../../Utils/slideShowGraphQL';
import useSlideShowContext from '../../../stores/useSlideShowContext';

const SlideTitle = ({ isSaving, setIsSaving, slide }) => {
  const [title, setTitle] = useState('');
  const [updateSlideMutation] = useMutation(UPDATE_SLIDE);
  const updateSlideInContext = useSlideShowContext(state => state.updateSlide);

  const typingTimeout = 800;
  let inputTimeout;

  const updateSlide = (text) => {
    const mutationParams = {
      variables: {
        id: slide.id,
        title: text
      }
    };

    setIsSaving(true);

    updateSlideMutation(mutationParams)
      .then((res) => {
        updateSlideInContext(slide.id, res.data.updateSlide.slide);
        setIsSaving(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    // Set default title to "Slide Title" for new slides.
    if (slide.title === null) setTitle('Slide Title');
    else setTitle(slide.title);
  }, [slide.id]);

  useEffect(() => {
    // Don't send graphql mutation request if title hasn't changed.
    // i.e. slide is loading in or current slide has same title as previously chosen slide.
    if (isSaving || title === slide.title) return;

    // Timeout to limit update calls until user is finished typing.
    inputTimeout = setTimeout(() => {
      updateSlide(title);
    }, typingTimeout);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(inputTimeout);
  }, [title]);


  const inputTextHandler = (e) => {
    setTitle((e.target.value));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') e.target.blur();
  };

  const updateTitleText = (e) => {
    if (isSaving || title === slide.title) return;
    clearTimeout(inputTimeout);
    updateSlide(e.target.value);
  };

  return (
    <div>
      <input
        className={styles.slideTitle}
        onChange={inputTextHandler}
        onKeyDown={handleKeyPress}
        onBlur={updateTitleText}
        value={title}
      />
    </div>
  );
};

SlideTitle.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  slide: slideObjectPropTypes.isRequired,
};

export default SlideTitle;
