import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { validConfirmation } from './Validators';
import styles from './TypeToConfirm.module.scss';

export default function TypeToConfirm(props) {
  function inputField({ input, ...rest }) {
    return (
      <input
        {...input}
        {...rest}
      />
    );
  }

  return (
    <Field
      className={props.classes}
      name={props.name}
      render={inputField}
      validate={validConfirmation(props.confirmationString)}
    />
  );
}

TypeToConfirm.propTypes = {
  classes: PropTypes.string,
  confirmationString: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

TypeToConfirm.defaultProps = {
  classes: styles.input
};
