import React from 'react';

interface UseMediaManagerProps {
  ref: React.MutableRefObject<HTMLDivElement>;
}

const useMediaManager = ({ ref }: UseMediaManagerProps) => {
  const stopVideos = () => {
    const ids = Array.from(ref.current.querySelectorAll('.video-js')).map(v => v.id);

    ids.forEach((videoId) => {
      const player = window.videojs(videoId);
      if (!player.paused()) player.pause();
    });
  };

  const stopAudios = () => {
    // Simplified audio player
    ref.current.querySelectorAll('.simple-audio-player').forEach((a: HTMLElement) => {
      if (a.classList.contains('btn--purple')) a.click();
    });

    // Full size audio players
    ref.current.querySelectorAll('audio').forEach((a: HTMLAudioElement) => {
      a.pause();
    });
  };

  const stopMedias = () => {
    stopVideos();
    stopAudios();
  };

  return { stopMedias };
};

export default useMediaManager;
