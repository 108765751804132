import moment from 'moment';

export const validateDates = ({ pause_start_date: startDate, pause_stop_date: stopDate }) => {
  if (!startDate || !stopDate) return {};

  const formattedStopDate = moment(stopDate, 'YYYY-MM-DD');
  const formattedStartDate = moment(startDate, 'YYYY-MM-DD');

  if (moment.duration(formattedStopDate.diff(formattedStartDate)).asDays() >= 1) return {};

  return { pause_stop_date: 'Stop date must be after start date.' };
};
