import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useHomepageOptInMutation = ({ stafferPath }) => (
  useMutation({
    mutationFn: ({ nextSetting }: { nextSetting: boolean }) => (
      axios
        .patch(stafferPath, { new_student_homepage: nextSetting })
        .then(res => res.data)
    ),
    mutationKey: ['useHomepageOptInMutation'],
  })
);

export default useHomepageOptInMutation;
