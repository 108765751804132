import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { CancelButton, SubmitButton } from 'common/Modal';
import Axios from 'axios';
import styles from './SubmitAssessmentButton.module.scss';

export default class SubmitAssessmentButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    studentName: PropTypes.string.isRequired,
    submitPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: null
  };

  constructor(props) {
    super(props);

    this.state = { error: false, modalOpen: false, submitting: false };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitAssessment = this.submitAssessment.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  submitAssessment() {
    this.setState({ submitting: true });

    return Axios
      .put(this.props.submitPath)
      .then(() => window.location.reload())
      .catch(() => this.setState({ error: true }));
  }

  render() {
    return (
      <div className={`${styles.noSubmissionMessage} ${this.props.className}`}>
        <i className="fa fa-exclamation-circle mr5" aria-hidden />
        Due date has passed and student has not submitted. Submit assessment to begin grading.

        <button
          type="button"
          className="btn btn--sm btn--red ml10"
          onClick={this.openModal}
        >
          Turn In
        </button>
        <Modal
          closeModal={this.closeModal}
          headerText="Turn In"
          isOpen={this.state.modalOpen}
          error={this.state.error}
        >
          Are you sure you want to submit this assessment for {this.props.studentName}?

          <hr />

          <footer>
            <CancelButton onCancel={this.closeModal} />
            <SubmitButton onSubmit={this.submitAssessment} isSubmitting={this.state.submitting} />
          </footer>
        </Modal>
      </div>
    );
  }
}
