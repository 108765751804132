import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

export default class NewProgramGuideButton extends Component {
  static propTypes = {
    ajaxPath: PropTypes.string.isRequired,
    hasGuide: PropTypes.bool.isRequired,
    processed: PropTypes.bool
  };

  static defaultProps = { processed: true };

  constructor(props) {
    super(props);
    this.state = { processed: this.props.processed };
  }

  componentDidMount() {
    this.isUpdated();

    if (!this.state.processed) {
      setTimeout(() => { this.makeRotate($('.fa-spinner')); });
    }
  }

  generateGuide() {
    Axios.post(this.props.ajaxPath).then((response) => {
      if (response.data.isEmpty) {
        window.location.reload();
      }
      else {
        this.setState({ processed: false });
        this.showLoadingButton();
        this.makeRotate($('.fa-spinner'));
        this.isUpdated();
      }
    });
  }

  isUpdated() {
    if (!this.state.processed) {
      Axios.get(this.props.ajaxPath).then((response) => {
        if (response.data.processed) {
          window.location.reload();
        }
        else {
          setTimeout(() => { this.isUpdated(); }, 30000);
        }
      });
    }
  }

  button() {
    if (this.state.processed) {
      return (
        <button
          className="action mt5"
          onClick={() => this.generateGuide()}
          type="button"
        >
          {this.buttonText()}
        </button>
      );
    }

    return (
      <button
        className="disabled action mt5"
        disabled
        type="button"
      >
        <i className="fa fa-spinner fa-1x fa-fw" />
        Generating Guide
      </button>
    );
  }

  buttonText() {
    return this.props.hasGuide ? 'Update PDFs' : 'Create PDFs';
  }

  showLoadingButton() {
    const loadingIcon = document.createElement('i');
    loadingIcon.classList.add('fa', 'fa-spinner', 'fa-1x', 'fa-fw');

    const loadingButton = $('#loadingButton');
    loadingButton.html(loadingIcon);
    this.makeRotate(loadingIcon);
    loadingButton.append(' Generating Guide');
  }

  makeRotate(elem) {
    $(elem).animateRotate(
      360,
      1337,
      'linear',
      () => { this.makeRotate(elem); }
    );
  }

  render() {
    return (
      this.button()
    );
  }
}
