import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from '../../ElementInsert.module.scss';
import { getSlideLabel } from '../../../../../../Utils';
import useSlideShowContext from '../../../../../../../stores/useSlideShowContext';

const SlideSelect = ({ destinationSlide, handleDestinationSlideChange }) => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slides = useSlideShowContext(state => state.slides);

  return (
    <>
      <label htmlFor="jumpToSelect" className={`${styles.required} ${styles.labelText}`}>
        Slide
      </label>
      <Select
        className={`${styles.labelSelect} ${styles.slideSelect}`}
        clearable={false}
        value={destinationSlide}
        onChange={handleDestinationSlideChange}
        options={slides.map(slide => ({ label: getSlideLabel(slide, currentSlide), value: slide.id }))}
      />
    </>
  );
};

SlideSelect.propTypes = {
  destinationSlide: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  handleDestinationSlideChange: PropTypes.func.isRequired,
};

export default SlideSelect;
