import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import * as Routes from '@/modules/routes';
import Pluralize from 'pluralize';

export const codeColumn = (columnIndex: number) => ({
  sortable: true,
  targets: columnIndex,
  width: '155px',
  createdCell: (td, _cellData, rowData) => {
    ReactDOM.render(
      <span style={{ overflowWrap: 'anywhere' }}>{rowData.code}</span>,
      td,
    );
  },
});

export const titleColumn = (columnIndex: number) => ({
  sortable: true,
  targets: columnIndex,
  width: '400px',
  createdCell: (td, _cellData, rowData) => {
    ReactDOM.render(
      <a href={Routes.admin_program_lessons_path(rowData.id)}>{rowData.title}</a>,
      td,
    );
  },
});

export const licensesColumn = (columnIndex: number,
  modalManager: ModalManager,
  setProgramParams: (rowData) => void) => {
  const openLicenseModal = (rowData, cellData) => {
    setProgramParams(rowData);
    modalManager.open();
  };

  return ({
    sortable: true,
    targets: columnIndex,
    title: 'Num of Licenses',
    width: '65px',
    createdCell: (td, _cellData, rowData) => {
      if (_cellData !== 0) {
        ReactDOM.render(
          <button
            className="link-style purple"
            onClick={() => openLicenseModal(rowData, _cellData)}
            type="button"
          >
            {rowData.num_licenses}
            <i
              aria-hidden="true"
              className="fa fa-edit tw-ml-1"
            />
          </button>,
          td,
        );
      } else {
        ReactDOM.render(
          <button className="link-style purple" type="button">
            {rowData.num_licenses}
          </button>,
          td,
        );
      }
    },
  });
};

export const publishStatusColumn = (columnIndex: number) => ({
  sortable: true,
  targets: columnIndex,
  width: '75px',
  createdCell: (td, _cellData, rowData) => {
    ReactDOM.render(
      <span>{rowData.published}</span>,
      td,
    );
  },
});

export const actionColumn = (columnIndex: number) => ({
  sortable: false,
  targets: columnIndex,
  width: '5px',
  createdCell: (td, _cellData, rowData) => {
    ReactDOM.render(
      <a href={Routes.edit_admin_program_path(rowData.id)} className="btn btn--outline-purple btn--sm">Edit</a>,
      td,
    );
  },
});

const SubscribersColumn = ({ subscribers }: { subscribers: string[] }) => {
  const [showNames, setShowNames] = useState(false);
  const hasSubscribers = subscribers.length > 0;

  return (
    <>
      <span>{subscribers.length} {Pluralize('subscriber', subscribers.length)}</span>
      {hasSubscribers && (
        <button
          type="button"
          onClick={() => { setShowNames(!showNames); }}
          className="btn btn--link-purple"
        >
          {showNames ? 'Hide All' : 'View All'}
        </button>
      )}

      {showNames && <span>{subscribers.join(', ')}</span>}
    </>
  );
};

export const subscribersColumn = (columnIndex: number) => ({
  sortable: false,
  targets: columnIndex,
  width: '90px',
  createdCell: (td, _cellData, { subscriber }) => {
    ReactDOM.render(
      <SubscribersColumn subscribers={subscriber} />,
      td,
    );
  },
});

export const copyrightsColumn = (columnIndex: number) => ({
  sortable: true,
  targets: columnIndex,
  width: '90px',
  createdCell: (td, _cellData, { copyrights }) => {
    ReactDOM.render(
      <span>&copy; {copyrights}</span>,
      td,
    );
  },
});

export const CUSTOM_COLUMN_DEFS = (modalManager, setProgramParams) => ([
  titleColumn(0), copyrightsColumn(1), codeColumn(2),
  subscribersColumn(3), licensesColumn(4, modalManager, setProgramParams),
  publishStatusColumn(5), actionColumn(6),
]);

export const STANDARD_COLUMN_DEFS = (modalManager, setProgramParams) => ([
  titleColumn(0), copyrightsColumn(1), codeColumn(2),
  licensesColumn(3, modalManager, setProgramParams),
  publishStatusColumn(4), actionColumn(5),
]);

export const CUSTOM_COLUMNS = ['title', 'copyrights', 'code', 'subscriber', 'num_licenses', 'published', 'action'];

export const STANDARD_COLUMNS = ['title', 'copyrights', 'code', 'num_licenses', 'published', 'action'];
