import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal, { Footer, useModalManager } from '@/components/common/Modal';
import * as Routes from '@/modules/routes';
import { useSubmit } from '@/components/common/Forms';
import { createDataURI } from '@/modules/TCIUtils';
import PosterSelector from './PosterSelector';
import useVideoPosterStore, { Video } from './useVideoPosterStore';

interface SelectPosterProps {
  afterSubmit: (response: Video) => void;
  videoId: number;
}

const generateFile = (video: Video, locale: 'en' | 'es', poster: HTMLCanvasElement = undefined) => {
  if (!poster) return null;

  const blob = createDataURI(poster);
  const fileNameField = locale === 'es' ? 'video_es_file_name' : 'video_file_name';

  const videoName = video[fileNameField].split('.mp4')[0];
  const timestamp = Date.now();
  const extension = blob.type.split('/')[1];
  const posterFileName = `${videoName}_poster_${timestamp}.${extension}`;

  return new File([blob], posterFileName);
};

const SelectPoster = ({ afterSubmit, videoId }: SelectPosterProps) => {
  const {
    enPoster,
    esPoster,
    video,
    setVideo,
    useEnPoster,
  } = useVideoPosterStore();
  const modal = useModalManager();
  const submitter = useSubmit();
  const videosPath = Routes.plato_api_video_path(videoId);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (response: Video) => {
    afterSubmit(response);
    modal.close();
  };

  useEffect(() => {
    if (modal.isOpen) {
      setIsLoading(true);
      axios.get(videosPath).then(({ data: { video: videoData } }) => {
        setVideo(videoData);
        setIsLoading(false);
      });
    }
  }, [modal.isOpen]);

  return (
    <>
      <button
        id="poster_actions_item"
        type="button"
        className="dropdown-item full-size"
        onClick={modal.open}
      >
        <i className="fa fa-image" /> Select Poster
      </button>
      <Modal
        isLoading={isLoading}
        isOpen={modal.isOpen}
        closeModal={modal.close}
        headerText="Select Poster"
      >
        {video?.url && <PosterSelector locale="en" />}
        {video?.es_url && <PosterSelector locale="es" />}
        <Footer
          primaryButtonText="Save"
          primaryButtonCallback={() => {
            submitter.submit('patch', videosPath, {
              poster: generateFile(video, 'en', enPoster),
              poster_es: generateFile(video, 'es', esPoster),
              use_en_poster: useEnPoster,
            }, handleSubmit);
          }}
          primaryButtonSubmit={false}
          primaryButtonDisabled={useEnPoster ? false : enPoster === null && esPoster === null}
          secondaryButtonCallback={modal.close}
          submitting={submitter.submitting}
        />
      </Modal>
    </>
  );
};

SelectPoster.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  videoId: PropTypes.number.isRequired,
};

export default SelectPoster;
