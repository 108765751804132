import React, { useEffect } from 'react';
import showToast from './index';

interface DisplayToastProps {
  message: string;
  autoClose?: number;
  msgType?: 'info' | 'success' | 'warning' | 'error';
  msgHeading?: string;
}

// Use this component to display toasts on pages built outside of react. i.e. in .erb files
const DisplayToast = ({ message, msgType = 'info', autoClose = 5000, msgHeading }: DisplayToastProps) => {
  useEffect(() => {
    showToast(
      <div>{message}</div>,
      { autoClose, msgType, msgHeading },
    );
  });

  return (
    <></>
  );
};

export default DisplayToast;
