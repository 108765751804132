import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Footer } from 'common/Modal';
import { TextField } from 'common/Forms';
import * as Routes from 'routes';
import Axios from 'axios';
import { checkConnection,
  footerCTA,
  CheckConnectionBanner } from '../../RosteringSetupModal/Steps/ConfigurationStep/CheckConnection';
import useCredentialStore from '../useCredentialStore';
import { updateLtiKey, createOAuthCredential, updateOAuthCredential } from '../queries';

const Configuration = ({ initialLmsType, modalManager, subscriberId, next }) => {
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);
  const canvasCredentials = useCredentialStore(state => state.canvasCredentials);
  const setStep = useCredentialStore(state => state.setStep);
  const submitting = useCredentialStore(state => state.submitting);
  const setSubmitting = useCredentialStore(state => state.setSubmitting);
  const [connection, setConnection] = useState('');

  const submitCallback = () => {
    setStep(2);
    setSubmitting(false);
    next();
  };

  const createNewOAuthCredential = params => (
    createOAuthCredential(subscriberId, params)
      .then(submitCallback)
      .catch(error => console.error(error))
  );

  const updateExistingOAuthCredential = params => (
    updateOAuthCredential(subscriberId, params)
      .then(submitCallback)
      .catch(error => console.error(error))
  );

  const createOAuthCredentialAndDeleteLtiKey = params => (
    Axios.all([
      createOAuthCredential(subscriberId, params),
      updateLtiKey(subscriberId, { ...ltiCredentials, deployment_id: '', lms_provider_id: '' })
    ]).then(submitCallback)
      .catch(error => console.error(error))
  );

  const submitForm = async (values) => {
    setSubmitting(true);

    if (connection !== 'connected') {
      setConnection('checking');

      let params = `client_id=${values.key}&host=${values.url}`;
      if (values.secret) {
        params = `${params}&secret=${values.secret}`;
      }

      const endpoint = Routes.plato_api_check_lms_connection_subscriber_path(subscriberId);

      const connectionResult = await checkConnection(subscriberId, params, endpoint);

      setConnection(connectionResult);
      setSubmitting(false);

      return false;
    }

    const params = {
      ...values,
      subscriber_id: subscriberId
    };

    if (!initialLmsType) return createNewOAuthCredential(params);
    if (initialLmsType === 'canvas') return updateExistingOAuthCredential(params);

    return createOAuthCredentialAndDeleteLtiKey(params);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.url) {
      errors.url = 'Enter URL';
    }

    if (!values.key) {
      errors.key = 'Enter ID';
    }

    if (!values.secret && !canvasCredentials?.id) {
      errors.secret = 'Enter Secret';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={canvasCredentials}
      keepDirtyOnReinitialize
      validate={validate}
      render={({
        handleSubmit, hasSubmitErrors, hasValidationErrors
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div>
            To enable this integration for all of your users,
            provide the API credentials unique to your Canvas instance.
            For instructions, see
            {' '}
            <a
              href="https://teachtci.com/canvas-integration-setup"
              target="_blank"
              rel="noreferrer noopener"
            >
              How to generate Canvas API Credentials
            </a>
          </div>
          <TextField
            name="url"
            placeholder="Enter Domain URL (ex: https://example.instructure.com)"
            label="Domain URL"
            required
            format={value => (value ? value.replace(/\s/g, '') : value)}
          />
          <TextField
            name="key"
            placeholder="Enter ID"
            label="ID"
            required
            format={value => (value ? value.replace(/\s/g, '') : value)}
          />
          <TextField
            name="secret"
            placeholder="Enter Secret"
            label="Secret"
            required
            format={value => (value ? value.replace(/\s/g, '') : value)}
          />
          <CheckConnectionBanner connectionState={connection} connectionType="lms" />
          <Footer
            secondaryButtonCallback={modalManager.close}
            primaryButtonText={footerCTA(connection) || 'Next'}
            primaryButtonDisabled={hasSubmitErrors || hasValidationErrors || submitting}
          />
        </form>
      )}
    />
  );
};

Configuration.propTypes = {
  initialLmsType: PropTypes.string,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired
  }).isRequired,
  next: PropTypes.func,
  subscriberId: PropTypes.number.isRequired
};

Configuration.defaultProps = {
  initialLmsType: null,
  next: () => {},
};

export default Configuration;
