import React from 'react';

interface BulkGradingButtonProps {
  modalManager: {
    close: () => void;
    isOpen: boolean;
    open: () => void;
  };
}

const BulkGradingButton = ({ modalManager }: BulkGradingButtonProps) => (
  <div className="tw-flex tw-justify-center tw-mt-5">
    <button className="btn btn--purple" type="button" onClick={modalManager.open}>
      Bulk Grade
    </button>
  </div>
);

export default BulkGradingButton;
