import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import StampPicker from './StampPicker';
import styles from './VirtualStamps.module.scss';
import VirtualStamp from './VirtualStamp';

const AddVirtualStampButton = ({ selectedStamp, setSelectedStamp }) => {
  const [tippyInstance, setTippyInstance] = useState();

  const setInitialTippyInstance = (instance) => {
    if (tippyInstance) return;

    setTippyInstance(instance);
  };

  const hideTippyOnSelect = () => {
    if (!tippyInstance) return;

    tippyInstance.hide();
  };

  const selectStamp = (icon) => {
    hideTippyOnSelect();
    setSelectedStamp(icon);
  };

  const renderClearButton = () => {
    if (!selectedStamp) return null;

    return (
      <button
        type="button"
        className={styles.removeStampButton}
        onClick={() => selectStamp(null)}
      >
        <i className="fa fa-times" aria-label="Remove Stamp" />
      </button>
    );
  };

  const renderAddStampButton = () => (
    <span className={selectedStamp ? styles.container : ''}>
      <button
        type="button"
        className={`${styles.stampButton} ${selectedStamp ? '' : styles.addStamp}`}
      >
        {selectedStamp ? <VirtualStamp stampName={selectedStamp} /> : 'Add Stamp'}
      </button>
      {renderClearButton()}
    </span>
  );

  return (
    <Tooltip
      content={<StampPicker selectStamp={selectStamp} />}
      theme="white"
      size="medium"
      hideOnClick
      arrow={false}
      trigger="click"
      distance="2"
      placement="bottom-start"
      flip={false}
      onShow={setInitialTippyInstance}
    >
      {renderAddStampButton()}
    </Tooltip>
  );
};

AddVirtualStampButton.propTypes = {
  selectedStamp: PropTypes.string,
  setSelectedStamp: PropTypes.func.isRequired
};

AddVirtualStampButton.defaultProps = {
  selectedStamp: null
};

export default AddVirtualStampButton;
