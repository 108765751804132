import React from 'react';
import Correlations from './Correlations';
import { SectionCorrelationsType } from './types';

interface SectionCorrelationsProps {
  correlations: SectionCorrelationsType;
}

const SectionCorrelations = ({ correlations }: SectionCorrelationsProps) => (
  <div>
    <h2 className="mt30">Student Text</h2>
    {correlations.sections.map((section: any) => {
      const sectionCorrelations = correlations[section.id];
      if (!sectionCorrelations || sectionCorrelations.length === 0) return null;

      return (
        <Correlations
          key={section.id}
          correlations={sectionCorrelations}
          toggleText={section.title}
        />
      );
    })}
  </div>
);

export default SectionCorrelations;
