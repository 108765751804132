import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CollapsibleCard.module.scss';
import { redirectLinks } from '../../../modules/ExternalRedirect';

export default class CollapsibleCard extends Component {
  static propTypes = {
    collapsedContent: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    isInitiallyCollapsed: PropTypes.bool,
    programResources: PropTypes.bool,
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    isInitiallyCollapsed: true,
    programResources: false
  };

  constructor(props) {
    super(props);
    this.state = { isCollapsed: this.props.isInitiallyCollapsed };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(e) {
    e.preventDefault();
    this.setState({ isCollapsed: !this.state.isCollapsed }, () => redirectLinks(this.cardContainer));
  }

  renderCollapsedContent() {
    return (
      <div
        id={`${this.props.title.replace(/ /g, '_')}-card`}
        className={`${styles.collapsedSection} ${this.state.isCollapsed ? styles.collapsed : null}`}
      >
        <hr />
        <div
          className={`respect_breaks allow-list-style ${styles.collapsedText}`}
          dangerouslySetInnerHTML={{ __html: this.props.collapsedContent }}
        />
      </div>
    );
  }

  renderArrow() {
    const arrowClass = this.state.isCollapsed ? 'fa-caret-right' : 'fa-caret-down';

    return (
      <div>
        <i className={`fa ${arrowClass} ${styles.arrow}`} aria-hidden="true" />
      </div>
    );
  }

  renderImage() {
    return <img className={styles.image} src={this.props.imageSrc} alt="" />;
  }

  renderTitleSection() {
    if (this.props.programResources) {
      return (
        <button
          type="button"
          aria-controls={`${this.props.title.replace(/ /g, '_')}-card`}
          aria-expanded={!this.state.isCollapsed}
          className={`${styles.programTitleSection} ${!this.state.isCollapsed ? styles.expanded : null}`}
          onClick={this.toggleCollapse}
        >
          {this.renderArrow()}
          {this.props.imageSrc && this.renderImage()}
          <div>
            <span className={styles.programTitle}>{this.props.title}</span>
            <span>{this.props.description}</span>
          </div>
        </button>
      );
    }

    return (
      <button
        type="button"
        aria-controls={`${this.props.title.replace(/ /g, '_')}-card`}
        aria-expanded={!this.state.isCollapsed}
        className={`${styles.titleSection} ${!this.state.isCollapsed ? styles.expanded : null}`}
        onClick={this.toggleCollapse}
      >
        <div className={styles.title}>{this.props.title}</div>
        {this.renderArrow()}
      </button>
    );
  }

  render() {
    return (
      <div
        className={`card ${styles.content}`}
        ref={(node) => { this.cardContainer = node; }}
      >
        {this.renderTitleSection()}
        {this.renderCollapsedContent()}
      </div>
    );
  }
}
