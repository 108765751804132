import React from 'react';
import { Button, Heading } from 'react-aria-components';
import { ExpandCollapseButton } from '@/components/staffer/PlanningDrawer/ExpandCollapseButton';
import { PlanningContext, TRANSLATIONS, usePlanningDrawer } from './utils';
import { PrintPdfButton } from './PrintPdfButton';

interface PlanningHeaderProps {
  planningContext: PlanningContext;
  printPdfPath: string;
}

export const PlanningHeader = ({ planningContext, printPdfPath }: PlanningHeaderProps) => {
  const { close } = usePlanningDrawer();

  return (
    <Heading className="tw-p-4 tw-border-b tw-border-solid tw-border-med-grey" slot="title">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h2 className="tw-p-0">{TRANSLATIONS.title(planningContext)}</h2>
        <div className="tw-flex tw-items-center tw-gap-4">
          <ExpandCollapseButton />
          <PrintPdfButton printPdfPath={printPdfPath} />
          <Button
            aria-label={TRANSLATIONS.dialog.close}
            className="tw-bg-white tw-outline-none tw-border-none tw-text-xl tw-cursor-pointer"
            slot="close"
            onPress={close}
          >
            ✕
          </Button>
        </div>
      </div>
      <p className="tw-text-dark-grey tw-text-sm tw-mb-0">{TRANSLATIONS.visibilityMessage}.</p>
    </Heading>
  );
};
