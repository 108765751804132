define([], function() {
  function DragDropDropzone(config) {
    this.id = config.id;
    this.parent_slide = config.slide_id;
    this.$node = config.$node;
    this.gradeable = !(this.$node.hasClass('dont_grade_js'));
  }

  DragDropDropzone.prototype = {
    init: function () {
      this.$node.addClass('destination')
        .append('' +
        '<div class="wrong_icon">' +
        '<i class="fa fa-times-circle"></i></div>' +
        '<div class="right_icon">' +
        '<i class="fa fa-check-circle"></i></div>'
      );
    }
  };

  return DragDropDropzone
});