import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { gql } from '@apollo/client';
import { useDataTableFromQuery } from 'common/DataTable';
import RemoveImage from './Remove';
import AddImage from './Add';
import EditImage from './Edit';
import { filterAndRemovePrefix } from '../../common/libraries/common/utils';

const ImageManagerTable = ({ excludeColumns, excludeFields, modelType, modelId }) => {
  const GET_IMAGE_MODELS = gql`
    query ImageModelsTable($modelId: Int!, $modelType: String!) {
      imageModels(modelType: $modelType, modelId: $modelId) {
        id,
        alignment,
        captionEn,
        captionEs,
        decorative,
        displaySize,
        displayStyle,
        hideCaptions,
        snippet,
        zoomType,
        image {
          id,
          credits,
          descriptionConfidence,
          descriptionEn,
          descriptionEs,
          englishImageOnly,
          hasText,
          imageUrl,
          imageEsUrl,
          fileName,
          fileNameEs,
          resolution,
          thumbnail,
          titleEn,
          titleEs
        }
      }
    }
  `;

  const {
    DataTable,
    addResource,
    deleteResource,
    updateResource,
    updateResources,
    resources
  } = useDataTableFromQuery(GET_IMAGE_MODELS, { modelId, modelType }, 'imageModels');

  const formatRowData = rowData => (
    Object.assign(rowData, {
      id: parseInt(rowData.id, 10),
      image: {
        ...filterAndRemovePrefix(rowData, 'image_'),
        imageUrl: rowData.image_imageUrl,
        imageEsUrl: rowData.image_imageEsUrl,
      }
    })
  );

  const columns = [
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          rowData.image_imageUrl && <img width="50px" src={rowData.image_thumbnail} alt="..." />,
          td
        );
      },
      name: 'image_thumbnail',
      targets: 0,
      title: 'Image',
      width: '100px'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={rowData.image_imageUrl}
          >
            {rowData.image_fileName}
          </a>,
          td
        );
      },
      name: 'image_fileName',
      targets: 1,
      title: 'File Name'
    },
    {
      name: 'snippet',
      targets: 2,
      width: '120px'
    },
    {
      name: 'displayStyle',
      targets: 3,
      title: 'Size Used',
      width: '100px'
    },
    {
      name: 'image_resolution',
      targets: 4,
      title: 'Dimensions',
      width: '100px'
    },
    {
      name: 'zoomType',
      targets: 5,
      width: '100px'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <div className="text--center">
            <label>
              <input
                type="checkbox"
                checked={rowData.decorative}
                disabled
              />
              <span className="sr-only">
                Image {rowData.image_fileName} is {rowData.decorative ? '' : 'not '}decorative
              </span>
            </label>
          </div>,
          td
        );
      },
      name: 'decorative',
      targets: -2,
      width: '80px'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <div>
            <EditImage
              excludeFields={excludeFields}
              imageModel={formatRowData(rowData)}
              afterSubmit={updateResource}
              modelType={modelType}
              modelId={modelId}
              updateResources={updateResources}
            />
            <RemoveImage
              imageModelId={parseInt(rowData.id, 10)}
              fileName={rowData.image_fileName}
              afterSubmit={deleteResource}
              standaloneButton
            />
          </div>,
          td
        );
      },
      name: 'actions',
      searchable: false,
      sortable: false,
      targets: -1,
      width: '100px'
    }
  ];

  // const filteredColumns = columns;
  const filteredColumns = columns.filter(c => !excludeColumns.includes(c.name));

  // can be expanded or removed if every instance of the component removes <AddImage/>
  const showAddImage = () => (modelType !== 'ReferenceMaterial');

  return (
    <Fragment>
      {showAddImage() && (
      <AddImage
        afterSubmit={addResource}
        excludeFields={excludeFields}
        modelType={modelType}
        modelId={modelId}
        updateResources={updateResources}
      />
      )}
      <DataTable
        autoWidth={false}
        columns={filteredColumns.map(c => c.name)}
        handleResourcesManually
        isLoading={false}
        resources={resources}
        info={false}
        paging={false}
        columnDefs={filteredColumns}
      />
    </Fragment>
  );
};

ImageManagerTable.propTypes = {
  excludeColumns: PropTypes.arrayOf(PropTypes.oneOf(['displayStyle', 'image_resolution', 'zoomType'])),
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es'])),
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired
};

ImageManagerTable.defaultProps = {
  excludeColumns: [],
  excludeFields: [],
};

export default ImageManagerTable;
