import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './DeleteQuestion.module.scss';

export default function DeleteQuestion({
  deleteQuestionPath
}) {
  return (
    <button
      type="button"
      onClick={() => confirmDeletion(deleteQuestionPath)}
      className={styles.deleteIcon}
    >
      <i className="fa fa-trash-o fa-fw" />
    </button>
  );
}

DeleteQuestion.propTypes = {
  deleteQuestionPath: PropTypes.string.isRequired
};

function confirmDeletion(url) {
  const confirmation = confirm('Are you sure you want to delete this question? ');

  if (confirmation === true) {
    sendDeleteRequest(url);
  }
}

function sendDeleteRequest(url) {
  return axios.delete(url)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
}
