import React from 'react';
import { IIconProps } from './utils';

export const Biographies = ({ fill = 'currentColor' }: IIconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2652 2.24829C9.65682 2.24829 6.73169 5.17342 6.73169 8.78175V11.0276C6.73169 12.1552 7.64579 13.0693 8.7734 13.0693C9.901 13.0693 10.8151 12.1552 10.8151 11.0276V8.78175C10.8151 7.42863 11.912 6.33171 13.2652 6.33171H38.174V29.1988H13.2652C12.3987 29.1988 11.5717 29.3675 10.8151 29.6737V20.0111C10.8151 18.8835 9.901 17.9694 8.7734 17.9694C7.64579 17.9694 6.73169 18.8835 6.73169 20.0111V35.7323C6.73169 39.3406 9.65682 42.2658 13.2652 42.2658H13.4693V44.7158C13.4693 45.8434 14.3834 46.7575 15.511 46.7575C16.6386 46.7575 17.5527 45.8434 17.5527 44.7158V42.2658H33.4781H33.8726H39.0928C40.8405 42.2658 42.2574 40.8489 42.2574 39.1011V31.2405V5.41294C42.2574 3.66515 40.8405 2.24829 39.0928 2.24829H13.2652ZM13.2652 33.2822H38.174V38.1823H33.8726H33.4781H17.5527V35.7323H13.4693V38.1823H13.2652C11.912 38.1823 10.8151 37.0854 10.8151 35.7323C10.8151 34.3792 11.912 33.2822 13.2652 33.2822Z"
      fill={fill}
    />
  </svg>
);
