import { getDateTime } from 'TCIUtils';

export function formatAssignments(assignments) {
  return assignments.map(assignment => formattedAssignment(assignment));
}

export function formattedAssignment(assignment) {
  return ({
    actions: '',
    assignment_label: assignment.assignment_label,
    assignment_location: assignment.assignment_location,
    assignment_models_hash: assignment.assignment_models_hash,
    classes: assignment.classes,
    classroom_assignment: assignment.classroom_assignment,
    connected_to_canvas: assignment.connected_to_canvas,
    connected_to_google: assignment.connected_to_google,
    connected_to_schoology: assignment.connected_to_schoology,
    created_at: assignment.created_at,
    dates_enforced: assignment.dates_enforced,
    direct_link: assignment.direct_link,
    due_datetime: getDateTime(assignment.due_datetime),
    first_lesson_id: assignment.first_lesson_id,
    first_unit_id: assignment.first_unit_id,
    id: assignment.id,
    lms_type: assignment.lms_type,
    name: assignment.name,
    notes: assignment.notes,
    randomize_answers: assignment.randomize_answers,
    start_datetime: getDateTime(assignment.start_datetime),
    status: assignment.status,
    students: assignment.num_students,
    teacher_name: assignment.teacher_name,
    updated_at: assignment.updated_at,
  });
}
