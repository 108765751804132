import React from 'react';
import PropTypes from 'prop-types';
import ComingSoon from './ComingSoon';

const BetaFeatures = ({ active }) => {
  if (!active) return null;

  return (
    <>
      <ComingSoon />
    </>
  );
};

BetaFeatures.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default BetaFeatures;
