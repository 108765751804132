import React from 'react';
import Modal, { Footer } from 'components/common/Modal';
import { capitalize } from 'components/common/Utils';
import Axios from 'axios';
import * as Routes from 'modules/routes';

interface RosterExclusionModalProps {
  closeModal: Function;
  modalIsOpen: Boolean;
  rosterAction: String;
  stafferIds: Set<Number>;
  subscriberId: number;
  updateTable: Function;
}

const RosterExclusionModal = ({ closeModal, modalIsOpen, rosterAction, stafferIds, subscriberId, updateTable } :
                                RosterExclusionModalProps) => {
  const modalText = (
    `Changes ${rosterAction === 'exclude' ? 'will not' : 'may'} be made to these users ` +
    `and their classrooms in the next roster sync if you ${rosterAction} them. ` +
    `Are you sure you want to ${rosterAction} ${stafferIds.size} users?`
  )

  const setRosterExclusion = () => {
    Axios.post(Routes.plato_api_bulk_set_roster_exclusion_subscriber_staffers_path(subscriberId), {
      staffer_ids: Array.from(stafferIds),
      roster_action: rosterAction
    })
      .then((response) => {
        updateTable({ updatedStaff: response.data.staffers })
        closeModal();
      })
  }

  return (
    <Modal isOpen={modalIsOpen} closeModal={closeModal} headerText={`${capitalize(rosterAction)} from Auto-Rostering`}>
      {modalText}
      <Footer
        primaryButtonCallback={setRosterExclusion}
        primaryButtonClassName="btn btn--purple"
        primaryButtonSubmit={false}
        primaryButtonText={`Yes, ${rosterAction}`}
        secondaryButtonCallback={closeModal}
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
}

export default RosterExclusionModal;
