import { useEffect, useRef } from 'react';

interface UseIntervalOptions {
  delay?: number;
  shouldClearEarly?: boolean;
}

const DEFAULT_OPTIONS: UseIntervalOptions = { delay: 1000, shouldClearEarly: false };

const useInterval = (callback, opts: UseIntervalOptions = { delay: 1000, shouldClearEarly: false }) => {
  const { delay, shouldClearEarly } = { ...DEFAULT_OPTIONS, ...opts };

  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    intervalRef.current = setInterval(tick, delay);

    return () => clearInterval(intervalRef.current);
  }, [delay]);

  useEffect(() => {
    if (!shouldClearEarly) return;

    intervalRef.current = undefined;
    clearInterval(intervalRef.current);
  }, [shouldClearEarly]);

  return intervalRef;
};

export default useInterval;
