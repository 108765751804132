import { useCallback } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useFormContext, useWatch } from 'react-hook-form';
import { SchemaType } from '@/components/common/VocabularyCards/variables';
import { debounce } from '@/modules/TCIUtils';

interface UseAutoSaveProps {
  saveFn: () => void;
}

const useAutoSave = ({ saveFn }: UseAutoSaveProps) => {
  const { handleSubmit, formState: { isDirty } } = useFormContext<SchemaType>();
  const data = useWatch();
  const onSave = useCallback(debounce(handleSubmit(saveFn), 800), []);

  useDeepCompareEffect(() => {
    if (!isDirty) return;

    onSave();
  }, [data]);
};

export default useAutoSave;
