import React from 'react';
import { twMerge } from '@/utils';

interface ILinkButtonProps {
  children: ReactChildren,
  className?: string;
  onClick: () => void;
}

const LinkButton = ({ children, className, onClick }: ILinkButtonProps) => (
  <button
    className={twMerge('btn btn--link-purple', className)}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

export default LinkButton;
