import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'common/Modal';
import { useSubmit } from 'common/Forms';
import * as Routes from 'routes';
import NotesForm from './NotesForm';

const NotesModal = ({
  type, afterSubmit, currentSlideId, stafferId, resource, buttonContent, buttonClass
}) => {
  const modal = useModalManager();
  const submitter = useSubmit();

  return (
    <React.Fragment>
      <button type="button" className={buttonClass} onClick={modal.open}>
        {buttonContent}
      </button>
      <Modal isOpen={modal.isOpen} closeModal={modal.close} headerText={type === 'edit' ? 'Edit Note' : 'Add Note'}>
        <Form
          initialValues={resource}
          onSubmit={(values) => {
            values.note = values.note || null;

            submitter.submit(
              type === 'edit' ? 'patch' : 'post',
              type === 'edit' ? Routes.plato_api_slide_status_path(resource.id) : Routes.plato_api_slide_statuses_path(),
              Object.assign(values, { slide_id: currentSlideId, staffer_id: stafferId }),
              (response) => { modal.close(); afterSubmit(response); }
            );
          }}
          render={({ handleSubmit, hasValidationErrors }) => (
            <NotesForm
              onSubmit={handleSubmit}
              closeModal={modal.close}
              hasValidationErrors={hasValidationErrors}
              submitting={submitter.submitting}
            />
          )}
        />
      </Modal>
    </React.Fragment>
  );
};

NotesModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  buttonClass: PropTypes.string.isRequired,
  buttonContent: PropTypes.node.isRequired,
  currentSlideId: PropTypes.number,
  resource: PropTypes.shape({
    id: PropTypes.number,
    note: PropTypes.string,
    status: PropTypes.string
  }),
  stafferId: PropTypes.number,
  type: PropTypes.string.isRequired
};

NotesModal.defaultProps = {
  currentSlideId: null,
  resource: {},
  stafferId: null
};

export default NotesModal;
