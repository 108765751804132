import React from 'react';
import styles from './EditBorderPopover.module.scss';

const MIN = 0;
const MAX = 100;

interface BorderEditorProps {
  activeBorderRadius: string;
  activeWidth: string;
  onChange: (values: {
    width?: string;
    borderRadius?: string
  }) => void;
}

const parseValue = e => Math.max(MIN, Math.min(MAX, parseFloat(e.target.value || '0'))).toString();

const BorderEditor = ({ activeBorderRadius, activeWidth, onChange }: BorderEditorProps) => (
  <div className={styles.container}>
    <div className={styles.input}>
      <label htmlFor="width">Thickness</label>
      <div className="mt5">
        <input
          className={styles.numberInput}
          min={MIN}
          max={MAX}
          type="number"
          name="width"
          onChange={(e) => {
            onChange({ width: parseValue(e) });
          }}
          placeholder=" "
          value={activeWidth || 0}
        />
        <span>px</span>
      </div>
    </div>

    <div className={styles.input}>
      <label htmlFor="borderRadius">Radius</label>
      <div className="mt5">
        <input
          className={styles.numberInput}
          min={MIN}
          max={MAX}
          type="number"
          name="borderRadius"
          onChange={(e) => {
            onChange({ borderRadius: parseValue(e) });
          }}
          placeholder=" "
          value={activeBorderRadius || 0}
        />
        <span>px</span>
      </div>
    </div>
  </div>
);

export default BorderEditor;
