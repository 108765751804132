import React from 'react';
import PropTypes from 'prop-types';
import ModelSelect from './index';
import I18n from 'i18n-js';

const AssessmentSelect = ({ ...props }) => (
  <ModelSelect {...props} selectType="assessment" title={`${I18n.t('assessments')}:`} />
);

AssessmentSelect.propTypes = {
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  lessonId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  resourceUrl: PropTypes.string.isRequired
};

export default AssessmentSelect;
