import React, { useEffect, useMemo, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  NotebooksAndReadingCheckboxesProps,
} from '@/components/staffer/AssignmentModal/WhatSection/Details/NotebooksAndReadingCheckboxes';

interface BulkSelectCheckboxProps extends NotebooksAndReadingCheckboxesProps {
  label: string;
  valueKey: string;
}

const getIsChecked = (valuesToWatch: string[][], valueKey: string) => (
  valuesToWatch.every(valueArr => valueArr && valueArr.join('').includes(valueKey))
);

const getIsIndeterminate = (valuesToWatch, valueKey) => (
  !valuesToWatch.every(valueArr => valueArr && valueArr.join('').includes(valueKey))
  && valuesToWatch.some(valueArr => valueArr && valueArr.join('').includes(valueKey))
);

const BulkSelectCheckbox = ({ assignables, formChangeValue, label, values, valueKey }: BulkSelectCheckboxProps) => {
  const ref = useRef<HTMLInputElement>();

  // Get the value that should be set for each key for the specified value type that we're watching for easy lookup
  const assignableValuesForKey: { [key: string]: string } = useMemo(() => (
    assignables
      .filter(a => a.assignables.map(arr => arr?.value || '').join('').includes(valueKey))
      .reduce((acc, cur) => {
        const key = `notebook-reading-${cur.id}`;
        const value = cur.assignables.find(a => a && a.value.includes(valueKey))?.value;

        return { ...acc, [key]: value || '' };
      }, {})
  ), [assignables]);

  const keysToWatch = useMemo(() => Object.keys(assignableValuesForKey), [JSON.stringify(assignableValuesForKey)]);
  const valuesToWatch = useMemo(() => keysToWatch.map(key => values[key]), [keysToWatch, JSON.stringify(values)]);

  const [isChecked, setIsChecked] = useState(getIsChecked(valuesToWatch, valueKey));
  const [isInDeterminate, setIsInDeterminate] = useState(getIsIndeterminate(valuesToWatch, valueKey));

  useDeepCompareEffect(() => {
    setIsInDeterminate(
      !valuesToWatch.every(valueArr => valueArr && valueArr.join('').includes(valueKey))
      && valuesToWatch.some(valueArr => valueArr && valueArr.join('').includes(valueKey)),
    );
  }, [valuesToWatch]);

  useDeepCompareEffect(() => {
    setIsChecked(getIsChecked(valuesToWatch, valueKey));
  }, [valuesToWatch]);

  useEffect(() => {
    if (!ref?.current) return;

    ref.current.indeterminate = isInDeterminate;
  }, [ref?.current, isInDeterminate]);

  useEffect(() => {
    if (!isChecked) {
      if (isInDeterminate) return;

      assignables.forEach((assignable) => {
        const key = `notebook-reading-${assignable.id}`;
        const value = values[key];

        if (!value) return;

        formChangeValue(value.filter(val => !val.includes(valueKey)), key);
      });
    } else {
      keysToWatch.forEach((key) => {
        const value = values[key];
        const assignable = assignableValuesForKey[key];

        if (!value) {
          formChangeValue([assignable], key);
          return;
        }

        if (!value.join('').includes(valueKey)) {
          formChangeValue([...value, assignable], key);
        }
      });
    }
  }, [isChecked]);

  return (
    <label className="tw-flex tw-items-start tw-p-2 tw-gap-1">
      <input
        className="tw-mt-0.5"
        checked={isChecked}
        onChange={() => setIsChecked(prev => !prev)}
        ref={ref}
        type="checkbox"
        value={valueKey}
      />
      <span className="tw-text-left">{label}</span>
    </label>
  );
};

export default BulkSelectCheckbox;
