import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TTSButton from 'shared/TextToSpeechButton';
import styles from './index.module.scss';
import AssessmentQuestionForm from '../AssessmentQuestionForm';
import MultipleChoiceOption from '../MultipleChoiceOption';
import { checkArrayEquality } from '../../../../modules/TCIUtils';

const ImageMultipleChoiceQuestion = ({ ...props }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(props.savedResult ? props.savedResult.answer.split(',') : []);
  }, []);

  const updateSelectedChoice = (choiceId, isChecked) => {
    setSelected((prevState) => {
      if (!props.multiCorrect) {
        return [choiceId];
      }
      if (isChecked && !prevState.includes(choiceId)) {
        return [...prevState, choiceId];
      }
      const index = prevState.indexOf(choiceId);
      if (index > -1) prevState.splice(index, 1);
      return [...prevState];
    });
  };

  const renderChoiceField = (image, optionLabel, choiceId, onAnswerChange) => (
    <React.Fragment>
      <Field
        component={MultipleChoiceOption}
        optionContainerStyle={styles.optionContainer}
        optionLabelStyle={styles.optionLabel}
        id={`${props.questionId}-${choiceId}`}
        key={`${props.questionId}-${choiceId}`}
        label={`${optionLabel}.`}
        name="answer"
        type={props.multiCorrect ? 'checkbox' : 'radio'}
        value={choiceId}
        isEqual={(a, b) => {
          if (props.multiCorrect) return checkArrayEquality(a, b);
          return a === b;
        }}
        onChange={(e, input) => {
          updateSelectedChoice(choiceId, !input.checked);
          onAnswerChange(e, input);
        }}
      />
      <label htmlFor={`${props.questionId}-${choiceId}`}>
        <img src={image.url} alt="" />
      </label>
    </React.Fragment>
  );

  const renderImageChoices = (onAnswerChange) => {
    const imageChoices = Object.keys(props.options).map((optionLabel) => {
      const option = props.options[optionLabel];
      const choiceId = option.image_choice_value;
      return (
        <div key={`${props.questionId}-${choiceId}`} className={styles.imageChoice}>
          <div
            id={`${props.questionId}-${choiceId}-border`}
            className={`${styles.imageChoiceWrapper} ${selected.includes(choiceId) ? styles.imageChoiceBorder : ''} image-choice-wrapper`}
          >
            {renderChoiceField(option.image, optionLabel, option.image_choice_value, onAnswerChange)}
          </div>
        </div>
      );
    });

    return <ul className={`${styles.imageChoicesContainer} image-choices-container`}>{imageChoices}</ul>;
  };

  const parseValues = values => ({
    answer: values.answer.toString(),
    question_id: values.question_id
  });

  const parseAnswer = (answer) => {
    if (answer && props.multiCorrect) return answer.split(',');
    return answer;
  };

  const renderTTSButton = () => {
    if (!props.questionTTSEnabled) return null;

    return (
      <TTSButton
        locale={props.locale}
        idToRead={`question_${props.questionId}`}
      />
    );
  };

  return (
    <div>
      <AssessmentQuestionForm
        {...props}
        questionContent={({ onAnswerChange }) => renderImageChoices(onAnswerChange)}
        questionType="ImageMultipleChoice"
        parseValues={parseValues}
        parseAnswer={parseAnswer}
      >
        <span
          className={`${!props.isInSlide && 'ml5'} respect_breaks`}
          dangerouslySetInnerHTML={{ __html: props.questionBody }}
        />
        {renderTTSButton()}
      </AssessmentQuestionForm>
    </div>
  );
};

const ImageChoiceShape = PropTypes.shape({ filename: PropTypes.string, url: PropTypes.string });

ImageMultipleChoiceQuestion.propTypes = {
  locale: PropTypes.oneOf(['en', 'EN', 'es', 'ES']).isRequired,
  multiCorrect: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    A: ImageChoiceShape,
    B: ImageChoiceShape,
    C: ImageChoiceShape,
    D: ImageChoiceShape
  }).isRequired,
  preview: PropTypes.bool.isRequired,
  questionBody: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTTSEnabled: PropTypes.bool,
  savedResult: PropTypes.shape({
    answer: PropTypes.string,
    updated_at: PropTypes.string
  }),
};

ImageMultipleChoiceQuestion.defaultProps = {
  questionTTSEnabled: true,
  savedResult: {}
};

export default ImageMultipleChoiceQuestion;
