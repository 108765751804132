import React from 'react';
import * as Routes from '@/modules/routes';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { IExerciseResult, INotebook, IStudent, IStatus } from '../../../types';
import { COLOR_LEGEND } from '../../../variables';
import GradeCell from './index';
import { roundScore } from './utils';

interface UnitProjectGradeCellProps {
  exerciseResult: IExerciseResult;
  student: IStudent;
  unitProject: INotebook;
}

const UnitProjectGradeCell = ({ exerciseResult, student, unitProject }: UnitProjectGradeCellProps) => {
  const programId = useGradesTableStore(state => state.programId);
  const unit = useGradesTableStore(state => state.unit);
  const classroom = useGradesTableStore(state => state.classroom);

  const path = Routes.grade_by_student_shared_question_answers_path(student, {
    program_id: programId,
    unit_id: unit.id,
    classroom_id: classroom.id,
    expand: unitProject.id,
  });

  const unitProjectStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    if (!exerciseResult) return status;

    if (exerciseResult.score) {
      status.text = roundScore(exerciseResult.score);
    } else if (exerciseResult.completed_at) {
      status.text = 'Turned In';
      status.color = COLOR_LEGEND.completed;
    } else {
      status.text = 'In Progress';
      status.color = COLOR_LEGEND.in_progress;
    }

    return status;
  };

  return (
    <GradeCell
      id={`unit-project-${unit.id}-student-${student.id}-score`}
      status={unitProjectStatus()}
      url={path}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default UnitProjectGradeCell;
