import React from 'react';
import { COLOR_LEGEND } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';

const ColorLegend = () => {
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);


  const legendItem = (color, text) => (
    <div className="tw-flex tw-items-center tw-ml-4">
      <div className={`tw-w-5 tw-h-5 tw-inline-block tw-rounded tw-mr-2 ${color}`} />
      <span>{text}</span>
    </div>
  );

  return (
    <div className="tw-flex tw-justify-end">
      {legendItem(COLOR_LEGEND.in_progress, 'In Progress')}
      {legendItem(COLOR_LEGEND.completed, 'Turned In')}
      {lowScoresSettings.enabled && legendItem(COLOR_LEGEND.low_score, 'Low Score')}
    </div>
  );
};

export default ColorLegend;
