import { MouseEventHandler, useLayoutEffect, useState } from 'react';
import I18n from '@/modules/i18n';

const useQuestionLocaleToggleListener = ({
  modelId,
  forAnswerKey = false,
  modelType = null
}) => {
  const [isInSpanish, setIsInSpanish] = useState(I18n.locale === 'es');

  useLayoutEffect(() => {
    const handler: MouseEventHandler<HTMLSpanElement> = (e) => {
      e.stopPropagation();

      if (!(e.target as HTMLSpanElement).classList.contains('tab')) return;

      const text = (e.target as HTMLSpanElement).innerText || (e.target as HTMLSpanElement).innerHTML;

      setIsInSpanish(text.toLowerCase().includes('spanish'));
    };

    const selector = modelType === 'QuestionGroup' ?
                       `#question_group_${modelId} .tabs` :
                       `#question-${modelId}-form ${forAnswerKey ? '.sample-answer' : ''} .tabs`;

    const tabsContainer: HTMLDivElement = document.querySelector(selector);

    // @ts-ignore
    tabsContainer.addEventListener('click', handler);

    // @ts-ignore
    return () => tabsContainer.removeEventListener('click', handler);
  }, [modelId]);

  return { isInSpanish };
};

export default useQuestionLocaleToggleListener;
