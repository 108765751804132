import React, { useContext } from 'react';
import BuilderContext from 'common/Context';
import Dropdown from '../../../../../Dropdown';

const DropZoneButton = () => {
  const { dropZoneDispatch, isEditingLabels, setIsEditingLabels, setIsCopying, dropZoneShape } = useContext(BuilderContext);
  const handleAddDropZone = () => dropZoneDispatch({ shape: dropZoneShape, type: 'ADD' });
  const handleAddKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    dropZoneDispatch({ shape: dropZoneShape, type: 'ADD' });
  };

  return (
    <div className="inline_block">
      <Dropdown buttonClass="btn btn--sm btn--green dropdown-toggle" toggleText="Drop Zone">
        <button
          className="dropdown-item block full_width cursor_point"
          data-closeonclick
          type="button"
          onClick={handleAddDropZone}
          onKeyDown={handleAddKeyDown}
        >
          <i className="fa fa-plus" />
          Add Drop Zone
        </button>
        <button
          className="dropdown-item block full_width cursor_point"
          data-closeonclick
          onClick={() => setIsEditingLabels(prev => !prev)}
          type="button"
        >
          <i className="fa fa-edit" />
          {isEditingLabels ? 'Exit Edit Mode' : 'Edit Dropzone Labels'}
        </button>
        <button
          className="dropdown-item block full_width cursor_point"
          data-closeonclick
          onClick={() => setIsCopying(prev => !prev)}
          type="button"
        >
          <i className="fa fa-copy" />
          Copy Drop Zone
        </button>
      </Dropdown>
    </div>
  );
};

export default DropZoneButton;
