import React from 'react';
import cn from '@/utils/cn';

type GridCols = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type Width = StringWithPrefix<'tw-w-', string>
| StringWithPrefix<'tw-min-w-', string>
| StringWithPrefix<'tw-grid-cols-', GridCols>;

interface SkeletonProps {
  bgColor?: StringWithPrefix<'tw-bg-', string>;
  className?: string;
  count?: number;
  dir?: 'tw-flex-row' | 'tw-flex-col';
  gap?: StringWithPrefix<'tw-gap-', string>;
  height?: StringWithPrefix<'tw-h-', string> | StringWithPrefix<'tw-min-h-', string>;
  rounded?: StringWithPrefix<'tw-rounded-', string> | 'tw-rounded';
  width?: Width;
}

const Skeleton = ({
  bgColor = 'tw-bg-gray-200',
  className,
  count = 1,
  dir = 'tw-flex-col',
  gap = 'tw-gap-1',
  height = 'tw-h-4',
  rounded = 'tw-rounded',
  width = 'tw-w-full',
}: SkeletonProps) => (
  <div className={cn('tw-flex', dir, gap)}>
    {new Array(count).fill(null).map(() => (
      <div
        className={cn(
          'tw-animate-pulse',
          bgColor,
          height,
          rounded,
          width,
          className,
        )}
      />
    ))}
  </div>
);

export default Skeleton;
