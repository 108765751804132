import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import AddModal from './AddModal';

export default class Add extends Component {
  static propTypes = {
    states: PropTypes.instanceOf(Array),
    createPath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired,
    wrapperClass: PropTypes.string,
    buttonClass: PropTypes.string
  };

  static defaultProps = {
    states: [],
    wrapperClass: '',
    buttonClass: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div className={this.props.wrapperClass}>
        <Button handleClick={this.openModal} buttonClass={this.props.buttonClass} />

        <AddModal
          closeModal={this.closeModal}
          states={this.props.states}
          modalIsOpen={this.state.modalIsOpen}
          updateTable={this.props.updateTable}
          createPath={this.props.createPath}
        />
      </div>
    );
  }
}
