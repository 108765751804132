import React from 'react';
import I18n from 'i18n-js';
import { ActivityCard, ReadingCard, SectionVideoCard } from '@/components/shared/LessonOverview/Cards';
import TCISlideShows from './TCISlideShows';
import { readingPath, sectionsWithLessonVideos } from '../utils';
import { Lesson, Program, SectionType, StudentSlideShows } from '../types';
import TCICreatedSlideShowCards from './TCICreatedSlideShowCards';
import AssignedSlideShowCards from './AssignedSlideShowCards';

interface FlexibleLessonOptionsProps {
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  showArchived: boolean;
  slideShows: StudentSlideShows;
  stafferId: number;
}

const FlexibleLessonOptions = ({
  lesson,
  program,
  sections,
  showArchived,
  slideShows,
  stafferId,
}: FlexibleLessonOptionsProps) => (
  <>
    {/* Text with Notes/Reading card */}
    <ReadingCard
      bannerBorderColor="green"
      bannerLabel="reading"
      lessonId={lesson.id}
      linkLabel={program.referenceTextLabel}
      readingPath={
        readingPath(
          sections.filter(s => s.kind === 'default' && s.title.includes('Introduction'))[0],
          program,
          sections,
        )
      }
      program={program}
      sections={sections.filter(s => s.kind === 'default' && s.investigation === false)}
      type="reading"
    >
      {I18n.t('lesson_overview_reading_description')}
    </ReadingCard>

    <TCISlideShows
      lesson={lesson}
      program={program}
      slideShows={slideShows}
    />

    {slideShows.modules.map(slideShow => (
      <ActivityCard
        key={slideShow.id}
        slideShow={slideShow}
        programId={program.id}
        lessonId={lesson.id}
      />
    ))}

    {Object.values(sectionsWithLessonVideos(sections)).map(section => (
      <SectionVideoCard
        key={section.id}
        lessonId={lesson.id}
        program={program}
        readingPath={readingPath(section, program, sections)}
        section={section}
        stafferId={stafferId}
      />
    ))}

    <TCICreatedSlideShowCards
      lesson={lesson}
      program={program}
      slideShows={slideShows}
    />

    <AssignedSlideShowCards
      lesson={lesson}
      program={program}
      slideShows={slideShows}
      tci
    />

    <AssignedSlideShowCards
      lesson={lesson}
      program={program}
      slideShows={slideShows}
    />
  </>
);

export default FlexibleLessonOptions;
