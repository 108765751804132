import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager, Footer } from 'common/Modal';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import { SubmitError } from 'common/Forms/Utils';

const ManualMergeWrapper = ({ selectedImage, detectedDuplicateImage, onRemove }) => (
  <ApolloProvider client={apolloClient}>
    <ManualMerge
      selectedImage={selectedImage}
      detectedDuplicateImage={detectedDuplicateImage}
      onRemove={onRemove}
    />
  </ApolloProvider>
);

ManualMergeWrapper.propTypes = {
  detectedDuplicateImage: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedImage: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired
};

const MERGE_DUPLICATE_IMAGES = gql`
  mutation ManuallyMergeDuplicateImages($imageIds: [ID!]!) {
    mergeDuplicateImages(imageIds: $imageIds) {
      result {
        bestImage {
          id
          smallUrl
          fileName
          imageUrl
        }
      }
    }
  }
`;

const ManualMerge = ({
  selectedImage, detectedDuplicateImage, onRemove
}) => {
  const modalManager = useModalManager();
  const [manuallyMergeImagesMutation, { loading }] = useMutation(MERGE_DUPLICATE_IMAGES);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [mergedImageId, setMergedImageId] = useState(null);

  if (mergedImageId) {
    return (
      <p>Merged into Image ID {mergedImageId}</p>
    );
  }

  const mergeImages = () => {
    const imageIds = [parseInt(selectedImage.id, 10), parseInt(detectedDuplicateImage.id, 10)];

    manuallyMergeImagesMutation({ variables: { imageIds: imageIds } })
      .then((result) => {
        setMergedImageId(result.data.mergeDuplicateImages.result.bestImage.id);
        onRemove(detectedDuplicateImage, result.data.mergeDuplicateImages.result.bestImage);
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setHasSubmitError(true);
      });
  };

  const imageInfo = image => (
    <div key={image.id} className="mb20">
      <img width="150px" src={image.imageUrl} alt="..." />
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={image.imageUrl}
        >
          {image.fileName}
        </a>
      </div>
    </div>
  );

  const modalText = (
    <div>
      <div className="mb20">
        <h2>Image:</h2>
        {imageInfo(selectedImage)}
      </div>

      <div>
        <h2>Detected Duplicate:</h2>
        {imageInfo(detectedDuplicateImage)}
      </div>
    </div>
  );

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn--sm btn--outline-purple mb5"
        onClick={modalManager.open}
      >
        Merge Images
      </button>
      <Modal
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        headerText="Manually Merge Images"
      >
        {modalText}
        {hasSubmitError && <SubmitError error="Error merging images. Please reload the page and try again." />}
        <Footer
          primaryButtonText="Merge Images"
          secondaryButtonText="Don't Merge"
          primaryButtonCallback={mergeImages}
          primaryButtonSubmit={false}
          secondaryButtonCallback={close}
          submitting={loading}
        />
      </Modal>
    </Fragment>
  );
};

ManualMerge.propTypes = {
  detectedDuplicateImage: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedImage: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired
};

export default ManualMergeWrapper;
