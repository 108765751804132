import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default function FinalFormSelect({ input, ...rest }) {
  return (
    <Select {...input} {...rest} value={rest.selectValue} />
  );
}

FinalFormSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    selectValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ])
  }).isRequired
};
