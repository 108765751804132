import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../../ProgressHeader.module.scss';

import * as constants from '../../steps';
import {
  getStepImage, SrOnlyStepStatus, Steps
} from '../../Utils';

export default class ProgressHeader extends Component {
  static propTypes = {
    completedSteps: constants.completedStepsPropTypes.isRequired,
    headerImages: PropTypes.shape({
      number: PropTypes.shape({
        completed: PropTypes.string,
        pending: PropTypes.string,
      })
    }),
    labels: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.number)
  };

  static defaultProps = {
    page: 0
  };

  constructor(props) {
    super(props);

    this.renderStep = this.renderStep.bind(this);
  }

  _imageClass(page) {
    if (this._page() === page || this.props.completedSteps[page]) {
      return styles.activeImg;
    }

    return '';
  }

  _activeCaptionClass(page) {
    if (this._page() === page || this.props.completedSteps[page]) {
      return styles.activeCaption;
    }

    return '';
  }

  _currentStepClass(page) {
    if (this._page() === page) {
      return styles.currentStep;
    }

    return '';
  }

  _page() {
    // We need to offset the page since the first step
    // is no longer part of the wizard
    return this.props.page + 1;
  }

  renderStep(step, text) {
    return (
      <div className={styles.headerImageContainer} key={text}>
        <img
          alt=""
          className={`${styles.headerImage} ${this._imageClass(step)}`}
          src={getStepImage(
            this.props.headerImages,
            this.props.completedSteps[step],
            step
          )}
        />
        <span
          className={`${styles.caption} ${this._activeCaptionClass(step)} ${this._currentStepClass(step)}`}
        >
          {text} <SrOnlyStepStatus isStepCompleted={this.props.completedSteps[step]} />
        </span>
      </div>
    );
  }

  render() {
    return (
      <Steps
        wrapperClass={styles.header}
        renderStep={this.renderStep}
        divider={<div key="divider" className={styles.dottedLine} />}
        steps={this.props.steps}
        labels={this.props.labels}
      />
    );
  }
}
