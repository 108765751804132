import React from 'react';
import clsx from 'clsx';
import { IStatus } from '@/components/staffer/Scores/GradesTable/types';
import styles from './GradesCells.module.scss';

interface GradeCellProps {
  id: string,
  label?: string,
  status: IStatus,
  url: string,
  updatedSinceGraded?: boolean,
}

const GradeCell = ({ id, label, status, url, updatedSinceGraded }: GradeCellProps) => (
  <div className={clsx(`tw-flex tw-flex-col tw-justify-center tw-w-full tw-h-full ${status.color}`)}>
    <a id={id} aria-label={label} href={url} className={status.color}>
      {status.text}
    </a>
    {updatedSinceGraded && <span className={styles.updatedSinceGraded}>Updated!</span>}
  </div>
);

export default GradeCell;
