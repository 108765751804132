/* istanbul ignore file */
/* eslint-disable */
import EarthSciencesIcon from './EarthSciencesIcon';
import EconomicsIcon from './EconomicsIcon';
import EngineeringIcon from './EngineeringIcon';
import GeographyIcon from './GeographyIcon';
import GovernmentIcon from './GovernmentIcon';
import HistoryIcon from './HistoryIcon';
import LifeSciencesIcon from './LifeSciencesIcon';
import LinesIcon from './LinesIcon';
import PhysicalSciencesIcon from './PhysicalSciencesIcon';
import ShapesIcon from './ShapesIcon';

// TODO: add translations
const icons = {
  0: LinesIcon,
  1: ShapesIcon,
  2: HistoryIcon,
  3: GeographyIcon,
  4: EconomicsIcon,
  5: GovernmentIcon,
  6: LifeSciencesIcon,
  7: EarthSciencesIcon,
  8: PhysicalSciencesIcon,
  9: EngineeringIcon,
};

export default icons;
