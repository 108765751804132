import React from 'react';
import PropTypes from 'prop-types';

const JumpToButton = ({ buttonText, destinationSlide }) => (
  <span>
    <span contentEditable={false} data-button-type="jump" data-jump-to={destinationSlide.value}>
      <span
        className="btn btn--purple jump-btn"
        data-button-type="jump"
        data-jump-to={destinationSlide.value}
        role="button"
      >
        {buttonText}
      </span>
    </span>
  </span>
);

JumpToButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  destinationSlide: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default JumpToButton;
