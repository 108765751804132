import React from 'react';
import useLessonOverview from '@/hooks/api/useLessonOverview';
import Header from '@/components/shared/NewLessonOverview/Header';
import AssessWith from '@/components/shared/NewLessonOverview/AssessWith';
import ShowSlideshowCheckbox from '@/components/shared/NewLessonOverview/ShowSlideshowCheckbox';
import DiveDeeper from './DiveDeeper';
import Libraries from './Libraries';

type NewLessonOverViewProps = {
  civicsLibrary: boolean;
  hasAssessments: boolean;
  lessonId: number;
  locale: string;
  primarySourceLibrary: boolean;
  programId: number;
  stafferId?: number;
  unitColor: string;
  unitId: number;
};

const NewLessonOverview = ({
  civicsLibrary, hasAssessments, lessonId, locale, primarySourceLibrary, programId, stafferId, unitColor, unitId,
}: NewLessonOverViewProps) => {
  const { lesson, program, sections, isLoading } = useLessonOverview({ programId, lessonId, locale });

  if (isLoading) return null;
  const isStaffer = !!stafferId;

  return (
    <>
      <Header
        lessonTitle={lesson?.title}
        lessonPosition={lesson?.titleNumber}
        unitColor={unitColor}
      />
      {isStaffer && <ShowSlideshowCheckbox />}
      <div>
        {program && (
          <AssessWith
            hasAssessments={hasAssessments}
            lesson={lesson}
            program={program}
            sections={sections}
            stafferId={stafferId}
            unitColor={unitColor}
          />
        )}
      </div>
      <footer className="tw-w-full tw-h-[300px] tw-bg-light-grey tw-flex">
        <DiveDeeper
          lesson={lesson}
          stafferId={stafferId}
          programId={programId}
          sections={sections}
        />
        <Libraries
          programId={programId}
          lessonId={lessonId}
          unitId={unitId}
          civicsLibrary={civicsLibrary}
          primarySource={primarySourceLibrary}
        />
      </footer>
    </>
  );
};

export default NewLessonOverview;
