import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQueries } from '@tanstack/react-query';
import useAssessments from '../useAssessments';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { IQuestionAnswer } from '../../../types';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';

const useAssessmentQuestionAnswers = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const isEnabled = !!(classroom && lesson);

  const { data: assessments } = useAssessments(classroom.id, lesson?.id);

  return useQueries({
    queries: assessments.map(assessment => ({
      queryKey: [QueryKeys.AssessmentQuestionAnswers, assessment.id, classroom.id],
      queryFn: () => {
        const path = Routes.plato_api_assessment_progress_question_answers_path({
          assessment_id: assessment.id,
          classroom_id: classroom.id,
          only: ['id', 'student_id'],
        });

        return axios
          .get<{ data: IQuestionAnswer[] }>(path)
          .then(res => res.data.data)
          .catch(err => console.error(err));
      },
      enabled: isEnabled,
    })),
  });
};

export default useAssessmentQuestionAnswers;
