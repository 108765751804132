import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import { useQueryClient } from '@tanstack/react-query';
import { COLOR_LEGEND } from '../../../variables';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { IAssessment, IQuestionAnswer, IStatus, IStudent } from '../../../types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useAssessmentExerciseResults from '../../../hooks/queries/exerciseResults/useAssessmentExerciseResults';
import GradeCell from './index';
import { roundScore } from './utils';

interface AssessmentGradeCellProps {
  student: IStudent;
  assessment: IAssessment;
}

export const AssessmentGradeCell = ({ student, assessment }: AssessmentGradeCellProps) => {
  const programId = useGradesTableStore(state => state.programId);
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);
  const { data: exerciseResults } = useAssessmentExerciseResults(classroom, lesson);

  const queryClient = useQueryClient();

  const queryData = (
    queryClient.getQueryData([QueryKeys.AssessmentQuestionAnswers, assessment.id, classroom.id]) || []
  ) as IQuestionAnswer[];

  const exerciseResult = useMemo(() => (
    exerciseResults.find(er => (
      er.student_id === student.id && er.exercise_type === 'Assessment' && er.exercise_id === assessment.id
    ))
  ), [exerciseResults]);

  const gradePath = useMemo(() => (
    Routes.grade_by_student_shared_question_answers_path(student, {
      program_id: programId,
      lesson_id: lesson.id,
      classroom_id: classroom.id,
      assessment_id: assessment.id,
    })
  ), [programId, lesson, classroom, assessment]);

  const getAssessmentStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    // If an exercise result is present and has been completed.
    if (exerciseResult && exerciseResult.completed) {
      const graded: boolean = !!exerciseResult.graded_at && !!exerciseResult.score;

      if (!graded) {
        status.text = 'Turned In';
        status.color = COLOR_LEGEND.completed;
        return status;
      }

      const shouldHighlight = lowScoresSettings.enabled
        && parseInt(exerciseResult.score, 10) <= (assessment.total_points_possible * lowScoresSettings.threshold / 100);

      status.text = roundScore(exerciseResult.score);
      status.color = shouldHighlight ? COLOR_LEGEND.low_score : '';

      return status;
    }

    // If the exercise hasn't been graded yet and has answered questions, it's in progress.
    const hasAnswers = queryData.some(qa => (qa.student_id === student.id));

    if (hasAnswers) {
      status.color = COLOR_LEGEND.in_progress;
      status.text = 'In Progress';
    }

    // Else, the assessment has yet to be started.
    return status;
  };

  const assessmentStatus = getAssessmentStatus();

  return (
    <GradeCell
      id={`assessment-${assessment.id}-student-${student.id}-score`}
      label={`View/Grade assessment score for ${student.first_name} ${student.last_name}`}
      status={assessmentStatus}
      url={gradePath}
      updatedSinceGraded={exerciseResult?.updated_since_graded}
    />
  );
};

export default AssessmentGradeCell;
