import React from 'react';
import { expiringYear, IProgram } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import { programIsExpired } from '@/components/common/Utils';
import tciLogo from '../../../../../images/tci-logo.svg';

export const Thumbnail = ({ url }: { url: string }) => (
  <img
    className="tw-w-[50px] tw-min-w-[50px] tw-h-[50px] tw-bg-white tw-overflow-auto"
    src={url || tciLogo}
    alt="Program thumbnail"
  />
);

const ProgramCell = ({ program }: { program: IProgram }) => (
  <div className="tw-flex tw-items-center tw-gap-2">
    <Thumbnail url={!program.small_url.includes('missing.png') && program.small_url} />

    <span>
      <span dangerouslySetInnerHTML={{ __html: program.full_title_with_edition }} />
      {program.retirement_year === expiringYear && (
        <>
          <br />
          <span className="red mt5">
            Unavailable June 30, {expiringYear}.&nbsp;
            <a
              href="https://teachtci.com/retiring-program"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn More
            </a>
          </span>
        </>
      )}
      {programIsExpired(program.retirement_year) && (
        <>
          <br />
          <span className="red mt5">
            Retired edition.&nbsp;
            <a
              href="https://help.teachtci.com/s/article/Edition-Retirement"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn More
            </a>
          </span>
        </>
      )}
    </span>
  </div>
);

export default ProgramCell;
