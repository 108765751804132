import React from 'react';
import PropTypes from 'prop-types';
import GoogleSettings from 'common/GoogleSettings';
import CanvasLti13Settings from 'shared/CanvasLti13Settings';
import CanvasSettings from 'shared/CanvasSettings';
import SchoologySettings from 'shared/SchoologySettings';

import styles from './IntegrationOptions.module.scss';

const IntegrationOptions = ({
  active, canvasConnectionData, canvasSubscriberConnected, googleConnectionData, updateState,
  userType, subscriberId, programId, connectedToCanvas1_3, connectedToSchoology1_3
}) => {
  if (!active) return null;

  return (
    <div className={styles.integrationsWrapper}>
      <GoogleSettings
        active
        connectionData={googleConnectionData}
        updateState={updateState}
        useCardFormat
        userType={userType}
        programId={programId}
      />

      {canvasSubscriberConnected && (
        <CanvasSettings
          subscriberConnected={canvasSubscriberConnected}
          connectionData={canvasConnectionData}
          subscriberId={subscriberId}
          updateState={updateState}
          userType={userType}
          programId={programId}
        />
      )}

      {/* eslint-disable-next-line camelcase */}
      {!connectedToSchoology1_3 && (
        // eslint-disable-next-line camelcase
        <CanvasLti13Settings connectedToCanvas1_3={connectedToCanvas1_3} programId={programId} />
      )}

      {/* eslint-disable-next-line camelcase */}
      {!connectedToCanvas1_3 && (
        // eslint-disable-next-line camelcase
        <SchoologySettings connectedToSchoology1_3={connectedToSchoology1_3} programId={programId} />
      )}

      <div className={styles.lineBreak} />
      <div className={styles.integrationsFooter}>
        <a href="https://teachtci.com/lms-integrations">Learn more about TCI&apos;s integrations</a>
      </div>
    </div>
  );
};

IntegrationOptions.propTypes = {
  active: PropTypes.bool.isRequired,
  canvasConnectionData: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    user_canvas_id: PropTypes.string
  }).isRequired,
  canvasSubscriberConnected: PropTypes.bool.isRequired,
  connectedToCanvas1_3: PropTypes.bool.isRequired,
  connectedToSchoology1_3: PropTypes.bool.isRequired,
  googleConnectionData: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    user_google_id: PropTypes.number
  }).isRequired,
  programId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateState: PropTypes.func.isRequired,
  userType: PropTypes.string
};

IntegrationOptions.defaultProps = {
  userType: 'Student'
};

export default IntegrationOptions;
