import { components as Components, SingleValueProps } from 'react-select-5';
import React from 'react';

const SingleValue = ({ children, ...props } : SingleValueProps) => (
  <Components.SingleValue
    {...props}
    className="tw-h-full tw-absolute tw-leading-none tw-flex tw-items-center"
  >
    {children}
  </Components.SingleValue>
);

export default SingleValue;
