import React from 'react';
import styles
  from '@/components/admin/SlideShow/Builder/BackgroundDropdownActions/BackgroundDropdownActions.module.scss';
import BackgroundAudioModal from '@/components/admin/SlideShow/Builder/BackgroundAudioButton/BackgroundAudioModal';
import { Slide } from '../CreateTransistionGroup/utils';

interface AudioOptionsProps {
  afterSubmit: (arg) => void;
  modalManager: ModalManager;
  slide: Slide
}

const ManageAudioOption = ({ afterSubmit, modalManager, slide }: AudioOptionsProps) => {
  const hasAudio = slide.backgroundAudioUrl !== '/missing.png';
  const actionText : string = hasAudio ? 'Edit Background Audio' : 'Add Background Audio';

  return (
    <>
      {modalManager.isOpen && <BackgroundAudioModal
        actionText={actionText}
        afterSubmit={afterSubmit}
        slide={slide}
        modalManager={modalManager}
      />}
      <button type="button" className={`${styles.dropdownItem} dropdown-item`} onClick={modalManager.open}>
        {actionText}
      </button>
    </>
  );
};

export {
  ManageAudioOption,
};
