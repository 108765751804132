import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDataTable } from '@/components/common/DataTable';
import UploadPdfButton from '../UploadPdfButton';
import PdfPoller from '../UploadPdfButton/PdfPoller';
import { ProgramIndexType } from './types';
import DeleteBtn from './DeleteBtn';

interface IndexCsvTableProps {
  initialResources: ProgramIndexType[];
  programId: number;
}

const IndexCsvTable = ({ programId, initialResources }: IndexCsvTableProps) => {
  const [resources, setResources] = useState(initialResources);

  const {
    DataTable,
  } = useDataTable();

  const renderLocale = (locale) => {
    if (locale === 'en') {
      return <>English</>;
    }

    return <>Spanish</>;
  };

  const columns = [
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <UploadPdfButton
            programId={programId}
            programIndexPdfUpload={rowData}
            setResources={setResources}
            resources={resources}
          />,
          td,
        );
      },
      name: 'fileName',
      targets: 0,
      title: 'PDF Name',
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          renderLocale(rowData.locale),
          td,
        );
      },
      name: 'locale',
      targets: 1,
      title: 'Index Language',
    },
    {
      createdCell: (td, cellData, rowData) => {
        const updateResources = () => {
          setResources(prev => prev.map((resource) => {
            if (resource.locale === rowData.locale) {
              return ({
                id: null,
                fileName: null,
                uploadStatus: null,
                pdfUrl: null,
                resultCsvUrl: null,
                locale: rowData.locale,
              });
            }

            return resource;
          }));
        };

        ReactDOM.render(
          <div>
            <PdfPoller programIndexPdfUploadId={rowData.id} />
            {rowData.id && (
              <DeleteBtn
                id={rowData.id}
                onClick={updateResources}
              />
            )}
          </div>,
          td,
        );
      },
      name: 'actions',
      searchable: false,
      sortable: false,
      targets: -1,
      title: 'Actions',
      width: '100px',
    },
  ];

  return (
    <DataTable
      autoWidth={false}
      columns={columns.map(c => c.name)}
      handleResourcesManually
      isLoading={false}
      resources={resources}
      info={false}
      paging={false}
      columnDefs={columns}
      defaultOrder={[[1, 'asc']]}
    />
  );
};

export default IndexCsvTable;
