import React, { useEffect, useRef, useState } from 'react';
import { CheckboxField } from '@/components/common/Forms';
import CaptionOverwriteWarning from '@/components/admin/Videos/FormCheckboxFields/CaptionOverwriteWarning';

interface NeedsCaptionFieldProps {
  hasVideoAttachment: boolean;
  hasCaptionAttachment: boolean;
  needsCaptions: boolean;
  type: 'add' | 'edit';
  form: any;
  locale: 'en' | 'es';
  orderIsInProgress: boolean;
}

export const NeedsCaptionField = ({
  needsCaptions,
  hasVideoAttachment,
  hasCaptionAttachment,
  type,
  form,
  locale,
  orderIsInProgress,
}: NeedsCaptionFieldProps) => {
  const needsCaptionsLabel = locale === 'es' ? 'needs_captions_es' : 'needs_captions';
  const isFirstRender = useRef(true);
  const [isChecked, setIsChecked] = useState(needsCaptions);

  const isDisabled = () => {
    if (orderIsInProgress) return true; // Don't allow new order if there's one in progress
    if (type === 'add' && locale === 'en') return false; // Never disable when adding a new English video
    if (locale === 'es' && form.getState().values?.english_video_only) return true; // Disable for Spanish if "English Only" is checked
    if (!hasVideoAttachment) return true; // Can't request captions if there's no video file
    return false;
  };

  useEffect(() => {
    // Set the parent form's needs_captions / needs_captions_es value
    if (form) form.change(needsCaptionsLabel, isChecked);
  }, [isChecked]);

  useEffect(() => {
    // Ignore the first file change event, since this is just the
    // initial render and not a new user uploaded file
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // Automatically check the boxes when a new file is uploaded
    if (!isDisabled() && hasVideoAttachment) setIsChecked(true);
  }, [hasVideoAttachment]);

  return (
    <div className="tw-inline-flex">
      <CheckboxField
        disabled={isDisabled()}
        checked={isChecked}
        onChange={() => setIsChecked(prevState => !prevState)}
        name={needsCaptionsLabel}
        label="Request Captions"
        tooltipIcon="info"
        tooltipText="Videos marked as Needs Captions will automatically be sent to a service for caption file generation."
      />
      {isChecked && hasCaptionAttachment && (
        <CaptionOverwriteWarning className="tw-inline-flex tw-items-center tw-mx-6" />
      )}
    </div>
  );
};

export default NeedsCaptionField;
