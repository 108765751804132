/**
 * Renders a table component that is commonly used on sysadmin views
 * props:
 *   containerClass (string): the class that will be applied to the container div
 *          tableId (string): the id that will be applied to the table
 *           columns (array): expects an array of objects in the following format:
 *                            [{ text: 'Header Text', className: 'Header Class Name' }…]
 *                            renders a thead row with text/class names
 *                  children: expects <tr> components that comprise the tbody
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import Filter from '../Filters';
import styles from './SysadminTable.module.scss';

export default function SysadminTable(props) {
  const children = () => {
    if (props.isLoading) {
      return (
        <tr>
          <td className={styles.loading} colSpan={props.columns.length}>
            <i className="fa fa-spinner fa-spin" />
          </td>
        </tr>
      );
    }
    else if (props.children.length === 0) {
      return (
        <tr>
          <td className={styles.empty} colSpan={props.columns.length}>
            {props.emptyMessage}
          </td>
        </tr>
      );
    }
    return props.children;
  };

  const handleFilterChange = (filter) => {
    props.filterChange(filter);
  };

  const renderFilter = filter => (
    <Filter
      name={filter.name}
      options={filter.options}
      selectAllOption={filter.selectAll}
      handleOptionChange={handleFilterChange}
    />
  );

  const renderHeaderCell = column => (
    <th key={column.text} className={`${styles.headerCell} ${column.className}`}>
      {column.text} {(column.filter && Object.keys(column.filter).length > 0) ? renderFilter(column.filter) : ''}
    </th>
  );

  return (
    <div className={props.containerClass}>
      {props.buttonAbove}
      <div className="clear" />
      <div className="tablebox mt1em">
        <table className="tabular_data" id={props.tableId}>
          <thead>
            <tr>
              {props.columns.map(column => renderHeaderCell(column))}
            </tr>
          </thead>
          <tbody>
            {children()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

SysadminTable.propTypes = {
  containerClass: PropTypes.string,
  isLoading: PropTypes.bool,
  tableId: PropTypes.string
};

SysadminTable.defaultProps = {
  containerClass: 'sysadmin-table-container',
  tableId: 'sysadmin-table',
  buttonAbove: null,
  columns: [],
  filterChange: null
};
