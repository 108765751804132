import React from 'react';
import I18n from 'i18n-js';
import Modal, { Footer } from '@/components/common/Modal';
import useSlideShowStore, { ISlide } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';

interface UnsavedChangesConfirmationModalProps {
  destinationSlide: ISlide;
  modalManager: ModalManager;
  setSelectedSlide: (ISlide) => void;
}

const UnsavedChangesConfirmationModal = ({
  destinationSlide, modalManager, setSelectedSlide,
}: UnsavedChangesConfirmationModalProps) => {
  const setCurrentSlide = useSlideShowContext(state => state.setCurrentSlide);
  const setHasUnsavedChanges = useSlideShowStore(state => state.setHasUnsavedChanges);

  const closeModal = () => {
    setHasUnsavedChanges(false);
    modalManager.close();
  };

  const footer = () => (
    <Footer
      primaryButtonCallback={() => {
        setSelectedSlide(destinationSlide);
        setCurrentSlide(destinationSlide);
        closeModal();
      }}
      primaryButtonSubmit={false}
      secondaryButtonCallback={closeModal}
      wrapperClassName="tw-pr-4 tw-pb-4"
      primaryButtonClassName="btn btn--purple"
      primaryButtonText={I18n.t('leave_slide')}
    />
  );

  return (
    <Modal
      isOpen={modalManager.isOpen}
      closeModal={closeModal}
      headerText={I18n.t('leave_slide')}
      footer={footer()}
    >
      <div>
        <p>Changes you made on the slide are currently being saved.</p>
        <p>Are you sure you want to leave the slide?</p>
      </div>
    </Modal>
  );
};

export default UnsavedChangesConfirmationModal;
