import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styles from './TextGroup.module.scss';
import LoadingButton from './LoadingButton';
import { getDateTime, pollEndpoint } from '../../../modules/TCIUtils';

const POLL_WAIT_TIME = 10000; // 10 seconds.
const POLL_TIMEOUT_TIME = 300000; // 5 minutes.

export default class TextGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    updatePdfPath: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.generatePdf = this.generatePdf.bind(this);

    this.state = {
      isUpdating: false,
      updateTimestamp: null,
      currentPdfPath: null
    };
  }

  componentDidMount() {
    this.getInitialStatus();
  }

  getAjaxPath() {
    return this.props.updatePdfPath;
  }

  setStatus(response) {
    if (!response.data.hasPdf) {
      return;
    }

    this.setState({ updateTimestamp: response.data.timestamp });
    this.setState({ currentPdfPath: response.data.currentPdfPath });
    this.setState({ isUpdating: response.data.isUpdating });
  }

  getInitialStatus() {
    return axios
      .get(this.getAjaxPath())
      .then((response) => {
        this.setStatus(response);

        if (response.data.isUpdating) {
          this.pollUntilUpdated();
        }
      })
      .catch(error => console.log(error));
  }

  pollUntilUpdated() {
    return pollEndpoint({
      endpointUrl: this.getAjaxPath(),
      interval: POLL_WAIT_TIME,
      timeout: POLL_TIMEOUT_TIME,
      until: response => !response.data.isUpdating
    })
      .then(response => this.setStatus(response))
      .catch(error => console.log(error));
  }

  generatePdf() {
    return axios
      .post(this.getAjaxPath())
      .then(() => {
        this.setState({ isUpdating: true });
        return this.pollUntilUpdated();
      })
      .catch(error => console.log(error));
  }

  renderCurrentSection() {
    if (this.state.updateTimestamp && this.state.currentPdfPath) {
      return (
        <div className={styles.currentPdf}>
          <a className={styles.button} href={this.state.currentPdfPath} target="_blank">
            View Current {this.props.name} Text PDF
          </a>
          <span>(Last updated on {getDateTime(this.state.updateTimestamp)})</span>
        </div>
      );
    }

    return (
      <div className={styles.currentPdf}>
        {this.props.name} Text PDF
      </div>
    );
  }

  render() {
    return (
      <div className={styles.modalContent}>
        {this.renderCurrentSection()}

        <div className={styles.updatePdf}>
          <LoadingButton
            isLoading={this.state.isUpdating}
            buttonTextDefault="Update PDF"
            buttonTextLoading="Generating PDF"
            handleClick={this.generatePdf}
          />
        </div>
      </div>
    );
  }
}
