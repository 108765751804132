import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import styles from './TextToSpeechButton.module.scss';

interface SpeedSelectMenuProps {
  selectedSpeed: string,
  setSelectedSpeed: Function
}

const SpeedSelectMenu = ({ selectedSpeed, setSelectedSpeed }: SpeedSelectMenuProps) => {
  const content = (
    <div style={{ display: 'block' }}>
      <button
        type="button"
        onClick={() => setSelectedSpeed('slow')}
        className={`dropdown-item ${styles.ttsSpeedButton}`}
        aria-label="Select slow speed"
      >
        <span
          className={styles.ttsSpeedSelectText}
          style={{ visibility: selectedSpeed === 'slow' ? 'visible' : 'hidden' }}
        >
          <i className="fa fa-check" />
        </span>
        <span>Slow Speed</span>
      </button>
      <button
        type="button"
        onClick={() => setSelectedSpeed('normal')}
        className={`dropdown-item ${styles.ttsSpeedButton}`}
        aria-label="Select normal speed"
      >
        <span
          className={styles.ttsSpeedSelectText}
          style={{ visibility: selectedSpeed === 'normal' ? 'visible' : 'hidden' }}
        >
          <i className="fa fa-check" />
        </span>
        <span>Normal Speed</span>
      </button>
    </div>
  );

  return (
    <Tooltip
      arrow={false}
      content={content}
      distance={0}
      interactive
      placement="bottom"
      theme="dropdown"
    >
      <button
        type="button"
        className={styles.ttsActionDropdown}
        aria-label="Open speed selection menu"
      >
        <i className="fa fa-caret-down" />
      </button>
    </Tooltip>
  );
};

export default SpeedSelectMenu;
