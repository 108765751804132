import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import I18n from 'modules/i18n';
import moment from 'moment';
import { formatLocalTimestamp, maxDateTime } from 'components/common/Utils';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import styles from './TransitionSlide.module.scss';
import Tooltip from '../../../../common/Tooltip';

interface TurnInButtonProps {
  exerciseResults?: {
    completedAt: string;
    answerUpdatedAt: number;
  }[];
  isStudent: boolean;
  isPresentMode: boolean;
  turnInPath: string;
  unansweredQuestions?: any[];
  loading: boolean;
  disabled?: boolean;
}

const TurnInButton = ({
  exerciseResults, isPresentMode, isStudent, turnInPath, unansweredQuestions, loading, disabled,
}: TurnInButtonProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const [turnedInAt, setTurnedInAt] = useState<string | null>(null);

  const hasNewAnswers = () => {
    if (!isPresentMode) return false;
    if (!turnedInAt) return true;

    const turnedInAtDate = new Date(turnedInAt);
    const answersUpdatedAt = exerciseResults.map(er => new Date(er.answerUpdatedAt));
    const answerUpdatedAtDate = maxDateTime(answersUpdatedAt);

    return turnedInAtDate <= answerUpdatedAtDate;
  };

  useEffect(() => {
    if (!exerciseResults?.length) return;

    const slideGroupsCompletedAt = exerciseResults.map(er => new Date(er.completedAt));

    setTurnedInAt(maxDateTime(slideGroupsCompletedAt).toISOString());
  }, [exerciseResults]);

  return (
    <Tooltip shouldDisplay={!isStudent} content="Teachers cannot Turn In. This feature only works for students.">
      <div className={styles.turnInButton}>
        <button
          type="button"
          className="btn btn--purple"
          disabled={loading || !isStudent || submitting || !hasNewAnswers() || (unansweredQuestions.length > 0) || disabled}
          onClick={() => {
            setSubmitting(true);
            Axios.post(turnInPath)
              .then(() => setTurnedInAt(moment().toDate().toString()))
              .catch((e: Error) => setError(e))
              .finally(() => setSubmitting(false));
          }}
        >
          {I18n.t('turn_in')}
        </button>
        {turnedInAt && (
          <p>
            {I18n.t('turned_in_on', { timestamp: formatLocalTimestamp(turnedInAt, false) })}
          </p>
        )}
      </div>
    </Tooltip>
  );
};

export default TurnInButton;
