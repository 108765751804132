import React, { useState } from 'react';
import * as Routes from 'modules/routes';
import { IClassroom, useStafferClassrooms } from '@/hooks/api/useClassrooms';
import { useModalManager } from '@/components/common/Modal';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import DataTable from '@/components/common/DataTable';
import { COLUMN_NAMES, useColumnDefs } from '@/components/common/OutOfTermClassroomModal/utils';

interface OutOfTermClassroomModalProps {
  stafferId: number;
}

const OutOfTermClassroomModal = ({ stafferId }: OutOfTermClassroomModalProps) => {
  const { isOpen, close, open } = useModalManager();
  const [selectedClassroom, setSelectedClassroom] = useState<IClassroom>();

  const columnDefs = useColumnDefs();

  const { data: classrooms, isLoading } = useStafferClassrooms(stafferId, {
    active: true,
    outside_current_term: true,
    with_program: 431,
  });

  const toggleRadio = (rowData) => {
    const radio = $(`#radio-${rowData.id}`)[0] as HTMLInputElement;
    radio.checked = true;
    setSelectedClassroom(rowData);
  };

  if (!classrooms.length) return null;

  return (
    <>
      <button
        className={`
          tw-absolute tw-right-0 tw-top-3 tw-border-none 
          tw-bg-transparent tw-text-tci-purple tw-cursor-pointer`}
        onClick={open}
        type="button"
      >
        View an out-of-term class
      </button>
      <SubmitModal
        isOpen={isOpen}
        close={close}
        headerText="Select Out-of-Term Class"
        onSubmit={(e) => {
          e.preventDefault();
          const urlParams = new URLSearchParams(window.location.search);
          const programId = urlParams.get('program_id');
          const lessonId = urlParams.get('lesson_id');

          window.location.assign(Routes.teacher_score_path(selectedClassroom.id, {
            program_id: programId,
            lesson_id: lessonId,
          }));
        }}
        submitText="Select"
        submittingText="Saving..."
        nearlyFullScreen
      >
        <p className="tw-text-base">Select a class to view grades.</p>
        <DataTable
          handleResourcesManually
          columns={COLUMN_NAMES}
          columnDefs={columnDefs}
          defaultOrder={[1, 'asc']}
          loading={isLoading}
          paging={false}
          info={false}
          resources={classrooms}
          createdRow={(row: HTMLTableRowElement, rowData: IClassroom) => {
            row.addEventListener('click', () => toggleRadio(rowData));
            row.addEventListener('keydown', (e: KeyboardEvent) => {
              if (e.key === 'Enter') toggleRadio(rowData);
            });
            // eslint-disable-next-line no-param-reassign
            row.tabIndex = 0;
          }}
        />
      </SubmitModal>
    </>
  );
};

export default OutOfTermClassroomModal;
