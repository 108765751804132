import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import ModalContent from './ModalContent';

const AdministratorsModal = ({
  closeModal, getAdminInfoPath, isOpen, subscriberContactAdminMessage
}) => (
  <Modal
    closeModal={closeModal}
    isOpen={isOpen}
    headerText="Contact Admin"
  >
    <ModalContent
      ajaxPath={getAdminInfoPath}
      customMessage={subscriberContactAdminMessage}
    />
  </Modal>
);

export default AdministratorsModal;

AdministratorsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getAdminInfoPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

AdministratorsModal.defaultProps = {
  isOpen: false
};
