import React from 'react';
import TCIOnlyLock from 'common/TCIOnlyLock';

export function isRowTransferrable(rowData) {
  return (rowData.is_transferrable && ['Order', 'Transfer', 'Temp Access'].includes(rowData.transaction_type));
}

export function getTransferContent() {
  return (
    <span>
      <i aria-hidden="true" className="fa fa-arrows-h" />
      {' '}
      Transfer
      {' '}
      <TCIOnlyLock />
    </span>
  );
}
