import React, { useCallback, useMemo, useState } from 'react';
import { ConfirmModal, useModalManager } from '@/components/common/Modal/';

export const useConfirmModal = ({ pristine }: { pristine: boolean }) => {
  const [confirmAction, setConfirmAction] = useState<() => void>();

  const confirmModalManager = useModalManager();

  const confirmCallback = useCallback((callback: () => void) => {
    if (pristine) return callback();

    setConfirmAction(() => () => callback());
    return confirmModalManager.open();
  }, [pristine]);

  const closeModal = () => {
    setConfirmAction(undefined);
    confirmModalManager.close();
  };

  const confirmModalProps = useMemo(() => ({
    isOpen: confirmModalManager.isOpen,
    closeModal,
    confirmAction,
  }), [confirmModalManager.isOpen, closeModal, confirmAction]);

  return {
    confirmModalProps,
    confirmCallback,
  };
};

interface ConfirmUnsavedChangesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  confirmAction: () => void;
}

const ConfirmUnsavedChangesModal = ({ isOpen, closeModal, confirmAction }: ConfirmUnsavedChangesModalProps) => (
  <ConfirmModal
    isLoading={false}
    isOpen={isOpen}
    onCancel={closeModal}
    onConfirm={() => {
      confirmAction();
      closeModal();
    }}
    headerText="Unsaved changes"
    confirmationText="There are unsaved changes. Are you sure you want to leave?"
    confirmButtonText="Yes, Leave"
  />
);

export default ConfirmUnsavedChangesModal;
