import React from 'react';
import * as Routes from '@/modules/routes';
import I18n from 'i18n-js';
import { UnitType } from '../../types';
import styles from './UnitCard.module.scss';
import Callout from '../../../../shared/ProgramContents/Unit/Callout';
import Tooltip from '@/components/common/Tooltip';

interface UnitCardProps {
  unit: UnitType;
}

const UnitCard = ({ unit }: UnitCardProps) => {
  const unitPath = Routes.shared_program_unit_path(unit.program_id, unit.id);

  return (
    <a className={styles.unitCard} href={unitPath}>
      <>
        <div
          className={styles.unitImage}
          style={{
            backgroundImage: `url(${unit.poster_url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Callout color={unit.call_out_color} text={unit.call_out_text} />
        </div>

        <div className={styles.infoWrapper}>
          <div className={styles.unitInfo}>
            <span className="tw-text-[#3C3C3C]">{I18n.t('unit').concat(' ', unit.position.toString())}</span>
            <div className={styles.title}>
              <span className={`tw-text-tci-purple tw-font-bold tw-text-xl ${styles.unitTitle}`}>
                {unit.title}
              </span>
              <div className={styles.icon}>
                <Tooltip content={I18n.t('view_unit', { number: unit.position})}>
                  <i className="fa fa-icon fa-arrow-circle-right" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </>
    </a>
  );
};

export default UnitCard;
