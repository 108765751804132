import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import showToast from 'common/Toast';
import * as Routes from 'routes';
import canvasIcon from 'images/icons/canvas-red-box.png';
import UnconnectedCard from 'common/Settings/IntegrationsCards/CardTypes/UnconnectedCard';
import ConnectedCard from 'common/Settings/IntegrationsCards/CardTypes/ConnectedCard';
import ConnectionData from '../../common/UserLMSIntegrations/ConnectionData';
import { duplicateKeyMessage, incompleteSetup, openAuthPopup } from './Utils';

const CanvasSettings = ({
  connectionData, updateState, userType, subscriberId, subscriberConnected, programId,
}) => {
  const connectedToCanvas = connectionData.user_canvas_id;

  const renderToast = () => {
    showToast(incompleteSetup, { autoClose: 6000, msgHeading: 'Failed to connect.', msgType: 'error' });
  };

  const connectionIsValid = () => {
    const endpoint = Routes.plato_api_check_lms_connection_subscriber_path(subscriberId);

    return Axios.get(endpoint, { timeout: 8000 })
      .then(response => response.data.result)
      .catch(() => false);
  };

  const connectToCanvas = async () => {
    const connectionIsSuccessful = await connectionIsValid();

    if (!connectionIsSuccessful) {
      renderToast();
      return;
    }

    openAuthPopup()
      .then(data => updateState({ canvasConnectionData: data }))
      .catch((error) => {
        if (error.message === 'duplicate_key') {
          showToast(duplicateKeyMessage, { autoClose: 6000, msgHeading: 'Failed to connect.', msgType: 'error' });
        }
        else {
          showToast('Failed to connect', { msgType: 'error' });
        }
      });
  };

  if (connectedToCanvas) {
    const canvasConnectionDataNode = (
      <ConnectionData
        {...connectionData}
        lmsType="Canvas"
        updateState={updateState}
        userType={userType}
        iconPath={canvasIcon}
      />
    );

    return (
      <ConnectedCard
        connectionData={canvasConnectionDataNode}
        helpArticleHref="https://teachtci.com/canvas-integration"
        icon={canvasIcon}
        lmsType="Canvas"
        programId={programId}
      />
    );
  }

  return (
    <UnconnectedCard
      bodyText="Sync Assignments and Grades"
      connectLMS={connectToCanvas}
      connectionText="Connect to Canvas"
      enabled={subscriberConnected}
      headerText="Canvas API"
      icon={canvasIcon}
    />
  );
};

CanvasSettings.propTypes = {
  connectionData: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    user_canvas_id: PropTypes.string
  }).isRequired,
  programId: PropTypes.number.isRequired,
  subscriberConnected: PropTypes.bool.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateState: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired
};

export default CanvasSettings;
