import React from 'react';
import I18n from 'i18n-js';
import * as Routes from 'modules/routes';
import gameIcon from 'images/icons/lesson-game-yellow-outline.png';

interface NewVocabularyGamesLinkProps {
  lessonId: number;
  programId: number;
}

const NewVocabularyGamesLink = ({ lessonId, programId }: NewVocabularyGamesLinkProps) => (
  <div className="tw-flex tw-items-center tw-gap-2 tw-mt-6">
    <img alt="Game Icon" className="tw-max-w-[25px]" src={gameIcon} />
    <span>
      {I18n.t('checkout_our')}
      {' '}
      <a className="tw-font-bold" href={Routes.library_shared_program_games_path(programId, { lesson_games: true, lesson_id: lessonId })}>
        {I18n.t('vocabulary_game')}!
      </a>
    </span>
  </div>
);

export default NewVocabularyGamesLink;
