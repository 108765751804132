import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';

const PublishButtonWrapper = ({ rowData, updateProgramIndices }) => (
  <ApolloProvider client={apolloClient}>
    <Publish rowData={rowData} updateProgramIndices={updateProgramIndices} />
  </ApolloProvider>
);

PublishButtonWrapper.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number,
    published: PropTypes.bool
  }).isRequired,
  updateProgramIndices: PropTypes.func.isRequired
};

const UPDATE_PROGRAM_INDEX = gql`
  mutation UpdateProgramIndex($id: ID!,
                            $published: Boolean) {
    updateProgramIndex(id: $id,
                     published: $published) {
      result {
        id,
        uploadStatus,
        createdAt,
        processingEndTime,
        published,
        programIndexCsv {
          id,
          fileName,
          url
        }
      }
    }
  }
`;

const Publish = ({ rowData, updateProgramIndices }) => {
  const [updateProgramIndexMutation] = useMutation(UPDATE_PROGRAM_INDEX);

  const updateProgramIndex = () => {
    const variables = {
      variables: {
        id: rowData.id,
        published: !rowData.published
      }
    };

    updateProgramIndexMutation(variables)
      .then((result) => {
        updateProgramIndices(rowData.id, result.data.updateProgramIndex.result.published, rowData.locale);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <button
      type="button"
      onClick={updateProgramIndex}
      className="btn btn--sm btn--outline-purple"
    >
      {rowData.published ? 'Unpublish' : 'Publish'}
    </button>
  );
};

Publish.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number,
    published: PropTypes.bool
  }).isRequired,
  updateProgramIndices: PropTypes.func.isRequired
};

export default PublishButtonWrapper;
