import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Footer } from 'common/Modal';
import * as Routes from 'routes';
import styles from './ConnectClassroomForm.module.scss';

const DisconnectClassroomForm = ({
  closeModal, subscriberId, rowData, updateClassroom, lmsProvider
}) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();

      const classroomId = rowData.id;
      const updateClassroomPath = Routes.plato_api_subscriber_classroom_path(subscriberId, classroomId);
      const classroom = {
        id: classroomId,
        lms_course_id: null,
        lms_course_name: null,
        lms_type: null
      };

      Axios
        .patch(updateClassroomPath, { classroom })
        .then((res) => {
          closeModal();
          updateClassroom(res.data.data);
        });
    }}
  >
    <span className={styles.warningContainer}>
      <i aria-hidden="true" className="fa fa-warning" />
      <h3 className="mb20">WARNING:</h3>
    </span>
    <p>
      You are disconnecting the&nbsp;
      {lmsProvider === 'google' ? 'Google Classroom' : 'Canvas Course'}: &nbsp;
      <strong>{rowData.lms_course_name}</strong>&nbsp;
      from the TCI Class: <strong>{rowData.name}</strong>.
    </p>
    <p>
      TCI assignments and grades will <strong>not be synced</strong>&nbsp;
      to {lmsProvider === 'google' ? 'Google Classroom' : 'Canvas'} anymore.
    </p>
    <p className="mt20">Are you sure you want to continue?</p>

    <Footer
      primaryButtonText="Yes, disconnect"
      primaryButtonClassName="btn btn--red"
      secondaryButtonText="Cancel"
      secondaryButtonCallback={closeModal}
      submittingText="Disconnecting..."
      wrapperClassName={styles.modalFooter}
    />
  </form>
);

DisconnectClassroomForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  lmsProvider: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    lms_course_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};

export default DisconnectClassroomForm;
