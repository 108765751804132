import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { usePaginatedTable } from 'common/DataTable/PaginatedTable';
import * as Routes from 'routes';
import AddVideo from './AddVideo';
import VideosDropdown from './VideosDropdown';
import styles from './index.module.scss';
import AdvancedFilters from '../../common/AdvancedFilters';
import { buildFilterPath, filtersPropTypes } from '../../common/AdvancedFilters/Utils';

export default function VideosTable() {
  const pageSize = 500;

  const videosPath = Routes.plato_api_videos_path({
    page: 'pageNumber',
    per_page: 'pageSize',
    column: 'columnName',
    order: 'sortDirection',
    includes: ['model_videos', 'tags', 'audios']
  }).replace('pageSize', pageSize.toString());

  const [getUrl, setGetUrl] = useState(videosPath);

  const {
    PaginatedTable,
    resources,
    setResources,
    updateResource,
    deleteResource,
    addResources,
    doneLoading,
    setPagesLoaded,
    setDoneLoading,
  } = usePaginatedTable(pageSize);

  const updateActiveFilters = (updatedActiveFilters) => {
    // if we're done loading, set doneLoading to false to begin loading the filter request
    // if we're currently loading something, set doneLoading to true to stop loading
    // and allow the filter request through
    setGetUrl(buildFilterPath(videosPath, updatedActiveFilters));
    setDoneLoading(false);
    setResources([]);
    setPagesLoaded(0);
  };

  const addResource = (resource) => {
    addResources([resource]);
  };

  const asyncSearchPath = Routes.plato_api_search_videos_path();
  const initialFilters = [{ operatorValue: 'contains', propertyValue: 'video_file_name', value: null }];
  const properties = [
    {
      label: 'File Name',
      value: 'video_file_name',
      operators: ['eq', 'not_eq', 'cont', 'not_cont']
    },
    {
      label: 'Needs Captions',
      value: 'needs_captions',
      operators: ['eq'],
      isBoolean: true
    },
    {
      label: 'Date Uploaded',
      value: 'video_updated_at',
      operators: ['eq', 'not_eq', 'gteq', 'lteq'],
      isDate: true
    },
    {
      label: 'Title (English)',
      operators: ['eq', 'not_eq', 'cont', 'not_cont', 'blank'],
      value: 'title_en'
    },
    {
      label: 'Title (Spanish)',
      operators: ['eq', 'not_eq', 'cont', 'not_cont', 'blank'],
      value: 'title_es'
    }
  ];

  const renderActiveFilters = () => (
    <div>
      <AdvancedFilters
        initialFilters={initialFilters}
        properties={properties}
        searchPath={getUrl}
        resources={resources}
        updateActiveFilters={updateActiveFilters}
        asyncSearchPath={asyncSearchPath}
      />
      <hr />
    </div>
  );

  const columns = [
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <div className={`${styles.thumbnailContainer}`}>
            {rowData.poster_url && <img src={rowData.poster_url} alt="..." />}
          </div>,
          td
        );
      },
      name: 'poster_url',
      targets: 0,
      title: 'Poster'
    },
    {
      name: 'video_file_name',
      targets: 1,
      title: 'File Name',
      width: '30%'
    },
    {
      name: 'video_file_size',
      targets: 2,
      title: 'File Size',
      type: 'file-size'
    },
    {
      createdCell: (td, cellData) => { ReactDOM.render(moment(cellData).format('MMM D, YYYY'), td); },
      name: 'video_updated_at',
      targets: 3,
      title: 'Date Uploaded'
    },
    {
      createdCell: (td, needsCaptions) => { ReactDOM.render(needsCaptions ? 'Yes' : 'No', td); },
      name: 'needs_captions',
      targets: 4,
      width: '7%'
    },
    {
      name: 'caption_status_text',
      render: (data) => {
        if (typeof data === 'object') {
          let res = `<a href=${data['en']} target="_blank" rel="noopener noreferrer">View English</a><br />`;

          if (data['es']) {
            res += `<a href=${data['es']} target="_blank" rel="noopener noreferrer">View Spanish</a>`;
          }

          return res;
        }

        return data;
      },
      targets: 5,
      title: 'Caption Status'
    },
    {
      name: 'reference_count',
      targets: 6,
      title: 'Num of Uses',
      width: '7%'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <VideosDropdown rowData={rowData} afterEdit={updateResource} afterDelete={deleteResource} />,
          td
        );
      },
      name: 'actions',
      searchable: false,
      sortable: false,
      targets: 7
    }
  ];

  const columnMapping = columns.map(c => (c.name === 'actions' ? null : c.name));

  return (
    <React.Fragment>
      {renderActiveFilters()}
      {useMemo(() => <AddVideo afterSubmit={addResource} />, [])}
      <PaginatedTable
        autoWidth={false}
        columns={columns.map(c => c.name)}
        columnDefs={columns}
        columnMapping={columnMapping}
        exportedColumns={[1, 2, 3, 4, 5, 6]}
        defaultOrder={[[1, 'asc'], [3, 'asc']]}
        getUrl={getUrl}
      />
    </React.Fragment>
  );
}
