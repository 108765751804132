import React from 'react';
import Axios from 'axios';
import { ConfirmModal, useModalManager } from '@/components/common/Modal';
import * as Routes from '@/modules/routes';
import showToast from '@/components/common/Toast';
import styles from './SysadminDropdown.module.scss';

interface ResetMfaProps {
  stafferEmail: string;
  stafferId: number;
  updateTable?: ({ updatedStaff: any }) => void;
}

const ResetMfa = ({ stafferEmail, stafferId, updateTable }: ResetMfaProps) => {
  const modalManager = useModalManager();
  const resetPath = Routes.plato_api_reset_mfa_staffer_path(stafferId);

  const resetMfa = () => {
    Axios.post(resetPath)
      .then((res) => {
        if (updateTable) updateTable({ updatedStaff: res.data.staffer });
        showToast(`MFA reset successfully for ${res.data.staffer.email}`, { autoClose: 10000 });
      })
      .catch((error) => {
        showToast(`Failed to reset MFA for ${stafferEmail}`, { autoClose: 10000, msgType: 'error' });
        console.log(error);
      })
      .finally(() => {
        modalManager.close();
      });
  };

  return (
    <>
      <button
        id="edit-button"
        className={`dropdown-item ${styles.dropdownButton}`}
        type="button"
        onClick={modalManager.open}
      >
        <i aria-hidden="true" className="fa fa-repeat" /> Reset MFA
      </button>
      <ConfirmModal
        isLoading={false}
        isOpen={modalManager.isOpen}
        onCancel={modalManager.close}
        onConfirm={resetMfa}
        headerText="Reset MFA"
        confirmationText={`Are you sure you want to reset the existing multi-factor authentication (MFA) setup for ${stafferEmail}?`}
        confirmButtonText="Reset"
      />
    </>
  );
};

export default ResetMfa;
