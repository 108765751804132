import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import formStyles from '../Form.module.scss';

export default function InputField({ className, id, fieldName }) {
  return (
    <div>
      <label htmlFor={id}>
        <span className="sr-only">{fieldName} contains</span>
      </label>

      <Field
        className={`${className} ${formStyles.textInput}`}
        name={id}
        id={id}
        component="input"
        type="text"
        placeholder={`Enter ${fieldName}`}
      />
    </div>
  );
}

InputField.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

InputField.defaultProps = {
  className: ''
};
