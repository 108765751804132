import { withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import React from 'react';

const StyledSlider = withStyles({
  disabled: {},
  mark: {
    borderRadius: '50%',
    height: '10px',
    marginLeft: '-5px',
    marginTop: '-4px',
    width: '10px',
  },
  markActive: {
    backgroundColor: 'lightgrey',
    opacity: 1
  },
  markLabel: {
    color: '#555',
    top: '35px'
  },
  rail: {
    opacity: 1
  },
  root: {
    '&$disabled': {
      color: 'lightgrey'
    },
    color: 'lightgrey',
    marginBottom: '30px',
    marginTop: '10px',
    maxWidth: 600,
    width: '100%'
  },
  thumb: {
    '$disabled &': {
      height: '30px',
      marginLeft: '-15px',
      marginTop: '-14px',
      width: '30px'
    },
    '&:active': {
      boxShadow: '0 0 0 14px rgba(146, 110, 209, 0.16)'
    },
    '&:focus': {
      boxShadow: '0 0 0 8px rgba(146, 110, 209, 0.16)'
    },
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(146, 110, 209, 0.16)'
    },
    backgroundColor: 'lightgrey',
    boxShadow: '0 2px 4px 0 #ddd',
    height: '30px',
    marginLeft: '-15px',
    marginTop: '-14px',
    width: '30px'
  },
  thumbPurple: { backgroundColor: '#522e91' }
})(({ classes, hasAnswer, ...rest }) => (
  <Slider
    classes={{
      disabled: classes.disabled,
      mark: classes.mark,
      markActive: classes.markActive,
      markLabel: classes.markLabel,
      rail: classes.rail,
      root: classes.root,
      thumb: `${classes.thumb} ${hasAnswer ? classes.thumbPurple : ''}`
    }}
    {...rest}
  />
));

export default function SliderAdapter({
  ariaLabelledBy, defaultValue, input, meta, previewOnly, ...rest
}) {
  const hasAnswer = input.value !== null && input.value !== '';

  return (
    <StyledSlider
      {...input}
      {...rest}
      aria-labelledby={ariaLabelledBy}
      data-dirty={!previewOnly && meta.dirty}
      hasAnswer={hasAnswer}
      value={hasAnswer ? Number(input.value) : defaultValue}
    />
  );
}

SliderAdapter.propTypes = {
  ariaLabelledBy: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired
  }).isRequired,
  previewOnly: PropTypes.bool.isRequired
};
