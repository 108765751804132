import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = ({ header, subHeader }) => {
  return (
    <h4 className="with_inline_subheader">
      {header}
      <span>{subHeader}</span>
    </h4>
  );
};

SectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
};

export default SectionHeader;
