import axios, { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  plato_api_texture_path,
  plato_api_three_d_model_path,
  plato_api_three_d_model_textures_path,
} from '@/modules/routes';
import { fileExtension, SchemaType } from './utils';

const useUpdateThreeDModelMutation = (id: string) => (
  useMutation({
    mutationFn: async (values: SchemaType) => {
      const formData = new FormData();

      let modelRequest: AxiosResponse | undefined;

      if (values.model && values.model[0]) {
        formData.append('three_d_model[model]', values.model[0]);

        modelRequest = await axios
          .patch(
            plato_api_three_d_model_path(id),
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
      }

      const model = modelRequest ? modelRequest.data.data : {};

      const textureRequests = values
        .textures
        .map((texture) => {
          if (texture.destroy) return axios.delete(plato_api_texture_path(texture.id));

          if (values.model[0] && ['glb', 'gltf'].includes(fileExtension(values.model))) {
            return axios.delete(plato_api_texture_path(texture.id));
          }

          const textureFormData = new FormData();

          if (texture.file[0]) textureFormData.append('texture[image_en]', texture.file[0]);
          textureFormData.append('texture[image_models_attributes][0][texture_type]', texture.texture_type);

          if (texture.id) {
            textureFormData.append('texture[image_models_attributes][0][id]', texture.imageModelId);
            return axios
              .patch(
                plato_api_texture_path(texture.id),
                textureFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } },
              );
          }

          textureFormData.append('texture[image_models_attributes][0][model_id]', id);
          textureFormData.append('texture[image_models_attributes][0][model_type]', 'ThreeDModel');

          return axios
            .post(
              plato_api_three_d_model_textures_path(id),
              textureFormData,
              { headers: { 'Content-Type': 'multipart/form-data' } },
            );
        });

      return Promise.all(textureRequests).then((textureResponses) => {
        const textures = textureResponses.map(data => data.data.data);

        return { ...model, textures };
      });
    },
    mutationKey: ['useUpdateThreeDModelMutation', id],
  })
);

export default useUpdateThreeDModelMutation;
