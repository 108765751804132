import React from 'react';
import PropTypes from 'prop-types';
import styles from '../IntegrationsCards.module.scss';
import IntegrationStatus from './IntegrationStatus';

const ConnectedCard = ({
  connectionData, helpArticleHref, icon, lmsType, programId,
}) => (
  <div className={`${styles.cardWrapper} ${styles.fullWidth}`}>
    <div className={`${styles.cardHeader} ${styles.headerWithIcon}`}>
      {lmsType}
      <img alt="" className={`${styles.integrationIcon}`} src={icon} />
    </div>
    Automatically sync your TCI assignments and grades with {lmsType}.
    <a
      href={helpArticleHref}
      className="ml3"
      target="_blank"
      rel="noopener noreferrer"
    >
      Discover how to get started.
    </a>
    <div className="tw-flex tw-content-between">

      <div className="mt20">
        {connectionData}
      </div>

      <IntegrationStatus lmsType={lmsType} programId={programId} />
    </div>
  </div>
);

ConnectedCard.propTypes = {
  connectionData: PropTypes.node.isRequired,
  helpArticleHref: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  lmsType: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
};

export default ConnectedCard;
