import React from 'react';
import { Path } from 'fabric';
import useDrawingContext from '../../store/useDrawingContext';
import PenSettingsInput from './PenSettingsInput';
import icons from '../icons';
import { getPenOpacity } from './penSettingUtils';
import Button from './Button';

const OpacityButton = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const globalPenOpacity = useDrawingContext(state => state.penOpacity);
  const setPenOpacity = useDrawingContext(state => state.setPenOpacity);
  const { alt, icon } = icons.opacity;

  const opacity = getPenOpacity(canvas, globalPenOpacity);
  const activeObjects = canvas?.getActiveObjects() || [];

  const handleOpacityChange = (newOpacity: number) => {
    if (activeObjects.length > 0) {
      const opacity = newOpacity / 100;

      activeObjects.forEach((obj) => {
        if (obj.isType(Path.type)) {
          const parts = obj.stroke.toString().split(',');
          parts.pop();
          parts.push(`${opacity})`);

          /* eslint-disable-next-line no-param-reassign */
          obj.stroke = parts.join(',');
        } else {
          /* eslint-disable-next-line no-param-reassign */
          obj.opacity = opacity;
        }
      });

      canvas.requestRenderAll();

      return;
    }

    setPenOpacity(newOpacity);
  };

  return (
    <Button
      alt={alt}
      src={icon}
      type="opacity"
    >
      <PenSettingsInput
        defaultValue={opacity}
        label={alt}
        onChange={handleOpacityChange}
      />
    </Button>
  );
};

export default OpacityButton;
