import Axios from 'axios';
import * as Routes from '@/modules/routes';
import { CanvasCredentials, LtiCredentials } from './useCredentialStore';

export const fetchCredentials = (subscriberId: number) => {
  const oauthCredentialPath = Routes.plato_api_subscriber_subscriber_oauth_credentials_path(subscriberId);
  const ltiKeysPath = Routes.plato_api_subscriber_lti_keys_path(subscriberId);

  return Axios.all([Axios.get(oauthCredentialPath), Axios.get(ltiKeysPath)]);
};

export const createLtiKey = (subscriberId: number, ltiCredentials: LtiCredentials | any) => {
  const url = Routes.plato_api_subscriber_lti_keys_path(subscriberId);

  return Axios({
    data: { lti_key: ltiCredentials },
    method: 'post',
    url,
  });
};

export const updateLtiKey = (subscriberId: number, ltiCredentials: LtiCredentials | any) => {
  const url = Routes.plato_api_subscriber_lti_key_path(subscriberId, ltiCredentials.id || 0);
  return Axios({
    data: { lti_key: ltiCredentials },
    method: 'put',
    url,
  });
};

export const deleteLtiKey = (subscriberId: number, ltiCredentials: LtiCredentials | any) => (
  updateLtiKey(subscriberId, { ...ltiCredentials, deployment_id: '', lms_provider_id: '' })
);

export const createOAuthCredential = (subscriberId: number, canvasCredentials: CanvasCredentials | any) => {
  const url = Routes.plato_api_subscriber_subscriber_oauth_credentials_path(subscriberId);

  return Axios({
    data: { subscriber_oauth_credential: canvasCredentials },
    method: 'post',
    url,
  });
};

export const updateOAuthCredential = (subscriberId: number, canvasCredentials: CanvasCredentials | any) => {
  const url = Routes.plato_api_subscriber_subscriber_oauth_credential_path(subscriberId, canvasCredentials.id || 0);

  return Axios({
    data: { subscriber_oauth_credential: canvasCredentials },
    method: 'put',
    url,
  });
};

export const deleteOAuthCredential = (subscriberId: number, canvasCredentialsId: number) => {
  const url = Routes.plato_api_subscriber_subscriber_oauth_credential_path(subscriberId, canvasCredentialsId);

  return Axios.delete(url);
};
