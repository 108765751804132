import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Search from './Search';

const SearchWrapper = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/:userType/programs/:programId/search" element={<Search />} />
    </Routes>
  </BrowserRouter>
);

export default SearchWrapper;
