import React, { useState, useEffect } from 'react';
import { Button, DialogTrigger, Popover } from 'react-aria-components';
import I18n from 'i18n-js';
import axios from 'axios';
import { useModalManager } from '@/components/common/Modal';
import { UnitType } from '@/components/shared/NewDashboard/types';
import * as Routes from '@/modules/routes';
import TableOfContentsModal from './TableOfContentsModal';
import TableOfContents from './TableOfContents';

interface TableOfContentsButtonProps {
  programId: number;
  renderInModal?: boolean;
  units?: UnitType[];
}

const TableOfContentsButton = ({
  programId, renderInModal = false, units: initialUnits = [],
}: TableOfContentsButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [units, setUnits] = useState<any[]>(initialUnits);
  const [loading, setLoading] = useState(true);
  const modalManager = useModalManager();

  useEffect(() => {
    const route = Routes.plato_api_program_units_path(programId);

    axios.get(route)
      .then((response) => {
        setUnits(response.data.data);
        setLoading(false);
      });
  }, [programId]);

  return (
    <DialogTrigger>
      <Button
        className="tw-bg-inherit tw-border-none tw-cursor-pointer tw-flex tw-text-[#522e91] tw-font-[inherit]
        tw-text-[14px] tw-items-center"
        onPress={() => (renderInModal ? modalManager.open() : setIsOpen(prev => !prev))}
        isDisabled={loading}
      >
        <i className="fa fa-icon fa-list-ul tw-text-xl tw-mr-2" />
        <div className="tw-flex tw-items-center tw-ml-1.5 hover:tw-underline">
          {I18n.t('table_of_contents')}
        </div>
      </Button>

      <Popover
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <div className="tw-text-base tw-border-med-grey tw-rounded-lg tw-shadow-md tw-bg-white tw-w-[700px]">
          <header className="tw-rounded-t-lg tw-text-xl tw-font-bold tw-py-2 tw-px-4 tw-text-[#555]">
            {I18n.t('table_of_contents')}
            <Button
              className="tw-absolute tw-right-2 tw-text-xl tw-border-0 tw-cursor-pointer tw-text-[#555]"
              aria-label="Close popup"
              onPress={() => setIsOpen(false)}
            >
              ✕
            </Button>
          </header>
          <TableOfContents units={units} />
        </div>
      </Popover>

      {modalManager.isOpen && (
        <TableOfContentsModal
          isOpen={modalManager.isOpen}
          closeModal={modalManager.close}
          units={units}
        />
      )}
    </DialogTrigger>
  );
};

export default TableOfContentsButton;
