import React, { useState } from 'react';
import CopyToClipboardButton from '@/components/common/CopyToClipboardButton';

interface TextFieldWithCopyButtonProps {
  errorMessage: string;
  successMessage: string;
  initialText: string;
  label: string;
  readOnly?: boolean;
  placeholder?: string;
}

const TextFieldWithCopyButton = (
  { readOnly, initialText, successMessage, errorMessage, placeholder, label }: TextFieldWithCopyButtonProps,
) => {
  const [text, setText] = useState(initialText);

  return (
    <div className="textfield-icon-button-container input string optional">
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label>{label}</label>
      <div className="textfield-icon-button">
        <input
          type="text"
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={e => setText(e.target.value)}
          value={text}
        />
        <CopyToClipboardButton
          text={text}
          successMessage={successMessage}
          errorMessage={errorMessage}
          useIcon
        />
      </div>
    </div>
  );
};

TextFieldWithCopyButton.defaultProps = {
  readOnly: false,
};

export default TextFieldWithCopyButton;
