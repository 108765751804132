import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'common/ReactSelect5';

import { ContentTextArea, ButtonTextInput } from './Components/SharedInputs';
import SectionsSelect from './Components/SectionsSelect';
import styles from '../ElementInsert.module.scss';
import SlideSelect from './Components/SlideSelect';
import VideoSelect from './Components/VideoSelect';
import QuestionSelectFields from '../../../../SlideObjectQuestions/QuestionPickerModal/QuestionSelectFields';
import QuestionPicker from '../../../../SlideObjectQuestions/QuestionPickerModal/QuestionPicker';
import { slideObjectPropTypes } from '../../../../../../Utils';
import { renderVideoSourceOptions } from '../../../../../../../VideoManager/Utils';
import YoutubeURLInput from './Components/YoutubeURLInput';
import useSlideShowContext from '../../../../../../stores/useSlideShowContext';
import useSlideShowStore from '../../../../../../stores/useSlideShowStore';

const TEXT_BUTTON_OPTIONS = [
  { label: 'Tooltip', value: 'Tooltip' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Jump To...', value: 'Jump To...' },
  { label: 'Video', value: 'Video' },
  { label: 'Question', value: 'Question' },
];

const TextButtonInputs = ({
  buttonText,
  canAddTCIVideos,
  contentType,
  chosenVideo,
  destinationSlide,
  displayAsLink,
  editMode,
  equationModal,
  handleContentTextChange,
  handleContentTypeChange,
  handleButtonTextChange,
  handleDestinationSlideChange,
  handleSectionTypeChange,
  handleVideoChange,
  hintText,
  htmlElementId,
  lessonId,
  programId,
  sectionIds,
  sectionType,
  sectionTypeOptions,
  setSectionIds,
  slideObject,
  setDisplayAsLink,
  setHasError,
  unitId,
  notebookId,
  typeOption,
  setTypeOption,
  sectionId,
  selectedQuestionGroupIds,
  setSectionId,
  setNotebookId,
  setSelectedQuestionGroupIds,
  setVideoSource,
  videoSource,
  customImageModal,
  customAudioModal,
  customVideoModal,
}) => {
  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  const questionPicker = () => {
    if (!notebookId && typeOption !== 'Slide Show') return null;
    if (typeOption === 'Section' && sectionId === 0) return null;

    return (
      <QuestionPicker
        notebookId={notebookId}
        selectedQuestionGroupIds={selectedQuestionGroupIds}
        setHasError={setHasError}
        setSelectedQuestionGroupIds={setSelectedQuestionGroupIds}
        modelType={htmlElementId ? 'HtmlElements::QuestionButton' : 'Slide'}
        modelId={htmlElementId || slideObject.slideId}
        typeOption={typeOption}
        slideShowId={slideShowId}
      />
    );
  };

  const renderVideoOptions = () => {
    if (canAddTCIVideos && videoSource === 'tci') {
      return <VideoSelect chosenVideo={chosenVideo} editMode={editMode} handleVideoChange={handleVideoChange} />;
    }

    return (
      <YoutubeURLInput enteredURL={hintText} handleURLChange={handleContentTextChange} />
    );
  };

  const contentTypeInput = () => (
    <Fragment>
      <div className="tw-flex tw-items-end">
        <div>
          <label htmlFor="contentType" className={`${styles.required} ${styles.labelText} mt15`}>
            Content Type
          </label>
          <Select
            className={styles.labelSelect}
            isClearable={false}
            formatOptionLabel={option => (
              <div>
                {option.label}&nbsp;
                {(isAdmin && option.value === 'Question') && (
                  <i className="fa fa-lock" />
                )}
              </div>
            )}
            inputId="contentType"
            onChange={handleContentTypeChange}
            openOnFocus
            options={TEXT_BUTTON_OPTIONS}
            value={TEXT_BUTTON_OPTIONS.find(option => option.value === contentType)}
            isOptionDisabled={option => option.disabled}
          />
        </div>
        {contentType === 'Jump To...' && (
          <label className="tw-flex tw-items-center tw-mb-2 tw-ml-8">
            <input
              type="checkbox"
              checked={displayAsLink}
              onChange={(e) => setDisplayAsLink(e.target.checked)}
              className="tw-mr-2"
            />
            Display as link
          </label>
        )}
      </div>
    </Fragment>
  );

  const sectionTypeInput = () => (
    <Fragment>
      <label htmlFor="sectionType" className={`${styles.required} ${styles.labelText}`}>
        Section Type
      </label>
      <Select
        className={`${styles.labelSelect} ${styles.sectionTypeSelect}`}
        isClearable={false}
        inputId="sectionType"
        onChange={handleSectionTypeChange}
        openOnFocus
        options={sectionTypeOptions}
        value={sectionTypeOptions.find(option => option.value === sectionType)}
        isOptionDisabled={option => option.disabled}
      />
    </Fragment>
  );

  const contentInput = () => {
    if (contentType === 'Tooltip') {
      return (
        <ContentTextArea
          customImageModal={customImageModal}
          customAudioModal={customAudioModal}
          customVideoModal={customVideoModal}
          equationModal={equationModal}
          handleContentTextChange={handleContentTextChange}
          hintText={hintText}
        />
      );
    }

    if (contentType === 'Jump To...') {
      return (
        <SlideSelect destinationSlide={destinationSlide} handleDestinationSlideChange={handleDestinationSlideChange} />
      );
    }

    if (contentType === 'Video') {
      return (
        <>
          {canAddTCIVideos && <span className="mb15 show_block">{renderVideoSourceOptions(videoSource, setVideoSource)}</span>}
          {renderVideoOptions()}
        </>
      );
    }

    if (contentType === 'Question') {
      return (
        <>
          <div className={styles.vl} />
          <div className={styles.questionSelects}>
            <QuestionSelectFields
              lessonId={lessonId}
              programId={programId}
              sectionId={sectionId}
              setHasError={setHasError}
              setNotebookId={setNotebookId}
              setSectionId={setSectionId}
              setTypeOption={setTypeOption}
              slideObject={slideObject}
              typeOption={typeOption}
              unitId={unitId}
              notebookId={notebookId}
              modelType={htmlElementId ? 'HtmlElements::QuestionButton' : 'Slide'}
              modelId={htmlElementId || slideObject.slideId}
            />
            <hr />
            {questionPicker()}
          </div>
        </>
      );
    }

    if (contentType === 'Reading' || (editMode && sectionIds && sectionIds.length > 0)) {
      return (
        <>
          {sectionTypeInput()}
          <SectionsSelect sectionIds={sectionIds} sectionType={sectionType} setSectionIds={setSectionIds} />
        </>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <ButtonTextInput buttonText={buttonText} handleButtonTextChange={handleButtonTextChange} />
      {contentTypeInput()}
      <span className="mt15">
        {contentInput()}
      </span>
    </Fragment>
  );
};

TextButtonInputs.propTypes = {
  buttonText: PropTypes.string.isRequired,
  canAddTCIVideos: PropTypes.bool.isRequired,
  chosenVideo: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  contentType: PropTypes.string.isRequired,
  destinationSlide: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  displayAsLink: PropTypes.bool,
  editMode: PropTypes.bool.isRequired,
  equationModal: PropTypes.func,
  handleButtonTextChange: PropTypes.func.isRequired,
  handleContentTextChange: PropTypes.func.isRequired,
  handleContentTypeChange: PropTypes.func.isRequired,
  handleDestinationSlideChange: PropTypes.func.isRequired,
  handleSectionTypeChange: PropTypes.func.isRequired,
  handleVideoChange: PropTypes.func.isRequired,
  hintText: PropTypes.string,
  htmlElementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lessonId: PropTypes.number,
  notebookId: PropTypes.number,
  programId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  sectionIds: PropTypes.arrayOf(PropTypes.string),
  sectionType: PropTypes.string.isRequired,
  sectionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  selectedQuestionGroupIds: PropTypes.instanceOf(Set).isRequired,
  setDisplayAsLink: PropTypes.func,
  setHasError: PropTypes.func.isRequired,
  setNotebookId: PropTypes.func.isRequired,
  setSectionId: PropTypes.func.isRequired,
  setSectionIds: PropTypes.func.isRequired,
  setSelectedQuestionGroupIds: PropTypes.func.isRequired,
  setTypeOption: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  typeOption: PropTypes.string.isRequired,
  unitId: PropTypes.number,
};

TextButtonInputs.defaultProps = {
  displayAsLink: false,
  equationModal: () => {},
  hintText: '',
  lessonId: null,
  notebookId: null,
  sectionIds: [],
  setDisplayAsLink: () => {},
  unitId: null,
};

export default TextButtonInputs;
