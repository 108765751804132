import { CameraStartingArgs } from '@/hooks/useThreeJs/threeJsHelpers';
import { plato_api_three_d_model_path } from '@/modules/routes';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { PerspectiveCamera } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { SlideObject } from '@/components/admin/SlideShow/types';

interface CameraPositionButtonProps {
  camera: PerspectiveCamera;
  controls: OrbitControls;
  modalManager: ModalManager;
  slideObject: SlideObject;
}


const CameraPositionButton = ({ camera, controls, modalManager, slideObject }: CameraPositionButtonProps) => {
  const threeDModelId = slideObject.threeDModels[0].id;

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({
      position: camera_pos,
      rotation: camera_rot,
      target: camera_target,
      zoom: camera_zoom,
    }: CameraStartingArgs) => (
      axios
        .patch(plato_api_three_d_model_path(threeDModelId), {
          three_d_model: {
            camera_pos,
            camera_rot,
            camera_target,
            camera_zoom,
          },
        })
        .then(res => res.data.data)
    ),
    mutationKey: ['CameraPosition', threeDModelId],
  });

  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const handleMutate = async (
    position: CameraStartingArgs['position'],
    rotation: CameraStartingArgs['rotation'],
    zoom: CameraStartingArgs['zoom'],
    target: CameraStartingArgs['target'],
  ) => {
    const res = await mutateAsync({ position, rotation, target, zoom });

    const threeDModel = {
      ...slideObject.threeDModels[0],
      cameraPos: res.camera_pos,
      cameraRot: res.camera_rot,
      cameraTarget: res.camera_target,
      cameraZoom: res.camera_zoom,
    };

    updateSlideObject({ ...slideObject, threeDModels: [threeDModel] });

    modalManager.close();
  };

  return (
    <button
      className="dropdown-item tw-cursor-pointer"
      disabled={isLoading}
      onClick={(e) => {
        e.stopPropagation();
        const rotation = { x: camera.rotation.x, y: camera.rotation.y, z: camera.rotation.z };
        handleMutate(camera.position, rotation, camera.zoom, controls.target);
      }}
      type="button"
    >
      Set as default view
    </button>
  );
};

export default CameraPositionButton;
