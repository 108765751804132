import React, { Component } from 'react';
import { Form } from 'react-final-form';
import Axios from 'axios/index';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import AllowMultipleSessionsField from 'components/admin/FormFields/AllowMultipleSessionsField';
import FormField from 'components/admin/FormFields/FormField';
import { Footer } from 'components/common/Modal';
import { renderErrors, SubmitError } from 'components/common/Forms/Utils';
import { renderFlagFields } from 'components/common/DataTable/Utils';
import showToast from 'components/common/Toast';
import styles from '../Form.module.scss';

export default class EditForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    flags: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    student: PropTypes.shape({
      allow_multiple_sessions: PropTypes.bool,
      first_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      last_name: PropTypes.string.isRequired,
      uid: PropTypes.string,
      username: PropTypes.string.isRequired
    }).isRequired,
    updatePath: PropTypes.string.isRequired,
    updateTable: PropTypes.func.isRequired,
    userIsSysadmin: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    return Axios
      .patch(this.props.updatePath.replace(':id', this.props.student.id), { student: values })
      .then((response) => {
        this.props.updateTable(response.data.data, 'update');
        this.props.closeModal();
        showToast(`Account info updated for ${values.first_name} ${values.last_name}.`, { autoClose: 10000 });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        showToast(`Failed to update account info for ${values.first_name} ${values.last_name}.`, { autoClose: 10000, msgType: 'error' });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  _getInitialValues() {
    const values = {
      allow_multiple_sessions: this.props.student.allow_multiple_sessions,
      first_name: this.props.student.first_name,
      last_name: this.props.student.last_name,
      username: this.props.student.username,
      uid: this.props.student.uid
    };

    this.props.flags.forEach((flag) => {
      values[flag.name] = this.props.student[flag.name];
    });

    return values;
  }

  render() {
    return (
      <Form
        initialValues={this._getInitialValues()}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, pristine, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="sr-only">Fields marked with an asterisk (*) are required.</span>

            <FormField htmlFor="first_name" label="First Name" required />
            <FormField htmlFor="last_name" label="Last Name" required />
            <FormField htmlFor="username" label="Username" required />
            <FormField htmlFor="password" label="New Password" autoComplete="new-password" type="password" />
            <FormField htmlFor="uid" label="UID" />

            {renderFlagFields(this.props.userIsSysadmin, this.props.flags, styles.formRow, styles.checkboxLabel)}

            {this.props.userIsSysadmin && <AllowMultipleSessionsField />}

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={pristine}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }
}
