import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import AssignmentModal from '../AssignmentModal';
import Link from '../assessments/AssignmentModalTrigger/Link';
import {
  assessmentPropType,
  classroomsPropType,
} from '../AssignmentModal/PropTypes';

export default class Assign extends Component {
  static propTypes = {
    assessment: assessmentPropType.isRequired,
    assessmentsPagePath: PropTypes.string.isRequired,
    assessmentsPath: PropTypes.string.isRequired,
    assignmentsPath: PropTypes.string.isRequired,
    classrooms: classroomsPropType.isRequired,
    initiallyAssignable: PropTypes.bool,
    program: PropTypes.shape({
      id: PropTypes.number.isRequired,
      science: PropTypes.bool.isRequired
    }).isRequired,
    stafferId: PropTypes.number.isRequired
  };

  static defaultProps = {
    initiallyAssignable: true
  };

  constructor(props) {
    super(props);
    this.state = {
      assessments: [],
      assignable: this.props.initiallyAssignable,
      initialLessonId: null,
      initialValues: {},
      modalIsOpen: false
    };

    this.openModalWithReloadedAssessments = this.openModalWithReloadedAssessments.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setDeleteConfirmationListener();
    this.setInitialValues();
  }

  setInitialValues() {
    const initialValues = { modelType: 'assessment', modelsToAssign: [{ value: `Assessment-${this.props.assessment.id}` }] };
    const initialLessonId = this.props.assessment.lesson_id;

    this.setState({ initialLessonId, initialValues });
  }

  setDeleteConfirmationListener() {
    $(document).on('ajax:complete', (xhr) => {
      const defaultConfirmation = 'Are you sure you want to delete this question?';
      const lastQuestionConfirmation = `${defaultConfirmation} All assignments must have at least one question. ` +
        'If you delete this question, this assessment will no longer be assigned to any classes.';

      const { num_of_questions: numQuestion } = JSON.parse(xhr.detail[0].responseText);
      this.setState({ assignable: numQuestion > 0 });

      // Set confirmation text for all answerable questions
      if ($('#notebook-questions-container.assigned').length > 0) {
        if (numQuestion === 1) {
          $(".question-header[data-answerable='true']")
            .find('.delete-question-group')
            .attr('data-confirm', lastQuestionConfirmation);
        }
        else {
          $(".question-header[data-answerable='true']")
            .find('.delete-question-group')
            .attr('data-confirm', defaultConfirmation);
        }
      }
    });
  }

  openModalWithReloadedAssessments() {
    return axios
      .get(
        this.props.assessmentsPath,
        { params: { for_program: this.props.program.id, non_empty: true } }
      )
      .then((response) => {
        const assessments = response.data.data;
        return this.setState({ assessments, modalIsOpen: true });
      });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        <Link
          assessmentId={this.props.assessment.id}
          assignable={this.state.assignable}
          lessonId={this.props.assessment.lesson_id}
          openModal={this.openModalWithReloadedAssessments}
          withIcon
        />

        <AssignmentModal
          assessments={this.state.assessments}
          assessmentsPagePath={this.props.assessmentsPagePath}
          assignmentsPath={this.props.assignmentsPath}
          classrooms={this.props.classrooms}
          closeModal={this.closeModal}
          handlePostSave={() => {}}
          initialLessonId={this.state.initialLessonId}
          initialValues={this.state.initialValues}
          modalIsOpen={this.state.modalIsOpen}
          program={this.props.program}
          stafferId={this.props.stafferId}
        />
      </div>
    );
  }
}
