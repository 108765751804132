import React, { useState } from 'react';

const Correlations = ({ correlations, toggleText }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <h3 className="show_hide tw-cursor-pointer" onClick={() => { setExpanded(prev => !prev); }}>
        <span className={`expandable small ${expanded ? 'open' : 'closed'} fixed`}>
          <span className="expandable-toggle-btn" />
          <span className="expandable-toggle-text">{toggleText}</span>
        </span>
      </h3>
      {expanded && (
        <ul className="bullet_list">
          {correlations.map(({ key, value }) => (
            <li key={`${key || Math.random()}-${value}`} className="mt5 tw-pl-[45px] tw-list-none">
              {key}&nbsp;
              {value}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Correlations;
