import React, { useState } from 'react';
import axios from 'axios';
import * as Routes from 'modules/routes';
import Modal, { useModalManager, Footer } from '../../../common/Modal';

interface GameResetButtonProps {
  exerciseResultId: string;
  gameId: string;
  gameType: string;
}

const GameResetButton = ({ exerciseResultId, gameId, gameType }: GameResetButtonProps) => {
  const modalManager = useModalManager();
  const [loading, setLoading] = useState(false);

  const gameTypesHash = { state_capitals: 'State Capitals', vocabulary_game: 'Vocabulary' }

  const resetScores = () => {
    setLoading(true);
    if (gameType === 'reading_game') {
      axios
        .post(Routes.plato_api_reset_reading_games_exercise_result_path(exerciseResultId))
        .then(() => { window.location.reload(); })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    }
    else {
      axios
        .delete(Routes.plato_api_game_exercise_result_path(gameId, exerciseResultId))
        .then(() => { window.location.reload(); })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  const footer = () => (
    <Footer
      primaryButtonDisabled={loading}
      primaryButtonText="Reset"
      primaryButtonSubmit={false}
      primaryButtonCallback={resetScores}
      secondaryButtonCallback={modalManager.close}
      submitting={loading}
      wrapperClassName="pr20 pl20 pb20"
    />
  )

  const warningText = () => {
    if (gameType === 'reading_game') {
      return "Are you sure? This student's scores and work for the all the reading checks in the lesson will get cleared."
    }

    return `Are you sure? This student's score for the ${gameTypesHash[gameType]} Game will be permanently deleted.`
  }

  const modal = () => {
    return (
      <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText="Reset Game" footer={footer()}>
        {warningText()}
      </Modal>
    )
  }

  return (
    <>
      <button className="btn btn--purple" onClick={modalManager.open} type="button">
        Reset Total Score
      </button>
      {modal()}
    </>
  );
}

export default GameResetButton;
