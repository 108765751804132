import React from 'react';
import create from 'zustand';
import update from 'immutability-helper';

type VocabularyTerm = {
  label: React.ReactNode,
  term: string;
  value: number;
  vocabDefinition: React.ReactNode;
  vocabTerm: React.ReactNode;
};

interface IVocabularyStoreVariables {
  loading: boolean;
  selectedTerms: { [key: string]: string | number };
  selectedValues: () => (string | number)[];
  setLoading: (loading: boolean) => void;
  setSelectedTerm: (id: string, value: string | number) => void;
  setVocabularyTerms: (vocabularyTerms: VocabularyTerm[]) => void;
  vocabularyTerms: VocabularyTerm[];
}

const useVocabularyStore = create<IVocabularyStoreVariables>((set, get) => ({
  loading: true,
  selectedTerms: {},
  selectedValues: () => Object.values(get().selectedTerms),
  setLoading: (loading: boolean) => set({ loading }),
  setSelectedTerm: (id: string, value: string | number) => set(state => ({
    selectedTerms: update(state.selectedTerms, { [id]: { $set: value } }),
  })),
  setVocabularyTerms: (vocabularyTerms: VocabularyTerm[]) => set({ vocabularyTerms }),
  vocabularyTerms: [],
}));

export default useVocabularyStore;
