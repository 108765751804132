import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from '../Utils';
import * as constants from '../steps';

export default function RosterSteps({
  isCsvDistrict, wrapperClass, divider, renderStep, completedSteps
}) {
  const getStepName = (step) => {
    if (completedSteps[step]) return 'Setup Complete';

    return 'Setup Incomplete';
  };

  function renderNonCsvSteps() {
    return (
      <Steps
        wrapperClass={wrapperClass}
        renderStep={renderStep}
        divider={divider}
        steps={[
          constants.ROSTER_METHOD_STEP, constants.CONFIGURATION_STEP, constants.CONTACT_STEP_NON_CSV,
          constants.MAP_COURSES_STEP, constants.FINAL_STEP
        ]}
        labels={[
          'Integration Method', 'Configuration', 'Rostering Contact', 'Assign Programs',
          getStepName(constants.FINAL_STEP)
        ]}
      />
    );
  }

  function renderCsvSteps() {
    return (
      <Steps
        wrapperClass={wrapperClass}
        renderStep={renderStep}
        divider={divider}
        steps={[
          constants.ROSTER_METHOD_STEP, constants.CONFIGURATION_STEP, constants.DISTRICT_CONFIG_STEP,
          constants.CONTACT_STEP_CSV, constants.FINAL_STEP
        ]}
        labels={[
          'Integration Method', 'Configuration', 'District Configuration', 'Rostering Contact',
          getStepName(constants.FINAL_STEP)
        ]}
      />
    );
  }

  return isCsvDistrict ? renderCsvSteps() : renderNonCsvSteps();
}

RosterSteps.propTypes = {
  completedSteps: constants.completedStepsPropTypes,
  divider: PropTypes.element.isRequired,
  isCsvDistrict: PropTypes.bool.isRequired,
  renderStep: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string.isRequired
};

RosterSteps.defaultProps = {
  completedSteps: {},
};
