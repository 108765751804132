import React from 'react';
import PropTypes from 'prop-types';
import { modalManagerPropTypes } from 'common/Modal';
import MutationModal from '../MutationModal';
import { modelVideoShapeProps, UPDATE_MODEL_VIDEO } from '../Utils';

const EditVideo = ({ ...props }) => {
  const initialValues = () => {
    if (props.modelVideo) {
      return {
        ...props.modelVideo,
        video_id: props.modelVideo.videoId
      };
    }

    if (props.youtubeVideoURL) {
      return { youtube_video_url: props.youtubeVideoURL };
    }
  };

  return (
    <MutationModal
      addButtonText="Edit Video"
      afterSubmit={props.afterSubmit}
      headerText="Edit Video"
      initialValues={initialValues()}
      modalManager={props.modalManager}
      modelId={props.modelId}
      modelType={props.modelType}
      modelVideo={props.modelVideo}
      mutation={UPDATE_MODEL_VIDEO}
      onYoutubeSubmit={props.onYoutubeSubmit}
      showYoutubeOption={props.showYoutubeOption}
      standaloneButton={props.standaloneButton}
      userType={props.userType}
      youtubeSubmitLoading={props.youtubeSubmitLoading}
    />
  );
};

EditVideo.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  modalManager: modalManagerPropTypes,
  modelId: PropTypes.string.isRequired,
  modelType: PropTypes.string.isRequired,
  modelVideo: modelVideoShapeProps,
  showYoutubeOption: PropTypes.bool,
  standaloneButton: PropTypes.bool,
  userType: PropTypes.string,
  youtubeVideoURL: PropTypes.string
};

EditVideo.defaultProps = {
  modalManager: null,
  modelVideo: null,
  showYoutubeOption: false,
  standaloneButton: true,
  userType: 'Sysadmin',
  youtubeVideoURL: ''
};

export default EditVideo;
