import React, { Fragment, useState } from 'react';
import FroalaEditorBase from 'froala-editor';
import { useModalManager } from 'common/Modal';
import PropTypes from 'prop-types';
import FroalaConfig from 'froala/FroalaConfig';
import FroalaEditor from 'common/FroalaEditor';
import styles from '../../ElementInsert.module.scss';
import slideStyles from '../../../../../../Slide.module.scss';
import {
  addEditAudioListeners
} from '../../../FroalaListeners';

import {
  EQUATION_COMMAND_NAME,
  ELEMENT_INSERT_COMMAND_NAME,
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  CUSTOM_AUDIO_COMMAND_NAME,
} from '../../../../../../../../../shared/Froala/froalaConstants';

import { formatToolbarButtons } from '../../../../Toolbar';
import useSlideShowContext from '../../../../../../../stores/useSlideShowContext';

export const ContentTextArea = ({ customAudioModal, customVideoModal, customImageModal, equationModal, handleContentTextChange, hintText }) => {
  const [editorInstance, setEditorInstance] = useState(null);
  const [slideSoundClipId, setSlideSoundClipId] = useState('');
  const [$audioButtonToEdit, setAudioButtonToEdit] = useState(null);

  const userType = useSlideShowContext(state => state.userType);

  const addImageModalManager = useModalManager();
  const audioModalManager = useModalManager();
  const addVideoModalManager = useModalManager();
  const equationModalManager = useModalManager();

  //  Remove elements from this array as we add them into the functionality into the buttons.
  const buttonsToExclude = [ELEMENT_INSERT_COMMAND_NAME];

  const froalaConfig = FroalaConfig({
    allowHTML: true,
    customConfig: {
      charCounterCount: false,
      enter: FroalaEditorBase.ENTER_BR,
      fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
      imageEditButtons: ['imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageSize'],
      quickInsertTags: [],
      toolbarButtons: formatToolbarButtons(userType, buttonsToExclude),
    },
    events: {
      // eslint-disable-next-line func-names
      'commands.after': function(commandName) {
        switch (commandName) {
          case CUSTOM_IMAGE_COMMAND_NAME:
            addImageModalManager.open();
            break;
          case CUSTOM_AUDIO_COMMAND_NAME:
            audioModalManager.open();
            break;
          case CUSTOM_VIDEO_COMMAND_NAME:
            addVideoModalManager.open();
            break;
          case EQUATION_COMMAND_NAME:
            equationModalManager.open();
            break;
          default:
            break;
        }
      },
      // eslint-disable-next-line func-names
      initialized: function() {
        const editor = this;
        setEditorInstance(this);
        addEditAudioListeners(editor.$el, setSlideSoundClipId, setAudioButtonToEdit, audioModalManager);
      }
    },
  });

  return (
    <>
      <section className={slideStyles.textEditContainer}>
        <label className={`${styles.required} ${styles.labelText} mt25`}>
          Content Text
        </label>
        <FroalaEditor
          customConfig={froalaConfig}
          text={hintText}
          onModelChange={handleContentTextChange}
          model={hintText}
          tag="textarea"
        />
      </section>
      {customImageModal(editorInstance, addImageModalManager, true)}
      {equationModal(editorInstance, equationModalManager, true)}
      {audioModalManager.isOpen && customAudioModal(editorInstance, audioModalManager, $audioButtonToEdit, setAudioButtonToEdit, setSlideSoundClipId, slideSoundClipId)}
      {customVideoModal(editorInstance, addVideoModalManager, true)}
    </>
  );
};

ContentTextArea.propTypes = {
  customAudioModal: PropTypes.func,
  customImageModal: PropTypes.func,
  customVideoModal: PropTypes.func,
  equationModal: PropTypes.func,
  handleContentTextChange: PropTypes.func.isRequired,
  hintText: PropTypes.string,
};

ContentTextArea.defaultProps = {
  customAudioModal: () => {},
  customImageModal: () => {},
  customVideoModal: () => {},
  equationModal: () => {},
  hintText: '',
};

export const ButtonTextInput = ({ buttonText, handleButtonTextChange }) => (
  <Fragment>
    <label htmlFor="buttonText" className={`${styles.required} ${styles.labelText} mt15`}>
      Button Text
    </label>
    <input
      className={`${styles.modalField}`}
      id="buttonText"
      onChange={handleButtonTextChange}
      value={buttonText}
      placeholder="Start typing here..."
      type="text"
    />
  </Fragment>
);

ButtonTextInput.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleButtonTextChange: PropTypes.func.isRequired,
};
