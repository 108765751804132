import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from '@/modules/routes';

import StandardButton from "@/components/ui/Buttons/StandardButton";

interface PublishButtonProps {
  published: boolean;
  readingGameId: number;
}

const PublishButton = ({ published, readingGameId }: PublishButtonProps) => {
  const [currentPublished, setCurrentPublished] = useState(published)

  const togglePublish = () => (
    Axios.patch(Routes.plato_api_reading_game_path(readingGameId, { published: !currentPublished } ))
      .then((response) => {
        setCurrentPublished(response.data.data.published);
      })
  )

  return (
    <StandardButton
      color="purple"
      outlineStyle
      onClick={togglePublish}
    >
      {currentPublished ? 'Unpublish' : 'Publish'}
    </StandardButton>
  );
}

export default PublishButton;
