import React from 'react';
import styles from './SaveLocation.module.scss';
import mySlideshowsTab from '../../../../../../images/my-slideshows.png';

const SaveLocation = () => (
  <div>
    <hr className={styles.divider} />
    <p>
      View your saved presentations by returning to the Lesson Overview and selecting &quot;My Slideshows.&quot;
    </p>

    <div className={styles.imgContainer}>
      <img
        src={mySlideshowsTab}
        alt="Slideshow location tab"
        className={styles.slideshowsTab}
      />
    </div>
  </div>
);

export default SaveLocation;
