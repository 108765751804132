import { useState } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';

const useDescribeImage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const describeImage = (params) => {
    setLoading(true);
    return Axios.post<{ data: any }>(Routes.plato_api_describe_images_path(), params)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(e);
      })
      .finally(() => setLoading(false));
  };

  return { describeImage, error, loading };
};

export default useDescribeImage;
