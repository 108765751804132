import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import Modal, { modalManagerPropTypes } from 'common/Modal';
import { getLocale } from 'common/Utils';
import styles from './SectionModal.module.scss';
import Tab from './Tab.tsx';
import useSectionTypeMap from './useSectionTypeMap.ts';

export const GET_SECTIONS_QUERY = gql`
  query getSections($sectionIds: [ID!], $programId: ID!) {
    sections(ids: $sectionIds) {
      id,
      title(programId: $programId)
      type
    }
  }
`;

const useSections = (sectionIds, programId) => {
  const queryData = { variables: { sectionIds, programId } };
  return useQuery(GET_SECTIONS_QUERY, queryData);
};

const SectionModal = ({ content, programId, sectionIds, modalManager }) => {
  const [activeSectionId, setActiveSectionId] = useState(sectionIds[0]);
  const locale = getLocale();
  const routes = Object.fromEntries(sectionIds.map(sectionId => (
    [sectionId, Routes.shared_section_path(sectionId, { program_id: programId, locale, in_iframe: true })]
  )));

  useEffect(() => setActiveSectionId(sectionIds[0]), [sectionIds]);
  const { data, loading } = useSections(sectionIds, programId);
  const sectionTypeMap = useSectionTypeMap(content);

  const renderHeader = () => {
    if (!data) return null;

    const { sections } = data;

    return sectionIds.map((sectionId) => {
      const currentSection = sections.find(section => section.id === sectionId);
      const isActive = activeSectionId === sectionId;
      const sectionType = sectionTypeMap[sectionId];
      const shouldLink = sectionType !== 'Unit' && currentSection.type !== 'Unit';

      return (
        <Tab
          key={sectionId}
          isActive={isActive}
          onClick={() => setActiveSectionId(sectionId)}
          section={currentSection}
          shouldLink={shouldLink}
          route={routes[sectionId].replace('&locale=en&in_iframe=true', '')}
        />
      );
    });
  };

  return (
    <Modal
      parentSelector={() => document.querySelector('.current-slide')}
      closeModal={modalManager.close}
      headerClass={styles.headerClass}
      headerText={renderHeader()}
      isOpen={modalManager.isOpen}
      nearlyFullScreen
    >
      {!loading && (
        sectionIds.map(sectionId => (
          <div
            aria-hidden={sectionId !== activeSectionId}
            key={`section-${sectionId}`}
            className={`${styles.sectionIframeContainer} 
            ${sectionId === activeSectionId ? '' : 'hidden'}`}
          >
            <iframe className={styles.sectionIframe} src={routes[sectionId]} title={`Section ${sectionId}`} />
          </div>
        ))
      )}
    </Modal>
  );
};

SectionModal.propTypes = {
  content: PropTypes.string.isRequired,
  modalManager: modalManagerPropTypes.isRequired,
  programId: PropTypes.string.isRequired,
  sectionIds: PropTypes.arrayOf(PropTypes.string),
};

SectionModal.defaultProps = {
  sectionIds: [],
};

export default SectionModal;
