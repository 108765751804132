import React from 'react';

type UnitProjectScoreTypes = {
  hasBeenGraded: boolean,
  maxScore: number,
  score: number
};

const UnitProjectScore = ({ score, maxScore, hasBeenGraded }: UnitProjectScoreTypes) => {
  const scoreValue = () => {
    if (!hasBeenGraded) return 'Not graded';

    return score;
  };

  return (
    <div>
      <strong>Total Unit Projects Score:</strong> {' '}
      {scoreValue()} / {maxScore}
    </div>
  );
};

export default UnitProjectScore;
