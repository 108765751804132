import React from 'react';
import { CheckboxOrRadioGroup } from '../Util';

export const RestartLessonReviewGames = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Lesson Review Games"
    name="lesson_game_retry"
    text={<>Allow students to retake lesson review games by clicking a restart button.<br />
            Only their most recent score will be recorded on the Grades page.</>}
  />
);

export const RestartVocabularyGames = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Vocabulary Games"
    name="vocab_game_retry"
    text={<>Allow students to retake vocabulary games by clicking a restart button.<br />
            Only their most recent score will be recorded on the Grades page.</>}
  />
);

export const RestartVideoActivities = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Video Activities"
    name="video_activity_retry"
    text={<>Allow students to retake Video Activities by clicking a restart button.<br />
            Only their most recent score will be recorded on the Grades page.</>}
  />
);

export const RestartReadingChecks = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Reading Checks"
    name="reading_game_retry"
    text={<>Allow students to retake reading checks by clicking a restart button.<br />
            Only their most recent score will be recorded on the Grades page.</>}
  />
);

export const RestartClassroomActivities = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Classroom Activities"
    name="classroom_activity_retry"
    text={<>Allow students to retake Classroom Activities by clicking a restart button.<br />
      Only their most recent score will be recorded on the Grades page.</>}
  />
);

export const RestartLessonOutlines = ({ form, values }) => (
  <CheckboxOrRadioGroup
    form={form}
    values={values}
    label="Restart Lesson Outlines"
    name="lesson_outline_retry"
    text={<>Allow students to retake Lesson Outline Slideshows by clicking a restart button.<br />
      Only their most recent score will be recorded on the Grades page.</>}
  />
);
