import React from 'react';
import { twMerge } from '@/utils';

export interface ILabelProps extends React.HTMLProps<HTMLLabelElement> {
  children: ReactChildren,
  className?: string;
  required?: boolean;
}

const Label = ({
  children,
  className = '',
  required = false,
  htmlFor,
}: ILabelProps) => (
  <label className={twMerge('tw-w-[130px]', className)} htmlFor={htmlFor}>
    {children}
    {required && (<span className="tw-text-[red]">*</span>)}
  </label>
);

export default Label;
