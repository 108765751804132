import React, { Component } from 'react';
import Modal, { CancelButton, SubmitButton } from 'common/Modal';
import Axios from 'axios';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import { poll } from '../../../modules/TCIUtils';
import I18n from 'i18n-js';

export default class SubmitAssessmentButton extends Component {
  static propTypes = {
    assessmentId: PropTypes.number.isRequired,
    classroomId: PropTypes.number.isRequired,
    lessonId: PropTypes.number.isRequired,
    programId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { error: false, modalOpen: false, submittingAssessment: false };

    this.onError = this.onError.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitAssessment = this.submitAssessment.bind(this);
  }

  static checkNoDirtyQuestions() {
    return !document.querySelector('[data-dirty=true]');
  }

  openModal() {
    this.setState({ isLoading: true, modalOpen: true });

    return poll(SubmitAssessmentButton.checkNoDirtyQuestions, 3000, 500)
      .then(() => {
        const allQuestionsAnswered = !document.querySelector('[data-answered=false]');
        this.setState({ allQuestionsAnswered, isLoading: false });
      })
      .catch(this.onError);
  }

  onError() {
    this.setState({ error: true, isLoading: false });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  modalText() {
    if (this.state.allQuestionsAnswered) {
      return 'Are you sure you want to turn in this assessment? You cannot make any more changes after this.';
    }

    return (
      <React.Fragment>
        You have unanswered questions.
        You will <strong>not</strong> be able to answer these questions after you turn in.
        Are you sure you want to turn in your assessment?
      </React.Fragment>
    );
  }

  submitAssessment() {
    this.setState({ submittingAssessment: true });

    const submitAssessmentPath = Routes.shared_program_lesson_assessment_path(
      this.props.programId,
      this.props.lessonId,
      this.props.assessmentId,
      { classroom_id: this.props.classroomId }
    );

    return Axios
      .put(submitAssessmentPath)
      .then((response) => { window.location.assign(response.data.new_url); })
      .catch(this.onError);
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn--purple" onClick={this.openModal}>{I18n.t('turn_in')}</button>
        <Modal
          isOpen={this.state.modalOpen}
          headerText="Turn In Assessment"
          closeModal={this.closeModal}
          closeable={!this.state.error}
          isLoading={this.state.isLoading}
          error={this.state.error}
        >
          {this.modalText()}

          <hr />

          <footer>
            <CancelButton onCancel={this.closeModal} />
            <SubmitButton
              onSubmit={this.submitAssessment}
              isSubmitting={this.state.submittingAssessment}
              text="Yes, Turn In"
            />
          </footer>
        </Modal>
      </React.Fragment>
    );
  }
}
