import cn from '@/utils/cn';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SchemaType, fileExtension, shouldUseTextures, CurrentValues, getExtension } from './utils';
import ThreeDModelTextureFields from './ThreeDModelTextureFields';
import RequiredMaterialNamesList from './RequiredMaterialNamesList';

const validFileExtensions = ['.glb', '.gltf', '.obj'] as const;
const fileRequirementText = `File Requirement: ${validFileExtensions.join(', ')}`;

interface ThreeDModelUploadFormProps {
  currentValues: CurrentValues;
  form: UseFormReturn<SchemaType, any>;
  slideObjectId: string | number;
}

const ThreeDModelUploadForm = ({ currentValues, form, slideObjectId }: ThreeDModelUploadFormProps) => {
  const { register, watch } = form;

  const modelValue = watch('model');
  const currentFileExtension = getExtension(currentValues.fileName);
  const extension = fileExtension(modelValue);
  const useTextures = shouldUseTextures(extension || currentFileExtension);

  return (
    <div className="tw-mt-2 tw-mb-10">
      <RequiredMaterialNamesList form={form} slideObjectId={slideObjectId} />
      {currentValues.fileName && (
        <div className="tw-flex tw-items-center tw-gap-5 tw-mb-10">
          <span className="tw-font-bold tw-ml-0.5">Current Model File</span>
          <span>{currentValues.fileName}</span>
        </div>
      )}
      <div className="tw-flex tw-items-center tw-gap-12 tw-mb-10">
        <label className="after:tw-text-red after:tw-content-['*']" htmlFor="model">
          <span className="tw-font-bold">3D Model File</span>
        </label>
        <div>
          <div
            data-requirement-text={fileRequirementText}
            className={cn(
              'tw-relative after:tw-content-[attr(data-requirement-text)]',
              'after:tw-absolute after:tw-left-0 after:tw-top-full after:tw-mt-1',
            )}
          >
            <input accept={validFileExtensions.join(',')} type="file" {...register('model')} />
          </div>
        </div>
      </div>
      {useTextures && <ThreeDModelTextureFields currentValues={currentValues} form={form} />}
    </div>
  );
};

export default ThreeDModelUploadForm;
