import React from 'react';
import PropTypes from 'prop-types';
import styles from './VirtualStamps.module.scss';

import stampCorrectIcon1 from 'images/stamps/stamp-correct-checkmark.svg';
import stampCorrectIcon2 from 'images/stamps/stamp-correct-thumbs-up.svg';
import stampCorrectIcon3 from 'images/stamps/stamp-correct-excellent-work.svg';
import stampCorrectIcon4 from 'images/stamps/stamp-correct-pigeon-trophy.svg';
import stampCorrectIcon5 from 'images/stamps/stamp-correct-alpaca-party-hat.svg';
import stampIncorrectIcon1 from 'images/stamps/stamp-incorrect-x.svg';
import stampIncorrectIcon2 from 'images/stamps/stamp-incorrect-confused.svg';
import stampIncorrectIcon3 from 'images/stamps/stamp-incorrect-incomplete.svg';
import stampIncorrectIcon4 from 'images/stamps/stamp-incorrect-pigeon-x.svg';
import stampIncorrectIcon5 from 'images/stamps/stamp-incorrect-robot-question.svg';
import stampGeneralIcon1 from 'images/stamps/stamp-general-star.svg';
import stampGeneralIcon2 from 'images/stamps/stamp-general-way-to-go.svg';
import stampGeneralIcon3 from 'images/stamps/stamp-general-creative.svg';
import stampGeneralIcon4 from 'images/stamps/stamp-general-showing-growth.svg';
import stampGeneralIcon5 from 'images/stamps/stamp-general-lookin-sharp.svg';
import stampGeneralIcon6 from 'images/stamps/stamp-general-good-effort.svg';

const stamps = {
  'stamp-correct-checkmark': stampCorrectIcon1,
  'stamp-correct-thumbs-up': stampCorrectIcon2,
  'stamp-correct-excellent-work': stampCorrectIcon3,
  'stamp-correct-pigeon-trophy': stampCorrectIcon4,
  'stamp-correct-alpaca-party-hat': stampCorrectIcon5,
  'stamp-incorrect-x': stampIncorrectIcon1,
  'stamp-incorrect-confused': stampIncorrectIcon2,
  'stamp-incorrect-incomplete': stampIncorrectIcon3,
  'stamp-incorrect-pigeon-x': stampIncorrectIcon4,
  'stamp-incorrect-robot-question': stampIncorrectIcon5,
  'stamp-general-star': stampGeneralIcon1,
  'stamp-general-way-to-go': stampGeneralIcon2,
  'stamp-general-creative': stampGeneralIcon3,
  'stamp-general-showing-growth': stampGeneralIcon4,
  'stamp-general-lookin-sharp': stampGeneralIcon5,
  'stamp-general-good-effort': stampGeneralIcon6
};

const VirtualStamp = ({ stampName }) => (
    <div className={styles.virtualStamp}>
      <img src={stamps[stampName]} alt=""/>
    </div>
  );

VirtualStamp.propTypes = {
  stampName: PropTypes.string.isRequired
};

export default VirtualStamp;
