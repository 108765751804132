import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import AutoSave from 'common/Forms/AutoSave';
import TagSelect from './TagSelect';
import { CATEGORIES } from './utils';

const TagsForm = ({ buildingTowardsIds, onSubmit, tagIds }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{
      building_towards_ids: buildingTowardsIds,
      tag_ids: tagIds,
    }}
    render={({ handleSubmit }) => (
      <form className="tags-form" onSubmit={handleSubmit}>
        <AutoSave debounce={500} save={handleSubmit} />
        {CATEGORIES.map(category => <TagSelect key={category.label} {...category} />)}
      </form>
    )}
  />
);

TagsForm.defaultProps = {
  buildingTowardsIds: [],
  tagIds: [],
};

TagsForm.propTypes = {
  buildingTowardsIds: PropTypes.arrayOf(PropTypes.number),
  onSubmit: PropTypes.func.isRequired,
  tagIds: PropTypes.arrayOf(PropTypes.number),
};

export default TagsForm;
