/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const EconomicsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.58503 11.4968V14.4184H4.91495V8.98565L4.66727 8.73829L1.58503 11.4968ZM5.88183 9.94949V14.4188H9.21207V9.61237L7.31559 11.3778L5.88183 9.94949ZM11.4031 2.50933L12.3247 3.42437L7.32983 8.07637L4.68871 5.44453L0.796387 8.92885L1.58423 9.80917L4.64519 7.06933L7.30215 9.71685L13.1639 4.25765L14.1757 5.26197L15.1732 1.48389L11.4031 2.50933ZM10.2199 8.67365V14.4184H13.5501V5.99845L13.3458 5.76213L10.2199 8.67365Z"
      fill="currentColor"
    />
  </svg>
);

export default EconomicsIcon;
