import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import ModalBody from '../../common/VideoManager/ModalBody';
import { SubmitError } from '../../common/Forms/Utils';
import { Footer } from '../../common/Modal';
import { initialValueShapeProps } from './Utils';

const FormModal = ({
  hasSubmitError, initialValues, loading, onSubmit, modalBodyProps
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <ModalBody {...modalBodyProps} />
        {hasSubmitError && <SubmitError error="Error adding video. Please reload the page and try again." />}
        <Footer submitting={loading} />
      </form>
    )}
  />
);

FormModal.defaultProps = {
  hasSubmitError: false,
  initialValues: {},
  loading: false,
  modalBodyProps: {
    hasVideoLessonOption: false,
    showWidth: false
  }
};

FormModal.propTypes = {
  hasSubmitError: PropTypes.bool,
  initialValues: initialValueShapeProps,
  loading: PropTypes.bool,
  modalBodyProps: PropTypes.shape({
    initialVideoFileName: PropTypes.string,
    showAutoplayOption: PropTypes.bool,
    showVideoLessonOption: PropTypes.bool,
    showWidth: PropTypes.bool
  }),
  onSubmit: PropTypes.func.isRequired
};

export default FormModal;
