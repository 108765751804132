import React from 'react';
import PropTypes from 'prop-types';
import styles from './JumpToSectionButton.module.scss';

const SectionMenu = ({ sectionGroups, closeMenu }) => {
  const jumpToSectionOnPage = (sectionId) => {
    const $iframe = $(`#student-text-iframe-${sectionId}`);

    if (!$iframe[0]) return;

    const iframeIsHidden = $iframe[0].offsetParent === null;

    if (iframeIsHidden) {
      const $sectionHeader = $iframe.closest('.section').find('.section-header');
      $sectionHeader.data('skip-animation', true);
      $sectionHeader.click();
    }

    $([document.documentElement, document.body]).animate({
      scrollTop: $iframe.offset().top - 20
    }, 500);
  };

  const sectionLink = section => (
    <div key={section.id}>
      <div>
        <a
          href="#"
          role="button"
          onClick={(e) => {
            e.preventDefault();
            jumpToSectionOnPage(section.id);
          }}
        >
          {section.label}
        </a>
      </div>
    </div>
  );

  const closeButton = () => (
    <button
      className={`close btn btn--link-white ${styles.closeButton}`}
      type="button"
      aria-label="Close Jump to Section menu"
      onClick={closeMenu}
    >
      ✕
    </button>
  );

  const renderSectionGroups = () => (
    sectionGroups.map(sectionGroup => (
      <div key={sectionGroup.title} className={styles.sectionListBody}>
        <div className={styles.sectionGroupTitle}>{sectionGroup.title}</div>
        {sectionGroup.sections.map(section => sectionLink(section))}
      </div>
    ))
  );

  return (
    <div id="jumpToSectionMenu" className={`card ${styles.sectionList}`}>
      <div className={styles.sectionListHeader}>
        <span>Jump to Section:</span>
        {closeButton()}
      </div>
      {renderSectionGroups()}
    </div>
  );
};

SectionMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  sectionGroups: PropTypes.arrayOf(PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      section: PropTypes.string
    })).isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
};

export default SectionMenu;
