import React from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import { toCamelCase, toSnakeCase } from 'modules/TCIUtils';
import { IHtmlElement } from '@/components/interfaces/graphql/HtmlElement';
import IJumpToButton from '@/components/interfaces/graphql/HtmlElement/JumpToButton';
import IHintButton from '@/components/interfaces/graphql/HtmlElement/HintButton';
import IQuestionButton from '@/components/interfaces/graphql/HtmlElement/QuestionButton';
import IReadingButton from '@/components/interfaces/graphql/HtmlElement/ReadingButton';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';
import IVideoButton from '@/components/interfaces/graphql/HtmlElement/VideoButton';
import { HtmlElementBase } from '@/components/interfaces/graphql/HtmlElement/HtmlElementUtils';
import TooltipButtonSnippet from './TooltipButton/Snippet';
import VideoButtonSnippet from './VideoButton/Snippet';
import QuestionButtonSnippet from './QuestionButton/Snippet';
import HintButtonSnippet from './HintButton/Snippet';
import ReadingButtonSnippet from './ReadingButton/Snippet';
import JumpToButtonSnippet from './JumpToButton/Snippet';

interface CreateOrUpdateHtmlElementArgs {
  id?: number | string;
  type: HtmlElementBase['type'];
  modelId: HtmlElementBase['modelId'];
  modelType: HtmlElementBase['modelType'];
  htmlEn: HtmlElementBase['htmlEn'];
  locale: 'en' | 'es';
}

const SnippetFactory = () => {
  const createOrUpdateHtmlElement = async ({
    id,
    type,
    modelId,
    modelType,
    htmlEn,
    locale = 'en',
    ...rest
  }: CreateOrUpdateHtmlElementArgs) => {
    const htmlLocale = locale === 'en' ? '' : 'Es';
    const path = id ? Routes.plato_api_html_element_path(id) : Routes.plato_api_html_elements_path();
    const params = toSnakeCase({ type, modelId, modelType, [`html${htmlLocale}`]: htmlEn, ...rest });
    const response = await Axios[id ? 'patch' : 'post'](path, params);

    const htmlElement: IHtmlElement = toCamelCase(response.data.data);

    switch (type) {
      case 'HintButton':
        return <HintButtonSnippet hintButton={htmlElement as IHintButton} />;
      case 'JumpToButton':
        return <JumpToButtonSnippet jumpToButton={htmlElement as IJumpToButton} />;
      case 'QuestionButton':
        return <QuestionButtonSnippet questionButton={htmlElement as IQuestionButton} />;
      case 'ReadingButton':
        return <ReadingButtonSnippet readingButton={htmlElement as IReadingButton} />;
      case 'TooltipButton':
        return <TooltipButtonSnippet tooltipButton={htmlElement as ITooltipButton} />;
      case 'VideoButton':
      default:
        return <VideoButtonSnippet videoButton={htmlElement as IVideoButton} />;
    }
  };

  return {
    createOrUpdateHtmlElement,
  };
};

export default SnippetFactory;
