import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Slide.module.scss';
import { slideObjectPropTypes } from '../../Utils';
import AddImage from '../../../ImageManager/Add';
import EditImage from '../../../ImageManager/Edit';
import useSlideShowImageFetcher from '../../Utils/useSlideShowImageFetcher.ts';
import useSlideShowContext from '../../stores/useSlideShowContext';

const SlideObjectImage = ({ slideObject, modalManager, interactable }) => {
  const { fetchImages } = useSlideShowImageFetcher();

  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const locale = useSlideShowContext(state => state.locale);
  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const afterSave = (res) => {
    const imageModel = res.length ? res[0] : res;

    updateSlideObject({ ...slideObject, imageModel });
  };

  const renderModal = () => {
    if (slideObject.imageModel) {
      const imageModel = {
        ...slideObject.imageModel,
        id: parseInt(slideObject.imageModel.id, 10),
        image_credits: slideObject.imageModel.image.credits,
        image_descriptionConfidence: slideObject.imageModel.image.descriptionConfidence,
        image_descriptionEn: slideObject.imageModel.image.descriptionEn,
        image_descriptionEs: slideObject.imageModel.image.descriptionEs,
        image_fileName: slideObject.imageModel.image.fileName,
        image_fileNameEs: slideObject.imageModel.image.fileNameEs,
        image_titleEn: slideObject.imageModel.image.titleEn,
        image_titleEs: slideObject.imageModel.image.titleEs
      };

      return (
        <EditImage
          afterMergeDuplicates={afterSave}
          afterSubmit={afterSave}
          dontMergeCallback={afterSave}
          imageModel={imageModel}
          modalManager={modalManager}
          modelId={parseInt(slideObject.id, 10)}
          modelType="SlideObject"
          standaloneButton={false}
          getExistingImages={fetchImages}
          isAdmin={isAdmin}
          fromSlideShow
          excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
        />
      );
    }

    return (
      <AddImage
        afterMergeDuplicates={afterSave}
        afterSubmit={afterSave}
        dontMergeCallback={afterSave}
        modalManager={modalManager}
        modelId={parseInt(slideObject.id, 10)}
        modelType="SlideObject"
        standaloneButton={false}
        getExistingImages={fetchImages}
        isAdmin={isAdmin}
        fromSlideShow
        excludeFields={['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es']}
      />
    );
  };

  const determineSrc = () => {
    const { imageModel: { image: { imageEsUrl, imageUrl } } } = slideObject;

    if (locale === 'es' && imageEsUrl && !imageEsUrl.includes('missing.png')) return imageEsUrl;

    return imageUrl;
  };

  const renderImage = () => {
    const { imageModel: { image: { descriptionEn, descriptionEs } } } = slideObject;
    const description = locale === 'es' ? descriptionEs || descriptionEn : descriptionEn;

    return (
      <img
        src={determineSrc()}
        alt={description || ''}
        className={styles.slideObjectImage}
        data-zoomurl={slideObject.imageModel.image.imageUrl}
      />
    );
  };

  return (
    <div
      key={slideObject.id}
      className={`slideObjectImageContainer ${styles.slideObjectImageContainer}`}
    >
      {slideObject.imageModel && renderImage()}
      {interactable && renderModal()}
    </div>
  );
};

SlideObjectImage.propTypes = {
  interactable: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  slideObject: slideObjectPropTypes.isRequired,
};

SlideObjectImage.defaultProps = {
  interactable: true
};

export default SlideObjectImage;
