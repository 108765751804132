import React, { Fragment, useState } from 'react';
import Axios from 'axios';
import { plato_api_slide_path } from '@/modules/routes';
import Modal, { Footer } from '@/components/common/Modal';
import { SubmitError } from '@/components/common/Forms/Utils';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { Slide } from '../types';

interface RemoveSlideProps {
  modalManager: ModalManager;
  slide: Slide;
}

const RemoveSlide = ({ modalManager, slide } : RemoveSlideProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const removeSlide = useSlideShowContext(state => state.removeSlide);

  const destroySlide = () => {
    setSubmitting(true);
    Axios.delete(plato_api_slide_path(slide.id))
      .then((result) => {
        if (!result.data.data.id) {
          setHasError(true);
          setSubmitting(false);
          return;
        }

        removeSlide(slide);
        setSubmitting(false);
        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setSubmitting(false);
      });
  };

  const renderModal = () => (
    <Modal isOpen={modalManager.isOpen} closeModal={modalManager.close} headerText={`Delete Slide ${slide.position}`}>
      <p>Are you sure you want to delete this slide?</p>
      {hasError && <SubmitError error="Error deleting slide. Please reload the page and try again." />}
      <Footer
        primaryButtonText="Yes, delete"
        primaryButtonCallback={destroySlide}
        primaryButtonSubmit={false}
        secondaryButtonCallback={modalManager.close}
        primaryButtonClassName="btn btn--red"
        submitting={submitting}
      />
    </Modal>
  );

  return (
    <Fragment>
      <div>
        <button
          aria-label="Delete slide"
          className="dropdown-item full-size"
          onClick={modalManager.open}
          type="button"
        >
          Delete Slide
        </button>
      </div>
      {renderModal()}
    </Fragment>
  );
};

export default RemoveSlide;
