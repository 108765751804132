import useThreeJs from '@/hooks/useThreeJs';
import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Mesh } from 'three';
import useThreeDModel from './useThreeDModel';
import { SchemaType } from './utils';

interface RequiredTextureListProps {
  form: UseFormReturn<SchemaType, any>;
  slideObjectId: string | number;
}

const RequiredMaterialNamesList = ({ form, slideObjectId }: RequiredTextureListProps) => {
  const [materialNames, setMaterialNames] = useState<string[]>([]);
  const [modelUrl, setModelUrl] = useState<string>();
  const ref = useRef<HTMLDivElement>();
  const texturesRef = useRef([]);

  const modelValue = form.watch('model');
  const { data: threeDModel } = useThreeDModel({ slideObjectId });

  useEffect(() => {
    if (modelValue && modelValue.length > 0) {
      if (modelValue[0].name.includes('.gltf') || modelValue[0].name.includes('.glb')) return;

      const url = URL.createObjectURL(modelValue[0]);
      setModelUrl(url);
    } else if (threeDModel?.model_url) {
      if (threeDModel.model_url.includes('.gltf') || threeDModel.model_url.includes('.glb')) return;

      setModelUrl(threeDModel.model_url);
    }
  }, [threeDModel?.model_url, modelValue]);

  useThreeJs({
    modelUrl,
    onSuccess: ({ model }) => {
      const tempMaterialNames: string[] = [];

      model.traverse((child: Mesh) => {
        if (Array.isArray(child.material)) {
          child.material.forEach((mat) => {
            if (!mat) return;

            tempMaterialNames.push(mat.name);
          });

          return;
        }

        if (!child.material) return;

        tempMaterialNames.push(child.material.name);
      });

      setMaterialNames([...new Set(tempMaterialNames)]);
    },
    ref,
    textures: texturesRef.current,
  });

  return (
    <>
      {materialNames.length === 0 && <div className="tw-hidden" ref={ref} />}
      {materialNames.length > 0 && (
        <div className="tw-text-center">
          <p>
            Ensure the texture files contain one of these names in them so they can be mapped appropriately.
          </p>
          <ul className="tw-font-bold tw-mb-4">
            {materialNames.join(', ')}
          </ul>
        </div>
      )}
    </>
  );
};

export default RequiredMaterialNamesList;
