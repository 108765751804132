import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import AutoSave from 'common/Forms/AutoSave';
import Select from 'react-select';
import styles from './BackgroundPositionManager.module.scss';
import './react-select-reset.css';

const BackgroundPositionManager = ({
  defaultValue,
  includeVertical,
  initialSliderPosition,
  initialSliderPositionY,
  minWidth,
  object,
  objectRef,
  options,
  relocate,
  submitHandler,
}) => {
  const isCustom = useMemo(() => {
    const { backgroundPosition } = object;

    return backgroundPosition && backgroundPosition.match(/center \d+/g);
  }, [object.backgroundPosition]);

  const initialBgPosition = ({ backgroundPosition }) => {
    if (backgroundPosition) {
      if (isCustom) return 'custom';

      return backgroundPosition;
    }

    return defaultValue;
  };

  const initialPosition = ({ backgroundPosition }) => {
    if (isCustom) return backgroundPosition.replace('center ', '');

    return initialSliderPosition;
  };

  const initialValues = {
    backgroundPosition: initialBgPosition(object),
    customPosition: initialPosition(object),
    customPositionY: initialSliderPositionY,
  };

  const renderDropdown = ({ input }) => (
    <div>
      <label htmlFor="backgroundPosition">Position Type</label>
      <Select
        {...input}
        value={options.find(option => option.value === input.value)}
        clearable={false}
        inputId="backgroundPosition"
        onChange={(opt) => {
          input.onChange(opt.value);
          relocate && relocate();
        }}
        options={options}
        searchable={false}
      />
    </div>
  );

  const renderSlider = ({ input }) => {
    const isVertical = input.name === 'customPositionY';

    return (
      <div className={`${styles.rangeContainer} ${isVertical ? styles.vertical : ''}`}>
        <label htmlFor="custom-position" className="hidden">Custom Range</label>
        <input
          className={`${styles.rangeInput} ${isVertical ? styles.verticalInput : ''}`}
          id="custom-position"
          type="range"
          min="0"
          max="100"
          {...input}
          onChange={(e) => {
            $(objectRef.current)
              .css({ backgroundPosition: `center ${e.target.value}%` });
            input.onChange(e.target.value);
          }}
        />
        <input
          className={styles.rangeValueInput}
          type="text"
          {...input}
          value={`${(input.value || '0')}%`}
          onChange={(e) => {
            input.onChange(e.target.value.replace('%', ''));
          }}
        />
      </div>
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submitHandler}
      render={({ handleSubmit, values }) => (
        <form role="form" className={`${styles.form} ${minWidth ? styles.minWidth : ''}`} onSubmit={handleSubmit}>
          <AutoSave debounce={400} save={submitHandler} />
          <Field
            value={options[0].value}
            name="backgroundPosition"
            render={renderDropdown}
          />
          {values.backgroundPosition === 'custom' && (
            <Field
              name="customPosition"
              render={renderSlider}
            />
          )}
          {values.backgroundPosition === 'custom' && includeVertical && (
            <Field
              name="customPositionY"
              render={renderSlider}
            />
          )}
        </form>
      )}
    />
  );
};

BackgroundPositionManager.defaultProps = {
  defaultValue: 'center center',
  includeVertical: false,
  initialSliderPosition: '50',
  initialSliderPositionY: '100',
  minWidth: true,
  objectRef: React.createRef(),
  options: [
    { label: 'Top', value: 'center top' },
    { label: 'Bottom', value: 'center bottom' },
    { label: 'Center', value: 'center center' },
    { label: 'Custom', value: 'custom' },
  ],
};

BackgroundPositionManager.propTypes = {
  defaultValue: PropTypes.string,
  includeVertical: PropTypes.bool,
  initialSliderPosition: PropTypes.string,
  initialSliderPositionY: PropTypes.string,
  minWidth: PropTypes.bool,
  object: PropTypes.shape({
    backgroundPosition: PropTypes.string,
  }).isRequired,
  objectRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  relocate: PropTypes.func,
  submitHandler: PropTypes.func.isRequired,
};

export default BackgroundPositionManager;
