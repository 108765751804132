import React, { ReactNode, useState } from 'react';
import I18n from '@/modules/i18n';
import { SubmitButton } from '@/components/ui/Buttons';
import { Form, Field } from 'react-final-form';
import { SelectField } from '@/components/common/Forms';
import CheckboxGroupDropdown from '@/components/common/Forms/CheckboxGroupDropdown';
import { ISlide } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import { toCamelCase } from '@/modules/TCIUtils';
import showToast from '@/components/common/Toast';
import { ACTIONS, CONDITIONS, selectableSlides } from './utils';

interface EditSlideConditionProps {
  slide: ISlide;
}

interface FormValues {
  slideConditionId: number | string;
  slideActionId: number | string;
  selectedSlideIds: [number | string];
}

const UNFILTERED_SLIDE_CONDITION_OPTIONS = [
  { label: I18n.t('questions_completed_from_slides'), value: CONDITIONS.QuestionsCompletedFromSlides },
  { label: I18n.t('questions_completed_from_this_slide'), value: CONDITIONS.QuestionsCompletedFromThisSlide },
];

const EditSlideCondition = ({ slide }: EditSlideConditionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const updateSlide = useSlideShowContext(state => state.updateSlide);
  const slides = useSlideShowContext(state => state.slides);

  const slidesWithQuestions = slides.filter((member: ISlide) => (
    Object.keys(member).includes('slideObjects')
    && (member.slideObjects || []).find(so => (
      (so.answerableQuestionIds || []).length > 0
      || (so.htmlElements || []).find(el => el.type === 'QuestionButton')
    ))
  )) as ISlide[];

  const isQuestionsCompletedFromSlidesSelected = values => values.slideConditionId === CONDITIONS.QuestionsCompletedFromSlides;
  const isQuestionsCompletedFromThisSlideSelected = values => values.slideConditionId === CONDITIONS.QuestionsCompletedFromThisSlide;

  const isSubmitDisabled = (values, pristine) => {
    if (pristine) return true;
    if (!values.slideConditionId || !values.slideActionId) return true;

    if (isQuestionsCompletedFromSlidesSelected(values)) {
      return (values.selectedSlideIds || []).length === 0;
    }

    return false;
  };

  // This function gives a layout to achieve the desired UI design,
  // with a fixed save button in the lower right and conditionally-appearing dropdown in the second row.
  const dropdownRow = (
    { column1, column2, column3, wrapperClass }:
    { column1?: ReactNode, column2?: ReactNode, column3?: ReactNode, wrapperClass?: string },
  ) => (
    <div className={`tw-flex tw-flex-row ${column3 ? '' : 'tw-pb-4'} ${wrapperClass}`}>
      <div className="tw-flex tw-basis-11/12 tw-flex-row tw-justify-between">
        <div className="tw-flex tw-w-[45%]">{column1}</div>
        <div className="tw-flex tw-w-[45%]">{column2}</div>
      </div>
      <div className="tw-flex tw-basis-1/12 tw-items-end tw-justify-end">
        {column3}
      </div>
    </div>
  );

  const dropdownWithLabel = ({ selectField, label }: { selectField: ReactNode, label?: string }) => (
    <>
      <div className="tw-flex tw-justify-end tw-pr-5 tw-pt-6 tw-basis-1/6 tw-font-bold tw-text-base tw-tracking-widest">
        {label}
      </div>
      <div className="tw-basis-5/6">
        {selectField}
      </div>
    </>
  );

  const slideConditionOptions = UNFILTERED_SLIDE_CONDITION_OPTIONS.filter((option) => {
    if (option.value !== CONDITIONS.QuestionsCompletedFromThisSlide) return true;

    return slidesWithQuestions.some(s => s.id === slide.id);
  });

  const slideActionOptions = values => ([
    {
      label: I18n.t('unlock_navigation_action'),
      value: ACTIONS.UnlockNavigation,
      disabled: isQuestionsCompletedFromThisSlideSelected(values),
    },
    {
      label: I18n.t('unlock_next_slide'),
      value: ACTIONS.UnlockNextSlide,
      disabled: isQuestionsCompletedFromSlidesSelected(values),
    },
  ]);

  const conditionsSelectField = change => (
    <SelectField
      label={I18n.t('conditions')}
      name="slideConditionId"
      placeholder={I18n.t('select_a_condition')}
      onChange={(slideConditionId) => {
        if (slideConditionId == null) {
          change('slideActionId', null);
          change('selectedSlideIds', null);
        }
      }}
      options={slideConditionOptions}
      fieldClass="tw-w-full"
      labelClass="tw-pb-1"
      rowClass="tw-flex tw-flex-col tw-w-full"
    />
  );

  const actionsSelectField = values => (
    <SelectField
      label={I18n.t('actions')}
      name="slideActionId"
      placeholder={I18n.t('select_an_action')}
      options={slideActionOptions(values)}
      fieldClass="tw-w-full"
      labelClass="tw-pb-1"
      rowClass="tw-flex tw-flex-col tw-w-full"
    />
  );

  const slidesSelectField = ({ values, formChangeValue }) => {
    const slideOptions = selectableSlides(slide, slidesWithQuestions)
      .map(s => ({
        label: `${I18n.t('slide')} ${s.position}`,
        value: parseInt(s.id as any as string, 10),
      }));

    let toggleText: string | JSX.Element = 'No previous slides found';

    if (slideOptions.length > 0) {
      toggleText = slideOptions
        .filter(option => (values.selectedSlideIds || []).includes(option.value))
        .map(option => option.label.replace(`${I18n.t('slide')} `, ''))
        .join(', ') || <span className="tw-text-[#aaa]">{I18n.t('select_slides')}</span>;
    }

    return (
      <>
        <label className="tw-pb-1">
          {I18n.t('slide_or_slides')}
        </label>
        <Field
          bulkSelectText={I18n.t('all_slides')}
          buttonClassName="tw-w-full"
          disabled={slideOptions.length === 0}
          component={CheckboxGroupDropdown}
          dropdownClassName="tw-w-full"
          formChangeValue={formChangeValue}
          hasBulkSelect
          legendText={I18n.t('slide_or_slides')}
          name="selectedSlideIds"
          options={slideOptions}
          toggleText={toggleText}
        />
      </>
    );
  };

  return (
    <Form
      mutators={{
        changeValue: (args, state, utils) => utils.changeValue(state, args[1], () => args[0]),
      }}
      onSubmit={({ slideConditionId, slideActionId, selectedSlideIds }: FormValues) => {
        setIsLoading(true);
        Axios
          .patch(Routes.plato_api_condition_path(slide.condition.id, {
            condition: slideConditionId,
            _action: slideActionId,
            slide_ids: selectedSlideIds,
          }))
          .then((res) => {
            setError(null);
            updateSlide(slide.id, { condition: toCamelCase(res.data.data) });
            showToast(I18n.t('slide_conditions_updated', { position: slide.position }), { autoClose: 6000 });
          })
          .catch(err => setError(err))
          .finally(() => setIsLoading(false));
      }}
      initialValues={{
        selectedSlideIds: slide.condition.slideIds,
        slideActionId: slide.condition.action,
        slideConditionId: slide.condition.condition,
      }}
      render={({ handleSubmit, pristine, values, form: { change, mutators } }) => {
        const needsSlideSelection = isQuestionsCompletedFromSlidesSelected(values);

        return (
          <form className="tw-flex tw-flex-col tw-w-full" onSubmit={handleSubmit}>
            {dropdownRow({
              column1: dropdownWithLabel({label: I18n.t('if').toUpperCase(), selectField: conditionsSelectField(change)}),
              column2: dropdownWithLabel({label: I18n.t('then').toUpperCase(), selectField: actionsSelectField(values)}),
            })}

            {dropdownRow({
              column1: needsSlideSelection && dropdownWithLabel({
                label: '',
                selectField: slidesSelectField({
                  formChangeValue: mutators.changeValue,
                  values,
                }),
              }),
              column3: (
                <div>
                  <SubmitButton
                    className="tw-py-1 tw-px-2"
                    disabled={isSubmitDisabled(values, pristine)}
                    submitting={isLoading}
                  >
                    {I18n.t('save')}
                  </SubmitButton>
                </div>
              ),
              wrapperClass: needsSlideSelection ? '' : 'tw-pt-4',
            })}
            {error && <div className="tw-text-red">Something went wrong...</div>}
          </form>
        );
      }}
    />
  );
};

export default EditSlideCondition;
