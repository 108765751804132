import IGlossaryTerm from '@/components/interfaces/GlossaryTerm';
import { plato_api_get_glossary_terms_glossary_terms_path } from '@/modules/routes';
import axios, { AxiosResponse } from 'axios';
import FE from 'froala-editor';
import ReactDOM from 'react-dom';
import React from 'react';
import { FROALA_TEMPLATE_NAMES } from '../../froalaUtils';
import { icon } from './icon';
import { cleanUpDeleteButton, refreshTermsInEditor, sortedTerms, vocabBoxHtml } from './vocabBoxUtils';
import DeleteVocabBox from './DeleteVocabBox';

type ApiResponse = { glossary_terms: IGlossaryTerm[] };

export const VOCAB_BOX = {
  commandName: 'VocabBox' as const,
  events: {
    init: async (lessonId: string | number, editor: FE, selector: string, locale: Locale) => {
      if (!lessonId) return;

      let res: AxiosResponse<ApiResponse> | null;

      try {
        res = await axios
          .get<ApiResponse>(plato_api_get_glossary_terms_glossary_terms_path({ lesson_id: lessonId }));
      } catch {
        return;
      }

      VOCAB_BOX.glossaryTerms = sortedTerms(res.data.glossary_terms);

      setTimeout(() => {
        refreshTermsInEditor(VOCAB_BOX.glossaryTerms, {
          locale,
          selector,
        });
      }, 10);

      editor.html.set(editor.html.get());
      editor.undo.saveStep();
    },
    add: ({ editor, lessonId, locale = 'en', programId }: {
      editor: FE,
      lessonId: number | string,
      locale: Locale,
      programId: number | string
    }) => {
      editor.html.insert(vocabBoxHtml(VOCAB_BOX.glossaryTerms, locale, lessonId, programId), true);
    },
    mousedown: (e: MouseEvent, editor: FE) => {
      const vocabBoxes = document.querySelectorAll('.vocab-box');

      if ([...vocabBoxes].some(node => node.contains(e.target as Node))) {
        e.preventDefault();
        e.stopPropagation();

        if (!editor) return;

        const targetElement = e.target as HTMLElement;
        if (targetElement.className.includes('btn')) return;

        const isVocabBox = targetElement.id.includes('vocab-box');
        const isHeader = targetElement.className.includes('header');
        const isTerm = targetElement.className.includes('term');
        if (!(isVocabBox || isHeader || isTerm)) return;

        const vocabBox = $(targetElement).closest('.vocab-box').get(0);
        const checkDeleteButton = document.getElementById('vocabBoxDeleteContainer');

        if (checkDeleteButton) {
          cleanUpDeleteButton();
        } else {
          const vocabBoxDeleteContainer = document.createElement('div');
          vocabBoxDeleteContainer.id = 'vocabBoxDeleteContainer';
          document.body.appendChild(vocabBoxDeleteContainer);
          setTimeout(() => {
            ReactDOM.render(
              React.createElement(DeleteVocabBox, { vocabBox, editor }),
              vocabBoxDeleteContainer,
            );
          }, 100);
        }
      }
    },
  },
  glossaryTerms: [] as IGlossaryTerm[],
  name: 'Vocab Box' as const,
  locale: 'en' as Locale,
  icon,
};

export const vocabBoxPluginCommand = () => {
  FE.DefineIcon(VOCAB_BOX.commandName, { ICON: VOCAB_BOX.icon, template: FROALA_TEMPLATE_NAMES.empty });

  FE.RegisterCommand(VOCAB_BOX.commandName, {
    focus: false,
    icon: VOCAB_BOX.commandName,
    refreshAfterCallback: true,
    title: 'Insert Vocab Box',
    undo: true,
  });
};
