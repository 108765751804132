import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'common/Modal';
import useFlags from '../../../../../../hooks/useFlags';

const Info = ({
  modalManager, setSeenInfo, previous, lmsType
}) => {
  const { canvasLti13 } = useFlags();

  useEffect(() => {
    setSeenInfo(true);
  }, []);

  const setComplete = () => {
    modalManager.close();
  };

  if (lmsType === 'schoology' || canvasLti13) {
    return (
      <div>
        <p>Congratulations! You’ve finished setting up the Assignment and Grade Sync for your district.</p>

        <p className="mt20 mb60">
          Do your teachers need help using their learning management system?
          Share TCI&apos;s professional development courses with them to get started.
        </p>
        <Footer
          secondaryButtonText="Back"
          secondaryButtonCallback={previous}
          primaryButtonCallback={setComplete}
          primaryButtonText="Close"
          primaryButtonSubmit={false}
        />
      </div>
    );
  }

  return (
    <div>
      <p>Congratulations! You’ve finished setting up the Canvas Assignment and Grade Sync for your district.</p>

      <p className="mt50 mb50">
        Your teachers can automatically sync assignments and grades to Canvas. Share setup instructions:&nbsp;
        <a
          href="https://www.teachtci.com/canvas-integration"
          target="_blank"
          rel="noopener noreferrer"
        >
          How do I use TCI with Canvas?&nbsp;
          <i className="fa fa-external-link" aria-hidden="true" />
        </a>
      </p>
      <Footer
        secondaryButtonText="Back"
        secondaryButtonCallback={previous}
        primaryButtonCallback={setComplete}
        primaryButtonText="Close"
        primaryButtonSubmit={false}
      />
    </div>
  );
};

Info.propTypes = {
  lmsType: PropTypes.string,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired
  }).isRequired,
  previous: PropTypes.func,
  setSeenInfo: PropTypes.func.isRequired
};

Info.defaultProps = {
  lmsType: 'schoology',
  previous: () => {}
};

export default Info;
