import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  checkDuplicateStaff,
  checkDuplicateStudents,
  checkPassword,
  checkProgramCode,
  correctHeaders,
  hasBlankCells,
  hasIncorrectNumOfCells
} from '../Validations/CsvValidations';
import Spinner from './Spinner';

export default class CsvChecker extends Component {
  static propTypes = {
    file: PropTypes.instanceOf(Object).isRequired,
    handleCheck: PropTypes.func.isRequired,
    importType: PropTypes.string.isRequired,
    programCodes: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this._checkRowForStudent = this._checkRowForStudent.bind(this);
    this._checkRowForStaff = this._checkRowForStaff.bind(this);
  }

  componentDidMount() {
    this._checkCsv();
  }

  _checkCsv() {
    const reader = new FileReader();
    reader.onload = evt => this._checkRows(evt.target.result);
    reader.readAsText(this.props.file);
  }

  _checkRowForStudent(cells, rowNum, uniqueRows) {
    let errors = [];

    errors = errors.concat(hasBlankCells(cells, rowNum));
    errors = errors.concat(checkPassword(cells[3], cells[4], rowNum));
    errors = errors.concat(checkProgramCode(cells[6], this.props.programCodes, rowNum));
    errors = errors.concat(checkDuplicateStudents(cells, this.props.importType, rowNum, uniqueRows));

    return errors;
  }

  _checkRowForStaff(cells, rowNum, uniqueRows) {
    let errors = [];

    errors = errors.concat(hasBlankCells(cells, rowNum));
    errors = errors.concat(checkPassword(cells[3], cells[4], rowNum));
    errors = errors.concat(checkProgramCode(cells[5], this.props.programCodes, rowNum));
    errors = errors.concat(checkDuplicateStaff(cells, rowNum, uniqueRows));

    return errors;
  }

  _getCheckRowFn() {
    if (this.props.importType === 'staff') return this._checkRowForStaff;
    return this._checkRowForStudent; // only other option is 'student'
  }

  _checkRows(file) {
    const rowStrings = file.split(/[\r\n]+/g);
    const rows = rowStrings.map(row => row.split(','));
    const numRows = rowStrings.filter(str => str.length !== 0).length; // count non-empty rows
    const uniqueRows = []; // contains list of unique rows read
    let errors = [];

    // check header row
    errors = errors.concat(correctHeaders(rows[0], this.props.importType));

    // check remaining rows
    const checkRow = this._getCheckRowFn();
    rows.shift();
    rows.forEach((cells, index) => {
      const rowNum = index + 2;
      if (cells.length === 1 && cells[0] === '') return; // skip empty rows

      // proceed with validations for the row only if number of cells is correct
      const rowError = hasIncorrectNumOfCells(cells, this.props.importType, rowNum);
      if (rowError.length) {
        errors = errors.concat(rowError);
      }
      else {
        const newErrors = checkRow(cells, rowNum, uniqueRows);
        errors = errors.concat(newErrors);
      }
    });

    this.props.handleCheck(errors, numRows);
  }

  render() {
    return <Spinner />;
  }
}
