import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgressBar from 'common/CircularProgressBar';
import exclamationMarkCircle from 'images/rosteringSetup/exclamationMarkCircle.svg';

import styles from '../RosterCard/PartialCard.module.scss';
import SSOSetupModal from '../SSOSetupModal/index';
import { isAdmin } from '../../../../common/Utils';
import Tooltip from '../../../../common/Tooltip/index';
import rosteringCardStyles from '../RosteringCard.module.scss';
import { ssoModelPropType } from '../Proptypes';
import SSOSteps from './SSOSteps';
import DeleteButton from '../Delete';
import { ssoHeaderImagesLti, ssoHeaderImagesSaml, getStepImage, SrOnlyStepStatus, VerticalDottedLine, percentComplete, getCardClass } from '../Utils';
import * as constants from '../steps';

export default class PartialCard extends Component {
  static propTypes = {
    ssoModel: ssoModelPropType.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    subscriber_id: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
    rootUrl: PropTypes.string.isRequired,
    completedSteps: constants.completedStepsPropTypes.isRequired,
    isSaml: PropTypes.bool.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    isComplete: PropTypes.bool,
    removeSSOModel: PropTypes.func.isRequired
  };

  static defaultProps = {
    isComplete: false
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      currentStep: 0
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderStep = this.renderStep.bind(this);
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  openModal(e, page) {
    e.preventDefault();
    this.setState({
      modalIsOpen: true,
      currentStep: page
    });
  }

  handleKeyPress(e, page) {
    if (e.keyCode === 13) {
      this.openModal(e, page);
    }
  }

  _renderSSOSetupModal() {
    if (this.state.modalIsOpen) {
      return (
        <SSOSetupModal
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          ssoModelPath={this.props.ssoModelPath}
          ssoModel={this.props.ssoModel}
          subscriber_id={this.props.subscriber_id}
          updateSSOModel={this.props.updateSSOModel}
          uploadXMLUrl={this.props.uploadXMLUrl}
          rootUrl={this.props.rootUrl}
          currentStep={this.state.currentStep}
          completedSteps={this.props.completedSteps}
          isSaml={this.props.isSaml}
        />
      );
    }

    return null;
  }

  _renderLabel(page, text) {
    if (this.props.completedSteps[page] || !isAdmin(this.props.userType)) {
      return text;
    }

    return (
      <a
        href="#"
        onClick={(e) => { this.openModal(e, page); }}
        tabIndex="0"
        onKeyPress={(e) => { this.handleKeyPress(e, page); }}
      >
        {text}
      </a>
    );
  }

  _getProgressBarText() {
    return this.props.isSaml ? 'SAML' : 'LTI';
  }

  _getTextOffset() {
    return this.props.isSaml ? 23 : 32;
  }

  _getWrapperClass() {
    if (this.props.isComplete) return null;
    return getCardClass(false);
  }

  _ssoModelPath() {
    return this.props.ssoModelPath.replace(':id', this.props.ssoModel.id);
  }

  _renderStartButton(disabled) {
    return (
      <button
        className="btn btn--block btn--purple"
        onClick={this.openModal}
        disabled={disabled}
      >
        Continue SSO Setup
      </button>
    );
  }

  _renderDisabledStartButton() {
    return (
      <Tooltip
        content="The ability to set up single sign on integrations yourself is coming soon."
      >
        <div>
          {this._renderStartButton(true)}
        </div>
      </Tooltip>
    );
  }

  _renderPartialCard() {
    return (
      <div>
        <h2 className={rosteringCardStyles.displayFlex}>
          <span className={styles.headerLeft}>
            <img alt="" className={styles.titleImage} src={exclamationMarkCircle} />
            <span className="ml10">Single Sign On</span>
          </span>

          <DeleteButton
            deletePath={this._ssoModelPath()}
            onDelete={this.props.removeSSOModel}
          />
        </h2>

        <hr className={rosteringCardStyles.hr} />

        <div className={styles.header}>
          <div>
            <CircularProgressBar
              className={styles.progressBar}
              percent={percentComplete(this.props.completedSteps)}
              text={this._getProgressBarText()}
              xOffsetText={this._getTextOffset()}
            />
          </div>
          <div className={styles.infoText}>
            <p className={styles.percent}>{percentComplete(this.props.completedSteps)}%</p>
            <p>Single Sign on</p>
            <p>Integration Completed</p>
          </div>
        </div>

        <SSOSteps
          wrapperClass={styles.steps}
          renderStep={this.renderStep}
          isSaml={this.props.isSaml}
          divider={<VerticalDottedLine key="divider" />}
        />

        {isAdmin(this.props.userType) ? this._renderStartButton() : this._renderDisabledStartButton()}
      </div>
    );
  }

  renderStep(step, text) {
    return (
      <div className={styles.step} key={text}>
        <img
          alt=""
          className={styles.stepImage}
          src={getStepImage(
            this.props.isSaml ? ssoHeaderImagesSaml : ssoHeaderImagesLti,
            this.props.completedSteps[step],
            step
          )}
        />
        <span className="ml30">
          {this._renderLabel(step, text)}
          <SrOnlyStepStatus isStepCompleted={this.props.completedSteps[step]} />
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className={this._getWrapperClass()}>
        {this.props.isComplete || this._renderPartialCard()}
        {this._renderSSOSetupModal()}
      </div>
    );
  }
}
