import 'whatwg-fetch';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../common/Modal';
import UploadForm from './UploadForm';

import styles from '../PrintModal/PrintModal.module.scss';
import driveIcon from './google-drive.svg';

export default class UploadToDriveModal extends Component {
  static propTypes = {
    canToggleLanguage: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    email: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    headerLabel: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    name: PropTypes.string,
    successSignOnCallback: PropTypes.func.isRequired,
    assessmentLibrary: PropTypes.bool,
  };

  static defaultProps = {
    email: null,
    imageUrl: null,
    name: null,
    assessmentLibrary: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.getFile = this.getFile.bind(this);
    this.fillFileData = this.fillFileData.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getFile() {
    return this.state.fileData;
  }

  fillFileData() {
    if (this.state.fileData) return;
    this.fetchFileData().then(data => this.setState({ fileData: data }));
  }

  async fetchFileData() {
    let url = this.props.fileUrl;

    if (this.state.locale) url = url.replace(/locale=\w+/, `locale=${this.state.locale}`);

    const response = await fetch(url);
    return response.blob();
  }

  renderLanguageButton(locale) {
    return (
      <button
        type="button"
        className="btn btn--card preserve-color"
        onClick={() => this.setState({ locale })}
      >
        <img src={driveIcon} alt="" />
        {locale === 'en' ? 'English' : 'Spanish'}
      </button>
    );
  }

  renderLanguageToggle() {
    return (
      <React.Fragment>
        <div className={styles.languageOptionsContainer}>
          {this.renderLanguageButton('en')}
          {this.renderLanguageButton('es')}
        </div>

        <hr />

        <footer>
          <button type="button" className="btn btn--link-purple" onClick={this.closeModal}>Cancel</button>
        </footer>
      </React.Fragment>
    );
  }

  closeModal() {
    if (this.props.canToggleLanguage) {
      this.setState({ fileData: null, locale: null }, this.props.closeModal);
    }
    else {
      this.props.closeModal();
    }
  }

  renderUploadForm() {
    return (
      <UploadForm
        fileUrl={this.props.fileUrl}
        fileName={this.props.fileName}
        closeModal={this.closeModal}
        successSignOnCallback={this.props.successSignOnCallback}
        imageUrl={this.props.imageUrl}
        name={this.props.name}
        email={this.props.email}
        fillFileData={this.fillFileData}
        getFile={this.getFile}
        locale={this.state.locale}
        assessmentLibrary={this.props.assessmentLibrary}
      />
    );
  }

  showLanguageToggle() {
    return !this.state.locale && this.props.canToggleLanguage;
  }

  render() {
    return (
      <Modal
        className={this.showLanguageToggle() ? 'w500' : 'w850'}
        closeModal={this.closeModal}
        isOpen={this.props.isOpen}
        headerText={this.props.headerLabel}
      >
        {this.showLanguageToggle() ? this.renderLanguageToggle() : this.renderUploadForm()}
      </Modal>
    );
  }
}
