import React from 'react';
import I18n from 'i18n-js';

const ArchivedMessage = () => (
  <span className="tw-ml-[100px] tw-border tw-border-solid tw-rounded tw-border-[#F59C06] tw-bg-[#FDF9F4] tw-px-2 tw-py-1.5">
    <i className="fa fa-exclamation-triangle tw-text-[#F59C06] tw-text-base tw-mr-2" aria-hidden="true" />
    {I18n.t('archived_content')}
  </span>
);

export default ArchivedMessage;
