import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useModalManager, SubmitModal } from 'common/Modal';
import { FileField } from 'common/Forms';
import * as Routes from 'routes';
import styles from './index.module.scss';

const ImageChoiceCardBody = ({ ...props }) => {
  const modalManager = useModalManager();
  const [imageUrl, setImageUrl] = useState(props.image.url);
  const [imageFilename, setImageFilename] = useState(props.image.filename);
  const hasImage = () => (imageUrl !== null && imageUrl !== undefined);

  const toggleCheckboxContainer = () => {
    if (hasImage()) $(props.checkboxContainerId).show();
    else {
      $(props.checkboxId).prop('checked', false);
      $(props.checkboxContainerId).hide();
    }
  };

  useEffect(toggleCheckboxContainer, [imageUrl]);

  const afterSubmit = (response) => {
    if (hasImage()) {
      setImageUrl(null);
      setImageFilename(null);
    }
    else {
      setImageUrl(response.url);
      setImageFilename(response.filename);
    }
    $(props.imageChoiceContainerId)[0].dispatchEvent(new CustomEvent('validate_image_choice'));
  };

  const blankImageIcon = <i aria-hidden="true" className={`fa fa-file-image-o ${styles.blankImageIcon}`} />;
  const image = <img src={imageUrl} alt="" />;
  const addImageModalContent = (
    <React.Fragment>
      Your image will be resized to 300px x 260px.
      <FileField
        name="image_choice"
        label="Upload Image: "
        accept="image/png, image/jpeg, image/jpg"
      />
      Image Requirement: .JPEG / .JPG / .PNG
    </React.Fragment>
  );
  const deleteImageModalContent = (
    <React.Fragment>
      Are you sure you want to delete <strong>{imageFilename}</strong>?
    </React.Fragment>
  );

  return (
    <div className={styles.addImageContainer}>
      <div className={styles.imageChoiceCardHeader}>
        <span>{props.choiceId}.</span>
      </div>
      {hasImage() ? image : blankImageIcon}
      <button
        className={`btn ${hasImage() ? 'btn--red' : 'btn--green'}`}
        type="button"
        onClick={modalManager.open}
      >
        {hasImage() ? 'Delete Image' : 'Add Image'}
      </button>
      <SubmitModal
        headerText={`${hasImage() ? 'Delete' : 'Add'} Image Choice - ${props.choiceId}`}
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        submitPath={Routes.plato_api_question_path(props.questionId)}
        afterSubmit={afterSubmit}
        additionalData={{ choice_id: props.choiceId }}
        method="patch"
        primaryButtonText={`${hasImage() ? 'Delete' : 'Submit'}`}
        primaryButtonClassName={`btn ${hasImage() ? 'btn--red' : 'btn--purple'}`}
        disableUntilChange={!hasImage()}
      >
        {hasImage() ? deleteImageModalContent : addImageModalContent}
      </SubmitModal>
    </div>
  );
};

ImageChoiceCardBody.propTypes = {
  checkboxContainerId: PropTypes.string.isRequired,
  checkboxId: PropTypes.string.isRequired,
  choiceId: PropTypes.string.isRequired,
  image: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  imageChoiceContainerId: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired
};

export default ImageChoiceCardBody;
