import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import styles from './AdvancedFilters.module.scss';

export default function DatePicker({
  selected, changeField, filterName, bindSelectRef, placeholderText, className, readOnly
}) {
  function formatDate(date) {
    return date ? date.format('YYYY-MM-DD') : '';
  }

  return (
    <label htmlFor="select-due-date" className={styles.datePickWrapper}>
      <ReactDatePicker
        title="Date Select"
        dateFormat="MM/dd/yyyy"
        ref={(ref) => { bindSelectRef(ref); }}
        selected={selected ? new Date(`${selected}T00:00:00`) : null}
        onChange={date => changeField(filterName, { value: formatDate(moment(date)) })}
        placeholderText={placeholderText}
        className={className}
        readOnly={readOnly}
        id="select-due-date"
      />
      <img alt="" className={styles.calendarIcon} src="/images/icon_calendar_small.png" />
    </label>
  );
}

DatePicker.propTypes = {
  selected: PropTypes.string,
  changeField: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  bindSelectRef: PropTypes.func,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool
};

DatePicker.defaultProps = {
  bindSelectRef: () => {},
  className: '',
  placeholderText: null,
  readOnly: false,
  selected: null
};
