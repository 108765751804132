import React from 'react';

import { AssessmentLibrary } from '@tci/media-libraries';
import '@tci/media-libraries/dist/style.css';
import * as Routes from 'modules/routes';
import { CopyModal } from 'components/staffer/assessments/CopyLink';
import ShareLink from 'components/staffer/assessments/ShareLink';
import DeleteAssessmentButton from 'components/staffer/assessments/DeleteAssessmentButton';
import CreateNewTestButton from 'components/staffer/assessments/CreateNewTestButton';
import PrintModal from 'components/shared/PrintModal';
import SaveToGoogleDrive from 'components/shared/SaveToGoogleDrive';
import UnitLessonSelect from 'components/common/ModelSelect/UnitLessonSelect';
import UnShareButton from 'components/staffer/assessments/UnShareButton';

import AsyncAssignmentModal from 'components/staffer/AssignmentModal/AsyncAssignmentModal';

type AssessmentsProps = {
  programId: number;
  lessonId?: number;
  unitId?: number;
};

const Assessments = ({ programId, lessonId, unitId }: AssessmentsProps) => (
  <AssessmentLibrary
    AsyncAssignmentModal={AsyncAssignmentModal}
    CopyModal={CopyModal}
    PrintModal={PrintModal}
    ShareLink={ShareLink}
    DeleteAssessmentButton={DeleteAssessmentButton}
    CreateNewTestButton={CreateNewTestButton}
    UnitLessonSelect={UnitLessonSelect}
    programId={programId}
    lessonId={lessonId}
    unitId={unitId}
    routes={{
      assessmentPath: Routes.plato_api_assessment_path,
      assessmentsLibraryPath: Routes.library_shared_program_assessments_path,
      shareAssessmentPath: Routes.share_staffer_assessment_path,
      unShareAssessmentPath: Routes.unshare_staffer_assessment_path,
      copyAssessmentPath: Routes.copy_to_staffer_assessments_path,
      editAssessmentPath: Routes.edit_staffer_assessment_path,
      stafferAssessmentsPath: Routes.staffer_assessments_path,
      deleteAssessmentPath: Routes.staffer_assessment_path,
      previewAssessmentPath: Routes.shared_program_lesson_assessment_path,
      printAssessmentPath: Routes.pdf_staffer_assessment_print_path,
      assignmentsPath: Routes.teacher_program_assignments_path,
      gradesPath: Routes.teacher_program_scores_path
    }}
    SaveToGoogleDrive={SaveToGoogleDrive}
    UnShareButton={UnShareButton}
  />
);

export default Assessments;
