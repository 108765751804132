import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgressBar from 'common/CircularProgressBar';

import cleverLogo from 'images/rosteringSetup/cleverLogo.png';
import onerosterLogo from 'images/rosteringSetup/onerosterLogo.png';
import sftpIcon from 'images/rosteringSetup/sftpIcon.svg';
import exclamationMarkCircle from 'images/rosteringSetup/exclamationMarkCircle.svg';

import styles from './PartialCard.module.scss';
import rosteringCardStyles from '../RosteringCard.module.scss';
import { rosterHeaderImages, rosterHeaderImagesCsv, getStepImage, SrOnlyStepStatus, VerticalDottedLine, CardCTA, percentComplete } from '../Utils';

import { PROVIDER_DISPLAY_NAME } from './constants';
import RosterSteps from './RosterSteps';
import DeleteButton from '../Delete';

import * as constants from '../steps';
import { isAdmin } from '../../../../common/Utils';

export default class PartialCard extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      sync_type: PropTypes.string.isRequired
    }).isRequired,
    autoRosterDistrictPath: PropTypes.string.isRequired,
    completedSteps: constants.completedStepsPropTypes.isRequired,
    openModal: PropTypes.func.isRequired,
    updateRosterDistrict: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.renderStep = this.renderStep.bind(this);
  }

  _isCsvDistrict() {
    return this.props.autoRosterDistrict.sync_type === 'tci_csv';
  }

  handleKeyPress(e, page) {
    if (e.keyCode === 13) {
      this.props.openModal(e, page);
    }
  }

  _getDeleteMessage() {
    return (
      <p>
        To confirm deletion of this integration
        ({ PROVIDER_DISPLAY_NAME[this.props.autoRosterDistrict.sync_type] }),
        type <em>delete me</em> into the field.
      </p>
    );
  }

  _getImage() {
    switch (this.props.autoRosterDistrict.sync_type) {
      case 'clever_api':
        return cleverLogo;
      case 'oneroster_api':
        return onerosterLogo;
      case 'tci_csv':
        return sftpIcon;
      default:
        return null;
    }
  }

  _renderLabel(page, text) {
    if (this.props.completedSteps[page] || !isAdmin(this.props.userType)) {
      return text;
    }

    return (
      <a
        href="#"
        onClick={(e) => { this.props.openModal(e, page); }}
        tabIndex="0"
        onKeyPress={(e) => { this.handleKeyPress(e, page); }}
      >
        {text}
      </a>
    );
  }

  renderStep(step, text) {
    return (
      <div className={styles.step} key={text}>
        <img
          alt=""
          className={styles.stepImage}
          src={getStepImage(
                this._isCsvDistrict() ? rosterHeaderImagesCsv : rosterHeaderImages,
                this.props.completedSteps[step],
                step
              )}
        />
        <span className="ml30">
          {this._renderLabel(step, text)}
          <SrOnlyStepStatus isStepCompleted={this.props.completedSteps[step]} />
        </span>
      </div>
    );
  }

  renderDeleteButton() {
    if (isAdmin(this.props.userType)) {
      return (
        <DeleteButton
          deletePath={this.props.autoRosterDistrictPath}
          onDelete={this.props.updateRosterDistrict}
          deleteMessage={this._getDeleteMessage()}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        <h2 className={rosteringCardStyles.displayFlex}>
          <span className={styles.headerLeft}>
            <img alt="" className={styles.titleImage} src={exclamationMarkCircle} />
            <span className="ml10">Rostering</span>
          </span>

          {this.renderDeleteButton()}
        </h2>

        <hr className={rosteringCardStyles.hr} />

        <div className={styles.header}>
          <div>
            <CircularProgressBar
              className={styles.progressBar}
              percent={percentComplete(this.props.completedSteps)}
              imageSrc={this._getImage()}
            />
          </div>
          <div className={styles.infoText}>
            <p className={styles.percent}>{percentComplete(this.props.completedSteps)}%</p>
            <p>{PROVIDER_DISPLAY_NAME[this.props.autoRosterDistrict.sync_type]}</p>
            <p>Setup Completed</p>
          </div>
        </div>

        <RosterSteps
          divider={<VerticalDottedLine key="divider" />}
          isCsvDistrict={this._isCsvDistrict()}
          renderStep={this.renderStep}
          wrapperClass={styles.steps}
        />

        <div className="m20">
          <CardCTA
            enabled={isAdmin(this.props.userType)}
            onClick={this.props.openModal}
            started
          />
        </div>
      </div>
    );
  }
}
