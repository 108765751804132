import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import I18n from 'i18n';
import { snakeToTitle } from 'common/Utils';
import FilterRow from './FilterRow';
import { filtersPropTypes, propertyPropTypes } from './Utils';
import styles from './AdvancedFilters.module.scss';

export default class AdvancedFilters extends Component {
  static propTypes = {
    asyncSearchPath: PropTypes.string,
    buttonClass: PropTypes.string,
    clearable: PropTypes.bool,
    disableSelectedProperties: PropTypes.bool,
    filterLabel: PropTypes.string,
    getFilteredResources: PropTypes.func,
    initialFilters: filtersPropTypes,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    resources: PropTypes.arrayOf(PropTypes.object).isRequired,
    showClearButton: PropTypes.bool,
    searchPath: PropTypes.string,
    updateActiveFilters: PropTypes.func
  };

  static defaultProps = {
    asyncSearchPath: null,
    disableSelectedProperties: false,
    buttonClass: '',
    clearable: true,
    filterLabel: I18n.t('filters'),
    getFilteredResources: null,
    initialFilters: null,
    showClearButton: true,
    searchPath: null,
    updateActiveFilters: null
  };

  // Converts a ransack property to a human-friendly filter label
  // Ex: shared_classrooms_staffers_email > Classroom Teacher Email
  static generateFilterLabel(propertyValue) {
    const friendlyPropertyValue = propertyValue
      .replace('shared_classrooms', 'classroom')
      .replace('staffers', 'teacher')
      .replace('complete_classrooms', 'classroom');

    return snakeToTitle(friendlyPropertyValue);
  }

  static getFilterRowKey(config) {
    return (config.propertyValue ? config.propertyValue : config.id);
  }

  static getNewFilterRowData() {
    return { id: Date.now() };
  }

  constructor(props) {
    super(props);

    // Always show at least one filter row:
    const filterRows = this.props.initialFilters ?
      this.props.initialFilters : [AdvancedFilters.getNewFilterRowData()];

    this.state = {
      filterRows,
      filters: {},
      isInitialLoad: true
    };

    this.addFilter = this.addFilter.bind(this);
    this.addToFilters = this.addToFilters.bind(this);
    this.removeFilterRow = this.removeFilterRow.bind(this);
    this.removeFromFilters = this.removeFromFilters.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  addFilter() {
    this.setState({
      filterRows: [...this.state.filterRows, AdvancedFilters.getNewFilterRowData()]
    });
  }

  addToFilters(filterKey, filterParam) {
    const filters = Object.assign({}, this.state.filters);
    filters[filterKey] = filterParam;
    this.setState({ filters }, () => this.search());
  }

  removeFilterRow(currentFilter, indexToRemove) {
    if (this.state.filterRows.length === 1) {
      this.clearAllFilters();
    }
    else {
      const updatedFilters = Object.assign({}, this.state.filters);
      delete updatedFilters[currentFilter];
      this.setState(prevState => ({
        filterRows: prevState.filterRows.filter((_, rowIndex) => rowIndex !== indexToRemove),
        filters: updatedFilters,
        isInitialLoad: false
      }), () => this.search());
    }
  }

  removeFromFilters(filterKey) {
    const filters = this.state.filters;
    delete filters[filterKey];

    this.setState({ filters });
  }

  clearAllFilters() {
    this.setState(
      { filterRows: [AdvancedFilters.getNewFilterRowData()], filters: {}, isInitialLoad: false },
      () => this.search()
    );
  }

  async search() {
    if (this.props.searchPath && this.props.getFilteredResources) {
      const data = this.buildSearchData();

      await axios
        .post(this.props.searchPath, data)
        .then(response => this.props.getFilteredResources(response.data.data))
        .catch(error => console.log(error));
    }

    if (this.props.updateActiveFilters) {
      this.props.updateActiveFilters(this.state.filters);
    }
  }

  buildSearchData() {
    return ({ search: this.state.filters });
  }

  _getInitialProperty(index) {
    if (this.state.isInitialLoad) {
      const { initialFilters, properties } = this.props;

      if (initialFilters && initialFilters.length > index) {
        let initialProperties = properties.find(property => property.value === initialFilters[index].propertyValue);
        initialProperties = initialProperties || {
          label: AdvancedFilters.generateFilterLabel(initialFilters[index].propertyValue),
          operators: ['eq', 'not_eq', 'cont', 'not_cont'],
          value: initialFilters[index].propertyValue
        };

        return initialProperties;
      }
    }

    return null;
  }

  renderFilterRows() {
    return (
      this.state.filterRows.map((config, i) => (
        <FilterRow
          clearable={this.props.clearable}
          disableSelectedProperties={this.props.disableSelectedProperties}
          index={i}
          initialOperatorValue={config.operatorValue}
          initialProperty={this._getInitialProperty(i)}
          initialValue={config.value}
          key={AdvancedFilters.getFilterRowKey(config)}
          activeProperties={Object.keys(this.state.filters)}
          resources={this.props.resources}
          properties={this.props.properties}
          addToFilters={this.addToFilters}
          removeFilterRow={this.removeFilterRow}
          removeFromFilters={this.removeFromFilters}
          asyncSearchPath={this.props.asyncSearchPath}
        />
      ))
    );
  }

  render() {
    return (
      <div>
        <div className={styles.header}>
          <h2>{this.props.filterLabel}</h2>
          {this.props.showClearButton && <button
            type="button"
            className="btn btn--sm btn--link-purple"
            onClick={this.clearAllFilters}
            disabled={!Object.values(this.state.filters).some(filterVal => filterVal)}
          >
            {I18n.t('clear')}
          </button>}
        </div>

        {this.renderFilterRows()}

        <button
          className={`btn btn--outline-purple mb5 ${this.props.buttonClass}`}
          onClick={this.addFilter}
          type="button"
        >
          {I18n.t('add_filter')}
        </button>
      </div>
    );
  }
}
