import React from 'react';
import { Button, Modal, Dialog } from 'react-aria-components';
import cn from '@/utils/cn';
import { PlanningHeader } from './PlanningHeader';
import { TabbedContent } from './TabbedContent';
import styles from './PlanningDrawer.module.scss';
import { BUTTON_CLASSES, PlanningContext, Resource, TRANSLATIONS, usePlanningDrawer } from './utils';

interface PlanningDrawerProps {
  planningContext: PlanningContext;
  printPdfPath: string;
  resources: Resource[];
}

export const PlanningDrawer = ({ printPdfPath, resources, planningContext }: PlanningDrawerProps) => {
  const {
    close,
    open,
    isOpen,
    isExpanded,
    toggleOpen,
  } = usePlanningDrawer();

  if (resources.length === 0) return null;

  return (
    <>
      <Button
        aria-label={TRANSLATIONS.dialog.open(planningContext)}
        aria-expanded={isOpen}
        className={BUTTON_CLASSES}
        onPress={open}
      >

        {TRANSLATIONS.title(planningContext)}
        <span className="fa fa-chevron-up" />
      </Button>
      <Modal
        className={cn(styles.drawer, { [styles.fullWidth]: isExpanded }, 'tw-transition-all tw-text-base')}
        isDismissable
        isOpen={isOpen}
        onOpenChange={toggleOpen}
      >
        <Dialog aria-label={TRANSLATIONS.dialog.standard(planningContext)} className="tw-h-screen tw-flex tw-flex-col">
          <PlanningHeader planningContext={planningContext} printPdfPath={printPdfPath} />
          <TabbedContent resources={resources} />
          <Button
            aria-label={TRANSLATIONS.dialog.close}
            className={cn(BUTTON_CLASSES, 'tw-absolute -tw-left-[38px] tw-right-auto tw-z-10')}
            slot="close"
            onPress={close}
          >
            {TRANSLATIONS.title(planningContext)}
            <span className="fa fa-chevron-down" />
          </Button>
        </Dialog>
      </Modal>
    </>
  );
};
