import React from 'react';
import showToast from '@/components/common/Toast';

interface CopySlideIdButtonProps {
  slideId: number | string;
}

const CopySlideIdButton = ({ slideId }: CopySlideIdButtonProps) => {
  const handleClick = () => {
    navigator.clipboard.writeText(slideId.toString());
    showToast('Copied to Clipboard!', { position: 'bottom-right' });
  };

  return (
    <button
      className="dropdown-item full-size"
      onClick={handleClick}
      type="button"
    >
      Copy Slide ID
      <span className="fa fa-lock tw-ml-2" />
    </button>
  );
};

export default CopySlideIdButton;
