import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { formatPrograms } from 'common/Forms/Utils';
import styles from './ImportYourData.module.scss';
import ImportForm from './ImportForm';

export default class ImportYourData extends Component {
  static propTypes = {
    active: PropTypes.bool,
    importDataPath: PropTypes.string.isRequired,
    programId: PropTypes.number.isRequired,
    programsPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    active: false
  };

  constructor(props) {
    super(props);

    this.state = {
      programOptions: []
    };
  }

  componentDidMount() {
    this.getProgramOptions();
  }

  getProgramOptions() {
    return axios
      .get(this.props.programsPath)
      .then(response => this.setState({ programOptions: formatPrograms(response.data.data) }))
      .catch(error => console.log(error));
  }

  renderForm() {
    if (this.state.programOptions.length > 0) {
      return (
        <ImportForm
          importDataPath={this.props.importDataPath}
          programId={this.props.programId}
          programOptions={this.state.programOptions}
        />
      );
    }

    return (
      <div className={styles.noOtherCopyright}>
        <p className="mb20">
          There are no other versions of this program available.
        </p>
      </div>
    );
  }

  render() {
    if (!this.props.active) return null;

    return (
      <div className={styles.container}>
        <span>
          Import your created lesson guides and assessments from other versions of this program.
        </span>
        {this.renderForm()}
      </div>
    );
  }
}
