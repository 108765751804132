import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dropdown from '../../../../../../common/Dropdown';
import styles from '../../Navigator.module.scss';
import useSlideConditions from '../../../useSlideConditions';
import LockedSlideIcon from './LockedSlideIcon';
import useSlideShowContext from '../../../../stores/useSlideShowContext';

const PositionInput = ({ dropdownItemText, numElements, onDirectNav, position }) => {
  const formattedSlides = useSlideShowContext(state => state.slides);
  const { isSlideLocked } = useSlideConditions();
  const [localPosition, setLocalPosition] = useState(position);

  useEffect(() => setLocalPosition(position), [position]);

  const positionInputHandler = (e, setOpen = () => {}) => {
    const newPosition = e.target.value;
    setLocalPosition(newPosition);
    if (newPosition < 1 || newPosition > numElements) {
      setOpen(true);
      return;
    }

    setOpen(false);
    onDirectNav(newPosition - 1); // set to (newPosition - 1) since 1-based indexing is used for display purposes
  };

  return (
    <div className={styles.positionInputContainer}>
      <label htmlFor="position-input">Slide</label>
      <Dropdown
        buttonClass={styles.navigatorDropdownButton}
        dropdownClass={styles.dropdown}
        dropdownPosition="top"
        toggleText={({ setOpen }) => (
          <>
            <span className="hidden">Type position to change slides</span>
            <input
              id="position-input"
              className={styles.positionInput}
              onChange={e => positionInputHandler(e, setOpen)}
              type="number"
              value={localPosition.toString()}
            />
          </>
        )}
      >
        {/* The text for each item may need to get passed in as an array instead */}
        {/* depending on how custom we want the text for each item to be */}
        {new Array(numElements).fill(null).map((_, i) => {
          const locked = isSlideLocked(formattedSlides[i]);

          return (
            <a
              data-closeonclick
              key={i}
              onClick={() => {
                if (locked) return;
                positionInputHandler({ target: { value: i + 1 } });
              }}
              className={clsx('dropdown-item', {
                [styles.dropdownItem]: true,
                [styles.active]: position === i + 1,
                [styles.disabled]: locked,
              })}
              href="#"
            >
              {position === i + 1 && <i className={`fa fa-solid fa-check ${styles.greenCheck}`} />}
              {dropdownItemText} {i + 1}
              {locked &&
                <span className={styles.lock}>
                  <LockedSlideIcon slide={formattedSlides[i]} />
                </span>
              }
            </a>
          );
        })}
      </Dropdown>
      <span>/ {numElements}</span>
    </div>
  );
};

PositionInput.propTypes = {
  dropdownItemText: PropTypes.string.isRequired,
  numElements: PropTypes.number.isRequired,
  onDirectNav: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

export default PositionInput;
