import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

export default function Checkbox({
  checked, disabled, handleChange, indeterminate, labelText, srOnly, name, value
}) {
  const setCheckboxRef = (checkbox) => {
    if (checkbox) {
      checkbox.indeterminate = indeterminate;
    }
  };

  function _renderLabelText() {
    if (srOnly) {
      return <span className="sr-only">{labelText}</span>;
    }

    return labelText;
  }

  return (
    <label className={styles.label}>
      <input
        disabled={disabled}
        name={name}
        value={value}
        onChange={handleChange}
        type="checkbox"
        checked={checked}
        ref={setCheckboxRef}
      />
      {_renderLabelText()}
    </label>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  srOnly: PropTypes.bool,
  value: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
  srOnly: false,
  value: ''
};
