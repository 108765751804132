import React, { useMemo } from 'react';
import * as Routes from 'modules/routes';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import useVideoActivity from '../../hooks/queries/useVideoActivity';
import { HeaderCell, VideoActivityGradeCell } from '../Cells';
import useVideoActivityExerciseResult from '../../hooks/queries/exerciseResults/useVideoActivityExerciseResult';

const useVideoActivityColumn = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);
  const { data: videoActivity } = useVideoActivity(classroom.id, lesson?.id);
  const { data: exerciseResults } = useVideoActivityExerciseResult(classroom, lesson);
  const assignVideoActivitySlideshow = $('#slug-attributes').data('flags')?.assign_slideshow_video_activity;

  return useMemo(() => {
    const accessor = (row: IStudentRow) => row.student;

    if (!videoActivity || assignVideoActivitySlideshow || (videoActivity.max_score || 0) === 0) {
      return [];
    }

    return [
      columnHelper.accessor(accessor, {
        id: `videoActivity-${videoActivity.id}`,
        header: () => (
          <HeaderCell title="Video Activity" maxScore={videoActivity.max_score}>
            <HeaderCell.SyncGradesButton
              exerciseType="video activity"
              exerciseResults={exerciseResults}
              link={Routes.sync_classroom_scores_teacher_notebook_score_path(
                videoActivity.id,
                { classroom_id: classroom.id,
                  notebook_id: videoActivity.id,
                  program_id: programId },
              )}
            />
          </HeaderCell>
        ),
        cell: info => <VideoActivityGradeCell videoActivity={videoActivity} student={info.getValue()} />,
      }),
    ];
  }, [videoActivity]);
};

export default useVideoActivityColumn;
