import React, { Component, useEffect, useRef, useState } from 'react';
import Axios from 'axios/index';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import DataTable from '../index';

import { matchAttributesForMultipleRows, sanitizeCell } from '../Utils';

export default class PaginatedTable extends Component {
  static propTypes = {
    addResources: PropTypes.func.isRequired,
    autoWidth: PropTypes.bool,
    buttons: PropTypes.instanceOf(Array),
    columnDefs: PropTypes.instanceOf(Array),
    columnMapping: PropTypes.instanceOf(Array).isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    createdRow: PropTypes.func,
    defaultExpandRows: PropTypes.bool,
    defaultOrder: PropTypes.instanceOf(Array),
    doneLoading: PropTypes.bool.isRequired,
    doneLoadingCallback: PropTypes.func,
    expandCallback: PropTypes.func,
    expandCallbackAfterInit: PropTypes.func,
    exportedColumns: PropTypes.instanceOf(Array),
    getUrl: PropTypes.string.isRequired,
    hideButtons: PropTypes.bool,
    info: PropTypes.bool,
    isLoading: PropTypes.func,
    noRecordsMessage: PropTypes.string,
    onDraw: PropTypes.func,
    pageLength: PropTypes.number,
    pagesLoaded: PropTypes.number.isRequired,
    paging: PropTypes.bool,
    resetResources: PropTypes.func.isRequired,
    resources: PropTypes.instanceOf(Array).isRequired,
    showPagingOptions: PropTypes.bool,
    showSpinner: PropTypes.bool,
    sortCallback: PropTypes.func
  };

  static defaultProps = {
    autoWidth: true,
    buttons: [],
    columnDefs: [],
    createdRow: null,
    defaultExpandRows: false,
    defaultOrder: [[0, 'asc']],
    doneLoadingCallback: () => {},
    expandCallback: null,
    expandCallbackAfterInit: null,
    exportedColumns: [],
    hideButtons: false,
    info: true,
    isLoading: () => {},
    noRecordsMessage: 'No data available in table',
    onDraw: null,
    pageLength: 20,
    paging: true,
    showPagingOptions: true,
    showSpinner: false,
    sortCallback: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      requestInProgress: false,
      columnId: this.props.defaultOrder[0][0],
      sortDirection: this.props.defaultOrder[0][1],
      loadId: 0
    };

    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    if (this.props.doneLoading) {
      return;
    }

    this.loadMoreResources(this.props.pagesLoaded + 1);
    this.setOnDrawListener();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resources !== nextProps.resources && !nextProps.doneLoading) {
      this.loadMoreResources(nextProps.pagesLoaded + 1);
    }
  }

  // clear resources and increment loadId so that any old responses coming in are discarded
  onSort(columnId, sortDirection) {
    if (Number.isInteger(columnId) && !this.props.doneLoading && (this.state.columnId !== columnId || this.state.sortDirection !== sortDirection)) {
      this.setState({
        requestInProgress: false,
        loadId: this.state.loadId + 1,
        columnId: columnId,
        sortDirection: sortDirection
      }, () => this.props.resetResources());
    }

    if (Number.isInteger(columnId)) this.props.sortCallback(columnId, sortDirection);
  }

  loadMoreResources(pageNumber) {
    if (this.state.requestInProgress) {
      return;
    }

    // if doneLoading is to true by a filter request interrupting the load, stop the initial request
    // then clear the table for the new filter request
    if (this.props.doneLoading) {
      this.setState({ requestInProgress: false }, () => this.props.resetResources());
      return;
    }

    this.setState({ requestInProgress: true }, () => {
      const getUrl = this.props.getUrl
        .replace('pageNumber', pageNumber.toString())
        .replace('columnName', this.props.columnMapping[this.state.columnId] || 'id')
        .replace('sortDirection', this.state.sortDirection || 'asc');
      const loadId = this.state.loadId;
      Axios
        .get(getUrl)
        .then((response) => {
          // discard old responses
          if (loadId !== this.state.loadId) {
            return;
          }

          const newResources = response.data.data;
          matchAttributesForMultipleRows(newResources, this.props.columns);

          this.setState({
            requestInProgress: false,
            totalCount: response.data.meta.count
          }, () => this.props.addResources(newResources, response.data.meta));
        })
        .catch(errors => console.log(errors))
        .then(() => {
          if (this.props.doneLoading) {
            this.props.doneLoadingCallback();
          }
        });
    });
  }

  getRows(config) {
    if (this.dataTableRef) return this.dataTableRef.getRows(config);

    return null;
  }

  setOnDrawListener() {
    if (this.props.onDraw && this.dataTableRef) {
      this.dataTableRef.tableRef.dataTable.on('draw', () => {
        this.props.onDraw();
      });
    }
  }

  _buttons() {
    if (this.props.hideButtons) {
      return this.props.buttons;
    }

    return this.props.buttons.concat([
      {
        className: 'btn btn--sm btn--outline-purple mr30',
        enabled: false,
        exportOptions: {
          columns: this.props.exportedColumns,
          format: { body: sanitizeCell }
        },
        extend: 'csv',
        text: `${I18n.t('export_csv')}`
      },
      {
        className: 'fa fa-spinner fa-spin mr5',
        tag: 'i',
        text: ''
      }
    ]);
  }

  render() {
    return (
      <DataTable
        autoWidth={this.props.autoWidth}
        columns={this.props.columns}
        columnDefs={this.props.columnDefs}
        createdRow={this.props.createdRow}
        defaultOrder={this.props.defaultOrder}
        defaultExpandRows={this.props.defaultExpandRows}
        handleResourcesManually
        resources={this.props.resources}
        isLoading={this.props.isLoading() || this.props.pagesLoaded === 0}
        isLoadingBackground={!this.props.doneLoading}
        noRecordsMessage={this.props.noRecordsMessage}
        pageLength={this.props.pageLength}
        paginatedLoading
        paging={this.props.paging}
        showPagingOptions={this.props.showPagingOptions}
        info={this.props.info}
        sortCallback={this.onSort}
        showSpinner={this.props.showSpinner}
        totalCount={this.state.totalCount}
        ref={(ref) => { this.dataTableRef = ref; }}
        expandCallback={this.props.expandCallback}
        expandCallbackAfterInit={this.props.expandCallbackAfterInit}
        buttons={this._buttons()}
      />
    );
  }
}

export function usePaginatedTable(pageSize) {
  const [resources, setResources] = useState([]);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [filterSent, setFilterSent] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const tableRef = useRef(null);

  const addResources = (newResources) => {
    setDoneLoading(filterSent ? false : newResources.length < pageSize);
    setPagesLoaded(pagesLoaded + 1);
    setFilterSent(false);

    setResources(prev => [...prev, ...newResources]);
  };

  const updateActiveFilters = (newActiveFilters) => {
    setActiveFilters(newActiveFilters);
    setDoneLoading(false);
    setFilterSent(true);
    setPagesLoaded(0);
    setResources([]);
  };

  const updateResource = (resource) => {
    setResources(prev => prev.map(r => (r.id === resource.id ? resource : r)));
  };

  const resetResources = () => {
    setDoneLoading(false);
    setPagesLoaded(0);
    setResources([]);
  };

  const deleteResource = resource => setResources(prevResources => prevResources.filter(r => r.id !== resource.id));

  const renderPaginatedTable = ({ columns, resources: customResources, defaultOrder, ...props }) => {
    useEffect(() => {
      if (props.onDraw && tableRef?.current?.dataTableRef?.tableRef?.dataTable) {
        tableRef.current.dataTableRef.tableRef.dataTable.on('draw', () => {
          props.onDraw();
        })
      }
    }, [tableRef?.current?.dataTableRef?.tableRef?.dataTable])

    const formattedResources = (customResources || resources).map(resource => Object.assign({ actions: '' }, resource));

    return (
      <React.Fragment>
        <PaginatedTable
          defaultOrder={order || defaultOrder || [[0, 'asc']]}
          handleResourcesManually
          resources={formattedResources}
          columns={columns}
          addResources={addResources}
          resetResources={resetResources}
          pagesSize={pageSize}
          pagesLoaded={pagesLoaded}
          doneLoading={doneLoading}
          sortCallback={(a, b) => { setOrder([[a, b]]); }}
          ref={tableRef}
          {...props}
        />
      </React.Fragment>
    );
  };

  return {
    PaginatedTable: renderPaginatedTable,
    activeFilters,
    addResources,
    deleteResource,
    doneLoading,
    pageSize,
    pagesLoaded,
    resetResources,
    resources,
    setDoneLoading,
    setPagesLoaded,
    setResources,
    tableRef,
    updateActiveFilters,
    updateResource
  };
}
