import React from 'react';
import { useModalManager, SubmitModal } from 'components/common/Modal';
import { TextField } from 'components/common/Forms';
import { required } from 'components/common/Forms/Validators';
import * as Routes from 'modules/routes';
import showToast from 'components/common/Toast';

interface TranslateModelProps {
  modelName: string;
  columns: string[];
}

interface TranslatorProps {
  translatableModels: TranslateModelProps[];
}

const TranslateModel = ({ modelName, columns }: TranslateModelProps) => {
  const modalManager = useModalManager();

  const afterSubmit = ({ model_type, num_translations_scheduled }) => {
    showToast(
      `Successfully scheduled background jobs to translate ${num_translations_scheduled} ${model_type} records`,
      { autoClose: 6000 },
    );
  };

  return (
    <>
      <button
        className="card btn btn--link-purple tw-w-[30%] tw-flex-col tw-justify-between tw-whitespace-normal tw-text-base tw-items-center tw-flex"
        type="button"
        onClick={modalManager.open}
      >
        <p className="tw-w-full">
          {modelName}
        </p>
      </button>
      <SubmitModal
        className="tw-text-base"
        headerText={`Translate ${modelName}`}
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
        submitPath={Routes.translate_admin_translations_path({ model_type: modelName })}
        method="post"
      >
        <TextField
          autoFocus
          name="ids"
          placeholder={`Enter a comma separated list of ${modelName} IDs to translate`}
          label="IDs to translate"
          validate={required}
          required
        />
        This will translate the following attributes:
        <ul className="tw-ml-10 tw-list-disc">
          {columns.map(column => (
            <li key={column}>{column}</li>
          ))}
        </ul>
      </SubmitModal>
    </>
  );
};

const Translator = ({ translatableModels }: TranslatorProps) => (
  <div className="tw-text-base tw-flex tw-flex-wrap tw-gap-[30px] tw-mt-5">
    {translatableModels.map(({ columns, modelName }) => (
      <TranslateModel columns={columns} modelName={modelName} />
    ))}
  </div>
);

export default Translator;
