import React, { useEffect, useState, useRef } from 'react';
import Modal, { Footer } from 'components/common/Modal';
import Spinner from 'components/common/Spinner';
import * as Routes from 'modules/routes';
import CollapsibleDropdown from 'modules/CollapsibleDropdown';
import { setupSelectAllCheckboxes } from 'modules/TCIUtils';
import axios from 'axios';
import styles from './ProgramAccessModal.module.scss';

type AccessModalTypes = {
  isOpen: boolean;
  closeModal: () => void;
  getPath: string;
  modalHeaderText: string;
  model: string;
  paramName?: string;
  submitPath: string;
};

const ProgramAccessModal = ({ isOpen, closeModal, submitPath, getPath, model, modalHeaderText,
  paramName = 'program_ids' }: AccessModalTypes) => {
  const [programsByLicense, setProgramsByLicense] = useState({});
  const [programIds, setProgramIds] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const ref = useRef();
  const form = useRef();

  const getProgramsByLicense = () => axios
    .get(Routes.plato_api_programs_by_license_programs_path())
    .then(res => setProgramsByLicense(res.data));

  const getProgramAccessProgramIds = () => axios
    .get(getPath)
    .then(res => setProgramIds(res.data.program_ids));

  useEffect(() => {
    setIsloading(true);
    Promise.all([getProgramAccessProgramIds(), getProgramsByLicense()])
      .catch(err => console.error(err))
      .finally(() => setIsloading(false));
  }, []);

  useEffect(() => {
    if (isLoading) return;

    const $selectAll = $('input.select_all');
    if ($selectAll.length > 0) {
      setupSelectAllCheckboxes($selectAll);
    }

    const selector = new CollapsibleDropdown({
      area: ref.current,
    });
    selector.init();
  }, [isLoading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const values = $(form.current).serializeArray().map((input => input.value));

    return axios
      .put(submitPath, { [model]: { [paramName]: values } })
      .then(() => {
        closeModal();
      });
  };

  const modalContent = () => (
    <div>
      <h2>Access Settings</h2>
      <form onSubmit={onSubmit} ref={form}>
        <ul id="programs_list" ref={ref} className={styles.listContainer}>
          {Object.keys(programsByLicense).map(license => (
            <ul className="top parent expanded">
              <li className="parent" key={license}>
                <i className="fa fa-chevron-down" />
                <label>
                  <input className="select_all" type="checkbox" />
                  {license}
                </label>
              </li>
              <ul className="parent subcategory expanded">
                {Object.keys(programsByLicense[license]).map(programType => (
                  <>
                    <li className="parent" key={programType}>
                      <i className="fa fa-chevron-right" />
                      <label>
                        <input className="select_all" type="checkbox" />
                        {programType}
                      </label>
                    </li>
                    <ul className="parent subcategory program-level leaf">
                      {programsByLicense[license][programType].map(program => (
                        <li className="parent program" key={program.data.id}>
                          <label>
                            <input
                              type="checkbox"
                              name={program.data.id}
                              value={program.data.id}
                              defaultChecked={programIds.includes(program.data.id)}
                            />
                            <span dangerouslySetInnerHTML={{ __html: program.data.full_title_with_edition }} />
                          </label>
                        </li>
                      ))}
                    </ul>
                  </>
                ))}
              </ul>
            </ul>
          ))}
        </ul>

        <Footer
          nearlyFullScreen
          secondaryButtonCallback={closeModal}
          wrapperClassName={styles.footer}
        />
      </form>
    </div>
  );

  return (
    <div className="dsp-flex">
      <Modal
        nearlyFullScreen
        headerText={modalHeaderText}
        isOpen={isOpen}
        closeModal={closeModal}
      >
        {isLoading ? <Spinner /> : modalContent()}
      </Modal>
    </div>
  );
};

export default ProgramAccessModal;
