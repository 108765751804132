import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ImageFormData } from '@/components/admin/Images/interfaces';
import { Label } from '@/components/ui/Form';
import { INPUT_VARIANTS } from '@/components/ui/Form/variants';
import { ImagePreview } from '@/components/common/Forms';
import { FIELD_CONTAINER_CLASSES, LABEL_CLASSES } from '../constants';

interface ImageFileFieldProps {
  name: 'image_en' | 'image_es';
  onChange?: (File) => void;
  loading?: boolean;
  label: string;
}

const ImageFileField = ({ onChange, loading, name, label }: ImageFileFieldProps) => {
  const [imagePreview, setImagePreview] = useState<string>();
  const { control, getValues, register } = useFormContext<ImageFormData>();

  const watch = useWatch({ control, name });

  useEffect(() => {
    const imageFile = (getValues(name) || [])[0];

    if (!imageFile) return;

    if (onChange) onChange(imageFile);
    setImagePreview(URL.createObjectURL(imageFile));
  }, [watch]);

  return (
    <div className="tw-flex tw-gap-2 tw-relative">
      <div className={FIELD_CONTAINER_CLASSES}>
        <Label className={LABEL_CLASSES} htmlFor={name} required>
          {label}
        </Label>

        <div className="tw-flex tw-items-center">
          {imagePreview && <ImagePreview src={imagePreview} />}
          <input
            className={INPUT_VARIANTS.file}
            id={name}
            type="file"
            accept="image/png, image/jpg, image/jpeg, image/gif"
            {...register(name)}
          />
          {loading && (
            <span className="tw-absolute tw-right-0">
              <i aria-hidden className="fa fa-spinner fa-spin" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageFileField;
