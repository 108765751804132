import React from 'react';
import PropTypes from 'prop-types';
import ActionsDropdown from 'common/ActionsDropdown';
import TCIOnlyLock from 'common/TCIOnlyLock';
import ImpersonateModal from './ImpersonateModal';

const StaffDropdown = ({ manageProgramsPath, rowData, openModal, transferPath, userType }) => {
  const _renderTransfer = () => {
    if (transferPath) {
      return (
        <a
          className="dropdown-item"
          href={transferPath.concat(`&teacher[]=${rowData.id}`)}
        >
          <i aria-hidden="true" className="fa fa-exchange" /> Transfer <TCIOnlyLock />
        </a>
      );
    }

    return null;
  };

  const EditButton = (
    <a
      className="dropdown-item"
      href="#"
      role="button"
      onClick={e => openModal(e, rowData, 'edit')}
    >
      <i aria-hidden="true" className="fa fa-edit" /> Edit
    </a>
  );

  return (
    <ActionsDropdown>
      {EditButton}
      <a
        className="dropdown-item"
        href="#"
        role="button"
        onClick={e => openModal(e, rowData, 'delete')}
      >
        <i aria-hidden="true" className="fa fa-trash" /> Delete
      </a>

      <div className="dropdown-divider" />

      <a
        className="dropdown-item"
        href={manageProgramsPath.replace(':teacher_id', rowData.id)}
      >
        <i aria-hidden="true" className="fa fa-edit" /> Manage Programs
      </a>

      {_renderTransfer()}

      <ImpersonateModal staffer={rowData} userType={userType} />
    </ActionsDropdown>
  );
};

StaffDropdown.propTypes = {
  manageProgramsPath: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    username: PropTypes.string,
  }).isRequired,
  transferPath: PropTypes.string,
  userType: PropTypes.string.isRequired
};

StaffDropdown.defaultProps = {
  transferPath: null
};

export default StaffDropdown;
