import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useModalManager } from 'common/Modal';
import FroalaEditor from 'common/FroalaEditor';
import BuilderContext from 'common/Context';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'common/ApolloClient';

import TextEditorConfig, { registerFroalaCommands } from './EditorConfig';
import CustomImageModal from './CustomCommands/CustomImage/CustomImageModal';

registerFroalaCommands();

const TextEditor = ({ id, left, title, top, position }) => {
  const addImageModalManager = useModalManager();
  const [editorInstance, setEditorInstance] = useState(null);
  const { draggableChoiceDispatch, questionId } = useContext(BuilderContext);

  const froalaConfig = TextEditorConfig({ addImageModalManager, setEditorInstance });

  const handleChange = async (text) => {
    await draggableChoiceDispatch({ payload: { id, left, title: text, top, position }, type: 'UPDATE' });
  };

  const customImageModal = () => (
    <ApolloProvider client={apolloClient}>
      <CustomImageModal
        editorInstance={editorInstance}
        questionId={questionId}
        modalManager={addImageModalManager}
      />
    </ApolloProvider>
  );

  return (
    <>
      <FroalaEditor
        customConfig={froalaConfig}
        model={title}
        onModelChange={handleChange}
        text={title}
      />
      {addImageModalManager.isOpen && customImageModal()}
    </>
  );
};

TextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  title: PropTypes.string,
  top: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
};

TextEditor.defaultProps = {
  title: '',
};

export default TextEditor;
