import { formatSelectOption } from '../../common/Forms/Utils';

/**
 * Return array with options for Select component. Used for generating options
 * for district course codes.
 * @param codes
 * @returns array of value/label hashes
 */
export default function getCodeSelectOptions(codes) {
  return codes.map(code => formatSelectOption(code));
}
