import React, { useEffect, useLayoutEffect, useState } from 'react';
import VideoPlayer from '.';

const config = { attributes: true };

interface OldSlideShowVideoPlayerProps {
  maxWidth: string | number;
  onPlay: () => void;
  setLoading: () => void;
  setVideoHeight: () => void;
  video: {
    caption_es_url: string;
    caption_url: string;
    is_silent: boolean;
    poster_url: string;
    url: string;
  }
  width: string | number;
}

type Player = { el_: HTMLDivElement, paused: () => boolean, pause: () => void };

const OldSlideShowVideoPlayer = (props: OldSlideShowVideoPlayerProps) => {
  const [player, setPlayer] = useState<Player>();
  const [parent, setParent] = useState<HTMLDivElement>();

  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'style') {
          if (parent.style.visibility === 'hidden') {
            if (player && !player.paused()) player.pause();
          }
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (!player) return;

    // eslint-disable-next-line no-underscore-dangle
    const $slideParent = $(player.el_).closest('.slide');
    setParent($slideParent[0]);
  }, [player]);

  useEffect(() => {
    if (!parent) return;

    const observer = new MutationObserver(callback);
    observer.observe(parent, config);

    return () => {
      observer.disconnect();
    };
  }, [parent]);

  return (
    <VideoPlayer
      {...props}
      sharePlayer={(playerRef) => {
        setPlayer(playerRef);
      }}
    />
  );
};

export default OldSlideShowVideoPlayer;
