import React, { useEffect, useState } from 'react';
import { plato_api_get_glossary_terms_glossary_terms_path, plato_api_glossary_term_models_path } from '@/modules/routes';
import Axios from 'axios';
import Select from 'react-select-5';
import { SpinnerIcon } from '@/components/common/Utils';
import AddGlossaryTerm from '@/components/shared/Froala/plugins/GlossaryTerms/AddGlossaryTerm/AddGlossaryTerm';
import { useModalManager } from '@/components/common/Modal';
import { useQuery } from '@tanstack/react-query';
import { renderToStaticMarkup } from 'react-dom/server';
import GlossaryTerm from '@/components/shared/Froala/plugins/GlossaryTerms/GlossaryTerm';
import Menu from './Menu';
import SingleValue from './SingleValue';

type Term = { definition: string, id: number, term: string };
type Data = { label: string, value: number };

interface FormProps {
  editorInstance: any;
  locale: Locale;
}

const Form = ({ editorInstance, locale } : FormProps) => {
  const [selectedTerm, setSelectedTerm] = useState({ label: '', value: 0 });
  const [text, setText] = useState('');
  const modalManager = useModalManager(false);

  const handleSelect = () => {
    setTimeout(() => {
      setText(editorInstance.selection.text());
    }, 10);
  };

  const path = window.location.pathname.split('/');
  const sectionId = path[3];

  let lessonId = '';

  const persistSelect = () => {
    if (!editorInstance) return;
    editorInstance.selection.restore();
    editorInstance.selection.save();
  };

  const formatVocab = (vocabTerms: Term[]) => vocabTerms.map(term => ({
    label: term.term,
    value: term.id,
  })).sort((a, b) => a.label.localeCompare(b.label));


  useEffect(() => {
    const queryParams = window.location.search;
    const params = new URLSearchParams(queryParams);
    lessonId = params.get('lesson_id');

    const container = document.querySelector('.fr-box');
    if (!container || !editorInstance) return () => null;

    document.addEventListener('selectionchange', handleSelect);
    return () => document.removeEventListener('selectionchange', handleSelect);
  }, []);

  const { isLoading, error, data } = useQuery(
    ['glossaryTerms'],
    () => Axios.get(plato_api_get_glossary_terms_glossary_terms_path({ lesson_id: lessonId })),
  );

  const handleSelectTerm = (term: Data) => {
    setSelectedTerm(term);
    persistSelect();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!sectionId) return;
    editorInstance.selection.restore();
    const glossaryTerm = data?.data?.glossary_terms.find(term => term.id === selectedTerm.value);

    const glossaryTermModelId = await Axios.post(plato_api_glossary_term_models_path(), {
      glossary_term_model: {
        model_id: sectionId,
        model_type: 'Section',
        glossary_term_id: glossaryTerm.id,
      },
    })
    .then(res => res.data.data.id)
    .catch((err) => {
      console.log(err);
    });

    editorInstance.html.insert(renderToStaticMarkup(
      <GlossaryTerm
        originalText={editorInstance.selection.text()}
        modelId={glossaryTermModelId}
        selectedTerm={glossaryTerm}
        locale={locale}
      />,
    ));

    setSelectedTerm({ label: '', value: 0 });
    setText('');
    e.preventDefault();
  };

  if (isLoading || error) {
    return (
      <div className="tw-w-[350px] tw-h-[100px] tw-flex tw-justify-center tw-items-center tw-text-5xl tw-pt-5">
        <SpinnerIcon />
      </div>
    );
  }

  const options = data?.data?.glossary_terms ? formatVocab(data.data.glossary_terms) : [];

  return (
    <>
      <AddGlossaryTerm isOpen={modalManager.isOpen} handleClose={modalManager.close} />
      <form onSubmit={handleSubmit} className="tw-w-[350px] tw-mx-auto tw-space-y-4 tw-p-4">
        <div className="tw-flex tw-items-center tw-space-x-4">
          <label
            htmlFor="term-select"
            className="tw-font-bold tw-w-1/8"
          >
            Term
          </label>
          <Select
            value={selectedTerm}
            onChange={handleSelectTerm}
            options={options}
            className="tw-w-full tw-h-full tw-relative"
            components={{
              Menu: props => <Menu {...props} handleOpen={modalManager.open} />,
              SingleValue,
            }}
          />
        </div>

        <div className="tw-flex tw-items-center tw-space-x-4">
          <label
            htmlFor="text-input"
            className="tw-font-bold tw-w-1/8"
          >
            Text
          </label>
          <textarea
            id="text-input"
            value={text}
            disabled
            className="tw-w-full tw-border tw-border-gray-300 tw-rounded-lg tw-p-2 tw-focus:ring-2"
            rows={4}
          />
        </div>

        <div className="tw-flex tw-justify-end">
          <button
            type="submit"
            className="btn btn--purple btn--sm"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Form;
