import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from "../../../ContentActions/ContentActions.module.scss";

const MAX = 999;
const MIN = 0;

interface MaxWidthProps {
  editorInstance: { undo: { saveStep: () => void; } };
  htmlElement: JQuery<HTMLElement>;
  setEditMode: (nextState: boolean) => void;
  setIsEditingSize: (nextState: boolean) => void;
}

export const updateHtmlElement = async (id: string, buttonWidth: string) => {
  const res = await Axios.patch(Routes.plato_api_html_element_path(id, {
    button_max_width: buttonWidth,
  }));

  const { data: { data: { button_max_width: maxWidth } } } = res.data;

  return maxWidth;
};

const MaxWidth = ({ editorInstance, htmlElement, setEditMode, setIsEditingSize }: MaxWidthProps) => {
  const htmlElementId = htmlElement.attr('data-html-element-id');
  const initialWidth = parseInt(htmlElement[0].style.maxWidth.replace('px', ''), 10);
  const [value, setValue] = useState(initialWidth || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => { setEditMode(false); };
  }, []);

  const onSubmit = async ( value: (string | number) ) => {
    const maxWidth = Math.max(MIN, Math.min(MAX, parseFloat(value.toString() || '0')));
    setValue(maxWidth);
    const newMaxWidth = await updateHtmlElement(htmlElementId, (maxWidth.toString() + 'px'));

    if (htmlElement[0]) {
      htmlElement[0].style.maxWidth = newMaxWidth;
      htmlElement.attr('data-updated-at', Date.now().toString());
    }

    editorInstance.undo.saveStep();
    setEditMode(false);
  };

  return (
    <div className={`${styles.container} ${styles.paddingContainer}`}>
      <div className={styles.contentWrapper}>
        <div className={styles.paddingWrapper}>
          <div className={styles.paddingInner}>
            <label>Max Width</label>
            <input
              className={styles.paddingInput}
              min={MIN.toString()}
              max={MAX.toString()}
              type="number"
              name=""
              onChange={(e) => {
                setIsSubmitting(true);
                onSubmit(e.target.value)
                  .then(() => setIsSubmitting(false));
              }}
              placeholder=" "
              value={value || ''}
            />
          </div>
          <span>px</span>
        </div>
        <div className={styles.btnWrapper}>
          <button
            className={`${styles.submitBtn}`}
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              setIsSubmitting(true);
              onSubmit(value)
                .then(() => {
                  setIsSubmitting(false);
                  setIsEditingSize(false);
                });
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
};

export default MaxWidth;
