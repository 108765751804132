import React from 'react';
import { twMerge } from '@/utils';
import Card from '@/components/ui/Card';
import SidebarHead from '@/components/ui/Sidebar/SidebarHead';

interface SidebarProps {
  children: React.ReactNode;
  className?: string;
  isFloating?: boolean;
}

const Sidebar = ({ children, className = '', isFloating = false }: SidebarProps) => (
  <Card
    className={twMerge(
      'tw-min-w-0 tw-w-48 tw-min-h-0 tw-shrink-0 tw-grow-0 tw-h-[0%] tw-basis-48 tw-p-4 tw-box-border',
      'tw-border-solid tw-border-gray-100 tw-border',
      isFloating ? 'tw-sticky tw-top-14' : '',
      className,
    )}
  >
    {children}
  </Card>
);

Sidebar.Head = SidebarHead;

export default Sidebar;
