/* eslint-disable no-param-reassign */

import { useEffect } from 'react';
import * as fabric from 'fabric';
import {
  Pen, Select, Text, Shape,
} from '@/components/common/Forms/Questions/Drawing/Modes';
import { DrawingMode, DRAWING_MODES, Mode } from '../constants';

interface UseModesProps {
  canvas: fabric.Canvas;
  eraserWidth: number;
  mode: Mode;
  color: string;
  penWidth: number;
  penOpacity: number;
}

const useModes = ({ canvas, eraserWidth, mode, color, penWidth, penOpacity }: UseModesProps) => {
  useEffect(() => {
    if (!canvas) return;

    canvas.isDrawingMode = DRAWING_MODES.includes(mode as DrawingMode);
    canvas.selection = mode === 'select';

    switch (mode) {
      case 'pen':
        Pen(canvas, color, penWidth, penOpacity);
        break;
      /* istanbul ignore next */
      // case 'eraser':
      //   Eraser(canvas, eraserWidth, questionId);
      //   break;
      case 'select':
        Select(canvas);
        break;
      case 'text':
        Text(canvas);
        break;
      case 'shape':
        Shape(canvas);
        break;
      /* istanbul ignore next */
      default:
        canvas.freeDrawingBrush = null;
        canvas.isDrawingMode = false;
    }
  }, [canvas, mode, color, penWidth, penOpacity, eraserWidth]);
};

export default useModes;
