import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import ParsedVideo, { useParsedVideo } from './ParsedElements/ParsedVideo';

interface ParsedFroalaHtmlProps {
  containerSelector?: string;
  html: string,
  shouldAutoPlayVideo?: boolean,
  shouldParse: boolean,
  separateVideo?: boolean,
  targetVideoContainerId?: string,
}

export function useVideoHtmlParser(containerSelector: string, separateVideo?: boolean, targetVideoContainerId?: string, shouldAutoPlayVideo?: boolean) {
  const ref = useRef<HTMLDivElement>();

  const parse = () => {
    if (!ref.current) return;

    const videos = ref.current.querySelectorAll(`${containerSelector} video`);

    videos.forEach((videoElement) => {
      const video = videoElement as HTMLElement;

      if (video.classList.contains('video-js')) return;
      video.style.display = 'none';

      const parsedVideoProps = useParsedVideo(video.parentElement);
      const parent = document.createElement('div');

      if (typeof parsedVideoProps.props.maxWidth === 'string' && parsedVideoProps.props.maxWidth.includes('%')) {
        parent.style.width = parsedVideoProps.props.maxWidth;
      } else {
        // Set parent max width to prevent overflow.
        parent.style.maxWidth = '100%';
        parent.style.width = `${parsedVideoProps.props.maxWidth}px`;
      }

      // only apply inline-block if we have a fixed width video
      if (parent.style.width?.includes('px')) {
        parent.style.display = 'inline-block';
      }

      if (video.style.textAlign === 'center') {
        parent.style.position = 'relative';
        parent.style.left = '50%';
        parent.style.transform = 'translateX(-50%)';
      }

      if (separateVideo) {
        const videoContainer = document.getElementById(targetVideoContainerId);

        videoContainer.appendChild(parent);
      } else {
        video.parentElement.parentElement.insertBefore(parent, video.parentElement);
      }

      ReactDOM.render(
        <ParsedVideo
          fallbackElement={video}
          shouldAutoPlayVideo={shouldAutoPlayVideo}
          {...parsedVideoProps.props}
        />, parent
      );
    });
  };

  return { parse, ref };
}

const ParsedFroalaHtml = ({ containerSelector = '.fr-view', html, shouldAutoPlayVideo, shouldParse, separateVideo, targetVideoContainerId }: ParsedFroalaHtmlProps) => {
  const { parse, ref } = useVideoHtmlParser(
    containerSelector,
    separateVideo,
    targetVideoContainerId,
    shouldAutoPlayVideo,
  );

  useLayoutEffect(() => {
    if (!shouldParse) return;

    parse();
  }, [html]);

  return (
    <div ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default ParsedFroalaHtml;
