import React from 'react';

interface CardBodyProps {
  children: React.ReactNode;
  className?: string;
}

const CardBody = ({ children, className = '' }: CardBodyProps) => (
  <div className={`tw-p-3 ${className}`}>{children}</div>
);

export default CardBody;
