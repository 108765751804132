import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from '@/components/staffer/AssignmentModal/WhatSection/WhatSection.module.scss';
import sectionStyles from '@/components/staffer/AssignmentModal/Section.module.scss';
import { SelectField } from '@/components/common/Forms';
import useAssignablesStore from '../store/useAssignablesStore';
import { useQuery } from '@tanstack/react-query';

const GameSelector = () => {
  const [games, setGames] = useState([]);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const programId = useAssignablesStore(state => state.programId);
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const initialModels = useAssignablesStore(state => state.initialValues.modelsToAssign);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);

  const { isFetching: isLoadingGames } = useQuery(['fetchGames'], () => {
    const gamePath = Routes.plato_api_get_game_assignables_assignables_path({
      lesson_id: selectedUnitLesson.value,
      program_id: programId,
    });

    Axios.get(gamePath)
      .then(res => res.data.assignables)
      .then(gamesData => setGames(gamesData))
      .catch(err => console.error(err));
  });

  useEffect(() => {
    if (isLoadingGames || loadingLessons || loadingTypes) return;

    if (initialModels.length) {
      setStore({ score: initialModels[0]['score'] });
      formChangeValue(initialModels[0].value, 'modelsToAssign');
    }
    else if (games.length === 1) {
      setStore({ score: games[0].score })
      formChangeValue(games[0].value, 'modelsToAssign');
    }
  }, [initialModels, games, loadingLessons, loadingTypes, isLoadingGames]);

  const handleGameChange = (gameId: string) => {
    const score = games.find(game => game.value.includes(gameId))?.score;
    setStore({ score });
    formChangeValue(gameId, 'modelsToAssign');
  };

  return (
    <SelectField
      className={styles.assignableSelector}
      disabled={games.length === 0}
      fieldClass={styles.largeField}
      label="Details"
      labelClass={sectionStyles.label}
      name="modelsToAssign"
      searchable={false}
      placeholder="Select one"
      onChange={handleGameChange}
      options={games}
      required
      rowClass={sectionStyles.fieldContainer}
    />
  );
};

export default GameSelector;
