import React from 'react';
import { useModalManager } from '@/components/common/Modal';
import { Button } from '@/components/ui/Buttons';
import AddLessonsModal from './AddLessonsModal';

interface AddLessonsProps {
  programId: number;
}

const AddLessons = ({ programId }: AddLessonsProps) => {
  const modalManager = useModalManager();

  return (
    <>
      <Button
        color="green"
        small
        onClick={() => modalManager.open()}
      >
        Add Lessons
      </Button>
      {modalManager.isOpen && (
        <AddLessonsModal
          parentProgramId={programId}
          modalManager={modalManager}
        />
      )}
    </>
  );
};

export default AddLessons;
