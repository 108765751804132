import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import AddModal from 'common/VideoManager/AddModal';
import { useDataTable } from 'common/DataTable';
import * as Routes from 'routes';
import ActionsDropdown from 'common/ActionsDropdown';
import DeleteModal from './DeleteModal';
import { flattenDeep } from '../../../modules/TCIUtils';
import EditModal from './EditModal';

const VideoManagerTable = ({ modelType, modelId, showVideoLessonOption }) => {
  const dataPath = Routes.plato_api_model_videos_path({ for_model: { model_id: modelId, model_type: modelType } });
  const {
    DataTable,
    addResource,
    deleteResource,
    resources,
    updateResource
  } = useDataTable(dataPath);

  const columns = ['video_poster_url', 'video_video_file_name', 'width', 'snippet', 'actions'];

  const columnDefs = [
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          rowData.video_poster_url && <img width="50px" src={rowData.video_poster_url} alt="..." />,
          td
        );
      },
      targets: 0,
      title: 'Poster',
      width: '100px'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={rowData.video_url}
          >
            {rowData.video_video_file_name}
          </a>,
          td
        );
      },
      targets: 1,
      title: 'File'
    },
    {
      targets: -3,
      width: '60px'
    },
    {
      targets: -2,
      title: 'Video Snippet',
      width: '120px'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <ActionsDropdown>
            <EditModal
              modelVideo={rowData}
              afterSubmit={updateResource}
              showVideoLessonOption={showVideoLessonOption}
            />
            <DeleteModal modelVideoId={rowData.id} afterSubmit={deleteResource} />
          </ActionsDropdown>,
          td
        );
      },
      sortable: false,
      targets: -1,
      width: '100px'
    }
  ];

  if (showVideoLessonOption) {
    columns.splice(2, 0, 'video_lesson');

    columnDefs.push({
      createdCell: (td, cellData) => {
        ReactDOM.render(
          cellData && <div className="center"><i className="fa fa-check" aria-hidden /></div>,
          td
        );
      },
      targets: 2,
      title: 'Video Lesson?',
      width: '110px'
    });
  }

  return (
    <Fragment>
      <AddModal
        buttonClassName="btn btn--green btn--sm mb5"
        afterSubmit={addResource}
        modelId={modelId}
        modelType={modelType}
        unique={false}
        initialValues={{ width: 580 }}
        showWidth
        showVideoLessonOption={showVideoLessonOption}
      />
      <DataTable
        columns={columns}
        resources={resources.map(resource => flattenDeep(resource))}
        info={false}
        paging={false}
        columnDefs={columnDefs}
      />
    </Fragment>
  );
};

VideoManagerTable.propTypes = {
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired,
  showVideoLessonOption: PropTypes.bool
};

VideoManagerTable.defaultProps = {
  showVideoLessonOption: false
};

export default VideoManagerTable;
