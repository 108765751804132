import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';

import AdvancedFilters from 'common/AdvancedFilters';
import PaginatedTable from 'common/DataTable/PaginatedTable';
import PathContext from 'common/Context';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from 'common/AdvancedFilters/Utils';
import { matchAttributesForSingleRow } from 'common/DataTable/Utils';
import { isAdmin, isSysadmin, isSupportManager } from 'common/Utils';
import Tooltip from 'common/Tooltip';
import CopyToClipboardButton from 'common/CopyToClipboardButton';
import * as Routes from 'routes';
import { pollEndpoint, arrayToAjaxParams } from '../../../../modules/TCIUtils';

import AddStaff from './AddStaff';
import BulkAction from './BulkAction';
import Checkbox from './BulkAction/Checkbox';
import Delete from './Delete';
import Edit from './Edit';
import RemovePrograms from './RemovePrograms';
import StaffDropdown from './StaffDropdown';

import styles from './StaffTable.module.scss';
import RosterExclusionModal from './RosterExclusionModal/RosterExclusionModal';
import DeleteStafferModal from './DeleteStafferModal/DeleteStafferModal';
import LockTeacherModal from './LockTeacherModal';

export default class StaffTable extends Component {
  static propTypes = {
    accessCode: PropTypes.string.isRequired,
    autoRosterDistrict: PropTypes.bool.isRequired,
    canCsvUpdate: PropTypes.bool,
    columns: PropTypes.instanceOf(Array).isRequired,
    contactTypeOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    flags: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })),
    initialFilters: filtersPropTypes.isRequired,
    paths: PropTypes.objectOf(PropTypes.string),
    programCodes: PropTypes.instanceOf(Array).isRequired,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired,
    stafferId: PropTypes.number.isRequired,
    subscriberId: PropTypes.number.isRequired,
    userIsSysadmin: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    canCsvUpdate: true,
    flags: [],
    paths: {}
  };

  static getColumnMapping() {
    return [
      null,
      'last_name',
      'first_name',
      'email',
      'role',
      'current_sign_in_at',
      'school_year_sign_in_count',
      'num_classes',
      'num_programs',
      null,
      'uid',
      'contact_types'
    ];
  }

  constructor(props) {
    super(props);

    const paginationPageSize = 50;

    this.state = {
      bulkChecked: 'none',
      deleteModalOpen: false,
      deleteStafferModalOpen: false,
      doneLoading: false,
      editModalOpen: false,
      filterSent: false,
      getPath: this.props.paths.getPath.replace('pageSize', paginationPageSize.toString()),
      lockAction: 'lock',
      lockTeacherModalOpen: false,
      pageSize: paginationPageSize,
      pagesLoaded: 0,
      removeProgramsModalOpen: false,
      resources: [],
      rosterExclusionModalOpen: false,
      rowData: null,
      selectedStaffIds: new Set(),
      showSpinner: false,
    };

    this.openModal = this.openModal.bind(this);
    this.setBulkState = this.setBulkState.bind(this);
    this.onTableDraw = this.onTableDraw.bind(this);
    this.bulkProgramRemove = this.bulkProgramRemove.bind(this);
    this.updateProgramState = this.updateProgramState.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleBulkCheckboxChange = this.handleBulkCheckboxChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.hyperlinkClasses = this.hyperlinkClasses.bind(this);
    this.removeFinished = this.removeFinished.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this._updateActiveFilters = this._updateActiveFilters.bind(this);
    this._addResources = this._addResources.bind(this);
    this._resetResources = this._resetResources.bind(this);
  }

  openModal(e, rowData, type, extraState = undefined) {
    e.preventDefault();
    let newState = { [`${type}ModalOpen`]: true };
    if (rowData) newState = Object.assign(newState, { rowData });
    if (extraState) newState = Object.assign(newState, extraState);
    this.setState(newState);
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false });
  }

  handleBulkCheckboxChange() {
    const currentPageStaffIds = this._getCurrentPageStaffIds();
    const updatedSet = new Set(this.state.selectedStaffIds);
    let bulkChecked = 'none';

    if (this.state.bulkChecked === 'all') {
      currentPageStaffIds.forEach((id) => {
        updatedSet.delete(id);
        this._renderCheckboxForRow({
          id, checked: false, target: document.getElementById(`bulk-staff-${id}`)
        });
      });
    }
    else {
      currentPageStaffIds.forEach((id) => {
        updatedSet.add(id);
        this._renderCheckboxForRow({
          id, checked: true, target: document.getElementById(`bulk-staff-${id}`)
        });
      });
      bulkChecked = 'all';
    }

    this.setState({ selectedStaffIds: updatedSet, bulkChecked });
  }

  handleCheckboxChange(e) {
    const id = parseInt(e.target.value, 10);
    const updatedSet = new Set(this.state.selectedStaffIds);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    }
    else {
      updatedSet.add(id);
    }
    const bulkChecked = this._isCurrentPageAllChecked(updatedSet);

    this.setState(
      { selectedStaffIds: updatedSet, bulkChecked },
      () =>
          this._renderCheckboxForRow({
            id,
            checked: this.state.selectedStaffIds.has(id),
            target: document.getElementById(`bulk-staff-${id}`)
          })
    );
  }

  updateTable({ newStaff, updatedStaff, deletedIds }) {
    // update edited staff
    let resources = this.state.resources.map((staff) => {
      if (Array.isArray(updatedStaff)) {
        const updatedRecord = updatedStaff.find(updated => updated.id === staff.id);

        if (updatedRecord) {
          matchAttributesForSingleRow(updatedRecord, this.props.columns);
          return updatedRecord;
        }
      }
      else {
        if (updatedStaff && staff.id === updatedStaff.id) {
          matchAttributesForSingleRow(updatedStaff, this.props.columns);
          return updatedStaff;
        }
      }

      return Object.assign({}, staff);
    });

    // add staff
    if (newStaff) {
      matchAttributesForSingleRow(newStaff, this.props.columns);
      resources = resources.concat([newStaff]);
    }

    // remove deleted staff
    if (deletedIds) {
      resources = resources.filter(staff => !deletedIds.includes(staff.id));
    }

    this.setState({ resources });
  }

  _updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this._showFilteredResources()
    );
  }

  _showFilteredResources() {
    const getPath = buildFilterPath(
      this.props.paths.getPath.replace('pageSize', this.state.pageSize.toString()),
      this.state.activeFilters
    );

    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getPath,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  _getCurrentPageStaffIds() {
    if (this.tableRef) {
      const rows = this.tableRef.getRows({ page: 'current' });
      if (rows) return Array.from(rows).map(row => row.id);
    }

    return [];
  }

  _isCurrentPageAllChecked(set) {
    if (set.size === 0) return 'none';

    const currentPageStaffIds = this._getCurrentPageStaffIds();
    return currentPageStaffIds.every(staffId => set.has(staffId)) ? 'all' : 'some';
  }

  _renderDeleteModal() {
    if (!this.state.deleteModalOpen) return null;

    return (
      <Delete
        closeModal={() => this.closeModal('delete')}
        isOpen={this.state.deleteModalOpen}
        id={this.state.rowData.id}
        updateTable={this.updateTable}
      />
    );
  }

  _renderRosterExclusionModal() {
    if (!this.state.rosterExclusionModalOpen) return null;

    return (
      <RosterExclusionModal
        modalIsOpen={this.state.rosterExclusionModalOpen}
        closeModal={() => this.closeModal('rosterExclusion')}
        rosterAction={this.state.rosterAction}
        stafferIds={this.state.selectedStaffIds}
        subscriberId={this.props.subscriberId}
        updateTable={this.updateTable}
      />
    );
  }

  _renderDeleteStafferModal() {
    if (!this.state.deleteStafferModalOpen) return null;

    return (
      <DeleteStafferModal
        modalIsOpen={this.state.deleteStafferModalOpen}
        closeModal={() => this.closeModal('deleteStaffer')}
        stafferIds={this.state.selectedStaffIds}
        subscriberId={this.props.subscriberId}
        updateTable={this.updateTable}
      />
    );
  }

  countRosterExclusions() {
    const excluded = [];
    const unexcluded = [];

    this.state.resources.forEach((staffer) => {
      if (this.state.selectedStaffIds.has(staffer.id)) {
        staffer.exclude_from_rostering ? excluded.push(staffer) : unexcluded.push(staffer);
      }
    })

    return ({
      excluded: excluded.length,
      unexcluded: unexcluded.length
    });
  }

  countLocked() {
    return this.state.resources
      .filter(staffer => this.state.selectedStaffIds.has(staffer.id))
      .reduce((acc, staffer) => acc + (staffer.locked ? 1 : 0), 0);
  }

  countPrograms() {
    return this.state.resources
      .filter(staffer => this.state.selectedStaffIds.has(staffer.id))
      .reduce((acc, staffer) => acc + staffer.num_programs, 0);
  }

  updateProgramState(updatedStaffers) {
    const staffers = {};
    updatedStaffers.forEach(staffer => staffers[staffer.id] = staffer);
    const updatedResources = [...this.state.resources];

    updatedResources.forEach((staffer) => {
      if (staffer.id in staffers) {
        Object.assign(staffer, staffers[staffer.id]);
      }
    });

    this.setState({ resources: updatedResources });
  }

  removeFinished(res) {
    const isDone = res.data.data.reduce((acc, staffer) => acc && staffer.classroom_destroy_finished, true);
    if (isDone) this.updateProgramState(res.data.data);
    return isDone;
  }

  bulkProgramRemove(programIds) {
    const staffIds = Array.from(this.state.selectedStaffIds);
    const data = {
      program_ids: Array.from(programIds),
      staffer_ids: staffIds
    };
    const endpoint = `${this.props.paths.removeFinishedEndpoint}?${arrayToAjaxParams(staffIds, 'id_in')}`;
    this.setState({ showSpinner: true });
    return Axios
      .post(this.props.paths.bulkRemoveProgramPath, data)
      .then(() => pollEndpoint({ endpointUrl: endpoint, timeout: 500000, until: this.removeFinished }))
      .then(() => Axios.post(Routes.reset_assigned_counts_admin_subscriber_seat_pools_path({
        program_ids: Array.from(programIds),
        subscriber_id: this.props.subscriberId
      })))
      .catch(e => console.log(e))
      .then(() => this.setState(prevState => ({
        bulkChecked: this._isCurrentPageAllChecked(prevState.selectedStaffIds),
        showSpinner: false
      })));
  }

  _renderRemoveProgramsModal() {
    if (this.state.removeProgramsModalOpen) {
      return (
        <RemovePrograms
          bulkProgramRemove={this.bulkProgramRemove}
          closeModal={() => this.closeModal('removePrograms')}
          modalOpen={this.state.removeProgramsModalOpen}
          stafferIds={Array.from(this.state.selectedStaffIds)}
          subscriberId={this.props.subscriberId}
        />
      );
    }

    return null;
  }

  _renderEditModal() {
    if (this.state.editModalOpen) {
      return (
        <Edit
          autoRosterDistrict={this.props.autoRosterDistrict}
          closeModal={() => this.closeModal('edit')}
          contactTypeOptions={this.props.contactTypeOptions}
          modalOpen={this.state.editModalOpen}
          staff={this.state.rowData}
          updateTable={this.updateTable}
          userIsSysadmin={this.props.userIsSysadmin}
          flags={this.props.flags}
        />
      );
    }

    return null;
  }

  _renderBulkCheckbox() {
    if (this.state.resources.length && document.getElementById('select-all')) {
      return ReactDOM.createPortal(
        <Checkbox
          checked={this.state.bulkChecked === 'all'}
          indeterminate={this.state.bulkChecked === 'some'}
          handleChange={this.handleBulkCheckboxChange}
          labelText={(this.state.bulkChecked ? 'Unselect' : 'Select').concat(' all staff on this page')}
          name="select-all"
          srOnly
        />,
        document.getElementById('select-all')
      );
    }

    return null;
  }

  _renderCheckboxForRow({ id, checked, target }) {
    const fullName = target.dataset.fullName;

    ReactDOM.render(
      <Checkbox
        checked={checked}
        handleChange={this.handleCheckboxChange}
        labelText={checked ? `Unselect ${fullName}` : `Select ${fullName}`}
        name="bulk-staff"
        srOnly
        value={id.toString()}
      />,
      target
    );
  }

  hyperlinkClasses(td, cellData, rowData) {
    if (cellData > 0 && !isSupportManager(this.props.userType)) {
      const data = {
        initialFilters: [
          {
            propertyValue: 'staffer_full_name_with_email_or_staffers_full_name_with_email',
            operatorValue: 'eq',
            value: `${rowData.first_name} ${rowData.last_name} (${rowData.email})`
          }
        ]
      };

      ReactDOM.render(
        <a href={`${this.props.paths.classesPath}?${$.param(data)}`}>{cellData}</a>,
        td
      );
    }

    return cellData;
  }

  _getColumnDefs() {
    const defs = [
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <>
              {cellData}
              &nbsp;
              {rowData.locked && (
                <Tooltip
                  content="Locked users are redirected to a Locked Access page after signing in. Contact ar@teachtci.com
                  to resolve payment issues."
                  theme="white"
                  size="medium"
                >
                  <i className="fa fa-lock" aria-hidden="true" />
                </Tooltip>
              )}
            </>,
            td
          );
        },
        sortable: false,
        targets: 3,
      },
      {
        render: (data) => {
          if (!data) return '';

          return moment(data).format('MMM DD, YYYY');
        },
        targets: 5,
        title: 'Last Sign In',
        type: 'date',
      },
      {
        targets: 6,
        title: 'Num of Sign-ins'
      },
      {
        targets: 7,
        title: 'Num of Classes',
        createdCell: this.hyperlinkClasses
      },
      { targets: 8, title: 'Num of Programs' },
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <p>{rowData.uid}</p>,
            td
          );
        },
        targets: 10,
        title: 'UID',
        visible: false
      },
      {
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <p>{rowData.contact_types}</p>,
            td
          );
        },
        targets: 11,
        title: 'Contact Types',
        visible: false
      }
    ];

    if (isAdmin(this.props.userType) || isSupportManager(this.props.userType)) {
      return defs.concat([
        {
          targets: 0,
          title: '<div id="select-all" />',
          sortable: false,
          searchable: false,
          data: null,
          visible: isAdmin(this.props.userType),
          createdCell: (td, cellData, rowData) => {
            $(td).attr({
              id: `bulk-staff-${rowData.id}`,
              'data-full-name': `${rowData.first_name} ${rowData.last_name}`
            });

            this._renderCheckboxForRow({
              id: rowData.id,
              checked: this.state.selectedStaffIds.has(rowData.id),
              target: td
            });
          }
        },
        {
          targets: 9,
          data: null,
          width: '12%',
          sortable: false,
          searchable: false,
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <StaffDropdown
                rowData={rowData}
                openModal={this.openModal}
                manageProgramsPath={this.props.paths.manageProgramsPath}
                userType={this.props.userType}
                transferPath={isSysadmin(this.props.userType) ? this.props.paths.transferPath : null}
              />,
              td
            );
          }
        }
      ]);
    }

    return defs.concat([
      {
        targets: 0,
        visible: false
      },
      {
        targets: 8,
        visible: false
      }
    ]);
  }

  _renderAddStaff() {
    return (
      <AddStaff
        autoRosterDistrict={this.props.autoRosterDistrict}
        canCsvUpdate={this.props.canCsvUpdate}
        contactTypeOptions={this.props.contactTypeOptions}
        flags={this.props.flags}
        isAdmin={isAdmin(this.props.userType)}
        programCodes={this.props.programCodes}
        updateTable={this.updateTable}
        userIsSysadmin={this.props.userIsSysadmin}
        userType={this.props.userType}
        stafferId={this.props.stafferId}
      />
    );
  }

  _renderBulkAction() {
    if (this.state.selectedStaffIds.size && isAdmin(this.props.userType)) {
      return (
        <BulkAction
          isSysadmin={isSysadmin(this.props.userType)}
          selectedStaffIds={Array.from(this.state.selectedStaffIds)}
          openDeleteStafferModal={e => this.openModal(e, null, 'deleteStaffer')}
          openRemoveProgramsModal={e => this.openModal(e, null, 'removePrograms')}
          openLockTeacherModal={(e, lockAction) => this.openModal(e, null, 'lockTeacher', { lockAction })}
          openRosterExclusionModal={this.openModal}
          numLocked={this.countLocked()}
          numSelectedPrograms={this.countPrograms()}
          rosterExclusionNums={this.countRosterExclusions()}
        />
      );
    }

    return null;
  }

  _renderExcludeFromRosteringLabel() {
    if (this.state.showExcludeLabel) {
      return (
        <div className={`${styles.excludeLabelContainer} mb5`}>
          <div className={styles.redBox} /> Excluded from auto-rostering
        </div>
      );
    }

    return null;
  }

  _renderFilter() {
    return (
      <div>
        <AdvancedFilters
          initialFilters={this.props.initialFilters}
          properties={this.props.properties}
          resources={this.state.resources}
          updateActiveFilters={this._updateActiveFilters}
          asyncSearchPath={this.props.paths.asyncSearchPath}
        />
        <hr />
      </div>
    );
  }

  _renderAccessCode() {
    return (
      <div className="mb10">
        Account Code: {this.props.accessCode}
        <Tooltip
          content="Share this code with teachers who will create an account manually. This code changes every 6 months."
          theme="white"
          size="medium"
        >
          <i className="fa fa-info-circle tw-ml-1.5 tw-mr-1.5" aria-hidden="true" />
        </Tooltip>
        <CopyToClipboardButton
          text={this.props.accessCode}
          successMessage="Account Code copied to clipboard"
          errorMessage="Failed to copy Account Code to clipboard"
        />
      </div>
    );
  }

  _addResources(newResources) {
    this.setState(prevState => ({
      doneLoading: !prevState.filterSent && newResources.length < prevState.pageSize,
      filterSent: false,
      pagesLoaded: prevState.pagesLoaded + 1,
      resources: [...prevState.resources].concat(newResources)
    }));
  }

  _resetResources() {
    this.setState({
      doneLoading: false,
      pagesLoaded: 0,
      resources: []
    });
  }

  setBulkState() {
    this.setState(prevState => ({
      bulkChecked: this._isCurrentPageAllChecked(prevState.selectedStaffIds)
    }));
  }

  onTableDraw() {
    // updates bulk checkbox upon table re-draw
    const currentSelectedIds = this.state.selectedStaffIds;
    this.setState({
      bulkChecked: this._isCurrentPageAllChecked(currentSelectedIds)
    });
  }

  render() {
    return (
      <PathContext.Provider value={this.props.paths}>
        <div>
          {this._renderFilter()}
          {this._renderAccessCode()}
          <div className={styles.flexCenter}>
            <div className={styles.flexCenter}>
              {this._renderAddStaff()}
              {this._renderBulkAction()}
            </div>

            {this._renderExcludeFromRosteringLabel()}
          </div>

          <PaginatedTable
            doneLoadingCallback={this.setBulkState}
            addResources={this._addResources}
            columns={this.props.columns}
            resources={this.state.resources}
            ref={(ref) => { this.tableRef = ref; }}
            columnMapping={StaffTable.getColumnMapping()}
            createdRow={(row, data) => {
              if (data.exclude_from_rostering) {
                row.classList.add(styles.redRow);
                this.setState({ showExcludeLabel: true });
              }
            }}
            exportedColumns={[1, 2, 3, 4, 11, 5, 6, 7, 8, 10]}
            defaultOrder={[[1, 'asc'], [2, 'asc'], [3, 'asc']]}
            columnDefs={this._getColumnDefs()}
            getUrl={this.state.getPath}
            pageSize={this.state.pageSize}
            pagesLoaded={this.state.pagesLoaded}
            doneLoading={this.state.doneLoading}
            showSpinner={this.state.showSpinner}
            resetResources={this._resetResources}
            onDraw={this.onTableDraw}
          />

          {this._renderBulkCheckbox()}
          {this._renderDeleteModal()}
          {this._renderRemoveProgramsModal()}
          {this._renderEditModal()}
          {this._renderRosterExclusionModal()}
          {this._renderDeleteStafferModal()}
          {this.state.lockTeacherModalOpen && (
            <LockTeacherModal
              action={this.state.lockAction}
              modalIsOpen={this.state.lockTeacherModalOpen}
              closeModal={() => this.closeModal('lockTeacher')}
              stafferIds={this.state.selectedStaffIds}
              subscriberId={this.props.subscriberId}
              updateTable={this.updateTable}
            />
          )}
        </div>
      </PathContext.Provider>
    );
  }
}
