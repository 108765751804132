import { ICondition, ISlide } from '@/components/admin/SlideShow/stores/useSlideShowStore';

export const CONDITIONS = {
  QuestionsCompletedFromSlides: 'questions_answered',
  QuestionsCompletedFromThisSlide: 'questions_answered_current_slide',
};

export const ACTIONS = {
  UnlockNavigation: 'unlock_navigation',
  UnlockNextSlide: 'unlock_next_slide',
};

export const canSlideApplySelfRule = (slide: ISlide) => (
  slide.slideObjects.some(so => so.answerableQuestionIds.length > 0)
);

export const isConditionSelfRule = (condition: ICondition) => (
  [CONDITIONS.QuestionsCompletedFromThisSlide].includes(condition.condition)
);

export const isSlideSelectable = (slide: ISlide, other: ISlide, condition?: ICondition) => {
  if (condition && isConditionSelfRule(condition)) return canSlideApplySelfRule(slide);

  return slide.position > other.position;
};

export const selectableSlides = (slide: ISlide, slides: ISlide[]) => (
  slides.filter(other => isSlideSelectable(slide, other))
);
