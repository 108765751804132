import React, { useMemo, useState } from 'react';
import Axios from 'axios';
import Select from 'react-select-5';
import StatusIcon from './StatusIcon';

interface SelectProps {
  label: string;
  model: string;
  name: string;
  ajaxPath: string;
  initialValue: string;
  options: { [key: string]: string | number };
}

type OptionType = {
  label: string;
  value: string | number;
};

const AutoSaveSelect = ({ label, model, name, ajaxPath, initialValue, options }: SelectProps) => {
  const [value, setValue] = useState<OptionType>({ label: initialValue, value: options[initialValue] });
  const [loading, setLoading] = useState<boolean>(null);
  const [error, setError] = useState<Error>(null);

  const inputId = useMemo<string>(() => `${model}-${name}`, [model, name]);
  const values = useMemo<OptionType[]>(() => (
    Object.keys(options).map(key => ({ label: key, value: options[key] }))
  ), [options]);

  const handleSelect = (option: OptionType) => {
    setValue(option);
    setLoading(true);

    Axios.patch(ajaxPath, { [model]: { [name]: option.value } })
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <div className="tw-flex tw-items-center">
      <label htmlFor={inputId}>
        <p className="mt15">{label}</p>
      </label>
      <Select
        className="tw-w-32 tw-ml-5"
        classNamePrefix="Select"
        inputId={inputId}
        options={values}
        onChange={handleSelect}
        value={value}
        styles={{ menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 }) }}
      />
      <StatusIcon loading={loading} error={error} />
    </div>
  );
};

export default AutoSaveSelect;
