import React, { ReactComponentElement } from 'react';
import Tooltip from '@/components/common/Tooltip';
import styles from './Form.module.scss';

interface DescriptionConfidenceFieldProps {
  disabled: boolean;
  field: ReactComponentElement<any>;
  handleClick: () => void;
}

const DescriptionConfidenceField = ({ disabled, field, handleClick }: DescriptionConfidenceFieldProps) => {
  const tooltipText = 'Score between 0 and 1 representing the likelihood that the generated description is correct.';

  return (
    <div className="tw-flex tw-ml-[130px] tw-items-center">
      <button
        type="button"
        className={`btn btn--sm btn--purple ${styles.floatRight}`}
        onClick={handleClick}
        disabled={disabled}
      >
        Generate Alt Text
      </button>

      {field}

      <Tooltip
        content={tooltipText}
        theme="white"
        size="medium"
      >
        <span>
          <i className="fa fa-question-circle" aria-hidden />
          <span className="sr-only">
            {tooltipText}
          </span>
        </span>
      </Tooltip>
    </div>
  );
};

export default DescriptionConfidenceField;
