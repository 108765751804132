import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, useQuery } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import * as Routes from 'routes';
import Spinner from 'common/Spinner';
import useAssignablesStore from '@/components/staffer/AssignmentModal/WhatSection/store/useAssignablesStore';
import Modal from '../../common/Modal/index';
import { presenceOr } from '../../common/Utils';
import AssignmentForm from './AssignmentForm';
import { assignmentInitialValuesPropType, assessmentsPropType } from './PropTypes';
import styles from './AssignmentModal.module.scss';
import { GET_CLASSROOM_DATA } from './Utils';

const AsyncAssignmentModalWrapper = ({ ...props }) => (
  <ApolloProvider client={apolloClient}>
    <AsyncAssignmentModal {...props} />
  </ApolloProvider>
);

export const AsyncAssignmentModal = ({ ...props }) => {
  if (!props.modalIsOpen) return null;

  const { loading, data } = useQuery(
    GET_CLASSROOM_DATA,
    { variables: { programId: (props.programId || props.program.id), stafferId: props.stafferId } }
  );

  const modalHeaderText = props.newRecord ? 'Create Assignment' : 'Edit Assignment';
  const score = useAssignablesStore(state => state.score);

  const assessmentsPagePath = Routes.staffer_lesson_assessments_path({
    lesson_id: props.initialLessonId,
    program_id: props.programId || props.program.id
  });

  const formatAssessments = () => {
    const assessments = props.assessments || data?.staffer?.assessments || [];

    return assessments.map(assessment => {
      const id = parseInt(assessment.id, 10);
      return {
        ...assessment,
        id,
        label: assessment.name,
        value: `Assessment-${id}`,
        lesson_id: assessment.lessonId,
      };
    });
  };

  const programForAssignmentForm = () => {
    const program = props.program || { id: props.programId };
    return {
      ...program,
      display_investigations: presenceOr(program.display_investigations, program.displayInvestigations),
      display_investigations_text_section: presenceOr(
        program.display_investigations_text_section, program.displayInvestigationsTextSection),
      display_unit_content: presenceOr(program.display_unit_content, program.displayUnitContent),
      middle_school: presenceOr(program.middle_school, program.middleSchool),
      id: parseInt(program.id, 10)
    };
  };

  const closeModal = () => {
    useAssignablesStore.getState().resetState();
    props.closeModal();
  };

  const assignmentForm = () => {

    return (
      <AssignmentForm
        assessments={formatAssessments()}
        assessmentsPagePath={assessmentsPagePath}
        assignmentsPath={Routes.plato_api_assignments_path()}
        classrooms={data.staffer.classrooms}
        closeModal={closeModal}
        connectedToCanvas={data.staffer.connectedToCanvas}
        connectedToCanvas13={data.staffer.connectedToCanvas13}
        connectedToGoogleClassroom={data.staffer.connectedToGoogleClassroom}
        handlePostSave={props.handlePostSave}
        initialLessonId={props.initialLessonId}
        initialValues={props.initialValues}
        newRecord={props.newRecord}
        program={programForAssignmentForm()}
        score={score}
        stafferId={props.stafferId}
        subscriberConnectedToCanvas={data.staffer.subscriberConnectedToCanvas}
      />
    );
  };

  const spinner = <Spinner className={styles.spinner} isLoading={loading} />;

  return (
    <Modal
      bodyClassName={styles.modalPopup}
      className={styles.modalPopup}
      closeModal={closeModal}
      headerText={modalHeaderText}
      isOpen={props.modalIsOpen}
    >
      {loading ? spinner : assignmentForm()}
    </Modal>
  );
};

AsyncAssignmentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handlePostSave: PropTypes.func.isRequired,
  initialLessonId: PropTypes.number,
  initialValues: assignmentInitialValuesPropType,
  modalIsOpen: PropTypes.bool.isRequired,
  newRecord: PropTypes.bool,
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  programId: PropTypes.number,
  stafferId: PropTypes.number,
  assessments: assessmentsPropType,
  updatePath: PropTypes.string
};

AsyncAssignmentModal.defaultProps = {
  initialLessonId: null,
  initialValues: {},
  newRecord: true,
  stafferId: null,
  updatePath: ''
};

export default AsyncAssignmentModalWrapper;
