import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './Dropdown.module.scss';
import { Spinner } from '../../common/Modal';

export default class Dropdown extends Component {
  state = { slugs: {} };

  static propTypes = {
    error: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }))
  };

  static defaultProps = {
    messages: []
  };

  static formatDate(date) {
    return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY');
  }

  static renderError() {
    return (
      <div
        className={styles.message}
      >
        <p>Sorry, there was an issue loading the articles. See What&apos;s New in our Help Center.</p>
        <a
          className={`btn btn--outline-purple btn--sm ${styles.helpCenterButton}`}
          href="http://www.teachtci.com/whats-new"
          target="_blank"
          rel="noreferrer noopener"
        >
          Visit Help Center
        </a>
      </div>
    );
  }

  componentDidMount() {
    const slugsDiv = document.querySelector('#slug-attributes');

    if (!slugsDiv) return;

    this.setState({
      slugs: {
        lessonId: slugsDiv.getAttribute('data-lesson-id'),
        programId: slugsDiv.getAttribute('data-program-id'),
        unitId: slugsDiv.getAttribute('data-unit-id'),
      },
    });
  }

  renderMessage(message) {
    return (
      <div
        className={styles.message}
        key={`header-notification-${message.id}`}
      >
        <p>{Dropdown.formatDate(message.created_at)}</p>
        <p dangerouslySetInnerHTML={{ __html: this.parseContent(message.content) }} />
      </div>
    );
  }

  parseContent(message) {
    const { slugs: { lessonId, programId, unitId } } = this.state;

    if (programId) {
      message = message.replace('%3Aprogram_id', programId).replace(':program_id', programId);
    }

    if (lessonId) {
      message = message.replace('%3Alesson_id', lessonId).replace(':lesson_id', lessonId);
    }

    if (unitId) {
      message = message.replace('%3Aunit_id', unitId).replace(':unit_id', unitId);
    }

    return message;
  }

  renderMessages() {
    return (
      <div className={`${styles.messagesContainer} ${this.props.isLoading ? styles.loading : ''}`}>
        <Spinner isLoading={this.props.isLoading} />
        {this.props.error && Dropdown.renderError()}
        {this.props.messages.map(message => (this.renderMessage(message)))}
      </div>
    );
  }

  render() {
    return (
      <div
        className={styles.dropdown}
      >
        <div className={styles.header}>
          What&apos;s New
        </div>

        {this.renderMessages()}
      </div>
    );
  }
}
