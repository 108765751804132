import React from 'react';
import RemoveImage from 'components/admin/ImageManager/Remove';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { slidePropTypes } from '../../Utils';
import styles from './BackgroundDropdownActions.module.scss';

interface RemoveBackgroundImageProps {
  modalManager: ModalManager;
  slide: slidePropTypes.isRequired,
}

const RemoveBackgroundImage = ({ slide, modalManager }: RemoveBackgroundImageProps) => {
  const updateSlide = useSlideShowContext(state => state.updateSlide);

  return (
    <>
      <button type="button" className={`${styles.dropdownItem} dropdown-item`} onClick={modalManager.open}>
        Remove Background Image
      </button>
      <RemoveImage
        afterSubmit={() => {
          updateSlide(slide.id, { imageModel: undefined });
        }}
        closeButtonClassName="tw-text-white"
        headerClass="tw-bg-red"
        headerText={<span className="tw-text-white">Remove Background Image</span>}
        imageModelId={slide.imageModel.id}
        modalManager={modalManager}
        modalText="Are you sure you want to remove the background image for this slide?"
        removeButtonText="Yes, Remove"
      />
    </>
  );
};

export default RemoveBackgroundImage;
