import create from 'zustand';

interface IEditImageMarginStore {
  element: HTMLElement;
  setElement: (element: HTMLElement) => void;
}

const newStore = () => (
  create<IEditImageMarginStore>(set => ({
    element: null,
    setElement: element => set(prev => ({ ...prev, element })),
  }))
);

const useEditImageMarginsStore = create<IEditImageMarginStore>(set => ({
  element: null,
  setElement: element => set(prev => ({ ...prev, element })),
}));

type IEditImageMarginsStore = {
  stores: { [key: string]: ReturnType<typeof newStore> };
  get: (selector: string) => ReturnType<typeof newStore>;
};

export const EditImageMarginsStore: IEditImageMarginsStore = {
  stores: {},
  get: (selector: string) => {
    if (EditImageMarginsStore.stores[selector]) return EditImageMarginsStore.stores[selector];

    EditImageMarginsStore.stores[selector] = newStore();

    return EditImageMarginsStore.stores[selector];
  },
};

export default useEditImageMarginsStore;
