import React, { useRef } from 'react';
import Anchor from '@/components/ui/Anchor';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import Form from './Form';

interface PopoverProps {
  editorInstance: any;
  setAnchorRef: any;
  locale: Locale;
}

const Popover = ({ editorInstance, setAnchorRef, locale }: PopoverProps) => {
  const menuRef = useRef(null);

  // const updateEditor: WrapperFunction = callback => (
  //   (...args) => {
  //     callback(...args);
  //     editorInstance.events.trigger('contentChanged');
  //   }
  // );
  //
  // const refreshEditor = () => {
  //   editorInstance.html.set(editorInstance.html.get());
  //   editorInstance.selection.restore();
  //   editorInstance.events.trigger('contentChanged');
  // };

  if (!editorInstance) return null;
  return (
    <QueryClientProvider client={queryClient}>
      <Anchor
        attachListenersTo={[document.querySelector('.fr-wrapper')]}
        className="fr-popup fr-desktop"
        defaultOpen={false}
        offset={{ top: -40 }}
        to={document.getElementById(`GlossaryTerms-${editorInstance.id}`)}
        onClose={() => {
          editorInstance.markers.remove();
        }}
        ref={setAnchorRef}
      >
        <div ref={menuRef} className="fr-buttons">
          <Form editorInstance={editorInstance} locale={locale} />
        </div>
      </Anchor>
    </QueryClientProvider>
  );
};

export default Popover;
