import React, { useState } from 'react';
import I18n from 'modules/i18n';
import Modal from 'components/common/Modal';
import ButtonWithTooltip from './ButtonWithTooltip';
import Form from './Form';

type SlideShow = {
  activityType?: string;
  assignable?: boolean;
  className?: string;
  id: number;
  isAssigned: boolean;
  is_module: boolean;
  lesson?: {
    id: number;
  },
  links: {
    deletePath?: string;
    editPath?: string;
    presentPath?: string;
    printUrl?: string;
    sharePath?: string;
    unfilteredPresentPath?: string;
    unsharePath?: string;
  };
  pacing?: number;
  program?: object;
  shareable?: boolean,
  shared?: boolean,
  slideShowId?: number,
  stafferId?: number,
  title?: string,
  useNewBuilder?: boolean,
};

interface ButtonProps {
  slideShow: SlideShow;
}

interface EditButtonProps extends ButtonProps {
  useNewBuilder?: boolean;
  lessonId?: string;
  programId?: string;
  fromSlideShow?: boolean;
  hideTabs?: boolean;
}

export const PresentButton = ({ slideShow }: ButtonProps) => {
  const querySeparator = slideShow.links.unfilteredPresentPath?.includes('?') ? '&' : '?';

  return (
    <ButtonWithTooltip
      ariaLabel={`${I18n.t('present')} ${slideShow.title}`}
      link={`${slideShow.links.unfilteredPresentPath}${querySeparator}from=Lesson`}
      openInNewWindow
      tooltipContent={I18n.t('present')}
      iconClassName="fa fa-play"
    />
  );
};

export const EditExistingSlideShowButton = ({ slideShow }: EditButtonProps) => {
  return (
    <ButtonWithTooltip
      ariaLabel={`${I18n.t('edit')} ${slideShow.title}`}
      link={`${slideShow.links.editPath}${slideShow.useNewBuilder ? '?new_builder=true' : ''}`}
      tooltipContent={I18n.t('edit/preview')}
      iconClassName="fa fa-edit"
    />
  );
};

export const EditNewSlideShowButton = (
  { hideTabs, useNewBuilder, lessonId, programId, slideShow, fromSlideShow }: EditButtonProps,
) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (useNewBuilder) event.preventDefault();
    if (!useNewBuilder) return;

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <ButtonWithTooltip
        ariaLabel={`${I18n.t('edit')} ${slideShow.title}`}
        link={`${slideShow.links.editPath}${slideShow.useNewBuilder ? '?new_builder=true' : ''}`}
        onClick={openModal}
        tooltipContent={I18n.t('edit/preview')}
        iconClassName="fa fa-edit"
      />
      <Modal
        closeModal={closeModal}
        isOpen={modalIsOpen}
        headerText="Save New Presentation"
      >
        <Form
          button="edit"
          id={slideShow.id}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          type={slideShow.className}
          useNewBuilder={slideShow.useNewBuilder}
          programId={programId}
          lessonId={lessonId}
          fromSlideShow={fromSlideShow}
          hideTabs={hideTabs}
        />
      </Modal>
    </>
  );
};

// print icon for only TCI-created slide shows w/ guides
export const PrintButton = ({ slideShow }: ButtonProps) => {
  const link = slideShow.links.printUrl;
  if (!link) return null;

  return (
    <ButtonWithTooltip
      ariaLabel={`${I18n.t('print')} ${slideShow.title}`}
      link={slideShow.links.printUrl}
      openInNewWindow
      tooltipContent={I18n.t('print_guide')}
      iconClassName="fa fa-print"
    />
  );
};

// delete icon for only teacher slide shows
export const DeleteButton = ({ slideShow }: ButtonProps) => {
  const link = slideShow.links.deletePath;
  if (!link) return null;

  return (
    <ButtonWithTooltip
      ariaLabel={`${I18n.t('delete')} ${slideShow.title}`}
      link={slideShow.links.deletePath}
      dataConfirm="Are you sure you want to delete this lesson guide?"
      rel="nofollow"
      dataMethod="delete"
      tooltipContent={I18n.t('delete')}
      iconClassName="fa fa-trash"
    />
  );
};
