import React, { useState } from 'react';
import { t } from 'i18n-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Sidebar, { SidebarHeadWithButton } from '@/components/ui/Sidebar';
import useProfessionalDevelopmentData, {
  IApiSlideShow,
} from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';
import UnitSection from '@/components/common/ProfessionalDevelopment/UnitSection';
import PDFilters from '@/components/common/ProfessionalDevelopment/PDFilters';
import useFilters from '@/hooks/useFilters';

interface ProfessionalDevelopmentProps {
  pdProgramId: number;
  programId: number;
  userId: number;
}

const queryClient = new QueryClient();

const ProfessionalDevelopment = ({ pdProgramId, programId, userId }: ProfessionalDevelopmentProps) => {
  const { data: units } = useProfessionalDevelopmentData(programId);
  const [selectedSlideShows, setSelectedSlideShows] = useState<IApiSlideShow[]>([]);
  const [statuses, setStatuses] = useState<(IApiSlideShow['status'])[]>([]);

  const slideShowIds = selectedSlideShows.map(ss => ss.id);

  const { data: slideShows } = useFilters(units.map(unit => unit.slide_shows).flat(), {
    in_slide_show: {
      getValue: datum => slideShowIds.includes(datum.id),
      setActiveIf: slideShowIds.length > 0,
    },
    status: {
      getValue: datum => statuses.includes(datum.status),
      setActiveIf: statuses.length > 0,
    },
  });

  const filteredData = units.map((unit) => {
    const unitSlideShows = slideShows.filter(ss => ss.unit_id === unit.id);
    return { ...unit, slide_shows: unitSlideShows };
  });

  return (
    <div className="tw-mt-8 tw-mb-4 tw-flex">
      <Sidebar isFloating>
        <SidebarHeadWithButton
          buttonText={t('clear')}
          headerText={t('filters')}
          onClick={() => setSelectedSlideShows([])}
        />
        <PDFilters
          units={units}
          slideShowIds={slideShowIds}
          setSelectedSlideShows={setSelectedSlideShows}
          statuses={statuses}
          setStatuses={setStatuses}
        />
      </Sidebar>
      <div className="tw-flex tw-flex-wrap tw-gap-8 tw-flex-col tw-flex-grow">
        <UnitSection data={filteredData} pdProgramId={pdProgramId} programId={programId} userId={userId} />
      </div>
    </div>
  );
};

const ProfessionalDevelopmentWrapper = (props: ProfessionalDevelopmentProps) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ProfessionalDevelopment {...props} />
  </QueryClientProvider>
);

export default ProfessionalDevelopmentWrapper;
