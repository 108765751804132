import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import styles from './ButtonWithTooltip.module.scss';

function ButtonWithTooltip(props) {
  const linkProps = {
    'aria-label': props.ariaLabel,
    'data-method': props.dataMethod,
    'data-confirm': props.dataConfirm,
    className: `${props.className} ${props.isDisabled ? styles.disabled : null} ${styles.iconLink}`,
    onClick: props.onClick,
    rel: props.rel,
  };

  const hrefProps = props.shouldNavigate ? {
    href: props.isDisabled ? null : props.link,
    target: props.openInNewWindow ? '_blank' : null
  } : { type: 'button' };

  const TagName = props.shouldNavigate ? 'a' : 'button';

  return (
    <TagName
      {...linkProps}
      {...hrefProps}
    >
      <Tooltip content={props.tooltipContent}>
        <i
          className={`
            ${props.iconClassName}
            ${styles.icon}
            ${props.inverted ? styles.inverted : null}
            ${props.isDisabled ? styles.disabled : null}
          `}
        />
      </Tooltip>
    </TagName>
  );
}

ButtonWithTooltip.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataConfirm: PropTypes.string,
  dataMethod: PropTypes.string,
  iconClassName: PropTypes.string,
  inverted: PropTypes.bool,
  isDisabled: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  openInNewWindow: PropTypes.bool,
  rel: PropTypes.string,
  tooltipContent: PropTypes.string.isRequired,
};

ButtonWithTooltip.defaultProps = {
  className: '',
  dataConfirm: null,
  dataMethod: null,
  iconClassName: '',
  inverted: false,
  isDisabled: false,
  link: '#',
  onClick: null,
  openInNewWindow: false,
  shouldNavigate: true,
  rel: null,
};

export default ButtonWithTooltip;
