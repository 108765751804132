import { useEffect, useMemo, useState } from 'react';
import { areSetsEqual } from '@/modules/Utils';
import ILicense from '@/components/interfaces/License';
import useProgramAccessStore from '../ProgramAccessTable/useProgramAccessStore';

interface UseManageProgramsCheckboxes {
  selectedLicense: ILicense | undefined;
}

const useManageProgramsCheckboxes = ({ selectedLicense }: UseManageProgramsCheckboxes) => {
  const [initialActiveProgramIds, setInitialActiveProgramIds] = useState<Set<number>>(new Set());

  const setStore = useProgramAccessStore(state => state.setStore);
  const selectedPrograms = useProgramAccessStore(state => state.selectedPrograms);
  const seatPools = useProgramAccessStore(state => state.seatPools);

  const pristine = useMemo<boolean>(() => (
    areSetsEqual(selectedPrograms, initialActiveProgramIds)
  ), [selectedPrograms, initialActiveProgramIds]);

  useEffect(() => {
    if (!selectedLicense) return;

    const currentActiveProgramIds = seatPools
      .filter((pool) => {
        if (pool.license_id !== selectedLicense.id) return false;

        return pool.program.licenses.map(l => l.id).includes(selectedLicense.id);
      })
      .map(pool => pool.program_id);

    setInitialActiveProgramIds(new Set([...currentActiveProgramIds]));
    setStore({ selectedPrograms: new Set([...currentActiveProgramIds]) });
  }, [selectedLicense, seatPools.length]);

  return { pristine };
};

export default useManageProgramsCheckboxes;
