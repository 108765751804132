import React from 'react';
import Tooltip from 'common/Tooltip';
import PropTypes from 'prop-types';
import googleClassroomCheckIcon from 'images/icons/google_class_connected.png';
import canvasIcon from 'images/icons/canvas-connected.png';
import schoologyIcon from 'images/icons/schoology-connected.svg';
import styles from './ConnectedTooltip.module.scss';

const imageProps = {
  Google: {
    src: googleClassroomCheckIcon,
    alt: 'google classroom'
  },
  Canvas: {
    src: canvasIcon,
    alt: 'canvas classroom'
  },
  Schoology: {
    src: schoologyIcon,
    alt: 'schoology classroom'
  }
};

const ConnectedTooltip = ({ studentName, connectionType }) => (
  <Tooltip
    content={`${studentName} is connected to ${connectionType}.`}
    theme="white"
    size="medium"
  >
    <img
      className={styles.icon}
      src={imageProps[connectionType].src}
      alt={`connected to ${imageProps[connectionType].alt}`}
    />
  </Tooltip>
);

ConnectedTooltip.defaultProps = {
  connectionType: 'Google'
};

ConnectedTooltip.propTypes = {
  studentName: PropTypes.string.isRequired,
  connectionType: PropTypes.oneOf(['Google', 'Canvas', 'Schoology'])
};

export default ConnectedTooltip;
