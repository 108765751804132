import SamlSettingSteps from './Steps/Configuration/SamlSteps';
import LtiKeySteps from './Steps/Configuration/LtiSteps';
import CleverStep from './Steps/Configuration/CleverStep';

const wizards = {
  LtiKey: LtiKeySteps,
  SamlSetting: SamlSettingSteps,
  Clever: CleverStep
};

export default wizards;
