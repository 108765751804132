import { Form, Field } from 'react-final-form';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

import AutoSave from 'common/Forms/AutoSave';
import * as Routes from 'routes';
import TextArea from 'common/Forms/TextArea';
import { formatLocalTimestamp } from 'common/Utils';

import styles from './QuestionAnswerComment.module.scss';
import SaveStatus from '../SaveStatus';
import AddVirtualStampButton from '../VirtualStamps';
import VirtualStamp from '../VirtualStamps/VirtualStamp';

export default class QuestionAnswerComment extends Component {
  static propTypes = {
    commentingTeacher: PropTypes.string.isRequired,
    exerciseId: PropTypes.number.isRequired,
    isTeacher: PropTypes.bool.isRequired,
    lastUpdated: PropTypes.string,
    programId: PropTypes.number.isRequired,
    questionAnswerId: PropTypes.number.isRequired,
    teacherComment: PropTypes.string,
    teacherStamp: PropTypes.string,
    textAreaId: PropTypes.string.isRequired
  };

  static defaultProps = {
    lastUpdated: null,
    teacherComment: null,
    teacherStamp: null
  };

  constructor(props) {
    super(props);

    this.state = {
      saveError: false,
      savedTime: null,
      saving: false,
      selectedStamp: this.props.teacherStamp
    };
    this.textAreaRef = React.createRef();

    this._showTextArea = this._showTextArea.bind(this);
    this._setFocus = this._setFocus.bind(this);
    this.setSelectedStamp = this.setSelectedStamp.bind(this);

    this.container = document.querySelector(`#section-${this.props.exerciseId}-container`);
  }

  componentDidMount() {
    if (!this.container) return;

    this.observer = new MutationObserver(() => this.forceUpdate());
    this.observer.observe(this.container, { attributeFilter: ['style'], attributes: true });
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  processData(values) {
    const data = { teacher_stamp: this.state.selectedStamp };

    if (values) {
      Object.assign(data, values);
    }

    return { question_answer: data };
  }

  save = async (values) => {
    this.setState({ saveError: false, saving: true });

    const path = Routes.comment_shared_question_answer_path(this.props.questionAnswerId, { program_id: this.props.programId, format: 'json' });
    await Axios
      .put(path, this.processData(values))
      .then(() => {
        const savedTime = formatLocalTimestamp(new Date(), true);
        this.setState({ savedTime, saving: false });
      })
      .catch(() => {
        this.setState({ saveError: true, saving: false });
      });
  };

  setSelectedStamp(stamp) {
    this.setState({ selectedStamp: stamp }, this.save);
  }

  _renderCommentForm() {
    return (
      <Form
        keepDirtyOnReinitialize
        initialValues={{ teacher_comment: this.props.teacherComment }}
        onSubmit={this.save}
        render={() => (
          <div>
            <AutoSave allowBlanks save={this.save} />

            <div className={styles.commentFields}>
              <AddVirtualStampButton
                selectedStamp={this.state.selectedStamp}
                setSelectedStamp={this.setSelectedStamp}
              />

              <Field
                name="teacher_comment"
                type="text"
                placeholder="Type here to comment..."
                className={`${styles.newTextarea} resizable ${this.state.selectedStamp ? styles.textareaWithStamp : ''}`}
              >
                {(props) => {
                  if (this.container && !this.container.offsetParent) return null;

                  return <TextArea id={this.props.textAreaId} {...props} ref={this.textAreaRef} />;
                }}
              </Field>
            </div>
          </div>
        )}
      />
    );
  }

  _showTextArea() {
    this.textAreaRef.current = document.getElementById(this.props.textAreaId);
    this.setState({ showTextArea: true }, this._setFocus);
  }

  _setFocus() {
    this.textAreaRef.current.focus();
  }

  renderCommentBox() {
    return (
      <div>
        {this._renderCommentForm()}

        <span>
          <SaveStatus
            savedTime={this.state.savedTime}
            saving={this.state.saving}
            saveErrorStatus={this.state.saveErrorStatus}
            networkError={this.state.networkError}
            inputError={false}
            lastUpdated={formatLocalTimestamp(this.props.lastUpdated, false)}
            saveError={this.state.saveError}
          />
        </span>
      </div>
    );
  }

  renderStudentView() {
    if (this.props.teacherComment  || this.props.teacherStamp) {
      return (
        <div className={styles.studentView}>
          {this.renderStudentViewStamp()}
          {this.renderStudentViewComment()}
        </div>
      );
    }

    return null;
  }

  renderStudentViewComment() {
    return (
      <div className={styles.commentContainer}>
        <div>{this.props.teacherComment}</div>
        <div className={styles.teacherName}>
          - {this.props.commentingTeacher}
        </div>
      </div>
    );
  }

  renderStudentViewStamp() {
    if (this.props.teacherStamp == null) return null;

    return (
      <VirtualStamp stampName={this.props.teacherStamp} />
    );
  }

  render() {
    if (!this.props.isTeacher) {
      return this.renderStudentView();
    }

    if (this.props.teacherComment == null &&
      this.props.teacherStamp == null &&
      !this.state.showTextArea) {
      return (
        <button className={styles.addCommentButton} type="button" onClick={this._showTextArea}>
          <i className="fa fa-plus text--green" aria-hidden="true" /> Add Comment/Stamp
        </button>
      );
    }

    return this.renderCommentBox();
  }
}
