import React from 'react';
import PropTypes from 'prop-types';
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player';
import styles from './AudioPlayer.module.scss';

const AudioPlayerWrapper = ({ src }) => (
  <AudioPlayerProvider>
    <AudioPlayer src={src} />
  </AudioPlayerProvider>
);

AudioPlayerWrapper.propTypes = {
  src: PropTypes.string.isRequired
};

const AudioPlayer = ({ src }) => {
  const { togglePlayPause, playing } = useAudioPlayer({ format: 'mp3', src: src });

  const action = playing ? 'pause' : 'play';

  return (
    <button
      type="button"
      className={`btn ${playing ? 'btn--purple' : `${styles.whiteBg} btn--outline-purple`} btn--md ${styles.player}`}
      onClick={togglePlayPause}
      aria-label={`${action} audio`}
    >
      <i className={`fa fa-${action}`} aria-hidden />
    </button>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired
};

export default AudioPlayerWrapper;
