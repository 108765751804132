/* istanbul ignore file */
/* eslint-disable */
import React from 'react';

const GeographyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08992 0.816895C5.7112 0.816895 3.7832 2.75289 3.7832 5.14121C3.7832 5.98713 4.02528 6.77625 4.44336 7.44265L7.696 14.8855C7.91264 15.2875 8.26736 15.2875 8.48368 14.8855L11.7362 7.44265C12.1544 6.77609 12.3968 5.98713 12.3968 5.14121C12.3968 2.75289 10.4688 0.816895 8.08992 0.816895ZM8.08992 7.46073C6.8592 7.46073 5.86112 6.46281 5.86112 5.23209C5.86112 4.00121 6.85888 3.00361 8.08992 3.00361C9.32048 3.00361 10.3186 4.00121 10.3186 5.23209C10.3186 6.46281 9.3208 7.46073 8.08992 7.46073Z"
      fill="currentColor"
    />
  </svg>
);

export default GeographyIcon;
