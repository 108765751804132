import React from 'react';
import moment from 'moment';
import { formatLocalTimestampWithFormat } from '@/components/common/Utils';
import I18n from 'i18n-js';

interface ISaveStatusProps {
  dbTimestamp?: string;
  isSaving?: boolean;
}
const SaveStatus = ({ dbTimestamp, isSaving = false }: ISaveStatusProps) => {
  if (!dbTimestamp) return null;

  const momentTime = moment(dbTimestamp).local();

  const updatedMessage = `${I18n.t('saved_on')} ${formatLocalTimestampWithFormat(momentTime, 'MMMM DD, YYYY hh:mm A')}`;

  const content = isSaving ? `${I18n.t('saving')}...` : updatedMessage;

  return <p className="tw-inline-block">{content}</p>;
};

export default SaveStatus;
