import React from 'react';
import PropTypes from 'prop-types';
import showToast from 'common/Toast';
import Modal from 'common/Modal';
import logo from 'images/icons/google-classroom-icon.png';
import canvasLogo from 'images/icons/canvas-red-box.png';
import { openAuthPopup as openGoogleAuthPopup } from '../GoogleSettings/Utils';
import { openAuthPopup as openCanvasAuthPopup } from '../../shared/CanvasSettings/Utils';

const ModalHeader = ({ integrationType, isConnectedToGoogle }) => (
  <div className="dsp-flex-center">
    <img className="mr10" src={isConnectedToGoogle ? logo : canvasLogo} width="30px" alt="" />
    Please Reconnect or Disconnect your {integrationType} Account
  </div>
);

const ReconnectModal = ({ closeModal, setCurrentPane, integrationType }) => {
  const isConnectedToGoogle = () => integrationType === 'Google';

  const reconnectThenClose = () => {
    (isConnectedToGoogle() ? openGoogleAuthPopup : openCanvasAuthPopup)()
      .then(() => {
        closeModal();
        showToast(`${integrationType} account reconnected`);
      })
      .catch(() => showToast('Failed to connect', { msgType: 'error' }));
  };

  return (
    <Modal
      headerText={<ModalHeader integrationType={integrationType} isConnectedToGoogle={isConnectedToGoogle()} />}
      isOpen
      closeable={false}
    >
      <p>Your connection to {isConnectedToGoogle() ? 'Google Classroom' : 'Canvas'} has been lost.</p>

      <p>
        Please reconnect your {integrationType} account to continue syncing assignments and grades.
        If you’d like to stop using our {isConnectedToGoogle() ? 'Google Classroom' : 'Canvas'} integration,
        please disconnect your account.
      </p>

      <hr />

      <footer>
        <button type="button" className="btn btn--outline-red mr10" onClick={() => setCurrentPane('disconnect')}>
          <i className="fa fa-unlink" aria-hidden="true" /> Disconnect Account
        </button>
        <button type="button" className="btn btn--purple" onClick={reconnectThenClose}>
          Reconnect
        </button>
      </footer>
    </Modal>
  );
};

ModalHeader.propTypes = {
  integrationType: PropTypes.string.isRequired,
  isConnectedToGoogle: PropTypes.bool.isRequired
};


ReconnectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  integrationType: PropTypes.string.isRequired,
  setCurrentPane: PropTypes.func.isRequired
};

export default ReconnectModal;
