import React from 'react';
import PropTypes from 'prop-types';
import './froala.scss';
import I18n from 'i18n';
import Editor from './Editor';
import Present from './Present';
import { slideObjectPropTypes } from '../../../Utils';
import { getHTML } from '../../Utils';
import 'assets/stylesheets/pdfkit/slide_builder_modules/plus_button.scss';

const SlideObjectText = ({
  isEditable, interactable, jumpToSlide, slideObject, updateSlideObject, thumbnail, hasError,
  setHasError, setIsSaving, isSaving
}) => {
  if (interactable && isEditable) {
    return (
      <Editor
        isSaving={isSaving}
        key={slideObject.id}
        slideObject={slideObject}
        updateSlideObject={updateSlideObject}
        hasError={hasError}
        setHasError={setHasError}
        setIsSaving={setIsSaving}
      />
    );
  }

  return (
    <Present
      content={getHTML(I18n.locale || 'en', slideObject)}
      thumbnail={thumbnail}
      jumpToSlide={jumpToSlide}
      slideObject={slideObject}
    />
  );
};

SlideObjectText.propTypes = {
  hasError: PropTypes.bool,
  interactable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  jumpToSlide: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  thumbnail: PropTypes.bool.isRequired,
  updateSlideObject: PropTypes.func.isRequired,
};

SlideObjectText.defaultProps = {
  hasError: false,
};

export default SlideObjectText;
