import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import SubmitButton from '../../../common/Forms/SubmitButton';
import { snakeToTitle } from '../../../common/Utils';
import { renderErrors, SubmitError } from '../../../common/Forms/Utils';
import {
  required, minLength, composeValidators, validEmail
} from '../../../common/Forms/Validators';
import ContactAdminButton from '../../registrations/ContactAdminButton';
import showToast from '../../../common/Toast';
import TransferModal from '../../registrations/TransferModal';
import styles from './MyAccount.module.scss';
import MyAccountDropdown from './MyAccountDropdown';

export default class MyAccount extends Component {
  static propTypes = {
    active: PropTypes.bool,
    getAdminInfoPath: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      default_home: PropTypes.string,
      program_homepage: PropTypes.string
    }).isRequired,
    signUpCodesPath: PropTypes.string.isRequired,
    staffer: PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      from_auto_roster_district: PropTypes.bool.isRequired,
      last_name: PropTypes.string.isRequired,
      username: PropTypes.string
    }).isRequired,
    subscriberContactAdminMessage: PropTypes.string,
    subscriberName: PropTypes.string.isRequired,
    transferPath: PropTypes.string.isRequired,
    updatePath: PropTypes.string.isRequired,
    updateState: PropTypes.func,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    active: false,
    updateState: () => {}
  };

  static _renderFieldLabel(attribute) {
    const titleizedAttribute = snakeToTitle(attribute);

    return (
      <span>{titleizedAttribute}</span>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    };
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });

    const newValues = {
      ...values,
      default_home: values?.default_home?.value,
    };

    return Axios
      .put(this.props.updatePath, newValues)
      .then((response) => {
        showToast('Save successful');
        this.props.updateState({
          staffer: response.data.staffer,
          settings: response.data.data.settings
        });
        this.setState({ submitting: false });
      })
      .catch(error => this._handleSubmitError(error));
  };

  _handleSubmitError(error) {
    this.setState({ submitting: false });
    return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
  }

  _getInitialValues() {
    const {
      email, first_name, last_name, username
    } = this.props.staffer;

    const {
      default_home, program_homepage
    } = this.props.settings;

    return {
      default_home, email, first_name, last_name, program_homepage, username
    };
  }

  _renderField(attribute, validator) {
    if (this.props.staffer.from_auto_roster_district) {
      return (
        <div className={styles.row}>
          <span className={styles.label}>
            {MyAccount._renderFieldLabel(attribute)}
          </span>
          {this.props.staffer[attribute]}
        </div>
      );
    }

    return (
      <Field
        name={attribute}
        validate={validator}
      >
        {({ input, meta }) => (
          <div className={styles.row}>
            <label htmlFor={`staff-${attribute.replace(/_/g, '-')}`} className={styles.label}>
              {MyAccount._renderFieldLabel(attribute)}
            </label>

            <div>
              <input
                id={`staff-${attribute.replace(/_/g, '-')}`}
                className={styles.textInput}
                {...input}
              />

              {meta.error && meta.touched &&
              <div className={styles.fieldError}>{meta.error}</div>}
            </div>
          </div>
        )}
      </Field>
    );
  }

  _renderPasswordFields() {
    if (this.props.staffer.from_auto_roster_district) return null;

    return (
      <div>
        <Field
          name="password"
          validate={minLength(6)}
        >
          {({ input, meta }) => (
            <div className={styles.row}>
              <label htmlFor="staff-password" className={styles.label}>
                New Password
              </label>

              <div>
                <input
                  autoComplete="new-password"
                  id="staff-password"
                  className={styles.textInput}
                  type="password"
                  {...input}
                />

                {meta.error && meta.touched &&
                <div className={styles.fieldError}>{meta.error}</div>}
              </div>
            </div>
          )}
        </Field>

        <Field
          name="password_confirmation"
          validate={minLength(6)}
        >
          {({ input, meta }) => (
            <div className={styles.row}>
              <label htmlFor="staff-password-confirmation" className={styles.label}>
                Confirm New Password
              </label>

              <div>
                <input
                  autoComplete="new-password"
                  id="staff-password-confirmation"
                  className={styles.textInput}
                  type="password"
                  {...input}
                />

                {meta.error && meta.touched &&
                <div className={styles.fieldError}>{meta.error}</div>}
              </div>
            </div>
          )}
        </Field>
        <hr />
        <p className="mb20">
          <strong>Your current password is required for all changes to your account settings.</strong>
        </p>
        <Field
          name="current_password"
          validate={composeValidators(required, minLength(6))}
        >
          {({ input, meta }) => (
            <div className={styles.row}>
              <label htmlFor="staff-current-password" className={styles.label}>
                Current Password
              </label>

              <div>
                <input
                  autoComplete="new-password"
                  id="staff-current-password"
                  className={styles.textInput}
                  type="password"
                  {...input}
                />

                {meta.error && meta.touched &&
                <div className={styles.fieldError}>{meta.error}</div>}
              </div>
            </div>
          )}
        </Field>
      </div>
    );
  }

  _renderContactInfo() {
    if (!this.props.staffer.from_auto_roster_district) return null;

    return (
      <div className={`mb20 ${styles.info}`}>
        <span className={styles.content}>
          <i className={`fa fa-info-circle ${styles.icon}`} aria-hidden="true" />
          <p>
            Your district manages all account information.
            To request a change, please contact your district administrator.
          </p>
        </span>
        <div className={styles.buttonContainer}>
          <ContactAdminButton
            buttonClass="btn--outline-purple"
            getAdminInfoPath={this.props.getAdminInfoPath}
            subscriberContactAdminMessage={this.props.subscriberContactAdminMessage}
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.active) return null;

    return (
      <div>
        {this._renderContactInfo()}
        <Form
          initialValues={this._getInitialValues()}
          onSubmit={this.handleSubmit}
          render={({
            dirtySinceLastSubmit, handleSubmit, hasSubmitErrors,
            hasValidationErrors, pristine, submitError
          }) => (
            <form onSubmit={handleSubmit}>
              {this._renderField('first_name', required)}
              {this._renderField('last_name', required)}
              {this._renderField('email', composeValidators(required, validEmail))}
              {this._renderField('username')}
              <div className={styles.fieldAnnotation}>
                Username is optional. Students can use your email or username to sign in.
              </div>
              <div className={styles.row}>
                <span className={styles.label}>Role</span>
                <span>{this.props.userType}</span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>District Account</span>
                <TransferModal
                  signUpCodesPath={this.props.signUpCodesPath}
                  subscriberName={this.props.subscriberName}
                  transferPath={this.props.transferPath}
                />
              </div>
              {!this.props.staffer.from_auto_roster_district && <hr />}
              {this._renderPasswordFields()}
              <SubmitError error={submitError} />
              {(!this.props.staffer.from_auto_roster_district &&
                (this.props.userType === "Coordinator" || this.props.userType === "Admin")) && (
                <>
                  <hr />
                  <MyAccountDropdown
                    className={styles.row}
                    label="Default Landing Page"
                    name="default_home"
                    options={[
                      {label: 'Admin Dashboard', value: '0'},
                      {label: 'My Programs', value: '1'},
                    ]}
                  />
                  <hr />
                </>
              )}
              <div className={styles.actionContainer}>
                {
                  !this.props.staffer.from_auto_roster_district && (
                    <SubmitButton
                      submitButtonText="Update"
                      submitDisabled={
                        pristine || hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit)
                      }
                      submitting={this.state.submitting}
                    />
                  )
                }
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
