import React from 'react';
import axios from 'axios';
import { Position } from '@photo-sphere-viewer/core';
import { plato_api_panoramic_image_path } from '@/modules/routes';
import usePanoramicImage from '../../Content/SlideObjectPanoramicImage/usePanoramicImage';

interface PanoramicDefaultViewButtonProps {
  panoramicCamera?: Position;
  slideObjectId: string | number;
  onSuccess: () => void;
}

const PanoramicDefaultViewButton = ({ panoramicCamera, slideObjectId, onSuccess }: PanoramicDefaultViewButtonProps) => {
  const { data: panoramicImage } = usePanoramicImage(slideObjectId);

  const updateDefaultView = async () => {
    try {
      await axios.patch(
        plato_api_panoramic_image_path(panoramicImage.id),
        { panoramic_image: { default_view: panoramicCamera } },
      );

      onSuccess();
    } catch (e) {
      console.log(e);
    }
  };

  if (!panoramicImage) return null;

  return (
    <button
      className="dropdown-item tw-cursor-pointer"
      type="button"
      onClick={updateDefaultView}
    >
      Set as default view
    </button>
  );
};


export default PanoramicDefaultViewButton;
