import React, { Component } from 'react';
import { FormInput } from 'common/Forms/FormInput';
import FormCheckboxGroup from 'common/Forms/FormCheckboxGroup';
import { Footer, Spinner } from 'common/Modal';
import Axios from "axios/index";
import Select from 'react-select';
import Row from '../../common/Forms/Row';
import styles from './ToggleImage.module.scss';
import ImagesList from './ImagesList';
import ImageManager from 'common/ImageManager';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const zoomableOpts = [
      { checkValue: 't', checkText: '', checked: this.state.zoomable }
    ];

    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    )
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`${styles['toggle-image-form']} toggle-image-form`} >

        <FormInput label="title" stateName="title" value={this.state.title}
                   id={this.state.id} inputType="text" onChange={this.handleChange.bind(this)} />

        <Row>
          <label>Selection Type</label>
          <Select searchable={true}
                required={true}
                value={this.state.selectionType}
                options={this.props.selectionTypes}
                onChange={this.setSelectionType.bind(this)} />
        </Row>

        <FormCheckboxGroup stateName="zoomable" checkboxes={zoomableOpts} labelText="Zoomable by default" onChange={this.handleZoomableChange.bind(this)} />

        { this.renderImageLayers() }

        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  renderImageLayers() {
    if (!this.props.newRecord) {
      return (
        <div>
          <ImagesList id={this.state.id}
                      items={this.state.items}
                      onReorderItems={this.onReorderItems.bind(this)}
                      onRemoveItem={this.removeItem.bind(this)}
                      onUpdateItem={this.updateItem.bind(this)}
                      toggleEditingMode={this.toggleEditingMode.bind(this)}
          />

          <div className="block_link" onClick={this.toggleImageManager.bind(this)}>
            Add Layer
          </div>
          <ImageManager isOpen={this.state.imageManagerIsOpen}
                        onClose={this.toggleImageManager.bind(this)}
                        onAddImage={this.handleAddImage.bind(this)}
                        getExistingImagesUrl={this.props.getExistingImagesUrl} />
        </div>
      )
    }
  }


  componentDidMount() {
    this.setState({ isLoading: true });
    this.getImageLayers();
  }

  toggleImageManager() {
    this.setState({
      imageManagerIsOpen: !this.state.imageManagerIsOpen
    })
  }

  getImageLayers() {
    if (this.props.newRecord) {
      this.setState({ isLoading: false });
      return;
    }
    Axios.get(`/shared/toggle_images/${this.state.id}/get_image_layers`).then(response => {
      let image_layers = response.data.length > 0 ? response.data.sort((a, b) => { return a.position > b.position }) : response.data;

      this.setState({
        items: image_layers,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  setSelectionType(e) {
    this.setState({ selectionType: e ? e.value: null })
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleZoomableChange(e) {
    this.setState({
      zoomable: e.target.checked ? 't' : 'f'
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isEditing) return false;

    this.setState({ isLoading: true });

    let method = this.props.newRecord ? 'post' : 'put',
      url = this.props.newRecord ? `/admin/toggle_images` : `/admin/toggle_images/${this.props.id}`,
      callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
      data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    })
  }

  handleFileAttachmentChange(e) {
    this.setState({ image_to_upload: e.target.files[0] })
  }

  handleAddImage(image) {
    let method = 'post',
      url = '/admin/toggle_image_layers',
      data = this._buildLayerFormData(image);

    this.setState({ isAddingImage: true });

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({isAddingImage: false, error: true});
      }
      else {
        this.onAddLayer(response.data);
        this.setState({
          isAddingImage: false,
          image_to_upload: undefined,
          imageManagerIsOpen: false
        });
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isAddingImage: false, error: true });
    })
  }

  onAddLayer(data) {
    let updated_items = this.state.items;
    updated_items.push(data);
    this.setState({ items: updated_items });
  }

  onReorderItems(ordered_items) {
    ordered_items = ordered_items.map((item, index) => {
      item.position = index + 1;
      return item;
    });
    this.setState({ items: ordered_items });

    let method = 'put',
      url = '/admin/toggle_images/' + this.props.id + '/reorder',
      data = {};

    data.toggle_image = ordered_items.map((item) => { return item.id });

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({isLoading: false, error: true});
      }
      else {
        this.setState({ isLoading: false });
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    })
  }

  removeItem(item) {
    let items = this.state.items;
    let index = null;

    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i]['id'] === item.id) {
        index = i;
        items.splice(index, 1);
        break;
      }
    }
    this.setState({ items: items });

    this.onReorderItems(items);
  }

  updateItem(item) {
    let items = this.state.items;
    let index = this.state.items.indexOf(item);

    items[index] = item;
    this.setState({ items: items });
  }

  _buildLayerFormData(image) {
    let formData = new FormData();

    formData.append('toggle_image_layer[zoomable]', this.state.zoomable);
    formData.append('toggle_image_layer[toggle_image_id]', this.state.id);
    formData.append('toggle_image_layer[position]', this.state.items.length + 1);

    if (typeof image === 'object') {
      formData.append('toggle_image_layer[slide_image_attributes][slide_image]', image);
      formData.append('toggle_image_layer[slide_image_attributes][slide_id]', this.state.locationId);
    }
    else {
      formData.append('toggle_image_layer[image_id]', image);
    }
    return formData;
  }

  renderImageSubmitButton() {
    if (this.state.image_to_upload) {
      return (
        <div className={'block_link'} onClick={this.handleFileAttachmentSubmit.bind(this)} >{this.state.isAddingImage ? 'Adding Image...' : 'Add Image'}</div>
      )
    }
  }

  toggleEditingMode(value) {
    this.setState({
      isEditing: value
    });
  }

  _buildFormData() {
    const { locationId, locationType, title,
      selectionType, zoomable, id } = this.state;

    let formData = new FormData();

    formData.append('id', id);
    formData.append('toggle_image[model_id]', locationId);
    formData.append('toggle_image[model_type]', locationType);
    formData.append('toggle_image[title]', title);
    formData.append('toggle_image[selection_type]', selectionType);
    formData.append('toggle_image[zoomable]', zoomable);

    return formData;
  }
}

Form.defaultProps = {
  isLoading: false,
  isAddingImage: false,
  image_to_upload: false,
  items: [],
  isEditing: false,
  imageManagerIsOpen: false
};
