import React, { useState } from 'react';
import clsx from 'clsx';
import Modal, { useModalManager } from '@/components/common/Modal';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from '@/components/common/ApolloClient';
import GraphQlImageModel from '@/components/interfaces/GraphQlImageModel';
import ImageModelForm, {
  FILENAME_LENGTH_SERVER_ERROR, FILENAME_LENGTH_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE,
} from '../Form';
import CompareDuplicates from '../CompareDuplicates';
import ExistingImagePicker from '../ExistingImagePicker/ExistingImagePicker';

export const CREATE_IMAGE_MODEL = gql`
  mutation CreateImageModel($modelId: ID!,
                            $questionId: ID,
                            $modelType: String!,
                            $imageFile: File,
                            $imageEsFile: File,
                            $credits: String,
                            $decorative: Boolean,
                            $captionEn: String,
                            $captionEs: String,
                            $descriptionConfidence: Float,
                            $descriptionEn: String,
                            $descriptionEs: String,
                            $alignment: String,
                            $displaySize: String,
                            $zoomType: String,
                            $titleEn: String,
                            $titleEs: String,
                            $hasText: Boolean,
                            $mediaType: String,
                            $hideCaptions: Boolean,
                            $englishImageOnly: Boolean,
                            $answerData: JSON,
                            $mediaMeta: JSON,
                            $readOnly: Boolean) {
    createImageModel(modelId: $modelId,
                     modelType: $modelType,
                     questionId: $questionId
                     imageFile: $imageFile,
                     imageEsFile: $imageEsFile,
                     credits: $credits,
                     decorative: $decorative,
                     captionEn: $captionEn,
                     captionEs: $captionEs,
                     descriptionConfidence: $descriptionConfidence,
                     descriptionEn: $descriptionEn,
                     descriptionEs: $descriptionEs,
                     alignment: $alignment,
                     displaySize: $displaySize,
                     zoomType: $zoomType,
                     titleEn: $titleEn,
                     titleEs: $titleEs,
                     hasText: $hasText,
                     hideCaptions: $hideCaptions,
                     mediaType: $mediaType,
                     englishImageOnly: $englishImageOnly,
                     answerData: $answerData,
                     mediaMeta: $mediaMeta,
                     readOnly: $readOnly) {
      result {
        imageModel {
          id,
          alignment,
          captionEn,
          captionEs,
          decorative,
          dimensions,
          displaySize,
          displayStyle,
          hideCaptions,
          answerData,
          mediaMeta,
          snippet,
          tableCellsAnswerDataEs,
          zoomType,
          image {
            id,
            credits,
            descriptionConfidence,
            descriptionEn,
            descriptionEs,
            imageUrl,
            imageEsUrl,
            fileName,
            fileNameEs,
            resolution,
            thumbnail,
            titleEn,
            titleEs,
            hasText,
            englishImageOnly,
          }
        },
        duplicateImages {
          id,
          imageUrl,
          imageEsUrl,
          fileName
        },
        newImage {
          id,
          imageUrl,
          imageEsUrl,
          fileName
        }
      }
    }
  }
`;

export interface IAddImageProps {
  afterMergeDuplicates?: (imageModel: GraphQlImageModel[]) => any;
  afterSubmit: (imageModel: GraphQlImageModel) => any;
  buttonClass?: string;
  buttonText?: string;
  clearDefaultImage?: () => any;
  defaultImage?: GraphQlImageModel;
  dontMergeCallback?: (imageModel: GraphQlImageModel) => any;
  excludeFields?: string[];
  forFroala?: boolean;
  fromButton?: boolean;
  fromSlideShow?: boolean;
  getExistingImages?: () => any;
  headerText?: string;
  isAdmin?: boolean;
  isSmallBtn?: boolean;
  mediaMeta?: object;
  mediaType?: string;
  modalManager?: ModalManager;
  modelId: string | number;
  modelType: string;
  standaloneButton?: boolean;
  updateResources?: () => any;
}

const AddImage = ({
  afterMergeDuplicates = () => { },
  afterSubmit,
  buttonClass = '',
  buttonText = 'Add Image',
  clearDefaultImage = () => { },
  defaultImage,
  dontMergeCallback = () => { },
  excludeFields = [],
  forFroala = false,
  fromButton = false,
  fromSlideShow = false,
  getExistingImages = undefined,
  headerText = 'Add Image',
  isAdmin = false,
  isSmallBtn = true,
  mediaMeta = {},
  mediaType = null,
  modalManager: modalManagerProp,
  modelId,
  modelType,
  standaloneButton = true,
  updateResources = () => { },
}: IAddImageProps) => {
  const modalManager = modalManagerProp || useModalManager();
  const duplicateCheckModalManager = useModalManager();
  const [createImageModelMutation, { loading }] = useMutation(CREATE_IMAGE_MODEL, { context: { hasUpload: true } });
  const [submitError, setSubmitError] = useState();
  const [newImage, setNewImage] = useState(null);
  const [imageModel, setImageModel] = useState(null);
  const [duplicateImages, setDuplicateImages] = useState(null);
  const [existingImage, setExistingImage] = useState(false);

  const addImage = (values) => {
    const variables = {
      variables: {
        alignment: values.alignment,
        captionEn: values.caption_en,
        captionEs: values.caption_es,
        credits: values.credits,
        decorative: values.decorative,
        descriptionConfidence: values.description_confidence,
        descriptionEn: values.description_en,
        descriptionEs: values.description_es,
        displaySize: values.display_size,
        englishImageOnly: values.english_image_only,
        hasText: values.has_text,
        hideCaptions: values.hide_captions,
        imageEsFile: values.image_es,
        imageFile: values.image,
        mediaMeta,
        modelId,
        modelType,
        readOnly: fromButton,
        titleEn: values.title_en,
        titleEs: values.title_es,
        zoomType: values.zoom_type,
        ...(!!mediaType && { mediaType }),
      },
    };

    createImageModelMutation(variables)
      .then((result) => {
        const mutationResult = result.data.createImageModel.result;
        const imageModelCreated = mutationResult.imageModel;
        const imageIsDup = mutationResult.duplicateImages && mutationResult.newImage;

        // we want to trigger the afterSubmit for froala editors to insert the imageModel into the editor
        if (imageModelCreated && (!(afterMergeDuplicates && imageIsDup) || forFroala)) {
          afterSubmit(mutationResult.imageModel);
        }

        // If sent back potential duplicates, open duplicate comparison modal:
        if (imageIsDup) {
          setImageModel(imageModelCreated);
          setNewImage(mutationResult.newImage);
          setDuplicateImages(mutationResult.duplicateImages);
          modalManager.close();
          duplicateCheckModalManager.open();
          return;
        }

        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setSubmitError(error.message.includes(FILENAME_LENGTH_SERVER_ERROR)
          ? FILENAME_LENGTH_ERROR_MESSAGE
          : DEFAULT_ERROR_MESSAGE);
      });
  };

  const renderDuplicateCheckModal = () => {
    if (!newImage || !duplicateImages) return null;

    const formattedImage = { ...newImage, id: parseInt(newImage.id, 10) };

    const formattedDuplicateImages = duplicateImages.map(image => ({ ...image, id: parseInt(image.id, 10) }));

    return (
      <CompareDuplicates
        afterMergeDuplicates={afterMergeDuplicates}
        updateResources={updateResources}
        modelId={modelId}
        modelType={modelType}
        newImage={formattedImage}
        duplicateImages={formattedDuplicateImages}
        isOpen={duplicateCheckModalManager.isOpen}
        dontMergeCallback={dontMergeCallback}
        close={duplicateCheckModalManager.close}
        imageModel={imageModel}
      />
    );
  };

  const closeModal = () => {
    if (clearDefaultImage) clearDefaultImage();
    modalManager.close();
  };

  const getMessage = () => {
    let message = 'Existing Image';
    message += fromSlideShow ? ' in Slideshow' : '';

    return message;
  };

  return (
    <ApolloProvider client={apolloClient}>
      {standaloneButton && (
        <button
          type="button"
          className={clsx(`btn btn--green mb5 ${buttonClass}`, { 'btn--sm': isSmallBtn })}
          onClick={modalManager.open}
        >
          {buttonText}
        </button>
      )}
      <Modal nearlyFullScreen isOpen={modalManager.isOpen} closeModal={closeModal} headerText={headerText}>
        {getExistingImages && (
          <div>
            <label className="tw-inline-flex tw-items-center" htmlFor="toggleExistingImageOff">
              <input
                id="toggleExistingImageOff"
                name="existingImage"
                type="radio"
                onChange={() => setExistingImage(false)}
                checked={!existingImage}
              />
              New Image
            </label>
            <label className="tw-inline-flex tw-items-center" htmlFor="toggleExistingImageOn">
              <input
                id="toggleExistingImageOn"
                name="existingImage"
                type="radio"
                onChange={() => setExistingImage(true)}
                checked={existingImage}
              />
              {getMessage()}
            </label>
          </div>
        )}
        {existingImage ? (
          <ExistingImagePicker
            afterSubmit={afterSubmit}
            closeModal={modalManager.close}
            getExistingImages={getExistingImages}
            modelId={modelId}
            modelType={modelType}
            fromButton={fromButton}
          />
        ) : (
          <ImageModelForm
            excludeFields={excludeFields}
            submitError={submitError}
            onSubmit={addImage}
            submitting={loading}
            closeModal={modalManager.close}
            defaultImage={defaultImage}
            isAdmin={isAdmin}
            fromSlideShow={fromSlideShow}
            action={headerText}
          />
        )}
      </Modal>
      {renderDuplicateCheckModal()}
    </ApolloProvider>
  );
};

export default AddImage;
