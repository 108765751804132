import React, { useMemo, useState } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';
import Modal, { Footer } from '@/components/common/Modal';
import { INPUT_VARIANTS } from '@/components/ui/Form/variants';
import { CONFIRM_REMOVE_TEXT, getCurrentDate, RowData } from './constants';

interface RemoveDemoAccessModalProps {
  closeModal: () => void;
  isOpen: boolean;
  rowData: RowData;
  subscriber: {
    id: number;
    name: string;
  }
}

const RemoveDemoAccessModal = ({ closeModal, isOpen, rowData, subscriber }: RemoveDemoAccessModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [deleteProgramMappings, setDeleteProgramMappings] = useState(false);

  const isInputValid = useMemo(() => inputValue.toLowerCase() === CONFIRM_REMOVE_TEXT, [inputValue]);

  const { isLoading, mutate } = useMutation({
    mutationFn: async () => {
      await axios
        .post(Routes.plato_api_remove_demo_license_subscriber_license_path(subscriber.id, rowData.id, {
          delete_program_mappings: deleteProgramMappings,
        }))
        .then(() => window.location.reload());
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      headerText="Remove Demo License"
      isOpen={isOpen}
    >
      <div className="tw-text-sm">
        <div className="tw-mb-5">
          Are you sure you want to remove <strong>{rowData.name}</strong> for <strong>{subscriber.name}</strong>?
        </div>

        <div className="tw-mb-5">
          This action will:
          <ul className="tw-list-disc">
            <li className="tw-ml-5">
              Delete {rowData.num_classrooms} classrooms from {rowData.assigned_teacher_seats_count} teachers
              and {rowData.assigned_student_seats_count} students.
            </li>
            <li className="tw-ml-5">
              Set the expiration date of {rowData.num_subscriptions} transactions to today ({getCurrentDate()}).
            </li>
          </ul>
        </div>

        <div className="tw-mb-5">
          There are {rowData.num_program_mappings} program assignment rules with demo access for this license.
          What would you like to do?

          <label className="tw-flex tw-items-center">
            <input
              id="programMapping"
              name="programMapping"
              type="radio"
              onChange={() => setDeleteProgramMappings(false)}
              checked={!deleteProgramMappings}
            />
            Convert them to regular access
          </label>

          <label className="tw-flex tw-items-center">
            <input
              id="programMapping"
              name="programMapping"
              type="radio"
              onChange={() => setDeleteProgramMappings(true)}
              checked={deleteProgramMappings}
            />
            Delete Them
          </label>
        </div>

        <div>
          <div className="tw-mb-2">
            <label htmlFor="remove-demo-license-confirm">
              To confirm, type <strong><i>{CONFIRM_REMOVE_TEXT}</i></strong> into the field below.
            </label>
          </div>

          <input
            id="remove-demo-license-confirm"
            className={INPUT_VARIANTS.default}
            type="text"
            onChange={e => setInputValue(e.target.value)}
          />
        </div>
      </div>

      <Footer
        primaryButtonText="Remove License"
        primaryButtonClassName="btn btn--red"
        secondaryButtonText="Cancel"
        secondaryButtonCallback={closeModal}
        primaryButtonCallback={mutate}
        primaryButtonDisabled={!isInputValid}
        submitting={isLoading}
        primaryButtonSubmit={false}
      />
    </Modal>
  );
};

const queryClient = new QueryClient();

const RemoveDemoAccessModalWrapper = (props: RemoveDemoAccessModalProps) => (
  <QueryClientProvider client={queryClient}>
    <RemoveDemoAccessModal {...props} />
  </QueryClientProvider>
);

export default RemoveDemoAccessModalWrapper;
