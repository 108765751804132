import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IClassroomLesson } from '../../types';
import { QueryKeys } from '../../GradesTableQueryClientProvider';

const useClassroomLesson = (classroomId: number, lessonId: number) => {
  const isEnabled = !!lessonId && !!classroomId;

  const query = useQuery([QueryKeys.ClassroomLesson, classroomId, lessonId], () => {
    const path = Routes.plato_api_get_classroom_lesson_classroom_lessons_path({
      lesson_id: lessonId,
      classroom_id: classroomId,
    });

    return axios
      .get<{ data: IClassroomLesson }>(path)
      .then(res => res.data?.data || null)
      .catch(err => console.error(err));
  }, { staleTime: Infinity, enabled: isEnabled });

  return { ...query, data: query.data || null };
};

export default useClassroomLesson;
