import React, { useContext } from 'react';
import I18n from 'modules/i18n';
import BuilderContext from 'common/Context';
import Dropdown from 'common/Dropdown';
import { capitalize } from 'common/Utils';
import { CheckboxField } from '@/components/common/Forms';
import { Form } from 'react-final-form';
import styles from './SettingsButton.module.scss';
import { DraggableContainerPosition } from '../../types';

const ANSWER_CHOICES = 'answer_choices';
const DROP_ZONES = 'drop_zones';
const CHANGE_SHAPE = 'change_shape';
const CHANGE_POSITION = 'change_position';
const POSITIONS = [
  DraggableContainerPosition.Left,
  DraggableContainerPosition.Right,
  DraggableContainerPosition.Top,
  DraggableContainerPosition.Bottom,
];

const SettingsButton = ({ hasTransparentAnswerChoices, hasTransparentDropzones }) => {
  const {
    dragAndDropSettingsDispatch,
    setDropZoneShape,
    dropZoneShape,
    dropZoneDispatch,
    draggableChoices,
    draggableContainerPosition,
    setDraggableContainerPosition,
    textAreaDispatch,
  } = useContext(BuilderContext);

  const handleSettingsChange = async (args, _state, _utils) => {
    const settingsType = args[0];

    if (settingsType === ANSWER_CHOICES) {
      dragAndDropSettingsDispatch({
        payload: { hasTransparentAnswerChoices: !hasTransparentAnswerChoices },
        type: 'SET_TRANSPARENT_ANSWER_CHOICES'
      });
    }
    else if (settingsType === DROP_ZONES) {
      dragAndDropSettingsDispatch({
        payload: { hasTransparentDropzones: !hasTransparentDropzones },
        type: 'SET_TRANSPARENT_DROPZONES'
      });
    }
    else if (settingsType === CHANGE_SHAPE) {
      await dragAndDropSettingsDispatch({
        payload: { dropZoneShape: args[1] },
        type: 'SET_DROP_ZONE_SHAPE'
      });

      await dropZoneDispatch({
        payload: { dropZoneShape: args[1] },
        type: 'UPDATE_DROP_ZONE_SHAPE'
      });

      // Only adjust drop zone position if there are linked vocab/glossary terms.
      if (draggableChoices.a.vocabId) {
        await textAreaDispatch({
          payload: args[1],
          type: 'SPACE_TEXT_AREAS'
        });

        await dropZoneDispatch({
          type: 'MATCH_DROPZONES_TO_TEXT_AREAS'
        });
      }

      setDropZoneShape(args[1]);
    }
    else if (settingsType === CHANGE_POSITION) {
      dragAndDropSettingsDispatch({
        payload: { draggableContainerPosition: args[1] },
        type: 'SET_DRAGGABLE_CONTAINER_POSITION'
      });
      setDraggableContainerPosition(args[1]);
    }
  };

  return (
    <div className="inline_block">
      <Dropdown
        buttonClass="btn btn--sm btn--green dropdown-toggle"
        dropdownClass="!tw-top-[45px] tw-w-[280px]"
        toggleText="Settings"
      >
        <Form
          initialValues={{ hasTransparentAnswerChoices, hasTransparentDropzones }}
          onSubmit={() => {}}
          mutators={{ handleSettingsChange: handleSettingsChange }}
          render={({ values, form }) => (
            <form
              onSubmit={() => {}}
              className={styles.settingsForm}
            >
              <Dropdown
                buttonClass={`
                  !tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-w-full tw-mt-1.5
                `}
                dropdownClass="tw-ml-5 tw-px-4 tw-py-1.5"
                toggleText={(
                  <div className="!tw-cursor-pointer tw-flex tw-items-center tw-justify-between">
                    <div className={`!tw-cursor-pointer tw-ml-[25px] ${styles.labelStyle}`}>
                      Draggable Container Position
                    </div>
                    <i className="!tw-cursor-pointer fa fa-icon fa-caret-right" />
                  </div>
                )}
                dropdownPosition="right"
              >
                {POSITIONS.map(position => (
                  <CheckboxField
                    key={position}
                    onChange={() => form.mutators.handleSettingsChange(CHANGE_POSITION, position)}
                    fieldClass={styles.checkboxField}
                    labelClass={styles.checkboxLabel}
                    rowClass={styles.checkboxRow}
                    checked={draggableContainerPosition === position}
                    label={capitalize(position)}
                    name="draggableContainerPosition"
                  />
                ))}
              </Dropdown>

              <hr />

              <CheckboxField
                onChange={() => form.mutators.handleSettingsChange(ANSWER_CHOICES)}
                fieldClass={styles.checkboxField}
                labelClass={styles.checkboxLabel}
                rowClass={styles.checkboxRow}
                checked={values.hasTransparentAnswerChoices}
                label={I18n.t('transparent_answer_choices')}
                name="hasTransparentAnswerChoices"
              />

              <hr />

              <CheckboxField
                onChange={() => form.mutators.handleSettingsChange(DROP_ZONES)}
                fieldClass={styles.checkboxField}
                labelClass={styles.checkboxLabel}
                rowClass={styles.checkboxRow}
                checked={values.hasTransparentDropzones}
                label={I18n.t('transparent_dropzones')}
                name="hasTransparentDropzones"
              />

              <Dropdown
                buttonClass={`
                  !tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-w-full tw-mt-1.5
                `}
                dropdownClass="tw-ml-5 tw-px-4 tw-py-1.5"
                toggleText={(
                  <div className="!tw-cursor-pointer tw-flex tw-items-center tw-justify-between">
                    <div className={`!tw-cursor-pointer tw-ml-[25px] ${styles.labelStyle}`}>Dropzone Shapes</div>
                    <i className="!tw-cursor-pointer fa fa-icon fa-caret-right" />
                  </div>
                )}
                dropdownPosition="right"
              >
                <CheckboxField
                  onChange={() => form.mutators.handleSettingsChange(CHANGE_SHAPE, 'rectangle')}
                  fieldClass={styles.checkboxField}
                  labelClass={styles.checkboxLabel}
                  rowClass={styles.checkboxRow}
                  checked={dropZoneShape === 'rectangle'}
                  label={(
                    <div className="tw-flex tw-items-center">
                      <i className="fa fa-icon fa-square-o tw-text-xl tw-mx-2 tw-my-2" />
                      <div className="tw-ml-1">Rectangles</div>
                    </div>
                  )}
                  name="rectangles"
                />

                <CheckboxField
                  onChange={() => form.mutators.handleSettingsChange(CHANGE_SHAPE, 'circle')}
                  fieldClass={styles.checkboxField}
                  labelClass={styles.checkboxLabel}
                  rowClass={styles.checkboxRow}
                  checked={dropZoneShape === 'circle'}
                  label={(
                    <div className="tw-flex tw-items-center">
                      <div className="fa fa-icon fa-circle-thin tw-text-xl tw-mx-2 tw-my-2" />
                      <div className="tw-ml-1">Circles</div>
                    </div>
                  )}
                  name="circles"
                />
              </Dropdown>
            </form>
          )}
        />
      </Dropdown>
    </div>
  );
};

export default SettingsButton;
