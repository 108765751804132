import React from 'react';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import { Lesson, Program } from '@/components/shared/LessonOverview/types';
import assessmentsIcon from 'images/icons/assessments.svg';
import Card, { CardBody } from './Card';
import ActionBar from './ActionBar';

type AssessmentsCardProps = {
  hasAssessments: boolean,
  lesson: Lesson,
  lessonId: number,
  program: Program,
  type: string,
  stafferId?: number,
  unitColor: string,
};

const AssessmentsCard = ({
  hasAssessments, lesson, lessonId, program, stafferId, type, unitColor,
}: AssessmentsCardProps) => {
  const isAssigned = lesson.assessments.some(assessment => assessment.isAssigned);

  const printHref = () => {
    if (!hasAssessments) return '';

    return Routes.pdf_staffer_assessment_print_path(lesson.assessments[0].id, {
      program_id: program.id,
      locale: I18n.locale,
    });
  };

  // If there is at least one assessment that can be assigned, return the id of the first one.
  const assessmentToAssign = hasAssessments ? {
    label: lesson.assessments[0].name,
    value: `Assessment-${lesson.assessments[0].id}`,
  } : {
    label: '',
    value: '',
  };

  return (
    <Card label="assessments" type={type} unitColor={unitColor}>
      <CardBody
        ariaLabel="TCI Assessments"
        iconSrc={assessmentsIcon}
        linkHref={Routes.shared_program_lesson_assessments_path(program.id, lessonId)}
        linkLabel={`TCI ${I18n.t('assessments')}`}
        type={I18n.t(type)}
        unitColor={unitColor}
      />
      {stafferId && (
        <ActionBar
          href={Routes.shared_program_lesson_assessments_path(program.id, lessonId)}
          initialValues={{
            modelType: 'assessment',
            modelsToAssign: [assessmentToAssign],
          }}
          isAssigned={isAssigned}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
          showPrintButton={hasAssessments}
          printHref={printHref()}
        />
      )}
    </Card>
  );
};

export default AssessmentsCard;
