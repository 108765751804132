import React from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { twMerge } from '@/utils';
import Label, { ILabelProps } from '@/components/ui/Form/Label';
import ErrorMessage from '@/components/ui/Form/ErrorMessage';
import Tooltip from '@/components/common/Tooltip';

interface EditableDivProps {
  className?: string;
  containerClasses?: string;
  control?: Control<any, any>;
  errors?: FieldErrors<any>;
  label: string;
  name: string;
  placeholder?: string;
  LabelProps?: Partial<ILabelProps>;
  tooltipText?: string;
  onChange?: (e: ContentEditableEvent) => void;
}

const EditableDiv = ({
  className,
  containerClasses,
  control,
  errors = {},
  label,
  name,
  placeholder,
  LabelProps = {},
  tooltipText,
  onChange,
}: EditableDivProps) => {
  const controller = useController({ control, name });

  const tooltip = () => {
    if (!tooltipText) return null;

    return (
      <Tooltip content={tooltipText} theme="white" size="medium">
        <span className="tw-ml-1">
          <i className="fa fa-question-circle" aria-hidden />
          <span className="sr-only">{tooltipText}</span>
        </span>
      </Tooltip>
    );
  };

  return (
    <div ref={controller.field.ref} className={containerClasses}>
      <Label {...{ htmlFor: name, ...LabelProps }}>
        {label}
        {tooltip()}
      </Label>
      <ContentEditable
        className={twMerge(
          'tw-w-full tw-border-gray-300 tw-border tw-border-solid tw-rounded tw-p-2.5 tw-max-w-[280px] tw-box-border',
          'tw-transition-all tw-resize-none tw-overflow-hidden tw-duration-100 tw-break-words tw-whitespace-pre-wrap',
          'tw-bg-white', 'empty:before:content-[attr(wat)]',
          className,
        )}
        innerRef={controller.field.ref}
        html={controller.field.value}
        onChange={(e) => {
          if (onChange) onChange(e);
          controller.field.onChange(e.currentTarget.textContent);
        }}
        placeholder={placeholder}
      />
      <ErrorMessage errors={errors} name={name} />
    </div>
  );
};

export default EditableDiv;
