import React from 'react';
import clsx from 'clsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AddQuestionImage from './Add';
import Card from './Card';
import { QuestionOrQuestionGroupType, ImageModelOpts, useImageModels } from './utils';
import TablePromptAnswerKeyButton from './TablePromptAnswerKeyButton';

interface ManagerProps {
  forAnswerKey?: boolean;
  modelId: number;
  questionType: QuestionOrQuestionGroupType;
  userType: UserType;
  withTableAnswerKey?: boolean;
  automaticTranslation?: boolean;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  },
});

const Manager = ({ forAnswerKey, automaticTranslation, modelId, questionType, userType, withTableAnswerKey }: ManagerProps) => {
  const canAddVideo = ['Sysadmin', 'Admin', 'ContentManager'].includes(userType);

  const nonBackgroundImages = questionType === 'DragAndDropQuestion';
  const modelType = questionType === 'QuestionGroup' ? 'QuestionGroup' : 'Question';

  const opts: ImageModelOpts = { forAnswerKey, nonBackgroundImages, modelType };
  const { data: imageModels, refetch } = useImageModels(modelId, opts);

  const afterSubmit = () => refetch();
  const afterEdit = () => refetch();

  const mediaType = () => {
    if (forAnswerKey) return 'answer';
    if (questionType === 'DrawingQuestion') return 'background';

    return 'question';
  };

  const hasImageModels = (imageModels || []).length > 0;
  const tablePromptImageModel = (imageModels || [])[0];

  return (
    <>
      {forAnswerKey &&
        <div className="mb5">
          <p className="tw-my-4 tw-font-bold tw-inline-block">Image</p>
        </div>
      }
      <div className={clsx('tw-flex tw-gap-1', { 'tw-mb-4 -tw-mt-3': forAnswerKey })}>
        <>
          {!hasImageModels &&
            <AddQuestionImage
              afterSubmit={afterSubmit}
              buttonText={forAnswerKey ? 'Add Answer Image' : 'Add Image'}
              forAnswerKey={forAnswerKey}
              modelId={modelId}
              modelType={modelType}
              userType={userType}
              mediaType={mediaType()}
            />
          }
          {withTableAnswerKey &&
            <TablePromptAnswerKeyButton
              afterSubmit={afterSubmit}
              automaticTranslation={automaticTranslation}
              imageModel={tablePromptImageModel}
              questionId={modelId}
            />
          }
        </>
        {!forAnswerKey && (
          <AddQuestionImage
            afterSubmit={afterSubmit}
            buttonText="Add GIF"
            mediaType={mediaType()}
            modelId={modelId}
            modelType={modelType}
            userType={userType}
          />
        )}
        {canAddVideo && <span id={`add-video-button-question-${modelId}`} className="input" />}
      </div>
      {hasImageModels && !forAnswerKey && (
        <p className="tw-my-4 tw-font-bold tw-inline-block">Image</p>
      )}
      <div className="tw-mb-4 tw-flex tw-flex tw-flex-wrap tw-justify-between tw-gap-4">
        {(imageModels || []).map(imageModel => (
          <Card
            afterSubmit={afterEdit}
            forAnswerKey={forAnswerKey}
            imageModel={imageModel}
            key={imageModel.id}
            mediaType={mediaType()}
            modelId={modelId}
            modelType={modelType}
            questionType={questionType}
            userType={userType}
          />
        ))}
      </div>
    </>
  );
};

const ManagerWrapper = (props: ManagerProps) => (
  <QueryClientProvider client={queryClient}>
    <Manager {...props} />
  </QueryClientProvider>
);

export default ManagerWrapper;
