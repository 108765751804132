import { useContext } from 'react';
import { useStore } from 'zustand';
import { GradesTableContext } from './GradesTableStoreProvider';
import { UseGradesTableState } from './createGradesTableStore';

const useGradesTableContext = <T>(
  selector: (state: UseGradesTableState) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(GradesTableContext);

  return useStore(store, selector, equalityFn);
};

export default useGradesTableContext;
