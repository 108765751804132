import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

interface GradeByAssignmentInfoHeaderProps {
  assignmentName?: string;
  assignmentDueDate?: string;
  lessonTitleNumber?: number;
  lessonTitle?: string;
}

const dateTimeToUserTimeZone = (date) => {
  const datetime = moment(date);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const datetimeLocal = datetime.tz(userTimezone);

  return datetimeLocal.format('MM/DD/YYYY hh:mm A z');
};

const TopRowAssignment = ({ assignmentName }) => {
  if (!assignmentName) return null;

  const assignmentNameLabel = I18n.translate('assignment_name');

  return (
    <p className="tw-m-0 tw-text-sm">
      <span className="tw-font-bold !tw-m-0">{assignmentNameLabel}: </span>
      <span className="tw-font-normal !tw-m-0">{assignmentName}</span>
    </p>
  );
};

const LineDivider = () => (
  <div className="tw-w-full tw-border-t tw-border-solid tw-border-[#dcdcdc] tw-my-2" />
);

const BottomRowLesson = ({ lessonTitle, lessonTitleNumber, assignmentDueDate }) => {
  const lessonLabel = I18n.translate('lesson');
  const dueLabel = I18n.translate('due');

  return (
    <div className="!tw-m-0 tw-text-sm">
      {lessonTitle && lessonTitleNumber && (
        <>
          <span className="tw-font-bold !tw-m-0">{lessonLabel} {lessonTitleNumber} - </span>
          <span className="tw-font-normal !tw-m-0">{lessonTitle}</span>
        </>
      )}
      {assignmentDueDate && (
        <>
          <span className="tw-font-bold !tw-m-0 !tw-ml-8">{dueLabel}: </span>
          <span
            className="tw-font-normal !tw-m-0"
          >{dateTimeToUserTimeZone(assignmentDueDate)}
          </span>
        </>
      )}
    </div>
  );
};

const GradeByAssignmentInfoHeader = ({
  assignmentName,
  assignmentDueDate,
  lessonTitle,
  lessonTitleNumber,
}: GradeByAssignmentInfoHeaderProps) => (
  <div className="tw-w-full tw-pr-8">
    <TopRowAssignment assignmentName={assignmentName} />
    <LineDivider />
    <BottomRowLesson
      assignmentDueDate={assignmentDueDate}
      lessonTitle={lessonTitle}
      lessonTitleNumber={lessonTitleNumber}
    />
  </div>
);

export default GradeByAssignmentInfoHeader;
