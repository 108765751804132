import React from 'react';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { SlideGroup } from '@/components/admin/SlideShow/Builder/CreateTransistionGroup/utils';
import TransitionSlide from '../PresentMode/TransitionSlide';

const BuilderTransitionSlide = () => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slideShow = useSlideShowContext(state => state.slideShow);
  const formattedSlides = useSlideShowContext(state => state.slides);

  const slideGroups = formattedSlides.filter(s => s.type === 'Transition') as SlideGroup[];
  const groupIndex = slideGroups.findIndex(group => group.id === currentSlide.id);

  return (
    <div>
      <TransitionSlide
        forDisplay="builder"
        imageUrl={slideShow.imageUrl}
        jumpToSlide={() => {}}
        slideGroupIndex={groupIndex}
        slideGroups={slideGroups}
      />
    </div>
  );
};

export default BuilderTransitionSlide;
