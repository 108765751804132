import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from 'common/Modal';
import { ApolloProvider, gql, useMutation } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import ImageModelForm, {
  FILENAME_LENGTH_SERVER_ERROR, FILENAME_LENGTH_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE
} from '../Form';
import CompareDuplicates from '../CompareDuplicates';
import ExistingImagePicker from '../ExistingImagePicker/ExistingImagePicker.tsx';

const EditImagePropTypes = {
  afterMergeDuplicates: PropTypes.func,
  afterSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  btnClasses: PropTypes.string,
  dontMergeCallback: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es'])),
  fromSlideShow: PropTypes.bool,
  getExistingImages: PropTypes.func,
  headerText: PropTypes.string,
  imageModel: PropTypes.shape({
    alignment: PropTypes.string,
    captionEn: PropTypes.string,
    captionEs: PropTypes.string,
    decorative: PropTypes.bool,
    displaySize: PropTypes.string,
    id: PropTypes.number.isRequired,
    image_credits: PropTypes.string,
    image_descriptionConfidence: PropTypes.number,
    image_descriptionEn: PropTypes.string,
    image_descriptionEs: PropTypes.string,
    image_fileName: PropTypes.string.isRequired,
    image_titleEn: PropTypes.string,
    image_titleEs: PropTypes.string,
    zoomType: PropTypes.string
  }),
  isAdmin: PropTypes.bool,
  mediaType: PropTypes.string,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }),
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelType: PropTypes.string.isRequired,
  standaloneButton: PropTypes.bool,
  updateResources: PropTypes.func,
  useButtonIcon: PropTypes.bool,
};

const EditImageDefaultProps = {
  afterMergeDuplicates: null,
  buttonText: 'Edit Image',
  btnClasses: 'btn--sm btn--outline-purple',
  dontMergeCallback: () => {},
  fromSlideShow: false,
  getExistingImages: undefined,
  headerText: 'Edit Image',
  isAdmin: false,
  mediaType: null,
  modalManager: null,
  standaloneButton: true,
  updateResources: () => {},
  useButtonIcon: true,
};

const EditImageWrapper = ({ ...props }) => (
  <ApolloProvider client={apolloClient}>
    <EditImage {...props} />
  </ApolloProvider>
);

EditImageWrapper.propTypes = EditImagePropTypes;
EditImageWrapper.defaultProps = EditImageDefaultProps;

export const UPDATE_IMAGE_MODEL = gql`
  mutation UpdateImageModel($id: ID!,
                            $questionId: ID,
                            $imageFile: File,
                            $imageEsFile: File,
                            $credits: String,
                            $decorative: Boolean,
                            $captionEn: String,
                            $captionEs: String,
                            $descriptionConfidence: Float,
                            $descriptionEn: String,
                            $descriptionEs: String,
                            $hideCaptions: Boolean,
                            $alignment: String,
                            $displaySize: String,
                            $zoomType: String,
                            $titleEn: String,
                            $titleEs: String,
                            $hasText: Boolean,
                            $mediaType: String,
                            $answerData: JSON,
                            $englishImageOnly: Boolean,
                            $removeSpanishImage: Boolean) {
    updateImageModel(id: $id,
                     questionId: $questionId,
                     imageFile: $imageFile,
                     imageEsFile: $imageEsFile,
                     credits: $credits,
                     decorative: $decorative,
                     captionEn: $captionEn,
                     captionEs: $captionEs,
                     descriptionConfidence: $descriptionConfidence,
                     descriptionEn: $descriptionEn,
                     descriptionEs: $descriptionEs,
                     hideCaptions: $hideCaptions,
                     alignment: $alignment,
                     displaySize: $displaySize,
                     zoomType: $zoomType,
                     titleEn: $titleEn,
                     titleEs: $titleEs,
                     hasText: $hasText,
                     mediaType: $mediaType,
                     answerData: $answerData,
                     englishImageOnly: $englishImageOnly
                     removeSpanishImage: $removeSpanishImage) {
      result {
        imageModel {
          id,
          alignment,
          captionEn,
          captionEs,
          decorative,
          displaySize,
          displayStyle,
          dimensions,
          hideCaptions,
          answerData,
          snippet,
          tableCellsAnswerDataEs,
          mediaMeta,
          zoomType,
          image {
            id,
            credits,
            descriptionConfidence,
            descriptionEn,
            descriptionEs,
            imageUrl,
            imageEsUrl,
            fileName,
            fileNameEs,
            resolution,
            thumbnail,
            titleEn,
            titleEs,
            hasText,
            englishImageOnly,
          }
        },
        duplicateImages {
          id,
          imageUrl,
          fileName
        },
        newImage {
          id,
          imageUrl,
          fileName
        }
      }
    }
  }
`;

const EditImage = ({ ...props }) => {
  const modalManager = props.modalManager || useModalManager();
  const [updateImageModelMutation, { loading }] = useMutation(UPDATE_IMAGE_MODEL, { context: { hasUpload: true } });
  const [submitError, setSubmitError] = useState();
  const duplicateCheckModalManager = useModalManager();
  const [newImage, setNewImage] = useState(null);
  const [imageModel, setImageModel] = useState(null);
  const [duplicateImages, setDuplicateImages] = useState(null);
  const [existingImage, setExistingImage] = useState(false);

  const editImage = (values) => {
    // key-values with `undefined` are getting removed from the graphql request- change to `null` to prevent this,
    // and only opt in to this behavior for select attributes.
    ['caption_en', 'caption_es', 'credits'].forEach(key => {
      if (values[key] === undefined) values[key] = null;
    });

    const variables = {
      variables: {
        alignment: values.alignment,
        captionEn: values.caption_en,
        captionEs: values.caption_es,
        credits: values.credits,
        decorative: values.decorative,
        descriptionConfidence: values.description_confidence,
        descriptionEn: values.description_en,
        descriptionEs: values.description_es,
        displaySize: values.display_size,
        englishImageOnly: values.english_image_only,
        hasText: values.has_text,
        hideCaptions: values.hide_captions,
        id: props.imageModel.id,
        imageEsFile: values.image_es,
        imageFile: values.image,
        removeSpanishImage: values.remove_spanish_image,
        titleEn: values.title_en,
        titleEs: values.title_es,
        zoomType: values.zoom_type,
        ...(!!props.mediaType && { mediaType: props.mediaType }),
      }
    };

    updateImageModelMutation(variables)
      .then((result) => {
        const mutationResult = result.data.updateImageModel.result;
        const imageModelCreated = mutationResult.imageModel;
        const imageIsDup = mutationResult.duplicateImages && mutationResult.newImage;

        if (imageModelCreated && !(props.afterMergeDuplicates && imageIsDup)) {
          props.afterSubmit(mutationResult.imageModel);
        }

        // If sent back potential duplicates, open duplicate comparison modal:
        if (imageIsDup) {
          setImageModel(imageModelCreated);
          setNewImage(mutationResult.newImage);
          setDuplicateImages(mutationResult.duplicateImages);
          modalManager.close();
          duplicateCheckModalManager.open();
          return;
        }

        modalManager.close();
      })
      .catch((error) => {
        console.log(error);
        setSubmitError(error.message.includes(FILENAME_LENGTH_SERVER_ERROR) ?
                         FILENAME_LENGTH_ERROR_MESSAGE :
                         DEFAULT_ERROR_MESSAGE);
      });
  };

  const renderDuplicateCheckModal = () => {
    if (!newImage || !duplicateImages) return null;

    const formattedImage = { ...newImage, id: parseInt(newImage.id, 10) };

    return (
      <CompareDuplicates
        afterMergeDuplicates={props.afterMergeDuplicates}
        dontMergeCallback={props.dontMergeCallback}
        modelId={props.modelId}
        modelType={props.modelType}
        newImage={formattedImage}
        duplicateImages={duplicateImages}
        isOpen={duplicateCheckModalManager.isOpen}
        close={duplicateCheckModalManager.close}
        updateResources={props.updateResources}
        imageModel={imageModel}
      />
    );
  };

  const getMessage = () => {
    let message = 'Existing Image';

    message += props.fromSlideShow ? ' in Slideshow' : '';

    return message;
  };

  return (
    <Fragment>
      {props.standaloneButton && (
        <button type="button" className={`btn mb5 ${props.btnClasses}`} onClick={modalManager.open}>
          {props.useButtonIcon && <i aria-hidden="true" className="fa fa-pencil-square-o mr5" />}
          {props.buttonText}
        </button>
      )}
      <Modal
        nearlyFullScreen
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        headerText={props.headerText}
      >
        {props.getExistingImages && (
          <div>
            <label htmlFor="toggleExistingImageOff">
              <input
                id="toggleExistingImageOff"
                name="existingImage"
                type="radio"
                onChange={() => setExistingImage(false)}
                checked={!existingImage}
              />
              New Image
            </label>
            <label htmlFor="toggleExistingImageOn">
              <input
                id="toggleExistingImageOn"
                name="existingImage"
                type="radio"
                onChange={() => setExistingImage(true)}
                checked={existingImage}
              />
              {getMessage()}
            </label>
          </div>
        )}
        {existingImage ? (
          <ExistingImagePicker
            afterSubmit={props.afterSubmit}
            closeModal={modalManager.close}
            getExistingImages={props.getExistingImages}
            imageModelId={props.imageModel.id}
            modelId={props.modelId}
            modelType={props.modelType}
          />
        ) : (
          <ImageModelForm
            excludeFields={props.excludeFields}
            submitError={submitError}
            onSubmit={editImage}
            submitting={loading}
            closeModal={modalManager.close}
            imageModel={props.imageModel}
            isAdmin={props.isAdmin}
            fromSlideShow={props.fromSlideShow}
            action={props.headerText}
          />
        )}
      </Modal>
      {renderDuplicateCheckModal()}
    </Fragment>
  );
};

EditImage.propTypes = EditImagePropTypes;
EditImage.defaultProps = EditImageDefaultProps;

export default EditImageWrapper;
