import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import videoPropTypes from 'common/VideoPlayer/Proptypes';
import VideoCard from './VideoCard';
import AddModal from './AddModal';

const VideoManager = ({
  buttonTarget, modelType, modelId, existingModelVideo, buttonClassName, cardLabel
}) => {
  if (!document.querySelector(buttonTarget)) {
    return null;
  }

  const [currentModelVideo, setCurrentModelVideo] = useState(existingModelVideo);
  const ctaText = currentModelVideo ? 'Change Video' : 'Add Video';

  let initialValues = {};
  if (currentModelVideo) {
    initialValues = { video_id: { label: currentModelVideo.video.video_file_name, value: currentModelVideo.video.id } };
  }

  return (
    <Fragment>
      <AddModal
        afterSubmit={setCurrentModelVideo}
        initialValues={initialValues}
        buttonTarget={buttonTarget}
        ctaText={ctaText}
        buttonClassName={buttonClassName}
        modelId={modelId}
        modelType={modelType}
        unique
      />
      <VideoCard
        currentModelVideo={currentModelVideo}
        setCurrentModelVideo={setCurrentModelVideo}
        modelType={modelType}
        modelId={modelId}
        cardLabel={cardLabel}
      />
    </Fragment>
  );
};

VideoManager.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  buttonTarget: PropTypes.string.isRequired,
  cardLabel: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string
  }),
  existingModelVideo: PropTypes.shape({
    id: PropTypes.number,
    video: videoPropTypes
  }),
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired
};

VideoManager.defaultProps = {
  cardLabel: null,
  existingModelVideo: null
};

export default VideoManager;
