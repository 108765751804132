import React from 'react';

const REFERENCE_HEIGHT = 426;

export const scaleValue = (toolbarEl: React.MutableRefObject<HTMLDivElement>, referenceValue: number, maxValue) => {
  const toolbarHeight = toolbarEl?.current?.offsetHeight;

  const scale = (toolbarHeight / REFERENCE_HEIGHT);

  return Math.min(maxValue, scale * referenceValue);
};

export const buttonStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  marginTop: `${scaleValue(toolbarEl, 0.05, 0.25)}rem`,
  marginBottom: `${scaleValue(toolbarEl, 0.05, 0.25)}rem`,
  padding: `${scaleValue(toolbarEl, 0.45, 0.5)}rem`,
});

interface ImgStyleProps {
  toolbarEl: React.MutableRefObject<HTMLDivElement>,
  referenceWidth?: number,
  referenceHeight?: number,
  maxWidth?: number,
  maxHeight?: number,
}

export const imgStyle = ({
  toolbarEl,
  referenceWidth = 16,
  referenceHeight = 16,
  maxWidth = 25,
  maxHeight = 25,
}: ImgStyleProps) => ({
  height: `${scaleValue(toolbarEl, referenceHeight, maxHeight)}px`,
  width: `${scaleValue(toolbarEl, referenceWidth, maxWidth)}px`,
});

export const isSelected = (mode, buttonType, disabled) => {
  if (disabled) return false;

  return mode === buttonType;
};

interface FaStyleProps {
  toolbarEl: React.MutableRefObject<HTMLDivElement>,
  referenceFontSize?: number,
  referenceLineHeight?: number,
  maxFontSize?: number,
  maxLineHeight?: number,
}

export const faStyle = ({
  toolbarEl,
  referenceFontSize = 1.25,
  referenceLineHeight = 1.3,
  maxFontSize = 1.875,
  maxLineHeight = 2.25,
}: FaStyleProps) => {
  const fontSize = scaleValue(toolbarEl, referenceFontSize, maxFontSize);
  const lineHeight = scaleValue(toolbarEl, referenceLineHeight, maxLineHeight);

  return {
    fontSize: `${fontSize}rem`,
    lineHeight: `${lineHeight}rem`,
  };
};

export const popoverStyle = (
  toolbarEl: React.MutableRefObject<HTMLDivElement>,
  referenceWidth = 200,
  maxWidth = 300,
) => ({
  width: `${scaleValue(toolbarEl, referenceWidth, maxWidth)}px`,
  padding: `${scaleValue(toolbarEl, 1.5, 1.75)}rem ${scaleValue(toolbarEl, 0.75, 1)}rem`
});

export const listItemStyle = (
  toolbarEl: React.MutableRefObject<HTMLDivElement>,
) => ({
  fontSize: `${scaleValue(toolbarEl, 0.8, 1)}rem`,
  lineHeight: `${scaleValue(toolbarEl, 1.2, 1.5)}rem`,
});
