import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'common/Tooltip';
import AdministratorsModal from 'common/AdministratorsModal';
import { ConditionalWrap } from 'common/Utils';
import { handleKeyPress, Button } from './Utils';
import I18n from 'modules/i18n';

export const AdminMessage = ({
  adminDashPath
}) => (
  <p>
    {I18n.locale === 'es' ? 'Su distrito está administrando el acceso al programa. Si está registrado automáticamente,' : 'Your district is managing program access. If you are autorostered'}
    {' '}
    <a
      href="https://www.teachtci.com/exclude-teacher"
      target="_blank"
      rel="noopener noreferrer"
    >
      {I18n.locale === 'es' ? 'exclúyase de la asignación automática' : 'exclude yourself from autorostering'}
    </a>
    {I18n.locale === 'es' ? ' y ' : ' and '}
    <a
      href="https://www.teachtci.com/add-remove-program-from-teacher"
      target="_blank"
      rel="noopener noreferrer"
    >
      {I18n.locale === 'es' ? 'administre sus programas.' : 'manage your programs.'}
    </a>
    {' '}
    {I18n.locale === 'es' ? 'De lo contrario, administre su configuración de bloqueo en el' : 'Otherwise, manage your lock settings on the'}
    {' '}
    <a
      href={adminDashPath}
    >
      {I18n.locale === 'es' ? 'panel de administración.' : 'admin dashboard.'}
    </a>
  </p>
);

AdminMessage.propTypes = {
  adminDashPath: PropTypes.string.isRequired
};

export function TeacherMessage({
  getAdminInfoPath
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <p>
        Your district manages your programs. Please contact your
        {' '}
        <a
          className="admin-modal-button"
          role="button"
          tabIndex={0}
          onClick={() => setIsModalOpen(true)}
          onKeyPress={e => handleKeyPress(e, () => setIsModalOpen(true))}
        >
          admins
        </a>
        {' '}
        to add a program.
      </p>

      {isModalOpen && (
        <AdministratorsModal
          closeModal={() => setIsModalOpen(false)}
          getAdminInfoPath={getAdminInfoPath}
          isOpen={isModalOpen}
        />
      )}
    </div>
  );
}

TeacherMessage.propTypes = {
  getAdminInfoPath: PropTypes.string.isRequired
};


export function TooltipMessage({
  getAdminInfoPath, userType, adminDashPath
}) {
  if (userType === 'Admin') {
    return (
      <AdminMessage adminDashPath={adminDashPath} />
    );
  }

  return (<TeacherMessage getAdminInfoPath={getAdminInfoPath} />);
}

TooltipMessage.propTypes = {
  adminDashPath: PropTypes.string.isRequired,
  getAdminInfoPath: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired
};

const BtnLinkWithTooltip = ({
  buttonClasses, disabled, disabledMessage, path, faIcon, buttonText
}) => (
  <div>
    <ConditionalWrap
      condition={disabled}
      wrap={children => (
        <Tooltip
          content={disabledMessage}
          theme="white"
          size="medium"
        >
          {children}
        </Tooltip>
      )}
    >
      <Button
        path={path}
        disabled={disabled}
        classes={buttonClasses}
      >
        <i aria-hidden="true" className={faIcon} /> {buttonText}
      </Button>
    </ConditionalWrap>
  </div>
);

BtnLinkWithTooltip.propTypes = {
  buttonClasses: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.node.isRequired,
  faIcon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

BtnLinkWithTooltip.defaultProps = {
  buttonClasses: undefined,
  disabled: false
};

export default BtnLinkWithTooltip;
