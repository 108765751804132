import create from 'zustand';
import axios, { CancelTokenSource } from 'axios';
import { DEFAULT_FILTERS } from './Filters/filterCategories';
import { ISearchResult, SearchFilters } from './types';

interface ISearchStoreVariables {
  cancelTokenSource: CancelTokenSource;
  clearFilters: () => void;
  error: boolean;
  exclusions: DropdownOption[];
  filters: SearchFilters;
  isLoading: boolean;
  numResults: number;
  results: ISearchResult[];
  setCancelTokenSource: (cancelTokenSource: CancelTokenSource) => void;
  setError: (error: boolean) => void;
  setExclusions: (exclusions: DropdownOption[]) => void;
  setFilters: (error: SearchFilters) => void;
  setIsLoading: (isLoading: boolean) => void;
  setNumResults: (numResults: number) => void;
  setResults: (results: ISearchResult[]) => void;
  setShowAdvanced: (showAdvanced: boolean) => void;
  setSuggestions: (suggestions: string[]) => void;
  showAdvanced: boolean;
  suggestions: string[];
}

const useSearchStore = create<ISearchStoreVariables>(set => ({
  cancelTokenSource: axios.CancelToken.source(),
  clearFilters: () => set({ filters: DEFAULT_FILTERS }),
  error: false,
  exclusions: [],
  filters: { ...DEFAULT_FILTERS },
  isLoading: false,
  numResults: undefined,
  results: [],
  setCancelTokenSource: cancelTokenSource => set({ cancelTokenSource }),
  setError: error => set({ error }),
  setExclusions: exclusions => set({ exclusions }),
  setFilters: filters => set({ filters }),
  setIsLoading: isLoading => set({ isLoading }),
  setNumResults: numResults => set({ numResults }),
  setResults: results => set({ results }),
  setShowAdvanced: showAdvanced => set({ showAdvanced }),
  setSuggestions: suggestions => set({ suggestions }),
  showAdvanced: false,
  suggestions: [],
}));

export default useSearchStore;
