import React, { useContext, useMemo } from 'react';
import cn from '@/utils/cn';
import DndAnswerContext from 'components/common/Context';
import { useDrop } from 'react-dnd';
import I18n from 'i18n-js';
import styles from './DraggableChoicesBank.module.scss';
import DraggableChoice from '../DraggableChoice';
import QuestionBody from './QuestionBody';
import AnswerKeyButton from './AnswerKeyButton';
import ItemTypes from '../../ItemTypes';
import { DraggableItemType } from '../../types';
import SubmissionTextHelper from '../../../../SubmissionTextHelper';
import { sortedDraggableChoicesByPosition } from '../../Builder/DraggableChoiceEditor/Utils';

interface DraggableChoicesBankProps {
  isHorizontallyPositioned?: boolean;
  isComplete?: boolean;
  isSubmittable?: boolean;
  isSubmitDisabled: boolean;
  onSubmit: (isSubmitting: boolean) => any;
  shouldDisplayPrompt?: boolean;
  submissionTextHelper: SubmissionTextHelper;
}

const DraggableChoicesBank = ({
  isComplete = false,
  isHorizontallyPositioned,
  isSubmittable = false,
  isSubmitDisabled,
  onSubmit,
  shouldDisplayPrompt = true,
  submissionTextHelper
}: DraggableChoicesBankProps) => {
  const {
    answerChoiceDispatch,
    draggableChoices,
    answerChoices,
    showAnswerKey,
    useFloatingSubmitBtn,
  } = useContext(DndAnswerContext);

  const remainingChoices = useMemo(() => (
    sortedDraggableChoicesByPosition(draggableChoices)
      .map(([key, _choice]) => key)
      .filter(key => !Object.values(answerChoices).flat().includes(key))
  ), [answerChoices]);

  const [{ highlighted }, drop] = useDrop(() => ({
    accept: ItemTypes.draggableChoice,
    collect: (monitor) => {
      const item: DraggableItemType = monitor.getItem();
      return {
        canDrop: monitor.canDrop() && item && !remainingChoices.includes(item.id),
        highlighted: monitor.isOver() && item && !remainingChoices.includes(item.id),
        isOver: monitor.isOver(),
      };
    },
    drop: (item: DraggableItemType) => {
      answerChoiceDispatch({ payload: { draggableChoiceId: item.id }, type: 'REMOVE' });
    },
  }), [answerChoices]);

  const handleSubmit = () => onSubmit(true);

  const renderAnswerKey = (!showAnswerKey && !isSubmittable) || isComplete;

  return (
    <div
      className={cn(styles.bankContainer, {
        [styles.horizontalBankContainer]: isHorizontallyPositioned
      })}
    >
      <div className={styles.questionInfo}>
        {shouldDisplayPrompt && <QuestionBody ttsEnabled />}
        { renderAnswerKey && <AnswerKeyButton />}
      </div>

      <div
        className={cn(styles.draggableChoicesContainer, {
          [styles.highlighted]: highlighted,
          [styles.horizontalDraggableChoicesContainer]: isHorizontallyPositioned
        })}
        ref={drop}
      >
        {!showAnswerKey && remainingChoices.map(key => (
          <DraggableChoice
            key={key}
            id={key}
            hideDelete={Object.keys(draggableChoices).length === 1}
            isHorizontallyPositioned={isHorizontallyPositioned}
            {...draggableChoices[key]}
          />
        ))}
      </div>
      <div className={cn(styles.submitBtnContainer, { 'tw-my-1.5': isHorizontallyPositioned })}>
        {isSubmittable && !useFloatingSubmitBtn && (
          <>
            {submissionTextHelper.submissionAlert()}
            <button
              className={cn('btn btn--purple', { 'btn--sm': isHorizontallyPositioned })}
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
              type="button"
            >
              {I18n.t('submit')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DraggableChoicesBank;
