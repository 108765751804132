import ReactDOM from 'react-dom';
import React from 'react';
import * as Routes from '@/modules/routes';
import * as States from '@/modules/States';

const columnDefs = () => {
  const positionColumn = (columnIndex: number) => ({
    sortable: false,
    targets: columnIndex,
    visible: false,
  });

  const licenseColumn = (columnIndex: number) => ({
    sortable: false,
    targets: columnIndex,
    title: 'License',
    width: '40%',
  });

  const statesColumn = (columnIndex: number) => ({
    sortable: false,
    targets: columnIndex,
    title: 'Availability By Default',
    createdCell: (td, _cellData, rowData) => {
      const statesButton = (
        <span className={`${rowData.id} actions`}>
          <button
            className="btn btn--light-grey btn--sm"
            type="button"
          >
            <i className="fa fa-globe" aria-hidden /> {' '}
            <span className="selection">{rowData.default_state_message}</span>
          </button>
        </span>
      );

      ReactDOM.render(
        statesButton,
        td,
        () => {
          const statesTrigger = $(`.${rowData.id}`)[0];
          const statesModal = new States.picker({
            id: `${rowData.id}`,
            default_closed: true,
            trigger_element: statesTrigger,
            statesUrl: Routes.plato_api_get_states_program_licenses_path({ program_license_id: rowData.id }),
            submit: 'Submit',
            submitUrl: Routes.plato_api_program_license_states_path({ program_license_id: rowData.id }),
            type: 'program_license_states',
          });
          statesModal.init();
        },
      );
    },
  });

  return [positionColumn(0), licenseColumn(1), statesColumn(2)];
};

export default columnDefs;
