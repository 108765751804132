import React, { Component } from 'react';
import MaterialSelect from '../MaterialSelect';
import Axios from 'axios';
import { inputId, FormInput } from '../Utils';
import { Footer, Spinner } from 'common/Modal';
import Select from 'react-select';
import FormCheckbox from 'common/Forms/FormCheckbox';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    pkgSize: '',
    pkgQty: '',
    boxNum: '',
    locDetail: '',
    venDesc: '',
    venPartNum: '',
    unitPrice: '',
    isLoading: false,
    kitUnitId: null,
    inRefillKit: false
  };

  render() {
    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    );
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`kit-materials-form ${this.state.isLoading ? 'loading' : ''}`}>
        {this.renderMaterialsInput()}
        <FormInput label="package size" stateName="pkgSize" value={this.state.pkgSize}
                   id={this.props.id} inputType="number" onChange={this.handleNumChange.bind(this)} />
        {this.renderUnitsInput()}
        <FormInput label="package quantity" stateName="pkgQty" value={this.state.pkgQty}
                   id={this.props.id} inputType="number" onChange={this.handleNumChange.bind(this)} />
        <FormInput label="box #" stateName="boxNum" value={this.state.boxNum} required={true}
                   id={this.props.id} inputType="number" onChange={this.handleNumChange.bind(this)} />
        <FormInput label="location detail" stateName="locDetail" value={this.state.locDetail}
                   id={this.props.id} onChange={this.handleChange.bind(this)} />
        <FormInput label="vendor description" stateName="venDesc" value={this.state.venDesc}
                   id={this.props.id} onChange={this.handleChange.bind(this)} />
        <FormInput label="vendor part #" stateName="venPartNum" value={this.state.venPartNum}
                   id={this.props.id} onChange={this.handleChange.bind(this)} />
        <FormInput label="unit price" stateName="unitPrice" value={this.state.unitPrice} inputType="number"
                   id={this.props.id} step="0.01" onChange={this.handleNumChange.bind(this)} />
        <FormCheckbox checkboxValue={this.state.inRefillKit} stateName="inRefillKit" id={this.props.id}
                      onChange={this.handleCheckboxChange.bind(this)} checkLabel='In Refill/Consumables Kit'/>
        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  handleCheckboxChange(val, stateName) {
    this.setState({
      [stateName]: val
    })
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleNumChange(e, stateName) {
    if (e.target.value === '' || parseFloat(e.target.value) >= 0) this.handleChange(e, stateName);
  }

  setMaterialId(e) {
    this.setState({
      materialId: e ? e.value : null,
      kitUnitId: e && e.materialUnitId ? e.materialUnitId : ''
    });
  }

  setUnit(e) {
    this.setState({ kitUnitId: e ? e.value : '' });
  }

  _buildFormData() {
    const { programId, materialId, pkgSize,
            pkgQty, boxNum, locDetail,
            venDesc, venPartNum, unitPrice, kitUnitId, inRefillKit } = this.state;

    let formData = new FormData();

    formData.append('program_kit_material[program_id]', programId);
    formData.append('program_kit_material[material_id]', materialId);
    formData.append('program_kit_material[measurement_unit_id]', kitUnitId || '');
    formData.append('program_kit_material[package_size]', pkgSize);
    formData.append('program_kit_material[package_quantity]', pkgQty);
    formData.append('program_kit_material[box_number]', boxNum);
    formData.append('program_kit_material[location_detail]', locDetail);
    formData.append('program_kit_material[vendor_description]', venDesc);
    formData.append('program_kit_material[vendor_part_number]', venPartNum);
    formData.append('program_kit_material[in_refill_kit]', inRefillKit);
    formData.append('program_kit_material[unit_price]', unitPrice);

    return formData;
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    let method = this.props.newRecord ? 'post' : 'put',
        url = this.props.newRecord ? `/admin/programs/${this.props.programId}/program_kit_materials` : `/admin/program_kit_materials/${this.props.id}`,
        callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
        data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({isLoading: false, error: true});
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    })
  }

  renderMaterialsInput() {
    return (
      <div className="form-row">
        <label>Material</label>
        <MaterialSelect name="program_kit_material[material_id]"
                        value={this.state.materialId}
                        onChange={this.setMaterialId.bind(this)}
                        options={this.props.forSelect} />
      </div>
    );
  }

  renderUnitsInput() {
    return(
      <div className="form-row">
        <label className="mt5" htmlFor={inputId({ label: 'units',
          newRecord: this.props.newRecord,
          id: this.props.id })}>
          Unit of Measurement
        </label>
        <Select searchable={true}
                required={true}
                value={this.state.kitUnitId || ''}
                onChange={this.setUnit.bind(this)}
                options={[{ value: '', label: 'None' }].concat(this.props.units)} />
      </div>
    );
  }
}
