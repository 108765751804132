import React, { useState } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from './TwelveDaysOfCleanup.module.scss';
import SlackMessage from './SlackMessage';
import { MessageType, SelectType } from './types';

interface TwelveDaysOfCleanupProps {
  messages: MessageType[];
  cleanupDays: {
    [key: string]: SelectType;
  }
}

const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(day => ({ label: `Day ${day}`, value: `Day ${day}` }));

const TwelveDaysOfCleanup = ({ messages, cleanupDays }: TwelveDaysOfCleanupProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState(cleanupDays);

  const remainingDays = () => {
    const alreadySelectedDays = Object.values(selectedDays).map(day => day.value);

    return DAYS.filter(day => !alreadySelectedDays.includes(day.value));
  };

  const setSelectedDay = (option, id) => {
    const route = option ? Routes.select_message_admin_cleanup_index_path : Routes.unselect_message_admin_cleanup_index_path;
    const params = option ? { message: { id, ...option } } : { message: { id } };

    setSubmitting(true);
    Axios.post(route(params))
      .then(res => setSelectedDays(res.data))
      .catch(e => console.error(e))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <h1>12 Days of Cleanup</h1>
      <div className={styles.messagesContainer}>
        {messages.map(message => (
          <SlackMessage
            key={message.client_msg_id}
            message={message}
            remainingDays={remainingDays()}
            selectedDays={selectedDays}
            setSelectedDay={setSelectedDay}
          />
        ))}
        <a
          className={`btn btn--purple ${submitting && 'disabled'}`}
          href={Routes.generate_csv_admin_cleanup_index_path()}
          rel="noopener noreferrer"
          target="_blank"
        >
          Generate CSV
        </a>
      </div>
    </>
  );
};

export default TwelveDaysOfCleanup;
