const QueryKeys = {
  Assessments: 'Assessments',
  CompletedStatus: 'CompletedStatus',
  Correlations: 'Correlations',
  ImageModels: 'ImageModels',
  SlideShows: 'SlideShows',
  SlideShowQuestionGroups: 'SlideShowQuestionGroups',
  UseTeacherNotes: 'UseTeacherNotes',
  UseClassrooms: 'useClassrooms',
  UseStyleSheets: 'useStyleSheets',
  UseThemes: 'useThemes',
  UseThreeDModel: 'useThreeDModel',
  UseProfessionalDevelopmentResults: 'useProfessionalDevelopmentResults',
} as const;

export default QueryKeys;
