import React from 'react';
import {
  IApiSlideShow,
  IApiUnit,
} from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';
import UnitCheckboxes from '@/components/common/ProfessionalDevelopment/UnitCheckboxes';
import FilterSection from '@/components/common/ProfessionalDevelopment/FilterSection';
import StatusCheckboxes from '@/components/common/ProfessionalDevelopment/StatusCheckboxes';

interface PDFiltersProps {
  units: IApiUnit[];
  slideShowIds: number[];
  setSelectedSlideShows: React.Dispatch<React.SetStateAction<IApiSlideShow[]>>
  statuses: (IApiSlideShow['status'])[];
  setStatuses: React.Dispatch<React.SetStateAction<(IApiSlideShow['status'])[]>>
}

const PDFilters = ({ units, slideShowIds, setSelectedSlideShows, statuses, setStatuses,
}: PDFiltersProps) => (
  <>
    <FilterSection title="Courses">
      {units.map(unit => (
        <UnitCheckboxes
          key={unit.id}
          slideShowIds={slideShowIds}
          setSelectedSlideShows={setSelectedSlideShows}
          unit={unit}
        />
      ))}
    </FilterSection>

    <FilterSection title="Status">
      <StatusCheckboxes statuses={statuses} setStatuses={setStatuses} />
    </FilterSection>
  </>
);

export default PDFilters;
