define(['Interactions/DragDropDraggable', 'Interactions/DragDropDropzone'], function(DragDropDraggable, DragDropDropzone) {
  // Type
// 1: 1 Origin
// 2: Individual Origins

  /**
   * Drag and Drop game (used in slideshow & interactions)
   * @param parent_slide
   * @param type
   * @constructor
   */

  function DragDropGame(parent_slide, type) {
    this.slide_id = parent_slide;
    this.game_type = type || 1;
    this.draggables = [];
    this.dropzones = [];
    this.remember_origin = false;
  }

  DragDropGame.prototype = {

    init: function () {
      if (this.game_type == 2) {
        this.remember_origin = true;
      }
      var this_obj = this;
      $('#' + this.slide_id + ' .dd_answer_js').each(function () {
        var _class = $(this).attr('class');
        var draggable_obj = new DragDropDraggable({
          id: this_obj.parseIdent(_class),
          class: _class,
          slide_id: this_obj.slide_id,
          remember_origin: this_obj.remember_origin,
          $node: $(this)
        });
        draggable_obj.init();
        this_obj.draggables.push(draggable_obj);
      });
      $('#' + this.slide_id + ' .dd_dropzone').each(function () {
        var _class = $(this).attr('class');
        var dropzone_obj = new DragDropDropzone({
          id: this_obj.parseIdent(_class),
          class: _class,
          slide_id: this_obj.slide_id,
          $node: $(this)
        });
        dropzone_obj.init();
        this_obj.dropzones.push(dropzone_obj);
      });

      // Add event listeners
      $('#' + this.slide_id + ' .dd_check_answers').on('click', function (e) {
        e.preventDefault();
        if ($('#' + this_obj.slide_id + ' .dd_dropzone .dd_answer').length == 0) {
          $('#' + this_obj.slide_id + ' .it_dialog_box')
            .text('Please select an answer.')
            .show()
            .delay(2500)
            .fadeOut(100)
        }
        else {
          let onlyCorrect = true;
          let partialCorrect = false;
          let correct = true;
          for (var i = 0; i < this_obj.dropzones.length; i++) {
            var dropzone_id = this_obj.dropzones[i].id,
              $dropzone_selector = this_obj.dropzones[i].$node,
              correct_selection_bool = $dropzone_selector.find('.dd_answer_js.id_' + dropzone_id).length > 0,
              has_answer = $dropzone_selector.find('.dd_answer_js').length > 0;

            if (correct_selection_bool) {
              $dropzone_selector.find('.right_icon').show();
              $dropzone_selector.find('.wrong_icon').hide();
              $dropzone_selector.css('border', '.2em solid #22aa22');
              correct = true;
            }
            else if (!has_answer && $dropzone_selector.hasClass('blank_ok_js')) {
              $dropzone_selector.find('.wrong_icon').hide();
              $dropzone_selector.find('.right_icon').hide();
              $dropzone_selector.css('border', '');
              correct = true;
              partialCorrect = true;
            }
            else {
              $dropzone_selector.find('.wrong_icon').show();
              $dropzone_selector.find('.right_icon').hide();
              $dropzone_selector.css('border', '.2em solid #C10211');
              correct = false;
            }

            onlyCorrect = onlyCorrect && correct;
          }

          if (onlyCorrect && partialCorrect) {
            $(`#${this_obj.slide_id} .show_on_correct`).hide();
            $(`#${this_obj.slide_id} .show_on_incorrect`).hide();
            $(`#${this_obj.slide_id} .show_on_partial_correct`).show();
          }
          else if (onlyCorrect) {
            $(`#${this_obj.slide_id} .show_on_incorrect`).hide();
            $(`#${this_obj.slide_id} .show_on_partial_correct`).hide();
            $(`#${this_obj.slide_id} .show_on_correct`).show();
          }
          else {
            $(`#${this_obj.slide_id} .show_on_correct`).hide();
            $(`#${this_obj.slide_id} .show_on_partial_correct`).hide();
            $(`#${this_obj.slide_id} .show_on_incorrect`).show();
          }
        }
      });
      $('#' + this.slide_id + ' .dd_reset_answers').on('click', function (e) {
        e.preventDefault();
        this_obj.reset();
      });
    },

    reset: function () {
      var this_obj = this,
        $dropzone_node,
        $draggable_node;

      for (var i = 0; i < this.draggables.length; i++) {
        $draggable_node = this_obj.draggables[i].$node;

        $draggable_node.appendTo($('#' + this_obj.draggables[i].origin));
        $draggable_node.css({
          top: 0,
          left: 0
        });
      }

      for (var j = 0; j < this.dropzones.length; j++) {
        $dropzone_node = this_obj.dropzones[j].$node;

        if (this_obj.dropzones[j].gradeable) {
          $dropzone_node.css('border', '');
          $dropzone_node.find('div.wrong_icon').hide();
          $dropzone_node.find('div.right_icon').hide();
        }
      }

      // Hide all elements that are part of drag and drop
      $('.show_on_correct').hide();
      $('.show_on_incorrect').hide();
      $('.show_on_partial_correct').hide();

      // reset counter tables and charts
      // the functions called here are from table-counter-min.js and chart.js hosted on CDN100
      const $counterTable = $('.counter_table');
      if ($counterTable.length && $counterTable.attr('class').includes('reset')) {
        initializeCounterTables();
      }

      if ((typeof counterGraphObjects !== 'undefined') && counterGraphObjects.length && $counterTable.attr('class').includes('reset')) {
        counterGraphObjects.filter(n => n).forEach((graph) => { graph.reset(); graph.render(); });
      }
    },

    parseIdent: function(dd_class) {
      var ident,
          id_regex,
          class_arr;

      id_regex = /id_(\d*)/;
      class_arr = [];

      class_arr = dd_class.split(' ');

      for (var i = 0; i < class_arr.length; i++) {
        if (class_arr[i].match(id_regex)) {
          ident = class_arr[i].match(id_regex)[1];
        }
      }
    return ident;
    }
  };

  return {
    create: function(parent_slide, type) {
      return new DragDropGame(parent_slide, type);
    }
  }
});
