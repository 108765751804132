import I18n from 'i18n-js';
import React, { Component, Fragment } from 'react';
import styles from './ShowUnansweredQuestionsButton.module.scss';

export default class ShowUnansweredQuestionsButton extends Component {
  static getUnansweredCount() {
    return document.querySelectorAll('[data-answered=false]').length;
  }

  static addWrapper(question) {
    const $questionContainer = $(question).parents('.assessment-question-container');

    // Don't wrap if already wrapped:
    if ($questionContainer.parent().hasClass('needs-answer')) {
      return;
    }

    $questionContainer.wrap("<fieldset class='needs-answer'></fieldset>");
    $questionContainer.parent().prepend(`<legend class='needs-answer'>${I18n.t('needs_answer')}</legend>`);
  }

  static removeWrapper(question) {
    const $questionContainer = $(question).parents('.assessment-question-container.answerable');

    $questionContainer.prev('legend.needs-answer').remove();
    $questionContainer.unwrap('fieldset.needs-answer');
  }

  constructor(props) {
    super(props);

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        this.onMutate(mutation);
      });
    });

    this.state = {
      isShowingUnansweredQuestions: false,
      mutationObserver: mutationObserver,
      unansweredCount: ShowUnansweredQuestionsButton.getUnansweredCount()
    };

    this.onMutate = this.onMutate.bind(this);
    this.addMutationListener = this.addMutationListener.bind(this);
    this.showUnansweredQuestions = this.showUnansweredQuestions.bind(this);
  }

  componentDidMount() {
    this.addQuestionAnswerListeners();
  }

  componentWillUnmount() {
    this.state.mutationObserver.disconnect();
  }

  addQuestionAnswerListeners() {
    const $unansweredQuestions = $('[data-answered]');

    $unansweredQuestions.each((_, question) => {
      // Add listener to remove fieldset if data-answered becomes true and update unanswered count:
      this.addMutationListener(question);
    });
  }

  showUnansweredQuestions() {
    if (!this.state.isShowingUnansweredQuestions) {
      this.setState({ isShowingUnansweredQuestions: true });
    }

    const $unansweredQuestions = $('[data-answered=false]');

    $unansweredQuestions.each((_, question) => {
      ShowUnansweredQuestionsButton.addWrapper(question);
    });

    // Jump to the first unanswered question on the page:
    const $markedQuestions = $('.needs-answer');
    if ($markedQuestions.length > 0) {
      $(document).scrollTop($markedQuestions.first().offset().top);
    }
  }

  addMutationListener(element) {
    this.state.mutationObserver.observe(element, { attributeFilter: ['data-answered'], attributes: true });
  }

  onMutate(mutation) {
    this.setState({ unansweredCount: ShowUnansweredQuestionsButton.getUnansweredCount() });

    if (!this.state.isShowingUnansweredQuestions) {
      return;
    }

    const $question = $(mutation.target);

    if ($question.attr('data-answered') === 'true') {
      // Remove wrapper if becomes answered:
      ShowUnansweredQuestionsButton.removeWrapper($question);
    }
    else if ($question.attr('data-answered') === 'false') {
      // Apply wrapper if becomes unanswered:
      ShowUnansweredQuestionsButton.addWrapper($question);
    }
  }

  renderText() {
    return I18n.t('unanswered_question_quantity_label', {quantity: this.state.unansweredCount});
  }

  renderMessage() {
    if (!this.state.unansweredCount || this.state.unansweredCount < 1) {
      return null;
    }

    return (
      <span className={`ml10 ${styles.text}`} data-ignore="1">
        <i aria-hidden="true" className="fa fa-info-circle" />
        {' '}
        {this.renderText()}

        <button
          type="button"
          className="btn btn--md btn--outline-red ml10"
          onClick={this.showUnansweredQuestions}
        >
          {I18n.t('show_me')}
        </button>
      </span>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderMessage()}
      </Fragment>
    );
  }
}
