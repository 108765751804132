import React from 'react';
import PropTypes from 'prop-types';

const ReadingButton = ({ buttonText, sectionIds, sectionType }) => (
  <span>
    <span contentEditable={false} data-button-type="reading" data-section-type={sectionType}>
      <span
        className="btn btn--green reading-btn"
        data-section-ids={sectionIds.join(',')}
        role="button"
        tabIndex={0}
      >
        {buttonText}
      </span>
    </span>
  </span>
);

ReadingButton.propTypes = {
  buttonText: PropTypes.string,
  sectionIds: PropTypes.arrayOf(PropTypes.string),
  sectionType: PropTypes.string,
};

ReadingButton.defaultProps = {
  buttonText: 'Reading',
  sectionIds: [],
  sectionType: 'Lesson',
};

export default ReadingButton;
