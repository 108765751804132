import React, { ReactNode } from 'react';
import I18n from 'modules/i18n';
import styles from './index.module.scss';
import clsx from 'clsx';
import { BorderColors, CardType } from '../types';

type ActionBarProps = {
  children?: React.ReactNode,
};

export const ActionBar = ({ children }: ActionBarProps) => (
  <div className={styles.bar}>
    <div className={styles.actions}>
      {children}
    </div>
  </div>
);

type CardBodyProps = {
  ariaLabel: string,
  children: ReactChildren,
  iconClassNames?: string,
  iconSrc: string,
  linkLabel: string,
  linkHref: string,
  lowerIcon?: ReactNode,
  openInNewWindow?: boolean,
};

export const CardBody = ({
  ariaLabel,
  children,
  iconClassNames,
  iconSrc,
  linkHref,
  linkLabel,
  lowerIcon,
  openInNewWindow,
}: CardBodyProps) => {
  const target = openInNewWindow ? '_blank' : '';

  return (
    <>
      <a
        href={linkHref}
        tabIndex={-1}
        aria-label={ariaLabel}
        target={target}
        aria-hidden
      >
        <div className={styles.header}>
          <img
            alt={ariaLabel}
            src={iconSrc}
            className={`${styles.img} ${iconClassNames}`}
          />
          {lowerIcon && <div className={styles.lowerIcon}>{lowerIcon}</div>}
        </div>
      </a>
      <div className={styles.body}>
        <div className={styles.title}>
          <a
            href={linkHref}
            className={styles.titleLink}
            aria-label={linkLabel}
            target={target}
          >
            {linkLabel}
          </a>
        </div>

        <div className={styles.description}>
          {children}
        </div>
      </div>
    </>
  );
};

CardBody.defaultProps = {
  openInNewWindow: false,
};

type CardProps = {
  archived?: boolean,
  bannerBorderColor: BorderColors,
  label: CardType,
  paddedLabel?: boolean,
  type: string,
  children: ReactChildren
};

const Card = (
  { archived = false, bannerBorderColor, children, label, paddedLabel = false, type }: CardProps
) => {
  const formattedLabel = I18n.t(label).toUpperCase();

  return (
    <section
      id={`${type}-card-container`}
      className={clsx(`card ${styles.card}`, { archived })}
      aria-label={`${type}-card`}
    >
      <div className={`${styles.banner} ${styles[bannerBorderColor]}`}>
        {paddedLabel ?
          <div className="tw-px-2">{formattedLabel}</div>
          :
          formattedLabel
        }
      </div>
      {children}
    </section>
  );
};

export default Card;
