import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'common/Card';
import Modal from 'common/Modal';
import Form from '../CreateNewTestButton/Form';

const CardBody = () => (
  <div className="new-test-card__body new-test-card__body--lg-icon">
    <i className="fa fa-plus fa-2x border--circle--lg text--green" aria-hidden="true" />
    <br />
    <br />
    <strong>Question Bank</strong>
  </div>
);

const Bar = ({ questionCount }) => (
  <div className="new-test-card__footer--green">
    {questionCount} questions
  </div>
);

Bar.propTypes = {
  questionCount: PropTypes.number.isRequired
};

export default class QuestionBankCard extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };

    this.openModal = this.openModal.bind(this);
    this.redirectToQuestionBank = this.redirectToQuestionBank.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  redirectToQuestionBank(data) {
    window.location.href = `${this.props.questionBankPath}&exercise_id=${data.id}&exercise_type=Assessment&filter[exercises][]=Assessment&filter[lessons][]=${this.props.lessonId}`;
  }

  render() {
    return (
      <a
        href="#"
        onClickCapture={this.openModal}
        role="button"
        className="js-prevent-default"
      >
        <Card
          cardBody={<CardBody />}
          bar={<Bar questionCount={this.props.questionCount} />}
          wrapperClass="card new-test-card new-test-card--clickable"
        >
          <Modal
            closeModal={this.closeModal}
            isOpen={this.state.modalIsOpen}
            headerText="Create New Test"
          >
            <Form
              ajaxPath={this.props.ajaxPath}
              name={`${this.props.lessonTitle} Test`}
              afterSubmit={this.redirectToQuestionBank}
            />
          </Modal>
        </Card>
      </a>
    );
  }
}

QuestionBankCard.propTypes = {
  ajaxPath: PropTypes.string.isRequired,
  lessonId: PropTypes.number.isRequired,
  lessonTitle: PropTypes.string.isRequired,
  questionBankPath: PropTypes.string.isRequired,
  questionCount: PropTypes.number.isRequired
};
