import React from 'react';
import styles from './index.module.scss';
import Tooltip from '../Tooltip';

interface GradeShortcutProps {
  comingSoon?: boolean;
  gradesLink: string;
  isTemporary?: boolean;
}

const GradeShortcut = ({ comingSoon, gradesLink, isTemporary }: GradeShortcutProps) => {
  const tooltipContent = comingSoon ? 'Grading coming soon' : 'Grades';

  const ComingSoonButton = () => (
    <button type="button" className={styles.icon} disabled>
      <i className={`fa fa-bar-chart ${styles.disabled}`} aria-hidden="true" />
      <span className="sr-only">{tooltipContent}</span>
    </button>
  );

  const GradesLink = () => (
    <button
      type="button"
      className={`${styles.icon} ${isTemporary && styles.disabled}`}
      disabled={isTemporary}
    >
      <a href={gradesLink} className={styles.gradesLink}>
        <i className={`fa fa-bar-chart ${isTemporary && styles.disabled}`} aria-hidden="true" />
        <span className="sr-only">{tooltipContent}</span>
      </a>
    </button>
  );

  return (
    <Tooltip
      content={tooltipContent}
      size="medium"
      distance="15"
      placement="bottom"
      interactive={false}
    >
      <span>
        {comingSoon ? <ComingSoonButton /> : <GradesLink />}
      </span>
    </Tooltip>
  );
};

GradeShortcut.defaultProps = {
  comingSoon: false,
  isTemporary: false,
};

export default GradeShortcut;
