import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import TCIOnlyLock from 'common/TCIOnlyLock';
import styles from './StudentsTable.module.scss';

export default class DeleteAllStudents extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { modalOpen: false, submitting: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  handleDelete() {
    this.setState({ submitting: true });

    Axios
      .post(this.props.path)
      .then(() => this.closeModal())
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false });
      });
  }

  _renderModalDeleteButton() {
    let buttonText = 'Yes, delete ALL students';

    if (this.state.submitting) {
      buttonText = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
          Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--red"
        onClick={this.handleDelete}
        disabled={this.state.submitting}
      >
        {buttonText}
      </button>
    );
  }

  render() {
    return (
      <div>
        <button className={`btn btn--outline-red ${styles.deleteAllStudents}`} onClick={this.openModal}>
          Delete ALL Students <TCIOnlyLock />
        </button>

        <Modal
          closeModal={this.closeModal}
          headerText="Delete ALL Students"
          isOpen={this.state.modalOpen}
        >
          <p>
            Are you sure? This will delete ALL students and their saved work!
            This process will start at or after 3pm PST.
          </p>

          <hr />

          <footer>
            <button className="btn btn--link-purple" onClick={this.closeModal}>
              Cancel
            </button>

            {this._renderModalDeleteButton()}
          </footer>
        </Modal>
      </div>
    );
  }
}
