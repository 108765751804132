import React from 'react';
import * as Routes from '@/modules/routes';
import { ISearchResult } from './types';
import Result, { ImageModel } from './Result';
import { FILTER_CATEGORIES } from './Filters/filterCategories';
import { useParams } from 'react-router-dom';

const ComponentMap = {
  ImageModel,
  SectionReadingLevelTranslation: Result,
  ReferenceMaterialTranslation: Result,
  Slide: Result,
};

const SearchResult = ({
  body, category, id, image_url, klass, locale, path, position, program, title, location, search_id,
}: ISearchResult) => {
  const filterCategory = FILTER_CATEGORIES.find(filter => filter.value === category)?.label;
  const Component = ComponentMap[klass];
  const { programId } = useParams();

  return (
    <div
      className="tw-flex tw-flex-col card tw-p-2 tw-my-3 tw-text-base"
      role="listitem"
      aria-label={`Search result ${position}: ${title}`}
    >
      <span dangerouslySetInnerHTML={{ __html: program }} />
      <span>{filterCategory}{location ? ` - ${location}` : ''}</span>
      <span>
        <a
          href={path.show}
          target="_blank"
          dangerouslySetInnerHTML={{ __html: title }}
          onClick={() => {
            const data = new FormData();

            data.append('search_id', search_id.toString());
            data.append('klass', klass);
            data.append('klass_id', id.toString());
            data.append('program_id', programId);

            navigator.sendBeacon(Routes.plato_api_convert_path(), data);
          }}
        />
        {path.edit && (
          <a
            href={path.edit}
            target="_blank"
            aria-label="Edit search result"
          >
            <i className="fa fa-pencil tw-ml-1" aria-hidden="true" />
            <span className="sr-only">Edit search result</span>
          </a>
        )}
      </span>
      <Component
        body={body}
        category={category}
        id={id}
        image_url={image_url}
        klass={klass}
        locale={locale}
        path={path}
        position={position}
        program={program}
        title={title}
        location={location}
        search_id={search_id}
      />
    </div>
  );
};

export default SearchResult;
