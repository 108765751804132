import React, { useLayoutEffect, useState } from 'react';
import Answer from '../index';
import styles from '../DraggableChoicesBank/DraggableChoicesBank.module.scss';
import Modal from '../../../../../Modal';
import { DragAndDropQuestionType } from '../../types';

interface GradingViewProps {
  locale: Locale;
  parentContainerId: string;
  question: DragAndDropQuestionType;
  studentId?: string;
  lessonId?: number;
}


const AnswerKeyButton = ({ locale, parentContainerId, question, studentId = null, lessonId = null }: GradingViewProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [parentNode, setParentNode] = useState(null);

  useLayoutEffect(() => {
    setParentNode(document.querySelector('#page_container'));

    return () => setParentNode(null);
  }, []);

  if (!parentNode) return null;


  return (
    <>
      <button
        id={`${parentContainerId}Button`}
        className={`${styles.thumbnailStyle}`}
        onClick={() => setIsOpen(prev => !prev)}
        type="button"
      >
        <Answer
          gradingView
          locale={locale}
          parentContainerId={`${parentContainerId}Button`}
          question={question}
          showAnswerKey
          studentId={studentId}
          lessonId={lessonId}
        />
      </button>
      <Modal
        id={`drag-and-drop-answer-modal-${question.id}`}
        overlayClassName={styles.answerKeyModal}
        closeModal={() => setIsOpen(false)}
        headerClass={styles.answerKeyModalHeader}
        headerText=""
        isOpen={isOpen}
        nearlyFullScreen
        parentSelector={() => parentNode}
      >
        <Answer
          gradingView
          locale={locale}
          parentContainerId={`drag-and-drop-answer-modal-${question.id}`}
          question={question}
          showAnswerKey
          studentId={studentId}
          lessonId={lessonId}
        />
      </Modal>
    </>
  );
};

export default AnswerKeyButton;
