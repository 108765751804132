import React from 'react';
import cn from '@/utils/cn';
import I18n from '@/modules/i18n';
import useDrawingContext from '../store/useDrawingContext';
import { faStyle, scaleValue } from './styles';

const savingTextStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  fontSize: `${scaleValue(toolbarEl, 0.675, 0.875)}rem`,
  lineHeight: `${scaleValue(toolbarEl, 1, 1.25)}rem`,
});

const placeholderBoxStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  height: `${scaleValue(toolbarEl, 40, 45)}px`,
});

const SavingIndicator = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const answerId = useDrawingContext(store => store.answerId);
  const autoSaveQueued = useDrawingContext(store => store.autoSaveQueued);
  const saving = useDrawingContext(store => store.saving);
  const builder = useDrawingContext(store => store.builder);

  const savingOrQueued = saving || autoSaveQueued;

  if ((!builder && !answerId) && !saving) return null;

  return (
    <div>
      {/* placeholder so saved message doesn't overlap with small screens */}
      <div style={placeholderBoxStyle(toolbarEl)} />
      <div className={cn(
        'tw-absolute tw-w-full tw-left-0 tw-bottom-2 tw-text-xl',
        {
          'tw-text-gray-500': savingOrQueued,
          'tw-text-green': !savingOrQueued,
        },
      )}
      >
        <p className="tw-text-center tw-mb-0 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <i
            className="fa fa-check"
            style={faStyle({
              toolbarEl,
              referenceFontSize: 1.2,
              referenceLineHeight: 1.3,
              maxFontSize: 1.5,
              maxLineHeight: 2,
            })}
          />
          <span style={savingTextStyle(toolbarEl)}>
            {savingOrQueued ? `${I18n.t('saving')}…` : I18n.t('saved')}
          </span>
        </p>
      </div>
    </div>
  );
};

export default SavingIndicator;
