import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'react-cookies';
import TCIOnlyLock from 'common/TCIOnlyLock';
import styles from './Tab.module.scss';

const subTabsShape = {
  active: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  showNewLabel: PropTypes.bool,
  sublabel: PropTypes.string,
  target: PropTypes.string,
  tciOnly: PropTypes.bool,
  url: PropTypes.string
};

export default class Tab extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    colorMode: PropTypes.string.isRequired,
    currentProgramId: PropTypes.number,
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    initiallyExpanded: PropTypes.bool,
    label: PropTypes.string.isRequired,
    lineAbove: PropTypes.bool,
    showNewLabel: PropTypes.bool,
    sublabel: PropTypes.string,
    subtabs: PropTypes.arrayOf(PropTypes.shape(subTabsShape)),
    target: PropTypes.string,
    tciOnly: PropTypes.bool,
    url: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: '',
    currentProgramId: null,
    id: null,
    initiallyExpanded: false,
    lineAbove: false,
    showNewLabel: false,
    sublabel: '',
    subtabs: null,
    target: null,
    tciOnly: false
  };

  constructor(props) {
    super(props);
    this.state = { expanded: this.props.initiallyExpanded };
    this.toggleCollapse = this.toggleCollapse.bind(this);

    this._updateExpandedTabsCookie = this._updateExpandedTabsCookie.bind(this);
  }

  _activeClass() {
    return this.tabIsActive() ? styles.active : '';
  }

  _updateExpandedTabsCookie() {
    const expandedTabsData = Cookie.load('expandedTabs') || {};
    expandedTabsData[this.props.label] = this.state.expanded;
    Cookie.save('expandedTabs', expandedTabsData, { path: '/' });
  }

  toggleCollapse(e) {
    if (e.keyCode && e.keyCode !== 13) return;
    e.preventDefault();

    this.setState(prevState => ({ expanded: !prevState.expanded }), this._updateExpandedTabsCookie);
  }

  tabIsActive() {
    return this.props.active && !this.props.subtabs;
  }

  subTabsGroupId() {
    return `left-nav-sub-tabs-${this.props.index}`;
  }

  renderSubtabs() {
    if (!this.props.subtabs) {
      return null;
    }

    return this.props.subtabs.map(subTab => (
      <li
        id={subTab.id}
        className={`${styles.subTab} ${styles[this.props.colorMode]} ${subTab.active ? styles.active : ''}`}
        key={`${this.props.label}-${subTab.label}`}
      >
        <a
          href={subTab.url}
          className={styles.label}
          aria-current={subTab.active ? 'page' : ''}
          target={subTab.target}
        >
          <div>
            {subTab.label} {subTab.tciOnly && <TCIOnlyLock />}
            {subTab.showNewLabel && <span className={styles.newLabel}>NEW</span>}
            <div className={styles.sublabel}>{subTab.sublabel}</div>
          </div>
        </a>
      </li>
    ));
  }

  renderLabel() {
    if (this.props.subtabs && this.props.lineAbove) {
      return (
        <span className={styles.parentTab}>
          {this.props.label}
        </span>
      );
    }

    return this.props.label;
  }

  renderParentTab() {
    return (
      <div
        aria-controls={this.subTabsGroupId()}
        aria-current={this.tabIsActive() ? 'page' : ''}
        aria-expanded={!!this.props.subtabs && this.state.expanded}
        aria-haspopup={!!this.props.subtabs}
        aria-label={`${this.props.label} tab`}
        className={styles.label}
        onClick={this.toggleCollapse}
        onKeyDown={this.toggleCollapse}
        role="button"
        tabIndex="0"
      >
        <div>
          {this.renderLabel()}
          {this.props.tciOnly && <TCIOnlyLock />}
          <div className={styles.sublabel}>{this.props.sublabel}</div>
        </div>
        <div
          tabIndex="-1"
          className={styles.arrow}
        >
          <i
            className={`fa ${this.state.expanded ? 'fa-caret-down' : 'fa-caret-right'} ${styles.caret}`}
            aria-hidden="true"
          />
          <span className={styles.circle} />
        </div>
      </div>
    );
  }

  renderRegularLink() {
    return (
      <a
        href={this.props.url}
        className={styles.label}
        aria-label={`${this.props.label} tab`}
        aria-current={this.tabIsActive() ? 'page' : ''}
        aria-haspopup={!!this.props.subtabs}
        aria-expanded={!!this.props.subtabs && this.state.expanded}
        aria-controls={this.subTabsGroupId()}
        target={this.props.target}
        tabIndex="0"
      >
        <div>
          {this.renderLabel()}
          {this.props.tciOnly && <TCIOnlyLock />}
          <div className={styles.sublabel}>{this.props.sublabel}</div>
        </div>
      </a>
    );
  }

  render() {
    return (
      <>
        {this.props.lineAbove && <hr className={styles.lineSeparator} />}
        <ul className={`${this.props.currentProgramId ? styles.newSideNav : ''}`}>
          <li
            id={this.props.id}
            className={`${styles.tab} ${styles[this.props.colorMode]} ${this.props.className} ${this._activeClass()}`}
          >
            {this.props.subtabs ? this.renderParentTab() : this.renderRegularLink()}
          </li>

          <li>
            <ul
              id={this.subTabsGroupId()}
              className={this.state.expanded ? 'sub_tabs' : 'sub_tabs hidden'}
            >
              {this.renderSubtabs()}
            </ul>
          </li>
        </ul>
      </>
    );
  }
}
