import React from 'react';
import styles from '../../Navigator.module.scss';
import LockedSlideIcon from './LockedSlideIcon';
import useSlideConditions from '../../../useSlideConditions';
import { ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';

interface NavigationButtonProps {
  slide: ISlideOrSlideGroup;
  disabled: boolean;
  onClick: () => void;
  label: 'Next Slide' | 'Previous Slide';
  faIcon: 'fa-chevron-left' | 'fa-chevron-right';
}

const NavigationButton = ({ slide, disabled, onClick, label, faIcon }: NavigationButtonProps) => {
  const { isSlideLocked } = useSlideConditions();

  if (isSlideLocked(slide)) {
    return (
      <span className={styles.navigatorButton}>
        <LockedSlideIcon slide={slide} />
      </span>
    );
  }

  return (
    <button
      aria-disabled={disabled}
      aria-label={label}
      className={styles.navigatorButton}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <span aria-hidden="true" className={`fa ${faIcon}`} />
    </button>
  );
};

export default NavigationButton;
