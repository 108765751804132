import React from 'react';
import I18n from 'i18n-js';
import printerIcon from 'images/icons/printer-icon.svg';
import arrowIcon from 'images/icons/arrow.svg';
import { twMerge } from '@/utils';
import { InitialAssignableType } from '@/components/shared/LessonOverview/Cards/AssignButton';
import { Program } from '@/components/shared/LessonOverview/types';
import Tooltip from '@/components/common/Tooltip';
import AssignButton from './AssignButton';

interface ActionBarProps {
  href: string;
  initialValues: InitialAssignableType;
  isAssigned: boolean;
  lessonId: number,
  program: Program,
  printHref?: string,
  showPrintButton?: boolean,
  stafferId?: number,
}

const ActionBar = ({
  href, initialValues, isAssigned, lessonId, printHref = '', program, showPrintButton = false, stafferId,
}: ActionBarProps) => {
  const CIRCLE_ICON_CLASS = 'tw-border-none tw-rounded-[50%] tw-text-2xl tw-flex tw-justify-center tw-items-center ' +
    'tw-cursor-pointer tw-mr-[10px] tw-h-11 tw-w-11';

  return (
    <div className="tw-py-2.5 tw-px-4 tw-border-t tw-border-solid tw-border-med-grey tw-mx-2">
      <div className="tw-h-auto tw-float-left tw-text-center tw-leading-[30px] tw-flex tw-items-center">
        <a
          href={href}
          className={twMerge(CIRCLE_ICON_CLASS, 'tw-bg-tci-purple')}
          target=""
        >
          <img
            alt=""
            className={`
              tw-w-[23.69px] tw-h-[23.69px] tw-fill-tci-purple
            `}
            src={arrowIcon}
          />
        </a>
        <AssignButton
          circle
          initialValues={initialValues}
          isAssigned={isAssigned}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
        />
        {showPrintButton && (
          <Tooltip
            content={I18n.t('print_text')}
            size="medium"
            distance="15"
            hideOnClick
          >
            <a
              className={twMerge(CIRCLE_ICON_CLASS, 'tw-bg-[#34025B]/[.05]')}
              href={printHref}
              target="_blank"
            >
              <img
                alt="Printer"
                className="tw-w-[26px] tw-h-[26px]"
                src={printerIcon}
              />
            </a>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
