import React, { Children, useContext } from 'react';
import cn from '@/utils/cn';
import { useDrop } from 'react-dnd';
import DndAnswerContext from 'components/common/Context';
import styles from './DropZone.module.scss';
import ItemTypes from '../../ItemTypes';
import { DraggableItemType } from '../../types';
import { positionedContainerStyle, sizedContainerStyle } from '../../utils';
import useDragAndDropStore from '../../useDragAndDropStore';

interface DropZoneType {
  children?: ReactChildren;
  dropZoneRef?: any;
  height?: number;
  id: string;
  isDropDisabled?: boolean;
  left: number;
  shape?: string;
  title: string;
  top: number;
  vocabId?: number;
  width?: number;
}

const DropZone = ({
  children,
  isDropDisabled = false,
  id,
  left,
  shape,
  title,
  top,
  width,
  height,
  dropZoneRef,
  vocabId,
}: DropZoneType) => {
  const label = `Drop zone ${id.toUpperCase()}`;
  const { answerChoices, answerChoiceDispatch, correctAnswers, hasTransparentDropzones } = useContext(DndAnswerContext);
  const isGloballyDragging = useDragAndDropStore(state => state.isGloballyDragging);
  const hasNoDroppedItems = !children || Children.count(children) === 0;

  const [{ highlighted }, drop] = useDrop(() => ({
    accept: ItemTypes.draggableChoice,
    canDrop: () => !isDropDisabled,
    collect: (monitor) => {
      const item: DraggableItemType = monitor.getItem();

      return {
        canDrop: monitor.canDrop() && item && !(answerChoices[id] || []).includes(item.id),
        highlighted: monitor.isOver() && item && !(answerChoices[id] || []).includes(item.id),
        isOver: monitor.isOver(),
      };
    },
    drop: (item: DraggableItemType) => {
      answerChoiceDispatch({ payload: { draggableChoiceId: item.id, dropZoneId: id }, type: 'ADD' });
    },
  }), [children, isDropDisabled]);


  const needsMoreAnswers = () => {
    const numChoices = answerChoices[id]?.length;
    const numCorrectAnswers = correctAnswers[id]?.length;

    return !!numChoices && !!numCorrectAnswers && (numChoices < numCorrectAnswers);
  };

  return (
    <div
      style={positionedContainerStyle(left, top, width, (vocabId && shape === 'rectangle') ? 8 : height)}
      role="button"
      aria-label={label}
      tabIndex={0}
      ref={drop}
    >
      <div
        ref={dropZoneRef}
        className={cn(
          styles.dropZone, {
            [styles.moreAnswers]: needsMoreAnswers(),
            [styles.highlighted]: highlighted,
            [styles.transparentBackgroundDragging]: isGloballyDragging && hasTransparentDropzones,
            [styles.transparentBackgroundNotDragging]: !isGloballyDragging && hasTransparentDropzones,
            [styles.circle]: shape === 'circle',
            [styles.square]: shape !== 'circle',
          },
        )}
        style={sizedContainerStyle(false)}
      >
        <div className={styles.dropZoneContent}>
          {hasNoDroppedItems && (
            <div className={styles.label}>
              {title}
            </div>
          )}
          <div
            id={`test-${id}`}
            role="list"
            className={styles.dropZoneContentChildren}
            aria-label={`answer choices for Drop zone ${id}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
