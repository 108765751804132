import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { allChecked, handleCheckboxChange, handleBulkCheckboxChange, Slide } from './utils';
import Checkbox from '../../../../common/Checkbox';
import DataTable from '../../../../common/DataTable';

type SelectSlideTableProps = {
  slides: Slide[];
  selectedSlideIds: any[],
  setSelectedSlideIds: (data: object) => void;
  setValue: (string, any) => void;
};

const SelectSlideTable = ({ slides, selectedSlideIds, setSelectedSlideIds, setValue }: SelectSlideTableProps) => {
  const [bulkChecked, setBulkChecked] = useState('none');

  const renderBulkCheckbox = () => {
    if (document.getElementById('select-all')) {
      const label = (
        <span className="sr-only">
          {(bulkChecked ? 'Unselect' : 'Select').concat(' all records on this page')}
        </span>
      );

      ReactDOM.render(
        <Checkbox
          id="select-all"
          checked={bulkChecked === 'all'}
          input={{ name: 'select-all', value: 'select-all' }}
          indeterminate={bulkChecked === 'some'}
          handleChange={() => handleBulkCheckboxChange({ bulkChecked, setSelectedSlideIds, setBulkChecked, slides })}
          label={label}
        />,
        document.getElementById('select-all'),
      );
    }

    return null;
  };

  const renderCheckboxForRow = ({ checked, id, target }) => {
    const label = (
      <span className="sr-only">
        {checked ? 'Unselect row' : 'Select row'}
      </span>
    );

    ReactDOM.render(
      <Checkbox
        id={`bulk-slide-${id}`}
        input={{ name: 'bulk-slide', value: id.toString() }}
        checked={checked}
        handleChange={e => handleCheckboxChange(e, selectedSlideIds, setSelectedSlideIds)}
        label={label}
      />,
      target,
    );
  };

  useEffect(() => {
    renderBulkCheckbox();
  }, [bulkChecked]);

  useEffect(() => {
    slides.forEach((slide) => {
      const target = document.getElementById(`bulk-slide-${slide.id}`);

      renderCheckboxForRow({
        checked: selectedSlideIds.includes(parseInt(slide.id as string, 10)),
        id: slide.id,
        target,
      });
    });

    setValue('slideIds', selectedSlideIds.join(', '));

    const bulkCheck = allChecked({ selectedSlideIds, slides });
    setBulkChecked(bulkCheck);
  }, [selectedSlideIds]);

  return (
    <DataTable
      initComplete={renderBulkCheckbox}
      columns={['id', 'position', 'title']}
      columnDefs={[
        {
          targets: 0,
          title: '<div id="select-all" />',
          sortable: false,
          searchable: false,
          data: null,
          createdCell: (td, cellData, rowData) => {
            $(td).attr({
              id: `bulk-slide-${rowData.id}`,
              'data-title': rowData.title,
            });

            renderCheckboxForRow({
              checked: selectedSlideIds.includes(rowData.id),
              id: rowData.id,
              target: td,
            });
          },
        },
        {
          targets: 1,
          title: 'Slide #',
        },
        {
          targets: 2,
        },
      ]}
      defaultOrder={[1, 'asc']}
      handleResourcesManually
      info={false}
      paging={false}
      resources={slides}
    />
  );
};

export default SelectSlideTable;
