import React, { useEffect, useState } from 'react';
import Cookie from 'react-cookies';
import { redirectLinks } from '../../../../modules/ExternalRedirect';
import styles from '../HeaderNotifications.module.scss';

interface RetiringProgramHeaderProps {
  expiringYear: number,
  programRetirementYear: number,
  user: {
    canSeeRetiringProgramMessages: boolean,
    type: string,
  }
}

const RetiringProgramHeader = ({ expiringYear, programRetirementYear, user }: RetiringProgramHeaderProps) => {
  const [hideHeader, setHideHeader] = useState(true);
  const thirtyDaysInSeconds = 2592000;

  useEffect(() => {
    redirectLinks(document.body);
    // Check if notification has been closed before.
    const headerCookie = Cookie.load('closedRetiringProgramHeader') || false;

    // Hide header if
    // --- teacher has manually closed header aka. cookie is present
    // --- user is not a teacher or admin
    // --- user cannot see retiring messages/ flag is off
    // --- program has a retirement year
    const headerClosed = (headerCookie === 'true');
    const shouldSeeMessage = (user.type === 'Teacher' || user.type === 'Admin');
    const programExpiring = (programRetirementYear === expiringYear && !!programRetirementYear);
    setHideHeader(headerClosed || !shouldSeeMessage || !programExpiring || !user.canSeeRetiringProgramMessages);
  }, []);

  const closeNotification = () => {
    Cookie.save('closedRetiringProgramHeader', true, {
      maxAge: thirtyDaysInSeconds,
      path: '/',
    });
    setHideHeader(true);
  };

  return (
    <header className={`with_left_nav ${styles.retiringProgramHeader} ${hideHeader ? styles.hide : ''}`}>
      <span className={`site-banner ${styles.retiringProgramMessage}`}>
        <div></div>
        <div className="dsp-flex">
          <i className="fa fa-exclamation-triangle" />
          <p>This edition will be unavailable after June 30, {expiringYear}.&nbsp;
            <a href="https://teachtci.com/retiring-program" target="_blank" rel="noopener noreferrer">Learn more</a>
            &nbsp;about available upgrades.
          </p>
        </div>
        <button className={styles.closeHeaderButton} onClick={() => closeNotification()}>&times;</button>
      </span>
    </header>
  );
};

export default RetiringProgramHeader;
