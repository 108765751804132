import React, { useState } from 'react';
import Axios from 'axios';
import { useFormContext, SubmitHandler, FormProvider, useForm } from 'react-hook-form';
import { Footer } from 'components/common/Modal';
import useCredentialStore from '@/components/admin/integrations/RosteringOverview/AssignmentAndGrades/useCredentialStore';
import { deleteOAuthCredential, updateLtiKey, createLtiKey } from '../queries';

const LMS_PROVIDER_ID = {
  canvas: 1,
  canvas_1_3: 1,
  schoology: 3,
} as const;

interface IFormData {
  lmsType: string;
}

type CredentialsType = {
  id: number;
  subscriber_id: number;
  deployment_id: string;
  client_id?: string;
};

export interface LtiConfigurationProps {
  initialLmsType?: 'canvas' | 'schoology' | 'canvas_1_3';
  setCredentials?: Function,
  setLmsType: Function,
  credentials?: CredentialsType,
  previous?: Function;
  next?: Function;
  subscriberId: number;
  lmsType: 'canvas' | 'schoology' | 'canvas_1_3';
}

interface LtiConfigurationBaseProps extends LtiConfigurationProps {
  disabled: boolean;
  children: ReactChildren;
}

const LtiConfiguration = ({
  subscriberId, next, previous, setLmsType, disabled, children, lmsType, initialLmsType,
}: LtiConfigurationBaseProps) => {
  const ltiCredentials = useCredentialStore(state => state.ltiCredentials);
  const canvasCredentials = useCredentialStore(state => state.canvasCredentials);
  const setStep = useCredentialStore(state => state.setStep);
  const submitting = useCredentialStore(state => state.submitting);
  const setSubmitting = useCredentialStore(state => state.setSubmitting);
  const { handleSubmit } = useFormContext();

  const back = () => {
    setLmsType(null);
    setStep(1);
    previous();
  };

  const submitCallback = () => {
    setStep(2);
    setSubmitting(false);
    next();
  };

  const createNewLtiKey = (formData: IFormData) => (
    createLtiKey(subscriberId, { ...formData, subscriber_id: subscriberId, lms_provider_id: LMS_PROVIDER_ID[lmsType] })
      .then(submitCallback).catch((e) => { console.error(e); })
  );

  const updateLtiKeyAndDeleteOAuthCredential = (formData: IFormData) => (
    Axios.all([
      updateLtiKey(subscriberId, { ...formData, id: ltiCredentials.id, lms_provider_id: LMS_PROVIDER_ID[lmsType] }),
      deleteOAuthCredential(subscriberId, canvasCredentials.id),
    ]).then(submitCallback).catch((e) => { console.error(e); })
  );

  const updateExistingLtiKey = (formData: IFormData) => (
    updateLtiKey(subscriberId, { ...formData, id: ltiCredentials.id, lms_provider_id: LMS_PROVIDER_ID[lmsType] })
      .then(submitCallback).catch((e) => { console.error(e); })
  );

  const onSubmit: SubmitHandler<IFormData> = (formData: IFormData) => {
    setSubmitting(true);

    if (!initialLmsType) return createNewLtiKey(formData);
    if (initialLmsType === 'canvas' && lmsType !== 'canvas') return updateLtiKeyAndDeleteOAuthCredential(formData);
    if (initialLmsType !== 'canvas') return updateExistingLtiKey(formData);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {children}

      <Footer
        secondaryButtonText="Back"
        secondaryButtonCallback={back}
        primaryButtonText="Next"
        primaryButtonDisabled={disabled || submitting}
      />
    </form>
  );
};

export const formWrapper = (Component: React.ComponentType<any>) => (props: LtiConfigurationProps) => {
  const methods = useForm({ mode: 'onChange' });

  return (
    <FormProvider {...methods}>
      <Component {...props} />
    </FormProvider>
  );
};

export default LtiConfiguration;
