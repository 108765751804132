import React from 'react';
import * as Routes from '@/modules/routes';
import GradeCell from '@/components/staffer/Scores/GradesTable/Table/Cells/GradeCells/index';
import { IStatus } from '@/components/staffer/Scores/GradesTable/types';
import { COLOR_LEGEND } from '@/components/staffer/Scores/GradesTable/variables';
import useReadingGameExerciseResults
  from '@/components/staffer/Scores/GradesTable/hooks/queries/exerciseResults/useReadingGameExerciseResults';
import useReadingGames from '@/components/staffer/Scores/GradesTable/hooks/queries/useReadingGames';
import useGradesTableStore from '@/components/staffer/Scores/GradesTable/store/useGradesTableStore';
import { roundScore } from './utils';

const ReadingCheckGradeCell = ({ studentId }: { studentId: number }) => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const lowScoresSettings = useGradesTableStore(state => state.lowScoresSettings);
  const { data: exerciseResults } = useReadingGameExerciseResults();
  const { data: readingGames } = useReadingGames(lesson?.id);

  const exerciseResultsForStudent = exerciseResults.filter(er => er.student_id === studentId);
  const resultsWithScore = exerciseResultsForStudent.filter(er => er.score !== undefined && er.score !== null);

  const inProgress = exerciseResultsForStudent.length > 0;
  const allCompleted = resultsWithScore.length === readingGames.length && resultsWithScore.some(er => !!er.completed_at)

  const path = Routes.grade_by_student_shared_question_answers_path(studentId, {
    program_id: programId,
    classroom_id: classroom.id,
    lesson_id: lesson.id,
    activity_type: 'reading_game',
  });

  const readingCheckStatus = () => {
    const status: IStatus = {
      color: '',
      text: '-',
    };

    if (!exerciseResultsForStudent) return status;

    const totalScore = roundScore(exerciseResultsForStudent.reduce((acc, cur) => acc + parseFloat(cur.score) || 0, 0));

    if (totalScore && allCompleted) {
      const maxScore = readingGames.reduce((acc, cur) => acc + (cur.max_points || 0), 0);
      const lowScoreThreshold = maxScore * lowScoresSettings.threshold / 100;
      const shouldHighlight = lowScoresSettings.enabled && totalScore <= lowScoreThreshold;

      status.text = totalScore;
      status.color = shouldHighlight ? COLOR_LEGEND.low_score : COLOR_LEGEND.completed;
    } else if (inProgress) {
      status.text = totalScore;
      status.color = COLOR_LEGEND.in_progress;
    }

    return status;
  };

  const updatedSinceGraded = exerciseResultsForStudent.filter(er => er.updated_since_graded).length > 0;

  return (
    <GradeCell
      id={`reading-checks-status-${studentId}-score`}
      status={readingCheckStatus()}
      url={path}
      updatedSinceGraded={updatedSinceGraded}
    />
  );
};

export default ReadingCheckGradeCell;
