import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select-5';
import ILicense from '@/components/interfaces/License';
import useProgramAccessStore from '../ProgramAccessTable/useProgramAccessStore';

interface OptionType {
  label: string;
  value: number;
}

interface UseLicenseSelectProps {
  initialLicenseId: number | undefined;
}

export const useLicenseSelect = ({ initialLicenseId }: UseLicenseSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>();

  const licenses = useProgramAccessStore<ILicense[]>(state => state.licenses);

  const dropdownOptions = useMemo<OptionType[]>(() => (
    licenses.map(license => ({
      label: license.name,
      value: license.id,
    }))
  ), [licenses.length]);

  const selectedLicense = useMemo<ILicense>(() => (
    selectedOption && licenses.find(license => license.id === selectedOption.value)
  ), [selectedOption]);

  const licenseSelectProps = useMemo(() => ({
    selectedOption,
    dropdownOptions,
    setSelectedOption,
  }), [selectedOption, dropdownOptions, setSelectedOption]);

  useEffect(() => {
    if (!licenses.length || selectedOption) return;

    const initialOption = dropdownOptions.find(option => option.value === initialLicenseId);

    setSelectedOption(initialOption || dropdownOptions[0]);
  }, [licenses.length]);

  return {
    licenseSelectProps,
    selectedLicense,
  };
};

interface LicenseSelectProps {
  confirmCallback: (cb: () => void) => void;
  dropdownOptions: OptionType[];
  selectedOption: OptionType;
  setSelectedOption: (option: OptionType) => void;
}

const LicenseSelect = ({ confirmCallback, selectedOption, dropdownOptions, setSelectedOption }: LicenseSelectProps) => (
  <label className="tw-inline-flex tw-items-center tw-gap-12 tw-min-w-[400px] tw-p-3" htmlFor="license-select">
    <span className="tw-text-base">License</span>
    <div className="tw-flex-1">
      <Select
        value={selectedOption}
        menuPlacement="auto"
        menuPosition="fixed"
        name="license-select"
        options={dropdownOptions}
        placeholder="Select License..."
        onChange={option => confirmCallback(() => setSelectedOption(option))}
        styles={{ menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 }) }}
      />
    </div>
  </label>
);

export default LicenseSelect;
