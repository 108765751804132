import { MutableRefObject, useEffect, useState } from 'react';

/*
  This hook is used to hide the next <br> element when the HintButton is expanded, as long as the HintButton
  is immediately followed by two <br> elements.
  When the HintButton is expanded, it gets `display: block;` which causes an extra newline to be displayed in the DOM
  if there are two <br> elements immediately following it.
 */
const useBrHider = (ref: MutableRefObject<HTMLSpanElement>, show: boolean): boolean => {
  const [hideBr, setHideBr] = useState(false);

  useEffect(() => {
    if (!show) {
      setHideBr(false);
      return;
    }

    const spanHtml = $(ref?.current)?.prop('outerHTML')?.replace(/&nbsp;/g, '');
    const parentHtml = $(ref?.current)?.parent().prop('outerHTML')?.replace(/&nbsp;/g, '');

    if (!spanHtml || !parentHtml) return;

    const index = parentHtml.indexOf(spanHtml);
    const htmlAfterButton = parentHtml.slice(index + spanHtml.length);
    // match two <br> elements (with arbitrary html attributes) that are immediately following each other
    const htmlBeforeBr = htmlAfterButton.match(/^.*?(?=<br\b[^>]*>\s*<br\b[^>]*>)/) ?? [''];

    if (htmlBeforeBr[0]) {
      setHideBr(false);
    } else {
      setHideBr(true);
    }
  }, [show]);

  return hideBr;
};

export default useBrHider;
