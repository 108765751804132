import { CSSProperties, useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { RefObject } from 'react'

// Reminder:
// getInitialClientOffset: clientX/clientY when drag started
// getInitialSourceClientOffset: parent top/left bounding box when drag started
// getClientOffset: current clientX/clientY
// getSourceClientOffset: difference between parent top/left and current clientX/clientY
//  = (getClientOffset + getInitialSourceClientOffset) - getInitialClientOffset
// getDifferenceFromInitialOffset: difference between clientX/clientY when drag started and current one

export type Point = {
  x: number,
  y: number,
}

const subtract = (a: Point, b: Point): Point => {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  };
}

const calculateParentOffset = (monitor): Point => {
  const client = monitor.getInitialClientOffset();
  const source = monitor.getInitialSourceClientOffset();

  if (client === null || source === null) {
    return { x: 0, y: 0 };
  }

  return subtract(client, source);
}

export const calculatePointerPosition = (monitor, childRef: RefObject<Element>): Point | null => {
  const offset = monitor.getClientOffset();
  if (offset === null) {
    return null;
  }

  // If we don't have a reference to a valid child, use the default offset:
  // current cursor - initial parent/drag source offset
  if (!childRef.current || !childRef.current.getBoundingClientRect) {
    return subtract(offset, calculateParentOffset(monitor));
  }

  const bb = childRef.current.getBoundingClientRect();
  const middle = { x: bb.width / 2, y: bb.height / 2 };
  return subtract(offset, middle);
}

const getStyle = (currentOffset: Point): CSSProperties => {
  const transform = `translate(${currentOffset.x.toFixed(1)}px, ${currentOffset.y.toFixed(1)}px)`;
  return {
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    transform,
    WebkitTransform: transform,
  };
}


export const usePreview = () => {
  const child = useRef(null);
  const collectedProps = useDragLayer((monitor) => {
    return {
      currentOffset: calculatePointerPosition(monitor, child),
      isDragging: monitor.isDragging(),
      itemType: monitor.getItemType(),
      item: monitor.getItem(),
      monitor,
    };
  })

  if (!collectedProps.isDragging || collectedProps.currentOffset === null) {
    return { display: false };
  }

  return {
    display: true,
    itemType: collectedProps.itemType,
    item: collectedProps.item,
    style: getStyle(collectedProps.currentOffset),
    monitor: collectedProps.monitor,
    ref: child,
  };
}
