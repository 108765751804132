import React from 'react';

const DistrictInfo = () => (
  <div>
    <p>
      To gain access to the SFTP directory we have set up for your district.
    </p>
    <br />
    <ol>
      <li>
        Follow these instructions on
        {' '}
        <a
          href="https://www.teachtci.com/generate-ssh-keys"
          target="_blank"
          rel="noreferrer noopener"
        >
          generating a Public SSH Key
        </a>
        {' '}
        for TCI.
      </li>
      <li>Paste your Public SSH Key in the field below.</li>
    </ol>
    <br />
    <p>
      In the next step you learn more about the roster CSVs you will need to place into the SFTP.
    </p>
  </div>
);

export default DistrictInfo;
