import React from 'react';
import { useQuery } from '@apollo/client';
import { SlideGroup } from '@/components/admin/SlideShow/Builder/CreateTransistionGroup/utils';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { GET_SLIDE_SHOW_ASSIGNMENT_RESULTS } from '../../Utils/slideShowGraphQL';
import {
  ForDisplay,
  IAssignmentShortcutProps,
  IGradeShortcutProps, ILmsShareProps
} from '@/components/admin/SlideShow/PresentMode/TransitionSlide/types';

interface FinalSlideProps {
  assignmentId: string;
  imageUrl: string;
  slideGroups: SlideGroup[];
  slideGroupIndex: number;
  jumpToSlide: (number) => void;
  AssignmentShortcutProps: IAssignmentShortcutProps;
  GradeShortcutProps: IGradeShortcutProps;
  LmsShareProps: ILmsShareProps;
  forDisplay: ForDisplay
  isTemporary?: boolean;
}

const FinalSlide = ({
  assignmentId, imageUrl, jumpToSlide, slideGroups, slideGroupIndex, AssignmentShortcutProps,
  GradeShortcutProps, LmsShareProps, forDisplay, isTemporary,
}: FinalSlideProps) => {
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  const { loading, data } = useQuery(GET_SLIDE_SHOW_ASSIGNMENT_RESULTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      assignmentId,
      slideShowId,
    },
  });

  return (
    <>
      <LeftPanel
        imageUrl={imageUrl}
        isIntroSlide={false}
        slideGroup={slideGroups[slideGroupIndex]}
      />
      <RightPanel
        isIntroSlide={false}
        isFinalSlide
        slideGroups={slideGroups}
        slideGroupIndex={slideGroupIndex}
        jumpToSlide={jumpToSlide}
        assignmentId={assignmentId}
        exerciseResults={loading ? [] : data?.assignment?.exerciseResults}
        unansweredQuestions={loading ? [] : data?.slideShow?.unansweredQuestions}
        AssignmentShortcutProps={AssignmentShortcutProps}
        LmsShareProps={LmsShareProps}
        GradeShortcutProps={GradeShortcutProps}
        forDisplay={forDisplay}
        loading={loading}
        isTemporary={isTemporary}
      />
    </>
  );
};

export default FinalSlide;
