import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../../ProgressHeader.module.scss';

import * as constants from '../../steps';
import { rosterHeaderImages, rosterHeaderImagesCsv, getStepImage, SrOnlyStepStatus } from '../../Utils';
import RosterSteps from '../../RosterCard/RosterSteps';

export default class ProgressHeader extends Component {
  static propTypes = {
    completedSteps: constants.completedStepsPropTypes.isRequired,
    isCsv: PropTypes.bool.isRequired,
    page: PropTypes.number
  };

  static defaultProps = {
    page: 0
  };

  constructor(props) {
    super(props);

    this.renderStep = this.renderStep.bind(this);
  }

  // increment page by 1 because parent wizard has 1 page - so page n of this wizard is really n + 1 of the full wizard
  _imageClass(page) {
    if (this.props.page + 1 === page || this.props.completedSteps[page]) {
      return styles.activeImg;
    }

    return '';
  }

  _activeCaptionClass(page) {
    if (this.props.page + 1 === page || this.props.completedSteps[page]) {
      return styles.activeCaption;
    }

    return '';
  }

  renderStep(step, text) {
    return (
      <div className={styles.headerImageContainer} key={text}>
        <img
          alt=""
          className={`${styles.headerImage} ${this._imageClass(step)}`}
          src={getStepImage(
                this.props.isCsv ? rosterHeaderImagesCsv : rosterHeaderImages,
                this.props.completedSteps[step],
                step
              )}
        />
        <span
          className={`${styles.caption} ${this._activeCaptionClass(step)}`}
        >
          {text} <SrOnlyStepStatus isStepCompleted={this.props.completedSteps[step]} />
        </span>
      </div>
    );
  }

  render() {
    return (
      <RosterSteps
        divider={<div key="divider" className={styles.dottedLine} />}
        isCsvDistrict={this.props.isCsv}
        completedSteps={this.props.completedSteps}
        renderStep={this.renderStep}
        wrapperClass={styles.header}
      />
    );
  }
}
