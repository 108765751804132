import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import ModelSelect from './index';

const NotebookSelect = ({ ...props }) => (
  <ModelSelect
    {...props}
    notebookId={props.notebookId}
    sectionId={props.sectionId}
    selectType="notebook"
    title={`${I18n.t('notebook')}:`}
    refetchDependencies={[props.notebookId || props.sectionId]}
  />
);

NotebookSelect.propTypes = {
  handleSelect: PropTypes.func,
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }),
  notebookId: PropTypes.number,
  notebookType: PropTypes.string,
  parentResourceUrl: PropTypes.string,
  programId: PropTypes.number.isRequired,
  resourceUrl: PropTypes.string,
  sectionId: PropTypes.number,
};

NotebookSelect.defaultProps = {
  handleSelect: null,
  initialOption: null,
  notebookId: undefined,
  notebookType: null,
  parentResourceUrl: null,
  resourceUrl: '',
  sectionId: undefined,
};

export default NotebookSelect;
