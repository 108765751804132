import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import useFlags from '@/hooks/useFlags';
import useGradesTableStore from '../../store/useGradesTableStore';
import useScorableNotebooks from '../../hooks/queries/useScorableNotebooks';

interface SectionCollapseHeaderProps {
  rowSpan: number;
  tableRef: React.MutableRefObject<HTMLDivElement>;
}

const CONTENT_PADDING_TOP = 10;

const SectionCollapseHeader = ({ rowSpan, tableRef }: SectionCollapseHeaderProps) => {
  const isSectionNotebooksExpanded = useGradesTableStore(state => state.isSectionNotebooksExpanded);
  const setStore = useGradesTableStore(state => state.setStore);
  const { data: scorableNotebooks } = useScorableNotebooks();
  const contentRef = useRef(null);
  const { updatedGradesTable } = useFlags();

  const toggleExpanded = () => setStore({ isSectionNotebooksExpanded: !isSectionNotebooksExpanded });

  const faCaretClass = useMemo(() => (
    isSectionNotebooksExpanded ? 'fa fa-caret-left' : 'fa fa-caret-right'
  ), [isSectionNotebooksExpanded]);

  useEffect(() => {
    if (!tableRef.current) return null;

    const scrollListener = (e) => {
      if (!contentRef.current) return;

      const newY = e.target.scrollTop + CONTENT_PADDING_TOP;

      contentRef.current.style.top = `${newY}px`;
    };

    tableRef.current.addEventListener('scroll', scrollListener);

    return () => tableRef?.current?.removeEventListener('scroll', scrollListener);
  }, [tableRef?.current]);

  return (
    <th className={clsx('section-collapse tw-pt-3', { 'tw-w-[30px]': updatedGradesTable })} onClick={toggleExpanded} rowSpan={rowSpan}>
      <div className={clsx('tw-h-full', {
        'tw-w-[30px]': updatedGradesTable,
        'tw-pr-2': updatedGradesTable,
        'tw-w-[40px]': !updatedGradesTable,
      })}>
        <div ref={contentRef} className="tw-absolute">
          <i className={`${faCaretClass} tw-pl-1`} aria-hidden />

          <div className="show-hide-label">
            {isSectionNotebooksExpanded ? 'Hide' : `Show ${scorableNotebooks.length} Sections`}
          </div>
        </div>
      </div>
    </th>
  );
};

export default SectionCollapseHeader;
