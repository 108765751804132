import React, { useState } from 'react';
import Popover from '@/components/ui/Popover';
import Tooltip from '@/components/ui/Tooltip';
import clsx from 'clsx';
import { twMerge } from '@/utils';
import ShapeCategories from './ShapeCategories';
import Shapes from './Shapes';
import { imgStyle, isSelected, buttonStyle, scaleValue } from '../styles';
import useDrawingContext from '../../store/useDrawingContext';
import icons from '../icons';

const popoverStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  left: '1.5rem',
  width: `${scaleValue(toolbarEl, 14.2, 16)}rem`,
  minHeight: `${scaleValue(toolbarEl, 267, 310)}px`,
});

const ShapesButton = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const disabled = useDrawingContext(state => state.disabled);
  const mode = useDrawingContext(state => state.mode);
  const icon = icons.shapes;
  const [category, setCategory] = useState('');
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const buttonClassName = twMerge(
    'tw-bg-transparent tw-text-white tw-border-0 tw-cursor-pointer tw-p-2 tw-rounded tw-my-1',
    'hover:tw-bg-tci-purple tw-flex tw-items-center tw-justify-center',
    'disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
  );

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Popover
          open={popoverIsOpen}
          onOpenChange={(isOpen) => {
            setPopoverIsOpen(isOpen);
            if (!isOpen) setCategory('');
          }}
        >
          <Popover.Anchor />
          <Tooltip.Trigger asChild>
            <Popover.Trigger
              type="button"
              className={clsx(
                buttonClassName, {
                  'tw-bg-tci-purple': isSelected(mode, 'shape', disabled),
                },
              )}
              disabled={disabled}
              style={buttonStyle(toolbarEl)}
            >
              <img
                style={imgStyle({ toolbarEl })}
                src={icon.icon}
                alt={icon.alt}
              />
            </Popover.Trigger>
          </Tooltip.Trigger>
          <Popover.Content
            className="tw-absolute tw-top-1 tw-overflow-visible"
            aria-label="Shape categories"
            style={popoverStyle(toolbarEl)}
          >
            <ShapeCategories
              category={category}
              setCategory={setCategory}
            />
            <Shapes
              category={category}
              setCategory={setCategory}
              setPopoverIsOpen={setPopoverIsOpen}
            />
          </Popover.Content>
          <Tooltip.Content
            className="tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2 tw-outline tw-outline-1 tw-outline-gray-300"
            side="right"
            sideOffset={10}
          >
            {icon.alt}
          </Tooltip.Content>
        </Popover>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default ShapesButton;
