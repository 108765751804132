import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import { ConditionalWrap } from 'common/Utils';
import scorecardIcon from 'images/icons/scorecard-with-check.svg';
import rosteringCardStyles from '../RosteringCard.module.scss';
import CanvasIntegrationModal from './CanvasIntegrationModal';
import useCredentialStore from './useCredentialStore';
import useFlags from '../../../../../hooks/useFlags';
import { useModalManager } from '../../../../common/Modal';
import { fetchCredentials } from './queries';

const OAuthCard = ({ hasCompletedLTI = false, subscriberId, userType }) => {
  const setCanvasCredentials = useCredentialStore(state => state.setCanvasCredentials);
  const setLtiCredentials = useCredentialStore(state => state.setLtiCredentials);
  const submitting = useCredentialStore(state => state.submitting);
  const setSubmitting = useCredentialStore(state => state.setSubmitting);
  const modalManager = useModalManager();
  const { canvasLti13 } = useFlags();

  useEffect(() => {
    if (modalManager.isOpen || submitting) return;

    setSubmitting(true);
    fetchCredentials(subscriberId)
      .then((response) => {
        const [canvasCreds, ltiCreds] = response;
        setCanvasCredentials(canvasCreds.data?.data);
        setLtiCredentials(ltiCreds.data?.data[0]);
        setSubmitting(false);
      });
  }, [modalManager.isOpen]);

  const addCanvasIntegrationButton = () => {
    const isCoordinator = userType === 'Coordinator';

    return (
      <ConditionalWrap
        condition={isCoordinator}
        wrap={children => (
          <Tooltip
            content="Only administrators can set up integrations."
            theme="white"
            size="medium"
          >
            <span>{children}</span>
          </Tooltip>
        )}
      >
        <button
          className="btn btn--block btn--purple"
          onClick={modalManager.open}
          type="button"
          disabled={isCoordinator}
        >
          Add Integration
        </button>
      </ConditionalWrap>
    );
  };

  return (
    <Fragment>
      <div className={`card ${rosteringCardStyles.card} ${rosteringCardStyles.generalCard} ${rosteringCardStyles.withMinHeight}`}>
        <h2>Assignment and Grade Sync</h2>
        <hr className={rosteringCardStyles.hr} />
        <div className={rosteringCardStyles.displayFlex}>
          <img alt="" src={scorecardIcon} />
          <div className="ml30">
            <p className="mb20">
              {canvasLti13 ? (
                'Supported Methods: Google Classroom*, Canvas LTI 1.3, Schoology LTI 1.3'
              ) : (
                'Supported Methods: Google Classroom*, Canvas, Schoology LTI 1.3'
              )}
              <br />
              <br />
              *Administrator setup not required
            </p>
            {addCanvasIntegrationButton()}
          </div>
        </div>
      </div>
      <CanvasIntegrationModal
        modalManager={modalManager}
        subscriberId={subscriberId}
        setCanvasCredentials={setCanvasCredentials}
        setLtiCredentials={setLtiCredentials}
        hasCompletedLTI={hasCompletedLTI}
      />
    </Fragment>
  );
};

OAuthCard.propTypes = {
  hasCompletedLTI: PropTypes.bool,
  subscriberId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

OAuthCard.defaultProps = {
  hasCompletedLTI: false
};

export default OAuthCard;
