import create from 'zustand';

interface IMainIdeaStore {
  isActive: boolean;
  selector: string;
  toggle: () => void;
}

const newStore = (parentSelector: string) => (
  create<IMainIdeaStore>((set, get) => ({
    isActive: false,
    selector: parentSelector,
    toggle: () => {
      const { selector } = get();

      const svg = document.querySelector(`${selector} .main-ideas-svg`);
      svg.classList.toggle('active');

      return set(prev => ({ ...prev, isActive: svg.classList.contains('active') }));
    },
  }))
);

type IMainIdeasStore = {
  stores: { [key: string]: ReturnType<typeof newStore> };
  get: (selector: string) => ReturnType<typeof newStore>;
};

export const MainIdeasStore: IMainIdeasStore = {
  stores: {},
  get: (selector: string) => {
    if (MainIdeasStore.stores[selector]) return MainIdeasStore.stores[selector];

    MainIdeasStore.stores[selector] = newStore(selector);

    return MainIdeasStore.stores[selector];
  },
};
