import React from 'react';
import PropTypes from 'prop-types';

const RulePreviewLink = ({
  count, path, subscriberId, initialFilters
}) => {
  if (count === '0') {
    return <span>0</span>;
  }

  return <a href={path(subscriberId, { initialFilters })}>{count}</a>;
};

RulePreviewLink.propTypes = {
  count: PropTypes.string.isRequired,
  initialFilters: PropTypes.object.isRequired,
  path: PropTypes.func.isRequired,
  subscriberId: PropTypes.number.isRequired
};

export default RulePreviewLink;
