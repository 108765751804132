import React, { Component } from 'react';
import Axios from 'axios';
import { Footer, Spinner } from 'common/Modal';
import { FormInput } from '../Utils';
import Table from './Table';
import PropTypes from 'prop-types';

export default class Form extends Component {
  static propTypes = {
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    unit: PropTypes.string,
    units: PropTypes.array
  };

  static defaultProps = {
    unit: '',
    isLoading: false,
    disableSubmit: false,
    error: false,
    onUpdate: null,
    onAdd: null
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    );
    else return (
      <div id='measurement-unit-modal'>
        <form onSubmit={this._handleSubmit.bind(this)}
              className={`materials-form units-form ${this.state.isLoading ? 'loading' : ''}`}>
          <div>
            <FormInput label="unit" id={this.props.id}
                       stateName="unit"
                       value={this.state.unit}
                       required={true}
                       onChange={this._handleChange.bind(this)} />
          </div>
          <Spinner isLoading={this.state.isLoading} />
          <input className="block_link"
                 type="submit"
                 value="Submit"
                 data-disable-with="Submitting…"
                 disabled={this.state.disableSubmit} />
        </form>

        <Table units={this.props.units}
               removeObject={this.props.removeObject}
               onUpdate={this.props.onUpdate}
        />
      </div>
    );
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  _buildFormData() {
    const { unit } = this.state;
    let formData = new FormData();

    formData.append('measurement_unit[unit]', unit);

    return formData;
  }

  _clearForm() {
    this.setState(this.initialState);
  }

  _handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  _handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true, disableSubmit: true });

    let method = this.props.newRecord ? 'post' : 'put',
      url = this.props.newRecord ? 'measurement_units' : `measurement_units/${this.props.id}`,
      callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
      data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false, disableSubmit: false });
        this._clearForm();
        callback(response.data, 'units', 'label');
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }
};
