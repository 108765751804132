import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../RosteringCard.module.scss';

import {
  isConfigComplete,
  isPreferencesStepComplete,
  getCardClass,
  isSaml,
  completedSSOSteps
} from '../Utils';
import { ssoModelPropType } from '../Proptypes';
import CompletedCard from './CompletedCard';
import CanvasCompletedCard from '../AssignmentAndGrades/CompletedCard';
import PartialCard from './PartialCard';
import SSONewCard from './NewCard';

export default class SSOExistingCards extends Component {
  static _isComplete(ssoModel) {
    if (ssoModel.model_type === 'Clever') {
      return true;
    }

    if (ssoModel.model_type === 'SamlSetting') {
      return !!isConfigComplete(ssoModel);
    }

    return (
      !!(isConfigComplete(ssoModel) &&
      isPreferencesStepComplete(ssoModel))
    );
  }

  static addNewModel(models, newModel) {
    const updatedSSOModels = JSON.parse(JSON.stringify(models));
    updatedSSOModels.push(newModel);

    return updatedSSOModels;
  }

  static updateModel(models, updatedModel) {
    const updatedSSOModels = JSON.parse(JSON.stringify(models));
    const index = models.findIndex(ssoModel => ssoModel.id === updatedModel.id);
    updatedSSOModels[index] = updatedModel;

    return updatedSSOModels;
  }

  static propTypes = {
    hasCompletedClever: PropTypes.bool,
    rootUrl: PropTypes.string.isRequired,
    ssoModelCreatePath: PropTypes.string.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    ssoModels: PropTypes.arrayOf(ssoModelPropType).isRequired,
    subscriber_id: PropTypes.number.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    hasCompletedClever: false
  };

  constructor(props) {
    super(props);

    this.state = {
      newSSOModel: null,
      ssoModels: this.props.ssoModels
    };

    this.updateSSOModels = this.updateSSOModels.bind(this);
    this.refreshNewSSOModel = this.refreshNewSSOModel.bind(this);
    this.updateNewSSOModel = this.updateNewSSOModel.bind(this);
    this.removeSSOModel = this.removeSSOModel.bind(this);
  }

  updateSSOModels(updatedModel) {
    this.setState(prevState => (
      { ssoModels: SSOExistingCards.updateModel(prevState.ssoModels, updatedModel) }
    ));
  }

  existingModels() {
    return this.state.ssoModels.map(ssoModel => ssoModel.model_type);
  }

  updateNewSSOModel(updatedModel) {
    this.setState({ newSSOModel: updatedModel });
  }

  // clear new model and add it to existing models
  refreshNewSSOModel() {
    if (!this.state.newSSOModel) return;

    this.setState(prevState => (
      {
        newSSOModel: null,
        ssoModels: SSOExistingCards.addNewModel(prevState.ssoModels, prevState.newSSOModel)
      }
    ));
  }

  removeSSOModel(removedModelId) {
    this.setState(
      prevState => ({
        ssoModels: prevState.ssoModels
          .filter(ssoModel => ssoModel.id !== removedModelId)
      })
    );
  }

  completedSSOModels = () => this.state.ssoModels.filter(ssoModel => SSOExistingCards._isComplete(ssoModel));

  hasCompletedLTI = () => !!this.completedSSOModels().find(ssoModel => ssoModel.model_type === 'LtiKey');

  _renderCompletedCard() {
    if (!this.completedSSOModels().length) return null;

    return (
      <div key="completed_card" className={getCardClass(true)}>
        <CompletedCard
          ssoModels={this.completedSSOModels()}
          ssoModelPath={this.props.ssoModelPath}
          removeSSOModel={this.removeSSOModel}
          rootUrl={this.props.rootUrl}
          updateSSOModel={this.updateSSOModels}
          uploadXMLUrl={this.props.uploadXMLUrl}
          subscriberId={this.props.subscriber_id}
        />
      </div>
    );
  }

  _renderCanvasCompletedCard() {
    return (
      <CanvasCompletedCard
        subscriberId={this.props.subscriber_id}
        hasCompletedLTI={this.hasCompletedLTI()}
        userType={this.props.userType}
      />
    );
  }

  _renderPartialCards() {
    return this.state.ssoModels.map(ssoModel => (
      <PartialCard
        ssoModel={ssoModel}
        removeSSOModel={this.removeSSOModel}
        completedSteps={completedSSOSteps(ssoModel)}
        isComplete={SSOExistingCards._isComplete(ssoModel)}
        isSaml={isSaml(ssoModel)}
        subscriber_id={this.props.subscriber_id}
        ssoModelPath={this.props.ssoModelPath}
        uploadXMLUrl={this.props.uploadXMLUrl}
        userType={this.props.userType}
        rootUrl={this.props.rootUrl}
        updateSSOModel={this.updateSSOModels}
        key={`partial_${ssoModel.id}`}
      />
    ));
  }

  _renderNewCards() {
    return (
      <div className={styles.newCardsContainer} key="new_card">
        <SSONewCard
          subscriber_id={this.props.subscriber_id}
          ssoModelPath={this.props.ssoModelPath}
          ssoModelCreatePath={this.props.ssoModelCreatePath}
          uploadXMLUrl={this.props.uploadXMLUrl}
          userType={this.props.userType}
          rootUrl={this.props.rootUrl}
          ssoModel={this.state.newSSOModel}
          updateSSOModel={this.updateNewSSOModel}
          onClose={this.refreshNewSSOModel}
          completedSteps={completedSSOSteps(this.state.newSSOModel)}
          isSaml={isSaml(this.state.newSSOModel)}
          existingModels={this.existingModels()}
          hasCompletedClever={this.props.hasCompletedClever}
        />
        {<div id="newCredentials" />}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {[
          this._renderCompletedCard(),
          this._renderCanvasCompletedCard(),
          ...this._renderPartialCards(),
          this._renderNewCards()
        ]}
      </Fragment>
    );
  }
}
