import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import styles from './LessonNumber.module.scss';
import { Footer } from 'common/Modal';

export default class Form extends Component {
  static propTypes = {
    ajaxPath: PropTypes.string.isRequired,
    display_type: PropTypes.string,
    icons: PropTypes.array,
    icon_id: PropTypes.number,
    show_menu: PropTypes.bool
  };

  static defaultProps = {
    display_type: 'number',
    icon_id: 0,
    show_menu: false
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return(
      <form onSubmit={this._handleSubmit.bind(this)}>
        <div className={styles.container}
             onClick={() => this._toggleDropdownOff()} >
          <label htmlFor='display-type'
                 className={styles["radio-title"]} >Display Type:</label>
          <br/><br/>
          <div id='display-type'
               onChange={this._handleDisplayTypeChange.bind(this)}>
            <label className={styles["radio-label"]}>
              <input type='radio'
                     name='display-type'
                     value='number'
                     defaultChecked={this.state.display_type === 'number'} />
              Number
            </label>
            <label className={styles["radio-label"]}>
              <input type='radio'
                     name='display-type'
                     value='icon'
                     defaultChecked={this.state.display_type === 'icon'}/>
              Icon
            </label>
            <label className={styles["radio-label"]}>
              <input type='radio'
                     name='display-type'
                     value='none'
                     defaultChecked={this.state.display_type === 'none'}/>
              None
            </label>
          </div>
          <br/>
          { this.state.display_type === 'icon' ? this._iconSelect() : this._spacer() }
        </div>
        <Footer/>
      </form>
    );
  }

  _handleDisplayTypeChange(event) {
    this.setState({
      display_type: event.target.value,
      show_menu: false
    });
  }

  _spacer() {
    return(
      <div>
        <br className={styles['spacer']}/>
      </div>
    );
  }

  _iconSelect() {
    return(
      <div>
        <button type='button'
                id='icon-dropdown'
                className='btn btn--outline-purple'
                onClick={() => this._toggleDropdown()}>
                  Select Icon <i className={'fa fa-caret-down'}/>
        </button>
        { this._dropdown() }
      </div>
    );
  }

  _toggleDropdown() {
    const currentState = this.state.show_menu;
    this.setState({ show_menu: !currentState });
  }

  //closes the dropdown if the user clicks anywhere outside of it
  _toggleDropdownOff() {
    if (this.state.show_menu === true){
      this.setState({ show_menu: false });
    }
  }

  _dropdown() {
    const all_icons = this.props.icons;
    return(
      <div className={`${styles["lesson-num-dropdown"]} ${this.state.show_menu && 'show_block'}`}>
        {
          all_icons.slice(1).map((icon) => {
            return(
              <div key={`${icon[1]}`}>
                {
                  Object.keys(icon[2]).map((fa_icon_class) => {
                    return (
                      <div key={`${icon[1]}`}
                           data-icon-name={`${icon[0]}`}
                           data-icon-id={`${icon[1]}`}
                           data-icon-fa-class={`${icon[2][fa_icon_class]}`}
                           className={styles['custom-dropdown-item']}
                           onClick={(event) => this._handleIconChange(event)}>
                            <i data-icon-name={`${icon[0]}`}
                               data-icon-id={`${icon[1]}`}
                               className={`${icon[2][fa_icon_class]} ${styles['icon']}`}/>
                            {icon[0]}
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  _handleIconChange(event) {
    this.setState({
      icon_id: event.target.getAttribute('data-icon-id')
    });
    const caret = " <i class='fa fa-caret-down'/>";
    $('#icon-dropdown').text($(event.target).attr('data-icon-name')).append(caret);
  }

  _handleSubmit(event) {
    event.preventDefault();
    let data = this._buildData();

    Axios.put(this.state.ajaxPath, data).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
      }
      else if (response.data.redirect_to) {
        window.location.replace(response.data.redirect_to);
      }
    }).catch(error => {
      console.log(error);
    })
  }

  _buildData() {
    let params = this.props.params;
    let data = {
      'display_type': this.state.display_type
    };

    if (this.state.icon_id !== 0) {
      data['icon_id'] = this.state.icon_id;
    }

    if (params['unit_content_id']) {
      data['unit_content_id'] = params['unit_content_id'];
    }

    if (params['program_content_id']) {
      data['program_content_id'] = params['program_content_id'];
    }
    return data;
  }
}
