import React, { Fragment } from 'react';
import { useModalManager, SubmitModal } from '@/components/common/Modal';
import { FileField, LabelField, TextField } from '@/components/common/Forms';
import { required } from '@/components/common/Forms/Validators';
import * as Routes from '@/modules/routes';
import NeedsCaptionField from './FormCheckboxFields/NeedsCaptionField';
import EnglishVideoOnly from './FormCheckboxFields/EnglishVideoOnly';
import VideoTags from './VideoTags';
import NeedsAudioDescriptionsField from './FormCheckboxFields/NeedsAudioDescriptionsField';
import styles from './index.module.scss';
import {
  getStartDurationPairsFromFile,
} from './audioAnalyze';
import StartDurationInputs from './StartDurationInputs';

interface EditVideoProps {
  afterSubmit: () => any;
  video: {
    audios: {
      id: number;
      track_en_file_name: string;
      track_es_file_name: string;
    }[];
    caption_es_file_name: string;
    caption_es_url: string;
    caption_file_name: string;
    caption_url: string;
    english_video_only: boolean;
    id: number;
    needs_audio_descriptions: boolean;
    needs_captions: boolean;
    needs_captions_es: boolean;
    rev_status: string;
    rev_status_es: string;
    tags: { id: number }[];
    title_en: string;
    title_es: string;
    url: string;
    es_url: string | null;
    video_es_file_name: string;
    video_file_name: string;
  }
}

const EditVideo = ({ afterSubmit, video }: EditVideoProps) => {
  const modalManager = useModalManager();

  return (
    <Fragment>
      <button type="button" className="dropdown-item full-size" onClick={modalManager.open}>
        <i className="fa fa-edit" /> Edit Video/Caption
      </button>
      <SubmitModal
        headerText="Edit Video/Caption"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        afterSubmit={afterSubmit}
        submitPath={Routes.plato_api_video_path(video.id)}
        method="patch"
        initialValues={{ ...video, tag_ids: video.tags.map(tag => tag.id), audios_attributes: video.audios }}
        useRecursiveAppend
        className={styles.modalSize}
        bodyClassName={styles.bodyModalSize}
      >
        {({ form }) => (
          <>
            <VideoTags />
            <hr />
            <h2 className="tw-text-lg"><strong>English</strong></h2>
            <TextField
              className={styles.input}
              name="title_en"
              content={video.title_en}
              placeholder="Enter a video title…"
              label="Title"
              validate={required}
              required
            />
            <LabelField id="current_video" label="Current Video" content={video.video_file_name} />
            <FileField name="video" label="Upload Video File" accept=".mp4" />
            <NeedsCaptionField
              needsCaptions={video.needs_captions}
              hasCaptionAttachment={form.getState().values.caption || video.caption_url}
              hasVideoAttachment={form.getState().values.video || video.url}
              orderIsInProgress={video.rev_status === 'In Progress'}
              form={form}
              type="edit"
              locale="en"
            />
            <EnglishVideoOnly englishOnlySelected={video.english_video_only} />
            <LabelField id="current_caption" label="Current Caption" content={video.caption_file_name} />
            <FileField name="caption" label="Upload Caption File" accept=".vtt" />
            <NeedsAudioDescriptionsField
              disabled={video.needs_audio_descriptions}
              needsAudioDescriptions={video.needs_audio_descriptions}
            />

            {video.audios[0]?.track_en_file_name && (
            <LabelField
              id="current_track_en"
              label="Current English Audio Description"
              content={video.audios[0]?.track_en_file_name}
            />
            )}
            <FileField
              accept=".mp3"
              name="audios_attributes[0][track_en]"
              onChange={async (file: File) => {
                const startDurationPairs = await getStartDurationPairsFromFile(file, video.url);

                form.change('audios_attributes[0][ad_en_data]', startDurationPairs);
              }}
              label="Upload Audio Description File"
            />
            <StartDurationInputs key={form.getState().values} form={form} name="ad_en_data" />

            <TextField rowClass="tw-hidden" name="audios_attributes[0][id]" value={video.audios[0]?.id} />
            <hr className={styles.spanishDivingLine} />
            <h2 className={styles.spanishSectionHeader}><strong>Spanish</strong></h2>
            <TextField
              className={styles.input}
              name="title_es"
              content={video.title_es}
              placeholder="Enter a video title…"
              label="Title"
              tooltipIcon="info"
              tooltipText="A Spanish translation will be automatically entered if there is none provided."
            />
            <LabelField id="current_video_es" label="Current Video" content={video.video_es_file_name} />
            <FileField name="video_es" label="Upload Video File" accept=".mp4" />
            <NeedsCaptionField
              needsCaptions={video.needs_captions_es}
              hasCaptionAttachment={form.getState().values.caption_es || video.caption_es_url}
              hasVideoAttachment={form.getState().values.video_es || video.es_url}
              orderIsInProgress={video.rev_status_es === 'In Progress'}
              form={form}
              type="edit"
              locale="es"
            />
            <LabelField id="current_caption_es" label="Current Caption" content={video.caption_es_file_name} />
            <FileField name="caption_es" label="Upload Caption File" accept=".vtt" />
            <div className="tw-hidden">
              {video.audios[0]?.track_es_file_name && (
              <LabelField
                id="current_track_es"
                label="Current Spanish Audio Description"
                content={video.audios[0]?.track_es_file_name}
              />
              )}
              <FileField
                accept=".mp3"
                name="audios_attributes[0][track_es]"
                label="Upload Audio Description File"
                onChange={async (file: File) => {
                  const startDurationPairs = await getStartDurationPairsFromFile(file, video.url);

                  form.change('audios_attributes[0][ad_es_data]', startDurationPairs);
                }}
              />
              <StartDurationInputs form={form} name="ad_es_data" />
            </div>
          </>
        )}
      </SubmitModal>
    </Fragment>
  );
};


export default EditVideo;
