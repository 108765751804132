import React from 'react';
import ClassroomSelect from '@/components/common/ModelSelect/ClassroomSelect';
import UnitLessonSelect from '@/components/common/ModelSelect/UnitLessonSelect';
import * as Routes from '@/modules/routes';
import useGradesTableStore from './store/useGradesTableStore';

const Navigation = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const unit = useGradesTableStore(state => state.unit);
  const programId = useGradesTableStore(state => state.programId);
  const stafferId = useGradesTableStore(state => state.stafferId);

  const classroomSelectUrl = Routes.teacher_score_path('select_id', {
    program_id: programId,
    ...(!!lesson?.id && { lesson_id: lesson.id }),
    ...(!!unit?.id && { unit_id: unit.id }),
  });

  const lessonSelectUrl = Routes.teacher_program_scores_path(programId, {
    classroom_id: classroom.id,
    lesson_id: 'select_id',
  });

  const unitSelectUrl = Routes.teacher_program_scores_path(programId, {
    classroom_id: classroom.id,
    unit_id: 'select_id',
  });

  return (
    <>
      {classroom.id && (
        <ClassroomSelect
          resourceUrl={classroomSelectUrl}
          programId={programId}
          initialOption={{
            label: classroom?.name,
            value: classroom?.id,
          }}
          showOutOfTermClassrooms
          stafferId={stafferId}
        />
      )}

      {(lesson?.id || unit?.id) && (
        <UnitLessonSelect
          resourceUrl={lessonSelectUrl}
          parentResourceUrl={unitSelectUrl}
          programId={programId}
          initialOption={{
            label: (unit || lesson).title,
            value: (unit || lesson).id,
          }}
        />
      )}
    </>
  );
};

export default Navigation;
