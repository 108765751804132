import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SSOSetupModal from '../SSOSetupModal';
import { ssoModelPropType } from '../Proptypes';
import { isSaml, completedSSOSteps } from '../Utils';

export default class EditButton extends Component {
  static propTypes = {
    rootUrl: PropTypes.string.isRequired,
    ssoModel: ssoModelPropType.isRequired,
    ssoModelPath: PropTypes.string.isRequired,
    subscriber_id: PropTypes.number.isRequired,
    updateSSOModel: PropTypes.func.isRequired,
    uploadXMLUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  openModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  _renderSSOSetupModal() {
    if (this.state.modalIsOpen) {
      return (
        <SSOSetupModal
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          ssoModelPath={this.props.ssoModelPath}
          ssoModel={this.props.ssoModel}
          subscriber_id={this.props.subscriber_id}
          updateSSOModel={this.props.updateSSOModel}
          uploadXMLUrl={this.props.uploadXMLUrl}
          rootUrl={this.props.rootUrl}
          currentStep={1}
          completedSteps={completedSSOSteps(this.props.ssoModel)}
          isSaml={isSaml(this.props.ssoModel)}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <span>
        <button
          type="button"
          className="btn btn--outline-purple btn--sm"
          onClick={this.openModal}
          aria-label="Edit SSO Setup"
        >
          <i className="fa fa-edit" aria-hidden />
          {' '}
          Edit
        </button>

        {this._renderSSOSetupModal()}
      </span>
    );
  }
}
