import React, { useState } from 'react';
import { Field } from 'react-final-form';
import styles from '../../Form.module.scss';
import ReactSelectAdapter from './ReactSelectAdapter';
import { usernameSources } from '../../../Utils';

const FormRow = ({ children }: { children: ReactChildren }) => (
  <div className={styles.row}>
    <div className={styles.fieldContainer}>
      {children}
    </div>
  </div>
);

interface UsernameSourceProps {
  rosteringType: 'clever' | 'oneroster';
  staffUsernameSource?: string;
  usernameSource?: string;
}

const UsernameSource = ({ rosteringType, staffUsernameSource, usernameSource }: UsernameSourceProps) => {
  const initiallyOpen = (typeof staffUsernameSource === 'string' || typeof usernameSource === 'string');
  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const caretClass = isOpen ? 'fa fa-caret-up' : 'fa fa-caret-down';

  return (
    <>
      <FormRow>
        <button
          type="button"
          className="btn btn--link-purple pl0"
          onClick={toggleIsOpen}
        >
          Advanced: Change staff or student username source <i className={caretClass} />
        </button>
      </FormRow>

      {isOpen && (
        <>
          <FormRow>
            <label
              htmlFor="staff_username_source"
              className={styles.label}
            >
              Staff Username Source
            </label>
            <Field
              name="staff_username_source"
              id="staff_username_source"
              component={ReactSelectAdapter}
              options={usernameSources(rosteringType)}
              placeholder="Select username source"
              className={styles.input}
              parse={val => (val ? val.value : val)}
            />
          </FormRow>
          <FormRow>
            <label htmlFor="username_source" className={styles.label}>Student Username Source</label>
            <Field
              name="username_source"
              id="username_source"
              component={ReactSelectAdapter}
              options={usernameSources(rosteringType)}
              placeholder="Select username source"
              className={styles.input}
              parse={val => (val ? val.value : val)}
            />
          </FormRow>
        </>
      )}
    </>
  );
};

export default UsernameSource;
