import React from 'react';
import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import I18n from '@/modules/i18n';
import cn from '@/utils/cn';
import { GradeByType } from '../NotebookGradeByMenu/types';

export const GradeByMenu = ({ handleSelect }) => {
  return (
    <MenuTrigger>
      <Button className="dropdown-item focus:tw-bg-inherit tw-cursor-pointer tw-relative tw-min-w-[150px]">
        <i className="fa fa-bar-chart" aria-hidden="true" />
        {I18n.t('grade_by')}
        <i className="fa fa-caret-right tw-absolute tw-top-1/2 -tw-right-1 -tw-translate-y-1/2" aria-hidden="true" />
      </Button>
      <Popover
        className={cn(
          'tw-box-border tw-border tw-border-solid tw-rounded',
          'tw-bg-white tw-border-med-grey',
        )}
        offset={4}
        placement="right top"
      >
        <Menu className="tw-cursor-pointer">
          <MenuItem
            className="dropdown-item tw-rounded-t"
            onAction={() => handleSelect(GradeByType.Section)}
          >
            {I18n.t('section')}
          </MenuItem>
          <MenuItem
            className="dropdown-item"
            onAction={() => handleSelect(GradeByType.Lesson)}
          >
            {I18n.t('lesson')}
          </MenuItem>
          <MenuItem
            className="dropdown-item tw-rounded-b"
            onAction={() => handleSelect(GradeByType.Assignment)}
          >
            {I18n.t('assignment')}
          </MenuItem>
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};
