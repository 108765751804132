import React, { useEffect, useRef, useState } from 'react';
import * as Routes from 'routes';
import PropTypes from 'prop-types';
import styles from './ManagePrograms.module.scss';
import AddProgramsButton from './AddProgramsButton';
import RemoveProgramsButton from './RemoveProgramsButton';
import I18n from 'modules/i18n';

const ManagePrograms = (
  {
    hasTrials, adminDashPath, userType, addProgramsDisabled, removeProgramsDisabled, getAdminInfoPath,
    addProgramsPath, manuallyManaged, adminProgramsPath, removeProgramsPath, trialStaffer,
  }
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => (setIsExpanded(!isExpanded));
  const btnRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target) && isExpanded) {
        toggleExpanded();
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [toggleExpanded]);

  return (
    <div className={styles.expandContainer} ref={btnRef}>
      <button
        onClick={toggleExpanded}
        className="btn btn--purple"
        type="button"
        aria-haspopup="menu"
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-controls="manageProgramMenu"
      >
        {I18n.t('manage_programs')} <i className={`fa fa-caret-down ${styles.caret}`} />
      </button>

      <div id="manageProgramMenu" className={`${styles.menuContainer} ${!isExpanded && 'hidden'}`}>
        {!trialStaffer && (
          <AddProgramsButton
            adminDashPath={adminDashPath}
            userType={userType}
            disabled={addProgramsDisabled}
            getAdminInfoPath={getAdminInfoPath}
            addProgramsPath={addProgramsPath}
            manuallyManaged={manuallyManaged}
            adminProgramsPath={adminProgramsPath}
          />
        )}
        {!trialStaffer && (
          <RemoveProgramsButton
            adminDashPath={adminDashPath}
            userType={userType}
            disabled={removeProgramsDisabled}
            getAdminInfoPath={getAdminInfoPath}
            removeProgramsPath={removeProgramsPath}
          />
        )}
        {trialStaffer && (
          <a
            className="dropdown-item"
            href={Routes.trials_teacher_programs_path()}
            role="button"
          >
            <i aria-hidden="true" className="fa fa-plus" /> Add 30 day Trial
          </a>
        )}
        {hasTrials && (
          <a
            className="dropdown-item"
            href={Routes.remove_teacher_programs_path()}
            role="button"
          >
            <i aria-hidden="true" className="fa fa-trash" /> Remove Trials
          </a>
        )}
      </div>
    </div>
  );
};

ManagePrograms.propTypes = {
  addProgramsDisabled: PropTypes.bool,
  addProgramsPath: PropTypes.string.isRequired,
  adminDashPath: PropTypes.string.isRequired,
  adminProgramsPath: PropTypes.string.isRequired,
  getAdminInfoPath: PropTypes.string.isRequired,
  hasTrials: PropTypes.bool.isRequired,
  manuallyManaged: PropTypes.bool,
  removeProgramsDisabled: PropTypes.bool,
  removeProgramsPath: PropTypes.string.isRequired,
  trialStaffer: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

ManagePrograms.defaultProps = {
  addProgramsDisabled: false,
  manuallyManaged: false,
  removeProgramsDisabled: false,
};

export default ManagePrograms;
