import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useModalManager } from 'components/common/Modal';
import SysadminTable from '../../../common/SysadminTable';
import { ModalButton, sortedMaterials } from '../Utils';
import Row from '../LessonMaterialsTable/Row';
import Form from '../LessonMaterialsTable/Form';

const defaultColumns = [
  { text: '', className: 'image' },
  { text: 'Name', className: 'name' },
  { text: 'Quantity', className: 'quantity' },
  { text: 'Used in', className: 'used-in' },
  { text: 'Source', className: 'program-kit-material' },
  { text: 'Safety Information', className: 'safety-notes' },
  { text: 'Actions', className: 'actions' },
];

type ProgramKitMaterial = {
  material_id: number;
  value: number;
  label: string;
};
type SelectOption = {
  label: string;
  value: number;
};
type Column = {
  text: string;
  className: string;
};
type UnitProjectMaterialsTableProps = {
  columns: Column[];
  endpoint: string;
  isAdmin?: boolean;
  programKitMaterials: ProgramKitMaterial[];
  unitId: string;
  unitNotebooks: SelectOption[];
};

type AddModalButtonProps = {
  distributions: any[];
  isAdmin: boolean;
  materialsForSelect: any[];
  measurementUnits: any[];
  onAdd: (material: any) => void;
  programKitMaterials: any[];
  unitNotebooks: any[];
};

const AddModalButton = ({
  distributions,
  isAdmin,
  materialsForSelect,
  measurementUnits,
  onAdd,
  programKitMaterials,
  unitNotebooks,
}: AddModalButtonProps) => {
  const modalManager = useModalManager();

  if (!isAdmin) return null;

  return (
    <ModalButton
      buttonText="Add Material"
      buttonClass="btn btn--green mr10"
      modalHeader="Add Material"
      modalClass="add-materials"
      modelType="Notebook"
      newRecord
      openModal={modalManager.open}
      closeModal={modalManager.close}
      onAdd={onAdd}
      FormComponent={Form}
      modalIsOpen={modalManager.isOpen}
      forSelect={materialsForSelect}
      distributions={distributions}
      unitNotebooks={unitNotebooks}
      programKitMaterials={programKitMaterials}
      units={measurementUnits}
    />
  );
};

const UnitProjectMaterialsTable = ({
  columns = defaultColumns,
  endpoint,
  isAdmin = false,
  programKitMaterials,
  unitId,
  unitNotebooks,
}: UnitProjectMaterialsTableProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [materialsForSelect, setMaterialsForSelect] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);


  const handleRemove = (id) => {
    setMaterials(prev => prev.filter(material => material.id !== id));
  };

  useEffect(() => {
    setIsLoading(true);

    Axios
      .get(endpoint)
      .then((res) => {
        if (res.data && res.data.materials) {
          setMaterials(sortedMaterials(res.data.materials));
          setMaterialsForSelect(res.data.forSelect);
          setDistributions(res.data.distributions);
          setMeasurementUnits(res.data.units);
        }

        setIsLoading(false);
      });
  }, [unitId]);

  return (
    <SysadminTable
      columns={columns}
      containerClass="unit-materials"
      emptyMessage="No materials found."
      tableId="materials"
      isLoading={isLoading}
      buttonAbove={(
        <div className="h35 inline_block pos_fr">
          <AddModalButton
            distributions={distributions}
            isAdmin={isAdmin}
            materialsForSelect={materialsForSelect}
            measurementUnits={measurementUnits}
            onAdd={material => setMaterials([...materials, material])}
            programKitMaterials={programKitMaterials}
            unitNotebooks={unitNotebooks}
          />
        </div>
      )}
    >
      {materials.map(material => (
        <Row
          admin={isAdmin}
          key={material.id}
          modalHeader="Edit Unit Material"
          newRecord={false}
          removeRow={handleRemove}
          updateRow={() => {}}
          kitMaterialsUrl=""
          usedIn={material.used_in}
          forSelect={materialsForSelect}
          distributions={distributions}
          unitNotebooks={unitNotebooks}
          programKitMaterials={programKitMaterials}
          units={measurementUnits}
          {...material}
        />
      ))}
    </SysadminTable>
  );
};

export default UnitProjectMaterialsTable;
