import React from 'react';
import useShapesStore from './useShapesStore';
import useDrawingContext from '../../store/useDrawingContext';
import { scaleValue } from '../styles';

interface ShapesProps {
  category: string;
  setCategory: (category: string) => void;
  setPopoverIsOpen: (isOpen: boolean) => void;
}

const containerStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  left: `${scaleValue(toolbarEl, 14.75, 16.5)}rem`,
  minWidth: `${scaleValue(toolbarEl, 480, 775)}px`,
  maxHeight: `${scaleValue(toolbarEl, 267, 310)}px`,
});

const imgStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  maxWidth: `${scaleValue(toolbarEl, 30, 50)}px`,
  maxHeight: `${scaleValue(toolbarEl, 30, 50)}px`,
});

const Shapes = ({ category, setCategory, setPopoverIsOpen }: ShapesProps) => {
  if (category === '') return null;

  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const shapes = useShapesStore(state => state.shapes);
  const categoryMap = useShapesStore(state => state.categoryMap);
  const setMode = useDrawingContext(state => state.setMode);
  const setShapeUrl = useDrawingContext(state => state.setShapeUrl);
  const categoryId = categoryMap[category];
  const shapesForCategory = shapes[categoryId];

  const handleClick = (shape) => {
    setMode('shape');
    setShapeUrl(shape.url);
    setPopoverIsOpen(false);
    setCategory('');
  };

  return (
    <div
      className="tw-absolute tw-top-0 tw-bg-white tw-rounded tw-border tw-shadow-md tw-outline tw-outline-1 tw-outline-gray-300 tw-p-2 tw-overflow-auto"
      role="menu"
      style={containerStyle(toolbarEl)}
    >
      <h2
        className="tw-text-center tw-pb-2"
        style={{ fontSize: `${scaleValue(toolbarEl, 120, 140)}%` }}
      >
        {category}
      </h2>
      <p>
        {shapesForCategory.map(shape => (
          <button
            role="menuitem"
            onClick={() => handleClick(shape)}
            key={shape.id}
            type="button"
            className="tw-bg-transparent tw-border-0 hover:tw-cursor-pointer hover:tw-bg-light-grey hover:tw-outline hover:tw-outline-1 tw-inline-flex tw-items-center tw-justify-center"
          >
            <img
              className="tw-w-full tw-h-full tw-p-1"
              style={imgStyle(toolbarEl)}
              src={shape.url}
              alt={shape.category}
            />
          </button>
        ))}
      </p>
    </div>
  );
};

export default Shapes;
