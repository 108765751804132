import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import * as Routes from 'modules/routes';

import Modal, { Footer } from '../../../common/Modal';
import { required } from '../../../common/Forms/Validators';
import { renderErrors, RequiredAsterisk, SubmitError } from '../../../common/Forms/Utils';
import { formatTeacherForSelect, sortingCallback } from '../../../common/Classes/FormUtils';
import styles from '../../../common/Classes/Form.module.scss';
import modalStyles from '../../../common/Classes/Modal.module.scss';

import ReactSelectAdapter from './ReactSelectAdapter';

export default class Add extends Component {
  static propTypes = {
    createPath: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    teachers: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })).isRequired,
    updateTable: PropTypes.func.isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })),
    subscriberId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    programs: []
  };

  constructor(props) {
    super(props);

    this.state = {
      licenses: [],
      programs: [],
      programsLoading: true,
      submitting: false
    };

    this._getLicensesAndPrograms();

    this.openAddModal = this.openAddModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _getLicensesAndPrograms() {
    const licensesPath = Routes.plato_api_subscriber_licenses_path({
      non_welcome: true,
      subscriber_id: this.props.subscriberId,
      with_current_subscriptions: ''
    });

    Axios
      .get(licensesPath)
      .then((res) => {
        const formattedLicenses = res.data.data.map(license => (
          { label: `[${license.code}] ${license.name}`, value: license.id, position: license.position }
        ));

        this.setState({ licenses: formattedLicenses });
      });

    const path = Routes.plato_api_programs_by_license_subscriber_path(this.props.subscriberId);
    Axios
      .get(path)
      .then((res) => {
        this.setState({ programs: res.data, programsLoading: false });
      });
  }

  _getProgramsForLicense(values) {
    if (this.state.programsLoading || !values?.license?.value) return [];

    if (!this.state.programs[values.license.value]) {
      return [];
    }

    return this.state.programs[values.license.value].map(p => ({ label: p.full_title_with_edition, value: p.id }));
  }

  handleSubmit(values) {
    const request_data = {
      classroom: {
        license_id: values.license.value,
        name: values.name,
        period: values.period,
        program_id: values.program.value,
        staffer_id: values.staffer.value
      }
    };

    this.setState({ submitting: true });

    return Axios
      .post(this.props.createPath, request_data)
      .then((response) => {
        const classroom = response.data.data;

        this.props.updateTable({
          ...classroom,
          program: classroom.program.full_title_with_edition,
          students: classroom.student_seats_count
        });
        this.setState({ submitting: false });
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  }

  openAddModal(e) {
    this.props.openModal(e, null, 'add');
  }

  _getTeachersForSelect() {
    return this.props.teachers.map(teacher => formatTeacherForSelect(teacher)).sort(sortingCallback);
  }

  _renderAddClassButton() {
    return (
      <button className="btn btn--green" onClick={this.openAddModal} disabled={this.props.isLoading}>
        Add Class
      </button>
    );
  }

  render() {
    return (
      <div>
        {this._renderAddClassButton()}

        <Modal
          className={modalStyles.modal}
          bodyClassName={modalStyles.modal}
          headerText="Add Class"
          isOpen={this.props.modalIsOpen}
          closeModal={this.props.closeModal}
        >
          <Form
            mutators={{
              clearProgram: (args, state, utils) => {
                utils.changeValue(state, 'license', () => args[0]);
                utils.changeValue(state, 'program', () => {});
              }
            }}
            onSubmit={this.handleSubmit}
            render={({
              handleSubmit, hasValidationErrors, form, pristine, submitError, values
            }) => (
              <form onSubmit={handleSubmit}>
                <span className="sr-only">Fields marked with an asterisk (*) are required.</span>

                <Field
                  id="add-name-field"
                  className={`${styles.input} ${styles.textInput}`}
                  name="name"
                  validate={required}
                >
                  {({input, meta}) => (
                    <div className={styles.row}>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="add-name" className={styles.label}>
                          Class Name<RequiredAsterisk />
                        </label>

                        <input
                          id="add-name"
                          className={styles.textInput}
                          placeholder="Name"
                          {...input}
                        />
                      </div>

                      {
                        meta.error && meta.touched &&
                        <div className={styles.fieldError}>{meta.error}</div>
                      }
                    </div>
                  )}
                </Field>

                <Field
                  id="add-period-field"
                  className={`${styles.input} ${styles.textInput}`}
                  name="period"
                  validate={required}
                >
                  {({input, meta }) => (
                    <div className={styles.row}>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="add-period" className={styles.label}>
                          Period<RequiredAsterisk />
                        </label>

                        <input
                          id="add-period"
                          className={styles.textInput}
                          placeholder="Period"
                          {...input}
                        />
                      </div>

                      {
                        meta.error && meta.touched &&
                        <div className={styles.fieldError}>{meta.error}</div>
                      }
                    </div>
                  )}
                </Field>

                <div className={styles.row}>
                  <div className={styles.fieldContainer}>
                    <label htmlFor="add-teacher" className={styles.label}>
                      Teacher<RequiredAsterisk />
                    </label>
                    <Field
                      className={styles.input}
                      component={ReactSelectAdapter}
                      id="add-teacher"
                      name="staffer"
                      options={this._getTeachersForSelect()}
                      required
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.fieldContainer}>
                    <label htmlFor="add-license" className={styles.label}>
                      License<RequiredAsterisk />
                    </label>
                    <Field
                      className={styles.input}
                      component={ReactSelectAdapter}
                      id="add-licesnse"
                      name="license"
                      placeholder="Select License"
                      onChange={form.mutators.clearProgram}
                      options={this.state.licenses.sort((a, b) => { return (a.position > b.position) ? 1 : -1; })}
                      required
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.fieldContainer}>
                    <label htmlFor="add-program" className={styles.label}>
                      Program<RequiredAsterisk />
                    </label>
                    <Field
                      className={styles.input}
                      component={ReactSelectAdapter}
                      disabled={this.state.programsLoading}
                      id="add-program"
                      name="program"
                      placeholder="Select Program"
                      options={this._getProgramsForLicense(values)}
                      required
                    />
                  </div>
                </div>

                <SubmitError error={submitError} />

                <Footer
                  secondaryButtonCallback={this.props.closeModal}
                  primaryButtonDisabled={pristine || hasValidationErrors}
                  submitting={this.state.submitting}
                />
              </form>
            )}
          />
        </Modal>
      </div>
    );
  }
}
