const getNextLetter = (objects) => {
  const keys = Object.keys(objects || {}).sort();

  if (keys.length === 0 || keys[0] !== 'a') return 'a';

  const letters = keys.map(letter => letter.charCodeAt(0));

  const nextLetterCharCode = letters.find((letter, index) => letters[index + 1] !== letter + 1) + 1;

  return String.fromCharCode(nextLetterCharCode);
};

export default getNextLetter;
