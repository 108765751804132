import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { required } from 'common/Forms/Validators';

import showToast from 'common/Toast';

import styles from './MyAccount.module.scss';
import Footer from '../Footer';

function passwordsMatch(passwords) {
  return passwords.password === passwords.password_confirmation;
}

export function submit(studentPath, values) {
  return Axios.put(studentPath, values).then((response) => {
    if (response.data.success) {
      showToast('Password change successful');
    }
    else {
      showToast('Password change failed', { msgType: 'error' });
    }
  }).catch(() => {
    showToast('Password change failed', { msgType: 'error' });
  });
}

export default function ChangePasswordForm({
  studentPath, onSubmit
}) {
  return (
    <Form
      onSubmit={values => onSubmit(studentPath, values)}
      validate={(values) => {
        const errors = {};
        if (values.student && !passwordsMatch(values.student)) {
          if (!errors.student) {
            errors.student = {};
          }

          errors.student.password_confirmation = "Passwords don't match";
        }

        return errors;
      }}
    >
      {({ handleSubmit, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.fieldGroup}>
            <label htmlFor="new-password" className={`bold ${styles.label}`}>
              New Password
            </label>
            <Field
              className={styles.input}
              id="new-password"
              name="student[password]"
              component="input"
              type="password"
              validate={required}
            />
          </div>

          <div className={styles.fieldGroup}>
            <Field
              name="student[password_confirmation]"
              type="password"
            >
              {({ input, meta }) => (
                <div>
                  <div>
                    <label htmlFor="confirm-password" className={`bold ${styles.label}`}>
                      Confirm Password
                    </label>

                    <input
                      id="confirm-password"
                      className={styles.input}
                      type="password"
                      {...input}
                    />
                  </div>

                  {
                    meta.error && meta.touched &&
                    <div className={styles.fieldError}>{meta.error}</div>
                  }
                </div>
              )}
            </Field>
          </div>

          <div className={styles.divider} />

          <div>
            <p className="bold mb20">
              Your current password is required for all changes to your account settings
            </p>

            <div className={styles.fieldGroup}>
              <label htmlFor="current-password" className={`bold ${styles.label}`}>
                Confirm Password
              </label>
              <Field
                className={styles.input}
                id="current-password"
                name="student[current_password]"
                component="input"
                type="password"
                validate={required}
              />
            </div>
          </div>
          <Footer disabled={hasValidationErrors} />
        </form>
      )}
    </Form>
  );
}

ChangePasswordForm.propTypes = {
  studentPath: PropTypes.string.isRequired,
  onSubmit: PropTypes.func
};

ChangePasswordForm.defaultProps = {
  onSubmit: submit
};
