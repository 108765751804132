import React from 'react';
import { Button } from '@/components/ui/Buttons';
import cn from '@/utils/cn';
import I18n from 'modules/i18n';
import paintIcon from '../paint-icon.svg';
import useDrawingContext from '../store/useDrawingContext';

interface DisabledOverlayProps {
  modalManager: ModalManager;
}

const DisabledOverlay = ({ modalManager }: DisabledOverlayProps) => {
  const disabled = useDrawingContext(state => state.disabled);
  const builder = useDrawingContext(state => state.builder);
  const editable = useDrawingContext(state => state.editable);

  const buttonText = builder ? 'Edit Canvas' : I18n.t('draw');

  return (
    <div
      aria-label="Edit drawing"
      className={cn(
        'tw-bg-[#000000] tw-absolute tw-inset-0 tw-z-20 tw-cursor-pointer tw-bg-opacity-20',
        'tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-lg tw-transition-all tw-rounded-xl',
      )}
      onKeyDown={e => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          modalManager.open();
        }
      }}
      tabIndex={0}
      role="button"
    >
      {editable && (
        <Button
          className={cn(
            'tw-text-lg tw-flex tw-items-center tw-justify-center tw-min-w-[200px]',
            'group-hover:tw-bg-[#422574] group-hover:tw-border-[#422574]',
          )}
        >
          <img
            aria-hidden="true"
            alt="Paint brush icon"
            src={paintIcon}
            className="tw-w-12 tw-h-12"
          /> {buttonText}
        </Button>
      )}
    </div>
  );
};

export default DisabledOverlay;
