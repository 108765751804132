import React from 'react';

const BannerIcon = () => (
  <svg width="53" height="65" viewBox="0 0 53 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3452_19082)">
      <path d="M5 45.5V1H48V45.5L27.0658 56L5 45.5Z" stroke="currentColor" fill="currentColor" />
    </g>
    <defs>
      <filter
        id="filter0_d_3452_19082"
        x="0.5"
        y="0.5"
        width="52"
        height="64.0564"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3452_19082" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3452_19082" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default BannerIcon;
