import React from 'react';
import useCompletedStatus from '@/hooks/useCompletedStatus';
import inProgressIcon from 'images/icons/in_progress.svg';

interface CompletedStatusIconProps {
  id: number | string;
  modelType: 'HtmlElements::QuestionButton' | 'SlideObject';
}

const Completed = () => (
  <div className={`tw-rounded-full tw-w-5 tw-h-5 tw-bg-white tw-inline-flex tw-items-center tw-justify-center
                   tw-border-solid tw-border-white`}>
    <i
      className={`fa fa-check tw-text-tci-purple tw-rounded-full tw-w-[1.1rem] tw-h-[1.1rem] tw-border-tci-purple
                  tw-bg-white tw-box-border tw-border-solid tw-border-[1.5px] tw-inline-flex tw-items-center
                  tw-justify-center tw-text-xs`}
    />
  </div>
);

const InProgress = () => (
  <div className="tw-rounded-full tw-w-5 tw-h-5 tw-bg-white tw-inline-flex tw-items-center tw-justify-center">
    <img src={inProgressIcon} className="tw-w-5" alt="In Progress icon" />
  </div>
);

const CompletedStatusIcon = ({ id, modelType }: CompletedStatusIconProps) => {
  const { data, isLoading } = useCompletedStatus({ id, modelType });

  if (isLoading || !data.status || data.status === 'not_started') return null;

  return data.status === 'completed' ? <Completed /> : <InProgress />;
};

export default CompletedStatusIcon;
