import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/components/staffer/Scores/GradesTable/GradesTableQueryClientProvider';
import axios from 'axios';
import { plato_api_reading_games_path } from '@/modules/routes';
import { IReadingGame } from '@/components/staffer/Scores/GradesTable/types';

const useReadingGames = (lessonId: number) => {
  const isEnabled = !!lessonId;
  const queryParams = { for_lesson: lessonId, published: true, extra_attributes: ['max_points', 'visible_to_user'] };

  const query = useQuery([QueryKeys.ReadingGames, lessonId], () => (
    axios
      .get<{ data: IReadingGame[] }>(plato_api_reading_games_path(queryParams))
      .then((res) => {
        if (!res.data.data) return [];

        return res.data.data.filter(datum => datum.visible_to_user);
      })
      .catch(() => ([] as IReadingGame[]))
  ), { enabled: isEnabled, placeholderData: [], staleTime: Infinity });

  return { isEnabled, ...query };
};

export default useReadingGames;
