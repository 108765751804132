import React from 'react';
import I18n from 'i18n-js';
import Pluralize from 'pluralize';
import styles from './MultipleChoiceQuestion.module.scss';
import { arraysEqual } from '../Utils';

function numberArrToAlphaArr(arr: number[] | string[], questionType: string) {
  if (questionType === 'FillInTheBlank') return arr.join(', ');

  const alphaUpperStart = 65;
  const charCode = numString => {
    const parsedInt = parseInt(numString, 10);
    return Number.isNaN(parsedInt) ? numString : String.fromCharCode(parsedInt + alphaUpperStart);
  };

  return arr.map(answer => charCode(answer)).join(', ');
}

type Values = {
  correctChoices: any[];
  correctChoicesIndexes?: number[];
  disabled?: boolean;
  hasSubmitted?: boolean;
  maxAttempts: number;
  maxPoints?: number;
  pointsEarned: number;
  previousAnswers: any[];
  questionType: '' | 'FillInTheBlank' | 'DragAndDrop';
  savedResult?: {
    answer?: string;
    previousAnswers?: string[];
    updated_at?: string;
  }
  submitting: boolean;
  value: any;
};

export default class SubmissionTextHelper {
  correctChoices: any[];

  correctChoicesIndexes: number[];

  disabled: boolean;

  hasSubmitted: boolean;

  maxAttempts: number;

  maxPoints: number;

  pointsEarned: number;

  previousAnswers: any[];

  questionType: string;

  savedResult: {
    answer?: string;
    answerData?: string;
    previousAnswers?: string[];
    updated_at?: string;
  };

  submitting: boolean;

  value: any;

  constructor({
    correctChoices,
    correctChoicesIndexes = [],
    disabled = false,
    hasSubmitted = false,
    maxAttempts,
    pointsEarned,
    previousAnswers,
    savedResult,
    submitting,
    value,
    questionType,
    maxPoints,
  }: Values) {
    this.correctChoices = correctChoices;
    this.correctChoicesIndexes = correctChoicesIndexes;
    this.disabled = disabled;
    this.hasSubmitted = hasSubmitted;
    this.maxAttempts = maxAttempts;
    this.pointsEarned = pointsEarned;
    this.previousAnswers = previousAnswers;
    this.submitting = submitting;
    this.value = value;
    this.questionType = questionType;
    this.savedResult = savedResult;
    this.maxPoints = maxPoints;
  }

  hasCorrectAnswer() {
    if (this.questionType === 'DragAndDrop') {
      let currentAnswer = this.previousAnswers[this.previousAnswers.length - 1];
      if (!currentAnswer) return false;

      currentAnswer = JSON.parse(currentAnswer);

      return this.hasSubmitted && Object.keys(this.correctChoices).every(key => {
        return arraysEqual(this.correctChoices[key], currentAnswer[key]);
      });
    }

    if (this.questionType === 'FillInTheBlank') {
      const currentAnswer = this.previousAnswers[this.previousAnswers.length - 1];
      if (!currentAnswer || this.correctChoicesIndexes.length === 0) return false;

      return this.hasSubmitted && this.correctChoicesIndexes.every((answer, index) => {
        return answer === currentAnswer[index];
      });
    }

    return this.hasSubmitted && this.pointsEarned > 0;
  }

  hasPartiallyCorrectAnswer() {
    return this.isPartiallyCorrect() && this.previousAnswers.length === this.maxAttempts;
  }

  isPartiallyCorrect() {
    if (!(['DragAndDrop', 'FillInTheBlank'].includes(this.questionType))) return false;

    const decimalPointsEarned = parseFloat((this.pointsEarned || 0).toString());
    return (
      this.hasSubmitted &&
        decimalPointsEarned > 0 &&
        this.maxPoints > decimalPointsEarned
    );
  }

  cannotSubmit() {
    // this happens when a FITB has a saved answer but not submitted
    if (this.questionType === 'FillInTheBlank'
      && this.savedResult?.answerData
      && this.previousAnswers.length === 0) {
      return false;
    }
    if (this.submitting) return true;
    if (!this.value) return true;

    if (this.questionType === 'FillInTheBlank'
      && this.previousAnswers.some((ans => JSON.stringify(ans) === JSON.stringify(this.value)))) return true;

    if (this.previousAnswers.includes(this.value)) return true;

    return this.isDisabled();
  }

  isDisabled() {
    return this.previousAnswers.length >= this.maxAttempts || this.hasCorrectAnswer() || this.disabled;
  }

  submissionAlert() {
    const fontColorClass = this.submissionFontColor();

    return (
      <span id="submission-alert" className={fontColorClass}>
        <i
          className={`fa fa-${this.submissionIcon()} ${fontColorClass === '' ? styles.incorrectIconColor : ''}`}
          aria-hidden="true"
        />
        {this.previousAnswers.length === 0 ? '' : this.submissionText()}
      </span>
    );
  }

  messageWithPoints(translationKey) {
    return `${I18n.t(translationKey, { points: this.pointsEarned })} ${Pluralize(I18n.t('point').toLowerCase(), Math.ceil(this.pointsEarned))}.`
  }

  private submissionText() {
    if (this.hasCorrectAnswer()) {
      return this.messageWithPoints('submission.correct');
    }

    if (this.hasPartiallyCorrectAnswer()) {
      return this.messageWithPoints('submission.partially_correct');
    }

    if (this.isPartiallyCorrect() && this.previousAnswers.length < this.maxAttempts) {
      return I18n.t('submission.partially_correct_incomplete');
    }

    if (this.previousAnswers.length < this.maxAttempts) {
      return I18n.t('submission.incorrect_incomplete');
    }

    if (this.questionType === 'DragAndDrop') {
      return I18n.t('submission.incorrect_complete');
    }

    if (this.correctChoices.length === 0) {
      return I18n.t('submission.incorrect_complete');
    }

    return I18n.t('submission.incorrect_with_answer', { answer: numberArrToAlphaArr(this.correctChoices || [], this.questionType) });
  }

  private submissionFontColor() {
    if (this.previousAnswers.length === 0) return 'n/a';
    if (this.hasCorrectAnswer() || this.hasPartiallyCorrectAnswer()) return 'text--green';
    if (this.previousAnswers.length < this.maxAttempts) return '';

    return 'text--red';
  }

  private submissionIcon() {
    if (this.hasCorrectAnswer() || this.hasPartiallyCorrectAnswer()) return 'check';
    if (this.previousAnswers.length === 0) return '';
    if (this.previousAnswers.length < this.maxAttempts) return 'exclamation-triangle';

    return 'times';
  }
}
