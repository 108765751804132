import React from 'react';
import ButtonSnippet from '@/components/common/HtmlElements/ButtonSnippet';
import IHintButton from '@/components/interfaces/graphql/HtmlElement/HintButton';

interface SnippetProps {
  hintButton: IHintButton;
}

const Snippet = ({ hintButton: { id, type, data: { autoHide, backgroundColor = 'purple' } } }: SnippetProps) => (
  <ButtonSnippet
    autoHide={autoHide}
    backgroundColor={backgroundColor}
    className="fr-deletable fa fa-plus-circle"
    id={id}
    type={type}
  />
);

export default Snippet;
