import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VideoPlayer from '../../../common/VideoPlayer';

interface ParsedVideoProps {
  height: number | string;
  fallbackElement: HTMLElement;
  maxWidth: number | string;
  shouldAutoPlayVideo?: boolean;
  url: string;
}

interface UseParsedVideoProps {
  hasVideo: boolean,
  height?: number | string,
  props?: ParsedVideoProps
}

const extraAttributes = ['sd_url', 'hd_url', 'fhd_url', 'sd_es_url', 'hd_es_url', 'fhd_es_url'] as const;
const extraAttributeParams = extraAttributes.map(url => `extra_attributes[]=${url}`).join('&');

export const useParsedVideo = (element: HTMLElement): UseParsedVideoProps => {
  const $video = $($(element).find('video'));

  if ($video.length === 0) return { hasVideo: false };

  const url = $video.find('Source:first').attr('src');

  const widthString = window.getComputedStyle($video[0]).width || $video[0].width.toString();
  const heightString = window.getComputedStyle($video[0]).height;
  const width = widthString.includes('%') ? (
    widthString
  ) : parseInt(widthString.replace('px', ''), 10);
  const height = (heightString === '' || heightString.includes('%') || heightString.includes('auto')) ? (
    heightString
  ) : parseInt(heightString.replace('px', ''), 10);

  return {
    hasVideo: true,
    height,
    props: { height, url, maxWidth: width, fallbackElement: element },
  };
};

const ParsedVideo = ({ height, fallbackElement, maxWidth, shouldAutoPlayVideo, url }: ParsedVideoProps) => {
  const [video, setVideo] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const matches = url.match(/\/\d+\//);

    if (!matches || matches.length === 0) return;

    const id = matches[0].replaceAll('/', '');

    axios
      .get(`/api/v2/videos/${id}?${extraAttributeParams}`)
      .then((res) => {
        if (res.data.video) {
          setVideo(res.data.video);
        }
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  if (isLoading) return null;

  // if done loading and no video is found, find video that was hidden and display it
  if (!(isLoading || video)) {
    $(fallbackElement).find('video').css({ display: 'block' });
    return null;
  }

  $(fallbackElement).remove();
  return (
    <VideoPlayer
      height={height}
      maxWidth={maxWidth}
      shouldAutoPlay={shouldAutoPlayVideo}
      video={video}
    />
  );
};

export default ParsedVideo;
