import React from 'react';
import clsx from 'clsx';
import Tooltip from 'components/common/Tooltip';
import Spinner from '@/components/common/Spinner';
import useAsyncFileDownload from '@/hooks/useAsyncFileDownload';

type ItemType = 'Certificate' | 'Badge';

interface DisabledTooltipProps {
  itemType: ItemType;
}

const DisabledTooltip = ({ itemType }: DisabledTooltipProps) => (
  <Tooltip content={`Please complete the course to download your ${itemType.toLowerCase()}.`} interactive={false}>
    <span className="tw-absolute tw-inset-0" />
  </Tooltip>
);

interface DownloadLinkProps {
  courseCompleted: boolean;
  dropdownIcon: string;
  fileName: string;
  itemType: ItemType;
  itemUrl: string;
}

const DownloadLink = ({ courseCompleted, dropdownIcon, fileName, itemType, itemUrl }: DownloadLinkProps) => {
  const { isLoading, onClick } = useAsyncFileDownload(itemUrl, fileName);

  return (
    <div className="tw-relative">
      {!courseCompleted && <DisabledTooltip itemType={itemType} />}
      <button
        className="dropdown-item tw-min-w-full tw-flex tw-cursor-pointer tw-items-center tw-gap-1.5"
        onClick={onClick}
        type="button"
        disabled={!courseCompleted || isLoading}
      >
        {isLoading && <Spinner className="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-y-1/2 -tw-ml-1.5" />}
        <img
          className={clsx('tw-w-4', {
            'tw-opacity-30': !courseCompleted || isLoading,
          })}
          src={dropdownIcon}
          alt={`${itemType} Icon`}
        />
        Download {itemType}
      </button>
    </div>
  );
};

export default DownloadLink;
