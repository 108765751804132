import React, { useEffect, useState } from 'react';
import { ISlideOrSlideGroup } from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { UnansweredQuestion } from '@/components/admin/SlideShow/types';
import I18n from 'i18n';
import UnansweredQuestionsLink from './UnansweredQuestionsLink';

interface UnansweredQuestionsProps {
  unansweredQuestions: any[];
}

const formatQuestions = (unansweredQuestions: UnansweredQuestion[]) => {
  return unansweredQuestions.reduce((questions, question) => {
    const { slideId } = question;
    // eslint-disable-next-line no-param-reassign
    if (typeof questions[slideId] === 'undefined') questions[slideId] = [];

    questions[slideId].push(question);

    return questions;
  }, {});
};

const UnansweredQuestions = ({ unansweredQuestions }: UnansweredQuestionsProps) => {
  const formattedSlides: ISlideOrSlideGroup[] = useSlideShowContext(state => state.slides);
  const [formattedQuestions, setFormattedQuestions] = useState({});

  useEffect(() => {
    setFormattedQuestions(formatQuestions(unansweredQuestions));
  }, [unansweredQuestions]);

  const unansweredQuestionsMessage = () => I18n.t(
    unansweredQuestions.length > 1 ? 'unanswered_questions' : 'unanswered_question',
    { quantity: unansweredQuestions.length },
  );

  return (
    <div
      className="tw-border tw-border-red tw-border-solid tw-mt-2 tw-w-[75%] tw-flex tw-justify-center tw-p-3 tw-flex-col tw-items-center tw-overflow-auto"
    >
      <span className="tw-text-red">
        <i className="fa fa-info-circle" />
        &nbsp;{unansweredQuestionsMessage()}
      </span>
      <div className="tw-flex tw-flex-col tw-max-h-[225px] tw-mt-3">
        {Object.keys(formattedQuestions).map(slideId => (
          <UnansweredQuestionsLink
            key={`unanswered-questions-${slideId}`}
            slide={formattedSlides.find(formattedSlide => formattedSlide.id === slideId)}
            unansweredQuestions={formattedQuestions[slideId]}
          />
        ))}
      </div>
    </div>
  );
};

export default UnansweredQuestions;
