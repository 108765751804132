import React from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { Button } from '@/components/ui/Buttons';
import cn from '@/utils/cn';
import Froala from '@/components/shared/Froala';
import IHotspot from '@/components/interfaces/Hotspot';
import './hotspot-froala-styles.scss';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { CUSTOM_IMAGE_COMMAND_NAME } from '@/components/shared/Froala/froalaConstants';
import {
  graphQlImageModelToTag,
} from '@/components/shared/Froala/froalaUtils';
import { insertIntoEditor }
  from '@/components/admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/FroalaListeners';
import { useDeleteHotspot, useUpdateHotspot } from '../../panoramicImageUtils';
import usePanoramicImageStore from '../usePanoramicImageStore';

interface HotspotEditorProps {
  index: number;
  hotspot: IHotspot;
}

const HotspotEditor = ({ index, hotspot }: HotspotEditorProps) => {
  const setCurrentlyPlacing = usePanoramicImageStore(state => state.setCurrentlyPlacing);
  const currentlyPlacing = usePanoramicImageStore(state => state.currentlyPlacing);
  const panoramicImage = usePanoramicImageStore(state => state.panoramicImage);
  const viewer = usePanoramicImageStore(state => state.viewer);
  const updateHotspot = useUpdateHotspot();
  const deleteHotspot = useDeleteHotspot(hotspot.id);
  const debounceUpdateHotspot = useDebounceCallback(updateHotspot, 800);
  const locale = usePanoramicImageStore(state => state.locale);

  const handleChange = (text: string) => {
    debounceUpdateHotspot.cancel();
    const hotspotContentKey = locale == 'es' ? 'hotspotContent_es' : 'hotspotContent';
    debounceUpdateHotspot({ id: hotspot.id, [hotspotContentKey]: text });
  };
  const handleDelete = () => deleteHotspot();

  const isBeingPlaced = currentlyPlacing?.id === hotspot.id;

  const handleClick = () => {
    if (viewer) {
      const markerPlugin: MarkersPlugin = viewer.getPlugin(MarkersPlugin);
      markerPlugin.gotoMarker(hotspot.id.toString(), '5rpm');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key !== 'Enter') return;

    handleClick();
  };

  return (
    <div
      key={hotspot.id}
      className={cn(
        'tw-bg-light-grey tw-p-4 tw-border tw-border-light-grey tw-rounded tw-relative hotspot-editor',
        { 'tw-border-black': isBeingPlaced },
      )}
    >
      {isBeingPlaced && (
        <div
          className={`
            tw-absolute tw-rounded tw-border-solid tw-border-black tw-border tw-top-0 tw-left-0 tw-w-full tw-h-full
            tw-flex tw-items-center tw-justify-center tw-bg-[rgba(255,255,255,0.5)]
          `}
        >
          Click on the panoramic image to {hotspot.placed ? 'move' : 'place'} this hotspot
        </div>
      )}

      <div className="tw-flex tw-justify-between tw-items-center tw-pb-2">
        <div>
          <span className="tw-text-base">{`Hotspot ${index + 1}`}</span>
        </div>
        <div className="tw-flex tw-justify-center">
          <Button
            onClick={() => setCurrentlyPlacing(hotspot)}
            outlineStyle
            small
          >
            {hotspot.placed ? 'Move' : 'Place'} Hotspot
          </Button>
          <button className="tw-text-red tw-text-[20px] tw-ml-4 tw-border-none tw-bg-inherit tw-p-0" onClick={handleDelete} type="button">
            <i className="fa fa-trash-o fa-fw no-toggle" />
          </button>
        </div>
      </div>
      <div
        className={`
          tw-flex tw-items-center tw-justify-center tw-border tw-rounded-md 
          tw-overflow-hidden tw-border-[#CCC] tw-border-solid
        `}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <Froala
          customConfig={{
            height: 200,
            toolbarButtons: [
              ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript', 'fontSize'],
              ['indent', 'outdent', 'clearFormatting', 'formatOL', 'formatUL', 'textColor', CUSTOM_IMAGE_COMMAND_NAME],
            ],
            width: 'auto',
            imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|',
              'imageLink', 'linkOpen', 'linkEdit', 'linkRemove',
              '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
          }}
          modelType="Hotspot"
          modelId={panoramicImage.id}
          name={`Hotspot-${hotspot.id}-hotspotContent`}
          text={locale == 'es' ? hotspot.hotspotContent_es : hotspot.hotspotContent}
          userType="Admin"
          onChange={handleChange}
          AddImageProps={{
            excludeFields: ['alignment'],
            afterSubmit: (imageModel, editorInstance) => {
              insertIntoEditor(editorInstance, graphQlImageModelToTag(imageModel));
            },
          }}
        />
      </div>
    </div>
  );
};

export default HotspotEditor;
