import { IClassroom } from '@/hooks/api/useClassrooms';
import ReactDOM from 'react-dom';
import moment from 'moment/moment';
import React, { useMemo } from 'react';

export const COLUMN_NAMES = [
  'id',
  'Class Name',
  'Class Period',
  'Students',
  'Term Name',
  'Term Start',
  'Term End',
];

export const useColumnDefs = () => (
  useMemo(() => (
    [
      {
        className: '!tw-text-transparent tw-align-middle',
        createdCell: (td: any, _cellData: any, rowData: IClassroom) => {
          ReactDOM.render(
            <input
              id={`radio-${rowData.id}`}
              type="radio"
              value={rowData.id}
              name="selected-classroom"
            />,
            td,
          );
        },
        searchable: false,
        sortable: false,
        targets: 0,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => row.name,
        searchable: false,
        sortable: true,
        targets: 1,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => row.period,
        searchable: false,
        sortable: true,
        targets: 2,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => row.student_seats_count,
        searchable: false,
        sortable: true,
        targets: 3,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => row.term_name,
        searchable: false,
        sortable: true,
        targets: 4,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => moment(row.term_start_date).format('MMM DD, YYYY'),
        searchable: false,
        sortable: true,
        targets: 5,
      },
      {
        className: 'tw-align-middle',
        render: (_data: any, _type: any, row: IClassroom) => moment(row.term_end_date).format('MMM DD, YYYY'),
        searchable: false,
        sortable: true,
        targets: 6,
      },
    ]
  ), [])
);
