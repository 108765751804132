import React, { useState } from 'react';
import Axios from 'axios';
import { Form } from 'react-final-form';
import { TextField } from '@/components/common/Forms';
import { Footer } from '@/components/common/Modal';
import * as Routes from '@/modules/routes';

interface CodeFormProps {
  afterSubmit?: (valid) => void;
  inline?: boolean;
  invalidCode?: string;
  errorMessage?: string;
  handleSubmit?: () => any;
  modalManager?: ModalManager;
  userId?: string;
}

interface FormWrapperProps {
  children: ReactChildren;
  handleSubmit?: () => any;
  inline?: boolean;
}

const FormWrapper = ({ children, handleSubmit, inline }: FormWrapperProps) => {
  if (inline) {
    return (
      <form
        autoComplete="off"
        className="simple_form edit_staffer"
        method="post"
        id="edit_staffer"
        noValidate
        action="/staff/sign_in"
      >
        {children}
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

const CodeForm = ({ afterSubmit, inline, invalidCode, modalManager, userId }: CodeFormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [formCode, setFormCode] = useState('');
  const initialValues = inline ? { staffer: { mfa_code: invalidCode } } : { code: formCode };

  const onSubmit = inline ? () => {} : async ({ code }) => {
    setSubmitting(true);
    const { data: { valid } } = await Axios.post<{ valid: boolean }>(
      Routes.plato_api_validate_otp_staffer_path(userId, { code }),
    );
    setSubmitting(false);
    setFormCode(code);
    if (afterSubmit) afterSubmit(valid);

    return valid ? modalManager?.close() : { code: 'Invalid code' };
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitErrors, values }) => {
        const errorMessage = () => {
          if (submitting) return undefined;
          if (pristine) {
            if (invalidCode) return 'Invalid code';
            if (values?.code) return submitErrors?.code;
          }
          if (!values?.staffer?.mfa_code && !values?.code) return 'Required';

          return undefined;
        };

        const goHome = () => {
          window.location.assign(Routes.new_staffer_session_path());
        };

        return (
          <FormWrapper inline={inline} handleSubmit={handleSubmit}>
            <span className={`tw-flex tw-flow tw-items-center ${!inline && '!tw-pl-5'}`}>
              <TextField
                className="!tw-w-[275px]"
                aria-label="code"
                name={inline ? 'staffer[mfa_code]' : 'code'}
                placeholder="Code"
                autoFocus
                hideLabel
              />
              <span>
                <span className="tw-text-[red] tw-pl-2">
                  {errorMessage()}
                </span>
              </span>
            </span>
            <Footer
              className="tw-flex tw-flex-row tw-content-end tw-items-center tw-justify-end"
              primaryButtonDisabled={!!errorMessage() || submitting}
              primaryButtonText={inline ? 'Submit' : 'Save'}
              secondaryButtonText={inline ? 'Back' : 'Cancel'}
              secondaryButtonCallback={modalManager?.close || goHome}
            />
          </FormWrapper>
        );
      }}
    />
  );
};

export default CodeForm;
