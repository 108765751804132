import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import styles from './ModalContent.module.scss';

export default class ModalContent extends Component {
  static propTypes = {
    administrators: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string.isRequired
    })),
    ajaxPath: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    classNames: PropTypes.string,
    customMessage: PropTypes.string,
  };

  static defaultProps = {
    administrators: null,
    isLoading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      administrators: this.props.administrators,
      isLoading: this.props.isLoading
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this._getAdminInfo();
  }

  _getAdminInfo() {
    return Axios.get(this.props.ajaxPath)
      .then((response) => {
        const { administrators } = response.data;

        this.setState({
          administrators,
          isLoading: false
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  Rows() {
    if (this.state.isLoading || !this.state.administrators) {
      return null;
    }

    return this.state.administrators.map(staffer => (
      <tr key={staffer.id}>
        <td>{staffer.fullName}</td>
        <td><a href={`mailto:${staffer.email}`}>{staffer.email}</a></td>
      </tr>
    ));
  }

  Table() {
    return (
      <table className="table table--sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>

        <tbody>
          {this.Rows()}
        </tbody>
      </table>
    );
  }

  noAdminsMessage() {
    return (
      <>
        Please contact&nbsp;
        <a href="mailto:info@teachtci.com">info@teachtci.com</a>
        &nbsp;if you have questions about your TCI access
      </>
    );
  }

  render() {
    const hasAdmins = (this.state.administrators || []).length > 0;
    const message = this.props.customMessage || (hasAdmins
      ? 'Please contact a TCI administrator for your school district (listed below) if you have questions about your TCI access.'
      : this.noAdminsMessage());

    return (
      <div className={this.props.classNames}>
        {this.state.isLoading ?
          <i className="fa fa-spinner fa-spin" /> :
          <>
            <p className={styles.message}>
              {message}
            </p>
            {hasAdmins && this.Table()}
          </>
        }
      </div>
    );
  }
}
