import PropTypes from 'prop-types';

const imagePropTypes = PropTypes.shape({
  credits: PropTypes.string,
  descriptionEn: PropTypes.string,
  descriptionEs: PropTypes.string,
  fileName: PropTypes.string,
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  resolution: PropTypes.string,
  thumbnail: PropTypes.string,
  titleEn: PropTypes.string,
  titleEs: PropTypes.string
});

export const imageModelPropTypes = PropTypes.shape({
  alignment: PropTypes.string,
  captionEn: PropTypes.string,
  captionEs: PropTypes.string,
  decorative: PropTypes.bool.isRequired,
  displaySize: PropTypes.string.isRequired,
  displayStyle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: imagePropTypes.isRequired,
  snippet: PropTypes.string.isRequired,
  zoomType: PropTypes.string.isRequired
});

export const slideTemplateObjectPropTypes = PropTypes.shape({
  height: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired,
  width: PropTypes.number.isRequired,
});

export const slideObjectPropTypes = PropTypes.shape({
  html: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageModel: imageModelPropTypes,
  index: PropTypes.number,
  isDragAndDrop: PropTypes.bool,
  slideId: PropTypes.number,
});

export const slidePropTypes = PropTypes.shape({
  backgroundPosition: PropTypes.string,
  buildingTowardsModelTags: PropTypes.arrayOf(PropTypes.shape({
    tagId: PropTypes.number.isRequired,
  })),
  displaySlideHeader: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageModel: imageModelPropTypes,
  modelTags: PropTypes.arrayOf(PropTypes.shape({
    tagId: PropTypes.number.isRequired,
  })),
  notes: PropTypes.string,
  notesEn: PropTypes.string,
  notesEs: PropTypes.string,
  position: PropTypes.number.isRequired,
  slideGroupIcon: PropTypes.string,
  slideGroupTitleEn: PropTypes.string,
  slideGroupTitleEs: PropTypes.string,
});

export const videoPropTypes = PropTypes.shape({
  caption_url: PropTypes.string,
  is_silent: PropTypes.bool.isRequired,
  poster_url: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});


/* FUNCTIONS */

export const getNewSlidePosition = (currentSlide, formattedSlides = []) => {
  if (!currentSlide) return undefined;

  const slideGroups = formattedSlides
    .filter(s => s.__typename === 'SlideGroup' && s.position < currentSlide.position);

  const offset = slideGroups.length;

  return currentSlide.position - offset + 1;
};

export const addFileToInput = ({ callback, filename, inputElementId, url }) => {
  if (!url) return;

  fetch(url)
    .then(res => res.blob())
    .then((blob) => {
      const file = new File([blob], filename, { lastModified: new Date().getTime(), type: `${blob.type}` });
      callback(file);
      const fileInputElement = document.getElementById(inputElementId);
      if (!fileInputElement) return;

      const container = new DataTransfer();
      container.items.add(file);
      fileInputElement.files = container.files;
    });
};
