import update from 'immutability-helper';

const dragAndDropSettingsReducer = (dragAndDropSettings, { payload, type }) => {
  switch (type) {
    case 'SET_TRANSPARENT_ANSWER_CHOICES':
      return update(dragAndDropSettings, {
        $merge: { hasTransparentAnswerChoices: payload.hasTransparentAnswerChoices }
      });
    case 'SET_TRANSPARENT_DROPZONES':
      return update(dragAndDropSettings, {
        $merge: { hasTransparentDropzones: payload.hasTransparentDropzones }
      });
    case 'SET_DRAGGABLE_CONTAINER_POSITION':
      return update(dragAndDropSettings, {
        $merge: { draggableContainerPosition: payload.draggableContainerPosition }
      });
    case 'SET_DROP_ZONE_SHAPE':
      return update(dragAndDropSettings, {
        $merge: { dropZoneShape: payload.dropZoneShape }
      });
    default:
      return dragAndDropSettings;
  }
};

export default dragAndDropSettingsReducer;
