import React from 'react';
import I18n from 'i18n-js';
import engineeringGearIcon from 'images/icons/engineering_icon.svg';
import BannerIcon from '@/components/shared/NewDashboard/Cards/LessonCard/BannerIcon';
import Tooltip from '@/components/common/Tooltip';

interface EngineeringBannerProps {
  className?: string;
  unitColor: string;
  showTooltip?: boolean;
}

const EngineeringBanner = ({ className, unitColor, showTooltip = true }: EngineeringBannerProps) => {
  const tooltipText = I18n.t('lesson_engineering_tooltip');

  return (
    <Tooltip content={tooltipText} shouldDisplay={showTooltip}>
      <div className={className} style={{ color: `#${unitColor}` }}>
        <BannerIcon />
        <img
          src={engineeringGearIcon}
          alt={tooltipText}
          className="tw-absolute tw-inset-x-1/2 tw-z-[1001] tw--translate-x-1/2"
        />
      </div>
    </Tooltip>
  );
};

export default EngineeringBanner;
