import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { ApolloProvider, gql } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import { useDataTableFromQuery } from 'common/DataTable';
import { formatLocalTimestampWithFormat } from 'common/Utils';
import * as Routes from 'routes';
import UploadCsvButton from './UploadCsvButton';
import ProgramIndexRowPoller from './ProgramIndexRowPoller';
import PublishButton from './Publish';

const ProgramIndicesWrapper = ({ programId }) => (
  <ApolloProvider client={apolloClient}>
    <ProgramIndices
      programId={programId}
    />
  </ApolloProvider>
);

ProgramIndicesWrapper.propTypes = {
  programId: PropTypes.number.isRequired
};

const GET_PROGRAM_INDICES = gql`
    query ProgramIndicesTable($programId: Int!) {
      programIndices(programId: $programId) {
        id,
        createdAt,
        locale,
        processingEndTime,
        published,
        uploadStatus,
        programIndexCsv {
          id,
          fileName,
          url
        }
      }
    }
  `;

const ProgramIndices = ({ programId }) => {
  const {
    DataTable,
    addResource,
    resources,
    updateResource,
    updateResources
  } = useDataTableFromQuery(GET_PROGRAM_INDICES, { programId }, 'programIndices');

  const formatDate = date => formatLocalTimestampWithFormat(date, 'MMMM DD, hh:mm A');

  const updateProgramIndices = (id, published, locale) => {
    const updatedResources = resources.map((resource) => {
      if (resource.id === id) {
        return Object.assign({}, resource, { published: published });
      }

      if (resource.locale !== locale) {
        return Object.assign({}, resource, { published: resource.published });
      }

      return Object.assign({}, resource, { published: false });
    });

    updateResources(updatedResources);
  };

  const renderDate = (td, cellData) => {
    if (!cellData) return;

    ReactDOM.render(formatDate(cellData), td);
  };

  const actions = (td, _, rowData) => {
    if (rowData.uploadStatus !== 'Finished') return;

    const previewPath = Routes.preview_admin_program_index_path(rowData.id);

    ReactDOM.render(
      <Fragment>
        <a
          href={previewPath}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--sm btn--outline-purple"
        >
          Preview
        </a>
        <PublishButton rowData={rowData} updateProgramIndices={updateProgramIndices} />
      </Fragment>,
      td
    );
  };

  const columns = [
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <a
            href={rowData.programIndexCsv_url}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {rowData.programIndexCsv_fileName}
          </a>,
          td
        );
      },
      name: 'programIndexCsv_fileName',
      targets: 0,
      title: 'Title'
    },
    {
      createdCell: (td, _cellData, rowData) => {
        const text = rowData.locale === 'en' ? 'English' : 'Spanish';

        td.innerHTML = text;
      },
      name: 'locale',
      targets: 1,
      title: 'Language',
    },
    {
      createdCell: renderDate,
      name: 'createdAt',
      targets: 2,
      title: 'Begin Time'
    },
    {
      createdCell: renderDate,
      name: 'processingEndTime',
      targets: 3,
      title: 'End Time'
    },
    {
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <ProgramIndexRowPoller
            programIndexId={parseInt(rowData.id, 10)}
            uploadStatus={rowData.uploadStatus}
            updateResource={updateResource}
          />,
          td
        );
      },
      name: 'uploadStatus',
      targets: 4,
      title: 'Status'
    },
    {
      createdCell: actions,
      name: 'actions',
      searchable: false,
      sortable: false,
      targets: -1,
      title: '',
      width: '100px'
    }
  ];

  const sortedResources = resources.sort((a, b) => {
    return moment(a.processingEndTime).isBefore(moment(b.processingEndTime)) ? 1 : -1;
  });

  return (
    <Fragment>
      <UploadCsvButton afterSubmit={addResource} programId={programId} />
      <DataTable
        autoWidth={false}
        columns={columns.map(c => c.name)}
        handleResourcesManually
        isLoading={false}
        resources={sortedResources}
        info={false}
        paging={false}
        columnDefs={columns}
        defaultOrder={[[1, 'desc']]}
      />
    </Fragment>
  );
};

ProgramIndices.propTypes = {
  programId: PropTypes.number.isRequired
};

export default ProgramIndicesWrapper;
