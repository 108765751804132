import React from 'react';
import styles from './SectionModal.module.scss';

interface TabProps {
  section: {
    id: string;
    title: string;
  },
  onClick: () => void;
  isActive: boolean;
  route: string;
  shouldLink: boolean;
}

const Tab = ({ section, onClick, isActive, route, shouldLink }: TabProps) => {
  return (
    <button
      className={`${styles.tab} ${isActive ? styles.active : ''}`}
      key={`section-${section.id}`}
      type="button"
      onClick={onClick}
    >
      Reading -
      {section.title}
      {isActive && shouldLink && (
        <a className="ml12 mt2" href={route} rel="noopener noreferrer" target="_blank">
          <i className="fa fa-external-link" />
        </a>
      )}
    </button>
  );
};

export default Tab;
