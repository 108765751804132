import React from 'react';
import Modal, { Footer } from '@/components/common/Modal';
import I18n from 'modules/i18n';

const ConfirmationModal = ({ handleSave, submitting, closeModal, isOpen }) => {

  const footer = () => (
    <Footer
      primaryButtonCallback={handleSave}
      primaryButtonClassName="btn btn--purple"
      primaryButtonDisabled={submitting}
      primaryButtonSubmit={false}
      primaryButtonText="Update"
      secondaryButtonText="Cancel"
      secondaryButtonCallback={closeModal}
      wrapperClassName="tw-pr-4 tw-pb-4"
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText={I18n.t('update_settings')}
      footer={footer()}
    >
      <div>
        <p>{I18n.t('changes_student_settings')}</p>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
