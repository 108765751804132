import React, { Fragment } from 'react';
import Axios from 'axios';
import { plato_api_slides_path } from '@/modules/routes';
import addSlideIcon from 'images/icons/slide-add-48-regular.svg';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import ActionsDropdown from '@/components/common/ActionsDropdown';
import styles from '../Builder/SlidePanel/Thumbnail/Thumbnail.module.scss';
import builderStyles from '../Builder/SlideShowBuilder.module.scss';
import ImportSlideButton from './ImportSlideButton';

const dropdownStyle = createSlide => (
  <Fragment>
    <button
      aria-label="Add slide"
      className="dropdown-item full-size"
      onClick={createSlide}
      type="button"
    >
      Add Slide
    </button>
  </Fragment>
);

const slideStyle = (createSlide) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') createSlide();
  };

  return (
    <div
      className={styles.slide}
      role="button"
      tabIndex={0}
      onClick={createSlide}
      onKeyDown={handleKeyDown}
    >
      <span className={styles.positionNumber} />
      <div className={`unstyled ${styles.thumbnail} ${styles.addSlide}`}>
        <div className={styles.plus}><i className="fa fa-plus" /></div>
      </div>
    </div>
  );
};

const buttonStyle = (createSlide) => {
  const userType = useSlideShowContext(state => state.userType);
  const isContentManager = ['ContentManager', 'Sysadmin'].includes(userType);

  if (isContentManager) {
    return (
      <div className="dsp-flex tw-max-h-[31px]">
        <button
          className={`
            btn btn--outline-purple tw-rounded-tr-none tw-rounded-br-none
          `}
          type="button"
          onClick={createSlide}
          aria-label="Add Slide"
        >
          <div className={builderStyles.textWithIcon}>
            <img src={addSlideIcon} alt="Add Slide" />
            Add Slide
          </div>
        </button>
        <ActionsDropdown
          dropdownClassName="tw-py-0"
          toggleClassName={`
             btn--outline-purple tw-rounded-tl-none tw-rounded-bl-none tw-border-l-0 -tw-mt-[13px] tw-px-3
          `}
          toggleText=""
        >
          <ImportSlideButton />
        </ActionsDropdown>
      </div>
    );
  }

  return (
    <div className="dsp-flex">
      <button type="button" onClick={createSlide} className="btn btn--outline-purple" aria-label="Add Slide">
        <div className={builderStyles.textWithIcon}>
          <img src={addSlideIcon} alt="Add Slide" />
          Add Slide
        </div>
      </button>
    </div>
  );
};

interface AddSlideProps {
  position?: number;
  slideGroupId?: number;
  styling?: string;
}

const AddSlide = ({ position = null, slideGroupId, styling = 'button' }: AddSlideProps) => {
  const addSlide = useSlideShowContext(state => state.addSlide);
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  const createSlide = () => {
    Axios.post(plato_api_slides_path(), { position, slide_group_id: slideGroupId, slide_show_id: slideShowId })
      .then((res) => {
        addSlide(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  switch (styling) {
    case 'dropdown':
      return dropdownStyle(createSlide);
    case 'slide':
      return slideStyle(createSlide);
    case 'button':
    default:
      return buttonStyle(createSlide);
  }
};

export default AddSlide;
