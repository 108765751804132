import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tabs from 'common/Settings/Tabs';
import GoogleSettings from 'common/GoogleSettings';

import styles from './Settings.module.scss';
import MyAccount from './Forms/MyAccount';

export default class Settings extends Component {
  static propTypes = {
    googleConnectionData: PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      profilePictureUrl: PropTypes.string
    }).isRequired,
    isAutoRostered: PropTypes.bool.isRequired,
    student: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string
    }).isRequired,
    studentChangePasswordPath: PropTypes.string.isRequired,
    studentPath: PropTypes.string.isRequired,
  };

  static formatTabs() {
    return (
      [{ key: 'my_account', label: 'My Account' },
        { key: 'integrations', label: 'Integrations' }]
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'my_account',
      googleConnectionData: props.googleConnectionData
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  _isActive(tab) {
    return this.state.activeTab === tab;
  }

  updateState(newState) {
    this.setState(newState);
  }

  _renderIntegrationsTab() {
    return (
      <GoogleSettings
        active={this._isActive('integrations')}
        connectionData={this.state.googleConnectionData}
        updateState={this.updateState}
        userType="student"
      />
    );
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className={`dsp-flex ${styles.settings_container}`}>
        <Tabs
          setActiveTab={this.setActiveTab}
          activeTab={activeTab}
          tabs={Settings.formatTabs()}
        />

        <div className={styles.contentContainer}>
          <MyAccount
            student={this.props.student}
            studentPath={this.props.studentChangePasswordPath}
            isAutoRostered={this.props.isAutoRostered}
            active={this._isActive('my_account')}
          />

          {this._renderIntegrationsTab()}
        </div>
      </div>
    );
  }
}
