import React from 'react';
import { IVocabDecksPageDeck } from '@/components/common/VocabularyCards/interfaces';
import tciLogo from '../../../../images/tci-logo-white.svg';

const DeckUserIcon = ({ deck }: { deck: IVocabDecksPageDeck }) => {
  const bgColor = deck.staffer_id === 0 ? 'tw-bg-tci-purple' : 'tw-bg-green';
  const className = `tw-rounded-full ${bgColor} tw-box-border tw-w-5 tw-h-5 tw-p-0.5 tw-flex tw-items-center 
                   tw-justify-center tw-absolute tw-bottom-1 tw-right-1`;
  return (
    <div className={className}>
      {deck.staffer_id === 0 ? (
        <img alt="TCI Logo" src={tciLogo} />
      ) : (
        <span className="tw-text-[10px] tw-text-white">
          {`${deck.staffer.first_name.charAt(0)}${deck.staffer.last_name.charAt(0)}`}
        </span>
      )}
    </div>
  );
};

export default DeckUserIcon;
