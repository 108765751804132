import { useQuery } from '@tanstack/react-query';
import * as Routes from '@/modules/routes';
import axios from 'axios';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';
import { IExerciseResult } from '../../../types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useLessonGames from '../useLessonGames';

interface IApiResponse {
  data: IExerciseResult[];
}

const useGames = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const { data: gamesForLessonData } = useLessonGames();

  const isEnabled = !!(classroom?.id && lesson?.id) && gamesForLessonData.length > 0;

  const query = useQuery([QueryKeys.GameExerciseResults, classroom?.id, lesson?.id], () => (
    axios.get<IApiResponse>(Routes.plato_api_game_results_exercise_results_path({
      only: [
        'id', 'student_id', 'exercise_type', 'exercise_id', 'score', 'completed', 'requires_clever_passback',
        'lms_settings', 'updated_since_graded',
      ],
      classroom_id: classroom.id,
      lesson_id: lesson.id,
    })).then(res => res.data.data)
  ), { enabled: isEnabled, placeholderData: [] });

  return { ...query, isEnabled };
};

export default useGames;
