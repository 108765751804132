import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import * as Routes from '../../../../../../../modules/routes';
import { submit } from '../../../submit';

const MAX_ATTEMPTS = 2;

interface UseQuestionAnswerSaveArgs {
  correctChoices?: any;
  isSubmittable?: boolean;
  loading?: boolean;
  parseAnswer?: (args?: any) => any;
  pointsEarned?: number;
  pointsPerDraggable: { [key: string]: number[] };
  previousAnswers?: any;
  slideShowId?: number;
  questionId: number;
  values: object;
}

const useQuestionAnswerSave = ({
  correctChoices = {},
  isSubmittable = false,
  loading = true,
  parseAnswer = undefined,
  pointsEarned = 0,
  pointsPerDraggable = {},
  previousAnswers = [],
  values,
  questionId,
  slideShowId = null,
}: UseQuestionAnswerSaveArgs) => {
  const [state, setState] = useState({
    correctChoices,
    previousAnswers,
    status: '',
    submitting: false,
    updatedAt: '',
    value: values,
    pointsEarned,
    pointsPerDraggable,
  });

  useDeepCompareEffect(() => {
    setState(prev => ({ ...prev, previousAnswers }));
  }, [previousAnswers, questionId]);

  useDeepCompareEffect(() => {
    setState(prev => ({ ...prev, correctChoices }));
  }, [correctChoices]);

  useEffect(() => {
    setState(prev => ({ ...prev, pointsEarned }));
  }, [pointsEarned]);

  useDeepCompareEffect(() => {
    setState(prev => ({ ...prev, pointsPerDraggable }));
  }, [pointsPerDraggable]);

  const submitter = (isSubmitting: boolean) => {
    submit({
      ajaxUrl: Routes.plato_api_question_answers_path({ slideshow_id: slideShowId }),
      setNewState: (newState: typeof state) => setState(prev => ({ ...prev, ...newState })),
      value: JSON.stringify(values),
      questionId,
      parseAnswer,
      isSubmitting,
    });
  };

  useDeepCompareEffect(() => {
    if (!isSubmittable) return;
    if (loading) return;
    if (Object.keys(values).length === 0) return;
    if (previousAnswers.length === MAX_ATTEMPTS) return;
    if (previousAnswers.length > 0 && pointsEarned === 1) return;

    submitter(false);
  }, [isSubmittable, values]);

  return ({
    ...state,
    submit: submitter,
  });
};

export default useQuestionAnswerSave;
