import React, { useState, useEffect } from 'react';
import { usePaginatedTable } from 'components/common/DataTable/PaginatedTable';
import AdvancedFilters from 'components/common/AdvancedFilters';
import { buildFilterPath } from 'components/common/AdvancedFilters/Utils';
import * as Routes from 'modules/routes';
import moment from 'moment-timezone';
import I18n from 'modules/i18n';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface ProfessionalDevelopmentTableProps {
  subscriberId: string;
}

const ProfessionalDevelopmentTable = ({ subscriberId }: ProfessionalDevelopmentTableProps) => {
  const {
    doneLoading,
    pagesLoaded,
    PaginatedTable,
    resources,
    setDoneLoading,
    setResources,
    setPagesLoaded,
  } = usePaginatedTable(10);

  const [formattedResources, setFormattedResources] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  useDeepCompareEffect(() => {
    if (activeFilters.length === 0) return;

    setDoneLoading(false);
    setResources([]);
    setPagesLoaded(0);
  }, [activeFilters]);

  const formatResourcesForPDTable = staffers => (staffers.map(s => ({
    first_name: s.first_name,
    last_name: s.last_name,
    email: s.email,
    completion_date: s.completion_date ? moment(s.completion_date).format('MMM D, YYYY') : '',
    course_completed: s.course_completed ? s.course_completed : '',
    course_duration: s.course_duration ? s.course_duration : '',
  })));

  useEffect(() => {
    const tableFormattedResources = formatResourcesForPDTable(resources);

    setFormattedResources(tableFormattedResources);
  }, [resources]);

  const BASE_URL = Routes.plato_api_pd_results_subscriber_staffers_path(subscriberId, {
    order: 'sortDirection',
    page: 'pageNumber',
    per_page: '10',
  });

  const pdIndexUrl = buildFilterPath(BASE_URL, activeFilters);

  const columnMapping = [
    'last_name',
    'first_name',
    'email',
    'completion_date',
    'course_completed',
    'course_duration',
  ];

  const columns = [
    'last_name',
    'first_name',
    'email',
    'completion_date',
    'course_completed',
    'course_duration',
  ];

  const columnDefs = [
    {
      targets: 0,
      title: I18n.t('last_name'),
    },
    {
      targets: 1,
      title: I18n.t('first_name'),
    },
    {
      targets: 2,
      title: I18n.t('email'),
    },
    {
      targets: 3,
      title: I18n.t('completion_date'),
      type: 'date',
    },
    {
      targets: 4,
      title: I18n.t('course_completed'),
    },
    {
      targets: 5,
      title: I18n.t('course_duration'),
    },
  ];

  return (
    <>
      <AdvancedFilters
        initialFilters={[{ propertyValue: 'course_completed', operatorValue: 'cont' }]}
        properties={[{ label: 'Course Title', value: 'course_completed', operators: ['eq', 'cont'] }]}
        resources={formattedResources}
        updateActiveFilters={setActiveFilters}
        searchPath={Routes.plato_api_pd_results_subscriber_staffers_path(subscriberId)}
        asyncSearchPath={Routes.plato_api_search_pd_results_subscriber_staffers_path(subscriberId)}
      />
      <PaginatedTable
        doneLoading={doneLoading}
        columns={columns}
        columnDefs={columnDefs}
        columnMapping={columnMapping}
        defaultOrder={[[0, 'asc'], [1, 'asc']]}
        exportedColumns={[0, 1, 2, 3, 4, 5]}
        getUrl={pdIndexUrl}
        pagesLoaded={pagesLoaded}
        resources={formatResourcesForPDTable(resources)}
      />
    </>
  );
};

export default ProfessionalDevelopmentTable;
