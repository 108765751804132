import { Field } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './MethodStep.module.scss';

const RadioButton = ({
  className, id, input, label, disabled
}) => (
  <div className={`${className} inline_block`}>
    <input disabled={disabled} type="radio" id={id} {...input} />
    <label className={disabled ? styles.disabled : null} htmlFor={id}>{label}</label>
  </div>
);

RadioButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

RadioButton.defaultProps = {
  className: '',
  disabled: false
};

function CleverRadio({
  hasCompletedCleverRostering, hasStartedMethod
}) {
  if (hasCompletedCleverRostering) {
    return (
      <Field
        component={RadioButton}
        id="clever-radio"
        label="Clever"
        name="method"
        type="radio"
        value="clever"
        disabled={hasStartedMethod}
      />
    );
  }

  return null;
}

CleverRadio.propTypes = {
  hasCompletedCleverRostering: PropTypes.bool.isRequired,
  hasStartedMethod: PropTypes.bool.isRequired
};

export default function Options({
  hasCompletedCleverRostering, existingModels
}) {
  const modelExists = modelType => existingModels.includes(modelType);

  return (
    <div>
      <CleverRadio
        hasCompletedCleverRostering={hasCompletedCleverRostering}
        hasStartedMethod={modelExists('Clever')}
      />

      <Field
        className="ml10"
        component={RadioButton}
        id="saml-radio"
        label="SAML"
        name="method"
        type="radio"
        value="saml"
        disabled={modelExists('SamlSetting')}
      />

      <Field
        className="ml10"
        component={RadioButton}
        id="lti-radio"
        label="LTI"
        name="method"
        type="radio"
        value="lti"
        disabled={modelExists('LtiKey')}
      />
    </div>
  );
}

Options.propTypes = {
  hasCompletedCleverRostering: PropTypes.bool,
  existingModels: PropTypes.arrayOf(PropTypes.string).isRequired
};

Options.defaultProps = {
  hasCompletedCleverRostering: false
};
