import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import templateIcon from 'images/icons/template-purple.svg';
import styles from '../SlideShowBuilder.module.scss';
import { slidePropTypes } from '../../Utils';
import SlideTemplatePicker from './SlideTemplatePicker';
import Slide from '../../Slide';
import SlideTitle from './SlideTitle';
import EditorTabs from './EditorTabs';
import ActionsDropdown from '../../../../common/ActionsDropdown';
import BackgroundDropdownActions from '../BackgroundDropdownActions';
import BackgroundAudioButton from '../BackgroundAudioButton';
import TextEditorToolbar from '../../Slide/Content/SlideObjectText/Toolbar';
import LocaleToggle from '../../../../common/LocaleToggle';
import useSlideShowContext from '../../stores/useSlideShowContext';
import useFlags from '../../../../../hooks/useFlags';

const { autoplayAudio } = useFlags();

const TemplateActions = ({ selectedSlide }) => {
  const templateDropdownContent = () => (
    <div className={`mr10 ${styles.textWithIcon}`}>
      <img src={templateIcon} alt="Templates" />
      Change Template
    </div>
  );

  return (
    <div className={styles.templateActions}>
      <ActionsDropdown toggleText={templateDropdownContent()} position="end">
        <SlideTemplatePicker isModal slide={selectedSlide} />
      </ActionsDropdown>
      <BackgroundDropdownActions slide={selectedSlide} />
      {autoplayAudio && <BackgroundAudioButton slide={selectedSlide} />}
    </div>
  );
};

TemplateActions.propTypes = {
  selectedSlide: slidePropTypes.isRequired,
};
const SlideEditor = ({
  isSaving, lessonId, programId, setIsSaving, unitId, isVideoActivity, slideGroups
}) => {
  const isAdmin = useSlideShowContext(state => state.isAdmin);
  const locale = useSlideShowContext(state => state.locale);
  const setLocale = useSlideShowContext(state => state.setLocale);
  const slide = useSlideShowContext(state => state.currentSlide);

  const renderTemplatePicker = () => (
    <SlideTemplatePicker
      slide={slide}
    />
  );

  useEffect(() => {
    setLocale(locale);
  }, [slide.id]);

  const hasTemplate = slide && slide.slideTemplateId;

  const renderBuilderContent = () => {
    if (slide.slideTemplateId === null) return renderTemplatePicker();

    return (
      <div data-testid="Current slide" id="slide_reel" className={`current-slide locale-${locale || 'en'}`}>
        <SlideTitle
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          slide={slide}
        />
        {isAdmin && (
          <LocaleToggle
            isSaving={isSaving}
            locale={locale}
            setLocale={setLocale}
          />
        )}
        <Slide
          imageModel={slide.imageModel}
          isEditorActive
          isSaving={isSaving}
          isVideoActivity={isVideoActivity}
          locale={locale}
          setIsSaving={setIsSaving}
          slide={slide}
          slideId={slide.id}
          slideObjects={slide.slideObjects}
          templateObjects={slide.slideTemplate.slideTemplateObjects}
          lessonId={lessonId}
          programId={programId}
          unitId={unitId}
          slideGroups={slideGroups}
        />
        {hasTemplate && <TemplateActions selectedSlide={slide} />}
      </div>
    );
  };

  return (
    <>
      <TextEditorToolbar />
      {renderBuilderContent()}
      <EditorTabs slide={slide} />
    </>
  );
};

SlideEditor.propTypes = {
  isSaving: PropTypes.bool,
  isVideoActivity: PropTypes.bool,
  lessonId: PropTypes.number,
  programId: PropTypes.number.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  slideGroups: PropTypes.arrayOf(PropTypes.object),
  unitId: PropTypes.number,
};

SlideEditor.defaultProps = {
  isSaving: false,
  isVideoActivity: false
};

export default SlideEditor;
