import React, { Component } from 'react';
import ImageItem from './ImageItem';
import { SortableElement } from 'react-sortable-hoc';
import styles from './ToggleImage.module.scss';


const SortableImageItem = SortableElement(ImageItem);

export default class SortableImageList extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <div className={styles['image-list-container']}>
        <ul>
          {this.props.items.map((value, index) => (
            <SortableImageItem key={`item-${index}`}
                               id={value.id}
                               index={index}
                               position={value.position}
                               urls={value.urls}
                               title={value.title}
                               zoomable={value.zoomable}
                               useDragHandle={true}
                               toggleEditingMode={this.props.toggleEditingMode}
                               onDelete={this.props.onRemoveItem}
                               onUpdate={this.props.onUpdateItem} />
          ))}
        </ul>
      </div>
    )
  }
}