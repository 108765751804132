import * as fabric from 'fabric';
import React, { useEffect, useState } from 'react';
import cn from '@/utils/cn';
import useActionBarStore from './useActionBarStore';
import SharedActions from './AvailableActions/SharedActions';
import TextActions from './AvailableActions/TextActions';
import useDrawingContext from '../store/useDrawingContext';

const ActionBar = () => {
  const canvas = useDrawingContext(state => state.canvas);
  const coordinates = useActionBarStore(state => state.coordinates);
  const [isText, setIsText] = useState(false);
  const activeObject = canvas?.getActiveObject();

  useEffect(() => {
    setIsText(canvas?.getActiveObject()?.isType(fabric.IText.type));
  }, [activeObject]);

  if (!coordinates || !activeObject) {
    return null;
  }

  const getCoordinates = () => {
    const left = isText ? coordinates.left - 115 : coordinates.left - 47.5;
    let offsetTop = 0;

    if (activeObject) {
      const angle = activeObject.angle || 0;
      const normalizedAngle = ((angle % 360) + 360) % 360;

      if (normalizedAngle > 150 && normalizedAngle < 210) {
        offsetTop = -25;
      }
    }

    return {
      left,
      top: coordinates.top + offsetTop,
    };
  };

  return (
    <div
      aria-label="Actions for selected object"
      role="menu"
      className={cn(
        'tw-fixed tw-flex tw-items-center tw-justify-center tw-gap-2 tw-h-[40px]',
        'tw-bg-white tw-shadow-md tw-rounded tw-z-9999 tw-text-lg tw-box-border',
        {
          'tw-w-[355px]': isText,
          'tw-w-[220px]': !isText,
        },
      )}
      style={getCoordinates()}
    >
      <>
        {isText && (
          <TextActions />
        )}
        <SharedActions />
      </>
    </div>
  );
};

export default ActionBar;
