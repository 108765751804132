import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, useQuery } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import apolloClient from 'common/ApolloClient';
import { useModalManager } from 'common/Modal';
import * as Routes from 'routes';
import Axios from 'axios';
import { GET_PRESENT_MODE_SLIDE_SHOW } from '../Utils/slideShowGraphQL';
import HeaderBar from './HeaderBar';
import SlidePanel from './SlidePanel';
import SlideEditor from './SlideEditor';
import styles from './SlideShowBuilder.module.scss';
import { getUrlParams } from '../../../common/Utils';
import Navigation from './Navigation';
import BuilderTransitionSlide from './BuilderTransitionSlide';
import { formatPresentSlides } from '../PresentMode/Utils';
import SlideShowTitle from './SlideShowTitle';
import useSlideShowStore from '../stores/useSlideShowStore';
import UnsavedChangesConfirmationModal from './UnsavedChangesConfirmationModal';
import useDuplicateIndicesReporter from './useDuplicateIndicesReporter';
import { queryClient } from '../../../../utils/ReactQuery';
import showToast from '../../../common/Toast';
import useSlideShowContext from '../stores/useSlideShowContext';
import SlideShowStoreProvider from '../stores/SlideShowStoreProvider';
import useThemes from '../../../../hooks/api/useThemes';
import cn from '../../../../utils/cn';
import EnvironmentLabel from './EnvironmentLabel';

const SlideShowBuilderWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <SlideShowStoreProvider props={props}>
      <QueryClientProvider client={queryClient}>
        <SlideShowBuilder {...props} />
      </QueryClientProvider>
    </SlideShowStoreProvider>
  </ApolloProvider>
);

const SlideShowBuilderPropTypes = {
  canEditQuestions: PropTypes.bool,
  classroomIds: PropTypes.arrayOf(PropTypes.number),
  environment: PropTypes.string.isRequired,
  lessonId: PropTypes.number,
  locale: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  programId: PropTypes.number.isRequired,
  returnPath: PropTypes.string.isRequired,
  slideId: PropTypes.string,
  slideShowId: PropTypes.number.isRequired,
  stafferId: PropTypes.number.isRequired,
  themeId: PropTypes.number,
  unitId: PropTypes.number,
  userType: PropTypes.string.isRequired
};

SlideShowBuilderWrapper.defaultProps = {
  canEditQuestions: false,
  classroomIds: [],
  locale: 'en',
};

SlideShowBuilderWrapper.propTypes = SlideShowBuilderPropTypes;

export const SlideShowBuilder = (props) => {
  const [destinationSlide, setDestinationSlide] = useState();
  const [hasError, setHasError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [themeClass, setThemeClass] = useState('');
  const params = getUrlParams();
  const unsavedChangesModalManager = useModalManager();

  const slides = useSlideShowContext(state => state.slides);
  const selectedSlide = useSlideShowContext(state => state.currentSlide);
  const setSelectedSlide = useSlideShowContext(state => state.setCurrentSlide);
  const setSlideShow = useSlideShowContext(state => state.setSlideShow);
  const setSlides = useSlideShowContext(state => state.setSlides);
  const setThemeId = useSlideShowContext(state => state.setThemeId);
  const themeId = useSlideShowContext(state => state.themeId);
  const setThemes = useSlideShowContext(state => state.setThemes);

  const hasUnsavedChanges = useSlideShowStore(state => state.hasUnsavedChanges);
  const setCanEditQuestions = useSlideShowStore(state => state.setCanEditQuestions);
  const setLocale = useSlideShowContext(state => state.setLocale);

  useEffect(() => setLocale(props.locale), [props.locale]);
  useEffect(() => setCanEditQuestions(props.canEditQuestions), [props.canEditQuestions]);

  const { data: themes, isSuccess, isLoading } = useThemes();

  const queryData = {
    fetchPolicy: 'no-cache',
    variables: {
      parentId: parseInt(params.parent_id, 10),
      parentType: params.parent_type,
      programId: props.programId,
      slideShowId: props.slideShowId,
    }
  };
  const {
    loading,
    data,
    error,
    previousData,
  } = useQuery(GET_PRESENT_MODE_SLIDE_SHOW, queryData);

  useDuplicateIndicesReporter(data, previousData, props.userType);

  useEffect(() => {
    if (isLoading || !isSuccess) return;
    setThemes(themes);
  }, [isLoading, isSuccess, themes]);

  useEffect(() => {
    if (!themes) return;
    setThemeClass(themes.find(theme => theme.id === themeId)?.css_name);
  }, [themes, themeId]);

  useEffect(() => {
    if (props.userType === 'Sysadmin' || props.userType === 'Teacher') {
      const route = Routes.plato_api_all_progress_for_slideshow_for_classrooms_question_answers_path({
        classroom_ids: props.classroomIds,
        slideshow_id: props.slideShowId,
      });

      Axios.get(route).then((res) => {
        if (res.data.data.length > 0) {
          showToast(
            <div>
              This slideshow has questions with saved student work. Your changes may impact scores. See this&nbsp;
              <a href="https://www.teachtci.com/slideshow-edit-impacts" target="_blank" rel="noopener noreferrer">
                article
              </a>
              &nbsp; for more details and examples.
            </div>,
            { autoClose: 35000, msgType: 'warning', msgHeading: 'Warning: Saved Student Work' }
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!data.slideShow) return;

    setSlideShow(data.slideShow);
    setThemeId(props.themeId);
  }, [data && data.slideShow]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setHasError(true);
      return;
    }

    if (loading) {
      return;
    }

    if (!data || !data.slideShow.slides) {
      setHasError(true);
      return;
    }

    // Use formatted slides to include transition slides in position.
    const formattedSlides = formatPresentSlides(data.slideShow, data.slideShow.slides.map(s => s.id));
    setSlides(formattedSlides);

    if (data.slideShow.slides.length && !selectedSlide) {
      if (props.slideId) {
        setSelectedSlide(data.slideShow.slides.find(slide => slide.id === props.slideId));
      }
      // If unset or out of bounds, set the first slide in the slide show as the selected slide.
      else if (formattedSlides.length <= (parseInt(props.position, 10) - 1) ||
        (parseInt(props.position, 10) - 1) < 0 || props.position == null) {
        setSelectedSlide(data.slideShow.slides[0]);
      }
      else {
        setSelectedSlide(data.slideShow.slides[props.position - 1]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!selectedSlide) return;

    const updatedSlide = slides.find(s => s.id === selectedSlide.id);

    setSelectedSlide(updatedSlide);
  }, [slides]);

  if (hasError) return ('Something went wrong. Please refresh the page and try again.');

  const checkForUnsavedChanges = (newSlide) => {
    if (unsavedChangesModalManager.isOpen) return;
    if (!hasUnsavedChanges) {
      setSelectedSlide(newSlide);
    }
    else if (!unsavedChangesModalManager.isOpen) {
      setDestinationSlide(newSlide);
      unsavedChangesModalManager.open();
    }
  };

  const renderSlideComponents = () => (
    <Fragment>
      <SlideEditor
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        slide={selectedSlide}
        lessonId={props.lessonId}
        programId={props.programId}
        unitId={parseInt(props.unitId, 10)}
        isVideoActivity={data?.slideShow?.activityType === 'video_activity'}
        slideGroups={data?.slideShow?.slideGroups}
      />
    </Fragment>
  );

  return (
    <>
      <Navigation
        selectedSlide={selectedSlide}
        setSelectedSlide={(newSlide) => {
          checkForUnsavedChanges(newSlide);
        }}
      />
      <div className={styles.slideShowBuilderWrapper}>
        <HeaderBar
          isSaving={isSaving}
          returnPath={props.returnPath}
          selectedSlide={selectedSlide}
        />
        <div id="slideShowBuilderDiv" className={cn(styles.slideShowBuilderBody, {
          [themeClass]: !!themeId
        })}>
          <SlidePanel
            setSelectedSlide={(newSlide) => {
              checkForUnsavedChanges(newSlide);
            }}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
          />
          <section aria-label="Slide Editor" className={styles.slideEditor}>
            <SlideShowTitle
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            >
              {selectedSlide && selectedSlide.type === 'Transition' && <BuilderTransitionSlide />}
              {selectedSlide && selectedSlide.type !== 'Transition' && renderSlideComponents()}
            </SlideShowTitle>
          </section>
          <EnvironmentLabel
            environment={props.environment}
            locale={props.locale}
            programId={props.programId}
            userType={props.userType}
          />
        </div>
      </div>
      {unsavedChangesModalManager.isOpen && (
        <UnsavedChangesConfirmationModal
          modalManager={unsavedChangesModalManager}
          setSelectedSlide={setSelectedSlide}
          destinationSlide={destinationSlide}
        />
      )}
    </>
  );
};

SlideShowBuilder.propTypes = SlideShowBuilderPropTypes;

export default SlideShowBuilderWrapper;
