import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios/index';
import PropTypes from 'prop-types';

import ActionsDropdown from '../../common/ActionsDropdown';
import DataTable from '../../common/DataTable';
import Add from './Add';
import Edit from './Edit';
import Search from './Search';
import Delete from './Delete';

import { isAdmin } from '../../common/Utils';

export default class CourseMappingTable extends Component {
  static propTypes = {
    columns: PropTypes.instanceOf(Array).isRequired,
    destroyPath: PropTypes.string.isRequired,
    getPath: PropTypes.string.isRequired,
    grades: PropTypes.instanceOf(Array).isRequired,
    model: PropTypes.string,
    searchPath: PropTypes.string.isRequired,
    updatePath: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    model: null
  };

  // Sorts programs alphabetically by title
  static _alphabetize(a, b) {
    return a.full_title_with_edition.localeCompare(b.full_title_with_edition);
  }

  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      districtCourseCodes: [],
      editModalOpen: false,
      programs: [],
      resources: [],
      searchResults: []
    };

    this.getDistrictCourseCodesForProgram = this.getDistrictCourseCodesForProgram.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateSearchResults = this.updateSearchResults.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this._populateTable();
  }

  getDistrictCourseCodesForProgram(programCode) {
    if (this.state.resources) {
      return [...new Set(this.state.resources
        .filter(row => programCode === row.program_code)
        .map(row => row.district_course_code))
      ];
    }

    return [];
  }

  openModal(e, rowData, type) {
    e.preventDefault();
    this.setState({ [`${type}ModalOpen`]: true, rowData });
  }

  closeModal(type) {
    this.setState({ [`${type}ModalOpen`]: false, rowData: null });
  }

  updateSearchResults(searchResults) {
    this.setState({ searchResults });
  }

  // Callback to update table after successful updating to database
  updateTable({ newMappedPrograms, deletedIds }) {
    let resources = this.state.resources;

    // remove deleted mapped_programs by id
    resources = resources.filter(mp => !deletedIds.includes(mp.id));

    // add new mapped_programs
    resources = resources.concat(newMappedPrograms);

    // Update resources and state, update search results
    this.setState(
      { resources },
      () => {
        this._setAllDistrictCourseCodes();
        if (this.state.resources.length) this.searchRef.triggerSearch();
      }
    );
  }

  // Return filtered list of resources
  _getResourcesToShow() {
    return this.state.resources.filter(mp => this.state.searchResults.includes(mp.id));
  }

  _setAllDistrictCourseCodes() {
    let codes = [];
    if (this.state.resources) {
      codes = [...new Set(this.state.resources.map(row => row.district_course_code))].sort();
    }
    this.setState({ districtCourseCodes: codes });
  }

  _populateTable() {
    this.setState({ isLoading: true });

    Axios
      .get(this.props.getPath)
      .then((response) => {
        const alphabetized_programs = response.data.subscriber.active_programs.sort(CourseMappingTable._alphabetize);
        this.setState(
          {
            programs: alphabetized_programs,
            resources: response.data.subscriber.mapped_programs,
            subscriberId: response.data.subscriber.id
          },
          () => {
            this._setAllDistrictCourseCodes();
            if (this.state.resources.length) this.searchRef.triggerSearch();
          }
        );
      })
      .catch(errors => console.log(errors))
      .then(() => this.setState({ isLoading: false }));
  }

  _renderWelcomeText() {
    if (this.state.resources.length === 0) {
      return (
        <p>
          To begin mapping your courses to TCI's programs, please click on Add
          Course Mapping.
        </p>
      );
    }

    return null;
  }

  _renderDelete() {
    if (this.state.deleteModalOpen) {
      return (
        <Delete
          closeModal={() => this.closeModal('delete')}
          destroyPath={this.props.destroyPath}
          modalIsOpen={this.state.deleteModalOpen}
          rowData={this.state.rowData}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  _renderEdit() {
    if (this.state.editModalOpen) {
      return (
        <Edit
          closeModal={() => this.closeModal('edit')}
          districtCourseCodes={this.state.districtCourseCodes}
          updatePath={this.props.updatePath}
          getDistrictCourseCodesForProgram={this.getDistrictCourseCodesForProgram}
          modalIsOpen={this.state.editModalOpen}
          rowData={this.state.rowData}
          subscriberId={this.state.subscriberId}
          updateTable={this.updateTable}
        />
      );
    }

    return null;
  }

  _renderAdd() {
    if (isAdmin(this.props.userType)) {
      return (
        <Add
          allDistrictCourseCodes={this.state.districtCourseCodes}
          getDistrictCourseCodesForProgram={this.getDistrictCourseCodesForProgram}
          programs={this.state.programs}
          subscriberId={this.state.subscriberId}
          updateTable={this.updateTable}
          updatePath={this.props.updatePath}
        />
      );
    }
    return null;
  }

  _renderDropdown(rowData) {
    return (
      <ActionsDropdown>
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, rowData, 'edit')}
        >
          <i aria-hidden="true" className="fa fa-edit" /> Edit
        </a>

        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={e => this.openModal(e, rowData, 'delete')}
        >
          <i aria-hidden="true" className="fa fa-trash" /> Delete
        </a>
      </ActionsDropdown>
    );
  }

  _renderSearch() {
    if (this.state.resources.length) {
      return (
        <div>
          <Search
            grades={this.props.grades}
            ref={(ref) => { this.searchRef = ref; }}
            searchPath={this.props.searchPath}
            subscriberId={this.state.subscriberId}
            updateSearchResults={this.updateSearchResults}
          />

          <hr />
        </div>
      );
    }

    return null;
  }

  _renderDataTable() {
    if (this.state.resources.length) {
      return (
        <DataTable
          buttons={[{
            extend: 'csv',
            className: 'btn btn--sm btn--outline-purple mr30',
            text: 'Export to CSV',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }]}
          columns={this.props.columns}
          columnDefs={[
            { targets: 0, width: '19%' },
            { targets: 1, width: '14%' },
            { targets: 3, width: '14%' },
            {
              targets: 4,
              width: '12%',
              sortable: false,
              searchable: false,
              visible: isAdmin(this.props.userType),
              createdCell: (td, cellData, rowData) => {
                ReactDOM.render(
                  this._renderDropdown(rowData),
                  td
                );
              }
            }
          ]}
          defaultOrder={[[0, 'asc']]}
          handleResourcesManually
          isLoading={this.state.isLoading}
          model={this.props.model}
          resources={this._getResourcesToShow()}
          searching={false}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        {this._renderSearch()}

        {this._renderWelcomeText()}

        {this._renderAdd()}

        {this._renderDataTable()}

        {this._renderEdit()}

        {this._renderDelete()}
      </div>
    );
  }
}
