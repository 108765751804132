import React from 'react';
import VideoPlayer from 'common/VideoPlayer';
import { formatLocalTimestampWithFormat } from 'common/Utils';
import { numberWithCommas } from 'TCIUtils';

export const DEFAULT_NOTEBOOK_GREEN = '3fae2a';

/**
 * Return the text for auto-saving. Used in assessments and notebooks.
 * @param status String of status code
 * @param updatedAt
 * @returns {string}
 */
function getAutoSaveStatusMessage(status, updatedAt = null) {
  if (status === '200') {
    return `Saved at ${updatedAt}`;
  }
  if (status === '400') {
    return 'Save failed. Please try again. (Check your internet connection.)';
  }
  if (status === '500') {
    return 'Oh no! Your record did not save. Please try again. If you continue to experience this problem, please contact info@teachtci.com.';
  }

  return 'Save failed. Please try again.';
}

/**
 * Returns a span element with the appropriate styling for the status message.
 * @param status
 * @param submitting
 * @param updatedAt
 * @param exerciseType 'Assessment' or 'Notebook'
 * @returns {null|*}
 */
export const renderStatusMessage = (status, submitting, updatedAt, exerciseType) => {
  if (!status && !submitting) return null;

  let message;
  let messageClass;
  let error = false;

  if (submitting) message = 'Saving…';
  else {
    message = getAutoSaveStatusMessage(status, updatedAt);
    if (status !== '200') error = true;
  }

  if (exerciseType === 'Assessment') {
    messageClass = error ? 'connection_error_background' : 'saved_background';
  }
  else if (exerciseType === 'Notebook' || exerciseType === 'ReferenceMaterial') {
    messageClass = 'save_message visible';
    if (error) messageClass += ' failed';
  }

  return (
    <span className={messageClass} style={{ visibility: 'visible' }} data-ignore="1">
      {message}
    </span>
  );
};

/**
 * Render an image (including GIF), if any.
 * @param image
 * @returns {null|*}
 */
export const renderImage = (image) => {
  if (!image) return null;

  return (
    <img
      width={image.width}
      src={image.imageUrl}
      alt={image.description || ''}
      className="assessment-question-image"
      data-zoomurl={image.zoomUrl}
    />
  );
};

/**
 * Render video for a question, if any.
 * @param video
 * @param width
 * @param minWidth
 * @param maxWidth
 * @param height
 * @returns {null|*}
 */
export const renderVideo = ({ video, width = '400', minWidth = '400', maxWidth = null, height = null }) => (
  <VideoPlayer
    video={video}
    width={width}
    minWidth={minWidth}
    maxWidth={maxWidth}
    height={height}
  />
);

/**
 * Returns a formatted string of a given date to either:
 * Show the time e.g: 9:45 AM (if newlyAnswered is TRUE)
 * Show the date e.g: January 1, 2000 (if newlyAnswered is FALSE)
 * @param time (Date or Time String)
 * @param newlyAnswered (Boolean indicating if a question has been answered for the first time OR has had its answer updated)
 * @returns {String}
 */
export const formatTimestamp = (time, newlyAnswered) => {
  const timeFormat = newlyAnswered ? 'h:mm A' : 'LL';

  return formatLocalTimestampWithFormat(time, timeFormat);
};

/**
 * Form validator to check that the text value is fewer than 100k characters
 * @param values (String) text field answer
 * @param maxChars (Number) maximum length answer can be
 * @returns {Object}
 */
export const validateAnswerLength = (values, maxChars = 100000) => {
  if (values.answer && values.answer.length > maxChars) {
    const errors = {};
    const overflowAmount = numberWithCommas(values.answer.length - maxChars);
    errors.answer = `${numberWithCommas(maxChars)} character limit exceeded. Remove ${overflowAmount} characters.`;
    return errors;
  }

  return undefined;
};
