import { setImageModelIdFromSnippet } from './callbacks';
import {
  CUSTOM_IMAGE_COMMAND_NAME,
  CUSTOM_VIDEO_COMMAND_NAME,
  IMAGE_EDIT_COMMAND_NAME,
  IMAGE_EDIT_MARGINS_COMMAND_NAME,
} from './froalaConstants';
import { AvailablePlugin, EventHandlerEntry, PluginProps } from './froalaTypes';
import { MAIN_IDEAS } from './plugins/MainIdeas';
import { MainIdeasStore } from './plugins/MainIdeas/MainIdeasStore';
import { GLOSSARY_TERMS } from './plugins/GlossaryTerms';
import { VOCAB_BOX } from './plugins/VocabBox';
import useEditImageMarginsStore, { EditImageMarginsStore } from './ImageMarginEditor/useEditImageMarginsStore';

interface TextEditorArgs {
  activePlugins?: AvailablePlugin[];
  addVideoModalManager: ModalManager;
  addImageModalManager: ModalManager;
  editorClass?: string;
  eventHandlers: EventHandlerEntry[];
  customEvents: object;
  editImageModalManager: ModalManager;
  setCurrentImageModelId: (next: number) => any;
  setEditorInstance: (editorInstance: any) => any;
  pluginProps?: PluginProps;
}

export const TextEditorEvents = ({
  activePlugins,
  addVideoModalManager,
  addImageModalManager,
  eventHandlers = [],
  customEvents = {},
  editImageModalManager,
  pluginProps = {},
  setCurrentImageModelId,
  setEditorInstance,
}: TextEditorArgs) => ({
  // eslint-disable-next-line func-names
  'commands.after': function (commandName: string) {
    switch (commandName) {
      case CUSTOM_VIDEO_COMMAND_NAME:
        this.selection.save();
        addVideoModalManager.open();
        break;
      case CUSTOM_IMAGE_COMMAND_NAME:
        this.selection.save();
        addImageModalManager.open();
        break;
      case IMAGE_EDIT_COMMAND_NAME:
        this.selection.save();
        editImageModalManager.open();
        this.popups.hide('image.edit');
        break;
      case IMAGE_EDIT_MARGINS_COMMAND_NAME: {
        const store = EditImageMarginsStore.get(`${this.id.toString()}-image`);
        store.getState().setElement(this.image.get()[0]);

        this.selection.save();
        this.selection.clear();
        break;
      }
      case MAIN_IDEAS.commandName: {
        const selector = pluginProps?.MainIdeas?.selector || 'body';
        const mainIdeasStore = MainIdeasStore.get(selector);

        if (mainIdeasStore.getState().isActive) {
          this.selection.restore();
        } else {
          this.selection.save();
          this.selection.clear();
        }

        mainIdeasStore.getState().toggle();
        break;
      }
      case GLOSSARY_TERMS.commandName: {
        this.selection.save();
        break;
      }
      case VOCAB_BOX.commandName: {
        VOCAB_BOX.events.add({
          editor: this,
          lessonId: pluginProps?.VocabBox?.lessonId,
          locale: pluginProps?.VocabBox?.locale || 'en',
          programId: pluginProps?.VocabBox?.programId,
        });

        break;
      }
      default:
        break;
    }
  },
  initialized() {
    setEditorInstance(this);

    eventHandlers.forEach((entry) => {
      Object.entries(entry).forEach(([event, handler]) => {
        this.el.addEventListener(event, (e: any) => handler(e, this));
      });
    });

    this.el.addEventListener('click', (e: MouseEvent) => {
      setImageModelIdFromSnippet(e, setCurrentImageModelId);
    });

    if (activePlugins.includes(GLOSSARY_TERMS.commandName)) {
      const selector = pluginProps?.GlossaryTerms?.selector || 'body';
      const glossaryTermsPopoverRef = pluginProps?.GlossaryTerms?.glossaryTermsPopoverRef;
      const editor = this;
      this.el.addEventListener('click', (e: MouseEvent) => {
        GLOSSARY_TERMS.events.click(e, glossaryTermsPopoverRef, selector, editor);
      });
    }

    if (activePlugins.includes(MAIN_IDEAS.commandName)) {
      const selector = pluginProps?.MainIdeas?.selector || 'body';
      const editor = this;

      this.el.addEventListener('mousedown', (e: MouseEvent) => {
        MAIN_IDEAS.events.mousedown(e, selector);
      });
      document.addEventListener('mouseup', (e: MouseEvent) => {
        MAIN_IDEAS.events.mouseup(e, selector);
      });
      document.addEventListener('click', (e: MouseEvent) => {
        MAIN_IDEAS.events.click(e, selector, editor);
      });
    }

    if (activePlugins.includes(VOCAB_BOX.commandName)) {
      VOCAB_BOX.events.init(
        pluginProps?.VocabBox?.lessonId,
        this,
        pluginProps?.VocabBox?.selector,
        pluginProps?.VocabBox?.locale,
      );

      document.addEventListener('mousedown', (e : MouseEvent) => VOCAB_BOX.events.mousedown(e, this));
    }
  },
  ...customEvents,
});
