import React from 'react';
import SectionEditorDropdown from './SectionEditorDropdown';

const LAYOUT_OPTIONS = [
  { label: 'Full Width', value: 'full_width' },
  { label: 'Two Column', value: 'two_column' },
] as const;

export type LayoutValue = typeof LAYOUT_OPTIONS[number]['value'];

interface LayoutDropdownProps {
  className?: string;
  initialValue: LayoutValue;
  onChange: (value: string) => any;
}

const LayoutDropdown = ({ className = '', initialValue, onChange }: LayoutDropdownProps) => {
  const defaultValue = LAYOUT_OPTIONS.find(opt => opt.value === initialValue);

  return (
    <SectionEditorDropdown
      className={className}
      label="Layout"
      initialValue={defaultValue.value}
      name="section[column_layout]"
      onChange={onChange}
      options={[...LAYOUT_OPTIONS]}
    />
  );
};

export default LayoutDropdown;
