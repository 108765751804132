import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import StaffForm from '../StaffForm';

export default function AddModal({
  autoRosterDistrict, closeModal, modalOpen, updateTable, userIsSysadmin, flags, contactTypeOptions
}) {
  return (
    <Modal
      closeModal={closeModal}
      isOpen={modalOpen}
      headerText="Add Staff"
    >
      <StaffForm
        autoRosterDistrict={autoRosterDistrict}
        closeModal={closeModal}
        contactTypeOptions={contactTypeOptions}
        updateTable={updateTable}
        userIsSysadmin={userIsSysadmin}
        flags={flags}
      />
    </Modal>
  );
}

AddModal.propTypes = {
  autoRosterDistrict: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contactTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  updateTable: PropTypes.func.isRequired,
  userIsSysadmin: PropTypes.bool.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};
