import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Tooltip from 'common/Tooltip';
import I18n from 'i18n-js';
import Modal from '../../../common/Modal';
import Form from './Form';

export default function DeleteAssessmentButton({ disabled, deleteAssessmentPath, classNames, shared }) {
  const [modalIsOpen, setModelIsOpen] = useState(false);

  const _openModal = () => {
    if (disabled || shared) return;

    setModelIsOpen(true);
  };

  const closeModal = () => {
    setModelIsOpen(false);
  };

  const tooltipContent = () => {
    if (disabled) {
      return I18n.t('assessment_saved_answers_delete_message');
    }

    if (shared) {
      return I18n.t('assessment_unshare_delete_message');
    }

    return 'Delete this assessment.';
  };

  return (
    <Tooltip content={tooltipContent()} shouldDisplay={disabled || shared}>
      <button
        type="button"
        onClick={_openModal}
        className={`${classNames} full-size ${(disabled || shared) && 'link-disabled disabled'}`}
      >
        <i className="fa fa-trash" aria-hidden />
        Delete
      </button>
      <Modal
        isOpen={modalIsOpen}
        headerText="Delete Assessment"
        closeModal={closeModal}
      >
        <Form
          deleteAssessmentPath={deleteAssessmentPath}
          onSuccess={closeModal}
        />
      </Modal>
    </Tooltip>
  );
}

DeleteAssessmentButton.propTypes = {
  classNames: PropTypes.string,
  deleteAssessmentPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  shared: PropTypes.bool
};

DeleteAssessmentButton.defaultProps = {
  classNames: '',
  shared: false
};
