import React from 'react';
import { useModalManager } from '@/components/common/Modal';
import LinkVocabularyModal from './LinkVocabularyModal';

const LinkVocabularyWrapper = ({ icon, text }) => {
  const modalManager = useModalManager();

  return (
    <>
      <button type="button" className="btn btn--outline-purple tw-m-2" onClick={modalManager.open}>
        <i className={`fa ${icon}`} /> {text}
      </button>

      {modalManager.isOpen && (
        <LinkVocabularyModal
          closeModal={modalManager.close}
          isOpen={modalManager.isOpen}
        />
      )}
    </>
  );
};

export default LinkVocabularyWrapper;
