import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import styles from '@/components/admin/SlideShow/PresentMode/Navigator/Navigator.module.scss';
import Tooltip from '@/components/common/Tooltip';
import { slideBackgroundAudioUrl } from '../../Utils';
import { DEFAULT_BACKGROUND_AUDIO_VOLUME } from '../../index';
import useSlideShowStore from '../../../stores/useSlideShowStore';
import useSlideShowContext from '../../../stores/useSlideShowContext';

const StyledSlider = styled(Slider)({
  color: 'white',
  position: 'relative',
  bottom: '-5px',
  right: '12px'
});

const VOLUME_BAR_WIDTH = 100;

const SlideAudioControl = ({ isMuted, setIsMuted, setVolumeInSettings, volumeFromSettings }) => {
  const { getSetSlideAudioVolume } = useSlideShowStore();
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const [icon, setIcon] = useState('fa-volume-up');
  const [volume, setVolume] = useState(volumeFromSettings || getSetSlideAudioVolume());
  const [volumeBarWidth, setVolumeBarWidth] = useState(0);

  const setVolumeAndIcon = (value) => {
    setVolume(value);
    setIcon(value > 0 ? 'fa-volume-up' : 'fa-volume-off');
  };

  const refreshAudioIcon = (store) => {
    setVolumeAndIcon(isMuted ? 0 : store.getSetSlideAudioVolume());
  };


  useSlideShowStore.subscribe(refreshAudioIcon);

  const changeVolume = (_e, value = null) => {
    let newVolume;

    if (value == null && getSetSlideAudioVolume() > 0) {  // Muting
      newVolume = 0;
      setIsMuted(true);
    } else if (value == null) { // Unmuting
      newVolume = [null, undefined, 0].includes(volumeFromSettings) ?
                    DEFAULT_BACKGROUND_AUDIO_VOLUME : volumeFromSettings;

      setVolumeInSettings(newVolume);
      setIsMuted(false);
    } else { // volume control bar changed
      newVolume = value;
      setVolumeInSettings(newVolume);
      if (isMuted && newVolume > 0) setIsMuted(false);
      if (!isMuted && newVolume === 0) setIsMuted(true);
    }

    getSetSlideAudioVolume(newVolume);
    setVolumeAndIcon(newVolume);
  };

  const tooltipText = () => {
    const action = getSetSlideAudioVolume() > 0 ? 'mute' : 'unmute';

    return `Click to ${action} background audio`;
  };

  return slideBackgroundAudioUrl(currentSlide) ? (
    <div className="tw-flex" onMouseLeave={() => setVolumeBarWidth(0)}>
      {volumeBarWidth > 0 &&
        <Box sx={{ width: volumeBarWidth }}>
          <StyledSlider
            aria-label="Volume"
            min={0}
            max={1}
            onChange={changeVolume}
            step={0.01}
            value={volume}
          />
        </Box>
      }
      <Tooltip interactive={false} content={tooltipText()}>
        <button
          className={styles.settingsButton}
          onClick={changeVolume}
          onMouseEnter={() => setVolumeBarWidth(VOLUME_BAR_WIDTH)}
          type="button"
        >
          <i aria-hidden className={`fa ${icon} ${styles.settingsIcon} ${styles.volumeIcon}`} />
        </button>
      </Tooltip>
    </div>
  ) : null;
};

export const SlideAudioControlMutedPropTypes = PropTypes.shape({
  isMuted: PropTypes.bool,
  setIsMuted: PropTypes.func
});

SlideAudioControl.propTypes = PropTypes.shape({
  ...SlideAudioControlMutedPropTypes,
  setVolumeInSettings: PropTypes.bool,
  volumeFromSettings: PropTypes.func
});

export default SlideAudioControl;
