import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { formatPrograms } from 'common/Forms/Utils';
import Tabs from 'common/Settings/Tabs';
import styles from './Settings.module.scss';
import MyAccount from './Forms/MyAccount';
import StudentFeatures from './Forms/StudentFeatures';
import Grades from './Forms/Grades';
import StudentAccess from './Forms/StudentAccess';
import ImportYourData from './Forms/ImportYourData';
import IntegrationOptions from '../../shared/Settings/IntegrationOptions';
import BetaFeatures from '../../shared/Settings/BetaFeatures';
import { queryClient } from '../../../utils/ReactQuery';

export default class Settings extends Component {
  static propTypes = {
    assignmentsPath: PropTypes.string,
    canvasConnectionData: PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      profilePictureUrl: PropTypes.string,
      user_canvas_id: PropTypes.string
    }).isRequired,
    canvasSubscriberConnected: PropTypes.bool,
    connectedToCanvas1_3: PropTypes.bool,
    connectedToSchoology1_3: PropTypes.bool,
    displayUnitContent: PropTypes.bool.isRequired,
    getAdminInfoPath: PropTypes.string.isRequired,
    googleConnectionData: PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      profilePictureUrl: PropTypes.string
    }).isRequired,
    importDataPath: PropTypes.string,
    initialActiveTab: PropTypes.string,
    initialSettings: PropTypes.shape({
      default_home: PropTypes.string,
      grade_notebooks_by: PropTypes.string,
      notebook_possible_points: PropTypes.string,
      program_homepage: PropTypes.string,
      show_page_numbers: PropTypes.bool,
      student_add_lock: PropTypes.bool,
      student_challenge_highlight: PropTypes.string,
      student_closed_book_assessments: PropTypes.bool,
      student_reading_level_a_enabled: PropTypes.bool,
      student_reading_level_b_enabled: PropTypes.bool,
      student_spanish_enabled: PropTypes.bool,
      student_view_assessment_results_off: PropTypes.bool,
      trends_highlight: PropTypes.string
    }).isRequired,
    initialStaffer: PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      from_auto_roster_district: PropTypes.bool.isRequired,
      last_name: PropTypes.string.isRequired,
      username: PropTypes.string
    }).isRequired,
    manageClassesPath: PropTypes.string,
    narrationEnabled: PropTypes.bool,
    programHasClassroomActivity: PropTypes.bool.isRequired,
    programHasLessonOutline: PropTypes.bool.isRequired,
    programHasReadingChecks: PropTypes.bool.isRequired,
    programHasUnits: PropTypes.bool.isRequired,
    programHasVideoActivity: PropTypes.bool.isRequired,
    programHasVocabGames: PropTypes.bool.isRequired,
    programId: PropTypes.number,
    reviewGameSetting: PropTypes.bool.isRequired,
    sameSeriesProgramsCount: PropTypes.number,
    sameSeriesProgramsPath: PropTypes.string,
    schoolLevel: PropTypes.string,
    scienceProgram: PropTypes.bool,
    signUpCodesPath: PropTypes.string.isRequired,
    stafferPath: PropTypes.string.isRequired,
    streamEnabled: PropTypes.bool,
    subscriberContactAdminMessage: PropTypes.string,
    subscriberId: PropTypes.number.isRequired,
    subscriberName: PropTypes.string.isRequired,
    transferPath: PropTypes.string.isRequired,
    updatePath: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    assignmentsPath: null,
    canvasSubscriberConnected: false,
    connectedToCanvas1_3: false,
    connectedToSchoology1_3: false,
    importDataPath: null,
    initialActiveTab: null,
    manageClassesPath: null,
    narrationEnabled: null,
    programId: null,
    sameSeriesProgramsCount: 1,
    sameSeriesProgramsPath: null,
    scienceProgram: null,
    streamEnabled: null
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.initialActiveTab || 'my_account',
      canvasConnectionData: this.props.canvasConnectionData,
      googleConnectionData: this.props.googleConnectionData,
      settings: this.props.initialSettings,
      staffer: this.props.initialStaffer,
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  updateState(newState) {
    this.setState(newState);
  }

  _isActive(tab) {
    return this.state.activeTab === tab;
  }

  _renderIntegrationsTab() {
    return (
      <IntegrationOptions
        active={this._isActive('integrations')}
        canvasSubscriberConnected={this.props.canvasSubscriberConnected}
        connectedToCanvas1_3={this.props.connectedToCanvas1_3}
        connectedToSchoology1_3={this.props.connectedToSchoology1_3}
        canvasConnectionData={this.state.canvasConnectionData}
        googleConnectionData={this.state.googleConnectionData}
        subscriberId={this.props.subscriberId}
        updateState={this.updateState}
        userType={this.props.userType}
        programId={this.props.programId}
      />
    );
  }

  getProgramOptions() {
    return axios
      .get(this.props.sameSeriesProgramsPath)
      .then(response => this.setState({ programOptions: formatPrograms(response.data.data) }))
      .catch(error => console.log(error));
  }

  formatTabs() {
    const defaultTabs = [
      { key: 'my_account', label: 'My Account' }
    ];
    const programTabs = [
      { key: 'student_features', label: 'Student Features' },
      { key: 'grades', label: 'Grades' },
      { key: 'student_access', label: 'Student Access' }
    ];
    const optInFeaturesTab = { key: 'beta_features', label: 'Opt-In Features' };
    const googleTabs = { key: 'integrations', label: 'Integrations' };

    let tabs = defaultTabs;

    if (this.props.programId !== null) {
      if (this.props.sameSeriesProgramsCount > 1) {
        const importYourDataTab = { key: 'import_your_data', label: 'Import Your Data' };
        programTabs.splice(2, 0, importYourDataTab);
      }
      tabs = tabs.concat(programTabs);
    }

    tabs = [...tabs, optInFeaturesTab, googleTabs];

    return tabs;
  }

  render() {
    const { activeTab } = this.state;
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <div className={`dsp-flex ${styles.settings_container}`}>
          <Tabs
            setActiveTab={this.setActiveTab}
            activeTab={activeTab}
            tabs={this.formatTabs()}
          />

          <div className={styles.contentContainer}>
            <MyAccount
              getAdminInfoPath={this.props.getAdminInfoPath}
              settings={this.state.settings}
              signUpCodesPath={this.props.signUpCodesPath}
              staffer={this.state.staffer}
              subscriberContactAdminMessage={this.props.subscriberContactAdminMessage}
              subscriberName={this.props.subscriberName}
              transferPath={this.props.transferPath}
              updatePath={this.props.updatePath}
              updateState={this.updateState}
              active={this._isActive('my_account')}
              userType={this.props.userType}
            />
            <StudentFeatures
              scienceProgram={this.props.scienceProgram}
              settings={this.state.settings}
              stafferPath={this.props.stafferPath}
              updateState={this.updateState}
              streamEnabled={this.props.streamEnabled}
              active={this._isActive('student_features')}
              narrationEnabled={this.props.narrationEnabled}
              manageClassesPath={this.props.manageClassesPath}
              programHasVideoActivity={this.props.programHasVideoActivity}
              programHasVocabGames={this.props.programHasVocabGames}
              reviewGameSetting={this.props.reviewGameSetting}
              programHasLessonOutline={this.props.programHasLessonOutline}
              programHasClassroomActivity={this.props.programHasClassroomActivity}
            />

            <Grades
              stafferPath={this.props.stafferPath}
              updateState={this.updateState}
              active={this._isActive('grades')}
              settings={this.state.settings}
            />

            {this.props.sameSeriesProgramsCount > 1 && (
              <ImportYourData
                active={this._isActive('import_your_data')}
                importDataPath={this.props.importDataPath}
                programId={this.props.programId}
                programsPath={this.props.sameSeriesProgramsPath}
              />
            )}

            <BetaFeatures
              active={this._isActive('beta_features')}
            />

            <StudentAccess
              assignmentsPath={this.props.assignmentsPath}
              schoolLevel={this.props.schoolLevel}
              scienceProgram={this.props.scienceProgram}
              settings={this.state.settings}
              stafferPath={this.props.stafferPath}
              updateState={this.updateState}
              streamEnabled={this.props.streamEnabled}
              active={this._isActive('student_access')}
              displayUnitContent={this.props.displayUnitContent}
              programHasReadingChecks={this.props.programHasReadingChecks}
            />

            {this._renderIntegrationsTab()}
          </div>
        </div>
      </QueryClientProvider>
    );
  }
}
