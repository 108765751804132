import React from 'react';
import I18n from 'modules/i18n';
import floridaIcon from 'images/icons/florida.svg';
import styles from './HeaderButton.module.scss';

interface HeaderButtonProps {
  href: string;
}

export const HeaderButton = ({ href }: HeaderButtonProps) => (
  <button
    type="button"
    className={`btn btn--white ${styles.headerButton}`}
    onClick={() => !window.location.href.match(href) && window.location.assign(href)}
  >
    <div className={styles.buttonContent}>
      <img src={floridaIcon} alt="Florida icon" />
      <span>{I18n.t('view_fl_benchmarks')}</span>
    </div>
  </button>
);

export default HeaderButton;
