import React, { MouseEvent } from 'react';
import styles from './Status.module.scss';
import { Color } from '../../types';

interface Props {
  checked?: boolean;
  borderColor?: Color;
}

const Status = ({ checked, borderColor = Color.PURPLE }: Props) => {
  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <input
      type="checkbox"
      className={`${styles.status} border-${borderColor} tw-mb-0.5`}
      checked={checked}
      onClick={handleClick}
    />
  );
};

export default Status;
