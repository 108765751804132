import React from 'react';
import { roundScore } from '@/components/staffer/Scores/GradesTable/Table/Cells/GradeCells/utils';


type ActivityScoreTypes = {
  hasBeenGraded: boolean,
  maxScore: number,
  score: number,
  ungradedCount: number,
};

const ActivityScore = ({ score, maxScore, hasBeenGraded, ungradedCount }: ActivityScoreTypes) => {
  const scoreValue = () => {
    if (!hasBeenGraded) return 'Not graded';

    return roundScore(score);
  };

  return (
    <div>
      <strong>Total Score:</strong> {' '}
      {scoreValue()} / {maxScore} {' '}
      {ungradedCount > 0 && (
        <>
          ({ungradedCount} ungraded)
        </>
      )}
    </div>
  );
};

export default ActivityScore;
