import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSubmit } from 'common/Forms';
import Modal, { useModalManager, Footer } from 'common/Modal';
import * as Routes from 'routes';

const DeleteNoteModal = ({ resourceId, afterSubmit }) => {
  const modalManager = useModalManager();
  const submitter = useSubmit();

  return (
    <Fragment>
      <button type="button" className="btn btn--link-red" onClick={modalManager.open}>
        <i className="fa fa-trash-o" aria-label="Delete note" />
      </button>
      <Modal headerText="Delete Note" isOpen={modalManager.isOpen} closeModal={modalManager.close}>
        <form onSubmit={(e) => {
          e.preventDefault();
          submitter.submit(
            'delete',
            Routes.plato_api_slide_status_path(resourceId),
            null,
            (response) => { modalManager.close(); afterSubmit(response); }
          );
        }}
        >
          Are you sure you want to delete this note?
          <Footer
            primaryButtonText="Delete"
            submittingText="Deleting..."
            secondaryButtonCallback={modalManager.close}
            submitting={submitter.submitting}
          />
        </form>

      </Modal>
    </Fragment>
  );
};

DeleteNoteModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired
};

export default DeleteNoteModal;
