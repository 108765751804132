import styles from './TextHighlighter.module.scss';

// Returns true if a click target matches a selector or is contained inside an element which matches it
export const selectorClicked = (e, selector) => e.target.matches(selector) || e.target.closest(selector);

// Given an array of nodes, return only the text nodes in the array
export const filterTextNodes = nodes => nodes.filter(node => node.nodeType === Node.TEXT_NODE);

// If a node is a text object, split the text by the given offset and return the second node
// Otherwise, return the original node
export const splitNode = (node, offset, isEnd = false) => {
  let nodeSegment;

  if (node.nodeType === Node.TEXT_NODE) {
    node.splitText(offset);
    nodeSegment = node.nextSibling;
  }
  else if (isEnd && offset > 0) {
    nodeSegment = node.nextSibling;
  }
  else {
    nodeSegment = node;
  }

  return nodeSegment;
};

// Recursively builds an array of all child and grandchild nodes of a parent element
// Ignore definitions for vocab words
export const allNodes = (parent) => {
  let nodesList = [parent];

  parent.childNodes.forEach((childNode) => {
    if (childNode.classList && childNode.classList.contains('definition')) return;
    nodesList = nodesList.concat(allNodes(childNode));
  });

  return nodesList;
};

export const allTextNodes = (containerSelector) => {
  const container = document.querySelector(containerSelector);
  return filterTextNodes(allNodes(container));
};

// Returns the number of times a string appears in another string
// Search is case insensitive sensitive and ignores duplicate whitespace characters
//
// Ex: countOccurrences('the', 'the apple and the orange') => 2
// Ex: countOccurrences('the', 'The apple and the orange') => 2
// Ex: countOccurrences('apple   ', 'The apple and the orange') => 1
export const countOccurrences = (needle, haystack) => {
  const needleSanitized = needle.replace(/\s+/g, ' ');
  const haystackSanitized = haystack.replace(/\s+/g, ' ');

  const needleSearch = new RegExp(needleSanitized.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
  const matches = haystackSanitized.match(needleSearch);
  return matches ? matches.length : 0;
};

export const wrapNode = (node, color, className) => {
  const highlight = document.createElement('span');
  if (className) {
    highlight.className = className;
  }
  else {
    highlight.className = `${styles.highlight} ${styles[color]}`;
  }

  highlight.innerHTML = node.textContent;
  node.replaceWith(highlight);
  return highlight;
};

// Finds all nodes that occur between a start and end node
// If inclusive is set to true, the end node is returned as well
// Start node is always included
export const nodesBetween = (startNode, endNode, containerSelector, inclusive = false) => {
  const container = document.querySelector(containerSelector);
  const nodes = allNodes(container);
  const startNodeIndex = nodes.indexOf(startNode);
  const endNodeIndex = nodes.indexOf(endNode);

  return nodes.filter((node, index) => {
    const afterStartNode = index >= startNodeIndex;
    const beforeEndNode = inclusive ? index <= endNodeIndex : index < endNodeIndex;

    return afterStartNode && beforeEndNode;
  });
};

// Find all nodes that occur between the start and end node and wrap them with a highlighting span
export const wrapNodesBetween = (startNode, endNode, color, containerSelector, className) => {
  const allNodesBetween = nodesBetween(startNode, endNode, containerSelector);
  const textNodesBetween = filterTextNodes(allNodesBetween);

  return textNodesBetween.map((node) => {
    if (className) {
      if (node.parentElement?.classList?.contains(className)) return node;

      return wrapNode(node, color, className);
    }

    return wrapNode(node, color, className);
  });
};

// Iterates over each text node in the document until the highlighted text is found at the desired index
// This is done by adding the contents of each text node together one by one until the completed string contains the
// desired number of occurrences of the desired text
//
// For example, given the highlight { text: 'plants and animals', index: 2 }, this function will keep iterating over the
// text nodes in the document until three instances of the string 'plants and animals' are found (an index of 0 means
// the first occurrence, 1 second occurrence, etc.)
//
// Once the end node is found, the function will iterate over each character in the node until the exact ending offset
// of the highlight is found
// Finally, it splits the end node by the offset
const findHighlightEndNode = (highlightData, containerSelector) => {
  const textNodes = allTextNodes(containerSelector);
  let textSoFar = '';

  for (let nodeIndex = 0; nodeIndex < textNodes.length; nodeIndex++) {
    const node = textNodes[nodeIndex];
    if (countOccurrences(highlightData.text, textSoFar + node.textContent) >= highlightData.index + 1) {
      for (let charIndex = 0; charIndex < node.textContent.length; charIndex++) {
        textSoFar += node.textContent.charAt(charIndex);
        if (countOccurrences(highlightData.text, textSoFar) >= highlightData.index + 1) {
          return splitNode(node, charIndex + 1);
        }
      }
    }

    textSoFar += node.textContent;
  }
};

// Starting from the end node found in the above function, this function finds the start node of the highlight by
// iterating backwards one node / one character at a time until the exact contents of the highlighted text are found
// Once the text is found, its splits the node again by the offset
//
// For example, given that the end node contains the text 'Look for plants and animals' and the text being searched for
// is 'plants and animals', this function will iterate over 18 characters (starting from the back) then split the node
// into: 'Look for ' and 'plants and animals'
const findHighlightStartNode = (highlightData, containerSelector, highlightEndNode) => {
  const textNodes = allTextNodes(containerSelector);
  let textSoFar = '';

  const endNodeIndex = textNodes.indexOf(highlightEndNode.previousSibling);
  for (let nodeIndex = endNodeIndex; nodeIndex >= 0; nodeIndex--) {
    const node = textNodes[nodeIndex];
    for (let charIndex = node.textContent.length - 1; charIndex >= 0; charIndex--) {
      textSoFar = node.textContent.charAt(charIndex) + textSoFar;
      if (countOccurrences(highlightData.text, textSoFar) === 1) {
        return node.splitText(charIndex);
      }
    }
  }
};

export const loadHighlight = (highlightData, containerSelector) => {
  const highlightEndNode = findHighlightEndNode(highlightData, containerSelector);
  if (!highlightEndNode) return [];

  const highlightStartNode = findHighlightStartNode(highlightData, containerSelector, highlightEndNode);

  return wrapNodesBetween(
    highlightStartNode,
    highlightEndNode,
    highlightData.color,
    containerSelector,
    highlightData.className,
  );
};

// Returns true if the current device allows touch interaction
export const detectMobileDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  }
  catch (e) {
    return false;
  }
}
