import FE from 'froala-editor';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  allNodes,
  countOccurrences,
  filterTextNodes,
  loadHighlight,
  splitNode,
} from '../../../../common/TextHighlighter/Util';
import { FROALA_TEMPLATE_NAMES } from '../../froalaUtils';
import { MainIdeasStore } from './MainIdeasStore';
import { groupMainIdeaSpans } from './mainIdeaUtils';
import icon from './icon';
import deleteIcon from './deleteIcon';
import MainIdeasDeletePopover from './MainIdeasDeletePopover';

export const MAIN_IDEAS = {
  commandName: 'MainIdeas',
  name: 'Main Ideas',
  icon,
  events: {
    click: (e: MouseEvent, selector: string, editorInstance: any) => {
      const store = MainIdeasStore.get(selector);

      if (!store.getState().isActive) return;

      const editor = document.querySelector(`${selector} .fr-view`) as HTMLElement;
      if (!editor) return;

      const target = e.target as HTMLElement;

      if (!target.classList.contains('main-idea')) return;

      const popoverRoot = document.querySelector('#main-ideas-root') || document.createElement('div');
      popoverRoot.id = 'main-ideas-root';

      e.stopPropagation();
      e.preventDefault();

      const handleDelete = () => {
        if (!target.parentNode) return;
        const content = target.textContent;
        const textNode = document.createTextNode(content);
        target.parentNode.replaceChild(textNode, target);
        ReactDOM.unmountComponentAtNode(popoverRoot);
        popoverRoot.remove();
        groupMainIdeaSpans();
      };

      const handleClickOutside = (event: MouseEvent) => {
        if (!popoverRoot.contains(event.target as Node)
            && !target.contains(event.target as Node)) {
          ReactDOM.unmountComponentAtNode(popoverRoot);
          popoverRoot.remove();
          document.removeEventListener('mousedown', handleClickOutside);
        }
      };

      ReactDOM.render(
        <MainIdeasDeletePopover
          deleteIcon={deleteIcon}
          onDelete={handleDelete}
          targetElement={target}
          editorTop={editor.getBoundingClientRect().top}
          editorInstance={editorInstance}
        />,
        popoverRoot,
      );

      requestAnimationFrame(() => {
        document.addEventListener('mousedown', handleClickOutside);
      });
    },
    mouseup: (e: MouseEvent, selector: string) => {
      const editor = document.querySelector(`${selector} .fr-view`);
      const mainIdeasStore = MainIdeasStore.get(selector);

      if (!mainIdeasStore.getState().isActive) return;

      const selection = document.getSelection();

      if (!editor.contains(selection.focusNode)) return;
      if (selection.toString() === '') return;

      const range = selection.getRangeAt(0);

      const textNodesInSelection: Node[] = filterTextNodes(allNodes(range.cloneContents()));
      let selectionText = textNodesInSelection
        .reduce((text, node) => text + node.textContent, '')
        .replace(/\s+/g, ' ');

      const nextChar = selection.focusNode.textContent.charAt(selection.focusOffset);
      const includeNextChar = nextChar === '.';
      if (includeNextChar) selectionText += '.';

      const endOffset = includeNextChar ? range.endOffset + 1 : range.endOffset;
      const endNode = splitNode(range.endContainer, endOffset, true);
      const nodes: Node[] = allNodes(editor);

      const endNodeIndex = nodes.indexOf(endNode) + 1;

      const textNodesUpToRange = nodes
        .filter((node, index) => index < endNodeIndex && node.nodeType === Node.TEXT_NODE);
      const textUpToRange = textNodesUpToRange.reduce((total, node) => total + node.textContent, '');

      const index = countOccurrences(selectionText, textUpToRange) - 1;

      document.querySelectorAll('.fr-marker').forEach(node => node.remove());

      loadHighlight(
        {
          className: 'main-idea',
          index,
          text: selection.toString().replaceAll('\n', ''),
        },
        `${selector} .fr-view`,
      );

      groupMainIdeaSpans();
    },
    mousedown: (e: MouseEvent, selector: string) => {
      const store = MainIdeasStore.get(selector);

      if (!store.getState().isActive) return;

      const target = e.target as HTMLElement;
      if (!target.classList.contains('main-idea')) return;

      const editor = document.querySelector(`${selector} .fr-view`) as HTMLElement;
      if (!editor) return;

      e.preventDefault();
      e.stopPropagation();
    },
  },
} as const;

export const mainIdeasPluginCommand = () => {
  FE.DefineIcon(MAIN_IDEAS.commandName, { ICON: MAIN_IDEAS.icon, template: FROALA_TEMPLATE_NAMES.empty });

  FE.RegisterCommand(MAIN_IDEAS.commandName, {
    focus: false,
    icon: MAIN_IDEAS.commandName,
    refreshAfterCallback: true,
    title: 'Main Ideas',
    undo: true,
  });
};
