import React from 'react';
import PropTypes from 'prop-types';

interface ActionButtonProps {
  children: ReactChildren,
  onClick: () => void;
}

const ActionButton = ({ children, onClick }: ActionButtonProps) => (
  <button className="action" onClick={onClick} type="button">
    {children}
  </button>
);

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;
