import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BuilderContext from 'common/Context';
import styles from './DragAndDrop.module.scss';

const DeleteButton = ({ id, label, type }) => {
  const {
    correctAnswerDispatch,
    dropZoneDispatch,
    draggableChoiceDispatch,
    textAreaDispatch
  } = useContext(BuilderContext);

  const removeObject = () => {
    switch (type) {
      case 'vocab':
      case 'dropZone':
        dropZoneDispatch({ payload: { id }, type: 'DELETE' });
        return correctAnswerDispatch({ payload: { id, type: 'dropZone' }, type: 'REMOVE' });
      case 'textArea':
        return textAreaDispatch({ payload: { id }, type: 'DELETE' });
      default:
        draggableChoiceDispatch({ payload: { id }, type: 'DELETE' });
        return correctAnswerDispatch({ payload: { id, type }, type: 'REMOVE' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    removeObject();
  };

  return (
    <div
      role="button"
      className={styles.deleteButton}
      aria-label={label}
      onClick={removeObject}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      X
    </div>
  );
};

DeleteButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DeleteButton;
