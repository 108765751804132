import React from 'react';
import googleIcon from 'assets/images/google_classroom_logo.svg';
import canvasIcon from 'assets/images/canvas_logo.svg';
import schoologyIcon from 'images/icons/schoology-icon.svg';
import { IClassroom } from '../../types';

const ICONS = {
  google: googleIcon,
  canvas: canvasIcon,
  schoology: schoologyIcon,
};

interface LmsSyncTimeProps {
  classroom: IClassroom;
}

const LmsSyncTime = ({ classroom }: LmsSyncTimeProps) => {
  const {
    connected_lms_type: connectedLmsType,
    lms_sync_time: lmsSyncTime,
    lms_sync_date: lmsSyncDate,
  } = classroom;

  if (!(connectedLmsType && (lmsSyncTime || lmsSyncDate))) return null;

  const formatTime = () => {
    if (lmsSyncTime) {
      return new Date(lmsSyncTime * 1000).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      });
    }

    return new Date(lmsSyncDate * 1000).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <div className="tw-flex tw-items-center tw-self-end">
      <img
        className="tw-w-[20px]"
        src={ICONS[connectedLmsType]}
        alt={`${connectedLmsType} logo`}
      />
      <span className="tw-ml-1">
        {`Last Export: ${formatTime()}`}
      </span>
    </div>
  );
};

export default LmsSyncTime;
