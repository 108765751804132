import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Axios from 'axios/index';
import { FORM_ERROR } from 'final-form';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';
import { renderErrors, RequiredAsterisk, SubmitError } from '../../../common/Forms/Utils';
import { Footer } from '../../../common/Modal';
import styles from '../Form.module.scss';
import showToast from '../../../common/Toast';

export default class TransferForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    searchSubscribersPath: PropTypes.string.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      customerNumber: PropTypes.string.isRequired,
    }).isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      transferNotes: PropTypes.string,
      adminEmail: PropTypes.string
    })).isRequired,
    transferPath: PropTypes.string.isRequired,
    transferSubscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    updateTable: PropTypes.func.isRequired,
    viewSubscriberPath: PropTypes.string.isRequired
  };

  static formatSubscriberForSelect(subscriber) {
    return ({
      label: `${subscriber.name} (${subscriber.customer_number} - ${subscriber.city}, ${subscriber.state})`,
      value: subscriber.id
    });
  }

  static searchParameters(searchFieldName, searchInput) {
    return { search: { [`${searchFieldName}_cont`]: searchInput } };
  }

  constructor(props) {
    super(props);

    const anyTempAccess = props.subscriptions.some(s => s.transactionType === 'Temp Access');

    const transferSubscriberOption = {
      label: this.props.transferSubscriber.name,
      value: this.props.transferSubscriber.id
    };

    const tempAccessTransferNote = `Transferred from ${this.props.subscriber.name} (${this.props.subscriber.id})`;
    this.initialTransferNote = anyTempAccess ? tempAccessTransferNote : null;

    this.state = {
      newSubscriber: anyTempAccess ? transferSubscriberOption : null,
      submitting: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.sendTransferRequest = this.sendTransferRequest.bind(this);
  }

  getOptions(input) {
    return Axios
      .post(this.props.searchSubscribersPath, TransferForm.searchParameters('name', input))
      .then(response => ({ options: this.formatOptions(response.data.data) }))
      .catch(error => console.log(error));
  }

  formatOptions(subscribersForSelect) {
    // Don't show the current subscriber as an option
    const filteredSubscribers = subscribersForSelect.filter(subscriber => subscriber.id !== this.props.subscriber.id);

    return filteredSubscribers.map(subscriber => TransferForm.formatSubscriberForSelect(subscriber));
  }

  handleSelect(args, state, utils) {
    this.setState({ newSubscriber: args[0] });

    const updatedValue = args[0] ? args[0].value : null;
    utils.changeValue(state, 'new_subscriber_id', () => updatedValue);
  }

  handleSubmit(values) {
    const transferRequests = this.props.subscriptions.map(subscription => this.sendTransferRequest(values, subscription));

    return Promise
      .all([...transferRequests])
      .then(() => {
        this.showSuccessToast();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  }

  sendTransferRequest(values, subscription) {
    this.setState({ submitting: true });

    return (
      Axios
        .post(this.props.transferPath.replace(':id', subscription.id), values)
        .then((response) => {
          if (response.data.errors) {
            this.setState({ submitting: false });
            console.log(response.data.errors);
          }
          else {
            this.props.updateTable(subscription, 'delete');
            this.props.closeModal();
          }
        })
        .catch((error) => {
          this.setState({ submitting: false });
          console.log(error.response.data.errors);
          throw error;
        })
    );
  }

  showSuccessToast() {
    const subscriberPath = this.props.viewSubscriberPath.replace(':id', this.state.newSubscriber.value);

    showToast(
      <div>
        <p>
          Transfer successful. See
          <a href={subscriberPath} target="_blank" rel="noopener noreferrer"> Transactions</a>
        </p>
      </div>
    );
  }

  renderSelect(handleSelect) {
    return (
      <div className={styles.formRow}>
        <label
          htmlFor="new_subscriber_id"
          className={styles.label}
        >
          To
          <RequiredAsterisk />
        </label>

        <AsyncSelect
          className={`${styles.select}`}
          joinValues
          id="new_subscriber_id"
          cacheOptions
          loadOptions={this.getOptions}
          defaultOptions
          onChange={handleSelect}
          value={this.state.newSubscriber}
          required
        />
      </div>
    );
  }

  renderFromFieldInformation() {
    return (
      <div className={styles.formRow}>
        <div className={styles.label}>
          From
        </div>
        <div>
          {`${this.props.subscriber.name} (${this.props.subscriber.customerNumber})`}
        </div>
      </div>
    );
  }

  renderFormFields(form, handleSubmit, submitError) {
    return (
      <form onSubmit={handleSubmit}>
        {this.renderFromFieldInformation()}

        {this.renderSelect(form.mutators.handleSelect)}

        <div className={styles.formRow}>
          <label htmlFor="transfer_notes" className={styles.label}>
            Reason for Transfer and Exchange
            <RequiredAsterisk />
          </label>
          <Field
            id="transfer_notes"
            name="transfer_notes"
            component="textarea"
            className={styles.textArea}
            required
            placeholder="Please enter reason for transfer including reference to SF case and/or NS case or RA."
          />
        </div>

        <SubmitError error={submitError} />

        <Footer
          secondaryButtonCallback={this.props.closeModal}
          submitting={this.state.submitting}
        />
      </form>
    );
  }

  render() {
    return (
      <Form
        mutators={{
          handleSelect: this.handleSelect
        }}
        initialValues={{
          transfer_notes: this.initialTransferNote,
          new_subscriber_id: this.state.newSubscriber && this.state.newSubscriber.value
        }}
        onSubmit={this.handleSubmit}
        render={({ form, handleSubmit, submitError }) => (this.renderFormFields(form, handleSubmit, submitError))}
      />
    );
  }
}
