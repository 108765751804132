import Axios from 'axios';
import * as Routes from '@/modules/routes';
import * as fabric from 'fabric';
import { centerImage } from '../functions';

interface LoadDataProps {
  answerId?: number;
  builder?: boolean;
  questionId: number;
  userType: UserType;
}

type QuestionAnswerResponse = {
  question_answer: {
    answer_data: string;
  };
};

type QuestionResponse = {
  question: {
    body?: string | undefined;
    data: {
      fabric_data: string;
    };
  };
};

type BackgroundImageResponse = {
  data: {
    height: number;
    width: number;
    image: {
      url: string;
    }
  }[];
};

interface LoadAnswerDataProps {
  answerId?: number;
  builder?: boolean;
  userType: UserType;
}

const loadAnswerData = async ({ answerId, builder = false, userType }: LoadAnswerDataProps) => {
  if (!answerId || builder || userType === 'Sysadmin') return null;

  const answerData = await Axios.get<QuestionAnswerResponse>(Routes.plato_api_question_answer_path(answerId));

  const parsedAnswerData = JSON.parse(answerData.data.question_answer.answer_data);

  return Object.fromEntries(
    Object.entries(parsedAnswerData).filter(([key]) => key !== 'backgroundImage'),
  );
};

const loadQuestionData = async ({ questionId }: { questionId: number }) => (
  Axios.get<QuestionResponse>(Routes.plato_api_question_path(questionId ?? 0))
);

// eslint-disable-next-line consistent-return
const loadBackgroundImage = async ({ questionId }: { questionId: number }) => {
  try {
    const backgroundImageData = await Axios.get<BackgroundImageResponse>(Routes.plato_api_image_models_path({
      for_model: { model_id: questionId, model_type: 'Question' },
      background_images: true,
      extra_attributes: ['height', 'width'],
      includes: ['image'],
    }));

    if (backgroundImageData.data.data.length > 0) {
      try {
        const fabricImage = await fabric.Image.fromURL(
          backgroundImageData.data.data[0].image.url,
          { crossOrigin: 'anonymous' },
        );

        centerImage(fabricImage);

        return {
          backgroundImageData: fabricImage,
          backgroundImageDimensions: {
            width: backgroundImageData.data.data[0].width,
            height: backgroundImageData.data.data[0].height,
          },
        };
      } catch (err) {
        // this may error if there is no image at the url
        console.debug(err);

        return {};
      }
    }
  } catch (error) {
    if (error.response.status === 404) {
      return {};
    }

    console.error(error);
  }

  return {};
};

const loadData = async ({ answerId, builder = false, questionId, userType }: LoadDataProps) => {
  const answerData = await loadAnswerData({ answerId, builder, userType });
  const questionData = await loadQuestionData({ questionId });
  const { backgroundImageData, backgroundImageDimensions } = await loadBackgroundImage({ questionId });

  return ({ answerData, questionData, backgroundImageData, backgroundImageDimensions });
};

export default loadData;
