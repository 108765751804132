import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@apollo/client';
import SubmitModal from '@/components/ui/Modal/SubmitModal';
import { GET_SLIDE_OBJECT } from '@/components/admin/SlideShow/Utils/slideShowGraphQL';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { CurrentValues, schema, SchemaType } from './utils';
import ThreeDModelUploadForm from './ThreeDModelUploadForm';
import useCreateThreeDModelMutation from './useCreateThreeDModelMutation';
import useUpdateThreeDModelMutation from './useEditThreeDModelMutation';

interface ThreeDModelUploadModalProps {
  currentValues: CurrentValues;
  modalManager: ModalManager;
  slideObjectId: string;
}

export type FormValues = {
  model: FileList;
  textures: FileList[];
};

const ThreeDModelUploadModal = ({ currentValues, modalManager, slideObjectId }: ThreeDModelUploadModalProps) => {
  const { isLoading: isLoadingCreate, mutateAsync } = useCreateThreeDModelMutation(slideObjectId);
  const {
    isLoading: isLoadingUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = useUpdateThreeDModelMutation(currentValues.id);
  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const { refetch: getSlideObject } = useQuery(GET_SLIDE_OBJECT, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const refetchSlideObject = async () => {
    const { data } = await getSlideObject({ id: slideObjectId });
    updateSlideObject(data.slideObject);
  };

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const defaultTextureValues = (currentValues.textures || [])
    .map(t => ({ id: t.id, texture_type: t.textureType, imageModelId: t.imageModelId }));

  const form = useForm<SchemaType>({
    defaultValues: {
      modelFileName: currentValues.fileName,
      textures: defaultTextureValues,
    },
    resolver: zodResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async (data: SchemaType) => {
    if (currentValues.id) {
      await mutateAsyncUpdate(data);
    } else {
      await mutateAsync(data);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await form.handleSubmit(onSubmit)();

    await refetchSlideObject();

    setTimeout(() => modalManager.close(), 0);
  };

  return (
    <SubmitModal
      className="tw-max-w-7xl tw-min-w-max"
      headerText="Add 3D Model"
      isLoading={isLoading}
      isValid={form.formState.isValid}
      submitText="Upload"
      onSubmit={handleSubmit}
      {...modalManager}
    >
      <ThreeDModelUploadForm currentValues={currentValues} form={form} slideObjectId={slideObjectId} />
    </SubmitModal>
  );
};

export default ThreeDModelUploadModal;
