import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { SelectAdapter, useTags } from './utils';
import styles from './TagsForm.module.scss';

const TagSelect = ({ name, label, placeholder, scope }) => {
  const { data: tags } = useTags(scope);

  const tagLabel = tag => (tag.description ? `${tag.value} - ${tag.description}` : tag.value);

  const options = useMemo(() => (
    tags.map(tag => ({ value: tag.id, label: tagLabel(tag) }))
  ), [tags.length, scope]);

  const tagIds = useMemo(() => (
    tags.reduce((acc, cur) => ({ ...acc, [cur.id]: tagLabel(cur) }), {})
  ), [tags.length, scope]);

  if (!label) {
    return (
      <Field name={name} component={SelectAdapter} options={options} placeholder={placeholder} tagIds={tagIds} />
    );
  }

  return (
    <label htmlFor={name} className={styles.tagLabel}>
      <div className={styles.text}>{label}</div>
      <Field name={name} component={SelectAdapter} options={options} placeholder={placeholder} tagIds={tagIds} />
    </label>
  );
};

TagSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  scope: PropTypes.string.isRequired,
};

TagSelect.defaultProps = {
  label: null,
  placeholder: 'Select…'
};

export default TagSelect;
