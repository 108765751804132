import React, { Component } from 'react';
import Axios from 'axios';
import { DeleteUnit } from '../Utils';
import InlineInput from 'react-inline-editing';
import PropTypes from 'prop-types';

export default class Row extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    canDelete: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    removeObject: PropTypes.func.isRequired
  };

  static defaultProps = {
    canDelete: true,
    confirmationModalIsOpen: false,
    isDeleting: false
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const { canDelete, confirmationModalIsOpen } = this.state;

    return (
      <tr>
        <td className='unit-label'
            onClick={Row._handleFocus.bind(this)}>
          <InlineInput text={this.state.unit.label}
                       inputClassName='inline-input'
                       onFocusOut={this._handleUpdate.bind(this)} />
        </td>
        <td className='delete-action'>
          <DeleteUnit confirmationModalIsOpen={confirmationModalIsOpen}
                      canDelete={canDelete}
                      openConfirmationModal={this.openConfirmationModal.bind(this)}
                      closeConfirmationModal={this.closeConfirmationModal.bind(this)}
                      handleDelete={this._handleDelete.bind(this)}
                      isDeleting={this.state.isDeleting} />
        </td>
      </tr>
    )
  }

  _handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/measurement_units/${this.props.id}`).then(response => {
      response.data ? this._handleConfirm() : this.setState({ error: true });
    }).catch(error => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, error: true, isDeleting: false });
    })
  }

  openConfirmationModal(e) {
    e.preventDefault();
    if (this.props.canDelete) this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal(e) {
    this.setState({ confirmationModalIsOpen: false });
  }

  _handleConfirm(e) {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeObject(this.props.id, 'units', 'value');
  }

  static _buildFormData(value) {
    let formData = new FormData();
    formData.append('measurement_unit[unit]', value);

    return formData;
  }

  _handleUpdate(value) {
    this.setState({ isLoading: true });

    let method = 'put',
      url = `measurement_units/${this.props.id}`,
      data = Row._buildFormData(value),
      callback = this.props.onUpdate;

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }

  static _handleFocus(e) {
    e.stopPropagation();

    let label = e.target.querySelector('label');
    if (label) label.click();
  }
}
