import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Footer, Spinner } from '../../common/Modal';
import FormCheckbox from '../../common/Forms/FormCheckbox';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSelectSection = this.handleSelectSection.bind(this);
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  _buildFormData() {
    const { lessonModuleId, sectionIds } = this.state;

    const formData = new FormData();

    formData.append('lesson_module_sections[lesson_module_id]', lessonModuleId);
    formData.append('lesson_module_sections[section_ids]', sectionIds);

    return formData;
  }

  handleSelectSection(e, id) {
    const updated_items = this.state.sectionIds;
    const item_index = updated_items.indexOf(id);

    if (e === false && item_index > -1) {
      updated_items.splice(item_index, 1);
    }
    else {
      updated_items.push(id);
    }
    this.setState({ sectionIds: updated_items });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const method = 'post';
    const url = '/admin/lesson_module_sections';
    const callback = this.props.onAdd;
    const data = this._buildFormData();

    Axios({ method, url, data }).then((response) => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }

  renderSectionCheckboxes() {
    return this.state.allSections && this.state.allSections.map(section =>
      (
        <div key={section.id}>
          <FormCheckbox id={section.id} stateName={section.id} checkLabel={section.title} onChange={this.handleSelectSection} />
        </div>
      ));
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">There was an error. Please reload the page and try again.</div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`sections-form ${this.state.isLoading ? 'loading' : ''}`}
      >
        {this.renderSectionCheckboxes()}
        <Footer secondaryButtonCallback={this.closeModal} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }
}

Form.defaultProps = {
  lessonModuleId: null,
  sectionIds: [],
  allSections: []
};

Form.propTypes = {
  lessonModuleId: PropTypes.number,
  sectionIds: PropTypes.arrayOf(PropTypes.number),
  allSections: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};
