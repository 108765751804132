import React, { Component } from 'react';
import Axios from 'axios';
import { Footer, Spinner } from 'common/Modal';
import { FormInput, FormCheckbox, FormImageUploader } from '../Utils';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    title: '',
    note: '',
    imageUrl: null,
    removeImage: false,
    imageFile: null,
    isLoading: false,
    error: false,
    newRecord: false,
    onAdd: null,
    onUpdate: null,
    hazard: false
  };

  render() {
    if (this.state.error) return (
      <div className="error">There was an error. Please reload the page and try again.</div>
    );
    else return (
      <form onSubmit={this.handleSubmit.bind(this)}
            className={`safety-notes-form ${this.state.isLoading ? 'loading' : ''}`}>
        <FormImageUploader
          onFileAdded={this.onFileAdded.bind(this)}
          onFileRemoved={this.onFileRemoved.bind(this)}
          imageUrl={this.state.imageUrl}
          newRecord={this.props.newRecord}
          id={this.props.id}
        />

        <FormInput label="name" stateName="title"
                   required={true} id={this.props.id}
                   value={this.state.title}
                   onChange={this.handleChange.bind(this)} />
        <FormInput label="description" stateName="note"
                   required={true} id={this.props.id}
                   value={this.state.note} InputType={'textarea'}
                   onChange={this.handleChange.bind(this)} />
        <FormCheckbox checked={this.state.hazard}
                      onChange={e => this.handleCheck(e, 'hazard')}
                      label="Hazard" />
        <Footer secondaryButtonCallback={this.closeModal.bind(this)} />
        <Spinner isLoading={this.state.isLoading} />
      </form>
    );
  }

  componentDidMount() {
    this.initialState = this.state;
  }

  onFileAdded(imageUrl, imageFile) {
    this.setState({ imageFile, imageUrl });
  }

  onFileRemoved() {
    this.setState({ imageFile: null, imageUrl: null });
  }

  _buildFormData() {
    const { title, note, imageFile, hazard } = this.state;
    let formData = new FormData();

    if (imageFile) formData.append('safety_note[image]', imageFile);
    if (title) formData.append('safety_note[title]', title);
    if (note) formData.append('safety_note[note]', note);
    formData.append('safety_note[hazard]', hazard);

    return formData;
  }

  closeModal() {
    this.clearForm();
    this.props.closeModal();
  }

  clearForm() {
    this.setState(this.initialState);
  }

  handleChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value
    });
  }

  handleCheck(e, stateName) {
    this.setState({
      [stateName]: e.target.checked
    });
  }

  setSafetyNotes(ids) {
    this.setState({ safetyNoteIds: ids });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.imageUrl && !this.state.imageFile) return;

    this.setState({ isLoading: true });

    // post for new, put for update
    let method = this.props.newRecord ? 'post' : 'put',
        url = this.props.newRecord ? 'safety_notes' : `safety_notes/${this.props.id}`,
        callback = this.props.newRecord ? this.props.onAdd : this.props.onUpdate,
        data = this._buildFormData();

    Axios({ method, url, data }).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({ isLoading: false, error: true });
      }
      else {
        this.setState({ isLoading: false });
        this.props.closeModal();
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false, error: true });
    });
  }
}
