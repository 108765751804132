import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { IProgram } from './types';

interface UseProgramsProps {
  skip?: boolean;
  attributes?: string[];
}

const DEFAULT_ATTRIBUTES = ['full_title_with_edition', 'id', 'code'];

const usePrograms = ({ skip, attributes = DEFAULT_ATTRIBUTES }: UseProgramsProps = {}) => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const path = Routes.plato_api_programs_path({
    alphabetical: true,
    non_archived: true,
    only: attributes,
  });

  useEffect(() => {
    if (skip) return;

    setIsLoading(true);
    axios
      .get<{ data: IProgram[] }>(path)
      .then(res => setPrograms(res.data.data))
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, programs };
};

export default usePrograms;
