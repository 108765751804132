import React from 'react';
import { act } from 'react-dom/test-utils';
import { fireEvent, render, screen } from '@testing-library/react';
import Axios from 'axios';
import AutoSaveSelect from './AutoSaveSelect';

jest.mock('axios');
const axios = Axios as jest.Mocked<typeof Axios>;

describe('<AutoSaveSelect />', () => {
  const model = 'subscriber';
  const name = 'session_timeout_in';
  const label = 'Sign a user out after this length of continuous inactivity';
  const initialValue = '1 day';
  const options = { '1 hour': 60, '1 day': 1440, '1 week': 10080 };
  const path = '/path';

  const renderAutoSaveSelect = () => render(
    <AutoSaveSelect
      label={label}
      model={model}
      name={name}
      ajaxPath={path}
      initialValue={initialValue}
      options={options}
    />,
  );
  const getDropdown = () => screen.getByLabelText(label);

  describe('initial render', () => {
    it('shows the initial value in the dropdown', () => {
      renderAutoSaveSelect();
      expect(getDropdown()).toBeInTheDocument();
      expect(screen.getByText(initialValue)).toBeInTheDocument();
      expect(screen.queryByText(Object.keys(options)[2])).not.toBeInTheDocument();
    });
  });

  describe('when an option is selected', () => {
    beforeEach(() => {
      axios.patch.mockResolvedValue({});
      renderAutoSaveSelect();
    });

    it('sets the selected option', async () => {
      const newOption = Object.keys(options)[2];
      fireEvent.keyDown(getDropdown(), { key: 'ArrowDown' });
      await act(async () => {
        fireEvent.click(screen.getByText(newOption));
      });

      expect(screen.getByText(newOption)).toBeInTheDocument();
      expect(screen.queryByText(initialValue)).not.toBeInTheDocument();
      expect(Axios.patch).toHaveBeenCalledWith(path, { [model]: { [name]: options[newOption] } });
    });
  });
});
