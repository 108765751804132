import { useEffect } from 'react';
import styles from '../../../common/Drawer/Drawer.module.scss';

const shouldNotNavigate = (e) => {
  const { activeElement } = document;

  const expandableElements = Array.from(document.querySelectorAll('[aria-expanded=true]'));
  const hidableElements = Array.from(document.querySelectorAll('[aria-hidden=false]'));

  // @ts-ignore
  if (['INPUT', 'TEXTAREA'].includes(activeElement.tagName) || activeElement.contentEditable === 'true') return true;
  if (activeElement.getAttribute('role') === 'button' || ['A', 'BUTTON'].includes(activeElement.tagName)) {
    return !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key);
  }
  if (activeElement.classList.contains('fr-element')) return true;
  if (expandableElements.length > 0) return true;
  if (hidableElements.length > 0) {
    if (hidableElements.every(el => el.classList.contains(styles.drawer))) return false;

    return true;
  }

  return false;
};

export default function useKeyboardNavigation({
  numElements,
  onBack,
  onForward,
  position,
  forwardKeys = ['ArrowDown', 'ArrowRight'],
  backKeys = ['ArrowUp', 'ArrowLeft'],
}) {
  const onKeyDown = (e: KeyboardEvent) => {
    if (shouldNotNavigate(e)) return;

    const canGoForward = position !== numElements;
    const canGoBack = position !== 1;

    if (forwardKeys.includes(e.key) && canGoForward) onForward();
    else if (backKeys.includes(e.key) && canGoBack) onBack();
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [position, numElements, onBack, onForward]);
}
