import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Axios from 'axios';
import PathContext from 'components/common/Context';
import { Footer } from 'components/common/Modal';
import { renderFlagFields } from 'components/common/DataTable/Utils';
import { renderErrors } from 'components/common/Forms/Utils';
import showToast from 'components/common/Toast';
import Fields from './Form/Fields.tsx';

import styles from './StaffForm.module.scss';

const TEACHER_TYPE = 'Teacher';

export default class StaffForm extends Component {
  static contextType = PathContext;

  static propTypes = {
    autoRosterDistrict: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    contactTypeOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    flags: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    newRecord: PropTypes.bool,
    staff: PropTypes.shape({
      allow_multiple_sessions: PropTypes.bool,
      contact_types: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.string.isRequired,
      exclude_from_rostering: PropTypes.bool.isRequired,
      first_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      include_as_contact_admin: PropTypes.bool,
      last_name: PropTypes.string.isRequired,
      require_password_reset: PropTypes.bool,
      role: PropTypes.string.isRequired,
      sysadmin: PropTypes.bool.isRequired,
      uid: PropTypes.string,
      username: PropTypes.string
    }),
    updateTable: PropTypes.func.isRequired,
    userIsSysadmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    contactTypeOptions: [],
    newRecord: true,
    staff: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  handleSubmit = async (values) => {
    this.setState({ submitting: true });
    if (values.type === TEACHER_TYPE) values.include_as_contact_admin = false;
    if (values.contact_types) values.contact_types = values.contact_types.map(ct => ct.value);

    // create
    if (this.props.newRecord) {
      return Axios
        .post(this.context.createPath, values)
        .then((response) => {
          this.props.updateTable({ newStaff: response.data.data });
          this.props.closeModal();
        })
        .catch(error => this._handleSubmitError(error));
    }

    // get rid of undefined values so that it sends properly
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) values[key] = null;
    });

    // update
    return Axios
      .put(this.context.updatePath.replace(':id', this.props.staff.id), values)
      .then((response) => {
        this.props.updateTable({ updatedStaff: response.data.data });
        this.props.closeModal();
        showToast(`Account info updated for ${values.first_name} ${values.last_name}.`, { autoClose: 10000 });
      })
      .catch((error) => {
        this._handleSubmitError(error);
        showToast(`Failed to update account info for ${values.first_name} ${values.last_name}.`, { autoClose: 10000, msgType: 'error' });
      });
  };

  _getInitialValues() {
    if (this.props.newRecord) {
      return { include_as_contact_admin: true, teacher_added_by_admin: true, type: TEACHER_TYPE, };
    }

    const values = {
      allow_multiple_sessions: this.props.staff.allow_multiple_sessions,
      contact_types: this.props.contactTypeOptions?.filter(opt => this.props.staff.contact_types?.includes(opt.value)),
      email: this.props.staff.email,
      exclude_from_rostering: this.props.staff.exclude_from_rostering,
      first_name: this.props.staff.first_name,
      include_as_contact_admin: this.props.staff.include_as_contact_admin,
      last_name: this.props.staff.last_name,
      require_password_reset: this.props.staff.require_password_reset,
      type: this.props.staff.role,
      uid: this.props.staff.uid,
      username: this.props.staff.username,
    };

    this.props.flags.forEach((flag) => {
      values[flag.name] = this.props.staff[flag.name];
    });

    return values;
  }

  _renderRoleField = (form) => {
    if (!this.props.newRecord && this.props.staff.sysadmin) return <Fields.AccessTypeField />;

    return <Fields.RoleField form={form} isTeacher={form.getState().values.type === TEACHER_TYPE} />;
  }

  _handleSubmitError(error) {
    this.setState({ submitting: false });
    return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
  }

  renderForm({
    form, dirtySinceLastSubmit, handleSubmit, hasSubmitErrors,
    hasValidationErrors, pristine, submitError
  }) {
    const renderFooter = () => (
      <Footer
        secondaryButtonCallback={this.props.closeModal}
        primaryButtonDisabled={
          pristine || hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit)
        }
        submitting={this.state.submitting}
      />
    );

    return (
      <form onSubmit={handleSubmit}>
        <Fields.FirstNameField />
        <Fields.LastNameField />
        <Fields.EmailField />

        <Fields.UsernameField />
        <Fields.PasswordField userIsSysadmin={this.props.userIsSysadmin} />

        {!this.props.newRecord && this.props.staff.sysadmin ? null : <Fields.UIDField />}
        <hr className="!tw-ml-auto !tw-mr-auto" />
        {this._renderRoleField(form)}

        {form.getState().values.type !== TEACHER_TYPE &&
          <Fields.ContactType contactTypeOptions={this.props.contactTypeOptions} />}

        {this.props.autoRosterDistrict && <Fields.ExcludeFromRosteringField />}

        {renderFlagFields(this.props.userIsSysadmin, this.props.flags, styles.formRow, styles.checkboxLabel)}

        {this.props.userIsSysadmin && <Fields.AllowMultipleSessionsField />}

        {submitError && <div className={styles.red}>{submitError}</div>}

        {renderFooter()}
      </form>
    );
  }

  render() {
    return (
      <Form
        mutators={{
          handleSelectRoleField: ([field], state, { changeValue }) => {
            if (field.target.value === TEACHER_TYPE && state.formState.values.include_as_contact_admin === true) {
              changeValue(state, 'include_as_contact_admin', () => false);
            }
          }
        }}
        initialValues={this._getInitialValues()}
        onSubmit={this.handleSubmit}
        render={({
          dirtySinceLastSubmit, handleSubmit, hasSubmitErrors,
          hasValidationErrors, pristine, submitError, form
        }) => this.renderForm({
          form,
          dirtySinceLastSubmit,
          handleSubmit,
          hasSubmitErrors,
          hasValidationErrors,
          pristine,
          submitError
        })}
      />
    );
  }
}
