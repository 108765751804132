import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tooltip from 'common/Tooltip';
import Dropdown from './Dropdown';
import styles from './HeaderNotifications.module.scss';
import giftboxIcon from '../../../../images/icons/giftbox.svg';
import giftboxIconWithDot from '../../../../images/icons/giftbox-with-dot.svg';
import { redirectLinks } from '../../../modules/ExternalRedirect';

export default class HeaderNotifications extends Component {
  static propTypes = {
    getMessagesPath: PropTypes.string.isRequired,
    initialHasNewMessages: PropTypes.bool.isRequired,
    stafferPath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      error: false,
      hasNewMessages: this.props.initialHasNewMessages,
      isLoadingMessages: true,
      messages: []
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateLastSeen = this.updateLastSeen.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getMessages() {
    return axios
      .get(this.props.getMessagesPath)
      .then((response) => {
        this.setState({
          isLoadingMessages: false,
          messages: response.data.data
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          isLoadingMessages: false
        });
      });
  }

  handleClick = (e) => {
    if (!this.dropdownNode.contains(e.target) && this.state.dropdownOpen) {
      this.toggleDropdown();
    }
  };

  toggleDropdown() {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
    if (this.state.messages.length === 0) {
      return this.getMessages()
        .then(() => {
          this.updateLastSeen();
          redirectLinks(this.dropdownNode);
        });
    }

    return null;
  }

  updateLastSeen() {
    return axios
      .put(this.props.stafferPath,
        { last_seen_header_notification: this.state.messages[0].id })
      .then(() => {
        this.setState({ hasNewMessages: false });
      })
      .catch(error => console.log(error));
  }

  renderIcon() {
    return (
      <Tooltip content="What's New" size="medium" theme="white" interactive={false}>
        <button
          type="button"
          className={`btn btn--link-white ${styles.giftboxButton}`}
          onClick={this.toggleDropdown}
        >
          <img
            className={this.state.hasNewMessages ? styles.withDot : null}
            src={this.state.hasNewMessages ? giftboxIconWithDot : giftboxIcon}
            alt="View What's New messages"
          />
        </button>
      </Tooltip>
    );
  }

  renderDropdown() {
    if (this.state.dropdownOpen) {
      return (
        <Dropdown
          isLoading={this.state.isLoadingMessages}
          messages={this.state.messages}
          error={this.state.error}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div
        className={styles.notifications}
        ref={(node) => { this.dropdownNode = node; }}
      >
        {this.renderIcon()}
        {this.renderDropdown()}
      </div>
    );
  }
}
