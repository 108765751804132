import React, { useContext } from 'react';
import styles from './DragAndDrop.module.scss';
import BuilderContext from '../../../../Context';

interface CopyButtonProps {
  id: string;
  label: string;
}

const CopyButton = ({ id, label }: CopyButtonProps) => {
  const { dropZoneDispatch, isCopying, setIsCopying } = useContext(BuilderContext);

  const copy = () => {
    dropZoneDispatch({ payload: { id }, type: 'COPY' });
    setIsCopying(prev => !prev);
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    copy();
  };

  if (!isCopying) return null;

  return (
    <div
      aria-label={label}
      className={styles.copyButton}
      onClick={copy}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <i className="fa fa-copy" />
    </div>
  );
};

export default CopyButton;
