import React from 'react';
import I18n from 'i18n-js';
import * as Routes from 'modules/routes';
import AssignmentCardsFactory from '../Cards/AssignmentCard/AssignmentCardsFactory';
import AsyncAssignmentModal from 'components/staffer/AssignmentModal/AsyncAssignmentModal';
import { useModalManager } from 'components/common/Modal';
import { capitalizeWords } from 'modules/TCIUtils';
import { UserType } from '../types';
import Section from '../Section/index';

interface ToDoCardProps {
  user: UserType;
  classroomId: number;
  lessonId: number;
  programId: number;
}

const ToDoSection = ({ user, classroomId, lessonId, programId }: ToDoCardProps) => {
  const isStudent = user.type === 'Student';
  const modalManager = useModalManager();

  const createAssignmentButton = () => {
    if (isStudent) return (
      <a
        className="btn btn--sm btn--outline-purple tw-font-normal"
        href={Routes.shared_program_assignments_path(programId, { classroom_id: classroomId, activeToggle: 'past_due' })}
      >
        {capitalizeWords(I18n.t('view_past_due_assignments'))}
      </a>
    );

    return (
      <button
        className="btn btn--sm btn--outline-purple"
        onClick={modalManager.open}
      >
        {capitalizeWords(I18n.t('create_assignment'))}
      </button>
    );
  };

  return (
    <Section
      headerColorClass="tw-bg-tci-purple"
      headerIcon={<i className="fa fa-icon fa-calendar tw-mr-2" />}
      headerLink={createAssignmentButton()}
      headerTitle={isStudent ? I18n.t('to_do') : I18n.t('current_assignments')}
    >
      <AssignmentCardsFactory classroomId={classroomId} programId={programId} user={user} />

      <AsyncAssignmentModal
        closeModal={modalManager.close}
        handlePostSave={() => {}}
        initialLessonId={lessonId}
        modalIsOpen={modalManager.isOpen}
        programId={programId}
        stafferId={user.id}
      />
    </Section>
  );
};

export default ToDoSection;
