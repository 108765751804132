import React from 'react';
import I18n from 'modules/i18n';
import * as Routes from 'modules/routes';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from './TransitionSlide.module.scss';
import AssessLink from './AssessLink';

const SHOW_WHAT_YOU_KNOW_TITLE = 'show what you know';

const AssessWithLinks = ({ isTemporary = false }) => {
  const programId = useSlideShowContext(state => state.programId);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const userType = useSlideShowContext(state => state.userType);

  const { lesson } = slideShow;
  const isStudent = userType === 'Student';

  if (isTemporary) {
    return null;
  }

  return (
    <div className={`${styles.finalSlideItem} ${styles.assessBox}`}>
      <p>{I18n.t('ready_to_assess')}</p>
      {lesson.lessonGameAssignable && (
        <AssessLink
          icon="game"
          href={Routes.shared_program_lesson_challenge_path(programId, lesson.id)}
          title={lesson.lessonGameAssignable.label}
        />
      )}
      <AssessLink
        icon="assess"
        href={isStudent ? (
          Routes.shared_program_lesson_assessments_path(
            programId,
            lesson.id,
          )
        ) : (
          Routes.staffer_lesson_assessments_path(
            lesson.id,
            { program_id: programId },
          )
        )}
        title={I18n.t('tci_assessments')}
      />
      {lesson.sections.flatMap(section => (
        section.notebooks.filter(nb => nb.titleEn?.toLowerCase() !== SHOW_WHAT_YOU_KNOW_TITLE)
          .map(notebook => (
            <AssessLink
              key={notebook.id}
              icon="notebook"
              href={section.investigation ? (
                Routes.stream_shared_program_lesson_investigations_path(
                  programId,
                  lesson.id,
                  { expand: notebook.id },
                )
              ) : (
                Routes.shared_section_path(
                  section.id,
                  { on_notebook: true, program_id: programId, anchor: 'notebook' },
                )
              )}
              title={I18n.locale === 'es' ? notebook.titleEs : notebook.titleEn}
            />
          ))
      ))}
    </div>
  );
};

export default AssessWithLinks;
