import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Axios from 'axios';

import FormContents from './Form';
import DistrictInfo from './DistrictInfo';

export default class CsvForm extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      public_key: PropTypes.string,
      partial_teacher_update: PropTypes.bool
    }).isRequired,
    autoRosterUpdatePath: PropTypes.string.isRequired,
    next: PropTypes.func,
    previous: PropTypes.func,
    updateRosterDistrict: PropTypes.func.isRequired
  };

  static defaultProps = {
    previous: () => {},
    next: () => {}
  };

  constructor(props) {
    super(props);

    this._submit = this._submit.bind(this);
  }

  _submit(values) {
    return Axios
      .put(this.props.autoRosterUpdatePath, { auto_roster_district: values })
      .then((response) => {
        this.props.updateRosterDistrict(response.data.data);
        this.props.next();
      })
      .catch(error => console.log(error));
  }

  _initialValues() {
    return {
      public_key: this.props.autoRosterDistrict.public_key,
      partial_teacher_update: this.props.autoRosterDistrict.partial_teacher_update
    };
  }

  render() {
    return (
      <div>
        <DistrictInfo />

        <Form
          onSubmit={this._submit}
          initialValues={this._initialValues()}
        >
          {({ handleSubmit }) => (
            <FormContents
              handleSubmit={handleSubmit}
              previous={this.props.previous}
            />
          )}
        </Form>
      </div>
    );
  }
}
