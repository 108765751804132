import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import { IExerciseResult, IStudentRow, INotebook } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import useUnitProjects from '../../hooks/queries/useUnitProjects';
import useUnitProjectExerciseResults from '../../hooks/queries/exerciseResults/useUnitProjectExerciseResults';
import { HeaderCell } from '../Cells';
import UnitProjectGradeCell from '../Cells/GradeCells/UnitProjectGradeCell';

const exerciseResultsForUnit = (exerciseResults: IExerciseResult[], unitProject: INotebook) => (
  exerciseResults.filter(er => er.exercise_id === unitProject.id)
);

const useUnitProjectsColumn = () => {
  const { data: unitProjects } = useUnitProjects();
  const { data: exerciseResults } = useUnitProjectExerciseResults();
  const programId = useGradesTableStore(state => state.programId);
  const unit = useGradesTableStore(state => state.unit);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const compareFn = (exerciseResult: IExerciseResult, unitProject: INotebook, studentId: number) => (
    exerciseResult.exercise_id === unitProject.id && exerciseResult.student_id === studentId
  );

  return useMemo(() => (
    unitProjects.map((unitProject) => {
      const accessor = (row: IStudentRow) => row.student;

      return columnHelper.accessor(accessor, {
        id: `unitProject-${unitProject.id}`,
        header: () => (
          <HeaderCell title={unitProject.title}>
            <HeaderCell.SyncGradesButton
              exerciseType="unit project"
              exerciseResults={exerciseResultsForUnit(exerciseResults, unitProject)}
              link={Routes.sync_classroom_scores_teacher_notebook_score_path(
                classroom.id,
                {
                  classroom_id: classroom.id,
                  notebook_id: unitProject.id,
                  program_id: programId,
                },
              )}
            />
          </HeaderCell>
        ),
        cell: (info) => {
          const student = info.getValue();
          const exerciseResult = exerciseResults.find(er => compareFn(er, unitProject, student.id));

          return <UnitProjectGradeCell exerciseResult={exerciseResult} student={student} unitProject={unitProject} />;
        },
      });
    })
  ), [unitProjects.length, exerciseResults.length]);
};

export default useUnitProjectsColumn;
