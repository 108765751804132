import React from 'react';
import { useModalManager } from '@/components/common/Modal';
import { Button } from '@/components/ui/Buttons';
import AddProgramModal from './AddProgramModal';

interface AddProgramProps {
  disabled?: boolean;
}

const AddProgram = ({ disabled = false }: AddProgramProps) => {
  const modalManager = useModalManager();

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => modalManager.open()}
        outlineStyle
        small
      >
        Add Program
      </Button>
      {modalManager.isOpen && (
        <AddProgramModal
          modalManager={modalManager}
        />
      )}
    </>
  );
};

export default AddProgram;
