import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  adjustCamera,
  animate,
  getExtension,
  renderGlbGltf,
  setupCamera,
  setupControls,
  setupEnvironment,
  setupObjFbx,
  setupRenderer,
  setupScene,
  Vec3,
} from './threeJsHelpers';
import { Texture } from './threeJsUtils';

interface ThreeJsProps {
  modelUrl: string;
  onSuccess?: ({
    camera,
    controls,
  }: { camera: THREE.PerspectiveCamera, controls: OrbitControls, model: THREE.Group }) => any;
  ref: React.MutableRefObject<HTMLElement>;
  startingPosition?: Vec3;
  startingRotation?: Vec3;
  startingTarget?: Vec3;
  startingZoom?: number;
  textures: Texture[];
}

export default function setupThreeJs({
  modelUrl,
  onSuccess = () => { },
  ref,
  startingPosition = {} as ThreeJsProps['startingPosition'],
  startingRotation = {} as ThreeJsProps['startingRotation'],
  startingTarget = {} as ThreeJsProps['startingTarget'],
  startingZoom = 1,
  textures,
}: ThreeJsProps) {
  const scene = setupScene();
  const camera = setupCamera({
    aspectRatio: ref.current.clientWidth / ref.current.clientHeight,
  });

  const renderer = setupRenderer(ref.current);
  const controls = setupControls(camera, ref.current);
  const animateCallback = () => animate({
    camera,
    controls,
    renderer,
    scene,
  });

  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0, 5, 0);
  scene.add(directionalLight);

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
  scene.add(directionalLight2);

  setupEnvironment(scene, renderer);

  ref.current.appendChild(renderer.domElement);

  const extension = getExtension(modelUrl);

  if (['glb', 'gltf'].includes(extension)) {
    renderGlbGltf({
      modelUrl,
      onError: e => console.error(e),
      onSuccess: (model) => {
        scene.add(model);

        adjustCamera(camera, controls, model, {
          position: startingPosition,
          rotation: startingRotation,
          target: startingTarget,
          zoom: startingZoom,
        });

        renderer.setAnimationLoop(animateCallback);
        onSuccess({ camera, controls, model });
      },
    });
  } else {
    setupObjFbx({
      modelUrl,
      textures,
      onError: e => console.error(e),
      onSuccess: (model) => {
        scene.add(model);

        adjustCamera(camera, controls, model, {
          position: startingPosition,
          rotation: startingRotation,
          target: startingTarget,
          zoom: startingZoom,
        });

        renderer.setAnimationLoop(animateCallback);
        onSuccess({ camera, controls, model });
      },
    });
  }

  return { camera, controls, renderer, scene };
}
