import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import { Print } from '../../../../images/react/Print';
import cn from '@/utils/cn';
import { HEADER_BUTTON_CLASSES, TRANSLATIONS } from './utils';

interface PrintPdfButtonProps {
  printPdfPath: string;
}

export const PrintPdfButton = ({ printPdfPath }: PrintPdfButtonProps) => (
  <Tooltip
    content={TRANSLATIONS.printMessage}
    size="medium"
    distance="15"
    interactive={false}
  >
    <a
      aria-label={TRANSLATIONS.printMessage}
      className={cn(HEADER_BUTTON_CLASSES, 'tw-p-1')}
      href={printPdfPath}
      rel="noopener noreferrer"
      role="button"
      target="_blank"
    >
      <Print />
    </a>
  </Tooltip>
);
