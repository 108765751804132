import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import cardStyles from 'shared/LessonOverview/Cards/index.module.scss';
import ActionBar from 'shared/LessonOverview/Cards/ActionBar';
import SlideConditionsIcon from '@/components/common/SlideConditionsIcon';
import styles from './TeacherCard.module.scss';

const TeacherCard = ({ slideShow, teacherInitials, programId, lessonId, hideTabs, stafferId }) => (
  <div className={`card ${styles.card}`}>
    <div className={styles.header}>
      {slideShow.activityType &&
        <div className={`${cardStyles.banner} ${cardStyles.green}`}>{I18n.t(slideShow.activityType).toUpperCase()}</div>
      }
      <div className={styles.thumbnail}>
        {teacherInitials}
      </div>

      <div className={styles.title}>
        <a
          href={`${slideShow.links.editPath}${slideShow.useNewBuilder ? '?new_builder=true' : ''}`}
        >
          {slideShow.truncatedTitle}
          {slideShow.hasSlideConditions && stafferId != null &&
            <div className="tw-absolute tw-right-[5px]">
              <SlideConditionsIcon
                iconClassName="svg-green"
                tooltipContent={I18n.t('slideshow_slide_conditions')}
              />
            </div>
          }
        </a>
      </div>
    </div>

    <ActionBar
      slideShow={slideShow}
      programId={programId}
      lessonId={lessonId}
      hideTabs={hideTabs}
      stafferId={stafferId}
    />
  </div>
);

TeacherCard.defaultProps = {
  hideTabs: false,
};

TeacherCard.propTypes = {
  hideTabs: PropTypes.bool,
  lessonId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  slideShow: PropTypes.shape({
    activityType: PropTypes.string.isRequired,
    links: PropTypes.shape({ editPath: PropTypes.string.isRequired, }).isRequired,
    truncatedTitle: PropTypes.string.isRequired,
    useNewBuilder: PropTypes.bool,
  }).isRequired,
  stafferId: PropTypes.number.isRequired,
  teacherInitials: PropTypes.string.isRequired,
};

export default TeacherCard;
