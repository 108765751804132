define(['Interactions/DragDropGame'], function(DragDropGame) {
  function DragDropDraggable(config) {
    this.id = config.id;
    this.parent_slide = config.slide_id;
    this.remember_origin = config.remember_origin;
    this.$node = config.$node;
  }

  DragDropDraggable.prototype = {

    init: function () {
      var this_obj = this;
      $('#' + this_obj.parent_slide + " .dd_answer_js.id_" + this_obj.id).prop('draggable', true);
      if (this.remember_origin) {
        this.origin = this.$node[0].parentNode.getAttribute('id');
      }
      else {
        this.origin = this_obj.parent_slide + " .dd_origin";
      }
    }
  };

  return DragDropDraggable
});