import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#content_container, #main, #root');

interface ModalProps {
  allowOverflow?: boolean;
  bodyClassName?: string;
  children: React.ReactNode;
  className?: string;
  closeButtonClassName?: string,
  closeModal?: () => void;
  closeable?: boolean;
  error?: boolean;
  errorMessage?: string;
  footer?: React.ReactNode;
  fullScreen?: boolean;
  headerClass?: string;
  headerText?: React.ReactNode;
  isLoading?: boolean;
  isOpen?: boolean;
  nearlyFullScreen?: boolean;
  overlayClassName?: string;
  parentSelector?: () => HTMLElement;
  portalClassName?: string;
  preventScroll?: boolean;
  shouldCloseOnOverlayClick: boolean;
}

const spinner = () => (
  <div className="text--center">
    <i className="fa fa-spinner fa-spin fa-2x" aria-label="Loading" />
  </div>
);

const Modal = ({
  allowOverflow,
  bodyClassName,
  children,
  className,
  closeButtonClassName,
  closeModal,
  closeable,
  error,
  errorMessage,
  footer,
  fullScreen,
  headerClass,
  headerText,
  isLoading,
  isOpen,
  nearlyFullScreen,
  overlayClassName,
  parentSelector,
  portalClassName,
  preventScroll,
  shouldCloseOnOverlayClick,
}: ModalProps) => {
  const isCloseableOnClick = shouldCloseOnOverlayClick === undefined ? closeable : shouldCloseOnOverlayClick;

  const overflowClass = () => (allowOverflow ? styles.allowOverflow : '');

  const modalSizeClass = () => {
    if (fullScreen) return styles.fullScreen;
    if (nearlyFullScreen) return styles.nearlyFullScreen;

    return null;
  };

  const closeButton = () => (
    <button
      type="button"
      className={`${styles.closeBtn} ${closeButtonClassName} close`}
      onClick={closeModal}
      onKeyPress={closeModal}
      aria-label="Close popup"
    >
      ✕
    </button>
  );

  const portalClass = () => {
    if (portalClassName) return `${styles.modalPortal} ${portalClassName}`;

    return styles.modalPortal;
  };

  const modalContent = () => {
    if (error) {
      return <div className={styles.error}>{errorMessage}</div>;
    }

    return (
      <div className={`${styles.body} ${bodyClassName} ${overflowClass()} ${modalSizeClass()}`}>
        {isLoading ? Modal.spinner() : children}
      </div>
    );
  };

  return (
    <ReactModal
      role="dialog"
      aria={{ hidden: !isOpen }}
      portalClassName={portalClass()}
      overlayClassName={`${styles.overlayBackground} ${overlayClassName}`}
      className={`${styles.modalContainer} ${className} ${overflowClass()} ${modalSizeClass()}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      parentSelector={parentSelector}
      preventScroll={preventScroll}
      shouldCloseOnOverlayClick={isCloseableOnClick}
    >
      <header className={`${styles.header} ${headerClass}`}>
        {closeable && closeButton()}
        <h1 className="pb0">{headerText}</h1>
      </header>
      {modalContent()}
      {footer && footer}
    </ReactModal>
  );
};

Modal.propTypes = {
  allowOverflow: PropTypes.bool,
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  closeModal: PropTypes.func,
  closeable: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  footer: PropTypes.node,
  fullScreen: PropTypes.bool,
  headerClass: PropTypes.string,
  headerText: PropTypes.node,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  nearlyFullScreen: PropTypes.bool,
  overlayClassName: PropTypes.string,
  parentSelector: PropTypes.func,
  portalClassName: PropTypes.string,
  preventScroll: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

Modal.defaultProps = {
  allowOverflow: false,
  bodyClassName: '',
  className: 'new-modal-container',
  closeButtonClassName: '',
  closeModal: () => { },
  closeable: true,
  error: false,
  errorMessage: 'There was an error. Please reload the page and try again.',
  footer: null,
  fullScreen: false,
  headerClass: '',
  headerText: 'Header Text',
  isLoading: false,
  isOpen: false,
  nearlyFullScreen: false,
  overlayClassName: '',
  parentSelector: () => document.body,
  portalClassName: null,
  preventScroll: false,
  shouldCloseOnOverlayClick: undefined,
};

Modal.spinner = spinner;

export default Modal;
