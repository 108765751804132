import React, { useEffect, useRef } from 'react';
import cn from '@/utils/cn';
import ToolbarButton from './ToolbarButton';
import ColorButton from './ColorButton';
import OpacityButton from './PenSettings/OpacityButton';
import WeightButton from './PenSettings/WeightButton';
import ShapesButton from './ShapesButton';
import RedoButton from './RedoButton';
import UndoButton from './UndoButton';
import SavingIndicator from './SavingIndicator';
import { scaleValue } from './styles';
import SettingsButton from './SettingsButton/SettingsButton';
import useDrawingContext from '../store/useDrawingContext';

const toolbarStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  padding: `${scaleValue(toolbarEl, 0.25, 0.5)}rem ${scaleValue(toolbarEl, 0.5, 0.75)}rem`,
});

const dividerStyle = (toolbarEl: React.MutableRefObject<HTMLDivElement>) => ({
  marginTop: `${scaleValue(toolbarEl, 0.25, 0.625)}rem`,
  marginBottom: `${scaleValue(toolbarEl, 0.25, 0.625)}rem`,
});

const Divider = () => {
  const toolbarEl = useDrawingContext(state => state.toolbarEl);
  const disabled = useDrawingContext(state => state.disabled);

  return (
    <hr
      className={cn(
        '!tw-mx-0',
        { 'tw-opacity-50': disabled },
      )}
      style={dividerStyle(toolbarEl)}
    />
  );
};

const Toolbar = () => {
  const toolbarEl = useRef<HTMLDivElement>(null);
  const disabled = useDrawingContext(state => state.disabled);
  const setToolbarEl = useDrawingContext(state => state.setToolbarEl);

  useEffect(() => {
    setToolbarEl(toolbarEl);
  }, [toolbarEl?.current]);

  return (
    <div
      style={toolbarStyle(toolbarEl)}
      className="tw-border tw-border-black tw-bg-black tw-flex tw-flex-col tw-py-2 tw-px-3 tw-z-[1200] tw-relative"
      data-testid="toolbar"
      ref={toolbarEl}
    >
      <ToolbarButton text="select" />
      <Divider />
      <ToolbarButton text="pen" />
      <ToolbarButton text="text" />
      <ShapesButton />
      <Divider />
      <ColorButton />
      <OpacityButton />
      <WeightButton />
      <Divider />
      <UndoButton />
      <RedoButton />
      <SettingsButton />
      {!disabled && (
        <SavingIndicator />
      )}
    </div>
  );
};

export default Toolbar;
