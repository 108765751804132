/* eslint-disable no-param-reassign */

import { useEffect } from 'react';
import * as fabric from 'fabric';
import Cookie from 'react-cookies';
import { placeText } from '../Modes/Text';
import { placeShape } from '../Modes/Shape';
import useActionBarStore from '../ActionBar/useActionBarStore';
import { drawGuideLines, hideGuideLines, isObjectRotating } from '../functions';
import useKeyboardControls from './useKeyboardControls';
import useDrawingContext from '../store/useDrawingContext';

interface UseListenersProps {
  canvas: fabric.Canvas;
}

const useListeners = ({ canvas }: UseListenersProps) => {
  const mode = useDrawingContext(state => state.mode);
  const setMode = useDrawingContext(state => state.setMode);
  const autoSave = useDrawingContext(state => state.autoSave);
  const addHistory = useDrawingContext(state => state.addHistory);
  const setIsExpanded = useDrawingContext(state => state.setIsExpanded);
  const color = useDrawingContext(state => state.color);
  const setColor = useDrawingContext(state => state.setColor);
  const shapeUrl = useDrawingContext(state => state.shapeUrl);
  const clipboardValue = useDrawingContext(state => state.clipboardValue);
  const undo = useDrawingContext(state => state.undo);
  const redo = useDrawingContext(state => state.redo);
  const copy = useDrawingContext(state => state.copy);
  const paste = useDrawingContext(state => state.paste);
  const cut = useDrawingContext(state => state.cut);
  const deleteObject = useDrawingContext(state => state.deleteObject);
  const duplicateObject = useDrawingContext(state => state.duplicateObject);
  const toggleHelpMenuOpen = useDrawingContext(state => state.toggleHelpMenuOpen);
  const setCoordinates = useActionBarStore(state => state.setCoordinates);
  const clearCoordinates = useActionBarStore(state => state.clearCoordinates);

  useEffect(() => {
    if (!canvas) return;

    // eslint-disable-next-line consistent-return
    const handleKeydown = (e: KeyboardEvent) => {
      const activeObject = canvas.getActiveObject();

      if (activeObject?.isType(fabric.IText.type)) {
        if ((activeObject as fabric.IText).isEditing) {
          setCoordinates({ object: activeObject, canvas });
          return;
        }
      }

      useKeyboardControls({
        canvas,
        copy,
        cut,
        deleteObject,
        duplicateObject,
        e,
        paste,
        redo,
        setCoordinates,
        toggleHelpMenuOpen,
        undo,
      });
    };

    const handleToolSelection = () => {
      setIsExpanded(false);
    };

    const handlePathCreated = ({ path }) => {
      path.erasable = true;
    };

    const handleSelectionCreated = ({ selected }) => {
      setCoordinates({ object: canvas.getActiveObject(), canvas });

      if (selected.length !== 1) return;

      if (selected[0].isType(fabric.Path.type)) setColor(selected[0].stroke);
      if (selected[0].isType(fabric.IText.type)) setColor(selected[0].fill);
    };

    const handleObjectModified = () => {
      autoSave();
      addHistory();
      hideGuideLines();
    };

    const handleObjectRotating = () => {
      isObjectRotating(canvas.getActiveObject())
        .then((isRotating) => {
          if (isRotating) {
            clearCoordinates();
            return;
          }

          setCoordinates({ object: canvas.getActiveObject(), canvas });
        });
    };

    const handleObjectMoving = () => {
      const activeObject = canvas.getActiveObject();
      setCoordinates({ object: activeObject, canvas });

      const snapSetting = Cookie.load('snapToGrid');
      if (snapSetting === 'false' || typeof snapSetting === 'undefined') return;
      drawGuideLines(activeObject, canvas);
    };

    const handleObjectScaling = () => {
      setCoordinates({ object: canvas.getActiveObject(), canvas });
    };

    const handleObjectAdded = ({ target }) => {
      if (target.isType(fabric.Path.type)) target.erasable = true;

      autoSave();
      addHistory();
    };

    const handleTextChanged = () => {
      autoSave();
    };

    const handleObjectRemoved = () => {
      autoSave();
      addHistory();
    };

    const handleMouseUp = async () => {
      if (mode === 'text') {
        placeText(canvas, color);
        setMode('select');
      }

      if (mode === 'shape') {
        await placeShape(canvas, shapeUrl, color).then(() => {
          setMode('select');
        });
      }
    };

    const handleMouseMove = (e) => {
      if (clipboardValue) {
        clipboardValue.setXY(e.scenePoint);
      }
    };

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('toolSelected', handleToolSelection);
    canvas.on('path:created', handlePathCreated);
    canvas.on('selection:created', handleSelectionCreated);
    canvas.on('selection:updated', handleSelectionCreated);
    canvas.on('selection:cleared', clearCoordinates);
    canvas.on('object:modified', handleObjectModified);
    canvas.on('object:rotating', handleObjectRotating);
    canvas.on('object:moving', handleObjectMoving);
    canvas.on('object:scaling', handleObjectScaling);
    canvas.on('object:added', handleObjectAdded);
    canvas.on('text:changed', handleTextChanged);
    canvas.on('object:removed', handleObjectRemoved);
    canvas.on('mouse:up', handleMouseUp);
    canvas.on('mouse:move', handleMouseMove);

    return () => {
      canvas.off('path:created', handlePathCreated);
      canvas.off('selection:created', handleSelectionCreated);
      canvas.off('selection:updated', handleSelectionCreated);
      canvas.off('selection:cleared', clearCoordinates);
      canvas.off('object:modified', handleObjectModified);
      canvas.off('object:rotating', handleObjectRotating);
      canvas.off('object:moving', handleObjectMoving);
      canvas.off('object:scaling', handleObjectScaling);
      canvas.off('object:added', handleObjectAdded);
      canvas.off('text:changed', handleTextChanged);
      canvas.off('object:removed', handleObjectRemoved);
      canvas.off('mouse:up', handleMouseUp);
      canvas.off('mouse:move', handleMouseMove);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('toolSelected', handleToolSelection);
    };
  }, [canvas, mode, setMode, clipboardValue]);
};

export default useListeners;
