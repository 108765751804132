import update from 'immutability-helper';

const addAnswerChoice = (answerChoices, action) => {
  const { draggableChoiceId, dropZoneId } = action.payload;

  const newAnswers = {};

  Object.keys(answerChoices).forEach((key) => {
    newAnswers[key] = answerChoices[key].filter(choice => choice !== draggableChoiceId);
  });

  const answersForDropZone = new Set(answerChoices[dropZoneId]).add(draggableChoiceId);

  newAnswers[dropZoneId] = Array.from(answersForDropZone);

  return update(answerChoices, {
    $merge: newAnswers,
  });
};

const initializeFromDb = (answerChoices, action) => (
  update(answerChoices, {
    $merge: action.payload,
  })
);

const removeAnswerChoice = (answerChoices, action) => {
  const { draggableChoiceId } = action.payload;

  const newAnswers = {};

  Object.keys(answerChoices).forEach((key) => {
    newAnswers[key] = answerChoices[key].filter(choice => choice !== draggableChoiceId);
  });

  return update(answerChoices, {
    $merge: newAnswers,
  });
};

const answerChoiceReducer = (answerChoices, action) => {
  switch (action.type) {
    case 'ADD':
      return addAnswerChoice(answerChoices, action);
    case 'REMOVE':
      return removeAnswerChoice(answerChoices, action);
    case 'INITIALIZE_FROM_DB':
      return initializeFromDb(answerChoices, action);
    default:
      return answerChoices;
  }
};

export default answerChoiceReducer;
