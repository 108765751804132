import React, { useState } from 'react';
import Axios from 'axios';
import I18n from 'modules/i18n';
import moment from 'moment';
import * as Routes from 'modules/routes';
import { formatLocalTimestamp } from 'components/common/Utils';
import useSlideShowStore from '@/components/admin/SlideShow/stores/useSlideShowStore';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import Spinner from '@/components/common/Spinner';
import useAsyncFileDownload from '@/hooks/useAsyncFileDownload';
import { UnansweredQuestion } from '@/components/admin/SlideShow/types';
import UnansweredQuestions from './UnansweredQuestions';
import styles from './TransitionSlide.module.scss';
import Tooltip from '../../../../common/Tooltip';
import badgeIcon from '../../../../../../../assets/images/icons/download-badge.svg';
import certIcon from '../../../../../../../assets/images/icons/download-cert.svg';

interface TurnInButtonProps {
  badgeUrl: string;
  completed?: boolean;
  exerciseResult?: {
    completedAt: string;
  };
  isPresentMode: boolean;
  lessonId: number;
  lessonData: {
    titleWithPosition: string;
  };
  notebook: {
    id: number;
  };
  programId: string;
  unansweredQuestions: UnansweredQuestion[]
}

const StafferTurnInButton = ({
  completed,
  badgeUrl,
  exerciseResult,
  isPresentMode,
  lessonData,
  lessonId,
  notebook,
  programId,
  unansweredQuestions,
}: TurnInButtonProps) => {
  const { id: notebookId } = notebook;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const [turnedInAt, setTurnedInAt] = useState<string | null>(exerciseResult ? exerciseResult.completedAt : null);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const referrerProgramId = useSlideShowContext(state => state.referrerProgramId);

  const downloadUrl = Routes.pd_certificate_pdf_teacher_program_professional_developments_path(referrerProgramId || -1, {
    slide_show_id: slideShow.id,
  });

  const { isLoading, onClick } = useAsyncFileDownload(downloadUrl, `${slideShow.titleEn}_with_TCI_Certificate.pdf`);

  const showUnansweredQuestions = () => (!completed && unansweredQuestions.length > 0);

  return (
    <div className={styles.stafferTurnInButtonContainer}>
      {!turnedInAt && (
        <>
          <p>{`${I18n.t('you_reached_the_end', { title: lessonData.titleWithPosition })} course!`}</p>
          <span>{I18n.t('receive_certificate')}</span>
          {showUnansweredQuestions() && <UnansweredQuestions unansweredQuestions={unansweredQuestions} />}
          <Tooltip shouldDisplay={!completed} content="To submit, please complete all questions in the course.">
            <div className={styles.stafferTurnInButton}>
              <button
                type="button"
                className="btn btn--purple"
                disabled={submitting || !isPresentMode || !completed}
                onClick={() => {
                  setSubmitting(true);
                  Axios.post(Routes.plato_api_staffer_turn_in_notebook_path(notebookId, {
                    program_id: programId,
                    lesson_id: lessonId,
                  }))
                    .then(() => setTurnedInAt(moment().toDate().toString()))
                    .catch((e: Error) => setError(e))
                    .finally(() => setSubmitting(false));
                }}
              >
                {I18n.t('submit')}
              </button>
            </div>
          </Tooltip>
        </>
      )}
      {turnedInAt && (
        <div className={styles.turnInInfo}>
          <p>Congratulations! Download your certificate and badge.</p>
          <p>
            {I18n.t('turned_in_on', { timestamp: formatLocalTimestamp(turnedInAt, false) })}
          </p>
          <div className={styles.downloadButtons}>
            <button
              className="btn btn--purple dsp-flex-center tw-relative"
              disabled={isLoading}
              onClick={onClick}
              type="button"
            >
              {isLoading && <Spinner className="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-y-1/2 -tw-ml-1.5" />}
              <img src={certIcon} alt="cert_icon" className="mr10" />
              Download Certificate
            </button>
            <a className="btn btn--purple dsp-flex-center" href={badgeUrl} download>
              <img src={badgeIcon} alt="badge_icon" className="mr10" />
              Download Badge
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default StafferTurnInButton;
