import React from 'react';
import ButtonSnippet from '@/components/common/HtmlElements/ButtonSnippet';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';

interface TooltipButtonSnippetProps {
  tooltipButton: ITooltipButton;
}

const Snippet = ({
  tooltipButton: {
    id,
    type,
    data: {
      autoHide,
      backgroundColor = 'purple',
      buttonMaxWidth,
      buttonTextEn,
      buttonTextEs,
    },
  },
}: TooltipButtonSnippetProps) => (
  <ButtonSnippet
    autoHide={autoHide}
    className="fr-deletable tooltip-btn"
    backgroundColor={backgroundColor}
    buttonMaxWidth={buttonMaxWidth}
    buttonTextEn={buttonTextEn}
    buttonTextEs={buttonTextEs}
    id={id}
    type={type}
  />
);

export default Snippet;
