import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import * as Routes from '@/modules/routes';
import cn from '@/utils/cn';
import Axios from 'axios';
import Modal, { useModalManager, Footer } from '@/components/common/Modal';
import styles from './EditScore.module.scss';
import useFlags from '../../../hooks/useFlags';

interface EditMaxScoreModalProps {
  callback?: (num: number) => any;
  classroomId: number;
  initialMaxScore: number;
  lessonId?: number;
  programId: number;
  sectionId?: number;
  setMaxScore?: (next: number) => void;
}

const EditMaxScoreModal = ({
  callback = () => {},
  classroomId,
  initialMaxScore,
  lessonId = null,
  programId,
  sectionId = null,
  setMaxScore: setMaxScoreCallback,
}: EditMaxScoreModalProps) => {
  const modalManager = useModalManager();
  const [maxScore, setMaxScore] = useState(initialMaxScore);
  const { updatedGradesTable } = useFlags();

  return (
    <div className={cn({ inline_block: !updatedGradesTable })}>
      {updatedGradesTable ? (
        <button
          className="dropdown-item tw-min-w-full tw-cursor-pointer focus:tw-bg-inherit"
          onClick={modalManager.open}
          type="button"
        >
          <i className="fa fa-edit" />
          Edit Max Score
        </button>
      ) : (
        <button
          type="button"
          onClick={modalManager.open}
          className={`section-score btn btn--link-purple ${styles.scoreButton}`}
        >
          {maxScore} <i className="fa fa-edit" aria-hidden />
        </button>
      )}
      <Modal
        headerText={`Set ${lessonId ? 'Lesson' : 'Section'} Notebook Score`}
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
      >
        <Form
          onSubmit={(values) => {
            const resource = lessonId ? { lesson_id: lessonId } : { section_id: sectionId };

            const getPath = Routes.change_max_notebook_points_teacher_score_path(classroomId, {
              program_id: programId,
              ...resource,
            });

            Axios.post(getPath, values).then((response: { data: { max_notebook_points: string } }) => {
              setMaxScore(parseInt(response.data.max_notebook_points, 10));
              if (setMaxScoreCallback) setMaxScoreCallback(parseInt(response.data.max_notebook_points, 10));
              callback(parseInt(response.data.max_notebook_points, 10));
              $('#max-lesson-score').trigger('section_score_updated');
            });
          }}
          initialValues={{ max_notebook_grade: maxScore }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <label htmlFor="max-score" className={styles.label}>
                Max Score:
              </label>

              <Field
                id="max-score"
                component="input"
                name="max_notebook_grade"
                min={0}
                type="number"
                className={styles.textInput}
              />

              <div className="mt30">
                <p>Notes:</p>
                <ul className="with-list-style mt5">
                  <li>All scores already assigned will remain the same value.</li>
                  <li>This change applies to all classes.</li>
                </ul>
              </div>

              <Footer
                secondaryButtonCallback={modalManager.close}
              />
            </form>
          )}
        />
      </Modal>
    </div>
  );
};

const EditMaxScore = ({ initialMaxScore, ...props }: EditMaxScoreModalProps) => {
  const { updatedGradesTable } = useFlags();

  if (updatedGradesTable) {
    return (
      <span className="tw-font-light">
        <EditMaxScoreModal initialMaxScore={initialMaxScore} {...props} />
      </span>
    );
  }

  return (
    <span className="tw-font-light tw-text-[14px]">
      Max Score:&nbsp;
      <EditMaxScoreModal initialMaxScore={initialMaxScore} {...props} />
    </span>
  );
};

export default EditMaxScore;
