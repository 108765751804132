import * as constants from './steps';

export default class AutoRosterDistrict {
  constructor(autoRosterDistrict, mappedCoursesSize, subscriber, teacherSeatsLocked) {
    this._autoRosterDistrict = autoRosterDistrict;
    this._mappedCoursesSize = mappedCoursesSize;
    this._subscriber = subscriber;
    this._teacherSeatsLocked = teacherSeatsLocked;
  }

  getCompletedSteps() {
    if (this._getSyncType() === 'tci_csv') {
      return {
        [constants.ROSTER_METHOD_STEP]: this._getSyncType(),
        [constants.CONFIGURATION_STEP]: this._isConfigurationComplete(),
        [constants.DISTRICT_CONFIG_STEP]: this._instructionsSeen(),
        [constants.CONTACT_STEP_CSV]: this._isContactStepComplete(),
        [constants.FINAL_STEP]: this._getSyncType() && this._isConfigurationComplete() &&
          this._instructionsSeen() && this._isContactStepComplete(),
      };
    }
    return {
      [constants.ROSTER_METHOD_STEP]: this._getSyncType(),
      [constants.CONFIGURATION_STEP]: this._isConfigurationComplete(),
      [constants.CONTACT_STEP_NON_CSV]: this._isContactStepComplete(),
      [constants.MAP_COURSES_STEP]: this._isCourseMappingComplete(),
      [constants.FINAL_STEP]: this._getSyncType() && this._isConfigurationComplete() &&
        this._isContactStepComplete() && this._isCourseMappingComplete()
    };
  }

  isSetupComplete() {
    const completedSteps = this.getCompletedSteps();

    return (
      completedSteps[constants.CONTACT_STEP_NON_CSV] &&
      completedSteps[constants.CONFIGURATION_STEP] &&
      completedSteps[constants.ROSTER_METHOD_STEP]
    );
  }

  _isCourseMappingComplete() {
    if (!this._teacherSeatsLocked) return true;

    return this._mappedCoursesSize > 0;
  }

  isCleverComplete() {
    return (
      this._getSyncType() === 'clever_api' &&
      this.isSetupComplete()
    );
  }

  updateTeacherSeatsLocked(teacherSeatsLocked) {
    this._teacherSeatsLocked = teacherSeatsLocked;
  }

  updateDistrict(autoRosterDistrict) {
    this._autoRosterDistrict = autoRosterDistrict;
  }

  updateSubscriber(subscriber) {
    this._subscriber = subscriber;
  }

  _getSyncType() {
    return this._autoRosterDistrict && this._autoRosterDistrict.sync_type;
  }

  _isConfigurationComplete() {
    if (!this._autoRosterDistrict) return false;

    return (
      this._isCleverConfigComplete() ||
      this._isOnerosterConfigComplete() ||
      this._isCsvConfigComplete()
    );
  }

  _isCleverConfigComplete() {
    if (this._getSyncType() !== 'clever_api') {
      return false;
    }

    return (
      this._subscriber &&
      this._subscriber.uid
    );
  }

  _isCsvConfigComplete() {
    if (this._getSyncType() !== 'tci_csv') {
      return false;
    }

    return (
      this._autoRosterDistrict &&
      this._autoRosterDistrict.public_key
    );
  }

  _isOnerosterConfigComplete() {
    if (this._getSyncType() !== 'oneroster_api') {
      return false;
    }

    return (
      this._autoRosterDistrict &&
      this._autoRosterDistrict.client_id &&
      this._autoRosterDistrict.endpoint_url &&
      this._autoRosterDistrict.client_secret
    );
  }

  _instructionsSeen() {
    return this._autoRosterDistrict && this._autoRosterDistrict.instructions_seen;
  }

  _isContactStepComplete() {
    return (
      this._autoRosterDistrict &&
      this._autoRosterDistrict.contact_email &&
      this._autoRosterDistrict.first_name &&
      this._autoRosterDistrict.last_name
    );
  }
}
