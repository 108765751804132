const mergeChildMainIdeas = (node: Element) => {
  const directChildMainIdeas = node.querySelectorAll(':scope > .main-idea');

  directChildMainIdeas.forEach((child) => {
    const textNode = document.createTextNode(child.textContent);
    child.replaceWith(textNode);
  });
};

export const groupMainIdeaSpans = () => {
  const mainIdeaSpans = [...document.querySelectorAll('.main-idea')].reverse();

  mainIdeaSpans.forEach((node) => {
    const nodesToMerge = [];
    let currentNode = node;

    mergeChildMainIdeas(currentNode);

    if (!currentNode.nextSibling) return;

    while (currentNode.nextSibling) {
      const sibling = currentNode.nextSibling as HTMLElement;

      if (sibling.nodeType === Node.TEXT_NODE && sibling.textContent.trim() === '') {
        nodesToMerge.push(sibling);
        currentNode = sibling;
      } else if (sibling?.classList?.contains('main-idea')) {
        nodesToMerge.push(sibling);
        currentNode = sibling;
      } else {
        break;
      }
    }

    nodesToMerge.forEach((mergeable: HTMLElement) => {
      if (mergeable.nodeType === Node.TEXT_NODE) {
        /* eslint no-param-reassign: "error" */
        node.innerHTML += mergeable.textContent;
      } else {
        /* eslint no-param-reassign: "error" */
        node.innerHTML += mergeable.innerHTML;
      }

      mergeable.remove();
    });
  });
};
