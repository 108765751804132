const CHECKMARK_ICON_CLASS = 'checkmark-icon';
const SELECTION_OFF_CLASS = 'tci-custom-selection-off';
const AUDIO_DESCRIPTIONS_MENU_CLASS = 'tci-custom-audio-descriptions-heading';
const NON_CHECKMARK_MENU_ITEM_NAMES = ['CaptionSettingsMenuItem'];
export const ENGLISH_LABEL = 'English';

const AUDIO_DESCRIPTIONS_KEY = 'audioDescriptionsLanguage';
const storedAudioDescriptionsValue = () => localStorage.getItem(AUDIO_DESCRIPTIONS_KEY);
export const audioDescriptionsEnabled = () => storedAudioDescriptionsValue() !== '';

// Set up custom styling for the Audio Descriptions (AD) menu, and
// Store the user selection for AD on/off in localStorage
export const connectNativeAudioDescriptionsMenu = (videoPlayer, audioPlayer) => {
  // @ts-ignore
  const menu = videoPlayer.controlBar.descriptionsButton;
  const menuItems = menu.items;

  menuItems.forEach((menuItem, index) => {
    customizeAudioDescriptionsMenuItem(audioPlayer, menuItem, index)
  });

  // Add CSS to give a checkmark on the selected menu item
  createCheckmarkWrappers(menuItems);

  // Create 'Audio Descriptions' menu heading
  $(menu.menu.el())
    .children('.vjs-menu-content')
    .prepend(`<li class="vjs-menu-item ${AUDIO_DESCRIPTIONS_MENU_CLASS}">Audio Descriptions</li>`)
};

const customizeAudioDescriptionsMenuItem = (audioPlayer, menuItem, index) => {
  const { track: { label: trackLabel } } = menuItem;
  const trackType = trackLabel === ENGLISH_LABEL ? 'en' : null;
  const isSelected = trackType === storedAudioDescriptionsValue();

  // 'Descriptions Off' menu item
  if (index === 0) setInitialOffSelectionText(menuItem);

  // Audio Descriptions menu item
  if (index === 1) {
    // Add AD icon after text
    $(menuItem.el())
      .children('.vjs-menu-item-text')
      .append('<span class="audio-descriptions-icon" />')
  }

  // Store user choice for AD on/off in localStorage
  menuItem.on('click', () => {
    const enabled = audioDescriptionsEnabled();

    if (!enabled && trackType) {
      audioPlayer.muted = false;
    }
    else if (enabled && !trackType) {
      audioPlayer.muted = true;
    }

    localStorage.setItem(AUDIO_DESCRIPTIONS_KEY, trackType || '');
  });

  // Set selection based on local storage setting. Off is selected by default.
  if (isSelected) menuItem.trigger('click');
};

export const createCheckmarkWrappers = (menuItems) => {
  menuItems
    .filter(menuItem => !NON_CHECKMARK_MENU_ITEM_NAMES.includes(menuItem.name_))
    .forEach(menuItem => {
      $(menuItem.el())
        .children('.vjs-menu-item-text')
        .prepend(`<span class="${CHECKMARK_ICON_CLASS}" />`)
  });
};

export const setInitialOffSelectionText = (menuItem) => {
  $(menuItem.el())
    .children('.vjs-menu-item-text')
    .first()
    .html(`<span class="${SELECTION_OFF_CLASS}">Off</span>`);
};

export const setMenuItemText = (menuItem, text) => {
  if (!menuItem) return;
  $(menuItem.el())
    .children('.vjs-menu-item-text')
    .first()
    .text(text);
};

export interface AudioVideoPositionData {
  audioTime: number;
  videoTime: number;
}

const rounded = (time) => Math.round(time * 10) / 10;

export const buildAudioCuesData = (audioDescriptionsCues) => {
  const resultData: AudioVideoPositionData[] = [];

  (audioDescriptionsCues || []).forEach((cue, index) => {
    const audioTime = rounded(cue.start + cue.duration);
    if (index === 0) {
      resultData.push({ videoTime: cue.start, audioTime });
      return;
    }

    const previousCue = resultData[index - 1];
    const playTime = cue.start - previousCue.audioTime;
    resultData.push({ videoTime: rounded(previousCue.videoTime + playTime), audioTime });
  });

  return resultData;
};
