import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import I18n from 'i18n';

import Modal from '../../../common/Modal';

export default class Delete extends Component {
  static propTypes = {
    destroyPath: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      assignment_label: PropTypes.string.isRequired,
      connected_to_canvas: PropTypes.bool.isRequired,
      connected_to_google: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired,
    updateResources: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      submitting: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleDelete() {
    this.setState({ submitting: true });
    axios
      .delete(this.props.destroyPath.replace(':id', this.props.rowData.id))
      .then(response => this.updateTable(response))
      .catch(error => this.handleError(error));
  }

  updateTable(response) {
    if (response.status === 200) {
      this.props.updateResources(response.data.data, 'delete');
    }
    this.closeModal();
  }

  handleError(error) {
    this.props.updateResources(error.toString(), 'error');
    this.closeModal();
  }

  _renderDeleteButton() {
    let text = 'Delete';
    if (this.state.submitting) {
      text = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--red"
        disabled={this.state.submitting}
        onClick={this.handleDelete}
      >
        {text}
      </button>
    );
  }


  renderModal() {
    if (this.state.modalIsOpen) {
      return (
        <Modal
          closeModal={this.closeModal}
          headerText="Delete Assignment"
          isOpen={this.state.modalIsOpen}
        >
          {this.props.rowData.connected_to_google && (
            <p className="mb20">Deleting this assignment will also delete the synced assignment on Google Classroom.</p>
          )}
          {this.props.rowData.connected_to_canvas && (
            <p className="mb20">Deleting this assignment will also delete the synced assignment on Canvas.</p>
          )}
          <p className="mb10 bold">
            Are you sure you want to delete the assignment:
          </p>
          <p>
            {this.props.rowData.assignment_label}
          </p>

          <hr />

          <footer>
            <button
              className="btn btn--link-purple"
              onClick={this.closeModal}
            >
              Cancel
            </button>

            {this._renderDeleteButton()}
          </footer>
        </Modal>
      );
    }

    return null;
  }

  render() {
    return (
      <span>
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={this.openModal}
        >
          <i className="fa fa-trash" /> {I18n.t('delete')}
        </a>
        {this.renderModal()}
      </span>
    );
  }
}
