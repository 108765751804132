import anchorIcon from './anchoring-phenomenon.svg';
import assessmentsIcon from './assessments@2x.svg';
import checkIcon from './check.svg';
import closingProjectIcon from './closing-project.svg';
import discussIcon from './discuss.svg';
import explainAndProcessIcon from './explain-and-processing.svg';
import gamesIcon from './games.svg';
import gradesIcon from './grades.svg';
import hoaNotesIcon from './HOA-notes.svg';
import hoiIcon from './HOI.svg';
import infoIcon from './info.svg';
import infoFilledIcon from './info-filled.svg';
import localConnectionsIcon from './local-connections.svg';
import materialsIcon from './materials.svg';
import observeIcon from './observe.svg';
import openingProjectIcon from './opening-project.svg';
import overviewIcon from './overview.svg';
import quickCheckIcon from './quick-check.svg';
import readingIcon from './reading.svg';
import readingCheckIcon from './reading-check.svg';
import settingsIcon from './settings.svg';
import showWhatYouKnowIcon from './show-what-you-know.svg';
import videoActivityIcon from './video-activity.svg';
import vocabIcon from './vocab.svg';

const icons = {
  readingIcon,
  vocabIcon,
  showWhatYouKnowIcon,
  hoiIcon,
  observeIcon,
  explainAndProcessIcon,
  videoActivityIcon,
  gamesIcon,
  assessmentsIcon,
  settingsIcon,
  checkIcon,
  anchorIcon,
  readingCheckIcon,
  discussIcon,
  localConnectionsIcon,
  overviewIcon,
  // closingProjectIcon,
  // gradesIcon,
  // hoaNotesIcon,
  // infoIcon,
  // infoFilledIcon,
  // materialsIcon,
  // openingProjectIcon,
  // quickCheckIcon,
};

export default icons;
