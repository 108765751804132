import React, { Component } from 'react';
import Axios from 'axios';
import SysadminTable from 'common/SysadminTable';
import Form from './Form';
import Row from './Row';
import { ModalButton } from "../Utils";

const columns = [
  { text: 'Image', className: 'image' },
  { text: 'Name', className: 'name' },
  { text: 'Quantity', className: 'quantity' },
  { text: 'Unit', className: 'unit' },
  { text: 'Distribution', className: 'distribution' },
  { text: 'Source', className: 'program-kit-material' },
  { text: 'Reusable', className: 'reusable' },
  { text: 'Recyclable', className: 'recyclable' },
  { text: 'Safety Notes', className: 'safety-notes' },
  { text: 'Actions', className: 'actions' }
];

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static defaultProps = {
    error: null,
    materials: [],
    safetyNotes: [],
    isLoading: false,
    modalIsOpen: false,
    emptyMessage: null,
    previewUrl: '#'
  };

  render() {

    return (
      <SysadminTable containerClass="lesson-modules-materials"
                     tableId="module-materials"
                     columns={columns}
                     isLoading={this.state.isLoading}
                     buttonAbove={this.renderButtonAbove()}
                     emptyMessage={this.props.emptyMessage}>
        {this.renderMaterials()}
      </SysadminTable>
    );
  }

  renderButtonAbove() {
    const modelType = this.props.modelType === 'Lesson' ? 'Lesson' : 'Module';
    return (
      <div>
        <ModalButton buttonText={`Add ${modelType} Material`}
                     buttonClass="action"
                     modalHeader={`Add ${modelType} Material`}
                     modalClass="add-materials"
                     newRecord={true}
                     openModal={this.openModal.bind(this)}
                     closeModal={this.closeModal.bind(this)}
                     onAdd={this.addRow.bind(this)}
                     FormComponent={Form}
                     {...this.state} />
        <a href={this.props.previewUrl} target="_blank" className="action ml5">Preview</a>
      </div>
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    this.getMaterials();
  }

  sortByName(a, b) {
    return (a.handoutTitle || a.name).localeCompare(b.handoutTitle || b.name);
  }

  addRow(material) {
    this.setState(prevState => ({
      materials: [...prevState.materials, material].sort(this.sortByName)
    }));
  }

  updateRow(updatedMaterial) {
    this.setState({
      materials: this.state.materials.map(material =>
        updatedMaterial.id === material.id ? updatedMaterial : material
      ).sort(this.sortByName)
    });
  }

  removeRow(id) {
    this.setState({
      materials: this.state.materials.filter(material => material.id !== id)
    });
  }

  getMaterials() {
    this.setState({ isLoading: true });
    Axios.get('get_materials').then(response => {
      const { materials, forSelect, units } = response.data;
      this.setState({
        materials,
        forSelect,
        units,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  renderMaterials() {
    return this.state.materials && this.state.materials.map((material) => {
      return (
        <Row key={material.id}
             newRecord={false}
             forSelect={this.state.forSelect}
             units={this.state.units}
             removeRow={this.removeRow.bind(this)}
             updateRow={this.updateRow.bind(this)}
             {...this.props}
             {...material} />
      );
    });
  }
}
