import { useEffect } from 'react';

/**
 * A hook created to retroactively adding a class name to the parent span of audio tags
 * stored in slide objects of type "text".
 * Could theoretically be used to add anything to text content that needs to be modified.
 * @param editorInstance
 */
export default function useRetroactiveAdder(editorInstance) {
  useEffect(() => {
    if (!editorInstance) return;

    setTimeout(() => {
      const audioTags = document.querySelectorAll('.fr-element audio');
      let numChanged = 0;

      audioTags.forEach((audioTag) => {
        if (audioTag.parentNode.classList.contains('full-player-span')) return;

        audioTag.parentNode.classList.add('full-player-span');
        numChanged += 1;
      });

      if (numChanged > 0) editorInstance.undo.saveStep();
    }, 0);
  }, [editorInstance]);
}
