export default class GreenhouseSimulation {
  constructor(config) {
    this.container = config.container;
    this.fps = config.fps || 30;
    this.width = 660;
    this.height = 482;
    this.visible_photons = new Map();
    this.infrared_photons = new Map();
    this.frame_count = 0;
    this.number_of_clouds = 0;
    this.BASE_TEMP = -15;
    this.paused = false;
    this.play_speed = 10;
    this.produce_heat = false;
    this.REFLECTION_THRESHOLD = .61;
    this.gas_reflection_threshold = 97.5;
    this.BASE_PHOTONS = 45;
    this.MAX_PHOTON_MULTIPLIER = 30;
    this.CONCENTRATION_PRESETS = {
      'today': {
        'co2': '388 ppm',
        'ch4': '1.843 ppm',
        'n2o': '0.317 ppm',
        'val': 1.5
      },
      '1750': {
        'co2': '280 ppm',
        'ch4': '0.730 ppm',
        'n2o': '0.270 ppm',
        'val': 1.0
      },
      'Ice age': {
        'co2': '180 ppm',
        'ch4': '0.380 ppm',
        'n2o': '0.215 ppm',
        'val': 0.6
      }
    };
    this.BASE_URL = config.BASE_URL;
    this.photon_urls = {
      infrared: this.BASE_URL + 'Photon_Infrared.png',
      sunlight: this.BASE_URL + 'Photon_Visible.png'
    }
  }

  init() {
    this._build();

    this._setupListeners();

    this.time_marker_prev = Date.now();
    this.interval = 1000/this.fps;


    window.requestAnimationFrame(this.loop.bind(this));
  }

  _build() {
    this.backdrop = document.createElement('div');
    this.backdrop.className = 'simulation-backdrop greenhouse';

    this.canvas = document.createElement('canvas');
    this.canvas.className = 'simulation-canvas';

    this.canvas.height = this.height;
    this.canvas.width = this.width;

    this.menu = document.createElement('div');
    this.menu.className = 'simulation-menu';

    this.legend_header = document.createElement('div');
    this.legend_header.className = 'simulation-submenu-header';
    this.legend_header.innerHTML = 'Legend';
    this.menu.appendChild(this.legend_header);

    this.legend = document.createElement('div');
    this.legend.className = 'simulation-submenu';

    this.visible_photon_legend = document.createElement('div');
    this.visible_photon_legend.className = 'simulation-photon-legend visible';
    this.visible_photon_legend.innerHTML = 'Visible Sunlight';
    this.legend.appendChild(this.visible_photon_legend);

    this.infrared_photon_legend = document.createElement('div');
    this.infrared_photon_legend.className = 'simulation-photon-legend infrared';
    this.infrared_photon_legend.innerHTML = 'Infrared Sunlight';
    this.legend.appendChild(this.infrared_photon_legend);

    this.menu.appendChild(this.legend);

    this.atmosphere_during_header = document.createElement('div');
    this.atmosphere_during_header.className = 'simulation-submenu-header';
    this.atmosphere_during_header.innerHTML = 'Atmosphere during...';

    this.menu.appendChild(this.atmosphere_during_header);

    this.atmosphere_during = document.createElement('div');
    this.atmosphere_during.className = 'simulation-submenu';

    this.atm_selector_wrapper = document.createElement('div');
    this.atm_selector_wrapper.className = 'simulation-atm-select-wrapper';

    this.atm_selector = document.createElement('select');
    this.atm_selector.className = 'simulation-atm-selector';
    this.atm_today_option = document.createElement('option');
    this.atm_today_option.value = 'today';
    this.atm_today_option.innerHTML = 'Today';
    this.atm_today_option.selected = true;
    this.atm_selector.appendChild(this.atm_today_option);

    this.atm_1750_option = document.createElement('option');
    this.atm_1750_option.value = '1750';
    this.atm_1750_option.innerHTML = '1750';
    this.atm_selector.appendChild(this.atm_1750_option);

    this.atm_ice_age_option = document.createElement('option');
    this.atm_ice_age_option.value = 'Ice age';
    this.atm_ice_age_option.innerHTML = 'Ice age';
    this.atm_selector.appendChild(this.atm_ice_age_option);

    this.atm_adjustable_option = document.createElement('option');
    this.atm_adjustable_option.value = 'adjustable';
    this.atm_adjustable_option.innerHTML = 'Adjustable';
    this.atm_selector.appendChild(this.atm_adjustable_option);

    this.atmosphere_during.appendChild(this.atm_selector_wrapper);
    this.atm_selector_wrapper.appendChild(this.atm_selector);

    this.atm_adjustable_slider_wrapper = document.createElement('div');
    this.atm_adjustable_slider_wrapper.className = 'simulation-concentration-control-wrapper';

    this.atm_adjustable_text = document.createElement('div');
    this.atm_adjustable_text.className = 'simulation-adjustable-text';
    this.atm_adjustable_text.innerHTML = 'Greenhouse Gas Concentration';
    this.atm_adjustable_slider_wrapper.appendChild(this.atm_adjustable_text);

    this.atm_adjustable_slider = document.createElement('input');
    this.atm_adjustable_slider.type = 'range';
    this.atm_adjustable_slider.className = 'simulation-concentration-control';
    this.atm_adjustable_slider.min = '0';
    this.atm_adjustable_slider.max = '3';
    this.atm_adjustable_slider.step = '.1';
    this.atm_adjustable_slider.value = this.CONCENTRATION_PRESETS['today']['val'].toString();

    this.atm_adjustable_slider_wrapper.appendChild(this.atm_adjustable_slider);
    this.atmosphere_during.appendChild(this.atm_adjustable_slider_wrapper);

    this.co2_reading = document.createElement('div');
    this.co2_reading.className = 'simulation-gas-reading co2';
    this.co2_reading.innerHTML = '388 ppm';

    this.atmosphere_during.appendChild(this.co2_reading);

    this.ch4_reading = document.createElement('div');
    this.ch4_reading.className = 'simulation-gas-reading ch4';
    this.ch4_reading.innerHTML = '1.843 ppm';

    this.atmosphere_during.appendChild(this.ch4_reading);

    this.n2o_reading = document.createElement('div');
    this.n2o_reading.className = 'simulation-gas-reading n2o';
    this.n2o_reading.innerHTML = '0.317 ppm';

    this.atmosphere_during.appendChild(this.n2o_reading);
    this.menu.appendChild(this.atmosphere_during);

    this.controls_header = document.createElement('div');
    this.controls_header.className = 'simulation-submenu-header';
    this.controls_header.innerHTML = 'Controls';

    this.menu.appendChild(this.controls_header);

    this.controls = document.createElement('div');
    this.controls.className = 'simulation-submenu';

    this.play_button = document.createElement('div');
    this.play_button.className = 'simulation-play-button pause';

    this.controls.appendChild(this.play_button);

    this.speed_slider_wrapper = document.createElement('div');
    this.speed_slider_wrapper.className = 'simulation-speed-control-wrapper';

    this.speed_slider = document.createElement('input');
    this.speed_slider.type = 'range';
    this.speed_slider.className = 'simulation-speed-control';
    this.speed_slider.min = '1';
    this.speed_slider.max = '10';
    this.speed_slider.value = '10';
    this.speed_slider.step = '1';

    this.controls.appendChild(this.speed_slider_wrapper);
    this.speed_slider_wrapper.appendChild(this.speed_slider);

    this.menu.appendChild(this.controls);

    this.clouds_label = document.createElement('div');
    this.clouds_label.innerHTML = 'Clouds';
    this.clouds_label.className = 'simulation-submenu-clouds-label';

    this.clouds_selector_wrapper = document.createElement('div');
    this.clouds_selector_wrapper.className = 'simulation-clouds-select-wrapper';

    this.clouds_selector = document.createElement('select');
    this.clouds_selector.className = 'simulation-select clouds';
    this.clouds_selector.innerHTML = `
        <option value=0>0</option>
        <option value=1>1</option>
        <option value=2>2</option>
        <option value=3>3</option>`;

    this.controls.appendChild(this.clouds_label);
    this.controls.appendChild(this.clouds_selector_wrapper);
    this.clouds_selector_wrapper.appendChild(this.clouds_selector);

    this.backdrop.appendChild(this.canvas);
    this.container.appendChild(this.backdrop);
    this.container.appendChild(this.menu);

    this.ctx = this.canvas.getContext('2d');

    this.thermometer = new Thermometer({
      container: this.container,
      parent: this
    });

    this.thermometer.build();

  }

  _setupListeners() {
    $(this.clouds_selector).on('change', (e) => {
      this.number_of_clouds = e.target.value;
    });

    $(this.atm_adjustable_slider).on('change', (e) => {
      this.atm_adjustable_option.selected = true;
      $(this.co2_reading).hide();
      $(this.n2o_reading).hide();
      $(this.ch4_reading).hide();
      this.gas_reflection_threshold = 99 - parseFloat(e.target.value);
    });

    $(this.atm_selector).on('change', (e) => {
      if (e.target.value === 'adjustable') {
        $(this.co2_reading).hide();
        $(this.n2o_reading).hide();
        $(this.ch4_reading).hide();
      }
      else {
        this.populateReadings(this.CONCENTRATION_PRESETS[e.target.value]);
        this.gas_reflection_threshold = 99 - parseFloat(this.CONCENTRATION_PRESETS[e.target.value]['val']);
        this.atm_adjustable_slider.value = this.CONCENTRATION_PRESETS[e.target.value]['val'].toString();
      }
    });

    $(this.play_button).on('click', () => {
      if (this.paused) {
        this.play();
      }
      else {
        this.pause();
      }
    });

    $(this.speed_slider).on('change', (e) => {
      this.play_speed = parseInt(e.target.value, 10);
    });
  }

  pause() {
    this.play_button.className = 'simulation-play-button play';
    this.paused = true;
  }

  play() {
    this.play_button.className = 'simulation-play-button pause';
    this.paused = false;
    this.loop();
  }

  loop() {
    this.time_marker_now = Date.now();
    this.delta = this.time_marker_now - this.time_marker_prev;

    if (this.delta > this.interval) {
      this.frame_count++;

      this._clearCanvas();

      if (this.frame_count % (15 + 10 - this.play_speed) === 0) {
        let id = this.frame_count;

        this.visible_photons.set(id, new VisiblePhoton({
          parent: this,
          pos_x: getRandomInt(this.width),
          pos_y: 0,
          velocity: {x: 0, y: 1}
        }));
      }

      if (this.produce_heat && this.frame_count % (10 + 10 - this.play_speed) === 0) {
        let id = this.frame_count;

        this.infrared_photons.set(id, new InfraredPhoton({
          parent: this,
          pos_x: getRandomInt(this.width),
          pos_y: this.height * .9,
          velocity: {x: 0, y: -1}
        }));
      }

      this._updateObjects();
      this._drawObjects();
      this.time_marker_prev = this.time_marker_now - (this.delta % this.interval);
    }

    if (!this.paused) {
      window.requestAnimationFrame(this.loop.bind(this));
    }
  }

  populateReadings(preset) {
    this.co2_reading.innerHTML = preset['co2'];
    this.n2o_reading.innerHTML = preset['n2o'];
    this.ch4_reading.innerHTML = preset['ch4'];
    this.co2_reading.style.display = 'block';
    this.ch4_reading.style.display = 'block';
    this.n2o_reading.style.display = 'block';
  }

  _clearCanvas() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  _updateObjects() {
    let reflection_points = this.getCloudLocations(this.number_of_clouds);

    this.visible_photons.forEach((v, k, m) => {
      if (v.pos_y > this.height) {
        this.produce_heat = true;
        this.visible_photons.delete(k);
      }
      else {
        v.move();
      }
    });

    this.infrared_photons.forEach((v, k, m) => {
      if (v.pos_y < 0) {
        this.infrared_photons.delete(k);
      }
      else {
        v.reflect(reflection_points);
        if (this._shouldReflectPhotons()) {
          v.gas_reflect();
        }
        v.move();
      }
    });

    this.thermometer.setReading(this.calculateTemperature());

    if (this.frame_count % 100 === 0) {
      this.thermometer.updateCylinder();
    }
  }

  _shouldReflectPhotons() {
    const maxPhotons = parseInt(this.BASE_PHOTONS + this.atm_adjustable_slider.value * this.MAX_PHOTON_MULTIPLIER, 10);
    return this.infrared_photons.size <= maxPhotons;
  }

  _drawObjects() {
    let clouds = this.getCloudLocations(3);

    this.infrared_photons.forEach((v, k, m) => {
      v.draw();
    });

    if (this.number_of_clouds > 0) {
      this.drawCloud(clouds[0].x, clouds[0].y, this.width * .2, this.width * .06);
    }

    if (this.number_of_clouds > 1) {
      this.drawCloud(clouds[1].x, clouds[1].y, this.width * .2, this.width * .06);
    }

    if (this.number_of_clouds > 2) {
      this.drawCloud(clouds[2].x, clouds[2].y, this.width * .2, this.width * .06);
    }

    this.visible_photons.forEach((v, k, m) => {
      v.draw();
    });
  }

  drawCloud(x, y, w, h) {
    this.ctx.fillStyle = '#cccccc';
    this.ctx.beginPath();
    this.ctx.arc(x, y, .8 * h, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.fillStyle = '#cccccc';
    this.ctx.beginPath();
    this.ctx.arc(x - .25 * w, y, h * .7, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.fillStyle = '#cccccc';
    this.ctx.beginPath();
    this.ctx.arc(x - .5 * w, y, h * .35, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.fillStyle = '#cccccc';
    this.ctx.beginPath();
    this.ctx.arc(x + .2 * w, y, h * .6, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.fillStyle = '#cccccc';
    this.ctx.beginPath();
    this.ctx.arc(x + .4 * w, y, h * .3, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fill();
  }

  resize() {
    this.width = $(this.container).innerWidth();
    this.height = $(this.container).innerHeight();
  }

  calculateTemperature() {
    return this.BASE_TEMP + parseInt((this.infrared_photons.size * .9), 10);
  }

  getCloudLocations(num) {
    let locations;
    switch(parseInt(num, 10)) {
      case 1:
        locations = [
          { y: this.height - (.3 * this.height), x: this.width * .2 }
        ];
        break;
      case 2:
        locations = [
          { y: this.height - (.3 * this.height), x: this.width * .2},
          { y: this.height - (.5 * this.height), x: this.width * .5}
        ];
        break;
      case 3:
        locations = [
          { y: this.height - (.3 * this.height), x: this.width * .2},
          { y: this.height - (.5 * this.height), x: this.width * .5},
          { y: this.height - (.4 * this.height), x: this.width * .7}
        ];
        break;
      default:
        locations = [];
        break;
    }

    return locations;
  }
}

class Photon {
  constructor(config) {
    this.parent = config.parent;
    this.pos_x = config.pos_x || this.parent.width / 4;
    this.pos_y = config.pos_y || 0;
    this.velocity = config.velocity || {x: 0, y: 1};
    this.always_visible = config.always_visible || false;
    this.image = new Image();
    this.image.src = this.parent.photon_urls.sunlight;
  }

  move() {
    this.pos_x += (this.velocity.x * .004 * this.parent.width) * (this.parent.play_speed / 10);
    this.pos_y += (this.velocity.y * .004 * this.parent.width) * (this.parent.play_speed / 10);
  }

  draw() {
    this.parent.ctx.drawImage(this.image, this.pos_x, this.pos_y, this.parent.width * .02, this.parent.width * .02);
  }
}

class InfraredPhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.infrared;
  }

  reflect(reflection_points) {
    for (let i = 0; i < reflection_points.length; i++) {
      if (
        // Check for cloud collisions, and reflect a percentage
        (this.pos_y >= reflection_points[i]['y'] - (.001 * this.parent.width) && this.pos_y <= reflection_points[i]['y'] + (.001 * this.parent.width))
        && this.pos_x >= reflection_points[i]['x'] && this.pos_x <= (reflection_points[i]['x'] + this.parent.width * .2 + (.001 * this.parent.width))
        && Math.random() > this.parent.REFLECTION_THRESHOLD
      ) {
        this.velocity.y = 0 - this.velocity.y;
        break;
      }
      if (this.pos_y > this.parent.height && this.velocity.y > 0) {
        this.velocity.y = 0 - this.velocity.y;
      }
    }
  }

  gas_reflect() {
    if (Math.random() * 100 > this.parent.gas_reflection_threshold) {
      this.velocity.y = 0 - this.velocity.y;
    }
    if (this.pos_y > this.parent.height && this.velocity.y > 0) {
      this.velocity.y = 0 - this.velocity.y;
    }
  }
}

class VisiblePhoton extends Photon {
  constructor(config) {
    super(config);
    this.image.src = this.parent.photon_urls.sunlight;
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function _log(fc, msg, val) {
  if (fc % 100 == 0) {
    console.log(msg, val);
  }
}

class Thermometer {
  constructor(config) {
    this.container = config.container;
    this.parent = config.parent;
  }

  build() {
    this.wrapper = document.createElement('div');
    this.wrapper.className = 'simulation-thermometer';


    this.therm_fill = document.createElement('div');
    this.therm_fill.className = 'simulation-thermometer-fill';

    this.therm_outline = document.createElement('div');
    this.therm_outline.className = 'simulation-thermometer-outline';

    this.reading = document.createElement('div');
    this.reading.className = 'simulation-thermometer-text';
    this.reading.innerHTML = '50';

    this.therm_outline.appendChild(this.reading);

    this.wrapper.appendChild(this.therm_fill);
    this.wrapper.appendChild(this.therm_outline);

    this.container.appendChild(this.wrapper);
  }

  setReading(val) {
    this.reading.innerHTML = this.val = val;
  }

  updateCylinder() {
    let percent = (this.val / 175) * 100;

    if (percent >= 40) {
      this.therm_fill.className = 'simulation-thermometer-fill per_40';
    }
    if (percent >= 45) {
      this.therm_fill.className = 'simulation-thermometer-fill per_45';
    }
    if (percent >= 50) {
      this.therm_fill.className = 'simulation-thermometer-fill per_50';
    }
    if (percent >= 55) {
      this.therm_fill.className = 'simulation-thermometer-fill per_55';
    }
    if (percent >= 60) {
      this.therm_fill.className = 'simulation-thermometer-fill per_60';
    }
    if (percent >= 65) {
      this.therm_fill.className = 'simulation-thermometer-fill per_65';
    }
    if (percent >= 70) {
      this.therm_fill.className = 'simulation-thermometer-fill per_70';
    }
    if (percent >= 75) {
      this.therm_fill.className = 'simulation-thermometer-fill per_75';
    }
    if (percent >= 80) {
      this.therm_fill.className = 'simulation-thermometer-fill per_80';
    }
    if (percent >= 85) {
      this.therm_fill.className = 'simulation-thermometer-fill per_85';
    }
    if (percent >= 90) {
      this.therm_fill.className = 'simulation-thermometer-fill per_90';
    }
    if (percent >= 95) {
      this.therm_fill.className = 'simulation-thermometer-fill per_95';
    }
  }
}
