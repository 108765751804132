import PropTypes from 'prop-types';
import React, { useRef, useLayoutEffect, useContext, useState, useEffect } from 'react';
import cn from '@/utils/cn';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import BuilderContext from 'common/Context';
import ItemTypes from '../../ItemTypes';
import styles from './DraggableChoice.module.scss';
import DeleteButton from '../DeleteButton';
import DraggableChoiceContent from '../../DraggableChoiceContent';
import { resizeWithinContainer, getTextContent } from '../../utils';
import useVocabularyStore from '../../useVocabularyStore';
import useDragAndDropStore from '../../useDragAndDropStore';

const DraggableChoice = ({ dropZoneId, id, title, hideDelete, dragZoneWidth, dragZoneHeight, vocabId }) => {
  const vocabularyTerms = useVocabularyStore(state => state.vocabularyTerms);
  const setIsGloballyDragging = useDragAndDropStore(state => state.setIsGloballyDragging);
  const { loading, hasTransparentAnswerChoices, dropZoneShape } = useContext(BuilderContext);
  const [textContent, setTextContent] = useState('');

  const type = ItemTypes.draggableChoice;
  const label = `Draggable choice: ${id}`;
  const deleteLabel = `Delete draggable choice: ${title}`;

  // When using a dependency array for `useDrag` with the `dropEffect` option,
  // the dependencies must be added to the dropEffect for it to work.
  // See bug: https://github.com/react-dnd/react-dnd/issues/3345
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    item: { dropZoneId, id, textContent, title, type },
    options: {
      dropEffect: 'move', textContent
    },
    type,
  }), [textContent]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    setIsGloballyDragging(isDragging);
  }, [isDragging, setIsGloballyDragging]);

  const renderDeleteButton = () => {
    if (dropZoneId || hideDelete) return undefined;

    return (
      <DeleteButton id={id} type={type} label={deleteLabel} />
    );
  };

  const contentRef = useRef(null);
  const resizeListener = () => {
    resizeWithinContainer(contentRef, dragZoneWidth, dragZoneHeight);
  };

  useEffect(() => {
    setTextContent(getTextContent(vocabId, vocabularyTerms, loading, title, 'term'));
  }, [vocabId, vocabularyTerms, loading, title]);

  useLayoutEffect(() => {
    resizeListener();
    setTimeout(() => resizeListener(), 100);

    window.addEventListener('resize', resizeListener);

    // eslint-disable-next-line consistent-return
    return () => { window.removeEventListener('resize', resizeListener); };
  }, [contentRef, contentRef?.current, dragZoneHeight, dragZoneWidth]);

  return (
    <div ref={contentRef}>
      <div
        ref={drag}
        key={id}
        className={cn(
          styles.draggableChoice, {
            [styles.transparentBackground]: hasTransparentAnswerChoices,
            [styles.circle]: dropZoneShape === 'circle',
            [styles.rectangle]: dropZoneShape !== 'circle',
          }
        )}
        role="button"
        aria-label={label}
        tabIndex={0}
      >
        {renderDeleteButton()}
        <DraggableChoiceContent className={styles.content} content={textContent} />
      </div>
    </div>
  );
};

DraggableChoice.propTypes = {
  dragZoneHeight: PropTypes.number,
  dragZoneWidth: PropTypes.number,
  dropZoneId: PropTypes.string,
  hideDelete: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  vocabId: PropTypes.number,
};

DraggableChoice.defaultProps = {
  dropZoneId: null,
  hideDelete: true,
  vocabId: null,
};

export default DraggableChoice;
