export type RowData = {
  id: number;
  name: string;
  num_program_mappings: number;
  num_classrooms: number;
  assigned_student_seats_count: number;
  assigned_teacher_seats_count: number;
  num_subscriptions: number;
};

export const CONFIRM_REMOVE_TEXT = 'remove demo license';

export const getCurrentDate = () => new Date().toLocaleDateString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});
