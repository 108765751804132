import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';

import Modal from '../../../common/Modal';
import Form from './Form';
import Tooltip from '../../../common/Tooltip';
import styles from './index.module.scss';

export default class ShareLink extends Component {
  static propTypes = {
    button: PropTypes.bool,
    iconClasses: PropTypes.string,
    linkClasses: PropTypes.string,
    linkText: PropTypes.string,
    model: PropTypes.string.isRequired,
    replaceOnShare: PropTypes.bool,
    sharable: PropTypes.bool,
    shareQuestionTooltip: PropTypes.bool,
    shared: PropTypes.bool,
    sharedToDistrict: PropTypes.bool
  };

  static defaultProps = {
    button: false,
    iconClasses: null,
    linkClasses: null,
    linkText: null,
    replaceOnShare: false,
    sharable: true,
    shareQuestionTooltip: false,
    shared: false,
    sharedToDistrict: false
  };

  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  _openModal(event) {
    event.preventDefault();
    if (this.props.shared) return;
    this.setState({modalIsOpen: true});
  }

  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  _onSuccess(districtOnly) {
    this.setState({shared: true, sharedToDistrict: districtOnly === 'true'});
  }

  _shareButton() {
    return (
      this.props.button ?
      <button
        type="button"
        className={this.props.linkClasses}
        onClick={this._openModal.bind(this)}>
        <i className={`fa fa-share ${this.props.iconClasses}`} aria-hidden="true" /> {this.props.linkText}
      </button> :
        this.state.sharable ?
          <a
            href="#"
            className={`${this.props.linkClasses} ${this.props.shared && 'disabled link-disabled'} js-prevent-default`}
            onClick={this._openModal.bind(this)}
            role="button">
            <i className={`fa fa-share ${this.props.iconClasses}`} aria-hidden="true" /> {this.props.linkText}
          </a> :
          <a
            href="#"
            role="button"
            className={`${this.props.linkClasses} js-prevent-default link-disabled disabled`}>
            <i className={`fa fa-share ${this.props.iconClasses}`} aria-hidden="true" /> {this.props.linkText}
          </a>
    )
  }

  _sharedText() {
    return (
      <div className="p5">
        {this.props.replaceOnShare &&
          <span className="shared-question"><i className="fa fa-check mr5" aria-hidden="true" /></span>}
        This {this.props.model.toLowerCase()} is currently shared
        {this.state.sharedToDistrict ? ` within your district only` :
                                       ` with all teachers, including your district`}.
      </div>
    )
  }

  _sharedTooltip() {
    return (
      <Tooltip
        interactive={false}
        content={renderToString(this._sharedText())}>
        {this._shareButton()}
      </Tooltip>
    );
  }

  static _unsharableText() {
    return (
      <div className="p5">
        Tests without questions can’t be shared.
      </div>
    );
  }

  _unsharableTooltip() {
    return (
      <Tooltip
        interactive={false}
        content={renderToString(ShareLink._unsharableText())}>
        {this._shareButton()}
      </Tooltip>
    );
  }

  _shareQuestionTooltip() {
    if (!this.props.shareQuestionTooltip) return null;

    const tooltipMessage = 'Help your fellow teachers by sharing your assessment questions! ' +
      "We'll put your question in the Teacher Question Bank for teachers to add to their own assessments.";

    return (
      <h4 className={styles.shareQuestion}>
        Share Your Creativity
        <Tooltip content={tooltipMessage} size="medium" theme="white">
          <i className="fa fa-question-circle" aria-label={tooltipMessage} />
        </Tooltip>
      </h4>
    );
  }

  componentDidMount() {
    $(document).on('ajax:complete', function(xhr) {
      const { num_of_questions } = JSON.parse(xhr.detail[0].responseText);
      this.setState({sharable: num_of_questions > 0})
    }.bind(this));
  }

  _shareQuestionContent() {
    if (this.state.shared && this.props.replaceOnShare) return this._sharedText();
    if (this.props.shared) return this._sharedTooltip();
    if (this.state.sharable) return this._shareButton();

    return this._unsharableTooltip();
  }

  render() {
    return (
      <div className={styles.shareQuestionContainer}>
        {this._shareQuestionTooltip()}
        <div>
          {this._shareQuestionContent()}

          <Modal
            closeModal={this._closeModal.bind(this)}
            isOpen={this.state.modalIsOpen}
            headerText={`Share ${this.props.model}`}
          >
            <Form
              onSuccess={this._onSuccess.bind(this)}
              closeModal={this._closeModal.bind(this)}
              {...this.props}
            />
          </Modal>
        </div>
      </div>
    );
  }
}
