import React from 'react';
import * as Routes from 'routes';
import Axios from 'axios';
import SelectAsync from 'react-select/lib/Async';
import PropTypes from 'prop-types';
import styles from '../../ElementInsert.module.scss';

const VideoSelect = ({ chosenVideo, editMode, handleVideoChange }) => {
  const searchPath = value => Routes.plato_api_search_videos_path({ search: { video_file_name_cont: value } });

  const formatOptions = videos => videos.map(video => ({
    ...video,
    label: video.video_file_name,
    modelVideoId: (editMode && chosenVideo) ? chosenVideo.modelVideoId : undefined,
    value: video.id,
  }));

  const loadOptions = input => (
    Axios
      .post(searchPath(input))
      .then(response => ({ options: formatOptions(response.data.data) }))
      .catch(error => console.log(error))
  );

  return (
    <>
      <label htmlFor="jumpToSelect" className={`${styles.required} ${styles.labelText}`}>
        Video
      </label>
      <SelectAsync
        className={styles.videoSelect}
        loadOptions={loadOptions}
        onChange={handleVideoChange}
        value={chosenVideo}
      />
    </>
  );
};

VideoSelect.propTypes = {
  chosenVideo: PropTypes.shape({
    label: PropTypes.string.isRequired,
    modelVideoId: PropTypes.string,
    value: PropTypes.number.isRequired,
  }),
  editMode: PropTypes.bool.isRequired,
  handleVideoChange: PropTypes.func.isRequired,
};

VideoSelect.defaultProps = {
  chosenVideo: {
    modelVideoId: null,
  },
};

export default VideoSelect;
