import React, { useEffect, useRef, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { IStyleSheet } from '@/components/interfaces/Theme';
import { queryClient } from '@/utils/ReactQuery';
import useStyleSheets from '@/hooks/api/useStyleSheets';
import useEditStyleSheetMutation from '@/hooks/api/useEditStyleSheetMutation';
import ITheme from '@/components/interfaces/Theme';
import './froala-styles.scss';
import ThemeDropdown from './ThemeDropdown';
import SubmitButton from '@/components/ui/Buttons/SubmitButton';
import { useCodeMirror } from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';

type StyleSheetEditorProps = {
  themeId?: number;
};

const extensions = [css()];

const StyleSheetEditor = ({ themeId }: StyleSheetEditorProps) => {
  const [currentThemeId, setCurrentThemeId] = useState<ITheme['id']>(themeId);
  const [currentStyleSheet, setCurrentStyleSheet] = useState<IStyleSheet>({} as IStyleSheet);
  const [rulesData, setRulesData] = useState('');
  const { data: styleSheets, isLoading, refetch } = useStyleSheets();
  const { isLoading: isEditing, mutateAsync } = useEditStyleSheetMutation();

  const styleSheetFromData = () => {
    return styleSheets.find(ss => ss.theme_id === currentThemeId);
  };

  useEffect(() => {
    if (!currentThemeId || isLoading || !styleSheets) return;

    const styleSheet = styleSheetFromData();
    if (!styleSheet) return;

    setCurrentStyleSheet(styleSheet);
    setRulesData(styleSheet.rules_data);
  }, [currentThemeId, isLoading, setCurrentStyleSheet, setRulesData, styleSheets]);

  const codeMirrorRef = useRef();
  const { setContainer } = useCodeMirror({
    container: codeMirrorRef.current,
    onChange: (data) => setRulesData(data),
    height: '500px',
    extensions,
    value: rulesData,
  });

  const updateStyleSheet = async () => {
    await mutateAsync({ id: currentStyleSheet.id, rulesData });
    refetch();
  };

  const isPristine = () => {
    return styleSheetFromData()?.rules_data === rulesData;
  };

  useEffect(() => {
    if (codeMirrorRef.current) {
      setContainer(codeMirrorRef.current);
    }
  }, [codeMirrorRef.current]);

  return (
    <>
      <div className="tw-flex tw-column tw-justify-between">
        <ThemeDropdown
          currentThemeId={currentThemeId}
          labelClassName="!tw-w-[55px]"
          onChange={nextTheme => setCurrentThemeId(nextTheme)}
          withNoneOption={false}
        />
      </div>

      <div ref={codeMirrorRef} />

      <div className="tw-flex tw-items-end tw-mt-3">
        <SubmitButton
          disabled={isLoading || isEditing || isPristine()}
          onClick={updateStyleSheet}
          submitting={isEditing}
        >
          Save
        </SubmitButton>
      </div>
    </>
  );
};

const StyleSheetEditorWrapper = (props: StyleSheetEditorProps) => (
  <QueryClientProvider client={queryClient}>
    <StyleSheetEditor {...props} />
  </QueryClientProvider>
);

export default StyleSheetEditorWrapper;
