import React from 'react';
import PropTypes from 'prop-types';

import Wizard from 'common/Wizard';
import LtiConfiguration from '../ConfigurationStep/LtiConfiguration';
import ProgressHeader from '../ProgressHeader';
import Location from '../Location';

import { samlSettingPropTypes } from '../../../Proptypes';
import { completedStepsPropTypes } from '../../../steps';

export default function LtiKeySteps({
  closeModal, ssoModel, ssoModelPath, updateSSOModel, rootUrl, completedSteps, initialStep
}) {
  return (
    <Wizard
      progressHeader={<ProgressHeader completedSteps={completedSteps} isSaml={false} />}
      initialStep={initialStep}
      lastPageNextCallback={closeModal}
    >
      <LtiConfiguration
        ssoModel={ssoModel}
        ssoModelPath={ssoModelPath}
        updateSSOModel={updateSSOModel}
        rootUrl={rootUrl}
      />

      <Location
        ssoModel={ssoModel}
        ssoModelPath={ssoModelPath}
        updateSSOModel={updateSSOModel}
      />
    </Wizard>
  );
}

LtiKeySteps.propTypes = {
  closeModal: PropTypes.func.isRequired,
  ssoModel: PropTypes.shape({
    id: PropTypes.number,
    model_type: PropTypes.string,
    model: samlSettingPropTypes
  }).isRequired,
  ssoModelPath: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  updateSSOModel: PropTypes.func.isRequired,
  completedSteps: completedStepsPropTypes.isRequired,
  initialStep: PropTypes.number
};

LtiKeySteps.defaultProps = {
  initialStep: 0
};

