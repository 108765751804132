/**
 * default: Renders a modal component
 * props:
 *           isOpen (bool): whether the modal is open
 *         className (str): class to be appended to the modal container
 *        headerText (str): text to be placed in the header of the modal
 *   closeModal (function): callback function when the modal is closed
 *   children (components): accepts components to be rendered inside of the modal
 *
 * Footer: renders the default modal footer
 * props:
 *   onCancel (function): callback for when the cancel button is clicked
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import I18n from 'i18n-js';
import { useSubmit } from 'common/Forms';
import styles from './Modal.module.scss';
import PrimaryFooterButton from './PrimaryFooterButton';
import Modal from './Modal';

export const SubmitModal = ({
  initialValues,
  submitPath,
  method,
  children,
  closeAfterSubmit,
  closeModal,
  afterSubmit: callback,
  afterError: errorCallback,
  primaryButtonClassName,
  primaryButtonText,
  submittingText,
  additionalData,
  disableUntilChange,
  submitting,
  validateForm,
  useRecursiveAppend,
  ...props
}) => {
  const submitter = useSubmit();

  primaryButtonText = primaryButtonText || (method === 'delete' ? 'Yes, remove' : 'Submit');
  submittingText = submittingText || (method === 'delete' ? 'Deleting...' : 'Submitting...');

  const afterSubmit = (response) => {
    if (closeAfterSubmit) closeModal();
    if (callback) callback(response);
  };

  const afterError = (error) => {
    if (errorCallback) errorCallback(error);
  };

  return (
    <Modal closeModal={closeModal} {...props}>
      <Form
        initialValues={initialValues}
        onSubmit={(values) => { submitter.submit(method, submitPath, { ...values, ...additionalData }, afterSubmit, afterError, useRecursiveAppend); }}
        validate={validateForm}
        render={({ form, handleSubmit, hasValidationErrors, pristine }) => {
          return (
            <form autoComplete="off" onSubmit={handleSubmit}>
              {typeof (children) === 'function' ? children({ form }) : children}
              <Footer
                submitting={submitter.submitting || submitting}
                secondaryButtonCallback={closeModal}
                primaryButtonText={primaryButtonText}
                submittingText={submittingText}
                primaryButtonDisabled={hasValidationErrors || (pristine && disableUntilChange)}
                primaryButtonClassName={primaryButtonClassName}
              />
            </form>
          );
        }}
      />
    </Modal>
  );
};

SubmitModal.propTypes = {
  additionalData: PropTypes.shape(),
  afterError: PropTypes.func,
  afterSubmit: PropTypes.func,
  bodyClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  closeAfterSubmit: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  disableUntilChange: PropTypes.bool,
  initialValues: PropTypes.shape(),
  method: PropTypes.string.isRequired,
  primaryButtonClassName: PropTypes.string,
  primaryButtonText: PropTypes.string,
  submitPath: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  submittingText: PropTypes.string,
  useRecursiveAppend: PropTypes.bool,
  validateForm: PropTypes.func,
};

SubmitModal.defaultProps = {
  additionalData: {},
  afterError: null,
  afterSubmit: null,
  bodyClassName: null,
  closeAfterSubmit: true,
  disableUntilChange: false,
  initialValues: {},
  primaryButtonClassName: undefined,
  primaryButtonText: null,
  submitting: false,
  submittingText: null,
  useRecursiveAppend: false,
  validateForm: null,
};

export function Footer(props) {
  function renderSecondaryButton() {
    if (props.secondaryButtonCallback) {
      return (
        <button
          className={props.secondaryButtonClassName}
          onClick={props.secondaryButtonCallback}
          type="button"
        >
          {props.secondaryButtonText}
        </button>
      );
    }

    return null;
  }

  return (
    <div className={props.wrapperClassName}>
      <hr className={props.nearlyFullScreen ? styles.nearlyFullScreen : ''} />
      <footer className={props.className}>
        {props.children}
        {renderSecondaryButton()}
        {props.displaySubmitButton && (
          <PrimaryFooterButton
            buttonText={props.primaryButtonText}
            disabled={props.primaryButtonDisabled}
            disableWithTooltipText={props.disableWithTooltipText}
            isSubmit={props.primaryButtonSubmit}
            onClick={props.primaryButtonCallback}
            submitting={props.submitting}
            submittingText={props.submittingText}
            wrapperClassName={props.wrapperClassName}
            buttonClassName={props.primaryButtonClassName}
          />
        )}
      </footer>
    </div>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disableWithTooltipText: PropTypes.string,
  displaySubmitButton: PropTypes.bool,
  nearlyFullScreen: PropTypes.bool,
  primaryButtonCallback: PropTypes.func,
  primaryButtonClassName: PropTypes.string,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonSubmit: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  secondaryButtonCallback: PropTypes.func,
  secondaryButtonClassName: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  submitting: PropTypes.bool,
  submittingText: PropTypes.string,
  wrapperClassName: PropTypes.string
};

Footer.defaultProps = {
  children: [],
  disableWithTooltipText: null,
  displaySubmitButton: true,
  nearlyFullScreen: false,
  primaryButtonCallback: () => {},
  primaryButtonClassName: 'btn btn--purple',
  primaryButtonDisabled: false,
  primaryButtonSubmit: true,
  primaryButtonText: `${I18n.t('submit')}`,
  secondaryButtonCallback: null,
  secondaryButtonClassName: 'btn btn--link-purple',
  secondaryButtonText: I18n.t('cancel'),
  submitting: false,
  submittingText: `${I18n.t('submitting')}...`,
  wrapperClassName: ''
};

export function CancelButton({ onCancel }) {
  return (
    <button
      type="button"
      className="btn btn--link-purple"
      onClick={onCancel}
    >
      {I18n.t('cancel')}
    </button>
  );
}

CancelButton.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export function SubmitButton({
  onSubmit, isSubmitting, text, submittingText
}) {
  return (
    <button
      type="button"
      className="btn btn--md btn--purple"
      onClick={onSubmit}
      disabled={isSubmitting}
    >
      {isSubmitting && <i className="fa fa-spinner fa-spin mr5" aria-hidden="true" />}
      {isSubmitting ? submittingText : text}
    </button>
  );
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submittingText: PropTypes.string,
  text: PropTypes.string
};

SubmitButton.defaultProps = {
  isSubmitting: false,
  submittingText: 'Submitting...',
  text: 'Submit'
};

export function useModalManager(initialOpen = false) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { isOpen, open, close };
}

export const modalManagerPropTypes = PropTypes.shape({
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired
});

export const StandardError = () => (
  <div>
    <div className="tw-text-red tw-mb-1">
      Oops, something went wrong.
    </div>
    <div>
      If the problem persists, please contact support at&nbsp;
      <a href="mailto:info@teachtci.com">info@teachtci.com</a>
    </div>
  </div>
);

export { default as ConfirmModal } from './ConfirmModal';
export { default as Spinner } from './Spinner';

export default Modal;
