import React, { Component } from 'react';
import Axios from 'axios';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import PropTypes from 'prop-types';

import { Footer } from '../../../common/Modal';
import { convertCopyright, renderErrors, SubmitError } from '../../../common/Forms/Utils';
import getCodeSelectOptions from '../FormUtils';

import styles from '../Form.module.scss';

export default class AddForm extends Component {
  static propTypes = {
    allDistrictCourseCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    closeModal: PropTypes.func.isRequired,
    getDistrictCourseCodesForProgram: PropTypes.func.isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      full_title_with_edition: PropTypes.string
    })).isRequired,
    subscriberId: PropTypes.number,
    updateTable: PropTypes.func.isRequired,
    updatePath: PropTypes.string.isRequired
  };

  static defaultProps = {
    subscriberId: null
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedCodes: [],
      selectedProgram: null,
      submitting: false
    };
  }

  handleSubmit = async (values) => {
    const requestData = this._processDataForSubmission(values);

    this.setState({ submitting: true });

    return Axios
      .post(this.props.updatePath, requestData)
      .then((response) => {
        this.props.updateTable(response.data);
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
      });
  };

  _getSavedDistrictCourseCodesForProgram(programCode) {
    const mappedCodes = this.props.getDistrictCourseCodesForProgram(programCode);
    return getCodeSelectOptions(this.props.allDistrictCourseCodes).filter(option => mappedCodes.includes(option.value));
  }

  _getProgramOptions() {
    return this.props.programs.map(program => (
      {
        label: convertCopyright(program.full_title_with_edition),
        value: program.code
      }
    ));
  }

  // Determine which courses to add and remove
  _processDataForSubmission(values) {
    const requestData = values;
    const selectedCourses = values.course_numbers.map(c => c.value);
    const savedCourses = this._getSavedDistrictCourseCodesForProgram(values.program_code.value).map(c => c.value);

    requestData.program_code = values.program_code.value;
    requestData.courses_to_add = selectedCourses.filter(c => !savedCourses.includes(c));
    requestData.courses_to_remove = [];

    return requestData;
  }

  render() {
    return (
      <Form
        initialValues={{
          subscriber_id: this.props.subscriberId
        }}
        mutators={{
          handleCodeSelect: (args, state, utils) => {
            this.setState({ selectedCodes: args[0] });
            utils.changeValue(state, 'course_numbers', () => args[0]);
          },
          handleProgramSelect: (args, state, utils) => {
            this.setState({ selectedProgram: args[0] });
            utils.changeValue(state, 'program_code', () => args[0]);
          }
        }}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, pristine, submitError
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="program_code"
              render={() => (
                <div className={styles.formRow}>
                  <label className={styles.label} htmlFor="add-mapping-program">
                    Program
                  </label>

                  <Select
                    className={styles.input}
                    id="add-mapping-program"
                    onChange={form.mutators.handleProgramSelect}
                    options={this._getProgramOptions()}
                    required
                    value={this.state.selectedProgram}
                  />
                </div>
              )}
            />

            <Field
              name="course_numbers"
              render={() => (
                <div className={styles.formRow}>
                  <label
                    className={styles.label}
                    htmlFor="add-mapping-code"
                  >
                    District Course Code
                  </label>

                  <CreatableSelect
                    className={styles.input}
                    id="add-mapping-code"
                    onChange={form.mutators.handleCodeSelect}
                    multi
                    options={getCodeSelectOptions(this.props.allDistrictCourseCodes)}
                    required
                    value={this.state.selectedCodes}
                    placeholder="Add/Select"
                  />
                </div>
              )}
            />

            <SubmitError error={submitError} />

            <Footer
              secondaryButtonCallback={this.props.closeModal}
              primaryButtonDisabled={pristine}
              submitting={this.state.submitting}
            />
          </form>
        )}
      />
    );
  }
}
