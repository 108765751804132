import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import styles from './Slide.module.scss';
import ContentContainer from './ContentContainer';
import { imageModelPropTypes, slideObjectPropTypes, slidePropTypes, slideTemplateObjectPropTypes } from '../Utils';
import { getNotes, useBackgroundImage, useResizer, fullSizeSlideTemplateObjectIds } from './Utils';
import DragDropGame from '../../../../modules/interactions/DragDropGame';
import slideShowGame from '../../../../modules/slide_show/slideShowGame';
import InteractiveHotSpot from '../../../../modules/interactions/InteractiveHotSpot';
import Drawer from '../../../common/Drawer';
import './videoJs.scss';
import useVideoControlBarRepositioner from './hooks/useVideoControlBarRepositioner';
import useImageZoom from './hooks/useImageZoom';
import useSlideShowContext from '../stores/useSlideShowContext';
import useFlags from '../../../../hooks/useFlags';
import SlideHeader from './SlideHeader';

const Slide = (props) => {
  const userType = useSlideShowContext(state => state.userType);
  const setSlideRef = useSlideShowContext(state => state.setSlideRef);
  const locale = useSlideShowContext(state => state.locale);
  const slideRef = useRef();
  const { displaySlideGroupHeaders } = useFlags();
  const shouldDisplayHeader = props?.slide?.displaySlideHeader && displaySlideGroupHeaders;

  useBackgroundImage(slideRef, props.slide, props.imageModel);
  useResizer(props.forDisplay, slideRef, props.isTemplate);
  useVideoControlBarRepositioner(props.slideId);
  useImageZoom({ ref: slideRef, skip: props.forDisplay !== 'present' });

  useEffect(() => {
    if (props.isEditorActive) setSlideRef(slideRef);
  }, [slideRef]);

  const getObjectStyles = (templateObject) => {
    const headerOffset = 10;
    const initialY = templateObject.position.y;
    const initialHeight = templateObject.height;

    // Full size template objects should take up the entire slide.
    const isFullSize = fullSizeSlideTemplateObjectIds.includes(parseInt(templateObject.id, 10));

    // Vertical position of the slide template object on the slide.
    let top = initialY;

    const halfSlidePosition = 49;
    const belowMiddleOfSlide = initialY >= halfSlidePosition;

    // Move template objects down to make room for the header
    if (shouldDisplayHeader && !isFullSize) {
      // Template objects below the middle of the slide only need to move down half the header height.
      top += (belowMiddleOfSlide) ? headerOffset / 2 : headerOffset;
    }

    // 90 is the original max height for all template objects besides "Full Size" templates.
    // The header offset takes 10px away from usable space and makes the new max height 80.
    // We convert the height here to adjust all template objects evenly.
    const height = (shouldDisplayHeader && !isFullSize) ? (initialHeight * 80) / 90 : initialHeight;

    return {
      backgroundColor: templateObject.styles?.backgroundColor,
      borderRadius: templateObject.styles?.borderRadius,
      height: `${height}%`,
      left: `${templateObject.position.x}%`,
      top: `${top}%`,
      width: `${templateObject.width}%`,
    };
  };

  const initDragDrops = () => {
    const gameType = $(slideRef.current).find('.remember_origin_js').length > 0 ? 2 : 1;
    DragDropGame.create(props.slideId, gameType).init(); // initializes the draggables and dropzones

    slideShowGame.slideShowGame(); // attaches listeners to the draggables and dropzones
    slideShowGame.slideExpandables();
  };

  const initCustomJSInteractions = () => {
    if (props.slideId && props.forDisplay !== 'thumbnail') {
      initDragDrops();
      InteractiveHotSpot.init(slideRef.current.getAttribute('class').split(/\s+/)[0]); // initializes hotspots
    }
  };

  useEffect(() => {
    if (props.forDisplay !== 'thumbnail') initCustomJSInteractions();
  }, [
    props.slideId,
    slideRef && slideRef.current && slideRef.current.querySelectorAll('.dd_answer_js'),
    slideRef && slideRef.current && slideRef.current.querySelectorAll('.hs_trigger'),
  ]);

  const getAssociatedObject = (index) => {
    if (props.isTemplate || props.templateObjects.length === 0) return null;

    return props.slideObjects.find(obj => (obj.index === index));
  };

  // maps slide_objects to their corresponding slide_template_object
  const renderSlideObjects = () => {
    if (!props.templateObjects || props.templateObjects.length === 0) return null;

    return props.templateObjects.map((templateObject) => {
      const slideObject = getAssociatedObject(templateObject.index);

      return (
        <ContentContainer
          index={templateObject.index}
          isSaving={props.isSaving}
          isTemplate={props.isTemplate}
          isVideoActivity={props.isVideoActivity}
          key={templateObject.id}
          interactable={props.forDisplay === 'builder'}
          setIsSaving={props.setIsSaving}
          slideId={props.slideId}
          slideObject={slideObject}
          slideObjects={props.slideObjects}
          slideTemplateStyles={getObjectStyles(templateObject)}
          thumbnail={props.forDisplay === 'thumbnail'}
          userType={userType}
          jumpToSlide={props.jumpToSlide}
        />
      );
    });
  };

  const getSlideStyle = () => {
    const style = ['slide', styles.slide, props.className];

    if (props.forDisplay === 'present') {
      style.push(styles.present);
      style.push('present');
    }
    else if (props.forDisplay === 'thumbnail') {
      style.push(styles.thumbnail);
    }
    else if (props.isTemplate) {
      style.push(styles.slideTemplateOption);
    }

    if (props.isModal) style.push(styles.modalSlide);
    if (props.isSelected) style.push(styles.selected);

    return style.join(' ');
  };

  const displayNotesDrawer = props.forDisplay === 'present' &&
    userType &&
    userType !== 'Student' &&
    props.slide.notes;

  // The presence of an `imageModel` means there is a background image (see `useBackgroundImage`).
  // For accessibility, we can't add an `alt` attribute to background images
  let bgImageAccessibility = {};
  let label = '';
  if (props.imageModel) {
    const { image: { descriptionEn, descriptionEs } } = props.imageModel;
    label = (locale === 'es' ? descriptionEs || descriptionEn : descriptionEn) || '';

    bgImageAccessibility = {
      'aria-label': label,
      role: 'img',
    };
  }

  return (
    <div
      className={getSlideStyle()}
      ref={slideRef}
      {...(label.length ? bgImageAccessibility : {})}
      id={props.forDisplay !== 'thumbnail' ? props.slideId : null}
    >
      {displayNotesDrawer && (
        <Drawer
          buttonClass={styles.teacherNotesIcon}
          buttonText={<span className="fa fa-sticky-note-o" />}
          contentClass={styles.teacherNotesContent}
          drawerClass={clsx(styles.teacherNotesDrawer, 'fr-view')}
          headerClass={styles.teacherNotesHeader}
          position="right"
          title={I18n.t('notes')}
        >
          <div
            className={styles.teacherNotesText}
            dangerouslySetInnerHTML={{ __html: getNotes(I18n.locale, props.slide) }}
          />
        </Drawer>
      )}

      {shouldDisplayHeader && <SlideHeader locale={locale} slide={props?.slide} slideGroups={props?.slideGroups} />}
      {renderSlideObjects()}
    </div>
  );
};

Slide.propTypes = {
  className: PropTypes.string,
  forDisplay: PropTypes.string,
  imageModel: imageModelPropTypes,
  isEditorActive: PropTypes.bool,
  isModal: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSelected: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isVideoActivity: PropTypes.bool,
  jumpToSlide: PropTypes.func,
  setIsSaving: PropTypes.func,
  slide: slidePropTypes,
  slideGroups: PropTypes.arrayOf(PropTypes.object),
  slideId: PropTypes.string,
  slideObjects: PropTypes.arrayOf(slideObjectPropTypes),
  templateObjects: PropTypes.arrayOf(slideTemplateObjectPropTypes),
};

Slide.defaultProps = {
  className: '',
  forDisplay: 'builder',
  imageModel: null,
  isEditorActive: false,
  isModal: false,
  isSaving: false,
  isSelected: false,
  isTemplate: false,
  isVideoActivity: false,
  jumpToSlide: () => {},
  setIsSaving: () => {},
  slide: null,
  slideId: '',
  slideObjects: [],
  templateObjects: [],
};

export default Slide;
