import React, { useState } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import Tooltip from 'components/common/Tooltip';
import { SpinnerIcon } from 'components/common/Utils';
import ReactDOM from 'react-dom';
import styles from './QuestionCopyButton.module.scss';
import useQuestionCopyStore from './useQuestionCopyStore';

interface QuestionCopyButtonProps {
  programId: number;
  questionId: number;
}

type CopyQuestionResponse = {
  data: any
};

const LoadingSpinner = () => ReactDOM.createPortal(
  <li className="question_group">
    <ul className="question_group">
      <li>
        <div className={`question-header allow-list-style ${styles.loadingSpinner}`}>
          <SpinnerIcon />
        </div>
      </li>
    </ul>
  </li>,
  document.querySelector('#notebook-questions-container'),
);

const QuestionCopyButton = ({ programId, questionId }: QuestionCopyButtonProps) => {
  const setLoading = useQuestionCopyStore(state => state.setLoading);
  const loading = useQuestionCopyStore(state => state.loading);

  // Using a separate state for the spinner because the loading variable is global and creates a spinner per question.
  const [displaySpinner, setDisplaySpinner] = useState(false);

  const setCopyStatus = (status) => {
    setLoading(status);
    setDisplaySpinner(status);
  };

  const copyQuestion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const url = Routes.copy_staffer_question_path(questionId);

    setCopyStatus(true);
    Axios.post<CopyQuestionResponse, any>(url, { program_id: programId })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      }).then(({ data }) => {
        window.question_builder.addQuestion(data);
      }).finally(() => setCopyStatus(false));
  };

  return (
    <>
      {displaySpinner && <LoadingSpinner />}
      <Tooltip
        content="Make a Copy"
        theme="white"
        size="medium"
        interactive={false}
      >
        <button
          type="button"
          className="btn btn--link-purple add-question"
          onClick={copyQuestion}
          style={{ padding: 0 }}
          disabled={loading}
        >
          {loading ? <SpinnerIcon /> : (
            <i
              className="fa fa-clone fa-1x fa-fw js-prevent-toggle"
              aria-hidden="true"
            />
          )}
        </button>
      </Tooltip>
    </>
  );
};

export default QuestionCopyButton;
