import React, { useEffect, useMemo, useRef } from 'react';
import VideoPlayer, { DEFAULT_VIDEO_OPTIONS } from '@/components/common/VideoPlayer';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { autoplayMediaSetting } from '@/components/admin/SlideShow/PresentMode/Utils';
import { closeOnEscapeKey } from '@/components/common/Utils';
import { VideoType } from '@/components/interfaces/graphql/HtmlElement/HtmlElementUtils';
import styles from './VideoButton.module.scss';
import { renderYoutubeIframe } from '../../../admin/VideoManager/Utils';
import { repositionControlBar } from '../../../admin/SlideShow/Slide/hooks/useVideoControlBarRepositioner';

interface PopupProps {
  autoplay?: boolean;
  modalManager: ModalManager;
  video: VideoType;
  youtubeVideoUrl: string;
}

const Popup = ({ autoplay, modalManager, video, youtubeVideoUrl }: PopupProps) => {
  const ref = useRef<HTMLDivElement>();
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const slideShowId = useSlideShowContext(state => state.slideShowId);

  const videoPlayer = useMemo(() => (
    <VideoPlayer
      video={video}
      width="100%"
      height="100%"
      onPlay={repositionControlBar}
      options={{ ...DEFAULT_VIDEO_OPTIONS, fluid: false }}
      shouldAutoPlay={autoplay && autoplayMediaSetting(slideShowId)}
    />
  ), [video.id]);

  const renderVideo = () => {
    if (youtubeVideoUrl) return renderYoutubeIframe(youtubeVideoUrl);
    if (video) return videoPlayer;

    return null;
  };

  const handleClose = () => {
    if (ref.current) {
      const videoEl = ref.current.querySelector('.video-js');

      if (videoEl) {
        const { id } = videoEl;

        const player = window.videojs(id);

        if (!player.paused()) player.pause();
      }
    }

    modalManager.close();
  };

  useEffect(() => {
    handleClose();
  }, [currentSlide]);

  closeOnEscapeKey(handleClose);

  return (
    <span ref={ref} className={`${styles.popup} ${modalManager.isOpen ? '' : styles.closed}`}>
      {modalManager.isOpen && renderVideo()}

      <i
        onClick={handleClose}
        onKeyDown={handleClose}
        className={`fa fa-times-circle ${styles.closeButton}`}
        role="button"
        tabIndex={0}
      />
    </span>
  );
};

export default Popup;
