import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import NotebookSelect from 'common/ModelSelect/NotebookSelect';
import Select from 'react-select';
import { slideObjectPropTypes } from '../../../../Utils';
import styles from '../index.module.scss';

const SectionNotebookSelectsWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <SectionNotebookSelects {...props} />
  </ApolloProvider>
);

const GET_INITIAL_DROPDOWN_OPTIONS = gql`
  query getInitialDropdownOptions($programId: ID!, $lessonId: ID, $unitId: ID, $isLesson: Boolean!) {
    lesson(id: $lessonId) @include(if: $isLesson) {
      sections {
        id,
        title(programId: $programId),
        notebooks {
          id,
          title,
        }
      }
    },
    unit(id: $unitId) @skip(if: $isLesson) {
      sections {
        id,
        title(programId: $programId),
        notebooks {
          id,
          title,
        }
      }
    }
  }
`;

const SectionNotebookSelects = (props) => {
  const [sectionOption, setSectionOption] = useState({ id: 0, title: '' });
  const [notebookOption, setNotebookOption] = useState({ id: 0, title: '' });
  const queryData = {
    fetchPolicy: 'network-only',
    variables: {
      isLesson: !!props.lessonId,
      lessonId: props.lessonId,
      programId: props.programId,
      unitId: props.unitId,
    }
  };
  const { loading, data, error } = useQuery(GET_INITIAL_DROPDOWN_OPTIONS, queryData);

  useEffect(() => {
    if (error) {
      props.setHasError(true);
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }

    if (loading) return;

    const dataPrefix = props.lessonId ? data.lesson : data.unit;

    const initialSection = dataPrefix.sections.filter(section => section.id === props.sectionId.toString());

    // If initialSection is defined, set it as initial option in sections select dropdown.
    if (Array.isArray(initialSection) && initialSection.length) {
      setSectionOption({
        label: initialSection[0].title,
        value: props.sectionId,
      });
    }
    else if (dataPrefix.sections.length === 0) {
      return;
    }
    else {
      setSectionOption({
        label: dataPrefix.sections[0].title,
        value: parseInt(dataPrefix.sections[0].id, 10),
      });
    }
  }, [data]);

  useEffect(() => {
    if (!sectionOption?.value) return;

    props.setSectionId(sectionOption.value)
  }, [sectionOption]);

  const notebookOptionsLoaded = (options, formatOptionsCallback) => {
    formatOptionsCallback(options);
    let initialNotebook;

    if (props.notebookId) initialNotebook = options.filter(option => option.value === props.notebookId).at(0);

    if (!initialNotebook) initialNotebook = options[0];

    setNotebookOption(initialNotebook);
    if (initialNotebook?.value) props.setNotebookId(initialNotebook.value);
  };

  const handleSectionChange = (e) => {
    setSectionOption(e.value);

    props.setSectionId(parseInt(e.value, 10));
  };

  const getOptions = () => {
    const dataPrefix = props.lessonId ? data.lesson : data.unit;

    return (
      dataPrefix.sections.map(section => ({
        label: section.title,
        value: section.id,
      }))
    );
  };

  const sectionSelect = () => {
    if (loading) return null;

    return (
      <div className={`${styles.modelSelect}`}>
        <label htmlFor="notebook-select">
          <p>Sections:</p>
        </label>
        <Select
          className={`${styles.select} model-select`}
          id="section-select"
          label="section-select"
          onChange={handleSectionChange}
          options={getOptions()}
          value={sectionOption}
        />
      </div>
    );
  };

  const notebookSelect = () => {
    if (loading) return null;

    return (
      <NotebookSelect
        className={styles.select}
        sectionId={props.sectionId}
        lessonId={props.lessonId}
        programId={props.programId}
        handleSelect={option => props.setNotebookId(option.value)}
        doneLoadingCallback={notebookOptionsLoaded}
        initialOption={notebookOption}
        value={notebookOption}
        noResultsText="No notebooks for this section"
      />
    );
  };

  return (
    <>
      {sectionSelect()}
      {notebookSelect()}
    </>
  );
};

SectionNotebookSelects.propTypes = {
  lessonId: PropTypes.number,
  notebookId: PropTypes.number,
  programId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  setHasError: PropTypes.func.isRequired,
  setNotebookId: PropTypes.func.isRequired,
  setSectionId: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  unitId: PropTypes.number,
};

export default SectionNotebookSelectsWrapper;
