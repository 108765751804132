import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useModalManager } from 'common/Modal';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import ReconnectModal from './ReconnectModal';
import DisconnectModal from './DisconnectModal';

const InvalidTokenModalWrapper = ({ integrationType }) => (
  <ApolloProvider client={apolloClient}>
    <InvalidTokenModal integrationType={integrationType} />
  </ApolloProvider>
);

const InvalidTokenModal = ({ integrationType }) => {
  const { close, isOpen } = useModalManager(true);
  const [currentPane, setCurrentPane] = useState('info');

  if (!isOpen) return null;

  if (currentPane === 'info') {
    return (
      <ReconnectModal
        closeModal={close}
        setCurrentPane={setCurrentPane}
        integrationType={integrationType}
      />
    );
  }

  return (
    <DisconnectModal
      closeModal={close}
      setCurrentPane={setCurrentPane}
      integrationType={integrationType}
    />
  );
};

InvalidTokenModalWrapper.propTypes = {
  integrationType: PropTypes.string.isRequired
};

InvalidTokenModal.propTypes = {
  integrationType: PropTypes.string.isRequired
};


export default InvalidTokenModalWrapper;
