import PropTypes from 'prop-types';

export const samlSettingPropTypes = PropTypes.shape({
  id: PropTypes.number,
  idp_entity_id: PropTypes.string,
  idp_sso_target_url: PropTypes.string,
  idp_cert: PropTypes.string,
  name_identifier_format: PropTypes.string
});

export const ltiKeyPropTypes = PropTypes.shape({
  id: PropTypes.number,
  consumer_key: PropTypes.string,
  secret: PropTypes.string
});

export const ssoModelPropType = PropTypes.shape({
  id: PropTypes.number,
  model_type: PropTypes.string,
  model: PropTypes.oneOfType([samlSettingPropTypes, ltiKeyPropTypes]),
  sign_in_preferences: PropTypes.objectOf(PropTypes.string)
});
