import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { IQuestionAnswer } from '../../../types';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';

const useVideoActivityQuestionAnswers = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const videoActivity = useGradesTableStore(state => state.videoActivity);

  const isEnabled = !!(classroom && lesson && Object.keys(videoActivity).length > 0);

  const query = useQuery([QueryKeys.VideoActivityQuestionAnswers, lesson?.id, classroom.id], () => {
    const path = Routes.plato_api_video_activity_progress_question_answers_path({
      classroom_id: classroom.id,
      lesson_id: lesson?.id,
      only: ['id', 'student_id'],
    });

    return axios
      .get<{ data: IQuestionAnswer[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err));
  }, { enabled: isEnabled });

  return { ...query, data: query.data || [] };
};

export default useVideoActivityQuestionAnswers;
