import { useState } from 'react';
import { IPlaceholderProps } from '@/components/common/VocabularyCards/interfaces';
import { DropResult, OnDragEndResponder, OnDragUpdateResponder } from 'react-beautiful-dnd';

const getDraggedDom = (draggableId) => {
  const domQuery = `[data-rbd-draggable-id='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM;
};

interface UseDragAndDropProps {
  onDragEnd: (result: DropResult) => any;
}

const useDragAndDrop = ({ onDragEnd: onDragEndCallback }: UseDragAndDropProps) => {
  const [placeholderProps, setPlaceholderProps] = useState<IPlaceholderProps>({} as IPlaceholderProps);

  const onDragUpdate: OnDragUpdateResponder = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    // @ts-ignore
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop)
      + updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr['currentStyle'] || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft,
      ),
    });
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    setPlaceholderProps({} as IPlaceholderProps);

    onDragEndCallback(result);
  };

  return { onDragUpdate, onDragEnd, placeholderProps };
};

export default useDragAndDrop;
