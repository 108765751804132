import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { Footer } from 'common/Modal';
import { Form } from 'react-final-form';
import Axios from 'axios';
import moment from 'moment';
import styles from './NarrationClips.module.scss';

export default class NarrationClips extends Component {
  static propTypes = {
    narrationClip: PropTypes.shape({
      filename: PropTypes.string,
      uploadDate: PropTypes.string,
      url: PropTypes.string
    }),
    narrationOutOfDate: PropTypes.bool,
    readingLevel: PropTypes.string,
    updatePath: PropTypes.string.isRequired
  };

  static defaultProps = {
    narrationClip: null,
    narrationOutOfDate: false,
    readingLevel: 'Level A'
  };

  static renderEmptyRow() {
    return (
      <tr id="empty">
        <td className="center" colSpan="5">No narration clips</td>
      </tr>
    );
  }

  static renderUploadDate(clip) {
    const date = new Date(clip.uploadDate);

    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  static processNarrationClip(data) {
    if (data.narration_clip_file_name) {
      return {
        filename: data.narration_clip_file_name,
        uploadDate: data.narration_clip_updated_at,
        url: data.narration_clip_url
      };
    }

    return null;
  }

  static renderErrorMessage() {
    return (
      <div className={styles.error}>Error uploading. Please upload your file again.</div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      isModalOpen: false,
      narrationClip: this.props.narrationClip,
      narrationOutOfDate: this.props.narrationOutOfDate,
      submitting: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleOutOfDateChange = this.handleOutOfDateChange.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  buildFormData() {
    if (this.state.file) {
      const formData = new FormData();
      formData.append('section[narration_clip]', this.state.file);
      return formData;
    }

    return { section: { narration_clip: null } };
  }

  updateNarrationClip(response) {
    this.setState({
      narrationClip: NarrationClips.processNarrationClip(response.data.data),
      narrationOutOfDate: response.data.data.narration_out_of_date
    });
  }

  deleteFile() {
    if (confirm('Are you sure you want to delete the narration file?')) {
      this.setState({ deleting: true, file: null }, this.handleSubmit);
    }
  }

  handleOutOfDateChange({ target }) {
    this.setState({ narrationOutOfDate: target.checked });
    const data = { section: { narration_out_of_date: target.checked } };
    this.handleSubmit({ data });
  }

  handleSubmit({ data = null } = {}) {
    this.setState({ submitting: true, errors: false });

    return Axios
      .patch(this.props.updatePath, data || this.buildFormData())
      .then((response) => {
        this.updateNarrationClip(response);
        this.closeModal();
        this.setState({ deleting: false, submitting: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false, errors: true });
      });
  }

  renderUploadForm() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            { this.renderFileUploadField() }

            { this.state.errors && NarrationClips.renderErrorMessage()}

            <Footer
              wrapperClassName="pt30"
              secondaryButtonCallback={this.closeModal}
              submitting={this.state.submitting}
              primaryButtonText="Add"
              primaryButtonDisabled={!this.state.file}
            />
          </form>
        )}
      />
    );
  }

  renderTableBody() {
    if (this.state.narrationClip) {
      return (
        <tr>
          <td>{this.state.narrationClip.filename}</td>
          <td>{NarrationClips.renderUploadDate(this.state.narrationClip)}</td>
          <td>{this.props.readingLevel}</td>
          <td>
            <label htmlFor="narration-out-of-date" className="sr-only">Narration out of date</label>
            <input
              id="narration-out-of-date"
              type="checkbox"
              name="narration_out_of_date"
              checked={this.state.narrationOutOfDate}
              onChange={this.handleOutOfDateChange}
            />
          </td>
          {this.renderActions()}
        </tr>
      );
    }

    return NarrationClips.renderEmptyRow();
  }

  renderActions() {
    return (
      <td className={styles.actions}>
        <a
          href={this.state.narrationClip.url}
          target="_blank"
          rel="noopener noreferrer"
          className="action"
        >
          Preview
        </a>

        <button type="button" className="action" onClick={this.openModal}>Edit</button>

        <button type="button" className="action" onClick={this.deleteFile}>
          {this.state.deleting && <i className="fa fa-spinner fa-spin mr5" />}
          {this.state.deleting ? 'Deleting...' : 'Delete'}
        </button>
      </td>
    );
  }

  renderTable() {
    return (
      <div className="tablebox">
        <table className={`tabular_data ${styles.table}`}>
          <thead>
            <tr>
              <td>Filename</td>
              <td>Upload Date</td>
              <td>Reading Level</td>
              <td>Narration Out of Date?</td>
              <td>Actions</td>
            </tr>
          </thead>

          <tbody>
            {this.renderTableBody()}
          </tbody>
        </table>
      </div>
    );
  }

  renderFileUploadField() {
    return (
      <input
        type="file"
        accept=".wav"
        name="narration_clip"
        id="narration_clip"
        onChange={e => this.setState({ file: e.target.files[0] })}
      />
    );
  }

  renderAddNarrationButton() {
    if (this.state.narrationClip) return null;

    return (
      <button
        type="button"
        className={`btn btn--green btn--sm ${styles.addButton}`}
        onClick={this.openModal}
      >
        Add Narration Clip
      </button>
    );
  }

  render() {
    return (
      <div>
        {this.renderAddNarrationButton()}

        <Modal
          headerText="Add Narration"
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
        >
          {this.renderUploadForm()}
        </Modal>

        {this.renderTable()}
      </div>
    );
  }
}
