import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import ImageUploader from 'common/ImageUploader';

import { capitalizeWords, truncate } from '../../../modules/TCIUtils';
import Modal, { ConfirmModal } from '../../common/Modal';
import Tooltip from '../../common/Tooltip';

export function ModalButton(props) {
  const FormComponent = props.FormComponent;
  return (
    <span>
      <button
        type={props.buttonType}
        className={`${props.buttonClass}`}
        onClick={props.openModal}
      >
        {props.buttonHtml || props.buttonText}
      </button>
      {props.children}
      <Modal
        className={props.modalClass}
        closeModal={props.closeModal}
        isOpen={props.modalIsOpen}
        headerText={props.modalHeader || "Edit Material"}
      >
        <FormComponent
          updateMaterial={props.onUpdate}
          closeModal={props.closeModal}
          {...props}
        />
      </Modal>
    </span>
  );
}

ModalButton.defaultProps = {
  openModal: null,
  closeModal: null,
  newRecord: false,
  modalIsOpen: false,
  modalHeader: '',
  buttonClass: null,
  buttonType: 'button'
};

export function DeleteButton(props) {
  const deleteButton = (
    <span>
      <button
        type={props.buttonType}
        className={`unstyled btn btn--outline-red btn--sm ${!props.canDelete && 'disabled'}`}
        onClick={props.openConfirmationModal}
      >
        Delete
      </button>
    </span>
  );

  const material_delete_message = (
    <div className="p5">Cannot delete this unit because it is associated to a material.</div>
  );

  const deleteButtonWithIcon = (
    <button
      type={props.buttonType}
      className={`unstyled btn btn--outline-red btn--sm ${!props.canDelete && 'disabled'}`}
      onClick={props.openConfirmationModal}
    >
      <i className="fa fa-trash" aria-hidden="true" /> Delete
    </button>
  );

  return (
    <span>
      {!props.canDelete ?
        <Tooltip
          interactive={false}
          content={renderToString(material_delete_message)}
        >
          {deleteButton}
        </Tooltip>
        :
        (props.withIcon ? deleteButtonWithIcon : deleteButton)
      }
      <ConfirmModal
        isLoading={props.isDeleting}
        isOpen={props.confirmationModalIsOpen}
        onCancel={props.closeConfirmationModal}
        onConfirm={props.handleDelete}
        headerText="Confirm Delete"
        confirmationText="Are you sure you would like to delete this item?"
      />
    </span>
  );
}

DeleteButton.propTypes = {
  canDelete: PropTypes.bool,
  closeConfirmationModal: PropTypes.func,
  confirmationModalIsOpen: PropTypes.bool,
  handleDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  openConfirmationModal: PropTypes.func,
  withIcon: PropTypes.bool,
  buttonType: PropTypes.string
};

DeleteButton.defaultProps = {
  canDelete: true,
  closeConfirmationModal: null,
  confirmationModalIsOpen: false,
  handleDelete: null,
  isDeleting: false,
  openConfirmationModal: null,
  withIcon: false,
  buttonType: 'button'
};


export function DeleteUnit(props) {
  const trashIcon = (
    <i
      className={`fa fa-trash-o fa-1x ${!props.canDelete && 'disabled'}`}
      onClick={props.openConfirmationModal}
    />
  );

  const unit_delete_message = (
    <div className="p5">Cannot delete this unit because it is associated to a material.</div>
  );

  return (
    <div>
      {!props.canDelete ?
        <Tooltip
          interactive={false}
          content={renderToString(unit_delete_message)}
        >
          {trashIcon}
        </Tooltip>
        :
        trashIcon
      }
      <ConfirmModal
        isLoading={props.isDeleting}
        isOpen={props.confirmationModalIsOpen}
        onCancel={props.closeConfirmationModal}
        onConfirm={props.handleDelete}
        headerText="Confirm Delete"
        confirmationText="Are you sure you would like to delete this item?"
      />
    </div>
  );
}

DeleteUnit.propTypes = {
  canDelete: PropTypes.bool,
  closeConfirmationModal: PropTypes.func,
  confirmationModalIsOpen: PropTypes.bool,
  handleDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  openConfirmationModal: PropTypes.func
};

DeleteUnit.defaultProps = {
  canDelete: true,
  confirmationModalIsOpen: false,
  openConfirmationModal: null,
  closeConfirmationModal: null,
  handleDelete: null,
  isDeleting: false
};


export function inputId(config) {
  return `${config.label}-${config.newRecord ? 'new' : config.id}`
}

export function FormRow(props) {
  return (
    <div className="form-row">
      {props.children}
    </div>
  )
}

export function FormInput(props) {
  const { id, newRecord, label,
          stateName, inputType, required,
          onChange, accept, step, afterLabel,
          readOnly, checked, options } = props,
          InputType = props.InputType || 'input';

  let value = props.value;
  if (typeof props.value === 'number') value = props.value;
  else if (inputType === 'file') value = undefined;
  else value = props.value || '';

  return (
    <FormRow>
      <label
        className="mt5"
        htmlFor={inputId({ label, newRecord, id })}
      >
        {capitalizeWords(label)}
        {afterLabel && ` (${afterLabel})`}
      </label>
      <InputType
        required={required}
        type={inputType}
        id={inputId({ label, newRecord, id })}
        value={value}
        step={step}
        accept={accept}
        onChange={(e) => onChange(e, (stateName || label))}
        readOnly={readOnly}
        checked={checked}
        options={options}
      />
    </FormRow>

  );
}

FormInput.defaultProps = {
  newRecord: false,
  required: false,
  accept: null,
  onChange: null,
  inputType: 'text',
  afterLabel: null,
  options: null
};

export function FormCheckbox(props) {
  return (
    <FormRow>
      <label>
        <input
          type="checkbox"
          checked={props.stateName || props.checked}
          onChange={(e) => props.onChange(e, (props.stateName || props.label))}
        />
        {props.label}
      </label>
    </FormRow>
  );
}

export function FormImageUploader(props) {
  return (
    <FormRow>
      <ImageUploader
        allowRemove={props.allowRemove}
        onFileAdded={props.onFileAdded}
        onFileRemoved={props.onFileRemoved}
        imageUrl={props.imageUrl}
        labelClasses="file"
        containerClasses="image-input-container"
        controlsClasses="image-controls"
        inputId={inputId({ label: 'file', newRecord: props.newRecord, id: props.id })}
      />
    </FormRow>
  );
}

FormImageUploader.propTypes = {
  allowRemove: PropTypes.bool,
  id: PropTypes.number,
  imageUrl: PropTypes.string,
  newRecord: PropTypes.bool.isRequired,
  onFileAdded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func
};

FormImageUploader.defaultProps = {
  allowRemove: true,
  id: null,
  imageUrl: null,
  onFileRemoved: null
};

export function FileInput(props) {
  const { label, newRecord, id, className,
        file, fileUrl, fileName, removeFile,
        handleClear, handleChange, required } = props,
        _id = inputId({ label, newRecord, id }),
        hideInput = !file && !removeFile && fileName,
        hasFile = (file || fileName) && !removeFile;

  return (
    <FormRow>
      <label className="mt10" htmlFor={id}>{label}</label>
      <div className={`${className} h35`}>
        {hideInput ?
          <a className="ml15" href={fileUrl} target="_blank">
            {truncate(fileName, 50)} <i className="fa fa-external-link" />
          </a>
        :
          <input type="file" id={_id} accept="application/pdf"
                 onChange={handleChange} required={required} />
        }
        <a className={`block_link ml10 ${!hasFile && 'cancel'}`} href="#" onClick={handleClear}>
          Clear File
        </a>
      </div>
    </FormRow>
  );
}

export function SafetyNoteThumbnail(props) {
  const content = (
    <div className="w200 p5">
      <div className="bold pb10">{props.title}</div>
      <div>{props.note}</div>
    </div>
  );

  const triggerEl = (
    <div className="safety-note-img">
      <img src={props.imageUrl} alt="" />
    </div>
  );

  return (
    <Tooltip distance="20" theme="white" content={renderToString(content)}>
      {triggerEl}
    </Tooltip>
  );
}

SafetyNoteThumbnail.propTypes = {
  imageUrl: PropTypes.string,
  note: PropTypes.string,
  title: PropTypes.string
};

SafetyNoteThumbnail.defaultProps = {
  imageUrl: '/missing.png',
  note: '',
  title: ''
};

export function sortedMaterials(data) {
  const grouped = data.reduce((acc, material) => {
    const title = material.programScopedSource?.title || material.source?.title;
    (acc[title] ||= []).push(material);

    return acc;
  }, {});

  return ['Kit', 'Teacher Supplied', 'Print'].reduce((acc, source) => {
    return [...acc, ...(grouped[source] || [])];
  }, []);
};
