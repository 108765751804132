import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Routes from 'modules/routes';
import styles from '@/components/staffer/AssignmentModal/WhatSection/WhatSection.module.scss';
import sectionStyles from '@/components/staffer/AssignmentModal/Section.module.scss';
import I18n from 'i18n-js';
import { Field } from 'react-final-form';
import { SelectField } from '@/components/common/Forms';
import CheckboxGroup from '@/components/common/Forms/CheckboxGroup';
import ArchivedMessage from '@/components/staffer/AssignmentModal/WhatSection/ArchivedMessage';
import useAssignablesStore from '../store/useAssignablesStore';

interface SlideShowProps {
  archived: boolean,
  score: number,
  slideGroups: [{
    label: string,
    value: string,
    score: number,
    extension: boolean
  }]
}

const SlideShowSelector = ({ form }) => {
  const formChangeValue = useAssignablesStore(state => state.formChangeValue);
  const selectedUnitLesson = useAssignablesStore(state => state.selectedUnitLesson);
  const selectedType = useAssignablesStore(state => state.selectedType);
  const programId = useAssignablesStore(state => state.programId);
  const initialValues = useAssignablesStore(state => state.initialValues);
  const loadingLessons = useAssignablesStore(state => state.loadingLessons);
  const loadingTypes = useAssignablesStore(state => state.loadingTypes);
  const setStore = useAssignablesStore(state => state.setStore);
  const [slideShows, setSlideShows] = useState([]);
  const [selectedSlideShow, setSelectedSlideShow] = useState<SlideShowProps>();

  useEffect(() => {
    Axios.get(Routes.plato_api_get_slide_show_assignables_assignables_path({
      lesson_id: selectedUnitLesson.value,
      program_id: programId,
      type: selectedType,
    })).then((res) => {
      setSlideShows(res.data.assignables);
    });
  }, [selectedType]);

  const handleSlideShowSelect = (selectedSlideShowValue) => {
    let newSelectedSlideShow = slideShows.find(ss => ss.value === selectedSlideShowValue);

    if (!newSelectedSlideShow) {
      if (slideShows.length === 1) {
        newSelectedSlideShow = slideShows[0];
      } else {
        return;
      }
    }

    setSelectedSlideShow(newSelectedSlideShow);

    if (newSelectedSlideShow?.slideGroups?.length) {
      const defaultModels = newSelectedSlideShow.slideGroups.filter(sg => !sg.extension).map(sg => sg.value);
      const assignedSlideShow = initialValues.slideShowSelect;
      const assignedModels = assignedSlideShow === newSelectedSlideShow.value && initialValues.modelsToAssign;

      // When editing, if there are assigned slide_groups, show those instead of the default extension ones
      const initialModels = assignedModels || defaultModels;

      formChangeValue(initialModels, 'modelsToAssign');
      formChangeValue(selectedSlideShowValue, 'slideShowSelect');
    } else {
      formChangeValue(newSelectedSlideShow.value, 'modelsToAssign');
    }
  };

  useEffect(() => {
    if (loadingLessons || loadingTypes) return;
    if (!slideShows.length) return;

    if (initialValues.slideShowSelect) {
      handleSlideShowSelect(initialValues.slideShowSelect);
    } else if (initialValues.modelsToAssign?.length) {
      handleSlideShowSelect(initialValues.modelsToAssign[0]);
    } else if (slideShows.length) {
      handleSlideShowSelect(slideShows[0].value);
    }
  }, [initialValues, slideShows]);

  useEffect(() => {
    if (!(selectedType === 'classroomActivity')) return;

    let score = 0;

    const { modelsToAssign } = form.getState().values;

    // If the slide show has no slide groups.
    if (!Array.isArray(modelsToAssign)) {
      setStore({ score: selectedSlideShow?.score });
      return;
    }

    modelsToAssign.forEach((value) => {
      const slideGroup = selectedSlideShow?.slideGroups?.find(sg => sg.value?.includes(value));
      if (!slideGroup) return;

      score += slideGroup.score;
    });

    setStore({ score });
  }, [form.getState().values.modelsToAssign, selectedSlideShow]);

  useEffect(() => {
    if (!(selectedType === 'videoActivity')) return;
    if (!selectedSlideShow) return;

    const { score } = selectedSlideShow;
    setStore({ score });
  }, [form.getState().values.modelsToAssign, selectedSlideShow]);

  const slideGroupSelector = () => {
    if (!selectedSlideShow?.slideGroups?.length) return <></>;

    return (
      <span className={sectionStyles.detailList}>
        <span className={sectionStyles.label} />
        <div className={styles.largeField}>
          <span>{I18n.t('default_recommended_slide_groups')}</span>
          <Field
            checkboxRowStyle={sectionStyles.detailOption}
            bulkSelectText="All"
            component={CheckboxGroup}
            formChangeValue={formChangeValue}
            hasBulkSelect
            key="slide-groups"
            legendText="Assign All"
            legendSrOnly
            name="modelsToAssign"
            options={selectedSlideShow.slideGroups}
          />
        </div>
      </span>
    );
  };

  return (
    <>
      <SelectField
        className={styles.assignableSelector}
        fieldClass={styles.largeField}
        label="Details"
        labelClass={sectionStyles.label}
        name={selectedSlideShow?.slideGroups?.length ? 'slideShowSelect' : 'modelsToAssign'}
        searchable={false}
        placeholder="Select one"
        onChange={handleSlideShowSelect}
        options={slideShows}
        required
        rowClass={sectionStyles.fieldContainer}
      />
      {selectedSlideShow?.archived && <ArchivedMessage />}
      {slideGroupSelector()}
    </>
  );
};

export default SlideShowSelector;
