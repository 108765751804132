import React, { useState, useRef, useEffect } from 'react';
import TextToSpeechButton from 'components/shared/TextToSpeechButton';
import I18n from 'i18n-js';
import useDrawingContext from './store/useDrawingContext';
import styles from './QuestionText.module.scss';

interface QuestionTextProps {
  questionTextEl: React.MutableRefObject<HTMLDivElement>;
}

const QuestionText: React.FC<QuestionTextProps> = ({ questionTextEl }) => {
  const questionId = useDrawingContext(state => state.questionId);
  const questionBody = useDrawingContext(state => state.body);
  const isExpanded = useDrawingContext(state => state.isExpanded);
  const setIsExpanded = useDrawingContext(state => state.setIsExpanded);
  const canvas = useDrawingContext(state => state.canvas);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const formattedBody = questionBody?.replace(/\n/g, '<br>');

  useEffect(() => {
    const checkTruncation = () => {
      if (contentRef.current) {
        const element = contentRef.current;
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
        setIsTruncated(element.scrollHeight > lineHeight);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [questionBody]);

  useEffect(() => {
    if (!canvas) return;

    if (questionTextEl.current && $('[data-fabric="wrapper"]')[0]) {
      canvas.elements.container.style.marginTop = `${questionTextEl.current.offsetHeight}px`;
    }
  }, [canvas]);

  return (
    <div
      ref={questionTextEl}
      className="tw-border-b tw-border-gray-300 tw-shadow-md tw-bg-white tw-z-10 tw-w-full tw-absolute"
    >
      <div className="tw-relative tw-p-4">
        <div
          ref={contentRef}
          className={`tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out ${
            isExpanded ? 'tw-max-h-[75vh] tw-overflow-y-auto' : 'tw-max-h-[1.5em]'
          } tw-break-words tw-pr-28`}
        >
          {formattedBody ? (
            <div
              id={`question-text-${questionId}`}
              className={`${styles.questionText} tw-text-[clamp(14px,2vw,18px)] tw-leading-5 tw-m-0 ${
                isExpanded ? 'tw-min-h-[50px]' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: formattedBody }}
            />
          ) : (
            <p className="tw-text-[clamp(14px,2vw,18px)] tw-m-0">Loading question text...</p>
          )}
        </div>
        <div className="tw-absolute tw-top-0 tw-right-0 tw-flex tw-flex-col tw-items-end tw-p-4">
          {isTruncated && (
            <>
              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded)}
                className="tw-text-tci-purple tw-no-underline tw-bg-transparent tw-border-none tw-p-0 tw-cursor-pointer tw-whitespace-nowrap tw-mr-8"
                style={{ right: '10px', marginLeft: '10px' }}
              >
                {isExpanded ? I18n.t('see_less') : I18n.t('see_more')}
                <span className={`tw-ml-1 tw-transition-transform tw-duration-300 ${isExpanded ? 'tw-rotate-180' : ''}`}>
                  ▼
                </span>
              </button>
              {isExpanded && (
              <div className="tw-mt-4 tw-mr-8">
                <TextToSpeechButton
                  locale={I18n.locale === 'es' ? 'es' : 'en'}
                  idToRead={`question-text-${questionId}`}
                />
              </div>
              )}
            </>
          )}
          {!isTruncated && (
            <div className="tw-mt-0 tw-mr-8">
              <TextToSpeechButton
                locale={I18n.locale === 'es' ? 'es' : 'en'}
                idToRead={`question-text-${questionId}`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionText;
