import React, { Component } from 'react';
import Axios from 'axios';
import { Spinner } from 'common/Modal';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export default class Form extends Component {
  static propTypes = {
    districtOnly: PropTypes.bool,
    saveBeforeShare: PropTypes.bool,
    questionId: PropTypes.number,
    paramName: PropTypes.string.isRequired
  };

  static defaultProps = {
    error: null,
    isLoading: false,
    districtOnly: false,
    fallbackPath: null,
    paramName: null,
    saveBeforeShare: false,
    questionId: null
  };

  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      this.state.error ? (this.props.paramName === 'question' ? 'An error occurred. Please try manually saving the question before sharing.' :
                                                                'An error occurred. Please try again.') :
      <form onSubmit={this._handleSubmit.bind(this)}>
        <div onChange={this._handleChange.bind(this)}
             className={styles.container}>
          <label className={styles.label}>
            <input type="radio"
                   value="false"
                   name="share"
                   defaultChecked={this.state.districtOnly === false} />
            Share with all teachers, including within my district
          </label>
          <label className={styles.label}>
            <input type="radio"
                   value="true"
                   name="share"
                   defaultChecked={this.state.districtOnly === true} />
            Share within my district only
          </label>
        </div>
        <Spinner isLoading={this.state.isLoading} />
        <div>
          <hr />
          <footer>
            <input
              className="btn btn--purple"
              type="submit"
              value={this.props.saveBeforeShare ? 'Save & Submit' : 'Submit'}
              data-disable-with="Submitting…" />
          </footer>
        </div>
      </form>
    );
  }

  _handleChange(event) {
    this.setState({districtOnly: event.target.value});
  }

  _handleSubmit(event) {
    event.preventDefault();

    this.setState({isLoading: true});

    if (this.props.saveBeforeShare && this.props.questionId) {
      const $form = $(`form[data-question-id="${this.props.questionId}"]`);
      let formData;

      const gridPromptData = window.admin_grids[this.props.questionId];
      if (gridPromptData) {
        gridPromptData.writeToFields();
        formData = $(gridPromptData.form).serialize();
      }
      else {
        formData = $form.serialize();
      }


      Axios.patch($form.attr('action'), formData).then(response => {
        if (response.data.errors) {
          console.log(response.data.errors);
          this.setState({isLoading: false, error: true});
        } else {
          this._shareQuestion();
        }
      });
    } else {
      this._shareQuestion();
    }
  }

  _shareQuestion() {
    let data = {};
    data[this.props.paramName] = {
      'district_only': this.state.districtOnly,
      'fallback_path': this.props.fallbackPath
    };

    Axios.post(this.state.ajaxPath, data).then(response => {
      if (response.data.errors) {
        console.log(response.data.errors);
        this.setState({isLoading: false, error: true});
      } else {
        this.setState({isLoading: false});
        this.props.onSuccess(this.state.districtOnly);
        this.props.closeModal();
        if (response.data.redirectTo) {
          window.location.replace(response.data.redirectTo);
        }
      }
    }).catch(error => {
      console.log(error);
      this.setState({isLoading: false, error: true});
    })
  }
}
