import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import { Form, Field } from 'react-final-form';

import { Footer, Spinner } from '../../common/Modal';
import showToast from '../../common/Toast';
import GooglePicker from './GooglePicker';
import styles from './UploadForm.module.scss';

export default class UploadForm extends Component {
  static propTypes = {
    assessmentLibrary: PropTypes.bool,
    closeModal: PropTypes.func,
    email: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    fillFileData: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    imageUrl: PropTypes.string,
    locale: PropTypes.string,
    name: PropTypes.string,
    successSignOnCallback: PropTypes.func.isRequired
  };

  static defaultProps = {
    assessmentLibrary: false,
    closeModal: null,
    email: null,
    imageUrl: null,
    locale: null,
    name: null
  };

  constructor(props) {
    super(props);
    this.state = { submitting: false, directory: 'My Drive', directoryId: 'root' };
    this.selectDirectory = this.selectDirectory.bind(this);
    this.upload = this.upload.bind(this);
    this.signIn = this.signIn.bind(this);
    this.setRootDirectory = this.setRootDirectory.bind(this);
  }

  componentDidMount() {
    this.props.fillFileData();
  }

  setRootDirectory() {
    this.setState({ directory: 'My Drive', directoryId: 'root' });
  }

  selectDirectory(data) {
    if (data.action === google.picker.Action.PICKED) {
      this.setState({ directory: data.docs[0].name, directoryId: data.docs[0].id });
    }
    else {
      this.setRootDirectory();
    }
  }

  signIn() {
    gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' }).then((user) => {
      this.setRootDirectory();
      this.props.successSignOnCallback(user);
    }, error => console.log(error));
  }

  upload(values) {
    this.setState({ submitting: true });
    const boundary = '-------314159265358979323846';
    const delimiter = `\r\n--${boundary}\r\n`;
    const closeDelim = `\r\n--${boundary}--`;
    const location = this.state.directoryId;
    const file = new Blob([this.props.getFile()], { type: 'application/pdf' });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const contentType = file.type || 'application/octet-stream';
      const metadata = {
        title: values.file_name || 'TCI',
        mimeType: contentType,
        parents: [{
          id: location
        }]
      };

      const base64Data = reader.result.split(',')[1];
      const multipartRequestBody =
        `${delimiter
        }Content-Type: application/json\r\n\r\n${
          JSON.stringify(metadata)
        }${delimiter
        }Content-Type: ${contentType}\r\n` +
        'Content-Transfer-Encoding: base64\r\n' +
        `\r\n${
          base64Data
        }${closeDelim}`;

      gapi.client.request({
        path: '/upload/drive/v2/files',
        method: 'POST',
        params: { uploadType: 'multipart' },
        headers: {
          'Content-Type': `multipart/mixed; boundary="${boundary}"`
        },
        body: multipartRequestBody
      }).then(() => {
        this.setState({ submitting: false });
        showToast('Save successful');
        this.props.closeModal();
      });
    };
  }

  renderUser() {
    return (
      <div className={styles.userContainer}>
        <img alt="" className={styles.googleIcon} src={this.props.imageUrl} />
        <div className={styles.rightItems}>
          <div className={styles.name}>{this.props.name}</div>
          <div>{this.props.email}</div>
          <div>
            Not You?
            <button type="button" className={`btn btn--link-purple ${styles.switchAccounts}`} onClick={this.signIn}>
              Switch Accounts
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderLanguageLabel() {
    return (
      <div className={styles.formRow}>
        <div className={styles.label}>Language</div>
        {this.props.locale === 'en' ? 'English' : 'Spanish'}
      </div>
    );
  }

  render() {
    if (!this.props.getFile()) return (<div className={styles.googleDrive}><Spinner isLoading /></div>);
    return (
      <Form
        onSubmit={this.upload}
        initialValues={{ file_name: this.props.fileName }}
        render={({
          form, handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            {this.props.locale && this.renderLanguageLabel()}
            {this.props.assessmentLibrary && (
              <div className={styles.warningBox}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                <span className={styles.warningText}>
                  {I18n.t('warning_message')}
                </span>
              </div>
            )}
            <div className={styles.formRow}>
              <div className={styles.label}>Save To</div>
              <GooglePicker
                selectCallback={this.selectDirectory}
                directory={this.state.directory}
              />
            </div>
            <div className={styles.formRow}>
              <label htmlFor="file_name" className={styles.label}>Save As</label>
              <Field
                id="file_name"
                name="file_name"
                component="input"
                disabled={this.state.submitting}
                className={styles.fileName}
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.label}>Signed In As</div>
              <div>{this.renderUser()}</div>
            </div>
            <Footer
              secondaryButtonCallback={this.props.closeModal}
              submitting={this.state.submitting}
              primaryButtonText="Save"
            />
          </form>
        )}
      />
    );
  }
}
