import React from 'react';
import Tooltip from 'components/common/Tooltip';
import I18n from 'modules/i18n';
import key from 'images/icons/key.png';
import keyDisabled from 'images/icons/key-disabled.png';
import styles from './ImpersonateStudent.module.scss';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import { ImpersonateStudentProps } from './types';

interface ImpersonateStudentButtonProps extends ImpersonateStudentProps {
  buttonClass?: string;
  iconClass?: string;
  modal: ModalManager;
  tooltipPlacement: 'top' | 'bottom';
}

const ImpersonateStudentButton = ({
  student, enabled, modal, buttonClass, iconClass, tooltipPlacement,
}: ImpersonateStudentButtonProps) => {
  const srText = student ? I18n.t('sign_in_as', { name: student.name }) : I18n.t('view_as_student');
  const btnClass = buttonClass || styles.button;
  const imgClass = iconClass || styles.warningIcon;
  const tooltipContent = () => {
    if (!enabled) return I18n.t('return_to_admin');

    return student ? I18n.t('sign_in_as_this_student') : I18n.t('view_as_student');
  };

  return (
    <Tooltip
      interactive={false}
      content={tooltipContent()}
      theme="white"
      size="medium"
      placement={tooltipPlacement}
    >
      <button
        disabled={!enabled}
        className={`tw-bg-white ${btnClass}`}
        type="button"
        aria-haspopup="dialog"
        aria-controls="impersonateStudentModal"
        aria-label="Sign in as student"
        {...useKeydownAndClick(enabled ? modal.open : null)}
      >
        <img src={enabled ? key : keyDisabled} alt="" className={imgClass} />
        <span className="sr-only">{srText}</span>
      </button>
    </Tooltip>
  );
};

export default ImpersonateStudentButton;
