import axios from 'axios';
import { plato_api_decks_path } from '@/modules/routes';
import { QueryKeys, useVocabDecksStore } from '@/components/common/VocabularyCards/variables';
import { useQuery } from '@tanstack/react-query';
import { IVocabDecksPageDeck } from '@/components/common/VocabularyCards/interfaces';

interface IDecksApiResponse {
  data: IVocabDecksPageDeck[];
}

const useDecks = (opts = {}) => (
  useQuery([QueryKeys.DECKS, opts], () => (
    axios
      .get<IDecksApiResponse>(plato_api_decks_path(opts))
      .then(res => res.data.data)
  ), { staleTime: Infinity, placeholderData: [] })
);

export const useVocabStoreDecks = () => {
  const lessonId = useVocabDecksStore(state => state.lessonId);
  const currentUser = useVocabDecksStore(state => state.currentUser);
  const scope = currentUser.type === 'Student' ? { for_student: currentUser.id } : { for_staffer: currentUser.id };

  return useDecks({
    extra_attributes: ['staffer'],
    includes: ['glossary_terms', 'staffer'],
    for_lesson: lessonId,
    ...scope,
  });
};

export default useDecks;
