import Axios from 'axios';
import { debounce } from '@/modules/TCIUtils';
import * as Routes from '@/modules/routes';
import { CreateDrawingStoreVariables } from '../store/createDrawingStore';

type SaveArgs = Pick<CreateDrawingStoreVariables,
  'builder'
  | 'canvas'
  | 'questionId'
  | 'sectionId'
  | 'slideShowId'>

const save = ({
  builder,
  canvas,
  questionId,
  sectionId = null,
  slideShowId = null,
}: SaveArgs) => {
  if (builder) {
    return (
      Axios.patch<{ id: number }>(
        Routes.plato_api_question_path(questionId),
        { data: { fabric_data: JSON.stringify(canvas) } },
      )
    );
  }

  return (
    Axios.post<{ id: number }>(
      Routes.plato_api_question_answers_path({ section_id: sectionId, slideshow_id: slideShowId }),
      {
        question_answer: {
          answer_data: JSON.stringify(canvas),
          // print_data: canvas?.toDataURL(),
          question_id: questionId,
        },
      },
    )
  );
};

const triggerAutoSaveEvent = (questionId: number) => {
  window.dispatchEvent(new CustomEvent('autosave', { detail: { questionId } }));
};

const autoSave = debounce(({
  builder = false,
  canvas,
  exerciseType,
  questionId,
  questionAnswer,
  sectionId,
  setAnswerId,
  setAutoSaveQueued,
  setSaving,
  slideShowId,
  userType,
}: CreateDrawingStoreVariables) => {
  setAutoSaveQueued(false);

  if (questionId === 0) return;
  if (!builder && userType === 'Sysadmin') return;
  if (!builder && userType === 'Teacher' && exerciseType === 'Assessment') return;

  if (!canvas) return;

  const currentCanvas = canvas.toJSON().objects;
  const initialQuestionAnswer = questionAnswer.objects;

  const isDirty = JSON.stringify(currentCanvas) !== JSON.stringify(initialQuestionAnswer);

  if (isDirty) {
    setSaving(true);

    save({ builder, canvas, questionId, sectionId, slideShowId })
      .then((res) => {
        triggerAutoSaveEvent(questionId);
        if (builder) return;
        if (!res) return;

        if (setAnswerId) setAnswerId(res.data.id);
      }).catch((error) => {
        console.log('Error saving: ', error);
      })
      .finally(() => {
        setSaving(false);
      });
  }
}, 800);

export default autoSave;
