import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { modalManagerPropTypes } from 'common/Modal';
import { TextField } from 'common/Forms';
import { initialValueShapeProps, UPDATE_SLIDE_OBJECT, YOUTUBE_LINK_TEST_REGEX } from './Utils';
import { Footer } from '../../common/Modal';
import { SubmitError } from '../../common/Forms/Utils';

const YoutubeEmbed = ({ initialValues, loading, onSubmit }) => {
  const validateForm = (values) => {
    const errors = {};

    if (!values.youtube_video_url) errors.youtube_video_url = 'No URL given';

    if (!YOUTUBE_LINK_TEST_REGEX.test(values.youtube_video_url)) errors.youtube_video_url = 'Invalid URL';

    return errors;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <TextField name="youtube_video_url" required />
          {submitError && <SubmitError error={submitError} />}

          <Footer submitting={loading} primaryButtonDisabled={pristine} />
        </form>
      )}
    />
  );
};

YoutubeEmbed.propTypes = {
  initialValues: initialValueShapeProps,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default YoutubeEmbed;
