import { useContext } from 'react';
import { useStore } from 'zustand';
import { SlideShowContext } from './SlideShowStoreProvider';
import { CreateSlideShowStoreVariables } from './useSlideShowStore';

const useSlideShowContext = <T>(
  selector: (state: CreateSlideShowStoreVariables) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(SlideShowContext);

  return useStore(store, selector, equalityFn);
};

export default useSlideShowContext;
