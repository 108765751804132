import cn from '@/utils/cn';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface DropdownMenuProps {
  children: HTMLCollection | React.ReactNode;
  content: React.ReactNode;
  height: number;
  left?: number;
  top?: number;
  id: string;
  title: string;
}

const DropdownMenu = ({
  children,
  content,
  height,
  left = 55,
  top = 45,
  id,
  title,
}: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  useOnClickOutside(ref, (e) => {
    const target = e.target as HTMLElement;

    if (target === buttonRef.current || buttonRef.current.contains(target)) return;

    setIsOpen(false);
  });

  return (
    <>
      <button
        ref={buttonRef}
        id={id}
        type="button"
        tabIndex={-1}
        aria-controls={`dropdown-menu-${id}`}
        aria-expanded={isOpen}
        className={cn('fr-command fr-btn fr-dropdown', { 'fr-active': isOpen })}
        data-title={title}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {content}
        <span className="fr-sr-only">{title}</span>
      </button>
      <div
        ref={ref}
        id={`dropdown-menu-${id}`}
        className="fr-dropdown-menu"
        role="listbox"
        aria-labelledby={id}
        aria-hidden={!isOpen}
        style={{ left, top }}
      >
        <div
          className="fr-dropdown-wrapper"
          role="presentation"
          style={{ height: isOpen ? height : 0 }}
        >
          <div className="fr-dropdown-content" role="presentation">
            <ul className="fr-dropdown-list" role="presentation">
              {React.Children.map(children, child => (
                <li onClick={() => setIsOpen(false)} role="presentation">
                  {child}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownMenu;
