import React from 'react';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ToastBody from './ToastBody';
import CloseButton from './CloseButton';
import styles from './Toast.module.scss';

toast.configure();

export default function showToast(msg, {
  autoClose = 1500, closeOnClick = true, msgHeading = '', msgType = 'success', position = 'bottom-left'
} = {}) {
  const options = {
    autoClose,
    className: styles.toast,
    closeButton: <CloseButton />,
    closeOnClick,
    draggable: true,
    hideProgressBar: true,
    pauseOnHover: true,
    position,
    transition: Slide
  };

  toast(
    <ToastBody
      body={msg}
      heading={msgHeading}
      msgType={msgType}
    />,
    options
  );
}
