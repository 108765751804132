import React from 'react';
import PropTypes from 'prop-types';
import styles from './SaveStatus.module.scss';

function renderOopsError() {
  return <span className="red">Save failed. Please refresh the page and try again.</span>;
}

function renderSaving() {
  return <span className="gray">Saving...</span>;
}

function renderSaveSuccessful(time) {
  return (
    <span className={styles.saved_at}>
      <i className={`fa fa-check ${styles.checkbox}`} />
      Saved at {time}
    </span>
  );
}

function renderLastUpdated(lastUpdated) {
  return <span className="gray">{lastUpdated}</span>;
}

function renderTimestamp(time) {
  if (time) {
    return (
      <span className="gray">
        Updated
        {' '}
        {time}
      </span>
    );
  }

  return null;
}

export default function SaveStatus(props) {
  if (props.inputError) return null;
  if (props.saveError) return renderOopsError();
  if (props.saving) return renderSaving();
  if (props.savedTime) return renderSaveSuccessful(props.savedTime);

  if (props.lastUpdated) {
    return renderTimestamp(props.lastUpdated);
  }

  return null;
}

SaveStatus.propTypes = {
  inputError: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.string,
  saveError: PropTypes.bool,
  savedTime: PropTypes.string,
  saving: PropTypes.bool.isRequired
};

SaveStatus.defaultProps = {
  lastUpdated: null,
  saveErrorStatus: null,
  savedTime: null
};
