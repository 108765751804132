import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Modal from 'common/Modal';
import { SubmitError } from '../../Forms/Utils';

export default class Delete extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    destroyPath: PropTypes.string.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    updateTable: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.setState({ submitting: true });
    return Axios
      .delete(this.props.destroyPath.replace(':id', this.props.rowData.id))
      .then((response) => {
        if (response.status === 200) {
          this.props.updateTable(response.data.data);
        }

        this.setState({ submitting: false });
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ submitting: false, error: error.response.data.message });
      });
  }

  _renderDeleteButton() {
    let text = 'Delete';
    if (this.state.submitting) {
      text = (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Deleting...
        </span>
      );
    }

    return (
      <button
        className="btn btn--purple"
        disabled={this.state.submitting}
        onClick={this.handleDelete}
      >
        {text}
      </button>
    );
  }

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        headerText="Delete Class"
        isOpen={this.props.modalIsOpen}
      >
        <h1 className="mb10">
          Are you sure you want to delete this class?
        </h1>

        {this.state.error && <SubmitError error={this.state.error} />}

        <hr />

        <footer>
          <button
            className="btn btn--link-purple"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>

          {this._renderDeleteButton()}
        </footer>
      </Modal>
    );
  }
}
