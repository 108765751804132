import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import { toCamelCase } from '@/modules/TCIUtils';
import { SlideShowContext } from '@/components/admin/SlideShow/stores/SlideShowStoreProvider';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import OuterHtml from '@/components/ui/OuterHtml';
import { IHtmlElement } from '@/components/interfaces/graphql/HtmlElement';
import VideoButton from './VideoButton';
import HintButton from './HintButton';
import QuestionButton from './QuestionButton';
import TooltipButton from './TooltipButton/TooltipButton';
import ReadingButton from './ReadingButton/ReadingButton';
import JumpToButton from './JumpToButton/JumpToButton';
import { useVariableReplacer } from './useVariableReplacer';

interface UseHtmlElementReplacerProps {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  htmlElements: IHtmlElement[];
  skip: boolean;
}

const useHtmlElementReplacer = ({ containerRef, htmlElements, skip }: UseHtmlElementReplacerProps) => {
  const storeRef = useSlideShowContext(state => state.storeRef);

  const component = (htmlElement: IHtmlElement) => {
    switch (htmlElement.type) {
      case 'HintButton':
        return <HintButton hintButton={htmlElement} />;
      case 'JumpToButton':
        return <JumpToButton jumpToButton={htmlElement} />;
      case 'QuestionButton':
        return <QuestionButton containerRef={containerRef} questionButton={htmlElement} />;
      case 'TooltipButton':
        return <TooltipButton tooltipButton={htmlElement} />;
      case 'VideoButton':
        return <VideoButton videoButton={htmlElement} containerRef={containerRef} />;
      case 'ReadingButton':
        return <ReadingButton readingButton={htmlElement} />;
      case 'ModelEssentialQuestion':
        return <OuterHtml html={htmlElement.snippet} />;
      case 'ModelNumber':
        return <OuterHtml html={htmlElement.snippet} />;
      case 'ModelPhenomenon':
        return <OuterHtml html={htmlElement.snippet} />;
      case 'ModelStoryline':
        return <OuterHtml html={htmlElement.snippet} />;
      case 'ModelTitle':
        return <OuterHtml html={htmlElement.snippet} />;
      default:
        return null;
    }
  };

  const wrappedComponent = (htmlElement: IHtmlElement) => (
    <SlideShowContext.Provider value={storeRef.current}>
      <QueryClientProvider client={queryClient}>
        {component(htmlElement)}
      </QueryClientProvider>
    </SlideShowContext.Provider>
  );

  useVariableReplacer(htmlElements);

  const replaceSnippet = (node: HTMLElement) => {
    if (node.className.includes('variable')) return;

    const { htmlElementId } = node.dataset;
    const htmlElement: IHtmlElement = toCamelCase(htmlElements.find(e => e.id === htmlElementId));
    const parent = document.createElement('div');

    ReactDOM.render(wrappedComponent(htmlElement), parent, () => {
      node.replaceWith(...Array.from(parent.childNodes));
    });
  };

  useEffect(() => {
    if (skip) return;
    if (!containerRef || !containerRef.current) return;

    containerRef.current
      .querySelectorAll('[data-html-element-id]')
      .forEach(replaceSnippet);
  }, [containerRef?.current?.querySelectorAll('[data-html-element-id]')]);
};

export default useHtmlElementReplacer;
