import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal';
import EditForm from './EditForm';

import styles from '../Modal.module.scss';

export default function Edit({
  closeModal, districtCourseCodes, getDistrictCourseCodesForProgram,
  modalIsOpen, rowData, subscriberId, updateTable, updatePath
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText="Edit Course Mapping"
    >
      <EditForm
        allDistrictCourseCodes={districtCourseCodes}
        closeModal={closeModal}
        getDistrictCourseCodesForProgram={getDistrictCourseCodesForProgram}
        programCode={rowData.program_code}
        programName={rowData.program_name}
        subscriberId={subscriberId}
        updateTable={updateTable}
        updatePath={updatePath}
      />
    </Modal>
  );
}

Edit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  districtCourseCodes: PropTypes.instanceOf(Array).isRequired,
  getDistrictCourseCodesForProgram: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    district_course_code: PropTypes.string.isRequired,
    program_code: PropTypes.string.isRequired,
    program_name: PropTypes.string.isRequired
  }).isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateTable: PropTypes.func.isRequired,
  updatePath: PropTypes.string.isRequired
};
