import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IReferenceMaterial } from '../../types';

const useReferenceMaterials = (classroomId: number, lessonId?: number) => {
  const isEnabled = !!lessonId;

  const query = useQuery(['ReferenceMaterials', classroomId, lessonId], () => {
    const path = Routes.plato_api_staffer_reference_materials_reference_materials_path({
      classroom_id: classroomId,
      lesson_id: lessonId,
      only: ['id', 'title', 'max_score', 'assignable_type', 'assignable_label'],
    });

    return axios
      .get<{ data: IReferenceMaterial[] }>(path)
      .then(res => res.data?.data || null)
      .catch(err => console.error(err));
  }, { staleTime: Infinity, enabled: isEnabled });

  return { ...query, isEnabled, data: query.data || null };
};

export default useReferenceMaterials;
