import classroomActivityIconGreenOutline from 'images/icons/classroom-activity-green-outline.svg';
import classroomActivityIconGreyOutline from 'images/icons/classroom-activity-grey-outline.svg';
import investigationIconGreenOutline from 'images/icons/investigation-green-outline.svg';
import investigationIconGreyOutline from 'images/icons/investigation-grey-outline.svg';
import videoActivityIconGreenOutline from 'images/icons/video-activity-green-outline.svg';
import videoActivityIconGreyOutline from 'images/icons/video-activity-grey-outline.svg';
import lessonOutlineIconGreenOutline from 'images/icons/lesson-outline-green-outline.svg';
import lessonOutlineIconGreyOutline from 'images/icons/lesson-outline-grey-outline.svg';

import activityIcon from 'assets/images/lesson_modules/module_type_icons/activity.svg';
import challengeIcon from 'assets/images/lesson_modules/module_type_icons/challenge.svg';
import connectionsIcon from 'assets/images/lesson_modules/module_type_icons/connections.svg';
import investigationIcon from 'assets/images/lesson_modules/module_type_icons/investigation.svg';
import msopIcon from 'assets/images/lesson_modules/module_type_icons/making_sense_of_phenomena.svg';
import observingPhenomenaIcon from 'assets/images/lesson_modules/module_type_icons/observing_phenomena.svg';
import previewIcon from 'assets/images/lesson_modules/module_type_icons/preview.svg';
import primarySourceIcon from 'assets/images/lesson_modules/module_type_icons/primary_source.svg';
import processingIcon from 'assets/images/lesson_modules/module_type_icons/processing.svg';
import readingFurtherIcon from 'assets/images/lesson_modules/module_type_icons/reading_further.svg';
import vocabularyIcon from 'assets/images/lesson_modules/module_type_icons/vocabulary.svg';

export const icons = {
  archived_classroom_activity: classroomActivityIconGreyOutline,
  archived_investigation_activity: investigationIconGreyOutline,
  archived_lesson_outline: lessonOutlineIconGreyOutline,
  archived_video_activity: videoActivityIconGreyOutline,
  classroom_activity: classroomActivityIconGreenOutline,
  investigation_activity: investigationIconGreenOutline,
  lesson_outline: lessonOutlineIconGreenOutline,
  video_activity: videoActivityIconGreenOutline,
};

export const lessonModuleIcons = {
  activity: activityIcon,
  challenge: challengeIcon,
  connections: connectionsIcon,
  investigation: investigationIcon,
  making_sense_of_phenomena: msopIcon,
  observing_phenomena: observingPhenomenaIcon,
  preview: previewIcon,
  primary_source: primarySourceIcon,
  processing: processingIcon,
  reading_further: readingFurtherIcon,
  vocabulary: vocabularyIcon,
};
