import React from 'react';
import PropTypes from 'prop-types';
import { NumberField } from 'common/Forms';
import { SelectField, optionList, Header } from '../Util';
import styles from './GradesForm.module.scss';

const validateNotesGrade = (value) => {
  let error = false;

  if (isNaN(value)) {
    error = true;
  }
  else if (value > 100 || value < 1) {
    error = true;
  }
  else if (value % 1 !== 0) {
    error = true;
  }

  return error ? 'Default points value is invalid. Must be an integer value from 1 to 100' : null;
};

const lessonTooltip = 'Changing this setting will update the max possible score of all notebooks,' +
  ' except for notebook lesson scores that you have already set on the Grades page.';
const sectionTooltip = 'Changing this setting will update the max possible score of all notebooks,' +
  ' except for notebook section scores that you have already set on the Grades page.';

const GradesForm = ({ form }) => (
  <>
    <Header text="Select your default grading type and points per Notebook" />

    <SelectField
      label="Grade notebooks by"
      name="grade_notebooks_by"
      onChange={form.mutators.handleSelect}
      options={[{ label: 'Section', value: 'section' }, { label: 'Lesson', value: 'lesson' }]}
      sectionHeader="tw-inline"
      tooltip={`Changing this setting will update all notebooks to grade by lesson or section,
                except for lessons for which you've already graded a notebook.`}
    />

    <span className={styles.numberFieldContainer}>
      <NumberField
        label="When grading by lesson"
        name="notebook_possible_points"
        fieldClass="tw-pl-[95px]"
        labelClass={styles.notebookScoreLabel}
        tooltipText={lessonTooltip}
        validate={validateNotesGrade}
      />
      per lesson
    </span>

    <span className={styles.numberFieldContainer}>
      <NumberField
        label="When grading by section"
        name="section_possible_points"
        fieldClass="tw-pl-[95px]"
        labelClass={styles.notebookScoreLabel}
        tooltipText={sectionTooltip}
        validate={validateNotesGrade}
      />
      per section
    </span>


    <div className="tw-mt-5">
      <Header text="Select what results you want highlighted in Grades Page" />
    </div>

    <SelectField
      label="Class Trends"
      name="trends_highlight"
      onChange={form.mutators.handleSelect}
      options={optionList(0, 100, 5, '%')}
      tooltip="Highlights questions with a class average lower than the percentage you set."
    />

    <SelectField
      label="Grades"
      name="student_challenge_highlight"
      onChange={form.mutators.handleSelect}
      options={[{ label: 'None', value: -1 }, ...optionList(0, 100, 5, '%')]}
      tooltip="Highlights individual scores lower than the percentage you set."
    />
  </>
);

GradesForm.propTypes = {
  form: PropTypes.shape({
    mutators: PropTypes.shape({
      handleSelect: PropTypes.func
    })
  }).isRequired,
};

export default GradesForm;
