/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { ApolloProvider, gql, useMutation, useQuery } from '@apollo/client';
import apolloClient from 'components/common/ApolloClient';
import Select from 'react-select';

import styles from './styles.module.scss';
import { useModalManager } from 'components/common/Modal';
import Modal, { Footer } from '../../../common/Modal';
import NotebookCheckboxes from "./NotebookCheckboxes";

const AddExistingNotebooksWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <AddExistingNotebooks {...props} />
  </ApolloProvider>
);

export const GET_PROGRAM_UNIT_OPTIONS = gql`
  query getProgramUnitOptions {
    program {
      scopes(params: ["with_units", "standard"]) {
        id,
        copyrightedTitleWithCode,
        units {
          id,
          titleEn
        }
      }
    }
  }
`;

export const CREATE_MODEL_NOTEBOOKS = gql`
  mutation createModelNotebooks($notebookIds: [ID!]!, $unitId: ID!) {
    createModelNotebooks(notebookIds: $notebookIds, unitId: $unitId) {
      success
    }
  }
`

type AddExistingNotebooksProps = {
  currentUnitId: number
}

const AddExistingNotebooks = ({ currentUnitId } : AddExistingNotebooksProps) => {
  const modalManager = useModalManager();

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedNotebooks, setSelectedNotebooks] = useState(new Set())
  const [programOptions, setProgramOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [hasError, setHasError] = useState(false);

  const { loading, data } = useQuery(GET_PROGRAM_UNIT_OPTIONS, { variables: { fetchPolicy: 'network-only' } });
  const [createModelNotebooks, { loading: submitLoading }] = useMutation(CREATE_MODEL_NOTEBOOKS);

  useEffect(() => {
    if (loading) return;

    const arr = data.program.scopes.map(program => {
      return ({
        label: program.copyrightedTitleWithCode,
        value: program.id,
        units: program.units.map((unit) => {
          return ({
            value: unit.id,
            label: unit.titleEn
          })
        }).flat()
      });
    })

    setProgramOptions(arr);
  }, [data]);

  const handleUnitSelect = (e) => {
    if (e === null) {
      setSelectedUnit(null);
      setSelectedNotebooks(new Set());
    }
    else {
      setSelectedUnit(e);
    }
  };

  const handleProgramSelect = (e) => {
    if (e === null) {
      setSelectedProgram(null);
      setUnitOptions([]);
      setSelectedUnit(null);
      setSelectedNotebooks(new Set());
    }
    else {
      setSelectedProgram(e)
      setUnitOptions(e.units);
    }
  }

  const submit = () => {
    createModelNotebooks({ variables: { notebookIds: Array.from(selectedNotebooks),
                                                unitId: currentUnitId } })
      .then((response) => {
        if (response.data.createModelNotebooks.success === true) {
          window.location.reload();
        }
        else {
          setHasError(true)
        }
      })
      .catch((error) => {
        console.log(error);
        setHasError(true)
      });
  }

  const footer = () => {
    return (
      <Footer
        primaryButtonCallback={submit}
        primaryButtonDisabled={selectedNotebooks.size === 0}
        primaryButtonSubmit={false}
        secondaryButtonCallback={modalManager.close}
        submitting={submitLoading}
        wrapperClassName={styles.footer}
      />
    )
  }

  const renderModal = () => (
    <Modal
      allowOverflow
      nearlyFullScreen
      className={styles.modal}
      closeModal={modalManager.close}
      headerText="Add Existing Notebooks"
      isOpen={modalManager.isOpen}
      footer={footer()}
    >
      <div className={styles.selectContainer}>
        <label htmlFor="program-select">
          <p>Program:</p>
        </label>
        <Select
          className={styles.select}
          inputProps={{ id: 'program-select' }}
          label="program-select"
          onChange={handleProgramSelect}
          options={programOptions}
          value={selectedProgram}
        />
      </div>

      <div className={styles.selectContainer}>
        <label htmlFor="unit-select">
          <p>Units:</p>
        </label>
        <Select
          className={styles.select}
          disabled={unitOptions.length === 0}
          inputProps={{ id: 'unit-select' }}
          label="unit-select"
          onChange={handleUnitSelect}
          options={unitOptions}
          value={selectedUnit}
        />
      </div>

      {selectedUnit &&
        <NotebookCheckboxes
          selectedNotebooks={selectedNotebooks}
          setSelectedNotebooks={setSelectedNotebooks}
          unitId={selectedUnit.value}
        />}

      {hasError && <p className="red">Error occurred adding existing notebooks</p>}
    </Modal>
  )

  return (
    <span>
      <button
        className="btn btn--sm btn--green"
        onClick={modalManager.open}
      >
        Add Existing Notebooks
      </button>
      {modalManager.isOpen && renderModal()}
    </span>
  )
};

export default AddExistingNotebooksWrapper;
