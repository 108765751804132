import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import {
  audioDescriptionsEnabled,
  AudioVideoPositionData,
  buildAudioCuesData,
  connectNativeAudioDescriptionsMenu,
  ENGLISH_LABEL,
 } from '@/components/common/VideoPlayer/Utils';

interface AudioDescriptionsPlayerProps {
  audioDescriptionsCues?: { start: number, duration: number }[];
  audioDescriptionsUrl: string;
  videoPlayer: videojs.Player;
}

// Include this component in the video.js player to give an audio descriptions menu and audio track.
const AudioDescriptionsPlayer = ({
  audioDescriptionsCues, audioDescriptionsUrl, videoPlayer
}: AudioDescriptionsPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>();
  const audioPlayer = audioRef.current;
  const [audioVideoPositionData, setAudioVideoPositionData] = useState<AudioVideoPositionData[]>([]);

  useEffect(() => {
    setAudioVideoPositionData(buildAudioCuesData(audioDescriptionsCues));
  }, [audioDescriptionsCues, setAudioVideoPositionData]);

  useEffect(() => {
    if (!audioPlayer || !videoPlayer) return;

    videoPlayer.on('play', () => {
      audioPlayer.muted = !audioDescriptionsEnabled();
      if (handleInitialPlay()) return;

      audioPlayer.currentTime = audioTimeFromCurrentVideoTime();
      audioPlayer.play();
    });
    videoPlayer.on('pause', managePauseAction);
    videoPlayer.on('loadedmetadata', () => connectNativeAudioDescriptionsMenu(videoPlayer, audioPlayer));
    audioPlayer.addEventListener('timeupdate', manageVideoPlaybackWithCues)
  }, [audioPlayer, videoPlayer, localStorage]);

  // Override play/pause behavior for the special case of a pause at 0:00
  const handleInitialPlay = () => {
    const { currentTime } = audioPlayer;
    if (!audioDescriptionsEnabled() || currentTime !== 0) return;

    const currentCue = findCurrentCue(currentTime);
    if (currentCue && currentCue.start !== 0) return;

    audioPlayer.play();
    pluginHelper().setShouldBypassAudioPause(true);
    videoPlayer.pause();
    return true;
  };

  /**
   * With extended audio descriptions (audioDescriptionsCues), pausing can have a couple contexts:
   *
   * 1) The user can normally pause the video- to pause both video and audio descriptions (AD) audio.
   * 2) Video can be programmatically paused by `manageVideoPlaybackWithCues` within this component,
   *    to give time for the extra audio descriptions to play.
   *
   * Flag `shouldBypassAudioPause` is used to differentiate cases 1) and 2),
   * to determine whether to pause the audio descriptions audio or not.
   *
   * A second flag `isGloballyPaused` is additionally used
   * to prevent `manageVideoPlaybackWithCues` from errantly unpausing the video.
   *
   */
  const managePauseAction = () => {
    if (!pluginHelper().getShouldBypassAudioPause()) {
      audioPlayer.pause();
      pluginHelper().setIsGloballyPaused(true);
    } else {
      pluginHelper().setShouldBypassAudioPause(false);
      pluginHelper().setIsGloballyPaused(false);
    }
  };

  const audioTimeFromCurrentVideoTime = () => {
    const currentVideoTime = videoPlayer.currentTime();
    if ((audioDescriptionsCues || []).length === 0) return currentVideoTime;

    let data: AudioVideoPositionData;
    audioVideoPositionData.forEach(cue => {
      if (cue.videoTime <= currentVideoTime) data = cue;
    });

    if (!data) return currentVideoTime;

    const extraTime = currentVideoTime - data.videoTime;
    return data.audioTime + extraTime;
  };

  const manageVideoPlaybackWithCues = () => {
    if (!audioDescriptionsEnabled()) return;

    const { currentTime } = audioPlayer;
    const currentCue = findCurrentCue(currentTime);

    if (currentCue) {
      if (!videoPlayer.paused()) {
        pluginHelper().setShouldBypassAudioPause(true);
        videoPlayer.pause();
      }
    } else if (videoPlayer.paused() && !currentCue && !pluginHelper().getIsGloballyPaused()) {
      videoPlayer.play();
    }
  };

  const findCurrentCue = (currentTime) => {
    return (audioDescriptionsCues || []).find(cue => {
      return cue.start <= currentTime && currentTime <= (cue.start + cue.duration);
    });
  };

  const pluginHelper = () => {
    // @ts-ignore
    return videoPlayer && videoPlayer.audioDescriptionsPlugin();
  };

  return (
    <>
      <audio ref={audioRef} src={audioDescriptionsUrl} />
      {/* Providing <track> gives the video player's native audio descriptions menu */}
      <track
        label={ENGLISH_LABEL}
        kind="descriptions"
        srcLang="en"
      />
    </>
  );
};

AudioDescriptionsPlayer.propTypes = {
  audioDescriptionsUrl: PropTypes.string.isRequired,
  videoPlayer: PropTypes.any,
};

export default AudioDescriptionsPlayer;
