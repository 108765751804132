import React from 'react';
import { useModalManager } from 'components/common/Modal';
import BulkGradingModal from './BulkGradingModal';
import BulkGradingButton from './BulkGradingButton';
import { ScoreData } from './types';

interface BulkGradingWrapperProps {
  scoresData: ScoreData[];
}

export const BulkGradingWrapper = ({ scoresData }: BulkGradingWrapperProps) => {
  const modalManager = useModalManager();

  return (
    <>
      <BulkGradingButton
        modalManager={modalManager}
      />

      {modalManager.isOpen && (
        <BulkGradingModal
          isOpen={modalManager.isOpen}
          closeModal={modalManager.close}
          scoresData={scoresData}
        />
      )}
    </>
  );
};
