import { GridData, Locale } from './TablePromptAnswerKeyContent';

export const registerCurrentQuestionDataState = (questionId: number) => {
  const editGrid = editGridForQuestionId(questionId);
  if (!editGrid) return;

  const toggles = editGrid.locale !== 'es' ? ['es', 'en'] : ['en', 'es'];
  // Toggle the language back and forth to save in memory any in-progress changes to the question.
  toggles.forEach(locale => editGrid.toggleLanguage(locale));
};

export const editGridDataForQuestionAndLocale = (locale: Locale, questionId: number): GridData | {} => {
  const editGrid = editGridForQuestionId(questionId);
  if (!editGrid) return {};

  const editGridLocale = editGrid.locale;
  if (editGridLocale !== locale) editGrid.setCellsContent(locale);

  const data = {
    chunk: editGrid.toString(),
    rows: editGrid.num_rows,
    columns: editGrid.num_cols,
    col_ratios: editGrid.ratios.columns.join(','),
    height: editGrid.height
  };

  if (editGridLocale !== locale) editGrid.setCellsContent(editGridLocale);

  return data;
};

const editGridForQuestionId = (questionId: number) => {
  return (window.edit_grids || {})[questionId];
};

export const mergeValuesIntoGridData = (data: GridData, values: string[]): GridData | undefined => {
  if (!validData(data, values)) return;

  let idx = 0
  const mergedChunk = JSON.parse(data.chunk).map(row => {
    return row.map(cell => {
      if (!cell['is_admin']) cell['val'] = values[idx];
      idx += 1;
      return cell;
    });
  });

  return { ...data, chunk: JSON.stringify(mergedChunk) };
};

// Check valid data and log debugging info.
const validData = (data: GridData, values: string[]): boolean => {
  if (!values || values.length === 0) {
    console.log('Unable to merge values into grid data: `values` is empty.');
    return false;
  }
  if (!data || Object.keys(data).length === 0) {
    console.log('Unable to merge values into grid data: `data` is empty.');
    return false;
  }
  const numDataValues = JSON.parse(data.chunk).reduce((sum, arr) => sum + arr.length, 0);
  if (numDataValues !== values.length) {
    console.log(`Table structures incompatible:
                ${data.rows} rows, ${data.columns} columns,
                ${numDataValues} data values, ${values.length} new data values.`);
    return false;
  }

  return true;
};
