import React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { ScrollMenu, type publicApiType } from 'react-horizontal-scrolling-menu-7';
import Arrow from './Arrow';
import { OnScreenContext } from './OnScreenContext';
import 'react-horizontal-scrolling-menu-7/dist/styles.css';

const onWheel = (apiObj: publicApiType, ev: React.WheelEvent) => {
  // NOTE: there is no good standard way to distinguish touchpad scrolling gestures,
  // but we can assume that gesture will affect X axis, mouse scroll only Y axis
  // or if deltaY too small it's probably touchpad
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
};

interface HorizontalScrollMenuProps {
  hideArrows: boolean;
  children: ReactChildren;
}

const HorizontalScrollMenu = ({ hideArrows = false, children }: HorizontalScrollMenuProps) => {
  // NOTE: to show/hide arrows only when main component is on screen and visible
  const { isIntersecting: isVisible, ref } = useIntersectionObserver({ threshold: 1 });

  return (
    <div ref={ref}>
      <OnScreenContext.Provider value={isVisible}>
        <ScrollMenu
          LeftArrow={!hideArrows && <Arrow direction="left" />}
          RightArrow={!hideArrows && <Arrow direction="right" />}
          onWheel={onWheel}
          scrollContainerClassName="no-scrollbar tw-pt-1"
        >
          {/* @ts-ignore */}
          {children}
        </ScrollMenu>
      </OnScreenContext.Provider>
    </div>
  );
};

export default HorizontalScrollMenu;
