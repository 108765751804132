import React from 'react';
import I18n from 'modules/i18n';
import * as Routes from 'modules/routes';
import clsx from 'clsx';
import {
  ForDisplay, IAssignmentShortcutProps, IGradeShortcutProps, ILmsShareProps,
} from '@/components/admin/SlideShow/PresentMode/TransitionSlide/types';
import ImpersonateShortcut from '@/components/common/ShortcutButtons/ImpersonateShortcut';
import LmsShare from '@/components/common/ShortcutButtons/LmsShare';
import GradeShortcut from '@/components/common/ShortcutButtons/GradeShortcut';
import AssignmentShortcut from '@/components/common/ShortcutButtons/AssignmentShortcut';
import { ConditionalWrap } from 'components/common/Utils';
import Tooltip from '../../../../common/Tooltip';
import useSlideShowContext from '../../stores/useSlideShowContext';
import useSlideShowStore from '../../stores/useSlideShowStore';
import UnansweredQuestions from './UnansweredQuestions';
import styles from './TransitionSlide.module.scss';
import { SlideGroup } from '../../Builder/CreateTransistionGroup/utils';
import TitleLink from './TitleLink';
import AssessWithLinks from './AssessWithLinks';
import AssignmentSelect from './AssignmentSelect';
import TurnInButton from './TurnInButton';

interface RightPanelProps {
  assignmentId: string;
  exerciseResults?: {
    completedAt: string;
    answerUpdatedAt: number;
  }[];
  isIntroSlide: boolean;
  isFinalSlide: boolean;
  isTemporary?: boolean;
  slideGroups: SlideGroup[];
  slideGroupIndex: number;
  jumpToSlide: (number) => void;
  unansweredQuestions?: any[];
  AssignmentShortcutProps: IAssignmentShortcutProps;
  GradeShortcutProps: IGradeShortcutProps;
  LmsShareProps: ILmsShareProps;
  forDisplay: ForDisplay;
  loading: boolean;
}

const RightPanel = ({
  assignmentId, exerciseResults, isIntroSlide, isFinalSlide, isTemporary, slideGroups, slideGroupIndex, jumpToSlide,
  unansweredQuestions, AssignmentShortcutProps, GradeShortcutProps, LmsShareProps, forDisplay, loading,
}: RightPanelProps) => {
  // Don't show a title link for the last transition slide
  const titleLinks = slideGroups.slice(0, -1);
  const { titleEn, titleEs } = slideGroups[slideGroupIndex];
  const title = I18n.locale === 'es' ? titleEs : titleEn;
  const programId = useSlideShowContext(state => state.programId);
  const slideShow = useSlideShowStore(state => state.slideShow) ?? useSlideShowContext(state => state.slideShow);
  const userType = useSlideShowContext(state => state.userType);
  const slideIds = useSlideShowContext(state => state.slideIds);
  const allowImpersonate = useSlideShowContext(state => state.allowImpersonate);
  const userId = useSlideShowContext(state => state.userId);

  const { lesson, unit } = slideShow;
  const isUnitSlideshow = !lesson;
  const isStudent = userType === 'Student';
  const hasAssignments = slideShow.slideGroupsAssignments.length > 0;
  const isAdminOrTeacher = ['Sysadmin', 'ContentManager', 'Admin', 'Teacher'].includes(userType);

  const backToButton = () => {
    if (isStudent && hasAssignments && !isUnitSlideshow) {
      return (
        <span>
          <a
            className={`btn btn--outline-purple btn--sm ${styles.backToButton} ${styles.student} ${isTemporary && 'disabled'}`}
            href={Routes.shared_program_assignments_path(programId, { lesson_id: lesson.id })}
            onClick={isTemporary ? e => e.preventDefault() : undefined}
            aria-disabled={isTemporary}
          >
            {I18n.t('back_to_assignments')}
          </a>
        </span>
      );
    }

    return (
      <span>
        <a
          className={`btn btn--outline-purple btn--sm ${styles.backToButton} ${isTemporary && 'disabled'}`}
          href={isUnitSlideshow ? (
            Routes.stream_shared_program_investigations_path(programId, { unit_id: unit.id, unit_projects: true })
          ) : (
            Routes.shared_program_lesson_slide_shows_path(programId, lesson.id)
          )}
          onClick={isTemporary ? e => e.preventDefault() : undefined}
          aria-disabled={isTemporary}
        >
          {I18n.t(isUnitSlideshow ? 'back_to_unit_projects' : 'back_to_lesson_overview')}
        </a>
      </span>
    );
  };

  const backToButtonWrapper = () => (
    <ConditionalWrap
      condition={isTemporary}
      wrap={children => (
        <div className="relative inline-block">
          <Tooltip content={I18n.t('no_access_to_lesson')} interactive={false}>
            {children}
          </Tooltip>
        </div>
      )}
    >
      {backToButton()}
    </ConditionalWrap>
  );

  const filteredUnansweredQuestions = () => {
    if (slideShow.activityType === 'lesson_outline' && slideIds.length === 0) {
      return unansweredQuestions;
    }

    return unansweredQuestions?.filter(question => slideIds?.includes(question.slideId));
  };

  const showUnansweredQuestions = () => (userType === 'Student' && filteredUnansweredQuestions()?.length > 0);

  return (
    <section id="right-panel" className={styles.rightPanel}>
      {isFinalSlide ? (
        <>
          {showUnansweredQuestions() && <UnansweredQuestions unansweredQuestions={filteredUnansweredQuestions()} />}
          {isAdminOrTeacher && forDisplay === 'present' && (
            <div className={styles.actionsContainer}>
              <ImpersonateShortcut
                enabled={allowImpersonate}
                programId={programId}
                stafferId={userId}
                isTemporary={isTemporary}
              />
              {LmsShareProps && <LmsShare {...LmsShareProps} isTemporary={isTemporary} />}
              {AssignmentShortcutProps && <AssignmentShortcut {...AssignmentShortcutProps} isTemporary={isTemporary} />}
              {GradeShortcutProps && GradeShortcutProps.gradesLink
                  && <GradeShortcut {...GradeShortcutProps} isTemporary={isTemporary} />}
            </div>
          )}
          {unit && isUnitSlideshow && backToButtonWrapper()}
          <div
            className={clsx({
              [styles.finalSlideItem]: true,
              'tw-w-full': isStudent,
              'tw-items-center': isStudent,
            })}
          >
            <p>{`${I18n.t('you_reached_the_end', { title })}!`}</p>
          </div>
          {!isUnitSlideshow && (
            <>
              {!isStudent && <AssessWithLinks isTemporary={isTemporary} />}
              <div
                className={clsx({
                  [styles.finalSlideItem]: true,
                  'tw-w-full': isStudent,
                })}
              >
                {!isStudent && <p>{I18n.t('see_more_options')}</p>}
                {backToButtonWrapper()}
              </div>
            </>
          )}
          {assignmentId && (
            <TurnInButton
              loading={loading}
              exerciseResults={exerciseResults}
              isStudent={isStudent}
              isPresentMode
              turnInPath={Routes.plato_api_turn_in_slide_show_path(slideShow.id, {
                program_id: programId,
                lesson_id: lesson.id,
                assignment_id: assignmentId,
              })}
              unansweredQuestions={filteredUnansweredQuestions()}
            />
          )}
        </>
      ) : (
        <>
          <div className="tw-flex tw-justify-between tw-w-full">
            {!window.location.href.includes('builder') && hasAssignments && (
              <AssignmentSelect assignmentId={assignmentId} />
            )}

            {(unit || lesson) && slideShow.activityType !== 'professional_development' && backToButtonWrapper()}
          </div>
          <div className={styles.titleLinks}>
            <div className={styles.jumpToLabel}>
              {`${I18n.t(isIntroSlide ? 'jump_to' : 'up_next')}:`}
            </div>
            {titleLinks.map((group, index, elements) => {
              const isLastLink = index === titleLinks.length - 1;
              const nextIsExtension = !isLastLink && elements[index + 1]?.extension === true;
              return (
                <TitleLink
                  key={group.id}
                  completed={index < slideGroupIndex}
                  isLastLink={isLastLink}
                  onClick={() => jumpToSlide(group.position)}
                  title={I18n.locale === 'es' ? group.titleEs : group.titleEn}
                  extension={group.extension}
                  pacing={group.pacing}
                  nextIsExtension={nextIsExtension}
                />
              );
            })}
          </div>
        </>
      )}
    </section>
  );
};

export default RightPanel;
