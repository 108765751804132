import React from 'react';
import PropTypes from 'prop-types';
import Modal, { useModalManager } from '../../../common/Modal';
import TablePromptAnswerKeyContent from './TablePromptAnswerKeyContent';

const TablePromptAnswerKeyButton = ({ questionId, automaticTranslation, imageModel, afterSubmit }) => {
  const { open, close, isOpen } = useModalManager();

  return (
    <>
      <button
        type="button"
        className="btn btn--outline-purple btn--sm"
        onClick={open}
      >
        Use Table Prompt
      </button>

      {isOpen &&
        <Modal
          nearlyFullScreen
          isOpen={isOpen}
          closeModal={close}
          headerText="Create Answer Image with Table Prompt"
        >
          <TablePromptAnswerKeyContent
            questionId={questionId}
            automaticTranslation={automaticTranslation}
            imageModel={imageModel}
            close={close}
            afterSubmit={afterSubmit}
          />
        </Modal>
      }
    </>
  );
};

TablePromptAnswerKeyButton.propTypes = {
  questionId: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  automaticTranslation: PropTypes.bool,
  imageModel: PropTypes.object,
};

export default TablePromptAnswerKeyButton;
