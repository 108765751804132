import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import * as Routes from 'routes';
import { capitalizeWords } from 'TCIUtils';
import { QuickLinks, QuickLinkPropType } from './QuickLinkCards';
import AssignmentCard from './Cards/AssignmentsCard';
import StudentWorkCard from './Cards/StudentWorkCard';
import BadgesCard from './Cards/BadgesCard';
import styles from './index.module.scss';
import SignInAsStudent from './SignInAsStudent';

const Dashboard = ({ lastViewed, lessonId, program, quickLinks, signInAsStudentOptions, user, hasPDCourses }) => {
  const isStudent = () => user.type === 'Student';

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.header}>
        <h1 className="tw-bg-white">{I18n.t('welcome')}, {capitalizeWords(user.name)}</h1>
        {!isStudent() && <SignInAsStudent signInAsStudentOptions={signInAsStudentOptions} />}
      </div>

      {isStudent() && (
        <>
          <AssignmentCard
            lessonId={lessonId}
            program={program}
            userId={user.id}
            lastViewed={lastViewed}
          />
          <div className={styles.shortcutsHeader}>
            <h2>Shortcuts</h2>
            <a
              href={Routes.shared_program_lesson_slide_shows_path(program.id, lessonId)}
              className="btn btn--outline-purple"
            >
              {I18n.t('lesson_overview')}
            </a>
          </div>
          <QuickLinks quickLinks={quickLinks} isStudent={isStudent()} />
        </>
      )}

      {!isStudent() && (
        <>
          <QuickLinks quickLinks={quickLinks} isStudent={isStudent()} />

          <StudentWorkCard
            lessonId={lessonId}
            program={program}
          />

          {hasPDCourses && (
            <BadgesCard
              program={program}
            />
          )}
        </>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  hasPDCourses: PropTypes.bool,
  lastViewed: PropTypes.string,
  lessonId: PropTypes.number.isRequired,
  program: PropTypes.shape({
    display_unit_content: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    science: PropTypes.bool.isRequired
  }).isRequired,
  quickLinks: PropTypes.shape({
    assignments: QuickLinkPropType,
    notebook: QuickLinkPropType,
    planLesson: QuickLinkPropType,
    readText: QuickLinkPropType
  }).isRequired,
  signInAsStudentOptions: SignInAsStudent.propTypes.signInAsStudentOptions.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

Dashboard.defaultProps = {
  lastViewed: null
};

export default Dashboard;
