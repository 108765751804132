import React from 'react';

interface InstructionsProps {
  inline?: boolean;
  mfaEnabled: boolean;
  QRCode?: string;
}

const Instructions = ({ inline, mfaEnabled, QRCode }: InstructionsProps) => {
  if (mfaEnabled && inline) {
    return <p className="tw-pt-1">Enter the code generated by your authenticator app</p>;
  }

  return (
    <>
      <strong>Mobile Authenticator</strong>
      <ol className="tw-list-decimal tw-list-outside tw-pl-5 marker:tw-font-bold">
        <li>
          <p className="tw-mb-0 tw-font-bold">Open your Mobile Authenticator App</p>
          <p className="tw-mb-0">Recommended: Google Authenticator, Authy</p>
        </li>
        <li>
          <p className="tw-mb-0 tw-font-bold">Scan the QR code below using your app of choice</p>
          {(QRCode) && <img alt="QR Code for MFA" className="tw-bg-black" src={QRCode} />}
        </li>
        <li>
          <span className="tw-mb-0 tw-font-bold">
            Enter and verify the code generated by your Authenticator App
          </span>
        </li>
      </ol>
    </>
  );
};

export default Instructions;
