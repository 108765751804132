import React from 'react';
import ReactDOM from 'react-dom';

import Checkbox from '../../admin/subscribers/StaffTable/BulkAction/Checkbox';

export const renderProgramsCount = (td, cellData) => {
  ReactDOM.render(cellData.length, td);
};

export const renderTeachersCount = (td, cellData, rowData) => {
  ReactDOM.render(rowData.teachers_count, td);
};

// only returns the first instance of a classroom with the given UID
export const filterDuplicateRosters = classrooms => (
  classrooms.filter((classroom, index, orignalArr) => (
    index === orignalArr.findIndex(c => c.uid === classroom.uid)
  ))
);

export const renderCheckboxForRow = ({
  checked, disabled = false, handleCheckboxChange, rowData, target
}) => {
  ReactDOM.render(
    <Checkbox
      checked={checked}
      disabled={disabled}
      handleChange={handleCheckboxChange}
      labelText={checked ? `Unselect ${rowData.name}` : `Select ${rowData.name}`}
      name="bulk-classroom"
      srOnly
      value={rowData.id.toString()}
    />,
    target,
  );
};
