import { useEffect, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useAssignablesStore from "@/components/staffer/AssignmentModal/WhatSection/store/useAssignablesStore";

const readingSorter = (a, b) => {
  const value = (item) => {
    if (item.includes('Section')) return 1;
    if (item.includes('Notebook')) return 2;
    if (item.includes('Reading')) return 3;
  };

  return value(a) - value(b);
};

const IndeterminateCheckboxChecker = ({ readingAssignables = [], form, formChangeValue, viewAllDetails }) => {
  const ref = useRef<HTMLInputElement>();
  const setStore = useAssignablesStore(state => state.setStore);

  useEffect(() => {
    const input = document.querySelector('input[name="modelsToAssign"][value="bulk-select"]') as HTMLInputElement;

    if (input) ref.current = input;
  }, []);

  useEffect(() => {
    const handler = (e, key) => {
      if (e.target.checked) return;

      e.target.indeterminate = false;
      formChangeValue([], key);
      formChangeValue(form.getState().values.modelsToAssign.filter((ma) => ma !== e.target.value), 'modelsToAssign');
    };

    const cleanup = () => {
      (readingAssignables || []).forEach((readingAssignable) => {
        const assignables = readingAssignable.assignables.filter(a => !!a);
        const inputValue = assignables.map(assignable => assignable.value).sort(readingSorter).join(',');
        const input = document.querySelector(`input[name="modelsToAssign"][value="${inputValue}"]`) as HTMLInputElement;

        if (input) {
          // @ts-ignore
          input.removeEventListener('click', handler);
        }
      });
    };

    (readingAssignables || []).forEach((readingAssignable) => {
      const assignables = readingAssignable.assignables.filter(a => !!a);
      const key = `notebook-reading-${readingAssignable.id}`;
      const inputValue = assignables.map(assignable => assignable.value).sort(readingSorter).join(',');
      const input = document.querySelector(`input[name="modelsToAssign"][value="${inputValue}"]`);

      if (input) input.addEventListener('click', e => handler(e, key));

      return () => cleanup();
    });
  }, []);

  useEffect(() => {
    if (!ref) return;
    if (!ref?.current) return;

    const handler = (e) => {
      if (e.target.checked) return;

      (readingAssignables || []).forEach((readingAssignable) => {
        formChangeValue([], `notebook-reading-${readingAssignable.id}`);
      });

      formChangeValue([], 'modelsToAssign');
    };

    ref.current.addEventListener('click', handler);

    return () => ref.current.removeEventListener('click', handler);
  }, [ref, ref?.current]);

  useDeepCompareEffect(() => {
    if (viewAllDetails) return;

    (readingAssignables || []).forEach((readingAssignable) => {
      const assignables = readingAssignable.assignables.filter(a => !!a);

      const key = `notebook-reading-${readingAssignable.id}`;
      const inputValue = assignables.map(assignable => assignable.value).sort(readingSorter).join(',');

      const { values } = form.getState();

      if (values[key]?.length > 0) {
        // If not all values have been assigned, set the simple input value to indeterminate
        const isIndeterminate = values[key].length !== assignables.length
          && !form.getState().values.modelsToAssign.includes(inputValue);

        if (isIndeterminate) {
          setTimeout(() => {
            const input = document.querySelector(`input[name="modelsToAssign"][value="${inputValue}"]`);

            // @ts-ignore
            if (input && !input.indeterminate) {
              // @ts-ignore
              input.indeterminate = true;
            }
          }, 0);
        }
      }
    });
  }, [readingAssignables, form.getState().values, viewAllDetails]);

  return null;
};

export default IndeterminateCheckboxChecker;
