import React, { useMemo } from 'react';
import * as Routes from '@/modules/routes';
import Popover from '@/components/ui/Popover';
import useFlags from '@/hooks/useFlags';
import GamesGradeCell from '../Cells/GradeCells/GamesGradeCell';
import { IChallenge, IGame, IStudentRow, IExerciseResult } from '../../types';
import { columnHelper } from '../../variables';
import { QueryKeys } from '../../GradesTableQueryClientProvider';
import useGradesTableStore from '../../store/useGradesTableStore';
import useLessonGames from '../../hooks/queries/useLessonGames';
import useGames from '../../hooks/queries/exerciseResults/useGames';
import { HeaderCell } from '../Cells/index';

const getGameHeader = (game: IGame | IChallenge) => (
  `${game.game_type === 'Lesson Review Game' ? 'Lesson Review Game: ' : ''}${game.assignment_modal_initial_value.modelsToAssign[0].label}`
);

const exerciseResultsForGame = (exerciseResults: IExerciseResult[], game: IGame | IChallenge) => (
  exerciseResults.filter(er => er.exercise_id === game.id)
);

const useGamesColumns = () => {
  const { data } = useLessonGames();
  const { data: exerciseResults } = useGames();
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const programId = useGradesTableStore(state => state.programId);
  const { updatedGradesTable } = useFlags();

  return useMemo(() => (
    data
      .filter(game => game.max_score > 0)
      .map((game) => {
        const accessor = (row: IStudentRow) => row.student.id;

        if (game.assignment_modal_initial_value.modelsToAssign[0] === null) return null;

        const isClearEnabled = exerciseResults.some(er => er.exercise_id === game.id && er.exercise_type === 'Challenge');

        return columnHelper.accessor(accessor, {
          header: () => {
            const clearUrl = Routes.reset_classroom_scores_teacher_classroom_challenge_scores_path(game.id, {
              classroom_id: classroom.id,
              lesson_id: lesson.id,
            });
            const trendsUrl = Routes.teacher_reading_challenge_trend_path(lesson.id, {
              classroom_id: classroom.id,
              program_id: programId,
            });

            return (
              <HeaderCell title={getGameHeader(game)} maxScore={game.max_score}>
                {game.game_type === 'Lesson Review Game' && updatedGradesTable && (
                  <Popover>
                    <Popover.Trigger
                      className={`tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-absolute 
                                  tw-right-1 tw-top-1 tw-text-lg hover:tw-bg-opacity-10 hover:tw-bg-tci-purple`}
                    >
                      <i className="fa fa-ellipsis-v" />
                    </Popover.Trigger>
                    <Popover.Content
                      className="tw-w-auto"
                      onOpenAutoFocus={(e) => {
                        e.preventDefault();
                        // The default focusable element is the bottom element which can be disabled
                        // and opens its tooltip which is kind of annoying behavior. Focus the always enabled el instead
                        const div = e.target as HTMLDivElement;
                        (div.children[0] as HTMLAnchorElement).focus();
                      }}
                      side="top"
                    >
                      <HeaderCell.TrendsAndClearButtons
                        ClearAllButtonProps={{
                          assignmentType: 'lesson game',
                          link: clearUrl,
                          isDisabled: !isClearEnabled,
                          queryKey: [QueryKeys.GameExerciseResults, classroom?.id, lesson.id],
                        }}
                        TrendsButtonProps={{
                          assignmentType: 'lesson game',
                          link: trendsUrl,
                        }}
                      />
                    </Popover.Content>
                  </Popover>
                )}
                {game.game_type === 'Lesson Review Game' && !updatedGradesTable && (
                  <HeaderCell.TrendsAndClearButtons
                    ClearAllButtonProps={{
                      assignmentType: 'lesson game',
                      link: clearUrl,
                      isDisabled: !isClearEnabled,
                      queryKey: [QueryKeys.GameExerciseResults, classroom?.id, lesson.id],
                    }}
                    TrendsButtonProps={{
                      assignmentType: 'lesson game',
                      link: trendsUrl,
                    }}
                  />
                )}
                {game.game_type === 'Lesson Review Game' && (
                  <HeaderCell.SyncGradesButton
                    exerciseType="lesson_game"
                    exerciseResults={exerciseResultsForGame(exerciseResults, game)}
                    link={Routes.send_challenge_scores_teacher_classroom_challenge_scores_path(
                      classroom.id,
                      { program_id: programId, lesson_id: lesson.id },
                    )}
                  />
                )}

                {game.game_type !== 'Lesson Review Game' && (
                  <HeaderCell.SyncGradesButton
                    exerciseType="game"
                    exerciseResults={exerciseResultsForGame(exerciseResults, game)}
                    link={Routes.send_game_scores_shared_program_game_path(
                      game.id, classroom.id,
                      { classroom_id: classroom.id,
                        program_id: programId },
                    )}
                  />
                )}
              </HeaderCell>
            );
          },
          id: `game-${game.id}`,
          cell: info => <GamesGradeCell studentId={info.getValue()} game={game} />,
        });
      })
      .filter(game => game !== null)
  ), [data.length, lesson?.id, classroom?.id, programId, exerciseResults]);
};

export default useGamesColumns;
