import React, {
  Fragment, useEffect, useLayoutEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import Modal, { Footer } from 'common/Modal';
import { SubmitError } from 'common/Forms/Utils';
import CodeMirror from 'codemirror/lib/codemirror';
import styles from '../Slide.module.scss';
import { slideObjectPropTypes } from '../../Utils';
import { getHTML } from '../Utils';
import useSlideShowContext from '../../stores/useSlideShowContext';

const SlideObjectHTMLEditor = (props) => {
  const textAreaRef = useRef();
  const [afterRender, setAfterRender] = useState(false);
  const locale = useSlideShowContext(state => state.locale);

  // this effect triggers once a slideObject has been selected or an HTML modal has been opened
  // because of the react timeline, this will cause the modal to begin rendering
  useEffect(() => {
    setAfterRender(true);
  }, [props.modalManager.isOpen, props.slideObject.id]);

  // useLayoutEffect to prevent flickering of textArea -> CodeMirror
  useLayoutEffect(() => {
    // wait until the DOM has begun rendering to ensure that the textArea exists and can be referenced
    if (!afterRender) return;
    if (props.modalManager.isOpen) {
      const codeMirrorInstance = CodeMirror.fromTextArea(textAreaRef.current, {
        autoCloseTags: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html'
      });

      // pre-populate the CodeMirror with data from the slideObject
      if (props.slideObject.html) codeMirrorInstance.setValue(getHTML(locale, props.slideObject));
    }
    setAfterRender(false);
  }, [afterRender]);

  // destroy the CodeMirror instance when the modal is closed
  const closeHTMLModal = () => {
    const codeMirrorInstance = $(textAreaRef.current).next('.CodeMirror')[0].CodeMirror;
    codeMirrorInstance.save();
    codeMirrorInstance.toTextArea();
    props.modalManager.close();
  };

  const handleHTMLSubmit = () => {
    const codeMirrorInstance = $(textAreaRef.current).next('.CodeMirror')[0].CodeMirror;
    props.updateSlideObject({ html: codeMirrorInstance.getValue() });
    closeHTMLModal();
  };

  const modalFooter = () => (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={handleHTMLSubmit}
      primaryButtonClassName="btn btn--purple"
      primaryButtonText="Save"
      primaryButtonSubmit={false}
      secondaryButtonCallback={closeHTMLModal}
      submitting={props.isSaving}
      submittingText="Saving..."
      wrapperClassName="pr20 pl20 pb20"
    />
  );

  const fullLocale = locale === 'en' ? 'English' : 'Spanish';

  const modalTitle = () => (props.slideObject.html ? `Edit HTML (${fullLocale})` : `Add HTML (${fullLocale})`);

  return (
    <Fragment>
      <div
        className={styles.htmlContainer}
        dangerouslySetInnerHTML={{ __html: getHTML(locale, props.slideObject) }}
      />
      {props.interactable && (
        <Modal
          closeModal={closeHTMLModal}
          footer={modalFooter()}
          headerText={modalTitle()}
          isOpen={props.modalManager.isOpen}
          nearlyFullScreen
        >
          {props.hasError && <SubmitError error="Error adding HTML. Please reload the page and try again." />}
          <textarea
            id={`slide-object-${props.slideObject.id}-html-editor`}
            ref={textAreaRef}
          />
        </Modal>
      )}
    </Fragment>
  );
};

SlideObjectHTMLEditor.propTypes = {
  hasError: PropTypes.bool,
  interactable: PropTypes.bool,
  isSaving: PropTypes.bool,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  setHasError: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  updateSlideObject: PropTypes.func.isRequired
};

SlideObjectHTMLEditor.defaultProps = {
  hasError: false,
  interactable: true,
  isSaving: false
};

export default SlideObjectHTMLEditor;
