import Axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { plato_api_slide_object_panoramic_images_path } from '@/modules/routes';

type Values = {
  image: any[];
};

const useCreatePanoramicImageMutation = (slideObjectId: string) => (
  useMutation({
    mutationFn: async (values: Values) => {
      const formData = new FormData();

      formData.append('panoramic_image[image]', values.image[0]);

      const imageRequest = await Axios.post(
        plato_api_slide_object_panoramic_images_path(slideObjectId),
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      return imageRequest.data;
    },
    mutationKey: ['useCreatePanoramicImageMutation', slideObjectId],
  })
);

export default useCreatePanoramicImageMutation;
