import FE from 'froala-editor';
import FroalaConfig from 'froala/FroalaConfig';
import styles from './TeacherNotes.module.scss';

const TextEditorConfig = ({ setEditorInstance }) => FroalaConfig({
  customConfig: {
    editorClass: styles.teacherNotes,
    enter: FE.ENTER_BR,
    height: '100%',
    imagePaste: false,
    pastePlain: true,
    toolbarButtons: [
      'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'formatOL', 'formatUL', 'outdent',
      'indent', 'insertLink', 'undo', 'redo', 'clearFormatting', 'selectAll',
    ],
    width: '100%',
    // eslint-disable-next-line sort-keys
    events: {
      // eslint-disable-next-line func-names
      'html.set': function() {
        this.selection.setAtEnd(this.$el.get(0));
        this.selection.restore();
      },
      initialized() {
        setEditorInstance(this);
      },
    },
  },
});

export default TextEditorConfig;
