import React, { useState } from 'react';
import DataTable from '@/components/common/DataTable';
import { useModalManager } from '@/components/common/Modal';
import {
  CUSTOM_COLUMN_DEFS, STANDARD_COLUMN_DEFS, CUSTOM_COLUMNS, STANDARD_COLUMNS,
} from '@/components/admin/ProgramsTable/columnDefinitions';
import LicenseAvailabilityModal from '@/components/admin/ProgramsTable/DefaultProgramLicenses/LicenseAvailabilityModal';

interface TableProps {
  archived: boolean;
  custom: boolean;
  isLoading: boolean;
  tableData: any;
  archivedTableData: any;
}

const Table = ({ archived, custom, isLoading, tableData, archivedTableData }: TableProps) => {
  const licensesModalManager = useModalManager();
  const [programParams, setProgramParams] = useState();

  const columns = () => (custom ? CUSTOM_COLUMNS : STANDARD_COLUMNS);
  const columnDefs = () => {
    if (custom) return CUSTOM_COLUMN_DEFS(licensesModalManager, setProgramParams);

    return STANDARD_COLUMN_DEFS(licensesModalManager, setProgramParams);
  };

  return (
    <>
      {archived && (
        <DataTable
          columns={columns()}
          columnDefs={columnDefs()}
          defaultOrder={[0, 'asc']}
          handleResourcesManually
          info={false}
          paging={false}
          resources={archivedTableData}
          sortable={false}
          isLoading={isLoading}
        />
      )}
      <DataTable
        columns={columns()}
        columnDefs={columnDefs()}
        defaultOrder={[0, 'asc']}
        handleResourcesManually
        info={false}
        paging={false}
        resources={tableData}
        sortable={false}
        isLoading={isLoading}
      />
      <LicenseAvailabilityModal modalManager={licensesModalManager} programParams={programParams} />
    </>
  );
};

export default Table;
