import React from 'react';
import ParsedFroalaHtml from '@/components/shared/ParsedFroalaHtml';

interface NewSectionTextProps {
  html: string;
  sectionTheme:string;
}

const NewSectionText = ({ html, sectionTheme } : NewSectionTextProps) => {
  const handleTextSectionClick = (e : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'STRONG' && target.classList.contains('glossary-text')) {
      e.preventDefault();
      const glossaryDefinition = (target.nextSibling as Element);

      if (glossaryDefinition.className.toString().includes('tw-hidden')) {
        glossaryDefinition.classList.remove('tw-hidden');
      } else {
        glossaryDefinition.classList.add('tw-hidden');
      }
    }
  };


  return (
    <div onClick={handleTextSectionClick} role="document" className="new-section-text">
      <div className={`fr-view tw-h-auto tw-w-auto ${sectionTheme}`}>
        <ParsedFroalaHtml html={html} shouldParse />
      </div>
    </div>
  );
};

export default NewSectionText;
