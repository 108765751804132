import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Modal from 'common/Modal';
import { snakeToTitle, isAdmin } from 'common/Utils';

import styles from './CompletedCard.module.scss';
import RunSync from '../../../subscribers/ManageRostering/RunSync';
import RunSyncButton from './RunSyncButton';
import { PROVIDER_DISPLAY_NAME } from './constants';
import { CompletedCardTable } from '../Utils';
import DeleteButton from '../Delete';
import SchedulePause from './SchedulePause';
import showToast from 'common/Toast';

export default class CompletedCard extends Component {
  static propTypes = {
    autoRosterDistrict: PropTypes.shape({
      contact_email: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      pause_start_date: PropTypes.string,
      pause_stop_date: PropTypes.string,
      roster_app: PropTypes.string,
      sync_type: PropTypes.string.isRequired
    }).isRequired,
    autoRosterDistrictPath: PropTypes.string.isRequired,
    deleteDistrictCallBack: PropTypes.func.isRequired,
    getRosterEventsPath: PropTypes.string.isRequired,
    mapCoursesPath: PropTypes.string.isRequired,
    mappedCoursesSize: PropTypes.number.isRequired,
    nextSyncTime: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    runSyncPath: PropTypes.string.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    updateRosterDistrict: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
  };

  static defaultProps = {
    nextSyncTime: null
  };

  static _deleteButtonText() {
    return (
      <span>
        <i aria-hidden="true" className="fa fa-trash-o" /> Delete
      </span>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      hasRunningSync: false,
      pauseModalIsOpen: false,
      rosterEvent: null,
      runModalIsOpen: false,
    };

    this.openRunModal = this.openRunModal.bind(this);
    this.closeRunModal = this.closeRunModal.bind(this);
    this.openPauseModal = this.openPauseModal.bind(this);
    this.closePauseModal = this.closePauseModal.bind(this);
    this.toggleSyncStatus = this.toggleSyncStatus.bind(this);
    this.handlePauseSubmit = this.handlePauseSubmit.bind(this);
  }

  componentDidMount() {
    this._getLastSuccessfulRosterEvent();
    this._getProcessingRosterEvent();
  }

  isAutoRosterEnabled() {
    if (this.props.autoRosterDistrict.enabled) {
      return (<p className={styles.activeDistrict}>Active</p>);
    }

    return (<p className={styles.inactiveDistrict}>Inactive</p>);
  }

  closePauseModal() {
    this.setState({ pauseModalIsOpen: false });
  }

  openPauseModal() {
    this.setState({ pauseModalIsOpen: true });
  }

  closeRunModal() {
    this.setState({ runModalIsOpen: false });
  }

  openRunModal() {
    this.setState({ runModalIsOpen: true });
  }

  toggleSyncStatus() {
    const autoRosterParams = { auto_roster_district: { enabled: !this.props.autoRosterDistrict.enabled } };

    return Axios
      .put(this.props.autoRosterDistrictPath, autoRosterParams)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          this.props.updateRosterDistrict(response.data.data);
        }
      })
      .catch(error => console.log(error));
  }

  handlePauseSubmit(values, msg) {
    const pauseStartDate = values.pause_start_date && moment(values.pause_start_date).format('YYYY-MM-DD');
    const pauseStopDate = values.pause_stop_date && moment(values.pause_stop_date).format('YYYY-MM-DD');

    const autoRosterParams = {
      auto_roster_district: { pause_start_date: pauseStartDate, pause_stop_date: pauseStopDate }
    };

    Axios
      .put(this.props.autoRosterDistrictPath, autoRosterParams)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
        }
        else {
          this.props.updateRosterDistrict(response.data.data);
          this.closePauseModal();
          showToast(typeof msg === 'string' ? msg : 'Your pause has been successfully scheduled.', { autoClose: 4000 });
        }
      })
      .catch(error => console.log(error));
  }

  _getLastSuccessfulRosterEvent() {
    Axios
      .get(`${this.props.getRosterEventsPath}?last_successful`)
      .then((response) => {
        this.setState({ rosterEvent: response.data.data[0] });
      })
      .catch(errors => console.log(errors));
  }

  _getDeleteMessage() {
    return (
      <p>
        To confirm deletion of this integration
        (
        { PROVIDER_DISPLAY_NAME[this.props.autoRosterDistrict.sync_type] }
        ),
        type
        {' '}
        <em>delete me</em>
        {' '}
        into the field.
      </p>
    );
  }

  _getProcessingRosterEvent() {
    Axios
      .get(`${this.props.getRosterEventsPath}?processing`)
      .then((response) => {
        const hasRunningSync = response.data.data.length > 0;
        this.setState({ hasRunningSync });
      })
      .catch(errors => console.log(errors));
  }

  _nextScheduledDate() {
    if (this.props.autoRosterDistrict.enabled) {
      return this.props.nextSyncTime;
    }

    return 'Syncs are currently paused.';
  }

  _renderRunSyncModal() {
    return (
      <Modal
        closeModal={this.closeRunModal}
        isOpen={this.state.runModalIsOpen}
        headerText="Run Sync"
      >
        <RunSync
          subscriber_id={this.props.subscriber.id}
          closeModal={this.closeRunModal}
          run_sync_url={this.props.runSyncPath}
        />
      </Modal>
    );
  }

  _renderToggleSyncs() {
    let buttonText = 'Enable Sync';

    if (this.props.autoRosterDistrict.enabled) {
      buttonText = 'Pause Sync';
    }

    return (
      <button
        type="button"
        className="btn btn--sm btn--outline-purple ml5"
        onClick={this.toggleSyncStatus}
      >
        <i aria-hidden="true" className="fa fa-pause" />
        {' '}
        {buttonText}
      </button>
    );
  }

  _renderActionButtons() {
    if (isAdmin(this.props.userType)) {
      return (
        <div className={styles.buttonsRow}>
          <span>
            {this._renderEditButton()}
            <DeleteButton
              deletePath={this.props.autoRosterDistrictPath}
              onDelete={this.props.deleteDistrictCallBack}
              deleteMessage={this._getDeleteMessage()}
              buttonLabel={CompletedCard._deleteButtonText()}
              buttonClass="btn--sm"
              wrapperClass="ml5"
            />
          </span>
          <span>
            <RunSyncButton
              hasRunningSync={this.state.hasRunningSync}
              districtDisabled={!this.props.autoRosterDistrict.enabled}
              openRunModal={this.openRunModal}
            />
            {this._renderRunSyncModal()}
            {this._renderToggleSyncs()}
            {!this.props.autoRosterDistrict.pause_start_date && (
              <SchedulePause
                close={this.closePauseModal}
                handlePauseSubmit={this.handlePauseSubmit}
                pauseStartDate={this.props.autoRosterDistrict.pause_start_date}
                pauseStopDate={this.props.autoRosterDistrict.pause_stop_date}
                isOpen={this.state.pauseModalIsOpen}
                open={this.openPauseModal}
              />
            )}
          </span>
        </div>
      );
    }

    return null;
  }

  _renderEditButton() {
    return (
      <button
        type="button"
        className="btn btn--sm btn--outline-purple ml5"
        onClick={this.props.openModal}
      >
        <i aria-hidden="true" className="fa fa-pencil-square-o" /> Edit
      </button>
    );
  }

  _isUsingCSVMethod() {
    return this.props.autoRosterDistrict.sync_type === 'tci_csv';
  }

  _isUsingOnerosterMethod() {
    return this.props.autoRosterDistrict.sync_type === 'oneroster_api';
  }

  _rowContents() {
    return [[this._syncColumns()], [this._lowerTableColumns()]];
  }

  _columnHeaders() {
    return [this._syncHeaders(), this._lowerTableHeaders()];
  }

  _syncHeaders() {
    if (this._isUsingCSVMethod()) {
      return ['Method', 'Status', 'Last Sync', 'Next Sync'];
    }

    return ['Method', 'Status', 'Program Assignment Rules', 'Last Sync', 'Next Sync'];
  }

  _lowerTableHeaders() {
    let lowerTableHeaders = ['Contact'];

    if (this._isUsingOnerosterMethod()) {
      lowerTableHeaders.push('Roster App');
    }
    else {
      lowerTableHeaders.push('');
    }

    if (this.props.autoRosterDistrict.pause_start_date) {
      lowerTableHeaders = lowerTableHeaders.concat([undefined, 'Scheduled Pause', undefined]);
    }

    return lowerTableHeaders;
  }

  _mappedCoursesLink() {
    return (
      <a
        href={this.props.mapCoursesPath}
        title="Link to Program Assignment Rules page"
      >
        {this.props.mappedCoursesSize}
      </a>
    );
  }

  _syncColumns() {
    const syncType = PROVIDER_DISPLAY_NAME[this.props.autoRosterDistrict.sync_type];
    const status = this.isAutoRosterEnabled();
    const coursesMapped = this._mappedCoursesLink();
    const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
    const lastSyncTime = this.state.rosterEvent && moment.unix(this.state.rosterEvent.date).format(`MMM DD, YYYY [at] hh:mm A [${timezone}]`) || 'N/A';
    let nextSyncTime = moment(this._nextScheduledDate()).format(`MMM DD, YYYY [at] hh:mm A [${timezone}]`);
    if (nextSyncTime === 'Invalid date'){
      nextSyncTime = 'N/A';
    }

    if (this._isUsingCSVMethod()) {
      return [syncType, status, lastSyncTime, nextSyncTime];
    }

    return [syncType, status, coursesMapped, lastSyncTime, nextSyncTime];
  }

  _lowerTableColumns() {
    let lowerTableColumns = [`${this.props.autoRosterDistrict.first_name} ${this.props.autoRosterDistrict.last_name} \
      (${this.props.autoRosterDistrict.contact_email})`];

    if (this._isUsingOnerosterMethod()) {
      const rosterApp = snakeToTitle(this.props.autoRosterDistrict.roster_app);

      lowerTableColumns.push(rosterApp);
    }
    else {
      lowerTableColumns.push('');
    }

    if (this.props.autoRosterDistrict.pause_start_date) {
      const startDate = this.props.autoRosterDistrict.pause_start_date;
      const stopDate = this.props.autoRosterDistrict.pause_stop_date;

      lowerTableColumns = lowerTableColumns.concat(
        [
          undefined,
          `Pause Start Date - ${moment(startDate).utc().format('MMM D, YYYY')}`,
          `Pause Stop Date - ${stopDate ? moment(stopDate).utc().format('MMM D, YYYY') : 'N/A'}`
        ]
      );
    }

    return lowerTableColumns;
  }

  render() {
    return (
      <CompletedCardTable
        cardHeader="Rostering"
        actionButtons={this._renderActionButtons()}
        headers={this._columnHeaders()}
        contents={this._rowContents()}
        openPauseModal={this.openPauseModal}
        closePauseModal={this.closePauseModal}
        handlePauseSubmit={this.handlePauseSubmit}
        pauseStartDate={this.props.autoRosterDistrict.pause_start_date}
        pauseStopDate={this.props.autoRosterDistrict.pause_stop_date}
        pauseModalIsOpen={this.state.pauseModalIsOpen}
        editing={true}
      />
    );
  }
}
