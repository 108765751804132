import React, { useEffect, useState } from 'react';
import * as Routes from '@/modules/routes';
import axios from 'axios';
import Spinner from '@/components/common/Spinner';
import I18n from 'i18n-js';
import moment from 'moment';
import AssignmentCard from './AssignmentCard';
import { UserType } from '../../types';

interface AssignmentCardsProps {
  user: UserType;
  classroomId: number;
  programId: number;
}

const AssignmentCardsFactory = ({ user, classroomId, programId }: AssignmentCardsProps) => {
  const [loading, setLoading] = useState(true);
  const [assignments, setAssignments] = useState([]);

  const isStudent = user.type === 'Student';
  const notDone = assignment => assignment.status === 'to_do';
  const notPassedDueDate = assignment => new Date(assignment.due_datetime) > new Date();

  const studentFilters = assignment => (
    notDone(assignment) && notPassedDueDate(assignment)
  );

  // If the user is a student, show assignments that have the to do status and have not passed their due date
  const getFilterOptions = assignment => (
    isStudent ? studentFilters(assignment) : (new Date(assignment.due_datetime) > new Date())
  );

  useEffect(() => {
    const route = isStudent ? Routes.plato_api_student_index_student_assignments_path(
      user.id,
      {
        for_classroom: classroomId,
        in_program: programId,
      },
    ) : Routes.plato_api_staffer_assignments_path(
      user.id,
      {
        in_program: programId,
        local_time_zone: moment.tz.guess(),
      },
    );

    axios.get(route)
      .then((response) => {
        setLoading(false);
        setAssignments(response.data.data.filter(assignment => getFilterOptions(assignment)));
      });
  }, []);

  const renderNoAssignmentsMessage = () => (
    <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
      {isStudent && <i className="fa fa-icon fa-check-circle tw-text-green tw-text-4xl tw-mb-1" />}
      <div className="tw-text-[18px]">{I18n.t('you_have_no_assignments')}</div>
    </div>
  );

  const getAssignmentCards = () => (
    assignments
      .sort((a, b) => {
        const dueDateA = new Date(a.due_datetime);
        const dueDateB = new Date(b.due_datetime);
        return dueDateA.getTime() - dueDateB.getTime();
      })
      .map(assignment => (
        <AssignmentCard
          key={`assignment-card-${assignment.id}`}
          assignment={assignment}
          assignmentModelsHash={assignment.assignment_models_hash}
          user={user}
        />
      ))
  );

  if (loading) return <div className="tw-flex tw-justify-center tw-items-center"><Spinner /></div>;

  if (assignments.length === 0) return renderNoAssignmentsMessage();

  return (
    <div className={`tw-flex tw-flex-col tw-items-center tw-justify-center
                     tw-w-full tw-gap-5 tw-py-10 tw-h-full`}
    >
      {getAssignmentCards()}
    </div>
  );
};

export default AssignmentCardsFactory;
