import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import styles from './ImpersonateModal.module.scss';

const ModalBody = ({ staffer, close }) => (
  <Fragment>
    <p>By signing in as {staffer.first_name} {staffer.last_name}, you are accessing their account and will see everything they see.</p>
    <br />
    <p>
      <i className={`fa fa-exclamation-triangle ${styles.warningIcon}`} />
      <strong>WARNING:</strong>
    </p>
    <br />
    <p>Any changes made while signed in as {staffer.first_name} {staffer.last_name} will <strong>overwrite</strong> existing data.</p>

    <hr />
    <footer>
      <button
        className="btn btn--link-purple"
        onClick={close}
        type="button"
      >
        Cancel
      </button>
      <a
        href={Routes.staffer_masquerade_path(staffer.id, { return_path: window.location.href })}
        className="btn btn--purple"
      >
        Sign in
      </a>
    </footer>
  </Fragment>
);

ModalBody.propTypes = {
  close: PropTypes.func.isRequired,
  staffer: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string
  }).isRequired
};

export default ModalBody;
