import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.scss';

export default function Tabs({ setActiveTab, activeTab, tabs }) {
  const calculateNextTab = (nextPosition) => {
    const lastTabIndex = tabs.length - 1;

    if (nextPosition > lastTabIndex) return tabs[0];
    if (nextPosition < 0) return tabs[lastTabIndex];

    return tabs[nextPosition];
  };

  const handleNextTab = (nextPosition) => {
    const nextTab = calculateNextTab(nextPosition);
    const nextTabObject = document.getElementById(`tab-${nextTab.key}`);

    setActiveTab(nextTab.key);
    nextTabObject.focus();
  };

  const handleKeyPress = (event) => {
    const currentPosition = tabs.map(tab => tab.key).indexOf(activeTab);

    if (event.key === 'Home') {
      const nextPosition = 0;
      handleNextTab(nextPosition);
    }

    if (event.key === 'End') {
      const nextPosition = tabs.length - 1;
      handleNextTab(nextPosition);
    }

    if (event.key === 'ArrowUp') {
      const nextPosition = currentPosition - 1;
      handleNextTab(nextPosition);
    }

    if (event.key === 'ArrowDown') {
      const nextPosition = currentPosition + 1;
      handleNextTab(nextPosition);
    }
  };

  const renderTab = (tabKey, label) => (
    <button
      id={`tab-${tabKey}`}
      type="button"
      role="tab"
      tabIndex={activeTab === tabKey ? 0 : -1}
      aria-selected={activeTab === tabKey}
      key={tabKey}
      onClick={() => (setActiveTab(tabKey))}
      onKeyDown={handleKeyPress}
      className={`${styles.button} ${activeTab === tabKey ? styles.active : ''}`}
    >
      {label}
    </button>
  );

  return (
    <div className={styles.tab_container}>
      {tabs.map(tab => renderTab(tab.key, tab.label))}
    </div>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};
