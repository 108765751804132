import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import { truncate } from 'modules/TCIUtils';
import { TRUNCATION_LENGTH } from '../variables';

export const TruncateTitle = (title: string, updatedGradesTable: boolean): React.ReactNode => {
  if (updatedGradesTable && title.length >= TRUNCATION_LENGTH) {
    return (
      <Tooltip content={title}>
        <span dangerouslySetInnerHTML={{ __html: truncate(title, TRUNCATION_LENGTH) }} />
      </Tooltip>
    );
  }
  return <span dangerouslySetInnerHTML={{ __html: title }} />;
};
