/* eslint-disable no-param-reassign */

import React, { useCallback, useRef } from 'react';
import Axios from 'axios';
import ReactDOM from 'react-dom';
import { useDebounceCallback } from 'usehooks-ts';
import * as Routes from '@/modules/routes';
import DropdownMenu from '@/components/common/Dropdown/DropdownMenu';
import { useDropdownEvents } from '@/components/common/Dropdown';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from '../../Content/SlideObjectText/AnchoredDropdown/AnchoredDropdown.module.scss';
import ColorPicker from './ColorPicker';
import { convertToHex } from '../../Utils';
import usePopover from '../../hooks/usePopover';
import { PopoverProps, PopoverData } from '../types';

const ColorPickerPopover = ({ anchorId, openerClass, slideObject, styleDivRef }: PopoverProps) => {
  const ref = useRef<HTMLDivElement>();
  const dropdownEvents = useDropdownEvents(ref);
  const updateSlideObjectInContext = useSlideShowContext(state => state.updateSlideObject);

  const afterToggle = () => {
    if (slideObject.backgroundColor || slideObject.opacity) {
      styleDivRef.current.style.backgroundColor = `${slideObject.backgroundColor}${slideObject.opacity}`;
    } else {
      styleDivRef.current.style.backgroundColor = '';
    }
  };

  const { coordinates, setIsUpdated } = usePopover({ afterToggle, anchorId, dropdownEvents, openerClass, ref });

  const updateSlideObject = (data: PopoverData) => (
    Axios.patch(Routes.plato_api_slide_object_path(slideObject.id), {
      background_color: data.color,
      opacity: convertToHex(data.opacity),
    }).then((res) => {
      updateSlideObjectInContext(res.data.slideObject);
    })
  );

  const handleSubmit = (data: PopoverData, closePopover = true): Promise<any> => {
    setIsUpdated(true);
    if (closePopover) dropdownEvents.setOpen(false);

    return updateSlideObject(data);
  };

  const debouncedSubmit = useCallback(useDebounceCallback(handleSubmit, 300), []);

  const handleChange = async (data: PopoverData) => {
    // Sets color inline, takes in 8 digit hex code (ex. #ff0000ff) and browser converts it to rgba.
    // First 6 digits of code are color, last 2 are opacity. e.g. #ff0000ff would equal rgba(255,0,0,1)
    if (data.color || data.opacity) {
      styleDivRef.current.style.backgroundColor = `${data.color}${convertToHex(data.opacity)}`;
    } else {
      styleDivRef.current.style.backgroundColor = '';
    }
    await debouncedSubmit(data, false);
  };

  return ReactDOM.createPortal(
    <DropdownMenu open={dropdownEvents.open} className={styles.margin} coordinates={coordinates} ref={ref}>
      {dropdownEvents.open && (
        <ColorPicker
          activeColor={slideObject.backgroundColor}
          activeOpacity={slideObject.opacity}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
    </DropdownMenu>,
    document.body,
  );
};

export default ColorPickerPopover;
