import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Modal, { Footer } from '../../../common/Modal';
import PostScoresButton from '../../../staffer/Scores/GradesTable/PostScoresButton';

export default class PostClassGradesButton extends Component {
  static propTypes = {
    assessmentId: PropTypes.number.isRequired,
    classroomId: PropTypes.number.isRequired,
    lessonId: PropTypes.number.isRequired,
    programId: PropTypes.number.isRequired,
    classroomName: PropTypes.string.isRequired,
    classroomPeriod: PropTypes.string.isRequired,
    allQuestionsGraded: PropTypes.bool.isRequired,
    classScoresSubmitted: PropTypes.bool.isRequired,
    postScoresPath: PropTypes.string.isRequired,
    regradeStudentPath: PropTypes.string.isRequired,
    updatedGradesTable: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      regradeModalOpen: false,
      submitError: false,
      submitting: false
    };

    this._postClassGrades = this._postClassGrades.bind(this);
    this._openRegradeModal = this._openRegradeModal.bind(this);
    this._closeRegradeModal = this._closeRegradeModal.bind(this);
    this._regradeClass = this._regradeClass.bind(this);
  }

  _renderPostScoresButton() {
    if (this.props.updatedGradesTable) {
      return (
        <PostScoresButton
          assessmentId={this.props.assessmentId}
          classroomId={this.props.classroomId}
          lessonId={this.props.lessonId}
          programId={this.props.programId}
          text="Post Class Scores"
          variant="button"
        />
      );
    }

    return (
      <button
        id="post-class-scores-button"
        onClick={this._postClassGrades}
        className="btn btn--purple"
        disabled={!this.props.allQuestionsGraded && !this.props.updatedGradesTable}
        type="button"
      >
        Post Class Scores
      </button>
    );
  }

  _renderRegradeButton() {
    return (
      <button
        id="regrade-class-scores-button"
        onClick={this._openRegradeModal}
        className="btn btn--purple"
      >
        Edit Class Scores
      </button>
    );
  }

  _openRegradeModal() {
    this.setState({ regradeModalOpen: true });
  }

  _closeRegradeModal() {
    this.setState({ regradeModalOpen: false });
  }

  _renderRegradeModal() {
    return (
      <Modal
        className="regrade-class-scores-modal"
        isOpen={this.state.regradeModalOpen}
        headerText="Edit Scores"
        closeModal={this._closeRegradeModal}
      >
        <p>Are you sure you would like to edit scores for a student in this class? The previous total scores for all students in this class will be unavailable to them and you will need to post the modified scores again.</p>
        <form onSubmit={this._regradeClass}>
          <Footer
            secondaryButtonCallback={this._closeRegradeModal}
            submitting={this.state.submitting}
            primaryButtonText="Yes, edit scores"
          />
        </form>
      </Modal>
    );
  }

  _post(path) {
    this.setState({ submitting: true });

    Axios.post(path).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      this.setState({ submitError: true });
    });
  }

  _postClassGrades(e) {
    e.preventDefault();
    this._post(this.props.postScoresPath);
  }

  _regradeClass(e) {
    e.preventDefault();
    this._post(this.props.regradeStudentPath);
  }

  render() {
    return (
      <div>
        {this.props.classScoresSubmitted ? this._renderRegradeButton() : this._renderPostScoresButton()}
        {this._renderRegradeModal()}
      </div>
    );
  }
}
