import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CircularProgressBar.module.scss';

export default class Index extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
    imageSrc: PropTypes.string,
    text: PropTypes.string,
    xOffsetText: PropTypes.number
  };

  static defaultProps = {
    imageSrc: null,
    text: null,
    xOffsetText: 0
  };

  constructor(props) {
    super(props);

    this.updateProgress = this.updateProgress.bind(this);
  }

  componentDidMount() {
    this.updateProgress();
  }

  componentDidUpdate() {
    this.updateProgress();
  }

  updateProgress() {
    const max = -219.99078369140625;

    const bar = this.el.children[0];

    bar
      .querySelector(`.${styles.fill}`)
      .setAttribute('style', `stroke-dashoffset: ${((100 - this.props.percent) / 100) * max}`);
  }

  _renderSVG() {
    return (`
        <svg 
            class="${styles.progress} ${styles.noselect}" 
            x="0px" y="0px" 
            viewBox="0 0 80 80"
        > 
          <path class="${styles.transform}" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" /> 
          <path class="${styles.transform} ${styles.fill}" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" /> 
          ${this.props.imageSrc ? this.renderImage() : this.renderText()}
        </svg>
    `);
  }

  renderText() {
    return (`<text width="45" height="45" x="${this.props.xOffsetText}px" y="45px">${this.props.text}</text>`);
  }

  renderImage() {
    return (`<image width="45" height="45" x="17px" y="17px" xlink:href="${this.props.imageSrc}" />`);
  }

  render() {
    return (
      <div
        ref={(el) => { this.el = el; }}
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html: this._renderSVG() }}
      />
    );
  }
}
