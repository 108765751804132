import React from 'react';
import { AxiosError } from 'axios';
import { ISeatPool } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import Modal, { Footer } from '@/components/common/Modal/';
import { SpinnerIcon } from '@/components/common/Utils';
import useProgramAccessStore from '../ProgramAccessTable/useProgramAccessStore';
import ManageProgramsTable from './ManageProgramsTable';
import useUpdateActiveProgramsMutation from './useUpdateActiveProgramsMutation';
import useManageProgramsCheckboxes from './useManageProgramsCheckboxes';
import LicenseSelect, { useLicenseSelect } from './LicenseSelect';
import ConfirmModal, { useConfirmModal } from './ConfirmModal';

interface ManageProgramsWizardProps {
  modalManager: ModalManager;
  initialLicenseId: number;
  subscriberStateId: number;
}

const ManageProgramsWizard = ({ modalManager, initialLicenseId, subscriberStateId }: ManageProgramsWizardProps) => {
  const isLoading = useProgramAccessStore(state => state.isLoading);
  const failedSeatPools = useProgramAccessStore(state => state.failedSeatPools);
  const setStore = useProgramAccessStore(state => state.setStore);
  const updateActiveProgramsMutation = useUpdateActiveProgramsMutation();
  const { licenseSelectProps, selectedLicense } = useLicenseSelect({ initialLicenseId });
  const { pristine } = useManageProgramsCheckboxes({ selectedLicense });
  const { confirmModalProps, confirmCallback } = useConfirmModal({ pristine });

  const onSave = () => (
    updateActiveProgramsMutation
      .mutateAsync(selectedLicense.id)
      .then(() => {
        setStore({ failedSeatPools: [] });
        modalManager.close();
      })
      .catch((err: AxiosError<{ data: ISeatPool[] }>) => setStore({ failedSeatPools: err.response.data.data }))
  );

  const closeModal = () => confirmCallback(() => {
    setStore({ failedSeatPools: [] });
    modalManager.close();
  });

  return (
    <>
      <Modal
        headerText="Manage Programs"
        closeModal={closeModal}
        isOpen={modalManager.isOpen}
        nearlyFullScreen
        aria-expanded="false"
      >
        <LicenseSelect
          {...licenseSelectProps}
          confirmCallback={confirmCallback}
        />
        {isLoading && <SpinnerIcon />}
        {selectedLicense && (
          <ManageProgramsTable licenseId={selectedLicense.id} subscriberStateId={subscriberStateId} />
        )}

        <Footer
          className="tw-relative"
          primaryButtonText="Save"
          secondaryButtonCallback={closeModal}
          primaryButtonCallback={onSave}
          primaryButtonSubmit={false}
          primaryButtonDisabled={pristine}
          submitting={updateActiveProgramsMutation.isLoading}
        >
          {failedSeatPools.length > 0 && (
            <div className="tw-absolute tw-left-0 tw-top-1/2 -tw-translate-y-1/2 error-container red">
              You cannot remove programs that are still in use. Tip: Bulk Remove program from all Staff.
            </div>
          )}
        </Footer>
      </Modal>

      <ConfirmModal {...confirmModalProps} />
    </>
  );
};

export default ManageProgramsWizard;
