import React, { useRef, useEffect } from 'react';

interface ContentInputsProps {
  id: number;
  title: string;
  copyIds: number[];
  reuseIds: number[];
  addId: (type: string, id: number) => void;
  removeId: (type: string, id: number) => void;
  isChecked: boolean;
  isIndeterminate?: boolean;
  showDoNotUseRadio?: boolean
  onCheck: () => void;
}

const ContentInputs = ({
  id,
  title,
  copyIds,
  reuseIds,
  addId,
  removeId,
  isChecked,
  isIndeterminate = false,
  showDoNotUseRadio = false,
  onCheck,
}: ContentInputsProps) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate && !isChecked;
    }
  }, [isIndeterminate, isChecked]);

  return (
    <div className="tw-flex">
      <label className="tw-flex tw-items-center tw-mb-2">
        <input
          ref={checkboxRef}
          type="checkbox"
          value="reuse"
          checked={isChecked}
          onChange={onCheck}
        />
        <span className="tw-ml-1">{title}</span>
      </label>

      {(isChecked || isIndeterminate) && (
        <div className="tw-flex tw-flex-auto tw-justify-end">
          <div className="tw-flex tw-w-[250px]">
            <label
              className="tw-flex tw-items-center tw-mb-2"
              style={{ display: 'none' }}
            >
              <input
                type="radio"
                value="reuse"
                checked={reuseIds.includes(id)}
                onChange={() => {
                  addId('reuse', id);
                  removeId('copy', id);
                }}
              />
              <span>Pick up</span>
            </label>

            <label
              className="tw-flex tw-items-center tw-mb-2"
            >
              <input
                type="radio"
                value="reuse"
                checked={copyIds.includes(id)}
                onChange={() => {
                  addId('copy', id);
                  removeId('reuse', id);
                }}
              />
              <span>Copy</span>
            </label>

            {showDoNotUseRadio && (
              <label className="tw-flex tw-items-center tw-mb-2">
                <input
                  type="radio"
                  value="doNotUse"
                  checked={!reuseIds.includes(id) && !copyIds.includes(id)}
                  onChange={() => {
                    removeId('copy', id);
                    removeId('reuse', id);
                  }}
                />
                <span>Do Not Use</span>
              </label>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentInputs;
