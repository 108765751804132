import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Tabs extends Component {
  static defaultProps = {
    selected: 0
  };

  static propTypes = {
    selected: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.element
    ]).isRequired
  };

  constructor(props) {
    super(props);
    this.state = { selected: props.selected };
  }

  handleClick(index, e) {
    e.preventDefault();

    this.setState({
      selected: index
    });
  }

  renderTitles() {
    function labels(child, index) {
      return (
        <button
          key={index}
          className={`btn btn--sm btn--outline-purple ${this.state.selected === index && 'active'}`}
          onClick={this.handleClick.bind(this, index)}
        >
          {child.props.label}
        </button>
      );
    }
    return (
      <div
        className="btn-group"
        role="group"
        aria-label="View warnings, errors, and archives of records during a sync event"
      >
        {this.props.children.map(labels.bind(this))}
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        {this.props.children[this.state.selected]}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderTitles()}
        {this.renderContent()}
      </div>
    );
  }
}

export const Pane = props => (
  <div>
    {props.children}
  </div>
);

Pane.propTypes = {
  children: PropTypes.element.isRequired
};
