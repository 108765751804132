import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import ModelSelect from './index';
import OutOfTermClassroomModal from '../OutOfTermClassroomModal';

const ClassroomSelect = ({ showOutOfTermClassrooms, ...props }) => (
  <div className="tw-relative tw-inline-block">
    {showOutOfTermClassrooms && props.stafferId && <OutOfTermClassroomModal stafferId={props.stafferId} />}
    <ModelSelect {...props} selectType="classroom" title={`${I18n.t('class')}:`} />
  </div>
);

ClassroomSelect.propTypes = {
  initialOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  showOutOfTermClassrooms: PropTypes.bool,
  programId: PropTypes.number.isRequired,
  resourceUrl: PropTypes.string,
  stafferId: PropTypes.number,
};

ClassroomSelect.defaultProps = {
  resourceUrl: null,
  showOutOfTermClassrooms: false,
};

export default ClassroomSelect;
