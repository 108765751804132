import React, { Fragment } from 'react';
import { SubmitModal, useModalManager } from '@/components/common/Modal';
import { staffer_question_group_path } from '@/modules/routes';
import styles from './DeleteModal.module.scss';

interface DeleteModalProps {
  questionGroupId: number;
}

const DeleteModal = ({ questionGroupId }: DeleteModalProps) => {
  const modalManager = useModalManager();

  const handleClick = (e) => {
    e.preventDefault();
    modalManager.open();
  };

  const afterSubmit = () => {
    $(`#question_group_${questionGroupId}`).remove();
  };

  return (
    <Fragment>
      <a role="button" className="tw-text-red tw-text-[20px] delete-question-group" onClick={handleClick}>
        <i className="fa fa-trash-o fa-fw no-toggle" aria-hidden />
      </a>
      <SubmitModal
        headerText="Delete Question Group?"
        submitPath={staffer_question_group_path(questionGroupId)}
        method="delete"
        isOpen={modalManager.isOpen}
        closeModal={modalManager.close}
        primaryButtonText="Delete"
        primaryButtonClassName="btn btn--red delete-question-group"
        headerClass={styles.modalHeader}
        afterSubmit={afterSubmit}
      >
        By deleting this Question Group, you will remove all questions linked to it.
      </SubmitModal>
    </Fragment>
  );
};

export default DeleteModal;
