import React from 'react';
import { capitalizeWords } from '@/modules/TCIUtils';
import I18n from 'i18n-js';
import UnitSection from './UnitSection';
import ToDoSection from './ToDoSection';
import StudentWorkSection from './StudentWorkSection';
import ImpersonateShortcut from '../../common/ShortcutButtons/ImpersonateShortcut';

import { ProgramType, UserType } from './types';

interface NewDashboardProps {
  classroomId: number;
  hasUnits: boolean;
  lessonId: number;
  program: ProgramType;
  stafferMasquerade: boolean;
  user: UserType;
}

const NewDashboard = ({ program, user, classroomId, hasUnits, lessonId, stafferMasquerade }: NewDashboardProps) => {
  const isStudent = user.type === 'Student';

  return (
    <div className="tw-bg-white">
      <div className="tw-mx-5 tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-[96%]">
        <h1>{I18n.t('welcome')}, {capitalizeWords(user.firstName || user.name)}!</h1>
        {!isStudent && (
          <ImpersonateShortcut
            programId={program.id}
            stafferId={user.id}
            enabled={!stafferMasquerade}
          />
        )}
      </div>
      <ToDoSection
        classroomId={classroomId}
        lessonId={lessonId}
        programId={program.id}
        user={user}
      />
      {!isStudent && (
        <StudentWorkSection
          lessonId={lessonId}
          programId={program.id}
        />
      )}
      {hasUnits && (
        <UnitSection programId={program.id} />
      )}
    </div>
  );
};

export default NewDashboard;
