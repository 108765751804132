import { useEffect } from 'react';
import { useAudioPlayer } from 'react-use-audio-player';

const audioEffects = ({ audioCommand, audioSrc }) => {
  const audioPlayer = useAudioPlayer({ format: 'mp3', src: audioSrc });

  // if the audioSrc changes, stop current audio then load the new src
  useEffect(() => {
    audioPlayer.stop();
    if (audioSrc) audioPlayer.load({ src: audioSrc });
  }, [audioSrc]);

  // wait until the audio player has finished loading the new src before playing
  useEffect(() => {
    if (!audioPlayer.ready || audioCommand === 'stop') return;

    audioPlayer.play();
  }, [audioPlayer.ready]);

  // start/stop the audio based on the audioCommand
  useEffect(() => {
    if (audioCommand === 'play') {
      audioPlayer.play();
    }
    else {
      audioPlayer.stop();
    }
  }, [audioCommand]);
};

export default audioEffects;
