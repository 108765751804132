import React, { useLayoutEffect, useRef } from 'react';
import Label, { ILabelProps } from '@/components/ui/Form/Label';
import { twMerge } from '@/utils';
import { FieldErrors, useWatch } from 'react-hook-form';
import ErrorMessage from '@/components/ui/Form/ErrorMessage';

interface ITextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  containerClasses?: string;
  errors?: FieldErrors<any>;
  label: string;
  LabelProps?: Partial<ILabelProps>;
  name: string;
  onChange: React.FormEventHandler<HTMLTextAreaElement>;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(({
  className = '',
  containerClasses = 'tw-flex tw-flex-col tw-gap-2',
  errors,
  label,
  LabelProps = {},
  name,
  onBlur,
  ...rest
}, ref) => {
  const watch = useWatch({ name });
  const wrapperRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;

    const textArea = wrapperRef.current.firstChild as HTMLTextAreaElement;

    textArea.style.height = '0';
  }, [wrapperRef, wrapperRef?.current]);

  useLayoutEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;

    const textArea = wrapperRef.current.firstChild as HTMLTextAreaElement;

    setTimeout(() => {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }, 10);
  }, [wrapperRef, wrapperRef?.current, watch]);

  return (
    <div className={containerClasses}>
      <Label {...{ htmlFor: name, ...LabelProps }}>
        {label}
      </Label>
      <div ref={wrapperRef}>
        <textarea
          className={twMerge(
            'tw-w-full tw-border-gray-300 tw-border tw-border-solid tw-rounded tw-p-2.5 tw-max-w-[280px]',
            'tw-box-border tw-transition-all tw-resize-none tw-overflow-hidden tw-duration-100',
            className,
          )}
          id={name}
          name={name}
          ref={ref}
          {...rest}
        />
        <ErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
});

export default TextArea;
