import React from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import styles from '../../Form.module.scss';

const helpUrl = 'https://www.teachtci.com/troubleshoot-roster-api-setup';

const BannerContent = ({
  connectionType, iconType, iconColor, text, help
}) => (
  <div className={styles.connectionBanner}>
    <i className={`fa ${iconType} mr5`} style={{ color: iconColor }} aria-hidden="true" />
    {text}
    {help && connectionType !== 'lms' && (
      <a href={helpUrl} className="ml3" target="_blank" rel="noopener noreferrer">Find out why.</a>
    )}
  </div>
);

BannerContent.propTypes = {
  connectionType: PropTypes.string,
  help: PropTypes.bool,
  iconColor: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

BannerContent.defaultProps = { connectionType: '', help: false };

export const CheckConnectionBanner = ({ connectionState, connectionType }) => {
  if (connectionState === 'checking') {
    return (
      <BannerContent
        iconType="fa-spinner fa-spin"
        iconColor="purple"
        text="Testing Connection..."
      />
    );
  }

  if (connectionState === 'connected') {
    return (
      <BannerContent
        iconType="fa-check"
        iconColor="green"
        text="Success! Please proceed to the next step."
      />
    );
  }

  if (connectionState === 'failed') {
    return (
      <BannerContent
        iconType="fa-times"
        iconColor="red"
        text="Connection was unsuccessful. Please update and try again."
        connectionType={connectionType}
        help
      />
    );
  }

  return null;
};

CheckConnectionBanner.defaultProps = { connectionType: '' };

CheckConnectionBanner.propTypes = {
  connectionState: PropTypes.string.isRequired,
  connectionType: PropTypes.string
};

export const footerCTA = (connectionState) => {
  if (!connectionState || connectionState === 'checking') {
    return 'Test Connection';
  }

  if (connectionState === 'failed') {
    return 'Test Again';
  }

  return null;
};

export const checkConnection = (subscriberId, params, endpoint = null) => {
  const providerConnectionPath = endpoint || Routes.plato_api_check_provider_connection_subscriber_path(subscriberId);

  return Axios.get(`${providerConnectionPath}?${params}`, { timeout: 8000 })
    .then(response => (response.data.result ? 'connected' : 'failed'))
    .catch((error) => {
      console.error(error);
      return 'failed';
    });
};
