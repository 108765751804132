define(['Zoom'], function(Zoom) {
  function SlideshowImage(parent) {
    this.parent = parent || document.body;
    this.border_size = 2;
    this.box_shadow_size = 14;
    this.attached_to_slide = (parent !== null && parent !== undefined);
  }

  SlideshowImage.prototype = {
    init: function(node, useZoomIcon = false) {
      this.node = node;
      this.slide = $(node).closest('.slide');
      this.setupListeners(this.node);
      if (useZoomIcon) this.appendZoomIcon();
      this.image_loaded = false;
    },

    setupListeners: function(node) {
      // We need to detect dragging so that we
      // don't trigger the popup unexpectedly
      let dragging = false;
      // we can exclude the drag exception so that other zooms are still working
      const excludeDrag = node.getAttribute('data-excludedrag') === 'true';
      if (!excludeDrag) {
        $(node).on('mousedown', function() {
          dragging = false;
          $(this).on('mousemove', function() {
            dragging = true;
          }.bind(this));
        });
      }

      $(node).on('click', () => {
        // check if the image is being edited in Froala before showing the popup.
        const editingInFroala = $(node).closest('div.fr-element')[0]?.contentEditable;

        if (!dragging && !editingInFroala) {
          this.showPopup();
          this.showOverlay();
        }
      });
    },

    // The size of the <img> element can differ from the size of the source image inside of it
    // This returns the total gap that surrounds the image
    getImageParentGap() {
      const naturalWidth = this.node.naturalWidth;
      const naturalHeight = this.node.naturalHeight;
      const renderedWidth = $(this.node)
        .width();
      const renderedHeight = $(this.node)
        .height();

      // Calculate the scaling factors
      const widthScalingFactor = renderedWidth / naturalWidth;
      const heightScalingFactor = renderedHeight / naturalHeight;

      // Use the smaller of the two scaling factors to keep the image aspect ratio
      const scalingFactor = Math.min(widthScalingFactor, heightScalingFactor);

      // Calculate the actual rendered content size
      const contentWidth = naturalWidth * scalingFactor;
      const contentHeight = naturalHeight * scalingFactor;

      // Calculate the gap between source image and the parent node
      const horizontalGap = (renderedWidth - contentWidth);
      const verticalGap = (renderedHeight - contentHeight);
      return {
        horizontalGap,
        verticalGap
      };
    },

    fitZoomIconToSourceImage(wrapper) {
      // Make a div match the source image's size to place the zoom icon inside
      const {
        horizontalGap,
        verticalGap
      } = this.getImageParentGap();
      const imageZoomIcon = document.createElement('div');
      const imageMarginBottom = parseInt($(this.node)
        .css('margin-bottom'), 10);
      const imageMarginRight = parseInt($(this.node)
        .css('margin-right'), 10);

      // Place the icon's wrapper in the bottom right corner of the image, excluding margins
      imageZoomIcon.classList.add('image-zoom-icon');
      imageZoomIcon.style.width = `${$(this.node)
        .innerWidth() - horizontalGap}px`;
      imageZoomIcon.style.height = `${$(this.node)
        .innerHeight() - verticalGap}px`;
      imageZoomIcon.style.bottom = `${(verticalGap / 2) + imageMarginBottom}px`;
      imageZoomIcon.style.right = `${(horizontalGap / 2) + imageMarginRight}px`;

      wrapper.appendChild(imageZoomIcon);
    },

    appendZoomIcon: function() {
      const wrapperClass = 'image-zoom-icon-wrapper';
      const wrapperNode = this.node.parentNode;
      if (wrapperNode.classList.contains(wrapperClass)) {
        const wrapperParentHeight = wrapperNode.parentNode.clientHeight;
        const wrapperParentWidth = wrapperNode.parentNode.clientWidth;
        const wrapperHeight = wrapperNode.clientHeight;
        const wrapperWidth = wrapperNode.clientWidth;

        if (wrapperParentWidth > wrapperWidth && wrapperParentHeight > wrapperHeight) {
          wrapperNode.style.height = '100%';
          this.node.style.maxHeight = '100%';
        }
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.classList.add(wrapperClass);

      // Insert the wrapper and move the <img> inside it
      this.node.parentNode.insertBefore(wrapper, this.node);
      wrapper.appendChild(this.node);

      const parentHeight = this.node.parentNode.clientHeight;
      if (parentHeight && parentHeight > 0) {
        this.node.style.maxHeight = `${parentHeight}px`;
      }

      // Apply certain styles of the image to its wrapper (for froala)
      const computedStyles = window.getComputedStyle(this.node);
      Array.from(computedStyles).forEach((property) => {
        if (['margin', 'float'].includes(property)) {
          wrapper.style[property] = computedStyles.getPropertyValue(property);
        }
      });

      this.fitZoomIconToSourceImage(wrapper);

      this.setupListeners(wrapper);
    },

    buildPopup: function() {
      if (!this.popup) {
        this.div_el = document.createElement('div');
        this.div_el.style.display = 'none';
        this.div_el.style.borderRadius = '4px';
        this.div_el.style.zIndex = '2000';
        this.div_el.className = 'slide_show_popup_div';
        if (!this.attached_to_slide) {
          this.div_el.style.position = 'absolute';
          this.div_el.style.backgroundColor = '#ffffff';
          this.div_el.style.border = '2px solid #ffffff';
          this.div_el.style.boxShadow = '2px 2px 4px 3px #333333';
          this.div_el.style.padding = '0';
        }
        this.buildImageContainer();
        this.buildCloseButton();
        this.parent.appendChild(this.div_el);
        this.setupCloseListeners();
      }
      return this.div_el;
    },

    buildCloseButton: function(){
        this.close_div = document.createElement('div');
        this.close_div.className = "modal_close tci_video_popup_close_js";
        this.close_div.innerHTML = "X";
        this.div_el.appendChild(this.close_div);
    },

    buildImageContainer: function(){
      this.image_container = document.createElement('div');
      this.image_container.className = 'zoom-image-container';
      this.div_el.appendChild(this.image_container);
    },

    loadImage: function() {
      if (!this.popup_image) {
        this.appendSpinner();
        this.popup_image = document.createElement('img');
        this.popup_image.crossOrigin = this.node.crossOrigin;
        this.popup_image.className = 'zoomed-image';
        this.popup_image.onload = () => {
          this.image_loaded = true;
          $(this.image_container).empty();
          $(this.spinner).remove();
          this.image_container.appendChild(this.popup_image);
          this.imageOriginalSize = { height: $(this.popup_image).height(), width: $(this.popup_image).width()};
          this.setPopupSize();
          this.setPopupPosition();
          this.createZoom();
        };
        this.popup_image.src = this.node.getAttribute('data-zoomurl');
      }
    },

    appendSpinner: function() {
      this.spinner = document.createElement('i');
      this.spinner.className = 'fa fa-spinner fa-spin fa-lg';
      this.spinner.style.padding = '10px';
      this.div_el.appendChild(this.spinner);
      this.setPopupPosition();
    },

    createZoom:function(){
      this.zoom = new Zoom.default({ image_box: this.image_container });
      this.zoom.init();
      this.zoom._createTools();

      return true;
    },

    setupCloseListeners: function() {
      const container = $('.slide_show_popup_div');
      $(document).on('mousedown', function(e) {
        if ($(this.popup).is(':visible') && !container.is(e.target) && container.has(e.target).length === 0) {
          this.closeModal();
        }
      }.bind(this));

      $(this.close_div).on('click', function(){
        this.closeModal();
      }.bind(this));

      $(document).on("fullscreenchange", function() {
        if (!document.fullscreen) {
          this.closePopup();
          this.closeOverlay();
        }
        this.restoreImageSize();
      }.bind(this));

      $(document).on("mozfullscreenchange", function() {
        if (!document.mozFullScreen) {
          this.closePopup();
          this.closeOverlay();
        }
        this.restoreImageSize();
      }.bind(this));

      $(document).on("webkitfullscreenchange", function() {
        if (!document.webkitIsFullScreen) {
          this.closePopup();
          this.closeOverlay();
        }
        this.restoreImageSize();
      }.bind(this));
    },

    closeModal: function() {
      this.closePopup();
      this.closeOverlay();
      if (this.zoom !== undefined) {
        this.zoom.reset();
      }
    },

    buildOverlay: function() {
      let $overlay;
      if (this.slide.length) {
        $overlay = $('<div class="slide_show_overlay"></div>');
        this.slide.append($overlay);
      }
      else {
        $overlay = $('.overlay_background');
      }

      $($overlay).on('click', function(e) {
        if (e.target === $overlay[0]) {
          this.closeModal();
        }
      }.bind(this));

      return $overlay[0];
    },

    showPopup: function() {
      if (!this.popup) this.popup = this.buildPopup();
      if (!this.overlay) this.overlay = this.buildOverlay();

      this.popup.style.display = 'block';
      this.loadImage();
      if (this.image_loaded) {
        this.setPopupSize();
        if (this.zoom !== undefined) {
          this.zoom.reset();
        }
        this.setPopupPosition();
      }
    },

    restoreImageSize: function(){
      const $image = $(this.popup_image);
      $image.outerHeight(this.imageOriginalSize.height);
      $image.outerWidth(this.imageOriginalSize.width);
    },

    closePopup: function() {
      if (this.popup) this.popup.style.display = 'none';
    },

    showOverlay: function() {
      this.overlay.classList.add('visible');
      this.overlay.style.display = 'block';
    },

    closeOverlay: function() {
      if (this.overlay) {
        this.overlay.classList.remove('visible');
        this.overlay.style.display = 'none';
      }
    },

    setPopupSize: function() {
      const $popup = $(this.popup);
      const $image = $(this.popup_image);

      const max_popup_width = this.parent.offsetWidth - 2*this.border_size - 2*this.box_shadow_size;
      const max_popup_height = this.parent.offsetHeight - 2*this.border_size - 2*this.box_shadow_size;

      const popup_margin = 2;


      // This could be organized better
      if (this.imageOriginalSize.height > max_popup_height){
            $image.outerHeight(max_popup_height - popup_margin);
            $image.css('width', 'auto');
          if ($image.outerWidth() > max_popup_width){
              $image.outerWidth(max_popup_width - popup_margin);
              $image.css('height', 'auto');
          }
          // check the original width and then check the new height to ensure that both are below maximum
        } else if (this.imageOriginalSize.width > max_popup_width){
          $image.outerWidth(max_popup_width - popup_margin);
          $image.css('height', 'auto');
          if ($image.outerHeight() > max_popup_height){
              $image.outerHeight(max_popup_height - popup_margin);
              $image.css('width', 'auto');
          }
      }

      $popup.height($image.outerHeight()+1);
      $popup.width($image.outerWidth());

    },

    setPopupPosition: function() {
      if (this.attached_to_slide) {
        this.popup.style.top = ((this.parent.offsetHeight - this.popup.offsetHeight) / 2) + "px";
        this.popup.style.left = ((this.parent.offsetWidth - this.popup.offsetWidth) / 2) + "px";
      }
      else {
        this.popup.style.top = ((this.parent.offsetHeight - this.popup.offsetHeight) / 2) + $(window).scrollTop() + "px";
        this.popup.style.left = ((this.parent.offsetWidth - this.popup.offsetWidth) / 2) + "px";
      }
    }
  };

  return SlideshowImage
});
