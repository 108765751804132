import React, { useState, useLayoutEffect } from 'react';
import SlideshowImage from 'modules/SlideshowImage';
import useFlags from '@/hooks/useFlags';
const { displaySlideGroupHeaders } = useFlags();

interface UseImageZoomProps {
  ref: React.MutableRefObject<HTMLDivElement>;
  skip: boolean;
}

const useImageZoom = ({ ref, skip }: UseImageZoomProps) => {
  const [slideshowImages, setSlideshowImages] = useState([]);
  let timeout;

  useLayoutEffect(() => {
    if (skip) return undefined;
    if (!ref || !ref.current) return undefined;

    clearTimeout(timeout);

    // Give time for slide to finish loading all content before initializing js.
    timeout = setTimeout(() => {
      ref.current.querySelectorAll('[data-zoomurl]').forEach((img) => {
        if (!slideshowImages.map(i => i.node).includes(img)) {
          const slideshowImage = new SlideshowImage();
          slideshowImage.init(img, displaySlideGroupHeaders);
          setSlideshowImages(prev => [...prev, slideshowImage]);
        }
      });
    }, 250);
    return () => slideshowImages.forEach(slideshowImage => slideshowImage.closeModal());
  }, [ref && ref.current && ref.current.querySelectorAll('[data-zoomurl]'), displaySlideGroupHeaders]);
};

export default useImageZoom;
