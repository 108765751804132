import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import Form from './Form';

export default class CreateNewTestButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  _openModal(event) {
    event.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <span>
        <button
          type="button"
          className="btn btn--green"
          aria-label={this.props.ariaLabel}
          disabled={this.props.disabled}
          onClick={this._openModal}
        >
          {this.props.buttonText}
        </button>

        <Modal
          closeModal={this._closeModal}
          isOpen={this.state.modalIsOpen}
          headerText="Create New Test"
        >
          <Form
            ajaxPath={this.props.ajaxPath}
            name={`${this.props.lessonTitle} Test`}
          />
        </Modal>
      </span>
    );
  }
}

CreateNewTestButton.propTypes = {
  ajaxPath: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  lessonTitle: PropTypes.string.isRequired
};

CreateNewTestButton.defaultProps = {
  ariaLabel: null,
  buttonText: 'Create New Test',
  disabled: false
};
