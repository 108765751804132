import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'common/Tooltip';
import styles from './DeleteButton.module.scss';

export function Button({ openModal, rowData, disabled }) {
  return (
    <button
      className={`dropdown-item ${styles['dropdown-button']}`}
      onClick={e => openModal(e, rowData, 'delete')}
      disabled={disabled}
    >
      <i aria-hidden="true" className="fa fa-trash" /> Delete
    </button>
  );
}

Button.propTypes = {
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  disabled: false
};

export default function DeleteButton({ openModal, rowData, numOfClassrooms }) {
  if (numOfClassrooms < 2) {
    return (
      <Tooltip
        content="To delete your last class in a program, remove the program from My Programs."
        placement="left"
        theme="white"
      >
        <div>
          <Button openModal={openModal} rowData={rowData} disabled />
        </div>
      </Tooltip>
    );
  }

  return (
    <Button openModal={openModal} rowData={rowData} />
  );
}

DeleteButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  numOfClassrooms: PropTypes.number.isRequired
};
