import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BuilderContext from 'common/Context';
import { useDrop } from 'react-dnd';
import { sortedDraggableChoicesByPosition } from './Utils';
import DraggableChoice from '../DraggableChoice';
import styles from './DraggableChoiceEditor.module.scss';
import ItemTypes from '../../ItemTypes';
import SectionHeader from '../SectionHeader';

const Container = ({ draggableChoices }) => {
  const { draggableChoiceDispatch, correctAnswerDispatch } = useContext(BuilderContext);
  const handleClick = () => draggableChoiceDispatch({ type: 'ADD' });

  const [{ highlighted }, drop] = useDrop(() => ({
    accept: [ItemTypes.draggableChoice],
    collect: (monitor) => {
      const item = monitor.getItem();
      const isFromDropZone = item && item.dropZoneId;

      return {
        canDrop: monitor.canDrop() && isFromDropZone,
        highlighted: monitor.isOver() && isFromDropZone,
        isOver: monitor.isOver() && isFromDropZone,
      };
    },
    drop: (item) => {
      const { dropZoneId, id, type } = item;
      if (dropZoneId) correctAnswerDispatch({ payload: { dropZoneId, id, type }, type: 'REMOVE' });
    },
  }), [draggableChoiceDispatch, correctAnswerDispatch]);

  const renderDraggableChoices = () => (
    sortedDraggableChoicesByPosition(draggableChoices).map(([key, draggableChoice]) => (
      <DraggableChoice
        key={key}
        id={key}
        hideDelete={Object.keys(draggableChoices).length === 1}
        {...draggableChoice}
      />
    )));

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    draggableChoiceDispatch({ type: 'ADD' });
  };

  return (
    <section
      className={`${styles.draggableChoicesContainer} ${highlighted && styles.highlighted}`}
      aria-label="Draggable choices"
      ref={drop}
    >
      <SectionHeader
        header="Set Correct Answers"
        subHeader="Drag the choices to the correct dropzones to create your answer key."
      />
      <div className={styles.choicesContainer}>
        {renderDraggableChoices()}
        <div
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
          aria-label="Add draggable choice"
          className={styles.draggableChoice}
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
        >
          <div className={styles.addChoice}>+</div>
        </div>
      </div>
    </section>
  );
};

Container.propTypes = {
  draggableChoices: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Container;
