import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'common/Utils';
import styles from './RuleBreakdown.module.scss';

const RuleList = ({
  rules, comparisonRules, diffColor, programId, comparisonProgramId, programData
}) => {
  const program = programData.find(currentProgram => currentProgram.id === programId);
  const programTitle = program && `[${program.code}] ${program.title}`;

  return (
    <Fragment>
      <div className="dsp-flex allow-list-style mt15 mb15">
        <div className={styles.label}>Program to Assign</div>
        <div
          className={programId === comparisonProgramId ? null : diffColor}
          dangerouslySetInnerHTML={{ __html: programTitle }}
        />
      </div>
      <div className="dsp-flex allow-list-style">
        <div className={styles.label}>Filters</div>
        <ul className="lh1_3">
          {rules.map((rule) => {
            const colorClass = comparisonRules.includes(rule) ? null : diffColor;
            return <li className={colorClass}>{capitalize(rule)}</li>;
          })}
        </ul>
      </div>
    </Fragment>
  );
};

RuleList.propTypes = {
  comparisonProgramId: PropTypes.number.isRequired,
  comparisonRules: PropTypes.arrayOf(PropTypes.string).isRequired,
  diffColor: PropTypes.string.isRequired,
  programData: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string
  })).isRequired,
  programId: PropTypes.number.isRequired,
  rules: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RuleList;
