define(['TCIUtils'], function(TCIUtils) {
  /**
   * This is used to set the checkbox states for the collapsible lesson/program selector.
   * See views/admin/_program_lessons_list.html.erb for an example of proper HTML structure.
   *
   */

  function CollapsibleDropdown(config) {
    this.area = config.area;
  }

  CollapsibleDropdown.prototype = {
    init: function () {
      this._setupListeners();
      this._traverseList();
    },

    _setupListeners: function () {
      $(this.area).on('click', function (e) {
        if (e.target.tagName === 'I') {
          $(e.target).parent().toggleClass('expanded');
          $(e.target).parent().next('ul').slideToggle();
        }
      });

      $(this.area).find('input').on('change', function (e) {
        this._traverseList();
      }.bind(this));
    },

    _traverseList: function () {
      var $leaves = $(this.area).find('.parent.leaf').filter(function () {
        return $(this).find('input').length > 0
      });
      this._travelUpTree($leaves);
    },

    _travelUpTree: function ($elements) {
      $elements.each(function (index, object) {
        var $checkboxes = $(object).children('li').find('input');
        var $checked = $(object).children('li').find('input:checked');
        var $indeterminate = $(object).find('input:indeterminate');
        var $selectAll = $(object).prev('li').find('.select_all');
        TCIUtils.setSelectAllStates($selectAll, $checked, $checkboxes, $indeterminate);
      }.bind(this));

      var $parents = $elements.parent().siblings('ul').addBack();
      if (!$parents.hasClass('top')) this._travelUpTree($parents);
    }
  };

  return CollapsibleDropdown
});
