import axios, { CancelTokenSource } from 'axios';
import * as Routes from '@/modules/routes';
import { userInfo } from '@/components/common/libraries/actions';
import {
  FilterField,
  FilterParams,
  VideoDisplayProps,
  VideoFilter,
  VideoInfo,
} from './types';

export const initialFilters: VideoFilter = {
  included_in: [],
  category: [],
  status: [
    { label: 'Published', value: 'published', checked: false },
    { label: 'Unpublished', value: 'unpublished', checked: false },
  ],
  keyword: '',
  page: 1,
  per_page: 24,
};

export const hideIncludedIns = [
  'Assessment',
];

let filterCancelToken: CancelTokenSource;

/* eslint-disable no-param-reassign */

export const getFilteredVideos = (filters: VideoFilter, programId: string | number) => {
  if (filterCancelToken !== undefined) {
    filterCancelToken.cancel('Operation canceled due to new request.');
  }

  filterCancelToken = axios.CancelToken.source();

  const params = {
    'search[program_id_eq]': programId,
    'search[lesson_ids_in]': [],
    'search[tag_ids_in]': [],
    'search[title_en_cont]': filters.keyword.length
      ? filters.keyword
      : undefined,
    page: filters.page,
    per_page: filters.per_page,
    column: 'title_en',
    order: 'asc',
  } as FilterParams;

  filters.unit?.forEach((unitFilers) => {
    const checkedLessons = unitFilers.lessons
      .filter(lessonFilter => lessonFilter.checked)
      .map(lessonFilter => lessonFilter.value);

    params['search[lesson_ids_in]'].push(...checkedLessons);
  });

  filters.lesson?.forEach((lessonFilter) => {
    if (lessonFilter.checked) {
      params['search[lesson_ids_in]'].push(lessonFilter.value);
    }
  });

  filters.included_in.forEach((includedFilter) => {
    if (includedFilter.checked) {
      params[`search[${includedFilter.value}]`] = true;
    }
  });

  filters.category
    .filter(categoryFilter => categoryFilter.checked)
    .forEach((categoryFilter) => {
      params['search[tag_ids_in]'].push(categoryFilter.value);
    });

  if (userInfo.student) {
    params['search[published_eq]'] = true;
  } else {
    if (filters.status.some(statusFilter => statusFilter.checked && statusFilter.value === 'published')) {
      params['search[published_eq]'] = true;
    }

    if (filters.status.some(statusFilter => statusFilter.checked && statusFilter.value === 'unpublished')) {
      if (params['search[published_eq]'] === true) {
        params['search[published_eq]'] = undefined;
      } else {
        params['search[published_eq]'] = false;
      }
    }
  }

  return axios
    .get(Routes.plato_api_videos_path(), {
      params,
      cancelToken: filterCancelToken.token,
    })
    .then(res => ({
      data: res.data.data,
      count: res.data.meta.count,
    }));
};

export const getFilters = (programId: string | number) => {
  const sortFilter = (filterA: FilterField, filterB: FilterField) => (
    filterA.label
      .toLowerCase()
      .localeCompare(filterB.label.toLocaleLowerCase())
  );

  return axios
    .get<VideoFilter>(Routes.plato_api_filter_categories_videos_path({ program_id: programId }))
    .then((res) => {
      const filters = { ...initialFilters };

      const savedFilters = JSON.parse(
        localStorage.getItem('video-filters') ?? JSON.stringify(initialFilters),
      ) as VideoFilter;

      filters.unit = res.data.unit?.map((unit) => {
        const label = Object.keys(unit)[0];
        const lessons = Object.values(unit)[0].map(
          (lessonFilter: FilterField) => {
            const checked = savedFilters.unit?.some(unitFilter => (
              unitFilter.lessons.find(savedLesson => (
                savedLesson.value === lessonFilter.value && savedLesson.checked
              ))
            ));
            return { ...lessonFilter, checked };
          },
        );

        return { label, lessons };
      });

      filters.lesson = res.data.lesson?.map((lessonFilter) => {
        lessonFilter.checked = savedFilters.lesson?.some(
          savedLesson => (
            savedLesson.value === lessonFilter.value && savedLesson.checked
          ),
        );
        return lessonFilter;
      });

      filters.included_in = res.data.included_in
        .filter(includedFilter => !hideIncludedIns.includes(includedFilter.label))
        .map((includedFilter) => {
          const checked = savedFilters.included_in.some(
            savedIncluded => (savedIncluded.value === includedFilter.value && savedIncluded.checked),
          );
          return { ...includedFilter, checked };
        })
        .sort(sortFilter);

      filters.status = filters.status.map((statusFilter) => {
        const checked = savedFilters.status.some(
          savedStatus => (savedStatus.value === statusFilter.value && savedStatus.checked),
        );
        return { ...statusFilter, checked };
      });

      return filters;
    });
};

export const publishVideo = (videoId: string | number) => (
  axios.post<{ data: VideoInfo }>(Routes.plato_api_publish_video_path(videoId))
);

export const unpublishVideo = (videoId: string | number) => (
  axios.post<{ data: VideoInfo }>(Routes.plato_api_unpublish_video_path(videoId))
);

export const getVideoDisplayProps = (videoId: string | number) => (
  axios.get<{ video: { display_props: VideoDisplayProps } }>(
    Routes.plato_api_video_path(videoId),
    { params: { only: ['id'], extra_attributes: ['display_props'] } },
  )
);
