import React, { Fragment, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Modal, { Footer } from 'common/Modal';
import { DateField } from 'common/Forms';
import { required } from 'common/Forms/Validators';
import { Form } from 'react-final-form';
import styles from './SchedulePause.module.scss';
import { validateDates } from './Validations';

const Index = ({
  handlePauseSubmit, open, close, isOpen, pauseStartDate, pauseStopDate, editing
}) => {
  const secondaryAction = () => {
    if (!pauseStartDate) return close();

    const values = { pause_start_date: '', pause_stop_date: '' };
    return handlePauseSubmit(values, 'Your scheduled pause has been successfully unscheduled.');
  };

  const renderButton = () => {
    if (editing) {
      return (
        <button
          type="button"
          className="btn btn--link-purple"
          onClick={open}
        >
          <i aria-hidden="true" className="fa fa-pencil-square-o" /> Edit
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn--sm btn--outline-purple ml5"
        onClick={open}
      >
        <i aria-hidden="true" className="fa fa-clock-o" />
        {' '}
        Schedule a Pause
      </button>
    );
  };

  return (
    <Fragment>
      {renderButton()}
      <Modal isOpen={isOpen} closeModal={close} headerText="Schedule a Pause" className={styles.modalBody}>
        <Form
          initialValues={{
            pause_start_date: pauseStartDate && moment(pauseStartDate, 'YYYY-MM-DD').toDate(),
            pause_stop_date: pauseStopDate && moment(pauseStopDate, 'YYYY-MM-DD').toDate()
          }}
          onSubmit={handlePauseSubmit}
          validate={validateDates}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <p>
                A scheduled sync pause allows you to preserve your most recent sync in TCI indefinitely. While your sync
                is paused, TCI will not process any new rosters for the duration of your pause.
              </p>
              <ul>
                <li>
                  To schedule a pause and have syncs resume on a certain date, please fill out both dates.
                </li>
                <li>
                  To schedule an indefinite pause, please leave the “Pause Stop Date” blank.
                </li>
              </ul>
              <DateField
                name="pause_start_date"
                required
                validate={required}
                minDate={moment().toDate()}
              />
              <DateField
                name="pause_stop_date"
                minDate={moment().toDate()}
              />
              <Confirmation values={values} />
              <Footer
                secondaryButtonCallback={secondaryAction}
                secondaryButtonText={pauseStartDate ? 'Unschedule' : 'Close'}
              />
            </form>
          )}
        />
      </Modal>
    </Fragment>
  );
};

Index.propTypes = {
  close: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  handlePauseSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  pauseStartDate: PropTypes.string,
  pauseStopDate: PropTypes.string
};

Index.defaultProps = {
  editing: false,
  pauseStartDate: undefined,
  pauseStopDate: undefined
};

export default Index;

const Confirmation = ({ values }) => {
  if (!values.pause_start_date) return null;
  const startDate = moment(values.pause_start_date).format('MMMM D, YYYY');

  if (!values.pause_stop_date) {
    return (
      <p className="mt10 gray">
        Your roster sync will pause indefinitely on <span className="black">{startDate}</span>
      </p>
    );
  }
  const stopDate = moment(values.pause_stop_date).format('MMMM D, YYYY');

  return (
    <p className="mt10 gray">
      Your roster sync will pause on&nbsp;
      <span className="black">{startDate}</span>&nbsp;
      and resume on&nbsp;
      <span className="black">{stopDate}</span>.
    </p>
  );
};

Confirmation.propTypes = {
  values: PropTypes.shape({
    pause_start_date: PropTypes.instanceOf(moment),
    pause_stop_date: PropTypes.instanceOf(moment)
  }).isRequired
};
