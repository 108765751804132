import { useEffect } from 'react';
import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IStudent } from '../../types';
import useGradesTableStore from '../../store/useGradesTableStore';
import { QueryKeys } from '../../GradesTableQueryClientProvider';

const byFirstAndLastName = (s1: IStudent, s2: IStudent) => {
  const lastNameCompare = s1.last_name.localeCompare(s2.last_name);

  if (lastNameCompare) return lastNameCompare;

  return s1.first_name.localeCompare(s2.first_name);
};

const useStudents = (classroomId: number) => {
  const setStore = useGradesTableStore(state => state.setStore);

  const query = useQuery([QueryKeys.Students, classroomId], () => {
    const path = Routes.plato_api_classroom_students_path(classroomId, {
      only: ['id', 'first_name', 'last_name'],
    });

    return axios
      .get<{ data: IStudent[] }>(path)
      .then(res => res.data.data.sort(byFirstAndLastName))
      .catch(err => console.error(err));
  }, { enabled: !!classroomId, placeholderData: [] });

  useEffect(() => {
    setStore({ students: query.data || [] });
  }, [query.data]);

  return { ...query, data: query.data || [] };
};

export default useStudents;
