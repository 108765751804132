import React, { useState, useEffect } from 'react';
import * as Routes from 'modules/routes';
import I18n from 'i18n-js';
import Axios from 'axios';
import moment from 'moment';
import gradesIcon from '@images/icons/bar-outline.svg';
import Section from '../Section/index';
import NotificationsByDate from '../../Dashboard/Cards/StudentWorkCard/NotificationsByDate';
import { capitalizeWords } from 'modules/TCIUtils';
import { NotificationByDateType } from '../../Dashboard/Cards/StudentWorkCard/types';

interface StudentWorkSectionProps {
  lessonId: number;
  programId: number;
}

const StudentWorkSection = ({ lessonId, programId }: StudentWorkSectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationsByDate, setNotificationsByDate] = useState<NotificationByDateType[]>([]);
  const [error, setError] = useState();

  useEffect(() => {
    const getPath = Routes.plato_api_staffer_notifications_path({
      program_id: programId,
      time_zone: moment.tz.guess(),
    });

    setIsLoading(true);
    Axios
      .get<{ dates: NotificationByDateType[] }>(getPath)
      .then((response) => {
        setNotificationsByDate(response.data.dates);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }, []);

  const renderMessage = (message) => (
    <p className="tw-text-[18px] tw-flex tw-items-center">
      {message}
    </p>
  );

  const renderStudentNotifications = () => {
    if (isLoading) return null;
    if (error) return renderMessage('Refresh the page to load notifications.');
    if (!notificationsByDate.length) return renderMessage(I18n.t('no_student_work_text'));

    return (
      <div className="tw-flex tw-flex-col tw-justify-center tw-w-full tw-h-full">
        {notificationsByDate.map((date) => {
          const notificationIds = date.notifications.map(n => `${n.type}${n.id}`).join(',');

          return (
            <NotificationsByDate
              key={`${date.date_formatted}-${notificationIds}`}
              date={date}
              programId={programId}
            />
          );
        })}
      </div>
    );
  };

  const viewGradesButton = () => {
    if (notificationsByDate.length) return null;

    return (
      <a
        className="btn btn--sm btn--outline-purple tw-font-normal"
        href={Routes.teacher_program_scores_path(programId, { lesson_id: lessonId })}
      >
        {capitalizeWords(I18n.t('view_grades'))}
      </a>
    );
  };


  return (
    <Section
      headerColorClass="tw-bg-teal-blue"
      headerIcon={<img className="tw-w-[18px] tw-mr-2" alt="Student Work" src={gradesIcon} />}
      headerLink={viewGradesButton()}
      headerTitle={I18n.t('student_work')}
    >
      {renderStudentNotifications()}
    </Section>
  );
};

export default StudentWorkSection;
