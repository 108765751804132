import React, { useEffect } from 'react';
import Axios, { AxiosError } from 'axios';
import { useSearchParams, useParams } from 'react-router-dom';
import * as Routes from '@/modules/routes';
import SearchBar from './SearchBar';
import Filters from './Filters';
import SearchResults from './SearchResults';
import TotalResults from './TotalResults';
import { ISearchResults, SearchFilterType } from './types';
import useSearchStore from './useSearchStore';

const createOption = (label: string) => ({ label, value: label });
const createFilter = (label: string, checked = false) => ({ label, value: label, checked });

const Search = () => {
  const { programId } = useParams();
  const filters = useSearchStore(state => state.filters);
  const setFilters = useSearchStore(state => state.setFilters);
  const setExclusions = useSearchStore(state => state.setExclusions);
  const setShowAdvanced = useSearchStore(state => state.setShowAdvanced);
  const [searchParams] = useSearchParams();
  const setError = useSearchStore(state => state.setError);
  const setIsLoading = useSearchStore(state => state.setIsLoading);
  const cancelTokenSource = useSearchStore(state => state.cancelTokenSource);
  const setResults = useSearchStore(state => state.setResults);
  const setNumResults = useSearchStore(state => state.setNumResults);
  const setSuggestions = useSearchStore(state => state.setSuggestions);

  const setPageTitle = () => {
    document.title = `Search Results for '${searchParams.get('term')}' - TeachTCI`;
  };

  const getSearchResults = ({ query, exclude, location, queryFilters }) => {
    setError(false);
    setIsLoading(true);

    Axios.post<ISearchResults>(
      Routes.plato_api_search_path({
        query,
        exclude,
        program_id: programId,
        filters: queryFilters,
        location,
      }),
      {},
      { cancelToken: cancelTokenSource.token },
    ).then((response) => {
      setPageTitle();
      setResults(response?.data?.results);
      setNumResults(response?.data?.numResults);
      setSuggestions(response?.data?.suggestions);
      setIsLoading(false);
    }).catch((err: AxiosError) => {
      if (err.message === 'Canceled') return;
      setError(true);
      setIsLoading(false);
      console.error(err);
    });
  };

  const newFilters = (filterType: SearchFilterType) => filters[filterType].map(filter => (
    searchParams.get(filterType)?.split(',')?.includes(filter.value) ? { ...filter, checked: true } : filter
  ));

  const parseFilter = (filterType: SearchFilterType) => searchParams.get(filterType)?.split(',')?.map(filter => createFilter(filter, true));

  useEffect(() => {
    const newReadingFilters = newFilters('reading');
    const newReferenceMaterialFilters = newFilters('reference_material');
    const newImageFilters = newFilters('image');
    const newLanguageFilters = newFilters('language');
    const newSlideShowFilters = newFilters('slide_show');

    if (newReadingFilters || newReferenceMaterialFilters || newImageFilters || newSlideShowFilters) {
      setFilters({
        ...filters,
        reading: newReadingFilters,
        reference_material: newReferenceMaterialFilters,
        image: newImageFilters,
        language: newLanguageFilters,
        slide_show: newSlideShowFilters,
      });
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get('term')) return;

    const parsedExclusions = searchParams.get('exclude')?.split(',')?.map(createOption);
    const parsedReadingFilters = parseFilter('reading');
    const parsedReferenceMaterialFilters = parseFilter('reference_material');
    const parsedImageFilters = parseFilter('image');
    const parsedLanguageFilters = parseFilter('language');
    const parsedLocation = searchParams.get('location');
    const parsedSlideShowFilters = parseFilter('slide_show');

    if (parsedExclusions) {
      setExclusions(parsedExclusions);
    }

    if (parsedExclusions || parsedLocation) {
      setShowAdvanced(true);
    }

    if (searchParams.toString()) {
      getSearchResults({
        query: searchParams.get('term'),
        exclude: parsedExclusions,
        location: searchParams.get('location'),
        queryFilters: {
          image: parsedImageFilters,
          language: parsedLanguageFilters,
          reading: parsedReadingFilters,
          reference_material: parsedReferenceMaterialFilters,
          slide_show: parsedSlideShowFilters,
        },
      });
    }
  }, [searchParams.toString()]);

  return (
    <>
      <h2>Search Results</h2>
      <SearchBar />
      <hr className="tw-mt-5" />
      <TotalResults />
      <div className="tw-flex">
        <Filters />
        <SearchResults />
      </div>
    </>
  );
};

export default Search;
