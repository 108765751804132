import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';
import styles from './NavigationCard.module.scss';
import Tooltip from '../Tooltip';
import libraries from './libraries';

const LibraryLink = ({ className, comingSoon, icon, text, tooltip, url, programId, lessonId }) => (
  <Tooltip content={tooltip} interactive shouldDisplay={!!tooltip}>
    <a className={`${styles.libraryLink} ${comingSoon && styles.comingSoon}`} href={url(programId, lessonId)}>
      <div className={`${styles.icon} ${className} ${comingSoon && styles.comingSoon}`}>
        <img src={icon} alt={text} />
      </div>
      <p>{I18n.t(text)}</p>
    </a>
  </Tooltip>
);

LibraryLink.defaultProps = {
  comingSoon: false,
  tooltip: null,
};

LibraryLink.propTypes = {
  className: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  lessonId: PropTypes.number,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  url: PropTypes.func.isRequired,
};

const LibraryLinks = ({ programId, lessonId, unitId, civicsLibraryEnabled, primarySourceLibraryEnabled }) => (
  <div className={styles.libraryLinks}>
    {libraries(
      unitId,
      civicsLibraryEnabled,
      primarySourceLibraryEnabled
    ).map(
      library => (<LibraryLink key={library.text} {...library} programId={programId} lessonId={lessonId} />)
    )}
  </div>
);

LibraryLinks.propTypes = {
  civicsLibraryEnabled: PropTypes.bool.isRequired,
  lessonId: PropTypes.number,
  primarySourceLibraryEnabled: PropTypes.bool.isRequired,
  programId: PropTypes.number.isRequired,
  unitId: PropTypes.number,
};

LibraryLinks.defaultProps = {
  unitId: null,
};

export default LibraryLinks;
