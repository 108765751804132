import React, { useState } from 'react';
import { t } from 'i18n-js';
import Tooltip from '@/components/common/Tooltip';
import videoIcon from 'images/icons/video-camera.svg';
import Card from '@/components/ui/Card';
import IReferenceMaterial from '@/components/interfaces/ReferenceMaterial';
import AssignButton from '@/components/shared/LessonOverview/Cards/AssignButton';
import { getNameFromTitle } from './utils';

interface ReferenceMaterialCardProps {
  currentStafferId?: number;
  programId: number;
  referenceMaterial: IReferenceMaterial;
  lessonId: number;
}

const ReferenceMaterialCard = ({
  currentStafferId, programId, referenceMaterial, lessonId
}: ReferenceMaterialCardProps) => {
  const [isAssigned, setIsAssigned] = useState(false);
  const link = referenceMaterial.link.replace('program_id', programId.toString());

  return (
    <Card className="tw-flex tw-flex-col tw-justify-between tw-min-w-0 tw-w-60">
      <Card.Image
        alt={referenceMaterial.title}
        src={referenceMaterial.medium_image}
        to={link}
        toText={`Navigate to reference page for ${referenceMaterial.title}`}
      />
      <Card.Body className="tw-h-full tw-min-h-0 tw-flex tw-flex-col tw-justify-between">
        <>
          <div className="tw-flex tw-justify-between">
            <div>
              <a className="tw-font-bold tw-text-base" href={link}>
                {getNameFromTitle(referenceMaterial.title)}
              </a>
              <p className="tw-mt-4 tw-text-gray-500">{referenceMaterial.date_range}</p>
            </div>
            {referenceMaterial.has_video && (
              <div className="tw-justify-self-end">
                <Tooltip content={t('video_included')}>
                  <img src={videoIcon} alt="Video icon" className="tw-max-w-sm" />
                </Tooltip>
              </div>
            )}
          </div>
          <div className="tw-pt-3">
            {currentStafferId
              && (
                <AssignButton
                  handlePostSave={() => setIsAssigned(true)}
                  initialValues={{
                    modelType: referenceMaterial.assignable_type,
                    modelsToAssign: referenceMaterial.model_to_assign,
                  }}
                  lessonId={lessonId}
                  program={{ id: programId }}
                  stafferId={currentStafferId}
                  isAssigned={referenceMaterial.is_assigned || isAssigned}
                />
              )
            }
          </div>
        </>
      </Card.Body>
    </Card>
  );
};

export default ReferenceMaterialCard;
