import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaConfig from 'froala/FroalaConfig';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'Vendor/froala/plugins/file';
import { useDebounceCallback } from 'usehooks-ts';

const FroalaEditor = ({
  allowFileUploads,
  allowHTML,
  baseUrl,
  customConfig,
  events,
  fileUploadParam,
  imageUploadParam,
  htmlName,
  livePreview,
  onModelChange,
  tag,
  text,
  textOnly
}) => {
  const config = FroalaConfig({
    allowFileUploads,
    allowHTML,
    baseUrl,
    customConfig,
    events,
    fileUploadParam,
    imageUploadParam,
    livePreview,
    textOnly
  });

  const [model, setModel] = useState(text);
  const debouncedSetModel = useDebounceCallback(setModel, 800);

  const editorRef = useRef();

  useEffect(() => debouncedSetModel(text), [text]);

  useEffect(() => {
    const element = editorRef && editorRef.current && editorRef.current.el;
    if (!element) return;

    element.setAttribute('name', htmlName);
  }, [editorRef, editorRef.current, editorRef.current?.el]);

  const handleModelChange = (newModel) => {
    setModel(newModel);
    if (onModelChange) onModelChange(newModel);
  };
  return (
    <FroalaEditorComponent
      config={config}
      model={model}
      onModelChange={handleModelChange}
      tag={tag}
      ref={editorRef}
    />
  );
};

FroalaEditor.propTypes = {
  allowFileUploads: PropTypes.bool,
  allowHTML: PropTypes.bool,
  baseUrl: PropTypes.string,
  customConfig: PropTypes.shape({}),
  events: PropTypes.objectOf(PropTypes.func),
  fileUploadParam: PropTypes.string,
  htmlName: PropTypes.string,
  imageUploadParam: PropTypes.string,
  livePreview: PropTypes.bool,
  onModelChange: PropTypes.func,
  tag: PropTypes.string,
  text: PropTypes.string,
  textOnly: PropTypes.bool
};

FroalaEditor.defaultProps = {
  allowFileUploads: false,
  allowHTML: false,
  baseUrl: '',
  customConfig: {},
  events: {},
  fileUploadParam: '',
  htmlName: '',
  imageUploadParam: '',
  livePreview: false,
  onModelChange: null,
  tag: 'textarea',
  text: '',
  textOnly: false
};

export default FroalaEditor;
