import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { diff } from 'deep-object-diff';
import PropTypes from 'prop-types';

class AutoSave extends Component {
  static propTypes = {
    allowBlanks: PropTypes.bool,
    debounce: PropTypes.number,
    forFilters: PropTypes.bool,
    initialValues: PropTypes.shape({
      answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array]),
      question_id: PropTypes.number,
      teacher_comment: PropTypes.string
    }).isRequired,
    save: PropTypes.func.isRequired,
    values: PropTypes.shape({
      answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array]),
      question_id: PropTypes.number,
      teacher_comment: PropTypes.string
    }).isRequired
  };

  static defaultProps = {
    allowBlanks: false,
    debounce: 800,
    forFilters: false
  };

  constructor(props) {
    super(props);
    this.state = { values: props.values };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.forFilters) {
      // we do not want to update the component when it's:
      // for a filter and
      // the filter values haven't changed
      // because it causes the debounce to start all over again
      // preventing filters from loading until the table is finished updating
      // or the debounce finishes before the parent table updates
      return this.props.values !== nextProps.values;
    }

    // shouldComponentUpdate defaults to true in the super case
    return true;
  }

  componentDidUpdate() {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(this.handleSave, this.props.debounce);
  }

  handleSave = async () => {
    if (this.promise) await this.promise;

    const { save } = this.props;
    const values = this.valuesToCompare();

    const difference = diff(this.state.values, values);
    if (Object.keys(difference).length) {
      // values have changed
      this.setState({ values });
      this.promise = save(values);
      await this.promise;
      delete this.promise;
    }
  };

  valuesToCompare() {
    const { values, initialValues } = this.props;

    if (!this.props.allowBlanks) return values;

    return Object.keys(initialValues).reduce((acc, key) => {
      acc[key] = typeof values[key] === 'undefined' ? '' : values[key];
      return acc;
    }, {});
  }

  render() {
    return null;
  }
}

export default props => (
  <FormSpy {...props} subscription={{ initialValues: true, values: true }} component={AutoSave} />
);
