import React, { useEffect, useState } from 'react';
import Modal, { Footer } from '@/components/common/Modal';
import VideoPlayer from '@/components/common/VideoPlayer';
import { getVideoDisplayProps } from '@/components/common/libraries/VideoLibrary/actions';
import { VideoDisplayProps, VideoInfo } from '@/components/common/libraries/VideoLibrary/types';
import Status from '@/components/common/libraries/common/Status';
import { Color } from '@/components/common/libraries/types';

import styles from './VideoPlayerModal.module.scss';

interface Props {
  visibility: boolean;
  videoInfo?: VideoInfo;
  showPublish: boolean;
  onClose: () => void;
  onPublish: (id: string | number | undefined, title: string | undefined) => void;
  onUnpublish: (id: string | number | undefined, title: string | undefined) => void;
}

const VideoPlayerModal = ({
  visibility,
  onClose,
  videoInfo,
  showPublish,
  onPublish,
  onUnpublish,
}: Props) => {
  const [videoDisplayProps, setVideoDisplayProps] = useState<VideoDisplayProps>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (videoInfo?.id) {
      setIsLoading(true);
      getVideoDisplayProps(videoInfo.id).then(({ data: { video } }) => {
        setVideoDisplayProps(video.display_props);
        setIsLoading(false);
      });
    }
  }, [videoInfo?.id]);

  const handleClose = () => {
    setVideoDisplayProps(undefined);
    onClose();
  };

  const handlePublishClick = () => {
    if (!videoInfo) {
      return;
    }

    if (videoInfo?.published) {
      onUnpublish(videoInfo.id, videoInfo.title);
    } else {
      onPublish(videoInfo.id, videoInfo.title);
    }
  };

  // @ts-ignore
  const videoWithDisplayProps: VideoDisplayProps = { ...videoInfo, ...videoDisplayProps };

  return (
    <Modal
      closeModal={handleClose}
      headerText={videoInfo?.title_en}
      bodyClassName="tw-max-h-[70vh]"
      className="tw-w-[900px]"
      isOpen={visibility}
      isLoading={isLoading}
      shouldReturnFocusAfterClose
      preventScroll
      shouldCloseOnEsc
      shouldFocusAfterRender
      footer={(
        <Footer
          className="tw-px-5 tw-pb-5"
          displaySubmitButton={false}
        >
          <button
            className="btn btn--link-purple"
            onClick={handleClose}
            type="button"
          >
            Cancel
          </button>
          {showPublish && (
            <button
              className="btn btn--outline-purple"
              onClick={handlePublishClick}
              type="button"
            >
              {videoInfo?.published ? (
                <>
                  <Status borderColor={Color.GREEN} checked /> Published
                </>
              ) : (
                <>
                  <Status /> Publish
                </>
              )}
            </button>
          )}
        </Footer>
      )}
    >
      <div className={styles.modalContent}>
        <div className={styles.content}>
          {videoInfo && <VideoPlayer video={videoWithDisplayProps} width="100%" />}
        </div>
      </div>
    </Modal>
  );
};

export default VideoPlayerModal;
