import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import I18n from 'i18n-js';
import moment from 'moment-timezone';
import styles from './PostGradeButton.module.scss';
import Modal, { Footer } from '../../../common/Modal';
import ActionsDropdown from '../../../common/ActionsDropdown';
import { capitalize } from '../../../common/Utils';
import * as Routes from 'routes';

export default class PostGradeButton extends Component {
  static propTypes = {
    alreadyGraded: PropTypes.bool,
    clearWorkPath: PropTypes.string.isRequired,
    exerciseResult: PropTypes.shape({ id: PropTypes.string, score: PropTypes.string }),
    exerciseType: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    programId: PropTypes.number.isRequired,
    postGradePath: PropTypes.string.isRequired,
    readyToSubmit: PropTypes.bool,
    regradeStudentPath: PropTypes.string.isRequired,
  };

  static defaultProps = {
    alreadyGraded: false,
    readyToSubmit: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      modalError: false,
      submitting: false,
      clearWorkModalOpen: false,
      regradeModalOpen: false
    };

    this._toggleMenu = this._toggleMenu.bind(this);
    this._postGrade = this._postGrade.bind(this);
    this._openClearWorkModal = this._openClearWorkModal.bind(this);
    this._closeClearWorkModal = this._closeClearWorkModal.bind(this);
    this._clearWork = this._clearWork.bind(this);
    this._openRegradeModal = this._openRegradeModal.bind(this);
    this._closeRegradeModal = this._closeRegradeModal.bind(this);
    this._regrade = this._regrade.bind(this);

    document.addEventListener('mousedown', this._handleOutsideClick.bind(this));
  }

  _handleOutsideClick(e) {
    if (!this.node.contains(e.target)) this.setState({ expanded: false });
  }

  _renderPostGradeButton() {
    return (
      <button
        id="post-grade-button"
        onClick={this._postGrade}
        className={`btn btn--purple ${styles.postButton}`}
        disabled={!this.props.readyToSubmit}
      >
        Post Score
      </button>
    );
  }

  _renderRegradeButton() {
    return (
      <button
        id="regrade-button"
        onClick={this._openRegradeModal}
        className={`btn btn--purple ${styles.postButton}`}
      >
        Edit Score
      </button>
    );
  }

  _renderCaret() {
    return (
      <button
        id="menu-caret"
        className={`btn btn--purple ${styles.caret}`}
        onClick={this._toggleMenu}
      >
        <i className="fa fa-caret-down" />
      </button>
    );
  }

  _toggleMenu() {
    this.setState({ expanded: !this.state.expanded });
  }

  _postGrade(e) {
    e.preventDefault();
    this.setState({ submitting: true });

    return Axios.put(this.props.postGradePath).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      this.setState({ modalError: true });
    });
  }

  _renderClearWorkModal() {
    return (
      <Modal
        className="clear-work-modal"
        isOpen={this.state.clearWorkModalOpen}
        headerText="Clear Student Work"
        closeModal={this._closeClearWorkModal}
        error={this.state.modalError}
      >
        Are you sure? This student’s assessment answers will be permanently deleted.
        <form onSubmit={this._clearWork}>
          <Footer
            secondaryButtonCallback={this._closeClearWorkModal}
            submitting={this.state.submitting}
          />
        </form>
      </Modal>
    );
  }

  _openClearWorkModal(e) {
    if (e.keyCode && e.keyCode !== 13) return;
    this.setState({ clearWorkModalOpen: true, expanded: false });
  }

  _closeClearWorkModal() {
    this.setState({ clearWorkModalOpen: false });
  }

  _clearWork(e) {
    e.preventDefault();
    this.setState({ submitting: true });

    return Axios.delete(this.props.clearWorkPath).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      this.setState({ modalError: true });
    });
  }

  _openRegradeModal() {
    this.setState({ regradeModalOpen: true });
  }

  _closeRegradeModal() {
    this.setState({ regradeModalOpen: false });
  }

  _regrade(e) {
    e.preventDefault();
    this.setState({ submitting: true });

    return Axios.post(this.props.regradeStudentPath).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      this.setState({ modalError: true });
    });
  }

  _renderRegradeModal() {
    return (
      <Modal
        className="regrade-modal"
        isOpen={this.state.regradeModalOpen}
        headerText="Edit Score"
        closeModal={this._closeRegradeModal}
        error={this.state.modalError}
      >
        <p>Are you sure you would like to edit this student’s scores? The previous total score will be unavailable to the student and you will need to post the modified score again.</p>
        <form onSubmit={this._regrade}>
          <Footer
            secondaryButtonCallback={this._closeRegradeModal}
            submitting={this.state.submitting}
            primaryButtonText="Yes, edit score"
          />
        </form>
      </Modal>
    );
  }

  printAssessmentButton() {
    if (this.props.exerciseResult) {
      return (
        <a
          className="dropdown-item"
          href={Routes.plato_api_print_assessment_exercise_result_path(this.props.exerciseResult.id, {
            locale: this.props.locale,
            program_id: this.props.programId,
            timezone: { abbr: moment.tz(moment.tz.guess()).zoneAbbr(), zone: moment.tz.guess(), }
          })}
        >
          {capitalize(`${I18n.t('print')} ${I18n.t('student_work')}`)}
        </a>
      );
    }

    return null;
  }

  renderActionsDropdown() {
    return (
      <ActionsDropdown
        toggleClassName={`btn--purple ${styles.caret}`}
        toggleText=""
      >
        {this.props.exerciseType === 'Assessment' && this.printAssessmentButton()}
        <a
          className="dropdown-item"
          href="#"
          role="button"
          onClick={this._openClearWorkModal}
        >
          {I18n.t('clear')} {I18n.t('student_work')}
        </a>
      </ActionsDropdown>
    );
  }

  render() {
    return (
      <div
        className={styles.buttonContainer}
        ref={(node) => { this.node = node; }}
      >
        <div className="tw-flex">
          {this.props.alreadyGraded ? this._renderRegradeButton() : this._renderPostGradeButton()}
          {this.renderActionsDropdown()}
        </div>
        {this._renderClearWorkModal()}
        {this._renderRegradeModal()}
      </div>
    );
  }
}
