import React, { useMemo, useState } from 'react';
import { twMerge } from '@/utils';

export { default as useAlphabetFilter } from './useAlphabetFilter';

interface AlphabetFilterProps {
  activeLetters?: string[];
  className?: string;
  initialLetter?: string;
  onChange?: (arg: any) => any;
}

const getButtonClasses = (letter: string, activeLetter: string) => (
  twMerge(
    'tw-border-none tw-w-[30px] tw-h-[30px] tw-p-[initial] tw-leading-[30px] tw-text-[0.9rem] tw-font-bold tw-ml-0.5',
    'tw-cursor-pointer tw-bg-transparent disabled:tw-cursor-not-allowed',
    letter === '' && 'tw-mr-1.5',
    letter === activeLetter && 'tw-rounded-full tw-bg-tci-purple tw-text-white',
  )
);

const alphabetFromCodes = () => {
  const alphaCodes = Array.from(Array(26)).map((e, i) => i + 65);
  return alphaCodes.map(code => String.fromCharCode(code));
};

const AlphabetFilter = ({
  activeLetters = alphabetFromCodes(),
  className = '',
  initialLetter = '',
  onChange = () => {},
}: AlphabetFilterProps) => {
  const [activeLetter, setActiveLetter] = useState<string>(initialLetter);
  const alphabet = alphabetFromCodes();

  const activeLetterObject = useMemo(() => (
    activeLetters.reduce((acc, letter) => ({ ...acc, [letter]: true }), {})
  ), [activeLetters]);

  const handleChange = (value: string) => {
    setActiveLetter(value);
    onChange(value);
  };

  const getClassname = (grey: boolean) => {
    const classname = grey
      ? ('tw-relative tw-flex tw-justify-center tw-items-center tw-text-gray-400 tw-leading-[30px]')
      : ('tw-relative tw-before:bg-transparent tw-flex tw-items-center tw-justify-center tw-leading-[30px]');
    return classname;
  };

  return (
    <div className={`tw-p-5 tw-pl-2.5 ${className}`}>
      <div className="tw-flex">
        <React.Fragment key="">
          <input
            id="alphabet-all"
            type="radio"
            name="alphabet-filter"
            value=""
            checked={activeLetter === ''}
            onChange={() => handleChange('')}
            className="tw-appearance-none"
          />
          <label
            htmlFor="alphabet-all"
            className={`${getButtonClasses('', activeLetter)} tw-relative tw-flex tw-items-center tw-justify-center`}
            style={{ width: '30px', height: '25px', lineHeight: '30px' }}
          >
              ALL
          </label>
        </React.Fragment>
        {alphabet.map(char => (
          <React.Fragment key={char}>
            <input
              id={`alphabet-${char}`}
              type="radio"
              name="alphabet-filter"
              value={char}
              checked={activeLetter === char}
              onChange={() => handleChange(char)}
              disabled={!activeLetters.includes(char)}
              className="tw-appearance-none"
            />
            <label
              htmlFor={`alphabet-${char}`}
              className={`${getButtonClasses(char, activeLetter)} ${getClassname(!activeLetters.includes(char))}`}
              style={{ width: '30px', height: '25px', lineHeight: '30px' }}
            >
              {char}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AlphabetFilter;
