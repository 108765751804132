import FE from 'froala-editor';
import FroalaConfig from 'froala/FroalaConfig';

const iconTemplate = '<i class="fa fa-[NAME]" style="color:#000000;font-size:17px" aria-hidden="true"></i>';

export const CUSTOM_IMAGE_COMMAND_NAME = 'customAddImage';

const customImageCommand = () => {
  FE.DefineIconTemplate('black-icon', iconTemplate);
  FE.DefineIcon('addImageIcon', { NAME: 'image', template: 'black-icon' });

  FE.RegisterCommand(CUSTOM_IMAGE_COMMAND_NAME, {
    focus: false,
    icon: 'addImageIcon',
    refreshAfterCallback: true,
    title: 'Add Image',
    undo: true,
  });
};

// initializes the custom Froala toolbar commands
export const registerFroalaCommands = () => {
  customImageCommand();
};

const TextEditorEvents = ({ addImageModalManager, setEditorInstance }) => ({
  // eslint-disable-next-line func-names
  'commands.after': function(commandName) {
    switch (commandName) {
      case CUSTOM_IMAGE_COMMAND_NAME:
        this.selection.save();
        addImageModalManager.open();
        break;
      default:
        break;
    }
  },
  initialized: function() {
    setEditorInstance(this);
  },
});

const TextEditorConfig = ({ addImageModalManager, setEditorInstance }) => FroalaConfig({
  customConfig: {
    enter: FE.ENTER_BR,
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
    height: '100%',
    heightMax: 200,
    imagePaste: false,
    toolbarButtons: [
      'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'indent', 'outdent',
      'clearFormatting', 'formatOL', 'formatUL', 'textColor', CUSTOM_IMAGE_COMMAND_NAME,
    ],
    width: '100%',
  },
  events: TextEditorEvents({ addImageModalManager, setEditorInstance }),
});

export default TextEditorConfig;
