import React, { useState, useEffect } from 'react';
import { plato_api_glossary_terms_path } from '@/modules/routes';
import Modal from '@/components/ui/Modal/Modal';
import SubmitFooter from '@/components/ui/Modal/SubmitFooter';
import LocaleToggle from '@/components/common/LocaleToggle';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/utils/ReactQuery';
import { useForm, Controller } from 'react-hook-form';
import cn from '@/utils/cn';

const validFileExtensions = ['.png', '.jpg', '.gif'] as const;

interface GlossaryModalProps {
  isOpen: boolean,
  handleClose: () => void,
}

type GlossaryTermForm = {
  term_en: string;
  term_es: string;
  definition_en: string;
  definition_es: string;
  image?: File | null;
};

const AddGlossaryTerm = ({ isOpen, handleClose }: GlossaryModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<GlossaryTermForm>({
    defaultValues: {
      term_en: '',
      term_es: 'SPANISH PLACEHOLDER',
      definition_en: '',
      definition_es: 'SPANISH PLACEHOLDER',
      image: null,
    },
  });
  const [locale, setLocale] = useState<'en' | 'es'>('en');

  const path = window.location.pathname.split('/');
  const section = path[2] || '';
  const sectionId = path[3];

  const { isLoading, mutate } = useMutation({
    mutationFn: (formData: FormData) => axios.post(plato_api_glossary_terms_path(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['glossaryTerms']);
    },
    onError: (e) => {
      console.log(e);
    },
    onSettled: handleClose,
  });

  const onSubmit = (data) => {
    if (!(section && /\d/.test(sectionId))) return;

    const { term_en, term_es, definition_en, definition_es, image } = data;

    if (!term_en || !definition_en || !term_es || !definition_es) return;

    const formData = new FormData();
    formData.append('glossary_term[section_id]', sectionId);
    formData.append('glossary_term[term_en]', term_en);
    formData.append('glossary_term[definition_en]', definition_en);
    formData.append('glossary_term[term_es]', term_es);
    formData.append('glossary_term[definition_es]', definition_es);
    if (image) formData.append('glossary_term[glossary_image]', image);

    mutate(formData);

    reset({
      term_en: '',
      term_es: 'SPANISH PLACEHOLDER',
      definition_en: '',
      definition_es: 'SPANISH PLACEHOLDER',
      image: null,
    });
  };

  return (
    <>
      <Modal
        close={handleClose}
        headerText="Create Glossary Term"
        isOpen={isOpen}
        className="tw-h-fit tw-w-[800px]"
      >
        <LocaleToggle
          btnClasses="tw-block tw-mb-[1.5rem] tw-overflow-hidden tw-text-tci-purple"
          isSaving={false}
          locale={locale}
          setLocale={setLocale}
        />
        <form className="tw-flex tw-flex-col" onSubmit={handleSubmit(onSubmit)}>
          <label className="tw-flex tw-mb-[1.5rem]">
            <div className="tw-flex tw-mr-[20px] tw-w-[6rem] tw-pt-[0.5rem]">
              <p className="tw-font-bold tw-text-[16px]"> Term </p>
              <RequiredAsterisk />
            </div>
            <input
              type="text"
              {...register('term_en', { required: true })}
              className={cn('tw-w-full tw-rounded-lg tw-h-[2.5rem] tw-px-[1rem] tw-border-[1px] tw-border-solid', {
                'tw-border-red': errors.term_en,
                'tw-border-grey': !errors.term_en,
                'tw-hidden': locale === 'es',
              })}
            />
            <input
              type="text"
              {...register('term_es', { required: true })}
              className={cn('tw-w-full tw-rounded-lg tw-h-[2.5rem] tw-px-[1rem] tw-border-[1px] tw-border-solid', {
                'tw-border-solid tw-border-red': errors.term_es,
                'tw-border-grey': !errors.term_es,
                'tw-hidden': locale === 'en',
              })}
            />
          </label>
          <label className="tw-flex tw-mb-[1.5rem]">
            <div className="tw-flex tw-mr-[20px] tw-w-[6rem] tw-pt-[0.5rem]">
              <p className="tw-text-[16px]"> Definition </p>
              <RequiredAsterisk />
            </div>
            <textarea
              {...register('definition_en', { required: true })}
              className={cn('tw-w-full tw-resize-none tw-rounded-lg tw-h-[5rem] tw-pt-2 tw-px-[1rem] tw-border-[1px] tw-border-solid', {
                'tw-border-red': errors.definition_en,
                'tw-border-grey': !errors.definition_en,
                'tw-hidden': locale === 'es',
              })}
            />
            <textarea
              {...register('definition_es', { required: true })}
              className={cn('tw-w-full tw-resize-none tw-rounded-lg tw-h-[5rem] tw-pt-2 tw-px-[1rem] tw-border-[1px] tw-border-solid', {
                'tw-border-red': errors.definition_es,
                'tw-border-grey': !errors.definition_es,
                'tw-hidden': locale === 'en',
              })}
            />
          </label>
          <div className="tw-flex tw-items-center">
            <p className="tw-font-bold tw-w-[6rem] tw-text-[16px]"> Add Image </p>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  accept={validFileExtensions.join(',')}
                  onChange={e => field.onChange(e.target.files[0])}
                />
              )}
            />
          </div>
          <SubmitFooter close={handleClose} isLoading={isLoading} isValid />
          {Object.keys(errors).length > 0 && (
            <p className="tw-text-red tw-absolute tw-bottom-4">Please double-check the English and Spanish fields.</p>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddGlossaryTerm;
