import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import key from 'images/icons/key.png';
import I18n from 'i18n';
import Modal, { useModalManager } from 'common/Modal';
import ModalBody from '../../staffer/ImpersonateStudent/ModalBody';
import styles from './index.module.scss';

const SignInAsStudentSelect = ({ ...props }) => {
  const [student, setStudent] = useState({});
  const modal = useModalManager();

  const selectLabel = (
    <span className="dsp-flex-center text--nowrap tw-bg-white">
      {I18n.t('sign_in_as_student')}
      <img className={styles.key} src={key} alt="" />
    </span>
  );

  const handleSelect = (option) => {
    setStudent({ id: option.value, name: option.label });
    modal.open();
  };

  return (
    <span className={styles.signInAsStudentContainer}>
      <label htmlFor="program_id" className={styles.selectLabel}>
        <p className="mt15">{selectLabel}</p>
      </label>
      <Select
        id="program_id"
        className={styles.select}
        searchable
        required
        options={props.signInAsStudentOptions}
        placeholder={I18n.t('select_student_or_search')}
        onChange={handleSelect}
      />
      <Modal isOpen={modal.isOpen} closeModal={modal.close} headerText={I18n.t('sign_in_as_student')}>
        <ModalBody student={student} close={modal.close} />
      </Modal>
    </span>
  );
};

SignInAsStudentSelect.propTypes = {
  signInAsStudentOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }))
};

SignInAsStudentSelect.defaultProps = {
  signInAsStudentOptions: []
};

export default SignInAsStudentSelect;
