import React, { useState } from 'react';
import Modal, { Footer } from 'components/common/Modal';
import Axios from 'axios';
import * as Routes from 'modules/routes';

interface DeleteStafferModalProps {
  closeModal: Function;
  modalIsOpen: Boolean;
  stafferIds: Set<Number>;
  subscriberId: number;
  updateTable: Function;
}

const DeleteStafferModal = ({ closeModal, modalIsOpen, stafferIds, subscriberId, updateTable } :
                              DeleteStafferModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const modalText = (
    "Deleting these users will remove all access to your account from these users. " +
    `Are you sure you want to complete this action for ${stafferIds.size} users?`
  )

  const deleteStaffers = () => {
    setSubmitting(true);
    Axios.post(Routes.plato_api_bulk_delete_subscriber_staffers_path(subscriberId), {
      staffer_ids: Array.from(stafferIds)
    })
      .then((response) => {
        setSubmitting(false);
        updateTable({ deletedIds: response.data.staffers })
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
      })
  }

  const renderError = () => (
    <div>
      <br />
      <span className="red">Error deleting teachers. Please reload your page and try again.</span>
    </div>
  )

  return (
    <Modal isOpen={modalIsOpen} closeModal={closeModal} headerText="Delete Staff">
      {modalText}
      {hasError && renderError()}
      <Footer
        primaryButtonCallback={deleteStaffers}
        primaryButtonClassName="btn btn--purple"
        primaryButtonDisabled={submitting}
        primaryButtonSubmit={false}
        primaryButtonText="Yes, delete"
        secondaryButtonCallback={closeModal}
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
}

export default DeleteStafferModal;
