import React from 'react';
import PropTypes from 'prop-types';

import rosteringCard from 'images/rosteringSetup/rosteringCard.svg';
import cleverLogo from 'images/rosteringSetup/cleverLogo.png';
import onerosterLogo from 'images/rosteringSetup/onerosterLogo.png';
import sftpIcon from 'images/rosteringSetup/sftpIcon.svg';

import rosteringCardStyles from '../RosteringCard.module.scss';
import styles from './UnstartedCard.module.scss';
import { isAdmin } from '../../../../common/Utils';
import { CardCTA } from '../Utils';

export default function UnstartedCard({ openModal, userType }) {
  return (
    <div>
      <h2>Rostering</h2>

      <hr className={rosteringCardStyles.hr} />

      <img className={styles.mainImage} alt="" src={rosteringCard} />

      <p className="mb20">
        Supported methods: Clever, OneRoster, API, and CSVs sent to an SFTP.
      </p>

      <div className={`${rosteringCardStyles.displayFlex} ${styles.rosterImageContainer} mb20`}>
        <img className={styles.cleverImage} alt="" src={cleverLogo} />
        <img className={styles.oneRosterImage} alt="" src={onerosterLogo} />
        <img className={styles.sftpImage} alt="" src={sftpIcon} />
      </div>

      <CardCTA
        enabled={isAdmin(userType)}
        onClick={openModal}
        started={false}
      />
    </div>
  );
}

UnstartedCard.propTypes = {
  openModal: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired
};
