import React from 'react';
import * as Routes from 'modules/routes';
import I18n from 'modules/i18n';
import BiographiesIcon from './icons/BiographiesIcon';
import GlobeIcon from './icons/GlobeIcon';
import HardHatIcon from './icons/HardHatIcon';
import LawIcon from './icons/LawIcon';
import CivicsLibraryIcon from './icons/CivicsLibraryIcon';
import PrimarySourceIcon from './icons/PrimarySourceIcon';
import AtomIcon from './icons/AtomIcon';
import Card, { CardBody } from './Card';
import { ReferenceMaterialAssignment } from '../types';
import { ReferenceMaterialTypes } from '@/components/interfaces/ReferenceMaterial';
import { svgAsImgSrc } from '@/components/common/Utils';

type ReferenceMaterialCardProps = {
  assignments: ReferenceMaterialAssignment[],
  programId: number | string,
  type: string,
};

const LIBRARIES_CONFIG_BY_TYPE = {
  [ReferenceMaterialTypes.BIOGRAPHIES_TYPE]: {
    i18Key: 'library_biographies',
    icon: <BiographiesIcon />,
    iconClassNames: 'tw-w-[125px]',
    tag: 'Biographies',
  },
  [ReferenceMaterialTypes.CAREER_PROFILES_TYPE]: {
    i18Key: 'library_career_profiles',
    icon: <HardHatIcon />,
    tag: 'Career Profiles',
  },
  [ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE]: {
    i18Key: 'library_supreme_court_cases',
    icon: <LawIcon />,
    iconClassNames: 'tw-w-[50px]',
    tag: 'Supreme Court Cases',
  },
  [ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE]: {
    i18Key: 'library_primary_sources',
    icon: <PrimarySourceIcon />,
    tag: 'Primary Source Library',
  },
  [ReferenceMaterialTypes.CIVICS_TYPE]: {
    i18Key: 'library_civics',
    icon: <CivicsLibraryIcon />,
    tag: 'Civics Library',
  },
  [ReferenceMaterialTypes.MAPS_TYPE]: {
    i18Key: 'library_maps',
    icon: <GlobeIcon />,
    tag: 'Maps',
  },
  [ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE]: {
    i18Key: 'library_science_and_engineering',
    icon: <AtomIcon />,
    iconClassNames: 'tw-w-[50px]',
    tag: 'Science and Engineering Resources',
  },
};

const ReferenceMaterialCard = ({ assignments, programId, type }: ReferenceMaterialCardProps) => {
  if (assignments == null || assignments.length === 0) return null;

  const { i18Key, icon, tag, iconClassNames } = LIBRARIES_CONFIG_BY_TYPE[type] || {};

  return (
    <Card
      key={`${type}-card`}
      bannerBorderColor="blue"
      label={i18Key}
      paddedLabel
      type={type}
    >
      <CardBody
        ariaLabel={I18n.t(i18Key)}
        iconClassNames={iconClassNames || 'tw-w-[60px]'}
        iconSrc={svgAsImgSrc(icon)}
        linkLabel={I18n.t(i18Key)}
        linkHref={Routes.shared_program_reference_materials_path(programId, { tag: tag })}
      >
        {assignments
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(assignment => (
            <a
              key={`assignment-link-${assignment.id}`}
              href={assignment.href}
              rel="noopener noreferrer"
              target="_blank"
            >
              {assignment.label}
            </a>
          ))
        }
      </CardBody>
    </Card>
  );
};

export default ReferenceMaterialCard;
