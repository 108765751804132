import React, { useLayoutEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import setupThreeJs from './setupThreeJs';
import { Vec3 } from './threeJsHelpers';
import { Texture } from './threeJsUtils';

export type UseThreeJsOnSuccess = ({
  camera,
  controls,
}: { camera: THREE.PerspectiveCamera, controls: OrbitControls, model: THREE.Group }) => any;

interface ThreeJsProps {
  modelUrl: string;
  onSuccess?: UseThreeJsOnSuccess;
  ref: React.MutableRefObject<HTMLElement>;
  startingPosition?: Vec3;
  startingRotation?: Vec3;
  startingTarget?: Vec3;
  startingZoom?: number;
  textures: Texture[];
}

const useThreeJs = ({
  modelUrl,
  onSuccess = () => { },
  ref,
  startingPosition,
  startingRotation,
  startingTarget,
  startingZoom,
  textures,
}: ThreeJsProps) => {
  useLayoutEffect(() => {
    if (!ref.current) return () => { };
    if (!modelUrl) return () => { };

    const { controls, renderer, scene } = setupThreeJs({
      modelUrl,
      onSuccess,
      ref,
      startingPosition,
      startingRotation,
      startingTarget,
      startingZoom,
      textures,
    });

    return () => {
      controls?.dispose();
      renderer?.dispose();
      scene?.children?.forEach(child => scene.remove(child));

      /* eslint-disable-next-line no-param-reassign */
      if (ref.current) ref.current.innerHTML = '';
    };
  }, [ref, modelUrl]);
};


export default useThreeJs;
