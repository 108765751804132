import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import styles from '../Modal.module.scss';
import TransferForm from './TransferForm';

export default function Transfer({
  subscriber, subscription, closeModal, modalIsOpen, updateTable, transferPath, searchSubscribersPath, transferSubscriber, viewSubscriberPath
}) {
  return (
    <Modal
      bodyClassName={styles.modal}
      className={styles.modal}
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText="Transfer"
    >
      <TransferForm
        subscriber={subscriber}
        subscriptions={[subscription]}
        closeModal={closeModal}
        transferPath={transferPath}
        updateTable={updateTable}
        searchSubscribersPath={searchSubscribersPath}
        viewSubscriberPath={viewSubscriberPath}
        transferSubscriber={transferSubscriber}
      />
    </Modal>
  );
}

Transfer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  searchSubscribersPath: PropTypes.string.isRequired,
  subscriber: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    customerNumber: PropTypes.string.isRequired
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.number.isRequired,
    transferNotes: PropTypes.string,
    adminEmail: PropTypes.string
  }).isRequired,
  transferPath: PropTypes.string.isRequired,
  transferSubscriber: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  updateTable: PropTypes.func.isRequired,
  viewSubscriberPath: PropTypes.string.isRequired
};
