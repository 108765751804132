import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import styles from './DragAndDrop.module.scss';

const ENABLED_SETTINGS = {
  bottom: false,
  bottomLeft: false,
  bottomRight: true,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false,
};

const CustomHandle = () => (
  <div id="resize-handle" className={styles.resizableIcon} aria-label="resize text area">
    <div className={styles.line1} />
    <div className={styles.line2} />
  </div>
);

const ResizableContainer = ({
  parentEl,
  children,
  className,
  onResizeStart,
  onResizeStop,
}) => {
  const [boundsParams, setBoundsParams] = useState({ height: 5.4, width: 6.4 });

  useEffect(() => {
    if (!parentEl) return;

    setBoundsParams({
      height: parentEl.clientHeight / 100,
      width: parentEl.clientWidth / 100
    });
  }, [parentEl]);

  return (
    <Resizable
      bounds={parentEl}
      className={className}
      enable={ENABLED_SETTINGS}
      onResizeStart={onResizeStart}
      onResizeStop={(e, direction, ref) => {
        const newDimensions = {
          height: ref.offsetHeight / boundsParams.height,
          width: ref.offsetWidth / boundsParams.width
        };

        onResizeStop(e, newDimensions);
      }}
      size={{ height: '100%', width: '100%' }}
      handleComponent={{ bottomRight: <CustomHandle /> }}
    >
      {children}
    </Resizable>
  );
};

ResizableContainer.defaultProps = {
  className: '',
  height: null,
  parentEl: null,
  width: null,
};

ResizableContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  onResizeStart: PropTypes.func.isRequired,
  onResizeStop: PropTypes.func.isRequired,
  parentEl: PropTypes.instanceOf(Element),
};

export default ResizableContainer;
