import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/Modal/index';
import EditForm from './EditForm';

export default function Edit({
  closeModal, modalIsOpen, rowData, updatePath, updateTable, userIsSysadmin, flags
}) {
  return (
    <Modal
      closeModal={closeModal}
      isOpen={modalIsOpen}
      headerText="Edit Student"
    >
      <EditForm
        student={rowData}
        closeModal={closeModal}
        updatePath={updatePath}
        updateTable={updateTable}
        userIsSysadmin={userIsSysadmin}
        flags={flags}
      />
    </Modal>
  );
}

Edit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    uid: PropTypes.string,
    username: PropTypes.string.isRequired
  }).isRequired,
  updatePath: PropTypes.string.isRequired,
  updateTable: PropTypes.func.isRequired,
  userIsSysadmin: PropTypes.bool.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};
