import { useQuery } from '@tanstack/react-query';
import * as Routes from 'modules/routes';
import { matchAttributesForMultipleRows } from '@/components/common/DataTable/Utils';
import axios from 'axios';
import { useState } from 'react';
import { COLUMNS, ISeatPool } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';

const PAGE_SIZE = 20;

export const getUrl = (subscriberId: number, page: number) => (
  Routes.plato_api_seat_pools_path({
    with_current_subscriptions: '',
    extra_attributes: ['number_of_teachers', 'number_of_students'],
    order: 'asc',
    page,
    per_page: PAGE_SIZE,
    subscriber_id: subscriberId,
  })
);

interface ApiResponse {
  data: ISeatPool[];
}

const useSubscriberSeatPools = (subscriberId: number) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<ISeatPool[]>([]);

  const query = useQuery(['subscriberSeatPools', subscriberId, page], () => (
    axios
      .get<ApiResponse>(getUrl(subscriberId, page))
      .then((res) => {
        if (res.data.data.length === 0) return [];

        const rows = [...res.data.data];

        matchAttributesForMultipleRows(rows, COLUMNS);

        setPage(prev => prev + 1);
        setData(prev => [...prev, ...rows]);

        return rows;
      })
  ), { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false });

  return { ...query, data };
};

export default useSubscriberSeatPools;
