import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';

export default function Footer({ disabled }) {
  return (
    <div>
      <hr />
      <footer className={styles.footer}>
        <button
          type="submit"
          className="btn btn--purple"
          disabled={disabled}
        >
          Update
        </button>
      </footer>
    </div>
  );
}

Footer.propTypes = {
  disabled: PropTypes.bool
};

Footer.defaultProps = {
  disabled: false
};
