import React from 'react';
import UnitLessonSelect from '@/components/common/ModelSelect/UnitLessonSelect';
import { RequiredAsterisk } from '@/components/common/Forms/Utils';
import useAssignablesStore from './store/useAssignablesStore';
import styles from './WhatSection.module.scss';

interface UnitLessonSelectorProps {
  initialUnitLessonId: number;
  labelStyle: string;
  programId: number;
  formChangeValue: (newValue: Array<string> | string | number, formValueName: string) => void;
}

const UnitLessonSelector = ({
  initialUnitLessonId,
  labelStyle,
  programId,
  formChangeValue,
}: UnitLessonSelectorProps) => {
  const setUnitLesson = useAssignablesStore(state => state.setUnitLesson);
  const setSelectedType = useAssignablesStore(state => state.setSelectedType);
  const setStore = useAssignablesStore(state => state.setStore);
  const hasUnits = useAssignablesStore(state => state.hasUnits);
  const resetState = useAssignablesStore(state => state.resetState);

  const doneLoading = (options, formatOptions) => {
    formatOptions(options);
    if (initialUnitLessonId) {
      formChangeValue(initialUnitLessonId, 'lesson_id');
      setUnitLesson({ label: '', value: initialUnitLessonId });
    }

    setStore({ loadingLessons: false });
  };

  const lessonSelectLabel = () => (
    <label htmlFor="model-select" className={`${labelStyle} tw-bg-white`}>
      {hasUnits ? 'Unit/Lesson' : 'Lesson'}<RequiredAsterisk />
    </label>
  );

  const handleUnitLessonSelect = (selectedUnitLesson) => {
    formChangeValue('', 'type');
    setSelectedType('');

    formChangeValue([], 'modelsToAssign');
    formChangeValue([], 'slideShowSelect');
    resetState();

    formChangeValue(selectedUnitLesson.value, 'lesson_id');
    setUnitLesson(selectedUnitLesson);
  };

  return (
    <>
      {lessonSelectLabel()}
      <UnitLessonSelect
        className={styles.largeField}
        doneLoadingCallback={doneLoading}
        handleSelect={handleUnitLessonSelect}
        initialOption={{ label: '', value: initialUnitLessonId }}
        placeholder="Select one"
        programId={programId}
        showLabel={false}
      />
    </>
  );
};

export default UnitLessonSelector;
