import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ElementInsert.module.scss';
import { videoPropTypes } from '../../../../../../Utils';

// Content editable span wrapped in another span to allow deleting button and tooltip in one action.
const VideoButton = ({ buttonText, video }) => (
  <span>
    <span
      contentEditable={false}
      data-button-type="video"
      data-video={JSON.stringify(video)}
    >
      <span
        className="btn btn--purple video-btn"
        role="button"
        tabIndex={0}
      >
        {buttonText}
      </span>
      <span className={`slide-popup hint-text ${styles.slidePopup} ${styles.video}`}>
        <video
          className="btnVideo video-js vjs-big-play-centered"
          key={video.url}
          controls="pause"
          controlsList="nodownload"
          poster={video.poster_url}
          preload="metadata"
          crossOrigin="anonymous"
          disablePictureInPicture
          muted={video.is_silent}
          width="100%"
        >
          {video.captionUrl && <track label="English" kind="captions" srcLang="en" src={video.captionUrl} />}
          {video.captionEsUrl && <track label="Español" kind="captions" srcLang="es" src={video.captionEsUrl} />}
          <source src={video.url} />
        </video>
        <i
          className={`fa fa-times-circle popup-close-btn ${styles.slidePopupCloseBtn}`}
          role="button"
          tabIndex={0}
        />
      </span>
    </span>
  </span>
);

VideoButton.propTypes = {
  buttonText: PropTypes.string,
  video: videoPropTypes,
};

VideoButton.defaultProps = {
  buttonText: '',
};
