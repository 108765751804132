import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Select from 'react-select-5';
import * as Routes from 'modules/routes';
import StatusIcon from '@/components/common/Forms/AutoSaveSelect/StatusIcon';
import styles from './CategoryDropdown.module.scss';

interface CategoryDropdownProps {
  assessment: {
    id: number;
    category: string;
  };
  categories: { [key: string]: string };
}

type OptionType = {
  label: string;
  value: string;
};

const CategoryDropdown = ({ assessment, categories }: CategoryDropdownProps) => {
  const initialOption: OptionType = { label: categories[assessment.category], value: assessment.category };
  const [category, setCategory] = useState<OptionType>(initialOption);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(null);
  const [error, setError] = useState<Error>(null);

  const handleSelect = (option: OptionType) => {
    setCategory(option);
    setLoading(true);

    Axios.put(Routes.plato_api_assessment_path(assessment.id, { assessment: { category: option.value } }))
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setOptions(Object.keys(categories).map(key => ({ label: categories[key], value: key })));
  }, []);

  return (
    <div className={styles.container}>
      <label htmlFor="category_select">
        <p className="mt15"><strong>Category:</strong></p>
      </label>
      <Select
        className={styles.dropdown}
        classNamePrefix="Select"
        inputId="category_select"
        options={options}
        onChange={handleSelect}
        value={category}
        styles={{ menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 }) }}
      />
      <StatusIcon loading={loading} error={error} />
    </div>
  );
};

export default CategoryDropdown;
