import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Modal, { Footer } from 'common/Modal';
import * as Routes from 'routes';
import styles from './index.module.scss';

const intervalTime = 60;

const SignInModal = ({ studentAccount }) => {
  const [sessionError, setSessionError] = useState('');
  const [redirecting, setRedirecting] = useState(false);

  const checkSignIn = () => {
    Axios
      .get(Routes.plato_api_status_index_path())
      .then(() => setSessionError(''))
      .catch(({ response }) => {
        setSessionError(prevError => prevError || response.data.message);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden) checkSignIn();
    }, intervalTime * 1000);

    window.addEventListener('focus', checkSignIn);

    return () => {
      clearInterval(interval);
      window.removeEventListener('focus', checkSignIn);
    };
  }, []);

  useEffect(() => {
    if (sessionError) {
      $(document.body).children().each((_, el) => {
        if (!(el.classList.contains('no-blur'))) el.classList.add(styles.blur);
      });
    }
  }, [sessionError]);

  const goToSignInPage = () => {
    setRedirecting(true);
    const setReturnToPath = Routes.plato_api_set_return_to_status_index_path();
    const redirectPath = studentAccount ? Routes.new_student_session_path() : Routes.new_staffer_session_path();

    Axios
      .post(setReturnToPath, { return_to: window.location.href })
      .then(() => { window.location.href = redirectPath; });
  };

  return (
    <Modal
      headerText="Please Sign In Again"
      isOpen={!!sessionError}
      className={styles.signInModal}
      closeable={false}
      portalClassName="no-blur"
    >
      <p>{sessionError}</p>
      <p>
        {sessionError.match(/another browser/) ? 'To sign back in on this browser, ' : 'To sign back in, '}
        please refresh your browser, or click <strong>Sign in</strong> below.
      </p>
      <Footer
        primaryButtonText="Sign in"
        primaryButtonSubmit={false}
        primaryButtonCallback={goToSignInPage}
        primaryButtonDisabled={redirecting}
      />
    </Modal>
  );
};

SignInModal.propTypes = {
  studentAccount: PropTypes.bool.isRequired
};

export default SignInModal;
