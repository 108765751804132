import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import styles from './StudentFeatures/StudentFeatures.module.scss';
import { InfoTooltip } from './Util';

const EditStudentLink = ({ path }) => (
  <a href={path} target="_blank" rel="noopener noreferrer">Edit per student</a>
);

EditStudentLink.propTypes = {
  path: PropTypes.string.isRequired
};

export default function RadioGroup({
  form, values, label, name, manageClassesPath, negativeSetting, tooltip, onLabel, offLabel, sectionHeaderClass, showDots
}) {
  return (
    <div className={`${styles.inlineRow} mb15`}>
      <span className={`mr20 ${styles.radioGroup}`}>
        <span className={sectionHeaderClass}>{label}</span>
        {tooltip && <InfoTooltip content={tooltip} />}
      </span>
      <div className={styles.radioContainer}>
        <Field
          id={`${name}_on`}
          name={name}
          onChange={form.mutators.handleRadio}
          type="radio"
          value={negativeSetting ? 'false' : 'true'}
          checked={negativeSetting ? !values[name] : values[name]}
          component="input"
        />

        <label htmlFor={`${name}_on`} className={`${styles.radio} ${styles.centeredText}`}>
          {onLabel}
          {showDots && <i className={`fa fa-circle ${styles.onIcon} ${styles.onIcon}`} aria-hidden />}
        </label>

        <Field
          id={`${name}_off`}
          name={name}
          onChange={form.mutators.handleRadio}
          type="radio"
          value={negativeSetting ? 'true' : 'false'}
          checked={negativeSetting ? values[name] : !values[name]}
          component="input"
        />

        <label htmlFor={`${name}_off`} className={`${styles.radio} ${styles.centeredText}`}>
          {offLabel}
          {showDots && <i className={`fa fa-circle ${styles.offIcon}`} aria-hidden />}
        </label>
      </div>
      {manageClassesPath && <EditStudentLink path={manageClassesPath} />}
    </div>
  );
}

export function handleRadio(args, state, utils) {
  const isOn = args[0].target.value === 'true';

  utils.changeValue(state, args[0].target.name, () => isOn);
}

RadioGroup.propTypes = {
  form: PropTypes.shape({
    mutators: PropTypes.shape({
      handleRadio: PropTypes.func
    })
  }).isRequired,
  label: PropTypes.string,
  manageClassesPath: PropTypes.string,
  name: PropTypes.string.isRequired,
  negativeSetting: PropTypes.bool,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  sectionHeaderClass: PropTypes.string,
  showDots: PropTypes.bool,
  tooltip: PropTypes.node,
  values: PropTypes.shape({
    student_view_lesson_off: PropTypes.bool
  }).isRequired
};

RadioGroup.defaultProps = {
  label: '',
  manageClassesPath: null,
  negativeSetting: false,
  offLabel: 'No',
  onLabel: 'Yes',
  sectionHeaderClass: '',
  showDots: true,
  tooltip: null,
};
