import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DeleteButton } from '../materials/Utils';

export default class Row extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.setTiming = this.setTiming.bind(this);
  }

  setTiming(e) {
    this.setState({ timing: e.value });
    const formData = new FormData();

    formData.append('lesson_module_sections[timing]', e.value);

    Axios.patch(`/admin/lesson_module_sections/${this.props.id}`, formData).then((response) => {
      if (!response.data) {
        console.log('No data returned.');
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  openConfirmationModal() {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  handleConfirm() {
    this.setState({ isDeleting: false });
    this.closeConfirmationModal();
    this.props.removeRow(this.props.id);
  }

  handleDelete(e) {
    this.setState({ isDeleting: true });
    e.preventDefault();
    Axios.delete(`/admin/lesson_module_sections/${this.props.id}`).then((response) => {
      if (response.data) {
        this.handleConfirm();
      }
      else {
        console.log('No data returned.');
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ confirmationModalIsOpen: false, isDeleting: false });
    });
  }

  render() {
    const {
      title, startingPage, endingPage, timing
    } = this.state;

    return (
      <tr>
        <td className="title">{title}</td>
        <td className="page-numbers">{startingPage}-{endingPage}</td>
        <td className="timing">
          <Select
            searchable={false}
            value={timing}
            clearable={false}
            options={this.props.timings}
            onChange={this.setTiming}
          />
        </td>
        <td className="actions">
          <DeleteButton
            confirmationModalIsOpen={this.state.confirmationModalIsOpen}
            openConfirmationModal={this.openConfirmationModal}
            closeConfirmationModal={this.closeConfirmationModal}
            handleDelete={this.handleDelete}
            isDeleting={this.state.isDeleting}
          />
        </td>
      </tr>
    );
  }
}

Row.defaultProps = {
  title: '',
  startingPage: null,
  endingPage: null,
  timing: null,
  isDeleting: false,
  timings: []
};

Row.propTypes = {
  id: PropTypes.number.isRequired,
  timing: PropTypes.string,
  removeRow: PropTypes.func.isRequired,
  timings: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  startingPage: PropTypes.number,
  endingPage: PropTypes.number,
  isDeleting: PropTypes.bool
};
