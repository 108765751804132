import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './FilterInput.module.scss';

export default class FilterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isChecked: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getInputId() {
    return `filter-${this.props.name}-${this.props.option.value}`;
  }

  select(select) {
    this.setState({ isChecked: select });
  }

  handleChange(e) {
    const val = e.target.value;
    const checked = e.target.checked;

    this.setState({ isChecked: checked }, () => {
      this.props.onOptionChange(val, checked);
    });
  }

  handleKeyDown(e) {
    const val = e.target.value;
    const prev_checked = this.state.isChecked;

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.setState({ isChecked: !prev_checked }, () => {
        this.props.onOptionChange(val, !prev_checked);
      });
    }
  }

  render() {
    return (
      <div className="materials-filters" key={this.props.option.value}>
        <label htmlFor={this.getInputId()} className={styles.label}>
          <input
            id={this.getInputId()}
            className="mr5"
            type="checkbox"
            value={this.state.option.value}
            checked={this.state.isChecked}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          {this.props.option.label}
        </label>
      </div>
    );
  }
}

FilterInput.defaultProps = {
  name: null
};

FilterInput.propTypes = {
  name: PropTypes.string,
  option: PropTypes.objectOf(PropTypes.string).isRequired,
  onOptionChange: PropTypes.func.isRequired
};
