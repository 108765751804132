import React from 'react';
import { twMerge } from '@/utils';
import Checkbox from '@/components/common/libraries/common/Checkbox';
import Collapse from '@/components/common/libraries/common/Collapse';
import { FilterField } from '@/components/common/libraries/VideoLibrary/types';
import styles from './Filter.module.scss';

const Divider = () => <div className={styles.divider} />;

interface FilterProps {
  label: React.ReactNode;
  value: string | number;
  checked: boolean;
  className?: string;
  onChange: (value: string | number, checked: boolean) => void;
}

const Filter = ({ label, value, checked, className, onChange }: FilterProps) => {
  const handleChange = () => {
    onChange(value, !checked);
  };

  const inputClassname = twMerge(
    'focus:tw-outline focus:tw-outline-1 focus:tw-outline-black',
    'hover:tw-outline hover:tw-border-black',
    'tw-transition tw-duration-200 tw-ease-in-out',
  );

  return (
    <div className={className ? `${styles.filter} ${className}` : styles.filter}>
      <Checkbox
        label={label}
        checked={checked}
        extraClassName="hover:tw-text-tci-purple focus:tw-text-tci-purple"
        extraInputClassName={inputClassname}
        onChange={handleChange}
        shouldCover
      />
    </div>
  );
};

interface FilterCategoryProps {
  className?: string;
  children?: ReactChildren;
  filterClassName?: string;
  filters?: FilterField[];
  onChange?: (value: string | number, checked: boolean) => void;
  shouldCollapse?: boolean;
  triggerElement: JSX.Element;
}

const FilterCategory = ({
  className, children, filterClassName, filters, onChange, shouldCollapse = true, triggerElement,
}: FilterCategoryProps) => (
  <div className={className}>
    <Collapse
      triggerElement={triggerElement}
      shouldCollapse={shouldCollapse}
    >
      {children}
      {filters?.map(filter => (
        <Filter
          className={filterClassName}
          key={filter.value}
          label={filter.label}
          value={filter.value}
          checked={filter.checked ?? false}
          onChange={onChange}
        />
      ))}
    </Collapse>
  </div>
);

export {
  Divider,
  FilterCategory,
};

export default Filter;
