import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Tooltip from 'common/Tooltip';
import AutoSave from '../AutoSave';
import SliderAdapter from './SliderAdapter';
import styles from './SliderQuestion.module.scss';
import {
  formatTimestamp, renderStatusMessage, DEFAULT_NOTEBOOK_GREEN
} from '../QuestionUtils';

export default class SliderQuestion extends Component {
  static propTypes = {
    ajaxPath: PropTypes.string,
    disabled: PropTypes.bool, // true => not interactive, no save button
    initialQuestionAnswer: PropTypes.shape({
      answer: PropTypes.string.isRequired,
      answer_updated_at: PropTypes.string.isRequired
    }),
    notebookBorderColor: PropTypes.string,
    previewOnly: PropTypes.bool, // true => interactive but no save button or form submission
    question: PropTypes.shape({
      answer_choices: PropTypes.arrayOf(PropTypes.string).isRequired,
      body: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired,
    viewOnly: PropTypes.bool // true => not interactive, no save button, and shows disabled overlay
  };

  static defaultProps = {
    ajaxPath: null,
    disabled: false,
    initialQuestionAnswer: null,
    notebookBorderColor: DEFAULT_NOTEBOOK_GREEN,
    previewOnly: false,
    viewOnly: false
  };

  constructor(props) {
    super(props);

    const hasAnswer = this.props.initialQuestionAnswer !== null;
    const savedAnswer = hasAnswer ? this.props.initialQuestionAnswer.answer : null;

    this.state = {
      savedAnswer,
      status: hasAnswer ? '200' : null,
      submitting: false,
      updatedAt: hasAnswer ? formatTimestamp(this.props.initialQuestionAnswer.answer_updated_at, false) : null,
      value: savedAnswer
    };

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.renderSlider = this.renderSlider.bind(this);
    this.save = this.save.bind(this);
  }

  save = async (values) => {
    if (this.props.disabled || this.props.previewOnly || this.props.viewOnly) return null;

    this.setState({ status: null, submitting: true });

    return axios
      .post(this.props.ajaxPath, { question_answer: values })
      .then((response) => {
        const data = response.data;

        if (data.errors) {
          this.setState({ status: '500' });
          return data.errors;
        }

        this.setState({
          savedAnswer: data.question_answer.answer,
          status: '200',
          updatedAt: formatTimestamp(data.question_answer.updated_at, true),
        });
        return data.question_answer.answer;
      })
      .catch((error) => {
        this.setState({ status: '400' });
        return error;
      })
      .then(() => this.setState({ submitting: false }));
  };

  getDefaultValue = () => (
    this.props.initialQuestionAnswer ?
      this.props.initialQuestionAnswer.answer :
      (this.props.question.answer_choices.length - 1) / 2
  );

  getMarks = () => (
    this.props.question.answer_choices.map((answerChoice, index) => ({
      label: answerChoice,
      value: index
    }))
  );

  getMax = () => this.props.question.answer_choices.length - 1;

  renderSlider = ({ form }) => (
    <form className={`${styles.questionContainer} slider-question`} data-answered={!!this.state.value}>
      {
        !this.props.disabled && !this.props.previewOnly && !this.props.viewOnly &&
        <AutoSave save={this.save} />
      }

      <Field
        ariaLabelledBy={`SliderQuestion-${this.props.question.id}`}
        component={SliderAdapter}
        name="answer"
        id={`slider-${this.props.question.id}`}
        defaultValue={this.getDefaultValue()}
        disabled={this.props.disabled || this.props.viewOnly}
        marks={this.getMarks()}
        min={0}
        max={this.getMax()}
        onChange={form.mutators.handleAnswerChange}
        onChangeCommitted={form.mutators.handleAnswerChange}
        previewOnly={this.props.previewOnly}
        step={null}
        valueLabelDisplay="off"
      />
    </form>
  );

  handleAnswerChange = (args, state, utils) => {
    const value = String(args[1]);
    this.setState({ value });
    utils.changeValue(state, 'answer', () => value);
  };

  renderSaveButtonAndStatus() {
    if (this.props.disabled || this.props.previewOnly || this.props.viewOnly) return null;

    const { status, submitting, updatedAt } = this.state;

    return (
      <div className="save">
        {renderStatusMessage(status, submitting, updatedAt, 'Notebook')}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div
          className="text--center question-interactable"
          style={{ backgroundColor: `#${this.props.notebookBorderColor}` }}
        >
          {(this.props.viewOnly) && <div className="div-overlay" />}
          <Form
            keepDirtyOnReinitialize
            initialValues={{
              answer: this.state.savedAnswer,
              question_id: this.props.question.id
            }}
            mutators={{
              handleAnswerChange: this.handleAnswerChange
            }}
            onSubmit={this.save}
            render={this.renderSlider}
          />
          <Tooltip content="Click on the circle and move it to answer the question." size="regular" theme="white">
            <i className="fa fa-pencil pencil-icon" />
          </Tooltip>
        </div>

        {this.renderSaveButtonAndStatus()}
      </Fragment>
    );
  }
}
