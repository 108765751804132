import React, { useRef } from 'react';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import useImageZoom from '@/hooks/useImageZoom';
import { useCanvas, useQuestionAnswer, useCanvasResizer } from '../hooks';
import Spinner from '../Spinner';
import DisabledOverlay from './DisabledOverlay';
import useDrawingContext from '../store/useDrawingContext';

interface PreviewProps {
  modalManager: ModalManager;
}

const Preview = ({ modalManager }: PreviewProps) => {
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const canvas = useCanvas({ canvasEl });
  const disabled = useDrawingContext(state => state.disabled);
  const editable = useDrawingContext(state => state.editable);
  const resizeContainer = useRef<HTMLDivElement>();
  const { containerWidth, containerHeight } = useCanvasResizer({ canvas, resizeContainer });
  const { dataUrl, isLoading } = useQuestionAnswer({ canvas, preview: true });

  useImageZoom({ ref: resizeContainer, imgSrc: dataUrl, disabled: editable });

  return (
    <div
      className="tw-relative tw-group"
      tabIndex={0}
      ref={resizeContainer}
      role="button"
      {...useKeydownAndClick(() => (!disabled && modalManager.open()))}
    >
      <div
        className="tw-shadow-md tw-bg-white tw-w-full tw-h-full tw-flex fw-flex-row tw-relative tw-rounded-xl"
      >
        <canvas
          style={{ display: 'none' }}
          width={containerWidth}
          height={containerHeight}
          ref={canvasEl}
        />
        {isLoading && <Spinner />}
        {dataUrl && (
          <img
            className="tw-min-w-full"
            src={dataUrl}
            alt="preview"
          />
        )}
        {!isLoading && (
          <DisabledOverlay
            modalManager={modalManager}
          />
        )}
      </div>
    </div>
  );
};

export default Preview;
