import create from 'zustand';

interface QuestionCopyStoreVariables {
  loading: boolean;
}

type IQuestionCopyStore = Partial<QuestionCopyStoreVariables> & {
  setLoading: (nextState: boolean) => void;
};

const useQuestionCopyStore = create<IQuestionCopyStore>(set => ({
  loading: false,
  setLoading: loading => set({ loading }),
}));

export default useQuestionCopyStore;
