import React from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'modules/routes';
import { EditNewSlideShowButton } from '@/components/shared/LessonOverview/Cards/ActionBar/Buttons';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import styles from '../../Navigator.module.scss';
import Tooltip from '../../../../../../common/Tooltip';

const SlideShowEditButton = ({ parentId, parentType, position, programId, slideShow, slideShowId }) => {
  const slideRef = useSlideShowContext(state => state.slideRef);
  const userId = useSlideShowContext(state => state.userId);
  const userType = useSlideShowContext(state => state.userType);

  const builderPath = () => {
    switch (userType) {
      case 'Sysadmin':
      case 'SupportManager':
      case 'ContentManager':
        return Routes.builder_admin_slide_show_path(slideShowId, {
          parent_id: parentId, parent_type: parentType, program_id: programId, position,
        });
      case 'Admin':
      case 'Coordinator':
      case 'Teacher':
        return Routes.builder_shared_slide_show_path(slideShowId, {
          parent_id: parentId, parent_type: parentType, program_id: programId, position,
        });
      default:
        return '';
    }
  };

  return (
    <div className={`${styles.dropdownItem} ${styles.flexSpaceBetween}`}>
      <div>Edit Slideshow</div>
      {(['Teacher', 'Admin', 'Coordinator'].includes(userType) && userId !== slideShow.staffer_id) ? (
        <div className={styles.editButton}>
          <EditNewSlideShowButton
            slideShow={{
              ...slideShow,
              slideShowId,
              title: slideShow.title_en,
              useNewBuilder: slideShow.use_new_builder,
              className: 'slide_show',
              links: {
                editPath: Routes.edit_shared_program_lesson_slide_show_path(programId, parentId, slideShowId, position),
              },
            }}
            programId={programId}
            lessonId={parentId}
            fromSlideShow
            useNewBuilder
          />
        </div>
      ) : (
        <Tooltip
          appendTo={slideRef.current}
          content="Edit Slideshow"
          interactive={false}
        >
          <a className={styles.fullScreenButton} href={builderPath()} type="button">
            <i className="fa fa-edit" />
          </a>
        </Tooltip>
      )}
    </div>
  );
};

export const SlideShowEditButtonPropTypes = PropTypes.shape({
  parentId: PropTypes.string.isRequired,
  parentType: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  slideShowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

SlideShowEditButton.propTypes = SlideShowEditButtonPropTypes.isRequired;

export default SlideShowEditButton;
