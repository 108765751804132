import React from 'react';
import clsx from 'clsx';
import I18n from 'i18n-js';
import moment from 'moment';
import { truncate } from '@/modules/TCIUtils';
import assessmentIcon from 'images/icons/assessment-white-outline.svg';
import bookIcon from 'images/icons/open-books-white.svg';
import classActivityIcon from 'images/icons/hand.svg';
import gameIcon from 'images/icons/controller-white-outline.svg';
import investigationIcon from 'images/icons/investigation-white-outline.svg';
import clipboardIcon from 'images/icons/clipboard-white-outline.svg';
import videoActivityIcon from 'images/icons/video-activity-white-outline.svg';
import BiographiesIcon from '@/components/shared/LessonOverview/Cards/icons/BiographiesIcon';
import GlobeIcon from '@/components/shared/LessonOverview/Cards/icons/GlobeIcon';
import HardHatIcon from '@/components/shared/LessonOverview/Cards/icons/HardHatIcon';
import LawIcon from '@/components/shared/LessonOverview/Cards/icons/LawIcon';
import CivicsLibraryIcon from '@/components/shared/LessonOverview/Cards/icons/CivicsLibraryIcon';
import PrimarySourceIcon from '@/components/shared/LessonOverview/Cards/icons/PrimarySourceIcon';
import AtomIcon from '@/components/shared/LessonOverview/Cards/icons/AtomIcon';
import cn from '@/utils/cn';
import Tooltip from '../../../../common/Tooltip';
import styles from './AssignmentCards.module.scss';
import { AssignmentModelsHashType, AssignmentType, UserType } from '../../types';
import { ReferenceMaterialTypes } from '@/components/interfaces/ReferenceMaterial';
import { svgAsImgSrc } from '@/components/common/Utils';

interface AssignmentCardProps {
  assignment: AssignmentType;
  assignmentModelsHash: AssignmentModelsHashType;
  user: UserType;
}

const AssignmentCard = ({ assignment, assignmentModelsHash, user }: AssignmentCardProps) => {
  if (!assignment || !assignmentModelsHash) return <></>;

  const assignmentTypes = Object.keys(assignmentModelsHash);
  const sectionDetails = (assignmentModelsHash.Section || []).filter(section => !section.stream);
  const allAssignmentDetails = assignmentTypes.flatMap((type) => {
    const items = assignmentModelsHash[type] || [];

    // Only use notebook and reading game items if the reading text has not been assigned
    if (sectionDetails.length === 0) {
      return items;
    } if (type === 'Notebook') {
      return items.filter(notebook => (
        !notebook.unit_project
          && !notebook.video_activity
          && !sectionDetails.find(assignedReading => assignedReading.id === notebook.parent_section)
      ));
    } if (type === 'ReadingGame') {
      return items.filter(readingGame => !sectionDetails.find(assignedReading => assignedReading.id === readingGame.parent_section));
    }

    return items;
  }) || [];

  const firstAssignmentDetails = allAssignmentDetails[0];
  const availableToStart = new Date(assignment.start_datetime) < new Date() || user.type !== 'Student';

  const ReferenceMaterialIcon = ({ type = firstAssignmentDetails.assignable_type }) => {
    const props = { color: 'white' };

    switch(type) {
      case ReferenceMaterialTypes.BIOGRAPHIES_TYPE:
        return <BiographiesIcon { ...props } />;
      case ReferenceMaterialTypes.CAREER_PROFILES_TYPE:
        return <HardHatIcon { ...props } />;
      case ReferenceMaterialTypes.CIVICS_TYPE:
        return <CivicsLibraryIcon { ...props } />;
      case ReferenceMaterialTypes.MAPS_TYPE:
        return <GlobeIcon { ...props } />;
      case ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE:
        return <PrimarySourceIcon { ...props } />;
      case ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE:
        return <AtomIcon { ...props } />;
      case ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE:
        return <LawIcon { ...props } />;
      default:
        return <BiographiesIcon { ...props } />;
    }
  };

  const getIcon = (): { icon: string; iconColorClass: string } => {
    switch (firstAssignmentDetails.assignment_type) {
      case 'Assessment':
        return { icon: assessmentIcon, iconColorClass: 'tw-bg-orange' };
      case 'Challenge': case 'Game':
        return { icon: gameIcon, iconColorClass: 'tw-bg-orange' };
      case 'ReferenceMaterial':
        return { icon: svgAsImgSrc(<ReferenceMaterialIcon />), iconColorClass: 'tw-bg-teal-blue' };
      case 'Section': case 'Notebook': case 'ReadingGame':
        return {
          icon: firstAssignmentDetails.stream ? investigationIcon
            : firstAssignmentDetails.unit_project ? clipboardIcon
              : bookIcon,
          iconColorClass: 'tw-bg-tci-purple',
        };
      case 'SlideShow': case 'SlideGroup':
        switch (firstAssignmentDetails.activity_type) {
          case 'classroom_activity':
            return { icon: classActivityIcon, iconColorClass: 'tw-bg-green' };
          case 'investigation_activity':
            return { icon: investigationIcon, iconColorClass: 'tw-bg-green' };
          case 'video_activity':
            return { icon: videoActivityIcon, iconColorClass: 'tw-bg-green' };
          case 'lesson_outline':
            return { icon: clipboardIcon, iconColorClass: 'tw-bg-green' };
          default:
            return { icon: bookIcon, iconColorClass: 'tw-bg-green' };
        }
      default:
        return { icon: bookIcon, iconColorClass: 'tw-bg-tci-purple' };
    }
  };

  const getDueDate = () => {
    const dueToday = moment(assignment.due_datetime).startOf('day').isSame(moment().startOf('day'));
    const dueTomorrow = moment(assignment.due_datetime).startOf('day').isSame(moment().add(1, 'day').startOf('day'));

    const label = dueToday ? I18n.t('today')
      : dueTomorrow ? I18n.t('tomorrow')
        : moment(assignment.due_datetime).format('ddd MMM D');

    return <div className={clsx('tw-text-base', { 'tw-text-green': dueToday })}>{I18n.t('due')} {label}</div>;
  };

  const renderDirections = notes => (
    <div>
      <span className="tw-font-bold tw-pr-1">Directions: </span>
      <span>{notes}</span>
    </div>
  );

  const renderDirectionsWithTooltip = () => {
    if (!assignment.notes || assignment.notes.length === 0) return null;

    const maxLength = 150;

    return (
      assignment.notes.length >= maxLength ? (
        <Tooltip placement="bottom" content={renderDirections(assignment.notes)}>
          <span>{renderDirections(truncate(assignment.notes, maxLength))}</span>
        </Tooltip>
      ) : renderDirections(assignment.notes)
    );
  };

  const { iconColorClass, icon } = getIcon();

  const renderCardContent = () => (
    <>
      <div className={clsx(iconColorClass, styles.cardImage)}>
        <img src={icon} alt={firstAssignmentDetails.assignable_type || assignmentTypes[0]} />
      </div>
      <div className={styles.cardDetails}>
        <div className="tw-text-[#3C3C3C] tw-text-base">
          <span className="tw-font-bold">
            {assignment.assignment_unit_lesson}
          </span>
          {`${assignment.assignment_unit_lesson ? ' - ' : ''}`}
          <span>
            {assignment.assignment_location_title}
          </span>
        </div>
        <div className={cn('tw-text-lg tw-flex tw-items-center', { 'tw-text-tci-purple': availableToStart })}>
          <div className={cn('tw-pb-1', { 'hover:tw-underline': availableToStart })}>
            {assignment.assignment_title}
            {availableToStart && <i className="fa fa-icon fa-arrow-circle-right tw-ml-3 tw-text-3xl" />}
          </div>
        </div>
        <div className="tw-leading-5">
          {allAssignmentDetails.map((assignmentDetails, index) => (
            availableToStart ? (
              <a
                key={`assignment-link-${index}`}
                className="hover:tw-underline tw-block"
                href={assignmentDetails.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div dangerouslySetInnerHTML={{ __html: assignmentDetails.label }} />
              </a>
            ) : (
              <div
                key={`assignment-link-${index}`}
                className="tw-flex tw-items-center"
              >
                <div dangerouslySetInnerHTML={{ __html: assignmentDetails.label }} />
                <Tooltip content={I18n.t('assignment_not_started')}>
                  <i className="fa fa-icon fa-info-circle tw-ml-1.5" />
                </Tooltip>
              </div>
            )))}
        </div>
      </div>
      <div className={`${styles.assignmentDirections} tw-text-[#3C3C3C]`}>
        <div className="tw-flex tw-justify-end tw-mb-5">{getDueDate()}</div>
        {renderDirectionsWithTooltip()}
      </div>
    </>
  );

  return availableToStart ? (
    <a
      className={`${styles.cardInfo} ${styles.cardBorder}`}
      href={allAssignmentDetails[0].href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {renderCardContent()}
    </a>
  ) : (
    <div className={`${styles.cardInfo} ${styles.cardBorder}`}>
      {renderCardContent()}
    </div>
  );
};

export default AssignmentCard;
