import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import ImageManagerTable from './ImageManagerTable';

const ImageManager = ({ excludeColumns, excludeFields, modelType, modelId }) => (
  <ApolloProvider client={apolloClient}>
    <ImageManagerTable
      excludeColumns={excludeColumns}
      excludeFields={excludeFields}
      modelType={modelType}
      modelId={modelId}
    />
  </ApolloProvider>
);

ImageManager.propTypes = {
  excludeColumns: PropTypes.arrayOf(PropTypes.oneOf(['displayStyle', 'image_resolution', 'zoomType'])),
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['display_size', 'zoom_type', 'alignment', 'caption_en', 'caption_es'])),
  modelId: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired
};

ImageManager.defaultProps = {
  excludeColumns: [],
  excludeFields: [],
};

export default ImageManager;
