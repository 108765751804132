import React, { useEffect, useState } from 'react';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/common/Tooltip';
import { SpinnerIcon } from '@/components/common/Utils';
import playButton from 'images/icons/play.svg';
import pauseButton from 'images/icons/pause.svg';
import Axios from 'axios';
import * as Routes from '@/modules/routes';
import styles from './TextToSpeechButton.module.scss';
import useSpeechstream from './useSpeechStream';
import SpeakElementButton from './SpeakElementButton';
import './speechstream.scss';
import SpeedSelectMenu from './SpeedSelectMenu';

interface TTSButtonProps {
  page?: number;
  locale: Locale;
  idToRead?: string;
}

const TextToSpeechButton = ({ page, locale, idToRead }: TTSButtonProps) => {
  const getSpeedRoute = `${Routes.plato_api_settings_path()}?id=text_to_speech_speed`;
  const setSpeedRoute = Routes.plato_api_settings_path();
  const [selectedSpeed, setSelectedSpeed] = useState(null);
  const config = { page, locale, idToRead, selectedSpeed };
  const { canUseUpdatedSpeechstream, playing, toggleSpeech, stop, loaded, setVoice } = useSpeechstream(config);

  const voiceForSpeed = (speed: string) => {
    if (!canUseUpdatedSpeechstream) return null;

    return speed === 'slow' ? 'Joey' : 'Matthew';
  };

  const getUserTTSSpeed = async () => {
    const speed = await Axios.get(getSpeedRoute)
      .then(response => response.data.text_to_speech_speed)
      .catch(() => {});
    return speed || 'normal';
  };

  if (idToRead) {
    return (
      <SpeakElementButton
        locale={locale}
        idToRead={idToRead}
        getUserTTSSpeed={getUserTTSSpeed}
        voiceForSpeed={voiceForSpeed}
      />
    );
  }

  const label = playing ? 'Pause' : 'Play';
  const iconSrc = playing ? pauseButton : playButton;
  const imgAlt = playing ? 'Pause Button' : 'Play Button';
  const style = playing ? { backgroundColor: '#522e91' } : {};
  const playButtonClass = () => {
    const buttonStyle = (!canUseUpdatedSpeechstream || locale.toLowerCase() === 'es') ? styles.textAction : styles.ttsActionPlay;

    if (loaded) return buttonStyle;

    return `${buttonStyle} ${styles.loading}`;
  };

  const updateUserPreferredSpeed = (newSpeed: string) => {
    setSelectedSpeed(newSpeed);
    setVoice(voiceForSpeed(newSpeed));
  };

  useEffect(() => {
    setVoice(voiceForSpeed(selectedSpeed));
  }, [loaded, selectedSpeed]);

  useEffect(() => {
    getUserTTSSpeed().then(speed => updateUserPreferredSpeed(speed));
  }, []);

  const saveUserPreferredSpeed = (newSpeed: string) => {
    if (newSpeed !== selectedSpeed) {
      Axios.patch(setSpeedRoute, { settings: { text_to_speech_speed: newSpeed } })
        .then(() => updateUserPreferredSpeed(newSpeed));
    }
  };

  return (
    <div className="tw-flex">
      <Tooltip
        content={label}
        theme="white"
        size="medium"
        interactive={false}
      >
        <button
          type="button"
          className={playButtonClass()}
          style={style}
          onClick={toggleSpeech}
          aria-label={label}
        >
          {loaded ? <img alt={imgAlt} className="standard" src={iconSrc} /> : <SpinnerIcon />}
        </button>
      </Tooltip>
      {(canUseUpdatedSpeechstream && locale.toLowerCase() === 'en') && (
        <SpeedSelectMenu selectedSpeed={selectedSpeed} setSelectedSpeed={saveUserPreferredSpeed} />
      )}

      <Tooltip
        content="Stop"
        theme="white"
        size="medium"
        interactive={false}
      >
        <button
          type="button"
          className={`${styles.textAction} ${styles.buttonSize}`}
          onClick={stop}
          aria-label="Stop"
        >
          {loaded ? <i className="fa fa-solid fa-stop tw-text-tci-purple" /> : <SpinnerIcon />}
        </button>
      </Tooltip>
    </div>
  );
};

export default TextToSpeechButton;
