import React, { useState } from 'react';
import Axios from 'axios';
import { Spinner } from 'common/Modal';
import PropTypes from 'prop-types';

export default function Form({ deleteAssessmentPath, onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const _handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    const response = await Axios.delete(deleteAssessmentPath, { data: { prevent_redirect: true } });
    setIsLoading(false);

    if (response.data.errors) {
      console.log(response.data.errors);
      setIsError(true);
    } else {
      onSuccess();
      window.location.reload();
    }
  }

  return (
    <form onSubmit={_handleSubmit}>
      <div>
        {isError ?
          'An error occurred. Please try again.' :
          'Are you sure you want to delete this assessment and all of its questions?'
        }
      </div>
      <Spinner isLoading={isLoading} />
      <div>
        <hr />
        <footer>
          <input
            className="btn btn--purple"
            type="submit"
            value="Yes"
            data-disable-with="Submitting…" />
        </footer>
      </div>
    </form>
  );
};

Form.propTypes = {
  deleteAssessmentPath: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired
};
