import React from 'react';
import PropTypes from 'prop-types';
import styles from './CloseButton.module.scss';

export default function CloseButton({ closeToast }) {
  return (
    <button
      aria-label="Close popup"
      className={styles.close}
      onClick={closeToast}
      type="button"
    >
      ✕
    </button>
  );
}

CloseButton.propTypes = {
  closeToast: PropTypes.func
};

CloseButton.defaultProps = {
  closeToast: null
};
