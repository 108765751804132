import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Footer, Spinner } from 'common/Modal';
import styles from './Form.module.scss';

export default class Form extends Component {
  static propTypes = {
    afterSubmit: PropTypes.func,
    ajaxPath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  };

  static defaultProps = {
    afterSubmit: null
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: props.name
    };
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(event) {
    this.setState({ name: event.target.value });
  }

  _handleSubmit(event) {
    event.preventDefault();

    this.setState({ isLoading: true });

    const data = { assessment: { name: this.state.name, online: true } };

    Axios.post(this.props.ajaxPath, data)
      .then((response) => {
        if (response.data.errors) {
          console.log(response.data.errors);
          this.setState({ isLoading: false });
        }
        else if (this.props.afterSubmit) {
          this.props.afterSubmit(response.data);
        }
        else if (response.data.redirect_to) {
          window.location.replace(response.data.redirect_to);
        }
        else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this._handleSubmit} className={styles.form}>
        <div className={styles.row}>
          <label className={styles.label}>
            <span className={styles.labelText}>Name </span>
            <input
              autoFocus
              type="text"
              required
              value={this.state.name}
              onChange={this._handleChange}
              className={styles.textInput}
            />
          </label>
        </div>
        <Spinner isLoading={this.state.isLoading} />
        <Footer />
      </form>
    );
  }
}
