import React from 'react';
import Tooltip from '@/components/common/Tooltip';
import { ISlideOrSlideGroup } from '../../../../stores/useSlideShowStore';
import useSlideConditions from '../../../useSlideConditions';

const LockedSlideIcon = ({ slide }: { slide: ISlideOrSlideGroup }) => {
  const { lockedSlideMessage } = useSlideConditions();
  const message = lockedSlideMessage(slide);

  return (
    <Tooltip
      content={message}
      interactive={false}
      size="medium"
      theme="white"
    >
      <span aria-hidden="true" className="fa fa-lock" />
    </Tooltip>
  );
};

export default LockedSlideIcon;
