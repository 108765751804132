import React from 'react';

interface PlaySpeechStreamParams {
  canUseUpdatedSpeechstream?: boolean;
  idToRead?: string;
  playbackStateDispatch?: React.Dispatch<any>;
}

const IGNORED_SELECTORS = ['.vocab-block-bkg'];

// Docs: https://docs.speechstream.net/home
const SpeechStream = {
  addEventListener: ({ locale, page, setIsLoaded, showSpeechstreamToolbar, voice }) => {
    if (window.speechstreamEventListenerAdded) {
      setIsLoaded(true);
      return;
    }

    window.addEventListener('speechToolbarLoaded', () => {
      setIsLoaded(true);

      if (showSpeechstreamToolbar) {
        $('#__bs_entryDiv').css('display', 'initial');
      }

      if (locale && locale.toUpperCase() === 'ES') {
        SpeechStream.setSpeechBookID('ESALL');
        SpeechStream.setVoice('Paulina');
      } else {
        SpeechStream.setSpeechBookID('ALL');

        if (voice) {
          SpeechStream.setVoice(voice);
        }
      }
      if (page) SpeechStream.setSpeechPageID(page);

      window.speechstream.speechTools.setSpeechStartedCallback(() => {
        if (!window.lastIdSpoken || !window.speechstreamPlayButtons) return;

        Object.keys(window.speechstreamPlayButtons)
          .filter(buttonId => buttonId !== (window.lastIdSpoken || '0'))
          .forEach((id) => {
            window.speechstreamPlayButtons[id].stopPlaying();
          });
      });

      window.speechstream.speechTools.setSpeechStoppedCallback(() => {
        if (!window.speechstreamPlayButtons) return;
        if (!window.lastIdSpoken) {
          return window.speechstreamPlayButtons[0] && window.speechstreamPlayButtons[0].stopPlaying();
        }

        return window.speechstreamPlayButtons[window.lastIdSpoken]
          && window.speechstreamPlayButtons[window.lastIdSpoken].stopPlaying();
      });

      SpeechStream.ignoreSelectors(IGNORED_SELECTORS);
    });

    window.speechstreamEventListenerAdded = true;
    window.speechstreamPlayButtons = {};
  },
  ignoreSelectors: (selectors) => {
    selectors.forEach((selector) => {
      window.speechstream.domControlTools.getNewDomControl(window.document).addIgnoreListQuerySelector(selector);
    });
  },
  pause: (playbackStateDispatch) => {
    playbackStateDispatch({ type: 'TOGGLE' });
    window.speechstream.speechTools.pause();
  },
  play: ({ idToRead = 'text_to_speech_start', playbackStateDispatch }: PlaySpeechStreamParams) => {
    playbackStateDispatch({ type: 'PLAY' });
    const selection = window.getSelection().toString();
    const node = selection ? $(`#${idToRead} :contains("${selection}")`).last() : document.getElementById(idToRead);

    window.speechstreamPlayButtons[idToRead] = { stopPlaying: () => playbackStateDispatch({ type: 'STOP' }) };
    window.lastIdSpoken = idToRead;

    if (selection) {
      SpeechStream.setStartPoint(node);
      SpeechStream.setStopAfter(idToRead);
      SpeechStream.setStopAfter(idToRead);
      window.speechstream.speechTools.play();
    } else {
      window.speechstream.speechTools.speakElement(node, false);
    }
  },
  setSpeechBookID: bookId => window.speechstream.speechTools.setSpeechBookID(bookId),
  setSpeechPageID: pageId => window.speechstream.speechTools.setSpeechPageID(pageId),
  setStartPoint: node => window.speechstream.domControlTools.setStartPoint(node),
  setStopAfter: (id) => {
    window.speechstream.domControlTools.getNewDomControl(window.document).addCommand(id, 'stopAfter');
  },
  setVoice: (voiceName) => { if (window.speechstream && window.speechstream.speechTools) window.speechstream.speechTools.setVoice(voiceName); },
  stop: (playbackStateDispatch = null) => {
    if (playbackStateDispatch) playbackStateDispatch({ type: 'STOP' });
    window.speechstream.speechTools.stop();
  },
  eventListenerAdded: () => window.speechstreamEventListenerAdded,
};

export default SpeechStream;
