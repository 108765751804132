import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

const DraggableChoiceContent = (props) => {
  // Configure sanitizeHTML to allow froala styles/images.
  const sanitize = (content) => {
    // wrap strings with superscripts|subscripts in a span to get them to display properly
    let dirty = /<sup>|<sub>/.test(content) ? `<span>${content}</span>` : content;
    // Add `draggable="false"` to img tags, so Safari doesn't give a ghost image
    dirty = content.replaceAll('<img ', '<img draggable="false" ');

    return {
      __html: sanitizeHtml(
        dirty, {
          allowedAttributes: {
            img: ['src', 'alt', 'data-snippet', 'style', 'draggable'],
            li: ['style'],
            p: ['style'],
            span: ['style'],
          },
          allowedClasses: {
            img: ['imageInQuestion', 'fr-fic', 'fr-dii', 'fr-draggable'],
          },
          allowedSchemes: ['data', 'http', 'https'],
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        }
      ),
    }
  };

  return (
    <div
      className={`fr-view fr-element ${props.className}`}
      dangerouslySetInnerHTML={sanitize(props.content)}
    />
  );
};

DraggableChoiceContent.defaultProps = {
  className: '',
  content: '',
};

DraggableChoiceContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

export default DraggableChoiceContent;
