import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DropZone from '../DropZone';
import DraggableChoice from '../DraggableChoice';

const DropZoneWithDraggableChoices = ({
  id, isCorrect, isComplete, isDragDisabled, dropZones, answerChoices, draggableChoices
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const current = ref && ref.current;
    if (!current) return;

    setWidth(current.clientWidth);
    setHeight(current.clientHeight);
  }, [ref && ref.current, setWidth, setHeight]);

  return (
    <div>
      <DropZone
        key={id}
        dropZoneRef={ref}
        id={id}
        isCorrect={isCorrect}
        isDropDisabled={isCorrect || isDragDisabled}
        useAnswerStyles={isComplete || isCorrect}
        {...dropZones[id]}
      >
        {(answerChoices[id] || []).map((choiceId) => {
          if (!draggableChoices[choiceId]) return null;

          return (
            <DraggableChoice
              isDragged
              dragZoneWidth={width}
              dragZoneHeight={height}
              isDragDisabled={isCorrect || isDragDisabled}
              id={choiceId}
              key={choiceId}
              isCorrect={isCorrect}
              title={draggableChoices[choiceId].title}
              useAnswerStyles={isComplete || isCorrect}
              vocabId={draggableChoices[choiceId].vocabId}
            />
          );
        })}
      </DropZone>
    </div>
  );
};

DropZoneWithDraggableChoices.propTypes = {
  id: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isDragDisabled: PropTypes.bool.isRequired,
  dropZones: PropTypes.object.isRequired,
  answerChoices: PropTypes.object.isRequired,
  draggableChoices: PropTypes.object.isRequired,
};

export default DropZoneWithDraggableChoices;
