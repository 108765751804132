import * as Routes from '@/modules/routes';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import useGradesTableStore from '../../../store/useGradesTableStore';
import { IQuestionAnswer, ISlideShow } from '../../../types';
import { QueryKeys } from '../../../GradesTableQueryClientProvider';

const useSlideShowQuestionAnswers = (slideshow: ISlideShow) => {
  const classroom = useGradesTableStore(state => state.classroom);

  const isEnabled = !!(classroom && slideshow);

  const query = useQuery([QueryKeys.SlideShowQuestionAnswers, classroom.id, slideshow.id], () => {
    const path = Routes.plato_api_slideshow_progress_question_answers_path({
      classroom_id: classroom.id,
      slideshow_id: slideshow.id,
      only: ['id', 'student_id', 'score'],
    });

    return axios
      .get<{ data: IQuestionAnswer[] }>(path)
      .then(res => res.data.data)
      .catch(err => console.error(err));
  }, { enabled: isEnabled });

  return { ...query, data: query.data || [] };
};

export default useSlideShowQuestionAnswers;
