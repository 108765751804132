import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useModalManager } from '@/components/common/Modal';
import { closeOnEscapeKey } from '@/components/common/Utils';
import useCompletedStatus from '@/hooks/useCompletedStatus';
import NotebookPreview from '@/components/common/NotebookPreview';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import CompletedStatusIcon from '@/components/common/HtmlElements/QuestionButton/CompletedStatusIcon';
import Tooltip from '@/components/ui/Tooltip';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import IQuestionButton from '@/components/interfaces/graphql/HtmlElement/QuestionButton';
import styles from './QuestionButton.module.scss';

interface QuestionButtonProps {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  questionButton: IQuestionButton;
}

const QuestionButton = ({
  containerRef,
  questionButton: {
    id,
    data: {
      backgroundColor = 'blue',
      buttonMaxWidth,
      buttonTextEn,
      buttonTextEs,
    },
  },
}: QuestionButtonProps) => {
  const modalManager = useModalManager<ModalManager>();
  const buttonRef = useRef();
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const programId = useSlideShowContext(state => state.programId);

  const { refetch } = useCompletedStatus({ id, modelType: 'HtmlElements::QuestionButton' });

  const closeModal = async () => {
    modalManager.close();
    await refetch();
  };

  useEffect(() => {
    modalManager.close();
  }, [currentSlide]);

  closeOnEscapeKey(() => {
    closeModal().then(() => { });
    if (buttonRef.current) $(buttonRef.current).blur();
  });

  return (
    <>
      <Tooltip.Provider>
        <Tooltip open onOpenChange={() => { }}>
          <Tooltip.Trigger
            ref={buttonRef}
            className="btn"
            data-background-color={backgroundColor}
            data-button-text-en={buttonTextEn}
            data-button-text-es={buttonTextEs || buttonTextEn}
            data-html-element-type="QuestionButton"
            style={{ maxWidth: buttonMaxWidth }}
            type="button"
            {...useKeydownAndClick(() => modalManager.open())}
          />
          <Tooltip.Content align="start" alignOffset={-12} side="top" sideOffset={-12}>
            <CompletedStatusIcon id={id} modelType="HtmlElements::QuestionButton" />
          </Tooltip.Content>
        </Tooltip>
      </Tooltip.Provider>
      {containerRef.current && (
        ReactDOM.createPortal(
          <span className={`${styles.popup} ${modalManager.isOpen ? '' : styles.closed}`}>
            <div className="tw-overflow-auto tw-h-full">
              {modalManager.isOpen && (
                <NotebookPreview
                  previewParams={{
                    modelType: 'HtmlElements::QuestionButton',
                    modelId: id,
                    programId,
                  }}
                />
              )}
            </div>
            <i
              className={`fa fa-times-circle ${styles.closeButton}`}
              role="button"
              {...useKeydownAndClick(() => closeModal())}
            />
          </span>,
          containerRef.current.parentElement,
        )
      )}
    </>
  );
};

export default QuestionButton;
