import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckboxGroup from './CheckboxGroup';
import styles from './CheckboxGroupDropdown.module.scss';

export default class CheckboxGroupDropdown extends Component {
  static propTypes = {
    bulkSelectText: PropTypes.string,
    buttonClassName: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownClassName: PropTypes.string,
    formChangeValue: PropTypes.func.isRequired, // React Final Form mutator
    handleChange: PropTypes.func,
    hasBulkSelect: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]).isRequired
    }).isRequired,
    legendSrOnly: PropTypes.bool,
    legendText: PropTypes.string,
    meta: PropTypes.shape({
      error: PropTypes.string,
      pristine: PropTypes.bool,
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired,
    showErrorMessage: PropTypes.bool,
    toggleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  };

  static defaultProps = {
    bulkSelectText: 'Select all',
    buttonClassName: '',
    disabled: false,
    dropdownClassName: '',
    handleChange: () => { },
    hasBulkSelect: false,
    id: `checkbox-dropdown-${Math.floor(Math.random() * 1000)}`,
    legendSrOnly: false,
    legendText: '',
    showErrorMessage: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownExpanded: false
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getCaret() {
    const caretDirection = this.state.dropdownExpanded ? 'fa-caret-up' : 'fa-caret-down';
    return (
      <i className={`${styles.caret} fa ${caretDirection}`} aria-hidden="true" />
    );
  }

  getToggleClassName() {
    const className = [styles.toggle, this.props.buttonClassName];
    if (this.props.disabled) className.push(styles.disabled);
    else {
      if (this.state.dropdownExpanded) className.push(styles.expanded);
      if (this.props.meta.error) className.push(styles.error);
    }
    return className.join(' ');
  }

  getDropdownClassName() {
    let className = styles.dropdown;
    if (this.props.disabled || !this.state.dropdownExpanded) className = `${className} hidden`;
    return `${className} ${this.props.dropdownClassName}`;
  }

  getDropdownId() {
    return `dropdown-${this.props.input.name}`;
  }

  handleClick = (e) => {
    if (!this.dropdownNode.contains(e.target) && this.state.dropdownExpanded) {
      this.toggleDropdown();
    }
  };

  toggleDropdown() {
    if (this.props.disabled) return;
    this.setState(prevState => ({ dropdownExpanded: !prevState.dropdownExpanded }));
  }

  render() {
    return (
      <div className={styles.container} ref={(node) => { this.dropdownNode = node; }}>
        <button
          id={this.props.id}
          aria-controls={this.getDropdownId()}
          aria-expanded={this.state.dropdownExpanded}
          className={this.getToggleClassName()}
          disabled={this.props.disabled}
          onClick={this.toggleDropdown}
          type="button"
        >
          {this.props.toggleText}
          {this.getCaret()}
        </button>

        <div id={this.getDropdownId()} className={this.getDropdownClassName()}>
          <CheckboxGroup
            bulkSelectText={this.props.bulkSelectText}
            formChangeValue={this.props.formChangeValue}
            handleChange={this.props.handleChange}
            hasBulkSelect={this.props.hasBulkSelect}
            input={this.props.input}
            legendSrOnly={this.props.legendSrOnly}
            legendText={this.props.legendText}
            meta={this.props.meta}
            options={this.props.options}
            showErrorMessage={this.props.showErrorMessage}
          />
        </div>
      </div>
    );
  }
}
