import React, { useContext, useRef, useEffect, useState } from 'react';
import DndAnswerContext from 'components/common/Context';
import styles from './DropZoneArea.module.scss';
import cn from '@/utils/cn';
import { backgroundStyle } from '../../utils';
import DropZoneWithDraggableChoices from './DropZoneWithDraggableChoices';
import DropZone from '../DropZone';
import DraggableChoice from '../DraggableChoice';
import TextArea from '../TextArea';
import { CorrectAnswersType, DraggableContainerPosition } from '../../types';
import { DIMENSIONS } from '../index';

interface DropZoneAreaProps {
  correctChoices?: CorrectAnswersType;
  cumulativeDndScalar: number;
  isComplete?: boolean;
  isDragDisabled?: boolean;
  draggableContainerPosition: DraggableContainerPosition;
  previousAnswers?: object;
}
const DropZoneArea = ({
  correctChoices,
  cumulativeDndScalar,
  isComplete = false,
  isDragDisabled = false,
  draggableContainerPosition,
  previousAnswers = {},
}: DropZoneAreaProps) => {
  const backgroundRef = useRef();
  const [wrapperStyles, setWrapperStyles] = useState({});
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const {
    answerChoices,
    background,
    draggableChoices,
    dropZones,
    showAnswerKey,
    textAreas,
    studentId,
  } = useContext(DndAnswerContext);
  const isHorizontallyPositioned = [
    DraggableContainerPosition.Top, DraggableContainerPosition.Bottom
  ].includes(draggableContainerPosition);

  /**
   * If the question is horizontally positioned, this useEffect calculates the new width:
   *
   * - Multiply the current height by the aspect ratio of the question container
   * - Account for the two CSS transform scalings that are done on DnD questions (cumulativeDndScalar prop)
   * - Multiply by 2/3: which is the flex ratio of:
   *   - this component (flex: 2) to:
   *   - DraggableChoicesBank (flex: 1)
   */
  useEffect(() => {
    if (!backgroundRef.current || !isHorizontallyPositioned) return;

    // @ts-ignore
    const sizes = backgroundRef.current?.getBoundingClientRect();
    const aspectRatio = DIMENSIONS.width / DIMENSIONS.height;
    const width = sizes.height * aspectRatio / cumulativeDndScalar * 2 / 3;
    setWrapperHeight(sizes.height / cumulativeDndScalar);
    setWrapperStyles({ width: `${width}px` });
  }, [backgroundRef, backgroundRef.current, setWrapperHeight, setWrapperStyles, isHorizontallyPositioned])

  return (
    <>
      {isHorizontallyPositioned &&
        <div
          className={cn(styles.whiteBackground, {
            'tw-bottom-0': draggableContainerPosition === DraggableContainerPosition.Top,
            'tw-top-0': draggableContainerPosition === DraggableContainerPosition.Bottom,
          })}
          style={{ ...backgroundStyle(background), height: wrapperHeight, backgroundRepeat: 'no-repeat' }}
        />
      }
      <div
        className={cn(styles.dropZoneArea, {
          [styles.horizontalDropZoneArea]: isHorizontallyPositioned,
        })}
        ref={backgroundRef}
        style={{ ...(!isHorizontallyPositioned && backgroundStyle(background)), ...wrapperStyles }}
      >
        <div className={styles.posAbsolute}>
          {Object.keys(dropZones).sort().map((key) => {
            const hasChoices = Object.keys(correctChoices).length > 0;
            const selected = [...previousAnswers[key] || []].sort();
            const correct = [...correctChoices[key] || []].sort();
            const isCorrect = hasChoices && JSON.stringify(selected) === JSON.stringify(correct);
            const shouldUseCorrectChoices = !!(!studentId && showAnswerKey && correctChoices[key])

            return (
              <DropZoneWithDraggableChoices
                id={key}
                key={key}
                isCorrect={shouldUseCorrectChoices || isCorrect}
                isComplete={isComplete}
                isDragDisabled={isDragDisabled}
                dropZones={dropZones}
                answerChoices={shouldUseCorrectChoices ? correctChoices : answerChoices}
                draggableChoices={draggableChoices}
              />
            );
          })}
          {Object.keys(textAreas).sort().map(key => (
            <TextArea
              key={key}
              id={key}
              {...textAreas[key]}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DropZoneArea;
