import React from 'react';
import moment from 'moment-timezone';
import styles from './RosteringStep.module.scss';

interface CompletionStepProps {
  autoRosterDistrict: { enabled: boolean; sync_type: string };
  closeModal: () => void;
  completedSteps: number;
  nextSyncTime: string;
  previous?: () => void;
}

export default function CompletionStep({
  autoRosterDistrict, closeModal, nextSyncTime, previous, completedSteps,
}: CompletionStepProps) {
  const nextScheduledSyncTimeDate = () => {
    if (autoRosterDistrict.enabled) {
      const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
      const formattedSyncTime = moment(nextSyncTime).format(`MMM DD, YYYY [at] hh:mm A [${timezone}]`);

      if (formattedSyncTime === 'Invalid date') return 'N/A';
      return formattedSyncTime;
    }

    return 'Syncs are currently paused.';
  };

  const provider = () => {
    if (autoRosterDistrict.sync_type.includes('clever')) {
      return 'with the Clever API';
    }
    if (autoRosterDistrict.sync_type.includes('oneroster')) {
      return 'with the OneRoster API';
    }

    return 'via CSV';
  };

  const allStepsCompleted = completedSteps[4];

  const modalCloseCTA = () => (
    <button
      type="button"
      className="btn btn--outline-purple mr10"
      onClick={closeModal}
    >
      Close
    </button>
  );

  return (
    <div>
      <p>
        {allStepsCompleted ? (
          <>
            <p className={styles.stepSubSection}><i className="fa fa-check-circle" /> Class Rosters</p>
            Congratulations! Class rosters are now being managed {provider()}.
            The next sync will run at {nextScheduledSyncTimeDate()}.
          </>
        ) : (
          <p>Please return to complete previous steps</p>
        )}
        <br />
      </p>
      <div>
        <hr />
        <footer>
          <button type="button" onClick={previous} className="btn btn--link-purple">
            Back
          </button>
          {allStepsCompleted && modalCloseCTA()}
        </footer>
      </div>
    </div>
  );
}
