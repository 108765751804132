import React from 'react';
import { WrapperFunction } from '../SectionEditorUtils';
import DropdownMenu from './DropdownMenu';
import DropdownOption from './DropdownOption';
import Border from './icons/Border';

const SHADOW_BORDER_CLASS = 'border-shadowed';
const ROUNDED_BORDER_CLASS = 'border-rounded';
const BORDER_CLASS = 'border';

type IBorder = typeof SHADOW_BORDER_CLASS | typeof ROUNDED_BORDER_CLASS | typeof BORDER_CLASS;

interface BorderDropdownProps {
  element: HTMLElement;
  wrapper: WrapperFunction;
}

const BorderDropdown = ({ element, wrapper }: BorderDropdownProps) => {
  const setBorder = wrapper((nextBorder: IBorder) => {
    element.classList.toggle(nextBorder);
  });

  return (
    <DropdownMenu
      content={<Border />}
      height={106}
      left={146}
      id="border"
      title="Border"
    >
      <DropdownOption onClick={() => setBorder('border-rounded')} title="Rounded">
        Rounded
      </DropdownOption>
      <DropdownOption onClick={() => setBorder('border')} title="Bordered">
        Bordered
      </DropdownOption>
      <DropdownOption onClick={() => setBorder('border-shadowed')} title="Shadow">
        Shadow
      </DropdownOption>
    </DropdownMenu>
  );
};

export default BorderDropdown;
