import React from 'react';
import PropTypes from 'prop-types';
import googleIcon from 'images/icons/google-icon.png';
import googleClassroomIcon from 'images/icons/google-classroom-icon.png';

import Banner from 'common/Banner';
import showToast from '../Toast';
import styles from './GoogleSettings.module.scss';
import ConnectionData from '../UserLMSIntegrations/ConnectionData';
import { openAuthPopup, missingScopesMessage, duplicateKeyMessage } from './Utils';
import ConnectButton from '../Settings/IntegrationsCards/ConnectButton';
import UnconnectedCard from '../Settings/IntegrationsCards/CardTypes/UnconnectedCard';
import ConnectedCard from '../Settings/IntegrationsCards/CardTypes/ConnectedCard';

const GoogleSettings = ({
  active, connectionData, updateState, useCardFormat, userType, programId,
}) => {
  if (!active) return null;

  const connectToGoogleClassroom = () => {
    openAuthPopup()
      .then(data => updateState({ googleConnectionData: data }))
      .catch((error) => {
        if (error.message === 'missing_scopes') {
          showToast(missingScopesMessage, { autoClose: 6000, msgHeading: 'Connection failed.', msgType: 'error' });
        }
        else if (error.message === 'duplicate_key') {
          showToast(duplicateKeyMessage, { autoClose: 6000, msgHeading: 'Failed to connect.', msgType: 'error' });
        }
        else {
          showToast('Failed to connect', { autoClose: 6000, msgType: 'error' });
        }
      });
  };

  const hasGoogleAccount = () => (connectionData.user_google_id || connectionData.email);

  const ssiOnly = (connectionData.user_google_id && !connectionData.email);

  const originalFormat = (
    <div>
      <h2 className={`${styles.header} mb10`}>
        Your Google Account
        <img alt="" src={googleIcon} className={styles.googleIcon} />
      </h2>

      {hasGoogleAccount() ?
        <ConnectionData {...connectionData} lmsType="Google Classroom" updateState={updateState} userType={userType} /> :
        <ConnectButton onClick={connectToGoogleClassroom} />}

      <h2 className={`${styles.header} mb10 mt20`}>
        Google Classroom
        <img alt="" src={googleClassroomIcon} className={styles.googleIcon} />
      </h2>

      <Banner iconType="fa-info" color="blue">
        Automatically sync your TCI assignments and grades with Google Classroom.
        <a
          href="https://www.teachtci.com/tci-google-classroom-help"
          className="ml3"
          target="_blank"
          rel="noopener noreferrer"
        >
          Discover how to get started.
        </a>
      </Banner>
      {ssiOnly && (
        <div className="mt20">
          <ConnectButton onClick={connectToGoogleClassroom}>
            Connect to Google Classroom
          </ConnectButton>
        </div>
      )}
    </div>
  );

  if (useCardFormat) {
    if (!hasGoogleAccount()) {
      return (
        <UnconnectedCard
          bodyText="Sync Assignments and Grades"
          connectLMS={connectToGoogleClassroom}
          connectionText="Connect to Google"
          headerText="Google Classroom"
          icon={googleClassroomIcon}
        />
      );
    }

    const googleConnectionDataNode = (
      <span>
        <ConnectionData {...connectionData} lmsType="Google Classroom" updateState={updateState} userType={userType} />
        {ssiOnly && (
          <div className="mt20">
            <ConnectButton onClick={connectToGoogleClassroom} withIcon>
              Connect to Google Classroom
            </ConnectButton>
          </div>
        )}
      </span>
    );

    return (
      <ConnectedCard
        connectionData={googleConnectionDataNode}
        helpArticleHref="https://teachtci.com/google-classroom-integration"
        icon={googleClassroomIcon}
        lmsType="Google Classroom"
        programId={programId}
      />
    );
  }

  return originalFormat;
};

GoogleSettings.propTypes = {
  active: PropTypes.bool.isRequired,
  connectionData: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    user_google_id: PropTypes.number
  }).isRequired,
  programId: PropTypes.number.isRequired,
  updateState: PropTypes.func.isRequired,
  useCardFormat: PropTypes.bool,
  userType: PropTypes.string
};

GoogleSettings.defaultProps = {
  useCardFormat: false,
  userType: 'Student'
};

export default GoogleSettings;
