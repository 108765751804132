import Tooltip from '@/components/ui/Tooltip';
import Popover from '@/components/ui/Popover';
import cn from '@/utils/cn';
import React from 'react';

interface ActionProps {
  children?: ReactChildren,
  className: string;
  onClick?: () => any;
  PopoverContent?: any;
  tooltipContent?: any;
}

const Action = ({
  children, className, onClick = () => {}, PopoverContent, tooltipContent,
}: ActionProps) => {
  const testId = typeof tooltipContent === 'string' ? `${tooltipContent.toLowerCase()}-object` : 'more';
  const PopoverComponent = PopoverContent ? Popover.Trigger : 'button';

  return (
    <Tooltip.Provider>
      <Tooltip>
        <Popover>
          <Tooltip.Trigger asChild>
            <PopoverComponent
              data-testid={testId}
              type="button"
              role="menuitem"
              className={cn(
                'tw-border-0 tw-bg-transparent tw-p-2 hover:tw-bg-gray-100',
                'tw-cursor-pointer tw-rounded-sm tw-transition-colors',
                'tw-flex tw-items-center tw-justify-center',
                className,
              )}
              onClick={() => setTimeout(onClick, 0)}
            >
              {children}
            </PopoverComponent>
          </Tooltip.Trigger>
          <Tooltip.Content className="tw-bg-white tw-shadow tw-rounded-sm tw-text-sm tw-py-1 tw-px-2">
            {tooltipContent}
            <Tooltip.Arrow className="tw-fill-white tw-bg-transparent" />
          </Tooltip.Content>
          <Popover.Content
            side="right"
            className="!tw-w-[unset]"
          >
            {PopoverContent}
          </Popover.Content>
        </Popover>
      </Tooltip>
    </Tooltip.Provider>
  );
};

export default Action;
