import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadToDriveModal from './UploadModal';
import { appendRandomQueryString } from '../../../modules/TCIUtils';

export default class SaveToGoogleDrive extends Component {
  static propTypes = {
    button: PropTypes.bool,
    canToggleLanguage: PropTypes.bool,
    fileName: PropTypes.string,
    fileUrl: PropTypes.string.isRequired,
    headerLabel: PropTypes.string,
    linkClasses: PropTypes.string,
    linkText: PropTypes.string,
    wrapper_classes: PropTypes.string,
    assessmentLibrary: PropTypes.bool,
  };

  static defaultProps = {
    button: true,
    canToggleLanguage: false,
    fileName: 'TCI File',
    headerLabel: 'Save to Google Drive',
    linkClasses: '',
    linkText: 'Save To Drive',
    wrapper_classes: '',
    assessmentLibrary: false,
  };

  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false, modalHasBeenOpened: false };
    this._openUploadModal = this._openUploadModal.bind(this);
    this._closeUploadModal = this._closeUploadModal.bind(this);
    this.successSignOn = this.successSignOn.bind(this);
  }

  _openUploadModal(event) {
    event.preventDefault();

    // don't open modal if client library isn't loaded
    if (!window.googleClientLoaded) return;

    // if no user is signed in, prompt sign in before opening modal
    if (this.isSignedIn()) {
      const user = gapi.auth2.getAuthInstance().currentUser.get();
      this.successSignOn(user);
    }
    else {
      gapi.auth2.getAuthInstance().signIn().then(
        user => this.successSignOn(user),
        error => console.log(error)
      );
    }
  }

  isSignedIn() {
    return gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  successSignOn(user) {
    const profile = user.getBasicProfile();
    this.setState({
      imageUrl: profile.getImageUrl(),
      name: profile.getName(),
      email: profile.getEmail(),
      modalIsOpen: true,
      modalHasBeenOpened: true
    });
  }

  _closeUploadModal() {
    this.setState({ modalIsOpen: false });
  }

  renderModal() {
    if (!this.state.modalHasBeenOpened) return null;

    return (
      <UploadToDriveModal
        canToggleLanguage={this.props.canToggleLanguage}
        isOpen={this.state.modalIsOpen}
        closeModal={this._closeUploadModal}
        fileUrl={appendRandomQueryString(this.props.fileUrl)}
        fileName={this.props.fileName}
        imageUrl={this.state.imageUrl}
        name={this.state.name}
        email={this.state.email}
        successSignOnCallback={this.successSignOn}
        headerLabel={this.props.headerLabel}
        assessmentLibrary={this.props.assessmentLibrary}
      />
    );
  }

  renderButton() {
    return (
      <span>
        <button
          type="button"
          onClick={this._openUploadModal}
        >
          Save To Drive
        </button>
      </span>
    );
  }

  renderLink() {
    return (
      <a
        href="#"
        role="button"
        onClick={this._openUploadModal}
        dangerouslySetInnerHTML={{ __html: this.props.linkText }}
        className={`${this.props.linkClasses} js-prevent-default`}
      />
    );
  }

  render() {
    return (
      <div className={this.props.wrapper_classes} title="Save to Google Drive">
        {this.props.button ? this.renderButton() : this.renderLink()}
        {this.renderModal()}
      </div>
    );
  }
}
