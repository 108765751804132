import React from 'react';

export function TeacherIcon(props) {
  return (
    <svg className={`source-icon teacher ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.61 50.66">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="teacher-svg" d="M2.38,35.55a5.32,5.32,0,0,1,3.38-3.67l10.11-3.33a3.16,3.16,0,0,0,2.43-2.84c-1.55-1.62-1.92-3.75-2.41-5.65l-.68-.4a16.86,16.86,0,0,1-1.09-6.77l.51-.39c-.13-1.85-.08-5.18.8-6.82,3.39-6.24,14.33-6.24,17.71,0,.88,1.64.94,5,.8,6.82l.51.39a16.86,16.86,0,0,1-1.09,6.77l-.68.4c-.49,1.9-.86,4-2.4,5.65a3.16,3.16,0,0,0,2.06,2.73l10.48,3.44a5.31,5.31,0,0,1,3.37,3.67l1.32,14.11H1.1L2.38,35.55Z" />
        </g>
      </g>
    </svg>
  );
}

export function PrintIcon(props) {
  return (
    <svg className={`source-icon ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.57 85.76">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-1" d="M75.3,26.07H71.74V9.26A9.27,9.27,0,0,0,62.48,0H22.09a9.27,9.27,0,0,0-9.26,9.26V26.07H9.26A9.27,9.27,0,0,0,0,35.33V62.19a9.27,9.27,0,0,0,9.26,9.26h3.57v5a9.27,9.27,0,0,0,9.26,9.26H62.48a9.27,9.27,0,0,0,9.26-9.26v-5H75.3a9.27,9.27,0,0,0,9.27-9.26V35.33A9.27,9.27,0,0,0,75.3,26.07ZM17,9.26a5.09,5.09,0,0,1,5.08-5.08H62.48a5.09,5.09,0,0,1,5.08,5.08V26.07H17ZM67.56,76.5a5.09,5.09,0,0,1-5.08,5.08H22.09A5.09,5.09,0,0,1,17,76.5V53.41H67.56ZM80.38,62.19a5.08,5.08,0,0,1-5.08,5.08H71.74v-18H12.83v18H9.26a5.09,5.09,0,0,1-5.08-5.08V35.33a5.09,5.09,0,0,1,5.08-5.08h66a5.08,5.08,0,0,1,5.08,5.08Z" />
        </g>
      </g>
    </svg>
  );
}

export function KitIcon(props) {
  return (
    <svg className={`source-icon ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 48.58">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-1" d="M1.74,48.58h53V26.46H57v-13a5.14,5.14,0,0,0-5.14-5.14H40.07V0H16.42V8.32H5.14A5.14,5.14,0,0,0,0,13.46v13H1.74Zm51-2h-49V26.46H22.06a6.27,6.27,0,0,0,12.37,0H52.74ZM23.67,25.41A4.58,4.58,0,1,1,28.24,30,4.58,4.58,0,0,1,23.67,25.41ZM18.42,2H38.07V8.32h-2V4H20.42V8.32h-2ZM34.07,8.32H22.42V6H34.07ZM2,13.46a3.15,3.15,0,0,1,3.14-3.14H51.86A3.15,3.15,0,0,1,55,13.46v11H34.44a6.27,6.27,0,0,0-12.4,0H2Z" /><polygon className="cls-1" points="7.74 35.33 5.74 35.33 5.74 44.58 12.74 44.58 12.74 42.58 7.74 42.58 7.74 35.33" />
          <polygon className="cls-1" points="50.74 44.58 50.74 35.33 48.74 35.33 48.74 42.58 43.74 42.58 43.74 44.58 50.74 44.58" />
          <polygon className="cls-1" points="27.24 28.66 29.24 28.66 29.24 26.41 31.49 26.41 31.49 24.41 29.24 24.41 29.24 22.16 27.24 22.16 27.24 24.41 24.99 24.41 24.99 26.41 27.24 26.41 27.24 28.66" />
        </g>
      </g>
    </svg>
  );
}
