import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EnableMFAForm from '../Enable/EnableMFAForm';

interface EnforceMFAProps {
  mfaEnabled?: boolean;
  invalidCode?: string;
  userId: string;
  QRCodeURI?: string;
}

const queryClient = new QueryClient();

const EnforceMFA = ({ mfaEnabled, invalidCode, userId, QRCodeURI }: EnforceMFAProps) => (
  <EnableMFAForm
    inline
    mfaEnabled={mfaEnabled}
    invalidCode={invalidCode}
    userId={userId}
    QRCodeURI={QRCodeURI}
  />
);

const EnforceMFAWrapper = ({ mfaEnabled, invalidCode, userId, QRCodeURI }:EnforceMFAProps) => (
  <QueryClientProvider client={queryClient}>
    <EnforceMFA mfaEnabled={mfaEnabled} invalidCode={invalidCode} userId={userId} QRCodeURI={QRCodeURI} />
  </QueryClientProvider>
);


export default EnforceMFAWrapper;
