import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { plato_api_slide_sound_clip_path, plato_api_slide_sound_clips_path } from '@/modules/routes';

import Modal, { Footer } from 'common/Modal';
import { FileField, CheckboxField } from 'common/Forms';
import { composeValidators, fileSizeLimiter, required } from 'common/Forms/Validators';
import Tooltip from 'common/Tooltip';
import { addFileToInput, slideObjectPropTypes } from '@/components/admin/SlideShow/Utils';
import simpleAudioButtonPlayerImage from 'images/audioButtonTooltip/simple-audio-button-image.png';
import htmlAudioPlayerImage from 'images/audioButtonTooltip/html5-audio-player-image.png';
import styles from './CustomAudioModal.module.scss';
import { insertIntoEditor } from '../FroalaListeners';

const AudioForm = ({
  $audioButtonToEdit, editorInstance, modalManager, slideObject, slideSoundClipId, useFullPlayerInitialValue, userType
}) => {
  const { data, isLoading: audioFileLoading } = useQuery({
    queryFn: () => (
      !slideSoundClipId ? null : axios
        .get(plato_api_slide_sound_clip_path(slideSoundClipId))
        .then(res => res.data)
        .catch(e => console.log(e))
    ),
    queryKey: [slideSoundClipId]
  });
  const [audioFile, setAudioFile] = useState(null);
  const [audioFileEs, setAudioFileEs] = useState(null);

  useLayoutEffect(() => {
    // pre-populate the input with an audio file if given
    if (slideSoundClipId && data && !audioFileLoading) {
      addFileToInput({
        callback: setAudioFile,
        filename: data.slideSoundClipFileName,
        inputElementId: 'audioFile_field',
        url: data.s3Url
      });
      addFileToInput({
        callback: setAudioFileEs,
        filename: data.slideSoundClipEsFileName,
        inputElementId: 'audioFileEs_field',
        url: data.s3UrlEs
      });
    }
  }, [data]);

  const audioTag = (slideSoundClip, useFullPlayer) => {
    if (useFullPlayer) {
      return (
        <span className="full-player-span" contentEditable="false">
          <audio
            controls
            src={slideSoundClip.s3Url}
            data-sound-clip-id={slideSoundClip.id}
            data-src-es={slideSoundClip.s3UrlEs}
          >
            Your browser does not support the audio element.
          </audio>
        </span>
      );
    }

    return (
      <span
        className="simple-audio-player btn btn--md btn--outline-purple"
        contentEditable={false}
        data-command="play"
        data-sound-clip-id={slideSoundClip.id}
        data-src={slideSoundClip.s3Url}
        data-src-es={slideSoundClip.s3UrlEs}
        role="button"
        tabIndex={0}
      >
        <i aria-hidden className="fa fa-play" contentEditable="false" />
      </span>
    );
  };

  const modifyExistingButton = (newSlideSoundClip, useFullPlayer) => {
    const srcAttr = useFullPlayer ? 'src' : 'data-src';
    $audioButtonToEdit.attr('data-sound-clip-id', newSlideSoundClip.id).attr(srcAttr, newSlideSoundClip.s3Url);

    if (newSlideSoundClip.s3UrlEs) {
      $audioButtonToEdit.attr('data-src-es', newSlideSoundClip.s3UrlEs);
    }
  };

  const { isLoading: loading, mutate: createSlideSoundClip } = useMutation({
    mutationFn: formData => (
      axios.post(plato_api_slide_sound_clips_path(), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ),
    onSuccess: (res, formData) => {
      const newSlideSoundClip = res.data;

      // if we're editing an existing audio button
      if ($audioButtonToEdit) {
        if (formData.use_full_player !== useFullPlayerInitialValue) {
          // if we're changing it to use the full player, delete the DOM node and insert an html5 audio tag
          editorInstance.selection.setBefore($audioButtonToEdit[0]);
          $audioButtonToEdit.remove();

          insertIntoEditor(editorInstance, audioTag(newSlideSoundClip, formData.use_full_player));
        }
        else {
          // otherwise just modify the data-sound-clip-id and data-src
          modifyExistingButton(newSlideSoundClip, formData.use_full_player);
        }
      }
      else {
        insertIntoEditor(editorInstance, audioTag(newSlideSoundClip, formData.use_full_player));
      }

      modalManager.close();
    },
    onError: e => console.log(e),
  });

  const submitForm = (formValues) => {
    const variables = {
      audio_file: formValues.audioFile,
      audio_file_es: formValues.audioFileEs,
      skip_transcription: formValues.skip_transcription,
      slide_object_id: slideObject.id,
      use_full_player: formValues.use_full_player,
    };

    createSlideSoundClip(variables);
  };

  const fileField = () => {
    if (audioFileLoading) return ('Loading Audio File');

    if (userType === 'Teacher') {
      return (
        <FileField
          name="audioFile"
          label="Audio File"
          accept=".mp3"
          required
          validate={composeValidators(required, fileSizeLimiter(20, 'File'))}
          fileTypeText="File Requirement: .MP3 and less than 20MB"
        />
      );
    }

    return (
      <FileField
        name="audioFile"
        label="Audio File"
        accept="audio/mp3, audio/mp4, audio/mpeg"
        required
        validate={required}
      />
    );
  };

  const tooltipContent = () => (
    <div className={styles.tooltip}>
      <p>Default player:</p>
      <img className={styles.simpleAudioButtonImage} src={simpleAudioButtonPlayerImage} alt="simplified play button" />
      <p className="mt15">Full player:</p>
      <img className={styles.htmlAudioImage} src={htmlAudioPlayerImage} alt="full sized audio player" />
    </div>
  );

  const label = () => (
    <span>
      Use full player
      {' '}
      <Tooltip
        allowHTML
        content={tooltipContent()}
        interactive={false}
        placement="right"
        size="medium"
        theme="white"
      >
        <i className="fa fa-question-circle" />
      </Tooltip>
    </span>
  );

  return (
    <Form
      onSubmit={submitForm}
      initialValues={{
        audioFile,
        audioFileEs,
        skip_transcription: data?.slideSoundClip?.skipTranscription,
        use_full_player: useFullPlayerInitialValue,
      }}
      render={({
        form,
        handleSubmit,
        invalid,
        pristine
      }) => (
        <form onSubmit={handleSubmit}>
          {fileField()}
          {userType !== 'Teacher' && (
            <FileField
              name="audioFileEs"
              label="Audio File (Spanish)"
              accept="audio/mp3, audio/mp4, audio/mpeg"
            />
          )}
          <CheckboxField
            name="use_full_player"
            label={label()}
            labelPos="right"
            fieldClass="mr5"
          />
          {userType !== 'Teacher' && (
            <CheckboxField
              name="skip_transcription"
              label="Skip transcription"
              labelPos="right"
              fieldClass="mr5"
            />
          )}
          <Footer
            submitting={loading}
            secondaryButtonCallback={modalManager.close}
            primaryButtonText="Submit"
            primaryButtonDisabled={invalid || pristine}
          />
        </form>
      )}
    />
  );
};

AudioForm.propTypes = {
  $audioButtonToEdit: PropTypes.object,
  editorInstance: PropTypes.object,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  slideSoundClipId: PropTypes.string,
  useFullPlayerInitialValue: PropTypes.bool,
  userType: PropTypes.string.isRequired,
};

AudioForm.defaultProps = {
  $audioButtonToEdit: null,
  editorInstance: {},
  slideSoundClipId: '',
  useFullPlayerInitialValue: false,
};

const CustomAudioModal = ({
  $audioButtonToEdit, editorInstance, modalManager, setAudioButtonToEdit,
  setSlideSoundClipId, slideObject, slideSoundClipId, userType
}) => {
  const closeModal = () => {
    setAudioButtonToEdit(null);
    setSlideSoundClipId('');
    modalManager.close();
  };

  const useFullPlayer = $audioButtonToEdit && $audioButtonToEdit.prop('tagName') === 'AUDIO';

  return (
    <Modal
      isOpen={modalManager.isOpen}
      closeModal={closeModal}
      headerText={`${slideSoundClipId ? 'Edit' : 'Add'} Audio`}
    >
      <AudioForm
        $audioButtonToEdit={$audioButtonToEdit}
        slideSoundClipId={slideSoundClipId}
        editorInstance={editorInstance}
        modalManager={modalManager}
        slideObject={slideObject}
        useFullPlayerInitialValue={useFullPlayer}
        userType={userType}
      />
    </Modal>
  );
};

CustomAudioModal.propTypes = {
  $audioButtonToEdit: PropTypes.object,
  editorInstance: PropTypes.object,
  modalManager: PropTypes.shape({
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
  }).isRequired,
  setAudioButtonToEdit: PropTypes.func.isRequired,
  setSlideSoundClipId: PropTypes.func.isRequired,
  slideObject: slideObjectPropTypes.isRequired,
  slideSoundClipId: PropTypes.string,
  userType: PropTypes.string.isRequired,
};

CustomAudioModal.defaultProps = {
  $audioButtonToEdit: null,
  editorInstance: {},
  slideSoundClipId: '',
};

export default CustomAudioModal;
