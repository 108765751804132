import React from 'react';
import { IIconProps } from './utils';

export const Assessments = ({ fill = 'currentColor' }: IIconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_99_4354)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 1.25C5.98858 1.25 3.75 3.48858 3.75 6.25V22.5V23.75C3.75 26.5114 5.98858 28.75 8.75 28.75H11C11.6904 28.75 12.25 28.1904 12.25 27.5C12.25 26.8096 11.6904 26.25 11 26.25H8.75C7.36929 26.25 6.25 25.1307 6.25 23.75V22.5V6.25C6.25 4.86929 7.36929 3.75 8.75 3.75H13.75C14.4404 3.75 15 3.19036 15 2.5C15 1.80964 14.4404 1.25 13.75 1.25H8.75ZM18.75 1.25C18.0596 1.25 17.5 1.80964 17.5 2.5C17.5 3.19036 18.0596 3.75 18.75 3.75H20C21.3807 3.75 22.5 4.86929 22.5 6.25V12.8083C22.5 13.4986 23.0596 14.0583 23.75 14.0583C24.4404 14.0583 25 13.4986 25 12.8083V6.25C25 3.48858 22.7614 1.25 20 1.25H18.75ZM8.75 7.5C8.05964 7.5 7.5 8.05964 7.5 8.75C7.5 9.44036 8.05964 10 8.75 10H18.75C19.4404 10 20 9.44036 20 8.75C20 8.05964 19.4404 7.5 18.75 7.5H8.75ZM8.75 12.5C8.05964 12.5 7.5 13.0596 7.5 13.75C7.5 14.4404 8.05964 15 8.75 15H18.75C19.4404 15 20 14.4404 20 13.75C20 13.0596 19.4404 12.5 18.75 12.5H8.75ZM8.75 17.5C8.05964 17.5 7.5 18.0596 7.5 18.75C7.5 19.4404 8.05964 20 8.75 20H15C15.6904 20 16.25 19.4404 16.25 18.75C16.25 18.0596 15.6904 17.5 15 17.5H8.75ZM26.4108 16.4464L27.3036 17.3392C27.8988 17.9344 27.8988 18.8994 27.3036 19.4947L26.642 20.1562C26.4917 20.3065 26.248 20.3065 26.0977 20.1562L23.5938 17.6523C23.4435 17.502 23.4435 17.2583 23.5938 17.108L24.2554 16.4464C24.8506 15.8512 25.8156 15.8512 26.4108 16.4464ZM21.9609 18.7408L14.9828 25.7189C14.7701 25.9317 14.625 26.2027 14.566 26.4977L14.0077 29.2894C13.9538 29.5587 14.1913 29.7962 14.4606 29.7423L17.2523 29.184C17.5473 29.125 17.8183 28.9799 18.0311 28.7672L25.0092 21.789C25.1595 21.6387 25.1595 21.395 25.0092 21.2447L22.5052 18.7408C22.3549 18.5905 22.1112 18.5905 21.9609 18.7408Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_99_4354">
        <rect width="30" height="30" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);
