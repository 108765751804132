import React, { useEffect } from 'react';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/ui/Tooltip';
import cn from '@/utils/cn';
import ReactSelect5 from '@/components/common/ReactSelect5';
import { ImagePreview } from '@/components/common/Forms';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { CurrentValues, SchemaType } from './utils';

const TEXTURE_TYPE_OPTIONS = [
  { label: 'Bump / Height', value: 'bump' },
  { label: 'Color / Diffuse', value: 'color' },
  { label: 'Displacement', value: 'displacement' },
  { label: 'Emissive', value: 'emissive' },
  { label: 'Metallic', value: 'metallic' },
  { label: 'Normal', value: 'normal' },
  { label: 'Opacity', value: 'opacity' },
  { label: 'Roughness', value: 'roughness' },
] as const;

type Option = typeof TEXTURE_TYPE_OPTIONS[number];

const validFileExtensions = ['.jpg', '.jpeg', '.png', '.bmp', '.tiff'] as const;
const fileRequirementText = `File Requirement: ${validFileExtensions.join(', ')}`;

interface ThreeDModelTextureFieldsProps {
  currentValues: CurrentValues;
  form: UseFormReturn<SchemaType, any>;
}

const ThreeDModelTextureFields = ({ currentValues, form }: ThreeDModelTextureFieldsProps) => {
  const { watch, control, register } = form;
  const { append, fields, remove } = useFieldArray<SchemaType, 'textures', 'rhfId'>({
    control,
    keyName: 'rhfId',
    name: 'textures',
  });

  const addItem = () => append(undefined);

  useEffect(() => {
    if (watch('textures').length > 0) return () => { };

    addItem();

    return () => watch('textures').forEach((_, i) => remove(i));
  }, [watch('model')]);

  return (
    <div>
      <div className="tw-flex tw-gap-12">
        <div>
          <label className="after:tw-text-red after:tw-content-['*']" htmlFor="textures">
            <span className="tw-font-bold tw-whitespace-nowrap">Texture File(s)</span>
          </label>
          <Tooltip.Provider>
            <Tooltip>
              <Tooltip.Trigger asChild>
                <span className="tw-block tw-ml-1 -tw-mr-4 tw-cursor-pointer fa fa-question-circle" />
              </Tooltip.Trigger>
              <Tooltip.Content
                className="tw-bg-white tw-shadow-md tw-max-w-[200px] tw-rounded tw-py-2 tw-px-4 tw-text-center"
              >
                <p className="tw-leading-5">
                  For
                  {' '}
                  <span className="tw-font-bold">.obj</span>
                  {' '}
                  and
                  {' '}
                  <span className="tw-font-bold">.fbx</span>
                  {' '}
                  files, upload a texture file to add details to your 3D model.
                </p>
                <Tooltip.Arrow className="tw-fill-white tw-bg-transparent" />
              </Tooltip.Content>
            </Tooltip>
          </Tooltip.Provider>
        </div>
        <div className="tw-flex-grow">
          {fields.map((field, i) => {
            const shouldDestroy = watch(`textures.${i}.destroy`);
            const currentTexture = (currentValues.textures || [])[i];

            const handleRemove = () => {
              if (field.id) form.setValue(`textures.${i}.destroy`, true);
              else remove(i);
            };

            return (
              <div
                className={cn(
                  'tw-mb-8',
                  { 'tw-hidden': shouldDestroy },
                )}
                key={`textures.${i}`}
              >
                <div>
                  <div
                    data-requirement-text={fileRequirementText}
                    className={cn(
                      'tw-relative after:tw-content-[attr(data-requirement-text)] tw-flex tw-items-center tw-gap-4',
                      'after:tw-absolute after:tw-left-0 after:tw-top-full after:tw-mt-1',
                      'after:tw-w-max',
                    )}
                  >
                    {currentTexture && (
                      <ImagePreview src={currentTexture.imageUrl} />
                    )}
                    <input
                      className="tw-w-max"
                      accept="image/*"
                      key={field.id}
                      type="file"
                      {...register(`textures.${i}.file` as const)}
                    />
                    <Controller
                      name={`textures.${i}.texture_type`}
                      control={control}
                      render={({ field: selectField }) => (
                        <div className="tw-flex tw-items-center tw-gap-2 tw-ml-auto">
                          <label
                            className="after:tw-text-red after:tw-content-['*']"
                            htmlFor={`textures.${i}.texture_type`}
                          >
                            <span className="tw-font-bold">Type</span>
                          </label>
                          <ReactSelect5
                            {...selectField}
                            onChange={(opt: Option) => selectField.onChange(opt.value)}
                            options={TEXTURE_TYPE_OPTIONS}
                            placeholder="Texture Type"
                            menuPosition="fixed"
                            fontSizeOverride={14}
                            value={TEXTURE_TYPE_OPTIONS.find(opt => opt.value === selectField.value)}
                          />
                        </div>
                      )}
                    />
                    <button
                      className={cn(`
                          tw-bg-red tw-text-white tw-border-none tw-cursor-pointer tw-ml-4
                          tw-inline-flex tw-justify-center tw-items-center tw-p-2 tw-rounded
                        `, {
                      })}
                      type="button"
                      onClick={handleRemove}
                    >
                      {I18n.t('remove')}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <button
            className="tw-cursor-pointer tw-bg-transparent tw-border-none tw-font-bold tw-font-tci tw-p-0"
            onClick={addItem}
            type="button"
          >
            <span
              className={`tw-text-white tw-bg-green tw-rounded-full tw-h-[16px] tw-w-[16px] 
                          tw-p-0.5 tw-mr-2 tw-inline-flex tw-items-center tw-justify-center`}
            >
              <span className="fa fa-plus tw-mt-[1px]" />
            </span>
            Add another texture
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThreeDModelTextureFields;
