import { useEffect } from 'react';

interface UseTurnInListenerProps {
  questionId: number;
  setIsDirty: (isDirty: boolean) => void;
}

const useTurnInListener = ({ questionId, setIsDirty }: UseTurnInListenerProps) => {
  const handleTurnIn = (e) => {
    if ((e.detail.questionIds as number[]).includes(questionId)) {
      setIsDirty(false);
    }
  };

  useEffect(() => {
    window.addEventListener('turnIn', handleTurnIn);

    return () => {
      window.removeEventListener('turnIn', handleTurnIn);
    };
  }, []);
};

export default useTurnInListener;
