import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextHighlighter.module.scss';

export const HighlightButton = ({ color, createHighlight }) => (
  <button
    className={`${styles.button} ${styles.highlightIcon} ${styles[color]}`}
    type="button"
    aria-label="Add highlight"
    onClick={createHighlight}
    onTouchStart={createHighlight}
  />
);

HighlightButton.propTypes = { color: PropTypes.string.isRequired, createHighlight: PropTypes.func.isRequired };

export const ColorButton = ({ color, onColorChange }) => (
  <button
    type="button"
    className={`${styles.button} ${styles.colorPicker} ${styles[color]}`}
    onClick={() => onColorChange({ color })}
    onTouchStart={() => onColorChange({ color })}
  />
);

ColorButton.propTypes = { color: PropTypes.string.isRequired, onColorChange: PropTypes.func.isRequired };

export const DeleteButton = ({ deleteHighlights }) => (
  <button
    className={`${styles.button} ${styles.trash}`}
    type="button"
    aria-label="Delete highlight"
    onClick={deleteHighlights}
  >
    <i className="fa fa-trash" />
  </button>
);

DeleteButton.propTypes = { deleteHighlights: PropTypes.func.isRequired };

export default function Tooltip({
  createHighlight, currentColor, deleteHighlights, mode, showDelete, mobileDevice, updateHighlight, xPosition, yPosition
}) {
  const onColorChange = mode === 'create' ? createHighlight : updateHighlight;

  return (
    <div
      className={`${styles.tooltip} ${mobileDevice ? styles.mobile : styles.desktop}`}
      style={{
        left: `${xPosition}px`,
        top: `${yPosition}px`
      }}
    >
      {mode === 'create' && <HighlightButton color={currentColor} createHighlight={createHighlight} />}

      <ColorButton color="green" onColorChange={onColorChange} />
      <ColorButton color="blue" onColorChange={onColorChange} />
      <ColorButton color="orange" onColorChange={onColorChange} />
      <ColorButton color="rose" onColorChange={onColorChange} />
      <ColorButton color="yellow" onColorChange={onColorChange} />
      <ColorButton color="purple" onColorChange={onColorChange} />

      {showDelete && <DeleteButton deleteHighlights={deleteHighlights} />}

      <div className={styles.triangle} />
    </div>
  );
}

Tooltip.propTypes = {
  createHighlight: PropTypes.func.isRequired,
  currentColor: PropTypes.string.isRequired,
  deleteHighlights: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  showDelete: PropTypes.bool.isRequired,
  mobileDevice: PropTypes.bool.isRequired,
  updateHighlight: PropTypes.func.isRequired,
  xPosition: PropTypes.number.isRequired,
  yPosition: PropTypes.number.isRequired
};
