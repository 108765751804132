import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'common/ApolloClient';
import Modal, { useModalManager } from 'common/Modal';
import buttonStyles from '../ConnectClassroom/ConnectClassroomButton.module.scss';
import googleClassroomLogo from '../ConnectClassroom/google_logo.svg';
import canvasLogo from '../ConnectClassroom/canvas_logo.svg';
import styles from './ConnectStudentsButton.module.scss';
import ModalContent from './ModalContent';

const ModalHeader = ({ isGoogleIntegration }) => (
  <div className="dsp-flex-center">
    <img className="mr10" src={isGoogleIntegration ? googleClassroomLogo : canvasLogo} width="30px" alt="" />
    Connect Student Accounts
  </div>
);

ModalHeader.propTypes = {
  isGoogleIntegration: PropTypes.bool.isRequired
};

const ConnectStudentsButton = ({
  classroomId, lmsType, programId, subscriberId, updateClassroom
}) => {
  const { isOpen, close, open } = useModalManager();
  const isGoogleIntegration = lmsType === 'google';

  return (
    <ApolloProvider client={apolloClient}>
      <button
        className="dropdown-item full-size"
        type="button"
        onClick={open}
      >
        <div className={buttonStyles.googleClassroomButton}>
          <img alt="" src={isGoogleIntegration ? googleClassroomLogo : canvasLogo} />
          Manage Students on {isGoogleIntegration ? 'Google Classroom' : 'Canvas'}
        </div>
      </button>
      <Modal
        headerText={<ModalHeader isGoogleIntegration={isGoogleIntegration} />}
        isOpen={isOpen}
        closeModal={close}
        className={styles.modal}
      >
        <ModalContent
          classroomId={classroomId}
          closeModal={close}
          lmsType={lmsType}
          programId={programId}
          subscriberId={subscriberId}
          updateClassroom={updateClassroom}
        />
      </Modal>
    </ApolloProvider>
  );
};

ConnectStudentsButton.propTypes = {
  classroomId: PropTypes.number.isRequired,
  lmsType: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
  updateClassroom: PropTypes.func.isRequired
};

export default ConnectStudentsButton;
