import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PaginatedTable from '../../common/DataTable/PaginatedTable';
import ActionsDropdown from '../../common/ActionsDropdown';
import styles from './ReferenceMaterialsTable.module.scss';
import AdvancedFilters from '../../common/AdvancedFilters';
import { buildFilterPath, filtersPropTypes, propertyPropTypes } from '../../common/AdvancedFilters/Utils';
import DeleteModalButton from '../../common/DeleteModalButton';
import Checkbox from '../../common/Checkbox';
import BulkAddProgram from './BulkAddProgram';
import { matchAttributesForSingleRow } from '../../common/DataTable/Utils';
import ModalButton from '../../common/ModalButton';

export default class ReferenceMaterialsTable extends Component {
  static propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    columns: PropTypes.instanceOf(Array).isRequired,
    initialFilters: filtersPropTypes,
    pageSize: PropTypes.number,
    paths: PropTypes.objectOf(PropTypes.string).isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    properties: PropTypes.arrayOf(propertyPropTypes).isRequired
  };

  static defaultProps = {
    initialFilters: [{ operatorValue: 'cont', propertyValue: 'programs_full_title_with_edition' }],
    pageSize: 100,
  };

  static getColumnMapping() {
    return [
      null,
      'image',
      'title',
      'questions_count',
      'programs',
      'non_standards_tags',
      'published',
      'created_at',
      'updated_at',
      null
    ];
  }

  static displayImage(imageUrl, title) {
    if (!imageUrl) {
      return (
        <div>
          No image
        </div>
      );
    }

    return (
      <img className={styles.previewImage} src={imageUrl} alt={title} />
    );
  }

  static renderDate(td, cellData) {
    ReactDOM.render(ReferenceMaterialsTable.formatDate(cellData), td);
  }

  static formatDate(date) {
    return moment.utc(date).local().format('MMM D, YYYY');
  }

  static _addCellId(td, id) {
    $(td).attr({
      id: `bulk-subscription-${id}`
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      doneLoading: false,
      filterSent: false,
      getUrl: this.props.ajaxUrl.replace('pageSize', this.props.pageSize.toString()),
      pagesLoaded: 0,
      resources: [],
      selectedIds: new Set()
    };

    this.addResources = this.addResources.bind(this);
    this.removeResource = this.removeResource.bind(this);
    this.updateResources = this.updateResources.bind(this);
    this.resetResources = this.resetResources.bind(this);
    this.updateActiveFilters = this.updateActiveFilters.bind(this);
    this.handleBulkCheckboxChange = this.handleBulkCheckboxChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onTableDraw = this.onTableDraw.bind(this);
    this.setBulkState = this.setBulkState.bind(this);
  }

  getCheckboxColumn(columnIndex) {
    return (
      {
        targets: columnIndex,
        title: '<div id="select-all" />',
        sortable: false,
        searchable: false,
        data: null,
        createdCell: (td, cellData, rowData) => {
          ReferenceMaterialsTable._addCellId(td, rowData.id);

          this._renderCheckboxForRow({
            id: rowData.id,
            checked: this.state.selectedIds.has(rowData.id),
            target: td
          });
        }
      }
    );
  }

  getColumnDefs() {
    return [
      this.getCheckboxColumn(0),
      {
        targets: 1,
        title: 'Image',
        width: '10%',
        sortable: false,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            ReferenceMaterialsTable.displayImage(rowData.primary_image, rowData.title),
            td
          );
        }
      },
      {
        targets: 2,
        title: 'Title',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <a href={this.props.paths.editPath.replace(':id', rowData.id)}>{cellData}</a>,
            td
          );
        }
      },
      {
        targets: 3,
        title: 'Questions',
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            <a href={this.props.paths.editQuestionsPath.replace(':id', rowData.id)}>{cellData}</a>,
            td
          );
        }
      },
      {
        targets: 4,
        title: 'Programs'
      },
      {
        targets: 5,
        title: 'Tags'
      },
      {
        targets: 6,
        title: 'Published',
        width: '10%'
      },
      {
        targets: 7,
        title: 'Date Created',
        createdCell: ReferenceMaterialsTable.renderDate,
        width: '9%'
      },
      {
        targets: 8,
        title: 'Last Updated',
        createdCell: ReferenceMaterialsTable.renderDate,
        width: '9%'
      },
      {
        targets: -1,
        title: 'Actions',
        sortable: false,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            this.renderActionsDropdown(rowData),
            td
          );
        }
      }
    ];
  }

  getActiveFilters() {
    const activeFilters = this.state.activeFilters;

    let getUrl = this.props.ajaxUrl
      .replace('pageSize', this.props.pageSize.toString());

    getUrl = buildFilterPath(getUrl, activeFilters);

    // if we're done loading, set doneLoading to false to begin loading the filter request
    // if we're currently loading something, set doneLoading to true to stop loading
    // and allow the filter request through
    this.setState(prevState => ({
      doneLoading: prevState.filterSent || !prevState.doneLoading,
      filterSent: true,
      getUrl,
      loadId: prevState.loadId + 1,
      pagesLoaded: 0,
      resources: []
    }));
  }

  updateActiveFilters(activeFilters) {
    this.setState(
      { activeFilters },
      () => this.getActiveFilters()
    );
  }

  addResources(newResources) {
    this.setState((prevState) => {
      const filterState = {
        doneLoading: newResources.length < this.props.pageSize,
        pagesLoaded: prevState.pagesLoaded + 1,
        resources: [...prevState.resources].concat(newResources)
      };

      if (prevState.filterSent) {
        filterState.doneLoading = false;
        filterState.filterSent = false;
      }

      return filterState;
    });
  }

  removeResource(data) {
    this.removeFromSelected(data.id);

    this.setState(prevState => ({
      resources: prevState.resources.filter(resource => resource.id !== data.id)
    }));
  }

  removeFromSelected(id) {
    const updatedSelectedSet = new Set(this.state.selectedIds);

    updatedSelectedSet.delete(id);

    this.updateCheckboxState(id, updatedSelectedSet);
  }

  resetResources() {
    this.setState({
      doneLoading: false,
      pagesLoaded: 0,
      resources: []
    });
  }

  updateResources(updatedResources) {
    this.setState(prevState => ({
      resources: prevState.resources.map(resource => this.getUpdatedResource(resource, updatedResources))
    }));
  }

  getUpdatedResource(originalResource, updatedResources) {
    const updatedResource = updatedResources.find(resource => resource.id === originalResource.id);

    if (updatedResource) {
      matchAttributesForSingleRow(updatedResource, this.props.columns);
      return updatedResource;
    }

    return originalResource;
  }

  getSelectedRecords() {
    return this.state.resources.filter(resource => this.state.selectedIds.has(resource.id));
  }

  renderAdd() {
    return (
      <a
        className="btn btn--green mr5"
        href={this.props.paths.newPath}
      >
        Add Reference Material
      </a>
    );
  }

  renderActionsDropdown(rowData) {
    return (
      <ActionsDropdown>
        {this.renderEdit(rowData)}
        {this.renderDelete(rowData)}
      </ActionsDropdown>
    );
  }

  renderEdit(rowData) {
    return (
      <a
        className="dropdown-item"
        href={this.props.paths.editPath.replace(':id', rowData.id)}
      >
        <i aria-hidden="true" className="fa fa-edit" />
        {' '}
        Edit
      </a>
    );
  }

  renderDelete(rowData) {
    const buttonLabel = (
      <Fragment>
        <i aria-hidden="true" className="fa fa-trash" />
        {' '}
        Delete
      </Fragment>
    );

    return (
      <DeleteModalButton
        buttonClasses="dropdown-item full-size"
        buttonLabel={buttonLabel}
        destroyPath={this.props.paths.destroyPath}
        id={rowData.id}
        modalHeaderText="Delete Reference Material"
        removeResource={this.removeResource}
      />
    );
  }

  renderFilters() {
    return (
      <div>
        <AdvancedFilters
          initialFilters={this.props.initialFilters}
          disableSelectedProperties={this.props.initialFilters}
          properties={this.props.properties}
          searchPath={this.props.ajaxUrl}
          resources={this.state.resources}
          updateActiveFilters={this.updateActiveFilters}
          asyncSearchPath={this.props.paths.asyncSearchPath}
        />
        <hr />
      </div>
    );
  }

  renderBulkActions() {
    if (this.state.selectedIds.size) {
      return (
        <ActionsDropdown toggleText="Bulk Actions">
          <ModalButton
            allowOverflow
            modalHeaderText="Add Program"
            buttonClasses="dropdown-item full-size"
            buttonLabel="Add Program"
          >
            <BulkAddProgram
              programs={this.props.programs}
              referenceMaterials={this.getSelectedRecords()}
              updatePath={this.props.paths.updatePath}
              updateResources={this.updateResources}
            />
          </ModalButton>
        </ActionsDropdown>
      );
    }

    return null;
  }

  uncheckAllCheckboxes() {
    const updatedSelectedSet = new Set(this.state.selectedIds);

    // Remove all ids that are from the current page:
    this._getCurrentPageCheckboxIds().forEach((id) => {
      if (updatedSelectedSet.has(id)) {
        updatedSelectedSet.delete(id);

        this._updateCheckbox({ id, checked: false });
      }
    });

    this.setState({ selectedIds: updatedSelectedSet, bulkChecked: 'none' });
  }

  checkAllCheckboxes() {
    const updatedSelectedSet = new Set(this.state.selectedIds);

    this._getCurrentPageCheckboxIds().forEach((id) => {
      updatedSelectedSet.add(id);

      this._updateCheckbox({ id, checked: true });
    });

    this.setState({ selectedIds: updatedSelectedSet, bulkChecked: 'all' });
  }

  _getCurrentPageCheckboxIds() {
    if (this.tableRef) {
      const rows = this.tableRef.getRows({ page: 'current' });

      if (rows) return Array.from(rows).map(row => row.id);
    }

    return [];
  }

  handleBulkCheckboxChange() {
    if (this._getBulkCheckedState(this.state.selectedIds) === 'all') {
      this.uncheckAllCheckboxes();
    }
    else {
      this.checkAllCheckboxes();
    }
  }

  _getBulkCheckedState(set) {
    if (set.size === 0) return 'none';

    return this._getCurrentPageCheckboxIds().every(id => set.has(id)) ? 'all' : 'some';
  }

  handleCheckboxChange(e) {
    const id = parseInt(e.target.value, 10);
    const updatedSelectedSet = new Set(this.state.selectedIds);

    if (updatedSelectedSet.has(id)) {
      updatedSelectedSet.delete(id);
    }
    else {
      updatedSelectedSet.add(id);
    }

    this.updateCheckboxState(id, updatedSelectedSet);
  }

  updateCheckboxState(id, updatedSelectedSet) {
    const bulkChecked = this._getBulkCheckedState(updatedSelectedSet);

    this.setState(
      {
        selectedIds: updatedSelectedSet,
        bulkChecked
      },
      () => this._updateCheckbox({ id, checked: this.state.selectedIds.has(id) })
    );
  }

  renderBulkCheckbox() {
    const label = (
      <span className="sr-only">
        {(this.state.bulkChecked ? 'Unselect' : 'Select').concat(' all records on this page')}
      </span>
    );

    if (this.state.resources.length && document.getElementById('select-all')) {
      return ReactDOM.createPortal(
        <Checkbox
          id="select-all"
          checked={this.state.bulkChecked === 'all'}
          input={{ name: 'select-all', value: 'select-all' }}
          indeterminate={this.state.bulkChecked === 'some'}
          handleChange={this.handleBulkCheckboxChange}
          label={label}
        />,
        document.getElementById('select-all')
      );
    }

    return null;
  }

  onTableDraw() {
    // updates bulk checkbox upon table re-draw
    const currentSelectedIds = this.state.selectedIds;
    this.setState({
      bulkChecked: this._getBulkCheckedState(currentSelectedIds)
    });
  }

  setBulkState() {
    this.setState(prevState => ({
      bulkChecked: this._getBulkCheckedState(prevState.selectedIds)
    }));
  }

  _updateCheckbox({ id, checked }) {
    const target = document.getElementById(`bulk-subscription-${id}`);

    if (!target) return;

    this._renderCheckboxForRow({ id, checked, target });
  }

  _renderCheckboxForRow({ id, checked, target }) {
    const label = (
      <span className="sr-only">
        {checked ? 'Unselect row' : 'Select row'}
      </span>
    );

    ReactDOM.render(
      <Checkbox
        id={`bulk-subscription-${id}`}
        input={{ name: 'bulk-subscription', value: id.toString() }}
        checked={checked}
        handleChange={this.handleCheckboxChange}
        label={label}
      />,
      target
    );
  }

  renderPaginatedTable() {
    return (
      <div className={styles.table}>
        <PaginatedTable
          doneLoadingCallback={this.setBulkState}
          addResources={this.addResources}
          ref={(ref) => { this.tableRef = ref; }}
          resetResources={this.resetResources}
          columns={this.props.columns}
          resources={this.state.resources}
          columnMapping={ReferenceMaterialsTable.getColumnMapping()}
          exportedColumns={[2, 3, 4, 5, 6, 7, 8]}
          defaultOrder={[[2, 'asc']]}
          columnDefs={this.getColumnDefs()}
          getUrl={this.state.getUrl}
          pageSize={this.props.pageSize}
          pagesLoaded={this.state.pagesLoaded}
          doneLoading={this.state.doneLoading}
          onDraw={this.onTableDraw}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderFilters()}
        <div className={styles.tableActions}>
          {this.renderAdd()}
          {this.renderBulkActions()}
        </div>
        {this.renderPaginatedTable()}
        {this.renderBulkCheckbox()}
      </div>
    );
  }
}
