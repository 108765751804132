import { useState } from 'react';
import { RowData } from './constants';

const useRemoveDemoAccessModal = () => {
  const [isRemoveDemoAccessModalOpen, setIsRemoveDemoAccessModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<RowData>(null);

  const toggleRemoveDemoAccessModal = (rowData: RowData) => {
    setIsRemoveDemoAccessModalOpen(!!rowData);
    setSelectedRowData(rowData);
  };

  return {
    toggleRemoveDemoAccessModal,
    isRemoveDemoAccessModalOpen,
    selectedRowData,
  };
};

export default useRemoveDemoAccessModal;
