import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import I18n from 'i18n-js';
import { useVideoHtmlParser } from '@/components/shared/ParsedFroalaHtml/ParsedFroalaHtml';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { closeOnEscapeKey } from '@/components/common/Utils';
import useKeydownAndClick from '@/hooks/useKeydownAndClick';
import clsx from 'clsx';
import useMediaManager from '../hooks/useMediaManager';
import styles
  from '../../../admin/SlideShow/Slide/Content/SlideObjectText/CustomFroalaCommands/ElementInsert/ElementInsert.module.scss';
import ITooltipButton from '@/components/interfaces/graphql/HtmlElement/TooltipButton';

interface TooltipButtonProps {
  tooltipButton: ITooltipButton;
}

interface InlineStyles {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  width?: string;
}

const TooltipButton = ({
  tooltipButton: {
    id,
    htmlEn,
    htmlEs,
    data: {
      autoHide = true,
      backgroundColor = 'purple',
      buttonTextEn,
      buttonTextEs,
      tooltipStyles,
      width,
      buttonMaxWidth,
    },
  },
}: TooltipButtonProps) => {
  const html = (I18n.locale === 'es' && !!htmlEs) ? htmlEs : htmlEn;

  const htmlId = `tooltipButton-${id}-content`;
  const tooltipRef = useRef();

  const currentOpenTooltipId = useSlideShowContext(state => state.currentOpenTooltipId);
  const setCurrentOpenTooltipId = useSlideShowContext(state => state.setCurrentOpenTooltipId);

  const showTooltip = id === currentOpenTooltipId;
  // Set this state instead if we don't want to auto hide the tooltip when other tooltips are opened.
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const { parse, ref } = useVideoHtmlParser(`#${htmlId}`);
  const { stopMedias } = useMediaManager({ ref });
  const positionStyles = Object.keys(tooltipStyles || {}).reduce((acc, cur) => ({
    [cur]: tooltipStyles[cur],
    ...acc,
  }), {});

  const inlineStyles: InlineStyles = { ...positionStyles, width };

  useEffect(() => {
    if (((autoHide && !showTooltip) || (!autoHide && !displayTooltip)) && tooltipRef.current) {
      $(tooltipRef.current).parents('span:first')?.find('.hint-text:first')?.hide();
    }
    if (!showTooltip || !displayTooltip) {
      stopMedias();
    }
  }, [currentOpenTooltipId, displayTooltip]);

  useLayoutEffect(() => {
    setTimeout(() => parse(), 100);
  }, []);

  closeOnEscapeKey(() => {
    setCurrentOpenTooltipId(undefined);
    setDisplayTooltip(false);
  });

  const toggleTooltip = () => {
    if (autoHide) {
      setCurrentOpenTooltipId(showTooltip ? undefined : id);
    } else {
      // Set this state instead if we don't want to auto hide the tooltip when other tooltips are opened.
      setDisplayTooltip(!displayTooltip);
    }
  };

  return (
    <span>
      <span contentEditable={false} data-button-type="tooltip">
        <span
          ref={tooltipRef}
          className="btn tooltip-btn"
          style={{ maxWidth: buttonMaxWidth }}
          data-background-color={backgroundColor}
          data-button-type="tooltip"
          data-html-element-type="TooltipButton"
          data-button-text-en={buttonTextEn}
          data-button-text-es={buttonTextEs || buttonTextEn}
          role="button"
          {...useKeydownAndClick(toggleTooltip)}
        />
        <span className={`slide-popup hint-text ${styles.defaultStyles} ${styles.htmlElement} ${styles.forceScrollbar}`} style={inlineStyles}>
          <span className={styles.defaultStyles} id={htmlId} ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
          <i
            {...useKeydownAndClick(toggleTooltip)}
            className={clsx({
              fa: true,
              'fa-times-circle': true,
              'popup-close-btn': true,
              [styles.slidePopupCloseBtn]: true,
              'tw-text-[#522e91]': !autoHide,
            })}
            role="button"
          />
        </span>
      </span>
    </span>
  );
};

export default TooltipButton;
