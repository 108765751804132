import React, { useLayoutEffect } from 'react';
import SlideshowImage from 'modules/SlideshowImage';

interface UseImageZoomProps {
  ref: React.MutableRefObject<HTMLDivElement>;
  imgSrc: string;
  disabled?: boolean;
}

// Adds our standard image zoom functionality to the given image ref.
// Also handles adding the data-zoomUrl attribute to the given ref.
const useImageZoom = ({ ref, imgSrc, disabled }: UseImageZoomProps) => {
  useLayoutEffect(() => {
    if (!ref || !ref.current || disabled) return;

    ref.current.setAttribute('data-zoomUrl', imgSrc);
    const slideshowImage = new SlideshowImage();
    slideshowImage.init(ref.current);
  }, [ref, ref.current, imgSrc]);
};

export default useImageZoom;
