import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import Modal, { Footer } from '@/components/common/Modal';
import { SubmitError } from '@/components/common/Forms/Utils';
import { plato_api_slide_object_path } from '@/modules/routes';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { QuestionGroup, SlideObject } from '@/components/admin/SlideShow/types';
import QuestionPicker from './QuestionPicker';
import QuestionSelectFields from './QuestionSelectFields';

const SECTION_TYPE = 'Section';
const ACTIVITY_NOTEBOOK_TYPE = 'Activity Notebook';

interface QuestionPickerModalProps {
  hasError: boolean;
  isSaving: boolean;
  isVideoActivity: boolean;
  modalManager: ModalManager;
  setHasError: (nextState: boolean) => void;
  setIsSaving: (nextState: boolean) => void;
  slideObject: SlideObject;
}

const QuestionPickerModal = ({
  hasError = false,
  isSaving = false,
  isVideoActivity = false,
  modalManager,
  setHasError,
  setIsSaving,
  slideObject,
}: QuestionPickerModalProps) => {
  const lessonId = useSlideShowContext(state => state.lessonId);
  const programId = useSlideShowContext(state => state.programId);
  const unitId = useSlideShowContext(state => state.unitId);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const [sectionId, setSectionId] = useState(0);
  const [notebookId, setNotebookId] = useState(0);
  const [selectedQuestionGroupIds, setSelectedQuestionGroupIds] = useState(new Set());
  const [typeOption, setTypeOption] = useState(isVideoActivity ? ACTIVITY_NOTEBOOK_TYPE : SECTION_TYPE);
  const updateSlideObject = useSlideShowContext(state => state.updateSlideObject);

  const formatType = (type) => {
    switch (type) {
      case 'Unit' || 'Unit Project':
        return 'Unit Project';
      case 'Lesson' || ACTIVITY_NOTEBOOK_TYPE:
        return ACTIVITY_NOTEBOOK_TYPE;
      default:
        return SECTION_TYPE;
    }
  };

  useEffect(() => {
    const { questionGroups } = slideObject;

    if (!questionGroups || !questionGroups.length) return;

    if (questionGroups[0].exerciseType === 'SlideShow') {
      setTypeOption('Slide Show');
    } else {
      setNotebookId(parseInt(questionGroups[0].notebook.id, 10));
      setSectionId(parseInt(questionGroups[0].notebook.modelId, 10));
      setTypeOption(formatType(questionGroups[0].notebook.modelType));
    }
  }, []);

  const questionPicker = () => {
    if (!notebookId && typeOption !== 'Slide Show') return null;
    if (typeOption === SECTION_TYPE && sectionId === 0) return null;

    return (
      <QuestionPicker
        notebookId={notebookId}
        selectedQuestionGroupIds={selectedQuestionGroupIds}
        setHasError={setHasError}
        setSelectedQuestionGroupIds={setSelectedQuestionGroupIds}
        modelType="SlideObject"
        modelId={slideObject.id}
        slideShowId={slideShowId}
        typeOption={typeOption}
      />
    );
  };

  const modalBody = () => {
    if (hasError) return <SubmitError error="Error adding Questions. Please reload the page and try again." />;

    return (
      <React.Fragment>
        <QuestionSelectFields
          lessonId={lessonId}
          modelId={slideObject.id}
          modelType="SlideObject"
          programId={programId}
          sectionId={sectionId}
          setHasError={setHasError}
          setNotebookId={setNotebookId}
          setSectionId={setSectionId}
          setTypeOption={setTypeOption}
          slideObject={slideObject}
          typeOption={typeOption}
          unitId={unitId}
          notebookId={notebookId}
        />

        <hr />

        {questionPicker()}
      </React.Fragment>
    );
  };

  const reloadIframe = () => {
    // @ts-ignore
    const iframes: HTMLIframeElement[] = [...document.getElementsByClassName(`isn-iframe-${slideObject.id}`)];

    // Refresh slide and thumbnail iframes
    iframes.forEach((iframe) => {
      iframe.contentWindow.location.reload();
    });
  };

  const updateSlide = () => {
    const mutationParams = {
      program_id: programId,
      question_group_ids: [...selectedQuestionGroupIds],
    };

    setIsSaving(true);

    Axios.patch(plato_api_slide_object_path(slideObject.id), mutationParams)
      .then((res) => {
        const questionGroups = res.data.question_groups as QuestionGroup[];

        updateSlideObject({ ...slideObject, questionGroups });

        setIsSaving(false);
        reloadIframe();
        modalManager.close();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setHasError(true);
        setIsSaving(false);
      });
  };

  const modalFooter = () => (
    <Footer
      nearlyFullScreen
      primaryButtonCallback={updateSlide}
      primaryButtonClassName="btn btn--purple"
      primaryButtonText="Save"
      primaryButtonSubmit={false}
      secondaryButtonCallback={modalManager.close}
      submitting={isSaving}
      submittingText="Saving..."
      wrapperClassName="pr20 pl20 pb20"
    />
  );

  return (
    <Fragment>
      <Modal
        closeModal={modalManager.close}
        footer={modalFooter()}
        headerText="Add Notebook Questions"
        isOpen={modalManager.isOpen}
        nearlyFullScreen
      >
        {modalBody()}
      </Modal>
    </Fragment>
  );
};

const QuestionPickerModalWrapper = props => (
  <QuestionPickerModal {...props} />
);

export default QuestionPickerModalWrapper;
