import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ShowHideButton = ({
  containerId, hideText, showText, initialHidden
}) => {
  const [hidden, setHidden] = useState(initialHidden);
  const container = useRef(<div />);

  useEffect(() => {
    container.current = document.getElementById(containerId);
    if (initialHidden) container.current.classList.add('hide');
  }, []);

  const onToggle = () => {
    if (container.current) {
      if (hidden) {
        container.current.classList.remove('hide');
      }
      else {
        container.current.classList.add('hide');
      }
      setHidden(!hidden);
    }
  };

  return (
    <button type="button" className="btn btn--outline-purple btn--md" onClick={onToggle}>
      <i className={`fa ${hidden ? showText.icon : hideText.icon} mr5`} aria-hidden />
      { hidden ? showText.message : hideText.message }
    </button>
  );
};

ShowHideButton.propTypes = {
  containerId: PropTypes.string.isRequired,
  hideText: PropTypes.shape({ icon: PropTypes.string, message: PropTypes.string }),
  initialHidden: PropTypes.bool,
  showText: PropTypes.shape({ icon: PropTypes.string, message: PropTypes.string })
};

ShowHideButton.defaultProps = {
  hideText: { icon: 'fa-caret-down', message: 'Hide' },
  initialHidden: true,
  showText: { icon: 'fa-caret-right', message: 'Show' }
};

export default ShowHideButton;
