/* eslint-disable no-param-reassign */

import React, { MutableRefObject, useEffect, useState } from 'react';
import * as fabric from 'fabric';
import { useResizeObserver } from 'usehooks-ts';
import { centerImage } from '../functions';
import useActionBarStore from '../ActionBar/useActionBarStore';

export const ORIGINAL_DIMENSIONS = {
  width: 1366,
  height: 768,
};

interface UseCanvasResizerProps {
  canvas: fabric.Canvas;
  questionTextEl?: React.MutableRefObject<HTMLDivElement> | null;
  resizeContainer: MutableRefObject<HTMLElement>;
  toolbarEl?: React.MutableRefObject<HTMLDivElement> | null;
}

const newDimensions = (availableWidth, availableHeight, aspectRatio) => {
  if (availableWidth / availableHeight < aspectRatio) {
    return {
      width: availableWidth,
      height: availableWidth / aspectRatio,
    };
  }

  return {
    height: availableHeight,
    width: availableHeight * aspectRatio,
  };
};

const useCanvasResizer = ({
  canvas,
  resizeContainer = { current: document.body },
  questionTextEl,
  toolbarEl,
}: UseCanvasResizerProps) => {
  const setCoordinates = useActionBarStore(state => state.setCoordinates);
  const [containerWidth, setContainerWidth] = useState(1366);
  const [containerHeight, setContainerHeight] = useState(768);
  const [zoomLevel, setZoomLevel] = useState(1);

  useResizeObserver({
    ref: resizeContainer,
    box: 'content-box',
    onResize: () => {
      const inModal = resizeContainer.current === document.body;
      const fullWidth = resizeContainer.current?.clientWidth;
      const toolbarWidth = toolbarEl?.current?.offsetWidth ?? 0;
      const fullHeight = resizeContainer.current.clientHeight;
      const questionTextHeight = questionTextEl?.current?.offsetHeight ?? 0;

      const availableWidth = fullWidth - toolbarWidth - (inModal ? 100 : 0);
      const availableHeight = fullHeight - (questionTextHeight || 0) - (inModal ? 100 : 0);

      const aspectRatio = 16 / 9;

      const { height, width } = newDimensions(availableWidth, availableHeight, aspectRatio);

      if (inModal) {
        setContainerWidth(width);
        setContainerHeight(height);

        canvas?.setDimensions({ width, height });

        const newZoom = width / ORIGINAL_DIMENSIONS.width;
        setZoomLevel(newZoom);
      } else {
        const canvasWidth = fullWidth - toolbarWidth;

        setContainerWidth(canvasWidth);
        setContainerHeight(height);

        canvas?.setDimensions({ width: canvasWidth, height });

        const newZoom = canvasWidth / ORIGINAL_DIMENSIONS.width;
        setZoomLevel(newZoom);
      }

      if (canvas?.backgroundImage) centerImage(canvas.backgroundImage);

      const activeObject = canvas?.getActiveObject();

      if (!activeObject) return;

      setCoordinates({ object: activeObject, canvas });
    },
  });

  useEffect(() => {
    if (zoomLevel === 1) return;

    canvas?.setZoom(zoomLevel);
    canvas?.renderAll();
  }, [zoomLevel]);

  useEffect(() => {
    canvas?.setDimensions({
      height: containerHeight,
      width: containerWidth,
    });
  }, [containerWidth, containerHeight]);

  return { containerWidth, containerHeight };
};

export default useCanvasResizer;
