import React from 'react';
import I18n from 'modules/i18n';

interface GlobeIconProps {
  ariaLabel?: string;
  color?: string;
  size?: number
}

const GlobeIcon = ({
  ariaLabel = I18n.t('library_maps'),
  color = '#247F99',
  size = 60
 }: GlobeIconProps) => (
  <svg
    aria-label={ariaLabel}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667933 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 9.88333 17.996 9.76233 17.988 9.637C17.98 9.51167 17.9757 9.40767 17.975 9.325C17.8917 9.80833 17.6667 10.2083 17.3 10.525C16.9333 10.8417 16.5 11 16 11H14C13.45 11 12.9793 10.8043 12.588 10.413C12.1967 10.0217 12.0007 9.55067 12 9V8H8V6C8 5.45 8.196 4.97933 8.588 4.588C8.98 4.19667 9.45067 4.00067 10 4H11C11 3.61667 11.1043 3.27933 11.313 2.988C11.5217 2.69667 11.7757 2.459 12.075 2.275C11.7417 2.19167 11.4043 2.125 11.063 2.075C10.7217 2.025 10.3673 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10H7C8.1 10 9.04167 10.3917 9.825 11.175C10.6083 11.9583 11 12.9 11 14V15H8V17.75C8.33333 17.8333 8.66267 17.896 8.988 17.938C9.31333 17.98 9.65067 18.0007 10 18Z" fill={color} />
  </svg>
);

export default GlobeIcon;
