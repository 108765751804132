import React from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import backIcon from 'images/icons/arrow-purple-back.svg';
import styles from './SlideShowBuilder.module.scss';
import AddSlide from '../Utils/AddSlide';
import { getNewSlidePosition, slidePropTypes } from '../Utils';
import { getUrlParams } from '../../../common/Utils';
import CreateTransitionGroup from './CreateTransistionGroup/CreateTransitionGroup.tsx';
import SaveStatus from './SlideEditor/SaveStatus';
import useSlideShowStore from '../stores/useSlideShowStore';
import useSlideShowContext from '../stores/useSlideShowContext';

const HeaderBar = ({ isSaving, returnPath, selectedSlide }) => {
  const currentSlide = useSlideShowContext(state => state.currentSlide);
  const formattedSlides = useSlideShowContext(state => state.slides);
  const canEditQuestions = useSlideShowStore(state => state.canEditQuestions);
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const slides = useSlideShowContext(state => state.slides);

  const programId = getUrlParams('program_id').program_id;
  const parentId = getUrlParams('parent_id').parent_id;
  const parentType = getUrlParams('parent_type').parent_type;
  const formattedSlide = formattedSlides.find(slide => slide.id === (currentSlide ? currentSlide.id : null));

  const presentModeHref = Routes.present_mode_shared_slide_show_path(
    slideShowId,
    {
      from: 'builder',
      parent_id: parentId && parentId.replace('#', ''),
      parent_type: parentType && parentType.replace('#', ''),
      program_id: programId && programId.replace('#', ''),
      starting_slide: currentSlide && (formattedSlide ? formattedSlide.position : currentSlide.position),
    }
  );

  const questionBuilderHref = Routes.questions_shared_slide_show_path(
    slideShowId, {
      parent_id: parentId && parentId.replace('#', ''),
      parent_type: parentType && parentType.replace('#', ''),
      program_id: programId && programId.replace('#', ''),
    }
  );

  const showGroupButton = selectedSlide && selectedSlide.slideTemplateId;

  return (
    <header
      aria-label="Header Bar"
      className={styles.header}
    >
      <div className={styles.headerLeft}>
        <AddSlide
          position={getNewSlidePosition(selectedSlide, formattedSlides)}
          slideGroupId={selectedSlide && selectedSlide.slideGroupId}
        />
        {showGroupButton && (
          <CreateTransitionGroup
            slideShowId={slideShowId}
            slides={slides}
          />
        )}
        {canEditQuestions && (
          <a
            className="btn btn--outline-purple ml5"
            href={questionBuilderHref}
            rel="noopener noreferrer"
            target="_blank"
          >
            Edit Questions <i className="fa fa-external-link ml5" aria-hidden="true"/>
          </a>
        )}
      </div>
      <span className={styles.slideShowOptions}>
        {selectedSlide && <SaveStatus isSaving={isSaving} slide={selectedSlide} />}
        <a href={returnPath} className="btn btn--outline-purple" aria-label="Back to Lesson">
          <div className={styles.textWithIcon}>
            <img src={backIcon} alt="Back" />
            Back to Lesson
          </div>
        </a>
        <a
          className={`btn btn--outline-purple ml5 ${selectedSlide ? '' : 'disabled'}`}
          href={presentModeHref}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={styles.textWithIcon}>
            <i className="fa fa-play" />
            Present
          </div>
        </a>
      </span>
    </header>
  );
};

HeaderBar.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  returnPath: PropTypes.string.isRequired,
  selectedSlide: slidePropTypes,
};

HeaderBar.defaultProps = {
  selectedSlide: null
};

export default HeaderBar;
