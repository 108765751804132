import React from 'react';
import IPanoramicImage from '@/components/interfaces/PanoramicImage';
import Hotspot from './Hotspot';

interface HiddenHotspotsProps {
  panoramicImage: IPanoramicImage;
  locale?: 'en' | 'es';
}

const HiddenHotspots = ({ panoramicImage, locale }: HiddenHotspotsProps) => (
  <div className="tw-hidden">
    {panoramicImage.hotspots.map(hotspot => (
      <Hotspot
        hotspot={hotspot}
        key={hotspot.id}
        locale={locale}
      />
    ))}
  </div>
);
export default HiddenHotspots;
