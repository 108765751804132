import { useEffect } from 'react';
import ILicense from '@/components/interfaces/License';
import { ISubscriber, seatPoolOrder } from '@/components/admin/ProgramAccess/ProgramAccessTable/utils';
import useProgramAccessStore from '@/components/admin/ProgramAccess/ProgramAccessTable/useProgramAccessStore';
import useSubscriberSeatPools from '@/components/admin/ProgramAccess/ProgramAccessTable/useSubscriberSeatPools';
import usePrograms from '@/components/admin/ProgramAccess/ProgramAccessTable/usePrograms';

// Fetch seatPools, programs and licenses for the subscriber and set them in store
const useProgramAccessTableData = (subscriber: ISubscriber) => {
  const setStore = useProgramAccessStore(store => store.setStore);
  const licenses = useProgramAccessStore(store => store.licenses);

  const { data: seatPools, isLoading: seatPoolsIsLoading } = useSubscriberSeatPools(subscriber.id);
  const { data: programs, isLoading: programsIsLoading } = usePrograms(subscriber);

  useEffect(() => setStore({ subscriber }), []);

  useEffect(() => {
    setStore({ isLoading: seatPoolsIsLoading || programsIsLoading });
  }, [seatPoolsIsLoading, programsIsLoading]);

  useEffect(() => {
    const activeLicenseIds = seatPools.map(sp => sp.license_id);
    const unsortedLicenses = Object.values(
      programs
        .map(p => p.licenses)
        .flat()
        .reduce<{ [id: number]: ILicense }>((acc, cur) => {
        if (activeLicenseIds.includes(cur.id)) {
          return { ...acc, [cur.id]: cur };
        }

        return acc;
      }, {}),
    );

    const sortedLicenses = unsortedLicenses.sort((a, b) => a.position - b.position);

    setStore({ programs, licenses: sortedLicenses });
  }, [programs.length, seatPools.length]);

  useEffect(() => {
    if (!programs.length) return;

    const seatPoolsWithPrograms = seatPools.map(seatPool => ({
      ...seatPool,
      program: programs.find(p => p.id === seatPool.program_id),
      license: licenses.find(l => l.id === seatPool.license_id),
    }));
    const orderedSeatPools = seatPoolsWithPrograms.sort((a, b) => seatPoolOrder(a, b));

    setStore({ seatPools: orderedSeatPools });
  }, [seatPools.length, programs.length, licenses.length]);
};

export default useProgramAccessTableData;
