import React, { useEffect, useState } from 'react';

interface ImageFitButtonProps {
  submitHandler: (backgroundSize: string) => void;
  size?: string;
}

const ImageFitButton = ({ submitHandler, size }: ImageFitButtonProps) => {
  const [backgroundSize, setBackgroundSize] = useState(size || 'contain');
  const inputStyles = 'tw-flex tw-gap-1 tw-p-1 tw-items-center';

  useEffect(() => {
    setBackgroundSize(size || 'contain');
  }, []);

  const handleBackgroundSize = (fit: string) => {
    setBackgroundSize(fit);
    submitHandler(fit);
  };

  const radioButton = (id, label) => (
    <label className={inputStyles}>
      <input
        type="radio"
        id={id}
        name="background-size"
        value={id}
        defaultChecked={backgroundSize === id}
        onChange={e => handleBackgroundSize(e.target.value)}
      />
      {label}
    </label>
  );

  return (
    <div className="tw-w-32">
      {radioButton('contain', 'Resize to Fit')}
      {radioButton('cover', 'Crop to Fill')}
      {radioButton('stretch', 'Stretch to Fill')}
    </div>
  );
};

export default ImageFitButton;
