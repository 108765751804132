/**
 * ImagePopup with dragging and zoom in zoom out buttons
 *
 * @param config
 * @constructor
 */
export default class Zoom {
  constructor(config) {
    this.ZOOM_FACTOR = 100;
    this.image_box = config.image_box;
    this.IMAGE = $(this.image_box).find('img');
    this._DRAGGING_STARTED = 0;
    this._LAST_MOUSEMOVE_POSITION = { x: null, y:null };
    this._DIV_OFFSET = $(this.image_box).offset();
    this._CONTAINER_WIDTH = $(this.image_box).outerWidth();
    this._CONTAINER_HEIGHT = $(this.image_box).outerHeight();
    this._IMAGE_WIDTH = this._CONTAINER_WIDTH;
    this._IMAGE_HEIGHT = this._CONTAINER_HEIGHT;
    this.scale = () => this.ZOOM_FACTOR / 100;
  }

  /**
   *  Sets up the listeners
   */
  init() {
    $(this.image_box).on('click', '.image-zoom-slider__zoom-in', this.zoomIn.bind(this));
    $(this.image_box).on('click', '.image-zoom-slider__zoom-out', this.zoomOut.bind(this));
    $(this.image_box).on('dblclick', this.handleDblClick.bind(this));
    $(this.image_box).on('mousedown', this.startDragEvent.bind(this));
    $(this.image_box).on('mouseup', this.stopDragEvent.bind(this));
    $(this.image_box).on('mouseleave', this.stopDragEvent.bind(this));
    $(this.image_box).on('mousemove', this.dragImage.bind(this));
  }

  handleDblClick(e) {
    let targetClasses = Array.from(e.target.classList);
    // image_div is from student text, zoom-image-container is from slide show
    if (targetClasses.includes('image_div') ||
        targetClasses.includes('zoom-image-container')) {
      this.zoomIn(e);
    }
  }

  /**
   * Builds the zoom in and zoom out buttons
   */
  _createTools() {
    this.tools = document.createElement('div');
    this.tools.className = 'image-zoom-slider';

    this.zoom_out_button = document.createElement('i');
    this.zoom_out_button.className = 'fa fa-minus image-zoom-slider__zoom-out disabled';

    this.scrubber = document.createElement('div');

    this.zoom_in_button = document.createElement('i');
    this.zoom_in_button.className = 'fa fa-plus image-zoom-slider__zoom-in';

    $(this.tools).append($(this.zoom_in_button));
    $(this.tools).append($(this.scrubber));
    $(this.tools).append($(this.zoom_out_button));

    $(this.image_box).append($(this.tools));
    $(this.scrubber).slider({
      orientation: "vertical",
      min: 100,
      max: 300,
      step: 50,
      slide: (event, ui) => this.changeSlider(event, ui),
      classes: {
        "ui-slider-handle": "ui-corner-all image-zoom-slider__scrubber",
        "ui-slider": "image-zoom-slider__scrubber-line"
      }
    });

    $(this.scrubber).on('click', () => this.stopDragEvent())
  }

  /**
   * Zooms in image, increases scale if necessary
   * @private
   */
  zoomIn(e) {
    if (this.scale() <= 2.5) {
      this.ZOOM_FACTOR += 50;
      this.changeImageSize(e);
    }
    this._syncZoomTools();
  }

  /**
   * Zooms out image, decreases scale if necessary
   * @private
   */
  zoomOut(e) {
    if (this.scale() > 1) {
      this.ZOOM_FACTOR -= 50;
      this.changeImageSize(e);
    }
    this._syncZoomTools();
  }

  _syncZoomTools() {
    switch(this.scale()) {
      case 3:
        $(this.zoom_in_button).addClass('disabled');
        break;
      case 1:
        $(this.zoom_out_button).addClass('disabled');
        break;
      default:
        $(this.zoom_in_button).removeClass('disabled');
        $(this.zoom_out_button).removeClass('disabled');
    }
    $(this.scrubber).slider('value', this.ZOOM_FACTOR);
  }

  changeSlider(event, ui) {
    this._DRAGGING_STARTED = 0;
    this.ZOOM_FACTOR = ui.value;
    if (this.ZOOM_FACTOR === 100) {
      $(this.zoom_out_button).addClass('disabled');
    }
    else if (this.ZOOM_FACTOR === 300) {
      $(this.zoom_in_button).addClass('disabled');
    }
    else {
      $(this.zoom_out_button).removeClass('disabled');
      $(this.zoom_in_button).removeClass('disabled');
    }

    this.changeImageSize(event);
  }

  reset(e) {
    this.ZOOM_FACTOR = 100;
    $(this.scrubber).slider('value', this.ZOOM_FACTOR);
    this.changeImageSize(e);
    $(this.zoom_in_button).removeClass('disabled');
    $(this.zoom_out_button).addClass('disabled');
  }

  resize() {
    this._CONTAINER_WIDTH = $(this.image_box).outerWidth();
    this._CONTAINER_HEIGHT = $(this.image_box).outerHeight();
  }

  changeImageSize(e) {
    this._scaleImage(e);
    this._centerImage(e);
  }

  /**
   * Sets image size based on the current scale value
   * @private
   */
  _scaleImage(e) {
    let new_width = this._CONTAINER_WIDTH * this.scale(),
      new_height = this._CONTAINER_HEIGHT * this.scale();

    $(this.IMAGE).css('width', new_width + 'px');
    $(this.IMAGE).css('height', new_height + 'px');
  }

  _centerImage(e) {
    let origX = this._CONTAINER_WIDTH,
      origY = this._CONTAINER_HEIGHT,
      heightDiff = origY - this.scale() * origY,
      widthDiff = origX - this.scale() * origX,
      newX = widthDiff / 2,
      newY = heightDiff / 2;

    $(this.IMAGE).css({
      left: newX,
      top: newY
    });
  }

  startDragEvent(event) {
    $(this.image_box).addClass('dragging');
    if (this.ZOOM_FACTOR > 100) this._DRAGGING_STARTED = 1;

    this._setMousePosition(event);
  }

  stopDragEvent() {
    $(this.image_box).removeClass('dragging');
    this._DRAGGING_STARTED = 0;
  }

  /**
   * Returns the current mousemove position
   * @param e
   * @returns {{x: number, y: number}}
   * @private
   */
  _getMousePosition(e) {
    return {
      x: e.pageX - this._DIV_OFFSET.left,
      y: e.pageY - this._DIV_OFFSET.top
    };
  }

  /**
   * Sets the current mousemove position
   * in this._LAST_MOUSEMOVE_POSITION
   * @param e
   * @private
   */
  _setMousePosition(e) {
    this._LAST_MOUSEMOVE_POSITION = this._getMousePosition(e);
  }

  dragImage(event) {
    if (this._DRAGGING_STARTED === 1) {
      const current_mouse_position = this._getMousePosition(event);
      const change_x = current_mouse_position.x - this._LAST_MOUSEMOVE_POSITION.x;
      const change_y = current_mouse_position.y - this._LAST_MOUSEMOVE_POSITION.y;

      /* Save mouse position */
      this._LAST_MOUSEMOVE_POSITION = current_mouse_position;

      const img_top = parseInt($(this.IMAGE).css('top'), 10);
      const img_left = parseInt($(this.IMAGE).css('left'), 10);

      let img_top_new = img_top + change_y;
      let img_left_new = img_left + change_x;

      // Validate top and left do not fall outside the image,
      // otherwise white space will be seen
      if (img_top_new > 0) img_top_new = 0;
      if (img_top_new < (this._CONTAINER_HEIGHT - $(this.IMAGE).height())) {
        img_top_new = this._CONTAINER_HEIGHT - $(this.IMAGE).height();
      }

      if(img_left_new > 0) img_left_new = 0;
      if(img_left_new < (this._CONTAINER_WIDTH - $(this.IMAGE).width())) {
        img_left_new = this._CONTAINER_WIDTH - $(this.IMAGE).width();
      }

      $(this.IMAGE).css({ top: img_top_new + 'px', left: img_left_new + 'px' });
    }
  }
}
