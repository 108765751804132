import React from 'react';
import { truncate } from 'modules/TCIUtils';
import clsx from 'clsx';
import styles from './TransitionSlide.module.scss';
import Tooltip from '../../../../common/Tooltip';

interface TitleLinkProps {
  completed: boolean;
  extension: boolean;
  isLastLink: boolean;
  onClick: () => void;
  title: string;
  pacing?: number;
  nextIsExtension?: boolean;
}

const TRUNCATION_LENGTH = 55;

const TitleButton = ({ extension, onClick, title, pacing }) => (
  <button
    type="button"
    className="btn btn--link-purple no_pad tw-flex tw-w-full"
    tabIndex={0}
    onClick={onClick}
  >
    <div className="tw-flex tw-w-full tw-justify-between">
      {extension
        ? (
          <div className={styles.extensionTitle}>{title} {pacing ? `(${pacing} min)` : ''}</div>
        )
        : (
          <>
            <div>{title}</div>
            {pacing ? <span className={styles.pacing}>{pacing} min</span> : ''}
          </>
        )
      }
    </div>
  </button>
);

const TitleLink = ({ completed, isLastLink, onClick, title, extension, pacing, nextIsExtension }: TitleLinkProps) => (
  <div
    className={clsx({
      [styles.titleLink]: true,
      'tw-mb-[10px]': nextIsExtension === false,
      'tw-mb-[2px]': nextIsExtension === true,
    })}
  >
    <div
      className={clsx({
        [styles.progressCircle]: extension === false,
        [styles.completed]: completed === true,
      })}
    />
    {extension && <div className={styles.extensionBar} />}
    {!isLastLink && <div className={styles.progressBar} />}
    <div
      className={clsx({
        [styles.linkBorder]: true,
        [styles.bottomBorder]: nextIsExtension === false,
      })}
    >
      {title?.length > TRUNCATION_LENGTH
        ? (
          <Tooltip content={title}>
            <TitleButton
              extension={extension}
              onClick={onClick}
              title={truncate(title, TRUNCATION_LENGTH)}
              pacing={pacing}
            />
          </Tooltip>
        )
        : <TitleButton extension={extension} onClick={onClick} title={title} pacing={pacing} />
      }
    </div>
  </div>
);

export default TitleLink;
